import React, { useMemo } from 'react';
import Datetime from 'react-datetime';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';

import styled from 'styled-components';
import * as R from 'ramda';
import * as formHelpers from '../../../utils/redux-form-helper';
import { validateDateRangeReputationManagement } from '../utils';
import {
  Select,
  Stack,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { FormattedMessage } from 'react-intl';
import messages from '../../Ledger/LedgerFilterSection/LedgerFilterLayout/FilterControls/messages';
import repMgmtMessages from '../messages';
import type {
  DateFilterType,
  DateFilterTypeChangeHandler,
} from '../ReviewsFilter/types';
import { grey } from '@fortress-technology-solutions/fortress-component-library/design';

type Props = {
  timeFrame: string,
  onTimeFrameChange: Function,
  onFromDateChange: Function,
  onToDateChange: Function,
  toDate: string,
  fromDate: string,
  dateFilterType: DateFilterType,
  onDateFilterTypeChange: DateFilterTypeChangeHandler,
  label: string,
  width?: number,
  disabled?: boolean,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${R.prop('width')};

  .date-range-ctrl {
    height: 33px;
  }
`;

const Separator = styled.div`
  width: 16px;
  height: 0;
  border: 1px solid ${grey.light};
  margin: 0 10px;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .rdt {
    flex-grow: 1;!important;
  }
`;

const dateTimeClassname = 'date-range-ctrl';

function VariableDateControl({
  timeFrame,
  onTimeFrameChange,
  onFromDateChange,
  onToDateChange,
  toDate,
  fromDate,
  dateFilterType,
  onDateFilterTypeChange,
  width,
  label,
  disabled,
}: Props) {
  const isDateRange = useMemo(
    () => dateFilterType === 'range',
    [dateFilterType],
  );
  const showError = useMemo(() => {
    return !validateDateRangeReputationManagement(fromDate, toDate);
  }, [fromDate, toDate]);

  const handleRangeCheckboxChange = () => {
    onDateFilterTypeChange(isDateRange ? 'timeframe' : 'range');
  };

  const DATE_TIMEFRAME_OPTIONS = [
    {
      text: 'All Time',
      value: 'All Time',
    },
    {
      text: 'Last 30 days',
      value: 'Last 30 days',
    },
    {
      text: 'Last 90 days',
      value: 'Last 90 days',
    },
    {
      text: 'Last 6 months',
      value: 'Last 6 months',
    },
    {
      text: 'Last 12 months',
      value: 'Last 12 months',
    },
  ];

  return (
    <Wrapper width={width}>
      <Stack direction="row" justifyContent="space-between">
        <div>{label && <ControlLabel>{label}</ControlLabel>}</div>
        <Stack direction="row" spacing={1}>
          <input
            type="checkbox"
            onChange={handleRangeCheckboxChange}
            checked={isDateRange}
            disabled={disabled}
          />
          <ControlLabel>
            <FormattedMessage {...messages.range} />
          </ControlLabel>
        </Stack>
      </Stack>
      {isDateRange && (
        <FormGroup
          data-testid="reviewFiltersDatePicker"
          controlId="toDate"
          validationState={showError ? 'error' : null}
        >
          <InputContainer>
            <form autoComplete="off">
              <Datetime
                value={fromDate}
                inputProps={{
                  placeholder: 'MM/DD/YYYY',
                  className: dateTimeClassname,
                }}
                timeFormat={false}
                closeOnSelect={true}
                onChange={onFromDateChange}
                renderInput={formHelpers.renderDatePickerInput}
              />
            </form>
            <Separator />
            <form autoComplete="off">
              <Datetime
                value={toDate}
                inputProps={{
                  placeholder: 'MM/DD/YYYY',
                  className: dateTimeClassname,
                }}
                timeFormat={false}
                closeOnSelect={true}
                onChange={onToDateChange}
                renderInput={formHelpers.renderDatePickerInput}
              />
            </form>
          </InputContainer>
          {showError && (
            <HelpBlock className="has-error">
              <FormattedMessage {...repMgmtMessages.dateValidationError} />
            </HelpBlock>
          )}
        </FormGroup>
      )}
      {!isDateRange && (
        <FormGroup>
          <InputContainer>
            <Select
              placeholder="Choose"
              value={timeFrame}
              options={DATE_TIMEFRAME_OPTIONS}
              onChange={onTimeFrameChange}
              fullWidth
            />
          </InputContainer>
        </FormGroup>
      )}
    </Wrapper>
  );
}

export default VariableDateControl;
