import { useState } from 'react';
import * as R from 'ramda';
import CommercialScheduledRentsService from '../../../../../services/commercialScheduledRentsService';

const getHoldover = (formValues: Object) => {
  if (!R.isNil(formValues.dollarIncrease))
    return { dollarAmount: formValues.dollarIncrease };
  if (!R.isNil(formValues.percentageIncrease))
    return { percentage: formValues.percentageIncrease };

  return { noIncrease: true };
};

export function useCreateRents(
  propertyId: string,
  organizationId: string,
  leaseId: string,
  toasterFn: Function,
  refreshRents: Function,
) {
  const [isPosting, setIsPosting] = useState(false);

  const createRents = async (formValues: Object) => {
    if (isPosting || !formValues) return;

    const service = new CommercialScheduledRentsService();

    setIsPosting(true);

    try {
      const holdover = getHoldover(formValues);
      await service.createRents(
        organizationId,
        propertyId,
        leaseId,
        formValues.rentSchedules,
        holdover,
      );
      toasterFn({
        message: 'Scheduled Rents created successfully.',
        title: 'Success',
      });
      refreshRents();
    } catch (e) {
      toasterFn({
        type: 'error',
        message: 'Failed to create Scheduled Rents.',
        title: 'Error',
      });
    }

    setIsPosting(false);
  };

  return [createRents, isPosting];
}
