const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class FinancialPeriodsService {
  getAllClosedPeriods(
    organizationId: string,
    propertyId: string,
    optionArgs?: Object,
  ) {
    const defaultOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const options = { ...defaultOptions, ...optionArgs };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/closed-financialPeriods`,
      options,
    );
  }
  close(organizationId: string, propertyId: string) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/financialPeriods/close`,
      options,
    );
  }
}

export default FinancialPeriodsService;
