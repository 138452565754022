import type {
  TxBatchPayload,
  TxBatchPostPayload,
} from '../containers/CreateTransactionBatch/types';

const { get, post, del } = require('../utils/api');

class TransactionBatchService {
  save(
    organizationId: string,
    propertyId: string,
    payload: TxBatchPayload,
  ): any {
    return post(
      `/${organizationId}/${propertyId}/transaction-batch`,
      JSON.stringify(payload),
    );
  }

  getAllTransactionBatches(
    organizationId: string,
    propertyId: string,
    signal?: Object,
  ): Promise<any> {
    const options = signal ? { signal } : {};
    return get(`/${organizationId}/${propertyId}/transaction-batch`, options);
  }

  get(id: string, propertyId: string, orgId: string): Promise<any> {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return get(`/${orgId}/${propertyId}/transaction-batch/${id}`, options);
  }

  post(
    organizationId: string,
    propertyId: string,
    payload: TxBatchPostPayload,
  ) {
    return post(
      `/${organizationId}/${propertyId}/transaction-batch/post`,
      JSON.stringify(payload),
    );
  }

  delete(
    organizationId: string,
    propertyId: string,
    transactionBatchHeaderId: string,
  ): any {
    return del(
      `/${organizationId}/${propertyId}/transaction-batch/${transactionBatchHeaderId}`,
    );
  }
}

export default TransactionBatchService;
