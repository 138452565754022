import { useQuery } from 'react-query';
import FloorPlanService from '../../../services/floorPlanService';
import { useMemo } from 'react';

import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

export const useFetchUnitsByFloorPlan = ({
  organizationId,
  propertyId,
  floorPlanId,
  show,
}) => {
  const floorPlanService = useMemo(() => {
    return new FloorPlanService();
  }, []);
  return useQuery(
    ['unitsByFloorPlan', floorPlanId],
    async () => {
      const unitByFp = await floorPlanService.getAllUnitsByFloorPlan(
        organizationId,
        propertyId,
        floorPlanId,
      );
      return unitByFp.map((unit) => {
        return {
          ...unit,
          isADA: unit.isADA ? 'Yes' : 'No',
          resident: {
            variant: 'link',
            href: getUrlWithSelectedPropertyId(`/resident/${unit.residentId}`),
            value: unit.Resident ? unit.Resident : null,
          },
          applicant: {
            variant: 'link',
            href: getUrlWithSelectedPropertyId(
              `/application/${unit.applicationId}`,
            ),
            value: unit.Applicant ? unit.Applicant : null,
          },
        };
      });
    },
    {
      staleTime: 60000,
      enabled: Boolean(floorPlanId?.length && show),
    },
  );
};
