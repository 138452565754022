import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageFloorPlan.MissingSmartPricesModal.Title',
    defaultMessage: 'Smart Price',
  },
  whyTitle: {
    id: 'App.ManageFloorPlan.MissingSmartPricesModal.whyTitle',
    defaultMessage: "Why I don't see a Smart Price on every floor plan?",
  },
  whyContent: {
    id: 'App.ManageFloorPlan.MissingSmartPricesModal.whyContent',
    defaultMessage:
      'If you have Smart Price turned on, you might still not always see a recommendation for every floor plan.',
  },
  becauseTitle: {
    id: 'App.ManageFloorPlan.MissingSmartPricesModal.becauseTitle',
    defaultMessage: 'This can happen because:',
  },
  becauseContent1: {
    id: 'App.ManageFloorPlan.MissingSmartPricesModal.becauseContent1',
    defaultMessage:
      // eslint-disable-next-line max-len
      "There aren't enough units in the floor plan to create a supply and demand model. If you think this is the issue and want to figure out a solution, contact your admin.",
  },
  becauseContent2: {
    id: 'App.ManageFloorPlan.MissingSmartPricesModal.becauseContent2',
    defaultMessage:
      // eslint-disable-next-line max-len
      "The model was recenty turned on and is still developing suggestions. If you don't see smart prices within 3 days of turning it on, contact your admin.",
  },
  becauseContent3: {
    id: 'App.ManageFloorPlan.MissingSmartPricesModal.becauseContent3',
    defaultMessage:
      // eslint-disable-next-line max-len
      "The selected floor plan belongs to commercial units. Currently we don't support smart pricing for commercial-enabled floor plans.",
  },
  gotIt: {
    id: 'App.ManageFloorPlan.MissingSmartPricesModal.gotIt',
    defaultMessage: 'Okay, got it',
  },
});

export default messages;
