import { isNil } from 'ramda';

import formMessages from '../../../containers/ShortLeaseApplication/messages';

const warn = (values: any, { intl }: Object) => {
  const warnings = {};

  if (isNil(values)) {
    warnings.hasSignedCopy = intl.formatMessage(
      formMessages.requiredForCompletion
    );
    return warnings;
  }

  if (!values.hasSignedCopy) {
    warnings.hasSignedCopy = intl.formatMessage(
      formMessages.requiredForCompletion
    );
  }

  return warnings;
};

export default warn;
