import { useState, useEffect } from 'react';
import PropertyTransactionCodesService from '../../../services/propertyTransactionCodesService';

export const useGetPropertySubsidyTransactionCode = ({
  organizationId,
  propertyId,
}: Object): any => {
  const [transactionCodes, setTransactionCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };

    const getPropertySubsidyBatchTransactionCode = async () => {
      if (organizationId && propertyId) {
        setIsLoading(true);
        const propertyTransactionCodesService =
          new PropertyTransactionCodesService();
        const propertyTransactionCodes =
          await propertyTransactionCodesService.getPropertySubsidyBatchTransactionCodes(
            organizationId,
            propertyId,
            options,
          );
        setTransactionCodes(propertyTransactionCodes);
        setIsLoading(false);
      }
    };

    getPropertySubsidyBatchTransactionCode();

    return () => abortController.abort();
  }, [organizationId, propertyId]);

  return [transactionCodes, isLoading];
};
