import React from 'react';
import { FieldArray } from 'redux-form';

import PetFields from './PetFields';
import PendingPets from '../Form/PendingPets';

class Pets extends React.Component<any> {
  render() {
    const { leaseLocked } = this.props;
    return (
      <div className="applicant-grp">
        <div className="block-heading row">
          <h2>Pet(s)</h2>
        </div>
        <div className="row">
          <div className="col-xs-6 col-sm-12">
            <div className="row">
              <div className="labels-head container-of-inputs">
                <div className="col col-xs-6 col-sm-2">
                  <label>Name</label>
                </div>
                <div className="col col-xs-6 col-sm-2">
                  <label>Pet*</label>
                </div>
                <div className="col col-xs-6 col-sm-2">
                  <label>Type*</label>
                </div>
                <div className="col col-xs-6 col-sm-1">
                  <label>Weight (lbs)</label>
                </div>
                <div className="col col-xs-6 col-sm-2">
                  <label>Pet Tag</label>
                </div>
                <div className="col col-xs-6 col-sm-1 text-center">
                  <label>Service Animal?</label>
                </div>
              </div>
              <div className="container-of-trash">
                <div className="row-remove-btn">
                  <label>Delete</label>
                </div>
              </div>
            </div>
            <div className="row">
              <FieldArray
                name="pets"
                component={PetFields}
                deletePet={this.props.deletePet}
                leaseLocked={leaseLocked}
              />
            </div>
            <PendingPets deletePet={this.props.deletePendingPet} />
          </div>
          <div className="col-xs-6 col-sm-5">{/* nothing*/}</div>
        </div>
        <div className="row">
          <a
            className="btn-text"
            onClick={this.props.addPet}
            disabled={leaseLocked}
          >
            + Add Pet
          </a>
        </div>
      </div>
    );
  }
}

export default Pets;
