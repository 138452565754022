import { defineMessages } from 'react-intl';

const messages = defineMessages({
  disabled: {
    id: 'App.DisabilitySection.Disabled',
    defaultMessage: 'Disabled',
  },
  mobileDisability: {
    id: 'App.DisabilitySection.MobileDisability',
    defaultMessage: 'Mobile Disability',
  },
  hearingDisability: {
    id: 'App.DisabilitySection.HearingDisability',
    defaultMessage: 'Hearing Disability',
  },
  visualDisability: {
    id: 'App.DisabilitySection.VisualDisability',
    defaultMessage: 'Vision Disability',
  },
  otherDisability: {
    id: 'App.DisabilitySection.OtherDisability',
    defaultMessage: 'Other Disability',
  },
});

export default messages;
