import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { debounce } from 'lodash';
import moment from 'moment';
import { pathOr } from 'ramda';
import React from 'react';
import { Col, FormControl, FormGroup, InputGroup, Row } from 'react-bootstrap';
import DateTime from 'react-datetime';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import DashesIfNullOrUndefined from '../../../components/DashesIfNullOrUndefined';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import HideUnitFromOnlineAvailabilityCheckbox from './HideUnitFromOnlineAvailabilityCheckbox';
import messages from './messages';

const UnitStatus = ({
  intl,
  notes,
  unit,
  unitStatuses,
  updateNotes,
  updateUnit,
  checkUserPermissions,
}: Object) => {
  const { hideUnitFromPublicListing } = useFlags();

  const unitStatusVacant = pathOr('---', ['unitStatus', 'isVacant'], unit);
  const unitStatusIsManagementOwned = pathOr(
    false,
    ['unitStatus', 'isManagementOwned'],
    unit,
  );
  const unitStatus = pathOr('---', ['unitStatus', 'description'], unit);
  const unitStatusId = pathOr(null, ['unitStatusId'], unit);
  const actualReadyDate = () => {
    if (unitStatus === 'Vacant - Ready' && unit.actualReadyDate) {
      const formattedActualReadyDate = moment
        .utc(unit.actualReadyDate)
        .format('MM/DD/YYYY');
      return (
        <DateTime
          closeOnSelect={true}
          inputProps={{
            disabled: !checkUserPermissions(['manage-units-update']),
          }}
          onChange={changeActualReadyDate}
          renderInput={renderDatePickerInput}
          timeFormat={false}
          value={formattedActualReadyDate}
          isValidDate={(currentDate) => {
            return currentDate.isBefore();
          }}
        />
      );
    }
    return '---';
  };
  const enableEditEstimatedReadyDate =
    unitStatus === 'Vacant / Not Ready' ||
    unitStatus === 'Occupied / On Notice to Vacate';

  const estimatedReadyDate = () => {
    const makeReadyDate = pathOr('', ['makeReadyDate'], unit);
    if (
      makeReadyDate &&
      (unitStatus === 'Vacant / Not Ready' ||
        unitStatus === 'Occupied / On Notice to Vacate')
    ) {
      return moment.utc(makeReadyDate).format('MM/DD/YYYY');
    }

    const moveOut = pathOr(
      pathOr(null, ['priorAssignation', 'lease', 'moveOutDate'], unit),
      ['currentAssignation', 'lease', 'noticeToVacate', 'moveOutDate'],
      unit,
    );
    if (!moveOut) {
      return 'N/A';
    }
    const turnMoveOutDays = pathOr(0, ['property', 'turnMoveOutDays'], unit);
    const date = new Date(moveOut);
    const moveOutDate = date.setDate(date.getDate() + turnMoveOutDays);
    return moment.utc(moveOutDate).format('MM/DD/YYYY');
  };

  const changeMakeReadyDate = debounce((e) => {
    const date = moment(e).format('YYYY-MM-DD');
    if (date) {
      updateUnit({ makeReadyDate: date });
    }
  }, 2000);

  const changeActualReadyDate = debounce((e) => {
    if (typeof e !== 'string') {
      const date = moment.utc(e).startOf('day');
      updateUnit({ actualReadyDate: date });
    } else {
      toastr.error(
        intl.formatMessage(messages.error),
        intl.formatMessage(messages.invalidDate),
      );
    }
  }, 2000);

  const renderDatePickerInput = (props, openCalendar) => {
    const buttonClick = props.disabled ? undefined : openCalendar;
    return (
      <InputGroup>
        <FormControl type="text" {...props} className="input-md" />
        <span onClick={buttonClick} className="input-group-addon">
          <i className="et-icons16 et-calendar" />
        </span>
      </InputGroup>
    );
  };

  const renderUnitStatus = () => {
    if (unitStatusVacant || unitStatusIsManagementOwned) {
      const statusChoices = unitStatuses.reduce((statuses, status) => {
        if (status.description === 'Down')
          return [
            ...statuses,
            <ElementWithPermissions
              key={status.id}
              scope={['manage-units-update-down-unit']}
            >
              <option key={status.id} value={status.id}>
                {status.description}
              </option>
            </ElementWithPermissions>,
          ];
        if (status.isVacant || status.isManagementOwned) {
          let option = (
            <option key={status.id} value={status.id}>
              {status.description}
            </option>
          );
          if (status.isManagementOwned) {
            option = (
              <ElementWithPermissions
                key={status.id}
                scope={['manage-units-update-property-office-or-model']}
              >
                {option}
              </ElementWithPermissions>
            );
          }
          return [...statuses, option];
        }
        return statuses.sort();
      }, []);

      return (
        <form>
          <select
            className="form-control input-md"
            onChange={(e) => updateUnit({ unitStatusId: e.target.value })}
            value={unitStatusId || ''}
          >
            {!unitStatus && (
              <option key={0} value="" disabled>
                <DashesIfNullOrUndefined
                  data={intl.formatMessage(messages.choose)}
                />
              </option>
            )}
            {statusChoices}
          </select>
        </form>
      );
    } else {
      return <span>{unitStatus}</span>;
    }
  };

  return (
    <div className="panel unit-information panel-withheader">
      <Box
        className="panel-head"
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={hideUnitFromPublicListing ? '2px 20px !important' : undefined}
      >
        <h2>
          <FormattedMessage {...messages.title} />
        </h2>

        {hideUnitFromPublicListing && (
          <HideUnitFromOnlineAvailabilityCheckbox
            hasPermission={checkUserPermissions(['manage-units-update'])}
            unitStatusId={unit.unitStatusId}
            unitStatuses={unitStatuses}
            hideUnitFromOnlineAvailability={unit.hideUnitFromOnlineAvailability}
            updateUnit={(hideUnitFromOnlineAvailability) =>
              updateUnit({ hideUnitFromOnlineAvailability })
            }
          />
        )}
      </Box>
      <div className="container-fluid unit-details panel__details">
        <div className="row">
          <Col xs={12} sm={6}>
            <Row>
              <Col xs={12} md={6}>
                <strong>
                  <FormattedMessage {...messages.status} />
                </strong>
              </Col>
              <Col xs={12} md={6}>
                <ElementWithPermissions scope={['manage-units-update']}>
                  {renderUnitStatus()}
                </ElementWithPermissions>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={6}>
            <Row>
              <Col xs={12} md={6}>
                <strong>
                  <FormattedMessage {...messages.estimatedReadyDate} />
                </strong>
              </Col>
              <Col xs={12} md={6}>
                <DateTime
                  closeOnSelect={true}
                  inputProps={{
                    disabled:
                      !enableEditEstimatedReadyDate ||
                      !checkUserPermissions(['manage-units-update']),
                  }}
                  onChange={changeMakeReadyDate}
                  renderInput={renderDatePickerInput}
                  timeFormat={false}
                  value={estimatedReadyDate()}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <strong>
                  <FormattedMessage {...messages.actualReadyDate} />
                </strong>
              </Col>
              <Col xs={12} md={6}>
                {actualReadyDate()}
              </Col>
            </Row>
          </Col>
        </div>
        <Row>
          <div className="col-xs-12 padtop10">
            <div className="row">
              <div className="col-xs-12 col-md-3">
                <strong>
                  <FormattedMessage {...messages.statusNotes} />
                </strong>
              </div>
              <div className="col-xs-12 col-md-9">
                <FormGroup>
                  <ElementWithPermissions scope={['manage-units-update']}>
                    <textarea
                      className="form-control input-lg"
                      placeholder="Start typing..."
                      onChange={updateNotes}
                      value={notes}
                      maxLength="255"
                    />
                  </ElementWithPermissions>
                </FormGroup>
              </div>
            </div>
          </div>
        </Row>
        {hideUnitFromPublicListing && (
          <Typography variant={'caption'}>
            <Typography fontWeight={500} component={'span'}>
              Note:{' '}
            </Typography>
            <FormattedMessage {...messages.changingUnitStatus} />
          </Typography>
        )}
      </div>
    </div>
  );
};

export default UnitStatus;
