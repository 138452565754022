import cloneDeep from 'lodash/cloneDeep';
import type { Disability } from '../../components/LeaseAffordableApplicantFormCommonSections/types';

export const GET_APPLICANT_PRIMARY_FORM_INFORMATION =
  'app/PrimaryLeaseApplication/GET_APPLICANT_PRIMARY_FORM_INFORMATION';
export const GET_APPLICANT_PRIMARY_FORM_INFORMATION_SUCCESS =
  'app/PrimaryLeaseApplication/GET_APPLICANT_PRIMARY_FORM_INFORMATION_SUCCESS';
export const GET_APPLICANT_PRIMARY_FORM_INFORMATION_ERROR =
  'app/PrimaryLeaseApplication/GET_APPLICANT_PRIMARY_FORM_INFORMATION_ERROR';

export const SAVE_PRIMARY_FORM =
  'app/PrimaryLeaseApplication/SAVE_PRIMARY_FORM';
export const SAVE_PRIMARY_FORM_SUCCESS =
  'app/PrimaryLeaseApplication/SAVE_PRIMARY_FORM_SUCCESS';
export const SAVE_PRIMARY_FORM_ERROR =
  'app/PrimaryLeaseApplication/SAVE_PRIMARY_FORM_ERROR';

export const CLEAN_APPLICANT_PRIMARY_FORM_INFORMATION =
  'app/PrimaryLeaseApp/CLEAN_APPLICANT_PRIMARY_FORM_INFORMATION';

export const GET_ALL_APPLICANT_PRIMARY_FORM_INFORMATION =
  'app/PrimaryLeaseApp/GET_ALL_APPLICANT_PRIMARY_INFORMATION';

export const PRINT_APPLICATION_FORM =
  'app/PrimaryLeaseApp/PRINT_APPLICATION_FORM';
export const PRINT_APPLICATION_FORM_SUCCESS =
  'app/PrimaryLeaseApp/PRINT_APPLICATION_FORM_SUCCESS';
export const PRINT_APPLICATION_FORM_ERROR =
  'app/PrimaryLeaseApp/PRINT_APPLICATION_FORM_ERROR';

export const PRINT_WINDOW_APPLICATION_FORM =
  'app/PrimaryLeaseApp/PRINT_WINDOW_APPLICATION_FORM';

export const DEFAULT_EMPTY_EMPLOYER_INFO = {
  address: {
    city: '',
    state: '',
    street: '',
    zipCode: '',
  },
  annualSalary: '',
  employerName: '',
  from: '',
  phoneNumber: '',
  position: '',
  supervisorName: '',
  to: void 0,
};

export const FORM_NAME = 'primaryLeaseApplication';

export const defaultDisability: Disability = {
  hasDisability: null,
  disabilityHearing: null,
  disabilityMobile: null,
  disabilityOther: null,
  disabilityVisual: null,
};

export const emptyMinor = {
  suffixId: 'default',
  relationship: 'default',
  affordableRelationship: 'default',
  disability: cloneDeep(defaultDisability),
};

export const MINOR_AMOUNT = 4;
