import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { pathOr, update } from 'ramda';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import Spinner from '../../../../components/Spinner';
import Table from '../../../../components/Table';
import Data from '../../../../components/Table/Data';
import Row from '../../../../components/Table/Row';
import UploadDocumentModal from '../../../../components/UploadDocumentModal';

import { promptToaster } from '../../../App/actions';

import EditGRModal from './EditGRModal';
import messages from './messages';
import GrossRentRow from './GrossRentRow';
import { UPLOAD_MODAL, EDIT_CERT_MODAL } from './constants';
import { useAsyncDownloadAll59As } from './hooks';
import { determineIf59aIsDisabled } from './utils';

const TableContainer = styled.div`
  max-height: 65vh;
  max-width: 100%;
  padding: 0;
  overflow-x: auto;
`;

type CerticationsTableProps = {
  headers: Array<Object>,
  intl: Object,
  onSortChange: Function,
};

function ManageCertificationsContent({
  actions: { promptToaster },
  headers,
  intl,
  isLoading,
  onSortChange,
  rows,
  selectedProperty,
  signed59ADocumentType,
}: CerticationsTableProps) {
  const [modalToShow, setModalToShow] = useState(null);
  const [handleDocumentUpload, setHandleDocumentUpload] = useState(null);
  const [editCertInfo, setEditCertInfo] = useState({});
  const [grossRentCertRows, setGrossRentCertRows] = useState(rows);

  useEffect(() => {
    setGrossRentCertRows(rows);
  }, [rows]);

  const { handleGenerateAll59As, successfullyGenerated59As, submitting } =
    useAsyncDownloadAll59As(selectedProperty, promptToaster);

  const hideModal = () => {
    setModalToShow(null);
  };

  /**
   * This is an overly complex spider's web of functions being passed around.
   * This function in particular is being passed into GrossRentRow. When
   * clicking the action button to upload a document on a row, this sets the
   * state of `handleDocumentUpload` to the appropriate upload function for that
   * row. `handdleDocumentUpload` is passed into the upload doc modal below.
   */
  const openUploadDocumentModal = (uploadDocumentFn: Function) => () => {
    const submitDocumentUpload =
      () => (document: Object, cleanupFn: Function) => {
        uploadDocumentFn(document, cleanupFn);
      };
    setHandleDocumentUpload(submitDocumentUpload);
    setModalToShow(UPLOAD_MODAL);
  };

  const openEditGRCertModal = (certInfo: Object) => () => {
    setEditCertInfo(certInfo);
    setModalToShow(EDIT_CERT_MODAL);
  };

  const removeRow = (rowIdToRemove: string) => () => {
    const filteredRows = grossRentCertRows.filter(
      ({ affordableQualificationId }) =>
        affordableQualificationId !== rowIdToRemove,
    );
    setGrossRentCertRows(filteredRows);
  };

  const updateRow = (newRow: Object) => {
    const indexToUpdate = rows.findIndex(
      (r) => r.affordableQualificationId === newRow.affordableQualificationId,
    );
    const rowsWithUpdatedRecord = update(
      indexToUpdate,
      newRow,
      grossRentCertRows,
    );
    setGrossRentCertRows(rowsWithUpdatedRecord);
  };

  useEffect(() => {
    if (successfullyGenerated59As) {
      const updatedRows = grossRentCertRows.map((row) => {
        const generate59aDisabledForRow = determineIf59aIsDisabled(row);
        if (!generate59aDisabledForRow) {
          return { ...row, generated59a: true };
        }
        return row;
      });
      setGrossRentCertRows(updatedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullyGenerated59As]);

  const signed59ADocumentTypeId = pathOr(null, ['id'], signed59ADocumentType);
  const text = pathOr('', ['name'], signed59ADocumentType);
  const signed59ADocumentTypeOption = { value: signed59ADocumentTypeId, text };

  const fileValidations = (file: Object) => {
    const type = pathOr('', ['type'], file);
    if (type.includes('pdf')) {
      return [];
    }
    return ['Note: The document type selected for upload must be a PDF.'];
  };

  const generatableRows = grossRentCertRows.filter((row) => {
    const generate59aDisabledForRow = determineIf59aIsDisabled(row);
    return !generate59aDisabledForRow;
  });

  return (
    <>
      {!isLoading && (
        <>
          <UploadDocumentModal
            show={modalToShow === UPLOAD_MODAL}
            onHide={hideModal}
            handleDocumentUpload={handleDocumentUpload}
            title="Upload Signed 59a"
            documentTypeOptions={[signed59ADocumentTypeOption]}
            fileValidations={fileValidations}
          />
          <EditGRModal
            show={modalToShow === EDIT_CERT_MODAL}
            onHide={hideModal}
            editCertInfo={editCertInfo}
          />
        </>
      )}
      <Col xs={6} md={6}>
        <p>
          <FormattedMessage {...messages.grCertsFound} />
          <span className="text-blue">
            {grossRentCertRows ? `: ${grossRentCertRows.length}` : ': 0'}
          </span>
        </p>
      </Col>
      <Col xs={6} md={6}>
        <button
          className="btn btn-shout pull-right"
          onClick={handleGenerateAll59As}
          disabled={submitting || generatableRows.length === 0}
        >
          {submitting ? <Spinner small /> : 'Generate All 59a'}
        </button>
      </Col>
      <Col xs={12}>
        <TableContainer>
          <Table
            name="manage-certifications"
            headers={headers}
            onSort={onSortChange}
          >
            {isLoading ? (
              <Row>
                <Data colSpan={headers.length}>
                  <Spinner />
                </Data>
              </Row>
            ) : grossRentCertRows.length > 0 ? (
              grossRentCertRows.map((row) => (
                <GrossRentRow
                  key={row.affordableQualificationId}
                  grCert={row}
                  handleRemoveRow={removeRow(row.affordableQualificationId)}
                  openUploadDocumentModal={openUploadDocumentModal}
                  openEditGRCertModal={openEditGRCertModal}
                  promptToaster={promptToaster}
                  selectedProperty={selectedProperty}
                  updateRow={updateRow}
                />
              ))
            ) : (
              <Row>
                <Data colSpan={headers.length}>
                  <FormattedMessage {...messages.noEntriesFound} />
                </Data>
              </Row>
            )}
          </Table>
        </TableContainer>
        {!isLoading && (
          <div>
            <p className="padleft5">
              <strong>*</strong>
              If the assistance details for the Gross Rent cert do not have a
              change from the prior cert, the column will list NC for No Change.
            </p>
            {grossRentCertRows.length > 0 &&
              grossRentCertRows.some(
                (gr) =>
                  pathOr(null, ['isCorrection'], gr) === 'Yes' &&
                  pathOr(null, ['correction'], gr) === null,
              ) && (
                <p className="padleft5">
                  <strong>**</strong> The correction information for the Gross
                  Rent certification will need to be manually entered by
                  selecting the “Edit Cert” action before you can download the
                  59a.
                </p>
              )}
            {grossRentCertRows.length > 0 &&
              grossRentCertRows.some(
                (gr) => pathOr(null, ['priorCertificationDate'], gr) === null,
              ) && (
                <p className="padleft5">
                  <strong>***</strong> A prior certification has not been
                  completed in Fortress. The prior certification information
                  will need to be manually entered by selecting the “Edit Cert”
                  action before you can download the 59a.
                </p>
              )}
          </div>
        )}
      </Col>
    </>
  );
}

export const mapStateToProps = ({
  app: { selectedProperty },
}: Object): Object => {
  return {
    selectedProperty,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  );
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ManageCertificationsContent));
