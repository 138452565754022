import {
  GET_FINAL_ACCOUNT_STATEMENT,
  GET_FINAL_ACCOUNT_STATEMENT_SUCCESS,
  GET_FINAL_ACCOUNT_STATEMENT_FAILURE
} from './constants';
import type { Action } from '../App/types';

export const initialState = {};

export default function finalAccountStatementReducer(
  state: Object = initialState,
  action: Action<*, *>
) {
  const { type, payload, error } = action;
  switch (type) {
    case GET_FINAL_ACCOUNT_STATEMENT: {
      return initialState;
    }
    case GET_FINAL_ACCOUNT_STATEMENT_SUCCESS: {
      return { ...state, payload };
    }
    case GET_FINAL_ACCOUNT_STATEMENT_FAILURE: {
      return { ...state, error, payload };
    }
    default:
      return state;
  }
}
