import * as ActionTypes from './constants';

import type { Action } from '../App/types';
import type { Prospect } from '../ProspectProfile/types';

export function getOneProspect(prospectId: string): Action<string> {
  return {
    type: ActionTypes.GET_ONE_PROSPECT,
    payload: prospectId
  };
}

export function getOneProspectSuccess(prospect: Prospect): Action<Prospect> {
  return {
    type: ActionTypes.GET_ONE_PROSPECT_SUCCESS,
    payload: prospect
  };
}

export function getOneProspectError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ONE_PROSPECT_ERROR,
    payload: error
  };
}

export function editHousehold(prospect: Prospect): Action<Prospect> {
  return {
    type: ActionTypes.EDIT_PROSPECT_HOUSEHOLD,
    payload: prospect
  };
}

export function editHouseholdSuccess(): Action<any> {
  return {
    type: ActionTypes.EDIT_PROSPECT_HOUSEHOLD_SUCCESS,
    payload: undefined
  };
}

export function editHouseholdError(error: Error): Action<Error> {
  return {
    type: ActionTypes.EDIT_PROSPECT_HOUSEHOLD_ERROR,
    payload: error
  };
}

export function clean(): Action<any> {
  return {
    type: ActionTypes.CLEAN,
    payload: undefined
  };
}
