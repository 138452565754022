import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { confirmable, createConfirmation } from 'react-confirm';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { contains, defaultTo, isNil, pathOr } from 'ramda';
import ErrorMessage from '../../components/ErrorMessage';
import moment from 'moment';
import {
  renderDateField,
  renderSelectField,
  validateDateIsNotFuture,
} from '../../utils/redux-form-helper';
import messages from './messages';
import { bindActionCreators } from 'redux';
import actions from 'redux-form/lib/actions';
import validate from './validate';
import { validateForm } from './utils';
import { TypeRanges } from './TypeRanges';
import WODCompletedForm, {
  DATE_CONTROL_OPTIONS as WOD_DATE_CONTROL_OPTIONS,
} from './WODCompletedForm';
import LeaseChangeOutForm from './LeaseChangeOutForm';
import ByDateControls from './ByDateControls';
import { LEASE_CHANGE_OUT_REF_ID, WOD_COMPLETED_REF_ID } from './constants';

type StateProps = {
  locale: any,
  startDate: string,
  endDate: string,
  startPeriod: string,
  endPeriod: string,
  scheduledPeriod: string,
  actualPeriod: string,
  futurePeriod: string,
  format: string,
  period: string,
  selectedProperty: string,
  organizationId: string,
};
type State = {
  reportWasUpdated: boolean,
};

type Props = {
  pristine: boolean,
  valid: boolean,
  show: boolean,
  error: Object,
  dismiss: Function,
  proceed: Function,
  intl: Object,
  store: Object,
  report: any,
  periods: Array<any>,
  startDate: any,
  endDate: any,
  startPeriod: string,
  endPeriod: string,
  scheduledPeriod: string,
  actualPeriod: string,
  futurePeriod: string,
  format: string,
  period: string,
  date: any,
  typeRange: string,
  organizationId: string,
  propertyId: string,
  selectedProperty: { id: string },
};

export class CreateReportModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      reportWasUpdated: false,
    };
  }

  getReport() {
    let startDate,
      endDate,
      period,
      date,
      startPeriod,
      endPeriod,
      scheduledPeriod,
      actualPeriod,
      futurePeriod,
      currentPeriod;

    if (this.props.typeRange === 'period') {
      period = this.props.period;
    }
    if (this.props.typeRange === 'range') {
      startDate = this.props.startDate;
      endDate = this.props.endDate;
    }
    if (this.props.typeRange === 'date') {
      date = this.props.date;
    }
    if (this.props.typeRange === 'periodRange') {
      startPeriod = this.props.startPeriod;
      endPeriod = this.props.endPeriod;
    }
    if (this.props.typeRange === 'scheduledAndActualPeriodRange') {
      scheduledPeriod = this.props.scheduledPeriod;
      actualPeriod = this.props.actualPeriod;
    }
    if (this.props.typeRange === 'currentAndFuturePeriodRange') {
      currentPeriod = moment().format('YYYYMM');
      futurePeriod = this.props.futurePeriod;
    }

    return {
      id: this.props.report.id,
      startDate: isNil(startDate)
        ? startDate
        : moment(startDate).format('MM-DD-YYYY'),
      endDate: isNil(endDate) ? endDate : moment(endDate).format('MM-DD-YYYY'),
      startPeriod,
      endPeriod,
      scheduledPeriod,
      actualPeriod,
      currentPeriod,
      futurePeriod,
      period,
      customDateField: this.props.customDateField,
      leaseType: this.props.leaseType,
      format: this.props.format,
      organizationId: this.props.organizationId,
      property: this.props.selectedProperty,
      report: this.props.report,
      date: isNil(date) ? date : moment(date).format('MM-DD-YYYY'),
    };
  }
  close = () => {
    this.props.dismiss();
  };
  saveAndClose = () => {
    const reportInformation = this.getReport();
    this.props.proceed({ ...reportInformation, saveAndClose: true });
  };

  /**
   * For scheduled periods, we want to show entries from the property's fiscal periods
   * (which are gonna pe in the past and until the current period),
   * PLUS 6 months worth of periods in the future. These future periods are not in the
   * table so we need to add them here
   */
  getScheduledPeriodOptions() {
    // Include current period just in case it's not in the DB yet
    const options = [...this.props.periods];
    [0, 1, 2, 3, 4, 5, 6].forEach((offset) => {
      const period = moment().add(offset, 'months').format('YYYYMM');
      if (!options.some((o) => o.value === period)) {
        const option = {
          value: period,
          text: period,
        };
        options.push(option);
      }
    });

    return options.sort((o1, o2) => (o1.value < o2.value ? 1 : -1));
  }

  /**
   * Option to select a Financial Period that is up to 24 months after current period
   */
  getFuturePeriodOptions() {
    const periodRange = Array.from(Array(24).keys());
    const options = periodRange.reduce((options, offset) => {
      const period = moment().add(offset, 'months').format('YYYYMM');
      if (!options.some((o) => o.value === period)) {
        const option = {
          value: period,
          text: period,
        };
        options.push(option);
      }
      return options;
    }, this.props.periods.slice(0, 1));

    return options.sort((o1, o2) => (o1.value > o2.value ? 1 : -1));
  }

  renderFormByTypeRange = () => {
    const { report, store } = this.props;
    const currentFinancialPeriod = moment().format('YYYYMM');
    const isValidDate = report.runByNonFutureDate
      ? validateDateIsNotFuture
      : null;

    return (
      <form>
        <TypeRanges store={this.props.store} report={this.props.report} />
        {this.props.typeRange === 'date' && (
          <Row className={'padtop5'} key="run-by-one-date-2">
            <Col className="col-md-5 col-xs-6">
              <Field
                store={this.props.store}
                name="date"
                component={renderDateField}
                classPicker="modal-fixed-position"
                bsSize="lg"
                disabled={this.props.typeRange !== 'date'}
                isValidDate={isValidDate}
              />
            </Col>
          </Row>
        )}
        {this.props.typeRange === 'range' && (
          <ByDateControls
            report={report}
            store={store}
            typeRange={this.props.typeRange}
          />
        )}
        {this.props.typeRange === 'period' && (
          <Row className={'padtop5'}>
            <Col className="col-md-10 col-xs-12">
              <Field
                store={this.props.store}
                name="period"
                bsSize="lg"
                component={renderSelectField}
                options={this.props.periods}
                disabled={this.props.typeRange !== 'period'}
              />
            </Col>
          </Row>
        )}
        {report.runByPeriodRange && (
          <>
            <Row>
              <label> Period Range</label>
            </Row>
            <Row className={'padtop5'}>
              <Col className="col-md-5 col-xs-6 has-range-date has-range-nolabel">
                <Field
                  store={this.props.store}
                  name="startPeriod"
                  component={renderSelectField}
                  options={this.props.periods}
                  bsSize="lg"
                  disabled={this.props.typeRange !== 'periodRange'}
                />
              </Col>
              <Col className="col-md-5 col-xs-6">
                <Field
                  store={this.props.store}
                  name="endPeriod"
                  component={renderSelectField}
                  options={this.props.periods}
                  bsSize="lg"
                  disabled={this.props.typeRange !== 'periodRange'}
                />
              </Col>
            </Row>
          </>
        )}

        {report.runByScheduledAndActualPeriod && [
          <Row className={'padtop5'} key="run-by-scheduled-and-actual-period">
            <Col className="col-md-5 col-xs-6">
              <label>
                {this.props.intl.formatMessage(
                  messages.scheduledChargesPeriodLabel,
                )}
              </label>
              <Field
                store={this.props.store}
                name="scheduledPeriod"
                component={renderSelectField}
                options={this.getScheduledPeriodOptions()}
                bsSize="lg"
              />
            </Col>
            <Col className="col-md-5 col-xs-6">
              <label>
                {this.props.intl.formatMessage(
                  messages.actualChargesPeriodLabel,
                )}
              </label>
              <Field
                store={this.props.store}
                name="actualPeriod"
                component={renderSelectField}
                options={this.props.periods}
                bsSize="lg"
              />
            </Col>
          </Row>,
        ]}

        {report.runByCurrentAndFuturePeriod && (
          <>
            <Row className="padleft5">
              <label> Period Range</label>
            </Row>
            <Row className={'padtop5'} key="run-by-current-and-future-period">
              <Col className="col-md-5 col-xs-6 has-range-date has-range-nolabel">
                <Field
                  store={this.props.store}
                  name="currentPeriod"
                  component={renderSelectField}
                  options={[
                    {
                      value: currentFinancialPeriod,
                      text: currentFinancialPeriod,
                    },
                  ]}
                  bsSize="lg"
                  disabled={true}
                />
              </Col>
              <Col className="col-md-5 col-xs-6">
                <Field
                  store={this.props.store}
                  name="futurePeriod"
                  component={renderSelectField}
                  options={this.getFuturePeriodOptions()}
                  bsSize="lg"
                />
              </Col>
            </Row>
          </>
        )}
      </form>
    );
  };

  renderForm = () => {
    const { report, store } = this.props;

    if (report.referenceId === WOD_COMPLETED_REF_ID)
      return <WODCompletedForm store={store} />;

    if (report.referenceId === LEASE_CHANGE_OUT_REF_ID)
      return <LeaseChangeOutForm store={store} report={report} />;

    return this.renderFormByTypeRange();
  };

  render() {
    const { pristine, valid, report, format } = this.props;
    let typeOptions = [
      { value: '', text: 'Choose', disabled: true },
      { value: 'csv', text: 'CSV', disabled: false },
      { value: 'pdf', text: 'PDF', disabled: false },
    ];
    if (format === 'zip-xml' || format === 'scheduleA') {
      typeOptions = [];
    }
    if (
      (report.referenceId === 'Monthly-Summary-Transactions' ||
        report.referenceId === 'Scheduled-Lease-Charges' ||
        report.referenceId === 'Recent-Payment' ||
        report.referenceId === 'Resident-Ledgers-Customer-Ops') &&
      format !== 'zip-xml'
    ) {
      var { pdfTypeFound, index } = this.findInJson(typeOptions, 'pdf');
      if (pdfTypeFound) {
        typeOptions = typeOptions.slice(0, index);
      }
    }

    return (
      <form>
        <Modal
          backdrop
          bsSize="small"
          show={this.props.show}
          onHide={this.close}
        >
          <Modal.Header closeButton>
            <i className="et-pencil" />
            <Modal.Title componentClass="h1">
              {this.props.intl.formatMessage(messages.header)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.error && (
              <Row>
                <ErrorMessage message={this.props.error.message} />
              </Row>
            )}
            {report && (
              <Row>
                <div className="container-fluid">
                  <form>
                    {this.renderForm()}
                    {typeOptions && typeOptions.length > 0 ? (
                      <Row>
                        <div className="col-md-12 col-xs-12">
                          <div className="form-group">
                            <label>Format</label>
                            <Field
                              store={this.props.store}
                              name="format"
                              component={renderSelectField}
                              bsSize="lg"
                              options={typeOptions}
                            />
                          </div>
                        </div>
                      </Row>
                    ) : null}
                  </form>
                </div>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col xs={6}>
                <Button
                  bsStyle="default"
                  className="pull-right"
                  onClick={this.close}
                >
                  Close
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  bsStyle="primary"
                  className="pull-left"
                  onClick={this.saveAndClose}
                  disabled={
                    pristine || !valid || !validateForm(report, this.props)
                  }
                >
                  Create Report
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </form>
    );
  }

  findInJson(typeOptions: any, searchValue: string) {
    var index = -1;
    var objectToFind = searchValue;
    var pdfTypeFound = typeOptions.find((item, i): number => {
      if (item.value === objectToFind) {
        index = i;
        return i;
      }
      return -1;
    });
    return { pdfTypeFound, index };
  }
}

let InjectedCreateReportModal = reduxForm({
  form: 'createReport',
  touchOnChange: true,
  validate: validate,
  shouldAsyncValidate: (params) => {
    if (!params.syncValidationPasses) {
      return false;
    }

    if (params.trigger === 'change') {
      return false;
    }

    return contains(params.blurredField, [
      'startDate',
      'endDate',
      'startPeriod',
      'endPeriod',
      'format',
      'period',
      'typeRange',
      'date',
    ]);
  },
})(CreateReportModal);

const selector = formValueSelector('createReport');

const mapStateToProps = (state): StateProps => {
  const defaultToDefault = defaultTo('default');
  if (state.app.selectedProperty) {
    const wodCompletedTypeRange = selector(state, 'wodCompletedTypeRange');
    return {
      locale: state.languageProvider.locale,
      customDateField:
        wodCompletedTypeRange === WOD_DATE_CONTROL_OPTIONS.COMPLETED_DATE.value
          ? 'finishedDate'
          : undefined,
      leaseType: selector(state, 'leaseType'),
      startDate: selector(state, 'startDate'),
      endDate: selector(state, 'endDate'),
      startPeriod: selector(state, 'startPeriod'),
      endPeriod: selector(state, 'endPeriod'),
      scheduledPeriod: selector(state, 'scheduledPeriod'),
      actualPeriod: selector(state, 'actualPeriod'),
      futurePeriod: selector(state, 'futurePeriod'),
      format: defaultToDefault(selector(state, 'format')),
      period: defaultToDefault(selector(state, 'period')),
      typeRange: selector(state, 'typeRange'),
      date: selector(state, 'date'),
      selectedProperty: state.app.selectedProperty,
      organizationId: state.app.currentUser
        ? state.app.currentUser.user.organizationId
        : '',
    };
  }
  throw new Error('A property must be selected.');
};

export const mapDispatchToProps = (dispatch: any): Object => {
  const bindedActions = bindActionCreators(
    { updateSyncWarnings: actions.updateSyncWarnings },
    dispatch,
  );
  return { actions: bindedActions };
};

InjectedCreateReportModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedCreateReportModal);

InjectedCreateReportModal = confirmable(InjectedCreateReportModal);

const dialog = createConfirmation(InjectedCreateReportModal);

export const createReport = (
  store: any,
  intl: Object,
  report: any,
  periods: Array<any>,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    let typeRange = '';
    let scheduledPeriod = '';
    if (report.runByPeriod) {
      typeRange = 'period';
    } else if (report.runByDate) {
      typeRange = 'range';
    } else if (report.runByOneDate) {
      typeRange = 'date';
    } else if (report.runByPeriodRange) {
      typeRange = 'periodRange';
    } else if (report.runByScheduledAndActualPeriod) {
      typeRange = 'scheduledAndActualPeriodRange';
      // By default, select next period
      scheduledPeriod = moment().add(1, 'months').format('YYYYMM');
    } else if (report.runByCurrentAndFuturePeriod) {
      typeRange = 'currentAndFuturePeriodRange';
    }
    const reportName = pathOr('', ['name'], report);
    // TODO: This is a hack to know if the report is XML type...
    // This should be a column on the Reports table...
    // Hate to leave it this tech debt, but its in the interest of deadlines
    // and the fact that we are going to be possibly refactoring reports in general
    const AFFORDABLE_XML_NAMES = [
      'Affordable XML',
      'Affordable State Report',
      'MINC Tenant Transactions',
    ];
    const isAffordableXML = AFFORDABLE_XML_NAMES.includes(reportName);
    const isScheduleA = ['Schedule A'].indexOf(reportName) !== -1;
    let format = null;
    if (isAffordableXML) {
      format = 'zip-xml';
    }
    if (isScheduleA) {
      format = 'scheduleA';
    }

    dialog({
      store,
      report,
      periods,
      intl,
      format,
      initialValues: {
        typeRange,
        format,
        scheduledPeriod,
        wodCompletedTypeRange: WOD_DATE_CONTROL_OPTIONS.CREATION_DATE.value,
      },
    }).then(
      (data) => resolve(data),
      (error) => reject(error),
    );
  });
};
