import { useMemo } from 'react';
import { useQuery } from 'react-query';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import UnitService from '../../services/unitService';
import PropertyFeeService from '../../services/propertyFeesService';
import { useEffect } from 'react';
import { toastr } from 'react-redux-toastr';

function mapUnitToOption(u: Object) {
  return {
    value: u.id,
    label: u.number,
  };
}

function useUnits() {
  const selectedProperty = useSelectedProperty();
  const { isLoading, data } = useQuery(
    ['units', selectedProperty?.id],
    async () => {
      const unitService = new UnitService();

      const data = await unitService.getAll(
        selectedProperty.id,
        selectedProperty.organizationId,
        true,
      );

      return data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  return { isLoading, data };
}

export function useUnitOptions() {
  const { isLoading, data } = useUnits();
  const unitOptions = useMemo(() => {
    if (isLoading) return [];
    return data.map(mapUnitToOption);
  }, [isLoading, data]);

  return { unitOptions, isLoading };
}

function useSelectedPropertyFee({ propertyFeeId, history }) {
  const selectedProperty = useSelectedProperty();
  const { isLoading: isPropertyFeeLoading, data: propertyFee } = useQuery(
    ['propertyFee', propertyFeeId],
    async () => {
      const pfService = new PropertyFeeService();

      const data = await pfService.getById(propertyFeeId, {
        includeUnits: true,
      });

      return data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (isPropertyFeeLoading) return;

    if (
      !propertyFee ||
      propertyFee.organizationId !== selectedProperty.organizationId
    ) {
      toastr.error('Error', 'Amenity not found.');
      history.push(`/property/${selectedProperty.id}/manage-amenities`);
      return;
    }

    if (propertyFee.feeType !== 'Amenity') {
      toastr.error('Error', 'The selected property fee is not an amenity.');
      history.push(`/property/${selectedProperty.id}/manage-amenities`);
      return;
    }

    if (propertyFee.propertyId !== selectedProperty.id) {
      toastr.error('Error', 'This amenity belongs to a different property.');
      history.push(`/property/${selectedProperty.id}/manage-amenities`);
      return;
    }
  }, [
    propertyFee,
    isPropertyFeeLoading,
    history,
    selectedProperty.id,
    selectedProperty.organizationId,
    selectedProperty.name,
  ]);

  return { isPropertyFeeLoading, propertyFee };
}

export function useEditAmenity({ propertyFeeId, history }) {
  const { isPropertyFeeLoading, propertyFee } = useSelectedPropertyFee({
    propertyFeeId,
    history,
  });
  const { isLoading: isUnitOptionsLoading, unitOptions } = useUnitOptions();
  const initialSelectedUnitIds = useMemo(() => {
    if (!propertyFee) return [];
    return (propertyFee?.units ?? []).reduce((acc, u) => {
      if (u?.id) acc.push(u.id);
      return acc;
    }, []);
  }, [propertyFee]);

  return {
    isPageLoading: isPropertyFeeLoading,
    amenityFormProps: {
      isUnitOptionsReady: !isUnitOptionsLoading,
      unitOptions,
      initialSelectedUnitIds,
      initialValues: {
        amenityName: propertyFee?.displayNameOnQuote,
        feeAmount: propertyFee?.feeAmount,
      },
      history,
    },
  };
}
