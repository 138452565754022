import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.CreateWorkOrder.AfterService.Title',
    defaultMessage: 'To Be Completed After Service',
  },
  status: {
    id: 'App.CreateWorkOrder.AfterService.Status',
    defaultMessage: 'Status',
  },
  statusChangeDate: {
    id: 'App.CreateWorkOrder.AfterService.StatusChangeDate',
    defaultMessage: 'Status Change Date',
  },
  finishedDate: {
    id: 'App.CreateWorkOrder.AfterService.FinishedDate',
    defaultMessage: 'Finished Date',
  },
  afterServiceNote: {
    id: 'App.CreateWorkOrder.AfterService.AfterServiceNote',
    defaultMessage: 'After Service Note from Tech',
  },
  vendorNotes: {
    id: 'App.CreateWorkOrder.AfterService.VendorNotes',
    defaultMessage: 'Third Party Vendor Notes',
  },
  reason: {
    id: 'App.CreateWorkOrder.AfterService.reason',
    defaultMessage: 'Reason*',
  },
  photos: {
    id: 'App.CreateWorkOrder.AfterService.photos',
    defaultMessage: 'Photos After Service',
  },
  photosUploaded: {
    id: 'App.CreateWorkOrder.AfterService.photosUploaded',
    defaultMessage: '{current} of {max} photos uploaded',
  },
  uploadPhotosButton: {
    id: 'App.CreateWorkOrder.AfterService.uploadPhotosButton',
    defaultMessage: 'Upload Photos',
  },
  uploadError: {
    id: 'App.CreateWorkOrder.AfterService.uploadError',
    defaultMessage: 'An error occurred with your upload. Check the following:',
  },
  errorFormat: {
    id: 'App.CreateWorkOrder.AfterService.errorFormat',
    defaultMessage: 'Format must be .jpeg, .jpg, or .png',
  },
  errorSize: {
    id: 'App.CreateWorkOrder.AfterService.errorSize',
    defaultMessage: 'Image cannot exceed 10MB',
  },
  errorMaxImages: {
    id: 'App.CreateWorkOrder.AfterService.errorMaxImages',
    defaultMessage: 'Maximum of five images allowed',
  },
  deleteConfirmation: {
    id: 'App.CreateWorkOrder.AfterService.deleteConfirmation',
    defaultMessage:
      'Are you sure you want to delete the image from the work order?',
  },
});

export default messages;
