import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  getNextClosestAvailableDate,
  disableUnAvailableDates,
  calculateFinalMaxDate,
} from './utils';
import { UseManageFields } from './types';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils';

const ERROR_MESSAGES = {
  disablePast: 'Date cannot be in the past',
  minDate: 'End Date must be after Start Date',
  shouldDisableDate: 'Date is not available',
};
const useManageFields = ({
  actualOrAnticipatedMoveOutDate,
  allPropertyFeesWithRentableItemsData,
  clearErrors,
  editMode,
  errors,
  fields,
  selectedEndDate,
  selectedHouseholdRentableItemData,
  selectedPropertyFeeWithRentableItem,
  selectedPropertyItemOfPropertyFeeWithRentableItem,
  selectedStartDate,
  setError,
}: UseManageFields) => {
  const [currentEditingIndex, setCurrentEditingIndex] = useState();
  const [editingUnavailableDates, setEditingUnavailableDates] = useState([]);

  const onError = useCallback(
    (field, error) => {
      if (error?.length) {
        setError(field.name, {
          type: 'error',
          message: ERROR_MESSAGES[error],
        });
      } else {
        clearErrors(field.name);
      }
    },
    [clearErrors, setError],
  );

  // Set editing unavailable dates when selectedHouseholdRentableItemData changes
  useEffect(() => {
    const { startDate, endDate } = selectedHouseholdRentableItemData || {};
    setEditingUnavailableDates([startDate, endDate]);
  }, [selectedHouseholdRentableItemData]);

  // Set current editing index when editingUnavailableDates changes
  useEffect(() => {
    const index =
      selectedPropertyItemOfPropertyFeeWithRentableItem?.unavailableDates?.findIndex(
        (date) => {
          const [startDateString, endDateString] = date;
          const editingStartDateString = editingUnavailableDates[0]
            ? formatDateDB(editingUnavailableDates[0])
            : null;
          const editingEndDateString = editingUnavailableDates[1]
            ? formatDateDB(editingUnavailableDates[1])
            : null;

          const formattedStartDateString = startDateString
            ? formatDateDB(startDateString)
            : null;
          const formattedEndDateString = endDateString
            ? formatDateDB(endDateString)
            : null;

          const endDateNull = endDateString === null;

          return formattedStartDateString === editingStartDateString &&
            endDateNull
            ? endDateString === editingEndDateString
            : formattedEndDateString === editingEndDateString;
        },
      );

    setCurrentEditingIndex(index);
  }, [
    editingUnavailableDates,
    selectedPropertyItemOfPropertyFeeWithRentableItem?.unavailableDates,
  ]);

  const mappedFieldsWithOptions = useMemo(() => {
    return [...fields].map((field) => {
      if (field.name === 'type') {
        return {
          ...field,
          options: allPropertyFeesWithRentableItemsData?.map((item) => ({
            text: item.displayNameOnQuote,
            value: item.id,
          })),
        };
      }

      if (field.name === 'numberId') {
        return {
          ...field,
          watchFields: ['type'],
          options:
            selectedPropertyFeeWithRentableItem?.propertyRentableItems?.map(
              (item) => ({
                text: item.numberId,
                value: item.id,
              }),
            ),
        };
      }

      if (['startDate', 'endDate'].includes(field.name)) {
        return {
          ...field,
          disablePast: false,
          watchFields:
            field.name === 'startDate' ? ['numberId'] : ['startDate'],
        };
      }

      return field;
    });
  }, [
    fields,
    allPropertyFeesWithRentableItemsData,
    selectedPropertyFeeWithRentableItem?.propertyRentableItems,
  ]);

  const unAvailableDates = useMemo(() => {
    return selectedPropertyItemOfPropertyFeeWithRentableItem?.unavailableDates?.map(
      (date, index) => {
        if (index === currentEditingIndex) {
          return [selectedStartDate, selectedEndDate];
        }
        return date;
      },
    );
  }, [
    currentEditingIndex,
    selectedEndDate,
    selectedPropertyItemOfPropertyFeeWithRentableItem?.unavailableDates,
    selectedStartDate,
  ]);

  const currentEditingMaxEndDate = useMemo(() => {
    return getNextClosestAvailableDate(
      unAvailableDates,
      selectedStartDate,
      currentEditingIndex,
    );
  }, [currentEditingIndex, selectedStartDate, unAvailableDates]);

  const mappedFieldsWithErrors = useMemo(() => {
    return Object.keys(errors)?.length
      ? mappedFieldsWithOptions.map((field) => {
          const error = errors[field.name];
          if (error && error?.message) {
            return {
              ...field,
              error: Boolean(error),
              helperText: error.message,
            };
          }

          return field;
        })
      : mappedFieldsWithOptions;
  }, [errors, mappedFieldsWithOptions]);

  // Re-map fields when state changes
  return useMemo(() => {
    return mappedFieldsWithErrors.map((field) => {
      let fieldAdditionalProps = {};
      // Set disabled dates for start and end date
      if (field.name === 'startDate' || field.name === 'endDate') {
        if (!field.onError) {
          fieldAdditionalProps = {
            onError: (error) => onError(field, error),
          };
        }

        if (
          unAvailableDates?.length ||
          actualOrAnticipatedMoveOutDate?.length
        ) {
          fieldAdditionalProps = {
            ...fieldAdditionalProps,
            shouldDisableDate: (day) =>
              disableUnAvailableDates({
                unAvailableDates,
                actualOrAnticipatedMoveOutDate,
                editMode,
                currentEditingIndex,
                currentEditingMaxEndDate,
                isEndDateField: field.name === 'endDate',
                day,
              }),
          };

          if (field.name === 'endDate' && selectedStartDate) {
            const finalMaxDate = calculateFinalMaxDate({
              unAvailableDates,
              currentEditingMaxEndDate,
              editMode,
              selectedStartDate,
            });

            if (finalMaxDate) {
              fieldAdditionalProps = {
                ...fieldAdditionalProps,
                maxDate: moment(finalMaxDate),
                rules: {
                  required: true,
                },
              };
            }
          }
        }

        // Set min and max date for end date
        if (field.name === 'endDate' && selectedStartDate) {
          fieldAdditionalProps = {
            ...fieldAdditionalProps,
            minDate: moment(selectedStartDate),
          };
        }
      }

      // Disable type and numberId fields when in edit mode
      if (editMode && (field.name === 'type' || field.name === 'numberId')) {
        fieldAdditionalProps = {
          ...fieldAdditionalProps,
          disabled: true,
        };
      }

      return {
        ...field,
        ...fieldAdditionalProps,
      };
    });
  }, [
    actualOrAnticipatedMoveOutDate,
    currentEditingIndex,
    currentEditingMaxEndDate,
    editMode,
    mappedFieldsWithErrors,
    onError,
    selectedStartDate,
    unAvailableDates,
  ]);
};

export default useManageFields;
