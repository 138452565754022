import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import {
  renderRadioGroupField,
  renderTextField,
} from '../../../utils/redux-form-helper';

import { studentsTypes } from '../configuration';

import messages from './messages';

const booleanOptions = [
  { text: 'Yes', value: 'true', disabled: false },
  { text: 'No', value: 'false', disabled: false },
];

class StudentInformationSection extends Component<any, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isFullTimeStudent: false,
    };
  }
  componentDidMount() {
    const { initialValues } = this.props;
    const isFullTimeStudent =
      initialValues?.studentType?.substring(0, 3) === 'f_t';
    this.setState({
      ...this.state,
      isFullTimeStudent,
    });
  }

  render() {
    const { intl, changeCallback } = this.props;
    const { isFullTimeStudent } = this.state;
    return (
      <div className="form-content">
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={4}>
                <label className="required control-label">
                  {intl.formatMessage(messages.currentStudent)}
                </label>
                <Field
                  name="isStudent"
                  fieldName="isStudent"
                  options={booleanOptions}
                  component={renderRadioGroupField}
                  onClick={(e) => {
                    if (e.target.value === 'false') {
                      this.setState({
                        ...this.state,
                        isFullTimeStudent: false,
                      });
                      changeCallback('studentInformation.studentType', null);
                      changeCallback('studentInformation.TANFAssistance', null);
                      changeCallback(
                        'studentInformation.jobTrainingProgram',
                        null,
                      );
                      changeCallback(
                        'studentInformation.singleParentDependantChild',
                        null,
                      );
                      changeCallback(
                        'studentInformation.marriedJointReturn',
                        null,
                      );
                      changeCallback('studentInformation.fosterProgram', null);
                      changeCallback(
                        'studentInformation.otherStudentStatus',
                        null,
                      );
                    }
                  }}
                  inline
                />
              </Col>
              <Col xs={8}>
                <p>{intl.formatMessage(messages.studentType)}</p>
                <Field
                  ref="studentType"
                  name="studentType"
                  fieldName="studentType"
                  options={studentsTypes}
                  component={renderRadioGroupField}
                  onClick={(e) => {
                    changeCallback('studentInformation.isStudent', 'true');
                    if (e.target.value === 'p_t') {
                      this.setState({
                        ...this.state,
                        isFullTimeStudent: false,
                      });
                      changeCallback('studentInformation.TANFAssistance', null);
                      changeCallback(
                        'studentInformation.jobTrainingProgram',
                        null,
                      );
                      changeCallback(
                        'studentInformation.singleParentDependantChild',
                        null,
                      );
                      changeCallback(
                        'studentInformation.marriedJointReturn',
                        null,
                      );
                      changeCallback('studentInformation.fosterProgram', null);
                      changeCallback(
                        'studentInformation.otherStudentStatus',
                        null,
                      );
                    } else {
                      this.setState({ ...this.state, isFullTimeStudent: true });
                    }
                  }}
                  inline
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xsOffset={4} xs={8}>
            <p>{intl.formatMessage(messages.studentStatus)}</p>
            <Row>
              <Col xs={12} sm={4}>
                {intl.formatMessage(messages.TANFAssistance)}
              </Col>
              <Col xs={12} sm={8}>
                <Field
                  name="TANFAssistance"
                  fieldName="TANFAssistance"
                  options={booleanOptions}
                  component={renderRadioGroupField}
                  inline
                  disabled={!isFullTimeStudent}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4}>
                {intl.formatMessage(messages.jobTrainingProgram)}
              </Col>
              <Col xs={12} sm={8}>
                <Field
                  name="jobTrainingProgram"
                  fieldName="jobTrainingProgram"
                  options={booleanOptions}
                  component={renderRadioGroupField}
                  inline
                  disabled={!isFullTimeStudent}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4}>
                {intl.formatMessage(messages.singleParentDependantChild)}
              </Col>
              <Col xs={12} sm={8}>
                <Field
                  name="singleParentDependantChild"
                  fieldName="singleParentDependantChild"
                  options={booleanOptions}
                  component={renderRadioGroupField}
                  inline
                  disabled={!isFullTimeStudent}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4}>
                {intl.formatMessage(messages.marriedJointReturn)}
              </Col>
              <Col xs={12} sm={8}>
                <Field
                  name="marriedJointReturn"
                  fieldName="marriedJointReturn"
                  options={booleanOptions}
                  component={renderRadioGroupField}
                  inline
                  disabled={!isFullTimeStudent}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4}>
                {intl.formatMessage(messages.fosterProgram)}
              </Col>
              <Col xs={12} sm={8}>
                <Field
                  name="fosterProgram"
                  fieldName="fosterProgram"
                  options={booleanOptions}
                  component={renderRadioGroupField}
                  inline
                  disabled={!isFullTimeStudent}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4}>
                {intl.formatMessage(messages.other)}
              </Col>
              <Col xs={12} sm={4}>
                <Field
                  name="otherStudentStatus"
                  component={renderTextField}
                  placeholder={intl.formatMessage(
                    messages.otherStudentStatusPlaceholder,
                  )}
                  disabled={!isFullTimeStudent}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StudentInformationSection;
