import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { bindActionCreators } from 'redux';
import {
  curryN,
  equals,
  find,
  is,
  keys,
  length,
  not,
  pathOr,
  pickBy,
  propEq,
} from 'ramda';

import FilterDrawer from '../../components/FilterDrawer';
import {
  cleanLoadedWorkOrders,
  getAllWorkOrders,
  getAllWorkOrdersError,
  getAllWorkOrdersSuccess,
  updateColumnsSortValue,
  updateWorkOrder,
  updateWorkOrdersQueryParams,
} from './actions.js';
import {
  getAllPriorityLevels,
  getAllWorkOrderStatus,
  getWorkOrderAssignees,
} from '../App/actions.js';
import ColumnHeader from '../../components/Common/columnHeader.js';
import PaginationFooter from '../../components/PaginationFooter/index.js';
import WorkOrderDetails from './WorkOrderDetails/index.js';
import type {
  FilterValue,
  OrderValue,
  PaginationMeta,
  Property,
  WorkOrder,
} from '../App/types.js';
import type { ManageWorkOrdersColumnOrder } from './types';
import messages from './messages.js';
import {
  getAssignees,
  getDropDownAssignees,
  getPriorityLevels,
  getWorkOrderStatusList,
} from './selectors.js';
import ElementWithPermissions from '../../components/ElementWithPermissions';
import SearchActions from '../../components/SearchActions';
import moment from 'moment';
import * as utils from './utils';
import * as dateHelpers from '../../utils/date-helpers';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import { table as tableColors } from '@fortress-technology-solutions/fortress-component-library/design';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

type SearchFormValues = {
  searchText: string,
};

type StateProps = {
  workOrders: Array<WorkOrder>,
  workOrderAssignees: Array<Object>,
  dropDownAssignees: Array<Object>,
  workOrderStatusList: Array<Object>,
  priorityLevels: Array<Object>,
  meta: PaginationMeta,
  locale: string,
  selectedProperty: string,
  organizationId: string,
  property: Property,
};

type Props = {
  actions: Object,
  flags: { workOrderDueDateManageScreen: boolean },
  intl: any,
  history: Object,
  columnOrder: ManageWorkOrdersColumnOrder,
  workOrderStatusList: Array<Object>,
};

type State = {
  currentPage: number,
  limit: number,
  showFilter: boolean,
  currentFilter: FilterValue,
  searchText: string,
  currentSorting: OrderValue,
  dateErrors: {
    dateFromError: string,
    dateToError: string,
  },
};

export class ManageWorkOrders extends React.Component<
  StateProps & Props,
  State,
> {
  constructor(props: StateProps & Props) {
    super();
    this.state = {
      currentPage: 1,
      limit: 25,
      showFilter: false,
      searchText: '',
      useDefaultFilter: true,
      currentFilter: {},
      currentSorting: {
        fieldName: '',
        order: '',
      },
      dateErrors: {
        dateFromError: '',
        dateToError: '',
      },
    };
  }
  componentWillMount() {
    this.props.actions.cleanLoadedWorkOrders();
    window.document.addEventListener('keydown', this._handleEscKey);
  }

  componentWillUnmount() {
    window.document.removeEventListener('keydown', this._handleEscKey);
  }

  componentDidMount() {
    this.props.actions.getWorkOrderAssignees();
    this.props.actions.getAllPriorityLevels();
    this.props.actions.getAllWorkOrderStatus();
    this.onPageChange(this.state.currentPage);
  }

  componentDidUpdate(prevProps: Props, prevState) {
    /**
     * The backend defaults to only returning open and held work orders if no options are given, so apply those filters
     */
    const { state, props } = this;
    if (not(equals(props.workOrderStatusList, prevProps.workOrderStatusList))) {
      const initialStatusesArray = props.workOrderStatusList.filter(
        (status) => status.isDefaultFilter,
      );
      const initialStatuses = {};
      for (let i = 0; i < initialStatusesArray.length; i++) {
        initialStatuses[`${initialStatusesArray[i].value}`] = true;
      }
      const currentFilter = {
        status: { ...initialStatuses },
      };
      this.setState({ currentFilter, useDefaultFilter: false });
    }

    // This syncs the component filter/sorting state with the redux state. Which is used for queries
    if (
      !equals(state.currentFilter, prevState.currentFilter) ||
      !equals(state.currentSorting, prevState.currentSorting) ||
      !equals(state.searchText, prevState.searchText)
    ) {
      this.props.actions.updateWorkOrdersQueryParams({
        currentFilter: state.currentFilter,
        currentSorting: state.currentSorting,
        searchText: state.searchText,
      });
    }
  }

  handleEditWorkOrderClick = (id: string) => {
    navigateToUrlWithSelectedPropertyId(`/edit-work-order/${id}`);
  };

  onNewWorkOrderClick = () => {
    navigateToUrlWithSelectedPropertyId('/add-work-order');
  };

  onPageChange = (nextPage: number) => {
    const limit = window.matchMedia('(min-width: 767px)').matches ? 25 : 10;
    this.props.actions.getAllWorkOrders(
      nextPage,
      limit,
      this.state.currentFilter,
      this.state.currentSorting,
      this.state.searchText,
      this.state.useDefaultFilter,
    );
    this.setState({
      currentPage: nextPage,
      limit: limit,
    });
  };

  onDropdownChange = (
    field: string,
    workOrder: WorkOrder,
    { target: { value } }: Object,
  ) => {
    const workOrderToUpdate = {
      detailId: workOrder.detailId,
      [field]: value,
      propertyId: workOrder.propertyId,
    };
    this.props.actions.updateWorkOrder(
      workOrderToUpdate,
      this.state.currentPage,
      this.state.limit,
    );
  };

  onFilterChange = (
    field: string,
    value: string,
    { target: { checked } }: Object,
  ) => {
    const currentFilter = this.state.currentFilter;
    const filteredField = currentFilter[field] || {};
    filteredField[value] = checked;
    currentFilter[field] = filteredField;
    this.setState({
      currentFilter,
      useDefaultFilter: false,
    });
  };

  onDateFilterChange = (field: string) => {
    return (date: Object) => {
      const currentFilter = {
        ...this.state.currentFilter,
        [field]: date,
      };
      this.setState({ currentFilter, useDefaultFilter: false });
      this.validateDateFormat(field, date);
      this.validateDateRange(field, date);
    };
  };

  validateDateFormat = (field: string, date: Object) => {
    const dateErrors = dateHelpers.validateDateFormat({ field, date });
    if (Object.keys(dateErrors).length) {
      this.setState({
        dateErrors: { ...this.state.dateErrors, ...dateErrors },
      });
    }
  };

  validateDateRange = (field: string, date: Object) => {
    const { dateFrom, dateTo } = this.state.currentFilter;

    const { datesFormatted, fromAfterTo } = dateHelpers.validateDateRange({
      field,
      date,
      dateTo,
      dateFrom,
    });

    if (datesFormatted && fromAfterTo) {
      this.setState({
        dateErrors: {
          ...this.state.dateErrors,
          dateToError: 'Date must be after the From field',
        },
      });
    } else if (datesFormatted) {
      this.setState({
        dateErrors: {
          ...this.state.dateErrors,
          dateToError: '',
        },
      });
    }
  };

  toggleFilter = (show: boolean) => {
    this.setState({
      showFilter: show,
    });
  };

  onApplyFilterClick = () => {
    const dateTo = pathOr('', ['state', 'currentFilter', 'dateTo'], this);
    const filter = {
      ...this.state.currentFilter,
    };
    if (dateTo) {
      filter.dateTo = moment(dateTo).add(1, 'day');
    }
    this.props.actions.getAllWorkOrders(
      1,
      this.state.limit,
      filter,
      this.state.currentSorting,
      this.state.searchText,
      false,
    );

    this.setState({
      currentPage: 1,
      showFilter: false,
    });
  };

  clearFilters = () => {
    this.setState({
      currentPage: 1,
      currentFilter: {},
      dateErrors: {
        dateFromError: '',
        dateToError: '',
      },
      useDefaultFilter: false,
    });
  };

  hasFilter = (fieldName: string) => {
    const filterValue = this.state.currentFilter[fieldName] || {};
    const trueProps = pickBy((val) => val === true, filterValue);
    return length(keys(trueProps)) > 0;
  };

  hasAnyFilter() {
    const { currentFilter } = this.state;
    const filters = is(Object, currentFilter) ? currentFilter : {};
    let flattenedFilters = {};
    Object.values(filters).forEach((filter) =>
      Object.assign(flattenedFilters, filter),
    );
    const trueProps = pickBy((val) => val === true, flattenedFilters);
    return length(keys(trueProps)) > 0;
  }

  generateFilters() {
    return [
      {
        fieldName: 'priorityLevel',
        fieldDescription: 'Priority Level',
        options: this.props.priorityLevels,
      },
      {
        fieldName: 'status',
        fieldDescription: 'Status',
        options: this.props.workOrderStatusList,
      },
      {
        fieldName: 'assignedTo',
        fieldDescription: 'Assigned to',
        options: this.props.workOrderAssignees,
      },
    ];
  }

  handleSearchSubmit = ({ searchText = '' }: SearchFormValues) => {
    const {
      actions: { getAllWorkOrders },
    } = this.props;
    getAllWorkOrders(
      1,
      this.state.limit,
      this.state.currentFilter,
      {
        fieldName: '',
        order: '',
      },
      searchText,
    );
    this.setState({
      searchText,
      currentPage: 1,
      showFilter: false,
    });
  };

  getOrder = (fieldName: string) => {
    return fieldName === this.state.currentSorting.fieldName
      ? this.state.currentSorting.order
      : '';
  };

  onOrderClick = (field: string) => {
    const columnOrder = this.props.columnOrder[field];
    const order = columnOrder === 'ascending' ? 'DESC' : 'ASC';
    const icon =
      columnOrder === 'sortable'
        ? 'ascending'
        : columnOrder === 'ascending'
        ? 'descending'
        : 'ascending';
    this.props.actions.updateColumnsSortValue(field, icon);
    this.props.actions.getAllWorkOrders(
      this.state.currentPage,
      this.state.limit,
      this.state.currentFilter,
      {
        fieldName: field,
        order: order,
      },
      this.state.searchText,
    );
    this.setState({
      currentSorting: {
        fieldName: field,
        order: order,
      },
    });
  };

  _handleEscKey = (event: any) => {
    if (event.keyCode === 27) {
      this.toggleFilter(false);
    }
  };

  downloadCSV = async () => {
    const { state, props } = this;
    const { organizationId, property } = props;
    if (property?.id) {
      return utils.downloadCSV({
        pageNumber: null,
        limit: 100000,
        filterValue: state.currentFilter,
        sorting: state.currentSorting,
        searchText: state.searchText,
        organizationId,
        propertyId: property.id,
        hasAnyFilters: this.hasAnyFilter() || !!state.searchText.length,
      });
    }
  };

  render() {
    const {
      columnOrder,
      intl: { formatMessage },
      priorityLevels,
      dropDownAssignees,
      workOrderAssignees,
      workOrders,
      meta,
      flags,
    } = this.props;
    const onAssigneeChangeCurried = curryN(3, this.onDropdownChange);
    const onPriorityChangeCurried = curryN(3, this.onDropdownChange);
    const onOrderClickCurried = curryN(2, this.onOrderClick);
    const onFilterChangeCurried = curryN(3, this.onFilterChange);
    const filters = this.generateFilters();
    const totalAssignees = workOrderAssignees.reduce(
      (assignees, assignee) => {
        if (!find(propEq('value', assignee.value))(dropDownAssignees)) {
          assignee.status = 'Inactive';
          assignee.disabled = true;
          assignees.push(assignee);
        }
        return assignees;
      },
      [...dropDownAssignees],
    );
    const dateInfo = {
      onDateFilterChange: this.onDateFilterChange,
      errors: this.state.dateErrors,
    };

    const searchActionsProps = {
      filterButton: {
        hasActiveFilters: this.hasAnyFilter(),
        onClick: this.toggleFilter,
      },
      csvDownloadButton: {
        onClick: this.downloadCSV,
      },
      searchField: {
        form: 'searchWorkOrders',
        onSubmit: this.handleSearchSubmit,
        placeholder: formatMessage(messages.searchPlaceholder),
      },
    };
    return (
      <DocumentTitle
        data-test="manage-work-orders"
        title={formatMessage(messages.domTitle)}
      >
        <div className="bodywrap container-fluid">
          <FilterDrawer
            filters={filters}
            onFilterChange={onFilterChangeCurried}
            onCloseClick={this.toggleFilter}
            onApplyClick={this.onApplyFilterClick}
            show={this.state.showFilter}
            clearFilters={this.clearFilters}
            currentFilter={this.state.currentFilter}
            formatMessage={formatMessage}
            dateInfo={dateInfo}
          />
          <div className="row section-header">
            <div className="col-xs-12 col-md-4">
              <h1>
                <FormattedMessage {...messages.header} />
              </h1>
              <div className="legend">
                <span className="legend-color legend-color--inactive" />
                <span className="small">
                  {formatMessage(messages.inactiveAssigneeWarning)}
                </span>
                {flags?.workOrderDueDateManageScreen && (
                  <>
                    <span
                      className="legend-color"
                      style={{ backgroundColor: tableColors?.urgent }}
                    />
                    <span className="small">
                      {formatMessage(messages.pastDue)}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="col-xs-12 col-md-8">
              <SearchActions {...searchActionsProps} />
              <ElementWithPermissions scope={['workorder-create']}>
                <button
                  className="btn btn-shout"
                  onClick={this.onNewWorkOrderClick}
                >
                  {formatMessage(messages.createWorkOrder)}
                </button>
              </ElementWithPermissions>
            </div>
            <div className="container-fluid">
              <table className="table table-prospects table-striped">
                <thead className="table-header">
                  <tr>
                    <ColumnHeader
                      columnLabel={formatMessage(messages.id)}
                      sortable={true}
                      icon={columnOrder.id}
                      order={this.getOrder('id')}
                      onOrderClick={onOrderClickCurried('id')}
                    />
                    <ColumnHeader
                      columnLabel={formatMessage(messages.location)}
                    />
                    <ColumnHeader
                      columnLabel={formatMessage(messages.issueDescription)}
                      sortable={true}
                      icon={columnOrder.issueDescription}
                      order={this.getOrder('issueDescription')}
                      onOrderClick={onOrderClickCurried('issueDescription')}
                    />
                    <ColumnHeader
                      columnLabel={formatMessage(messages.creationDate)}
                      sortable={true}
                      icon={columnOrder.creationDate}
                      order={this.getOrder('creationDate')}
                      onOrderClick={onOrderClickCurried('creationDate')}
                    />
                    <ColumnHeader
                      columnLabel={formatMessage(messages.assignedTo)}
                      sortable={true}
                      icon={columnOrder.assignedTo}
                      hasFilter={this.hasFilter('assignedTo')}
                      order={this.getOrder('assignedTo')}
                      onOrderClick={onOrderClickCurried('assignedTo')}
                    />
                    <ColumnHeader
                      columnLabel={formatMessage(messages.priority)}
                      sortable={true}
                      icon={columnOrder.priorityLevel}
                      hasFilter={this.hasFilter('priorityLevel')}
                      order={this.getOrder('priorityLevel')}
                      onOrderClick={onOrderClickCurried('priorityLevel')}
                    />
                    <ColumnHeader
                      columnLabel={formatMessage(messages.status)}
                      sortable={true}
                      icon={columnOrder.status}
                      hasFilter={this.hasFilter('status')}
                      order={this.getOrder('status')}
                      onOrderClick={onOrderClickCurried('status')}
                    />
                    <ColumnHeader
                      columnLabel={formatMessage(messages.daysOpen)}
                      sortable={true}
                      icon={columnOrder.daysOpen}
                      order={this.getOrder('daysOpen')}
                      onOrderClick={onOrderClickCurried('daysOpen')}
                    />
                    {flags?.workOrderDueDateManageScreen && (
                      <ColumnHeader
                        columnLabel={formatMessage(messages.dueDate)}
                        sortable={true}
                        icon={columnOrder.dueDate}
                        order={this.getOrder('dueDate')}
                        onOrderClick={onOrderClickCurried('dueDate')}
                      />
                    )}
                    <ColumnHeader
                      columnLabel={formatMessage(messages.completionDate)}
                      sortable={true}
                      icon={columnOrder.finishedDate}
                      order={this.getOrder('finishedDate')}
                      onOrderClick={onOrderClickCurried('finishedDate')}
                    />
                    <ColumnHeader
                      columnLabel={formatMessage(messages.requestor)}
                      sortable={true}
                      icon={columnOrder.requestor}
                      order={this.getOrder('requestor')}
                      onOrderClick={onOrderClickCurried('requestor')}
                    />
                  </tr>
                </thead>
                <tbody>
                  {workOrders &&
                    workOrders.map((workOrder) => (
                      <WorkOrderDetails
                        key={workOrder.detailId}
                        flags={flags}
                        onClick={this.handleEditWorkOrderClick}
                        workOrder={workOrder}
                        dropDownAssignees={totalAssignees}
                        priorityLevels={priorityLevels}
                        onAssigneeChange={onAssigneeChangeCurried(
                          'assignedToId',
                        )}
                        onPriorityChange={onPriorityChangeCurried(
                          'priorityLevelId',
                        )}
                      />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          {meta && meta.pageCount > 1 && (
            <PaginationFooter
              currentPage={this.state.currentPage}
              limit={this.state.limit}
              count={this.props.meta.count}
              totalCount={this.props.meta.totalCount}
              pageCount={this.props.meta.pageCount}
              onPageChange={this.onPageChange}
            />
          )}
        </div>
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = ({
  app,
  manageWorkOrders: { workOrders, meta, columnOrder },
}: any): any => {
  return {
    workOrderAssignees: getAssignees(workOrders),
    dropDownAssignees: getDropDownAssignees(app),
    priorityLevels: getPriorityLevels(app),
    workOrderStatusList: getWorkOrderStatusList(app),
    workOrders,
    columnOrder,
    meta,
    organizationId: app.currentUser.user.organizationId,
    property: app.selectedProperty,
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(
      {
        updateWorkOrder,
        updateWorkOrdersQueryParams,
        getAllWorkOrders,
        getAllWorkOrdersSuccess,
        getAllWorkOrdersError,
        updateColumnsSortValue,
        cleanLoadedWorkOrders,
        getWorkOrderAssignees,
        getAllPriorityLevels,
        getAllWorkOrderStatus,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(withLDConsumer()(ManageWorkOrders)));
