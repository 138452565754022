import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import {
  renderTextField,
  renderCurrencyField,
} from '../../../../../utils/redux-form-helper';
import messages from './messages';

export const Concessions = ({ intl, isEditable }: Object) => (
  <React.Fragment>
    <Row className="padtop10 padbottom10">
      <Col xs={12}>
        <h2>
          <i className="et-money text-blue padright10" />
          Concessions
        </h2>
      </Col>
    </Row>
    <Row className="container-fluid">
      <form>
        <Col xs={12} sm={7} md={6} lg={7}>
          <Row>
            <Col xs={6} md={5} lg={6}>
              <label>
                {intl.formatMessage(messages.oneTimeRentConcessionLabel)}
              </label>
            </Col>
            <Col xs={6} md={7} lg={6}>
              <Row>
                <Col xs={4} md={12} lg={4}>
                  <b>{intl.formatMessage(messages.amountLabel)}: </b>
                </Col>
                <Col xs={8} md={12} lg={8}>
                  <Field
                    name="oneTimeRentConcession"
                    component={renderCurrencyField}
                    step="0.01"
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={5} lg={6}>
              <label>
                {intl.formatMessage(messages.monthlyRentConcessionLabel)}
              </label>
            </Col>
            <Col xs={6} md={7} lg={6}>
              <Row>
                <Col xs={4} md={12} lg={4}>
                  <b>{intl.formatMessage(messages.amountLabel)}: </b>
                </Col>
                <Col xs={8} md={12} lg={8}>
                  <Field
                    name="monthlyRentConcession"
                    component={renderCurrencyField}
                    step="0.01"
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={6} md={5} lg={6}>
              <label>
                {intl.formatMessage(messages.otherDiscountConcessionLabel)}
              </label>
            </Col>
            <Col xs={6} md={7} lg={6}>
              <Row>
                <Col xs={4} md={12} lg={4}>
                  <b>{intl.formatMessage(messages.amountLabel)}:</b>
                </Col>
                <Col xs={8} md={12} lg={8}>
                  <Field
                    name="otherDiscountConcession"
                    component={renderCurrencyField}
                    step="0.01"
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={5} md={6} lg={5}>
          <Row>
            <Col xs={4}>
              <label>{intl.formatMessage(messages.monthsLabel)} </label>
            </Col>
            <Col xs={8}>
              <div className="form-group">
                <Field
                  className="input-md form-control"
                  name="oneTimeRentMonth"
                  component={renderTextField}
                  maxLength="25"
                  disabled={!isEditable}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <label>{intl.formatMessage(messages.monthsLabel)}</label>
            </Col>
            <Col xs={8}>
              <div className="form-group">
                <Field
                  className="input-md form-control"
                  name="monthlyRentMonth"
                  component={renderTextField}
                  maxLength="25"
                  disabled={!isEditable}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <label>{intl.formatMessage(messages.descriptionLabel)}</label>
            </Col>
            <Col xs={8}>
              <div className="form-group">
                <Field
                  className="input-md form-control"
                  name="discountDescription"
                  component={renderTextField}
                  maxLength="25"
                  disabled={!isEditable}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <div className="divider" />
      </form>
    </Row>
  </React.Fragment>
);

export default Concessions;
