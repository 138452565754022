import { useFlags } from 'launchdarkly-react-client-sdk';
import { filter, pathOr } from 'ramda';
import React from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import TheWorkNumber from '../../../components/TheWorkNumber';
import WaitlistInfoSection from '../../../components/WaitlistInfoSection';
import useWaitlistService from '../../../components/WaitlistInfoSection/hooks';
import {
  showWaitlistUpdateFail,
  showWaitlistUpdateSuccess,
} from '../../../components/WaitlistInfoSection/utils';
import { useOneProperty } from '../../../hooks/data-fetching/useOneProperty';
import type { FeatureFlags } from '../../../types';
import { getSentAndExecutedStatus } from '../../../utils/lease-helpers';
import type { DropdownOption, User } from '../../App/types';
import type { Applicant, Application } from '../../CreateApplication/types';
import { useApplicationStatusOptions } from '../hooks';
import type { DecisionStatus } from '../types';
import ApplicantChecklistToggle from './ApplicantChecklistToggle';
import { ApplicationChecklistCompletionStatus } from './ApplicationChecklistCompletionStatus';
import ApplicationNotes from './ApplicationNotes';
import ApplicationReceived from './ApplicationReceived';
import CreditCriminalStatus from './CreditCriminalStatus';
import messages from './messages';

const RowWarning = styled(Row)`
  .highlight-details {
    padding: 10px;
    margin: 0 20px 20px;
  }
`;

type Props = {
  intl: Object,
  propertyId: string,
  organizationId: string,
  currentRecord: any,
  applicationDecisionStatus: Array<DecisionStatus>,
  applicationStatuses: Array<DropdownOption>,
  applicationId: string,
  screening: Object,
  screeningLetterTypes: Array<Object>,
  saveScreeningResults: Function,
  generateAdverseActionLetter: Function,
  checkUserPermissions: Function,
  currentUser: User,
  handleNotesChange: Function,
  handleChecklistOptionChange: Function,
  handleEditApplicationForm: Function,
  handleStatusChange: Function,
  submitScreeningApplication: Function,
  isCompliant: boolean,
  promptToaster: Function,
  selectedProperty: Object,
  householdId: string,
  initialValues: { applicationStatusId: string },
};

type LeaseProps = {
  intl: Object,
  currentRecord: Application,
  handleStatusChange?: Function,
  handleNotesChange?: Function,
  applicationStatuses: Array<DropdownOption>,
  checkUserPermissions: Function,
  isResident?: boolean,
  leaseExecuted?: boolean,
  leaseSentToPortal?: boolean,
  applicationStatusId: string,
};

type ChecklistProps = {
  intl: Object,
  currentRecord: Object,
  applicationDecisionStatus: Array<DecisionStatus>,
  handleChecklistOptionChange?: Function,
  handleEditApplicationForm: Function,
  approveApplicant?: Function,
  cancelApplicant?: Function,
  isResident?: boolean,
  isCanceled?: boolean,
  deniedReasons?: any,
  enabled?: boolean,
  isCompliant: boolean,
  leaseExecuted?: boolean,
  leaseSentToPortal?: boolean,
};

export const getWaitlistSectionProps = (currentRecord) => {
  const {
    applicationStatus: currentApplicationStatus,
    property: { organizationId },
    propertyId,
    id: applicationId,
  } = currentRecord;
  return {
    organizationId,
    propertyId,
    applicationId,
    currentApplicationStatus,
  };
};

export const statusOptionIsWaitlist = (status) =>
  /Waitlist/gi.test(status?.text ?? '');

export const getWaitlistStatusId = (applicationStatuses) =>
  applicationStatuses.find(statusOptionIsWaitlist)?.value ?? '';

export const waitlistChangeHandler = async (
  currentStatusId,
  newStatusId,
  waitlistStatusId,
  localWaitlistInfo,
  waitlistActions,
) => {
  if (newStatusId === currentStatusId) {
    return false;
  }

  const { updateWaitlist } = waitlistActions;
  const { id: applicationId } = localWaitlistInfo;

  if (currentStatusId === waitlistStatusId) {
    const newWaitlistInfo = {
      waitlistStartTime: localWaitlistInfo.waitlistStartTime,
      waitlistEndTime: new Date(),
    };
    await updateWaitlist(applicationId, newWaitlistInfo);
    return true;
  }

  if (newStatusId === waitlistStatusId) {
    const newWaitlistInfo = {
      waitlistStartTime: localWaitlistInfo?.waitlistStartTime ?? new Date(),
      waitlistEndTime: null,
    };
    await updateWaitlist(applicationId, newWaitlistInfo);
    return true;
  }
};

export const LeaseApplication = ({
  intl,
  currentRecord,
  handleStatusChange,
  handleNotesChange,
  applicationStatuses,
  checkUserPermissions,
  isResident,
  leaseExecuted,
  leaseSentToPortal,
  applicationStatusId,
}: LeaseProps) => {
  const statusUpdateAllowed = checkUserPermissions([
    'application-status-update',
    'application-status-update-excluding-approved',
  ]);

  const prospectUpdateAllowed = checkUserPermissions(['prospect-update']);
  const disableStatusChange =
    !statusUpdateAllowed || isResident || leaseExecuted || leaseSentToPortal;

  const { addWaitlistFields: isWaitlistEnabled } = useFlags();

  const {
    property: { id: propertyId, organizationId },
    id: applicationId,
    applicationStatus: { id: currentStatusId },
  } = currentRecord;
  const waitlistStatusId = getWaitlistStatusId(applicationStatuses);

  const [localWaitlistInfo, waitlistActions, isLoading] = useWaitlistService(
    organizationId,
    propertyId,
    applicationId,
  );

  const waitlistSectionProps = {
    ...getWaitlistSectionProps(currentRecord),
    localWaitlistInfo,
    waitlistActions,
    isLoading,
  };

  const handleWaitlistChange = async (event, newStatusId) => {
    try {
      const changed = await waitlistChangeHandler(
        currentStatusId,
        newStatusId,
        waitlistStatusId,
        localWaitlistInfo,
        waitlistActions,
      );
      if (changed) {
        showWaitlistUpdateSuccess(intl);
      }
    } catch (err) {
      showWaitlistUpdateFail(intl, err);
    }
  };

  const handlers = [handleStatusChange, handleWaitlistChange];

  const callAllHandlers = (event, newStatusId) => {
    handlers.forEach((handler) => handler(event, newStatusId));
  };

  const [applicationStatusOptions] = useApplicationStatusOptions(
    applicationStatuses,
    applicationStatusId,
  );

  return (
    <div className="container-fluid ledger-head">
      <Row>
        <Col xs={12} md={5}>
          <h1>{intl.formatMessage(messages.leaseApplication)}</h1>
        </Col>
        <Col xs={12} md={7}>
          <ul className="filter-horizontal">
            <li className="txt-status">
              <span>{intl.formatMessage(messages.status)}:</span>
            </li>
            <li>
              <Field
                name="applicationStatusId"
                component="select"
                className="form-control float-right"
                onChange={statusUpdateAllowed ? callAllHandlers : () => null}
                disabled={disableStatusChange}
              >
                {applicationStatusOptions.map((status) => (
                  <option
                    key={status.value}
                    value={status.value}
                    disabled={status.disabled}
                  >
                    {status.text}
                  </option>
                ))}
              </Field>
            </li>
          </ul>
        </Col>
      </Row>

      {isWaitlistEnabled && (
        <FormGroup>
          <WaitlistInfoSection {...waitlistSectionProps} />
        </FormGroup>
      )}

      <ApplicationReceived currentRecord={currentRecord} />

      <ApplicationNotes
        intl={intl}
        currentRecord={currentRecord}
        handleNotesChange={handleNotesChange}
        maxLength={620}
        isResident={isResident}
        prospectUpdateAllowed={prospectUpdateAllowed}
      />
    </div>
  );
};

export const filterApplicants = (applicants: Array<Applicant>) => {
  const hasChecklist = (x) => x.checklist !== null;
  return filter(hasChecklist, applicants);
};

export const countCompleteApplications = (applicants: Array<Applicant>) => {
  const checklistIsApproved = (x) => x.checklist.approved;
  return filter(checklistIsApproved, applicants).length;
};

export const ApplicationChecklistContainer = ({
  intl,
  currentRecord,
  applicationDecisionStatus,
  handleChecklistOptionChange,
  handleEditApplicationForm,
  isResident,
  deniedReasons,
  approveApplicant,
  cancelApplicant,
  isCanceled,
  isCompliant,
  leaseExecuted,
  leaseSentToPortal,
}: ChecklistProps) => {
  const applicantsChecklist = filterApplicants(currentRecord.applicants);
  return (
    <div className="container-fluid">
      <div className="accordion accordion-applicationmanager panel-group">
        {applicantsChecklist.map((applicant) => (
          <ApplicantChecklistToggle
            key={applicant.id}
            applicant={applicant}
            intl={intl}
            applicationDecisionStatus={applicationDecisionStatus}
            handleChecklistOptionChange={handleChecklistOptionChange}
            handleEditApplicationForm={handleEditApplicationForm}
            isResident={isResident}
            deniedReasons={deniedReasons}
            approveApplicant={approveApplicant}
            cancelApplicant={cancelApplicant}
            isCanceled={isCanceled}
            isCompliant={isCompliant}
            leaseExecuted={leaseExecuted}
            leaseSentToPortal={leaseSentToPortal}
          />
        ))}
      </div>
    </div>
  );
};

export const LeaseApplicationTab = ({
  intl,
  currentRecord,
  applicationDecisionStatus,
  applicationStatuses,
  handleChecklistOptionChange,
  handleEditApplicationForm,
  handleNotesChange,
  handleStatusChange,
  applicationId,
  screening,
  screeningLetterTypes,
  submitScreeningApplication,
  refreshActivityTable,
  saveScreeningResults,
  generateAdverseActionLetter,
  checkUserPermissions,
  currentUser,
  isCompliant,
  promptToaster,
  selectedProperty,
  householdId,
  initialValues: { applicationStatusId },
}: Props) => {
  const propertyId = selectedProperty?.id ?? '';
  const organizationId = currentUser?.user?.organization?.id ?? '';
  const propertyDetails = useOneProperty(organizationId, propertyId);
  const isTransUnionActive = propertyDetails[0]?.isTransUnionActive;
  const lease = pathOr({}, ['au', 'lease'], currentRecord);
  const { leaseExecuted, leaseSentToPortal } = getSentAndExecutedStatus(lease);
  const {
    theWorkNumber,
    transunionOffLeaseAppOff,
    applicationChecklistUpdate,
  }: FeatureFlags = useFlags();

  const theWorkNumberProps = {
    organizationId: currentUser?.user?.organization?.id ?? '',
    propertyId: selectedProperty?.id ?? '',
    householdId: householdId ?? '',
    currentUser,
  };

  const adverseActionDeadlineDays =
    selectedProperty?.config?.adverseActionDeadlineDays;

  return (
    <div className="tab-pane active" id="1a">
      {leaseExecuted && (
        <RowWarning>
          <div className="highlight-details">
            <FormattedMessage {...messages.leaseLockedExecuted} />
          </div>
        </RowWarning>
      )}
      {leaseSentToPortal && (
        <RowWarning>
          <div className="highlight-details">
            <FormattedMessage {...messages.leaseLockedSentToPortal} />
          </div>
        </RowWarning>
      )}
      <LeaseApplication
        intl={intl}
        currentRecord={currentRecord}
        applicationDecisionStatus={applicationDecisionStatus}
        applicationStatuses={applicationStatuses}
        handleNotesChange={handleNotesChange}
        handleStatusChange={handleStatusChange}
        checkUserPermissions={checkUserPermissions}
        leaseExecuted={leaseExecuted}
        leaseSentToPortal={leaseSentToPortal}
        applicationStatusId={applicationStatusId}
      />
      <CreditCriminalStatus
        intl={intl}
        applicationId={applicationId}
        screening={screening}
        onResubmitCallback={submitScreeningApplication}
        refreshActivityTable={refreshActivityTable}
        saveScreeningResults={saveScreeningResults}
        generateAdverseActionLetter={generateAdverseActionLetter}
        screeningLetterTypes={screeningLetterTypes}
        currentUser={currentUser}
        isResident={false}
        promptToaster={promptToaster}
        isTransUnionActive={isTransUnionActive}
        transunionOffLeaseAppOff={transunionOffLeaseAppOff}
        adverseActionDeadlineDays={adverseActionDeadlineDays}
      />
      {theWorkNumber && <TheWorkNumber {...theWorkNumberProps} />}
      <ApplicationChecklistCompletionStatus
        isTransUnionActive={isTransUnionActive}
        applicationChecklistUpdate={applicationChecklistUpdate}
      />
      <ApplicationChecklistContainer
        {...{
          intl,
          currentRecord,
          applicationDecisionStatus,
          handleChecklistOptionChange,
          handleEditApplicationForm,
          isCompliant,
          leaseExecuted,
          leaseSentToPortal,
        }}
      />
    </div>
  );
};

export default reduxForm({
  form: 'LeaseApplicationTab',
  enableReinitialize: true,
})(LeaseApplicationTab);
