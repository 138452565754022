import { Divider } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { grey } from '@fortress-technology-solutions/fortress-component-library/design';
import { useMemo } from 'react';
import styled from 'styled-components';
import AppliedFilters from '../../../../components/AppliedFilters';
import FilterControls from './FilterControls';

const Container = styled.div`
  background: #ffffff;
  border: 1px solid ${grey.lighter};
  border-radius: 3px;
  padding: 16px;
`;

type Props = {
  onFilterChange: Function,
  currentFilter: Object,
  customerStatus: string,
  onApplyFiltersClick: Function,
  showFilter: boolean,
  onDateFilterChange: Function,
  customerFilters: Array,
};

const formatActiveFilter = (filter, value) => {
  switch (filter) {
    case 'prospectsNumberOfBeds':
    case 'residentsNumberOfBeds':
      return `${value} ${value > 1 ? 'Bedrooms' : 'Bedroom'}`;
    case 'residentsUnitLevel':
      return `Unit Level ${value}`;
    case 'residentsBuildingNumber':
      return `Building ${value}`;
    default:
      return value;
  }
};

const mapFilters = (filters: Object, customerFilters: Array): Array<string> => {
  return Object.entries(filters).reduce((acc, [key, selectedOptions]) => {
    const customerFilter = customerFilters.find((f) => f.fieldName === key);
    if (!customerFilter) return acc;

    selectedOptions.forEach((option) => {
      acc.push({
        id: `${key}-${option}`,
        text: formatActiveFilter(
          key,
          customerFilter.options.find((o) => o.value === option).text,
        ),
        meta: {
          fieldName: key,
          value: option,
        },
      });
    });

    return acc;
  }, []);
};

export const Filters = ({
  onFilterChange,
  currentFilter,
  customerStatus,
  onApplyFiltersClick,
  showFilter,
  onDateFilterChange,
  customerFilters,
}: Props) => {
  const filters = useMemo(() => {
    return customerFilters.reduce((acc, customerFilter) => {
      const { fieldName } = customerFilter;
      acc[fieldName] = Object.entries(currentFilter[fieldName]).reduce(
        (activeFilters, [key, value]) => {
          if (value) activeFilters.push(key);

          return activeFilters;
        },
        [],
      );

      return acc;
    }, {});
  }, [currentFilter, customerFilters]);

  const dateRange = useMemo(() => {
    if (customerStatus === 'prospects' && currentFilter.prospects.deadOrLost)
      return {
        to: currentFilter.dateTo,
        from: currentFilter.dateFrom,
      };
    return { to: '', from: '' };
  }, [
    currentFilter.dateFrom,
    currentFilter.dateTo,
    currentFilter.prospects.deadOrLost,
    customerStatus,
  ]);

  const onChange = (key, payload) => {
    const customerFilter = customerFilters.find((f) => f.fieldName === key);

    customerFilter.options.forEach((option) => {
      const checked = payload.values.includes(option.value);

      onFilterChange(key)(option.value)({ target: { checked } });
    });

    onApplyFiltersClick();
  };

  const appliedFilters = useMemo(
    () => mapFilters(filters, customerFilters),
    [customerFilters, filters],
  );

  const onClearAll = () => {
    customerFilters.forEach(({ fieldName, options }) =>
      options.forEach((option) => {
        onFilterChange(fieldName)(option.value)({ target: { checked: false } });
      }),
    );

    onApplyFiltersClick();
  };

  const onRemoveFilter = (filter) => {
    onFilterChange(filter.meta.fieldName)(filter.meta.value)({
      target: { checked: false },
    });

    onApplyFiltersClick();
  };

  const onDateChange = (field) =>
    onDateFilterChange(field, onApplyFiltersClick);

  if (appliedFilters.length === 0 && !showFilter) return null;
  return (
    <Container>
      {appliedFilters.length > 0 && (
        <>
          <AppliedFilters
            filters={appliedFilters}
            onClearAll={onClearAll}
            onRemoveFilter={onRemoveFilter}
          />
          {showFilter && (
            <Divider style={{ marginTop: 10, marginBottom: 16 }} />
          )}
        </>
      )}
      {showFilter && (
        <FilterControls
          values={filters}
          onChange={onChange}
          customerStatus={customerStatus}
          dateRange={dateRange}
          onDateChange={onDateChange}
          customerFilters={customerFilters}
        />
      )}
    </Container>
  );
};
