import { HEADERS } from './constants';
import { pathOr } from 'ramda';
import moment from 'moment';
import { phoneFormatter } from '../../utils/redux-form-helper';
import { formatNBedsArr } from '../../utils/prospectPreferences-helpers';

export const parseCSV = (response: Object[]) => {
  const headers = HEADERS.map((header) => header.title).join(',');
  const rows = pathOr([], ['results'], response).map((prospect) => {
    const createdAt = prospect.createdAt;
    const createdAtFormatted = createdAt
      ? `"${moment(createdAt).format('ll')}"`
      : '';
    // $FlowFixMe
    const lastActivity = pathOr(null, ['lastActivity', 'startTime'], prospect);
    const lastActivityFormatted = lastActivity
      ? `"${moment(lastActivity).format('ll')}"`
      : '';
    // $FlowFixMe
    const nextActivity = pathOr(null, ['nextActivity', 'startTime'], prospect);
    const nextActivityFormatted = nextActivity
      ? `"${moment(nextActivity).format('ll')}"`
      : '';
    // $FlowFixMe
    const moveInDate = pathOr(
      null,
      ['prospectPreferences', 'moveInDateFrom'],
      prospect,
    );
    const moveInDateFormatted = moveInDate
      ? `"${moment(moveInDate).format('ll')}"`
      : '';
    const nBeds = formatNBedsArr(
      prospect?.prospectPreferences?.nBedsArr,
      prospect?.prospectPreferences?.nBeds,
    );
    // $FlowFixMe
    const assignedTo = `"${pathOr(
      '',
      ['assignedTo', 'firstName'],
      prospect,
    )} ${pathOr('', ['assignedTo', 'lastName'], prospect)}"`;
    const phoneNumber = pathOr('', ['phoneNumber'], prospect);
    const phoneNumberFormatted = `"${phoneFormatter(phoneNumber)}"`;
    return [
      // $FlowFixMe
      `"${pathOr('', ['currentProspectStatus', 'name'], prospect)}"`,
      // $FlowFixMe
      `"${pathOr('', ['lastName'], prospect)}"`,
      // $FlowFixMe
      `"${pathOr('', ['firstName'], prospect)}"`,
      phoneNumberFormatted,
      // $FlowFixMe
      `"${pathOr('', ['emailAddress'], prospect)}"`,
      createdAtFormatted,
      lastActivityFormatted,
      nextActivityFormatted,
      moveInDateFormatted,
      nBeds,
      assignedTo,
    ].join(',');
  });

  return { headers, rows };
};

function isDesiredDateRangeValid(start, end) {
  const moveInDateScheduled = moment(start);
  const moveInDateTo = moment(end);

  return (
    moveInDateTo.isAfter(moveInDateScheduled) ||
    moveInDateTo.isSame(moveInDateScheduled, 'day')
  );
}

export function getDesiredMoveInDate({
  prospectPreferences,
  isUnitAssigned = false,
}) {
  const isDateValid = isDesiredDateRangeValid(
    prospectPreferences?.moveInDateScheduled,
    prospectPreferences?.moveInDateTo,
  );

  const desiredEndDateWhenUnitSelectedInPortal = isDateValid
    ? prospectPreferences?.moveInDateTo
    : null;

  return {
    moveInDateFrom:
      prospectPreferences?.unitId && !isUnitAssigned
        ? prospectPreferences?.moveInDateScheduled
        : prospectPreferences?.moveInDateFrom,
    moveInDateTo:
      prospectPreferences?.unitId && !isUnitAssigned
        ? desiredEndDateWhenUnitSelectedInPortal
        : prospectPreferences?.moveInDateTo || null,
  };
}

export function getScheduledMoveInDate({
  lease,
  isUnitAssigned,
  prospectPreferences,
}) {
  const autoAssignedScheduledMoveInDate = isUnitAssigned
    ? prospectPreferences?.moveInDateScheduled
    : null;
  return lease?.moveInDate || autoAssignedScheduledMoveInDate;
}
