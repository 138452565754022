import { defineMessages } from 'react-intl';

const messages = defineMessages({
  concessions: {
    id: 'App.CommercialLeaseDataTab.Concessions.Concessions',
    defaultMessage: 'Concessions',
  },
  oneTimeRentConcessionLabel: {
    id: 'App.CommercialLeaseDataTab.Concessions.OneTimeRentConcessionLabel',
    defaultMessage: 'One-Time Rent Concession',
  },
  amountLabel: {
    id: 'App.CommercialLeaseDataTab.Concessions.AmountLabel',
    defaultMessage: 'Amount',
  },
  monthlyRentConcessionLabel: {
    id: 'App.CommercialLeaseDataTab.Concessions.MonthlyRentConcessionLabel',
    defaultMessage: 'Monthly Rent Concession',
  },
  otherDiscountConcessionLabel: {
    id: 'App.CommercialLeaseDataTab.Concessions.OtherDiscountConcessionLabel',
    defaultMessage: 'Other/Discount Concession',
  },
  monthsLabel: {
    id: 'App.CommercialLeaseDataTab.Concessions.MonthsLabel',
    defaultMessage: 'Months',
  },
  descriptionLabel: {
    id: 'App.CommercialLeaseDataTab.Concessions.DescriptionLabel',
    defaultMessage: 'Description',
  },
  note: {
    id: 'App.CommercialLeaseDataTab.Concessions.Note',
    defaultMessage: 'NOTE:',
  },
  noteDescription: {
    id: 'App.CommercialLeaseDataTab.Concessions.NoteDescription',
    defaultMessage: `You will need to manually add concession on
              the "Monthly Charges" table below.`,
  },
});

export default messages;
