import { put, takeLatest, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import { GET_PORTFOLIO } from './constants';
import { getPortfolioSuccess, getPortfolioError } from './actions';
import { selectCurrentUserOrganizationId } from '../App/selectors';
import { renderNoDataToastr } from '../../utils/redux-form-helper';
import KpiService from '../../services/kpiService';

export function* fetchGetPortfolioSaga(): Saga<void> {
  try {
    const portfolioService = new KpiService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const response = yield portfolioService.getPortfolio(organizationId);
    if (response.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getPortfolioSuccess(response));
  } catch (err) {
    yield put(getPortfolioError(err));
  }
}

export function* getPortfolioSaga(): Saga<void> {
  yield takeLatest(GET_PORTFOLIO, fetchGetPortfolioSaga);
}

export default [getPortfolioSaga];
