import { defineMessages } from 'react-intl';

const messages = defineMessages({
  recordTitle: {
    id: 'App.ManageProspects.ActivityModal.RecordTitle',
    defaultMessage: 'Record Activity',
  },
  scheduleTitle: {
    id: 'App.ManageProspects.ActivityModal.ScheduleTitle',
    defaultMessage: 'Schedule Activity',
  },
  createTitle: {
    id: 'App.ManageProspects.ActivityModal.CreateTitle',
    defaultMessage: 'Create Activity',
  },
  activityTypeLabel: {
    id: 'App.ManageProspects.ActivityModal.ActivityTypeLabel',
    defaultMessage: 'Select a type of activity',
  },
  activityCompletionLabel: {
    id: 'App.ManageProspects.ActivityModal.ActivityCompletionLabel',
    defaultMessage: 'How was this activity completed?',
  },
  activityDateLabel: {
    id: 'App.ManageProspects.ActivityModal.ActivityDateLabel',
    defaultMessage: 'Activity date',
  },
  recordTimeLabel: {
    id: 'App.ManageProspects.ActivityModal.RecordTimeLabel',
    defaultMessage: 'time',
  },
  scheduleStartTimeLabel: {
    id: 'App.ManageProspects.ActivityModal.ScheduleStartTimeLabel',
    defaultMessage: 'Start time',
  },
  scheduleEndTimeLabel: {
    id: 'App.ManageProspects.ActivityModal.ScheduleEndTimeLabel',
    defaultMessage: 'End time',
  },
  scheduleByLabel: {
    id: 'App.ManageProspects.ActivityModal.ScheduleByLabel',
    defaultMessage: 'Scheduled for',
  },
  notesLabel: {
    id: 'App.ManageProspects.ChangeStatusModal.NotesLabel',
    defaultMessage: 'Notes',
  },
  notesPlaceholder: {
    id: 'App.ManageProspects.ChangeStatusModal.NotesPlaceHolder',
    defaultMessage: 'Start typing...',
  },
  noTimeLabel: {
    id: 'App.ManageProspects.ChangeStatusModal.NoTimeLabel',
    defaultMessage: 'Do not specify a time',
  },
  notesLengthWarning: {
    id: 'App.ManageProspects.ChangeStatusModal.WotesLengthWarning',
    defaultMessage: '{current} of {max} max length.',
  },
  addActivity: {
    id: 'App.ManageProspects.ActivityModal.AddActivity',
    defaultMessage: 'Add Activity',
  },
  cancel: {
    id: 'App.ManageProspects.ActivityModal.Cancel',
    defaultMessage: 'Cancel',
  },
  notesRequired: {
    id: 'App.ManageProspects.ActivityModal.NotesRequired',
    defaultMessage: 'Notes are required',
  },
  requiredFields: {
    id: 'App.ManageProspects.ActivityModal.RequiredFields',
    defaultMessage: 'Required',
  },
  invalidTime: {
    id: 'App.ManageProspects.ActivityModal.InvalidTime',
    defaultMessage: 'Invalid time value',
  },
  invalidDate: {
    id: 'App.ManageProspects.ActivityModal.InvalidDate',
    defaultMessage: 'Invalid date value',
  },
  noOverlappingActivities: {
    id: 'App.ManageProspects.ActivityModal.NoOverlappingActivities',
    defaultMessage:
      'Activity timeframe conflicts with an activity already scheduled, please review activity date and time.',
  },
  tooManyActivitiesForAssignee: {
    id: 'App.ManageProspects.ActivityModal.TooManyActivitiesForAssignee',
    defaultMessage:
      'User cannot have more than 200 activities scheduled. Please complete some activities to continue.',
  },
  tooManyActivitiesForProspect: {
    id: 'App.ManageProspects.ActivityModal.TooManyActivitiesProspect',
    defaultMessage:
      'Prospect cannot have more than 5 activities scheduled. Please complete some activities to continue.',
  },
  noStartDateInFuture: {
    id: 'App.ManageProspects.ActivityModal.NoStartDateInFuture',
    defaultMessage:
      'You cannot add an activity record in the future, please review activity date and time.',
  },
  noStartDateInPast: {
    id: 'App.ManageProspects.ActivityModal.NoStartDateInPast',
    defaultMessage:
      'You cannot schedule a follow-up in the past, please review activity date and time.',
  },
  noEndDateBeforeStartDate: {
    id: 'App.ManageProspects.ActivityModal.NoEndDateBeforeStartDate',
    defaultMessage:
      'Start time needs to be before end time. Please review date and time.',
  },
  activityDetails: {
    id: 'App.ManageProspects.ActivityModal.ActivityDetails',
    defaultMessage: 'Activity Details',
  },
  schedulingDetails: {
    id: 'App.ManageProspects.ActivityModal.SchedulingDetails',
    defaultMessage: 'Scheduling Details',
  },
  allFieldsRequired: {
    id: 'App.ManageProspects.ActivityModal.AllFieldsRequired',
    defaultMessage: '*All fields are required',
  },
  prospect: {
    id: 'App.ManageProspects.ActivityModal.Prospect',
    defaultMessage: 'Prospect',
  },
  applicant: {
    id: 'App.ManageProspects.ActivityModal.Applicant',
    defaultMessage: 'Applicant',
  },
  resident: {
    id: 'App.ManageProspects.ActivityModal.Resident',
    defaultMessage: 'Resident',
  },
  'prior-resident': {
    id: 'App.ManageProspects.ActivityModal.PriorResident',
    defaultMessage: 'Prior Resident',
  },
  viewProfileButton: {
    id: 'App.ManageProspects.ActivityModal.ViewProfileButton',
    defaultMessage: 'View Profile',
  },
  chooseOption: {
    id: 'App.ManageProspects.ActivityModal.chooseOption',
    defaultMessage: 'Choose an option',
  },
  emptyAssignessListError: {
    id: 'App.ManageProspects.ActivityModal.EmptyAssignessListError',
    defaultMessage:
      '*There are no assignees. Please contact your system administrator.',
  },
  tooFarPast: {
    id: 'App.ManageProspects.ActivityModal.tooFarPast',
    defaultMessage:
      'You cannot record an activity before the prospect was created.',
  },
  calendarFor: {
    id: 'App.ManageProspects.ActivityModal.calendarfor',
    defaultMessage: 'Calendar for',
  },
  footerNote: {
    id: 'App.ManageProspects.CreateActivity.footerNote',
    defaultMessage:
      'Note: The user who saves the activity record will be listed as the ' +
      'Completed By" user, regardless of who the activity was scheduled for.',
  },
});

export default messages;
