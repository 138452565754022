import React from 'react';
import { Button } from 'react-bootstrap';

import ElementWithPermissions from '../../../../components/ElementWithPermissions';

type Props = {
  intl: Object,
  documents: Array<Object>,
  onDownloadDocument: Function,
  onEditDocument: Function,
  onDeleteDocument: Function,
};

const DocumentRows = ({
  intl,
  documents,
  onDownloadDocument,
  onEditDocument,
  onDeleteDocument,
}: Props): Array<any> => {
  const { formatDate } = intl;

  return documents.map((document, idx) => {
    return (
      <tr key={idx} data-test="document-row">
        <td>{formatDate(document.createdAt)}</td>
        <td>{document.name}</td>
        <td>{document && document.notes ? document.notes : '---'}</td>
        <td className="text-center">
          <Button
            id="download-button"
            bsStyle="default"
            bsSize="small"
            onClick={() => onDownloadDocument(document)}
          >
            <i className="icon et-download" />
          </Button>
          <Button
            id="edit-button"
            bsStyle="default"
            bsSize="small"
            onClick={() => onEditDocument(document)}
          >
            <i className="icon et-pencil" />
          </Button>
          <ElementWithPermissions scope={['document-delete']}>
            <Button
              id="delete-button"
              bsStyle="default"
              bsSize="small"
              onClick={() => onDeleteDocument(document)}
            >
              <i className="icon et-trash" />
            </Button>
          </ElementWithPermissions>
        </td>
      </tr>
    );
  });
};

export default DocumentRows;
