import { get, post, patch } from '../utils/api';

export default class UnitFeesService {
  getAll(query?: Object) {
    const queryStr = query ? `?${new URLSearchParams(query).toString()}` : '';
    return get(`/unit-fees${queryStr}`);
  }
  batchCreate(payload: Object[]) {
    const body = JSON.stringify(payload);
    return post('/unit-fees/batch', body);
  }
  updateAssignedUnitAmenities({
    unitId,
    propertyAmenityPTCID,
    newAssignedUnitAmenities,
  }) {
    const body = JSON.stringify({
      propertyAmenityPTCID,
      newAssignedUnitAmenities,
    });
    return patch(`/unit-fees/${unitId}/amenities`, body);
  }
}
