import { defineMessages } from 'react-intl';

const messages = defineMessages({
  unit: {
    id: 'App.Ledger.LedgerDetails.Unit',
    defaultMessage: 'Unit',
  },
  transactionDate: {
    id: 'App.Ledger.LedgerDetails.TransactionDate',
    defaultMessage: 'Transaction Date',
  },
  actualPostDate: {
    id: 'App.Ledger.LedgerDetails.ActualPostDate',
    defaultMessage: 'Actual Post Date',
  },
  subjournal: {
    id: 'App.Ledger.LedgerDetails.Subjournal',
    defaultMessage: 'Subj',
  },
  period: {
    id: 'App.Ledger.LedgerDetails.Period',
    defaultMessage: 'Period',
  },
  code: {
    id: 'App.Ledger.LedgerDetails.Code',
    defaultMessage: 'Code',
  },
  status: {
    id: 'App.Ledger.LedgerDetails.Status',
    defaultMessage: 'St*',
  },
  manual: {
    id: 'App.Ledger.LedgerDetails.Manual',
    defaultMessage: 'M**',
  },
  transactionNotes: {
    id: 'App.Ledger.LedgerDetails.TransactionNotes',
    defaultMessage: 'Transaction Notes',
  },
  description: {
    id: 'App.Ledger.LedgerDetails.Description',
    defaultMessage: 'Description',
  },
  documentNumber: {
    id: 'App.Ledger.LedgerDetails.DocumentNumber',
    defaultMessage: 'Doc #',
  },
  charges: {
    id: 'App.Ledger.LedgerDetails.Charges',
    defaultMessage: 'Charges',
  },
  credits: {
    id: 'App.Ledger.LedgerDetails.Credits',
    defaultMessage: 'Credits',
  },
  balance: {
    id: 'App.Ledger.LedgerDetails.Balance',
    defaultMessage: 'Balance',
  },
  view: {
    id: 'App.Ledger.LedgerDetails.View',
    defaultMessage: 'View',
  },
  posted: {
    id: 'App.Ledger.LedgerDetails.Posted',
    defaultMessage: 'P',
  },
  reversed: {
    id: 'App.Ledger.LedgerDetails.Reversed',
    defaultMessage: 'RD',
  },
  reversal: {
    id: 'App.Ledger.LedgerDetails.Reversal',
    defaultMessage: 'RL',
  },
});

export default messages;
