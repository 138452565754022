import { put, takeLatest, select } from 'redux-saga/effects';
import { reset } from 'redux-form';
import { actions as toastrActions } from 'react-redux-toastr';
import FileSaver from 'file-saver';

import {
  GET_DOCUMENT_TYPES,
  GET_RESIDENT_LETTER_DOCUMENT_TYPES,
  SAVE_DOCUMENT,
  GET_ALL_DOCUMENTS,
  DELETE_DOCUMENT,
  DOWNLOAD_DOCUMENT,
  GENERATE_RESIDENT_LETTER,
  RESIDENT_LETTER_FORM_NAME,
} from './constants';
import {
  getDocumentTypesSuccess,
  getResidentLetterDocumentTypesSuccess,
  saveDocumentSuccess,
  saveDocumentError,
  getAllDocuments,
  getAllDocumentsSuccess,
  generateResidentLetterSuccess,
  generateResidentLetterError,
} from './actions';

import { getCurrentResidentId } from '../../containers/ResidentProfile/selectors';
import { getOneApplication } from '../ApplicationProfile/actions';

import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import DocumentTypeService from '../../services/documentTypeService';
import DocumentService from '../../services/documentService';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import { isResidentStage } from './selectors';
import messages from './messages';
import { getOneResident } from '../ResidentProfile/actions';
import type { Saga } from 'redux-saga';

export function* fetchGetDocumentTypes({ payload }: Object): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const documentTypeService = new DocumentTypeService();
    const response = yield documentTypeService.getAll(
      organizationId,
      selectedProperty.id,
      payload.applicationId,
    );
    yield put(getDocumentTypesSuccess(response));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getDocumentTypesSaga(): Saga<void> {
  yield takeLatest(GET_DOCUMENT_TYPES, fetchGetDocumentTypes);
}

export function* fetchGetResidentLetterDocumentTypes({
  payload,
}: Object): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const documentTypeService = new DocumentTypeService();
    const response = yield documentTypeService.getResidentLetterDocumentTypes(
      organizationId,
      selectedProperty.id,
      payload.applicationId,
    );
    yield put(getResidentLetterDocumentTypesSuccess(response));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getResidentLetterDocumentTypesSaga(): Saga<void> {
  yield takeLatest(
    GET_RESIDENT_LETTER_DOCUMENT_TYPES,
    fetchGetResidentLetterDocumentTypes,
  );
}

export function* fetchSaveDocument(action: Object): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const residentId = yield select(isResidentStage);
    const documentService = new DocumentService();
    const document = new FormData();
    document.append('file', action.payload.file);
    document.append('documentTypeId', action.payload.documentTypeId);
    document.append('applicantId', action.payload.applicantId);
    document.append('applicationId', action.payload.applicationId);
    document.append('notes', action.payload.notes);

    yield documentService.save(document, organizationId, selectedProperty.id);
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successMessage),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(saveDocumentSuccess());
    yield put(reset('uploader'));
    yield put(
      getAllDocuments(
        action.payload.applicationId,
        action.payload.pageNumber,
        action.payload.limit,
        action.payload.sorting,
      ),
    );
    if (residentId) {
      yield put(getOneResident(residentId));
      return;
    }
    yield put(getOneApplication(action.payload.applicationId));
  } catch (err) {
    let error = err;
    if (err.indexOf('maximum allowed') > -1)
      error = 'File size must be less than 25MB';
    yield put(
      toastrActions.add({
        type: 'error',
        message: error,
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(saveDocumentError());
  }
}

/**
 * Will be called everytime the action SAVE_DOCUMENT is executed.
 * This is how the documents table updates after a new document is saved.
 */
export function* saveDocumentSaga(): Saga<void> {
  yield takeLatest(SAVE_DOCUMENT, fetchSaveDocument);
}

export function* fetchGetAllDocuments({ payload }: Object): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const documentService = new DocumentService();
    const response = yield documentService.getAll(
      payload.applicationId,
      payload.paginationInfo.pageNumber,
      payload.paginationInfo.limit,
      payload.sorting,
      organizationId,
      selectedProperty.id,
    );
    yield put(getAllDocumentsSuccess(response.results, response.meta));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getAllDocumentsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_DOCUMENTS, fetchGetAllDocuments);
}

export function* fetchDeleteDocument(action: Object): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const residentId = yield select(isResidentStage);
    const documentService = new DocumentService();
    yield documentService.delete(
      organizationId,
      action.payload.documentId,
      selectedProperty.id,
    );
    const showToastAlert = action.payload.options?.showToastAlert;
    if (showToastAlert) {
      yield put(
        toastrActions.add({
          type: 'success',
          message: renderTranslatedMessage(messages.successDeleteMessage),
          title: renderTranslatedMessage(messages.successHeader),
          options: {
            showCloseButton: true,
            removeOnHover: true,
          },
        }),
      );
    }
    yield put(
      getAllDocuments(
        action.payload.applicationId,
        action.payload.pageNumber,
        action.payload.limit,
        action.payload.sorting,
      ),
    );
    if (residentId) {
      yield put(getOneResident(residentId));
      return;
    }
    yield put(getOneApplication(action.payload.applicationId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

/**
 * Will be called everytime the action DELETE_DOCUMENT is executed.
 * This is how the documents table updates after a document is deleted.
 */
export function* deleteDocumentSaga(): Saga<void> {
  yield takeLatest(DELETE_DOCUMENT, fetchDeleteDocument);
}

export function* downloadDocument({ payload }: Object): any {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const documentService = new DocumentService();
    const response = yield documentService.download(
      organizationId,
      payload.documentId,
      selectedProperty.id,
    );

    FileSaver.saveAs(response, payload.filename);
  } catch {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.failedDocumentDownload),
        title: renderTranslatedMessage(messages.failedDocumentDownloadTitle),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* downloadDocumentSaga(): Saga<void> {
  yield takeLatest(DOWNLOAD_DOCUMENT, downloadDocument);
}

export function* generateResidentLetter(action: Object): Saga<void> {
  const {
    applicationId,
    residentLetterId,
    filename,
    pageNumber,
    limit,
    sorting,
  } = action.payload;
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const documentService = new DocumentService();
    const residentId = yield select(getCurrentResidentId);
    const isAllCommercial = selectedProperty.hasCommercialFloorPlans === 'ALL';
    const response = yield documentService.saveResidentLetter(
      { applicationId, residentId },
      residentLetterId,
      organizationId,
      selectedProperty.id,
    );
    FileSaver.saveAs(response, filename);
    yield put(
      toastrActions.add({
        type: 'success',
        message: isAllCommercial
          ? renderTranslatedMessage(messages.generateTenantLetterSuccessMessage)
          : renderTranslatedMessage(messages.generateLetterSuccessMessage),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(generateResidentLetterSuccess());
    yield put(reset(RESIDENT_LETTER_FORM_NAME));
    yield put(getAllDocuments(applicationId, pageNumber, limit, sorting));
  } catch (err) {
    const selectedProperty = yield select(selectSelectedProperty);
    const isAllCommercial = selectedProperty.hasCommercialFloorPlans === 'ALL';
    yield put(
      toastrActions.add({
        type: 'error',
        message: isAllCommercial
          ? renderTranslatedMessage(messages.generateTenantLetterErrorMessage)
          : renderTranslatedMessage(messages.generateLetterErrorMessage),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(generateResidentLetterError());
  }
}

export function* saveResidentLetterSaga(): Saga<void> {
  yield takeLatest(GENERATE_RESIDENT_LETTER, generateResidentLetter);
}

export default [
  getDocumentTypesSaga,
  getResidentLetterDocumentTypesSaga,
  saveDocumentSaga,
  getAllDocumentsSaga,
  deleteDocumentSaga,
  downloadDocumentSaga,
  saveResidentLetterSaga,
];
