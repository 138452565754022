import app from './containers/App/reducer';
import applicationProfile from './containers/ApplicationProfile/reducer';
import bankDeposit from './containers/ViewBankDeposit/reducer';
import createApplication from './containers/CreateApplication/reducer';
import createResident from './containers/CreateResident/reducer';
import createCommercialTenant from './containers/CreateCommercialTenant/reducer';
import createRole from './containers/CreateRole/reducer';
import createUser from './containers/CreateUser/reducer';
import deposits from './containers/ManageDeposits/reducer';
import editApplicationHousehold from './containers/EditApplicationHousehold/reducer';
import editProspectHousehold from './containers/EditProspectHousehold/reducer';
import editResidentHousehold from './containers/EditResidentHousehold/reducer';
import editRole from './containers/EditRole/reducer';
import editUser from './containers/EditUser/reducer';
import finalAccountStatement from './containers/FinalAccountStatement/reducer';
import forgotPassword from './containers/ForgotPassword/reducer';
import home from './containers/Home/reducer';
import languageProvider from './containers/LanguageProvider/reducer';
import leaseDataTab from './containers/LeaseDataTab/reducer';
import leasingActivity from './containers/LeasingActivity/reducer';
import ledger from './containers/Ledger/reducer';
import login from './containers/Login/reducer';
import manageApplications from './containers/ManageApplications/reducer';
import manageCommunications from './containers/ManageCommunications/reducer';
import manageDelinquencies from './containers/ManageDelinquency/reducer';
import manageDocuments from './containers/ManageDocuments/reducer';
import manageLeaseExpirations from './containers/ManageLeaseExpirations/reducer';
import managePriorResidents from './containers/ManagePriorResidents/reducer';
import manageProspects from './containers/ManageProspects/reducer';
import manageReports from './containers/ManageReports/reducer';
import viewCommunication from './containers/ViewCommunications/reducer';
import manageUndepositedPayments from './containers/ManageUndepositedPayments/reducer';
import manageUnitAvailability from './containers/ManageUnitAvailability/reducer';
import manageWorkOrders from './containers/ManageWorkOrders/reducer';
import manageFloorPlans from './containers/ManageFloorPlans/reducer';
import miscTransactions from './containers/ManageMiscTransactions/reducer';
import monthEndClose from './containers/MonthEndClose/reducer';
import overview from './containers/Overview/reducer';
import peopleProfile from './containers/PeopleProfile/reducer';
import portfolio from './containers/Portfolio/reducer';
import primaryLeaseApplication from './containers/PrimaryLeaseApplication/reducer';
import prospectProfile from './containers/ProspectProfile/reducer';
import qualification from './containers/QualificationForms/reducer';
import renewalOffer from './containers/GenerateRenewalOffer/reducer';
import resetPassword from './containers/ResetPassword/reducer';
import residentProfile from './containers/ResidentProfile/reducer';
import secondaryLeaseApplication from './containers/NonPrimaryLeaseApplication/reducer';
import shortLeaseApplication from './containers/ShortLeaseApplication/reducer';
import storage from 'redux-persist/lib/storage';
import viewUnit from './containers/ViewUnit/reducer';
import viewUnitEditFees from './containers/ViewUnit/EditFees/reducer';
import recertificationFormData from './containers/RecertificationApplication/reducer';
import transferResidentReducer from './containers/ResidentProfile/ResidentTransfer/reducer';
import leasePacketReducer from './containers/LeaseDataTab/LeaseDataTabForm/LeaseDataTabFormSections/LeasePacket/reducer'; // eslint-disable-line
import { combineReducers } from 'redux';
import { createChargeBatchReducer } from './containers/CreateChargeBatch/reducer';
import { LOG_OUT } from './containers/App/constants';
import { persistReducer } from 'redux-persist';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { routerReducer, push } from 'react-router-redux';
import {
  unitOptionsReducer,
  editWorkOrder,
} from './containers/WorkOrder/reducer';
import {
  transactionTypesReducer,
  propertyTransactionCodesReducer,
  propertySubjournalTypesReducer,
  createTransactionReducer,
} from './components/CreateTransaction/reducer';

const loginConfig = {
  key: 'login',
  storage: storage,
  blacklist: ['showErrorMessage', 'errorMessage'],
};

const appConfig = {
  key: 'app',
  storage: storage,
  whitelist: ['currentUser', 'leftNavState', 'navigationOptions'],
};

const appReducer = combineReducers({
  app: persistReducer(appConfig, app),
  applicationProfile,
  createApplication,
  createChargeBatch: createChargeBatchReducer,
  createResident,
  createCommercialTenant,
  createRole,
  createTransaction: createTransactionReducer,
  createUser,
  currentProperty: combineReducers({
    subjournalTypes: propertySubjournalTypesReducer,
    transactionCodes: propertyTransactionCodesReducer,
    unitOptions: unitOptionsReducer,
    affordableFloorplanPricing: viewUnitEditFees,
  }),
  editApplicationHousehold,
  editProspectHousehold,
  editResidentHousehold,
  editRole,
  editUser,
  editWorkOrder,
  finalAccountStatement,
  financials: combineReducers({
    bankDeposit,
    deposits,
    miscTransactions,
  }),
  forgotPassword,
  form: formReducer,
  home,
  languageProvider,
  leaseDataTab,
  leasingActivity,
  ledger,
  login: persistReducer(loginConfig, login),
  manageApplications,
  manageCommunications,
  manageDelinquencies,
  manageDocuments,
  manageLeaseExpirations,
  managePriorResidents,
  manageProspects,
  manageReports,
  viewCommunication,
  manageUndepositedPayments,
  manageUnitAvailability,
  manageWorkOrders,
  manageFloorPlans,
  monthEndClose,
  overview,
  peopleProfile,
  portfolio,
  primaryLeaseApplication,
  prospectProfile,
  qualification,
  recertificationFormData,
  renewalOffer,
  resetPassword,
  residentProfile,
  routing: routerReducer,
  secondaryLeaseApplication,
  shortLeaseApplication,
  toastr: toastrReducer,
  transactionTypes: transactionTypesReducer,
  transferResidentInformation: transferResidentReducer,
  viewUnit,
  viewUnitEditFees,
  leasePacket: leasePacketReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOG_OUT) {
    const { routing, login, app } = state;
    state = { routing, login, app };
    localStorage.removeItem('session_id');
    push('/login');
  }

  return appReducer(state, action);
};

export default rootReducer;
