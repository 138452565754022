import { default as DatePickerLibrary } from 'react-datetime';
import { useController } from 'react-hook-form';
import React from 'react';
import * as formHelpers from '../../utils/redux-form-helper';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';

export function DatePicker(props) {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return (
    <Stack direction="column">
      <DatePickerLibrary
        error={error}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        name={field.name}
        inputRef={field.ref}
        timeFormat={false}
        closeOnSelect={true}
        renderInput={formHelpers.renderDatePickerInput}
        {...props}
      />
    </Stack>
  );
}

export default DatePicker;
