import { defineMessages } from 'react-intl';

const messages = defineMessages({
  code: {
    id: 'App.EditMasterTransactionCode.code',
    defaultMessage: 'Code',
  },
  description: {
    id: 'App.EditMasterTransactionCode.description',
    defaultMessage: 'Description',
  },
  defaultDebitGLAccount: {
    id: 'App.EditMasterTransactionCode.defaultDebitGLAccount',
    defaultMessage: 'Default Debit GL Account #',
  },
  defaultCreditGLAccount: {
    id: 'App.EditMasterTransactionCode.defaultCreditGLAccount',
    defaultMessage: 'Default Credit GL Account #',
  },
  transactionType: {
    id: 'App.EditMasterTransactionCode.transactionType',
    defaultMessage: 'Transaction Type',
  },
  subjournal: {
    id: 'App.EditMasterTransactionCode.subjournal',
    defaultMessage: 'Subjournal',
  },
  assignToProperty: {
    id: 'App.EditMasterTransactionCode.assignToProperty',
    defaultMessage: 'Code assigned to properties',
  },
  header: {
    id: 'App.EditMasterTransactionCode.header',
    defaultMessage: 'Edit Transaction Code',
  },
  cancel: {
    id: 'App.EditMasterTransactionCode.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.EditMasterTransactionCode.save',
    defaultMessage: 'Save',
  },
  goback: {
    id: 'App.EditMasterTransactionCode.goback',
    defaultMessage: 'Go Back',
  },
  title: {
    id: 'App.EditMasterTransactionCode.title',
    defaultMessage: 'Edit Transaction Code',
  },
  successTitle: {
    id: 'App.EditMasterTransactionCode.successTitle',
    defaultMessage: 'Success',
  },
  errorTitle: {
    id: 'App.EditMasterTransactionCode.errorTitle',
    defaultMessage: 'Error',
  },
  saveSuccess: {
    id: 'App.EditMasterTransactionCode.saveSuccess',
    defaultMessage: 'Transaction code settings saved',
  },
  propertiesDisabled: {
    id: 'App.EditMasterTransactionCode.propertiesDisabled',
    defaultMessage:
      'Properties using Transaction Codes for monthly auto-posting cannot be unassigned.',
  },
  cancelConfirmation: {
    id: 'App.EditMasterTransactionCode.cancelConfirmation',
    defaultMessage: 'Are you sure you want to leave?',
  },
});

export default messages;
