// @flow

import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Field } from 'redux-form';
import { renderPasswordField } from '../../../utils/redux-form-helper';
import messages from '../messages';
import type { Intl } from '../../../types';
import type { Restrictions } from './types';

export type Props = {
  intl: Intl,
  handleSubmit: Function,
  handleChange: Function,
  validToken?: boolean,
  showErrorMessage?: boolean,
  submitting?: boolean,
  valid?: boolean,
} & Restrictions;

export default ({
  intl,
  handleSubmit,
  handleChange,
  minTenCharacters,
  minOneUppercase,
  minOneLowercase,
  minOneSymbol,
  minOneNumber,
  validToken,
  showErrorMessage,
  submitting,
  valid,
}: Props): any => {
  return (
    <Form className="loginform" onSubmit={handleSubmit}>
      <Field
        onChange={handleChange}
        name="password"
        classes="blue-label pull-left"
        label={intl.formatMessage(messages.newPassword)}
        component={renderPasswordField}
        bsSize="lg"
      />
      <Field
        name="confirmPassword"
        classes="blue-label pull-left"
        label={intl.formatMessage(messages.confirmPassword)}
        component={renderPasswordField}
        bsSize="lg"
      />
      <div className="form-group">
        <small className="text-left">
          {intl.formatMessage(messages.passwordMustInclude)}
        </small>
        <div className="password-validator container-fluid">
          <Row>
            <Col xs={10}>
              <ul className="validation-list">
                <li>{intl.formatMessage(messages.minTenCharacters)}</li>
                <li>{intl.formatMessage(messages.minOneUppercase)}</li>
                <li>{intl.formatMessage(messages.minOneLowercase)}</li>
                <li>{intl.formatMessage(messages.minOneSymbol)}</li>
                <li>{intl.formatMessage(messages.minOneNumber)}</li>
              </ul>
            </Col>
            <Col xs={2}>
              <ul className="validation-lights">
                <li
                  className={['val1', minTenCharacters ? 'validated' : ''].join(
                    ' ',
                  )}
                >
                  <span>x</span>
                </li>
                <li
                  className={['val2', minOneUppercase ? 'validated' : ''].join(
                    ' ',
                  )}
                >
                  <span>x</span>
                </li>
                <li
                  className={['val3', minOneLowercase ? 'validated' : ''].join(
                    ' ',
                  )}
                >
                  <span>x</span>
                </li>
                <li
                  className={['val4', minOneSymbol ? 'validated' : ''].join(
                    ' ',
                  )}
                >
                  <span>x</span>
                </li>
                <li
                  className={['val5', minOneNumber ? 'validated' : ''].join(
                    ' ',
                  )}
                >
                  <span>x</span>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>
      <Button
        className="btn-primary"
        type="submit"
        disabled={submitting || !valid || !validToken}
      >
        Continue
      </Button>
    </Form>
  );
};
