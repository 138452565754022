import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as reduxForm from 'redux-form';
import moment from 'moment';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as R from 'ramda';
import * as constants from './constants';
import messages from '../messages';
import InsuranceForm from './InsuranceForm';
import SubmitButton from '../../../components/SubmitButton';
import ConfirmOverlay from '../../../components/ConfirmOverlay';
import * as tenantActions from '../actions';

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButtonMargins = styled.span`
  & button {
    margin: 0 10px;
  }
`;

const Note = styled.div`
  display: flex;
  justify-content: center;
  & span:first-child {
    font-weight: 500;
  }
`;

const TrashRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  & i {
    font-size: 20px;
  }
`;

const getInitValuesFromInsurance = R.pipe(
  R.pick([
    'commercialInsuranceTypeId',
    'limits',
    'tenDayClause',
    'requirements',
    'effectiveDate',
    'expirationDate',
    'propertyListedAsAdditionalInsured',
  ]),
  R.evolve({
    tenDayClause: R.toString,
    propertyListedAsAdditionalInsured: R.toString,
    effectiveDate: moment,
    expirationDate: moment,
  }),
);

function InsuranceModal({
  insurance,
  onHide,
  show,
  organizationId,
  householdId,
  submit,
  form,
  createInsurance,
  refreshInsurances,
  editInsurance,
  deleteInsurance,
}) {
  const [showConfirmDeleteOverlay, setShowConfirmDeleteOverlay] =
    useState(false);
  const isAddForm = R.isNil(insurance);
  const headerMessage = isAddForm
    ? messages.addInsurance
    : messages.editInsurance;
  const showNote = isAddForm;

  const handleHide = () => {
    onHide();
    setShowConfirmDeleteOverlay(false);
  };

  const handleDeleteReject = () => {
    setShowConfirmDeleteOverlay(false);
  };

  const handleDeleteConfirm = () => {
    deleteInsurance({
      insuranceId: insurance.id,
      householdId,
      refreshInsurances,
    });
    handleHide();
    setShowConfirmDeleteOverlay(false);
  };

  const handleTrashIconClick = () => {
    setShowConfirmDeleteOverlay(true);
  };

  const isSubmitDisabled = () => {
    return R.prop('syncErrors', form) || showConfirmDeleteOverlay;
  };

  const handleSubmit = (values) => {
    if (isAddForm)
      createInsurance({ insurance: values, householdId, refreshInsurances });
    else
      editInsurance({
        insuranceId: insurance.id,
        insurance: values,
        householdId,
        refreshInsurances,
      });

    handleHide();
  };

  const handleSubmitButtonClick = () => {
    submit(constants.FORM_NAME);
  };

  const initialValues = useMemo(
    () => (isAddForm ? undefined : getInitValuesFromInsurance(insurance)),
    [insurance, isAddForm],
  );

  return (
    <Modal show={show} onHide={handleHide} bsSize="large" autoFocus>
      <Modal.Header style={{ paddingBottom: 0 }} closeButton>
        <i className="icon et-notes" />
        <h1>
          <FormattedMessage {...headerMessage} />
        </h1>
        <p style={{ marginLeft: '1%', fontWeight: 400 }}>
          * <FormattedMessage {...messages.requiredField} />
        </p>
      </Modal.Header>
      <Modal.Body>
        {showConfirmDeleteOverlay && (
          <ConfirmOverlay
            title={
              <FormattedMessage
                {...messages.insuranceDeleteConfirmationTitle}
              />
            }
            rejectMessage={<FormattedMessage {...messages.no} />}
            confirmMessage={<FormattedMessage {...messages.yes} />}
            onReject={handleDeleteReject}
            onConfirm={handleDeleteConfirm}
          />
        )}
        {!isAddForm && (
          <TrashRow>
            <a>
              <i className="icon et-trash" onClick={handleTrashIconClick} />
            </a>
          </TrashRow>
        )}
        <InsuranceForm
          initialValues={initialValues}
          organizationId={organizationId}
          onSubmit={handleSubmit}
        />
        {showNote && (
          <Note>
            <p>
              <span>
                <FormattedMessage {...messages.addNote} />
              </span>
              &nbsp;
              <FormattedMessage {...messages.addNoteContent} />
            </p>
          </Note>
        )}
      </Modal.Body>
      <Modal.Footer>
        <FooterContainer>
          <Button type="button" bsStyle="default" onClick={handleHide}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <SubmitButtonMargins>
            <SubmitButton
              bsStyle="primary"
              clickHandler={handleSubmitButtonClick}
              disabled={isSubmitDisabled()}
              isSubmitting={false}
            >
              <FormattedMessage {...messages.save} />
            </SubmitButton>
          </SubmitButtonMargins>
        </FooterContainer>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = ({ form }) => ({
  form: form[constants.FORM_NAME],
});

const mapDispatchToProps = {
  submit: reduxForm.submit,
  createInsurance: tenantActions.createInsurance,
  editInsurance: tenantActions.editInsurance,
  deleteInsurance: tenantActions.deleteInsurance,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceModal);
