export const SECTION_8_SUBSIDY_CODE = 1;
export const FORM_NAME = 'exceptionsHUD';
export const YES_RADIO_VALUE = 'yes';
export const NO_RADIO_VALUE = 'no';
export const POST_UNIVERSE_CODE = 2;
export const CERTIFICATION_TYPES = {
  MOVE_IN: 'MOVE_IN',
  INITIAL: 'INITIAL',
  RECERT: 'RECERT',
  INTERIM: 'INTERIM',
  CORRECTION: 'CORRECTION',
  TERMINATION: 'TERMINATION',
  MOVE_OUT: 'MOVE_OUT',
  GROSS_RENT: 'GROSS_RENT',
};
