import { FormattedMessage } from 'react-intl';
import React from 'react';

import messages from './messages';
import generalMessages from '../App/messages';

export const AMENITY_DETAILS_PREFIX = 'amenityFee__';
export const HEADERS = ({
  isAffordable,
  isConventional,
  isMixed,
  isRDRAEnabled,
  rdRents,
  showTextingColumn,
  showAutopayColumn,
  showPADColumn,
  intl,
  manageRentRollBuildingsColumn,
}) => [
  {
    id: 'unit',
    label: <FormattedMessage {...messages.unit} />,
    sortable: true,
    searchable: true,
    dataType: 'string',
  },
  {
    id: 'floorPlan',
    label: <FormattedMessage {...messages.floorPlan} />,
    sortable: true,
    searchable: true,
    multiselect: true,
    dataType: 'string',
  },
  ...(manageRentRollBuildingsColumn
    ? [
        {
          id: 'buildingNumber',
          label: <FormattedMessage {...messages.buildingNumber} />,
          sortable: true,
          searchable: true,
          multiselect: true,
          dataType: 'string',
        },
      ]
    : []),
  {
    id: 'bedsBaths',
    label: <FormattedMessage {...messages.bedsBaths} />,
    sortable: true,
    searchable: true,
    multiselect: true,
    dataType: 'string',
  },
  {
    id: 'sqFt',
    label: <FormattedMessage {...messages.sqFeet} />,
    sortable: true,
    searchable: true,
    dataType: 'number',
  },
  {
    id: 'status',
    label: <FormattedMessage {...messages.status} />,
    sortable: true,
    searchable: true,
    multiselect: true,
    dataType: 'string',
  },
  {
    id: 'resident',
    label: <FormattedMessage {...messages.resident} />,
    sortable: true,
    searchable: true,
    dataType: 'string',
  },
  {
    id: 'otherHouseholdMembers',
    label: <FormattedMessage {...messages.otherHouseholdMembers} />,
    sortable: true,
    searchable: true,
    dataType: 'string',
  },
  ...(showTextingColumn
    ? [
        {
          id: 'texting',
          label: <FormattedMessage {...messages.texting} />,
          sortable: true,
          searchable: false,
          dataType: 'number',
          multiselect: true,
          filterOptions: [
            { text: 'New', value: 'new' },
            { text: 'Unresolved', value: 'unresolved' },
          ],
        },
      ]
    : []),
  ...(isAffordable || isMixed
    ? [
        {
          id: 'requestedHardshipExemption',
          label: <FormattedMessage {...messages.hardship} />,
          sortable: true,
          searchable: false,
          dataType: 'boolean',
          multiselect: true,
          filterOptions: [
            {
              text: intl.formatMessage(generalMessages.yes),
              value: 'yes',
            },
            {
              text: intl.formatMessage(generalMessages.no),
              value: 'no',
            },
          ],
        },
        {
          id: 'setAside',
          label: <FormattedMessage {...messages.setAside} />,
          sortable: true,
          searchable: true,
          dataType: 'number',
        },
        ...(isRDRAEnabled
          ? [
              {
                id: 'rdRentalAssistanceRA',
                label: <FormattedMessage {...messages.rdRentalAssistanceRA} />,
                sortable: true,
                multiselect: false,
                filterOptions: [
                  { text: 'Yes', value: 'yes' },
                  { text: 'No', value: 'no' },
                ],
                dataType: 'string',
              },
            ]
          : []),
      ]
    : []),
  {
    id: 'moveInDate',
    label: <FormattedMessage {...messages.moveInDate} />,
    sortable: true,
    searchable: true,
    dataType: 'date',
  },
  {
    id: 'leaseStartDate',
    label: <FormattedMessage {...messages.leaseStartDate} />,
    sortable: true,
    searchable: true,
    dataType: 'date',
  },
  {
    id: 'leaseEndDate',
    label: <FormattedMessage {...messages.leaseEndDate} />,
    sortable: true,
    searchable: true,
    dataType: 'date',
  },
  {
    id: 'collectionNote',
    label: <FormattedMessage {...messages.collectionsNotes} />,
    sortable: true,
    searchable: true,
    dataType: 'string',
  },
  {
    id: 'underEviction',
    label: <FormattedMessage {...messages.underEviction} />,
    sortable: true,
    searchable: false,
    multiselect: true,
    filterOptions: [
      { text: 'Yes', value: 'yes' },
      { text: 'No', value: 'no' },
    ],
    dataType: 'boolean',
  },
  {
    id: 'baseFloorPlanMarketRent',
    label: <FormattedMessage {...messages.floorPlanBaseGPR} />,
    info: <FormattedMessage {...messages.floorPlanBaseGPRTooltip} />,
    sortable: true,
    searchable: true,
    dataType: 'number',
  },
  {
    id: 'amenityFees',
    label: <FormattedMessage {...messages.amenityFees} />,
    sortable: true,
    searchable: true,
    dataType: 'number',
  },
  {
    id: 'marketRent',
    label: <FormattedMessage {...messages.gprMarketRent} />,
    info: <FormattedMessage {...messages.unitGPRMarketRentTooltip} />,
    sortable: true,
    searchable: true,
    dataType: 'number',
  },
  ...(isAffordable || isMixed
    ? [
        {
          id: 'totalGrossRent',
          label: <FormattedMessage {...messages.totalGrossRent} />,
          info: <FormattedMessage {...messages.totalGrossRentTooltip} />,
          sortable: true,
          searchable: true,
          dataType: 'number',
        },
        {
          id: 'grossRentLimit',
          label: <FormattedMessage {...messages.grossRentLimit} />,
          info: <FormattedMessage {...messages.grossRentLimitTooltip} />,
          sortable: true,
          searchable: true,
          dataType: 'number',
        },
        {
          id: 'netMaxRent',
          label: <FormattedMessage {...messages.netMaxRent} />,
          info: <FormattedMessage {...messages.netMaxRentTooltip} />,
          sortable: true,
          searchable: true,
          dataType: 'number',
        },
      ]
    : []),
  {
    id: 'priorLeasedRent',
    label: <FormattedMessage {...messages.priorLeasedRent} />,
    sortable: true,
    searchable: true,
    dataType: 'number',
  },
  ...(showAutopayColumn
    ? [
        {
          id: 'isAutopayActive',
          label: <FormattedMessage {...messages.autopayHeader} />,
          sortable: true,
          searchable: false,
          multiselect: true,
          filterOptions: [
            { text: 'On', value: 'on' },
            { text: 'Off', value: 'off' },
          ],
          dataType: 'string',
        },
      ]
    : []),
  ...(showPADColumn
    ? [
        {
          id: 'isPADActive',
          label: <FormattedMessage {...messages.padHeader} />,
          sortable: true,
          searchable: false,
          multiselect: true,
          filterOptions: [
            { text: 'On', value: 'on' },
            { text: 'Off', value: 'off' },
          ],
          dataType: 'string',
        },
      ]
    : []),
  {
    id: 'leasedRent',
    label: <FormattedMessage {...messages.currentLeasedRent} />,
    sortable: true,
    searchable: true,
    dataType: 'number',
  },
  ...(isAffordable || isMixed
    ? [
        {
          id: 'nonOptionalCharge',
          label: <FormattedMessage {...messages.nonOptionalCharge} />,
          sortable: true,
          searchable: true,
          dataType: 'number',
        },
        {
          id: 'actualUa',
          label: <FormattedMessage {...messages.actualUa} />,
          info: <FormattedMessage {...messages.actualUaTooltip} />,
          sortable: true,
          searchable: true,
          dataType: 'number',
        },
        {
          id: 'utilityAllowances',
          label: <FormattedMessage {...messages.utilityAllowance} />,
          info: { ...messages.utilityAllowanceTooltip },
          sortable: true,
          searchable: true,
          dataType: 'number',
        },
      ]
    : []),
  {
    id: 'residentRent',
    label: <FormattedMessage {...messages.residentChargedRent} />,
    sortable: true,
    searchable: true,
    dataType: 'number',
  },
  {
    id: 'subsidyRent',
    label: <FormattedMessage {...messages.subsidyRent} />,
    sortable: true,
    searchable: true,
    dataType: 'number',
  },
  ...(rdRents?.basicRent
    ? [
        {
          id: 'basicRent',
          label: <FormattedMessage {...messages.basicRent} />,
          sortable: true,
          searchable: true,
          dataType: 'number',
        },
      ]
    : []),
  ...(rdRents?.noteRent
    ? [
        {
          id: 'noteRent',
          label: <FormattedMessage {...messages.noteRent} />,
          sortable: true,
          searchable: true,
          dataType: 'number',
        },
      ]
    : []),
  {
    id: 'chargedUnitFees',
    label: <FormattedMessage {...messages.chargedUnitFees} />,
    sortable: true,
    searchable: true,
    dataType: 'number',
  },
  {
    id: 'balance',
    label: <FormattedMessage {...messages.balance} />,
    sortable: true,
    searchable: true,
    dataType: 'number',
  },
  {
    id: 'moveOutDate',
    label: <FormattedMessage {...messages.moveOutDate} />,
    sx: {
      backgroundColor: 'primary.dark',
    },
    sortable: true,
    searchable: true,
    dataType: 'date',
  },
  {
    id: 'moveInReady',
    label: <FormattedMessage {...messages.moveInReady} />,
    sx: {
      backgroundColor: 'primary.dark',
    },
    sortable: true,
    searchable: true,
    dataType: 'date',
  },
  {
    id: 'daysVacant',
    label: <FormattedMessage {...messages.daysVacant} />,
    sx: {
      backgroundColor: 'primary.dark',
    },
    sortable: true,
    searchable: true,
    dataType: 'number',
  },
  {
    id: 'quotingRent',
    label: <FormattedMessage {...messages.quotingRent} />,
    sx: {
      backgroundColor: 'primary.dark',
    },
    sortable: true,
    searchable: true,
    dataType: 'number',
  },
  {
    id: 'applicant',
    label: <FormattedMessage {...messages.applicant} />,
    sx: {
      backgroundColor: 'primary.dark',
    },
    sortable: true,
    searchable: true,
    dataType: 'string',
  },
  {
    id: 'applicantStatus',
    label: <FormattedMessage {...messages.applicantStatus} />,
    sx: {
      backgroundColor: 'primary.dark',
    },
    sortable: true,
    searchable: true,
    multiselect: true,
    dataType: 'string',
  },
  {
    id: 'scheduledMoveInDate',
    label: <FormattedMessage {...messages.scheduledMoveInDate} />,
    sx: {
      backgroundColor: 'primary.dark',
    },
    sortable: true,
    searchable: true,
    dataType: 'date',
  },
];

export const PROPERTY_PATH_MAP = ({ unitAmenityHeaders }) => ({
  unit: {
    path: 'unit',
    dataType: 'string',
  },
  floorPlan: {
    path: 'floorPlan',
    dataType: 'string',
  },
  buildingNumber: {
    path: 'buildingNumber',
    dataType: 'string',
  },
  bedsBaths: {
    path: 'bedsBaths',
    dataType: 'string',
  },
  sqFt: {
    path: 'sqFt',
    dataType: 'number',
  },
  status: {
    path: 'status',
    dataType: 'string',
  },
  resident: {
    path: 'resident',
    dataType: 'string',
  },
  isAutopayActive: {
    path: 'isAutopayActive',
    dataType: 'string',
  },
  isPADActive: {
    path: 'isPADActive',
    dataType: 'string',
  },
  otherHouseholdMembers: {
    path: 'otherHouseholdMembers_search',
    dataType: 'string',
  },
  texting: {
    path: 'texting.TOTAL',
    dataType: 'number',
    filterPath: 'texting',
    filterFn: (filterByArr: string[], filterValue: any) => {
      if (filterByArr.includes('new') && filterValue.NEW > 0) {
        return true;
      }
      if (filterByArr.includes('unresolved') && filterValue.UNRESOLVED > 0) {
        return true;
      }
      return false;
    },
  },
  requestedHardshipExemption: {
    path: 'requestedHardshipExemption',
    dataType: 'boolean',
  },
  setAside: {
    path: 'setAside',
    dataType: 'number',
  },
  rdRentalAssistanceRA: {
    path: 'rdRentalAssistanceRA',
    dataType: 'string',
  },
  moveInDate: {
    path: 'moveInDate',
    dataType: 'date',
  },
  leaseStartDate: {
    path: 'leaseStartDate',
    dataType: 'date',
  },
  leaseEndDate: {
    path: 'leaseEndDate',
    dataType: 'date',
  },
  collectionNote: {
    path: 'collectionNote',
    dataType: 'string',
  },
  underEviction: {
    path: 'underEviction',
    dataType: 'boolean',
  },
  baseFloorPlanMarketRent: {
    path: 'baseFloorPlanMarketRent',
    dataType: 'number',
  },
  ...(unitAmenityHeaders?.length &&
    unitAmenityHeaders.reduce((object, unitAmenityHeaderId) => {
      object[unitAmenityHeaderId] = {
        path: unitAmenityHeaderId,
        dataType: 'number',
      };
      return object;
    }, {})),
  amenityFees: {
    path: 'amenityFees',
    dataType: 'number',
  },
  marketRent: {
    path: 'marketRent',
    dataType: 'number',
  },
  totalGrossRent: {
    path: 'totalGrossRent',
    dataType: 'number',
  },
  grossRentLimit: {
    path: 'grossRentLimit',
    dataType: 'number',
  },
  netMaxRent: {
    path: 'netMaxRent',
    dataType: 'number',
  },
  priorLeasedRent: {
    path: 'priorLeasedRent',
    dataType: 'number',
  },
  leasedRent: {
    path: 'leasedRent',
    dataType: 'number',
  },
  residentRent: {
    path: 'residentRent',
    dataType: 'number',
  },
  subsidyRent: {
    path: 'subsidyRent',
    dataType: 'number',
  },
  basicRent: {
    path: 'basicRent',
    dataType: 'number',
  },
  noteRent: {
    path: 'noteRent',
    dataType: 'number',
  },
  nonOptionalCharge: {
    path: 'nonOptionalCharge',
    dataType: 'number',
  },
  actualUa: {
    path: 'actualUa',
    dataType: 'number',
  },
  utilityAllowances: {
    path: 'utilityAllowances',
    dataType: 'number',
  },
  chargedUnitFees: {
    path: 'chargedUnitFees',
    dataType: 'number',
  },
  balance: {
    path: 'balance',
    dataType: 'number',
  },
  moveOutDate: {
    path: 'moveOutDate',
    dataType: 'date',
  },
  moveInReady: {
    path: 'moveInReady',
    dataType: 'date',
  },
  daysVacant: {
    path: 'daysVacant',
    dataType: 'number',
  },
  quotingRent: {
    path: 'quotingRent',
    dataType: 'number',
  },
  applicant: {
    path: 'applicant',
    dataType: 'string',
  },
  applicantStatus: {
    path: 'applicantStatus',
    dataType: 'string',
  },
  scheduledMoveInDate: {
    path: 'scheduledMoveInDate',
    dataType: 'date',
  },
});

export const RENT_ROLL_TOTAL_COLUMNS = {
  sqFt: { variant: 'number', value: 0 },
  baseFloorPlanMarketRent: { variant: 'currency', value: 0 },
  amenityFees: { variant: 'currency', value: 0 },
  marketRent: { variant: 'currency', value: 0 },
  netMaxRent: { variant: 'currency', value: 0 },
  priorLeasedRent: { variant: 'currency', value: 0 },
  leasedRent: { variant: 'currency', value: 0 },
  residentRent: { variant: 'currency', value: 0 },
  chargedUnitFees: { variant: 'currency', value: 0 },
  subsidyRent: { variant: 'currency', value: 0 },
  basicRent: { variant: 'currency', value: 0 },
  noteRent: { variant: 'currency', value: 0 },
  balance: { variant: 'currency', value: 0 },
  totalGrossRent: { variant: 'currency', value: 0 },
  grossRentLimit: { variant: 'currency', value: 0 },
  actualUa: { variant: 'currency', value: 0 },
  utilityAllowances: { variant: 'currency', value: 0 },
};
