import { useEffect, useState } from 'react';
import MiscTransactionsService from '../../../services/miscTransactionsService';

export const useGetAllFiscalPeriods = ({
  propertyId,
  organizationId,
}: FetchCamRecordStatusHookProps): any => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };

    const fetchData = async () => {
      if (organizationId && propertyId) {
        setIsLoading(true);
        const miscTransactionsService = new MiscTransactionsService();
        const response = await miscTransactionsService.getAllPeriods(
          organizationId,
          propertyId,
          options,
        );
        setData(response);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => abortController.abort();
  }, [organizationId, propertyId]);

  return [data, isLoading];
};
