import React, { useMemo } from 'react';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { useFormContext } from 'react-hook-form';
import { filteringPills } from '../utils';
import { autoOrManualOptions } from '../constants';
import SingleSelect from '../../Forms/SingleSelect';
import MultiSelect from '../../Forms/MultiSelect';
import FilterPills from '../../FilterPills';
import Label from '../../Forms/Label';
import DateRangePicker from '../../Forms/DateRangePicker';
import {
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from '../messages';
import { ActivityFilterOptions } from '../types';
import { blue } from '@fortress-technology-solutions/fortress-component-library/design';

type Props = {
  activityFilterOptions: ActivityFilterOptions,
  prospectId: string,
  intl: Object,
  filters: Object,
  setPage: Function,
};

function ActivityFilters(props: Props) {
  const { control, resetField } = useFormContext();

  const filterByStyles = {
    textAlign: 'left',
    color: `${blue.main}`,
  };

  const filterPills = useMemo(
    () => filteringPills(props.filters, props.activityFilterOptions?.types),
    [props.filters, props.activityFilterOptions?.types],
  );

  return (
    <div aria-label="filter-controls">
      <Stack>
        <FilterPills
          intl={props.intl}
          filterPills={filterPills}
          filters={props.filters}
          setPage={props.setPage}
        />
      </Stack>
      <Typography sx={filterByStyles} variant={'h4'} marginBottom={2}>
        {props.intl.formatMessage(messages.filterBy)}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <DateRangePicker intl={props.intl} />
        </Grid>
        <Grid item xs={2}>
          <Label>{props.intl.formatMessage(messages.status)}</Label>
          <MultiSelect
            control={control}
            options={props.activityFilterOptions?.statuses}
            name={'statuses'}
            placeholder={'Choose'}
            multiple
            onClearSelected={() => resetField('statuses')}
          />
        </Grid>
        <Grid item xs={2}>
          <Label>{props.intl.formatMessage(messages.type)}</Label>
          <MultiSelect
            control={control}
            options={props.activityFilterOptions?.types}
            name={'types'}
            placeholder={'Choose'}
            multiple
            onClearSelected={() => resetField('types')}
          />
        </Grid>
        <Grid item xs={2}>
          <Label>{props.intl.formatMessage(messages.autoOrManual)}</Label>
          <SingleSelect
            control={control}
            options={autoOrManualOptions}
            name={'autoOrManual'}
            placeholder={'Choose'}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default ActivityFilters;
