import React, { useEffect, useState } from 'react';
import { ButtonToolbar, Col, Modal, Row } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from '../messages';
import {
  Button,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { pathOr } from 'ramda';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
} from '../../App/selectors';
import { connect } from 'react-redux';
import EditFloorPlanForm from './EditFloorPlanForm';
import { EDIT_FORM_NAME } from '../constants';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import FloorPlanService from '../../../services/floorPlanService';
import { getAllFloorPlans } from './../../ManageFloorPlans/actions';
function EditFloorPlanModal({
  show,
  onCloseModal,
  actions,
  floorPlan = {},
  context,
  anyTouched,
  invalid,
}) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    actions.getAllFloorPlans();
  }, [actions]);

  const onSubmit = async (values: Object) => {
    const service = new FloorPlanService();
    const { organizationId, propertyId } = context;

    await service.update(floorPlan.id, organizationId, propertyId, values);
    onCloseModal();
  };

  const submitForm = (evt: Object) => {
    evt.preventDefault();
    const { submit } = actions;
    submit(EDIT_FORM_NAME);
  };

  const onCancelClick = () => {
    if (anyTouched) {
      setShowConfirmation(true);
    } else {
      onCloseModal();
    }
  };

  const onConfirmationCancel = () => {
    setShowConfirmation(false);
  };
  const onConfirmationProceed = () => {
    onCloseModal();
    setShowConfirmation(false);
  };

  const renderCancelConfirmation = () => {
    return (
      <div
        className="modal-confirm"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: 16,
          paddingTop: '0px',
        }}
      >
        <Typography variant={'h1'}>
          <FormattedMessage {...messages.cancelConfirmationHeader} />
        </Typography>
        <Stack direction={'row'} spacing={2} paddingTop={2}>
          <Button
            variant="primary"
            onClick={onConfirmationProceed}
            sx={{ flex: 1 }}
          >
            <FormattedMessage {...messages.yes} />
          </Button>
          <Button
            variant="default"
            onClick={onConfirmationCancel}
            sx={{ flex: 1 }}
          >
            <FormattedMessage {...messages.no} />
          </Button>
        </Stack>
      </div>
    );
  };
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      onHide={onCloseModal}
      bsSize="small"
      autoFocus
    >
      <Modal.Header>
        <h1>Edit Floor Plan</h1>
      </Modal.Header>
      <Modal.Body style={{ marginTop: '0px' }}>
        {showConfirmation && renderCancelConfirmation()}
        <EditFloorPlanForm onSubmit={onSubmit} />
      </Modal.Body>
      {!showConfirmation && (
        <Modal.Footer>
          <ButtonToolbar>
            <Row>
              <Col xs={6}>
                <Button
                  fullWidth
                  type="button"
                  variant="default"
                  className="pull-right"
                  onClick={() => onCancelClick()}
                  disabled={false}
                >
                  <FormattedMessage {...messages.cancel} />
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  variant="primary"
                  // style={{ backgroundColor: '#4D73FF', color: '#FFFFFF' }}
                  className="pull-left"
                  onClick={submitForm}
                  disabled={invalid}
                >
                  <FormattedMessage {...messages.saveChanges} />
                </Button>
              </Col>
            </Row>
          </ButtonToolbar>
        </Modal.Footer>
      )}
    </Modal>
  );
}

const mapStateToProps = (state: Object): ConnectedProps => {
  const anyTouched = pathOr(
    false,
    ['form', EDIT_FORM_NAME, 'anyTouched'],
    state,
  );
  const invalid =
    Object.keys(pathOr({}, ['form', EDIT_FORM_NAME, 'syncErrors'], state))
      .length > 0;
  return {
    anyTouched,
    invalid,
    context: {
      propertyId: selectSelectedProperty(state).id,
      organizationId: selectCurrentUserOrganizationId(state),
    },
  };
};

const mapDispatchToProps = (dispatch: Object): ConnectedMethods => ({
  actions: bindActionCreators({ submit, getAllFloorPlans }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EditFloorPlanModal));
