import { defineMessages } from 'react-intl';

const messages = defineMessages({
  showHideColumns: {
    id: 'App.ShowHideColumnsRentRoll.ShowHideColumns',
    defaultMessage: 'Show/Hide Columns',
  },
  clear: {
    id: 'App.ShowHideColumnsRentRoll.Clear',
    defaultMessage: 'Clear All Selected',
  },
});

export default messages;
