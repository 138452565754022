import React, { Component } from 'react';
import moment from 'moment';
import { pathOr, without } from 'ramda';
import { FEE_MODES, FORM_MODES, FEE_MODE_MAP } from './constants';
import FeeList from './FeeList';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
  getAllPricings,
  createPricing,
  updatePricing,
  deletePricing,
} from './actions';
import { getAllUtilityAllowanceTypes } from '../../App/actions';
import { getUtilityAllowanceTypeOptions } from '../../App/selectors';

import componentMessages from './messages';
import generalMessages from '../../App/messages';
const messages = { ...generalMessages, ...componentMessages };

type FormMode = $Values<typeof FORM_MODES>;
type FeeMode = $Values<typeof FEE_MODES>;

type ConnectedProps = {
  currentMarketRent: string,
  currentQuotingRent: string,
  currentDeposit: string,
  currentFinancialMarketRent: string,
  marketRents: Array<Object>,
  quotingRents: Array<Object>,
  financialMarketRents: Array<Object>,
  deposits: Array<Object>,
  allowances: Array<Object>,
  allowanceValues: Array<Object>,
  isAffordable: boolean,
  isAffordableMixedProperty: boolean,
  HUDGrossRent: string,
  HUDGrossRents: Array<Object>,
  isHUDFloorPlan: boolean,
  isRDFloorPlan: boolean,
  isLIHTCFloorPlan: boolean,
  rdRents: Object,
  basicRents: Object,
  noteRent: string,
  basicRent: string,
  noteRents: Array<Object>,
  hud236BasicRents: Array<Object>,
  hud236MarketRents: Array<Object>,
  utilityAllowanceTypes: Array<Object>,
  locale: string,
  currentNonOptionalCharge: string,
  nonOptionalCharges: Array<Object>,
  isAffordableMixedLIHTC: boolean,
  intl: Object,
  isSection236: boolean,
  flags: Object,
};

type ConnectedMethods = {
  actions: {
    getAllPricings: Function,
    createPricing: Function,
    updatePricing: Function,
    deletePricing: Function,
    getAllUtilityAllowanceTypes: Function,
  },
};

type Props = ConnectedProps &
  ConnectedMethods & {
    id: string,
  };

type State = {
  feeMode: ?FeeMode,
  formMode: ?FormMode,
};

export class EditFees extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
      feeMode: null,
      formMode: null,
    };
  }

  componentDidMount() {
    const {
      id,
      actions: { getAllPricings, getAllUtilityAllowanceTypes },
    } = this.props;
    getAllPricings(id);
    getAllUtilityAllowanceTypes();
  }

  resetFeeMode = (feeMode: FeeMode) => {
    if (feeMode === this.state.feeMode) {
      this.setState({ feeMode: null });
    }
  };

  resetMarketRent = () => {
    const { MARKET_RENT } = FEE_MODES;
    this.resetFeeMode(MARKET_RENT);
  };

  resetQuotingRent = () => {
    const { QUOTING_RENT } = FEE_MODES;
    this.resetFeeMode(QUOTING_RENT);
  };

  resetFinancialMarketRent = () => {
    const { FINANCIAL_MARKET_RENT } = FEE_MODES;
    this.resetFeeMode(FINANCIAL_MARKET_RENT);
  };

  resetDeposit = () => {
    const { DEPOSIT } = FEE_MODES;
    this.resetFeeMode(DEPOSIT);
  };

  resetHUDGrossRent = () => {
    const { HUD_GROSS_RENT } = FEE_MODES;
    this.resetFeeMode(HUD_GROSS_RENT);
  };

  resetNoteRent = () => {
    const { NOTE_RENT } = FEE_MODES;
    this.resetFeeMode(NOTE_RENT);
  };
  reset236MarketRent = () => {
    this.resetFeeMode(FEE_MODES['236_MARKET_RENT']);
  };
  resetBasicRent = () => {
    const { BASIC_RENT } = FEE_MODES;
    this.resetFeeMode(BASIC_RENT);
  };

  reset236BasicRent = () => {
    this.resetFeeMode(FEE_MODES['236_BASIC_RENT']);
  };

  setFeeMode = (feeMode: FeeMode) => {
    if (this.state.feeMode === null) this.setState({ feeMode });
  };

  handleCollapse = (type: FeeMode) => {
    this.resetFeeMode(type);
  };

  handleExpand = (type: FeeMode) => {
    this.setFeeMode(type);
  };

  handleCreate = (pricing: Object) => {
    const {
      actions: { createPricing },
    } = this.props;
    createPricing(pricing);
  };

  handleUpdate = (pricing: Object) => {
    const {
      actions: { updatePricing },
    } = this.props;
    updatePricing(pricing);
  };

  handleDelete = (pricing: Object) => {
    const {
      actions: { deletePricing },
    } = this.props;
    deletePricing(pricing);
  };

  editMostRecent = (feeMode: FeeMode) => {
    const fees = this.props?.[FEE_MODE_MAP[feeMode]] ?? null;
    const defaultValue = {
      feeAmount: '0.00',
      startDate: new Date('2018-01-01'),
    };
    if (!Array.isArray(fees)) {
      return defaultValue;
    }

    const mostRecentFee = fees.find((fee) => {
      return fee.endDate === null;
    });
    return mostRecentFee
      ? {
          feeAmount: mostRecentFee.feeAmount,
          startDate: new Date(mostRecentFee.startDate),
        }
      : defaultValue;
  };

  render() {
    const {
      marketRents,
      quotingRents,
      deposits,
      financialMarketRents,
      currentMarketRent,
      currentQuotingRent,
      currentFinancialMarketRent,
      currentDeposit,
      allowances,
      allowanceValues,
      isAffordable,
      isAffordableMixedProperty,
      HUDGrossRent,
      HUDGrossRents,
      hud236MarketRent,
      hud236MarketRents,
      noteRent,
      basicRent,
      basicRents,
      noteRents,
      hud236BasicRent,
      hud236BasicRents,
      isHUDFloorPlan,
      isRDFloorPlan,
      isLIHTCFloorPlan,
      rdRents,
      flags,
      locale,
      utilityAllowanceTypes,
      nonOptionalCharges,
      currentNonOptionalCharge,
      isAffordableMixedLIHTC,
      intl,
      isSection236,
    } = this.props;
    const { feeMode } = this.state;
    const {
      MARKET_RENT,
      QUOTING_RENT,
      DEPOSIT,
      UTILITY_ALLOWANCE,
      HUD_GROSS_RENT,
      NOTE_RENT,
      BASIC_RENT,
      FINANCIAL_MARKET_RENT,
      NON_OPTIONAL_CHARGE,
    } = FEE_MODES;
    const HUD_236_BASIC_RENT = FEE_MODES['236_BASIC_RENT'];
    const HUD_236_MARKET_RENT = FEE_MODES['236_MARKET_RENT'];
    const nonOptionalChargessFeature =
      flags?.nonOptionalChargessFeature ?? false;

    const isRDOnly = isRDFloorPlan && !isLIHTCFloorPlan && !isHUDFloorPlan;

    const allowancesSet = new Set();
    // Use a set to remove duplicate allowances
    allowanceValues.map((allowance) =>
      allowancesSet.add(JSON.stringify(allowance?.allowances)),
    );
    const allowancesArray = Array.from(allowancesSet).map((allowance) =>
      JSON.parse(allowance),
    );

    return (
      <div className="accordion panel-group">
        <FeeList
          title={intl.formatMessage(
            messages[isAffordable ? 'grossRentLimit' : 'currentMarketRent'],
          )}
          collapsed={MARKET_RENT !== feeMode}
          disabled={feeMode !== null && MARKET_RENT !== feeMode}
          type={MARKET_RENT}
          fees={marketRents}
          currentRate={currentMarketRent}
          onCollapse={this.handleCollapse}
          onExpand={this.handleExpand}
          onUpdate={this.handleUpdate}
          onCreate={this.handleCreate}
          onDelete={this.handleDelete}
          flags={flags}
          utilityAllowanceTypes={utilityAllowanceTypes}
          locale={locale}
        />
        {isHUDFloorPlan && (
          <FeeList
            title={intl.formatMessage(messages.hudGrossRentLimit)}
            collapsed={HUD_GROSS_RENT !== feeMode}
            disabled={feeMode !== null && HUD_GROSS_RENT !== feeMode}
            type={HUD_GROSS_RENT}
            fees={HUDGrossRents}
            currentRate={HUDGrossRent}
            onCollapse={this.handleCollapse}
            onExpand={this.handleExpand}
            onUpdate={this.handleUpdate}
            onCreate={this.handleCreate}
            onDelete={this.handleDelete}
            flags={flags}
            utilityAllowanceTypes={utilityAllowanceTypes}
            locale={locale}
          />
        )}
        {isHUDFloorPlan && isSection236 && (
          <FeeList
            title={intl.formatMessage(messages.hud236BasicRent)}
            collapsed={HUD_236_BASIC_RENT !== feeMode}
            disabled={feeMode !== null && HUD_236_BASIC_RENT !== feeMode}
            type={HUD_236_BASIC_RENT}
            fees={hud236BasicRents}
            currentRate={hud236BasicRent}
            onCollapse={this.handleCollapse}
            onExpand={this.handleExpand}
            onUpdate={this.handleUpdate}
            onCreate={this.handleCreate}
            onDelete={this.handleDelete}
            flags={flags}
            utilityAllowanceTypes={utilityAllowanceTypes}
            locale={locale}
          />
        )}
        {isHUDFloorPlan && isSection236 && (
          <FeeList
            title={intl.formatMessage(messages.hud236MarketRent)}
            collapsed={HUD_236_MARKET_RENT !== feeMode}
            disabled={feeMode !== null && HUD_236_MARKET_RENT !== feeMode}
            type={HUD_236_MARKET_RENT}
            fees={hud236MarketRents}
            currentRate={hud236MarketRent}
            onCollapse={this.handleCollapse}
            onExpand={this.handleExpand}
            onUpdate={this.handleUpdate}
            onCreate={this.handleCreate}
            onDelete={this.handleDelete}
            flags={flags}
            utilityAllowanceTypes={utilityAllowanceTypes}
            locale={locale}
          />
        )}
        {isRDFloorPlan && rdRents.noteRent && (
          <FeeList
            title={intl.formatMessage(messages.noteRent)}
            collapsed={NOTE_RENT !== feeMode}
            disabled={feeMode !== null && NOTE_RENT !== feeMode}
            type={NOTE_RENT}
            isRDOnly={isRDOnly}
            fees={noteRents}
            currentRate={noteRent}
            onCollapse={this.handleCollapse}
            onExpand={this.handleExpand}
            onUpdate={this.handleUpdate}
            onCreate={this.handleCreate}
            onDelete={this.handleDelete}
            flags={flags}
            utilityAllowanceTypes={utilityAllowanceTypes}
            locale={locale}
          />
        )}
        {isRDFloorPlan && rdRents.basicRent && (
          <FeeList
            title={intl.formatMessage(messages.basicRent)}
            collapsed={BASIC_RENT !== feeMode}
            disabled={feeMode !== null && BASIC_RENT !== feeMode}
            type={BASIC_RENT}
            fees={basicRents}
            currentRate={basicRent}
            onCollapse={this.handleCollapse}
            onExpand={this.handleExpand}
            onUpdate={this.handleUpdate}
            onCreate={this.handleCreate}
            onDelete={this.handleDelete}
            flags={flags}
            utilityAllowanceTypes={utilityAllowanceTypes}
            locale={locale}
          />
        )}
        <FeeList
          title={intl.formatMessage(messages.currentQuotingRent)}
          collapsed={QUOTING_RENT !== feeMode}
          disabled={feeMode !== null && QUOTING_RENT !== feeMode}
          type={QUOTING_RENT}
          fees={quotingRents}
          currentRate={currentQuotingRent}
          onCollapse={this.handleCollapse}
          onExpand={this.handleExpand}
          onUpdate={this.handleUpdate}
          onCreate={this.handleCreate}
          onDelete={this.handleDelete}
          flags={flags}
          utilityAllowanceTypes={utilityAllowanceTypes}
          locale={locale}
        />
        <FeeList
          title={intl.formatMessage(messages.currentSuggestedDeposit)}
          collapsed={DEPOSIT !== feeMode}
          disabled={feeMode !== null && DEPOSIT !== feeMode}
          type={DEPOSIT}
          fees={deposits}
          currentRate={currentDeposit}
          onCollapse={this.handleCollapse}
          onExpand={this.handleExpand}
          onUpdate={this.handleUpdate}
          onCreate={this.handleCreate}
          onDelete={this.handleDelete}
          flags={flags}
          utilityAllowanceTypes={utilityAllowanceTypes}
          locale={locale}
          isCurrentSuggestedDeposit
        />
        {isAffordableMixedProperty && (
          <FeeList
            title={intl.formatMessage(messages.financialMarketRent)}
            collapsed={FINANCIAL_MARKET_RENT !== feeMode}
            disabled={feeMode !== null && FINANCIAL_MARKET_RENT !== feeMode}
            type={FINANCIAL_MARKET_RENT}
            fees={financialMarketRents}
            currentRate={currentFinancialMarketRent}
            onCollapse={this.handleCollapse}
            onExpand={this.handleExpand}
            onUpdate={this.handleUpdate}
            onCreate={this.handleCreate}
            onDelete={this.handleDelete}
            flags={flags}
            utilityAllowanceTypes={utilityAllowanceTypes}
            locale={locale}
          />
        )}
        {isAffordableMixedLIHTC && nonOptionalChargessFeature && (
          <FeeList
            title={intl.formatMessage(messages.nonOptionalCharges)}
            collapsed={NON_OPTIONAL_CHARGE !== feeMode}
            disabled={feeMode !== null && NON_OPTIONAL_CHARGE !== feeMode}
            type={NON_OPTIONAL_CHARGE}
            fees={nonOptionalCharges}
            isRDOnly={isRDOnly}
            currentRate={currentNonOptionalCharge}
            onCollapse={this.handleCollapse}
            onExpand={this.handleExpand}
            onUpdate={this.handleUpdate}
            onCreate={this.handleCreate}
            onDelete={this.handleDelete}
            flags={flags}
            utilityAllowanceTypes={utilityAllowanceTypes}
            locale={locale}
          />
        )}
        {allowances && isAffordable
          ? allowancesArray.map((allowance, key) => {
              const name = pathOr('', ['pha', 'name'], allowance);
              const allowanceType = `${name}_${UTILITY_ALLOWANCE}`;
              const fees = allowanceValues
                .filter(
                  (allowance) =>
                    name ===
                    pathOr('', ['allowances', 'pha', 'name'], allowance),
                )
                .reduce(
                  (prev, val) => {
                    const valType = pathOr(
                      '',
                      ['allowances', 'pha', 'name'],
                      val,
                    );
                    val.uaTypes = without(
                      [null, undefined],
                      (val?.fpuaTypes ?? []).map(
                        (fpuaType) => fpuaType?.utilityAllowanceType,
                      ),
                    );
                    if (valType === name) prev.list.push(val);
                    const today = moment().unix();
                    const start = moment(val.startDate).startOf('day').unix();
                    const end =
                      val.endDate !== null
                        ? moment(val.endDate).endOf('day').unix()
                        : null;
                    if (today > start && (end === null || today < end)) {
                      prev.currentRate = val.feeAmount;
                    }
                    return prev;
                  },
                  { list: [], currentRate: allowance.utilityAllowanceAmount },
                );
              const utilityAlowancesId = pathOr('', ['id'], allowance);
              const uaMessage = `${name} ${intl.formatMessage(
                messages.utilityAllowance,
              )}`;
              return (
                <FeeList
                  key={key}
                  title={uaMessage}
                  collapsed={allowanceType !== feeMode}
                  disabled={feeMode !== null && allowanceType !== feeMode}
                  type={allowanceType}
                  fees={fees.list}
                  currentRate={fees.currentRate}
                  onCollapse={this.handleCollapse}
                  onExpand={this.handleExpand}
                  onUpdate={this.handleUpdate}
                  onCreate={(pricing) =>
                    this.handleCreate({ ...pricing, utilityAlowancesId })
                  }
                  onDelete={this.handleDelete}
                  flags={flags}
                  utilityAllowanceTypes={utilityAllowanceTypes}
                />
              );
            })
          : null}
      </div>
    );
  }
}

export function mapStateToProps(state: Object): Object {
  return {
    currentMarketRent: state?.viewUnitEditFees?.currentMarketRent ?? '0',
    marketRents: state?.viewUnitEditFees?.marketRents ?? [],
    currentQuotingRent: state?.viewUnitEditFees?.currentQuotingRent ?? '0',
    quotingRents: state?.viewUnitEditFees?.quotingRents ?? [],
    currentFinancialMarketRent:
      state?.viewUnitEditFees?.currentFinancialMarketRent ?? '0',
    HUDGrossRent: state?.viewUnitEditFees?.HUDGrossRent ?? '0',
    HUDGrossRents: state?.viewUnitEditFees?.HUDGrossRents ?? [],
    hud236MarketRents: state?.viewUnitEditFees?.hud236MarketRents ?? [],
    hud236MarketRent: state?.viewUnitEditFees?.hud236MarketRent ?? '0',
    noteRent: state?.viewUnitEditFees?.noteRent ?? '0',
    basicRent: state?.viewUnitEditFees?.basicRent ?? '0',
    noteRents: state?.viewUnitEditFees?.noteRents ?? [],
    basicRents: state?.viewUnitEditFees?.basicRents ?? [],
    hud236BasicRents: state?.viewUnitEditFees?.hud236BasicRents ?? [],
    hud236BasicRent: state?.viewUnitEditFees?.hud236BasicRent ?? '0',
    currentDeposit: state?.viewUnitEditFees?.currentDeposit ?? '0',
    deposits: state?.viewUnitEditFees?.deposits ?? [],
    currentNonOptionalCharge:
      state?.viewUnitEditFees?.currentNonOptionalCharge ?? '0',
    nonOptionalCharges: state?.viewUnitEditFees?.nonOptionalCharges ?? [],
    allowanceValues: state?.viewUnitEditFees?.allowances ?? [],
    utilityAllowanceTypes: getUtilityAllowanceTypeOptions(state),
    locale: state?.languageProvider?.locale,
    financialMarketRents: state?.viewUnitEditFees?.financialMarketRents ?? [],
  };
}

export function mapDispatchToProps(dispatch: Object): ConnectedMethods {
  return {
    actions: bindActionCreators(
      {
        getAllPricings,
        createPricing,
        updatePricing,
        deletePricing,
        getAllUtilityAllowanceTypes,
      },
      dispatch,
    ),
  };
}

const connected = connect(mapStateToProps, mapDispatchToProps)(EditFees);

export default withLDConsumer()(connected);
