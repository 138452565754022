import moment from 'moment/moment';

export const isRecertLate = (recertDue: string = ''): boolean => {
  const today = moment().startOf('day');
  const recertDueMoment = moment(recertDue);

  if (!recertDueMoment.isValid()) {
    return false;
  }

  recertDueMoment.subtract(1, 'months').endOf('month');

  return today.isAfter(recertDueMoment);
};
