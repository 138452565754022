import {
  useRef,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import {
  useResizeObserver,
  useMutationObserver,
  useGetRefById,
} from '@fortress-technology-solutions/fortress-component-library/hooks';
import { styled } from '@fortress-technology-solutions/fortress-component-library/utils';
import {
  APP_BAR_HEIGHT,
  PADDING,
  MUTATION_OBSERVER_OPTIONS,
} from './constants';
import { debounce } from 'lodash';
import { calculateChildrenHeight, calculatePageHeight } from './utils';

const Container = styled('div')({
  height: '100%',
});

const INITIAL_STATE_ONLY = false;
const TableLayout = ({
  children,
  name,
  watch,
  sx,
  childrenElementsHeight = 0,
  containerElementsHeight = 0,
}) => {
  const ref = useRef(null);
  const previousParentHeight = useRef(null);
  // elements that take up space on page
  const homeTabRef = useGetRefById('home-tab');
  const homeTabContentRef = useGetRefById('home-tab-content');

  const [containerHeight, setContainerHeight] = useState(
    window.innerHeight - (APP_BAR_HEIGHT + containerElementsHeight),
  );
  const [tableHeight, setTableHeight] = useState(
    containerHeight - (childrenElementsHeight + PADDING),
  );

  const calculatePageHeightCallback = useCallback(
    (body) => {
      debounce(() => {
        const containerHeight = calculatePageHeight({
          homeTabContentRef,
          homeTabRef,
          previousParentHeight,
          body,
        });

        if (!isNaN(containerHeight) && !INITIAL_STATE_ONLY) {
          setContainerHeight((prevContainerHeight) =>
            containerHeight > 0 ? containerHeight : prevContainerHeight,
          );
        }
      }, 300)();
    },
    [homeTabContentRef, homeTabRef],
  );

  const calculateChildrenHeightCallback = useCallback(
    ({ children }) => {
      debounce(() => {
        const tableHeight = calculateChildrenHeight({
          children,
          containerHeight,
          name,
        });

        if (!isNaN(tableHeight) && !INITIAL_STATE_ONLY) {
          setTableHeight((prevTableHeight) =>
            tableHeight > 0 ? tableHeight : prevTableHeight,
          );
        }
      }, 300)();
    },
    [containerHeight, name],
  );

  // mutation observer to watch for changes in children
  useMutationObserver(
    ref,
    useCallback(
      (mutationsList, observer) => {
        // If the target node or its children's attributes change, do something
        for (let mutation of mutationsList) {
          if (mutation.type === 'childList') {
          } else if (mutation.type === 'attributes') {
            if (!ref.current) return;
            calculateChildrenHeightCallback(ref.current);
          }
        }
      },
      [calculateChildrenHeightCallback],
    ),
    MUTATION_OBSERVER_OPTIONS,
  );

  useResizeObserver(
    document.body,
    useCallback(
      (entry) => {
        if (previousParentHeight.current === entry.target.clientHeight) {
          return;
        }
        calculatePageHeightCallback(entry.target);
      },
      [calculatePageHeightCallback],
    ),
  );

  useEffect(() => {
    if (!ref.current) return;
    calculateChildrenHeightCallback(ref.current);
  }, [calculateChildrenHeightCallback, containerHeight]);

  useLayoutEffect(() => {
    if (!ref.current?.children) return;

    // resize observe the children that match the watch ids
    const children = ref.current.children;
    const resizeObserver = new ResizeObserver((entries) => {
      if (!ref.current) return;
      calculateChildrenHeightCallback(ref.current);
    });

    Array.from(children).forEach((child) => {
      if (watch?.includes(child.id)) {
        resizeObserver.observe(child);
      }
    });
  }, [calculateChildrenHeightCallback, watch]);

  return (
    <Container
      ref={ref}
      id={`table-layout_${name}`}
      sx={{
        overflow: 'hidden',
        height: containerHeight - 1,
        padding: 2,
        ...sx,
      }}
    >
      {children(tableHeight)}
    </Container>
  );
};

export default TableLayout;
