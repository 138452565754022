import * as ActionTypes from './constants';

export function login(payload: any): * {
  return {
    type: ActionTypes.LOGIN,
    payload,
  };
}

export function forgetMe(payload: any): * {
  return {
    type: ActionTypes.FORGET_ME,
    payload,
  };
}

export function loginFail(payload: any): * {
  return {
    type: ActionTypes.LOGIN_FAIL,
    payload,
  };
}

export function clearMessages(): * {
  return {
    type: ActionTypes.CLEAR_MESSAGES,
  };
}

export function loginSuccess(): * {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    payload: undefined,
  };
}

export function startTokenRenewal(): * {
  return {
    type: ActionTypes.START_TOKEN_RENEWAL,
    payload: undefined,
  };
}

export function startUserIdleDetection(): * {
  return {
    type: ActionTypes.START_USER_IDLE_DETECTION,
    payload: undefined,
  };
}

export function tokenRenewalProcessStopped(): * {
  return {
    type: ActionTypes.STOPPED_TOKEN_RENEWAL_PROCESS,
    payload: undefined,
  };
}

export function renewUserToken(token: string): * {
  return {
    type: ActionTypes.RENEW_USER_TOKEN,
    payload: token,
  };
}

export function renewUserTokenSuccess(): * {
  return {
    type: ActionTypes.RENEW_USER_TOKEN_SUCCESS,
    payload: undefined,
  };
}

export function isUserIdle(): * {
  return {
    type: ActionTypes.USER_IS_IDLE,
    payload: undefined,
  };
}
export function isUserActive(): * {
  return {
    type: ActionTypes.USER_IS_ACTIVE,
    payload: undefined,
  };
}

export function cancellingUserIdleProcess(): * {
  return {
    type: ActionTypes.CANCELLING_USER_IDLE_PROCESS,
    payload: undefined,
  };
}

export function handleSocketExpiredSession(): * {
  return {
    type: ActionTypes.HANDLE_SOCKET_LOGOUT_EXPIRED_SESSION,
    payload: undefined,
  };
}
