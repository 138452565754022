import { put, get } from '../utils/api';

export default class IntegrationService {
  updateIntegrationProperty(
    organizationId: string,
    propertyId: string,
    integrationId: string,
    data: Object,
  ) {
    return put(
      `/${organizationId}/${propertyId}/integration/${integrationId}/integration-property`,
      JSON.stringify(data),
    );
  }

  getIntegrations(
    organizationId: string,
    propertyId: string,
    queryParams?: Object,
  ): Promise<Object> {
    const queryParamsArray = Object.entries(queryParams).map(
      ([key, value]) => `${key}=${value}`,
    );
    const hasParams = queryParamsArray.length > 0;
    const queryParamsString = queryParamsArray.join('&');
    return get(
      `/${organizationId}/${propertyId}/integrations${
        hasParams ? `?${queryParamsString}` : ''
      }`,
    );
  }
}
