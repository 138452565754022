import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ManageProperties.WOHouseholdPreferences.Header',
    defaultMessage: 'Work Order Details',
  },
  cancel: {
    id: 'App.ManageProperties.WOHouseholdPreferences.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.ManageProperties.WOHouseholdPreferences.Save',
    defaultMessage: 'Save Changes',
  },
  confirmationTitle: {
    id: 'App.ManageProperties.WOHouseholdPreferences.Title',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },

  confirmationCancel: {
    id: 'App.ManageProperties.WOHouseholdPreferences.Confirmation.Cancel',
    defaultMessage: 'No',
  },
  confirm: {
    id: 'App.ManageProperties.WOHouseholdPreferences.Confirmation.Confirm',
    defaultMessage: 'Yes',
  },
  success: {
    id: 'App.ManageProperties.WOHouseholdPreferences.Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'App.ManageProperties.WOHouseholdPreferences.Error',
    defaultMessage: 'Error',
  },
  successBody: {
    id: 'App.ManageProperties.WOHouseholdPreferences.successBody',
    defaultMessage: 'Work Order Preferences saved',
  },
  errorBody: {
    id: 'App.ManageProperties.WOHouseholdPreferences.errorBody',
    defaultMessage: 'Error saving Work Order Preferences',
  },
});

export default messages;
