import { useMemo } from 'react';
import store from '../../store';

const usePermissionsHook = ({ scope = [] }: { scope: string[] }) => {
  return useMemo(() => {
    const userPermissions: { scope: string }[] =
      store.getState()?.app?.currentUser?.permissions ?? [];
    return userPermissions.some((permission) =>
      scope?.some((value) => value === permission.scope),
    );
  }, [scope]);
};
export default usePermissionsHook;
