import { useState, useContext, useEffect, useMemo } from 'react';
import { toastr } from 'react-redux-toastr';
import * as R from 'ramda';
import messages from '../messages';
import LeaseExpirationLimitService, {
  LimitUpdate,
} from '../../../services/leaseExpirationLimitService';
import { PageContext } from '../context';

type LimitData = {
  leaseCount: number,
  limit?: number,
  limitId?: string,
};

type LastUpdated = {
  by: string,
  on: string,
};

type UseLimitDataResponse = {
  limitData: LimitData[],
  unitCount: number,
  isLoading: boolean,
  isSaving: boolean,
  isDirty: boolean,
  editLimit: (month: number, newLimit: number) => void,
  saveChanges: Function,
  clearChanges: Function,
  lastUpdated: LastUpdated,
};

const generateLimitUpdates = (
  limitData: LimitData[],
  originalLimitData: LimitData[],
): LimitUpdate[] => {
  return originalLimitData.reduce(
    (updates, cur, month) =>
      cur.limit !== limitData[month].limit
        ? [...updates, { id: cur.limitId, limit: limitData[month].limit }]
        : [...updates],
    [],
  );
};

export function useLimitData(year: string): UseLimitDataResponse {
  const {
    formatMessage,
    organizationId,
    propertyId,
    onEditLimitsSuccess,
    onTableDirty,
    graphData,
  } = useContext(PageContext);
  const [originalLimitData, setOriginalLimitData] = useState([]);
  const [limitData, setLimitData] = useState([]);
  const [unitCount, setUnitCount] = useState(0);
  const [lastUpdated, setLastUpdated] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const limitUpdates = useMemo(
    () => generateLimitUpdates(limitData, originalLimitData),
    [limitData, originalLimitData],
  );
  const isDirty = useMemo(() => limitUpdates.length > 0, [limitUpdates]);

  const fetchData = async () => {
    if (isLoading || isSaving) return;

    setIsLoading(true);

    try {
      const service = new LeaseExpirationLimitService();
      const response = await service.getLimitDataByYears(
        organizationId,
        propertyId,
        [year],
      );

      const yearData = response[year];
      const ld = yearData.limitData;

      setLimitData(ld);
      setOriginalLimitData([...ld]);
      setLastUpdated({
        by: yearData.lastUpdatedBy,
        on: yearData.lastUpdatedOn,
      });
      setUnitCount(response.unitCount);
    } catch {
      toastr.error(
        formatMessage(messages.error),
        formatMessage(messages.limitDataFetchError),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const saveChanges = async () => {
    if (isLoading || isSaving) return;

    setIsSaving(true);

    try {
      const service = new LeaseExpirationLimitService();

      await service.editLimits(organizationId, propertyId, limitUpdates);

      setIsSaving(false);

      onEditLimitsSuccess?.();
    } catch {
      toastr.error(
        formatMessage(messages.error),
        formatMessage(messages.limitDataSaveError),
      );
      setIsLoading(false);
    }
  };

  const editLimit = (month: number, newLimit: number) => {
    if (newLimit < 0) return;

    const oldMonthData = limitData[month];

    if (!oldMonthData) return;

    setLimitData(
      R.update(month, { ...oldMonthData, limit: newLimit }, limitData),
    );
  };

  const clearChanges = () => {
    setLimitData([...originalLimitData]);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData]);

  useEffect(() => {
    onTableDirty(year, isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    limitData,
    unitCount,
    isLoading,
    editLimit,
    isDirty,
    saveChanges,
    isSaving,
    clearChanges,
    lastUpdated,
  };
}
