import moment, { Moment } from 'moment';
import isEmpty from 'lodash/isEmpty';

import { sortByDateField } from '../../../utils/date-helpers';

import type { PassbookRate } from '../AffordableSetup/Programs/shared';

export const stripOnlyWhiteSpaceToNull = (val) => {
  const isWhitespace = (val || '').trim().length === 0;
  return isWhitespace ? null : val;
};

export const displayAsDashesIfEmpty = (val) => {
  return val ? val : '---';
};

export const getProgramPassbookRatesDataByProgramName = (
  programName: string,
  passbookRates: Array<PassbookRate>,
): Array<PassbookRate> => {
  const programPassbookRates = (passbookRates ?? []).filter(
    (rate) => rate.programName === programName,
  );
  return sortByDateField(programPassbookRates, 'startDate', true);
};

export const isDateBetweenRange = (
  date: Moment,
  startDate?: string = moment().toISOString(),
  endDate?: string = '',
): boolean => {
  const mStartDate = moment(startDate);
  const mEndDate = !isEmpty(endDate)
    ? moment(endDate)
    : moment().add(100, 'year');
  return date.isBetween(mStartDate, mEndDate, 'day', '[]');
};
