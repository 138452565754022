import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import { reduxForm, Field, submit } from 'redux-form';
import {
  renderBlockDataCurrencyField,
  renderBlockDataSelectField,
  renderBlockDataTextField,
  required,
  requiredSelect,
  number,
  maxlength
} from '../../utils/redux-form-helper';
import MUPContext from './Context';

const Header = () => (
  <MUPContext.Consumer>
    {(ctx) => (
      <Row className="form-header">
        <Col md={4}>
          <h2 className="float-left">Create Deposit</h2>
        </Col>
        <Col md={8} className="text-right">
          <div className="highlight-details highlight-details--nobg">
            <span>Batch ID: Unsaved Deposit</span>
            <span className="no-third">Period: {ctx.period}</span>
          </div>
        </Col>
      </Row>
    )}
  </MUPContext.Consumer>
);

const Actions = connect(
  undefined,
  { submit }
)((props) => (
  <MUPContext.Consumer>
    {(ctx) => (
      <Row>
        <Col xs={12}>
          <div className="form-footer padtop20">
            <div className="button-group">
              <Button onClick={ctx.onCancel} disabled={ctx.disableCancel}>
                Cancel
              </Button>
              <Button
                bsStyle="primary"
                onClick={() => props.submit('depositHeaderForm')}
                disabled={ctx.disableSubmit}
              >
                Create Deposit
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    )}
  </MUPContext.Consumer>
));

const parseTotal = (val?: string) => (val ? Number(val) : val);
const noteMaxLength = maxlength(50, 'Note cannot exceed 50 characters.');

const Form = reduxForm({
  form: 'depositHeaderForm',
  initialValues: { bankAccountId: 'default' }
})((props) => (
  <MUPContext.Consumer>
    {(ctx) => (
      <form onSubmit={props.handleSubmit}>
        <Row className="padtop10">
          <Col md={3}>
            <Field
              name="depositTotal"
              label="Deposit Total"
              placeholder="0.00"
              parse={parseTotal}
              validate={[required, number]}
              component={renderBlockDataCurrencyField}
            />
          </Col>
          <Col md={2}>
            <Field
              name="bankAccountId"
              label="Bank Account"
              options={ctx.bankAccountOptions}
              validate={requiredSelect}
              component={renderBlockDataSelectField}
            />
          </Col>
          <Col md={2}>
            <div className="block-data block-white-shadow">
              <h5>Batch Date</h5>
              <span>{ctx.batchDate}</span>
            </div>
          </Col>
          <Col md={5}>
            <Field
              name="note"
              label="Note"
              validate={noteMaxLength}
              component={renderBlockDataTextField}
            />
          </Col>
        </Row>
      </form>
    )}
  </MUPContext.Consumer>
));

export default () => [
  <Grid key="grid" fluid>
    <Header />
    <MUPContext.Consumer>
      {(ctx) => <Form onSubmit={ctx.onSubmit} />}
    </MUPContext.Consumer>
    <Row />
    <Col xs={12}>
      <p>
        <strong>NOTE:</strong> Total Selected Deposits must equal Deposit Total
        in order to Create Deposit.{' '}
      </p>
    </Col>
  </Grid>,
  <Actions key="actions" />
];
