import * as R from 'ramda';
import type { ContactFormValues } from './types';
import {
  validatePhoneNumber,
  validateName,
  validateEmail,
  validateNumber,
} from '../../../utils/validations';

const requiredFields = [
  'commercialContactTypeId',
  'contactName',
  'phoneNumber',
  'emailAddress',
  'contactAddress',
  'city',
  'stateId',
  'zipCode',
];

function validate(values: ContactFormValues) {
  const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty]);

  const errors: Object = requiredFields.reduce(
    (errors, field) =>
      isNilOrEmpty(values[field])
        ? { ...errors, [field]: 'Required Field' }
        : errors,
    {},
  );

  if (
    R.isNil(errors.zipCode) &&
    (!validateNumber(values.zipCode) || values.zipCode.length !== 5)
  )
    errors.zipCode = 'Invalid Zip Code / Postal Code';

  if (R.isNil(errors.phoneNumber) && !validatePhoneNumber(values.phoneNumber))
    errors.phoneNumber = 'Invalid Phone Number';

  if (R.isNil(errors.contactName) && !validateName(values.contactName))
    errors.contactName = 'Invalid Contact Name';

  if (R.isNil(errors.emailAddress) && !validateEmail(values.emailAddress))
    errors.emailAddress = 'Invalid Email';

  if (
    R.isNil(errors.secondaryPhoneNumber) &&
    !validatePhoneNumber(values.secondaryPhoneNumber)
  )
    errors.secondaryPhoneNumber = 'Invalid Phone Number';

  return errors;
}

export default validate;
