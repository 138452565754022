import { useMemo } from 'react';
import { useQuery } from 'react-query';
import UnitService from '../../services/unitService';

export const useFetchAllUnitAvailablity = () => {
  const unitService = useMemo(() => new UnitService(), []);
  const abortController = new AbortController();
  const queryKey = [];
  const options = { signal: abortController.signal };

  const { data, isLoading } = useQuery(
    ['CentralizedUnits', queryKey],
    () => unitService.getCentralizedUnitAvailability(options),
    { enabled: true, refetchOnWindowFocus: false },
  );

  const units = useMemo(() => data ?? [], [data]);

  return {
    data: units,
    isLoading,
  };
};
