import type { Action } from '../App/types';
import type { ShortLeaseApplicationState } from './types';
import * as ActionTypes from './constants';

const initialState: ShortLeaseApplicationState = {
  applicant: undefined,
  isSubmitting: false,
};

const shortLeaseApplicationReducer = (
  state: ShortLeaseApplicationState = initialState,
  action: Action<*>,
): ShortLeaseApplicationState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_APPLICANT_INFORMATION_SUCCESS: {
      return { ...state, applicant: payload };
    }
    case ActionTypes.GET_APPLICANT_INFORMATION_ERROR: {
      return { ...state, applicant: initialState.applicant };
    }
    case ActionTypes.SAVE_APPLICANT_FORM: {
      return { ...state, isSubmitting: true };
    }
    case ActionTypes.SAVE_APPLICANT_FORM_SUCCESS: {
      return { ...state, isSubmitting: false };
    }
    case ActionTypes.SAVE_APPLICANT_FORM_ERROR: {
      return { ...state, isSubmitting: false };
    }
    case ActionTypes.CLEAN:
      return initialState;
    default:
      return state;
  }
};

export default shortLeaseApplicationReducer;
