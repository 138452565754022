import type { LoginState } from '../types';
import messages from './messages';

const validate = (values: LoginState, { intl }: Object) => {
  const errors = {};

  if (!values.username) {
    errors.username = intl.formatMessage(messages.requiredFields);
  }

  if (!values.password) {
    errors.password = intl.formatMessage(messages.requiredFields);
  }

  return errors;
};

export default validate;
