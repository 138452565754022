import {
  getElapsedMonthProratedAmount,
  getRemainingMonthProratedAmount,
} from '../../../utils/prorateCalculator.js';

export const getTransactionTypeOptions = (transactionTypes) => {
  return !!transactionTypes
    ? [
        { value: '', text: 'Choose', disabled: true },
        ...transactionTypes
          .filter(
            (tt) =>
              tt?.name === 'Credit' ||
              tt?.name === 'Charge' ||
              tt?.name === 'Security Charge',
          )
          .map((tt) => {
            return {
              value: tt?.id,
              text: tt?.name,
              disabled: false,
            };
          }),
      ]
    : [{ value: '', text: 'Choose', disabled: true }];
};

export const calculateProrateAmount = ({ amount, date, computeForMoveIn }) => {
  if (!date) {
    return null;
  }
  const prorateAmount = computeForMoveIn
    ? getRemainingMonthProratedAmount(date, amount)
    : getElapsedMonthProratedAmount(date, amount);

  return prorateAmount;
};
