import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import { bindActionCreators } from 'redux';
import { map, pathOr } from 'ramda';
import moment from 'moment';
import confirm from '../../components/ConfirmDialogModal';
import * as createResidentActions from './actions';
import { getMonthlyTransactions } from '../ApplicationProfile/actions';
import { MoveInCheckApplicationScreen } from '../../components/MoveInCheck';
import { getMoveInProratedTransactions } from '../../utils/prorateCalculator';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import messages from './messages';

import {
  getApplicantName,
  getLeaseStartDate,
  getUnitNumber,
} from './selectors';
import { getSelectedPropertyClassType } from '../App/selectors';
import { generateComplianceStatusArray } from './utils';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import type { GlobalState } from '../App/types';

import { FeatureFlags } from '../../types';

type StateProps = {
  locale: string,
  vehicleStatus?: Object,
  moveInActions?: Array<Object>,
  applicationId: string,
  checklistStatus?: Object,
  screeningStatus?: Object,
  signedLeaseStatus?: Object,
  leaseStatus?: Object,
  leaseStartDateStatus?: Object,
  unitAvailabilityStatus?: Object,
  applicantName: string,
  unitNumber: string,
  leaseStartDate: Object,
  monthlyTransactions?: Array<Object>,
  propertyClassType: string,
  currentApplication: ?Object,
  location?: any,
  leaseMoveInDate: string,
  property: Object,
};
type Props = {
  intl: any,
  propertyId: string,
  history: Object,
  store: Object,
  moveInApplyFullAmountToProRate: boolean,
  flags: Object,
  propertyDetails: Object,
  actions: {
    getChecklistStatus: (applicant: string) => Object,
    getSignedLeaseStatus: (applicant: string) => Object,
    getScreeningStatus: (applicant: string) => Object,
    getVehicleStatus: (applicant: string) => Object,
    getMoveInActionsStatus: () => Object,
    getLeaseStatus: (applicant: string) => Object,
    getLeaseStartDateStatus: (applicant: string) => Object,
    getOneApplication: (applicant: string) => void,
    getOneProperty: (applicant: string) => void,
    clean: () => void,
    moveIn: (
      applicationId: string,
      actualMoveInDate: any,
      requiredActions: Array<Object>,
      moveInWithoutScreening: boolean,
      prorated: Array<Object>,
    ) => void,
    getUnitAvailabilityStatus: (unitId: string) => Object,
    getMonthlyTransactions: (applicationId: string) => Object,
  },
};

type State = {
  actualMoveInDate: any,
  moveInActions: Array<any>,
};

export class CreateResident extends Component<StateProps & Props, State> {
  constructor(props: StateProps & Props) {
    super(props);
    this.state = {
      actualMoveInDate: moment(new Date()).format('YYYY-MM-DD'),
      moveInActions: [],
    };
  }

  componentWillMount() {
    this.props.actions.clean();
  }

  componentDidMount() {
    const applicationId: string = this.props.applicationId;
    this.props.actions.getVehicleStatus(applicationId);
    this.props.actions.getMoveInActionsStatus();
    this.props.actions.getOneApplication(applicationId);
    this.props.actions.getChecklistStatus(applicationId);
    this.props.actions.getSignedLeaseStatus(applicationId);
    this.props.actions.getScreeningStatus(applicationId);
    this.props.actions.getLeaseStatus(applicationId);
    this.props.actions.getLeaseStartDateStatus(applicationId);
    this.props.actions.getUnitAvailabilityStatus(applicationId);
    this.props.actions.getMonthlyTransactions(applicationId);
    if (this.props.flags.transunionOffLeaseAppOff) {
      this.props.actions.getOneProperty(applicationId);
    }
  }

  handleCancel = () => {
    const route = `/application/${this.props.applicationId}`;
    navigateToUrlWithSelectedPropertyId(route);
  };

  handleMoveInDateUpdate = (moveInActionsField: Array<any>, newDate: any) => {
    this.setState({
      actualMoveInDate: moment(newDate).format('YYYY-MM-DD'),
      moveInActions: moveInActionsField,
    });
  };

  handleSubmit = (values: any) => {
    confirm(this.props.intl.formatMessage(messages.convertConfirmation), {
      intl: this.props.intl,
    })
      .then(() => {
        const requiredActions: Array<any> = map(
          (action) => ({
            ...action,
            valid: values['moveInActions'][action.id] || true,
          }),
          this.getApplicableMoveInActions() || [],
        );

        const transUnionActive = this.props.propertyDetails?.isTransUnionActive;

        const moveInWithoutScreeningFlagOn = transUnionActive
          ? values.moveInWithoutScreening
          : true;

        const moveInWithoutScreening = this.props.flags.transunionOffLeaseAppOff
          ? moveInWithoutScreeningFlagOn
          : values.moveInWithoutScreening;

        this.props.actions.moveIn(
          this.props.applicationId,
          values.actualMoveInDate,
          requiredActions,
          moveInWithoutScreening,
          values.prorated,
        );
      })
      .catch(() => {});
  };

  getApplicableMoveInActions = (): Array<Object> => {
    const { propertyDetails, flags } = this.props;
    const isTransUnionActive = propertyDetails?.isTransUnionActive;

    const moveInActionsFlagOn = isTransUnionActive
      ? this.props.moveInActions.filter(
          (action: Object) =>
            action.text !== 'Screening Completed (if applicable)',
        )
      : this.props.moveInActions;

    const moveInActions = flags?.transunionOffLeaseAppOff
      ? moveInActionsFlagOn
      : this.props.moveInActions.filter(
          (action: Object) =>
            action.text !== 'Screening Completed (if applicable)',
        );
    return moveInActions;
  };

  render() {
    const {
      applicantName,
      checklistStatus,
      intl,
      leaseStartDate,
      leaseStartDateStatus,
      leaseStatus,
      screeningStatus,
      signedLeaseStatus,
      unitNumber,
      vehicleStatus,
      unitAvailabilityStatus,
      monthlyTransactions,
      propertyClassType,
      currentApplication,
      leaseMoveInDate,
      moveInApplyFullAmountToProRate,
      propertyDetails,
      property,
    } = this.props;
    const flags: FeatureFlags = this.props.flags;
    const applicantPropertyClass = pathOr(
      '',
      ['propertyClass', 'name'],
      currentApplication,
    );
    const isAffordable =
      propertyClassType === 'Affordable' ||
      (propertyClassType === 'Mixed' &&
        applicantPropertyClass === 'Affordable');

    let statusArray = [];
    const complianceStatuses = generateComplianceStatusArray(
      currentApplication,
      property?.pap,
      flags,
    );
    statusArray = isAffordable ? [...complianceStatuses] : [];

    const roundProratedRents = propertyDetails?.config?.roundProratedRents;

    const proratedTransactions = getMoveInProratedTransactions(
      monthlyTransactions || [],
      this.state.actualMoveInDate,
      moveInApplyFullAmountToProRate,
      false,
      roundProratedRents,
      flags?.correctProrateOnMoveinScreen ?? false,
    );
    const isTransUnionActive = propertyDetails?.isTransUnionActive;
    const moveInActions = this.getApplicableMoveInActions();

    const allSteps = [
      {
        ...unitAvailabilityStatus,
        title: this.props.intl.formatMessage(messages.unitAvailabilityStatus),
      },
      {
        ...checklistStatus,
        title: this.props.intl.formatMessage(messages.applicationCheckList),
      },
      {
        ...screeningStatus,
        title: this.props.intl.formatMessage(messages.householdScreening),
      },
      {
        ...leaseStatus,
        title: this.props.intl.formatMessage(messages.leaseStatus),
      },
      {
        ...signedLeaseStatus,
        title: this.props.intl.formatMessage(messages.signedLease),
      },
      {
        ...vehicleStatus,
        title: this.props.intl.formatMessage(messages.vehicleInformation),
      },
      {
        ...leaseStartDateStatus,
        title: this.props.intl.formatMessage(messages.leaseStartDateStatus),
      },

      ...statusArray,
    ];

    const stepsFlagOn = isTransUnionActive
      ? allSteps
      : allSteps.filter((step: Object) => step.title !== 'Household Screening');

    const steps = flags?.transunionOffLeaseAppOff ? stepsFlagOn : allSteps;

    const show =
      vehicleStatus &&
      checklistStatus &&
      screeningStatus &&
      signedLeaseStatus &&
      leaseStatus &&
      leaseStartDateStatus &&
      moveInActions &&
      unitAvailabilityStatus &&
      monthlyTransactions;
    return (
      <DocumentTitle title="Fortress - Convert to Resident">
        {show && (
          <MoveInCheckApplicationScreen
            applicantName={applicantName}
            handleCancel={this.handleCancel}
            intl={intl}
            leaseStartDate={leaseStartDate}
            moveInActions={moveInActions}
            onSubmit={this.handleSubmit}
            steps={steps}
            unitNumber={unitNumber}
            monthlyTransactions={proratedTransactions}
            handleMoveInDateUpdate={this.handleMoveInDateUpdate}
            isTransUnionActive={isTransUnionActive}
            openFiscalPeriod={pathOr(
              null,
              ['openFiscalPeriod'],
              this.props.currentApplication,
            )}
            initialValues={{
              actualMoveInDate: this.state.actualMoveInDate,
              prorated: proratedTransactions,
              moveInActions: this.state.moveInActions,
              previousLeaseMoveOutDate: pathOr(
                null,
                ['previousLeaseMoveOutDate'],
                unitAvailabilityStatus,
              ),
            }}
            leaseMoveInDate={leaseMoveInDate}
            isAffordable={isAffordable}
            moveInApplyFullAmountToProRate={moveInApplyFullAmountToProRate}
            roundProratedRents={roundProratedRents}
          />
        )}
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): StateProps => {
  const { languageProvider, createResident } = state;
  // $FlowFixMe
  const leaseMoveInDate = pathOr(
    '',
    ['currentApplication', 'au', 'lease', 'moveInDate'],
    createResident,
  );
  return {
    applicationId: ownProps.match.params.applicationId,
    locale: languageProvider.locale,
    vehicleStatus: createResident.vehicleStatus,
    propertyDetails: createResident.propertyDetails,
    checklistStatus: createResident.checklistStatus,
    screeningStatus: createResident.screeningStatus,
    signedLeaseStatus: createResident.signedLeaseStatus,
    moveInActions: createResident.moveInActions,
    leaseStatus: createResident.leaseStatus,
    leaseStartDateStatus: createResident.leaseStartDateStatus,
    unitAvailabilityStatus: createResident.unitAvailabilityStatus,
    monthlyTransactions: createResident.monthlyTransactions,
    currentApplication: createResident.currentApplication,
    applicantName: getApplicantName(state, ownProps.history),
    unitNumber: getUnitNumber(state),
    leaseStartDate: getLeaseStartDate(state),
    propertyClassType: getSelectedPropertyClassType(state),
    leaseMoveInDate,
    moveInApplyFullAmountToProRate: pathOr(
      false,
      ['app', 'selectedProperty', 'moveInApplyFullAmountToProRate'],
      state,
    ),
    propertyId: state?.app?.selectedProperty?.id ?? '',
    property: state?.app?.selectedProperty,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...createResidentActions,
      getMonthlyTransactions,
    },
    dispatch,
  );
  return { actions };
}

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateResident)),
);
