import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.Title',
    defaultMessage: 'Set Aside Program Setup',
  },
  programName: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.ProgramName',
    defaultMessage: 'Program Name',
  },
  setAside: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.SetAside',
    defaultMessage: 'Set Aside',
  },
  numberOfUnits: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.NumberOfUnits',
    defaultMessage: '# of Units',
  },
  hud: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.Hud',
    defaultMessage: 'HUD',
  },
  incomeLimitPercentage: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.IncomeLimitPercentage',
    defaultMessage: 'Income Limit %',
  },
  medianIncomePercentage: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.MedianIncomePercentage',
    defaultMessage: '% Median Income',
  },
  maxRentPercentage: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.MaxRentPercentage',
    defaultMessage: 'Max Rent %',
  },
  overIncomeRecertPercentage: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.OverIncomeRecertPercentage',
    defaultMessage: 'Over Income at Recert %',
  },
  yes: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.No',
    defaultMessage: 'No',
  },
  note: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.Note',
    defaultMessage: 'Note: ',
  },
  noteDescription: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.NoteDescription',
    defaultMessage:
      'Making changes to the set aside programs will affect all households and units that utilize these programs.',
  },
  save: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.Save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.Cancel',
    defaultMessage: 'Cancel',
  },
  setAsideSetupSuccess: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.Success',
    defaultMessage: 'Set Aside Programs were saved successfully.',
  },
  setAsideSetupError: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup.Error',
    defaultMessage: 'Error updating the Set Aside programs',
  },
});

export default messages;
