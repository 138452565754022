// $FlowFixMe
import React from 'react';
import { injectIntl } from 'react-intl';
import {
  renderRadioOption,
  renderTextField,
  renderSelectField,
} from '../../../utils/redux-form-helper';
import { connect } from 'react-redux';
import { Form, Field, formValueSelector, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { FORM_NAME } from '../constants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import messages from './messages';
import validate from './validate';

type ReduxFormProps = {
  handleSubmit: Function,
  untouch: Function,
  values: any,
};

type Props = ReduxFormProps & {
  alarmActive: boolean,
  isDoorCodeActive: boolean,
  initialValues: Object,
  onSubmit: Function,
  property: Object,
  context: Object,
  entryPreferenceOptions: Array<Object>,
  intl: any,
  change: Function,
};

const FormRow = ({ label, children }: Object) => (
  <div className="row">
    <div className="col-md-4 col-xs-12 ">
      <label>{label}</label>
    </div>
    <div className="col-md-8 col-xs-12 ">{children}</div>
  </div>
);
const WorkOrderPreferencesForm = ({
  alarmActive,
  isDoorCodeActive,
  change,
  downloadOnboardingCsv,
  handleSubmit,
  initialValues,
  onboardingReport,
  onSubmit,
  property,
  untouch,
  context,
  entryPreferenceOptions,
  values,
  intl,
  flags,
}: Props) => {
  const onHandleSubmit = (formValues) => {
    if (onSubmit) onSubmit(formValues);
  };

  return (
    <Form encType="multipart/form-data" onSubmit={handleSubmit(onHandleSubmit)}>
      <FormRow label={<FormattedMessage {...messages.entryPreference} />}>
        <Field
          name="defaultEntryPermissionPreferenceId"
          options={entryPreferenceOptions}
          component={renderSelectField}
          bsSize="lg"
        />
      </FormRow>
      <FormRow label={<FormattedMessage {...messages.alarm} />}>
        <div className="col-xs-6 text-center" style={{ marginBottom: '35px' }}>
          <Field
            name={'isAlarmActive'}
            fieldName={'isAlarmActive'}
            option={{
              value: 'yes',
              text: <FormattedMessage {...messages.yes} />,
            }}
            component={renderRadioOption}
            inline
            onChange={() => change('alarmCode', null)}
          />
        </div>
        <div className="col-xs-6 text-center" style={{ marginBottom: '35px' }}>
          <Field
            name={'isAlarmActive'}
            fieldName={'isAlarmActive'}
            option={{
              value: 'no',
              text: <FormattedMessage {...messages.no} />,
            }}
            component={renderRadioOption}
            inline
            onChange={() => change('alarmCode', null)}
          />
        </div>
      </FormRow>
      {alarmActive === 'yes' && (
        <FormRow label={<FormattedMessage {...messages.alarmCode} />}>
          <Field
            name="alarmCode"
            maxLength={10}
            component={renderTextField}
            placeholder={intl.formatMessage(messages.typeInAlarm)}
            disabled={alarmActive === 'no'}
            bsSize="lg"
          />
          {alarmActive === 'yes' && (
            <div className="padbottom20">
              <strong>
                <FormattedMessage {...messages.note} />
              </strong>{' '}
              <FormattedMessage {...messages.alarmNote} />
            </div>
          )}
        </FormRow>
      )}

      {flags.doorCodeFortressPortal && (
        <>
          <FormRow label={<FormattedMessage {...messages.usesDoorCode} />}>
            <div
              className="col-xs-6 text-center"
              style={{ marginBottom: '35px' }}
            >
              <Field
                name={'isDoorCodeActive'}
                fieldName={'isDoorCodeActive'}
                option={{
                  value: 'yes',
                  text: <FormattedMessage {...messages.yes} />,
                }}
                component={renderRadioOption}
                inline
                onChange={() => change('doorCode', null)}
              />
            </div>
            <div
              className="col-xs-6 text-center"
              style={{ marginBottom: '35px' }}
            >
              <Field
                name={'isDoorCodeActive'}
                fieldName={'isDoorCodeActive'}
                option={{
                  value: 'no',
                  text: <FormattedMessage {...messages.no} />,
                }}
                component={renderRadioOption}
                inline
                onChange={() => change('doorCode', null)}
              />
            </div>
          </FormRow>
          {isDoorCodeActive === 'yes' && (
            <FormRow label={<FormattedMessage {...messages.doorCode} />}>
              <Field
                name="doorCode"
                maxLength={10}
                component={renderTextField}
                placeholder={intl.formatMessage(messages.typeInDoorCode)}
                disabled={isDoorCodeActive === 'no'}
                bsSize="lg"
              />
              {isDoorCodeActive === 'yes' && (
                <div className="padbottom20">
                  <strong>
                    <FormattedMessage {...messages.note} />
                  </strong>{' '}
                  <FormattedMessage {...messages.doorNote} />
                </div>
              )}
            </FormRow>
          )}
        </>
      )}
    </Form>
  );
};
const selector = formValueSelector(FORM_NAME);
// $FlowFixMe
const connnectedForm = connect((state: any) => ({
  alarmActive: selector(state, 'isAlarmActive'),
  isDoorCodeActive: selector(state, 'isDoorCodeActive'),
}))(
  injectIntl(
    reduxForm({
      form: FORM_NAME,
      touchOnChange: true,
      validate,
    })(WorkOrderPreferencesForm),
  ),
);
export default withLDConsumer()(connnectedForm);
