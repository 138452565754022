import moment from 'moment';
import { put, takeLatest, select, call } from 'redux-saga/effects';
import { is } from 'ramda';

import {
  GET_ALL_ACTIVITIES_BY_ASSIGNEE,
  GET_TASK_INFORMATION,
} from './constants';
import {
  getAllActivitiesByAssigneeSuccess,
  getAllActivitiesByAssigneeError,
  getTaskInformationSuccess,
  getTaskInformationError,
  clearActivitiesByAssignee,
} from './actions';
import ActivityService from '../../services/activityService';
import TaskService from '../../services/taskService';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import type { Saga } from 'redux-saga';

export function* fetchgetAllActivitiesByAssignee(action: Object): Saga<void> {
  try {
    yield put(clearActivitiesByAssignee());
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const assigneeId = action.payload.assigneeId;
    const activityService = new ActivityService();

    let start =
      assigneeId === 'all'
        ? moment().startOf('day')
        : moment().startOf('month').subtract(7, 'days');
    let end = moment().endOf('month').add(7, 'days');

    if (action?.payload?.dates) {
      const dates = action.payload.dates;
      if (is(Array, dates)) {
        start = dates[0];
        end = dates[dates.length - 1];
      } else {
        start = dates.start;
        end = dates.end;
      }
    }

    const activities = yield activityService.getByAssignee(
      assigneeId,
      selectedProperty.id,
      organizationId,
      {
        start,
        end,
      },
    );

    yield put(getAllActivitiesByAssigneeSuccess(activities));
  } catch (err) {
    yield put(getAllActivitiesByAssigneeError(err));
  }
}

export function* getAllActivitiesByAssigneeSaga(): Saga<void> {
  yield takeLatest(
    GET_ALL_ACTIVITIES_BY_ASSIGNEE,
    fetchgetAllActivitiesByAssignee,
  );
}

export function* fetchGetTaskInformation({ payload }: Object): Saga<void> {
  try {
    payload?.isLoadingToggle?.(true);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const taskService = new TaskService();
    const tasks = yield call(
      taskService.getAll,
      selectedProperty.id,
      organizationId,
    );
    yield put(getTaskInformationSuccess(tasks));
    payload?.isLoadingToggle?.(false);
  } catch (err) {
    yield put(getTaskInformationError(err));
  }
}

export function* getTaskInformationSaga(): Saga<void> {
  yield takeLatest(GET_TASK_INFORMATION, fetchGetTaskInformation);
}

export default [getAllActivitiesByAssigneeSaga, getTaskInformationSaga];
