import {
  GET_EXPIRING_LEASES,
  GET_EXPIRING_LEASES_SUCCESS,
  GET_EXPIRING_LEASES_FAILURE,
  SELECT_LEASE_FOR_RENEWAL,
  CLEAN_LOADED_EXPIRING_LEASES,
} from './constants';
import type { Action, FilterValueAndPagination } from '../App/types';

export function getExpiringLeases(
  payload: FilterValueAndPagination,
): Action<any> {
  return {
    type: GET_EXPIRING_LEASES,
    payload,
  };
}

export function getExpiringLeasesSuccess({
  meta,
  results,
}: Object): Action<any> {
  return {
    type: GET_EXPIRING_LEASES_SUCCESS,
    payload: results,
    meta,
  };
}

export function getExpiringLeasesFailure(payload: Error): Action<any> {
  return {
    type: GET_EXPIRING_LEASES_FAILURE,
    error: true,
    payload,
  };
}

export function selectLeaseForRenewal(payload: Object): Action<any> {
  return {
    type: SELECT_LEASE_FOR_RENEWAL,
    payload,
  };
}

export function cleanLoadedExpiringLeases(): Action<any> {
  return {
    type: CLEAN_LOADED_EXPIRING_LEASES,
    payload: undefined,
  };
}
