import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ComplianceOverview.Title',
    defaultMessage: 'Compliance Summary',
  },
  certificationType: {
    id: 'App.ComplianceOverview.CertificationType',
    defaultMessage: 'Certification Type',
  },
  certStatus: {
    id: 'App.ComplianceOverview.CertStatus',
    defaultMessage: 'Status',
  },
  propertyName: {
    id: 'App.ComplianceOverview.PropertyName',
    defaultMessage: 'Property',
  },
  unitNumber: {
    id: 'App.ComplianceOverview.UnitNumber',
    defaultMessage: 'Unit',
  },
  applicantName: {
    id: 'App.ComplianceOverview.ApplicantName',
    defaultMessage: 'Name',
  },
  complianceDocumentType: {
    id: 'App.ComplianceOverview.ComplianceDocumentType',
    defaultMessage: 'Compliance Document',
  },
  uploadDateTime: {
    id: 'App.ComplianceOverview.UploadDateTime',
    defaultMessage: 'Upload Date / Time',
  },
  isReviewed: {
    id: 'App.ComplianceOverview.IsReviewed',
    defaultMessage: 'Reviewed',
  },
  programType: {
    id: 'App.ComplianceOverview.ProgramType',
    defaultMessage: 'Program Type',
  },
  voucherEffectiveDate: {
    id: 'App.ComplianceOverview.VoucherEffectiveDate',
    defaultMessage: 'Effective Date',
  },
  certHistory: {
    id: 'App.ComplianceOverview.CertHistory',
    defaultMessage: 'Cert History',
  },
  complianceApprovalStatus: {
    id: 'App.ComplianceOverview.ComplianceApprovalStatus',
    defaultMessage: 'Compliance Approval Status',
  },
  isActive: {
    id: 'App.ComplianceOverview.IsActive',
    defaultMessage: 'Active',
  },
  isResident: {
    id: 'App.ComplianceOverview.IsResident',
    defaultMessage: 'Resident',
  },
  needsReview: {
    id: 'App.ComplianceOverview.NeedsReview',
    defaultMessage: 'Needs Review',
  },
  allOpenCerts: {
    id: 'App.ComplianceOverview.AllOpenCerts',
    defaultMessage: 'All Open Certs',
  },
  includeNotStartedCertifications: {
    id: 'App.ComplianceOverview.IncludeNotStartedCertifications',
    defaultMessage: 'Include Not Started Certifications',
  },
  waitlistApplicant: {
    id: 'App.ComplianceOverview.WaitlistApplicant',
    defaultMessage: 'Waitlist Applicants',
  },
  doNotInclude: {
    id: 'App.ComplianceOverview.DoNotInclude',
    defaultMessage: 'Do Not Include',
  },
  attentionRequired: {
    id: 'App.ComplianceOverview.AttentionRequired',
    defaultMessage: 'Attention Required',
  },
  getComplianceOverviewError: {
    id: 'App.ComplianceOverview.GetComplianceOverviewError',
    defaultMessage: 'There was an error getting the compliance overview data.',
  },
  effectivePastDue: {
    id: 'App.ComplianceOverview.EffectivePastDue',
    defaultMessage: 'Effective Date Past Due',
  },
  certHistoryFor: {
    id: 'App.ComplianceOverview.CertHistoryFor',
    defaultMessage: 'Cert History for {name}',
  },
  certOpened: {
    id: 'App.ComplianceOverview.CertOpened',
    defaultMessage: 'Cert Opened',
  },
  firstSubmissionPacketUpload: {
    id: 'App.ComplianceOverview.FirstSubmissionPacketUpload',
    defaultMessage: '1st Submission Packet Upload',
  },
  daysInProcess: {
    id: 'App.ComplianceOverview.DaysInProcess',
    defaultMessage: 'Days in Process',
  },
  loadError: {
    id: 'App.ComplianceOverview.LoadError',
    defaultMessage: 'Something went wrong fetching the records.',
  },
  retry: {
    id: 'App.ComplianceOverview.Retry',
    defaultMessage: 'Retry',
  },
  totalHouseholds: {
    id: 'App.ComplianceOverview.TotalHouseholds',
    defaultMessage: 'Total Number of Households',
  },
  needsReviewHouseholdNote: {
    id: 'App.ComplianceOverview.NeedsReviewHouseholdNote',
    defaultMessage:
      'Displayed are only the most recently uploaded compliance packets.',
  },
  pastDue: {
    id: 'App.ComplianceOverview.PastDue',
    defaultMessage: 'Past Due',
  },
});

export default messages;
