/* eslint-disable no-unused-vars */
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, formValueSelector } from 'redux-form';
import { palette } from '@fortress-technology-solutions/fortress-component-library/design';
import { Select } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  renderMultiSelectField,
  renderNumberField,
  renderSelectField,
  renderTextAreaField,
  renderTextField,
  validateIsInteger,
  validateIsPositive,
} from '../../../utils/redux-form-helper';
import { EDIT_FORM_NAME } from '../constants';
import messages from '../messages';
import validate from './validate';
import UnitService from '../../../services/unitService';

function EditFloorPlanForm({ intl, handleSubmit, onSubmit, minOccupants }) {
  const onHandleSubmit = (formValues) => {
    if (onSubmit) onSubmit(formValues);
  };

  //function to get the unit type options using the unitService
  const getUnitTypeOptions = async () => {
    const unitService = new UnitService();
    const unitTypes = await unitService.getUnitTypes();
    return unitTypes.map((unitType) => ({
      value: unitType.id,
      label: unitType.name,
    }));
  };

  return (
    <Form onSubmit={handleSubmit(onHandleSubmit)}>
      <Field
        name="internalName"
        component={renderTextField}
        maxLength="30"
        label={intl.formatMessage(messages.internalName)}
        showLabel
      />
      <Field
        name="marketingName"
        component={renderTextField}
        maxLength="60"
        label={intl.formatMessage(messages.marketingName)}
        showLabel
      />
      <Field
        bsSize=""
        name="marketingDescription"
        component={renderTextAreaField}
        maxLength="100"
        label={intl.formatMessage(messages.marketingDescription)}
        showLabel
      />
      <div className="row">
        <div className="col-xs-12 col-sm-4">
          <Field
            name="nBeds"
            component={renderNumberField}
            label={intl.formatMessage(messages.bedrooms)}
            showLabel
            min="0"
            validate={[validateIsInteger, validateIsPositive]}
          />
        </div>
        <div className="col-xs-12 col-sm-4">
          <Field
            name="nBaths"
            component={renderNumberField}
            label={intl.formatMessage(messages.bathrooms)}
            showLabel
            min="0"
            validate={[validateIsInteger, validateIsPositive]}
          />
        </div>
        <div className="col-xs-12 col-sm-4">
          <Field
            name="nHalfBaths"
            component={renderNumberField}
            label={intl.formatMessage(messages.halfBathrooms)}
            showLabel
            min="0"
            validate={[validateIsInteger, validateIsPositive]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-7">
          <div className="">
            <label>
              <FormattedMessage {...messages.minMaxOccupants} />{' '}
            </label>
            <div className="row">
              <div className="col-sm-4">
                <Field
                  name="minOccupants"
                  component={renderNumberField}
                  min={'0'}
                  validate={[validateIsInteger, validateIsPositive]}
                />
              </div>
              <div
                className="col-sm-1"
                style={{ color: palette.light.lightGrey, fontSize: '20px' }}
              >
                /
              </div>
              <div className="col-sm-4">
                <Field
                  name="maxOccupants"
                  component={renderNumberField}
                  min={minOccupants}
                  validate={[validateIsInteger, validateIsPositive]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <label>
            <FormattedMessage {...messages.unitTypeLabel} />{' '}
          </label>
          <Field
            name="unitType"
            component={renderSelectField}
            min={'0'}
            validate={[validateIsInteger, validateIsPositive]}
          />
        </div>
        <div className="col-sm-6">
          <label>
            <FormattedMessage {...messages.programTypeLabel} />{' '}
          </label>
          <Field
            name="programType"
            component={renderMultiSelectField}
            min={'0'}
            validate={[validateIsInteger, validateIsPositive]}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group">
          <div className="col-sm-6">
            <Field
              name="setAside"
              component={() => {
                return (
                  <Select
                    // sx={{ m: 1 }}
                    fullWidth={true}
                    sx={{ height: '31px' }}
                    label={<FormattedMessage {...messages.setAsideLabel} />}
                    options={[]}
                    value={''}
                  />
                );
              }}
              min={'0'}
              validate={[validateIsInteger, validateIsPositive]}
            />
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <Field
                name="homeUnit"
                component={() => {
                  return (
                    <Select
                      multiple={true}
                      fullWidth={true}
                      label={<FormattedMessage {...messages.homeUnitLabel} />}
                      options={[]}
                      value={[]}
                    />
                  );
                }}
                min={'0'}
                validate={[validateIsInteger, validateIsPositive]}
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

const selector = formValueSelector(EDIT_FORM_NAME);

const connnectedForm = connect((state: any) => ({
  initialValues: state.viewUnit.floorplanDetails,
  allFloorplans: state.manageFloorPlans.floorPlans,
  minOccupants: selector(state, 'minOccupants'),
}))(
  injectIntl(
    reduxForm({
      form: EDIT_FORM_NAME,
      touchOnChange: true,
      validate,
      enableReinitialize: true,
    })(EditFloorPlanForm),
  ),
);
export default withLDConsumer()(connnectedForm);
