import type { Action } from '../App/types';
import {
  GET_DEPOSIT,
  GET_DEPOSIT_SUCCESS,
  GET_DEPOSIT_ERROR,
  CLEAR_LOADED_DEPOSIT
} from './constants';

export function getDeposit(depositId: string): Action<any> {
  return {
    type: GET_DEPOSIT,
    payload: depositId
  };
}

export function getDepositSuccess(response: Array<any>): Action<Array<any>> {
  return {
    type: GET_DEPOSIT_SUCCESS,
    payload: response
  };
}

export function getDepositError(error: Error): Action<Error> {
  return {
    type: GET_DEPOSIT_ERROR,
    payload: error,
    error: true
  };
}

export function clearLoadedDeposit(): Action<any> {
  return {
    type: CLEAR_LOADED_DEPOSIT,
    payload: undefined
  };
}
