import { pathOr } from 'ramda';

const headers = {
  // TODO update headers structure
  prospects: {
    headers: [
      { displayName: 'Prospect Name', name: 'name' },
      { displayName: 'Created Date', name: 'createdAt' },
      {
        displayName: 'Status Changed Date',
        name: 'prospectStatusChangeLog',
        pathOr: pathOr('', ['prospectStatusChangeLog', '0', 'createdAt']),
      },
      { displayName: 'Desired Move-In Date', name: 'desiredMoveInDate' },
      {
        displayName: '# of bedrooms',
        name: 'numberOfBedrooms',
        pathOr: pathOr('', ['prospectPreferences', 'nBeds']),
      },
      { displayName: 'Assigned to', name: 'assignedTo' },
      { displayName: 'Phone Number', name: 'phoneNumber' },
      { displayName: 'Email', name: 'emailAddress' },
    ],
  },
  // TODO update headers structure
  applicants: {
    headers: [
      { displayName: 'Applicant Name', name: 'name' },
      { displayName: 'Assigned Unit #', name: 'assignedUnit' },
      { displayName: 'Status', name: 'status' },
      { displayName: 'Scheduled Move-In Date', name: 'scheduledMoveInDate' },
      { displayName: 'Assigned to', name: 'assignedTo' },
      { displayName: 'Phone Number', name: 'phoneNumber' },
      { displayName: 'Email', name: 'emailAddress' },
    ],
  },
  residents: {
    headers: [
      { displayName: 'Resident Name', name: 'name' },
      { displayName: 'Unit Number', name: 'unitNumber' },
      { displayName: 'Building Number', name: 'buildingNumber' },
      { displayName: 'Balance', name: 'balance' },
      { displayName: 'Lease End Date', name: 'endDate' },
      { displayName: 'Move-In Date', name: 'moveInDate' },
      { displayName: 'Phone Number', name: 'phoneNumber' },
      { displayName: 'Email', name: 'emailAddress' },
    ],
  },
  // TODO update headers structure
  priorResidents: {
    headers: [
      'Prior Resident',
      'Unit Number',
      'Move-In Date',
      'Move-Out Date',
      'Resident Balance',
    ],
  },
};

export default headers;
