// $FlowFixMe
import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { pathOr } from 'ramda';
import styled from 'styled-components';
import {
  renderTextField,
  renderSimpleCheckboxField,
} from '../../../../utils/redux-form-helper';
import WorkOrderService from '../../../../services/workOrderService';

const StyledFormGroup = styled.div`
  & {
    display: inline-flex;
    flex-wrap: wrap;
    width: 50%;
    padding: 0 20px;
  }
`;
const StyledFormLabel = styled.div`
  flex: 0 40%;
`;
const StyledFormInput = styled.div`
  flex: 0 60%;
`;
const StyledSection = styled.div`
  padding: 20px 0 40px;
`;

const RenderUnitIssuesForm = ({
  unitIssues,
  toggleUnitIssue,
  selectedUnitIssues,
}) => {
  return unitIssues
    ? unitIssues.map((unitIssue, key) => (
        <StyledFormGroup key={unitIssue.id}>
          <StyledFormLabel>
            <Field
              key={`checkbox${unitIssue.id}`}
              name={`unitIssue.${unitIssue.id}`}
              component={renderSimpleCheckboxField}
              bsSize="lg"
              onClick={(e) => toggleUnitIssue(e, unitIssue.id)}
            />
            <label className="padtop10">{unitIssue.unitIssueType}</label>
          </StyledFormLabel>
          <StyledFormInput>
            <Field
              key={`textbox${unitIssue.id}`}
              name={`unitIssueDescription.${unitIssue.id}`}
              className="input-lg form-control"
              component={renderTextField}
              placeholder={'Issue Observed'}
              disabled={!selectedUnitIssues[unitIssue.id] === true}
            />
          </StyledFormInput>
        </StyledFormGroup>
      ))
    : null;
};

const UnitIssuesObserved = ({
  organizationId,
  propertyId,
  initialValues,
  changeCallback,
  location,
}: Object) => {
  const [unitIssues, setUnitIssues] = useState([]);
  const [selectedUnitIssues, setSelectedUnitIssues] = useState({});
  useEffect(() => {
    const woService = new WorkOrderService();
    woService
      .getAllWorkOrderUnitIssues(organizationId, propertyId)
      .then((issues) => {
        setUnitIssues(issues);
      });
  }, [organizationId, propertyId]);

  useEffect(() => {
    const unitIssues = initialValues.unitIssues;
    if (unitIssues) {
      const issueIds = {};
      unitIssues.forEach((issue) => {
        const unitIssueTypeId = pathOr(null, ['issueType', 'id'], issue);
        changeCallback(`unitIssue.${unitIssueTypeId}`, true);
        changeCallback(
          `unitIssueDescription.${unitIssueTypeId}`,
          issue.unitIssueDescription,
        );
        issueIds[unitIssueTypeId] = true;
      });
      setSelectedUnitIssues({
        ...issueIds,
      });
    }
  }, [initialValues.unitIssues, location]); // eslint-disable-line

  const toggleUnitIssue = (e, issueId) => {
    const checked = pathOr(false, ['target', 'checked'], e);
    setSelectedUnitIssues({
      ...selectedUnitIssues,
      [issueId]: checked,
    });
    if (!checked) {
      changeCallback(`unitIssueDescription.${issueId}`, null);
    }
  };

  return (
    <StyledSection>
      <div className="padbottom10 padtop30">
        <label>Unit Issues Observed</label>
      </div>
      <RenderUnitIssuesForm
        unitIssues={unitIssues}
        toggleUnitIssue={toggleUnitIssue}
        selectedUnitIssues={selectedUnitIssues}
      />
    </StyledSection>
  );
};

export default UnitIssuesObserved;
