import type { Action } from '../App/types';
// type import type { ManageWorkOrdersState } from './types';
import * as ActionTypes from './constants';

export const initialState = {
  workOrders: [],
  meta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  currentFilter: {},
  columnOrder: {
    id: 'sortable',
    location: 'sortable',
    issueDescription: 'sortable',
    creationDate: 'sortable',
    assignedTo: 'sortable',
    priorityLevel: 'sortable',
    status: 'sortable',
    daysOpen: 'sortable',
    requestor: 'sortable',
  },
  currentSorting: {
    fieldName: '',
    order: '',
  },
  searchText: '',
};

const manageWorkOrdersReducer = (
  state: Object = initialState,
  action: Action<*, *>,
): Object => {
  // type
  const { type, payload, meta, error } = action;
  switch (type) {
    case ActionTypes.GET_ALL_WORK_ORDERS_SUCCESS: {
      return { ...state, workOrders: payload, meta };
    }
    case ActionTypes.UPDATE_COLUMNS_SORT_VALUE: {
      return {
        ...state,
        columnOrder: Object.assign(
          {},
          { ...initialState.columnOrder },
          payload,
        ),
      };
    }
    case ActionTypes.UPDATE_WORK_ORDERS_QUERY_PARAMS: {
      return { ...state, ...payload };
    }
    case ActionTypes.GET_ALL_WORK_ORDERS_ERROR: {
      return { ...state, error };
    }
    case ActionTypes.CLEAN_LOADED_WORK_ORDERS: {
      return { ...initialState };
    }
    case ActionTypes.GET_ALL_WORK_ORDERS:
    default:
      return state;
  }
};

export default manageWorkOrdersReducer;
