import { useMutation, MutationOptions } from 'react-query';
import useAbortController from '../../hooks/useAbortController';
import UnitService from '../../services/unitService';
import FileSaver from 'file-saver';
import { toastr } from 'react-redux-toastr';
import moment from 'moment/moment';

const useUnitQuoteMutation = ({
  propertyName,
  propertyId,
  organizationId,
  userId,
  options,
}: {
  propertyName: string,
  propertyId: string,
  organizationId: string,
  userId: string,
  options?: MutationOptions,
}) => {
  const abortControllerOptions = useAbortController();

  return useMutation(
    async ({ unitId, applicantId, prospectId }) => {
      const responseFile = await new UnitService().getQuote(
        propertyId,
        organizationId,
        unitId,
        userId,
        applicantId,
        prospectId,
        abortControllerOptions,
      );

      FileSaver.saveAs(
        responseFile,
        `Quote-${propertyName.replace(
          /[^a-zA-z0-9]+/gi,
          '-',
        )}-${moment().format('MM-DD-YYYY')}.pdf`,
      );
    },
    {
      enabled: Boolean(propertyId && organizationId),
      onError: (error: any) => {
        toastr.error('Error', 'There was an error while downloading the quote');
      },
      ...options,
    },
  );
};

export default useUnitQuoteMutation;
