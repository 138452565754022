import React from 'react';
import { FormattedMessage } from 'react-intl';
import { HelpBlock, FormGroup } from 'react-bootstrap';
import messages from './messages';
import styled from 'styled-components';
import { trim } from 'ramda';

type Props = {
  name: string,
  value: string,
  onChange: Function,
  editMode: boolean,
  required: boolean,
  validateFunction: Function,
  message: Object,
  invalidMessageStyles: Object,
  display: string,
  maxLength: string,
  custom: any,
};

const validationState = (
  editMode: boolean,
  validateFunction: Function,
  value: string,
) => {
  return editMode && !validateFunction(value || '') ? 'error' : undefined;
};

const requiredText = (
  editMode: boolean,
  validateFunction: Function,
  value: string,
  message: Object,
  invalidMessageStyles: Object,
) =>
  validationState(editMode, validateFunction, value) && (
    <HelpBlock style={invalidMessageStyles}>
      <FormattedMessage {...message} />
    </HelpBlock>
  );

const StyledInput = styled.input`
  max-width: ${(props) => props.maxWidth};
  display: ${(props) => props.display};
  margin: ${(props) => props.margin};
`;

export const TextInput = ({
  name,
  value,
  onChange,
  editMode,
  required,
  validateFunction = trim,
  message = messages.required,
  invalidMessageStyles,
  display,
  maxLength = '524288',
  ...custom
}: Props) => (
  <FormGroup
    validationState={validationState(
      required && editMode,
      validateFunction,
      value,
    )}
    style={{ display }}
  >
    <StyledInput
      name={name}
      className="input-md form-control"
      value={value}
      disabled={!editMode}
      onChange={onChange}
      maxWidth="90%"
      display={display}
      maxLength={maxLength}
      {...custom}
    />
    {required &&
      requiredText(
        editMode,
        validateFunction,
        value,
        message,
        invalidMessageStyles,
      )}
  </FormGroup>
);
