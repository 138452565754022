import type { FormProspect } from '../types';
import { all, isEmpty, isNil, anyPass, type } from 'ramda';
import moment from 'moment';
import messages from './messages';
import {
  validateEmail,
  validatePhoneNumber,
  validateRange,
} from '../../../utils/validations';

const nameRegex = (name) => {
  return /^[a-zA-Z][a-zA-Z0-9 .',&-]*$/i.test(name);
};

const petTagRegex = (name) => {
  return /^[a-zA-Z0-9 .',&-]*$/i.test(name);
};
const validateFirstAndLastNames = (intl, name) => {
  if (name && !nameRegex(name)) {
    return intl.formatMessage(messages.invalidName);
  }
};

const validatePetTag = (intl, name) => {
  if (name && !petTagRegex(name)) {
    return intl.formatMessage(messages.invalidPetTag);
  }
};

const validateNames = (intl, name) => {
  if (name && !nameRegex(name)) {
    return intl.formatMessage(messages.invalidFieldValue);
  }
};

const validateEmailString = (intl, email) => {
  if (!validateEmail(email)) {
    return intl.formatMessage(messages.invalidEmailAddress);
  }
};

const validateAge = (intl, age, min, max) => {
  if (!validateRange(age, min, max)) {
    return intl.formatMessage(messages.invalidMinorAge);
  }
};

const validatePhoneNumberString = (intl, phoneNumber) => {
  if (!validatePhoneNumber(phoneNumber)) {
    return intl.formatMessage(messages.invalidPhoneNumber);
  }
};

const validateAdults = (intl, adultsArray) => {
  if (adultsArray && adultsArray.length > 0) {
    const errors = [];
    adultsArray.forEach((adult) => {
      if (adult) {
        const adultError = {};
        ['firstName', 'lastName'].forEach((field) => {
          adultError[field] = validateFirstAndLastNames(intl, adult[field]);
        });
        ['middleName', 'preferredName'].forEach((field) => {
          adultError[field] = validateNames(intl, adult[field]);
        });
        if (adult.emailAddress) {
          adultError.emailAddress = validateEmailString(
            intl,
            adult.emailAddress,
          );
        }
        if (adult.phoneNumber) {
          adultError.phoneNumber = validatePhoneNumberString(
            intl,
            adult.phoneNumber,
          );
        }
        errors.push(adultError);
      }
    });
    return errors;
  }
};

const validateMinors = (intl, minorsArray) => {
  if (minorsArray && minorsArray.length > 0) {
    const errors = [];
    minorsArray.forEach((minor) => {
      if (minor) {
        const minorError = {};
        ['firstName', 'lastName'].forEach((field) => {
          if (minor[field]) {
            minorError[field] = validateFirstAndLastNames(intl, minor[field]);
          }
        });
        ['middleName', 'preferredName'].forEach((field) => {
          if (minor[field]) {
            minorError[field] = validateNames(intl, minor[field]);
          }
        });
        if (minor.age) {
          minorError.age = validateAge(intl, minor.age, 0, 17);
        }
        errors.push(minorError);
      }
    });
    return errors;
  }
};

const validatePets = (intl, petsArray) => {
  if (petsArray && petsArray.length > 0) {
    const petArrayErrors = [];
    petsArray.forEach((pet, petIndex) => {
      if (!petArrayErrors[petIndex]) petArrayErrors[petIndex] = {};
      if (!pet || !pet.petTypeId) {
        petArrayErrors[petIndex] = {
          ...petArrayErrors[petIndex],
          petTypeId: intl.formatMessage(messages.generalRequiredLabel),
        };
        return;
      }
      if (!pet.petBreedId) {
        petArrayErrors[petIndex] = {
          ...petArrayErrors[petIndex],
          petBreedId: intl.formatMessage(messages.generalRequiredLabel),
        };
      }

      if (pet.petTag) {
        const pettagErrs = validatePetTag(intl, pet.petTag);
        petArrayErrors[petIndex] = {
          ...petArrayErrors[petIndex],
          petTag: pettagErrs,
        };
      }
    });

    return petArrayErrors;
  }
};

const validateBaths = (intl, amount) => {
  if (amount && (amount < 0 || amount > 9 || !/^[0-9]*$/i.test(amount + ''))) {
    return intl.formatMessage(messages.invalidBaths);
  }
};

const validatePrice = (intl, amountToValidate, amountFrom, amountTo) => {
  if (amountToValidate < 0 || amountToValidate > 99999) {
    return intl.formatMessage(messages.invalidValue);
  }

  if (amountFrom > amountTo) {
    return intl.formatMessage(messages.invalidRange);
  }
};

const validateMoveInDateRange = (intl, dateFrom, dateTo) => {
  if (!dateFrom) {
    return intl.formatMessage(messages.fromDateNeeded);
  }

  if (dateFrom > dateTo) {
    return intl.formatMessage(messages.invalidRange);
  }
};

const validateDateFormat = (intl, date) => {
  const formattedDate = moment(date);
  const todayDate = new Date();
  if (!formattedDate.isValid() || type(date) === 'String') {
    return intl.formatMessage(messages.invalidDateFormat);
  } else if (formattedDate.isBefore(todayDate)) {
    return intl.formatMessage(messages.invalidDateValue);
  }
};

const validate = (values: FormProspect, { intl }: Object) => {
  const errors = {};

  if (
    all(anyPass([isNil, isEmpty]))([
      values.firstName,
      values.lastName,
      values.emailAddress,
      values.phoneNumber,
      values.contactTypeId,
    ])
  ) {
    errors.firstName = ' ';
    errors.lastName = ' ';
    errors.emailAddress = ' ';
    errors.phoneNumber = ' ';
    errors.contactTypeId = ' ';
    errors._error = {
      sync: false,
      async: true,
      message: intl.formatMessage(messages.requiredFields),
    };
  }

  if (!errors.firstName) {
    errors.firstName = validateFirstAndLastNames(intl, values.firstName);
  }
  if (!errors.middleName) {
    errors.middleName = validateNames(intl, values.middleName);
  }
  if (!errors.lastName) {
    errors.lastName = validateFirstAndLastNames(intl, values.lastName);
  }
  if (!errors.preferredName) {
    errors.preferredName = validateNames(intl, values.preferredName);
  }
  if (!errors.emailAddress) {
    errors.emailAddress = validateEmailString(intl, values.emailAddress);
  }
  if (!errors.phoneNumber) {
    errors.phoneNumber = validatePhoneNumberString(intl, values.phoneNumber);
  }

  errors.adults = validateAdults(intl, values.adults);
  errors.minors = validateMinors(intl, values.minors);
  errors.pets = validatePets(intl, values.pets);
  errors.nBaths = validateBaths(intl, values.nBaths);

  errors.priceFrom = validatePrice(
    intl,
    parseInt(values.priceFrom, 10),
    parseInt(values.priceFrom, 10),
    parseInt(values.priceTo, 10),
  );
  errors.priceTo = validatePrice(
    intl,
    parseInt(values.priceTo, 10),
    parseInt(values.priceFrom, 10),
    parseInt(values.priceTo, 10),
  );

  if (values.moveInDateFrom) {
    errors.moveInDateFrom = validateDateFormat(intl, values.moveInDateFrom);
  }

  if (values.moveInDateTo) {
    errors.moveInDateTo = validateDateFormat(intl, values.moveInDateTo);
  }

  if (
    !values.noMoveInVisible &&
    !values.moveInToVisible &&
    !values.moveInDateFrom
  ) {
    errors.moveInDateFrom = ' ';
  }

  if (!values.noMoveInVisible && values.moveInToVisible) {
    const moveInDateError = validateMoveInDateRange(
      intl,
      values.moveInDateFrom,
      values.moveInDateTo,
    );
    errors.moveInDateFrom = moveInDateError;
  }

  if (!values.referralTypeId) {
    errors.referralTypeId = intl.formatMessage(messages.requiredReferralType);
  }

  if (!values.contactTypeId) {
    errors.contactTypeId = intl.formatMessage(messages.requiredContactType);
  }

  return errors;
};

export default validate;
