import WorkOrderService from '../../services/workOrderService';
import type { OrderValue } from '../../containers/App/types';
import { phoneFormatter } from '../../utils/redux-form-helper';
import { isNil, pathOr } from 'ramda';
import { download } from '../../utils/downloadFile';
import {
  appendFilterTextToCSV,
  removeCommasForCSVExport,
} from '../../utils/csv-helpers';
import {
  getDateDiffInDays,
  getDateUTC,
  formatDateUTC,
} from '../../utils/date-helpers';
import { table as tableColor } from '@fortress-technology-solutions/fortress-component-library/design';

export const getRowColor = (
  isPastDueDate: boolean,
  isActiveWorkOrder: boolean,
  workOrderAssignedToIsNullOrStatusCheckIsInactive: boolean,
): string | undefined => {
  if (isPastDueDate && isActiveWorkOrder) {
    return tableColor?.urgent;
  }

  if (workOrderAssignedToIsNullOrStatusCheckIsInactive) {
    return tableColor?.warning;
  }
};

export const downloadCSV = async ({
  pageNumber = null,
  limit,
  filterValue,
  sorting,
  searchText,
  organizationId,
  propertyId,
  defaultFilterActive,
  hasAnyFilters,
}: {
  pageNumber: number | null,
  limit: number,
  filterValue: any,
  sorting: OrderValue,
  searchText: string,
  organizationId: string,
  propertyId: string,
  defaultFilterActive?: boolean,
  hasAnyFilters: boolean,
}) => {
  const workOrderService = new WorkOrderService();
  const response = await workOrderService.getAllWorkOrders(
    pageNumber,
    limit,
    filterValue,
    sorting,
    searchText,
    organizationId,
    propertyId,
    defaultFilterActive,
  );

  const { headers, rows } = parseCSV(response.results);
  const csv = appendFilterTextToCSV({ headers, rows, hasAnyFilters });
  download(csv, 'WorkOrders.csv', 'text/csv;charset=utf-8');
};

const parseCSV = (results) => {
  const headers = [
    'Work Order Id',
    'Unit/Location',
    'Issue Description',
    'Creation Date',
    'Assigned to',
    'Priority',
    'Status',
    'Days Open',
    'Requestor - Phone Number',
  ].join(',');

  const rows = results.map((item) => {
    const assigneeName = item.assignedTo
      ? `${item.assignedTo.firstName} ${item.assignedTo.lastName}`
      : '';
    const requestorName = item.requestorName || 'None';
    const requestorPhone = item.requestorPhone
      ? phoneFormatter(item.requestorPhone)
      : '';
    const requestDate = formatDateUTC(item.createdAt);
    const otherLocation = pathOr(null, ['otherCommonAreaLocation'], item);

    let location = item.commonAreaLocation
      ? item.commonAreaLocation.commonAreaLocationDescription
      : item.unitNumber;

    if (!location) location = '---';

    if (location === 'Other' && otherLocation) {
      location = `${location} - ${otherLocation}`;
    }

    const lastStatusChange = item.lastStatusChangeDate;
    const finishedDate = item.finishedDate;
    const statusDescription = pathOr('', ['status', 'statusDescription'], item);
    const dateToCompare =
      statusDescription === 'Open' || statusDescription === 'On Hold'
        ? getDateUTC()
        : statusDescription === 'Canceled'
        ? lastStatusChange
        : isNil(finishedDate)
        ? lastStatusChange
        : finishedDate;

    const daysOpen = getDateDiffInDays(dateToCompare, item.createdAt);
    const detailIdReadable = pathOr('', ['detailIdReadable'], item);
    const priorityLevelDescription = pathOr(
      '',
      ['priorityLevel', 'priorityLevelDescription'],
      item,
    );
    const issueDescription = pathOr('', ['issue', 'issueDescription'], item);
    // $FlowFixMe
    const otherDescription = pathOr(null, ['otherDescription'], item);
    let description = otherDescription
      ? `Other - ${otherDescription}`
      : issueDescription;

    return removeCommasForCSVExport([
      detailIdReadable,
      location,
      description,
      requestDate,
      assigneeName,
      priorityLevelDescription,
      statusDescription,
      daysOpen,
      `${requestorName} ${requestorPhone}`,
    ]).join(',');
  });

  return { headers, rows };
};
