import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.EditRole.Title',
    defaultMessage: 'Fortress - Edit role'
  },
  header: {
    id: 'App.EditRole.Header',
    defaultMessage: 'Edit role'
  },
  chooseOption: {
    id: 'App.EditRole.ChooseAnOption',
    defaultMessage: 'Choose an option'
  },
  successHeader: {
    id: 'App.EditRole.SuccessHeader',
    defaultMessage: 'Success'
  },
  successDescription: {
    id: 'App.EditRole.SuccessDescription',
    defaultMessage: 'The role was updated.'
  },
  errorHeader: {
    id: 'App.EditRole.ErrorHeader',
    defaultMessage: 'Error'
  },
  cancelConfirmation: {
    id: 'App.EditRole.CancelConfirmation',
    defaultMessage: 'Are you sure you want to leave?'
  }
});

export default messages;
