import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import moment from 'moment';
import type {
  FetchCamRecordsHookProps,
  CreateCamRecordHookProps,
  DeleteCamRecordHookProps,
  FetchCamRecordStatusHookProps,
  FetchCamConfirmationHookProps,
  StartNewCanHookProps,
  FetchCamTransactionsHookProps,
  FetchPropertyAvgSqftOccupancyHookProps,
} from './types';
import messages from './messages';

import CamRecordService from '../../services/camRecordService';
import CamConfirmationService from '../../services/camConfirmationService';
import PropertyService from '../../services/propertyService';

export const useFetchCamRecords = ({
  intl,
  propertyId,
  organizationId,
  householdId,
  promptToaster,
  onSuccess,
}: FetchCamRecordsHookProps): any => {
  const [camRecords, setCamRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetchCamRecords = async () => {
      try {
        setIsLoading(true);
        const camRecordService = new CamRecordService();
        const response = await camRecordService.getAll(
          organizationId,
          propertyId,
          householdId,
          abortController.signal,
        );
        const sortedCams = (response || []).sort((camA, camB) => {
          const startA = pathOr(null, ['startDate'], camA);
          const startB = pathOr(null, ['startDate'], camB);
          const mStartA = moment(startA);
          const mStartB = moment(startB);
          if (!mStartA.isValid() || !mStartB.isValid()) {
            return -1;
          }
          return mStartA.isSameOrAfter(mStartB) ? -1 : 1;
        });
        setCamRecords(sortedCams);
        onSuccess(sortedCams);
        setIsLoading(false);
      } catch (err) {
        if (!abortController.signal.aborted) {
          promptToaster({
            type: 'error',
            title: intl.formatMessage(messages.fetchCamRecordError),
            message: err.toString(),
          });
        }
      }
    };
    if (propertyId && organizationId && householdId) {
      fetchCamRecords();
    }

    return () => abortController.abort();
  }, [organizationId, propertyId, householdId, shouldRefresh]); // eslint-disable-line

  return [isLoading, camRecords, () => setShouldRefresh(!shouldRefresh)];
};

export const useCreateCamRecord = ({
  intl,
  propertyId,
  organizationId,
  householdId,
  promptToaster,
  refresh,
}: CreateCamRecordHookProps): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [camRecord, setCamRecord] = useState(null);

  const returnedFn = (payload: Object) => setCamRecord(payload);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const createCamRecord = async (camRecord: Object) => {
      setIsLoading(true);
      try {
        if (!propertyId || !organizationId || !householdId || !camRecord) {
          throw new Error('Could not add CAM Record');
        }

        const camRecordService = new CamRecordService();
        await camRecordService.add(
          organizationId,
          propertyId,
          householdId,
          camRecord,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.addCamRecordSuccessTitle),
          message: intl.formatMessage(messages.addCamRecordSuccessMessage),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.addCamRecordError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        refresh();
      }
    };

    if (!isLoading && camRecord) {
      createCamRecord(camRecord);
    }

    return () => {
      setCamRecord(null);
    };
  }, [
    organizationId,
    propertyId,
    householdId,
    promptToaster,
    intl,
    isLoading,
    camRecord,
    refresh,
  ]);

  return [isLoading, returnedFn];
};

export const useModifyCamRecord = ({
  intl,
  propertyId,
  organizationId,
  householdId,
  promptToaster,
  refresh,
}: CreateCamRecordHookProps): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [camRecord, setCamRecord] = useState(null);
  const [camRecordId, setCamRecordId] = useState(null);

  const returnedFn = (id: string, payload: Object) => {
    setCamRecord(payload);
    setCamRecordId(id);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const modifyCamRecord = async (
      modifyId: string,
      modifyCamRecord: Object,
    ) => {
      setIsLoading(true);
      try {
        if (
          !propertyId ||
          !organizationId ||
          !householdId ||
          !modifyId ||
          !modifyCamRecord
        ) {
          throw new Error('Could not modify CAM Record');
        }

        const camRecordService = new CamRecordService();
        await camRecordService.modify(
          organizationId,
          propertyId,
          householdId,
          modifyId,
          modifyCamRecord,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.modifyCamRecordSuccessTitle),
          message: intl.formatMessage(messages.modifyCamRecordSuccessMessage),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.modifyCamRecordError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        refresh();
      }
    };

    if (!isLoading && camRecordId && camRecord) {
      modifyCamRecord(camRecordId, camRecord);
    }

    return () => {
      setCamRecord(null);
      setCamRecordId(null);
    };
  }, [
    organizationId,
    propertyId,
    householdId,
    promptToaster,
    intl,
    isLoading,
    camRecord,
    camRecordId,
    refresh,
  ]);

  return [isLoading, returnedFn];
};

export const useRemoveCamRecord = ({
  intl,
  propertyId,
  organizationId,
  householdId,
  promptToaster,
  onSuccess,
  refresh,
}: DeleteCamRecordHookProps): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [camRecordId, setCamRecordId] = useState(null);
  const [transactionsToReassign, setTransactionsToReassign] = useState(null);

  const returnedFn = (
    newCamRecordsId: any,
    newTransactionsToReassign?: Array<Object>,
  ) => {
    setCamRecordId(newCamRecordsId);
    setTransactionsToReassign(newTransactionsToReassign);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const removeCamRecord = async (deleteCamRecordId: any) => {
      setIsLoading(true);
      try {
        if (
          !propertyId ||
          !organizationId ||
          !householdId ||
          !deleteCamRecordId
        ) {
          throw new Error('Could not delete CAM Record');
        }

        const camRecordService = new CamRecordService();
        await camRecordService.remove(
          organizationId,
          propertyId,
          householdId,
          deleteCamRecordId,
          transactionsToReassign,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.removeCamRecordSuccessTitle),
          message: intl.formatMessage(messages.removeCamRecordSuccessMessage),
        });
        onSuccess();
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.removeCamRecordError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        refresh();
      }
    };

    if (!isLoading && camRecordId) {
      removeCamRecord(camRecordId);
    }

    return () => {
      setCamRecordId(null);
    };
  }, [
    transactionsToReassign,
    organizationId,
    propertyId,
    householdId,
    promptToaster,
    intl,
    isLoading,
    camRecordId,
    refresh,
    onSuccess,
  ]);

  return [isLoading, returnedFn];
};

export const useFetchCamRecordStatus = ({
  intl,
  propertyId,
  organizationId,
  camRecordId,
  promptToaster,
}: FetchCamRecordStatusHookProps): any => {
  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetchCamRecords = async () => {
      if (organizationId && propertyId && camRecordId) {
        setIsLoading(true);
        const camRecordService = new CamRecordService();
        const response = await camRecordService.getStatus(
          organizationId,
          propertyId,
          camRecordId,
          abortController.signal,
        );
        const newStatus = pathOr(null, ['status'], response);
        setStatus(newStatus);
        setIsLoading(false);
      }
    };

    fetchCamRecords();

    return () => abortController.abort();
  }, [organizationId, propertyId, camRecordId, promptToaster, shouldRefresh]);

  return [isLoading, status, () => setShouldRefresh(!shouldRefresh)];
};

export const useStartNewCam = ({
  intl,
  propertyId,
  organizationId,
  promptToaster,
  onStartNewCamCompleted,
}: StartNewCanHookProps): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [camRecordId, setCamRecordId] = useState(null);
  const [camRecord, setCamRecord] = useState(null);

  const returnedFn = (sourceCamRecordId: string, payload: Object) => {
    setCamRecordId(sourceCamRecordId);
    setCamRecord(payload);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const startNewCam = async (
      sourceCamRecordId: string,
      camRecordPayload: Object,
    ) => {
      setIsLoading(true);
      try {
        if (
          !propertyId ||
          !organizationId ||
          !sourceCamRecordId ||
          !camRecordPayload
        ) {
          throw new Error('Could not start new CAM');
        }

        const camRecordService = new CamRecordService();
        const response = await camRecordService.startNewCam(
          organizationId,
          propertyId,
          camRecordId,
          camRecordPayload,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.startNewCamSuccessTitle),
          message: intl.formatMessage(messages.startNewCamSuccessMessage),
        });
        const newRecordId = pathOr(null, ['id'], response);
        onStartNewCamCompleted(newRecordId);
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.startNewCamError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (!isLoading && camRecord && camRecordId) {
      startNewCam(camRecordId, camRecord);
    }

    return () => {
      setCamRecordId(null);
      setCamRecord(null);
    };
  }, [
    organizationId,
    propertyId,
    camRecordId,
    camRecord,
    promptToaster,
    intl,
    isLoading,
    onStartNewCamCompleted,
  ]);

  return [isLoading, returnedFn];
};

export const useFetchCamRecordConfirmations = ({
  intl,
  organizationId,
  propertyId,
  camRecordId,
}: FetchCamConfirmationHookProps): any => {
  const [confirmations, setConfirmations] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetchCamConfirmations = async () => {
      if (organizationId && propertyId && camRecordId) {
        const camRecordService = new CamConfirmationService();
        const response = await camRecordService.getAllByRecordId(
          organizationId,
          propertyId,
          camRecordId,
          abortController.signal,
        );
        setConfirmations(response);
      }
    };

    fetchCamConfirmations();

    return () => abortController.abort();
  }, [organizationId, propertyId, camRecordId, shouldRefresh]);

  return [confirmations, () => setShouldRefresh(!shouldRefresh)];
};

export const useFetchPropertyAvgSqftOccupancy = ({
  intl,
  propertyId,
  organizationId,
  promptToaster,
}: FetchPropertyAvgSqftOccupancyHookProps): any => {
  const [occupancy, setOccupancy] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetch = async () => {
      if (organizationId && propertyId) {
        setIsLoading(true);
        const propertyService = new PropertyService();
        const response = await propertyService.getAvgSqftOccupancy(
          organizationId,
          propertyId,
          abortController.signal,
        );
        setOccupancy(response);
        setIsLoading(false);
      }
    };

    fetch();

    return () => abortController.abort();
  }, [organizationId, propertyId, promptToaster, shouldRefresh]);

  return [isLoading, occupancy, () => setShouldRefresh(!shouldRefresh)];
};

export const useFetchCamTransactions = ({
  intl,
  organizationId,
  propertyId,
  camRecordsId,
  promptToaster,
  onComplete,
}: FetchCamTransactionsHookProps): any => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchData = async () => {
      if (propertyId && organizationId && camRecordsId) {
        setIsLoading(true);
        const camRecordService = new CamRecordService();
        const response = await camRecordService.getTransactions(
          organizationId,
          propertyId,
          camRecordsId,
        );

        setIsLoading(false);
        setTransactions(response);
        onComplete(response);
      }
    };

    if (shouldRefresh) {
      fetchData();
      setShouldRefresh(false);
    }

    return () => abortController.abort();
  }, [organizationId, propertyId, camRecordsId, shouldRefresh, onComplete]);

  return [isLoading, transactions, () => setShouldRefresh(true)];
};
