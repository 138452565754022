import request, { apiURL } from '../utils/api';

class BatchDocumentService {
  get(organizationId: string, propertyId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/create-charge-batch`,
      options,
    );
  }
  create(form: FormData, organizationId: string, propertyId: string) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
      body: form,
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/batch-documents`,
      options,
    );
  }

  createAffordable(form: FormData, organizationId: string, propertyId: string) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
      body: form,
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/batch-property-data`,
      options,
    );
  }
}

export default BatchDocumentService;
