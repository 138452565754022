import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import { dissoc, pathOr } from 'ramda';
import confirm from '../../components/ConfirmDialogModal';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import * as createApplicationActions from './actions';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';

import { getSelectedPropertyClassType } from '../App/selectors';

import {
  getAllNameSuffixes,
  getAllPropertyClasses,
  getAllApplicantTypes,
  getAllRelationships,
  getAllAffordableRelationships,
  getAllPetTypes,
} from '../App/actions';
import { getPropertyTransactionCodes } from '../../components/CreateTransaction/actions';
import { CreateApplicationForm } from '../../components/EditHouseholdForm';
import {
  generateInitialAdult,
  generateInitialMinor,
  generateInitialPet,
} from '../../components/EditHouseholdForm/utils';
import messages from './messages';

import {
  getSelectedPropertyClass,
  getSelectedPropertyClassVisible,
  getNameSuffixOptions,
  getApplicantTypeOptions,
  getRelationshipOptions,
  getMinorRelationshipOptions,
  getAffordableRelationshipOptions,
  getApplicablePropertyClassOptions,
  getPetTypeOptions,
} from '../App/selectors';
import { getProspectName, getPropertyId } from './selectors';

import type { Property, DropdownOption } from '../App/types';
import type { Prospect } from '../ProspectProfile/types';
import type { Application } from './types';

type StateProps = {
  prospectId: string,
  prospect?: Prospect,
  prospectName: string,
  propertyClass: string,
  propertyId: string,
  primaryApplicantId: string,
  propertyClassVisible: boolean,
  selectedProperty?: Property,
  nameSuffixes: Array<DropdownOption>,
  propertyClasses: Array<DropdownOption>,
  relationships: Array<DropdownOption>,
  minorRelationship: Array<DropdownOption>,
  affordableRelationship: Array<DropdownOption>,
  applicantTypes: Array<DropdownOption>,
  propertyTransactionCodes: Array<any>,
  petTypes: Array<DropdownOption & { petBreeds: Array<DropdownOption> }>,
  locale: string,
  isAffordable: boolean,
  isHouseholdSubmitting: boolean,
  adultsCount: number,
  applicationFees: ?Object,
};
type Props = {
  intl: any,
  history: Object,
  actions: {
    clean: () => void,
    getOneProspect: (string) => void,
    getAllNameSuffixes: () => void,
    getAllPropertyClasses: () => void,
    getAllApplicantTypes: () => void,
    getAllRelationships: () => void,
    getAllAffordableRelationships: () => void,
    getPropertyTransactionCodes: () => void,
    getAllPetTypes: () => void,
    createApplication: (Application) => void,
    getApplicationFees: (numAdults: number) => void,
  },
};

type State = {};

export class CreateApplication extends Component<StateProps & Props, State> {
  componentWillMount() {
    this.props.actions.clean();
  }
  componentDidMount() {
    this.props.actions.getAllNameSuffixes();
    this.props.actions.getAllApplicantTypes();
    this.props.actions.getAllRelationships();
    this.props.actions.getAllAffordableRelationships();
    this.props.actions.getAllPropertyClasses();
    this.props.actions.getAllPetTypes();
    this.props.actions.getOneProspect(this.props.prospectId);
    this.props.actions.getPropertyTransactionCodes();
  }

  componentDidUpdate(prevProps: Object) {
    const prev = pathOr(0, ['adultsCount'], prevProps);
    // $FlowFixMe
    const next = pathOr(0, ['adultsCount'], this.props);
    if (+prev !== +next) {
      this.props.actions.getApplicationFees(this.props.adultsCount);
    }
  }

  removeDefaultFromSelectFields = (values: any) => {
    const { isAffordable } = this.props;
    return {
      ...values,
      adults: values.adults.map((adult) => ({
        ...dissoc('id', adult),
        suffixId: adult.suffixId === 'default' ? undefined : adult.suffixId,
        relationship:
          adult.relationship === 'default' ? undefined : adult.relationship,
        affordableRelationship:
          adult.affordableRelationship === 'default' || !isAffordable
            ? undefined
            : adult.affordableRelationship,
      })),
      minors: values.minors.map((minor) => ({
        ...dissoc('id', minor),
        suffixId: minor.suffixId === 'default' ? undefined : minor.suffixId,
        relationship:
          minor.relationship === 'default' ? undefined : minor.relationship,
        affordableRelationship:
          minor.affordableRelationship === 'default' || !isAffordable
            ? undefined
            : minor.affordableRelationship,
      })),
    };
  };
  handleCancel = () => {
    const route = `/prospect/${this.props.prospectId}`;
    navigateToUrlWithSelectedPropertyId(route);
  };
  handleSubmit = async (values: any) => {
    const { intl } = this.props;
    try {
      await confirm(intl.formatMessage(messages.convertConfirmation), { intl });
      const valuesWithoutDefault = this.removeDefaultFromSelectFields(values);
      this.props.actions.createApplication(valuesWithoutDefault);
    } catch (e) {}
  };
  render() {
    const {
      prospect,
      propertyClass,
      propertyClassVisible,
      prospectName,
      propertyId,
      prospectId,
      affordableRelationship,
    } = this.props;

    const headOfHouseholdRelationship = affordableRelationship.find(
      ({ text }) => /Head/gi.test(text),
    );
    const adults = generateInitialAdult(prospect, headOfHouseholdRelationship);
    const minors = generateInitialMinor(prospect);
    const pets = generateInitialPet(prospect);
    const appFees = this.props.applicationFees;
    return (
      <DocumentTitle title="Fortress - Convert to Applicant">
        <AsyncBox
          loading={Boolean(
            !prospect && !headOfHouseholdRelationship?.value?.length,
          )}
          sx={{ minHeight: 'calc(100vh - 40px)' }}
        >
          {prospect && (
            <CreateApplicationForm
              initialValues={{
                adults,
                minors,
                pets,
                prospectId,
                propertyId,
                unitTypeId: propertyClass,
              }}
              pageTitle={`${this.props.intl.formatMessage(
                messages.convertConfirmation,
              )} ${prospectName}`}
              suffixList={this.props.nameSuffixes}
              relationshipList={this.props.relationships}
              minorRelationshipList={this.props.minorRelationship}
              affordableRelationship={this.props.affordableRelationship}
              applicantTypeList={this.props.applicantTypes}
              propertyClassList={this.props.propertyClasses}
              handleCancel={this.handleCancel}
              onSubmit={this.handleSubmit}
              propertyClassVisible={propertyClassVisible}
              petTypeList={this.props.petTypes}
              applicationFees={appFees}
              isAffordable={this.props.isAffordable}
              handleEditHouseholdMember={() => {}}
              isHouseholdSubmitting={this.props.isHouseholdSubmitting}
            />
          )}
        </AsyncBox>
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = (
  state: Object,
  ownProps: Object,
): StateProps => {
  const { app, languageProvider, createApplication, currentProperty } = state;
  const propertyClassType = getSelectedPropertyClassType(state);
  const propertyClass = getSelectedPropertyClass(state);
  // $FlowFixMe
  const applicantPropertyClass = pathOr('', ['name'], propertyClass);
  const propertiesClasses = pathOr('', ['app', 'propertyClasses'], state);
  const affordableObject =
    propertiesClasses !== ''
      ? propertiesClasses.find((x) => x.name === 'Affordable')
      : '';
  return {
    prospectId: ownProps.match.params.prospectId,
    prospect: createApplication.prospect,
    selectedProperty: app.selectedProperty,
    nameSuffixes: getNameSuffixOptions(state),
    propertyClass,
    propertyClassVisible: getSelectedPropertyClassVisible(state),
    prospectName: getProspectName(state),
    propertyId: getPropertyId(state),
    propertyClasses: getApplicablePropertyClassOptions(state),
    propertyTransactionCodes: currentProperty.transactionCodes.codes || [],
    relationships: getRelationshipOptions(state),
    minorRelationship: getMinorRelationshipOptions(state),
    affordableRelationship: getAffordableRelationshipOptions(state),
    applicantTypes: getApplicantTypeOptions(state),
    petTypes: getPetTypeOptions(state),
    locale: languageProvider.locale,
    primaryApplicantId: createApplication.primaryApplicantId,
    adultsCount: pathOr(
      0,
      ['form', 'editHousehold', 'values', 'adults', 'length'],
      state,
    ),
    applicationFees: createApplication.applicationFees,
    isAffordable:
      propertyClassType === 'Affordable' ||
      (propertyClassType === 'Mixed' &&
        applicantPropertyClass === 'Affordable') ||
      pathOr(
        false,
        ['form', 'editHousehold', 'values', 'unitTypeId'],
        state,
        // $FlowFixMe
      ) === pathOr('', ['id'], affordableObject),
    isHouseholdSubmitting: createApplication.isHouseholdSubmitting,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...createApplicationActions,
      getAllNameSuffixes,
      getAllPropertyClasses,
      getAllApplicantTypes,
      getAllRelationships,
      getAllAffordableRelationships,
      getAllPetTypes,
      getPropertyTransactionCodes,
    },
    dispatch,
  );
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CreateApplication));
