import React from 'react';
import { Alert } from 'react-bootstrap';

type Props = {
  message: string,
  type?: any
};

const ErrorMessage = ({ message, type='error' }: Props) => 
  <Alert bsStyle={type==='error'? 'danger': 'warning'}>{message}</Alert>;

export default ErrorMessage;
