import { useCallback } from 'react';
import useFetchAndSetData from './useFetchAndSetData';
import PropertyService from '../../services/propertyService';

export default function useIsExpirationLimitsActive(
  organizationId: string,
  propertyId: string,
): Object {
  const initialState = null;

  const fetcherCallback = useCallback(() => {
    const service = new PropertyService();
    return service.getIsLeaseExpirationLimitsActive(organizationId, propertyId);
  }, [organizationId, propertyId]);

  return useFetchAndSetData(initialState, fetcherCallback);
}
