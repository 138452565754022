export const DEFAULT_VALUES = {
  name: '',
  description: '',
  propertyIds: [],
};

export const PROPERTY_COMMERCIAL_FLOORPLANS = {
  ALL: 'ALL',
  SOME: 'SOME',
  NONE: 'NONE',
};
