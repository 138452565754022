import { useController } from 'react-hook-form';
import { Select as SelectComponentLibrary } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled(Stack)`
  & span {
    top: 0px;
  }
`;

function MultiSelect(props) {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  const arr = [];
  const multiSelectValue = field.value ? arr.concat(field.value) : arr;

  return (
    <Wrapper>
      <SelectComponentLibrary
        onClearSelected={() => field.onChange([])}
        {...props}
        error={error}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={multiSelectValue}
        name={field.name}
        inputRef={field.ref}
        multiple
        fullWidth
      />
      {error && error.message}
    </Wrapper>
  );
}

export default MultiSelect;
