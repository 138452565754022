import { createSelector } from 'reselect';
import { propEq, defaultTo, pathOr } from 'ramda';
import { isDirty, hasSubmitSucceeded } from 'redux-form';
import type { GlobalState } from '../App/types';
import type { State } from './types';
import type {
  HouseholdMember,
  AdultHouseholdMember,
  MinorHouseholdMember,
  PetHouseholdMember,
} from '../../services/residentService';
import type { ApplicantType } from '../../services/applicantTypeService';
import {
  getApplicantTypes,
  getSuffixes,
  getRelationships,
  getAffordableRelationshipOptions,
} from '../App/selectors';

const getLocalState = (state: GlobalState) => state.editResidentHousehold;
const getGlobalState = (state: GlobalState) => state;
const defaultToDefault = defaultTo('default');
export const getPrimaryResident = createSelector(
  getLocalState,
  (state: State) => {
    const household = state.household || [];
    return household.find(
      (member: HouseholdMember) =>
        member.type === 'adult' && member.rc.isPrimary,
    );
  },
);

export const getPrimaryResidentName = createSelector(
  getPrimaryResident,
  (resident?: AdultHouseholdMember) =>
    resident &&
    `${resident.rc.customer.firstName} ${resident.rc.customer.lastName}`,
);

export const getPageTitle = createSelector(
  getPrimaryResidentName,
  (name?: string) =>
    name ? `Fortress - Edit ${name}'s Household` : 'Fortress - Edit Household',
);

export const getFinanciallyResponsibleMembers = createSelector(
  getLocalState,
  getApplicantTypes,
  (state: State, applicantTypes: ApplicantType[]) => {
    const household = state.household || [];
    return household.filter(
      (member: HouseholdMember) =>
        member &&
        member.applicant &&
        member.type === 'adult' &&
        propEq(
          'financiallyResponsible',
          true,
          applicantTypes.find(
            propEq('id', member.applicant.applicantTypeId),
          ) || {
            financiallyResponsible: false,
          },
        ),
    );
  },
);

export const getFinanciallyResponsibleNames = createSelector(
  getPrimaryResidentName,
  getFinanciallyResponsibleMembers,
  (primaryName?: string, members: AdultHouseholdMember[]) => {
    const secondaryMembers = members.filter(
      (m: AdultHouseholdMember) =>
        !m.rc.isPrimary && !propEq('status', 'Prior', m),
    );
    const secondaryNames = secondaryMembers.map(
      (m: AdultHouseholdMember) =>
        `${m.rc.customer.firstName} ${m.rc.customer.lastName}`,
    );
    return primaryName ? [primaryName, ...secondaryNames] : secondaryNames;
  },
);

const nameOrEmpty = (obj?: any) => (obj || { name: '' }).name;

export const getSecondaryAdults = createSelector(
  getLocalState,
  (state: State) =>
    state.household
      ? state.household.filter(
          (m: HouseholdMember) => m.type === 'adult' && !m.rc.isPrimary,
        )
      : [],
);

export const getAdults = createSelector(
  getPrimaryResident,
  getSecondaryAdults,
  (
    primaryAdult?: AdultHouseholdMember,
    secondaryAdults: AdultHouseholdMember[],
  ) =>
    primaryAdult
      ? [
          primaryAdult,
          ...secondaryAdults.filter((resident) => resident.status !== 'Prior'),
        ]
      : secondaryAdults,
);

export const getAffordableHohRelId = (affordableRelationships) => {
  const headOfHouseholdAffordableRel =
    affordableRelationships.find(({ text }) => /Head/gi.test(text)) ?? {};
  const { value: headOfHouseholdAffordableRelId } =
    headOfHouseholdAffordableRel;
  return headOfHouseholdAffordableRelId;
};

export const getAffordableHeadOfHouseholdRelId = createSelector(
  getAffordableRelationshipOptions,
  getAffordableHohRelId,
);

export const setAffordableRelationshipId = (
  { id, affordableRelationshipId }: AdultHouseholdMember,
  primaryResidentId: string,
  headOfHouseholdAffordableRelId: string,
): string => {
  return primaryResidentId === id
    ? headOfHouseholdAffordableRelId
    : affordableRelationshipId;
};

export const getAdultsFormValues = createSelector(
  getPrimaryResident,
  getAffordableHeadOfHouseholdRelId,
  getAdults,
  (
    primaryResident,
    headOfHouseholdAffordableRelId,
    adults: AdultHouseholdMember[],
  ) => {
    const { id: primaryResidentId } = primaryResident ?? {};
    return adults.map((adult: AdultHouseholdMember) => {
      // $FlowFixMe
      const applicantTypeId = pathOr(
        '',
        ['applicant', 'applicantTypeId'],
        adult,
      );
      return {
        id: adult.id,
        firstName: adult.rc.customer.firstName,
        middleName: adult.rc.customer.middleName,
        lastName: adult.rc.customer.lastName,
        phoneNumber: adult.rc.customer.phoneNumber,
        emailAddress: adult.rc.customer.emailAddress,
        preferredName: adult.rc.customer.preferredName,
        suffix: defaultToDefault(adult.rc.customer.suffixId),
        relationshipId: defaultToDefault(adult.relationshipId),
        affordableRelationshipId: defaultToDefault(
          setAffordableRelationshipId(
            {
              id: adult.id,
              affordableRelationshipId:
                adult.applicant.affordableRelationshipId,
            },
            primaryResidentId,
            headOfHouseholdAffordableRelId,
          ),
        ),
        isPrimary: adult.rc.isPrimary,
        type: defaultToDefault(applicantTypeId),
      };
    });
  },
);

export const isAffordableApplication = createSelector(
  getAdults,
  (adults: AdultHouseholdMember[]) => {
    if (adults && adults.length > 0) {
      // $FlowFixMe
      const propertyType = pathOr(
        'conventional',
        ['applicant', 'application', 'propertyClass', 'name'],
        adults[0],
      );
      return propertyType === 'Affordable';
    }
    return false;
  },
);

export const getAdultsReadData = createSelector(
  getAdults,
  getApplicantTypes,
  getSuffixes,
  getRelationships,
  (
    adults: AdultHouseholdMember[],
    applicantTypes: any[],
    suffixes: any[],
    relationships: any[],
  ) =>
    adults.map((adult: AdultHouseholdMember) => ({
      applicantTypeText: nameOrEmpty(
        applicantTypes.find(propEq('id', adult.applicant.applicantTypeId)),
      ),
      firstName: adult.rc.customer.firstName,
      middleName: adult.rc.customer.middleName,
      lastName: adult.rc.customer.lastName,
      preferredName: adult.rc.customer.preferredName,
      suffixText: nameOrEmpty(
        suffixes.find(propEq('id', adult.rc.customer.suffixId)),
      ),
      relationshipText: nameOrEmpty(
        relationships.find(propEq('id', adult.relationshipId)),
      ),
      isPrimary: adult.rc.isPrimary,
    })),
);

export const getEnabledAdults = createSelector(
  getLocalState,
  (state: State) => state.enabledAdults,
);

export const getEnabledMinors = createSelector(
  getLocalState,
  (state: State) => state.enabledMinors,
);

export const isHouseholdLoading = createSelector(
  getLocalState,
  (state: State) => state.loadingHousehold,
);

export const isApplicantTypesLoading = createSelector(
  getGlobalState,
  (state: State) => state.app.applicantTypesIsLoading,
);

export const isPetTypesLoading = createSelector(
  getGlobalState,
  (state: State) => state.app.petTypesIsLoading,
);

export const isPetBreedsLoading = createSelector(
  getGlobalState,
  (state: State) => state.app.petBreedsIsLoading,
);

export const isRelationshipsLoading = createSelector(
  getGlobalState,
  (state: State) => state.app.relationshipsIsLoading,
);

export const isAffordableRelationshipsLoading = createSelector(
  getGlobalState,
  (state: State) => state.app.affordableRelationshipsIsLoading,
);

export const isSuffixesLoading = createSelector(
  getGlobalState,
  (state: State) => state.app.nameSuffixesIsLoading,
);

export const getMinors = createSelector(getLocalState, (state: State) =>
  state.household
    ? state.household.filter(
        (resident) => resident.type === 'minor' && resident.status !== 'Prior',
      )
    : [],
);

export const getMinorsFormValues = createSelector(
  getMinors,
  (minors: MinorHouseholdMember[]) =>
    minors.map((minor: MinorHouseholdMember) => {
      return {
        id: minor.id,
        firstName: minor.residentMinor.firstName,
        middleName: minor.residentMinor.middleName,
        lastName: minor.residentMinor.lastName,
        preferredName: minor.residentMinor.preferredName,
        suffixId: defaultToDefault(minor.residentMinor.suffixId),
        relationshipId: defaultToDefault(minor.relationshipId),
        affordableRelationshipId: defaultToDefault(
          minor.applicant.affordableRelationshipId,
        ),
        age: minor.residentMinor.age,
      };
    }),
);

export const getPets = createSelector(getLocalState, (state: State) =>
  state.household
    ? state.household.filter(
        (resident) => resident.type === 'pet' && resident.status !== 'Prior',
      )
    : [],
);

export const getPetsFormValues = createSelector(
  getPets,
  (pets: PetHouseholdMember[]) =>
    pets.map((pet: PetHouseholdMember) => ({
      id: pet.id,
      name: pet.residentPet.name,
      petTypeId: pet.residentPet.petTypeId,
      petBreedId: pet.residentPet.breedId,
      weight: pet.residentPet.weight,
      petTag: pet.residentPet.petTag,
      isServiceAnimal: !!pet.residentPet.isServiceAnimal,
    })),
);

export const getPendingApplicantPets = createSelector(
  getLocalState,
  (state: State) =>
    state.pendingHousehold
      ? state.pendingHousehold.filter((applicant) => applicant.applicantPet)
      : [],
);

export const getPendingPetsFormValues = createSelector(
  getPendingApplicantPets,
  (pets: any[]) =>
    pets.map((pet: any) => ({
      id: pet.id,
      name: pet.applicantPet.name,
      petTypeId: pet.applicantPet.petTypeId,
      petBreedId: pet.applicantPet.breedId,
      weight: pet.applicantPet.weight,
      petTag: pet.applicantPet.petTag,
      isServiceAnimal: !!pet.applicantPet.isServiceAnimal,
    })),
);

const formName = 'editResidentHousehold';

export const enablePrompt = createSelector(
  isDirty(formName),
  hasSubmitSucceeded(formName),
  (formDirty: boolean, formSubmitted: boolean) => formDirty && !formSubmitted,
);

export const isHouseholdHistoryLoading = createSelector(
  getLocalState,
  (state: State) => state.loadingHouseholdHistory,
);

export const getHouseholdHistory = createSelector(
  getLocalState,
  (state: State) => state.householdHistory,
);

export const getHouseholdLeases = createSelector(
  getLocalState,
  (state: State) => state.leases,
);

export const getHouseholdHasOpenCerts = createSelector(
  getGlobalState,
  (state: State) => state.editResidentHousehold.householdHasOpenCerts,
);
