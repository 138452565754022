import { injectIntl } from 'react-intl';
import {
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { CollectionsTrack as CollectionsTrackCL } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress'; // eslint-disable-line
import useCollectionsTrack from './hooks';

function CollectionsTrack(props) {
  const { uiProps } = useCollectionsTrack(props);

  return (
    <Stack spacing={1.5}>
      <Typography variant="labelLarge">Collections Status</Typography>
      <CollectionsTrackCL {...uiProps} />
    </Stack>
  );
}

export default injectIntl(CollectionsTrack);
