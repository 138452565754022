import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageReports.Title',
    defaultMessage: 'Fortress - Manage reports',
  },
  successHeader: {
    id: 'App.ManageReports.SuccessHeader',
    defaultMessage: 'Success',
  },
  failedDocumentDownload: {
    id: 'App.ManageReports.FailedDocumentDownload',
    defaultMessage: 'This file is not available to display',
  },
});

export default messages;
