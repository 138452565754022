import React from 'react';
import moment from 'moment';
import { DATE_INPUT_FORMAT, DATE_OUTPUT_FORMAT } from './constants';

export const formatColumnType = (
  intl: Object,
  value: any,
  header: Object,
): any => {
  switch (header.type) {
    case 'date': {
      return value
        ? moment(value, DATE_INPUT_FORMAT).format(DATE_OUTPUT_FORMAT)
        : '';
    }
    case 'dateOrString': {
      const validDate = moment(value, DATE_INPUT_FORMAT);
      return value && validDate.isValid()
        ? moment(value, DATE_INPUT_FORMAT).format(DATE_OUTPUT_FORMAT)
        : value;
    }
    case 'money': {
      return intl.formatNumber(+value || 0, {
        style: 'currency',
        currency: 'USD',
      });
    }
    case 'number': {
      return value ? intl.formatNumber(+value) : '0';
    }
    case 'numberZeroNull': {
      return value && +value !== 0 ? intl.formatNumber(+value) : null;
    }
    case 'moneyZeroNull': {
      return value && +value !== 0
        ? intl.formatNumber(+value || 0, {
            style: 'currency',
            currency: 'USD',
          })
        : null;
    }
    case 'boolean': {
      return value === true ? (
        <i className="icon et-alert-urgent text-red" />
      ) : null;
    }
    default: {
      return value;
    }
  }
};
