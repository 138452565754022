import React from 'react';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Table } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useFetchUnitsByFloorPlan } from './hooks';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import styled from 'styled-components';
import componentMessages from './messages';
import generalMessages from '../../App/messages';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  Button,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

const messages = { ...generalMessages, ...componentMessages };
const GridWithStaticEmptyMessage = styled(Grid)`
  div[style*='position: absolute']:has(p) {
    position: static !important;
  }
`;
const UnitsByFloorPlanModal = ({
  show,
  onClose,
  propertyId,
  organizationId,
  floorPlanId,
  floorInternalName,
  numUnits,
  intl,
}: Object) => {
  const { data, isLoading } = useFetchUnitsByFloorPlan({
    organizationId,
    propertyId,
    floorPlanId,
    show,
  });
  return (
    <Modal
      open={show}
      onClose={onClose}
      title={`${intl.formatMessage(
        messages.unitsAssignedTo,
      )} ${floorInternalName} ${intl.formatMessage(messages.floorPlan)}`}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            {intl.formatMessage(messages.close)}
          </Button>
        </>
      }
    >
      <Grid container>
        <Grid item md={12}>
          <Typography variant="h5" sx={{ marginBottom: '16px' }}>
            {intl.formatMessage(messages.noOfUnits)} {numUnits}
          </Typography>
        </Grid>
        <Grid item md={12}>
          {isLoading ? (
            <Spinner />
          ) : (
            <GridWithStaticEmptyMessage item md={12}>
              <Table
                headers={[
                  {
                    id: 'number',
                    label: intl.formatMessage(messages.unitNumber),
                    sortable: false,
                  },
                  {
                    id: 'unitFloorLevel',
                    label: intl.formatMessage(messages.unitLevel),
                    sortable: false,
                  },
                  {
                    id: 'isADA',
                    label: intl.formatMessage(messages.unitAda),
                    sortable: false,
                  },
                  {
                    id: 'description',
                    label: intl.formatMessage(messages.unitStatus),
                    sortable: false,
                  },
                  {
                    id: 'resident',
                    label: intl.formatMessage(messages.resident),
                    sortable: false,
                  },
                  {
                    id: 'applicant',
                    label: intl.formatMessage(messages.applicant),
                    sortable: false,
                  },
                ]}
                rows={data}
                emptyStateMessage={intl.formatMessage(
                  messages.emptyStateMessage,
                )}
                count={data?.length || 0}
                isLoading={isLoading}
                showSettings={false}
                sx={{ padding: '0px' }}
              />
            </GridWithStaticEmptyMessage>
          )}
        </Grid>
      </Grid>
    </Modal>
  );
};

export default UnitsByFloorPlanModal;
