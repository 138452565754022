import { defineMessages } from 'react-intl';

const messages = defineMessages({
  startNewCAMBtn: {
    id: 'App.CamTab.StartNewCAMBtn',
    defaultMessage: 'Start New CAM',
  },
  fetchCamRecordError: {
    id: 'App.CamTab.FetchCamRecordError',
    defaultMessage: 'Error fetching CAM Records',
  },
  addCamRecordSuccessTitle: {
    id: 'App.CamTab.AddCamRecordSuccessTitle',
    defaultMessage: 'CAM Record added',
  },
  addCamRecordSuccessMessage: {
    id: 'App.CamTab.AddCamRecordSuccessMessage',
    defaultMessage: 'The CAM Record was added successfully.',
  },
  addCamRecordError: {
    id: 'App.CamTab.AddCamRecordError',
    defaultMessage: 'Error adding the CAM Record',
  },
  modifyCamRecordSuccessTitle: {
    id: 'App.CamTab.ModifyCamRecordSuccessTitle',
    defaultMessage: 'CAM Record updated',
  },
  modifyCamRecordSuccessMessage: {
    id: 'App.CamTab.ModifyCamRecordSuccessMessage',
    defaultMessage: 'The CAM Record was updated successfully.',
  },
  modifyCamRecordError: {
    id: 'App.CamTab.ModifyCamRecordError',
    defaultMessage: 'Error updating the CAM Record',
  },
  removeCamRecordSuccessTitle: {
    id: 'App.CamTab.RemoveCamRecordSuccessTitle',
    defaultMessage: 'CAM Record deleted',
  },
  removeCamRecordSuccessMessage: {
    id: 'App.CamTab.RemoveCamRecordSuccessMessage',
    defaultMessage: 'The CAM Record was deleted successfully.',
  },
  removeCamRecordError: {
    id: 'App.CamTab.RemoveCamRecordError',
    defaultMessage: 'Error deleting the CAM Record',
  },
  startNewCamSuccessTitle: {
    id: 'App.CamTab.StartNewCamSuccessTitle',
    defaultMessage: 'New CAM started',
  },
  startNewCamSuccessMessage: {
    id: 'App.CamTab.StartNewCamSuccessMessage',
    defaultMessage: 'The new CAM was started successfully.',
  },
  startNewCamError: {
    id: 'App.CamTab.StartNewCamError',
    defaultMessage: 'Error starting a new CAM',
  },
  cancelConfirmation: {
    id: 'App.CamTab.CancelConfirmation',
    defaultMessage: 'Are you sure you want to cancel this CAM?',
  },
});

export default messages;
