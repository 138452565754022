import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import { curryN, pathOr } from 'ramda';
import * as viewCommunicationActions from './actions';
import CommunicationTable from './CommunicationTable';
import messages from './messages';
import type {
  GlobalState,
  PaginationMeta,
  OrderValue,
  Property,
} from '../App/types';
import {
  getCommunications,
  getCommunicationStatuses,
  getCommunicationTypes,
  getCustomerTypes,
} from './selectors';
import FilterDrawer from '../../components/FilterDrawer';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import type { FilterValue } from '../../containers/App/types.js';

type StateProps = {
  state: Object,
  users: Array<any>,
  meta: PaginationMeta,
  currentSorting: OrderValue,
  communications: Array<any>,
  columnOrder: {
    method: string,
    type: string,
    createdDate: string,
    createdTime: string,
    title: string,
  },
  types: any,
  methods: any,
  status: any,
  selectedProperty: Property,
};

type InjectedProps = {
  actions: Object,
  intl: any,
  history: Object,
  isLoading: boolean,
};

type State = {
  searchText: string,
  showFilter: boolean,
  currentFilter: FilterValue,
  dateErrors: {
    dateToError: string,
    dateFromError: string,
  },
  currentPage: number,
};

export class ViewCommunicationPage extends Component<
  StateProps & InjectedProps,
  State,
> {
  state = {
    currentSorting: {
      fieldName: '',
      order: '',
    },
    showFilter: false,
    currentFilter: {},
    dateErrors: { dateFromError: '', dateToError: '' },
    currentPage: 1,
  };

  componentWillMount() {
    this.props.actions.cleanLoadedCommunications();
    window.document.addEventListener('keydown', this._handleEscKey);
  }

  _handleEscKey = (event: any) => {
    if (event.keyCode === 27) {
      this.toggleFilter(false);
    }
  };

  componentDidMount() {
    this.props.actions.getAllCommunications(
      this.props.currentSorting,
      this.state.currentFilter,
    );
  }

  handleOrderClick = (field: string) => {
    const columnOrder = this.props.columnOrder[field];
    const order = columnOrder === 'ascending' ? 'DESC' : 'ASC';
    const icon =
      columnOrder === 'sortable'
        ? 'ascending'
        : columnOrder === 'ascending'
        ? 'descending'
        : 'ascending';
    this.props.actions.updateColumnsSortValue(field, icon);
    this.props.actions.getAllCommunications(
      {
        fieldName: field,
        order,
      },
      this.state.currentFilter,
    );
  };

  getOrder = (fieldName: string) => {
    return fieldName === this.state.currentSorting.fieldName
      ? this.state.currentSorting.order
      : '';
  };

  onCancel = () => {
    navigateToUrlWithSelectedPropertyId('/manage-communications');
  };
  onCloseClick = () => {
    this.setState({
      showFilter: false,
    });
  };

  onApplyFilterClick = () => {
    const dateTo = pathOr('', ['state', 'currentFilter', 'dateTo'], this);
    const filter = {
      ...this.state.currentFilter,
    };
    if (dateTo) {
      // $FlowFixMe
      filter.dateTo = moment(dateTo).add(1, 'day');
    }
    this.props.actions.getAllCommunications(this.state.currentSorting, filter);
    this.setState({
      currentPage: 1,
      showFilter: false,
    });
  };

  generateFilters() {
    return [
      {
        fieldName: 'method',
        fieldDescription: 'Method',
        options: this.props.methods,
      },
      {
        fieldName: 'type',
        fieldDescription: 'Type',
        options: this.props.types,
      },
      {
        fieldName: 'status',
        fieldDescription: 'Status',
        options: this.props.status,
      },
    ];
  }

  validateDateFormat = (field: string, date: Object) => {
    if (date === '') {
      const dateErrors = {
        [`${field}Error`]: '',
      };
      return this.setState({
        dateErrors: { ...this.state.dateErrors, ...dateErrors },
      });
    }
    if (!(date && moment(date).isValid())) {
      const dateErrors = {
        [`${field}Error`]: 'Must be a valid date',
      };
      return this.setState({
        dateErrors: { ...this.state.dateErrors, ...dateErrors },
      });
    } else if (date && moment(date).isValid()) {
      const dateErrors = {
        [`${field}Error`]: '',
      };
      this.setState({
        dateErrors: { ...this.state.dateErrors, ...dateErrors },
      });
    }
  };

  validateDateRange = (field: string, date: Object) => {
    const { dateFrom, dateTo } = this.state.currentFilter;
    let momentFrom, momentTo, validDateFrom, validDateTo;
    if (field === 'dateFrom' && dateTo) {
      momentFrom = moment(date);
      momentTo = moment(dateTo);
      validDateFrom = date && momentFrom.isValid();
      validDateTo = dateTo && momentTo.isValid();
    } else if (field === 'dateTo' && dateFrom) {
      momentFrom = moment(dateFrom);
      momentTo = moment(date);
      validDateFrom = dateFrom && momentFrom.isValid();
      validDateTo = date && momentTo.isValid();
    }
    const datesFormatted = validDateFrom && validDateTo;
    const fromAfterTo = momentFrom && momentFrom.isAfter(momentTo);
    if (datesFormatted && fromAfterTo) {
      this.setState({
        dateErrors: {
          ...this.state.dateErrors,
          dateToError: 'Date must be after the From field',
        },
      });
    } else if (datesFormatted) {
      this.setState({
        dateErrors: {
          ...this.state.dateErrors,
          dateToError: '',
        },
      });
    }
  };

  onDateFilterChange = (field: string) => {
    return (date: Object) => {
      const currentFilter = {
        ...this.state.currentFilter,
        [field]: date,
      };
      this.setState({ currentFilter });
      this.validateDateFormat(field, date);
      this.validateDateRange(field, date);
    };
  };

  toggleFilter = (show: boolean) => {
    this.setState({
      showFilter: show,
    });
  };

  clearFilters = () => {
    this.setState({
      currentPage: 1,
      currentFilter: {},
      dateErrors: { dateFromError: '', dateToError: '' },
    });
  };

  onFilterChange = (
    field: string,
    value: string,
    { target: { checked } }: Object,
  ) => {
    const currentFilter = this.state.currentFilter;
    const filteredField = currentFilter[field] || {};
    filteredField[value] = checked;
    currentFilter[field] = filteredField;
    this.setState({
      currentFilter,
    });
  };

  handleViewCommunicationClick = (headerId: string, status: string) => {
    this.props.actions.getOneCommunication(headerId, status);
    navigateToUrlWithSelectedPropertyId('/view-communication');
  };

  render() {
    const isAllCommercial =
      this.props.selectedProperty.hasCommercialFloorPlans === 'ALL';
    const filters = this.generateFilters();
    const onFilterChangeCurried = curryN(3, this.onFilterChange);
    const dateInfo = {
      onDateFilterChange: this.onDateFilterChange,
      errors: this.state.dateErrors,
    };
    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <div className="bodywrap">
          <Row>
            <Col xs={6} sm={12}>
              <a className="btn-text" onClick={this.onCancel}>
                <i className="et-chevron-left" />
                {this.props.intl.formatMessage(
                  messages.returnToCreateCommunications,
                )}
              </a>
            </Col>
          </Row>
          <FilterDrawer
            formatMessage={this.props.intl.formatMessage}
            filters={filters}
            onFilterChange={onFilterChangeCurried}
            openFilter={this.toggleFilter}
            hasActiveFilters={false}
            onApplyClick={this.onApplyFilterClick}
            show={this.state.showFilter}
            clearFilters={this.clearFilters}
            currentFilter={this.state.currentFilter}
            onCloseClick={this.toggleFilter}
            dateInfo={dateInfo}
          />
          <CommunicationTable
            intl={this.props.intl}
            openFilter={this.toggleFilter}
            columnOrder={this.props.columnOrder}
            communications={this.props.communications}
            handleOrderClick={this.handleOrderClick}
            getOrder={this.getOrder}
            history={this.props.history}
            hasActiveFilters={false}
            handleViewCommunicationClick={this.handleViewCommunicationClick}
            isAllCommercial={isAllCommercial}
            isLoading={this.props.isLoading}
          />
        </div>
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = (state: GlobalState) => {
  const { app, viewCommunication } = state;
  return {
    communications: getCommunications(state),
    currentSorting: viewCommunication.currentSorting,
    columnOrder: viewCommunication.columnOrder,
    status: getCommunicationStatuses(),
    types: getCustomerTypes(((app.selectedProperty: any): Property)),
    methods: getCommunicationTypes(),
    selectedProperty: app.selectedProperty,
    isLoading: viewCommunication.isLoading,
  };
};
ViewCommunicationPage.contextTypes = {
  store: PropTypes.any,
};

export function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    actions: bindActionCreators(
      {
        ...viewCommunicationActions,
      },
      dispatch,
    ),
  };
}

const InjectedManageReportsPage = injectIntl(ViewCommunicationPage);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedManageReportsPage);
