/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  passbookRateTitle: {
    id: 'App.ViewProperty.AffordableSetup.Programs.ProgramPassbookRate.Title',
    defaultMessage: '{programName} Passbook Rate',
  },
  passbookRate: {
    id: 'App.ViewProperty.AffordableSetup.Programs.ProgramPassbookRate.PassbookRate',
    defaultMessage: 'Passbook Rate',
  },
  showHistory: {
    id: 'App.ViewProperty.AffordableSetup.Programs.ProgramPassbookRate.ShowHistory',
    defaultMessage: 'Show History',
  },
  hideHistory: {
    id: 'App.ViewProperty.AffordableSetup.Programs.ProgramPassbookRate.HideHistory',
    defaultMessage: 'Hide History',
  },
});

export default messages;
