import React from 'react';
import DocumentTitle from 'react-document-title';
import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { injectIntl } from 'react-intl';
import useBaseTableProps from '../../hooks/useBaseTableProps';

import EditFeeModal from '../../components/EditFeeModal';
import { useFetchAllFees } from './hooks';
import messages from './messages';
import TableLayout from '../../components/TableLayout';

function ManageFees({ intl }) {
  const baseTableProps = useBaseTableProps();

  const {
    allColumnsHidden,
    closeModal,
    columnOptions,
    count,
    filteredHeaders,
    handleColumnChange,
    isEditModalOpen,
    isLoading,
    name,
    refetchFees,
    rows,
    selectedColumns,
    selectedFee,
    totalCount,
  } = useFetchAllFees({ intl });

  return (
    <DocumentTitle title={'Manage Fees'}>
      <TableLayout name={name} childrenElementsHeight={56 + 8}>
        {(tableHeight) => (
          <>
            <TableHeader
              title={intl.formatMessage(messages.title)}
              count={count}
              totalCount={totalCount}
            />
            <Table
              {...baseTableProps}
              allColumnsHidden={allColumnsHidden}
              columnOptions={columnOptions}
              handleColumnChange={handleColumnChange}
              emptyStateMessage={intl.formatMessage(messages.emptyStateMessage)}
              headers={filteredHeaders}
              height={tableHeight}
              isLoading={isLoading}
              name={name}
              rows={rows}
              selectedColumns={selectedColumns}
              count={count}
              totalCount={totalCount}
            />
            {isEditModalOpen && (
              <EditFeeModal
                intl={intl}
                isOpen={isEditModalOpen}
                selectedFee={selectedFee}
                onClose={closeModal}
                refetchFees={refetchFees}
              />
            )}
          </>
        )}
      </TableLayout>
    </DocumentTitle>
  );
}

const InjectedManageFees = injectIntl(ManageFees);
export default InjectedManageFees;
