import { defineMessages } from 'react-intl';

const messages = defineMessages({
  edit: {
    id: 'App.UnitSpecials.Edit',
    defaultMessage: 'Edit'
  },
  currentSpecials: {
    id: 'App.UnitSpecials.CurrentSpecials',
    defaultMessage: 'Current Specials:'
  }
});

export default messages;
