import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ledger: {
    id: 'App.Ledger.LedgerHeader.Ledger',
    defaultMessage: 'Ledger',
  },
  export: {
    id: 'App.Ledger.LedgerHeader.Export',
    defaultMessage: 'Export',
  },
  status: {
    id: 'App.Ledger.LedgerHeader.Status',
    defaultMessage: 'Status',
  },
  period: {
    id: 'App.Ledger.LedgerHeader.Period',
    defaultMessage: 'Period',
  },
  balance: {
    id: 'App.Ledger.LedgerHeader.Balance',
    defaultMessage: 'Balance',
  },
  currentCharges: {
    id: 'App.Ledger.LedgerHeader.CurrentCharges',
    defaultMessage: 'Current Charges',
  },
  createTransaction: {
    id: 'App.Ledger.CreateTransaction',
    defaultMessage: 'Create Transaction',
  },
  deposits: {
    id: 'App.Ledger.LedgerHeader.Deposits',
    defaultMessage: 'Deposits',
  },
  held: {
    id: 'App.Ledger.LedgerHeader.Held',
    defaultMessage: 'Held: ',
  },
  owed: {
    id: 'App.Ledger.LedgerHeader.Owed',
    defaultMessage: 'Owed: ',
  },
  fasReady: {
    id: 'App.Ledger.LedgerHeader.FasReady',
    defaultMessage:
      'Ledger has been reviewed and is completely ready for Final Account Statement.',
  },
  refundtoApplicant: {
    id: 'App.Ledger.refundtoApplicant',
    defaultMessage: 'Refund to Applicant',
  },
  noRefundAvailable: {
    id: 'App.Ledger.noRefundAvailable',
    defaultMessage: 'No Refund Available',
  },
  outstandingBalanceDue: {
    id: 'App.Ledger.outstandingBalanceDue',
    defaultMessage: 'Outstanding balance due',
  },
});

export default messages;
