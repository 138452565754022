const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

export type ApplicantType = {
  id: string,
  name: string,
  type: string,
  translations: { en: string, es: string },
  financiallyResponsible: boolean,
  order: number,
  createdAt: Date,
  updatedAt: Date
};

class ApplicantTypeService {
  getAll() {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(`${apiURL}/applicantTypes`, options);
  }
}

export default ApplicantTypeService;
