import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import ConfirmOverlay from '../../../../components/ConfirmOverlay';
import LeaseBasicsEditForm from './LeaseBasicsEditForm';

type Props = {
  intl: Object,
  initialValues: Object,
  show: boolean,
  onHide: Function,
  onSubmit: Function,
  onSaveClick: Function,
  canSubmit: boolean,
  isDirty: boolean,
  isRenewal: boolean,
  priorLease: Object,
};

const OverlayOverrides = styled.span`
  .modal-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    margin-top: 0;
  }
`;

function LeaseBasicsEditModal({
  intl,
  initialValues,
  show,
  onHide,
  onSaveClick,
  onSubmit,
  canSubmit,
  isDirty,
  priorLease,
  isRenewal,
}: Props) {
  const [showCloseConfirm, setShowCloseConfirm] = useState(false);

  const handleSaveClick = () => {
    onHide();
    onSaveClick();
  };

  const handleHide = () => {
    if (isDirty) setShowCloseConfirm(true);
    else onHide();
  };

  const handleCloseReject = () => {
    setShowCloseConfirm(false);
  };

  const handleCloseConfirm = () => {
    onHide();
    setShowCloseConfirm(false);
  };

  return (
    <Modal className="edit-lease-data" backdrop show={show} onHide={handleHide}>
      <Modal.Header closeButton>
        <i className="icon et-pencil" />
        <Modal.Title componentClass="h1">Edit Fields</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginTop: showCloseConfirm ? 0 : undefined }}>
        {showCloseConfirm && (
          <OverlayOverrides>
            <ConfirmOverlay
              title="Are you sure you want to leave without saving?"
              rejectMessage="No"
              confirmMessage="Yes"
              onReject={handleCloseReject}
              onConfirm={handleCloseConfirm}
            />
          </OverlayOverrides>
        )}
        <LeaseBasicsEditForm
          initialValues={initialValues}
          intl={intl}
          onSubmit={onSubmit}
          priorLease={priorLease}
          isRenewal={isRenewal}
        />
      </Modal.Body>
      {!showCloseConfirm && (
        <Modal.Footer>
          <Row>
            <Col xs={6}>
              <Button
                bsStyle="default"
                className="pull-right"
                onClick={handleHide}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                bsStyle="primary"
                className="pull-left"
                type="submit"
                onClick={handleSaveClick}
                disabled={!canSubmit || !isDirty}
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default LeaseBasicsEditModal;
