import React from 'react';
import { Field } from 'redux-form';
import { renderSelectField } from '../../../../utils/redux-form-helper';
import type { DropdownOption } from '../../../App/types';

type Props = {
  contactTypes: Array<DropdownOption>,
  intl: Object
};

const ContactType = ({ contactTypes }: Props) => (
  <Field name="contactTypeId" component={renderSelectField} options={contactTypes} bsSize="lg" />
);

export default ContactType;
