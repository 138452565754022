import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import styled from 'styled-components';
import moment from 'moment';
import { pathOr } from 'ramda';
import {
  renderCurrencyField,
  renderDateField,
  renderMultiSelectField,
  getMultiSelectDefaultValue,
} from '../../../utils/redux-form-helper';
import validate from './validate';
import { FormattedMessage } from 'react-intl';
import messages from '../../App/messages';
import { parseMultiSelectValues } from '../../AffordableQualificationTab/Shared/utils';

type ReduxFormProps = {
  handleSubmit: Function,
};

type Props = ReduxFormProps & {
  onAdd: Function,
  onCancel: Function,
  isValidDate: Function,
  showUaTypeAndEffectiveDate: boolean,
  utilityAllowanceTypes: Array<Object>,
  change: Function,
  flags: Object,
  isCurrentSuggestedDeposit?: boolean,
};

const StyledFeeForm = styled.div`
  .form-group .input-group > input.form-control {
    min-height: 38px;
  }
`;

const ActionContainer = styled.div`
  min-height: 38px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
`;
export class FloorplanFeesForm extends Component<Props> {
  handleAdd = ({
    feeAmount,
    startDate,
    implementationDate,
    utilityAllowanceTypeIds,
  }: Object) => {
    const { onAdd } = this.props;
    const nextStartDate = moment(startDate).format('YYYY-MM-DD');
    const nextImplementationDate =
      moment(implementationDate).format('YYYY-MM-DD');
    onAdd({
      feeAmount,
      startDate: nextStartDate,
      implementationDate: nextImplementationDate,
      utilityAllowanceTypeIds,
    });
  };

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
  };

  render() {
    const {
      handleSubmit,
      isValidDate,
      showUaTypeAndEffectiveDate,
      utilityAllowanceTypes,
      change,
    } = this.props;
    // $FlowFixMe
    const initialDate = pathOr(
      null,
      ['initialValues', 'startDate'],
      this.props,
    );
    const colSpan = showUaTypeAndEffectiveDate ? 2 : 3;
    return (
      <StyledFeeForm className="row row-active padbottom10">
        {showUaTypeAndEffectiveDate && (
          <div className={`col-xs-${colSpan + 1}`}>
            <Field
              name="utilityAllowanceTypeIds"
              defaultValue={getMultiSelectDefaultValue(
                'utilityAllowanceTypeIds',
                this.props?.initialValues,
                utilityAllowanceTypes,
              )}
              component={renderMultiSelectField}
              onChangeCallback={(val) =>
                change('utilityAllowanceTypeIds', parseMultiSelectValues(val))
              }
              options={utilityAllowanceTypes}
              disabled={
                initialDate && initialDate < moment().subtract(1, 'days')
              }
            />
          </div>
        )}
        <div className={`col-xs-${colSpan}`}>
          <Field
            name="feeAmount"
            component={renderCurrencyField}
            min="0"
            max="9999"
            step=".01"
            precision={2}
            disabled={initialDate && initialDate < moment().subtract(1, 'days')}
          />
        </div>
        {showUaTypeAndEffectiveDate && (
          <div className={`col-xs-${colSpan}`}>
            <Field
              name="c"
              component={renderDateField}
              isValidDate={(calendarDate) =>
                isValidDate(calendarDate, 'implementationDate')
              }
              dateFormat="MM/DD/YYYY"
              bsSize="md"
              disabled={
                initialDate && initialDate < moment().subtract(1, 'days')
              }
              utc
            />
          </div>
        )}
        <div className={`col-xs-${colSpan}`}>
          <Field
            name="startDate"
            component={renderDateField}
            isValidDate={(calendarDate) =>
              isValidDate(calendarDate, 'startDate')
            }
            dateFormat="MM/DD/YYYY"
            bsSize="md"
            disabled={initialDate && initialDate < moment().subtract(1, 'days')}
            utc
          />
        </div>
        <div className={`col-xs-${colSpan - 1} col-lg-${colSpan - 1}`} />
        <div className={`col-xs-${colSpan + 1} col-lg-${colSpan}`}>
          <ActionContainer>
            <button
              onClick={handleSubmit(this.handleAdd)}
              className="btn btn-text btn-text--small text-right btn-text--green"
              disabled={initialDate && initialDate < moment()}
            >
              <FormattedMessage {...messages.save} />
              <i className="icon et-isolated-check" />
            </button>
            <button
              onClick={this.handleCancel}
              className="btn btn-text btn-text--small text-right btn-text--red"
            >
              <FormattedMessage {...messages.cancel} />
              <i className="icon et-delete-3" />
            </button>
          </ActionContainer>
        </div>
      </StyledFeeForm>
    );
  }
}

export default reduxForm({
  touchOnChange: true,
  asyncChangeFields: ['feeAmount', 'startDate'],
  validate,
})(FloorplanFeesForm);
