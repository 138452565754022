import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { DEFAULT_SORT_ORDER } from '../../components/Table/constants';

export const GET_PROPERTIES = 'app/ManageProperties/GET_PROPERTIES';
export const GET_PROPERTIES_SUCCESS =
  'app/ManageProperties/GET_PROPERTIES_SUCCESS';
export const GET_PROPERTIES_ERROR = 'app/ManageProperties/GET_PROPERTIES_ERROR';

export const NAME = 'ManageProperties';

// Portal Active Yes
export const PORTAL_ACTIVE_NEVER_ONBOARDED = 'activeNeverOnboarded';
export const PORTAL_ACTIVE_AND_ONBOARDED = 'activeAndOnboarded';

// Portal Active Processing
export const PORTAL_ACTIVE_AND_PROCESSING = 'activeAndProcessing';

// Portal Active No
export const PORTAL_DEACTIVATED_AND_ONBOARDED = 'deactivatedAndOnboarded';
export const PORTAL_DEACTIVATED_NEVER_ONBOARDED = 'deactivatedNeverOnboarded';
export const PORTAL_NEVER_ACTIVE = 'neverActive';
export const PORTAL_STATUS = {
  ACTIVE_NEVER_ONBOARDED: 'activeNeverOnboarded',
  ACTIVE_AND_ONBOARDED: 'activeAndOnboarded',
  ACTIVE_AND_PROCESSING: 'activeAndProcessing',
  DEACTIVATED_AND_ONBOARDED: 'deactivatedAndOnboarded',
  DEACTIVATED_NEVER_ONBOARDED: 'deactivatedNeverOnboarded',
  NEVER_ACTIVE: 'neverActive',
};

export const HEADERS = [
  {
    id: 'status',
    title: <FormattedMessage {...messages.status} />,
  },
  {
    id: 'name',
    title: <FormattedMessage {...messages.propertyName} />,
    classes: 'table-col-width150',
  },
  {
    id: 'shortName',
    title: <FormattedMessage {...messages.shortName} />,
    classes: 'table-col-width100',
  },
  {
    id: 'ownerName',
    title: <FormattedMessage {...messages.ownerName} />,
    classes: 'table-col-width150',
  },
  {
    id: 'propertyType',
    title: <FormattedMessage {...messages.propertyType} />,
    classes: 'table-col-width100',
  },
  {
    id: 'address',
    title: <FormattedMessage {...messages.address} />,
    classes: 'table-col-width180',
  },
  {
    id: 'phoneNumber',
    title: <FormattedMessage {...messages.phone} />,
    classes: 'table-col-width150',
  },
  {
    id: 'email',
    title: <FormattedMessage {...messages.email} />,
    classes: 'table-col-width150',
  },
  {
    id: 'period',
    title: <FormattedMessage {...messages.period} />,
    classes: 'table-col-width100',
  },
  {
    id: 'autoLateFeesPostingActive',
    title: <FormattedMessage {...messages.autoLateFeesPostingActive} />,
    classes: 'table-col-width150',
  },
  {
    id: 'leaseVariance',
    title: <FormattedMessage {...messages.leaseVariance} />,
    classes: 'table-col-width100',
  },
  {
    id: 'gpr',
    title: <FormattedMessage {...messages.gpr} />,
    classes: 'table-col-width100',
  },
  {
    id: 'isPortalActive',
    title: <FormattedMessage {...messages.portalActive} />,
    classes: 'table-col-width100',
  },
  {
    id: 'links',
    title: <FormattedMessage {...messages.links} />,
    order: null,
    classes: 'table-col-width100',
  },
];

export const CSV_HEADERS = [
  {
    id: 'status',
    title: messages.status.defaultMessage,
  },
  {
    id: 'name',
    title: messages.propertyName.defaultMessage,
  },
  {
    id: 'shortName',
    title: messages.shortName.defaultMessage,
  },
  {
    id: 'ownerName',
    title: messages.ownerName.defaultMessage,
  },
  {
    id: 'propertyClass',
    title: messages.propertyType.defaultMessage,
  },
  {
    id: 'address',
    title: messages.address.defaultMessage,
  },
  {
    id: 'phoneNumber',
    title: messages.phone.defaultMessage,
  },
  {
    id: 'email',
    title: messages.email.defaultMessage,
  },
  {
    id: 'period',
    title: messages.period.defaultMessage,
  },
  {
    id: 'autoLateFeesPostingActive',
    title: messages.autoLateFeesPostingActive.defaultMessage,
  },
  {
    id: 'leaseVariance',
    title: messages.leaseVariance.defaultMessage,
  },
  {
    id: 'gpr',
    title: messages.gpr.defaultMessage,
  },
  {
    id: 'isPortalActive',
    title: messages.portalActive.defaultMessage,
  },
  {
    id: 'links',
    title: messages.links.defaultMessage,
  },
];

// Query format for first page
export const FIRST_PAGE = { pageNumber: 1, limit: 25 };

export const INIT_PAG_PROPERTIES = {
  results: null,
  meta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
};

export const INIT_QUERY = {
  sorting: { fieldName: 'name', order: DEFAULT_SORT_ORDER },
  paginationInfo: { pageNumber: 1, limit: 25 },
  searchText: '',
  filterValue: {
    status: {
      Active: true,
    },
  },
};

export const STATIC_FILTERS = [
  {
    fieldName: 'status',
    fieldDescription: 'Property Status',
    options: [
      { text: 'Active', value: 'Active' },
      { text: 'Inactive', value: 'Inactive' },
    ],
  },
  {
    fieldName: 'isPortalActive',
    fieldDescription: 'Portal Active?',
    options: [
      { text: 'Yes', value: 'true' },
      {
        text: 'No',
        value: 'false',
      },
    ],
  },
];
