import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.EditApplicationHousehold.Title',
    defaultMessage: 'Household information for ',
  },
  successHeader: {
    id: 'App.EditApplicationHousehold.SuccessHeader',
    defaultMessage: 'Success',
  },
  successDescription: {
    id: 'App.EditApplicationHousehold.SuccessDescription',
    defaultMessage: 'Application household updated.',
  },
  errorHeader: {
    id: 'App.EditApplicationHousehold.errorHeader',
    defaultMessage: 'Error',
  },
  confirmHouseholdEditWithCompletedCertifications: {
    id: 'App.EditApplicationHousehold.confirmHouseholdEditWithCompletedCertifications',
    defaultMessage:
      // eslint-disable-next-line max-len
      'This action will affect a completed certification for this household. Confirming this action will:{br}1. Reopen the certification{br}2. Change the Compliance Approval Status to “Correction Needed”{br}3. Require a new document to be generated and uploaded to reflect the changed information.{br}{br}Do you want to continue?',
  },
  editHousehold: {
    id: 'App.EditApplicationHousehold.editHousehold',
    defaultMessage: 'Edit Household',
  },
  warning: {
    id: 'App.EditApplicationHousehold.warning',
    defaultMessage: 'Warning',
  },
});

export default messages;
