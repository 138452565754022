import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ethnicity: {
    id: 'App.BasicAffordableInformationSection.ethnicity',
    defaultMessage: 'Ethnicity',
  },
  race: {
    id: 'App.BasicAffordableInformationSection.race',
    defaultMessage: 'Race',
  },
  raceSource: {
    id: 'App.BasicAffordableInformationSection.raceSource',
    defaultMessage: 'RD Only * - Race Source',
  },
});

export default messages;
