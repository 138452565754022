import type { Action } from '../App/types';
import type {
  QualificationChecklist,
  QualificationChecklistAction,
} from './types';
import * as ActionTypes from './constants';

export function saveQualificationChecklist(
  checklistValues: QualificationChecklist,
  applicationId: string,
  applicantId: string,
  profile: string,
  residentId: string,
  affordableQualificationId: string,
  programName: string,
  isIncome: boolean,
): Action<QualificationChecklistAction> {
  return {
    type: ActionTypes.SAVE_QUALIFICATION_CHECKLIST,
    payload: {
      checklistValues,
      applicationId,
      applicantId,
      profile,
      residentId,
      affordableQualificationId,
      programName,
      isIncome,
    },
  };
}

export function saveQualificationChecklistSuccess(): Action<any> {
  return {
    type: ActionTypes.SAVE_QUALIFICATION_CHECKLIST_SUCCESS,
    payload: undefined,
  };
}

export function saveQualificationChecklistError(): Action<any> {
  return {
    type: ActionTypes.SAVE_QUALIFICATION_CHECKLIST_ERROR,
    payload: undefined,
  };
}

export function updateQualificationChecklist(
  checklistValues: QualificationChecklist,
  affordableChecklistHistoryId: string,
  residentId: string,
): Action<any> {
  return {
    type: ActionTypes.UPDATE_QUALIFICATION_CHECKLIST,
    payload: {
      checklistValues,
      affordableChecklistHistoryId,
      residentId,
    },
  };
}

export function getOneQualificationChecklist(
  applicationId: string,
  applicantId: string,
): Action<any> {
  return {
    type: ActionTypes.GET_ONE_QUALIFICATION_CHECKLIST,
    payload: {
      applicationId,
      applicantId,
    },
  };
}

export function getOneQualificationChecklistSuccess(
  response: Object,
): Action<any> {
  return {
    type: ActionTypes.GET_ONE_QUALIFICATION_CHECKLIST_SUCCESS,
    payload: response,
  };
}

export function printIncomeAssetForm(
  organizationId: string,
  applicationId: string,
  applicantId: string,
  propertyId: string,
  affordableQualificationId: string,
  type: string,
  applicantName: string,
  programName: string,
): Action<any> {
  return {
    type: ActionTypes.PRINT_INCOME_ASSET_FORM,
    payload: {
      organizationId,
      applicationId,
      applicantId,
      propertyId,
      affordableQualificationId,
      type,
      applicantName,
      programName,
    },
  };
}
