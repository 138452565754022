import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { pathOr } from 'ramda';
import messages from './messages';
import { Button, Row, Col } from 'react-bootstrap';
import Spinner from '../../../../components/Spinner';
import DashesIfNullOrUndefined from '../../../../components/DashesIfNullOrUndefined';
import Status from '../../../ManageProspects/Status';
import { formatCurrency } from '../../../../utils/index';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import AllocationSetupModal from './allocationSetupModal';
import { ALLOCATION_SETUP_MODAL_MODES } from './constants';
import {
  parseAllocationModalInitialValues,
  getBaseAmountColumnText,
  getExpenseCapsColumnText,
  getGrossUpColumnText,
  getProRataShareColumnText,
} from './utils';
import { allocationNameSorting } from '../../utils';

type Props = {
  intl: Object,
  propertyId: string,
  organizationId: string,
  promptToaster: Function,
  selectedCamRecord: Object,
  generateCamCalculations: Function,
  onAllocationUpdated: Function,
  calculations: Array<Object>,
  confirmations: Array<Object>,
  isLoadingAllocations: boolean,
  allocations: Array<Object>,
  isAllocationsSetUpComplete: boolean,
  refresh: Function,
};

const CamAllocationsSetup = (props: Props) => {
  const {
    intl,
    propertyId,
    organizationId,
    promptToaster,
    selectedCamRecord,
    generateCamCalculations,
    onAllocationUpdated,
    calculations,
    confirmations,
    isLoadingAllocations,
    allocations,
    isAllocationsSetUpComplete,
    refresh,
  } = props;

  const [showAllocationSetupModal, setShowAllocationSetupModal] =
    useState(false);
  const [selectedAllocation, setSelectedAllocation] = useState(null);

  const [allocationSetupModalMode, setAllocationSetupModalMode] =
    useState(null);

  const onModalHide = () => {
    setShowAllocationSetupModal(false);
    onAllocationUpdated();
  };

  const showIsLoading = isLoadingAllocations;
  const showTable = !showIsLoading;

  return (
    <React.Fragment>
      {showAllocationSetupModal && (
        <AllocationSetupModal
          intl={intl}
          propertyId={propertyId}
          organizationId={organizationId}
          promptToaster={promptToaster}
          selectedCamRecord={selectedCamRecord}
          selectedAllocation={selectedAllocation}
          allocationSetupModalMode={allocationSetupModalMode}
          onAllocationSaveCallback={refresh}
          onHide={onModalHide}
          initialValues={parseAllocationModalInitialValues(selectedAllocation)}
          refreshAllCamAllocation={refresh}
          confirmations={confirmations}
        />
      )}

      <Row className="padtop15 padbottom10 padleft5">
        <Col xs={12}>
          <h2>
            <i className="et-clipboard text-blue padright10" />
            <FormattedMessage {...messages.camAllocationsSetup} />
          </h2>
        </Col>
      </Row>
      {showIsLoading && (
        <div className="text-center">
          <Spinner />
        </div>
      )}

      {showTable && (
        <React.Fragment>
          <div className="table-scroll table-units-container">
            <table className="table table-fixed-headers table-prospects table-striped">
              <thead className="table-header">
                <tr>
                  <th>
                    <FormattedMessage {...messages.status} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.primaryCamPool} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.glAccounts} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.allowMontlyEstimate} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.baseAmount} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.caps} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.grossUp} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.annualBudgetedExpenses} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.proRataShare} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.setup} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {renderCamAllocationSetupRows(
                  intl,
                  setShowAllocationSetupModal,
                  setSelectedAllocation,
                  allocations,
                  setAllocationSetupModalMode,
                  confirmations,
                )}
              </tbody>
            </table>
          </div>
          {!confirmations ||
            (confirmations.length === 0 && (
              <Row>
                <Col xs={12}>
                  <a
                    className="pull-right padright10"
                    onClick={() => {
                      setSelectedAllocation(null);
                      setAllocationSetupModalMode(
                        ALLOCATION_SETUP_MODAL_MODES.EXCEPTION,
                      );
                      setShowAllocationSetupModal(true);
                    }}
                  >
                    <FormattedMessage {...messages.addCAMException} />
                  </a>
                </Col>
              </Row>
            ))}
          {!confirmations ||
            (confirmations.length === 0 && (
              <Row>
                <Col xs={12}>
                  {isAllocationsSetUpComplete &&
                  calculations &&
                  calculations.length > 0 &&
                  calculations.length === allocations.length ? (
                    <Button
                      bsStyle="primary"
                      className="btn btn-tertiary btn-default center-block"
                      disabled={!isAllocationsSetUpComplete}
                      onClick={() => generateCamCalculations()}
                    >
                      <span>
                        <FormattedMessage {...messages.refreshCalculations} />
                      </span>
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-shout center-block"
                      disabled={!isAllocationsSetUpComplete}
                      onClick={() => generateCamCalculations()}
                    >
                      <span>
                        <FormattedMessage {...messages.getCalculations} />
                      </span>
                    </Button>
                  )}
                </Col>
              </Row>
            ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const renderCamAllocationSetupRows = (
  intl,
  setShowAllocationSetupModal,
  setSelectedAllocation,
  allocations = [],
  setAllocationSetupModalMode,
  confirmations = [],
) => {
  return (
    allocations &&
    allocations.sort(allocationNameSorting).map((allocation, i) => {
      const isComplete = pathOr(false, ['isComplete'], allocation);
      const statusColor = isComplete ? 'green' : 'red';
      const tooltip = (
        <Tooltip id="tooltip">
          {isComplete === true ? 'Complete' : 'Incomplete'}
        </Tooltip>
      );
      const name = pathOr(
        pathOr('', ['intacctGLAccountGroup', 'name'], allocation),
        ['camException', 'name'],
        allocation,
      );
      const glAccountsIncluded = pathOr(
        'Selection',
        ['glAccountsIncluded'],
        allocation,
      );
      const baseAmount = getBaseAmountColumnText(intl, allocation);
      const expenseCaps = getExpenseCapsColumnText(intl, allocation);
      const grossUp = getGrossUpColumnText(intl, allocation);
      const annualBudgetedExpenses = pathOr(
        null,
        ['annualBudgetedExpenses'],
        allocation,
      );
      const proRataShare = getProRataShareColumnText(intl, allocation);

      const isLeaseAllowForMonthlyEstimates = pathOr(
        false,
        ['isLeaseAllowForMonthlyEstimates'],
        allocation,
      );
      const allowMonthlyEstimate = isLeaseAllowForMonthlyEstimates ? (
        <FormattedMessage {...messages.yes} />
      ) : (
        '---'
      );

      return [
        <tr key={i}>
          <td>
            <OverlayTrigger
              key={`${i}-overlay`}
              placement="right"
              delayHide={200}
              trigger={['hover', 'focus']}
              overlay={tooltip}
            >
              <span>
                <Status color={statusColor} />
              </span>
            </OverlayTrigger>
          </td>
          <td>
            <DashesIfNullOrUndefined data={name} />
          </td>
          <td>
            <DashesIfNullOrUndefined data={glAccountsIncluded} />
          </td>
          <td>
            <DashesIfNullOrUndefined data={allowMonthlyEstimate} />
          </td>
          <td>
            <DashesIfNullOrUndefined data={baseAmount} />
          </td>
          <td>
            <DashesIfNullOrUndefined data={expenseCaps} />
          </td>
          <td>
            <DashesIfNullOrUndefined data={grossUp} />
          </td>
          <td>
            {annualBudgetedExpenses !== null
              ? `${formatCurrency(intl, +annualBudgetedExpenses)}`
              : '---'}
          </td>
          <td>
            <DashesIfNullOrUndefined data={proRataShare} />
          </td>
          <td>
            <Button
              bsStyle="default"
              bsSize="small"
              onClick={() => {
                setSelectedAllocation(allocation);
                setAllocationSetupModalMode(
                  ALLOCATION_SETUP_MODAL_MODES.ALLOCATION,
                );
                setShowAllocationSetupModal(true);
              }}
            >
              <i className="icon et-pencil text-blue" />
            </Button>
          </td>
        </tr>,
      ];
    })
  );
};

export default CamAllocationsSetup;
