import React from 'react';

type Props = {
  type: string,
}

export const UnavailableIcon = () => (
  <div className="status-icon-big status-unavailable text-center">
    <i className="icon et-circle-delete"></i>
  </div>
);

export const DisabledIcon = () => (
  <div className="status-icon-big status-disabled text-center">
    <i className="icon et-comment-alert"></i>
  </div>
);

export const UploadIcon = () => (
  <div className="status-icon-big status-default text-center">
    <i className="icon et-upload"></i>
  </div>
);

export const ApprovedIcon = () => (
  <div className="status-icon-big status-approved text-center">
    <i className="icon et-check-shield"></i>
  </div>                
);

export const ConditionsIcon = () => (
  <div className="status-icon-big status-conditions text-center">
    <i className="icon et-alert-diamond"></i>
  </div>
);

export const DeclinedIcon = () => (
  <div className="status-icon-big status-declined text-center">
    <i className="icon et-construction"></i>
  </div>
);

export const ErrorIcon = () => (
  <div className="status-icon-big status-declined text-center">
    <i className="icon et-alert-urgent"></i>
  </div>
);

const ScreeningIcon = ({ type }: Props) => {
  const icons = {
    unavailable: <UnavailableIcon />,
    disabled: <DisabledIcon />,
    upload: <UploadIcon />,
    approved: <ApprovedIcon />,
    conditions: <ConditionsIcon />,
    declined: <DeclinedIcon />,
    error: <ErrorIcon />,
    pending: <UnavailableIcon />,
  };
  const icon = icons[type];
  return icon ? icon : <div />;
};

export default ScreeningIcon;