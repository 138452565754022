import { isNil, isEmpty, pathOr } from 'ramda';
import moment from 'moment';
import type Moment from 'moment';

const UTILITY_ALLOWANCE = 'UTILITY_ALLOWANCE';
const HUD_GROSS_RENT = 'HUD_GROSS_RENT';

export const dateSort = (a: Object, b: Object, order?: string = 'DESC') => {
  const oA = isNil(a) ? { updatedAt: new Date() } : a;
  const oB = isNil(b) ? { updatedAt: new Date() } : b;
  const dateATime = new Date(oA.updatedAt).getTime();
  const dateBTime = new Date(oB.updatedAt).getTime();
  if (order === 'DESC') {
    return dateBTime - dateATime;
  } else {
    return dateATime - dateBTime;
  }
};

export const isValidDateBuilder = (minStartDate: string) => (
  calendarDate: Moment,
) => {
  const defaultValue = moment.utc().startOf('date');
  const startDate = minStartDate
    ? moment.utc(minStartDate).add(1, 'days')
    : defaultValue;

  return calendarDate.isSameOrAfter(startDate);
};

export const initializeFormValues = (floorPlansIds: Array<string>) => {
  let initialValues = {};
  (floorPlansIds || []).forEach((floorPlanId) => {
    initialValues[floorPlanId] = {
      newHudGrossRent: undefined,
      newHudUa: undefined,
      newHudUaStartDate: undefined,
      newHudGrossRentStartDate: undefined,
    };
  });
  return initialValues;
};

export const reduceFormValues = (values: Object, floorPlans: Array<Object>) => {
  let result = [];
  Object.keys(values).forEach((floorPlanId) => {
    const floorPlan = floorPlans.find((fp) => fp.id === floorPlanId) || {};
    if (
      Object.values(values[floorPlanId]).some((value) => value !== undefined)
    ) {
      if (
        !isNil(values[floorPlanId]['newHudGrossRent']) &&
        !isNil(values[floorPlanId]['newHudGrossRentStartDate'])
      ) {
        result.push({
          floorPlanId,
          pricingType: HUD_GROSS_RENT,
          feeAmount: parseFloat(values[floorPlanId]['newHudGrossRent']),
          startDate: values[floorPlanId]['newHudGrossRentStartDate'].format(
            'YYYY-MM-DD',
          ),
        });
      }

      if (
        !isNil(values[floorPlanId]['newHudUa']) &&
        !isNil(values[floorPlanId]['newHudUaStartDate'])
      ) {
        const isFpUa = pathOr([], ['fpua'], floorPlan).length > 0;
        const uaNamePath = ['pha', 'name'];
        const allowances = isFpUa
          ? pathOr([], ['fpua'], floorPlan)
          : pathOr([], ['allowances'], floorPlan);
        const latestUa = allowances.find((allowance) =>
          isNil(allowance.endDate),
        );
        const name = pathOr(
          '',
          isFpUa ? ['ua'].concat(uaNamePath) : uaNamePath,
          latestUa,
        );

        result.push({
          floorPlanId,
          utilityAlowancesId: pathOr(
            '',
            isFpUa ? ['utilityAlowancesId'] : ['id'],
            latestUa,
          ),
          pricingType: `${name}_${UTILITY_ALLOWANCE}`,
          feeAmount: parseFloat(values[floorPlanId]['newHudUa']),
          startDate: values[floorPlanId]['newHudUaStartDate'].format(
            'YYYY-MM-DD',
          ),
        });
      }
    }
  });

  return result;
};

export const validatePairRequired = (
  values: Object,
  floorPlanId: string,
  isRent: boolean = false,
) => {
  const feeKey = isRent ? 'newHudGrossRent' : 'newHudUa';
  const feeValue = values[floorPlanId][feeKey];
  const dateKey = isRent ? 'newHudGrossRentStartDate' : 'newHudUaStartDate';
  const dateValue = values[floorPlanId][dateKey];
  const isFeeValueDefined = !isNil(feeValue) && !isEmpty(feeValue);
  const isDateValueDefined = !isNil(dateValue) && !isEmpty(dateValue);
  // prettier-ignore
  return {
    isValidPair: (isFeeValueDefined && isDateValueDefined) || (!isFeeValueDefined && !isDateValueDefined),
    requiredValue: !isFeeValueDefined ? feeKey : (!isDateValueDefined ? dateKey : ''),
  };
};

export const processFloorPlanWithPricingResult = (
  floorPlans: Array<Object>,
): Array<Object> => {
  let result = floorPlans;
  return result
    .filter((floorPlan) => floorPlan.hudFloorplan)
    .map((floorPlan) => {
      const fpua = pathOr([], ['fpua'], floorPlan);
      const allowances = pathOr([], ['allowances'], floorPlan);

      return {
        ...floorPlan,
        fpua: fpua.filter((uaAllowance) => {
          return pathOr(false, ['ua', 'pha', 'isHUD'], uaAllowance);
        }),
        allowances: allowances.filter((allowance) => {
          return pathOr(false, ['pha', 'isHUD'], allowance);
        }),
      };
    });
};
