import { defineMessages } from 'react-intl';

const messages = defineMessages({
  FloorplanPricingInformation: {
    id: 'App.ViewUnit.FloorplanPricing.FloorplanPricingInformation',
    defaultMessage: 'Floor Plan Pricing Information',
  },
});

export default messages;
