import React, { Component } from 'react';
import cn from 'classnames';

type Props = {
  className?: string,
  label?: any,
  error?: any,
  warning?: any,
  onChange: Function,
  name: string,
};

type State = {
  files: Array<Object>,
};

export default class FileField extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
      files: [],
    };
  }

  handleChange = (evt: Object) => {
    const { onChange } = this.props;
    const files = evt.target.files;
    this.setState({ files });
    onChange(files);
  };

  render() {
    const { label, className, name, error, warning, ...props } = this.props;
    const { files } = this.state;
    return (
      <div className={cn(className, 'form-group')}>
        <label className="input">{label}</label>
        <input
          className="inputfile inputfile-custom"
          id={name}
          type="file"
          {...props}
          name={name}
          onChange={this.handleChange}
        />
        <label htmlFor={name}>
          <span>{files && files.length > 0 && files[0].name}</span>
          <strong>Browse</strong>
        </label>
        {(error || warning) && (
          <span className="has-error">{error || warning}</span>
        )}
      </div>
    );
  }
}
