import React, { Component } from 'react';
import { connect } from 'react-redux';
import { intersection, pathOr, pluck } from 'ramda';
import type { ChildrenArray, Element } from 'react';

import type { UserPermission, GlobalState } from '../../containers/App/types';

type Props = {
  children: ChildrenArray<Element<any>>,
  hidden?: boolean,
  scope: Array<string>,
  link: string,
};

type StateProps = {
  userPermissions: Array<UserPermission>,
};

export class LinkedElementWithPermissions extends Component<
  StateProps & Props,
> {
  render() {
    const { children, scope, userPermissions } = this.props;
    // $FlowFixMe
    const userScopes = pluck('scope', userPermissions);
    const disabled = intersection(scope, userScopes).length === 0;
    if (disabled) {
      return children;
    }

    return (
      <a href={this.props.link} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }
}

export const mapStateToProps = ({ app }: GlobalState): StateProps => ({
  // $FlowFixMe
  userPermissions: pathOr([], ['currentUser', 'permissions'], app),
});

export default connect(mapStateToProps)(LinkedElementWithPermissions);
