import type {
  ProrateCalculation,
  ProrateCalculationColumns,
  ProrateDates,
} from './types';
import { pathOr } from 'ramda';

import { formatMoney } from '../../../utils/formatter';

export const formatProrateDates = (dates: ProrateDates): string => {
  return dates && dates.start && dates.end
    ? `${dates.start} - ${dates.end}`
    : '---';
};

export const getProrateCalculationColumns = (
  calculation: ProrateCalculation,
  doNotProrateTransactionCodes: Array<string>,
): ProrateCalculationColumns => {
  const transactionCodeId = pathOr(null, ['transactionCodeId'], calculation);
  const transactionCode = pathOr(null, ['transactionCode'], calculation);
  const transactionType = pathOr(null, ['transactionType'], calculation);
  const totalCharged = pathOr(null, ['totalCharged'], calculation);
  const totalProrate = pathOr(null, ['totalProrate'], calculation);
  const adjustmentsToPost = pathOr(null, ['adjustmentsToPost'], calculation);

  const currentLeaseMonthlyAmount = pathOr(
    null,
    ['priorLease', 'monthlyAmount'],
    calculation,
  );
  const currentLeaseProrateDatesObj = pathOr(
    null,
    ['priorLease', 'prorateDates'],
    calculation,
  );
  const currentLeaseProrateDates = formatProrateDates(
    currentLeaseProrateDatesObj,
  );
  const currentLeaseProrate = pathOr(
    null,
    ['priorLease', 'proratedAmount'],
    calculation,
  );

  const renewalLeaseMonthlyAmount = pathOr(
    null,
    ['renewalLease', 'monthlyAmount'],
    calculation,
  );
  const renewalLeaseProrateDatesObj = pathOr(
    null,
    ['renewalLease', 'prorateDates'],
    calculation,
  );
  const renewalLeaseProrateDates = formatProrateDates(
    renewalLeaseProrateDatesObj,
  );
  const renewalLeaseProrate = pathOr(
    null,
    ['renewalLease', 'proratedAmount'],
    calculation,
  );

  const doNotProrateChecked =
    doNotProrateTransactionCodes.includes(transactionCodeId);

  const adjustments = doNotProrateChecked ? null : adjustmentsToPost;

  return {
    transactionCodeId,
    doNotProrateChecked,
    columns: [
      transactionCode,
      transactionType,
      formatMoney(totalCharged),
      formatMoney(doNotProrateChecked ? totalCharged : totalProrate),
      formatMoney(adjustments),
      formatMoney(currentLeaseMonthlyAmount),
      currentLeaseProrateDates,
      formatMoney(currentLeaseProrate),
      formatMoney(renewalLeaseMonthlyAmount),
      renewalLeaseProrateDates,
      formatMoney(renewalLeaseProrate),
    ],
  };
};
