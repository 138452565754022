import { Col, Row } from 'react-bootstrap';
import messages from '../messages';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import {
  renderDateField,
  renderSelectField,
  renderTimeField,
} from '../../../utils/redux-form-helper';
import React from 'react';
import { contains, defaultTo, find, propEq } from 'ramda';
import type {
  ActivityType,
  DropdownOption,
} from '../../../containers/App/types';
import validate from '../validate';
import warn from '../warn';
import asyncValidate from '../asyncValidate';
import { bindActionCreators } from 'redux';
import actions from 'redux-form/lib/actions';
import { connect } from 'react-redux';
import FormTemplate from '../FormTemplate';
import { FORM_NAME } from '../constants';

type Props = {
  intl: Object,
  locale: string,
  store: any,
  activityTypesList: Array<DropdownOption & ActivityType>,
  assigneeList: Array<DropdownOption>,
  activityTypeId: string,
  change: Function,
  handleSubmit: Function,
  onSubmit: Function,
  mode: string,
};

const RecordForm = (props: Props) => {
  const {
    intl,
    store,
    locale,
    activityTypesList,
    assigneeList,
    activityTypeId,
    change,
    handleSubmit,
    onSubmit,
    error,
    warning,
  } = props;

  const generateCompletionStatusList = () => {
    let options = [];

    if (activityTypeId) {
      const selectedActivityType = defaultTo({ completionStatusOptions: [] })(
        find(propEq('value', activityTypeId))(activityTypesList),
      );
      const visibleOptions =
        selectedActivityType.completionStatusOptions.filter(
          (option) => option.showForRecord,
        );
      options = visibleOptions.map((option) => ({
        value: option.id,
        text: option.translations[locale] || option.name,
      }));
    }
    options.unshift({
      value: 'default',
      text: intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return options;
  };

  const onActivityTypeChange = () => {
    change('activityCompletionStatusId', 'default');
  };

  const onHandleSubmit = (formValues) => {
    if (onSubmit) onSubmit(formValues);
  };

  const recordForm = (
    <form encType="multipart/form-data" onSubmit={handleSubmit(onHandleSubmit)}>
      <Row>
        <Col md={12}>
          <Field
            label={intl.formatMessage(messages.activityTypeLabel)}
            store={store}
            name="activityTypeId"
            component={renderSelectField}
            options={activityTypesList}
            bsSize="lg"
            onChange={onActivityTypeChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Field
            label={intl.formatMessage(messages.activityCompletionLabel)}
            store={store}
            name="activityCompletionStatusId"
            component={renderSelectField}
            options={generateCompletionStatusList()}
            bsSize="lg"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field
            label={intl.formatMessage(messages.activityDateLabel)}
            store={store}
            name="startDate"
            component={renderDateField}
            classPicker="modal-fixed-position"
            bsSize="lg"
          />
        </Col>
        <Col md={6}>
          <Field
            label={intl.formatMessage(messages.recordTimeLabel)}
            store={store}
            name="startTime"
            component={renderTimeField}
            bsSize="lg"
          />
        </Col>
      </Row>
      {assigneeList.length > 0 ? (
        <Row>
          <Col md={12}>
            <Field
              label={intl.formatMessage(messages.scheduleByLabel)}
              store={store}
              name="assignedToId"
              component={renderSelectField}
              options={assigneeList}
              bsSize="lg"
            />
          </Col>
        </Row>
      ) : (
        <Typography variant={'caption'} color={'error'}>
          {intl.formatMessage(messages.emptyAssignessListError)}
        </Typography>
      )}
      <Row>
        <Col md={12}>
          <Typography variant={'caption'}>
            {intl.formatMessage(messages.footerNote)}
          </Typography>
        </Col>
      </Row>
    </form>
  );

  return (
    <FormTemplate
      formFields={recordForm}
      intl={intl}
      store={store}
      error={error}
      warning={warning}
    />
  );
};

const selector = formValueSelector(FORM_NAME.RECORD);

const mapStateToProps = (state) => {
  if (state.app.selectedProperty) {
    return {
      locale: state.languageProvider.locale,
      startDate: selector(state, 'startDate'),
      startTime: selector(state, 'startTime'),
      endTime: selector(state, 'endTime'),
      notes: selector(state, 'notes'),
      activities: state.home.activities,
      activityTypeId: selector(state, 'activityTypeId'),
      activityCompletionStatusId: selector(state, 'activityCompletionStatusId'),
      assignedToId: selector(state, 'assignedToId'),
      allDay: selector(state, 'allDay'),
      selectedProperty: state.app.selectedProperty,
      organizationId: state?.app?.currentUser?.user?.organizationId ?? '',
    };
  }
  throw new Error('A property must be selected.');
};

const mapDispatchToProps = (dispatch: any): Object => {
  const bindedActions = bindActionCreators(
    { updateSyncWarnings: actions.updateSyncWarnings },
    dispatch,
  );
  return {
    actions: bindedActions,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: FORM_NAME.RECORD,
    touchOnChange: true,
    validate: validate,
    warn: warn,
    asyncValidate: asyncValidate,
    shouldAsyncValidate: (params) => {
      if (!params.syncValidationPasses) {
        return false;
      }
      if (params.trigger === 'change') {
        return false;
      }
      return contains(params.blurredField, [
        'startDate',
        'startTime',
        'endTime',
        'activityTypeId',
        'assignedToId',
      ]);
    },
  })(RecordForm),
);
