import { defineMessages } from 'react-intl';

export default defineMessages({
  noReportForSelectedDate: {
    id: 'App.AllManageReports.NoReportForSelectedDate',
    defaultMessage: 'No reports found for selected date',
  },
  noReportForLast90Days: {
    id: 'App.AllManageReports.NoReportForLast90Days',
    defaultMessage: 'No reports in the last 90 days',
  },
  reportGeneratedDate: {
    id: 'App.AllManageReports.ReportGeneratedDate',
    defaultMessage: 'Report Generated Date',
  },
});
