import type { ApplicationsInProcessEntity, Highlight } from '../types';
import moment from 'moment';
import { ASSIGN_TYPE } from '../constants';
import { getHighlightColor } from './general/getHighlightColor';
import messages from '../messages';
import { getUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';

type ApplicationInProcessHeaderArgs = {
  intl: Object,
  taskTabNumberOfBedroomsFlag: boolean,
  taskTabAssigneeDropdownFlag: boolean,
};

type ApplicationInProcessHeader = {
  id: number,
  text: Object,
  // eslint-disable-next-line flowtype/space-after-type-colon
  sortKey:
    | 'unit'
    | 'numberOfBedrooms'
    | 'applicant'
    | 'desiredMoveInDate'
    | 'portalUser'
    | 'assignedTo',
};

type MappedApplicationsInProcess = {
  id: string,
  unit: string,
  numberOfBedrooms?: string,
  applicant: { link: string, value: string },
  desiredMoveInDate: 'string',
  portalUser: 'string',
  assignedTo: Object,
  highlight: Highlight,
};

export const mapApplicationsInProcess = (props: {
  applicationsInProcess: ApplicationsInProcessEntity[],
  taskTabNumberOfBedroomsFlag: boolean,
  taskTabAssigneeDropdownFlag: boolean,
}): MappedApplicationsInProcess[] => {
  const {
    applicationsInProcess,
    taskTabNumberOfBedroomsFlag,
    taskTabAssigneeDropdownFlag,
  } = props;
  return applicationsInProcess.map((task) => {
    const moveInDateFrom = task.move_in_from;
    const moveInDateFromMoment = moment(moveInDateFrom);
    const moveInDateTo = task.move_in_to;
    const moveInDateToMoment = moment(moveInDateTo);
    let desiredMoveInDate = moveInDateFrom
      ? moveInDateFromMoment.format('MM/DD/YYYY')
      : '';
    if (moveInDateFrom && moveInDateTo) {
      desiredMoveInDate += ' - ' + moveInDateToMoment.format('MM/DD/YYYY');
    }
    const userAssigned = task.assigned_to;

    const highlight = getHighlightColor({
      checkDate: task.move_in_from,
      status: task.userStatus,
      checkEndDate: task.move_in_to,
    });

    const assignedTo = taskTabAssigneeDropdownFlag
      ? userAssigned ?? 'N/A'
      : userAssigned
      ? {
          value: task.assignedToId,
          text: userAssigned,
          disabled: false,
          dropDownMenuAssign: ASSIGN_TYPE.APPLICATION,
        }
      : 'N/A';

    if (taskTabNumberOfBedroomsFlag) {
      return {
        id: task.id,
        unit: task.unit || '---',
        numberOfBedrooms: String(task.nBeds ?? '---'),
        applicant: {
          link: getUrlWithSelectedPropertyId(
            `/application/${task.application_id}`,
          ),
          value: task.name || 'N/A',
        },
        desiredMoveInDate: desiredMoveInDate || 'N/A',
        portalUser: task.portalUser || '---',
        assignedTo,
        highlight,
      };
    }

    return {
      id: task.id,
      unit: task.unit || '---',
      applicant: {
        link: getUrlWithSelectedPropertyId(
          `/application/${task.application_id}`,
        ),
        value: task.name || 'N/A',
      },
      desiredMoveInDate: desiredMoveInDate || 'N/A',
      portalUser: task.portalUser || '---',
      assignedTo: userAssigned
        ? {
            value: task.assignedToId,
            text: userAssigned,
            disabled: false,
            dropDownMenuAssign: ASSIGN_TYPE.APPLICATION,
          }
        : 'N/A',
      highlight,
    };
  });
};

export const applicationsInProcessHeaders = ({
  intl,
  taskTabNumberOfBedroomsFlag,
}: ApplicationInProcessHeaderArgs): ApplicationInProcessHeader[] => {
  return taskTabNumberOfBedroomsFlag
    ? [
        {
          id: 1,
          text: intl.formatMessage(messages.unitHeader),
          sortKey: 'unit',
        },
        {
          id: 2,
          text: intl.formatMessage(messages.numberOfBedroomsHeader),
          sortKey: 'numberOfBedrooms',
        },
        {
          id: 3,
          text: intl.formatMessage(messages.applicantHeader),
          sortKey: 'applicant',
        },
        {
          id: 4,
          text: intl.formatMessage(messages.desiredMoveInDateHeader),
          sortKey: 'desiredMoveInDate',
        },
        {
          id: 5,
          text: intl.formatMessage(messages.portalUser),
          sortKey: 'portalUser',
        },
        {
          id: 6,
          text: intl.formatMessage(messages.assignedToHeader),
          sortKey: 'assignedTo',
        },
      ]
    : [
        {
          id: 1,
          text: intl.formatMessage(messages.unitHeader),
          sortKey: 'unit',
        },
        {
          id: 2,
          text: intl.formatMessage(messages.applicantHeader),
          sortKey: 'applicant',
        },
        {
          id: 3,
          text: intl.formatMessage(messages.desiredMoveInDateHeader),
          sortKey: 'desiredMoveInDate',
        },
        {
          id: 4,
          text: intl.formatMessage(messages.portalUser),
          sortKey: 'portalUser',
        },
        {
          id: 5,
          text: intl.formatMessage(messages.assignedToHeader),
          sortKey: 'assignedTo',
        },
      ];
};
