import React from 'react';

import messages from './messages';
import { PlusIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { TableHeader } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import useIntl from '../../../hooks/useIntl';

const ManageDepositsHeader = () => {
  const intl = useIntl();

  return (
    <TableHeader
      title={intl.formatMessage(messages.title)}
      actions={
        <Button onClick={() => null} variant="shout" startIcon={<PlusIcon />}>
          {intl.formatMessage(messages.createRentForecast)}
        </Button>
      }
    />
  );
};

export default ManageDepositsHeader;
