import { defineMessages } from 'react-intl';

const messages = defineMessages({
  date: {
    id: 'App.CreateSubsidyBatch.UploadDocuments.Date',
    defaultMessage: 'Date',
  },
  name: {
    id: 'App.CreateSubsidyBatch.UploadDocuments.Name',
    defaultMessage: 'Name',
  },
  notes: {
    id: 'App.CreateSubsidyBatch.UploadDocuments.Notes',
    defaultMessage: 'Notes',
  },
  actions: {
    id: 'App.CreateSubsidyBatch.UploadDocuments.Actions',
    defaultMessage: 'Actions',
  },
  addDocument: {
    id: 'App.CreateSubsidyBatch.UploadDocuments.AddDocument',
    defaultMessage: 'Add Document',
  },
  confirmDelete: {
    id: 'App.CreateSubsidyBatch.UploadDocuments.ConfirmDelete',
    defaultMessage: 'Are you sure you want to delete?',
  },
  attachmentDeleteSuccessTitle: {
    id: 'App.CreateSubsidyBatch.UploadDocuments.AttachmentDeleteSuccessTitle',
    defaultMessage: 'Attachment deleted',
  },
  attachmentDeleteSuccessMessage: {
    id: 'App.CreateSubsidyBatch.UploadDocuments.AttachmentDeleteSuccessMessage',
    defaultMessage: 'The attachment was deleted successfully.',
  },
  attachmentDeleteErrorTitle: {
    id: 'App.CreateSubsidyBatch.UploadDocuments.AttachmentDeleteErrorTitle',
    defaultMessage: 'Error deleting attachment',
  },
  attachmentDownloadErrorTitle: {
    id: 'App.CreateSubsidyBatch.UploadDocuments.AttachmentDownloadErrorTitle',
    defaultMessage: 'Error downloading attachment',
  },
  attachmentsLoadingErrorTitle: {
    id: 'App.CreateSubsidyBatch.UploadDocuments.AttachmentsLoadingErrorTitle',
    defaultMessage: 'Error loading attachments',
  },
  noDataDescription: {
    id: 'App.CreateSubsidyBatch.UploadDocuments.NoDataDescription',
    defaultMessage: 'There is currently no data to display',
  },
});

export default messages;
