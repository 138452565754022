import formMessages from './messages';
import type { SecundaryFormOtherInformation } from '../../App/types';
import moment from 'moment';

const validate = (values: SecundaryFormOtherInformation, { intl }: Object) => {
  const errors = {};

  if (values.bankruptcyDateFiled) {
    const formattedDate = moment(values.bankruptcyDateFiled);
    const todayDate = new Date();
    if (!formattedDate.isBefore(todayDate)) {
      errors.bankruptcyDateFiled = intl.formatMessage(
        formMessages.invalidDateValue
      );
    }
  }

  return errors;
};

export default validate;
