import React, { useMemo } from 'react';
import {
  ControlLabel,
  FormControl,
  HelpBlock,
  InputGroup,
} from 'react-bootstrap';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { FormattedMessage, injectIntl } from 'react-intl';
import styled from 'styled-components';
import messages from '../messages';

type Props = {
  from?: string,
  to?: string,
  exactAmountValue?: string,
  onFromChange: Function,
  onToChange: Function,
  onExactAmountChange: Function,
  clearAmountRange: Function,
  clearExactAmount: Function,
};

const Separator = styled.div`
  width: 16px;
  height: 0;
  border: 1px solid #b4b4b4;
  margin: 0 10px;
`;

export const isFromAmountGreaterThanToAmount = (
  fromAmount?: string,
  toAmount?: string,
) => {
  if (!fromAmount || !toAmount) return false;
  return parseInt(fromAmount) >= parseInt(toAmount);
};

function VariableAmountControl(props: Props) {
  const showError = useMemo(
    () => isFromAmountGreaterThanToAmount(props.from, props.to),
    [props.from, props.to],
  );
  const onRangeCheckboxChange = () => {
    if (props.from || props.to) {
      props.clearAmountRange();
    }
    props.setIsRange(!props.isRange);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
          <ControlLabel>
            <FormattedMessage {...messages.amount} />
          </ControlLabel>
          <Stack direction="row" spacing={1}>
            <input
              type="checkbox"
              data-testid="onRangeCheckbox"
              checked={props.isRange}
              onChange={onRangeCheckboxChange}
            />
            <ControlLabel>
              <FormattedMessage {...messages.range} />
            </ControlLabel>
          </Stack>
        </Stack>
      </Grid>
      {!props.isRange && (
        <Grid item xs={12}>
          <Stack direction="row">
            <InputGroup style={{ flexGrow: 1 }}>
              <InputGroup.Addon>$</InputGroup.Addon>
              <FormControl
                id="exactAmount"
                style={{ height: '33px' }}
                placeholder={props.isRange ? 'From' : '0.00'}
                type="number"
                onChange={props.onExactAmountChange}
                value={props.exactAmountValue}
              />
            </InputGroup>
          </Stack>
        </Grid>
      )}
      {props.isRange && (
        <Stack direction="row" alignItems="center">
          <Grid item xs={6}>
            <Stack direction="row">
              <InputGroup style={{ flexGrow: 1 }}>
                <InputGroup.Addon>$</InputGroup.Addon>
                <FormControl
                  id="toAmount"
                  style={{ height: '33px' }}
                  placeholder={'From'}
                  type="number"
                  onChange={props.onFromChange}
                  value={props.from || props.exactAmountValue}
                />
              </InputGroup>
            </Stack>
          </Grid>
          <Separator />
          <Grid item xs={6}>
            <InputGroup style={{ flexGrow: 1 }}>
              <InputGroup.Addon>$</InputGroup.Addon>
              <FormControl
                style={{ height: '33px' }}
                placeholder={'To'}
                type="number"
                onChange={props.onToChange}
                value={props.to}
              />
            </InputGroup>
          </Grid>
        </Stack>
      )}
      {showError && (
        <HelpBlock className="has-error">
          <FormattedMessage {...messages.amountValidationError} />
        </HelpBlock>
      )}
    </Grid>
  );
}

export default injectIntl(VariableAmountControl);
