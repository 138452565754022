import React from 'react';
import { FieldArray } from 'redux-form';
import PetFields from './PetFields';
class PendingPets extends React.Component<any> {
  render() {
    return (
        <div className="row">
              <FieldArray
                name="pendingPets"
                component={PetFields}
                deletePet={this.props.deletePet}
                isPendingApplicant
              />
        </div>
    );
  }
}

export default PendingPets;
