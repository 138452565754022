export const handleLeaseDownloadErrorMessage = (error) => {
  if (
    typeof error === 'string' &&
    error.toLowerCase().includes('lease packet snapshot not found')
  ) {
    return (
      'Lease Packet not found. Unlock and re-save the lease, ' +
      'then try downloading again, or contact your admin for support.'
    );
  }

  return error?.toString() ?? 'An error occurred downloading the lease.';
};

export const handleLeaseSaveErrorMessage = (error) => {
  if (
    typeof error === 'string' &&
    error.toLowerCase().includes('lease document not found')
  ) {
    return 'Lease document not found. Please contact your admin for support.';
  }
  if (
    typeof error === 'string' &&
    error.toLowerCase().includes('unit type not found')
  ) {
    return 'Failed to save the lease. Please contact your admin for support.';
  }

  return error?.toString() ?? 'An error occurred saving the lease.';
};
