/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  unitNumber: {
    id: 'App.ManageFloorPlan.UnitsAssigned.UnitNumber',
    defaultMessage: 'Unit',
  },
  unitLevel: {
    id: 'App.ManageFloorPlan.UnitsAssigned.UnitLevel',
    defaultMessage: 'Unit Level',
  },
  unitAda: {
    id: 'App.ManageFloorPlan.UnitsAssigned.UnitAda',
    defaultMessage: 'ADA',
  },
  unitStatus: {
    id: 'App.ManageFloorPlan.UnitsAssigned.UnitStatus',
    defaultMessage: 'Status',
  },
  residentAssignedtoUnit: {
    id: 'App.ManageFloorPlan.UnitsAssigned.ResidentAssignedtoUnit',
    defaultMessage: 'Resident',
  },
  applicantAssignedtoUnit: {
    id: 'App.ManageFloorPlan.UnitsAssigned.ApplicantAssignedtoUnit',
    defaultMessage: 'Applicant',
  },
  noOfUnits: {
    id: 'App.ManageFloorPlan.UnitsAssigned.noOfUnits',
    defaultMessage: '# of Units: ',
  },
  unitsAssignedTo: {
    id: 'App.ManageFloorPlan.UnitsAssigned.unitsAssignedTo',
    defaultMessage: 'Units assigned to ',
  },
  transfer: {
    id: 'App.ManageFloorPlan.UnitsAssigned.transfer',
    defaultMessage: '(transfer)',
  },
  emptyStateMessage: {
    id: 'App.ManageFloorPlan.UnitsAssigned.emptyStateMessage',
    defaultMessage: 'No units assigned to this floor plan. ',
  },
});

export default messages;
