import type { Action, User } from '../App/types';
import * as ActionTypes from './constants';

export function getUser(userId: string): * {
  return {
    type: ActionTypes.GET_USER,
    userId,
  };
}

export function setSelectedProperties(properties: Array<string>): * {
  return {
    type: ActionTypes.SET_SELECTED_PROPERTIES,
    payload: properties,
  };
}
export function setSelectedPropertyGroups(propGroups: Array<string>): * {
  return {
    type: ActionTypes.SET_SELECTED_PROPERTY_GROUPS,
    payload: propGroups,
  };
}

export function setUserAllProperties(val: boolean): * {
  return {
    type: ActionTypes.SET_USER_ALL_PROPERTIES,
    payload: val,
  };
}

export function getUserSuccess(user: User): Action<User> {
  return {
    type: ActionTypes.GET_USER_SUCCESS,
    payload: user,
  };
}

export function getUserFail(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_USER_FAIL,
    payload: error,
  };
}

export function showNonePropertiesSelectedError(
  nonePropertiesSelected: boolean,
): * {
  return {
    type: ActionTypes.NONE_PROPERTIES_SELECTED,
    payload: nonePropertiesSelected,
  };
}

export function editUser(user: User, userId: string): Action<User> {
  return {
    type: ActionTypes.EDIT_USER,
    payload: user,
    id: userId,
  };
}

export function editUserSuccess(): any {
  return {
    type: ActionTypes.EDIT_USER_SUCCESS,
  };
}

export function editUserFail(error: Error): Action<Error> {
  return {
    type: ActionTypes.EDIT_USER_FAIL,
    payload: error,
  };
}

export function toggleMultiselect(value: boolean): * {
  return {
    type: ActionTypes.TOGGLE_MULTISELECT,
    payload: value,
  };
}
