import type { ResidentMoveOutInfo } from '../types.js';
import { type, pathOr } from 'ramda';
import moment from 'moment';
import messages from './messages';
import ntvMessages from '../NoticeToVacateModal/messages';

const validateDateFormat = (intl, date) => {
  const formattedDate = moment(date);
  const todayDate = moment(Date.now());
  if (!formattedDate.isValid() || type(date) === 'String') {
    return intl.formatMessage(messages.invalidDateFormat);
  } else if (formattedDate.isAfter(todayDate)) {
    return intl.formatMessage(messages.invalidDateValue);
  }
};

const validateSelection = (intl, selection) => {
  if (selection === 'choose') {
    return intl.formatMessage(messages.required);
  }
};

const validate = (
  values: ResidentMoveOutInfo,
  { intl, openFiscalPeriod, isFirstFiscalPeriod }: Object,
) => {
  const errors = {};
  const moveOutDate = moment(values.moveOutDate);
  const OFPStartDate = moment(pathOr(null, ['startDate'], openFiscalPeriod));

  errors.residentPaidAmtDueAtMoveOut = !values.residentPaidAmtDueAtMoveOut
    ? intl.formatMessage(messages.required)
    : validateSelection(intl, values.residentPaidAmtDueAtMoveOut);
  errors.turnedInKeys = !values.turnedInKeys
    ? intl.formatMessage(messages.required)
    : validateSelection(intl, values.turnedInKeys);
  errors.moveOutChecklistCompleted = !values.moveOutChecklistCompleted
    ? intl.formatMessage(messages.required)
    : validateSelection(intl, values.moveOutChecklistCompleted);

  errors.moveOutDate = !values.moveOutDate
    ? intl.formatMessage(messages.required)
    : validateDateFormat(intl, values.moveOutDate);

  if (
    !errors.moveOutDate &&
    moveOutDate.isBefore(OFPStartDate, 'day') &&
    !isFirstFiscalPeriod
  ) {
    errors.moveOutDate = intl.formatMessage(ntvMessages.beforeFiscalPeriod);
  }

  return errors;
};

export default validate;
