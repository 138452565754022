import { TextingTab as TextingTabComp } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress'; // eslint-disable-line max-len
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { useTextingTab } from './hooks';
import { injectIntl } from 'react-intl';
import { Props } from './types';
import { CONVERSATION_HEIGHT } from './constants';

function TextingTab(props: Props) {
  const {
    conversations,
    messages,
    hasMoreMessages,
    selectedConversationId,
    message,
    isSending,
    textingPhoneNumber,
    isLoading,
    handleLoadMoreMessages,
    handleSelectConversation,
    handleMessageChange,
    handleSendMessage,
    handleToggleResolved,
  } = useTextingTab(props);

  if (isLoading) return <Spinner />;

  return (
    <TextingTabComp
      intl={props.intl}
      conversationHeight={CONVERSATION_HEIGHT}
      conversations={conversations}
      messages={messages}
      hasMoreMessages={hasMoreMessages}
      selectedConversationId={selectedConversationId}
      message={message}
      isSending={isSending}
      textingPhoneNumber={textingPhoneNumber}
      onLoadMoreMessages={handleLoadMoreMessages}
      onSelectConversation={handleSelectConversation}
      onMessageChange={handleMessageChange}
      onSendMessage={handleSendMessage}
      onToggleResolved={handleToggleResolved}
    />
  );
}

export default injectIntl(TextingTab);
