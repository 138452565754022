// $FlowFixMe
import { useCallback, useEffect, useState } from 'react';
import { INIT_PAG_PROPERTIES, INIT_QUERY } from './constants';
import OwnerService from '../../services/ownerService';
import PropertyService from '../../services/propertyService';
import messages from './messages';

export const useAsyncProperties = (
  organizationId: string,
  setActiveFilters: Function,
  setCurrentFilters: Function,
) => {
  const [paginatedPropertyInfo, setProperties] = useState(INIT_PAG_PROPERTIES);
  const [query, setQuery] = useState(INIT_QUERY);
  const [update, setUpdate] = useState(false);

  const updateQuery = (newQuery) => {
    setUpdate(true);
    setQuery(newQuery);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchProperties = async () => {
      if (paginatedPropertyInfo.results === null || update) {
        const propertyService = new PropertyService();
        const options = { signal: abortController.signal };
        const propertiesRes = await propertyService.getProperties(
          organizationId,
          query,
          options,
        );
        setProperties(propertiesRes);
        setUpdate(false);
        setCurrentFilters(query.filterValue);
        setActiveFilters(query.filterValue);
      }
    };

    if (subscribed) {
      fetchProperties();
    }

    return () => {
      subscribed = false;
    };
  }, [
    organizationId,
    setActiveFilters,
    setCurrentFilters,
    query,
    update,
    paginatedPropertyInfo,
  ]);

  return { ...paginatedPropertyInfo, query, updateQuery };
};

export const useAsyncOwners = (
  organizationId: string,
  formatMessage: Function,
  toasterFn: Function,
) => {
  const [owners, setOwners] = useState([]);
  const [subscribed, setSubscribed] = useState(true);
  const fetchOwners = useCallback(async () => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const ownerService = new OwnerService();
    const options = { signal: abortController.signal };

    setSubscribed(true);

    try {
      const ownersResponse = await ownerService.getPropertyOwners(
        organizationId,
        options,
      );

      if (ownersResponse.error) {
        throw ownersResponse.error;
      }
      if (subscribed) {
        if (ownersResponse.length === 0) {
          toasterFn({
            type: 'info',
            title: formatMessage(messages.success),
            message: formatMessage(messages.ownerFetchEmpty),
          });
        }

        // Update State
        setOwners(ownersResponse);
      }
    } catch (error) {
      if (subscribed) {
        // Ask Zoltan about weird error here
        toasterFn({
          type: 'error',
          title: formatMessage(messages.errorHeader),
          message: formatMessage(messages.ownerFetchError),
        });
      }
      setOwners([]);
    }
  }, [organizationId, formatMessage, toasterFn, subscribed]); // TODO ask Zoltan about dependency array

  useEffect(() => {
    setSubscribed(true);
    fetchOwners();
    return () => {
      setSubscribed(false);
    };
  }, [fetchOwners]);

  return {
    owners,
    setOwners,
  };
};
