import React, { useMemo } from 'react';
import moment from 'moment/moment';
import {
  Paper,
  Stack,
  Divider,
  SizedBox,
  Typography,
  Spinner,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import messages from './messages';
import ResidentHistoryTable from './ResidentHistoryTable';
import { useFlags } from 'launchdarkly-react-client-sdk';

type UnitHistoryRowData = {
  id: string,
  floorPlan: string,
  setAside?: string | null,
  changedOn: string,
  changedBy: string,
};

type HouseholdHistoryRowData = {
  id: string,
  residentId: string,
  moveInDate: string,
  moveOutDate: string,
};

type Props = {
  rows: UnitHistoryRowData[],
  intl?: Intl,
  onFloorPlanClick?: (id: string, floorPlan: string) => void,
  isLoading?: boolean,
  householdUnitHistoryProps?: {
    rows: HouseholdHistoryRowData[],
    onResidentClick?: (id: string, resident: string) => void,
    isLoading?: boolean,
    isError?: boolean,
  },
};

const DATE_FORMAT = 'MM/DD/YYYY';
const tableGridSx = {
  maxHeight: 260,
  overflowY: 'auto',
  maxWidth: '100%',
  overflowX: 'auto',
};

const UnitHistoryTable = ({ rows = [], onFloorPlanClick, intl }: Props) => {
  const formatMessage = intl?.formatMessage;
  return (
    <Grid container role="table" aria-label="Unit History Table">
      <Grid
        container
        paddingX={2}
        role="row"
        aria-label="Unit History Table Header Row"
      >
        <Grid item xs={4} role="cell">
          <Typography variant="h4">
            {formatMessage?.(messages.floorPlanColumn)}
          </Typography>
        </Grid>
        <Grid item xs={3} role="cell">
          <Typography variant="h4">
            {formatMessage?.(messages.setAsideColumn)}
          </Typography>
        </Grid>
        <Grid item xs={5} role="cell">
          <Typography variant="h4">
            {formatMessage?.(messages.changedOnColumn)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SizedBox h={5} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        container
        role="rowgroup"
        aria-label="Unit History Table Rows"
        marginTop={1}
        paddingX={2}
        sx={tableGridSx}
      >
        {rows.map((rowData, i) => (
          <Grid container key={i} role="row">
            <Grid item xs={12}>
              <SizedBox h={5} />
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={4} role="cell">
                <Typography
                  variant="tableLink"
                  fontSize={14}
                  role="button"
                  onClick={() => {
                    onFloorPlanClick?.(rowData.id, rowData.floorPlan);
                  }}
                >
                  {rowData.floorPlan}
                </Typography>
              </Grid>
              <Grid item xs={3} role="cell">
                <Typography>{rowData.setAside ?? '---'}</Typography>
              </Grid>
              <Grid item xs={5} role="cell">
                <Typography>
                  {moment(rowData.changedOn).format(DATE_FORMAT)} by{' '}
                  {rowData.changedBy}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SizedBox h={5} />
            </Grid>
            {i + 1 !== rows.length && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const UnitHistory = (props: Props) => {
  const formatMessage = props.intl?.formatMessage;
  const { affordableTransfers: affordableTransfersFlag } = useFlags();
  const content = useMemo(() => {
    if (props.isLoading) return <Spinner />;
    if (props.rows.length === 0)
      return (
        <Typography textAlign="center">
          {formatMessage?.(messages.emptyStateMessage)}
        </Typography>
      );
    return <UnitHistoryTable {...props} />;
  }, [props, formatMessage]);

  return (
    <Paper sx={{ paddingLeft: 0.5, paddingBottom: 2 }}>
      <Stack>
        <Typography variant="h2" padding={2}>
          {formatMessage?.(messages.header)}
        </Typography>
        <Divider />
        <div style={{ height: 20 }} />
        {content}
        {affordableTransfersFlag && (
          <>
            <div style={{ height: 20 }} />
            <Divider />
            <Typography variant="bodyLarge" padding={2}>
              {formatMessage?.(messages.residentHistory)}
            </Typography>
            <ResidentHistoryTable
              {...props.householdUnitHistoryProps}
              intl={props.intl}
            />
          </>
        )}
      </Stack>
    </Paper>
  );
};

UnitHistory.defaultProps = {
  isLoading: false,
  onFloorPlanClick: () => {},
};

export default UnitHistory;
