import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';

import PropertyService from '../../../services/propertyService';
import LeaseService from '../../../services/leaseService';
import type {
  FetchPropertyRenewalProrateMethodProps,
  FetchFetchLeaseRenewalProratesProps,
} from './types';
import { PRORATE_METHODS } from '../../../components/CompleteRenewalCheck/RenewalProrates/constants';

export const useFetchPropertyRenewalProrateMethod = ({
  propertyId,
  organizationId,
}: FetchPropertyRenewalProrateMethodProps): any => {
  const [method, setMethod] = useState(PRORATE_METHODS.NO_NEVER);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetch = async () => {
      if (organizationId && propertyId) {
        setIsLoading(true);
        const propertyService = new PropertyService();
        const response = await propertyService.getPropertyById(
          organizationId,
          propertyId,
          { signal: abortController.signal },
        );
        const prorateMethodName = pathOr(
          PRORATE_METHODS.NO_NEVER,
          ['renewalProrateMethod', 'name'],
          response,
        );
        setMethod(prorateMethodName);
        setIsLoading(false);
      }
    };

    fetch();

    return () => abortController.abort();
  }, [organizationId, propertyId, method]);

  return [isLoading, method];
};

export const useFetchLeaseRenewalProrates = ({
  propertyId,
  organizationId,
  leaseId,
}: FetchFetchLeaseRenewalProratesProps): any => {
  const [prorates, setProrates] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetch = async () => {
      if (organizationId && propertyId && leaseId) {
        try {
          setIsLoading(true);
          const leaseService = new LeaseService();
          const response = await leaseService.getRenewalLeaseProrates(
            organizationId,
            propertyId,
            leaseId,
            { signal: abortController.signal },
          );
          setProrates(response);
        } catch (err) {
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetch();

    return () => abortController.abort();
  }, [organizationId, propertyId, leaseId]);

  return [isLoading, prorates];
};
