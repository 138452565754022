import * as R from 'ramda';
import type { IncomeLimitsFormValues } from './types';
import { validateDate } from '../../../../utils/validations';
import { getPplFieldName } from './utils';
import { PPL } from './constants';

// PPL fields 1-8 are required, 9-12 optional
const requiredFields = PPL.filter((number) => number < 9).map((number) =>
  getPplFieldName(number),
);

const allPplFields = PPL.map((number) => getPplFieldName(number));

function validate(values: IncomeLimitsFormValues, allValues: any) {
  const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty]);

  // Validate PPL fields
  const errors: Object = allPplFields.reduce((errors, field) => {
    const fieldValue = values[field];
    if (requiredFields.includes(field) && isNilOrEmpty(fieldValue)) {
      return {
        ...errors,
        [field]: 'Required Field',
      };
    }

    if (+fieldValue < 0 || +fieldValue > 1000000) {
      return {
        ...errors,
        [field]: 'Must be between 0 and 1000000',
      };
    }

    return errors;
  }, {});

  // Validate effective date, only for new entries since on existing ones we can't modify the date
  if (allValues.isNewEntry && !validateDate(values.effectiveDate)) {
    errors.effectiveDate = 'Invalid Date';
  }

  return errors;
}

export default validate;
