import { isNil } from 'ramda';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import KPICardContainer from '../../../components/KPICardContainer';

import PercentageChange from '../PercentageChange';

const KpiBox = ({
  data = { firstAmount: 0, belowDescription: '' },
  topTitle,
  firstDescription,
  formatNumber,
  dataLoaded,
  isCurrency,
  redirectTo,
}: Object) => {
  const { firstAmount, belowDescription, belowAmount, percentChangeValues } =
    data;

  const formattedAmount = isCurrency
    ? formatNumber(firstAmount, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    : firstAmount;
  const kpiLinkContent = isNil(redirectTo) ? (
    formattedAmount
  ) : (
    <Link to={redirectTo} style={{ fontWeight: 400 }}>
      {formattedAmount}
    </Link>
  );

  return (
    <KPICardContainer title={<FormattedMessage {...topTitle} />}>
      <AsyncBox loading={!dataLoaded}>
        <div>
          <div className="row padtop30">
            <div className="col text-center">
              <span className="font30 text-blue">{kpiLinkContent}</span>
              <h5 className="font-10">
                <FormattedMessage {...firstDescription} />
              </h5>
            </div>
          </div>
          <div className="row padtop10">
            <h5 className="font-10 text-center">
              {belowDescription}{' '}
              <strong>
                {isCurrency
                  ? formatNumber(belowAmount, {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })
                  : belowAmount}
              </strong>
              {percentChangeValues && (
                <PercentageChange
                  changePositive={percentChangeValues.chargesPositive}
                  percentChange={percentChangeValues.chargesPercentChange}
                  formatNumber={formatNumber}
                />
              )}
            </h5>
          </div>
        </div>
      </AsyncBox>
    </KPICardContainer>
  );
};

export default KpiBox;
