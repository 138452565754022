import { defineMessages } from 'react-intl';

/* eslint-disable max-len */
const messages = defineMessages({
  header: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.Header',
    defaultMessage: 'Edit Activity',
  },
  delete: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.Delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.Cancel',
    defaultMessage: 'Cancel',
  },
  saveAndClose: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.SaveAndClose',
    defaultMessage: 'Save and Close',
  },
  saveAndAddNew: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.SaveAndAddNew',
    defaultMessage: 'Save and Add New',
  },
  activityDetails: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.ActivityDetails',
    defaultMessage: 'Activity Details',
  },
  viewProfile: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.ViewProfile',
    defaultMessage: 'View Profile',
  },
  activityType: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.ActivityType',
    defaultMessage: 'Activity Type',
  },
  activityDate: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.ActivityDate',
    defaultMessage: 'Activity Date',
  },
  activityTime: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.ActivityTime',
    defaultMessage: 'Activity Time',
  },
  howWasCompleted: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.HowWasCompleted',
    defaultMessage: 'How was this activity completed',
  },
  scheduledBy: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.ScheduledBy',
    defaultMessage: 'Scheduled for',
  },
  completedBy: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.CompletedBy',
    defaultMessage: 'Completed by',
  },
  editNotes: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.EditNotes',
    defaultMessage: 'Edit Notes',
  },
  notesLengthWarning: {
    id: 'App.ManageProspects.EditCompletedActivityModal.NotesLengthWarning',
    defaultMessage: '{current} of {max} max length.',
  },
  deleteConfirmationHeader: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.DeleteConfirmationHeader',
    defaultMessage: 'Are you sure you want to delete?',
  },
  cancelConfirmationHeader: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.CancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to close without saving?',
  },
  deleteConfirmationText: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.DeleteConfirmationText',
    defaultMessage:
      ' This action cannot be undone! To return to the edit view, click the {noText} option below.',
  },
  yes: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.No',
    defaultMessage: 'No',
  },
  note: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.Note',
    defaultMessage: 'Note',
  },
  noteContent: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.NoteContent',
    defaultMessage:
      'The user who saves the activity record will be listed as the “Completed By” user, regardless of who the activity was scheduled for.',
  },
  prospect: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.Prospect',
    defaultMessage: 'Prospect',
  },
  applicant: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.Applicant',
    defaultMessage: 'Applicant',
  },
  recordedActivity: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.RecordedActivity',
    defaultMessage: 'Recorded Activity',
  },
  resident: {
    id: 'App.PeopleProfile.EditCompletedActivityModal.Resident',
    defaultMessage: 'Resident',
  },
  'prior-resident': {
    id: 'App.PeopleProfile.EditCompletedActivityModal.PriorResident',
    defaultMessage: 'Prior Resident',
  },
});

export default messages;
