import type { Action } from '../App/types';
import * as ActionTypes from './constants';

export function getQualificationHistory(applicationId: string): Action<any> {
  return {
    type: ActionTypes.GET_QUALIFICATION_HISTORY,
    payload: applicationId,
  };
}

export function getQualificationHistorySuccess(
  qualificationHistory: Array<Object>,
): Action<any> {
  return {
    type: ActionTypes.GET_QUALIFICATION_HISTORY_SUCCESS,
    payload: qualificationHistory,
  };
}

export function getQualificationHistoryError(error: Object): Action<any> {
  return {
    type: ActionTypes.GET_QUALIFICATION_HISTORY_ERROR,
    payload: error,
  };
}

export function updateHouseholdQualificationHistory(
  qualificationHistoryId: string,
  values: Object,
  applicationId: string,
): Action<any> {
  return {
    type: ActionTypes.UPDATE_HOUSEHOLD_HISTORY,
    payload: {
      applicationId,
      qualificationHistoryId,
      values,
    },
  };
}
