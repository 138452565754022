import { defineMessages } from 'react-intl';

const messages = defineMessages({
  required: {
    id: 'App.VehiclesForm.Required',
    defaultMessage: 'Required Field',
  },
});

export default messages;
