import React from 'react';
import { Field } from 'redux-form';

import {
  renderTextField,
  renderSelectField,
  renderCurrencyField,
  renderFlexNumberField,
} from '../../../../../../utils/redux-form-helper';

import {
  TENANT_LANDLORD_SELECT_OPTIONS,
  TERMINATION_TYPE_OPTIONS,
  DAYS_MONTHS_SELECT_OPTIONS,
} from '../../constants';

import { FlexFormInput } from '../../common';

const TerminationOptionsFormFields = ({ values }: Object) => {
  return (
    <React.Fragment>
      <FlexFormInput>
        <Field
          name="Termination Rights For"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Termination Rights For"
          options={TENANT_LANDLORD_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Start Date Period"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Start Date Period"
          options={DAYS_MONTHS_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Start Date"
          component={renderFlexNumberField}
          label="Start Date"
          max="1000"
          min="0"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Expiration Date Period"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Expiration Date Period"
          options={DAYS_MONTHS_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Expiration Date"
          component={renderFlexNumberField}
          label="Expiration Date"
          max="1000"
          min="0"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Notice Period"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Notice Period"
          options={DAYS_MONTHS_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Notice Date"
          component={renderFlexNumberField}
          label="Notice Date"
          max="1000"
          min="0"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Termination Type"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Termination Type"
          options={TERMINATION_TYPE_OPTIONS}
        />
      </FlexFormInput>
      {values && values['Termination Type'] ? (
        <React.Fragment>
          {values['Termination Type'] === 'Amount' ? (
            <FlexFormInput>
              <Field
                name="Termination Penalty/Fee"
                component={renderCurrencyField}
                label="Termination Penalty/Fee"
                step="0.01"
              />
            </FlexFormInput>
          ) : null}
          {values['Termination Type'] === 'Description' ? (
            <FlexFormInput>
              <Field
                name="Termination Penalty/Fee"
                component={renderTextField}
                label="Termination Penalty/Fee"
                maxLength="255"
                showLabel
              />
            </FlexFormInput>
          ) : null}
        </React.Fragment>
      ) : null}
      <FlexFormInput>
        <Field
          name="Other Requirements"
          component={renderTextField}
          label="Other Requirements"
          maxLength="255"
          showLabel
        />
      </FlexFormInput>
    </React.Fragment>
  );
};

export default TerminationOptionsFormFields;
