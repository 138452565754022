import React, { Component, useState } from 'react';
import { Col } from 'react-bootstrap';
import { FormSection, Field } from 'redux-form';
import { injectIntl } from 'react-intl';
import {
  renderSelectField,
  renderRadioGroupField,
  renderMultiSelectFieldFortress,
} from '../../../utils/redux-form-helper';

// Components
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import {
  Banner,
  IconWithLabel,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import MiniProfileFormPanel from '../../MiniProfileFormPanel';

// Constants
import {
  additionalStudentTypes,
  custodyOptions,
  ethnicityOptions,
  genderOptions,
  studentsTypes,
  raceOptions,
  veteranOptions,
  disabledOptions,
  yesNoBooleanOptions,
  raceSourceOptions,
  DO_NOT_WISH_PROVIDE_OPTION,
} from '../../LeaseAffordableApplicantFormCommonSections/configuration';
import messages from './messages.js';
import { GUARANTOR_LEASE_SIGNER } from '../../../containers/GeneralAffordable/constants';
import { NON_AFFORDABLE_RELATIONSHIP_IDS } from './constants';

// Utils
import { requiredVal } from '../utils';
import { isEmpty, isNil } from 'ramda';

// Hooks
import { useFlags } from 'launchdarkly-react-client-sdk';
import useSelectedProperty from '../../../hooks/useSelectedProperty.js';
import useMultiRaceSelect from '../../../hooks/useMultiRaceSelect.js';

const PANEL_TITLE = 'Affordable Information';

const FIELD_TO_LABEL_MAP = {
  gender: 'Gender',
  veteran: 'US Military Veteran',
  jointCustody: 'Joint Custody',
  'disability.hasDisability': 'Disabled',
  'disability.disabilityMobile': 'Mobile Disability',
  'disability.disabilityHearing': 'Hearing Disability',
  'disability.disabilityVisual': 'Vision Disability',
  'disability.disabilityOther': 'Other Disability',
  race: 'Race',
  ethnicity: 'Ethnicity',
  affordableRelationshipId: 'Affordable Relation',
  studentType: 'Student',
  raceSource: 'Race Source',
  MINCNumber: 'MINC #',
};

type Props = {
  openPanel: ?string,
  togglePanel: Function,
  affordableRelationships: Array<Object>,
  isAdult: boolean,
  bypassedFields?: string[],
  showRaceSourceField: boolean,
  showMINCField: boolean,
  applicant: Object,
  reduxFormOnChange: Function,
  basicInformationMultiRace: string[],
};

type State = {
  editable: boolean,
};

const DEFAULT_OPTION = { value: '', text: 'Choose', disabled: true };
const DISABLE_DEFAULT_OPTION = {
  value: null,
  text: 'Choose',
  disabled: false,
};

const AffordableInformationFormSection = ({
  disabled,
  affordableRelationships,
  isAdult,
  sectionInitialValues,
  showRaceSourceField,
  showMINCField,
  requireRDDemographics,
  reduxFormOnChange,
  basicInformationMultiRace,
}) => {
  const { doNotWishToProvideDisability, raceEnhancements } = useFlags();
  const { state } = useSelectedProperty();
  const [disabilityOption, setDisabilityOption] = useState(true);
  // This sets if RD demographic fields are required
  const [requireRDFields, setRequireRDFields] = useState(requireRDDemographics);

  const genders = [DEFAULT_OPTION].concat(genderOptions);
  const vetOptions = [DEFAULT_OPTION].concat(veteranOptions);
  const jointCustodyOptions = [DEFAULT_OPTION].concat(custodyOptions);
  const races = raceEnhancements ? raceOptions(state?.code) : raceOptions();
  const { showMultiSelect, stateCode, handleItem } = useMultiRaceSelect();

  const ethnicities = [DEFAULT_OPTION].concat(ethnicityOptions);
  const yesNoOptions = [DEFAULT_OPTION].concat(yesNoBooleanOptions);
  const yesNoOptionsFlagEnabled = [DISABLE_DEFAULT_OPTION].concat(
    yesNoBooleanOptions,
  );
  let affordableRelationshipOptions = affordableRelationships.filter((ar) =>
    isAdult ? ar.isAdult : ar.isChild,
  );
  const studentOptions = [
    DEFAULT_OPTION,
    ...studentsTypes,
    ...additionalStudentTypes,
  ];
  const raceSources = [DEFAULT_OPTION].concat(raceSourceOptions);

  const renderDisabledOptions = (event) => {
    if (event.target.value === 'yes') {
      setDisabilityOption(false);
    } else if (event.target.value === 'no') {
      setDisabilityOption(true);
    } else {
      setDisabilityOption(true);
    }
  };

  const isAffordableRelationshipIdBypassed =
    isEmpty(sectionInitialValues.affordableRelationshipId) ||
    isNil(sectionInitialValues.affordableRelationshipId) ||
    sectionInitialValues.affordableRelationshipId === DEFAULT_OPTION.value;

  const rdRequiredLabel = requireRDFields ? '*' : '';

  const headOfHouseholdId = affordableRelationships.find(
    (relationship) => relationship?.text === 'Head of Household',
  );

  affordableRelationshipOptions = affordableRelationships.map((obj) => {
    if (obj.value === headOfHouseholdId?.value) {
      return { ...obj, disabled: true };
    } else {
      return obj;
    }
  });

  const handleMultiRaceSelect = (item) => {
    handleItem(item, (newItem) =>
      reduxFormOnChange('affordableInformation.multiRace', newItem),
    );
  };

  return (
    <FormSection name="affordableInformation">
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              name="gender"
              selectClassNames="input-md form-control"
              component={renderSelectField}
              label={`${FIELD_TO_LABEL_MAP.gender}${rdRequiredLabel}`}
              disabled={disabled}
              options={genders}
              validate={requireRDFields ? requiredVal : undefined}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            {isAdult ? (
              <Field
                name="veteran"
                selectClassNames="input-md form-control"
                component={renderSelectField}
                label={FIELD_TO_LABEL_MAP.veteran}
                disabled={disabled}
                options={vetOptions}
              />
            ) : (
              <Field
                name="jointCustody"
                selectClassNames="input-md form-control"
                component={renderSelectField}
                label={`${FIELD_TO_LABEL_MAP.jointCustody}*`}
                disabled={disabled}
                options={jointCustodyOptions}
              />
            )}
          </div>
        </div>
      </div>

      {doNotWishToProvideDisability && (
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <Col xs={12}>
                <Field
                  name="disability.hasDisability"
                  component={renderRadioGroupField}
                  label={`${FIELD_TO_LABEL_MAP['disability.hasDisability']}*`}
                  options={disabledOptions}
                  onClick={(e) => renderDisabledOptions(e)}
                  disabled={disabled}
                  bsSize="lg"
                  style={{ margin: '15px' }}
                  inline
                />
              </Col>
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              name="disability.disabilityMobile"
              selectClassNames="input-md form-control"
              component={renderSelectField}
              label={FIELD_TO_LABEL_MAP['disability.disabilityMobile']}
              disabled={
                doNotWishToProvideDisability
                  ? disabled || disabilityOption
                  : disabled
              }
              options={
                !doNotWishToProvideDisability
                  ? yesNoOptions
                  : yesNoOptionsFlagEnabled
              }
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              name="disability.disabilityHearing"
              selectClassNames="input-md form-control"
              component={renderSelectField}
              label={FIELD_TO_LABEL_MAP['disability.disabilityHearing']}
              disabled={
                doNotWishToProvideDisability
                  ? disabled || disabilityOption
                  : disabled
              }
              options={
                !doNotWishToProvideDisability
                  ? yesNoOptions
                  : yesNoOptionsFlagEnabled
              }
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              name="disability.disabilityVisual"
              selectClassNames="input-md form-control"
              component={renderSelectField}
              label={FIELD_TO_LABEL_MAP['disability.disabilityVisual']}
              disabled={
                doNotWishToProvideDisability
                  ? disabled || disabilityOption
                  : disabled
              }
              options={
                !doNotWishToProvideDisability
                  ? yesNoOptions
                  : yesNoOptionsFlagEnabled
              }
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              name="disability.disabilityOther"
              selectClassNames="input-md form-control"
              component={renderSelectField}
              label={FIELD_TO_LABEL_MAP['disability.disabilityOther']}
              disabled={
                doNotWishToProvideDisability
                  ? disabled || disabilityOption
                  : disabled
              }
              options={
                !doNotWishToProvideDisability
                  ? yesNoOptions
                  : yesNoOptionsFlagEnabled
              }
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            {showMultiSelect ? (
              <Field
                name="multiRace"
                fieldName="multiRace"
                placeholder={'Select Races'}
                label={`${FIELD_TO_LABEL_MAP.race}${rdRequiredLabel}`}
                options={raceOptions(stateCode, { withDefault: false })}
                component={renderMultiSelectFieldFortress}
                bsSize="lg"
                disabled={disabled}
                controlledValue={basicInformationMultiRace || []}
                excludeFromSelectAll={[DO_NOT_WISH_PROVIDE_OPTION.value]}
                onChangeCallback={handleMultiRaceSelect}
              />
            ) : (
              <Field
                name="race"
                selectClassNames="input-md form-control"
                component={renderSelectField}
                label={`${FIELD_TO_LABEL_MAP.race}${rdRequiredLabel}`}
                disabled={disabled}
                options={races}
                validate={requireRDFields ? requiredVal : undefined}
              />
            )}
          </div>
        </div>
        {showRaceSourceField && (
          <div className="col-sm-6">
            <div className="form-group">
              <Field
                name="raceSource"
                selectClassNames="input-md form-control"
                component={renderSelectField}
                label={`${FIELD_TO_LABEL_MAP.raceSource}${rdRequiredLabel}`}
                disabled={disabled}
                options={raceSources}
                validate={requireRDFields ? requiredVal : undefined}
              />
            </div>
          </div>
        )}
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              name="ethnicity"
              selectClassNames="input-md form-control"
              component={renderSelectField}
              label={`${FIELD_TO_LABEL_MAP.ethnicity}${rdRequiredLabel}`}
              disabled={disabled}
              options={ethnicities}
              validate={requireRDFields ? requiredVal : undefined}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              name="affordableRelationshipId"
              selectClassNames="input-md form-control"
              component={renderSelectField}
              label={`${FIELD_TO_LABEL_MAP.affordableRelationshipId}*`}
              disabled={true}
              options={affordableRelationshipOptions}
              warn={
                isAffordableRelationshipIdBypassed ? requiredVal : undefined
              }
              validate={
                isAffordableRelationshipIdBypassed ? undefined : requiredVal
              }
              onChange={(_, newValue) => {
                if (showRaceSourceField) {
                  // if new value is affordable member then require fields
                  // And race source is shown (RD)
                  setRequireRDFields(
                    !NON_AFFORDABLE_RELATIONSHIP_IDS.includes(newValue),
                  );
                }
              }}
            />
          </div>
        </div>
        {!isAdult && (
          <div className="col-sm-6">
            <div className="form-group">
              <Field
                name="studentType"
                selectClassNames="input-md form-control"
                component={renderSelectField}
                label={FIELD_TO_LABEL_MAP.studentType}
                disabled={disabled}
                options={studentOptions}
              />
            </div>
          </div>
        )}
      </div>
    </FormSection>
  );
};

export class AffordableInformation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editable: false,
    };
  }

  toggleEdit = () => {
    this.setState({ editable: !this.state.editable });
  };

  render() {
    const {
      openPanel,
      togglePanel,
      affordableRelationships,
      isAdult,
      bypassedFields = [],
      sectionInitialValues,
      applicant,
      showRaceSourceField = false,
      showMINCField = false,
      intl,
      reduxFormOnChange,
      basicInformationMultiRace,
    } = this.props;
    const mappedBypassedFields = bypassedFields.map(
      (bf) => FIELD_TO_LABEL_MAP[bf],
    );

    // RD will make demographics required for affordable applicants
    const isAffordableApplicant =
      applicant?.applicantType?.name !== GUARANTOR_LEASE_SIGNER &&
      !NON_AFFORDABLE_RELATIONSHIP_IDS.includes(
        sectionInitialValues?.affordableRelationshipId,
      );
    const requireRDDemographics = showRaceSourceField && isAffordableApplicant;

    return (
      <MiniProfileFormPanel
        open={openPanel === PANEL_TITLE}
        toggleOpen={togglePanel(PANEL_TITLE)}
        title={PANEL_TITLE}
        bypassedFields={mappedBypassedFields}
      >
        <div className="container-fluid padleft5 padright5 padtop15 padbottom15">
          <div className="row">
            <div className="col-md-12">
              <a
                onClick={() => this.toggleEdit()}
                className="small simple-link pull-right"
              >
                <i className="icon et-pencil" />
                <span>Edit </span>
              </a>
            </div>
          </div>
          <AffordableInformationFormSection
            disabled={!this.state.editable}
            affordableRelationships={affordableRelationships}
            isAdult={isAdult}
            sectionInitialValues={sectionInitialValues}
            showRaceSourceField={showRaceSourceField}
            requireRDDemographics={requireRDDemographics}
            showMINCField={showMINCField}
            reduxFormOnChange={reduxFormOnChange}
            basicInformationMultiRace={basicInformationMultiRace}
          />
          <>
            <Banner
              text={
                <IconWithLabel
                  Icon={AlertInfoIcon}
                  label={intl.formatMessage(
                    messages.affordableRelationshipWarning,
                  )}
                />
              }
              color={'blue'}
              BoxProps={{
                sx: {
                  width: '100%',
                  padding: '8px',
                  textAlign: 'left',
                  border: 'none',
                },
              }}
              TypographyProps={{
                noWrap: false,
                sx: {
                  padding: '0',
                },
              }}
            />
          </>
        </div>
      </MiniProfileFormPanel>
    );
  }
}

export default injectIntl(AffordableInformation);
