import React from 'react';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import { pathOr } from 'ramda';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import { useFetchSubsidyPaymentBatches } from './hooks';
import { formatDate, formatMoney } from '../../../utils/formatter';
import Spinner from '../../../components/Spinner';

type Props = {
  organizationId: string,
  propertyId: string,
  redirectToCreateSubsidyBatch: Function,
};

const renderSubsidyPaymentBatchesRows = (
  subsidyPaymentBatches,
  redirectToCreateSubsidyBatch,
) => {
  return subsidyPaymentBatches && subsidyPaymentBatches.length > 0 ? (
    subsidyPaymentBatches.map((subsidyPayment, i) => {
      const period = pathOr(
        '',
        ['propertyFiscalPeriod', 'period'],
        subsidyPayment,
      );
      const formattedPeriod = period
        ? `${period.substr(4)}/${period.substr(0, 4)}`
        : '';
      const isPosted = pathOr(false, ['isPosted'], subsidyPayment);
      const isPosting = pathOr(false, ['isPosting'], subsidyPayment);
      const isDeposited =
        pathOr('', ['depositStatus'], subsidyPayment) === 'Deposited';
      const status = isDeposited
        ? 'Deposited'
        : isPosting || isPosted
        ? 'Pending'
        : 'Open';
      const date = pathOr('', ['batchDate'], subsidyPayment);
      const amount = pathOr('', ['batchTotal'], subsidyPayment);
      const documentNumber = pathOr('', ['documentNumber'], subsidyPayment);
      const note = pathOr('', ['batchNote'], subsidyPayment);
      return (
        <tr key={i}>
          <td>{status}</td>
          <td>{formatDate(date)}</td>
          <td>{isPosted ? formattedPeriod : 'Unposted'}</td>
          <td>{formatMoney(amount)}</td>
          <td>{documentNumber}</td>
          <td>{note}</td>
          <td>
            <ElementWithPermissions scope={['subsidy-payment-create-edit']}>
              <button
                className="btn btn-sm btn-default"
                onClick={() => {
                  redirectToCreateSubsidyBatch(subsidyPayment.id);
                }}
              >
                <i className="et-pencil" />
              </button>
            </ElementWithPermissions>
          </td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan={7}>
        <div className="text-center">
          <FormattedMessage {...messages.noDataDescription} />
        </div>
      </td>
    </tr>
  );
};
export const ManagesubsidyPaymentsDetails = (props: Props) => {
  const { organizationId, propertyId, redirectToCreateSubsidyBatch } = props;
  const { subsidyPaymentBatches, isLoading } = useFetchSubsidyPaymentBatches({
    organizationId,
    propertyId,
  });

  return (
    <div className="container-fluid" data-test="payment-details">
      <div className="table-scroll table-deposits-container">
        <table className="table table-prospects table-striped">
          <thead className="table-header">
            <tr>
              <th>
                <FormattedMessage {...messages.status} />
              </th>
              <th>
                <FormattedMessage {...messages.date} />
              </th>
              <th>
                <FormattedMessage {...messages.period} />
              </th>
              <th>
                <FormattedMessage {...messages.amount} />
              </th>
              <th>
                <FormattedMessage {...messages.doc} />
              </th>
              <th>
                <FormattedMessage {...messages.note} />
              </th>
              <th>
                <FormattedMessage {...messages.view} />
              </th>
            </tr>
          </thead>
          <tbody>
            {!isLoading && subsidyPaymentBatches ? (
              renderSubsidyPaymentBatchesRows(
                subsidyPaymentBatches,
                redirectToCreateSubsidyBatch,
              )
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="text-center">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="padtop30">
        <strong>
          <FormattedMessage {...messages.notes} />
        </strong>
        <div className="padtop5">
          <FormattedMessage {...messages.notes1} />
        </div>
        <div>
          <FormattedMessage {...messages.notes2} />
        </div>
      </div>
    </div>
  );
};

export default ManagesubsidyPaymentsDetails;
