import confirm from '../../components/ConfirmDialogModal';
import messages from './messages';

export function onPetTypeChange(change: Function, field: string) {
  change(`otherInformation.${field}.breedId`, 'default');
}

export const onExistentialChange = (
  resetSection: Function,
  change: Function,
  items: Array<Object>,
  itemType: string,
  intl: Object
) => {
  const isMinors = itemType === 'minors';
  const minorDefaultValues = { suffixId: 'default', relationship: 'default' };
  const petDefaultValues = { petTypeId: 'default', breedId: 'default' };
  const defaultValues = isMinors ? minorDefaultValues : petDefaultValues;
  const sectionToChange = isMinors
    ? 'additionalOccupants.minors.minorsInformation'
    : 'otherInformation.petsInformation';
  const radioToRevertOnCancel = isMinors
    ? 'additionalOccupants.minors.anyMinor'
    : 'otherInformation.pets';
  const confirmationMessage = isMinors
    ? messages.confirmDeleteMinors
    : messages.confirmDeletePets;

  return ({ target: { value } }: Object) => {
    const cancelFunc = () => {
      resetSection(radioToRevertOnCancel);
    };

    const existingItems =
      items.length > 0 ? items.filter((item) => !!item.id) : [];
    if (value === 'no') {
      if (existingItems.length > 0) {
        confirm(intl.formatMessage(confirmationMessage), {
          intl,
          cancelFunc
        })
          .then(() => {
            const itemsToDelete = items.map(({ id, applicantId }) => {
              const withId = id ? { ...defaultValues, id } : defaultValues;
              const finalObject = applicantId
                ? { ...withId, applicantId }
                : withId;
              return finalObject;
            });
            change(sectionToChange, itemsToDelete);
          })
          .catch(() => {});
      } else {
        resetSection(sectionToChange);
      }
    } else if (value === 'yes') {
      resetSection(radioToRevertOnCancel);
    }
  };
};

export const resetField = (change: Function, fieldName: string) => {
  return ({ target: { value } }: Object) => {
    if (value === 'no') {
      change(fieldName, null);
    }
  };
};
