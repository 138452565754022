import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import messages from './messages';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { useAsyncCommercialScheduledRents } from './hooks';
import { prop, isEmpty, sortWith, descend, ascend } from 'ramda';
import moment from 'moment';
import ElementWithPermissions from '../../../../components/ElementWithPermissions';
import RentModal from './RentModal';
import EditRentModal from './EditRentModal';
import { formatCurrency } from '../../../../utils';

type Props = {
  propertyId: string,
  organizationId: string,
  leaseId: string,
  lease: Object,
  unit: Object,
  intl: Object,
  canComplete: boolean,
  isResident: boolean,
  isRenewal: boolean,
};

const AddRentBtn = styled.a`
  margin-left: 10px;
`;

const ScrollTable = styled.div`
  overflow-x: scroll;
`;

const rentDisabledTooltip = (
  <Tooltip key={'tooltip-rent-disabled'} id={'tooltip-rent-disabled'}>
    <FormattedMessage {...messages.rentDisabledTooltip} />
  </Tooltip>
);

function Rent({
  propertyId,
  organizationId,
  leaseId,
  unit,
  lease,
  canComplete,
  isResident,
  isRenewal,
  intl,
}: Props) {
  const [isAddModalShown, setIsAddModalShown] = useState(false);
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const [scheduledRents, isLoading, refreshRents] =
    useAsyncCommercialScheduledRents(propertyId, organizationId, leaseId, unit);

  const [renderedScheduledRents, setRenderedScheduledRents] = useState([]);
  const [toggleShowMore, setToggleShowMore] = useState(false);

  const getCurrentEffectiveRentStep = (scheduledRents: Object[]) => {
    const sortedRents = sortWith([descend(prop('startDate'))], scheduledRents);
    const today = moment();

    return sortedRents.find(
      (sr) =>
        !sr.isHoldover && moment(sr.startDate).isSameOrBefore(today, 'day'),
    );
  };

  const getFirstRentStep = (scheduledRents: Object[]) => {
    return sortWith([ascend(prop('startDate'))], scheduledRents)[0];
  };

  useEffect(() => {
    if (!scheduledRents.length) return;

    if (toggleShowMore) setRenderedScheduledRents(scheduledRents);
    else {
      const currentEffectiveRentStep =
        getCurrentEffectiveRentStep(scheduledRents);
      const rentStepToDisplay =
        (isResident && !isRenewal) || (isRenewal && currentEffectiveRentStep)
          ? currentEffectiveRentStep
          : getFirstRentStep(scheduledRents);
      setRenderedScheduledRents(rentStepToDisplay ? [rentStepToDisplay] : []);
    }
  }, [toggleShowMore, scheduledRents, isResident, isRenewal]);

  const endLeaseDate = prop('endDate', lease);
  const isScheduledRentsEmpty = isEmpty(scheduledRents);

  const handleAddRentClick = () => {
    setIsAddModalShown(true);
  };

  const handleEditBtnClick = () => {
    setIsEditModalShown(true);
  };

  const handleAddModalHide = () => {
    setIsAddModalShown(false);
  };

  const handleEditModalHide = () => {
    setIsEditModalShown(false);
  };

  const handleToggleShowMoreOrLess = () => {
    setToggleShowMore(!toggleShowMore);
  };

  return (
    <React.Fragment>
      <RentModal
        show={isAddModalShown}
        onHide={handleAddModalHide}
        lease={lease}
        propertyId={propertyId}
        organizationId={organizationId}
        refreshRents={refreshRents}
      />
      <EditRentModal
        show={isEditModalShown}
        onHide={handleEditModalHide}
        lease={lease}
        propertyId={propertyId}
        organizationId={organizationId}
        refreshRents={refreshRents}
        scheduledRents={scheduledRents}
      />
      <Row className="padtop10 padbottom10">
        <Col xs={6}>
          <h2>
            <i className="et-money text-blue padright10" />
            <FormattedMessage {...messages.rent} className="padright10" />
            {(!canComplete || !leaseId) && (
              <OverlayTrigger
                key={'tooltip-overlay'}
                placement="bottom"
                delayHide={50}
                trigger={['hover', 'focus']}
                overlay={rentDisabledTooltip}
              >
                <i className="icon et-alert-info padleft10" />
              </OverlayTrigger>
            )}
            {!isScheduledRentsEmpty && (
              <a onClick={handleEditBtnClick}>
                <i className="et-pencil text-blue padleft10" />
              </a>
            )}
          </h2>
        </Col>
        {scheduledRents.length > 1 && (
          <Col xs={5}>
            <a className="float-right" onClick={handleToggleShowMoreOrLess}>
              <strong>
                {!toggleShowMore ? (
                  <FormattedMessage {...messages.showMore} />
                ) : (
                  <FormattedMessage {...messages.showLess} />
                )}
              </strong>
            </a>
          </Col>
        )}
      </Row>
      <Row className="row">
        <Col xs={12}>
          {isScheduledRentsEmpty ? (
            !isLoading && (
              <ElementWithPermissions scope={['lease-create']}>
                <AddRentBtn
                  disabled={!canComplete || !leaseId}
                  onClick={handleAddRentClick}
                >
                  + <FormattedMessage {...messages.addRent} />
                </AddRentBtn>
              </ElementWithPermissions>
            )
          ) : (
            <ScrollTable>
              <table className="table table-simple">
                <tbody>
                  <tr>
                    <th>
                      <FormattedMessage {...messages.startDate} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.endDate} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.months} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.monthlyAmount} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.psfPerMonth} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.psfPerYear} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.totalRent} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.notes} />
                    </th>
                  </tr>
                  {renderedScheduledRents.map((rent, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <FormattedDate value={rent.startDate} />
                        </td>
                        <td>
                          {rent.endDate ? (
                            <FormattedDate value={rent.endDate} />
                          ) : rent.months !== -1 && endLeaseDate ? (
                            <FormattedDate value={moment(endLeaseDate)} />
                          ) : (
                            '---'
                          )}
                        </td>
                        <td>
                          {rent.months === -1
                            ? 'Holdover'
                            : rent.months + ' Months'}
                        </td>
                        <td>{formatCurrency(intl, rent.monthlyAmount)}</td>
                        <td>{formatCurrency(intl, rent.psfPerMonth)}</td>
                        <td>{formatCurrency(intl, rent.psfPerYear)}</td>
                        <td>{formatCurrency(intl, rent.totalRent)}</td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`rentNotesTooltip-${i}`}>
                                {rent.notes}
                              </Tooltip>
                            }
                          >
                            <Button
                              bsStyle="default"
                              bsSize="small"
                              className="btn-comments"
                              disabled={!rent.notes || rent.notes.length === 0}
                            >
                              <i className="icon et-comment-words" />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </ScrollTable>
          )}
        </Col>
      </Row>
      <Row>
        <div className="col-xs-12 padtop10">
          <Col xs={12}>
            <strong>
              <FormattedMessage {...messages.note} />{' '}
            </strong>
            <FormattedMessage {...messages.noteDescription} />
          </Col>
        </div>
      </Row>
    </React.Fragment>
  );
}

export default Rent;
