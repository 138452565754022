import { push } from 'react-router-redux';
import { put, takeLatest, call, select, throttle } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { find, propEq, pathOr, defaultTo } from 'ramda';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';

import ApplicationService from '../../services/applicationService';
import * as ActionTypes from './constants';
import * as createCommercialTenantActions from './actions';
import messages from './messages';

import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import type { Saga } from 'redux-saga';
import type { Action, Property } from '../App/types';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetSignedLeaseStatus(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicantInformation = {
      applicationId: action.payload,
      propertyId: selectedProperty.id,
    };
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplicationSignedLeaseStatus,
      applicantInformation,
    );
    yield put(
      createCommercialTenantActions.getApplicationSignedLeaseStatusSucess(
        response,
      ),
    );
  } catch (err) {
    yield put(createCommercialTenantActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetLeaseStatus(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicantInformation = {
      applicationId: action.payload,
      propertyId: selectedProperty.id,
    };
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplicationLeaseStatus,
      applicantInformation,
    );
    yield put(
      createCommercialTenantActions.getApplicationLeaseStatusSucess(response),
    );
  } catch (err) {
    yield put(createCommercialTenantActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetContactStatus(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);

    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getContactStatus,
      selectedProperty.id,
      action.payload,
    );
    yield put(createCommercialTenantActions.getContactStatusSuccess(response));
  } catch (err) {
    yield put(createCommercialTenantActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetInsuranceStatus(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);

    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getInsuranceStatus,
      selectedProperty.id,
      action.payload,
    );
    yield put(
      createCommercialTenantActions.getInsuranceStatusSuccess(response),
    );
  } catch (err) {
    yield put(createCommercialTenantActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetLeaseStartDateStatus(
  action: Action<string>,
): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicantInformation = {
      applicationId: action.payload,
      propertyId: selectedProperty.id,
    };
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplicationLeaseStartDateStatus,
      applicantInformation,
    );
    yield put(
      createCommercialTenantActions.getApplicationLeaseStartDateStatusSucess(
        response,
      ),
    );
  } catch (err) {
    yield put(createCommercialTenantActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetUnitAvailabilityStatus(
  action: Action<string>,
): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicantInformation = {
      applicationId: action.payload,
      propertyId: selectedProperty.id,
    };
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getUnitAvailabilityStatus,
      applicantInformation,
    );
    yield put(
      createCommercialTenantActions.getUnitAvailabilityStatusSuccess(response),
    );
  } catch (err) {
    yield put(createCommercialTenantActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetOneApplication(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplication,
      organizationId,
      selectedProperty.id,
      action.payload,
    );
    yield put(createCommercialTenantActions.getOneApplicationSuccess(response));
  } catch (err) {
    yield put(createCommercialTenantActions.getApplicantInformationError(err));
  }
}

export function* putCommercialMoveIn(action: Action<any>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicationService = new ApplicationService();
    const residents = yield applicationService.commercialMoveIn(
      organizationId,
      selectedProperty.id,
      action.payload.applicationId,
      action.payload.actualMoveInDate,
      action.payload.proratedTransactions,
    );

    const primary = defaultTo({}, find(propEq('isPrimary', true), residents));
    const residentId = pathOr('', ['residentId'])(primary);
    yield put(createCommercialTenantActions.commercialMoveInSuccess());
    yield put(push(getUrlWithSelectedPropertyId(`/resident/${residentId}`)));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(createCommercialTenantActions.commercialMoveInError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getApplicationSignedLeaseStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_SIGNED_LEASE_STATUS,
    fetchGetSignedLeaseStatus,
  );
}

export function* getApplicationLeaseStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_LEASE_STATUS,
    fetchGetLeaseStatus,
  );
}

export function* getContactStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_CONTACT_STATUS,
    fetchGetContactStatus,
  );
}

export function* getInsuranceStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_INSURANCE_STATUS,
    fetchGetInsuranceStatus,
  );
}

export function* getApplicationLeaseStartDateStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_LEASE_START_DATE_STATUS,
    fetchGetLeaseStartDateStatus,
  );
}

export function* getOneApplicationSaga(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ONE_APPLICATION, fetchGetOneApplication);
}
export function* commercialMoveIn(): Saga<void> {
  yield throttle(500, ActionTypes.COMMERCIAL_MOVE_IN, putCommercialMoveIn);
}
export function* getUnitAvailabilityStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_UNIT_AVAILABILITY_STATUS,
    fetchGetUnitAvailabilityStatus,
  );
}
export default [
  getApplicationSignedLeaseStatus,
  getApplicationLeaseStatus,
  getOneApplicationSaga,
  getApplicationLeaseStartDateStatus,
  commercialMoveIn,
  getUnitAvailabilityStatus,
  getContactStatus,
  getInsuranceStatus,
];
