// $FlowFixMe
import React, { useState } from 'react';
import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, FieldArray, change } from 'redux-form';
import {
  always,
  cond,
  equals,
  findIndex,
  ifElse,
  or,
  pathOr,
  propEq,
} from 'ramda';
import styled from 'styled-components';
import { renderSelectField } from '../../utils/redux-form-helper';
import messages from './messages';
import deleteConfirmation from '../DeleteConfirmation';
import confirm from '../ConfirmDialogModal';
import ErrorMessage from '../ErrorMessage';
import validate from './validate';
import AdultList from './AdultList';
import MinorList from './MinorList';
import PetList from './PetList';
import FeeList from './FeeList';
import SubmitButton from '../SubmitButton';

export const formModes = {
  PROSPECT_CONVERT: 'PROSPECT_CONVERT',
  PROSPECT_HOUSEHOLD: 'PROSPECT_HOUSEHOLD',
  APPLICATION_HOUSEHOLD: 'APPLICATION_HOUSEHOLD',
};

export type FormMode = $Keys<typeof formModes>;

const RowWarning = styled(Row)`
  .highlight-details {
    padding: 10px;
  }
`;

type Option = {
  value: string,
  text: string,
  disabled: boolean,
};

type affordableRelationshipOption = Option & {
  isAdult: boolean,
  isChild: boolean,
};

type Props = {
  mode: FormMode,
  handleSubmit: Function,
  handleCancel: Function,
  handleEditHouseholdMember: Function,
  initialValues: Object,
  array: Object,
  change: Function,
  dispatch: any,
  primaryApplicantId: string,
  relationshipList: Array<Option>,
  minorRelationshipList: Array<Option>,
  affordableRelationship: Array<affordableRelationshipOption>,
  isAffordable: boolean,
  applicantTypeList: Array<Object>,
  propertyClassList: Array<Object>,
  petTypeList: Array<Option>,
  applicationFees: Array<Object>,
  suffixList: Array<Option>,
  prospectName: string,
  intl: Object,
  propertyClassVisible: boolean,
  pageTitle: string,
  pristine: boolean,
  submitting: boolean,
  error: string,
  isHouseholdSubmitting: boolean,
  leaseExecuted: boolean,
  leaseSentToPortal: boolean,
  guarantorOptions: Object,
  liveInCaretakerOptions: Object,
};

const findInitialPrimary = (initialValues: Object) => {
  const adults = pathOr([], ['adults'], initialValues);
  const primaryIndex = findIndex(propEq('isProspect', true))(adults);
  return `adults[${primaryIndex}]`;
};

export const EditHouseholdForm = (props: Props) => {
  const [prevPrimaryId, setPrevPrimaryId] = useState('');
  const [prevPrimaryIndex, setPrevPrimaryIndex] = useState(
    findInitialPrimary(props.initialValues),
  );

  const onAddNewAdult = () => {
    props.array.push('adults', {
      type: 'default',
      suffixId: 'default',
      relationship: 'default',
      affordableRelationship: 'default',
    });
  };

  const onDeleteAdult = (index: number) => {
    deleteConfirmation(props.intl.formatMessage(messages.deleteConfirmation), {
      intl: props.intl,
    })
      .then(() => props.array.remove('adults', index))
      .catch(() => {});
  };

  const onAddNewMinor = () => {
    props.array.push('minors', {
      suffixId: 'default',
      relationship: 'default',
      affordableRelationship: 'default',
    });
  };

  const onDeleteMinor = (index: number) => {
    deleteConfirmation(props.intl.formatMessage(messages.deleteConfirmation), {
      intl: props.intl,
    })
      .then(() => props.array.remove('minors', index))
      .catch(() => {});
  };

  const onPrimaryChange = (
    newPrimaryApplicantId: string,
    field: string,
    hohAffRelId: string,
  ) => {
    const applicantMessage = {
      title: 'Are you sure you want to change the Primary Applicant?',
      body: 'All data trails and financial transactions will be updated to the new primary applicant',
    };
    const prospectMessage = {
      title: 'Are you sure you want to change the Primary Prospect?',
      body: 'All data trails will be updated to the new primary prospect',
    };
    const message =
      props.mode === formModes.PROSPECT_HOUSEHOLD
        ? prospectMessage
        : applicantMessage;
    confirm(message.title, {
      body: message.body,
    }).then(() => {
      setPrevPrimaryId(props.primaryApplicantId);
      props.dispatch(
        change('editHousehold', 'HOHChange', {
          previousHOHId: props.primaryApplicantId,
          newHOHId: newPrimaryApplicantId,
        }),
      );
      props.dispatch(
        change('editHousehold', 'primaryApplicantId', newPrimaryApplicantId),
      );
      props.dispatch(
        change('editHousehold', `${field}.relationship`, 'default'),
      );
      if (props.isAffordable) {
        const head = props.affordableRelationship.find((af) =>
          af.text.includes('Head'),
        );
        props.dispatch(
          change(
            'editHousehold',
            `${field}.affordableRelationship`,
            // $FlowFixMe
            head.value,
          ),
        );

        props.dispatch(
          change(
            'editHousehold',
            `${prevPrimaryIndex}.affordableRelationship`,
            'default',
          ),
        );
      }
      setPrevPrimaryIndex(field);
    });
  };

  const onAddNewPet = () => {
    props.array.push('pets', {
      petTypeId: 'default',
      breedId: 'default',
    });
  };

  const onDeletePet = (index: number) => {
    deleteConfirmation(props.intl.formatMessage(messages.deleteConfirmation), {
      intl: props.intl,
    })
      .then(() => props.array.remove('pets', index))
      .catch(() => {});
  };

  const onPetTypeChange = (field: string) => {
    props.change(`${field}.breedId`, 'default');
  };

  const onCancel = () => {
    confirm(props.intl.formatMessage(messages.cancelConfirmation), {
      intl: props.intl,
    })
      .then(() => props.handleCancel())
      .catch(() => {});
  };

  const changeFormValue = (field: string, value: string) => {
    props.dispatch(change('editHousehold', field, value));
  };

  const { initialValues, leaseExecuted, leaseSentToPortal } = props;
  const maxMinorAndPetOccupants = 9;
  const maxAdultOccupants = 11;
  const isConvertToApplicant = always(
    equals(formModes.PROSPECT_CONVERT, props.mode),
  );
  const isEditApplicationHousehold = always(
    equals(formModes.APPLICATION_HOUSEHOLD, props.mode),
  );
  const fieldsRequired = or(
    isConvertToApplicant(),
    isEditApplicationHousehold(),
  );
  const fieldsReadOnly = isEditApplicationHousehold();
  const submitMessage = ifElse(
    isConvertToApplicant,
    always(messages.convertToApplicant),
    always(messages.submit),
  )();
  const instructions = cond([
    [
      isConvertToApplicant,
      always(<FormattedMessage {...messages.convertInstructions} />),
    ],
    [
      isEditApplicationHousehold,
      always(
        <FormattedMessage
          {...messages.applicationEditInstructions}
          values={{
            editIcon: <i className="icon et-pencil text-blue" />,
          }}
        />,
      ),
    ],
  ])();
  const backbuttonValue = equals(formModes.APPLICATION_HOUSEHOLD, props.mode)
    ? messages.goBackApplicant
    : messages.goBack;
  const leaseLocked = leaseExecuted || leaseSentToPortal;
  const adultAffordableRelationships = (
    props.affordableRelationship || []
  ).filter((ar) => ar.isAdult);
  const childAffordableRelationships = (
    props.affordableRelationship || []
  ).filter((ar) => ar.isChild);
  return (
    <form className="form-container container-fluid">
      <Grid fluid>
        <div className="applicant-convert-header">
          <Row>
            <Col xs={6} sm={12}>
              <a className="btn-text" onClick={onCancel}>
                <i className="et-chevron-left" />
                <FormattedMessage {...backbuttonValue} />
              </a>
            </Col>
          </Row>
          {leaseExecuted && (
            <RowWarning>
              <div className="highlight-details">
                <FormattedMessage {...messages.leaseLockedExecuted} />
              </div>
            </RowWarning>
          )}
          {leaseSentToPortal && (
            <RowWarning>
              <div className="highlight-details">
                <FormattedMessage {...messages.leaseLockedSentToPortal} />
              </div>
            </RowWarning>
          )}
          <Row>
            <Col xs={6} sm={7}>
              <h1>{props.pageTitle}</h1>
              <p>{instructions}</p>
              <p>
                <FormattedMessage {...messages.requiredLabel} />
              </p>
            </Col>
            <Col xs={6} sm={5} className="text-right">
              {props.propertyClassVisible && (
                <div className="unittype-menu">
                  <Field
                    name="unitTypeId"
                    component={renderSelectField}
                    options={props.propertyClassList}
                    label={<FormattedMessage {...messages.unitType} />}
                  />
                </div>
              )}
            </Col>
          </Row>
          {props.error && (
            <Row>
              <ErrorMessage message={props.error} />
            </Row>
          )}
        </div>
        <div className="applicant-convert-form">
          <FieldArray
            name="adults"
            component={AdultList}
            mode={props.mode}
            isConvertToApplicant={isConvertToApplicant()}
            onAddNew={onAddNewAdult}
            onDelete={onDeleteAdult}
            onEdit={props.handleEditHouseholdMember}
            showEditButton={isEditApplicationHousehold()}
            suffixList={props.suffixList}
            relationshipList={props.relationshipList}
            affordableRelationship={adultAffordableRelationships}
            applicantTypeList={props.applicantTypeList}
            maxOccupants={maxAdultOccupants}
            fieldsRequired={fieldsRequired}
            fieldsReadOnly={fieldsReadOnly}
            isAffordable={props.isAffordable}
            onPrimaryChange={onPrimaryChange}
            primaryApplicantId={props.primaryApplicantId}
            leaseLocked={leaseLocked}
            prevPrimaryId={prevPrimaryId}
            initialValues={initialValues}
            guarantorOptions={props.guarantorOptions}
            liveInCaretakerOptions={props.liveInCaretakerOptions}
            change={changeFormValue}
          />
          <FieldArray
            name="minors"
            component={MinorList}
            onAddNew={onAddNewMinor}
            onDelete={onDeleteMinor}
            onEdit={props.handleEditHouseholdMember}
            showEditButton={isEditApplicationHousehold()}
            suffixList={props.suffixList}
            minorRelationshipList={props.minorRelationshipList}
            affordableRelationship={childAffordableRelationships}
            applicantTypeList={props.applicantTypeList}
            maxOccupants={maxMinorAndPetOccupants}
            fieldsRequired={fieldsRequired}
            fieldsReadOnly={fieldsReadOnly}
            isAffordable={props.isAffordable}
            leaseLocked={leaseLocked}
          />
          <FieldArray
            name="pets"
            component={PetList}
            onAddNew={onAddNewPet}
            onDelete={onDeletePet}
            onEdit={props.handleEditHouseholdMember}
            showEditButton={isEditApplicationHousehold()}
            petTypeList={props.petTypeList}
            onPetTypeChange={onPetTypeChange}
            maxOccupants={maxMinorAndPetOccupants}
            intl={props.intl}
            fieldsRequired={fieldsRequired}
            fieldsReadOnly={fieldsReadOnly}
            leaseLocked={leaseLocked}
          />
          {isConvertToApplicant() && (
            <FieldArray
              name="fees"
              component={FeeList}
              propertyTransactionCodes={props.applicationFees}
              change={props.change}
              intl={props.intl}
              applicationFees={props.applicationFees}
            />
          )}
        </div>

        <Col xs={6}>
          <Button bsStyle="default" className="pull-right" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col xs={6}>
          <SubmitButton
            bsStyle="primary"
            disabled={props.isHouseholdSubmitting || props.pristine}
            className="btn btn-primary pull-left"
            clickHandler={props.handleSubmit(validate(props))}
            isSubmitting={props.isHouseholdSubmitting}
          >
            <FormattedMessage {...submitMessage} />
          </SubmitButton>
        </Col>
      </Grid>
    </form>
  );
};

const WithProps =
  (
    Component: any,
    additionalProps: { mode: FormMode, propertyClassVisible?: boolean },
  ) =>
  (props) =>
    <Component {...props} {...additionalProps} />;

export const CreateApplicationForm = reduxForm({
  form: 'editHousehold',
})(
  injectIntl(
    WithProps(EditHouseholdForm, { mode: formModes.PROSPECT_CONVERT }),
  ),
);

const EditProspectHousehold = reduxForm({
  form: 'editHousehold',
})(
  injectIntl(
    WithProps(EditHouseholdForm, {
      mode: formModes.PROSPECT_HOUSEHOLD,
      propertyClassVisible: false,
    }),
  ),
);

const EditApplicationHousehold = reduxForm({
  form: 'editHousehold',
})(
  injectIntl(
    WithProps(EditHouseholdForm, {
      mode: formModes.APPLICATION_HOUSEHOLD,
      propertyClassVisible: false,
    }),
  ),
);

const selector = formValueSelector('editHousehold');
// $FlowFixMe
export const EditApplicationHouseholdForm = connect((state) => ({
  primaryApplicantId: selector(state, 'primaryApplicantId'),
}))(EditApplicationHousehold);

// $FlowFixMe
export const EditProspectHouseholdForm = connect((state) => ({
  primaryApplicantId: selector(state, 'primaryApplicantId'),
}))(EditProspectHousehold);
