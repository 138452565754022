import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import useAbortController from '../../hooks/useAbortController';
import UnitService from '../../services/unitService';
import FloorPlanPricingService from '../../services/floorPlanPricingService';
import { AMENITY_PROPERTY_FEE } from '../../containers/ManageUnitAvailability/constants';

const useManageUnitAvailabilityQuery = ({
  propertyId,
  organizationId,
  options,
}: {
  propertyId: string,
  organizationId: string,
  options?: UseQueryOptions,
}) => {
  const queryKey = [
    'useManageUnitAvailabilityQuery',
    organizationId,
    propertyId,
  ];

  const abortControllerOptions = useAbortController();

  const { data, isLoading } = useQuery(
    queryKey,
    async () => {
      const response = await new UnitService().getAllByProperty(
        propertyId,
        organizationId,
        abortControllerOptions,
      );
      const marketRentsResponse =
        await new FloorPlanPricingService().getAllMarketRentsByProperty(
          organizationId,
          propertyId,
          abortControllerOptions,
        );

      const amenities = (response?.propertyFees ?? [])
        .filter((pf) => pf?.feeType === AMENITY_PROPERTY_FEE)
        .map((amenity) => ({
          id: amenity?.id,
          displayNameOnQuote: amenity?.displayNameOnQuote,
        }));
      const units = response.units.map((unit) => {
        const property = {
          ...unit.property,
          propertyFees: response.propertyFees,
        };
        return { ...unit, property };
      });

      return {
        units,
        amenities,
        marketRents: marketRentsResponse?.marketRents ?? [],
      };
    },
    {
      enabled: Boolean(propertyId && organizationId),
      ...options,
    },
  );

  const results = useMemo(
    () =>
      data ?? {
        units: [],
        amenities: [],
        marketRents: [],
      },
    [data],
  );

  return {
    results,
    isLoading,
  };
};

export default useManageUnitAvailabilityQuery;
