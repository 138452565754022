import { PROSPECT_PREFIX, CUSTOMER_PREFIX } from './constants';
import type {
  Message,
  Conversation,
  ConversationStatus,
  MessageStatus,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress/TextingTab/TextingTab.types'; // eslint-disable-line max-len
import type { HouseholdMember } from './types';

export function prospectToHouseholdMember(
  prospectDto: Object,
): HouseholdMember {
  return {
    id: `${PROSPECT_PREFIX}${prospectDto.id}`,
    name: `${prospectDto.firstName}${
      prospectDto.lastName ? ` ${prospectDto.lastName}` : ''
    }`,
    phone: prospectDto.phoneNumber,
  };
}

export function customerToHouseholdMember(
  customerDto: Object,
): HouseholdMember {
  return {
    id: `${CUSTOMER_PREFIX}${customerDto.id}`,
    name: `${customerDto.firstName}${
      customerDto.lastName ? ` ${customerDto.lastName}` : ''
    }`,
    phone: customerDto.phoneNumber,
  };
}

export function conversationDtoToConversation(
  conversationDto: Object,
  householdMember: HouseholdMember,
): Conversation {
  return {
    id: conversationDto['_id'],
    recipients: [{ ...householdMember }],
    lastReceivedMessage: conversationDto.lastReceivedMessage
      ? {
          content: conversationDto.lastReceivedMessage.content,
          dateDelivered: conversationDto.lastReceivedMessage.updatedAt,
        }
      : null,
    status: conversationStatusToStatus(conversationDto.conversationStatus),
  };
}

export function conversationStatusToStatus(
  conversationStatus: string,
): ConversationStatus {
  if (conversationStatus === 'INVALID_PHONE_NUMBER') return 'invalid-phone';
  if (conversationStatus === 'NEW') return 'new-message';
  if (conversationStatus === 'UNRESOLVED') return 'unresolved';
  return 'resolved';
}

export function messageDtoToMessage({
  messageDto,
  conversation,
  selectedProperty,
  user,
  includeUser,
  includeAttachments,
}: {
  messageDto: Object,
  conversation: Conversation,
  selectedProperty: Object,
  includeUser?: boolean,
  user?: Object,
  includeAttachments: boolean,
}): Message {
  const senderType =
    messageDto.type === 'OUTBOUND' ? 'property' : 'household-member';
  const recipient =
    senderType === 'household-member' ? conversation.recipients[0] : undefined;
  return {
    id: messageDto['_id'],
    content: messageDto.content,
    sender: recipient?.name ?? selectedProperty.name,
    senderType,
    dateDelivered: messageDto.updatedAt,
    deliveryFailureReason:
      messageDto.messageStatus === 'INVALID_PHONE_NUMBER'
        ? 'invalid-phone'
        : undefined,
    status: messageStatusToStatus(messageDto.messageStatus),
    isPhoneNumberChangeMsg:
      messageDto.content.startsWith('Phone number has changed from ') &&
      messageDto.type === 'INTERNAL',
    user: includeUser && user ? `${user.firstName} ${user.lastName}` : null,
    attachments: includeAttachments
      ? messageDto.attachments?.map((a) => ({
          type: 'image',
          url: a.url,
        }))
      : undefined,
  };
}

export function messageStatusToStatus(messageStatus: string): MessageStatus {
  if (messageStatus === 'DELIVERED' || messageStatus === 'RECEIVED')
    return 'delivered';
  if (messageStatus === 'UNDELIVERED') return 'undelivered';
  if (messageStatus === 'FAILED') return 'failed';
  if (messageStatus === 'INVALID_PHONE_NUMBER') return 'invalid-phone';
  if (messageStatus === 'MASS_COMMUNICATION') return 'mass-communication';
  if (messageStatus === 'AUTOMATED') return 'automated';
  return 'processing';
}
