import chunk from 'lodash.chunk';
import { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { TwoWayCommunicationService } from '../../services/twoWayCommunicationService';

export function useFetchConversations(
  recipientsIds: string[],
  showTextingColumn: boolean,
) {
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    if (
      process.env.REACT_APP_TWO_WAY_API_URL &&
      showTextingColumn &&
      recipientsIds.length > 0
    ) {
      async function getConversations() {
        const twoWayCommunicationService = new TwoWayCommunicationService();

        try {
          const recipientsIdsChunks = chunk(recipientsIds, 100);

          const responses = await Promise.all(
            recipientsIdsChunks.map(async (recipientsIdsChunk) =>
              twoWayCommunicationService.getConversationsByRecipientsIds(
                recipientsIdsChunk,
              ),
            ),
          );

          setConversations(responses.flat());
        } catch (error) {
          toastr.error(
            'Error',
            error.networkError ||
              error.toString() ||
              'Failed to load conversations',
          );
        }
      }

      getConversations();
    }
  }, [recipientsIds, showTextingColumn]);

  return [conversations];
}
