import { Link as ReactRouterLink } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useIntl from './useIntl';

const useBaseTableProps = () => {
  const intl = useIntl();

  return {
    ReactRouterLink,
    ReactBeautifulDnD: {
      DragDropContext,
      Droppable,
      Draggable,
    },
    intl,
  };
};

export default useBaseTableProps;
