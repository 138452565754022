import type {
  Role,
  OrderValue,
  PropertyRole,
  RoleAssignment,
} from '../containers/App/types';
const { apiURL, get, post, patch } = require('../utils/api');

const request = require('../utils/api').default;

class RoleService {
  getAllRolesPaginate(
    organizationId: string,
    pageNumber: number,
    limit: number,
    sorting: OrderValue,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const sort =
      sorting.fieldName === ''
        ? ''
        : `&sort=${sorting.fieldName}&order=${sorting.order}`;
    return request(
      `${apiURL}/${organizationId}/userRoles/paginate?page=${pageNumber}&limit=${limit}${sort}`,
      options,
    ); // eslint-disable-line max-len
  }
  getAllRoles(organizationId: string) {
    return get(`/${organizationId}/userRoles`);
  }

  getAllRoleTypes() {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/userRoleTypes`, options);
  }

  getRole(roleId: string, organizationId: string) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/${organizationId}/userRoles/${roleId}`, options);
  }

  createRole(role: Role) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(role),
    };
    return request(`${apiURL}/${role.organizationId}/userRoles`, options);
  }

  editRole(roleId: string, role: Role) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(role),
    };
    return request(
      `${apiURL}/${role.organizationId}/userRoles/${roleId}`,
      options,
    );
  }

  bulkCreatePropertyRoles(organizationId, propertyRoles: PropertyRole[]) {
    return post(
      `/${organizationId}/propertyRoles`,
      JSON.stringify(propertyRoles),
    );
  }

  getPropertyRoleAssignments(organizationId: string, propertyId: string) {
    return get(`/${organizationId}/${propertyId}/propertyRoles/assignments`);
  }

  getAllPropertyRoles(organizationId: string) {
    return get(`/${organizationId}/propertyRoles`);
  }

  async assignPropertyRoles(
    propertyId: string,
    roleAssignments: RoleAssignment[],
  ) {
    return post(
      `/${propertyId}/propertyRoles/assign`,
      JSON.stringify(roleAssignments),
    );
  }

  async bulkUpdatePropertyRoles(organizationId, propertyRoles: PropertyRole[]) {
    return patch(
      `/${organizationId}/propertyRoles`,
      JSON.stringify(propertyRoles),
    );
  }
}

export default RoleService;
