import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewUnit.MonthlyRecurringCharges.Title',
    defaultMessage: 'Monthly Recurring Charges',
  },
  total: {
    id: 'App.ViewUnit.MonthlyRecurringCharges.Total',
    defaultMessage: 'Total Monthly Recurring Charges',
  },
  unitQuotingRentAmount: {
    id: 'App.ViewUnit.MonthlyRecurringCharges.UnitQuotingRentAmount',
    defaultMessage: 'Unit Quoting Rent Amount',
  },
});

export default messages;
