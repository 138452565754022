import React, { Component } from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import {
  FormattedMessage,
  FormattedNumber,
  injectIntl,
  type InjectIntlProvidedProps,
} from 'react-intl';
import { moment } from 'react-datetime';
import { Col, Row } from 'react-bootstrap';
import messages from '../messages';
import { pathOr, isNil, isEmpty } from 'ramda';
import {
  DOCUMENT_NUMBER_MAX_LENGTH,
  FORM_NAME,
  NOTE_MAX_LENGTH,
} from '../constants';
import { Text, Total } from '../Fields';
import {
  renderCurrencyField,
  renderDateField,
  renderSelectFieldTwoColumns,
  renderTextFieldTwoColumns,
  renderCheckboxFieldTwoColumns,
} from '../../../utils/redux-form-helper';
import {
  validateMaxLength,
  validateRequired,
} from '../../../utils/redux-form/field-validations';
import validate from './validate';
import DashesIfNullOrUndefined from '../../DashesIfNullOrUndefined';

type OptionType = { value: ?string, text: string };

type DefaultProps = {
  onSubmit: Function,
  defaultOption: OptionType,
  frNames: string[],
  residents: Array<Object>,
  subjournals: Array<Object>,
  transactionCodes: Array<Object>,
  transactionTypes: Array<Object>,
};

type ReduxFormProps = {
  change: Function,
  form: string,
  handleSubmit: Function,
  initialValues: Object,
  resetSection: Function,
};

type Props = InjectIntlProvidedProps &
  DefaultProps &
  ReduxFormProps & {
    formValues: Object,
    priorBalance: number,
    priorSecurityBalance: number,
    priorSubjournalBalances: Object,
    propertyName: string,
    status: string,
    unit: ?Object,
    isAllCommercial: boolean,
    camPools?: Array<Object>,
    propertyFiscalPeriod: Object,
  };

type State = {};

export const maxLength25 = (value: ?string) =>
  validateMaxLength(DOCUMENT_NUMBER_MAX_LENGTH)(value);

export const maxLength100 = (value: ?string) =>
  validateMaxLength(NOTE_MAX_LENGTH)(value);

export class CreateTransactionForm extends Component<Props, State> {
  static defaultProps = {
    onSubmit: () => {},
    defaultOption: { value: 'default', text: 'Choose', disabled: true },
    transactionCodes: [],
    transactionTypes: [],
    subjournals: [],
    residents: [],
  };

  validateReactDateTime = (currentDate: Object) => {
    const fiscalPeriodStartDate = pathOr(
      null,
      ['propertyFiscalPeriod', 'startDate'],
      this.props,
    );
    const momentFiscalPeriodStartDate = moment(fiscalPeriodStartDate);
    const today = moment().startOf('day');
    return moment(currentDate).isBetween(
      momentFiscalPeriodStartDate,
      today,
      'day',
      '[]',
    );
  };

  handleTransactionTypeChange = (evt: Object) => {
    const { resetSection, change } = this.props;
    resetSection('subjournal', 'code');
    change('applyToCamPool', false);
  };

  handleSubjournalChange = (evt: Object) => {
    const { resetSection } = this.props;
    resetSection('code', 'receivedFrom');
  };

  handleCamRecordChange = (evt: Object) => {
    const { resetSection } = this.props;
    resetSection('camPool');
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    onSubmit();
  };

  generateOptions = (data: Array<Object>, shape: Object): Array<Object> => {
    return data.map((datum) => {
      return {
        value: pathOr('', shape.value, datum),
        text: pathOr('', shape.text, datum),
      };
    });
  };

  generateOptionsCodes = (
    data: Array<Object>,
    shape: Object,
  ): Array<Object> => {
    return data.map((datum) => {
      const code = pathOr('', shape.text, datum);
      const codeDescription = pathOr(
        '',
        ['transactionCode', 'description'],
        datum,
      );
      return {
        value: pathOr('', shape.value, datum),
        text: `${code} - ${codeDescription}`,
      };
    });
  };

  isPayment = () => {
    const {
      formValues: { code: transactionCodeId },
      transactionCodes,
    } = this.props;
    const selectedCode = transactionCodes.find((code) => {
      return code.id === transactionCodeId;
    });
    return !!selectedCode
      ? pathOr(false, ['transactionCode', 'isPayment'], selectedCode)
      : false;
  };

  isSecurity = () => {
    const {
      formValues: { transactionType },
      transactionTypes,
    } = this.props;
    const selectedType = transactionTypes.find((type) => {
      return type.id === transactionType;
    });
    return !!selectedType && !selectedType.hasSubjournals;
  };

  requiredForPayment = (value: string) => {
    const {
      intl: { formatMessage },
    } = this.props;
    return validateRequired(value, formatMessage(messages.required));
  };

  getPreviousBalance() {
    const {
      formValues: { subjournal },
    } = this.props;
    const { priorBalance, priorSecurityBalance, priorSubjournalBalances } =
      this.props;
    const selectedSubjournalDesc = pathOr(
      'default',
      ['masterSubjournalType', 'description'],
      subjournal,
    );
    if (selectedSubjournalDesc !== 'default') {
      return parseFloat(priorSubjournalBalances[selectedSubjournalDesc]);
    }
    return this.isSecurity() ? priorSecurityBalance : priorBalance;
  }

  computeAccountBalance = (
    selectedTransactionType: any,
    amount: string,
    oldBalance: number,
  ) => {
    const amountFloat = parseFloat(amount || '0.00');
    return selectedTransactionType
      ? selectedTransactionType.accountType === 'Debit'
        ? oldBalance + amountFloat
        : oldBalance - amountFloat
      : 0;
  };

  getFilteredCodes(): Array<any> {
    const {
      formValues: { subjournal, transactionType },
      subjournals,
      transactionCodes,
    } = this.props;
    const selectedSubjournal = subjournals.find((s) => s.id === subjournal);
    return transactionCodes
      .filter(
        (code) =>
          transactionType ===
          pathOr('', ['transactionCode', 'transactionTypeId'], code),
      )
      .filter(
        (code) =>
          this.isSecurity() ||
          (selectedSubjournal &&
            //$FlowFixMe
            pathOr('', ['transactionCode', 'masterSubjournal', 'id'], code) ===
              selectedSubjournal.masterSubjournalId),
      );
  }

  getFilteredSubjournals(): Array<any> {
    const {
      formValues: { transactionType },
      subjournals,
      transactionCodes,
    } = this.props;
    const availableCodes = transactionCodes.filter(
      (code) => code.transactionCode.transactionTypeId === transactionType,
    );
    const availableSubjournals = availableCodes.map(
      (code) => code.transactionCode.subjournalId,
    );
    return subjournals.filter((subjournal) =>
      availableSubjournals.find((e) => e === subjournal.masterSubjournalId),
    );
  }

  render() {
    const {
      change,
      defaultOption,
      formValues: {
        amount,
        transactionType,
        applyToCamPool,
        camRecord,
        subjournal,
      },
      frNames,
      handleSubmit,
      intl: { formatMessage },
      onSubmit,
      propertyName,
      status,
      transactionTypes,
      unit,
      isAllCommercial,
      camPools,
    } = this.props;

    const currencyStyle = { value: 'currency' };
    const isPayment = this.isPayment();
    const selectedTransactionType: any = transactionTypes.find(
      (t) => t.id === transactionType,
    );
    const selectedSubjournal = this.getFilteredSubjournals().find(
      (s) => s.id === subjournal,
    );
    const oldBalance = this.getPreviousBalance();
    const accountBalance = this.computeAccountBalance(
      selectedTransactionType,
      amount,
      oldBalance,
    );

    const selectedTransactionTypeName = pathOr(
      '',
      ['name'],
      selectedTransactionType,
    );
    const selectedTransactionTypeIsApplicableToCam = [
      'charge',
      'credit',
    ].includes(selectedTransactionTypeName.toLowerCase());
    const selectedSubjournalName = pathOr(
      '',
      ['masterSubjournalType', 'description'],
      selectedSubjournal,
    );
    const selectedSubjournalIsApplicableToCam = ['cam', 'resident'].includes(
      selectedSubjournalName.toLowerCase(),
    );

    const receivedFromOptions = [
      ...frNames.map((name) => ({
        value: name,
        text: name,
      })),
    ];
    if (receivedFromOptions.length !== 1) {
      receivedFromOptions.unshift(defaultOption);
    } else {
      change('receivedFrom', receivedFromOptions[0].value);
    }

    const subjournalOptions = [
      ...this.generateOptions(this.getFilteredSubjournals(), {
        value: ['id'],
        text: ['masterSubjournalType', 'description'],
      }),
    ];
    if (subjournalOptions.length !== 1) {
      subjournalOptions.unshift(defaultOption);
    } else {
      change('subjournal', subjournalOptions[0].value);
    }

    const transactionTypesOptions = [
      ...this.generateOptions(
        transactionTypes.filter(
          (type) => type.includeInCreateSingleTransaction,
        ),
        {
          value: ['id'],
          text: ['name'],
        },
      ),
    ];
    if (transactionTypesOptions.length !== 1) {
      transactionTypesOptions.unshift(defaultOption);
    } else {
      change('transactionType', transactionTypesOptions[0].value);
    }

    const transactionCodesOptions = [
      ...this.generateOptionsCodes(this.getFilteredCodes(), {
        value: ['id'],
        text: ['transactionCode', 'code'],
      }),
    ];
    if (transactionCodesOptions.length !== 1) {
      transactionCodesOptions.unshift(defaultOption);
    } else {
      change('code', transactionCodesOptions[0].value);
    }

    const getCamPeriodsOptions = () => {
      if (!showCamPools) {
        return [];
      }

      const periodsOptions = [
        ...this.generateOptions(camPools, {
          value: ['camRecordsId'],
          text: ['startDate'],
        }),
      ];
      if (periodsOptions.length !== 1) {
        periodsOptions.unshift(defaultOption);
      } else {
        change('camRecord', periodsOptions[0].value);
      }
      return periodsOptions;
    };

    const getCamPoolsOptions = () => {
      if (!showCamPools || !camRecord || camRecord === 'default') {
        return [defaultOption];
      }

      const selectedCam = camPools.find((p) => p.camRecordsId === camRecord);
      const pools = pathOr(null, ['camPools'], selectedCam);
      if (!pools) {
        return [defaultOption];
      }

      const poolsOptions = [
        ...this.generateOptions(pools, {
          value: ['camAllocationsId'],
          text: ['camPoolName'],
        }),
      ];
      if (poolsOptions.length !== 1) {
        poolsOptions.unshift(defaultOption);
      } else {
        change('camPool', poolsOptions[0].value);
      }
      return poolsOptions;
    };

    const showCamPools =
      selectedSubjournalIsApplicableToCam &&
      selectedTransactionTypeIsApplicableToCam &&
      !isPayment &&
      !isNil(camPools) &&
      !isEmpty(camPools);

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Text label={<FormattedMessage {...messages.propertyName} />}>
          {propertyName}
        </Text>
        <Text label={<FormattedMessage {...messages.unitId} />}>
          <DashesIfNullOrUndefined data={unit ? unit.number : null} />
        </Text>
        <Text label={<FormattedMessage {...messages.status} />}>
          {isAllCommercial
            ? status === 'Prior Resident'
              ? 'Prior Tenant'
              : status === 'Current Resident'
              ? 'Current Tenant'
              : status
            : status}
        </Text>
        <Text label={<FormattedMessage {...messages.residentName} />}>
          {frNames.join(', ')}
        </Text>
        <Field
          name="transactionType"
          component={renderSelectFieldTwoColumns}
          col1={[4, 12]}
          col2={[8, 12]}
          label={<FormattedMessage {...messages.transactionType} />}
          options={transactionTypesOptions}
          onChange={this.handleTransactionTypeChange}
        />
        <Field
          name="subjournal"
          component={renderSelectFieldTwoColumns}
          col1={[4, 12]}
          col2={[8, 12]}
          label={<FormattedMessage {...messages.subjournal} />}
          options={subjournalOptions}
          disabled={transactionType === 'default' || this.isSecurity()}
          onChange={this.handleSubjournalChange}
        />
        <Field
          name="code"
          component={renderSelectFieldTwoColumns}
          col1={[4, 12]}
          col2={[8, 12]}
          label={<FormattedMessage {...messages.code} />}
          options={transactionCodesOptions}
          disabled={transactionType === 'default'}
        />
        {showCamPools && (
          <React.Fragment>
            <Field
              name="applyToCamPool"
              col1={[4, 12]}
              col2={[8, 12]}
              component={renderCheckboxFieldTwoColumns}
              label={<FormattedMessage {...messages.applyToCamPool} />}
            />
            {applyToCamPool && (
              <React.Fragment>
                <Field
                  name="camRecord"
                  component={renderSelectFieldTwoColumns}
                  col1={[4, 12]}
                  col2={[8, 12]}
                  label={<FormattedMessage {...messages.camPeriod} />}
                  options={getCamPeriodsOptions()}
                  onChange={this.handleCamRecordChange}
                />
                <Field
                  name="camPool"
                  component={renderSelectFieldTwoColumns}
                  col1={[4, 12]}
                  col2={[8, 12]}
                  label={<FormattedMessage {...messages.camPool} />}
                  options={getCamPoolsOptions()}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {isPayment && (
          <Field
            name="receivedFrom"
            component={renderSelectFieldTwoColumns}
            col1={[4, 12]}
            col2={[8, 12]}
            label={<FormattedMessage {...messages.receivedFrom} />}
            options={receivedFromOptions}
            validate={[this.requiredForPayment]}
          />
        )}
        <Row>
          <Col md={4} xs={12}>
            <label>
              <FormattedMessage {...messages.date} />
            </label>
          </Col>
          <Col md={8} xs={12}>
            <Field
              name="date"
              component={renderDateField}
              bsSize="md"
              isValidDate={this.validateReactDateTime}
            />
          </Col>
        </Row>
        {isPayment && (
          <Field
            name="documentNumber"
            component={renderTextFieldTwoColumns}
            col1={[4, 12]}
            col2={[8, 12]}
            label={<FormattedMessage {...messages.documentNumber} />}
            autoComplete="off"
            maxLength={DOCUMENT_NUMBER_MAX_LENGTH}
            validate={[this.requiredForPayment]}
            warn={[maxLength25]}
          />
        )}
        <Field
          name="note"
          component={renderTextFieldTwoColumns}
          col1={[4, 12]}
          col2={[8, 12]}
          label={<FormattedMessage {...messages.note} />}
          autoComplete="off"
          placeholder={formatMessage(messages.notePlaceholder)}
          maxLength={NOTE_MAX_LENGTH}
          warn={[maxLength100]}
        />
        <Text label={<FormattedMessage {...messages.priorBalance} />}>
          <div className="text-right">
            <strong>
              <FormattedNumber
                value={oldBalance}
                style={currencyStyle.value}
                currency="USD"
              />
            </strong>
          </div>
        </Text>
        <Row>
          <Col md={4} xs={12}>
            <label>
              <FormattedMessage {...messages.amount} />
            </label>
          </Col>
          <Col md={8} xs={12}>
            <Field
              name="amount"
              component={renderCurrencyField}
              className="text-right"
              step="0.01"
            />
          </Col>
        </Row>

        <Total label={<FormattedMessage {...messages.accountBalance} />}>
          <FormattedNumber
            value={accountBalance}
            style={currencyStyle.value}
            currency="USD"
          />
        </Total>
      </Form>
    );
  }
}

export default injectIntl(
  reduxForm({
    form: FORM_NAME,
    initialValues: {
      subjournal: 'default',
      transactionType: 'default',
      receivedFrom: 'default',
      code: 'default',
      amount: '',
      date: moment(),
      applyToCamPool: false,
      camRecord: 'default',
      camPool: 'default',
    },
    touchOnChange: true,
    asyncChangeFields: ['documentNumber', 'note'],
    enableReinitialize: true,
    validate,
  })(CreateTransactionForm),
);
