import { defineMessages } from 'react-intl';

const messages = defineMessages({
  noEmergencyContact: {
    id: 'App.Household.EmergencyContactsSection.NoEmergencyContact',
    defaultMessage: 'No Emergency Contact',
  },
  primary: {
    id: 'App.Household.EmergencyContactsSection.Primary',
    defaultMessage: 'Primary',
  },
  name: {
    id: 'App.Household.EmergencyContactsSection.Name',
    defaultMessage: 'Name',
  },
  phoneNumber: {
    id: 'App.Household.EmergencyContactsSection.PhoneNumber',
    defaultMessage: 'Phone Number',
  },
  emergencyContactsFetchError: {
    id: 'App.Household.EmergencyContactsSection.EmergencyContactsFetchError',
    defaultMessage: 'Error loading emergency contacts',
  },
  confirmSetPrimary: {
    id: 'App.Household.EmergencyContactsSection.ConfirmSetPrimary',
    defaultMessage: `Only one Emergency Contact can be set as Primary.
      Setting this contact as primary will unset the current one.
      Are you sure you want to proceed?`,
  },
  addEmergencyContactSuccessTitle: {
    id:
      'App.Household.EmergencyContactsSection.AddEmergencyContactSuccessTitle',
    defaultMessage: 'Emergency Contact added',
  },
  addEmergencyContactSuccessMessage: {
    id:
      'App.Household.EmergencyContactsSection.AddEmergencyContactSuccessMessage',
    defaultMessage: 'The Emergency Contact was added successfully.',
  },
  addEmergencyContactError: {
    id: 'App.Household.EmergencyContactsSection.AddEmergencyContactError',
    defaultMessage: 'Error adding the Emergency Contact',
  },
  editEmergencyContactSuccessTitle: {
    id:
      'App.Household.EmergencyContactsSection.EditEmergencyContactSuccessTitle',
    defaultMessage: 'Emergency Contact updated',
  },
  editEmergencyContactSuccessMessage: {
    id:
      'App.Household.EmergencyContactsSection.EditEmergencyContactSuccessMessage',
    defaultMessage: 'The Emergency Contact was updated successfully.',
  },
  editEmergencyContactError: {
    id: 'App.Household.EmergencyContactsSection.EditEmergencyContactError',
    defaultMessage: 'Error updating the Emergency Contact',
  },
  setPrimaryEmergencyContactSuccessTitle: {
    id:
      'App.Household.EmergencyContactsSection.SetPrimaryEmergencyContactSuccessTitle',
    defaultMessage: 'Primary Emergency Contact updated',
  },
  setPrimaryEmergencyContactSuccessMessage: {
    id:
      'App.Household.EmergencyContactsSection.SetPrimaryEmergencyContactSuccessMessage',
    defaultMessage:
      'The Emergency Contact was successfully set as Primary Contact.',
  },
  setPrimaryEmergencyContactError: {
    id:
      'App.Household.EmergencyContactsSection.SetPrimaryEmergencyContactError',
    defaultMessage: 'Error setting Primary Contact',
  },
  deleteEmergencyContactSuccessTitle: {
    id:
      'App.Household.EmergencyContactsSection.DeleteEmergencyContactSuccessTitle',
    defaultMessage: 'Emergency Contact deleted',
  },
  deleteEmergencyContactSuccessMessage: {
    id:
      'App.Household.EmergencyContactsSection.DeleteEmergencyContactSuccessMessage',
    defaultMessage: 'The Emergency Contact was deleted successfully.',
  },
  deleteEmergencyContactError: {
    id: 'App.Household.EmergencyContactsSection.DeleteEmergencyContactError',
    defaultMessage: 'Error deleting Primary Contact',
  },
});

export default messages;
