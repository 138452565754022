import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.HouseholdVehicles.Header',
    defaultMessage: 'Household Vehicle Information',
  },
  count: {
    id: 'App.HouseholdVehicles.Count',
    defaultMessage: 'Number of Vehicles',
  },
  description: {
    id: 'App.HouseholdVehicles.Description',
    defaultMessage: 'Make/Model/Year/Color',
  },
  year: {
    id: 'App.HouseholdVehicles.Year',
    defaultMessage: 'Year',
  },
  make: {
    id: 'App.HouseholdVehicles.Make',
    defaultMessage: 'Make',
  },
  model: {
    id: 'App.HouseholdVehicles.Model',
    defaultMessage: 'Model',
  },
  color: {
    id: 'App.HouseholdVehicles.Color',
    defaultMessage: 'Color',
  },
  licensePlate: {
    id: 'App.HouseholdVehicles.Plate',
    defaultMessage: 'License Plate',
  },
  licenseState: {
    id: 'App.HouseholdVehicles.State',
    defaultMessage: 'License State',
  },
  tagId: {
    id: 'App.HouseholdVehicles.TagId',
    defaultMessage: 'Decal/Tag ID',
  },
  parkingSpot: {
    id: 'App.HouseholdVehicles.ParkingSpot',
    defaultMessage: 'Parking Spot Number',
  },
  cancel: {
    id: 'App.HouseholdVehicles.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.HouseholdVehicles.Save',
    defaultMessage: 'Save Changes',
  },
  success: {
    id: 'App.HouseholdVehicles.Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'App.HouseholdVehicles.Failure',
    defaultMessage: 'Error',
  },
  createVehicleSuccessDescription: {
    id: 'App.HouseholdVehicles.CreateSuccessDescription',
    defaultMessage: 'Household vehicle created',
  },
  createVehicleFailureDescription: {
    id: 'App.HouseholdVehicles.FailureDescription',
    defaultMessage: 'Vehicle could not be created',
  },
  updateVehicleSuccessDescription: {
    id: 'App.HouseholdVehicles.UpdateSuccessDescription',
    defaultMessage: 'Household vehicle successfully updated',
  },
  updateVehicleErrorDescription: {
    id: 'App.HouseholdVehicles.UpdateErrorDescription',
    defaultMessage: 'Vehicle could not be updated',
  },
  deleteVehicleSuccessDescription: {
    id: 'App.HouseholdVehicles.DeleteSuccessDescription',
    defaultMessage: 'Household vehicle successfully deleted',
  },
  deleteVehicleErrorDescription: {
    id: 'App.HouseholdVehicles.DeleteErrorDescription',
    defaultMessage: 'Vehicle could not be deleted',
  },
  confirmationTitle: {
    id: 'App.HouseholdVehicles.Confirmation.Title',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  confirmDeleteTitle: {
    id: 'App.HouseholdVehicles.Confirm.Delete.Vehicle.Title',
    defaultMessage: 'Are you sure you want to delete this vehicle?',
  },
  confirmationCancel: {
    id: 'App.HouseholdVehicles.Confirmation.Cancel',
    defaultMessage: 'No',
  },
  confirm: {
    id: 'App.HouseholdVehicles.Confirmation.Confirm',
    defaultMessage: 'Yes',
  },
  required: {
    id: 'App.HouseholdVehicles.Required',
    defaultMessage: 'Please choose the number of vehicles in the household',
  },
});

export default messages;
