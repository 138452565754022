import { defineMessages } from 'react-intl';

const messages = defineMessages({
  domTitle: {
    id: 'App.ManageUsers.domTitle',
    defaultMessage: 'Fortress - Manage users'
  },
  header: {
    id: 'App.ManageUsers.Header',
    defaultMessage: 'Manage Users'
  },
  lastName: {
    id: 'App.ManageUsers.LastName',
    defaultMessage: 'Last Name'
  },
  firstName: {
    id: 'App.ManageUsers.FirstName',
    defaultMessage: 'First Name'
  },
  username: {
    id: 'App.ManageUsers.Username',
    defaultMessage: 'Username'
  },
  email: {
    id: 'App.ManageUsers.email',
    defaultMessage: 'Email'
  },
  title: {
    id: 'App.ManageUsers.Title',
    defaultMessage: 'Title'
  },
  role: {
    id: 'App.ManageUsers.Role',
    defaultMessage: 'Role'
  },
  lastAccess: {
    id: 'App.ManageUsers.LastAccess',
    defaultMessage: 'Last Access'
  },
  properties: {
    id: 'App.ManageUsers.Properties',
    defaultMessage: 'Properties'
  },
  allProperties: {
    id: 'App.ManageUsers.AllProperties',
    defaultMessage: 'All'
  },
  actions: {
    id: 'App.ManageUsers.Actions',
    defaultMessage: 'Actions'
  },
  view: {
    id: 'App.ManageUsers.View',
    defaultMessage: 'View'
  },
  status: {
    id: 'App.ManageUsers.Status',
    defaultMessage: 'Status'
  },
  searchUsers: {
    id: 'App.ManageUsers.searchUsers',
    defaultMessage: 'Search for users...'
  },
  addUser: {
    id: 'App.ManageUsers.addUser',
    defaultMessage: '+ Add new user'
  },
  sortAscendingLetters: {
    id: 'App.ManageUsers.SortAscendingLetters',
    defaultMessage: 'A - Z'
  },
  sortDescendingLetters: {
    id: 'App.ManageUsers.SortDescendingLetters',
    defaultMessage: 'Z - A'
  },
  sortAscendingNumbers: {
    id: 'App.ManageUsers.SortAscendingNumbers',
    defaultMessage: '0 - 9'
  },
  sortDescendingNumbers: {
    id: 'App.ManageUsers.SortDescendingNumbers',
    defaultMessage: '9 - 0'
  },
  sortAscendingDates: {
    id: 'App.ManageUsers.SortAscendingDates',
    defaultMessage: 'Newest to Oldest'
  },
  sortDescendingDates: {
    id: 'App.ManageUsers.sortDescendingDates',
    defaultMessage: 'Oldest to Newest'
  },
  editUserSuccessHeader: {
    id: 'App.ManageUsers.EditUserSuccessHeader',
    defaultMessage: 'User edited'
  },
  editUserSuccessDescription: {
    id: 'App.ManageUsers.EditUserSuccessDescription',
    defaultMessage: 'The user was edited successfully'
  },
  createUserSuccessHeader: {
    id: 'App.ManageUsers.CreateUserSuccessHeader',
    defaultMessage: 'User created'
  },
  createUserSuccessDescription: {
    id: 'App.ManageUsers.CreateUserSuccessDescription',
    defaultMessage: 'The user was created successfully'
  }
});

export default messages;
