import { isNil, omit } from 'ramda';
import { get, post, put } from '../utils/api';

export default class InsurancePolicyService {
  async getInsurancePolicies(
    organizationId: string,
    propertyId: string,
    householdId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/households/${householdId}/insurance-policy`,
      options,
    );
  }

  async addInsurancePolicy(
    organizationId: string,
    propertyId: string,
    householdId: string,
    payload: Object,
  ) {
    const body = new FormData();
    Object.entries(omit(['proofOfCoverage'], payload)).forEach(
      ([key, value]) => {
        body.append(key, value);
      },
    );

    if (payload.proofOfCoverage?.file) {
      body.append('proofOfCoverage', payload.proofOfCoverage.file);
    }

    return post(
      `/${organizationId}/${propertyId}/households/${householdId}/insurance-policy`,
      body,
      {
        headers: {
          Authorization: localStorage.getItem('session_id'),
        },
      },
    );
  }

  async editInsurancePolicy(
    organizationId: string,
    propertyId: string,
    householdId: string,
    insuranceId: string,
    payload: Object,
  ) {
    const body = new FormData();
    Object.entries(omit(['proofOfCoverage'], payload)).forEach(
      ([key, value]) => {
        if (!isNil(value)) body.append(key, value);
      },
    );

    if (payload.proofOfCoverage?.id) {
      body.append('proofOfCoverage', payload.proofOfCoverage.id);
    } else {
      body.append('proofOfCoverage', payload.proofOfCoverage?.file ?? '');
    }

    return put(
      `/${organizationId}/${propertyId}/households/${householdId}/insurance-policy/${insuranceId}`,
      body,
      {
        headers: {
          Authorization: localStorage.getItem('session_id'),
        },
      },
    );
  }
}
