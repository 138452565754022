import React from 'react';
import { Form } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';

type Props = {
  onFilterClick: Function,
  hasActiveFilters: boolean,
  handleSubmit: Function,
  placeholder: string,
  style: Object
};

export const SearchActions = ({ onFilterClick, hasActiveFilters, placeholder, handleSubmit, style }: Props) => (
  <div className="search-actions" style={style}>
    <button
      className={`button-filter ${hasActiveFilters ? 'filters-are-active' : ''}`}
      onClick={() => onFilterClick(true)}
    >
      <i className="et-filter" />
    </button>
    <div className="search-input">
      <Form onSubmit={handleSubmit}>
        <Field className="input-lg" component="input" type="search" name="searchText" placeholder={placeholder} />
        <button type="submit">
          <i className="et-search" />
        </button>
      </Form>
    </div>
  </div>
);

export default reduxForm({
  form: 'searchProspects'
})(SearchActions);
