import { useContext, useState, useEffect, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AppContext } from '../../containers/App/context';
import {
  useMediaQuery,
  useLocalStorage,
} from '@fortress-technology-solutions/fortress-component-library/hooks';
import { useMenuToggle } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { useTwoWayNotifications } from '../../containers/App/Routes/MainLayoutRoutes/hooks';
import {
  filterNavigationOptionsByPermissionsPropertyClass,
  determineNavigationToOnClick,
  getOptionNotificationsBadgeType,
} from './utils';
import { NavigationOption } from '../../containers/App/types.js';

const useNavigationOptions = ({
  navigationOptions,
  selectedProperty,
  permissions,
  paymentProvider,
  flags,
  selectedPropertySubjournals,
  locale,
  isXs,
  history,
  setOpen,
  twoWayNotifications,
}: {
  navigationOptions: NavigationOption[],
  selectedProperty: Object,
  permissions: string[],
  paymentProvider: string,
  flags: Object,
  locale: string,
}): Object => {
  return useMemo(() => {
    const { pathname } = history.location;

    const filteredOptions = filterNavigationOptionsByPermissionsPropertyClass({
      navigationOptions,
      userPermissions: permissions,
      selectedProperty,
      paymentProvider,
      flags,
      selectedPropertySubjournals,
    });

    // if order === -1 then it will be placed at the end of the list
    const sortedOptions = filteredOptions.sort((a, b) => {
      if (a.order === -1) {
        return 1;
      }
      if (b.order === -1) {
        return -1;
      }
      return a.order - b.order;
    });

    const navOptionsArray = [];
    sortedOptions.forEach(({ name, translations, openTab, url, children }) => {
      const navOption = {
        text: translations[locale],
        value: name,
        selected: pathname === url,
        notification: getOptionNotificationsBadgeType(
          twoWayNotifications,
          name,
        ),
        ...(!Boolean(children?.length) &&
          determineNavigationToOnClick({
            url,
            openTab,
            history,
            setOpen,
            isXs,
          })),
      };

      const subOptions = children.map((subOption) => {
        const selected = pathname === subOption.url;

        if (selected) {
          navOption.selected = true;
        }

        return {
          text: subOption.translations[locale],
          value: subOption.name,
          selected,
          notification: getOptionNotificationsBadgeType(
            twoWayNotifications,
            subOption.name,
          ),
          ...determineNavigationToOnClick({
            url: subOption.url,
            openTab: subOption.openTab,
            history,
            setOpen,
            isXs,
          }),
        };
      });

      navOption.subOptions = subOptions ?? [];

      navOptionsArray.push(navOption);
    });

    return navOptionsArray;
  }, [
    history,
    navigationOptions,
    permissions,
    selectedProperty,
    paymentProvider,
    flags,
    selectedPropertySubjournals,
    locale,
    twoWayNotifications,
    setOpen,
    isXs,
  ]);
};
const useAppBarNavDrawer = ({
  actions,
  navigationOptions,
  paymentProvider,
  locale,
  selectedPropertySubjournals,
  history,
}) => {
  const flags = useFlags();
  const {
    permissions,
    selectedProperty,
    onSelectProperty,
    onSelectPortfolioSummary,
    logOut,
  } = useContext(AppContext);

  const [fullName, setFullName] = useState('');
  const { localStorageState: sessionId } = useLocalStorage(
    'session_id',
    'string',
  );
  useEffect(() => {
    if (sessionId?.length && sessionId !== 'undefined') {
      let decoded = JSON.parse(atob(sessionId.split('.')[1]));
      setFullName(`${decoded.firstName} ${decoded.lastName}`);
      actions.socketConnect();
    }
  }, [actions, sessionId]);

  const twoWayNotifications = useTwoWayNotifications({ selectedProperty });
  const isXs = useMediaQuery('only', 'xs');
  const isSmallerThanMd = useMediaQuery('down', 'md');
  const [drawerHovered, setDrawerHovered] = useState(false);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (flags.leftsideNavButton) {
      if (isSmallerThanMd) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  }, [flags.leftsideNavButton, isSmallerThanMd]);

  const {
    open: userMenuOpen,
    handleToggle: handleUserMenuToggle,
    handleClose: handleUserMenuClose,
    anchorRef: userMenuAnchorRef,
  } = useMenuToggle(false);

  const navOptionsArray = useNavigationOptions({
    navigationOptions,
    selectedProperty,
    permissions,
    paymentProvider,
    flags,
    isXs,
    locale,
    selectedPropertySubjournals,
    history,
    setOpen,
    twoWayNotifications,
  });

  const useMobileDrawer = flags.leftsideNavButton ? isSmallerThanMd : isXs;
  const showNavText = !useMobileDrawer ? drawerHovered || open : true;

  return {
    drawerHovered,
    flags,
    fullName,
    handleUserMenuClose,
    handleUserMenuToggle,
    isXs,
    isSmallerThanMd,
    logOut,
    navOptionsArray,
    onSelectPortfolioSummary,
    onSelectProperty,
    open,
    selectedProperty,
    setDrawerHovered,
    setOpen,
    showNavText,
    useMobileDrawer,
    userMenuAnchorRef,
    userMenuOpen,
  };
};

export default useAppBarNavDrawer;
