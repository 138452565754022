import { defineMessages } from 'react-intl';

const messages = defineMessages({
  yes: {
    id: 'App.Alert.yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'App.Alert.no',
    defaultMessage: 'No'
  }
});

export default messages;
