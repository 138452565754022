export const PROPERTY_PATH_MAP = {
  documentName: {
    path: 'documentName',
    dataType: 'string',
  },
  documentCategory: {
    path: 'documentCategory',
    dataType: 'string',
    filterPath: 'documentCategory',
    filterFn: (selectedCategories: string[] | string, category: string) => {
      if (typeof selectedCategories === 'string') {
        return selectedCategories.toLowerCase() === category.toLowerCase();
      }

      return (
        selectedCategories.find(
          (selectedCategory) =>
            selectedCategory.toLowerCase() === category.toLowerCase(),
        ) !== undefined
      );
    },
  },
  categoryDetails: {
    path: 'categoryDetails',
    dataType: 'string',
    filterPath: 'categoryDetails',
    filterFn: (selectedDetails: string | string[], detail: string) => {
      const parsedDetail = !detail ? '' : detail;

      if (typeof selectedDetails === 'string') {
        return selectedDetails.toLowerCase() === parsedDetail.toLowerCase();
      }

      return (
        selectedDetails.find(
          (selectedDetail) =>
            selectedDetail.toLowerCase() === parsedDetail.toLowerCase(),
        ) !== undefined
      );
    },
  },
  numberOfAssignedProperties: {
    path: 'numberOfAssignedProperties',
    dataType: 'number',
  },
};
