import styled from 'styled-components';

export const CaptionButtonContainer = styled.div`
  display: inline-block;
  height: 75px;
  margin-right: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  .btn {
    min-width: 200px;
  }
`;
