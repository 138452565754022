import { useCallback, useMemo, useState } from 'react';
import * as R from 'ramda';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import * as constants from '../constants';
import type { ReviewResponse } from '../types';
import { useFetchReviews, useRatingBreakdown, useOverallStats } from '../hooks';
import OverallAvgRatingCard from '../OverallAvgRatingCard';
import ReviewsCard from '../ReviewsCard';
import type { DateFilterType } from '../ReviewsFilter/types';
import type {
  FilterValues,
  FetchReviewParams,
} from '../../../services/reputationManagementService';

const sortingValueToOrderParamsMap = {
  'most-recent': {
    orderBy: 'updateTime',
    order: 'desc',
  },
  oldest: {
    orderBy: 'updateTime',
    order: 'asc',
  },
  highest: {
    orderBy: 'rating',
    order: 'desc',
  },
  lowest: {
    orderBy: 'rating',
    order: 'asc',
  },
};

const defaultFilters: FilterValues = {
  timeFrame: 'All Time',
  toDate: undefined,
  fromDate: undefined,
  ratings: [],
  status: undefined,
  category: undefined,
};

function ReputationMgmtFullContent() {
  const [fetchReviewParams, setFetchReviewParams]: [
    FetchReviewParams,
    (v: FetchReviewParams) => void,
  ] = useState({
    sources: [constants.GOOGLE_SOURCE_VALUE],
    page: 0,
    pageSize: constants.PAGE_SIZE_INCREASE,
    ...defaultFilters,
  });
  const [dateFilterType, setDateFilterType] = useState('timeframe');
  const [sortingValue, setSortingValue] = useState('most-recent');
  const [showFilters, setShowFilters] = useState(false);

  const selectedSource: string = useMemo(
    () => fetchReviewParams.sources[0],
    [fetchReviewParams.sources],
  );

  const ratingType = useMemo(
    () => constants.SOURCE_TO_RATING_TYPE_MAP[selectedSource],
    [selectedSource],
  );

  const {
    reviewData,
    isLoading: isReviewDataLoading,
    refreshReviews,
    updateReviewResponse,
  } = useFetchReviews(fetchReviewParams);
  const {
    overallStats,
    isLoading: isOverallStatsLoading,
    refreshStats,
  } = useOverallStats(fetchReviewParams.sources);
  const {
    ratingBreakdown,
    isLoading: isBreakdownLoading,
    refreshBreakdown,
  } = useRatingBreakdown(fetchReviewParams.sources, ratingType);

  const filterValues: FilterValues = useMemo(
    () =>
      R.pick(
        ['timeFrame', 'toDate', 'fromDate', 'ratings', 'status', 'category'],
        fetchReviewParams,
      ),
    [fetchReviewParams],
  );

  const selectedRatings = useMemo(() => {
    if (filterValues.ratings.length === 0) {
      return 'all';
    }
    const ratingMap = {
      one: 'oneStar',
      two: 'twoStars',
      three: 'threeStars',
      four: 'fourStars',
      five: 'fiveStars',
    };
    return filterValues.ratings.map((r) => ratingMap[r]);
  }, [filterValues.ratings]);

  const handleRatingSelect = ({ star, isSelected }) => {
    const ratingMap = {
      oneStar: 'one',
      twoStars: 'two',
      threeStars: 'three',
      fourStars: 'four',
      fiveStars: 'five',
    };

    const mappedStar = ratingMap[star];
    const all = ['one', 'two', 'three', 'four', 'five'];

    if (selectedRatings === 'all') {
      setFetchReviewParams({
        ...fetchReviewParams,
        ratings: R.without(mappedStar, all),
      });
      return;
    }

    setFetchReviewParams({
      ...fetchReviewParams,
      ratings: isSelected
        ? R.without(mappedStar, fetchReviewParams.ratings)
        : R.append(mappedStar, fetchReviewParams.ratings),
    });
  };

  const handleSearch = (searchValue: string) => {
    setFetchReviewParams(R.assoc('search', searchValue, fetchReviewParams));
  };

  const handleLoadMore = () => {
    const currentPageSize = fetchReviewParams.pageSize ?? 0;
    setFetchReviewParams(
      R.assoc(
        'pageSize',
        currentPageSize + constants.PAGE_SIZE_INCREASE,
        fetchReviewParams,
      ),
    );
  };

  const handleSortingOptionChange = (newVal: string) => {
    const orderParams = sortingValueToOrderParamsMap[newVal];

    if (!orderParams) return;

    setSortingValue(newVal);
    setFetchReviewParams({
      ...fetchReviewParams,
      ...orderParams,
    });
  };

  const handleFilterValuesChange = (key: string, value: any) => {
    setFetchReviewParams({
      ...fetchReviewParams,
      [key]: value,
    });
  };

  const handleDateFilterTypeChange = (type: DateFilterType) => {
    setDateFilterType(type);

    if (type === 'timeframe')
      setFetchReviewParams({
        ...fetchReviewParams,
        toDate: undefined,
        fromDate: undefined,
      });
    else if (type === 'range')
      setFetchReviewParams({
        ...fetchReviewParams,
        timeFrame: undefined,
      });
  };

  const handleClearAllFilters = () => {
    const clearedFilters =
      dateFilterType === 'range'
        ? { ...defaultFilters, timeFrame: undefined }
        : defaultFilters;

    setFetchReviewParams({
      ...fetchReviewParams,
      ...clearedFilters,
    });
  };

  const handleRemoveFilter = ({ meta: { key, value, isDateRangeFilter } }) => {
    const ratingMap = {
      '1 Star': 'one',
      '2 Stars': 'two',
      '3 Stars': 'three',
      '4 Stars': 'four',
      '5 Stars': 'five',
    };

    if (isDateRangeFilter)
      setFetchReviewParams({
        ...fetchReviewParams,
        toDate: defaultFilters.toDate,
        fromDate: defaultFilters.fromDate,
      });
    else if (key === 'ratings')
      setFetchReviewParams({
        ...fetchReviewParams,
        ratings: R.without(ratingMap[value], fetchReviewParams.ratings),
      });
    else
      setFetchReviewParams({
        ...fetchReviewParams,
        [key]: defaultFilters[key],
      });
  };

  const handleFilterToggle = (newValue: boolean) => {
    setShowFilters(newValue);
  };

  const handleResponseDeleteSuccess = useCallback(() => {
    refreshReviews();
    refreshStats();
    refreshBreakdown();
  }, [refreshReviews, refreshStats, refreshBreakdown]);

  const handleSourceChange = (newSource: string) => {
    setFetchReviewParams({
      ...fetchReviewParams,
      ...defaultFilters,
      sources: [newSource],
    });
  };

  const handleResponseSubmitSuccess = useCallback(
    (reviewId: string, response: ReviewResponse) => {
      updateReviewResponse(reviewId, response);
    },
    [updateReviewResponse],
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <OverallAvgRatingCard
          ratingBreakdown={ratingBreakdown || undefined}
          avgRating={overallStats?.avgRating}
          reviewCount={overallStats?.reviewCount}
          sourceCount={overallStats?.sourceCount}
          ratingCount={overallStats?.ratingCount}
          recommendedPercent={overallStats?.recommendedPercent}
          notRecommendedPercent={overallStats?.notRecommendedPercent}
          recommendedCount={overallStats?.recommendedCount}
          notRecommendedCount={overallStats?.notRecommendedCount}
          showSpinner={isBreakdownLoading || isOverallStatsLoading}
          selectedRatings={selectedRatings}
          onRatingSelect={handleRatingSelect}
          selectedSource={selectedSource}
          onSourceChange={handleSourceChange}
          ratingType={ratingType}
        />
      </Grid>
      <Grid item xs={12}>
        <ReviewsCard
          reviews={reviewData?.reviews ?? []}
          avgRating={reviewData?.avgRating ?? 0}
          recommendedPercent={reviewData?.recommendedPercent ?? 0}
          totalReviews={reviewData?.reviewCount ?? 0}
          showSpinner={isReviewDataLoading}
          onSearch={handleSearch}
          onLoadMore={handleLoadMore}
          selectedSortingOption={sortingValue}
          onSortingOptionChange={handleSortingOptionChange}
          filterValues={filterValues}
          onFilterValuesChange={handleFilterValuesChange}
          dateFilterType={dateFilterType}
          onDateFilterTypeChange={handleDateFilterTypeChange}
          onClearAllFilters={handleClearAllFilters}
          onRemoveFilter={handleRemoveFilter}
          showFilters={showFilters}
          onFilterToggle={handleFilterToggle}
          onResponseDeleteSuccess={handleResponseDeleteSuccess}
          ratingType={ratingType}
          onResponseSubmitSuccess={handleResponseSubmitSuccess}
        />
      </Grid>
    </Grid>
  );
}

export default ReputationMgmtFullContent;
