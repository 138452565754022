import React, { Component } from 'react';
import {
  Box,
  Typography,
  Button,
  Stack,
  IconButton,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { TrashIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Col, FormGroup, HelpBlock, Modal, Row } from 'react-bootstrap';
import { confirmable, createConfirmation } from 'react-confirm';

import messages from './messages';
import type { Activity } from '../../containers/ProspectProfile/types';
import ElementWithPermissions from '../../components/ElementWithPermissions';
import LinkedElementWithPermissions from '../../components/LinkedElementWithPermissions';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import * as dateHelpers from '../../utils/date-helpers';

type State = {
  showDeleteConfirmation: boolean,
  showCancelConfirmation: boolean,
  notes: string,
  notesEmpty: boolean,
  activityWasUpdated: boolean,
  notesCharLimit: number,
  notesCharLimitExceeded: boolean,
};

type Props = {
  show: boolean,
  dismiss: Function,
  proceed: Function,
  intl: Object,
  activity: Activity,
  store: Object,
  stageInfo: Object,
};

export class EditCompletedActivityModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showDeleteConfirmation: false,
      showCancelConfirmation: false,
      notes: props.activity.notes,
      notesEmpty: false,
      activityWasUpdated: false,
      notesCharLimit: 2000,
      notesCharLimitExceeded: false,
    };
  }

  componentDidMount() {
    window.onpopstate = (event) => {
      this.props.dismiss();
    };
  }

  deleteConfirmationToggle = (visible: boolean) => {
    this.setState({
      showDeleteConfirmation: visible,
    });
  };

  cancelConfirmationToggle = (visible: boolean) => {
    this.setState({
      showCancelConfirmation: visible,
    });
  };

  confirmDelete = () => {
    this.props.proceed(this.props.activity);
  };

  saveAndClose = (notes: string) => {
    this.props.proceed({ ...this.props.activity, notes, saveAndClose: true });
  };

  saveAndAddNew = (notes: string) => {
    this.props.proceed({ ...this.props.activity, notes, saveAndAddNew: true });
  };

  handleChange = (event: any) => {
    if (event.target.value.length > 1979) {
      this.setState({ notesCharLimitExceeded: true });
    } else {
      this.setState({ notesCharLimitExceeded: false });
    }

    this.setState({
      notes: event.target.value,
      activityWasUpdated: true,
      notesEmpty: event.target.value.length === 0,
    });
  };
  cancel = () => {
    if (this.state.showDeleteConfirmation || this.state.showCancelConfirmation)
      return;
    if (this.state.activityWasUpdated) {
      this.cancelConfirmationToggle(true);
    } else {
      this.props.dismiss();
    }
  };
  renderDeleteConfirmation = () => {
    return (
      <div className="modal-confirm">
        <Typography variant={'h1'}>
          {this.props.intl.formatMessage(messages.deleteConfirmationHeader)}
        </Typography>
        <Typography paragraph>
          {this.props.intl.formatMessage(messages.deleteConfirmationText, {
            noText: this.props.intl.formatMessage(messages.no),
          })}
        </Typography>
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Button
            variant={'delete'}
            onClick={this.confirmDelete}
            sx={{ width: 180 }}
          >
            {this.props.intl.formatMessage(messages.yes)}
          </Button>
          <Button
            variant={'default'}
            onClick={() => this.deleteConfirmationToggle(false)}
            sx={{ width: 180 }}
          >
            {this.props.intl.formatMessage(messages.no)}
          </Button>
        </Stack>
      </div>
    );
  };
  renderCancelConfirmation = () => {
    return (
      <div className="modal-confirm">
        <Typography variant={'h1'}>
          {this.props.intl.formatMessage(messages.cancelConfirmationHeader)}
        </Typography>
        <Col xs={12} sm={6}>
          <Button variant={'delete'} onClick={this.props.dismiss}>
            {this.props.intl.formatMessage(messages.yes)}
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            variant="default"
            onClick={() => this.cancelConfirmationToggle(false)}
          >
            {this.props.intl.formatMessage(messages.no)}
          </Button>
        </Col>
      </div>
    );
  };

  render() {
    const activityType = this.props.activity.activityType || { name: '' };
    const completionStatus = this.props.activity.completionStatus || {
      name: '',
    };
    const scheduledBy = this.props.activity.ownedBy
      ? `${this.props.activity.ownedBy.firstName} ${this.props.activity.ownedBy.lastName}`
      : '---';
    const updatedAt = this.props.activity.updatedAt || new Date();
    const updatedByName = this.props.activity.updatedBy
      ? `${this.props.activity.updatedBy.firstName} ${this.props.activity.updatedBy.lastName}`
      : '---';
    const disabledButtonValidation =
      this.props.activity.notes === this.state.notes ||
      this.state.notesEmpty ||
      this.state.showCancelConfirmation ||
      this.state.showDeleteConfirmation;
    const stageInfo =
      this.props.stageInfo.currentStage === 'applicant'
        ? 'application'
        : this.props.stageInfo.currentStage;

    return (
      <Modal backdrop bsSize="lg" show={this.props.show} onHide={this.cancel}>
        <Modal.Header closeButton>
          <i className="et-calendar" />
          <Modal.Title componentClass="h1">
            {this.props.intl.formatMessage(messages.header)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showDeleteConfirmation && this.renderDeleteConfirmation()}
          {this.state.showCancelConfirmation && this.renderCancelConfirmation()}
          <Row>
            <Col xs={12} md={6}>
              <Typography variant={'h4'}>
                {this.props.intl.formatMessage(messages.activityDetails)}
              </Typography>
            </Col>
            <Col xs={12} md={6}>
              <div>
                <Typography variant={'h4'}>
                  {this.props.intl.formatMessage(
                    messages[this.props.stageInfo.currentStage],
                  )}
                  :{' '}
                  <LinkedElementWithPermissions
                    scope={[
                      'prospect-create',
                      'prospect-read',
                      'prospect-update',
                      'prospect-assign',
                    ]}
                    store={this.props.store}
                    link={getUrlWithSelectedPropertyId(
                      `/${stageInfo}/${this.props.stageInfo.urlId || ''}`,
                    )}
                  >
                    {`${this.props.activity.prospect.firstName || '---'} ${
                      this.props.activity.prospect.lastName || '---'
                    }`}
                  </LinkedElementWithPermissions>
                </Typography>
              </div>
            </Col>
          </Row>
          <Row
            className={
              this.state.showDeleteConfirmation ||
              this.state.showCancelConfirmation
                ? 'hide'
                : ''
            }
          >
            <Col xs={12} sm={6}>
              <Typography variant={'formSubheading'}>
                {this.props.intl.formatMessage(messages.recordedActivity)}
              </Typography>
              <ul className="list-no-border">
                <li className="scheduled-info--basic">
                  <i className="et-team" />
                  <Typography variant={'inputLabel'} pr={1}>
                    {this.props.intl.formatMessage(messages.activityType)}:
                  </Typography>
                  <Typography component={'span'}>
                    {activityType.name}
                  </Typography>
                </li>
                <li className="scheduled-info--basic">
                  <i className="et-calendar" />
                  <Typography variant={'inputLabel'} pr={1}>
                    {this.props.intl.formatMessage(messages.activityDate)}:
                  </Typography>
                  <Typography component={'span'}>
                    {dateHelpers.formatDateUTC(this.props.activity.startTime)}
                  </Typography>
                </li>
                <li className="scheduled-info--basic">
                  <i className="et-clock" />
                  <Typography variant={'inputLabel'} pr={1}>
                    {this.props.intl.formatMessage(messages.activityTime)}:
                  </Typography>
                  <Typography component={'span'}>
                    {dateHelpers.formatTime(
                      this.props.activity.startTime,
                      this.props.intl,
                    )}
                  </Typography>
                </li>
              </ul>
              <ul className="list-no-border">
                <li className="scheduled-info--basic">
                  <Typography variant="inputLabel" pr={1}>
                    {this.props.intl.formatMessage(messages.howWasCompleted)}:
                  </Typography>
                  <Typography component={'span'}>
                    {completionStatus.name}
                  </Typography>
                </li>
                <li className="scheduled-info--basic">
                  <Typography variant="inputLabel" pr={1}>
                    {this.props.intl.formatMessage(messages.scheduledBy)}:
                  </Typography>
                  <Typography component={'span'}>{scheduledBy}</Typography>
                </li>
                <li className="scheduled-info--basic">
                  <Typography variant="inputLabel" pr={1}>
                    {this.props.intl.formatMessage(messages.completedBy)}:
                  </Typography>
                  <Typography component={'span'}>{updatedByName}</Typography>
                </li>
                <li className="scheduled-info--basic">
                  <Typography variant={'caption'}>
                    <Typography component={'span'} pr={1}>
                      {this.props.intl.formatMessage(messages.note)}:
                    </Typography>{' '}
                    {this.props.intl.formatMessage(messages.noteContent)}
                  </Typography>
                </li>
              </ul>
            </Col>
            <Col xs={12} sm={6}>
              <Row>
                <Col xs={12}>
                  <Typography variant={'inputLabel'}>
                    {this.props.intl.formatMessage(messages.editNotes)}
                  </Typography>
                  <FormGroup
                    validationState={
                      this.state.notes.length > 1979 ? 'warning' : null
                    }
                  >
                    <textarea
                      value={this.state.notes}
                      onChange={this.handleChange}
                      className={[
                        'form-control padtop10',
                        this.state.notesEmpty ? 'border-red' : '',
                      ].join(' ')}
                      rows="3"
                      placeholder="Start typing..."
                      name="notes"
                      maxLength={2000}
                    />
                    <HelpBlock>
                      {this.state.notesCharLimitExceeded &&
                        this.props.intl.formatMessage(
                          messages.notesLengthWarning,
                          {
                            current: this.state.notes.length,
                            max: this.state.notesCharLimit,
                          },
                        )}
                    </HelpBlock>
                  </FormGroup>

                  {this.state.notesEmpty && (
                    <span className="has-error">Notes are required</span>
                  )}
                  <div className="box-last-edit">
                    <p>
                      Last edit by {updatedByName} on{' '}
                      {this.props.intl.formatDate(updatedAt, {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                      })}{' '}
                      @ {this.props.intl.formatTime(updatedAt)}
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        {!this.state.showDeleteConfirmation &&
          !this.state.showCancelConfirmation && (
            <Modal.Footer>
              <Row>
                <Stack spacing={1} direction={'row'}>
                  <Box flex={2} sx={{ textAlign: 'left' }}>
                    <ElementWithPermissions
                      scope={['activity-delete']}
                      store={this.props.store}
                    >
                      <IconButton
                        variant={'delete'}
                        id="delete-button"
                        onClick={() => this.deleteConfirmationToggle(true)}
                        disabled={
                          this.state.showCancelConfirmation ||
                          this.state.showDeleteConfirmation
                        }
                      >
                        <TrashIcon />
                        <span className="visible-xs">
                          {this.props.intl.formatMessage(messages.delete)}
                        </span>
                      </IconButton>
                    </ElementWithPermissions>
                  </Box>

                  <Button
                    variant="default"
                    onClick={this.cancel}
                    disabled={
                      this.state.showCancelConfirmation ||
                      this.state.showDeleteConfirmation
                    }
                  >
                    {this.props.intl.formatMessage(messages.cancel)}
                  </Button>
                  <ElementWithPermissions
                    scope={['activity-update']}
                    store={this.props.store}
                  >
                    <Button
                      variant={'primary'}
                      id={'save-and-close'}
                      onClick={() => this.saveAndClose(this.state.notes)}
                      disabled={disabledButtonValidation}
                    >
                      {this.props.intl.formatMessage(messages.saveAndClose)}
                    </Button>
                  </ElementWithPermissions>
                  <ElementWithPermissions
                    scope={['activity-update']}
                    store={this.props.store}
                  >
                    <Button
                      variant={'primary'}
                      id={'save-and-add-new'}
                      onClick={() => this.saveAndAddNew(this.state.notes)}
                      disabled={disabledButtonValidation}
                    >
                      {this.props.intl.formatMessage(messages.saveAndAddNew)}
                    </Button>
                  </ElementWithPermissions>
                </Stack>
              </Row>
            </Modal.Footer>
          )}
      </Modal>
    );
  }
}
// }

const InjectedEditCompletedActivityModal = confirmable(
  EditCompletedActivityModal,
);

const dialog = createConfirmation(InjectedEditCompletedActivityModal);

export const editCompletedActivity = (
  store: any,
  intl: Object,
  activity: Activity,
  stageInfo: Object,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    dialog({
      store,
      activity,
      stageInfo,
      intl,
    }).then(
      (data) => resolve(data),
      (error) => reject(error),
    );
  });
};
