import React from 'react';
import { Row, Col } from 'react-bootstrap';
import affordable from './affordable.json';
import conventional from './conventional.json';

type Props = {
  locale: string,
  applicationIncomeMultiplier: string,
  isAffordable?: boolean,
};

const LeaseApplicationGuidelines = ({
  locale,
  applicationIncomeMultiplier,
  isAffordable = false,
}: Props) => {
  const source = isAffordable ? affordable : conventional;
  const localizedText = source[locale] || source['en'];
  return (
    <React.Fragment>
      <Row className="leaseapp-header print-only">
        <Col xs={12} md={12} className="instructions">
          <h2 className="print-only">Qualifications and guidelines</h2>
          {'Thank you for your interest in our community. '}
          {'We look forward to you calling our community home. '}
          {"Let's get started on the first step to making that happen!"}
        </Col>
      </Row>
      <Row className="leaseapp-guidelines print-only">
        <div className="guidelines-container">
          {localizedText.sections.map((section, sectionIndex) => (
            <React.Fragment key={`s___${sectionIndex}`}>
              <div className="subheading">
                <h4>{section.title}</h4>
                {section.description && <h4>{section.description}</h4>}
              </div>
              {section.subSections.map((subSection, subSectionIndex) => (
                <React.Fragment
                  key={`s___${sectionIndex}___sS___${subSectionIndex}`}
                >
                  <h5>{subSection.title}</h5>
                  {subSection.paragraphs.map((paragraph, paragraphIndex) => (
                    <p
                      className="indented"
                      key={`s___${sectionIndex}___sS___${subSectionIndex}___par___${paragraphIndex}`}
                    >
                      {paragraph.replace(
                        '{applicationIncomeMultiplier}',
                        applicationIncomeMultiplier.toLowerCase(),
                      )}
                    </p>
                  ))}
                </React.Fragment>
              ))}
              {section.pageBreakAfter && <hr className="breakpage" />}
            </React.Fragment>
          ))}
        </div>
      </Row>
    </React.Fragment>
  );
};

export default LeaseApplicationGuidelines;
