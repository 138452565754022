import React, { useMemo } from 'react';
import _ from 'lodash';
import { FormattedDate } from 'react-intl';
import messages from './messages';
import type { Prospect } from '../../ProspectProfile/types';
import { Col, Row } from 'react-bootstrap';
import type { ContactType, PetType, ReferralType } from '../../App/types';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import { pathOr } from 'ramda';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { formatNBedsArr } from '../../../utils/prospectPreferences-helpers';
import { getFloorPlanName } from '../../../utils/floorPlan';
import { formatDateUTC } from '../../../utils/date-helpers';
import { getDesiredMoveInDate } from '../../ManageProspects/utils';

type Props = {
  intl: Object,
  prospect: Prospect,
  petTypes: Array<PetType>,
  contactTypes: Array<ContactType>,
  referralTypes: Array<ReferralType>,
  handleProspectInfoEdit?: Function,
  floorPlans: string[],
};

type OverViewProps = {
  intl: Object,
  prospect: Prospect,
  contactTypes: Array<ContactType>,
  referralTypes: Array<ReferralType>,
  handleProspectInfoEdit?: Function,
};

const dashesIfNullOrUndefined = (data: string | number) =>
  !data || data === null ? '---' : data;

export const Overview = ({
  intl,
  prospect,
  contactTypes,
  referralTypes,
  handleProspectInfoEdit,
  floorPlans,
}: OverViewProps) => {
  const { propertySettingApplyWithoutAUnit } = useFlags();

  const { prospectPreferences } = prospect;
  const emptyContactOrReferral = {
    id: 'default',
    name: '---',
    translations: { en: '---', es: '---' },
  };
  // $FlowFixMe
  const preferences = pathOr({}, ['prospectPreferences'], prospect);
  const contactType =
    _.find(contactTypes, ['id', prospect.contactTypeId]) ||
    emptyContactOrReferral;
  const referralType =
    _.find(referralTypes, ['id', prospect.referralTypeId]) ||
    emptyContactOrReferral;
  const { floorPlan, nBeds, nBedsArr } = useMemo(() => {
    return {
      nBeds: dashesIfNullOrUndefined(prospectPreferences.nBeds),
      nBedsArr: formatNBedsArr(
        prospectPreferences?.nBedsArr,
        prospectPreferences?.nBeds,
      ),
      floorPlan: getFloorPlanName({
        floorPlans,
        preferredFloorPlanId: prospectPreferences.preferredFloorPlanId,
      }),
    };
  }, [
    floorPlans,
    prospectPreferences.nBeds,
    prospectPreferences?.nBedsArr,
    prospectPreferences.preferredFloorPlanId,
  ]);
  const nBaths = dashesIfNullOrUndefined(pathOr('', ['nBaths'], preferences));
  const nHalfBaths = dashesIfNullOrUndefined(
    pathOr('', ['nHalfBaths'], preferences),
  );
  const helpText = dashesIfNullOrUndefined(
    pathOr('', ['helpText'], preferences),
  );
  const wants = dashesIfNullOrUndefined(pathOr('', ['wants'], preferences));
  const notWants = dashesIfNullOrUndefined(
    pathOr('', ['notWants'], preferences),
  );
  const { assigningUnitsMoveInDates: assigningUnitsMoveInDatesFlag } =
    useFlags();
  const { moveInDateFrom, moveInDateTo } = getDesiredMoveInDate({
    prospectPreferences: prospect?.prospectPreferences,
  });

  return (
    <div>
      <Col xs={12} sm={4}>
        <h1>{intl.formatMessage(messages.overview)}</h1>
      </Col>
      <Col xs={12} sm={8}>
        <div className="btn-toolbar">
          {handleProspectInfoEdit ? (
            <ElementWithPermissions scope={['prospect-update']}>
              <button
                className="btn btn-primary--ghost pull-right"
                onClick={handleProspectInfoEdit}
              >
                <i className="et-pencil icon" />
                {intl.formatMessage(messages.editInfo)}
              </button>
            </ElementWithPermissions>
          ) : (
            ''
          )}
        </div>
      </Col>
      <div className="divider" />
      <Col xs={12}>
        <h3>{intl.formatMessage(messages.introductoryNote)}</h3>
        <p>
          {helpText
            .toString()
            .split('\n')
            .map((line, i) => (
              <span key={i}>
                {line}
                <br />
              </span>
            ))}
        </p>
      </Col>
      <div className="divider" />
      <Col xs={12}>
        <h3>{intl.formatMessage(messages.details)}</h3>
        <section id="section-details">
          <Row>
            <Col xs={12} sm={3} className="padbottom10">
              <label>{intl.formatMessage(messages.noBeds)}</label>
              <p>{propertySettingApplyWithoutAUnit ? nBedsArr : nBeds}</p>
            </Col>
            <Col xs={12} sm={3} className="padbottom10">
              <label>{intl.formatMessage(messages.noBaths)}</label>
              <p>{nBaths}</p>
            </Col>
            <Col xs={12} sm={3} className="padbottom10">
              <label># Half Bath</label>
              <p>{nHalfBaths}</p>
            </Col>
            <Col xs={12} sm={3}>
              <label>{intl.formatMessage(messages.moveInDate)}</label>
              {assigningUnitsMoveInDatesFlag ? (
                <p>
                  {moveInDateFrom ? (
                    <FormattedDate
                      value={formatDateUTC(moveInDateFrom)}
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                    />
                  ) : (
                    intl.formatMessage(messages.noMoveInDate)
                  )}
                  {moveInDateFrom && moveInDateTo ? (
                    <span>
                      {' '}
                      -{' '}
                      <FormattedDate
                        value={formatDateUTC(moveInDateTo)}
                        year="numeric"
                        month="2-digit"
                        day="2-digit"
                      />{' '}
                    </span>
                  ) : (
                    ''
                  )}
                </p>
              ) : (
                <p>
                  {preferences.moveInDateFrom ? (
                    <FormattedDate
                      value={formatDateUTC(preferences.moveInDateFrom)}
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                    />
                  ) : (
                    intl.formatMessage(messages.noMoveInDate)
                  )}
                  {preferences.moveInDateTo && preferences.moveInDateFrom ? (
                    <span>
                      {' '}
                      -{' '}
                      <FormattedDate
                        value={formatDateUTC(preferences.moveInDateTo)}
                        year="numeric"
                        month="2-digit"
                        day="2-digit"
                      />{' '}
                    </span>
                  ) : (
                    ''
                  )}
                </p>
              )}
            </Col>
          </Row>
          <Row>
            {propertySettingApplyWithoutAUnit && (
              <Col xs={12} sm={3}>
                <label>{intl.formatMessage(messages.floorPlan)}</label>
                <p>{floorPlan}</p>
              </Col>
            )}
            <Col xs={12} sm={3}>
              <label>{intl.formatMessage(messages.priceRange)}</label>
              <p>
                {preferences.priceFrom ? `$${preferences.priceFrom}` : '?'} -{' '}
                {preferences.priceTo ? `$${preferences.priceTo}` : '?'}
              </p>
            </Col>
            <Col xs={12} sm={3}>
              <label>{intl.formatMessage(messages.leaseTerm)}</label>
              <p>
                {preferences.leaseTermInMonths
                  ? `${preferences.leaseTermInMonths} months`
                  : '---'}
              </p>
            </Col>
          </Row>
          <div className="divider" />
          <Col xs={12}>
            <h3>{intl.formatMessage(messages.wants)}</h3>
            <p>
              {wants
                .toString()
                .split('\n')
                .map((line, i) => (
                  <span key={i}>
                    {line}
                    <br />
                  </span>
                ))}
            </p>
          </Col>
          <div className="divider" />
          <Col xs={12}>
            <h3>{intl.formatMessage(messages.doesntWant)}</h3>
            <p>
              {notWants
                .toString()
                .split('\n')
                .map((line, i) => (
                  <span key={i}>
                    {line}
                    <br />
                  </span>
                ))}
            </p>
          </Col>
          <div className="divider" />
          <Col xs={12}>
            <h3>{intl.formatMessage(messages.contactType)}</h3>
            <section id="contacttype">
              <p>{contactType.name}</p>
            </section>
          </Col>
          <Col xs={12}>
            <h3>{intl.formatMessage(messages.source)}</h3>
            <section id="source">
              <p>{referralType.name}</p>
            </section>
          </Col>
        </section>
      </Col>
    </div>
  );
};

const ProspectInfoTab = ({
  intl,
  prospect,
  petTypes,
  contactTypes,
  referralTypes,
  handleProspectInfoEdit,
  floorPlans,
  scheduledMoveInDate,
}: Props) => {
  return (
    <div className="tab-pane active" id="1a">
      <Overview
        intl={intl}
        prospect={prospect}
        contactTypes={contactTypes}
        referralTypes={referralTypes}
        handleProspectInfoEdit={handleProspectInfoEdit}
        floorPlans={floorPlans}
      />
    </div>
  );
};

export default ProspectInfoTab;
