import React, { useState } from 'react';
import {
  Modal,
  Row,
  Col,
  ButtonToolbar,
  Button,
  Checkbox,
} from 'react-bootstrap';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import SubmitButton from '../../../components/SubmitButton';

type Props = {
  onHide: Function,
  onSubmit: Function,
  show: boolean,
};

const ConfirmFASModal = (props: Props) => {
  const { onHide, onSubmit, show } = props;
  const [isClicked, setIsClicked] = useState(false);
  const [sendEmailSelected, setSendEmailSelected] = useState(false);
  const toggleCheckbox = () => {
    setSendEmailSelected(!sendEmailSelected);
  };
  return (
    <div className="static-modal">
      <Modal
        bsSize="small"
        dialogClassName="modal-dialog modal-alert"
        show={show}
        onHide={onHide}
        autoFocus={true}
        data-test="fas-modal"
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <h2 className="text-center">
                <FormattedMessage {...messages.title} />
              </h2>
              <p className="text-center">
                <FormattedMessage {...messages.bodyonce} />
                <br />
                <FormattedMessage {...messages.body} />
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-center">
              <Checkbox
                value={sendEmailSelected || false}
                checked={sendEmailSelected || false}
                className="custom-checkbox flying-checkbox"
                data-test="email-chkbox"
                onClick={() => toggleCheckbox()}
              >
                <span className="custom-check-square" />
                <span
                  style={sendEmailSelected ? { 'font-weight': 'bold' } : {}}
                >
                  <FormattedMessage {...messages.emailConfirmation} />
                </span>
              </Checkbox>
            </Col>
          </Row>
          <Row></Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button
              type="button"
              bsStyle="default"
              data-test="btn-cancel"
              onClick={() => {
                onHide();
                setSendEmailSelected(false);
              }}
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
            <SubmitButton
              type="submit"
              bsStyle="primary"
              classes="pull-left"
              data-test="btn-submit"
              clickHandler={() => {
                setIsClicked(true);
                onSubmit(sendEmailSelected);
              }}
              isSubmitting={isClicked}
            >
              <FormattedMessage {...messages.confirm} />
            </SubmitButton>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmFASModal;
