import type { Disability, HasDisability } from './types';

export const getDisabilityValues = (
  initialValues: Disability,
  currentValues: Disability,
  hasDisability: HasDisability,
): Disability => {
  const result: Disability = {
    disabilityHearing: undefined,
    disabilityMobile: undefined,
    disabilityOther: undefined,
    disabilityVisual: undefined,
  };
  const generalValue = hasDisability === 'notWishToProvide' ? null : 'no';
  const isYesValue = hasDisability === 'yes';

  Object.keys(result).forEach((key: string) => {
    const disabilityValue = currentValues?.[key] ?? initialValues?.[key];
    result[key] = isYesValue ? disabilityValue : generalValue;
  });

  return result;
};
