import type { FilterValues, ChangeEventHandler, Option } from './types';
import MultiSelectControl from './MultiSelectControl';
import DateRangeControl from './DateRangeControl';

type Props = {
  floorPlanOptions: Option[],
  unitStatusOptions: Option[],
  bedroomOptions: Options[],
  bathroomOptions: Option[],
  values: FilterValues,
  onChange: ChangeEventHandler,
};

const MULTI_SELECT_CONTROL_WIDTH = '15%';
const DATE_CONTROL_WIDTH = '30%';

const renderBedsValue = (selected: number[]) => {
  if (!selected) return;
  return selected.map((v) => (v === 1 ? '1 bed' : `${v} beds`)).join(',');
};

const renderBathsValue = (selected: number[]) => {
  if (!selected) return;
  return selected.map((v) => (v === 1 ? '1 bath' : `${v} baths`)).join(',');
};

function FilterControls({
  floorPlanOptions,
  floorPlanTypeOptions,
  unitStatusOptions,
  bedroomOptions,
  bathroomOptions,
  onChange,
  values,
}: Props) {
  const handleMultiSelectChange = (key: string) => (e: Object) => {
    onChange(key, {
      type: 'multi-select',
      values: e.target.value,
    });
  };
  const handleDateChange = (key: string) => (newValue: Object | string) => {
    onChange(key, {
      type: 'date',
      value:
        typeof newValue === 'string' ? newValue : newValue.format('MM/DD/YYYY'),
    });
  };

  const shouldShowFloorPlanType = floorPlanTypeOptions.length > 1;

  return (
    <div>
      <div>
        <h5 style={{ color: '#4D73FF' }}>Filter By</h5>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <MultiSelectControl
          name="floorPlan"
          label="Floor Plan"
          placeholder="All Floor Plans"
          aria-label="floorplan-filter"
          options={floorPlanOptions}
          onChange={handleMultiSelectChange('floorPlan')}
          value={values.floorPlan}
          width={MULTI_SELECT_CONTROL_WIDTH}
        />

        {shouldShowFloorPlanType && (
          <MultiSelectControl
            name="floorPlanType"
            label="Floor Plan Type"
            placeholder="All Floor Plan Types"
            aria-label="all-floor-plan-types"
            options={floorPlanTypeOptions}
            onChange={handleMultiSelectChange('floorPlanType')}
            value={values.floorPlanType}
            width={MULTI_SELECT_CONTROL_WIDTH}
          />
        )}
        <MultiSelectControl
          name="unitStatus"
          label="Unit Status"
          placeholder="All Unit Statuses"
          aria-label="unit-status-filter"
          options={unitStatusOptions}
          onChange={handleMultiSelectChange('unitStatus')}
          value={values.unitStatus}
          width={MULTI_SELECT_CONTROL_WIDTH}
        />
        <DateRangeControl
          label="Move-In Date"
          from={values.moveInDateFrom}
          to={values.moveInDateTo}
          fromPlaceholder="From"
          toPlaceholder="To"
          onFromChange={handleDateChange('moveInDateFrom')}
          onToChange={handleDateChange('moveInDateTo')}
          width={DATE_CONTROL_WIDTH}
        />
        <MultiSelectControl
          name="bedrooms"
          label="Bedrooms"
          placeholder="All Bedrooms"
          aria-label="beds-filter"
          options={bedroomOptions}
          onChange={handleMultiSelectChange('bedrooms')}
          value={values.bedrooms}
          width={MULTI_SELECT_CONTROL_WIDTH}
          renderValue={renderBedsValue}
        />
        <MultiSelectControl
          name="bathrooms"
          label="Bathrooms"
          placeholder="All Bathrooms"
          aria-label="baths-filter"
          options={bathroomOptions}
          onChange={handleMultiSelectChange('bathrooms')}
          value={values.bathrooms}
          width={MULTI_SELECT_CONTROL_WIDTH}
          renderValue={renderBathsValue}
        />
      </div>
    </div>
  );
}

export default FilterControls;
