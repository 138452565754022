import messages from '../messages';
import type { Highlight, RenewalsInProcessEntity, Resident } from '../types';
import moment from 'moment';
import { getHighlightColor } from './general/getHighlightColor';
import { getUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';

type RenewalInProcessHeader = {
  id: number,
  text: Object,
  sortKey: 'unit' | 'resident' | 'leaseExpiration' | 'assignedTo',
};

type MappedRenewalsInProcess = {
  id: string,
  unit: string,
  resident: Resident,
  leaseExpiration: string,
  assignedTo: string,
  highlight: Highlight,
};

export const mapRenewalsInProcess = (
  renewalsInProcess: RenewalsInProcessEntity[],
): MappedRenewalsInProcess[] => {
  return renewalsInProcess.map((task) => {
    const endDate = task.end_date;
    const userAssigned = task.assigned_to;
    const primaryResident = task.name;
    const p2 = primaryResident
      ? {
          link: getUrlWithSelectedPropertyId(`/resident/${task.resident_id}`),
          value: primaryResident,
        }
      : 'N/A';

    const isLeaseSignedAndNotExecuted =
      task.leaseSignature && task.action !== 'EXECUTED';

    const highlight = getHighlightColor({
      checkDate: task.end_date,
      status: task.userStatus,
      isLeaseSignedAndNotExecuted,
    });

    return {
      id: task.id,
      unit: task.unit,
      resident: p2,
      leaseExpiration: endDate ? moment(endDate).format('MM/DD/YYYY') : 'N/A',
      assignedTo: userAssigned ? userAssigned : 'N/A',
      highlight,
    };
  });
};

export const renewalsInProcessHeaders = (
  intl: Object,
): RenewalInProcessHeader[] => {
  return [
    {
      id: 1,
      text: intl.formatMessage(messages.unitHeader),
      sortKey: 'unit',
    },
    {
      id: 2,
      text: intl.formatMessage(messages.residentHeader),
      sortKey: 'resident',
    },
    {
      id: 3,
      text: intl.formatMessage(messages.leaseExpirationHeader),
      sortKey: 'leaseExpiration',
    },
    {
      id: 4,
      text: intl.formatMessage(messages.assignedToHeader),
      sortKey: 'assignedTo',
    },
  ];
};
