import { put, takeLatest, delay } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import { FORGOT_PASSWORD_EMAIL_SENT } from './constants';
import {
  sendForgotPasswordEmailError,
  clearMessages,
  sendForgotPasswordEmailSuccess,
} from './actions';
import UserService from '../../services/userService';
import type { Action } from '../App/types';
import type { ForgotPasswordPayload } from './types';
import type { Saga } from 'redux-saga';
import messages from './messages';

export function* fetchForgotPassword(
  action: Action<ForgotPasswordPayload>,
): Saga<void> {
  try {
    const userService = new UserService();
    yield userService.sendResetPasswordToken(action.payload);
    yield put(sendForgotPasswordEmailSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.emailSent),
        title: renderTranslatedMessage(messages.emailSentTitle),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(sendForgotPasswordEmailError(err));
    yield delay(5000);
    yield put(clearMessages());
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.emailSentFailure),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* forgotPasswordSaga(): Saga<void> {
  yield takeLatest(FORGOT_PASSWORD_EMAIL_SENT, fetchForgotPassword);
}

export default [forgotPasswordSaga];
