import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { createConfirmation, confirmable } from 'react-confirm';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import moment from 'moment';
import type Moment from 'moment';
import { find, propEq, defaultTo, contains, partial } from 'ramda';
import {
  renderTextAreaField,
  renderSelectField,
  renderDateField,
  renderTimeField,
  renderCheckboxField,
} from '../../utils/redux-form-helper';
import ErrorMessage from '../../components/ErrorMessage';
import messages from './messages';
import validate from './validate';
import warn from './warn';
import asyncValidate from './asyncValidate';

import { bindActionCreators } from 'redux';
import actions from 'redux-form/lib/actions';
import type {
  Prospect,
  Activity,
} from '../../containers/ProspectProfile/types';
import type {
  Property,
  ActivityCompletionStatus,
  DropdownOption,
} from '../../containers/App/types';
import AssigneeCalendar from '../AssigneeCalendar';
import ActivityService from '../../services/activityService';
import ElementWithPermissions from '../../components/ElementWithPermissions';
type State = {
  runInitialAsyncValidation: boolean,
  assigneeActivities: Array<Activity>,
  calendarView: boolean,
};

type ActivityType = {
  completionStatusOptions: Array<ActivityCompletionStatus>,
  canBeOverlapped: boolean,
};

type Props = {
  mode: 'record' | 'schedule',
  valid: boolean,
  warning: string,
  error: Object,
  change: Function,
  asyncValidate: Function,
  show: boolean,
  proceed: Function,
  dismiss: Function,
  intl: Object,
  store: Object,
  assignedToId: string,
  prospect: Prospect,
  activityTypesList: Array<DropdownOption & ActivityType>,
  assigneeList: Array<DropdownOption>,
  stage: string,
  urlId: string,
  onHandleDismiss?: Function,
};

type StateProps = {
  locale: string,
  startDate: Moment,
  startTime: Moment,
  endTime: Moment,
  notes: string,
  activityTypeId: string,
  activityCompletionStatusId: string,
  assignedToId: string,
  allDay: boolean,
  selectedProperty: Property,
  organizationId: string,
};

const DateForRecord = ({ intl, store }: Object) => (
  <Row>
    <Col xs={12} md={6}>
      <h3>{intl.formatMessage(messages.activityDateLabel)}</h3>
      <Field
        store={store}
        name="startDate"
        component={renderDateField}
        classPicker="modal-fixed-position"
        bsSize="lg"
      />
    </Col>
    <Col xs={12} md={6}>
      <h3>{intl.formatMessage(messages.recordTimeLabel)}</h3>
      <Field
        store={store}
        name="startTime"
        component={renderTimeField}
        bsSize="lg"
      />
    </Col>
  </Row>
);

const DateForSchedule = ({ intl, store }: Object) => (
  <Row>
    <Col xs={12}>
      <h3>{intl.formatMessage(messages.activityDateLabel)}</h3>
      <Field
        store={store}
        name="startDate"
        component={renderDateField}
        classPicker="modal-fixed-position"
        bsSize="lg"
      />
    </Col>
  </Row>
);

const TimeForSchedule = ({
  intl,
  store,
  disabled,
  toggleCalendarView,
}: Object) => (
  <Row>
    <Col xs={12} md={6}>
      <h3>{intl.formatMessage(messages.scheduleStartTimeLabel)}</h3>
      <Field
        store={store}
        name="startTime"
        component={renderTimeField}
        bsSize="lg"
        disabled={disabled}
      />
    </Col>
    <Col xs={12} md={6}>
      <h3>{intl.formatMessage(messages.scheduleEndTimeLabel)}</h3>
      <Field
        store={store}
        name="endTime"
        component={renderTimeField}
        bsSize="lg"
        disabled={disabled}
      />
    </Col>
    <Col xs={6}>
      <Field
        store={store}
        name="allDay"
        component={renderCheckboxField}
        bsSize="lg"
        label={intl.formatMessage(messages.noTimeLabel)}
      />
    </Col>
    <Col xs={6}>
      <a className="link-availability pull-right">
        <i className="et-calendar"> </i>
        <span className="small" onClick={() => toggleCalendarView(true)}>
          Check Availability
        </span>
      </a>
    </Col>
  </Row>
);

const ScheduledBy = ({ intl, store, assigneeList }: Object) => (
  <Row>
    <Col xs={12}>
      <h3>{intl.formatMessage(messages.scheduleByLabel)}</h3>
      <Field
        store={store}
        name="assignedToId"
        component={renderSelectField}
        options={assigneeList}
        bsSize="lg"
      />
    </Col>
  </Row>
);

export class ActivityModal extends Component<StateProps & Props, State> {
  constructor(props: StateProps & Props) {
    super(props);
    this.getActivity.bind(this);
    this.generateCompletionStatusList.bind(this);
    this.onActivityTypeChange.bind(this);
    this.state = {
      runInitialAsyncValidation: true,
      calendarView: false,
      assigneeActivities: [],
    };
  }

  componentDidUpdate() {
    if (this.state.runInitialAsyncValidation) {
      this.setState(
        {
          runInitialAsyncValidation: false,
        },
        () => {
          this.props.asyncValidate('startDate', this.props.startDate, 'blur');
        },
      );
    }
  }

  generateCompletionStatusList() {
    let options = [];

    if (this.props.activityTypeId) {
      const selectedActivityType = defaultTo({ completionStatusOptions: [] })(
        find(propEq('value', this.props.activityTypeId))(
          this.props.activityTypesList,
        ),
      );
      const visibleOptions =
        selectedActivityType.completionStatusOptions.filter(
          (option) => option.showForRecord,
        );
      options = visibleOptions.map((option) => ({
        value: option.id,
        text: option.translations[this.props.locale] || option.name,
      }));
    }
    options.unshift({
      value: 'default',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return options;
  }

  generateActivitiesForAssignee(assignedToId: string) {
    const activityService = new ActivityService();
    activityService
      .getByAssignee(
        assignedToId,
        this.props.selectedProperty.id,
        this.props.organizationId,
      )
      .then((response: Array<Activity>) => {
        this.setState({
          assigneeActivities: response,
        });
      })
      .catch(() => {
        this.setState({
          assigneeActivities: [],
        });
      });
  }

  toggleCalendarView(show: boolean) {
    if (show) {
      this.generateActivitiesForAssignee(this.props.assignedToId);
    }
    this.setState({
      calendarView: show,
    });
  }
  getActivity() {
    this.props.startDate.set({
      hour: this.props.allDay ? 0 : this.props.startTime.hours(),
      minute: this.props.allDay ? 0 : this.props.startTime.minutes(),
      second: 0,
      millisecond: 0,
    });

    const endDate = moment(this.props.startDate);

    if (this.props.mode === 'schedule') {
      endDate.set({
        hour: this.props.allDay ? 23 : this.props.endTime.hours(),
        minute: this.props.allDay ? 59 : this.props.endTime.minutes(),
        second: 0,
        millisecond: 0,
      });
    }

    return {
      startTime: this.props.startDate.toDate(),
      endTime: endDate.toDate(),
      activityCompletionStatusId:
        this.props.mode === 'record'
          ? this.props.activityCompletionStatusId
          : undefined,
      notes: this.props?.notes,
      activityTypeId: this.props.activityTypeId,
      prospectId: this.props.prospect.id || '',
      assignedToId: this.props.assignedToId,
      allDay: this.props.allDay,
      propertyId: this.props.selectedProperty.id,
    };
  }
  onActivityTypeChange() {
    if (this.props.mode === 'record') {
      this.props.change('activityCompletionStatusId', 'default');
    }
  }

  onAssignedToChange(event: any, value: string) {
    this.generateActivitiesForAssignee(value);
  }

  render() {
    const assignee = find(propEq('value', this.props.assignedToId))(
      this.props.assigneeList,
    ) || { text: '---' };
    const stage =
      this.props.stage === 'applicant' ? 'application' : this.props.stage;

    return (
      <form>
        <Modal
          backdrop
          bsSize="lg"
          show={this.props.show}
          onHide={this.props.dismiss}
        >
          <Modal.Header closeButton>
            <i
              className={
                this.props.mode === 'schedule' ? 'et-calendar' : 'et-pencil'
              }
            />
            <Modal.Title componentClass="h1">
              {this.props.mode === 'record' &&
                this.props.intl.formatMessage(messages.recordTitle)}
              {this.props.mode === 'schedule' &&
                this.props.intl.formatMessage(messages.scheduleTitle)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-subheader">
              <Row>
                <Col xs={12} md={6}>
                  <h1>
                    {this.props.mode === 'record' &&
                      this.props.intl.formatMessage(messages.activityDetails)}
                    {this.props.mode === 'schedule' &&
                      this.props.intl.formatMessage(messages.schedulingDetails)}
                  </h1>
                  <h5>
                    {this.props.intl.formatMessage(messages.allFieldsRequired)}
                  </h5>
                </Col>
                <Col xs={12} md={6}>
                  <div className="prospect-profile__link">
                    <h4>
                      <strong>
                        {this.props.intl.formatMessage(
                          messages[this.props.stage],
                        )}
                      </strong>{' '}
                      {`${
                        this.props.prospect.firstName
                          ? this.props.prospect.firstName
                          : '--'
                      }
                        ${
                          this.props.prospect.lastName
                            ? this.props.prospect.lastName
                            : '--'
                        }`}
                    </h4>
                    <a
                      className="btn btn-tertiary"
                      href={`/${stage}/${this.props.urlId || ''}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>
                        {this.props.intl.formatMessage(
                          messages.viewProfileButton,
                        )}
                      </span>
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
            {this.props.error && (
              <Row>
                <ErrorMessage message={this.props.error.message} />
              </Row>
            )}
            {this.props.warning && (
              <Row>
                {' '}
                <ErrorMessage type="warning" message={this.props.warning} />
              </Row>
            )}
            {this.state.calendarView && (
              <Row>
                <Col xs={12} sm={6}>
                  <a onClick={() => this.toggleCalendarView(false)}>
                    <i className="et-chevron-left" />
                    Back to follow-up details
                  </a>
                  <h2 className="calendar__title">
                    <span>{`${this.props.intl.formatMessage(
                      messages.calendarFor,
                    )}: ${assignee.text}`}</span>
                  </h2>
                </Col>
                <Col xs={12}>
                  <div className="box-calendar">
                    <AssigneeCalendar
                      defaultView={'week'}
                      intl={this.props.intl}
                      locale={this.props.locale}
                      selectedAssignee={assignee}
                      store={this.props.store}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <Row className={this.state.calendarView ? 'hide' : ''}>
              <form>
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={12}>
                      <Field
                        label={this.props.intl.formatMessage(
                          messages.activityTypeLabel,
                        )}
                        store={this.props.store}
                        name="activityTypeId"
                        component={renderSelectField}
                        options={this.props.activityTypesList}
                        bsSize="lg"
                        onChange={this.onActivityTypeChange.bind(this)}
                      />
                    </Col>
                  </Row>
                  {this.props.mode === 'record' && (
                    <Row>
                      <Col xs={12}>
                        <Field
                          label={this.props.intl.formatMessage(
                            messages.activityCompletionLabel,
                          )}
                          store={this.props.store}
                          name="activityCompletionStatusId"
                          component={renderSelectField}
                          options={this.generateCompletionStatusList()}
                          bsSize="lg"
                        />
                      </Col>
                    </Row>
                  )}
                  {this.props.mode === 'record' && (
                    <DateForRecord
                      intl={this.props.intl}
                      store={this.props.store}
                    />
                  )}
                  {this.props.mode === 'schedule' && (
                    <DateForSchedule
                      intl={this.props.intl}
                      store={this.props.store}
                    />
                  )}
                  {this.props.mode === 'schedule' && (
                    <TimeForSchedule
                      intl={this.props.intl}
                      store={this.props.store}
                      change={this.props.change}
                      disabled={this.props.allDay}
                      toggleCalendarView={this.toggleCalendarView.bind(this)}
                    />
                  )}
                  {this.props.mode === 'record' &&
                    (this.props.assigneeList.length > 0 ? (
                      <ScheduledBy
                        intl={this.props.intl}
                        store={this.props.store}
                        assigneeList={this.props.assigneeList}
                      />
                    ) : (
                      <span className="has-error">
                        {this.props.intl.formatMessage(
                          messages.emptyAssignessListError,
                        )}
                      </span>
                    ))}
                </Col>
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={12}>
                      <h3>
                        {this.props.intl.formatMessage(messages.notesLabel)}
                      </h3>
                      <Field
                        store={this.props.store}
                        name="notes"
                        component={renderTextAreaField}
                        placeholder={this.props.intl.formatMessage(
                          messages.notesPlaceholder,
                        )}
                        rows="3"
                        maxLength="2000"
                      />
                    </Col>
                  </Row>
                  {this.props.mode === 'schedule' &&
                    (this.props.assigneeList.length > 0 ? (
                      <ScheduledBy
                        intl={this.props.intl}
                        store={this.props.store}
                        assigneeList={this.props.assigneeList}
                      />
                    ) : (
                      <span className="has-error">
                        {this.props.intl.formatMessage(
                          messages.emptyAssignessListError,
                        )}
                      </span>
                    ))}
                </Col>
              </form>
            </Row>
          </Modal.Body>
          {!this.state.calendarView && (
            <Modal.Footer>
              <Row>
                <Col xs={6}>
                  <Button
                    bsStyle="default"
                    className="pull-right"
                    onClick={
                      this.props.onHandleDismiss
                        ? partial(this.props.onHandleDismiss, [
                            this.props.dismiss,
                          ])
                        : this.props.dismiss
                    }
                  >
                    {this.props.intl.formatMessage(messages.cancel)}
                  </Button>
                </Col>
                <Col xs={6}>
                  <ElementWithPermissions
                    scope={['activity-create']}
                    store={this.props.store}
                  >
                    <Button
                      bsStyle="primary"
                      className="pull-left"
                      disabled={
                        !this.props.valid ||
                        this.state.calendarView ||
                        this.props.assigneeList.length === 0
                      }
                      onClick={() => this.props.proceed(this.getActivity())}
                    >
                      {this.props.intl.formatMessage(messages.addActivity)}
                    </Button>
                  </ElementWithPermissions>
                </Col>
              </Row>
            </Modal.Footer>
          )}
        </Modal>
      </form>
    );
  }
}

let InjectedActivityModal = reduxForm({
  form: 'recordActivity',
  touchOnChange: true,
  validate: validate,
  warn: warn,
  asyncValidate: asyncValidate,
  shouldAsyncValidate: (params) => {
    if (!params.syncValidationPasses) {
      return false;
    }

    if (params.trigger === 'change') {
      return false;
    }

    if (
      !contains(params.blurredField, [
        'startDate',
        'startTime',
        'endTime',
        'activityTypeId',
        'assignedToId',
      ])
    ) {
      return false;
    }
    return true;
  },
})(ActivityModal);

const selector = formValueSelector('recordActivity');

const mapStateToProps = (state): StateProps => {
  if (state.app.selectedProperty) {
    return {
      locale: state.languageProvider.locale,
      startDate: selector(state, 'startDate'),
      startTime: selector(state, 'startTime'),
      endTime: selector(state, 'endTime'),
      notes: selector(state, 'notes'),
      activities: state.home.activities,
      activityTypeId: selector(state, 'activityTypeId'),
      activityCompletionStatusId: selector(state, 'activityCompletionStatusId'),
      assignedToId: selector(state, 'assignedToId'),
      allDay: selector(state, 'allDay'),
      selectedProperty: state.app.selectedProperty,
      organizationId: state.app.currentUser
        ? state.app.currentUser.user.organizationId
        : '',
    };
  }
  throw new Error('A property must be selected.');
};
const mapDispatchToProps = (dispatch: any): Object => {
  const bindedActions = bindActionCreators(
    { updateSyncWarnings: actions.updateSyncWarnings },
    dispatch,
  );
  return { actions: bindedActions };
};

InjectedActivityModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedActivityModal);

InjectedActivityModal = confirmable(InjectedActivityModal);

const dialog = createConfirmation(InjectedActivityModal);

export const recordActivity = (
  store: any,
  intl: any,
  prospect: Prospect,
  activityTypesList: Array<DropdownOption & ActivityType>,
  assigneeList: Array<DropdownOption>,
  stage: string,
  urlId: string,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    dialog({
      store,
      intl,
      activityTypesList,
      assigneeList,
      stage,
      urlId,
      prospect,
      calendarView: false,
      mode: 'record',
      initialValues: {
        assignedToId:
          (assigneeList || []).length > 0 ? assigneeList[0].value : undefined,
        activityTypeId:
          (activityTypesList || []).length > 0
            ? activityTypesList[0].value
            : undefined,
        activityCompletionStatusId: 'default',
        prospectCreationDate: moment(prospect.createdAt),
        startDate: moment(),
        startTime: moment(),
        allDay: false,
      },
    }).then(
      (data: any) => resolve(data),
      (error: Object) => reject(error),
    );
  });
};

export const scheduleActivity = (
  store: any,
  intl: any,
  prospect: Prospect,
  activityTypesList: Array<DropdownOption & ActivityType>,
  assigneeList: Array<DropdownOption>,
  stage: string,
  urlId: string,
  onHandleDismiss?: Function,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const currentDate = moment();
    const startDate = moment(currentDate).add(
      60 - currentDate.minutes(),
      'minutes',
    );
    const endDate = moment(startDate).add(30, 'minutes');
    dialog({
      store,
      intl,
      activityTypesList,
      assigneeList,
      stage,
      urlId,
      prospect,
      mode: 'schedule',
      ...(onHandleDismiss && { onHandleDismiss }),
      initialValues: {
        assignedToId:
          (assigneeList || []).length > 0 ? assigneeList[0].value : undefined,
        activityTypeId:
          (activityTypesList || []).length > 0
            ? activityTypesList[0].value
            : undefined,
        startDate: startDate,
        startTime: startDate,
        endTime: endDate,
        allDay: false,
      },
    }).then(
      (data: any) => resolve(data),
      (error: Object) => reject(error),
    );
  });
};
