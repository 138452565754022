export const FORM_TYPE = {
  SCHEDULE: 'schedule',
  RECORD: 'record',
};

export const FORM_NAME = {
  SCHEDULE: 'scheduleForm',
  RECORD: 'recordForm',
};

export const STAGE = {
  APPLICANT: 'applicant',
  APPLICATION: 'application',
};
