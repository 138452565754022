import { get, post, put } from '../utils/api';

export default class PropertyGroupService {
  getAllPropertyGroups(organizationId: string, options?: Object) {
    return get(
      `/${organizationId}/property-groups?includeProperties=true`,
      options,
    );
  }

  getById(id: string, organizationId: string, options?: Object) {
    return get(`/${organizationId}/property-group/${id}`, options);
  }

  create(data: Object, organizationId: string, options?: Object) {
    const body = JSON.stringify(data);
    return post(`/${organizationId}/property-group/create`, body, options);
  }

  update(data: Object, id: string, organizationId: string, options?: Object) {
    const body = JSON.stringify(data);
    return put(`/${organizationId}/property-group/${id}/update`, body, options);
  }
}
