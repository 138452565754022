import moment from 'moment';
import isNaN from 'lodash/isNaN';

import messages from '../containers/App/messages';

import type { SelectOption } from '../containers/App/types';

export const TEXT_ALIGN_OPTIONS = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify',
};

export const formatCurrency = (
  intl: any,
  value: number,
  empty: any = '---',
): any =>
  !value
    ? empty
    : intl.formatNumber(value, {
        style: 'currency',
        currency: 'USD',
      });

type FormatMoneyOptions = {
  style?: string,
  currency?: string,
  minimumFractionDigits?: number,
};

export type FormatSelectOptions = {
  formatMessage: Function,
  data: Array<Object>,
  textField: string,
  valueField: string,
  disableAll?: boolean,
  includeDefault?: boolean,
  includeDisable?: boolean,
};

export const formatMoney = (
  amount: ?number,
  options: FormatMoneyOptions = {},
  defaultEmptyValue: string = '---',
): string => {
  if (!amount) {
    return defaultEmptyValue;
  }

  const defaultOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  };

  // $FlowFixMe
  const formatter = Intl.NumberFormat('en-US', {
    ...defaultOptions,
    ...options,
  });
  return formatter.format(amount);
};

export const formatDate = (
  date: string,
  format: string = 'MM/DD/YYYY',
  defaultEmptyValue: string = '---',
) => {
  if (!date) {
    return defaultEmptyValue;
  }
  return moment(date).format(format);
};

export const formatNewLine = (text: any): any => {
  if (typeof text !== 'string') {
    return text;
  }
  return text
    .split(/\n/)
    .reduce(
      (result, word) => (result.length ? [...result, <br />, word] : [word]),
      [],
    );
};

export const formatToSelectOptions = ({
  formatMessage,
  data,
  textField,
  valueField,
  disableAll = false,
  includeDefault = true,
  includeDisable = true,
}: FormatSelectOptions): Array<SelectOption> => {
  return (data ?? []).reduce(
    (acc: Array<SelectOption>, option: Object) => {
      return [
        ...acc,
        {
          value: option?.[valueField] ?? '',
          text: option?.[textField] ?? '',
          ...(includeDisable ? { disabled: disableAll } : {}),
        },
      ];
    },
    includeDefault
      ? [
          {
            value: 'default',
            text: formatMessage(messages.choose),
            ...(includeDisable ? { disabled: true } : {}),
          },
        ]
      : [],
  );
};

export const normalizeString = (text: string) =>
  text.replace(/\s+/g, ' ').trim();

export const formatPercentage = (
  value: number | string,
  empty: any = '---',
) => {
  const numericValue = parseFloat(value);
  const isValid = !isNaN(numericValue);
  return `${isValid ? (value * 100).toFixed(2) : empty}%`;
};
