import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.CreateTransactionBatch.ConfirmDeleteBatchModal.title',
    defaultMessage: 'Confirm Delete Batch',
  },
  body: {
    id: 'App.CreateTransactionBatch.ConfirmDeleteBatchModal.body',
    defaultMessage:
      // eslint-disable-next-line max-len
      'This action will permanently delete all the information for this batch. The batch will no longer be available for further updates. Are you sure you want to proceed?',
  },
  yes: {
    id: 'App.CreateTransactionBatch.ConfirmDeleteBatchModal.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.CreateTransactionBatch.ConfirmDeleteBatchModal.no',
    defaultMessage: 'No',
  },
});

export default messages;
