import { defineMessages } from 'react-intl';

const messages = defineMessages({
  leaseSigningMethod: {
    id: 'App.LeaseDataTabForm.LeaseSigningMethod.leaseSigningMethod',
    defaultMessage: 'Lease Signing Method',
  },
  note: {
    id: 'App.LeaseDataTabForm.LeaseSigningMethod.note',
    defaultMessage:
      'NOTE: You must select a lease signing method in order to generate the lease',
  },
  signingMethod: {
    id: 'App.LeaseDataTabForm.LeaseSigningMethod.signingMethod',
    defaultMessage: 'Signing Method:',
  },
  electronicNote: {
    id: 'App.LeaseDataTabForm.LeaseSigningMethod.electronicNote',
    defaultMessage: `NOTE:  An electronic lease can only be fully executed when electronically signed through
      Portal by all leaseholders (including non-financial).  To change the lease signing method to manual,
      click "Edit" below, change the drop-down above to "Manual" and click save.  This will remove the lease
      information from the Portal.  You will then be able to print a Lease for manual signatures.`,
  },
  manualNote: {
    id: 'App.LeaseDataTabForm.LeaseSigningMethod.manualNote',
    defaultMessage: `NOTE: A manual lease must be signed by all leaseholders and uploaded via the “Upload
      Signed Lease” button. To change the lease signing method to electronic, click "Edit" below, change
      the drop-down above and click save.  This is only available if your property has access to Portal.`,
  },
  lockedNonFinancial: {
    id: 'App.LeaseDataTabForm.LeaseSigningMethod.LockedNonFinancial',
    defaultMessage:
      'This lease can only be manually signed due to non-financial lease signer(s) in the household.',
  },
  noteOnSigningMethod: {
    id: 'App.LeaseDataTabForm.LeaseSigningMethod.NoteOnSigningMethod',
    defaultMessage: 'NOTE ON SIGNING METHOD: ',
  },
});

export default messages;
