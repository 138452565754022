import React, { useState, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import ElementWithPermissions from '../../components/ElementWithPermissions';
import confirm from '../../components/ConfirmDialogModal';
import messages from './messages';
import { BusinessInformationForm } from './BusinessInformationForm';
import { FormattedMessage } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';
import { OtherForm } from './OtherForm';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { promptToaster } from '../App/actions';
import { useAsyncStates } from '../ManageBuildings/hooks';
import { useForm } from '../ViewProperty/hooks';
import { useCreateCommercialApplication, useProspectAssignees } from './hooks';
import { trim, compose, propOr, isEmpty, reduce, pathOr } from 'ramda';
import { validateEmail, validatePhoneNumber } from '../../utils/validations';
import { validateFrom, validateTo } from './OtherForm/validations';
import type { GlobalState } from '../App/types';

type Props = {
  selectedProperty: Object,
  history: Object,
  intl: Object,
  actions: Object,
};

const CreateCommercialApplicant = ({
  selectedProperty,
  history,
  intl,
  actions,
}: Props) => {
  if (selectedProperty.hasCommercialFloorPlans === 'NONE') {
    history.push('/404');
  }

  const { states } = useAsyncStates();
  const { prospectAssignees } = useProspectAssignees(selectedProperty);
  const [createCommercialApplication] = useCreateCommercialApplication(
    selectedProperty,
    actions.promptToaster,
    intl,
    history,
  );

  const [businessInformationData, , onBusinessInformationDataChange] = useForm({
    tenantLegalName: '',
    doingBusinessAs: '',
    parentCompany: '',
    phoneNumber: '',
    emailAddress: '',
    street: '',
    street2: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [otherData, , onOtherDataChange] = useForm({
    moveInDateFrom: '',
    moveInDateTo: '',
    assignedToId: '',
  });
  const [range, setRange] = useState(false);
  const [noMoveInDate, setNoMoveInDate] = useState(false);

  useEffect(() => {
    onOtherDataChange({
      target: {
        name: 'assignedToId',
        value: pathOr('', ['0', 'id'], prospectAssignees),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prospectAssignees]);

  const cancel = () => {
    confirm(intl.formatMessage(messages.cancel), {
      intl,
    }).then(() => {
      history.push('/');
    });
  };

  const cleanData = (data: Object, property: string) =>
    compose(trim, propOr('', property))(data);

  const arePropertiesEmpty = (data: Object, properties: string[] = []) =>
    reduce(
      (acc, [key, value]: any) =>
        acc || (properties.includes(key) ? isEmpty(value) : false),
      false,
      Object.entries(data),
    );

  const isDataValid = () => {
    const data = {
      tenantLegalName: cleanData(businessInformationData, 'tenantLegalName'),
      doingBusinessAs: cleanData(businessInformationData, 'doingBusinessAs'),
      parentCompany: cleanData(businessInformationData, 'parentCompany'),
      phoneNumber: validatePhoneNumber(businessInformationData.phoneNumber)
        ? businessInformationData.phoneNumber
        : '',
      emailAddress: validateEmail(businessInformationData.emailAddress)
        ? cleanData(businessInformationData, 'emailAddress')
        : '',
      street: cleanData(businessInformationData, 'street'),
      street2: cleanData(businessInformationData, 'street2'),
      city: cleanData(businessInformationData, 'city'),
      state: businessInformationData.state,
      zipCode:
        businessInformationData.zipCode.length === 5
          ? businessInformationData.zipCode
          : '',
      moveInDateFrom:
        !noMoveInDate &&
        validateFrom(otherData.moveInDateFrom, range, otherData.moveInDateTo)
          ? otherData.moveInDateFrom.toISOString()
          : '',
      moveInDateTo:
        range && validateTo(otherData.moveInDateTo, range)
          ? otherData.moveInDateTo.toISOString()
          : '',
      assignedToId: otherData.assignedToId,
    };

    if (
      !arePropertiesEmpty(data, [
        'tenantLegalName',
        'phoneNumber',
        'emailAddress',
        ...(range ? ['moveInDateFrom', 'moveInDateTo'] : []),
        ...(!noMoveInDate ? ['moveInDateFrom'] : []),
        ...(businessInformationData.zipCode ? ['zipCode'] : []),
        'assignedToId',
      ])
    ) {
      return data;
    }
    return false;
  };

  const save = () => {
    confirm(intl.formatMessage(messages.save), {
      intl,
    }).then(() => {
      const data = isDataValid();
      if (data) {
        createCommercialApplication(data);
      }
    });
  };

  return (
    <DocumentTitle title={intl.formatMessage(messages.title)}>
      <Grid className="bodywrap" fluid>
        <div className="form-wrapper container-fluid">
          <div className="applicant-convert-header">
            <Row>
              <Col xs={6} md={12} className="form-header">
                <h2 className="float-left">
                  <FormattedMessage {...messages.title} />
                </h2>
                <small className="float-left">
                  <FormattedMessage {...messages.requiredFields} />
                </small>
              </Col>
            </Row>
          </div>
          <div className="form-container container-fluid">
            <BusinessInformationForm
              intl={intl}
              data={businessInformationData}
              onChange={onBusinessInformationDataChange}
              states={states}
            />
            <OtherForm
              intl={intl}
              data={otherData}
              onChange={onOtherDataChange}
              range={range}
              setRange={setRange}
              noMoveInDate={noMoveInDate}
              setNoMoveInDate={setNoMoveInDate}
              prospectAssignees={prospectAssignees}
            />
            <Row>
              <ElementWithPermissions scope={['prospect-create']}>
                <div className="col-xs-6">
                  <button
                    type="button"
                    className="pull-right btn btn-default"
                    onClick={cancel}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-xs-6">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={save}
                    disabled={!isDataValid()}
                  >
                    Add Applicant
                  </button>
                </div>
              </ElementWithPermissions>
            </Row>
          </div>
        </div>
      </Grid>
    </DocumentTitle>
  );
};

const InjectedViewProperty = injectIntl(CreateCommercialApplicant);

const mapStateToProps = ({ app }: GlobalState): Object => {
  const { selectedProperty } = app;
  return { selectedProperty };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  actions: bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedViewProperty);
