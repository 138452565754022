import ReportService from '../../services/reportService';
import ReportsService from '../../services/reportService';
import FileSaver from 'file-saver';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import messages from '../../containers/ManageReports/messages';

export const downloadLastReportFunc = async (payload) => {
  const {
    organizationId,
    propertyId,
    id,
    filename,
    mainReportId,
    promptToaster,
  } = payload;
  try {
    const reportService = new ReportService();
    const response = await reportService.downloadPreviousReport(
      organizationId,
      mainReportId,
      propertyId,
      id,
    );
    const sanitizedFilename = (filename || '').replace(/^reports\//, '');
    FileSaver.saveAs(response, sanitizedFilename);
  } catch {
    promptToaster({
      type: 'error',
      message: renderTranslatedMessage(messages.failedDocumentDownload),
      title: 'Error while downloading',
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    });
  }
};

export const getHistoricalReportsFunc = async (payload) => {
  const {
    organizationId,
    propertyId,
    dateTo,
    dateFrom,
    reportId,
    promptToaster,
    setHistoricalReports,
    setHistoricalSearch,
  } = payload;

  try {
    const reportService = new ReportsService();
    const response = await reportService.getHistoricalReports({
      organizationId,
      propertyId,
      dateTo,
      dateFrom,
      reportId,
    });
    if (response.length === 0) {
      promptToaster({
        type: 'info',
        message: 'There is currently no data to display',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      });
    }
    setHistoricalReports(response);
    setHistoricalSearch(true);
  } catch {
    promptToaster({
      type: 'error',
      message: renderTranslatedMessage(messages.failedDocumentDownload),
      title: 'Error while downloading',
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    });
  }
};
