import { defineMessages } from 'react-intl';

const messages = defineMessages({
  goBackToProfile: {
    id: 'App.Qualification.GoBackToProfile',
    defaultMessage: 'Go Back to Profile',
  },
  assetInformation: {
    id: 'App.Qualification.AssetInformation',
    defaultMessage: 'Asset Information',
  },
  state: {
    id: 'App.Qualification.State',
    defaultMessage: 'State / Province',
  },
  incomeSuccessDescription: {
    id: 'App.Qualification.IncomeSuccessDescription',
    defaultMessage: 'Income Verification successfully saved',
  },
  assetsSuccessDescription: {
    id: 'App.Qualification.AssetsSuccessDescription',
    defaultMessage: 'Assets Verification successfully saved',
  },
  successHeader: {
    id: 'App.Qualification.SuccessHeader',
    defaultMessage: 'Success',
  },
  errorHeader: {
    id: 'App.Qualification.ErrorHeader',
    defaultMessage: 'Error',
  },
  ssnBenefitsPlaceholder: {
    id: 'App.Qualification.ssnBenefitsPlaceholder',
    defaultMessage: 'SSN Benefits Claim Number',
  },
  ssnBenefitsClaimNumber: {
    id: 'App.Qualification.ssnBenefitsClaimNumber',
    defaultMessage: 'SSN Benefits Claim Number (if different from SSN)',
  },
  retry: {
    id: 'App.Qualification.Retry',
    defaultMessage: 'Retry',
  },
  loadError: {
    id: 'App.Qualification.LoadError',
    defaultMessage: 'Failed to load data.',
  },
});

export default messages;
