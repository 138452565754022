import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ManageSubsidyPaymentsHeader.ManageSubsidyPayments',
    defaultMessage: 'Manage Subsidy Payments',
  },
  createSubsidyBatch: {
    id: 'App.ManageSubsidyPaymentsHeader.CreateSubsidyBatch',
    defaultMessage: 'Create Subsidy Batch',
  },
  close: {
    id: 'App.ManageSubsidyPaymentsHeader.Close',
    defaultMessage: 'Close',
  },
  modalHeader: {
    id: 'App.ManageSubsidyPaymentsHeader.ModalHeader',
    defaultMessage: 'Unable to Create Payment Batch',
  },
  messageLine1: {
    id: 'App.ManageSubsidyPaymentsHeader.MessageLine1',
    defaultMessage:
      // eslint-disable-next-line max-len
      'You are not able to create a subsidy batch because required subsidy transaction codes have not been assigned to your property.',
  },
  messageLine2: {
    id: 'App.ManageSubsidyPaymentsHeader.MessageLine2',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Please contact your organization administrator to add the missing transactions codes.',
  },
});

export default messages;
