import PaginationFooter from '../../PaginationFooter';
import React from 'react';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import type { ActivityData } from '../types';

type Props = {
  activitiesByProspect: ActivityData,
  page: number,
  limit: number,
  setPage: Function,
};

function ActivityTableFooter(props: Props) {
  const { activitiesByProspect, page, limit, setPage, isLoading } = props;

  if (isLoading) {
    return null;
  }

  return (
    <Stack marginTop={1}>
      {activitiesByProspect.meta.pageCount > 1 && (
        <PaginationFooter
          currentPage={page}
          limit={limit}
          count={activitiesByProspect.meta.count}
          totalCount={activitiesByProspect.meta.totalCount}
          pageCount={activitiesByProspect.meta.pageCount}
          onPageChange={setPage}
        />
      )}
      <Stack>
        <FormattedMessage {...messages.automatedActivityNote} />
      </Stack>
    </Stack>
  );
}

export default ActivityTableFooter;
