import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import FileSaver from 'file-saver';
import { endDatadogSession } from './datadog';

function parseJSON(response) {
  return new Promise(
    (resolve) =>
      response
        .json()
        .then((json) => {
          resolve({
            status: response.status,
            ok: response.ok,
            json,
          });
        })
        .catch(() => {}), // catches the aborted requests
  );
}

/* istanbul ignore next */
// Get API URL from environment
const environmentURL = process.env.REACT_APP_API_URL;
export const apiURL = environmentURL || 'http://localhost:8000';
export const REQUEST_ABORTED = 'The user aborted a request.';

export const getRequestOptions = () => ({
  headers: {
    Authorization: localStorage.getItem('session_id'),
    'Content-Type': 'application/json',
  },
});
export const logout = () => {
  localStorage.removeItem('session_id');
  endDatadogSession();
  if (!window.location.pathname === '/login') window.location.href = '/login';
};

export const requestAPI = (url: string, body: any, options?: Object) => {
  const requestOptions: Object = {
    headers: {
      Authorization: localStorage.getItem('session_id'),
      'Content-Type': 'application/json',
    },
    body: undefined,
  };
  if (body) {
    requestOptions.body = body;
  }
  // $FlowFixMe
  return new Promise((resolve, reject) => {
    fetch((options.isOtherApi ? '' : apiURL) + url, {
      ...requestOptions,
      ...options,
    })
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          return resolve(response.json);
        }
        if (response.json.statusCode === 401) {
          logout();
        }
        const errorResponse = response.json.message || response.json.error;
        return reject(errorResponse);
      })
      .catch((error) => reject({ networkError: error.message }));
  });
};
export const del = (url: string, body?: any, options?: Object) => {
  return requestAPI(url, body, { method: 'DELETE', ...options });
};
export const get = (url: string, options?: Object) => {
  return requestAPI(url, false, { method: 'GET', ...options });
};
export const post = (url: string, body?: any, options?: Object) => {
  return requestAPI(url, body, { method: 'POST', ...options });
};
export const put = (url: string, body?: any, options?: Object) => {
  return requestAPI(url, body, { method: 'PUT', ...options });
};
export const patch = (url: string, body?: any, options?: Object) => {
  return requestAPI(url, body, { method: 'PATCH', ...options });
};

export const getDownload = (
  url: string,
  fileName: string,
  options?: Object,
) => {
  return downloadAPI(url, fileName, false, { method: 'GET', ...options });
};

export const postDownload = (
  url: string,
  fileName: string,
  body?: any,
  options?: Object,
) => {
  return downloadAPI(url, fileName, body, { method: 'POST', ...options });
};

export const downloadAPI = (
  url: string,
  fileName: string,
  body: any,
  options?: Object,
) => {
  const requestOptions = {
    headers: {
      Authorization: localStorage.getItem('session_id'),
      'Content-Type': 'application/json',
    },
    body: undefined,
  };
  if (body) {
    requestOptions.body = body;
  }
  // $FlowFixMe
  return new Promise((resolve, reject) => {
    fetch(apiURL + url, { ...requestOptions, ...options })
      .then((response) => {
        if (response.status === 500 || response.status === 400) {
          return response.json().then((message) => {
            return reject(message.error);
          });
        }
        return response.blob();
      })
      .then((response) => {
        if (response) {
          FileSaver.saveAs(response, fileName);
          resolve(response);
        }
      });
  });
};

// TODO: Deprecate
export function request(url: string, options?: Object) {
  // $FlowFixMe
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then(parseJSON)
      .then((response) => {
        if (response.ok) {
          return resolve(response.json);
        }
        if (response.json.statusCode === 401) {
          logout();
        }

        const errorResponse = options?.fullError
          ? response.json
          : response.json.message || response.json.error;

        return reject(errorResponse);
      })
      .catch((error) =>
        reject({
          networkError: error.message,
        }),
      );
  });
}
export default request;

// TODO: Deprecate
export const requestToDownload = (url: string, options?: Object) => {
  // $FlowFixMe
  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (
          response.status === 500 ||
          response.status === 400 ||
          response.status === 404
        ) {
          return response.json().then((error) => {
            return reject(error.message ?? error.error);
          });
        }
        return resolve(response.blob());
      })
      .catch((error) =>
        reject({
          networkError: error.message,
        }),
      );
  });
};
