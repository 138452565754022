/* eslint-disable react/style-prop-object */
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isEmpty } from 'ramda';
import { connect } from 'react-redux';
import { useQueryClient } from 'react-query';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import {
  Button,
  Spinner,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import Table from '../../components/Table';
import Row from '../../components/Table/Row';
import Data from '../../components/Table/Data';

import { promptToaster } from '../App/actions';
import { HEADERS } from './constants';
import { useFetchPropertyGroups } from '../../hooks/data-fetching/useFetchPropertyGroups';
import { parsePropertyGroups, sortPropertyGroups } from './utils';

import generalMessages from '../App/messages';

const messages = { ...generalMessages };

type ConnectedProps = {
  organizationId: string,
  actions: {
    promptToaster: Function,
  },
};

const ManagePropertyGroupsContainer = styled.div`
  margin-top: 2%;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 2%;

  button.MuiButton-primarySubtle:not(:hover) {
    background: white;
  }
`;

const GroupDetailRow = ({ group, onEditViewClick }: Object) => {
  return (
    <Row className="table-row" key={`data-row-${group.id}`}>
      <Data>{group.name}</Data>
      <Data>{group.description}</Data>
      <Data>{group.numProperties}</Data>
      <Data>{group.createdByOn}</Data>
      <Data>{group.lastModifiedByOn}</Data>
      <Data>
        <Button onClick={onEditViewClick} variant="primarySubtle">
          <i className="et-pencil" />
        </Button>
      </Data>
    </Row>
  );
};

const buildRows = ({
  propertyGroups,
  intl,
  onEditViewClick,
}: Object): ReactNode | Array<ReactNode> => {
  if (isEmpty(propertyGroups)) {
    return (
      <Row key="noData">
        <Data colSpan={HEADERS.length}>
          <FormattedMessage
            {...messages.noDataFoundCustom}
            values={{
              custom: intl.formatMessage(messages.propertyGroups) ?? '',
            }}
          />
        </Data>
      </Row>
    );
  }

  return propertyGroups.map((group) => {
    return (
      <GroupDetailRow
        key={`row-${group.id}`}
        group={group}
        onEditViewClick={() => onEditViewClick(group.id)}
      />
    );
  });
};

const ManagePropertyGroups = ({
  intl,
  organizationId,
  locale,
  history,
  actions: { promptToaster },
}) => {
  const queryClient = useQueryClient();
  const memoizedIntl = useMemo(() => {
    return intl;
  }, [intl]);
  const [propertyGroups, setPropertyGroups] = useState([]);
  const [currentSorting, setCurrentSorting] = useState({
    fieldName: 'lastModifiedByOn',
    order: 'DESC',
  });

  const title = intl.formatMessage(messages.fortressTitle, {
    title: intl.formatMessage(messages.managePropertyGroups),
  });

  const onAddNewGroupClick = () => {
    history.push('/create-property-group');
  };

  const onSort = ({ fieldName, order }: OrderValue) => {
    setCurrentSorting({
      fieldName,
      order,
    });
  };

  const { data: propertyGroupsData, isLoading } = useFetchPropertyGroups({
    organizationId,
    intl,
    promptToaster,
  });

  useEffect(() => {
    if (propertyGroupsData) {
      const unsortedPropertyGroups = parsePropertyGroups(
        propertyGroupsData,
        memoizedIntl,
        locale,
      );

      setPropertyGroups(
        sortPropertyGroups(unsortedPropertyGroups, currentSorting, locale),
      );
    }
  }, [propertyGroupsData, currentSorting, memoizedIntl, locale]);

  const onEditViewClick = async (propertyGroupId: string) => {
    await queryClient.invalidateQueries(
      'ManagePropertyGroups > Get Property Group',
      {
        exact: true,
      },
    );
    history.push(`/property-group/${propertyGroupId}`);
  };

  return (
    <DocumentTitle title={title}>
      <ManagePropertyGroupsContainer>
        <Grid container>
          <Grid item xs={12}>
            <Link to={'/properties'} className="btn-text">
              <i className="et-chevron-left" />
              <FormattedMessage
                {...messages.backTo}
                values={{
                  route: `${intl.formatMessage(
                    messages.manage,
                  )} ${intl.formatMessage(messages.properties)}`,
                }}
              />
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2">
              <FormattedMessage {...messages.managePropertyGroups} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={8}></Grid>
              <Grid item xs={4}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <Button
                      variant="shout"
                      sx={{
                        minHeight: '40px',
                      }}
                      onClick={onAddNewGroupClick}
                    >
                      +&nbsp;
                      <FormattedMessage
                        {...messages.addNewCustom}
                        values={{ custom: intl.formatMessage(messages.group) }}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table
              name={'managePropertyGroups'}
              headers={HEADERS}
              onSort={onSort}
            >
              {isLoading ? (
                <Row key="loader">
                  <Data colSpan={HEADERS.length}>
                    <Spinner />
                  </Data>
                </Row>
              ) : (
                buildRows({ propertyGroups, intl, onEditViewClick })
              )}
            </Table>
          </Grid>
        </Grid>
      </ManagePropertyGroupsContainer>
    </DocumentTitle>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  organizationId: state?.app?.currentUser
    ? state?.app?.currentUser?.user?.organizationId
    : '',
  locale: state?.languageProvider?.locale,
});

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  );
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ManagePropertyGroups));
