import { defineMessages } from 'react-intl';

/**
 * @notes
 * - This constant is related to the pastDue certifications per property calculation for
 * the Portfolio summary.
 */
export const COMPLIANCE_APPROVAL_STATUSES = {
  approved: 'Approved',
  approvedPendingSign: 'Approved Pending Signatures',
  correctionNeeded: 'Correction Needed',
  correctionNeededFinal: 'Correction Needed - Final Packet',
  denied: 'Denied',
  pending: 'Pending',
  onNoticeHold: 'Household on Notice / Hold',
  none: 'None', // not defined in the DB, but used in our code
  notStarted: 'Not Started', // not defined in the DB, but used in our code
  started: 'Started', // not defined in the DB, but used in our code
  complete: 'Complete', // not defined in the DB, but used in our code
  pendingFinalApproval: 'Pending Final Approval',
};

export const COMPLIANCE_APPROVAL_STATUSES_LOWERCASE = {
  approved: 'approved',
  approvedPendingSign: 'approved pending signatures',
  correctionNeeded: 'correction needed',
  correctionNeededFinal: 'correction needed - final packet',
  denied: 'denied',
  pending: 'pending',
  onNoticeHold: 'household on notice / hold',
  none: 'none', // not defined in the DB, but used in our code
  notStarted: 'not started', // not defined in the DB, but used in our code
  started: 'started', // not defined in the DB, but used in our code
  complete: 'complete', // not defined in the DB, but used in our code
  pendingFinalApproval: 'pending final approval',
};

export const affordableComplianceApprovalStatusesMessages = defineMessages({
  approved: {
    id: 'App.Constants.AffordableComplianceApprovalStatuses.Approved',
    defaultMessage: COMPLIANCE_APPROVAL_STATUSES.approved,
  },
  approvedPendingSign: {
    id: 'App.Constants.AffordableComplianceApprovalStatuses.ApprovedPendingSign',
    defaultMessage: COMPLIANCE_APPROVAL_STATUSES.approvedPendingSign,
  },
  correctionNeeded: {
    id: 'App.Constants.AffordableComplianceApprovalStatuses.CorrectionNeeded',
    defaultMessage: COMPLIANCE_APPROVAL_STATUSES.correctionNeeded,
  },
  correctionNeededFinal: {
    id: 'App.Constants.AffordableComplianceApprovalStatuses.CorrectionNeededFinal',
    defaultMessage: COMPLIANCE_APPROVAL_STATUSES.correctionNeededFinal,
  },
  denied: {
    id: 'App.Constants.AffordableComplianceApprovalStatuses.Denied',
    defaultMessage: COMPLIANCE_APPROVAL_STATUSES.denied,
  },
  pending: {
    id: 'App.Constants.AffordableComplianceApprovalStatuses.Pending',
    defaultMessage: COMPLIANCE_APPROVAL_STATUSES.pending,
  },
  onNoticeHold: {
    id: 'App.Constants.AffordableComplianceApprovalStatuses.OnNoticeHold',
    defaultMessage: COMPLIANCE_APPROVAL_STATUSES.onNoticeHold,
  },
  none: {
    id: 'App.Constants.AffordableComplianceApprovalStatuses.None',
    defaultMessage: COMPLIANCE_APPROVAL_STATUSES.none,
  },
  notStarted: {
    id: 'App.Constants.AffordableComplianceApprovalStatuses.NotStarted',
    defaultMessage: COMPLIANCE_APPROVAL_STATUSES.notStarted,
  },
  started: {
    id: 'App.Constants.AffordableComplianceApprovalStatuses.Started',
    defaultMessage: COMPLIANCE_APPROVAL_STATUSES.started,
  },
  complete: {
    id: 'App.Constants.AffordableComplianceApprovalStatuses.Complete',
    defaultMessage: COMPLIANCE_APPROVAL_STATUSES.complete,
  },
  pendingFinalApproval: {
    id: 'App.Constants.AffordableComplianceApprovalStatuses.PendingFinalApproval',
    defaultMessage: COMPLIANCE_APPROVAL_STATUSES.pendingFinalApproval,
  },
});

export const affordableComplianceApprovalStatusesMessagesUppercaseMap =
  defineMessages({
    Approved: {
      id: 'App.Constants.AffordableComplianceApprovalStatuses.Approved',
      defaultMessage: COMPLIANCE_APPROVAL_STATUSES.approved,
    },
    'Approved Pending Signatures': {
      id: 'App.Constants.AffordableComplianceApprovalStatuses.ApprovedPendingSign',
      defaultMessage: COMPLIANCE_APPROVAL_STATUSES.approvedPendingSign,
    },
    'Correction Needed': {
      id: 'App.Constants.AffordableComplianceApprovalStatuses.CorrectionNeeded',
      defaultMessage: COMPLIANCE_APPROVAL_STATUSES.correctionNeeded,
    },
    'Correction Needed - Final Packet': {
      id: 'App.Constants.AffordableComplianceApprovalStatuses.CorrectionNeededFinal',
      defaultMessage: COMPLIANCE_APPROVAL_STATUSES.correctionNeededFinal,
    },
    Denied: {
      id: 'App.Constants.AffordableComplianceApprovalStatuses.Denied',
      defaultMessage: COMPLIANCE_APPROVAL_STATUSES.denied,
    },
    Pending: {
      id: 'App.Constants.AffordableComplianceApprovalStatuses.Pending',
      defaultMessage: COMPLIANCE_APPROVAL_STATUSES.pending,
    },
    'Household on Notice / Hold': {
      id: 'App.Constants.AffordableComplianceApprovalStatuses.OnNoticeHold',
      defaultMessage: COMPLIANCE_APPROVAL_STATUSES.onNoticeHold,
    },
    None: {
      id: 'App.Constants.AffordableComplianceApprovalStatuses.None',
      defaultMessage: COMPLIANCE_APPROVAL_STATUSES.none,
    },
    'Not Started': {
      id: 'App.Constants.AffordableComplianceApprovalStatuses.NotStarted',
      defaultMessage: COMPLIANCE_APPROVAL_STATUSES.notStarted,
    },
    Started: {
      id: 'App.Constants.AffordableComplianceApprovalStatuses.Started',
      defaultMessage: COMPLIANCE_APPROVAL_STATUSES.started,
    },
    Complete: {
      id: 'App.Constants.AffordableComplianceApprovalStatuses.Complete',
      defaultMessage: COMPLIANCE_APPROVAL_STATUSES.complete,
    },
    'Pending Final Approval': {
      id: 'App.Constants.AffordableComplianceApprovalStatuses.PendingFinalApproval',
      defaultMessage: COMPLIANCE_APPROVAL_STATUSES.pendingFinalApproval,
    },
  });
