import * as constants from './constants';
import type {
  CreateInsurancePayload,
  EditInsurancePayload,
  DeleteInsurancePayload,
  CreateContactPayload,
  EditContactPayload,
  DeleteContactPayload,
} from './types';

export function createInsurance(payload: CreateInsurancePayload) {
  return {
    type: constants.CREATE_INSURANCE,
    payload,
  };
}

export function editInsurance(payload: EditInsurancePayload) {
  return {
    type: constants.EDIT_INSURANCE,
    payload,
  };
}

export function deleteInsurance(payload: DeleteInsurancePayload) {
  return {
    type: constants.DELETE_INSURANCE,
    payload,
  };
}

export function createContact(payload: CreateContactPayload) {
  return {
    type: constants.CREATE_CONTACT,
    payload,
  };
}

export function editContact(payload: EditContactPayload) {
  return {
    type: constants.EDIT_CONTACT,
    payload,
  };
}

export function deleteContact(payload: DeleteContactPayload) {
  return {
    type: constants.DELETE_CONTACT,
    payload,
  };
}
