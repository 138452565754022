import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageLeaseExpirations.Title',
    defaultMessage: 'Manage Expiring Leases',
  },
  unitsFound: {
    id: 'App.ManageLeaseExpirations.UnitsFound',
    defaultMessage: 'Units Found',
  },
  search: {
    id: 'App.ManageLeaseExpirations.SearchManage',
    defaultMessage: 'Search',
  },
  month: {
    id: 'App.ManageLeaseExpirations.Month',
    defaultMessage: 'Month to month leases:',
  },
  expiringText: {
    id: 'App.ManageLeaseExpirations.ExpiringText',
    defaultMessage: 'Leases Expiring',
  },
  under30: {
    id: 'App.ManageLeaseExpirations.Expiration0To30Days',
    defaultMessage: 'in 0-30 Days:',
  },
  between31and60: {
    id: 'App.ManageLeaseExpirations.Between31and60',
    defaultMessage: 'in 31-60 Days:',
  },
  between61and90: {
    id: 'App.ManageLeaseExpirations.Between61and90',
    defaultMessage: 'in 61-90 Days:',
  },
  between90and120: {
    id: 'App.ManageLeaseExpirations.Between90and120',
    defaultMessage: 'in 91-120 Days:',
  },
  over120: {
    id: 'App.ManageLeaseExpirations.Over120',
    defaultMessage: 'in 121+ Days:',
  },
  unit: {
    id: 'App.ManageLeaseExpirations.Unit',
    defaultMessage: 'Unit',
  },
  floorplan: {
    id: 'App.ManageLeaseExpirations.FloorPlan',
    defaultMessage: 'Floor Plan',
  },
  resident: {
    id: 'App.ManageLeaseExpirations.Resident',
    defaultMessage: 'Resident',
  },
  moveIn: {
    id: 'App.ManageLeaseExpirations.MoveInDate',
    defaultMessage: 'Move-in Date',
  },
  leaseStartDate: {
    id: 'App.ManageLeaseExpirations.LeaseStartDate',
    defaultMessage: 'Lease Start Date',
  },
  leaseEndDate: {
    id: 'App.ManageLeaseExpirations.LeaseEndDate',
    defaultMessage: 'Lease End Date',
  },
  daysUntilLeaseEnd: {
    id: 'App.ManageLeaseExpirations.DaysUntilLeaseEnd',
    defaultMessage: 'Days until Lease End',
  },
  gprMarketRent: {
    id: 'App.ManageLeaseExpirations.GPRMarketRent',
    defaultMessage: 'GPR Market Rent',
  },
  priorLeasedRent: {
    id: 'App.ManageLeaseExpirations.PriorLeasedRent',
    defaultMessage: 'Prior Leased Rent',
  },
  currentLeasedRent: {
    id: 'App.ManageLeaseExpirations.CurrentLeasedRent',
    defaultMessage: 'Current Leased Rent',
  },
  otherUnitFees: {
    id: 'App.ManageLeaseExpirations.OtherUnitFees',
    defaultMessage: 'Other Unit Fees',
  },
  doNotRenew: {
    id: 'App.ManageLeaseExpirations.DoNotRenew',
    defaultMessage: 'Do Not Renew',
  },
  underEviction: {
    id: 'App.ManageLeaseExpirations.UnderEviction',
    defaultMessage: 'Under Eviction',
  },
  balance: {
    id: 'App.ManageLeaseExpirations.Balance',
    defaultMessage: 'Balance',
  },
  quotingRent: {
    id: 'App.ManageLeaseExpirations.QuotingRent',
    defaultMessage: 'Quoting Rent',
  },
  actions: {
    id: 'App.ManageLeaseExpirations.Actions',
    defaultMessage: 'Actions',
  },
  quoteLeaseRenewal: {
    id: 'App.ManageLeaseExpirations.QuoteLeaseRenewal',
    defaultMessage: 'Quote Lease Renewal',
  },
  noticeToVacate: {
    id: 'App.ManageLeaseExpirations.NoticeToVacate',
    defaultMessage: 'Occupied / On NTV',
  },
  leaseRenewalStatus: {
    id: 'App.ManageLeaseExpirations.LeaseRenewalStatus',
    defaultMessage: 'Lease Renewal Status',
  },
  tenant: {
    id: 'App.ManageLeaseExpirations.Tenant',
    defaultMessage: 'Tenant',
  },
  note: {
    id: 'App.ManageLeaseExpirations.Note',
    defaultMessage: 'Note',
  },
});

export default messages;
