/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';
import commercialScheduledRentsService from '../../../../services/commercialScheduledRentsService';
import { pathOr, head } from 'ramda';
import moment from 'moment';

const handleCalculations = (
  scheduledRentsList: Array<Object>,
  unitRentableSqFtHistory: Array<Object>,
) => {
  return scheduledRentsList.map((scheduledRent, i) => {
    const rentableSqft = calcRentableSqFt(
      scheduledRent.startDate,
      unitRentableSqFtHistory,
    );
    const isLastRent = i === scheduledRentsList.length - 1;

    //endDate - one day prior to the subsequent entry's start date
    const endDate = !isLastRent
      ? calcEndDate(scheduledRentsList[i + 1].startDate)
      : '';

    const months =
      !scheduledRent.isHoldover && !isLastRent
        ? moment(endDate)
            .add(1, 'days') // include last day (end date)
            .diff(moment(scheduledRent.startDate), 'months')
        : -1;

    //psfPerMonth = Monthly Rent / Unit Rentable Sq. Ft.
    const psfPerMonth =
      unitRentableSqFtHistory.length && rentableSqft !== 0
        ? (scheduledRent.monthlyAmount / rentableSqft).toFixed(2)
        : null;
    //psfPerYear = Monthly Rent * 12 / Unit Rentable Sq. Ft.
    const psfPerYear =
      unitRentableSqFtHistory.length && rentableSqft !== 0
        ? ((scheduledRent.monthlyAmount * 12) / rentableSqft).toFixed(2)
        : null;
    //TotalRent = (#months x Monthly Rent)
    const totalRent = months < 0 ? null : months * scheduledRent.monthlyAmount;

    return {
      endDate,
      months,
      psfPerMonth,
      psfPerYear,
      totalRent,
      startDate: moment(scheduledRent.startDate).toDate(),
      monthlyAmount: scheduledRent.monthlyAmount.toFixed(2),
      isHoldover: scheduledRent.isHoldover,
      id: scheduledRent.id,
      notes: scheduledRent.notes,
      holdoverDollarAmount: scheduledRent.holdoverDollarAmount,
      holdoverPercentage: scheduledRent.holdoverPercentage,
    };
  });
};

const calcEndDate = (nextStartDate: Date) => {
  return moment(nextStartDate).subtract(1, 'days');
};

const calcRentableSqFt = (
  rentStartDate: Date,
  unitRentableSqFtHistory: Array<Object>,
) => {
  const earliestValue = head(unitRentableSqFtHistory);
  const initialRentableSqft = earliestValue ? earliestValue.squareFeet : 0;
  const rentableSqft = unitRentableSqFtHistory.reduce(
    (currentRentableSqFt: number, history: Object) => {
      if (moment(rentStartDate).isSameOrAfter(history.startDate)) {
        return history.squareFeet;
      }
      return currentRentableSqFt;
    },
    initialRentableSqft,
  );
  return rentableSqft;
};

export const useAsyncCommercialScheduledRents = (
  propertyId: string,
  organizationId: string,
  leaseId: string,
  unit: Object,
) => {
  const [scheduledRents, setScheduledRents] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const unitRentableSqFtHistory = pathOr(
    [],
    ['unitRentableSquareFeetHistory'],
    unit,
  );

  useEffect(() => {
    if (!leaseId || isLoading) return;

    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const scheduledRentsService = new commercialScheduledRentsService();

    const fetchScheduledRents = async () => {
      const scheduledRentsList = await scheduledRentsService.getAll(
        organizationId,
        propertyId,
        leaseId,
        {
          signal: abortController.signal,
        },
      );

      if (scheduledRentsList)
        setScheduledRents(
          handleCalculations(scheduledRentsList, unitRentableSqFtHistory),
        );

      setRefresh(false);
      setIsLoading(false);
    };

    fetchScheduledRents();
    setIsLoading(true);
  }, [
    propertyId,
    organizationId,
    leaseId,
    unit,
    refresh,
    unitRentableSqFtHistory,
  ]);

  const refreshRents = () => setRefresh(true);

  return [scheduledRents, isLoading, refreshRents];
};
