import { isEmpty, isNil } from 'ramda';
import messages from './messages';
import { ORGANIZATION_FIELDS_MAP } from './constants';

import { hasDuplicates } from '../validate';
import generalMessages from '../../App/messages';

const checkEmptyNil = (value: string) => {
  return isEmpty(value) || isNil(value);
};

/**
 * Validates the Income Verification Alternate Form by checking if the required fields are filled out.
 * -
 * @param {*} values a json with the form values
 * @param {*} param1 an object with the props comming from the IncomeVerificationForm component
 * @returns an object with or without errrors
 */
export const validate = (values: Object, { intl }: Object) => {
  const {
    INCOME_VALUE_FIELDS,
    INCOME_ROW_NAMES,
    PAYRATE_VALUE_FIELDS,
    HOURSWEEKSMONTHS_VALUE_FIELDS,
  } = ORGANIZATION_FIELDS_MAP.alternate;
  const mustSelectOneOptionWarning = intl.formatMessage(
    messages.mustSelectOneOption,
  );

  const errors = {};

  const employmentList = values?.employmentList ?? [];
  if (checkEmptyNil(values['employment'])) {
    errors['employment'] = mustSelectOneOptionWarning;
  } else if (values['employment'] === 'yes' && employmentList.length < 1) {
    errors['employment'] = 'Employment Rows Cannot Be Empty';
  }

  const employmentListErrorArray = [];
  employmentList.forEach((employmentRow, index) => {
    const employmentRowErrors = {};
    const payRate = Number(employmentRow?.PayRate ?? 0);
    const payFrequency = employmentRow?.PayFrequency ?? -1;
    const hoursWeeksMonths = Number(employmentRow?.HoursWeeksMonths ?? 0);
    const payType = +employmentRow?.payType ?? -1;

    const validations = {
      payType: [() => payType === -1 && 'Employment Type Required'],
      PayFrequency: [() => payFrequency === -1 && 'Pay Frequency Required'],
      PayRate: [
        () => payRate === 0 && 'Pay Rate Required',
        () => payRate < 0 && 'Must Be Positive',
      ],
      HoursWeeksMonths: [
        () => hoursWeeksMonths === 0 && 'Hours/Weeks/Months Required',
        () => hoursWeeksMonths < 0 && 'Hours/Weeks/Months Must Be Positive',
        () =>
          payFrequency === 'monthly' &&
          hoursWeeksMonths > 12.0 &&
          intl.formatMessage(messages.valueMustBeBetween, {
            value: 'Months',
            lower: '1',
            upper: '12',
          }),
      ],
    };

    Object.entries(validations).forEach(([key, validationArray]) => {
      validationArray.some((validation) => {
        const result = validation();
        if (result) {
          employmentRowErrors[key] = result;
        }
        return result;
      });
    });

    employmentListErrorArray[index] = employmentRowErrors;
  });

  errors.employmentList = employmentListErrorArray;

  const otherList = values?.otherList ?? [];
  if (checkEmptyNil(values['other'])) {
    errors['other'] = mustSelectOneOptionWarning;
  } else if (values['other'] === 'yes' && otherList.length < 1) {
    errors['other'] = 'Other Income Rows Cannot Be Empty';
  }

  const otherListErrorArray = [];
  otherList.forEach((otherRow, index) => {
    const otherRowErrors = {};
    const payRate = Number(otherRow?.PayRate ?? 0);
    const payFrequency = otherRow?.PayFrequency ?? -1;
    const hoursWeeksMonths = Number(otherRow?.HoursWeeksMonths ?? 0);
    const payType = +otherRow?.payType ?? -1;
    const incomeName = otherRow?.IncomeName;

    const validations = {
      payType: [() => payType === -1 && 'Employment Type Required'],
      PayFrequency: [() => payFrequency === -1 && 'Pay Frequency Required'],
      PayRate: [
        () => payRate === 0 && 'Pay Rate Required',
        () => payRate < 0 && 'Must Be Positive',
      ],
      HoursWeeksMonths: [
        () => hoursWeeksMonths === 0 && 'Hours/Weeks/Months Required',
        () => hoursWeeksMonths < 0 && 'Hours/Weeks/Months Must Be Positive',
        () =>
          payFrequency === 'monthly' &&
          hoursWeeksMonths > 12.0 &&
          'Months Must Be Between 1 and 12',
      ],
      IncomeName: [
        () => !incomeName && 'Income Name Required',
        () =>
          hasDuplicates(otherList, incomeName, 'IncomeName') &&
          intl.formatMessage(generalMessages.duplicateIdentifiers, {
            item: '"Other" rows',
            identifiers: 'names',
          }),
      ],
    };

    Object.entries(validations).forEach(([key, validationArray]) => {
      validationArray.some((validation) => {
        const result = validation();
        if (result) {
          otherRowErrors[key] = result;
        }
        return result;
      });
    });

    otherListErrorArray[index] = otherRowErrors;
  });
  errors.otherList = otherListErrorArray;

  INCOME_ROW_NAMES.forEach((row) => {
    const isCurrentIncomeRowInTheFieldsList = INCOME_VALUE_FIELDS.includes(
      `${row}Income`,
    );

    if (checkEmptyNil(values[row]) && isCurrentIncomeRowInTheFieldsList) {
      errors[`${row}Income`] = mustSelectOneOptionWarning;
    } else if (
      values[row] === 'yes' &&
      isCurrentIncomeRowInTheFieldsList &&
      (!values[`${row}Income`] || +values[`${row}Income`] === 0)
    ) {
      errors[`${row}Income`] = intl.formatMessage(messages.amountRequired);
    }
    if (values[`${row}Income`] < 0) {
      errors[`${row}Income`] = intl.formatMessage(messages.mustBePositive);
    }
  });

  // Validate payDataColumns
  INCOME_ROW_NAMES.forEach((row) => {
    const isCurrentPayRateRowInTheFieldsList = PAYRATE_VALUE_FIELDS.includes(
      `${row}PayRate`,
    );
    if (checkEmptyNil(values[row]) && isCurrentPayRateRowInTheFieldsList) {
      errors[`${row}PayRate`] = mustSelectOneOptionWarning;
    } else if (
      values[row] === 'yes' &&
      isCurrentPayRateRowInTheFieldsList &&
      (!values[`${row}PayRate`] || +values[`${row}PayRate`] === 0)
    ) {
      errors[`${row}PayRate`] = intl.formatMessage(messages.amountRequired);
    }
    if (values[`${row}PayRate`] < 0) {
      errors[`${row}PayRate`] = intl.formatMessage(messages.mustBePositive);
    }

    if (
      values[row] === 'yes' &&
      (!values[`${row}PayFrequency`] || +values[`${row}PayFrequency`] === -1)
    ) {
      errors[`${row}PayFrequency`] = intl.formatMessage(
        messages.frequencyRequired,
      );
    }

    const hoursWeeksMonthsRow = HOURSWEEKSMONTHS_VALUE_FIELDS.includes(
      `${row}HoursWeeksMonths`,
    );
    if (checkEmptyNil(values[row]) && hoursWeeksMonthsRow) {
      errors[`${row}HoursWeeksMonths`] = mustSelectOneOptionWarning;
    } else if (
      values[row] === 'yes' &&
      hoursWeeksMonthsRow &&
      (!values[`${row}HoursWeeksMonths`] ||
        +values[`${row}HoursWeeksMonths`] === 0)
    ) {
      errors[`${row}HoursWeeksMonths`] = intl.formatMessage(
        messages.amountRequired,
      );
    }
    if (values[`${row}HoursWeeksMonths`] < 0) {
      errors[`${row}HoursWeeksMonths`] = intl.formatMessage(
        messages.mustBePositive,
      );
    }
    if (
      values[`${row}HoursWeeksMonths`] &&
      values[`${row}PayFrequency`] === 'monthly' &&
      values[`${row}HoursWeeksMonths`] > 12
    ) {
      errors[`${row}HoursWeeksMonths`] = intl.formatMessage(
        messages.valueMustBeBetween,
        {
          value: 'Months',
          lower: '1',
          upper: '12',
        },
      );
    }
  });

  if (checkEmptyNil(values.notWorking)) {
    errors.notWorking = mustSelectOneOptionWarning;
  }

  if (checkEmptyNil(values.collectChildSupport)) {
    errors.collectChildSupportCounty = mustSelectOneOptionWarning;
  } else if (
    values.collectChildSupport === 'yes' &&
    checkEmptyNil(values.collectChildSupportCounty)
  ) {
    errors.collectChildSupportCounty = intl.formatMessage(
      messages.stateAndCountyRequired,
    );
  }

  if (checkEmptyNil(values.anticipateChildSupport)) {
    errors.anticipateChildSupportCounty = mustSelectOneOptionWarning;
  } else if (
    values.anticipateChildSupport === 'yes' &&
    checkEmptyNil(values.anticipateChildSupportCounty)
  ) {
    errors.anticipateChildSupportCounty = intl.formatMessage(
      messages.stateAndCountyRequired,
    );
  }

  if (checkEmptyNil(values.other)) {
    errors.otherIncomeName = mustSelectOneOptionWarning;
  } else if (values.other === 'yes' && checkEmptyNil(values.otherIncomeName)) {
    errors.otherIncomeName = intl.formatMessage(messages.sourceRequired);
  }

  if (checkEmptyNil(values.publicHousing)) {
    errors.publicHousing = intl.formatMessage(
      messages.publicHousingAuthorityRequired,
    );
  }

  return errors;
};

export default validate;
