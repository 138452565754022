import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import { bindActionCreators } from 'redux';
import { pathOr, isEmpty, isNil, insertAll } from 'ramda';
import { Link } from 'react-router-dom';
import { promptToaster } from '../App/actions';
import * as constants from './constants';
import {
  getAllColumns,
  getSwitchableColumns,
  getTotalsList,
} from './columnBuilder';
import {
  usePropertySpecials,
  useRentRoll,
  usePropertySpecialsSave,
  useFilterControls,
  useAppliedFilters,
  useUnitAmenityHeaders,
} from './hooks';
import messages from './messages';
import { getAllReports } from '../ManageReports/actions';
import UnitSpecials from '../../components/UnitSpecials';
import Table from '../../components/Table';
import Row from '../../components/Table/Row';
import Data from '../../components/Table/Data';
import ManageLayout from '../../components/ManageLayout';
import SearchField from '../Fields/Search';
import { formatColumnType } from '../ManageCommercialRentRoll/helpers';
import { sortAndFilterUnits } from '../../utils/unit-helpers';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import * as utils from './utils';
import AppliedFilters from '../../components/AppliedFilters';
import FilterControls from './FilterControls';
import FilterIconButton from '../../components/FilterIconButton';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import ShowHideColumnsRentRoll from '../../components/ShowHideColumnsRentRoll/index';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type RentRollProps = {
  intl: Object,
  actions: Object,
  selectedProperty: Object,
  reports: Array<Object>,
  organizationId: string,
  specialsForm: Object,
  flags: Object,
};

export const ManageRentRoll = ({
  intl,
  actions: { getAllReports },
  selectedProperty,
  reports,
  organizationId,
  specialsForm,
  flags,
}: RentRollProps) => {
  const rentRollOverhaulActive = flags?.rentRollOverhaul;
  const _ = constants;
  const propertyClass = selectedProperty.propertyClass.name;
  const isCommercial =
    selectedProperty?.hasCommercialFloorPlans ===
    constants.CommercialFloorPlans.All;

  const { id: propertyId, name: propertyName } = selectedProperty;
  const [refreshSpecials, setRefreshSpecials] = useState(false);
  const storedShowHideColumns = useMemo(
    () => JSON.parse(localStorage.getItem('show-hide-columns-rent-roll')),
    [],
  );
  const totalsList = useMemo(() => {
    let totalsColumns = constants.RENT_ROLL_TOTAL_COLUMNS;
    if (rentRollOverhaulActive) {
      totalsColumns = getTotalsList(propertyClass);
    }
    return totalsColumns;
  }, [propertyClass, rentRollOverhaulActive]);

  const showHideColumnList = useMemo(() => {
    let configuredShowHideColumns = null;
    if (rentRollOverhaulActive) {
      configuredShowHideColumns = getSwitchableColumns({
        storedShowHideColumns,
        propertyClass,
        intl,
        flags,
      });
    }
    return constants.generateShowHideColumnList({
      storedShowHideColumns,
      configuredShowHideColumns,
    });
  }, [
    storedShowHideColumns,
    propertyClass,
    rentRollOverhaulActive,
    intl,
    flags,
  ]);

  useEffect(() => {
    getAllReports({});
  }, [getAllReports]);

  const [rows, loaded] = useRentRoll(selectedProperty, promptToaster);
  const unitAmenityHeaders = useUnitAmenityHeaders({ unitRows: rows ?? [] });
  const [isFilterBtnActive, setIsFilterBtnActive] = useState(false);
  const {
    filterValues,
    options,
    handleFilterControlsChange,
    clearAllFilterValues,
    clearMoveInDateRange,
  } = useFilterControls(rows);
  const { appliedFilters, handleRemoveFilter, handleClearAll } =
    useAppliedFilters(
      filterValues,
      handleFilterControlsChange,
      clearAllFilterValues,
      clearMoveInDateRange,
    );
  const filterArray = useMemo(
    () => utils.mapFilterValuesToFilterArray(filterValues),
    [filterValues],
  );
  const [specials] = usePropertySpecials(
    selectedProperty,
    promptToaster,
    refreshSpecials,
  );
  const [handleSaveSpecial] = usePropertySpecialsSave(
    selectedProperty,
    promptToaster,
    specials,
  );
  const [query, setQuery] = useState({
    sorting: { fieldName: 'order', order: 'ASC' },
  });
  const [mappedRows, setMappedRows] = useState([]);
  const [editingSpecials, setEditingSpecials] = useState(false);

  const {
    sorting: { fieldName, order },
    searchText,
  } = query;

  const [normalizedRows, filteredRows] = useMemo(() => {
    const filtered = sortAndFilterUnits(
      mappedRows,
      fieldName,
      order,
      searchText,
      _.DATE_INPUT_FORMAT,
      filterArray,
    );

    const mappedTableRowsValues = filtered.map(({ rows }) => {
      const reducedRow = Object.entries(rows).reduce(
        (acc, [key, { value }]: any) => {
          acc[key] = value;
          return acc;
        },
        {},
      );
      return reducedRow;
    });
    const mappedTableRowsRaw = filtered.map(({ rows }) => {
      const reducedRow = Object.entries(rows).reduce(
        (acc, [key, { raw }]: any) => {
          acc[key] = raw;
          return acc;
        },
        {},
      );
      return reducedRow;
    });
    return [mappedTableRowsValues, mappedTableRowsRaw];
  }, [
    mappedRows,
    fieldName,
    order,
    searchText,
    filterArray,
    _.DATE_INPUT_FORMAT,
  ]);

  const submitSpecial = async () => {
    await handleSaveSpecial(specialsForm);
    setRefreshSpecials(!refreshSpecials);
    setEditingSpecials(false);
  };

  const isAffordable =
    propertyClass === 'Mixed' || propertyClass === 'Affordable';

  const manageRentRollHeaders = useMemo(() => {
    if (isAffordable && !rentRollOverhaulActive)
      return _.RENT_ROLL_TABLE_HEADERS_AFFORDABLE;
    if (rentRollOverhaulActive) {
      return getAllColumns(propertyClass, flags, isCommercial);
    }
    return _.RENT_ROLL_TABLE_HEADERS;
  }, [
    propertyClass,
    _,
    isAffordable,
    rentRollOverhaulActive,
    isCommercial,
    flags,
  ]);
  const totalAmenityFeesIndex = manageRentRollHeaders.findIndex(
    (h) => h.id === 'amenityFees',
  );
  const [showHideColumns, setShowHideColumns] = useState(showHideColumnList);
  const [totalsColumnsList] = useState(totalsList);

  const [headers, setHeaders] = useState(manageRentRollHeaders);

  useEffect(() => {
    localStorage.setItem(
      'show-hide-columns-rent-roll',
      JSON.stringify(showHideColumns),
    );
    const hideColumns = showHideColumns.filter((row) => row.selected === false);
    const showAmenityDetails =
      hideColumns.find((c) => c.id === constants.AMENITY_DETAILS_ID) ===
      undefined;
    const filteredHeaders = manageRentRollHeaders.filter((row) => {
      const hideColumn = hideColumns.find((col) => col.id === row.id);
      if (hideColumn) return false;
      return true;
    });
    const newHeaders = showAmenityDetails
      ? insertAll(
          totalAmenityFeesIndex - 1,
          unitAmenityHeaders,
          filteredHeaders,
        )
      : filteredHeaders;
    setHeaders(newHeaders);
    const mappedRows = utils.getMappedRows({ rows, intl, headers: newHeaders });
    setMappedRows(mappedRows);
  }, [
    intl,
    rows,
    showHideColumns,
    manageRentRollHeaders,
    totalAmenityFeesIndex,
    unitAmenityHeaders,
  ]);

  const handleSort = ({ fieldName, order }: Object) => {
    const sorting = { fieldName, order };
    setQuery({ ...query, sorting });
  };

  const handleSearch = ({ searchText }: Object) => {
    setQuery({ ...query, searchText });
  };

  const reportId =
    reports.find(({ referenceId = '' }) => referenceId === 'Rent-Roll')?.id ||
    '';

  const footerRows = flags?.refreshUnitsTotalsRowManageRentRoll
    ? filteredRows
    : rows;

  const unitsFound =
    flags?.refreshUnitsTotalsRowManageRentRoll && appliedFilters.length > 0
      ? `${filteredRows.length} of ${rows.length}`
      : rows.length;

  return (
    <DocumentTitle title={intl.formatMessage(messages.title)}>
      <ManageLayout
        title={
          <div className="col-xs-12 col-md-7">
            <h1>
              <FormattedMessage {...messages.header} />
            </h1>
            <p>
              <FormattedMessage {...messages.subheader} />{' '}
              <span className="text-blue" id="units-found">
                {unitsFound}
              </span>
            </p>
          </div>
        }
        actions={
          <div className="col-xs-12 col-md-5">
            <button
              className="btn-tertiary pull-right btn"
              disabled={!loaded}
              onClick={() =>
                utils.handleDownload({
                  fileType: 'csv',
                  organizationId,
                  propertyId,
                  propertyName,
                  reportId,
                  query,
                  filterValues,
                  showHideColumns,
                })
              }
            >
              <i className="et-cloud-download" />
              <span style={{ marginLeft: '8px' }}>CSV </span>
            </button>
            <button
              className="btn-tertiary pull-right btn padright10"
              disabled={!loaded}
              onClick={() =>
                utils.handleDownload({
                  fileType: 'pdf',
                  organizationId,
                  propertyId,
                  propertyName,
                  reportId,
                  query,
                  filterValues,
                  showHideColumns,
                })
              }
            >
              <i className="icon et-cloud-download" />
              <span style={{ marginLeft: '8px' }}>PDF </span>
            </button>
            <div className="search-actions search-align-right pull-right">
              <SearchField
                className="search-input search-input-active"
                form="ManageRentRoll"
                placeholder={intl.formatMessage(messages.search)}
                onSubmit={handleSearch}
              />
            </div>
            {
              <FilterIconButton
                className="pull-right"
                active={isFilterBtnActive}
                disabled={!loaded}
                onClick={() => setIsFilterBtnActive(!isFilterBtnActive)}
              />
            }
          </div>
        }
      >
        {appliedFilters.length > 0 && (
          <div style={{ width: '70%' }}>
            <AppliedFilters
              filters={appliedFilters}
              onRemoveFilter={handleRemoveFilter}
              onClearAll={handleClearAll}
            />
          </div>
        )}
        {isFilterBtnActive && (
          <div style={{ padding: '10px 0px' }}>
            <FilterControls
              {...options}
              values={filterValues}
              onChange={handleFilterControlsChange}
            />
          </div>
        )}
        <UnitSpecials
          intl={intl}
          specials={specials}
          editable={editingSpecials}
          toggleEditCallback={() => setEditingSpecials(!editingSpecials)}
          submitSpecialsCallback={submitSpecial}
        />
        <div className="row">
          <div className="col-xs-12">
            {
              <ShowHideColumnsRentRoll
                isLoading={!loaded}
                showHideColumnsList={showHideColumns}
                setShowHideColumns={setShowHideColumns}
                isAffordable={isAffordable}
              />
            }
          </div>
        </div>
        <div className="table-scroll table-units-container">
          <Table
            id="ManageRentRoll"
            name="ManageRentRoll"
            headers={headers}
            onSort={handleSort}
            footer={() =>
              FooterRow({
                intl,
                rows: footerRows,
                loaded,
                headers,
                totalsColumnsList,
              })
            }
          >
            <TableRows
              rows={normalizedRows}
              loaded={loaded}
              query={query}
              filters={filterArray}
              headers={headers}
              propertyId={propertyId}
            />
          </Table>
        </div>
      </ManageLayout>
    </DocumentTitle>
  );
};

export const mapDispatchToProps = (dispatch: any): Object => {
  const actions = bindActionCreators(
    {
      getAllReports,
    },
    dispatch,
  );
  return { actions };
};

export const mapStateToProps = ({
  app,
  form,
  manageReports: { reports },
}: Object): Object => {
  return {
    organizationId: app.currentUser?.user?.organizationId || '',
    selectedProperty: app.selectedProperty,
    specialsForm: form.updateUnitSpecials,
    reports,
  };
};

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(ManageRentRoll)),
);

export const FooterRow = ({
  intl,
  rows,
  loaded,
  headers,
  totalsColumnsList,
}: Object) => {
  const initialValues = totalsColumnsList.reduce((accumulator, value) => {
    return { ...accumulator, [value]: 0 };
  }, {});
  const totals = rows.reduce((acc, row) => {
    totalsColumnsList.forEach((column) => {
      acc[column] += +pathOr(0, [column], row);
    });
    return acc;
  }, initialValues);

  return loaded ? (
    <tfoot className="table-footer">
      <tr>
        {headers?.map((header) => {
          if (totalsColumnsList.indexOf(header.id) !== -1) {
            if (header.id === 'sqFt') {
              header.type = 'number';
            }
            return (
              <td key={header.id}>
                {formatColumnType(intl, totals[header.id], header)}
              </td>
            );
          }
          return <td key={header.id} />;
        })}
      </tr>
    </tfoot>
  ) : null;
};

export const TableRows = ({
  rows,
  loaded,
  headers,
  propertyId,
}: Object): any => {
  return rows.length ? (
    rows.map((row: Object, key: number): any => (
      <Row key={`row${key}`} className="table-row">
        {headers?.reduce((acc, header, otherKey) => {
          const value = row[header.id];
          if (value) {
            if (
              header.id === 'applicant' &&
              !isEmpty(row.applicationId) &&
              !isNil(row.applicationId)
            ) {
              acc.push(
                <Data key={otherKey}>
                  <Link
                    to={getUrlWithSelectedPropertyId(
                      `/application/${row.applicationId}`,
                    )}
                  >
                    {value}
                  </Link>
                </Data>,
              );
            } else if (
              header.id === 'resident' &&
              !isEmpty(row.residentId) &&
              !isNil(row.residentId)
            ) {
              acc.push(
                <Data key={otherKey}>
                  <Link
                    to={getUrlWithSelectedPropertyId(
                      `/resident/${row.residentId}`,
                    )}
                  >
                    {value}
                  </Link>
                </Data>,
              );
            } else if (
              header.id === 'floorPlan' &&
              !isEmpty(row.floorPlanId) &&
              !isNil(row.floorPlanId)
            ) {
              acc.push(
                <Data key={otherKey}>
                  <Link
                    to={getUrlWithSelectedPropertyId(
                      `/floorPlan/${row.floorPlanId}`,
                    )}
                  >
                    {value}
                  </Link>
                </Data>,
              );
            } else if (header.id === 'unit') {
              acc.push(
                <Data key={otherKey}>
                  <Link
                    to={getUrlWithSelectedPropertyId(`/unit/${row.unitId}`)}
                  >
                    {value}
                  </Link>
                </Data>,
              );
            } else if (header.id === 'otherHouseholdMembers') {
              const membersValue = (value ?? []).map((member) => (
                <li>{member}</li>
              ));
              acc.push(
                !isEmpty(membersValue) ? (
                  <Data key={otherKey}>
                    <ul
                      style={{
                        paddingLeft: '5px',
                        listStylePosition: 'inside',
                        listStyleType: 'none',
                      }}
                    >
                      {membersValue}
                    </ul>
                  </Data>
                ) : (
                  <Data key={otherKey} />
                ),
              );
            } else {
              acc.push(<Data key={otherKey}>{value}</Data>);
            }
          } else {
            acc.push(<Data key={otherKey} />);
          }
          return acc;
        }, [])}
      </Row>
    ))
  ) : (
    <Row key={1} className="table-row">
      {loaded ? (
        <Data colSpan={headers?.length}>No units available</Data>
      ) : (
        <Data colSpan={headers?.length}>
          <Spinner />
        </Data>
      )}
    </Row>
  );
};
