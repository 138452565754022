import React from 'react';
import { Row, Col, Checkbox, FormGroup, FormControl } from 'react-bootstrap';
import { isNil, isEmpty } from 'ramda';
import Styled from 'styled-components';

import {
  filterPeriodsForTransactionDate,
  getCamPeriodsDropdownOptions,
  getCamPoolsDropdownOptions,
} from '../../../utils/cam';
import messages from './messages';

const LabelForCheckbox = Styled.label`
  margin-top: 10px;
`;

type FormValues = {
  applyToCamPool: boolean,
  camRecord: string,
  camPool: string,
};

type Props = {
  camPools?: Array<Object>,
  intl: any,
  formValues: FormValues,
  setFormValues: Function,
  showApplyToCamPool?: boolean,
  title?: string,
  compact?: boolean,
  transactionDate: Date,
};

const DEFAULT_OPTION = { value: 'default', text: 'Choose', disabled: true };

const ModifyTransactionCamPoolForm = ({
  formValues,
  setFormValues,
  camPools,
  intl,
  showApplyToCamPool = true,
  compact = false,
  title,
  transactionDate,
}: Props) => {
  const hasCamPools = !isNil(camPools) && !isEmpty(camPools);

  const camRecord = formValues.camRecord || DEFAULT_OPTION.value;
  const camPool = formValues.camPool || DEFAULT_OPTION.value;
  const applyToCamPool = formValues.applyToCamPool;

  const renderCamPeriodsOptions = () => {
    if (!hasCamPools) {
      return [DEFAULT_OPTION];
    }

    const filteredCamPools = filterPeriodsForTransactionDate(
      transactionDate,
      camPools,
    );

    return getCamPeriodsDropdownOptions(
      filteredCamPools,
      DEFAULT_OPTION,
      setFormValues,
      formValues,
    );
  };

  const renderCamPoolsOptions = () => {
    if (!hasCamPools) {
      return [DEFAULT_OPTION];
    }

    return getCamPoolsDropdownOptions(
      camPools,
      DEFAULT_OPTION,
      setFormValues,
      formValues,
    );
  };

  const onApplyToCamPoolChange = (evt: any) => {
    const newValue = evt.target.checked;
    setFormValues({
      ...formValues,
      applyToCamPool: newValue,
    });
  };

  const onCamRecordChange = (evt: any) => {
    setFormValues({
      ...formValues,
      camRecord: evt.target.value,
      camPool: DEFAULT_OPTION.value,
    });
  };

  const onCamPoolChange = (evt: any) => {
    setFormValues({
      ...formValues,
      camPool: evt.target.value,
    });
  };

  const FullPeriodFields = () => {
    return (
      !compact && (
        <>
          <Row>
            <Col md={4} xs={12}>
              <label>{intl.formatMessage(messages.camPeriod)}</label>
            </Col>
            <Col md={8} xs={12}>
              <FormGroup>
                <FormControl
                  data-default-value-selected={
                    camRecord !== DEFAULT_OPTION.value
                  }
                  componentClass="select"
                  onChange={onCamRecordChange}
                  disabled={!applyToCamPool}
                  input={{ value: camRecord }}
                  value={camRecord}
                >
                  {renderCamPeriodsOptions()}
                </FormControl>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={4} xs={12}>
              <label>{intl.formatMessage(messages.camPool)}</label>
            </Col>
            <Col md={8} xs={12}>
              <FormGroup>
                <FormControl
                  data-default-value-selected={camPool !== DEFAULT_OPTION.value}
                  componentClass="select"
                  onChange={onCamPoolChange}
                  disabled={!applyToCamPool}
                  input={{ value: camPool }}
                  value={camPool}
                >
                  {renderCamPoolsOptions()}
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
        </>
      )
    );
  };

  const CompactPeriodFields = () => {
    return (
      compact && (
        <>
          <Row>
            <Col md={6} xs={12}>
              <FormGroup>
                <FormControl
                  data-default-value-selected={
                    camRecord !== DEFAULT_OPTION.value
                  }
                  componentClass="select"
                  onChange={onCamRecordChange}
                  disabled={!applyToCamPool}
                  input={{ value: camRecord }}
                  value={camRecord}
                >
                  {renderCamPeriodsOptions()}
                </FormControl>
              </FormGroup>
            </Col>

            <Col md={6} xs={12}>
              <FormGroup>
                <FormControl
                  data-default-value-selected={camPool !== DEFAULT_OPTION.value}
                  componentClass="select"
                  onChange={onCamPoolChange}
                  disabled={!applyToCamPool}
                  input={{ value: camPool }}
                  value={camPool}
                >
                  {renderCamPoolsOptions()}
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
        </>
      )
    );
  };

  return (
    <>
      {compact ? (
        <label className="required">
          {title || intl.formatMessage(messages.assignToCamPool)}
        </label>
      ) : (
        <Row>
          <h1>{title || intl.formatMessage(messages.assignToCamPool)}</h1>
        </Row>
      )}
      {showApplyToCamPool && (
        <Row>
          <Col md={4} xs={12}>
            <LabelForCheckbox>
              {intl.formatMessage(messages.applyToCamPool)}
            </LabelForCheckbox>
          </Col>
          <Col md={8} xs={12}>
            <FormGroup>
              <Checkbox
                checked={applyToCamPool}
                className="custom-checkbox padleft20"
                onChange={onApplyToCamPoolChange}
                input={{ value: applyToCamPool }}
              >
                <input type="checkbox" />
                <span className="custom-check-square center-block" />
              </Checkbox>
            </FormGroup>
          </Col>
        </Row>
      )}

      {applyToCamPool && (
        <>
          <FullPeriodFields />
          <CompactPeriodFields />
        </>
      )}
    </>
  );
};

export default ModifyTransactionCamPoolForm;
