import React, { useState } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { Field } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import * as validations from './validations';
import * as constants from './constants';
import messages from './messages';
import {
  extractDateFormat,
  nonEmptyString,
  number,
  greaterOrEqual,
  ReadOnlyField,
  renderCurrencyField,
  renderDateField,
  renderTextField,
  required,
  validDate,
} from '../../../../../utils/redux-form-helper';
import FormattedDateOrDashes from '../../../../../components/FormattedDateOrDashes';

const LabelOverrides = styled.span`
  & label {
    margin-bottom: 16px;
  }
`;

const ActionButton = styled.a`
  display: block;
  font-size: 18px;
  margin-top: 4px;
`;

function RentScheduleFieldArray({
  fields,
  startDate,
  intl,
  onRowDelete,
  onRowUpdate,
  onCreate,
  isFormValid,
  change,
}: Object) {
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const [initialRentValues, setInitialRentValues] = useState(null);
  const DATE_FORMAT = extractDateFormat(intl);

  const handleAddRentClick = () => {
    fields.push({});
    setEditableRowIndex(fields.length);
  };

  return (
    <React.Fragment>
      <Row style={{ maxHeight: 250, overflowY: 'auto' }}>
        {fields.map((field, index) => {
          const isRowDisabled = editableRowIndex !== index;
          const isFirstRow = index === 0;
          const pencilBtnMarginTop = isFirstRow ? 27 : undefined;
          const isEditButtonShown = index === fields.length - 1;
          const isTrashButtonShown = !isFirstRow && index === fields.length - 1;

          const handlePencilButtonClick = () => {
            const field = fields.get(index);
            setEditableRowIndex(index);
            setInitialRentValues({ ...field });
          };

          const handleTrashButtonClick = () => {
            const rentId = fields.get(index).id;
            onRowDelete(rentId).then(() => {
              fields.pop();
            });
          };

          const handleUpdate = (values, fallbackValues) => {
            onRowUpdate(values.id, {
              startDate: values.startDate,
              monthlyAmount: values.monthlyAmount,
              notes: values.notes,
            }).catch(() => change(field, { ...fallbackValues }));
          };

          const handleCreate = (values) => {
            onCreate({
              startDate: values.startDate,
              monthlyAmount: values.monthlyAmount,
              notes: values.notes,
            }).catch(() => fields.pop());
          };

          const handleEditSave = () => {
            const field = fields.get(index);

            if (field.id) handleUpdate(field, { ...initialRentValues });
            else handleCreate(field);

            setEditableRowIndex(null);
            setInitialRentValues(null);
          };

          const handleEditCancel = () => {
            setEditableRowIndex(null);

            if (!initialRentValues) {
              fields.pop();
              return;
            }

            change(field, { ...initialRentValues });
            setInitialRentValues(null);
          };

          const dateField = isFirstRow ? (
            <LabelOverrides>
              <ReadOnlyField
                label={intl.formatMessage(messages.startDateLabel)}
                value={
                  <FormattedDateOrDashes
                    value={startDate}
                    format={DATE_FORMAT}
                  />
                }
              />
            </LabelOverrides>
          ) : (
            <Field
              name={`${field}.startDate`}
              component={renderDateField}
              bsSize="lg"
              placeholder={DATE_FORMAT}
              dateFormat={DATE_FORMAT}
              validate={[
                validDate,
                required,
                validations.afterPreviousStartDate,
                validations.beforeLeaseEndDate,
              ]}
              disabled={isRowDisabled}
            />
          );
          const actionButtons = isRowDisabled ? (
            <React.Fragment>
              {isEditButtonShown && (
                <Col xs={12} md={1}>
                  <ActionButton
                    style={{ marginTop: pencilBtnMarginTop }}
                    onClick={handlePencilButtonClick}
                  >
                    <i className="icon et-pencil" />
                  </ActionButton>
                </Col>
              )}
              {isTrashButtonShown && (
                <Col xs={12} md={1}>
                  <ActionButton onClick={handleTrashButtonClick}>
                    <i className="icon et-trash" />
                  </ActionButton>
                </Col>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Col xs={12} md={1}>
                <ActionButton
                  disabled={!isFormValid}
                  style={{ marginTop: pencilBtnMarginTop }}
                  onClick={handleEditSave}
                >
                  <i className="icon et-isolated-check" />
                </ActionButton>
              </Col>
              <Col xs={12} md={1}>
                <ActionButton
                  style={{ marginTop: pencilBtnMarginTop }}
                  onClick={handleEditCancel}
                >
                  <i className="icon et-delete-3" />
                </ActionButton>
              </Col>
            </React.Fragment>
          );
          const monthlyAmountLabel = isFirstRow
            ? intl.formatMessage(messages.monthlyAmountLabel)
            : undefined;
          const notesLabel = isFirstRow
            ? intl.formatMessage(messages.notesLabel)
            : undefined;

          return (
            <Row key={index}>
              <Col xs={12} md={3}>
                {dateField}
              </Col>
              <Col xs={12} md={3}>
                <Field
                  name={`${field}.monthlyAmount`}
                  label={monthlyAmountLabel}
                  placeholder="0.00"
                  step="0.01"
                  component={renderCurrencyField}
                  required
                  className="input-md form-control"
                  style={{ height: constants.INPUT_HEIGHT }}
                  validate={[required, greaterOrEqual, number]}
                  disabled={isRowDisabled}
                />
              </Col>
              <Col xs={12} md={4}>
                <Field
                  name={`${field}.notes`}
                  label={notesLabel}
                  placeholder={intl.formatMessage(messages.notesPlaceholder)}
                  component={renderTextField}
                  showLabel={isFirstRow}
                  className="input-md form-control"
                  maxLength="200"
                  style={{ height: constants.INPUT_HEIGHT }}
                  validate={[nonEmptyString]}
                  disabled={isRowDisabled}
                />
              </Col>
              {actionButtons}
            </Row>
          );
        })}
      </Row>
      <Row>
        <Col xs={12}>
          <a disabled={!R.isNil(editableRowIndex)} onClick={handleAddRentClick}>
            + {intl.formatMessage(messages.addRent)}
          </a>
        </Col>
      </Row>
      <div style={{ marginBottom: fields.length < 3 ? 80 : 30 }} />
    </React.Fragment>
  );
}

export default RentScheduleFieldArray;
