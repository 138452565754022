import React, { Component, createContext } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import {
  sortBy,
  prop,
  find,
  pathEq,
  propEq,
  omit,
  path,
  pathOr,
  isEmpty,
  isNil,
} from 'ramda';
import moment from 'moment';
import type Moment from 'moment';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { debounce } from 'lodash';
import PrintProvider from 'react-easy-print';

import {
  createWorkOrder,
  getAllUnits,
  getWorkOrder,
  saveWorkOrder,
  clearLoadedWorkOrder,
} from './actions';
import messages from './messages';
import {
  getAllCommonAreaLocations,
  getAllEntryPermissions,
  getAllPriorityLevels,
  getAllUnitLocations,
  getAllWorkOrderIssues,
  getWorkOrderAssignees,
  getAllWorkOrderStatus,
  selectProperty,
} from '../App/actions';

import type {
  CommonArea,
  UnitLocation,
  EntryPermission,
  PriorityLevel,
  WorkOrderIssue,
  User,
  Property,
  WorkOrderStatus,
} from '../App/types';

import WorkOrderForm from './WorkOrderForm';
import { phoneParser } from '../../utils/redux-form-helper';

const parseDate = (date: any) => {
  return date ? moment(date) : null;
};

const parseTime = (time: any) => {
  const today = moment().format('YYYY-MM-DD');
  return time ? moment(`${today} ${time}`) : null;
};

type Props = {
  currentUser: User,
  commonAreaLocations: Array<CommonArea>,
  entryPermissions: Array<EntryPermission>,
  priorityLevels: Array<PriorityLevel>,
  unitOptions: Array<Object>,
  unitLocations: Array<UnitLocation>,
  workOrderAssignees: Array<User>,
  workOrderIssues: Array<WorkOrderIssue>,
  selectedProperty: Property,
  isSaving: boolean,
  history: Object,
  workOrderStatusList: Array<WorkOrderStatus>,
  currentDate: Moment,
  defaultPriority: string,
  match: Object,
  workOrderId: string,
  workOrderToEdit: Object,
  isSubmitting: boolean,
};

type InjectedProps = {
  actions: Object,
  intl: any,
};

type State = {
  issueSearchString: string,
  unitSearchString: string,
};

export const WorkOrderContext = createContext();

export class CreateEditWorkOrder extends Component<
  Props & InjectedProps,
  State,
> {
  excludedFormValues: Array<string>;
  potentialNulls: Array<string>;
  constructor(props: Props & InjectedProps) {
    super(props);
    this.excludedFormValues = [
      'location',
      'noTimeSpecified',
      'issueSearch',
      'unitSearch',
      'issueDescription',
      'petAlerts',
      'issueNotFound',
      'unitSelectionUpdated',
    ];
    this.potentialNulls = [
      'entryPermissionPreferenceId',
      'requestedStartDateFrom',
      'requestedStartDateTo',
    ];
    this.state = {
      issueSearchString: '',
      unitSearchString: '',
    };
  }
  static defaultProps = {
    currentDate: moment(),
  };

  componentDidMount() {
    const { workOrderId } = this.props.match.params;
    this.props.actions.getAllCommonAreaLocations();
    this.props.actions.getAllEntryPermissions();
    this.props.actions.getAllPriorityLevels();
    this.props.actions.getAllUnitLocations();
    this.props.actions.getAllWorkOrderIssues();
    this.props.actions.getWorkOrderAssignees();
    this.props.actions.getAllWorkOrderStatus();
    this.props.actions.getAllUnits();
    if (workOrderId) {
      this.props.actions.getWorkOrder(workOrderId);
    }
  }

  componentWillUnmount() {
    this.props.actions.clearLoadedWorkOrder();
  }

  static getDerivedStateFromProps(props: any) {
    const searchParams = new URLSearchParams(props.location.search);
    const selectedPropertyId = searchParams.get('propertyId');
    const userProperties = props?.currentUser?.properties ?? [];

    if (selectedPropertyId?.length) {
      const newProperty = userProperties.find(
        ({ id }) => id === selectedPropertyId,
      );
      if (newProperty) props.actions.selectProperty(newProperty);
      else props.history.push('/');
    }
    return null;
  }

  parseNotificationsToBeSend = (text, email) => {
    const notificationFlag = [];
    if (text) notificationFlag.push('SMS');
    if (email) notificationFlag.push('EMAIL');
    return notificationFlag;
  };

  handleSubmit = (print: boolean) => {
    return (workOrder: Object) => {
      const {
        currentUser,
        workOrderToEdit,
        actions: { createWorkOrder, saveWorkOrder },
        match: {
          params: { workOrderId },
        },
      } = this.props;
      const enteredByUserId = currentUser.id;
      const defaultStatusId = this.getDefaultStatus();

      let workOrderToSubmit;

      const requestedStartDateFrom = workOrder.requestedStartDateFrom
        ? moment(workOrder.requestedStartDateFrom).format('YYYY-MM-DD')
        : null;
      const requestedStartDateTo = workOrder.requestedStartDateTo
        ? moment(workOrder.requestedStartDateTo).format('YYYY-MM-DD')
        : null;
      const requestedStartTimeFrom = workOrder.requestedStartTimeFrom
        ? moment(workOrder.requestedStartTimeFrom).format('HH:mm:ss')
        : null;
      const requestedStartTimeTo = workOrder.requestedStartTimeTo
        ? moment(workOrder.requestedStartTimeTo).format('HH:mm:ss')
        : null;

      if (workOrderId) {
        const setEmptyToNull = (fieldName: string) => {
          const potentialValue = pathOr(null, [fieldName], workOrder);
          return potentialValue === '' ? null : potentialValue;
        };
        const detailId = workOrderToEdit.detailId;
        const assignedToId = setEmptyToNull('assignedToId');
        const finishedDate = setEmptyToNull('finishedDate');

        const lastStatusChangeDate =
          workOrder.statusId !== workOrderToEdit.statusId
            ? new Date()
            : workOrderToEdit.lastStatusChangeDate;
        workOrderToSubmit = {
          ...omit(this.excludedFormValues, workOrder),
          petAlerts: workOrder.petAlerts
            ? this.parsePetAlerts(workOrder.petAlerts)
            : null,
          enteredByUserId,
          detailId,
          finishedDate,
          requestedStartDateFrom,
          requestedStartDateTo,
          requestedStartTimeFrom,
          requestedStartTimeTo,
          assignedToId,
          lastStatusChangeDate,
          sendNotifications: this.parseNotificationsToBeSend(
            workOrder['communicationMethod-Text'],
            workOrder['communicationMethod-Email'],
          ),
        };
      } else {
        const unitWO = workOrder.location === 'unit';
        const extraExclude = unitWO
          ? ['commonAreaLocationId']
          : ['unitLocationId', 'unitNumber'];

        const excludedValues = this.excludedFormValues.concat(extraExclude);
        workOrderToSubmit = {
          ...omit(excludedValues, workOrder),
          statusId: defaultStatusId,
          petAlert: unitWO ? workOrder.petAlert : null,
          petAlerts:
            workOrder.petAlert && workOrder.petAlerts && unitWO
              ? this.parsePetAlerts(workOrder.petAlerts)
              : null,
          enteredByUserId,
          requestedStartDateFrom,
          requestedStartDateTo,
          requestedStartTimeFrom,
          requestedStartTimeTo,
        };
      }
      if (workOrder.requestorPhone)
        workOrderToSubmit.requestorPhone = phoneParser(
          workOrder.requestorPhone,
        );
      this.potentialNulls.forEach((keyValue) => {
        if (workOrderToSubmit[keyValue] === null) {
          delete workOrderToSubmit[keyValue];
        }
      });

      workOrderId
        ? saveWorkOrder(workOrderToSubmit, print)
        : createWorkOrder(workOrderToSubmit, print);
    };
  };

  parseItems(type: string): Array<Object> {
    let items = [];
    if (type === 'workOrderStatusList') {
      items = this.props[`${type}`]
        ? this.props[`${type}`].map((item) => item['status'])
        : [];
      type = 'status';
    } else {
      items = this.props[`${type}s`]
        ? this.props[`${type}s`].map((item) => item[type])
        : [];
    }
    return this.generateSelections(items, type);
  }

  parsePetAlerts(petAlerts: Object): Array<Object> {
    const parsedPetAlerts = Object.keys(petAlerts).map((petId) => ({
      residentPetId: petId,
      isCaged: petAlerts[petId].isCaged === 'yes' ? true : false,
    }));
    return parsedPetAlerts;
  }
  generateInitialPetAlerts(petAlertsJson: Array<Object>): Object {
    let parsedAlerts = {};
    if (petAlertsJson && !isEmpty(petAlertsJson)) {
      parsedAlerts = petAlertsJson.reduce((acc, alert) => {
        if (isNil(alert.isCaged)) {
          return acc;
        }
        acc[alert.residentPetId] = {
          isCaged: alert.isCaged ? 'yes' : 'no',
        };
        return acc;
      }, {});
    }

    return parsedAlerts;
  }

  generateSelections(items: Array<Object>, type: string): Array<Object> {
    const originalSelections = Array.isArray(items)
      ? items.map((selection) => ({
          value: selection[`${type}Id`],
          text: selection[`${type}Description`],
          readableId: selection[`${type}Readable`],
        }))
      : [];
    const sortByField =
      type === 'priorityLevel'
        ? sortBy(prop('readableId'))
        : sortBy(prop('text'));
    const selections = sortByField(originalSelections);
    if (type !== 'priorityLevel') {
      selections.unshift({
        value: '',
        text: this.props.intl.formatMessage(messages.choose),
        readableId: 0,
        disabled: true,
      });
    }
    return selections;
  }

  generateWorkOrderAssignees(): Array<Object> {
    const workOrderAssignees = this.props.workOrderAssignees
      ? this.props.workOrderAssignees.map((assignee) => ({
          value: assignee.id,
          text: `${assignee.firstName} ${assignee.lastName}`,
        }))
      : [];
    workOrderAssignees.unshift({
      value: '',
      text: this.props.intl.formatMessage(messages.choose),
      readableId: 0,
      disabled: true,
    });
    return workOrderAssignees;
  }

  formatIssues(): Array<Object> {
    if (this.props.workOrderIssues) {
      return this.props.workOrderIssues.map((issue) => {
        const i = issue.issue;
        return {
          ...i,
          id: i.issueId,
          description: i.issueDescription,
          keywords: i.issueKeywords,
        };
      });
    }
    return [];
  }

  updateSearchString = (searchType: string, searchString: string) => {
    this.setState({ [searchType]: searchString });
  };

  getDefaultStatus() {
    if (this.props.workOrderStatusList) {
      const defaultStatus = find(pathEq(['isDefault'], true))(
        this.props.workOrderStatusList,
      );
      return path(['statusId'], defaultStatus);
    }
  }

  render() {
    const {
      match: {
        params: { workOrderId },
      },
      workOrderToEdit,
      isSubmitting,
    } = this.props;

    const searchIssues = debounce((term) => {
      this.updateSearchString('issueSearchString', term);
    }, 300);
    const searchUnits = debounce((term) => {
      this.updateSearchString('unitSearchString', term);
    }, 300);
    const workOrderAssignees = this.generateWorkOrderAssignees();
    const unitLocations = this.parseItems('unitLocation');
    const commonAreaLocations = this.parseItems('commonAreaLocation');
    const statuses = this.parseItems('workOrderStatusList');
    const entryPermissions = this.generateSelections(
      this.props.entryPermissions,
      'entryPermission',
    );
    const priorityLevels = this.generateSelections(
      this.props.priorityLevels,
      'priorityLevel',
    );
    const defaultPriority = find(propEq('text', 'Medium'))(priorityLevels);
    const priorityLevelId = defaultPriority ? defaultPriority.value : '';
    const workOrderIssues = this.formatIssues();

    const defaultInitialValues = {
      priorityLevelId,
      location: 'unit',
      noTimeSpecified: false,
      requestDate: this.props.currentDate,
      repeatOrder: false,
      petAlert: false,
      unitLocationId: '',
      unitNumber: '',
      commonAreaLocationId: '',
    };
    const noTimeSpecified =
      workOrderToEdit.requestedStartTimeFrom === null &&
      workOrderToEdit.requestedStartTimeTo === null;

    const parsedInitials = {
      issueDescription: pathOr(
        '',
        ['issue', 'issueDescription'],
        workOrderToEdit,
      ),
      location: workOrderToEdit.commonAreaLocationId ? 'commonArea' : 'unit',
      unitNumber: workOrderToEdit.unitNumber ? workOrderToEdit.unitNumber : '',
      unitSearch: workOrderToEdit.unitNumber ? workOrderToEdit.unitNumber : '',
      createdAt: parseDate(workOrderToEdit.createdAt),
      requestDate: parseDate(workOrderToEdit.requestDate),
      requestedStartDateFrom: parseDate(workOrderToEdit.requestedStartDateFrom),
      requestedStartDateTo: parseDate(workOrderToEdit.requestedStartDateTo),
      requestedStartTimeFrom: parseTime(workOrderToEdit.requestedStartTimeFrom),
      requestedStartTimeTo: parseTime(workOrderToEdit.requestedStartTimeTo),
      lastStatusChangeDate: parseDate(workOrderToEdit.lastStatusChangeDate),
      finishedDate: parseDate(workOrderToEdit.finishedDate),
      issueNotFound: workOrderToEdit.otherDescription ? true : null,
      staffRequested: workOrderToEdit.staffRequested,
      petAlerts: this.generateInitialPetAlerts(workOrderToEdit.petAlerts),
    };

    const initialValues = !workOrderId
      ? defaultInitialValues
      : {
          ...defaultInitialValues,
          ...workOrderToEdit,
          ...parsedInitials,
          noTimeSpecified,
        };
    const isEditMode = !!workOrderId;
    const isLoading =
      !this.props.selectedProperty?.id?.length && isEditMode
        ? initialValues?.issueDescription?.length
        : false;

    return (
      <PrintProvider intl={this.props.intl}>
        <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
          <AsyncBox loading={isLoading}>
            {this.props.selectedProperty?.id && (
              <WorkOrderContext.Provider value={{ workOrderToEdit }}>
                <WorkOrderForm
                  intl={this.props.intl}
                  searchIssues={searchIssues}
                  searchUnits={searchUnits}
                  unitNumbers={this.props.unitOptions}
                  unitLocations={unitLocations}
                  commonAreaLocations={commonAreaLocations}
                  entryPermissions={entryPermissions}
                  priorityLevels={priorityLevels}
                  workOrderAssignees={workOrderAssignees}
                  workOrderIssues={workOrderIssues}
                  onSubmit={this.handleSubmit}
                  history={this.props.history}
                  initialValues={initialValues}
                  isEditMode={!!workOrderId}
                  statuses={statuses}
                  workOrder={workOrderToEdit}
                  isSubmitting={isSubmitting}
                  data-test="work-order"
                  selectedProperty={this.props.selectedProperty}
                />
              </WorkOrderContext.Provider>
            )}
          </AsyncBox>
        </DocumentTitle>
      </PrintProvider>
    );
  }
}

export const mapStateToProps = ({
  app,
  editWorkOrder: { workOrder, isSubmitting },
  currentProperty: { unitOptions },
}: Object): any => {
  return {
    currentUser: app.currentUser.user,
    commonAreaLocations: app.commonAreaLocations,
    entryPermissions: app.entryPermissions,
    priorityLevels: app.priorityLevels,
    unitLocations: app.unitLocations,
    workOrderIssues: app.workOrderIssues,
    selectedProperty: app.selectedProperty,
    workOrderAssignees: app.workOrderAssignees,
    workOrderStatusList: app.workOrderStatusList,
    workOrderToEdit: workOrder,
    isSubmitting,
    unitOptions,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  const actions = bindActionCreators(
    {
      createWorkOrder,
      getAllUnits,
      getWorkOrder,
      saveWorkOrder,
      clearLoadedWorkOrder,
      getAllCommonAreaLocations,
      getAllEntryPermissions,
      getAllPriorityLevels,
      getAllUnitLocations,
      getAllWorkOrderIssues,
      getWorkOrderAssignees,
      getAllWorkOrderStatus,
      selectProperty,
    },
    dispatch,
  );
  return { actions };
};

const InjectedWorkOrderForm = injectIntl(CreateEditWorkOrder);

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedWorkOrderForm);
