import React from 'react';
import { reduxForm } from 'redux-form';
import * as R from 'ramda';
import * as constants from './constants';
// eslint-disable-next-line
import MonthlyLeaseChargesOrCredits from '../../../LeaseDataTab/LeaseDataTabForm/LeaseDataTabFormSections/MonthlyLeaseChargesOrCredits';
import * as hooks from '../../../LeaseDataTab/LeaseDataTabForm/hooks';

function CommercialMonthlyLeaseChargesOrCredits(props: Object) {
  const {
    applicationId,
    lease,
    canComplete,
    residentId,
    selectedMonthlyOption,
    formValues,
    unitId,
  } = props;

  const alternateNamedValues = [
    'overallLeaseComments',
    'leaseTypeId',
    'retailTypeId',
    'leaseEffectiveDate',
    'leaseEndDate',
    'commencementDate',
    'scheduledMoveInDate'
  ];
  const formValuesToRename = R.pick(alternateNamedValues, formValues);
  const augmentedFormValues = R.omit(alternateNamedValues, formValues);

  const comments = R.pathOr(
    '',
    ['overallLeaseComments'],
    formValues,
  );
  const commercialLeaseTypeId = R.pathOr(
    '',
    ['leaseTypeId'],
    formValuesToRename,
  );
  const commercialRetailTypeId = R.pathOr(
    '',
    ['retailTypeId'],
    formValuesToRename,
  );
  const effectiveDate = R.pathOr(
    '',
    ['leaseEffectiveDate'],
    formValuesToRename,
  );
  const endDate = R.pathOr('', ['leaseEndDate'], formValues);
  const startDate = R.pathOr(
    '',
    ['commencementDate'],
    formValuesToRename,
  );
  const moveInDate = R.pathOr('', ['scheduledMoveInDate'], formValuesToRename)
  const parsedFormValues = {
    ...augmentedFormValues,
    comments,
    commercialLeaseTypeId,
    commercialRetailTypeId,
    effectiveDate,
    endDate,
    moveInDate,
    startDate,
    unitId,
  };
  const leaseId = R.pathOr('', ['lease', 'id'], props);
  const {
    allMonthlyTransactions,
    updateTransactionsList,
  } = hooks.useAsyncMonthlyLeaseTransactions(leaseId);

  return (
    <MonthlyLeaseChargesOrCredits
      {...props}
      applicationId={applicationId}
      lease={lease}
      residentId={residentId}
      selectedMonthlyOption={selectedMonthlyOption}
      formValues={ parsedFormValues }
      allMonthlyTransactions={allMonthlyTransactions}
      updateTransactionsList={updateTransactionsList}
      canComplete={canComplete}
    />
  );
}

export default reduxForm({
  form: constants.FORM_NAME,
  enableReinitialize: true,
})(CommercialMonthlyLeaseChargesOrCredits);
