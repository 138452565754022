import React from 'react';
import { connect } from 'react-redux';
import { sortDepositsByDate } from '../actions';

import { formatPeriod } from '../../../utils/redux-form-helper.js';
import messages from './messages';
import type { Deposit } from '../types.js';

type Props = {
  intl: Object,
  deposits: Array<Deposit>,
  onView: Function
} & {
  dateSort: 'none' | 'asc' | 'dec',
  onSortByDateClick: Function
};

const renderDepositRows = (intl, deposits, onView) => {
  const { formatDate, formatNumber } = intl;
  return deposits
    ? deposits.map((transaction, i) => {
        const {
          id,
          propertyBankAccounts: { bankAccountType },
          name,
          batchDate,
          propertyFiscalPeriods: { period },
          batchTotal,
          batchNote,
          adjustments
        } = transaction;
        return (
          <tr key={i}>
            <td>Deposited</td>
            <td>{bankAccountType}</td>
            <td>{name}</td>
            <td>
              {formatDate(batchDate, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              })}
            </td>
            <td>{formatPeriod(period)}</td>
            <td>
              {formatNumber(batchTotal, {
                style: 'currency',
                currency: 'USD'
              })}
            </td>
            <td>{batchNote}</td>
            <td>{adjustments}</td>
            <td>
              <button
                className="btn btn-sm btn-default"
                href="#"
                onClick={() => onView(id)}
              >
                <i className="et-pencil" />
              </button>
            </td>
          </tr>
        );
      })
    : null;
};

const getSortIcon = (sortType: 'none' | 'asc' | 'dec') =>
  `et-${
    sortType === 'none'
      ? 'sortable'
      : sortType === 'asc'
        ? 'ascending'
        : 'descending'
  } sort-option`;

export const ManageDepositsDetails = ({
  intl,
  deposits,
  onView,
  dateSort,
  onSortByDateClick
}: Props) => {
  const { formatMessage } = intl;
  return (
    <div className="container-fluid" data-test="deposits-details">
      <div className="table-scroll table-deposits-container">
        <table className="table table-prospects table-striped">
          <thead className="table-header">
            <tr>
              <th>{formatMessage(messages.status)}</th>
              <th>{formatMessage(messages.bankAccount)}</th>
              <th>{formatMessage(messages.depositId)}</th>
              <th>
                {formatMessage(messages.date)}{' '}
                <i
                  className={getSortIcon(dateSort)}
                  onClick={() => onSortByDateClick()}
                />
              </th>
              <th>{formatMessage(messages.period)}</th>
              <th>{formatMessage(messages.amount)}</th>
              <th>{formatMessage(messages.notes)}</th>
              <th>{formatMessage(messages.adjustments)}</th>
              <th>{formatMessage(messages.view)}</th>
            </tr>
          </thead>
          {deposits ? (
            <tbody data-test="deposits-table">
              {renderDepositRows(intl, deposits, onView)}
            </tbody>
          ) : null}
        </table>
      </div>
    </div>
  );
};

export default connect(
  (state: any) => ({
    dateSort: state.financials.deposits.dateSort
  }),
  { onSortByDateClick: sortDepositsByDate }
)(ManageDepositsDetails);
