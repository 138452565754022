import { put, select, throttle } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { Action } from '../../containers/App/types';
import { actions as toastrActions } from 'react-redux-toastr';
import {
  CREATE_INSURANCE,
  EDIT_INSURANCE,
  DELETE_INSURANCE,
  CREATE_CONTACT,
  EDIT_CONTACT,
  DELETE_CONTACT,
} from './constants';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../../containers/App/selectors';
import messages from './messages';
import InsurancesService from '../../services/insurancesService';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import ContactsService from '../../services/commercialContactsService';

export function* createInsurance(action: Action<any>): Saga<void> {
  try {
    const { householdId, insurance, refreshInsurances } = action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const insurancesService = new InsurancesService();

    yield insurancesService.create(
      organizationId,
      selectedProperty.id,
      householdId,
      insurance,
    );

    refreshInsurances();

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.createInsuranceSuccess),
        title: renderTranslatedMessage(messages.success),
        options: { showCloseButton: true, removeOnHover: true },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.createInsuranceError),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* createInsuranceSaga(): Saga<void> {
  yield throttle(500, CREATE_INSURANCE, createInsurance);
}

export function* editInsurance(action: Action<any>): Saga<void> {
  try {
    const {
      householdId,
      insurance,
      insuranceId,
      refreshInsurances,
    } = action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const insurancesService = new InsurancesService();

    yield insurancesService.edit(
      organizationId,
      selectedProperty.id,
      householdId,
      insuranceId,
      insurance,
    );

    refreshInsurances();

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.editInsuranceSuccess),
        title: renderTranslatedMessage(messages.success),
        options: { showCloseButton: true, removeOnHover: true },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.editInsuranceError),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* editInsuranceSaga(): Saga<void> {
  yield throttle(500, EDIT_INSURANCE, editInsurance);
}

export function* deleteInsurance(action: Action<any>): Saga<void> {
  try {
    const { householdId, insuranceId, refreshInsurances } = action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const insurancesService = new InsurancesService();

    yield insurancesService.delete(
      organizationId,
      selectedProperty.id,
      householdId,
      insuranceId,
    );

    refreshInsurances();

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.deleteInsuranceSuccess),
        title: renderTranslatedMessage(messages.success),
        options: { showCloseButton: true, removeOnHover: true },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.deleteInsuranceError),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* deleteInsuranceSaga(): Saga<void> {
  yield throttle(500, DELETE_INSURANCE, deleteInsurance);
}

export function* createContact(action: Action<any>): Saga<void> {
  try {
    const { householdId, contact, refreshContacts } = action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const contactsService = new ContactsService();

    yield contactsService.create(
      organizationId,
      selectedProperty.id,
      householdId,
      contact,
    );

    refreshContacts();

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.createContactSuccess),
        title: renderTranslatedMessage(messages.success),
        options: { showCloseButton: true, removeOnHover: true },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.createContactError),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* createContactSaga(): Saga<void> {
  yield throttle(500, CREATE_CONTACT, createContact);
}

export function* editContact(action: Action<any>): Saga<void> {
  try {
    const { householdId, contact, contactId, refreshContacts } = action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const contactsService = new ContactsService();

    yield contactsService.edit(
      organizationId,
      selectedProperty.id,
      householdId,
      contactId,
      contact,
    );

    refreshContacts();

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.editContactSuccess),
        title: renderTranslatedMessage(messages.success),
        options: { showCloseButton: true, removeOnHover: true },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.editContactError),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* editContactSaga(): Saga<void> {
  yield throttle(500, EDIT_CONTACT, editContact);
}

export function* deleteContact(action: Action<any>): Saga<void> {
  try {
    const { householdId, contactId, refreshContacts } = action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const contactsService = new ContactsService();

    yield contactsService.delete(
      organizationId,
      selectedProperty.id,
      householdId,
      contactId,
    );

    refreshContacts();

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.deleteContactSuccess),
        title: renderTranslatedMessage(messages.success),
        options: { showCloseButton: true, removeOnHover: true },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.deleteContactError),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* deleteContactSaga(): Saga<void> {
  yield throttle(500, DELETE_CONTACT, deleteContact);
}

export default [
  createInsuranceSaga,
  editInsuranceSaga,
  deleteInsuranceSaga,
  createContactSaga,
  editContactSaga,
  deleteContactSaga,
];
