export const GET_RECERTIFICATION_FORM_INFORMATION =
  'app/PrimaryLeaseApplication/GET_RECERTIFICATION_FORM_INFORMATION';
export const GET_RECERTIFICATION_FORM_INFORMATION_SUCCESS =
  'app/PrimaryLeaseApplication/GET_RECERTIFICATION_FORM_INFORMATION_SUCCESS';
export const GET_RECERTIFICATION_FORM_INFORMATION_ERROR =
  'app/PrimaryLeaseApplication/GET_RECERTIFICATION_FORM_INFORMATION_ERROR';

export const SAVE_RECERTIFICATION_FORM =
  'app/PrimaryLeaseApplication/SAVE_RECERTIFICATION_FORM';
export const SAVE_RECERTIFICATION_FORM_SUCCESS =
  'app/PrimaryLeaseApplication/SAVE_RECERTIFICATION_FORM_SUCCESS';
export const SAVE_RECERTIFICATION_FORM_ERROR =
  'app/PrimaryLeaseApplication/SAVE_RECERTIFICATION_FORM_ERROR';

export const CLEAN_RECERTIFICATION_FORM_INFORMATION =
  'app/PrimaryLeaseApp/CLEAN_RECERTIFICATION_FORM_INFORMATION';
export const PRINT_RECERTIFICATION_APPLICATION_FORM =
  'app/PrimaryLeaseApp/PRINT_RECERTIFICATION_APPLICATION_FORM';

export const FORM_NAME = 'recertificationApplication';

export const DEFAULT_EMPTY_EMPLOYER_INFO = {
  address: {
    city: '',
    state: '',
    street: '',
    zipCode: '',
  },
  annualSalary: '',
  employerName: '',
  from: '',
  phoneNumber: '',
  position: '',
  supervisorName: '',
  to: void 0,
};
