import { defineMessages } from 'react-intl';

const messages = defineMessages({
  streetAddressPlaceholder: {
    id: 'App.AddressSection.StreetAddressPlaceholder',
    defaultMessage: 'Street Address',
  },
  city: {
    id: 'App.AddressSection.City',
    defaultMessage: 'City',
  },
  state: {
    id: 'App.AddressSection.State',
    defaultMessage: 'State / Province',
  },
  zipCode: {
    id: 'App.AddressSection.ZipCode',
    defaultMessage: 'Zip Code / Postal Code',
  },
});

export default messages;
