export const GET_UNIT_BY_ID = 'app/ViewUnit/GET_UNIT_BY_ID';
export const GET_UNIT_BY_ID_SUCCESS = 'app/ViewUnit/GET_UNIT_BY_ID_SUCCESS';
export const GET_UNIT_BY_ID_ERROR = 'app/ViewUnit/GET_UNIT_BY_ID_ERROR';

export const GET_FLOORPLAN_BY_ID = 'app/ViewUnit/GET_FLOORPLAN_BY_ID';
export const GET_FLOORPLAN_BY_ID_SUCCESS =
  'app/ViewUnit/GET_FLOORPLAN_BY_ID_SUCCESS';
export const GET_FLOORPLAN_BY_ID_ERROR =
  'app/ViewUnit/GET_FLOORPLAN_BY_ID_ERROR';

export const GET_UNIT_STATUSES = 'app/ViewUnit/GET_UNIT_STATUSES';
export const GET_UNIT_STATUSES_SUCCESS =
  'app/ViewUnit/GET_UNIT_STATUSES_SUCCESS';
export const GET_UNIT_STATUSES_ERROR = 'app/ViewUnit/GET_UNIT_STATUSES_ERROR';

export const UPDATE_UNIT = 'app/ViewUnit/UPDATE_UNIT';
export const UPDATE_UNIT_SUCCESS = 'app/ViewUnit/UPDATE_UNIT_SUCCESS';
export const UPDATE_UNIT_ERROR = 'app/ViewUnit/UPDATE_UNIT_ERROR';

export const GET_AVERAGE_INCOME_DATA = 'app/ViewUnit/GET_AVERAGE_INCOME_DATA';
export const GET_AVERAGE_INCOME_DATA_SUCCESS =
  'app/ViewUnit/GET_AVERAGE_INCOME_DATA_SUCCESS';
export const GET_AVERAGE_INCOME_DATA_ERROR =
  'app/ViewUnit/GET_AVERAGE_INCOME_DATA_ERROR';

export const CLEAN_LOADED_UNITS = 'app/ViewUnit/CLEAN_LOADED_UNITS';

export const FORM_NAME = 'floorplansForm';
export const EDIT_FORM_NAME = 'EditFloorPlanForm';
export const enabledStatuses = [
  'Vacant - Ready',
  'Vacant / Not Ready',
  'Occupied / On Notice to Vacate',
];
export const disabledStatuses = [
  'Down',
  'Non Productive',
  'Property Model',
  'Property Office',
];
