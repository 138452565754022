import { useCallback } from 'react';
import useFetchSetData from '../../hooks/data-fetching/useFetchAndSetData';
import WaitlistService from '../../services/waitlistService';
import { WaitlistInfo } from './types';

const useWaitlistService = (
  organizationId: string,
  propertyId: string,
  applicationId: string,
) => {
  const waitlistInitialState = {
    id: '',
    waitlistStartTime: null,
    waitlistEndTime: null,
    waitlistNotes: '',
  };

  const getWaitlist = useCallback(async () => {
    const service = new WaitlistService(organizationId, propertyId);
    return service.getWaitlistById(applicationId);
  }, [organizationId, propertyId, applicationId]);

  const [localData, setLocalData, isLoading] = useFetchSetData(
    waitlistInitialState,
    getWaitlist,
  );

  const updateWaitlist = async (
    applicationId: string,
    newWaitlistInfo: WaitlistInfo,
  ) => {
    const service = new WaitlistService(organizationId, propertyId);
    await service.updateWaitlist(applicationId, newWaitlistInfo);
    setLocalData((previousData) => ({
      ...previousData,
      ...newWaitlistInfo,
    }));
  };

  const actions = {
    setLocalData,
    updateWaitlist,
    getWaitlist,
  };

  return [localData, actions, isLoading];
};

export default useWaitlistService;
