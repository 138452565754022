import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.Title',
    defaultMessage: 'Requestor Preferences',
  },
  name: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.Name',
    defaultMessage: 'Requestor Name*',
  },
  nameNotRequired: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.nameNotRequired',
    defaultMessage: 'Requestor Name',
  },
  phone: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.Phone',
    defaultMessage: 'Phone Number',
  },
  email: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.Email',
    defaultMessage: 'Email',
  },
  reportedDate: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.ReportedDate',
    defaultMessage: 'Reported Date*',
  },
  datePreference: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.DatePreference',
    defaultMessage: 'Date Preference',
  },
  timePreference: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.TimePreference',
    defaultMessage: 'Time Preference',
  },
  noTimePreference: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.NoTimePreference',
    defaultMessage: 'No time specified',
  },
  unitEntryPreference: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.unitEntryPreference',
    defaultMessage: 'Unit Entry Preference',
  },
  entryPreferenceForWO: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.entryPreferenceForWO',
    defaultMessage: 'Entry Preference for Work Order',
  },
  petAlert: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.PetAlert',
    defaultMessage: 'Pet Alert',
  },
  entryInstructions: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.EntryInstructions',
    defaultMessage: 'Entry Instructions',
  },
  alarm: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.alarm',
    defaultMessage: 'Alarm?',
  },
  alarmCode: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.alarmCode',
    defaultMessage: 'Alarm Code',
  },
  usesDoorCode: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.door',
    defaultMessage: 'Door Code?',
  },
  doorCode: {
    id: 'App.WorkOrder.WorkOrderForm.RequestorPreferences.doorCode',
    defaultMessage: 'Door Code',
  },
});

export default messages;
