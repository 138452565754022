import React from 'react';
import { pathOr } from 'ramda';
import styled from 'styled-components';

const ReasonLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
`;

const CapitalizedStringDiv = styled.div`
  text-transform: capitalize;
`;

const TUReasonList = ({ reasons = {} }: Object) => {
  const categories = ['credit', 'criminal', 'eviction', 'income'];
  const list = categories.reduce((acc, category) => {
    const code = pathOr('', [`${category}Code`], reasons);
    if (code && code !== 1) {
      acc.push(
        <CapitalizedStringDiv key={category}>{`${category} - ${
          reasons[`${category}Message`]
        }`}</CapitalizedStringDiv>,
      );
    }
    return acc;
  }, []);
  return list && list.length !== 0 ? (
    <div>
      <ReasonLabel>Reason:</ReasonLabel>
      {list}
    </div>
  ) : null;
};

export default TUReasonList;
