import React from 'react';

// Components
import BasicInformationSection from '../../LeaseApplicantFormCommonSections/BasicInformationSection';
import AffordableDisabilitySection from '../DisabilitySection';
import GenderSection from '../../LeaseApplicantFormCommonSections/GenderSection';
import AffordableRaceSection from '../../LeaseAffordableApplicantFormCommonSections/AffordableRaceSection';

// Types
import type { SelectOption } from '../../../containers/App/types';

type Props = {
  countries: Array<SelectOption>,
  suffixes: Array<SelectOption>,
  states: Array<SelectOption>,
  intl: Object,
  required?: boolean,
  disabilitiesDisabled: boolean,
  disabilitiesRequired: boolean,
  reduxFormOnChange: Function,
  basicInformationMultiRace: string[],
};

const BasicInformation = ({
  countries,
  suffixes,
  states,
  intl,
  required,
  disabilitiesDisabled,
  disabilitiesRequired,
  reduxFormOnChange,
  basicInformationMultiRace,
}: Props) => (
  <div>
    <BasicInformationSection
      countries={countries}
      suffixes={suffixes}
      states={states}
      intl={intl}
      required={required}
      isAffordable={true}
    />
    <GenderSection intl={intl} required={required} />
    <AffordableDisabilitySection
      intl={intl}
      required={required}
      disabilitiesDisabled={disabilitiesDisabled}
      disabilitiesRequired={disabilitiesRequired}
    />
    <AffordableRaceSection
      intl={intl}
      required={required}
      reduxFormOnChange={reduxFormOnChange}
      basicInformationMultiRace={basicInformationMultiRace}
    />
  </div>
);

export default BasicInformation;
