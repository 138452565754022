import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import moment from 'moment';

import AffordableCertificationsService from '../../../../services/affordableCertificationsService';
import AffordableQualificationService from '../../../../services/affordableQualificationService';
// eslint-disable-next-line max-len
import MasterAffordableProgramDocumentTypeService from '../../../../services/masterAffordableProgramDocumentTypeService';

export const useAsyncGenerate59a = (
  affordableQualificationId: string,
  selectedProperty: Object,
  toasterFn: Function,
) => {
  const [submitting, setSubmitting] = useState(false);
  const [generated59aUpdate, setGenerated59aUpdate] = useState(false);

  const handleGenerate59a = () => setSubmitting(true);

  const selectedPropertyId = pathOr(null, ['id'], selectedProperty);
  const organizationId = pathOr(null, ['organizationId'], selectedProperty);

  useEffect(() => {
    const generate59a = async () => {
      if (submitting && selectedPropertyId && organizationId) {
        const affordableQualificationService =
          new AffordableQualificationService();
        try {
          const successMsg = 'Generated 59A Successfully';
          const filename = `50059A_${moment().unix()}.pdf`;
          await affordableQualificationService.generate59(
            organizationId,
            selectedPropertyId,
            affordableQualificationId,
            filename,
          );
          toasterFn({
            message: successMsg,
            title: 'Success',
          });
          setGenerated59aUpdate(true);
        } catch (error) {
          const errorMsg = 'There was an error generating the 59A';
          toasterFn({
            type: 'error',
            message: error.toString(),
            title: errorMsg,
          });
        } finally {
          setSubmitting(false);
        }
      }
    };
    if (submitting && selectedPropertyId && organizationId) {
      generate59a();
    }
  }, [
    affordableQualificationId,
    organizationId,
    selectedPropertyId,
    toasterFn,
    submitting,
  ]);

  return { handleGenerate59a, generated59aUpdate, submitting };
};

export const useAsyncDownloadAll59As = (
  selectedProperty: Object,
  toasterFn: Function,
) => {
  const [submitting, setSubmitting] = useState(false);
  const [successfullyGenerated59As, setSuccessfullyGenerated59As] =
    useState(false);

  const handleGenerateAll59As = () => {
    setSubmitting(true);
    toasterFn({
      message: 'Will download when complete',
      title: 'Generating Document',
      type: 'info',
    });
  };

  const selectedPropertyId = pathOr(null, ['id'], selectedProperty);
  const organizationId = pathOr(null, ['organizationId'], selectedProperty);

  useEffect(() => {
    const generate59a = async () => {
      if (submitting && selectedPropertyId && organizationId) {
        const affordableQualificationService =
          new AffordableQualificationService();
        try {
          const successMsg = 'Generated All 59As Successfully';
          const date = moment().format('MM-DD-YYYY');
          const filename = `${date} - Gross Rent 59a Batch.pdf`;
          await affordableQualificationService.generateAndDownloadAll59As(
            organizationId,
            selectedPropertyId,
            filename,
          );
          toasterFn({
            message: successMsg,
            title: 'Success',
          });
          setSuccessfullyGenerated59As(true);
        } catch (error) {
          const errorMsg = 'There was an error generating the 59As batch';
          toasterFn({
            type: 'error',
            message: error.toString(),
            title: errorMsg,
          });
        } finally {
          setSubmitting(false);
        }
      }
    };
    if (submitting && selectedPropertyId && organizationId) {
      generate59a();
    }
  }, [organizationId, selectedPropertyId, toasterFn, submitting]);

  return { handleGenerateAll59As, successfullyGenerated59As, submitting };
};

export const useAsyncUploadSigned59a = (
  affordableQualificationId: string,
  selectedProperty: Object,
  toasterFn: Function,
) => {
  const [docToUpload, setDocToUpload] = useState(null);
  const [uploadedDocument, setUploadedDocument] = useState(null);

  const handleUploadSigned59a = (
    documentObject: Object,
    cleanupFn: Function,
  ) => {
    setDocToUpload({ data: documentObject, cleanupFn });
  };

  const selectedPropertyId = pathOr(null, ['id'], selectedProperty);
  const organizationId = pathOr(null, ['organizationId'], selectedProperty);

  useEffect(() => {
    const fetchUploadComplianceDocument = async () => {
      const mapdtService = new MasterAffordableProgramDocumentTypeService();
      const { data, cleanupFn } = docToUpload;
      try {
        const res = await mapdtService.uploadCompliancePacket(
          organizationId,
          selectedPropertyId,
          affordableQualificationId,
          data,
        );
        if (docToUpload.data) {
          setUploadedDocument(res);
          if (cleanupFn) {
            cleanupFn();
          }
        }

        toasterFn({
          message: 'Successfully Uploaded Signed 59A',
          title: 'Success',
        });
      } catch (error) {
        toasterFn({
          type: 'error',
          message: error.toString(),
          title: 'Error',
        });
      } finally {
        setDocToUpload(null);
      }
    };
    if (
      affordableQualificationId &&
      docToUpload &&
      selectedPropertyId &&
      organizationId
    ) {
      fetchUploadComplianceDocument();
    }
    return () => setDocToUpload(null);
  }, [
    affordableQualificationId,
    docToUpload,
    selectedPropertyId,
    organizationId,
    toasterFn,
  ]);

  return { handleUploadSigned59a, uploadedDocument };
};

export function useCancelCertification(
  affordableQualId: string,
  selectedProperty: Object,
  toasterFn: Function,
  refreshFn: Function,
) {
  const [canceling, setCanceling] = useState(false);

  const selectedPropertyId = pathOr(null, ['id'], selectedProperty);
  const organizationId = pathOr(null, ['organizationId'], selectedProperty);

  const handleCancelCertification = () => setCanceling(true);

  useEffect(() => {
    const cancelCertification = async () => {
      const service = new AffordableQualificationService();
      try {
        await service.cancelCertification(
          organizationId,
          selectedPropertyId,
          affordableQualId,
        );

        toasterFn({
          title: 'Success',
          message: 'Certification canceled.',
        });
        refreshFn();
      } catch (e) {
        toasterFn({
          type: 'error',
          message: e.toString(),
          title: 'Error',
        });
      } finally {
        setCanceling(false);
      }
    };
    if (canceling) {
      cancelCertification();
    }
  }, [
    organizationId,
    selectedPropertyId,
    affordableQualId,
    canceling,
    toasterFn,
    refreshFn,
  ]);

  return { handleCancelCertification, canceling };
}

export function useCompleteCertification(
  affordableQualId: string,
  selectedProperty: Object,
  toasterFn: Function,
  refreshFn: Function,
) {
  const [completing, setCompleting] = useState(false);

  const selectedPropertyId = pathOr(null, ['id'], selectedProperty);
  const organizationId = pathOr(null, ['organizationId'], selectedProperty);

  const handleCompleteCertification = () => setCompleting(true);

  useEffect(() => {
    const completeCertification = async () => {
      const service = new AffordableQualificationService();
      try {
        await service.completeCertification(
          organizationId,
          selectedPropertyId,
          affordableQualId,
          false,
        );

        toasterFn({
          title: 'Success',
          message: 'Certification completed',
        });
        refreshFn();
      } catch (e) {
        toasterFn({
          type: 'error',
          message: e.toString(),
          title: 'Error',
        });
      } finally {
        setCompleting(false);
      }
    };
    if (completing) {
      completeCertification();
    }
  }, [
    organizationId,
    selectedPropertyId,
    affordableQualId,
    completing,
    toasterFn,
    refreshFn,
  ]);

  return { handleCompleteCertification, completing };
}

export function useAsyncEditCertification(
  selectedProperty: Object,
  affordableQualId: string,
  toasterFn: Function,
) {
  const [submitValues, setSubmitValues] = useState(null);
  const [editedGRCert, setEditedGRCert] = useState(null);

  const selectedPropertyId = pathOr(null, ['id'], selectedProperty);
  const organizationId = pathOr(null, ['organizationId'], selectedProperty);

  const handleEditGRCert = (values: Object, cleanupFn: Function) => {
    setSubmitValues({ values, cleanupFn });
  };

  useEffect(() => {
    const editGRCert = async () => {
      const service = new AffordableCertificationsService();
      const { values, cleanupFn } = submitValues;
      try {
        const { message, data } = await service.editGrossRentCert(
          organizationId,
          selectedPropertyId,
          affordableQualId,
          values,
        );
        toasterFn({
          title: 'Success',
          message,
        });
        setEditedGRCert(JSON.stringify(data));
      } catch (e) {
        toasterFn({
          type: 'error',
          message: e.toString(),
          title: 'Error',
        });
      } finally {
        setSubmitValues(null);
        cleanupFn();
      }
    };
    if (submitValues) {
      editGRCert();
    }
  }, [
    organizationId,
    selectedPropertyId,
    affordableQualId,
    submitValues,
    toasterFn,
  ]);

  return { handleEditGRCert, editedGRCert };
}
