import { defineMessages } from 'react-intl';

const messages = defineMessages({
  prorateCalculatorEmail: {
    id: 'App.ProrateCalculator.Header.EmailModal.ProrateCalculatorEmail',
    defaultMessage: 'Prorate Calculator Email',
  },
  requiredFields: {
    id: 'App.ProrateCalculator.Header.EmailModal.RequiredFields',
    defaultMessage: 'Required Fields',
  },
  cancel: {
    id: 'App.ProrateCalculator.Header.EmailModal.Cancel',
    defaultMessage: 'Cancel',
  },
  sendEmail: {
    id: 'App.ProrateCalculator.Header.EmailModal.SendEmail',
    defaultMessage: 'Send Email',
  },
  fromEmail: {
    id: 'App.ProrateCalculator.Header.EmailModal.FromEmail',
    defaultMessage: 'From Email',
  },
  thisFieldIsRequired: {
    id: 'App.ProrateCalculator.Header.EmailModal.ThisFieldIsRequired',
    defaultMessage: 'This field is required',
  },
  selectStatus: {
    id: 'App.ProrateCalculator.Header.EmailModal.SelectStatus',
    defaultMessage: 'Select status',
  },
  prospect: {
    id: 'App.ProrateCalculator.Header.EmailModal.Prospect',
    defaultMessage: 'Prospect',
  },
  applicant: {
    id: 'App.ProrateCalculator.Header.EmailModal.Applicant',
    defaultMessage: 'Applicant',
  },
  current: {
    id: 'App.ProrateCalculator.Header.EmailModal.Current',
    defaultMessage: 'Current Resident',
  },
  recipients: {
    id: 'App.ProrateCalculator.Header.EmailModal.Recipients',
    defaultMessage: 'Recipient(s)',
  },
  recipientsNote: {
    id: 'App.ProrateCalculator.Header.EmailModal.RecipientsNote',
    defaultMessage:
      'This calculation will be sent to all leaseholders within the selected household.',
  },
  selectUnitResident: {
    id: 'App.ProrateCalculator.Header.EmailModal.SelectUnitResident',
    defaultMessage: 'Select Unit/Resident',
  },
  emailSubject: {
    id: 'App.ProrateCalculator.Header.EmailModal.EmailSubject',
    defaultMessage: 'Email Subject',
  },
  invalidEmail: {
    id: 'App.ProrateCalculator.Header.EmailModal.InvalidEmail',
    defaultMessage: 'Invalid Email',
  },
  messageBody: {
    id: 'App.ProrateCalculator.Header.EmailModal.MessageBody',
    defaultMessage: 'Message Body',
  },
  attachments: {
    id: 'App.ProrateCalculator.Header.EmailModal.Attachments',
    defaultMessage: 'Attachments',
  },
  emailSubjectMessage: {
    id: 'App.ProrateCalculator.Header.EmailModal.EmailSubjectMessage',
    defaultMessage: '{moveInMoveOut} Quote for {propertyName}',
  },
  messageBodyMessage: {
    id: 'App.ProrateCalculator.Header.EmailModal.MessageBodyMessage',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Attached you will find a copy of the {moveInMoveOut} Quote for {propertyName}. Please contact our office with any questions.',
  },
  attachmentsMessage: {
    id: 'App.ProrateCalculator.Header.EmailModal.AttachmentMessage',
    defaultMessage: '{moveInMoveOut} Quote - {date}.pdf',
  },
  success: {
    id: 'App.ProrateCalculator.Header.EmailModal.Success',
    defaultMessage: 'Success',
  },
  prorateCalculationEmailSent: {
    id: 'App.ProrateCalculator.Header.EmailModal.ProrateCalculationEmailSent',
    defaultMessage: 'Prorate Calculation Email Sent',
  },
  error: {
    id: 'App.ProrateCalculator.Header.EmailModal.Error',
    defaultMessage: 'Error',
  },
});

export default messages;
