import { pathOr } from 'ramda';
import { createSelector } from 'reselect';
import type { GlobalState } from '../App/types';

const getEditApplicationHouseholdState = (state: GlobalState) =>
  state.editApplicationHousehold;

export const getApplication = createSelector(
  getEditApplicationHouseholdState,
  ({ currentApplication }) => currentApplication
);

export const getApplicantNames = createSelector(
  getApplication,
  (application) => {
    const applicants = pathOr([], ['applicants'], application);

    return applicants
      .reduce((allNames, currentApplicant) => {
        if (currentApplicant.applicantCustomer) {
          const {
            customer: { firstName, lastName }
          } = currentApplicant.applicantCustomer;
          allNames.push(`${firstName} ${lastName}`);
        }
        return allNames;
      }, [])
      .join(', ');
  }
);

export const getPropertyId = createSelector(
  getApplication,
  (application) => (application ? application.propertyId : '')
);
