import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageProspects.EditPendingActivityModal.Title',
    defaultMessage: 'Edit Activity',
  },
  activityTypeLabel: {
    id: 'App.ManageProspects.EditPendingActivityModal.ActivityTypeLabel',
    defaultMessage: 'Type of Activity',
  },
  activityCompletionLabel: {
    id: 'App.ManageProspects.EditPendingActivityModal.ActivityCompletionLabel',
    defaultMessage: 'How was this activity completed?',
  },
  activityDateLabel: {
    id: 'App.ManageProspects.EditPendingActivityModal.ActivityDateLabel',
    defaultMessage: 'Activity date',
  },
  startTimeLabel: {
    id: 'App.ManageProspects.EditPendingActivityModal.StartTimeLabel',
    defaultMessage: 'Start time',
  },
  endTimeLabel: {
    id: 'App.ManageProspects.EditPendingActivityModal.EndTimeLabel',
    defaultMessage: 'End time',
  },
  scheduleByLabel: {
    id: 'App.ManageProspects.EditPendingActivityModal.ScheduleByLabel',
    defaultMessage: 'Scheduled for',
  },
  notesLabel: {
    id: 'App.ManageProspects.EditPendingActivityModal.NotesLabel',
    defaultMessage: 'Notes',
  },
  notesPlaceholder: {
    id: 'App.ManageProspects.EditPendingActivityModal.NotesPlaceHolder',
    defaultMessage: 'Start typing...',
  },
  noTimeLabel: {
    id: 'App.ManageProspects.EditPendingActivityModal.NoTimeLabel',
    defaultMessage: 'Do not specify a time',
  },
  note: {
    id: 'App.ManageProspects.EditPendingActivityModal.Note',
    defaultMessage: 'Note',
  },
  noteContent: {
    id: 'App.ManageProspects.EditPendingActivityModal.NoteContent',
    defaultMessage:
      'The user who saves the activity record will be listed as the “Completed By” user, regardless of who the activity was scheduled for.', // eslint-disable-line
  },
  notesLengthWarning: {
    id: 'App.ManageProspects.EditPendingActivityModal.WotesLengthWarning',
    defaultMessage: '{current} of {max} max length.',
  },
  notesRequired: {
    id: 'App.ManageProspects.EditPendingActivityModal.NotesRequired',
    defaultMessage: 'Notes are required',
  },
  invalidTime: {
    id: 'App.ManageProspects.EditPendingActivityModal.InvalidTime',
    defaultMessage: 'Invalid time value',
  },
  invalidDate: {
    id: 'App.ManageProspects.EditPendingActivityModal.InvalidDate',
    defaultMessage: 'Invalid date value',
  },
  noOverlappingActivities: {
    id: 'App.ManageProspects.EditPendingActivityModal.NoOverlappingActivities',
    defaultMessage:
      'Activity timeframe conflicts with an activity already scheduled, please review activity date and time.',
  },
  tooManyActivitiesForAssignee: {
    id: 'App.ManageProspects.EditPendingActivityModal.TooManyActivitiesForAssignee',
    defaultMessage:
      'User cannot have more than 200 activities scheduled. Please complete some activities to continue.',
  },
  tooManyActivitiesForProspect: {
    id: 'App.ManageProspects.EditPendingActivityModal.TooManyActivitiesProspect',
    defaultMessage:
      'Prospect cannot have more than 5 activities scheduled. Please complete some activities to continue.',
  },
  noStartDateInFuture: {
    id: 'App.ManageProspects.EditPendingActivityModal.NoStartDateInFuture',
    defaultMessage:
      'You cannot add an activity record in the future, please review activity date and time.',
  },
  noStartDateInPast: {
    id: 'App.ManageProspects.EditPendingActivityModal.NoStartDateInPast',
    defaultMessage:
      'You cannot schedule a follow-up in the past, please review activity date and time.',
  },
  noEndDateBeforeStartDate: {
    id: 'App.ManageProspects.EditPendingActivityModal.NoEndDateBeforeStartDate',
    defaultMessage:
      'Start time needs to be before end time. Please review date and time.',
  },
  schedulingDetails: {
    id: 'App.ManageProspects.EditPendingActivityModal.SchedulingDetails',
    defaultMessage: 'Scheduling Details',
  },
  futureActivityDate: {
    id: 'App.ManageProspects.EditPendingActivityModal.futureActivityDate',
    defaultMessage:
      'You cannot complete a future activity. Please correct the activity date.',
  },
  pastDateNoCompletion: {
    id: 'App.ManageProspects.EditPendingActivityModal.pastDateNoCompletion',
    defaultMessage:
      'You cannot schedule a non-completed activity in the past. Please review activity date or complete the activity.',
  },
  tooFarPast: {
    id: 'App.ManageProspects.EditPendingActivityModal.tooFarPast',
    defaultMessage:
      'You cannot record an activity before the prospect was created.',
  },
  allFieldsRequired: {
    id: 'App.ManageProspects.EditPendingActivityModal.AllFieldsRequired',
    defaultMessage: '*All fields are required',
  },
  prospect: {
    id: 'App.ManageProspects.EditPendingActivityModal.Prospect',
    defaultMessage: 'Prospect',
  },
  applicant: {
    id: 'App.ManageProspects.EditPendingActivityModal.Applicant',
    defaultMessage: 'Applicant',
  },
  resident: {
    id: 'App.ManageProspects.EditPendingActivityModal.Resident',
    defaultMessage: 'Resident',
  },
  'prior-resident': {
    id: 'App.ManageProspects.EditPendingActivityModal.PriorResident',
    defaultMessage: 'Prior Resident',
  },
  viewProfileButton: {
    id: 'App.ManageProspects.EditPendingActivityModal.ViewProfileButton',
    defaultMessage: 'View Profile',
  },
  delete: {
    id: 'App.PeopleProfile.EditPendingActivityModal.Delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'App.PeopleProfile.EditPendingActivityModal.Cancel',
    defaultMessage: 'Cancel',
  },
  saveAndClose: {
    id: 'App.PeopleProfile.EditPendingActivityModal.SaveAndClose',
    defaultMessage: 'Save and Close',
  },
  saveAndAddNew: {
    id: 'App.PeopleProfile.EditPendingActivityModal.SaveAndAddNew',
    defaultMessage: 'Save and Add New',
  },
  scheduledActivity: {
    id: 'App.PeopleProfile.EditPendingActivityModal.ScheduledActivity',
    defaultMessage: 'Scheduled Activity',
  },
  deleteConfirmationHeader: {
    id: 'App.PeopleProfile.EditPendingActivityModal.DeleteConfirmationHeader',
    defaultMessage: 'Are you sure you want to delete?',
  },
  cancelConfirmationHeader: {
    id: 'App.PeopleProfile.EditPendingActivityModal.CancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to close without saving?',
  },
  deleteConfirmationText: {
    id: 'App.PeopleProfile.EditPendingActivityModal.DeleteConfirmationText',
    defaultMessage:
      ' This action cannot be undone! To return to the edit view, click the {noText} option below.',
  },
  yes: {
    id: 'App.PeopleProfile.EditPendingActivityModal.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.PeopleProfile.EditPendingActivityModal.No',
    defaultMessage: 'No',
  },
  chooseOption: {
    id: 'App.PeopleProfile.EditPendingActivityModal.ChooseAnOption',
    defaultMessage: 'Choose an option',
  },
  calendarFor: {
    id: 'App.PeopleProfile.EditPendingActivityModal.calendarFor',
    defaultMessage: 'Calendar for',
  },
});

export default messages;
