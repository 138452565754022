import { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Button } from 'react-bootstrap';
import MultiselectTwoSides from '@fortress-technology-solutions/react-multiselect-two-sides';
import {
  Input,
  Spinner,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import confirm from '../../components/ConfirmDialogModal';
import useFormatMessage from '../../hooks/useFormatMessage';
import messages from './messages';
import useSelectedProperty from '../../hooks/useSelectedProperty';

const MultiSelectWrapper = styled.span`
  margin-top: 12px;
  & .msts_theme_example {
    margin-top: 5px !important;
  }
`;

const ERROR_COLOR = '#D70000';

function AmenityForm({
  initialValues,
  initialSelectedUnitIds,
  isUnitOptionsReady,
  unitOptions,
  onSubmit,
  history,
}) {
  const formatMessage = useFormatMessage();
  const selectedProperty = useSelectedProperty();
  const {
    register,
    formState: { errors, isValid: isFormValid, isDirty },
    handleSubmit: formHandleSubmit,
  } = useForm({
    mode: 'onBlur',
    defaultValues: initialValues,
  });
  const [selectedUnitIds, setSelectedUnitIds] = useState(
    initialSelectedUnitIds ?? [],
  );

  return (
    <>
      <Typography
        variant="reputationMgmtColumnLabel"
        sx={{
          fontSize: 13,
          color: errors.amenityName ? ERROR_COLOR : undefined,
        }}
      >
        {formatMessage(messages.amenityNameField)}*
      </Typography>
      <div style={{ marginBottom: 5 }} />
      <Input
        placeholder={formatMessage(messages.amenityNameFieldPlaceholder)}
        fullWidth
        error={errors.amenityName?.message}
        helperText={errors.amenityName?.message}
        autoFocus
        {...register('amenityName', {
          required: formatMessage(messages.requiredError),
          maxLength: {
            message: formatMessage(messages.maxLengthError),
            value: 80,
          },
        })}
      />
      <Typography
        variant="reputationMgmtColumnLabel"
        sx={{ fontSize: 13, color: errors.feeAmount ? ERROR_COLOR : undefined }}
      >
        {formatMessage(messages.amenityValueField)}*
      </Typography>
      <div style={{ marginBottom: 5 }} />
      <div className="input-group">
        <div className="input-group-addon">$</div>
        <input
          className="input-lg form-control"
          step="0.01"
          placeholder="0.00"
          type="number"
          {...register('feeAmount', {
            min: {
              value: -999999,
              message: `${formatMessage(
                messages.minimumValueError,
              )} -$999,999.00.`,
            },
            max: {
              value: 999999,
              message: `${formatMessage(messages.maxValueError)} $999,999.00.`,
            },
            required: formatMessage(messages.requiredError),
            valueAsNumber: true,
          })}
        />
      </div>
      {errors.feeAmount?.message && (
        <Typography
          sx={{
            fontSize: 12,
            color: ERROR_COLOR,
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          }}
        >
          {errors.feeAmount?.message}
        </Typography>
      )}
      {isUnitOptionsReady ? (
        <MultiSelectWrapper>
          <Typography variant="reputationMgmtColumnLabel" sx={{ fontSize: 13 }}>
            {formatMessage(messages.assignToUnits)}
          </Typography>
          <MultiselectTwoSides
            className="msts_theme_example"
            onChange={(selection) => {
              setSelectedUnitIds(selection);
            }}
            availableHeader={formatMessage(messages.availableHeader)}
            availableFooter={`${formatMessage(messages.availableFooter)}: ${
              unitOptions.length
            }`}
            selectedHeader={formatMessage(messages.selectedHeader)}
            selectedFooter={`${formatMessage(messages.selectedFooter)}: ${
              selectedUnitIds.length
            }`}
            placeholder={formatMessage(messages.filterPlaceholder)}
            options={unitOptions}
            searchable={true}
            showControls={true}
            value={selectedUnitIds}
          />
        </MultiSelectWrapper>
      ) : (
        <Spinner />
      )}
      <Stack direction="row" justifyContent="center">
        <Button
          onClick={() => {
            if (!isDirty) {
              history.push(`/property/${selectedProperty.id}/manage-amenities`);
              return;
            }

            confirm(formatMessage(messages.cancelConfirmText)).then(() =>
              history.push(`/property/${selectedProperty.id}/manage-amenities`),
            );
          }}
        >
          {formatMessage(messages.cancelBtn)}
        </Button>
        <div style={{ marginRight: 15 }} />
        <Button
          className="btn btn-primary pull-left btn-xs-block"
          onClick={formHandleSubmit((data) =>
            onSubmit({ ...data, selectedUnitIds }),
          )}
          disabled={!isFormValid}
        >
          {formatMessage(messages.saveAndCloseBtn)}
        </Button>
      </Stack>
    </>
  );
}

export default AmenityForm;
