import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.CreateRole.CreateRoleForm.Title',
    defaultMessage: 'Fortress - Add role'
  },
  header: {
    id: 'App.AddRole.Header',
    defaultMessage: 'Add new role'
  },
  chooseOption: {
    id: 'App.AddRole.ChooseAnOption',
    defaultMessage: 'Choose an option'
  },
  successHeader: {
    id: 'App.AddRole.SuccessHeader',
    defaultMessage: 'Success'
  },
  successDescription: {
    id: 'App.AddRole.SuccessDescription',
    defaultMessage: 'The role was added.'
  },
  errorHeader: {
    id: 'App.AddRole.ErrorHeader',
    defaultMessage: 'Error'
  },
  cancelConfirmation: {
    id: 'App.AddRole.CancelConfirmation',
    defaultMessage: 'Are you sure you want to leave?'
  }
});

export default messages;
