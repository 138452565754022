import keyBy from 'lodash.keyby';
import { useMemo } from 'react';
import { useFetchConversations } from '../../hooks/data-fetching/useFetchConversations';
import useHasPermission from '../../hooks/useHasPermission';

export function injectConversations(Component) {
  function HOC(props) {
    const customerIds = useMemo(
      () => props.priorResidents.map((r) => r.customerIds).flat(),
      [props.priorResidents],
    );

    const hasCommunicationCreatePermission = useHasPermission(
      'communication-create',
    );
    const showTextingColumn =
      props.selectedProperty.isTwoWayCommunicationActive &&
      hasCommunicationCreatePermission;

    const [conversations] = useFetchConversations(
      customerIds,
      showTextingColumn,
    );

    const householdConversationsStatusDictionary = useMemo(() => {
      const conversationsDictionary = keyBy(
        conversations,
        (c) => c.recipient.customerId,
      );

      return props.priorResidents.reduce((acc, priorResident) => {
        const householdConversationsStatus = priorResident.customerIds.reduce(
          (acc, customerId) => {
            const conversation = conversationsDictionary[customerId];

            if (conversation) {
              acc[conversation.conversationStatus] += 1;
            }

            return acc;
          },
          { NEW: 0, UNRESOLVED: 0, RESOLVED: 0 },
        );

        householdConversationsStatus.TOTAL =
          householdConversationsStatus.NEW +
          householdConversationsStatus.UNRESOLVED;

        acc[priorResident.residentId] = householdConversationsStatus;

        return acc;
      }, {});
    }, [conversations, props.priorResidents]);

    return (
      <Component
        householdConversationsStatusDictionary={
          householdConversationsStatusDictionary
        }
        showTextingColumn={showTextingColumn}
        {...props}
      />
    );
  }

  return HOC;
}
