import { defineMessages } from 'react-intl';

const messages = defineMessages({
  mobileAppSetup: {
    id: 'App.mobileAppSetup.mobileAppSetup',
    defaultMessage: 'Mobile App Setup',
  },
  workOrders: {
    id: 'App.mobileAppSetup.workOrders',
    defaultMessage: 'Work Orders',
  },
  residentCommunication: {
    id: 'App.mobileAppSetup.residentCommunication',
    defaultMessage: 'Resident Communication',
  },
  on: {
    id: 'App.mobileAppSetup.on',
    defaultMessage: 'On',
  },
  off: {
    id: 'App.mobileAppSetup.off',
    defaultMessage: 'Off',
  },
  tooltipText: {
    id: 'App.mobileAppSetup.tooltip',
    defaultMessage: `When this feature is On, an SMS and Email will be automatically sent to the 
      resident on-file when a work order has been updated through the mobile app, Fortress Hub.`,
  },
});

export default messages;
