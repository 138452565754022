import { defineMessages } from 'react-intl';

const messages = defineMessages({
  amount: {
    id: 'App.Ledger.LedgerFilterSection.LedgerFilterLayout.FilterControls.Amount',
    defaultMessage: 'Amount',
  },
  range: {
    id: 'App.Ledger.LedgerFilterSection.LedgerFilterLayout.FilterControls.Range',
    defaultMessage: 'Range',
  },
  amountValidationError: {
    id: 'App.Ledger.LedgerFilterSection.LedgerFilterLayout.FilterControls.AmountValidationError',
    defaultMessage: 'Please choose a greater to amount',
  },
  dateValidationError: {
    id: 'App.Ledger.LedgerFilterSection.LedgerFilterLayout.FilterControls.DateValidationError',
    defaultMessage: 'Invalid Date Format',
  },
});

export default messages;
