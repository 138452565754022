// @flow

import React from 'react';
import { Redirect } from 'react-router-dom';
import { ValidationStates } from './types';
import type { ValidationState } from './types';

export type InherittedProps = {
  component: any,
  validation: ValidationState,
};

export type Props = {
  location: string,
};

export type RedirectToProps = {
  pathname: string,
  location: string,
};

const RedirectTo = ({ pathname, location }: RedirectToProps) => {
  return (
    <Redirect
      to={{
        pathname: pathname,
      }}
    />
  );
};

export default (
  { component: Component, validation }: InherittedProps,
  props: Props,
): any => {
  switch (validation) {
    case ValidationStates.UNAUTHORIZED:
      return <RedirectTo pathname="/login" location={props.location} />;
    case ValidationStates.FORBIDDEN:
      return <RedirectTo pathname="/forbidden" location={props.location} />;
    case ValidationStates.NOT_ACCEPTABLE:
      return (
        <RedirectTo pathname="/select-property" location={props.location} />
      );
    default:
      return <Component {...props} />;
  }
};
