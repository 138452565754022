import React, { useState, useEffect, useCallback } from 'react';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../components/Spinner';
import { reduxForm } from 'redux-form';
import DashesIfNullOrUndefined from '../../../components/DashesIfNullOrUndefined';
import { Row, Col, Button } from 'react-bootstrap';
import { sortHouseholds } from '../utils';
import SubsidyBatchRow from './SubsidyBatchRow';
import InlineSubsidyBatchRow from './InlineSubsidyBatchRow';
import { v4 as uuid } from 'uuid';
import ConfirmPostDepositModal from './confirmPostDepositModal';
import ConfirmDeleteSubsidyBatch from './confirmDeleteSubsidyBatch';
import styled from 'styled-components';
import UploadDocuments from '../UploadDocuments';
import { navigateToUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

const StyledForm = styled.form`
  .form-group {
    margin-bottom: 0;
  }
`;

type Props = {
  intl: Object,
  isSaved: boolean,
  householdsWithSubjournalBalance: Array<Object>,
  householdsTransactions: Array,
  saveSubsidyPaymentBatchDetail: Function,
  transactionCodes: Object,
  history: Object,
  initialValues: Object,
  isLoading: boolean,
  households: Object,
  deletePaymentBatchDetail: Function,
  postAndDepositSubsidyBatch: Function,
  selectedPropertyId: string,
  selectedOrganizationId: string,
  promptToaster: Function,
  deleteSubsidyPaymentBatch: Function,
  isDeletePaymentBatchLoading: boolean,
};

const TableFooter = ({
  intl,
  totals,
  totalDepositAmount,
  totalFinalSubBalance,
}) => (
  <tr>
    <td style={{ width: '20px', padding: '0px', paddingLeft: '10px' }}></td>
    <td className="text-center">
      <FormattedMessage {...messages.totals} />
    </td>
    <td></td>

    <td className="text-center">
      <DashesIfNullOrUndefined
        data={intl.formatNumber(+totals, {
          style: 'currency',
          currency: 'USD',
        })}
      />
    </td>
    <td className="text-center">
      <DashesIfNullOrUndefined
        data={intl.formatNumber(totalDepositAmount, {
          style: 'currency',
          currency: 'USD',
        })}
      />
    </td>
    <td className="text-center">
      <DashesIfNullOrUndefined
        data={intl.formatNumber(totalFinalSubBalance, {
          style: 'currency',
          currency: 'USD',
        })}
      />
    </td>
    <td colSpan={4}></td>
  </tr>
);

export const CreateSubsidyBatchDetails = (props: Props) => {
  const {
    intl,
    isSaved,
    householdsWithSubjournalBalance,
    saveSubsidyPaymentBatchDetail,
    transactionCodes,
    history,
    initialValues,
    isLoading,
    notInitialLoad,
    households,
    batchHeader,
    deletePaymentBatchDetail,
    postAndDepositSubsidyBatch,
    selectedPropertyId,
    selectedOrganizationId,
    promptToaster,
    deleteSubsidyPaymentBatch,
    isDeletePaymentBatchLoading,
  } = props;
  const householdList =
    households && households.length && sortHouseholds(households);
  const subsidyDepositTotal = batchHeader?.batchTotal ?? 0;
  const showSpinner = isLoading;
  const showSubsidyBatchRow =
    householdsWithSubjournalBalance &&
    householdsWithSubjournalBalance.length > 0;
  const [totalDepositAmount, setTotalDepositAmount] = useState(0);
  const [totalFinalSubBalance, setTotalFinalSubBalance] = useState(0);
  const [isRowSaving, setIsRowSaving] = useState(false);
  const [showPostDepositButton, setShowPostDepositButton] = useState(false);
  const findTotals = useCallback(() => {
    const result = householdsWithSubjournalBalance.reduce(
      (acc, transaction) => {
        const depositAmount = transaction?.transactionAmount ?? '';
        acc.totalDepo = acc.totalDepo + +depositAmount;
        const finalSubsidyBalance = !notInitialLoad
          ? 0
          : transaction?.finalSubsidyBalance ?? '';
        acc.totalFinalSub = acc.totalFinalSub + +finalSubsidyBalance;
        return acc;
      },
      { totalDepo: 0, totalFinalSub: 0 },
    );
    // There were rounding issues with Javascript causing these totals to be wrong:
    const newTotalDepo = result ? Math.round(result.totalDepo * 100) / 100 : 0;
    const newTotalFinalSub = result
      ? Math.round(result.totalFinalSub * 100) / 100
      : 0;
    setTotalDepositAmount(newTotalDepo);
    setTotalFinalSubBalance(newTotalFinalSub);
  }, [householdsWithSubjournalBalance, notInitialLoad]);

  const totals =
    householdsWithSubjournalBalance &&
    householdsWithSubjournalBalance.reduce((acc, row) => {
      return acc + row.subjournalRunningBalance;
    }, 0);

  useEffect(() => {
    showSubsidyBatchRow && findTotals();
    const containsInvalidTransactions = householdsWithSubjournalBalance.some(
      (t) => t.invalidReasons.length > 0,
    );
    findTotals &&
      setShowPostDepositButton(
        !containsInvalidTransactions &&
          !isRowSaving &&
          totalDepositAmount === subsidyDepositTotal,
      );
  }, [
    showSubsidyBatchRow,
    findTotals,
    totalDepositAmount,
    subsidyDepositTotal,
    isRowSaving,
    householdsWithSubjournalBalance,
  ]);

  const [inlineRowsArray, setInlineRowsArray] = useState([
    {
      id: uuid(),
      mode: 'default',
      householdOption: [],
    },
  ]);

  const isDisabled =
    (batchHeader?.depositStatus ?? '') === 'Deposited' ||
    (batchHeader?.isPosted ?? false) ||
    (batchHeader?.isPosting ?? false);
  const [showConfirmPostDepositModal, setShowConfirmPostDepositModal] =
    useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const depositId = batchHeader?.depositHeader?.id ?? null;
  if (isSaved)
    return (
      <React.Fragment>
        <ConfirmPostDepositModal
          show={showConfirmPostDepositModal}
          onHide={() => setShowConfirmPostDepositModal(false)}
          onSubmit={() => {
            postAndDepositSubsidyBatch(
              batchHeader?.id ?? null,
              history?.location?.pathname ?? null,
            );
          }}
        />
        <ConfirmDeleteSubsidyBatch
          show={showConfirmDeleteModal}
          onHide={() => setShowConfirmDeleteModal(false)}
          onSubmit={() => {
            deleteSubsidyPaymentBatch(batchHeader?.id ?? null);
          }}
          isDeletePaymentBatchLoading={isDeletePaymentBatchLoading}
        />
        <div className="container-fluid">
          <StyledForm>
            <div className="table-scroll table-deposits-container">
              <table className="table table-prospects table-striped">
                <thead className="table-header">
                  <tr>
                    <th
                      style={{
                        width: '20px',
                        padding: '0px',
                        paddingLeft: '10px',
                      }}
                    ></th>
                    <th>
                      <FormattedMessage {...messages.status} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.unitResident} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.currentSubsidyBalance} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.depositAmount} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.finalSB} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.transactionCode} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.note} />
                    </th>
                    <th>
                      <FormattedMessage {...messages.delete} />
                    </th>
                  </tr>
                </thead>
                <tbody data-test="details-table">
                  {showSpinner && (
                    <tr>
                      <td colSpan={7}>
                        <div className="text-center">
                          <Spinner />
                        </div>
                      </td>
                    </tr>
                  )}
                  {!showSpinner &&
                    showSubsidyBatchRow &&
                    householdsWithSubjournalBalance.map((transaction) => {
                      return (
                        <SubsidyBatchRow
                          key={transaction.id}
                          intl={intl}
                          transaction={transaction}
                          totalDepositAmount={totalDepositAmount}
                          setTotalDepositAmount={setTotalDepositAmount}
                          setIsRowSaving={setIsRowSaving}
                          totalFinalSubBalance={totalFinalSubBalance}
                          setTotalFinalSubBalance={setTotalFinalSubBalance}
                          saveSubsidyPaymentBatchDetail={
                            saveSubsidyPaymentBatchDetail
                          }
                          transactionCodes={transactionCodes}
                          initialValues={initialValues}
                          notInitialLoad={notInitialLoad}
                          deletePaymentBatchDetail={deletePaymentBatchDetail}
                          isDisabled={isDisabled}
                          setShowPostDepositButton={setShowPostDepositButton}
                          subsidyDepositTotal={subsidyDepositTotal}
                        />
                      );
                    })}
                  {!showSpinner &&
                    !isDisabled &&
                    inlineRowsArray.map((row, i) => (
                      <InlineSubsidyBatchRow
                        key={i}
                        index={i}
                        intl={intl}
                        setInlineRowsArray={setInlineRowsArray}
                        inlineRowsArray={inlineRowsArray}
                        mode={row.mode}
                        id={row.id}
                        householdList={householdList}
                        saveSubsidyPaymentBatchDetail={
                          saveSubsidyPaymentBatchDetail
                        }
                        batchHeader={batchHeader}
                        households={households}
                        transactionCodes={transactionCodes}
                      />
                    ))}
                </tbody>
                <tfoot className="table-footer">
                  <TableFooter
                    intl={intl}
                    totals={totals}
                    totalDepositAmount={totalDepositAmount}
                    totalFinalSubBalance={totalFinalSubBalance}
                  />
                </tfoot>
              </table>
            </div>
          </StyledForm>
          <div className="padtop30">
            <strong>
              <FormattedMessage {...messages.notes} />
            </strong>
            <div className="padtop5">
              <FormattedMessage {...messages.notes1} />
            </div>
            <div>
              <FormattedMessage {...messages.notes2} />
            </div>
            <div>
              <FormattedMessage {...messages.notes3} />
            </div>
          </div>
        </div>
        {depositId && (
          <UploadDocuments
            intl={intl}
            depositId={depositId}
            selectedPropertyId={selectedPropertyId}
            selectedOrganizationId={selectedOrganizationId}
            promptToaster={promptToaster}
          />
        )}
        <Row className="padtop30">
          <Col xs={12} sm={!isDisabled ? 5 : 12}>
            <Button
              type="button"
              bsStyle="default"
              className={!isDisabled ? 'pull-right' : 'center-block'}
              onClick={() =>
                navigateToUrlWithSelectedPropertyId('/manage-subsidy-payments')
              }
            >
              {!isDisabled ? (
                <FormattedMessage {...messages.saveClose} />
              ) : (
                <FormattedMessage {...messages.close} />
              )}
            </Button>
          </Col>
          <Col xs={12} sm={2}>
            {!isDisabled && (
              <Button
                type="button"
                bsStyle="primary"
                className="center-block"
                onClick={() => {
                  setShowConfirmPostDepositModal(true);
                }}
                disabled={!showPostDepositButton}
              >
                <FormattedMessage {...messages.postDeposit} />
              </Button>
            )}
          </Col>
          <Col xs={12} sm={5}>
            {!isDisabled && (
              <Button
                type="button"
                bsStyle="default"
                onClick={() => {
                  setShowConfirmDeleteModal(true);
                }}
                className="pull-left"
              >
                <FormattedMessage {...messages.delete} />
              </Button>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  else return <div></div>;
};

export default reduxForm({
  enableReinitialize: true,
  form: 'subsidyBatchForm',
})(CreateSubsidyBatchDetails);
