import type { Action } from '../App/types';
import type { EditUserState } from './types';
import * as ActionTypes from './constants';

const initialState = {
  user: {},
  selectedProperties: [],
  selectedPropertyGroups: [],
  hideMultiselect: false,
  nonePropertiesSelected: false,
  isSubmitting: false,
};

const editUserReducer = (
  state: EditUserState = initialState,
  action: Action<*>,
): EditUserState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_USER_SUCCESS: {
      return {
        ...state,
        user: payload,
        hideMultiselect: payload.allProperties,
      };
    }
    case ActionTypes.SET_SELECTED_PROPERTIES: {
      return { ...state, selectedProperties: payload };
    }
    case ActionTypes.SET_SELECTED_PROPERTY_GROUPS: {
      return { ...state, selectedPropertyGroups: payload };
    }
    case ActionTypes.SET_USER_ALL_PROPERTIES: {
      return {
        ...state,
        user: {
          ...state.user,
          allProperties: payload,
        },
      };
    }
    case ActionTypes.NONE_PROPERTIES_SELECTED: {
      return { ...state, nonePropertiesSelected: payload };
    }
    case ActionTypes.TOGGLE_MULTISELECT: {
      return { ...state, hideMultiselect: payload };
    }
    case ActionTypes.EDIT_USER: {
      return { ...state, isSubmitting: true };
    }
    case ActionTypes.EDIT_USER_SUCCESS:
    case ActionTypes.EDIT_USER_FAIL: {
      return { ...state, isSubmitting: false };
    }
    default:
      return state;
  }
};

export default editUserReducer;
