import { defineMessages } from 'react-intl';

const messages = defineMessages({
  status: {
    id: 'App.ManagesubsidyPaymentsDetails.Status',
    defaultMessage: 'Status',
  },
  date: {
    id: 'App.ManagesubsidyPaymentsDetails.Date',
    defaultMessage: 'Date',
  },
  period: {
    id: 'App.ManagesubsidyPaymentsDetails.Period',
    defaultMessage: 'Period',
  },
  amount: {
    id: 'App.ManagesubsidyPaymentsDetails.Amount',
    defaultMessage: 'Amount',
  },
  doc: {
    id: 'App.ManagesubsidyPaymentsDetails.Doc',
    defaultMessage: 'Doc #',
  },
  note: {
    id: 'App.ManagesubsidyPaymentsDetails.Note',
    defaultMessage: 'Note',
  },
  view: {
    id: 'App.ManagesubsidyPaymentsDetails.View',
    defaultMessage: 'View',
  },
  noDataDescription: {
    id: 'App.ManagesubsidyPaymentsDetails.NoDataDescription',
    defaultMessage: 'There is currently no data to display',
  },
  notes: {
    id: 'App.ManagesubsidyPaymentsDetails.Notes',
    defaultMessage: 'NOTES:',
  },
  notes1: {
    id: 'App.ManagesubsidyPaymentsDetails.Notes1',
    defaultMessage:
      '(1) “Open” payments have not been posted to resident ledgers or deposited.',
  },
  notes2: {
    id: 'App.ManagesubsidyPaymentsDetails.Notes2',
    defaultMessage:
      '(2) Edits to the subsidy batch can only be made while the status is “Open.”',
  },
});

export default messages;
