import React from 'react';
import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  Spinner,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { FolderIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import {
  MultiSelectList,
  SimpleTreeViewList,
} from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { useFetchPropertyGroups } from '../../../hooks/data-fetching/useFetchPropertyGroups';
import { Field } from 'redux-form';
import styled from 'styled-components';

// TODO: fix this styling text overflow problem in CL and remove this hack
const StyledDiv = styled.div`
  div.treeRowCollapseIcon {
    min-width: 24px;
  }
  div.treeRowContent {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    p.treeRowContent-treeRowLabel {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  div.MuiListItemButton-root.MuiListItemButton-dense {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
    p.MuiTypography-root.MuiTypography-body1 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const renderCheckbox = (props) => {
  return <Checkbox {...props} />;
};

const generatePropertiesItems = (properties, intl) => {
  return properties.map((property) => {
    return {
      label: property.name,
      value: property.id,
      filterId: property.propertyClass.id,
      groupLabel: intl.formatMessage(messages.PropertyLabel),
    };
  });
};

const generatePropertyClassFilterOptions = (propertyClasses, intl) => {
  const generatedOptions = propertyClasses.map((pc) => ({
    text: pc.name,
    value: pc.id,
  }));
  return [
    { value: 'all', text: intl.formatMessage(messages.All) },
    ...generatedOptions,
  ];
};

const categorizeProperties = (propertyMap) => {
  // affordable includes mixed
  const affordableProperties = [];
  const conventionalProperties = [];
  const commercialProperties = [];
  propertyMap.forEach((property) => {
    if (
      property?.propertyClass?.name === 'Affordable' ||
      property?.propertyClass?.name === 'Mixed'
    ) {
      affordableProperties.push(property);
    }

    if (property?.propertyClass?.name === 'Conventional') {
      conventionalProperties.push(property);
    }

    if (
      property?.hasCommercialFloorPlans === 'ALL' ||
      property?.hasCommercialFloorPlans === 'SOME'
    ) {
      commercialProperties.push(property);
    }
  });
  return [affordableProperties, conventionalProperties, commercialProperties];
};
const generatePropertyGroupItems = (propertyGroups, propertyMap, intl) => {
  const generatedTree = propertyGroups.map((pg) => ({
    label: pg.name,
    value: pg.id,
    children: pg.propertyIds.map((id) => {
      return {
        label: propertyMap.get(id)?.name ?? '',
        value: id,
      };
    }),
    selectable: true,
  }));
  const handleEmpty =
    generatedTree.length === 0
      ? [
          {
            label: intl.formatMessage(messages.NoPropertyGroups),
            value: 'NoGroups',
          },
        ]
      : generatedTree;

  const propertyTypeOptions = [];
  const [affordableProperties, conventionalProperties, commercialProperties] =
    categorizeProperties(propertyMap);
  if (affordableProperties.length > 0) {
    propertyTypeOptions.push({
      label: intl.formatMessage(messages.Affordable),
      value: 'AffordableGroup',
      selectable: true,
      children: affordableProperties.map((affProp) => {
        return {
          label: affProp.name,
          value: affProp.id,
        };
      }),
    });
  }
  if (conventionalProperties.length > 0) {
    propertyTypeOptions.push({
      label: intl.formatMessage(messages.Conventional),
      value: 'ConventionalGroup',
      selectable: true,
      children: conventionalProperties.map((conProp) => {
        return {
          label: conProp.name,
          value: conProp.id,
        };
      }),
    });
  }

  if (commercialProperties.length > 0) {
    propertyTypeOptions.push({
      label: intl.formatMessage(messages.Commercial),
      value: 'CommercialGroup',
      selectable: true,
      children: commercialProperties.map((commProp) => {
        return {
          label: commProp.name,
          value: commProp.id,
        };
      }),
    });
  }
  return [
    {
      label: intl.formatMessage(messages.PropertyTypes),
      value: 'PropertyTypes',
      children: propertyTypeOptions,
    },
    {
      label: intl.formatMessage(messages.PropertyGroups),
      value: 'PropertyGroups',
      children: handleEmpty,
    },
  ];
};

const generateSelectedItems = (
  selectedPropertyIds,
  selectedGroupIds,
  propertyGroupsData,
  propertyMap,
  intl,
) => {
  const selectedGroups = propertyGroupsData.filter((pg) => {
    return selectedGroupIds.includes(pg.id);
  });
  const [affordableProperties, conventionalProperties, commercialProperties] =
    categorizeProperties(propertyMap);

  const groupItems = selectedGroups.map((group) => {
    const groupChildren =
      group?.propertyIds?.map?.((id) => {
        return {
          label:
            propertyMap.get(id)?.name ?? intl.formatMessage(messages.Loading),
          value: id,
        };
      }) ?? [];
    return {
      label: group.name,
      id: group.id,
      groupLabel: intl.formatMessage(messages.GroupLabel),
      children: groupChildren,
    };
  });
  if (
    selectedGroupIds.includes('AffordableGroup') &&
    affordableProperties.length > 0
  ) {
    groupItems.push({
      label: intl.formatMessage(messages.Affordable),
      id: 'AffordableGroup',
      groupLabel: intl.formatMessage(messages.GroupLabel),
      children: affordableProperties.map((prop) => {
        return {
          label: prop.name,
          value: prop.id,
        };
      }),
    });
  }
  if (
    selectedGroupIds.includes('ConventionalGroup') &&
    conventionalProperties.length > 0
  ) {
    groupItems.push({
      label: intl.formatMessage(messages.Conventional),
      id: 'ConventionalGroup',
      groupLabel: intl.formatMessage(messages.GroupLabel),
      children: conventionalProperties.map((prop) => {
        return {
          label: prop.name,
          value: prop.id,
        };
      }),
    });
  }
  if (
    selectedGroupIds.includes('CommercialGroup') &&
    commercialProperties.length > 0
  ) {
    groupItems.push({
      label: intl.formatMessage(messages.Commercial),
      id: 'CommercialGroup',
      groupLabel: intl.formatMessage(messages.GroupLabel),
      children: commercialProperties.map((prop) => {
        return {
          label: prop.name,
          value: prop.id,
        };
      }),
    });
  }

  const propertyItems =
    selectedPropertyIds?.map?.((propertyId) => {
      const property = propertyMap.get(propertyId);
      return {
        label: property?.name,
        value: property?.id,
        groupLabel: intl.formatMessage(messages.PropertyLabel),
      };
    }) ?? [];
  return [...groupItems, ...propertyItems];
};

// Helper function to get list of selected properties in groups so MultiSelectList can know which icons to show
// in property list
const getPropertyIdsSelectedInGroups = (
  selectedGroupIds,
  propertyGroupsData,
  propertyMap,
) => {
  const selectedGroups = propertyGroupsData.filter((pg) => {
    return selectedGroupIds.includes(pg.id);
  });
  let selectedPropertyIds = selectedGroups.reduce((ids, group) => {
    return [...new Set(ids.concat(group.propertyIds))];
  }, []);
  const [affordableProperties, conventionalProperties, commercialProperties] =
    categorizeProperties(propertyMap);

  // Need to look if default groups are selected and calculate them
  if (
    selectedGroupIds.includes('AffordableGroup') &&
    affordableProperties.length > 0
  ) {
    const affordablePropertyIds = affordableProperties.map((p) => p.id);
    selectedPropertyIds = [
      ...new Set(selectedPropertyIds.concat(affordablePropertyIds)),
    ];
  }
  if (
    selectedGroupIds.includes('ConventionalGroup') &&
    conventionalProperties.length > 0
  ) {
    const conventionalPropertyIds = conventionalProperties.map((p) => p.id);
    selectedPropertyIds = [
      ...new Set(selectedPropertyIds.concat(conventionalPropertyIds)),
    ];
  }
  if (
    selectedGroupIds.includes('CommercialGroup') &&
    commercialProperties.length > 0
  ) {
    const commercialPropertyIds = commercialProperties.map((p) => p.id);
    selectedPropertyIds = [
      ...new Set(selectedPropertyIds.concat(commercialPropertyIds)),
    ];
  }
  return selectedPropertyIds;
};

const AssignUserProperty = ({
  properties = [],
  propertyClasses = [],
  intl,
  organizationId,
  selectedPropertyIds = [],
  selectedPropertyGroupIds = [],
  handlePropertySelectionChange = () => {},
  handlePropertyGroupSelectionChange = () => {},
  onAllPropertiesSelection = () => {},
  noneSelected,
  allPropertiesSelected = false,
}) => {
  const { data: propertyGroupsData, isLoading } = useFetchPropertyGroups({
    organizationId,
    intl,
  });
  if (isLoading || properties.length === 0) {
    return <Spinner />;
  }

  // Used to access properties in constant time instead of looping through whole array
  const propertyMap = new Map();
  properties.forEach((property) => {
    propertyMap.set(property.id, property);
  });

  const propertyGroups = generatePropertyGroupItems(
    propertyGroupsData,
    propertyMap,
    intl,
  );
  const filteredProperties = properties.filter((property) => {
    return property?.propertyStatus?.description !== 'Inactive';
  });

  const propertyItems = generatePropertiesItems(filteredProperties, intl);
  const propertyIdsSelectedInGroups = getPropertyIdsSelectedInGroups(
    selectedPropertyGroupIds,
    propertyGroupsData,
    propertyMap,
  );

  const selectedItems = generateSelectedItems(
    selectedPropertyIds,
    selectedPropertyGroupIds,
    propertyGroupsData,
    propertyMap,
    intl,
  );

  const filterOptions = generatePropertyClassFilterOptions(
    propertyClasses,
    intl,
  );

  const handleAllPropertiesSelection = () => {
    const updatedVal = !allPropertiesSelected;
    onAllPropertiesSelection(updatedVal);
  };

  const selectedLength = allPropertiesSelected
    ? properties.length
    : selectedItems.length;
  const selectedListTitle = `${intl.formatMessage(
    messages.SelectedItems,
  )}(${selectedLength})`;

  return (
    <StyledDiv>
      <Grid container>
        <Grid item xs={6} style={{ paddingTop: '10px' }}>
          <Typography variant="h3">
            <FormattedMessage {...messages.AssignPropertyHeader} />
          </Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Field
            name="allProperties"
            component={renderCheckbox}
            label={intl.formatMessage(messages.SelectAllProperties)}
            aria-label={intl.formatMessage(messages.SelectAllProperties)}
            checked={allPropertiesSelected}
            onClick={handleAllPropertiesSelection}
            bsSize="lg"
          />
        </Grid>
      </Grid>
      {allPropertiesSelected ? (
        <Grid container paddingY={'5px'}>
          <Grid item xs={12}>
            <SimpleTreeViewList
              width="100%"
              height={700}
              boxHeight={700}
              items={propertyItems}
              title={selectedListTitle}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                <FormattedMessage {...messages.AssignPropertiesDescription} />
              </Typography>
            </Grid>
            {noneSelected && (
              <Grid item xs={12}>
                <Typography>
                  <span className="has-error">
                    <FormattedMessage {...messages.NoneSelectedMessage} />
                  </span>
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Stack>
                <div style={{ padding: '8px 15px 8px 0' }}>
                  <MultiSelectList
                    items={propertyGroups}
                    searchPlaceholder={intl.formatMessage(
                      messages.GroupSearchPlaceholder,
                    )}
                    title={intl.formatMessage(messages.PropertyGroups)}
                    height={300}
                    listHeight={192}
                    enableTreeView
                    onChange={handlePropertyGroupSelectionChange}
                    selected={selectedPropertyGroupIds}
                    titleIcon={
                      <i
                        style={{
                          margin: '10px',
                          fontSize: '16px',
                          top: '3px',
                          position: 'relative',
                        }}
                        className="icon et-alert-info"
                      />
                    }
                    titleIconTooltip={intl.formatMessage(
                      messages.PropertyGroupToolTip,
                    )}
                  ></MultiSelectList>
                </div>
                <div style={{ padding: '8px 15px 0 0' }}>
                  <MultiSelectList
                    items={propertyItems}
                    searchPlaceholder={intl.formatMessage(
                      messages.PropertySearchPlaceholder,
                    )}
                    title={intl.formatMessage(messages.Properties)}
                    height={350}
                    listHeight={174}
                    enableFilter
                    filterOptions={filterOptions}
                    onChange={handlePropertySelectionChange}
                    selected={selectedPropertyIds}
                    itemsWithAfterIcons={propertyIdsSelectedInGroups}
                  ></MultiSelectList>
                </div>
              </Stack>
              <Grid container></Grid>
            </Grid>
            <Grid item xs={6} style={{ padding: '8px 0 0 15px' }}>
              <SimpleTreeViewList
                width="100%"
                height={665}
                boxHeight={700}
                items={selectedItems}
                title={selectedListTitle}
                noDataTitle={intl.formatMessage(messages.NoDataTitle)}
                noDataSubtitle={intl.formatMessage(messages.NoDataSubtitle)}
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '15px' }}>
              <Typography>
                <b>
                  <FormattedMessage {...messages.Note} />
                </b>
                <span style={{ position: 'relative', top: '5px' }}>
                  <FolderIcon />
                </span>
                <FormattedMessage {...messages.AssignPropertiesNote} />
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </StyledDiv>
  );
};

AssignUserProperty.defaultProps = {};

export default AssignUserProperty;
