import { gql } from '@apollo/client';
import useSelectedProperty from '../../../../hooks/useSelectedProperty';
import { useGqlQuery } from '../../../../utils/gql';

export default function usePaymentConfigData() {
  const selectedProperty = useSelectedProperty();

  const [propertyConfigResults] = useGqlQuery(
    gql`
      query FindPropertyConfig($where: JSONObject) {
        testFindPropertyConfig(where: $where) {
          shouldAcceptPartialPayment
          shouldAcceptOverpay
          overpayPercentageAllowed
          shouldAcceptBankAccounts
          shouldAcceptCards
          shouldAcceptChecks
          shouldAcceptCashPay
          paymentProcessor
        }
      }
    `,
    {
      where: {
        propertyId: selectedProperty.id,
      },
    },
  );
  const [managingEntityResults] = useGqlQuery(
    gql`
      query FindManagingEntity($where: JSONObject) {
        findManagingEntity(where: $where) {
          operatingBankAccount
          securityBankAccount
          miscBankAccount
        }
      }
    `,
    {
      where: {
        propertyId: selectedProperty.id,
      },
    },
  );

  const formatBankAccount = (bankAccount, paymentProcessor) => {
    if (!bankAccount) return '---';

    if (paymentProcessor === 'PRIORITY_PASSPORT') return `**** ${bankAccount}`;

    return bankAccount;
  };
  const formatBoolean = (value) => {
    if (value === true) return 'Yes';
    if (value === false) return 'No';
    return '---';
  };

  const propertyConfig = propertyConfigResults?.[0];
  const managingEntity = managingEntityResults?.[0];
  const acceptableFormsOfPaymentArray = [
    propertyConfig?.shouldAcceptBankAccounts && 'Bank Accounts',
    propertyConfig?.shouldAcceptCards && 'Cards',
    propertyConfig?.shouldAcceptChecks && 'Checks',
    propertyConfig?.shouldAcceptCashPay && 'Cash Pay',
  ].filter(Boolean);
  const acceptableFormsOfPayment =
    acceptableFormsOfPaymentArray.length > 0
      ? acceptableFormsOfPaymentArray.join(', ')
      : '---';
  const allowsPartialPayments = formatBoolean(
    propertyConfig?.shouldAcceptPartialPayment,
  );
  const allowsOverpay = formatBoolean(propertyConfig?.shouldAcceptOverpay);
  const overpayPct =
    typeof propertyConfig?.overpayPercentageAllowed === 'number'
      ? `${propertyConfig?.overpayPercentageAllowed}%`
      : '---';
  const operatingBankAccount = formatBankAccount(
    managingEntity?.operatingBankAccount,
    propertyConfig?.paymentProcessor,
  );
  const securityBankAccount = formatBankAccount(
    managingEntity?.securityBankAccount,
    propertyConfig?.paymentProcessor,
  );
  const miscBankAccount = formatBankAccount(
    managingEntity?.miscBankAccount,
    propertyConfig?.paymentProcessor,
  );

  return {
    operatingBankAccount,
    securityBankAccount,
    miscBankAccount,
    acceptableFormsOfPayment,
    allowsPartialPayments,
    allowsOverpay,
    overpayPct,
  };
}
