import { defineMessages } from 'react-intl';

const messages = defineMessages({
  assignToCamPool: {
    id: 'App.ViewTransactionModal.ModifyTransactionCamPoolModal.AssignToCamPool',
    defaultMessage: 'Assign to CAM Pool',
  },
  cancel: {
    id: 'App.ViewTransactionModal.ModifyTransactionCamPoolModal.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.ViewTransactionModal.ModifyTransactionCamPoolModal.Save',
    defaultMessage: 'Save',
  },
  applyToCamPool: {
    id: 'App.ViewTransactionModal.ModifyTransactionCamPoolModal.ApplyToCamPool',
    defaultMessage: 'Apply to CAM Pool?',
  },
  camPeriod: {
    id: 'App.ViewTransactionModal.ModifyTransactionCamPoolModal.CamPeriod',
    defaultMessage: 'CAM Start Date / Period',
  },
  camPool: {
    id: 'App.ViewTransactionModal.ModifyTransactionCamPoolModal.CamPool',
    defaultMessage: 'CAM Pool',
  },
  modifyCustomerOpsCamPoolSuccessMsg: {
    id: 'App.ViewTransactionModal.ModifyTransactionCamPoolModal.modifyCustomerOpsCamPoolSuccessMsg',
    defaultMessage: 'The Transaction was applied to the CAM Pool successfully.',
  },
  modifyCustomerOpsCamPoolSuccessTitle: {
    id: 'AppViewTransactionModal.ModifyTransactionCamPoolModal.modifyCustomerOpsCamPoolSuccessTitle',
    defaultMessage: 'Transaction applied to CAM Pool',
  },
  modifyCustomerOpsCamPoolSuccessError: {
    id: 'AppViewTransactionModal.ModifyTransactionCamPoolModal.modifyCustomerOpsCamPoolSuccessError',
    defaultMessage: 'Error applying Transaction to CAM Pool',
  },
  deleteCustomerOpsCamPoolSuccessMsg: {
    id: 'App.ViewTransactionModal.ModifyTransactionCamPoolModal.deleteCustomerOpsCamPoolSuccessMsg',
    defaultMessage:
      'The Transaction was unapplied from the CAM Pool successfully.',
  },
  deleteCustomerOpsCamPoolSuccessTitle: {
    id: 'AppViewTransactionModal.ModifyTransactionCamPoolModal.deleteCustomerOpsCamPoolSuccessTitle',
    defaultMessage: 'Transaction unapplied from CAM Pool',
  },
  deleteCustomerOpsCamPoolSuccessError: {
    id: 'AppViewTransactionModal.ModifyTransactionCamPoolModal.deleteCustomerOpsCamPoolSuccessError',
    defaultMessage: 'Error unapplied Transaction from CAM Pool',
  },
});

export default messages;
