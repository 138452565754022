import React, { useState } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { pathOr, isNil } from 'ramda';

import messages from './messages';
import { DEFAULT_OPTION } from './constants';
import { ReassignTransactionsFormProps } from './types';
import TransactionRow from './transactionRow';

const ReassignTransactionsForm = ({
  onHide,
  onSubmit,
  transactions,
  intl,
  camPools,
  footNote,
}: ReassignTransactionsFormProps) => {
  const [modifiedTransactions, setModifiedTransactions] = useState([
    ...transactions,
  ]);

  const setTransaction = (
    customerOpsLedgerId: string,
    camRecordsId: string,
    camAllocationsId: string,
  ) => {
    const newTransactions = modifiedTransactions.reduce((allTrans, t) => {
      if (t.customerOpsLedgerId === customerOpsLedgerId) {
        return [...allTrans, { ...t, camRecordsId, camAllocationsId }];
      }
      return [...allTrans, t];
    }, []);

    setModifiedTransactions(newTransactions);
  };

  const disableSave = () => {
    const hasInvalid = modifiedTransactions.some((t) => {
      const customerOpsLedgerId = pathOr(null, ['customerOpsLedgerId'], t);
      const camRecordsId = pathOr(null, ['camRecordsId'], t);
      const camAllocationsId = pathOr(null, ['camAllocationsId'], t);

      return (
        isNil(customerOpsLedgerId) ||
        isNil(camRecordsId) ||
        isNil(camAllocationsId) ||
        camAllocationsId === DEFAULT_OPTION.value ||
        camRecordsId === DEFAULT_OPTION.value
      );
    });
    return hasInvalid;
  };

  const onSave = () => {
    // Get only the rows that have changed, and we just need the customerOpsLedgerId and camAllocationsId
    const entriesToSave = modifiedTransactions
      .filter((t) => {
        const customerOpsLedgerId = pathOr(null, ['customerOpsLedgerId'], t);
        const camAllocationsId = pathOr(null, ['camAllocationsId'], t);
        if (
          isNil(customerOpsLedgerId) ||
          isNil(camAllocationsId) ||
          camAllocationsId === DEFAULT_OPTION.value
        ) {
          return false;
        }
        const originalTrans = transactions.find(
          (tr) => tr.customerOpsLedgerId === customerOpsLedgerId,
        );
        const originalAllocId = pathOr(
          null,
          ['camAllocationsId'],
          originalTrans,
        );
        return originalAllocId !== camAllocationsId;
      })
      .map((t) => ({
        customerOpsLedgerId: t.customerOpsLedgerId,
        camAllocationsId: t.camAllocationsId,
      }));

    onSubmit(entriesToSave);
  };

  return (
    <>
      <Modal.Body>
        <Row className="padtop20">
          <b>
            <Col md={2} xs={4}>
              <FormattedMessage {...messages.postDate} />
            </Col>
            <Col md={2} xs={4}>
              <FormattedMessage {...messages.code} />
            </Col>
            <Col md={2} xs={4}>
              <FormattedMessage {...messages.amount} />
            </Col>
            <Col md={3} xs={6}>
              <FormattedMessage {...messages.camPeriod} />
            </Col>
            <Col md={3} xs={6}>
              <FormattedMessage {...messages.camPool} />
            </Col>
          </b>
        </Row>

        {modifiedTransactions.map((transaction) =>
          TransactionRow({
            transaction,
            setTransaction,
            camPools,
            intl,
          }),
        )}
      </Modal.Body>
      <Modal.Footer>
        <Row className="text-center">
          <FormattedMessage {...footNote} />
        </Row>
        <Row>
          <Col xs={6}>
            <Button type="button" bsStyle="default" onClick={onHide}>
              <FormattedMessage {...messages.cancel} />
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              type="submit"
              bsStyle="primary"
              className="pull-left"
              disabled={disableSave()}
              onClick={onSave}
            >
              <FormattedMessage {...messages.save} />
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </>
  );
};

export default ReassignTransactionsForm;
