import { useEffect, useMemo, useCallback } from 'react';
import { createActivity } from '../../components/CreateActivityModal';
import {
  recordActivity,
  scheduleActivity,
} from '../../components/ActivityModal';
import { editCompletedActivity } from '../../components/EditCompletedActivityModal';
import type { Activity } from '../ProspectProfile/types';
import ActivityTypeService from '../../services/activityTypeService';
import { editPendingActivity } from '../../components/EditPendingActivityModal';
import { determineAffordablePrograms } from './utils';

export const useManageApplicationsPageV2 = ({
  actions,
  users = [],
  store,
  intl,
  currentPage = null,
  limit = null,
  currentSorting,
  selectedProperty,
  activityTypes = [],
  locale,
}) => {
  const { activityTypesList } = useMemo(() => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList({
      activityTypes,
      locale,
      intl,
    });

    return { activityTypeService, activityTypesList };
  }, [activityTypes, intl, locale]);
  const { organizationId, propertyId, affordablePrograms } = useMemo(() => {
    const {
      pap: propertyAffordablePrograms,
      organizationId: _organizationId,
      id,
    } = selectedProperty;

    return {
      organizationId: _organizationId,
      propertyId: id,
      affordablePrograms: determineAffordablePrograms(
        propertyAffordablePrograms,
      ),
    };
  }, [selectedProperty]);

  const createApplicationProspectActivity = useCallback(
    (data) => {
      actions.createApplicationProspectActivity(
        data,
        currentPage,
        limit,
        currentSorting ?? {},
      );
    },
    [actions, currentPage, currentSorting, limit],
  );

  useEffect(() => {
    actions.getAllActivityTypes();
    actions.getAllApplicationStatuses();
    actions.getProspectAssignees();
  }, [actions]);

  const usersList = useMemo(() => {
    return users.map((user) => ({
      value: user.id,
      text: `${user.firstName} ${user.lastName}`,
      disabled: false,
    }));
  }, [users]);

  const onRecordActivityClick = useCallback(
    async (prospect: any, applicationId: string) => {
      const data: Activity = await recordActivity(
        store,
        intl,
        prospect,
        activityTypesList,
        usersList,
        'applicant',
        applicationId,
      );
      createApplicationProspectActivity(data);
    },
    [
      activityTypesList,
      createApplicationProspectActivity,
      intl,
      store,
      usersList,
    ],
  );

  const onScheduleActivityClick = useCallback(
    async (prospect: any, applicationId: string) => {
      const data: Activity = await scheduleActivity(
        store,
        intl,
        prospect,
        activityTypesList,
        usersList,
        'applicant',
        applicationId,
      );
      createApplicationProspectActivity(data);
    },
    [
      activityTypesList,
      createApplicationProspectActivity,
      intl,
      store,
      usersList,
    ],
  );

  const onCreateActivityClick = useCallback(
    async (prospect: any, applicationId: string) => {
      const data: Activity = await createActivity({
        store,
        intl,
        prospect,
        activityTypesList,
        assigneeList: usersList,
        stage: 'applicant',
        urlId: applicationId,
        showViewProfileBtn: true,
      });
      createApplicationProspectActivity(data);
    },
    [
      activityTypesList,
      createApplicationProspectActivity,
      intl,
      store,
      usersList,
    ],
  );

  const onEditActivity = useCallback(
    async (activity: any, applicationId: string) => {
      const stageInfo = {
        currentStage: 'applicant',
        urlId: applicationId,
      };

      activity.activityType = activityTypes.filter(
        (at) => at.id === activity.activityTypeId,
      )[0];

      if (activity.activityType) {
        activity.completionStatus =
          activity.activityType.completionStatusOptions.filter(
            (s) => s.id === activity.activityCompletionStatusId,
          )[0];
      }
      const editPromise = activity.activityCompletionStatusId
        ? await editCompletedActivity(store, intl, activity, stageInfo)
        : await editPendingActivity(
            store,
            intl,
            activity,
            activityTypesList,
            usersList,
            activity.prospect,
            stageInfo,
          );

      editPromise.then((data: any) => {
        if (data.saveAndClose || data.saveAndAddNew) {
          actions.applicationUpdateProspectActivity(
            data,
            currentPage,
            limit,
            currentSorting ?? {},
          );

          if (data.saveAndAddNew) {
            onCreateActivityClick(activity.prospect, applicationId);
          }
        } else {
          actions.deleteApplicationProspectActivity(
            data,
            currentPage,
            limit,
            currentSorting,
          );
        }
      });
    },
    [
      actions,
      activityTypes,
      activityTypesList,
      currentPage,
      currentSorting,
      intl,
      limit,
      onCreateActivityClick,
      store,
      usersList,
    ],
  );

  return {
    organizationId,
    propertyId,
    affordablePrograms,
    onRecordActivityClick,
    onEditActivity,
    onScheduleActivityClick,
    onCreateActivityClick,
  };
};
