import { put, takeLatest, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { GET_ALL_FLOOR_PLANS, GET_ALL_SMART_PRICES } from './constants';
import {
  getAllFloorPlansSuccess,
  getAllFloorPlansFail,
  getAllSmartPricesSuccess,
  getAllSmartPricesFail,
} from './actions';
import type { Action, FilterValueAndPagination } from '../App/types';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import FloorPlanService from '../../services/floorPlanService';
import SmartPricingService from '../../services/smartPricingService';
import { renderNoDataToastr } from '../../utils/redux-form-helper';

export function* fetchGetAllFloorPlansSaga({
  payload,
}: Action<FilterValueAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const floorPlanService = new FloorPlanService();
    const response = yield floorPlanService.getAll(organizationId, property.id);
    if (response.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getAllFloorPlansSuccess(response));
  } catch (err) {
    yield put(getAllFloorPlansFail(err));
  }
}

export function* getAllFloorPlansSaga(): Saga<void> {
  yield takeLatest(GET_ALL_FLOOR_PLANS, fetchGetAllFloorPlansSaga);
}

export function* fetchGetAllSmartPricesSaga({
  payload,
}: Action<FilterValueAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const smartPricingService = new SmartPricingService();
    const response = yield smartPricingService.getPropertySmartPrices(
      organizationId,
      property.id,
    );

    if (response.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getAllSmartPricesSuccess(response));
  } catch (err) {
    yield put(getAllSmartPricesFail(err));
  }
}

export function* getAllSmartPricesSaga(): Saga<void> {
  yield takeLatest(GET_ALL_SMART_PRICES, fetchGetAllSmartPricesSaga);
}

export default [getAllFloorPlansSaga, getAllSmartPricesSaga];
