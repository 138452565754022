import { createSelector } from 'reselect';
import type { AppState, GlobalState } from './types';
import { always, cond, equals, isNil, pathOr, pathSatisfies, T } from 'ramda';
import { GUARANTOR_LEASE_SIGNER } from '../GeneralAffordable/constants';

const getAppState = (state: GlobalState) => state.app;
const getRoutingState = (state: GlobalState) => state.routing;
const getCurrentLocale = (state: GlobalState) => state.languageProvider.locale;

export const selectCurrentUserOrganizationId = createSelector(
  getAppState,
  (app): string => (app.currentUser ? app.currentUser.user.organizationId : ''),
);

export const selectSelectedProperty = createSelector(
  getAppState,
  (app) => app.selectedProperty,
);

export const selectCurrentLocationPath = createSelector(
  getRoutingState,
  (route): string => (route.location ? route.location.pathname : ''),
);

export const getSelectedPropertyName = createSelector(
  selectSelectedProperty,
  (property) => property && property.name,
);

export const getSelectedPropertyState = createSelector(
  selectSelectedProperty,
  (selectedProperty) => selectedProperty?.state?.code ?? '',
);

export const getSelectedPropertyClass = createSelector(
  selectSelectedProperty,
  (selectedProperty) =>
    selectedProperty &&
    selectedProperty.propertyClassId &&
    selectedProperty.propertyClass.canBeAppliedFor
      ? selectedProperty.propertyClassId
      : 'default',
);

export const getSelectedPropertyClassType = createSelector(
  selectSelectedProperty,
  (selectedProperty) => pathOr('', ['propertyClass', 'name'], selectedProperty),
);

export const getSelectedPropertyClassVisible = createSelector(
  selectSelectedProperty,
  (selectedProperty) =>
    selectedProperty && selectedProperty.propertyClassId
      ? !selectedProperty.propertyClass.canBeAppliedFor
      : true,
);

export const isUserLoggedIn = createSelector(getAppState, (app) => {
  //$FlowFixMe
  return pathSatisfies((x) => !isNil(x), ['currentUser', 'user', 'id'], app);
});

export const getSuffixes = createSelector(
  getAppState,
  (app) => app.nameSuffixes,
);

export const getRelationships = createSelector(
  getAppState,
  (app) => app.relationships,
);

export const getNameSuffixOptions = createSelector(getAppState, (app) => [
  {
    value: 'default',
    text: 'Choose',
    disabled: false,
  },
  ...app.nameSuffixes.map((suffix) => ({
    value: suffix.id,
    text: suffix.name,
    disabled: false,
  })),
]);

export const getApplicantTypes = createSelector(
  getAppState,
  (app) => app.applicantTypes,
);

export const getApplicantTypeOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => {
    const applicantTypeOptions = app.applicantTypes.filter(
      (applicantType) => applicantType.type === 'Adult',
    );
    const sortedOptions = [
      'Financial Lease Signer',
      'Employee Lease Signer',
      'Courtesy Officer Lease Signer',
      'Guarantor Lease Signer',
      'Vendor Unit Lease Signer',
      'Corporate Unit Lease Signer',
      'Non Financial Lease Signer',
      'Live-In-Caretaker',
    ];
    return [
      {
        value: 'default',
        text: 'Choose',
        disabled: true,
      },
      ...sortedOptions
        .map((optionName) =>
          applicantTypeOptions.find((option) => option.name === optionName),
        )
        .filter((applicantType) => !!applicantType)
        .map((applicantType: any) => ({
          value: applicantType.id,
          text: applicantType.translations[locale] || applicantType.name,
          disabled: false,
          financiallyResponsible: applicantType.financiallyResponsible,
        })),
    ];
  },
);

export const getGuarantorLeaseSignerOption = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => {
    return app.applicantTypes.find(
      (applicantType) =>
        applicantType.type === 'Adult' &&
        applicantType.name === GUARANTOR_LEASE_SIGNER,
    );
  },
);

export const getLiveInCaretakerOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => {
    const applicantType = app.applicantTypes.find(
      (applicantType) =>
        applicantType.type === 'Adult' &&
        applicantType.name === 'Live-In-Caretaker',
    );

    const affordableRelationship = app.affordableRelationship.find(
      (relationship) => relationship.name === 'Live-in Caretaker',
    );

    const relationship = app.relationships.find(
      (relationship) => relationship.name === 'Other',
    );
    return {
      applicantType,
      affordableRelationship,
      relationship,
    };
  },
);

export const getGuarantorOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => {
    const applicantType = app.applicantTypes.find(
      (applicantType) =>
        applicantType.type === 'Adult' &&
        applicantType.name === GUARANTOR_LEASE_SIGNER,
    );

    const affordableRelationship = app.affordableRelationship.find(
      (relationship) => relationship.name === 'Guarantor',
    );

    const relationship = app.relationships.find(
      (relationship) => relationship.name === 'Guarantor',
    );
    return {
      applicantType,
      affordableRelationship,
      relationship,
    };
  },
);

export const getRelationshipOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => [
    {
      value: 'default',
      text: 'Choose',
      disabled: true,
    },
    ...app.relationships.map((relationship) => ({
      value: relationship.id,
      text: relationship.translations[locale] || relationship.name,
      disabled: false,
    })),
  ],
);

export const getGuarantorRelationshipOption = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => {
    return app.relationships.find(
      (relationship) => relationship.name === 'Guarantor',
    );
  },
);

export const getMinorRelationshipOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => [
    {
      value: 'default',
      text: 'Choose',
      disabled: true,
    },
    ...app.relationships
      .filter((r) => r.childRelationship)
      .map((relationship) => ({
        value: relationship.id,
        text: relationship.translations[locale] || relationship.name,
        disabled: false,
      })),
  ],
);

export const getAffordableRelationshipOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => [
    {
      value: 'default',
      text: 'Choose',
      isChild: true,
      isAdult: true,
      disabled: true,
    },
    ...app.affordableRelationship.map((relationship) => ({
      value: relationship.id,
      text: relationship.translations[locale] || relationship.name,
      isChild: relationship.childRelationship || false,
      isAdult: relationship.adultRelationship || false,
      disabled: false,
    })),
  ],
);

export const getMinorAffordableRelationshipOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => [
    {
      value: 'default',
      text: 'Choose',
      isChild: true,
      isAdult: false,
      disabled: true,
    },
    ...app.affordableRelationship
      .filter((r) => r.childRelationship)
      .map((relationship) => ({
        value: relationship.id,
        text: relationship.translations[locale] || relationship.name,
        isChild: relationship.childRelationship || false,
        isAdult: relationship.adultRelationship || false,
        disabled: false,
      })),
  ],
);

export const getProspectAssigneesOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app) =>
    app.allAssignees
      .filter((user) => user.userRole.canBeAssignedProspect)
      .map((user) => ({
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
        disabled: false,
      })),
);

export const getApplicablePropertyClassOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => [
    {
      value: 'default',
      text: 'Choose',
      disabled: true,
    },
    ...app.propertyClasses
      .filter((propertyClass) => propertyClass.canBeAppliedFor)
      .map((propertyClass) => ({
        value: propertyClass.id,
        text: propertyClass.translations[locale] || propertyClass.name,
        disabled: false,
      })),
  ],
);

export const getPetTypeOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => [
    {
      value: 'default',
      text: 'Choose',
      petBreeds: [
        {
          value: 'default',
          text: 'Choose',
          disabled: true,
        },
      ],
      disabled: true,
    },
    ...app.petTypes.map((petType) => ({
      value: petType.id,
      text: petType.translations[locale] || petType.name,
      requiresWeight: petType.requiresWeight,
      petBreeds: [
        {
          value: 'default',
          text: 'Choose',
          disabled: true,
        },
        ...petType.petBreeds.map((breed) => ({
          value: breed.id,
          text: breed.translations[locale] || breed.name,
          disabled: false,
        })),
      ],
      disabled: false,
    })),
  ],
);

export const getCountryOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => [
    {
      value: 'default',
      text: 'Choose',
      disabled: true,
    },
    ...app.countries.map((country) => ({
      value: country.id,
      text: country.translations[locale] || country.name,
      disabled: false,
    })),
  ],
);

export const getStateOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => [
    {
      value: 'default',
      text: 'Choose',
      disabled: true,
    },
    ...app.states.map((state) => ({
      value: state.id,
      text: state.translations[locale] || state.name,
      disabled: false,
    })),
  ],
);

export const getIncomeTypeOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => [
    {
      value: 'default',
      text: 'Choose',
      disabled: true,
    },
    ...app.incomeTypes.map((incomeType) => ({
      value: incomeType.id,
      text: incomeType.translations[locale] || incomeType.name,
      disabled: false,
    })),
  ],
);

export const getSpecialNeedsDesignationOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => [
    ...app.specialNeedsDesignations.map((specialNeedsDesignation) => ({
      value: specialNeedsDesignation.id,
      text:
        specialNeedsDesignation?.translations?.[locale] ||
        specialNeedsDesignation?.name,
      label:
        specialNeedsDesignation?.translations?.[locale] ||
        specialNeedsDesignation?.name,
      disabled: false,
    })),
  ],
);

export const getEmploymentStatusesOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) =>
    app.employmentStatuses.map((status) => ({
      value: status.id,
      text: status.translations[locale] || status.name,
      disabled: false,
    })),
);

export const getMoveOutReasonsOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (state, disabledOptionIds) => disabledOptionIds ?? [],
  (app, locale, disabledOptionIds) => {
    return [
      {
        value: 'default',
        text: 'Choose',
        disabled: true,
      },
      ...app.moveOutReasons.map((reason) => ({
        value: reason.id,
        text: reason.reason,
        disabled: disabledOptionIds.includes(reason.id),
      })),
    ];
  },
);

export const getHousingSituationOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => {
    return [
      {
        value: 'default',
        text: 'Choose',
        disabled: true,
      },
      ...(app?.housingSituations ?? []).map((hs) => ({
        value: hs.id,
        text: hs?.translations?.[locale] ?? hs.name,
      })),
    ];
  },
);

export const getUtilityAllowanceTypeOptions = createSelector(
  getAppState,
  getCurrentLocale,
  (app, locale) => [
    ...app.utilityAllowanceTypes.map((utilityAllowanceType) => ({
      value: utilityAllowanceType.id,
      text:
        utilityAllowanceType?.translations?.[locale] ||
        utilityAllowanceType.name,
      label:
        utilityAllowanceType?.translations?.[locale] ||
        utilityAllowanceType.name,
      disabled: false,
    })),
  ],
);

export const getEnvironmentName = createSelector(getAppState, (app) => {
  return cond([
    [equals('dev'), always('development')],
    [equals('development'), always('development')],
    [equals('staging'), always('staging')],
    [equals('training'), always('training')],
    [equals('traininget'), always('training')],
    [equals('experiment'), always('development')],
    [equals('production'), always('')],
    [T, always('development')],
  ])(app.buildInformation.deploymentEnvironment);
});

export const getWriteOffsForCustomer = (customerId: string) =>
  createSelector(
    getAppState,
    (app: AppState) => app.writeOffsByCustomerId[customerId],
  );

export const getWriteOffBalanceForCustomer = (customerId: string) =>
  createSelector(getWriteOffsForCustomer(customerId), (writeOffs?: any[]) =>
    writeOffs ? writeOffs : 0,
  );

export const getCurrentUser = createSelector(getAppState, ({ currentUser }) => {
  let permissions = [];
  if (currentUser && currentUser.permissions) {
    permissions = currentUser.permissions;
  }
  return { ...currentUser, permissions };
});
