import { defineMessages } from 'react-intl';

const messages = defineMessages({
  modalTitle: {
    id: 'App.LeaseDataTabForm.editRequiredLeaseFeesModal.modalTitle',
    defaultMessage: 'Edit Non-Rent Required Charges',
  },
  feesTitle: {
    id: 'App.LeaseDataTabForm.editRequiredLeaseFeesModal.feesTitle',
    defaultMessage: 'Active fees:',
  },
  removedFeesTitle: {
    id: 'App.LeaseDataTabForm.editRequiredLeaseFeesModal.removedFeesTitle',
    defaultMessage: 'Removed fees:',
  },
  cancelConfirmation: {
    id: 'App.LeaseDataTabForm.editRequiredLeaseFeesModal.cancelConfirmation',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  save: {
    id: 'App.LeaseDataTabForm.editRequiredLeaseFeesModal.save',
    defaultMessage: 'Save Changes',
  },
  cancel: {
    id: 'App.LeaseDataTabForm.editRequiredLeaseFeesModal.cancel',
    defaultMessage: 'Cancel',
  },
  delete: {
    id: 'App.LeaseDataTabForm.editRequiredLeaseFeesModal.delete',
    defaultMessage: 'Delete',
  },
  invalidValue: {
    id: 'App.LeaseDataTabForm.editRequiredLeaseFeesModal.invalidValue',
    defaultMessage: 'Invalid value',
  },
  no: {
    id: 'App.LeaseDataTabForm.editRequiredLeaseFeesModal.no',
    defaultMessage: 'No',
  },
  yes: {
    id: 'App.LeaseDataTabForm.editRequiredLeaseFeesModal.yes',
    defaultMessage: 'Yes',
  },
  noFees: {
    id: 'App.LeaseDataTabForm.editRequiredLeaseFeesModal.noFees',
    defaultMessage: 'No fees to display',
  },
});

export default messages;
