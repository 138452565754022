import React from 'react';
import DoughnutCard from '../DoughnutCard';

import messages from './messages';

const NetOccupancy = ({
  occupancyData,
  netOccupancyData,
  formatMessage,
  dataLoaded,
  fetching,
  selectedOptionNumber,
  changeSelectedRange,
}: Object) => {
  const { moveIns, moveOuts, approved, inProcess, downUnits } =
    netOccupancyData;
  const { occupied, unoccupied } = occupancyData;
  const totalUnits = occupied + unoccupied;
  const netOccupancyChange = moveIns - moveOuts;
  const updatedOccupied = occupied + netOccupancyChange - downUnits;
  const updatedUnoccupied =
    totalUnits - occupied - netOccupancyChange + downUnits;
  const approvedOccupied = updatedOccupied + approved;
  const labels = ['Occupied', 'Unoccupied'];
  const data = [updatedOccupied, updatedUnoccupied];
  const approvedOccupancyPercent =
    Math.round((10000 * approvedOccupied) / totalUnits) / 100;
  const inProcessOccupancyPercent =
    Math.round((10000 * (approvedOccupied + inProcess)) / totalUnits) / 100;
  const downUnitsOccupancyPercent =
    Math.round((10000 * (occupied + netOccupancyChange)) / totalUnits) / 100;
  return (
    <DoughnutCard
      data={data}
      labels={labels}
      header={formatMessage(messages.netOccupancy)}
      dataLoaded={dataLoaded}
      fetching={fetching}
      selectedOptionNumber={selectedOptionNumber}
      changeSelectedRange={changeSelectedRange}
      showCenterText
    >
      <div className="row padtop10">
        <div className="col-xs-12 text-center">
          <span className="font-10">{`${formatMessage(
            messages.downUnits,
          )} `}</span>
          <strong className="font-10 disp-inline">
            {downUnits}
            {downUnitsOccupancyPercent
              ? `- ${downUnitsOccupancyPercent}%`
              : null}
          </strong>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6 text-center">
          <span className="font-10">
            {`${formatMessage(messages.approvedMoveIns)} `}
            <strong>{moveIns}</strong>
          </span>
        </div>
        <div className="col-xs-6 text-center">
          <span className="font-10">
            {`${formatMessage(messages.moveOuts)} `}
            <strong>{moveOuts}</strong>
          </span>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 text-center">
          <span className="font-10">{`${formatMessage(
            messages.approved,
          )} `}</span>
          <strong className="font-10 disp-inline">
            {approved}
            {approvedOccupancyPercent ? `- ${approvedOccupancyPercent}%` : null}
          </strong>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 text-center">
          <span className="font-10">{`${formatMessage(
            messages.processing,
          )} `}</span>
          <strong className="font-10 disp-inline">
            {inProcess}
            {inProcessOccupancyPercent
              ? `- ${inProcessOccupancyPercent}%`
              : null}
          </strong>
        </div>
      </div>
    </DoughnutCard>
  );
};

export default NetOccupancy;
