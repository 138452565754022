import {
  validateRequired,
  validatePositiveMoney,
} from '../../../../utils/validations';

const validate = (values: Object, props: Object) => {
  const errors = {};
  const { amount } = values;
  // Required Fields
  ['transactionType', 'subjournal', 'transactionCode', 'amount'].forEach(
    (fieldName) => {
      if (!validateRequired(fieldName, values)) {
        errors[fieldName] = 'This field is required';
      }
    },
  );

  ['amount'].forEach((fieldName) => {
    if (
      (parseFloat(amount) <= 0 || !validatePositiveMoney(amount)) &&
      !errors[fieldName]
    ) {
      errors[fieldName] = 'Please enter a valid number greater than 0';
    }
  });

  return errors;
};

export default validate;
