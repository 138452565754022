import React from 'react';
import { pathOr } from 'ramda';
import { FormattedMessage } from 'react-intl';
import BarGraph from '../../../components/Graphs/BarGraph';
import KPICardContainer from '../../../components/KPICardContainer';
import {
  Box,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { palette } from '@fortress-technology-solutions/fortress-component-library/design';
import messages from './messages';

const MakeReadies = ({ data, dataLoaded }: Object) => {
  const labels = ['Leased', 'Not Leased'];
  const leasedReady = pathOr(0, ['leased', 'ready'], data);
  const leasedNotReady = pathOr(0, ['leased', 'notReady'], data);
  const notLeasedReady = pathOr(0, ['notLeased', 'ready'], data);
  const notLeasedNotReady = pathOr(0, ['notLeased', 'notReady'], data);
  const datasets = [
    {
      label: 'Ready',
      data: [leasedReady, notLeasedReady],
    },
    {
      label: 'Not Ready',
      data: [leasedNotReady, notLeasedNotReady],
    },
  ];
  const stackedData = {
    labels,
    datasets,
  };
  return (
    <KPICardContainer
      title={<FormattedMessage {...messages.makeReadies} />}
      legend={
        <Stack spacing={1} direction={'row'} alignItems={'center'}>
          <Box
            sx={{
              backgroundColor: palette.main.blue,
              height: 12,
              width: 12,
              borderRadius: '50%',
            }}
          />
          <Typography variant={'caption'} paddingLeft={0.5}>
            <FormattedMessage {...messages.ready} />
          </Typography>
          <Box
            sx={{
              backgroundColor: palette.light.blue,
              height: 12,
              width: 12,
              borderRadius: '50%',
            }}
          />
          <Typography variant={'caption'} paddingLeft={0.5}>
            <FormattedMessage {...messages.notReady} />
          </Typography>
        </Stack>
      }
    >
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <AsyncBox loading={!dataLoaded}>
            <BarGraph data={stackedData} stacked />
          </AsyncBox>
        </div>
      </div>
    </KPICardContainer>
  );
};

export default MakeReadies;
