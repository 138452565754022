import React, { Component } from 'react';
import { Button, Col, Grid, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { any, equals, partial, pathOr } from 'ramda';
import {
  Field,
  FieldArray,
  formValueSelector,
  getFormSyncErrors,
  reduxForm,
} from 'redux-form';
import { ProrateSection } from '../ProRateSection';
import { connect } from 'react-redux';
import moment from 'moment';

import ElementWithPermissions from '../ElementWithPermissions';
import {
  renderCheckboxField,
  renderDateField,
} from '../../utils/redux-form-helper';
import messages from './messages';
import validate from './validate';
import { formatDateToEnglishLocalization } from './utils';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import type { FeatureFlags } from '../../types';

type Props = {
  applicantName: string,
  handleCancel: Function,
  handleSubmit: Function,
  handleMoveInDateUpdate: Function,
  intl: Object,
  invalid: boolean,
  moveInActions: Array<Object>,
  steps: Array<Object>,
  submitting: boolean,
  unitNumber: string,
  monthlyTransactions: Array<Object>,
  array: any,
  openFiscalPeriod?: Object,
  formSyncErrors: any,
  moveInActionsField: Array<any>,
  leaseMoveInDate: string,
  isAffordable: boolean,
  actualMoveInDateTouched: boolean,
  moveInApplyFullAmountToProRate: boolean,
  isTransUnionActive: boolean,
  flags: Object,
  roundProratedRents: boolean,
};

type InjectedProps = {
  actualMoveInDate: any,
  array: any,
};
type ReduxFormProps = {
  change: Function,
  form: string,
  handleSubmit: Function,
  initialValues: Object,
  resetSection: Function,
  array: any,
};

const renderMoveInActions = ({ moveInActions }) => (
  <ul className="list-simple list-movein-actions">
    {moveInActions.map((option, index) => (
      <li key={`actions__${index}`}>
        <Field
          name={`moveInActions[${option.id}]`}
          component={renderCheckboxField}
          bsSize="lg"
          label={option.text}
        />
      </li>
    ))}
  </ul>
);

export class MoveInCheckForm extends Component<
  Props & InjectedProps & ReduxFormProps,
> {
  onCancel = () => {
    this.props.handleCancel();
  };

  getStatus = (status: Object) => {
    return status.valid
      ? 'status status-ball status-green'
      : 'status status-ball status-red';
  };

  render() {
    const {
      applicantName,
      handleSubmit,
      intl,
      invalid,
      moveInActions,
      steps,
      submitting,
      unitNumber,
      monthlyTransactions,
      handleMoveInDateUpdate,
      formSyncErrors,
      leaseMoveInDate,
      actualMoveInDate,
      actualMoveInDateTouched,
      moveInApplyFullAmountToProRate,
      isTransUnionActive,
      roundProratedRents,
    } = this.props;

    const flags: FeatureFlags = this.props.flags;
    const MoveInDateStatus = {
      statusText: Object.keys(formSyncErrors).includes('actualMoveInDate')
        ? 'Not Confirmed'
        : 'Confirmed',
      valid: !Object.keys(formSyncErrors).includes('actualMoveInDate'),
    };

    const moveInDateFromLease = moment(leaseMoveInDate);
    const displayMoveInDateError = !moveInDateFromLease.isSame(
      actualMoveInDate,
      'day',
    );
    const formattedLeaseMoveIn = moveInDateFromLease.format('MM/DD/YYYY');
    const moveInDateErrorMsg = displayMoveInDateError
      ? `Move-in date must match the lease's Scheduled Move-In date (${formattedLeaseMoveIn})`
      : null;

    const roundProratedRentsNote = roundProratedRents
      ? intl.formatMessage(messages.roundProratedAmounts)
      : null;
    return (
      <form className="form-container container-fluid resident-convert-form resident-expand-container">
        <Grid fluid>
          <div className="applicant-convert-header">
            <Row>
              <Col xs={6} sm={12}>
                <a className="btn-text" onClick={this.onCancel}>
                  <i className="et-chevron-left" />
                  Go Back to Applicant profile
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <h1>
                  Are you sure you want to convert this Applicant to a Resident?{' '}
                  {applicantName}
                </h1>
                <h4>Unit Number: {unitNumber}</h4>
                <p>Please review and confirm before you continue.</p>
              </Col>
            </Row>
            <div className="form-container">
              <Row>
                <Col xs={12} sm={7}>
                  <h3>Steps Approved And Documents Uploaded:</h3>
                  <div className="checklist-small">
                    <ul>
                      {steps.map((step, index) => (
                        <li key={`steps__${index}`}>
                          <div className="item-property"> {step.title}</div>
                          <div className="item-value text-right">
                            {step.statusText}
                            <span className={this.getStatus(step)} />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {flags.transunionOffLeaseAppOff ? (
                    <>
                      {isTransUnionActive && (
                        <ElementWithPermissions
                          hidden
                          scope={['move-in-without-screening']}
                        >
                          <ul className="list-simple">
                            <li>
                              <Field
                                name={'moveInWithoutScreening'}
                                component={renderCheckboxField}
                                bsSize="lg"
                                label={intl.formatMessage(
                                  messages.moveInWithoutScreening,
                                )}
                              />
                            </li>
                          </ul>
                        </ElementWithPermissions>
                      )}
                    </>
                  ) : (
                    <ElementWithPermissions
                      hidden
                      scope={['move-in-without-screening']}
                    >
                      <ul className="list-simple">
                        <li>
                          <Field
                            name={'moveInWithoutScreening'}
                            component={renderCheckboxField}
                            bsSize="lg"
                            label={intl.formatMessage(
                              messages.moveInWithoutScreening,
                            )}
                          />
                        </li>
                      </ul>
                    </ElementWithPermissions>
                  )}
                </Col>
                <Col xs={12} sm={5}>
                  <h3>Move-In Actions Completed:</h3>
                  <FieldArray
                    name="moveInActions"
                    component={renderMoveInActions}
                    moveInActions={moveInActions}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="padtop10">
                  <h3>Confirm Move-In Date:</h3>
                </Col>
                <Col xs={12} sm={7}>
                  <div className="checklist-small no-margin-bottom">
                    <ul>
                      <li>
                        <div className="item-property">
                          <Field
                            name="actualMoveInDate"
                            onChange={partial(handleMoveInDateUpdate, [
                              this.props.moveInActionsField,
                            ])}
                            component={renderDateField}
                            format={formatDateToEnglishLocalization}
                            placeholder="MM/DD/YYYY"
                            bsSize="lg"
                          />
                          {moveInDateErrorMsg && (
                            <p
                              className={
                                actualMoveInDateTouched
                                  ? 'text-red'
                                  : 'text-gray'
                              }
                            >
                              {moveInDateErrorMsg}
                            </p>
                          )}
                        </div>
                        <div className="item-value text-right">
                          {MoveInDateStatus.statusText}
                          <span className={this.getStatus(MoveInDateStatus)} />
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs={12}>
                  <p>
                    <strong>
                      IMPORTANT: This date must be the actual date of move-in.
                    </strong>
                  </p>
                  <p>
                    <b>Note: </b>
                    Move-In Date cannot be in the future. Changes will recompute
                    the prorated values below.
                  </p>
                </Col>
              </Row>
              <hr />
            </div>
          </div>
          <ProrateSection
            headerText={intl.formatMessage(
              messages.proRateAmountsToLedgerHeader,
              {
                type: moveInApplyFullAmountToProRate
                  ? intl.formatMessage(messages.full)
                  : intl.formatMessage(messages.proRate),
              },
            )}
            noteText={
              'Move-In Date changes will result in previously edited prorated amounts being reset.'
            }
            monthlyTransactions={monthlyTransactions}
            moveInDate={this.props.actualMoveInDate}
            intl={intl}
            moveInApplyFullAmountToProRate={moveInApplyFullAmountToProRate}
            roundProratedRentsNote={roundProratedRentsNote}
          ></ProrateSection>
          <Col xs={6}>
            <Button
              bsStyle="default"
              className="btn btn-default pull-right"
              onClick={this.onCancel}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={6}>
            <ElementWithPermissions scope={['resident-create']}>
              <Button
                disabled={any(equals(true), [submitting, invalid])}
                className="btn btn-primary pull-left"
                onClick={handleSubmit}
              >
                <FormattedMessage {...messages.submitButton} />
              </Button>
            </ElementWithPermissions>
          </Col>
        </Grid>
      </form>
    );
  }
}

const MoveInCheckApplicationForm = reduxForm({
  form: 'MoveInCheck',
  validate: validate,
  enableReinitialize: true,
})(MoveInCheckForm);

const selector = formValueSelector('MoveInCheck');

const mapStateToProps = (state: Object): any => {
  const { form } = state;
  return {
    moveInActionsField: selector(state, 'moveInActions'),
    actualMoveInDate: selector(state, 'actualMoveInDate'),
    formSyncErrors: getFormSyncErrors('MoveInCheck')(state),
    actualMoveInDateTouched: pathOr(
      false,
      ['MoveInCheck', 'fields', 'actualMoveInDate', 'touched'],
      form,
    ),
  };
};

export const MoveInCheckApplicationScreen = withLDConsumer()(
  connect(mapStateToProps)(MoveInCheckApplicationForm),
);
