import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { renderDateField } from '../../utils/redux-form-helper';

import { moveInMessages, moveOutMessages } from './messages.js';
import validate from './validate';

type MoveType = 'moveIn' | 'moveOut';

type Props = {
  show: boolean,
  dismiss: Function,
  handleSubmit: Function,
  onSubmit: Function,
  pristine: boolean,
  valid: boolean,
  reset: Function,
  dirty: boolean,
  initialValues: Object,
  moveType: MoveType,
  additionalValidation?: Function,
};

type State = {
  showCancelConfirmation: boolean,
};

export class EditMoveDateModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showCancelConfirmation: false,
    };
  }

  messages =
    this.props.moveType === 'moveIn' ? moveInMessages : moveOutMessages;

  cancelConfirmationToggle = (visible: boolean) => {
    this.setState({
      showCancelConfirmation: visible,
    });
  };

  onClose = () => {
    const { dirty, reset, dismiss } = this.props;
    const { showCancelConfirmation } = this.state;

    if (dirty && !showCancelConfirmation) {
      this.cancelConfirmationToggle(true);
      return;
    } else if (dirty && showCancelConfirmation) {
      reset();
    }

    this.cancelConfirmationToggle(false);
    dismiss();
  };

  renderModalHeader = () => {
    return (
      <Modal.Header closeButton>
        <i className="icon et-pencil" />
        <Modal.Title componentClass="h1">
          <FormattedMessage {...this.messages.title} />
        </Modal.Title>
      </Modal.Header>
    );
  };

  renderModalFooter = () => {
    const { pristine, valid, handleSubmit } = this.props;
    const { showCancelConfirmation } = this.state;
    const submitEnabled = pristine || (!pristine && valid);

    return (
      <Modal.Footer className={showCancelConfirmation ? 'disabled' : ''}>
        <Row>
          <Col xs={6}>
            <Button
              bsStyle="default"
              className="pull-right"
              onClick={this.onClose}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              bsStyle="primary"
              className="pull-left"
              type="submit"
              onClick={handleSubmit}
              disabled={!submitEnabled}
            >
              Save Changes
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    );
  };

  renderModalFormBody = () => {
    return (
      <Row className="scrollable-modal__content">
        <div className="container-fluid">
          <Row>
            <Col md={5} xs={12}>
              <label>
                <FormattedMessage {...this.messages.dateLabel} />
              </label>
            </Col>
            <Col md={7} xs={12}>
              <Field
                name="editMoveDate"
                component={renderDateField}
                bsSize="md"
              />
            </Col>
          </Row>
        </div>
      </Row>
    );
  };

  renderModalCancelBody = () => {
    return (
      <div className="modal-confirm">
        <Row>
          <Col xs={12}>
            <h1>Are you sure you want to leave without saving?</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Button
              bsStyle="primary"
              className="pull-right"
              onClick={this.onClose}
            >
              Yes
            </Button>
          </Col>
          <Col xs={12} sm={6}>
            <Button
              bsStyle="default"
              className="pull-left"
              onClick={() => this.cancelConfirmationToggle(false)}
            >
              No
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    const { show } = this.props;
    const { showCancelConfirmation } = this.state;

    return (
      <form>
        <Modal
          className="edit-move-date"
          bsSize="sm"
          backdrop
          show={show}
          onHide={() => this.onClose()}
        >
          {this.renderModalHeader()}
          <Modal.Body>
            {this.renderModalFormBody()}
            {showCancelConfirmation && this.renderModalCancelBody()}
          </Modal.Body>
          {this.renderModalFooter()}
        </Modal>
      </form>
    );
  }
}

export const mapStateToProps = (
  { form }: Object,
  { initialValues }: Object,
): Object => {
  return {
    initialValues,
  };
};

export const EditMoveInDateModal = connect(mapStateToProps)(
  reduxForm({
    form: 'editMoveInDateForm',
    enableReinitialize: true,
    validate,
  })(EditMoveDateModal),
);

export const EditMoveOutDateModal = connect(mapStateToProps)(
  reduxForm({
    form: 'editMoveOutDateForm',
    enableReinitialize: true,
    validate,
  })(EditMoveDateModal),
);
