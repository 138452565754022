import {
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  grey,
  orange,
  red,
} from '@fortress-technology-solutions/fortress-component-library/design';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

function ActivityTableLegend() {
  return (
    <Stack
      direction={'row'}
      marginTop={1}
      marginBottom={1}
      spacing={1}
      alignItems={'center'}
    >
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
        <rect
          x="0.5"
          y="0.5"
          width="14"
          height="14"
          fill={orange.lighter}
          stroke={grey.light}
        />
      </svg>
      <Typography>
        <FormattedMessage {...messages.upcoming} />
      </Typography>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
        <rect
          x="0.5"
          y="0.5"
          width="14"
          height="14"
          fill={red.lighter}
          stroke={grey.light}
        />
      </svg>
      <Typography>
        <FormattedMessage {...messages.pastDue} />
      </Typography>
    </Stack>
  );
}

export default ActivityTableLegend;
