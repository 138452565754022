import { defineMessages } from 'react-intl';

export default defineMessages({
  between: {
    id: 'app.containers.AllManageLeaseExpirations.between',
    defaultMessage: 'between',
  },
  leaseEndDateBaseMessage: {
    id: 'app.containers.AllManageLeaseExpirations.leaseEndDateBaseMessage',
    defaultMessage: 'Lease End Date is',
  },
  leases: {
    id: 'app.containers.AllManageLeaseExpirations.leases',
    defaultMessage: 'leases',
  },
  leasesExpiringIn: {
    id: 'app.containers.AllManageLeaseExpirations.leasesExpiringIn',
    defaultMessage: 'Leases Expiring in',
  },
  monthToMonth: {
    id: 'app.containers.AllManageLeaseExpirations.Month-to-Month',
    defaultMessage: 'Month-to-Month',
  },
  onOrBefore: {
    id: 'app.containers.AllManageLeaseExpirations.onOrBefore',
    defaultMessage: 'on or before',
  },
  to: {
    id: 'app.containers.AllManageLeaseExpirations.to',
    defaultMessage: 'to',
  },
});
