import { omit } from 'ramda';
import type { Action } from '../App/types';
import type { WorkOrderState } from './types';
import * as ActionTypes from './constants';

export const initialUnitOptionsState: Array<Object> = [];

export const unitOptionsReducer = (
  state: Array<Object> = initialUnitOptionsState,
  action: Action<*, *>,
): Array<Object> => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ALL_UNITS_SUCCESS: {
      return [...payload];
    }
    default:
      return state;
  }
};

export const initialEditWorkOrderState: WorkOrderState = {
  workOrder: {
    requestDate: '',
    detailIdReadable: '',
    enteredByUser: {
      firstName: '',
      lastName: '',
    },
    assignedTo: {
      id: '',
      firstName: '',
      lastName: '',
    },
  },
  isSubmitting: false,
};

export const editWorkOrder = (
  state: WorkOrderState = initialEditWorkOrderState,
  action: Action<*, *>,
): WorkOrderState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_WORK_ORDER_SUCCESS: {
      const workOrder = omit(['requestorId'], payload);
      return { workOrder, isSubmitting: false };
    }
    case ActionTypes.CREATE_WORK_ORDER: {
      return { ...state, isSubmitting: true };
    }
    case ActionTypes.CREATE_WORK_ORDER_SUCCESS: {
      return {
        workOrder: {
          ...initialEditWorkOrderState.workOrder,
          ...payload,
        },
        isSubmitting: false,
      };
    }
    case ActionTypes.CLEAR_LOADED_WORK_ORDER: {
      return initialEditWorkOrderState;
    }
    default:
      return state;
  }
};
