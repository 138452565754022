import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import Spinner from '../../../../components/Spinner';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ColumnHeader from '../../../../components/ColumnHeader';
import SearchField from '../../../Fields/Search';
import { Row, Col } from 'react-bootstrap';
import { clone } from 'ramda';
import { getUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';

type Props = {
  intl: any,
  currentResidents: Object,
  isCurrentResidentsLoading: boolean,
  currentResidentRows: Object,
  setCurrentResidentRows: Function,
  setDisableAddToBatch: Function,
  isAllCommercial: boolean,
};

const renderAllCurrentResidents = (currentResidents, toggleSelectedRow) => {
  return currentResidents && currentResidents.length > 0 ? (
    currentResidents.map((currentResident, i) => {
      return (
        <tr key={i}>
          <td className="hidden-xs">
            <div
              className="checkbox custom-checkbox checkbox-lg"
              style={{ margin: 0 }}
            >
              <label>
                <input
                  type="checkbox"
                  checked={currentResident.selected}
                  onChange={() => toggleSelectedRow(currentResident.customerId)}
                />
                <span className="custom-check-square" />
              </label>
            </div>
          </td>
          <td>{currentResident?.status}</td>
          <td>{currentResident?.unitNumber}</td>
          <td>{currentResident?.floorPlan}</td>
          <td>
            <a
              href={getUrlWithSelectedPropertyId(
                `/resident/${currentResident?.residentId}`,
              )}
              target="_blank"
              rel="noreferrer"
            >
              {currentResident?.customerName}
            </a>
          </td>
          <td>{currentResident?.leaseStartDate}</td>
          <td>{currentResident?.leaseEndDate}</td>
          <td>{currentResident.currentLedgerBalance}</td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan={8}>
        <div className="text-center">
          <FormattedMessage {...messages.noDataDescription} />
        </div>
      </td>
    </tr>
  );
};

export const AddGroupGrid = (props: Props) => {
  const {
    isCurrentResidentsLoading,
    currentResidents,
    intl,
    currentResidentRows,
    setCurrentResidentRows,
    setDisableAddToBatch,
    isAllCommercial,
  } = props;
  const [selectedAll, setSelectedAll] = useState(true);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [filteredResidents, setFilteredResidents] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  useEffect(() => {
    setSelectedRowCount(currentResidents?.results?.length);
  }, [currentResidents]);

  const toggleSelectedRow = (customerId: string) => {
    const updatedRows = currentResidentRows.map((row) => {
      if (row.customerId === customerId) {
        row.selected = !row.selected;
      }
      return row;
    });
    setCurrentResidentRows([...updatedRows]);
    const selectedRow = updatedRows.find(
      (row) => row.customerId === customerId,
    );

    isFiltered &&
      setFilteredResidents([
        ...filteredResidents?.map((row) => {
          if (row.customerId === customerId) {
            row.selected = !row.selected;
          }
          return row;
        }),
      ]);
    const isSelected = selectedRow?.selected;
    if (isSelected) {
      /** enable AddtoBatch button if at least one row selected*/
      setSelectedRowCount(selectedRowCount + 1);
      setDisableAddToBatch(false);
      setShowErrorMsg(false);
      if (selectedRowCount + 1 === currentResidentRows.length) {
        setSelectedAll(true);
      }
    } else {
      /** disable AddtoBatch button if no rows selected*/
      setSelectedRowCount(selectedRowCount > 0 ? selectedRowCount - 1 : 0);
      if (selectedRowCount - 1 === 0) {
        setDisableAddToBatch(true);
        setShowErrorMsg(true);
      }
      setSelectedAll(false);
    }
  };
  const infoPopover = (
    <Popover id={'popover-positioned-bottom'}>
      <p>
        {isAllCommercial ? (
          <FormattedMessage {...messages.tooltipTenant} />
        ) : (
          <FormattedMessage {...messages.tooltipResident} />
        )}
      </p>
    </Popover>
  );
  const onSelectAllClicked = () => {
    if (selectedAll) {
      const listOfResidents = currentResidentRows?.map((row) => {
        return { ...row, selected: false };
      });
      setCurrentResidentRows([...listOfResidents]);

      isFiltered &&
        setFilteredResidents([
          ...filteredResidents?.map((row) => {
            return { ...row, selected: false };
          }),
        ]);
      setSelectedRowCount(0);
      setDisableAddToBatch(true);
      setShowErrorMsg(true);
    } else {
      const listOfResidents = currentResidentRows?.map((row) => {
        return { ...row, selected: true };
      });
      setCurrentResidentRows([...listOfResidents]);

      isFiltered &&
        setFilteredResidents([
          ...filteredResidents?.map((row) => {
            return { ...row, selected: true };
          }),
        ]);

      setSelectedRowCount(currentResidentRows.length);
      setDisableAddToBatch(false);
      setShowErrorMsg(false);
    }
    setSelectedAll(!selectedAll);
  };

  const handleFilter = ({ searchText }: Object) => {
    if (searchText) {
      setIsFiltered(true);
      const searchResult = currentResidentRows
        ?.filter(
          (row) =>
            row?.unitNumber.includes(searchText) ||
            row?.customerName.toLowerCase().includes(searchText.toLowerCase()),
        )
        .sort((a, b) =>
          (a?.unitNumber ?? null) < (b?.unitNumber ?? null) ? -1 : 1,
        );
      setFilteredResidents(clone(searchResult));
    } else {
      setIsFiltered(false);
      setFilteredResidents([]);
    }
  };

  return (
    <div className="container-fluid padleft10">
      <div className="padtop30">
        {showErrorMsg && (
          <div className="alert alert-secondary">
            <div className="container-fluid">
              <div>
                <strong>
                  {isAllCommercial ? (
                    <FormattedMessage {...messages.errorMsgTenant} />
                  ) : (
                    <FormattedMessage {...messages.errorMsgResident} />
                  )}
                </strong>
              </div>
            </div>
          </div>
        )}
        <Row>
          <Col md={6}>
            <h2 className="padtop30">
              {isAllCommercial ? (
                <FormattedMessage {...messages.gridLabelTenant} />
              ) : (
                <FormattedMessage {...messages.gridLabelResident} />
              )}
            </h2>
          </Col>
          <Col md={6}>
            <div className="search-actions search-align-right pull-right padbottom10">
              <SearchField
                className="search-input search-input-active"
                form="ManageRentRoll"
                placeholder={intl.formatMessage(
                  isAllCommercial
                    ? messages.searchTenant
                    : messages.searchResident,
                )}
                onSubmit={handleFilter}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="table-scroll table-deposits-container">
        <table className="table table-prospects table-striped">
          <thead className="table-header">
            <tr>
              <ColumnHeader
                columnLabel={
                  <div
                    className="checkbox custom-checkbox checkbox-lg"
                    style={{ margin: 0 }}
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedAll}
                        onChange={onSelectAllClicked}
                        disabled={filteredResidents?.length}
                      />
                      <span className="custom-check-square" />
                    </label>
                  </div>
                }
              />
              <th>
                <FormattedMessage {...messages.status} />
              </th>
              <th>
                <FormattedMessage {...messages.unit} />
              </th>
              <th>
                <FormattedMessage {...messages.floorPlan} />
              </th>
              <th>
                {isAllCommercial ? (
                  <FormattedMessage {...messages.tenant} />
                ) : (
                  <FormattedMessage {...messages.resident} />
                )}
              </th>
              <th>
                <FormattedMessage {...messages.leaseStartDate} />
              </th>
              <th>
                <FormattedMessage {...messages.leaseEndDate} />
              </th>
              <th>
                <FormattedMessage {...messages.currentLedgerBalance} />{' '}
                <OverlayTrigger overlay={infoPopover}>
                  <i className="icon et-alert-info" />
                </OverlayTrigger>
              </th>
            </tr>
          </thead>
          <tbody>
            {!isCurrentResidentsLoading ? (
              renderAllCurrentResidents(
                isFiltered ? [...filteredResidents] : [...currentResidentRows],
                toggleSelectedRow,
              )
            ) : (
              <tr>
                <td colSpan={8}>
                  <div className="text-center">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddGroupGrid;
