import uniq from 'lodash/uniq';
import { get, post, put } from '../../utils/api';
import {
  CUSTOMER_PREFIX,
  MESSAGE_LIMIT,
  NEW_CONV_SUFFIX,
  PROSPECT_PREFIX,
} from './constants';
import {
  customerToHouseholdMember,
  prospectToHouseholdMember,
  messageDtoToMessage,
  conversationDtoToConversation,
} from './mappings';
import type { HouseholdMember } from './types';

export function fetchHouseholdMembers({
  prospectId,
  householdId,
  selectedProperty,
}): Promise<HouseholdMember[]> {
  const orgPropPrefix = `/${selectedProperty.organizationId}/${selectedProperty.id}/`;

  if (prospectId) {
    return get(`${orgPropPrefix}prospect/${prospectId}`).then((response) => {
      return [prospectToHouseholdMember(response)];
    });
  }

  return get(
    `${orgPropPrefix}households/${householdId}/customers/texting-customers`,
  ).then((response) => {
    return response.map(customerToHouseholdMember);
  });
}

export async function sendMessage({ message, conversation }) {
  const recipientId = conversation.recipients[0].id;
  const isProspect = recipientId.startsWith(PROSPECT_PREFIX);
  const recipientKey = isProspect ? 'prospectId' : 'customerId';
  const recipientValue = isProspect
    ? recipientId.replace(PROSPECT_PREFIX, '')
    : recipientId.replace(CUSTOMER_PREFIX, '');

  const response = await post(
    `${process.env.REACT_APP_TWO_WAY_API_URL}/messages`,
    JSON.stringify({
      content: message,
      recipient: {
        [recipientKey]: recipientValue,
      },
    }),
    { isOtherApi: true },
  );

  return response;
}

export async function fetchConversations({ householdMembers }) {
  if (!householdMembers || householdMembers.length === 0) return [];

  const recipientIdParam = householdMembers
    .map((hm) => {
      const isProspect = hm.id.startsWith(PROSPECT_PREFIX);
      const recipientId = isProspect
        ? hm.id.replace(PROSPECT_PREFIX, '')
        : hm.id.replace(CUSTOMER_PREFIX, '');
      return `recipientId=${recipientId}`;
    })
    .join('&');

  const conversations = await get(
    `${process.env.REACT_APP_TWO_WAY_API_URL}/conversations?${recipientIdParam}`,
    { isOtherApi: true },
  );

  return householdMembers.map((hm) => {
    const conversation = conversations.find((c) => {
      const recipient = c.recipients[0];
      return (
        hm.id.includes(recipient.customerId) ||
        hm.id.includes(recipient.prospectId)
      );
    });

    return conversationDtoToConversation(
      conversation ?? {
        _id: `${hm.id}${NEW_CONV_SUFFIX}`,
        lastReceivedMessage: null,
      },
      hm,
    );
  });
}

export async function fetchMessages({
  conversation,
  selectedProperty,
  cursor,
  includeUser,
  includeAttachments,
}) {
  if (!conversation || conversation.id.includes(NEW_CONV_SUFFIX)) return [];

  const messages = await get(
    `${process.env.REACT_APP_TWO_WAY_API_URL}/messages?conversationId=${
      conversation.id
    }&limit=${MESSAGE_LIMIT}${cursor ? `&cursor=${cursor}` : ''}`,
    { isOtherApi: true },
  );

  const messagesWithUser = messages.filter((m) => m.userId);
  const userIds = uniq(messagesWithUser.map((m) => m.userId));

  const users =
    includeUser && userIds.length
      ? await get(`/users?id=${userIds.join(',')}`)
      : [];

  return messages.map((m) =>
    messageDtoToMessage({
      messageDto: m,
      conversation,
      selectedProperty,
      includeUser,
      user: users.find((u) => u.id === m.userId),
      includeAttachments,
    }),
  );
}

export async function updateConversation(conversationId: string, body: Object) {
  return put(
    `${process.env.REACT_APP_TWO_WAY_API_URL}/conversations/${conversationId}`,
    JSON.stringify(body),
    { isOtherApi: true },
  );
}
