// @flow

import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import ErrorMessage from '../../../components/ErrorMessage';
import messages from '../messages';
import validate from './validate';
import Success from './Success';
import View from './View';
import type { Intl } from '../../../types';
import type { Restrictions } from './types';

export type Props = {
  intl: Intl,
  handleSubmit: Function,
  change: Function,
  history: { push: (string) => void },
  errorMessage: string,
  validToken?: boolean,
  showSuccessMessage?: boolean,
  showErrorMessage?: boolean,
  valid?: boolean,
  submitting?: boolean,
  pristine?: boolean,
};

export type State = Restrictions;

export class ResetPasswordForm extends Component<Props, State> {
  state: Object = {};

  handleChange: any = (ev: any, val: string) => {
    this.setState({
      minTenCharacters: val.length >= 10,
      minOneUppercase: /[A-Z]/.test(val),
      minOneLowercase: /[a-z]/.test(val),
      minOneSymbol: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(val),
      minOneNumber: /[0-9]/.test(val),
    });
  };

  onReturnToLoginClick: any = () => this.props.history.push('/login');

  render(): any {
    const {
      intl,
      handleSubmit,
      showSuccessMessage,
      showErrorMessage,
      errorMessage,
      valid,
      submitting,
      validToken,
    } = this.props;

    return (
      <div>
        <div className="loginwrapper">
          <div className="container-fluid login-container">
            <h1 className="fortress-logo-gray">
              <span>Fortress</span>
            </h1>
            <Row>
              <Col xs={12}>
                {!showSuccessMessage && (
                  <div className="login-details text-center">
                    <h4>{intl.formatMessage(messages.header)}</h4>
                  </div>
                )}

                {showErrorMessage && <ErrorMessage message={errorMessage} />}

                {!showSuccessMessage && (
                  <View
                    intl={intl}
                    handleSubmit={handleSubmit}
                    handleChange={this.handleChange}
                    minTenCharacters={this.state.minTenCharacters}
                    minOneUppercase={this.state.minOneUppercase}
                    minOneLowercase={this.state.minOneLowercase}
                    minOneSymbol={this.state.minOneSymbol}
                    minOneNumber={this.state.minOneNumber}
                    valid={valid}
                    submitting={submitting}
                    showErrorMessage={showErrorMessage}
                    validToken={validToken}
                  />
                )}

                {showSuccessMessage && (
                  <Success
                    intl={intl}
                    returnToLogin={this.onReturnToLoginClick}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
        <div className="footer-login">
          <p className="text-center">{intl.formatMessage(messages.help)}</p>
        </div>
      </div>
    );
  }
}

// $FlowFixMe
export default reduxForm({
  form: 'resetPassword',
  validate,
})(ResetPasswordForm);
