import type { Prospect, Activity } from '../ProspectProfile/types';
import type {
  ProspectAndPagination,
  ProspectPaginationAndStatusChange,
  ActivityAndPagination
} from './types';
import type {
  Action,
  PaginationMeta,
  FilterValueAndPagination,
  FilterValue,
  OrderValue
} from '../App/types';
import * as ActionTypes from './constants';

export function getAllProspects(
  pageNumber: number,
  limit: number,
  filterValue: FilterValue,
  sorting: OrderValue,
  searchText: string
): Action<FilterValueAndPagination> {
  return {
    type: ActionTypes.GET_ALL_PROSPECTS,
    payload: {
      sorting,
      filterValue,
      searchText,
      paginationInfo: {
        pageNumber,
        limit
      }
    }
  };
}

export function getAllProspectsSuccess(
  prospects: Array<Prospect>,
  meta: PaginationMeta
): Action<Array<Prospect>, PaginationMeta> {
  return {
    type: ActionTypes.GET_ALL_PROSPECTS_SUCCESS,
    payload: prospects,
    meta: meta
  };
}

export function getAllProspectsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_PROSPECTS_ERROR,
    payload: error
  };
}

export function assignProspect(
  prospect: Prospect,
  pageNumber: number,
  limit: number
): Action<ProspectAndPagination> {
  return {
    type: ActionTypes.ASSIGN_PROSPECT,
    payload: {
      prospect,
      paginationInfo: {
        pageNumber,
        limit
      }
    }
  };
}

export function assignProspectSuccess(): Action<any> {
  return {
    type: ActionTypes.ASSIGN_PROSPECT_SUCCESS,
    payload: undefined
  };
}

export function assignProspectError(error: Error): Action<Error> {
  return {
    type: ActionTypes.ASSIGN_PROSPECT_ERROR,
    payload: error
  };
}

export function cleanLoadedProspects(): Action<any> {
  return {
    type: ActionTypes.CLEAN_LOADED_PROSPECTS,
    payload: undefined
  };
}

export function changeProspectStatus(
  prospect: Prospect,
  newStatus: string,
  notes: string,
  pageNumber: number,
  limit: number
): Action<ProspectPaginationAndStatusChange> {
  return {
    type: ActionTypes.CHANGE_PROSPECT_STATUS,
    payload: {
      prospect,
      newStatusInfo: {
        newStatus,
        notes
      },
      paginationInfo: {
        pageNumber,
        limit
      }
    }
  };
}

export function changeProspectStatusSuccess(): Action<any> {
  return {
    type: ActionTypes.CHANGE_PROSPECT_STATUS_SUCCESS,
    payload: undefined
  };
}

export function changeProspectStatusError(error: Error): Action<Error> {
  return {
    type: ActionTypes.CHANGE_PROSPECT_STATUS_ERROR,
    payload: error
  };
}

export function createProspectActivity(
  activity: Activity,
  pageNumber: number,
  limit: number
): Action<ActivityAndPagination> {
  return {
    type: ActionTypes.CREATE_PROSPECT_ACTIVITY,
    payload: {
      activity: Object.assign(activity, {
        customerStatus: ActionTypes.PROSPECT
      }),
      paginationInfo: {
        pageNumber,
        limit
      }
    }
  };
}

export function updateProspectActivity(
  activity: Activity,
  pageNumber: number,
  limit: number
): Action<ActivityAndPagination> {
  return {
    type: ActionTypes.UPDATE_PROSPECT_ACTIVITY,
    payload: {
      activity,
      paginationInfo: {
        pageNumber,
        limit
      }
    }
  };
}

export function refreshProspect(
  activity: Activity,
  pageNumber: number,
  limit: number
): Action<ActivityAndPagination> {
  return {
    type: ActionTypes.REFRESH_PROSPECT,
    payload: {
      activity,
      paginationInfo: {
        pageNumber,
        limit
      }
    }
  };
}

export function deleteProspectActivity(
  activity: Activity,
  pageNumber: number,
  limit: number
): Action<ActivityAndPagination> {
  return {
    type: ActionTypes.DELETE_PROSPECT_ACTIVITY,
    payload: {
      activity,
      paginationInfo: {
        pageNumber,
        limit
      }
    }
  };
}

export function createProspectActivitySuccess(): Action<any> {
  return {
    type: ActionTypes.CREATE_PROSPECT_ACTIVITY_SUCCESS,
    payload: undefined
  };
}

export function createProspectActivityError(error: Error): Action<Error> {
  return {
    type: ActionTypes.CREATE_PROSPECT_ACTIVITY_ERROR,
    payload: error
  };
}

export function clearManageProspectMessages(): Action<any> {
  return {
    type: ActionTypes.CLEAR_MANAGE_PROSPECT_MESSAGES,
    payload: undefined
  };
}

export function updateColumnsSortValue(field: string, value: string) {
  return {
    type: ActionTypes.UPDATE_COLUMNS_SORT_VALUE,
    payload: { [field]: value }
  };
}
