import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import messages from './messages';

const TITLE_FONT_SIZE = '18px';
const TITLE_SPACING = '16px';

const SectionTitle = styled.h2`
  color: #4d4d4d;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: ${TITLE_FONT_SIZE};
  line-height: 21px;
  letter-spacing: -0.32px;
  margin-right: ${TITLE_SPACING};
`;

const TitleIcon = styled.i`
  color: rgba(43, 145, 171, 1);
  font-size: ${TITLE_FONT_SIZE};
  margin-right: ${TITLE_SPACING};
`;

const LeaseSelect = styled.select`
  margin-top: -5px;
`;

type OnLeaseChangeEvent = (leaseId: string) => void;

export type SectionHeaderProps = {
  windowWidth: number,
  leaseOptions: Object[],
  onLeaseChange: OnLeaseChangeEvent,
  selectedLeaseId: string,
  showAbstractButtons: boolean,
  showLeaseDropdown: boolean,
  onPDFBtnClick: Function,
  disabledAbstractButtons: boolean,
};

function SectionHeader({
  windowWidth,
  leaseOptions,
  onLeaseChange,
  selectedLeaseId,
  showAbstractButtons,
  showLeaseDropdown,
  onPDFBtnClick,
  disabledAbstractButtons,
}: SectionHeaderProps) {
  const titleLg = useMemo(() => {
    if (windowWidth >= 1700) return 2;
    if (windowWidth >= 1280) return 3;
    return 4;
  }, [windowWidth]);
  const leaseSelectLgPull = useMemo(() => {
    if (windowWidth >= 2400) return 1;
    if (windowWidth >= 1700) return 0;
    if (windowWidth >= 1480) return 1;
    if (windowWidth >= 1280) return 0;
    return 1;
  }, [windowWidth]);

  return (
    <React.Fragment>
      <Col sm={4} md={5} lg={titleLg}>
        <SectionTitle>
          <TitleIcon className="et-clipboard" />
          <FormattedMessage {...messages.leaseBasics} />
        </SectionTitle>
      </Col>
      {showLeaseDropdown && (
        <Col sm={5} md={6} lg={3} lgPull={leaseSelectLgPull}>
          <LeaseSelect
            className="form-control"
            onChange={onLeaseChange}
            value={selectedLeaseId}
          >
            {leaseOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.text}
              </option>
            ))}
          </LeaseSelect>
        </Col>
      )}
      {showAbstractButtons && (
        <Col>
          <button
            className="btn-tertiary pull-right btn padright10"
            style={{ marginTop: 0 }}
            onClick={onPDFBtnClick}
            disabled={disabledAbstractButtons}
          >
            <i className="icon et-cloud-download" />
            <span style={{ marginLeft: '8px' }}>PDF </span>
          </button>
        </Col>
      )}
    </React.Fragment>
  );
}

export default SectionHeader;
