const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class NavigationOptionsService {
  getAll() {
    const options = {
      headers: {
        Authorization: process.env.REACT_APP_TEMP_TOKEN,
        'Content-Type': 'application/json'
      }
    };
    return request(`${apiURL}/navigationOptions`, options);
  }
}

export default NavigationOptionsService;
