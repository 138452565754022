export const GET_ONE_APPLICATION =
  'app/EditApplicaitonHousehold/GET_ONE_APPLICATION';
export const GET_ONE_APPLICATION_SUCCESS =
  'app/EditApplicaitonHousehold/GET_ONE_APPLICATION_SUCCESS';
export const GET_ONE_APPLICATION_ERROR =
  'app/EditApplicaitonHousehold/GET_ONE_APPLICATION_ERROR';

export const EDIT_APPLICATION_HOUSEHOLD =
  'app/EditApplicaitonHousehold/EDIT_APPLICATION_HOUSEHOLD';
export const EDIT_APPLICATION_HOUSEHOLD_SUCCESS =
  'app/EditApplicaitonHousehold/EDIT_APPLICATION_HOUSEHOLD_SUCCESS';
export const EDIT_APPLICATION_HOUSEHOLD_ERROR =
  'app/EditApplicaitonHousehold/EDIT_APPLICATION_HOUSEHOLD_ERROR';

export const CLEAN = 'app/EditApplicaitonHousehold/CLEAN';
