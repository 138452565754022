import { useEffect, useState } from 'react';
import { pathOr, pathEq } from 'ramda';

import type {
  FetchIntacctGLAccountsHookProps,
  FetchSubjournalsHookProps,
  FetchChargeCodesHookProps,
  FetchCamAllocationsHookProps,
  ModifyCamAllocationHookProps,
  CreateCamExceptionHookProps,
  RemoveCamExceptionHookProps,
} from './types';

import IntacctGLAccountsService from '../../../../services/intacctGLAccountsService';
import PropertySubjournalTypesService from '../../../../services/propertySubjournalTypesService';
import CamAllocationService from '../../../../services/camAllocationService';
import CamExceptionService from '../../../../services/camExceptionService';

import { formatGLAccountsResponse, fetchStartYearExpenses } from './utils';
import messages from './messages';

const REQUEST_ABORTED = 'The user aborted a request.';

export const useFetchIntacctGLAccounts = ({
  intl,
  propertyId,
  organizationId,
  selectedAllocation,
  promptToaster,
  loadAllGLAccounts,
}: FetchIntacctGLAccountsHookProps): any => {
  const [intacctGLAccounts, setIntacctGLAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetchIntacctGLAccounts = async () => {
      try {
        setIsLoading(true);
        const intacctGLAccountsService = new IntacctGLAccountsService();
        let response;
        if (selectedAllocation) {
          const intacctGLAccountsGroupId = pathOr(
            null,
            ['intacctGLAccountGroup', 'id'],
            selectedAllocation,
          );
          if (intacctGLAccountsGroupId !== null) {
            response = await intacctGLAccountsService.getByGroupId(
              organizationId,
              propertyId,
              intacctGLAccountsGroupId,
              abortController.signal,
            );
          } else {
            response = await intacctGLAccountsService.getAll(
              organizationId,
              propertyId,
              abortController.signal,
            );
          }
        } else {
          response = await intacctGLAccountsService.getAll(
            organizationId,
            propertyId,
            abortController.signal,
          );
        }
        const glAccounts = formatGLAccountsResponse(response);
        setIntacctGLAccounts(glAccounts);

        const isComplete = pathOr(false, ['isComplete'], selectedAllocation);
        if (!isComplete && !!selectedAllocation) loadAllGLAccounts(glAccounts);
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.fetchIntacctGlAccountsError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    };

    if (!isLoading && shouldRefresh) {
      fetchIntacctGLAccounts();
    }

    return () => setShouldRefresh(false);
  }, [
    intl,
    organizationId,
    propertyId,
    selectedAllocation,
    promptToaster,
    shouldRefresh,
    isLoading,
    loadAllGLAccounts,
  ]); // eslint-disable-line

  return [isLoading, intacctGLAccounts];
};

export const useFetchSubjournals = ({
  intl,
  propertyId,
  organizationId,
  promptToaster,
}: FetchSubjournalsHookProps): any => {
  const [subjournals, setSubjournals] = useState([]);
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchSubjournals = async () => {
      try {
        const intacctGLAccountsService = new PropertySubjournalTypesService();
        const response =
          await intacctGLAccountsService.getPropertySubjournalTypes(
            organizationId,
            propertyId,
            abortController.signal,
          );
        setSubjournals(response);
      } catch (err) {
        if (!pathEq(['networkError'], REQUEST_ABORTED, err)) {
          promptToaster({
            type: 'error',
            title: intl.formatMessage(messages.fetchSubjournalsError),
            message: err.toString(),
          });
        }
      }
    };

    fetchSubjournals();

    return () => abortController.abort();
  }, [intl, organizationId, propertyId, promptToaster]);

  return [subjournals];
};

export const useFetchChargeCodes = ({
  intl,
  propertyId,
  organizationId,
  selectedSubjournalId,
  promptToaster,
}: FetchChargeCodesHookProps): any => {
  const [chargeCodes, setChargeCodes] = useState([]);
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchChargeCodes = async () => {
      try {
        if (selectedSubjournalId) {
          const intacctGLAccountsService = new PropertySubjournalTypesService();
          const response =
            await intacctGLAccountsService.getPropertyChargeCodesBySubjournal(
              organizationId,
              propertyId,
              selectedSubjournalId,
              abortController.signal,
            );
          setChargeCodes(response);
        }
      } catch (err) {
        if (!pathEq(['networkError'], REQUEST_ABORTED, err))
          promptToaster({
            type: 'error',
            title: intl.formatMessage(messages.fetchChargeCodesError),
            message: err.toString(),
          });
      }
    };

    fetchChargeCodes();

    return () => abortController.abort();
  }, [intl, organizationId, propertyId, selectedSubjournalId, promptToaster]);

  return [chargeCodes];
};

export const useFetchCamAllocations = ({
  intl,
  propertyId,
  organizationId,
  camRecordId,
  promptToaster,
}: FetchCamAllocationsHookProps): any => {
  const [camAllocations, setCamAllocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isAllocationsSetUpComplete, setIsAllocationsSetUpComplete] =
    useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchData = async () => {
      if (propertyId && organizationId && camRecordId) {
        setIsLoading(true);
        const camAllocationService = new CamAllocationService();
        const response = await camAllocationService.getAll(
          organizationId,
          propertyId,
          camRecordId,
          abortController.signal,
        );
        let completeStatus = null;
        response.forEach((allocation) => {
          if (allocation.isComplete === false) {
            completeStatus = false;
          }
          if (completeStatus === null && allocation.isComplete === true) {
            completeStatus = true;
          }
        });
        setIsAllocationsSetUpComplete(completeStatus);
        setCamAllocations(response);
        setIsLoading(false);
      }
    };

    fetchData();

    return () => abortController.abort();
  }, [organizationId, propertyId, camRecordId, shouldRefresh]); // eslint-disable-line

  return [
    isLoading,
    camAllocations,
    isAllocationsSetUpComplete,
    () => setShouldRefresh(!shouldRefresh),
  ];
};

export const useModifyCamAllocation = ({
  intl,
  propertyId,
  organizationId,
  camRecordId,
  promptToaster,
  accountsForExpenseCapStartYear,
  setStartYearExpenses,
  refresh,
}: ModifyCamAllocationHookProps): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [camAllocation, setCamAllocation] = useState(null);
  const [camAllocationId, setCamAllocationId] = useState(null);

  const returnedFn = (id: string, payload: Object) => {
    setCamAllocation(payload);
    setCamAllocationId(id);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const modifyCamAllocation = async (
      modifyId: string,
      modifyPayload: Object,
    ) => {
      setIsLoading(true);
      try {
        if (
          !propertyId ||
          !organizationId ||
          !camRecordId ||
          !modifyId ||
          !modifyPayload
        ) {
          throw new Error('Could not modify CAM Allocation');
        }

        const startYearExpenses = pathOr(
          null,
          ['expenseCap', 'startYearExpenses'],
          modifyPayload,
        );
        if (!startYearExpenses) {
          // Check if we can get start year expenses from Intacct, otherwise users need to enter it manually
          const {
            fetchedStartYearExpenses,
            isStartYearExpenseActual,
            needsStartYearExpenses,
          } = await fetchStartYearExpenses({
            allocation: modifyPayload,
            accountsForExpenseCapStartYear,
            organizationId,
            propertyId,
            abortController,
          });

          setStartYearExpenses(
            fetchedStartYearExpenses,
            isStartYearExpenseActual,
          );

          if (needsStartYearExpenses) {
            setIsLoading(false);
            return;
          }
        }

        const camAllocationService = new CamAllocationService();
        await camAllocationService.modify(
          organizationId,
          propertyId,
          camRecordId,
          modifyId,
          modifyPayload,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.modifyCamAllocationSuccessTitle),
          message: intl.formatMessage(
            messages.modifyCamAllocationSuccessMessage,
          ),
        });
        setIsLoading(false);
        refresh();
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.modifyCamAllocationError),
          message: err.toString(),
        });
        setIsLoading(false);
        refresh();
      }
    };

    if (!isLoading && camAllocationId && camAllocation) {
      modifyCamAllocation(camAllocationId, camAllocation);
    }

    return () => {
      setCamAllocation(null);
      setCamAllocationId(null);
    };
    // We don't want this to be called every time dependencies change (which include the accounts for gross up)
    // so ignore eslint warnings here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organizationId,
    propertyId,
    camRecordId,
    promptToaster,
    intl,
    isLoading,
    camAllocation,
    camAllocationId,
    refresh,
  ]);

  return [isLoading, returnedFn];
};

export const useCreateCamException = ({
  intl,
  organizationId,
  propertyId,
  camRecordId,
  promptToaster,
  refresh,
  accountsForExpenseCapStartYear,
  setStartYearExpenses,
}: CreateCamExceptionHookProps): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [exceptionName, setExceptionName] = useState(null);
  const [allocation, setAllocation] = useState(null);

  const returnedFn = (newExceptionName: string, newAllocation: Object) => {
    setExceptionName(newExceptionName);
    setAllocation(newAllocation);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const createCamException = async (name: string, newAllocation: Object) => {
      setIsLoading(true);
      try {
        if (!propertyId || !organizationId || !camRecordId || !newAllocation) {
          throw new Error('Could not add CAM Custom Pool');
        }

        const startYearExpenses = pathOr(
          null,
          ['expenseCap', 'startYearExpenses'],
          newAllocation,
        );
        if (!startYearExpenses) {
          // Check if we can get start year expenses from Intacct, otherwise users need to enter it manually
          const {
            fetchedStartYearExpenses,
            isStartYearExpenseActual,
            needsStartYearExpenses,
          } = await fetchStartYearExpenses({
            allocation: newAllocation,
            accountsForExpenseCapStartYear,
            organizationId,
            propertyId,
            abortController,
          });

          setStartYearExpenses(
            fetchedStartYearExpenses,
            isStartYearExpenseActual,
          );

          if (needsStartYearExpenses) {
            setIsLoading(false);
            return;
          }
        }

        const payload = { camRecordsId: camRecordId, name };
        const camExceptionService = new CamExceptionService();
        const createdException = await camExceptionService.create(
          organizationId,
          propertyId,
          payload,
        );
        const allocationId = pathOr(
          null,
          ['allocation', 'id'],
          createdException,
        );
        if (allocationId) {
          const camAllocationService = new CamAllocationService();
          await camAllocationService.modify(
            organizationId,
            propertyId,
            camRecordId,
            allocationId,
            newAllocation,
          );
        }
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.addCamExceptionSuccessTitle),
          message: intl.formatMessage(messages.addCamExceptionSuccessMessage),
        });
        setIsLoading(false);
        refresh();
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.addCamExceptionError),
          message: err.toString(),
        });
        setIsLoading(false);
        refresh();
      }
    };
    if (!isLoading && exceptionName && allocation) {
      createCamException(exceptionName, allocation);
    }

    return () => {
      setExceptionName(null);
      setAllocation(null);
    };
    // We don't want this to be called every time dependencies change (which include the accounts for gross up)
    // so ignore eslint warnings here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organizationId,
    propertyId,
    camRecordId,
    promptToaster,
    intl,
    isLoading,
    refresh,
    exceptionName,
    allocation,
  ]);

  return [isLoading, returnedFn];
};

export const useRemoveCamException = ({
  intl,
  propertyId,
  organizationId,
  householdId,
  promptToaster,
  refreshCamAllocationTable,
  onSuccess,
}: RemoveCamExceptionHookProps): any => {
  const [camExceptionId, setCamExceptionId] = useState(null);
  const [isLoadingRemove, setIsLoadingRemove] = useState(false);
  const removeCamException = (payload: any) => setCamExceptionId(payload);
  useEffect(() => {
    // $FlowFixMe;
    const abortController = new AbortController(); // eslint-disable-line

    const removeCamException = async (deleteCamExceptionId: any) => {
      setIsLoadingRemove(true);
      try {
        if (!propertyId || !organizationId || !deleteCamExceptionId) {
          throw new Error('Could not delete CAM Exception');
        }

        const camExceptionService = new CamExceptionService();
        await camExceptionService.remove(
          organizationId,
          propertyId,
          deleteCamExceptionId,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.removeCamExceptionSuccessTitle),
          message: intl.formatMessage(
            messages.removeCamExceptionSuccessMessage,
          ),
        });
        onSuccess();
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.removeCamExceptionError),
          message: err.toString(),
        });
      } finally {
        setIsLoadingRemove(false);
        refreshCamAllocationTable();
      }
    };

    if (!isLoadingRemove && camExceptionId) {
      removeCamException(camExceptionId);
    }

    return () => {
      setCamExceptionId(null);
    };
  }, [
    organizationId,
    propertyId,
    promptToaster,
    intl,
    isLoadingRemove,
    camExceptionId,
    refreshCamAllocationTable,
    onSuccess,
  ]);
  return removeCamException;
};
