import {
  GET_FINAL_ACCOUNT_STATEMENT,
  GET_FINAL_ACCOUNT_STATEMENT_SUCCESS,
  GET_FINAL_ACCOUNT_STATEMENT_FAILURE,
  GENERATE_FINAL_ACCOUNT_STATEMENT,
} from './constants';

export function getFinalAccountStatement(payload: Object) {
  return {
    type: GET_FINAL_ACCOUNT_STATEMENT,
    payload,
  };
}

export function getFinalAccountStatementSuccess(payload: Object) {
  return {
    type: GET_FINAL_ACCOUNT_STATEMENT_SUCCESS,
    payload,
  };
}

export function getFinalAccountStatementFailure(payload: Object) {
  return {
    type: GET_FINAL_ACCOUNT_STATEMENT_FAILURE,
    error: true,
    payload,
  };
}

export function generateFinalAccountStatement(
  customerId: string,
  residentId: string,
  sendEmail: boolean,
) {
  return {
    type: GENERATE_FINAL_ACCOUNT_STATEMENT,
    payload: {
      customerId,
      residentId,
      sendEmail,
    },
  };
}
