import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { messages } from './messages';
import { GPRs } from '../constants';
import moment from 'moment';

type Props = {
  show: boolean,
  onHide: Function,
  intl: Object,
  promptToaster: Function,
  propertyId: string,
  organizationId: string,
  gpr: string,
  setGPR: Function,
  updateGPR: Function,
  propertyChangeHistoryLog: Object,
  isLoading: boolean,
};

export const GPRModal = ({
  show,
  onHide,
  intl,
  promptToaster,
  propertyId,
  organizationId,
  gpr: currentGPR,
  updateGPR,
  propertyChangeHistoryLog,
  isLoading,
}: Props) => {
  const [dirty, setDirty] = useState(false);
  const [gpr, setGPR] = useState(currentGPR);

  const updateDirtyOnChangeWrapper =
    (onChange: Function) =>
    (...args: any[]) => {
      if (!dirty) {
        setDirty(true);
      }
      onChange(...args);
    };

  useEffect(() => {
    setDirty(false);
    setGPR(currentGPR);
  }, [currentGPR, show]);

  const onSave = () => {
    updateGPR(gpr);
  };

  return (
    <Modal show={show} autoFocus onHide={onHide}>
      <Modal.Header closeButton>
        <i className="icon et-money" style={{ fontSize: 22, margin: 12 }}></i>
        <h1>{intl.formatMessage(messages.title)}</h1>
      </Modal.Header>
      <Modal.Body>
        <div className="padright30 padleft30">
          <div className="alert alert-info">
            {intl.formatMessage(messages.note)}
          </div>
          <div style={{ margin: '30px 0' }}>
            <h2>{intl.formatMessage(messages.mstType)}</h2>
            <select
              style={{ maxWidth: '50%' }}
              className="form-control"
              value={gpr}
              onChange={updateDirtyOnChangeWrapper((e) =>
                setGPR(e.target.value),
              )}
            >
              {GPRs.map((gpr) => (
                <option key={`gpr-${gpr}`} value={gpr}>
                  {gpr}
                </option>
              ))}
            </select>
            {propertyChangeHistoryLog && (
              <p>
                Last changed on{' '}
                {moment(propertyChangeHistoryLog.changeDate).format(
                  'MM-DD-YYYY',
                )}{' '}
                by {propertyChangeHistoryLog.user.firstName}{' '}
                {propertyChangeHistoryLog.user.lastName}
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col-xs-6">
            <button className="btn btn-default" onClick={onHide}>
              {intl.formatMessage(messages.cancel)}
            </button>
          </div>
          <div className="col-xs-6">
            <button
              className="btn btn-primary pull-left"
              onClick={onSave}
              disabled={!dirty || isLoading}
            >
              {intl.formatMessage(messages.save)}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
