import React from 'react';
import { Modal, Button, Row, ButtonToolbar } from 'react-bootstrap';
import type { ConfirmPriorMonthProratesModalProps } from './types';

export const ConfirmPriorMonthProratesModal = ({
  onCancel,
  onConfirm,
  show,
}: ConfirmPriorMonthProratesModalProps) => {
  return (
    <Modal
      className="text-center"
      dialogClassName="modal-dialog modal-alert"
      bsSize="small"
      backdrop
      show={show}
      onHide={onCancel}
    >
      <Modal.Body>
        <h2 className="text-center">Warning</h2>

        <Row>
          <p>This lease is retro-active to a prior month.</p>
          <p>Fortress will adjust the ledger for the current month</p>
          <p>but any adjustment to prior months will need to be manually</p>
          <p>corrected on the ledger.</p>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button type="button" bsStyle="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            bsStyle="primary"
            className="pull-left"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
};
