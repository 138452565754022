import type { Action, WorkOrder } from '../App/types';
import * as ActionTypes from './constants';
import { pathOr } from 'ramda';

export function createWorkOrder(
  workOrder: WorkOrder,
  print: boolean,
): Action<Object> {
  return {
    type: ActionTypes.CREATE_WORK_ORDER,
    payload: {
      workOrder,
      print,
    },
  };
}

export function createWorkOrderSuccess(
  detailIdReadable: string,
  createdAt: string,
): Action<any> {
  return {
    type: ActionTypes.CREATE_WORK_ORDER_SUCCESS,
    payload: {
      detailIdReadable,
      createdAt,
    },
  };
}

export function getAllUnits(): Action<any> {
  return {
    type: ActionTypes.GET_ALL_UNITS,
    payload: undefined,
  };
}

export function getAllUnitsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_UNITS_ERROR,
    payload: error,
  };
}

export function getAllUnitsSuccess(units: Array<Object>): Action<any> {
  const unitOptions = units.reduce((acc, unit) => {
    const currentAssignation = pathOr(
      [],
      ['currentAssignation', 'lease', 'resident'],
      unit,
    );
    if (currentAssignation.length === 0) {
      acc.push({
        id: unit.id,
        unitId: unit.id,
        householdId: '',
        description: `${unit.number}`,
        resident: {
          customerId: '',
          name: '',
          emailAddress: '',
          phoneNumber: '',
        },
      });
    } else {
      currentAssignation.forEach((resident) => {
        const customer = pathOr('', ['rc', 'customer'], resident);
        const customerId = pathOr('', ['id'], customer);
        const householdId = pathOr('', ['householdId'], resident);
        const isValidEmail = pathOr(null, ['isValidEmail'], customer);
        const isOptedOut = pathOr(null, ['isOptedOut'], customer);
        const firstName = pathOr(null, ['firstName'], customer);
        const lastName = pathOr(null, ['lastName'], customer);
        const name =
          firstName || lastName ? `${firstName} ${lastName}`.trim() : '';
        const emailAddress = pathOr(null, ['emailAddress'], customer);
        const phoneNumber = pathOr(null, ['phoneNumber'], customer);
        const status = pathOr(null, ['status'], customer);
        if (unit.id && name && unit.number && status === 'Current Resident') {
          acc.push({
            id: customerId,
            unitId: unit.id,
            householdId,
            description: `${unit.number} - ${name}`,
            resident: {
              customerId,
              name,
              emailAddress,
              phoneNumber,
              householdId,
              isValidEmail,
              isOptedOut,
            },
          });
        }
      });
    }
    return acc;
  }, []);
  return {
    type: ActionTypes.GET_ALL_UNITS_SUCCESS,
    payload: unitOptions,
  };
}

export function getWorkOrder(workOrderId: any): Action<any> {
  return {
    type: ActionTypes.GET_WORK_ORDER,
    payload: workOrderId,
  };
}

export function getWorkOrderSuccess(workOrder: WorkOrder): Action<any> {
  return {
    type: ActionTypes.GET_WORK_ORDER_SUCCESS,
    payload: workOrder,
  };
}

export function getWorkOrderError(error: any): Action<any> {
  return {
    type: ActionTypes.GET_WORK_ORDER_ERROR,
    payload: error,
  };
}

export function saveWorkOrder(
  workOrder: WorkOrder,
  print: boolean,
): Action<Object> {
  return {
    type: ActionTypes.SAVE_WORK_ORDER,
    payload: {
      workOrder,
      print,
    },
  };
}

export function saveWorkOrderSuccess(): Action<any> {
  return {
    type: ActionTypes.SAVE_WORK_ORDER_SUCCESS,
    payload: undefined,
  };
}

export function clearLoadedWorkOrder(): Action<any> {
  return {
    type: ActionTypes.CLEAR_LOADED_WORK_ORDER,
    payload: undefined,
  };
}
