import { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import useSelectedProperty from '../../../../../hooks/useSelectedProperty';
import { get } from '../../../../../utils/api';

export function useUnlockedLeasePacketSection({
  intl,
  leaseId,
  isLeaseLocked = false,
  updateAddendums,
  addendums: globalAddendums,
  isCommercial,
  isAffordable,
}) {
  const selectedProperty = useSelectedProperty();
  const { data: leaseDocResponse, isLoading: isLeaseDocLoading } = useQuery(
    ['leaseDoc', selectedProperty.organizationId, selectedProperty.id],
    () =>
      get(
        `/${selectedProperty.organizationId}/centralized-documents/leases/${selectedProperty.id}`,
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const unitType = isAffordable
    ? 'Affordable'
    : isCommercial
    ? 'Commercial'
    : 'Conventional';

  const {
    addendums,
    onAddendumChange,
    isLoading: isAddendumsLoading,
  } = useAddendumsForUnlockedLease({
    organizationId: selectedProperty.organizationId,
    propertyId: selectedProperty.id,
    addendums: globalAddendums,
    updateAddendums,
    leaseId,
    unitType,
  });

  const isLoading = isLeaseDocLoading || isAddendumsLoading;

  return {
    intl,
    isLeaseLocked,
    addendums,
    isLoading,
    onAddendumChange,
    leaseDocName: leaseDocResponse?.name ?? 'Lease',
  };
}

function useAddendumsForUnlockedLease({
  organizationId,
  propertyId,
  addendums,
  updateAddendums,
  leaseId,
  unitType,
}) {
  const addendumsQuery = useQuery(
    ['addendums', organizationId, propertyId],
    () =>
      get(
        `/${organizationId}/centralized-documents/lease-addendums/property/${propertyId}?unitType=${unitType}`,
      ),
    {
      refetchOnWindowFocus: false,
    },
  );
  const selectedAddendumsQuery = useQuery(
    ['selectedAddendums', organizationId, propertyId, leaseId],
    () =>
      get(
        `/${organizationId}/centralized-documents/lease-addendums/lease/${leaseId}`,
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!leaseId,
    },
  );
  const isLoading =
    addendumsQuery.isLoading || (leaseId && selectedAddendumsQuery.isLoading);

  useEffect(() => {
    if (isLoading) return;

    updateAddendums(
      addendumsQuery.data?.map((document) => ({
        id: document.leaseAddendum.id,
        name: document.name,
        isRequired: document.leaseAddendum.isRequired,
        isSelected:
          selectedAddendumsQuery.data?.find(
            (selectedAddendumDoc) =>
              selectedAddendumDoc.leaseAddendum.id ===
              document.leaseAddendum.id,
          ) !== undefined,
      })),
    );
  }, [
    addendumsQuery.data,
    selectedAddendumsQuery.data,
    isLoading,
    updateAddendums,
  ]);

  const onAddendumChange = useCallback(
    (id) => {
      updateAddendums(
        addendums.map((a) => {
          if (a.id === id)
            return {
              ...a,
              isSelected: !!!a.isSelected,
            };
          return a;
        }),
      );
    },
    [addendums, updateAddendums],
  );

  return {
    addendums,
    onAddendumChange,
    isLoading,
  };
}

export function useLockedLeasePacketSection({
  intl,
  leaseId,
  isLeaseLocked = false,
}) {
  const selectedProperty = useSelectedProperty();
  const { data: leasePacketSnapshot, isLoading: isSnapshotLoading } = useQuery(
    [
      'leasePacketSnapshot',
      selectedProperty.organizationId,
      selectedProperty.id,
    ],
    () =>
      get(
        `/${selectedProperty.organizationId}/${selectedProperty.id}/leasePacketSnapshots/${leaseId}`,
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!leaseId,
    },
  );

  const isLoading = isSnapshotLoading;

  const addendums =
    leasePacketSnapshot?.leaseAddendumDocs?.map((addendumDoc) => ({
      id: addendumDoc.key,
      name: addendumDoc.name,
      isRequired: addendumDoc.isRequired,
      isSelected: addendumDoc.isSelected,
    })) ?? [];

  return {
    intl,
    isLeaseLocked,
    addendums,
    isLoading,
    onAddendumChange: () => {},
    leaseDocName: leasePacketSnapshot?.leaseDoc?.name ?? 'Lease',
  };
}
