import { useComplianceApprovalStatuses } from '../../../hooks/data-fetching/useComplianceApprovalStatuses';

export const useLocalization = (organizationId?: string) => {
  const [complianceApprovalStatuses] =
    useComplianceApprovalStatuses(organizationId);

  return complianceApprovalStatuses
    .filter((approvalStatus) => approvalStatus.id && approvalStatus.name)
    .reduce((finalObject, approvalStatus) => {
      const { id: key, name: value } = approvalStatus;
      return {
        ...finalObject,
        [key]: value,
      };
    }, {});
};
