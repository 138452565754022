import React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';

import { renderRadioGroupField } from '../../../utils/redux-form-helper';
import messages from './messages';

type Props = { intl: Object };

const AdultSection = ({ intl }) => (
  <Row>
    <Col xs={12} md={2}>
      <h3 className="required">
        {intl.formatMessage(messages.adultRadioButtonLabel)}
      </h3>
    </Col>
    <Col xs={12} md={2}>
      <Field
        name="anyAdult"
        fieldName="adults"
        component={renderRadioGroupField}
        options={[
          { text: 'Yes', value: 'yes', disabled: false },
          { text: 'No', value: 'no', disabled: false }
        ]}
        inline
        className="form-options"
      />
    </Col>
  </Row>
);

const MinorSection = ({ intl }) => (
  <Row>
    <Col xs={12} md={2}>
      <h3 className="required">
        {intl.formatMessage(messages.minorRadioButtonLabel)}
      </h3>
    </Col>
    <Col xs={12} md={2}>
      <Field
        name="anyMinor"
        fieldName="minors"
        component={renderRadioGroupField}
        options={[
          { text: 'Yes', value: 'yes', disabled: false },
          { text: 'No', value: 'no', disabled: false }
        ]}
        inline
        className="form-options"
      />
    </Col>
  </Row>
);

const AdditionalOccupantSection = ({ intl }: Props) => (
  <div className="form-block">
    <div className="form-content">
      <div className="form-block">
        <AdultSection intl={intl} />
        <Row>
          <div className="alert alert-primary">
            {intl.formatMessage(messages.note)}
          </div>
        </Row>
        <MinorSection intl={intl} />
      </div>
    </div>
  </div>
);

export default AdditionalOccupantSection;
