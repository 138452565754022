export const dropdownValues = [
  {
    value: 'qualifications',
    text: 'Qualifications/ Personal data',
    disabled: false,
  },
  {
    value: 'assets',
    text: 'Assets',
    disabled: false,
  },
  {
    value: 'income',
    text: 'Incomes',
    disabled: false,
  },
  {
    value: 'documents',
    text: 'Documents',
    disabled: false,
  },
  {
    value: 'mincbaseline',
    text: 'MINC Baseline',
    disabled: false,
    rd: true,
  },
  {
    value: 'matbaseline-noprior',
    text: 'MAT Baseline (Skip households with MO certs)',
    disabled: false,
    hud: true,
  },
  {
    value: 'matbaseline',
    text: 'MAT Baseline',
    disabled: false,
    hud: true,
  },
];
