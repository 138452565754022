// $FlowFixMe
import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ConfirmOverlay from '../../../../../../components/ConfirmOverlay';
import SubmitButton from '../../../../../../components/SubmitButton';

import { executeLease } from '../../../../actions';

import messages from './messages';

const SignatureBodyWrapper = styled.div`
  height: 100%;
  margin: 20px;
`;

const SignatureWrapper = styled.div`
  margin: auto auto 30px auto;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  position: relative;
  height: 100px;
  canvas {
    height: 100%;
    width: 100%;
  }
`;

const ClearButton = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  color: red;
  margin: 3px;
  // text-decoration: none;
  :hover {
    text-decoration: none;
  }
`;

type ExecuteSignatureModalProps = {
  actions: Object,
  applicationId: string,
  isResident: boolean,
  leaseId: string,
  onHide: Function,
  profileId: string,
  prospectId: string,
  show: boolean,
};

const UploadLeaseModal = ({
  actions,
  applicationId,
  isResident,
  leaseId,
  onHide,
  profileId,
  prospectId,
  show,
}: ExecuteSignatureModalProps) => {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [signatureDataUrl, setSignatureDataUrl] = useState('');
  const canvasRef = useRef();

  const clearHandler = () => {
    setSignatureDataUrl('');
    canvasRef.current.clear();
  };

  const setSignatureOnForm = () => {
    if (!canvasRef.current.isEmpty()) {
      setSignatureDataUrl(canvasRef.current.toDataURL('image/png'));
    }
  };

  const hideCancelConfirmation = () => setShowCancelConfirmation(false);

  const handleHide = () => {
    if (!showCancelConfirmation) {
      setShowCancelConfirmation(true);
      return;
    }
    setShowCancelConfirmation(false);
    setIsSubmitting(false);
    if (onHide) onHide();
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    actions.executeLease(
      applicationId,
      leaseId,
      profileId,
      isResident,
      prospectId,
      signatureDataUrl,
      onHide,
    );
  };

  return (
    <Modal show={show} onHide={handleHide} autoFocus>
      <Modal.Header closeButton>
        <i className="icon et-checklist" />
        <h1 className="text-center">
          <FormattedMessage {...messages.signAndCompleteHeader} />
        </h1>
      </Modal.Header>
      <Modal.Body>
        {showCancelConfirmation && (
          <ConfirmOverlay
            title={<FormattedMessage {...messages.confirmationTitle} />}
            rejectMessage={
              <FormattedMessage {...messages.confirmationCancel} />
            }
            confirmMessage={<FormattedMessage {...messages.confirm} />}
            onReject={hideCancelConfirmation}
            onConfirm={handleHide}
          />
        )}
        <SignatureBodyWrapper>
          <h4>
            <FormattedMessage {...messages.yourSignature} />
          </h4>
          <SignatureWrapper>
            <SignatureCanvas
              ref={canvasRef}
              canvasProps={{
                onMouseUp: setSignatureOnForm,
                onTouchEnd: setSignatureOnForm,
              }}
            />
            <ClearButton type="button" onClick={clearHandler}>
              <i className="icon et-delete-3" />
              <FormattedMessage {...messages.clear} />
            </ClearButton>
          </SignatureWrapper>
          <input
            type="hidden"
            name="signatureDataUrl"
            value={signatureDataUrl}
          />
        </SignatureBodyWrapper>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col-xs-6">
            <button
              className="btn btn-default pull-right"
              onClick={handleHide}
              disabled={showCancelConfirmation}
            >
              <FormattedMessage {...messages.cancel} />
            </button>
          </div>
          <div className="col-xs-6">
            <SubmitButton
              classes="btn btn-primary pull-left"
              clickHandler={handleSubmit}
              disabled={!signatureDataUrl || showCancelConfirmation}
              isSubmitting={isSubmitting}
            >
              <FormattedMessage {...messages.signAndExecute} />
            </SubmitButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Object): Object => ({
  actions: bindActionCreators({ executeLease }, dispatch),
});

export default connect(null, mapDispatchToProps)(UploadLeaseModal);
