import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox, Button } from 'react-bootstrap';
import styled from 'styled-components';

import messages from './messages';
import Divider from '../Common/Divider';

const Down = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid gray;
  font-size: 0;
  line-height: 0;
  float: right;
  margin: 9px 0 0 5px;
`;

const Up = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid gray;
  font-size: 0;
  line-height: 0;
  float: right;
  margin: 9px 0 0 5px;
`;

const List = styled.div`
  position: absolute;
  z-index: 10;
  padding: 0 10px 10px 10px;
  min-width: 250px;
  right: 0px;
  top: 50px;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
`;

export const ShowHideColumnsRentRoll = ({
  setShowHideColumns,
  isLoading,
  isAffordable,
  showHideColumnsList,
}) => {
  const optionalColumnsList = useMemo(
    () =>
      isAffordable
        ? showHideColumnsList
        : showHideColumnsList.filter(
            (listItem) => listItem?.propertyType !== 'affordable',
          ),
    [showHideColumnsList, isAffordable],
  );

  const updateShowHideColumns = useCallback(
    (column) => {
      const showHideColumns = showHideColumnsList.map((obj) => {
        if (obj.id === column.id) {
          obj.selected = !obj.selected;
        }
        return obj;
      });
      setShowHideColumns(showHideColumns);
    },
    [setShowHideColumns, showHideColumnsList],
  );

  const [isListOpen, setIsListOpen] = useState(false);
  const handleClearAll = () => {
    const columnList = optionalColumnsList.map((obj) => ({
      ...obj,
      selected: false,
    }));
    localStorage.setItem(
      'show-hide-columns-rent-roll',
      JSON.stringify(columnList),
    );
    setShowHideColumns(columnList);
  };

  const close = (e) => {
    if (
      document
        ?.getElementById('multiSelectCheckboxDropdown')
        ?.contains(e.target)
    ) {
      return;
    }
    setIsListOpen(false);
  };
  useEffect(() => {
    isListOpen
      ? document.addEventListener('click', close)
      : document.removeEventListener('click', close);
  }, [isListOpen]);

  return (
    <div className=" float-right" id="multiSelectCheckboxDropdown">
      <Button
        disabled={isLoading}
        onClick={() => {
          setIsListOpen(!isListOpen);
        }}
      >
        <FormattedMessage {...messages.showHideColumns} />
        {isListOpen ? <Up /> : <Down />}
      </Button>
      {isListOpen && (
        <List>
          {optionalColumnsList?.map((column, i) => {
            return (
              <div key={i}>
                <Checkbox
                  className="custom-checkbox padleft20"
                  data-test="prorate-checkbox"
                  checked={column.selected}
                  value={column.id}
                  onChange={() => {
                    updateShowHideColumns(column);
                  }}
                >
                  <input type="checkbox" />
                  <span className="custom-check-square center-block" />
                  {column.text}
                </Checkbox>
              </div>
            );
          })}
          <Divider />
          <div className="text-center">
            <a onClick={handleClearAll}>
              <FormattedMessage {...messages.clear} />
            </a>
          </div>
        </List>
      )}
    </div>
  );
};

export default ShowHideColumnsRentRoll;
