import type { Action } from '../App/types';
import type { ApplicationProfileState } from './types';
import * as ActionTypes from './constants';
import * as ProspectActionTypes from '../PeopleProfile/constants';
import { omit, clone } from 'ramda';

const initialState = {
  affordableQualifyingChecklist: [],
  currentApplicationId: '',
  currentApplication: undefined,
  applicationDecisionStatuses: [],
  applicationStatuses: [],
  pendingActivities: [],
  completedActivities: [],
  completedActivitiesMeta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  allActivities: [],
  allActivitiesMeta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  columnOrder: {
    status: 'sortable',
    type: 'sortable',
    startTime: 'sortable',
  },
  screening: {
    open: false,
    submitting: false,
    results: {},
    error: null,
  },
  assignedUnits: [],
  labelEndDate: null,
  monthlyTransactions: [],
  selectedMonthlyOption: 'current',
  openFiscalPeriod: {},
  deletedApplicants: [],
  affordableQualifications: [],
  propertyClassId: '',
  fpNonOptionalCharges: [],
};

const applicationProfileReducer = (
  state: ApplicationProfileState = initialState,
  action: Action<*, *>,
): ApplicationProfileState => {
  const { type, payload, meta } = action;
  const defaultColumnOrder = { startTime: 'sortable' };
  switch (type) {
    case ActionTypes.GET_ONE_APPLICATION_SUCCESS: {
      const currentApplication = omit(['accountFinalized'], payload);
      return {
        ...state,
        currentApplication,
        currentApplicationId: payload.id,
        openFiscalPeriod: payload.openFiscalPeriod,
        deletedApplicants: payload.deletedApplicants,
        affordableQualifications: payload.affordableQualifications,
        affordableQualificationHistories:
          payload.affordableQualificationHistories,
        fpNonOptionalCharges: payload.floorPlanNonOptionalCharges,
      };
    }
    case ActionTypes.GET_ONE_APPLICATION_ERROR: {
      return { ...state, currentApplication: initialState.currentApplication };
    }
    case ActionTypes.GET_APPLICATION_DECISION_STATUS_SUCCESS: {
      return { ...state, applicationDecisionStatuses: payload };
    }
    case ProspectActionTypes.GET_PROSPECT_PENDING_ACTIVITIES_SUCCESS: {
      return { ...state, pendingActivities: payload };
    }
    case ProspectActionTypes.GET_PROSPECT_COMPLETED_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        completedActivities: payload,
        completedActivitiesMeta: meta,
      };
    }
    case ProspectActionTypes.GET_PROSPECT_ALL_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        allActivities: payload,
        allActivitiesMeta: meta,
      };
    }
    case ProspectActionTypes.UPDATE_COLUMNS_SORT_VALUE: {
      return {
        ...state,
        columnOrder: Object.assign({}, defaultColumnOrder, payload),
      };
    }
    case ProspectActionTypes.UPDATE_PROSPECT: {
      if (!state.currentApplication) {
        return state;
      }
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          prospectInfo: {
            ...state.currentApplication.prospectInfo,
            contactTypeId: payload.prospect.contactTypeId,
            referralTypeId: payload.prospect.referralTypeId,
            prospectPreferences: payload.prospect.prospectPreferences,
          },
        },
      };
    }
    case ActionTypes.UPDATE_LABEL_END_DATE: {
      if (!state.currentApplication) {
        return state;
      }
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          labelEndDate: payload,
        },
      };
    }
    case ActionTypes.GET_APPLICATION_STATUS_SUCCESS: {
      return { ...state, applicationStatuses: payload };
    }
    case ActionTypes.GET_SCREENING_STATUS:
    case ActionTypes.SUBMIT_SCREENING_APPLICATION: {
      return {
        ...state,
        screening: {
          ...state.screening,
          submitting: true,
          error: null,
        },
      };
    }
    case ActionTypes.GET_SCREENING_STATUS_SUCCESS: {
      return {
        ...state,
        screening: {
          ...state.screening,
          submitting: false,
          results: payload,
        },
      };
    }
    case ActionTypes.GET_SCREENING_STATUS_ERROR: {
      return {
        ...state,
        screening: {
          ...state.screening,
          submitting: false,
          error: payload.error,
          results: payload.message,
          message: payload.message,
        },
      };
    }
    case ActionTypes.GET_ASSIGNED_UNITS_SUCCESS: {
      return { ...state, assignedUnits: payload };
    }
    case ActionTypes.CLEAN_LOADED_APPLICATION: {
      return { ...initialState };
    }
    case ActionTypes.GET_MONTHLY_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        monthlyTransactions: payload,
      };
    }
    case ActionTypes.SET_MONTHLY_OPTION: {
      return {
        ...state,
        selectedMonthlyOption: payload,
      };
    }
    case ActionTypes.UPDATE_COMMERCIAL_APPLICATION_SUCCESS: {
      const currentApplication = omit(['accountFinalized'], payload);
      return {
        ...state,
        currentApplication,
      };
    }
    case ActionTypes.UPDATE_APPLICANT_SUCCESS: {
      const updates = payload?.updates ?? {};
      const updatedApplicants = clone(state.currentApplication.applicants);
      const updateApplicantIndex = updatedApplicants.findIndex(
        (app) => app.id === payload.applicantId,
      );
      if (updateApplicantIndex !== -1) {
        updatedApplicants[updateApplicantIndex] = {
          ...updatedApplicants[updateApplicantIndex],
          ...updates,
        };
      }
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          applicants: updatedApplicants,
        },
      };
    }
    default:
      return state;
  }
};

export default applicationProfileReducer;
