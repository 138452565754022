import React, { Component } from 'react';
import { Form, Field, reduxForm } from 'redux-form';
import {
  FormattedMessage,
  injectIntl,
  type InjectIntlProvidedProps,
} from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import messages from '../messages';
import {
  FORM_NAME,
  DOCUMENT_NUMBER_MAX_LENGTH,
  NOTE_MAX_LENGTH,
} from '../constants';
import { TwoColumnText } from '../../../../components/TwoColumnFields/index.js';
import {
  renderCurrencyField,
  renderTextFieldTwoColumns,
  renderTextAreaTwoColumns,
  renderSelectFieldTwoColumns,
} from '../../../../utils/redux-form-helper';
import {
  validateMaxLength,
  validateDollarAmount,
} from '../../../../utils/redux-form/field-validations';
import validate from './validate';
import { pathOr } from 'ramda';

type OptionType = { value: ?string, text: string };

type DefaultProps = {
  onSubmit: Function,
  defaultOption: OptionType,
};

type ReduxFormProps = {
  form: string,
  handleSubmit: Function,
  initialValues: Object,
};

type Props = InjectIntlProvidedProps &
  DefaultProps &
  ReduxFormProps & {
    propertyName: string,
    unitId: ?string,
    status: string,
    priorBalance: number,
    chargeCodes: Array<Object>,
    paymentCodes: Array<Object>,
  };

type State = {
  amount: number,
  transactionTypeId: string,
  transactionCodeId: string,
  msubtakebackSelected: boolean,
};

export const maxLengthDocumentWarn = (value: ?string) =>
  validateMaxLength(DOCUMENT_NUMBER_MAX_LENGTH)(value);

export const maxLengthNoteWarn = (value: ?string) =>
  validateMaxLength(NOTE_MAX_LENGTH)(value);

export class CreateTransactionForm extends Component<Props, State> {
  static defaultProps = {
    onSubmit: () => {},
    defaultOption: { value: 'default', text: 'Choose' },
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      amount: 0,
      transactionTypeId: 'default',
      transactionCodeId: 'default',
      msubtakebackSelected: false,
    };
  }

  handleTransactionTypeChange = (evt: Object) => {
    const selected = evt.target.value;

    const selectedValue = this.props.chargeCodes.find(
      (code) => code.value === selected,
    );

    if (pathOr('', ['text'], selectedValue).includes('MSUBTAKEBACK')) {
      this.setState({
        transactionTypeId: selected,
        msubtakebackSelected: true,
      });
    } else
      this.setState({
        transactionTypeId: selected,
        msubtakebackSelected: false,
      });
  };

  handleTransactionCodeChange = (evt: Object) => {
    this.setState({ transactionCodeId: evt.target.value });
  };

  handleAmountChange = (evt: Object) => {
    const amount = parseFloat(evt.target.value);
    Number.isNaN(amount)
      ? this.setState({ amount: 0 })
      : this.setState({ amount });
  };

  handleSubmit = () => {
    const { onSubmit } = this.props;
    onSubmit();
  };

  requiredForPayment = (value: string) => {
    return validateDollarAmount(value);
  };

  render() {
    const {
      intl: { formatDate },
      chargeCodes,
      paymentCodes,
      handleSubmit,
      defaultOption,
      onSubmit,
    } = this.props;

    const chargeCodesDropDownOptions = chargeCodes
      ? [defaultOption, ...chargeCodes]
      : [defaultOption];

    let paymentCodesDropDownOptions = paymentCodes
      ? [defaultOption, ...paymentCodes]
      : [defaultOption];

    if (this.state.msubtakebackSelected) {
      paymentCodesDropDownOptions = [
        defaultOption,
        ...paymentCodes.filter((pmtCode) => pmtCode.text.includes('REVPMT')),
      ];
    } else {
      paymentCodesDropDownOptions = paymentCodesDropDownOptions.filter(
        (pmtCode) => !pmtCode.text.includes('REVPMT'),
      );
    }

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TwoColumnText label={<FormattedMessage {...messages.date} />}>
          {formatDate(Date.now(), {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })}
        </TwoColumnText>
        <Field
          name="propertyTransactionCodeChargeId"
          component={renderSelectFieldTwoColumns}
          col1={[4, 12]}
          col2={[8, 12]}
          label={<FormattedMessage {...messages.chargeTransactionCode} />}
          options={chargeCodesDropDownOptions}
          onChange={this.handleTransactionTypeChange}
        />
        <Field
          name="propertyTransactionCodePaymentId"
          component={renderSelectFieldTwoColumns}
          col1={[4, 12]}
          col2={[8, 12]}
          label={<FormattedMessage {...messages.paymentTransactionCode} />}
          options={paymentCodesDropDownOptions}
          onChange={this.handleTransactionCodeChange}
        />
        <Field
          name="documentNumber"
          component={renderTextFieldTwoColumns}
          col1={[4, 12]}
          col2={[8, 12]}
          label={<FormattedMessage {...messages.documentNumber} />}
          autoComplete="off"
          maxLength={DOCUMENT_NUMBER_MAX_LENGTH}
          warn={[maxLengthDocumentWarn]}
        />
        <Field
          name="note"
          component={renderTextAreaTwoColumns}
          rows="3"
          col1={[4, 12]}
          col2={[8, 12]}
          label={<FormattedMessage {...messages.note} />}
          autoComplete="off"
          maxLength={NOTE_MAX_LENGTH}
          warn={[maxLengthNoteWarn]}
        />
        <Row>
          <Col md={4} xs={12}>
            <label>
              <FormattedMessage {...messages.amount} />
            </label>
          </Col>
          <Col md={8} xs={12}>
            <Field
              name="amount"
              component={renderCurrencyField}
              className="text-right"
              step="0.01"
              onChange={this.handleAmountChange}
              validate={[this.requiredForPayment]}
            />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default injectIntl(
  reduxForm({
    form: FORM_NAME,
    initialValues: {
      transactionType: 'default',
      receivedFrom: 'default',
      amount: '0.00',
    },
    touchOnChange: true,
    asyncChangeFields: ['documentNumber', 'note'],
    validate,
  })(CreateTransactionForm),
);
