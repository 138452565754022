import { pathOr } from 'ramda';

import type { Action } from '../App/types';
import type { RenewalOfferState } from './types';
import {
  GET_RENEWAL_OFFER_TERMS_SUCCESS,
  GET_PREVIOUS_RENEWAL_OFFERS_SUCCESS,
  CREATE_RENEWAL_OFFER_ERROR,
  GET_PREVIOUS_RENEWAL_OFFERS_ERROR,
  CLEAR_RENEWAL_OFFER_TERMS,
} from './constants';
import { GET_ONE_RESIDENT_SUCCESS } from '../ResidentProfile/constants';
import { SELECT_LEASE_FOR_RENEWAL } from '../ManageLeaseExpirations/constants';

export const initialState: RenewalOfferState = {
  leaseTermOptions: [],
  residentName: '',
  unit: {
    id: '',
    number: '',
  },
  leaseId: '',
  previousOffers: [],
};

export const parseOffer = (offer: Object) => {
  const {
    offer1Rent,
    offer2Rent,
    offer3Rent,
    offer1Term,
    offer2Term,
    offer3Term,
    offer1TermId,
    offer2TermId,
    offer3TermId,
    offerDate,
    offerExpirationDate,
    leaseOfferTransactions,
  } = offer;

  const lotx = leaseOfferTransactions?.map((lot) => ({
    transactionCode: lot.transactionCode,
    offer1amount: lot.offer1amount === 0 ? '---' : lot.offer1amount,
    offer2amount: lot.offer2amount === 0 ? '---' : lot.offer2amount,
    offer3amount: lot.offer3amount === 0 ? '---' : lot.offer3amount,
  }));
  const offer1Length = pathOr(
    '---',
    ['masterLeaseTerm', 'nMonths'],
    offer1Term,
  );
  const offer2Length = pathOr(
    '---',
    ['masterLeaseTerm', 'nMonths'],
    offer2Term,
  );
  const offer3Length = pathOr(
    '---',
    ['masterLeaseTerm', 'nMonths'],
    offer3Term,
  );
  return {
    offerDate,
    offerExpirationDate,
    offer1Rent,
    offer1TermId,
    offer2Rent: offer2Rent || '---',
    offer3Rent: offer3Rent || '---',
    offer1TermLength:
      offer1Length === '---' ? offer1Length : `${offer1Length} Months`,
    offer2TermLength:
      offer2Length === '---' ? offer2Length : `${offer2Length} Months`,
    offer3TermLength:
      offer3Length === '---' ? offer3Length : `${offer3Length} Months`,
    offer2TermId: offer2TermId || '---',
    offer3TermId: offer3TermId || '---',
    leaseOfferTransactions: lotx,
  };
};

const renewalOfferReducer = (
  state: RenewalOfferState = initialState,
  action: Action<*, *>,
): RenewalOfferState => {
  const { type, payload } = action;
  switch (type) {
    case GET_ONE_RESIDENT_SUCCESS: {
      const {
        residentCustomer: {
          customer: { firstName, lastName },
        },
        lease: { units },
        leaseId,
      } = payload.residentInfo;
      const unit = units.length === 1 ? units[0] : initialState.unit;
      return {
        ...state,
        leaseId,
        unit,
        residentName: `${firstName} ${lastName}`,
      };
    }
    case SELECT_LEASE_FOR_RENEWAL: {
      const { unit, residentName, leaseId } = payload;
      return { ...state, unit, residentName, leaseId };
    }
    case GET_RENEWAL_OFFER_TERMS_SUCCESS: {
      return { ...state, leaseTermOptions: payload };
    }
    case GET_PREVIOUS_RENEWAL_OFFERS_SUCCESS: {
      const previousOffers = payload
        .reduce((prevOffers, offer) => {
          if (!offer.offer1Rent || !offer.offer1Term || !offer.offer1TermId) {
            return prevOffers;
          }
          const prevOffer = parseOffer(offer);
          return [...prevOffers, prevOffer];
        }, [])
        .reverse();
      return {
        ...state,
        previousOffers,
      };
    }
    case CREATE_RENEWAL_OFFER_ERROR:
    case GET_PREVIOUS_RENEWAL_OFFERS_ERROR: {
      return {
        ...state,
        error: payload,
      };
    }
    case CLEAR_RENEWAL_OFFER_TERMS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default renewalOfferReducer;
