import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getSortFn } from '@fortress-technology-solutions/fortress-component-library/utils/index';
import type {
  UseFetchAllPropertyRentableItems,
  UseFetchAllPropertyFeesWithRentableItems,
  UseFetchAllHouseholdRentableItems,
  UseDeleteHouseholdRentableItem,
  UseCreateHouseholdRentableItem,
  UseUpdateHouseholdRentableItem,
  HouseholdRentableItem,
  PropertyRentableItem,
  PropertyFeesWithRentableItems,
  Query,
  Mutation,
} from './types';
import { UseFetchHouseholdRentableItem } from './types';

export const useFetchAllPropertyRentableItems = ({
  propertyId,
  organizationId,
  rentableItemsService,
}: UseFetchAllPropertyRentableItems): Query<PropertyRentableItem> => {
  const abortController = new AbortController();
  const options = { signal: abortController.signal };
  const queryKey = ['getAllPropertyRentableItems', propertyId];

  const { data, error, isLoading, refetch } = useQuery(
    queryKey,
    () =>
      rentableItemsService.getAllPropertyRentableItems(
        organizationId,
        propertyId,
        options,
      ),
    {
      enabled: Boolean(propertyId?.length && organizationId?.length),
      refetchOnWindowFocus: false,
    },
  );

  const { dataValue, hasData } = useMemo(() => {
    return {
      hasData: isLoading === false && Object.keys(data ?? {})?.length > 0,
      dataValue: data ?? {},
    };
  }, [data, isLoading]);

  return { data: dataValue, error, isLoading, hasData, refetch };
};

export const useFetchAllPropertyFeesWithRentableItems = ({
  propertyId,
  organizationId,
  rentableItemsService,
}: UseFetchAllPropertyFeesWithRentableItems): Query<PropertyFeesWithRentableItems> => {
  const abortController = new AbortController();
  const options = { signal: abortController.signal };
  const queryKey = ['getAllPropertyFeesWithRentableItems', propertyId];

  const { data, error, isLoading, refetch } = useQuery(
    queryKey,
    () =>
      rentableItemsService.getAllPropertyFeesWithRentableItems(
        organizationId,
        propertyId,
        options,
      ),
    {
      enabled: Boolean(propertyId?.length && organizationId?.length),
      refetchOnWindowFocus: false,
    },
  );

  const { dataValue, hasData } = useMemo(() => {
    return {
      hasData: isLoading === false && data?.length > 0,
      dataValue: data ?? [],
    };
  }, [data, isLoading]);

  return { data: dataValue, error, isLoading, hasData, refetch };
};

export const useFetchAllHouseholdRentableItems = ({
  propertyId,
  organizationId,
  householdId,
  rentableItemsService,
}: UseFetchAllHouseholdRentableItems): Query<HouseholdRentableItem> => {
  const abortController = new AbortController();
  const options = { signal: abortController.signal };
  const queryKey = ['getAllHouseholdRentableItems', propertyId, householdId];

  const { data, error, isLoading, refetch } = useQuery(
    queryKey,
    () =>
      rentableItemsService.getAllHouseholdRentableItems(
        organizationId,
        propertyId,
        householdId,
        options,
      ),
    {
      enabled: Boolean(
        propertyId?.length && organizationId?.length && householdId?.length,
      ),
    },
  );

  const { dataValue, hasData } = useMemo(() => {
    return {
      hasData: isLoading === false && data?.length > 0,
      dataValue: data?.sort(getSortFn('startDate', 'ASC').date) ?? [],
    };
  }, [data, isLoading]);

  return { data: dataValue, error, isLoading, hasData, refetch };
};

export const useDeleteHouseholdRentableItem = ({
  propertyId,
  organizationId,
  promptToaster,
  rentableItemsService,
}: UseDeleteHouseholdRentableItem): Mutation => {
  const abortController = new AbortController();
  const options = { signal: abortController.signal };
  const queryKey = ['deleteHouseholdRentableItem', propertyId];

  const { mutate, mutateAsync, isLoading } = useMutation(
    queryKey,
    (householdRentableItemId: string) =>
      rentableItemsService.deleteHouseholdRentableItem(
        organizationId,
        propertyId,
        householdRentableItemId,
        options,
      ),
    {
      onSuccess: () => {
        promptToaster({
          type: 'success',
          title: 'Deleted',
          message: 'Rentable item',
        });
      },
      onError: (error) => {
        promptToaster({
          type: 'error',
          title: 'Error deleting Rentable item',
          message: error,
        });
      },
      enabled: Boolean(propertyId?.length && organizationId?.length),
    },
  );

  return { mutate, mutateAsync, isLoading };
};

export const useCreateHouseholdRentableItem = ({
  propertyId,
  organizationId,
  promptToaster,
  rentableItemsService,
}: UseCreateHouseholdRentableItem): Mutation => {
  const abortController = new AbortController();
  const options = { signal: abortController.signal };
  const queryKey = ['createHouseholdRentableItem', propertyId];

  const { mutate, mutateAsync, isLoading } = useMutation(
    queryKey,
    (payload: HouseholdRentableItem) =>
      rentableItemsService.createHouseholdRentableItem(
        organizationId,
        propertyId,
        JSON.stringify(payload),
        options,
      ),
    {
      onSuccess: () => {
        promptToaster({
          type: 'success',
          title: 'Added',
          message: 'Rentable item',
        });
      },
      onError: (error) => {
        promptToaster({
          type: 'error',
          title: 'Error adding Rentable item',
          message: error,
        });
      },
      enabled: Boolean(propertyId?.length && organizationId?.length),
    },
  );

  return { mutate, mutateAsync, isLoading };
};

export const useFetchHouseholdRentableItem = ({
  id,
  propertyId,
  organizationId,
  rentableItemsService,
}: UseFetchHouseholdRentableItem): Query<HouseholdRentableItem> => {
  const abortController = new AbortController();
  const options = { signal: abortController.signal };
  const queryKey = ['getHouseholdRentableItem', propertyId, id];

  const { data, error, isLoading, refetch } = useQuery(
    queryKey,
    () =>
      rentableItemsService.getHouseholdRentableItem(
        organizationId,
        propertyId,
        id,
        options,
      ),
    {
      enabled: Boolean(
        propertyId?.length && organizationId?.length && id?.length,
      ),
    },
  );

  const { dataValue, hasData } = useMemo(() => {
    return {
      hasData: isLoading === false && Object.keys(data ?? {})?.length > 0,
      dataValue: data ?? {},
    };
  }, [data, isLoading]);

  return { data: dataValue, error, isLoading, hasData, refetch };
};

export const useUpdateHouseholdRentableItem = ({
  propertyId,
  organizationId,
  promptToaster,
  rentableItemsService,
}: UseUpdateHouseholdRentableItem): Mutation => {
  const abortController = new AbortController();
  const options = { signal: abortController.signal };
  const queryKey = ['updateHouseholdRentableItem', propertyId];

  const { mutate, mutateAsync, isLoading } = useMutation(
    queryKey,
    (payload: HouseholdRentableItem) =>
      rentableItemsService.updateHouseholdRentableItem(
        organizationId,
        propertyId,
        payload.id,
        JSON.stringify({ ...payload, id: undefined }),
        options,
      ),
    {
      onSuccess: () => {
        promptToaster({
          type: 'success',
          title: 'Updated',
          message: 'Rentable item',
        });
      },
      onError: (error) => {
        promptToaster({
          type: 'error',
          title: 'Error updating Rentable item',
          message: error,
        });
      },
      enabled: Boolean(propertyId?.length && organizationId?.length),
    },
  );

  return { mutate, mutateAsync, isLoading };
};
