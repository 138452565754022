import type { OrderValue } from '../containers/App/types';

const { apiURL, requestToDownload } = require('../utils/api');

const request = require('../utils/api').default;

class DocumentService {
  save(document: Object, organizationId: string, propertyId: string) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
      body: document,
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/documents`,
      options,
    );
  }

  getScreeningLetterOptions(requestOptions?: Object) {
    const options = {
      ...requestOptions,
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/adverseActionLetterOptions`, options);
  }
  getAdverseActionConditionsForApproval(requestOptions?: Object) {
    const options = {
      ...requestOptions,
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/adverseActionConditionsForApproval`, options);
  }

  saveAdverseActionLetter(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    letterType: string,
    requestOptions: Object,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
      body: JSON.stringify(requestOptions),
    };
    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/${applicationId}/screeningLetter/${letterType}`,
      options,
    );
  }

  saveResidentLetter(
    letter: Object,
    residentLetterId: string,
    organizationId: string,
    propertyId: string,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(letter),
    };

    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/letters/${residentLetterId}`,
      options,
    );
  }

  getAll(
    applicationId: string,
    pageNumber: number,
    limit: number,
    sorting: OrderValue,
    organizationId: string,
    propertyId: string,
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const sort =
      sorting.fieldName === ''
        ? ''
        : `&sort=${sorting.fieldName}&order=${sorting.order}`;
    const orgProp = `${apiURL}/${organizationId}/${propertyId}`;
    return request(
      `${orgProp}/documents?applicationId=${applicationId}&page=${pageNumber}&limit=${limit}${sort}`,
      options,
    ); // eslint-disable-line max-len
  }

  delete(organizationId: string, documentId: string, propertyId: string) {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/documents/${documentId}`,
      options,
    ); // eslint-disable-line max-len
  }

  download(
    organizationId: string,
    documentId: string,
    propertyId: string,
    options: ?Object = {},
  ) {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
      ...options,
    };

    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/documents/${documentId}`,
      requestOptions,
    );
  }

  downloadFASDocument(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    mode: string,
    options: ?Object = {},
  ) {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
      ...options,
    };

    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/${applicationId}/document/${mode}`,
      requestOptions,
    );
  }
}

export default DocumentService;
