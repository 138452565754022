import React from 'react';
import { Field } from 'redux-form';

import {
  renderDateField,
  renderSelectField,
  renderCurrencyField,
  renderFlexNumberField,
} from '../../../../../../utils/redux-form-helper';

import {
  FULL_DATE_FORMAT,
  GOSS_SALES_DUE_OPTIONS,
  MONTHS_SELECT_OPTIONS,
  DAYS_SELECT_OPTIONS,
} from '../../constants';

import { FlexFormInput } from '../../common';

const PercentageRentOptionsFormFields = ({ values }: Object) => {
  return (
    <React.Fragment>
      <FlexFormInput>
        <Field
          name="Breakpoint"
          component={renderCurrencyField}
          label="Breakpoint"
          step="0.01"
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Fiscal Year Start Date"
          component={renderDateField}
          classes="control-label"
          dateFormat={FULL_DATE_FORMAT}
          label="Fiscal Year Start Date"
          bsSize="md"
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Fiscal Year End Date"
          component={renderDateField}
          classes="control-label"
          dateFormat={FULL_DATE_FORMAT}
          label="Fiscal Year End Date"
          bsSize="md"
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Date Gross Sales Due"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Date Gross Sales Due"
          options={GOSS_SALES_DUE_OPTIONS}
        />
      </FlexFormInput>
      {values && values['Date Gross Sales Due'] ? (
        <React.Fragment>
          {values['Date Gross Sales Due'] === 'Monthly' ? (
            <FlexFormInput>
              <Field
                name="Day of Month Due"
                selectClassNames="input-md form-control"
                component={renderSelectField}
                label="Day of Month Due"
                options={DAYS_SELECT_OPTIONS()}
              />
            </FlexFormInput>
          ) : null}
          {values['Date Gross Sales Due'] === 'Annually' ? (
            <FlexFormInput>
              <Field
                name="Month Due"
                selectClassNames="input-md form-control"
                component={renderSelectField}
                label="Month Due"
                options={MONTHS_SELECT_OPTIONS()}
              />
            </FlexFormInput>
          ) : null}
        </React.Fragment>
      ) : null}
      <FlexFormInput>
        <Field
          name="Percentage of Sales Payable Above Breakpoint"
          component={renderFlexNumberField}
          label="Percentage of Sales Payable Above Breakpoint"
          max="1000"
          min="0"
          showLabel
        />
      </FlexFormInput>
    </React.Fragment>
  );
};

export default PercentageRentOptionsFormFields;
