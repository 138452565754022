import { pathOr } from 'ramda';
import type {
  PaymentSingleTransaction,
  CustomerLedger,
  MiscLedger,
} from '../../services/paymentSingleTransactionService';
import { ViewSingleTransactionModal } from '../../components/ViewSingleTransactionModal';
import ViewMiscTransactionModal from '../ManageMiscTransactions/MiscTransactionsDetails/viewMiscTransactionModal';
import { parseTransactionViewFields } from '../ManageMiscTransactions/MiscTransactionsDetails';

export function viewCustomerTransaction(
  payment: PaymentSingleTransaction,
  tx: CustomerLedger,
  propertyName: string,
  intl: Object,
  store: any,
) {
  const isOps = payment.paymentType === 'Ops';

  ViewSingleTransactionModal({
    // $FlowFixMe
    singleTransaction: {
      id: tx.id,
      // $FlowFixMe
      propertyFiscalPeriod: tx.propertyFiscalPeriod,
      // $FlowFixMe
      status: payment.transactionStatus,
      // $FlowFixMe
      isManual: tx.isManual,
      // $FlowFixMe
      property: { name: propertyName },
      // $FlowFixMe
      unit: tx.unit,
      customerStatus: tx.customerStatus,
      payment: {
        financiallyResponsibleLeaseSignersInHousehold:
          payment.financiallyResponsibleLeaseSignersInHousehold,
      },
      // $FlowFixMe
      receivedFrom: payment.receivedFrom,
      // $FlowFixMe
      subjournal: isOps ? payment.customerOpsLedger.subjournal : null,
      // $FlowFixMe
      propertyTransactionCode: {
        // $FlowFixMe
        transactionCode: {
          // $FlowFixMe
          code: tx.propertyTransactionCode.transactionCode.code,
          // $FlowFixMe
          description: tx.propertyTransactionCode.transactionCode.description,
          // $FlowFixMe
          transactionType: {
            // $FlowFixMe
            name: isOps ? 'Payment' : 'Security Payment',
          },
        },
      },
      // $FlowFixMe
      transactionDate: tx.transactionDate,
      // $FlowFixMe
      documentNumber: tx.documentNumber,
      // $FlowFixMe
      transactionNote: tx.transactionNote,
      // $FlowFixMe
      transactionAmount: tx.transactionAmount,
      // $FlowFixMe
      batchPaymentDetail: tx.batchPaymentDetail,
      // $FlowFixMe
      updatedBy: payment.updatedBy,
      updatedAt: payment.updatedAt,
      // $FlowFixMe
      createdBy: payment.createdBy,
    },
    isReversable: false,
    onReverse: () => {},
    onPrint: window.print.bind(window),
    personStatus: tx.customerStatus,
    frNames: payment.financiallyResponsibleLeaseSignersInHousehold,
    store,
    intl,
    isOps,
  });
}

export function viewMiscTransaction(
  payment: PaymentSingleTransaction,
  tx: MiscLedger,
  intl: Object,
) {
  ViewMiscTransactionModal(
    intl,
    parseTransactionViewFields(intl, {
      status: payment.transactionStatus,
      transactionDate: tx.transactionDate,
      // $FlowFixMe
      chargeCode: pathOr('', ['ptcCharge', 'transactionCode', 'code'], tx),
      // $FlowFixMe
      paymentCode: pathOr('', ['ptcPayment', 'transactionCode', 'code'], tx),
      // $FlowFixMe
      chargeCodeDescription: pathOr(
        '',
        ['ptcCharge', 'transactionCode', 'description'],
        tx,
      ),
      // $FlowFixMe
      paymentCodeDescription: pathOr(
        '',
        ['ptcPayment', 'transactionCode', 'description'],
        tx,
      ),
      documentNumber: tx.documentNumber,
      transactionNote: tx.transactionNote,
      transactionAmount: tx.transactionAmount,
    }),
    () => {},
    tx.propertyFiscalPeriods.period,
    payment.transactionStatus,
    false,
  );
}
