import { ButtonToolbar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import FilterIconButton from '../../FilterIconButton';
import React from '.';
import ElementWithPermissions from '../../ElementWithPermissions';
import type { ActivityData } from '../types';

type Props = {
  activitiesByProspect: ActivityData,
  onCreateActivityClick: Function,
  isFilterActive: boolean,
  setIsFilterActive: Function,
  isDirty: boolean,
};

function ActivityActions(props: Props) {
  return (
    <ButtonToolbar>
      <ElementWithPermissions scope={['activity-create']}>
        <button
          className="btn btn-shout pull-right"
          onClick={props.onCreateActivityClick}
        >
          <i className="et-calendar icon" />
          <FormattedMessage {...messages.createActivity} />
        </button>
      </ElementWithPermissions>
      <FilterIconButton
        className="pull-right"
        active={props.isFilterActive}
        disabled={
          !props.activitiesByProspect ||
          props.activitiesByProspect.results.length === 0 ||
          props.isDirty
        }
        onClick={() => props.setIsFilterActive(!props.isFilterActive)}
      />
    </ButtonToolbar>
  );
}

export default ActivityActions;
