import { useCallback, useEffect, useMemo, useState } from 'react';
import { mapFloorPlanOptions } from '../../../../utils/floorPlan';
import { BEDROOM_OPTIONS } from './constants';
import { v5 as uuidv5 } from 'uuid';
import { range } from 'ramda';
import messages from './messages';
// eslint-disable-next-line max-len
import { ProspectProfileFormPreferences } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';

const UUID_NAMESPACE = '022cfa5a-9c86-4e1a-8ed8-8d3435fd5bde';
export const NO_PREFERENCE_TEXT = 'No Preference';
export const NO_PREFERENCE_KEY = 'noPreference';
export const NO_PREFERENCE_UUID = uuidv5(NO_PREFERENCE_TEXT, UUID_NAMESPACE);

export const NO_PREFERENCE_OPTION = {
  value: NO_PREFERENCE_UUID,
  text: NO_PREFERENCE_TEXT,
  nBeds: NO_PREFERENCE_KEY,
};

const INITIAL_VALUES = {
  ...ProspectProfileFormPreferences.defaultProps.formState,
};
export const useLookingFor = ({
  intl,
  initialValues,
  floorPlans,
  reduxFormOnChange,
}) => {
  const initialState = {
    ...INITIAL_VALUES,
    ...initialValues,
    nBedsArr: initialValues.nBedsArr,
  };
  const [formState, setFormState] = useState({
    ...initialState,
  });
  const isNBedsArrPopulated =
    Array.isArray(formState.nBedsArr) && formState.nBedsArr.length > 0;
  const selectedNBeds = formState?.nBedsArr;
  const portalUserHasUnitSelected = initialValues?.unitId;
  const moveInDateFromInitValue = initialValues?.moveInDateFrom;
  const unitIsNotAssigned = !initialValues?.isUnitAssigned;

  const handleChange = useCallback(
    ({ name, value }) => {
      const onMoveInDateValueChange =
        name === 'moveInDateFrom' && value !== moveInDateFromInitValue;

      // redux form doesn't change when value = undefined
      if (value === undefined) {
        value = null;
      }

      // once nBedsArr is populated, delete deprecated nBeds
      if (isNBedsArrPopulated) {
        reduxFormOnChange('nBeds', null);
      }

      // sync portal move-in date with fortress move-in date
      if (
        onMoveInDateValueChange &&
        portalUserHasUnitSelected &&
        unitIsNotAssigned
      ) {
        reduxFormOnChange('moveInDateScheduled', value);
      }

      reduxFormOnChange(name, value);
      setFormState((prevState) => ({ ...prevState, [name]: value }));
    },
    [
      reduxFormOnChange,
      isNBedsArrPopulated,
      portalUserHasUnitSelected,
      moveInDateFromInitValue,
      unitIsNotAssigned,
    ],
  );

  const nBedOptions = useMemo(
    () =>
      Array.from(
        new Set(
          floorPlans
            ?.filter((floorPlan) => floorPlan.text !== NO_PREFERENCE_TEXT)
            .map((floorPlan) => floorPlan.nBeds),
        ),
      ),
    [floorPlans],
  );

  const processedFloorPlans = useMemo(() => {
    return mapFloorPlanOptions(floorPlans);
  }, [floorPlans]);

  const floorPlanOptions = useMemo(() => {
    return selectedNBeds?.length > 0 && selectedNBeds[0] !== NO_PREFERENCE_KEY
      ? processedFloorPlans.filter(filterFloorPlansByBedroomSelection)
      : processedFloorPlans;

    function filterFloorPlansByBedroomSelection(floorPlanOptions) {
      return [...selectedNBeds].some(
        (nBed) =>
          Number(nBed) === floorPlanOptions.nBeds ||
          floorPlanOptions.nBeds === NO_PREFERENCE_KEY,
      );
    }
  }, [processedFloorPlans, selectedNBeds]);

  const leaseTermOptions = useMemo(
    () =>
      range(0, 25).map((x) => {
        const text =
          x === 0
            ? `${intl.formatMessage(messages.chooseOption)}`
            : x === 1
            ? `${x} ${intl.formatMessage(messages.month)}`
            : `${x} ${intl.formatMessage(messages.months)}`;
        return {
          text,
          value: `${x}`,
        };
      }),
    [intl],
  );

  useEffect(() => {
    const chosenFloorPlanNotCompatibleWithSelectedBedroomOptions =
      !floorPlanOptions.find(
        (floorPlan) => floorPlan.value === formState?.preferredFloorPlanId,
      );

    if (chosenFloorPlanNotCompatibleWithSelectedBedroomOptions) {
      setFormState((prevState) => ({
        ...prevState,
        preferredFloorPlanId: null,
      }));
    }
  }, [floorPlanOptions, formState.preferredFloorPlanId]);

  const nBedSelectOptions = useMemo(() => {
    return BEDROOM_OPTIONS.filter((brOption) =>
      nBedOptions.some((nBed) => Number(brOption.value) === nBed),
    );
  }, [nBedOptions]);

  return {
    formState,
    handleChange,
    leaseTermOptions,
    floorPlanOptions,
    nBedSelectOptions,
  };
};
