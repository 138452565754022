import * as api from '../../utils/api';
import * as R from 'ramda';
import * as rentRollUtils from '../../utils/rent-rolls';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import type { AppliedFilters } from './types';
import type { FilterValues } from './FilterControls/types';
import type { FilterArray } from '../../utils/unit-helpers';
import { formatColumnType } from '../ManageCommercialRentRoll/helpers';
import * as dateHelpers from '../../utils/date-helpers';
import { valueListToQueryStr } from '../../utils/query-helpers';

export const getMappedRows = ({
  rows,
  intl,
  headers,
}: {
  rows: Object[],
  intl: Object,
  headers: Array<Object>,
}) => {
  return rows.map((row) => ({
    rows: Object.entries(row).reduce((acc, [key, value]) => {
      const header = headers?.find(({ id }) => id === key);

      if (header && header.id === 'collectionNote')
        value = rentRollUtils.getCollectionNotesValues(row);
      acc[key] = {
        raw: value,
        value: header ? formatColumnType(intl, value, header) : value,
      };
      return acc;
    }, {}),
  }));
};

const mapFilterValuesToQueryString = (filterValues: FilterValues): string => {
  if (!filterValues) return '';

  const floorPlanStr = valueListToQueryStr(filterValues.floorPlan, 'floorplan');
  const floorPlanTypeStr = valueListToQueryStr(
    filterValues.floorPlanType,
    'floorPlanType',
  );
  const unitStatusStr = valueListToQueryStr(filterValues.unitStatus, 'status');
  const bedroomsStr = valueListToQueryStr(filterValues.bedrooms, 'nBeds');
  const bathroomsStr = valueListToQueryStr(filterValues.bathrooms, 'nBaths');

  const moveInDateFromStr = filterValues.moveInDateFrom
    ? `&moveInDateFrom=${filterValues.moveInDateFrom}`
    : '';
  const moveInDateToStr = filterValues.moveInDateTo
    ? `&moveInDateTo=${filterValues.moveInDateTo}`
    : '';

  return [
    floorPlanStr,
    floorPlanTypeStr,
    unitStatusStr,
    bedroomsStr,
    bathroomsStr,
    moveInDateFromStr,
    moveInDateToStr,
  ].join('');
};

export const handleDownload = ({
  fileType = 'csv',
  organizationId,
  propertyId,
  propertyName,
  reportId,
  query: {
    sorting: { fieldName = '', order = '' },
    searchText = '',
  },
  filterValues,
  showHideColumns = [],
}: {
  fileType: 'csv' | 'pdf',
  organizationId: string,
  propertyId: string,
  propertyName: string,
  reportId: string,
  query: {
    sorting: { fieldName: string, order: string },
    searchText: string,
  },
  filterValues: FilterValues,
  showHideColumns: { id: string, text: string, selected: boolean }[],
}) => {
  let queryArray = [],
    queryString = '';

  if (fieldName?.length) queryArray.push(`&fieldName=${fieldName}`);

  if (order?.length) queryArray.push(`&order=${order}`);

  if (searchText?.length) queryArray.push(`&searchText=${searchText}`);

  const extraColumns = showHideColumns
    .filter((shc) => shc.selected)
    .map((shc) => shc.id);

  if (extraColumns.length)
    queryArray.push(`&extraColumns=${extraColumns.join(',')}`);

  const filterValuesQueryStr = mapFilterValuesToQueryString(filterValues);

  if (filterValuesQueryStr.length > 0) queryArray.push(filterValuesQueryStr);

  if (queryArray.length) {
    queryArray.unshift('?');
    queryString = queryArray.join('');
  }

  return api.getDownload(
    `/${organizationId}/${propertyId}/${fileType}-reports/${reportId}${queryString}&preventUpload=true`,
    `${propertyName}_${moment().format('YYYYMMDD')}.${fileType}`,
  );
};

const toAppliedFilter = R.curryN(
  3,
  (key: string, textFn: Function, value: any) => ({
    id: uuid(),
    text: textFn?.(value) || value,
    meta: {
      key,
      value,
    },
  }),
);
const bedsTextFn = (count: number) => (count === 1 ? '1 bed' : `${count} beds`);
const bathsTextFn = (count: number) =>
  count === 1 ? '1 bath' : `${count} baths`;

const moveInDateRangeToAppliedFilter = (
  fromDateStr: ?string,
  toDateStr: ?string,
) => {
  const DATE_FORMAT = 'MM/DD/YYYY';

  if (!fromDateStr || !toDateStr) return [];

  const from = moment(fromDateStr);
  const to = moment(toDateStr);

  if (!from.isValid() || !to.isValid() || from.isAfter(to, 'day')) return [];

  return [
    {
      id: uuid(),
      text: `${from.format(DATE_FORMAT)}-${to.format(DATE_FORMAT)}`,
      meta: { isMoveInDateRangeFilter: true },
    },
  ];
};

export const mapFilterValuesToAppliedFilters = (
  filterValues: FilterValues,
): AppliedFilters => {
  if (!filterValues) return [];

  const floorPlanFilters = filterValues.floorPlan.map(
    toAppliedFilter('floorPlan', R.identity),
  );
  const floorPlanTypeFilters = filterValues.floorPlanType.map(
    toAppliedFilter('floorPlanType', R.identity),
  );
  const unitStatusFilters = filterValues.unitStatus.map(
    toAppliedFilter('unitStatus', R.identity),
  );
  const bedroomFilters = filterValues.bedrooms.map(
    toAppliedFilter('bedrooms', bedsTextFn),
  );
  const bathroomFilters = filterValues.bathrooms.map(
    toAppliedFilter('bathrooms', bathsTextFn),
  );
  const moveInDateRangeFilter = moveInDateRangeToAppliedFilter(
    filterValues.moveInDateFrom,
    filterValues.moveInDateTo,
  );

  return [
    ...floorPlanFilters,
    ...floorPlanTypeFilters,
    ...unitStatusFilters,
    ...moveInDateRangeFilter,
    ...bedroomFilters,
    ...bathroomFilters,
  ];
};

export const mapFilterValuesToFilterArray = (
  values: FilterValues,
): FilterArray => {
  if (!values) return [];

  const floorPlanFilter =
    values.floorPlan?.length > 0
      ? { type: 'value', key: 'floorPlan', values: [...values.floorPlan] }
      : undefined;
  const floorPlanTypeFilter =
    values.floorPlanType?.length > 0
      ? {
          type: 'value',
          key: 'floorPlanType',
          values: [...values.floorPlanType],
        }
      : undefined;
  const statusFilter =
    values.unitStatus?.length > 0
      ? { type: 'value', key: 'status', values: [...values.unitStatus] }
      : undefined;
  const moveInDateFilter =
    dateHelpers.isValidDate(values.moveInDateFrom) &&
    dateHelpers.isValidDate(values.moveInDateTo)
      ? {
          type: 'date-range',
          key: 'moveInDate',
          from: values.moveInDateFrom,
          to: values.moveInDateTo,
        }
      : undefined;
  const nBedsFilter =
    values.bedrooms?.length > 0
      ? { type: 'value', key: 'nBeds', values: [...values.bedrooms] }
      : undefined;
  const nBathsFilter =
    values.bathrooms?.length > 0
      ? { type: 'value', key: 'nBaths', values: [...values.bathrooms] }
      : undefined;

  return [
    floorPlanFilter,
    floorPlanTypeFilter,
    statusFilter,
    moveInDateFilter,
    nBedsFilter,
    nBathsFilter,
  ].filter((f) => f);
};
