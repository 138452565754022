import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, FormSection } from 'redux-form';
import classNames from 'classnames';

import {
  renderCurrencyField,
  renderDateField,
  extractCurrentDateFormat
} from '../../../utils/redux-form-helper';
import messages from './messages';
import AddressSection from '../../AddressSection';
import OwnOrRentSection from '../OwnOrRentSection';

import type { SelectOption } from '../../../containers/App/types';

type Props = {
  intl: Object,
  states: Array<SelectOption>,
  title: Object,
  required?: boolean
};

const ResidenceInformationSection = ({
  intl,
  states,
  title,
  required
}: Props) => (
  <div>
    <div className="form-block">
      <div className="form-content">
        <Row>
          <Col xs={12} md={12}>
            <h3>{intl.formatMessage(title)}</h3>
          </Col>
        </Row>
        <FormSection name="address">
          <AddressSection
            intl={intl}
            states={states}
            required={required}
            streetLabel={messages.streetAddressLabel}
          />
        </FormSection>
        <Row>
          <Col xs={12} md={6}>
            <Field
              name="dateMovedIn"
              component={renderDateField}
              bsSize="lg"
              label={intl.formatMessage(messages.dateMovedIn)}
              placeholder={extractCurrentDateFormat(intl)}
              dateFormat={extractCurrentDateFormat(intl)}
              classes={classNames({ required })}
            />
          </Col>
          <Col xs={12} md={6}>
            <Field
              name="monthlyPayment"
              component={renderCurrencyField}
              className="input-lg"
              label={intl.formatMessage(messages.monthlyPayment)}
              classes={classNames({ required })}
            />
          </Col>
        </Row>
      </div>
    </div>
    <FormSection name="ownOrRent">
      <OwnOrRentSection
        intl={intl}
        ownOption={{ text: 'Own', value: 'own', disabled: false }}
        rentOption={{ text: 'Rent', value: 'rent', disabled: false }}
        notCurrentlyOwnRentOption={{
          text: 'Do not currently own or rent',
          value: 'other',
          disabled: false
        }}
        required={required}
      />
    </FormSection>
  </div>
);

export default ResidenceInformationSection;
