import { FORM_INFO } from './constants';
// This is added currently for Washington State requirements for their asset forms
export const hasInterestRateField = (property) => {
  const physicalState = property?.physicalState ?? '';
  const isWashingtonState = physicalState === 'US-WA';
  return isWashingtonState;
};

export const getAssetDefinitions = (isHotmaActive) => {
  let allAssetDefinitions = [...FORM_INFO.initialAssets];
  if (!isHotmaActive) {
    allAssetDefinitions = [
      ...allAssetDefinitions,
      ...FORM_INFO.retirementAccounts,
    ];
  }
  allAssetDefinitions = [
    ...allAssetDefinitions,
    ...FORM_INFO.lifeInsurance,
    ...FORM_INFO.finalAssets,
  ];
  return allAssetDefinitions;
};
