import React from 'react';
import { FieldArray } from 'redux-form';
import { RetirementAssetFieldArrayRows } from './RetirementAssetFieldArrayRows';

export const ExpandableRetirementRows = ({
  asset,
  fields,
  isPrior,
  handleAdd,
  handleDelete,
  noClicked,
  calculateCashValue,
  calculateAnnualIncome,
}) => {
  const disabled = fields[asset.name] !== 'yes';
  const showAddAccountBtn = !disabled && asset?.expandable;
  return (
    <React.Fragment key={asset.name}>
      <FieldArray
        name={`${asset.name}List`}
        component={RetirementAssetFieldArrayRows}
        asset={asset}
        isPrior={isPrior}
        assetName={asset.name}
        handleDelete={handleDelete}
        handleAdd={handleAdd}
        noClicked={noClicked}
        formFields={fields}
        showAddAccountBtn={showAddAccountBtn}
        calculateCashValue={calculateCashValue}
        calculateAnnualIncome={calculateAnnualIncome}
      />
    </React.Fragment>
  );
};
