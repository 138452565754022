import { defineMessages } from 'react-intl';

const messages = defineMessages({
  errorHeader: {
    id: 'App.TextingTab.ErrorHeader',
    defaultMessage: 'Error',
  },
  failedToLoadHouseholdMembersErrorMsg: {
    id: 'App.TextingTab.FailedToLoadHouseholdMembersErrorMsg',
    defaultMessage: 'Failed to load household members.',
  },
  failedToLoadConversationsErrorMsg: {
    id: 'App.TextingTab.failedToLoadConversationsErrorMsg',
    defaultMessage: 'Failed to load conversations.',
  },
  failedToLoadMessagesErrorMsg: {
    id: 'App.TextingTab.FailedToLoadMessagesErrorMsg',
    defaultMessage: 'Failed to load messages.',
  },
});

export default messages;
