import { useMemo, useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { Select } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import LimitInput from './LimitInput';
import { PageContext } from '../context';
import Spacer from '../../../components/Spacer';
import IconLink from '../../../components/IconLink';
import messages from '../messages';

export type SelectedMonthInfo = {
  currentLimit: number,
  leaseLimit: number,
  leaseCount: number,
  actualLimit: number,
};

type Props = {
  monthOptions: { value: string, text: string }[],
  selectedLimitMonth: string,
  onSelectedLimitMonthChange: Function,
  limits: number[],
  leaseCounts: number[],
  selectedYears: number[],
  unitCount: number,
  isSaving: boolean,
  onSave: (year: number, month: number, value: number) => void,
  labels: [string, number][],
};

function SelectedLimit({
  limits,
  leaseCounts,
  selectedYears,
  unitCount,
  onSave,
  isSaving,
  monthOptions,
  selectedLimitMonth,
  onSelectedLimitMonthChange,
  labels,
}: Props) {
  const { canEdit, formatMessage } = useContext(PageContext);
  const [inputValue, setInputValue] = useState(0);
  const selectedMonthInfo: SelectedMonthInfo = useMemo(() => {
    const monthsShort = moment.monthsShort();

    const dataIndex = labels.findIndex(([month, year]) => {
      const monthIndex = monthsShort.findIndex((m) => m === month);

      return `${monthIndex}-${year}` === selectedLimitMonth;
    });

    if (dataIndex < 0)
      return {
        currentLimit: 0,
        actualLimit: 0,
        leaseCount: 0,
        leaseLimit: 0,
      };

    return {
      currentLimit: limits[dataIndex],
      actualLimit: (leaseCounts[dataIndex] / unitCount) * 100,
      leaseCount: leaseCounts[dataIndex],
      leaseLimit: Math.ceil((inputValue * unitCount) / 100),
    };
  }, [selectedLimitMonth, limits, leaseCounts, inputValue, labels, unitCount]);

  const isSelectedMonthInThePast = useMemo(() => {
    const [month, year] = selectedLimitMonth.split('-');
    const selectedDate = moment([year, month]);
    const today = moment();

    return selectedDate.isBefore(today, 'months');
  }, [selectedLimitMonth]);

  const areButtonsDisabled = useMemo(
    () =>
      isSaving ||
      inputValue === selectedMonthInfo.currentLimit ||
      isSelectedMonthInThePast,
    [isSaving, inputValue, selectedMonthInfo, isSelectedMonthInThePast],
  );

  const handleInputValueChange = (value: number) => {
    setInputValue(value);
  };

  const handleSave = () => {
    const [month, year] = selectedLimitMonth.split('-');
    onSave(year, month, inputValue);
  };

  const clearChanges = () => {
    setInputValue(selectedMonthInfo.currentLimit);
  };

  const renderSelectValue = (value) =>
    monthOptions.find((opt) => opt.value === value)?.text ?? '';

  useEffect(() => {
    setInputValue(selectedMonthInfo.currentLimit);
  }, [selectedMonthInfo.currentLimit]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Select
        name="selectedLimitMonth"
        options={monthOptions}
        value={selectedLimitMonth}
        onChange={onSelectedLimitMonthChange}
        sx={{ width: 150 }}
        renderValue={renderSelectValue}
      />
      <Spacer h={10} />
      <b>{formatMessage(messages.currentLimit)}: </b>
      {canEdit ? (
        <>
          <Spacer h={10} />
          <LimitInput
            value={inputValue}
            onChange={handleInputValueChange}
            style={{ width: 91, height: 36 }}
            disabled={isSaving || isSelectedMonthInThePast}
          />
          <Spacer h={5} />
          <IconLink
            iconClass="et-isolated-check"
            color="#4CBB17"
            iconFontSize={20}
            onClick={handleSave}
            disabled={areButtonsDisabled}
          />
          <IconLink
            iconClass="et-delete"
            color="#D70000"
            iconFontSize={12}
            onClick={clearChanges}
            disabled={areButtonsDisabled}
          />
        </>
      ) : (
        <>
          <Spacer h={2} />
          <b>{selectedMonthInfo.currentLimit.toFixed(1)}%</b>
        </>
      )}
      <Spacer h={10} />
      <b>{formatMessage(messages.leasesPlural)}: </b>
      <Spacer h={3} />
      <span>{selectedMonthInfo.leaseLimit}</span>
      <Spacer h={20} />
      <span style={{ color: '#9A9A9A' }}>/</span>
      <Spacer h={20} />
      <b>{formatMessage(messages.actuals)}: </b>
      <Spacer h={3} />
      <span>{selectedMonthInfo.actualLimit.toFixed(1)}%</span>
      <Spacer h={10} />
      <b>{formatMessage(messages.leasesPlural)}: </b>
      <Spacer h={3} />
      <span>{selectedMonthInfo.leaseCount}</span>
    </div>
  );
}

export default SelectedLimit;
