import type { Action } from '../App/types';
import {
  GET_PORTFOLIO_SUCCESS,
  GET_PORTFOLIO_ERROR,
  CLEAN_LOADED_PORTFOLIO,
} from './constants';

export const initialState = {
  portfolioEntries: [],
};

const portfolioReducer = (
  state: Object = initialState,
  action: Action<*, *>,
): Object => {
  const { type, payload, error } = action;
  switch (type) {
    case GET_PORTFOLIO_SUCCESS: {
      return { ...state, portfolioEntries: payload };
    }
    case GET_PORTFOLIO_ERROR: {
      return { ...state, error };
    }
    case CLEAN_LOADED_PORTFOLIO: {
      return initialState;
    }
    default:
      return state;
  }
};

export default portfolioReducer;
