import * as R from 'ramda';
import type { AddEditEmergencyContactFormValues } from './types';
import {
  validatePhoneNumber,
  validateName,
  validateEmail,
  validateZipCode,
} from '../../../../utils/validations';

const requiredFields = ['contactName', 'phoneNumber', 'relationship'];

function validate(values: AddEditEmergencyContactFormValues) {
  const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty]);

  const errors: Object = requiredFields.reduce(
    (errors, field) =>
      isNilOrEmpty(values[field])
        ? { ...errors, [field]: 'Required Field' }
        : errors,
    {},
  );

  if (
    R.isNil(errors.zipCode) &&
    values?.zipCode &&
    !validateZipCode(values.zipCode)
  )
    errors.zipCode = 'Invalid Zip Code / Postal Code';

  if (R.isNil(errors.phoneNumber) && !validatePhoneNumber(values.phoneNumber))
    errors.phoneNumber = 'Invalid Phone Number';

  if (R.isNil(errors.contactName) && !validateName(values.contactName))
    errors.contactName = 'Invalid Contact Name';

  if (
    R.isNil(errors.emailAddress) &&
    !isNilOrEmpty(values.emailAddress) &&
    !validateEmail(values.emailAddress)
  ) {
    errors.emailAddress = 'Invalid Email';
  }

  if (
    R.isNil(errors.secondaryPhoneNumber) &&
    !isNilOrEmpty(values.secondaryPhoneNumber) &&
    !validatePhoneNumber(values.secondaryPhoneNumber)
  )
    errors.secondaryPhoneNumber = 'Invalid Phone Number';
  return errors;
}

export default validate;
