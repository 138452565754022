import {
  CommentConversationIcon,
  NewMessagesIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { useHasIndicator } from './hooks';

function TextingTabIcon(props) {
  const hasIndicator = useHasIndicator(props);
  return hasIndicator ? <NewMessagesIcon /> : <CommentConversationIcon />;
}

export default TextingTabIcon;
