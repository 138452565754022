/* eslint-disable max-len*/
import React, { useEffect, useState } from 'react';
import { NoPrint } from 'react-easy-print';
import { Field } from 'redux-form';
import moment from 'moment';
import {
  phoneFormatter,
  renderTextAreaField,
} from '../../../../utils/redux-form-helper';
import { isStatusSelected } from '../validate';
import messages from './messages';
import CommunicationTable from './CommunicationTable';

type Props = {
  intl: any,
  statuses: any,
  selectedStatusId: string,
  change: Function,
  workOrder: Object,
  property: {
    phone: string,
    email: string,
    name: string,
  },
  selectedFinishedDate: any,
  requestor: {
    name: string,
    email: string,
    phoneNumber: string,
  },
  selectedPropertyId: string,
  customer: Object,
  fieldsDirty: boolean,
};
function CommunicationToResidentSection({
  intl,
  statuses,
  selectedStatusId,
  change,
  workOrder,
  property,
  selectedFinishedDate,
  requestor,
  selectedPropertyId,
  customer,
  fieldsDirty,
}: Props) {
  const { issue, holdReason } = workOrder;
  const isOnHoldSelected = isStatusSelected(statuses, selectedStatusId);
  const isCompleteSelected = isStatusSelected(
    statuses,
    selectedStatusId,
    'Completed',
  );

  const isReasonSelected = workOrder.holdReason;

  const [noteText, setNoteText] = useState('');

  const onHoldEmailTemplate = `Dear Valued Resident,\n
The work order you entered for ${
    issue?.issueDescription
  } was placed On Hold on ${moment().format('MM/DD/YYYY')} due to ${holdReason}.
We will be back to complete this request.\n
Please contact the management office with any questions or concerns at ${
    property.email
  } or ${phoneFormatter(property.phone)}.\n
${property?.name}`;

  const completedEmailTemplate = `Dear Valued Resident,\n
The work order you entered for ${
    issue?.issueDescription
  } was Completed on ${moment(selectedFinishedDate).format('MM/DD/YYYY')}.\n
Please contact the management office with any questions or concerns at ${
    property.email
  } or ${phoneFormatter(property.phone)}.\n
${property?.name}`;

  const onHoldTextTemplate = `Hello, Valued Resident! Please check your email for an update regarding the work order you submitted.
Contact our office at ${phoneFormatter(property.phone)} with any questions.`;
  const completedTextTemplate = `Hello, Valued Resident! Please check your email for an update regarding the work order you submitted.
Contact our office at ${phoneFormatter(property.phone)} with any questions.`;

  const getEmailTemplate = () => {
    if (isOnHoldSelected) return onHoldEmailTemplate;
    if (isCompleteSelected) return completedEmailTemplate;
    return '';
  };

  const getTextTemplate = () => {
    if (isOnHoldSelected) return onHoldTextTemplate;
    if (isCompleteSelected) return completedTextTemplate;
    return '';
  };

  change('emailTemplate', getEmailTemplate());
  change('textTemplate', getTextTemplate());

  const [showTemplateFields, setShowTemplate] = useState(false);

  useEffect(() => {
    if (
      (isOnHoldSelected && isReasonSelected) ||
      (isCompleteSelected && selectedFinishedDate)
    ) {
      if (!customer.id || !fieldsDirty) {
        setShowTemplate(false);
      } else {
        setShowTemplate(true);
      }
    } else {
      setShowTemplate(false);
    }
  }, [
    customer.id,
    isCompleteSelected,
    isOnHoldSelected,
    isReasonSelected,
    selectedFinishedDate,
    fieldsDirty,
  ]);
  const isOnHoldOrCompleted =
    (isOnHoldSelected && isReasonSelected) ||
    (isCompleteSelected && selectedFinishedDate);

  useEffect(() => {
    if (!customer.id) {
      setNoteText(intl.formatMessage(messages.noCommunicationInfoNote));
    } else if (isOnHoldOrCompleted && fieldsDirty) {
      setNoteText(intl.formatMessage(messages.noteOnHoldOrCompleted));
    } else {
      setNoteText(intl.formatMessage(messages.note));
    }
  }, [intl, customer.id, isOnHoldOrCompleted, fieldsDirty]);

  return (
    <div data-test="edit-wo-after-service">
      <div className="row padtop10">
        <div className="col-xs-12 padtop30">
          <h3>{intl.formatMessage(messages.title)}</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12  padtop10">{noteText}</div>
      </div>
      {showTemplateFields && (
        <div className="row">
          <div className="col-xs-12 padtop10">
            <CommunicationTable
              selectedPropertyId={selectedPropertyId}
              requestor={requestor}
              change={change}
              customer={customer}
            />
          </div>
          <div className="col-xs-12 padtop30">
            <div className="form-group">
              <NoPrint force>
                <label>{intl.formatMessage(messages.emailToResident)}</label>
                <Field
                  name="emailTemplate"
                  className="form-control textarea-short"
                  maxLength="500"
                  component={renderTextAreaField}
                  disabled
                />
              </NoPrint>
            </div>
          </div>
          <div className="col-xs-12 padtop10">
            <div className="form-group">
              <NoPrint force>
                <label>{intl.formatMessage(messages.textToResident)}</label>
                <Field
                  name="textTemplate"
                  className="form-control textarea-short"
                  maxLength="500"
                  component={renderTextAreaField}
                  disabled
                />
              </NoPrint>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CommunicationToResidentSection;
