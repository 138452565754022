import keyBy from 'lodash.keyby';
import { useMemo } from 'react';
import { useFetchConversations } from '../../hooks/data-fetching/useFetchConversations';
import useHasPermission from '../../hooks/useHasPermission';

export function injectConversations(Component) {
  function HOC(props) {
    const prospectIds = useMemo(
      () => props.prospects.map((p) => p.id).flat(),
      [props.prospects],
    );

    const hasCommunicationCreatePermission = useHasPermission(
      'communication-create',
    );
    const showTextingColumn =
      props.selectedProperty.isTwoWayCommunicationActive &&
      hasCommunicationCreatePermission;

    const [conversations] = useFetchConversations(
      prospectIds,
      showTextingColumn,
    );

    const householdConversationsStatusDictionary = useMemo(() => {
      const conversationsDictionary = keyBy(
        conversations,
        (c) => c.recipient.prospectId,
      );

      return props.prospects.reduce((acc, prospect) => {
        const conversation = conversationsDictionary[prospect.id] ?? {};
        const householdConversationsStatus = {
          NEW: conversation.conversationStatus === 'NEW' ? 1 : 0,
          UNRESOLVED: conversation.conversationStatus === 'UNRESOLVED' ? 1 : 0,
          RESOLVED: conversation.conversationStatus === 'RESOLVED' ? 1 : 0,
        };

        householdConversationsStatus.TOTAL =
          householdConversationsStatus.NEW +
          householdConversationsStatus.UNRESOLVED;

        acc[prospect.id] = householdConversationsStatus;

        return acc;
      }, {});
    }, [conversations, props.prospects]);

    return (
      <Component
        householdConversationsStatusDictionary={
          householdConversationsStatusDictionary
        }
        showTextingColumn={showTextingColumn}
        {...props}
      />
    );
  }

  return HOC;
}
