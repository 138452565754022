import moment from 'moment';
import messages from './messages';

const validate = (values: Object, { intl }: Object) => {
  const errors = { LeaseEndDateRangeSection: {} };

  const { from, to } = values.LeaseEndDateRangeSection ?? {};
  if (to && from) {
    if (moment(to).isBefore(from, 'day')) {
      errors.LeaseEndDateRangeSection.to = intl.formatMessage(
        messages.toBeforeFrom,
      );
    }
  }

  return errors;
};

export default validate;
