import { useCallback, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { useQueryClient } from 'react-query';
import { Box } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { useConfirmModal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { AppContext } from '../App/context';
import useAssignUnitMutation from '../../hooks/data-modifying/useAssignUnitMutation';
import useTransferLeaseMutation from '../../hooks/data-modifying/useTransferLeaseMutation';
import useUnAssignUnitMutation from '../../hooks/data-modifying/useUnAssignUnitMutation';
import messages from '../ManageUnitAvailability/messages';
import appMessages from '../../containers/App/messages';
import residentMessages from '../ResidentProfile/messages';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import useSearchParams from './hooks.useSearchParams';
import useHistory from '../../hooks/useHistory';
import useIntl from '../../hooks/useIntl';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

const useAssignUnit = ({ applicantToAssignUnit }) => {
  const history = useHistory();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [unitIdToAssign, setUnitIdToAssign] = useState(null);
  const [actionType, setActionType] = useState(null);
  const { openConfirm, openConfirmModal, closeConfirmModal } =
    useConfirmModal();
  const { organizationId, id: propertyId } = useSelectedProperty();
  const { userId } = useContext(AppContext);
  const { isTransfer, applicationId, residentId } = useSearchParams();

  const onClose = useCallback(() => {
    closeConfirmModal();
    setUnitIdToAssign(null);
  }, [closeConfirmModal]);

  const inValidateQueries = useCallback(() => {
    queryClient.invalidateQueries([
      'useManageUnitAvailabilityQuery',
      'applicationService.getApplication',
      organizationId,
      propertyId,
    ]);
    queryClient.invalidateQueries([
      'applicationService.getApplication',
      organizationId,
      propertyId,
      applicationId,
    ]);
    queryClient.invalidateQueries([
      'household',
      'lease',
      'validateTransferLease',
    ]);
  }, [applicationId, organizationId, propertyId, queryClient]);

  const { mutate: assignUnitMutate, isLoading: assignUnitMutateIsLoading } =
    useAssignUnitMutation({
      organizationId,
      propertyId,
      applicationId: applicantToAssignUnit?.id,
      options: {
        onSuccess: () => {
          inValidateQueries();
          toastr.success(
            intl.formatMessage(appMessages.success),
            intl.formatMessage(messages.assignUnitSuccess),
          );
          onClose();
        },
        onError: (err) => {
          toastr.error(intl.formatMessage(appMessages.error), err.toString());
        },
      },
    });

  const { mutate: unAssignUnitMutate, isLoading: unAssignUnitMutateIsLoading } =
    useUnAssignUnitMutation({
      organizationId,
      propertyId,
      assignedUnitId: applicantToAssignUnit?.au?.id,
      options: {
        onSuccess: () => {
          inValidateQueries();
          toastr.success(
            intl.formatMessage(appMessages.success),
            intl.formatMessage(messages.unitUnassigned),
          );
          onClose();
        },
        onError: (err) => {
          toastr.error(intl.formatMessage(appMessages.error), err.toString());
        },
      },
    });

  const {
    mutate: transferLeaseMutate,
    isLoading: transferLeaseMutateIsLoading,
  } = useTransferLeaseMutation({
    organizationId,
    propertyId,
    residentId,
    applicationId,
    userId,
    options: {
      onSuccess: () => {
        inValidateQueries();
        toastr.success(
          intl.formatMessage(appMessages.success),
          intl.formatMessage(residentMessages.successDescriptionTransfer),
        );
        onClose();

        history.push(
          getUrlWithSelectedPropertyId(`/resident/${residentId}?tab=3`),
        );
      },
      onError: (err) => {
        toastr.error(intl.formatMessage(appMessages.error), err.toString());
      },
    },
  });

  const assignUnit = useCallback(() => {
    if (isTransfer && residentId) {
      transferLeaseMutate(unitIdToAssign);
    } else {
      assignUnitMutate(unitIdToAssign);
    }
  }, [
    assignUnitMutate,
    isTransfer,
    residentId,
    unitIdToAssign,
    transferLeaseMutate,
  ]);

  const handleAssignUnitClick = useCallback(
    (unitId) => {
      setActionType('assign');
      openConfirmModal();
      setUnitIdToAssign(unitId);
    },
    [openConfirmModal],
  );

  const handleUnAssignUnitClick = useCallback(() => {
    setActionType('unassign');
    openConfirmModal();
  }, [openConfirmModal]);

  const { firstName, lastName } = applicantToAssignUnit?.prospectInfo ?? {};

  return {
    title: (
      <>
        <FormattedMessage
          {...messages[
            actionType === 'unassign'
              ? 'confirmUnassignUnit'
              : isTransfer
              ? 'confirmTransferUnit'
              : 'confirmAssignUnit'
          ]}
        />
        <Box paddingLeft={0.5}> {`${firstName} ${lastName}`}</Box>
      </>
    ),
    onClose,
    actionsProps: [
      {
        variant: 'text',
        children: <FormattedMessage {...appMessages.cancel} />,
        onClick: onClose,
      },
      {
        children: <FormattedMessage {...appMessages.yes} />,
        submitButton: true,
        isSubmitting:
          assignUnitMutateIsLoading ||
          transferLeaseMutateIsLoading ||
          unAssignUnitMutateIsLoading,
        onClick: { assign: assignUnit, unassign: unAssignUnitMutate }[
          actionType
        ],
      },
    ],
    handleAssignUnitClick,
    handleUnAssignUnitClick,
    openConfirm,
    openConfirmModal,
    closeConfirmModal,
  };
};

export default useAssignUnit;
