import WaitlistInfoDisplayer from './WaitlistInfoDisplayer';
import { WaitlistSectionProps } from './types';
import { isWaitlistStatus } from './utils';

const WaitlistNotesSection = (props: WaitlistSectionProps) => {
  const { currentApplicationStatus, ...waitlistProps } = props;
  const isShowWaitlistNotes = isWaitlistStatus(currentApplicationStatus);

  return isShowWaitlistNotes ? (
    <WaitlistInfoDisplayer {...waitlistProps} />
  ) : null;
};

export default WaitlistNotesSection;
