import { defineMessages } from 'react-intl';

const messages = defineMessages({
  transactionCode: {
    id: 'App.ResidentProfile.ResidentMoveOut.ProRatedAmounts.TransactionCode',
    defaultMessage: 'Transaction Code',
  },
  description: {
    id: 'App.ResidentProfile.ResidentMoveOut.ProRatedAmounts.Description',
    defaultMessage: 'Description',
  },
  transactionType: {
    id: 'App.ResidentProfile.ResidentMoveOut.ProRatedAmounts.TransactionType',
    defaultMessage: 'Transaction Type',
  },
  monthlyRecurringAmount: {
    id: 'App.ResidentProfile.ResidentMoveOut.ProRatedAmounts.MonthlyRecurringAmount',
    defaultMessage: 'Monthly Recurring Amount',
  },
  moveOutCredit: {
    id: 'App.ResidentProfile.ResidentMoveOut.ProRatedAmounts.MoveOutCredit',
    defaultMessage: 'Move-Out {type} Credit',
  },
  proRated: {
    id: 'App.ResidentProfile.ResidentMoveOut.ProRatedAmounts.ProRated',
    defaultMessage: 'Prorated',
  },
  full: {
    id: 'App.ResidentProfile.ResidentMoveOut.ProRatedAmounts.Full',
    defaultMessage: 'Full',
  },
});

export default messages;
