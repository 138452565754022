import { defineMessages } from 'react-intl';

const messages = defineMessages({
  firstNameLabel: {
    id: 'App.ProspectProfile.ProspectProfileForm.FirstName.Label',
    defaultMessage: 'First Name *'
  },
  firstNamePlaceholder: {
    id: 'App.ProspectProfile.ProspectProfileForm.FirstName.Placeholder',
    defaultMessage: 'First name'
  },
  middleNameLabel: {
    id: 'App.ProspectProfile.ProspectProfileForm.MiddleName.Label',
    defaultMessage: 'Middle Name'
  },
  middleNamePlaceholder: {
    id: 'App.ProspectProfile.ProspectProfileForm.MiddleName.Placeholder',
    defaultMessage: 'Middle name'
  },
  lastNameLabel: {
    id: 'App.ProspectProfile.ProspectProfileForm.LastName.Label',
    defaultMessage: 'Last Name *'
  },
  lastNamePlaceholder: {
    id: 'App.ProspectProfile.ProspectProfileForm.LastName.Placeholder',
    defaultMessage: 'Last name'
  },
  suffixLabel: {
    id: 'App.ProspectProfile.ProspectProfileForm.Suffix.Label',
    defaultMessage: 'Suffix'
  },
  suffixPlaceholder: {
    id: 'App.ProspectProfile.ProspectProfileForm.Suffix.Placeholder',
    defaultMessage: 'Suffix'
  },
  preferredNameLabel: {
    id: 'App.ProspectProfile.ProspectProfileForm.PreferredName.Label',
    defaultMessage: 'Preferred Name'
  },
  preferredNamePlaceholder: {
    id: 'App.ProspectProfile.ProspectProfileForm.PreferredName.Placeholder',
    defaultMessage: 'Preferred name'
  },
  phoneLabel: {
    id: 'App.ProspectProfile.ProspectProfileForm.Phone.Label',
    defaultMessage: 'Phone *'
  },
  emailLabel: {
    id: 'App.ProspectProfile.ProspectProfileForm.Email.Label',
    defaultMessage: 'Email *'
  },
  emailPlaceholder: {
    id: 'App.ProspectProfile.ProspectProfileForm.Email.Placeholder',
    defaultMessage: 'Email'
  },
  myNameIs: {
    id: 'App.ProspectProfile.ProspectProfileForm.Email.MyNameIs',
    defaultMessage: 'Great! I\'d love to help you with that. Again, my name is '
  },
  mayIAsk: {
    id: 'App.ProspectProfile.ProspectProfileForm.Email.MayIAsk',
    defaultMessage: '. May I ask who I am speaking with?'
  }
});

export default messages;
