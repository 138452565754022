import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import classNames from 'classnames';
import { isNil, not } from 'ramda';

import {
  renderTextField,
  renderSelectField,
} from '../../utils/redux-form-helper';
import messages from './messages';

import type { SelectOption } from '../../containers/App/types';

type Props = {
  store?: Object,
  intl: Object,
  states: Array<SelectOption>,
  required?: boolean,
  disabled?: ?boolean,
  streetLabel: Object,
};

const AddressSection = ({
  store,
  intl,
  states,
  required,
  disabled = false,
  streetLabel,
}: Props) => {
  const storeProp = {};
  if (not(isNil(store))) {
    storeProp.store = store;
  }
  return (
    <Row>
      <Col xs={12} md={3}>
        <Field
          {...storeProp}
          name="street"
          component={renderTextField}
          className="input-lg"
          label={intl.formatMessage(streetLabel)}
          placeholder={intl.formatMessage(messages.streetAddressPlaceholder)}
          showLabel
          classes={classNames({ required })}
          disabled={disabled}
        />
      </Col>
      <Col xs={12} md={3}>
        <Field
          {...storeProp}
          name="city"
          component={renderTextField}
          className="input-lg"
          label={intl.formatMessage(messages.city)}
          placeholder={intl.formatMessage(messages.city)}
          showLabel
          classes={classNames({ required })}
          disabled={disabled}
        />
      </Col>
      <Col xs={12} md={3}>
        <Field
          {...storeProp}
          name="state"
          component={renderSelectField}
          options={states}
          bsSize="lg"
          label={intl.formatMessage(messages.state)}
          classes={classNames({ required })}
          disabled={disabled}
        />
      </Col>
      <Col xs={12} md={3}>
        <Field
          {...storeProp}
          name="zipCode"
          component={renderTextField}
          className="input-lg"
          label={intl.formatMessage(messages.zipCode)}
          placeholder={intl.formatMessage(messages.zipCode)}
          showLabel
          classes={classNames({ required })}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

export default AddressSection;
