import { defineMessages } from 'react-intl';

export const COMPLIANCE_DOCUMENT_TYPES_SHORT = {
  finalSignedPacket: 'Final Signed Packet',
  hudFinalSignedPacket: 'HUD Final Signed Packet',
  hudSubmission: 'HUD Submission',
  submission: 'Submission',
};
export const COMPLIANCE_DOCUMENT_TYPES = {
  finalSignedPacket: 'Compliance Final Signed Packet',
  hudFinalSignedPacket: 'HUD Compliance Final Signed Packet',
  hudSubmission: 'HUD Compliance Submission',
  submission: 'Compliance Submission Packet',
};

export const COMPLIANCE_DOCUMENT_TYPES_SHORT_TO_KEY_MAP = {
  [COMPLIANCE_DOCUMENT_TYPES_SHORT.finalSignedPacket]: 'finalSignedPacket',
  [COMPLIANCE_DOCUMENT_TYPES_SHORT.hudFinalSignedPacket]:
    'hudFinalSignedPacket',
  [COMPLIANCE_DOCUMENT_TYPES_SHORT.hudSubmission]: 'hudSubmission',
  [COMPLIANCE_DOCUMENT_TYPES_SHORT.submission]: 'submission',
};

export const complianceDocumentTypeMessages = defineMessages({
  finalSignedPacket: {
    id: 'App.ComplianceDocumentType.finalSignedPacket',
    defaultMessage: COMPLIANCE_DOCUMENT_TYPES.finalSignedPacket,
  },
  hudFinalSignedPacket: {
    id: 'App.ComplianceDocumentType.hudFinalSignedPacket',
    defaultMessage: COMPLIANCE_DOCUMENT_TYPES.hudFinalSignedPacket,
  },
  hudSubmission: {
    id: 'App.ComplianceDocumentType.hudSubmission',
    defaultMessage: COMPLIANCE_DOCUMENT_TYPES.hudSubmission,
  },
  submission: {
    id: 'App.ComplianceDocumentType.submission',
    defaultMessage: COMPLIANCE_DOCUMENT_TYPES.submission,
  },
});
