import React, { Fragment } from 'react';
import { FormControl } from 'react-bootstrap';
import { isNil } from 'ramda';
import ElementWithPermissions from '../ElementWithPermissions';
import type { DropdownOption } from '../../containers/App/types';

type Props = {
  items: Array<DropdownOption>,
  changeFunction: Function,
  initialValue: string,
  scope?: Array<string>,
  disabled?: boolean,
};

const ManageFormsDropDown = ({
  items,
  changeFunction,
  initialValue,
  scope,
  disabled,
}: Props) => {
  const dropdown = (
    <FormControl
      componentClass="select"
      onChange={changeFunction}
      value={initialValue}
      disabled={disabled}
    >
      {items
        .filter((item) => !isNil(item))
        .map((item, i) => (
          <option key={i} value={item.value} disabled={item.disabled}>
            {item.text}
          </option>
        ))}
    </FormControl>
  );
  return (
    <Fragment>
      {scope ? (
        <ElementWithPermissions scope={scope}>
          {dropdown}
        </ElementWithPermissions>
      ) : (
        dropdown
      )}
    </Fragment>
  );
};

export default ManageFormsDropDown;
