import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseChargesOrCredits.EditChargeModal.header',
    defaultMessage: 'Warning!',
  },
  bodyEdit: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseChargesOrCredits.EditChargeModal.bodyEdit',
    defaultMessage:
      // eslint-disable-next-line max-len
      'This scheduled lease charge is connected to a future Prorate. Changes to this Scheduled charge may affect the amount and frequency of the renewal lease. Do you want to continue?',
  },
  bodyAdd: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseChargesOrCredits.EditChargeModal.bodyAdd',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Renewal Prorates have been set for this household. Adding a new charge will not affect the prorate amount previously determined. Do you want to continue?',
  },
  yes: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseChargesOrCredits.EditChargeModal.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseChargesOrCredits.EditChargeModal.no',
    defaultMessage: 'No',
  },
});

export default messages;
