import { getWorkNumberIntegrationFromList } from '../../containers/ViewProperty/IntegrationsSetup/utils';

export const extractIntegrationsFromCurrentUser = (currentUser) => {
  return currentUser?.user?.organization?.integrations ?? [];
};

export const extractIntegrationsFromSelectedProperty = (selectedProperty) => {
  return selectedProperty?.integrations ?? [];
};

export const isAppCompleted = (ssn, arn) => {
  return ssn || arn;
};

export const checkDocumentExists = (paystubRequestResponse = {}) => {
  // return false;
  const documentSize = paystubRequestResponse?.size ?? 0;
  return documentSize > 0 ? true : false;
};

export const isWorkNumberShown = (
  organizationIntegrations,
  propertyIntegrations,
) => {
  const organizationWorkNumberIntegration = getWorkNumberIntegrationFromList(
    organizationIntegrations,
  );
  const propertyWorkNumberIntegration =
    getWorkNumberIntegrationFromList(propertyIntegrations);

  const organizationEnabled =
    organizationWorkNumberIntegration?.isActive ?? false;
  const propertyEnabled = propertyWorkNumberIntegration?.isActive ?? false;

  return organizationEnabled && propertyEnabled;
};

export default {
  extractIntegrationsFromCurrentUser,
  extractIntegrationsFromSelectedProperty,
  isWorkNumberShown,
  isAppCompleted,
  checkDocumentExists,
};
