import ReportsService from '../../services/reportService';
import FileSaver from 'file-saver';
import moment from 'moment';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import messages from './messages';
import { LEASE_CHANGE_OUT_REF_ID } from '../../components/CreateReportModal/constants';

const getFileNameForLeaseChangeOut = (
  reportName,
  propertyName,
  startDate,
  endDate,
  fileExtension,
) => {
  return `${reportName}_${propertyName}_From_${moment(startDate).format(
    'YYYY-MM-DD',
  )}_to_${moment(endDate).format('YYYY-MM-DD')}.${fileExtension}`;
};

const getStandardFileName = (reportName, propertyName, fileExtension) => {
  return `${reportName}_${propertyName}_${moment().format(
    'YYYY-MM-DD',
  )}.${fileExtension}`;
};

export const generateReport = async (
  payload,
  setGeneratedReport,
  promptToaster,
) => {
  const {
    organizationId,
    property,
    startDate,
    endDate,
    period,
    id,
    format,
    report,
    date,
    startPeriod,
    endPeriod,
    scheduledPeriod,
    actualPeriod,
    currentPeriod,
    futurePeriod,
    customDateField,
    leaseType,
  } = payload;

  try {
    const propertyId = property?.id ?? '';
    const propertyName = property?.name ?? '';
    const reportService = new ReportsService();
    let fileExtension = format;
    let callback;
    let query = '';

    if (
      ['Delinquent-Prepaid', 'Delinquent-Prepaid-Collection-Notes'].includes(
        report.referenceId,
      )
    ) {
      query = 'fieldName=unitNumber&order=ASC';
    }
    switch (format) {
      case 'zip-xml':
        if (
          ['Affordable-State-Report', 'Affordable-XML'].includes(
            report.referenceId,
          )
        ) {
          callback = reportService.downloadAffordableStateReport;
          const propertyState = property?.physicalState ?? '';
          fileExtension = 'xml';
          if (propertyState === 'US-TX') {
            fileExtension = 'zip';
          }
        }
        if (['MINC-Tenant-Transactions'].includes(report.referenceId)) {
          callback = reportService.downloadMINCTenantTransactionsReport;
          fileExtension = 'xml';
        }
        break;
      case 'scheduleA':
        callback = reportService.downloadScheduleAReport;
        fileExtension = 'pdf';
        break;
      case 'pdf':
        callback = reportService.downloadPDFReport;
        break;
      case 'csv':
        callback = reportService.downloadCSVReport;
        break;
      default:
        callback = () => {};
    }
    const xls = await callback(
      organizationId,
      propertyId,
      id,
      startDate,
      endDate,
      period,
      date,
      startPeriod,
      endPeriod,
      scheduledPeriod,
      actualPeriod,
      currentPeriod,
      futurePeriod,
      customDateField,
      leaseType,
      query,
    );
    const fileName =
      report.referenceId === LEASE_CHANGE_OUT_REF_ID
        ? getFileNameForLeaseChangeOut(
            report.name,
            propertyName,
            startDate,
            endDate,
            fileExtension,
          )
        : getStandardFileName(report.name, propertyName, fileExtension);
    FileSaver.saveAs(xls, fileName);
    setGeneratedReport(xls);
    promptToaster({
      type: 'success',
      message: 'Report Created.',
      title: 'Success',
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    });
  } catch (err) {
    promptToaster({
      type: 'info',
      message: 'There is currently no data to report',
      title: 'No data found!',
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    });
  }
};

export const downloadLastReport = async (
  propertyId,
  organizationId,
  report,
  promptToaster,
) => {
  try {
    const { filename } = report;
    const reportService = new ReportsService();
    const response = await reportService.download(
      organizationId,
      report.id,
      propertyId,
    );
    const sanitizedFilename = (filename || '').replace(/^reports\//, '');
    FileSaver.saveAs(response, sanitizedFilename);
  } catch {
    promptToaster({
      type: 'error',
      message: renderTranslatedMessage(messages.failedDocumentDownload),
      title: 'Error while downloading',
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    });
  }
};

export const getMonthEndCloseZipFile = async (
  property,
  period,
  fileType,
  promptToaster,
) => {
  try {
    const { organizationId } = property;
    const propertyId = property?.id;
    const reportService = new ReportsService();

    const zippedFile = await reportService.downloadZipFileForMonthEndClose(
      organizationId,
      propertyId,
      fileType,
      period,
    );

    const fileName =
      fileType === 'csv'
        ? `AME_reports_${period}_${fileType}.zip`
        : `AME_reports_${period}.pdf`;

    FileSaver.saveAs(zippedFile, fileName);
  } catch (error) {
    promptToaster({
      type: 'info',
      message: 'There is currently no data to report',
      title: 'No data found!',
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    });
  }
};
