import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import LegalDocumentPageLayout from '../../components/LegalDocumentPageLayout';
import {
  BigBreak,
  SectionTitle,
  RegularText,
} from '../../components/LegalDocumentPageLayout/TextHelpers';
import { LINKS } from './constants';

const infoWeCollectLeftColStyle = {
  paddingTop: 18,
  maxWidth: 300,
  width: 300,
};

const infoWeMayShareLeftColStyle = {
  minWidth: 300,
  maxWidth: 300,
};

function PrivacyPolicy() {
  return (
    <DocumentTitle title="Fortress - Privacy Policy">
      <LegalDocumentPageLayout links={LINKS}>
        <section id="overview">
          <SectionTitle>Last Updated: July 7, 2022</SectionTitle>
          <BigBreak />
          <RegularText>
            Fortress Technology Solutions, Inc. (“Fortress”, “we”, “us”, “our”)
            recognizes the importance of protecting the privacy of individuals
            (referred to as “you” or “your” in this Privacy Policy).
            <BigBreak />
            This Privacy Policy applies to personal information we may collect
            through the below (collectively the “Services”):
          </RegularText>
          <ul style={{ paddingLeft: 80 }}>
            <li>
              <RegularText>Websites;</RegularText>
            </li>
            <li>
              <RegularText>Mobile applications;</RegularText>
            </li>
            <li>
              <RegularText>
                Other platforms or services that we own, operate, or make
                available that link to this Privacy Policy; and
              </RegularText>
            </li>
            <li>
              <RegularText>
                All other general inquiries or communications we may receive
                (including, but not limited to, postal mail, electronic mail,
                and telephone communications).
              </RegularText>
            </li>
          </ul>
          <br />
          <RegularText>
            Please note, if you elect to engage in additional registrations or
            services offered by Fortress, you may be asked to agree to
            additional terms regarding our use of your personal information.
            <BigBreak />
            We encourage you to review this Privacy Policy and our [
            <Link to="termsofuse">Terms of Use</Link>] prior to use our Services
            or sharing personal information with Fortress.
            <BigBreak />
            This Privacy Policy outlines what personal information we may
            collect, how we may use it, and for what reasons we may share your
            personal information.
          </RegularText>
        </section>
        <BigBreak />
        <section id="information-we-collect-and-why">
          <SectionTitle>Information We Collect and Why</SectionTitle>
          <br />
          <Stack>
            <Stack direction="row">
              <RegularText style={infoWeCollectLeftColStyle}>
                <b>Contact Information</b>
                <br />
                Ex.: name; physical address; email address; phone number
              </RegularText>
              <ul>
                <li>
                  <RegularText>
                    Providing and improving our Services to you
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Analyzing our Services to improve quality or safety features
                    of our Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Supporting our internal operations such as fraud detection
                    and technical support
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Enforcing our [<Link to="termsofuse">Terms of Use</Link>]
                    and protecting Fortress as permitted by applicable law
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Marketing purposes and responding to questions regarding our
                    Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>Complying with applicable law</RegularText>
                </li>
              </ul>
            </Stack>
            <Stack direction="row">
              <RegularText style={infoWeCollectLeftColStyle}>
                <b>Financial Information</b>
                <br />
                Ex.: banking information; credit score; financial history
                information
              </RegularText>
              <ul>
                <li>
                  <RegularText>
                    Providing and improving our Services to you
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Analyzing our Services to improve quality or safety features
                    of our Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Supporting our internal operations such as fraud detection
                    and technical support
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Enforcing our [<Link to="termsofuse">Terms of Use</Link>]
                    and protecting Fortress as permitted by applicable law
                  </RegularText>
                </li>
                <li>
                  <RegularText>Complying with applicable law</RegularText>
                </li>
              </ul>
            </Stack>
            <Stack direction="row">
              <RegularText style={infoWeCollectLeftColStyle}>
                <b>Internet / Electronic Activity</b>
                <br />
                Ex.: IP address; browsing history; website navigation and clicks
              </RegularText>
              <ul>
                <li>
                  <RegularText>
                    Providing and improving our Services to you
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Analyzing our Services to improve quality or safety features
                    of our Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Supporting our internal operations such as fraud detection
                    and technical support
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Enforcing our [<Link to="termsofuse">Terms of Use</Link>]
                    and protecting Fortress as permitted by applicable law
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Marketing purposes and responding to questions regarding our
                    Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>Complying with applicable law</RegularText>
                </li>
              </ul>
            </Stack>
            <Stack direction="row">
              <RegularText style={infoWeCollectLeftColStyle}>
                <b>Employment / Professional Information</b>
                <br />
                Ex.: employer; job title; education level; background check;
                income
              </RegularText>
              <ul>
                <li>
                  <RegularText>
                    Employment verification and screening
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Providing and improving our Services to you
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Analyzing our Services to improve quality or safety features
                    of our Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Supporting our internal operations such as fraud detection
                    and technical support
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Enforcing our [<Link to="termsofuse">Terms of Use</Link>]
                    and protecting Fortress as permitted by applicable law
                  </RegularText>
                </li>
                <li>
                  <RegularText>Complying with applicable law</RegularText>
                </li>
              </ul>
            </Stack>
            <Stack direction="row">
              <RegularText style={infoWeCollectLeftColStyle}>
                <b>Demographic Information</b>
                <br />
                Ex.: date of birth; marital status; veteran/military status;
                household information (including pets and children where
                applicable); race; gender; ethnicity
              </RegularText>
              <ul>
                <li>
                  <RegularText>
                    Providing and improving our Services to you
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Analyzing our Services to improve quality or safety features
                    of our Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Supporting our internal operations such as fraud detection
                    and technical support
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Enforcing our [<Link to="termsofuse">Terms of Use</Link>]
                    and protecting Fortress as permitted by applicable law
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Marketing purposes and responding to questions regarding our
                    Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>Complying with applicable law</RegularText>
                </li>
              </ul>
            </Stack>
            <Stack direction="row">
              <RegularText style={infoWeCollectLeftColStyle}>
                <b>Personal Preferences</b>
                <br />
                Ex.: marketing preferences; purchase history
              </RegularText>
              <ul>
                <li>
                  <RegularText>
                    Providing and improving our Services to you
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Analyzing our Services to improve quality or safety features
                    of our Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Marketing purposes and responding to questions regarding our
                    Services
                  </RegularText>
                </li>
              </ul>
            </Stack>
            <Stack direction="row">
              <RegularText style={infoWeCollectLeftColStyle}>
                <b>Operational Information</b>
                <br />
                Ex.: transactional information; credentials to Services;
                customer service interactions
              </RegularText>
              <ul>
                <li>
                  <RegularText>
                    Providing and improving our Services to you
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Analyzing our Services to improve quality or safety features
                    of our Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Supporting our internal operations such as fraud detection
                    and technical support
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Enforcing our [<Link to="termsofuse">Terms of Use</Link>]
                    and protecting Fortress as permitted by applicable law
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Marketing purposes and responding to questions regarding our
                    Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>Complying with applicable law</RegularText>
                </li>
              </ul>
            </Stack>
            <Stack direction="row">
              <RegularText style={infoWeCollectLeftColStyle}>
                <b>Social Interaction Information</b>
                <br />
                Ex.: voluntary service responses; voluntary interactions with
                social media platforms/applications
              </RegularText>
              <ul>
                <li>
                  <RegularText>
                    Providing and improving our Services to you
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Analyzing our Services to improve quality or safety features
                    of our Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Marketing purposes and responding to questions regarding our
                    Services
                  </RegularText>
                </li>
              </ul>
            </Stack>
            <Stack direction="row">
              <RegularText style={infoWeCollectLeftColStyle}>
                <b>Other Identifying Information</b>
                <br />
                Ex.: lease/rental information; social security number; driver’s
                license number/image; passport number; taxpayer identification
                number; prior address(es); vehicle information
              </RegularText>
              <ul>
                <li>
                  <RegularText>
                    Providing and improving our Services to you
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Analyzing our Services to improve quality or safety features
                    of our Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Supporting our internal operations such as fraud detection
                    and technical support
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Enforcing our [<Link to="termsofuse">Terms of Use</Link>]
                    and protecting Fortress as permitted by applicable law
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Marketing purposes and responding to questions regarding our
                    Services
                  </RegularText>
                </li>
                <li>
                  <RegularText>Complying with applicable law</RegularText>
                </li>
              </ul>
            </Stack>
          </Stack>
        </section>
        <BigBreak />
        <section id="information-we-may-share">
          <SectionTitle>Information We May Share</SectionTitle>
          <br />
          <RegularText>
            Fortress will not share your personal information with external
            parties except as described below, as otherwise provided in this
            Privacy Policy, as otherwise agreed by you (for example, if you
            expressly consent to a disclosure) or as required or permitted by
            law.
          </RegularText>
          <br />
          <Stack sx={{ paddingLeft: 10 }}>
            <Stack direction="row">
              <b style={infoWeMayShareLeftColStyle}>
                Service Providers / Vendors
              </b>
              <RegularText>
                Fortress may share information with our service providers and
                other vendors providing certain services to or on behalf of
                Fortress.
              </RegularText>
            </Stack>
            <br />
            <Stack direction="row">
              <b style={infoWeMayShareLeftColStyle}>Corporate Transaction</b>
              <RegularText>
                If Fortress merges with, acquires, or is sold, or in the event
                of another transfer of Fortress assets such as bankruptcy,
                Fortress may share or transfer your information in connection
                with such an occurrence.
              </RegularText>
            </Stack>
            <br />
            <Stack direction="row">
              <b style={infoWeMayShareLeftColStyle}>
                Legal and Regulatory Enforcement
              </b>
              <RegularText>
                From time to time, it may be necessary for Fortress to disclose
                information in order to comply with a: legal or regulatory
                request; reporting obligations; request from a governmental
                authority; to cooperate with law enforcement; and/or for other
                legal reasons.
              </RegularText>
            </Stack>
            <br />
            <Stack direction="row">
              <b style={infoWeMayShareLeftColStyle}>
                Enforcement of Our Rights or Rights of Others
              </b>
              <RegularText>
                Fortress may disclose information, including your information,
                to external parties to the extent necessary to enforce or
                protect our rights, privacy, Services, or property, and/or that
                of others, which includes enforcing our [
                <Link to="termsofuse">Terms of Use</Link>] and any other
                agreements in relation to our Services.
              </RegularText>
            </Stack>
          </Stack>
        </section>
        <BigBreak />
        <section id="fortress-services-with-partners-and-third-party-links-and-services">
          <SectionTitle>
            Fortress Services with Partners and Third-Party Links and Services
          </SectionTitle>
          <br />
          <ul style={{ paddingLeft: 90 }}>
            <li>
              <RegularText>
                <b>Fortress Services with Fortress Clients:</b>
              </RegularText>
              <ul>
                <li>
                  <RegularText>
                    Fortress may partner with and provide our Services to
                    property managers, commercial landlords, and others
                    operating in a similar manner in regard to rental
                    residential and commercial real estate properties
                    (collectively, “Fortress Clients”). Fortress may process or
                    interact with personal information uploaded to our Services
                    on behalf of Fortress Clients as a service provider to
                    Fortress Clients. In such case, Fortress may collect,
                    receive and disclose the information in accordance with its
                    agreements with Fortress Clients. Those Fortress Clients are
                    responsible for information practices concerning any
                    personal information you may provide to us or our clients.
                    If you have any questions or concerns about the information
                    handling practices of a Fortress Client using our Services,
                    please contact the Fortress Client directly.
                  </RegularText>
                </li>
                <li>
                  <RegularText>
                    Fortress may also partner with third-party service providers
                    to provide you with certain opportunities to engage in other
                    services and you may have the option to use the websites and
                    applications of other third parties.
                  </RegularText>
                </li>
              </ul>
            </li>
            <li>
              <RegularText>
                <b>Third-Party Links and Services:</b>
              </RegularText>
              <ul>
                <li>
                  <RegularText>
                    Fortress may also partner with third-party service providers
                    to provide you with certain opportunities to engage in other
                    services and you may have the option to use the websites and
                    applications of other third parties.
                  </RegularText>
                </li>
              </ul>
            </li>
          </ul>
          <RegularText>
            Fortress does not control the websites, services, or applications
            that may be offered by third parties and is not responsible for the
            content that may be present or how your personal information may be
            handled by other third parties. Our Privacy Policy applies to your
            interactions with our Services and does not extend to your
            interactions with websites, services, or applications offered by
            other third parties. We encourage you to read the Privacy Policy or
            inquire as to how other third parties may handle your personal
            information before interacting with other third parties.
          </RegularText>
        </section>
        <BigBreak />
        <section id="security">
          <SectionTitle>Security</SectionTitle>
          <br />
          <RegularText>
            Fortress endeavors to take commercially reasonable technical and
            organizational measures in accordance with applicable law to protect
            and safeguard information. Fortress undertakes these measures for
            security reasons, however, we cannot guarantee with 100% certainty
            that any information provided over an internet connection will
            remain secure, so any information you may provide over the internet
            is provided at your own risk.
          </RegularText>
        </section>
        <BigBreak />
        <section id="use-of-our-services-by-children">
          <SectionTitle>Use of Our Services by Children</SectionTitle>
          <br />
          <RegularText>
            Our Services are not directed toward children. By using any of our
            Services, you represent that you are not under sixteen (16) years of
            age. We do not knowingly collect, process, or otherwise maintain
            personal information for children under the age of sixteen (16)
            without authorization by a verified guardian. If you believe that we
            may have inadvertently collected personal information from or about
            a child under the age of sixteen (16), please send us an email at
            [PLACEHOLDER] or call us at [PLACEHOLDER].
          </RegularText>
        </section>
        <BigBreak />
        <section id="consent">
          <SectionTitle>Consent</SectionTitle>
          <br />
          <RegularText>
            If you provide any contact information as described above in
            registering for or requesting more information about any of our
            Services, your provision of your contact information constitutes
            your affirmative consent that Fortress may contact you by email,
            text message, or telephone to provide you with information and
            notices regarding our Services or regarding any updates to this
            Privacy Policy.
            <BigBreak />
            You may choose to opt-out of future commercial email messages from
            us by following the directions on the link provided in any
            commercial message we may send you.
          </RegularText>
        </section>
        <BigBreak />
        <section id="do-not-track">
          <SectionTitle>Do Not Track</SectionTitle>
          <br />
          <RegularText>
            A “Do Not Track” signal or preference setting in your web browser
            may let the operator of a website you visit know that you do not
            want that website operator collecting certain information about you
            during your visit to the website. Fortress does not currently
            respond to, or honor, any “Do Not Track” signal, request, or
            preference setting that you may have enabled or otherwise elected on
            your web browser. More information about “Do Not Track” is available
            at: <a href="https://allaboutdnt.com/">https://allaboutdnt.com/</a>.
          </RegularText>
        </section>
        <BigBreak />
        <section id="california-privacy-rights">
          <SectionTitle>California Privacy Rights</SectionTitle>
          <br />
          <RegularText>
            California residents are entitled to contact us to request
            information about whether we have disclosed Personal Information, as
            defined under California law, to third parties for the third
            parties' direct marketing purposes. We do not share Personal
            Information with third parties for their own marketing purposes,
            however. California customers may request further information about
            our compliance with this law by contacting us.
            <BigBreak />
            The rights above are separate from rights that consumers may have
            under the California Consumer Privacy Act. Please note that, at this
            time, we do not meet the thresholds to be considered a “business” as
            defined by the California Consumer Privacy Act. We are, therefore,
            not currently subject to that law. Nonetheless, we respect your
            privacy rights, and you can find information about how Fortress may
            interact with your personal information in this Privacy Policy.
          </RegularText>
        </section>
        <BigBreak />
        <section id="international-users-of-fortress-services">
          <SectionTitle>International Users of Fortress Services</SectionTitle>
          <br />
          <RegularText>
            Our Services are not directed toward individuals outside of the
            United States. If you provide information to us when using our
            Services, it may be transferred to, processed, or stored in the
            United States or other countries with different laws and protections
            governing the handling of personal information than the country you
            reside in. You should not use our Services if you are not a resident
            of the United States, and your use of our Services constitutes
            affirmative consent to have your personal information transferred
            to, processed, or stored in the United States or other countries.
          </RegularText>
        </section>
        <BigBreak />
        <section id="privacy-policy-updates">
          <SectionTitle>Privacy Policy Updates</SectionTitle>
          <br />
          <RegularText>
            We may make changes to this Privacy Policy from time-to-time or as
            otherwise required by law. Any such changes will be effective
            immediately upon posting and any personal information of yours that
            we may interact with during your use of our Services will be treated
            in accordance with the most recent version of our posted Privacy
            Policy.
          </RegularText>
        </section>
        <BigBreak />
        <section id="contact-us">
          <SectionTitle>Contact Us</SectionTitle>
          <br />
          <RegularText>
            If you have any questions regarding this Privacy Policy or about our
            practices in regard to personal information we may interact with
            when you use our Services, please feel free to contact us at:
            <BigBreak />
            <b>Fortress Technology Solutions, Inc.</b>
            <br />
            188 16th Ave. South
            <br />
            Suite 200
            <br />
            Nashville, TN 37203
            <BigBreak />
            Email: <a>privacy@fortresstech.net</a>
            <br />
            Phone Number: <a>615-490-6727</a>
          </RegularText>
        </section>
      </LegalDocumentPageLayout>
    </DocumentTitle>
  );
}

export default PrivacyPolicy;
