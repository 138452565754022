const { post } = require('../utils/api');

export default class IntacctAccountBalancesService {
  get(
    organizationId: string,
    propertyId: string,
    payload: Object,
    signal?: Object,
  ) {
    const options = signal ? { signal } : {};
    return post(
      `/${organizationId}/${propertyId}/intacct/account-balances`,
      JSON.stringify(payload),
      options,
    );
  }
}
