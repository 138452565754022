export const generateFiscalPeriodsOption = (
  fiscalPeriods,
  fiscalPeriodsLoading,
) => {
  const fiscalPeriodsOptions = [{ value: '', text: 'Choose', disabled: true }];
  if (!fiscalPeriodsLoading && fiscalPeriods && fiscalPeriods.length) {
    fiscalPeriods.slice(0, 12).map((i) =>
      fiscalPeriodsOptions.push({
        value: i.period,
        text: i.period.replace(/(\d{4})(\d{2})/, '$1-$2'),
        disabled: false,
      }),
    );
  }
  return fiscalPeriodsOptions;
};
