import { createSelector } from 'reselect';
import * as R from 'ramda';
import type { Property } from '../App/types';

const getAllCommunications = (state: any) => state.viewCommunication;

const getCorrespondingMethod = (communicationType: string) => {
  switch (communicationType) {
    case 'EMAIL':
      return 'Email';
    case 'SMS':
      return 'SMS';
    default:
      return 'Letter';
  }
};

const getCurrentStatus = (customerStatus: string) => {
  switch (customerStatus) {
    case 'Error':
      return { name: 'ERROR', color: 'status-tag--red-light' };
    case 'Scheduled':
      return { name: 'SCHEDULED', color: 'status-tag--orange-light' };
    case 'InProcess':
      return { name: 'IN PROGRESS', color: 'status-tag--orange-light' };
    case 'Partial':
      return { name: 'PARTIAL', color: 'status-tag--orange-light' };
    default:
      return { name: 'SUCCESSFUL', color: 'status-tag--green-light' };
  }
};

export const getCommunicationStatuses = () => {
  const communicationStatus: any = [
    {
      value: 'InProcess',
      text: 'In Process',
      enabled: true,
    },
    {
      value: 'Scheduled',
      text: 'Scheduled',
      enabled: false,
    },
    {
      value: 'Error',
      text: 'Error',
      enabled: true,
    },
    {
      value: 'Completed',
      text: 'Successful',
      enabled: true,
    },
    {
      value: 'Partial',
      text: 'Partial',
      enabled: true,
    },
  ];
  return communicationStatus.filter((cs) => cs.enabled);
};
export const getCommunicationTypes = () => {
  const communicationTypes: any = [
    {
      value: 'EMAIL',
      text: 'Email',
      enabled: true,
    },
    {
      value: 'SMS',
      text: 'SMS',
      enabled: true,
    },
    {
      value: 'LETTER',
      text: 'Letter',
      enabled: true,
    },
  ];
  return communicationTypes.filter((ct) => ct.enabled);
};
export const getCustomerTypes = (selectedProperty: Property) => {
  const isAllCommercial =
    selectedProperty && selectedProperty.hasCommercialFloorPlans === 'ALL';
  const customerTypes: any = [
    {
      value: 'Prospect',
      text: 'Prospect',
      enabled: false,
    },
    {
      value: 'Applicant',
      text: 'Applicant',
      enabled: false,
    },
    {
      value: 'Current Resident',
      text: isAllCommercial ? 'Current Tenant' : 'Current Resident',
      enabled: true,
    },
    {
      value: 'Prior Resident',
      text: 'Prior Resident',
      enabled: false,
    },
  ];
  return customerTypes.filter((ct) => ct.enabled);
};

const getCommunicationTitle = (communication: Object): string => {
  const commType = communication.communicationType.toLowerCase();

  if (commType === 'letter')
    return R.pathOr(
      '---',
      ['commData', 'letterTemplate', 'name'],
      communication,
    );
  if (commType === 'email')
    return R.pathOr('---', ['commData', 'title'], communication);
  if (commType === 'sms')
    return R.pathOr('---', ['commData', 'smsTitle'], communication);

  return '---';
};

export const getCommunications = createSelector(
  getAllCommunications,
  (state: any): any[] => {
    if (!state.communications) return [];
    return state.communications.map((communication) => {
      return {
        communicationType: getCorrespondingMethod(
          communication.communicationType,
        ),
        customerStatus: communication.customerStatus,
        createdAt: communication.createdAt,
        headerId: communication.id,
        communicationLetterId: communication.communicationContentId,
        status: getCurrentStatus(communication.status),
        title: getCommunicationTitle(communication),
      };
    });
  },
);
