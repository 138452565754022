import type { Action } from '../App/types';
import type { ResetPasswordState } from './types';
import * as ActionTypes from './constants';

const initialState: ResetPasswordState = {
  token: '',
  password: '',
  confirmPassword: '',
  showSuccessMessage: false,
  showErrorMessage: false,
  verifyTokenSuccess: false,
  errorMessage: '',
};

const resetPasswordReducer = (
  state: ResetPasswordState = initialState,
  action: Action<*, *>
): ResetPasswordState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.VERIFY_TOKEN_SUCCESS: {
      return { ...state, verifyTokenSuccess: true, showErrorMessage: false };
    }
    case ActionTypes.VERIFY_TOKEN_FAIL: {
      return {
        ...state,
        showErrorMessage: true,
        errorMessage: payload.toString(),
        showSuccessMessage: false
      };
    }
    case ActionTypes.RESET_PASSWORD_SUCCESS: {
      return { ...state, showSuccessMessage: true, showErrorMessage: false };
    }
    case ActionTypes.RESET_PASSWORD_FAIL: {
      return {
        ...state,
        showErrorMessage: true,
        errorMessage: payload.toString(),
        showSuccessMessage: false
      };
    }
    default:
      return state;
  }
};

export default resetPasswordReducer;
