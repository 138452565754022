// $FlowFixMe
import React, { useState } from 'react';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import Adults from './Adults';
import Minors from './Minors';
import Pets from './Pets';
import deleteConfirmation from '../../../components/DeleteConfirmation';
import confirm from '../../../components/ConfirmDialogModal';

const deleteConfirmMessage =
  'Clicking Yes will Remove this Member from the Household.' +
  ' Are you sure you want to Remove this Member from the Household?';

const EditResidentHousehold = (props: any) => {
  const [prevPrimaryId, setPrevPrimaryId] = useState('');
  const [prevPrimaryIndex, setPrevPrimaryIndex] = useState('adults[0]');

  const addMinor = () => {
    props.array.push('minors', {
      suffixId: 'default',
      relationshipId: 'default',
      affordableRelationshipId: 'default',
    });
  };

  const addAdult = () => {
    props.array.push('adults', {
      suffix: 'default',
      relationshipId: 'default',
      type: 'default',
      affordableRelationshipId: 'default',
    });
  };

  const deleteMinor = (index: number) => {
    deleteConfirmation(deleteConfirmMessage).then(() =>
      props.array.remove('minors', index),
    );
  };

  const addPet = () => {
    props.array.push('pets', {
      petTypeId: 'default',
      petBreedId: 'default',
    });
  };

  const deletePet = (index: number) => {
    deleteConfirmation(deleteConfirmMessage).then(() =>
      props.array.remove('pets', index),
    );
  };

  const deletePendingPet = (index: number) => {
    deleteConfirmation(deleteConfirmMessage).then(() =>
      props.array.remove('pendingPets', index),
    );
  };

  const deleteAdult = (index: number) => {
    deleteConfirmation(deleteConfirmMessage).then(() =>
      props.array.remove('adults', index),
    );
  };

  const onPrimaryChange = (
    newPrimaryResidentId: string,
    field: string,
    hohAffRelId: string,
  ) => {
    confirm('Are you sure you want to change the Primary Resident?', {
      body: 'All data trails and financial transactions will be updated to the new primary resident',
    }).then(() => {
      setPrevPrimaryId(props.primaryResidentId);
      props.dispatch(
        change(
          'editResidentHousehold',
          'primaryResidentId',
          newPrimaryResidentId,
        ),
      );
      props.dispatch(
        change('editResidentHousehold', `${field}.relationshipId`, 'default'),
      );
      props.dispatch(
        change(
          'editResidentHousehold',
          `${field}.affordableRelationshipId`,
          hohAffRelId,
        ),
      );

      props.dispatch(
        change(
          'editResidentHousehold',
          `${prevPrimaryIndex}.affordableRelationshipId`,
          'default',
        ),
      );
      setPrevPrimaryIndex(field);
    });
  };

  const { leaseLocked, householdHasOpenCerts } = props;

  return (
    <div className="form-container container-fluid">
      <div className="applicant-convert-form">
        <Adults
          deleteAdult={deleteAdult}
          addAdult={addAdult}
          primaryResidentId={props.primaryResidentId}
          prevPrimaryId={prevPrimaryId}
          onPrimaryChange={onPrimaryChange}
          leaseLocked={leaseLocked}
          householdHasOpenCerts={householdHasOpenCerts}
          change={(field: string, value: string) =>
            props.dispatch(change('editResidentHousehold', field, value))
          }
        />
        <Minors
          addMinor={addMinor}
          deleteMinor={deleteMinor}
          leaseLocked={leaseLocked}
        />
        <Pets
          addPet={addPet}
          deletePet={deletePet}
          deletePendingPet={deletePendingPet}
          leaseLocked={leaseLocked}
        />
      </div>
    </div>
  );
};

const ReduxFormEditResidentHousehold = reduxForm({
  form: 'editResidentHousehold',
})(EditResidentHousehold);

const selector = formValueSelector('editResidentHousehold');
// $FlowFixMe
const EditResidentHouseholdForm = connect((state) => ({
  primaryResidentId: selector(state, 'primaryResidentId'),
}))(ReduxFormEditResidentHousehold);

export default EditResidentHouseholdForm;
