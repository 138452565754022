import { CREATE_VEHICLE, UPDATE_VEHICLE, DELETE_VEHICLE } from './constants';
import type {
  CreateVehiclePayload,
  UpdateVehiclePayload,
  DeleteVehiclePayload,
} from './types';
import type { Action } from '../../containers/App/types';

export function createVehicle(payload: CreateVehiclePayload): Action<any> {
  return {
    type: CREATE_VEHICLE,
    payload,
  };
}

export function updateVehicle(payload: UpdateVehiclePayload): Action<any> {
  return {
    type: UPDATE_VEHICLE,
    payload,
  };
}

export function deleteVehicle(payload: DeleteVehiclePayload): Action<any> {
  return {
    type: DELETE_VEHICLE,
    payload,
  };
}
