import { defineMessages } from 'react-intl';

const messages = defineMessages({
  quote: {
    id: 'App.ManageProspects.ActionsMenu.Quote',
    defaultMessage: 'Quote'
  },
  assign: {
    id: 'App.ManageProspects.ActionsMenu.Assign',
    defaultMessage: 'Assign'
  },
  unassign: {
    id: 'App.ManageProspects.ActionsMenu.Unassign',
    defaultMessage: 'Unassign'
  }
});

export default messages;
