import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import {
  Field,
  formValueSelector,
  getFormInitialValues,
  reduxForm,
} from 'redux-form';
import { confirmable, createConfirmation } from 'react-confirm';
import {
  concat,
  isEmpty,
  isNil,
  not,
  partial,
  path,
  pathOr,
  prop,
} from 'ramda';
import moment from 'moment';
import styled from 'styled-components';
import { Banner } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { toastr } from 'react-redux-toastr';

import ErrorMessage from '../../../components/ErrorMessage';

import {
  renderCheckboxField,
  renderDateField,
  renderSelectField,
  renderTextField,
} from '../../../utils/redux-form-helper';
import AddressSection from '../../../components/AddressSection';
import messages from './messages';
import validate from './validate';
import type { SelectOption } from '../../App/types';

type HeaderMessageProps = {
  unitAssigned: Object,
  hasOpenTransfer: any,
  hasOpenRenewal: boolean,
  intl: Object,
};

const Header = styled.h1`
  color: #d70000;
`;

const Text = styled.p`
  font-size: large;
  padding: 2rem 8rem;
`;

const HeaderMessage = ({
  intl,
  unitAssigned,
  hasOpenTransfer,
  hasOpenRenewal,
}: HeaderMessageProps) => {
  const moveInDate = pathOr(null, ['lease', 'moveInDate'], unitAssigned);
  let errorMessage = '';
  if (hasOpenTransfer) {
    errorMessage = intl.formatMessage(messages.currentResidentUnderTransfer);
  } else if (hasOpenRenewal) {
    errorMessage = intl.formatMessage(messages.currentResidentInRenewal);
  } else {
    if (unitAssigned == null) return null;
    errorMessage = moveInDate
      ? intl.formatMessage(messages.unitAssignedWarning, {
          date: intl.formatDate(moveInDate),
        })
      : intl.formatMessage(messages.unitAssignedNoLeaseWarning);
  }

  return (
    <Row>
      <ErrorMessage type="warning" message={errorMessage} />
    </Row>
  );
};

type Props = {
  store: Object,
  intl: Object,
  show: boolean,
  proceed: Function,
  dismiss: Function,
  pristine: boolean,
  invalid: boolean,
  residentName: string,
  deleteDisabled: boolean,
  states: Array<SelectOption>,
  moveOutReasons: Array<SelectOption>,
  noForwardingAddress: boolean,
  isForeignAddress: boolean,
  turnMoveOutDays: number,
  moveInReadyDate: Date,
  forwardingAddressRequired: boolean,
  unit: Object,
  moveInDateUpdated: boolean,
  change: Function,
  untouch: Function,
  openFiscalPeriod: Object,
  lease: Object,
  isFromFAS: boolean,
  propertyHasHUD: boolean,
  housingSituations: Array<SelectOption>,
  isAffordableMixedProperty: boolean,
  isUnitAffordableConventional: boolean,
  propertyClassType: string,
  state: string,
};

type State = {
  showDeleteConfirmation: boolean,
  showCancelConfirmation: boolean,
  showSaveConfirmation: boolean,
  showHousingSituation: boolean,
  showEstateOfWarning: boolean,
};

export class NoticeToVacateModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      isAffordableMixedProperty,
      isUnitAffordableConventional,
      initialValues = {},
      financiallyResponsibleHousehold,
    } = props;
    const { moveOutReason } = initialValues;
    this.state = {
      showDeleteConfirmation: false,
      showCancelConfirmation: false,
      showSaveConfirmation: false,
      showEstateOfWarning:
        ((moveOutReason?.type === 'Personal' &&
          moveOutReason?.reason === 'Death') ||
          (moveOutReason?.type === 'WA' &&
            moveOutReason?.reason === 'Passed Away')) &&
        financiallyResponsibleHousehold?.length === 1,
      showHousingSituation:
        (moveOutReason?.type ?? '') === 'WA' &&
        isAffordableMixedProperty &&
        isUnitAffordableConventional,
    };
  }

  componentDidMount() {
    window.onpopstate = (event) => {
      this.props.dismiss();
    };
  }

  deleteConfirmationToggle = (visible: boolean) => {
    this.setState({
      showDeleteConfirmation: visible,
    });
  };

  cancelConfirmationToggle = (visible: boolean) => {
    this.setState({
      showCancelConfirmation: visible,
    });
  };

  saveConfirmationToggle = (visible: boolean) => {
    this.setState({
      showSaveConfirmation: visible,
    });
  };

  setShowHousingSituation = (visible: boolean) => {
    this.setState({
      showHousingSituation: visible,
    });
  };

  setShowEstateOfWarning = (visible: boolean) => {
    this.setState({
      showEstateOfWarning: visible,
    });
  };

  confirmDelete = () => {
    this.props.change('toDelete', true);
    this.props.proceed();
  };

  save = async () => {
    const { change, proceed, financiallyResponsibleHousehold } = this.props;

    if (
      financiallyResponsibleHousehold?.length === 1 &&
      financiallyResponsibleHousehold?.[0]?.name?.length > 245
    ) {
      toastr.error(
        'Error',
        this.props.intl.formatMessage(messages.estateOfCharacterLimit),
      );
    }

    await change('toDelete', false);
    await proceed();
  };

  cancel = () => {
    if (this.state.showDeleteConfirmation || this.state.showCancelConfirmation)
      return;
    if (!this.props.pristine) {
      this.cancelConfirmationToggle(true);
    } else {
      this.props.dismiss();
    }
  };
  renderDeleteConfirmation = () => {
    return (
      <div className="modal-confirm">
        <h1>
          {this.props.intl.formatMessage(messages.deleteConfirmationHeader)}
        </h1>
        <p>
          {this.props.intl.formatMessage(messages.deleteConfirmationText, {
            noText: this.props.intl.formatMessage(messages.no),
          })}
        </p>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="primary"
            className="btn-delete pull-right"
            onClick={this.confirmDelete}
          >
            {this.props.intl.formatMessage(messages.yes)}
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="default"
            className="pull-left"
            onClick={() => this.deleteConfirmationToggle(false)}
          >
            {this.props.intl.formatMessage(messages.no)}
          </Button>
        </Col>
      </div>
    );
  };
  renderCancelConfirmation = () => {
    return (
      <div className="modal-confirm">
        <h1>
          {this.props.intl.formatMessage(messages.cancelConfirmationHeader)}
        </h1>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="primary"
            className="btn-delete pull-right"
            onClick={this.props.dismiss}
          >
            {this.props.intl.formatMessage(messages.yes)}
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="default"
            className="pull-left"
            onClick={() => this.cancelConfirmationToggle(false)}
          >
            {this.props.intl.formatMessage(messages.no)}
          </Button>
        </Col>
      </div>
    );
  };

  renderSaveConfirmation = () => (
    <div className="modal-confirm" style={{ padding: '2rem' }}>
      <Header>{this.props.intl.formatMessage(messages.headsUp)}</Header>
      <Text>{this.props.intl.formatMessage(messages.confirmationMessage)}</Text>

      <Col xs={12} sm={6}>
        <Button
          bsStyle="default"
          className="pull-right"
          onClick={() => this.saveConfirmationToggle(false)}
        >
          {this.props.intl.formatMessage(messages.no)}
        </Button>
      </Col>
      <Col xs={12} sm={6}>
        <Button bsStyle="primary" className=" pull-left" onClick={this.save}>
          {this.props.intl.formatMessage(messages.yes)}
        </Button>
      </Col>
    </div>
  );

  handleSaveClick = () => {
    const { lease } = this.props;
    if (lease.isRenewal) {
      this.saveConfirmationToggle(true);
    } else {
      this.save();
    }
  };

  handleNoForwardingAddress = (evt: Object) => {
    const { change, untouch } = this.props;
    if (evt.target.checked === true) {
      change('street', null);
      change('city', null);
      change('state', 'default');
      change('zipCode', null);
      change('foreignAddressLine1', null);
      change('foreignAddressLine2', null);
      change('foreignAddressLine3', null);
      change('isForeignAddress', false);
      untouch('street');
      untouch('city');
      untouch('state');
      untouch('zipCode');
      untouch('foreignAddressLine1');
      untouch('foreignAddressLine2');
      untouch('foreignAddressLine3');
    }
  };

  handleForeignAddress = (evt: Object) => {
    const { change, untouch } = this.props;
    if (evt.target.checked === false) {
      change('foreignAddressLine1', null);
      change('foreignAddressLine2', null);
      change('foreignAddressLine3', null);
      untouch('foreignAddressLine1');
      untouch('foreignAddressLine2');
      untouch('foreignAddressLine3');
    } else {
      change('street', null);
      change('city', null);
      change('state', 'default');
      change('zipCode', null);
      change('noForwardingAddress', false);
      untouch('street');
      untouch('city');
      untouch('state');
      untouch('zipCode');
    }
  };

  handleMoveOutReasonChange = (evt: Object) => {
    const {
      isUnitAffordableConventional,
      isAffordableMixedProperty,
      moveOutReasons,
      change,
      untouch,
      financiallyResponsibleHousehold,
      housingSituations,
    } = this.props;
    const selectedValue = (moveOutReasons ?? []).find(
      (mr) => mr.value === evt.target.value,
    );
    const shouldValidateHouseSituation =
      selectedValue?.text?.startsWith('WA -') &&
      isAffordableMixedProperty &&
      isUnitAffordableConventional;
    change('housingSituationId', housingSituations[0]?.value);
    this.setShowHousingSituation(shouldValidateHouseSituation);
    if (!shouldValidateHouseSituation) {
      untouch('housingSituationId');
    }

    this.setShowEstateOfWarning(
      (selectedValue?.text === 'Personal - Death' ||
        selectedValue?.text === 'WA - Passed Away') &&
        financiallyResponsibleHousehold?.length === 1,
    );
  };

  render() {
    const {
      store,
      intl,
      unit,
      show,
      invalid,
      residentName,
      moveInReadyDate,
      deleteDisabled,
      states,
      moveOutReasons,
      isForeignAddress,
      forwardingAddressRequired,
      lease,
      isFromFAS,
      propertyHasHUD,
      initialValues,
      values,
      housingSituations,
    } = this.props;

    const individualFieldRequiredIndicator = forwardingAddressRequired
      ? ''
      : '*';
    const addressRequired = forwardingAddressRequired ? 'required' : '';
    const nextAssignation = path(['nextAssignation'], unit);
    const canDeleteBecauseNTV = isNil(nextAssignation) || deleteDisabled;
    const unitNumber = prop('number', unit);
    const showHeader =
      lease.isTransfer ||
      lease.isRenewal ||
      (!forwardingAddressRequired && not(canDeleteBecauseNTV));
    const isCommercialFloorplan = unit?.floorPlan?.isCommercial ?? false;

    const moveOutReasonId =
      values?.moveOutReasonId ?? initialValues?.moveOutReasonId;
    const unitState = unit?.state ?? '';
    const moveOutReasonsOptions = (moveOutReasons ?? []).reduce(
      (acc, reason) => {
        const { text, value } = reason;
        const isCommercialReason = (text ?? '').startsWith('Commercial -');
        const isHUDReason = (text ?? '').startsWith('HUD -');
        const isMentalIllnes = (text ?? '').includes(
          'untreated mental illness',
        );
        const isWA = unitState === 'WA';
        const shouldFilter =
          (!isCommercialFloorplan && isCommercialReason) ||
          (!propertyHasHUD && isHUDReason) ||
          (isMentalIllnes && (isCommercialFloorplan || !isWA));
        if (shouldFilter && moveOutReasonId !== value) {
          return acc;
        }

        if (shouldFilter && moveOutReasonId === value) {
          return [...acc, { disabled: true, text, value }];
        }

        return [...acc, reason];
      },
      [],
    );

    return (
      <Modal bsSize="lg" backdrop show={show} onHide={this.cancel}>
        <Modal.Header closeButton>
          <i className="icon et-upload" />
          <Modal.Title componentClass="h1">
            {intl.formatMessage(messages.title)}
          </Modal.Title>
          <p className="small semibold no-margin-bottom">
            {forwardingAddressRequired &&
              intl.formatMessage(messages.allRequired)}
            {not(forwardingAddressRequired) &&
              intl.formatMessage(messages.someRequired)}
          </p>
        </Modal.Header>
        <Modal.Body>
          {this.state.showDeleteConfirmation && this.renderDeleteConfirmation()}
          {this.state.showCancelConfirmation && this.renderCancelConfirmation()}
          {this.state.showSaveConfirmation && this.renderSaveConfirmation()}
          {!this.state.showCancelConfirmation &&
            !this.state.showDeleteConfirmation &&
            !this.state.showSaveConfirmation && (
              <form className="nopadtop">
                {showHeader && (
                  <HeaderMessage
                    intl={intl}
                    unitAssigned={nextAssignation}
                    hasOpenTransfer={lease.isTransfer}
                    hasOpenRenewal={lease.isRenewal}
                  />
                )}
                <Row>
                  <Col xs={12}>
                    <p>
                      <strong>
                        {intl.formatMessage(messages.unitNumberLabel)}{' '}
                        {unitNumber}
                      </strong>
                    </p>
                    <p>
                      <strong>
                        {intl.formatMessage(messages.residentLabel)}{' '}
                        {residentName}
                      </strong>
                    </p>
                  </Col>
                </Row>
                <Row className="padtop10">
                  <Col xs={12} md={4}>
                    <Field
                      store={store}
                      name="noticeDate"
                      label={concat(
                        intl.formatMessage(messages.noticeDateLabel),
                        individualFieldRequiredIndicator,
                      )}
                      component={renderDateField}
                      bsSize="lg"
                      disabled={forwardingAddressRequired}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Field
                      store={store}
                      name="moveOutDate"
                      label={concat(
                        intl.formatMessage(messages.moveOutDateLabel),
                        individualFieldRequiredIndicator,
                      )}
                      component={renderDateField}
                      bsSize="lg"
                      disabled={forwardingAddressRequired}
                      lease={lease}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <label>
                      {intl.formatMessage(messages.moveInReadyLabel)}
                    </label>
                    <div className="form-group">
                      <span className="vertical-align-middle">
                        <strong>
                          {moveInReadyDate instanceof Date
                            ? intl.formatDate(moveInReadyDate)
                            : '---'}
                        </strong>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <Field
                      store={store}
                      name="moveOutReasonId"
                      label={concat(
                        intl.formatMessage(messages.moveOutReasonLabel),
                        individualFieldRequiredIndicator,
                      )}
                      component={renderSelectField}
                      options={moveOutReasonsOptions}
                      bsSize="lg"
                      disabled={forwardingAddressRequired && isFromFAS}
                      onChange={this.handleMoveOutReasonChange}
                    />
                  </Col>
                  <Col xs={12} md={8}>
                    <Field
                      store={store}
                      name="notes"
                      component={renderTextField}
                      className="input-lg"
                      label={concat(
                        intl.formatMessage(messages.noteLabel),
                        individualFieldRequiredIndicator,
                      )}
                      placeholder={intl.formatMessage(messages.notePlaceholder)}
                      showLabel
                      maxLength="50"
                      disabled={forwardingAddressRequired && isFromFAS}
                    />
                  </Col>
                  <Col xs={12} md={12}>
                    {this.state.showEstateOfWarning && (
                      <Banner
                        text={intl.formatMessage(messages.estateOfWarning)}
                        BoxProps={{
                          sx: {
                            width: '100%',
                          },
                        }}
                        TypographyProps={{
                          noWrap: false,
                        }}
                      ></Banner>
                    )}
                  </Col>
                </Row>
                {this.state.showHousingSituation && (
                  <Row>
                    <Col xs={12} md={4}>
                      <Field
                        store={store}
                        name="housingSituationId"
                        label={concat(
                          intl.formatMessage(messages.housingSituationLabel),
                          individualFieldRequiredIndicator,
                        )}
                        component={renderSelectField}
                        options={housingSituations}
                        bsSize="lg"
                        disabled={forwardingAddressRequired && isFromFAS}
                      />
                    </Col>
                  </Row>
                )}
                <h3 className={addressRequired}>
                  {intl.formatMessage(messages.forwardingAddressTitle)}{' '}
                </h3>
                <p>{intl.formatMessage(messages.forwardingAddressSubtitle)}</p>
                <AddressSection
                  store={store}
                  intl={intl}
                  states={states}
                  streetLabel={messages.streetLabel}
                />
                <Row>
                  <Col xs={12} md={12}>
                    <Field
                      id="isForeignAddress"
                      store={store}
                      name="isForeignAddress"
                      component={renderCheckboxField}
                      label="Foreign Address:"
                      onChange={this.handleForeignAddress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <Field
                      store={store}
                      name="foreignAddressLine1"
                      component={renderTextField}
                      className="input-lg"
                      label="Address 1"
                      showLabel
                      disabled={not(isForeignAddress)}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Field
                      store={store}
                      name="foreignAddressLine2"
                      component={renderTextField}
                      className="input-lg"
                      label="Address 2"
                      showLabel
                      disabled={not(isForeignAddress)}
                    />
                  </Col>
                  <Col xs={12} md={4}>
                    <Field
                      store={store}
                      name="foreignAddressLine3"
                      component={renderTextField}
                      className="input-lg"
                      label="Address 3"
                      showLabel
                      disabled={not(isForeignAddress)}
                    />
                  </Col>
                </Row>
                {forwardingAddressRequired && (
                  <Row>
                    <Col xs={12} md={12}>
                      <Field
                        id="noForwardingAddress"
                        store={store}
                        name="noForwardingAddress"
                        component={renderCheckboxField}
                        label="No Forwarding Address. I confirm that I attempted to obtain
                    the forwarding address and the resident refused to provide."
                        onChange={this.handleNoForwardingAddress}
                      />
                    </Col>
                  </Row>
                )}
              </form>
            )}
        </Modal.Body>
        {!this.state.showCancelConfirmation &&
          !this.state.showDeleteConfirmation &&
          !this.state.showSaveConfirmation && (
            <Modal.Footer>
              <Row>
                <Col sm={!forwardingAddressRequired ? 4 : 6}>
                  <Button
                    onClick={this.cancel}
                    disabled={
                      this.state.showCancelConfirmation ||
                      this.state.showDeleteConfirmation
                    }
                    bsStyle="default"
                    className="pull-right"
                  >
                    {intl.formatMessage(messages.cancel)}
                  </Button>
                </Col>
                <Col
                  sm={!forwardingAddressRequired ? 4 : 6}
                  className={!forwardingAddressRequired ? 'text-center' : ''}
                >
                  <Button
                    className={!forwardingAddressRequired ? '' : 'pull-left'}
                    onClick={this.handleSaveClick}
                    disabled={
                      this.state.showCancelConfirmation ||
                      this.state.showDeleteConfirmation ||
                      invalid
                    }
                    bsStyle="primary"
                  >
                    {intl.formatMessage(messages.save)}
                  </Button>
                </Col>
                {!forwardingAddressRequired && (
                  <div className="col-sm-4">
                    <Button
                      className="btn-tertiary pull-left"
                      onClick={partial(this.deleteConfirmationToggle, [true])}
                      disabled={
                        lease.isTransfer ||
                        deleteDisabled ||
                        this.state.showCancelConfirmation ||
                        this.state.showDeleteConfirmation ||
                        not(canDeleteBecauseNTV)
                      }
                    >
                      {intl.formatMessage(messages.delete)}
                    </Button>
                  </div>
                )}
              </Row>
            </Modal.Footer>
          )}
      </Modal>
    );
  }
}

let NoticeToVacateForm = reduxForm({
  form: 'NoticeToVacate',
  destroyOnUnmount: false,
  enableReinitialize: true,
  fields: ['startDate'],
  validate,
})(NoticeToVacateModal);

const valueSelector = formValueSelector('NoticeToVacate');

const initialValueSelector = getFormInitialValues('NoticeToVacate');

const mapStateToProps = (state: Object, ownProps: Object): any => {
  const formInitialValues = initialValueSelector(state);
  const initialMoveOutDate = prop('moveOutDate', formInitialValues);
  const initialMoveOutDateMoment = moment(initialMoveOutDate);

  const moveOutDate = valueSelector(state, 'moveOutDate');
  const moveOutDateMoment = moment(moveOutDate);
  const noForwardingAddress = valueSelector(state, 'noForwardingAddress');
  const isForeignAddress = valueSelector(state, 'isForeignAddress');
  const startDate = state?.form?.FormLeaseDataTab?.values?.startDate;

  return {
    noForwardingAddress,
    isForeignAddress,
    startDate,
    moveInReadyDate: moveOutDateMoment.isValid()
      ? moveOutDateMoment.add(ownProps.turnMoveOutDays, 'd').toDate()
      : undefined,
    moveInDateUpdated: not(
      moveOutDateMoment.isSame(initialMoveOutDateMoment, 'day'),
    ),
  };
};

NoticeToVacateForm = connect(mapStateToProps)(NoticeToVacateForm);

NoticeToVacateForm = confirmable(NoticeToVacateForm);

const dialog = createConfirmation(NoticeToVacateForm);

const noticeToVacate = (
  store: Object,
  intl: Object,
  propertyHasHUD: boolean,
  propertyClassType: string,
  unit?: Object,
  states: Array<SelectOption>,
  forwardingAddressRequired: boolean,
  moveOutReasons: Array<SelectOption>,
  housingSituations: Array<SelectOption>,
  turnMoveOutDays: number,
  residentInfo: Object,
  onSubmit: Function,
  openFiscalPeriod: Object,
  leases?: Array<Object>,
  isFromFAS: Booelan,
  financiallyResponsibleHousehold: Array<Object>,
): Promise<any> => {
  const residentName = `${path(
    ['residentCustomer', 'customer', 'firstName'],
    residentInfo,
  )} ${path(['residentCustomer', 'customer', 'lastName'], residentInfo)}`;
  const defaultDate: string = moment().format('LL');
  const leaseId: string = path(['leaseId'], residentInfo);
  const ntv = pathOr(
    {
      noForwardingAddress: false,
      isForeignAddress: false,
      state: 'default',
      moveOutReasonId: 'default',
      noticeDate: defaultDate,
      moveOutDate: defaultDate,
      leaseId,
      housingSituationId: 'default',
    },
    ['lease', 'noticeToVacate'],
    residentInfo,
  );
  const lease = leases && leases.length > 0 ? leases[0] : {};

  const initialValues = {
    ...ntv,
    state: isNil(ntv.state) || isEmpty(ntv.state) ? 'default' : ntv.state,
  };
  // $FlowFixMe
  const deleteDisabled = isNil(path(['id'], initialValues));
  const moveOutDate = lease.moveOutDate || initialValues.moveOutDate;
  const isAffordableMixedProperty = ['Mixed', 'Affordable'].includes(
    propertyClassType,
  );
  const isUnitAffordableConventional = !(
    unit?.floorPlan?.isCommercial ?? false
  );

  return new Promise((resolve, reject) => {
    dialog({
      store,
      intl,
      unit,
      states,
      forwardingAddressRequired,
      moveOutReasons,
      housingSituations,
      propertyHasHUD,
      turnMoveOutDays,
      residentName,
      lease,
      deleteDisabled,
      onSubmit,
      openFiscalPeriod,
      initialValues: {
        ...initialValues,
        noticeDate: moment(initialValues.noticeDate),
        moveOutDate: moment(moveOutDate),
      },
      isFromFAS,
      isAffordableMixedProperty,
      isUnitAffordableConventional,
      financiallyResponsibleHousehold,
      propertyClassType,
    }).then(
      (data: any) => resolve(data),
      (error: Object) => reject(error),
    );
  });
};

export default noticeToVacate;
