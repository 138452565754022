import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import classNames from 'classnames';

import { renderPhoneField, renderEmailField, phoneParser, phoneFormatter } from '../../../utils/redux-form-helper';

import messages from './messages';

type Props = {
  intl: Object,
  required?: boolean
};

const ContactInformationSection = ({ intl, required }: Props) => (
  <div className="form-content">
    <h3>{intl.formatMessage(messages.title)}</h3>
    <Row>
      <Col xs={12} md={6}>
        <Field
          name="phoneNumber"
          component={renderPhoneField}
          className="input-lg"
          label={intl.formatMessage(messages.phoneLabel)}
          placeholder={intl.formatMessage(messages.phonePlaceholder)}
          showLabel
          parse={phoneParser}
          format={phoneFormatter}
          classes={classNames({ required })}
        />
      </Col>
      <Col xs={12} md={6}>
        <Field
          name="emailAddress"
          component={renderEmailField}
          className="input-lg"
          label={intl.formatMessage(messages.emailLabel)}
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          showLabel
          classes={classNames({ required })}
        />
      </Col>
    </Row>
  </div>
);

export default ContactInformationSection;
