import React from 'react';
import {
  Stack,
  TableCell,
  TableRow,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import DashesIfNullOrUndefined from '../../components/DashesIfNullOrUndefined';

import messages from './messages';
import ServiceAnimalIndicator from './ServiceAnimalIndicator';
import { HouseholdSection } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { formatDateDisplayLocal } from '@fortress-technology-solutions/fortress-component-library/utils/index';

type Props = {
  editHouseholdMember: Function,
  householdMembers: Array<Object>,
};

export default function ResidentHousehold({
  intl,
  householdMembers,
  editHouseholdMember,
}: Props) {
  return (
    <HouseholdSection
      intl={intl}
      headers={[
        messages.name,
        messages.relation,
        messages.status,
        messages.startDate,
        messages.endDate,
      ]}
      rows={householdMembers.map((occupant, i) => {
        const isPrior = occupant.status === 'Prior';
        const isPet = occupant.type === 'pet';
        let color = isPrior ? 'text.secondary' : null;
        color = occupant.status === 'Pending' ? 'error.main' : color;
        return (
          <TableRow
            key={i}
            sx={{ color, backgroundColor: 'transparent !important' }}
          >
            <TableCell>
              {' '}
              {color || occupant.status === 'Denied' || isPet ? (
                <span>{occupant.name}</span>
              ) : (
                <a onClick={() => editHouseholdMember(occupant.id)}>
                  <DashesIfNullOrUndefined data={occupant.name} />
                </a>
              )}
            </TableCell>
            <TableCell>
              <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                <span>{occupant.relationship}</span>

                {occupant.isServiceAnimal && <ServiceAnimalIndicator />}
              </Stack>
            </TableCell>
            <TableCell>{occupant.status}</TableCell>
            <TableCell>
              {occupant.startDate && formatDateDisplayLocal(occupant.startDate)}
            </TableCell>
            <TableCell>
              {occupant.endDate && formatDateDisplayLocal(occupant.endDate)}
            </TableCell>
          </TableRow>
        );
      })}
    ></HouseholdSection>
  );
}
