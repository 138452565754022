import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.WorkOrder.WorkOrderForm.OfficeNotes.Title',
    defaultMessage: 'Office Only'
  },
  previouslyReported: {
    id: 'App.WorkOrder.WorkOrderForm.OfficeNotes.PreviouslyReported',
    defaultMessage: 'Issue Previously reported?'
  },
  priority: {
    id: 'App.WorkOrder.WorkOrderForm.OfficeNotes.PriorityLevel',
    defaultMessage: 'Priority Level'
  },
  assignedTo: {
    id: 'App.WorkOrder.WorkOrderForm.OfficeNotes.AssignedTo',
    defaultMessage: 'Assigned to'
  },
  noteToTechnician: {
    id: 'App.WorkOrder.WorkOrderForm.OfficeNotes.NoteToTechnician',
    defaultMessage: 'Note to Technician'
  }
});

export default messages;
