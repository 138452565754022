import React from 'react';
import messages from '../messages.js';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';

export const DropDownsAndButton = ({
  filterReversed,
  formatMessage,
  ledger,
  onFilterChange,
  onJournalChange,
  onLedgerChange,
  subjournal,
  subJournals,
}: Object) => {
  return (
    <Grid container spacing={2} paddingLeft={2} paddingRight={2}>
      <Grid item xs={6} marginBottom={2}>
        <Stack direction="row" spacing={1}>
          <select onChange={onLedgerChange} className="form-control input-lg">
            <option value="ops">{formatMessage(messages.resident)}</option>
            <option value="security">{formatMessage(messages.security)}</option>
          </select>
          {ledger === 'ops' ? (
            <select
              onChange={onJournalChange}
              className="form-control input-lg subjournal-dropdown"
              value={subjournal}
            >
              <option key={''} value={''}>
                {formatMessage(messages.allSubjournals)}
              </option>
              {Array.isArray(subJournals) &&
                subJournals.map((journal) => {
                  return (
                    <option key={journal.value} value={journal.value}>
                      {journal.text}
                    </option>
                  );
                })}
            </select>
          ) : (
            ''
          )}
          <div
            className="checkbox custom-checkbox"
            style={{ minWidth: '200px' }}
          >
            <label>
              <input
                type="checkbox"
                checked={filterReversed}
                onChange={onFilterChange}
              />
              <span className="custom-check-square" />
              Hide Reversed Transactions
            </label>
          </div>
        </Stack>
      </Grid>
    </Grid>
  );
};
