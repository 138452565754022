import { gql } from '@apollo/client';

export const CREATE_NEW_PAYMENT_MUTATION = gql`
  mutation CreateNewPayment($data: [CreatePaymentInput]) {
    createNewPayment(data: $data) {
      id
      idempotencyId
      readableId
      ledger
      method
      amount
      status
      fees
      total
      date
      balanceAtPayment
    }
  }
`;

export const CREATE_PAYMENT_SOURCE_MUTATION = gql`
  mutation CreatePaymentSource($data: CreatePaymentSourceInput!) {
    createPaymentSource(data: $data) {
      id
      type
      accountType
      lastFour
      lastFourRouting
      expirationDate
      customerId
      status
      nickname
      isPreferred
    }
  }
`;

export const CREATE_AUTOPAY_SCHEDULE = gql`
  mutation CreateAutopaySchedule($data: CreateAutopayScheduleData) {
    createAutopaySchedule(data: $data) {
      id
      customerId
      managedEntityId
      paymentSourceId
      paymentDay
      paymentAmountType
      amount
      type
    }
  }
`;

export const UPDATE_AUTOPAY_SCHEDULE = gql`
  mutation UpdateAutopaySchedule(
    $id: String
    $data: UpdateAutopayScheduleData
  ) {
    updateAutopaySchedule(id: $id, data: $data) {
      id
      customerId
      managedEntityId
      paymentSourceId
      paymentDay
      paymentAmountType
      amount
      type
    }
  }
`;

export const DELETE_AUTOPAY_SCHEDULE = gql`
  mutation DeleteAutopaySchedule($id: String) {
    deleteAutopaySchedule(id: $id)
  }
`;

export const GET_RESIDENTS_EXISTING_AUTOPAY_SCHEDULES = gql`
  query GetResidentAutopaySchedules($customerIds: [String]) {
    findAutopaySchedule(where: { customerId: { in: $customerIds } }) {
      id
      customerId
      paymentDay
      paymentAmountType
      amount
      type
      lastRunDate
      paymentSource {
        id
        lastFour
      }
    }
  }
`;
