import type { Action, ErrorAction } from '../App/types';
import type { PortfolioEntry } from './types';
import {
  GET_PORTFOLIO,
  GET_PORTFOLIO_SUCCESS,
  GET_PORTFOLIO_ERROR,
  CLEAN_LOADED_PORTFOLIO,
} from './constants';

export function getPortfolio(): Action<any> {
  return {
    type: GET_PORTFOLIO,
    payload: undefined,
  };
}

export function getPortfolioSuccess(
  portfolioEntries: Array<PortfolioEntry>,
): Action<Array<PortfolioEntry>> {
  return {
    type: GET_PORTFOLIO_SUCCESS,
    payload: portfolioEntries,
  };
}

export function getPortfolioError(error: Error): ErrorAction {
  return {
    type: GET_PORTFOLIO_ERROR,
    error,
  };
}

export function cleanLoadedPortfolio(): Action<any> {
  return {
    type: CLEAN_LOADED_PORTFOLIO,
    payload: undefined,
  };
}
