import { FormattedMessage } from 'react-intl';

// Components
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Switch } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { SetupLabel, SetupTitle, SetupValue, SetupSwitch } from '../shared';
import SetupGridItem from '../../../SetupGridItem';
import ProgramPassbookRate from '../ProgramPassbookRate';

import OnOffTypography from '../../../OnOffTypography';
import type { PassbookRate } from '../shared';

// Constants
import messages from './messages';
import messagesAffordableSetup from '../../messages';

interface HUDType {
  id: string;
  name: string;
  code: string;
}

export interface HUDValues {
  HUDPrimarySubsidyTypeId: string;
  HUDProjectNumber: string;
  HUDSecondarySubsidyTypeId: string;
  HUDContractNumber: string;
  hudPrimarySubsidyType: HUDType;
  hudSecondarySubsidyType: HUDType;
  HUDCompleteGRCertsWithoutSigned59As: boolean;
  passbookRates: Array<PassbookRate>;
  autoSendToTRACS: boolean;
  autoRolloverIncomeAndAssets: boolean;
}

type Props = {
  promptToaster: Function,
  isLoadingPassbookRateData: boolean,
  editMode: boolean,
  onChange: Function,
  values: HUDValues,
};

const HUDSetup = ({
  editMode,
  onChange,
  values,
  promptToaster,
  isLoadingPassbookRateData,
}: Props) => {
  const {
    HUDProjectNumber,
    HUDContractNumber,
    hudPrimarySubsidyType,
    hudSecondarySubsidyType,
    HUDCompleteGRCertsWithoutSigned59As,
    autoRolloverIncomeAndAssets,
    passbookRates,
    autoSendToTRACS,
    flags: {
      alltracsDropboxAutomation,
      rolloverIncomeAndAssets,
      grossRentEnhancements,
    },
  } = values;

  const handleAutoSendToTRACSChange = (event: Object, value: boolean) => {
    onChange({
      target: { name: 'config.autoSendToTRACS', value },
    });
  };

  return (
    <Grid container spacing={2}>
      <SetupTitle>
        <FormattedMessage {...messages.title} />
      </SetupTitle>
      <Grid item xs={12} sm={6}>
        <SetupLabel {...messages.hudSubsidyType} />
        <SetupValue
          editMode={editMode}
          name="setup.HUDPrimarySubsidyTypeId"
          value={hudPrimarySubsidyType?.name}
          placeholder=""
          editable={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SetupLabel {...messages.hudSecondarySubsidyType} />
        <SetupValue
          editMode={editMode}
          name="setup.HUDSecondarySubsidyTypeId"
          value={hudSecondarySubsidyType?.name}
          placeholder=""
          editable={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SetupLabel {...messages.HUProjectNumber} />
        <SetupValue
          editMode={editMode}
          name="setup.HUDProjectNumber"
          value={HUDProjectNumber}
          placeholder=""
          onChange={onChange}
          editable={editMode}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SetupLabel {...messages.hudContractNumber} />
        <SetupValue
          editMode={editMode}
          name="setup.HUDContractNumber"
          value={HUDContractNumber}
          placeholder=""
          onChange={onChange}
          editable={editMode}
        />
      </Grid>
      {grossRentEnhancements && (
        <SetupGridItem
          label={
            <FormattedMessage
              {...messages.HUDCompleteGRCertsWithoutSigned59As}
            />
          }
          TooltipProps={{
            title: (
              <FormattedMessage
                {...messages.HUDCompleteGRCertsWithoutSigned59AsTooltip}
              />
            ),
          }}
        >
          <SetupSwitch
            switchId={'hud-complete-gr-certs-without-signed-59as-switch'}
            editMode={editMode}
            name="setup.HUDCompleteGRCertsWithoutSigned59As"
            disabled={!editMode}
            value={HUDCompleteGRCertsWithoutSigned59As}
            onChange={onChange}
          />
        </SetupGridItem>
      )}
      {alltracsDropboxAutomation && (
        <SetupGridItem
          label={<FormattedMessage {...messages.autoSendToTRACS} />}
          TooltipProps={{
            title: (
              <FormattedMessage {...messages.autoSendToTRACSDescription} />
            ),
          }}
        >
          {editMode ? (
            <Switch
              name="config.autoSendToTRACS"
              id="autoSendToTRACS-switch"
              checked={autoSendToTRACS}
              onChange={handleAutoSendToTRACSChange}
            />
          ) : (
            <OnOffTypography on={autoSendToTRACS}>
              {autoSendToTRACS ? (
                <FormattedMessage {...messages.on} />
              ) : (
                <FormattedMessage {...messages.off} />
              )}
            </OnOffTypography>
          )}
        </SetupGridItem>
      )}
      {rolloverIncomeAndAssets && (
        <SetupGridItem
          label={
            <FormattedMessage
              {...messagesAffordableSetup.autoRolloverIncomeAndAssets}
            />
          }
          TooltipProps={{
            title: (
              <FormattedMessage
                {...messagesAffordableSetup.autoRolloverIncomeAndAssetsDescription}
              />
            ),
          }}
        >
          <SetupSwitch
            switchId={'hud-auto-rollover-income-and-assets-switch'}
            editMode={editMode}
            name="setup.HUDAutoRolloverIncomeAndAssets"
            disabled={!editMode}
            value={autoRolloverIncomeAndAssets}
            onChange={onChange}
          />
        </SetupGridItem>
      )}
      <Grid item xs={12}>
        <ProgramPassbookRate
          key={'HUD_PassbookRates'}
          programName={'HUD'}
          isLoadingPassbookRateData={isLoadingPassbookRateData}
          passbookRates={passbookRates}
        />
      </Grid>
    </Grid>
  );
};

export default HUDSetup;
