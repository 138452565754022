// @flow
import request, { apiURL, put } from '../utils/api';

export type PropertyBankAccount = {
  id: string,
  readableId: number,
  bankAccountType: string,
  bankAccountNumber: string,
  bankName: string,
  comment: string,
  startDate: Date,
  endDate?: Date,
  propertyId: string,
  organizationId: string,
};

class PropertyBankAccountsService {
  getAllForProperty(
    propertyId: string,
    organizationId: string,
  ): Promise<PropertyBankAccount[]> {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/property-bank-accounts/current`,
      options,
    );
  }

  update(
    propertyId: string,
    organizationId: string,
    data: Object,
  ): Promise<PropertyBankAccount[]> {
    return put(
      `/${organizationId}/${propertyId}/property-bank-accounts`,
      JSON.stringify(data),
    );
  }
}

export default PropertyBankAccountsService;
