import {
  validateName,
  validatePositiveMoney,
  validatePositiveNumber,
  validatePhoneNumber,
  validateDate,
  validateEmail,
} from '../../../../../utils/validations';

import {
  EMAIL_FIELDS,
  PHONE_FIELDS,
  NUMBER_FIELDS,
  NAME_FIELDS,
  MONEY_FIELDS,
  DATE_FIELDS,
} from '../constants';

const validate = (values: Object) => {
  const errors = {};

  NAME_FIELDS.forEach((fieldName) => {
    if (fieldName && values[fieldName] && !validateName(values[fieldName])) {
      errors[fieldName] = 'Please enter a valid name';
    }
  });

  EMAIL_FIELDS.forEach((fieldName) => {
    if (fieldName && values[fieldName] && !validateEmail(values[fieldName])) {
      errors[fieldName] = 'Please enter a valid email';
    }
  });

  PHONE_FIELDS.forEach((fieldName) => {
    if (
      fieldName &&
      values[fieldName] &&
      !validatePhoneNumber(values[fieldName])
    ) {
      errors[fieldName] = 'Please enter a valid phone number';
    }
  });

  NUMBER_FIELDS.forEach((fieldName) => {
    if (
      fieldName &&
      values[fieldName] &&
      (!validatePositiveNumber(values[fieldName]) || values[fieldName] > 1000)
    ) {
      errors[fieldName] = 'Please enter a valid number';
    }
  });

  DATE_FIELDS.forEach((fieldName) => {
    if (fieldName && values[fieldName] && !validateDate(values[fieldName])) {
      errors[fieldName] = 'Please enter a valid date';
    }
  });

  MONEY_FIELDS.forEach((fieldName) => {
    if (
      fieldName &&
      values[fieldName] &&
      !validatePositiveMoney(values[fieldName])
    ) {
      errors[fieldName] = 'Please enter a valid amount';
    }
  });

  if (values && values['Tenant Improvement Tasks']) {
    const taskImprovementErrors = [];
    values['Tenant Improvement Tasks'].forEach((task, index) => {
      if (task && task['Task Due Date']) {
        const taskErrors = {};
        if (!validateDate(task['Task Due Date'])) {
          taskErrors['Task Due Date'] = 'Please enter a valid date';
        }
        taskImprovementErrors[index] = taskErrors;
      }
    });
    errors['Tenant Improvement Tasks'] = taskImprovementErrors;
  }

  return errors;
};

export default validate;
