import React from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { isNil } from 'ramda';
import { Link } from 'react-router-dom';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import Row from '../../../../components/Table/Row';
import Data from '../../../../components/Table/Data';

import { getCertificationStatus, getDynamicProperties } from './utils';
import { getUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';

import {
  CERTIFICATION_TYPES,
  OTHER_HUD,
  CERTIFICATION_TYPES_INTL,
  OTHER_LIHTC,
} from '../../constants';

import generalMessages from '../../../App/messages';
import manageCertsMessages from '../../messages';
import componentMessages from './messages';

const messages = {
  ...generalMessages,
  ...manageCertsMessages,
  ...componentMessages,
};

const StyledNote = styled(Button)`
  cursor: initial;
`;

const ActiveCertificationRow = ({
  certification,
  certificationType,
  intl,
}: Object) => {
  const status = certification?.certStatus ?? '---';
  const tooltip = !['Started', 'Complete'].includes(status) ? (
    <Tooltip id="tooltip">{status}</Tooltip>
  ) : null;
  const statusName = getCertificationStatus(status);
  const leaseStartDate = certification?.leaseStartDate ?? '---';
  const scheduledMoveInDate = certification?.scheduledMoveInDate ?? '---';
  const effectiveDate = certification?.effectiveDate ?? '---';
  const latestComplianceDocument =
    certification?.latestComplianceDocument ?? '---';
  const lastCertifiedDate = certification?.lastCertifiedDate ?? '---';
  const nextRecertDueDate = certification?.nextRecertDueDate ?? '---';
  const daysSinceLastUpload = certification?.daysSinceLastUpload ?? 0;

  let dynamicProperties = getDynamicProperties(); // Returns default values
  if (certificationType === CERTIFICATION_TYPES.MOVE_IN) {
    dynamicProperties = {
      ...getDynamicProperties(
        scheduledMoveInDate,
        !certification.isApplicant,
        'application',
      ),
    };
  }

  if (
    [CERTIFICATION_TYPES.INITIAL, OTHER_HUD, OTHER_LIHTC].includes(
      certificationType,
    )
  ) {
    dynamicProperties = {
      ...getDynamicProperties(
        effectiveDate,
        !certification.isPriorResident,
        'prior-resident',
      ),
      customerStatus: certification.isPriorResident,
    };
  }

  const certType = !isNil(
    CERTIFICATION_TYPES_INTL[certification?.certificationType],
  )
    ? intl.formatMessage(
        messages[CERTIFICATION_TYPES_INTL[certification.certificationType]],
      )
    : '---';

  const { linkPath, customerStatus, effectiveScheduledMoveInDate } =
    dynamicProperties;

  /* This array is because for Move-Ins and Initials
   * Balance goes before IsCorrection but for Other Hud
   * is reversed.
   */
  const balanceCorrectionData = [
    <Data>
      <FormattedNumber
        value={certification.balance}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency="USD"
      />
    </Data>,
    <Data>
      {certification.isCorrection ? (
        <FormattedMessage {...messages.yes} />
      ) : (
        '---'
      )}
    </Data>,
  ];

  return (
    <Row key={`AC-${certification.id}`}>
      <Data>
        {tooltip ? (
          <OverlayTrigger
            container={window.document.getElementById('table-row')}
            placement="right"
            delayHide={200}
            trigger={['hover', 'focus']}
            overlay={tooltip}
          >
            <span>{statusName}</span>
          </OverlayTrigger>
        ) : (
          <span>{statusName}</span>
        )}
      </Data>
      <Data>
        {certificationType === OTHER_HUD ? certType : certification.programName}
      </Data>
      <Data>{certification.unit}</Data>
      <Data>{certification.floorplan}</Data>
      <Data>
        <Link
          to={getUrlWithSelectedPropertyId(
            `/${linkPath}/${certification.headOfHouseholdId}`,
          )}
        >
          {certification.headOfHousehold}
        </Link>
      </Data>
      <Data>{effectiveScheduledMoveInDate}</Data>
      {![OTHER_LIHTC, OTHER_HUD].includes(certificationType)
        ? [<Data>{leaseStartDate}</Data>]
        : [<Data>{lastCertifiedDate}</Data>, <Data>{nextRecertDueDate}</Data>]}
      <Data>
        {customerStatus ? <FormattedMessage {...messages.yes} /> : '---'}
      </Data>
      {![OTHER_LIHTC, OTHER_HUD].includes(certificationType)
        ? balanceCorrectionData
        : balanceCorrectionData.reverse()}
      <Data>{latestComplianceDocument}</Data>
      <Data>{daysSinceLastUpload}</Data>
      <Data>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip-notes">
              {certification.certificationNotes}
            </Tooltip>
          }
        >
          <StyledNote
            bsStyle="default"
            bsSize="small"
            className="btn-comments"
            disabled={
              isNil(certification.certificationNotes) ||
              certification.certificationNotes.length === 0
            }
          >
            <i className="icon et-comment-words" />
          </StyledNote>
        </OverlayTrigger>
      </Data>
    </Row>
  );
};

export default ActiveCertificationRow;
