import React from 'react';
import GenericIntegrationSetup from '../GenericIntegration';

const ConserviceSetup = (props: Props) => {
  const { editMode, onChange, orgIntegrations, propertyIntegrations } = props;

  return (
    <GenericIntegrationSetup
      onChange={onChange}
      organizationIntegrations={orgIntegrations}
      propertyIntegrations={propertyIntegrations}
      editMode={editMode}
      useModal={false}
      integrationKey={'conservice'}
      integrationName={'Conservice'}
      integrationFormValue={'isConserviceEnabled'}
    />
  );
};

export default ConserviceSetup;
