import { put, throttle } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../../utils/redux-form-helper';
import type { Action } from '../../App/types';
import PropertyService from '../../../services/propertyService';
import { onboardPortal } from './actions';
import { ENABLE_PORTAL, DISABLE_PORTAL, ONBOARD_PORTAL } from './constants';

import messages from './messages';

import type { EnablePortalPayload } from './types';

export function* enablePortal(action: Action<EnablePortalPayload>): Saga<void> {
  try {
    const {
      organizationId,
      propertyId,
      updateProperties,
      sendEmails,
      settings,
    } = action.payload;

    const propertyService = new PropertyService();

    yield propertyService.enablePortal(organizationId, propertyId, settings);

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successfullyUpdatedPortal),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    if (sendEmails) {
      yield put(onboardPortal(organizationId, propertyId, updateProperties));
    } else {
      /**
       * This is a callback that is refreshing the list of properties displayed on
       * the Manage Properties page.
       */
      updateProperties();
    }
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.errorUpdatingPortal),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* enablePortalSaga(): Saga<void> {
  yield throttle(500, ENABLE_PORTAL, enablePortal);
}

export function* disablePortal(action: Action<any>): Saga<void> {
  try {
    const { organizationId, propertyId, updateProperties } = action.payload;

    const propertyService = new PropertyService();

    yield propertyService.disablePortal(organizationId, propertyId);

    /**
     * This is a callback that is refreshing the list of properties displayed on
     * the Manage Properties page.
     */
    updateProperties();

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successfullyUpdatedPortal),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.errorUpdatingPortal),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* disablePortalSaga(): Saga<void> {
  yield throttle(500, DISABLE_PORTAL, disablePortal);
}

export function* onboardPropertyPortal(action: Action<any>): Saga<void> {
  try {
    const { organizationId, propertyId, updateProperties } = action.payload;

    const propertyService = new PropertyService();

    yield propertyService.updateOnboardedByUserId(organizationId, propertyId);
    updateProperties();

    yield propertyService.onboardPropertyForPortal(organizationId, propertyId);

    /**
     * This is a callback that is refreshing the list of properties displayed on
     * the Manage Properties page.
     */
    if (updateProperties) {
      updateProperties();
    }

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successfullyOnboardedPortal),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(messages.errorOnboardingPortal),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* onboardPortalSaga(): Saga<void> {
  yield throttle(500, ONBOARD_PORTAL, onboardPropertyPortal);
}

export default [enablePortalSaga, disablePortalSaga, onboardPortalSaga];
