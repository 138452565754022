import type {
  Action,
  ErrorAction,
  FilterValue,
  FilterValueAndPagination,
  OrderValue,
  PaginationMeta,
  WorkOrder,
} from '../App/types';
import {
  CLEAN_LOADED_WORK_ORDERS,
  GET_ALL_WORK_ORDERS,
  GET_ALL_WORK_ORDERS_ERROR,
  GET_ALL_WORK_ORDERS_SUCCESS,
  UPDATE_COLUMNS_SORT_VALUE,
  UPDATE_WORK_ORDER_DROPDOWN,
  UPDATE_WORK_ORDER_DROPDOWN_ERROR,
  UPDATE_WORK_ORDER_DROPDOWN_SUCCESS,
  UPDATE_WORK_ORDERS_QUERY_PARAMS,
} from './constants';

export function getAllWorkOrders(
  pageNumber: number,
  limit: number,
  filterValue: FilterValue,
  sorting: OrderValue,
  searchText: string,
  defaultFilterActive?: boolean,
): Action<FilterValueAndPagination> {
  return {
    type: GET_ALL_WORK_ORDERS,
    payload: {
      sorting,
      filterValue,
      searchText,
      paginationInfo: {
        pageNumber,
        limit,
      },
      defaultFilterActive,
    },
  };
}

export function getAllWorkOrdersSuccess(
  workOrders: Array<WorkOrder>,
  meta: PaginationMeta,
): Action<Array<WorkOrder>, PaginationMeta> {
  return {
    type: GET_ALL_WORK_ORDERS_SUCCESS,
    payload: workOrders,
    meta,
  };
}

export function getAllWorkOrdersError(error: Error): ErrorAction {
  return {
    type: GET_ALL_WORK_ORDERS_ERROR,
    error,
  };
}

export function updateColumnsSortValue(field: string, value: string) {
  return {
    type: UPDATE_COLUMNS_SORT_VALUE,
    payload: { [field]: value },
  };
}

export function cleanLoadedWorkOrders(): Action<any> {
  return {
    type: CLEAN_LOADED_WORK_ORDERS,
    payload: undefined,
  };
}

export function updateWorkOrder(
  workOrder: WorkOrder,
  pageNumber: number,
  limit: number,
): Action<any> {
  return {
    type: UPDATE_WORK_ORDER_DROPDOWN,
    payload: {
      workOrder,
      paginationInfo: {
        pageNumber,
        limit,
      },
    },
  };
}

export function updateWorkOrderSuccess(): Action<any> {
  return {
    type: UPDATE_WORK_ORDER_DROPDOWN_SUCCESS,
    payload: undefined,
  };
}

export function updateWorkOrderError(error: Error): Action<Error> {
  return {
    type: UPDATE_WORK_ORDER_DROPDOWN_ERROR,
    payload: error,
  };
}

export function updateWorkOrdersQueryParams({
  currentFilter,
  currentSorting,
  searchText,
}): Action<{
  currentFilter: { [name: string]: string },
  currentSorting: { [name: string]: string },
  searchText: string,
}> {
  return {
    type: UPDATE_WORK_ORDERS_QUERY_PARAMS,
    payload: {
      currentFilter,
      currentSorting,
      searchText,
    },
  };
}
