import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.Overview.LeaseExpirationExposure.Title',
    defaultMessage: 'Lease Expiration Exposure',
  },
  monthToMonthLeases: {
    id: 'App.Overview.LeaseExpirationExposure.MonthToMonthLeases',
    defaultMessage: 'Month-to-Month Leases: ',
  },
  noteStart: {
    id: 'App.Overview.LeaseExpirationExposure.NoteStart',
    defaultMessage: '* Note: ',
  },
  note: {
    id: 'App.Overview.LeaseExpirationExposure.Note',
    defaultMessage:
      'When navigating to the Manage Expiring Leases screen, only the active leases will be displayed.',
  },
});

export default messages;
