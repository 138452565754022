import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import { bindActionCreators } from 'redux';
import { pathOr } from 'ramda';
import moment from 'moment';
import confirm from '../../components/ConfirmDialogModal';
import * as createCommercialTenantActions from './actions';
import { getMonthlyTransactions } from '../ApplicationProfile/actions';
import { MoveInCheckApplicationScreen } from './MoveInCheck';
import { getMoveInProratedTransactions } from '../../utils/prorateCalculator';
import messages from './messages';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import {
  getApplicantName,
  getLeaseStartDate,
  getUnitNumber,
  getRentStartDate,
  getIsRentDateEstimatedStatus,
} from './selectors';
import { getSelectedPropertyClassType } from '../App/selectors';

import type { GlobalState } from '../App/types';

type StateProps = {
  locale: string,
  applicationId: string,
  signedLeaseStatus?: Object,
  leaseStatus?: Object,
  leaseStartDateStatus?: Object,
  unitAvailabilityStatus?: Object,
  applicantName: string,
  unitNumber: string,
  leaseStartDate: Object,
  propertyClassType: string,
  currentApplication: ?Object,
  location?: any,
  leaseMoveInDate: string,
  monthlyTransactions?: Array<Object>,
};
type Props = {
  intl: any,
  propertyId: string,
  history: Object,
  store: Object,
  moveInApplyFullAmountToProRate: boolean,
  actions: {
    getSignedLeaseStatus: (applicant: string) => Object,
    getLeaseStatus: (applicant: string) => Object,
    getLeaseStartDateStatus: (applicant: string) => Object,
    getOneApplication: (applicant: string) => void,
    clean: () => void,
    moveIn: (applicationId: string, actualMoveInDate: any) => void,
    getUnitAvailabilityStatus: (unitId: string) => Object,
    getContactStatus: (applicationId: string) => Object,
    getInsuranceStatus: (applicationId: string) => Object,
    getMonthlyTransactions: (applicationId: string) => Object,
  },
};

type State = {
  actualMoveInDate: any,
};

export class CreateCommercialTenant extends Component<
  StateProps & Props,
  State,
> {
  constructor(props: StateProps & Props) {
    super(props);
    this.state = {
      actualMoveInDate: moment(new Date()).format('YYYY-MM-DD'),
    };
  }
  componentWillMount() {
    this.props.actions.clean();
  }
  componentDidMount() {
    const applicationId: string = this.props.applicationId;
    this.props.actions.getOneApplication(applicationId);
    this.props.actions.getSignedLeaseStatus(applicationId);
    this.props.actions.getLeaseStatus(applicationId);
    this.props.actions.getLeaseStartDateStatus(applicationId);
    this.props.actions.getUnitAvailabilityStatus(applicationId);
    this.props.actions.getContactStatus(applicationId);
    this.props.actions.getInsuranceStatus(applicationId);
    this.props.actions.getMonthlyTransactions(applicationId);
  }

  handleCancel = () => {
    const route = `/application/${this.props.applicationId}`;
    navigateToUrlWithSelectedPropertyId(route);
  };

  handleMoveInDateUpdate = (newDate: any) => {
    this.setState({
      actualMoveInDate: moment(newDate).format('YYYY-MM-DD'),
    });
  };

  handleSubmit = (values: any) => {
    confirm(this.props.intl.formatMessage(messages.convertConfirmation), {
      intl: this.props.intl,
    })
      .then(() => {
        this.props.actions.commercialMoveIn(
          this.props.applicationId,
          values.actualMoveInDate,
          values.prorated,
        );
      })
      .catch(() => {});
  };

  render() {
    const {
      applicantName,
      intl,
      leaseStartDate,
      leaseStartDateStatus,
      leaseStatus,
      contactStatus,
      insuranceStatus,
      signedLeaseStatus,
      unitNumber,
      unitAvailabilityStatus,
      leaseMoveInDate,
      rentStartDate,
      isRentDateEstimatedStatus,
      monthlyTransactions,
      moveInApplyFullAmountToProRate,
      flags,
    } = this.props;

    const steps = [
      {
        ...unitAvailabilityStatus,
        title: this.props.intl.formatMessage(messages.unitAvailabilityStatus),
      },
      {
        ...leaseStatus,
        title: this.props.intl.formatMessage(messages.leaseStatus),
      },
      {
        ...signedLeaseStatus,
        title: this.props.intl.formatMessage(messages.signedLease),
      },
      {
        ...contactStatus,
        title: this.props.intl.formatMessage(messages.contactStatus),
      },
      {
        ...insuranceStatus,
        title: this.props.intl.formatMessage(messages.insuranceStatus),
      },
      {
        ...isRentDateEstimatedStatus,
        title: `${this.props.intl.formatMessage(
          messages.rentDateEstimatedStatus,
        )}: ${rentStartDate}`,
      },
    ];

    const show =
      signedLeaseStatus &&
      leaseStatus &&
      contactStatus &&
      insuranceStatus &&
      leaseStartDateStatus &&
      unitAvailabilityStatus;
    const proratedTransactions = getMoveInProratedTransactions(
      monthlyTransactions || [],
      rentStartDate,
      moveInApplyFullAmountToProRate,
      true,
      false, // commercial tenants will not be rounding their prorated rents
      flags?.correctProrateOnMoveinScreen ?? false,
    );
    return (
      <DocumentTitle title="Fortress - Convert to Tenant">
        {show && (
          <MoveInCheckApplicationScreen
            applicantName={applicantName}
            handleCancel={this.handleCancel}
            intl={intl}
            leaseStartDate={leaseStartDate}
            onSubmit={this.handleSubmit}
            steps={steps}
            unitNumber={unitNumber}
            handleMoveInDateUpdate={this.handleMoveInDateUpdate}
            monthlyTransactions={proratedTransactions}
            openFiscalPeriod={pathOr(
              null,
              ['openFiscalPeriod'],
              this.props.currentApplication,
            )}
            initialValues={{
              actualMoveInDate: this.state.actualMoveInDate,
              prorated: proratedTransactions,
              previousLeaseMoveOutDate: pathOr(
                null,
                ['previousLeaseMoveOutDate'],
                unitAvailabilityStatus,
              ),
            }}
            leaseMoveInDate={leaseMoveInDate}
            moveInApplyFullAmountToProRate={moveInApplyFullAmountToProRate}
          />
        )}
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): StateProps => {
  const { languageProvider, createCommercialTenant } = state;
  // $FlowFixMe
  const leaseMoveInDate = pathOr(
    '',
    ['currentApplication', 'au', 'lease', 'moveInDate'],
    createCommercialTenant,
  );

  return {
    applicationId: ownProps.match.params.applicationId,
    locale: languageProvider.locale,
    signedLeaseStatus: createCommercialTenant.signedLeaseStatus,
    leaseStatus: createCommercialTenant.leaseStatus,
    contactStatus: createCommercialTenant.contactStatus,
    insuranceStatus: createCommercialTenant.insuranceStatus,
    leaseStartDateStatus: createCommercialTenant.leaseStartDateStatus,
    unitAvailabilityStatus: createCommercialTenant.unitAvailabilityStatus,
    currentApplication: createCommercialTenant.currentApplication,
    applicantName: getApplicantName(state),
    unitNumber: getUnitNumber(state),
    leaseStartDate: getLeaseStartDate(state),
    propertyClassType: getSelectedPropertyClassType(state),
    rentStartDate: getRentStartDate(state),
    isRentDateEstimatedStatus: getIsRentDateEstimatedStatus(state),
    monthlyTransactions: createCommercialTenant.monthlyTransactions,
    // $FlowFixMe
    leaseMoveInDate,
    moveInApplyFullAmountToProRate: pathOr(
      false,
      ['app', 'selectedProperty', 'moveInApplyFullAmountToProRate'],
      state,
    ),
    propertyId: state?.app?.selectedProperty?.id ?? '',
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...createCommercialTenantActions,
      getMonthlyTransactions,
    },
    dispatch,
  );
  return { actions };
}

export default withLDConsumer()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(injectIntl(CreateCommercialTenant)),
);
