import React from 'react';
import { Field } from 'redux-form';

import { renderTextAreaField } from '../../../../../../utils/redux-form-helper';

import { FlexFormInputFull } from '../../common';

const OtherRightsOptionsFormFields = ({ values }: Object) => {
  return (
    <React.Fragment>
      <FlexFormInputFull>
        <Field
          name="Other Rights"
          component={renderTextAreaField}
          label="Other Rights"
          rows="3"
          maxLength="1000"
          showLabel
        />
      </FlexFormInputFull>
    </React.Fragment>
  );
};

export default OtherRightsOptionsFormFields;
