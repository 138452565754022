import { defineMessages } from 'react-intl';

const messages = defineMessages({
  addGroupTitle: {
    id: 'App.AddGroupHeader.AddGroupTitle',
    defaultMessage: 'Add Group to Transaction Batch',
  },
  goBack: {
    id: 'App.AddGroupHeader.GoBack',
    defaultMessage: 'Back to Create Transaction Batch',
  },
});

export default messages;
