// $FlowFixMe
import React, { useState, useEffect } from 'react'; // eslint-disable-line
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import type {
  Activity,
  Assignee,
  Prospect,
} from '../../containers/ProspectProfile/types';
import * as prospectActions from '../../containers/PeopleProfile/actions';
import Spinner from '../../components/Spinner';
import { editCompletedActivity } from '../../components/EditCompletedActivityModal';
import { editPendingActivity } from '../../components/EditPendingActivityModal';
import messages from './messages';
import styled from 'styled-components';
import { curryN, takeLast } from 'ramda';
import { recordActivity, scheduleActivity } from '../ActivityModal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAllActivityTypes } from '../../containers/App/actions';
import {
  getAllActivitiesByAssignee,
  clearActivitiesByAssignee,
} from '../../containers/Home/actions';
import type { ActivityType } from '../../containers/App/types';
import { createActivity } from '../CreateActivityModal';

import { useAsyncAssigneeEvents } from './hooks';

import 'react-big-calendar/lib/css/react-big-calendar.css';

export const SCHEDULED_COLOR = '#7734E7';
export const RECORDED_COLOR = '#4D73FF';

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

const Calendar = styled(BigCalendar)`
  width: 100%;

  table[class$='rbc-agenda-table'] > tbody > tr > td {
    width: 33%;
  }

  table[class$='rbc-agenda-table'] > thead > tr > th {
    width: 33% !important;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-event-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

type Props = {
  actions: any,
  activities: Array<Activity>,
  activityTypes: Array<ActivityType>,
  assignees: Array<Assignee>,
  defaultView: string,
  intl: Object,
  locale: string,
  selectedAssignee: Assignee,
  onChangeAssignee: Function,
  store: any,
  currentFilter: string,
};

export const showMore = (message: string, total: number) =>
  `+${total} ${message}`;

const generateMessages = (intl: Object) => {
  return {
    allDay: intl.formatMessage(messages.allDay),
    previous: intl.formatMessage(messages.previous),
    next: intl.formatMessage(messages.next),
    today: intl.formatMessage(messages.today),
    month: intl.formatMessage(messages.month),
    week: intl.formatMessage(messages.week),
    day: intl.formatMessage(messages.day),
    agenda: intl.formatMessage(messages.agenda),
    date: intl.formatMessage(messages.date),
    time: intl.formatMessage(messages.time),
    event: intl.formatMessage(messages.event),
    showMore: curryN(2, showMore)(intl.formatMessage(messages.showMore)),
  };
};

export const eventStyleGetter = (event: Object) => {
  const activityCompletionStatusId =
    event?.activity?.activityCompletionStatusId;
  var style = {
    backgroundColor: activityCompletionStatusId
      ? RECORDED_COLOR
      : SCHEDULED_COLOR,
    borderRadius: '0px',
    opacity: 0.8,
    border: '0px',
    display: 'block',
  };
  return { style };
};

export const updateTooltip = (e: Object, props, currentView) => {
  const month = `${e.timeRange} ${e.toolTip}`;
  const weekAndDayTooltip = `${e.toolTip}`;
  if (
    (props.defaultView === 'month' && currentView === null) ||
    currentView === 'month'
  ) {
    return month;
  } else {
    return weekAndDayTooltip;
  }
};

export const generateFormattedActivities = (
  activities: Array<Activity>,
  filter: string,
) => {
  const newActivities: Array<Object> = activities
    .filter((a: Activity) => {
      if (!filter || filter === 'all') {
        return true;
      }
      const activityCompletionStatusId = a?.activityCompletionStatusId;
      if (!activityCompletionStatusId && filter === 'scheduled') {
        return true;
      }
      if (activityCompletionStatusId && filter === 'recorded') {
        return true;
      }
      return false;
    })
    .map((a: Activity) => {
      const activityTypeName = a.activityType ? a.activityType.name : '';
      const firstName = a?.prospect?.firstName;
      const lastName = a?.prospect?.lastName;
      const start = moment(a.startTime).format('hh:mm A');
      const end = moment(a.endTime).format('hh:mm A');
      const title = `${activityTypeName}: ${firstName} ${lastName}`;
      return {
        title,
        type: activityTypeName,
        id: a.id,
        timeRange: `${start} - ${end}`,
        toolTip: `${title} ${a.notes}`,
        start: new Date(a.startTime),
        end: new Date(a.endTime),
        allDay: a.allDay,
        desc: a.notes,
        activity: a,
      };
    });
  return newActivities;
};

export const formats: Object = {
  dayFormat: (date: string, culture: string, localizer: Function) =>
    localizer.format(date, 'MM/DD', culture),
};

export const displayEvent = (props: Object, e: Object, refreshFn: Function) => (
  <div
    style={{ height: '100%', width: '100%' }}
    onClick={() => handleClick(props, e, refreshFn)}
  >
    {e.title}
  </div>
);

export const handleClick = (props, { event: { activity } }, refreshFn: any) => {
  const customerArray = activity?.prospect?.customer ?? [];
  const customer = takeLast(1, customerArray)[0];
  let currentStage = customer?.status ?? 'prospect';
  let urlId = activity.prospectId;
  if (currentStage === 'Applicant') {
    currentStage = 'applicant';
    urlId = customer?.applicantCustomer?.applicant?.applicationId ?? urlId;
  } else if (currentStage === 'Current Resident') {
    currentStage = 'resident';
    urlId = customer?.resident?.residentId ?? urlId;
  } else if (currentStage === 'Prior Resident') {
    currentStage = 'prior-resident';
    urlId = customer?.resident?.residentId ?? urlId;
  }

  const stageInfo = {
    currentStage,
    urlId,
  };

  const activityTypesList = generateActivityTypesList(props);
  const users = generateUsers(props);
  const editPromise = activity.activityCompletionStatusId
    ? editCompletedActivity(props.store, props.intl, activity, stageInfo)
    : editPendingActivity(
        props.store,
        props.intl,
        activity,
        activityTypesList,
        users,
        activity.prospect,
        stageInfo,
      );

  const editFunction = () => onCreateActivityClick(props, activity.prospect);

  return editPromise
    .then((data: Activity) => {
      if (data.saveAndClose) {
        return props.actions.updateProspectActivity(data);
      } else if (data.saveAndAddNew) {
        props.actions.updateProspectActivity(data);
        return editFunction();
      } else {
        return props.actions.deleteProspectActivity(data);
      }
    })
    .then(() => {
      setTimeout(refreshFn, 1000);
    });
};

export const generateActivityTypesList = (props: Object): Array<any> => {
  const activityTypes = props.activityTypes.map((activityType) => ({
    value: activityType.id,
    text: activityType.translations[props.locale] || activityType.name,
    canBeOverlapped: activityType.canBeOverlapped,
    completionStatusOptions: activityType.completionStatusOptions,
    disabled: false,
  }));
  activityTypes.unshift({
    value: 'default',
    text: props.intl.formatMessage(messages.chooseOption),
    completionStatusOptions: [{ id: '' }],
    disabled: true,
    showForRecord: true,
  });
  return activityTypes;
};

export const onRecordActivityClick = (props: Object, prospect: Prospect) => {
  const activityTypesList = generateActivityTypesList(props);
  const users = generateUsers(props);
  recordActivity(
    props.store,
    props.intl,
    prospect,
    activityTypesList,
    users,
    'prospect',
    prospect.id,
  ).then((data: Activity) => {
    return props.actions.createProspectActivity(
      Object.assign(data, { customerStatus: 'Prospect' }),
    );
  });
};

export const onScheduleActivityClick = (props: Object, prospect: Prospect) => {
  const activityTypesList = generateActivityTypesList(props);
  const users = generateUsers(props);
  scheduleActivity(
    props.store,
    props.intl,
    prospect,
    activityTypesList,
    users,
    'prospect',
    prospect.id,
  ).then((data: Activity) => {
    return props.actions.createProspectActivity(
      Object.assign(data, { customerStatus: 'Prospect' }),
    );
  });
};

export const onCreateActivityClick = async (
  props: Object,
  prospect: Prospect,
) => {
  const activityTypesList = generateActivityTypesList(props);
  const users = generateUsers(props);
  const data: Activity = await createActivity({
    store: props.store,
    intl: props.intl,
    prospect: prospect,
    activityTypesList,
    assigneeList: users,
    stage: 'prospect',
    urlId: prospect.id,
    showViewProfileBtn: true,
  });
  props.actions.createProspectActivity({
    ...data,
    customerStatus: 'Prospect',
  });
};

export const generateUsers = (props: Object) => {
  return props.assignees
    ? props.assignees.map((user) => ({
        value: user.id,
        text: `${user.firstName} ${user.lastName}`,
        disabled: false,
      }))
    : [];
};

export const AssigneeCalendar = (props: Props) => {
  const [navigattedDate, setNavigatedDate] = useState(new Date(Date.now()));
  const [dates, setDates] = useState(null);
  const [currentView, setCurrentView] = useState('month');

  useEffect(() => {
    props.actions.getAllActivityTypes();
  }, []); // eslint-disable-line

  const { activities, activitiesLoading, refreshFn } = useAsyncAssigneeEvents(
    props.selectedProperty,
    props.selectedAssignee,
    dates,
  );

  return !activitiesLoading ? (
    <Calendar
      popup={true}
      formats={formats}
      view={currentView}
      date={navigattedDate}
      culture={props.locale}
      events={generateFormattedActivities(activities, props.currentFilter)}
      messages={generateMessages(props.intl)}
      onView={(view) => setCurrentView(view)}
      onRangeChange={(dates) => setDates(dates)}
      onNavigate={(date) => setNavigatedDate(date)}
      components={{ event: (e) => displayEvent(props, e, refreshFn) }}
      tooltipAccessor={(e) => updateTooltip(e, props, currentView)}
      eventPropGetter={eventStyleGetter}
      defaultDate={new Date(Date.now())}
      scrollToTime={new Date(Date.now())}
    />
  ) : (
    <Spinner />
  );
};

export const mapStateToProps = ({ app, home }: any) => {
  return {
    activities: home.activities,
    activityTypes: app.activityTypes,
    assignees: app.allAssignees,
    selectedProperty: app.selectedProperty,
  };
};

export const mapDispatchToProps = (dispatch: any): Object => {
  const actions = bindActionCreators(
    {
      ...prospectActions,
      getAllActivityTypes,
      getAllActivitiesByAssignee,
      clearActivitiesByAssignee,
    },
    dispatch,
  );
  return { actions };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssigneeCalendar);

export default withLDConsumer()(connected);
