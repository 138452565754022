import moment from 'moment';
import type { DisableUnAvailableDates } from './types';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils';

export const getNextClosestAvailableDate = (
  datesArray: (string | null)[] = [],
  referenceDate: string,
  currentEditingIndex: number,
) => {
  // Convert referenceDate to a formatted string
  const referenceDateString = formatDateDB(referenceDate);
  let closestDateString = null;

  for (let i = 0; i < datesArray.length; i++) {
    for (let j = 0; j < datesArray[i].length; j++) {
      let date = datesArray[i][j];
      if (date !== null) {
        // Convert date to a formatted string
        const dateStr = formatDateDB(date);
        if (
          dateStr > referenceDateString &&
          (closestDateString === null || dateStr < closestDateString) &&
          i !== currentEditingIndex
        ) {
          closestDateString = dateStr;
        }
      }
    }
  }

  return closestDateString ?? null;
};

const processUnavailableDates = (
  unAvailableDates,
  currentDay,
  editMode,
  isEndDateField,
  currentEditingIndex,
  currentEditingMaxEndDate,
): boolean => {
  // Format currentDay as 'YYYY-MM-DD'
  const currentDayString = formatDateDB(currentDay);

  return unAvailableDates.some((date, index) => {
    const [startDateString, endDateString] = date;

    // Convert moment to formatted string 'YYYY-MM-DD'
    const startDate = formatDateDB(startDateString);

    // If endDate is null, block future dates for 10 years
    const endDate = endDateString
      ? formatDateDB(endDateString)
      : formatDateDB(moment(startDate).add(10, 'year'));

    const isCurrentEditingDates = index === currentEditingIndex;
    let isDisabled = false;

    // Check for editMode and whether current dates are the ones being edited
    if (editMode && isCurrentEditingDates) {
      if (isEndDateField) {
        isDisabled = currentDayString === startDate;
      }
    } else {
      isDisabled = currentDayString >= startDate && currentDayString <= endDate;
    }

    // Check for endDateField and whether currentDay is after the currentEditingMaxEndDate
    if (currentEditingMaxEndDate && isEndDateField && !isDisabled) {
      const currentEditingMaxEndDateString = formatDateDB(
        currentEditingMaxEndDate,
      );
      isDisabled = currentDayString > currentEditingMaxEndDateString;
    }

    return isDisabled;
  });
};

export const disableUnAvailableDates = ({
  unAvailableDates = [],
  actualOrAnticipatedMoveOutDate,
  editMode,
  isEndDateField,
  currentEditingIndex,
  currentEditingMaxEndDate,
  day,
}: DisableUnAvailableDates): boolean => {
  // Convert day to a formatted string
  const currentDayString = formatDateDB(day);

  // Check for unavailable dates and process them
  let disableDay = unAvailableDates.length
    ? processUnavailableDates(
        unAvailableDates,
        currentDayString,
        editMode,
        isEndDateField,
        currentEditingIndex,
        currentEditingMaxEndDate,
      )
    : false;

  // Check if currentDay is after the actualOrAnticipatedMoveOutDate
  if (
    !disableDay &&
    actualOrAnticipatedMoveOutDate &&
    currentDayString > formatDateDB(actualOrAnticipatedMoveOutDate)
  ) {
    disableDay = true;
  }

  return disableDay;
};

export const calculateFinalMaxDate = ({
  unAvailableDates,
  selectedStartDate,
  editMode,
  currentEditingMaxEndDate,
}) => {
  let maxDate;

  const hasNullEndDate = unAvailableDates.some((date) => date[1] === null);

  // Convert selectedStartDate and currentEditingMaxEndDate to formatted strings
  const selectedStartDateString = formatDateDB(selectedStartDate);
  const currentEditingMaxEndDateString = currentEditingMaxEndDate
    ? formatDateDB(currentEditingMaxEndDate)
    : null;

  const maxDateCandidates = unAvailableDates
    .map((date) => formatDateDB(date[0]))
    .filter((startDateString) => startDateString > selectedStartDateString)
    .sort();

  if (maxDateCandidates.length > 0) {
    maxDate = maxDateCandidates[maxDateCandidates.length - 1];
  }

  const shouldSetMaxDate = editMode && hasNullEndDate;

  const finalMaxDate =
    currentEditingMaxEndDateString || (shouldSetMaxDate && maxDate)
      ? maxDate
      : undefined;

  return finalMaxDate;
};
