import React, { useEffect } from 'react';
import { Button, Col } from 'react-bootstrap';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';
import { SubmissionError, submit } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { any, keys } from 'ramda';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import * as actions from './actions';
import * as appActions from '../App/actions';
import * as selectors from './selectors';
import * as appSelectors from '../App/selectors';
import type { Props } from './types';
import Header from './Header';
import Form from './Form';
import confirm from '../../components/ConfirmDialogModal';
import { getSentAndExecutedStatus } from '../../utils/lease-helpers';
import type { EditResidentHouseholdFormValues } from './Form/types';
import validate from './Form/validate';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  Banner,
  Grid,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import messages from '../App/messages';

export const EditResidentHousehold = (props: Props) => {
  const {
    clearState,
    getHousehold,
    getAllPendingApplicants,
    getApplicantTypes,
    getPetBreeds,
    getPetTypes,
    getRelationshipTypes,
    getSuffixes,
    getAllAffordableRelationships,
    getLatestHouseholdHistory,
    getHouseholdLeasesByResidentId,
    getHouseholdHasOpenCerts,
    match,
    enablePrompt,
    householdHistory,
    leases,
    isHouseholdLoading,
    isHouseholdHistoryLoading,
    financiallyResponsibleNames,
    isApplicantTypesLoading,
    isPetTypesLoading,
    isPetBreedsLoading,
    isRelationshipsLoading,
    isAffordableRelationshipsLoading,
    isSuffixesLoading,
    intl,
    householdHasOpenCerts,
  } = props;

  const isLoading =
    isHouseholdLoading ||
    isHouseholdHistoryLoading ||
    isApplicantTypesLoading ||
    isPetTypesLoading ||
    isPetBreedsLoading ||
    isRelationshipsLoading ||
    isAffordableRelationshipsLoading ||
    isSuffixesLoading;

  const residentId = match?.params?.residentId;
  useEffect(() => {
    clearState();
    getHousehold(residentId);
    getAllPendingApplicants(residentId);
    getApplicantTypes();
    getPetBreeds();
    getPetTypes();
    getRelationshipTypes();
    getSuffixes();
    getAllAffordableRelationships();
    getLatestHouseholdHistory(residentId);
    getHouseholdLeasesByResidentId(residentId);
    getHouseholdHasOpenCerts(residentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getHousehold(residentId);
    getHouseholdHasOpenCerts(residentId);
  }, [residentId, getHousehold, getHouseholdHasOpenCerts]);

  const onSaveClick = () => {
    confirm('Are you sure you want to save?').then(() =>
      props.submit('editResidentHousehold'),
    );
  };

  const getPendingApplicantFormValues = (values: any) => {
    if (values.pendingPets) {
      return {
        pendingPets: [
          ...values.pendingPets.map((pet) => ({
            ...pet,
            petTypeId: pet.petTypeId === 'default' ? undefined : pet.petTypeId,
            weight: pet.weight === '' ? undefined : pet.weight,
            petTag: pet.petTag === '' ? undefined : pet.petTag,
          })),
        ],
      };
    }
    return { pendingPets: [] };
  };

  const getResidentFormFromValues = (values: any) => {
    return {
      ...values,
      adults: [
        ...values.adults.map((adult) => ({
          ...adult,
          suffix: adult.suffix === 'default' ? undefined : adult.suffix,
          relationshipId:
            adult.relationshipId === 'default'
              ? undefined
              : adult.relationshipId,
          affordableRelationshipId:
            adult.affordableRelationshipId === 'default'
              ? undefined
              : adult.affordableRelationshipId,
          isPrimary: values.primaryResidentId === adult.id,
        })),
      ],
      minors: [
        ...values.minors.map((minor) => ({
          ...minor,
          relationshipId:
            minor.relationshipId === 'default'
              ? undefined
              : minor.relationshipId,
          affordableRelationshipId:
            minor.affordableRelationshipId === 'default'
              ? undefined
              : minor.affordableRelationshipId,
        })),
      ],
      pets: [
        ...values.pets.map((pet) => ({
          ...pet,
          petTypeId: pet.petTypeId === 'default' ? undefined : pet.petTypeId,
          breedId: pet.breedId === 'default' ? undefined : pet.breedId,
          petTag: pet.petTag === '' ? undefined : pet.petTag,
          weight: pet.weight === '' ? undefined : pet.weight,
        })),
      ],
    };
  };

  const onSubmit = (values: EditResidentHouseholdFormValues) => {
    const errors = validate(
      values,
      props.petTypeOptions,
      props.applicantTypesOptions,
    );
    const hasKeys = (o) => keys(o).length > 0;

    if (any(hasKeys)([...errors.pets, ...errors.pendingPets, ...errors.adults]))
      throw new SubmissionError(errors);
    const residentToUpdate = getResidentFormFromValues(values);
    const pendingApplicantsToUpdate = getPendingApplicantFormValues(values);
    props.updatePendingApplicants(
      props.match.params.residentId,
      pendingApplicantsToUpdate,
    );
    props.editHousehold(props.match.params.residentId, residentToUpdate);
  };
  const onCancel = () => {
    navigateToUrlWithSelectedPropertyId(
      `/resident/${props.match.params.residentId}`,
    );
  };

  const lease = leases?.length ? leases[0] : {};

  const { leaseExecuted, leaseSentToPortal } = getSentAndExecutedStatus(lease);

  const lastEditedAt = moment
    .utc(householdHistory?.createdAt)
    .local()
    .format('MMM D, YYYY @ LT');

  if (isLoading) {
    return (
      <Spinner
        sx={{
          height: '100vh',
          width: '100%',
          position: 'fixed',
          zIndex: 1,
        }}
      />
    );
  }

  return (
    <DocumentTitle title={props.pageTitle}>
      <div className="container-fluid">
        <Prompt
          message="Are you sure you want to close without saving changes?"
          when={enablePrompt}
        />
        <Header
          financiallyResponsibleNames={financiallyResponsibleNames}
          residentId={residentId}
          leaseExecuted={leaseExecuted}
          leaseSentToPortal={leaseSentToPortal}
        />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {householdHasOpenCerts && (
            <Grid item xs={12}>
              <Banner
                color="warning"
                text={intl.formatMessage(messages.editHouseholdBlocked)}
                BoxProps={{
                  sx: {
                    width: '100%',
                    maxWidth: 'none',
                  },
                }}
              />
            </Grid>
          )}
          {!isHouseholdHistoryLoading && (
            <Grid item xs={12}>
              <Banner
                color="blue"
                text={intl.formatMessage(messages.lastEditToPrimary, {
                  dateTime: lastEditedAt,
                })}
                BoxProps={{ sx: { width: '100%', maxWidth: 'none' } }}
              />
            </Grid>
          )}
        </Grid>
        {!isHouseholdLoading && (
          <Form
            onSubmit={onSubmit}
            leaseLocked={
              householdHasOpenCerts || leaseExecuted || leaseSentToPortal
            }
            householdHasOpenCerts={householdHasOpenCerts}
          />
        )}
        <Col xs={6}>
          <Button
            data-testid="cancel"
            className="pull-right"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            bsStyle="primary"
            className="pull-left"
            onClick={onSaveClick}
            disabled={householdHasOpenCerts}
          >
            Save Changes
          </Button>
        </Col>
      </div>
    </DocumentTitle>
  );
};

export default connect(
  createStructuredSelector({
    pageTitle: selectors.getPageTitle,
    isHouseholdLoading: selectors.isHouseholdLoading,
    enablePrompt: selectors.enablePrompt,
    petTypeOptions: appSelectors.getPetTypeOptions,
    isHouseholdHistoryLoading: selectors.isHouseholdHistoryLoading,
    householdHistory: selectors.getHouseholdHistory,
    applicantTypesOptions: appSelectors.getApplicantTypeOptions,
    leases: selectors.getHouseholdLeases,
    financiallyResponsibleNames: selectors.getFinanciallyResponsibleNames,
    isApplicantTypesLoading: selectors.isApplicantTypesLoading,
    isPetTypesLoading: selectors.isPetTypesLoading,
    isPetBreedsLoading: selectors.isPetBreedsLoading,
    isRelationshipsLoading: selectors.isRelationshipsLoading,
    isAffordableRelationshipsLoading:
      selectors.isAffordableRelationshipsLoading,
    isSuffixesLoading: selectors.isSuffixesLoading,
    householdHasOpenCerts: selectors.getHouseholdHasOpenCerts,
  }),
  {
    clearState: actions.clearState,
    getHousehold: actions.getResidentHousehold,
    getAllPendingApplicants: actions.getAllPendingApplicants,
    editHousehold: actions.editResidentHousehold,
    updatePendingApplicants: actions.updatePendingApplicants,
    getApplicantTypes: appActions.getAllApplicantTypes,
    getPetBreeds: appActions.getAllPetBreeds,
    getPetTypes: appActions.getAllPetTypes,
    getRelationshipTypes: appActions.getAllRelationships,
    getAllAffordableRelationships: appActions.getAllAffordableRelationships,
    getSuffixes: appActions.getAllNameSuffixes,
    getLatestHouseholdHistory: actions.getLatestHouseholdHistory,
    getHouseholdLeasesByResidentId: actions.getHouseholdLeasesByResidentId,
    getHouseholdHasOpenCerts: actions.getHouseholdHasOpenCerts,
    submit,
  },
)(injectIntl(EditResidentHousehold));
