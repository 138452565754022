import { useMutation, MutationOptions } from 'react-query';
import useAbortController from '../../hooks/useAbortController';
import AssignedUnitsService from '../../services/assignedUnitsService';

const useAssignUnitMutation = ({
  organizationId,
  propertyId,
  applicationId,
  options,
}: {
  organizationId: string,
  propertyId: string,
  applicationId: string,
  options?: MutationOptions,
}) => {
  const abortControllerOptions = useAbortController();
  return useMutation(
    (unitId: string) =>
      new AssignedUnitsService().save(
        organizationId,
        propertyId,
        {
          unitId,
          applicationId,
        },
        abortControllerOptions,
      ),
    options,
  );
};

export default useAssignUnitMutation;
