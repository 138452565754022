import { useEffect } from 'react';

function useExitPrompt({ active = true }) {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!active) return;

      event.preventDefault();

      const message = 'Are you sure you want to leave this page?';

      event.returnValue = message;

      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [active]);
}

export default useExitPrompt;
