import React, { useState, useEffect } from 'react';
import { pathOr, isNil } from 'ramda';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  reduxForm,
  Field,
  formValueSelector,
  change,
  untouch,
} from 'redux-form';
import moment from 'moment';
import DashesIfNullOrUndefined from '../../../../components/DashesIfNullOrUndefined';
import ElementWithPermissions from '../../../../components/ElementWithPermissions';

import Spinner from '../../../../components/Spinner';

import messages from './messages';
import validate from './validate';
import {
  renderRadioGroupField,
  renderMultiSelectField,
  renderSelectField,
  renderPercentageField,
  renderTextField,
  renderCurrencyField,
  getMultiSelectDefaultValue,
} from '../../../../utils/redux-form-helper';

import {
  generateBaseAmountOptions,
  generateExpenseCapsOptions,
  generateProRataShareOptions,
  generateGrossUpOptions,
  generateLeaseAllowsMonthlyEstimatesOptions,
  generateIntacctGLAccountDropdownOptions,
  generateSubjournalDropdownOptions,
  generateChargeCodeDropdownOptions,
  generateYearDropdownOptions,
  formatModifyAllocationRequest,
  generateGLAccountsOptions,
} from './utils';

import {
  useFetchIntacctGLAccounts,
  useFetchSubjournals,
  useFetchChargeCodes,
  useModifyCamAllocation,
  useCreateCamException,
  useRemoveCamException,
} from './hooks';

import {
  GROSS_UP_OPTIONS,
  EXPENSE_CAPS_OPTIONS,
  ALLOCATION_SETUP_MODAL_MODES,
  ERR_INVALID_VALUE,
  PRO_RATA_SHARE_OPTIONS,
  BASE_AMOUNT_OPTIONS,
  GL_ACCOUNTS_OPTIONS,
} from './constants';

const StyledForm = styled.form`
  .form-group {
    margin: 0;
  }
  .checkbox {
    margin: 0;
    margin-bottom: 5px;
  }
`;

const GrossUpYesFields = styled.div`
  margin-top: -20px;
  font-size: x-small;
`;

const StyledDiv = styled.div`
  margin-top: -25px;
`;

const ZindexCorrection = styled.div`
  position: relative;
  z-index: ${(props) => props.zIndex || 1};
`;

const validateExpenseCapStartYearExpenses = (value: any) => {
  if (!value) {
    return 'Start Year Expenses Needed';
  } else if (+value < 0) {
    return ERR_INVALID_VALUE;
  }
  return undefined;
};

type Props = {
  intl: Object,
  propertyId: string,
  organizationId: string,
  selectedCamRecord: Object,
  initialValues: Object,
  promptToaster: Function,
  onHide: Function,
  handleSubmit: Function,
  onSubmit: Function,
  valid: boolean,
  reset: Function,
  pristine: boolean,
  change: Function,
  selectedGLAccountsOptions: boolean,
  selectedBaseAmountOptions: string,
  selectedExpenseCapsOptions: string,
  selectedProRataShareOptions: string,
  selectedGrossUpOptions: string,
  selectedAllocation: Object,
  selectedSubjournalId: string,
  selectedReconciliationSubjournald: string,
  selectedGLAccounts: any,
  selectedExceptionName: string,
  selectedExpenseCapLeaseStartYearAggregate: string,
  selectedExpenseCapLeaseStartYearCumulative: string,
  selectedExpenseCapLeaseStartYearCompounded: string,
  allocationSetupModalMode: string,
  onAllocationSaveCallback: Function,
  selectedExpenseCapYoyExcludedGLAccounts: string[],
  selectedExpenseCapAggregateExcludedGLAccounts: string[],
  selectedExpenseCapCumulativeExcludedGLAccounts: string[],
  selectedExpenseCapCompoundedExcludedGLAccounts: string[],
  selectedGrossUpExcludedGLAccounts: string[],
  resetFields: Function,
  refreshAllCamAllocation: Function,
  confirmations: Array<Object>,
};

const getSelectedOptionsFromIds = (
  selectedIds: string[],
  options: Object[],
): Object[] => {
  if (isNil(selectedIds)) {
    return [];
  }
  return selectedIds.reduce((selectedOpts: Object[], curId: string) => {
    const foundOpt = options.find((opt: Object) => opt.value === curId);
    return foundOpt ? [...selectedOpts, foundOpt] : [...selectedOpts];
  }, []);
};

const AllocationSetupModal = (props: Props) => {
  const {
    intl,
    propertyId,
    organizationId,
    selectedCamRecord: { id: camRecordId },
    promptToaster,
    selectedAllocation,
    onHide,
    handleSubmit,
    valid,
    reset,
    pristine,
    change,
    selectedCamRecord,
    selectedGLAccountsOptions,
    selectedBaseAmountOptions,
    selectedExpenseCapsOptions,
    selectedProRataShareOptions,
    selectedGrossUpOptions,
    selectedSubjournalId,
    selectedReconciliationSubjournalId,
    selectedGLAccounts,
    selectedExceptionName,
    selectedExpenseCapLeaseStartYearAggregate,
    selectedExpenseCapLeaseStartYearCumulative,
    selectedExpenseCapLeaseStartYearCompounded,
    allocationSetupModalMode,
    onAllocationSaveCallback,
    initialValues,
    selectedExpenseCapYoyExcludedGLAccounts = [],
    selectedExpenseCapAggregateExcludedGLAccounts = [],
    selectedExpenseCapCumulativeExcludedGLAccounts = [],
    selectedExpenseCapCompoundedExcludedGLAccounts = [],
    selectedGrossUpExcludedGLAccounts = [],
    resetFields,
    refreshAllCamAllocation,
    confirmations,
    touch,
  } = props;
  const allocationId = pathOr(null, ['id'], selectedAllocation);
  const initialIsStartYearExpenseActual = pathOr(
    false,
    ['expenseCap', 'isStartYearExpenseActual'],
    selectedAllocation,
  );
  const initialStartYearExpense = pathOr(
    null,
    ['expenseCap', 'startYearExpenses'],
    selectedAllocation,
  );

  const [isStartYearExpenseActual, setIsStartYearExpenseActual] = useState(
    initialIsStartYearExpenseActual,
  );
  const [showStartYearExpenses, setShowStartYearExpenses] = useState(
    initialStartYearExpense !== null,
  );
  const onSetStartYearExpenses = (expenses: number, isActual: boolean) => {
    if (!isActual) {
      // If we need it, reset the field so it forgets about the old value
      resetFields({
        expenseCapStartYearExpenses: '',
      });
    } else {
      change('expenseCapStartYearExpenses', expenses);
    }
    setIsStartYearExpenseActual(isActual);
    setShowStartYearExpenses(true);
  };

  const hooksProps = {
    intl,
    propertyId,
    organizationId,
    promptToaster,
  };

  const loadAllGLAccounts = (accounts: any) => {
    change(
      'selectedGLAccounts',
      accounts.map((a) => a.id),
    );
  };

  const [isLoadingIntacctGLAccounts, intacctGLAccounts] =
    useFetchIntacctGLAccounts({
      ...hooksProps,
      selectedAllocation,
      loadAllGLAccounts,
    });

  // Subjournals can be shared between "charge code" and "reconciliation charge code"
  const [subjournals] = useFetchSubjournals({
    ...hooksProps,
    selectedAllocation,
  });

  // Available charge codes for "charge code" and "reconciliation charge code" must be
  // separate since users can select different subjournals for each.
  const [chargeCodes] = useFetchChargeCodes({
    ...hooksProps,
    selectedSubjournalId,
  });
  const [reconciliationChargeCodes] = useFetchChargeCodes({
    ...hooksProps,
    selectedSubjournalId: selectedReconciliationSubjournalId,
  });

  const getExcludedAccountsForCaps = () => {
    switch (selectedExpenseCapsOptions) {
      case EXPENSE_CAPS_OPTIONS.YOY:
        return (
          selectedExpenseCapYoyExcludedGLAccounts ||
          initialValues.expenseCapYoyExcludedGLAccounts ||
          []
        );
      case EXPENSE_CAPS_OPTIONS.AGGREGATE:
        return (
          selectedExpenseCapAggregateExcludedGLAccounts ||
          initialValues.expenseCapAggregateExcludedGLAccounts ||
          []
        );
      case EXPENSE_CAPS_OPTIONS.CUMULATIVE:
        return (
          selectedExpenseCapCumulativeExcludedGLAccounts ||
          initialValues.expenseCapCumulativeExcludedGLAccounts ||
          []
        );
      case EXPENSE_CAPS_OPTIONS.COMPOUNDED:
        return (
          selectedExpenseCapCompoundedExcludedGLAccounts ||
          initialValues.expenseCapCompoundedExcludedGLAccounts ||
          []
        );
      default:
        return [];
    }
  };

  const accountsForExpenseCapStartYear = (intacctGLAccounts || [])
    .filter((acct) => {
      const excludeAccounts = getExcludedAccountsForCaps();
      const allGLAccountsToInclude =
        selectedGLAccounts || initialValues.selectedGLAccounts || [];
      return (
        (allGLAccountsToInclude.includes(acct.id) ||
          selectedGLAccountsOptions === GL_ACCOUNTS_OPTIONS.SELECT_ALL) &&
        !(excludeAccounts || []).includes(acct.id)
      );
    })
    .map((acct) => acct.accountNo);

  const onClose = () => {
    reset();
    setShowDeleteConfirmation(false);
    onHide();
  };
  const onModifySucceed = () => {
    onClose();
    onAllocationSaveCallback();
  };

  const [isLoadingModify, modifyCamAllocation] = useModifyCamAllocation({
    ...hooksProps,
    camRecordId,
    refresh: onModifySucceed,
    accountsForExpenseCapStartYear,
    setStartYearExpenses: onSetStartYearExpenses,
  });

  const [isLoadingSaveException, createCamException] = useCreateCamException({
    ...hooksProps,
    camRecordId,
    refresh: onModifySucceed,
    accountsForExpenseCapStartYear,
    setStartYearExpenses: onSetStartYearExpenses,
  });

  const getSelectedGLAccountsFullObjects = (): Array<Object> => {
    if (selectedGLAccountsOptions === GL_ACCOUNTS_OPTIONS.SELECT_ALL) {
      return intacctGLAccounts;
    }
    if (selectedGLAccountsOptions === GL_ACCOUNTS_OPTIONS.NONE) {
      return [];
    }

    // selectedGLAccounts is a list of account IDs.
    // This is the corresponding list of glAccount objects.
    const accounts: Array<any> = (selectedGLAccounts || [])
      .map((glAcctId) => {
        if (!intacctGLAccounts) {
          return null;
        }
        const matchingAccount = intacctGLAccounts.find((acct) => {
          const acctId = pathOr(null, ['id'], acct);
          return acctId === glAcctId;
        });
        return matchingAccount ? matchingAccount : null;
      })
      .filter((acct) => !!acct);
    return accounts;
  };

  const name = pathOr(
    pathOr('', ['intacctGLAccountGroup', 'name'], selectedAllocation),
    ['camException', 'name'],
    selectedAllocation,
  );

  const unitRentableSqFt = pathOr(
    null,
    ['unitRentableSquareFeet'],
    selectedCamRecord,
  );
  const propertyRentableSqFt = pathOr(
    null,
    ['propertyRentableSquareFeet'],
    selectedCamRecord,
  );
  const proRataShare = pathOr(null, ['proRataShare'], selectedCamRecord);

  const onSubmit = async (payload) => {
    const additionalData = {
      proRataShareActualPct: proRataShare,
      proRataShareId: pathOr(null, ['proRataShare', 'id'], selectedAllocation),
      baseAmountId: pathOr(null, ['baseAmount', 'id'], selectedAllocation),
      expenseCapId: pathOr(null, ['expenseCap', 'id'], selectedAllocation),
      grossUpId: pathOr(null, ['grossUp', 'id'], selectedAllocation),
      isStartYearExpenseActual: isStartYearExpenseActual,
    };
    const formattedPayload = formatModifyAllocationRequest(
      payload,
      intacctGLAccounts,
      additionalData,
    );

    if (allocationSetupModalMode === ALLOCATION_SETUP_MODAL_MODES.EXCEPTION) {
      createCamException(selectedExceptionName, formattedPayload);
    } else {
      modifyCamAllocation(allocationId, formattedPayload);
    }
  };

  const filterExcludedAccounts = (includedAccounts: Object[]) => {
    const isIncludedAccount = (a) => includedAccounts.includes(a);

    change(
      'expenseCapYoyExcludedGLAccounts',
      selectedExpenseCapYoyExcludedGLAccounts.filter(isIncludedAccount),
    );
    change(
      'expenseCapAggregateExcludedGLAccounts',
      selectedExpenseCapAggregateExcludedGLAccounts.filter(isIncludedAccount),
    );
    change(
      'expenseCapCumulativeExcludedGLAccounts',
      selectedExpenseCapCumulativeExcludedGLAccounts.filter(isIncludedAccount),
    );
    change(
      'expenseCapCompoundedExcludedGLAccounts',
      selectedExpenseCapCompoundedExcludedGLAccounts.filter(isIncludedAccount),
    );
    change(
      'grossUpExcludedGLAccounts',
      selectedGrossUpExcludedGLAccounts.filter(isIncludedAccount),
    );
  };

  const changeMultiSelectFieldValue = (fieldName: string, values: any) => {
    const newValues = (values || []).map((val) => val.value);
    change(fieldName, newValues);

    if (fieldName === 'selectedGLAccounts') filterExcludedAccounts(newValues);
  };

  const glAccountsForExcludedOptions = getSelectedGLAccountsFullObjects() || [];

  const isEstimatesConfirmed = !!(confirmations && confirmations.length !== 0);
  const scope = isEstimatesConfirmed ? ['cam-tab-edit-current-cam'] : null;

  const isAddCAMException =
    allocationSetupModalMode === ALLOCATION_SETUP_MODAL_MODES.EXCEPTION;
  const disableOptions = selectedGLAccountsOptions === GL_ACCOUNTS_OPTIONS.NONE;
  const disableGLAccountsDropDown =
    selectedGLAccountsOptions === GL_ACCOUNTS_OPTIONS.NONE ||
    isEstimatesConfirmed;
  const disableGlAccountCheckboxes = isEstimatesConfirmed;

  const showIsLoading =
    isLoadingModify || isLoadingSaveException || isLoadingIntacctGLAccounts;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const camException = pathOr(null, ['camException', 'id'], selectedAllocation);
  const camStartDate = pathOr('', ['startDate'], selectedCamRecord);

  const showDeleteIcon =
    !isAddCAMException && camException && !isEstimatesConfirmed;

  // Start Year Expenses field is only needed if YOY, Aggregate, Cumulative or Compounded is selected,
  // and we couldn't get it from Intacct
  const showAggregateStartYearExpense =
    !isNil(selectedExpenseCapLeaseStartYearAggregate) &&
    showStartYearExpenses &&
    selectedExpenseCapsOptions === EXPENSE_CAPS_OPTIONS.AGGREGATE;
  const showCumulativeStartYearExpense =
    !isNil(selectedExpenseCapLeaseStartYearCumulative) &&
    showStartYearExpenses &&
    selectedExpenseCapsOptions === EXPENSE_CAPS_OPTIONS.CUMULATIVE;
  const showCompoundedStartYearExpense =
    !isNil(selectedExpenseCapLeaseStartYearCompounded) &&
    showStartYearExpenses &&
    selectedExpenseCapsOptions === EXPENSE_CAPS_OPTIONS.COMPOUNDED;
  const showYoyStartYearExpense =
    showStartYearExpenses &&
    selectedExpenseCapsOptions === EXPENSE_CAPS_OPTIONS.YOY;

  useEffect(() => {
    touch('expenseCapStartYearExpenses');
  }, [
    showAggregateStartYearExpense,
    showCumulativeStartYearExpense,
    showCompoundedStartYearExpense,
    showYoyStartYearExpense,
    touch,
  ]);

  const handleResetFields = (selectedOption) => {
    switch (selectedOption) {
      case 'glOptionNone':
        resetFields({
          proRataCustomPercentage: '',
          baseYear: '',
          baseYearAmount: '',
          ownerStopExpenseAmount: '',
          expenseCapAmount: '',
          expenseCapPercentageAggregate: '',
          expenseCapLeaseStartYearAggregate: '',
          expenseCapPercentageCumulative: '',
          expenseCapLeaseStartYearCumulative: '',
          expenseCapPercentageCompounded: '',
          expenseCapLeaseStartYearCompounded: '',
          expenseCapStartYearExpenses: '',
          expenseCapYoyExcludedGLAccounts: [[]],
          expenseCapAggregateExcludedGLAccounts: [[]],
          expenseCapCumulativeExcludedGLAccounts: [[]],
          expenseCapCompoundedExcludedGLAccounts: [[]],
          grossUpYesPercentage: '',
          grossUpExcludedGLAccounts: [[]],
        });
        break;
      case 'proRataActual':
        resetFields({ proRataCustomPercentage: '' });
        break;
      case 'baseAmountBaseYear':
        resetFields({ ownerStopExpenseAmount: '' });
        break;
      case 'baseAmountOwnerStopExpense':
        resetFields({ baseYear: '', baseYearAmount: '' });
        break;
      case 'baseAmountNone':
        resetFields({
          baseYear: '',
          baseYearAmount: '',
          ownerStopExpenseAmount: '',
        });
        break;
      case 'expenseCapYoy':
        resetFields({
          expenseCapPercentageAggregate: '',
          expenseCapLeaseStartYearAggregate: '',
          expenseCapPercentageCumulative: '',
          expenseCapLeaseStartYearCumulative: '',
          expenseCapPercentageCompounded: '',
          expenseCapLeaseStartYearCompounded: '',
          expenseCapStartYearExpenses: '',
          expenseCapAggregateExcludedGLAccounts: [[]],
          expenseCapCumulativeExcludedGLAccounts: [[]],
          expenseCapCompoundedExcludedGLAccounts: [[]],
        });
        setShowStartYearExpenses(false);
        setIsStartYearExpenseActual(false);
        break;
      case 'expenseCapAggregate':
        resetFields({
          expenseCapAmount: '',
          expenseCapStartYearExpenses: '',
          expenseCapYoyExcludedGLAccounts: [[]],
          expenseCapCumulativeExcludedGLAccounts: [[]],
          expenseCapCompoundedExcludedGLAccounts: [[]],
          expenseCapPercentageCumulative: '',
          expenseCapLeaseStartYearCumulative: '',
          expenseCapPercentageCompounded: '',
          expenseCapLeaseStartYearCompounded: '',
        });
        setShowStartYearExpenses(false);
        setIsStartYearExpenseActual(false);
        break;
      case 'expenseCapCumulative':
        resetFields({
          expenseCapAmount: '',
          expenseCapStartYearExpenses: '',
          expenseCapYoyExcludedGLAccounts: [[]],
          expenseCapAggregateExcludedGLAccounts: [[]],
          expenseCapCompoundedExcludedGLAccounts: [[]],
          expenseCapPercentageAggregate: '',
          expenseCapLeaseStartYearAggregate: '',
          expenseCapPercentageCompounded: '',
          expenseCapLeaseStartYearCompounded: '',
        });
        setShowStartYearExpenses(false);
        setIsStartYearExpenseActual(false);
        break;
      case 'expenseCapCompounded':
        resetFields({
          expenseCapAmount: '',
          expenseCapStartYearExpenses: '',
          expenseCapYoyExcludedGLAccounts: [[]],
          expenseCapAggregateExcludedGLAccounts: [[]],
          expenseCapCumulativeExcludedGLAccounts: [[]],
          expenseCapPercentageAggregate: '',
          expenseCapLeaseStartYearAggregate: '',
          expenseCapPercentageCumulative: '',
          expenseCapLeaseStartYearCumulative: '',
        });
        setShowStartYearExpenses(false);
        setIsStartYearExpenseActual(false);
        break;
      case 'expenseCapNone':
        resetFields({
          expenseCapAmount: '',
          expenseCapPercentageAggregate: '',
          expenseCapLeaseStartYearAggregate: '',
          expenseCapPercentageCumulative: '',
          expenseCapLeaseStartYearCumulative: '',
          expenseCapPercentageCompounded: '',
          expenseCapLeaseStartYearCompounded: '',
          expenseCapStartYearExpenses: '',
          expenseCapYoyExcludedGLAccounts: [[]],
          expenseCapAggregateExcludedGLAccounts: [[]],
          expenseCapCumulativeExcludedGLAccounts: [[]],
          expenseCapCompoundedExcludedGLAccounts: [[]],
        });
        setShowStartYearExpenses(false);
        setIsStartYearExpenseActual(false);
        break;
      case 'grossUpNo':
        resetFields({
          grossUpYesPercentage: '',
          grossUpExcludedGLAccounts: [[]],
        });
        break;
      default:
        break;
    }
  };

  const removeCamException = useRemoveCamException({
    ...hooksProps,
    refreshCamAllocationTable: refreshAllCamAllocation,
    onSuccess: () => onClose(),
  });

  const renderModalDeleteConfirmationBody = (
    <div className="modal-confirm">
      <Row>
        <Col xs={12}>
          <h1>
            <FormattedMessage {...messages.deleteConfirmationMsg} />
          </h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="danger"
            className="pull-right"
            onClick={() => removeCamException(camException)}
          >
            <FormattedMessage {...messages.yes} />
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="default"
            className="pull-left"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            <FormattedMessage {...messages.no} />
          </Button>
        </Col>
      </Row>
    </div>
  );

  const allocationSetupModalTitle = (
    <div>
      {name} <FormattedMessage {...messages.pool} />
    </div>
  );

  const glAccountsDropDownOptions =
    generateIntacctGLAccountDropdownOptions(intacctGLAccounts);

  const glAccountsForExcludedDropdownOptions =
    generateIntacctGLAccountDropdownOptions([...glAccountsForExcludedOptions]);

  const glAccountsField = (
    <Field
      name="glAccountsOptions"
      fieldName="glAccountsOptions"
      options={generateGLAccountsOptions()}
      component={renderRadioGroupField}
      disabled={disableGlAccountCheckboxes}
      onClick={(e) => {
        handleResetFields(e.target.value);
      }}
    />
  );

  const selectedGLAccountsField = (
    <Field
      name="selectedGLAccounts"
      component={renderMultiSelectField}
      defaultValue={getMultiSelectDefaultValue(
        'selectedGLAccounts',
        initialValues,
        glAccountsDropDownOptions,
      )}
      bsSize="lg"
      options={glAccountsDropDownOptions}
      onChangeCallback={(val) =>
        changeMultiSelectFieldValue('selectedGLAccounts', val)
      }
      controlledValue={getSelectedOptionsFromIds(
        selectedGLAccounts,
        glAccountsDropDownOptions,
      )}
      isDisabled={disableGLAccountsDropDown}
    />
  );

  const proRataShareOptionsField = (
    <Field
      name="proRataShareOptions"
      fieldName="proRataShareOptions"
      options={generateProRataShareOptions()}
      component={renderRadioGroupField}
      disabled={disableOptions}
      style={{
        width: '100%',
      }}
      onClick={(e) => {
        handleResetFields(e.target.value);
      }}
    />
  );
  const premiseSqFt = unitRentableSqFt;
  const propertySqFt = propertyRentableSqFt;
  const proRataCustomPercentageField = (
    <Field
      name="proRataCustomPercentage"
      component={renderPercentageField}
      disabled={
        disableOptions ||
        selectedProRataShareOptions !== PRO_RATA_SHARE_OPTIONS.CUSTOM
      }
    />
  );

  const baseAmountOptionsField = (
    <Field
      name="baseAmountOptions"
      fieldName="baseAmountOptions"
      options={generateBaseAmountOptions(
        disableOptions,
        selectedBaseAmountOptions,
        scope,
      )}
      component={renderRadioGroupField}
      disabled={disableOptions}
      onClick={(e) => {
        handleResetFields(e.target.value);
      }}
    />
  );

  const baseYearAmountField = (
    <Field
      name="baseYearAmount"
      component={renderCurrencyField}
      disabled={
        disableOptions ||
        selectedBaseAmountOptions !== BASE_AMOUNT_OPTIONS.BASE_YEAR
      }
    />
  );
  const ownerStopExpenseAmountField = (
    <Field
      name="ownerStopExpenseAmount"
      component={renderCurrencyField}
      disabled={
        disableOptions ||
        selectedBaseAmountOptions !== BASE_AMOUNT_OPTIONS.OWNER_STOP_EXPENSE
      }
    />
  );

  const expenseCapsOptionsField = (
    <Field
      name="expenseCapsOptions"
      fieldName="expenseCapsOptions"
      options={generateExpenseCapsOptions(
        showYoyStartYearExpense,
        showAggregateStartYearExpense,
        showCumulativeStartYearExpense,
        showCompoundedStartYearExpense,
        selectedExpenseCapYoyExcludedGLAccounts,
        selectedExpenseCapAggregateExcludedGLAccounts,
        selectedExpenseCapCumulativeExcludedGLAccounts,
        selectedExpenseCapCompoundedExcludedGLAccounts,
      )}
      component={renderRadioGroupField}
      disabled={disableOptions}
      onClick={(e) => {
        handleResetFields(e.target.value);
      }}
    />
  );

  const expenseCapAmountField = (
    <Field
      name="expenseCapAmount"
      component={renderCurrencyField}
      min="0"
      max="1000000"
      disabled={
        disableOptions ||
        selectedExpenseCapsOptions !== EXPENSE_CAPS_OPTIONS.YOY
      }
    />
  );

  const expenseCapYoyExcludedGLAccountsField = (
    <Field
      name="expenseCapYoyExcludedGLAccounts"
      component={renderMultiSelectField}
      bsSize="sm"
      options={glAccountsForExcludedDropdownOptions}
      defaultValue={getMultiSelectDefaultValue(
        'expenseCapYoyExcludedGLAccounts',
        initialValues,
        glAccountsDropDownOptions,
      )}
      onChangeCallback={(val) => {
        changeMultiSelectFieldValue('expenseCapYoyExcludedGLAccounts', val);
        resetFields({
          expenseCapStartYearExpenses: '',
        });
        setShowStartYearExpenses(false);
      }}
      isDisabled={
        disableOptions ||
        selectedExpenseCapsOptions !== EXPENSE_CAPS_OPTIONS.YOY
      }
      controlledValue={getSelectedOptionsFromIds(
        selectedExpenseCapYoyExcludedGLAccounts,
        glAccountsForExcludedDropdownOptions,
      )}
    />
  );

  const expenseCapStartYearExpensesField = (
    <Field
      name="expenseCapStartYearExpenses"
      component={renderCurrencyField}
      min="0"
      max="1000000"
      disabled={disableOptions || isStartYearExpenseActual}
      validate={[validateExpenseCapStartYearExpenses]}
    />
  );

  const expenseCapPercentageFieldGeneric = (name: string, enableForOption) => (
    <Field
      name={name}
      component={renderPercentageField}
      step="0.01"
      disabled={
        disableOptions || selectedExpenseCapsOptions !== enableForOption
      }
    />
  );

  const expenseCapPercentageAggregateField = expenseCapPercentageFieldGeneric(
    'expenseCapPercentageAggregate',
    EXPENSE_CAPS_OPTIONS.AGGREGATE,
  );

  const expenseCapPercentageCumulativeField = expenseCapPercentageFieldGeneric(
    'expenseCapPercentageCumulative',
    EXPENSE_CAPS_OPTIONS.CUMULATIVE,
  );

  const expenseCapPercentageCompoundedField = expenseCapPercentageFieldGeneric(
    'expenseCapPercentageCompounded',
    EXPENSE_CAPS_OPTIONS.COMPOUNDED,
  );

  const expenseCapAggregateExcludedGLAccountsField = (
    <Field
      name="expenseCapAggregateExcludedGLAccounts"
      component={renderMultiSelectField}
      bsSize="sm"
      options={[...glAccountsForExcludedDropdownOptions]}
      defaultValue={getMultiSelectDefaultValue(
        'expenseCapAggregateExcludedGLAccounts',
        initialValues,
        glAccountsDropDownOptions,
      )}
      onChangeCallback={(val) => {
        changeMultiSelectFieldValue(
          'expenseCapAggregateExcludedGLAccounts',
          val,
        );
        resetFields({
          expenseCapStartYearExpenses: '',
        });
        setShowStartYearExpenses(false);
      }}
      isDisabled={
        disableOptions ||
        selectedExpenseCapsOptions !== EXPENSE_CAPS_OPTIONS.AGGREGATE
      }
      controlledValue={getSelectedOptionsFromIds(
        selectedExpenseCapAggregateExcludedGLAccounts,
        glAccountsForExcludedDropdownOptions,
      )}
    />
  );

  const expenseCapCumulativeExcludedGLAccountsField = (
    <Field
      name="expenseCapCumulativeExcludedGLAccounts"
      component={renderMultiSelectField}
      bsSize="sm"
      options={[...glAccountsForExcludedDropdownOptions]}
      defaultValue={getMultiSelectDefaultValue(
        'expenseCapCumulativeExcludedGLAccounts',
        initialValues,
        glAccountsDropDownOptions,
      )}
      onChangeCallback={(val) => {
        changeMultiSelectFieldValue(
          'expenseCapCumulativeExcludedGLAccounts',
          val,
        );
        resetFields({
          expenseCapStartYearExpenses: '',
        });
        setShowStartYearExpenses(false);
      }}
      isDisabled={
        disableOptions ||
        selectedExpenseCapsOptions !== EXPENSE_CAPS_OPTIONS.CUMULATIVE
      }
      controlledValue={getSelectedOptionsFromIds(
        selectedExpenseCapCumulativeExcludedGLAccounts,
        glAccountsForExcludedDropdownOptions,
      )}
    />
  );

  const expenseCapCompoundedExcludedGLAccountsField = (
    <Field
      name="expenseCapCompoundedExcludedGLAccounts"
      component={renderMultiSelectField}
      bsSize="sm"
      options={[...glAccountsForExcludedDropdownOptions]}
      defaultValue={getMultiSelectDefaultValue(
        'expenseCapCompoundedExcludedGLAccounts',
        initialValues,
        glAccountsDropDownOptions,
      )}
      onChangeCallback={(val) => {
        changeMultiSelectFieldValue(
          'expenseCapCompoundedExcludedGLAccounts',
          val,
        );
        resetFields({
          expenseCapStartYearExpenses: '',
        });
        setShowStartYearExpenses(false);
      }}
      isDisabled={
        disableOptions ||
        selectedExpenseCapsOptions !== EXPENSE_CAPS_OPTIONS.COMPOUNDED
      }
      controlledValue={getSelectedOptionsFromIds(
        selectedExpenseCapCompoundedExcludedGLAccounts,
        glAccountsForExcludedDropdownOptions,
      )}
    />
  );

  const expenseCapLeaseStartYearFieldGeneric = (
    name: string,
    enableForOption,
  ) => (
    <Field
      name={name}
      component={renderSelectField}
      options={generateYearDropdownOptions(100)}
      disabled={
        disableOptions || selectedExpenseCapsOptions !== enableForOption
      }
      onChange={(e) => {
        resetFields({
          expenseCapStartYearExpenses: '',
        });
        setShowStartYearExpenses(false);
      }}
    />
  );

  const expenseCapLeaseStartYearAggregateField =
    expenseCapLeaseStartYearFieldGeneric(
      'expenseCapLeaseStartYearAggregate',
      EXPENSE_CAPS_OPTIONS.AGGREGATE,
    );

  const expenseCapLeaseStartYearCumulativeField =
    expenseCapLeaseStartYearFieldGeneric(
      'expenseCapLeaseStartYearCumulative',
      EXPENSE_CAPS_OPTIONS.CUMULATIVE,
    );

  const expenseCapLeaseStartYearCompoundedField =
    expenseCapLeaseStartYearFieldGeneric(
      'expenseCapLeaseStartYearCompounded',
      EXPENSE_CAPS_OPTIONS.COMPOUNDED,
    );

  const grossUpOptionsField = (
    <Field
      name="grossUpOptions"
      fieldName="grossUpOptions"
      options={generateGrossUpOptions(selectedGrossUpExcludedGLAccounts)}
      component={renderRadioGroupField}
      disabled={disableOptions}
      onClick={(e) => {
        handleResetFields(e.target.value);
      }}
    />
  );
  const grossUpYesPercentageField = (
    <Field
      name="grossUpYesPercentage"
      component={renderPercentageField}
      disabled={
        disableOptions || selectedGrossUpOptions !== GROSS_UP_OPTIONS.YES
      }
    />
  );
  const grossUpYesAvgOccupancField = (
    <Field
      name="grossUpYesAvgOccupancy"
      component={renderPercentageField}
      disabled={
        disableOptions || selectedGrossUpOptions !== GROSS_UP_OPTIONS.YES
      }
    />
  );
  const grossUpExcludedGLAccountsField = (
    <Field
      name="grossUpExcludedGLAccounts"
      component={renderMultiSelectField}
      options={[...glAccountsForExcludedDropdownOptions]}
      defaultValue={getMultiSelectDefaultValue(
        'grossUpExcludedGLAccounts',
        initialValues,
        glAccountsDropDownOptions,
      )}
      isDisabled={
        disableOptions || selectedGrossUpOptions !== GROSS_UP_OPTIONS.YES
      }
      onChangeCallback={(val) =>
        changeMultiSelectFieldValue('grossUpExcludedGLAccounts', val)
      }
      controlledValue={getSelectedOptionsFromIds(
        selectedGrossUpExcludedGLAccounts,
        glAccountsForExcludedDropdownOptions,
      )}
    />
  );
  const leaseAllowsMonthlyEstimatesOptionsField = (
    <Field
      name="leaseAllowsMonthlyEstimatesOptions"
      fieldName="leaseAllowsMonthlyEstimatesOptions"
      options={generateLeaseAllowsMonthlyEstimatesOptions()}
      component={renderRadioGroupField}
      disabled={disableOptions}
    />
  );

  return (
    <Modal bsSize="large" backdrop show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <i className="icon et-doc-add"></i>
        <Modal.Title componentClass="h1">
          {isAddCAMException ? (
            <FormattedMessage {...messages.allocationSetupException} />
          ) : (
            <FormattedMessage {...messages.allocationSetup} />
          )}
        </Modal.Title>
      </Modal.Header>

      {/* Loading data... */}
      {showIsLoading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {showDeleteConfirmation && renderModalDeleteConfirmationBody}
            {!showDeleteConfirmation && (
              <Row className="scrollable-modal__content padleft5 padright5">
                <Row className="padbottom20">
                  <Col xs={12} className="text-center">
                    <Col xs={11}>
                      <h2>{!isAddCAMException && allocationSetupModalTitle}</h2>
                    </Col>
                    <Col xs={1}>
                      {showDeleteIcon && (
                        <a
                          onClick={() => {
                            setShowDeleteConfirmation(true);
                          }}
                          disabled={moment().isAfter(camStartDate)}
                        >
                          <i className="et-trash" />
                        </a>
                      )}
                    </Col>
                  </Col>
                </Row>
                <Row className="padtop10">
                  <Col xs={12} md={5}>
                    {isAddCAMException ? (
                      <Row>
                        <Col xs={6} md={5}>
                          <div className="padtop5">
                            <strong>
                              <FormattedMessage {...messages.exceptionName} />
                            </strong>
                          </div>
                        </Col>
                        <Col xs={6} md={7}>
                          <Field
                            name="exceptionName"
                            component={renderTextField}
                            className="input-lg"
                            placeholder="Custom Pool Name"
                            maxLength="50"
                          />
                        </Col>
                      </Row>
                    ) : (
                      <Row style={{ 'padding-left': '5px' }}>
                        <Col xs={6} md={5}>
                          <strong>
                            <FormattedMessage
                              {...messages.glAccountsIncluded}
                            />
                          </strong>
                        </Col>
                        <Col xs={6} md={6}>
                          <Row>
                            {scope ? (
                              <ElementWithPermissions scope={scope}>
                                {glAccountsField}
                              </ElementWithPermissions>
                            ) : (
                              glAccountsField
                            )}
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  {isAddCAMException ? (
                    <Col xs={12} md={7}>
                      <Row className="padright20 padleft10">
                        <Col xs={12} md={3}>
                          <strong>
                            <FormattedMessage
                              {...messages.selectedGLAccounts}
                            />
                            :*
                          </strong>
                        </Col>
                        <Col xs={12} md={9}>
                          <ZindexCorrection zIndex={10}>
                            {scope ? (
                              <ElementWithPermissions scope={scope}>
                                {selectedGLAccountsField}
                              </ElementWithPermissions>
                            ) : (
                              selectedGLAccountsField
                            )}
                          </ZindexCorrection>
                        </Col>
                      </Row>
                    </Col>
                  ) : (
                    <Col xs={12} md={6}>
                      <Row style={{ 'margin-top': '-17px' }}>
                        <FormattedMessage {...messages.selectedGLAccounts} />
                        <ZindexCorrection zIndex={10}>
                          {scope ? (
                            <ElementWithPermissions scope={scope}>
                              {selectedGLAccountsField}
                            </ElementWithPermissions>
                          ) : (
                            selectedGLAccountsField
                          )}
                        </ZindexCorrection>
                      </Row>
                    </Col>
                  )}
                </Row>

                <Row className="padtop30">
                  <Col xs={12} md={2}>
                    <strong>
                      <FormattedMessage {...messages.proRataShare} />
                      :*
                    </strong>
                  </Col>
                  <Col xs={1}>
                    {scope ? (
                      <ElementWithPermissions scope={scope}>
                        {proRataShareOptionsField}
                      </ElementWithPermissions>
                    ) : (
                      proRataShareOptionsField
                    )}
                  </Col>
                  <Col xs={9}>
                    <Row className="padbottom10">
                      <Col xs={1}></Col>
                      <Col xs={11}>
                        <span>
                          <DashesIfNullOrUndefined data={proRataShare} />% (
                          <FormattedMessage {...messages.premisesSqFt} />{' '}
                          {premiseSqFt ? (
                            <u>
                              <FormattedNumber value={premiseSqFt} />
                            </u>
                          ) : (
                            '---'
                          )}{' '}
                          /
                          <FormattedMessage
                            {...messages.totalPropertySqFt}
                          />{' '}
                          {propertySqFt ? (
                            <u>
                              <FormattedNumber value={propertySqFt} />
                            </u>
                          ) : (
                            '---'
                          )}
                          )
                        </span>
                      </Col>
                    </Row>
                    <Col xs={12} md={9}>
                      <Row>
                        <Col xs={1}></Col>
                        <Col xs={4}>
                          {scope ? (
                            <ElementWithPermissions scope={scope}>
                              {proRataCustomPercentageField}
                            </ElementWithPermissions>
                          ) : (
                            proRataCustomPercentageField
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                </Row>

                <Row className="padtop30">
                  <Col xs={12} md={2}>
                    <strong>
                      <FormattedMessage {...messages.baseAmount} />
                      :*
                    </strong>
                  </Col>
                  <Col xs={3}>
                    {scope ? (
                      <ElementWithPermissions scope={scope}>
                        {baseAmountOptionsField}
                      </ElementWithPermissions>
                    ) : (
                      baseAmountOptionsField
                    )}
                  </Col>
                  <Col xs={7}>
                    <Row>
                      <Col xs={4} className="padbottom5">
                        {scope ? (
                          <ElementWithPermissions scope={scope}>
                            {baseYearAmountField}
                          </ElementWithPermissions>
                        ) : (
                          baseYearAmountField
                        )}
                      </Col>
                      <Col xs={5}>
                        <Row>
                          <FormattedMessage {...messages.totalAnnualExpense} />
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={4}>
                        {scope ? (
                          <ElementWithPermissions scope={scope}>
                            {ownerStopExpenseAmountField}
                          </ElementWithPermissions>
                        ) : (
                          ownerStopExpenseAmountField
                        )}
                      </Col>
                      <Col xs={8}>
                        <Row>
                          <FormattedMessage {...messages.squareFoot} />
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="padtop30">
                  <Col xs={12} md={2}>
                    <strong>
                      <FormattedMessage {...messages.expenseCaps} />
                    </strong>
                  </Col>
                  <Col xs={1}>
                    {scope ? (
                      <ElementWithPermissions scope={scope}>
                        {expenseCapsOptionsField}
                      </ElementWithPermissions>
                    ) : (
                      expenseCapsOptionsField
                    )}
                  </Col>
                  <Col xs={9}>
                    <Row>
                      <Col xs={1}></Col>
                      <Col xs={3}>
                        {scope ? (
                          <ElementWithPermissions scope={scope}>
                            {expenseCapAmountField}
                          </ElementWithPermissions>
                        ) : (
                          expenseCapAmountField
                        )}
                        {showYoyStartYearExpense && (
                          <div className="padtop5">
                            {scope ? (
                              <ElementWithPermissions scope={scope}>
                                {expenseCapStartYearExpensesField}
                              </ElementWithPermissions>
                            ) : (
                              expenseCapStartYearExpensesField
                            )}
                          </div>
                        )}
                      </Col>
                      <Col xs={5}>
                        <StyledDiv>
                          <ZindexCorrection zIndex={9}>
                            <FormattedMessage
                              {...messages.accountsExcludedCap}
                            />
                            {scope ? (
                              <ElementWithPermissions scope={scope}>
                                {expenseCapYoyExcludedGLAccountsField}
                              </ElementWithPermissions>
                            ) : (
                              expenseCapYoyExcludedGLAccountsField
                            )}
                          </ZindexCorrection>
                        </StyledDiv>
                      </Col>
                      <Col xs={3}>
                        <StyledDiv>
                          <FormattedMessage {...messages.leaseStartYear} />
                        </StyledDiv>
                      </Col>
                    </Row>
                    {/* aggregate */}
                    <Row className="padtop5">
                      <Col xs={1}></Col>
                      <Col xs={3}>
                        {scope ? (
                          <ElementWithPermissions scope={scope}>
                            {expenseCapPercentageAggregateField}
                          </ElementWithPermissions>
                        ) : (
                          expenseCapPercentageAggregateField
                        )}
                        {showAggregateStartYearExpense && (
                          <div className="padtop5">
                            {scope ? (
                              <ElementWithPermissions scope={scope}>
                                {expenseCapStartYearExpensesField}
                              </ElementWithPermissions>
                            ) : (
                              expenseCapStartYearExpensesField
                            )}
                          </div>
                        )}
                      </Col>
                      <Col xs={5}>
                        <ZindexCorrection zIndex={8}>
                          {scope ? (
                            <ElementWithPermissions scope={scope}>
                              {expenseCapAggregateExcludedGLAccountsField}
                            </ElementWithPermissions>
                          ) : (
                            expenseCapAggregateExcludedGLAccountsField
                          )}
                        </ZindexCorrection>
                      </Col>
                      <Col xs={3}>
                        {scope ? (
                          <ElementWithPermissions scope={scope}>
                            {expenseCapLeaseStartYearAggregateField}
                          </ElementWithPermissions>
                        ) : (
                          expenseCapLeaseStartYearAggregateField
                        )}
                      </Col>
                    </Row>

                    {/* cumulative */}
                    <Row className="padtop5">
                      <Col xs={1}></Col>
                      <Col xs={3}>
                        {scope ? (
                          <ElementWithPermissions scope={scope}>
                            {expenseCapPercentageCumulativeField}
                          </ElementWithPermissions>
                        ) : (
                          expenseCapPercentageCumulativeField
                        )}
                        {showCumulativeStartYearExpense && (
                          <div className="padtop5">
                            {scope ? (
                              <ElementWithPermissions scope={scope}>
                                {expenseCapStartYearExpensesField}
                              </ElementWithPermissions>
                            ) : (
                              expenseCapStartYearExpensesField
                            )}
                          </div>
                        )}
                      </Col>
                      <Col xs={5}>
                        <ZindexCorrection zIndex={7}>
                          {scope ? (
                            <ElementWithPermissions scope={scope}>
                              {expenseCapCumulativeExcludedGLAccountsField}
                            </ElementWithPermissions>
                          ) : (
                            expenseCapCumulativeExcludedGLAccountsField
                          )}
                        </ZindexCorrection>
                      </Col>
                      <Col xs={3}>
                        {scope ? (
                          <ElementWithPermissions scope={scope}>
                            {expenseCapLeaseStartYearCumulativeField}
                          </ElementWithPermissions>
                        ) : (
                          expenseCapLeaseStartYearCumulativeField
                        )}
                      </Col>
                    </Row>

                    {/* Compounded */}
                    <Row className="padtop5">
                      <Col xs={1}></Col>
                      <Col xs={3}>
                        {scope ? (
                          <ElementWithPermissions scope={scope}>
                            {expenseCapPercentageCompoundedField}
                          </ElementWithPermissions>
                        ) : (
                          expenseCapPercentageCompoundedField
                        )}
                        {showCompoundedStartYearExpense && (
                          <div className="padtop5">
                            {scope ? (
                              <ElementWithPermissions scope={scope}>
                                {expenseCapStartYearExpensesField}
                              </ElementWithPermissions>
                            ) : (
                              expenseCapStartYearExpensesField
                            )}
                          </div>
                        )}
                      </Col>
                      <Col xs={5}>
                        <ZindexCorrection zIndex={6}>
                          {scope ? (
                            <ElementWithPermissions scope={scope}>
                              {expenseCapCompoundedExcludedGLAccountsField}
                            </ElementWithPermissions>
                          ) : (
                            expenseCapCompoundedExcludedGLAccountsField
                          )}
                        </ZindexCorrection>
                      </Col>
                      <Col xs={3}>
                        {scope ? (
                          <ElementWithPermissions scope={scope}>
                            {expenseCapLeaseStartYearCompoundedField}
                          </ElementWithPermissions>
                        ) : (
                          expenseCapLeaseStartYearCompoundedField
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="padtop30">
                  <Col xs={12} md={2}>
                    <strong>
                      <FormattedMessage {...messages.grossUp} />
                      :*
                    </strong>
                  </Col>
                  <Col xs={1}>
                    {scope ? (
                      <ElementWithPermissions scope={scope}>
                        {grossUpOptionsField}
                      </ElementWithPermissions>
                    ) : (
                      grossUpOptionsField
                    )}
                  </Col>
                  <Col xs={9}>
                    <Row>
                      <Col xs={1}></Col>
                      <Col xs={3}>
                        <GrossUpYesFields>
                          <FormattedMessage {...messages.grossUpPercentage} />
                          {scope ? (
                            <ElementWithPermissions scope={scope}>
                              {grossUpYesPercentageField}
                            </ElementWithPermissions>
                          ) : (
                            grossUpYesPercentageField
                          )}
                        </GrossUpYesFields>
                      </Col>
                      <Col xs={4}>
                        <GrossUpYesFields>
                          <FormattedMessage
                            {...messages.estimatedAvgOccupancy}
                          />
                          {scope ? (
                            <ElementWithPermissions scope={scope}>
                              {grossUpYesAvgOccupancField}
                            </ElementWithPermissions>
                          ) : (
                            grossUpYesAvgOccupancField
                          )}
                        </GrossUpYesFields>
                      </Col>
                      <Col xs={4}>
                        <GrossUpYesFields>
                          <FormattedMessage {...messages.accountsExcluded} />
                          <ZindexCorrection zIndex={5}>
                            {scope ? (
                              <ElementWithPermissions scope={scope}>
                                {grossUpExcludedGLAccountsField}
                              </ElementWithPermissions>
                            ) : (
                              grossUpExcludedGLAccountsField
                            )}
                          </ZindexCorrection>
                        </GrossUpYesFields>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="padtop30">
                  <Col xs={12} md={2}>
                    <strong>
                      <FormattedMessage {...messages.transactionCode} />
                    </strong>
                  </Col>
                  <Col xs={12} md={10}>
                    <Row>
                      <Col xs={4}>
                        <Field
                          name="subjournal"
                          component={renderSelectField}
                          bsSize="lg"
                          options={generateSubjournalDropdownOptions(
                            subjournals,
                          )}
                          onChange={() => change('chargeCode', null)}
                          disabled={disableOptions || isEstimatesConfirmed}
                        />
                      </Col>
                      <Col xs={4}>
                        <Field
                          name="chargeCode"
                          component={renderSelectField}
                          bsSize="lg"
                          options={generateChargeCodeDropdownOptions(
                            chargeCodes,
                          )}
                          disabled={
                            disableOptions ||
                            !selectedSubjournalId ||
                            isEstimatesConfirmed
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="padtop30">
                  <Col xs={12} md={2}>
                    <strong>
                      <FormattedMessage
                        {...messages.reconciliationTransactionCode}
                      />
                    </strong>
                  </Col>
                  <Col xs={12} md={10}>
                    <Row>
                      <Col xs={4}>
                        <Field
                          name="reconciliationSubjournal"
                          component={renderSelectField}
                          bsSize="lg"
                          options={generateSubjournalDropdownOptions(
                            subjournals,
                          )}
                          onChange={() =>
                            change('reconciliationChargeCode', null)
                          }
                          disabled={disableOptions || isEstimatesConfirmed}
                        />
                      </Col>
                      <Col xs={4}>
                        <Field
                          name="reconciliationChargeCode"
                          component={renderSelectField}
                          bsSize="lg"
                          options={generateChargeCodeDropdownOptions(
                            reconciliationChargeCodes,
                          )}
                          disabled={
                            disableOptions ||
                            !selectedReconciliationSubjournalId ||
                            isEstimatesConfirmed
                          }
                        />
                      </Col>
                    </Row>
                    <Row className="padtop30">
                      <Col xs={4}>
                        <FormattedMessage {...messages.leaseAllows} />
                      </Col>
                      <Col xs={4}>
                        {scope ? (
                          <ElementWithPermissions scope={scope}>
                            {leaseAllowsMonthlyEstimatesOptionsField}
                          </ElementWithPermissions>
                        ) : (
                          leaseAllowsMonthlyEstimatesOptionsField
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col xs={6}>
                <Button
                  bsStyle="default"
                  className="pull-right"
                  onClick={onClose}
                  disabled={showDeleteConfirmation}
                >
                  <FormattedMessage {...messages.cancel} />
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  bsStyle="primary"
                  className="pull-left"
                  type="submit"
                  disabled={pristine || !valid || showDeleteConfirmation}
                >
                  <FormattedMessage {...messages.save} />
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </StyledForm>
      )}
    </Modal>
  );
};

export const mapStateToProps = ({ form }: Object): Object => {
  const selector = formValueSelector('allocationSetupModal');
  return {
    selectedGLAccountsOptions: selector({ form }, 'glAccountsOptions'),
    selectedBaseAmountOptions: selector({ form }, 'baseAmountOptions'),
    selectedExpenseCapsOptions: selector({ form }, 'expenseCapsOptions'),
    selectedProRataShareOptions: selector({ form }, 'proRataShareOptions'),
    selectedGrossUpOptions: selector({ form }, 'grossUpOptions'),
    selectedSubjournalId: selector({ form }, 'subjournal'),
    selectedReconciliationSubjournalId: selector(
      { form },
      'reconciliationSubjournal',
    ),
    selectedGLAccounts: selector({ form }, 'selectedGLAccounts'),
    selectedExceptionName: selector({ form }, 'exceptionName'),
    selectedExpenseCapYoyExcludedGLAccounts: selector(
      { form },
      'expenseCapYoyExcludedGLAccounts',
    ),
    selectedExpenseCapAggregateExcludedGLAccounts: selector(
      { form },
      'expenseCapAggregateExcludedGLAccounts',
    ),
    selectedExpenseCapCumulativeExcludedGLAccounts: selector(
      { form },
      'expenseCapCumulativeExcludedGLAccounts',
    ),
    selectedExpenseCapCompoundedExcludedGLAccounts: selector(
      { form },
      'expenseCapCompoundedExcludedGLAccounts',
    ),
    selectedGrossUpExcludedGLAccounts: selector(
      { form },
      'grossUpExcludedGLAccounts',
    ),
    selectedExpenseCapLeaseStartYearAggregate: selector(
      { form },
      'expenseCapLeaseStartYearAggregate',
    ),
    selectedExpenseCapLeaseStartYearCumulative: selector(
      { form },
      'expenseCapLeaseStartYearCumulative',
    ),
    selectedExpenseCapLeaseStartYearCompounded: selector(
      { form },
      'expenseCapLeaseStartYearCompounded',
    ),
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  resetFields: (fieldsObj) => {
    Object.keys(fieldsObj).forEach((fieldKey) => {
      //reset the field's value
      dispatch(change('allocationSetupModal', fieldKey, fieldsObj[fieldKey]));
      dispatch(untouch('allocationSetupModal', fieldKey));
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'allocationSetupModal',
    enableReinitialize: true,
    validate,
    touchOnChange: true,
  })(AllocationSetupModal),
);
