import React from 'react';
import { Field } from 'redux-form';
import { Row, Col, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import PhoneNumber from '../PhoneNumber';
import {
  renderTextField,
  renderEmailField,
  renderSelectField,
} from '../../../../utils/redux-form-helper';

type Props = {
  intl: any,
  suffixes?: Array<Object>,
  firstName?: string,
};

export const FirstName = ({ intl }: Props) => {
  return (
    <Col xs={6} sm={3}>
      <Field
        className="input-lg"
        name="firstName"
        component={renderTextField}
        placeholder={intl.formatMessage(messages.firstNamePlaceholder)}
        maxLength="35"
      />
    </Col>
  );
};

export const MiddleName = ({ intl }: Props) => {
  return (
    <Col xs={6} sm={3}>
      <Field
        className="input-lg"
        name="middleName"
        component={renderTextField}
        placeholder={intl.formatMessage(messages.middleNamePlaceholder)}
        maxLength="35"
      />
    </Col>
  );
};

export const LastName = ({ intl }: Props) => {
  return (
    <Col xs={6} sm={3}>
      <Field
        className="input-lg"
        name="lastName"
        component={renderTextField}
        placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
        maxLength="35"
      />
    </Col>
  );
};

export const Suffix = ({ intl, suffixes }: Props) => {
  return (
    <Col xs={6} sm={3}>
      <Field
        selectClassNames="input-lg"
        name="suffixId"
        component={renderSelectField}
        placeholder={intl.formatMessage(messages.suffixPlaceholder)}
        options={suffixes}
        maxLength="35"
      />
    </Col>
  );
};

export const PreferredName = ({ intl }: Props) => {
  return (
    <Col xs={6} sm={3}>
      <Field
        className="input-lg"
        name="preferredName"
        component={renderTextField}
        placeholder={intl.formatMessage(messages.preferredNamePlaceholder)}
        maxLength="35"
      />
    </Col>
  );
};

export const Phone = ({ intl }: Props) => {
  return (
    <Col xs={6} sm={3}>
      <PhoneNumber intl={intl} />
    </Col>
  );
};

export const EmailAddress = ({ intl }: Props) => {
  return (
    <Col xs={6} sm={6}>
      <Field
        className="input-lg"
        name="emailAddress"
        component={renderEmailField}
        placeholder={intl.formatMessage(messages.emailPlaceholder)}
        label={intl.formatMessage(messages.emailLabel)}
        maxLength="255"
      />
    </Col>
  );
};

const PrimaryContactInformation = ({ intl, suffixes, firstName }: Props) => (
  <FormGroup className="applicant-grp">
    <Row className="block-heading">
      <h2>
        <FormattedMessage {...messages.myNameIs} /> {firstName}
        <FormattedMessage {...messages.mayIAsk} />
      </h2>
    </Row>
    <Row>
      <div className="container-of-inputs">
        <Col xs={6} sm={3}>
          <label>
            <FormattedMessage {...messages.firstNameLabel} />
          </label>
        </Col>
        <Col xs={6} sm={3}>
          <label>
            <FormattedMessage {...messages.middleNameLabel} />
          </label>
        </Col>
        <Col xs={6} sm={3}>
          <label>
            <FormattedMessage {...messages.lastNameLabel} />
          </label>
        </Col>
        <Col xs={6} sm={3}>
          <label>
            <FormattedMessage {...messages.suffixLabel} />
          </label>
        </Col>
      </div>
    </Row>
    <Row>
      <div className="container-of-inputs">
        <FirstName intl={intl} />
        <MiddleName intl={intl} />
        <LastName intl={intl} />
        <Suffix intl={intl} suffixes={suffixes} />
      </div>
    </Row>
    <Row>
      <div className="container-of-inputs">
        <Col xs={6} sm={3}>
          <label>
            <FormattedMessage {...messages.preferredNameLabel} />
          </label>
        </Col>
        <Col xs={6} sm={3}>
          <label>
            <FormattedMessage {...messages.phoneLabel} />
          </label>
        </Col>
        <Col xs={6} sm={5}>
          <label>
            <FormattedMessage {...messages.emailLabel} />
          </label>
        </Col>
        <Col xs={6} sm={1} />
      </div>
    </Row>
    <Row>
      <div className="container-of-inputs">
        <PreferredName intl={intl} />
        <Phone intl={intl} />
        <EmailAddress intl={intl} />
      </div>
    </Row>
  </FormGroup>
);

export default PrimaryContactInformation;
