import moment from 'moment';
import { intersection, pathOr, propOr } from 'ramda';
import { appendFilterTextToCSV } from '../../utils/csv-helpers';

import { HEADERS } from './constants';
import {
  getDesiredMoveInDate,
  getScheduledMoveInDate,
} from '../ManageProspects/utils';

const parseAssigneeName = (assignedTo: Object) => {
  const assigneeFirstName = pathOr('---', ['firstName'], assignedTo);
  const assigneeLastName = pathOr('---', ['lastName'], assignedTo);

  return `${assigneeFirstName} ${assigneeLastName}`;
};

const filterFinanciallyResponsibleApplicants = ({ applicantType }) =>
  pathOr(false, ['financiallyResponsible'], applicantType);

const findMainApplicant = (applicants: Array<Object>) =>
  applicants.find((applicant) =>
    pathOr(false, ['applicantCustomer', 'isProspect'], applicant),
  ) || {};

const parseContactInfo = (applicant: Object) => {
  const mainCustomer = pathOr({}, ['applicantCustomer', 'customer'], applicant);
  const emailAddress = propOr('', 'emailAddress', mainCustomer);
  const phoneNumber = propOr('', 'phoneNumber', mainCustomer);
  return { emailAddress, phoneNumber };
};

const parsePreferences = (prospect: Object) => {
  const desiredMoveInStartDate = pathOr(
    null,
    ['preferences', 'moveInDateFrom'],
    prospect,
  );
  const desiredMoveInEndDate = pathOr(
    null,
    ['preferences', 'moveInDateTo'],
    prospect,
  );
  return { desiredMoveInStartDate, desiredMoveInEndDate };
};

export const mapApplications = (
  applications: Array<any>,
  assigningUnitsMoveInDatesFlag?: boolean,
): Array<any> => {
  return applications.map((application) => {
    const id = pathOr('', ['id'], application);
    const assignedTo = pathOr({}, ['assignedTo'], application);
    const applicants = pathOr([], ['applicants'], application);
    const prospect = pathOr({}, ['prospect'], application);
    const unitApp = pathOr('---', ['au', 'unit', 'number'], application);
    const leaseMoveInDate = assigningUnitsMoveInDatesFlag
      ? getScheduledMoveInDate({
          lease: application?.au?.lease,
          isUnitAssigned: application?.au?.unitId,
          prospectPreferences: prospect?.preferences,
        })
      : application?.au?.lease?.moveInDate ?? null;
    const financiallyResponsibleApplicants = applicants.filter(
      filterFinanciallyResponsibleApplicants,
    );
    const mainApplicant = findMainApplicant(financiallyResponsibleApplicants);
    const { emailAddress, phoneNumber } = parseContactInfo(mainApplicant);
    const { desiredMoveInStartDate, desiredMoveInEndDate } =
      parsePreferences(prospect);
    const { moveInDateFrom, moveInDateTo } = getDesiredMoveInDate({
      prospectPreferences: prospect?.preferences,
      isUnitAssigned: Boolean(application?.au),
    });

    return {
      ...application,
      id,
      assigneeName: parseAssigneeName(assignedTo),
      filteredApplicants: financiallyResponsibleApplicants,
      filteredApplicant: mainApplicant,
      moveInDate: leaseMoveInDate,
      emailAddress,
      phoneNumber,
      unitApp,
      prospect,
      desiredMoveInStartDate: assigningUnitsMoveInDatesFlag
        ? moveInDateFrom
        : desiredMoveInStartDate,
      desiredMoveInEndDate: assigningUnitsMoveInDatesFlag
        ? moveInDateTo
        : desiredMoveInEndDate,
    };
  });
};

export const filterApplications = (
  applications: Array<Object>,
  statuses: Object,
  hasStatusFilters: boolean,
) => {
  let filteredApplications = null;

  // if status filters exist, filter applications
  if (hasStatusFilters) {
    filteredApplications = applications.filter(
      (application) => statuses[application.applicationStatus.id],
    );
  }

  return filteredApplications || applications;
};

const AFFORDABLE_PROGRAM_NAME_PATH = ['masterAffordableProgram', 'name'];

const findAffordableProgramByName = (
  propertyAffordablePrograms: Array<Object>,
  name: string,
) => {
  return propertyAffordablePrograms.find((program) =>
    (program?.masterAffordableProgram?.name ?? '').includes(name),
  );
};

const getGeneralAffordableProgram = (
  propertyAffordablePrograms: Array<Object>,
) => {
  const lihtcProgram = findAffordableProgramByName(
    propertyAffordablePrograms,
    'LIHTC',
  );
  if (lihtcProgram) {
    return lihtcProgram;
  }

  const nonHUDPrograms = propertyAffordablePrograms.filter((program) => {
    const programName = pathOr(null, AFFORDABLE_PROGRAM_NAME_PATH, program);
    return programName && programName !== 'HUD';
  });
  if (nonHUDPrograms.length > 0) {
    return nonHUDPrograms[0];
  }

  return null;
};

export const determineAffordablePrograms = (
  propertyAffordablePrograms: Array<Object>,
) => {
  const hudProgram = findAffordableProgramByName(
    propertyAffordablePrograms,
    'HUD',
  );
  const generalAffordableProgram = getGeneralAffordableProgram(
    propertyAffordablePrograms,
  );

  return { hudProgram, generalAffordableProgram };
};

const determineCSVHeaders = (affordablePrograms: Object) => {
  const { hudProgram, generalAffordableProgram } = affordablePrograms;

  const headers = HEADERS.filter((header) =>
    generalAffordableProgram
      ? true
      : header.id !== 'complianceApproval' &&
        header.id !== 'complianceDocument',
  )
    .filter((header) =>
      hudProgram
        ? true
        : header.id !== 'complianceApprovalHUD' &&
          header.id !== 'complianceDocumentHUD',
    )
    .map((header) => header.title.props.defaultMessage)
    .join(',');
  return headers;
};

export const parseCSV = (
  applications: Array<Object>,
  userPermissions: Array<Object>,
  locale: string,
  affordablePrograms: Object,
  hasAnyFilters: boolean,
) => {
  const mappedApplications = mapApplications(applications);
  const headers = determineCSVHeaders(affordablePrograms);
  const rows = mappedApplications.reduce((prev, application) => {
    const {
      applicationCode,
      assigneeName,
      filteredApplicants,
      complianceApproval = '---',
      complianceDocument = '---',
      complianceApprovalHUD = '---',
      complianceDocumentHUD = '---',
      unitApp,
      prospect,
      desiredMoveInStartDate,
      desiredMoveInEndDate,
      moveInDate,
      emailAddress,
      phoneNumber,
      createdAt,
      filteredApplicant,
      notes = '---',
    } = application;
    const applicants = filteredApplicants
      ? filteredApplicants
          .filter((applicant) => applicant.applicantCustomer)
          .map(
            (applicant) =>
              `${applicant.applicantCustomer.customer.firstName} ${applicant.applicantCustomer.customer.lastName}`,
          )
          .join(', ')
      : '';
    const status = application.applicationStatus.translations[locale];
    const mappedPhoneNumber =
      phoneNumber && filteredApplicant
        ? `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(
            3,
            3,
          )}-${phoneNumber.substr(6, 4)}`
        : '---';
    const mappedEmailAddress =
      emailAddress && filteredApplicant ? emailAddress : '---';
    const userScopes = userPermissions.map((permission) => permission.scope);
    const permissions = ['activity-read', 'activity-update', 'activity-delete'];
    const hasPermissions = intersection(permissions, userScopes).length > 0;
    const lastActivity =
      prospect?.lastAct?.startTime && hasPermissions
        ? moment(prospect.lastAct.startTime).format('MM/DD/YYYY')
        : '---';
    const nextActivity =
      prospect?.nextAct?.startTime && hasPermissions
        ? moment(prospect.nextAct.startTime).format('MM/DD/YYYY')
        : '---';
    let desiredMoveInDate =
      moment(desiredMoveInStartDate).format('MM/DD/YYYY') || '---';
    desiredMoveInDate !== 'Invalid date'
      ? (desiredMoveInDate +=
          desiredMoveInEndDate && desiredMoveInStartDate
            ? ` - ${moment(desiredMoveInEndDate).format('MM/DD/YYYY')}`
            : '')
      : (desiredMoveInDate = '---');

    const scheduledMoveInDate =
      moveInDate !== null ? moment(moveInDate).format('MM/DD/YYYY') : '---';

    const { hudProgram, generalAffordableProgram } = affordablePrograms;
    const generalAffordableData = generalAffordableProgram
      ? [complianceApproval, complianceDocument]
      : [];
    const hudProgramData = hudProgram
      ? [complianceApprovalHUD, complianceDocumentHUD]
      : [];

    const finalFields = [
      applicationCode,
      `"${applicants}"`,
      status,
      ...generalAffordableData,
      ...hudProgramData,
      unitApp,
      mappedPhoneNumber,
      mappedEmailAddress,
      lastActivity,
      nextActivity,
      desiredMoveInDate,
      scheduledMoveInDate,
      `"${notes}"`,
      moment(createdAt).format('MM/DD/YYYY'),
      assigneeName,
    ];
    prev.push(finalFields.join(','));
    return prev;
  }, []);

  return appendFilterTextToCSV({ headers, rows, hasAnyFilters });
};
