// $FlowFixMe
import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import PropertyService from '../../../services/propertyService';

export const useAsyncOnboardingReport = (property: Object) => {
  const [onboardingReport, setOnboardingReport] = useState(null);

  const resetOnboardingReport = () => setOnboardingReport(null);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchOnboardingReport = async () => {
      const { id, organizationId, portalLatestOnboardingGroupId } = property;
      if (!onboardingReport && portalLatestOnboardingGroupId) {
        const propertyService = new PropertyService();
        const options = { signal: abortController.signal };
        const onboardingReportRes = await propertyService.getOnboardingReport(
          organizationId,
          id,
          portalLatestOnboardingGroupId,
          options,
        );
        const reportTotals = pathOr(null, ['totals'], onboardingReportRes);
        setOnboardingReport(reportTotals);
      }
    };

    if (subscribed) {
      fetchOnboardingReport();
    }

    return () => {
      subscribed = false;
    };
  }, [property, onboardingReport]);

  return [onboardingReport, resetOnboardingReport];
};

export const useAsyncOnboardingReportDownload = (
  property: Object,
  downloadReport: boolean,
  setDownloadReport: Function,
) => {
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const downloadOnboardingReport = async () => {
      const {
        id,
        name,
        organizationId,
        portalLatestOnboardingGroupId,
      } = property;
      if (downloadReport && portalLatestOnboardingGroupId) {
        const propertyService = new PropertyService();
        const options = { signal: abortController.signal };
        await propertyService.getCSVOnboardingReport(
          organizationId,
          id,
          portalLatestOnboardingGroupId,
          name,
          options,
        );
        setDownloadReport(false);
      }
    };

    if (subscribed) {
      downloadOnboardingReport();
    }

    return () => {
      subscribed = false;
    };
  }, [property, downloadReport, setDownloadReport]);
};
