const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class AssignedUnitService {
  getAll(organizationId: string, propertyId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/assignedUnits`,
      options
    );
  }
  getById(organizationId: string, propertyId: string, id: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/assignedUnits/${id}`,
      options
    );
  }
  getByApplicationId(
    organizationId: string,
    propertyId: string,
    applicationId: string
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/assignedUnits?applicationId=${applicationId}`,
      options
    );
  }
  getByleaseId(organizationId: string, propertyId: string, leaseId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/assignedUnits?leaseId=${leaseId}`,
      options
    );
  }
  getByunitId(organizationId: string, propertyId: string, unitId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/assignedUnits?unitId=${unitId}`,
      options
    );
  }
  save(organizationId: string, propertyId: string, body: Object) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/assignedUnits`,
      options
    );
  }
  update(organizationId: string, propertyId: string, id: string, body: Object) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/assignedUnits/${id}`,
      options
    );
  }
  delete(organizationId: string, propertyId: string, id: string) {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/assignedUnits/${id}`,
      options
    );
  }
}

export default AssignedUnitService;
