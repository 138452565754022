import { useState, useEffect, useRef } from 'react';
import ApplicationService from '../../services/applicationService';
import UserService from '../../services/userService';
import messages from './messages';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

export const useProspectAssignees = (selectedProperty: Object) => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { id: propertyId, organizationId } = selectedProperty;
  const [prospectAssignees, setProspectAssignees] = useState([]);

  useEffect(() => {
    (async () => {
      const userService = new UserService();

      const response = await userService.getProspectAssignees(
        organizationId,
        propertyId,
      );

      if (isMounted.current && response) {
        setProspectAssignees(response);
      }
    })();
  }, [organizationId, propertyId]);

  return { prospectAssignees };
};

export const useCreateCommercialApplication = (
  selectedProperty: Object,
  promptToaster: Function,
  intl: Object,
  history: Object,
) => {
  const { id: propertyId, organizationId } = selectedProperty;

  const createCommercialApplication = async (data: Object) => {
    const applicationService = new ApplicationService();
    try {
      const payload = Object.entries(data).reduce((acc, [key, value]) => {
        if (value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      const response = await applicationService.createCommercialApplication(
        organizationId,
        propertyId,
        payload,
      );

      if (response) {
        promptToaster({
          title: intl.formatMessage(messages.success),
          message: intl.formatMessage(messages.successBody),
        });
        navigateToUrlWithSelectedPropertyId(`/application/${response.id}`);
      }
    } catch (_) {
      promptToaster({
        type: 'error',
        title: intl.formatMessage(messages.error),
        message: intl.formatMessage(messages.errorBody),
      });
    }
  };

  return [createCommercialApplication];
};
