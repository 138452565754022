import { FormattedMessage } from 'react-intl';
import messages from './messages';
import React from 'react';

export const GET_EXPIRING_LEASES = 'app/GET_EXPIRING_LEASES';
export const GET_EXPIRING_LEASES_SUCCESS = 'app/GET_EXPIRING_LEASES_SUCCESS';
export const GET_EXPIRING_LEASES_FAILURE = 'app/GET_EXPIRING_LEASES_FAILURE';
export const CLEAN_LOADED_EXPIRING_LEASES = 'app/CLEAN_LOADED_EXPIRING_LEASES';

export const SELECT_LEASE_FOR_RENEWAL =
  'app/ManageLeaseExpirations/SELECT_LEASE_FOR_RENEWAL';

export const FORM_NAME = 'ManageLeaseExpirations';

export const headers = [
  {
    id: 'leaseRenewalStatus',
    title: <FormattedMessage {...messages.leaseRenewalStatus} />,
  },
  { id: 'unit', title: <FormattedMessage {...messages.unit} /> },
  {
    id: 'floorPlan',
    title: <FormattedMessage {...messages.floorplan} />,
  },
  {
    id: 'resident',
    title: <FormattedMessage {...messages.resident} />,
  },
  {
    id: 'tenant',
    title: <FormattedMessage {...messages.tenant} />,
  },
  {
    id: 'moveInDate',
    title: <FormattedMessage {...messages.moveIn} />,
  },
  {
    id: 'leaseStartDate',
    title: <FormattedMessage {...messages.leaseStartDate} />,
  },
  {
    id: 'leaseEndDate',
    title: <FormattedMessage {...messages.leaseEndDate} />,
  },
  {
    id: 'daysUntilLeaseEnd',
    title: <FormattedMessage {...messages.daysUntilLeaseEnd} />,
  },
  {
    id: 'noticeToVacate',
    title: <FormattedMessage {...messages.noticeToVacate} />,
    order: null,
  },
  {
    id: 'doNotRenew',
    title: <FormattedMessage {...messages.doNotRenew} />,
  },
  {
    id: 'underEviction',
    title: <FormattedMessage {...messages.underEviction} />,
  },
  {
    id: 'marketRent',
    title: <FormattedMessage {...messages.gprMarketRent} />,
  },
  {
    id: 'priorLeasedRent',
    title: <FormattedMessage {...messages.priorLeasedRent} />,
  },
  {
    id: 'currentLeasedRent',
    title: <FormattedMessage {...messages.currentLeasedRent} />,
  },
  {
    id: 'otherUnitFees',
    title: <FormattedMessage {...messages.otherUnitFees} />,
    order: null,
  },
  {
    id: 'balance',
    title: <FormattedMessage {...messages.balance} />,
    order: null,
  },
  {
    id: 'quotingRent',
    title: <FormattedMessage {...messages.quotingRent} />,
  },
  {
    id: 'actions',
    title: <FormattedMessage {...messages.note} />,
    order: null,
  },
  {
    id: 'actions',
    title: <FormattedMessage {...messages.actions} />,
    order: null,
  },
];

export const currencyStyle = { value: 'currency' };
