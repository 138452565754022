import { pathOr } from 'ramda';
import React from 'react';
import MiniProfileFormPanel from '../../MiniProfileFormPanel';
import moment from 'moment';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

const PANEL_TITLE = 'Portal';

type Props = {
  isCommercial: boolean,
  isGuarantor: boolean,
  onClose: Function,
  openPanel: ?string,
  personalInfo: Object,
  residentPortalInfo: { sendPortalInviteEmail: Function },
  specialNeedsAffordableChange: boolean,
  togglePanel: Function,
};

const ResidentPortalDisabledErrorMessage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{
          marginTop: 'calc(25% - 15px) !important',
          maxWidth: '300px',
          fontSize: '17px',
          lineHeight: 'inherit',
        }}
      >
        There is no information currently available for this household member.
      </Typography>
    </Box>
  );
};
export const ResidentPortalEmail = ({
  isCommercial,
  isGuarantor,
  onClose,
  openPanel,
  personalInfo,
  residentPortalInfo,
  specialNeedsAffordableChange,
  togglePanel,
}: Props) => {
  if (
    !residentPortalInfo ||
    !residentPortalInfo.display ||
    personalInfo?.type === 'minor'
  ) {
    if (isCommercial || (specialNeedsAffordableChange && !isGuarantor)) {
      return null;
    }

    return <ResidentPortalDisabledErrorMessage />;
  }

  const { customerStatusTitle } = personalInfo;

  const sendPortalInviteEmail = (id) => {
    residentPortalInfo.sendPortalInviteEmail(id);
    onClose();
  };
  const portalUser = pathOr({}, ['portalUser'], personalInfo);
  const idPath =
    customerStatusTitle === 'Prospect' ? 'prospectId' : 'customerId';
  const idForEmail = pathOr('', [idPath], personalInfo);
  const { claimRequestedAt, claimToken, isClaimed } = portalUser;
  const lastSent = claimRequestedAt ? moment(claimRequestedAt) : null;
  let statusMessage = '';
  if (isClaimed) {
    statusMessage = `${customerStatusTitle} is on ${PANEL_TITLE}`;
  } else if (!claimToken && !isClaimed) {
    statusMessage = `${customerStatusTitle} has not been invited to ${PANEL_TITLE}`;
  } else if (claimToken && !isClaimed) {
    statusMessage = `${customerStatusTitle} previously invited to ${PANEL_TITLE}`;
    if (lastSent) {
      statusMessage += ` on ${lastSent.format(
        'MMMM Do, YYYY',
      )} at ${lastSent.format('h:mm:ss A')}`;
    }
  }

  const emailAddress = pathOr(null, ['emailAddress'], personalInfo);
  const noEmailAddress =
    !emailAddress || emailAddress === 'batch_default@mail.com';

  return (
    <MiniProfileFormPanel
      open={openPanel === PANEL_TITLE}
      toggleOpen={togglePanel(PANEL_TITLE)}
      title={PANEL_TITLE}
    >
      <div className="container-fluid padleft5 padright5 padtop15 padbottom15 text-center">
        <button
          className="btn btn-primary"
          onClick={() => sendPortalInviteEmail(idForEmail)}
          disabled={noEmailAddress}
        >
          <i className="icon et-mail" />
          Send Portal Invite
        </button>
        <div className="row">
          {noEmailAddress && (
            <div className="col-xs-12 text-center">
              <small className="text-gray--darker">
                <span>
                  {`${customerStatusTitle} must have an email address to receive a ${PANEL_TITLE} invite`}
                </span>
              </small>
            </div>
          )}
          <div className="col-xs-12 text-center">
            <small className="text-gray--darker">
              <span>{statusMessage}</span>
            </small>
          </div>
        </div>
      </div>
    </MiniProfileFormPanel>
  );
};

export default ResidentPortalEmail;
