import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import { and, not } from 'ramda';

import {
  renderTextField,
  renderEmailField,
  renderSelectField,
  renderPhoneFieldWithPermanentWarn,
  phoneParser,
  phoneFormatter,
  renderRadioButton,
} from '../../utils/redux-form-helper';
import { useRef } from 'react';

type Props = {
  isProspect: boolean,
  isNew: boolean,
  field: string,
  type: string,
  index: string,
  id: string,
  primaryApplicantId: string,
  onDelete: Function,
  onPrimaryChange: Function,
  suffixList: Array<Object>,
  relationshipList: Array<Object>,
  affordableRelationship: Array<Object>,
  applicantTypeList: Array<Object>,
  showApplicantType: boolean,
  fieldsReadOnly: boolean,
  showEditButton: boolean,
  onEdit: Function,
  isAffordable: boolean,
  isCreateApplicationMode: boolean,
  isProspectMode: boolean,
  leaseLocked: boolean,
  prevPrimaryId: string,
  adultInitialValues: Object,
  affordableRelationshipBundle: Object,
  guarantorOptions: Object,
  liveInCaretakerOptions: Object,
  change: Function,
};

const Adult = ({
  isNew,
  field,
  type,
  suffixList,
  relationshipList,
  applicantTypeList,
  onDelete,
  showApplicantType,
  fieldsReadOnly,
  showEditButton,
  onEdit,
  isAffordable,
  onPrimaryChange,
  index,
  id,
  primaryApplicantId,
  isProspect,
  isCreateApplicationMode,
  isProspectMode,
  leaseLocked,
  prevPrimaryId,
  affordableRelationshipBundle,
  guarantorOptions,
  liveInCaretakerOptions,
  adultInitialValues,
  change,
}: Props) => {
  const disableFields = and(not(isNew), fieldsReadOnly);
  const prevTypeRef = useRef(adultInitialValues?.type);

  const isPrimaryApplicant = primaryApplicantId === id;
  const localIsProspect = isCreateApplicationMode
    ? isProspect
    : isPrimaryApplicant;
  const isPreviousPrimary = prevPrimaryId === id;
  const filteredApplicantTypeList = localIsProspect
    ? applicantTypeList.filter(
        (typeList) =>
          typeof typeList.financiallyResponsible === 'undefined' ||
          typeList.financiallyResponsible,
      )
    : applicantTypeList;

  const applicantType = filteredApplicantTypeList.find(
    (apt) => apt.value === type,
  );
  // $FlowFixMe
  const isFinancial = applicantType.financiallyResponsible;
  const isGuarantorOrLiveInCaretakerLeaseSigner = [
    guarantorOptions?.applicantType?.id,
    liveInCaretakerOptions?.applicantType?.id,
  ].includes(applicantType.value);

  const onTypeChange = (event) => {
    const value = event.target.value;
    const isGuarantorLeaseSignerType =
      value === guarantorOptions?.applicantType?.id;
    const isLiveInCaretakerType =
      value === liveInCaretakerOptions?.applicantType?.id;

    const options = isGuarantorLeaseSignerType
      ? guarantorOptions
      : isLiveInCaretakerType
      ? liveInCaretakerOptions
      : {};

    if (isGuarantorLeaseSignerType || isLiveInCaretakerType) {
      change(`${field}.relationship`, options?.relationship?.id);
      change(
        `${field}.affordableRelationship`,
        options?.affordableRelationship?.id,
      );
    }

    const isPrevTypeGuarantorOrLiveInCaretaker = [
      guarantorOptions?.applicantType?.id,
      liveInCaretakerOptions?.applicantType?.id,
    ].includes(prevTypeRef.current);
    if (
      isPrevTypeGuarantorOrLiveInCaretaker &&
      !(isGuarantorLeaseSignerType || isLiveInCaretakerType)
    ) {
      change(`${field}.relationship`, 'default');
      change(`${field}.affordableRelationship`, 'default');
    }
    prevTypeRef.current = value;
  };

  return (
    <Row>
      <div className="container-of-inputs">
        {showApplicantType && (
          <Col xs={6} sm={2}>
            <Field
              name={`${field}.type`}
              component={renderSelectField}
              options={filteredApplicantTypeList}
              disabled={leaseLocked}
              onChange={onTypeChange}
            />
          </Col>
        )}
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.firstName`}
            component={renderTextField}
            maxLength="35"
            disabled={disableFields || leaseLocked}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.middleName`}
            component={renderTextField}
            maxLength="35"
            disabled={disableFields || leaseLocked}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.lastName`}
            component={renderTextField}
            maxLength="35"
            disabled={disableFields || leaseLocked}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.suffixId`}
            component={renderSelectField}
            options={suffixList}
            disabled={disableFields || leaseLocked}
          />
        </Col>
        <Col xs={6} sm={isAffordable ? 1 : 2}>
          <Field
            name={`${field}.preferredName`}
            component={renderTextField}
            maxLength="35"
            disabled={disableFields || leaseLocked}
          />
        </Col>
        {!isCreateApplicationMode && (
          <Col xs={6} sm={1} className="text-center">
            <Field
              key={`is-primary-radio-${index}`}
              name={'primaryApplicantId'}
              option={{ value: id }}
              component={renderRadioButton}
              disabled={
                isNew ||
                (!isFinancial && showApplicantType) ||
                isCreateApplicationMode
              }
              props={{
                input: {
                  name: 'primaryApplicantId',
                  onChange: () => {
                    onPrimaryChange(id, field);
                  },
                  value: primaryApplicantId,
                },
              }}
            />
          </Col>
        )}
        {isAffordable && (
          <Col xs={6} sm={isAffordable ? 1 : 2}>
            <Field
              name={`${field}.affordableRelationship`}
              component={renderSelectField}
              options={
                affordableRelationshipBundle.processedAffordableRelationships
              }
              disabled={
                affordableRelationshipBundle.disabled ||
                isGuarantorOrLiveInCaretakerLeaseSigner
              }
            />
          </Col>
        )}
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.relationship`}
            component={renderSelectField}
            options={relationshipList}
            disabled={
              isPreviousPrimary
                ? false
                : localIsProspect ||
                  leaseLocked ||
                  isGuarantorOrLiveInCaretakerLeaseSigner
            }
          />
        </Col>
        <Col xs={6} sm={!isProspectMode ? 1 : 2}>
          <Field
            name={`${field}.phone`}
            component={renderPhoneFieldWithPermanentWarn}
            parse={phoneParser}
            format={phoneFormatter}
            disabled={disableFields || leaseLocked}
          />
        </Col>
        <Col xs={6} sm={!isProspectMode && !isCreateApplicationMode ? 1 : 2}>
          <Field
            name={`${field}.email`}
            component={renderEmailField}
            maxLength="70"
            disabled={disableFields || leaseLocked}
          />
        </Col>
      </div>
      <div className="container-of-trash">
        <div className="row-remove-btn">
          {showEditButton && (
            <a onClick={onEdit} disabled={isNew || leaseLocked}>
              <i className="et-pencil" />
            </a>
          )}
          <a onClick={onDelete} disabled={localIsProspect || leaseLocked}>
            <i className="et-trash" />
          </a>
        </div>
      </div>
    </Row>
  );
};

export default Adult;
