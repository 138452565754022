import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.NotFound.header',
    defaultMessage: 'Page not found!'
  },
  message: {
    id: 'App.NotFound.message',
    defaultMessage: 'You probably landed into a broken link or a page that doesn’t exist.'
  },
  message2: {
    id: 'App.NotFound.message2',
    defaultMessage: 'Please contact system administrator.'
  },
  button: {
    id: 'App.NotFound.button',
    defaultMessage: 'Go Back'
  }
});

export default messages;
