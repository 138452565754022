export const GET_ONE_PROSPECT = 'app/CreateApplication/GET_ONE_PROSPECT';
export const GET_ONE_PROSPECT_SUCCESS =
  'app/CreateApplication/GET_ONE_PROSPECT_SUCCESS';
export const GET_ONE_PROSPECT_ERROR =
  'app/CreateApplication/GET_ONE_PROSPECT_ERROR';

export const CREATE_APPLICATION = 'app/CreateApplication/CREATE_APPLICATION';
export const CREATE_APPLICATION_SUCCESS =
  'app/CreateApplication/CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION_ERROR =
  'app/CreateApplication/CREATE_APPLICATION_ERROR';

export const GET_APPLICATION_FEES =
  'app/CreateApplication/GET_APPLICATION_FEES';
export const GET_APPLICATION_FEES_SUCCESS =
  'app/CreateApplication/GET_APPLICATION_FEES_SUCCESS';
export const GET_APPLICATION_FEES_ERROR =
  'app/CreateApplication/GET_APPLICATION_FEES_ERROR';

export const CLEAN = 'app/CreateApplication/CLEAN';
