/* eslint-disable react/style-prop-object */
import React, { useState, SyntheticEvent, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import { isNil, pick } from 'ramda';

import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import {
  Button,
  Input,
  Spinner,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';

import SelectList from '../SelectList';

import {
  useFetchProperties,
  useCreatePropertyGroup,
  useFetchPropertyClasses,
  useFetchPropertyGroup,
  useUpdatePropertyGroup,
} from './hooks';
import { areAllPropertiesSelected } from '../utils';
import { promptToaster } from '../../App/actions';
import { DEFAULT_VALUES } from './constants';

import generalMessages from '../../App/messages';
import componentMessages from '../messages';

import type { PropertyGroup } from '../types';

const messages = { ...generalMessages, ...componentMessages };

type ConnectedProps = {
  organizationId: string,
  actions: {
    promptToaster: Function,
  },
};

const AddEditPropertyGroupsContainer = styled.div`
  margin-top: 35px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 35px;

  .MuiTypography-root.form-label {
    padding-bottom: 5px;
  }

  div.MuiInputBase-root > fieldset > legend.css-nnbavb {
    border: none;
    width: 0;
  }

  .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputMultiline:focus {
    box-shadow: none;
    border: none;
  }

  .MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputMultiline {
    border: none;
  }

  div.MuiGrid-root.formButtons button {
    min-height: 40px;
    min-width: 175px;
  }

  div.MuiButton-default,
  div.MuiInputBase-root {
    background-color: white;
  }

  div[id*='cl-enumerable-list-'] {
    width: 100%;
    height: 100%;
    div[id$='-dataListContainer'] {
      p.treeRowContent-treeRowLabel {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      div.treeRowCollapseIcon {
        min-width: 24px;
      }
    }
  }
`;

const StyledModalBody = styled(Modal.Body)`
  div.MuiGrid-root button {
    min-height: 40px;
    min-width: 175px;
  }
  div.MuiButton-default {
    background-color: white;
  }
  &.modal-body {
    min-height: 210px !important; // This is an issue with a general rule calculating auto to 0
    &,
    & > .modal-confirm {
      margin-top: 0;
    }
  }
`;

const AddEditPropertyGroups = ({
  intl,
  history,
  match,
  organizationId,
  locale,
  actions: { promptToaster },
  key,
}) => {
  const queryClient = useQueryClient();
  const propertyGroupId = match?.params?.propertyGroupId;
  const isEditMode = !isNil(propertyGroupId);

  const [allSelected, setAllSelected] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [resetKey, setResetKey] = useState('-');

  const title = !isEditMode
    ? intl.formatMessage(messages.addNewCustom, {
        custom: intl.formatMessage(messages.propertyGroup),
      })
    : `${intl.formatMessage(messages.edit)} ${intl.formatMessage(
        messages.propertyGroup,
      )}`;

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const navigateToManageScreen = async () => {
    // Invalidate fetch queries so they fetch the most recent data after saving
    await queryClient.invalidateQueries(
      'ManagePropertyGroups > Get Property Groups',
      {
        exact: true,
      },
    );
    history.push('/manage-property-groups');
  };

  const { data: propertyGroup, isLoading: isPropertyGroupDataLoading } =
    useFetchPropertyGroup({
      propertyGroupId,
      organizationId,
      promptToaster,
      intl,
      locale,
    });

  const { data: propertiesData, isLoading: isPropertiesDataLoading } =
    useFetchProperties({
      organizationId,
      promptToaster,
      intl,
    });

  const { data: propertyClasses, isLoading: isPropertyClassesDataLoading } =
    useFetchPropertyClasses({
      promptToaster,
      intl,
      locale,
    });

  const [mutateCreatePropertyGroup, isCreateSubmitting] =
    useCreatePropertyGroup({
      promptToaster,
      intl,
    });

  const [mutateUpdatePropertyGroup, isUpdateSubmitting] =
    useUpdatePropertyGroup({
      callbackFn: navigateToManageScreen,
      promptToaster,
      intl,
    });

  const resetForm = () => {
    reset(DEFAULT_VALUES);
    /* MUI and CL Checkboxes are literally a pain to deal
     * when controlled no matter how much state reset happens
     * they DO NOT RESET doing this to reset them for good.
     */
    setResetKey(resetKey === '-' ? '_' : '-');
  };

  const onCancelClick = () => {
    if (isDirty) {
      setShowCancelModal(true);
    } else {
      navigateToManageScreen();
    }
  };

  const onSaveAndClose = (data: PropertyGroup) => {
    if (isEditMode) {
      mutateUpdatePropertyGroup({
        organizationId,
        propertyGroupId,
        payload: data,
      });
    } else {
      mutateCreatePropertyGroup(
        {
          organizationId,
          payload: data,
        },
        {
          onSuccess: () => {
            promptToaster({
              type: 'success',
              title: intl.formatMessage(messages.success),
              message: intl.formatMessage(messages.propertyGroupCreateSuccess),
            });
            navigateToManageScreen();
          },
        },
      );
    }
  };

  const onSaveAndAdd = (data) => {
    mutateCreatePropertyGroup(
      {
        organizationId,
        payload: data,
      },
      {
        onSuccess: () => {
          promptToaster({
            type: 'success',
            title: intl.formatMessage(messages.success),
            message: intl.formatMessage(messages.propertyGroupCreateSuccess),
          });
          resetForm();
        },
      },
    );
  };

  const onCancelConfirm = () => {
    resetForm();
    setShowCancelModal(false);
    navigateToManageScreen();
  };

  const onCancelClose = () => {
    setShowCancelModal(false);
  };

  const onFormKeyDown = (event: SyntheticEvent) => {
    const keyCode = event.keyCode ? event.keyCode : event.which;
    if (keyCode === 13) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (isEditMode) {
      const formPropertyGroup = pick(
        ['name', 'description', 'propertyIds'],
        propertyGroup ?? {},
      );
      const allPropertiesSelected = areAllPropertiesSelected(
        propertiesData,
        formPropertyGroup?.propertyIds ?? [],
      );
      setAllSelected(allPropertiesSelected);
      reset({
        ...formPropertyGroup,
      });
    }
    // Adding this for the react-hook-form reset fn
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertiesData, propertyGroup, propertyGroupId]);

  return (
    <DocumentTitle
      title={intl.formatMessage(messages.fortressTitle, { title })}
    >
      <AddEditPropertyGroupsContainer
        key={isEditMode ? propertyGroupId : 'create'}
      >
        <Modal
          className="addEditPropertyGroups"
          bsSize="small"
          show={showCancelModal}
          autoFocus
          onHide={onCancelClose}
        >
          <StyledModalBody>
            <div className="modal-confirm">
              <h1>
                <FormattedMessage {...messages.cancelProgress} />
              </h1>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Button variant="primary" onClick={onCancelConfirm}>
                        <FormattedMessage {...messages.yes} />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Button variant="default" onClick={onCancelClose}>
                        <FormattedMessage {...messages.no} />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </StyledModalBody>
        </Modal>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Link to={'/manage-property-groups'} className="btn-text">
              <i className="et-chevron-left" />
              <FormattedMessage
                {...messages.backTo}
                values={{
                  route: `${intl.formatMessage(
                    messages.manage,
                  )} ${intl.formatMessage(messages.propertyGroups)}`,
                }}
              />
            </Link>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography variant="h2" component={'span'}>
                {title}
              </Typography>
              <Typography
                variant="caption"
                component={'span'}
                sx={{ paddingLeft: '10px' }}
              >
                *&nbsp;
                <FormattedMessage {...messages.allFieldsAreRequired} />
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <form
              style={{
                display: 'inline-block',
              }}
              onKeyDown={onFormKeyDown}
            >
              <Grid container rowSpacing={3}>
                <Grid item xs={9}>
                  <Typography variant="body2" className="form-label">
                    {`${intl.formatMessage(
                      messages.group,
                    )} ${intl.formatMessage(messages.name)}`}
                  </Typography>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { value, onChange, onBlur },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        name="name"
                        inputProps={{
                          maxLength: 255,
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        required
                        fullWidth
                        variant="outlined"
                        error={invalid}
                        helperText={
                          invalid ? intl.formatMessage(messages.required) : ''
                        }
                        disabled={isCreateSubmitting || isUpdateSubmitting}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2" className="form-label">
                    {`${intl.formatMessage(
                      messages.group,
                    )} ${intl.formatMessage(messages.description)}`}
                  </Typography>
                  <Controller
                    name="description"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { value, onChange, onBlur },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        name="description"
                        inputProps={{
                          maxLength: 255,
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        required
                        fullWidth
                        variant="outlined"
                        multiline
                        maxRows={4}
                        minRows={3}
                        error={invalid ?? false}
                        helperText={
                          invalid ?? false
                            ? intl.formatMessage(messages.required)
                            : ''
                        }
                        disabled={isCreateSubmitting || isUpdateSubmitting}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={9} minHeight={500}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    height={'100%'}
                    style={{ alignContent: 'flex-start' }}
                  >
                    {isPropertiesDataLoading ||
                    isPropertyClassesDataLoading ||
                    isPropertyGroupDataLoading ? (
                      <Grid item xs={12}>
                        <Spinner />
                      </Grid>
                    ) : (
                      [
                        <Grid item xs={12}>
                          <Typography variant="body2" className="form-label">
                            <FormattedMessage
                              {...messages.assignCustom}
                              values={{
                                custom: intl.formatMessage(messages.properties),
                              }}
                            />
                          </Typography>
                        </Grid>,
                        <Grid item xs={12} className="selectList">
                          <Controller
                            name="propertyIds"
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({
                              field: { value, onChange, onBlur },
                              fieldState: { invalid, error },
                            }) => (
                              <SelectList
                                key={`select${resetKey}propertiesList`}
                                intl={intl}
                                value={value}
                                onChange={onChange}
                                filterOptions={propertyClasses}
                                properties={propertiesData}
                                allSelected={allSelected}
                                isSubmitting={
                                  isCreateSubmitting || isUpdateSubmitting
                                }
                              />
                            )}
                          />
                        </Grid>,
                      ]
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    className="formButtons"
                  >
                    <Grid item>
                      <Button
                        onClick={onCancelClick}
                        disabled={isCreateSubmitting || isUpdateSubmitting}
                      >
                        <FormattedMessage {...messages.cancel} />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        onClick={handleSubmit(onSaveAndClose)}
                        disabled={
                          !isValid ||
                          isCreateSubmitting ||
                          isUpdateSubmitting ||
                          (isEditMode && !isDirty)
                        }
                        variant="primary"
                      >
                        <FormattedMessage
                          {...(isEditMode
                            ? messages.save
                            : messages.saveAndClose)}
                        />
                      </Button>
                    </Grid>
                    {!isEditMode && (
                      <Grid item>
                        <Button
                          type="submit"
                          onClick={handleSubmit(onSaveAndAdd)}
                          disabled={
                            !isValid || isCreateSubmitting || isUpdateSubmitting
                          }
                          variant="primary"
                        >
                          <FormattedMessage {...messages.saveAndAddNew} />
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </AddEditPropertyGroupsContainer>
    </DocumentTitle>
  );
};

const mapStateToProps = (state): ConnectedProps => ({
  organizationId: state?.app.currentUser
    ? state?.app.currentUser.user.organizationId
    : '',
  locale: state.languageProvider.locale,
});

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  );
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AddEditPropertyGroups));
