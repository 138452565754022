import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import {
  renderCurrencyField,
  renderSelectField,
} from '../../../../../utils/redux-form-helper';
import { pathOr, isEmpty, isNil } from 'ramda';
import messages from './messages';
import {
  editFeeTypeOptions,
  editFeeDescriptiveAmountOptions,
} from './constants';
import validate from './validate';

type Props = {
  show: boolean,
  dismiss: Function,
  handleSaveFees: Function,
  onSubmit: Function,
  error: any,
  pristine: boolean,
  change: Function,
  valid: boolean,
  reset: Function,
  dirty: boolean,
  initialValues: Object,
  leaseRequiredFees: Array<Object>,
  isSubmitting: boolean,
};

const EditableFeeRow = ({ fee, deleteFn, change }: any) => {
  const index = fee.idx;
  const [isNumerical, setIsNumerical] = useState(isNil(fee.descriptiveValue));
  useEffect(() => {
    setIsNumerical(isNil(fee.descriptiveValue));
  }, [fee.descriptiveValue]);
  const toggleFeeType = (ev) => {
    const selectedVal = pathOr('', ['target', 'value'], ev);
    setIsNumerical(selectedVal === 'numeric');
    change(
      `monthlyReqFees[${index}].descriptiveValue`,
      editFeeDescriptiveAmountOptions[0].value,
    );
  };

  return (
    <Row key={index}>
      <Col xs={4}>
        <label>{fee.label}</label>
      </Col>
      <Col xs={3}>
        <div className="form-group">
          <Field
            name={`monthlyReqFees[${index}].type`}
            component={renderSelectField}
            options={editFeeTypeOptions}
            onChange={toggleFeeType}
          />
        </div>
      </Col>
      <Col xs={4}>
        <div className="form-group">
          {isNumerical ? (
            <Field
              name={`monthlyReqFees[${index}].value`}
              component={renderCurrencyField}
              step="0.01"
              disabled={false}
            />
          ) : (
            <Field
              name={`monthlyReqFees[${index}].descriptiveValue`}
              component={renderSelectField}
              options={editFeeDescriptiveAmountOptions}
              disabled={false}
            />
          )}
        </div>
      </Col>
      <Col xs={1}>
        <div className="container-of-trash">
          <a onClick={() => deleteFn(fee)}>
            <i className="et-trash" />
          </a>
        </div>
      </Col>
    </Row>
  );
};

const RemovedFeeRow = ({ fee, reAddFn }: any) => {
  const index = fee.idx;
  const [isNumerical, setIsNumerical] = useState(isNil(fee.descriptiveValue));
  useEffect(() => {
    setIsNumerical(isNil(fee.descriptiveValue));
  }, [fee]);
  return (
    <Row key={index}>
      <Col xs={4}>
        <label>{fee.label}</label>
      </Col>
      <Col xs={3}>
        <div className="form-group">
          <Field
            name={`monthlyReqFees[${index}].type`}
            component={renderSelectField}
            options={editFeeTypeOptions}
            disabled={true}
          />
        </div>
      </Col>
      <Col xs={4}>
        <div className="form-group">
          {isNumerical ? (
            <Field
              name={`monthlyReqFees[${index}].value`}
              component={renderCurrencyField}
              step="0.01"
              disabled={true}
            />
          ) : (
            <Field
              name={`monthlyReqFees[${index}].descriptiveValue`}
              component={renderSelectField}
              options={editFeeDescriptiveAmountOptions}
              disabled={true}
            />
          )}
        </div>
      </Col>

      <Col xs={1}>
        <div className="container-of-trash">
          <a onClick={() => reAddFn(fee)}>
            <i className="et-plus" />
          </a>
        </div>
      </Col>
    </Row>
  );
};

export const EditableLeaseMonthlyRequiredFees = ({
  monthlyRecurringCharges,
  deleteFn,
  change,
}: Object) => (
  <React.Fragment>
    {monthlyRecurringCharges.map((fee) => (
      <EditableFeeRow fee={fee} deleteFn={deleteFn} change={change} />
    ))}
  </React.Fragment>
);

export const RemovedLeaseMonthlyRequiredFees = ({
  monthlyRecurringCharges,
  reAddFn,
}: Object) => (
  <React.Fragment>
    {monthlyRecurringCharges.map((fee) => (
      <RemovedFeeRow fee={fee} reAddFn={reAddFn} />
    ))}
  </React.Fragment>
);

export const EditRequiredLeaseFeesModal = (props: Props) => {
  const {
    error,
    initialValues: { monthlyReqFees },
  } = props;

  const [feesChanged, setFeesChanged] = useState(false);
  const [showCancelConfirmation, setShowCancelConfimation] = useState(false);
  const [removedFees, setRemovedFees] = useState([]);
  const [activeFees, setActiveFees] = useState([]);
  useEffect(() => {
    if (!feesChanged) {
      setRemovedFees(monthlyReqFees.filter((f) => f.isRemoved));
      setActiveFees(monthlyReqFees.filter((f) => !f.isRemoved));
    }
  }, [feesChanged, monthlyReqFees]);

  const onClose = () => {
    const { dirty, reset, dismiss } = props;

    if ((dirty || !isEmpty(removedFees)) && !showCancelConfirmation) {
      setShowCancelConfimation(true);
      return;
    } else if (dirty && showCancelConfirmation) {
      reset();
    }
    setShowCancelConfimation(false);
    setFeesChanged(false);
    dismiss();
  };
  const removeFee = (fee) => {
    setFeesChanged(true);
    setRemovedFees((removedFees) => [...removedFees, fee]);
    setActiveFees((activeFees) => activeFees.filter((i) => i !== fee));
  };
  const reAddFee = (fee) => {
    setFeesChanged(true);
    setRemovedFees((removedFees) => removedFees.filter((i) => i !== fee));
    setActiveFees((activeFees) => [...activeFees, fee]);
  };

  const renderModalHeader = () => (
    <Modal.Header closeButton>
      <i className="icon et-money" />
      <Modal.Title componentClass="h1">
        {' '}
        <FormattedMessage {...messages.modalTitle} />
      </Modal.Title>
    </Modal.Header>
  );

  const renderModalFooter = () => {
    const { pristine, valid, handleSaveFees, isSubmitting } = props;
    const enableSubmit = pristine || (!pristine && valid);

    return (
      <Modal.Footer className={showCancelConfirmation ? 'disabled' : ''}>
        <Row>
          <Col xs={6}>
            <Button bsStyle="default" className="pull-right" onClick={onClose}>
              <FormattedMessage {...messages.cancel} />
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              bsStyle="primary"
              className="pull-left"
              type="submit"
              onClick={handleSaveFees(removedFees)}
              disabled={!enableSubmit || isSubmitting}
            >
              <FormattedMessage {...messages.save} />
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    );
  };

  const renderModalFormBody = () => {
    return (
      <Row className="scrollable-modal__content">
        {!isEmpty(monthlyReqFees) ? (
          <>
            <div className="container-fluid">
              <Row>
                <Col xs={12}>
                  <h2>
                    <FormattedMessage {...messages.feesTitle} />
                  </h2>
                </Col>
              </Row>
            </div>
            <div className="container-fluid">
              <FieldArray
                name="monthlyReqFees"
                component={EditableLeaseMonthlyRequiredFees}
                monthlyRecurringCharges={activeFees}
                deleteFn={removeFee}
                change={props.change}
              ></FieldArray>
            </div>
            {isEmpty(removedFees) ? null : (
              <>
                <div className="container-fluid">
                  <Row>
                    <Col xs={12}>
                      <h2>
                        <FormattedMessage {...messages.removedFeesTitle} />
                      </h2>
                    </Col>
                  </Row>
                </div>
                <div className="container-fluid">
                  <FieldArray
                    name="removedMonthlyReqFees"
                    component={RemovedLeaseMonthlyRequiredFees}
                    monthlyRecurringCharges={removedFees}
                    reAddFn={reAddFee}
                  ></FieldArray>
                </div>
              </>
            )}
          </>
        ) : (
          renderEmptyFees()
        )}

        <div className="container-fluid">
          {error && (
            <Row>
              <Col xs={12} className="has-error">
                {error}
              </Col>
            </Row>
          )}
        </div>
      </Row>
    );
  };

  const renderModalCancelBody = () => {
    return (
      <div className="modal-confirm">
        <Row>
          <Col xs={12}>
            <h1>
              {' '}
              <FormattedMessage {...messages.cancelConfirmation} />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Button bsStyle="primary" className="pull-right" onClick={onClose}>
              <FormattedMessage {...messages.yes} />
            </Button>
          </Col>
          <Col xs={12} sm={6}>
            <Button
              bsStyle="default"
              className="pull-left"
              onClick={() => setShowCancelConfimation(false)}
            >
              <FormattedMessage {...messages.no} />
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const renderEmptyFees = () => {
    return (
      <div className="modal-confirm">
        <Row>
          <Col xs={12}>
            <h1>
              {' '}
              <FormattedMessage {...messages.noFees} />
            </h1>
          </Col>
        </Row>
      </div>
    );
  };
  const { show } = props;

  return (
    <form>
      <Modal
        className="edit-lease-data"
        bsSize="lg"
        backdrop
        show={show}
        onHide={() => onClose()}
      >
        {renderModalHeader()}
        <Modal.Body>
          {renderModalFormBody(monthlyReqFees)}
          {showCancelConfirmation && renderModalCancelBody()}
        </Modal.Body>
        {renderModalFooter()}
      </Modal>
    </form>
  );
};

export const mapStateToProps = (
  { form }: Object,
  { initialValues }: Object,
): Object => {
  return {
    initialValues,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'editRequiredLeaseFeesForm',
    enableReinitialize: true,
    validate,
  })(EditRequiredLeaseFeesModal),
);
