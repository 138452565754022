import React from 'react';
import {
  renderTextField,
  renderSelectField,
  renderNumberField,
  renderCheckboxField,
} from '../../../../utils/redux-form-helper';
import { Field } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import messages from '../messages';
import deleteConfirmation from '../../../../components/DeleteConfirmation';

type ComponentProps = {
  intl: any,
  pet: any,
  onDelete: Function,
  sortedPetTypes: Array<Object>,
  breeds: Array<Object>,
  currentPet: Object,
};

const PetIndividual = ({
  pet,
  onDelete,
  sortedPetTypes,
  currentPet,
  breeds,
  intl,
}: ComponentProps) => {
  const deleteItemCallback = (options) => {
    deleteConfirmation(intl.formatMessage(messages.deleteItemConfirmation), {
      intl,
    })
      .then(() => onDelete(options))
      .catch(() => {});
  };
  return (
    <Row>
      <div className="container-of-inputs">
        <Col xs={6} sm={2}>
          <Field
            className="input-lg"
            name={`${pet}.name`}
            component={renderTextField}
            maxLength="100"
          />
        </Col>
        <Col xs={6} sm={2}>
          <Field
            selectClassNames="input-lg"
            name={`${pet}.petTypeId`}
            component={renderSelectField}
            options={sortedPetTypes}
          />
        </Col>
        <Col xs={6} sm={2}>
          <Field
            selectClassNames="input-lg"
            name={`${pet}.petBreedId`}
            component={renderSelectField}
            options={breeds}
            disabled={!currentPet || !currentPet.petTypeId}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            className="input-lg"
            name={`${pet}.weight`}
            component={renderNumberField}
            bsSize="lg"
            maxLength="4"
          />
        </Col>
        <Col xs={6} sm={2}>
          <Field
            className="input-lg"
            name={`${pet}.petTag`}
            component={renderTextField}
            maxLength="20"
          />
        </Col>
        <Col xs={6} sm={1} className={'text-center'}>
          <Field
            name={`${pet}.serviceAnimal`}
            component={renderCheckboxField}
          />
        </Col>
      </div>
      <div className="container-of-trash">
        <div className="row-remove-btn">
          <a onClick={deleteItemCallback}>
            <i className="et-trash" />
          </a>
        </div>
      </div>
    </Row>
  );
};

export default PetIndividual;
