import { useState, useEffect } from 'react';
import { Field, FormSection } from 'redux-form';
import {
  renderCheckboxField,
  renderCurrencyField,
  renderSelectField,
  renderTextField,
} from '../../../../utils/redux-form-helper';
import { FormattedNumber } from 'react-intl';

import { getTransactionTypeOptions } from '../utils';
import { isNil } from 'ramda';

type Props = {
  id: string,
  transactionTypes: Object,
  prorateAmount: number,
  deleteRow: Function,
  isDeleteDisabled: boolean,
  transactionCode: string,
  change: Function,
  initialTransactionCodeOptions: Array<Object>,
};

const AddInlineRow = (props: Props) => {
  const {
    id,
    deleteRow,
    transactionTypes,
    prorateAmount,
    initialTransactionCodeOptions,
    transactionCode,
    isDeleteDisabled,
    change,
  } = props;
  const transactionTypesOptions = getTransactionTypeOptions(transactionTypes);
  const [filteredPTC, setFilteredPTC] = useState(initialTransactionCodeOptions);
  const [isTransactionCodeDisabled, setIsTransactionCodeDisabled] = useState(
    !(initialTransactionCodeOptions.length - 1),
  );

  const [isAmountDisabled, setIsAmountDisabled] = useState(!transactionCode);

  useEffect(() => {
    if (filteredPTC.length === 1 && initialTransactionCodeOptions.length > 1) {
      setFilteredPTC(initialTransactionCodeOptions);
      setIsTransactionCodeDisabled(false);
    }
  }, [filteredPTC, initialTransactionCodeOptions]);

  useEffect(() => {
    const isDisabled = !transactionCode;
    const hasChanged = isAmountDisabled - isDisabled !== 0;
    if (hasChanged) {
      setIsAmountDisabled(isDisabled);
    }
  }, [transactionCode, isAmountDisabled]);

  const resetTransactionCodes = (target, value) => {
    change(`inline-${id}.transactionCode`, '');
    setFilteredPTC([{ value: '', text: 'Choose', disabled: true }]);
  };

  return (
    <FormSection name={`inline-${id}`}>
      <tr>
        <td>
          <Field
            name="transactionType"
            component={renderSelectField}
            options={transactionTypesOptions}
            onChange={resetTransactionCodes}
            messageAsTooltip
          />
        </td>
        <td>
          <Field
            name="transactionCode"
            component={renderSelectField}
            options={filteredPTC}
            disabled={isTransactionCodeDisabled}
            messageAsTooltip
          />
        </td>
        <td>
          <Field
            name="amount"
            component={renderCurrencyField}
            messageAsTooltip
            min="0"
            max="100000"
            disabled={isAmountDisabled}
            style={{ zIndex: '0' }}
            placeholder="0.00"
          />
        </td>
        <td>
          <Field
            classNames=""
            component={renderTextField}
            name="note"
            placeholder="Optional"
          />
        </td>
        <td>
          {isNil(prorateAmount) ? (
            '---'
          ) : (
            <FormattedNumber
              className="pull-right"
              value={prorateAmount}
              style={{ value: 'currency' }.value}
              currency="USD"
            />
          )}
        </td>
        <td>
          <Field
            component={renderCheckboxField}
            disabled={isAmountDisabled}
            name="doNotProrate"
          />
        </td>
        <td>
          <button
            className="btn btn-sm btn-default"
            onClick={() => {
              deleteRow(id);
            }}
            type="button"
            disabled={isDeleteDisabled}
          >
            <i className="et-trash" />
          </button>
        </td>
      </tr>
    </FormSection>
  );
};

export default AddInlineRow;
