import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import { FormLabel } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { renderTextAreaField } from '../../../utils/redux-form-helper';
import React from 'react';
import messages from '../messages';
import ErrorMessage from '../../ErrorMessage';

const FormTemplate = ({ store, intl, formFields, error, warning }) => {
  return (
    <>
      {error && (
        <Row>
          <Col md={12}>
            <ErrorMessage message={error.message} />
          </Col>
        </Row>
      )}
      {warning && (
        <Row>
          <Col md={12}>
            <ErrorMessage type="warning" message={warning} />
          </Col>
        </Row>
      )}
      <Col md={6}>{formFields}</Col>
      <Col md={6}>
        <FormLabel>{intl.formatMessage(messages.notesLabel)}</FormLabel>
        <Field
          store={store}
          name="notes"
          component={renderTextAreaField}
          rows="3"
          placeholder="Start typing..."
          maxLength="2000"
        />
      </Col>
    </>
  );
};

export default FormTemplate;
