export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';
export const SET_SELECTED_PROPERTIES = 'SET_SELECTED_PROPERTIES';
export const SET_SELECTED_PROPERTY_GROUPS = 'SET_SELECTED_PROPERTY_GROUPS';
export const SET_USER_ALL_PROPERTIES = 'SET_USER_ALL_PROPERTIES';
export const NONE_PROPERTIES_SELECTED = 'NONE_PROPERTIES_SELECTED';
export const TOGGLE_MULTISELECT = 'TOGGLE_MULTISELECT';
export const CLEAR_FAIL_MESSAGE = 'CLEAR_FAIL_MESSAGE';
