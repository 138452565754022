import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { Button, Col, Row } from 'react-bootstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import {
  renderSelectField,
  renderTextAreaField,
} from '../../../utils/redux-form-helper';
import validate from './validate';
import type { DropdownOption } from '../../App/types';
import LookingFor from '../../../containers/ProspectProfile/ProspectProfileForm/LookingFor';

type Props = {
  intl: Object,
  pristine: boolean,
  valid: boolean,
  handleProspectInfoEdit: Function,
  handleSubmit: Function,
  handleCancel: Function,
  contactTypes: Array<DropdownOption>,
  referralTypes: Array<DropdownOption>,
  array: Object,
  change: Function,
  petTypes: Array<DropdownOption>,
  noMoveInDate: boolean,
  moveInDateToVisible: boolean,
};

const introductoryNoteSection = (intl) => (
  <Col xs={12}>
    <h3 className="hidden-xs">
      {' '}
      <FormattedMessage {...messages.introductoryNote} />
    </h3>
    <input className="accordion-click" type="checkbox" id="col2helpText" />
    <label
      className="checkbox accordion-trigger visible-xs"
      htmlFor="col2helpText"
    >
      <FormattedMessage {...messages.introductoryNote} />
    </label>
    <section className="form-section">
      <Field
        name="helpText"
        component={renderTextAreaField}
        placeholder={intl.formatMessage(messages.introductoryNotePlaceholder)}
        rows="3"
        maxLength="500"
      />
    </section>
  </Col>
);

const contactTypeSection = (contactTypes) => (
  <Col xs={12}>
    <h3 className="hidden-xs">
      <FormattedMessage {...messages.contactType} />
    </h3>
    <input className="accordion-click" type="checkbox" id="col2ctype" />
    <label
      className="checkbox accordion-trigger visible-xs"
      htmlFor="col2ctype"
    >
      <FormattedMessage {...messages.contactType} />
    </label>
    <section className="form-section" id="contacttype">
      <Field
        name="contactTypeId"
        component={renderSelectField}
        options={contactTypes}
        bsSize="lg"
      />
    </section>
  </Col>
);

const sourceSection = (referralTypes) => (
  <Col xs={12}>
    <h3 className="hidden-xs">
      <FormattedMessage {...messages.source} />
    </h3>
    <input className="accordion-click" type="checkbox" id="col2source" />
    <label
      className="checkbox accordion-trigger visible-xs"
      htmlFor="col2source"
    >
      <FormattedMessage {...messages.source} />
    </label>
    <section className="form-section" id="source">
      <Field
        name="referralTypeId"
        component={renderSelectField}
        options={referralTypes}
        bsSize="lg"
      />
    </section>
  </Col>
);

export const ProspectInfoTabForm = (props: Props) => {
  const {
    intl,
    handleCancel,
    handleSubmit,
    contactTypes,
    referralTypes,
    change,
    pristine,
    floorPlans,
    valid,
    initialValues,
  } = props;

  return (
    <div>
      <Col xs={12} sm={4}>
        <h1>{intl.formatMessage(messages.overview)}</h1>
      </Col>
      <div className="divider" />
      <form onSubmit={handleSubmit}>
        <Col xs={12}>
          <Row>
            {introductoryNoteSection(intl)}
            <LookingFor
              intl={intl}
              reduxFormOnChange={change}
              floorPlans={floorPlans}
              prospectInfoEditMode={true}
              initialValues={initialValues}
            />
            {contactTypeSection(contactTypes)}
            {sourceSection(referralTypes)}
          </Row>
          <div className="row btn-toolbar">
            <Col xs={6}>
              <Button
                className="btn btn-default pull-right"
                onClick={() => handleCancel(pristine)}
              >
                {intl.formatMessage(messages.cancel)}
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="btn btn-primary pull-left"
                type="submit"
                disabled={pristine || !valid}
              >
                {intl.formatMessage(messages.save)}
              </Button>
            </Col>
          </div>
        </Col>
      </form>
    </div>
  );
};

export const mapStateToProps = ({ app, form }: Object): Object => {
  const selector = formValueSelector('prospectInfoTab');
  return {
    moveInDateToVisible: selector({ form }, 'moveInDateToVisible'),
    noMoveInDate: selector({ form }, 'noMoveInDate'),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'prospectInfoTab',
    validate: validate,
  })(ProspectInfoTabForm),
);
