import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.App.ViewBankDeposit.AddBankDepositDocument.Title',
    defaultMessage: 'Document Upload',
  },
  cancel: {
    id: 'App.App.ViewBankDeposit.AddBankDepositDocument.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.App.ViewBankDeposit.AddBankDepositDocument.Save',
    defaultMessage: 'Save',
  },
  saveAndClose: {
    id: 'App.App.ViewBankDeposit.AddBankDepositDocument.SaveClose',
    defaultMessage: 'Save and Close',
  },
  saveAndAddNew: {
    id: 'App.App.ViewBankDeposit.AddBankDepositDocument.SaveAddNew',
    defaultMessage: 'Save and Add New',
  },
  confirmCancel: {
    id: 'App.App.ViewBankDeposit.AddBankDepositDocument.ConfirmCancel',
    defaultMessage: 'Are you sure you want to close without saving?',
  },
  yes: {
    id: 'App.App.ViewBankDeposit.AddBankDepositDocument.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.App.ViewBankDeposit.AddBankDepositDocument.No',
    defaultMessage: 'No',
  },
  uploading: {
    id: 'App.App.ViewBankDeposit.AddBankDepositDocument.Uploading',
    defaultMessage: 'Uploading, please wait...',
  },
  attachmentUpdateSuccessTitle: {
    id:
      'App.App.ViewBankDeposit.AddBankDepositDocument.AttachmentUpdateSuccessTitle',
    defaultMessage: 'Attachment updated',
  },
  attachmentUpdateSuccessMsg: {
    id:
      'App.App.ViewBankDeposit.AddBankDepositDocument.AttachmentUpdateSuccessMsg',
    defaultMessage: 'The deposit attachment was updated successfully',
  },
  attachmentUpdateErrorTitle: {
    id:
      'App.App.ViewBankDeposit.AddBankDepositDocument.AttachmentUpdateErrorTitle',
    defaultMessage: 'Error updating attachment',
  },
  attachmentUploadSuccessTitle: {
    id:
      'App.App.ViewBankDeposit.AddBankDepositDocument.AttachmentUploadSuccessTitle',
    defaultMessage: 'Attachment uploaded',
  },
  attachmentUploadSuccessMsg: {
    id:
      'App.App.ViewBankDeposit.AddBankDepositDocument.AttachmentUploadSuccessMsg',
    defaultMessage: 'The deposit attachment was uploaded successfully',
  },
  attachmentUploadErrorTitle: {
    id:
      'App.App.ViewBankDeposit.AddBankDepositDocument.AttachmentUploadErrorTitle',
    defaultMessage: 'Error uploading attachment',
  },
  fileSelectionError: {
    id: 'App.App.ViewBankDeposit.AddBankDepositDocument.FileSelectionError',
    defaultMessage: 'An error occurred, please try again later.',
  },
});

export default messages;
