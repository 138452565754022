// $FlowFixMe
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { isPristine, isInvalid, submit } from 'redux-form';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import messages from './messages';

import { useAsyncSaveUnit } from './hooks';
import ConfirmOverlay from '../../../../components/ConfirmOverlay';
import GeneralUnitInformationForm from './EditUnitForm';
import { promptToaster } from '../../../App/actions';
import { FORM_NAME } from '../constants';
import useIsAffordableProperty from '../../../../hooks/useIsAffordableProperty';

type ConnectedProps = {
  pristine: boolean,
  invalid: boolean,
};

type Props = ConnectedProps & {
  refreshUnit: Function,
  unitToEdit: Object,
  onClose: Function,
  show: boolean,
  reset: Function,
  selectedProperty: Object,
  actions: Object,
  intl: Object,
};

const EditUnitGeneralInformationFormModal = ({
  unitToEdit,
  refreshUnit,
  pristine,
  invalid,
  onClose,
  reset,
  show,
  actions,
  intl,
  selectedProperty,
  flags,
}: Props) => {
  const isAffordableProperty = useIsAffordableProperty(selectedProperty);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const hideCancelConfirmation = () => setShowCancelConfirmation(false);

  const handleHide = () => {
    if (!pristine && !showCancelConfirmation) {
      setShowCancelConfirmation(true);
      return;
    }
    setShowCancelConfirmation(false);
    if (onClose) {
      onClose();
    }
  };
  const unitId = pathOr('', ['id'], unitToEdit);
  const saveUnitInfo = useAsyncSaveUnit(
    unitId,
    intl,
    selectedProperty,
    actions.promptToaster,
    refreshUnit,
  );

  const submit = (evt: Object) => {
    evt.preventDefault();
    const { submit } = actions;
    submit(FORM_NAME);
    onClose();
  };

  return (
    <Modal
      className="modal"
      backdrop
      bsSize="sm"
      show={show}
      onHide={handleHide}
      autoFocus
    >
      <div className="modal-content">
        <Modal.Header closeButton>
          <Modal.Title componentClass="h1">
            <FormattedMessage {...messages.modalHeader} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-vehicle-hh">
          {showCancelConfirmation && (
            <ConfirmOverlay
              title={<FormattedMessage {...messages.confirmationTitle} />}
              rejectMessage={
                <FormattedMessage {...messages.confirmationCancel} />
              }
              confirmMessage={<FormattedMessage {...messages.confirmation} />}
              onReject={hideCancelConfirmation}
              onConfirm={handleHide}
            />
          )}
          <GeneralUnitInformationForm
            onSubmit={saveUnitInfo}
            initialValues={unitToEdit}
            intl={intl}
            isAffordableProperty={isAffordableProperty}
            flags={flags}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-xs-6">
              <button
                className="btn btn-default pull-right"
                onClick={handleHide}
              >
                <FormattedMessage {...messages.modalCancel} />
              </button>
            </div>
            <div className="col-xs-6">
              <button
                className="btn btn-primary pull-left"
                type="submit"
                onClick={submit}
                disabled={pristine || invalid}
              >
                <FormattedMessage {...messages.modalSubmit} />
              </button>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export const mapStateToProps = ({ app, form, unitToEdit }: Object): Object => {
  // Using { form } because Redux helpers expect state object
  const pristine = isPristine(FORM_NAME)({ form });
  const invalid = isInvalid(FORM_NAME)({ form });
  return {
    pristine,
    invalid,
    selectedProperty: app.selectedProperty,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      promptToaster,
      submit,
    },
    dispatch,
  );
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditUnitGeneralInformationFormModal);
