import { defineMessages } from 'react-intl';

const messages = defineMessages({
  reportId: {
    id: 'App.ViewCommunication.CommunicationId',
    defaultMessage: 'Communication Id',
  },
  header: {
    id: 'App.ViewCommunication.CommunicationHeader',
    defaultMessage: 'View Communications',
  },
  subHeader: {
    id: 'App.ViewCommunication.CommunicationSubHeader',
    defaultMessage: 'Previous Communications Generated',
  },
  communications: {
    id: 'App.ViewCommunication.Communications',
    defaultMessage: 'Communications',
  },
  method: {
    id: 'App.ViewCommunication.Method',
    defaultMessage: 'Method',
  },
  type: {
    id: 'App.ViewCommunication.Type',
    defaultMessage: 'Type',
  },
  createdDate: {
    id: 'App.ViewCommunication.CreatedDate',
    defaultMessage: 'Date',
  },
  createdTime: {
    id: 'App.ViewCommunication.CreatedTime',
    defaultMessage: 'Time',
  },
  view: {
    id: 'App.ViewCommunication.View',
    defaultMessage: 'View',
  },
  status: {
    id: 'App.ViewCommunication.Status',
    defaultMessage: 'Status',
  },
  search: {
    id: 'App.ViewCommunication.Search',
    defaultMessage: 'Search',
  },
  title: {
    id: 'App.ViewCommunication.TitleCol',
    defaultMessage: 'Title',
  },
});

export default messages;
