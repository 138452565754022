import React, { useEffect, useState } from 'react';

type Props = {
  onChange: Function,
  clearSearch: Function,
  reseatSearch: Function,
};

export function SearchBar(props: Props) {
  const { onChange, clearSearch, resetSearch } = props;
  const [searchInput, setSearchInput] = useState('');

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onChange?.('searchTerm', {
      type: 'search',
      value: searchInput,
    });
  };

  const onSearchChange = (e) => {
    setSearchInput(e.target.value);
    if (e.target.value === '') {
      clearSearch();
    }
  };

  useEffect(() => {
    setSearchInput('');
  }, [resetSearch]);

  return (
    <div className="search-input search-input-active filter-icon-btn">
      <form onSubmit={handleSubmit}>
        <input
          name="searchText"
          className="input-lg"
          type="text"
          placeholder="Search"
          value={searchInput}
          onChange={onSearchChange}
        />
      </form>
      <button onClick={handleSubmit} type="submit">
        <i className="et-search" />
      </button>
    </div>
  );
}
