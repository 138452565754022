import { put, takeLatest, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import { GET_ALL_DELINQUENCIES } from './constants';
import {
  getAllDelinquenciesSuccess,
  getAllDelinquenciesError
} from './actions';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId
} from '../App/selectors';
import { renderNoDataToastr } from '../../utils/redux-form-helper';
import DelinquencyService from '../../services/delinquencyService';

import type { Property } from '../App/types';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetAllDelinquenciesSaga(): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const delinquencyService = new DelinquencyService();
    const response = yield delinquencyService.getAllDelinquencies(
      organizationId,
      selectedProperty.id
    );
    if (response.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getAllDelinquenciesSuccess(response));
  } catch (err) {
    yield put(getAllDelinquenciesError(err));
  }
}

export function* getAllDelinquenciesSaga(): Saga<void> {
  yield takeLatest(GET_ALL_DELINQUENCIES, fetchGetAllDelinquenciesSaga);
}

export default [getAllDelinquenciesSaga];
