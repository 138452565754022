export const GET_APPLICANT_INFORMATION =
  'app/ShortLeaseApplication/GET_APPLICANT_INFORMATION';
export const GET_APPLICANT_INFORMATION_SUCCESS =
  'app/ShortLeaseApplication/GET_APPLICANT_INFORMATION_SUCCESS';
export const GET_APPLICANT_INFORMATION_ERROR =
  'app/CreateApplication/GET_APPLICANT_INFORMATION_ERROR';

export const SAVE_APPLICANT_FORM =
  'app/ShortLeaseApplication/SAVE_APPLICANT_FORM';
export const SAVE_APPLICANT_FORM_SUCCESS =
  'app/ShortLeaseApplication/SAVE_APPLICANT_FORM_SUCCESS';
export const SAVE_APPLICANT_FORM_ERROR =
  'app/ShortLeaseApplication/SAVE_APPLICANT_FORM_ERROR';

export const CLEAN = 'app/ShortLeaseApplication/CLEAN';

export const FORM_NAME = 'shortLeaseApplication';
