import React from 'react';
import { Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import MultiselectTwoSides from '@fortress-technology-solutions/react-multiselect-two-sides';
import {
  renderTextField,
  renderSelectField,
} from '../../../../utils/redux-form-helper';
import messages from '../../messages';

export const Code = ({ intl, disabled }: Object) => {
  return (
    <Col xs={6}>
      <h3 className="hidden-xs">
        {' '}
        <FormattedMessage {...messages.code} />{' '}
      </h3>
      <section>
        <Field
          name="code"
          component={renderTextField}
          bsSize="lg"
          value="{code}"
          placeholder={intl.formatMessage(messages.code)}
          disabled={disabled}
        />
      </section>
    </Col>
  );
};

export const DefaultCreditGLAccount = ({ intl, disabled }: Object) => {
  return (
    <Col xs={6}>
      <h3 className="hidden-xs">
        {' '}
        <FormattedMessage {...messages.defaultCreditGLAccount} />{' '}
      </h3>
      <section>
        <Field
          name="defaultCreditGLAccount"
          component={renderTextField}
          bsSize="lg"
          placeholder={intl.formatMessage(messages.defaultCreditGLAccount)}
          disabled={disabled}
        />
      </section>
    </Col>
  );
};

export const DefaultDebitGLAccount = ({ intl, disabled }: Object) => {
  return (
    <Col xs={6}>
      <h3 className="hidden-xs">
        {' '}
        <FormattedMessage {...messages.defaultDebitGLAccount} />{' '}
      </h3>
      <section>
        <Field
          name="defaultDebitGLAccount"
          component={renderTextField}
          bsSize="lg"
          placeholder={intl.formatMessage(messages.defaultDebitGLAccount)}
          disabled={disabled}
        />
      </section>
    </Col>
  );
};

export const Description = ({ intl, disabled }: Object) => {
  return (
    <Col xs={6}>
      <h3 className="hidden-xs">
        {' '}
        <FormattedMessage {...messages.description} />{' '}
      </h3>
      <section>
        <Field
          name="description"
          component={renderTextField}
          bsSize="lg"
          placeholder={intl.formatMessage(messages.description)}
          disabled={disabled}
        />
      </section>
    </Col>
  );
};

export const Subjournal = ({ subjournals, disabled }: Object) => {
  return (
    <Col xs={6}>
      <h3 className="hidden-xs">
        {' '}
        <FormattedMessage {...messages.subjournal} />{' '}
      </h3>
      <section>
        <Field
          name="subjournal"
          component={renderSelectField}
          options={subjournals}
          bsSize="lg"
          disabled={disabled}
        />
      </section>
    </Col>
  );
};

export const TransactionType = ({ transactionTypes, disabled }: Object) => {
  return (
    <Col xs={6}>
      <h3 className="hidden-xs">
        {' '}
        <FormattedMessage {...messages.transactionType} />{' '}
      </h3>
      <section>
        <Field
          name="transactionType"
          component={renderSelectField}
          options={transactionTypes}
          bsSize="lg"
          disabled={disabled}
        />
      </section>
    </Col>
  );
};

export const PropertySelector = ({
  disabled,
  availableCount,
  selectedCount,
  properties,
  selectedProperties,
  hideMultiselect,
  nonePropertiesSelected,
  handleMultiselectChange,

  intl,
}: Object) => (
  <Col xs={12}>
    <h3 className="hidden-xs">
      {' '}
      <FormattedMessage {...messages.assignToProperty} />{' '}
    </h3>
    <div>
      <MultiselectTwoSides
        className={[
          'msts_theme_example',
          hideMultiselect ? 'msts-disabled' : '',
          nonePropertiesSelected ? 'msts-error' : '',
        ].join(' ')}
        onChange={handleMultiselectChange}
        availableHeader="Available"
        availableFooter={`Available: ${availableCount}`}
        selectedHeader="Selected"
        selectedFooter={`Selected: ${selectedCount}`}
        placeholder="Filter…"
        options={properties}
        searchable={true}
        showControls={true}
        value={selectedProperties}
        disabled={disabled}
      />
    </div>
    {nonePropertiesSelected && (
      <span className="has-error">Please select at least 1 property</span>
    )}
  </Col>
);
