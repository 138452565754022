import React from 'react';
import { Form } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';

type Props = {
  handleSubmit: Function,
  placeholder: string,
  onFilterClick: any,
  hasActiveFilters: any,
  onDownload: Function,
};

export const SearchActions = ({
  placeholder,
  handleSubmit,
  onFilterClick,
  hasActiveFilters,
  onDownload,
}: Props) => (
  <div className="search-actions">
    <button
      data-test="filter-btn"
      className={`button-filter ${
        hasActiveFilters ? 'filters-are-active' : ''
      }`}
      onClick={() => onFilterClick(true)}
    >
      <i className="et-filter" />
    </button>
    <div className="search-input">
      <Form onSubmit={handleSubmit}>
        <Field
          className="input-lg"
          component="input"
          type="search"
          name="searchText"
          placeholder={placeholder}
        />
        <button type="submit">
          <i className="et-search" />
        </button>
      </Form>
    </div>
    <button
      className="btn btn-tertiary"
      onClick={onDownload}
      style={{
        marginTop: 0,
        float: 'unset',
      }}
    >
      <i className="et-cloud-download" />
      <span style={{ marginLeft: '8px' }}>CSV</span>
    </button>
  </div>
);

export default reduxForm({
  form: 'searchUsers',
})(SearchActions);
