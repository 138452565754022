import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import ProspectRecipients from './ProspectRecipients';
import ResidentRecipients from './ResidentRecipients';
import ApplicantRecipients from './ApplicantRecipients';

type Props = {
  selectedRecipients: Array<Object>,
  communicationType: string,
  communicationBatchGenerated: boolean,
  validEmails: Array<any>,
  validPhones: Array<any>,
  response: Object,
  emailsValidated: boolean,
  phonesValidated: boolean,
  metricsInformation: Object,
  isViewCommunication: boolean,
  recipientsStatus: Array<Object>,
  recipientsType: string,
  isAllCommercial: boolean,
  selectedPropertyId: string,
};

const getRecipientComponent = (
  props,
  successRecipients,
  isEmailCommunication,
  isSmsCommunication,
  isEmailOrSmsCommunication,
  showMetricsInformation,
  isViewCommunication,
  isAllCommercial,
  selectedPropertyId,
) => {
  switch (props.recipientsType) {
    case 'residents':
    case 'Current Resident':
      return (
        <ResidentRecipients
          {...props}
          successRecipients={successRecipients}
          isEmailCommunication={isEmailCommunication}
          isSmsCommunication={isSmsCommunication}
          isEmailOrSmsCommunication={isEmailOrSmsCommunication}
          showMetricsInformation={showMetricsInformation}
          isViewCommunication={isViewCommunication}
          isAllCommercial={isAllCommercial}
        />
      );
    case 'prospects':
    case 'Prospect':
      return (
        <ProspectRecipients
          {...props}
          successRecipients={successRecipients}
          isEmailCommunication={isEmailCommunication}
          isSmsCommunication={isSmsCommunication}
          isEmailOrSmsCommunication={isEmailOrSmsCommunication}
          showMetricsInformation={showMetricsInformation}
          isViewCommunication={isViewCommunication}
        />
      );
    case 'Applicant':
      return (
        <ApplicantRecipients
          {...props}
          successRecipients={successRecipients}
          isEmailCommunication={isEmailCommunication}
          isSmsCommunication={isSmsCommunication}
          isEmailOrSmsCommunication={isEmailOrSmsCommunication}
          showMetricsInformation={showMetricsInformation}
          selectedPropertyId={selectedPropertyId}
        />
      );
    default:
      return null;
  }
};

export const Recipients = (props: Props) => {
  const {
    metricsInformation,
    selectedRecipients,
    communicationType,
    isViewCommunication,
    isAllCommercial,
    selectedPropertyId,
  } = props;
  const isEmailCommunication = communicationType === 'EMAIL';
  const isSmsCommunication = communicationType === 'SMS';
  const isEmailOrSmsCommunication = isEmailCommunication || isSmsCommunication;
  const successRecipients = props.response.successRecipients || [];
  const showMetricsInformation =
    isViewCommunication && isEmailOrSmsCommunication;
  return (
    <div className="panel unit-information panel-withheader communication-recipients-wrap">
      <div className="panel-head">
        <Row>
          <Col xs={12} sm={6}>
            <h2>Recipients</h2>
          </Col>
          <Col xs={12} sm={6} className="text-right">
            {showMetricsInformation ||
            (isEmailOrSmsCommunication && props.communicationBatchGenerated) ? (
              <div>
                <label className="text-success padright5">
                  Delivered: {metricsInformation.successRecipients}
                </label>
                <label className="text-red padright5">
                  Failure: {metricsInformation.failedRecipients}
                </label>
                <label>Total: {selectedRecipients.length}</label>
              </div>
            ) : (
              <h4>Selected: {selectedRecipients.length}</h4>
            )}
          </Col>
        </Row>
      </div>
      <div className="panel__details--full" style={{ 'overflow-x': 'scroll' }}>
        <Row>
          <Col xs={12}>
            <Table>
              <tbody>
                {getRecipientComponent(
                  props,
                  successRecipients,
                  isEmailCommunication,
                  isSmsCommunication,
                  isEmailOrSmsCommunication,
                  showMetricsInformation,
                  isViewCommunication,
                  isAllCommercial,
                  selectedPropertyId,
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Recipients;
