import React from 'react';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
const OnOffTypography = ({ on, children, ...props }) => {
  return (
    <Typography color={on ? 'primary' : 'action.disabled'} {...props}>
      {children}
    </Typography>
  );
};

export default OnOffTypography;
