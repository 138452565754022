import { SORT_DIRECTION } from '../../../../../utils/data-sorter';

const RESIDENT = 'resident';
const PRIOR_RESIDENT = 'priorResident';
const APPLICANT = 'applicant';
const PROSPECT = 'prospect';
const DAYS_OPEN = 'daysOpen';
const DAYS_SINCE_CONTACT = 'daysSinceContact';
const PRIORITY = 'priority';
const ASSIGNED_TO = 'assignedTo';
const NUMBER_OF_ACTIVITIES = 'numOfActivities';

type SortConfig = { key: string, direction: string };

type Assignee = {
  value: string,
  text: string,
  disabled: boolean,
  dropDownMenuAssign: string,
};

const getLastName = (fullName: string) => [...fullName].pop();

const sort = (a, b, sortConfig: SortConfig): number => {
  if (a < b) {
    return sortConfig.direction === SORT_DIRECTION.ASCENDING ? -1 : 1;
  }
  if (a > b) {
    return sortConfig.direction === SORT_DIRECTION.ASCENDING ? 1 : -1;
  }
  return 0;
};

const sortByLastName = (
  a: { link: string, value: string },
  b: { link: string, value: string },
  sortConfig: SortConfig,
): number => {
  const aLastName = getLastName(a.value.toLowerCase().trim().split(' '));
  const bLastName = getLastName(b.value.toLowerCase().trim().split(' '));

  return sort(aLastName, bLastName, sortConfig);
};

const sortAssigneeByLastName = (
  a: Assignee | string,
  b: Assignee | string,
  sortConfig: SortConfig,
): number => {
  if (a.hasOwnProperty('text')) {
    const aLastName = getLastName(a.text.toLowerCase().trim().split(' '));
    const bLastName = getLastName(b.text.toLowerCase().trim().split(' '));
    return sort(aLastName, bLastName, sortConfig);
  }

  const aLastName = getLastName(a.toLowerCase().trim().split(' '));
  const bLastName = getLastName(b.toLowerCase().trim().split(' '));

  return sort(aLastName, bLastName, sortConfig);
};

const sortByPriority = (
  a: string,
  b: string,
  sortConfig: SortConfig,
): number => {
  const priorityOrder = ['Urgent', 'High', 'Medium', 'Low', 'Warranty'];
  return sort(priorityOrder.indexOf(a), priorityOrder.indexOf(b), sortConfig);
};

const sortByDays = (
  a: number | string,
  b: number | string,
  sortConfig: SortConfig,
): number => {
  if (typeof a === 'string') {
    return sortConfig.direction === SORT_DIRECTION.ASCENDING ? -1 : 1;
  }
  if (typeof b === 'string') {
    return sortConfig.direction === SORT_DIRECTION.ASCENDING ? 1 : -1;
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return 0;
  }
  return sort(a, b, sortConfig);
};

export const sortTaskTables = (aElement, bElement, sortConfig) => {
  const a = aElement[sortConfig.key];
  const b = bElement[sortConfig.key];

  switch (sortConfig.key) {
    case RESIDENT:
    case PRIOR_RESIDENT:
    case APPLICANT:
    case PROSPECT:
      return sortByLastName(a, b, sortConfig);
    case DAYS_OPEN:
    case DAYS_SINCE_CONTACT:
      return sortByDays(a, b, sortConfig);
    case PRIORITY:
      return sortByPriority(a, b, sortConfig);
    case ASSIGNED_TO:
      return sortAssigneeByLastName(a, b, sortConfig);
    case NUMBER_OF_ACTIVITIES:
      return sort(parseInt(a), parseInt(b), sortConfig);
    default:
      if (a === 'N/A') {
        return sortConfig.direction === SORT_DIRECTION.ASCENDING ? -1 : 1;
      }
      if (b === 'N/A') {
        return sortConfig.direction === SORT_DIRECTION.ASCENDING ? 1 : -1;
      }
      if (a === 'N/A' && b === 'N/A') {
        return 0;
      }
      return sort(a, b, sortConfig);
  }
};
