import useFetchIntegrations from '../../hooks/data-fetching/useFetchIntegrations';
import { isWorkNumberShown } from './utils';

import DataDisplayer from './DataDisplayer';

const TheWorkNumber = (props: {
  propertyId: string,
  organizationId: string,
  householdId: string,
  currentUser: Object,
}) => {
  const { propertyId, organizationId } = props;
  const [integrations] = useFetchIntegrations(
    organizationId,
    propertyId,
    'twn-affordable',
  );
  const { organization, property } = integrations;

  return isWorkNumberShown(organization, property) ? (
    <DataDisplayer {...props} />
  ) : null;
};

export default TheWorkNumber;
