import React from 'react';
import moment from 'moment';
import {
  is,
  isEmpty,
  path,
  pathOr,
  propOr,
  values,
  curryN,
  allPass,
  always,
} from 'ramda';
import { isDate } from 'lodash';
import {
  calculateRentWithAmenities,
  getCurrentGrossRentLimit,
  getCurrentTDHA,
  getMonthlyRecurringCharges,
} from './lease-helpers';

type ReturnObject = {
  value: any,
  raw: string | number,
  link?: ?string,
};

const emptyResult = { value: '', raw: '' };

const isBetweenDates = (startDateMoment: Object, endDateMoment: ?Object) => {
  const today = moment();
  return (
    startDateMoment.isBefore(today) &&
    (!endDateMoment ||
      (endDateMoment.isAfter(today) &&
        startDateMoment.format('L') !== endDateMoment.format('L')))
  );
};

const isSomeoneTransferring = (unit: Object) =>
  pathOr(false, ['nextAssignation', 'lease', 'isTransfer'], unit);

export const floorPlan = (unit: Object): ReturnObject => {
  const floorPlanName = pathOr('', ['floorPlan', 'internalName'], unit);
  const floorPlanId = pathOr('', ['floorPlan', 'id'], unit);

  const results: ReturnObject = {
    value: floorPlanName,
    raw: floorPlanName,
  };
  if (floorPlanId) {
    results.link = `/floorplan/${floorPlanId}`;
  }
  // $FlowFixMe
  results.isAffordable = pathOr(false, ['floorPlan', 'isAffordable'], unit);
  // $FlowFixMe
  results.isCommercial = pathOr(false, ['floorPlan', 'isCommercial'], unit);
  // $FlowFixMe
  results.isCommercialLeasingActive = pathOr(
    false,
    ['floorPlan', 'isCommercialLeasingActive'],
    unit,
  );

  return results;
};

export const bedsBaths = (unit: Object): ReturnObject => {
  const beds = pathOr(0, ['floorPlan', 'nBeds'], unit);
  const baths = pathOr(0, ['floorPlan', 'nBaths'], unit);
  const halfBaths = pathOr(0, ['floorPlan', 'nHalfBaths'], unit);

  return {
    value: `${+beds}/${+baths + +halfBaths * 0.5}`,
    raw: `${+beds}|${+baths + +halfBaths * 0.5}`,
  };
};

export const squareFeet = (unit: Object): ReturnObject => {
  const grossSquareFeet = pathOr('', ['floorPlan', 'grossSquareFeet'], unit);
  return {
    value: grossSquareFeet,
    raw: grossSquareFeet,
  };
};

export const status = (unit: Object): ReturnObject => {
  const description = pathOr('', ['unitStatus', 'description'], unit);
  return {
    value: description,
    raw: description,
  };
};

export const leased = (unit: Object): ReturnObject => {
  const hasApplicant = pathOr(false, ['nextAssignation', 'id'], unit)
    ? 'Yes'
    : '';

  const status = pathOr(
    '',
    ['nextAssignation', 'application', 'applicationStatus', 'name'],
    unit,
  );

  if (
    status === 'Converted to Resident' &&
    unit.currentAssignation &&
    !isSomeoneTransferring(unit)
  ) {
    return emptyResult;
  }

  return {
    value: hasApplicant,
    raw: hasApplicant,
  };
};

export const moveInDate = (unit: Object): ReturnObject => {
  const moveIn = pathOr(
    '',
    ['currentAssignation', 'lease', 'actualMoveInDate'],
    unit,
  );
  const moveInVal = moveIn ? moment(moveIn).format('MM/DD/YYYY') : '';
  return {
    value: moveInVal,
    raw: moveInVal,
  };
};

export const leaseStartDate = (unit: Object): ReturnObject => {
  const start = pathOr('', ['currentAssignation', 'lease', 'startDate'], unit);
  const startVal = start ? moment(start).format('MM/DD/YYYY') : '';
  return {
    value: startVal,
    raw: startVal,
  };
};

export const leaseEndDate = (unit: Object): ReturnObject => {
  const end = pathOr('', ['currentAssignation', 'lease', 'endDate'], unit);
  const endVal = end ? moment(end).format('MM/DD/YYYY') : '';
  return {
    value: endVal,
    raw: endVal,
  };
};

export const collectionsNotes = (
  unit: Object,
  activities: Object,
): ReturnObject => {
  const residentId = pathOr(
    null,
    [
      'currentAssignation',
      'application',
      'household',
      'mainCus',
      'rc',
      'residentId',
    ],
    unit,
  );
  if (residentId) {
    const activity = activities[residentId];
    if (activity) {
      const startTime = moment(activity.startTime);
      // Only show Collections Notes younger than 30 days
      const isOld = startTime.isBefore(moment().subtract(30, 'days'), 'day');
      if (!isOld) {
        return {
          value: `${startTime.format('MM/DD/YYYY')} - ${activity.notes}`,
          raw: `${startTime.format('MM/DD/YYYY')} - ${activity.notes}`,
        };
      }
    }
  }
  return emptyResult;
};

export const notes = (unit: Object): ReturnObject => {
  const notes = pathOr('', ['notes'], unit);
  return {
    value: notes,
    raw: notes,
  };
};

export const underEviction = (unit: Object): ReturnObject => {
  const underEviction = pathOr(
    false,
    ['currentAssignation', 'application', 'household', 'underEviction'],
    unit,
  );

  return {
    value: underEviction ? <i className="icon et-alert-urgent text-red" /> : '',
    raw: underEviction ? 'Yes' : '',
  };
};

export const resident = (unit: Object): ReturnObject => {
  const currentAssignation = propOr(false, 'currentAssignation', unit);
  if (currentAssignation === false) {
    return emptyResult;
  }

  let name;
  let firstName = '';
  let lastName = '';
  let residentId = '';
  if (currentAssignation) {
    firstName = pathOr(
      pathOr(
        '',
        ['lease', 'household', 'mainCus', 'firstName'],
        currentAssignation,
      ),
      ['application', 'household', 'mainCus', 'firstName'],
      currentAssignation,
    );
    lastName = pathOr(
      pathOr(
        '',
        ['lease', 'household', 'mainCus', 'firstName'],
        currentAssignation,
      ),
      ['application', 'household', 'mainCus', 'lastName'],
      currentAssignation,
    );
    residentId = pathOr(
      pathOr(
        '',
        ['lease', 'household', 'mainCus', 'firstName'],
        currentAssignation,
      ),
      ['application', 'household', 'mainCus', 'rc', 'residentId'],
      currentAssignation,
    );
  }
  if (firstName && lastName) {
    name = [lastName, firstName].join(', ');
  } else {
    name = firstName + lastName;
  }

  const isUnitCommercial =
    pathOr(false, ['floorPlan', 'isCommercial'], unit) &&
    pathOr(false, ['floorPlan', 'isCommercialLeasingActive'], unit);
  if (isUnitCommercial) {
    name = firstName;
  }

  const results: ReturnObject = {
    value: `${name}`,
    raw: `${name}`,
  };
  if (residentId) {
    results.link = `/resident/${residentId}`;
  }
  return results;
};

export const marketRent = (unit: Object, intl: Object): ReturnObject => {
  const config1Value = pathOr(
    '',
    ['property', 'propertyReportConfiguration', 0, 'config1Value'],
    unit,
  );

  let startingRent;
  switch (config1Value) {
    case 'Market Rent':
    default:
      startingRent = pathOr(0, ['floorPlan', 'baseMarketRentAmount'], unit);
      break;
    case 'Quoting Rent':
      startingRent = pathOr(0, ['floorPlan', 'quotingRentAmount'], unit);
      break;
    case 'Financial Market Rent':
      startingRent = pathOr(
        0,
        ['floorPlan', 'financialMarketRentAmount'],
        unit,
      );
      break;
  }

  if (startingRent === 0) {
    return emptyResult;
  }
  const rent = calculateRentWithAmenities(unit, startingRent);

  const value = rent
    ? intl.formatNumber(rent, { style: 'currency', currency: 'USD' })
    : '';

  return {
    value,
    raw: rent || 0,
  };
};

export const priorLeasedRent = (unit: Object, intl: Object): ReturnObject => {
  const rent = pathOr(null, ['priorAssignation', 'lease', 'leasedRent'], unit);
  const value = rent
    ? intl.formatNumber(rent, { style: 'currency', currency: 'USD' })
    : '';
  return {
    value,
    raw: rent || 0,
  };
};

export const currentLeasedRent = (unit: Object, intl: Object): ReturnObject => {
  const isVacant = pathOr(false, ['unitStatus', 'isVacant'], unit);

  let rent = 0;

  if (!isVacant) {
    rent = pathOr(0, ['currentAssignation', 'lease', 'leasedRent'], unit);
  } else {
    const applicantStatus = pathOr(
      '',
      ['nextAssignation', 'application', 'applicationStatus', 'name'],
      unit,
    );
    if (applicantStatus.includes('Approved')) {
      rent = pathOr(0, ['nextAssignation', 'lease', 'leasedRent'], unit);
    }
  }

  const value = rent
    ? intl.formatNumber(rent, { style: 'currency', currency: 'USD' })
    : '';
  return {
    value,
    raw: rent || 0,
  };
};

export const otherUnitFees = (unit: Object, intl: Object): ReturnObject => {
  const transactions = pathOr(
    [],
    ['currentAssignation', 'lease', 'leaseMonthlyTransactions'],
    unit,
  );

  const chargedFees = transactions.reduce((prev, t) => {
    const code = pathOr('', ['ptc', 'tc', 'code'], t);
    const transactionType = pathOr('', ['ptc', 'tc', 'tt', 'name'], t);
    const notRent = code !== 'RENT' && code !== 'SUBRENT';
    const isOptional = pathOr(false, ['ptc', 'isOptional'], t);
    const isConcession = pathOr(false, ['ptc', 'tc', 'isConcession'], t);
    const notOptionalOrConcession =
      !isOptional && !isConcession && transactionType === 'Charge';
    const startDate = path(['startDate'], t);
    const endDate = path(['endDate'], t);
    const startDateMoment = moment(startDate);
    const endDateMoment = endDate ? moment(endDate) : null;
    const value = pathOr(0, ['amount'], t);

    if (
      notRent &&
      notOptionalOrConcession &&
      isBetweenDates(startDateMoment, endDateMoment)
    ) {
      // $FlowFixMe
      prev += value;
    }
    return prev;
  }, 0);

  const value = chargedFees
    ? intl.formatNumber(+chargedFees, { style: 'currency', currency: 'USD' })
    : 0;
  return {
    value,
    raw: chargedFees || 0,
  };
};

export const otherUnitFeesAvailable = (
  unit: Object,
  intl: Object,
): ReturnObject => {
  const arr = getMonthlyRecurringCharges(unit);
  const nonRentCharges = arr.reduce((prev, fee) => {
    prev += fee.value;
    return prev;
  }, 0);
  const value = nonRentCharges
    ? intl.formatNumber(nonRentCharges, { style: 'currency', currency: 'USD' })
    : '';
  return {
    value,
    raw: nonRentCharges || 0,
  };
};

export const balance = (unit: Object, intl: Object): ReturnObject => {
  const bal = pathOr(0, ['balance'], unit);
  const value = bal
    ? intl.formatNumber(bal, { style: 'currency', currency: 'USD' })
    : '';
  return {
    value,
    raw: bal || 0,
  };
};

export const moveOutDate = (unit: Object): ReturnObject => {
  const isVacant = pathOr(null, ['unitStatus', 'isVacant'], unit);

  if (!isVacant) {
    const ntvDate = pathOr(
      null,
      ['currentAssignation', 'lease', 'noticeToVacate', 'moveOutDate'],
      unit,
    );
    if (ntvDate !== null) {
      const date = moment(ntvDate).format('MM/DD/YYYY');
      return {
        value: date,
        raw: date,
      };
    }
    return emptyResult;
  }
  const moveOut = pathOr(
    null,
    ['priorAssignation', 'lease', 'moveOutDate'],
    unit,
  );
  const moveOutDate = moveOut ? moment(moveOut).format('MM/DD/YYYY') : '';
  return {
    value: moveOutDate,
    raw: moveOutDate,
  };
};

export const moveInReadyDate = (unit: Object, today: string): ReturnObject => {
  const unitStatus = pathOr(null, ['unitStatus', 'description'], unit);
  const isAvailable = pathOr(false, ['unitStatus', 'isAvailable'], unit);
  if (isAvailable === false) {
    return emptyResult;
  }

  if (unitStatus === 'Vacant - Ready') {
    return { value: 'Ready', raw: 'Ready' };
  }

  const moveOut = pathOr(
    pathOr(null, ['priorAssignation', 'lease', 'moveOutDate'], unit),
    ['currentAssignation', 'lease', 'noticeToVacate', 'moveOutDate'],
    unit,
  );

  const makeReadyDate = unit.makeReadyDate;
  if (
    makeReadyDate &&
    (unitStatus === 'Vacant / Not Ready' ||
      unitStatus === 'Occupied / On Notice to Vacate')
  ) {
    const makeReadyVal = moment
      .utc(makeReadyDate, 'YYYY-MM-DD')
      .format('MM/DD/YYYY');
    return {
      value: makeReadyVal,
      raw: makeReadyVal,
    };
  }

  const turnMoveOutDays = pathOr(0, ['property', 'turnMoveOutDays'], unit);
  if (!moveOut) {
    return { value: 'Unknown', raw: 'Unknown' };
  }

  const mMakeReadyByTurnOutDays = moment(moveOut).add(turnMoveOutDays, 'days');
  if (mMakeReadyByTurnOutDays.isSameOrBefore(today)) {
    return { value: 'Immediate', raw: 'Immediate' };
  }

  const makeReadyByTurnOutDays = mMakeReadyByTurnOutDays.format('MM/DD/YYYY');
  return {
    value: makeReadyByTurnOutDays,
    raw: makeReadyByTurnOutDays,
  };
};

export const daysVacant = (unit: Object, today: string): ReturnObject => {
  const isAvailable = pathOr(false, ['unitStatus', 'isAvailable'], unit);
  if (isAvailable === false) {
    return emptyResult;
  }

  const isVacant = pathOr(false, ['unitStatus', 'isVacant'], unit);
  if (isVacant === false) {
    return emptyResult;
  }

  const moveOut = pathOr(
    null,
    ['priorAssignation', 'lease', 'moveOutDate'],
    unit,
  );

  if (!moveOut) {
    const lastVacancyStartDate = unit?.lastVacancyStartDate;
    if (isAvailable && isVacant && lastVacancyStartDate) {
      const daysVacantStr = moment()
        .diff(moment(lastVacancyStartDate), 'days')
        .toString();
      return {
        value: daysVacantStr,
        raw: daysVacantStr,
      };
    } else return emptyResult;
  }

  const mMoveOut = moment(moveOut);

  if (mMoveOut.isAfter(today)) {
    return emptyResult;
  }

  const daysVacantStr = moment().diff(moment(mMoveOut), 'days');
  return {
    value: daysVacantStr,
    raw: daysVacantStr,
  };
};

export const vacancyLoss = (unit: Object, intl: Object) => {
  const today = moment().format('YYYY-MM-DD');
  const vacantDays = daysVacant(unit, today).raw || '0';
  const dateLastOccupied = moment().subtract(vacantDays, 'd');
  const monthsVacant = moment().diff(dateLastOccupied, 'months');
  const rawValue = monthsVacant * parseInt(marketRent(unit, intl).raw, 10);
  const value = rawValue
    ? intl.formatNumber(rawValue, {
        style: 'currency',
        currency: 'USD',
      })
    : '';
  return {
    value,
    raw: rawValue,
  };
};

export const quotingRent = (unit: Object, intl: Object): ReturnObject => {
  const isAvailable = pathOr(false, ['unitStatus', 'isAvailable'], unit);
  if (isAvailable === false) {
    return emptyResult;
  }

  let startingRent = unit.quotingRentAmount;
  if (!startingRent) {
    startingRent = pathOr('', ['floorPlan', 'quotingRentAmount'], unit);
    if (startingRent === '') {
      return emptyResult;
    }
  }
  const rent = calculateRentWithAmenities(unit, startingRent);

  const value = rent
    ? intl.formatNumber(rent, { style: 'currency', currency: 'USD' })
    : '';
  return {
    value,
    raw: rent || 0,
  };
};

export const applicant = (unit: Object): ReturnObject => {
  if (!path(['nextAssignation', 'application'], unit)) {
    return emptyResult;
  }

  const status = pathOr(
    '',
    ['nextAssignation', 'application', 'applicationStatus', 'name'],
    unit,
  );

  if (
    status === 'Converted to Resident' &&
    unit.currentAssignation &&
    !isSomeoneTransferring(unit)
  ) {
    return emptyResult;
  }

  const customer = pathOr(
    // TODO remove inner pathOr when everything is using the household on app instead of lease
    pathOr({}, ['nextAssignation', 'lease', 'household', 'mainCus'], unit),
    ['nextAssignation', 'application', 'household', 'mainCus'],
    unit,
  );
  if (isEmpty(customer)) {
    return emptyResult;
  }
  const applicationId = pathOr(
    '',
    ['nextAssignation', 'application', 'id'],
    unit,
  );
  let name, firstName, lastName;
  if (customer.lastName) {
    lastName = customer.lastName;
  }
  if (customer.firstName) {
    firstName = customer.firstName;
  }
  if (firstName && lastName) {
    name = [lastName, firstName].join(', ');
  } else {
    name = firstName + lastName;
  }

  const isUnitCommercial =
    pathOr(false, ['floorPlan', 'isCommercial'], unit) &&
    pathOr(false, ['floorPlan', 'isCommercialLeasingActive'], unit);
  if (isUnitCommercial) {
    name = customer.firstName ? customer.firstName : '';
  }
  const results: ReturnObject = {
    value: `${name}`,
    raw: `${name}`,
  };
  const residentId = pathOr(
    // TODO remove inner pathOr when everything is using the household on app instead of lease
    pathOr(
      '',
      ['nextAssignation', 'lease', 'household', 'mainCus', 'rc', 'residentId'],
      unit,
    ),
    [
      'nextAssignation',
      'application',
      'household',
      'mainCus',
      'rc',
      'residentId',
    ],
    unit,
  );
  if (isSomeoneTransferring(unit)) {
    results.link = `/resident/${residentId}`;
  } else {
    if (applicationId) {
      results.link = `/application/${applicationId}`;
    }
  }

  return results;
};

export const applicantStatus = (unit: Object): ReturnObject => {
  const status = pathOr(
    '',
    ['nextAssignation', 'application', 'applicationStatus', 'name'],
    unit,
  );

  if (status === 'Converted to Resident' && !unit.currentAssignation) {
    return {
      value: 'Awaiting Move In',
      raw: 'Awaiting Move In',
    };
  } else if (
    status === 'Converted to Resident' &&
    unit.currentAssignation &&
    isSomeoneTransferring(unit)
  ) {
    const unitNumber = pathOr(
      // TODO remove inner pathOr when everything is using the household on app instead of lease
      pathOr(
        '',
        [
          'nextAssignation',
          'lease',
          'household',
          'activeLease',
          'units',
          '0',
          'number',
        ],
        unit,
      ),
      [
        'nextAssignation',
        'application',
        'household',
        'activeLease',
        'units',
        '0',
        'number',
      ],
      unit,
    );
    return {
      value: `Transferring from ${unitNumber}`,
      raw: 'Transferring',
    };
  } else if (
    status === 'Converted to Resident' &&
    unit.currentAssignation &&
    !isSomeoneTransferring(unit)
  ) {
    return emptyResult;
  }
  return {
    value: status,
    raw: status,
  };
};

export const scheduledMoveInDate = (unit: Object): ReturnObject => {
  const status = pathOr(
    '',
    ['nextAssignation', 'application', 'applicationStatus', 'name'],
    unit,
  );
  if (
    status === 'Converted to Resident' &&
    unit.currentAssignation &&
    !isSomeoneTransferring(unit)
  ) {
    return emptyResult;
  }

  const isMovedIn = pathOr(
    false,
    ['nextAssignation', 'lease', 'isMovedIn'],
    unit,
  );
  if (isMovedIn) {
    return emptyResult;
  }

  const leaseMoveInDate = unit?.nextAssignation?.lease?.moveInDate;
  const preferenceMoveInDate =
    unit?.nextAssignation?.application?.household?.mainCus?.pp?.prefers
      ?.moveInDateScheduled;

  const moveInDate = leaseMoveInDate ?? preferenceMoveInDate ?? null;

  let formattedDate = '---';
  if (moveInDate) {
    formattedDate = moment(moveInDate).format('MM/DD/YYYY');
  }

  return {
    value: formattedDate,
    raw: formattedDate,
  };
};

export const subsidyOrChargedRent = (
  unit: Object,
  intl: Object,
  type: string,
): ReturnObject => {
  const transactions = pathOr(
    [],
    ['currentAssignation', 'lease', 'leaseMonthlyTransactions'],
    unit,
  );

  const today = moment();
  const sr = transactions.reduce((prev, t) => {
    const code = pathOr('', ['ptc', 'tc', 'code'], t);
    const startDate = path(['startDate'], t);
    const endDate = path(['endDate'], t);
    const startDateMoment = moment(startDate);
    const endDateMoment = endDate ? moment(endDate) : null;
    const amount = pathOr(pathOr(0, ['transactionAmount'], t), ['amount'], t);
    if (
      code === type &&
      startDateMoment.isBefore(today) &&
      (!endDateMoment ||
        (endDateMoment.isAfter(today) &&
          startDateMoment.format('L') !== endDateMoment.format('L')))
    ) {
      // $FlowFixMe
      return prev + amount;
    }
    return prev;
  }, 0);

  const value = sr
    ? intl.formatNumber(sr, {
        style: 'currency',
        currency: 'USD',
      })
    : '';

  return {
    value,
    raw: sr || 0,
  };
};

export const getCurrentTDHAValue = (unit: Object): any => {
  const fpua = pathOr(null, ['floorPlan', 'fpua'], unit);
  const allowances = pathOr(null, ['floorPlan', 'allowances'], unit);
  if (fpua && fpua.length > 0) {
    const filteredTDHA = getCurrentTDHA(fpua);
    if (filteredTDHA.length === 0) {
      return 0;
    }
    return filteredTDHA[0].feeAmount;
  }
  if (allowances && allowances.length > 0) {
    return allowances[0].utilityAllowanceAmount;
  }
  return 0;
};

const findMatchingFPUtilityAllowanceByDate = (
  fpUtilityAllowances: Array<Object>,
  voucherEffectiveDate: string,
) => {
  return fpUtilityAllowances.reduce((current, fpAllowance) => {
    const endDate = pathOr(null, ['endDate'], fpAllowance);
    const startDate = pathOr(null, ['startDate'], fpAllowance);

    const mEnDate = moment(endDate);
    const mStartDate = moment(startDate);
    const mEffectiveDate = moment(voucherEffectiveDate);

    if (endDate === null && mEffectiveDate.isSameOrAfter(mStartDate)) {
      return fpAllowance;
    }

    if (mEffectiveDate.isAfter(mEnDate)) {
      return current;
    }

    if (mEffectiveDate.isBetween(mStartDate, mEnDate)) {
      return fpAllowance;
    }

    if (mEffectiveDate.isBefore(mStartDate)) {
      return current;
    }

    return current;
  }, null);
};

export const determineUtilityAllowanceFee = (
  floorPlan: Object,
  voucherEffectiveDate: string,
) => {
  const allowances = pathOr(null, ['allowances'], floorPlan);
  const allowancesHUD =
    allowances &&
    allowances.filter((allowance) =>
      pathOr(false, ['pha', 'isHUD'], allowance),
    );
  const allowance =
    allowancesHUD && allowancesHUD.length > 0 && allowancesHUD[0];

  /* If there is no Allowance, then there won't be a fpua either */
  if (!allowance) {
    return null;
  }

  const fpUtilityAllowances = pathOr(null, ['fpua'], floorPlan);
  const fpUtilityAllowancesHUD =
    fpUtilityAllowances &&
    fpUtilityAllowances.filter((ua) =>
      pathOr(false, ['allowances', 'pha', 'isHUD'], ua),
    );

  const matchingFPAllowance =
    fpUtilityAllowancesHUD &&
    fpUtilityAllowancesHUD.length > 0 &&
    findMatchingFPUtilityAllowanceByDate(
      fpUtilityAllowancesHUD,
      voucherEffectiveDate,
    );

  return matchingFPAllowance
    ? pathOr(null, ['feeAmount'], matchingFPAllowance)
    : pathOr(null, ['utilityAllowanceAmount'], allowance);
};

export const determineHUDFloorPlan = (unit: Object) =>
  pathOr([], ['floorPlan', 'floorPlanAffordablePrograms'], unit).reduce(
    (acc, program) => {
      if (acc === true) return acc;
      return (
        pathOr(
          '',
          ['propertyAffordableProgram', 'masterAffordableProgram', 'name'],
          program,
        ) === 'HUD'
      );
    },
    false,
  );

export const grossRentLimit = (
  unit: Object,
  intl: Object,
  marketRents: Array<Object>,
): ReturnObject => {
  const isAffordable = pathOr(false, ['floorPlan', 'isAffordable'], unit);
  const floorPlanId = path(['floorPlanId'], unit);
  if (!isAffordable) {
    return emptyResult;
  }
  const baseMarketRent = pathOr(0, ['floorPlan', 'baseMarketRentAmount'], unit);
  const startingRent = getCurrentGrossRentLimit(
    baseMarketRent,
    marketRents,
    floorPlanId,
  );
  let value = 0;
  let rent = 0;
  const isHUDFloorPlan = determineHUDFloorPlan(unit);
  if (isHUDFloorPlan) {
    const leaseStartDate = pathOr(
      null,
      ['currentAssignation', 'lease', 'startDate'],
      unit,
    );
    const HUDUtilityAllowance = leaseStartDate
      ? determineUtilityAllowanceFee(unit.floorPlan, leaseStartDate)
      : determineUtilityAllowanceFee(unit.floorPlan, moment().format());
    const HUDGrossRent = pathOr(0, ['floorPlan', 'HUDGrossRent'], unit);
    rent = +HUDGrossRent - +HUDUtilityAllowance;
    value = intl.formatNumber(rent, {
      style: 'currency',
      currency: 'USD',
    });
  } else {
    const tdhaValue = getCurrentTDHAValue(unit);
    rent = calculateRentWithAmenities(unit, startingRent);
    const maxRentPercent = pathOr(100, ['floorPlan', 'maxRentPercent'], unit);
    value = rent
      ? intl.formatNumber((rent - tdhaValue) * (maxRentPercent / 100), {
          style: 'currency',
          currency: 'USD',
        })
      : '';
  }
  return {
    value,
    raw: rent || 0,
  };
};

const searchable = (unit: Object): ReturnObject => {
  const residents = pathOr(
    [],
    ['currentAssignation', 'lease', 'resident'],
    unit,
  );
  const applicants = allHouseholdMembersNames(
    pathOr({}, ['currentAssignation'], unit),
  );
  let searchable = residents
    .map((resident) => {
      const firstName = pathOr('', ['rc', 'customer', 'firstName'], resident);
      const lastName = pathOr('', ['rc', 'customer', 'lastName'], resident);
      return `${firstName} ${lastName}`;
    })
    .join(' ');
  if (!isEmpty(applicants)) {
    searchable = searchable.concat(' ').concat(applicants);
  }
  return {
    value: searchable,
    raw: searchable,
  };
};

export function sortUnitsByMoveInReady(
  units: Object[],
  order: string = 'DESC',
  dateFormat: string = 'MM/DD/YYYY',
): Object[] {
  const moveInReadyPath = ['rows', 'moveInReady', 'raw'];

  const {
    readyUnits,
    immediateUnits,
    unknownUnits,
    unavailableUnits,
    dateUnits,
  } = units.reduce(
    (acc, cur) => {
      const moveInReady = pathOr('', moveInReadyPath, cur);

      if (moveInReady === 'Ready')
        return { ...acc, readyUnits: [...acc.readyUnits, cur] };
      if (moveInReady === 'Immediate')
        return { ...acc, immediateUnits: [...acc.immediateUnits, cur] };
      if (moveInReady === 'Unknown')
        return { ...acc, unknownUnits: [...acc.unknownUnits, cur] };
      if (moveInReady === false)
        return {
          ...acc,
          unavailableUnits: [...acc.unavailableUnits, cur],
        };
      if (
        isDate(moveInReady) ||
        (typeof moveInReady === 'string' && !isNaN(Date.parse(moveInReady)))
      )
        return { ...acc, dateUnits: [...acc.dateUnits, cur] };

      return acc;
    },
    {
      readyUnits: [],
      immediateUnits: [],
      unknownUnits: [],
      unavailableUnits: [],
      dateUnits: [],
    },
  );
  const sortedMoveInReadyDateUnits = dateUnits.sort((a, b) => {
    const aVal = pathOr('', moveInReadyPath, a);
    const bVal = pathOr('', moveInReadyPath, b);

    const aDate =
      aVal === '' ? moment(0).unix() : moment(aVal, dateFormat).unix();
    const bDate =
      bVal === '' ? moment(0).unix() : moment(bVal, dateFormat).unix();

    return order === 'ASC' ? bDate - aDate : aDate - bDate;
  });

  return order === 'ASC'
    ? [
        ...unavailableUnits,
        ...unknownUnits,
        ...sortedMoveInReadyDateUnits,
        ...immediateUnits,
        ...readyUnits,
      ]
    : [
        ...readyUnits,
        ...immediateUnits,
        ...sortedMoveInReadyDateUnits,
        ...unknownUnits,
        ...unavailableUnits,
      ];
}

type DateRangeFilter = {
  type: 'date-range',
  key: string,
  from: string,
  to: string,
};
type ValueFilter = { type: 'value', key: string, values: string[] | number[] };
export type Filter = ValueFilter | DateRangeFilter;
type FilterArray = Filter[];
export type MappedTableRow = {
  rows: { [key: string]: { raw: string | number, value: string | number } },
};

const makeValueFilter = curryN(2, (f: ValueFilter, unit: MappedTableRow) => {
  const val = unit?.rows?.[f.key]?.raw;
  return f.values.includes(val);
});

const makeDateRangeFilter = curryN(
  2,
  (f: DateRangeFilter, unit: MappedTableRow) => {
    const momentFrom = f.from && moment(f.from);
    const momentTo = f.to && moment(f.to);

    if (
      !momentFrom.isValid?.() ||
      !momentTo.isValid?.() ||
      !momentTo.isSameOrAfter(momentFrom, 'day')
    )
      return true; // Filter is invalid

    const val = unit?.rows?.[f.key]?.raw;

    if (!val) return false;

    const date = moment(val);

    if (!date.isValid()) return false;

    return date.isBetween(f.from, f.to, 'day', '[]');
  },
);

const applyFilters = curryN(2, (filters: FilterArray, unit: MappedTableRow) => {
  const predicates = filters.map((f: Filter) => {
    if (f.type === 'value') return makeValueFilter(f);
    if (f.type === 'date-range') return makeDateRangeFilter(f);
    return always(true);
  });
  const evalFilter = allPass(predicates);

  return evalFilter(unit);
});

const applySearchText = curryN(2, (searchStr: string, unit: MappedTableRow) => {
  const rows = pathOr({}, ['rows'], unit);
  const searchable = pathOr({}, ['searchable'], unit);
  const rowValues = values(rows);
  rowValues.push(searchable);
  let result = false;
  rowValues.forEach((row) => {
    const value = `${pathOr('', ['value'], row)}`.toLowerCase().trim();
    const rawValue = `${pathOr('', ['raw'], row)}`.toLowerCase().trim();
    const search = `${searchStr.toLowerCase().trim()}`;
    if (value.indexOf(search) !== -1 || rawValue.indexOf(search) !== -1) {
      result = true;
    }
  });
  return result;
});

const makeUnitFilter = (filters: FilterArray, searchStr?: string) =>
  filters.length > 0 || (typeof searchStr === 'string' && searchStr.length > 0)
    ? allPass([applyFilters(filters), applySearchText(searchStr)])
    : null;

export const sortAndFilterUnits = (
  units: MappedTableRow[],
  field: string,
  dir: string = 'ASC',
  searchStr: string = '',
  dateFormat: string = 'MM/DD/YYYY',
  filters: FilterArray = [],
): Array<Object> => {
  const unitFilter = makeUnitFilter(filters, searchStr);
  let filteredUnits = units.filter(
    ({ hideUnitFromOnlineAvailability }) =>
      hideUnitFromOnlineAvailability === true,
  );
  filteredUnits = unitFilter ? units.filter(unitFilter) : units;

  if (field !== 'moveInReady')
    return filteredUnits.sort((a: Object, b: Object): number => {
      if (field === '') return 1;
      const aVal = pathOr('', ['rows', field, 'raw'], a);
      const bVal = pathOr('', ['rows', field, 'raw'], b);

      if (field.match(/Date$/i)) {
        const aDate =
          aVal === '' ? moment(0).unix() : moment(aVal, dateFormat).unix();
        const bDate =
          bVal === '' ? moment(0).unix() : moment(bVal, dateFormat).unix();
        return dir === 'ASC' ? aDate - bDate : bDate - aDate;
      }

      if (is(Number, aVal) && is(Number, bVal)) {
        return dir === 'ASC' ? aVal - bVal : bVal - aVal;
      }

      return dir === 'ASC'
        ? ('' + aVal).localeCompare(bVal, undefined, {
            numeric: true,
            sensitivity: 'base',
            ignorePunctuation: true,
          })
        : ('' + bVal).localeCompare(aVal, undefined, {
            numeric: true,
            sensitivity: 'base',
            ignorePunctuation: true,
          });
    });

  return sortUnitsByMoveInReady(filteredUnits, dir, dateFormat);
};

export const buildAvailabilityCounts = (
  rows: Array<Object>,
  intl: Object,
  marketRents: Array<Object>,
) => {
  return rows.reduce(
    (prev, row) => {
      const sqFeetObj = squareFeet(row);
      const quotingRentObj = quotingRent(row, intl);
      const otherUnitFeesObj = otherUnitFeesAvailable(row, intl);
      const marketRentObj = {
        raw: row.rentCalculations.marketRent,
      };
      const grossRentLimitObj = grossRentLimit(row, intl, marketRents);
      const subsidyRentObj = subsidyOrChargedRent(row, intl, 'SUBRENT');
      const priorLeasedRentObj = priorLeasedRent(row, intl);
      const currentLeasedRentObj = currentLeasedRent(row, intl);
      const balanceObj = balance(row, intl);

      return {
        sqFeet: (prev.sqFeet += +sqFeetObj.raw),
        quotingRent: (prev.quotingRent += +quotingRentObj.raw),
        marketRent: (prev.marketRent += +marketRentObj.raw),
        grossRentLimit: (prev.grossRentLimit += +grossRentLimitObj.raw),
        subsidyRent: (prev.subsidyRent += +subsidyRentObj.raw),
        priorLeasedRent: (prev.priorLeasedRent += +priorLeasedRentObj.raw),
        currentLeasedRent: (prev.currentLeasedRent +=
          +currentLeasedRentObj.raw),
        otherUnitFees: (prev.otherUnitFees += +otherUnitFeesObj.raw),
        balance: (prev.balance += +balanceObj.raw),
      };
    },
    {
      sqFeet: 0,
      quotingRent: 0,
      marketRent: 0,
      grossRentLimit: 0,
      subsidyRent: 0,
      priorLeasedRent: 0,
      currentLeasedRent: 0,
      otherUnitFees: 0,
      balance: 0,
    },
  );
};

export const buildRentRollCounts = (
  rows: Array<Object>,
  intl: Object,
  marketRents: Array<Object>,
) => {
  return rows.reduce(
    (prev, row) => {
      const sqFeetObj = squareFeet(row);
      const otherUnitFeesObj = otherUnitFees(row, intl);
      const marketRentObj = marketRent(row, intl);
      const grossRentLimitObj = grossRentLimit(row, intl, marketRents);
      const subsidyRentObj = subsidyOrChargedRent(row, intl, 'SUBRENT');
      const residentChargedRentObj = subsidyOrChargedRent(row, intl, 'RENT');
      const priorLeasedRentObj = priorLeasedRent(row, intl);
      const currentLeasedRentObj = currentLeasedRent(row, intl);
      const balanceObj = balance(row, intl);

      return {
        sqFeet: (prev.sqFeet += +sqFeetObj.raw),
        marketRent: (prev.marketRent += +marketRentObj.raw),
        grossRentLimit: (prev.grossRentLimit += +grossRentLimitObj.raw),
        subsidyRent: (prev.subsidyRent += +subsidyRentObj.raw),
        residentChargedRent: (prev.residentChargedRent +=
          +residentChargedRentObj.raw),
        priorLeasedRent: (prev.priorLeasedRent += +priorLeasedRentObj.raw),
        currentLeasedRent: (prev.currentLeasedRent +=
          +currentLeasedRentObj.raw),
        otherUnitFees: (prev.otherUnitFees += +otherUnitFeesObj.raw),
        balance: (prev.balance += +balanceObj.raw),
      };
    },
    {
      sqFeet: 0,
      marketRent: 0,
      grossRentLimit: 0,
      subsidyRent: 0,
      residentChargedRent: 0,
      priorLeasedRent: 0,
      currentLeasedRent: 0,
      otherUnitFees: 0,
      balance: 0,
    },
  );
};

export const buildManageRentRoll = (
  rows: Array<Object>,
  activities: Object,
  intl: Object,
  marketRents: Array<Object>,
): any => {
  if (!rows) return [];
  const counts = buildRentRollCounts(rows, intl, marketRents);
  const today = moment().format('YYYY-MM-DD');
  const arr = rows.map((row) => {
    return {
      id: row.id,
      searchable: searchable(row),
      rows: {
        unit: {
          value: row.number,
          raw: `"="${row.number}`,
          useValueForPDF: true,
        },
        floorPlan: floorPlan(row),
        bedsBaths: { ...bedsBaths(row), useValueForPDF: true },
        sqFeet: squareFeet(row),
        status: status(row),
        resident: resident(row),
        moveInDate: moveInDate(row),
        leaseStartDate: leaseStartDate(row),
        leaseEndDate: leaseEndDate(row),
        collectionsNotes: collectionsNotes(row, activities),
        underEviction: underEviction(row),
        marketRent: { ...marketRent(row, intl), isCurrency: true },
        grossRentLimit: {
          ...grossRentLimit(row, intl, marketRents),
          isCurrency: true,
        },
        priorLeasedRent: { ...priorLeasedRent(row, intl), isCurrency: true },
        currentLeasedRent: {
          ...currentLeasedRent(row, intl),
          isCurrency: true,
        },
        subsidyRent: {
          ...subsidyOrChargedRent(row, intl, 'SUBRENT'),
          isCurrency: true,
        },
        residentChargedRent: {
          ...subsidyOrChargedRent(row, intl, 'RENT'),
          isCurrency: true,
        },
        otherUnitFees: { ...otherUnitFees(row, intl), isCurrency: true },
        balance: { ...balance(row, intl), isCurrency: true },
        moveOutDate: moveOutDate(row),
        moveInReadyDate: moveInReadyDate(row, today),
        daysVacant: daysVacant(row, today),
        quotingRent: { ...quotingRent(row, intl), isCurrency: true },
        applicant: applicant(row),
        applicantStatus: applicantStatus(row),
        scheduledMoveInDate: scheduledMoveInDate(row),
        leased: leased(row),
        vacancyLoss: { ...vacancyLoss(row, intl), isCurrency: true },
      },
    };
  });

  return {
    arr,
    counts,
  };
};

export const buildUnitAvailability = (
  rows: Array<Object>,
  intl: Object,
  marketRents: Array<Object>,
): any => {
  if (!rows) return [];
  const pathArray: Array<any> = [
    'nextAssignation',
    'application',
    'applicants',
    '0',
    'id',
  ];
  const counts = buildAvailabilityCounts(rows, intl, marketRents);
  const today = moment().format('YYYY-MM-DD');
  const arr = rows.map((row) => ({
    id: row.id,
    searchable: searchable(row),
    assignedUnitId: pathOr(null, ['nextAssignation', 'id'], row),
    applicationId: pathOr(null, ['nextAssignation', 'applicationId'], row),
    applicantId: pathOr(null, pathArray, row),
    unitFees: row?.unitFees ?? [],
    rows: {
      unit: { value: row.number, raw: `"="${row.number}` },
      floorPlan: floorPlan(row),
      bedsBaths: bedsBaths(row),
      sqFeet: squareFeet(row),
      status: status(row),
      leased: leased(row),
      resident: resident(row),
      marketRent: {
        value: intl.formatNumber(row.rentCalculations.marketRent, {
          style: 'currency',
          currency: 'USD',
        }),
        raw: row.rentCalculations.marketRent,
      },
      priorLeasedRent: priorLeasedRent(row, intl),
      currentLeasedRent: currentLeasedRent(row, intl),
      moveOutDate: moveOutDate(row),
      moveInReady: moveInReadyDate(row, today),
      notes: notes(row),
      daysVacant: daysVacant(row, today),
      quotingRent: quotingRent(row, intl),
      otherUnitFeesAvailable: otherUnitFeesAvailable(row, intl),
      applicant: applicant(row),
      applicantStatus: applicantStatus(row),
      scheduledMoveInDate: scheduledMoveInDate(row),
    },
  }));

  return {
    arr,
    counts,
  };
};
function allHouseholdMembersNames(currentAssignation) {
  const householdMembers = pathOr(
    [],
    ['application', 'applicants'],
    currentAssignation,
  );
  const allMembersNames = householdMembers.map(propOr('', 'name')).join(' ');
  return allMembersNames;
}

export const getUnitAddress = (unit: Object) => {
  if (!unit) return '---';

  const streetAddress1 = propOr('', 'streetAddress1', unit);
  const city = propOr('', 'city', unit);
  const state = propOr('', 'state', unit);
  const zipCode = propOr('', 'zipCode', unit);

  return `${streetAddress1} ${city}, ${state} ${zipCode}`;
};
