import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Field } from 'redux-form';
import ByDateControls from './ByDateControls';
import { renderRadioOption } from '../../utils/redux-form-helper';

type Props = {
  store: Object,
};

export const DATE_CONTROL_OPTIONS = {
  CREATION_DATE: { text: 'Creation Date Range', value: '0' },
  COMPLETED_DATE: { text: 'Completed Date Range', value: '1' },
};

function WODCompletedForm({ store }: Props) {
  return (
    <Stack>
      <Stack direction="row">
        <Field
          store={store}
          name="wodCompletedTypeRange"
          fieldName="wodCompletedTypeRange"
          option={DATE_CONTROL_OPTIONS.CREATION_DATE}
          component={renderRadioOption}
          bsSize="lg"
          inline
        />
        <Field
          store={store}
          name="wodCompletedTypeRange"
          fieldName="wodCompletedTypeRange"
          option={DATE_CONTROL_OPTIONS.COMPLETED_DATE}
          component={renderRadioOption}
          bsSize="lg"
          inline
        />
      </Stack>
      <ByDateControls store={store} />
    </Stack>
  );
}

export default WODCompletedForm;
