const { request, apiURL, requestToDownload } = require('../utils/api');

class BankDepositService {
  getBankDeposit(
    organizationId: string,
    propertyId: string,
    depositId: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/deposit/${depositId}`,
      options,
    );
  }

  getDepositAttachments(
    organizationId: string,
    propertyId: string,
    depositId: string,
    signal?: Object,
  ) {
    const options: Object = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    if (signal) {
      options.signal = signal;
    }

    return request(
      `${apiURL}/${organizationId}/${propertyId}/deposit/${depositId}/attachments`,
      options,
    );
  }

  updateDepositAttachment(
    organizationId: string,
    propertyId: string,
    depositId: string,
    attachmentId: string,
    payload: any,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/deposit/${depositId}/attachments/${attachmentId}`,
      options,
    );
  }

  deleteDepositAttachment(
    organizationId: string,
    propertyId: string,
    depositId: string,
    attachmentId: string,
  ) {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/deposit/${depositId}/attachments/${attachmentId}`,
      options,
    );
  }

  downloadDepositAttachment(
    organizationId: string,
    propertyId: string,
    depositId: string,
    attachmentId: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };

    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/deposit/${depositId}/attachments/${attachmentId}`,
      options,
    );
  }

  uploadDepositAttachment(
    organizationId: string,
    propertyId: string,
    depositId: string,
    attachment: Object,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
      body: attachment,
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/deposit/${depositId}/attachments`,
      options,
    );
  }
}

export default BankDepositService;
