import { find, propEq } from 'ramda';
export const LEASE_END_DATE_SETTINGS_OPTIONS = [
  {
    text: 'Calculated Date',
    value: 'CALCULATED',
  },
  {
    text: 'Last Day of Month',
    value: 'LAST_OF_MONTH',
  },
  {
    text: 'Last Day of Prior Month',
    value: 'LAST_OF_PRIOR_MONTH',
  },
];

export const getOptionName = (value: string) => {
  const foundItem = find(
    propEq('value', value),
    LEASE_END_DATE_SETTINGS_OPTIONS,
  );
  return foundItem?.text || 'Choose';
};
