import { put, select, takeLatest } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';
import FileSaver from 'file-saver';

import messages from './messages';
import {
  renderNoDataToastr,
  renderTranslatedMessage,
} from '../../utils/redux-form-helper';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import { push } from 'react-router-redux';
import CommunicationService from '../../services/communicationService';
import {
  downloadCommunicationFail,
  downloadCommunicationSuccess,
  generateCommunicationBatchError,
  generateCommunicationBatchSuccess,
  getAllApplicantsSuccess,
  getAllProspectsSuccess,
  getAllResidentsSuccess,
  getDocumentTypesSuccess,
  getValidateEmailError,
  getValidateEmailSuccess,
  getValidateRecipientsError,
  getValidateRecipientsSuccess,
} from './actions';
import {
  DOWNLOAD_COMMUNICATION,
  GENERATE_COMMUNICATION_BATCH,
  GET_ALL_APPLICANTS,
  GET_ALL_PROSPECTS,
  GET_ALL_RESIDENTS,
  GET_DOCUMENT_TYPES,
  GET_VALIDATE_EMAIL,
  GET_VALIDATE_RECPIENTS,
  SHOW_NO_DATA_TOASTR,
} from './constants';
import DocumentTypeService from '../../services/documentTypeService';
import { getAllProspectsError } from '../ManageProspects/actions';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

export function* fetchGetAllResidentsSaga(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const communicationService = new CommunicationService();
    const response = yield communicationService.getResidents(
      organizationId,
      property.id,
    );
    yield put(getAllResidentsSuccess(response.results));
  } catch (error) {
    yield put(getAllProspectsError(error));
  }
}

export function* fetchGetAllProspectsSaga({ payload }: any): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const communicationService = new CommunicationService();
    const response = yield communicationService.getProspects(
      organizationId,
      property.id,
      payload.prospectStatusList,
    );
    yield put(getAllProspectsSuccess(response.results));
  } catch (error) {}
}

export function* fetchGetAllApplicantsSaga(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const applicationService = new CommunicationService();
    const response = yield applicationService.getApplicants(
      organizationId,
      property.id,
    );
    yield put(getAllApplicantsSuccess(response));
  } catch (error) {}
}

export function* fetchGetDocumentTypes(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const documentTypeService = new DocumentTypeService();
    const response = yield documentTypeService.getResidentLetterDocumentTypes(
      organizationId,
      selectedProperty.id,
      null,
      false,
    );
    yield put(getDocumentTypesSuccess(response));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGenerateCommunicationBatch(action: Object): Saga<void> {
  const { fileName, ...payload } = action.payload;
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const communicationService = new CommunicationService();
    let response = yield communicationService.generateCommunicationBatch({
      organizationId,
      propertyId: selectedProperty.id,
      payload,
    });
    yield put(
      toastrActions.add({
        type: 'success',
        message: 'Successfully generated communication',
        title: 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(push(getUrlWithSelectedPropertyId('/communications')));
    yield put(generateCommunicationBatchSuccess(response));
  } catch (err) {
    yield put(generateCommunicationBatchError(err));
  }
}

export function* fetchDownloadCommunicationSaga({ payload }: any): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const communicationService = new CommunicationService();
    const response = yield communicationService.downloadCommunication(
      organizationId,
      selectedProperty.id,
      payload.communicationId,
    );
    FileSaver.saveAs(response, `${payload.documentName}.pdf`);
    yield put(downloadCommunicationSuccess());
  } catch (err) {
    yield put(downloadCommunicationFail(err));
  }
}

export function* fetchgetValidateRecipients(action: Object): Saga<void> {
  const payload = action.payload;
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    const communicationService = new CommunicationService();
    let response = yield communicationService.getValidateRecipients({
      payload: {
        propertyId: selectedProperty.id,
        ...payload,
      },
    });
    yield put(
      getValidateRecipientsSuccess({
        response,
        communicationType: payload.communicationType,
      }),
    );
  } catch (err) {
    yield put(getValidateRecipientsError(err));
  }
}

export function* fetchGetValidateEmail(action: Object): Saga<void> {
  const payload = action.payload;
  try {
    const communicationService = new CommunicationService();
    let response = yield communicationService.getValidateEmail({
      payload,
    });
    yield put(getValidateEmailSuccess(response));
  } catch (err) {
    yield put(getValidateEmailError(err));
  }
}

export function* showNoDataToastr(): Saga<void> {
  yield put(renderNoDataToastr());
}

export function* getAllResidentsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_RESIDENTS, fetchGetAllResidentsSaga);
}

export function* getAllProspectsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_PROSPECTS, fetchGetAllProspectsSaga);
}

export function* getAllApplicantsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_APPLICANTS, fetchGetAllApplicantsSaga);
}

export function* getDocumentTypesSaga(): Saga<void> {
  yield takeLatest(GET_DOCUMENT_TYPES, fetchGetDocumentTypes);
}

export function* generateCommunicationBatch(): Saga<void> {
  yield takeLatest(
    GENERATE_COMMUNICATION_BATCH,
    fetchGenerateCommunicationBatch,
  );
}

export function* downloadCommunicationSaga(): Saga<void> {
  yield takeLatest(DOWNLOAD_COMMUNICATION, fetchDownloadCommunicationSaga);
}

export function* getValidateRecipients(): Saga<void> {
  yield takeLatest(GET_VALIDATE_RECPIENTS, fetchgetValidateRecipients);
}

export function* getValidateEmail(): Saga<void> {
  yield takeLatest(GET_VALIDATE_EMAIL, fetchGetValidateEmail);
}

export function* showNoDataToastrSaga(): Saga<void> {
  yield takeLatest(SHOW_NO_DATA_TOASTR, showNoDataToastr);
}

export default [
  getAllResidentsSaga,
  getDocumentTypesSaga,
  generateCommunicationBatch,
  downloadCommunicationSaga,
  getValidateRecipients,
  getValidateEmail,
  getAllProspectsSaga,
  getAllApplicantsSaga,
  showNoDataToastrSaga,
];
