import React from 'react';
import { Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export const ApplicationChecklistCompletionStatus = ({
  isTransUnionActive,
  applicationChecklistUpdate,
}) => {
  return (
    <div className="container-fluid">
      <Col xs={12}>
        <h2 className="appmanagertitle">
          <FormattedMessage {...messages.applicationChecklist} />
          {(!applicationChecklistUpdate || isTransUnionActive) && (
            <small>
              <FormattedMessage {...messages.cannotScreenUpdated} />
            </small>
          )}
        </h2>
      </Col>
    </div>
  );
};
