import React from 'react';
import styled from 'styled-components';

const NormalText = styled.span`
  font-weight: normal;
  display: inline !important;
`;

type IntacctJEStatus = {
  id: string,
  referenceNumber: string,
  status: 'Pending' | 'Sent' | 'Error',
};

type Props = {
  status: IntacctJEStatus,
};

const IntacctStatusSection = ({ status }: Props) => {
  const intacctStatus = status?.status ?? 'Pending';
  switch (intacctStatus) {
    case 'Sent':
      return (
        <NormalText>
          Sent <i className="icon et-circle-check text-green" />
        </NormalText>
      );
    case 'Error':
      return (
        <NormalText>
          Error <i className="icon et-alert-urgent text-red" />
        </NormalText>
      );
    default:
      return (
        <NormalText>
          Pending <i className="icon et-clock text-orange" />
        </NormalText>
      );
  }
};

export default IntacctStatusSection;
