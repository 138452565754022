import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isNil, pathOr } from 'ramda';
import { Link } from 'react-router-dom';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import ElementWithPermissions from '../../../components/ElementWithPermissions';

import FloorPlanImage from './FloorPlanImage';
import { useFloorPlanImage } from './hooks';
import messages from './messages';
import EditFloorPlanModal from '../EditFloorPlanModal';
import { PencilIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

const floorplanHeader = (unitNumber: string, unitFloorplan: string) => {
  return unitNumber !== '' ? (
    <h1>
      <FormattedMessage {...messages.unit} />
      {` #${unitNumber}`}
    </h1>
  ) : (
    <h1>
      <FormattedMessage {...messages.floorplan} />
      {` #${unitFloorplan}`}
    </h1>
  );
};

const AffordableAverageIncomeDetails = ({
  incomeAveragingPercent,
  actualIncomeAvg,
}: Object) => {
  const actualIncomeAvgClass =
    +actualIncomeAvg <= +(incomeAveragingPercent || 100)
      ? 'text-success'
      : 'text-danger';
  const actualIncomeAvgText = isNil(actualIncomeAvg)
    ? '---'
    : `${actualIncomeAvg}%`;
  return (
    <div className="average-income-details">
      <FormattedMessage {...messages.currentIa} />
      <span className={actualIncomeAvgClass}>{actualIncomeAvgText}</span>
    </div>
  );
};

const unitFloorplanSubHeader = (
  incomeAveragingPercent: number | null,
  actualIncomeAvg: number | null,
  unitFloorplan: string,
  unitFloorplanId: string,
  showFloorplansForm: Function,
  isCommercialFloorplan: boolean,
  isAffordableMixedProperty: boolean,
) => {
  return (
    <div className="row">
      <div className="col-xs-12">
        <h5>
          <strong>
            <FormattedMessage {...messages.type} />
          </strong>{' '}
          <Link
            to={getUrlWithSelectedPropertyId(`/floorplan/${unitFloorplanId}`)}
          >
            {unitFloorplan}
          </Link>{' '}
          <ElementWithPermissions scope={['floorplan-pricing-edit']}>
            <a className="simple-link" onClick={showFloorplansForm}>
              <i className="et-pencil" />
            </a>
          </ElementWithPermissions>
          {!isCommercialFloorplan && isAffordableMixedProperty && (
            <AffordableAverageIncomeDetails
              incomeAveragingPercent={incomeAveragingPercent}
              actualIncomeAvg={actualIncomeAvg}
            />
          )}
        </h5>
      </div>
    </div>
  );
};

const floorplanSubHeader = (
  sqft: string,
  numberOfUnits: string,
  marketingDesc: string,
) => {
  return (
    <div>
      <div className="row">
        <div className="col-xs-12">
          <h5 className="pull-left padright10">
            <strong>
              <FormattedMessage {...messages.sqft} />:
            </strong>{' '}
            {sqft} |
          </h5>
          <h5 className="pull-left">
            <strong>
              <FormattedMessage {...messages.numberOfUnits} />:
            </strong>{' '}
            {numberOfUnits}
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-5">
          <strong>
            <FormattedMessage {...messages.marketingDescription} />
          </strong>
        </div>
        <div className="col-xs-6">{marketingDesc}</div>
      </div>
    </div>
  );
};

const floorPlanImageRowStyle = {
  padding: '2% 8% 0px 8%',
};

const FloorplanDetails = ({
  incomeAveragingPercent,
  actualIncomeAvg,
  unitNumber,
  floorplan,
  showModal,
  isAffordableMixedProperty,
  selectedProperty,
  toasterFn,
  onFloorPlanImageChange,
  flags,
  loadFloorPlan,
  refetchUnit,
}: Object) => {
  const selectedPropertyId = pathOr('', ['id'], selectedProperty);
  const organizationId = pathOr('', ['organizationId'], selectedProperty);
  const {
    floorPlanImage,
    handleImageUpload,
    handleDeleteImage,
    handleDownloadImage,
    isLoaderShown,
  } = useFloorPlanImage(
    floorplan,
    selectedPropertyId,
    organizationId,
    toasterFn,
    onFloorPlanImageChange,
  );
  const unitName = pathOr('---', ['marketingName'], floorplan);
  const unitFloorplanId = pathOr('', ['id'], floorplan);
  const unitFloorplan = pathOr('---', ['internalName'], floorplan);
  const unitBedrooms = pathOr('---', ['nBeds'], floorplan);
  const unitBathrooms = pathOr('---', ['nBaths'], floorplan);
  const unitHalfBathrooms = pathOr('---', ['nHalfBaths'], floorplan);
  const unitMaxOccupants = pathOr('---', ['maxOccupants'], floorplan);
  const sqft = pathOr('---', ['grossSquareFeet'], floorplan);
  const numberOfUnits = pathOr('---', ['numberOfUnits'], floorplan);
  const marketingDesc = pathOr('---', ['marketingDescription'], floorplan);
  const unitMinOccupants = pathOr('---', ['minOccupants'], floorplan);
  const isCommercialFloorplan = pathOr(false, ['isCommercial'], floorplan);
  const [showEditModal, setShowEditModal] = useState(false);

  const onOpenEditFloorPlanModal = () => {
    setShowEditModal(true);
  };

  const onCloseEditFloorPlanModal = () => {
    setShowEditModal(false);
    loadFloorPlan(unitFloorplanId);
    refetchUnit();
  };

  return (
    <div className="panel unit-overview panelwlargeheader">
      <div className="unit-head">
        <div>
          {floorplanHeader(unitNumber, unitFloorplan)}
          <h4>{`"${unitName}"`}</h4>
          <ElementWithPermissions scope={['floor-plan-update']} hidden>
            <div className="float-right">
              <Button variant="text" onClick={onOpenEditFloorPlanModal}>
                <PencilIcon />
                <FormattedMessage {...messages.edit} />
              </Button>
            </div>
          </ElementWithPermissions>
        </div>
        {unitNumber !== ''
          ? unitFloorplanSubHeader(
              incomeAveragingPercent,
              actualIncomeAvg,
              unitFloorplan,
              unitFloorplanId,
              showModal,
              isCommercialFloorplan,
              isAffordableMixedProperty,
            )
          : floorplanSubHeader(sqft, numberOfUnits, marketingDesc)}
      </div>
      <div className="divider-centered--larger"> </div>
      <div className="row">
        <div className="unit-properties panel-box__details">
          <div className="unit-prop col-xs-3 col-md-3">
            <div className="unit-prop-value">
              <h1>{unitBedrooms}</h1>
              <i className="icon et-bed" />
            </div>
            <div className="unit-prop-att">
              <FormattedMessage {...messages.bedrooms} />
            </div>
          </div>
          <div className="unit-p-icon col-xs-3 col-md-3">
            <div className="unit-prop-value">
              <h1>{unitBathrooms}</h1>
              <i className="icon et-tub" />
            </div>
            <div className="unit-prop-att">
              <FormattedMessage {...messages.bathrooms} />
            </div>
          </div>
          <div className="unit-p-icon col-xs-3 col-md-3">
            <div className="unit-prop-value">
              <h1>{unitHalfBathrooms}</h1>
              <i className="icon et-toilet" />
            </div>
            <div className="unit-prop-att">
              <FormattedMessage {...messages.halfBaths} />
            </div>
          </div>
          <div className="unit-p-icon col-xs-3 col-md-3">
            <div className="unit-prop-value">
              <h1>{unitMinOccupants}</h1>/ <h1>{unitMaxOccupants}</h1>
              <i className="icon et-people" />
            </div>
            <div className="unit-prop-att">
              <FormattedMessage {...messages.minMaxOccupants} />
            </div>
          </div>
        </div>
      </div>
      {flags.floorPlanImageUpload && (
        <div className="row" style={floorPlanImageRowStyle}>
          <div className="col-xs-12">
            <FloorPlanImage
              image={floorPlanImage}
              loading={isLoaderShown}
              onUploadImage={handleImageUpload}
              onDeleteImage={handleDeleteImage}
              onDownloadImage={handleDownloadImage}
            />
          </div>
        </div>
      )}
      {showEditModal && floorplan && (
        <EditFloorPlanModal
          show={showEditModal}
          floorPlan={floorplan}
          onCloseModal={onCloseEditFloorPlanModal}
        />
      )}
    </div>
  );
};

export default withLDConsumer()(FloorplanDetails);
