import { defineMessages } from 'react-intl';

const messages = defineMessages({
  streetLabel: {
    id: 'App.ResidentProfile.NoticeToVacateModal.StreetLabel',
    defaultMessage: 'Street Address',
  },
  deleteConfirmationHeader: {
    id: 'App.ResidentProfile.NoticeToVacateModal.DeleteConfirmationHeader',
    defaultMessage: 'Are you sure you want to remove the Notice to Vacate?',
  },
  cancelConfirmationHeader: {
    id: 'App.ResidentProfile.NoticeToVacateModal.CancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to close without saving?',
  },
  deleteConfirmationText: {
    id: 'App.ResidentProfile.NoticeToVacateModal.DeleteConfirmationText',
    defaultMessage:
      ' This action cannot be undone! To return to the edit view, click the {noText} option below.',
  },
  yes: {
    id: 'App.ResidentProfile.NoticeToVacateModal.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.ResidentProfile.NoticeToVacateModal.No',
    defaultMessage: 'No',
  },
  required: {
    id: 'App.ResidentProfile.NoticeToVacateModal.Required',
    defaultMessage: 'Required value',
  },
  invalidDate: {
    id: 'App.ResidentProfile.NoticeToVacateModal.InvalidDate',
    defaultMessage: 'Invalid date value',
  },
  dateInFuture: {
    id: 'App.ResidentProfile.NoticeToVacateModal.DateInFuture',
    defaultMessage: 'Date cannot be in the future.',
  },
  dateInPast: {
    id: 'App.ResidentProfile.NoticeToVacateModal.DateInPast',
    defaultMessage: 'Date cannot be in the past.',
  },
  dateMustBeGreater: {
    id: 'App.ResidentProfile.NoticeToVacateModal.DateMustBeGreater',
    defaultMessage:
      'Invalid date value, must be greater than 30 days in the past.',
  },
  dateMustBeOnOrAfterLeaseStartDate: {
    id: 'App.ResidentProfile.NoticeToVacateModal.DateMustBeOnOrAfterLeaseStartDate',
    defaultMessage: 'Move Out Date cannot be prior to Lease Start Date',
  },
  dateMustBeOnOrAfterCommencementDate: {
    id: 'App.ResidentProfile.NoticeToVacateModal.DateMustBeOnOrAfterCommencementDate',
    defaultMessage: 'Move Out Date cannot be prior to Commencement Date',
  },
  dateMustBeBeforeNextAssignation: {
    id: 'App.ResidentProfile.NoticeToVacateModal.DateMustBeBeforeNextAssignation',
    defaultMessage:
      'Invalid date value, must be before move in date of applicant assigned to this unit.',
  },
  title: {
    id: 'App.ResidentProfile.NoticeToVacateModal.Title',
    defaultMessage: 'Notice to Vacate',
  },
  allRequired: {
    id: 'App.ResidentProfile.NoticeToVacateModal.AllRequired',
    defaultMessage: '* All fields are required',
  },
  someRequired: {
    id: 'App.ResidentProfile.NoticeToVacateModal.SomeRequired',
    defaultMessage: 'Fields with * are required',
  },
  unitNumberLabel: {
    id: 'App.ResidentProfile.NoticeToVacateModal.UnitNumberLabel',
    defaultMessage: 'Unit Number:',
  },
  residentLabel: {
    id: 'App.ResidentProfile.NoticeToVacateModal.ResidentLabel',
    defaultMessage: 'Resident:',
  },
  noticeDateLabel: {
    id: 'App.ResidentProfile.NoticeToVacateModal.NoticeDateLabel',
    defaultMessage: 'Notice Date',
  },
  moveOutDateLabel: {
    id: 'App.ResidentProfile.NoticeToVacateModal.MoveOutDateLabel',
    defaultMessage: 'Move-Out Date',
  },
  moveInReadyLabel: {
    id: 'App.ResidentProfile.NoticeToVacateModal.MoveInReadyLabel',
    defaultMessage: 'Expected Move-In Ready Date',
  },
  moveOutReasonLabel: {
    id: 'App.ResidentProfile.NoticeToVacateModal.MoveOutReasonLabel',
    defaultMessage: 'Reason for Move-Out',
  },
  noteLabel: {
    id: 'App.ResidentProfile.NoticeToVacateModal.NoteLabel',
    defaultMessage: 'Note',
  },
  notePlaceholder: {
    id: 'App.ResidentProfile.NoticeToVacateModal.NotePlaceholder',
    defaultMessage: 'Note',
  },
  forwardingAddressTitle: {
    id: 'App.ResidentProfile.NoticeToVacateModal.ForwardingAddressTitle',
    defaultMessage: 'Forwarding Address:',
  },
  forwardingAddressSubtitle: {
    id: 'App.ResidentProfile.NoticeToVacateModal.ForwardingAddressSubtitle',
    defaultMessage: 'Please add/update forwarding address:',
  },
  cancel: {
    id: 'App.ResidentProfile.NoticeToVacateModal.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.ResidentProfile.NoticeToVacateModal.Save',
    defaultMessage: 'Save',
  },
  delete: {
    id: 'App.ResidentProfile.NoticeToVacateModal.Delete',
    defaultMessage: 'Remove NTV',
  },
  unitAssignedWarning: {
    id: 'App.ResidentProfile.NoticeToVacateModal.UnitAssignedWarning',
    defaultMessage:
      'Unit is already assigned to an application. Applicant scheduled move in date is {date}.' +
      ' You can not remove this notice to vacate or update the move out date to be after {date}.',
  },
  unitAssignedNoLeaseWarning: {
    id: 'App.ResidentProfile.NoticeToVacateModal.UnitAssignedNoLeaseWarning',
    defaultMessage:
      'Unit is already assigned to an application. Applicant has not yet scheduled a move in date.' +
      ' You can not remove this notice to vacate.',
  },
  currentResidentUnderTransfer: {
    id: 'App.ResidentProfile.NoticeToVacateModal.CurrentResidentUnderTransfer',
    defaultMessage:
      'Notice cannot be removed if the Resident is transferring. Cancel the Transfer Lease before removing the Notice.',
  },
  currentResidentInRenewal: {
    id: 'App.ResidentProfile.NoticeToVacateModal.CurrentResidentInRenewal',
    defaultMessage: 'There is an open renewal for this household.',
  },
  beforeFiscalPeriod: {
    id: 'App.ResidentProfile.NoticeToVacateModal.beforeFiscalPeriod',
    defaultMessage:
      'You cannot select a move-out date that is in a prior/closed fiscal period.',
  },
  headsUp: {
    id: 'App.ResidentProfile.NoticeToVacateModal.headsUp',
    defaultMessage: 'Heads Up!',
  },
  confirmationMessage: {
    id: 'App.ResidentProfile.NoticeToVacateModal.confirmationMessage',
    defaultMessage: `This household has an open renewal. Entering a notice to vacate will not impact the lease renewal.
    Are you sure you want to add a notice to vacate?`,
  },
  housingSituationLabel: {
    id: 'App.ResidentProfile.NoticeToVacateModal.HousingSituationLabel',
    defaultMessage: 'New Housing Situation',
  },
  estateOfWarning: {
    id: 'App.ResidentProfile.NoticeToVacateModal.EstateOfWarning',
    defaultMessage:
      'Caution: As the Move-Out reason is "Personal - Death," and the resident is the last ' +
      'leaseholder, the phrase "Estate of" will be automatically appended to the resident\'s name.',
  },
  estateOfCharacterLimit: {
    id: 'App.ResidentProfile.NoticeToVacateModal.EstateOfCharacterLimit',
    defaultMessage:
      '"Estate of" was not added due to a character limit in the first name.',
  },
});

export default messages;
