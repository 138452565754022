// $FlowFixMe
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { pathOr, pick, isEmpty } from 'ramda';
import moment from 'moment';

import { getHUDContractRent } from '../../../utils/affordable';

import messages from './messages';
import EditUnitGeneralInformationFormModal from './EditUnitModal';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import UnitSqFtInformation from '../UnitSqFtInformation';
import useIsAffordableProperty from '../../../hooks/useIsAffordableProperty';

const GeneralUnitInformation = ({
  unit,
  intl,
  selectedProperty,
  refreshUnit,
  isHUDFloorPlan,
  getUnitById,
  flags,
}: Object) => {
  const isAffordableProperty = useIsAffordableProperty(selectedProperty);
  const [modal, setModal] = useState(false);
  const [unitToEdit, setUnitToEdit] = useState({});
  const resetModal = () => setModal(false);
  const noUnitLoaded = Object.keys(unit ?? {}).length === 0;

  const handleEditUnit = () => {
    const unitToEditInitialValues = pick(
      [
        'id',
        'unitFloorLevel',
        'streetAddress1',
        'streetAddress2',
        'state',
        'city',
        'zipCode',
        'isADA',
        'xmlUnitNumber',
      ],
      unit,
    );

    const isADA = pathOr(false, ['isADA'], unit).toString();

    setUnitToEdit({
      ...unitToEditInitialValues,
      isADA, // Handle null/undefined values
    });
    setModal(true);
  };

  const unitIsManagementOwned = pathOr(
    false,
    ['unitStatus', 'isManagementOwned'],
    unit,
  );
  const editUnitScopes = unitIsManagementOwned
    ? ['manage-units-update-property-office-or-model']
    : ['manage-units-update'];

  const unitMarketingDescription = pathOr(
    '---',
    ['floorPlan', 'marketingDescription'],
    unit,
  );
  const unitGrossSquareFeet = pathOr(
    null,
    ['floorPlan', 'grossSquareFeet'],
    unit,
  );
  const unitGrossSquareFeetFormatted =
    unitGrossSquareFeet !== null
      ? `${unitGrossSquareFeet} ${intl.formatMessage(messages.sqFt)}`
      : '---';

  const effectiveDate = pathOr(null, ['lease', 'startDate'], unit);
  const unitBaseMarketRentAmount = !isHUDFloorPlan
    ? pathOr(null, ['floorPlan', 'baseMarketRentAmount'], unit)
    : getHUDContractRent(unit.floorPlan, moment(effectiveDate));

  const unitQuotingRentAmount = pathOr(
    null,
    ['floorPlan', 'quotingRentAmount'],
    unit,
  );

  const unitEstimatedDepositAmount = pathOr(
    null,
    ['floorPlan', 'estimatedDepositAmount'],
    unit,
  );
  const unitFloorLevel = pathOr('---', ['unitFloorLevel'], unit);

  const unitAddress = {
    address1: unit.streetAddress1,
    address2: pathOr(null, ['streetAddress2'], unit),
    state: unit.state,
    city: unit.city,
    zipCode: unit.zipCode,
  };

  const formatCurrency = (value: number | null) =>
    value !== null
      ? intl.formatNumber(+value, {
          style: 'currency',
          currency: 'USD',
        })
      : '---';

  const isADAUnit = () => {
    if (!unit || isEmpty(unit)) return '---';
    return unit.isADA === true ? 'Yes' : 'No';
  };

  const sqFt = {
    grossSqFt: pathOr('---', ['floorPlan', 'grossSquareFeet'], unit),
    rentableSqFt: pathOr('---', ['unitRentableSquareFeet'], unit),
    usableSqFt: pathOr('---', ['unitUsableSquareFeet'], unit),
  };

  const buildingName = pathOr('---', ['building', 'name'], unit);

  const xmlUnitNumber = unit?.xmlUnitNumber ?? '---';

  const { removeUnitFloorLevelRestrictions } = flags;

  return (
    <div className="panel unit-information panel-withheader">
      <EditUnitGeneralInformationFormModal
        unitToEdit={unitToEdit}
        onClose={resetModal}
        show={modal === true}
        intl={intl}
        selectedProperty={selectedProperty}
        refreshUnit={refreshUnit}
        flags={flags}
      />
      <div className="panel-head row">
        <div className="col-xs-3 col-md-4">
          <h2>
            <FormattedMessage {...messages.title} />
          </h2>
        </div>
        <div className="col-xs-9 col-md-8 text-right">
          <ElementWithPermissions scope={editUnitScopes}>
            {noUnitLoaded ? (
              <a className="simple-link" disabled>
                <i className="et-pencil" />
                <FormattedMessage {...messages.unitEditButton} />
              </a>
            ) : (
              <a className="simple-link" onClick={handleEditUnit}>
                <i className="et-pencil" />
                <FormattedMessage {...messages.unitEditButton} />
              </a>
            )}
          </ElementWithPermissions>
        </div>
      </div>
      <div className="container-fluid">
        <div className="panel__details">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <strong>
                    <FormattedMessage {...messages.marketingDescription} />
                  </strong>
                </div>
                <div className="col-xs-12 col-md-6">
                  {unitMarketingDescription}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <strong>
                    <FormattedMessage {...messages.grossSqFt} />
                  </strong>
                </div>
                <div className="col-xs-12 col-md-6">
                  {unitGrossSquareFeetFormatted}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <strong>
                    <FormattedMessage
                      {...(removeUnitFloorLevelRestrictions
                        ? messages.unitFloorLevel
                        : messages.unitLevel)}
                    />
                  </strong>
                </div>
                <div className="col-xs-12 col-md-6">{unitFloorLevel}</div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <strong>
                    <FormattedMessage {...messages.unitAddress} />
                  </strong>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div>{unitAddress.address1}</div>
                  <div>{unitAddress.address2}</div>
                  <div>
                    {unitAddress.city}
                    {', '} {unitAddress.state} {unitAddress.zipCode}
                  </div>
                </div>
              </div>
              {isAffordableProperty && (
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <strong>
                      <FormattedMessage {...messages.xmlAltUnitNumber} />
                    </strong>
                  </div>
                  <div className="col-xs-12 col-md-6">{xmlUnitNumber}</div>
                </div>
              )}
            </div>
            <div className="col-xs-12 col-sm-6">
              <div className="row">
                <div className="col-xs-12 col-md-8">
                  <strong>
                    <FormattedMessage {...messages.baseMarketRentAmount} />
                  </strong>
                </div>
                <div className="col-xs-12 col-md-4">
                  {formatCurrency(unitBaseMarketRentAmount)}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-8">
                  <strong>
                    <FormattedMessage {...messages.quotingRentAmount} />
                  </strong>
                </div>
                <div className="col-xs-12 col-md-4">
                  {formatCurrency(unitQuotingRentAmount)}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-8">
                  <strong>
                    <FormattedMessage {...messages.estimatedDepositAmount} />
                  </strong>
                </div>
                <div className="col-xs-12 col-md-4">
                  {formatCurrency(unitEstimatedDepositAmount)}
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-8">
                  <strong>
                    <FormattedMessage {...messages.ADAUnit} />
                  </strong>
                </div>
                <div className="col-xs-12 col-md-4">{isADAUnit()}</div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-md-8">
                  <strong>
                    <FormattedMessage {...messages.buildingName} />
                  </strong>
                </div>
                <div className="col-xs-12 col-md-4">{buildingName}</div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <UnitSqFtInformation
            intl={intl}
            sqFt={sqFt}
            selectedProperty={selectedProperty}
            unitId={unit.id}
            refreshUnitCallback={getUnitById}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralUnitInformation;
