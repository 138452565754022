import { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import * as graphCommon from './graph-common';
import { PageContext } from '../context';
import messages from '../messages';

export type Props = {
  labels: string[],
  leasesData: number[],
  onColumnClick: Function,
  height?: number,
};

function Graph(props: Props) {
  const { formatMessage } = useContext(PageContext);

  return (
    <Bar
      height={props.height || graphCommon.DEFAULT_HEIGHT}
      data={{
        labels: props.labels,
        datasets: [
          graphCommon.getBarConfig(
            formatMessage(messages.leasesPlural),
            graphCommon.COLORS.LEASES,
            props.leasesData,
          ),
        ],
      }}
      options={{
        tooltips: {
          bodyFontStyle: 'bold',
          footerFontStyle: 'normal',
          callbacks: {
            title: () => '',
            label: graphCommon.labelConfig,
            footer: (items, data) => {
              const { value, xLabel } = items[0];

              if (!xLabel) return '';

              return graphCommon.padText(
                `${formatMessage(messages.leases)}: ${value}`,
                graphCommon.TOOLTIP_PADDING.BODY - 1,
              );
            },
          },
        },
        legend: {
          labels: {
            generateLabels: (c) => [
              graphCommon.getBarLegend(
                formatMessage(messages.leasesPlural),
                0,
                graphCommon.COLORS.LEASES,
              ),
            ],
            usePointStyle: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: false,
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
      }}
      onElementsClick={props.onColumnClick}
    />
  );
}

export default Graph;
