import { put, takeLatest, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';
import {
  renderTranslatedMessage,
  renderNoDataToastr,
} from '../../utils/redux-form-helper';
import messages from './messages';

import {
  GET_ALL_PROPERTY_PERIODS,
  GET_ALL_MISC_TRANSACTIONS,
  HANDLE_SOCKET_REVERSE_MISC_TRANSACTION_ERROR,
  HANDLE_SOCKET_REVERSE_MISC_TRANSACTION_SUCCESS,
  REVERSE_MISC_TRANSACTION,
  REVERSE_MISC_TRANSACTION_SUCCESS,
} from './constants';
import { CREATE_MISC_TRANSACTION_SUCCESS } from './CreateMiscTransaction/constants';
import {
  getAllPropertyPeriodsSuccess,
  getAllPropertyPeriodsError,
  getAllMiscTransactions,
  getAllMiscTransactionsSuccess,
  getAllMiscTransactionsError,
  reverseMiscTransactionSuccess,
} from './actions';
import {
  selectCurrentLocationPath,
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
} from '../App/selectors';
import MiscTransactionsService from '../../services/miscTransactionsService';
import type { Action, Property } from '../App/types';
import type { MiscTransactionRequest } from './types';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchAllPropertyPeriodsSaga(): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const miscTransactionsService = new MiscTransactionsService();
    const response = yield miscTransactionsService.getAllPeriods(
      organizationId,
      selectedProperty.id,
    );
    yield put(getAllPropertyPeriodsSuccess(response));
  } catch (err) {
    yield put(getAllPropertyPeriodsError(err));
  }
}

export function* getAllPropertyPeriodsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_PROPERTY_PERIODS, fetchAllPropertyPeriodsSaga);
}

export function* fetchAllMiscTransactions({
  payload,
}: Action<MiscTransactionRequest>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const miscTransactionsService = new MiscTransactionsService();
    const response = yield miscTransactionsService.getMiscTransactions(
      organizationId,
      selectedProperty.id,
      payload.pageNumber,
      payload.period,
    );
    if (response.results && response.results.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getAllMiscTransactionsSuccess(response));
  } catch (err) {
    yield put(getAllMiscTransactionsError(err));
  }
}

export function* getAllMiscTransactionsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_MISC_TRANSACTIONS, fetchAllMiscTransactions);
}

export function* fetchReverseMiscTransaction(action: Object): Saga<void> {
  try {
    const routePath = yield select(selectCurrentLocationPath);
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const { id: propertyId } = selectedProperty;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const miscTransactionService = new MiscTransactionsService();
    const notificationAttributes = {
      propertyId,
      routePath,
    };
    yield miscTransactionService.reverseMiscTransaction(
      organizationId,
      propertyId,
      action.payload.transactionId,
      notificationAttributes,
    );
    yield put(
      toastrActions.add({
        type: 'info',
        message: renderTranslatedMessage(messages.pendingDescriptionReversal),
        title: renderTranslatedMessage(messages.pendingHeaderReversal),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorDescriptionReversal),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* handleSocketReverseTransactionError(
  action: Object,
): Saga<void> {
  const { payload } = action;
  yield put(
    toastrActions.add({
      type: 'error',
      message: payload.error,
      title: renderTranslatedMessage(messages.errorDescriptionReversal),
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    }),
  );
}

export function* handleSocketReverseTransactionErrorSaga(): Saga<void> {
  yield takeLatest(
    HANDLE_SOCKET_REVERSE_MISC_TRANSACTION_ERROR,
    handleSocketReverseTransactionError,
  );
}

export function* handleSocketReverseTransactionSuccess(
  action: Object,
): Saga<void> {
  const {
    payload: { propertyId, routePath },
  } = action;
  const { id: currentPropertyId } = yield select(selectSelectedProperty);
  const currentRoutePath = yield select(selectCurrentLocationPath);
  yield put(
    toastrActions.add({
      type: 'success',
      message: renderTranslatedMessage(messages.successDescriptionReversal),
      title: renderTranslatedMessage(messages.successHeaderReversal),
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    }),
  );

  // Only update transactions if on the page where request was generated
  if (currentPropertyId === propertyId && currentRoutePath === routePath) {
    yield put(reverseMiscTransactionSuccess());
  }
}

export function* handleSocketReverseTransactionSuccessSaga(): Saga<void> {
  yield takeLatest(
    HANDLE_SOCKET_REVERSE_MISC_TRANSACTION_SUCCESS,
    handleSocketReverseTransactionSuccess,
  );
}

export function* reverseTransactionSaga(): Saga<void> {
  yield takeLatest(REVERSE_MISC_TRANSACTION, fetchReverseMiscTransaction);
}

export function* updateMiscTransactions(): Saga<void> {
  yield put(getAllMiscTransactions(1));
}

export function* watchReverseTransactionSuccess(): Saga<void> {
  yield takeLatest(REVERSE_MISC_TRANSACTION_SUCCESS, updateMiscTransactions);
}

export function* watchCreateMiscTransactionSuccess(): Saga<void> {
  yield takeLatest(CREATE_MISC_TRANSACTION_SUCCESS, updateMiscTransactions);
}

export default [
  getAllPropertyPeriodsSaga,
  getAllMiscTransactionsSaga,
  handleSocketReverseTransactionErrorSaga,
  handleSocketReverseTransactionSuccessSaga,
  reverseTransactionSaga,
  updateMiscTransactions,
  watchReverseTransactionSuccess,
  watchCreateMiscTransactionSuccess,
];
