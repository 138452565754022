import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import { renderDateField } from '../../../../utils/redux-form-helper';
import { reduxForm, Field } from 'redux-form';
import validate from './validate';

type Props = {
  onHide: Function,
  handleSubmit: Function,
  onSubmit: Function,
  valid: boolean,
  reset: Function,
  pristine: boolean,
  modalState: string,
};

const CamStartDateModal = ({
  onHide,
  handleSubmit,
  valid,
  reset,
  pristine,
}: Props) => {
  const onClose = () => {
    reset();
    onHide();
  };
  return (
    <form>
      <Modal
        className="activity-window"
        bsSize="small"
        backdrop
        show={true}
        onHide={onClose}
      >
        <Modal.Header closeButton>
          <i className="icon et-pencil"></i>
          <Modal.Title componentClass="h1">
            <FormattedMessage {...messages.modalTitle} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="scrollable-modal__content">
            <div className="container-fluid">
              <Row>
                <Col md={5} xs={12}>
                  <label>
                    <FormattedMessage {...messages.modalTitle} />
                  </label>
                </Col>
                <Col md={7} xs={12}>
                  <Field
                    name="camStartDate"
                    component={renderDateField}
                    bsSize="md"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <label>
                    <FormattedMessage {...messages.note} />
                  </label>
                </Col>
              </Row>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={6}>
              <Button
                bsStyle="default"
                className="pull-right"
                onClick={onClose}
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                bsStyle="primary"
                className="pull-left"
                type="submit"
                onClick={handleSubmit}
                disabled={pristine || !valid}
              >
                <FormattedMessage {...messages.save} />
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </form>
  );
};

export default reduxForm({
  form: 'camStartDateModalForm',
  enableReinitialize: true,
  validate,
})(CamStartDateModal);
