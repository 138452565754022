import { useMutation } from '@apollo/client';
import objectHash from 'object-hash';
import type { ConfigurePADFormValues } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress/ConfigurePADModal/ConfigurePADModal.types'; // eslint-disable-line max-len
import { find, isNil, map } from 'lodash';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useQuery } from 'react-query';
import { toastr } from 'react-redux-toastr';
import * as uuid from 'uuid';
import useHasPermission from '../../../../hooks/useHasPermission';
import useSelectedProperty from '../../../../hooks/useSelectedProperty';
import { get } from '../../../../utils/api';
import { useGqlQuery } from '../../../../utils/gql';
import {
  CREATE_NEW_PAYMENT_MUTATION,
  CREATE_AUTOPAY_SCHEDULE,
  CREATE_PAYMENT_SOURCE_MUTATION,
  DELETE_AUTOPAY_SCHEDULE,
  GET_RESIDENTS_EXISTING_AUTOPAY_SCHEDULES,
  UPDATE_AUTOPAY_SCHEDULE,
} from './queries';

export function usePADOption({
  residentId,
  isPrior,
  householdMembers,
  lateMethodId,
  configurePADModal,
  balance,
  householdId,
}) {
  const selectedProperty = useSelectedProperty();
  const hasConfigurePADPermission = useHasPermission('configure-pad');

  const [createNewPayment] = useMutation(CREATE_NEW_PAYMENT_MUTATION);
  const [createPaymentSource] = useMutation(CREATE_PAYMENT_SOURCE_MUTATION);
  const [createAutopaySchedule] = useMutation(CREATE_AUTOPAY_SCHEDULE);
  const [updateAutopaySchedule] = useMutation(UPDATE_AUTOPAY_SCHEDULE);
  const [deleteAutopaySchedule] = useMutation(DELETE_AUTOPAY_SCHEDULE);

  const [editData, setEditData] = useState(null);
  const [isOneTime, setIsOneTime] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sessionId = useRef(uuid.v4());

  const isApplicant = isNil(residentId);
  const customerIds = map(householdMembers, 'customerId');

  const hasFortressAsPaymentProvider = useMemo(
    () =>
      selectedProperty.paymentProviders?.find(
        (pp) => pp.paymentProvider === 'FORTRESS',
      ) !== undefined,
    [selectedProperty],
  );

  const [autopaySchedules, { refetch: refetchAutopaySchedules }] = useGqlQuery(
    GET_RESIDENTS_EXISTING_AUTOPAY_SCHEDULES,
    {
      customerIds,
    },
  );
  const { data: lateFeeDay } = useQuery(['lateFeeDay', lateMethodId], () =>
    get(`/lateFeeMethods?id=${lateMethodId}`).then(
      (methods) => methods?.[0]?.masterLateMethod?.lateDayOfMonth,
    ),
  );
  const getCurrentPADPaymentSource = useCallback(
    (customerId: string) => {
      return autopaySchedules?.find(
        (as) => as.customerId === customerId && as.type === 'PAD',
      )?.paymentSource;
    },
    [autopaySchedules],
  );
  const mappedHouseholdMembers = useMemo(
    () =>
      householdMembers
        .filter(
          (hm) =>
            hm.type === 'adult' && ['Current', 'Applicant'].includes(hm.status),
        )
        .map((hm) => {
          const hasActiveAutopaySchedule =
            autopaySchedules?.find(
              (as) => as.customerId === hm.customerId && as.type === 'Autopay',
            ) ?? false;
          return {
            id: hm.customerId,
            name: hm.name,
            isPrimary: hm.isPrimary,
            hasActiveAutopaySchedule,
            currentPADPaymentSource: getCurrentPADPaymentSource(hm.customerId),
          };
        }),
    [householdMembers, autopaySchedules, getCurrentPADPaymentSource],
  );
  const PADs = useMemo(
    () =>
      autopaySchedules
        ?.filter((as) => as.type === 'PAD')
        ?.map(
          (as) =>
            ({
              id: as.id,
              householdMember: mappedHouseholdMembers.find(
                (hm) => hm.id === as.customerId,
              )?.name,
              monthlyPaymentDay: as.paymentDay,
              paymentAmount: as.amount / 100,
              paymentAmountType: as.paymentAmountType,
              lastRunDate: as.lastRunDate,
            } ?? []),
        ),
    [autopaySchedules, mappedHouseholdMembers],
  );

  const disableOneTimeOption = hasConfigurePADPermission === false;
  const disableRecurringOption = hasConfigurePADPermission === false;
  const hideRecurringOption = isPrior || isApplicant;
  const shouldDisplay = hasFortressAsPaymentProvider;
  const showUSForm = selectedProperty.physicalCountry === 'US';

  useEffect(() => {
    if (configurePADModal.open === false) {
      setEditData(null);
      setIsOneTime(false);
    }
  }, [configurePADModal.open]);

  const onDelete = useCallback(
    function onDeleteCallback(autopayScheduleId) {
      deleteAutopaySchedule({
        variables: {
          id: autopayScheduleId,
        },
      })
        .then(() => {
          configurePADModal.closeModal();
          refetchAutopaySchedules();
          toastr.success(
            'Success',
            'Pre-authorized Debits (PAD) configuration canceled',
          );
        })
        .catch((e) => {
          toastr.error(
            'Error',
            `Error cancelling Pre-authorized Debits (PAD)${
              e?.message ? `: ${e.message}` : ''
            }`,
          );
        });
    },
    [deleteAutopaySchedule, refetchAutopaySchedules, configurePADModal],
  );

  async function getPaymentSourceIdForSubmit(values: ConfigurePADFormValues) {
    const selectedHouseholdMember = find(mappedHouseholdMembers, {
      id: values.householdMemberId,
    });

    if (selectedHouseholdMember.currentPADPaymentSource)
      return selectedHouseholdMember.currentPADPaymentSource.id;

    const result = await createPaymentSource({
      variables: {
        data: {
          idempotencyId: uuid.v4(),
          type: 'BANK_ACCOUNT',
          acceptTermsOfService: values.isDocumentSigned,
          name: values.nameOnAccount,
          accountNumber: values.accountNumber,
          customerId: values.householdMemberId,
          propertyId: selectedProperty.id,
          institutionNumber: values.financialInstitutionNumber,
          transitNumber: values.transitNumber,
          isOneTime,
          routingNumber: values.routingNumber,
          bankAccountType: values.accountType,
        },
      },
    });

    return result?.data?.createPaymentSource?.id;
  }

  async function handleRecurringPADSubmit(
    values: ConfigurePADFormValues,
    amount: number,
  ) {
    try {
      if (editData) {
        await updateAutopaySchedule({
          variables: {
            id: editData.autopaySchedule.id,
            data: {
              paymentDay: Number(values.paymentDay),
              paymentAmountType: values.paymentAmountType,
              amount,
            },
          },
        });
      } else {
        const paymentSourceId = await getPaymentSourceIdForSubmit(values);

        await createAutopaySchedule({
          variables: {
            data: {
              paymentSourceId,
              paymentDay: Number(values.paymentDay),
              paymentAmountType: values.paymentAmountType,
              amount,
              customerId: values.householdMemberId,
              propertyId: selectedProperty.id,
              type: 'PAD',
            },
          },
        });
      }

      toastr.success('Success', 'Pre-authorized Debits (PAD) saved');
    } catch (e) {
      toastr.error(
        'Error',
        `Error configuring Pre-authorized Debits (PAD)${
          e?.message ? `: ${e.message}` : ''
        }`,
      );
    }
  }

  async function handleOneTimePADSubmit(
    values: ConfigurePADFormValues,
    amount: number,
  ) {
    try {
      const paymentSourceId = await getPaymentSourceIdForSubmit(values);
      const balanceDue = Math.round(balance * 100);
      const selectedHouseholdMember = find(mappedHouseholdMembers, {
        id: values.householdMemberId,
      });
      const isOneTimePaymentSource =
        !selectedHouseholdMember.currentPADPaymentSource;
      const ledger = 'Resident';
      const product = 'FORTRESS_ONE_TIME_PAD';
      const idempotencyId = uuid.v5(
        objectHash({
          balanceSelectedAmount: amount,
          selectedPaymentMethodId: 'BANK_ACCOUNT',
          ledger,
          product,
        }),
        sessionId.current,
      );

      await createNewPayment({
        variables: {
          data: {
            idempotencyId,
            amount,
            paymentSourceId,
            householdId,
            balanceDue: balanceDue,
            ledger,
            customerId: selectedHouseholdMember?.id,
            propertyId: selectedProperty.id,
            organizationId: selectedProperty.organizationId,
            date: new Date(),
            product,
            metadata: {
              paymentSourceIsOneTime: isOneTimePaymentSource,
            },
          },
        },
      });

      toastr.success(
        'Success',
        'The one-time PAD has been processed ' +
          'successfully. The ledger will be updated shortly.',
      );
    } catch (e) {
      toastr.error(
        'Error',
        `Error encountered when initiating PAD${
          e?.message ? `: ${e.message}` : ''
        }`,
      );
    }
  }

  async function handleSubmit(values: ConfigurePADFormValues) {
    setIsSubmitting(true);

    const paymentAmount = getPaymentAmount(values, balance);
    const amount = paymentAmount
      ? Math.round(Number(paymentAmount) * 100)
      : null;

    const submitFn = isOneTime
      ? handleOneTimePADSubmit
      : handleRecurringPADSubmit;

    await submitFn(values, amount);

    refetchAutopaySchedules();

    configurePADModal.closeModal();
    setIsSubmitting(false);
  }

  function editPAD(id) {
    const selectedPAD = find(autopaySchedules, { id });
    setEditData({
      autopaySchedule: selectedPAD,
    });
    configurePADModal.openModal();
  }

  function handleOneTimePADClick() {
    setIsOneTime(true);
    configurePADModal.openModal();
  }

  return {
    shouldDisplay,
    householdMembers: mappedHouseholdMembers,
    lateFeeDay,
    onSubmit: handleSubmit,
    isSubmitting,
    onDelete,
    disableOneTimeOption,
    disableRecurringOption,
    hideRecurringOption,
    PADs,
    editData,
    editPAD,
    handleOneTimePADClick,
    isOneTime,
    showUSForm,
  };
}

function getPaymentAmount(
  { paymentAmountType, fixedAmount, paymentLimit }: ConfigurePADFormValues,
  balance: number,
) {
  if (paymentAmountType === 'FIXED_AMOUNT') {
    return fixedAmount;
  }
  if (paymentAmountType === 'BALANCE_DUE_BELOW') {
    return paymentLimit;
  }
  return balance;
}
