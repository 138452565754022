export const MONTLHY_LEASE_CHARGE_DROPDOWN_OPTIONS = [
  {
    value: 'current',
    text: 'Current',
    disabled: false,
  },
  {
    value: 'past',
    text: 'Past',
    disabled: false,
  },
  {
    value: 'future',
    text: 'Future',
    disabled: false,
  },
];

export const CHARGE_PERIOD_TYPES = {
  PRIOR_CURRENT: 'prior',
  FUTURE: 'future',
  PRORATE: 'prorate',
};
