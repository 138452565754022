import React, { useState } from 'react';
import { pathOr } from 'ramda';
import {
  Box,
  IconButton,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { PencilIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import BarGraph from '../../../../../components/Graphs/BarGraph';
import RequiredIncomeAveragingForm from './requiredIncomeAveragingForm';
import type { SetAsideSummaryComponentProps } from '../types';
import messages from './messages';
import { calculateActualIncomeAverage } from '../utils';

const SetAsideSummaryGraph = ({
  intl,
  kpi,
  contentHeight,
  incomeAveragingPercent,
  updateIncomeAveragingPercent,
  userPermissions,
}: SetAsideSummaryComponentProps) => {
  const [isEditingIncomeAvgPercent, setIsEditingIncomeAvgPercent] =
    useState(false);

  const datasets = kpi.map((setAside) => ({
    label: `Set-Aside: ${setAside.setAside}`,
    data: [setAside.numRequiredUnits, setAside.numActualUnits],
  }));

  const stackedData = {
    labels: ['', ''],
    datasets,
  };

  const onSaveIncomeAveragingPercent = (values: any) => {
    updateIncomeAveragingPercent(values.incomeAveragingPercent);
    setIsEditingIncomeAvgPercent(false);
  };

  const getChartTooltipLabel = (context) => {
    const index = pathOr(null, ['index'], context);
    if (index === null) {
      return context.value;
    }
    const type = index === 0 ? 'Required' : 'Actual';
    return `${type} Units: ${context.value}`;
  };

  const getChartTooltipTitle = ([context]) => {
    const datasetIndex = pathOr(null, ['datasetIndex'], context);
    if (datasetIndex === null) {
      return context.label;
    }

    const setAsideName =
      kpi.length > datasetIndex
        ? pathOr(null, ['setAside'], kpi[datasetIndex])
        : null;
    return setAsideName ? `Set-Aside: ${setAsideName}` : context.label;
  };

  const chartOptions = {
    tooltips: {
      callbacks: {
        label: getChartTooltipLabel,
        title: getChartTooltipTitle,
      },
    },
  };

  const actualIncomeAvg = calculateActualIncomeAverage(kpi);
  const isIncomeAverageValid = +actualIncomeAvg <= +incomeAveragingPercent;
  const requiredIncomeAvgText =
    incomeAveragingPercent === null ? '---' : `${incomeAveragingPercent}%`;
  const actualIncomeAvgText =
    actualIncomeAvg === null ? '---' : `${actualIncomeAvg}%`;

  const showEditIncomeAveragingButton = userPermissions.some(
    (p) => p.scope === 'compliance-tab-edit-set-aside-program',
  );

  return (
    <Box sx={{ width: { md: '50%' } }}>
      <Typography display={'block'} variant={'formSubheading'} marginBottom={1}>
        {intl.formatMessage({ ...messages.title })}
      </Typography>
      <Box
        sx={{
          height: contentHeight,
          backgroundColor: 'background.paper',
          position: 'relative',
          padding: 2,
          borderRadius: 1,
        }}
      >
        <div style={{ height: contentHeight - (48 + 32) }}>
          <BarGraph data={stackedData} stacked options={chartOptions} />
        </div>

        <Stack direction={'row'} justifyContent={'center'} spacing={6}>
          {!isEditingIncomeAvgPercent ? (
            <Stack alignItems={'center'}>
              <Typography
                variant={'h3'}
                display={'block'}
                sx={{ position: 'relative' }}
              >
                {requiredIncomeAvgText}
                {showEditIncomeAveragingButton && (
                  <IconButton
                    color={'primary'}
                    onClick={() => setIsEditingIncomeAvgPercent(true)}
                    sx={{ position: 'absolute', top: -4, right: -36 }}
                  >
                    <PencilIcon />
                  </IconButton>
                )}
              </Typography>
              <Typography>
                {!isEditingIncomeAvgPercent ? 'Required' : ''}
              </Typography>
            </Stack>
          ) : (
            <RequiredIncomeAveragingForm
              intl={intl}
              initialValues={{ incomeAveragingPercent }}
              onSubmit={(values) => onSaveIncomeAveragingPercent(values)}
              onCancel={() => setIsEditingIncomeAvgPercent(false)}
            />
          )}
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Typography
              variant={'h3'}
              color={isIncomeAverageValid ? 'success' : 'error'}
              display={'block'}
            >
              {actualIncomeAvgText}
            </Typography>
            <Typography>Actual</Typography>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default SetAsideSummaryGraph; //todo: add percentages
