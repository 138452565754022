import {
  all,
  always,
  clone,
  concat,
  equals,
  ifElse,
  map,
  pluck,
  type,
  pathOr,
  isEmpty,
} from 'ramda';

import messages from './messages';
import moment from 'moment';

const validate = (
  values: Object = {},
  { transferActions, steps, openFiscalPeriod, intl, lease }: Object,
) => {
  const errors = {};
  let transferActionValues = [];

  transferActionValues = map(
    (action) => ({
      ...action,
      valid: values['transferActions'][action.id],
    }),
    transferActions,
  );

  const evalSteps = clone(steps);

  const valuesToValidate = pluck(
    'valid',
    concat(transferActionValues, evalSteps),
  );

  errors._error = ifElse(all(equals(true)), always(undefined), always('error'))(
    valuesToValidate,
  );

  if (isEmpty(transferActions)) {
    errors._error = 'error';
  }

  let actualTransferDate = moment(values.actualTransferDate);
  if (!actualTransferDate.isValid() || type(values.startDate) === 'String') {
    errors.actualTransferDate = intl.formatMessage(messages.invalidDate);
  } else {
    actualTransferDate = actualTransferDate.startOf('day');
  }
  const openFiscalPeriodStartDate = pathOr(0, ['startDate'], openFiscalPeriod);
  if (
    !errors.actualTransferDate &&
    actualTransferDate.isBefore(openFiscalPeriodStartDate, 'day')
  ) {
    errors.actualTransferDate = intl.formatMessage(messages.beforeFiscalPeriod);
  }

  if (actualTransferDate.toDate() > Date.now()) {
    errors.actualTransferDate = intl.formatMessage(messages.moveInDateInFuture);
  }

  if (
    !errors.actualTransferDate &&
    actualTransferDate.toDate() <
      moment()
        .subtract(30, 'days')
        .toDate()
  ) {
    errors.actualTransferDate = intl.formatMessage(messages.moveInDateInPast);
  }

  const startDate = moment(lease.startDate);
  if (startDate.isValid()) {
    startDate.startOf('day');
  }

  if (
    !errors.actualTransferDate &&
    startDate.isValid() &&
    actualTransferDate.isBefore(startDate)
  ) {
    errors.actualTransferDate = intl.formatMessage(
      messages.moveInLessThanStartDate,
    );
  }
  return errors;
};

export default validate;
