import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { messages } from './messages';
import { BankInformationForm } from './BankInformationForm';
import { useForm } from '../hooks';
import { useUpdateBankAccounts } from './hooks';
import { pick } from 'ramda';

type Props = {
  show: boolean,
  onHide: Function,
  intl: Object,
  promptToaster: Function,
  propertyId: string,
  organizationId: string,
  propertyBankAccounts: Object[],
};

const getBankAccount = (bankAccounts: Object[], type: string) =>
  bankAccounts.find((b) => b.bankAccountType === type) || {};

const pickPayloadFields = (bankAccount: Object) =>
  pick(
    [
      'id',
      'bankAccountType',
      'bankAccountNumber',
      'bankName',
      'comment',
      'bankRoutingNumber',
    ],
    bankAccount,
  );

export const BankInformationModal = ({
  show,
  onHide,
  intl,
  promptToaster,
  propertyId,
  organizationId,
  propertyBankAccounts = [],
}: Props) => {
  const [
    bankAccounts,
    updateBankAccounts,
    setBankAccounts,
  ] = useUpdateBankAccounts(
    intl,
    promptToaster,
    propertyId,
    organizationId,
    propertyBankAccounts,
    onHide,
  );

  const [dirty, setDirty] = useState(false);

  const updateDirtyOnChangeWrapper = (onChange: Function) => (
    ...args: any[]
  ) => {
    if (!dirty) {
      setDirty(true);
    }
    onChange(...args);
  };

  useEffect(() => {
    setDirty(false);
  }, [show]);

  const [
    operatingAccount,
    setOperatingAccount,
    onOperatingAccountChange,
  ] = useForm(getBankAccount(bankAccounts, 'Operating'));
  const [
    securityAccount,
    setSecurityAccount,
    onSecurityAccountChange,
  ] = useForm(getBankAccount(bankAccounts, 'Security'));

  useEffect(() => {
    setOperatingAccount(getBankAccount(bankAccounts, 'Operating'));
    setSecurityAccount(getBankAccount(bankAccounts, 'Security'));
  }, [bankAccounts, setOperatingAccount, setSecurityAccount]);

  const onSave = () => {
    updateBankAccounts({
      bankAccounts: [
        pickPayloadFields(operatingAccount),
        pickPayloadFields(securityAccount),
      ],
    });
  };

  const onCancel = () => {
    setBankAccounts([...bankAccounts]);
    onHide();
  };

  return (
    <Modal bsSize="lg" show={show} autoFocus onHide={onCancel}>
      <Modal.Header closeButton>
        <i
          className="icon et-pay-check"
          style={{ fontSize: 25, margin: 12 }}
        ></i>
        <h1>{intl.formatMessage(messages.title)}</h1>
      </Modal.Header>
      <Modal.Body>
        <BankInformationForm
          intl={intl}
          title={messages.operatingAccount}
          data={operatingAccount}
          onChange={updateDirtyOnChangeWrapper(onOperatingAccountChange)}
        />
        <BankInformationForm
          intl={intl}
          title={messages.securityAccount}
          data={securityAccount}
          onChange={updateDirtyOnChangeWrapper(onSecurityAccountChange)}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col-xs-6">
            <button className="btn btn-default" onClick={onCancel}>
              {intl.formatMessage(messages.cancel)}
            </button>
          </div>
          <div className="col-xs-6">
            <button
              className="btn btn-primary pull-left"
              onClick={onSave}
              disabled={!dirty}
            >
              {intl.formatMessage(messages.save)}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
