import React from 'react';
import { Field } from 'redux-form';

import {
  renderSelectField,
  renderCurrencyField,
  renderFlexNumberField,
} from '../../../../../../utils/redux-form-helper';

import {
  TENANT_LANDLORD_SELECT_OPTIONS,
  RENT_RATE_OPTIONS,
  DAYS_SELECT_OPTIONS,
} from '../../constants';

import { FlexFormInput } from '../../common';

const RenewalOptionsFormFields = ({ values }: Object) => {
  return (
    <React.Fragment>
      <FlexFormInput>
        <Field
          name="Initiated by"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Initiated by"
          options={TENANT_LANDLORD_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Date Notice Required"
          component={renderFlexNumberField}
          label="Days Notice Required"
          max="1000"
          min="0"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Term of Renewal Option"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Term of Renewal Option (Months)"
          options={DAYS_SELECT_OPTIONS(120)}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Rent Rate"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Rent Rate"
          options={RENT_RATE_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Rent Rate Amount"
          component={renderCurrencyField}
          label="Rent Rate"
          step="0.01"
          placeholder="or amount"
        />
      </FlexFormInput>
    </React.Fragment>
  );
};

export default RenewalOptionsFormFields;
