import moment from 'moment';
import { pathOr, isNil } from 'ramda';

import { validateRequired, validateDate } from '../../../../utils/validations';

const validate = (values: Object, props: Object) => {
  const errors = {};

  const { editOptions, newAmount, endDate, newStartDate } = values;
  const { selectedCamCharge } = props;

  const currentStartDate = pathOr(null, ['startDate'], selectedCamCharge);
  const mCurrentStartDate = moment(currentStartDate);

  ['editOptions'].forEach((fieldName) => {
    if (!validateRequired(fieldName, values))
      errors._error = 'Please select one option';
  });

  if (editOptions === 'changeAmount') {
    ['newAmount', 'newStartDate'].forEach((fieldName) => {
      if (!validateRequired(fieldName, values))
        errors[fieldName] = 'This field is required';
    });
    if (newAmount >= 1000000 || newAmount <= 0) {
      errors['newAmount'] = 'Please enter a valid amount';
    }
    // validate dates
    if (!errors['newStartDate'] && !validateDate(newStartDate)) {
      errors['newStartDate'] =
        'New Start Date must be a valid date format (MM/DD/YYYY)';
    }
  }
  if (editOptions === 'endCharge') {
    if (!validateRequired('endDate', values))
      errors['endDate'] = 'This field is required';

    // validate dates
    if (!errors['endDate'] && !validateDate(endDate)) {
      errors['endDate'] = 'End Date must be a valid date format (MM/DD/YYYY)';
    }
  }

  // end date can't be before start date
  if (
    !errors['endDate'] &&
    !isNil(endDate) &&
    moment(endDate).isBefore(mCurrentStartDate, 'days')
  ) {
    errors['endDate'] = "End Date can't be before Start Date";
  }

  // when changing start date...
  // 1. start date can't be before today
  // 2. anything else is fine, since the BE will create a new charge and set the endDate of the current
  // one to the day before the new startDate
  if (
    !errors['newStartDate'] &&
    moment(newStartDate).isBefore(moment(), 'days')
  ) {
    errors['newStartDate'] = "Start Date can't be in the past";
  }

  return errors;
};

export default validate;
