import { defineMessages } from 'react-intl';

const moveInMessages = defineMessages({
  title: {
    id: 'App.EditMoveDateModal.MoveIn.Title',
    defaultMessage: 'Edit Move-In Date',
  },
  dateLabel: {
    id: 'App.EditMoveDateModal.MoveIn.DateLabel',
    defaultMessage: 'Move-In Date',
  },
});

const moveOutMessages = defineMessages({
  title: {
    id: 'App.EditMoveDateModal.MoveOut.Title',
    defaultMessage: 'Edit Move-Out Date',
  },
  dateLabel: {
    id: 'App.EditMoveDateModal.MoveOut.DateLabel',
    defaultMessage: 'Move-Out Date',
  },
});

export { moveInMessages, moveOutMessages };
