import { omit } from 'ramda';
import { throttle, put, takeLatest, select } from 'redux-saga/effects';
import {
  GET_ALL_PROSPECTS,
  ASSIGN_PROSPECT,
  CHANGE_PROSPECT_STATUS,
  CREATE_PROSPECT_ACTIVITY,
  UPDATE_PROSPECT_ACTIVITY,
  DELETE_PROSPECT_ACTIVITY,
} from './constants';
import {
  getAllProspects,
  getAllProspectsSuccess,
  getAllProspectsError,
  assignProspectSuccess,
  assignProspectError,
  changeProspectStatusSuccess,
  changeProspectStatusError,
  createProspectActivitySuccess,
  createProspectActivityError,
} from './actions';
import messages from './messages';
import ProspectProfileService from '../../services/prospectProfileService';
import ActivityService from '../../services/activityService';
import { getTaskInformation } from '../Home/actions';

import {
  selectCurrentFilter,
  selectCurrentSorting,
  selectSearchText,
} from './selectors';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
} from '../App/selectors';

import type {
  Action,
  Property,
  FilterValueAndPagination,
  FilterValue,
  OrderValue,
} from '../App/types';
import type {
  ProspectAndPagination,
  ProspectPaginationAndStatusChange,
  ActivityAndPagination,
} from './types';
import {
  renderTranslatedMessage,
  renderNoDataToastr,
} from '../../utils/redux-form-helper';
import { actions as toastrActions } from 'react-redux-toastr';
import type { Saga } from 'redux-saga';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetAllProspects({
  payload,
}: Action<FilterValueAndPagination>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const prospectsService = new ProspectProfileService();
    const response = yield prospectsService.getAll(
      payload.paginationInfo.pageNumber,
      payload.paginationInfo.limit,
      payload.filterValue,
      payload.sorting,
      payload.searchText,
      selectedProperty.id,
      organizationId,
    );
    if (response.results.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getAllProspectsSuccess(response.results, response.meta));
  } catch (err) {
    yield put(getAllProspectsError(err));
  }
}

export function* getAllProspectsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_PROSPECTS, fetchGetAllProspects);
}

export function* putAssignProspect({
  payload,
}: Action<ProspectAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const filterValue: FilterValue = yield select(selectCurrentFilter);
    const currentSorting: OrderValue = yield select(selectCurrentSorting);
    const searchText: string = yield select(selectSearchText);
    const prospectsService = new ProspectProfileService();
    yield prospectsService.assign(payload.prospect, organizationId);
    yield put(assignProspectSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    if (payload.prospect.tasks) {
      yield put(getTaskInformation());
    } else {
      yield put(
        getAllProspects(
          payload.paginationInfo.pageNumber,
          payload.paginationInfo.limit,
          filterValue,
          currentSorting,
          searchText,
        ),
      );
    }
  } catch (err) {
    yield put(assignProspectError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* assignProspect(): Saga<void> {
  yield takeLatest(ASSIGN_PROSPECT, putAssignProspect);
}

export function* putchangeProspectStatus({
  payload,
}: Action<ProspectPaginationAndStatusChange>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const filterValue: FilterValue = yield select(selectCurrentFilter);
    const currentSorting: OrderValue = yield select(selectCurrentSorting);
    const searchText: string = yield select(selectSearchText);
    const prospectsService = new ProspectProfileService();
    yield prospectsService.changeStatus(
      payload.prospect,
      payload.newStatusInfo.newStatus,
      payload.newStatusInfo.notes,
      organizationId,
    );
    yield put(changeProspectStatusSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(
      getAllProspects(
        payload.paginationInfo.pageNumber,
        payload.paginationInfo.limit,
        filterValue,
        currentSorting,
        searchText,
      ),
    );
  } catch (err) {
    yield put(changeProspectStatusError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* changeProspectStatus(): Saga<void> {
  yield throttle(500, CHANGE_PROSPECT_STATUS, putchangeProspectStatus);
}

export function* postCreateProspectActivity({
  payload,
}: Action<ActivityAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const filterValue: FilterValue = yield select(selectCurrentFilter);
    const currentSorting: OrderValue = yield select(selectCurrentSorting);
    const searchText: string = yield select(selectSearchText);
    const activityService = new ActivityService();
    yield activityService.save(payload.activity, organizationId);
    yield put(createProspectActivitySuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successActivityDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(
      getAllProspects(
        payload.paginationInfo.pageNumber,
        payload.paginationInfo.limit,
        filterValue,
        currentSorting,
        searchText,
      ),
    );
  } catch (err) {
    yield put(createProspectActivityError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* createProspectActivity(): Saga<void> {
  yield throttle(500, CREATE_PROSPECT_ACTIVITY, postCreateProspectActivity);
}
export function* refreshProspectData({
  payload,
}: Action<ActivityAndPagination>): Saga<void> {
  const filterValue: FilterValue = yield select(selectCurrentFilter);
  const currentSorting: OrderValue = yield select(selectCurrentSorting);
  const searchText: string = yield select(selectSearchText);
  yield put(
    getAllProspects(
      payload.paginationInfo.pageNumber,
      payload.paginationInfo.limit,
      filterValue,
      currentSorting,
      searchText,
    ),
  );
}
export function* postUpdateProspectActivity({
  payload,
}: Action<ActivityAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);

    const activityService = new ActivityService();
    const editPayload = omit(
      [
        'id',
        // $FlowFixMe
        'saveAndClose',
        // $FlowFixMe
        'saveAndAddNew',
        'createdAt',
        'updatedAt',
        // $FlowFixMe
        'createdById',
        // $FlowFixMe
        'updatedById',
        'updatedBy',
        'deletedAt',
        'lastUpdatedById',
        'ownedBy',
        'prospect',
        'completionStatus',
        'activityType',
      ],
      payload.activity,
    );
    yield activityService.edit(
      editPayload,
      payload.activity.id,
      organizationId,
    );
    // $FlowFixMe
    yield refreshProspectData({ payload });
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.successEditActivityDescription,
        ),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* editProspectActivity(): Saga<void> {
  yield throttle(500, UPDATE_PROSPECT_ACTIVITY, postUpdateProspectActivity);
}

export function* postDeleteProspectActivity({
  payload,
}: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const filterValue: FilterValue = yield select(selectCurrentFilter);
    const currentSorting: OrderValue = yield select(selectCurrentSorting);
    const searchText: string = yield select(selectSearchText);
    const activityService = new ActivityService();
    yield activityService.delete(payload.activity, organizationId);
    yield put(
      getAllProspects(
        payload.paginationInfo.pageNumber,
        payload.paginationInfo.limit,
        filterValue,
        currentSorting,
        searchText,
      ),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.successDeleteActivityDescription,
        ),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* deleteProspectActivity(): Saga<void> {
  yield throttle(500, DELETE_PROSPECT_ACTIVITY, postDeleteProspectActivity);
}

export default [
  getAllProspectsSaga,
  assignProspect,
  changeProspectStatus,
  createProspectActivity,
  editProspectActivity,
  deleteProspectActivity,
];
