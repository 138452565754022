import { useMemo, useState } from 'react';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  Rating,
  Typography,
  Stack,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import moment from 'moment';
import ResponseSection from './ResponseSection';
import * as constants from '../constants';
import type { ResponseSubmitSuccessHandler } from '../types';
import messages from '../messages';
import useFormatMessage from '../../../hooks/useFormatMessage';
import GoogleLogo from '../../../img/Google Icon@3x.png';
import FacebookLogo from '../../../img/Facebook Icon@3x.png';
import NotRecommendedIcon from '../../../img/Frame 44@3x.png';
import RecommendedIcon from '../../../img/Frame 45@3x.png';

type Props = {
  id: string,
  author: string,
  text: string,
  rating?: number,
  isRecommended: boolean,
  updateTime: string,
  source: string,
  canDelete: boolean,
  response?: {
    id?: string,
    text: string,
    updateTime: string,
  },
  isEdited: boolean,
  onResponseDeleteSuccess: Function,
  onResponseSubmitSuccess: ResponseSubmitSuccessHandler,
};

function ReviewItem({
  id,
  author,
  source,
  rating,
  isRecommended,
  text,
  updateTime,
  isEdited,
  response,
  canDelete,
  onResponseDeleteSuccess,
  onResponseSubmitSuccess,
}: Props) {
  const formatMessage = useFormatMessage();
  const [isResponseShown, setIsResponseShown] = useState(false);

  const handleShowResponse = () => {
    setIsResponseShown(true);
  };

  const handleHideResponse = () => {
    setIsResponseShown(false);
  };

  const logoProps = useMemo(() => {
    if (source === constants.GOOGLE_SOURCE_VALUE)
      return { src: GoogleLogo, alt: 'Google Logo' };
    if (source === constants.FACEBOOK_SOURCE_VALUE)
      return { src: FacebookLogo, alt: 'Facebook Logo' };

    return {};
  }, [source]);

  const ratingSection = useMemo(() => {
    if (typeof rating === 'number')
      return (
        <Rating
          value={rating}
          readOnly
          precision={constants.STAR_RATING_PRECISION}
        />
      );

    const style = { width: 20, height: 20 };

    return (
      <Typography variant="helper">
        {isRecommended ? (
          <>
            <img
              src={RecommendedIcon}
              alt={formatMessage(messages.recommended)}
              style={style}
            />{' '}
            {formatMessage(messages.recommends)}
          </>
        ) : (
          <>
            <img
              src={NotRecommendedIcon}
              alt={formatMessage(messages.doesntRecommend)}
              style={style}
            />{' '}
            {formatMessage(messages.doesntRecommend)}
          </>
        )}
      </Typography>
    );
  }, [rating, isRecommended, formatMessage]);

  return (
    <Grid container padding={1} spacing={1}>
      <Grid container item xs={12} md={2}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <span>
              <i className="et-user-edit" style={{ fontSize: 18 }} />{' '}
            </span>
            <Typography variant="body2">{author}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <img style={{ width: 25, height: 25 }} alt="Logo" {...logoProps} />
            <Typography>{constants.SOURCE_TO_TEXT_MAP[source]}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={10}>
        <Grid container item xs={12}>
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={12} md={10}>
              <Stack direction="row" alignItems="center" spacing={2}>
                {ratingSection}
                <Typography variant="helper">
                  {moment(updateTime).format(constants.DATE_FORMAT)}
                  {isEdited && ' (Edited)'}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container item xs={12} paddingTop={2} paddingBottom={1}>
            <Typography variant="body1">{text}</Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} padding={1}>
            <ResponseSection
              isResponseShown={isResponseShown}
              reviewId={id}
              source={source}
              response={response}
              formatMessage={formatMessage}
              canDelete={canDelete}
              onShowResponse={handleShowResponse}
              onHideResponse={handleHideResponse}
              onResponseDeleteSuccess={onResponseDeleteSuccess}
              onResponseSubmitSuccess={onResponseSubmitSuccess}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ReviewItem;
