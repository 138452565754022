import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { sort } from 'ramda';

import {
  GROSS_RENT,
  CERTIFICATION_TYPES,
  OTHER_HUD,
  OTHER_LIHTC,
} from '../constants';

import RecertsTable from './RecertsTable';
import GrossRentCertsTable from './GrossRentCertsTable';
import ActiveCertificationsTable from './ActiveCertifications';
import { useFetchAsyncCertifications } from './hooks';
import { applySort } from './utils';

import generalMessages from '../../App/messages';
import componentMessages from '../messages';

const messages = { ...generalMessages, ...componentMessages };

type CerticationsTableProps = {
  certificationType: string,
  filter: string,
  intl: Object,
  organizationId: string,
  searchText: string,
  selectedProperty: Object,
  currentSort: Object,
  handleSortChange: Function,
  headers: Array<Object>,
  complianceApprovalStatuses: Array<Object>,
  propertyAffordablePrograms: Array<Object>,
};

function ManageCertificationsContent({
  certificationType,
  filter,
  intl,
  organizationId,
  searchText,
  selectedProperty,
  currentSort,
  handleSortChange,
  headers,
  complianceApprovalStatuses,
  propertyAffordablePrograms,
  displayAll,
  setDisplayAll,
  show,
  setShow,
}: CerticationsTableProps) {
  const { rows, statistics, signed59ADocumentType, isLoading, summary } =
    useFetchAsyncCertifications(
      certificationType,
      organizationId,
      selectedProperty.id,
      searchText,
      filter,
      displayAll,
    );
  const onSortChange = ({ fieldName }: Object) => {
    const { order, columnName } = currentSort;
    if (fieldName === columnName) {
      const newOrder = order === 'ASC' ? 'DESC' : 'ASC';
      handleSortChange({
        columnName: fieldName,
        order: newOrder,
      });
    } else {
      handleSortChange({
        columnName: fieldName,
        order: 'ASC',
      });
    }
  };

  const sortedRows = sort(applySort(currentSort, headers), rows);

  if (certificationType === CERTIFICATION_TYPES.RECERT) {
    return (
      <RecertsTable
        rows={sortedRows}
        headers={headers}
        isLoading={isLoading}
        onSortChange={onSortChange}
        RecertsTable
        statistics={statistics}
        displayAll={displayAll}
        setDisplayAll={setDisplayAll}
        show={show}
        setShow={setShow}
      />
    );
  }

  if (certificationType === GROSS_RENT) {
    return (
      <GrossRentCertsTable
        rows={sortedRows}
        headers={headers}
        isLoading={isLoading}
        onSortChange={onSortChange}
        selectedProperty={selectedProperty}
        signed59ADocumentType={signed59ADocumentType}
      />
    );
  }

  if (
    [
      CERTIFICATION_TYPES.INITIAL,
      CERTIFICATION_TYPES.MOVE_IN,
      OTHER_HUD,
      OTHER_LIHTC,
    ].includes(certificationType)
  ) {
    return (
      <ActiveCertificationsTable
        certificationType={certificationType}
        rows={sortedRows}
        headers={headers}
        isLoading={isLoading}
        onSortChange={onSortChange}
        selectedProperty={selectedProperty}
        summary={summary}
        complianceApprovalStatuses={complianceApprovalStatuses}
        propertyAffordablePrograms={propertyAffordablePrograms}
      />
    );
  }

  return (
    <p style={{ marginTop: '25vh', textAlign: 'center' }}>
      <FormattedMessage {...messages.selectOption} />
    </p>
  );
}

const mapStateToProps = ({
  app: { currentUser, selectedProperty },
}: any): any => {
  return {
    currentUser: currentUser,
    organizationId: currentUser ? currentUser.user.organizationId : '',
    selectedProperty,
  };
};

export default connect(mapStateToProps)(
  injectIntl(ManageCertificationsContent),
);
