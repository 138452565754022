import { defineMessages } from 'react-intl';

const messages = defineMessages({
  vacantNotLeasedByFloorplan: {
    id: 'App.Overview.VacantNotLeasedByFloorplan.VacantNotLeasedByFloorplan',
    defaultMessage: 'Vacant Not Leased By Floor Plan',
  },
  ready: {
    id: 'App.Overview.VacantNotLeasedByFloorplan.Ready',
    defaultMessage: 'Ready',
  },
  notReady: {
    id: 'App.Overview.VacantNotLeasedByFloorplan.NotReady',
    defaultMessage: 'Not Ready',
  },
});

export default messages;
