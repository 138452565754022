const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class PortalService {
  sendPortalInviteEmail(
    organizationId: string,
    propertyId: string,
    customerOrProspectId: string,
    customerStatus: string,
  ) {
    const idType = customerStatus === 'prospect' ? 'prospectId' : 'customerId';
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        [idType]: customerOrProspectId,
        client: 'fortress',
      }),
    };
    return request(
      `${apiURL}/${organizationId}/portaluser/create-and-notify-portal-${customerStatus}`,
      options,
    );
  }

  togglePortalStatus(
    organizationId: string,
    propertyId: string,
    portalUserId: string,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/portal-user/${portalUserId}/status`,
      options,
    );
  }

  resetPortalAccount(
    organizationId: string,
    propertyId: string,
    portalUserId: string,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/portal-user/${portalUserId}/reset-account`,
      options,
    );
  }

  sendPortalResetPasswordEmail(
    organizationId: string,
    propertyId: string,
    emailAddress: string,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        emailAddress,
        client: 'fortress',
      }),
    };
    return request(
      `${apiURL}/portal/${organizationId}/${propertyId}/auth/resetPasswordToken`,
      options,
    );
  }

  updatePortalUser(
    organizationId: string,
    propertyId: string,
    portalUserId: string,
    query: Object,
  ) {
    const options = {
      method: 'PATCH',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/portal-user/${portalUserId}`,
      options,
    );
  }
}

export default PortalService;
