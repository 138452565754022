import { put, takeLatest, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { GET_EXPIRING_LEASES } from './constants';
import { getExpiringLeasesSuccess, getExpiringLeasesFailure } from './actions';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
} from '../App/selectors';
import { renderNoDataToastr } from '../../utils/redux-form-helper';
import ExpiringLeasesService from '../../services/expiringLeasesService';
import type { Action, Property, FilterValueAndPagination } from '../App/types';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchExpiringLeasesSaga({
  payload,
}: Action<FilterValueAndPagination>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const expiringLeasesService = new ExpiringLeasesService();
    const response = yield expiringLeasesService.get(
      organizationId,
      selectedProperty.id,
      payload,
    );
    if (response.results?.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getExpiringLeasesSuccess(response));
  } catch (err) {
    yield put(getExpiringLeasesFailure(err));
  }
}

export function* getExpiringLeasesSaga(): Saga<void> {
  yield takeLatest(GET_EXPIRING_LEASES, fetchExpiringLeasesSaga);
}

export default [getExpiringLeasesSaga];
