import React from 'react';

type Props = {
  small?: boolean,
};

const SpinnerBars = () => (
  <div className="spinner" data-testid="spinner">
    <div className="spinnerbar spnbar1" />
    <div className="spinnerbar spnbar2" />
    <div className="spinnerbar spnbar3" />
    <div className="spinnerbar spnbar4" />
    <div className="spinnerbar spnbar5" />
  </div>
);

const SmallSpinnerBars = () => (
  <div className="loading-container loading-container--inline loading-container--small">
    <SpinnerBars />
  </div>
);

const Spinner = ({ small }: Props) => {
  return small ? <SmallSpinnerBars /> : <SpinnerBars />;
};

export default Spinner;
