import styled from 'styled-components';

export const IconPlusMinus = styled.i`
  text-decoration: none;
  font-size: 25px;
  color: #4d73ff;
`;

export const RowDetailsGridForm = styled.form`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;

export const RowGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const AddButtonRowGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
`;

export const SaveCancelDetailsRowGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 13fr;
  text-align: left;
  margin-bottom: 20px;
  margin-top: -10px;
`;
