import { SelectOption } from './types';

export const translateFilterTexts = (
  filter: FieldDescriptor,
  locale: { [key: string]: string },
  language,
) => {
  const { options, ...rest } = filter ?? {};
  const { name } = filter ?? {};
  const { placeholder } = filter ?? {};
  const tranlatedLabel = locale?.[name] ?? name;
  const translatedOptions = (options ?? []).map((option: SelectOption) => {
    const { text, translations } = option ?? {};
    const translatedText = translations?.[language] ?? locale?.[text] ?? text;
    return {
      ...option,
      text: translatedText,
    };
  });
  const translatedPlaceholder = locale?.[placeholder] ?? placeholder;

  const translatedLabelObject = tranlatedLabel ? { label: tranlatedLabel } : {};
  const optionsObject =
    translatedOptions.length > 0 ? { options: translatedOptions } : {};
  const placeholderObject = translatedPlaceholder
    ? { placeholder: translatedPlaceholder }
    : {};

  return {
    ...rest,
    ...translatedLabelObject,
    ...optionsObject,
    ...placeholderObject,
  };
};
