import { RECERT_CERT_TYPE } from '../../constants/affordableCertificationTypes';
import moment from 'moment-business-days';
import { COMPLIANCE_TYPES } from './constants';
import { COMPLIANCE_APPROVAL_STATUSES } from '../../constants/affordableComplianceApprovalStatuses';

const CHECK_HOUR = 14;
const FINAL_BUSINESS_DAYS = 4;
const RECERT_BUSINESS_DAYS = 7;
const OTHER_BUSINESS_DAYS = 2;

/**
 * Will indicate whether a document or recert is past due
 * ---
 * @param {*} param0
 * @returns
 */
export const isDocumentOrRecertPastDue = ({
  certificationType,
  complianceType,
  complianceApprovalStatus,
  complianceDocumentType,
  complianceOverviewReviewedAndNumberOfDays,
  uploadDate,
  voucherEffectiveDate,
}) => {
  let lateDate = null;
  const documentUploadDate = uploadDate ? moment(uploadDate) : null;

  if (
    complianceOverviewReviewedAndNumberOfDays &&
    complianceType === COMPLIANCE_TYPES.ALL_OPEN_CERTS
  ) {
    return isVoucherDatePastDue(voucherEffectiveDate);
  } else if (
    isApprovalStatusPendingOrSimilar(complianceApprovalStatus) &&
    documentUploadDate
  ) {
    lateDate = setLateDate(certificationType, complianceDocumentType);
  }

  return documentUploadDate ? documentUploadDate.isBefore(lateDate) : null;
};

const isVoucherDatePastDue = (voucherDate) => {
  return moment(voucherDate)
    .hour(CHECK_HOUR)
    .isBefore(moment().hour(CHECK_HOUR));
};

const isApprovalStatusPendingOrSimilar = (approvalStatus) => {
  return [
    COMPLIANCE_APPROVAL_STATUSES.pending,
    COMPLIANCE_APPROVAL_STATUSES.pendingFinalApproval,
    COMPLIANCE_APPROVAL_STATUSES.correctionNeeded,
    COMPLIANCE_APPROVAL_STATUSES.approvedPendingSign,
    COMPLIANCE_APPROVAL_STATUSES.none,
    null,
  ].includes(approvalStatus);
};

const setLateDate = (certificationType, complianceDocumentType) => {
  let businessDays;

  if (complianceDocumentType?.includes('Final')) {
    businessDays = FINAL_BUSINESS_DAYS;
  } else {
    businessDays = isRecert(certificationType)
      ? RECERT_BUSINESS_DAYS
      : OTHER_BUSINESS_DAYS;
  }

  return moment().hour(CHECK_HOUR).businessSubtract(businessDays, 'days');
};

const isRecert = (certificationType) => {
  return certificationType === RECERT_CERT_TYPE;
};
