import { put, throttle, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../../utils/redux-form-helper';
import { CREATE_CHARGE_BATCH } from './constants';
import type { Action, Property } from '../../App/types';
import BatchDocumentService from '../../../services/batchDocumentService';
import { createChargeBatchSuccess, createChargeBatchFailure } from './actions';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
} from '../../App/selectors';
import messages from './messages';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* createChargeBatchSaga(action: Action<any>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);

    const propertyId = selectedProperty.id;
    const propertShortName = selectedProperty.shortName;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const batchDocumentService = new BatchDocumentService();
    const batchDocument = new FormData();

    batchDocument.append('file', action.payload.file);
    batchDocument.append('endDate', action.payload.endDate);
    batchDocument.append('noEndDate', action.payload.noEndDate);
    batchDocument.append('startDate', action.payload.startDate);
    batchDocument.append('shortName', propertShortName);

    yield batchDocumentService.create(
      batchDocument,
      organizationId,
      propertyId,
    );

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.createChargeBatchSuccessDescription,
        ),
        title: renderTranslatedMessage(messages.createChargeBatchSuccessHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );

    yield put(createChargeBatchSuccess());
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.createChargeBatchFailureHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(createChargeBatchFailure(err));
  }
}

export function* watchCreateChargeBatchSaga(): Saga<void> {
  yield throttle(500, CREATE_CHARGE_BATCH, createChargeBatchSaga);
}

export default [watchCreateChargeBatchSaga];
