import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import ViewBankDepositHeader from './ViewBankDepositHeader/index.js';
import ViewBankDepositDetails from './ViewBankDepositDetails/index.js';
import ViewBankDepositDocuments from './ViewBankDepositDocuments/index.js';
import { getDeposit, clearLoadedDeposit } from './actions.js';
import { promptToaster } from '../App/actions';
import messages from './messages';
import type { GlobalState } from '../App/types';
import { getAllPropertyPeriods } from '../ManageMiscTransactions/actions.js';
import type { Period } from '../ManageMiscTransactions/types.js';
import { ViewSingleTransactionModal } from '../../components/ViewSingleTransactionModal';
import ViewMiscTransactionModal from '../ManageMiscTransactions/MiscTransactionsDetails/viewMiscTransactionModal';
import { parseTransactionViewFields } from '../ManageMiscTransactions/MiscTransactionsDetails';
import { NoPrint } from 'react-easy-print';
import { pathOr } from 'ramda';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
} from '../App/selectors';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type Props = {
  bankDeposit: Object,
  depositId: string,
  periods: Array<Period>,
};

type InjectedProps = {
  intl: any,
  actions: Object,
  history: Object,
  store: Object,
};

export class ViewBankDeposit extends Component<Props & InjectedProps> {
  componentDidMount() {
    this.props.actions.getDeposit(this.props.depositId);
  }

  componentWillUnmount() {
    this.props.actions.clearLoadedDeposit();
  }

  onCancel = () => {
    navigateToUrlWithSelectedPropertyId('/manage-deposits');
  };

  onViewDetailsFunction = (transaction: Object): any => {
    const {
      store,
      intl,
      bankDeposit: { header },
    } = this.props;
    if (transaction.type === 'Batch') {
      navigateToUrlWithSelectedPropertyId(
        `/payment-batch/${transaction.realId}?goback=View Deposit`,
      );
    } else if (transaction.type === 'Misc') {
      const tx = transaction.additionalTxInfo;
      ViewMiscTransactionModal(
        intl,
        parseTransactionViewFields(intl, {
          status: tx.status,
          transactionDate: tx.transactionDate,

          chargeCode: pathOr('', ['ptcCharge', 'transactionCode', 'code'], tx),
          // $FlowFixMe
          paymentCode: pathOr(
            '',
            ['ptcPayment', 'transactionCode', 'code'],
            tx,
          ),
          // $FlowFixMe
          chargeCodeDescription: pathOr(
            '',
            ['ptcCharge', 'transactionCode', 'description'],
            tx,
          ),
          // $FlowFixMe
          paymentCodeDescription: pathOr(
            '',
            ['ptcPayment', 'transactionCode', 'description'],
            tx,
          ),

          documentNumber: tx.documentNumber,
          transactionNote: tx.transactionNote,
          transactionAmount: tx.transactionAmount,
        }),
        () => {},
        tx.propertyFiscalPeriods.period,
        tx.status,
        false,
      );
    } else {
      const treatAsNegative = pathOr(
        false,
        ['additionalTxInfo', 'payment', 'treatAsNegative'],
        transaction,
      );
      const amount = pathOr(0, ['paymentAmount'], transaction);

      ViewSingleTransactionModal({
        // $FlowFixMe
        singleTransaction: {
          ...transaction.additionalTxInfo,
          transactionAmount: treatAsNegative ? amount * -1 : amount,
          depositBatchName: header.name,
        },
        isReversable: false,
        onReverse: () => {},
        onPrint: window.print.bind(window),
        personStatus: transaction.additionalTxInfo.customerStatus,
        frNames:
          transaction.additionalTxInfo.payment
            .financiallyResponsibleLeaseSignersInHousehold,
        isOps: transaction.type === 'Ops',
        store,
        intl,
      });
    }
  };

  render() {
    const {
      intl,
      bankDeposit: { header, transactions },
      periods,
    } = this.props;

    return (
      <NoPrint>
        <div className="bodywrap" data-test="view-bank-deposit">
          <ViewBankDepositHeader
            intl={intl}
            headerData={header}
            data-test="view-bank-deposit-header"
          />
          {transactions ? (
            <ViewBankDepositDetails
              intl={intl}
              transactions={transactions}
              periods={periods}
              data-test="view-bank-deposit-details"
              onView={this.onViewDetailsFunction}
            />
          ) : null}
          <div className="container-fluid">
            <div className="table-footer">
              <div className="row">
                <div className="col-xs-12">
                  <span className="table-total">
                    {intl.formatMessage(messages.totalPayments)}:{' '}
                    {header.batchTotal}
                  </span>
                  <span className="text-darkgray">
                    {intl.formatMessage(messages.numberOfItems)}:{' '}
                    {transactions.length}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <ViewBankDepositDocuments
            intl={intl}
            depositId={this.props.depositId}
            selectedPropertyId={
              this.props.selectedProperty
                ? this.props.selectedProperty.id
                : null
            }
            selectedOrganizationId={this.props.selectedOrganizationId}
            promptToaster={this.props.actions.promptToaster}
          />

          <div className="row">
            <div className="col-xs-12">
              <div className="form-footer padtop20">
                <div className="button-group">
                  <button
                    className="btn btn-default"
                    onClick={() => this.onCancel()}
                  >
                    {intl.formatMessage(messages.cancel)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NoPrint>
    );
  }
}

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): Object => {
  const {
    financials: {
      bankDeposit,
      miscTransactions: { periods },
    },
  } = state;
  return {
    depositId: ownProps.match.params.depositId,
    bankDeposit,
    periods,
    selectedProperty: selectSelectedProperty(state),
    selectedOrganizationId: selectCurrentUserOrganizationId(state),
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    actions: bindActionCreators(
      {
        getDeposit,
        clearLoadedDeposit,
        getAllPropertyPeriods,
        promptToaster,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ViewBankDeposit));
