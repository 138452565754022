import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Card,
  Divider,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

import messages from './messages';
import { FormGroup, HelpBlock } from 'react-bootstrap';

type Props = {
  editMode: boolean,
  marketingInfo: Object,
  onChange: Function,
  disabled: boolean,
};

const MarketingDetails = (props: Props) => {
  const { editMode: editModeProp, marketingInfo, onChange, disabled } = props;

  const { pitch1, officeHoursDescription, propertyDescription } = marketingInfo;

  const editMode = editModeProp && !disabled;

  const validationState = (value: string) =>
    editMode && !value.trim() ? 'error' : undefined;

  const requiredText = (value: string) =>
    validationState(value) && (
      <HelpBlock>
        <FormattedMessage {...messages.required} />
      </HelpBlock>
    );

  return (
    <Card sx={{ padding: 2, height: '100%' }}>
      <Typography variant={'h3'}>
        <FormattedMessage {...messages.marketingDetails} />
      </Typography>
      <Divider sx={{ marginY: 2 }} />
      <Typography variant={'inputLabel'} display={'block'} mb={1}>
        <FormattedMessage {...messages.shortPropertyDescription} />*
      </Typography>
      <FormGroup validationState={validationState(pitch1)}>
        <textarea
          name="pitch1"
          className="form-control input-lg"
          value={pitch1}
          maxLength="255"
          disabled={!editMode}
          onChange={onChange}
        />
        {requiredText(pitch1)}
      </FormGroup>

      <Typography variant={'inputLabel'} display={'block'} mb={1}>
        <FormattedMessage {...messages.fullPropertyDescription} />
      </Typography>
      <FormGroup>
        <textarea
          name="propertyDescription"
          className="form-control input-lg"
          value={propertyDescription}
          maxLength="2500"
          disabled={!editMode}
          onChange={onChange}
        />
      </FormGroup>

      <Typography variant={'inputLabel'} display={'block'} mb={1}>
        <FormattedMessage {...messages.officeHours} />
      </Typography>
      <FormGroup>
        <input
          name="officeHoursDescription"
          className="form-control input-lg"
          value={officeHoursDescription}
          disabled={!editMode}
          onChange={onChange}
          maxLength="255"
        />
      </FormGroup>
    </Card>
  );
};

export default MarketingDetails;
