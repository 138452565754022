import { useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  useTableFilterSortSearchManager,
  useTableFilterSortData,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { formatPhoneNumber } from '@fortress-technology-solutions/fortress-component-library/utils';
import {
  getDateDiffInDays,
  getDateUTC,
  getIsPastDueDate,
} from '../../utils/date-helpers';
import { exportAndDownloadCSV } from '../../utils/csv-helpers';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import useHasPermission from '../../hooks/useHasPermission';
import UserService from '../../services/userService';
import PriorityLevelService from '../../services/priorityLevelService';
import { toastr } from 'react-redux-toastr';
import WorkOrderService from '../../services/workOrderService';
import useUniqueTableName from '../../hooks/useUniqueTableName';
import {
  ASSIGNE_INACTIVE_COLOR,
  PAST_DUE_COLOR,
  PROPERTY_PATH_MAP,
} from './constants';
import messages from './messages';

const defaultOption = {
  value: '',
  text: 'Choose',
  disabled: true,
};
const TABLE_NAME = 'manageWorkOrders';

function useHeaders({
  intl,
  filterOptions,
  manageWorkOrdersBuildingNumberFlag,
}) {
  return useMemo(() => {
    return [
      {
        id: 'id',
        label: intl.formatMessage(messages.id),
        sortable: true,
        searchable: true,
        dataType: 'string',
      },
      {
        id: 'location',
        label: intl.formatMessage(messages.location),
        searchable: true,
        dataType: 'string',
      },
      ...(manageWorkOrdersBuildingNumberFlag
        ? [
            {
              id: 'buildingNumber',
              label: intl.formatMessage(messages.buildingNumber),
              sortable: true,
              searchable: true,
              multiselect: true,
              dataType: 'string',
              filterOptions: filterOptions.buildingNumberOptions,
            },
          ]
        : []),
      {
        id: 'issueDescription',
        label: intl.formatMessage(messages.issueDescription),
        sortable: true,
        searchable: true,
        dataType: 'string',
      },
      {
        id: 'creationDate',
        label: intl.formatMessage(messages.creationDate),
        searchable: true,
        sortable: true,
        dataType: 'date',
      },
      {
        id: 'assignedTo',
        label: intl.formatMessage(messages.assignedTo),
        sortable: true,
        searchable: true,
        multiselect: true,
        filterOptions: filterOptions.assignedToOptions,
        dataType: 'string',
      },
      {
        id: 'priority',
        label: intl.formatMessage(messages.priority),
        sortable: true,
        searchable: true,
        multiselect: true,
        filterOptions: filterOptions.priorityOptions,
        dataType: 'string',
      },
      {
        id: 'status',
        label: intl.formatMessage(messages.status),
        sortable: true,
        searchable: true,
        multiselect: true,
        filterOptions: filterOptions.statusOptions,
        dataType: 'string',
      },
      {
        id: 'daysOpen',
        label: intl.formatMessage(messages.daysOpen),
        sortable: true,
        searchable: true,
        dataType: 'number',
      },
      {
        id: 'dueDate',
        label: intl.formatMessage(messages.dueDate),
        searchable: true,
        sortable: true,
        dataType: 'date',
      },
      {
        id: 'completionDate',
        label: intl.formatMessage(messages.completionDate),
        searchable: true,
        sortable: true,
        dataType: 'date',
      },
      {
        id: 'requestor',
        label: intl.formatMessage(messages.requestor),
        sortable: true,
        searchable: true,
        dataType: 'string',
      },
    ];
  }, [
    filterOptions.assignedToOptions,
    filterOptions.priorityOptions,
    filterOptions.statusOptions,
    filterOptions.buildingNumberOptions,
    intl,
    manageWorkOrdersBuildingNumberFlag,
  ]);
}

function useRows({
  sortedAndFilteredResults,
  assignedToOptions,
  onAssignedToChange,
  onPriorityChange,
  priorityOptions,
  assigneeChangeDisabled,
  priorityChangeDisabled,
  viewWorkOrderDisabled,
}) {
  return useMemo(() => {
    return sortedAndFilteredResults.map((workOrder) => {
      return {
        sx: {
          backgroundColor: workOrder.assigneeInactiveOrWithoutAccess
            ? ASSIGNE_INACTIVE_COLOR
            : workOrder.pastDue
            ? PAST_DUE_COLOR
            : undefined,
        },
        id: {
          value: workOrder.id,
        },
        location: {
          value: workOrder.location,
        },
        buildingNumber: {
          value: workOrder.buildingNumber,
        },
        issueDescription: {
          variant: 'link',
          to: workOrder.href,
          value: workOrder.issueDescription,
          disabled: viewWorkOrderDisabled,
        },
        creationDate: {
          variant: 'dateTime',
          value: workOrder.creationDate,
        },
        assignedTo: {
          variant: 'select',
          options: assignedToOptions,
          name: 'assignedToSelect',
          value: workOrder.assignedToId,
          onChange: (value: string) => onAssignedToChange(workOrder, value),
          disabled: assigneeChangeDisabled,
        },
        priority: {
          variant: 'select',
          options: priorityOptions,
          name: 'prioritySelect',
          value: workOrder.priorityId,
          onChange: (value: string) => onPriorityChange(workOrder, value),
          disabled: priorityChangeDisabled,
        },
        status: {
          variant: 'status',
          value: workOrder.status,
        },
        daysOpen: {
          variant: 'number',
          value: workOrder.daysOpen,
        },
        dueDate: {
          variant: 'date',
          value: workOrder.dueDate,
        },
        completionDate: {
          variant: 'date',
          value: workOrder.completionDate,
        },
        requestor: {
          value: [
            workOrder.requestorName,
            workOrder.requestorPhone
              ? formatPhoneNumber(workOrder.requestorPhone)
              : '',
          ],
        },
      };
    });
  }, [
    assignedToOptions,
    onAssignedToChange,
    onPriorityChange,
    priorityOptions,
    sortedAndFilteredResults,
    assigneeChangeDisabled,
    priorityChangeDisabled,
    viewWorkOrderDisabled,
  ]);
}

export function useFilterOptions({
  assignedToOptions,
  priorityOptions,
  workOrders,
}) {
  return useMemo(() => {
    const statusOptions = orderBy(
      workOrders.reduce((acc, workOrder) => {
        const status = workOrder.status;
        if (!acc.find((option) => option.value === status)) {
          acc.push({
            value: status,
            text: status,
          });
        }
        return acc;
      }, []),
      'text',
      'asc',
    );

    const buildingNumberOptions = orderBy(
      workOrders.reduce((acc, workOrder) => {
        const buildingNumber = workOrder.buildingNumber;
        if (!buildingNumber) {
          return acc;
        }
        if (!acc.find((option) => option.value === buildingNumber)) {
          acc.push({
            value: buildingNumber,
            text: buildingNumber,
          });
        }
        return acc;
      }, []),
      'text',
      'asc',
    );

    return {
      assignedToOptions: assignedToOptions.filter(
        (option) => option.disabled !== true,
      ),
      priorityOptions: priorityOptions.filter(
        (option) => option.disabled !== true,
      ),
      statusOptions,
      buildingNumberOptions,
    };
  }, [assignedToOptions, priorityOptions, workOrders]);
}

function useCSVRows({ sortedAndFilteredResults }) {
  return useMemo(() => {
    return sortedAndFilteredResults.map((workOrder) => {
      return {
        id: {
          value: workOrder.id.toString(),
        },
        location: {
          value: workOrder.location,
        },
        buildingNumber: {
          value: workOrder.buildingNumber,
        },
        issueDescription: {
          variant: 'description',
          value: workOrder.issueDescription,
        },
        creationDate: {
          variant: 'dateTime',
          value: workOrder.creationDate,
        },
        assignedTo: {
          value: workOrder.assignedTo ?? '',
        },
        priority: {
          value: workOrder.priority,
        },
        status: {
          value: workOrder.status,
        },
        daysOpen: {
          variant: 'number',
          value: workOrder.daysOpen.toString(),
        },
        dueDate: {
          variant: 'date',
          value: workOrder.dueDate ?? '',
        },
        completionDate: {
          variant: 'date',
          value: workOrder.completionDate ?? '',
        },
        requestor: {
          value: workOrder.requestorName
            ? `${workOrder.requestorName}${
                workOrder.requestorPhone
                  ? ` ${formatPhoneNumber(workOrder.requestorPhone)}`
                  : ''
              }`
            : '',
        },
      };
    });
  }, [sortedAndFilteredResults]);
}

function useHandleCSVButtonClick({
  exportAndDownloadCSV,
  filterState,
  dateState,
  searchState,
  filteredHeaders,
  csvRows,
}) {
  return useCallback(() => {
    if (!exportAndDownloadCSV) return false;

    const hasAnyFilters =
      Object.keys(filterState)?.length > 0 ||
      (dateState && Object.keys(dateState)?.length > 0) ||
      (searchState && Object.keys(searchState)?.length > 0) ||
      false;

    exportAndDownloadCSV({
      excludedHeaders: [],
      filteredHeaders,
      fileName: 'ManageWorkOrders.csv',
      hasAnyFilters,
      rows: csvRows,
    });

    return true;
  }, [
    exportAndDownloadCSV,
    filterState,
    dateState,
    searchState,
    filteredHeaders,
    csvRows,
  ]);
}

export function useManageWorkOrdersTable({
  name = TABLE_NAME,
  workOrders,
  intl,
  assignedToOptions,
  priorityOptions,
  onAssignedToChange,
  onPriorityChange,
  exportAndDownloadCSV,
  assigneeChangeDisabled,
  priorityChangeDisabled,
  viewWorkOrderDisabled,
  manageWorkOrdersBuildingNumberFlag,
}) {
  const filterOptions = useFilterOptions({
    assignedToOptions,
    priorityOptions,
    workOrders,
  });
  const headers = useHeaders({
    intl,
    filterOptions,
    manageWorkOrdersBuildingNumberFlag,
  });

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    headers,
    initialFilterState: {},
    initialOrderBy: 'creationDate',
    initialOrder: 'DESC',
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results: workOrders,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP,
  });

  const rows = useRows({
    sortedAndFilteredResults,
    intl,
    assignedToOptions,
    onAssignedToChange,
    onPriorityChange,
    priorityOptions,
    assigneeChangeDisabled,
    priorityChangeDisabled,
    viewWorkOrderDisabled,
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    initialColumnsState: undefined,
    firstRow: rows[0],
  });

  const csvRows = useCSVRows({ sortedAndFilteredResults });
  const handleCSVButtonClick = useHandleCSVButtonClick({
    exportAndDownloadCSV,
    filterState,
    dateState,
    searchState,
    // @ts-ignore
    filteredHeaders,
    csvRows,
  });

  return {
    allColumnsHidden,
    columnOptions,
    handleColumnChange,
    selectedColumns,
    dateState,
    headers: filteredHeaders,
    filterState,
    filterTypeState,
    handleSearchSubmit,
    handleDateSubmit,
    handleFilterChange,
    handleSortChange,
    onCSVButtonClick: handleCSVButtonClick,
    name,
    order,
    orderBy,
    PROPERTY_PATH_MAP,
    rows,
    searchState,
    count: rows.length,
    intl,
    handleFilterTypeChange,
    showSettings: true,
  };
}

export function useManageWorkOrdersV2({ intl }) {
  const { manageWorkOrdersBuildingNumber } = useFlags();
  const selectedProperty = useSelectedProperty();
  const name = useUniqueTableName('manage-work-orders');
  const [
    includeCanceledAndCompletedWorkOrders,
    setIncludeCanceledAndCompletedWorkOrders,
  ] = useState(false);
  const { assignedToOptions, isLoading: isAssignedToOptionsLoading } =
    useAssignedToOptions();
  const { priorityOptions, isLoading: isPriorityOptionsLoading } =
    usePriorityOptions();
  const {
    workOrders,
    isLoading: isWorkOrdersLoading,
    refetch: refreshWorkOrders,
  } = useWorkOrderData({
    includeCanceledAndCompletedWorkOrders,
    assignedToOptions,
  });
  const assigneeChangeDisabled =
    useHasPermission('change-work-order-assignee') === false;
  const priorityChangeDisabled = useHasPermission('workorder-edit') === false;
  const viewWorkOrderDisabled = useHasPermission('workorder-read') === false;

  const handleCreateWorkOrderClick = () => {
    navigateToUrlWithSelectedPropertyId('/add-work-order');
  };

  const handleToggleCanceledAndCompletedWorkOrders = useCallback(() => {
    setIncludeCanceledAndCompletedWorkOrders(
      !includeCanceledAndCompletedWorkOrders,
    );
  }, [includeCanceledAndCompletedWorkOrders]);

  const isLoading =
    isAssignedToOptionsLoading ||
    isPriorityOptionsLoading ||
    isWorkOrdersLoading;

  const props = useManageWorkOrdersTable({
    name,
    workOrders,
    intl,
    assignedToOptions,
    priorityOptions,
    onAssignedToChange: (workOrder, value) => {
      updateWorkOrder({
        detailId: workOrder.dbId,
        field: 'assignedToId',
        value,
        propertyId: selectedProperty.id,
        organizationId: selectedProperty.organizationId,
      }).then(() => refreshWorkOrders());
    },
    onPriorityChange: (workOrder, value) => {
      updateWorkOrder({
        detailId: workOrder.dbId,
        field: 'priorityLevelId',
        value,
        propertyId: selectedProperty.id,
        organizationId: selectedProperty.organizationId,
      }).then(() => refreshWorkOrders());
    },
    exportAndDownloadCSV,
    assigneeChangeDisabled,
    priorityChangeDisabled,
    viewWorkOrderDisabled,
    manageWorkOrdersBuildingNumberFlag: manageWorkOrdersBuildingNumber,
  });

  return {
    name,
    count: props?.rows?.length ?? 0,
    totalCount: workOrders?.length ?? 0,
    isLoading,
    onCreateWorkOrderClick: handleCreateWorkOrderClick,
    exportAndDownloadCSV,
    extraDataToggleProps: {
      isActive: includeCanceledAndCompletedWorkOrders,
      onToggle: handleToggleCanceledAndCompletedWorkOrders,
      text: intl.formatMessage(messages.includeSwitchLabel),
    },
    ...props,
  };
}

function useWorkOrderData({
  includeCanceledAndCompletedWorkOrders,
  assignedToOptions,
}) {
  const selectedProperty = useSelectedProperty();
  const workOrderService = new WorkOrderService();

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'propertyWorkOrders',
      selectedProperty.id,
      includeCanceledAndCompletedWorkOrders ? 'withCanceledAndCompleted' : '',
    ],
    queryFn: () =>
      workOrderService
        .getPropertyWorkOrders({
          propertyId: selectedProperty.id,
          organizationId: selectedProperty.organizationId,
          defaultFilterActive: includeCanceledAndCompletedWorkOrders === false,
        })
        .then((response) => response.results),
    onError: () => {
      toastr.error('Error', 'Failed to load work orders.');
    },
    enabled:
      assignedToOptions &&
      Array.isArray(assignedToOptions) &&
      assignedToOptions.length > 0,
    refetchOnWindowFocus: false,
  });

  return {
    workOrders: useMemo(() => {
      if (isLoading) return [];

      return data.map((workOrder) => {
        const location =
          workOrder.commonAreaLocation?.commonAreaLocationDescription ??
          workOrder.unitNumber;
        const issueDescription = workOrder.issue?.issueDescription ?? '';
        const description = workOrder.otherDescription
          ? `Other - ${workOrder.otherDescription}`
          : issueDescription;
        const finishedDate = workOrder.finishedDate;
        const lastStatusChange = workOrder.lastStatusChangeDate;
        const status = workOrder.status?.statusDescription;
        const dateToCompare =
          status === 'Open' || status === 'On Hold' || status === 'In Progress'
            ? getDateUTC()
            : status === 'Canceled'
            ? lastStatusChange
            : isNil(finishedDate)
            ? lastStatusChange
            : finishedDate;

        const daysOpen = getDateDiffInDays(dateToCompare, workOrder.createdAt);

        const assigneeStatus =
          workOrder.assignedTo?.userStatus?.name ?? 'Inactive';
        const isWithoutAccess =
          isNil(workOrder.assignedTo) ||
          isNil(
            assignedToOptions.find(
              (option) => option.value === workOrder.assignedTo.id,
            ),
          );
        const assigneeInactiveOrWithoutAccess =
          assigneeStatus === 'Inactive' || isWithoutAccess;

        const isActiveWorkOrder =
          status !== 'Completed' && status !== 'Canceled';
        const pastDue =
          getIsPastDueDate(workOrder.dueDate) && isActiveWorkOrder;

        return {
          id: workOrder.detailIdReadable.toString(),
          location,
          buildingNumber: workOrder.buildingNumber,
          issueDescription: description,
          creationDate: workOrder.createdAt,
          assignedToId: workOrder.assignedToId,
          assignedTo: workOrder.assignedTo
            ? `${workOrder.assignedTo.firstName} ${workOrder.assignedTo.lastName}`
            : undefined,
          priorityId: workOrder.priorityLevelId,
          priority: workOrder.priorityLevel?.priorityLevelDescription,
          status,
          daysOpen,
          dueDate: workOrder.dueDate,
          completionDate: finishedDate,
          requestorName: workOrder.requestorName,
          requestorPhone: workOrder.requestorPhone,
          href: `/property/${selectedProperty.id}/edit-work-order/${workOrder.detailId}`,
          assigneeInactiveOrWithoutAccess,
          pastDue,
          dbId: workOrder.detailId,
        };
      });
    }, [assignedToOptions, data, isLoading, selectedProperty.id]),
    isLoading,
    refetch,
  };
}

function useAssignedToOptions() {
  const selectedProperty = useSelectedProperty();
  const userService = new UserService();

  const { data, isLoading } = useQuery({
    queryKey: ['workOrderAssignees', selectedProperty.id],
    queryFn: () =>
      userService.getWorkOrderAssignees(
        selectedProperty.organizationId,
        selectedProperty.id,
      ),
    onError: () => {
      toastr.error('Error', 'Failed to load assignees.');
    },
    refetchOnWindowFocus: false,
  });

  return {
    assignedToOptions: useMemo(() => {
      const options = orderBy(
        isLoading
          ? []
          : data.map((assignee) => ({
              value: assignee.id,
              text: `${assignee.firstName} ${assignee.lastName}`,
            })),
        'text',
        'asc',
      );

      options.unshift({
        ...defaultOption,
      });

      return options;
    }, [data, isLoading]),
    isLoading,
  };
}

function usePriorityOptions() {
  const selectedProperty = useSelectedProperty();
  const priorityLevelService = new PriorityLevelService();

  const { data, isLoading } = useQuery({
    queryKey: ['workOrderPriorityLevels', selectedProperty.organizationId],
    queryFn: () => priorityLevelService.getAll(selectedProperty.organizationId),
    onError: () => {
      toastr.error('Error', 'Failed to load priority levels.');
    },
    refetchOnWindowFocus: false,
  });

  return {
    priorityOptions: useMemo(() => {
      const options = orderBy(
        isLoading
          ? []
          : data.map((priorityLevel) => ({
              value: priorityLevel.priorityLevelId,
              text: priorityLevel.priorityLevelDescription,
            })),
        'text',
        'asc',
      );

      options.unshift({
        ...defaultOption,
      });

      return options;
    }, [data, isLoading]),
    isLoading,
  };
}

function updateWorkOrder({
  detailId,
  field,
  value,
  propertyId,
  organizationId,
}) {
  const service = new WorkOrderService();
  return service
    .update(
      {
        detailId,
        [field]: value,
        propertyId,
      },
      organizationId,
    )
    .then(() => {
      toastr.success('Success', 'Work order updated.');
    })
    .catch(() => {
      toastr.error('Error', 'Failed to update work order.');
    });
}
