const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class WorkOrderPreference {
  search(
    organizationId: string,
    propertyId: string,
    { householdId, unitId }: Object,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const queryString = `unitId=${unitId}&householdId=${householdId}`;
    return request(
      `${apiURL}/${organizationId}/${propertyId}/household/workOrderPreferences/search?${queryString}`,
      options,
    );
  }
  createOrUpdate(
    organizationId: string,
    propertyId: string,
    preferencesBody: Object,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(preferencesBody),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/household/${
        preferencesBody.householdId
      }/workOrderPreference`,
      options,
    );
  }
}

export default WorkOrderPreference;
