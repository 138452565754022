import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.WorkOrder.Title',
    defaultMessage: 'Create Work Order'
  },
  choose: {
    id: 'App.WorkOrder.Choose',
    defaultMessage: 'Choose'
  },
  successHeader: {
    id: 'App.WorkOrder.SuccessHeader',
    defaultMessage: 'Success'
  },
  createdSuccessDescription: {
    id: 'App.WorkOrder.CreatedSuccessDescription',
    defaultMessage: 'The work order was created.'
  },
  editedSuccessDescription: {
    id: 'App.WorkOrder.EditedSuccessDescription',
    defaultMessage: 'The work order was edited.'
  },
  errorHeader: {
    id: 'App.WorkOrder.errorHeader',
    defaultMessage: 'Error'
  },
  cancelConfirmation: {
    id: 'App.WorkOrder.CancelConfirmation',
    defaultMessage:
      'Are you sure you want to discard the information on the form?'
  }
});

export default messages;
