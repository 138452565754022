import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tabs } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { COMPLIANCE_TYPES } from './constantsV2';
import messages from './messages';

const ComplianceOverviewTabs = ({
  complianceType,
  setComplianceType,
  tabs,
}) => {
  const { complianceOverviewReviewedAndNumberOfDays } = useFlags();

  return complianceOverviewReviewedAndNumberOfDays ? (
    <Tabs
      onChange={(_, value) => setComplianceType(value)}
      value={complianceType}
      tabs={tabs}
      sx={{ borderBottom: 1, borderColor: 'divider' }}
    />
  ) : null;
};

ComplianceOverviewTabs.defaultProps = {
  tabs: [
    {
      value: 'none',
      sx: { visibility: 'hidden', width: 0, minWidth: 0, padding: 0 },
    },
    {
      label: <FormattedMessage {...messages.allOpenCerts} />,
      value: COMPLIANCE_TYPES.ALL_OPEN_CERTS,
    },
    {
      label: <FormattedMessage {...messages.needsReview} />,
      value: COMPLIANCE_TYPES.NEEDS_REVIEW,
    },
  ],
};
export default ComplianceOverviewTabs;
