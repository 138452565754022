import store, { history } from '../store';
import { push } from 'react-router-redux';

export const getUrlWithSelectedPropertyId = (url: string) => {
  const selectedPropertyId = store.getState()?.app?.selectedProperty?.id;
  const urlToNavigateTo = selectedPropertyId
    ? `/property/${selectedPropertyId}${url}`
    : url;
  return urlToNavigateTo;
};

// This helper function is for components that dont have the property prop passed to it,
// Ideally we should replace all navigation that includes /property/:propertyId to use this
export const navigateToUrlWithSelectedPropertyId = (
  url: string,
  state?: object,
) => {
  const urlToNavigateTo = getUrlWithSelectedPropertyId(url);
  history.push({ pathname: urlToNavigateTo, state });
};

// This helper function is similar to above but for redux
export const pushWithSelectedPropertyId = (url: string) => {
  const urlToNavigateTo = getUrlWithSelectedPropertyId(url);
  return push(urlToNavigateTo);
};

export const parsePropertyIdFromPath = (pathname: string) => {
  const pathSplit = pathname.split('/');
  if (pathSplit.length < 3) {
    return '';
  }
  if (pathSplit[1] === 'property' && pathSplit[2]) {
    return pathSplit[2];
  }
  return '';
};
