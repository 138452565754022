import { defineMessages } from 'react-intl';

const messages = defineMessages({
  taskSummary: {
    id: 'App.Home.TaskTab.Deprecated.TaskSummary',
    defaultMessage: 'Task Summary',
  },
  legendRed: {
    id: 'App.Home.TaskTab.Deprecated.LegendRed',
    defaultMessage: 'Past Due',
  },
  legendYellow: {
    id: 'App.Home.TaskTab.Deprecated.LegendYellow',
    defaultMessage: 'Unassigned or Assignee no longer has access',
  },
  portalUser: {
    id: 'App.Home.TaskTab.Deprecated.PortalUser',
    defaultMessage: 'Portal',
  },
  residentActivity: {
    id: 'App.Home.TaskTab.Deprecated.ResidentActivity',
    defaultMessage: 'Resident Activity',
  },
  renewalsInProcessTitle: {
    id: 'App.Home.TaskTab.Deprecated.RenewalsInProcess.Title',
    defaultMessage: 'Renewals In Process',
  },
  transfersInProcessTitle: {
    id: 'App.Home.TaskTab.Deprecated.TransfersInProcess.Title',
    defaultMessage: 'Transfers In Process',
  },
  scheduledMoveOutTitle: {
    id: 'App.Home.TaskTab.Deprecated.ScheduledMoveOut.Title',
    defaultMessage: 'Scheduled Move-Outs',
  },
  pendingFASTitle: {
    id: 'App.Home.TaskTab.Deprecated.PendingFAS.Title',
    defaultMessage: 'Prior Resident Final Account Statements Not Generated',
  },
  openWorkOrdersTitle: {
    id: 'App.Home.TaskTab.Deprecated.openWorkOrders.Title',
    defaultMessage: 'Open Work Orders',
  },
  scheduledMoveInsTitle: {
    id: 'App.Home.TaskTab.Deprecated.scheduledMoveIns.Title',
    defaultMessage: 'Approved Applications - Scheduled Move-Ins',
  },
  applicationsInProcessTitle: {
    id: 'App.Home.TaskTab.Deprecated.applicationsInProcess.Title',
    defaultMessage: 'Applications - In Process and New Portal App',
  },
  unitHeader: {
    id: 'App.Home.TaskTab.Deprecated.RenewalsInProcess.UnitHeader',
    defaultMessage: 'Unit',
  },
  numberOfBedroomsHeader: {
    id: 'App.Home.TaskTab.DeprecatedDeprecated.RenewalsInProcess.NumberOfBedroomsHeader',
    defaultMessage: '# of Bedrooms',
  },
  residentHeader: {
    id: 'App.Home.TaskTab.Deprecated.RenewalsInProcess.ResidentHeader',
    defaultMessage: 'Resident',
  },
  leaseExpirationHeader: {
    id: 'App.Home.TaskTab.Deprecated.RenewalsInProcess.ExpirationHeader',
    defaultMessage: 'Lease Expiration',
  },
  assignedToHeader: {
    id: 'App.Home.TaskTab.Deprecated.RenewalsInProcess.AssignedToHeader',
    defaultMessage: 'Assigned To',
  },
  scheduledMoveOutDate: {
    id: 'App.Home.TaskTab.Deprecated.ScheduledMoveOut.AssignedToHeader',
    defaultMessage: 'Scheduled Move-Out Date',
  },
  priorResidentHeader: {
    id: 'App.Home.TaskTab.Deprecated.PendingFAS.priorResidentHeader',
    defaultMessage: 'Prior Resident',
  },
  moveOutDateHeader: {
    id: 'App.Home.TaskTab.Deprecated.PendingFAS.moveOutDateHeader',
    defaultMessage: 'Move-Out Date',
  },
  dueDateHeader: {
    id: 'App.Home.TaskTab.Deprecated.PendingFAS.dueDateHeader',
    defaultMessage: 'Due Date',
  },
  issueHeader: {
    id: 'App.Home.TaskTab.Deprecated.openWorkOrders.issue',
    defaultMessage: 'Issue',
  },
  priorityHeader: {
    id: 'App.Home.TaskTab.Deprecated.openWorkOrders.priorityHeader',
    defaultMessage: 'Priority',
  },
  daysOpenHeader: {
    id: 'App.Home.TaskTab.Deprecated.openWorkOrders.daysOpenHeader',
    defaultMessage: 'Days Open',
  },
  applicantHeader: {
    id: 'App.Home.TaskTab.Deprecated.scheduledMoveIns.applicantHeader',
    defaultMessage: 'Applicant',
  },
  scheduledMoveInDateHeader: {
    id: 'App.Home.TaskTab.Deprecated.scheduledMoveIns.scheduledMoveInDateHeader',
    defaultMessage: 'Scheduled Move-In Date',
  },
  scheduledTransferDateHeader: {
    id: 'App.Home.TaskTab.Deprecated.scheduledTransfers.scheduledTransferDateHeader',
    defaultMessage: 'Scheduled Transfer Date',
  },
  desiredMoveInDateHeader: {
    id: 'App.Home.TaskTab.Deprecated.desiredMoveIns.desiredMoveInDateHeader',
    defaultMessage: 'Desired Move-In Date',
  },
  leasesTitle: {
    id: 'App.Home.TaskTab.Deprecated.Leases.LeasesTitle',
    defaultMessage: 'Leases',
  },
  leasesTeamMemberHeader: {
    id: 'App.Home.TaskTab.Deprecated.Leases.LeasesTeamMemberHeader',
    defaultMessage: 'Team Member',
  },
  leasesGeneratedHeader: {
    id: 'App.Home.TaskTab.Deprecated.Leases.LeasesGeneratedHeader',
    defaultMessage: 'Generated',
  },
  leasesApprovedHeader: {
    id: 'App.Home.TaskTab.Deprecated.Leases.LeasesApprovedHeader',
    defaultMessage: 'Approved',
  },
  leasesDeniedAndCanceledHeader: {
    id: 'App.Home.TaskTab.Deprecated.Leases.LeasesDeniedAndCanceledHeader',
    defaultMessage: 'Denied / Canceled',
  },
  leasesTotalRowLabel: {
    id: 'App.Home.TaskTab.Deprecated.Leases.LeasesTotalRowLabel',
    defaultMessage: 'Team',
  },
  monthToMonthDateOption: {
    id: 'App.Home.TaskTab.Deprecated.Leases.MonthToDateOption',
    defaultMessage: 'Month to Date',
  },
  prospectsFollowUpTitle: {
    id: 'App.Home.TaskTab.Deprecated.ProspectsFollowUp.sectionHeader',
    defaultMessage: 'Prospects - Follow Up',
  },
  daysSinceContactHeader: {
    id: 'App.Home.TaskTab.Deprecated.ProspectsFollowUp.daysSinceHeader',
    defaultMessage: 'Days Since Contact',
  },
  numOfActivitiesHeader: {
    id: 'App.Home.TaskTab.Deprecated.ProspectsFollowUp.numActivitiesHeader',
    defaultMessage: '# of Activities',
  },
  prospectNameHeader: {
    id: 'App.Home.TaskTab.Deprecated.ProspectsFollowUp.prospectNameHeader',
    defaultMessage: 'Name',
  },
  legendGreen: {
    id: 'App.Home.TaskTab.Deprecated.LegendGreen',
    defaultMessage: 'Lease is Ready to be Executed',
  },
});

export default messages;
