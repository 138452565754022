import { defineMessages } from 'react-intl';

const messages = defineMessages({
  addAmenityTabTitle: {
    id: 'Amenity.addAmenityTabTitle',
    defaultMessage: 'Fortress - Add Amenity',
  },
  editAmenityTabTitle: {
    id: 'Amenity.editAmenityTabTitle',
    defaultMessage: 'Fortress - Edit Amenity',
  },
  addAmenityTitle: {
    id: 'Amenity.addAmenityTitle',
    defaultMessage: 'Add New Amenity',
  },
  editAmenityTitle: {
    id: 'Amenity.editAmenityTitle',
    defaultMessage: 'Edit Amenity',
  },
  amenityNameField: {
    id: 'Amenity.amenityNameField',
    defaultMessage: 'Amenity Name',
  },
  amenityNameFieldPlaceholder: {
    id: 'Amenity.amenityNameFieldPlaceholder',
    defaultMessage: 'Amenity Name',
  },
  amenityValueField: {
    id: 'Amenity.amenityValueField',
    defaultMessage: 'Amenity Value',
  },
  assignToUnits: {
    id: 'Amenity.assignToUnits',
    defaultMessage: 'Assign to units',
  },
  availableHeader: {
    id: 'Amenity.availableHeader',
    defaultMessage: 'Available',
  },
  selectedHeader: {
    id: 'Amenity.selectedHeader',
    defaultMessage: 'Assigned',
  },
  availableFooter: {
    id: 'Amenity.availableFooter',
    defaultMessage: 'Available',
  },
  selectedFooter: {
    id: 'Amenity.selectedFooter',
    defaultMessage: 'Selected',
  },
  filterPlaceholder: {
    id: 'Amenity.filterPlaceholder',
    defaultMessage: 'Filter...',
  },
  cancelConfirmText: {
    id: 'Amenity.cancelConfirmText',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  cancelBtn: {
    id: 'Amenity.cancelBtn',
    defaultMessage: 'Cancel',
  },
  saveAndCloseBtn: {
    id: 'Amenity.saveAndCloseBtn',
    defaultMessage: 'Save & Close',
  },
  maxLengthError: {
    id: 'Amenity.maxLengthError',
    defaultMessage: 'Max amount of characters is 80.',
  },
  requiredError: {
    id: 'Amenity.requiredError',
    defaultMessage: 'This field is required',
  },
  minimumValueError: {
    id: 'Amenity.minimumValueError',
    defaultMessage: 'Minimum value is',
  },
  maxValueError: {
    id: 'Amenity.maxValueError',
    defaultMessage: 'Max value is',
  },
  amenityCreatedSuccess: {
    id: 'Amenity.amenityCreatedSuccess',
    defaultMessage: 'Amenity created successfully.',
  },
  amenityUpdatedSuccess: {
    id: 'Amenity.amenityUpdatedSuccess',
    defaultMessage: 'Amenity updated successfully.',
  },
  amenityCreatedError: {
    id: 'Amenity.amenityCreatedError',
    defaultMessage: 'Failed to create amenity.',
  },
  amenityUpdatedError: {
    id: 'Amenity.amenityUpdatedError',
    defaultMessage: 'Failed to update amenity.',
  },
  assignUnitErrorOnCreate: {
    id: 'Amenity.assignUnitError',
    defaultMessage: 'Amenity was created, but failed to assign to units.',
  },
  assignUnitErrorOnUpdate: {
    id: 'Amenity.assignUnitErrorOnUpdate',
    defaultMessage: 'Amenity was  updated, but failed to assign to units.',
  },
  error: {
    id: 'Amenity.error',
    defaultMessage: 'Error',
  },
  success: {
    id: 'Amenity.success',
    defaultMessage: 'Success',
  },
});

export default messages;
