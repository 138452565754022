import {
  Tooltip,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { all, equals, isNil, pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  change,
  Field,
  initialize,
  reduxForm,
  reset,
  submit,
} from 'redux-form';
import Styled from 'styled-components';
import Spinner from '../../../../../components/Spinner';
import type { FeatureFlags } from '../../../../../types';
import {
  renderCheckboxField,
  renderMultiSelectField,
  renderSelectField,
  renderCurrencyField,
  required,
  positive,
} from '../../../../../utils/redux-form-helper';
import {
  useFetchScreeningLetterOptions,
  useFetchConditionsForApprovalOptions,
} from './hooks';
import messages from './messages';
import {
  generateAdverseActionLetterOptions,
  reduceAdverseActionLetterSelectedOptions,
  generateConditionsForApprovalOptions,
} from './utils';

const StyledModalBody = Styled.div`
  padding: 10px;
  overflow-y: visible;

  .react-multi-select__menu {
    margin-top: ${(props) => props.screeningLetterOptionsMarginTop}px;
    top: ${(props) =>
      props.screeningLetterOptionsMarginTop === 0
        ? `-${props.screeningLetterOptionsHeight}px`
        : '100%'};
  }
`;

const StyledDiv = Styled.div`
  padding: 10px 20px;
  position: ${(props) =>
    props.isMoreThanOneOptionVisible ? 'relative' : 'absolute'};
  bottom: 0;
`;

const initialValues = {
  creditHistory: true,
  criminalHistory: true,
  evictionHistory: true,
  incomeHistory: true,
  other: false,
  otherScreeningOptions: null,
};

const onAfterSubmit = (result, dispatch) => {
  dispatch(reset('adverseActionLetter'));
};

const APPROVED_WITH_CONDITIONS = 'Approved with Conditions';

const AdverseActionModal = ({
  intl,
  screeningResultReasons,
  adverseActionDeadlineDays,
  show,
  valid,
  toggleClose,
  promptToaster,
  dispatch,
  onSubmit,
  finalScreeningStatusResultMessage,
}: Object) => {
  const { isLoadingLetterOptions, screeningLetterOptions } =
    useFetchScreeningLetterOptions(show, promptToaster);
  const { isLoadingConditionsForApproval, conditionsForApprovalOptions } =
    useFetchConditionsForApprovalOptions(show, promptToaster);

  const isLoading = isLoadingLetterOptions && isLoadingConditionsForApproval;

  const {
    adverseActionLetterEmail,
    adverseActionLetter,
    adverseActionLetterApprovalConditions,
  }: FeatureFlags = useFlags();

  const showConditionsForApproval =
    adverseActionLetterApprovalConditions &&
    finalScreeningStatusResultMessage === APPROVED_WITH_CONDITIONS;

  const [otherMultiSelectDisabled, setOtherMultiSelectDisabled] =
    useState(true);
  const [otherLetterSelectedOptions, setOtherLetterSelectedOptions] = useState(
    [],
  );
  const [
    conditionsForApprovalAmountDisabled,
    setConditionsForApprovalAmountDisabled,
  ] = useState(true);
  const [conditionForApprovalSelected, setConditionForApprovalSelected] =
    useState(null);

  const [disableCreate, setDisableCreate] = useState(false);

  const [calculatedMultiSelectHeight, setCalculatedMultiSelectHeight] =
    useState(300);
  const [calculatedMultiSelectMarginTop, setCalculatedMultiSelectMarginTop] =
    useState(8);

  const showCredit =
    !isNil(screeningResultReasons.creditCode) &&
    screeningResultReasons.creditCode !== 1;

  const showCriminal =
    !isNil(screeningResultReasons.criminalCode) &&
    screeningResultReasons.criminalCode !== 1;

  const showEviction =
    !isNil(screeningResultReasons.evictionCode) &&
    screeningResultReasons.evictionCode !== 1;

  const showIncome =
    !isNil(screeningResultReasons.incomeCode) &&
    screeningResultReasons.incomeCode !== 1;

  const [screeningSelectValues, setScreeningSelectValues] = useState({
    creditHistory: showCredit,
    criminalHistory: showCriminal,
    evictionHistory: showEviction,
    incomeHistory: showIncome,
  });

  useEffect(() => {
    initialize('adverseActionLetter', initialValues);
    setOtherLetterSelectedOptions([]);
  }, []);

  useEffect(() => {
    const { creditHistory, criminalHistory, evictionHistory, incomeHistory } =
      screeningSelectValues;
    const values = [
      showCredit && creditHistory,
      showCriminal && criminalHistory,
      showEviction && evictionHistory,
      showIncome && incomeHistory,
    ];
    const areAllResultsDeselected = all(equals(false))(values);
    const areOtherOptionsSelected =
      !otherMultiSelectDisabled &&
      pathOr(0, ['length'], otherLetterSelectedOptions) > 0;
    setDisableCreate(areAllResultsDeselected && !areOtherOptionsSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    screeningSelectValues,
    otherMultiSelectDisabled,
    otherLetterSelectedOptions,
  ]);

  useEffect(() => {
    //8 = padding of list container;=, 16 = padding of each row, 18 = row height, 8 margin;
    const renderAbove =
      [showCredit, showCriminal, showEviction, showIncome].filter(
        (result) => result,
      ).length > 1;
    const currentAvailableOptions =
      screeningLetterOptions.length -
      pathOr(0, ['length'], otherLetterSelectedOptions);
    const otherOptionsHeight =
      currentAvailableOptions > 0
        ? 8 + currentAvailableOptions * (16 + 18) + 8
        : 50;
    setCalculatedMultiSelectHeight(
      otherOptionsHeight > 300 ? 300 : otherOptionsHeight,
    );
    setCalculatedMultiSelectMarginTop(!renderAbove ? 8 : 0);
  }, [
    screeningLetterOptions,
    otherLetterSelectedOptions,
    showCredit,
    showCriminal,
    showEviction,
    showIncome,
  ]);

  const handleClose = () => {
    dispatch(reset('adverseActionLetter'));
    setOtherMultiSelectDisabled(true);
    setConditionsForApprovalAmountDisabled(true);
    setOtherLetterSelectedOptions([]);
    toggleClose();
  };

  const handleOnSubmit = () => {
    dispatch(submit('adverseActionLetter'));
    setOtherMultiSelectDisabled(true);
    setConditionsForApprovalAmountDisabled(true);
    setOtherLetterSelectedOptions([]);
  };

  const onOtherChange = (event: Object, newValue: boolean) => {
    setOtherMultiSelectDisabled(!newValue);
  };

  const onConditionForApprovalChange = (e) => {
    const selectedValue = e.target.value;
    setConditionForApprovalSelected(selectedValue);
    const selectedValueNeedsAmount = conditionsForApprovalOptions.find(
      (i) => i.id === selectedValue,
    );
    const requiresAmount = selectedValueNeedsAmount?.requiresAmount;
    setConditionsForApprovalAmountDisabled(!requiresAmount);
    if (!requiresAmount) {
      dispatch(
        change(
          'adverseActionLetter',
          'conditionsforApprovalAmount',
          null,
          true,
        ),
      );
    }
    dispatch(
      change(
        'adverseActionLetter',
        'conditionsForApproval',
        conditionForApprovalSelected,
        true,
      ),
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <Modal backdrop show={show} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <i className="icon et-certified" />
          <Modal.Title componentClass="h1">
            <FormattedMessage {...messages.title} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <StyledModalBody
                screeningLetterOptionsHeight={calculatedMultiSelectHeight}
                screeningLetterOptionsMarginTop={calculatedMultiSelectMarginTop}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    className={'text-left'}
                    style={{
                      paddingLeft: '26px', // To align with footer note.
                    }}
                  >
                    <Typography variant="h2" fontSize={'18px'}>
                      <FormattedMessage {...messages.reasons} />
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={'text-right'}
                    style={{
                      paddingRight: '26px', // To align with footer note.
                    }}
                  >
                    {adverseActionLetter && [
                      <Typography variant="body1" component={'span'}>
                        <FormattedMessage {...messages.offerExpires} />
                      </Typography>,
                      <Typography variant="body2" component={'span'}>
                        {`${adverseActionDeadlineDays ?? 10} `}
                      </Typography>,
                      <Typography variant="body1" component={'span'}>
                        <FormattedMessage {...messages.days} />
                      </Typography>,
                      <Tooltip
                        variant="light"
                        arrow
                        placement="top"
                        title={intl.formatMessage(
                          messages.adverseDeadlineDaysNote,
                        )}
                        style={{ display: 'inline-block', marginLeft: '8px' }}
                      >
                        <i
                          style={{ position: 'relative', top: '1px' }}
                          className="icon et-alert-info"
                        />
                      </Tooltip>,
                    ]}
                  </Grid>

                  <Grid item xs={1} />
                  <Grid item xs={10}>
                    {showCredit && (
                      <Field
                        name="creditHistory"
                        component={renderCheckboxField}
                        label={intl.formatMessage(messages.creditHistory, {
                          creditMessage: screeningResultReasons.creditMessage,
                        })}
                        onChange={(event: Object, newValue: any) => {
                          dispatch(
                            change(
                              'adverseActionLetter',
                              'creditHistory',
                              newValue,
                              true,
                            ),
                          );
                          setScreeningSelectValues({
                            ...screeningSelectValues,
                            creditHistory: newValue,
                          });
                        }}
                      />
                    )}
                    {showCriminal && (
                      <Field
                        name="criminalHistory"
                        component={renderCheckboxField}
                        label={intl.formatMessage(messages.criminalHistory, {
                          criminalMessage:
                            screeningResultReasons.criminalMessage,
                        })}
                        onChange={(event: Object, newValue: any) => {
                          dispatch(
                            change(
                              'adverseActionLetter',
                              'criminalHistory',
                              newValue,
                              true,
                            ),
                          );
                          setScreeningSelectValues({
                            ...screeningSelectValues,
                            criminalHistory: newValue,
                          });
                        }}
                      />
                    )}
                    {showEviction && (
                      <Field
                        name="evictionHistory"
                        component={renderCheckboxField}
                        label={intl.formatMessage(messages.evictionHistory, {
                          evictionMessage:
                            screeningResultReasons.evictionMessage,
                        })}
                        onChange={(event: Object, newValue: any) => {
                          dispatch(
                            change(
                              'adverseActionLetter',
                              'evictionHistory',
                              newValue,
                              true,
                            ),
                          );
                          setScreeningSelectValues({
                            ...screeningSelectValues,
                            evictionHistory: newValue,
                          });
                        }}
                      />
                    )}
                    {showIncome && (
                      <Field
                        name="incomeHistory"
                        component={renderCheckboxField}
                        label={intl.formatMessage(messages.incomeHistory, {
                          incomeMessage: screeningResultReasons.creditMessage,
                          incomeDescription:
                            screeningResultReasons.incomeDescription || '',
                        })}
                        onChange={(event: Object, newValue: any) => {
                          dispatch(
                            change(
                              'adverseActionLetter',
                              'incomeHistory',
                              newValue,
                              true,
                            ),
                          );
                          setScreeningSelectValues({
                            ...screeningSelectValues,
                            incomeHistory: newValue,
                          });
                        }}
                      />
                    )}
                    {screeningLetterOptions.length > 0 && (
                      <Grid container>
                        <Grid item xs={2}>
                          <Field
                            name="other"
                            component={renderCheckboxField}
                            label={intl.formatMessage(messages.other)}
                            onChange={onOtherChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            name="otherScreeningOptions"
                            disabled={otherMultiSelectDisabled}
                            component={renderMultiSelectField}
                            options={generateAdverseActionLetterOptions(
                              screeningLetterOptions,
                              intl.locale,
                            )}
                            onChangeCallback={(val) => {
                              dispatch(
                                change(
                                  'adverseActionLetter',
                                  'otherScreeningOptions',
                                  reduceAdverseActionLetterSelectedOptions(val),
                                  true,
                                ),
                              );
                              setOtherLetterSelectedOptions(
                                reduceAdverseActionLetterSelectedOptions(val),
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {showConditionsForApproval && (
                    <Grid container rowSpacing={2}>
                      <Grid
                        item
                        xs={6}
                        className={'text-left'}
                        style={{
                          paddingLeft: '26px', // To align with footer note.
                        }}
                      >
                        <Typography variant="h2" fontSize={'18px'}>
                          <FormattedMessage {...messages.conditions} />
                        </Typography>
                      </Grid>
                      <Grid item xs={6} />
                      <Grid item xs={1} />
                      <Grid
                        item
                        xs={8}
                        className={'text-right'}
                        style={{
                          paddingRight: '26px', // To align with footer note.
                        }}
                      >
                        <Field
                          name="conditionsForApproval"
                          disabled={false}
                          component={renderSelectField}
                          options={generateConditionsForApprovalOptions(
                            conditionsForApprovalOptions,
                            intl.locale,
                          )}
                          onChange={(val) => {
                            onConditionForApprovalChange(val);
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Field
                          className="input-md form-control"
                          name="conditionsforApprovalAmount"
                          component={renderCurrencyField}
                          step=".01"
                          disabled={conditionsForApprovalAmountDisabled}
                          validate={
                            conditionsForApprovalAmountDisabled
                              ? []
                              : [required, positive]
                          }
                        />
                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                  )}
                </Grid>
              </StyledModalBody>
              {adverseActionLetterEmail && (
                <StyledDiv
                  isMoreThanOneOptionVisible={
                    showCredit ||
                    showCriminal ||
                    showEviction ||
                    showIncome ||
                    showConditionsForApproval
                  }
                >
                  <span className="font-bold">
                    <FormattedMessage {...messages.note} />:{' '}
                  </span>
                  <FormattedMessage {...messages.noteMessage} />
                </StyledDiv>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-xs-6">
              <button
                className="btn btn-default pull-right"
                onClick={() => handleClose()}
              >
                <FormattedMessage {...messages.cancel} />
              </button>
            </div>
            <div className="col-xs-6">
              <button
                className="btn btn-primary pull-left"
                type="submit"
                disabled={disableCreate || !valid}
                onClick={handleOnSubmit}
              >
                <FormattedMessage {...messages.create} />
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </form>
  );
};

// $FlowFixMe
export default connect()(
  reduxForm({
    form: 'adverseActionLetter',
    enableReinitialize: true,
    initialValues,
    onSubmitSuccess: onAfterSubmit,
  })(AdverseActionModal),
);
