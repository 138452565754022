import { defaultTo } from 'ramda';
import { validateRequired } from '../../../utils/validations';

import formMessages from '../../ShortLeaseApplication/messages';

import type { SecundaryFormAdditionalOccupants } from '../../App/types';

const warn = (
  AdditionalOccupantsInfo: SecundaryFormAdditionalOccupants,
  { intl }: Object
) => {
  const warnings = {};

  const values = defaultTo({})(AdditionalOccupantsInfo);
  if (!validateRequired('anyAdult', values)) {
    warnings.anyAdult = intl.formatMessage(formMessages.requiredForCompletion);
  }
  if (!validateRequired('anyMinor', values)) {
    warnings.anyMinor = intl.formatMessage(formMessages.requiredForCompletion);
  }

  return warnings;
};

export default warn;
