import { defaultTo } from 'ramda';
import { validateRequired } from '../../../utils/validations';

import formMessages from '../../ShortLeaseApplication/messages';

import type { SecundaryFormOtherInformation } from '../../App/types';

const warn = (
  otherInformationInfo: SecundaryFormOtherInformation,
  { intl, propertyState, propertyCity, flags }: Object,
) => {
  const warnings = {};
  const values = defaultTo({})(otherInformationInfo);
  const showCriminalQuestion = propertyCity !== 'Seattle';

  ['pets', 'bankruptcy']
    .concat(showCriminalQuestion ? ['convicted'] : [])
    .forEach((fieldName) => {
      if (!validateRequired(fieldName, values)) {
        warnings[fieldName] = intl.formatMessage(
          formMessages.requiredForCompletion,
        );
      }
    });

  if (
    showCriminalQuestion &&
    values.convicted === 'yes' &&
    !validateRequired('convictedDescription', values)
  ) {
    warnings.convictedDescription = intl.formatMessage(
      formMessages.requiredForCompletion,
    );
  }
  if (
    values.bankruptcy === 'yes' &&
    !validateRequired('bankruptcyDescription', values)
  ) {
    warnings.bankruptcyDescription = intl.formatMessage(
      formMessages.requiredForCompletion,
    );
  }
  if (
    values.bankruptcy === 'yes' &&
    !validateRequired('bankruptcyCaseFiled', values)
  ) {
    warnings.bankruptcyCaseFiled = intl.formatMessage(
      formMessages.requiredForCompletion,
    );
  }
  if (
    values.bankruptcy === 'yes' &&
    !validateRequired('bankruptcyDateFiled', values)
  ) {
    warnings.bankruptcyDateFiled = intl.formatMessage(
      formMessages.requiredForCompletion,
    );
  }
  if (
    values.bankruptcy === 'yes' &&
    !validateRequired('bankruptcyDischarged', values)
  ) {
    warnings.bankruptcyDischarged = intl.formatMessage(
      formMessages.requiredForCompletion,
    );
  }

  return warnings;
};

export default warn;
