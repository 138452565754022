import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.Title',
    defaultMessage: 'Adverse Action Letter',
  },
  reasons: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.Reasons',
    defaultMessage: 'Reasons for Screening Result',
  },
  creditHistory: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.CreditHistory',
    defaultMessage: 'Credit History - {creditMessage}',
  },
  criminalHistory: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.CriminalHistory',
    defaultMessage: 'Criminal History - {criminalMessage}',
  },
  evictionHistory: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.EvictionHistory',
    defaultMessage: 'Eviction History - {evictionMessage}',
  },
  incomeHistory: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.IncomeHistory',
    defaultMessage: 'Income History - {incomeMessage} - {incomeDescription}',
  },
  other: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.Other',
    defaultMessage: 'Other',
  },
  cancel: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.Cancel',
    defaultMessage: 'Cancel',
  },
  create: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.Create',
    defaultMessage: 'Create',
  },
  note: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.Note',
    defaultMessage: 'Note',
  },
  noteMessage: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.NoteMessage',
    defaultMessage:
      'When you create this letter, a copy will be sent to each email address on file for this household.',
  },
  offerExpires: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.OfferExpires',
    defaultMessage: 'This offer expires in ',
  },
  days: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.Days',
    defaultMessage: 'day(s)',
  },
  adverseDeadlineDaysNote: {
    // eslint-disable-next-line max-len
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.AdverseDeadlineDaysNote',
    defaultMessage:
      'This expiration limit is set as the default for your property and can only be changed by your administrator.',
  },
  conditions: {
    // eslint-disable-next-line max-len
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.AdverseActionLetterModal.Conditions',
    defaultMessage: 'Conditions for Approval',
  },
});

export default messages;
