import { useEffect, useRef, useState, useMemo } from 'react';
import * as R from 'ramda';

import LeaseExpirationLimitService from '../../services/leaseExpirationLimitService';
import messages from './messages';

export const useLeaseExpirationLimitsGraphData = (
  organizationId: string,
  propertyId: string,
  toasterFn: Function,
  formatMessage: Function,
) => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const isDataReady = useMemo(
    () => !R.isNil(graphData) && !isLoading,
    [graphData, isLoading],
  );

  const getLeaseExpirationLimitsGraphData = async (years: number[]) => {
    const leaseExpirationLimitService = new LeaseExpirationLimitService();

    try {
      safeSet(setIsLoading)(true);
      const response =
        await leaseExpirationLimitService.getLeaseExpirationLimitsGraphData(
          organizationId,
          propertyId,
          years,
        );

      if (response) {
        safeSet(setGraphData)(response);
      }
    } catch {
      toasterFn({
        type: 'error',
        message: formatMessage(messages.graphDataRequestError),
        title: formatMessage(messages.graphDataRequestErrorTitle),
      });
    } finally {
      safeSet(setIsLoading)(false);
    }
  };

  const safeSet = (setFn: Function) => (value: string) => {
    if (isMounted.current) {
      setFn(value);
    }
  };

  return [graphData, isDataReady, getLeaseExpirationLimitsGraphData];
};
