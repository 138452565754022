import { FormattedMessage } from 'react-intl';

import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

export const HEADERS = [
  {
    id: 'name',
    title: <FormattedMessage {...messages.groupName} />,
  },
  {
    id: 'description',
    title: <FormattedMessage {...messages.description} />,
  },
  {
    id: 'numProperties',
    title: <FormattedMessage {...messages.numberOfProperties} />,
  },
  {
    id: 'createdByOn',
    title: <FormattedMessage {...messages.createdByOn} />,
  },
  {
    id: 'lastModifiedByOn',
    title: <FormattedMessage {...messages.lastModifiedByOn} />,
  },
  {
    id: 'editView',
    title: <FormattedMessage {...messages.editView} />,
    order: null,
  },
];
