import type { User } from '../../App/types';
import messages from './messages';

const validateOnlyLettersAndNumbers = (intl, name, text) => {
  if (name && !/^[a-zA-Z][a-zA-Z0-9 ]*$/i.test(name)) {
    return intl.formatMessage(text);
  }
};

const validateOnlyLettersAndNumbersWithNoSpaces = (intl, name, text) => {
  if (name && !/^[a-zA-Z][a-zA-Z0-9]*$/i.test(name)) {
    return intl.formatMessage(text);
  }
};

const validateEmail = (intl, email) => {
  if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return intl.formatMessage(messages.invalidEmailAddress);
  }
};

const validate = (values: User, { intl }: Object) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = intl.formatMessage(messages.requiredFields);
  }

  if (!values.lastName) {
    errors.lastName = intl.formatMessage(messages.requiredFields);
  }

  if (!values.emailAddress) {
    errors.emailAddress = intl.formatMessage(messages.requiredFields);
  }

  if (!values.username) {
    errors.username = intl.formatMessage(messages.requiredFields);
  }

  if (!values.userStatusId || values.userStatusId === 'default') {
    errors.userStatusId = intl.formatMessage(messages.requiredFields);
  }

  if (!values.userTitleId || values.userTitleId === 'default') {
    errors.userTitleId = intl.formatMessage(messages.requiredFields);
  }

  if (!values.userRoleId || values.userRoleId === 'default') {
    errors.userRoleId = intl.formatMessage(messages.requiredFields);
  }

  if (!errors.firstName) {
    errors.firstName = validateOnlyLettersAndNumbers(intl, values.firstName, messages.invalidName);
  }

  if (!errors.lastName) {
    errors.lastName = validateOnlyLettersAndNumbers(intl, values.lastName, messages.invalidName);
  }

  if (!errors.emailAddress) {
    errors.emailAddress = validateEmail(intl, values.emailAddress);
  }

  if (!errors.username) {
    errors.username = validateOnlyLettersAndNumbersWithNoSpaces(intl, values.username, messages.invalidUsername);
  }

  return errors;
};

export default validate;
