import React, { Component } from 'react';
import { findIndex, omit, empty, sortBy } from 'ramda';
import { Link } from 'react-router-dom';
import { Collapse, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { TextingIndicator } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import messages from '../messages';
import ManageFormsDropDown from '../../../components/ManageFormsDropDown';
import Status from '../Status';
import ActionsMenu from '../ActionsMenu';
import type { Prospect } from '../../ProspectProfile/types';
import RelativeDateField from '../../../components/RelativeDateField';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import { formatNBedsArr } from '../../../utils/prospectPreferences-helpers';
import { formatDateUTC } from '../../../utils/date-helpers';
import { getDesiredMoveInDate } from '../utils';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

type Props = {
  prospects: Array<Prospect>,
  onAssigneeChange: Function,
  onStatusChange: Function,
  onRecordActivity: Function,
  onScheduleActivity: Function,
  onCreateActivity: Function,
  onViewProspect: Function,
  onEditActivity: Function,
  users: any,
  intl: Object,
};

type State = {
  toggles: Object,
};

class ProspectDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onToggleClick.bind(this);
    this.renderProspectDetailRow.bind(this);
    this.renderProspectToggleDetail.bind(this);
    this.renderProspectToggleHeader.bind(this);
    this.renderLastActivity.bind(this);
    this.renderNextActivity.bind(this);
    this.state = { toggles: {} };
  }

  formatPhone(phoneNumber: string) {
    return phoneNumber
      ? `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(
          3,
          3,
        )}-${phoneNumber.substr(6, 4)}`
      : '---';
  }

  onToggleClick(prospect: Prospect) {
    const id = prospect.id || '';
    const newStateToggles = omit([id], this.state.toggles);
    newStateToggles[id] = !this.state.toggles[id];
    this.setState({
      toggles: newStateToggles,
    });
  }

  getNextActivityClass(prospect: Prospect) {
    if (prospect.nextActivity && !empty(prospect.nextActivity.startTime)) {
      const currentTime = new Date();
      const startTime = new Date(prospect.nextActivity.startTime);
      if (startTime.getTime() < currentTime.getTime()) {
        return 'has-error';
      }
    }
  }

  addProspect(field: string, prospect: Prospect) {
    return Object.assign({}, prospect[field], { prospect });
  }

  renderLastActivity(prospect: Prospect) {
    return (
      prospect.lastActivity &&
      prospect.lastActivity.startTime && (
        <ElementWithPermissions
          scope={['activity-read', 'activity-update', 'activity-delete']}
        >
          <a
            onClick={this.props.onEditActivity(
              this.addProspect('lastActivity', prospect),
            )}
          >
            <RelativeDateField value={prospect.lastActivity.startTime} />
          </a>
        </ElementWithPermissions>
      )
    );
  }

  renderNextActivity(prospect: Prospect) {
    return (
      prospect.nextActivity &&
      prospect.nextActivity.startTime && (
        <ElementWithPermissions
          scope={['activity-read', 'activity-update', 'activity-delete']}
        >
          <a
            onClick={this.props.onEditActivity(
              this.addProspect('nextActivity', prospect),
            )}
            className={this.getNextActivityClass(prospect)}
          >
            <RelativeDateField value={prospect.nextActivity.startTime} />
          </a>
        </ElementWithPermissions>
      )
    );
  }

  renderProspectDetailRow(prospect: Prospect, className: string) {
    const id = prospect.id || '';
    const color = prospect.currentProspectStatus
      ? prospect.currentProspectStatus.color
      : 'gray';
    const statusName = prospect.currentProspectStatus
      ? prospect.currentProspectStatus.name
      : '';
    const tooltip = <Tooltip id="tooltip">{statusName}</Tooltip>;
    const assignedToId = prospect.assignedTo ? prospect.assignedTo.id : '';

    const assignee = prospect.assignedTo
      ? prospect.assignedTo
      : { firstName: '', lastName: '' };
    const assigneeIndex = findIndex((x) => x.value === assignedToId)(
      this.props.users,
    );
    const backgroundColor = assigneeIndex < 0 ? '#FFF1DB' : null;
    const assignees =
      assigneeIndex < 0
        ? sortBy((x) => x.text.toLowerCase())(
            [
              {
                value: assignedToId,
                text: `${assignee.firstName} ${assignee.lastName}`,
                disabled: false,
              },
            ].concat(this.props.users),
          )
        : this.props.users;
    const { moveInDateFrom } = getDesiredMoveInDate({
      prospectPreferences: prospect?.prospectPreferences,
    });
    const householdConversationsStatus =
      this.props.householdConversationsStatusDictionary[prospect.id];
    return (
      <tr key={`${id}-RW`} className={className} style={{ backgroundColor }}>
        <td>
          <span className="row-detail">Status:</span>
          <OverlayTrigger
            container={window.document.getElementById('table-row')}
            placement="right"
            delayHide={200}
            trigger={['hover', 'focus']}
            overlay={tooltip}
          >
            <span>
              <Status color={color} />
            </span>
          </OverlayTrigger>
        </td>
        <td className="hidden-xs">
          <a onClick={this.props.onViewProspect(prospect)}>
            {prospect.lastName}
            {!prospect.lastName && '---'}{' '}
          </a>
        </td>
        <td className="hidden-xs">
          {prospect.firstName}
          {!prospect.firstName && '---'}{' '}
        </td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.phoneNumber)}:
          </span>
          {this.formatPhone(prospect.phoneNumber)}
        </td>
        {this.props.showTextingColumn && (
          <td>
            {householdConversationsStatus.TOTAL > 0 ? (
              <Link
                to={getUrlWithSelectedPropertyId(
                  `/prospect/${prospect.id}?tab=texting`,
                )}
              >
                <TextingIndicator {...householdConversationsStatus} />
              </Link>
            ) : (
              <TextingIndicator {...householdConversationsStatus} />
            )}
          </td>
        )}
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.email)}:
          </span>
          {prospect.emailAddress && (
            <a href={`mailto:${prospect.emailAddress}`}>
              {prospect.emailAddress}{' '}
            </a>
          )}
          {!prospect.emailAddress && '---'}
        </td>
        <td>
          {prospect.createdAt && (
            <RelativeDateField value={prospect.createdAt} />
          )}
          {!prospect.createdAt && '---'}
        </td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.lastFollowUp)}:
          </span>
          {this.renderLastActivity(prospect) || '---'}
        </td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.nextFollowUp)}:
          </span>
          {this.renderNextActivity(prospect) || '---'}
        </td>
        {this.props.flags.assigningUnitsMoveInDates ? (
          <td>
            {moveInDateFrom ? (
              <RelativeDateField value={formatDateUTC(moveInDateFrom)} />
            ) : (
              '---'
            )}
          </td>
        ) : (
          <td>
            {prospect.prospectPreferences &&
              prospect.prospectPreferences.moveInDateFrom && (
                <RelativeDateField
                  value={new Date(prospect.prospectPreferences.moveInDateFrom)}
                />
              )}
            {(!prospect.prospectPreferences ||
              !prospect.prospectPreferences.moveInDateFrom) &&
              '---'}
          </td>
        )}
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.nBeds)}:
          </span>
          {this.props.propertySettingApplyWithoutAUnit
            ? formatNBedsArr(
                prospect?.prospectPreferences?.nBedsArr,
                prospect?.prospectPreferences?.nBeds,
              )
            : prospect?.prospectPreferences?.nBeds ?? '---'}
        </td>
        <td className="dropdown">
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.assignedTo)}:
          </span>
          <ManageFormsDropDown
            items={assignees}
            changeFunction={this.props.onAssigneeChange(prospect)}
            initialValue={assignedToId}
            scope={['prospect-assign']}
          />
        </td>
        <td className="dropdown">
          <ActionsMenu
            onStatusChange={this.props.onStatusChange(prospect)}
            onCreateActivity={this.props.onCreateActivity(prospect)}
            onRecordActivity={this.props.onRecordActivity(prospect)}
            onScheduleActivity={this.props.onScheduleActivity(prospect)}
          />
        </td>
      </tr>
    );
  }

  renderProspectToggleDetail(prospect: Prospect) {
    const id = prospect.id || '';
    return (
      <Collapse key={`${id}-DT`} in={this.state.toggles[id]}>
        {this.renderProspectDetailRow(
          prospect,
          'table-section hidden-sm hidden-md hidden-lg',
        )}
      </Collapse>
    );
  }

  renderProspectToggleHeader(prospect: Prospect) {
    const id = prospect.id || '';
    const className = this.state.toggles[id] ? 'expanded' : 'collapsed';
    return (
      <tr
        key={`${id}-HD`}
        className="visible-xs table-section"
        onClick={() => this.onToggleClick(prospect)}
      >
        <th className={`visible-xs clickable ${className}`}>
          {prospect.lastName}, {prospect.firstName}
        </th>
      </tr>
    );
  }

  render() {
    return (
      <tbody>
        {this.props.prospects.reduce((previous, prospect) => {
          return previous.concat([
            this.renderProspectToggleHeader(prospect),
            this.renderProspectToggleDetail(prospect),
            this.renderProspectDetailRow(prospect, 'table-section hidden-xs'),
          ]);
        }, [])}
      </tbody>
    );
  }
}

export default withLDConsumer()(ProspectDetails);
