import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Fields } from 'redux-form';
import { prop, toLower, compose, sortBy, concat, curryN } from 'ramda';
import PetIndividual from './PetIndividual';
import messages from './messages';

type ComponentProps = {
  intl: any,
  petTypes: Array<Object>,
  petBreeds: any,
  pets: Array<Object>,
  fields: Fields,
  meta: {
    error: Object,
  },
};
const PetRows = ({
  fields,
  meta: { error },
  intl,
  petTypes,
  petBreeds,
  pets,
}: ComponentProps) => {
  const RenderRowHeaders =
    fields.length > 0 ? (
      <Row>
        <div className="labels-head container-of-inputs">
          <Col xs={6} sm={2}>
            <label>
              <FormattedMessage {...messages.name} />
            </label>
          </Col>
          <Col xs={6} sm={2}>
            <label>
              <FormattedMessage {...messages.pet} />
            </label>
          </Col>
          <Col xs={6} sm={2}>
            <label>
              <FormattedMessage {...messages.type} />
            </label>
          </Col>
          <Col xs={6} sm={1}>
            <label>
              <FormattedMessage {...messages.weight} />
            </label>
          </Col>
          <Col xs={6} sm={2}>
            <label>
              <FormattedMessage {...messages.petTag} />
            </label>
          </Col>
          <Col xs={6} sm={1}>
            <label>
              <FormattedMessage {...messages.serviceAnimal} />
            </label>
          </Col>
        </div>
        <div className="container-of-trash">
          <div className="row-remove-btn">
            <label>
              <FormattedMessage {...messages.delete} />
            </label>
          </div>
        </div>
      </Row>
    ) : null;

  const emptyField = [
    {
      value: '',
      text: intl.formatMessage(messages.petsChooseOption),
      disabled: true,
    },
  ];
  const sortPetsCallback = sortBy(
    compose(
      toLower,
      prop('text'),
    ),
  );
  const sortedPetTypes = petTypes
    ? concat(emptyField, sortPetsCallback(petTypes))
    : [];
  const getbreeds = (currentPet: Object) => {
    let breeds = [];
    if (currentPet && currentPet.petTypeId) {
      breeds = petBreeds[currentPet.petTypeId];
    }
    return concat(emptyField, sortPetsCallback(breeds));
  };
  const fieldRemoveCurried = curryN(2, fields.remove);
  const RenderRows =
    fields && fields.length > 0
      ? fields.map((pet, index) => {
          const currentPet = pets[index];
          const breeds = getbreeds(currentPet);

          return (
            <PetIndividual
              intl={intl}
              key={index}
              pet={pet}
              onDelete={fieldRemoveCurried(index)}
              sortedPetTypes={sortedPetTypes}
              currentPet={currentPet}
              breeds={breeds}
            />
          );
        })
      : null;

  return (
    <div>
      {RenderRowHeaders}
      {RenderRows}
      {fields.length < 9 && (
        <Row>
          <a className="btn-text" onClick={() => fields.push()}>
            <FormattedMessage {...messages.addDetail} />
          </a>
        </Row>
      )}
    </div>
  );
};

export default PetRows;
