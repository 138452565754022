import React from 'react';
import { IntlShape, injectIntl, FormattedMessage } from 'react-intl';
import messages from './messages';
import {
  Typography,
  Box,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';

type Props = {
  isLoading: boolean,
  smartPricingEnabled: boolean,
  smartPriceLastUpdate: stringOrDate,
  intl: IntlShape,
};

function SmartPriceBanner(props: Props) {
  if (!props.isLoading && props.smartPricingEnabled) {
    return (
      <Grid
        item
        directin="row"
        xs={4}
        paddingRight={5}
        sx={{ display: 'flex', justifyContent: 'end' }}
      >
        <Box
          sx={{
            position: 'relative',
            borderRadius: 0,
          }}
        >
          <Typography variant="h4">
            <FormattedMessage {...messages.smartPriceLastUpdate} />{' '}
            <>
              {props.intl.formatDate(props.smartPriceLastUpdate, {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
              })}
            </>
          </Typography>
        </Box>
      </Grid>
    );
  } else return null;
}

export default injectIntl(SmartPriceBanner);
