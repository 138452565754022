import type { StatusChange } from '../types';

import messages from './messages';

const validate = (values: { statusChange: StatusChange }, { intl, requiresNote }: Object) => {
  const errors = {
    statusChange: {}
  };
  if (requiresNote && !values.statusChange.notes) {
    errors.statusChange.notes = intl.formatMessage(messages.notesRequired);
  }
  return errors;
};

export default validate;
