import React from 'react';
import GenericIntegrationSetup from '../GenericIntegration';

const MunibillingSetup = (props: Props) => {
  const { editMode, onChange, orgIntegrations, propertyIntegrations } = props;
  return (
    <GenericIntegrationSetup
      onChange={onChange}
      organizationIntegrations={orgIntegrations}
      propertyIntegrations={propertyIntegrations}
      editMode={editMode}
      useModal={false}
      integrationKey={'munibilling'}
      integrationName={'Munibilling'}
      integrationFormValue={'isMunibillingEnabled'}
    />
  );
};

export default MunibillingSetup;
