import React from 'react';
import { Field } from 'redux-form';
import { renderSelectField } from '../../../../utils/redux-form-helper';
import type { DropdownOption } from '../../../App/types';

type Props = {
  referralTypes: Array<DropdownOption>
};

const ReferralType = ({ referralTypes }: Props) => (
  <Field name="referralTypeId" component={renderSelectField} options={referralTypes} bsSize="lg" />
);

export default ReferralType;
