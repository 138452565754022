// $FlowFixMe
import { useEffect, useState } from 'react';
import { pathOr, isEmpty } from 'ramda';
import MasterTransactionCodesService from '../../services/masterTransactionCodesService';

const mapTransactionCodePayload = (txCode: Object) => {
  return {
    ...txCode,
    description: txCode.description.trim(),
    transactionType: pathOr(null, ['transactionType', 'name'], txCode),
    masterSubjournal: pathOr(
      '---',
      ['masterSubjournal', 'description'],
      txCode,
    ),
  };
};

export const useMasterTransacionCodes = (
  organizationId: string,
  searchText: string,
  setMasterTransactionCodes: Function,
) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    let subscribed = true;

    const fetchMasterTransacionCodes = async () => {
      const masterTransactionCodesService = new MasterTransactionCodesService();
      const response = await masterTransactionCodesService.getOrganizationMasterTransactionCodes(
        organizationId,
        searchText,
        options,
      );
      if (response.length) {
        const mappedTransactionCodes = response.map(mapTransactionCodePayload);
        setMasterTransactionCodes(mappedTransactionCodes);
      } else {
        setMasterTransactionCodes([]);
      }
      setIsLoading(false);
    };

    if (subscribed && !isEmpty(organizationId)) {
      fetchMasterTransacionCodes();
    }
    return () => {
      subscribed = false;
    };
  }, [organizationId, setIsLoading, searchText, setMasterTransactionCodes]);

  return isLoading;
};
