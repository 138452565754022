import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const BASE_INPUT_PROPS = {
  GridProps: {
    sx: 12,
    sm: 6,
  },
  InputProps: {
    sx: {
      width: '100%',
      height: 40,
      '&.Mui-readOnly': {
        boxShadow: 'unset',
        top: -10,
      },
      '&.Mui-readOnly .MuiSelect-icon': {
        color: 'transparent',
      },
      '&.Mui-readOnly .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
  },

  HelperTextProps: {
    sx: {
      display: 'none',
    },
  },
  placeholder: '---',
};

export const FIELDS = [
  {
    label: 'HOME Affordability Period Start Date',
    name: 'HOMEAffordabilityPeriodStartDate',
    variant: 'date',
    ...BASE_INPUT_PROPS,
  },
  {
    label: 'HOME Unit Type',
    name: 'HOMEUnitType',
    variant: 'singleSelect',
    options: [
      {
        text: <FormattedMessage {...messages.fixed} />,
        value: messages.fixed.defaultMessage,
      },
      {
        text: <FormattedMessage {...messages.floating} />,
        value: messages.floating.defaultMessage,
      },
    ],
    ...BASE_INPUT_PROPS,
  },
  {
    label: 'HOME Annual Income Type',
    name: 'HOMEAnnualIncomeType',
    variant: 'singleSelect',
    options: [
      {
        text: <FormattedMessage {...messages.hudSection8} />,
        value: messages.hudSection8.defaultMessage,
      },
      {
        text: <FormattedMessage {...messages.censusLongFormOrIRS1040} />,
        value: messages.censusLongFormOrIRS1040.defaultMessage,
      },
    ],
    ...BASE_INPUT_PROPS,
  },
  {
    label: 'HOME Property Type',
    name: 'HOMEPropertyType',
    variant: 'singleSelect',
    options: [
      {
        text: <FormattedMessage {...messages.none} />,
        value: messages.none.defaultMessage,
      },
      {
        text: <FormattedMessage {...messages.homeGroupHome} />,
        value: messages.homeGroupHome.defaultMessage,
      },
    ],
    ...BASE_INPUT_PROPS,
  },
];
