import React from 'react';
import { grey } from '@fortress-technology-solutions/fortress-component-library/design';
import styled from 'styled-components';

export type Props = {
  onChange: (e: Object) => void,
  value: string,
  disabled: boolean,
  placeholder: string,
  maxLength: number,
  defaultValue: string,
};

const StyledTextArea = styled.textarea`
  resize: none;
  border-radius: 3px;
  border: 1px solid ${grey.lighter};
  padding: 6px;
  box-sizing: border-box;
  width: 100%;
`;

const TextArea = ({
  onChange,
  value,
  disabled,
  placeholder,
  maxLength,
  defaultValue,
}: Props) => {
  return (
    <StyledTextArea
      rows="4"
      value={value.message}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      maxLength={maxLength}
      defaultValue={defaultValue}
    />
  );
};

export default TextArea;
