import { useCallback, useState } from 'react';

export function useModal(defaultShow: ?boolean = false) {
  const [show, setShow] = useState(defaultShow);

  const open = useCallback(() => {
    setShow(true);
  }, []);

  const close = useCallback(() => {
    setShow(false);
  }, []);

  return [show, open, close];
}
