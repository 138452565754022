import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Stack,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { TABLE_HEADERS } from './constants';
import { useFetchPropertySetAsidePrograms } from './hooks';
import Data from '../../../../components/Table/Data';

import { RowGrid } from './styled';
import SetAsideProgramsTableRow from './row';
import type { BaseSetAsideProgramsProps } from './types';
import ElementWithPermissions from '../../../../components/ElementWithPermissions';
import SetAsideSetupModal from './SetAsideSetupModal';

const getColumnHeaders = () => {
  return TABLE_HEADERS.map((name) => <div key={`header-${name}`}>{name}</div>);
};

const SetAsidePrograms = ({
  intl,
  propertyId,
  organizationId,
  promptToaster,
  userPermissions,
  refreshHandler,
}: BaseSetAsideProgramsProps) => {
  const hooksArgs = {
    intl,
    propertyId,
    organizationId,
    promptToaster,
  };
  const { shouldRefresh, setShouldRefresh } = refreshHandler;

  const [isLoading, setAsides, refresh] =
    useFetchPropertySetAsidePrograms(hooksArgs);

  const initialOpenedRows = setAsides.map((_, index) => false);
  const [openedRows, setOpenedRows] = useState(initialOpenedRows);
  const [setAsideSetupModalOpen, setSetAsideSetupModalOpen] = useState(false);
  const [isAddingEditingProgram, setIsAddingEditingProgram] = useState(false);

  const showSpinner = isLoading;
  const hasNoSetAside = !setAsides || setAsides.length === 0;
  const showEmptySetAside = !isLoading && hasNoSetAside; // if no row, show a "+" icon
  const showRows = !showSpinner && !showEmptySetAside;

  const onOpenRowDrawer = (index: number) => {
    const newOpenedRows = setAsides.map((_, idx) => idx === index);
    setOpenedRows(newOpenedRows);
  };

  const onCloseRowDrawer = () => {
    // close all since only one can be opened at a time
    const newOpenedRows = setAsides.map((_, index) => false);
    setOpenedRows(newOpenedRows);
  };

  const onOpenSetAsideSetupModal = () => {
    setSetAsideSetupModalOpen(true);
  };

  const closeSetAsideSetupModal = () => {
    setSetAsideSetupModalOpen(false);
  };

  const onAddEdit = ({ isAddMode, editingDetailsRows }) => {
    setIsAddingEditingProgram(isAddMode || editingDetailsRows.includes(true));
  };

  useEffect(() => {
    if (shouldRefresh) {
      refresh();
      setShouldRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefresh]);

  return (
    <Box
      sx={{
        width: {
          xs: 'calc(100vw - 32px)',
          sm: `calc(100vw - ${64 + 32}px)`,
          md: '100%',
        },
      }}
    >
      <Stack justifyContent={'space-between'} direction={'row'}>
        <Typography
          variant={'formSubheading'}
          display={'block'}
          marginBottom={1}
        >
          {intl.formatMessage(messages.title)}
        </Typography>
        <ElementWithPermissions
          scope={['compliance-tab-edit-set-aside-program']}
        >
          <Button
            variant={'shoutSubtle'}
            onClick={onOpenSetAsideSetupModal}
            disabled={isAddingEditingProgram}
          >
            <FormattedMessage {...messages.setAsideSetup} />
          </Button>
        </ElementWithPermissions>
      </Stack>

      <Box
        sx={{
          overflowX: 'auto',
          borderRadius: 1,
          width: { xs: 'calc(100vw - 32px)', sm: 'unset' },
        }}
      >
        <AsyncBox
          loading={showSpinner}
          ChildrenContainerProps={{
            sx: { minWidth: 1200, minHeight: { xs: '50vh' } },
          }}
        >
          <Table striped className="table-prospects mr-10">
            <thead className="table-header">
              <tr>
                <th>
                  <RowGrid>{getColumnHeaders()}</RowGrid>
                </th>
              </tr>
            </thead>
            <tbody>
              {showEmptySetAside && (
                <tr>
                  <Data colSpan={TABLE_HEADERS.length}>
                    No set aside program.
                  </Data>
                </tr>
              )}

              {showRows &&
                setAsides.map((setAside, index) => (
                  <SetAsideProgramsTableRow
                    intl={intl}
                    key={`setAsideRow-${index}`}
                    data={setAside}
                    refresh={refresh}
                    organizationId={organizationId}
                    propertyId={propertyId}
                    promptToaster={promptToaster}
                    index={index}
                    userPermissions={userPermissions}
                    isEditMode={openedRows[index]}
                    onOpenRowDrawer={onOpenRowDrawer}
                    onCloseRowDrawer={onCloseRowDrawer}
                    onAddEdit={onAddEdit}
                  />
                ))}
            </tbody>
          </Table>
        </AsyncBox>
      </Box>

      <SetAsideSetupModal
        setAsidePrograms={setAsides}
        show={setAsideSetupModalOpen}
        intl={intl}
        onClose={closeSetAsideSetupModal}
        organizationId={organizationId}
        propertyId={propertyId}
        promptToaster={promptToaster}
        refresh={setShouldRefresh}
      />
    </Box>
  );
};

export default SetAsidePrograms;
