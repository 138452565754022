// $FlowFixMe
import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import FileSaver from 'file-saver';

import DocumentService from '../services/documentService';
import { toastr } from 'react-redux-toastr';

export const useAsyncDownloadDocument = (
  selectedProperty: Object,
  documentId: ?string,
  fileName: ?string,
  toasterFn: ?Function,
) => {
  const [downloadDocument, setDownloadDocument] = useState(false);

  const handleDownloadDocument = () => setDownloadDocument(true);

  const selectedPropertyId = pathOr(null, ['id'], selectedProperty);
  const organizationId = pathOr(null, ['organizationId'], selectedProperty);

  useEffect(() => {
    const fetchDownloadDocument = async () => {
      const documentService = new DocumentService();
      try {
        const file = await documentService.download(
          organizationId,
          documentId,
          selectedPropertyId,
        );

        FileSaver.saveAs(file, fileName);
      } catch (e) {
        if (toasterFn) {
          toasterFn({
            type: 'error',
            message: 'There was an error downloading the Signed 59A',
            title: 'Success',
          });
        }
      } finally {
        setDownloadDocument(false);
      }
    };
    if (
      downloadDocument &&
      selectedPropertyId &&
      organizationId &&
      documentId &&
      fileName
    ) {
      fetchDownloadDocument();
    }

    return () => {
      setDownloadDocument(false);
    };
  }, [
    documentId,
    downloadDocument,
    fileName,
    organizationId,
    selectedPropertyId,
    toasterFn,
  ]);

  return handleDownloadDocument;
};

// Refactored hook from above, that doesn't require full property object
// Allows you to pass document id and filename as well
export const useAsyncDownloadDocumentV2 = (
  propertyId: string,
  organizationId: string,
) => {
  const [downloadDocument, setDownloadDocument] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [fileName, setFileName] = useState(null);

  const handleDownloadDocument = (documentId: string, fileName: string) => {
    setDocumentId(documentId);
    setFileName(fileName);
    setDownloadDocument(true);
  };

  useEffect(() => {
    const fetchDownloadDocument = async () => {
      const documentService = new DocumentService();
      try {
        const file = await documentService.download(
          organizationId,
          documentId,
          propertyId,
        );

        FileSaver.saveAs(file, fileName);
      } catch (e) {
        toastr.error('Failed to download document...');
      } finally {
        setDownloadDocument(false);
      }
    };
    if (
      downloadDocument &&
      propertyId &&
      organizationId &&
      documentId &&
      fileName
    ) {
      fetchDownloadDocument();
    }
    return () => {
      setDownloadDocument(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, downloadDocument, organizationId, propertyId]);

  return handleDownloadDocument;
};
