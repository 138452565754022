const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class ApplicationFeesService {
  getAll(organizationId: string, propertyId: string, numAdults: number) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const params = `numAdults=${numAdults}`;
    return request(
      `${apiURL}/${organizationId}/${propertyId}/application-fees?${params}`,
      options,
    );
  }
}

export default ApplicationFeesService;
