import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
  allPass,
  always,
  anyPass,
  assoc,
  compose,
  cond,
  equals,
  filter,
  flatten,
  is,
  isNil,
  keys,
  map,
  mergeAll,
  partialRight,
  pathOr,
  paths,
  test,
  values,
} from 'ramda';
import { Component } from 'react';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { getAllProspectStatus } from '../App/actions';
import { getCurrentUser } from '../App/selectors';
import type { GlobalState, Property } from '../App/types';
import * as manageCommunicationsActions from './actions';
import CommunicationsTable from './CommunicationsTable';
import headers from './CommunicationsTable/headers';
import {
  active,
  approved,
  deadOrLost,
  hasDelinquentBalance,
  hasPrepaidBalance,
  hold,
  isExpiringIn0_30Days,
  isExpiringIn31_60Days,
  isExpiringIn61_90Days,
  isExpiringIn91_120Days,
  monthToMonthLeases,
  numberOfBeds,
  onNTV,
  pendingApproval,
  residentsNumberOfBeds,
  residentsUnitLevel,
  residentsBuildingNumber,
  residentsUnitAmenities,
} from './filters';
import { injectFilters } from './hooks';
import messages from './messages';
import * as utils from './utils';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type StateProps = {
  state: Object,
  isLoading: boolean,
};

type InjectedProps = {
  actions: Object,
  intl: any,
  history: Object,
  currentUser: any,
  residents: Array<any>,
  prospects: Array<any>,
  applicants: Array<any>,
  selectedProperty: Property,
  prospectStatusList: Array<Object>,
  isInitialState: boolean,
  setCustomerStatus: Function,
  customerFilters: Array,
  setResidents: Function,
};

type State = {
  currentFilter: Object,
  query: Object,
  searchText: string,
  showFilter: boolean,
  customerStatus: string,
  selectedAll: boolean,
  rowsOnTable: Array<any>,
  filterApplied: boolean,
  currentSortedColumn: Object,
  immutableRowsOnTable: Array<any>,
  dateErrors: Object,
  isLoading: boolean,
  noDataToastrShown: boolean,
};

export class ManageCommunicationsPage extends Component<
  StateProps & InjectedProps,
  State,
> {
  constructor(props: StateProps & InjectedProps) {
    super(props);

    if (!this.props.selectedProperty) {
      this.props.history.push('select-property');
    }

    this.state = {
      currentFilter: {
        residents: {
          monthToMonthLeases: false,
          expiring0_30Days: false,
          expiring31_60Days: false,
          expiring61_90Days: false,
          expiring91_120Days: false,
          onntv: false,
          delinquentBalance: false,
          prepaidBalance: false,
        },
        residentsNumberOfBeds: {},
        residentsUnitLevel: {},
        residentsBuildingNumber: {},
        residentsUnitAmenities: {},
        applicants: {
          approved: false,
          pendingApproval: false,
        },
        prospects: {
          active: true,
          hold: false,
          deadOrLost: false,
        },
        prospectsNumberOfBeds: {
          one: false,
          two: false,
          three: false,
        },
        dateTo: '',
        dateFrom: '',
        none: {},
      },
      dateErrors: { dateFromError: '', dateToError: '' },
      query: {},
      searchText: '',
      showFilter: false,
      customerStatus: 'none',
      selectedAll: false,
      rowsOnTable: [],
      immutableRowsOnTable: [],
      filterApplied: false,
      currentSortedColumn: {
        columnName: '',
        order: '',
      },
      isLoading: false,
      noDataToastrShown: false,
    };
  }

  componentDidMount() {
    this.props.actions.resetLists();
    this.props.actions.getAllProspectStatus();
  }

  componentWillUnmount() {
    this.props.actions.resetLists();
  }

  onCustomerStatusChange = async (newCustomerStatus: string) => {
    this.props.setCustomerStatus(newCustomerStatus);
    await this.clearFilters();
    this.props.actions.change('ManageCommunications', 'searchText', '');
    switch (newCustomerStatus) {
      case 'residents':
        this.setState({
          ...this.state,
          customerStatus: newCustomerStatus,
          searchText: '',
          currentSortedColumn: {
            columnName: 'unitNumber',
            order: 'ASC',
          },
          isLoading: true,
        });
        await this.props.actions.getAllResidents();
        await this.waitForResidentsOnPropsToSetRowsOnTable();
        break;
      case 'prospects':
        this.setState({
          ...this.state,
          customerStatus: newCustomerStatus,
          searchText: '',
          currentSortedColumn: {
            columnName: 'desiredMoveInDate',
            order: 'ASC',
          },
          isLoading: true,
        });
        await this.props.actions.getAllProspects(this.props.prospectStatusList);
        await this.waitForProspectsOnPropsToSetRowsOnTable();
        break;
      case 'applicants':
        this.setState({
          ...this.state,
          customerStatus: newCustomerStatus,
          searchText: '',
          currentSortedColumn: {
            columnName: 'scheduledMoveInDate',
            order: 'ASC',
          },
          isLoading: true,
        });
        await this.props.actions.getAllApplicants();
        await this.waitForApplicantsOnPropsToSetRowsOnTable();
        break;
      default:
        break;
    }
  };
  waitForApplicantsOnPropsToSetRowsOnTable = () => {
    if (!this.props.isLoading && this.state.customerStatus === 'applicants') {
      const registers = this.props.flags
        .manageCommunicationsNonprimaryApplicants
        ? utils.getAllApplicantRegisters(this.props.applicants)
        : utils.getPrimaryApplicantRegisters(this.props.applicants);
      const sortedRowsOnTable = flatten(registers).sort(this.applySort);
      this.setState({
        ...this.state,
        selectedAll: false,
        rowsOnTable: sortedRowsOnTable,
        immutableRowsOnTable: sortedRowsOnTable,
        filterApplied: false,
        isLoading: false,
      });
    } else {
      setTimeout(this.waitForApplicantsOnPropsToSetRowsOnTable, 200);
    }
  };

  waitForProspectsOnPropsToSetRowsOnTable = () => {
    if (!this.props.isLoading && this.state.customerStatus === 'prospects') {
      const registers = this.props.prospects.map((prospect) => {
        const assignedTo = prospect.assignedTo;
        const prospectPreferences = prospect.prospectPreferences;
        return {
          ...prospect,
          name: `${prospect?.firstName ?? ''} ${
            prospect?.lastName ?? ''
          }`.trim(),
          assignedTo: `${assignedTo?.firstName ?? ''} ${
            assignedTo?.lastName ?? ''
          }`.trim(),
          desiredMoveInDate: prospectPreferences?.moveInDateFrom,
          applyFilter: false,
          selected: false,
        };
      });
      const sortedRowsOnTable = flatten(registers).sort(this.applySort);
      this.setState({
        ...this.state,
        selectedAll: false,
        rowsOnTable: sortedRowsOnTable,
        immutableRowsOnTable: sortedRowsOnTable,
        filterApplied: false,
        currentFilter: {
          ...this.state.currentFilter,
          prospects: {
            active: true,
            hold: false,
            deadOrLost: false,
          },
          prospectsNumberOfBeds: {
            one: false,
            two: false,
            three: false,
          },
        },
        isLoading: false,
      });
      this.onApplyFiltersClick();
    } else {
      setTimeout(this.waitForProspectsOnPropsToSetRowsOnTable, 200);
    }
  };

  waitForResidentsOnPropsToSetRowsOnTable = () => {
    if (!this.props.isLoading && this.state.customerStatus === 'residents') {
      const registers = this.props.residents.map((resident) => {
        return {
          ...resident,
          applyFilter: false,
          selected: false,
        };
      });
      const sortedRowsOnTable = flatten(registers).sort(this.applySort);
      this.props.setResidents(sortedRowsOnTable);
      this.setState({
        ...this.state,
        selectedAll: false,
        rowsOnTable: sortedRowsOnTable,
        immutableRowsOnTable: sortedRowsOnTable,
        filterApplied: false,
        isLoading: false,
      });
    } else {
      setTimeout(this.waitForResidentsOnPropsToSetRowsOnTable, 200);
    }
  };

  onSortChange = async (columnNameToSort: string) => {
    const { order, columnName } = this.state.currentSortedColumn;
    if (columnNameToSort === columnName) {
      const newOrder = order === 'ASC' ? 'DESC' : 'ASC';
      await this.setState({
        ...this.state,
        currentSortedColumn: {
          columnName,
          order: newOrder,
        },
      });
    } else {
      await this.setState({
        ...this.state,
        currentSortedColumn: {
          columnName: columnNameToSort,
          order: 'ASC',
        },
      });
    }
    const newRowsOnTable = this.state.rowsOnTable.sort(this.applySort);
    await this.setState({ ...this.state, rowsOnTable: newRowsOnTable });
  };

  applySort = (a: Object, b: Object) => {
    const { order, columnName } = this.state.currentSortedColumn;
    if (columnName === '') return 1;

    const statusHeaders = pathOr(
      [],
      [this.state.customerStatus, 'headers'],
      headers,
    );
    // $FlowFixMe
    const header = statusHeaders.find((h) => h.name === columnName);
    const getValue = pathOr(pathOr('', [columnName]), ['pathOr'], header);

    const aVal = getValue(a);
    const bVal = getValue(b);

    if (is(Number, aVal) && is(Number, bVal)) {
      return order === 'ASC' ? aVal - bVal : bVal - aVal;
    }

    return order === 'ASC'
      ? ('' + aVal).localeCompare(bVal, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      : ('' + bVal).localeCompare(aVal, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
  };

  onSelectAllClicked = () => {
    const newRowsOnTable = this.state.rowsOnTable.map((row) => {
      this.state.selectedAll === true
        ? (row.selected = false)
        : (row.selected = true);
      return row;
    });
    this.setState({
      ...this.state,
      selectedAll: !this.state.selectedAll,
      rowsOnTable: newRowsOnTable,
    });
  };

  toggleSelectedRow = (id: string) => {
    const newRowsOnTable = this.state.rowsOnTable.map((row) => {
      if (row.id === id) {
        row.selected = !row.selected;
      }
      return row;
    });

    const selectedAll = isNil(
      this.state.rowsOnTable.find((row) => row.selected === false),
    );

    this.setState({
      ...this.state,
      rowsOnTable: newRowsOnTable,
      selectedAll,
    });
  };

  clearFilters = () => {
    this.setState({
      ...this.state,
      currentFilter: {
        residents: {
          monthToMonthLeases: false,
          expiring0_30Days: false,
          expiring31_60Days: false,
          expiring61_90Days: false,
          expiring91_120Days: false,
          onntv: false,
          delinquentBalance: false,
          prepaidBalance: false,
        },
        residentsNumberOfBeds: {},
        residentsUnitLevel: {},
        residentsBuildingNumber: {},
        residentsUnitAmenities: {},
        applicants: {
          approved: false,
          pendingApproval: false,
        },
        prospects: {
          active: false,
          hold: false,
          deadOrLost: false,
        },
        prospectsNumberOfBeds: {
          one: false,
          two: false,
          three: false,
        },
        dateTo: '',
        dateFrom: '',
      },
      rowsOnTable: this.state.immutableRowsOnTable,
      filterApplied: false,
      showFilter: false,
      dateErrors: { dateFromError: '', dateToError: '' },
    });
  };

  handleFilterClick = () => {
    this.setState((prev) => ({ showFilter: !prev.showFilter }));
  };

  onDateFilterChange = (field: string, cb: ?Function) => (date: Object) => {
    const currentFilter = {
      ...this.state.currentFilter,
      [field]: date,
    };
    this.setState(
      {
        ...this.state,
        currentFilter,
      },
      cb,
    );
  };

  onFilterChange =
    (field: any) =>
    (value: string) =>
    ({ target: { checked } }: any) => {
      const currentFilter = this.state.currentFilter[field];
      currentFilter[value] = checked;
      this.setState({
        ...this.state,
        currentFilter: {
          ...this.state.currentFilter,
          [field]: currentFilter,
        },
      });
    };

  //This is to have a proper logic to filter by group of fields,
  // since now one have multiple field filters
  groupFiltersByCustomerStatus = (
    customerStatus: string,
    filtersToApply: Array<any>,
  ): Array<any> => {
    const customerFilters = this.props.customerFilters;

    const result = [];
    customerFilters.forEach((field) => {
      const optionFilters = [];
      field.options.forEach((option) => {
        const filter = filtersToApply.find(
          (filter) => filter.filterName === option.value,
        );
        if (!isNil(filter)) {
          optionFilters.push(filter.filter);
        }
      });
      if (optionFilters.length > 0) {
        result.push(anyPass(optionFilters));
      }
    });

    return result;
  };

  onApplyFiltersClick = () => {
    const isFilterChecked = (filter) => filter === true;
    const customerFilters = this.props.customerFilters;
    const filtersToApply = compose(
      keys,
      filter(isFilterChecked),
      mergeAll,
      paths(customerFilters.map((f) => [f.fieldName])),
    )(this.state.currentFilter);
    const { dateFrom, dateTo } = this.state.currentFilter;

    const matchFilterWithFunction = cond([
      [equals('delinquentBalance'), always(hasDelinquentBalance)],
      [equals('prepaidBalance'), always(hasPrepaidBalance)],
      [equals('monthToMonthLeases'), always(monthToMonthLeases)],
      [equals('expiring0_30Days'), always(isExpiringIn0_30Days)],
      [equals('expiring31_60Days'), always(isExpiringIn31_60Days)],
      [equals('expiring61_90Days'), always(isExpiringIn61_90Days)],
      [equals('expiring91_120Days'), always(isExpiringIn91_120Days)],
      [equals('approved'), always(approved)],
      [equals('pendingApproval'), always(pendingApproval)],
      [equals('onntv'), always(onNTV)],
      [equals('active'), always(active)],
      [equals('hold'), always(hold)],
      [
        equals('deadOrLost'),
        always(partialRight(deadOrLost, [dateFrom, dateTo])),
      ],
      [equals('one'), always(partialRight(numberOfBeds, [1]))],
      [equals('two'), always(partialRight(numberOfBeds, [2]))],
      [equals('three'), always(partialRight(numberOfBeds, [3]))],
      [
        test(/^residentsNumberOfBeds-/i),
        (f) => partialRight(residentsNumberOfBeds, [f]),
      ],
      [
        test(/^residentsUnitLevel-/i),
        (f) => partialRight(residentsUnitLevel, [f]),
      ],
      [
        test(/^residentsBuildingNumber-/i),
        (f) => partialRight(residentsBuildingNumber, [f]),
      ],
      [
        test(/^residentsUnitAmenities-/i),
        (f) => partialRight(residentsUnitAmenities, [f]),
      ],
    ]);

    const filters = [];
    filtersToApply.forEach((currentFilter) => {
      filters.push({
        filterName: currentFilter,
        filter: matchFilterWithFunction(currentFilter),
      });
    });

    const re = this.groupFiltersByCustomerStatus(
      this.state.customerStatus,
      filters,
    );

    let filteredRows = map(
      assoc('applyFilter', true),
      filter(allPass(re))(this.state.immutableRowsOnTable),
    );
    this.props.actions.change('ManageCommunications', 'searchText', '');
    this.setState({
      rowsOnTable:
        filtersToApply.length === 0
          ? this.state.immutableRowsOnTable
          : filteredRows,
      filterApplied: filtersToApply.length === 0 ? false : true,
      searchText: '',
      noDataToastrShown: false,
    });
  };

  searchRowsOnTable = (searchText: string) => {
    if (!searchText) return this.state.rowsOnTable;
    const search = `${searchText.toLowerCase().trim()}`;
    const searchedRows = this.state.rowsOnTable.filter((row) => {
      let result = false;
      const rowValues = values(row);
      rowValues.forEach((value) => {
        if (typeof value === 'string' || typeof value === 'number') {
          const castedValue = value.toString().toLowerCase().trim();
          if (castedValue.indexOf(search) !== -1) {
            result = true;
          }
        }
      });
      return result;
    });
    return searchedRows;
  };

  handleSubmit = async ({ searchText }: { searchText: string }) => {
    await this.clearFilters();
    this.setState({
      ...this.state,
      searchText,
      noDataToastrShown: false,
    });
  };

  handleCreateClick = () => {
    const rowsOnTable = this.searchRowsOnTable(this.state.searchText);
    const selectedResidents = rowsOnTable.filter(
      (resident) => resident.selected,
    );
    const { customerStatus } = this.state;
    let recipientType;
    switch (customerStatus) {
      case 'residents':
        recipientType = 'Current Resident';
        break;
      case 'applicants':
        recipientType = 'Applicant';
        break;
      case 'prospects':
        recipientType = 'Prospect';
        break;
      default:
        break;
    }
    this.props.actions.setSelectedResidents(selectedResidents);

    navigateToUrlWithSelectedPropertyId('/create-communication', {
      recipientType,
    });
  };

  showNoDataToastr = (rows: Array<Object>) => {
    if (
      !this.props.isInitialState &&
      !this.state.isLoading &&
      !this.state.noDataToastrShown
    ) {
      if (rows.length === 0) {
        this.props.actions.showNoDataToastr();
      }
      this.setState({ noDataToastrShown: true });
    }
  };

  render() {
    const rowsOnTable = this.searchRowsOnTable(this.state.searchText);
    const isAllCommercial =
      this.props.selectedProperty.hasCommercialFloorPlans === 'ALL';
    this.showNoDataToastr(rowsOnTable);

    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <CommunicationsTable
          intl={this.props.intl}
          onCustomerStatusChange={this.onCustomerStatusChange}
          customerStatus={this.state.customerStatus}
          selectedAll={this.state.selectedAll}
          onSelectAllClicked={this.onSelectAllClicked}
          rowsOnTable={rowsOnTable}
          toggleSelectedRow={this.toggleSelectedRow}
          isLoading={this.state.isLoading}
          handleFilterClick={this.handleFilterClick}
          query={this.state.query}
          handleSubmit={this.handleSubmit}
          filterApplied={this.state.filterApplied}
          onSortChange={this.onSortChange}
          sortOrder={this.state.currentSortedColumn.order}
          currentSortedColumnName={this.state.currentSortedColumn.columnName}
          handleCreateClick={this.handleCreateClick}
          isAllCommercial={isAllCommercial}
          currentFilter={this.state.currentFilter}
          onFilterChange={this.onFilterChange}
          onApplyFiltersClick={this.onApplyFiltersClick}
          showFilter={this.state.showFilter}
          onDateFilterChange={this.onDateFilterChange}
          customerFilters={this.props.customerFilters}
        />
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = (state: GlobalState) => {
  const { app, manageCommunications } = state;
  return {
    currentUser: getCurrentUser(state),
    residents: manageCommunications.residents,
    isLoading: manageCommunications.isLoading,
    selectedProperty: app.selectedProperty,
    prospects: manageCommunications.prospects,
    applicants: manageCommunications.applicants,
    prospectStatusList: app.prospectStatusList,
    isInitialState: manageCommunications.isInitialState,
  };
};

export function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    actions: bindActionCreators(
      {
        ...manageCommunicationsActions,
        change,
        getAllProspectStatus,
      },
      dispatch,
    ),
  };
}

const InjectedManageCommunicationsPage = injectIntl(
  injectFilters(ManageCommunicationsPage),
);

export default withLDConsumer()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(InjectedManageCommunicationsPage),
);
