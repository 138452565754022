// $FlowFixMe
import React from 'react';
import { Row, Col, FormGroup, InputGroup } from 'react-bootstrap';
import { Field } from 'redux-form';
import { BACKGROUND_COLOR } from '../../constants';
import { NoPrint } from 'react-easy-print';
import DashesIfNullOrUndefined from '../../../../components/DashesIfNullOrUndefined';
import { find, filter, pathOr, propEq, isEmpty } from 'ramda';
import {
  phoneParser,
  phoneFormatter,
  renderSimpleCheckboxField,
  renderDateField,
  renderPhoneField,
  renderSelectField,
  renderTextField,
  renderTextAreaField,
  renderTimeField,
  extractDateFormat,
  renderSearchDropDown,
} from '../../../../utils/redux-form-helper';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import messages from './messages';
import PetSection from '../PetSection';

const DEFAULT_ENTRY_PREFERENCE = 'No Preference';

type Props = {
  intl: any,
  entryPermissions?: Array<Object>,
  location: string,
  noTimeSpecified: boolean,
  invalid: Array<string>,
  householdPreferences: Object | null,
  householdPets: Array<Object>,
  unitNumbers: Array<Object>,
  formValues: Object,
  toggleStaffRequested: Function,
  changeSelectedUnit: Function,
  setSelectedUnit: Function,
  searchUnits: Function,
  changeCallback: Function,
  changeHouseholdCallback: Function,
};

const noTimeClicked = (changeCallback) => {
  changeCallback('requestedStartTimeFrom', null);
  changeCallback('requestedStartTimeTo', null);
};

const RequestorPreferences = ({
  intl,
  entryPermissions,
  location,
  noTimeSpecified,
  changeCallback,
  invalid,
  householdPreferences,
  householdPets,
  toggleStaffRequested,
  unitNumbers,
  changeSelectedUnit,
  setSelectedUnit,
  searchUnits,
  changeHouseholdCallback,
  formValues,
  flags,
}: Props) => {
  const { customerId, householdId, unitId, staffRequested } = formValues;
  const defaultEntryPreference = find(propEq('text', DEFAULT_ENTRY_PREFERENCE))(
    entryPermissions || [],
  );
  const householdEntryPreference = filter(
    propEq(
      'value',
      pathOr(
        pathOr('', ['value'], defaultEntryPreference),
        ['defaultEntryPermissionPreferenceId'],
        householdPreferences,
      ),
    ),
  )(entryPermissions || []);
  const dateFormat = extractDateFormat(intl);

  const options = unitNumbers.map((unit) => {
    return {
      label: unit.description,
      value: {
        customerId: pathOr(null, ['resident', 'customerId'], unit),
        householdId: pathOr(null, ['householdId'], unit),
        unitId: pathOr(null, ['unitId'], unit),
      },
    };
  });

  let selected =
    (customerId || householdId || unitId) && options
      ? options.find(
          (issue: Object) =>
            issue.value.customerId === customerId ||
            (issue.value.householdId === householdId &&
              issue.value.unitId === unitId),
        )
      : null;
  // No matching resident.. give us the unit
  if (!selected) {
    selected = options.find((issue: Object) => issue.value.unitId === unitId);
    if (selected) {
      // put old customer back in it.. propbably a prior
      selected.value.customerId = customerId;
      selected.value.householdId = householdId;
    }
  }

  return (
    <div data-test="comp-requestor-prefs">
      <Row className="padtop20">
        <Col xs={12}>
          <h3>{intl.formatMessage(messages.title)}</h3>
        </Col>
      </Row>
      {location === 'commonArea' ? (
        <Row>
          <Col xs={12} md={4}>
            <Field
              classNames="search-actions searchbox-default searchbox__hasautocomplete"
              component={renderSearchDropDown}
              name="unitSearch"
              placeholder="Search Units"
              onChangeCallback={changeHouseholdCallback}
              options={options}
              label="Household"
              bsSize="lg"
              fieldValue={selected}
            />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col xs={12}>
          <Field
            name="staffRequested"
            component={renderSimpleCheckboxField}
            bsSize="lg"
            label="Staff Requested"
            onClick={toggleStaffRequested}
          />
        </Col>
      </Row>
      <Row className="form-content">
        <Col xs={12} md={3}>
          <FormGroup>
            <Field
              name="requestorName"
              className="input-lg form-control"
              component={renderTextField}
              maxLength="35"
              placeholder="Name"
              disabled={!staffRequested}
              label={intl.formatMessage(messages.nameNotRequired)}
              showLabel={true}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={3}>
          <FormGroup>
            <label>{intl.formatMessage(messages.phone)}</label>
            <section>
              <Field
                className="input-lg"
                name="requestorPhone"
                component={renderPhoneField}
                placeholder="(___) ___-____"
                parse={phoneParser}
                format={phoneFormatter}
                disabled={true}
              />
            </section>
          </FormGroup>
        </Col>
        <Col xs={12} md={4}>
          <FormGroup>
            <label>{intl.formatMessage(messages.email)}</label>
            <Field
              name="requestorEmail"
              className="input-lg form-control"
              component={renderTextField}
              placeholder="Email Address"
              disabled={true}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={2}>
          <FormGroup>
            <InputGroup>
              <Field
                name="requestDate"
                component={renderDateField}
                bsSize="lg"
                placeholder={dateFormat}
                dateFormat={dateFormat}
                label={intl.formatMessage(messages.reportedDate)}
                classes="input"
                disabled={staffRequested}
              />
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row className="padtop20">
        <Col xs={12} md={4}>
          <Row>
            <Col xs={12} sm={6} className="has-range-date">
              <InputGroup>
                <Field
                  name="requestedStartDateFrom"
                  component={renderDateField}
                  bsSize="lg"
                  placeholder={dateFormat}
                  dateFormat={dateFormat}
                  label={intl.formatMessage(messages.datePreference)}
                  classes="input"
                  disabled={location === 'commonArea'}
                />
              </InputGroup>
            </Col>
            <Col xs={12} sm={6}>
              <InputGroup>
                <Field
                  name="requestedStartDateTo"
                  component={renderDateField}
                  bsSize="lg"
                  placeholder={dateFormat}
                  dateFormat={dateFormat}
                  label=" "
                  classes="input"
                  disabled={location === 'commonArea'}
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={4}>
          <Row>
            <Col xs={12}>
              <label>{intl.formatMessage(messages.timePreference)}</label>
              <Field
                name="noTimeSpecified"
                component={renderSimpleCheckboxField}
                bsSize="lg"
                label={intl.formatMessage(messages.noTimePreference)}
                disabled={location === 'commonArea'}
                onClick={() => noTimeClicked(changeCallback)}
              />
            </Col>
            {!noTimeSpecified ? (
              <span data-test="req-pref-time-inputs">
                <Col
                  xs={12}
                  sm={6}
                  className="has-range-date has-range-nolabel"
                >
                  <InputGroup>
                    <Field
                      name="requestedStartTimeFrom"
                      component={renderTimeField}
                      bsSize="lg"
                      classes="input"
                      disabled={location === 'commonArea'}
                    />
                  </InputGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <InputGroup>
                    <Field
                      name="requestedStartTimeTo"
                      component={renderTimeField}
                      bsSize="lg"
                      classes="input"
                      disabled={location === 'commonArea'}
                    />
                  </InputGroup>
                </Col>
              </span>
            ) : null}
          </Row>
        </Col>
        <Col xs={6} md={2}>
          <FormGroup>
            <Field
              selectClassNames="input-lg"
              name="householdEntryPermissionPreferenceId"
              component={renderSelectField}
              label={intl.formatMessage(messages.unitEntryPreference)}
              options={householdEntryPreference}
              maxLength="35"
              disabled={true}
            />
          </FormGroup>
        </Col>
        <Col xs={6} md={2}>
          <FormGroup>
            <Field
              selectClassNames="input-lg"
              name="entryPermissionPreferenceId"
              component={renderSelectField}
              label={intl.formatMessage(messages.entryPreferenceForWO)}
              options={entryPermissions}
              maxLength="35"
              disabled={location === 'commonArea'}
              style={
                invalid && invalid.includes('entryPreference')
                  ? BACKGROUND_COLOR
                  : null
              }
            />
          </FormGroup>
        </Col>
      </Row>
      {!isEmpty(householdPets) && location === 'unit' && (
        <PetSection
          intl={intl}
          location={location}
          householdPets={householdPets}
        />
      )}

      {householdPreferences && location === 'unit' && (
        <Row className="padtop20">
          <Col xs={12}>
            <Col xs={4} sm={2} className=" text-left">
              <label>{intl.formatMessage(messages.alarm)}</label>
            </Col>
            <Col xs={4} sm={2} className=" text-left">
              <label>{intl.formatMessage(messages.alarmCode)}</label>
            </Col>

            {flags.doorCodeFortressPortal && (
              <>
                <Col xs={4} sm={2} className=" text-left">
                  <label>{intl.formatMessage(messages.usesDoorCode)}</label>
                </Col>
                <Col xs={4} sm={2} className=" text-left">
                  <label>{intl.formatMessage(messages.doorCode)}</label>
                </Col>
              </>
            )}
          </Col>
          <Col xs={12}>
            <Col xs={4} sm={2} className=" text-left">
              <label>
                <DashesIfNullOrUndefined
                  data={householdPreferences.isAlarmActive}
                />
              </label>
            </Col>
            <Col xs={4} sm={2} className=" text-left">
              <label>
                {householdPreferences.isAlarmActive === 'Yes' ? (
                  <DashesIfNullOrUndefined
                    data={householdPreferences.alarmCode}
                  />
                ) : (
                  '---'
                )}
              </label>
            </Col>

            {flags.doorCodeFortressPortal && (
              <>
                <Col xs={4} sm={2} className=" text-left">
                  <label>
                    <DashesIfNullOrUndefined
                      data={householdPreferences.isDoorCodeActive}
                    />
                  </label>
                </Col>
                <Col xs={4} sm={2} className=" text-left">
                  <label>
                    {householdPreferences.isDoorCodeActive === 'Yes' ? (
                      <DashesIfNullOrUndefined
                        data={householdPreferences.doorCode}
                      />
                    ) : (
                      '---'
                    )}
                  </label>
                </Col>
              </>
            )}
          </Col>
        </Row>
      )}
      <Row className="padtop20">
        <Col xs={12}>
          <NoPrint force>
            <label>{intl.formatMessage(messages.entryInstructions)}</label>
            <Field
              name="requestCommentEntryAvailability"
              className="form-control textarea-short"
              placeholder="Start typing..."
              maxLength="500"
              component={renderTextAreaField}
            />
          </NoPrint>
        </Col>
      </Row>
    </div>
  );
};

export default withLDConsumer()(RequestorPreferences);
