import React from 'react';
import {
  Box,
  Badge,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  ListItemSecondaryAction,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  ExpandLessIcon,
  LaunchIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Collapse } from '@fortress-technology-solutions/fortress-component-library/Transitions';
import { Link as ReactRouterLink } from 'react-router-dom';

export const NavListIcon = ({ icon, sx, value, selected, notification }) => {
  const badgeColor = {
    new: 'error',
    unresolved: 'primary',
  }[notification];

  return (
    <>
      {value === 'Prorate Calculator' ? <Divider sx={{ marginY: 2 }} /> : null}
      <ListItemButton
        id={value}
        selected={selected}
        sx={{
          ...sx,
          height: 50,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {icon ? (
          <ListItemIcon
            sx={{
              pointerEvents: 'none',
              width: 'unset',
              minWidth: 'unset',
              display: 'unset',
            }}
          >
            {notification ? (
              <Badge variant="dot" color={badgeColor}>
                {icon}
              </Badge>
            ) : (
              icon
            )}
          </ListItemIcon>
        ) : null}
      </ListItemButton>
    </>
  );
};
const NavListItem = ({
  value,
  icon,
  open,
  onClick,
  setOpen,
  children,
  isSubOption,
  notification,
  text,
  onMouseEnter,
  onMouseLeave,
  selected,
  href,
  sx,
  to,
  target,
}) => {
  const ConditionalBadge = isSubOption && notification ? Badge : React.Fragment;
  const badgeColor = {
    new: 'error',
    unresolved: 'primary',
  }[notification];
  const ConditionalBadgeProps =
    isSubOption && notification
      ? {
          variant: 'dot',
          color: badgeColor,
          sx: { width: '100%' },
          slotProps: {
            badge: {
              style: {
                top: 8,
                right: 8,
              },
            },
          },
        }
      : {};

  const Wrapper = to ? ReactRouterLink : React.Fragment;
  const WrapperProps = to
    ? { to, style: { textDecoration: 'none', color: 'unset' } }
    : {};
  const isProrateCalculator = value === 'Prorate Calculator';

  return (
    <Wrapper {...WrapperProps}>
      <Box onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {isProrateCalculator ? <Divider sx={{ marginY: 2 }} /> : null}
        <ListItemButton
          component={href ? 'a' : undefined}
          id={value}
          onClick={onClick ? onClick : () => setOpen(!open)}
          sx={{ ...sx, color: 'unset !important' }}
          selected={selected}
          target={target}
          href={href}
        >
          <ConditionalBadge {...ConditionalBadgeProps}>
            {icon ? (
              <ListItemIcon
                sx={{
                  pointerEvents: 'none',
                  minWidth: 'unset',
                  marginRight: 1,
                }}
              >
                {!isSubOption && notification ? (
                  <Badge variant="dot" color={badgeColor}>
                    {icon}
                  </Badge>
                ) : (
                  icon
                )}
              </ListItemIcon>
            ) : null}
            <ListItemText
              sx={{
                pointerEvents: 'none',
              }}
              primary={
                !isSubOption ? (
                  <Typography variant="labelLarge">{text}</Typography>
                ) : null
              }
              primaryTypographyProps={{
                lineHeight: isProrateCalculator ? '1 !important' : '26px',
              }}
              secondary={
                isSubOption ? (
                  <Typography variant="bodySmall">{text}</Typography>
                ) : null
              }
            />

            {children ? (
              <ListItemSecondaryAction
                sx={{
                  display: 'flex',
                  pointerEvents: 'none',
                }}
              >
                <ExpandLessIcon
                  sx={{
                    transition: ({ transitions: { create } }) =>
                      create('transform'),
                    transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                />
              </ListItemSecondaryAction>
            ) : href ? (
              <ListItemSecondaryAction
                sx={{
                  height: 17.5,
                  top: 'calc(50% - 8.75px)',
                  transform: 'unset',
                }}
              >
                <LaunchIcon fontSize="16px" color="action" />
              </ListItemSecondaryAction>
            ) : null}
          </ConditionalBadge>
        </ListItemButton>
        {children}
      </Box>
    </Wrapper>
  );
};

const MainNavListItem = ({
  subOptions,
  showNavText,
  isXs,
  open,
  setOpen,
  ...option
}) => {
  return (
    <NavListItem
      {...option}
      open={open}
      setOpen={setOpen}
      onMouseEnter={subOptions && !isXs ? () => setOpen() : undefined}
      sx={{ px: 1.5 }}
    >
      {subOptions.length ? (
        <Collapse in={open}>
          <List component={'div'} disablePadding>
            {subOptions?.map((subOption) => {
              return (
                <NavListItem
                  key={subOption.value}
                  isSubOption={true}
                  sx={{ pl: 4.5 }}
                  {...subOption}
                />
              );
            })}
          </List>
        </Collapse>
      ) : null}
    </NavListItem>
  );
};

export default MainNavListItem;
