import { useState } from 'react';
import LeaseService from '../../../../../services/leaseService';

export function useUpdateReceivesHousingAssistance(
  organizationId: string,
  propertyId: string,
  leaseId: string,
  toasterFn: Function,
) {
  const [isLoading, setIsLoading] = useState(false);

  const updateReceivesHousingAssistance = async (value: boolean | null) => {
    const service = new LeaseService();

    setIsLoading(true);

    try {
      await service.updateReceivesHousingAssistance(
        organizationId,
        propertyId,
        leaseId,
        value,
      );
      toasterFn({
        message: 'Receives Housing Assistance was updated.',
        title: 'Success',
      });
    } catch (error) {
      toasterFn({
        type: 'error',
        message: error.toString(),
        title: 'Error',
      });
    }

    setIsLoading(false);
  };

  return [updateReceivesHousingAssistance, isLoading];
}
