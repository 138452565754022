import type {
  ChangeEventHandler,
  Option,
} from '../../../../ManageRentRoll/FilterControls/types';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import VariableDateControl from './VariableDateControl';
import MultiSelectControl from '../../../../ManageRentRoll/FilterControls/MultiSelectControl';
import VariableAmountControl from './VariableAmountControl';
import moment from 'moment';
import { sortLists } from '../../../../../components/FilterControls/utils';

type Props = {
  values: Object,
  onChange: ChangeEventHandler,
  clearExactDate: Function,
  clearDateRange: Function,
  transactionTypes: Option[],
  transactionCodes: Option[],
  periods: Option[],
  clearAmountRange: Function,
  clearExactAmount: Function,
  setIsDateRangeChecked: Function,
  isDateRangeChecked: boolean,
  setIsAmountRangeChecked: Function,
  isAmountRangeChecked: boolean,
  isFilterActive: boolean,
};

function FilterControls(props: Props): Object {
  const {
    values,
    onChange,
    clearExactDate,
    clearDateRange,
    transactionTypes,
    transactionCodes,
    ledger,
    periods,
    clearAmountRange,
    clearExactAmount,
    setIsDateRangeChecked,
    isDateRangeChecked,
    setIsAmountRangeChecked,
    isAmountRangeChecked,
    isFilterActive,
    lastPeriod,
  } = props;

  const transactionCodesOptions = sortLists(transactionCodes);

  const getTypeForOps = () => {
    return transactionTypes.filter((code) => {
      return (
        code.text !== 'Security Charge' && code.text !== 'Security Payment'
      );
    });
  };

  const getTypeForSecurity = () => {
    return transactionTypes.filter((code) => {
      return (
        code.text === 'Security Charge' || code.text === 'Security Payment'
      );
    });
  };

  const getPeriodsDuringOrAfterConversionDate = () => {
    return periods.filter((period) => {
      return moment(period.text).isSameOrAfter(
        moment(lastPeriod).startOf('month'),
      );
    });
  };

  const periodsOptions = getPeriodsDuringOrAfterConversionDate();
  const transactionTypeOptions =
    ledger === 'ops' ? getTypeForOps() : getTypeForSecurity();

  const handleMultiSelectChange = (key: string) => (e: Object) => {
    onChange?.(key, {
      type: 'multi-select',
      values: e.target.value,
    });
  };
  const handleDateChange = (key: string) => (newValue: Object | string) => {
    onChange?.(key, {
      type: 'date',
      value: typeof newValue === 'string' ? newValue : newValue.toDate(),
    });
  };
  const handleAmountChange = (key: string) => (e: Object) => {
    onChange?.(key, {
      type: 'amount',
      value: e.target.value,
    });
  };

  if (isFilterActive) {
    return (
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <h3>Filter By</h3>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Grid container spacing={2}>
              <Grid item lg={2}>
                <MultiSelectControl
                  name="periods"
                  label="Period"
                  placeholder={
                    periodsOptions.length < 1 ? 'No Periods' : 'All Periods'
                  }
                  aria-label="periods"
                  options={periodsOptions}
                  onChange={handleMultiSelectChange('periods')}
                  value={values.periods}
                  disabled={
                    values.exactDate ||
                    values.fromDate ||
                    values.toDate ||
                    periodsOptions.length < 1
                  }
                />
              </Grid>
              <Grid item lg={3}>
                <VariableDateControl
                  label="Transaction Date"
                  from={values.fromDate}
                  to={values.toDate}
                  fromPlaceholder="From"
                  toPlaceholder="To"
                  onFromChange={handleDateChange('fromDate')}
                  onToChange={handleDateChange('toDate')}
                  onExactDateChange={handleDateChange('exactDate')}
                  exactDateValue={values.exactDate}
                  exactDateLabel="Date"
                  exactDatePlaceholder="MM/DD/YYYY"
                  exactDateName="Exact Date"
                  clearDateRange={clearDateRange}
                  clearExactDate={clearExactDate}
                  setIsRange={setIsDateRangeChecked}
                  isRange={isDateRangeChecked}
                  disabled={values.periods.length > 0}
                />
              </Grid>
              <Grid item lg={3}>
                <VariableAmountControl
                  onFromChange={handleAmountChange('fromAmount')}
                  onToChange={handleAmountChange('toAmount')}
                  onExactAmountChange={handleAmountChange('exactAmount')}
                  from={values.fromAmount}
                  to={values.toAmount}
                  exactAmountValue={values.exactAmount}
                  clearAmountRange={clearAmountRange}
                  clearExactAmount={clearExactAmount}
                  isRange={isAmountRangeChecked}
                  setIsRange={setIsAmountRangeChecked}
                />
              </Grid>
              <Grid item lg={2}>
                <MultiSelectControl
                  name="transactionCodes"
                  label="Transaction Code"
                  placeholder={
                    transactionCodes?.length < 1 ? 'No Codes' : 'All Codes'
                  }
                  aria-label="transaction-codes"
                  options={transactionCodesOptions}
                  onChange={handleMultiSelectChange('transactionCodes')}
                  value={values.transactionCodes}
                  disabled={transactionCodes?.length < 1}
                />
              </Grid>
              <Grid item lg={2}>
                <MultiSelectControl
                  name="transactionTypes"
                  label="Transaction Type"
                  placeholder={
                    transactionTypeOptions.length < 1 ? 'No Types' : 'All Types'
                  }
                  aria-label="transaction-types"
                  options={transactionTypeOptions}
                  onChange={handleMultiSelectChange('transactionTypes')}
                  value={values.transactionTypes}
                  disabled={transactionTypeOptions.length < 1}
                />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return null;
}

export default FilterControls;
