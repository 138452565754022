import { defineMessages } from 'react-intl';

const messages = defineMessages({
  pageTitle: {
    id: 'App.ManageLeaseExpirationLimits.PageTitle',
    defaultMessage: 'Fortress - Manage Lease Expirations',
  },
  goBackBtn: {
    id: 'App.ManageLeaseExpirationLimits.GoBackBtn',
    defaultMessage: 'Go Back',
  },
  pageHeader: {
    id: 'App.ManageLeaseExpirationLimits.PageHeader',
    defaultMessage: 'Lease Expiration Limits',
  },
  currentMtmLabel: {
    id: 'App.ManageLeaseExpirationLimits.CurrentMtmLabel',
    defaultMessage: 'Current MTM Leases',
  },
  cardHeader: {
    id: 'App.ManageLeaseExpirationLimits.CardHeader',
    defaultMessage: 'Limits vs Actuals (% of Units)',
  },
  yearsVisibleLabel: {
    id: 'App.ManageLeaseExpirationLimits.YearsVisibleLabel',
    defaultMessage: 'Years Visible',
  },
  leasesPlural: {
    id: 'App.ManageLeaseExpirationLimits.LeasesPlural',
    defaultMessage: 'Leases',
  },
  leasesSingular: {
    id: 'App.ManageLeaseExpirationLimits.LeasesSingular',
    defaultMessage: 'Lease',
  },
  limitLegend: {
    id: 'App.ManageLeaseExpirationLimits.LimitLegend',
    defaultMessage: 'Limit',
  },
  actualsLegend: {
    id: 'App.ManageLeaseExpirationLimits.ActualsLegend',
    defaultMessage: 'Actuals',
  },
  availablesLegend: {
    id: 'App.ManageLeaseExpirationLimits.AvailablesLegend',
    defaultMessage: 'Available',
  },
  overLimitLegend: {
    id: 'App.ManageLeaseExpirationLimits.OverLimitLegend',
    defaultMessage: 'Over Limit',
  },
  graphDataRequestError: {
    id: 'App.ManageLeaseExpirationLimits.GraphDataRequestError',
    defaultMessage: 'Failed to load graph data.',
  },
  graphDataRequestErrorTitle: {
    id: 'App.ManageLeaseExpirationLimits.Error',
    defaultMessage: 'Error',
  },
  leaseLimit: {
    id: 'App.ManageLeaseExpirationLimits.LeaseLimit',
    defaultMessage: 'Lease Limit',
  },
  leases: {
    id: 'App.ManageLeaseExpirationLimits.Leases',
    defaultMessage: 'Lease(s)',
  },
  activeLeases: {
    id: 'App.ManageLeaseExpirationLimits.ActiveLeases',
    defaultMessage: 'Active Leases',
  },
  renewalInProcess: {
    id: 'App.ManageLeaseExpirationLimits.RenewalInProcess',
    defaultMessage: 'Renewal in Process',
  },
  moveInInProcess: {
    id: 'App.ManageLeaseExpirationLimits.MoveInInProcess',
    defaultMessage: 'Move-In in Process',
  },
  leasesAvailable: {
    id: 'App.ManageLeaseExpirationLimits.LeasesAvailable',
    defaultMessage: 'Lease(s) Available',
  },
  leasesOverLimit: {
    id: 'App.ManageLeaseExpirationLimits.LeasesOverLimit',
    defaultMessage: 'Lease(s) Over Limit',
  },
  viewLimitDataByYear: {
    id: 'App.ManageLeaseExpirationLimits.ViewLimitDataByYear',
    defaultMessage: 'View Limit Data By Year',
  },
  editLimitDataByYear: {
    id: 'App.ManageLeaseExpirationLimits.EditLimitDataByYear',
    defaultMessage: 'Edit Limits by Year',
  },
  closeLimitDataByYear: {
    id: 'App.ManageLeaseExpirationLimits.CloseLimitDataByYear',
    defaultMessage: 'Close Limit Data by Year',
  },
  limitDataByYearSectionTitle: {
    id: 'App.ManageLeaseExpirationLimits.LimitDataByYearSectionTitle',
    defaultMessage: 'Edit Limit Data by Year',
  },
  viewLimitDataByYearSectionTitle: {
    id: 'App.ManageLeaseExpirationLimits.ViewLimitDataByYearSectionTitle',
    defaultMessage: 'Limit Data by Year',
  },
  limitsFor: {
    id: 'App.ManageLeaseExpirationLimits.LimitsFor',
    defaultMessage: 'Limits for',
  },
  limits: {
    id: 'App.ManageLeaseExpirationLimits.Limits',
    defaultMessage: 'Limits',
  },
  actuals: {
    id: 'App.ManageLeaseExpirationLimits.Actuals',
    defaultMessage: 'Actuals',
  },
  saveChangesBtn: {
    id: 'App.ManageLeaseExpirationLimits.SaveChangesBtn',
    defaultMessage: 'Save Changes',
  },
  error: {
    id: 'App.ManageLeaseExpirationLimits.Error',
    defaultMessage: 'Error',
  },
  limitDataFetchError: {
    id: 'App.ManageLeaseExpirationLimits.LimitDataFetchError',
    defaultMessage: 'Failed to load limit data.',
  },
  limitDataSaveError: {
    id: 'App.ManageLeaseExpirationLimits.LimitDataSaveError',
    defaultMessage: 'Failed to save limit data.',
  },
  cancelChangesBtn: {
    id: 'App.ManageLeaseExpirationLimits.CancelChangesBtn',
    defaultMessage: 'Cancel',
  },
  closeConfirmTitle: {
    id: 'App.ManageLeaseExpirationLimits.CloseConfirmTitle',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  lastEditedBy: {
    id: 'App.ManageLeaseExpirationLimits.LastEditedBy',
    defaultMessage: 'Last Edited by',
  },
  on: {
    id: 'App.ManageLeaseExpirationLimits.On',
    defaultMessage: 'on',
  },
  currentLimit: {
    id: 'App.ManageLeaseExpirationLimits.CurrentLimit',
    defaultMessage: 'Current Limit',
  },
  editSingleLimitError: {
    id: 'App.ManageLeaseExpirationLimits.EditSingleLimitError',
    defaultMessage: 'Failed to edit limit.',
  },
});

export default messages;
