import { defineMessages } from 'react-intl';

const messages = defineMessages({
  requiredFields: {
    id: 'App.Login.LoginForm.RequiredFields',
    defaultMessage: 'Required'
  },
});

export default messages;
