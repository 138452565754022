import { v4 as uuid } from 'uuid';
import moment from 'moment';
import * as R from 'ramda';
import type { FilterValues } from '../../services/reputationManagementService';

const toAppliedFilter = R.curryN(
  3,
  (key: string, textFn: Function, value: any) => ({
    id: uuid(),
    text: textFn?.(value) || value,
    meta: {
      key,
      value,
    },
  }),
);

export const mapFilterValuesToAppliedFilters = (
  filterValues: FilterValues,
  options: string[],
): AppliedFilters => {
  if (!filterValues) return [];

  const preRatingsFilters = filterValues.ratings.map((ratingsId) => {
    const ratingObject = options.ratings.find((r) => r.value === ratingsId);
    return ratingObject?.text;
  });
  const ratingsFilter = preRatingsFilters.map(
    toAppliedFilter('ratings', R.identity),
  );

  const timeFrameToAppliedFilter = (timeFrame: ?string) => {
    if (!timeFrame) return [];

    return [
      {
        id: uuid(),
        text: timeFrame,
        meta: { key: 'timeFrame' },
      },
    ];
  };

  const timeFrameFilter = timeFrameToAppliedFilter(filterValues.timeFrame);

  const statusToAppliedFilter = (status: ?string) => {
    if (!status) return [];

    const statusObject = options.status.find((r) => r.value === status);

    return [
      {
        id: uuid(),
        text: statusObject?.text,
        meta: { key: 'status' },
      },
    ];
  };

  const isStatusFilter = statusToAppliedFilter(filterValues.status);

  const dateRangeToAppliedFilter = (
    fromDateStr: ?string,
    toDateStr: ?string,
  ) => {
    if (!fromDateStr || !toDateStr) return [];
    const DATE_FORMAT = 'MM/DD/YYYY';

    const fromMoment = moment(fromDateStr);
    const toMoment = moment(toDateStr);

    if (!validateDateRangeReputationManagement(fromDateStr, toDateStr))
      return [];

    return [
      {
        id: uuid(),
        text: `${fromMoment.format(DATE_FORMAT)} - ${toMoment.format(
          DATE_FORMAT,
        )}`,
        meta: { isDateRangeFilter: true },
      },
    ];
  };

  const dateRangeFilter = dateRangeToAppliedFilter(
    filterValues.fromDate,
    filterValues.toDate,
  );

  const categoryFilter = filterValues.category
    ? [
        toAppliedFilter(
          'category',
          (value) => {
            if (value === 'all') return 'All Categories';
            if (value === 'recommended') return 'Recommends';
            return "Doesn't Recommend";
          },
          filterValues.category,
        ),
      ]
    : [];

  return [
    ...dateRangeFilter,
    ...timeFrameFilter,
    ...ratingsFilter,
    ...isStatusFilter,
    ...categoryFilter,
  ];
};

export const validateDateRangeReputationManagement = (fromDate, toDate) => {
  if (!fromDate || !toDate) {
    return true;
  }

  const momentFrom = moment(fromDate);
  const momentTo = moment(toDate);

  if (momentFrom.isSame(momentTo)) return false;

  const validFromFormat = moment(fromDate, 'MM/DD/YYYY', true).isValid();
  const validToFormat = moment(toDate, 'MM/DD/YYYY', true).isValid();

  if (!validFromFormat) {
    return false;
  }

  if (!validToFormat) {
    return false;
  }

  if (momentFrom.isAfter(momentTo)) {
    return false;
  }

  return true;
};
