import React from 'react';
import messages from './messages';
import { FormGroup, Row, Col, Checkbox } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { validateFromMessage, validateToMessage } from './validations';
import { TouchedDateInput } from './TouchedDateInput';

type Props = {
  intl: Object,
  data: Object,
  onChange: Function,
  range: boolean,
  setRange: Function,
  noMoveInDate: boolean,
  setNoMoveInDate: Function,
  prospectAssignees: any[],
};

export const OtherForm = ({
  intl,
  data,
  onChange,
  range,
  setRange,
  noMoveInDate,
  setNoMoveInDate,
  prospectAssignees,
}: Props) => {
  const { moveInDateFrom, moveInDateTo, assignedToId } = data;

  return (
    <FormGroup className="applicant-grp">
      <Row className="block-heading">
        <h2>{intl.formatMessage(messages.other)}</h2>
      </Row>
      <Row>
        <div className="container-of-inputs">
          <Col md={2}>
            <label>
              <FormattedMessage {...messages.desiredMoveInDate} />*
            </label>
          </Col>
          <Col md={1}>
            <Checkbox
              value={range}
              checked={range}
              onChange={() => {
                setRange(noMoveInDate ? true : !range);
                setNoMoveInDate(false);
              }}
            >
              {intl.formatMessage(messages.range)}
            </Checkbox>
          </Col>
          <Col md={1}>
            <Checkbox
              value={noMoveInDate}
              checked={noMoveInDate}
              onChange={() => {
                setNoMoveInDate(!noMoveInDate);
                setRange(false);
              }}
            >
              {intl.formatMessage(messages.noMoveInDate)}
            </Checkbox>
          </Col>
          <Col md={2}>
            <label>
              <FormattedMessage {...messages.assignApplicant} />*
            </label>
          </Col>
        </div>
      </Row>
      <Row>
        <div className="container-of-inputs">
          <Col md={2}>
            <TouchedDateInput
              hidden={noMoveInDate}
              error={validateFromMessage(
                moveInDateFrom,
                range,
                intl,
                moveInDateTo,
              )}
              name="moveInDateFrom"
              value={moveInDateFrom}
              onChange={onChange}
            />
          </Col>
          <Col md={2}>
            <TouchedDateInput
              hidden={!range || noMoveInDate}
              error={validateToMessage(moveInDateTo, range, intl)}
              name="moveInDateTo"
              value={moveInDateTo}
              onChange={onChange}
            />
          </Col>
          <Col md={2}>
            <select
              name="assignedToId"
              className="input-lg form-control"
              value={assignedToId}
              onChange={onChange}
            >
              {prospectAssignees.map((prospectAssignee) => (
                <option
                  key={`prospect-assignee-${prospectAssignee.id}`}
                  value={prospectAssignee.id}
                >
                  {prospectAssignee.firstName} {prospectAssignee.lastName}
                </option>
              ))}
            </select>
          </Col>
        </div>
      </Row>
    </FormGroup>
  );
};
