import { defineMessages } from 'react-intl';

const messages = defineMessages({
  domTitle: {
    id: 'App.ManageProperties.domTitle',
    defaultMessage: 'Fortress - Manage Properties',
  },
  header: {
    id: 'App.ManageProperties.Header',
    defaultMessage: 'Manage Properties',
  },
  subHeader: {
    id: 'App.ManageProperties.SubHeader',
    defaultMessage: 'From here you can follow-up and view your properties.',
  },
  searchProperties: {
    id: 'App.ManageProperties.SearchProperties',
    defaultMessage: 'Search for a property...',
  },
  createProperty: {
    id: 'App.ManageProperties.CreateProperty',
    defaultMessage: 'Add New Property',
  },
  status: {
    id: 'App.ManageProperties.Status',
    defaultMessage: 'Status',
  },
  id: {
    id: 'App.ManageProperties.Id',
    defaultMessage: 'Fortress ID',
  },
  propertyName: {
    id: 'App.ManageProperties.Name',
    defaultMessage: 'Property Name',
  },
  shortName: {
    id: 'App.ManageProperties.ShortName',
    defaultMessage: 'Short Name',
  },
  ownerName: {
    id: 'App.ManageProperties.OwnerName',
    defaultMessage: 'Owner Name',
  },
  propertyType: {
    id: 'App.ManageProperties.PropertyType',
    defaultMessage: 'Property Type',
  },
  address: {
    id: 'App.ManageProperties.address',
    defaultMessage: 'Address',
  },
  phone: {
    id: 'App.ManageProperties.Phone',
    defaultMessage: 'Phone',
  },
  email: {
    id: 'App.ManageProperties.Email',
    defaultMessage: 'Email',
  },
  period: {
    id: 'App.ManageProperties.Period',
    defaultMessage: 'Period',
  },
  autoLateFeesPostingActive: {
    id: 'App.ManageProperties.AutoLateFeesPostingActive',
    defaultMessage: 'Auto Late Fees Posting Active',
  },
  leaseVariance: {
    id: 'App.ManageProperties.LeaseVariance',
    defaultMessage: 'Lease Variance',
  },
  portalActive: {
    id: 'App.ManageProperties.PortalActive',
    defaultMessage: 'Portal Active',
  },
  links: {
    id: 'App.ManageProperties.Links',
    defaultMessage: 'Links',
  },
  ownerFetchError: {
    id: 'App.ManageProperties.OwnerFetchError',
    defaultMessage: 'Error encountered while retrieving Property Owners',
  },
  ownerFetchEmpty: {
    id: 'App.ManageProperties.OwnerFetchSuccess',
    defaultMessage: 'Now Property Owners found',
  },
  errorHeader: {
    id: 'App.ManageProperties.ErrorHeader',
    defaultMessage: 'Error',
  },
  success: {
    id: 'App.ManageProperties.Success',
    defaultMessage: 'Success',
  },
  gpr: {
    id: 'App.ManageProperties.GPR',
    defaultMessage: 'GPR',
  },
});

export default messages;
