import DocumentTitle from 'react-document-title';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AddIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import useIntl from '../../hooks/useIntl';
import { useTableData } from './hooks';
import messages from './messages';
import TableLayout from '../../components/TableLayout';

function ManageAmenities({
  history,
  match: {
    params: { propertyId },
  },
}) {
  const intl = useIntl();
  const { name, rows, footerRow, isLoading, count, totalCount } = useTableData({
    history,
  });

  return (
    <DocumentTitle title={intl.formatMessage(messages.tabTitle)}>
      <TableLayout name={name} childrenElementsHeight={36 + 8}>
        {(tableHeight) => (
          <>
            <TableHeader
              title={intl.formatMessage(messages.title)}
              count={count}
              totalCount={totalCount}
              actions={
                <ReactRouterLink to={`/property/${propertyId}/amenity`}>
                  <Button
                    variant="shout"
                    size="large"
                    fullWidth
                    startIcon={<AddIcon />}
                  >
                    {intl.formatMessage(messages.addAmenityButton)}
                  </Button>
                </ReactRouterLink>
              }
            />

            <Table
              name={name}
              height={tableHeight}
              headers={[
                {
                  id: 'amenity',
                  label: intl.formatMessage(messages.amenityColumn),
                  sortable: false,
                },
                {
                  id: 'value',
                  label: intl.formatMessage(messages.valueColumn),
                  variant: 'currency',
                  dataType: 'number',
                  sortable: false,
                },
                {
                  id: 'numOfUnits',
                  label: intl.formatMessage(messages.numOfUnitsColumn),
                  dataType: 'number',
                  sortable: false,
                },
                {
                  id: 'valueXUnits',
                  label: intl.formatMessage(messages.valueXUnitsColumn),
                  dataType: 'number',
                  sortable: false,
                },
                {
                  id: 'edit',
                  label: intl.formatMessage(messages.editColumn),
                  variant: 'iconButton',
                  sortable: false,
                },
              ]}
              rows={rows}
              emptyStateMessage={intl.formatMessage(messages.emptyStateMessage)}
              footerRow={footerRow}
              isLoading={isLoading}
              count={count}
              totalCount={totalCount}
            />
          </>
        )}
      </TableLayout>
    </DocumentTitle>
  );
}

export default ManageAmenities;
