import * as api from '../../utils/api';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { curryN, pathOr } from 'ramda';
import moment from 'moment';

import PropTypes from 'prop-types';

import {
  clearLoadedLedger,
  createApplicantRefundStatement,
  getAllSubjournals,
  getCustomerLedgerHeader,
  getCustomerOpsTransactions,
  getCustomerSecurityTransactions,
  handleSocketFinancialTransactionSuccess,
  refreshLedger,
  reverseTransaction,
  updatePaymentRestrictions,
} from './actions.js';
import { getWriteOffsForCustomer, promptToaster } from '../App/actions';
import { getWriteOffBalanceForCustomer } from '../App/selectors';
import LedgerHeader from './LedgerHeader/index.js';
import LedgerDetails from './LedgerDetails/index.js';
import PaginationFooter from '../../components/PaginationFooter/index.js';
import messages from './messages.js';
import type {
  LedgerHeaderData,
  LedgerTransactionsObject,
  ReverseProps,
  Subjournal,
  Transaction,
} from './types.js';
import type { DropdownOption, Property } from '../App/types.js';
import { FORM_NAME } from '../../components/CreateTransaction/constants';
import CreateTransaction from '../../components/CreateTransaction';
import {
  getPropertySubjournalTypes,
  getPropertyTransactionCodes,
  getTransactionTypes,
  handleSocketTransactionSuccess,
} from '../../components/CreateTransaction/actions';
import { ViewSingleTransactionModal } from '../../components/ViewSingleTransactionModal';
import { getTransactionIsReversible } from '../../utils/transaction-helpers';
import { DropDownsAndButton } from './DropDownsAndButtons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import ApplicantRefundModal from '../../components/ApplicantRefundModal';
import { LedgerFilterSection } from './LedgerFilterSection';
import { getApplication } from '../ApplicationProfile/selectors';

type Props = {
  customerStatus: string,
  customerId: string,
  customerName: string,
  headerData: LedgerHeaderData,
  subjournals: Array<Subjournal>,
  ops: LedgerTransactionsObject,
  security: LedgerTransactionsObject,
  organizationId: string,
  property: Property,
  frMembers: Array<Object>,
  frNames: string[],
  unit: Object,
  residentId: ?string,
  fasReady?: boolean,
  accountFinalized?: boolean,
  handleFasReadyClick?: Function,
  resendClick?: Function,
  ledgerEditsDisabled: boolean,
  household: Object,
  applicationId?: string,
  isResident: boolean,
  camPools?: Array<Object>,
  onZeroBalance?: Function,
  propertyPaymentProvider: string,
  householdMembers: Object[],
  lateMethodId?: string,
  fasDate?: string,
  hasMoveOutProratedTransactions: boolean,
};

type InjectedProps = {
  intl: any,
  actions: Object,
  balanceWrittenOff: number,
  transactionTypes: Array<Object>,
  transactionCodes: Array<Object>,
  propertySubjournalTypes: Array<Object>,
};

type State = {
  currentLedger: string,
  currentPage: number,
  subjournal: string,
  modal: ?string,
  filterReversed: boolean,
};

const renderFooterDefinitions = (formatMessage) => (
  <div className="container-fluid" data-test="ledger-footer">
    <div className="row legend-simple">
      <div className="col-xs-12">
        <p>{formatMessage(messages.statusDefinition)}</p>
      </div>
      <div className="col-xs-12">
        <p>{formatMessage(messages.manualDefinition)}</p>
      </div>
    </div>
  </div>
);

export class Ledger extends Component<Props & InjectedProps, State> {
  constructor(props: Props & InjectedProps) {
    super(props);
    this.state = {
      currentLedger: 'ops',
      currentPage: 1,
      subjournal: '',
      filterReversed: true,
      modal: null,
      queryFilters: null,
    };
  }

  componentWillUnmount() {
    this.props.actions.clearLoadedLedger();
  }

  componentDidMount() {
    const { organizationId, property } = this.props;
    this.props.actions.getPropertySubjournalTypes();
    this.props.actions.getPropertyTransactionCodes(organizationId, property);
    this.props.actions.getTransactionTypes();
    this.props.actions.getCustomerLedgerHeader(this.props.customerId);
    this.props.actions.getAllSubjournals(this.props.customerId);
    this.props.actions.getCustomerOpsTransactions(
      this.state.currentPage,
      this.props.customerId,
      this.state.filterReversed,
    );
    if (this.props.accountFinalized)
      this.props.actions.getWriteOffsForCustomer(this.props.customerId);
  }

  componentDidUpdate(prevProps: any) {
    const { accountFinalized, headerData } = this.props;
    const { headerData: prevHeaderData } = prevProps;
    if (prevProps.accountFinalized !== accountFinalized && accountFinalized)
      this.props.actions.getWriteOffsForCustomer(this.props.customerId);

    const balance =
      typeof headerData.balance === 'string'
        ? parseFloat((headerData.balance ?? '').replace('$', ''))
        : headerData.balance;
    const prevBalance =
      typeof prevHeaderData?.balance === 'string'
        ? parseFloat((prevHeaderData?.balance ?? '').replace('$', ''))
        : prevHeaderData?.balance;
    // Checking prev vs current to avoid unwanted calls
    if (prevBalance !== balance && balance <= 0) {
      this.props?.onZeroBalance?.();
    }
  }

  runLedgerAction = (ledger: string, page: number, filterReversed: boolean) => {
    this.setState({ filterReversed });
    if (ledger === 'ops') {
      this.props.actions.getCustomerOpsTransactions(
        page,
        this.props.customerId,
        filterReversed,
        this.state.subjournal,
      );
    } else if (ledger === 'security') {
      this.props.actions.getCustomerSecurityTransactions(
        page,
        this.props.customerId,
        filterReversed,
      );
    }
  };

  onPageChange = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.runLedgerAction(
      this.state.currentLedger,
      nextPage,
      this.state.filterReversed,
    );
  };

  onLedgerChange = ({ target: { value } }: Object) => {
    this.setState({
      ...this.state,
      currentLedger: value,
      subjournal: '',
      currentPage: 1,
    });
    this.runLedgerAction(value, 1, this.state.filterReversed);
  };

  onFilterChange = () => {
    this.runLedgerAction(
      this.state.currentLedger,
      1,
      !this.state.filterReversed,
    );
  };

  onSubjournalChange = ({ target: { value } }: Object) => {
    this.setState({ ...this.state, subjournal: value, currentPage: 1 });
    if (this.props.flags.filterChargesOnLedger) {
      return;
    }
    this.props.actions.getCustomerOpsTransactions(
      1,
      this.props.customerId,
      this.state.filterReversed,
      value,
    );
  };

  printFunction = () => window.print();

  reverseFunction = ({
    id,
    ledger,
    period,
    notes,
    isNsf,
    onReverse,
    camAllocationsId,
  }: ReverseProps) => {
    this.setState({ currentPage: 1, subjournal: '', filterReversed: false });
    this.props.actions.reverseTransaction(
      id,
      ledger,
      period,
      notes,
      this.props.customerId,
      this.props.residentId,
      false,
      isNsf,
      camAllocationsId,
    );
  };

  transactionCanBeReversed = (transaction: Transaction, isOps: boolean) => {
    // $FlowFixMe
    const allowDomusoReversals = pathOr(
      false,
      ['property', 'allowDomusoReversals'],
      this.props,
    );
    // $FlowFixMe
    const balanceWrittenOff = pathOr(0, ['balanceWrittenOff'], this.props);
    // $FlowFixMe
    const accountFinalized = pathOr(false, ['accountFinalized'], this.props);

    return getTransactionIsReversible(
      transaction,
      isOps,
      allowDomusoReversals,
      accountFinalized,
      balanceWrittenOff,
    );
  };

  onTransactionClick = (transaction: Transaction, journalIsOps: boolean) => {
    ViewSingleTransactionModal({
      isOps: journalIsOps,
      intl: this.props.intl,
      singleTransaction: transaction,
      isReversable: this.transactionCanBeReversed(transaction, journalIsOps),
      onPrint: this.printFunction,
      onReverse: this.reverseFunction,
      store: this.context.store,
      frNames: this.props.frMembers.map((person) => person.name).join(', '),
      camPools: this.props.camPools,
      promptToaster: this.props.actions.promptToaster,
      organizationId: this.props.organizationId,
      propertyId: this.props.property.id,
      householdId: this.props.household.id,
      householdHasMoveOutProratedTransactions:
        this.props.hasMoveOutProratedTransactions,
      refreshLedger: () =>
        this.props.actions.refreshLedger(
          this.props.customerId,
          this.state.filterReversed,
        ),
    });
  };

  onPaymentRestrictionChange = (
    changedField: any,
    { target: { checked } }: any,
  ) => {
    const { isResident, residentId, applicationId } = this.props;
    const profileId = isResident ? residentId : applicationId;
    let payload = {};
    payload[changedField] = checked;

    this.props.actions.updatePaymentRestrictions(
      payload,
      this.props.household.id,
      isResident,
      profileId,
    );
  };

  generateSubjournals = (
    subjournals: Array<Subjournal>,
  ): Array<DropdownOption> => {
    return subjournals
      ? subjournals.map((subjournal) => {
          return {
            value: subjournal.subjournalId,
            text: subjournal['subjournal.description'],
          };
        })
      : [];
  };

  handleFiltersChange = (queryFilters) => {
    this.setState({ queryFilters });
  };

  downloadLedger = (type: any, dateOrder: any) => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { organizationId, property, customerId } = this.props;
    const customer = `customerId=${customerId}`;
    const currentLedger = `currentLedger=${this.state.currentLedger}`;
    const dateOrderParam = `dateOrder=${dateOrder}`;
    const filters = this.state.queryFilters;
    const subjournalParam =
      this.state.currentLedger === 'ops' && this.state.subjournal !== ''
        ? `&subjournalId=${this.state.subjournal}`
        : '';
    const tzString = `&timeZone=${timeZone}`;
    const queryString = `${customer}&${currentLedger}&${dateOrderParam}${filters}${subjournalParam}${tzString}`;
    const requestUrl = `/${organizationId}/${property.id}/${type}-reports/Ledger?${queryString}`;
    if (property && property.id) {
      api.getDownload(
        requestUrl,
        `Ledger_Transactions_${moment().format('YYYY-MM-DD')}.${type}`,
      );
    }
  };

  showCreateTransaction = () => {
    this.setState({ modal: FORM_NAME });
  };

  resetModal = () => {
    this.setState({ modal: null });
  };

  ledgerReset = () => {
    this.setState({ ...this.state, currentPage: 1, subjournal: '' });
  };

  refundToApplicant = (headerData: LedgerHeaderData) => {
    const {
      balance,
      deposits: { runningSecurityBalanceHeld },
      subjournalsBalance,
    } = headerData;
    const subsidy = pathOr('0.0', ['Subsidy'], subjournalsBalance);
    const residentLedgerBalance = parseFloat(balance) - parseFloat(subsidy);
    const depositHeld = parseFloat(runningSecurityBalanceHeld);
    /* Security deposit held (made negative) plus
    resident ledger balance (all subjournals excluding subsidy subjournal balance) is
    < 0, then "Refund to Applicant" button on the ledger screen is active.*/
    return residentLedgerBalance - depositHeld;
  };

  showApplicantRefundModal = () => {
    this.setState({ modal: 'ApplicantRefundForm' });
  };

  handleApplicantRefundSubmit = (values: Object) => {
    const { createApplicantRefundStatement } = this.props.actions;
    const { property, household } = this.props;

    const householdId = this.props.household.id;
    const applicationId = this.props.applicationId;
    const customerId = this.props.customerId;
    const propertyAddress1 = pathOr('', ['physicalStreetAddress1'], property);
    const propertyAddress2 = pathOr('', ['physicalStreetAddress2'], property);
    const propertyCity = pathOr('', ['physicalCity'], property);
    const propertyState = pathOr('', ['physicalState'], property);
    const propertyZip = pathOr('', ['physicalZip'], property);
    let applicantRefundMailingAddress = {};
    if (values.mailingAddress === 'forwardingAddress') {
      applicantRefundMailingAddress = {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
        selectedMailingOption: 'Other Forwarding Address',
      };
    } else if (values.mailingAddress === 'propertyAddress') {
      applicantRefundMailingAddress = {
        address1: propertyAddress1,
        address2: propertyAddress2,
        city: propertyCity,
        state: propertyState,
        zipCode: propertyZip,
        selectedMailingOption: 'Property Address',
      };
    } else if (values.mailingAddress === 'primaryApplicantCurrentAddress') {
      applicantRefundMailingAddress = {
        address1: pathOr(
          '',
          ['mainCus', 'applicantCustomer', 'address', 'street'],
          household,
        ),
        address2: null,
        city: pathOr(
          '',
          ['mainCus', 'applicantCustomer', 'address', 'city'],
          household,
        ),
        state: pathOr(
          '',
          ['mainCus', 'applicantCustomer', 'address', 'state'],
          household,
        ),
        zipCode: pathOr(
          '',
          ['mainCus', 'applicantCustomer', 'address', 'zipCode'],
          household,
        ),
        selectedMailingOption: 'Primary Applicant Current Address',
      };
    }
    this.resetModal();
    this.setState({ currentPage: 1, subjournal: '', filterReversed: false });
    createApplicantRefundStatement({
      applicantRefundMailingAddress,
      applicationId,
      householdId,
      customerId,
      success: 'Saved Successfully',
    });
  };

  render() {
    const {
      accountFinalized,
      balanceWrittenOff,
      customerId,
      fasReady,
      frMembers,
      frNames,
      handleFasReadyClick,
      headerData,
      household,
      intl: { formatMessage, formatNumber },
      ledgerEditsDisabled,
      ops,
      property,
      propertySubjournalTypes,
      residentId,
      security,
      subjournals,
      transactionCodes,
      transactionTypes,
      unit,
      propertyPaymentProvider,
      customerStatus,
      lateMethodId,
      actions: {
        handleSocketFinancialTransactionSuccess,
        handleSocketTransactionSuccess,
      },
      householdMembers,
    } = this.props;
    const subjournalDropDownData = this.generateSubjournals(subjournals);
    const pfp = pathOr(null, ['pfp'], property);

    const ledgerData =
      this.state.currentLedger === 'ops'
        ? ops.transactions
        : security.transactions;
    const priorBalance = parseFloat(headerData.balance);
    const priorSecurityBalance = parseFloat(
      headerData.deposits.runningSecurityBalanceOwed,
    );
    const priorSubjournalBalances = headerData.subjournalsBalance;
    const paymentRestrictions = {
      doNotAccept: household.paymentsDoNotAccept,
      certifiedOnly: household.paymentsCertifiedOnly,
      doNotAcceptPartialPayments: household.doNotAcceptPartialPayments,
    };
    const onPaymentRestrictionChangeCurried = curryN(
      2,
      this.onPaymentRestrictionChange.bind(this),
    );
    const requiredDataLoaded =
      !!transactionTypes && !!transactionCodes && !!subjournals;
    const isAllCommercial = property.hasCommercialFloorPlans === 'ALL';

    const refundToApplicantAmount = this.refundToApplicant(headerData);

    return (
      <div data-test="ledger">
        <CreateTransaction
          customerStatus={this.props.customerStatus}
          fasDone={this.props.accountFinalized}
          customerId={customerId}
          residentId={residentId}
          residents={frMembers}
          transactionTypes={transactionTypes}
          transactionCodes={transactionCodes}
          subjournals={propertySubjournalTypes}
          propertyName={property.name}
          status={headerData.status}
          priorBalance={priorBalance}
          priorSecurityBalance={priorSecurityBalance}
          priorSubjournalBalances={priorSubjournalBalances}
          onClose={this.resetModal}
          show={this.state.modal === FORM_NAME}
          frNames={frNames}
          unit={unit}
          ledgerReset={this.ledgerReset}
          filterReversed={this.state.filterReversed}
          isAllCommercial={isAllCommercial}
          camPools={this.props.camPools}
          propertyFiscalPeriod={pfp}
          householdHasMoveOutProratedTransactions={
            this.props?.hasMoveOutProratedTransactions
          }
        />
        <LedgerHeader
          formatMessage={formatMessage}
          formatNumber={formatNumber}
          headerData={headerData}
          downloadLedger={this.downloadLedger}
          data-test="ledger-header"
          fasReady={fasReady}
          accountFinalized={accountFinalized}
          handleFasReadyClick={handleFasReadyClick}
          balanceWrittenOff={balanceWrittenOff}
          isAllCommercial={isAllCommercial}
          paymentRestrictions={paymentRestrictions}
          onPaymentRestrictionChange={onPaymentRestrictionChangeCurried}
          createTransactionDisabled={ledgerEditsDisabled || !requiredDataLoaded}
          showCreateTransaction={this.showCreateTransaction}
          refundToApplicantAmount={refundToApplicantAmount}
          onClickApplicantRefund={this.showApplicantRefundModal}
          residentId={residentId}
          isPrior={customerStatus === 'Prior Resident'}
          propertyPaymentProvider={propertyPaymentProvider}
          propertyId={this.props.property.id}
          organizationId={this.props.organizationId}
          householdMembers={householdMembers}
          lateMethodId={lateMethodId}
          unitNumber={unit?.number}
          householdId={this.props.household.id}
          fasDate={this.props.fasDate}
        />
        {this.props.flags.filterChargesOnLedger ? (
          <LedgerFilterSection
            ledger={this.state.currentLedger}
            formatMessage={formatMessage}
            onLedgerChange={this.onLedgerChange}
            onJournalChange={this.onSubjournalChange}
            subJournals={subjournalDropDownData}
            subjournal={this.state.subjournal}
            filterReversed={this.state.filterReversed}
            onFilterChange={this.onFilterChange}
            data-test="ledger-controls"
            selectedProperty={property}
            customerId={this.props.customerId}
            ledgerData={ledgerData}
            formatNumber={formatNumber}
            isOps={this.state.currentLedger === 'ops'}
            isAllSubjournals={this.state.subjournal === ''}
            onViewTransaction={this.onTransactionClick}
            viewTransactionDisabled={ledgerEditsDisabled}
            transactionTypes={transactionTypes}
            handleSocketFinancialTransactionSuccess={
              handleSocketFinancialTransactionSuccess
            }
            handleSocketTransactionSuccess={handleSocketTransactionSuccess}
            currentLedger={this.state.currentLedger}
            page={this.state.currentPage}
            onPageChange={this.onPageChange}
            onHandleFilters={this.handleFiltersChange}
          />
        ) : (
          <>
            <DropDownsAndButton
              ledger={this.state.currentLedger}
              formatMessage={formatMessage}
              onLedgerChange={this.onLedgerChange}
              onJournalChange={this.onSubjournalChange}
              subJournals={subjournalDropDownData}
              subjournal={this.state.subjournal}
              filterReversed={this.state.filterReversed}
              onFilterChange={this.onFilterChange}
              data-test="ledger-controls"
            />
            <LedgerDetails
              ledgerData={ledgerData}
              formatMessage={formatMessage}
              formatNumber={formatNumber}
              isOps={this.state.currentLedger === 'ops'}
              isAllSubjournals={this.state.subjournal === ''}
              onViewTransaction={this.onTransactionClick}
              viewTransactionDisabled={ledgerEditsDisabled}
              data-test="ledger-table"
            />
          </>
        )}
        <ApplicantRefundModal
          show={this.state.modal === 'ApplicantRefundForm'}
          onClose={this.resetModal}
          onSubmit={this.handleApplicantRefundSubmit}
          headerData={headerData}
          property={property}
          currentAddress={pathOr(
            null,
            ['mainCus', 'applicantCustomer', 'address'],
            household,
          )}
        />
        {this.props[this.state.currentLedger].meta.pageCount > 1 &&
        !this.props.flags.filterChargesOnLedger ? (
          <div className="container-fluid">
            <PaginationFooter
              currentPage={this.state.currentPage}
              limit={15}
              count={this.props[this.state.currentLedger].meta.count}
              totalCount={this.props[this.state.currentLedger].meta.totalCount}
              pageCount={this.props[this.state.currentLedger].meta.pageCount}
              onPageChange={this.onPageChange}
            />
          </div>
        ) : null}
        {renderFooterDefinitions(formatMessage)}
      </div>
    );
  }
}

Ledger.contextTypes = {
  store: PropTypes.any,
};

export const mapStateToProps = (state: any, ownProps: any): any => {
  const {
    app,
    ledger: { headerData, subjournals, ops, security, ledgerEditsDisabled },
    transactionTypes,
    currentProperty,
  } = state;
  const balanceWrittenOff = getWriteOffBalanceForCustomer(ownProps.customerId)(
    state,
  );
  return {
    headerData,
    subjournals,
    ops,
    security,
    balanceWrittenOff,
    ledgerEditsDisabled,
    organizationId: app.currentUser.user.organizationId,
    property: app.selectedProperty,
    transactionTypes: transactionTypes.types,
    transactionCodes: currentProperty.transactionCodes.codes,
    propertySubjournalTypes: currentProperty.subjournalTypes.types,
    currentApplication: getApplication(state),
    propertyPaymentProvider: app?.propertyPaymentMethod?.paymentProvider,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    actions: bindActionCreators(
      {
        getCustomerLedgerHeader,
        getAllSubjournals,
        getCustomerOpsTransactions,
        getCustomerSecurityTransactions,
        clearLoadedLedger,
        reverseTransaction,
        getPropertyTransactionCodes,
        getTransactionTypes,
        getPropertySubjournalTypes,
        getWriteOffsForCustomer,
        updatePaymentRestrictions,
        createApplicantRefundStatement,
        promptToaster,
        refreshLedger,
        handleSocketFinancialTransactionSuccess,
        handleSocketTransactionSuccess,
      },
      dispatch,
    ),
  };
};

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(Ledger)),
);
