import { find, pathEq, pathOr } from 'ramda';
import isNil from 'lodash/isNil';

import {
  HOME_PROGRAM_NAME,
  LIHTC_HOME_PROGRAM_NAME,
  LIHTC_PROGRAM_NAME,
} from '../GeneralAffordable/constants';

/**
 * Small utils for parsing out data. Only consumed by the exported function and
 * its reducer.
 */
const getFloorPlanPrograms = (application) =>
  pathOr(
    null,
    ['au', 'unit', 'floorPlan', 'floorPlanAffordablePrograms'],
    application,
  );

const getAffordableQualifications = (application) =>
  pathOr(null, ['affordableQualifications'], application);

const getAffordableQualificationHistories = (application) =>
  pathOr(null, ['affordableQualificationHistories'], application);

const getPropertyAffordableProgramId = (fpProgram) =>
  pathOr(null, ['propertyAffordableProgram', 'id'], fpProgram);

const getStatusText = (qualMatchingFpProgram) =>
  pathOr(
    'Not Completed',
    ['complianceApproval', 'name'],
    qualMatchingFpProgram,
  );

const findMatchingQualification = (
  propertyAffordableProgramId,
  affordableQualifications,
) =>
  find(
    pathEq(['propertyAffordableProgram', 'id'], propertyAffordableProgramId),
  )(affordableQualifications);

const determineStatusValidity = (statusText) =>
  ['Approved', 'Approved Pending Signatures'].includes(statusText);

const getProgramName = (qualMatchingFpProgram) =>
  pathOr(
    '',
    ['propertyAffordableProgram', 'masterAffordableProgram', 'name'],
    qualMatchingFpProgram,
  );

const generateComplianceStatus = (valid, title, statusText) =>
  valid
    ? { title, valid, statusText }
    : { title, valid, statusText, error: 'Must have Compliance Approval' };

/**
 * Curried reducer function. First called with the affordableQualification
 * records to return the actual reducer. The reducer is used on the floorplan's
 * affordable programs to determine if an application has had approval from
 * compliance on all necessary certifications for move-in.
 */
const complianceStatusReducer =
  (affordableQualifications) => (statuses, fpProgram) => {
    const propertyAffordableProgramId =
      getPropertyAffordableProgramId(fpProgram);
    if (!propertyAffordableProgramId) {
      return statuses;
    }

    const qualMatchingFpProgram = findMatchingQualification(
      propertyAffordableProgramId,
      affordableQualifications,
    );

    const statusText = getStatusText(qualMatchingFpProgram);
    const valid = determineStatusValidity(statusText);
    const programName = getProgramName(qualMatchingFpProgram);
    const title = `Compliance Approval on ${programName} Qualification Tab`;

    const complianceStatus = generateComplianceStatus(valid, title, statusText);

    return [...statuses, complianceStatus];
  };

/**
 * Takes an application and reduces affordable qualification down to an array of
 * statuses for move-in
 */
export const generateComplianceStatusArray = (
  application: ?Object,
  propertyAffordablePrograms: Array<Object>,
  flags: Object,
): Array<Object> => {
  const { homeEnhancements = false } = flags ?? {};
  const floorPlanPrograms = getFloorPlanPrograms(application);

  if (!floorPlanPrograms) {
    return [];
  }

  const affordableQualifications = getAffordableQualifications(application);
  const affordableQualificationHistories =
    getAffordableQualificationHistories(application);

  const affordableQualificationsWithHistory = (
    affordableQualifications || []
  ).concat(affordableQualificationHistories);

  const lihtcHomeProgram = (propertyAffordablePrograms ?? []).find(
    (pap) => pap?.masterAffordableProgram?.name === LIHTC_HOME_PROGRAM_NAME,
  );
  const hasLihtcAndHomePrograms =
    !isNil(
      floorPlanPrograms.find(
        (fpap) =>
          fpap?.propertyAffordableProgram?.masterAffordableProgram?.name ===
          LIHTC_PROGRAM_NAME,
      ),
    ) &&
    !isNil(
      floorPlanPrograms.find(
        (fpap) =>
          fpap?.propertyAffordableProgram?.masterAffordableProgram?.name ===
          HOME_PROGRAM_NAME,
      ),
    );

  const fpPrograms =
    homeEnhancements && !isNil(lihtcHomeProgram) && hasLihtcAndHomePrograms
      ? [{ propertyAffordableProgram: lihtcHomeProgram }]
      : floorPlanPrograms;

  return fpPrograms.reduce(
    complianceStatusReducer(affordableQualificationsWithHistory),
    [],
  );
};
