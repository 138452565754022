import React, { Component } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { confirmable, createConfirmation } from 'react-confirm';
import { pathOr } from 'ramda';

type Props = {
  show: boolean,
  dismiss: Function,
  confirmation: any,
  options: Object,
  proceed: Function,
};

type State = {
  isSubmitting: boolean,
};

export class ConfirmDialog extends Component<Props, State> {
  constructor(props: State & Props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  render() {
    const { show, dismiss, confirmation, options } = this.props;
    const confirmationBody = pathOr(null, ['body'], options);
    const altConfirmationBody = pathOr(null, ['altBody'], options);
    const cancelFunc = pathOr(null, ['cancelFunc'], options);
    const onHideOrCancel = () => {
      if (cancelFunc) {
        cancelFunc();
      }
      dismiss();
    };
    return (
      <div className="static-modal">
        <Modal
          bsSize="small"
          dialogClassName="modal-dialog modal-alert"
          show={show}
          onHide={onHideOrCancel}
          autoFocus={true}
        >
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <h2 className="text-center">{confirmation}</h2>
                {confirmationBody && (
                  <p className="text-center">{confirmationBody}</p>
                )}
                {altConfirmationBody && (
                  <div className="text-center">{altConfirmationBody}</div>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-primary center-block"
              type="button"
              bsStyle="primary"
              onClick={onHideOrCancel}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const confirm = createConfirmation(confirmable(ConfirmDialog));
export default function (confirmation: any, options: {} = {}) {
  return confirm({ confirmation, options });
}
