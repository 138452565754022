import { get, put, del } from '../utils/api';

export default class AffordableQualificationDocumentService {
  async updateAffordableQualificationDocument(
    organizationId: string,
    propertyId: string,
    affordableQualificationDocumentId: string,
    updates: any,
  ) {
    // eslint-disable-next-line max-len
    return put(
      `/${organizationId}/${propertyId}/affordable-qualification-document/${affordableQualificationDocumentId}`,
      // Not sure why the put helper doesn't already handle stringify but ¯\_(ツ)_/¯
      JSON.stringify(updates),
    );
  }
  async deleteAffordableQualificationDocument(
    organizationId: string,
    propertyId: string,
    affordableQualificationDocumentId: string,
  ) {
    // eslint-disable-next-line max-len
    return del(
      `/${organizationId}/${propertyId}/affordable-qualification-document/${affordableQualificationDocumentId}`,
    );
  }

  async getComplianceDocumentsNeedsReview(organizationId: string) {
    // eslint-disable-next-line max-len
    return get(
      `/${organizationId}/affordable-qualification-document/needs-review`,
    );
  }
}
