import { get } from '../utils/api';

class DocumentTypeService {
  getAll(
    organizationId: string,
    propertyId: string,
    applicationId: ?string,
    ignoreShowForManualUploads: ?boolean,
    options?: Object,
  ) {
    const queryAppId = applicationId ? `applicationId=${applicationId}` : '';
    const queryShowManualUpload = ignoreShowForManualUploads
      ? 'ignoreShowForManualUploads=true'
      : '';

    return get(
      `/${organizationId}/${propertyId}/documentTypes?${queryAppId}&${queryShowManualUpload}`,
      options,
    );
  }

  getResidentLetterDocumentTypes(
    organizationId: string,
    propertyId: string,
    applicationId?: string,
    excludeBatchLetters?: boolean = true,
  ) {
    const queryStr = `?${
      applicationId ? `applicationId=${applicationId}&` : ''
    }excludeBatchLetters=${excludeBatchLetters ? 'true' : 'false'}`;
    return get(`/${organizationId}/${propertyId}/residentLetters${queryStr}`);
  }

  getAllAffordableDocuments(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(`/${organizationId}/${propertyId}/affordableDocuments`, options);
  }

  getComplianceDocumentFilterGroups(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/complianceDocumentFilterGroups`,
      options,
    );
  }
}

export default DocumentTypeService;
