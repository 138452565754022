import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ContactInformationSection.Title',
    defaultMessage: 'Contact Information'
  },
  phoneLabel: {
    id: 'App.ContactInformationSection.PhoneLabel',
    defaultMessage: 'Cell Phone'
  },
  phonePlaceholder: {
    id: 'App.ContactInformationSection.PhonePlaceholder',
    defaultMessage: 'Phone Number'
  },
  emailLabel: {
    id: 'App.ContactInformationSection.EmailLabel',
    defaultMessage: 'Email'
  },
  emailPlaceholder: {
    id: 'App.ContactInformationSection.EmailPlaceholder',
    defaultMessage: 'Email Address'
  }
});

export default messages;
