import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ForbiddenPage.header',
    defaultMessage: 'Sorry, no access!'
  },
  message: {
    id: 'App.ForbiddenPage.message',
    defaultMessage: 'It seems like you don’t have access to this page.'
  },
  message2: {
    id: 'App.ForbiddenPage.message2',
    defaultMessage: 'Please contact your system administrator.'
  },
  button: {
    id: 'App.ForbiddenPage.button',
    defaultMessage: 'Go Back'
  }
});

export default messages;
