import type { Action } from '../App/types';
import type { DepositsState } from './types';
import { sortWith, prop, ascend, descend } from 'ramda';
import {
  GET_ALL_DEPOSITS,
  GET_ALL_DEPOSITS_SUCCESS,
  GET_ALL_DEPOSITS_ERROR,
  CLEAR_LOADED_DEPOSITS,
  SORT_DEPOSITS_BY_DATE
} from './constants';

export const initialState = {
  deposits: [],
  dateSort: 'none'
};

const depositsReducer = (
  state: DepositsState = initialState,
  action: Action<*, *>
): Object => {
  const { type, payload, error } = action;
  switch (type) {
    case GET_ALL_DEPOSITS_SUCCESS: {
      return { ...state, deposits: payload };
    }
    case GET_ALL_DEPOSITS_ERROR: {
      return { ...state, depositsError: payload, error };
    }
    case CLEAR_LOADED_DEPOSITS: {
      return { ...initialState };
    }
    case SORT_DEPOSITS_BY_DATE: {
      const dateSort =
        state.dateSort === 'none' || state.dateSort === 'dec' ? 'asc' : 'dec';
      const getDate = prop('batchDate');
      return {
        dateSort,
        deposits:
          dateSort === 'asc'
            ? // $FlowFixMe
              sortWith([ascend(getDate)], state.deposits)
            : // $FlowFixMe
              sortWith([descend(getDate)], state.deposits)
      };
    }
    case GET_ALL_DEPOSITS:
    default:
      return state;
  }
};

export default depositsReducer;
