import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ErrorBoundary.Title',
    defaultMessage: 'Unexpected Fortress Error'
  },
  confirmMessage: {
    id: 'App.ErrorBoundary.ConfirmMessage',
    defaultMessage: 'Please contact your system administrator: {error}'
  },
  submit: {
    id: 'App.ErrorBoundary.Submit',
    defaultMessage: 'OK'
  }
});

export default messages;
