export const QUOTE_EXPIRATION_OPTIONS = [
  { value: 'SAME_DAY', label: 'Same Day' },
  { value: 'HOURS_24', label: '24 Hours' },
  { value: 'HOURS_48', label: '48 Hours' },
  { value: 'HOURS_72', label: '72 Hours' },
];

export const SUCCESSIVE_LEASE_TERM_MAP = {
  monthly: 'monthToMonth',
  annual: 'annual',
};

export const SUCCESSIVE_LEASE_TERM_OPTIONS = [
  { value: 'monthly', label: 'monthToMonth' },
  { value: 'annual', label: 'annual' },
];

export const HOUSING_ASSISTANCE_VOUCHER_OPTIONS = [
  { value: 'true', label: 'Yes - Accepts Housing Assistance' },
  { value: 'false', label: 'No - Does Not Accept Housing Assistance' },
];
