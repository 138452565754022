import { useController } from 'react-hook-form';
// eslint-disable-next-line max-len
import { SimpleCheckbox as SimpleCheckboxComponentLibrary } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';

const Checkbox = (props) => {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return (
    <Stack>
      <SimpleCheckboxComponentLibrary
        {...props}
        error={error}
        onChange={(event) => field.onChange(!field.value)}
        onBlur={field.onBlur}
        value={field.value}
        name={field.name}
        inputRef={field.ref}
        checked={field.value}
      />
    </Stack>
  );
};

export default Checkbox;
