import React, { Fragment, useState } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { pathOr } from 'ramda';

import DocumentSelection from './DocumentSelection';
import DocumentNotes from './DocumentNotes';
import {
  useUploadDepositAttachment,
  useUpdateDepositAttachment,
} from './hooks.js';
import messages from './messages';
import Spinner from '../../../components/Spinner';
import Alert from '../../../components/Alert';

type OpenMode = 'create' | 'edit';

type ModalProps = {
  intl: Object,
  show: boolean,
  document: Object, // Only if editing, for new ones it'll be null
  mode: OpenMode,
  depositId: string,
  selectedPropertyId: string,
  selectedOrganizationId: string,
  onClose: Function,
  promptToaster: Function,
  refresh: Function,
};

let AddBankDepositDocument = ({
  intl,
  show,
  document,
  mode,
  selectedPropertyId,
  selectedOrganizationId,
  depositId,
  onClose,
  promptToaster,
  refresh,
}: ModalProps) => {
  const hooksArgs = {
    intl,
    propertyId: selectedPropertyId,
    organizationId: selectedOrganizationId,
    depositId,
    promptToaster,
    refresh,
  };

  const initialFilename = pathOr('', ['name'], document);
  const initialNotes = pathOr('', ['notes'], document);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [filename, setFilename] = useState(initialFilename);
  const [file, setFile] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState('');
  const [notes, setNotes] = useState(initialNotes);

  const [uploaderIsSaving, onUploadDocument] = useUploadDepositAttachment(
    hooksArgs,
  );
  const [updaterIsSaving, onUpdateDocument] = useUpdateDepositAttachment(
    hooksArgs,
  );

  const isSaving = uploaderIsSaving || updaterIsSaving;

  const onFileSelectionChanged = (event: any) => {
    if (!(event && event.target && event.target.value)) {
      setErrorMsg(intl.formatMessage(messages.fileSelectionError));
    }

    setErrorMsg('');
    const filename = event.target.value.replace(/.*(\/|\\)/, '');
    const file = event.target.files[0];
    setFilename(filename);
    setFile(file);
  };

  const reset = () => {
    setShowCancelConfirmation(false);
    setFilename('');
    setFile(undefined);
    setErrorMsg('');
    setNotes('');
  };

  const onHide = () => {
    reset();
    onClose();
  };

  const saveAndClose = async () => {
    const cb = () => {
      refresh();
      onHide();
    };
    onUploadDocument({ file, notes }, () => cb);
  };

  const saveAndAddNew = async () => {
    const cb = () => {
      refresh();
      reset();
    };
    onUploadDocument({ file, notes }, () => cb);
  };

  const save = async () => {
    const cb = () => {
      refresh();
      onHide();
    };
    onUpdateDocument(document, notes, () => cb);
  };

  const isSaveDisabled = () => {
    const hasNoFile = !(file !== undefined);
    const hasError = errorMsg && errorMsg.length > 0;
    return showCancelConfirmation || isSaving || hasError || hasNoFile;
  };

  return (
    <form encType="multipart/form-data">
      <div className="mainbody">
        <Modal backdrop bsSize="lg" show={show} onHide={onHide}>
          <Modal.Header closeButton>
            <i className="icon et-upload" />
            <Modal.Title componentClass="h1">
              {intl.formatMessage(messages.title)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert
              message={intl.formatMessage(messages.confirmCancel)}
              onDismiss={() => setShowCancelConfirmation(false)}
              onProceed={onHide}
              show={showCancelConfirmation}
            />

            {isSaving && (
              <Row>
                <div className="loading-container text-center">
                  <Spinner small />
                  <h5>{intl.formatMessage(messages.uploading)}</h5>
                </div>
              </Row>
            )}

            <DocumentSelection
              intl={intl}
              show={!isSaving}
              onChange={onFileSelectionChanged}
              filename={filename}
              error={errorMsg}
              disable={mode === 'edit'}
            />

            <DocumentNotes
              intl={intl}
              show={!isSaving}
              notes={notes}
              onChange={(evt) => setNotes(evt.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Row>
              {mode === 'create' ? (
                <Fragment>
                  <Col xs={4}>
                    <Button
                      bsStyle="default"
                      className="pull-right"
                      onClick={() => setShowCancelConfirmation(true)}
                      disabled={showCancelConfirmation || isSaving}
                    >
                      {intl.formatMessage(messages.cancel)}
                    </Button>
                  </Col>
                  <Col xs={4} className="text-center">
                    <Button
                      bsStyle="primary"
                      onClick={saveAndClose}
                      disabled={isSaveDisabled()}
                    >
                      {intl.formatMessage(messages.saveAndClose)}
                    </Button>
                  </Col>

                  <Col xs={4}>
                    <Button
                      bsStyle="primary"
                      className="pull-left"
                      onClick={saveAndAddNew}
                      disabled={isSaveDisabled()}
                    >
                      {intl.formatMessage(messages.saveAndAddNew)}
                    </Button>
                  </Col>
                </Fragment>
              ) : (
                <Fragment>
                  <Col xs={12} sm={6}>
                    <Button
                      bsStyle="default"
                      className="pull-right"
                      onClick={() => setShowCancelConfirmation(true)}
                      disabled={showCancelConfirmation || isSaving}
                    >
                      {intl.formatMessage(messages.cancel)}
                    </Button>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Button
                      bsStyle="primary"
                      className="pull-left"
                      onClick={save}
                    >
                      {intl.formatMessage(messages.save)}
                    </Button>
                  </Col>
                </Fragment>
              )}
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    </form>
  );
};

export default AddBankDepositDocument;
