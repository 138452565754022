import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { FolderOpenIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { pathOr } from 'ramda';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import messages from './messages';
import { renderRadioOption } from '../../../utils/redux-form-helper';
import { RESIDENT_LETTER_FORM_NAME } from '../constants';

type Props = {
  handleSubmit: Function,
  floorPlan: Object,
  intl: Object,
  letters: Array<any>,
  onClose: Function,
  onSubmit: Function,
  reset: Function,
  residentLetterId: ?string,
  show: boolean,
  titleText?: string,
  buttonText?: string,
  isAffordable?: boolean,
  isCommercial?: boolean,
};

const RenderModalFooter = ({
  formatMessage,
  dismiss,
  handleSubmit,
  valid,
  buttonText,
}) => {
  return (
    <div className="modal-footer">
      <div className="row">
        <div className="col-xs-6">
          <button className="btn btn-default pull-right" onClick={dismiss}>
            {formatMessage(messages.cancel)}
          </button>
        </div>
        <div className="col-xs-6">
          <button
            className="btn btn-primary pull-left"
            type="submit"
            onClick={handleSubmit}
            disabled={!valid}
          >
            {buttonText || formatMessage(messages.generateLetter)}
          </button>
        </div>
      </div>
    </div>
  );
};

const RenderModalBody = ({ formatMessage, isAllCommercial, children }) => {
  return (
    <div
      className="scrollable-modal__content row"
      style={{
        maxHeight: 600,
        overflowY: 'auto',
      }}
    >
      <div className="container-fluid">
        <p className="no-margin-top">
          {isAllCommercial
            ? formatMessage(messages.tenantSelectDocuments)
            : formatMessage(messages.selectDocuments)}{' '}
        </p>
        {children}
      </div>
    </div>
  );
};

const RenderDocumentsList = ({ floorPlan, letters, isAffordable }) => {
  const documentsList = letters.map(({ text, value }, i) => {
    return (
      <li key={i}>
        <div className="form-group">
          <div className="radio">
            <Field
              name="residentLetterId"
              component={renderRadioOption}
              option={{ value, text }}
              bsSize="lg"
              label={text}
            />
          </div>
        </div>
      </li>
    );
  });
  return <ul className="list-select">{documentsList}</ul>;
};

export class ViewQualificationForms extends Component<Props> {
  render() {
    let {
      floorPlan,
      handleSubmit,
      intl: { formatMessage },
      letters: letterOptions,
      onClose,
      reset,
      show,
      titleText,
      buttonText,
      isAffordable,
      isAllCommercial,
    } = this.props;

    const letters = letterOptions.sort((a, b) =>
      a.text.localeCompare(b.text, undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    );

    const title = titleText
      ? titleText
      : isAllCommercial
      ? formatMessage(messages.tenantLetterLibrary)
      : formatMessage(messages.residentLetterLibrary);

    return (
      <form>
        <Modal
          open={show}
          onClose={onClose}
          icon={<FolderOpenIcon />}
          title={title}
          ModalProps={{
            PaperProps: {
              sx: {
                width: {
                  xs: 'calc(100% - 32px)',
                  sm: 480,
                },
                maxHeight: 'calc(100vh - 100px)',
              },
            },
          }}
          hideActions
        >
          <RenderModalBody
            formatMessage={formatMessage}
            isAllCommercial={isAllCommercial}
          >
            <RenderDocumentsList
              floorPlan={floorPlan}
              letters={letters}
              isAffordable={isAffordable}
            />
          </RenderModalBody>
          <RenderModalFooter
            formatMessage={formatMessage}
            dismiss={() => {
              onClose();
              reset('residentLetterId');
            }}
            handleSubmit={() => {
              handleSubmit();
              reset('residentLetterId');
            }}
            valid={!!this.props.residentLetterId}
            buttonText={buttonText}
          />
        </Modal>
      </form>
    );
  }
}

export const mapStateToProps = ({ form, residentProfile }: Object): Object => {
  const selector = formValueSelector(RESIDENT_LETTER_FORM_NAME);
  return {
    floorPlan: pathOr({}, ['unit', 'floorPlan'], residentProfile),
    residentLetterId: selector({ form }, 'residentLetterId'),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: RESIDENT_LETTER_FORM_NAME,
    enableReinitialize: true,
  })(ViewQualificationForms),
);
