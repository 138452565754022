const UPDATE_ADDENDUMS = 'UPDATE_ADDENDUMS';

const initialState = {
  addendums: [],
};

function leasePacketReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ADDENDUMS: {
      return {
        ...state,
        addendums: action.payload,
      };
    }
    default:
      return state;
  }
}

export const updateAddendums = (addendums) => ({
  type: UPDATE_ADDENDUMS,
  payload: addendums,
});

export const getSelectedOptionalAddendumIds = (state) =>
  state.leasePacket.addendums
    .filter((a) => a.isRequired !== true && a.isSelected === true)
    .map((a) => a.id);

export default leasePacketReducer;
