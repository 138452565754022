import { assocPath, isNil } from 'ramda';

import {
  validateRequired,
  validatePositiveMoney,
} from '../../../utils/validations';

const validate = (values: Object, props: Object) => {
  let errors = {};
  const requiredValues = [];
  const numericValues = [];

  Object.keys(values)
    .filter((v) => !!v)
    .forEach((key) => {
      requiredValues.splice(
        requiredValues.length,
        0,
        [key, 'transactionType'],
        [key, 'subjournal'],
        [key, 'transactionCode'],
        [key, 'amount'],
        [key, 'customer'],
      );

      numericValues.splice(numericValues.length, 0, [key, 'amount']);
    });
  requiredValues.forEach((fieldPath) => {
    const key = fieldPath[0];
    const fieldName = fieldPath[1];
    if (!isNil(values[key]) && !validateRequired(fieldName, values[key])) {
      errors = assocPath([key, fieldName], 'This field is required', errors);
    }
  });
  numericValues.forEach((fieldPath) => {
    const key = fieldPath[0];
    const fieldName = fieldPath[1];
    if (errors && errors[key] && errors[key][fieldName]) {
      return errors;
    }
    if (
      !isNil(values[key]) &&
      (!validatePositiveMoney(values[key][fieldName]) ||
        !parseInt(values[key][fieldName]))
    ) {
      errors = assocPath(
        [key, fieldName],
        'Please enter a valid number greater than 0',
        errors,
      );
    }
  });

  return errors;
};

export default validate;
