import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { curryN, head, is, isNil, pathOr, prop, trim } from 'ramda';
import { parse } from 'qs';
import DocumentTitle from 'react-document-title';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { FilterDrawer } from '@fortress-technology-solutions/fortress-component-library/Organisms';

import type { OrderValue } from '../App/types';

import { renderNoDataToastr } from '../../utils/redux-form-helper';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import confirm from '../../components/ConfirmDialogModal';
import UnitSpecials from '../../components/UnitSpecials';
import ManageLayout from '../../components/ManageLayout';
import Table from '../../components/Table';

// $FlowFixMe
import { download } from '../../utils/downloadFile.js';
import {
  assignUnit,
  cleanLoadedUnits,
  getAllPropertySpecials,
  getAllUnits,
  getAllUnitStatuses,
  getAssignedUnits,
  getUnitQuote,
  savePropertySpecial,
  unassignUnit,
} from './actions';

import { transferLease } from '../ResidentProfile/actions';
import type { Props, State, StateProps } from './types';
import messages from './messages';
import filterDrawerMessages from '../../components/FilterDrawer/messages';
import {
  getCSV,
  getFilteredUnits,
  getHeaders,
  getUnitsStatusSummary,
  hasFilters,
  parseCSV,
} from './utils';
import { ManageAvailabilityTableFooter } from './ManageUnitAvailabilityFooter';
import { TableRows } from './TableRows';
import { appendFilterTextToCSV } from '../../utils/csv-helpers';
import SearchActions from '../../components/SearchActions';
import SummaryBlock from './SummaryBlock';

export class ManageUnitAvailability extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
      filter: {
        leased: {},
        statuses: {},
      },
      showFilter: false,
      specialsEdit: false,
      specialsText: '',
      query: {
        leased: {},
        searchText: '',
        statuses: {},
        sorting: { fieldName: 'moveInReady', order: 'DESC' },
      },
      previousApplicant: {},
      isLoading: false,
    };
  }

  static defaultProps = {
    headers: [
      { id: 'unit', title: <FormattedMessage {...messages.unit} /> },
      { id: 'floorPlan', title: <FormattedMessage {...messages.floorPlan} /> },
      { id: 'bedsBaths', title: <FormattedMessage {...messages.bedsBaths} /> },
      { id: 'sqFeet', title: <FormattedMessage {...messages.sqFeet} /> },
      { id: 'status', title: <FormattedMessage {...messages.status} /> },
      { id: 'leased', title: <FormattedMessage {...messages.leased} /> },
      {
        id: 'resident',
        title: <FormattedMessage {...messages.resident} />,
      },
      {
        id: 'tenant',
        title: <FormattedMessage {...messages.tenant} />,
      },
      {
        id: 'marketRent',
        title: <FormattedMessage {...messages.marketRent} />,
      },
      {
        id: 'priorLeasedRent',
        title: <FormattedMessage {...messages.priorLeasedRent} />,
      },
      {
        id: 'currentLeasedRent',
        title: <FormattedMessage {...messages.currentLeasedRent} />,
      },
      {
        id: 'moveOutDate',
        title: <FormattedMessage {...messages.moveOutDate} />,
        classes: 'header-darker',
      },
      {
        id: 'moveInReady',
        title: <FormattedMessage {...messages.moveInReady} />,
        classes: 'header-darker',
      },
      {
        id: 'notes',
        title: <FormattedMessage {...messages.notes} />,
        classes: 'header-darker',
      },
      {
        id: 'daysVacant',
        title: <FormattedMessage {...messages.daysVacant} />,
        classes: 'header-darker',
      },
      {
        id: 'quotingRent',
        title: <FormattedMessage {...messages.quotingRent} />,
        classes: 'header-darker',
      },
      {
        id: 'otherUnitFeesAvailable',
        title: <FormattedMessage {...messages.requiredUnitFees} />,
        classes: 'header-darker',
      },
      {
        id: 'applicant',
        title: <FormattedMessage {...messages.applicant} />,
        classes: 'header-darker',
      },
      {
        id: 'applicantStatus',
        title: <FormattedMessage {...messages.applicantStatus} />,
        classes: 'header-darker',
      },
      {
        id: 'scheduledMoveInDate',
        title: <FormattedMessage {...messages.scheduledMoveInDate} />,
        classes: 'header-darker',
      },
      {
        id: 'actions',
        title: <FormattedMessage {...messages.actions} />,
        order: null,
      },
    ],
  };

  componentWillMount() {
    this.props.actions.cleanLoadedUnits();

    try {
      const applicant = localStorage.getItem('previous-applicant-id');
      if (applicant) {
        const applicantObj = JSON.parse(applicant);
        this.setState({ previousApplicant: applicantObj });
        localStorage.removeItem('previous-applicant-id');
      }
    } catch (e) {
      /* empty */
    }
  }

  componentDidMount() {
    const { intl } = this.props;
    this.props.actions.getAllUnits({ available: true, intl });
    this.props.actions.getAssignedUnits();
    this.props.actions.getAllPropertySpecials();
    this.props.actions.getAllUnitStatuses();
  }

  unassignUnit(assignedUnitId: string, application: Object, intl: Object) {
    const { unassignUnit } = this.props.actions;
    unassignUnit(assignedUnitId, application.id, intl);
  }

  unassignUnitConfirm(assignedUnitId: string, application: Object, intl: any) {
    if (!assignedUnitId) return false;
    const { firstName, lastName } = application.prospectInfo;
    confirm(
      `${intl.formatMessage(
        messages.confirmUnassignUnit,
      )} ${firstName} ${lastName}?`,
      {
        intl,
      },
    )
      .then(() =>
        this.unassignUnit(assignedUnitId, application.id, this.props.intl),
      )
      .catch(() => {});
  }

  assignUnit(unitId: string, application: Object, intl: Object) {
    const { transferring, residentId, user } = this.props;
    if (transferring && residentId) {
      this.props.actions.transferLease({
        applicationId: application.id,
        userId: user.id,
        residentId,
        unitId,
      });
    } else {
      this.props.actions.assignUnit({
        unitId,
        applicationId: application.id,
        intl,
      });
    }
  }

  assignUnitConfirm(unitId: string, application: Object, intl: any) {
    if (!unitId) return false;
    const { transferring } = this.props;
    const { firstName, lastName } = application.prospectInfo;
    confirm(
      `${intl.formatMessage(
        transferring
          ? messages.confirmTransferUnit
          : messages.confirmAssignUnit,
      )} ${firstName} ${lastName}?`,
      { intl },
    )
      .then(() => this.assignUnit(unitId, application, intl))
      .catch(() => {});
  }

  onViewLinkClick = (type: string, residentId: string) =>
    navigateToUrlWithSelectedPropertyId(`/${type}/${residentId}`);

  downloadCSV = async () => {
    const { props, state } = this;
    const { intl, organizationId, selectedProperty } = props;
    const isAllCommercial = selectedProperty.hasCommercialFloorPlans === 'ALL';

    if (selectedProperty && selectedProperty.id) {
      const hasAnyFilters =
        Object.values(state.filter.leased).some((value) => !!value) ||
        Object.values(state.filter.statuses).some((value) => !!value) ||
        (state.filter?.amenities?.length ?? 0) > 0 ||
        !!state.query.searchText?.length;

      const data = await getCSV({
        organizationId,
        selectedPropertyId: selectedProperty.id,
        query: state.query,
      });

      const filteredUnits = getFilteredUnits(
        props.units,
        state.query,
        props.location,
      );

      const filteredUnitIds = filteredUnits.map(({ id }) => id);

      const orderedUnits = [];
      // push to array based on order of [filteredUnitIds] to keep units in sorted order
      filteredUnitIds.forEach((unitId) => {
        const currentUnit = data.units.find((unit) => unit.id === unitId);
        if (currentUnit) orderedUnits.push(currentUnit);
      });

      data.units = orderedUnits;

      const { headers, rows } = await parseCSV({
        data,
        intl,
        isAllCommercial,
        organizationId,
        propsHeaders: props.headers,
        selectedPropertyId: selectedProperty.id,
        isColumnSorted: state.query.sorting.fieldName !== 'moveInReady',
      });

      const csv = appendFilterTextToCSV({ headers, rows, hasAnyFilters });

      download(csv, 'ManageUnitAvailability.csv', 'text/csv;charset=utf-8');
    }
  };

  handleSort = ({ fieldName, order }: OrderValue) => {
    const sorting = {
      fieldName: fieldName === 'tenant' ? 'resident' : fieldName,
      order,
    };
    const query = { ...this.state.query, sorting };
    this.setState({ query });
  };

  handleSubmit = ({ searchText }: Object) => {
    const nextQuery = {
      ...this.state.query,
      searchText,
    };
    this.setState({ query: nextQuery });
  };

  toggleSpecialsEdit = () => {
    const { specialsForm, specials } = this.props;

    const description =
      is(Array, specials) && head(specials)
        ? // $FlowFixMe
          prop('description', head(specials))
        : '';

    this.state.specialsEdit
      ? this.setState({ specialsEdit: false }, () => {
          const special = pathOr('', ['values', 'specials'], specialsForm);
          if (trim(description) !== trim(special)) {
            this.submitSpecialsCallback();
          }
        })
      : this.setState({ specialsEdit: !this.state.specialsEdit });
  };

  submitSpecialsCallback = () => {
    const {
      specialsForm: {
        // $FlowFixMe
        values,
      },
      actions: { savePropertySpecial },
      // $FlowFixMe
      organizationId,
      selectedProperty,
      specials,
    } = this.props;

    const special: Object = {
      organizationId,
      // $FlowFixMe
      propertyId: selectedProperty.id,
      description: values.specials,
      startDate: moment().toDate(),
    };

    if (!!(is(Array, specials) && head(specials))) {
      special.id = prop('id', head(specials));
    }

    savePropertySpecial(special);

    this.setState({
      specialsEdit: false,
    });
  };

  goBackToApplicant = (applicantId: string) => {
    navigateToUrlWithSelectedPropertyId(`/application/${applicantId}`);
  };

  goBackToResident = (residentId: string) => {
    navigateToUrlWithSelectedPropertyId(`/resident/${residentId}`);
  };

  goBackToProspect = (prospectId: string) => {
    navigateToUrlWithSelectedPropertyId(`/prospect/${prospectId}`);
  };

  handleFilterClick = () => {
    this.setState((prev) => ({ showFilter: !prev.showFilter }));
  };

  clearFilters = () => {
    this.setState((prev) => ({
      query: {
        ...prev.query,
        leased: {},
        statuses: {},
        amenities: [],
      },
      filter: {
        leased: {},
        statuses: {},
        amenities: [],
      },
    }));
  };

  onFilterChange = (
    field: string,
    filterValue: string,
    value: boolean | Array<string>,
  ) => {
    this.setState((prev) => {
      const newFilter = isNil(filterValue)
        ? {
            filter: {
              ...prev.filter,
              [field]: value,
            },
          }
        : {
            filter: {
              ...prev.filter,
              [field]: {
                ...prev.filter[field],
                [filterValue]: value,
              },
            },
          };

      return newFilter;
    });
  };

  onApplyFiltersClick = () => {
    this.setState((prev) => ({
      showFilter: false,
      query: {
        ...prev.query,
        ...prev.filter,
      },
    }));

    const filteredUnits = getFilteredUnits(
      this.props.units,
      this.state.filter,
      this.props.location,
    );
    if (!filteredUnits || filteredUnits.length === 0) {
      this.props.actions.renderNoDataToastr();
    }
  };

  render() {
    const {
      actions: { getUnitQuote },
      assignedUnits,
      headers,
      history,
      intl,
      location,
      meta: { totalCount },
      specials,
      specialsForm,
      statuses,
      units,
      user,
      counts,
      transferring,
      selectedProperty,
      isLoading,
      flags,
      amenities,
    } = this.props;
    const includeManageUnitAvailabilitySummary =
      flags?.bedroomBoxesManageAvailability;
    const unitAvailabilityFilterByAmenities =
      flags?.unitAvailabilityFilterByAmenities ?? false;

    const { query, specialsEdit } = this.state;

    const numUnits = totalCount || 0;
    const assignUnitConfirmCurry = curryN(2, this.assignUnitConfirm.bind(this));
    const unassignUnitConfirmCurry = curryN(
      2,
      this.unassignUnitConfirm.bind(this),
    );

    const { applicationId, prospectId, residentId } = parse(
      location.search.replace('?', ''),
    );

    const filters = [
      {
        name: 'statuses',
        description: 'Unit Status',
        variant: 'list',
        options: statuses
          .filter((status) => status.isAvailable === true)
          .map((status) => ({
            text: status.description,
            value: status.description,
          })),
      },
      {
        name: 'leased',
        description: 'Leased / Not Leased',
        variant: 'list',
        options: [
          {
            text: 'Leased',
            value: 'Yes',
          },
          {
            text: 'Not Leased',
            value: 'false',
          },
        ],
      },
      ...(unitAvailabilityFilterByAmenities && amenities?.length > 0
        ? [
            {
              name: 'amenities',
              description: intl.formatMessage(messages.unitAmenities),
              variant: 'multiselect',
              options: (amenities ?? []).map((amenity) => ({
                text: amenity.displayNameOnQuote,
                value: amenity.id,
              })),
            },
          ]
        : []),
    ];
    const isAllCommercial = selectedProperty.hasCommercialFloorPlans === 'ALL';

    const filteredUnits = getFilteredUnits(units, query, location);
    const unitsStatusSummary = getUnitsStatusSummary(filteredUnits);

    return (
      <DocumentTitle title={intl.formatMessage(messages.title)}>
        <ManageLayout
          title={
            <div className="col-xs-3 col-md-4">
              {prospectId ? (
                <a
                  className="btn-text"
                  onClick={() => this.goBackToProspect(prospectId)}
                >
                  <i className="et-chevron-left" />
                  <FormattedMessage {...messages.goBackProspect} />
                </a>
              ) : null}
              {transferring ? (
                <a
                  className="btn-text"
                  onClick={() => this.goBackToResident(residentId)}
                >
                  <i className="et-chevron-left" />
                  <FormattedMessage {...messages.goBackResident} />
                </a>
              ) : null}
              {!transferring &&
              this.state.previousApplicant &&
              applicationId ? (
                <a
                  className="btn-text"
                  onClick={() => this.goBackToApplicant(applicationId)}
                >
                  <i className="et-chevron-left" />
                  <FormattedMessage {...messages.goBack} />
                </a>
              ) : null}
              <h1>
                <FormattedMessage {...messages.header} />
              </h1>
              <p>
                <FormattedMessage {...messages.subHeader} />{' '}
                <span className="text-blue">{numUnits}</span>
              </p>
            </div>
          }
          actions={
            <div className="col-xs-9 col-md-8">
              <SearchActions
                filterButton={{
                  hasActiveFilters: hasFilters(this.state.query),
                  onClick: this.handleFilterClick,
                }}
                searchField={{
                  form: 'ManageRentRoll',
                  placeholder: intl.formatMessage(messages.searchUnits),
                  onSubmit: this.handleSubmit,
                }}
                csvDownloadButton={{
                  onClick: this.downloadCSV,
                }}
              />
            </div>
          }
        >
          <FilterDrawer
            squareChecked
            title={intl.formatMessage(filterDrawerMessages.filterBy)}
            clearBtnText={intl.formatMessage(filterDrawerMessages.clearFilters)}
            applyBtnText={intl.formatMessage(filterDrawerMessages.apply)}
            filters={filters}
            onFilterChange={this.onFilterChange}
            onCloseClick={this.handleFilterClick}
            onApplyClick={this.onApplyFiltersClick}
            show={this.state.showFilter}
            onClearFiltersClick={this.clearFilters}
            currentFilter={this.state.filter}
          />
          {specials ? (
            <UnitSpecials
              intl={intl}
              specials={specials}
              editable={specialsEdit}
              toggleEditCallback={this.toggleSpecialsEdit}
              submitSpecialsCallback={this.submitSpecialsCallback}
              specialsForm={specialsForm}
            />
          ) : null}
          {includeManageUnitAvailabilitySummary && (
            <div className="row" style={{ marginTop: -10 }}>
              {[...unitsStatusSummary].map(([key, value]) => {
                return (
                  <SummaryBlock
                    key={key}
                    bedroomSize={value.bedroomSize}
                    leased={value.leased}
                    status={value.status}
                  />
                );
              })}
            </div>
          )}
          <div className="table-scroll table-units-container">
            <Table
              name="ManageUnitAvailability"
              headers={
                isAllCommercial
                  ? getHeaders(headers, 'resident')
                  : getHeaders(headers, 'tenant')
              }
              defaultSort={{ fieldName: 'moveInReady', order: 'DESC' }}
              onSort={this.handleSort}
              footer={ManageAvailabilityTableFooter(counts, intl)}
            >
              <TableRows
                intl={intl}
                rows={units}
                headers={
                  isAllCommercial
                    ? getHeaders(headers, 'resident')
                    : getHeaders(headers, 'tenant')
                }
                assignedUnits={assignedUnits}
                application={this.state.previousApplicant}
                assignUnitCallback={assignUnitConfirmCurry}
                unassignUnitCallback={unassignUnitConfirmCurry}
                getUnitQuote={getUnitQuote}
                history={history}
                user={user}
                location={this.props.location}
                onViewLinkClick={this.onViewLinkClick}
                query={query}
                isLoading={isLoading}
                filteredUnits={filteredUnits}
              />
            </Table>
          </div>
        </ManageLayout>
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = (state: Object): StateProps => {
  const { app, languageProvider, manageUnitAvailability, form } = state;
  const { isTransfer, residentId } = parse(location.search.replace('?', '')); // eslint-disable-line

  return {
    user: app.currentUser.user,
    units: manageUnitAvailability.units,
    statuses: manageUnitAvailability.statuses,
    transferring: isTransfer,
    specials: manageUnitAvailability.specials,
    organizationId: app.currentUser ? app.currentUser.user.organizationId : '',
    selectedProperty: app.selectedProperty,
    meta: manageUnitAvailability.meta,
    counts: manageUnitAvailability.counts,
    columnOrder: manageUnitAvailability.columnOrder,
    locale: languageProvider.locale,
    specialsForm: form.updateUnitSpecials,
    assignedUnits: manageUnitAvailability.assignedUnits,
    residentId,
    isLoading: manageUnitAvailability.isLoading,
    amenities: manageUnitAvailability.amenities,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      getAllUnits,
      getAllUnitStatuses,
      getAllPropertySpecials,
      getAssignedUnits,
      savePropertySpecial,
      cleanLoadedUnits,
      assignUnit,
      unassignUnit,
      getUnitQuote,
      transferLease,
      renderNoDataToastr,
    },
    dispatch,
  );
  return { actions };
}

export default withLDConsumer()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(injectIntl(ManageUnitAvailability)),
);
