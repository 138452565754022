import { useEffect, useState } from 'react';
import CustomerService from '../../../services/customersService';
import PortalService from '../../../services/portalService';

export const usePortalInformation = (
  customerId: string,
  propertyId: string,
  organizationId: string,
  updated: boolean,
  setUpdated: Function,
) => {
  const [portalStatus, setPortalStatus] = useState({});

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    const fetchPortalData = async () => {
      const customerService = new CustomerService();
      const portalStatus = await customerService.getPortalStatus(
        customerId,
        propertyId,
        organizationId,
        options,
      );

      setPortalStatus(portalStatus);
      setUpdated(false);
    };

    fetchPortalData();
    return () => abortController.abort();
  }, [
    customerId,
    organizationId,
    propertyId,
    setPortalStatus,
    setUpdated,
    updated,
  ]);
  return { portalStatus, setPortalStatus };
};

export const useAsyncResetPortalAccount = (
  organizationId: string,
  propertyId: string,
  portalUserId: string,
  portalStatus: Object,
  setPortalStatus: Function,
  setShowConfirmation: Function,
  setUpdated: Function,
  toastr: Function,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleResetPortalAccount = async () => {
    setIsSubmitting(true);

    try {
      const portalService = new PortalService();
      const response = await portalService.resetPortalAccount(
        organizationId,
        propertyId,
        portalUserId,
      );
      setIsSubmitting(false);
      setShowConfirmation(false);
      setUpdated(true);

      if (response.error) {
        return true;
      }

      toastr({
        message: 'Account reset',
        title: 'Success',
      });

      return true;
    } catch (e) {
      setIsSubmitting(false);
      toastr({
        message: `${e.toString()}`,
        title: 'Error',
        type: 'error',
      });
      return true;
    }
  };

  return {
    isSubmitting,
    handleResetPortalAccount,
  };
};

export const useAsyncSendPortalClaimEmail = (
  organizationId: string,
  propertyId: string,
  customerId: string,
  setUpdated: Function,
  toastr: Function,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSendPortalClaimEmail = async () => {
    setIsSubmitting(true);

    try {
      const portalService = new PortalService();
      const response = await portalService.sendPortalInviteEmail(
        organizationId,
        propertyId,
        customerId,
        'resident',
      );
      setIsSubmitting(false);
      setUpdated(true);

      if (response.error) {
        return true;
      }

      toastr({
        message: 'Email sent',
        title: 'Success',
      });

      return true;
    } catch (e) {
      setIsSubmitting(false);
      toastr({
        message: `${e.toString()}`,
        title: 'Error',
        type: 'error',
      });
      return true;
    }
  };

  return {
    isSubmitting,
    handleSendPortalClaimEmail,
  };
};

export const useAsyncTogglePortalAccountStatus = (
  organizationId: string,
  propertyId: string,
  portalUserId: string,
  portalStatus: Object,
  setPortalStatus: Function,
  setUpdated: Function,
  toastr: Function,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleTogglePortalAccountStatus = async () => {
    setIsSubmitting(true);

    try {
      const portalService = new PortalService();
      const response = await portalService.togglePortalStatus(
        organizationId,
        propertyId,
        portalUserId,
      );
      setIsSubmitting(false);
      setUpdated(true);

      toastr({
        message: 'Status updated',
        title: 'Success',
      });

      if (response.error) {
        return true;
      }

      return true;
    } catch (e) {
      setIsSubmitting(false);
      toastr({
        message: `${e.toString()}`,
        title: 'Error',
        type: 'error',
      });
      return true;
    }
  };

  return {
    isSubmitting,
    handleTogglePortalAccountStatus,
  };
};

export const useAsyncSendPortalPasswordResetEmail = (
  organizationId: string,
  propertyId: string,
  emailAddress: string,
  setUpdated: Function,
  toastr: Function,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSendPortalPasswordResetEmail = async () => {
    setIsSubmitting(true);

    try {
      const portalService = new PortalService();
      const response = await portalService.sendPortalResetPasswordEmail(
        organizationId,
        propertyId,
        emailAddress,
      );
      setIsSubmitting(false);
      setUpdated(true);

      if (response.error) {
        return true;
      }

      toastr({
        message: 'Email sent',
        title: 'Success',
      });

      return true;
    } catch (e) {
      setIsSubmitting(false);
      toastr({
        message: `${e.toString()}`,
        title: 'Error',
        type: 'error',
      });
      return true;
    }
  };

  return {
    isSubmitting,
    handleSendPortalPasswordResetEmail,
  };
};

export const useAsyncUpdatePortalUser = (
  organizationId: string,
  propertyId: string,
  portalUserId: string,
  query: Object,
  portalStatus: Object,
  setPortalStatus: Function,
  setUpdated: Function,
  toastr: Function,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdatePortalUser = async () => {
    setIsSubmitting(true);

    try {
      const portalService = new PortalService();
      const response = await portalService.updatePortalUser(
        organizationId,
        propertyId,
        portalUserId,
        query,
      );
      setIsSubmitting(false);

      if (response.error) {
        return true;
      }
      setUpdated(true);

      toastr({
        message: 'Login attempts reset',
        title: 'Success',
      });

      return true;
    } catch (e) {
      setIsSubmitting(false);
      toastr({
        message: `${e.toString()}`,
        title: 'Error',
        type: 'error',
      });
      return true;
    }
  };

  return {
    isSubmitting,
    handleUpdatePortalUser,
  };
};
