import type { Action } from '../App/types';
import type { EditProspectHouseholdState } from './types';
import * as ActionTypes from './constants';

const initialState: EditProspectHouseholdState = {
  prospect: undefined,
  primaryApplicantId: undefined,
};

const editProspectHouseholdReducer = (
  state: EditProspectHouseholdState = initialState,
  action: Action<*>,
): EditProspectHouseholdState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ONE_PROSPECT_SUCCESS: {
      return { ...state, prospect: payload };
    }
    case ActionTypes.GET_ONE_PROSPECT_ERROR: {
      return { ...state, prospect: initialState.prospect };
    }
    case ActionTypes.CLEAN:
      return initialState;
    default:
      return state;
  }
};

export default editProspectHouseholdReducer;
