import React from 'react';
import { Field, FieldArray } from 'redux-form';

import {
  renderCurrencyField,
  renderTextAreaField,
  renderTextField,
  renderSelectField,
  renderDateField,
} from '../../../../../../utils/redux-form-helper';

import {
  CURRENCY_TYPE_SELECT_OPTIONS,
  FULL_DATE_FORMAT,
} from '../../constants';

import {
  FlexFormInput,
  FlexFormInputHalf,
  TextButtonRightContainer,
  FlexContainer,
  FlexFormInputGrow,
} from '../../common';

const TenantImpovementsTaskFormFields = ({ fields }) => {
  return (
    <div style={{ width: '100%' }}>
      <TextButtonRightContainer>
        <a onClick={() => fields.push()}>+ Add Task</a>
      </TextButtonRightContainer>
      {fields.map((field, index) => {
        return (
          <FlexContainer key={index}>
            <FlexFormInputGrow size={4}>
              <Field
                name={`${field}.Task`}
                component={renderTextField}
                label="Task"
                maxLength="255"
                showLabel
              />
            </FlexFormInputGrow>
            <FlexFormInputGrow size={4}>
              <Field
                name={`${field}.Task Due Date`}
                component={renderDateField}
                classes="input control-label"
                dateFormat={FULL_DATE_FORMAT}
                label="Task Due Date"
                bsSize="md"
              />
            </FlexFormInputGrow>
            <FlexFormInputGrow size={4}>
              <Field
                name={`${field}.Tenant is responsible above`}
                component={renderCurrencyField}
                label="Tenant is responsible above"
                step="0.01"
              />
            </FlexFormInputGrow>
            <FlexFormInputGrow size={1}>
              <a onClick={() => fields.remove(index)}>X</a>
            </FlexFormInputGrow>
          </FlexContainer>
        );
      })}
    </div>
  );
};

const TennantImprovementsOptionsFormFields = ({ values }: Object) => {
  return (
    <React.Fragment>
      <FlexFormInput>
        <Field
          name="Allowance by LL Type"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Allowance by LL Type"
          options={CURRENCY_TYPE_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Allowance by LL Amount"
          component={renderCurrencyField}
          label="Allowance by LL Amount"
          step="0.01"
        />
      </FlexFormInput>
      <FlexFormInputHalf>
        <Field
          name="Terms"
          component={renderTextAreaField}
          label="Terms"
          rows="3"
          maxLength="1000"
          showLabel
        />
      </FlexFormInputHalf>
      <FlexFormInputHalf>
        <Field
          name="LL Obligations"
          component={renderTextAreaField}
          label="LL Obligations"
          rows="3"
          maxLength="1000"
          showLabel
        />
      </FlexFormInputHalf>
      <FieldArray
        name="Tenant Improvement Tasks"
        component={TenantImpovementsTaskFormFields}
      />
    </React.Fragment>
  );
};

export default TennantImprovementsOptionsFormFields;
