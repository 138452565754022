import React from 'react';
import { injectIntl } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { moment } from 'react-datetime';
import { reduxForm, Field } from 'redux-form';
import {
  required,
  number,
  positive,
  renderBlockDataCurrencyField,
  renderBlockDataDateField,
  renderBlockDataCheckboxField,
  renderBlockDataTextField,
  maxlength,
  extractDateFormat,
} from '../../utils/redux-form-helper';
import validate from './validate';

const parseTotal = (val?: string) => (val ? Number(val) : 0);

const noteValidation = maxlength(50, 'Note cannot exceed 50 characters.');

const HeaderForm = (props) => {
  const { handleSubmit, onSubmit, isPosted, intl, fiscalPeriod } = props;
  const dateFormat = extractDateFormat(intl);

  const isValidDate = (selectedDate: Object) => {
    const fiscalPeriodStartDate = fiscalPeriod?.startDate;

    const momentFiscalPeriodStartDate = moment(fiscalPeriodStartDate);
    const today = moment().startOf('day');

    return moment(selectedDate).isBetween(
      momentFiscalPeriodStartDate,
      today,
      'day',
      '[]',
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="padtop10">
        <Col md={3} lg={2}>
          <Field
            name="batchTotal"
            label="Batch Total"
            placeholder="0.00"
            parse={parseTotal}
            component={renderBlockDataCurrencyField}
            validate={[number, positive]}
            readOnly={isPosted}
          />
        </Col>
        <Col md={2}>
          <div className="block-data block-white-shadow">
            <h5>Bank Account</h5>
            {isPosted ? (
              <p>Operating</p>
            ) : (
              <input
                className="input-lg form-control"
                value="Operating"
                disabled
              />
            )}
          </div>
        </Col>
        <Col md={2}>
          <Field
            name="batchDate"
            label="Batch Date"
            component={renderBlockDataDateField}
            isValidDate={isValidDate}
            validate={[required]}
            dateFormat={dateFormat}
            readOnly={isPosted}
          />
        </Col>
        <Col md={2}>
          <Field
            name="mustBeSingleDeposit"
            label="Must be a single deposit"
            component={renderBlockDataCheckboxField}
            readOnly={isPosted}
          />
        </Col>
        <Col md={3} lg={4}>
          <Field
            name="batchNote"
            label="Note"
            component={renderBlockDataTextField}
            validate={noteValidation}
            readOnly={isPosted}
          />
        </Col>
      </Row>
    </form>
  );
};

// $FlowFixMe
export default injectIntl(
  reduxForm({
    form: 'manageBatchHeaderForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
    touchOnChange: true,
  })(HeaderForm),
);
