import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export const FORM_NAME = 'ManageTransactionCodes';
export const DEFAULT_PROPERTY_SELECTION_OPTION = [
  { key: 'default', value: 'all', text: 'All Properties' },
];
export const FORM_HEADERS = [
  {
    id: 'code',
    title: <FormattedMessage {...messages.transactionCode} />,
  },
  {
    id: 'transactionType',
    title: <FormattedMessage {...messages.transactionType} />,
  },
  {
    id: 'masterSubjournal',
    title: <FormattedMessage {...messages.subjournal} />,
  },
  {
    id: 'description',
    title: <FormattedMessage {...messages.description} />,
  },
  {
    id: 'debitAccountNumber',
    title: <FormattedMessage {...messages.debitGLAccount} />,
  },
  {
    id: 'creditAccountNumber',
    title: <FormattedMessage {...messages.creditGLAccount} />,
  },
  {
    id: 'edit',
    order: null,
    title: <FormattedMessage {...messages.edit} />,
  },
];
