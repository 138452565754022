import React from 'react';
import styled from 'styled-components';
import { isEmpty, isNil } from 'ramda';
import { orange } from '@fortress-technology-solutions/fortress-component-library/design';

import Table from '../../../components/Table/index.js';
import Row from '../../../components/Table/Row';
import Data from '../../../components/Table/Data';

type Props = {
  headerRows: Array<Object>,
  name: string,
  onSort: Function,
  rows: Array<Object>,
};

const StyledRow = styled(Row)`
  &.compliance-needs-review {
    background-color: ${orange.lighter}!important;
  }
`;

export const ComplianceOverviewDetails = ({
  headers,
  name,
  onSort,
  rows,
  intl,
}: Props): any => {
  return (
    <Table name={name} headerRows={headers} onSort={onSort}>
      {rows
        ? rows.map((row: Object, key: number): any => (
            <StyledRow
              key={key}
              className={`table-row ${
                row?.needsReview && (isNil(row.error) || isEmpty(row.error))
                  ? 'compliance-needs-review'
                  : ''
              }`.trim()}
              data-test="compliance-row"
              error={row.error}
            >
              {row.columns.map((column: any, key: number) => (
                <Data key={key}>{column}</Data>
              ))}
            </StyledRow>
          ))
        : null}
    </Table>
  );
};

export default ComplianceOverviewDetails;
