import messages from '../messages';
import type { Highlight, ProspectsToFollowUpEntity } from '../types';
import { ASSIGN_TYPE, TASK_TYPE } from '../constants';
import { getHighlightColor } from './general/getHighlightColor';
import { getUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';

type ProspectFollowUpHeader = {
  id: number,
  text: Object,
  // eslint-disable-next-line flowtype/space-after-type-colon
  sortKey:
    | 'prospect'
    | 'desiredMoveInDate'
    | 'daysSinceContact'
    | 'numOfActivities'
    | 'assignedTo',
  initialSortDirection?: string,
};

type MappedProspectsToFollowUp = {
  id: string,
  prospect: { link: string, value: string },
  desiredMoveInDate: string,
  daysSinceContact: number,
  numOfActivities: string,
  assignedTo: {
    value: string,
    text: string,
    disabled: boolean,
    dropDownMenuAssign: string,
  },
  highlight: Highlight,
};

export const mapProspectsToFollowUp = (
  prospectsFollowUp: ProspectsToFollowUpEntity[],
  taskTabAssigneeDropdownFlag: boolean,
): MappedProspectsToFollowUp[] => {
  return prospectsFollowUp.map((task) => {
    const highlight = getHighlightColor({
      status: task.userStatus,
      type: TASK_TYPE.PROSPECTS_FOLLOW_UP,
      isRed: task.isRed,
    });

    const name = task.prospectName.split(',');
    const prospectName = `${name[1].trim()} ${name[0]}`;
    const assignedTo = taskTabAssigneeDropdownFlag
      ? task.assignedToName.trim() || 'N/A'
      : task.assignedToName.trim()
      ? {
          value: task.assignedToId,
          text: task.assignedToName,
          disabled: false,
          dropDownMenuAssign: ASSIGN_TYPE.PROSPECT,
        }
      : 'N/A';
    return {
      id: task.prospectId,
      prospect: {
        link: getUrlWithSelectedPropertyId(`/prospect/${task.prospectId}`),
        value: prospectName,
      },
      desiredMoveInDate: task.desiredMoveIn,
      daysSinceContact: task.daysSinceContact,
      numOfActivities: task?.activityCounts,
      assignedTo,
      highlight,
    };
  });
};

export const prospectFollowUpHeaders = (
  intl: Object,
): ProspectFollowUpHeader[] => [
  {
    id: 1,
    text: intl.formatMessage(messages.prospectNameHeader),
    sortKey: 'prospect',
  },
  {
    id: 2,
    text: intl.formatMessage(messages.desiredMoveInDateHeader),
    sortKey: 'desiredMoveInDate',
  },
  {
    id: 3,
    text: intl.formatMessage(messages.daysSinceContactHeader),
    sortKey: 'daysSinceContact',
    initialSortDirection: 'descending',
  },
  {
    id: 4,
    text: intl.formatMessage(messages.numOfActivitiesHeader),
    sortKey: 'numOfActivities',
  },
  {
    id: 5,
    text: intl.formatMessage(messages.assignedToHeader),
    sortKey: 'assignedTo',
  },
];
