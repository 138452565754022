import { useState, useEffect } from 'react';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { pathOr, pick } from 'ramda';

import PaymentBatchService from '../../services/paymentBatchService';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

export const useFetchPaymentBatch = ({
  propertyId,
  organizationId,
  batchId,
  history,
  initializeCallback,
}: Object): any => {
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    const fetchData = async () => {
      if (organizationId && propertyId && batchId && refresh) {
        try {
          setIsLoading(true);
          const paymentBatchService = new PaymentBatchService();
          const response = await paymentBatchService.get(
            batchId,
            propertyId,
            organizationId,
            options,
          );
          // Load the header form
          initializeCallback('manageBatchHeaderForm', {
            batchTotal: response.header.batchTotal,
            batchDate: moment(response.header.batchDate).toDate(),
            mustBeSingleDeposit: response.header.mustBeSingleDeposit,
            batchNote: response.header.batchNote,
          });
          setIsLoading(false);
          setData(response);
        } catch (e) {
          toastr.error('Error', 'Error loading payment batch.');
          navigateToUrlWithSelectedPropertyId('/payment-batch');
        }
      }
      if (!batchId && data !== null) {
        initializeCallback('manageBatchHeaderForm', {});
        setData(null);
      }
    };

    fetchData();

    setRefresh(false);
    return () => abortController.abort();
  }, [
    organizationId,
    propertyId,
    batchId,
    refresh,
    data,
    history,
    initializeCallback,
  ]);

  return [data, () => setRefresh(true), isLoading];
};

// save payment batch
export const useSavePaymentBatchHeader = ({
  organizationId,
  propertyId,
  promptToaster,
  refresh,
  batch,
  history,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const savePaymentBatchHeader = async (payload: Object) => {
    setIsLoading(true);
    try {
      const paymentBatchService = new PaymentBatchService();
      // Update if we are saving a current batch
      const batchId = pathOr(null, ['header', 'id'], batch);
      if (batchId) {
        payload.id = batchId;
      }
      const paymentBatchHeader =
        await paymentBatchService.savePaymentBatchHeader(
          organizationId,
          propertyId,
          payload,
        );
      promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Payment Batch Saved',
      });
      navigateToUrlWithSelectedPropertyId(
        `/payment-batch/${paymentBatchHeader.id}`,
      );
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
      refresh();
    }
  };

  return [savePaymentBatchHeader, isLoading];
};

// save payment batch detail
export const useSavePaymentBatchDetail = ({
  organizationId,
  propertyId,
  promptToaster,
  refresh,
  batch,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const savePaymentBatchDetail = async (payload: Object) => {
    setIsLoading(true);

    const headerId = pathOr(null, ['header', 'id'], batch);
    const batchDetail = {
      headerId,
      transactionAmount: payload.amount,
      receivedFromHouseholdMember: payload.receivedFrom,
      propertyTransactionCodeId: payload.transactionCode,
      transactionDate:
        batch?.header?.batchDate ?? moment().startOf('day').toDate(),
      transactionNote: payload.note || '',
      ...pick(['documentNumber', 'householdId', 'householdStatus', 'unitId'])(
        payload,
      ),
    };
    if (payload.id) {
      batchDetail.id = payload.id;
    }

    try {
      const paymentBatchService = new PaymentBatchService();
      await paymentBatchService.savePaymentBatchDetail(
        organizationId,
        propertyId,
        batchDetail,
      );
      promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Payment transaction added to batch.',
      });
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
      refresh();
    }
  };

  return [savePaymentBatchDetail, isLoading];
};

export const useDeletePaymentBatch = ({
  organizationId,
  propertyId,
  promptToaster,
  history,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const deletePaymentBatch = async (batchHeaderId: Object) => {
    setIsLoading(true);
    try {
      const paymentBatchService = new PaymentBatchService();
      await paymentBatchService.deletePaymentBatch(
        organizationId,
        propertyId,
        batchHeaderId,
      );
      promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Payment batch successfully deleted.',
      });
      navigateToUrlWithSelectedPropertyId('/payment-batch');
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return [deletePaymentBatch, isLoading];
};

export const useDeletePaymentBatchDetail = ({
  organizationId,
  propertyId,
  promptToaster,
  refresh,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const deletePaymentBatchDetail = async (detailId: Object) => {
    setIsLoading(true);

    try {
      const paymentBatchService = new PaymentBatchService();
      await paymentBatchService.deletePaymentBatchDetail(
        organizationId,
        propertyId,
        detailId,
      );
      promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Payment transaction removed from batch.',
      });
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
      refresh();
    }
  };

  return [deletePaymentBatchDetail, isLoading];
};

// post payment batch
export const usePostPaymentBatch = ({
  organizationId,
  propertyId,
  promptToaster,
  refresh,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const postPaymentBatch = async (
    batchHeaderId: string,
    currentRoutePath: string,
  ) => {
    setIsLoading(true);

    promptToaster({
      type: 'success',
      title: 'Success',
      message: 'Payment Batch is pending and will post when complete.',
    });

    try {
      const paymentBatchService = new PaymentBatchService();
      await paymentBatchService.postPaymentBatch(
        batchHeaderId,
        propertyId,
        organizationId,
        {
          currentRoutePath,
          batchHeaderId,
        },
      );
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
      refresh();
    }
  };

  return [postPaymentBatch, isLoading];
};
