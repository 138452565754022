import { defineMessages } from 'react-intl';

const messages = defineMessages({
  netOccupancy: {
    id: 'App.Overview.NetOccupancy.NetOccupancy',
    defaultMessage: 'Net Occupancy',
  },
  approvedMoveIns: {
    id: 'App.Overview.NetOccupancy.ApprovedMoveIns',
    defaultMessage: 'Approved Move-ins: ',
  },
  moveOuts: {
    id: 'App.Overview.NetOccupancy.MoveOuts',
    defaultMessage: 'Move-outs: ',
  },
  approved: {
    id: 'App.Overview.NetOccupancy.Approved',
    defaultMessage: 'Approved (no move-in date): ',
  },
  processing: {
    id: 'App.Overview.NetOccupancy.Processing',
    defaultMessage: 'Processing Applications: ',
  },
  downUnits: {
    id: 'App.Overview.NetOccupancy.DownUnits',
    defaultMessage: 'Down Units: ',
  },
});

export default messages;
