import { defineMessages } from 'react-intl';

const messages = defineMessages({
  incomeVerification: {
    id: 'App.Qualification.IncomeVerificationFormForILI.IncomeVerification',
    defaultMessage: 'Income Verification for',
  },
  incomeHeaderNote: {
    id: 'App.Qualification.IncomeVerificationFormForILI.IncomeHeaderNote',
    defaultMessage:
      'Please indicate each source of income that any member of your household (18 years or older) receives or anticipates receiving in the next 12 months.', // eslint-disable-line
  },
  employment: {
    id: 'App.Qualification.IncomeVerificationFormForILI.Employment',
    defaultMessage: 'Employment / Anticipated Employment',
  },
  employmentForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.EmploymentForms',
    defaultMessage: 'VOE or AONE & applicable documentation',
  },
  selfEmployment: {
    id: 'App.Qualification.IncomeVerificationFormForILI.SelfEmployment',
    defaultMessage: 'Self-Employment',
  },
  selfEmploymentForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.SelfEmploymentForms',
    defaultMessage: 'AOSE & applicable documentation',
  },
  militaryPay: {
    id: 'App.Qualification.IncomeVerificationFormForILI.MilitaryPay',
    defaultMessage: 'Military Pay',
  },
  militaryPayForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.MilitaryPayForms',
    defaultMessage: 'VOMP',
  },
  notWorking: {
    id: 'App.Qualification.IncomeVerificationFormForILI.NotWorking',
    defaultMessage: 'Not Working',
  },
  notWorkingForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.NotWorkingForms',
    defaultMessage: 'AONE',
  },
  unemploymentBenefits: {
    id: 'App.Qualification.IncomeVerificationFormForILI.UnemploymentBenefits',
    defaultMessage: 'Unemployment Benefits',
  },
  unemploymentBenefitsForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.UnemploymentBenefitsForms',
    defaultMessage: 'VOUB & AONE',
  },
  socialSecurity: {
    id: 'App.Qualification.IncomeVerificationFormForILI.SocialSecurity',
    defaultMessage: 'Social Security, SSD',
  },
  socialSecurityForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.socialSecurityForms',
    defaultMessage: 'VOSSB or SS Award Letter (EIV for HUD only)',
  },
  supplementalSecurity: {
    id: 'App.Qualification.IncomeVerificationFormForILI.supplementalSecurityIncome',
    defaultMessage: 'Supplemental Security Income (SSI)',
  },
  supplementalSecurityForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.supplementalSecurityIncomeForms',
    defaultMessage: 'VOSSB or SS Award Letter (EIV for HUD only)',
  },
  vaBenefits: {
    id: 'App.Qualification.IncomeVerificationFormForILI.VaBenefits',
    defaultMessage: 'V.A. Benefits',
  },
  vaBenefitsForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.VaBenefitsForms',
    defaultMessage: 'VOVAB',
  },
  tanf: {
    id: 'App.Qualification.IncomeVerificationFormForILI.TANF',
    defaultMessage: 'TANF (AFDC)',
  },
  tanfForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.TANFForms',
    defaultMessage: 'VOTANF',
  },
  disability: {
    id: 'App.Qualification.IncomeVerificationFormForILI.Disability',
    defaultMessage: 'Disability, Worker’s Comp.',
  },
  disabilityForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.disabilityForms',
    defaultMessage: 'VODOWC & AONE',
  },
  recurringGift: {
    id: 'App.Qualification.IncomeVerificationFormForILI.RecurringGift',
    defaultMessage: 'Recurring Gift',
  },
  recurringGiftForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.RecurringGiftForms',
    defaultMessage: 'AORG',
  },
  retirement: {
    id: 'App.Qualification.IncomeVerificationFormForILI.Retirement',
    defaultMessage: 'Regular Pmts from Retirement Acct.',
  },
  retirementForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.RetirementForms',
    defaultMessage: 'VORA',
  },
  trust: {
    id: 'App.Qualification.IncomeVerificationFormForILI.Trust',
    defaultMessage: 'Regular Pmts from Trust Account',
  },
  trustForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.TrustForms',
    defaultMessage: 'VOB',
  },
  absentFamilyMember: {
    id: 'App.Qualification.IncomeVerificationFormForILI.AbsentFamilyMember',
    defaultMessage: 'Income from Temporarily Absent Family Member',
  },
  absentFamilyMemberForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.AbsentFamilyMemberForms',
    defaultMessage: 'Applicable documentation',
  },
  otherForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.OtherForms',
    defaultMessage: 'Applicable documentation',
  },
  courtOrderedChildSupport: {
    id: 'App.Qualification.IncomeVerificationFormForILI.CourtOrderedChildSupport',
    defaultMessage:
      'Do you have court ordered or an agreement for child support?',
  },
  courtOrderedChildSupportText: {
    id: 'App.Qualification.IncomeVerificationFormForILI.CourtOrderedChildSupportText',
    defaultMessage: 'Amount Ordered:',
  },
  receivingChildSupport: {
    id: 'App.Qualification.IncomeVerificationFormForILI.ReceivingChildSupport',
    defaultMessage: 'Are you receiving the ordered child support?',
  },
  receivingChildSupportText: {
    id: 'App.Qualification.IncomeVerificationFormForILI.ReceivingChildSupportText',
    defaultMessage: 'Amount Receiving:',
  },
  receivingChildSupportForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.ReceivingChildSupportForms',
    defaultMessage: 'VOCSA, AOCSAP, AONCSA or other',
  },
  collectChildSupport: {
    id: 'App.Qualification.IncomeVerificationFormForILI.CollectChildSupport',
    defaultMessage: 'Have reasonable efforts been made to collect?',
  },
  anticipateChildSupport: {
    id: 'App.Qualification.IncomeVerificationFormForILI.AnticipateChildSupport',
    defaultMessage: 'Do you anticipate child support?',
  },
  anticipateChildSupportForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.AnticipateChildSupportForms',
    defaultMessage: 'AONE',
  },
  housingAssistance: {
    id: 'App.Qualification.IncomeVerificationFormForILI.HousingAssistance',
    defaultMessage: 'Housing Assistance (Voucher Holder)',
  },
  housingAssistanceForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.HousingAssistanceForms',
    defaultMessage: 'VOS8',
  },
  housingAssistanceText: {
    id: 'App.Qualification.IncomeVerificationFormForILI.HousingAssistanceText',
    defaultMessage: 'If yes, Public Housing Authority:',
  },
  publicHousing: {
    id: 'App.Qualification.IncomeVerificationFormForILI.PublicHousing',
    defaultMessage: 'Public Housing',
  },
  publicHousingForms: {
    id: 'App.Qualification.IncomeVerificationFormForILI.PublicHousingForms',
    defaultMessage: 'VOS8',
  },
  publicHousingText: {
    id: 'App.Qualification.IncomeVerificationFormForILI.PublicHousingText',
    defaultMessage: 'If yes, Public Housing Authority:',
  },
  county: {
    id: 'App.Qualification.IncomeVerificationFormForILI.County',
    defaultMessage: 'County',
  },
  typeOfIncome: {
    id: 'App.Qualification.IncomeVerificationFormForILI.TypeOfIncome',
    defaultMessage: 'Type of Income',
  },
  receiving: {
    id: 'App.Qualification.IncomeVerificationFormForILI.Receiving',
    defaultMessage: 'Currently Receiving or Anticipates Receiving',
  },
  no: {
    id: 'App.Qualification.IncomeVerificationFormForILI.No',
    defaultMessage: 'No',
  },
  yes: {
    id: 'App.Qualification.IncomeVerificationFormForILI.Yes',
    defaultMessage: 'Yes',
  },
  householdMemberName: {
    id: 'App.Qualification.IncomeVerificationFormForILI.HouseholdMemberName',
    defaultMessage: 'If Yes, Household Member Name',
  },
  annualGrossReceiving: {
    id: 'App.Qualification.IncomeVerificationFormForILI.AnnualGrossReceiving',
    defaultMessage: 'Annual Gross $ Amount Receiving',
  },
  requiredForm: {
    id: 'App.Qualification.IncomeVerificationFormForILI.RequiredForm',
    defaultMessage: 'If Yes, Required Form',
  },
  totalMonthlyIncome: {
    id: 'App.Qualification.IncomeVerificationFormForILI.TotalMonthlyIncome',
    defaultMessage: 'Total Monthly Income',
  },
  totalAnnualIncome: {
    id: 'App.Qualification.IncomeVerificationFormForILI.TotalAnnualIncome',
    defaultMessage: 'Total Annual Income',
  },
  saveIncomeInformation: {
    id: 'App.Qualification.IncomeVerificationFormForILI.SaveIncomeInformation',
    defaultMessage: 'Save Income Information',
  },
  hapPaymentText: {
    id: 'App.Qualification.IncomeVerificationFormForILI.HapPaymentText',
    defaultMessage: 'Housing Assistance Payment (HAP) $',
  },
  residentPaymentText: {
    id: 'App.Qualification.IncomeVerificationFormForILI.ResidentPaymentText',
    defaultMessage: 'Resident Payment $',
  },
  amountRequired: {
    id: 'App.Qualification.IncomeVerificationFormForILI.AmountRequired',
    defaultMessage: 'Amount Required',
  },
  frequencyRequired: {
    id: 'App.Qualification.IncomeVerificationFormForILI.FrequencyRequired',
    defaultMessage: 'Frequency Required',
  },
  stateAndCountyRequired: {
    id: 'App.Qualification.IncomeVerificationFormForILI.StateAndCountyRequired',
    defaultMessage: 'State and County Required',
  },
  sourceRequired: {
    id: 'App.Qualification.IncomeVerificationFormForILI.SourceRequired',
    defaultMessage: 'Source Required',
  },
  publicHousingAuthorityRequired: {
    id: 'App.Qualification.IncomeVerificationFormForILI.PublicHousingAuthorityRequired',
    defaultMessage: 'Public Housing Authority Required',
  },
  mustBePositive: {
    id: 'App.Qualification.IncomeVerificationFormForILI.MustBePositive',
    defaultMessage: 'Amount Must Be Positive',
  },
  state: {
    id: 'App.Qualification.IncomeVerificationFormForILI.State',
    defaultMessage: 'State / Province',
  },
  payFrequency: {
    id: 'App.Qualification.IncomeVerificationFormForILI.PayFrequency',
    defaultMessage: 'Pay Frequency',
  },
  payDataPayFrequencyLabel: {
    id: 'App.Qualification.IncomeVerificationFormForILI.PayDataPayFrequencyLabel',
    defaultMessage: 'Pay Frequency',
  },
  payDataPayRateLabel: {
    id: 'App.Qualification.IncomeVerificationFormForILI.PayDataPayRateLabel',
    defaultMessage: 'Pay Rate',
  },
  payDataHoursWeeksMonthsLabel: {
    id: 'App.Qualification.IncomeVerificationFormForILI.PayDataHoursWeeksMonthsLabel',
    defaultMessage: 'Hours/\u200bWeeks/\u200bMonths',
  },
  unearnedIncome17AndUnder: {
    id: 'App.Qualification.IncomeVerificationFormForILI.UnearnedIncome17AndUnder',
    defaultMessage: 'Unearned Income (17 and Under)',
  },
  incomeFromOnlineSources: {
    id: 'App.Qualification.IncomeVerificationFormForILI.IncomeFromOnlineSources',
    defaultMessage: 'Income From Online Sources',
  },
  pension: {
    id: 'App.Qualification.IncomeVerificationFormForILI.Pension',
    defaultMessage: 'Pension',
  },
  other: {
    id: 'App.Qualification.IncomeVerificationFormForILI.Other',
    defaultMessage: 'Other',
  },
  hoursWeeksMonthsDescription: {
    id: 'App.Qualification.IncomeVerificationFormForILI.HoursWeeksMonths.Label.Tooltip',
    defaultMessage:
      'If you select "Annually" or "Weekly" as the Pay Frequency, this ' +
      'column will be disabled and will be set based on the selected frequency. ' +
      'If you select "Monthly", please enter the number of months between 1 and 12. ' +
      'If you select, "Bi-Weekly", "Semi-Monthly", or "Hourly", please enter the ' +
      '{units} in this column.',
  },
  hoursPerWeek: {
    id: 'App.Qualification.IncomeVerificationFormForILI.HoursWeeksMonths.Label.Tooltip.HoursPerWeek',
    defaultMessage: 'hours per week',
  },
  valueMustBeBetween: {
    id: 'App.Qualification.IncomeVerificationFormForILI.Validations.Between',
    defaultMessage: '{value} Must Be Between {lower} and {upper}',
  },
  incomeConfirmation: {
    id: 'App.Qualification.IncomeVerificationFormForILI.Validations.IncomeConfirmation',
    defaultMessage: 'We have reviewed this household member’s income form.',
  },
  incomeConfirmationWarning: {
    id: 'App.Qualification.IncomeVerificationFormForILI.Validations.IncomeConfirmationWarning',
    defaultMessage: 'You must confirm you have reviewed this household member’s income form',
  },
  mustSelectOneOption: {
    id: 'App.Qualification.IncomeVerificationFormForILI.Validations.MustSelectOneOption',
    defaultMessage: 'An Option Must Be Selected',
  },
});

export default messages;
