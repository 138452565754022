import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.ApplicationReceived.Title',
    defaultMessage: 'Application Received',
  },
  save: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.ApplicationReceived.Save',
    defaultMessage: 'Save',
  },
  dateCannotBeInTheFuture: {
    id:
      'App.ApplicationProfile.LeaseApplicationTab.ApplicationReceived.DateCannotBeInTheFuture',
    defaultMessage: 'Date cannot be in the future.',
  },
  invalidDate: {
    id:
      'App.ApplicationProfile.LeaseApplicationTab.ApplicationReceived.InvalidDate',
    defaultMessage: 'Invalid Date Format',
  },
  success: {
    id:
      'App.ApplicationProfile.LeaseApplicationTab.ApplicationReceived.Update.Success',
    defaultMessage: 'Success',
  },
  error: {
    id:
      'App.ApplicationProfile.LeaseApplicationTab.ApplicationReceived.Update.Error',
    defaultMessage: 'Error',
  },
  successBody: {
    id:
      'App.ApplicationProfile.LeaseApplicationTab.ApplicationReceived.Update.successBody',
    defaultMessage: 'Application Received updated',
  },
  errorBody: {
    id:
      'App.ApplicationProfile.LeaseApplicationTab.ApplicationReceived.Update.errorBody',
    defaultMessage: 'Error updating Application Received',
  },
});
