import * as R from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import useFormatMessage from '../../../../hooks/useFormatMessage';
import { useSafeSet } from '../../../../hooks/useSafeSet';
import ProrateCalculatorService from '../../../../services/prorateCalculatorService';
import messages from './messages';

export function useSendProrateCalculatorEmail(
  organizationId: string,
  propertyId: string,
) {
  const [safeSet] = useSafeSet();

  const formatMessage = useFormatMessage();

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendProrateCalculatorEmail = useCallback(
    async (payload) => {
      const prorateCalculatorService = new ProrateCalculatorService();

      try {
        safeSet(setIsLoading)(true);
        safeSet(setSuccess)(false);
        await prorateCalculatorService.sendProrateCalculatorEmail(
          organizationId,
          propertyId,
          {
            ...payload,
            formValues: R.reject(R.isNil, payload.formValues),
          },
        );

        toastr.success(
          formatMessage(messages.success),
          formatMessage(messages.prorateCalculationEmailSent),
        );
        safeSet(setSuccess)(true);
      } catch (error) {
        toastr.error(formatMessage(messages.error), error.toString());
      } finally {
        safeSet(setIsLoading)(false);
      }
    },
    [formatMessage, organizationId, propertyId, safeSet],
  );

  return [sendProrateCalculatorEmail, isLoading, success];
}

export function useProrateCalculatorEmailRecipients(
  organizationId: string,
  propertyId: string,
) {
  const [safeSet] = useSafeSet();

  const formatMessage = useFormatMessage();

  const [recipients, setRecipients] = useState({
    households: [],
    prospects: [],
  });

  useEffect(() => {
    async function fetchData() {
      const prorateCalculatorService = new ProrateCalculatorService();

      try {
        const response =
          await prorateCalculatorService.getProrateCalculatorRecipients(
            organizationId,
            propertyId,
          );

        if (response) {
          safeSet(setRecipients)(response);
        }
      } catch (error) {
        toastr.error(formatMessage(messages.error), error.toString());
      }
    }

    fetchData();
  }, [formatMessage, organizationId, propertyId, safeSet]);

  return [recipients];
}
