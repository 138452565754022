import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';

import type { GlobalState } from '../App/types';
import { getEnvironmentName } from '../App/selectors';
import * as loginActions from './actions';
import LoginForm from './LoginForm';
import messages from './messages';

type State = {
  username: string,
  rememberMe: boolean,
  errorMessage: string,
  showErrorMessage: boolean
};

type StateProps = State & {
  environmentName: string
};

type InjectedProps = {
  actions: Object,
  intl: any,
  history: Object
};

export class LoginPage extends Component<StateProps & InjectedProps, State> {
  constructor(props: StateProps & InjectedProps, context: any) {
    super(props, context);
    this.state = {
      username: '',
      rememberMe: false,
      errorMessage: '',
      showErrorMessage: false
    };
  }

  handleSubmit = (login: any) => {
    const username = login.username;
    const password = login.password;
    const rememberMe = login.rememberMe;
    this.setState({ username });
    this.props.actions.login({ username, password, rememberMe });
  };

  toggleRememberMe = () => {
    this.setState((prevState) => ({
      rememberMe: !prevState.rememberMe
    }));
  };

  forgetLoggedUser = () => {
    this.setState({
      rememberMe: false,
      username: ''
    });
    this.props.actions.forgetMe();
  };

  render() {
    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <LoginForm
          initialValues={{
            username: this.props.username,
            rememberMe: this.props.rememberMe
          }}
          intl={this.props.intl}
          onSubmit={this.handleSubmit}
          rememberMe={this.props.rememberMe}
          username={this.props.username}
          showErrorMessage={this.props.showErrorMessage}
          errorMessage={this.props.errorMessage}
          history={this.props.history}
          toggleRememberMe={this.toggleRememberMe}
          handleForget={this.forgetLoggedUser}
          environmentName={this.props.environmentName}
        />
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = (globalState: GlobalState): StateProps => {
  const { login } = globalState;
  return {
    username: login.username,
    rememberMe: login.rememberMe,
    showErrorMessage: login.showErrorMessage,
    errorMessage: login.errorMessage,
    environmentName: getEnvironmentName(globalState)
  };
};

export function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(loginActions, dispatch)
  };
}

const InjectedLoginPage = injectIntl(LoginPage);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InjectedLoginPage);
