import { useContext, useState } from 'react';
import { AppContext } from '../../context';
import { useQuery } from 'react-query';
import { TwoWayCommunicationService } from '../../../../services/twoWayCommunicationService';
import useCustomEvent from '../../../../hooks/useCustomEvent';
import { CONVERSATION_STATUS_CHANGED_EVENT } from '../../../TextingTab/customEvents';
import { useFlags } from 'launchdarkly-react-client-sdk';

const DEFAULT_HAS_NOTIFICATIONS = {
  hasNewMessages: false,
  hasUnresolvedConversations: false,
};

const HAS_NOTIF_INTERVAL = 300000; // 5min

export function useMainLayoutRoutes() {
  const [menuDisplay, setMenuDisplay] = useState('collapsed');
  const {
    permissions,
    selectedProperty,
    onSelectProperty,
    onSelectPortfolioSummary,
    logOut,
  } = useContext(AppContext);
  const twoWayNotifications = useTwoWayNotifications({ selectedProperty });
  const selectedPropertyOptionName = selectedProperty
    ? selectedProperty.name
    : 'Portfolio Summary';

  function onToggle() {
    setMenuDisplay(menuDisplay === 'collapsed' ? 'extended' : 'collapsed');
  }

  return {
    permissions,
    selectedProperty,
    selectedPropertyOptionName,
    onSelectProperty,
    onSelectPortfolioSummary,
    logOut,
    menuDisplay,
    onToggle,
    twoWayNotifications,
  };
}

export function useTwoWayNotifications({ selectedProperty }) {
  const service = new TwoWayCommunicationService();
  const { twoWayNotifications } = useFlags();
  const shouldQueryNotifications =
    selectedProperty?.isTwoWayCommunicationActive === true &&
    twoWayNotifications === true;

  const { data, refetch } = useQuery(
    ['hasNotifications', selectedProperty],
    () => {
      return service.getHasNotifications({
        propertyId: selectedProperty?.id,
      });
    },
    {
      refetchInterval: HAS_NOTIF_INTERVAL,
      refetchOnWindowFocus: false,
      enabled: shouldQueryNotifications,
    },
  );

  useCustomEvent({
    eventKey: CONVERSATION_STATUS_CHANGED_EVENT,
    listener: () => {
      if (shouldQueryNotifications !== true) return;

      refetch();
    },
  });

  return {
    prospects: data?.prospects ?? DEFAULT_HAS_NOTIFICATIONS,
    applications: data?.applicants ?? DEFAULT_HAS_NOTIFICATIONS,
    rentRoll: data?.residents ?? DEFAULT_HAS_NOTIFICATIONS,
    priorResidents: data?.priors ?? DEFAULT_HAS_NOTIFICATIONS,
  };
}
