import messages from '../messages';

const isValidNumberValue = (value) => !value && value !== 0;
const validate = (
  values: any,
  { intl, allFloorplans, initialValues }: Object,
) => {
  const errors = {};

  if (values.maxOccupants < values.minOccupants) {
    errors.maxOccupants = intl.formatMessage(messages.maxOccupantsError);
  }

  if (!values.internalName) {
    errors.internalName = intl.formatMessage(messages.required);
  }

  if (!values.marketingName) {
    errors.marketingName = intl.formatMessage(messages.required);
  }

  if (!values.marketingDescription) {
    errors.marketingDescription = intl.formatMessage(messages.required);
  }
  if (isValidNumberValue(values.nBeds)) {
    errors.nBeds = intl.formatMessage(messages.required);
  }

  if (isValidNumberValue(values.nBaths)) {
    errors.nBaths = intl.formatMessage(messages.required);
  }

  if (isValidNumberValue(values.nHalfBaths)) {
    errors.nHalfBaths = intl.formatMessage(messages.required);
  }

  if (isValidNumberValue(values.minOccupants)) {
    errors.minOccupants = intl.formatMessage(messages.required);
  }

  if (isValidNumberValue(values.maxOccupants)) {
    errors.maxOccupants = intl.formatMessage(messages.required);
  }

  const duplicate = allFloorplans.find(
    (plan) =>
      plan.internalName === values.internalName && values.id !== plan.id,
  );

  if (duplicate) {
    errors.internalName = intl.formatMessage(
      messages.duplicateValueInAnotherFloorPlan,
    );
  }

  return errors;
};

export default validate;
