import type { Action } from '../App/types';
import type { LoginState } from './types';
import * as ActionTypes from './constants';

const initialState: LoginState = {
  username: '',
  rememberMe: false,
  showErrorMessage: false,
  errorMessage: '',
};

const loginReducer = (
  state: LoginState = initialState,
  action: Action<*, *>,
): LoginState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.LOGIN_FAIL: {
      return {
        ...state,
        showErrorMessage: true,
        errorMessage: payload.toString(),
      };
    }
    case ActionTypes.LOGIN: {
      return {
        ...state,
        showErrorMessage: false,
        errorMessage: '',
        username: payload.rememberMe ? payload.username : '',
        rememberMe: payload.rememberMe,
      };
    }
    case ActionTypes.FORGET_ME: {
      return {
        ...state,
        rememberMe: false,
        username: '',
      };
    }
    case ActionTypes.CLEAR_MESSAGES: {
      return {
        ...state,
        showErrorMessage: false,
        errorMessage: '',
      };
    }
    default:
      return state;
  }
};

export default loginReducer;
