// $FlowFixMe
import React, { Component, Fragment, useEffect } from 'react';
import { Row, Col, FormGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, registerField } from 'redux-form';
import { propEq, pathOr } from 'ramda';
import moment from 'moment';
import { NoPrint } from 'react-easy-print';
import { BACKGROUND_COLOR } from '../../constants';

import {
  renderTextField,
  renderTextAreaField,
  renderSelectField,
  renderRadioOption,
  renderSearchDropDown,
  renderSimpleCheckboxField,
} from '../../../../utils/redux-form-helper';
import messages from './messages';
import MultiPhotoUpload from '../../MultiPhotoUpload';
import { withRouter } from 'react-router-dom';
import useHasPermission from '../../../../hooks/useHasPermission';

type Props = {
  intl: any,
  unitNumbers: Array<Object>,
  unitLocations?: Array<Object>,
  commonAreaLocations?: Array<Object>,
  workOrderIssues: Array<Object>,
  unitLocation: string,
  noTimeSpecified: boolean,
  unitLocationId: string,
  commonAreaLocationId: string,
  issueSearch: string,
  unitSearch: string,
  categoryId: string,
  actions: Object,
  invalid: Array<string>,
  formValues: Object,
  changeCallback: Function,
  reset: Function,
  searchIssues: Function,
  searchUnits: Function,
  setStaffRequested: Function,
  changeSelectedUnit: Function,
  setSelectedUnit: Function,
  changeHouseholdCallback: Function,
  selectedPropertyId: string,
  flags: Object,
  organizationId: string,
  isEditMode: boolean,
};

const LocationSelectionRow = ({ formatMessage, location, toggleLocation }) => (
  <Row className="padbottom20">
    <div className="col-xs-12 padtop20">
      <Col xs={12} md={3}>
        <h3>{formatMessage(messages.issueLocationHeader)}</h3>
        <p>{formatMessage(messages.issueLocationSelection)}</p>
      </Col>
      <Col
        className={
          'radio-with-icons' + (location === 'unit' ? ' selected' : '')
        }
        xs={6}
        md={2}
        data-test="unit-radio"
      >
        <i className="et-tub" />
        <Field
          name="location"
          fieldName="location"
          className="col-xs-6 col-md-2"
          option={{ value: 'unit', text: 'Unit' }}
          component={renderRadioOption}
          onChange={toggleLocation}
          inline
        />
      </Col>
      <Col
        className={
          'radio-with-icons' + (location === 'unit' ? '' : ' selected')
        }
        xs={6}
        md={2}
        data-test="common-area-radio"
      >
        <i className="et-landscaping" />
        <Field
          name="location"
          fieldName="location"
          className="col-xs-6 col-md-2"
          option={{ value: 'commonArea', text: 'Common Area' }}
          component={renderRadioOption}
          onChange={toggleLocation}
          inline
        />
      </Col>
    </div>
  </Row>
);

const IssueNotListedRow = ({
  toggleNotListed,
  formatMessage,
  issueNotFound,
}) => (
  <Row className="padbottom20">
    <div className="col-xs-12">
      <div className="col-xs-3 col-sm-3 text-left">
        <label className="padtop10">
          {formatMessage(messages.issueNotListedAbove)}
        </label>
        <Field
          name="issueNotFound"
          component={renderSimpleCheckboxField}
          bsSize="lg"
          onClick={toggleNotListed}
        />
      </div>
      <div className="col-xs-9">
        <div style={{ margin: '0 8px 0 4px' }}>
          <Field
            name="otherDescription"
            className="input-lg form-control"
            component={renderTextField}
            placeholder={formatMessage(messages.otherIssue)}
            disabled={!issueNotFound}
          />
        </div>
      </div>
    </div>
  </Row>
);

const UnitDropDowns = ({
  unitNumbers,
  customerId,
  householdId,
  unitId,
  changeHouseholdCallback,
  formatMessage,
  unitLocations,
  invalid,
  isEditMode,
}) => {
  const hasEditPermission = useHasPermission('workorder-edit');
  const isFieldDisabled = !hasEditPermission && isEditMode;

  const options = unitNumbers.map((unit) => ({
    label: unit.description,
    value: {
      customerId: pathOr(null, ['resident', 'customerId'], unit),
      householdId: pathOr(null, ['householdId'], unit),
      unitId: pathOr(null, ['unitId'], unit),
    },
  }));

  let selected =
    customerId && householdId && unitId && options
      ? options.find(
          (issue: Object) =>
            issue.value.customerId === customerId &&
            issue.value.householdId === householdId &&
            issue.value.unitId === unitId,
        )
      : null;
  // No matching resident.. give us the unit
  if (!selected) {
    selected = options.find((issue: Object) => issue.value.unitId === unitId);
    if (selected) {
      // put old customer back in it.. propbably a prior
      selected.value.customerId = customerId;
      selected.value.householdId = householdId;
    }
  }
  return (
    <div data-test="location-unit">
      <Col xs={12} md={5} mdOffset={3}>
        <Field
          classNames="search-actions searchbox-default searchbox__hasautocomplete"
          component={renderSearchDropDown}
          name="unitSearch"
          placeholder="Search Units"
          onChangeCallback={changeHouseholdCallback}
          options={options}
          label={formatMessage(messages.household)}
          bsSize="lg"
          fieldValue={selected}
          isDisabled={isFieldDisabled}
        />
      </Col>
      <Col xs={12} md={4}>
        <FormGroup>
          <Field
            selectClassNames="input-lg"
            name="unitLocationId"
            component={renderSelectField}
            label={formatMessage(messages.unitLocation)}
            options={unitLocations}
            maxLength="35"
            style={
              invalid && invalid.includes('unitLocation')
                ? BACKGROUND_COLOR
                : null
            }
          />
        </FormGroup>
      </Col>
    </div>
  );
};

const CommonAreaDropDown = ({
  commonAreaLocations = [],
  commonAreaLocationId,
  changeCallback,
  formatMessage,
  invalid,
}) => {
  const otherLocation = commonAreaLocations.find(
    (cal) => cal.value === commonAreaLocationId && cal.text === 'Other',
  );
  useEffect(() => {
    if (!otherLocation) {
      changeCallback('otherCommonAreaLocation', null);
    }
  }, [
    commonAreaLocationId,
    otherLocation,
    commonAreaLocations,
    changeCallback,
  ]);
  return (
    <Fragment>
      <Col xs={4} mdOffset={3} data-test="location-common-area">
        <FormGroup>
          <Field
            selectClassNames="input-lg"
            name="commonAreaLocationId"
            component={renderSelectField}
            label={formatMessage(messages.commonAreaLocation)}
            options={commonAreaLocations}
            maxLength="35"
            style={
              invalid && invalid.includes('commonAreaLocation')
                ? BACKGROUND_COLOR
                : null
            }
          />
        </FormGroup>
      </Col>
      <Col xs={5}>
        <FormGroup>
          <label> </label>
          <Field
            name="otherCommonAreaLocation"
            className="input-lg form-control"
            component={renderTextField}
            placeholder={formatMessage(messages.otherLocation)}
            disabled={!otherLocation}
          />
        </FormGroup>
      </Col>
    </Fragment>
  );
};

const IssueSearchBox = ({
  issues,
  val,
  formatMessage,
  changeIssueCallback,
  isEditMode,
}) => {
  const hasEditPermission = useHasPermission('workorder-edit');
  const isFieldDisabled = !hasEditPermission && isEditMode;
  const options = issues
    .map((issue: Object) => ({
      value: {
        id: issue.issueId,
        keywords: issue.issueKeywords,
      },
      label: issue.description,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const selected =
    val && options
      ? options.find((issue: Object) => issue.value.id === val)
      : null;
  return (
    <Row>
      <div className="col-xs-12 padTop20">
        <Col xs={12} md={3}>
          <h3 className="padtop10">{formatMessage(messages.issueType)}</h3>
        </Col>
        <Col xs={12} md={9}>
          <Field
            classNames="search-actions searchbox-default searchbox__hasautocomplete"
            component={renderSearchDropDown}
            name="issueSearch"
            placeholder={formatMessage(messages.issueSearch)}
            onChangeCallback={changeIssueCallback}
            options={options}
            bsSize="lg"
            fieldValue={selected}
            isDisabled={isFieldDisabled}
          />
        </Col>
      </div>
    </Row>
  );
};

const IssueDescriptionAndDropDowns = ({
  location,
  formatMessage,
  unitNumbers,
  unitLocations,
  changeCallback,
  invalid,
  changeHouseholdCallback,
  customerId,
  householdId,
  unitId,
  commonAreaLocations,
  commonAreaLocationId,
  isEditMode,
}) => (
  <Row>
    <div className="col-xs-12 padTop20">
      <FormGroup>
        <div data-test="location-fields">
          {location === 'unit' ? (
            <UnitDropDowns
              formatMessage={formatMessage}
              unitNumbers={unitNumbers}
              unitLocations={unitLocations}
              changeCallback={changeCallback}
              invalid={invalid}
              changeHouseholdCallback={changeHouseholdCallback}
              customerId={customerId}
              householdId={householdId}
              unitId={unitId}
              isEditMode={isEditMode}
            />
          ) : (
            <CommonAreaDropDown
              formatMessage={formatMessage}
              commonAreaLocations={commonAreaLocations}
              invalid={invalid}
              commonAreaLocationId={commonAreaLocationId}
              changeCallback={changeCallback}
            />
          )}
        </div>
      </FormGroup>
    </div>
  </Row>
);

const IssueDetailsRow = ({ formatMessage }) => (
  <Row>
    <div className="col-xs-12 padTop20">
      <Col xs={12}>
        <NoPrint force>
          <h3>{formatMessage(messages.issueDetails)}</h3>
          <label>{formatMessage(messages.issueComment)}</label>
        </NoPrint>
      </Col>
      <NoPrint force>
        <Col xs={12}>
          <Field
            name="requestIssueComment"
            component={renderTextAreaField}
            rows="3"
            maxLength="500"
            placeholder={formatMessage(messages.startTyping)}
          />
        </Col>
      </NoPrint>
    </div>
  </Row>
);

export class IssueInfo extends Component<Props> {
  toggleLocation = () => {
    const { changeCallback, searchUnits, unitLocation } = this.props;
    changeCallback('unitSearch', null);
    changeCallback('unitLocationId', null);
    changeCallback('unitNumber', null);
    changeCallback('commonAreaLocationId', null);
    changeCallback('issueId', null);
    changeCallback('issueDescription', null);
    changeCallback('categoryId', null);
    changeCallback('noTimeSpecified', false);
    changeCallback('petAlert', false);
    changeCallback('requestedStartDateFrom', null);
    changeCallback('requestedStartDateTo', null);
    changeCallback('requestedStartTimeFrom', null);
    changeCallback('requestedStartTimeTo', null);
    changeCallback('entryPermissionPreferenceId', null);
    changeCallback('otherDescription', null);
    changeCallback('staffRequested', false);
    changeCallback('issueNotFound', false);
    changeCallback('householdId', null);
    changeCallback('customerId', null);
    changeCallback('requestorName', null);
    changeCallback('requestorPhone', null);
    changeCallback('requestorEmail', null);
    changeCallback('unitId', null);
    searchUnits('');
    if (unitLocation === 'commonArea') {
      changeCallback('requestDate', moment());
    }
  };

  toggleNotListed = (e: Object) => {
    const { changeCallback, workOrderIssues, formValues } = this.props;
    changeCallback('otherDescription', null);
    changeCallback('commonAreaLocationId', null);
    if (e.target.checked) {
      const otherIssue = workOrderIssues.find(
        (issue) =>
          issue.issueDescription === 'Other' &&
          ((formValues.location === 'unit' &&
            issue.issueOverallLocation === 'Unit') ||
            (formValues.location === 'commonArea' &&
              issue.issueOverallLocation === 'Common Area')),
      );
      if (otherIssue) {
        changeCallback('issueId', otherIssue.issueId);
        changeCallback('issueDescription', otherIssue.issueDescription);
        changeCallback('issueNotFound', true);
      }
    } else {
      changeCallback('issueId', null);
      changeCallback('issueNotFound', false);
    }
  };

  changeIssueCallback = (issueObj: Object) => {
    const { changeCallback, workOrderIssues } = this.props;
    if (!issueObj) {
      return;
    }
    const issue = workOrderIssues.find(
      (issue) => issue.issueId === issueObj.value.id,
    );
    if (issue) {
      changeCallback('commonAreaLocationId', issue.commonAreaLocationId);
      changeCallback('unitLocationId', issue.unitLocationId);
      changeCallback('issueId', issue.issueId);
      changeCallback('issueDescription', issue.issueDescription);
      changeCallback('categoryId', issue.categoryId);
      changeCallback('otherDescription', null);
      if (issue.issueDescription === 'Other') {
        changeCallback('issueNotFound', true);
      } else {
        changeCallback('issueNotFound', false);
      }
    }
  };

  filterIssuesByArea = () => {
    const {
      unitLocation,
      workOrderIssues,
      intl: { formatMessage },
    } = this.props;
    if (unitLocation) {
      const area = unitLocation === 'unit' ? 'Unit' : 'Common Area';
      const issues: Array<Object> = workOrderIssues
        ? workOrderIssues.filter((issue) =>
            propEq('issueOverallLocation', area)(issue),
          )
        : [];
      return issues;
    }
    return [
      {
        issueIdReadable: 0,
        issueDescription: formatMessage(messages.nothingFound),
        isEmpty: true,
      },
    ];
  };

  render() {
    const workOrderId = this.props.match.params.workOrderId;
    const { fortressWorkOrdersPhotos } = this.props.flags;
    const {
      intl,
      intl: { formatMessage },
      unitNumbers,
      unitLocations,
      commonAreaLocations,
      unitLocation,
      changeCallback,
      invalid,
      setSelectedUnit,
      commonAreaLocationId,
      changeHouseholdCallback,
      formValues,
      selectedPropertyId,
      organizationId,
      isEditMode,
    } = this.props;
    const {
      issueId,
      issueNotFound,
      customerId,
      householdId,
      unitId,
      issueImages,
      detailId,
    } = formValues;
    const issues = this.filterIssuesByArea();

    return (
      <Row className="form-content" data-test="issue-info">
        <NoPrint force>
          <LocationSelectionRow
            location={unitLocation}
            toggleLocation={this.toggleLocation}
            changeCallback={changeCallback}
            formatMessage={formatMessage}
          />
        </NoPrint>
        <IssueSearchBox
          formatMessage={formatMessage}
          issues={issues}
          changeIssueCallback={this.changeIssueCallback}
          val={issueId}
          isEditMode={isEditMode}
        />
        <IssueNotListedRow
          issueNotFound={issueNotFound}
          toggleNotListed={this.toggleNotListed}
          formatMessage={formatMessage}
        />
        <IssueDescriptionAndDropDowns
          location={unitLocation}
          formatMessage={formatMessage}
          unitNumbers={unitNumbers}
          unitLocations={unitLocations}
          commonAreaLocations={commonAreaLocations}
          changeCallback={changeCallback}
          setSelectedUnit={setSelectedUnit}
          invalid={invalid}
          commonAreaLocationId={commonAreaLocationId}
          changeHouseholdCallback={changeHouseholdCallback}
          customerId={customerId}
          householdId={householdId}
          unitId={unitId}
          isEditMode={isEditMode}
        />
        <IssueDetailsRow formatMessage={formatMessage} />

        {fortressWorkOrdersPhotos && (
          <div className="col-xs-12 padtop10">
            <div className="form-group">
              <label>{intl.formatMessage(messages.photosOfIssue)}</label>
              <MultiPhotoUpload
                alwaysEnabled={!workOrderId}
                fieldName="issueImages"
                images={issueImages}
                intl={intl}
                workOrderId={detailId}
                selectedPropertyId={selectedPropertyId}
                organizationId={organizationId}
                changeCallback={changeCallback}
              />
            </div>
          </div>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state: Object): Object => {
  return {
    formValues: pathOr({}, ['form', 'workOrderForm', 'values'], state),
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      registerField,
    },
    dispatch,
  );
  return { actions };
}

const wrapped = withRouter(IssueInfo);

export default connect(mapStateToProps, mapDispatchToProps)(wrapped);
