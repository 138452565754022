import React from 'react';

const PercentageChange = ({
  changePositive,
  percentChange,
  formatNumber,
}: Object) => (
  <span className={`text-${changePositive ? 'green' : 'red'}`}>
    <i className={`icon et-caret-${changePositive ? 'up' : 'down'}`} />
    {formatNumber(percentChange < 0 ? percentChange * -1 : percentChange, {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })}
  </span>
);

export default PercentageChange;
