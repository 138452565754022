import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { any, equals } from 'ramda';

import type { Props } from './types';
import messages from './messages';

export const CompleteRenewalFooter = ({
  handleCancel,
  handleSubmit,
  submitting,
  pristine,
  invalid,
}: Props) => {
  return (
    <>
      <Col xs={6}>
        <Button bsStyle="default" className="pull-right" onClick={handleCancel}>
          Cancel
        </Button>
      </Col>
      <Col xs={6}>
        <Button
          disabled={any(equals(true), [submitting, pristine, invalid])}
          className="btn btn-primary pull-left"
          onClick={handleSubmit}
        >
          <FormattedMessage {...messages.submitButton} />
        </Button>
      </Col>
    </>
  );
};
