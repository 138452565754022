import { defineMessages } from 'react-intl';

const messages = defineMessages({
  household: {
    id: 'App.PeopleProfile.Household.Household',
    defaultMessage: 'Household'
  },
  name: {
    id: 'App.PeopleProfile.Household.Name',
    defaultMessage: 'Name'
  },
  relation: {
    id: 'App.PeopleProfile.Household.Relation',
    defaultMessage: 'Relation'
  },
  edit: {
    id: 'App.PeopleProfile.Household.Edit',
    defaultMessage: 'Edit'
  },
});

export default messages;
