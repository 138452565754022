import { defineMessages } from 'react-intl';

const messages = defineMessages({
  gender: {
    id: 'App.BasicAffordableInformationSection.Gender',
    defaultMessage: 'Gender',
  },
  veteran: {
    id: 'App.BasicAffordableInformationSection.veteran',
    defaultMessage: 'Veteran',
  },
});

export default messages;
