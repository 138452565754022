import React from 'react';
import { Row, Col, Pagination } from 'react-bootstrap';
import PaginationItemsCounter from '../../components/PaginationItemsCounter';
import {
  createUltimatePagination,
  ITEM_TYPES,
} from 'react-ultimate-pagination';
import { type Pagination as Props } from './types.js';

const PaginationControl = createUltimatePagination({
  WrapperComponent: Pagination,
  itemTypeToComponent: {
    [ITEM_TYPES.PAGE]: ({ value, isActive, onClick }) => (
      <Pagination.Item onClick={onClick} active={isActive}>
        {value}
      </Pagination.Item>
    ),
    [ITEM_TYPES.ELLIPSIS]: ({ isActive, onClick }) => (
      <Pagination.Ellipsis disabled={isActive} onClick={onClick} />
    ),
    [ITEM_TYPES.FIRST_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.First disabled={isActive} onClick={onClick} />
    ),
    [ITEM_TYPES.PREVIOUS_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.Prev disabled={isActive} onClick={onClick} />
    ),
    [ITEM_TYPES.NEXT_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.Next disabled={isActive} onClick={onClick} />
    ),
    [ITEM_TYPES.LAST_PAGE_LINK]: ({ isActive, onClick }) => (
      <Pagination.Last disabled={isActive} onClick={onClick} />
    ),
  },
});

export const PaginationFooter = ({
  currentPage,
  limit,
  count,
  totalCount,
  pageCount,
  onPageChange,
}: Props) => {
  return (
    <Row className="paginationFooter">
      <Col xs={12}>
        <PaginationItemsCounter
          start={(currentPage - 1) * limit + 1}
          end={(currentPage - 1) * limit + count}
          total={totalCount}
        />
        <PaginationControl
          className="hidden-xs"
          currentPage={currentPage}
          totalPages={pageCount}
          onChange={onPageChange}
          boundaryPagesRange={1}
          siblingPagesRange={0}
        />
        <PaginationControl
          className="visible-xs"
          currentPage={currentPage}
          totalPages={pageCount}
          onChange={onPageChange}
          boundaryPagesRange={0}
          siblingPagesRange={0}
        />
      </Col>
    </Row>
  );
};

export default PaginationFooter;
