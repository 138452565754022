import React, { useEffect, useMemo, useState } from 'react';
import { isDirty, submit, change, untouch } from 'redux-form';
import { connect } from 'react-redux';
import { Grid, Row } from 'react-bootstrap';
import moment from 'moment';
import * as R from 'ramda';
import * as hooks from './hooks';
import * as constants from './constants';
import Spinner from '../../../../components/Spinner';
import { MarginY } from '../../../../components/Common/Spacing';
import useWindowSize from '../../../../utils/useWindowSize';
import SectionHeader from './SectionHeader';
import LeaseBasicsForm from './LeaseBasicsForm';
import { getFormValues } from 'redux-form';
import NewLeaseButtons from './NewLeaseButtons';
import type {
  Option,
  LeaseBasicsFormValues,
  OnLeaseBasicsSubmitEvent,
} from './types';
import { MANUAL_SIGNING_METHOD } from '../../../../utils/lease-helpers';
import LeaseBasicsEditModal from './LeaseBasicsEditModal';

type OnLeaseChangeEvent = (leaseId: string) => void;

type LeaseBasicsProps = {
  intl: Object,
  leaseOptions: Option[],
  lateMethodOptions: Option[],
  selectedLease: Object,
  selectedLeaseId: string,
  onLeaseChange: OnLeaseChangeEvent,
  unit: Object,
  customer: Object,
  organizationId: string,
  onSubmit: OnLeaseBasicsSubmitEvent,
  selectedLeaseTypeId: string,
  isEditable: boolean,
  isResident: boolean,
  leaseExists: boolean,
  showLeaseDropdown: boolean,
  canSubmitEdit: boolean,
  submit: Function,
  change: Function,
  untouch: Function,
  isEditFormDirty: boolean,
  concessionsFormValues: Object,
  residentId: string,
  applicationId: string,
  isDisabledRenewal: boolean,
  toasterFunction: Function,
  setSelectedLease: Function,
  refreshLeases: Function,
  navigationHistory: any,
  doNotRenew: boolean,
  canComplete: boolean,
  priorLease: Object,
  propertyId: string,
  underEviction: boolean,
  startRenewalMessage?: string,
};

const getDateValue = (v: any) => {
  if (moment.isMoment(v)) return v;
  if (typeof v === 'string' || moment.isDate(v)) return moment(v);
  return '';
};

function LeaseBasics({
  intl,
  leaseOptions,
  selectedLease,
  selectedLeaseId,
  onLeaseChange,
  unit,
  customer,
  lateMethodOptions,
  organizationId,
  onSubmit,
  selectedLeaseTypeId,
  isEditable,
  isResident,
  leaseExists,
  showLeaseDropdown,
  canSubmitEdit,
  submit,
  change,
  untouch,
  isEditFormDirty,
  concessionsFormValues,
  residentId,
  applicationId,
  isDisabledRenewal,
  toasterFunction,
  setSelectedLease,
  refreshLeases,
  navigationHistory,
  doNotRenew,
  canComplete,
  priorLease,
  propertyId,
  underEviction,
  startRenewalMessage,
  noticeToVacate,
}: LeaseBasicsProps) {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const windowSize = useWindowSize();
  const [leaseTypeOptions, leaseTypeOptionsLoaded] =
    hooks.useLeaseTypeOptions(organizationId);
  const [retailTypeOptions, retailTypeOptionsLoaded] =
    hooks.useRetailTypeOptions(organizationId);
  const [generateLeaseAbstract, isLeaseAbstractLoading] =
    hooks.useAsyncGenerateLeaseAbstract(toasterFunction);
  const isSpinnerShown = R.any(R.equals(false), [
    leaseTypeOptionsLoaded,
    retailTypeOptionsLoaded,
  ]);

  const { handleCancelLeaseRenewal } = hooks.useAsyncCancelLeaseRenewal({
    leaseId: selectedLease.id,
    refreshLeases,
    toasterFn: toasterFunction,
  });
  const isRetailTypeFieldShown = useMemo(() => {
    if (!Array.isArray(leaseTypeOptions)) return false;

    const selectedLeaseType = leaseTypeOptions.find(
      R.propEq('value', selectedLeaseTypeId),
    );

    if (!selectedLeaseType) return false;

    return selectedLeaseType.text === 'Retail';
  }, [selectedLeaseTypeId, leaseTypeOptions]);

  const formInitialValues = useMemo(
    () =>
      selectedLease
        ? {
            leaseTypeId: selectedLease.commercialLeaseTypeId || 'default',
            lateMethodId: selectedLease.lateMethodId || 'default',
            overallLeaseComments: selectedLease.comments,
            retailTypeId: selectedLease.commercialRetailTypeId || 'default',
            scheduledMoveInDate: getDateValue(selectedLease.moveInDate),
            leaseEffectiveDate: getDateValue(selectedLease.effectiveDate),
            commencementDate: getDateValue(selectedLease.startDate),
            leaseEndDate: getDateValue(selectedLease.endDate),
            rentStartDate: getDateValue(selectedLease.rentStartDate),
            isRentDateEstimated:
              typeof selectedLease.isRentDateEstimated === 'boolean'
                ? selectedLease.isRentDateEstimated.toString()
                : undefined,
            isMovedIn: selectedLease.isMovedIn || false,
          }
        : undefined,
    [selectedLease],
  );

  const editFormInitialValues = useMemo(
    () =>
      formInitialValues
        ? R.pick(
            [
              'leaseEffectiveDate',
              'commencementDate',
              'leaseEndDate',
              'rentStartDate',
              'overallLeaseComments',
              'scheduledMoveInDate',
              'isMovedIn',
              'isRentDateEstimated',
            ],
            formInitialValues,
          )
        : undefined,
    [formInitialValues],
  );

  useEffect(() => {
    if (isRetailTypeFieldShown) return;

    change(constants.FORM_NAME, 'retailTypeId', 'default');
    untouch(constants.FORM_NAME, 'retailTypeId');
  }, [isRetailTypeFieldShown, change, untouch]);

  const { handleStartLeaseRenewal, isSubmittingStartRenewal } =
    hooks.useAsyncStartLeaseRenewal({
      previousLeaseId: selectedLease.id,
      leaseData: { desiredSignatureMethod: MANUAL_SIGNING_METHOD },
      refreshLeases,
      toasterFn: toasterFunction,
    });

  const handleSubmit = (formValues: LeaseBasicsFormValues) => {
    onSubmit(selectedLeaseId, { ...formValues, ...concessionsFormValues });
  };

  const handleEditSubmit = (values: Object) => {
    onSubmit(selectedLeaseId, values);
    return values;
  };

  const handleEditSaveClick = () => {
    submit(constants.EDIT_FORM_NAME);
  };

  const handleLeaseEdit = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalHide = () => {
    setIsEditModalOpen(false);
  };

  const handlePDFBtnClick = () => {
    generateLeaseAbstract(organizationId, propertyId, selectedLease.id);
  };

  const isRenewal =
    selectedLease.isRenewal ||
    (selectedLease.isRenewalComplete && selectedLease.isMovedIn);

  if (isSpinnerShown) return <Spinner />;

  return (
    <React.Fragment>
      <Grid fluid>
        <Row>
          <SectionHeader
            leaseOptions={leaseOptions}
            onLeaseChange={onLeaseChange}
            selectedLeaseId={selectedLeaseId}
            windowWidth={windowSize.width}
            showAbstractButtons={leaseExists}
            showLeaseDropdown={showLeaseDropdown}
            onPDFBtnClick={handlePDFBtnClick}
            disabledAbstractButtons={isLeaseAbstractLoading}
          />
        </Row>
        <Row>
          <NewLeaseButtons
            isResident={isResident}
            residentId={residentId}
            applicationId={applicationId}
            selectedLease={selectedLease}
            isDisabledRenewal={isDisabledRenewal}
            handleLeaseRenewal={handleStartLeaseRenewal}
            handleRemoveLease={handleCancelLeaseRenewal}
            submittingRenewal={isSubmittingStartRenewal}
            navigationHistory={navigationHistory}
            doNotRenew={doNotRenew}
            canComplete={canComplete}
            underEviction={underEviction}
            startRenewalMessage={startRenewalMessage}
            noticeToVacate={noticeToVacate}
          />
        </Row>
        <MarginY value={24} />
        <Row>
          <LeaseBasicsForm
            intl={intl}
            lateMethodOptions={lateMethodOptions}
            leaseTypeOptions={leaseTypeOptions}
            retailTypeOptions={retailTypeOptions}
            unit={unit}
            customer={customer}
            initialValues={formInitialValues}
            onSubmit={handleSubmit}
            isRetailTypeFieldShown={isRetailTypeFieldShown}
            isEditable={isEditable}
            onLeaseEdit={handleLeaseEdit}
            isRenewal={isRenewal}
            priorLease={priorLease}
            noticeToVacate={noticeToVacate}
          />
        </Row>
      </Grid>
      <LeaseBasicsEditModal
        intl={intl}
        show={isEditModalOpen}
        onHide={handleEditModalHide}
        onSubmit={handleEditSubmit}
        onSaveClick={handleEditSaveClick}
        initialValues={editFormInitialValues}
        canSubmit={canSubmitEdit}
        isDirty={isEditFormDirty}
        isRenewal={isRenewal}
        priorLease={priorLease}
      />
    </React.Fragment>
  );
}

function mapStateToProps(state, ownProps): { selectedLeaseTypeId: string } {
  const formValues = R.pathOr(
    {},
    ['form', constants.FORM_NAME, 'values'],
    state,
  );
  const editFormSyncErrors = R.path(
    ['form', constants.EDIT_FORM_NAME, 'syncErrors'],
    state,
  );
  const selectedLeaseTypeId = R.propOr('default', 'leaseTypeId', formValues);
  const concessionsFormValues = getFormValues('Concessions')(state);
  return {
    selectedLeaseTypeId,
    canSubmitEdit: R.isNil(editFormSyncErrors),
    isEditFormDirty: isDirty(constants.EDIT_FORM_NAME)(state),
    concessionsFormValues,
    propertyId: R.pathOr(null, ['app', 'selectedProperty', 'id'], state),
  };
}

export default connect(mapStateToProps, { submit, change, untouch })(
  LeaseBasics,
);
