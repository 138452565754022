import messages from '../messages';
import React from 'react';
import Spinner from '../../../../../components/Spinner';
import PaginationFooter from '../../../../../components/PaginationFooter';
import LedgerRow from './LedgerRow';

type Props = {
  formatMessage: Object,
  transactions: Object,
  formatNumber: Function,
  isOps: boolean,
  isAllSubjournals: Array,
  onViewTransaction: Function,
  viewTransactionDisabled: boolean,
  transactionsIsLoading: boolean,
};

export const LedgerTable = (props: Props) => {
  const {
    formatMessage,
    formatNumber,
    isOps,
    isAllSubjournals,
    onViewTransaction,
    viewTransactionDisabled,
    allTransactions,
    transactionsIsLoading,
    page,
    setPage,
    limit,
  } = props;

  return (
    <>
      <div
        className="container-fluid table-scroll table-units-container"
        style={{ marginTop: '15px', maxWidth: '100%' }}
      >
        <table className="table table-prospects table-striped">
          <thead className="table-header">
            <tr>
              <th>{formatMessage(messages.unit)}</th>
              <th>{formatMessage(messages.transactionDate)}</th>
              <th>{formatMessage(messages.actualPostDate)}</th>
              {isOps ? <th>{formatMessage(messages.subjournal)}</th> : null}
              <th>{formatMessage(messages.period)}</th>
              <th>{formatMessage(messages.code)}</th>
              <th>{formatMessage(messages.status)}</th>
              <th>{formatMessage(messages.manual)}</th>
              <th>{formatMessage(messages.description)}</th>
              <th>{formatMessage(messages.documentNumber)}</th>
              <th>{formatMessage(messages.charges)}</th>
              <th>{formatMessage(messages.credits)}</th>
              <th>{formatMessage(messages.balance)}</th>
              <th>{formatMessage(messages.transactionNotes)}</th>
              <th>{formatMessage(messages.view)}</th>
            </tr>
          </thead>
          <tbody data-test="ledger-details">
            {transactionsIsLoading ? (
              <tr style={{ height: '580px' }}>
                <td colSpan={14}>
                  <Spinner />
                </td>
              </tr>
            ) : (
              <LedgerRow
                transactions={allTransactions?.results}
                formatMessage={formatMessage}
                formatNumber={formatNumber}
                isOps={isOps}
                isAllSubjournals={isAllSubjournals}
                onViewTransaction={onViewTransaction}
                viewTransactionDisabled={viewTransactionDisabled}
                transactionsIsLoading={transactionsIsLoading}
              />
            )}
          </tbody>
        </table>
      </div>
      <div className="container-fluid">
        {allTransactions?.meta.pageCount > 1 && (
          <PaginationFooter
            currentPage={page}
            limit={limit}
            count={allTransactions?.meta?.count}
            totalCount={allTransactions?.meta?.totalCount}
            pageCount={allTransactions?.meta?.pageCount}
            onPageChange={setPage}
          />
        )}
      </div>
    </>
  );
};
