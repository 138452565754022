import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { Grid, Form, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import MultiselectTwoSides from '@fortress-technology-solutions/react-multiselect-two-sides';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import SubmitButton from '../../../components/SubmitButton';

import validate from './validate';
import messages from './messages';
import {
  renderTextField,
  renderSelectField,
  renderCheckboxField,
} from '../../../utils/redux-form-helper';
import type { RoleType, Permission } from '../../App/types';

export const RoleName = ({ intl }: Object) => (
  <Col xs={12}>
    <h3>
      {' '}
      <FormattedMessage {...messages.roleName} />{' '}
    </h3>
    <section>
      <Field
        name="name"
        component={renderTextField}
        bsSize="lg"
        placeholder={intl.formatMessage(messages.roleName)}
      />
    </section>
  </Col>
);

export const AssigneeFlags = ({ intl }: Object) => (
  <Row>
    <Col xs={12} sm={6}>
      <Field
        name="canBeAssignedWorkOrder"
        component={renderCheckboxField}
        bsSize="lg"
        label={intl.formatMessage(messages.canBeAssignedWorkOrder)}
      />
    </Col>
    <Col xs={12} sm={6}>
      <Field
        name="canBeAssignedProspect"
        component={renderCheckboxField}
        bsSize="lg"
        label={intl.formatMessage(messages.canBeAssignedProspect)}
      />
    </Col>
  </Row>
);

export const RoleDescription = ({ intl }: Object) => (
  <Col xs={12}>
    <h3>
      {' '}
      <FormattedMessage {...messages.roleDescription} />{' '}
    </h3>
    <section>
      <Field
        name="description"
        component={renderTextField}
        bsSize="lg"
        placeholder={intl.formatMessage(messages.roleDescription)}
      />
    </section>
  </Col>
);

export const FieldRoleType = ({
  handleRoleTypeChange,
  roleTypes,
  intl,
}: Object) => (
  <Col xs={12}>
    <h3>
      {' '}
      <FormattedMessage {...messages.roleType} />{' '}
    </h3>
    <section>
      <Field
        name="roleTypeId"
        component={renderSelectField}
        options={roleTypes}
        bsSize="lg"
        onChange={(evt) => {
          handleRoleTypeChange(evt.target.value);
        }}
        placeholder={intl.formatMessage(messages.roleType)}
      />
    </section>
  </Col>
);

export const Buttons = ({
  submitting,
  valid,
  handleCancel,
  handleSubmit,
  onSubmit,
  submitPermissions,
  isSubmitting,
}: Object) => (
  <Row>
    <Col xs={12} sm={4}>
      <Button
        onClick={handleCancel}
        className="pull-right btn-xs-block"
        type="button"
        bsStyle="default"
      >
        <FormattedMessage {...messages.cancel} />
      </Button>
    </Col>
    <Col xs={12} sm={4}>
      <ElementWithPermissions scope={submitPermissions}>
        <SubmitButton
          classes="btn btn-primary center-block btn-xs-block"
          disabled={isSubmitting || !valid}
          clickHandler={handleSubmit((values) =>
            onSubmit({ ...values, shouldRedirect: true }),
          )}
          isSubmitting={isSubmitting}
        >
          <FormattedMessage {...messages.saveAndClose} />
        </SubmitButton>
      </ElementWithPermissions>
    </Col>
    <Col xs={12} sm={4}>
      <ElementWithPermissions scope={submitPermissions}>
        <SubmitButton
          classes="btn btn-primary pull-left btn-xs-block"
          disabled={isSubmitting || !valid}
          clickHandler={handleSubmit((values) =>
            onSubmit({ ...values, shouldRedirect: false }),
          )}
          isSubmitting={isSubmitting}
        >
          <FormattedMessage {...messages.saveAndAddNew} />
        </SubmitButton>
      </ElementWithPermissions>
    </Col>
  </Row>
);

type Props = {
  intl: any,
  header: string,
  permissions: Array<Permission>,
  roleTypes: Array<RoleType>,
  handleSubmit: Function,
  handleCancel: Function,
  valid: any,
  submitting: any,
  selectedPermissions: Array<string>,
  handleMultiselectChange: Function,
  handleRoleTypeChange: Function,
  hideMultiselect: boolean,
  nonePermissionsSelected: boolean,
  rolePermitsAssignees: boolean,
  change: Function,
  handlePermissionsSelectedError: Function,
  onSubmit: Function,
  submitPermissions: Array<string>,
  isSubmitting: boolean,
};
export class CreateRoleForm extends Component<Props> {
  componentWillReceiveProps(nextProps: any) {
    if (nextProps !== this.props) {
      this.props.change('permissions', nextProps.selectedPermissions);
      if (nextProps.selectedPermissions.length >= 1) {
        this.props.handlePermissionsSelectedError(false);
      }
    }
  }

  render() {
    const {
      header,
      handleSubmit,
      handleMultiselectChange,
      handleRoleTypeChange,
      permissions,
      intl,
      selectedPermissions,
      roleTypes,
      nonePermissionsSelected,
      rolePermitsAssignees,
      submitting,
      valid,
      handleCancel,
      onSubmit,
      submitPermissions,
      isSubmitting,
    } = this.props;

    const selectedCount = selectedPermissions.length;
    const availableCount = permissions.length - selectedCount;

    return (
      <Grid className="bodywrap col-centered" fluid={true}>
        <Form>
          <Row>
            <Col xs={12}>
              <h1
                className="title"
                style={{ display: 'inline-block', marginRight: '5px' }}
              >
                {header}
              </h1>
              <h5 className="small" style={{ display: 'inline-block' }}>
                *<FormattedMessage {...messages.subheader} />
              </h5>
            </Col>
          </Row>
          <Row>
            <RoleName intl={intl} />
          </Row>
          <Row>
            <RoleDescription intl={intl} />
          </Row>
          <Row>
            <FieldRoleType
              handleRoleTypeChange={handleRoleTypeChange}
              roleTypes={roleTypes}
              intl={intl}
            />
          </Row>
          {rolePermitsAssignees && <AssigneeFlags intl={intl} />}
          <Row>
            <Col xs={12}>
              <h3 className="hidden-xs">
                {' '}
                <FormattedMessage {...messages.selectPermissions} />{' '}
              </h3>
              <MultiselectTwoSides
                className={[
                  'msts_theme_example',
                  nonePermissionsSelected ? 'msts-error' : '',
                ].join(' ')}
                onChange={handleMultiselectChange}
                availableHeader="Available"
                availableFooter={`Available: ${availableCount}`}
                selectedHeader="Selected"
                selectedFooter={`Selected: ${selectedCount}`}
                placeholder="Filter…"
                options={permissions}
                searchable={true}
                showControls={true}
                value={selectedPermissions}
              />
              {nonePermissionsSelected && (
                <span className="has-error">
                  <FormattedMessage {...messages.selectPermission} />
                </span>
              )}
            </Col>
          </Row>
          <Buttons
            valid={valid}
            submitting={submitting}
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            submitPermissions={submitPermissions}
            isSubmitting={isSubmitting}
          />
        </Form>
      </Grid>
    );
  }
}

CreateRoleForm = reduxForm({
  form: 'createRole',
  validate: validate,
  enableReinitialize: true,
})(CreateRoleForm);

export default CreateRoleForm;
