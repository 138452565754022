import { put, takeEvery, select, all, spawn } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { pathOr } from 'ramda';

import { GET_KPI, GET_ALL_KPIS, USER_KPIS, OWNER_KPIS } from './constants';
import { getKpiSuccess, getAllKpisError } from './actions';
import KpiService from '../../services/kpiService';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
  getCurrentUser,
} from '../App/selectors';

function* fetchGetSingleKPI({
  payload: { kpi, signal, query = '' },
}): Saga<void> {
  const kpiWithQueryString = query ? `${kpi}${query}` : kpi;
  const organizationId = yield select(selectCurrentUserOrganizationId);
  const property = yield select(selectSelectedProperty);
  const kpiService = new KpiService();
  const response = yield kpiService.getKPI(
    organizationId,
    property.id,
    kpiWithQueryString,
    signal,
  );
  yield put(getKpiSuccess(kpi, response));
}

export function* fetchGetAllKpis({
  payload: { kpiList, signal },
}: Object): Saga<void> {
  try {
    const property = yield select(selectSelectedProperty);
    const currentUser = yield select(getCurrentUser);
    if (!property) {
    } else {
      const userRole = pathOr('', ['user', 'userRole', 'name'], currentUser);
      const isOwner = userRole === 'Owner';
      const kpis = isOwner ? OWNER_KPIS : USER_KPIS;
      yield all(
        kpis.map((kpi) =>
          spawn(fetchGetSingleKPI, { payload: { kpi, signal } }),
        ),
      );
    }
  } catch (err) {
    yield put(getAllKpisError(err));
  }
}

export function* getKpi(): Saga<void> {
  yield takeEvery(GET_KPI, fetchGetSingleKPI);
}

export function* getAllKpis(): Saga<void> {
  yield takeEvery(GET_ALL_KPIS, fetchGetAllKpis);
}

export default [getKpi, getAllKpis];
