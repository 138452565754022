import { defineMessages } from 'react-intl';

/* eslint-disable max-len */
const messages = defineMessages({
  adultsTitle: {
    id: 'App.EditHouseHoldForm.AdultsTitle',
    defaultMessage: 'Adult(s)',
  },
  minorsTitle: {
    id: 'App.EditHouseHoldForm.MinorsTitle',
    defaultMessage: 'Minor(s) - Under 18',
  },
  petsTitle: {
    id: 'App.EditHouseHoldForm.PetsTitle',
    defaultMessage: 'Pet(s)',
  },
  feesTitle: {
    id: 'App.EditHouseHoldForm.FeesTitle',
    defaultMessage: 'Fees charged to Applicant?',
  },
  feesTitleNoQuestion: {
    id: 'App.EditHouseHoldForm.FeesTitleNoQuestion',
    defaultMessage: 'Fees charged to Applicant',
  },
  householdFeesTitle: {
    id: 'App.EditHouseHoldForm.HouseholdFeesTitle',
    defaultMessage: 'Fees to Charge Household',
  },
  unitType: {
    id: 'App.EditHouseHoldForm.UnitType',
    defaultMessage: 'Unit Type* ',
  },
  applicantType: {
    id: 'App.EditHouseHoldForm.ApplicantType',
    defaultMessage: 'Type',
  },
  firstName: {
    id: 'App.EditHouseHoldForm.FirstName',
    defaultMessage: 'First',
  },
  middleName: {
    id: 'App.EditHouseHoldForm.MiddleName',
    defaultMessage: 'Middle',
  },
  lastName: {
    id: 'App.EditHouseHoldForm.LastName',
    defaultMessage: 'Last',
  },
  nameSuffix: {
    id: 'App.EditHouseHoldForm.NameSuffix',
    defaultMessage: 'Suffix',
  },
  preferredName: {
    id: 'App.EditHouseHoldForm.PreferredName',
    defaultMessage: 'Preferred Name',
  },
  affordableRelation: {
    id: 'App.EditHouseHoldForm.AffordableRelation',
    defaultMessage: 'Affordable Relation',
  },
  relationShip: {
    id: 'App.EditHouseHoldForm.RelationShip',
    defaultMessage: 'Relation',
  },
  phone: {
    id: 'App.EditHouseHoldForm.Phone',
    defaultMessage: 'Phone',
  },
  email: {
    id: 'App.EditHouseHoldForm.Email',
    defaultMessage: 'Email',
  },
  age: {
    id: 'App.EditHouseHoldForm.Age',
    defaultMessage: 'Age',
  },
  delete: {
    id: 'App.EditHouseHoldForm.Delete',
    defaultMessage: 'Delete',
  },
  name: {
    id: 'App.EditHouseHoldForm.Name',
    defaultMessage: 'Name',
  },
  petType: {
    id: 'App.EditHouseHoldForm.PetType',
    defaultMessage: 'Pet',
  },
  petBreed: {
    id: 'App.EditHouseHoldForm.PetBreed',
    defaultMessage: 'Type',
  },
  weight: {
    id: 'App.EditHouseHoldForm.Weight',
    defaultMessage: 'Weight (lbs)',
  },
  petTag: {
    id: 'App.EditHouseHoldForm.petTag',
    defaultMessage: 'Pet Tag',
  },
  serviceAnimal: {
    id: 'App.EditHouseHoldForm.ServiceAnimal',
    defaultMessage: 'Service Animal?',
  },
  addAdult: {
    id: 'App.EditHouseHoldForm.AddAdult',
    defaultMessage: '+ Add Adult',
  },
  addMinor: {
    id: 'App.EditHouseHoldForm.AddMinor',
    defaultMessage: '+ Add Minor',
  },
  addPet: {
    id: 'App.EditHouseHoldForm.AddPet',
    defaultMessage: '+ Add Pet',
  },
  convertToApplicant: {
    id: 'App.EditHouseHoldForm.ConvertToApplicant',
    defaultMessage: 'Convert to Applicant',
  },
  deleteConfirmation: {
    id: 'App.EditHouseHoldForm.DeleteConfirmation',
    defaultMessage: 'Are you sure you want to remove this item?',
  },
  cancelConfirmation: {
    id: 'App.EditHouseHoldForm.CancelConfirmation',
    defaultMessage: 'Are you sure you want to cancel?',
  },
  required: {
    id: 'App.EditHouseHoldForm.Required',
    defaultMessage: 'Field value is required.',
  },
  prospectValuesRequired: {
    id: 'App.EditHouseHoldForm.ProspectValuesRequired',
    defaultMessage:
      'First name or last name or email address or phone number should be provided for main prospect.',
  },
  invalidValue: {
    id: 'App.EditHouseHoldForm.InvalidValue',
    defaultMessage: 'Field value is not valid.',
  },
  invalidAmount: {
    id: 'App.EditHouseHoldForm.invalidAmount',
    defaultMessage: 'Please enter a valid amount.',
  },
  goBack: {
    id: 'App.EditHouseHoldForm.GoBack',
    defaultMessage: 'Go Back to Prospect Profile',
  },
  goBackApplicant: {
    id: 'App.EditHouseHoldForm.Applicant',
    defaultMessage: 'Go Back to Applicant Profile',
  },
  convertInstructions: {
    id: 'App.EditHouseHoldForm.convertInstructions',
    defaultMessage: 'Please review and make any changes before you continue.',
  },
  applicationEditInstructions: {
    id: 'App.EditHouseHoldForm.applicationEditInstructions',
    defaultMessage:
      'You can Add or Delete members of the Household here, ' +
      "but any changes to the Applicant's existing Household must be made on the Lease Applications via the Lease App tab " +
      'or by clicking {editIcon} below.',
  },
  requiredLabel: {
    id: 'App.EditHouseHoldForm.requiredLabel',
    defaultMessage: '(*) Required fields.',
  },
  title: {
    id: 'App.EditHouseHoldForm.Title',
    defaultMessage: 'Convert to Applicant:',
  },
  requiredPlaceholder: {
    id: 'App.EditHouseHoldForm.RequiredPlaceholder',
    defaultMessage: 'Required',
  },
  optionalPlaceholder: {
    id: 'App.EditHouseHoldForm.OptionalPlaceholder',
    defaultMessage: 'Optional',
  },
  submit: {
    id: 'App.EditHouseHoldForm.Submit',
    defaultMessage: 'Save Changes',
  },
  leaseLockedSentToPortal: {
    id: 'App.EditHouseHoldForm.LeaseLockedSentToPortal',
    defaultMessage:
      'A Lease for this household has been sent to Portal. If you need to edit the household, please go to the Lease Data tab and cancel the outstanding Lease.',
  },
  leaseLockedExecuted: {
    id: 'App.EditHouseHoldForm.lockedSentToPortal',
    defaultMessage:
      'A Lease for this household has been Executed. If you need to edit the household, please go to the Lease Data tab and cancel the outstanding Lease.',
  },
});

export default messages;
