import moment from 'moment';
import { autoOrManualNameConstants, statusNameConstants } from './constants';

const pillDateFormatter = (obj) => {
  const { fromDate, toDate } = obj;
  if (fromDate && toDate) {
    return {
      key: 'dateRange',
      text: `${moment(fromDate).format('MM/DD/YYYY')} - ${moment(toDate).format(
        'MM/DD/YYYY',
      )}`,
      formType: 'dateRangePicker',
    };
  }
  if (fromDate) {
    return {
      key: 'fromDate',
      text: moment(fromDate).format('MM/DD/YYYY'),
      formType: 'datePicker',
    };
  }
  return null;
};

const findTypeNameById = (id, types) => {
  return types.find((type) => type.value === id);
};

export const filteringPills = (filterObject, types) => {
  const date = pillDateFormatter(filterObject);
  const { fromDate, toDate, isRange, ...filters } = filterObject;
  const pills = [];
  for (const key of Object.keys(filters)) {
    if (filters[key]) {
      switch (key) {
        case 'statuses':
          filters[key].forEach((value) =>
            pills.push({
              key,
              value,
              text: statusNameConstants[value],
              formType: 'multi-select',
            }),
          );
          break;
        case 'types':
          filters[key].forEach((value) => {
            const typeName = findTypeNameById(value, types);
            pills.push({
              key,
              value,
              text: typeName.text,
              formType: 'multi-select',
            });
          });
          break;
        case 'autoOrManual':
          pills.push({
            key,
            value: filters[key],
            text: autoOrManualNameConstants[filters[key]],
            formType: 'single-select',
          });
          break;
        default:
      }
    }
  }

  return date ? [date, ...pills] : pills;
};
