import React from 'react';
import { Col } from 'react-bootstrap';
import LetterInput from './LetterInput';
import SMSInput from './SMSInput';
import EmailInput from './EmailInput';

type Props = {
  communicationType: string,
  communicationBatchGenerated: boolean,
  selectedResidents: Array<Object>,
  isLoading: boolean,
  isViewCommunication: boolean,
  recipientsAmount?: number,
  currentCommunicationCreationDate?: any,
  handleInputTextChange: Function,
  communicationData: Object,
  createdAt: Date,
  metricsInformation: Object,
  emailLetter: boolean,
  toggleEmailLetter: Function,
  organizationId: string,
  selectedPropertyId: string,
  toasterFn: Function,
  handleCommunicationEmailAttachmentChanges: Function,
};

type LetterInputProps = {
  openResidentsLetterModal: Function,
  documentName: string,
};

const CommunicationInput = (props: Props & LetterInputProps) => (
  <Col xs={12} md={7}>
    {props.communicationType === 'EMAIL' && (
      <EmailInput
        handleInputTextChange={props.handleInputTextChange}
        communicationData={props.communicationData}
        communicationBatchGenerated={props.communicationBatchGenerated}
        selectedResidents={props.selectedResidents}
        isLoading={props.isLoading}
        createdAt={props.createdAt}
        isViewCommunication={props.isViewCommunication}
        metricsInformation={props.metricsInformation}
        organizationId={props.organizationId}
        selectedPropertyId={props.selectedPropertyId}
        toasterFn={props.toasterFn}
        handleCommunicationEmailAttachmentChanges={
          props.handleCommunicationEmailAttachmentChanges
        }
      />
    )}
    {props.communicationType === 'LETTER' && (
      <LetterInput
        openResidentsLetterModal={props.openResidentsLetterModal}
        documentName={props.documentName}
        communicationBatchGenerated={props.communicationBatchGenerated}
        selectedResidents={props.selectedResidents}
        isLoading={props.isLoading}
        isViewCommunication={props.isViewCommunication}
        recipientsAmount={props.recipientsAmount}
        currentCommunicationCreationDate={
          props.currentCommunicationCreationDate
        }
        toggleEmailLetter={props.toggleEmailLetter}
        wasLetterEmailed={props.wasLetterEmailed}
      />
    )}
    {props.communicationType === 'SMS' && (
      <SMSInput
        handleInputTextChange={props.handleInputTextChange}
        smsText={props.communicationData.smsText}
        smsTitle={props.communicationData.smsTitle}
        communicationBatchGenerated={props.communicationBatchGenerated}
        selectedResidents={props.selectedResidents}
        isLoading={props.isLoading}
        createdAt={props.createdAt}
        isViewCommunication={props.isViewCommunication}
        metricsInformation={props.metricsInformation}
      />
    )}
  </Col>
);

export default CommunicationInput;
