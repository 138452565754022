import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';
import {
  reduxForm,
  Field,
  formValueSelector,
  FieldArray,
  change,
} from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  renderCurrencyField,
  renderSelectField,
  renderDateField,
  extractDateFormat,
} from '../../../utils/redux-form-helper';
import messages from '../messages.js';
import validate from './validate';
import { Props, TermLengthProps, CostProps, DropdownOption } from './types';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';

const mapTransactionCodesToDropdownOptions: Array<DropdownOption> = (
  transactionCodes,
) => {
  const codes = transactionCodes.map((txCode) => {
    return {
      value: txCode.transactionCode.id,
      text: txCode.transactionCode.code,
    };
  });
  codes.unshift({
    value: 'default',
    text: 'Choose',
    disabled: true,
  });
  return codes;
};

const disableField = (
  previousRent: string,
  previousTerm: string,
  enable: boolean,
): boolean => {
  return enable ? false : !previousRent || !previousTerm || +previousRent <= 0;
};

export const RenderTermLength = ({
  termsDropDown,
  fieldNumber,
  disable,
}: TermLengthProps) => (
  <div className="col-xs-12 col-sm-3" data-test="render-term-length">
    <div className="form-group">
      <label>
        <FormattedMessage {...messages.offer} />{' '}
        {fieldNumber === '1' ? `${fieldNumber} *` : `${fieldNumber} (Optional)`}
      </label>
      <Field
        selectClassNames="input-lg"
        name={`offer${fieldNumber}TermId`}
        component={renderSelectField}
        options={termsDropDown}
        maxLength="35"
        disabled={disable}
      />
    </div>
  </div>
);

export const RenderCost = ({ fieldName, disable, style }: CostProps) => (
  <div style={style} className="col-xs-12 col-sm-3" data-test="render-cost">
    <Field
      name={fieldName}
      component={renderCurrencyField}
      className="input-lg text-right"
      min="0"
      max="9999.99"
      step="1.00"
      placeholder="0.00"
      disabled={disable}
    />
  </div>
);

export const TransactionRow = ({
  field,
  propertyTransactionCodes,
  deleteTransaction,
  offer1Rent,
  offer1TermId,
  offer2Rent,
  offer2TermId,
  updateField,
  isTxCode,
  setIsTxCode,
  isTxType,
  setIsTxType,
}) => {
  const [filteredTxCodes, setFilteredTxCodes] = useState([
    {
      value: 'default',
      text: 'Choose',
      disabled: true,
    },
  ]);
  const transactionTypeOptions = [
    {
      value: 'default',
      text: 'Choose',
      disabled: true,
    },
    {
      value: 'Charge',
      text: 'Charge',
    },
    {
      value: 'Credit',
      text: 'Credit',
    },
  ];

  return (
    //
    <div className="row" data-test="transaction-row">
      <Stack alignItems="flex-start" flexDirection="row" spacing={2}>
        <div
          style={{ paddingLeft: '0px', paddingRight: '0px' }}
          className="col-xs-12 col-sm-3"
        >
          <Stack alignItems="flex-start" flexDirection="row" spacing={2}>
            <div className="col-xs-12 col-sm-6">
              <label>
                <FormattedMessage {...messages.type} />
              </label>
              <Field
                name={`${field}.transactionType`}
                selectClassNames="input-lg"
                component={renderSelectField}
                options={transactionTypeOptions}
                onChange={(e) => {
                  if (e.target.value === 'default') {
                    setFilteredTxCodes([
                      {
                        value: 'default',
                        text: 'Choose',
                        disabled: true,
                      },
                    ]);
                    setIsTxType(false);
                  } else {
                    setFilteredTxCodes(
                      mapTransactionCodesToDropdownOptions(
                        propertyTransactionCodes.filter(
                          (code) =>
                            code.transactionCode.transactionType.name ===
                            e.target.value,
                        ),
                      ),
                    );
                    updateField(`${field}.id`, 'default');
                    setIsTxType(true);
                  }
                }}
              />
            </div>
            <div className="col-xs-12 col-sm-6" style={{ marginTop: '0px' }}>
              <label>
                <FormattedMessage {...messages.transactionCode} />
              </label>
              <Field
                selectClassNames="input-lg"
                name={`${field}.id`}
                component={renderSelectField}
                onChange={() => setIsTxCode(true)}
                options={filteredTxCodes}
                maxLength="35"
                disabled={!isTxType}
              />
            </div>
          </Stack>
        </div>
        <RenderCost
          style={{ marginTop: '24px' }}
          fieldName={`${field}.offer1`}
          disable={!isTxCode}
        />
        <RenderCost
          style={{ marginTop: '24px' }}
          fieldName={`${field}.offer2`}
          disable={
            !offer1Rent || !offer1TermId || +offer1Rent <= 0 || !isTxCode
          }
        />
        <RenderCost
          style={{ marginTop: '24px' }}
          fieldName={`${field}.offer3`}
          disable={
            !offer2Rent || !offer2TermId || +offer2Rent <= 0 || !isTxCode
          }
        />
        <div style={{ marginTop: '34px' }} className="container-of-trash">
          <div className="row-remove-btn">
            <a onClick={() => deleteTransaction(field.match(/\d+/))}>
              <i className="et-trash" />
            </a>
          </div>
        </div>
      </Stack>
    </div>
  );
};

export const TransactionList = ({
  fields,
  propertyTransactionCodes,
  addTransaction,
  deleteTransaction,
  offer1Rent,
  offer1TermId,
  offer2Rent,
  offer2TermId,
  updateField,
  isTxCode,
  setIsTxCode,
  isTxType,
  setIsTxType,
}) => {
  return (
    <>
      {fields.map((field) => {
        return (
          <TransactionRow
            field={field}
            propertyTransactionCodes={propertyTransactionCodes}
            deleteTransaction={deleteTransaction}
            offer1Rent={offer1Rent}
            offer1TermId={offer1TermId}
            offer2Rent={offer2Rent}
            offer2TermId={offer2TermId}
            updateField={updateField}
            isTxCode={isTxCode}
            setIsTxCode={setIsTxCode}
            isTxType={isTxType}
            setIsTxType={setIsTxType}
          />
        );
      })}
      <div className="row">
        <a className="btn-text" onClick={addTransaction}>
          <FormattedMessage {...messages.addTransaction} />
        </a>
      </div>
    </>
  );
};

export const RenewalOfferForm = ({
  intl,
  termsDropDown,
  handleSubmit,
  valid,
  offer1Rent,
  offer2Rent,
  offer3Rent,
  offer1TermId,
  offer2TermId,
  offer3TermId,
  propertyTransactionCodes,
  previousOffers,
  array,
  updateField,
}: Props) => {
  const [isTxCode, setIsTxCode] = useState(false);
  const [isTxType, setIsTxType] = useState(false);
  const addTransaction = () => {
    array.push('transactionCodes', {
      id: 'default',
      transactionType: 'default',
      offer1: null,
      offer2: null,
      offer3: null,
    });
  };
  const deleteTransaction = (index) => {
    array.remove('transactionCodes', index);
  };

  const generateRenewalOffer = () => {
    setIsTxType(false);
    setIsTxCode(false);
    handleSubmit();
  };

  const { renewalOfferLetterRows } = useFlags();
  const dateFormat = extractDateFormat(intl);
  const tomorrow = moment().add(1).startOf('day');
  const offerExpires = moment().startOf('day').add(120, 'days').endOf('day');
  const validateDates = (currentDate: Moment) => {
    return currentDate.isBetween(tomorrow, offerExpires);
  };
  return (
    <div
      className="panel unit-information panel-withheader"
      data-test="renewal-offer-form"
    >
      <div className="panel-head">
        <div className="row">
          <div className="col-xs-12 col-sm-6 padtop10">
            <h2>
              <FormattedMessage {...messages.newRenewalOffer} />
            </h2>
          </div>
          <div className="col-xs-12 col-md-3 col-md-push-1 padtop10">
            <label>
              <FormattedMessage {...messages.offerExpires} /> &#42;
            </label>
          </div>
          <div className="col-xs-12 col-md-3">
            <Field
              name="offerExpirationDate"
              component={renderDateField}
              bsSize="lg"
              dateFormat={dateFormat}
              classes="input"
              isValidDate={validateDates}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="panel__details">
          <div className="row">
            <div className="col-xs-12 col-sm-3 padtop20">
              <h3 className="padtop20">
                <FormattedMessage {...messages.requiredTerm} />
              </h3>
            </div>
            <RenderTermLength
              termsDropDown={termsDropDown}
              fieldNumber="1"
              disable={false}
            />
            <RenderTermLength
              termsDropDown={termsDropDown}
              fieldNumber="2"
              disable={disableField(offer1Rent, offer1TermId, false)}
            />
            <RenderTermLength
              termsDropDown={termsDropDown}
              fieldNumber="3"
              disable={disableField(offer2Rent, offer2TermId, !!offer3TermId)}
            />
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-3 padtop15">
              <h3>
                <FormattedMessage {...messages.requiredLeaseRent} />
              </h3>
            </div>
            <RenderCost fieldName="offer1Rent" disable={false} />
            <RenderCost
              fieldName="offer2Rent"
              disable={disableField(offer1Rent, offer1TermId, false)}
            />
            <RenderCost
              fieldName="offer3Rent"
              disable={disableField(offer2Rent, offer2TermId, !!offer3Rent)}
            />
          </div>
          {renewalOfferLetterRows ? (
            <FieldArray
              name="transactionCodes"
              component={TransactionList}
              propertyTransactionCodes={propertyTransactionCodes}
              addTransaction={addTransaction}
              deleteTransaction={deleteTransaction}
              offer1Rent={offer1Rent}
              offer1TermId={offer1TermId}
              offer2Rent={offer2Rent}
              offer2TermId={offer2TermId}
              updateField={updateField}
              isTxCode={isTxCode}
              setIsTxCode={setIsTxCode}
              isTxType={isTxType}
              setIsTxType={setIsTxType}
            />
          ) : null}
          <div className="row">
            <div className="col-xs-12 padtop10">
              <p>
                <strong>
                  <FormattedMessage {...messages.note} />
                  &nbsp;
                </strong>
                <FormattedMessage {...messages.noteText} />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 padbottom15">
              <ElementWithPermissions scope={['lease-create']}>
                <button
                  className="btn btn-primary center-block"
                  type="button"
                  onClick={generateRenewalOffer}
                  disabled={!valid}
                >
                  <FormattedMessage {...messages.generateRenewalOffer} />
                </button>
              </ElementWithPermissions>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: Object, ownProps: Object): Props => {
  const {
    form,
    app: {
      currentUser: { permissions },
    },
    currentProperty,
    renewalOffer: { previousOffers },
  } = state;
  const selector = formValueSelector('renewalOffer');
  return {
    ownProps,
    permissions,
    offer1Rent: selector({ form }, 'offer1Rent'),
    offer2Rent: selector({ form }, 'offer2Rent'),
    offer3Rent: selector({ form }, 'offer3Rent'),
    offer1TermId: selector({ form }, 'offer1TermId'),
    offer2TermId: selector({ form }, 'offer2TermId'),
    propertyTransactionCodes: currentProperty.transactionCodes.codes ?? [],
    previousOffers,
    initialValues: {
      offerExpirationDate: 'MM/DD/YYYY',
      transactionCodes: [
        {
          id: 'default',
          transactionType: 'default',
          offer1: null,
          offer2: null,
          offer3: null,
        },
      ],
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateField: (field, newValue) =>
    dispatch(change('renewalOffer', field, newValue)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'renewalOffer',
    validate,
  })(RenewalOfferForm),
);
