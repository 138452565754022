import { defineMessages } from 'react-intl';

const messages = defineMessages({
  addNew: {
    id: 'App.viewUnit.EditFees.FeeList.AddNew',
    defaultMessage: '+ Add New {title}',
  },
  note1: {
    id: 'App.viewUnit.EditFees.FeeList.Note1',
    defaultMessage:
      // eslint-disable-next-line max-len
      '(1) When adding a New {title}, the start date must be after any currently listed market rent start dates in the table.',
  },
  note2: {
    id: 'App.viewUnit.EditFees.FeeList.Note2',
    defaultMessage:
      // eslint-disable-next-line max-len
      '(2) You can only edit or delete future {title}s. When doing so, you can only edit or delete the most future {title} first.',
  },
  note3: {
    id: 'App.viewUnit.EditFees.FeeList.Note3',
    defaultMessage:
      // eslint-disable-next-line max-len
      '(3) Adding a new HUD Gross Rent Limit will create a Gross Rent certification for all current residents assigned to units in this floor plan.',
  },
  note3UaType: {
    id: 'App.viewUnit.EditFees.FeeList.Note3UaType',
    defaultMessage:
      // eslint-disable-next-line max-len
      '(3) The start date of the utility allowance will appear on the Tenant Income Certification. The effective date may be the same or different dependent on when your property implemented the most recent utility allowance. The implementation date should be placed in the start date field.',
  },
  nonOptionalChargeNote1: {
    id: 'App.viewUnit.EditFees.FeeList.NonOptionalChargeNote1',
    defaultMessage:
      // eslint-disable-next-line max-len
      '(1) When adding a New Non-Optional Charge, the start date must be after any currently listed non-optional charge start dates in the table. ',
  },
  nonOptionalChargeNote2: {
    id: 'App.viewUnit.EditFees.FeeList.NonOptionalChargeNote2',
    defaultMessage:
      // eslint-disable-next-line max-len
      '(2) You can only edit or delete future Non-Optional Charges. When doing so, you can only edit or delete the most future Non-Optional Charge first. ',
  },
  noteNoteRent: {
    id: 'App.viewUnit.EditFees.FeeList.noteNoteRent',
    defaultMessage:
      // eslint-disable-next-line max-len
      '(3) When adding a new Note Rent value, you must update the Gross Rent Limit which is the Note Rent plus Utility Allowance.',
  },
});

export default messages;
