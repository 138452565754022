import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewBankDeposit.ViewBankDepositHeader.Title',
    defaultMessage: 'View Bank Deposit'
  },
  period: {
    id: 'App.ViewBankDeposit.ViewBankDepositHeader.Period',
    defaultMessage: 'Period'
  },
  batchId: {
    id: 'App.ViewBankDeposit.ViewBankDepositHeader.BatchId',
    defaultMessage: 'Batch ID'
  },
  batchTotal: {
    id: 'App.ViewBankDeposit.ViewBankDepositHeader.BatchTotal',
    defaultMessage: 'Batch Total'
  },
  bankAccount: {
    id: 'App.ViewBankDeposit.ViewBankDepositHeader.BankAccount',
    defaultMessage: 'Bank Account'
  },
  depositDate: {
    id: 'App.ViewBankDeposit.ViewBankDepositHeader.DepositDate',
    defaultMessage: 'Deposit Date'
  },
  note: {
    id: 'App.ViewBankDeposit.ViewBankDepositHeader.Note',
    defaultMessage: 'Note'
  }
});

export default messages;
