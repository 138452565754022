import FileSaver from 'file-saver';
import moment from 'moment';
import React from 'react';
import { toastr } from 'react-redux-toastr';
import styled from 'styled-components';
import useFormatMessage from '../../../../../hooks/useFormatMessage';
import DocumentService from '../../../../../services/documentService';
import { FileInput } from './FileInput';
import messages from './messages';

const StyledSpan = styled.span`
  font-size: 10px;
  font-weight: 400;
  color: #4e4e4e;
`;

const OverflowDiv = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block
  width: 345px;
`;

const downloadDocument = async (
  organizationId: string,
  propertyId: string,
  document: Object,
  formatMessage: Function,
) => {
  const documentService = new DocumentService();

  try {
    const file = await documentService.download(
      organizationId,
      document.id,
      propertyId,
    );

    FileSaver.saveAs(file, document.name);
  } catch (error) {
    toastr.error(
      formatMessage(messages.error),
      error.networkError ?? error.message,
    );
  }
};

export const ViewFile = (props) => {
  const formatMessage = useFormatMessage();

  if (!props.selectedInsurance?.proofOfCoverage)
    return <FileInput {...props} />;

  const file = props.input.value;

  return (
    <>
      {props.label && <label>{props.label}</label>}
      <div
        style={{
          display: 'block',
          color: '#4D73FF',
          fontWeight: 500,
          fontSize: 14,
          backgroundColor: '#F8F8F8',
          borderRadius: 3,
          height: 54,
          padding: '9px 13px',
        }}
      >
        <OverflowDiv title={file.name}>{file.name}</OverflowDiv>
        <a
          onClick={() => {
            downloadDocument(
              props.organizationId,
              props.propertyId,
              file,
              formatMessage,
            );
          }}
          style={{ float: 'right', fontSize: 16 }}
        >
          <i className="et-download" />
        </a>
        <StyledSpan style={{ verticalAlign: 'top' }}>
          {formatMessage(messages.added)}{' '}
          {moment(file.createdAt).format('MMMM D, YYYY')}
        </StyledSpan>
      </div>
      <StyledSpan style={{ margin: 4 }}>
        {formatMessage(messages.proofOfCoverageMessage)}
      </StyledSpan>
    </>
  );
};
