import type { Saga } from 'redux-saga';
import type { Action, Property } from '../../App/types';
import { put, takeLatest, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../../utils/redux-form-helper';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../../App/selectors';
import FloorPlanPricingService from '../../../services/floorPlanPricingService';
import {
  getAllPricings,
  getAllPricingsFailure,
  getAllPricingsSuccess,
  createPricingFailure,
  createPricingSuccess,
  deletePricingFailure,
  deletePricingSuccess,
  updatePricingFailure,
  updatePricingSuccess,
} from './actions';
import {
  GET_ALL_FLOORPLAN_PRICING,
  CREATE_FLOORPLAN_PRICING,
  UPDATE_FLOORPLAN_PRICING,
  DELETE_FLOORPLAN_PRICING,
} from './constants';

import componentMessages from './messages';
import generalMessages from '../../App/messages';

const messages = { ...componentMessages, ...generalMessages };

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetAllFloorPlanPricings({
  payload,
}: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const pricingService = new FloorPlanPricingService();
    const response = yield pricingService.getAll(
      organizationId,
      property.id,
      payload,
    );
    yield put(getAllPricingsSuccess(response));
  } catch (err) {
    yield put(getAllPricingsFailure(err));
  }
}

export function* getAllFloorPlanPricings(): Saga<void> {
  yield takeLatest(GET_ALL_FLOORPLAN_PRICING, fetchGetAllFloorPlanPricings);
}

export function* fetchCreateFloorPlanPricing({ payload }: Object): Saga<void> {
  const { viewUnit } = yield select();
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const pricingService = new FloorPlanPricingService();
    const { pricingType } = payload;
    const response = yield pricingService.create(
      organizationId,
      property.id,
      viewUnit.floorplanDetails.id,
      payload,
    );
    yield put(createPricingSuccess(response));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          pricingType === 'NON_OPTIONAL_CHARGE'
            ? messages.addedNewCharge
            : messages.addedNewPricing,
        ),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(createPricingFailure(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } finally {
    yield put(getAllPricings(viewUnit.floorplanDetails.id));
  }
}

export function* createFloorPlanPricing(): Saga<void> {
  yield takeLatest(CREATE_FLOORPLAN_PRICING, fetchCreateFloorPlanPricing);
}

export function* fetchDeleteFloorPlanPricing({ payload }: Object): Saga<void> {
  const { viewUnit } = yield select();
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const pricingService = new FloorPlanPricingService();
    const { pricingType } = payload;
    const response = yield pricingService.delete(
      organizationId,
      property.id,
      viewUnit.floorplanDetails.id,
      payload,
    );
    yield put(deletePricingSuccess(response));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          pricingType === 'NON_OPTIONAL_CHARGE'
            ? messages.deletedCharge
            : messages.deletedPricing,
        ),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(deletePricingFailure(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } finally {
    yield put(getAllPricings(viewUnit.floorplanDetails.id));
  }
}

export function* deleteFloorPlanPricing(): Saga<void> {
  yield takeLatest(DELETE_FLOORPLAN_PRICING, fetchDeleteFloorPlanPricing);
}

export function* fetchUpdateFloorPlanPricing({ payload }: Object): Saga<void> {
  const { viewUnit } = yield select();
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const pricingService = new FloorPlanPricingService();
    const { pricingType } = payload;
    const response = yield pricingService.update(
      organizationId,
      property.id,
      viewUnit.floorplanDetails.id,
      payload,
    );
    yield put(updatePricingSuccess(response));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          pricingType === 'NON_OPTIONAL_CHARGE'
            ? messages.updatedCharge
            : messages.updatedPricing,
        ),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(updatePricingFailure(err));
    yield toastrActions.add({
      type: 'error',
      message: err.toString(),
      title: renderTranslatedMessage(messages.error),
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    });
  } finally {
    yield put(getAllPricings(viewUnit.floorplanDetails.id));
  }
}

export function* updateFloorPlanPricing(): Saga<void> {
  yield takeLatest(UPDATE_FLOORPLAN_PRICING, fetchUpdateFloorPlanPricing);
}

export default [
  getAllFloorPlanPricings,
  createFloorPlanPricing,
  deleteFloorPlanPricing,
  updateFloorPlanPricing,
];
