export const HEADERS = [
  {
    id: 'unitNumber',
    label: 'Unit Number',
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'status',
    label: 'Status',
    searchable: true,
    sortable: true,
    multiselect: true,
    dataType: 'string',
  },
  {
    id: 'floorPlan',
    label: 'Floor Plan',
    searchable: true,
    sortable: true,
    multiselect: true,
    dataType: 'string',
  },
  {
    id: 'buildingNumber',
    label: 'Building Number',
    sortable: true,
    searchable: true,
    multiselect: true,
    dataType: 'string',
  },
  {
    id: 'profile',
    label: 'Resident / Applicant Name',
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'phoneNumber',
    label: 'Phone Number',
    sortable: true,
    searchable: true,
    dataType: 'string',
  },
  {
    id: 'moveInDate',
    label: 'Move-In Date',
    searchable: true,
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'moveOutDate',
    label: 'Move-Out Date',
    searchable: true,
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'collectionsNotes',
    label: 'Collections Notes',
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'underEviction',
    label: 'Under Eviction',
    sortable: true,
    searchable: false,
    multiselect: true,
    filterOptions: [
      { text: 'Yes', value: 'yes' },
      { text: 'No', value: 'no' },
    ],
    dataType: 'boolean',
  },
  {
    id: 'prepaidBalance',
    label: 'Prepaid Balance',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'delinquentBalance',
    label: 'Delinquent Balance',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'writtenOff',
    label: 'Written Off',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'subsidyOnlyBalance',
    label: 'Subsidy Only Balance',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'CAMOnlyBalance',
    label: 'CAM Only Balance',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'residentOnlyBalance',
    label: 'Resident Only Balance',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'rentOnlyBalance',
    label: 'Rent Only Balance',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'current',
    label: 'Current DQ',
    searchable: true,
    sortable: true,
    dataType: 'number',
    sx: { backgroundColor: 'primary.dark' },
  },
  {
    id: 'thirtyOneToSixty',
    label: '31-60 DQ',
    searchable: true,
    sortable: true,
    dataType: 'number',
    sx: { backgroundColor: 'primary.dark' },
  },
  {
    id: 'sixtyOneToNinety',
    label: '61-90 DQ',
    searchable: true,
    sortable: true,
    dataType: 'number',
    sx: { backgroundColor: 'primary.dark' },
  },
  {
    id: 'ninetyOnePlus',
    label: '91+ DQ',
    searchable: true,
    sortable: true,
    dataType: 'number',
    sx: { backgroundColor: 'primary.dark' },
  },
  {
    id: 'depositsHeld',
    label: 'Deposits Held',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'depositsOwed',
    label: 'Deposits Owed',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },

  {
    id: 'lateFees',
    label: '# Late Fees',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'nsfFees',
    label: '# NSF Fees',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'actions',
    label: 'Actions',
    searchable: false,
    sortable: false,
  },
];

export const PROPERTY_PATH_MAP = {
  unitNumber: {
    path: 'unitNumber',
    dataType: 'string',
  },
  status: {
    path: 'status',
    dataType: 'string',
  },
  floorPlan: {
    path: 'floorPlan',
    dataType: 'string',
  },
  buildingNumber: {
    path: 'buildingNumber',
    dataType: 'string',
  },
  profile: {
    path: 'profile',
    dataType: 'string',
  },
  phoneNumber: {
    path: 'phoneNumber',
    dataType: 'string',
  },
  moveInDate: {
    path: 'moveInDate',
    dataType: 'date',
  },
  moveOutDate: {
    path: 'moveOutDate',
    dataType: 'date',
  },
  collectionsNotes: {
    path: 'collectionsNotes',
    dataType: 'string',
  },
  underEviction: {
    path: 'underEviction',
    dataType: 'boolean',
  },
  prepaidBalance: {
    path: 'prepaidBalance',
    dataType: 'number',
  },
  delinquentBalance: {
    path: 'delinquentBalance',
    dataType: 'number',
  },
  writtenOff: {
    path: 'writtenOff',
    dataType: 'number',
  },
  subsidyOnlyBalance: {
    path: 'subsidyOnlyBalance',
    dataType: 'number',
  },
  CAMOnlyBalance: {
    path: 'CAMOnlyBalance',
    dataType: 'number',
  },
  residentOnlyBalance: {
    path: 'residentOnlyBalance',
    dataType: 'number',
  },
  rentOnlyBalance: {
    path: 'rentOnlyBalance',
    dataType: 'number',
  },
  current: {
    path: 'current',
    dataType: 'number',
  },
  thirtyOneToSixty: {
    path: 'thirtyOneToSixty',
    dataType: 'number',
  },
  sixtyOneToNinety: {
    path: 'sixtyOneToNinety',
    dataType: 'number',
  },
  ninetyOnePlus: {
    path: 'ninetyOnePlus',
    dataType: 'number',
  },
  depositsHeld: {
    path: 'depositsHeld',
    dataType: 'number',
  },
  depositsOwed: {
    path: 'depositsOwed',
    dataType: 'number',
  },
  lateFees: {
    path: 'lateFees',
    dataType: 'number',
  },
  nsfFees: {
    path: 'nsfFees',
    dataType: 'number',
  },
  actions: {
    path: 'residentId',
    dataType: 'string',
  },
};

export const SORT_TYPE = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const TOTAL_COLUMNS = {
  prepaidBalance: { variant: 'number', value: 0 },
  delinquentBalance: { variant: 'currency', value: 0 },
  writtenOff: { variant: 'currency', value: 0 },
  subsidyOnlyBalance: { variant: 'currency', value: 0 },
  CAMOnlyBalance: { variant: 'currency', value: 0 },
  residentOnlyBalance: { variant: 'currency', value: 0 },
  rentOnlyBalance: { variant: 'currency', value: 0 },
  current: { variant: 'currency', value: 0 },
  thirtyOneToSixty: { variant: 'currency', value: 0 },
  sixtyOneToNinety: { variant: 'currency', value: 0 },
  ninetyOnePlus: { variant: 'currency', value: 0 },
  depositsHeld: { variant: 'currency', value: 0 },
  depositsOwed: { variant: 'currency', value: 0 },
};
