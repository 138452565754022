import React from 'react';
import { find, propEq, pathOr } from 'ramda';

import { formatPeriod } from '../../../utils/redux-form-helper.js';
import messages from './messages';
import type { Period } from '../../ManageMiscTransactions/types.js';

type Props = {
  intl: Object,
  transactions: Array<Object>,
  periods: Array<Period>,
  onView: Function,
};

const wrangleBatch = (transaction: Object): Object => {
  return {
    type: 'Batch',
    notes: transaction.batchNote,
    paymentBatch: transaction.batchId,
    paymentId: '',
    date: transaction.batchDate,
    period: transaction.batchPeriod,
    amount: transaction.amount,
    numberOfItems: transaction.numberOfItems,
    reversedDepositId: '---',
    adjustments: transaction.adjusted ? 'Yes' : '',
  };
};

const wrangleSingle = (transaction: Object, periods: Array<Period>): Object => {
  const periodObject = find(propEq('id', transaction.paymentPeriod))(periods);
  const period = periodObject && periodObject.period ? periodObject.period : '';
  const treatAsNegative = pathOr(
    false,
    ['additionalTxInfo', 'payment', 'treatAsNegative'],
    transaction,
  );

  return {
    type: 'Single',
    notes: transaction.paymentNote,
    paymentBatch: '',
    paymentId: transaction.paymentID,
    date: transaction.paymentDate,
    period: period,
    amount: treatAsNegative
      ? transaction.paymentAmount * -1
      : transaction.paymentAmount,
    numberOfItems: 1,
    reversedDepositId: transaction.additionalTxInfo.reversedTxDeposit
      ? transaction.additionalTxInfo.reversedTxDeposit.name
      : '---',
    adjustments: transaction.additionalTxInfo.status !== 'Posted' ? 'Yes' : '',
  };
};

const depositTransactionRows = (
  intl: Object,
  transactions: Array<Object>,
  periods: Array<Period>,
  onView: Function,
): Array<any> => {
  const { formatDate, formatNumber } = intl;
  return transactions.map((transaction, i) => {
    const wrangledTransaction = transaction.batchId
      ? wrangleBatch(transaction)
      : wrangleSingle(transaction, periods);
    return (
      <tr key={i} data-test="deposit-row">
        <td>{wrangledTransaction.type}</td>
        <td>{wrangledTransaction.notes}</td>
        <td>{wrangledTransaction.paymentBatch}</td>
        <td>{wrangledTransaction.paymentId}</td>
        <td>
          {wrangledTransaction.date
            ? formatDate(wrangledTransaction.date, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
            : ''}
        </td>
        <td>{formatPeriod(wrangledTransaction.period)}</td>
        <td>
          {formatNumber(wrangledTransaction.amount, {
            style: 'currency',
            currency: 'USD',
          })}
        </td>
        <td>{wrangledTransaction.numberOfItems}</td>
        <td>{wrangledTransaction.reversedDepositId}</td>
        <td>{wrangledTransaction.adjustments}</td>
        <td>
          <button
            className="btn btn-sm btn-default"
            onClick={() => onView(transaction)}
          >
            <i className="et-pencil" />
          </button>
        </td>
      </tr>
    );
  });
};

const ViewDepositDetails = ({ intl, transactions, periods, onView }: Props) => {
  const { formatMessage } = intl;
  return (
    <div data-test="deposit-details">
      <div className="container-fluid">
        <div className="container-scrollable">
          <table className="table table-prospects table-fixed-headers table-striped">
            <thead className="table-header">
              <tr>
                <th>{formatMessage(messages.type)}</th>
                <th>{formatMessage(messages.notes)}</th>
                <th>{formatMessage(messages.paymentBatch)}</th>
                <th>{formatMessage(messages.paymentId)}</th>
                <th>{formatMessage(messages.date)}</th>
                <th>{formatMessage(messages.period)}</th>
                <th>{formatMessage(messages.amount)}</th>
                <th>{formatMessage(messages.numberOfItems)}</th>
                <th>Reversed Deposit ID</th>
                <th>{formatMessage(messages.adjustments)}</th>
                <th>{formatMessage(messages.view)}</th>
              </tr>
            </thead>
            <tbody>
              {transactions
                ? depositTransactionRows(intl, transactions, periods, onView)
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewDepositDetails;
