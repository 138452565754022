import { useQuery } from 'react-query';
import isEmpty from 'lodash/isEmpty';

import useAbortController from '../../../hooks/useAbortController';
import AffordableSetUpService from '../../../services/affordableSetUpService';
/**
 *
 *
 * @param {{
 *   organizationId: string,
 *   propertyId: string,
 *   state: string // Must be formatted as `<country>-<state>` ex: 'US-CA'
 * }} {
 *   organizationId,
 *   propertyId,
 *   state
 * }
 * @return {*}
 */
export const useFetchPassbookRatesByState = ({
  organizationId,
  propertyId,
  state,
}: {
  organizationId: string,
  propertyId: string,
  state: string,
}) => {
  const affordableSetUpService = new AffordableSetUpService();
  const abortControllerOptions = useAbortController();
  const queryKey = [
    'useFetchPassbookRatesByState',
    organizationId,
    propertyId,
    state,
  ];
  const { data, isLoading } = useQuery(
    queryKey,
    () =>
      affordableSetUpService.getAllPassbookRatesByState(
        organizationId,
        propertyId,
        state,
        abortControllerOptions,
      ),
    {
      enabled:
        !isEmpty(organizationId) && !isEmpty(propertyId) && !isEmpty(state),
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );

  return {
    data,
    isLoading,
  };
};
