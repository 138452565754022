import React from 'react';
import { Field } from 'redux-form';

import { renderCheckboxField } from '../../../utils/redux-form-helper';
import type { Props } from '../types';

export const ActionsCompleted = ({ completeRenewalActions }: Props) => {
  return (
    <>
      <h3>RENEWAL ACTIONS COMPLETED: </h3>
      <ul className="list-simple list-movein-actions">
        {completeRenewalActions.map((option, index) => (
          <li key={index}>
            <Field
              data-test="renewal-action-row"
              key={index}
              name={option.id.toString()}
              component={renderCheckboxField}
              bsSize="lg"
              label={option.text}
            />
          </li>
        ))}
      </ul>
    </>
  );
};
