import { defineMessages } from 'react-intl';

const messages = defineMessages({
  retroactiveModalTitle1: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.retroactiveModalTitle1',
    defaultMessage: 'WARNING:',
  },
  retroactiveModalTitle2: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.retroactiveModalTitle2',
    defaultMessage: 'The CAM Start Date is in the past:',
  },
  retroactiveModalSubtitle: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.retroactiveModalSubtitle',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Transactions previously posted to the ledger for the prior CAM may now apply to this CAM period. Review the posted transactions and make any necessary changes before saving:',
  },
  cancelingCamModalTitle1: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.cancelingCamModalTitle1',
    defaultMessage: 'WARNING:',
  },
  cancelingCamModalTitle2: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.cancelingCamModalTitle2',
    defaultMessage: 'This CAM has Pools applied to Transactions',
  },
  cancelingCamModalSubtitle: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.cancelingCamModalSubtitle',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Transactions previously posted to the ledger for this CAM must be reassigned to new Pools. Review the posted transactions and reassign them to CAM Pools before saving:',
  },
  postDate: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.postDate',
    defaultMessage: 'Post Date',
  },
  code: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.code',
    defaultMessage: 'Code',
  },
  amount: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.amount',
    defaultMessage: 'Amount',
  },
  camPeriod: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.camPeriod',
    defaultMessage: 'CAM Start Date / Period',
  },
  camPool: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.camPool',
    defaultMessage: 'CAM Pool',
  },
  retroactiveFootNote: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.retroactiveFootNote',
    defaultMessage:
      'Note: Estimates will not be confirmed if "Cancel" is selected.',
  },
  cancelingCamFootNote: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.cancelingCamFootNote',
    defaultMessage: 'Note: CAM will not be canceled if "Cancel" is selected.',
  },
  cancel: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.CamTab.CamCalculations.RetroactiveTransactions.save',
    defaultMessage: 'Save',
  },
});

export default messages;
