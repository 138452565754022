import {
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  appliedFilterSx,
  clearAllSx,
  deleteIconSx,
  filterPillSx,
  pillFontSx,
} from './styles';
import messages from '../ActivityContents/messages';
import { grey } from '@fortress-technology-solutions/fortress-component-library/design';

type Props = { filterPills: Object, filters: Object };

function FilterPills(props: Props) {
  const { reset, resetField, setValue } = useFormContext();

  const removePill = (f) => {
    const { key, value, formType } = f;

    if (formType === 'single-select') {
      props.setPage(1);
      resetField(key);
      return;
    }

    if (formType === 'multi-select') {
      props.setPage(1);
      resetField(key);
      setValue(
        key,
        { ...props.filters }[key].filter((n) => n !== value),
      );
      return;
    }

    if (formType === 'datePicker') {
      props.setPage(1);
      setValue('isRange', false);
      resetField(key);
      return;
    }

    if (formType === 'dateRangePicker') {
      props.setPage(1);
      setValue('isRange', false);
      resetField('fromDate');
      resetField('toDate');
    }
  };

  function Divider() {
    return (
      <svg width="100%" height="40" viewBox="0 0 843 1" fill="none">
        <rect width="843" height="1" fill={grey.lighter} />
      </svg>
    );
  }

  const handleReset = () => {
    props.setPage(1);
    reset();
  };

  if (props.filterPills.length > 0) {
    return (
      <Grid container alignItems={'center'}>
        <Grid item xs={1}>
          <Typography sx={appliedFilterSx}>
            {props.intl.formatMessage(messages.appliedFilters)}
          </Typography>
        </Grid>
        <Grid
          item
          lg={props.filterPills.length > 4 ? 10 : 'auto'}
          xl={props.filterPills.length > 5 ? 10 : 'auto'}
        >
          <Stack
            direction={'row'}
            style={{ margin: '4px 8px', flexWrap: 'wrap' }}
          >
            {props.filterPills.map((f, index) => (
              <Stack
                index={index}
                direction={'row'}
                sx={filterPillSx}
                alignItems={'center'}
                spacing={1}
              >
                <Typography sx={pillFontSx}>{f.text}</Typography>
                <Typography sx={deleteIconSx} onClick={() => removePill(f)}>
                  <i className="et-delete" />
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <Typography
            sx={clearAllSx}
            onClick={handleReset}
            style={{ cursor: 'pointer' }}
          >
            {props.intl.formatMessage(messages.clearAll)}
          </Typography>
        </Grid>
        <Divider />
      </Grid>
    );
  }
  return null;
}

export default FilterPills;
