import { GUARANTOR_LEASE_SIGNER } from './constants';

export const dropDownMapper = ({ id, name, code }) => {
  if (name) {
    return { value: id, text: name };
  } else {
    return { value: id, text: code };
  }
};

const parseYesNoFromTrueFalse = (value: ?boolean) => {
  if (value === true) {
    return 'yes';
  }
  if (value === false) {
    return 'no';
  }
  return '';
};

export const parseTrueFalseFromYesNo = (value: string) => {
  if (value === 'yes') {
    return true;
  }
  if (value === 'no') {
    return false;
  }
  return value;
};

export const parseExemptionIncomingValues = (exemptions) => {
  const {
    // Yes/no values
    allLeaseSignersAbleToSign59,
    householdExemptFromEligibilityCheck,
    householdHasHUDApprovalWaiver,
    householdRequestedHardshipException,
    qualifiesForSurvivorAssistance,
    qualifyForRentOverride,
    qualifyForWelfareRent,
    priorMedicalDisabilityDeduction,
    isUnitTransfer,
    householdHasExemptIncome,
    // Text values
    TTPbeforeOverride,
    welfareRentAmount,
    previousUnitNumber,
    // Select option values
    affordableExtenuatingCircumstanceId,
    affordableHardshipExceptionId,
    affordableIncomeExceptionCodeId,
    affordableWaiverCodeId,
  } = exemptions;

  const yesNoFields = {
    allLeaseSignersAbleToSign59: parseYesNoFromTrueFalse(
      allLeaseSignersAbleToSign59,
    ),
    hadPriorMedicalDisabilityDeduction: parseYesNoFromTrueFalse(
      priorMedicalDisabilityDeduction,
    ),
    householdExemptFromEligibilityCheck: parseYesNoFromTrueFalse(
      householdExemptFromEligibilityCheck,
    ),
    householdHasExemptIncome: parseYesNoFromTrueFalse(householdHasExemptIncome),
    householdHasHUDApprovalWaiver: parseYesNoFromTrueFalse(
      householdHasHUDApprovalWaiver,
    ),
    householdRequestedHardshipException: parseYesNoFromTrueFalse(
      householdRequestedHardshipException,
    ),
    qualifiesForSurvivorAssistance: parseYesNoFromTrueFalse(
      qualifiesForSurvivorAssistance,
    ),
    qualifyForRentOverride: parseYesNoFromTrueFalse(qualifyForRentOverride),
    qualifyForWelfareRent: parseYesNoFromTrueFalse(qualifyForWelfareRent),
    isUnitTransfer: parseYesNoFromTrueFalse(isUnitTransfer),
  };

  return {
    ...yesNoFields,
    TTPbeforeOverride: TTPbeforeOverride ?? '',
    welfareRentAmount: welfareRentAmount ?? '',
    affordableExtenuatingCircumstanceId:
      affordableExtenuatingCircumstanceId ?? '',
    affordableHardshipExceptionId: affordableHardshipExceptionId ?? '',
    affordableIncomeExceptionCodeId: affordableIncomeExceptionCodeId ?? '',
    affordableWaiverCodeId: affordableWaiverCodeId ?? '',
    previousUnitNumber: previousUnitNumber ?? '',
  };
};

export const parseCorrectionIncomingValues = (correction) => {
  const masterCorrectionCodeId = correction?.masterCorrectionCodeId ?? '';
  const eivIndicator = parseYesNoFromTrueFalse(correction?.eivIndicator);

  return { masterCorrectionCodeId, eivIndicator };
};

export const filterAndSortHouseholdMembersWithMinors = (
  householdMembers: Object[],
): Object[] => {
  const filteredAndSortedHouseholdMembersWithMinors = filterHouseholdMembers(
    householdMembers,
    true,
  );

  // sort household members by applicant type name minor last
  filteredAndSortedHouseholdMembersWithMinors.sort((a, b) => {
    const aIsMinor = a.applicantTypeName === 'Minor';
    const bIsMinor = b.applicantTypeName === 'Minor';

    if (aIsMinor && !bIsMinor) {
      return 1;
    }
    if (!aIsMinor && bIsMinor) {
      return -1;
    }
    return 0;
  });

  return filteredAndSortedHouseholdMembersWithMinors;
};
export const filterHouseholdMembers = (
  householdMembers: Object[],
  includeMinors: boolean = false,
): Object[] => {
  let filteredHouseholdMembers = householdMembers ? [...householdMembers] : [];

  if (filteredHouseholdMembers.length === 0) {
    return filteredHouseholdMembers;
  }

  filteredHouseholdMembers = filteredHouseholdMembers?.filter(
    (eventHouseholdMember) => {
      const { financiallyResponsible, applicantTypeName } =
        eventHouseholdMember;
      if (includeMinors && applicantTypeName === 'Minor') {
        return true;
      }
      return (
        financiallyResponsible && applicantTypeName !== GUARANTOR_LEASE_SIGNER
      );
    },
  );

  return filteredHouseholdMembers;
};
