// $FlowFixMe
import React, { useState } from 'react';
import { curryN } from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Row, Col, Table, Button } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import moment from 'moment';

import messages from './messages';
import { useAsyncGetUsers, useAsyncGetUserStatuses } from './hooks';

import { promptToaster } from '../../containers/App/actions';

import UserDetails from './UserDetails';
import SearchActions from './SearchActions';
import FilterDrawer from '../../components/FilterDrawer';
import ColumnHeader from '../../components/Common/columnHeader';
import PaginationFooter from '../../components/PaginationFooter';
import ElementWithPermissions from '../../components/ElementWithPermissions';

import { CSV_HEADERS } from './constants';

import downloadCsv from './downloadCsv';

const SORTABLE_COLUMNS = [
  { id: 'lastName', db: 'lastName' },
  { id: 'firstName', db: 'firstName' },
  { id: 'username', db: 'username' },
  { id: 'email', db: 'emailAddress' },
  { id: 'title', db: 'title' },
  { id: 'role', db: 'role' },
  { id: 'status', db: 'userStatus' },
  { id: 'lastAccess', db: 'lastSystemAccess' },
];
const STATIC_COLUMNS = [
  { id: 'properties', db: 'properties' },
  { id: 'actions', db: 'actions' },
  { id: 'view', db: 'view' },
];

const INITIAL_SORT_STATUS = SORTABLE_COLUMNS.reduce(
  (obj, { id }) => ({ ...obj, [id]: 'sortable' }),
  {},
);

type ConnectedMethods = {
  actions: {
    promptToaster: Function,
  },
};

const ManageUsersPage = ({
  intl,
  actions,
  locale,
  history,
  organizationId,
}: Object) => {
  /**
   * State
   */
  const [showFilter, setShowFilter] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});
  const [activeFilters, setActiveFilters] = useState({});
  const [columnSortStatus, setColumnSortStatus] = useState(INITIAL_SORT_STATUS);

  const setInitialActiveFilters = (newFilter) => {
    updateQuery({
      ...query,
      filterValue: newFilter,
      paginationInfo: { pageNumber: 1, limit: 15 },
    });
    setActiveFilters(newFilter);
    setCurrentFilters(newFilter);
  };
  const {
    userStatuses,
    isLoading: isUserStatusesLoading,
  } = useAsyncGetUserStatuses(setInitialActiveFilters, actions.promptToaster);

  const {
    meta: { count, totalCount, pageCount },
    results: users,
    query,
    updateQuery,
    isLoading: isUsersLoading,
  } = useAsyncGetUsers(
    organizationId,
    isUserStatusesLoading,
    actions.promptToaster,
  );

  /**
   * Methods
   */
  const onPageChange = (pageNumber: any) => {
    const {
      paginationInfo: { limit },
    } = query;
    const paginationInfo = { pageNumber, limit };
    updateQuery({ ...query, paginationInfo });
  };

  const generateFilters = () => {
    return [
      {
        fieldName: 'userStatusId',
        fieldDescription: 'Status',
        options: userStatuses.map((us) => ({
          text: us.name,
          value: us.id,
        })),
      },
    ];
  };

  const handleFilterOpen = () => {
    setShowFilter(true);
  };
  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleFilterApply = () => {
    updateQuery({
      ...query,
      filterValue: currentFilters,
      paginationInfo: { pageNumber: 1, limit: 15 },
    });
    setActiveFilters(currentFilters);
    setShowFilter(false);
  };
  const handleFilterChange = (field) => (value) => ({
    target: { checked },
  }) => {
    setCurrentFilters({
      ...currentFilters,
      [field]: {
        ...currentFilters[field],
        [value]: checked,
      },
    });
  };
  const handleFilterClear = () => {
    setCurrentFilters({});
  };
  const hasActiveFilters = () => {
    let filters = 0;
    Object.keys(activeFilters).map((af) =>
      Object.keys(activeFilters[af]).map((av) =>
        activeFilters[af][av] === true ? (filters += 1) : false,
      ),
    );
    return filters;
  };

  const handleSearchSubmit = ({ searchText }: Object) => {
    const { paginationInfo } = query;
    updateQuery({
      ...query,
      paginationInfo: { ...paginationInfo, pageNumber: 1 },
      searchText,
    });
  };

  const handleUserAdd = () => {
    history.push('/add-user');
  };
  const handleUserEdit = (id: any) => {
    history.push(`/edit-user/${id}`);
  };

  const getOrder = (fieldName: string) => {
    return fieldName === query.sorting.fieldName ? query.sorting.order : '';
  };

  const handleOrderClick = (field: string) => {
    if (isUsersLoading === true) return false;

    const currentSorting = columnSortStatus[field];
    const updatedIcon =
      currentSorting === 'sortable'
        ? 'ascending'
        : currentSorting === 'ascending'
        ? 'descending'
        : 'ascending';
    const updatedOrder = currentSorting === 'ascending' ? 'DESC' : 'ASC';
    setColumnSortStatus({
      ...INITIAL_SORT_STATUS,
      [field]: updatedIcon,
    });

    updateQuery({
      ...query,
      sorting: { fieldName: field, order: updatedOrder },
    });
  };
  const onOrderClickCurried = curryN(2, handleOrderClick);

  return (
    <DocumentTitle title={intl.formatMessage(messages.domTitle)}>
      <Grid className="bodywrap" fluid>
        <FilterDrawer
          filters={generateFilters()}
          show={showFilter}
          onCloseClick={handleFilterClose}
          onApplyClick={handleFilterApply}
          onFilterChange={handleFilterChange}
          clearFilters={handleFilterClear}
          currentFilter={currentFilters}
          formatMessage={intl.formatMessage}
        />
        <Row className="section-header">
          <Col xs={5}>
            <h1>
              <FormattedMessage {...messages.header} />
            </h1>
          </Col>
          <Col xs={7} className="text-right">
            <SearchActions
              onFilterClick={handleFilterOpen}
              hasActiveFilters={!!hasActiveFilters()}
              placeholder={intl.formatMessage(messages.searchUsers)}
              onSubmit={handleSearchSubmit}
              onDownload={() =>
                downloadCsv(
                  CSV_HEADERS,
                  'Manage Users Export',
                  `Run Date: ${moment().format('MM/DD/YYYY')}`,
                  organizationId,
                  query,
                )
              }
            />
            <ElementWithPermissions scope={['user-create']}>
              <Button
                className="btn btn-shout pull-right"
                onClick={handleUserAdd}
              >
                <FormattedMessage {...messages.addUser} />
              </Button>
            </ElementWithPermissions>
          </Col>
        </Row>
        <Row>
          <Col xs={12} id="table-row">
            <Table striped className="table-prospects">
              <thead className="table-header hidden-xs">
                <tr>
                  {SORTABLE_COLUMNS.map(({ id, db }) => (
                    <ColumnHeader
                      key={`column-header-${id}`}
                      columnLabel={intl.formatMessage(messages[id])}
                      sortable={true}
                      icon={columnSortStatus[db]}
                      order={getOrder(db)}
                      onOrderClick={onOrderClickCurried(db)}
                    />
                  ))}
                  {STATIC_COLUMNS.map(({ id, db }) => (
                    <ColumnHeader
                      key={`column-header-${id}`}
                      columnLabel={intl.formatMessage(messages[id])}
                    />
                  ))}
                </tr>
              </thead>
              {isUsersLoading === false && (
                <UserDetails
                  users={users}
                  intl={intl}
                  locale={locale}
                  handleEditUserClick={handleUserEdit}
                />
              )}
            </Table>
          </Col>
        </Row>
        {isUsersLoading === false && pageCount > 1 && (
          <PaginationFooter
            currentPage={query.paginationInfo.pageNumber}
            limit={query.paginationInfo.limit}
            count={count}
            totalCount={totalCount}
            pageCount={pageCount}
            onPageChange={onPageChange}
          />
        )}
      </Grid>
    </DocumentTitle>
  );
};

export const mapStateToProps = ({
  app: { currentUser },
  languageProvider,
}: any): any => {
  return {
    organizationId: currentUser.user.organizationId,
    locale: languageProvider.locale,
  };
};

export const mapDispatchToProps = (dispatch: Object): ConnectedMethods => ({
  actions: bindActionCreators({ promptToaster }, dispatch),
});

const InjectedManageUsers = injectIntl(ManageUsersPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedManageUsers);
