import { useState, useEffect } from 'react';
import InsurancesService from '../../../services/insurancesService';

export const DEFAULT_OPTION = { value: '', text: 'Choose', disabled: true };

export function useInsuranceTypeOptions(organizationId: string) {
  const [insuranceTypeOptions, setInsuranceTypeOptions] = useState([
    DEFAULT_OPTION,
  ]);
  const [optionsAreLoading, setOptionsAreLoading] = useState(false);
  const [optionsLoaded, setOptionsLoaded] = useState(false);

  useEffect(() => {
    if (optionsLoaded || optionsAreLoading) return;

    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const insurancesService = new InsurancesService();

    const fetchInsuranceTypes = async () => {
      const insuranceTypes = await insurancesService.getInsuranceTypes(
        organizationId,
        { signal: abortController.signal },
      );

      setInsuranceTypeOptions(
        [DEFAULT_OPTION].concat(
          insuranceTypes.map((it) => ({
            value: it.id,
            text: it.name,
          })),
        ),
      );
      setOptionsLoaded(true);
      setOptionsAreLoading(false);
    };

    fetchInsuranceTypes();
    setOptionsAreLoading(true);
  }, [insuranceTypeOptions, optionsLoaded, organizationId, optionsAreLoading]);

  return [insuranceTypeOptions, optionsLoaded];
}
