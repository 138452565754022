import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import useAbortController from '../useAbortController';
import AffordableService from '../../services/affordableService';

const useFetchHUDTransferProps = ({
  propertyId,
  organizationId,
  householdId,
  isTransfer,
  isAffordableOrMixedProperty,
  options,
}: {
  propertyId: string,
  organizationId: string,
  householdId: string,
  isTransfer: boolean,
  options?: UseQueryOptions,
}) => {
  const queryKey = [
    'useFetchHUDTransferProps',
    organizationId,
    propertyId,
    householdId,
  ];

  const abortControllerOptions = useAbortController();

  const affordableService = useMemo(() => new AffordableService(), []);

  const { data, isLoading } = useQuery(
    queryKey,
    async () => {
      const response = await affordableService.getHUDTransferProps(
        organizationId,
        propertyId,
        householdId,
        abortControllerOptions,
      );
      return response;
    },
    {
      enabled:
        Boolean(propertyId && organizationId) &&
        isTransfer &&
        isAffordableOrMixedProperty,
      refetchOnWindowFocus: false,
      ...options,
    },
  );

  const res = useMemo(
    () =>
      data ?? {
        hasMultipleHUDSubsidies: false,
        lastCertIsTermination: false,
      },
    [data],
  );

  return {
    hasMultipleHUDSubsidies: res?.hasMultipleHUDSubsidies,
    currentUnitHudCode: res?.currentUnitHudCode,
    lastCertIsTermination: res?.lastCertIsTermination,
    isLoading,
  };
};

export default useFetchHUDTransferProps;
