import React from 'react';
import { Form } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';

type Props = {
  handleSubmit: Function
};

export const SearchBox = ({ handleSubmit }: Props) => (
  <div className="search-actions-small">
    <div className="search-input search-input-active">
      <Form onSubmit={handleSubmit}>
        <Field
          className="input-lg"
          component="input"
          type="search"
          name="searchText"
          placeholder="Start typing to search..."
        />
        <button type="submit">
          <i className="et-search" />
        </button>
      </Form>
    </div>
  </div>
);

export default reduxForm({
  form: 'searchPriorResidents'
})(SearchBox);
