import { defineMessages } from 'react-intl';

const messages = defineMessages({
  chooseOption: {
    id: 'App.ProspectProfile.ProspectProfileForm.ChooseOption',
    defaultMessage: 'Choose an option',
  },
  title: {
    id: 'App.ProspectProfile.ProspectProfileForm.Title',
    defaultMessage: 'Fortress - Add new prospect',
  },
  successHeader: {
    id: 'App.ProspectProfile.SuccessHeader',
    defaultMessage: 'Success',
  },
  successDescription: {
    id: 'App.ProspectProfile.SuccessDescription',
    defaultMessage: 'The prospect was added.',
  },
  errorHeader: {
    id: 'App.ProspectProfile.errorHeader',
    defaultMessage: 'Error',
  },
  cancelConfirmation: {
    id: 'App.ProspectProfile.CancelConfirmation',
    defaultMessage:
      'Are you sure you want to discard the information on the form?',
  },
  createConfirmation: {
    id: 'App.ProspectProfile.CreateConfirmation',
    defaultMessage: 'Are you sure you want to create this prospect?',
  },
  unassigned: {
    id: 'App.ProspectProfile.Unassigned',
    defaultMessage: 'Unassigned',
  },
});

export default messages;
