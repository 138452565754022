import { useState, useEffect } from 'react';
import { INIT_PAG_PROPERTIES, INIT_QUERY } from './constants';
import UserService from '../../services/userService';

export const useAsyncGetUsers = (
  organizationId: string,
  isUserStatusesLoading: any,
  toasterFn: any,
) => {
  const [paginatedUsersInfo, setUsers] = useState(INIT_PAG_PROPERTIES);
  const [query, setQuery] = useState(INIT_QUERY);
  const [update, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const updateQuery = (newQuery) => {
    setUpdate(true);
    setQuery(newQuery);
  };

  useEffect(() => {
    let subscribed = true;

    const fetchUsers = async () => {
      const userService = new UserService();
      const response = await userService.getAllUsers(organizationId, query);

      if (response.error || (!response.meta && !response.results)) {
        toasterFn({
          type: 'error',
          message: `Error encountered retrieving Users: ${response.error}`,
        });

        setUsers(INIT_PAG_PROPERTIES);
      } else {
        if (response.results && response.results.length === 0) {
          toasterFn({
            type: 'info',
            message: 'This is currently no data to display',
          });
        }

        setUsers(response);
      }
      setIsLoading(false);
    };

    if (subscribed && isUserStatusesLoading === false) {
      fetchUsers();
    }

    return () => {
      subscribed = false;
    };
  }, [organizationId, query, update, isUserStatusesLoading, toasterFn]);

  return { ...paginatedUsersInfo, query, updateQuery, isLoading };
};

export const useAsyncGetUserStatuses = (
  setInitialActiveFilters: Function,
  toasterFn: any,
) => {
  const [info, setInfo] = useState({
    userStatuses: [],
    isLoading: true,
  });

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchUserStatuses = async () => {
      const userService = new UserService();
      const response = await userService.getAllUserStatuses();

      if (response.error) {
        toasterFn({
          type: 'error',
          message: `Error encountered retrieving UserStatuses: ${response.error}`,
        });
        setInfo({
          userStatuses: [],
          isLoading: false,
        });
      } else {
        const activeStatus = response.find((r) => r.name === 'Active');
        setInitialActiveFilters({
          userStatusId: {
            [activeStatus.id]: true,
          },
        });
        setInfo({
          userStatuses: response,
          isLoading: false,
        });
      }
    };

    if (subscribed) {
      fetchUserStatuses();
    }

    return () => {
      subscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toasterFn]);

  return info;
};
