import {
  handleSocketArsErrorEvent,
  handleSocketArsSuccessEvent,
} from './actions';

import { promptToaster } from '../../containers/App/actions';

/**
 * Consumed by Socket context to register listeners
 */
const applicationProfileSocketHandlers = (dispatch: Function, socket: any) => {
  socket.on('ars.success', (message) => {
    const {
      file: { data: fileData, name: fileName },
      payload: { applicationId },
    } = message;
    const payload = {
      applicationId,
      fileData,
      fileName,
    };
    dispatch(handleSocketArsSuccessEvent(payload));
  });

  socket.on('ars.pending', (message) => {
    dispatch(
      promptToaster({
        type: 'info',
        message: message.text,
        title: 'Pending',
      }),
    );
  });

  socket.on('ars.error', (message) => {
    dispatch(handleSocketArsErrorEvent(message));
  });
};

export default applicationProfileSocketHandlers;
