import { get, post, put, del } from '../utils/api';

class CommercialLeaseOptionsService {
  getAllOptions(organizationId: string, propertyId: string, options?: Object) {
    return get(
      `/${organizationId}/${propertyId}/commercial-lease-options`,
      options,
    );
  }

  getLeaseOptions(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/commercial-lease-options/lease/${leaseId}`,
      options,
    );
  }

  createLeaseOption(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    payload: Object,
    options?: Object,
  ) {
    const body = JSON.stringify(payload);
    return post(
      `/${organizationId}/${propertyId}/commercial-lease-options/lease/${leaseId}`,
      body,
      options,
    );
  }

  updateLeaseOption(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    optionId: string,
    payload: Object,
    options?: Object,
  ) {
    const body = JSON.stringify(payload);
    return put(
      `/${organizationId}/${propertyId}/commercial-lease-options/lease/${leaseId}/${optionId}`,
      body,
      options,
    );
  }

  deleteLeaseOption(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    optionId: string,
    options?: Object,
  ) {
    return del(
      `/${organizationId}/${propertyId}/commercial-lease-options/lease/${leaseId}/${optionId}`,
      options,
    );
  }
}

export default CommercialLeaseOptionsService;
