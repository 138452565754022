import React from 'react';
import { Col, Row } from 'react-bootstrap';
import messages from './messages';
import LeaseBasicsLeftColumn from './leftColumn';
import LeaseBasicsRightColumn from './rightColumn';

export const LeaseBasics = ({
  basicLeaseFees,
  canComplete,
  currentLeaseSelected,
  formValues,
  handleSubmit,
  intl,
  isEditable,
  labelEndDate,
  lateMethods,
  lease,
  leaseTerms,
  leaseTermOptions,
  residentId,
  transferInformation,
  showLeaseDataModal,
  unit,
  updateLeaseDate,
  noticeToVacate,
  formAsyncErrors,
  selectedProperty,
  promptToaster,
}: Object) => (
  <React.Fragment>
    {!residentId && (
      <Row className="padbottom10">
        <Col xs={12}>
          <h2>
            <i className="et-clipboard text-blue padright10" />
            {intl.formatMessage(messages.leaseBasicsLabel)}
          </h2>
        </Col>
      </Row>
    )}
    <Row className="container-fluid">
      <form>
        <LeaseBasicsLeftColumn
          basicLeaseFees={basicLeaseFees}
          intl={intl}
          isEditable={isEditable}
          lateMethods={lateMethods}
          isTransfer={lease.isTransfer}
          unit={unit}
        />
        <LeaseBasicsRightColumn
          currentLeaseSelected={currentLeaseSelected}
          formValues={formValues}
          handleSubmit={handleSubmit}
          intl={intl}
          isEditable={isEditable}
          lease={lease}
          leaseEndDate={labelEndDate ? labelEndDate : lease.endDate}
          leaseTerms={leaseTerms}
          leaseTermOptions={leaseTermOptions}
          residentId={residentId}
          transferInformation={transferInformation}
          showEditLink={!!(residentId && currentLeaseSelected)}
          showLeaseDataModal={showLeaseDataModal}
          unit={unit}
          valid={canComplete}
          updateLeaseDate={updateLeaseDate}
          noticeToVacate={noticeToVacate}
          formAsyncErrors={formAsyncErrors}
          selectedProperty={selectedProperty}
          promptToaster={promptToaster}
        />
      </form>
    </Row>
    <div className="divider" />
  </React.Fragment>
);

export default LeaseBasics;
