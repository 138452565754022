import * as ActionTypes from './constants';
import type { Role, RoleType, Permission } from '../App/types';

export function getAllPermissions() {
  return {
    type: ActionTypes.GET_ALL_PERMISSIONS,
  }
}

export function getAllPermissionsSuccess(permissions: Array<Permission>) {
  return {
    type: ActionTypes.GET_ALL_PERMISSIONS_SUCCESS,
    payload: permissions
  }
}

export function getAllPermissionsFail(error: Error) {
  return {
    type: ActionTypes.GET_ALL_PERMISSIONS_FAIL,
    payload: error
  }
}

export function getAllRoleTypes() {
  return {
    type: ActionTypes.GET_ALL_ROLE_TYPES,
  }
}

export function getAllRoleTypesSuccess(roleTypes: Array<RoleType>) {
  return {
    type: ActionTypes.GET_ALL_ROLE_TYPES_SUCCESS,
    payload: roleTypes
  }
}

export function getAllRoleTypesFail(error: Error) {
  return {
    type: ActionTypes.GET_ALL_ROLE_TYPES_FAIL,
    payload: error
  }
}

export function setSelectedPermissions(permissions: Array<Permission>): * {
  return {
    type: ActionTypes.SET_SELECTED_PERMISSIONS,
    payload: permissions
  };
}

export function showNonePermissionsSelectedError(nonePermissionsSelected: boolean): * {
  return {
    type: ActionTypes.NONE_PERMISSIONS_SELECTED,
    payload: nonePermissionsSelected
  };
}

export function createRole(role: Role): * {
  return {
    type: ActionTypes.CREATE_ROLE,
    payload: role
  };
}

export function createRoleSuccess(): * {
  return {
    type: ActionTypes.CREATE_ROLE_SUCCESS
  };
}

export function createRoleFail(error: Error): * {
  return {
    type: ActionTypes.CREATE_ROLE_FAIL,
    payload: error
  };
}

export function resetCreateRoleForm(): * {
  return {
    type: ActionTypes.RESET_CREATE_ROLE_FORM
  };
}

export function showRolePermitsAssignees(rolePermitAssgnees: boolean): * {
  return {
    type: ActionTypes.SHOW_ROLE_PERMIT_ASSIGNEED,
    payload: rolePermitAssgnees
  };
}
