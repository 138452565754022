import { select, call, put, take } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { getFormValues, reset } from 'redux-form';
import type { Saga } from 'redux-saga';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId
} from '../App/selectors';
import PaymentBatchService from '../../services/paymentBatchService';
import PaymentSingleTransactionService from '../../services/paymentSingleTransactionService';
import DepositsService from '../../services/depositsService';
import * as actions from './actions';
import * as actionTypes from './constants';
import * as selectors from './selectors';

export function* fetchUndepositedPaymentBatches(): Saga<void> {
  while (true) {
    yield take(actionTypes.GET_UNDEPOSITED_PAYMENT_BATCHES);
    try {
      const paymentBatchService = new PaymentBatchService();
      const selectedProperty = yield select(selectSelectedProperty);
      const orgId = yield select(selectCurrentUserOrganizationId);
      const batches = yield call(
        paymentBatchService.getAllUndepositedBatchesForProperty,
        selectedProperty.id,
        orgId
      );
      yield put(actions.getUndepositedPaymentBatchesSuccess(batches));
    } catch (err) {
      yield put(actions.getUndepositedPaymentBatchesFailure(err));
      yield put(
        toastrActions.add({
          type: 'error',
          message: 'Failed to load undeposited payment batches. Try reloading.',
          title: 'Error',
          options: {
            showCloseButton: true,
            removeOnHover: true
          }
        })
      );
    }
  }
}

export function* fetchUndepositedPayments(): Saga<void> {
  while (true) {
    yield take(actionTypes.GET_UNDEPOSITED_PAYMENTS);
    try {
      const paymentsService = new PaymentSingleTransactionService();
      const selectedProperty = yield select(selectSelectedProperty);
      const orgId = yield select(selectCurrentUserOrganizationId);
      const payments = yield call(
        paymentsService.getAllUndepositedForProperty,
        selectedProperty.id,
        orgId
      );
      yield put(actions.getUndepositedPaymentsSuccess(payments));
    } catch (err) {
      yield put(actions.getUndepositedPaymentsFailure(err));
      yield put(
        toastrActions.add({
          type: 'error',
          message: 'Failed to load undeposited payments. Try reloading.',
          title: 'Error',
          options: {
            showCloseButton: true,
            removeOnHover: true
          }
        })
      );
    }
  }
}

export function* createDeposit(): Saga<void> {
  while (true) {
    yield take(actionTypes.CREATE_DEPOSIT);
    try {
      const depositsService = new DepositsService();
      const selectedProperty = yield select(selectSelectedProperty);
      const orgId = yield select(selectCurrentUserOrganizationId);
      const header = yield select(getFormValues('depositHeaderForm'));
      const selectedForDeposit = yield select(selectors.getSelectedForDeposit);
      yield call(
        depositsService.createDeposit,
        {
          selectedForDeposit,
          header
        },
        selectedProperty.id,
        orgId
      );
      yield put(actions.createDepositSuccess());
      yield put(
        toastrActions.add({
          type: 'success',
          message: 'Payments deposited successfully',
          title: 'Success',
          options: {
            showCloseButton: true,
            removeOnHover: true
          }
        })
      );
      yield put(reset('depositHeaderForm'));
    } catch (err) {
      yield put(actions.createDepositFailure(err));
      yield put(
        toastrActions.add({
          type: 'error',
          message: err,
          title: 'Error',
          options: {
            showCloseButton: true,
            removeOnHover: true
          }
        })
      );
    }
  }
}

export default [
  fetchUndepositedPaymentBatches,
  fetchUndepositedPayments,
  createDeposit
];
