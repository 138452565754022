import { defineMessages } from 'react-intl';

const messages = defineMessages({
  status: {
    id: 'App.AddGroupGrid.Status',
    defaultMessage: 'Status',
  },
  unit: {
    id: 'App.AddGroupGrid.Unit',
    defaultMessage: 'Unit',
  },
  resident: {
    id: 'App.AddGroupGrid.Resident',
    defaultMessage: 'Resident',
  },
  tenant: {
    id: 'App.AddGroupGrid.Tenant',
    defaultMessage: 'Tenant',
  },
  floorPlan: {
    id: 'App.AddGroupGrid.FloorPlan',
    defaultMessage: 'Floor Plan',
  },
  leaseStartDate: {
    id: 'App.AddGroupGrid.LeaseStartDate',
    defaultMessage: 'Lease Start Date',
  },
  leaseEndDate: {
    id: 'App.AddGroupGrid.LeaseEndDate',
    defaultMessage: 'Lease End Date',
  },
  currentLedgerBalance: {
    id: 'App.AddGroupGrid.CurrentLedgerBalance',
    defaultMessage: 'Current Ledger Balance',
  },
  noDataDescription: {
    id: 'App.AddGroupGrid.NoDataDescription',
    defaultMessage: 'There is currently no data to display',
  },
  gridLabelResident: {
    id: 'App.AddGroupGrid.GridLabelResident',
    defaultMessage: 'Select Unit/Resident',
  },
  gridLabelTenant: {
    id: 'App.AddGroupGrid.GridLabelTenant',
    defaultMessage: 'Select Unit/Tenant',
  },
  tooltipResident: {
    id: 'App.AddGroupGrid.TooltipResident',
    defaultMessage:
      "This is the balance for the resident's ledger and does not include security balance.",
  },
  tooltipTenant: {
    id: 'App.AddGroupGrid.TooltipTenant',
    defaultMessage:
      "This is the balance for the tenant's ledger and does not include security balance.",
  },
  searchResident: {
    id: 'App.AddGroupGrid.searchResident',
    defaultMessage: 'Search for a Unit/Resident',
  },
  searchTenant: {
    id: 'App.AddGroupGrid.searchTenant',
    defaultMessage: 'Search for a Unit/Tenant',
  },
  errorMsgResident: {
    id: 'App.AddGroupGrid.ErrorMsgResident',
    defaultMessage:
      'There are no selected residents to be added. Select at least one to continue.',
  },
  errorMsgTenant: {
    id: 'App.AddGroupGrid.ErrorMsgTenant',
    defaultMessage:
      'There are no selected tenants to be added. Select at least one to continue.',
  },
});

export default messages;
