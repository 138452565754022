import * as ActionTypes from './constants';
import type { Action } from '../../App/types';

export function getTransferRequirements(transferReqs: Object): Action<any> {
  return {
    type: ActionTypes.GET_TRANSFER_REQUIREMENTS,
    payload: transferReqs,
  };
}

export function getTransferRequirementsSuccess(response: any): Action<any> {
  return {
    type: ActionTypes.GET_TRANSFER_REQUIREMENTS_SUCCESS,
    payload: response,
  };
}

export function getTransferRequirementsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_TRANSFER_REQUIREMENTS_ERROR,
    payload: error,
  };
}

export function completeTransfer(transferPayload: Object): Action<any> {
  return {
    type: ActionTypes.RESIDENT_COMPLETE_TRANSFER,
    payload: transferPayload,
  };
}

export function completeTransferSuccess(response: any): Action<any> {
  return {
    type: ActionTypes.RESIDENT_COMPLETE_TRANSFER_SUCCESS,
    payload: response,
  };
}

export function completeTransferError(error: Error): Action<Error> {
  return {
    type: ActionTypes.RESIDENT_COMPLETE_TRANSFER_ERROR,
    payload: error,
  };
}
