import { useEffect, useState } from 'react';
import { pick } from 'ramda';
import CommunicationService from '../../../../../services/communicationService';
import FileSaver from 'file-saver';

export const useUploadAttachment = ({
  organizationId,
  selectedPropertyId,
  communicationData,
  handleCommunicationEmailAttachmentChanges,
  toasterFn,
}: any) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const upload = async () => {
      const communicationService = new CommunicationService();
      const attachment = new FormData();
      attachment.append('file', file);
      try {
        const communicationEmailAttachment =
          await communicationService.uploadAttachment({
            attachment,
            organizationId,
            selectedPropertyId,
          });
        if (
          communicationEmailAttachment &&
          typeof communicationEmailAttachment === 'object'
        ) {
          const attachmentData = pick(
            ['attachmentUrl', 'fileName'],
            communicationEmailAttachment,
          );
          handleCommunicationEmailAttachmentChanges(attachmentData);
          toasterFn({
            message: 'Attachment uploaded successfully.',
            title: 'Success',
          });
        } else {
          throw new Error('Could not upload attachment');
        }
      } catch (err) {
        setFile(null);
        toasterFn({
          type: 'error',
          message: err,
          title: 'Error',
        });
      }
      setIsLoading(false);
    };

    if (
      file &&
      !communicationData.emailAttachment.attachmentUrl &&
      !isLoading
    ) {
      setIsLoading(true);
      upload();
    }
  }, [
    organizationId,
    selectedPropertyId,
    file,
    communicationData,
    toasterFn,
    isLoading,
    handleCommunicationEmailAttachmentChanges,
  ]);
  return { setFile, isLoading };
};

export const useDownloadAttachment = ({
  organizationId,
  selectedPropertyId,
  attachmentUrl,
  fileName,
  toasterFn,
}: any) => {
  const [downloadAttachment, setDownloadAttachment] = useState(false);

  const handleDownloadAttachment = () => setDownloadAttachment(true);

  useEffect(() => {
    const fetchDownloadAttachment = async () => {
      const communicationService = new CommunicationService();
      try {
        const file = await communicationService.downloadAttachment(
          organizationId,
          selectedPropertyId,
          attachmentUrl,
        );
        FileSaver.saveAs(file, fileName);
        toasterFn({
          message: 'Attachment downloaded successfully.',
          title: 'Success',
        });
      } catch (e) {
        toasterFn({
          type: 'error',
          message: 'There was an error downloading attachment',
          title: 'Error',
        });
      } finally {
        setDownloadAttachment(false);
      }
    };
    if (downloadAttachment && attachmentUrl) {
      fetchDownloadAttachment();
    }

    return () => {
      setDownloadAttachment(false);
    };
  }, [
    organizationId,
    selectedPropertyId,
    attachmentUrl,
    fileName,
    downloadAttachment,
    toasterFn,
  ]);

  return handleDownloadAttachment;
};

export const useDeleteAttachment = ({
  communicationData,
  handleCommunicationEmailAttachmentChanges,
  toasterFn,
  setFile,
}: any) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteAttachment = () => setIsDeleting(true);
  useEffect(() => {
    const deleteFile = async () => {
      if (!communicationData.emailAttachment.attachmentUrl)
        return Promise.resolve();
      setFile(null);
      handleCommunicationEmailAttachmentChanges({
        attachmentUrl: '',
        fileName: '',
      });
      toasterFn({
        message: 'Attachment deleted successfully.',
        title: 'Success',
      });
      setIsDeleting(false);
    };

    if (isDeleting) deleteFile();
  }, [
    isDeleting,
    communicationData,
    handleCommunicationEmailAttachmentChanges,
    setFile,
    toasterFn,
  ]);
  return handleDeleteAttachment;
};
