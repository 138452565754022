import type { Action, OrderValue, PaginationMeta } from '../App/types';
import * as ActionTypes from './constants';
import type { DocumentType, Document, DocumentListStatus } from './types';

export function getDocumentTypes(applicationId: string): Action<any> {
  return {
    type: ActionTypes.GET_DOCUMENT_TYPES,
    payload: {
      applicationId,
    },
  };
}

export function getDocumentTypesSuccess(
  documentTypes: Array<DocumentType>,
): Action<any> {
  return {
    type: ActionTypes.GET_DOCUMENT_TYPES_SUCCESS,
    payload: documentTypes,
  };
}

export function getResidentLetterDocumentTypes(
  applicationId: string,
): Action<any> {
  return {
    type: ActionTypes.GET_RESIDENT_LETTER_DOCUMENT_TYPES,
    payload: {
      applicationId,
    },
  };
}

export function getResidentLetterDocumentTypesSuccess(
  residentLetterDocumentTypes: Array<DocumentType>,
): Action<any> {
  return {
    type: ActionTypes.GET_RESIDENT_LETTER_DOCUMENT_TYPES_SUCCESS,
    payload: residentLetterDocumentTypes,
  };
}

/**
 * Handles saving a document to the store
 * -
 * @param {*} document
 * @param {*} pageNumber
 * @param {*} limit
 * @param {*} sorting
 * @param {*} options can be used to customize behaviour on sagas.js file
 * @returns a redux action object
 */
export function saveDocument(
  document: Document,
  pageNumber: number,
  limit: number,
  sorting: OrderValue,
  options?: Record<string, unknown>,
): Action<any> {
  return {
    type: ActionTypes.SAVE_DOCUMENT,
    payload: {
      ...document,
      pageNumber,
      limit,
      sorting,
      options,
    },
  };
}

export function saveDocumentSuccess(): Action<any> {
  return {
    type: ActionTypes.SAVE_DOCUMENT_SUCCESS,
    payload: undefined,
  };
}

export function saveDocumentError(): Action<any> {
  return {
    type: ActionTypes.SAVE_DOCUMENT_ERROR,
    payload: undefined,
  };
}

export function setDocumentsListStatus(
  status: DocumentListStatus,
): Action<any> {
  return {
    type:
      status === 'changed'
        ? ActionTypes.DOCUMENTS_LIST_CHANGED
        : ActionTypes.DOCUMENTS_LIST_UPDATED,
    payload: undefined,
  };
}

export function getAllDocuments(
  applicationId: string,
  pageNumber: number = 1,
  limit: number = 10,
  sorting: OrderValue = { fieldName: '' },
): Action<any> {
  return {
    type: ActionTypes.GET_ALL_DOCUMENTS,
    payload: {
      paginationInfo: {
        pageNumber,
        limit,
      },
      sorting,
      applicationId,
    },
  };
}

export function getAllDocumentsSuccess(
  documents: Array<Document>,
  meta: PaginationMeta,
): Action<any> {
  return {
    type: ActionTypes.GET_ALL_DOCUMENTS_SUCCESS,
    payload: documents,
    meta,
  };
}

export function updateColumnsSortValue(field: string, value: string) {
  return {
    type: ActionTypes.UPDATE_COLUMNS_SORT_VALUE,
    payload: { [field]: value },
  };
}

/**
 * Handles deleting a document on the store
 * -
 * @param {*} documentId
 * @param {*} applicationId
 * @param {*} pageNumber
 * @param {*} limit
 * @param {*} sorting
 * @param {*} options can be used to customize behaviour on sagas.js file
 * @returns a redux action object
 */
export function deleteDocument(
  documentId: string,
  applicationId: string,
  pageNumber: number,
  limit: number,
  sorting: OrderValue,
  options?: Record<string, unknown>,
): Action<any> {
  return {
    type: ActionTypes.DELETE_DOCUMENT,
    payload: {
      documentId,
      pageNumber,
      limit,
      sorting,
      applicationId,
      options,
    },
  };
}

export function downloadDocument(documentId: string, filename: string) {
  return {
    type: ActionTypes.DOWNLOAD_DOCUMENT,
    payload: {
      documentId,
      filename,
    },
  };
}

export function generateResidentLetter(
  applicationId: string,
  residentLetterId: string,
  filename: string,
  pageNumber: number,
  limit: number,
  sorting: OrderValue,
): Action<any> {
  return {
    type: ActionTypes.GENERATE_RESIDENT_LETTER,
    payload: {
      applicationId,
      residentLetterId,
      filename,
      pageNumber,
      limit,
      sorting,
    },
  };
}

export function generateResidentLetterSuccess(): Action<any> {
  return {
    type: ActionTypes.GENERATE_RESIDENT_LETTER_SUCCESS,
    payload: undefined,
  };
}

export function generateResidentLetterError(): Action<any> {
  return {
    type: ActionTypes.GENERATE_RESIDENT_LETTER_ERROR,
    payload: undefined,
  };
}
