import { useEffect, useState } from 'react';
import { pathOr, isNil, isEmpty } from 'ramda';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import UnitSqFtService from '../../../services/unitSqFtService';

export const useSqFtHistorySave = ({
  selectedProperty,
  unitId,
  type,
  refreshUnitCallback,
}: Object) => {
  const [isSaving, setIsSaving] = useState(false);
  const handleSaveHistory = async (obj: Object) => {
    try {
      let payload = {
        squareFeet: obj.squareFeet,
        startDate: moment(obj.startDate).toDate(),
      };
      const isRentable = type === 'rentable';
      let method;
      if (obj && !isEmpty(obj.id) && !isNil(obj.id)) {
        payload = { ...payload, id: obj.id };
        method = isRentable ? 'updateRentableSqFt' : 'updateUsableSqFt';
      } else {
        method = isRentable ? 'createRentableSqFt' : 'createUsableSqFt';
      }
      setIsSaving(true);
      const organizationId = pathOr('', ['organizationId'], selectedProperty);
      const propertyId = pathOr('', ['id'], selectedProperty);
      const unitSqFtServiceService = new UnitSqFtService();
      // $FlowFixMe
      const response = await unitSqFtServiceService[method]({
        organizationId,
        propertyId,
        unitId,
        payload,
      });

      await refreshUnitCallback(unitId);

      toastr.success('Success', 'History saved.');
      setIsSaving(false);
      return response;
    } catch (e) {
      toastr.error('Error', e.toString());
      setIsSaving(false);
    }
  };

  return {
    isSaving,
    handleSaveHistory,
  };
};

export const useSqFtHistoryDelete = ({
  selectedProperty,
  unitId,
  type,
  refreshUnitCallback,
}: Object) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDeleteHistory = async (id: string) => {
    const method =
      type === 'rentable' ? 'deleteRentableSqFt' : 'deleteUsableSqFt';
    try {
      setIsDeleting(true);
      const organizationId = pathOr('', ['organizationId'], selectedProperty);
      const propertyId = pathOr('', ['id'], selectedProperty);
      const unitSqFtServiceService = new UnitSqFtService();
      // $FlowFixMe
      const response = await unitSqFtServiceService[method]({
        organizationId,
        propertyId,
        unitId,
        id,
      });

      await refreshUnitCallback(unitId);

      toastr.success('Success', 'History deleted.');
      setIsDeleting(false);
      return response;
    } catch (e) {
      toastr.error('Error', e.toString());
      setIsDeleting(false);
    }
  };

  return {
    isDeleting,
    handleDeleteHistory,
  };
};

export const useAsyncSqFtHistories = (
  selectedProperty: Object,
  unitId: string,
  refresh: boolean,
) => {
  const [rentable, setRentable] = useState([]);
  const [usable, setUsable] = useState([]);
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };

    const fetchHistories = async () => {
      if (!isEmpty(selectedProperty) && !isNil(selectedProperty) && unitId) {
        const organizationId = pathOr('', ['organizationId'], selectedProperty);
        const propertyId = pathOr('', ['id'], selectedProperty);
        const unitSqFtServiceService = new UnitSqFtService();
        const rentableResponse = await unitSqFtServiceService.getRentableHistory(
          {
            organizationId,
            propertyId,
            unitId,
            options,
          },
        );
        const usableResponse = await unitSqFtServiceService.getUsableHistory({
          organizationId,
          propertyId,
          unitId,
          options,
        });
        setRentable(rentableResponse);
        setUsable(usableResponse);
      }
    };

    fetchHistories();
    return () => abortController.abort();
  }, [selectedProperty, unitId, refresh]);

  return { usable, rentable };
};
