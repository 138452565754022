import { useState, useEffect } from 'react';
import confirm from '../../../components/ConfirmDialogModal';
import EmergencyContactService from '../../../services/emergencyContactService';
import messages from './messages';
import type {
  BaseEmergencyContactsHookProps,
  EmergencyContactsHookWithRefreshProps,
} from './types';

const useFetchEmergencyContacts = ({
  intl,
  propertyId,
  organizationId,
  householdId,
  promptToaster,
}: BaseEmergencyContactsHookProps): Array<any> => {
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetchEmergencyContacts = async () => {
      setIsLoading(true);
      try {
        const emergencyContactService = new EmergencyContactService();
        const response = await emergencyContactService.getAll(
          organizationId,
          propertyId,
          householdId,
          abortController.signal,
        );
        let sorted = response
          .sort((contactA, contactB) => {
            return contactA.contactName.localeCompare(
              contactB.contactName,
              undefined,
              { sensitivity: 'base' },
            );
          })
          .sort((contactA, contactB) => {
            return (
              Number(contactB.isPrimaryContact) -
              Number(contactA.isPrimaryContact)
            );
          });

        setEmergencyContacts(sorted);
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.emergencyContactsFetchError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    };

    if (shouldRefresh && !isLoading) {
      fetchEmergencyContacts();
    }

    return () => {
      setShouldRefresh(false);
    };
  }, [
    organizationId,
    propertyId,
    householdId,
    promptToaster,
    intl,
    shouldRefresh,
    isLoading,
  ]);

  return {
    isLoading,
    emergencyContacts,
    refresh: () => setShouldRefresh(true),
  };
};

const useAddEmergencyContact = ({
  intl,
  organizationId,
  propertyId,
  householdId,
  promptToaster,
  refresh,
}: EmergencyContactsHookWithRefreshProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emergencyContact, setEmergencyContact] = useState(null);

  const addEmergencyContact = (emergencyContact: Object) => {
    setEmergencyContact(emergencyContact);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const addContact = async (emergencyContact: Object) => {
      setIsLoading(true);
      try {
        if (
          !propertyId ||
          !organizationId ||
          !householdId ||
          !emergencyContact
        ) {
          throw new Error('Could not add Emergency Contact');
        }

        const emergencyContactService = new EmergencyContactService();
        await emergencyContactService.add(
          organizationId,
          propertyId,
          householdId,
          emergencyContact,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.addEmergencyContactSuccessTitle),
          message: intl.formatMessage(
            messages.addEmergencyContactSuccessMessage,
          ),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.addEmergencyContactError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        refresh();
      }
    };

    if (!isLoading && emergencyContact) {
      addContact(emergencyContact);
    }

    return () => {
      setEmergencyContact(null);
    };
  }, [
    organizationId,
    propertyId,
    householdId,
    promptToaster,
    intl,
    isLoading,
    emergencyContact,
    refresh,
  ]);

  return addEmergencyContact;
};

const useEditEmergencyContact = ({
  intl,
  organizationId,
  propertyId,
  householdId,
  promptToaster,
  refresh,
}: EmergencyContactsHookWithRefreshProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emergencyContact, setEmergencyContact] = useState(null);

  const editEmergencyContact = (emergencyContact: Object) => {
    setEmergencyContact(emergencyContact);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const editContact = async (emergencyContact: Object) => {
      setIsLoading(true);
      try {
        if (
          !propertyId ||
          !organizationId ||
          !householdId ||
          !emergencyContact ||
          !emergencyContact.id
        ) {
          throw new Error('Could not edit Emergency Contact');
        }

        const emergencyContactService = new EmergencyContactService();
        await emergencyContactService.edit(
          organizationId,
          propertyId,
          householdId,
          emergencyContact,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.editEmergencyContactSuccessTitle),
          message: intl.formatMessage(
            messages.editEmergencyContactSuccessMessage,
          ),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.editEmergencyContactError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        refresh();
      }
    };

    if (!isLoading && emergencyContact) {
      editContact(emergencyContact);
    }

    return () => {
      setEmergencyContact(null);
    };
  }, [
    organizationId,
    propertyId,
    householdId,
    promptToaster,
    intl,
    isLoading,
    emergencyContact,
    refresh,
  ]);

  return editEmergencyContact;
};

const useSetEmergencyContactAsPrimary = ({
  intl,
  organizationId,
  propertyId,
  householdId,
  promptToaster,
  refresh,
}: EmergencyContactsHookWithRefreshProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emergencyContactId, setEmergencyContactId] = useState(null);

  const setEmergencyContactAsPrimary = (emergencyContactId: string) => {
    setEmergencyContactId(emergencyContactId);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const setPrimaryContact = async (emergencyContactId: string) => {
      setIsLoading(true);
      try {
        if (
          !propertyId ||
          !organizationId ||
          !householdId ||
          !emergencyContactId
        ) {
          throw new Error('Could not set Emergency Contact as primary contact');
        }

        const emergencyContactService = new EmergencyContactService();
        await emergencyContactService.setContactAsPrimary(
          organizationId,
          propertyId,
          householdId,
          emergencyContactId,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(
            messages.setPrimaryEmergencyContactSuccessTitle,
          ),
          message: intl.formatMessage(
            messages.setPrimaryEmergencyContactSuccessMessage,
          ),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.setPrimaryEmergencyContactError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        refresh();
      }
    };

    if (!isLoading && emergencyContactId) {
      confirm(intl.formatMessage(messages.confirmSetPrimary), { intl }).then(
        () => setPrimaryContact(emergencyContactId),
        () => {},
      );
    }

    return () => {
      setEmergencyContactId(null);
    };
  }, [
    organizationId,
    propertyId,
    householdId,
    promptToaster,
    intl,
    isLoading,
    emergencyContactId,
    refresh,
  ]);

  return setEmergencyContactAsPrimary;
};

const useDeleteEmergencyContact = ({
  intl,
  organizationId,
  propertyId,
  householdId,
  promptToaster,
  refresh,
}: EmergencyContactsHookWithRefreshProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [emergencyContactId, setEmergencyContactId] = useState(null);

  const deleteEmergencyContact = (emergencyContactId: string) => {
    setEmergencyContactId(emergencyContactId);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const deleteContact = async (emergencyContactId: string) => {
      setIsLoading(true);
      try {
        if (
          !propertyId ||
          !organizationId ||
          !householdId ||
          !emergencyContactId
        ) {
          throw new Error('Could not delete Emergency Contact');
        }

        const emergencyContactService = new EmergencyContactService();
        await emergencyContactService.delete(
          organizationId,
          propertyId,
          householdId,
          emergencyContactId,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(
            messages.deleteEmergencyContactSuccessTitle,
          ),
          message: intl.formatMessage(
            messages.deleteEmergencyContactSuccessMessage,
          ),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.deleteEmergencyContactError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        refresh();
      }
    };

    if (!isLoading && emergencyContactId) {
      deleteContact(emergencyContactId);
    }

    return () => {
      setEmergencyContactId(null);
    };
  }, [
    organizationId,
    propertyId,
    householdId,
    promptToaster,
    intl,
    isLoading,
    emergencyContactId,
    refresh,
  ]);

  return deleteEmergencyContact;
};

export {
  useFetchEmergencyContacts,
  useAddEmergencyContact,
  useEditEmergencyContact,
  useSetEmergencyContactAsPrimary,
  useDeleteEmergencyContact,
};
