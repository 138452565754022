import type { Document } from '../types';
import messages from './messages';

const validate = (values: Document, { intl }: Object) => {
  const errors = {};

  if (values.documentTypeId === 'default') {
    errors.documentTypeId = intl.formatMessage(messages.required);
  }

  if (values.applicantId === 'default') {
    errors.applicantId = intl.formatMessage(messages.required);
  }

  return errors;
};

export default validate;
