import React, { Component } from 'react';
import { Tooltip } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { FormattedMessage } from 'react-intl';
import { SORT_ASCENDING, SORT_DESCENDING } from '../constants';
import { type Header as Props } from '../types';

export default class Header extends Component<Props> {
  handleSort = () => {
    const { onSort, id } = this.props;
    if (onSort) onSort(id);
  };
  headerTooltip = (msg) => ({
    component: 'span',
    placement: 'top',
    variant: 'light',
    title: <FormattedMessage {...msg} />,
  });

  render() {
    const { title, onSort, active, classes, rowSpan, colSpan, scope, tooltip } =
      this.props;
    const icon =
      active === SORT_ASCENDING
        ? 'ascending'
        : active === SORT_DESCENDING
        ? 'descending'
        : 'sortable';

    const testTitle =
      typeof title === 'object' ? title.props.defaultMessage : title;

    return (
      <th
        className={`${classes ? ' ' + classes : ''}`}
        rowSpan={rowSpan}
        colSpan={colSpan}
        scope={scope}
      >
        {tooltip && (
          <Tooltip {...this.headerTooltip(tooltip)}>
            <AlertInfoIcon
              sx={{
                marginRight: 0.5,
                position: 'relative',
                top: 2,
                '& path': { stroke: 'white !important' },
              }}
              fontSize={'small'}
              strokeWidth={0.5}
            />
          </Tooltip>
        )}
        {title}

        {onSort && (
          <i
            className={`et-${icon} sort-option`}
            onClick={this.handleSort}
            data-testid={`sort-${testTitle.replace(' ', '-')}`}
          />
        )}
      </th>
    );
  }
}
