import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import messages from '../messages';

export const AssetFooter = ({
  totalAssets,
  totalAssetIncome,
  imputedIncome,
}) => {
  const totalAssetsColClass = 'col-xs-5';
  const totalMonthlyColClass = 'col-xs-5';
  return (
    <React.Fragment>
      <div className="row highlighted-footer">
        <div className={totalAssetsColClass}>
          <FormattedMessage {...messages.totalAssets} />
        </div>
        <div className="col-xs-7">
          <FormattedNumber
            value={totalAssets}
            style={{ value: 'currency' }.value}
            currency="USD"
          />
        </div>
      </div>
      <div className="row highlighted-footer padtop10">
        <div className={totalMonthlyColClass}>
          <FormattedMessage {...messages.totalMonthlyIncomeFromAssets} />
        </div>
        <div className="col-xs-2" />
        <div className="col-xs-4">
          <FormattedNumber
            value={totalAssetIncome}
            style={{ value: 'currency' }.value}
            currency="USD"
          />
        </div>
      </div>
      <div className="row highlighted-footer padtop10">
        <div className={totalMonthlyColClass}>
          <FormattedMessage {...messages.imputedIncome} />
        </div>
        <div className="col-xs-2" />
        <div className="col-xs-4">
          <FormattedNumber
            value={imputedIncome}
            style={{ value: 'currency' }.value}
            currency="USD"
          />
        </div>
      </div>
    </React.Fragment>
  );
};
