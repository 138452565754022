import type { Action } from '../App/types';
import type { SecondaryLeaseApplicationState } from './types';
import * as ActionTypes from './constants';

const initialState: SecondaryLeaseApplicationState = {
  applicant: undefined,
  isSubmitting: false,
};

const SecondaryLeaseApplicationReducer = (
  state: SecondaryLeaseApplicationState = initialState,
  action: Action<*>,
): SecondaryLeaseApplicationState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_APPLICANT_SECONDARY_FORM_INFORMATION_SUCCESS: {
      return { ...state, applicant: payload };
    }
    case ActionTypes.GET_APPLICANT_SECONDARY_FORM_INFORMATION_ERROR: {
      return { ...state, applicant: initialState.applicant };
    }
    case ActionTypes.SAVE_SECONDARY_FORM: {
      return { ...state, isSubmitting: true };
    }
    case ActionTypes.SAVE_SECONDARY_FORM_SUCCESS: {
      return { ...state, isSubmitting: false };
    }
    case ActionTypes.SAVE_SECONDARY_FORM_ERROR: {
      return { ...state, isSubmitting: false };
    }
    default:
      return state;
  }
};

export default SecondaryLeaseApplicationReducer;
