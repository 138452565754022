import React from 'react';
import { FormattedNumber } from 'react-intl';
import { renderCurrencyField } from '../../../../utils/redux-form-helper';
import { Field } from 'redux-form';
import normalizeProRatedAmount from './normalizeProRatedAmount';
type Props = {
  monthlyTransaction: Object,
  field: any,
  handleProRatedAmountChange: Function,
};

export const ProRatedDetails = ({
  monthlyTransaction,
  field,
  handleProRatedAmountChange,
}: Props): any => {
  return (
    <tr>
      <td>{monthlyTransaction.code}</td>
      <td>{monthlyTransaction.description}</td>
      <td>{monthlyTransaction.name}</td>
      <td>
        <FormattedNumber
          value={monthlyTransaction.amount}
          style={{ value: 'currency' }.value}
          currency="USD"
        />
      </td>
      <td>
        <Field
          name={`${field}.proratedAmount`}
          component={renderCurrencyField}
          className="text-right"
          min="0"
          max="9999"
          precision={2}
          step="0.01"
          normalize={normalizeProRatedAmount}
          disabled={true}
        />
      </td>
    </tr>
  );
};
