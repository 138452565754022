import { defineMessages } from 'react-intl';

const messages = defineMessages({
  failedToLoadCollectionSettings: {
    id: 'ledger.collectionsTrack.failedToLoadCollection',
    defaultMessage: 'Failed to load collection settings',
  },
  failedToLoadCollectionData: {
    id: 'ledger.collectionsTrack.failedToLoadCollectionData',
    defaultMessage: 'Failed to load collection data',
  },
  failedToActivatePaymentPlan: {
    id: 'ledger.collectionsTrack.failedToActivatePaymentPlan',
    defaultMessage: 'Failed to activate payment plan',
  },
  paymentPlanActivated: {
    id: 'ledger.collectionsTrack.paymentPlanActivated',
    defaultMessage: 'Payment plan activated',
  },
  success: {
    id: 'ledger.collectionsTrack.success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'ledger.collectionsTrack.error',
    defaultMessage: 'Error',
  },
  failedToLoadClaimData: {
    id: 'ledger.collectionsTrack.failedToLoadClaimData',
    defaultMessage: 'Failed to load claim data',
  },
  accountClosed: {
    id: 'ledger.collectionsTrack.accountClosed',
    defaultMessage: 'Account has been closed',
  },
  failedToCloseAccount: {
    id: 'ledger.collectionsTrack.failedToCloseAccount',
    defaultMessage: 'Failed to close account',
  },
  accountReOpened: {
    id: 'ledger.collectionsTrack.accountReOpened',
    defaultMessage: 'Account has been re-opened',
  },
  failedToReOpenAccount: {
    id: 'ledger.collectionsTrack.failedToReOpenAccount',
    defaultMessage: 'Failed to re-open account',
  },
  paymentPlanRemoved: {
    id: 'ledger.collectionsTrack.paymentPlanRemoved',
    defaultMessage: 'Payment plan has been removed',
  },
  failedToRemovePaymentPlan: {
    id: 'ledger.collectionsTrack.failedToRemovePaymentPlan',
    defaultMessage: 'Failed to remove payment plan',
  },
  sentToCollectionsSuccess: {
    id: 'ledger.collectionsTrack.sentToCollectionsSuccess',
    defaultMessage: 'Account has been sent to collections',
  },
  failedToSendToCollections: {
    id: 'ledger.collectionsTrack.failedToSendToCollections',
    defaultMessage: 'Failed to send account to collections',
  },
  removeSentToCollectionsSuccess: {
    id: 'ledger.collectionsTrack.removeSentToCollectionsSuccess',
    defaultMessage: 'Account has been removed from collections',
  },
  failedToRemoveSentToCollections: {
    id: 'ledger.collectionsTrack.failedToRemoveSentToCollections',
    defaultMessage: 'Failed to remove account from collections',
  },
});

export default messages;
