import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { keys, pickBy } from 'ramda';
import { bindActionCreators } from 'redux';
import DocumentTitle from 'react-document-title';
import { injectIntl, type InjectIntlProvidedProps } from 'react-intl';
import { filterFormInitialValues } from './Filter/filterConfigurations';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import messages from './messages';
import {
  cleanLoadedExpiringLeases,
  getExpiringLeases,
  selectLeaseForRenewal,
} from './actions';
import type { OrderValue, Property } from '../App/types';
import ExpiredLeasesFormFilter from './Filter/index';
import * as selectors from './selectors';
import Header from './Header';
import * as utils from './utils';
import LeaseInfoCards from './LeaseInfoCards';
import Table from './Table';
import moment from 'moment';
import { promptToaster } from '../App/actions';
import ManageLeaseExpirationLimitsLink from '../../components/ManageLeaseExpirationLimitsLink';
import Spacer from '../../components/Spacer';
import useExpirationLimitsPropertyToggle from '../../hooks/data-fetching/useExpirationLimitsPropertyToggle';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type ConnectedProps = {
  organizationId: string,
  propertyId: Object,
  assignedUnits: ?Array<Object>,
  isFetching: boolean,
  selectedProperty: Property,
};

type ConnectedMethods = {
  actions: {
    getExpiringLeases: Function,
    selectLeaseForRenewal: Function,
    cleanLoadedExpiringLeases: Function,
  },
};

type DefaultProps = {
  today: Date,
  headers: Array<Object>,
  leases: Array<Object>,
};

type Props = InjectIntlProvidedProps &
  ConnectedMethods &
  ConnectedProps &
  DefaultProps & {
    history: Object,
  };

const defaultState = {
  showFilter: false,
  sorting: {
    fieldName: '',
    order: '',
  },
  expirationPeriod: Object.keys(filterFormInitialValues.ExpirationSection).join(
    ',',
  ),
  renewalStatus: '',
  searchText: '',
  expirationRange: {
    from: '',
    to: '',
  },
};

export const ManageLeaseExpirations = ({
  actions: {
    getExpiringLeases,
    cleanLoadedExpiringLeases,
    selectLeaseForRenewal,
    toastrFn,
  },
  intl: { formatMessage },
  assignedUnits,
  organizationId,
  history,
  today,
  isFetching,
  selectedProperty,
  location,
}: Props) => {
  const { leaseExpirationMgmtV1 } = useFlags();
  const { isLeaseExpirationLimitsActive } = useExpirationLimitsPropertyToggle(
    organizationId,
    selectedProperty?.id,
    toastrFn,
  );

  const [state, setState] = useState(() => {
    const paramFilters = utils.getParamFilters(location);

    return { ...defaultState, ...paramFilters };
  });

  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    getExpiringLeases(state);
    return cleanLoadedExpiringLeases;
  }, [state, getExpiringLeases, cleanLoadedExpiringLeases]);

  const isAllCommercial = selectedProperty.hasCommercialFloorPlans === 'ALL';
  const hasActiveFilters = Boolean(
    state.searchText?.length ||
      state.expirationPeriod?.length ||
      state.renewalStatus?.length ||
      state.expirationRange?.from?._isAMomentObject ||
      state.expirationRange?.to?._isAMomentObject,
  );

  const handleSubmit = ({ searchText }: { searchText: string }) => {
    const nextState = {
      ...state,
      searchText,
    };
    setState(nextState);
  };

  const handleSort = (sorting: OrderValue) => {
    const nextState = { ...state, sorting };
    setState(nextState);
  };

  const quoteLeaseRenewalClick = (residentId: string) => {
    const url = `/generate-renewal-offer/${residentId}`;
    navigateToUrlWithSelectedPropertyId(url);
  };

  const filterLeases = (values: {
    ExpirationSection: { [name: string]: boolean },
    RenewalStatusSection: { [name: string]: boolean },
    LeaseEndDateRangeSection: { from: Date, to: Date },
  }) => {
    const filteredExpirationSection = pickBy(
      (val) => val,
      values.ExpirationSection,
    );
    const nextState = {
      ...state,
      expirationPeriod: keys(filteredExpirationSection).join(','),
      renewalStatus: keys(
        pickBy((val) => val, values.RenewalStatusSection),
      ).join(','),
      expirationRange: values.LeaseEndDateRangeSection,
    };
    setState(nextState);
  };

  return (
    <DocumentTitle
      data-testid={'DocumentTitle'}
      title={formatMessage(messages.title)}
    >
      <div className="bodywrap container-fluid">
        <ExpiredLeasesFormFilter
          data-testid={'ExpiredLeasesFormFilter'}
          onSubmit={filterLeases}
          hide={() => setShowFilter(false)}
          show={showFilter}
          isFetching={isFetching}
          initialValues={utils.getFilterFormInitialValues({
            expirationRange: state.expirationRange,
            expirationPeriod: state.expirationPeriod,
            renewalStatus: state.renewalStatus,
          })}
        />
        <div className="row">
          <div className="col-xs-12">
            <Header
              assignedUnitsCount={assignedUnits.length}
              formatMessage={formatMessage}
              handleSubmit={handleSubmit}
              hasActiveFilters={hasActiveFilters}
              organizationId={organizationId}
              selectedProperty={selectedProperty}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              downloadCSV={() =>
                utils.downloadCSV({
                  assignedUnits,
                  selectedProperty,
                  hasActiveFilters,
                })
              }
            />

            {leaseExpirationMgmtV1 && isLeaseExpirationLimitsActive && (
              <>
                <Spacer v={20} />
                <ManageLeaseExpirationLimitsLink
                  organizationId={organizationId}
                  propertyId={selectedProperty.id}
                  longText
                  history={history}
                />
              </>
            )}
            <LeaseInfoCards assignedUnits={assignedUnits} />
            <Table
              assignedUnits={assignedUnits}
              handleSort={handleSort}
              isAllCommercial={isAllCommercial}
              isFetching={isFetching}
              quoteLeaseRenewalClick={quoteLeaseRenewalClick}
              selectLeaseForRenewal={selectLeaseForRenewal}
              today={today}
            />
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

ManageLeaseExpirations.defaultProps = {
  assignedUnits: [],
  today: moment.utc(),
};

function mapStateToProps(state): ConnectedProps {
  return {
    organizationId: selectCurrentUserOrganizationId(state),
    propertyId: selectSelectedProperty(state),
    assignedUnits: selectors.getAssignedUnitsWithExpiringLeases(state),
    isFetching: state.manageLeaseExpirations.isFetching,
    selectedProperty: state.app.selectedProperty,
  };
}

function mapDispatchToProps(dispatch): ConnectedMethods {
  return {
    actions: bindActionCreators(
      {
        getExpiringLeases,
        selectLeaseForRenewal,
        cleanLoadedExpiringLeases,
        toastrFn: promptToaster,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ManageLeaseExpirations));
