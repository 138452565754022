import type { Action } from '../App/types';
import type { PrimaryLeaseApplicationState } from './types';
import * as ActionTypes from './constants';

const initialState: PrimaryLeaseApplicationState = {
  applicant: undefined,
  isSubmitting: false,
};

const primaryLeaseApplicationReducer = (
  state: PrimaryLeaseApplicationState = initialState,
  action: Action<*>,
): PrimaryLeaseApplicationState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_APPLICANT_PRIMARY_FORM_INFORMATION_SUCCESS: {
      return { ...state, applicant: payload };
    }
    case ActionTypes.GET_APPLICANT_PRIMARY_FORM_INFORMATION_ERROR: {
      return { ...state, applicant: initialState.applicant };
    }
    case ActionTypes.CLEAN_APPLICANT_PRIMARY_FORM_INFORMATION: {
      return { ...state, applicant: undefined };
    }
    case ActionTypes.SAVE_PRIMARY_FORM: {
      return { ...state, isSubmitting: true };
    }
    case ActionTypes.SAVE_PRIMARY_FORM_SUCCESS: {
      return { ...state, isSubmitting: false };
    }
    case ActionTypes.SAVE_PRIMARY_FORM_ERROR: {
      return { ...state, isSubmitting: false };
    }
    case ActionTypes.PRINT_APPLICATION_FORM: {
      return { ...state, isPrinting: true };
    }
    case ActionTypes.PRINT_APPLICATION_FORM_SUCCESS:
    case ActionTypes.PRINT_APPLICATION_FORM_ERROR: {
      return { ...state, isPrinting: false };
    }
    default:
      return state;
  }
};

export default primaryLeaseApplicationReducer;
