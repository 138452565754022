import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewUnit.Title',
    defaultMessage: 'Fortress - View Unit',
  },
  goBackManage: {
    id: 'App.ViewUnit.GoBackManage',
    defaultMessage: 'Go Back to Manage Rent Roll',
  },
  goBackAvailable: {
    id: 'App.ViewUnit.GoBackAvailable',
    defaultMessage: 'Go Back to Manage Unit Availability',
  },
  goBack: {
    id: 'App.ViewUnit.GoBack',
    defaultMessage: 'Go Back',
  },
  unit: {
    id: 'App.ViewUnit.Unit',
    defaultMessage: 'Unit',
  },
  sales: {
    id: 'App.ViewUnit.Sales',
    defaultMessage: 'Sales',
  },
  people: {
    id: 'App.ViewUnit.People',
    defaultMessage: 'People',
  },
  successHeader: {
    id: 'App.ViewUnit.SuccessHeader',
    defaultMessage: 'Success',
  },
  success: {
    id: 'App.ViewUnit.Success',
    defaultMessage: 'The Unit has been Updated.',
  },
  incomeAverageError: {
    id: 'App.ViewUnit.IncomeAverageFetchError',
    defaultMessage: 'Error loading Income Average data',
  },
  goBackManageCommercial: {
    id: 'App.ViewUnit.GoBackManageCommercial',
    defaultMessage: 'Go Back to Manage Commercial Rent Roll',
  },
  saveChanges: {
    id: 'App.ViewUnit.saveChanges',
    defaultMessage: 'Save Changes',
  },
  cancel: {
    id: 'App.ViewUnit.cancel',
    defaultMessage: 'Cancel',
  },
  internalName: {
    id: 'App.ViewUnit.internalName',
    defaultMessage: 'Internal Name',
  },
  marketingName: {
    id: 'App.ViewUnit.marketingName',
    defaultMessage: 'Marketing Name',
  },
  marketingDescription: {
    id: 'App.ViewUnit.marketingDescription',
    defaultMessage: 'Marketing Description',
  },
  bedrooms: {
    id: 'App.ViewUnit.bedrooms',
    defaultMessage: 'Bedroom(s)',
  },
  bathrooms: {
    id: 'App.ViewUnit.bathrooms',
    defaultMessage: 'Bathroom(s)',
  },
  halfBathrooms: {
    id: 'App.ViewUnit.halfBathrooms',
    defaultMessage: '1/2 Bathroom(s)',
  },
  minMaxOccupants: {
    id: 'App.ViewUnit.minMaxOccupants',
    defaultMessage: 'Min/Max Occupants',
  },
  yes: {
    id: 'App.ViewUnit.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.ViewUnit.no',
    defaultMessage: 'No',
  },
  cancelConfirmationHeader: {
    id: 'App.ViewUnit.cancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  required: {
    id: 'App.ViewUnit.required',
    defaultMessage: 'This field is required',
  },
  duplicateValueInAnotherFloorPlan: {
    id: 'App.ViewUnit.duplicateValueInAnotherFloorPlan',
    defaultMessage: 'This name is already reserved for another floor plan.',
  },
  maxOccupantsError: {
    id: 'App.ViewUnit.maxOccupantsError',
    defaultMessage:
      'Max Occupants has to be greater than or equal to Min Occupants.',
  },
  unitTypeLabel: {
    id: 'App.ViewUnit.unitTypeLabel',
    defaultMessage: 'Unit Type',
  },
  programTypeLabel: {
    id: 'App.ViewUnit.programTypeLabel',
    defaultMessage: 'Program Type',
  },
  setAsideLabel: {
    id: 'App.ViewUnit.setAsideLabel',
    defaultMessage: 'Set Aside',
  },
  homeUnitLabel: {
    id: 'App.ViewUnit.homeUnitLabel',
    defaultMessage: 'Home Unit type ',
  },
  homeTypeLabel: {
    id: 'App.ViewUnit.homeTypeLabel',
    defaultMessage: 'Home Type',
  },
  error: {
    id: 'App.ViewUnit.Error',
    defaultMessage: 'Error',
  },
});
export default messages;
