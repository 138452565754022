import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import Spinner from '../../../../components/Spinner';
import { filterCamPoolsInYear } from '../../../../utils/cam';

import { useFetchCamPools } from './hooks';
import messages from './messages';
import { AssignRetroactiveTransactionsModalProps } from './types';
import ReassignTransactionsForm from './form';

const AssignRetroactiveTransactionsModal = ({
  propertyId,
  organizationId,
  householdId,
  onHide,
  onSubmit,
  transactions,
  isLoading,
  intl,
}: AssignRetroactiveTransactionsModalProps) => {
  const [isLoadingCamPools, camPools] = useFetchCamPools({
    propertyId,
    organizationId,
    householdId,
  });

  const showIsLoading = isLoading || isLoadingCamPools;

  const currentYear = moment().year();
  const filteredCamPools = filterCamPoolsInYear(currentYear, camPools);

  return (
    <div className="static-modal">
      <Modal
        bsSize="large"
        dialogClassName="modal-dialog modal-alert"
        onHide={onHide}
        show={true}
        autoFocus={true}
        handleSubmit={onSubmit}
      >
        {showIsLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <>
            <Modal.Header>
              <Row>
                <Col xs={12}>
                  <h1 className="text-center">
                    <FormattedMessage {...messages.retroactiveModalTitle1} />
                  </h1>
                  <h1 className="text-center">
                    <FormattedMessage {...messages.retroactiveModalTitle2} />
                  </h1>

                  <h2 className="text-center">
                    <FormattedMessage {...messages.retroactiveModalSubtitle} />
                  </h2>
                </Col>
              </Row>
            </Modal.Header>

            <ReassignTransactionsForm
              intl={intl}
              onHide={onHide}
              onSubmit={onSubmit}
              transactions={transactions}
              camPools={filteredCamPools}
              footNote={messages.retroactiveFootNote}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default AssignRetroactiveTransactionsModal;
