import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ForecastRents.ForecastRentsHeader.Title',
    defaultMessage: 'Forecast Rents',
  },
  createRentForecast: {
    id: 'App.ForecastRents.ForecastRentsHeader.CreateRentForecast',
    defaultMessage: 'Create Rent Forecast',
  },
});

export default messages;
