import type { Action } from '../../App/types';
import {
  GET_ALL_FLOORPLAN_PRICING,
  GET_ALL_FLOORPLAN_PRICING_FAILURE,
  GET_ALL_FLOORPLAN_PRICING_SUCCESS,
  CREATE_FLOORPLAN_PRICING,
  CREATE_FLOORPLAN_PRICING_FAILURE,
  CREATE_FLOORPLAN_PRICING_SUCCESS,
  UPDATE_FLOORPLAN_PRICING,
  UPDATE_FLOORPLAN_PRICING_FAILURE,
  UPDATE_FLOORPLAN_PRICING_SUCCESS,
  DELETE_FLOORPLAN_PRICING,
  DELETE_FLOORPLAN_PRICING_FAILURE,
  DELETE_FLOORPLAN_PRICING_SUCCESS
} from './constants';

export function getAllPricings(payload: any): Action<any> {
  return {
    type: GET_ALL_FLOORPLAN_PRICING,
    payload
  };
}

export function getAllPricingsFailure(error: Error): Action<Error> {
  return {
    type: GET_ALL_FLOORPLAN_PRICING_FAILURE,
    payload: error
  };
}

export function getAllPricingsSuccess(pricings: Object): Action<Object> {
  return {
    type: GET_ALL_FLOORPLAN_PRICING_SUCCESS,
    payload: pricings
  };
}

export function createPricing(payload: any): Action<any> {
  return {
    type: CREATE_FLOORPLAN_PRICING,
    payload
  };
}

export function createPricingFailure(error: Error): Action<Error> {
  return {
    type: CREATE_FLOORPLAN_PRICING_FAILURE,
    payload: error
  };
}

export function createPricingSuccess(pricings: Object): Action<Object> {
  return {
    type: CREATE_FLOORPLAN_PRICING_SUCCESS,
    payload: pricings
  };
}

export function updatePricing(payload: any): Action<any> {
  return {
    type: UPDATE_FLOORPLAN_PRICING,
    payload
  };
}

export function updatePricingFailure(error: Error): Action<Error> {
  return {
    type: UPDATE_FLOORPLAN_PRICING_FAILURE,
    payload: error
  };
}

export function updatePricingSuccess(pricings: Object): Action<Object> {
  return {
    type: UPDATE_FLOORPLAN_PRICING_SUCCESS,
    payload: pricings
  };
}

export function deletePricing(payload: any): Action<any> {
  return {
    type: DELETE_FLOORPLAN_PRICING,
    payload
  };
}

export function deletePricingFailure(error: Error): Action<Error> {
  return {
    type: DELETE_FLOORPLAN_PRICING_FAILURE,
    payload: error
  };
}

export function deletePricingSuccess(pricings: Object): Action<Object> {
  return {
    type: DELETE_FLOORPLAN_PRICING_SUCCESS,
    payload: pricings
  };
}
