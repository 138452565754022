export const CREATE_WORK_ORDER = 'app/WorkOrder/CREATE_WORK_ORDER';
export const CREATE_WORK_ORDER_SUCCESS =
  'app/WorkOrder/CREATE_WORK_ORDER_SUCCESS';
export const CREATE_WORK_ORDER_ERROR = 'app/WorkOrder/CREATE_WORK_ORDER_ERROR';

export const GET_ALL_UNITS = 'app/WorkOrder/GET_ALL_UNITS';
export const GET_ALL_UNITS_SUCCESS = 'app/WorkOrder/GET_ALL_UNITS_SUCCESS';
export const GET_ALL_UNITS_ERROR = 'app/WorkOrder/GET_ALL_UNITS_ERROR';

export const GET_WORK_ORDER = 'app/WorkOrder/GET_WORK_ORDER';
export const GET_WORK_ORDER_SUCCESS = 'app/WorkOrder/GET_WORK_ORDER_SUCCESS';
export const GET_WORK_ORDER_ERROR = 'app/WorkOrder/GET_WORK_ORDER_ERROR';

export const CLEAR_WORK_ORDER_MESSAGES =
  'app/WorkOrder/CLEAR_WORK_ORDER_MESSAGES';

export const CLEAR_LOADED_WORK_ORDER = 'app/WorkOrder/CLEAR_LOADED_WORK_ORDER';

export const SAVE_WORK_ORDER = 'app/WorkOrder/SAVE_WORK_ORDER';
export const SAVE_WORK_ORDER_SUCCESS = 'app/WorkOrder/SAVE_WORK_ORDER_SUCCESS';
export const SAVE_WORK_ORDER_ERROR = 'app/WorkOrder/SAVE_WORK_ORDER_ERROR';

export const FORM_NAME = 'workOrderForm';
export const BACKGROUND_COLOR = { backgroundColor: '#FFF1DB' };
