import { useMemo } from 'react';
import { useQuery } from 'react-query';
import get from 'lodash/get';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import PropertyFeesService from '../../services/propertyFeesService';
import UnitFeesService from '../../services/unitFeesService';
import useUniqueTableName from '../../hooks/useUniqueTableName';

export function useTableData({ history }) {
  const name = useUniqueTableName('manage-amenities');
  const selectedProperty = useSelectedProperty();
  const { isLoading, data: amenityData } = useQuery(
    ['amenityData', selectedProperty?.id],
    async () => {
      const pfService = new PropertyFeesService();
      const ufService = new UnitFeesService();

      const amenities = await pfService.getAll({
        propertyId: selectedProperty?.id,
        feeType: 'Amenity',
        endDate: null,
      });

      if (amenities.length === 0) return { amenities: [], unitAmenityFees: [] };

      const amenityPtcId = get(amenities, '[0].propertyTransactionCodeId');

      const unitAmenityFees = await ufService.getAll({
        propertyTransactionCodeId: amenityPtcId,
        endDate: null,
      });

      return { amenities, unitAmenityFees };
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );
  const { rows, footerRow } = useMemo(() => {
    if (!amenityData) return [];

    const { amenities, unitAmenityFees } = amenityData;

    if (amenities.length === 0) return [];

    let totalNumOfUnits = 0;
    let totalValueXUnits = 0;

    const regularRows = amenities.map((amenity) => {
      const numOfUnits = unitAmenityFees.filter(
        (uaf) => uaf.propertyFeeId === amenity.id,
      ).length;
      const valueXUnits = amenity.feeAmount * numOfUnits;

      totalNumOfUnits += numOfUnits;
      totalValueXUnits += valueXUnits;

      return {
        amenity: {
          value: amenity.displayNameOnQuote,
        },
        value: {
          variant: 'currency',
          value: amenity.feeAmount,
        },
        numOfUnits: {
          variant: 'number',
          value: numOfUnits,
        },
        valueXUnits: {
          variant: 'currency',
          value: valueXUnits,
        },
        edit: {
          variant: 'iconButton',
          onClick: () =>
            history.push(
              `/property/${selectedProperty.id}/amenity/${amenity.id}`,
            ),
          iconName: 'PencilIcon',
        },
      };
    });
    const totalRow = {
      amenity: {
        value: ' ',
      },
      value: {
        variant: 'currency',
        value: ' ',
      },
      numOfUnits: {
        variant: 'number',
        value: totalNumOfUnits,
      },
      valueXUnits: {
        variant: 'currency',
        value: totalValueXUnits,
      },
      edit: {
        variant: 'iconButton',
        value: ' ',
      },
    };

    return {
      rows: regularRows,
      footerRow: totalRow,
    };
  }, [amenityData, history, selectedProperty.id]);

  return {
    name,
    isLoading,
    rows,
    footerRow,
    count: rows?.length ?? 0,
    totalCount: amenityData?.amenities?.length ?? 0,
  };
}
