import { Checkbox as BsCheckbox, InputGroup } from 'react-bootstrap';

type Props = {
  label?: string,
  checked: boolean,
  onChange: (boolean) => void,
};

function Checkbox({ label, checked, onChange }: Props) {
  const handleChange = () => {
    onChange?.(checked);
  };
  const labelId = `checkbox-label--${label}`;

  return (
    <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
      <BsCheckbox
        className="custom-checkbox padleft20"
        checked={checked}
        onChange={handleChange}
        aria-labelledby={labelId}
      >
        <span className="custom-check-square center-block" />
      </BsCheckbox>
      {label && <b id={labelId}>{label}</b>}
    </InputGroup>
  );
}

export default Checkbox;
