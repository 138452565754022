import React, { useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { partialRight } from 'ramda';
import {
  useUploadAttachment,
  useDownloadAttachment,
  useDeleteAttachment,
} from './hooks';
import { pathOr } from 'ramda';
import SubmitButton from '../../../../../components/SubmitButton';

type Props = {
  handleInputTextChange: Function,
  communicationBatchGenerated: boolean,
  selectedResidents: Array<Object>,
  isLoading: boolean,
  createdAt: Date,
  communicationData: Object,
  metricsInformation: Object,
  isViewCommunication: boolean,
  organizationId: string,
  selectedPropertyId: string,
  toasterFn: Function,
  handleCommunicationEmailAttachmentChanges: Function,
};

const EmailInput = (props: Props) => {
  const recipientsLength = props.selectedResidents.length;
  const outOfText =
    props.metricsInformation.successRecipients !== recipientsLength
      ? ` out of ${recipientsLength}`
      : '';
  const {
    organizationId,
    selectedPropertyId,
    communicationData,
    toasterFn,
    handleCommunicationEmailAttachmentChanges,
  } = props;
  const [showFileError, setShowFileError] = useState(false);
  const fileName = pathOr(
    '',
    ['emailAttachment', 'fileName'],
    communicationData,
  );
  const attachmentUrl = pathOr(
    '',
    ['emailAttachment', 'attachmentUrl'],
    communicationData,
  );
  const { setFile, isLoading } = useUploadAttachment({
    organizationId,
    selectedPropertyId,
    communicationData,
    handleCommunicationEmailAttachmentChanges,
    toasterFn,
  });

  const handleDownload = useDownloadAttachment({
    organizationId,
    selectedPropertyId,
    attachmentUrl,
    fileName,
    toasterFn,
  });

  const handleDeleteAttachment = useDeleteAttachment({
    communicationData,
    setFile,
    handleCommunicationEmailAttachmentChanges,
    toasterFn,
  });

  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleUpload = (event: any) => {
    const file = event.target.files[0];
    if (file && file.size > 25 * 1000 * 1000) {
      // 25mb
      setShowFileError(true);
    } else {
      setFile(event.target.files[0]);
      setShowFileError(false);
    }
  };

  return (
    <div className="panel unit-information panel-withheader">
      <div className="panel-head">
        <Row>
          <Col xs={12} sm={6}>
            <h2>Email</h2>
          </Col>
        </Row>
      </div>
      <div className="panel__details">
        <Row>
          <Col xs={6} sm={3} md={2} className="padtop20">
            <label>
              <strong>From Email</strong>
            </label>
          </Col>
          <Col xs={12} sm={6} md={4} className="padtop10 form-group">
            <input
              className="form-control input-lg"
              style={{
                border: props.communicationData.emailFromValidated
                  ? ''
                  : '1px solid #D70000',
              }}
              rows="6"
              onChange={partialRight(props.handleInputTextChange, [
                'emailFromText',
              ])}
              value={props.communicationData.emailFromText}
              disabled={
                props.isViewCommunication ||
                props.isLoading ||
                props.communicationBatchGenerated ||
                props.communicationData.emailFromOverridden
              }
            />
            {!props.communicationData.emailFromValidated && (
              <span className="has-error help-block">
                You must provide a valid email.
              </span>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={3} md={2} className="padtop20">
            <label>
              <strong>Email Subject</strong>
            </label>
          </Col>
          <Col xs={12} sm={6} md={4} className="padtop10 form-group">
            <input
              className="form-control input-lg"
              rows="6"
              onChange={partialRight(props.handleInputTextChange, [
                'emailSubjectText',
                true,
              ])}
              value={props.communicationData.emailSubjectText}
              disabled={
                props.isViewCommunication ||
                props.isLoading ||
                props.communicationBatchGenerated
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={3} md={2} className="padtop20">
            <label>
              <strong>Title</strong>
            </label>
          </Col>
          <Col xs={12} sm={6} md={4} className="padtop10 form-group">
            <input
              className="form-control input-lg"
              rows="6"
              onChange={partialRight(props.handleInputTextChange, [
                'emailTitleText',
              ])}
              value={props.communicationData.emailTitleText}
              disabled={
                props.isViewCommunication ||
                props.isLoading ||
                props.communicationBatchGenerated
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={3} md={2} className="padtop20">
            <label>
              <strong>Message Body</strong>
            </label>
          </Col>
          <Col xs={12} sm={6} md={8} className="padtop10  form-group">
            <textarea
              className="form-control input-lg"
              rows="6"
              onChange={partialRight(props.handleInputTextChange, [
                'emailBodyText',
              ])}
              value={props.communicationData.emailBodyText}
              disabled={
                props.isViewCommunication ||
                props.isLoading ||
                props.communicationBatchGenerated
              }
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={3} md={2} className="padtop20">
            <label>
              <strong>Attachment</strong>
            </label>
          </Col>
          {fileName || props.isViewCommunication ? (
            <Col xs={12} sm={9} md={10} className="padbottom30 form-group ">
              <Row className={'padtop15 padleft5'}>
                <label>{fileName}</label>
                {'  '}
                {fileName ? (
                  <span>
                    <a
                      onClick={() => {
                        handleDownload();
                      }}
                    >
                      <i className=" et-cloud-download" />
                    </a>
                    {'  '}
                    {!props.isViewCommunication && (
                      <a
                        onClick={() => {
                          handleDeleteAttachment(fileName);
                        }}
                      >
                        <i className="icon et-trash" />
                      </a>
                    )}
                  </span>
                ) : (
                  <label className="padbottom5"> No Attachment </label>
                )}
              </Row>
            </Col>
          ) : (
            <Col xs={12} sm={9} md={10} className="padbottom30 form-group ">
              <input
                className="inputfile inputfile-custom"
                ref={inputFile}
                type="file"
                name="file"
                onChange={handleUpload}
              />
              <SubmitButton
                classes="btn btn-tertiary"
                clickHandler={onButtonClick}
                isSubmitting={isLoading}
              >
                Upload Documents
              </SubmitButton>
              {'  '}
              {showFileError && (
                <span className="has-error">File maximum size is 25 MB</span>
              )}
            </Col>
          )}
        </Row>
        {(props.communicationBatchGenerated || props.isViewCommunication) && (
          <Row>
            <Col md={12}>
              <div className="alert alert-success">
                {`Email has been sent to ${
                  props.metricsInformation.successRecipients
                }${outOfText} recipients on ${moment(props.createdAt).format(
                  'dddd, MMM D, YYYY, LT',
                )} GMT-5`}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default EmailInput;
