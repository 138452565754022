import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { isNil } from 'ramda';
import styled from 'styled-components';

import { Modal } from 'react-bootstrap';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import { enablePortal, disablePortal, onboardPortal } from './actions';
import {
  useAsyncOnboardingReport,
  useAsyncOnboardingReportDownload,
} from './hooks';
import messages from './messages';

import PortalSettingsForm from './PropertyPortalForms/PortalSettingsForm';
import { PORTAL_STATUS } from '../constants';
import ConfirmOverlay from '../../../components/ConfirmOverlay';

import type { FormValues, PortalSettings } from './types';

type ConnectedProps = {
  anyTouched: boolean,
  invalid: boolean,
};

type ConnectedMethods = {
  actions: {
    submit: Function,
    enablePortal: Function,
    disablePortal: Function,
    onboardPortal: Function,
  },
};

type Props = ConnectedProps &
  ConnectedMethods & {
    onHide: Function,
    property: Object,
    show: boolean,
    updateProperties: Function,
  };

const StyledModalContainer = styled.div`
  .modal.confirm,
  .modal.confirm-backdrop {
    z-index: 1050;
  }
`;

const PropertyPortalModal = ({
  actions,
  anyTouched,
  invalid,
  onHide,
  property,
  show,
  updateProperties,
}: Props) => {
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [downloadOnboarding, setDownloadOnboarding] = useState(false);

  const hideCancelConfirmation = () => setShowCancelConfirmation(false);
  const downloadOnboardingCsv = () => setDownloadOnboarding(true);

  const [onboardingReport, resetOnboardingReport] =
    useAsyncOnboardingReport(property);
  useAsyncOnboardingReportDownload(
    property,
    downloadOnboarding,
    setDownloadOnboarding,
  );

  const handleHide = () => {
    if (isDirty && !showCancelConfirmation) {
      setShowCancelConfirmation(true);
      return;
    }
    setShowCancelConfirmation(false);
    resetOnboardingReport();
    onHide?.();
  };

  const handleValidationChange = (isValid: boolean, isDirty: boolean) => {
    setIsValid(isValid);
    setIsDirty(isDirty);
  };

  const initialValues: FormValues = {
    isResidentPortalActive: property.isResidentPortalActive,
    sendRegistrationEmails: !!property.portalLatestOnboardingGroupId,
    isElectronicLeaseSigningActive: property.isElectronicLeaseSigningActive,
    isUnitSelectionEnabled: property?.config?.isUnitSelectionEnabled ?? false,
    applyWithoutUnit: property?.config?.applyWithoutUnit ?? null,
  };

  const handleEnablePortal = (
    sendEmails: boolean = false,
    settings: PortalSettings = {
      isElectronicLeaseSigningActive: true,
      isUnitSelectionEnabled: false,
      applyWithoutUnit: null,
    },
  ) => {
    const { enablePortal } = actions;
    enablePortal(
      property.organizationId,
      property.id,
      updateProperties,
      sendEmails,
      settings,
    );
  };

  const onSubmit = (values: FormValues) => {
    const {
      isResidentPortalActive,
      isElectronicLeaseSigningActive,
      sendRegistrationEmails,
      isUnitSelectionEnabled,
      applyWithoutUnit,
    } = values;
    const { id, organizationId, portalStatus, portalLatestOnboardingById } =
      property;
    const { disablePortal, onboardPortal } = actions;

    const isActiveStatus = [
      PORTAL_STATUS.ACTIVE_AND_ONBOARDED,
      PORTAL_STATUS.ACTIVE_NEVER_ONBOARDED,
    ].includes(portalStatus);

    let sendEmails;
    switch (portalStatus) {
      case PORTAL_STATUS.NEVER_ACTIVE: {
        sendEmails = sendRegistrationEmails;
        break;
      }
      case PORTAL_STATUS.DEACTIVATED_NEVER_ONBOARDED: {
        sendEmails =
          sendRegistrationEmails && isNil(portalLatestOnboardingById);
        break;
      }
      default:
        sendEmails = false;
    }

    if (isResidentPortalActive) {
      const settings: PortalSettings = {
        isElectronicLeaseSigningActive,
        isUnitSelectionEnabled,
        applyWithoutUnit,
      };
      handleEnablePortal(sendEmails, settings);
    }

    if (!isResidentPortalActive && isActiveStatus) {
      disablePortal(organizationId, id, updateProperties);
    }

    if (
      sendRegistrationEmails &&
      portalStatus === PORTAL_STATUS.ACTIVE_NEVER_ONBOARDED &&
      isNil(portalLatestOnboardingById)
    ) {
      onboardPortal(organizationId, id, updateProperties);
    }

    onHide();
    resetOnboardingReport();
  };

  return (
    <StyledModalContainer>
      <Modal
        show={show}
        onHide={handleHide}
        bsSize="large"
        autoFocus
        backdrop="static"
      >
        <Modal.Header closeButton>
          <i className="icon et-phone-alert" />
          <Typography variant="h3" component="span">
            <FormattedMessage {...messages.header} />
          </Typography>
        </Modal.Header>
        <Modal.Body>
          {showCancelConfirmation && (
            <ConfirmOverlay
              backdrop
              title={<FormattedMessage {...messages.confirmationTitle} />}
              rejectMessage={
                <FormattedMessage {...messages.confirmationCancel} />
              }
              confirmMessage={<FormattedMessage {...messages.confirm} />}
              onReject={hideCancelConfirmation}
              onConfirm={handleHide}
            />
          )}
          <PortalSettingsForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            property={property}
            onboardingReport={onboardingReport}
            downloadOnboardingCsv={downloadOnboardingCsv}
            onValidationChange={handleValidationChange}
            className="portal-settings-form"
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-xs-6">
              <button
                className="btn btn-default pull-right"
                onClick={handleHide}
                disabled={showCancelConfirmation}
              >
                <FormattedMessage {...messages.cancel} />
              </button>
            </div>
            <div className="col-xs-6">
              <button
                className="btn btn-primary pull-left"
                disabled={showCancelConfirmation || !(isValid && isDirty)}
                type="submit"
                form="portal-settings-form"
              >
                <FormattedMessage {...messages.save} />
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </StyledModalContainer>
  );
};

const mapDispatchToProps = (dispatch: Object): ConnectedMethods => ({
  actions: bindActionCreators(
    { submit, enablePortal, disablePortal, onboardPortal },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(PropertyPortalModal);
