import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export const AMENITY_DETAILS_PREFIX = 'amenityFee__';
export const AMENITY_DETAILS_ID = 'amenityDetails';
export const DATE_INPUT_FORMAT = 'YYYY-MM-DD';
export const DATE_OUTPUT_FORMAT = 'MM/DD/YYYY';

export const RENT_ROLL_TOTAL_COLUMNS = [
  'sqFt',
  'marketRent',
  'netMaxRent',
  'priorLeasedRent',
  'leasedRent',
  'residentRent',
  'chargedUnitFees',
  'subsidyRent',
  'balance',
];

export const RENT_ROLL_TABLE_HEADERS = [
  { id: 'unit', title: <FormattedMessage {...messages.unit} />, type: '' },
  {
    id: 'floorPlan',
    title: <FormattedMessage {...messages.floorPlan} />,
    type: '',
  },
  {
    id: 'bedsBaths',
    title: <FormattedMessage {...messages.bedsBaths} />,
    type: '',
  },
  {
    id: 'sqFt',
    title: <FormattedMessage {...messages.sqFeet} />,
    type: '',
  },
  { id: 'status', title: <FormattedMessage {...messages.status} />, type: '' },
  {
    id: 'resident',
    title: <FormattedMessage {...messages.resident} />,
    type: '',
  },
  {
    id: 'moveInDate',
    title: <FormattedMessage {...messages.moveInDate} />,
    type: 'date',
  },
  {
    id: 'leaseStartDate',
    title: <FormattedMessage {...messages.leaseStartDate} />,
    type: 'date',
  },
  {
    id: 'leaseEndDate',
    title: <FormattedMessage {...messages.leaseEndDate} />,
    type: 'date',
  },
  {
    id: 'collectionNote',
    title: <FormattedMessage {...messages.collectionsNotes} />,
    classes: 'table-col-width210',
    type: 'string',
  },
  {
    id: 'underEviction',
    title: <FormattedMessage {...messages.underEviction} />,
    type: 'boolean',
  },
  {
    id: 'baseFloorPlanMarketRent',
    title: <FormattedMessage {...messages.baseFloorPlanMarketRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'amenityFees',
    title: <FormattedMessage {...messages.amenityFees} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'marketRent',
    title: <FormattedMessage {...messages.gprMarketRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'netMaxRent',
    title: <FormattedMessage {...messages.netMaxRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'priorLeasedRent',
    title: <FormattedMessage {...messages.priorLeasedRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'leasedRent',
    title: <FormattedMessage {...messages.currentLeasedRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'residentRent',
    title: <FormattedMessage {...messages.residentChargedRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'subsidyRent',
    title: <FormattedMessage {...messages.subsidyRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'chargedUnitFees',
    title: <FormattedMessage {...messages.chargedUnitFees} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'balance',
    title: <FormattedMessage {...messages.balance} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'moveOutDate',
    title: <FormattedMessage {...messages.moveOutDate} />,
    classes: 'header-darker',
    type: 'date',
  },
  {
    id: 'moveInReady',
    title: <FormattedMessage {...messages.moveInReady} />,
    classes: 'header-darker',
    type: 'dateOrString',
  },
  {
    id: 'daysVacant',
    title: <FormattedMessage {...messages.daysVacant} />,
    classes: 'header-darker',
    type: 'numberZeroNull',
  },
  {
    id: 'quotingRent',
    title: <FormattedMessage {...messages.quotingRent} />,
    classes: 'header-darker',
    type: 'moneyZeroNull',
  },
  {
    id: 'applicant',
    title: <FormattedMessage {...messages.applicant} />,
    classes: 'header-darker',
    type: '',
  },
  {
    id: 'applicantStatus',
    title: <FormattedMessage {...messages.applicantStatus} />,
    classes: 'header-darker',
    type: '',
  },
  {
    id: 'scheduledMoveInDate',
    title: <FormattedMessage {...messages.scheduledMoveInDate} />,
    classes: 'header-darker',
    type: 'date',
  },
];

export const RENT_ROLL_TABLE_HEADERS_AFFORDABLE = [
  { id: 'unit', title: <FormattedMessage {...messages.unit} />, type: '' },
  {
    id: 'floorPlan',
    title: <FormattedMessage {...messages.floorPlan} />,
    type: '',
  },
  {
    id: 'bedsBaths',
    title: <FormattedMessage {...messages.bedsBaths} />,
    type: '',
  },
  {
    id: 'sqFt',
    title: <FormattedMessage {...messages.sqFeet} />,
    type: '',
  },
  { id: 'status', title: <FormattedMessage {...messages.status} />, type: '' },
  {
    id: 'resident',
    title: <FormattedMessage {...messages.resident} />,
    type: '',
  },
  {
    id: 'setAside',
    title: <FormattedMessage {...messages.setAside} />,
    type: '',
  },
  {
    id: 'moveInDate',
    title: <FormattedMessage {...messages.moveInDate} />,
    type: 'date',
  },
  {
    id: 'leaseStartDate',
    title: <FormattedMessage {...messages.leaseStartDate} />,
    type: 'date',
  },
  {
    id: 'leaseEndDate',
    title: <FormattedMessage {...messages.leaseEndDate} />,
    type: 'date',
  },
  {
    id: 'collectionNote',
    title: <FormattedMessage {...messages.collectionsNotes} />,
    classes: 'table-col-width210',
    type: 'string',
  },
  {
    id: 'underEviction',
    title: <FormattedMessage {...messages.underEviction} />,
    type: 'boolean',
  },
  {
    id: 'baseFloorPlanMarketRent',
    title: <FormattedMessage {...messages.baseFloorPlanMarketRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'amenityFees',
    title: <FormattedMessage {...messages.amenityFees} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'marketRent',
    title: <FormattedMessage {...messages.gprMarketRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'netMaxRent',
    title: <FormattedMessage {...messages.netMaxRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'priorLeasedRent',
    title: <FormattedMessage {...messages.priorLeasedRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'leasedRent',
    title: <FormattedMessage {...messages.currentLeasedRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'nonOptionalCharge',
    title: <FormattedMessage {...messages.nonOptionalCharge} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'utilityAllowances',
    title: <FormattedMessage {...messages.utilityAllowance} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'grossRentLimit',
    title: <FormattedMessage {...messages.grossRentLimit} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'residentRent',
    title: <FormattedMessage {...messages.residentChargedRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'subsidyRent',
    title: <FormattedMessage {...messages.subsidyRent} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'chargedUnitFees',
    title: <FormattedMessage {...messages.chargedUnitFees} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'balance',
    title: <FormattedMessage {...messages.balance} />,
    type: 'moneyZeroNull',
  },
  {
    id: 'moveOutDate',
    title: <FormattedMessage {...messages.moveOutDate} />,
    classes: 'header-darker',
    type: 'date',
  },
  {
    id: 'moveInReady',
    title: <FormattedMessage {...messages.moveInReady} />,
    classes: 'header-darker',
    type: 'dateOrString',
  },
  {
    id: 'daysVacant',
    title: <FormattedMessage {...messages.daysVacant} />,
    classes: 'header-darker',
    type: 'numberZeroNull',
  },
  {
    id: 'quotingRent',
    title: <FormattedMessage {...messages.quotingRent} />,
    classes: 'header-darker',
    type: 'moneyZeroNull',
  },
  {
    id: 'applicant',
    title: <FormattedMessage {...messages.applicant} />,
    classes: 'header-darker',
    type: '',
  },
  {
    id: 'applicantStatus',
    title: <FormattedMessage {...messages.applicantStatus} />,
    classes: 'header-darker',
    type: '',
  },
  {
    id: 'scheduledMoveInDate',
    title: <FormattedMessage {...messages.scheduledMoveInDate} />,
    classes: 'header-darker',
    type: 'date',
  },
];

const BASE_SHOW_HIDE_HEADERS = [
  {
    id: 'grossRentLimit',
    text: 'Gross Rent Limit',
    propertyType: 'affordable',
    selected: false,
  },
  {
    id: 'nonOptionalCharge',
    text: 'Non Optional Charge',
    propertyType: 'affordable',
    selected: false,
  },
  {
    id: 'setAside',
    text: 'Set Aside',
    propertyType: 'affordable',
    selected: false,
  },
  {
    id: 'utilityAllowances',
    text: messages.utilityAllowance.defaultMessage,
    propertyType: 'affordable',
    selected: false,
  },
  {
    id: 'baseFloorPlanMarketRent',
    text: 'Base Floor Plan Market Rent',
    selected: false,
  },
  {
    id: 'amenityFees',
    text: 'Total Unit Amenities',
    selected: false,
  },
  {
    id: AMENITY_DETAILS_ID,
    text: 'Unit Amenities Details',
    selected: false,
  },
  {
    id: 'otherHouseholdMembers',
    text: 'Other Household Members',
    selected: false,
  },
];

export const generateShowHideColumnList = ({
  storedShowHideColumns,
  configuredShowHideColumns,
}) => {
  const list = configuredShowHideColumns
    ? configuredShowHideColumns
    : [...BASE_SHOW_HIDE_HEADERS];

  if (storedShowHideColumns && storedShowHideColumns.length)
    return list.map((header) => {
      const matchingHeader = storedShowHideColumns.find(
        (c) => c.id === header.id,
      );

      if (!matchingHeader) return header;

      return {
        ...header,
        selected: matchingHeader.selected,
      };
    });

  return list;
};

export const EXCLUDED_FOR_COMMERCIAL_COLUMNS = ['otherHouseholdMembers'];

export const CommercialFloorPlans = {
  All: 'ALL',
  Some: 'SOME',
  None: 'NONE',
};
