import React, { SyntheticEvent, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'ramda';
import moment from 'moment';
import styled from 'styled-components';

import {
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  Banner,
  Checkbox,
  Grid,
  RadioGroup,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';

import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

import { PORTAL_STATUS } from '../../constants';

import { FormSchema } from './PortalSettingsFormSchema';

import componentMessages from './messages';
import generalMessages from '../../../App/messages';

import type { FormValues } from '../types';

const messages = { ...generalMessages, ...componentMessages };

type OnboardingReport = {
  success?: number,
  skipped?: number,
  errors?: number,
};

type Props = {
  initialValues: FormValues,
  onSubmit: Function,
  downloadOnboardingCsv?: Function,
  onboardingReport?: OnboardingReport,
  property: Object,
  intl: Object,
  isLoadingOnboardingReport?: boolean,
  onValidationChange: Function,
  ref: any,
};

const StyledForm = styled.form`
  .history-banner,
  .banner {
    p {
      margin: 0;
    }
  }
  .history-banner {
    p {
      padding: 4px 10px;
    }
  }
  .MuiCheckbox-root {
    padding: 0 9px;
  }
`;

const PortalSettingsForm = ({
  initialValues,
  onSubmit,
  property,
  onboardingReport,
  downloadOnboardingCsv,
  isLoadingOnboardingReport,
  intl,
  onValidationChange,
  ref,
}: Props) => {
  const { assigningUnitsAtApplication, propertySettingApplyWithoutAUnit } =
    useFlags();
  const { portalStatus } = property;
  const propertyClass = property?.propertyClass?.name;
  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid, isDirty },
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: { ...initialValues, propertySettingApplyWithoutAUnit },
    resolver: joiResolver(FormSchema),
  });

  const watchSendRegistrationEmails = watch('sendRegistrationEmails', false);
  const watchIsResidentPortalActive = watch('isResidentPortalActive', false);
  const watchIsUnitSelectionEnabled = watch('isUnitSelectionEnabled', false);

  const isActive = [
    PORTAL_STATUS.ACTIVE_AND_ONBOARDED,
    PORTAL_STATUS.ACTIVE_NEVER_ONBOARDED,
  ].includes(portalStatus);

  const wasNeverActive = portalStatus === PORTAL_STATUS.NEVER_ACTIVE;
  const isConventional = propertyClass === 'Conventional';
  const hasSomeOrNoCommercialFloorPlans = ['SOME', 'NONE'].includes(
    property?.hasCommercialFloorPlans,
  );

  const {
    portalUpdatedAt,
    portalUpdatedBy,
    portalLatestOnboardingAt,
    portalLatestOnboardingBy,
  } = property ?? {};

  const portalUpdatedByUser = `${portalUpdatedBy?.firstName ?? ''} ${
    portalUpdatedBy?.lastName ?? ''
  }`;
  const portalUpdatedAtFormatted = !isNil(portalUpdatedAt)
    ? moment.utc(portalUpdatedAt).format('MM/DD/YYYY')
    : undefined;

  const portalLatestOnboardingByUser = `${
    portalLatestOnboardingBy?.firstName ?? ''
  } ${portalLatestOnboardingBy?.lastName ?? ''}`;
  const portalLatestOnboardingFormatted = !isNil(portalLatestOnboardingAt)
    ? moment.utc(portalLatestOnboardingAt).format('MM/DD/YYYY')
    : undefined;

  const portalActiveHistoryLabel = intl.formatMessage(
    messages.portalActiveHistoryLabel,
    {
      date: portalUpdatedAtFormatted,
      user: portalUpdatedByUser,
    },
  );

  const lastOnboardedHistoryLabel = intl.formatMessage(
    messages.initialRegistrationHistoryLabel,
    {
      date: portalLatestOnboardingFormatted,
      user: portalLatestOnboardingByUser,
    },
  );

  const { skipped = 0, success = 0, error = 0 } = onboardingReport ?? {};

  const onFormKeyDown = (event: SyntheticEvent) => {
    const keyCode = event.keyCode ? event.keyCode : event.which;
    if (keyCode === 13) {
      event.preventDefault();
    }
  };

  const onDowloadOnboardingReport = () => {
    downloadOnboardingCsv?.();
  };

  useEffect(() => {
    onValidationChange(isValid, isDirty);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, isDirty]);

  const portalNoteKey = wasNeverActive
    ? 'portalNeverActiveNote'
    : isActive
    ? 'portalActiveNote'
    : 'portalInactiveNote';
  const portalNote = intl.formatMessage(messages[portalNoteKey]);

  const onUnitSelectionChange = (value: boolean, onChange: Function) => {
    if (!value && propertySettingApplyWithoutAUnit) {
      setValue('applyWithoutUnit', null, {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    onChange(value);
  };

  return (
    <StyledForm
      id="portal-settings-form"
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={onFormKeyDown}
      className="portal-settings-form"
      ref={ref}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="h3" align="center">
            {property.name}
          </Typography>
        </Grid>
        <Grid item xs={12} className="banner">
          <Banner
            id="portalNote"
            key="portalNote"
            color="purple"
            text={portalNote}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs="auto">
              <Typography
                variant="formSubheading"
                component={'span'}
                style={{
                  paddingRight: '8px',
                }}
              >
                <FormattedMessage {...messages.portalActive} />
              </Typography>
              <Controller
                name="isResidentPortalActive"
                control={control}
                render={({
                  field: { value, onChange, onBlur },
                  fieldState: { invalid, error },
                }) => (
                  <Switch
                    name="isResidentPortalActive"
                    checked={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onLabel={intl.formatMessage(messages.yes)}
                    offLabel={intl.formatMessage(messages.no)}
                  />
                )}
              />
            </Grid>
            {!isNil(portalUpdatedAt) && (
              <Grid item xs className="history-banner">
                <Banner
                  id="portalHistory"
                  key="portalHistory"
                  text={portalActiveHistoryLabel}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="sendRegistrationEmails"
            control={control}
            render={({
              field: { value, onChange, onBlur },
              fieldState: { invalid, error },
            }) => (
              <Checkbox
                name="sendRegistrationEmails"
                disabled={!watchIsResidentPortalActive || value}
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
                label={intl.formatMessage(
                  messages.sendInitialRegistrationEmails,
                )}
              />
            )}
          />
          {!isNil(portalLatestOnboardingAt) && (
            <Grid item xs className="history-banner">
              <Banner
                id="onboardingHistory"
                key="onboardingHistory"
                text={lastOnboardedHistoryLabel}
              />
            </Grid>
          )}
        </Grid>
        {watchSendRegistrationEmails && !isNil(onboardingReport) && (
          <Grid item xs={12}>
            <>
              <IconButton
                variant="primarySubtle"
                onClick={onDowloadOnboardingReport}
                size="small"
                sx={{
                  marginLeft: '40px',
                }}
              >
                <i className="icon et-cloud-download" />
              </IconButton>
              <Typography
                variant="inputLabel"
                style={{
                  marginLeft: '10px',
                  marginRight: '32px',
                }}
              >
                <FormattedMessage {...messages.onboardedLeaseholdersReport} />
              </Typography>
              <Typography
                id="successReports"
                key="successReports"
                variant="inputLabel"
                component="span"
                style={{
                  marginRight: '16px',
                }}
              >
                {`${intl.formatMessage(messages.success)}: ${success}`}
              </Typography>
              <Typography
                id="skippedReports"
                key="skippedReports"
                variant="inputLabel"
                component="span"
                style={{
                  marginRight: '16px',
                }}
              >
                {`${intl.formatMessage(messages.skipped)}: ${skipped}`}
              </Typography>
              <Typography
                id="errorReports"
                key="errorReports"
                variant="inputLabel"
                component="span"
                style={{
                  marginRight: '16px',
                }}
              >
                {`${intl.formatMessage(messages.error)}: ${error}`}
              </Typography>
            </>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={!watchSendRegistrationEmails ? { paddingTop: '0' } : {}}
        >
          <Typography variant="tableText">
            <FormattedMessage {...messages.sendInitialRegistrationEmailsNote} />
          </Typography>
        </Grid>
        {watchIsResidentPortalActive && (
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs="auto">
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs="auto">
                    <Typography variant="formSubheading" component={'span'}>
                      <FormattedMessage {...messages.electronicLeaseSigning} />
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs="auto"
                    style={{
                      paddingRight: '8px',
                    }}
                  >
                    <Tooltip
                      variant="light"
                      title={intl.formatMessage(
                        messages.electronicLeaseSigningNote,
                      )}
                    >
                      <AlertInfoIcon />
                    </Tooltip>
                  </Grid>
                  <Grid item xs="auto">
                    <Controller
                      name="isElectronicLeaseSigningActive"
                      control={control}
                      render={({
                        field: { value, onChange, onBlur },
                        fieldState: { invalid, error },
                      }) => (
                        <Switch
                          name="isElectronicLeaseSigningActive"
                          checked={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          onLabel={intl.formatMessage(messages.on)}
                          offLabel={intl.formatMessage(messages.off)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {watchIsResidentPortalActive &&
          assigningUnitsAtApplication &&
          isConventional &&
          hasSomeOrNoCommercialFloorPlans && (
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={4}
              >
                <Grid item xs="auto">
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs="auto">
                      <Typography variant="formSubheading" component={'span'}>
                        <FormattedMessage {...messages.unitSelection} />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs="auto"
                      style={{
                        paddingRight: '8px',
                      }}
                    >
                      <Tooltip
                        variant="light"
                        title={intl.formatMessage(
                          messages.unitSelectionTooltip,
                        )}
                      >
                        <AlertInfoIcon />
                      </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                      <Controller
                        name="isUnitSelectionEnabled"
                        control={control}
                        render={({
                          field: { value, onChange, onBlur },
                          fieldState: { invalid, error },
                        }) => (
                          <Switch
                            name="isUnitSelectionEnabled"
                            checked={value}
                            onChange={(
                              event: SyntheticEvent,
                              value: boolean,
                            ) => {
                              onUnitSelectionChange(value, onChange);
                            }}
                            onBlur={onBlur}
                            onLabel={intl.formatMessage(messages.on)}
                            offLabel={intl.formatMessage(messages.off)}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {watchIsUnitSelectionEnabled &&
                  propertySettingApplyWithoutAUnit && (
                    <Grid item xs="auto">
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item xs="auto">
                          <Typography
                            variant="formSubheading"
                            component={'span'}
                          >
                            <FormattedMessage {...messages.applyWithoutUnit} />
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs="auto"
                          style={{
                            paddingRight: '8px',
                          }}
                        >
                          <Tooltip
                            variant="light"
                            title={
                              <React.Fragment>
                                <Typography variant="body1">
                                  <FormattedMessage
                                    {...messages.applyWithoutUnitTooltip}
                                    values={{
                                      br: <br />,
                                      will: (
                                        <b>
                                          {intl.formatMessage(messages.will)}
                                        </b>
                                      ),
                                      willNot: (
                                        <b>
                                          {intl.formatMessage(messages.willNot)}
                                        </b>
                                      ),
                                    }}
                                  />
                                </Typography>
                              </React.Fragment>
                            }
                          >
                            <AlertInfoIcon />
                          </Tooltip>
                        </Grid>
                        <Grid item xs="auto">
                          <Controller
                            name="applyWithoutUnit"
                            control={control}
                            render={({
                              field: { value, onChange, onBlur },
                              fieldState: { invalid, error },
                            }) => (
                              <ApplyWithoutUnitRadioGroup
                                intl={intl}
                                onBlur={onBlur}
                                onChange={onChange}
                                value={value}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          )}
        <Grid item xs={12}>
          <Typography variant="body2">
            <FormattedMessage
              {...messages.noteCustom}
              values={{
                note: '',
              }}
              component="span"
            />
            <Typography variant="body1" component="span">
              <FormattedMessage {...messages.portalSettingsNote} />
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </StyledForm>
  );
};

export const ApplyWithoutUnitRadioGroup = ({
  intl,
  value,
  onChange,
  onBlur,
}) => (
  <RadioGroup
    row
    items={[
      {
        label: intl.formatMessage(messages.yes),
        value: true,
      },
      {
        label: intl.formatMessage(messages.no),
        value: false,
      },
    ]}
    value={value}
    onChange={(e, value) => {
      onChange(value === 'true' || value === true);
    }}
    onBlur={onBlur}
  />
);

export default injectIntl(PortalSettingsForm);
