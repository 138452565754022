import React, { Fragment } from 'react';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import DoughnutGraph from '../../../components/Graphs/DoughnutGraph';
import { OPTIONS } from '../constants';
import KPICardContainer from '../../../components/KPICardContainer';

const DoughnutCard = ({
  data,
  labels,
  header,
  dataLoaded,
  showCenterText,
  placeholder,
  children,
  fetching,
  selectedOptionNumber,
  changeSelectedRange,
  getElementAtEvent,
}: Object) => {
  const usePlaceholder = data.every((value) => value === 0) && dataLoaded;
  return (
    <KPICardContainer
      title={changeSelectedRange ? 'Net Occupancy' : header}
      action={
        changeSelectedRange ? (
          <Box maxWidth={100}>
            <select
              className="input-sm form-control"
              value={OPTIONS[selectedOptionNumber].value}
              onChange={changeSelectedRange}
            >
              {OPTIONS.map(({ value, text }) => (
                <option key={value} value={value}>
                  {text}
                </option>
              ))}
            </select>
          </Box>
        ) : null
      }
    >
      <AsyncBox loading={!dataLoaded} style={{ height: '100%' }}>
        {usePlaceholder ? (
          <Typography height={'100%'} align={'center'}>
            {placeholder}
          </Typography>
        ) : (
          <>
            <DoughnutGraph
              labels={labels}
              data={data}
              showCenterText={fetching || !dataLoaded ? false : showCenterText}
              getElementAtEvent={getElementAtEvent}
            />
            {children}
          </>
        )}
      </AsyncBox>
    </KPICardContainer>
  );
};

export default DoughnutCard;
