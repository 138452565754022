import React, { Component } from 'react';
import {
  Button,
  Stack,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Modal, Row, Col } from 'react-bootstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { pathOr, props, isEmpty, isNil } from 'ramda';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import validate from './validate';
import moment from 'moment';

import messages from './messages';
import {
  renderDateField,
  extractCurrentDateFormat,
  renderCurrencyField,
  renderCheckboxField,
  renderTextField,
  renderSelectField,
} from '../../../../../../utils/redux-form-helper';
import { AddEditChargeModal } from '../AddEditChargeModal';

import {
  getTransactionTypes,
  getPropertySubjournalTypes,
  getPropertyTransactionCodes,
} from '../../../../../../components/CreateTransaction/actions';
import { ERROR_MESSAGES, MTOM_CODE_TEXT } from './constants';

type OptionType = { value: ?string, text: string };

type DefaultProps = {
  defaultOption: OptionType,
};

type Props = DefaultProps & {
  show: boolean,
  close: Function,
  intl: any,
  property: Object,
  headerData: Object,
  resetSection: Function,
  defaultOption: OptionType,
  transactionCodes: Array<Object>,
  transactionTypes: Array<Object>,
  subjournals: Array<Object>,
  noEndDate: boolean,
  valid: boolean,
  currenForm: Object,
  handleSubmit: Function,
  onSubmit: Function,
  subjournal: any,
  edit: boolean,
  transactionTypeId: string,
  lease: Object,
  frNames: string[],
  auditData: any,
};

type State = {
  amount: number,
  transactionCodeId: string,
  subjournal: Object,
  showCancelConfirmation: boolean,
  showConfirmAddEdit: boolean,
};
class MonthlyTransactionModal extends Component<Props, State> {
  static defaultProps = {
    defaultOption: { value: 'default', text: 'Choose', disabled: true },
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      amount: 0,
      transactionCodeId: 'default',
      subjournal: this.props.subjournal || { masterSubjournalId: 'default' },
      showCancelConfirmation: false,
      showConfirmAddEdit: false,
    };
  }

  componentDidMount() {
    const { organizationId, property } = this.props;
    this.props.actions.getTransactionTypes();
    this.props.actions.getPropertySubjournalTypes();
    this.props.actions.getPropertyTransactionCodes(organizationId, property);
  }

  handleTransactionTypeChange = (evt: Object) => {
    const { resetSection } = this.props;
    resetSection('propertyTransactionCodeId');
  };

  handleSubjournalChange = (evt: Object) => {
    const { resetSection } = this.props;
    const selectedSubjournal = this.props.subjournals.find(
      (sj) => sj.id === evt.target.value,
    );
    this.setState({ subjournal: selectedSubjournal });
    resetSection('propertyTransactionCodeId');
  };

  generateOptions = (
    data: Array<Object> = [],
    shape: Object,
  ): Array<Object> => {
    return data.map((datum) => {
      return {
        value: pathOr('', shape.value, datum),
        text: pathOr('', shape.text, datum),
      };
    });
  };

  handleClose = () => {
    const {
      currenForm: { anyTouched },
      close,
    } = this.props;
    const { showCancelConfirmation } = this.state;

    if (anyTouched && !showCancelConfirmation) {
      this.showConfirmation();
      return;
    }
    this.hideConfirmation();
    if (close) close();
  };

  setCancelConfirmation = (visible: boolean) => {
    this.setState({
      showCancelConfirmation: visible,
    });
  };

  showConfirmation = () => this.setCancelConfirmation(true);
  hideConfirmation = () => this.setCancelConfirmation(false);

  renderCancelConfirmation = () => {
    return (
      <div className="modal-confirm">
        <h1>
          <FormattedMessage {...messages.cancelConfirmationHeader} />
        </h1>
        <Col xs={12} sm={6}>
          <Button variant="primary" onClick={this.handleClose}>
            <FormattedMessage {...messages.yes} />
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button variant="default" onClick={this.hideConfirmation}>
            <FormattedMessage {...messages.no} />
          </Button>
        </Col>
      </div>
    );
  };

  render() {
    const {
      intl,
      show,
      property,
      headerData,
      defaultOption,
      subjournals,
      transactionTypeId,
      transactionCodes,
      transactionTypes,
      handleSubmit,
      onSubmit,
      edit,
      lease,
      frNames,
      auditData,
      initialValues,
      close,
      propertyTransactionCodeId,
      autoChargeMtmFeesFlag,
    } = this.props;

    const { subjournal } = this.state;
    const subjournalOptions = [
      defaultOption,
      ...this.generateOptions(subjournals, {
        value: ['id'],
        text: ['masterSubjournalType', ['description']],
      }),
    ];

    const transactionTypesOptions = [
      defaultOption,
      ...this.generateOptions(
        (transactionTypes || []).filter(
          (type) => type.includeInLeaseMonthlyCharges,
        ),
        {
          value: ['id'],
          text: ['name'],
        },
      ),
    ];

    const transactionCodesOptions = [
      defaultOption,
      ...this.generateOptions(
        transactionCodes
          .filter(
            (code) =>
              transactionTypeId === code.transactionCode.transactionTypeId,
          )
          .filter(
            (code) =>
              !code.transactionCode.masterSubjournal ||
              code.transactionCode.masterSubjournal.id ===
                subjournal.masterSubjournalId,
          ),
        {
          value: ['id'],
          text: ['transactionCode', 'code'],
        },
      ),
    ];

    const hideConfirmAddEdit = () => {
      this.setState({
        showConfirmAddEdit: false,
      });
    };

    const submitForm = () => {
      hideConfirmAddEdit();
      handleSubmit(onSubmit)();
      close();
    };

    const onSubmitClicked = () => {
      // If we're editing, the LMT is linked to a prorate from these fields
      const lmtProrateFieldValues = props(
        [
          'newLeaseProratedLmt',
          'priorLeaseProratedLmt',
          'prorateEndedLmtReplacement',
        ],
        initialValues,
      );
      // If we're editing, the lease is linked to a prorate from these fields
      const leaseProrateFieldValues = props(
        ['lewLeaseProrate', 'priorLeaseProrate'],
        lease,
      );
      const lmtHasProrateFields =
        edit && lmtProrateFieldValues.some((v) => !isEmpty(v) && !isNil(v));

      const leaseHasProrateFields =
        !edit && leaseProrateFieldValues.some((v) => !isEmpty(v) && !isNil(v));

      if (lmtHasProrateFields || leaseHasProrateFields) {
        this.setState({
          showConfirmAddEdit: true,
        });
      } else {
        handleSubmit(onSubmit)();
      }
    };

    const selectedPropertyTransactionCode = transactionCodesOptions.find(
      ({ value }) => value === propertyTransactionCodeId,
    );
    const disableButtons =
      this.state.showCancelConfirmation || this.state.showConfirmAddEdit;
    const autoChargeMtmFeesIsEnabled =
      [null, true].includes(property?.config?.autoChargeMtmFees) &&
      autoChargeMtmFeesFlag;

    const submitDisabled =
      !this.props.valid ||
      (autoChargeMtmFeesIsEnabled &&
        selectedPropertyTransactionCode?.text === MTOM_CODE_TEXT &&
        !edit);
    return (
      <Modal
        id="monthlyTransactionModal"
        bsSize="small"
        show={show}
        onHide={this.handleClose}
        autoFocus
        className="transaction"
      >
        <Modal.Header closeButton>
          <i className="icon et-money" />
          <h1>
            {edit
              ? intl.formatMessage(messages.headerTitleMonthlyeEdit)
              : intl.formatMessage(messages.headerTitleMonthly)}
          </h1>
        </Modal.Header>
        <Modal.Body>
          {this.state.showConfirmAddEdit && (
            <AddEditChargeModal
              intl={intl}
              edit={edit}
              onCancel={hideConfirmAddEdit}
              onConfirm={() => submitForm()}
            />
          )}
          {this.state.showCancelConfirmation && this.renderCancelConfirmation()}
          <Row
            className="scrollable-modal__content"
            style={this.state.showCancelConfirmation ? { display: 'none' } : {}}
          >
            <div className="container-fluid">
              <Row>
                <Col md={5} xs={12}>
                  <label>
                    {intl.formatMessage(messages.propertyNameLabel)}
                  </label>
                </Col>
                <Col md={7} xs={12}>
                  <div className="form-group">{property.name}</div>
                </Col>
              </Row>
              <Row>
                <Col md={5} xs={12}>
                  <label>{intl.formatMessage(messages.unitNumberLabel)}</label>
                </Col>
                <Col md={7} xs={12}>
                  <div className="form-group">{lease.unitNumber}</div>
                </Col>
              </Row>
              <Row>
                <Col md={5} xs={12}>
                  <label>{intl.formatMessage(messages.statusLabel)}</label>
                </Col>
                <Col md={7} xs={12}>
                  <div className="form-group">{headerData.status}</div>
                </Col>
              </Row>
              <Row>
                <Col md={5} xs={12}>
                  <label>{intl.formatMessage(messages.nameLabel)}</label>
                </Col>
                <Col md={7} xs={12}>
                  <div className="form-group">{frNames.join(', ')}</div>
                </Col>
              </Row>
              <Row>
                <Col md={5} xs={12}>
                  <label>{intl.formatMessage(messages.subjournalLabel)}</label>
                </Col>
                <Col md={7} xs={12}>
                  <Field
                    name="subjournalId"
                    component={renderSelectField}
                    options={subjournalOptions}
                    onChange={this.handleSubjournalChange}
                    disabled={edit}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={5} xs={12}>
                  <label>
                    {intl.formatMessage(messages.transactionTypeLabel)}
                  </label>
                </Col>
                <Col md={7} xs={12}>
                  <Field
                    name="transactionTypeId"
                    component={renderSelectField}
                    options={transactionTypesOptions}
                    onChange={this.handleTransactionTypeChange}
                    disabled={edit}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={5} xs={12}>
                  <label>{intl.formatMessage(messages.startDateLabel)}</label>
                </Col>
                <Col md={7} xs={12}>
                  <Field
                    name="startDate"
                    component={renderDateField}
                    bsSize="md"
                    placeholder={extractCurrentDateFormat(intl)}
                    dateFormat={extractCurrentDateFormat(intl)}
                    disabled={edit}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={5} xs={12}>
                  <label>{intl.formatMessage(messages.endDateLabel)}</label>
                </Col>
                <Col md={7} xs={12}>
                  <Field
                    name="endDate"
                    component={renderDateField}
                    bsSize="md"
                    placeholder={extractCurrentDateFormat(intl)}
                    dateFormat={extractCurrentDateFormat(intl)}
                    disabled={this.props.noEndDate}
                  />
                  <Field
                    name="noEndDate"
                    component={renderCheckboxField}
                    bsSize="lg"
                    label={intl.formatMessage(messages.noEndDateLabel)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={5} xs={12}>
                  <label>
                    {intl.formatMessage(messages.transactionCodeLabel)}
                  </label>
                </Col>
                <Col md={7} xs={12}>
                  <Field
                    name="propertyTransactionCodeId"
                    component={renderSelectField}
                    options={transactionCodesOptions}
                    disabled={
                      (edit ||
                        transactionTypeId === 'default' ||
                        subjournal.masterSubjournalId === 'default') &&
                      selectedPropertyTransactionCode?.text !== MTOM_CODE_TEXT
                    }
                    meta={
                      autoChargeMtmFeesIsEnabled &&
                      ERROR_MESSAGES[selectedPropertyTransactionCode?.text] &&
                      !edit
                        ? {
                            error:
                              ERROR_MESSAGES[
                                selectedPropertyTransactionCode?.text
                              ],
                            touched: propertyTransactionCodeId !== 'default',
                          }
                        : {}
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={5} xs={12}>
                  <label>{intl.formatMessage(messages.noteLabel)}</label>
                </Col>
                <Col md={7} xs={12}>
                  <Field
                    name="note"
                    component={renderTextField}
                    className="form-control input-md"
                    maxLength="25"
                    disabled={edit}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={5} xs={12}>
                  <label>
                    {intl.formatMessage(messages.transactionAmountLabel)}
                  </label>
                </Col>
                <Col md={7} xs={12}>
                  <Field
                    name="amount"
                    component={renderCurrencyField}
                    className="form-control input-md"
                    disabled={edit}
                  />
                </Col>
              </Row>
              <Row>
                {edit ? (
                  <div>
                    <Col md={5} xs={12}>
                      <label>{intl.formatMessage(messages.createdBy)}</label>
                    </Col>
                    <Col md={7} xs={12}>
                      <div className="form-group">
                        {auditData.createdBy} {intl.formatMessage(messages.on)}{' '}
                        {moment(auditData.createdAt).format(
                          'MMMM DD, YYYY, h:mm A',
                        )}
                      </div>
                    </Col>
                    <Col md={5} xs={12}>
                      <label>{intl.formatMessage(messages.updatedBy)}</label>
                    </Col>
                    <Col md={7} xs={12}>
                      <div className="form-group">
                        {auditData.updatedBy} {intl.formatMessage(messages.on)}{' '}
                        {moment(auditData.updatedAt).format(
                          'MMMM DD, YYYY, h:mm A',
                        )}
                      </div>
                    </Col>
                  </div>
                ) : null}
              </Row>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer className={disableButtons ? 'disabled ' : ''}>
          <Stack spacing={2} direction={'row'}>
            <Button
              variant={'default'}
              onClick={this.handleClose}
              sx={{ flex: 1 }}
            >
              Cancel
            </Button>
            <Button
              variant={'primary'}
              disabled={submitDisabled}
              onClick={onSubmitClicked}
              sx={{ flex: 1 }}
            >
              {edit ? 'Save' : 'Add'}
            </Button>
          </Stack>
        </Modal.Footer>
      </Modal>
    );
  }
}

export const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    actions: bindActionCreators(
      {
        getTransactionTypes,
        getPropertySubjournalTypes,
        getPropertyTransactionCodes,
      },
      dispatch,
    ),
  };
};

export const mapStateToProps = (state: Object): Object => {
  const { form } = state;
  const {
    ledger: { headerData },
  } = state;
  const selector = formValueSelector('leaseMonthlyTransactionForm');
  return {
    headerData,
    propertyTransactionCodeId: selector({ form }, 'propertyTransactionCodeId'),
    noEndDate: selector({ form }, 'noEndDate'),
    transactionTypeId: selector({ form }, 'transactionTypeId'),
    subjournalId: selector({ form }, 'subjournalId'),
    currenForm: form['leaseMonthlyTransactionForm'],
    organizationId: state.app.currentUser.user.organizationId,
    property: state.app.selectedProperty,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'leaseMonthlyTransactionForm',
    touchOnChange: true,
    validate,
  })(MonthlyTransactionModal),
);
