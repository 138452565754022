import React from 'react';

import type {
  Props,
  StatusBoxHeaderProps,
  StatusBoxMetaProps,
  StatusBoxStatusProps,
  StatusBoxNotesProps,
} from './types';
import messages from '../messages';
import { Field } from 'redux-form';
import { renderCurrencyField } from '../../../../../utils/redux-form-helper';
import { FormGroup, HelpBlock } from 'react-bootstrap';

import { FormattedMessage } from 'react-intl';

import TUReasonList from '../../../../../components/TUReasonList';
import ScreeningIcon from '../../../../../components/ScreeningIcon';

export const StatusBoxHeader = ({
  type,
  heading,
  onEditCallback,
  onResubmitCallback,
  isResident,
  checkPermission,
  checkApprovedPermission,
}: StatusBoxHeaderProps) => {
  return (
    <div className="creditstatus-heading">
      <h5>{heading}</h5>
      {(checkPermission || checkApprovedPermission) &&
      onEditCallback &&
      !isResident ? (
        <a className="btn-text" onClick={onEditCallback}>
          <i className="icon et-pencil" />
          <FormattedMessage {...messages.edit} />
        </a>
      ) : null}
      {(checkPermission || checkApprovedPermission) &&
      onResubmitCallback &&
      !isResident ? (
        <a className="btn-text" onClick={onResubmitCallback}>
          <i className="icon et-circle-up-arrow" />
          <FormattedMessage {...messages.resubmit} />
        </a>
      ) : null}
    </div>
  );
};
export const StatusBoxStatus = ({
  status,
  subStatus,
}: StatusBoxStatusProps) => (
  <div className="creditstatus-status">
    {status ? <h2>{status}</h2> : null}
    {subStatus ? <small className="unavailable">{subStatus}</small> : null}
  </div>
);
export const StatusBoxMeta = ({ meta }: StatusBoxMetaProps) => (
  <div className="creditstatus-meta">
    <small>{meta}</small>
  </div>
);

export const StatusBoxNotes = ({ notes }: StatusBoxNotesProps) =>
  notes ? (
    <div>
      <small>
        <b>NOTE:</b> {notes}
      </small>
    </div>
  ) : null;

export const StatusBox = ({
  type,
  section,
  heading,
  status,
  subStatus,
  onEditCallback,
  onResubmitCallback,
  meta,
  intl,
  updateMonthlyRentAmount,
  monthlyRentAmount,
  initialRentAmount,
  error,
  isResident,
  checkPermission,
  checkApprovedPermission,
  finalDecisionNote,
  reasons,
}: Props) => {
  const under10k = (value) => {
    if (value < 0) {
      return 0;
    } else if (value > 10000) {
      return 10000;
    } else {
      return value;
    }
  };
  const estimateAmount =
    heading === 'TransUnion Screening' &&
    type !== 'unavailable' &&
    type !== 'error' ? (
      <div className="estimate-monthly">
        <Field
          name="annualSalary"
          component={renderCurrencyField}
          className="form-control input-lg"
          label={intl.formatMessage(messages.estimatedMonthlyRentAmount)}
          placeholder={initialRentAmount || '0.00'}
          onChange={updateMonthlyRentAmount}
          normalize={under10k}
          disabled={isResident}
        />
        {error ? (
          <FormGroup controlId="formValidationError" validationState="error">
            <HelpBlock>{error}</HelpBlock>
          </FormGroup>
        ) : null}
      </div>
    ) : null;
  return (
    <div className="col-sm-6">
      <div className="row">
        <div className="col-sm-2">
          <ScreeningIcon type={type} />
        </div>
        <div className="col-sm-10">
          <StatusBoxHeader
            type={type}
            heading={heading}
            onEditCallback={onEditCallback}
            onResubmitCallback={onResubmitCallback}
            checkPermission={checkPermission}
            checkApprovedPermission={checkApprovedPermission}
            isResident={!!isResident}
          />
          <StatusBoxStatus status={status} subStatus={subStatus} />
          {reasons && <TUReasonList reasons={reasons} />}
          <StatusBoxMeta meta={meta} />
          <StatusBoxNotes notes={finalDecisionNote} />
          {estimateAmount}
        </div>
      </div>
    </div>
  );
};

export default StatusBox;
