import React from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { SubmitButton } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import useDownloadAll59AsMutation from './hooks.useDownloadAll59AsMutation';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

const GenerateAll59AsButton = ({ disabled, intl, ...props }) => {
  const { organizationId, id: propertyId } = useSelectedProperty();
  const downloadAll59AsMutation = useDownloadAll59AsMutation({
    organizationId,
    propertyId,
    options: {
      enabled: Boolean(organizationId && propertyId && !disabled),
      onMutate: () => {
        toastr.info(
          intl.formatMessage(messages.generatingAll59As),
          intl.formatMessage(messages.willDownloadWhenComplete),
        );
      },
      onSuccess: () => {
        toastr.success(
          intl.formatMessage(messages.success),
          intl.formatMessage(messages.generateAll59AsSuccess),
        );
      },
      onError: (error) => {
        toastr.error(
          intl.formatMessage(messages.error),
          intl.formatMessage(messages.errorGeneratingAll59As, {
            errorMessage: error.toString(),
          }),
        );
      },
    },
  });

  return (
    <SubmitButton
      variant={'shout'}
      isSubmitting={downloadAll59AsMutation.isLoading}
      onClick={downloadAll59AsMutation.mutate}
      disabled={disabled}
      {...props}
    >
      <FormattedMessage {...messages.generateAll59As} />
    </SubmitButton>
  );
};

export default GenerateAll59AsButton;
