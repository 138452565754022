import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import * as constants from './constants';
import validate from './validate';
import {
  phoneFormatter,
  phoneParser,
  renderPhoneField,
  renderSelectField,
  renderTextField,
  validateName,
  validateNonRequiredName,
  validateAddress,
  validateNonRequiredAddress,
} from '../../../utils/redux-form-helper';
import * as hooks from './hooks';
import Spinner from '../../../components/Spinner';
import messages from '../messages';

const FormGrid = styled.div`
  column-gap: 15px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 10px;
  row-gap: 20px;
`;

const GridItem = styled.div`
  ${({ columnStart, columnEnd, rowStart, rowEnd, justifySelf, alignSelf }) => `
    grid-column-start: ${columnStart};
    grid-column-end: ${columnEnd};
    grid-row-start: ${rowStart};
    grid-row-end: ${rowEnd};
    ${justifySelf ? `justify-self: ${justifySelf};` : ''}
    ${alignSelf ? `align-self: ${alignSelf};` : ''}
  `}
`;

function ContactForm({ organizationId, handleSubmit, onSubmit }) {
  const [commercialContactTypeOptions, commercialContactTypeOptionsLoaded] =
    hooks.useCommercialContactTypeOptions(organizationId);
  const [states, statesLoaded] = hooks.useStates();

  if (!commercialContactTypeOptionsLoaded || !statesLoaded) return <Spinner />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGrid>
        <GridItem columnStart={1} columnEnd={4} rowStart={1} rowEnd={1}>
          <Field
            name="commercialContactTypeId"
            component={renderSelectField}
            options={commercialContactTypeOptions}
            selectClassNames="input form-control"
            label={
              <FormattedMessage {...messages.commercialContactTypeField} />
            }
          />
        </GridItem>
        <GridItem columnStart={4} columnEnd={8} rowStart={1} rowEnd={1}>
          <Field
            name="contactName"
            component={renderTextField}
            label={<FormattedMessage {...messages.contactNameField} />}
            className="input form-control"
            placeholder="Enter Name"
            maxLength={35}
            showLabel
          />
        </GridItem>
        <GridItem columnStart={8} columnEnd={13} rowStart={1} rowEnd={1}>
          <Field
            name="businessName"
            component={renderTextField}
            label={<FormattedMessage {...messages.businessNameField} />}
            className="input form-control"
            placeholder="Enter Business Name"
            showLabel
            validate={[validateNonRequiredName]}
            maxLength="70"
          />
        </GridItem>
        <GridItem columnStart={1} columnEnd={4} rowStart={2} rowEnd={2}>
          <Field
            name="phoneNumber"
            component={renderPhoneField}
            label={<FormattedMessage {...messages.phoneNumberField} />}
            className="input form-control"
            placeholder="(xxx) xxx-xxxx"
            parse={phoneParser}
            format={phoneFormatter}
            showLabel
          />
        </GridItem>
        <GridItem columnStart={4} columnEnd={8} rowStart={2} rowEnd={2}>
          <Field
            name="businessPhoneExtension"
            component={renderTextField}
            label={<FormattedMessage {...messages.businessPhoneExtension} />}
            className="input form-control"
            placeholder="Enter Phone Extension"
            showLabel
          />
        </GridItem>
        <GridItem columnStart={8} columnEnd={13} rowStart={2} rowEnd={2}>
          <Field
            name="secondaryPhoneNumber"
            component={renderPhoneField}
            label={<FormattedMessage {...messages.secondaryPhoneNumberField} />}
            className="input form-control"
            placeholder="(xxx) xxx-xxxx"
            parse={phoneParser}
            format={phoneFormatter}
            showLabel
          />
        </GridItem>
        <GridItem columnStart={1} columnEnd={8} rowStart={3} rowEnd={3}>
          <Field
            name="emailAddress"
            component={renderTextField}
            label={<FormattedMessage {...messages.emailField} />}
            className="input form-control"
            placeholder="Enter Email"
            showLabel
          />
        </GridItem>
        <GridItem columnStart={8} columnEnd={13} rowStart={3} rowEnd={3}>
          <Field
            name="note"
            component={renderTextField}
            label={<FormattedMessage {...messages.noteField} />}
            className="input form-control"
            placeholder="Enter Note"
            showLabel
            maxLength="200"
          />
        </GridItem>
        <GridItem columnStart={1} columnEnd={4} rowStart={4} rowEnd={4}>
          <Field
            name="contactAddress"
            component={renderTextField}
            label={<FormattedMessage {...messages.contactAddressField} />}
            className="input form-control"
            placeholder="Address"
            showLabel
            validate={[validateAddress]}
            maxLength="100"
          />
        </GridItem>
        <GridItem columnStart={4} columnEnd={7} rowStart={4} rowEnd={4}>
          <Field
            name="contactAddress2"
            component={renderTextField}
            label={<FormattedMessage {...messages.contactAddress2Field} />}
            className="input form-control"
            placeholder="Address 2"
            showLabel
            validate={[validateNonRequiredAddress]}
            maxLength="100"
          />
        </GridItem>
        <GridItem columnStart={7} columnEnd={9} rowStart={4} rowEnd={4}>
          <Field
            name="city"
            component={renderTextField}
            label={<FormattedMessage {...messages.cityField} />}
            className="input form-control"
            placeholder="City"
            showLabel
            validate={[validateName]}
            maxLength="70"
          />
        </GridItem>
        <GridItem columnStart={9} columnEnd={11} rowStart={4} rowEnd={4}>
          <Field
            name="stateId"
            component={renderSelectField}
            options={states}
            selectClassNames="input form-control"
            label={<FormattedMessage {...messages.stateField} />}
          />
        </GridItem>
        <GridItem columnStart={11} columnEnd={13} rowStart={4} rowEnd={4}>
          <Field
            name="zipCode"
            component={renderTextField}
            label={<FormattedMessage {...messages.zipCodeField} />}
            className="input form-control"
            placeholder="Zip Code / Postal Code"
            showLabel
          />
        </GridItem>
      </FormGrid>
    </form>
  );
}

export default reduxForm({
  form: constants.FORM_NAME,
  validate,
})(ContactForm);
