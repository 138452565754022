import { useRef, useEffect, useState } from 'react';
import PropertyBankAccountsService from '../../../services/propertyBankAccountsService';
import { messages } from './messages';

export const useUpdateBankAccounts = (
  intl: Object,
  promptToaster: Function,
  propertyId: string,
  organizationId: string,
  propertyBankAccounts: Object[],
  onSuccess: Function,
) => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [bankAccounts, setBankAccounts] = useState(propertyBankAccounts);

  useEffect(() => {
    if (propertyBankAccounts.length > 0) {
      setBankAccounts(propertyBankAccounts);
    }
  }, [propertyBankAccounts]);

  const updateBankAccounts = async (data: Object) => {
    const propertyBankAccountsService = new PropertyBankAccountsService();

    try {
      const response = await propertyBankAccountsService.update(
        propertyId,
        organizationId,
        data,
      );

      if (response && isMounted.current) {
        setBankAccounts(response);

        if (onSuccess) {
          onSuccess();
        }
      }

      promptToaster({
        title: intl.formatMessage(messages.success),
        message: intl.formatMessage(messages.successBody),
      });
    } catch (_) {
      promptToaster({
        type: 'error',
        title: intl.formatMessage(messages.error),
        message: intl.formatMessage(messages.errorBody),
      });
    }
  };

  return [bankAccounts, updateBankAccounts, setBankAccounts];
};
