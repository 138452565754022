import React, { Component } from 'react';
import { Modal, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import Countdown from 'react-countdown-now';
import messages from './messages';

type Props = {
  dismiss: Function,
  userIdle: Function,
  logOut: Function,
  showExtendUserSessionModal: boolean,
  intl: any,
};

type CountdownProps = {
  minutes: number,
  seconds: number,
  completed: boolean,
  intl: Object,
  dismiss: Function,
  userIdle: Function,
  logOut: Function,
  showExtendUserSessionModal: boolean,
};

class ExtendSessionModal extends Component<Props, CountdownProps> {
  renderModal = ({
    minutes,
    seconds,
    completed,
    intl,
    showExtendUserSessionModal,
    dismiss,
    logOut,
  }: CountdownProps) => (
    <div className="static-modal">
      <Modal
        id="extendSessionModal"
        bsSize="small"
        dialogClassName="modal-dialog modal-alert"
        show={showExtendUserSessionModal}
        onHide={dismiss}
        autoFocus={true}
      >
        <Modal.Body>
          <Row>
            <div>
              <h1 className="fortress-logo-gray timeout-logo"> </h1>
            </div>
            <Row>
              <Col xs={12} className="padbottom15">
                <h2 className="text-center">
                  {intl.formatMessage(messages.headsUp)}
                </h2>
              </Col>
              <Col xs={12}>
                <h4 className="text-center">
                  {/* <FormattedMessage {...messages.details} /> */}
                  {intl.formatMessage(messages.details)}
                </h4>
              </Col>
            </Row>
          </Row>
          <div
            className="alert alert-danger padright30 padleft30 timeoutwrapper timeout-remaining"
            style={{ textAlign: 'center' }}
            id="countDown"
          >
            {minutes}:{seconds}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={12}>
              <p className="text-center">
                {/* <FormattedMessage {...messages.instructions} /> */}
                {intl.formatMessage(messages.instructions)}
              </p>
            </Col>
          </Row>
          <ButtonToolbar>
            <Col xs={6}>
              <Button type="button" bsStyle="primary" onClick={dismiss}>
                {intl.formatMessage(messages.continue)}
              </Button>
            </Col>
            <Col xs={6}>
              <Button type="button" bsStyle="default" onClick={logOut}>
                {intl.formatMessage(messages.signOut)}
              </Button>
            </Col>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </div>
  );

  render() {
    return (
      <Countdown
        date={Date.now() + 60000}
        renderer={this.renderModal}
        {...this.props}
      />
    );
  }
}

export default ExtendSessionModal;
