type Props = {
  phoneNumber: string,
};

const FormatPhone = ({ phoneNumber }: Props) => {
  const cleanPhoneNumber = phoneNumber && phoneNumber.replace(/[^\d]/g, ''); // Remove all symbols from phone numbers
  return phoneNumber
    ? `(${cleanPhoneNumber.substr(0, 3)}) ${cleanPhoneNumber.substr(
        3,
        3,
      )}-${cleanPhoneNumber.substr(6, 4)}`
    : '---';
};

export default FormatPhone;
