import { defineMessages } from 'react-intl';

const messages = defineMessages({
  statusLabel: {
    id: 'App.LeaseAffordable.EmploymentInformationSection.StatusLabel',
    defaultMessage: 'Status'
  },
  incomeLabel: {
    id: 'App.LeaseAffordable.EmploymentInformationSection.IncomeLabel',
    defaultMessage: 'If you are employed, provide your annual income'
  },
  incomePlaceholder: {
    id: 'App.LeaseAffordable.EmploymentInformationSection.IncomePlaceholder',
    defaultMessage: 'Enter Amount'
  },
  studentType: {
    id: 'App.LeaseAffordable.EmploymentInformationSection.StudentType',
    defaultMessage: 'If student is selected, are you:'
  }
});

export default messages;
