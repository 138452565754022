import moment from 'moment-business-days';
import React, { useEffect, useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { isEmpty, isNil } from 'ramda';
import styled from 'styled-components';

import {
  Spinner,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { FilterDrawer } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import {
  orange,
  grey,
  red,
} from '@fortress-technology-solutions/fortress-component-library/design';

import SearchField from '../Fields/Search';
import ComplianceOverviewDetails from './ComplianceOverviewDetails';
import CertHistoryDetails from './CertHistoryDetails';

import { useFetchComplianceOverview } from './hooks';

import type { OrderValue } from '../App/types';

import {
  FILTERS,
  FILTER_DESCRIPTIONS,
  HEADER_ROWS,
  COMPLIANCE_TYPES,
  FEATURE_FLAG_HEADERS,
} from './constants';
import { buildRows } from './utils';
import { COMPLIANCE_APPROVAL_STATUSES } from '../../utils/affordable';

import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

type ComplianceOverviewProps = {
  intl: Object,
  organizationId: string,
  isActive: boolean,
  promptToaster: Function,
};

const SquareLegend = styled.span`
  background-color: ${(props) => (props.color ? props.color : 'white')};
  width: 12px;
  height: 12px;
  border: 1px solid ${grey.main};
  box-sizing: border-box;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
`;

const ComplianceOverview = ({
  intl,
  organizationId,
  isActive,
  promptToaster,
}: ComplianceOverviewProps) => {
  moment.updateLocale('us', {
    workingWeekdays: [1, 2, 3, 4, 5],
  });
  const ldClient = useLDClient();
  const {
    complianceOverviewReviewedAndNumberOfDays,
    complianceCertHistorySummary,
  } = useFlags();

  const YES = intl.formatMessage(messages.yes);
  const NO = intl.formatMessage(messages.no);
  const FILTER_OPTIONS: Object = {
    type: [
      'Initial',
      'Interim',
      'Move-in',
      'Move-out',
      'Recert',
      'Termination',
    ],
    complianceApprovalStatus: [
      COMPLIANCE_APPROVAL_STATUSES.APPROVED_PENDING_SIGN,
      COMPLIANCE_APPROVAL_STATUSES.APPROVED,
      COMPLIANCE_APPROVAL_STATUSES.PENDING,
      COMPLIANCE_APPROVAL_STATUSES.PENDING_FINAL_APPROVAL,
      COMPLIANCE_APPROVAL_STATUSES.CORRECTION_NEEDED,
      COMPLIANCE_APPROVAL_STATUSES.CORRECTION_NEEDED_FINAL,
      COMPLIANCE_APPROVAL_STATUSES.ON_NOTICE_HOLD,
      COMPLIANCE_APPROVAL_STATUSES.NONE,
    ],
    isActive: [YES, NO],
    isResident: [YES, NO],
    programType: ['HUD', 'LIHTC'],
    waitlistApplicant: [intl.formatMessage(messages.doNotInclude)],
  };

  // #region State
  const [currentSorting, setCurrentSorting] = useState({
    fieldName: complianceOverviewReviewedAndNumberOfDays
      ? 'voucherEffectiveDate'
      : 'documentDate',
    order: 'DESC',
  });
  const [searchText, setSearchText] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [complianceType, setComplianceType] = useState(
    complianceOverviewReviewedAndNumberOfDays
      ? COMPLIANCE_TYPES.ALL_OPEN_CERTS
      : COMPLIANCE_TYPES.NEEDS_REVIEW,
  );
  const [title, setTitle] = useState(
    complianceOverviewReviewedAndNumberOfDays
      ? messages.allOpenCerts
      : messages.needsReview,
  );
  const [showCertHistory, setShowCertHistory] = useState(false);
  const [currentCertHistory, setCurrentCertHistory] = useState({});

  const assignAllFilters = (value: boolean, isDefault?: boolean = false) =>
    (!complianceOverviewReviewedAndNumberOfDays ||
    complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW
      ? FILTERS.filter((filter) => filter !== 'waitlistApplicant')
      : FILTERS
    ).reduce((acc, filter) => {
      acc[filter] = FILTER_OPTIONS[filter].reduce((a, option) => {
        a[option] = filter === 'waitlistApplicant' && isDefault ? true : value;
        return a;
      }, {});
      return acc;
    }, {});

  const buildFilters = (): Array<Object> =>
    (!complianceOverviewReviewedAndNumberOfDays ||
    complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW
      ? FILTERS.filter((filter) => filter !== 'waitlistApplicant')
      : FILTERS
    ).map((filter) => ({
      name: filter,
      description: intl.formatMessage(messages[FILTER_DESCRIPTIONS[filter]]),
      options: (FILTER_OPTIONS[filter] ?? []).map((e) => ({
        text: e,
        value: e,
      })),
      variant: 'list',
    }));

  const [drawerFilters, setDrawerFilters] = useState(buildFilters());
  const [currentFilter, setCurrentFilter] = useState(
    assignAllFilters(false, true),
  );
  const [filter, setFilter] = useState(assignAllFilters(false, true));
  // #endregion

  // Old Way to get data before deleteDocumentsFeature
  const complianceOverviewData = useFetchComplianceOverview({
    organizationId,
    intl,
    toasterFn: promptToaster,
    complianceOverviewReviewedAndNumberOfDays:
      complianceOverviewReviewedAndNumberOfDays,
    complianceType,
    setIsLoading,
    isActive,
  });

  // #region Events
  const clearFilters = () => {
    setFilter(assignAllFilters(false));
  };
  const handleFilterClick = () => {
    setShowFilter(true);
  };
  const handleSort = ({ fieldName, order }: OrderValue) => {
    setCurrentSorting({ fieldName, order });
  };
  const handleSearchSubmit = ({ searchText }: Object) => {
    setSearchText(searchText);
  };
  const onCloseFilterClick = () => {
    setFilter(currentFilter);
    setShowFilter(false);
  };
  const onApplyFilterClick = () => {
    setCurrentFilter(filter);
    setShowFilter(false);
  };
  const onComplianceTypeClick = (complianceType: string) => {
    setComplianceType(complianceType);
  };
  const onFilterChange = (
    field: string,
    filterValue: string,
    value: boolean | Array<string>,
  ) => {
    const newFilter = isNil(filterValue)
      ? {
          ...filter,
          [field]: value,
        }
      : {
          ...filter,
          [field]: {
            ...filter[field],
            [filterValue]: value,
          },
        };
    setFilter(newFilter);
  };
  const onViewCertHistoryClick = (certHistory: Object) => {
    setCurrentCertHistory(certHistory);
    setShowCertHistory(true);
  };
  const onCloseCertHistoryDetailsClick = () => {
    setShowCertHistory(false);
  };
  // #endregion

  const headersToFilter = [
    ...(!complianceOverviewReviewedAndNumberOfDays
      ? FEATURE_FLAG_HEADERS.complianceOverviewReviewedAndNumberOfDays
      : []),
    ...(!complianceCertHistorySummary
      ? FEATURE_FLAG_HEADERS.complianceCertHistorySummary
      : []),
  ];
  const headerRows = (HEADER_ROWS ?? []).filter(
    (header) => !headersToFilter.includes(header.id),
  );
  const headers = [
    {
      headers: headerRows.map((header) => ({
        ...header,
        title: !isEmpty(header.title)
          ? intl.formatMessage(messages[header.title])
          : '',
      })),
    },
  ];

  // #region Effects
  useEffect(() => {
    ldClient.identify({
      kind: 'user',
      key: 'fortressUser',
      organizationId,
    });
  });

  useEffect(() => {
    setHasFilters(
      Object.keys(currentFilter).some((key) =>
        Object.values(currentFilter[key]).some((e) => e),
      ),
    );
  }, [currentFilter]);

  useEffect(() => {
    const builtRows = buildRows(
      complianceOverviewData,
      complianceType,
      currentSorting,
      hasFilters,
      currentFilter,
      searchText,
      onViewCertHistoryClick,
      {
        complianceOverviewReviewedAndNumberOfDays,
        complianceCertHistorySummary,
      },
    );
    setRows(builtRows);
    setTotal(builtRows.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentSorting,
    complianceType,
    currentFilter,
    searchText,
    complianceOverviewData,
    hasFilters,
  ]);

  useEffect(() => {
    setTitle(messages[complianceType]);
    setDrawerFilters(buildFilters());
    setFilter(assignAllFilters(false, true));
    setCurrentFilter(assignAllFilters(false, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complianceType]);
  // #endregion

  return (
    <Grid
      container
      rowSpacing={1.5}
      sx={{ pl: '10px', pr: '10px', maxHeight: 'calc(98% - 86px)' }}
    >
      <CertHistoryDetails
        intl={intl}
        certHistory={currentCertHistory}
        onCloseCertHistoryDetailsClick={onCloseCertHistoryDetailsClick}
        show={showCertHistory}
      />
      {complianceOverviewReviewedAndNumberOfDays && (
        <Grid item xs={12}>
          <ButtonGroup>
            <button
              id="compliance-all"
              className={`btn btn-primary--ghost-white${
                complianceType === COMPLIANCE_TYPES.ALL_OPEN_CERTS
                  ? ' active'
                  : ''
              }`}
              type="button"
              onClick={() =>
                onComplianceTypeClick(COMPLIANCE_TYPES.ALL_OPEN_CERTS)
              }
            >
              <FormattedMessage {...messages.allOpenCerts} />
            </button>
            <button
              id="compliance-review"
              className={`btn btn-primary--ghost-white${
                complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW
                  ? ' active'
                  : ''
              }`}
              type="button"
              onClick={() =>
                onComplianceTypeClick(COMPLIANCE_TYPES.NEEDS_REVIEW)
              }
            >
              <FormattedMessage {...messages.needsReview} />
            </button>
          </ButtonGroup>
        </Grid>
      )}
      <Grid item xs={3} md={4}>
        {complianceOverviewReviewedAndNumberOfDays ? (
          <Grid container rowSpacing={0.5}>
            <Grid item xs={12}>
              <Typography variant="h2" mb={'10px'}>
                <FormattedMessage {...title} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {complianceType === COMPLIANCE_TYPES.ALL_OPEN_CERTS ? (
                <Grid container columnSpacing={2.5}>
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      sx={{ fontSize: '12px' }}
                    >
                      <SquareLegend color={orange.lighter} />
                      <FormattedMessage {...messages.attentionRequired} />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      sx={{ fontSize: '12px' }}
                    >
                      <SquareLegend color={red.lighter} />
                      <FormattedMessage {...messages.effectivePastDue} />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        ) : (
          <Typography variant="h1">
            <FormattedMessage {...messages.summary} />
          </Typography>
        )}
      </Grid>
      {isLoading ? (
        <Grid item xs={12}>
          <Spinner />
        </Grid>
      ) : (
        [
          <Grid item xs={9} md={8}>
            <div className="search-actions-small">
              <button
                className={`button-filter ${
                  hasFilters ? 'filters-are-active' : ''
                }`}
                onClick={handleFilterClick}
              >
                <i className="et-filter" />
              </button>
              <SearchField
                className="search-input-active"
                form={'ComplianceOverview'}
                placeholder={`${intl.formatMessage(messages.search)}`}
                onSubmit={handleSearchSubmit}
              />
            </div>
          </Grid>,
          <Grid item xs={12}>
            <div
              className="table-scroll table-units-container"
              style={{ maxHeight: '69vh' }}
            >
              <ComplianceOverviewDetails
                rows={rows}
                headers={headers}
                name="ComplianceOverview"
                onSort={handleSort}
                intl={intl}
              />
            </div>
            <FilterDrawer
              squareChecked
              title={intl.formatMessage(messages.filterBy)}
              clearBtnText={intl.formatMessage(messages.clearFilters)}
              applyBtnText={intl.formatMessage(messages.apply)}
              filters={drawerFilters}
              onFilterChange={onFilterChange}
              onCloseClick={onCloseFilterClick}
              onApplyClick={onApplyFilterClick}
              show={showFilter}
              onClearFiltersClick={clearFilters}
              currentFilter={filter}
            />
          </Grid>,
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '12px' }}>
              <FormattedMessage {...messages.total} />: {total}
            </Typography>
          </Grid>,
        ]
      )}
    </Grid>
  );
};

export default injectIntl(ComplianceOverview);
