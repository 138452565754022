import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.CreateChargeBatch.Title',
    defaultMessage: 'Create Charge Batch'
  },
  cancel: {
    id: 'App.CreateChargeBatch.Cancel',
    defaultMessage: 'Cancel'
  },
  submit: {
    id: 'App.CreateChargeBatch.Submit',
    defaultMessage: 'Upload'
  },
  confirmTitle: {
    id: 'App.CreateChargeBatch.Confirm.Title',
    defaultMessage: 'Are you sure you want to leave without saving?'
  },
  confirmMessage: {
    id: 'App.CreateChargeBatch.Confirm',
    defaultMessage: 'Yes'
  },
  rejectMessage: {
    id: 'App.CreateChargeBatch.Reject',
    defaultMessage: 'No'
  },
  createChargeBatchSuccessHeader: {
    id: 'App.CreateChargeBatch.Success.Header',
    defaultMessage: 'Success'
  },
  createChargeBatchSuccessDescription: {
    id: 'App.CreateChargeBatch.Success.Description',
    defaultMessage: 'File Uploaded'
  },
  createChargeBatchFailureHeader: {
    id: 'App.CreateChargeBatch.Failure.Header',
    defaultMessage: 'Error'
  },
  createChargeBatchFailureDescription: {
    id: 'App.CreateChargeBatch.Failure.Description',
    defaultMessage: 'File not Uploaded'
  }
});

export default messages;
