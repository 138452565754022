import React from 'react';
import { Col, Row as RbRow } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import Spinner from '../../../../components/Spinner';
import Table from '../../../../components/Table';
import Data from '../../../../components/Table/Data';
import Row from '../../../../components/Table/Row';

import { RecertificationStatBox } from './RecertificationStatBox';
import messages from './messages';
import RecertRow from './RecertRow';

import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  Stack,
  Tooltip,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
type CerticationsTableProps = {
  headers: Array,
  intl: Object,
  currentSort: Object,
  onSortChange: Function,
  displayAll: boolean,
  setDisplayAll: Function,
};

function RecertsTable({
  headers,
  intl,
  onSortChange,
  rows,
  statistics,
  isLoading,
  displayAll,
  setDisplayAll,
  show,
  setShow,
}: CerticationsTableProps) {
  const display = () => {
    setDisplayAll(!displayAll);
    if (!displayAll && show) {
      setShow(false);
    }
  };
  return (
    <>
      <Col xs={12} md={12}>
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          {' '}
          <p>
            <FormattedMessage {...messages.recertsFound} />
            <span className="text-blue">
              {rows ? `: ${rows.length}` : ': 0'}
            </span>
          </p>
          <Checkbox
            label={<FormattedMessage {...messages.displayAll} />}
            displayAll={displayAll}
            onChange={display}
          ></Checkbox>
          <Tooltip
            variant="light"
            title={intl.formatMessage(messages.ifDisplayAllSelected)}
            placement="right"
          >
            <AlertInfoIcon />
          </Tooltip>
        </Stack>
      </Col>
      <RbRow className="padtop10 padleft10 padright10">
        <RecertificationStatBox statistics={statistics} />
      </RbRow>
      <Col xs={12}>
        <div className="table-scroll table-units-container">
          <Table
            name="manage-certifications"
            headers={headers}
            onSort={onSortChange}
          >
            {isLoading ? (
              <Row>
                <Data colSpan={headers.length}>
                  <Spinner />
                </Data>
              </Row>
            ) : rows.length > 0 ? (
              rows.map((row) => (
                <RecertRow
                  key={`${row.programName} - ${row.residentId}`}
                  recertification={row}
                />
              ))
            ) : (
              <Row>
                <Data colSpan={headers.length}>
                  <FormattedMessage {...messages.noEntriesFound} />
                </Data>
              </Row>
            )}
          </Table>
        </div>
      </Col>
    </>
  );
}

export default injectIntl(RecertsTable);
