export interface FieldDescriptor {
  label: string;
  type?: string;
  name: string;
  defaultValues?: Object;
  locale?: {
    [key: string]: string,
  };
  [key: string]: any;
}

export const FieldTypes = {
  MULTISELECT: 'MultiSelect',
  SELECT: 'Select',
  CHECKBOX: 'Checkbox',
};
