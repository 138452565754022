import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ok: {
    id: 'App.DeleteDialogModal.ok',
    defaultMessage: 'Yes, proceed'
  },
  cancel: {
    id: 'App.DeleteDialogModal.cancel',
    defaultMessage: 'Cancel'
  }
});

export default messages;
