import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import ManageDepositsHeader from './ManageDepositsHeader/index.js';
import ManageDepositsDetails from './ManageDepositsDetails/index.js';
import { getAllDeposits, clearLoadedDeposits } from './actions';
import { getAllPropertyPeriods } from '../ManageMiscTransactions/actions.js';
import type { Period, Deposit } from './types.js';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type Props = {
  periods: Array<Period>,
  deposits: Array<Deposit>,
};

type InjectedProps = {
  intl: any,
  actions: Object,
  history: Object,
};

type State = {
  currentPeriod: string,
};

export class ManageDeposits extends Component<Props & InjectedProps, State> {
  constructor(props: Props & InjectedProps) {
    super(props);
    this.state = {
      currentPeriod: '',
    };
  }

  componentDidMount() {
    this.props.actions.getAllPropertyPeriods();
    this.props.actions.getAllDeposits();
  }

  componentWillUnmount() {
    this.props.actions.clearLoadedDeposits();
  }

  onPeriodChange = ({ target: { value } }: Object) => {
    this.setState({ ...this.state, currentPeriod: value });
    this.props.actions.getAllDeposits(value);
  };

  onView = (id: string) => {
    navigateToUrlWithSelectedPropertyId(`/deposit/${id}`);
  };

  render() {
    const { intl, periods, deposits } = this.props;
    return (
      <div className="bodywrap" data-test="manage-deposits">
        <ManageDepositsHeader
          formatMessage={intl.formatMessage}
          periods={periods}
          onPeriodChange={this.onPeriodChange}
          data-test="manage-deposits-header"
        />
        <ManageDepositsDetails
          intl={intl}
          deposits={deposits}
          onView={this.onView}
          data-test="manage-deposits-details"
        />
      </div>
    );
  }
}

export const mapStateToProps = ({
  app,
  financials: {
    miscTransactions: { periods },
    deposits: { deposits },
  },
}: Object): Object => {
  return {
    periods,
    deposits,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    actions: bindActionCreators(
      {
        getAllPropertyPeriods,
        getAllDeposits,
        clearLoadedDeposits,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ManageDeposits));
