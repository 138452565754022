import * as ActionTypes from './constants';

import type { Prospect, Activity } from '../ProspectProfile/types';
import type { Action, PaginationMeta, PaginationInfo } from '../App/types';
import type { StatusChange } from './types';

export function getOneProspect(prospectId: string): Action<string> {
  return {
    type: ActionTypes.GET_ONE_PROSPECT,
    payload: prospectId,
  };
}

export function getOneProspectSuccess(prospect: Prospect): Action<Prospect> {
  return {
    type: ActionTypes.GET_ONE_PROSPECT_SUCCESS,
    payload: prospect,
  };
}

export function getOneProspectError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ONE_PROSPECT_ERROR,
    payload: error,
  };
}

export function createProspectActivity(
  activity: Activity,
  refresh?: Function,
): Action<Activity> {
  return {
    type: ActionTypes.CREATE_PROSPECT_ACTIVITY,
    payload: { ...activity, refresh },
  };
}

export function createProspectActivitySuccess(): Action<any> {
  return {
    type: ActionTypes.CREATE_PROSPECT_ACTIVITY_SUCCESS,
    payload: undefined,
  };
}

export function createProspectActivityError(error: Error): Action<Error> {
  return {
    type: ActionTypes.CREATE_PROSPECT_ACTIVITY_ERROR,
    payload: error,
  };
}

export function getProspectPendingActivities(id: string): Action<string> {
  return {
    type: ActionTypes.GET_PROSPECT_PENDING_ACTIVITIES,
    payload: id,
  };
}

export function getProspectPendingActivitiesSuccess(
  activities: Array<Activity>,
): Action<Array<Activity>> {
  return {
    type: ActionTypes.GET_PROSPECT_PENDING_ACTIVITIES_SUCCESS,
    payload: activities,
  };
}

export function getProspectPendingActivitiesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_PROSPECT_PENDING_ACTIVITIES_ERROR,
    payload: error,
  };
}

export function getProspectCompletedActivities(
  id: string,
  pageNumber: number = 1,
  limit: number = 10,
  sort: string = 'endTime',
  order: string = 'DESC',
): Action<{ id: string } & PaginationInfo> {
  return {
    type: ActionTypes.GET_PROSPECT_COMPLETED_ACTIVITIES,
    payload: {
      id: id,
      pageNumber: pageNumber,
      limit: limit,
      sort: sort,
      order: order,
    },
  };
}

export function getProspectCompletedActivitiesSuccess(
  activities: Array<Activity>,
  meta: PaginationMeta,
): Action<Array<Activity>, PaginationMeta> {
  return {
    type: ActionTypes.GET_PROSPECT_COMPLETED_ACTIVITIES_SUCCESS,
    payload: activities,
    meta: meta,
  };
}

export function getProspectCompletedActivitiesError(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.GET_PROSPECT_COMPLETED_ACTIVITIES_ERROR,
    payload: error,
  };
}

export function getProspectAllActivities(
  id: string,
  pageNumber: number = 1,
  limit: number = 10,
  sort: string = 'dateTime',
  order: string = 'DESC',
): Action<{ id: string } & PaginationInfo> {
  return {
    type: ActionTypes.GET_PROSPECT_ALL_ACTIVITIES,
    payload: {
      id: id,
      pageNumber: pageNumber,
      limit: limit,
      sort: sort,
      order: order,
    },
  };
}

export function getProspectAllActivitiesSuccess(
  activities: Array<Activity>,
  meta: PaginationMeta,
): Action<Array<Activity>, PaginationMeta> {
  return {
    type: ActionTypes.GET_PROSPECT_ALL_ACTIVITIES_SUCCESS,
    payload: activities,
    meta: meta,
  };
}

export function getProspectAllActivitiesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_PROSPECT_ALL_ACTIVITIES_ERROR,
    payload: error,
  };
}

export function updateProspectActivity(
  activity: Activity,
  refresh?: Function,
): Action<Activity> {
  return {
    type: ActionTypes.UPDATE_PROSPECT_ACTIVITY,
    payload: { ...activity, refresh },
  };
}

export function updateProspectActivitySuccess(): Action<any> {
  return {
    type: ActionTypes.UPDATE_PROSPECT_ACTIVITY_SUCCESS,
    payload: undefined,
  };
}

export function updateProspectActivityError(error: Error): Action<Error> {
  return {
    type: ActionTypes.UPDATE_PROSPECT_ACTIVITY_ERROR,
    payload: error,
  };
}

export function deleteProspectActivity(
  activity: Activity,
  refresh?: Function,
): Action<Activity> {
  return {
    type: ActionTypes.DELETE_PROSPECT_ACTIVITY,
    payload: { ...activity, refresh },
  };
}

export function deleteProspectActivitySuccess(): Action<any> {
  return {
    type: ActionTypes.DELETE_PROSPECT_ACTIVITY_SUCCESS,
    payload: undefined,
  };
}

export function deleteProspectActivityError(error: Error): Action<Error> {
  return {
    type: ActionTypes.DELETE_PROSPECT_ACTIVITY_ERROR,
    payload: error,
  };
}

export function updateProspect(
  prospect: Prospect,
  statusChange: StatusChange,
): Action<{ prospect: Prospect, statusChange: StatusChange }> {
  return {
    type: ActionTypes.UPDATE_PROSPECT,
    payload: { prospect, statusChange },
  };
}

export function assignProspect(prospect: Prospect): Action<Prospect> {
  return {
    type: ActionTypes.ASSIGN_PROSPECT,
    payload: prospect,
  };
}

export function updateProspectSuccess(): Action<any> {
  return {
    type: ActionTypes.UPDATE_PROSPECT_SUCCESS,
    payload: undefined,
  };
}

export function updateProspectError(error: Error): Action<Error> {
  return {
    type: ActionTypes.UPDATE_PROSPECT_ERROR,
    payload: error,
  };
}

export function updateColumnsSortValue(field: string, value: string) {
  return {
    type: ActionTypes.UPDATE_COLUMNS_SORT_VALUE,
    payload: { [field]: value },
  };
}

export function sendProspectPortalInviteEmail(prospectId: string): Action<any> {
  return {
    type: ActionTypes.SEND_PROSPECT_PORTAL_INVITE_EMAIL,
    payload: {
      prospectId,
    },
  };
}
