import {
  Card,
  Switch,
  Typography,
  Divider,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import useHasPermission from '../../../hooks/useHasPermission';
import type { FeatureFlags } from '../../../types';
import { formatCurrency, removeNonNumericCharacters } from '../../../utils';
import { formatDate, formatTime } from '../../../utils/date-helpers';
import { TextInput } from '../BasicDetails/TextInput';
import { CollectionsSetupSection } from './CollectionsSetup';
import { ASSET_PROTECT, InsuranceTypes } from './constants';
import { EditedByBlock } from './customStyles';
import { useFetchLatestChangeHistoryLog } from './hooks';
import { InsuranceFeeInput } from './InsuranceFeeInput';
import { confirmInsuranceModal } from './InsuranceModal';
import messages from './messages';
import TheWorkNumberSetup from './TheWorkNumberSetup';
import ConserviceSetup from './ConserviceSetup';
import MunibillingSetup from './MunibillingSetup';
import { getWorkNumberIntegrationFromList } from './utils';
import SetupGridItem from '../SetupGridItem';
import OnOffTypography from '../OnOffTypography';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

type Props = {
  propertyDetails: Object,
  editMode: boolean,
  onChange: Function,
  disabled: boolean,
  organization: Object,
  isLoading: boolean,
};

const InsuranceTypeOptions = (insuranceType: string) =>
  InsuranceTypes.map((insuranceType) => (
    <option key={`insurance-type-${insuranceType}`} value={insuranceType}>
      {insuranceType}
    </option>
  ));

const InsuranceTooltipMessage = () => (
  <Typography>
    <strong>
      <FormattedMessage {...messages.assetProtect} />:
    </strong>{' '}
    <FormattedMessage {...messages.assetProtectInfo} />
    <br />
    <br />
    <strong>
      <FormattedMessage {...messages.none} />:
    </strong>{' '}
    <FormattedMessage {...messages.noneInfo} />
  </Typography>
);

const IntegrationsSetup = (props: Props) => {
  const {
    organizationId,
    propertyId,
    propertyDetails,
    editMode: editModeProp,
    disabled,
    onChange,
    organization,
    intl,
    isLoading,
  } = props;
  const {
    isDomusoActive,
    domusoPropertyCode,
    transunionPropertyId,
    isTransUnionActive,
    propertyIntegrations,
    propertyClass,
    insuranceType,
    insuranceFeeAmount,
    propertyRiskManagement = [],
    propertyType,
  } = propertyDetails;

  const { integrations: organizationIntegrations } = organization;
  const [lastUpdatedPropertyRiskManagement] = propertyRiskManagement;

  const {
    theWorkNumber,
    riskMgmtAssetProtectAdmin,
    collectionsOneWay,
    collectionsPropertySetting,
    transunionOffLeaseAppOff,
    transunionCodeSave,
  }: FeatureFlags = useFlags();

  const hasInsuranceEnrollmentPermission = useHasPermission(
    'insurance-enrollment',
  );

  const {
    data: transUnionLatestChangeHistoryLog,
    isLoading: transUnionIsLoading,
    refetch: transUnionRefetch,
  } = useFetchLatestChangeHistoryLog(
    organizationId,
    propertyId,
    'isTransUnionActive',
    transunionOffLeaseAppOff,
  );

  const {
    data: transUnionCodeChangeHistoryLog,
    refetch: transUnionCodeRefetch,
    remove: transUnionCodeRemove,
  } = useFetchLatestChangeHistoryLog(
    organizationId,
    propertyId,
    'transunionPropertyId',
    transunionCodeSave,
  );

  useEffect(() => {
    if (transunionOffLeaseAppOff) {
      transUnionRefetch();
    }
    if (transunionCodeSave) {
      if (!transUnionCodeChangeHistoryLog) {
        transUnionCodeRemove();
      }
      transUnionCodeRefetch();
    }
    // eslint-disable-next-line
  }, [isTransUnionActive]);

  useEffect(() => {
    if (
      transunionCodeSave &&
      !isLoading &&
      !transunionPropertyId &&
      isTransUnionActive &&
      transUnionCodeChangeHistoryLog
    ) {
      onChange({
        target: {
          name: 'transunionPropertyId',
          value:
            transUnionCodeChangeHistoryLog.changeFromValue !== 'NULL'
              ? transUnionCodeChangeHistoryLog.changeFromValue
              : transUnionCodeChangeHistoryLog.changeToValue,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isTransUnionActive, transUnionCodeChangeHistoryLog]);

  // Parse the work number integrations from propertyDetails
  const propertyWorkNumberIntegration =
    getWorkNumberIntegrationFromList(propertyIntegrations);

  // Parse the work number integrations from organization
  const orgWorkNumberIntegration = getWorkNumberIntegrationFromList(
    organizationIntegrations,
  );

  const doesOrgHaveWorkNumberIntegration = !!orgWorkNumberIntegration;
  const isAffordableOrMixedProperty =
    propertyClass === 'Affordable' || propertyClass === 'Mixed';
  const isCommercialProperty = propertyType === 'Commercial';

  const editMode = editModeProp && !disabled;

  // This function helps to convert the new Switch data to match parent form utility function
  const handleSwitchChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.checked;
    onChange({ target: { name, value } });
  };
  const handleRadioGroupChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    onChange({ target: { name, value } });
  };

  const onInsuranceTypeChange = useCallback(
    (event) => {
      const { name, value } = event.target;

      confirmInsuranceModal(intl, value).then((modalValues) => {
        onChange({ target: { name, value } });

        onChange({
          target: {
            name: 'insuranceFeeAmount',
            value: modalValues.insuranceFee ?? null,
          },
        });
      });
    },
    [intl, onChange],
  );

  return (
    <Card sx={{ padding: 2, height: '100%' }}>
      <Typography variant={'h3'}>
        <FormattedMessage {...messages.integrationsSetup} />
      </Typography>

      <Divider sx={{ marginY: 2 }} />

      <Grid container spacing={2}>
        {/* GENERAL */}
        <Grid item xs={12}>
          <Typography variant={'formSubheading'}>
            <FormattedMessage {...messages.general} />
          </Typography>
        </Grid>
        <SetupGridItem label={<FormattedMessage {...messages.domuso} />}>
          {editMode ? (
            <Switch
              name="isDomusoActive"
              id="domuso-switch"
              checked={isDomusoActive}
              disabled={!editMode}
              onChange={handleSwitchChange}
              inputProps={{ 'data-checked': isDomusoActive }}
            />
          ) : (
            <OnOffTypography on={isDomusoActive}>
              {isDomusoActive ? (
                <FormattedMessage {...messages.on} />
              ) : (
                <FormattedMessage {...messages.off} />
              )}
            </OnOffTypography>
          )}
        </SetupGridItem>
        <MunibillingSetup
          onChange={onChange}
          orgIntegrations={organizationIntegrations}
          propertyIntegrations={propertyIntegrations}
          editMode={editMode}
        />
        <SetupGridItem
          label={
            <>
              <FormattedMessage {...messages.domusoPropertyCode} />*
            </>
          }
        >
          {editMode ? (
            <TextInput
              name="domusoPropertyCode"
              id="domusoPropertyCode"
              value={removeNonNumericCharacters(domusoPropertyCode)}
              editMode={editMode}
              onChange={onChange}
              disabled={!isDomusoActive}
              required={isDomusoActive}
              maxLength={5}
              minLength={4}
              validateFunction={(value) =>
                value.length === 4 || value.length === 5
              }
              message={messages.domusoCodeLength}
              invalidMessageStyles={{
                float: 'right',
                marginRight: '10%',
              }}
            />
          ) : (
            <Typography>{domusoPropertyCode || '---'}</Typography>
          )}
        </SetupGridItem>
        <ConserviceSetup
          onChange={onChange}
          orgIntegrations={organizationIntegrations}
          propertyIntegrations={propertyIntegrations}
          editMode={editMode}
        />
        {riskMgmtAssetProtectAdmin && !isCommercialProperty && (
          <>
            <SetupGridItem
              label={<FormattedMessage {...messages.insuranceEnrollment} />}
              TooltipProps={{
                title: <InsuranceTooltipMessage />,
                children: <AlertInfoIcon />,
              }}
            >
              {editMode ? (
                <select
                  name="insuranceType"
                  className="form-control"
                  value={insuranceType}
                  onChange={onInsuranceTypeChange}
                  disabled={!hasInsuranceEnrollmentPermission}
                >
                  <InsuranceTypeOptions />
                </select>
              ) : (
                insuranceType
              )}
            </SetupGridItem>
            {insuranceType === ASSET_PROTECT && (
              <SetupGridItem
                label={<FormattedMessage {...messages.assetProtectFee} />}
              >
                {editMode ? (
                  <InsuranceFeeInput
                    insuranceFeeAmount={insuranceFeeAmount}
                    onChange={onChange}
                    intl={intl}
                  />
                ) : (
                  formatCurrency(intl, insuranceFeeAmount)
                )}
              </SetupGridItem>
            )}
            {lastUpdatedPropertyRiskManagement && (
              <SetupGridItem>
                <EditedByBlock>
                  <FormattedMessage {...messages.editedBy} />{' '}
                  {lastUpdatedPropertyRiskManagement.createdBy.firstName}{' '}
                  {lastUpdatedPropertyRiskManagement.createdBy.lastName}{' '}
                  <FormattedMessage {...messages.on} />{' '}
                  {moment(lastUpdatedPropertyRiskManagement.createdAt).format(
                    'MMM DD, YYYY @ h:mm A',
                  )}
                </EditedByBlock>
              </SetupGridItem>
            )}
          </>
        )}

        {/* SCREENINGS */}
        <Grid item xs={12}>
          <Divider sx={{ marginY: 2 }} />
          <Typography variant={'formSubheading'}>
            <FormattedMessage {...messages.screenings} />
          </Typography>
        </Grid>

        <SetupGridItem label={<FormattedMessage {...messages.transunion} />}>
          {editMode ? (
            <Switch
              name="isTransUnionActive"
              id="trans-union-switch"
              checked={isTransUnionActive}
              disabled={!editMode}
              onChange={handleSwitchChange}
            />
          ) : isTransUnionActive ? (
            <FormattedMessage {...messages.on} />
          ) : (
            <FormattedMessage {...messages.off} />
          )}
        </SetupGridItem>
        {transunionOffLeaseAppOff && (
          <>
            {transUnionLatestChangeHistoryLog &&
              !transUnionIsLoading &&
              transUnionLatestChangeHistoryLog?.user && (
                <SetupGridItem>
                  <Typography>
                    {intl.formatMessage(messages.transUnionLogHistoryMsg, {
                      transUnionToggleState:
                        transUnionLatestChangeHistoryLog.changeToValue ===
                        'true'
                          ? 'On'
                          : 'Off',
                      // eslint-disable-next-line
                      userName: `${transUnionLatestChangeHistoryLog?.user?.firstName} ${transUnionLatestChangeHistoryLog?.user?.lastName}`,
                      formatDate: formatDate(
                        transUnionLatestChangeHistoryLog?.changeDate,
                        intl,
                      ),
                      formatTime: formatTime(
                        transUnionLatestChangeHistoryLog?.changeDate,
                        intl,
                      ),
                    })}
                  </Typography>
                </SetupGridItem>
              )}
          </>
        )}
        <SetupGridItem
          label={
            <>
              <FormattedMessage {...messages.transunionCode} />*
            </>
          }
        >
          {editMode ? (
            <TextInput
              name="transunionPropertyId"
              value={transunionPropertyId}
              editMode={editMode}
              onChange={onChange}
              disabled={!isTransUnionActive}
              required={isTransUnionActive}
            />
          ) : (
            <span>{transunionPropertyId || '---'}</span>
          )}
        </SetupGridItem>
        {theWorkNumber &&
          isAffordableOrMixedProperty &&
          doesOrgHaveWorkNumberIntegration && (
            <TheWorkNumberSetup
              onChange={onChange}
              orgIntegrationDetails={orgWorkNumberIntegration}
              propIntegrationDetails={propertyWorkNumberIntegration}
              editMode={editMode}
            />
          )}

        {collectionsOneWay && collectionsPropertySetting && (
          <CollectionsSetupSection
            {...props}
            onSwitchChange={handleSwitchChange}
            onRadioGroupChange={handleRadioGroupChange}
            editMode={editMode}
          />
        )}
      </Grid>
    </Card>
  );
};

export default IntegrationsSetup;
