import { defineMessages } from 'react-intl';

const messages = defineMessages({
  transactionType: {
    id: 'App.ProrateCalculatorDetails.TransactionType',
    defaultMessage: 'Transaction Type',
  },
  note: {
    id: 'App.ProrateCalculatorDetails.Note',
    defaultMessage: 'Note',
  },
  transactionCode: {
    id: 'App.ProrateCalculatorDetails.TransactionCode',
    defaultMessage: 'Transaction Code',
  },
  enterTotalAmount: {
    id: 'App.ProrateCalculatorDetails.EnterTotalAmount',
    defaultMessage: 'Enter Total Amount',
  },
  prorateAmount: {
    id: 'App.ProrateCalculatorDetails.prorateAmount',
    defaultMessage: 'Prorate Amount',
  },
  doNotProrate: {
    id: 'App.ProrateCalculatorDetails.DoNotProrate',
    defaultMessage: 'Do Not Prorate',
  },
  delete: {
    id: 'App.ProrateCalculatorDetails.Delete',
    defaultMessage: 'Delete',
  },
  addNewTransaction: {
    id: 'App.ProrateCalculatorDetails.AddNewRow',
    defaultMessage: '+ Add New Row',
  },
  totalProrateDue: {
    id: 'App.ProrateCalculatorDetails.totalProrateDue',
    defaultMessage: 'Total Prorate Due:',
  },
  errorMsg: {
    id: 'App.ProrateCalculatorDetails.ErrorMsg',
    defaultMessage:
      // eslint-disable-next-line max-len
      'One or more residents moved out since the batch was last created or edited. Please remove the highlighted transaction(s) in order to save or post.',
  },
});

export default messages;
