import type { Action } from '../App/types';
import { pathOr, pick } from 'ramda';
import * as ActionTypes from './constants';
import moment from 'moment';

const initialState: Object = {
  changeHouseholdInformation: {},
  studentInformation: {},
  employmentInformation: {},
  applicantsCurrentEmployer: {},
  applicantsSecondCurrentEmployer: {},
  applicantsPreviousEmployer: {},
};

const parseChangeHouseholdInfo = (payload: Object): Object => {
  const anticipateChangeHousehold = pathOr(
    false,
    ['anticipateChangeHousehold'],
    payload,
  );
  const anticipateChangeHouseholdAdoption = pathOr(
    0,
    ['anticipateChangeHouseholdAdoption'],
    payload,
  );
  const anticipateChangeHouseholdPregnancy = pathOr(
    0,
    ['anticipateChangeHouseholdPregnancy'],
    payload,
  );
  const anticipateChangeHouseholdFoster = pathOr(
    0,
    ['anticipateChangeHouseholdFoster'],
    payload,
  );

  const anticipateChangeHouseholdString = anticipateChangeHousehold
    ? 'yes'
    : 'no';

  return {
    anticipateChangeHousehold: anticipateChangeHouseholdString,
    anticipateChangeHouseholdAdoption,
    anticipateChangeHouseholdPregnancy,
    anticipateChangeHouseholdFoster,
  };
};

const parseStudentInfo = (payload: Object): Object => {
  const studentInformationObject = pick(
    [
      'isStudent',
      'studentType',
      'TANFAssistance',
      'jobTrainingProgram',
      'singleParentDependantChild',
      'marriedJointReturn',
      'fosterProgram',
      'otherStudentStatus',
    ],
    payload,
  );
  const studentKeys = Object.keys(studentInformationObject);
  const studentInformation = studentKeys.reduce((studentInfo, key) => {
    const value = studentInformationObject[key];
    return {
      ...studentInfo,
      [key]: value !== null ? `${value}` : null,
    };
  }, {});
  return studentInformation;
};

const parseEmployerInfo = (employerObject: Object): Object => {
  if (!employerObject) return {};
  const {
    employerStreetAddress,
    city,
    state,
    zipCode,
    position,
    annualSalary,
    from,
    to,
    employerName,
    employerPhone,
    supervisorName,
  } = employerObject;
  const address = {
    street: employerStreetAddress,
    city,
    state: state ? state : 'default',
    zipCode,
  };
  const employerInfo = {
    address,
    position,
    annualSalary,
    from: from ? moment(from) : null,
    to: to ? moment(to) : null,
    employerName,
    phoneNumber: employerPhone,
    supervisorName,
  };
  return employerInfo;
};

const primaryLeaseApplicationReducer = (
  state: Object = initialState,
  action: Action<*>,
): Object => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_RECERTIFICATION_FORM_INFORMATION_SUCCESS: {
      const {
        applicantsCurrentEmployer,
        applicantsSecondCurrentEmployer,
        applicantsPreviousEmployer,
        employmentStatusId,
        anticipateChangeEmployer,
        hasSignedCopy,
        isComplete,
        affordableQualification,
      } = payload;
      const yesNoDict = { true: 'yes', false: 'no' };
      const anticipateChangeEmployerString =
        anticipateChangeEmployer !== null
          ? yesNoDict[`${anticipateChangeEmployer}`]
          : null;
      const employmentInformation = {
        status: employmentStatusId,
        anticipateChangeEmployer: anticipateChangeEmployerString,
      };
      const studentInformation = parseStudentInfo(payload);
      const changeHouseholdInformation = parseChangeHouseholdInfo(payload);

      return {
        ...state,
        applicantsCurrentEmployer: parseEmployerInfo(applicantsCurrentEmployer),
        applicantsSecondCurrentEmployer: parseEmployerInfo(
          applicantsSecondCurrentEmployer,
        ),
        applicantsPreviousEmployer: parseEmployerInfo(
          applicantsPreviousEmployer,
        ),
        changeHouseholdInformation,
        studentInformation,
        employmentInformation,
        hasSignedCopy,
        isComplete,
        affordableQualificationCertificationType:
          affordableQualification?.certificationType,
      };
    }
    case ActionTypes.GET_RECERTIFICATION_FORM_INFORMATION_ERROR: {
      return {
        ...state,
        recertificationFormData: initialState.recertificationFormData,
      };
    }
    case ActionTypes.CLEAN_RECERTIFICATION_FORM_INFORMATION: {
      return initialState;
    }
    default:
      return state;
  }
};

export default primaryLeaseApplicationReducer;
