import moment from 'moment';
import {
  put,
  takeEvery,
  takeLatest,
  select,
  all,
  call,
} from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import { GET_ALL_LEASING_KPIS, GET_LEASING_KPI } from './constants';
import { getLeasingKpiSuccess, getAllLeasingKpisError } from './actions';
import KpiService from '../../services/kpiService';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';

function* fetchGetSingleKPI({ payload }: Object): Saga<void> {
  const { kpi, query, signal } = payload;
  const kpiWithQueryString = query ? `${kpi}${query}` : kpi;
  const organizationId = yield select(selectCurrentUserOrganizationId);
  const property = yield select(selectSelectedProperty);
  const kpiService = new KpiService();
  const response = yield kpiService.getKPI(
    organizationId,
    property.id,
    kpiWithQueryString,
    signal,
  );
  yield put(getLeasingKpiSuccess(kpi, response));
}

export function* getSingleKpi(): Saga<void> {
  yield takeLatest(GET_LEASING_KPI, fetchGetSingleKPI);
}

export function* fetchGetAllKpis({ payload: { signal } }: Object): Saga<void> {
  try {
    const property = yield select(selectSelectedProperty);
    if (property) {
      const kpis = [
        'prospectAndApplicationTrafficSources',
        'employeeActivity',
        'weekOverWeekActivity',
        'leasesOverview',
      ];
      const startDate = moment().subtract(29, 'days').format();
      const query = `?startDate=${moment(startDate).format()}`;
      yield all(
        kpis.map((kpi) =>
          call(fetchGetSingleKPI, { payload: { kpi, query, signal } }),
        ),
      );
    }
  } catch (err) {
    yield put(getAllLeasingKpisError(err));
  }
}

export function* getAllKpis(): Saga<void> {
  yield takeEvery(GET_ALL_LEASING_KPIS, fetchGetAllKpis);
}

export default [getAllKpis, getSingleKpi];
