const { get, patch } = require('../utils/api');

class MarketingSectionService {
  getProperty(organizationId: string, propertyId: string, options?: Object) {
    return get(`/${organizationId}/${propertyId}/marketing`, options);
  }

  update = (organizationId: string, propertyId: string, data: Object) => {
    return patch(
      `/${organizationId}/${propertyId}/marketing`,
      JSON.stringify(data),
    );
  };
}

export default MarketingSectionService;
