import { put, takeLatest, select, throttle } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';
import moment from 'moment';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';

import {
  GET_CUSTOMER_LEDGER_HEADER,
  GET_ALL_SUBJOURNALS,
  GET_CUSTOMER_OPS_TRANSACTIONS,
  GET_CUSTOMER_SECURITY_TRANSACTIONS,
  HANDLE_SOCKET_FINANCIAL_TRANSACTION_FAILURE,
  HANDLE_SOCKET_FINANCIAL_TRANSACTION_SUCCESS,
  REVERSE_TRANSACTION,
  REFRESH_LEDGER,
  UPDATE_PAYMENT_RESTRICTIONS,
  CREATE_APPLICANT_REFUND_STATEMENT,
} from './constants';
import {
  getCustomerLedgerHeader,
  getCustomerLedgerHeaderSuccess,
  getCustomerLedgerHeaderError,
  getAllSubjournalsSuccess,
  getAllSubjournalsError,
  getCustomerOpsTransactions,
  getCustomerOpsTransactionsSuccess,
  getCustomerOpsTransactionsError,
  getCustomerSecurityTransactions,
  getCustomerSecurityTransactionsSuccess,
  getCustomerSecurityTransactionsError,
  financialTransactionSuccess,
  refreshLedger as refreshLedgerAction,
  updatePaymentRestrictionsSuccess,
} from './actions';
import { getWriteOffsForCustomer } from '../App/actions.js';
import { getOneResident } from '../ResidentProfile/actions.js';
import { getOneApplication } from '../ApplicationProfile/actions';

import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
  selectCurrentLocationPath,
} from '../App/selectors';
import LedgerService from '../../services/ledgerService';
import TransactionService from '../../services/transactionsService';
import CustomersService from '../../services/customersService';
import PropertyService from '../../services/propertyService';
import type { Action, Property } from '../App/types';
import type { LedgerRequest } from './types';
import messages from './messages';
import ApplicantRefundStatementService from '../../services/applicantRefundStatementService';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetCustomerLedgerHeaderSaga({
  payload,
}: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const ledgerService = new LedgerService();
    const response = yield ledgerService.getLedgerHeader(
      organizationId,
      selectedProperty.id,
      payload,
    );
    yield put(getCustomerLedgerHeaderSuccess(response));
  } catch (err) {
    yield put(getCustomerLedgerHeaderError(err));
  }
}

export function* getCustomerLedgerHeaderSaga(): Saga<void> {
  yield takeLatest(
    GET_CUSTOMER_LEDGER_HEADER,
    fetchGetCustomerLedgerHeaderSaga,
  );
}

export function* fetchGetCustomerSubjournalsSaga({
  payload,
}: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const ledgerService = new LedgerService();
    const response = yield ledgerService.getAllSubjournals(
      organizationId,
      selectedProperty.id,
      payload,
    );
    yield put(getAllSubjournalsSuccess(response));
  } catch (err) {
    yield put(getAllSubjournalsError(err));
  }
}

export function* getCustomerSubjournalsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_SUBJOURNALS, fetchGetCustomerSubjournalsSaga);
}

export function* fetchGetOpsLedgerSaga({
  payload,
}: Action<LedgerRequest>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const ledgerService = new LedgerService();
    const response = yield ledgerService.getTransactions(
      organizationId,
      selectedProperty.id,
      payload.pageNumber,
      payload.ledger,
      payload.customerId,
      payload.filterReversed,
      payload.subjournal,
    );
    yield put(getCustomerOpsTransactionsSuccess(response));
  } catch (err) {
    yield put(getCustomerOpsTransactionsError(err));
  }
}

export function* getOpsLedgerSaga(): Saga<void> {
  yield takeLatest(GET_CUSTOMER_OPS_TRANSACTIONS, fetchGetOpsLedgerSaga);
}

export function* fetchGetSecurityLedgerSaga({
  payload,
}: Action<LedgerRequest>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const ledgerService = new LedgerService();
    const response = yield ledgerService.getTransactions(
      organizationId,
      selectedProperty.id,
      payload.pageNumber,
      payload.ledger,
      payload.customerId,
      payload.filterReversed,
    );
    yield put(getCustomerSecurityTransactionsSuccess(response));
  } catch (err) {
    yield put(getCustomerSecurityTransactionsError(err));
  }
}

export function* getSecurityLedgerSaga(): Saga<void> {
  yield takeLatest(
    GET_CUSTOMER_SECURITY_TRANSACTIONS,
    fetchGetSecurityLedgerSaga,
  );
}

export function* fetchReverseTransaction(action: Object): Saga<void> {
  try {
    const {
      transactionId,
      ledger,
      period,
      notes,
      customerId,
      residentId,
      filterReversed,
      isNsf,
      camAllocationsId,
    } = action.payload;
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const propertyId = selectedProperty.id;
    const currentRoutePath = yield select(selectCurrentLocationPath);

    const notificationAttributes = {
      currentRoutePath,
      customerId,
      filterReversed,
      residentId,
    };
    const transactionService = new TransactionService();
    const reversedTransaction = yield transactionService.reverseTransaction(
      organizationId,
      propertyId,
      transactionId,
      ledger,
      period,
      notes,
      notificationAttributes,
      camAllocationsId,
    );

    if (reversedTransaction && isNsf) {
      const propertyService = new PropertyService();
      const nsfFee = yield propertyService.getPropertyNsfFee(
        organizationId,
        propertyId,
      );
      const transaction = {
        prospectCustomerId: customerId,
        propertyTransactionCodeId: nsfFee.propertyTransactionCodeId,
        date: moment().toDate(),
        amount: nsfFee.amount,
        receivedFrom: reversedTransaction.receivedFrom,
        note: `Auto generated from payment ${reversedTransaction.documentNumber}`,
      };
      yield transactionService.create(
        organizationId,
        selectedProperty.id,
        transaction,
        notificationAttributes,
      );
    }

    if (reversedTransaction && !reversedTransaction.messageId) {
      yield put(refreshLedgerAction(customerId, filterReversed));
      yield put(
        toastrActions.add({
          type: 'success',
          message: renderTranslatedMessage(messages.successDescriptionReversal),
          title: renderTranslatedMessage(messages.successHeaderReversal),
          options: {
            showCloseButton: true,
            removeOnHover: true,
          },
        }),
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'info',
          message: renderTranslatedMessage(messages.pendingDescriptionReversal),
          title: renderTranslatedMessage(messages.pendingHeaderReversal),
          options: {
            showCloseButton: true,
            removeOnHover: true,
          },
        }),
      );
    }
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeaderReversal),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* handleSocketFinancialTransactionFailureSaga(): Saga<void> {
  yield throttle(
    500,
    HANDLE_SOCKET_FINANCIAL_TRANSACTION_FAILURE,
    handleSocketFinancialTransactionFailure,
  );
}

export function* handleSocketFinancialTransactionFailure(
  action: Action<any>,
): Saga<void> {
  const { error, title } = action.payload;
  yield put(
    toastrActions.add({
      type: 'error',
      message: error,
      title: title,
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    }),
  );
}

export function* handleSocketFinancialTransactionSuccessSaga(): Saga<void> {
  yield throttle(
    500,
    HANDLE_SOCKET_FINANCIAL_TRANSACTION_SUCCESS,
    handleSocketFinancialTransactionSuccess,
  );
}

export function* handleSocketFinancialTransactionSuccess(
  action: Action<any>,
): Saga<void> {
  const currentRoutePath = yield select(selectCurrentLocationPath);

  const {
    currentRoutePath: notificationRoutePath,
    customerId,
    filterReversed,
    residentId,
    message,
    title,
  } = action.payload;
  yield put(
    toastrActions.add({
      type: 'success',
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
      message,
      title,
    }),
  );
  if (notificationRoutePath === currentRoutePath) {
    yield put(financialTransactionSuccess(customerId));
    yield put(refreshLedgerAction(customerId, filterReversed));
    if (residentId) {
      yield put(getOneResident(residentId));
    }
  }
}

export function* reverseTransactionSaga(): Saga<void> {
  yield throttle(500, REVERSE_TRANSACTION, fetchReverseTransaction);
}

export function* refreshLedger(action: Object): Saga<void> {
  const { customerId, filterReversed } = action.payload;
  const ledgerState = yield select((state) => state.ledger);
  if (ledgerState.headerData.status === 'Prior Resident') {
    yield put(getWriteOffsForCustomer(customerId));
  }
  yield put(getCustomerLedgerHeader(customerId));
  yield put(getCustomerSecurityTransactions(1, customerId, filterReversed));
  yield put(
    getCustomerOpsTransactions(
      1,
      customerId,
      filterReversed,
      ledgerState.ops.meta.subjournalId,
    ),
  );
}

export function* takeLatestLedgerRefresh(): Saga<void> {
  yield takeLatest(REFRESH_LEDGER, refreshLedger);
}

export function* fetchUpdatePaymentRestrictions(
  action: Action<any>,
): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const customersService = new CustomersService();
    const { isResident, profileId, householdId, paymentRestrictions } =
      action.payload;

    yield customersService.updatePaymentRestrictions(
      organizationId,
      selectedProperty.id,
      householdId,
      paymentRestrictions,
    );
    yield put(
      updatePaymentRestrictionsSuccess(action.payload.paymentRestrictions),
    );
    const refreshDataAction = isResident ? getOneResident : getOneApplication;
    yield put(refreshDataAction(profileId));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.updatePaymentRestrictions),
        title: renderTranslatedMessage(messages.successHeaderReversal),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        message: err.toString(),
        type: 'error',
        title: renderTranslatedMessage(messages.errorHeaderReversal),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}
export function* updatePaymentRestrictionsSaga(): Saga<void> {
  yield throttle(
    500,
    UPDATE_PAYMENT_RESTRICTIONS,
    fetchUpdatePaymentRestrictions,
  );
}

export function* postCreateApplicantRefund(action: Action<any>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicantRefundStatementService =
      new ApplicantRefundStatementService();
    const currentRoutePath = yield select(selectCurrentLocationPath);
    const {
      applicationId,
      householdId,
      customerId,
      applicantRefundMailingAddress,
    } = action.payload;
    const notificationAttributes = {
      currentRoutePath,
      customerId,
      filterReversed: false,
    };
    yield applicantRefundStatementService.createApplicantRefundStatement(
      organizationId,
      selectedProperty.id,
      customerId,
      householdId,
      applicationId,
      applicantRefundMailingAddress,
      notificationAttributes,
    );
    if (!notificationAttributes) {
      yield put(
        toastrActions.add({
          type: 'success',
          message: renderTranslatedMessage(messages.applicantRefundStatement),
          title: renderTranslatedMessage(messages.successHeaderReversal),
          options: {
            showCloseButton: true,
            removeOnHover: true,
          },
        }),
      );
    }
  } catch (err) {
    yield put(
      toastrActions.add({
        message: err.toString(),
        type: 'error',
        title: renderTranslatedMessage(messages.errorHeaderReversal),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}
export function* postCreateApplicantRefundSaga(): Saga<void> {
  yield throttle(
    500,
    CREATE_APPLICANT_REFUND_STATEMENT,
    postCreateApplicantRefund,
  );
}

export default [
  getCustomerLedgerHeaderSaga,
  getCustomerSubjournalsSaga,
  getOpsLedgerSaga,
  getSecurityLedgerSaga,
  handleSocketFinancialTransactionFailureSaga,
  handleSocketFinancialTransactionSuccessSaga,
  reverseTransactionSaga,
  takeLatestLedgerRefresh,
  updatePaymentRestrictionsSaga,
  postCreateApplicantRefundSaga,
];
