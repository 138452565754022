import { useCallback } from 'react';
import { exportAndDownloadCSV } from '../../utils/csv-helpers';
import {
  INITIAL_CERT_TYPE,
  MOVE_IN_CERT_TYPE,
  RECERT_CERT_TYPE,
  GROSS_RENT_CERT_TYPE,
  OTHER_HUD,
  INTERIM_CERT_TYPE,
} from '../../constants/affordableCertificationTypes';

const useCSVExport = ({
  selectedCertificationType,
  filteredHeaders,
  rows,
  hasAnyFilters,
}: {
  selectedCertificationType: string,
  filteredHeaders: {
    id: string,
    label: string | { props: { defaultMessage: string } },
  }[],
  rows: { [headerId: string]: { variant: string, value: string | number } },
  hasAnyFilters: boolean,
}) => {
  const onCSVButtonClick = useCallback(() => {
    const fileName = {
      [RECERT_CERT_TYPE]: 'Manage Certifications - Recertifications.csv',
      [GROSS_RENT_CERT_TYPE]: 'Manage Certifications - Gross-Rent.csv',
      [MOVE_IN_CERT_TYPE]: 'Manage Certifications - Move-Ins.csv',
      [INITIAL_CERT_TYPE]: 'Manage Certifications - Initial.csv',
      [INTERIM_CERT_TYPE]: 'Manage Certifications - Interim.csv',
      [OTHER_HUD]: 'Manage Certifications - Other HUD.csv',
    }[selectedCertificationType];

    const excludedHeaders = {
      [GROSS_RENT_CERT_TYPE]: ['generate59a', 'action', 'checkbox'],
    }[selectedCertificationType];

    exportAndDownloadCSV({
      excludedHeaders,
      filteredHeaders,
      fileName,
      rows,
      hasAnyFilters,
    });
  }, [selectedCertificationType, filteredHeaders, rows, hasAnyFilters]);

  return { onCSVButtonClick };
};

export default useCSVExport;
