import React, { Component } from 'react';
import { Modal, Button, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import { FORM_NAME } from './constants';
import messages from './messages';
import { createMiscTransaction } from './actions';
import CreateMiscTransactionForm from './CreateMiscTransactionForm';

type AppState = {
  form: Object,
  actions: Object,
};

type Props = AppState & {
  transactionTypes: Array<Object>,
  transactionCodes: Array<Object>,
  propertyName: string,
  status: string,
  onClose: Function,
  show: boolean,
  chargeCodes: Array<Object>,
  paymentCodes: Array<Object>,
};

type State = {
  showCancelConfirmation: boolean,
};

export class CreateTransaction extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showCancelConfirmation: false,
    };
  }

  setCancelConfirmation = (visible: boolean) => {
    this.setState({
      showCancelConfirmation: visible,
    });
  };

  showConfirmation = () => this.setCancelConfirmation(true);

  hideConfirmation = () => this.setCancelConfirmation(false);

  handleClose = () => {
    const {
      form: { anyTouched },
      onClose,
    } = this.props;
    const { showCancelConfirmation } = this.state;
    if (anyTouched && !showCancelConfirmation) {
      this.showConfirmation();
      return;
    }
    this.hideConfirmation();
    if (onClose) onClose();
  };

  submit = (e: Object) => {
    e.preventDefault();
    const {
      actions: { submit },
    } = this.props;
    submit(FORM_NAME);
  };

  handleSubmit = () => {
    const {
      form: {
        values: {
          propertyTransactionCodeChargeId,
          amount,
          propertyTransactionCodePaymentId,
          documentNumber,
          note,
        },
      },
      actions: { createMiscTransaction },
      onClose,
    } = this.props;
    const transaction = {
      note,
      documentNumber,
      date: new Date(),
      amount,
      propertyTransactionCodeChargeId,
      propertyTransactionCodePaymentId,
    };
    createMiscTransaction(transaction);
    if (onClose) onClose();
  };

  renderCancelConfirmation = () => {
    return (
      <div className="modal-confirm">
        <h1>
          <FormattedMessage {...messages.cancelConfirmationHeader} />
        </h1>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="primary"
            className="pull-right"
            onClick={this.handleClose}
          >
            <FormattedMessage {...messages.yes} />
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="default"
            className="pull-left"
            onClick={this.hideConfirmation}
          >
            <FormattedMessage {...messages.no} />
          </Button>
        </Col>
      </div>
    );
  };

  render() {
    const {
      chargeCodes,
      paymentCodes,
      propertyName,
      status,
      show,
      form,
    } = this.props;
    const valid = form ? !form.syncErrors : false;
    return (
      <Modal
        className="transaction"
        bsSize="small"
        show={show}
        autoFocus
        onHide={this.handleClose}
      >
        <Modal.Header closeButton>
          <i className="icon et-money" />
          <h1>
            <FormattedMessage {...messages.title} />
          </h1>
          <small className="disp-inline padtop5">
            <FormattedMessage {...messages.allFieldsRequired} />
          </small>
        </Modal.Header>
        <Modal.Body>
          {this.state.showCancelConfirmation && this.renderCancelConfirmation()}
          <div
            className="scrollable-modal__content row"
            style={this.state.showCancelConfirmation ? { display: 'none' } : {}}
          >
            <div className="container-fluid">
              <CreateMiscTransactionForm
                chargeCodes={chargeCodes}
                paymentCodes={paymentCodes}
                propertyName={propertyName}
                status={status}
                onSubmit={this.handleSubmit}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          className={this.state.showCancelConfirmation ? 'disabled ' : ''}
        >
          <div className="row">
            <div className="col-xs-6">
              <Button
                type="button"
                bsStyle="default"
                className="pull-right"
                onClick={this.handleClose}
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
            </div>
            <div className="col-xs-6">
              <Button
                type="submit"
                onClick={this.submit}
                bsStyle="primary"
                className="pull-left"
                disabled={!valid}
              >
                <FormattedMessage {...messages.post} />
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.form[FORM_NAME],
});
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      submit,
      createMiscTransaction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTransaction);
