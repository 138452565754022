import { defineMessages } from 'react-intl';

/* eslint-disable max-len */
const messages = defineMessages({
  leaseBasicsLabel: {
    id: 'App.LeaseDataTab.ResidentHeader.LeaseBasicsLabel',
    defaultMessage: 'Lease Basics',
  },
  leaseRenewalButton: {
    id: 'App.LeaseDataTab.ResidentHeader.leaseRenewalButton',
    defaultMessage: 'Start Lease Renewal',
  },
  transferButton: {
    id: 'App.LeaseDataTab.ResidentHeader.transferButton',
    defaultMessage: 'Start Transfer',
  },
  transferButtonDisabledLabel: {
    id: 'App.LeaseDataTab.ResidentHeader.transferButtonDisabledLabel',
    defaultMessage: 'Must enter NTV to begin transfer',
  },
  cancelRenewalButton: {
    id: 'App.LeaseDataTab.ResidentHeader.CancelRenewalButton',
    defaultMessage: 'Cancel Renewal',
  },
  completeRenewalButton: {
    id: 'App.LeaseDataTab.ResidentHeader.CompleteRenewalButton',
    defaultMessage: 'Complete Renewal',
  },
  cancelRenewalConfirmationHeader: {
    id: 'App.Commercial.LDT.ResidentHeader.CancelRenewalConfirmationHeader',
    defaultMessage: 'Are you sure you want to cancel this renewal?',
  },
  cancelRenewalConfirmationBody: {
    id: 'App.Commercial.LDT.ResidentHeader.CancelRenewalConfirmationBody',
    defaultMessage: 'Canceling this renewal will:',
  },
  cancelRenewalConfirmationBody1: {
    id: 'App.Commercial.LDT.ResidentHeader.CancelRenewalConfirmationBody1',
    defaultMessage: '1. Remove all lease information from the Lease Data tab',
  },
  cancelRenewalConfirmationBody2: {
    id: 'App.Commercial.LDT.ResidentHeader.CancelRenewalConfirmationBody2',
    defaultMessage: '2. Remove the renewal lease from the Documents tab and',
  },
  cancelRenewalConfirmationBody3: {
    id: 'App.Commercial.LDT.ResidentHeader.CancelRenewalConfirmationBody3',
    defaultMessage:
      '3. Reverse prorated charges and re-post the first of monthly charges to the ledger for the current lease, if applicable.',
  },
  cancelTransferConfirmationHeader: {
    id: 'App.ResidentProfile.Snapshot.cancelTransferConfirmationHeader',
    defaultMessage: 'Are you sure you want to cancel the Transfer?',
  },
  cancelTransferConfirmationBody: {
    id: 'App.LeaseDataTab.ResidentHeader.CancelTransferConfirmationBody',
    defaultMessage:
      'Canceling this transfer will remove all lease information in the Lease Data Tab, release the unit for other applicants, and remove the transfer lease from Document Tab, if applicable.',
  },
  cancelTransferButton: {
    id: 'App.LeaseDataTab.ResidentHeader.CancelTransferButton',
    defaultMessage: 'Cancel Transfer',
  },
  completeTransferButton: {
    id: 'App.LeaseDataTab.ResidentHeader.CompleteTransferButton',
    defaultMessage: 'Complete Transfer',
  },
  completeDisabled: {
    id: 'App.LeaseDataTab.ResidentHeader.completeDisabled',
    defaultMessage: 'Lease is missing required fields',
  },
  completeDisabledDirty: {
    id: 'App.LeaseDataTab.ResidentHeader.completeDisabledDirty',
    defaultMessage: 'Must save changes to complete',
  },
  completeDisabledDoNotRenew: {
    id: 'App.LeaseDataTab.ResidentHeader.completeDisabledDoNotRenew',
    defaultMessage: '"Do Not Renew" is selected',
  },
  completeDisabledUnderEviction: {
    id: 'App.LeaseDataTab.ResidentHeader.completeDisabledUnderEviction',
    defaultMessage: '"Under Eviction" is selected.',
  },
  completeDisabledRenewalAlreadyCompleted: {
    id: 'App.LeaseDataTab.ResidentHeader.completeDisabledRenewalAlreadyCompleted',
    defaultMessage: 'Renewal lease is already completed.',
  },
  completeDisabledTransferAlreadyCompleted: {
    id: 'App.LeaseDataTab.ResidentHeader.completeDisabledTransferAlreadyCompleted',
    defaultMessage: 'Transfer is done.',
  },
  headsUp: {
    id: 'App.LeaseDataTab.ResidentHeader.headsUp',
    defaultMessage: 'Heads Up!',
  },
  confirmationMessage: {
    id: 'App.LeaseDataTab.ResidentHeader.confirmationMessage',
    defaultMessage:
      'This household is on Notice to Vacate. Starting a lease renewal will not impact the notice to vacate. Are you sure you want to start this lease renewal?',
  },
  openQualificationTooltip: {
    id: 'App.LeaseDataTab.ResidentHeader.OpenQualificationTooltip',
    defaultMessage:
      'There is an open cert, complete or cancel the open cert in order to enable this button',
  },
  openGrossRentCertificationTooltip: {
    id: 'App.LeaseDataTab.ResidentHeader.OpenGrossRentCertificationTooltip',
    defaultMessage:
      'There is an open Gross Rent cert, complete or cancel the Gross Rent cert in order to enable this button',
  },
});

export default messages;
