import moment from 'moment';
import { isNil, pathOr } from 'ramda';

export function getMoveInProratedTransactions(
  monthlyTransactions: Array<Object>,
  startDate: any,
  moveInApplyFullAmountToProRate: boolean,
  checkForCurrentMonth?: boolean = false,
  roundProratedRents: boolean,
  correctProrateOnMoveinScreenFlag: boolean,
): Array<Object> {
  const startsThisMonth = (transactionStart: any) =>
    moment(transactionStart).isBefore(moment(startDate).endOf('month'));
  if (!startDate) {
    return [];
  }
  const startDateOnCurrentMonth =
    moment(startDate).isSameOrAfter(moment().startOf('month')) &&
    moment(startDate).isSameOrBefore(moment().endOf('month'));
  /**
   * When checking for current month (Commercial)
   * If the start date is before the start of current month, do not generate prorates
   * */
  if (checkForCurrentMonth && !startDateOnCurrentMonth) {
    return [];
  }
  // Function to check if a monthly transaction is no longer in use (same start/end date)
  const isDeleted = (transactionStart: any, transactionEnd: any) =>
    moment(transactionStart).isSame(moment(transactionEnd));

  // Function to check if the monthly transaction already ended
  const isNotPast = (transactionEnd: any) =>
    isNil(transactionEnd) || moment(transactionEnd).isAfter(moment(startDate));

  if (!isNil(startDate) && !isNil(monthlyTransactions)) {
    // Filter all the monthly transactions that are eligible to prorate
    const applicableTransactions = monthlyTransactions.filter(
      (mt) =>
        startsThisMonth(mt.startDate) &&
        !isDeleted(mt.startDate, mt.endDate) &&
        isNotPast(mt.endDate),
    );
    return applicableTransactions.map((monthlyTransaction: Object) => {
      const { amount, propertyTransactionCodeId } = monthlyTransaction;

      const rawProratedAmount = moveInApplyFullAmountToProRate
        ? amount
        : getRemainingMonthProratedAmount(startDate, amount);

      const priorMonthsAmount = getElapsedFullMonthsProratedAmount(
        startDate,
        amount,
      );
      let proratedAmount = 0;
      if (!correctProrateOnMoveinScreenFlag) {
        proratedAmount = Number(priorMonthsAmount + rawProratedAmount).toFixed(
          2,
        );
      } else {
        if (moment(startDate).isSameOrAfter(moment())) {
          proratedAmount = Number(rawProratedAmount).toFixed(2);
        } else if (moment(startDate).isBefore(moment())) {
          proratedAmount = Number(
            priorMonthsAmount + rawProratedAmount,
          ).toFixed(2);
        }
      }
      const code = pathOr(
        '',
        ['propertyTransactionCode', 'transactionCode', 'code'],
        monthlyTransaction,
      );
      const description = pathOr(
        '',
        ['propertyTransactionCode', 'transactionCode', 'description'],
        monthlyTransaction,
      );
      const name = pathOr(
        '',
        [
          'propertyTransactionCode',
          'transactionCode',
          'transactionType',
          'name',
        ],
        monthlyTransaction,
      );

      return {
        proratedAmount: roundProratedRents
          ? Math.round(proratedAmount)
          : proratedAmount,
        amount,
        propertyTransactionCodeId,
        code,
        description,
        name,
      };
    });
  }
  return [];
}

export function getMoveOutProratedTransactions(
  monthlyTransactions: Array<Object>,
  moveOutDate: any,
  moveInApplyFullAmountToProRate: boolean,
  roundProratedRents: boolean,
) {
  const monthlyProRatedTransactions = monthlyTransactions
    ? monthlyTransactions.reduce((prev, monthlyTransaction) => {
        const isNotStartOfMonthMoveOutProratedTransaction =
          !monthlyTransaction.isMoveOutProrate;
        const startDate = moment(monthlyTransaction.startDate);
        const firstDayOfMoveOutMonth = moment(moveOutDate).startOf('month');
        const startDateIsOnOrBeforeFirstDayOfMoveOutMonth =
          startDate.isSameOrBefore(firstDayOfMoveOutMonth);
        const endDateIsOnOrAfterFirstDayOfMoveOutMonthOrNull =
          moment(monthlyTransaction.endDate).isSameOrAfter(
            firstDayOfMoveOutMonth,
          ) || !monthlyTransaction.endDate;

        if (
          startDateIsOnOrBeforeFirstDayOfMoveOutMonth &&
          endDateIsOnOrAfterFirstDayOfMoveOutMonthOrNull &&
          isNotStartOfMonthMoveOutProratedTransaction
        ) {
          const hasProRated =
            monthlyTransaction.propertyTransactionCode.proRate;
          if (!hasProRated) {
            return prev;
          }
          const id = monthlyTransaction.id;
          const amount = pathOr(0, ['amount'], monthlyTransaction);
          const code = pathOr(
            '',
            ['propertyTransactionCode', 'proRate', 'transactionCode', 'code'],
            monthlyTransaction,
          );
          const description = pathOr(
            '',
            [
              'propertyTransactionCode',
              'proRate',
              'transactionCode',
              'description',
            ],
            monthlyTransaction,
          );

          const name = pathOr(
            '',
            [
              'propertyTransactionCode',
              'proRate',
              'transactionCode',
              'tt',
              'name',
            ],
            monthlyTransaction,
          );
          const propertyTransactionCodeId = pathOr(
            '',
            ['propertyTransactionCode', 'proRatePropertyTransactionCodeId'],
            monthlyTransaction,
          );
          const proratedAmount = getRemainingMonthProratedCredAmount(
            moveOutDate,
            amount,
          );
          const priorMonthsCreditAmount = getElapsedFullMonthsProratedAmount(
            moveOutDate,
            amount,
          );
          const fullProrateAmount = proratedAmount + priorMonthsCreditAmount;
          const toFixedProratedAmount = Number(fullProrateAmount.toFixed(2));
          const finalProratedAmount = roundProratedRents
            ? Math.round(toFixedProratedAmount)
            : toFixedProratedAmount;
          prev.push({
            proratedAmount: finalProratedAmount,
            monthlyTransactionId: id,
            amount,
            code,
            description,
            name,
            propertyTransactionCodeId,
          });
        }
        return prev;
      }, [])
    : [];
  return monthlyProRatedTransactions;
}

export function getRemainingMonthProratedCredAmount(moveOutDate, amount) {
  // Compute the prorated amount to credit based on the number
  // of calendar days that are missing of the move out month.
  const daysInMonth = moment(moveOutDate).daysInMonth();
  const lastDayOfMoveOutMonth = moment(moveOutDate).add(1, 'months').date(0);
  return (
    parseFloat(amount) *
    (lastDayOfMoveOutMonth.diff(moveOutDate, 'days') / daysInMonth)
  );
}

export function getElapsedMonthProratedAmount(moveOutDate, amount) {
  // Compute the prorated amount to charge based on the number
  // of calendar days that have elapsed from the move out month.
  const moveOutDateFormatted = moment(moveOutDate);
  const daysInMonth = moment(moveOutDate).daysInMonth();
  const daysDiff = moveOutDateFormatted.date();
  return (amount / daysInMonth) * daysDiff;
}

export function getRemainingMonthProratedAmount(moveInDate, amount: number) {
  // Compute the prorated amount to pay based on the number
  // of calendar days that remain of the move in month.
  const moveInDateFormatted = moment(moveInDate);
  const daysInMonth = moment(moveInDate).daysInMonth();
  const daysDiff = daysInMonth - moveInDateFormatted.date() + 1;
  return (daysDiff * amount) / daysInMonth;
}

function getElapsedFullMonthsProratedAmount(prorateDate, amount: number) {
  // Compute how many months have passed from the given date
  // to the current date, and return how many "first of month" charges should be added.
  const proratedDateFormatted = moment(prorateDate).startOf('month');
  const monthsDifference = moment()
    .startOf('month')
    .diff(proratedDateFormatted, 'months', true);
  const monthsDiff = Math.ceil(monthsDifference);
  return monthsDiff * amount;
}
