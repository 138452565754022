import type {
  Action,
  PaginationMeta,
  OrderValue,
  ErrorAction,
  WorkOrder
} from '../App/types';
import {
  GET_ALL_PRIOR_RESIDENTS,
  GET_ALL_PRIOR_RESIDENTS_SUCCESS,
  GET_ALL_PRIOR_RESIDENTS_ERROR,
  UPDATE_COLUMNS_SORT_VALUE,
  CLEAN_LOADED_PRIOR_RESIDENTS
} from './constants';

export function getAllPriorResidents(
  pageNumber: number,
  limit: number,
  sorting: OrderValue,
  searchText: string
): Action<Object> {
  return {
    type: GET_ALL_PRIOR_RESIDENTS,
    payload: {
      sorting,
      searchText,
      paginationInfo: {
        pageNumber,
        limit
      }
    }
  };
}

export function getAllPriorResidentsSuccess(
  workOrders: Array<WorkOrder>,
  meta: PaginationMeta
): Action<Array<WorkOrder>, PaginationMeta> {
  return {
    type: GET_ALL_PRIOR_RESIDENTS_SUCCESS,
    payload: workOrders,
    meta
  };
}

export function getAllPriorResidentsError(error: Error): ErrorAction {
  return {
    type: GET_ALL_PRIOR_RESIDENTS_ERROR,
    error
  };
}

export function updateColumnsSortValue(field: string, value: string) {
  return {
    type: UPDATE_COLUMNS_SORT_VALUE,
    payload: { [field]: value }
  };
}

export function cleanLoadedPriorResidents(): Action<any> {
  return {
    type: CLEAN_LOADED_PRIOR_RESIDENTS,
    payload: undefined
  };
}
