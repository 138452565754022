import { validateRequired } from '../../../utils/validations';
import formMessages from './messages';

const warn = (values: any, { intl }: Object) => {
  const warnings = {};

  [
    'moveInDate',
    'startDate',
    'lateMethodId',
    'leaseTermId',
    'numberOfKeys',
    'leasedRent',
  ].forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      warnings[fieldName] = intl.formatMessage(
        formMessages.requiredForGenerateLease,
      );
    }
  });
  return warnings;
};

export default warn;
