import { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import PropertyFeesService from '../../services/propertyFeesService';
import PropertyTransactionCodesService from '../../services/propertyTransactionCodesService';
import UnitFeesService from '../../services/unitFeesService';
import { useUnitOptions } from './hooks';
import Layout from './Layout';
import useFormatMessage from '../../hooks/useFormatMessage';
import messages from './messages';

function AddAmenity({
  history,
  match: {
    params: { propertyId },
  },
}) {
  const formatMessage = useFormatMessage();
  const selectedProperty = useSelectedProperty();
  const { isLoading, unitOptions } = useUnitOptions();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const createPropertyFee = async (data: Object, amenityPtcId: string) => {
    try {
      const pfService = new PropertyFeesService();

      return await pfService.create({
        propertyId: selectedProperty.id,
        organizationId: selectedProperty.organizationId,
        propertyTransactionCodeId: amenityPtcId,
        feeType: 'Amenity',
        isForAllUnits: false,
        displayAsOptionalFeeOnQuote: false,
        isBasicLeaseFee: false,
        isOneTimeCharge: false,
        includeInMarketRentCalculation: true,
        feeAmount: data.feeAmount,
        startDate: new Date(),
        displayNameOnQuote: data.amenityName,
      });
    } catch (e) {
      toastr.error(
        formatMessage(messages.error),
        formatMessage(messages.amenityCreatedError),
      );
    }
  };

  const assignPropertyFee = async (
    data: Object,
    createdPropertyFeeId: string,
    amenityPtcId: string,
  ) => {
    try {
      const unitFeesToCreate = data.selectedUnitIds.map((unitId) => ({
        propertyId: selectedProperty.id,
        unitId,
        propertyTransactionCodeId: amenityPtcId,
        propertyFeeId: createdPropertyFeeId,
        startDate: new Date(),
        endDate: null,
        feeAmount: data.feeAmount,
      }));
      const ufService = new UnitFeesService();

      return ufService.batchCreate(unitFeesToCreate);
    } catch (error) {
      toastr.error(
        formatMessage(messages.error),
        formatMessage(messages.assignUnitErrorOnCreate),
      );
    }
  };

  const handleSubmit = async (data) => {
    setIsSubmitting(true);

    const ptcService = new PropertyTransactionCodesService();

    const amenityPtc = await ptcService.getByMasterTransactionCode(
      selectedProperty.id,
      'AMENITY',
    );

    const createdPropertyFee = await createPropertyFee(data, amenityPtc.id);

    if (!createdPropertyFee) {
      setIsSubmitting(false);
      return;
    }

    if (data.selectedUnitIds.length === 0) {
      toastr.success(
        formatMessage(messages.success),
        formatMessage(messages.amenityCreatedSuccess),
      );
      history.push(`/property/${propertyId}/manage-amenities`);
      return;
    }

    const unitFees = await assignPropertyFee(
      data,
      createdPropertyFee.id,
      amenityPtc.id,
    );

    if (unitFees !== undefined)
      toastr.success(
        formatMessage(messages.success),
        formatMessage(messages.amenityCreatedSuccess),
      );

    history.push(`/property/${propertyId}/manage-amenities`);
  };

  return (
    <Layout
      isLoading={isSubmitting}
      amenityFormProps={{
        isUnitOptionsReady: !isLoading,
        unitOptions: unitOptions,
        onSubmit: handleSubmit,
        history,
      }}
    />
  );
}

export default AddAmenity;
