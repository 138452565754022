import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageApplications.Title',
    defaultMessage: 'Fortress - Manage applications'
  },
  chooseOption: {
    id: 'App.ManageApplications.ChooseAnOption',
    defaultMessage: 'Choose an option'
  },
  successEditActivityDescription: {
    id: 'App.ManageApplications.SuccessEditActivityDescription',
    defaultMessage: 'The activity was updated.'
  },
  successDeleteActivityDescription: {
    id: 'App.ManageApplications.SuccessDeleteActivityDescription',
    defaultMessage: 'The activity was deleted.'
  },
  errorHeader: {
    id: 'App.ManageApplications.ErrorHeader',
    defaultMessage: 'Error performing action'
  },
  successHeader: {
    id: 'App.ManageApplications.SuccessHeader',
    defaultMessage: 'Success'
  },
  successActivityDescription: {
    id: 'App.ManageApplications.SuccessActivityDescription',
    defaultMessage: 'The activity was added.'
  }
});

export default messages;
