import React from 'react';
import { connect } from 'react-redux';
import { isDirty } from 'redux-form';
import { Row } from 'react-bootstrap';

import * as leaseBasicsConstants from '../constants';

import RenewalButtons from './RenewalButtons';
import ResidentButtons from './ResidentButtons';

export type NewLeaseButtonsProps = {
  selectedLease: Object,
  isResident: boolean,
  residentId: string,
  applicationId: string,
  isDisabledRenewal: boolean,
  submittingRenewal: boolean,
  handleLeaseRenewal: Function,
  handleRemoveLease: Function,
  navigationHistory: any,
  doNotRenew: boolean,
  canComplete: boolean,
  underEviction: boolean,
  startRenewalMessage?: string,
  leaseBasicsIsDirty: boolean,
};

function NewLeaseButtons({
  selectedLease,
  isResident,
  residentId,
  applicationId,
  isDisabledRenewal,
  handleLeaseRenewal,
  handleRemoveLease,
  submittingRenewal,
  navigationHistory,
  doNotRenew,
  canComplete,
  underEviction,
  startRenewalMessage,
  leaseBasicsIsDirty,
  noticeToVacate,
}: any) {
  const showResidentButtons =
    isResident && !selectedLease.isRenewal && !selectedLease.isTransfer;
  const showRenewalButtons = isResident && selectedLease.isRenewal;
  return (
    <React.Fragment>
      <Row>
        {showRenewalButtons && (
          <RenewalButtons
            applicationId={applicationId}
            doNotRenew={doNotRenew}
            history={navigationHistory}
            lease={selectedLease}
            removeLease={handleRemoveLease}
            residentId={residentId}
            selectPreviousLease={() => {}} // not  necessary for now
            canComplete={canComplete}
            underEviction={underEviction}
            formDirty={leaseBasicsIsDirty}
          />
        )}
        {showResidentButtons && (
          <ResidentButtons
            isDisabledRenewal={isDisabledRenewal}
            leaseRenewalHandler={handleLeaseRenewal}
            submittingRenewal={submittingRenewal}
            startRenewalMessage={startRenewalMessage}
            noticeToVacate={noticeToVacate}
          />
        )}
      </Row>
    </React.Fragment>
  );
}

const mapStateToProps = (state: any): any => ({
  leaseBasicsIsDirty: isDirty(leaseBasicsConstants.FORM_NAME)(state),
});

export default connect(mapStateToProps)(NewLeaseButtons);
