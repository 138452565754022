import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import messages from './messages';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import CreateSubsidyBatchHeader from './CreateSubsidyBatchHeader';
import CreateSubsidyBatchDetails from './CreateSubsidyBatchDetails';
import {
  useFetchSubsidyPaymentBatch,
  useSaveSubsidyPaymentBatchHeader,
  useGetHouseholdsWithSubjournalBalance,
  useSaveSubsidyPaymentBatchDetail,
  useGetPropertySubsidyBatchTransactionCode,
  useGetAllHousehold,
  useDeletePaymentBatchDetail,
  usePostAndDepositSubsidyBatch,
  useUpdateBatchHeader,
  useDeleteSubsidyPaymentBatch,
} from './hooks';
import { bindActionCreators } from 'redux';
import { promptToaster } from '../../containers/App/actions';
import { useFetchOpenFiscalPeriodForProperty } from '../App/hooks';
import { calculateCurrentSubsidyBalance } from './utils';

type Props = {
  history: object,
  intl: any,
  propertyId: string,
  organizationId: string,
  subsidyPaymentBatchHeaderId: string,
  actions: Object,
};

export const CreateSubsidyBatch = (props: Props) => {
  const {
    intl,
    history,
    propertyId,
    organizationId,
    subsidyPaymentBatchHeaderId,
    actions,
  } = props;
  const [isSaved, setIsSaved] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const goBack = () => history.goBack();
  const [batch, refresh] = useFetchSubsidyPaymentBatch({
    propertyId,
    organizationId,
    subsidyPaymentBatchHeaderId,
    promptToaster: actions.promptToaster,
    setIsSaved,
    setInitialValues,
    history,
  });
  const [createSubsidyBatchHeader, isSaveHeaderLoading] =
    useSaveSubsidyPaymentBatchHeader({
      propertyId,
      organizationId,
      promptToaster: actions.promptToaster,
      setIsSaved,
      history,
    });
  const [updateSubsidyBatchHeader, isUpdateHeaderLoading] =
    useUpdateBatchHeader({
      propertyId,
      organizationId,
      promptToaster: actions.promptToaster,
    });

  const [householdsWithSubjournalBalance, isSubjournalBalanceLoading] =
    useGetHouseholdsWithSubjournalBalance({
      propertyId,
      organizationId,
      promptToaster: actions.promptToaster,
    });

  const [transactionCodes, isPtcLoading] =
    useGetPropertySubsidyBatchTransactionCode({ propertyId, organizationId });

  const [deletePaymentBatchDetail, isDeleteLoading] =
    useDeletePaymentBatchDetail({
      organizationId,
      propertyId,
      promptToaster: actions.promptToaster,
      refresh,
    });
  const [deleteSubsidyPaymentBatch, isDeletePaymentBatchLoading] =
    useDeleteSubsidyPaymentBatch({
      organizationId,
      propertyId,
      promptToaster: actions.promptToaster,
      history,
    });

  const batchId = batch?.header?.name ?? 'Unsaved Batch';
  const [fiscalPeriod, isFiscalPeriodLoading] =
    useFetchOpenFiscalPeriodForProperty({
      propertyId,
      organizationId,
    });

  const [saveSubsidyPaymentBatchDetail] = useSaveSubsidyPaymentBatchDetail({
    organizationId,
    propertyId,
    promptToaster: actions.promptToaster,
    refresh,
  });
  const [households, isHouseholdsLoading] = useGetAllHousehold({
    organizationId,
    propertyId,
  });
  const [postAndDepositSubsidyBatch] = usePostAndDepositSubsidyBatch({
    organizationId,
    propertyId,
    promptToaster: actions.promptToaster,
    history,
  });
  const period = fiscalPeriod?.period ?? null;
  const isLoading =
    isFiscalPeriodLoading ||
    isPtcLoading ||
    isHouseholdsLoading ||
    isDeleteLoading ||
    isSubjournalBalanceLoading;
  const householdsTransactions = batch?.transactions ?? [];
  const batchHeader = batch?.header ?? null;
  const notInitialLoad =
    householdsTransactions &&
    householdsTransactions.find((o) => o.transactionAmount !== 0);
  const initialValuesSubsidyBatch =
    householdsTransactions &&
    householdsTransactions.reduce((initialValues, row) => {
      const id = row?.id ?? '';
      const depositAmount = row?.transactionAmount ?? '';
      const note = row?.transactionNote ?? '';
      const status = row?.householdStatus ?? '';
      const resident = row?.receivedFromHouseholdMember ?? '';
      const unit = row?.unit?.number ?? '';
      const household = `${unit}${unit && '-'}${resident}`;
      const result = {
        depositAmount: !notInitialLoad ? '' : depositAmount,
        note,
        status,
        household,
      };
      initialValues[id] = { ...result };
      return initialValues;
    }, {});

  const householdArray = [];
  const householdWithSubjournalArray =
    householdsTransactions &&
    householdsTransactions
      .filter((transaction, index) => {
        const householdWithSubjournalBalance =
          householdsWithSubjournalBalance &&
          householdsWithSubjournalBalance.filter(
            (row) => transaction.householdId === row.householdId,
          );
        const subjournalRunningBalance = !transaction.isManual
          ? householdsWithSubjournalBalance
            ? householdWithSubjournalBalance[0]?.subjournalRunningBalance ?? 0
            : 0
          : calculateCurrentSubsidyBalance(
              householdArray,
              transaction.householdId,
              notInitialLoad,
            );
        const depositAmount = transaction?.transactionAmount ?? 0;
        const finalSubsidyBalance = !notInitialLoad
          ? 0
          : subjournalRunningBalance - depositAmount;
        const householdStatus = transaction?.householdStatus ?? '';
        const rowWithSubjournalBalance = {
          ...transaction,
          subjournalRunningBalance,
          finalSubsidyBalance,
        };
        householdArray.push(rowWithSubjournalBalance);
        if (
          (householdStatus === 'Current Resident' && !transaction.isManual) ||
          transaction.isManual
        ) {
          return true;
        }
        return false;
      })
      .map((transaction) => {
        const householdWithSubjournalBalance =
          householdArray &&
          householdArray.filter((row) => transaction.id === row.id);
        return householdWithSubjournalBalance[0];
      })
      .sort((a, b) =>
        (a?.unit?.order && b?.unit?.order
          ? a?.unit?.order < b?.unit?.order
          : (a?.unit?.number ?? '').localeCompare(b?.unit?.number ?? '')) &&
        !a.isManual &&
        !b.isManual
          ? -1
          : 1,
      );
  const [editHeader, setEditHeader] = useState(false);
  return (
    <React.Fragment>
      <DocumentTitle title={intl.formatMessage(messages.createSubsidyBatch)}>
        <div className="bodywrap" data-test="wrap">
          <CreateSubsidyBatchHeader
            period={period}
            isLoading={isLoading || isUpdateHeaderLoading}
            batchId={batchId}
            isSaved={isSaved}
            goBack={goBack}
            onSubmit={(value) => {
              createSubsidyBatchHeader(value);
            }}
            initialValues={initialValues}
            isSaveHeaderLoading={isSaveHeaderLoading}
            batch={batch}
            editHeader={editHeader}
            setEditHeader={setEditHeader}
            updateSubsidyBatchHeader={updateSubsidyBatchHeader}
          />
          <CreateSubsidyBatchDetails
            intl={intl}
            isSaved={isSaved}
            householdsWithSubjournalBalance={householdWithSubjournalArray}
            saveSubsidyPaymentBatchDetail={saveSubsidyPaymentBatchDetail}
            transactionCodes={transactionCodes}
            history={history}
            initialValues={initialValuesSubsidyBatch}
            isLoading={isLoading}
            notInitialLoad={notInitialLoad}
            households={households}
            batchHeader={batchHeader}
            deletePaymentBatchDetail={deletePaymentBatchDetail}
            postAndDepositSubsidyBatch={postAndDepositSubsidyBatch}
            promptToaster={actions.promptToaster}
            selectedPropertyId={propertyId}
            selectedOrganizationId={organizationId}
            deleteSubsidyPaymentBatch={deleteSubsidyPaymentBatch}
            isDeletePaymentBatchLoading={isDeletePaymentBatchLoading}
          />
        </div>
      </DocumentTitle>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch: any): Object => {
  const actions = bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  );
  return { actions };
};

const InjectedManageSubsidyPayments = injectIntl(CreateSubsidyBatch);
export const mapStateToProps = (
  { app: { currentUser, selectedProperty } }: any,
  ownProps: Object,
): any => {
  return {
    organizationId: currentUser.user.organizationId,
    propertyId: selectedProperty.id,
    subsidyPaymentBatchHeaderId: ownProps.match.params.paymentBatchHeaderId,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedManageSubsidyPayments);
