import { defineMessages } from 'react-intl';

const messages = defineMessages({
  petsLabel: {
    id: 'App.LeaseApplicantionNonPrimaryForm.OtherInformationSection.PetsLabel',
    defaultMessage: 'Pets',
  },
  beenConvictedAFelonyLabel: {
    id:
      'App.LeaseApplicantionNonPrimaryForm.OtherInformationSection.BeenConvictedAFelonyLabel',
    defaultMessage: 'Have you ever been convicted of a felony?',
  },
  filedOrInvolvedBanckruptcyLabel: {
    id:
      'App.LeaseApplicantionNonPrimaryForm.OtherInformationSection.FiledOrInvolvedBanckruptcyLabel',
    defaultMessage:
      'Have you ever filed bankruptcy or are you currently involved in a bankruptcy case?',
  },
  typeFiledPlaceholder: {
    id:
      'App.LeaseApplicantionNonPrimaryForm.OtherInformationSection.TypeFiledPlaceholder',
    defaultMessage: 'Type of Bankruptcy case filed',
  },
  dateFiledPlaceholder: {
    id:
      'App.LeaseApplicantionNonPrimaryForm.OtherInformationSection.DateFiledPlaceholder',
    defaultMessage: 'MM/DD/YY',
  },
  ifYesPleaseExplainPlaceholder: {
    id:
      'App.LeaseApplicantionNonPrimaryForm.OtherInformationSection.IfYesPleaseExplainPlaceholder',
    defaultMessage: 'If yes, please explain...',
  },
  invalidValue: {
    id: 'App.LeaseApplicantionNonPrimaryForm.InvalidValue',
    defaultMessage: 'Invalid value',
  },
  invalidDateValue: {
    id: 'App.LeaseApplicantionNonPrimaryForm.InvalidDateValue',
    defaultMessage: 'Invalid date value, must be in the past',
  },
  requiredForCompletion: {
    id: 'App.LeaseApplicantionNonPrimaryForm.RequiredForCompletion',
    defaultMessage: 'This field is required to complete the application',
  },
});

export default messages;
