// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { partial } from 'ramda';
import RoutePicker from './RoutePicker';
import validateRoute from './validate-route';

import { socketDisconnect } from '../../containers/App/actions';
import type { Property, UserPermission } from '../../containers/App/types';

export type Props = {
  actions: {
    socketDisconnect: Function,
  },
  component: any,
  scope?: Array<string>,
  selectedProperty?: ?Property,
  userPermissions?: Array<UserPermission>,
};

const PrivateRoute = ({
  actions: { socketDisconnect: dispatchSocketDisconnect },
  component,
  userPermissions = [],
  scope = [],
  selectedProperty,
  ...rest
}: Props) => (
  <Route
    {...rest}
    render={partial(RoutePicker, [
      {
        component,
        validation: validateRoute(
          userPermissions,
          scope,
          selectedProperty,
          dispatchSocketDisconnect,
          // $FlowFixMe
          rest.path,
        ),
      },
    ])}
  />
);

export const mapDispatchToProps = (dispatch: any): Object => {
  const bindedActions = bindActionCreators(
    {
      socketDisconnect,
    },
    dispatch,
  );
  return { actions: bindedActions };
};

// $FlowFixMe
export default connect(null, mapDispatchToProps)(PrivateRoute);
