import React from 'react';
import { Row, Col } from 'react-bootstrap';
import classNames from 'classnames';

// Redux
import { Field } from 'redux-form';
import {
  renderRadioGroupField,
  renderSelectField,
  renderMultiSelectFieldFortress,
} from '../../../utils/redux-form-helper';

// Hooks
import { useFlags } from 'launchdarkly-react-client-sdk';
import useMultiRaceSelect from '../../../hooks/useMultiRaceSelect';

// Constants
import {
  raceOptions,
  raceSourceOptions,
  ethnicityOptions,
  DO_NOT_WISH_PROVIDE_OPTION,
} from '../../LeaseAffordableApplicantFormCommonSections/configuration';
import messages from './messages';

/*
  reduxFormOnChange() allows updating the redux-form state from the outside, since
  <MultiSelect/> component does not update the state under the hood as <Field/> would do
*/
type Props = {
  intl: Object,
  required?: boolean,
  reduxFormOnChange: Function,
  basicInformationMultiRace: string[] | string,
};

/**
 * NOTES:
 * The component to be displayed to the client in this phase is determined by the value of
 * raceMultiSelect obtained from flags. If it's true, the MultiSelect component is shown,
 * otherwise, the SingleSelect dropdown is displayed.
 * @param {*} param0
 * @returns
 */
const AffordableRaceSection = ({
  intl,
  required,
  reduxFormOnChange,
  basicInformationMultiRace,
}: Props) => {
  const { raceEnhancements } = useFlags();
  const { showMultiSelect, stateCode, handleItem } = useMultiRaceSelect();

  const handleMultiRaceSelect = (item) => {
    handleItem(item, (newItem) =>
      reduxFormOnChange('basicInformation.multiRace', newItem),
    );
  };

  return (
    <div className="form-content">
      <Row>
        <Col xs={12} md={6}>
          <label>{`${intl.formatMessage(messages.ethnicity)}*`}</label>
          <Field
            name="ethnicity"
            fieldName="ethnicity"
            options={ethnicityOptions}
            component={renderRadioGroupField}
            classes={classNames({ required: required })}
          />
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <Col xs={12} md={12}>
              <label>{`${intl.formatMessage(messages.race)}*`}</label>
              {(raceEnhancements &&
                (showMultiSelect ? (
                  <Field
                    name="multiRace"
                    fieldName="multiRace"
                    placeholder={'Select Races'}
                    options={raceOptions(stateCode, { withDefault: false })}
                    component={renderMultiSelectFieldFortress}
                    excludeFromSelectAll={[DO_NOT_WISH_PROVIDE_OPTION.value]}
                    bsSize="lg"
                    controlledValue={basicInformationMultiRace || []}
                    onChangeCallback={handleMultiRaceSelect}
                  />
                ) : (
                  <Field
                    name="race"
                    fieldName="race"
                    options={raceOptions(stateCode)}
                    component={renderSelectField}
                    bsSize="lg"
                    classes={classNames({ required })}
                  />
                ))) || (
                <Field
                  name="race"
                  fieldName="race"
                  options={raceOptions()}
                  component={renderRadioGroupField}
                  classes={classNames({ required })}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <label>{`${intl.formatMessage(messages.raceSource)}`}</label>
              <Field
                name="raceSource"
                fieldName="raceSource"
                options={raceSourceOptions}
                component={renderRadioGroupField}
                classes={classNames({})}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AffordableRaceSection;
