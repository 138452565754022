import {
  Switch,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';

import { FormattedMessage } from 'react-intl';
import { TextInput } from '../../BasicDetails/TextInput';
import { displayAsDashesIfEmpty, stripOnlyWhiteSpaceToNull } from '../utils';

import integrationSetupMessages from '../../IntegrationsSetup/messages';

export type PassbookRate = {
  id: string,
  startDate: string,
  endDate?: string,
  amount: number | string,
  programName: string,
  state: string,
};

export const SetupLabel = (props) => {
  return (
    <Typography variant={'inputLabel'} display={'block'} mb={1}>
      <FormattedMessage {...props} />
    </Typography>
  );
};

export const SetupValue = ({
  editMode,
  name,
  value,
  onChange,
  placeholder,
  editable = true,
  valueMapper,
  maxLength,
  required = false,
}) => {
  return (
    <Grid item xs={7} sx={{ padding: '0 5px' }}>
      {editMode && editable ? (
        <TextInput
          name={name}
          value={value}
          editMode={editMode}
          placeholder={placeholder}
          onChange={({ target: { name, value } }) => {
            onChange({
              target: {
                name,
                value: stripOnlyWhiteSpaceToNull(value),
              },
            });
          }}
          maxLength={maxLength ? maxLength : '255'}
          required={required}
        />
      ) : (
        <Typography variant={'body1'}>
          {valueMapper
            ? displayAsDashesIfEmpty(valueMapper[value])
            : displayAsDashesIfEmpty(value)}
        </Typography>
      )}
    </Grid>
  );
};

export const SetupSwitch = ({ editMode, name, value, onChange, switchId }) => {
  return (
    <Grid item xs={7} sx={{ padding: '0 5px' }}>
      {editMode ? (
        <Switch
          id={switchId}
          name={name}
          checked={value}
          disabled={!editMode}
          onChange={(evt) => {
            const name = evt.target.name;
            const value = evt.target.checked;
            onChange({ target: { name, value } });
          }}
        />
      ) : value ? (
        <Typography variant={'body1'} color={'primary.main'}>
          <FormattedMessage {...integrationSetupMessages.on} />
        </Typography>
      ) : (
        <Typography variant={'body1'} color={'text.secondary'}>
          <FormattedMessage {...integrationSetupMessages.off} />
        </Typography>
      )}
    </Grid>
  );
};

export const SetupTitle = ({ children }) => {
  return (
    <Grid item xs={12}>
      <Typography variant={'h4'}>{children}</Typography>
    </Grid>
  );
};
