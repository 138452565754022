import type { Action } from '../App/types';
import type { CreateApplicationState } from './types';
import * as ActionTypes from './constants';

const initialState: CreateApplicationState = {
  prospect: undefined,
  applicationFees: null,
  isHouseholdSubmitting: false,
};

const createApplicationReducer = (
  state: CreateApplicationState = initialState,
  action: Action<*>,
): CreateApplicationState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ONE_PROSPECT_SUCCESS: {
      return { ...state, prospect: payload };
    }
    case ActionTypes.GET_ONE_PROSPECT_ERROR: {
      return { ...state, prospect: initialState.prospect };
    }
    case ActionTypes.CLEAN:
      return initialState;
    case ActionTypes.GET_APPLICATION_FEES_SUCCESS:
      return { ...state, applicationFees: payload };
    case ActionTypes.GET_APPLICATION_FEES_ERROR:
      return { ...state, applicationFees: {} };
    case ActionTypes.CREATE_APPLICATION:
      return { ...state, isHouseholdSubmitting: true };
    case ActionTypes.CREATE_APPLICATION_SUCCESS:
      return { ...state, isHouseholdSubmitting: false };
    case ActionTypes.CREATE_APPLICATION_ERROR:
      return { ...state, isHouseholdSubmitting: false };
    default:
      return state;
  }
};

export default createApplicationReducer;
