const { get } = require('../utils/api');

class OnlinePaymentsService {
  getAllOnlinePayments(
    organizationId: string,
    propertyId: string,
    signal?: Object,
  ): Promise<any> {
    const options = signal ? { signal } : {};
    return get(`/${organizationId}/${propertyId}/online-payments`, options);
  }

  getPropertyConfig(
    organizationId: string,
    propertyId: string,
    signal?: Object,
  ): Promise<any> {
    const options = signal ? { signal } : {};
    return get(`/${organizationId}/${propertyId}/property-config`, options);
  }
}

export default OnlinePaymentsService;
