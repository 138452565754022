import { any, compose, curryN, isNil, pathOr } from 'ramda';
import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import IdleTimer from 'react-idle-timer';
import { injectIntl } from 'react-intl';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { GlobalStyles } from '@fortress-technology-solutions/fortress-component-library/design';
import ExtendSessionModal from '../../components/ExtendSessionModal';
import WalkmeHiddenFields from '../../components/WalkmeHiddenFields';
import ErrorBoundary from '../../containers/ErrorBoundary';
import { HomeTabs } from '../Home';
import { setActiveKey } from '../Home/actions';
import { isUserActive, isUserIdle, startTokenRenewal } from '../Login/actions';
import { getAllKpis } from '../Overview/actions';
import * as appActions from './actions';
import { AppContext } from './context';
import { isUserLoggedIn } from './selectors';
import type {
  Actions,
  BuildInformation,
  CustomLDProp,
  GlobalState,
  Property,
  UserPermission,
} from './types';
import urlsUserIdleBlackList from './urlsUserIdleBlackList';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import queryClient from '../../react-query-client';
import MaintenancePage from '../Maintenance';
import Routes from './Routes';
import ScrollToTop from './ScrollToTop';

type Props = {
  buildInformation: BuildInformation,
  selectedProperty?: Property,
  userProperties: Array<Property>,
  userPermissions: Array<UserPermission>,
  showExtendUserSessionModal: boolean,
  isUser: boolean,
  selectedPropertyClassId: string,
  userRoleId: string,
  username: string,
  userId: string,
  organizationId: string,
  flags: {
    maintenanceMode: boolean,
  },
};
type InjectedProps = {
  intl: any,
  history: Object,
  actions: Actions,
  location: any,
};

type State = {
  abortController: Object,
};

export class App extends Component<Props & InjectedProps, State> {
  idleTimer: ?Object;
  removeListenerFunction: Function;
  unlisten: Function;

  constructor(props: Props & InjectedProps) {
    super(props);

    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    this.state = {
      abortController,
    };
    this.idleTimer = React.createRef();
    this.removeListenerFunction = () => {};
  }

  isToken = () => {
    const currentToken = localStorage.getItem('session_id');
    return !isNil(currentToken);
  };
  currentUrlisBlackListed = () => {
    const currentUrl = this.props.location.pathname;
    return !any((url) => url === currentUrl, urlsUserIdleBlackList);
  };

  selectPropertyIfParams(location) {
    // Have to do it this way instead of match because of the way our router is structured
    // React-Router doesn't let you get the path params from a global level, only in the component that
    // is defined on the route, first variable is property id
    const propertyId = location.pathname?.split('/')[2];
    if (propertyId) {
      const property = this.props.userProperties.find(
        (userProperty) => userProperty.id === propertyId,
      );
      if (property) {
        this.props.actions.selectProperty(property);
        this.props.actions.getSelectedPropertySubjournals();
        this.props.actions.getPropertyPaymentProvider();
      }
    }
  }

  componentDidMount() {
    this.props.actions.getAllNavigationOptions();
    this.updateLDClientContext({}, this.props);
    compose(this.startTokenRenewal, this.currentUrlisBlackListed)();
    this.selectPropertyIfParams(window.location);
    this.unlisten = this.props.history.listen((location, action) => {
      const propertyId = location.pathname?.split('/')?.[1];
      if (this.props?.selectedProperty?.id !== propertyId) {
        this.selectPropertyIfParams(location);
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.selectedProperty && this.props.selectedProperty) {
      this.props.actions.getSelectedPropertySubjournals();
      this.props.actions.getPropertyPaymentProvider();
    }
    this.updateLDClientContext(prevProps, this.props);
  }

  updateLDClientContext(prevProps: Object, props: Object) {
    const currentUsername = props?.username;
    const currentOrganizationId = props?.organizationId;
    const currentSelectedProperty = props?.selectedProperty;

    const previousUsername = prevProps?.username;
    const previousOrganizationId = prevProps?.organizationId;
    const prevSelectedProperty = prevProps?.selectedProperty;

    const usernameChanged = currentUsername !== previousUsername;
    const organizationIdChanged =
      currentOrganizationId !== previousOrganizationId;
    const selectedPropertyChanged =
      currentSelectedProperty?.id !== prevSelectedProperty?.id;

    const shouldUpdateLdProps = [
      usernameChanged,
      organizationIdChanged,
      selectedPropertyChanged,
    ].includes(true);

    if (shouldUpdateLdProps === false) return;

    this.updateCustomPropertiesForLd({
      username: currentUsername,
      organizationId: currentOrganizationId,
      propertyId: currentSelectedProperty?.id,
      physicalState: currentSelectedProperty?.physicalState?.replace?.(
        /US-/g,
        '',
      ),
      propertyType: currentSelectedProperty?.propertyClass?.name,
    });
  }

  updateCustomPropertiesForLd(ldCustomProps: CustomLDProp) {
    const { ldClient, userId } = this.props;
    ldClient.identify(
      {
        kind: 'user',
        key: userId,
        ...ldCustomProps,
      },
      null,
      () => {
        if (this.state.ldKey !== userId) {
          this.setState({ ldKey: userId });
        }
      },
    );
  }

  startTokenRenewal = (urlIsAllowed: boolean) => {
    if (urlIsAllowed && this.props.isUser && this.isToken())
      this.props.actions.startTokenRenewal();
  };

  dismiss = () => {
    this.props.actions.isUserActive();
  };
  userIdle = () => {
    compose(this.isUserIdle, this.currentUrlisBlackListed)();
  };
  isUserIdle = (urlIsAllowed: boolean) => {
    if (urlIsAllowed && !this.props.showExtendUserSessionModal)
      this.props.actions.isUserIdle();
  };

  showExtendUserSessionModal = (urlIsAllowed: boolean) => {
    if (urlIsAllowed && !this.props.showExtendUserSessionModal)
      this.props.actions.isUserActive();
  };
  userActive = () =>
    compose(this.showExtendUserSessionModal, this.currentUrlisBlackListed)();

  logOut = () => {
    this.props.actions.logOut();
    // Clear queries because user is logging out to prevent other users from seeing cached data
    queryClient.clear();
  };

  onSelectProperty = (property: Property) => {
    this.props.history.push(`/property/${property.id}`);
    this.props.actions.setActiveKey(HomeTabs.OVERVIEW);
    const abortController = new AbortController(); // eslint-disable-line
    this.setState({ abortController }, () =>
      this.props.actions.getAllKpis(abortController.signal),
    );
  };

  onSelectPortfolioSummary = () => {
    this.props.history.push('/');
    if (this.props.history.location.pathname === '/') {
      //Remove the route change listener
      this.removeListenerFunction();
      this.props.actions.selectPortfolioSummary();
    } else {
      //If Prompt was fired, listen for route change before selecting a new property
      this.removeListenerFunction = this.props.history.listen(
        (location, action) => {
          this.props.actions.selectPortfolioSummary();
        },
      );
    }
  };

  render() {
    const onSelectPropertyCurried = curryN(2, this.onSelectProperty);
    const hasCommercialFloorPlans = this.props.selectedProperty
      ? this.props.selectedProperty.hasCommercialFloorPlans
      : '';

    // FF for the Transaction Batches route

    const { flags = {} } = this.props;
    const { maintenanceMode: enableMaintenanceMode = false } = flags;

    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AppContext.Provider
          value={{
            history: this.props.history,
            permissions: this.props.userPermissions,
            selectedProperty: this.props.selectedProperty,
            intl: this.props.intl,
            formatMessage: this.props.intl.formatMessage,
            onSelectProperty: onSelectPropertyCurried,
            onSelectPortfolioSummary: this.onSelectPortfolioSummary,
            logOut: this.logOut,
            userOrganizationId: this.props.organizationId,
            userId: this.props.userId,
            ldKey: this.state.ldKey,
            isLdUserContextReady: this.state.ldKey === this.props.userId,
          }}
        >
          <GlobalStyles />
          {enableMaintenanceMode ? (
            <MaintenancePage />
          ) : (
            <ErrorBoundary intl={this.props.intl}>
              <DocumentTitle title="Fortress">
                <div>
                  <WalkmeHiddenFields
                    userRoleId={this.props.userRoleId}
                    propertyClassId={this.props.selectedPropertyClassId}
                    username={this.props.username}
                    organizationId={this.props.organizationId}
                    hasCommercialFloorPlans={hasCommercialFloorPlans}
                  />
                  {this.props.showExtendUserSessionModal && (
                    <ExtendSessionModal
                      dismiss={this.dismiss}
                      userIdle={() => {}}
                      logOut={this.logOut}
                      showExtendUserSessionModal={
                        this.props.showExtendUserSessionModal
                      }
                      intl={this.props.intl}
                    />
                  )}
                  <ReduxToastr
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar
                  />
                  <IdleTimer
                    id="IdleTimer"
                    ref={this.idleTimer}
                    onActive={this.userActive}
                    onIdle={this.userIdle}
                    timeout={3000}
                  >
                    <ScrollToTop />
                    <Routes />
                  </IdleTimer>
                </div>
              </DocumentTitle>
            </ErrorBoundary>
          )}
        </AppContext.Provider>
      </QueryClientProvider>
    );
  }
}

export const mapStateToProps = (globalState: GlobalState): Props => {
  const { app } = globalState;
  return {
    buildInformation: app.buildInformation,
    selectedProperty: app.selectedProperty,
    // $FlowFixMe
    selectedPropertyClassId: pathOr(
      'portfolioSummary',
      ['selectedProperty', 'propertyClass', 'id'],
      app,
    ),
    userId: app?.currentUser?.user?.id ?? '',
    // $FlowFixMe
    userRoleId: pathOr('', ['currentUser', 'user', 'userRoleId'], app),
    // $FlowFixMe
    username: pathOr('', ['currentUser', 'user', 'username'], app),
    // $FlowFixMe
    organizationId: pathOr('', ['currentUser', 'user', 'organizationId'], app),
    // $FlowFixMe
    userProperties: pathOr([], ['currentUser', 'user', 'properties'], app),
    // $FlowFixMe
    userPermissions: pathOr([], ['currentUser', 'permissions'], app),
    showExtendUserSessionModal: app.showExtendUserSessionModal,
    isUser: isUserLoggedIn(globalState),
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...appActions,
      getAllKpis,
      isUserActive,
      isUserIdle,
      setActiveKey,
      startTokenRenewal,
    },
    dispatch,
  );
  return { actions };
}

const AppInjected = injectIntl(App);
const router = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppInjected),
);
export default withLDConsumer()(router);
