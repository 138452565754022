import { useMemo, useState } from 'react';
import PropertyFeesService from '../../services/propertyFeesService';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import { useQuery } from 'react-query';
import messages from './messages';
import { formatDateDisplayLocal } from '@fortress-technology-solutions/fortress-component-library/utils/index';
import { useTableManageColumns } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import useUniqueTableName from '../../hooks/useUniqueTableName';

export const useFetchAllFees = ({ intl }) => {
  const name = useUniqueTableName('manage-fees');
  const selectedProperty = useSelectedProperty();
  const abortController = new AbortController();
  const options = { signal: abortController.signal };

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedFee, setSelectedFee] = useState({});

  const {
    isLoading,
    data: feesData,
    refetch: refetchFees,
  } = useQuery(
    ['feesData', selectedProperty?.id, selectedProperty?.organizationId],
    () =>
      new PropertyFeesService().getAllFeesByProperty(
        selectedProperty?.organizationId,
        selectedProperty?.id,
        options,
      ),
    {
      enabled: true,
      refetchOnWindowFocus: false,
    },
  );

  const closeModal = () => {
    setSelectedFee({});
    setEditModalOpen(false);
  };

  const handleEditClick = (fee) => {
    setSelectedFee(fee);
    setEditModalOpen(true);
  };

  const parseUpdatedUserName = (updatedBy: {
    firstName: string,
    lastName: string,
  }) => {
    if (updatedBy) {
      return `By ${updatedBy.firstName} ${updatedBy.lastName}`;
    }
    return '';
  };
  const rows = useMemo(() => {
    const chargeAt = {
      floorPlan: intl.formatMessage(messages.floorPlanFee),
      unit: intl.formatMessage(messages.unitFee),
      property: intl.formatMessage(messages.propertyFee),
    };
    if (!feesData) return [];
    const rows = feesData.map((item) => {
      return {
        id: item.id,
        transactionCode: {
          value: item.feeTransactionCode,
        },
        description: {
          variant: 'description',
          value: item.transactionDescription,
        },
        chargeAt: {
          value: chargeAt[item.chargeAt],
        },
        displayNameOnQuote: {
          value: item.nameOnQuote ? 'Yes' : 'No',
        },
        nameQuote: {
          value: item.nameOnQuote,
        },
        oneTimeCharge: {
          value: item.oneTimeCharged ? 'Yes' : 'No',
        },
        feeAmount: {
          variant: 'currency',
          value: item.feeAmount,
        },
        descriptionAmount: {
          value: item.descriptionAmount,
        },
        lastUpdated: {
          value: [
            formatDateDisplayLocal(item.updatedAt),
            parseUpdatedUserName(item.lastUpdatedBy),
          ],
        },
        edit: {
          variant: 'iconButton',
          name: 'editButton',
          iconName: 'PencilIcon',
          onClick: () => handleEditClick(item),
        },
      };
    });
    return rows;
  }, [feesData, intl]);

  const headers = [
    {
      id: 'transactionCode',
      label: intl.formatMessage(messages.feeTransactionCode),
    },
    {
      id: 'description',
      label: intl.formatMessage(messages.transactionDescription),
    },
    {
      id: 'chargeAt',
      label: intl.formatMessage(messages.chargeAt),
    },
    {
      id: 'displayNameOnQuote',
      label: intl.formatMessage(messages.displayNameOnQuote),
    },
    {
      id: 'nameQuote',
      label: intl.formatMessage(messages.nameOnQuote),
    },
    {
      id: 'oneTimeCharge',
      label: intl.formatMessage(messages.oneTimeCharge),
    },
    {
      id: 'feeAmount',
      label: intl.formatMessage(messages.feeAmount),
    },
    {
      id: 'descriptionAmount',
      label: intl.formatMessage(messages.feeDescriptionAmount),
    },
    {
      id: 'lastUpdated',
      label: intl.formatMessage(messages.lasUpdatedOnBy),
    },
    {
      id: 'edit',
      label: intl.formatMessage(messages.editHeader),
    },
  ];
  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  return {
    allColumnsHidden,
    closeModal,
    columnOptions,
    count: rows?.length ?? 0,
    filteredHeaders,
    handleColumnChange,
    isEditModalOpen,
    isLoading,
    name,
    refetchFees,
    rows,
    selectedColumns,
    selectedFee,
    totalCount: feesData?.length ?? 0,
  };
};
