import { defineMessages } from 'react-intl';

const messages = defineMessages({
  required: {
    id: 'App.ShortLeaseApplication.Required',
    defaultMessage: 'This field is required'
  },
  requiredForCompletion: {
    id: 'App.ShortLeaseApplication.RequiredForCompletion',
    defaultMessage: 'This field is required to complete the application'
  },
  salaryRequired: {
    id: 'App.ShortLeaseApplication.SalaryRequired',
    defaultMessage:
      'Required to complete the application. If no salary, please enter 0.'
  },
  monthlyPaymentWarning: {
    id: 'App.ShortLeaseApplication.MonthlyPaymentWarning',
    defaultMessage:
      'Required to complete the application. If no monthly payment, please enter 0.'
  },
  invalidValue: {
    id: 'App.ShortLeaseApplication.InvalidValue',
    defaultMessage: 'Invalid value'
  },
  successHeader: {
    id: 'App.ShortLeaseApplication.SuccessHeader',
    defaultMessage: 'Success'
  },
  progressSuccessDescription: {
    id: 'App.ShortLeaseApplication.ProgressSuccessDescription',
    defaultMessage: 'Lease application saved successfully.'
  },
  completionSuccessDescription: {
    id: 'App.ShortLeaseApplication.CompletionSuccessDescription',
    defaultMessage: 'Lease application completed successfully.'
  },
  errorHeader: {
    id: 'App.ShortLeaseApplication.errorHeader',
    defaultMessage: 'Error'
  },
  provideOne: {
    id: 'App.ShortLeaseApplication.provideOne',
    defaultMessage:
      'You must provide either SSN, license or passport to complete the application.'
  },
  stateIdOriginPeerRequired: {
    id: 'App.ShortLeaseApplication.statePeerRequired',
    defaultMessage: 'State must be provided.'
  },
  passportCountryPeerRequired: {
    id: 'App.ShortLeaseApplication.countryPeerRequired',
    defaultMessage: 'Country must be provided.'
  },
  validNumber: {
    id: 'App.ShortLeaseApplication.Number',
    defaultMessage: 'Please enter a valid number'
  },
  ownOrRentRequired: {
    id: 'App.ShortLeaseApplication.OwnOrRentRequired',
    defaultMessage:
      'Current own or rent information is required to complete the application'
  },
  validFormat: {
    id: 'App.ShortLeaseApplication.ValidFormat',
    defaultMessage: 'Please enter a valid date'
  },
  mustBeAfterFrom: {
    id: 'App.ShortLeaseApplication.MustBeAfterFrom',
    defaultMessage: 'Must be after the From date'
  }
});

export default messages;
