import { useQuery } from 'react-query';
import { get } from '../../../../utils/api';

export const useFetchCamHistory = ({
  propertyId,
  organizationId,
  camRecordId,
}: Object): any => {
  const { data, isLoading, refetch } = useQuery(
    ['camHistory', propertyId, organizationId, camRecordId],
    async () => {
      return get(
        `/${organizationId}/${propertyId}/camRecords/${camRecordId}/camHistory`,
      );
    },
    {
      refetchOnWindowFocus: false,
      initialData: [],
    },
  );

  return [isLoading, data, refetch];
};
