import useFetchAndSetData from './useFetchAndSetData';
import { useCallback } from 'react';
import IntegrationService from '../../services/integrationService';

export default function useFetchIntegrations(
  organizationId: string,
  propertyId: string,
  slug?: string,
): Object {
  const initialState = { property: [], organization: [] };
  const fetcherCallback = useCallback(() => {
    const service = new IntegrationService();
    const queryParams = { slug };
    return service.getIntegrations(organizationId, propertyId, queryParams);
  }, [organizationId, propertyId, slug]);

  return useFetchAndSetData(initialState, fetcherCallback);
}
