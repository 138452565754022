import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import classNames from 'classnames';
import { renderRadioGroupField } from '../../../utils/redux-form-helper';

import {
  yesNoBooleanOptions,
  generalDisabilityOptions,
} from '../../LeaseAffordableApplicantFormCommonSections/configuration';

import messages from './messages';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  intl: Object,
  required?: boolean,
  disabilitiesDisabled: boolean,
  disabilitiesRequired: boolean,
};

const DisabilitySection = ({
  intl,
  required,
  disabilitiesDisabled,
  disabilitiesRequired,
}: Props) => {
  const { doNotWishToProvideDisability } = useFlags();
  return (
    <div className="form-content">
      <Row>
        {doNotWishToProvideDisability && (
          <Col xs={12}>
            <Field
              name={'disability.hasDisability'}
              fieldName="hasDisability"
              component={renderRadioGroupField}
              options={generalDisabilityOptions}
              label={`${intl.formatMessage(messages.disabled)}*`}
              labelClass="padright20"
              className="form-options"
              classes={classNames({ required })}
              inline
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Field
            name={'disability.disabilityMobile'}
            fieldName="disabilityMobile"
            component={renderRadioGroupField}
            options={yesNoBooleanOptions}
            label={`${intl.formatMessage(messages.mobileDisability)}*`}
            labelClass="padright20"
            className="form-options"
            classes={classNames({ required })}
            disabled={disabilitiesDisabled}
            inline
          />
        </Col>
        <Col xs={12} md={6}>
          <Field
            name={'disability.disabilityHearing'}
            fieldName="disabilityHearing"
            component={renderRadioGroupField}
            options={yesNoBooleanOptions}
            label={`${intl.formatMessage(messages.hearingDisability)}*`}
            labelClass="padright20"
            className="form-options"
            classes={classNames({ required })}
            disabled={disabilitiesDisabled}
            inline
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Field
            name={'disability.disabilityVisual'}
            fieldName="disabilityVisual"
            component={renderRadioGroupField}
            options={yesNoBooleanOptions}
            label={`${intl.formatMessage(messages.visualDisability)}*`}
            labelClass="padright20"
            className="form-options"
            classes={classNames({ required })}
            disabled={disabilitiesDisabled}
            inline
          />
        </Col>
        <Col xs={12} md={6}>
          <Field
            name={'disability.disabilityOther'}
            fieldName="disabilityOther"
            component={renderRadioGroupField}
            options={yesNoBooleanOptions}
            label={`${intl.formatMessage(messages.otherDisability)}*`}
            labelClass="padright20"
            className="form-options"
            classes={classNames({ required })}
            disabled={disabilitiesDisabled}
            inline
          />
        </Col>
      </Row>
    </div>
  );
};

export default DisabilitySection;
