export const editFeeTypeOptions = [
  { text: 'Amount', value: 'numeric' },
  { text: 'Other', value: 'descriptive' },
];

export const editFeeDescriptiveAmountOptions = [
  {
    text: 'Charged by Utility Company',
    value: 'Charged by Utility Company',
    default: true,
  },
  { text: 'Variable', value: 'Variable' },
];
