import React from 'react';
import { Box } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Route } from 'react-router-dom';
import PageContent from './PageContent';
import AppFooter from '../../../../components/AppFooter';
import AppBarNavDrawer from '../../../../components/AppBarNavDrawer';
import { useFlags } from 'launchdarkly-react-client-sdk';

function MainLayoutRoutes() {
  const { leftsideNavButton } = useFlags();

  return (
    <Route>
      <Box>
        <AppBarNavDrawer>
          {({ open, drawerWidth }) => (
            <Box
              sx={{
                height: 'calc(100dvh - 40px)',
                ml: {
                  [leftsideNavButton ? 'md' : 'sm']: open
                    ? `${drawerWidth.open}px`
                    : `${drawerWidth.closed}px`,
                },
                mt: '40px', // AppBar height
                paddingTop: '0px !important',
              }}
            >
              <PageContent />
            </Box>
          )}
        </AppBarNavDrawer>
        <AppFooter />
      </Box>
    </Route>
  );
}

export default MainLayoutRoutes;
