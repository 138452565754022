import * as ActionTypes from './constants';

import type { Application } from '../CreateApplication/types';
import type { Action } from '../App/types';

export function clean(): Action<any> {
  return {
    type: ActionTypes.CLEAN,
    payload: undefined
  };
}

export function getOneApplication(applicationId: string): Action<string> {
  return {
    type: ActionTypes.GET_ONE_APPLICATION,
    payload: applicationId
  };
}

export function getOneApplicationSuccess(
  application: Application
): Action<Application> {
  return {
    type: ActionTypes.GET_ONE_APPLICATION_SUCCESS,
    payload: application
  };
}

export function getOneApplicationError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ONE_APPLICATION_ERROR,
    payload: error
  };
}

export function editApplicationHousehold(
  application: any
): Action<Application> {
  return {
    type: ActionTypes.EDIT_APPLICATION_HOUSEHOLD,
    payload: application
  };
}

export function editApplicationHouseholdSuccess(): Action<any> {
  return {
    type: ActionTypes.EDIT_APPLICATION_HOUSEHOLD_SUCCESS,
    payload: undefined
  };
}

export function editApplicationHouseholdError(error: Error): Action<Error> {
  return {
    type: ActionTypes.EDIT_APPLICATION_HOUSEHOLD_ERROR,
    payload: error
  };
}
