import { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import {
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { ToggleInput } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import DateInput from '../DateInput';
import TimeInput from '../TimeInput';
import { WaitlistSectionProps } from './types';
import {
  showWaitlistUpdateFail,
  showWaitlistUpdateSuccess,
  getStartTimeDate,
  getStartDateString,
  getStartTimeString,
} from './utils';
import { validateDate } from '../../utils/validations';
import messages from './messages';

export const getValidators = () => {
  const now = new Date();
  return [
    (dateToValidate) =>
      (dateToValidate?.isSameOrBefore?.(now) ?? false) || dateToValidate <= now,
  ];
};

const confirmDate = (dateToValidate: string | Date | Moment): boolean => {
  return getValidators().every((validator) => validator(dateToValidate));
};

const DateTimeSection = (props: Partial<WaitlistSectionProps>) => {
  const { intl, localWaitlistInfo, waitlistActions } = props;
  const { waitlistStartTime } = localWaitlistInfo;
  const { updateWaitlist } = waitlistActions;
  const [localWaitlistStartTime, setLocalWaitlistStartTime] =
    useState(waitlistStartTime);
  const isNoChange = waitlistStartTime === localWaitlistStartTime;

  useEffect((): void => {
    setLocalWaitlistStartTime(waitlistStartTime);
  }, [waitlistStartTime]);

  const onChangeDate = (newDate: string | Moment | Date): void => {
    if (!validateDate(newDate)) {
      return;
    }

    const newWaitlistDate = getStartTimeDate(localWaitlistStartTime);
    newWaitlistDate.setDate(newDate.date());
    newWaitlistDate.setMonth(newDate.month());
    newWaitlistDate.setFullYear(newDate.year());

    const newDateTimeString = newWaitlistDate.toISOString();
    setLocalWaitlistStartTime(newDateTimeString);
  };
  const onChangeTime = (newTime) => {
    if (!validateDate(newTime)) {
      return;
    }

    const newWaitlistStartTime = getStartTimeDate(localWaitlistStartTime);
    newWaitlistStartTime.setHours(newTime.hours());
    newWaitlistStartTime.setMinutes(newTime.minutes());

    const newDateTimeString = newWaitlistStartTime.toISOString();
    setLocalWaitlistStartTime(newDateTimeString);
  };
  const isValidDate = (currentDate, selectedDate) => {
    return confirmDate(currentDate) && confirmDate(selectedDate ?? currentDate);
  };

  const editableComponent = (
    <Stack direction="row" spacing={2}>
      <DateInput
        value={getStartTimeDate(localWaitlistStartTime)}
        onChange={onChangeDate}
        isValidDate={isValidDate}
      />
      <TimeInput
        value={getStartTimeDate(localWaitlistStartTime)}
        onChange={onChangeTime}
        isValidDate={isValidDate}
      />
    </Stack>
  );
  const displayComponent = (
    <Stack direction="row" spacing={2}>
      <Typography>{getStartDateString(waitlistStartTime)}</Typography>
      <Typography>{getStartTimeString(waitlistStartTime)}</Typography>
    </Stack>
  );
  const onSave = async (isEdit) => {
    if (isNoChange) {
      return;
    }

    try {
      const { id: applicationId } = localWaitlistInfo;
      const newWaitlistInfo = {
        waitlistStartTime: localWaitlistStartTime,
      };
      await updateWaitlist(applicationId, newWaitlistInfo);
      showWaitlistUpdateSuccess(intl);
    } catch (error) {
      showWaitlistUpdateFail(intl, 'waitlistDateTimeInFuture');
      setLocalWaitlistStartTime(waitlistStartTime);
    }
  };
  const onCancel = async () => {
    setLocalWaitlistStartTime(waitlistStartTime);
  };

  const controlsConfig = {
    onSave,
    onCancel,
  };
  const toggleInputProps = {
    label: {
      text: intl.formatMessage(messages.waitlistDateTimeTitle),
    },
    isNoChange,
    controlsConfig,
    editableComponent,
    displayComponent,
  };

  return <ToggleInput {...toggleInputProps} />;
};

export default injectIntl(DateTimeSection);
