import { useMemo } from 'react';
import { useActiveProperties } from '../../../../hooks/data-fetching/useActiveProperties';
import { useComplianceApprovalStatuses } from '../../../../hooks/data-fetching/useComplianceApprovalStatuses';
import { FieldTypes } from '../../../../components/FilterGroup/types';
import {
  LIHTC_PROGRAM_NAME,
  HUD_PROGRAM_NAME,
  COMPLIANCE_TYPES,
  valueToIntlKeyMap,
} from '../constants';
import messages from '../messages';

export const useFilterGroupSectionFieldDescriptors = (
  organizationId: string,
  complianceType?: string,
) => {
  const [properties, isLoadingProperties] = useActiveProperties(organizationId);
  const [rawComplianceApprovalStatuses, isLoadingComplianceApprovalStatuses] =
    useComplianceApprovalStatuses();

  const complianceApprovalStatuses = useMemo(
    () => [...rawComplianceApprovalStatuses, { id: 'none', name: 'None' }],
    [rawComplianceApprovalStatuses],
  );

  const fieldDescriptors = useMemo(() => {
    const complianceTypeToFieldMap = {
      [COMPLIANCE_TYPES.ALL_OPEN_CERTS]: [
        {
          name: 'certificationType',
          options: [
            'Initial',
            'Interim',
            'Move-in',
            'Move-out',
            'Recert',
            'Termination',
          ].map((value) => ({ value, text: value })),
          type: FieldTypes.MULTISELECT,
          placeholder: 'All',
        },
        {
          name: 'complianceDocumentType',
          options: [
            'finalSignedPacket',
            'hudFinalSignedPacket',
            'hudSubmission',
            'submission',
            'noDocumentUploaded',
          ].map((value) => ({
            value,
            text: value,
          })),
          type: FieldTypes.MULTISELECT,
          placeholder: 'All',
        },
        {
          name: 'complianceApprovalStatus',
          options: complianceApprovalStatuses
            .filter((status) => status.id && status.name)
            .map(({ id: value, name: text, translations }) => ({
              value,
              text,
              translations,
            })),
          isLoading: isLoadingComplianceApprovalStatuses,
          type: FieldTypes.MULTISELECT,
          placeholder: 'All',
        },
        {
          name: 'isActive',
          options: ['All', 'Yes', 'No'].map((value) => ({
            value,
            text: value,
          })),
          type: FieldTypes.SELECT,
        },
        {
          name: 'isResident',
          options: ['All', 'Yes', 'No'].map((value) => ({
            value,
            text: value,
          })),
          type: FieldTypes.SELECT,
        },
        {
          name: 'programType',
          options: [LIHTC_PROGRAM_NAME, HUD_PROGRAM_NAME].map((value) => ({
            value,
            text: value,
          })),
          type: FieldTypes.MULTISELECT,
          placeholder: 'All',
        },
        {
          name: 'status',
          options: ['All', 'pastDue', 'attentionRequired'].map((value) => ({
            value,
            text: value,
          })),
          type: FieldTypes.SELECT,
        },
        {
          name: 'propertyId',
          options: properties
            .filter((property) => property.id && property.name)
            .map(({ id: value, name: text, translations }) => ({
              value,
              text,
              translations,
            })),
          type: FieldTypes.MULTISELECT,
          placeholder: 'All',
          isLoading: isLoadingProperties,
        },
      ],
      default: [
        {
          name: 'certificationType',
          options: [
            'Initial',
            'Interim',
            'Move-in',
            'Move-out',
            'Recert',
            'Termination',
          ].map((value) => ({ value, text: value })),
          type: FieldTypes.MULTISELECT,
          placeholder: 'All',
        },
        {
          name: 'complianceDocumentType',
          options: [
            'finalSignedPacket',
            'hudFinalSignedPacket',
            'hudSubmission',
            'submission',
          ].map((value) => ({
            value,
            text: value,
          })),
          type: FieldTypes.MULTISELECT,
          placeholder: 'All',
        },
        {
          name: 'complianceApprovalStatus',
          options: complianceApprovalStatuses
            .filter((status) => status.id && status.name)
            .map(({ id: value, name: text, translations }) => ({
              value,
              text,
              translations,
            })),
          isLoading: isLoadingComplianceApprovalStatuses,
          type: FieldTypes.MULTISELECT,
          placeholder: 'All',
        },
        {
          name: 'isActive',
          options: ['All', 'Yes', 'No'].map((value) => ({
            value,
            text: value,
          })),
          type: FieldTypes.SELECT,
        },
        {
          name: 'isResident',
          options: ['All', 'Yes', 'No'].map((value) => ({
            value,
            text: value,
          })),
          type: FieldTypes.SELECT,
        },
        {
          name: 'programType',
          options: [LIHTC_PROGRAM_NAME, HUD_PROGRAM_NAME].map((value) => ({
            value,
            text: value,
          })),
          type: FieldTypes.MULTISELECT,
          placeholder: 'All',
        },
        {
          name: 'status',
          options: ['All', 'pastDue'].map((value) => ({
            value,
            text: value,
          })),
          type: FieldTypes.SELECT,
        },
        {
          name: 'propertyId',
          options: properties
            .filter((property) => property.id && property.name)
            .map(({ id: value, name: text, translations }) => ({
              value,
              text,
              translations,
            })),
          type: FieldTypes.MULTISELECT,
          placeholder: 'All',
          isLoading: isLoadingProperties,
        },
      ],
    };

    return (
      complianceTypeToFieldMap?.[complianceType] ??
      complianceTypeToFieldMap.default
    );
  }, [
    complianceApprovalStatuses,
    isLoadingComplianceApprovalStatuses,
    properties,
    isLoadingProperties,
    complianceType,
  ]);

  return [fieldDescriptors];
};

export const useLocalization = (
  organizationId: string,
  locale: string,
  intl: object,
) => {
  const constantLocalizations = useMemo(() => {
    const localizationEntries = Object.entries(valueToIntlKeyMap);
    return localizationEntries.reduce((localizedEntries, entry) => {
      try {
        const [key, intlKey] = entry;
        const localizedText = intl.formatMessage(messages?.[intlKey]);
        return {
          ...localizedEntries,
          [key]: localizedText,
        };
      } catch (error) {
        const [key, value] = entry;
        return {
          ...localizedEntries,
          [key]: value,
        };
      }
    }, {});
  }, [intl]);

  const localizationMap = useMemo(
    () =>
      Object.freeze({
        ...constantLocalizations,
      }),
    [constantLocalizations],
  );

  return [localizationMap];
};
