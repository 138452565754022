const { apiURL, put, get } = require('../utils/api');

const request = require('../utils/api').default;

class ApplicantService {
  getAllApplicantsByHouseholdId(
    organizationId: string,
    propertyId: string,
    householdId: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/applicants/household/${householdId}`,
      options,
    );
  }

  updateApplicant(
    organizationId: string,
    propertyId: string,
    applicantId: string,
    payload: Object,
    isAdult: boolean,
    options?: Object,
  ) {
    const path = isAdult ? 'applicant' : 'applicantMinor';
    return put(
      `/${organizationId}/${propertyId}/${path}/${applicantId}/update`,
      JSON.stringify(payload),
      options,
    );
  }

  getApplicant(
    organizationId: string,
    propertyId: string,
    applicantId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/applicant/${applicantId}`,
      options,
    );
  }
}

export default ApplicantService;
