import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change, Field, touch } from 'redux-form';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { pathOr, isEmpty, isNil, range } from 'ramda';

import {
  extractCurrentDateFormat,
  renderCheckboxField,
  renderDateField,
  renderSelectField,
} from '../../../../../utils/redux-form-helper';
import {
  calculateLeaseEndDate,
  calculateLeaseEndDateV2,
  generateNumberOfKeys,
  getLeaseTermOptions,
} from '../../../../../utils/lease-helpers.js';
import ElementWithPermissions from '../../../../../components/ElementWithPermissions';

import EditLeaseDataLink from '../../EditLeaseDataLink';

import messages from './messages';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useExpirationLimitsPropertyToggle from '../../../../../hooks/data-fetching/useExpirationLimitsPropertyToggle';

export const LeaseBasicsRightColumn = ({
  actions: { change, touch },
  formValues,
  intl,
  isEditable,
  lease,
  leaseEndDate,
  leaseTerms,
  residentId,
  showEditLink,
  showLeaseDataModal,
  unit,
  valid,
  transferInformation,
  updateLeaseDate,
  formAsyncErrors,
  selectedProperty,
  promptToaster,
  noticeToVacate,
}: any) => {
  const leaseEndDateDisabled =
    !formValues.startDate ||
    !formValues.leaseTermId ||
    formValues.leaseTermId === 'default' ||
    !isEditable;

  const isValidDate = (calendarDate: any) => {
    if (!isNil(transferInformation) && !isEmpty(transferInformation)) {
      return moment(transferInformation.previousResidentMoveOutDate).isBefore(
        calendarDate,
      );
    } else {
      const unitLeases = pathOr([], ['leases'], unit);
      const unitStatus = pathOr('', ['unitStatus', 'description'], unit);
      if (!unitStatus.includes('Occupied')) {
        return true;
      }
      const lastLease = unitLeases.filter((l) => l.id !== lease.id)[0];
      const moveOutDate = pathOr(
        '',
        ['noticeToVacate', 'moveOutDate'],
        lastLease,
      );
      return !moveOutDate ? true : moment(moveOutDate).isBefore(calendarDate);
    }
  };
  const flags = useFlags();
  const { leaseEndEnhance } = flags;
  const onChangeLeaseStartDate = (leaseTermId: any) => (startDate: any) => {
    updateLeaseDate('startDate', startDate);
    if (
      moment.isMoment(startDate) &&
      formValues.leaseTermId &&
      formValues.leaseTermId !== 'default'
    ) {
      const endDate = leaseEndEnhance
        ? calculateLeaseEndDateV2(
            startDate,
            leaseTermId,
            leaseTerms,
            selectedProperty,
          )
        : calculateLeaseEndDate(
            startDate,
            leaseTermId,
            leaseTerms,
            selectedProperty?.isSetLeaseEndDateToEndOfMonth,
          );
      touch('FormLeaseDataTab', 'endDate');
      change('FormLeaseDataTab', 'endDate', endDate);
    } else {
      change('FormLeaseDataTab', 'endDate', null);
    }
  };

  const onChangeLeaseTermId =
    (startDate: any) =>
    ({ target: { value } }: any) => {
      const momentStartDate = startDate ? moment(startDate) : moment('----');
      if (momentStartDate.isValid() && leaseTerms) {
        const endDate = leaseEndEnhance
          ? calculateLeaseEndDateV2(
              momentStartDate,
              value,
              leaseTerms,
              selectedProperty,
            )
          : calculateLeaseEndDate(
              momentStartDate,
              value,
              leaseTerms,
              selectedProperty?.isSetLeaseEndDateToEndOfMonth,
            );
        touch('FormLeaseDataTab', 'endDate');
        change('FormLeaseDataTab', 'endDate', endDate);
      } else {
        change('FormLeaseDataTab', 'endDate', null);
      }
    };

  const handleMoveInDateChange = (moveInDate: any) =>
    updateLeaseDate('moveInDate', moveInDate);

  const [endDateChanged, setEndDateChanged] = useState(false);
  const [isEndDateOverLimit, setIsEndDateOverLimit] = useState(
    Boolean(lease.overrideLeaseExpirationLimitUpdatedAt),
  );

  useEffect(() => {
    setIsEndDateOverLimit(Boolean(lease.overrideLeaseExpirationLimitUpdatedAt));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(lease.overrideLeaseExpirationLimitUpdatedAt)]);

  const [overLimitMap, setOverLimitMap] = useState({});

  useEffect(() => {
    if (formAsyncErrors?.endDate) {
      setOverLimitMap((p) => ({
        ...p,
        [moment(formValues.endDate).format('YYYY-MM')]: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formAsyncErrors?.endDate]);

  useEffect(() => {
    if (formValues.overrideLeaseExpirationLimit === false) {
      setIsEndDateOverLimit(
        overLimitMap[moment(formValues.endDate).format('YYYY-MM')] ?? false,
      );
    }
  }, [
    overLimitMap,
    formValues.endDate,
    formValues.overrideLeaseExpirationLimit,
  ]);

  const onChangeEndDate = (date) => {
    change('FormLeaseDataTab', 'endDate', date);
    change('FormLeaseDataTab', 'overrideLeaseExpirationLimit', false);
    setEndDateChanged(true);
  };

  useEffect(() => {
    setEndDateChanged(false);
  }, [lease.overrideLeaseExpirationLimitUpdatedAt]);

  const { isLeaseExpirationLimitsActive } = useExpirationLimitsPropertyToggle(
    selectedProperty?.organizationId,
    selectedProperty?.id,
    promptToaster,
  );

  return (
    <Col xs={12} sm={6}>
      <Row>
        <Col xs={6}>
          <label className="required">
            {intl.formatMessage(messages.numberOfKeysLabel)}
          </label>
        </Col>
        <Col xs={5}>
          <div className="form-group">
            <Field
              name="numberOfKeys"
              component={renderSelectField}
              options={generateNumberOfKeys(11)}
              disabled={!isEditable}
            >
              <option value="default">Choose</option>
            </Field>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <label className="required">
            {intl.formatMessage(messages.scheduledMoveInLabel)}
          </label>
        </Col>
        <Col xs={5}>
          <Field
            bsSize="md"
            className="input-group"
            component={renderDateField}
            dateFormat={extractCurrentDateFormat(intl)}
            disabled={!isEditable || lease.isRenewal}
            isValidDate={isValidDate}
            name="moveInDate"
            placeholder={extractCurrentDateFormat(intl)}
            onChange={handleMoveInDateChange}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <label className="required">
            {intl.formatMessage(messages.leaseTermLabel)}
          </label>
        </Col>
        <Col xs={5}>
          <div className="form-group">
            <Field
              name="leaseTermId"
              component={renderSelectField}
              options={getLeaseTermOptions(leaseTerms)}
              onChange={onChangeLeaseTermId(formValues.startDate)}
              disabled={!isEditable}
            />
          </div>
        </Col>
        <Col xs={1}>
          <EditLeaseDataLink
            showEditLink={showEditLink}
            showLeaseDataModal={showLeaseDataModal}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <label className="required">
            {intl.formatMessage(messages.leaseStartDateLabel)}
          </label>
        </Col>
        <Col xs={5}>
          <Field
            bsSize="md"
            className="input-group"
            component={renderDateField}
            dateFormat={extractCurrentDateFormat(intl)}
            disabled={!isEditable}
            name="startDate"
            onChange={onChangeLeaseStartDate(formValues.leaseTermId)}
            placeholder={extractCurrentDateFormat(intl)}
            noticeToVacate={noticeToVacate}
          />
        </Col>
        <Col xs={1}>
          <EditLeaseDataLink
            showEditLink={showEditLink}
            showLeaseDataModal={showLeaseDataModal}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <label className="required">
            {intl.formatMessage(messages.leaseEndDateLabel)}
          </label>
        </Col>
        <Col xs={5}>
          <Field
            name="endDate"
            component={renderDateField}
            bsSize="md"
            placeholder={extractCurrentDateFormat(intl)}
            dateFormat={extractCurrentDateFormat(intl)}
            className="input-group"
            disabled={leaseEndDateDisabled}
            onChange={onChangeEndDate}
          />
        </Col>
        <Col xs={1}>
          <EditLeaseDataLink
            showEditLink={showEditLink}
            showLeaseDataModal={showLeaseDataModal}
          />
        </Col>
      </Row>
      {flags.leaseExpirationMgmtV1 && isLeaseExpirationLimitsActive && (
        <Row>
          <Col xs={6}></Col>
          <Col xs={5}>
            {!isNil(lease.overrideLeaseExpirationLimitUpdatedAt) &&
              isEndDateOverLimit &&
              !endDateChanged && (
                <span
                  className="text-gray--darker"
                  aria-label="override-lease-expiration-legend"
                >
                  {intl.formatMessage(
                    messages.overrideLeaseExpirationLimitMessage,
                    {
                      ...lease.overrideLeaseExpirationLimitUpdatedBy,
                      date: moment(
                        lease.overrideLeaseExpirationLimitUpdatedAt,
                      ).format('MM/DD/YYYY'),
                    },
                  )}
                </span>
              )}
            <ElementWithPermissions
              scope={['lease-expirations-limit-override']}
            >
              <Field
                name="overrideLeaseExpirationLimit"
                component={renderCheckboxField}
                label={intl.formatMessage(
                  messages.overrideLeaseExpirationLimitLabel,
                )}
                disabled={!isEditable || !isEndDateOverLimit}
                aria-label="override-lease-expiration-limit"
              />
            </ElementWithPermissions>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={6}>
          <label className="required">
            {intl.formatMessage(messages.numberOfSatellites)}
          </label>
        </Col>
        <Col xs={5}>
          <div className="form-group">
            <Field
              name="numSatellites"
              options={range(0, 4).map((n) => ({
                value: n,
                text: n,
                disabled: false,
              }))}
              component={renderSelectField}
              disabled={!isEditable}
            />
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators({ change, touch }, dispatch);
  return { actions };
}

export default connect(null, mapDispatchToProps)(LeaseBasicsRightColumn);
