// @flow

import React from 'react';
import { Button } from 'react-bootstrap';
import messages from '../messages';
import type { Intl } from '../../../types';

export type Props = {
  intl: Intl,
  returnToLogin: () => void,
};

export default ({ intl, returnToLogin }: Props): any => {
  return (
    <div>
      <div className="login-details text-center">
        <div className="confirm-icon">
          <i className="et-checkbox icon" />
        </div>
        <h4>{intl.formatMessage(messages.passwordUpdateTitle)}</h4>
        <p>{intl.formatMessage(messages.passwordUpdateMessage)}</p>
      </div>
      <div className="loginform">
        <Button className="btn-primary" onClick={returnToLogin} type="submit">
          {intl.formatMessage(messages.goToSignIn)}
        </Button>
      </div>
    </div>
  );
};
