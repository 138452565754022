import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import React from 'react';
import { ControlLabel } from 'react-bootstrap';
import styled from 'styled-components';
import type { Option } from './types';

type Props = {
  label: string,
  options: Option[],
  onChange: Function,
  value: Option[],
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDiv = styled.div`
  display: block;
`;

function MultiCheckboxControl({
  label,
  options,
  onChange,
  value,
  width,
  ...otherProps
}: Props) {
  return (
    <Wrapper>
      <ControlLabel>{label}</ControlLabel>
      <StyledDiv>
        {options.map((option) => (
          <Checkbox
            key={option.value}
            name={option.value}
            label={option.text}
            checked={value.includes(option.value)}
            disabled={option.disabled}
            onChange={onChange}
            {...otherProps}
          />
        ))}
      </StyledDiv>
    </Wrapper>
  );
}

export default MultiCheckboxControl;
