import { useState } from 'react';
import MiniProfileFormPanel from '../MiniProfileFormPanel';
import ReportingInformationForm from './ReportingInformationForm';

// eslint-disable-next-line max-len
import { useFetchSpecialNeedsDesignationOptions } from '../../hooks/data-fetching/useFetchSpecialNeedsDesignationsOptions';

const PANEL_TITLE = 'Reporting Information';

type Props = {
  openPanel: ?string,
  togglePanel: Function,
  changeFunc: Function,
  property: Object,
  unit: Object,
  initialValues: any,
  isFinanciallyResponsibleAdult: boolean,
  customerType: 'Applicant' | 'Resident',
};

const ReportingInformationPanel = (props: Props) => {
  const {
    togglePanel,
    openPanel,
    changeFunc,
    initialValues,
    property,
    unit,
    isFinanciallyResponsibleAdult,
    showSpecialNeeds,
    showMINCField,
  } = props;
  const [editable, setEditable] = useState(false);
  const { data: specialNeedsOptions, isLoading: isLoadingSpecialNeedsOptions } =
    useFetchSpecialNeedsDesignationOptions();

  const toggleEdit = () => {
    setEditable(!editable);
  };

  const propertyClassName = property?.propertyClass?.name ?? '';
  const isConventional = propertyClassName === 'Conventional';
  const isMixed = propertyClassName === 'Mixed';
  const isAffordableFloorPlan = unit?.floorPlan?.isAffordable ?? false;
  const isMixedAndNotAffordableUnit = isMixed && !isAffordableFloorPlan;
  const showAnnualIncome =
    (isConventional || isMixedAndNotAffordableUnit) &&
    isFinanciallyResponsibleAdult;

  return (
    <MiniProfileFormPanel
      open={openPanel === PANEL_TITLE}
      toggleOpen={togglePanel(PANEL_TITLE)}
      title={PANEL_TITLE}
    >
      <div className="container-fluid padleft5 padright5 padtop15 padbottom15">
        <div className="row">
          <div className="col-md-12">
            <a
              onClick={() => toggleEdit()}
              className="small simple-link pull-right"
            >
              <i className="icon et-pencil" />
              <span>Edit</span>
            </a>
          </div>
        </div>
        <ReportingInformationForm
          change={changeFunc}
          disabled={!editable}
          initialValues={initialValues}
          showAnnualIncome={showAnnualIncome}
          isLoadingSpecialNeedsOptions={isLoadingSpecialNeedsOptions}
          specialNeedsOptions={specialNeedsOptions}
          showMINCField={showMINCField}
          showSpecialNeeds={showSpecialNeeds}
        />
      </div>
    </MiniProfileFormPanel>
  );
};

export default ReportingInformationPanel;
