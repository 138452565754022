import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { completeTransfer, getTransferRequirements } from './actions';
import { TransferForm } from './TransferForm';
import messages from './messages';
import confirm from '../../../components/ConfirmDialogModal';
import type { InjectedProps, Props, State } from './types';

class TransferContainer extends Component<Props & InjectedProps, State> {
  constructor(props: Props & InjectedProps) {
    super(props);
    this.state = {
      actualTransferDate: moment().format('MM/DD/YYYY'),
      transferActions: [],
    };
  }
  componentDidMount() {
    this.props.actions.getTransferRequirements({
      leaseId: this.props.leaseId,
      userId: this.props.userId,
    });
  }

  handleTransferDateUpdate = (
    transferActionsField: Array<any>,
    newTransferDate: any,
  ) => {
    this.setState({
      actualTransferDate: moment(newTransferDate).format('MM/DD/YYYY'),
      transferActions: transferActionsField,
    });
  };

  handleCompleteTransfer = () => {
    const { userId, leaseId, residentId, intl } = this.props;
    const { actualTransferDate } = this.state;

    confirm(intl.formatMessage(messages.confirmTransferHeader), {
      intl,
      body: intl.formatMessage(messages.confirmTransferBody),
    }).then(() => {
      this.props.handleCancel();
      this.props.actions.completeTransfer({
        userId,
        leaseId,
        actualTransferDate,
        residentId,
      });
    });
  };

  render() {
    return (
      <TransferForm
        {...this.props}
        handleTransferDateUpdate={this.handleTransferDateUpdate}
        initialValues={{
          actualTransferDate: this.state.actualTransferDate,
          transferActions: this.state.transferActions,
        }}
        handleSubmit={this.handleCompleteTransfer}
      />
    );
  }
}

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      getTransferRequirements,
      completeTransfer,
    },
    dispatch,
  );
  return { actions };
}
const mapStateToProps = (state: Object): any => {
  const { transferResidentInformation } = state;
  return {
    steps: transferResidentInformation.steps || [],
    transferActions: transferResidentInformation.actionsChecklist || [],
    loadingRequirements: transferResidentInformation.loadingRequirements,
    submitting: transferResidentInformation.submitting,
  };
};
export const TransferScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransferContainer);
