import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { partial, lt, ifElse, equals, always, isNil } from 'ramda';

import Minor from './Minor';
import messages from './messages';

import type { FieldArrayProps } from 'redux-form';

type Props = {
  fields: FieldArrayProps,
  onAddNew: Function,
  onDelete: Function,
  suffixList: Array<Object>,
  minorRelationshipList: Array<Object>,
  affordableRelationship: Array<Object>,
  maxOccupants: number,
  fieldsRequired: boolean,
  fieldsReadOnly: boolean,
  showEditButton: boolean,
  onEdit: Function,
  isAffordable: boolean,
  leaseLocked: boolean,
};

const MinorList = ({
  fields,
  suffixList,
  minorRelationshipList,
  affordableRelationship,
  onAddNew,
  onDelete,
  showEditButton,
  onEdit,
  maxOccupants,
  fieldsRequired,
  fieldsReadOnly,
  isAffordable,
  leaseLocked,
}: Props) => {
  const showAddButton = lt(fields.length, maxOccupants);
  const requiredIndicator = ifElse(
    equals(true),
    always('*'),
    always(''),
  )(fieldsRequired);
  return (
    <div className="applicant-grp">
      <Row className="block-heading">
        <Col xs={6} sm={10}>
          <h2>
            <FormattedMessage {...messages.minorsTitle} />
          </h2>
        </Col>
        {showEditButton && (
          <Col xs={6} sm={2}>
            <div className="float-right">
              <a
                className="btn-text"
                onClick={partial(onEdit, [undefined])}
                disabled={leaseLocked}
              >
                <i className="icon et-pencil" />
                Edit
              </a>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <div className="labels-head container-of-inputs">
          <Col xs={6} sm={2}>
            <label>
              <FormattedMessage {...messages.firstName} />
              {requiredIndicator}
            </label>
          </Col>
          <Col xs={6} sm={2}>
            <label>
              <FormattedMessage {...messages.middleName} />
            </label>
          </Col>
          <Col xs={6} sm={2}>
            <label>
              <FormattedMessage {...messages.lastName} />
              {requiredIndicator}
            </label>
          </Col>
          <Col xs={6} sm={1}>
            <label>
              <FormattedMessage {...messages.nameSuffix} />
            </label>
          </Col>
          <Col xs={6} sm={1}>
            <label>
              <FormattedMessage {...messages.preferredName} />
            </label>
          </Col>
          {isAffordable && (
            <Col xs={6} sm={1}>
              <label>
                <FormattedMessage {...messages.affordableRelation} />
                {requiredIndicator}
              </label>
            </Col>
          )}
          <Col xs={6} sm={2}>
            <label>
              <FormattedMessage {...messages.relationShip} />
              {requiredIndicator}
            </label>
          </Col>
          <Col xs={6} sm={isAffordable ? 1 : 2}>
            <label>
              <FormattedMessage {...messages.age} />
            </label>
          </Col>
        </div>
        <div className="container-of-trash">
          <div className="row-remove-btn">
            <label>
              <FormattedMessage {...messages.delete} />
            </label>
          </div>
        </div>
      </Row>
      {fields.map((member, index) => (
        <Minor
          field={member}
          key={`${member}${index}`}
          onDelete={partial(onDelete, [index])}
          suffixList={suffixList}
          minorRelationshipList={minorRelationshipList}
          affordableRelationship={affordableRelationship}
          fieldsReadOnly={fieldsReadOnly}
          isNew={isNil(fields.get(index).id)}
          isAffordable={isAffordable}
          leaseLocked={leaseLocked}
        />
      ))}
      {showAddButton && (
        <Row>
          <a className="btn-text" onClick={onAddNew} disabled={leaseLocked}>
            <FormattedMessage {...messages.addMinor} />
          </a>
        </Row>
      )}
    </div>
  );
};

export default MinorList;
