/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  leaseBasics: {
    id: 'App.Commercial.LDT.LeaseBasics.LeaseBasics',
    defaultMessage: 'Lease Basics',
  },
  leaseTypeField: {
    id: 'App.Commercial.LDT.LeaseBasics.LeaseTypeField',
    defaultMessage: 'Lease Type *',
  },
  tenantLegalName: {
    id: 'App.Commercial.LDT.LeaseBasics.TenantLegalName',
    defaultMessage: 'Tenant Legal Name',
  },
  dba: {
    id: 'App.Commercial.LDT.LeaseBasics.DBA',
    defaultMessage: 'DBA',
  },
  parentCompany: {
    id: 'App.Commercial.LDT.LeaseBasics.ParentCompany',
    defaultMessage: 'Parent Company',
  },
  rentableSquareFeet: {
    id: 'App.Commercial.LDT.LeaseBasics.RentableSquareFeet',
    defaultMessage: 'Rentable Sq ft.',
  },
  lateMethodField: {
    id: 'App.Commercial.LDT.LeaseBasics.LateMethodField',
    defaultMessage: 'Late Method *',
  },
  overallLeaseCommentsField: {
    id: 'App.Commercial.LDT.LeaseBasics.OverallLeaseCommentsField',
    defaultMessage: 'Overall Comments',
  },
  overallLeaseCommentsPlaceholder: {
    id: 'App.Commercial.LDT.LeaseBasics.OverallLeaseCommentsPlaceholder',
    defaultMessage: 'Enter Text',
  },
  retailTypeField: {
    id: 'App.Commercial.LDT.LeaseBasics.RetailTypeField',
    defaultMessage: 'Retail Type *',
  },
  unitNumber: {
    id: 'App.Commercial.LDT.LeaseBasics.UnitNumber',
    defaultMessage: 'Unit Number',
  },
  unitAddress: {
    id: 'App.Commercial.LDT.LeaseBasics.UnitAddress',
    defaultMessage: 'Unit Address',
  },
  scheduledMoveInField: {
    id: 'App.Commercial.LDT.LeaseBasics.ScheduledMoveInField',
    defaultMessage: 'Scheduled Move-in *',
  },
  leaseTerm: {
    id: 'App.Commercial.LDT.LeaseBasics.LeaseTerm',
    defaultMessage: 'Lease Term',
  },
  leaseEffectiveDateField: {
    id: 'App.Commercial.LDT.LeaseBasics.LeaseEffectiveDateField',
    defaultMessage: 'Lease Effective Date',
  },
  commencementDateField: {
    id: 'App.Commercial.LDT.LeaseBasics.CommencementDateField',
    defaultMessage: 'Commencement Date *',
  },
  leaseEndDateField: {
    id: 'App.Commercial.LDT.LeaseBasics.LeaseEndDateField',
    defaultMessage: 'Lease End Date *',
  },
  rentStartDateField: {
    id: 'App.Commercial.LDT.LeaseBasics.RentStartDateField',
    defaultMessage: 'Rent Start Date *',
  },
  estimatedRentDateField: {
    id: 'App.Commercial.LDT.LeaseBasics.EstimatedRentDateField',
    defaultMessage: 'Estimated Rent Date? *',
  },
  invalidDateError: {
    id: 'App.Commercial.LDT.LeaseBasics.InvalidDateError',
    defaultMessage: 'Invalid Date',
  },
  dateBeforeEarliestValidMoveInDateError: {
    id: 'App.Commercial.LDT.LeaseBasics.DateBeforeEarliestValidMoveInDateError',
    defaultMessage: 'Cannot be more than 180 days before Commencement Date.',
  },
  date180DaysAfterMoveInDateError: {
    id: 'App.Commercial.LDT.LeaseBasics.Date180DaysAfterMoveInDateError',
    defaultMessage:
      'Cannot be more than 180 days after Scheduled Move-In Date.',
  },
  dateBeforeCommencementError: {
    id: 'App.Commercial.LDT.LeaseBasics.DateBeforeCommencementError',
    defaultMessage: 'Cannot be before Commencement Date.',
  },
  dateAfterCommencementError: {
    id: 'App.Commercial.LDT.LeaseBasics.DateAfterCommencementError',
    defaultMessage: 'Cannot be after Commencement Date.',
  },
  dateAfterRentStartError: {
    id: 'App.Commercial.LDT.LeaseBasics.DateAfterRentStartError',
    defaultMessage: 'Cannot be after Rent Start Date.',
  },
  dateAfterLeaseEndError: {
    id: 'App.Commercial.LDT.LeaseBasics.DateAfterLeaseEndError',
    defaultMessage: 'Cannot be after Lease End Date.',
  },
  dateAfterMoveInError: {
    id: 'App.Commercial.LDT.LeaseBasics.DateAfterMoveInError',
    defaultMessage: 'Cannot be after Scheduled Move-In Date.',
  },
  dateBeforeTodayError: {
    id: 'App.Commercial.LDT.LeaseBasics.DateBeforeTodayError',
    defaultMessage: 'Cannot be in the past.',
  },
  dateMustBeAfterMoveInError: {
    id: 'App.Commercial.LDT.LeaseBasics.DateMustBeAfterMoveInError',
    defaultMessage: 'Must be after Scheduled Move-In Date.',
  },
  validDateBeforeEndDate: {
    id: 'App.Commercial.LDT.LeaseBasics.validDateBeforeEndDate',
    defaultMessage: "Can't select a date before previous lease's end date",
  },
  required: {
    id: 'App.Commercial.LDT.LeaseBasics.Required',
    defaultMessage: 'Required Field',
  },
  leaseRenewalButton: {
    id: 'App.Commercial.LDT.LeaseBasics.leaseRenewalButton',
    defaultMessage: 'Start Lease Renewal',
  },
  cancelRenewalButton: {
    id: 'App.Commercial.LDT.LeaseBasics.CancelRenewalButton',
    defaultMessage: 'Cancel Renewal',
  },
  completeRenewalButton: {
    id: 'App.Commercial.LDT.LeaseBasics.CompleteRenewalButton',
    defaultMessage: 'Complete Renewal',
  },
  cancelRenewalConfirmationHeader: {
    id: 'App.Commercial.LDT.LeaseBasics.CancelRenewalConfirmationHeader',
    defaultMessage: 'Are you sure you want to cancel this renewal?',
  },
  cancelRenewalConfirmationBody: {
    id: 'App.Commercial.LDT.LeaseBasics.CancelRenewalConfirmationBody',
    defaultMessage: 'Canceling this renewal will:',
  },
  cancelRenewalConfirmationBody1: {
    id: 'App.Commercial.LDT.LeaseBasics.CancelRenewalConfirmationBody1',
    defaultMessage: '1. Remove all lease information from the Lease Data tab',
  },
  cancelRenewalConfirmationBody2: {
    id: 'App.Commercial.LDT.LeaseBasics.CancelRenewalConfirmationBody2',
    defaultMessage: '2. Remove the renewal lease from the Documents tab and',
  },
  cancelRenewalConfirmationBody3: {
    id: 'App.Commercial.LDT.LeaseBasics.CancelRenewalConfirmationBody3',
    defaultMessage:
      '3. Reverse prorated charges and re-post the first of monthly charges to the ledger for the current lease, if applicable.',
  },
  completeDisabled: {
    id: 'App.Commercial.LDT.LeaseBasics.completeDisabled',
    defaultMessage: 'Lease is missing required fields',
  },
  completeDisabledDirty: {
    id: 'App.Commercial.LDT.LeaseBasics.completeDisabledDirty',
    defaultMessage: 'Must save changes to complete',
  },
  validNumber: {
    id: 'App.Commercial.LDT.LeaseBasics.validNumber',
    defaultMessage: 'Value must be positive',
  },
  completeDisabledDoNotRenew: {
    id: 'App.Commercial.LDT.LeaseBasics.completeDisabledDoNotRenew',
    defaultMessage: '"Do Not Renew" is selected',
  },
  completeDisabledUnderEviction: {
    id: 'App.Commercial.LDT.LeaseBasics.completeDisabledUnderEviction',
    defaultMessage: '"Under Eviction" is selected.',
  },
  completeDisabledAlreadyCompleted: {
    id: 'App.Commercial.LDT.LeaseBasics.completeDisabledAlreadyCompleted',
    defaultMessage: 'Renewal lease is already completed.',
  },
  headsUp: {
    id: 'App.Commercial.LDT.LeaseBasics.headsUp',
    defaultMessage: 'Heads Up!',
  },
  confirmationMessage: {
    id: 'App.Commercial.LDT.LeaseBasics.confirmationMessage',
    defaultMessage:
      'This household is on Notice to Vacate. Starting a lease renewal will not impact the notice to vacate. Are you sure you want to start this lease renewal?',
  },
  dateMustBeOnOrAfterRenewalDate: {
    id: 'App.Commercial.LDT.LeaseBasics.DateMustBeOnOrAfterRenewalDate',
    defaultMessage: 'Start Date cannot be after Anticipated Move-Out Date',
  },
});

export default messages;
