import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.CreateRole.CreateRoleForm.Header',
    defaultMessage: 'Add role'
  },
  subheader: {
    id: 'App.CreateRole.CreateRoleForm.Subheader',
    defaultMessage: 'All fields are required'
  },
  selectPermissions: {
    id: 'App.CreateRole.CreateRoleForm.SelectPermissions',
    defaultMessage: 'Select permissions'
  },
  cancel: {
    id: 'App.CreateRole.CreateRoleForm.Cancel',
    defaultMessage: 'Cancel'
  },
  saveAndClose: {
    id: 'App.CreateRole.CreateRoleForm.SaveAndClose',
    defaultMessage: 'Save and Close'
  },
  saveAndAddNew: {
    id: 'App.CreateRole.CreateRoleForm.SaveAndAddNew',
    defaultMessage: 'Save and Add New'
  },
  roleName: {
    id: 'App.CreateRole.CreateRoleForm.RoleName',
    defaultMessage: 'Role name'
  },
  roleDescription: {
    id: 'App.CreateRole.CreateRoleForm.RoleDescription',
    defaultMessage: 'Role description'
  },
  roleType: {
    id: 'App.CreateRole.CreateRoleForm.RoleType',
    defaultMessage: 'Role type'
  },
  requiredFields: {
    id: 'App.CreateRole.CreateRoleForm.RequiredFields',
    defaultMessage: 'Required'
  },
  invalidRoleName: {
    id: 'App.CreateRole.CreateRoleForm.InvalidRoleName',
    defaultMessage: 'Role name must have letters, digits or - _ . # @ ( ) /only'
  },
  invalidRoleNameLength: {
    id: 'App.CreateRole.CreateRoleForm.InvalidRoleNameLength',
    defaultMessage: 'Role name must be 50 characters long at most'
  },
  invalidRoleDescription: {
    id: 'App.CreateRole.CreateRoleForm.InvalidRoleDescription',
    defaultMessage: 'Role description must have letters, digits or - _ . # @ ( ) / only'
  },
  invalidRoleDescriptionLength: {
    id: 'App.CreateRole.CreateRoleForm.InvalidRoleDescriptionLength',
    defaultMessage: 'Role description must be 250 characters long at most'
  },
  selectPermission: {
    id: 'App.CreateRole.CreateRoleForm.SelectPermission',
    defaultMessage: 'Please select at least 1 permission'
  },
  canBeAssignedWorkOrder: {
    id: 'App.CreateRole.CreateRoleForm.canBeAssignedWorkOrder',
    defaultMessage: 'Can be assigned a work order'
  },
  canBeAssignedProspect: {
    id: 'App.CreateRole.CreateRoleForm.canBeAssignedProspect',
    defaultMessage: 'Can be assigned a prospect'
  },
  nameCharacterMax: {
    id: 'App.CreateRole.CreateRoleForm.NameCharacterMax',
    defaultMessage: 'Role name must be 50 characters or less'
  },
  descriptionCharacterMax: {
    id: 'App.CreateRole.CreateRoleForm.DescriptionCharacterMax',
    defaultMessage: 'Role description must be 250 characters or less'
  }
});

export default messages;
