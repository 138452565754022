import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { COMPLIANCE_TYPES } from './constantsV2';
import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

const ComplianceOverviewNotes = ({ complianceType }) => {
  return complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW ? (
    <Box paddingX={2}>
      <Typography component="span">
        <FormattedMessage {...messages.noteCustom} values={{ note: '' }} />
      </Typography>
      <Typography variant="caption">
        <FormattedMessage {...messages.needsReviewHouseholdNote} />
      </Typography>
    </Box>
  ) : null;
};

export default ComplianceOverviewNotes;
