import { defineMessages } from 'react-intl';

const messages = defineMessages({
  status: {
    id: 'App.TransactionBatchesGrid.Status',
    defaultMessage: 'Status',
  },
  transactionDate: {
    id: 'App.TransactionBatchesGrid.TransactionDate',
    defaultMessage: 'Transaction Date',
  },
  postedOnBy: {
    id: 'App.TransactionBatchesGrid.PostedOnBy',
    defaultMessage: 'Posted On / By',
  },
  period: {
    id: 'App.TransactionBatchesGrid.Period',
    defaultMessage: 'Period',
  },
  transactionDetails: {
    id: 'App.TransactionBatchesGrid.TransactionDetails',
    defaultMessage: 'Transaction Details',
  },
  note: {
    id: 'App.TransactionBatchesGrid.Note',
    defaultMessage: 'Note',
  },
  editOrView: {
    id: 'App.TransactionBatchesGrid.EditOrView',
    defaultMessage: 'Edit / View',
  },
  noDataDescription: {
    id: 'App.TransactionBatchesGrid.NoDataDescription',
    defaultMessage: 'There is currently no data to display',
  },
  notes: {
    id: 'App.TransactionBatchesGrid.Notes',
    defaultMessage: 'NOTES:',
  },
  notes1: {
    id: 'App.TransactionBatchesGrid.Notes1',
    defaultMessage:
      '(1) Transactions entered here do not post to Resident Ledgers until the Transaction Batch is "Posted."',
  },
  notes2: {
    id: 'App.TransactionBatchesGrid.Notes2',
    defaultMessage:
      '(2) Edits to the transaction batch can be made until the batch has been posted.',
  },
  notes3Residents: {
    id: 'App.TransactionBatchesGrid.Notes3Residents',
    defaultMessage:
      // eslint-disable-next-line max-len
      '(3) The transaction batches can be created only for current residents and for transaction types: Charge or Credit.',
  },
  notes3Tenants: {
    id: 'App.TransactionBatchesGrid.Notes3Tenants',
    defaultMessage:
      // eslint-disable-next-line max-len
      '(3) The transaction batches can be created only for current tenants and for transaction types: Charge or Credit.',
  },
  totalBatchAmount: {
    id: 'App.TransactionBatchesGrid.TotalBatchAmount',
    defaultMessage: 'Total Batch Amount:',
  },
  households: {
    id: 'App.TransactionBatchesGrid.Households',
    defaultMessage: 'Households:',
  },
  totalTransactions: {
    id: 'App.TransactionBatchesGrid.TotalTransactions',
    defaultMessage: 'Total Transactions:',
  },
});

export default messages;
