import React from 'react';
import moment from 'moment';
import {
  Button,
  ButtonToolbar,
  Col,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { curryN } from 'ramda';
import { FormattedDate, FormattedTime, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import {
  useActivityFilters,
  useActivityFilterOptions,
  useFetchActivities,
  useAppliedFilters,
  useIsFilterActive,
} from './hooks';

import PaginationFooter from '../PaginationFooter';
import ColumnHeader from '../ColumnHeader';
import FilterIconButton from '../FilterIconButton';
import ElementWithPermissions from '../ElementWithPermissions';
import AppliedFilters from '../AppliedFilters';

import messages from './messages';
import FilterControls from '../../components/FilterControls';

import type { Activity } from './types';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import { processActivityNotes } from './utils';

type Props = {
  intl: Object,
  selectedProperty: string,
  prospectId: string,
  activities: Array<Activity>,
  locale: string,
  onEditActivity: Function,
  onCreateActivityClick: Function,
  updateTrigger?: boolean,
};

const StyledHeaderDiv = styled.div`
  height: 80px;
  padding-bottom: 20px;
`;

const StyledComment = styled(Button)`
  cursor: initial;
`;

const StyledInlineDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 16px;
`;
const StyledYellowBox = styled.div`
  width: 10px;
  height: 10px;
  background-color: #fff1db;
  border: 1px solid #d1d1d1;
  margin-right: 8px;
`;

const StyledRedBox = styled.div`
  width: 10px;
  height: 10px;
  background-color: #fff0f0;
  border: 1px solid #d1d1d1;
  margin-right: 8px;
`;

const StyledFootNoteDiv = styled.div`
  padding-top: 16px;
`;

const StyledCheckmarkI = styled.i.attrs({
  className: 'icon et-isolated-check',
})`
  color: green;
`;

const getUserForActivityTable = (activity: Activity): string => {
  if (!activity.completionStatus) {
    if (activity.ownedBy) {
      return `${activity.ownedBy.firstName} ${activity.ownedBy.lastName}`;
    } else {
      return '---';
    }
  } else {
    return activity?.updatedBy?.username;
  }
};

const ActivityActions = ({
  activities,
  onCreateActivityClick,
  filters,
  isFilterActive,
  setIsFilterActive,
  isFilters,
}) => {
  return (
    <ButtonToolbar>
      <ElementWithPermissions scope={['activity-create']}>
        <button
          className="btn btn-shout pull-right"
          onClick={onCreateActivityClick}
        >
          <i className="et-calendar icon" />
          <FormattedMessage {...messages.createActivity} />
        </button>
      </ElementWithPermissions>
      <FilterIconButton
        className="pull-right"
        active={isFilterActive}
        disabled={(!activities || activities.length === 0) && !isFilters}
        onClick={() => setIsFilterActive(!isFilterActive)}
      />
    </ButtonToolbar>
  );
};

const ActivityTableDeprecated = ({
  intl,
  selectedProperty,
  prospectId,
  activities,
  locale,
  onEditActivity,
  onCreateActivityClick,
  updateTrigger,
}: Props) => {
  const [
    activityFilterOptions,
    activityFilterOptionsLoading,
    updateActivityFilterOptions,
  ] = useActivityFilterOptions({
    selectedProperty,
    prospectId,
  });

  const {
    filters,
    clearActivityFilters,
    handleFilterControlsChange,
    clearDateRange,
    clearExactDate,
    isFilters,
  } = useActivityFilters({
    activityFilterOptions,
  });

  const [
    allActivities,
    activitiesLoaded,
    page,
    limit,
    order,
    columnOrder,
    setPage,
    onOrderChange,
    refreshAllActivities,
  ] = useFetchActivities({
    selectedProperty,
    prospectId,
    filters,
    updateActivityFilterOptions,
    isFilters,
    shouldUpdate: updateTrigger, // to trigger a re-fetch since the table is not connected to redux
  });

  const { appliedFilters, handleRemoveFilter, handleClearAll } =
    useAppliedFilters(
      filters,
      handleFilterControlsChange,
      clearActivityFilters,
      clearDateRange,
      clearExactDate,
      activityFilterOptions,
    );

  const { isFilterActive, setIsFilterActive } = useIsFilterActive({
    allActivities,
    filters,
    isFilters,
  });

  const onOrderClickCurried = curryN(2, onOrderChange);

  const getUpcomingStatus = (activity: Activity) => {
    const now = activity.allDay ? moment().startOf('day') : moment();
    const activityStartTime = moment(activity.startTime);

    if (activityStartTime.isAfter(now)) {
      return intl.formatMessage(messages.upcoming);
    } else {
      return intl.formatMessage(messages.pastdue);
    }
  };

  const getUpcomingStatusClass = (activity: Activity) => {
    const now = activity.allDay ? moment().startOf('day') : moment();
    const activityStartTime = moment(activity.startTime);
    if (activityStartTime.isAfter(now)) {
      return 'rowYellow';
    } else {
      return 'highlight';
    }
  };

  return (
    <div aria-label="Activity Table">
      <StyledHeaderDiv>
        <Col xs={12} sm={8}>
          <h2>
            <FormattedMessage {...messages.activityHistory} />
          </h2>
        </Col>
        <Col xs={12} sm={4}>
          <ActivityActions
            activities={allActivities?.results}
            onCreateActivityClick={() =>
              onCreateActivityClick(refreshAllActivities)
            }
            filters={filters}
            isFilterActive={isFilterActive}
            setIsFilterActive={setIsFilterActive}
            isFilters={isFilters}
          />
        </Col>
        {isFilterActive && (
          <Col xs={12}>
            {appliedFilters.length > 0 && (
              <>
                <AppliedFilters
                  filters={appliedFilters}
                  onRemoveFilter={handleRemoveFilter}
                  onClearAll={handleClearAll}
                />
                <div className="divider" />
              </>
            )}
            <div style={{ padding: '10px 0px' }}>
              <FilterControls
                activityFilterOptionsLoading={activityFilterOptionsLoading}
                statuses={activityFilterOptions?.statuses ?? []}
                users={activityFilterOptions?.users ?? []}
                types={activityFilterOptions?.types ?? []}
                onChange={handleFilterControlsChange}
                setPage={setPage}
                clearDateRange={clearDateRange}
                clearExactDate={clearExactDate}
                values={filters}
              />
            </div>
          </Col>
        )}
        <Col xs={12}>
          <StyledInlineDiv>
            <StyledInlineDiv>
              <StyledYellowBox />
              <div>Upcoming</div>
            </StyledInlineDiv>
            <StyledInlineDiv>
              <StyledRedBox />
              <div>Past Due</div>
            </StyledInlineDiv>
          </StyledInlineDiv>
        </Col>
      </StyledHeaderDiv>
      {activitiesLoaded && (
        <>
          <table className="table table-prospects table-striped">
            <thead className="table-header">
              <tr>
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.dateTime)}
                  sortable={true}
                  icon={columnOrder.dateTime}
                  order={order}
                  onOrderClick={onOrderClickCurried('dateTime')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.automated)}
                  sortable={true}
                  icon={columnOrder.automated}
                  order={order}
                  onOrderClick={onOrderClickCurried('automated')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.status)}
                  sortable={true}
                  icon={columnOrder.status}
                  order={order}
                  onOrderClick={onOrderClickCurried('status')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.type)}
                  sortable={true}
                  icon={columnOrder.type}
                  order={order}
                  onOrderClick={onOrderClickCurried('type')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.user)}
                  sortable={true}
                  icon={columnOrder.user}
                  order={order}
                  onOrderClick={onOrderClickCurried('user')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.notes)}
                />
                <ColumnHeader columnLabel={intl.formatMessage(messages.edit)} />
              </tr>
            </thead>
            <tbody data-testid="activitiesRows">
              {allActivities.results.length > 0 ? (
                allActivities.results.map((activity, index) => {
                  const completionStatusName = activity.completionStatus
                    ? activity.completionStatus.translations[locale] ||
                      activity.completionStatus.name
                    : 'Scheduled - ' + getUpcomingStatus(activity);

                  const typeName = activity.activityType
                    ? activity.activityType.translations[locale] ||
                      activity.activityType.name
                    : '';
                  const assigneeName = getUserForActivityTable(activity);
                  return (
                    <tr
                      key={index}
                      className={`table-section ${
                        activity.completionStatus
                          ? ''
                          : getUpcomingStatusClass(activity)
                      }`}
                    >
                      <td>
                        {activity.allDay && !activity.completionStatus ? (
                          <FormattedDate value={activity.startTime} />
                        ) : (
                          <>
                            <FormattedDate
                              value={
                                activity.completionStatus
                                  ? activity.updatedAt
                                  : activity.startTime
                              }
                            />{' '}
                            -{' '}
                            <FormattedTime
                              value={
                                activity.completionStatus
                                  ? activity.updatedAt
                                  : activity.startTime
                              }
                            />
                          </>
                        )}
                      </td>
                      <td data-testid="automated">
                        {activity.isAutomated ? <StyledCheckmarkI /> : ''}
                      </td>
                      <td>{completionStatusName}</td>
                      <td>{typeName}</td>
                      <td>{assigneeName}</td>
                      <td>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`toolTipActivityNote${activity.id}`}>
                              {processActivityNotes(activity.notes)}
                            </Tooltip>
                          }
                        >
                          <StyledComment
                            bsStyle="default"
                            bsSize="small"
                            className="btn-comments"
                            disabled={!activity.notes}
                          >
                            <i className="icon et-comment-words" />
                          </StyledComment>
                        </OverlayTrigger>
                      </td>
                      <td>
                        <ElementWithPermissions
                          scope={[
                            'activity-create',
                            'activity-read',
                            'activity-update',
                            'activity-delete',
                          ]}
                        >
                          <Button
                            bsStyle="default"
                            bsSize="small"
                            onClick={() =>
                              onEditActivity(activity, refreshAllActivities)
                            }
                            disabled={activity.isAutomated}
                          >
                            <i className="et-pencil" />
                          </Button>
                        </ElementWithPermissions>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7}>No activities found</td>
                </tr>
              )}
            </tbody>
          </table>

          {allActivities.meta.pageCount > 1 && (
            <PaginationFooter
              currentPage={page}
              limit={limit}
              count={allActivities.meta.count}
              totalCount={allActivities.meta.totalCount}
              pageCount={allActivities.meta.pageCount}
              onPageChange={setPage}
            />
          )}

          <StyledFootNoteDiv>
            A*: Check mark indicates an automated activity
          </StyledFootNoteDiv>
        </>
      )}
      {!activitiesLoaded && <Spinner sx={{ width: '100%', minHeight: 400 }} />}
    </div>
  );
};

export default ActivityTableDeprecated;
