import React, { Fragment } from 'react';
import {
  PORTAL_NEVER_ACTIVE,
  PORTAL_ACTIVE_NEVER_ONBOARDED,
  PORTAL_ACTIVE_AND_PROCESSING,
  PORTAL_ACTIVE_AND_ONBOARDED,
  PORTAL_DEACTIVATED_NEVER_ONBOARDED,
  PORTAL_DEACTIVATED_AND_ONBOARDED,
  STATIC_FILTERS,
} from './constants';

const AVAIL_URL = process.env.REACT_APP_AVAILABILITY_URL || '';
const PORTAL_URL = process.env.REACT_APP_PORTAL_URL || '';

export const determinePortalStatus = (property: Object) => {
  const {
    isResidentPortalActive,
    onboardingQueue,
    portalLatestOnboardingGroupId,
    portalLatestOnboardingById,
    portalUpdatedAt,
  } = property;
  if (
    !isResidentPortalActive &&
    !portalLatestOnboardingGroupId &&
    !portalUpdatedAt
  ) {
    return PORTAL_NEVER_ACTIVE;
  }
  if (
    isResidentPortalActive &&
    !portalLatestOnboardingGroupId &&
    portalLatestOnboardingById === null
  ) {
    return PORTAL_ACTIVE_NEVER_ONBOARDED;
  }
  if (
    isResidentPortalActive &&
    ((portalLatestOnboardingGroupId && onboardingQueue > 0) ||
      (!portalLatestOnboardingGroupId && portalLatestOnboardingById))
  ) {
    return PORTAL_ACTIVE_AND_PROCESSING;
  }
  if (isResidentPortalActive && portalLatestOnboardingGroupId) {
    return PORTAL_ACTIVE_AND_ONBOARDED;
  }
  if (
    !isResidentPortalActive &&
    !portalLatestOnboardingGroupId &&
    portalUpdatedAt
  ) {
    return PORTAL_DEACTIVATED_NEVER_ONBOARDED;
  }
  if (!isResidentPortalActive && portalLatestOnboardingGroupId) {
    return PORTAL_DEACTIVATED_AND_ONBOARDED;
  }
};

export const determinedPortalActiveColumn = (
  property: Object,
  handleEditPropertyPortal: Function,
) => {
  const { portalStatus } = property;
  if (portalStatus === PORTAL_ACTIVE_AND_PROCESSING) {
    return (
      <span className="tag status-tag status-tag--orange-light">
        In Process
      </span>
    );
  }
  return [PORTAL_ACTIVE_NEVER_ONBOARDED, PORTAL_ACTIVE_AND_ONBOARDED].includes(
    portalStatus,
  ) ? (
    <a onClick={() => handleEditPropertyPortal(property)}>Yes</a>
  ) : (
    <a onClick={() => handleEditPropertyPortal(property)}>No</a>
  );
};

export const determinedPortalActiveColumnString = (portalStatus: any) => {
  if (portalStatus === PORTAL_ACTIVE_AND_PROCESSING) {
    return 'In Process';
  }

  return [PORTAL_ACTIVE_NEVER_ONBOARDED, PORTAL_ACTIVE_AND_ONBOARDED].includes(
    portalStatus,
  )
    ? 'Yes'
    : 'No';
};

export const determineLinkColumn = (
  portalStatus: ?string,
  orgPropUrl: string,
  contactUsWidget: boolean,
) => {
  if (
    [
      PORTAL_ACTIVE_NEVER_ONBOARDED,
      PORTAL_ACTIVE_AND_PROCESSING,
      PORTAL_ACTIVE_AND_ONBOARDED,
    ].includes(portalStatus)
  ) {
    return (
      <Fragment>
        <a href={`${AVAIL_URL}${orgPropUrl}`}>Availability</a> /{' '}
        <a href={`${PORTAL_URL}${orgPropUrl}`}>Portal</a>
        {contactUsWidget && (
          <>
            {' '}
            / <a href={`${PORTAL_URL}${orgPropUrl}/contact-us`}>Contact Us</a>
          </>
        )}
      </Fragment>
    );
  }
  return <a href={`${AVAIL_URL}${orgPropUrl}`}>Availability</a>;
};

export const determineLinkColumnString = (
  portalStatus: ?string,
  orgPropUrl: string,
) => {
  if (
    [
      PORTAL_ACTIVE_NEVER_ONBOARDED,
      PORTAL_ACTIVE_AND_PROCESSING,
      PORTAL_ACTIVE_AND_ONBOARDED,
    ].includes(portalStatus)
  ) {
    return `Availability: ${AVAIL_URL}${orgPropUrl}; Portal: ${PORTAL_URL}${orgPropUrl}`;
  }
  return `Availability: ${AVAIL_URL}${orgPropUrl}`;
};
export const getStreetAddress = (
  physicalStreetAddress1: string,
  physicalStreetAddress2: ?string,
) => {
  return [physicalStreetAddress1, physicalStreetAddress2]
    .filter(Boolean) // Physical Address 2 is optional
    .join(' ');
};

export const getFilters = (owners: Object) => [
  ...STATIC_FILTERS,
  {
    fieldName: 'ownerId',
    fieldDescription: 'Owner',
    options: owners.map((owner) => ({
      text: owner.name,
      value: owner.id,
    })),
  },
];

export const formatPhoneNumber = (phoneNumber: string) => {
  const cleanPhoneNumber = phoneNumber && phoneNumber.replace(/[^\d]/g, ''); // Remove all symbols from phone numbers
  return phoneNumber
    ? `(${cleanPhoneNumber.substr(0, 3)}) ${cleanPhoneNumber.substr(
        3,
        3,
      )}-${cleanPhoneNumber.substr(6, 4)}`
    : '---';
};
