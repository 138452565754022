import {
  LIHTC_PROGRAM_NAME,
  HUD_PROGRAM_NAME,
} from '../../AffordableQualificationTab/Shared/utils';

export { COMPLIANCE_TYPES } from '../constants';

export { LIHTC_PROGRAM_NAME, HUD_PROGRAM_NAME };

export const FORM_DEFAULTS = {
  isActive: 'All',
  isResident: 'All',
  status: 'All',
  waitlistApplicant: false,
  certificationType: [],
  complianceApprovalStatus: [],
  programType: [],
  complianceDocumentType: [],
  propertyId: [],
};

const intlKeySameAsKey = [
  'certificationType',
  'complianceDocumentType',
  'complianceApprovalStatus',
  'programType',
  'status',
  'propertyId',
  'none',
  'finalSignedPacket',
  'hudFinalSignedPacket',
  'hudSubmission',
  'submission',
  'noDocumentUploaded',
  'pastDue',
  'attentionRequired',
  'all',
].reduce((finalObject, key) => {
  return {
    ...finalObject,
    [key]: key,
  };
}, {});

export const valueToIntlKeyMap = Object.freeze({
  Initial: 'initial',
  Interim: 'interim',
  'Move-in': 'moveIn',
  'Move-out': 'moveOut',
  Recert: 'recert',
  Termination: 'termination',
  None: 'none',
  Yes: 'yes',
  No: 'no',
  [LIHTC_PROGRAM_NAME]: 'lihtc',
  [HUD_PROGRAM_NAME]: 'hud',
  isActive: 'active',
  isResident: 'resident',
  All: 'all',
  ...intlKeySameAsKey,
});
