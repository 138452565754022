import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'UnitHistory.header',
    defaultMessage: 'Unit History',
  },
  floorPlanColumn: {
    id: 'UnitHistory.floorPlanColumn',
    defaultMessage: 'Floor Plan',
  },
  setAsideColumn: {
    id: 'UnitHistory.setAsideColumn',
    defaultMessage: 'Set Aside',
  },
  changedOnColumn: {
    id: 'UnitHistory.changedOnColumn',
    defaultMessage: 'Changed On',
  },
  by: {
    id: 'UnitHistory.by',
    defaultMessage: 'by',
  },
  emptyStateMessage: {
    id: 'UnitHistory.emptyStateMessage',
    defaultMessage: 'No data available.',
  },
  residentHistory: {
    id: 'UnitHistory.residentHistory',
    defaultMessage: 'Resident History',
  },
  priorResident: {
    id: 'UnitHistory.priorResident',
    defaultMessage: 'Prior Resident',
  },
  moveInDate: {
    id: 'UnitHistory.moveInDate',
    defaultMessage: 'Move-In Date',
  },
  moveOutDate: {
    id: 'UnitHistory.moveOutDate',
    defaultMessage: 'Move-Out Date',
  },
});

export default messages;
