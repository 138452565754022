import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.OwnOrRentSection.Title',
    defaultMessage: 'Own or Rent?'
  },
  lenderName: {
    id: 'App.OwnOrRentSection.LenderName',
    defaultMessage: 'Lender Name'
  },
  phoneNumber: {
    id: 'App.OwnOrRentSection.PhoneNumber',
    defaultMessage: 'Phone Number'
  },
  lenderPhoneNumberPlaceholder: {
    id: 'App.OwnOrRentSection.LenderPhoneNumberPlaceholder',
    defaultMessage: 'Lender Phone Number'
  },
  landlordPhoneNumberPlaceholder: {
    id: 'App.OwnOrRentSection.LandlordPhoneNumberPlaceholder',
    defaultMessage: 'Landlord Phone Number'
  },
  landlordName: {
    id: 'App.OwnOrRentSection.LandlordName',
    defaultMessage: 'Landlord/Condo/Apartment Name'
  },
  doNotCurrentlyOwnRentExplanation: {
    id: 'App.OwnOrRentSection.DoNotCurrentlyOwnRentExplanation',
    defaultMessage: 'Do Not Currently Own Rent Explanation'
  },
  textAreaFieldPlaceholder: {
    id: 'App.OwnOrRentSection.TextAreaFieldPlaceholder',
    defaultMessage: 'Please explain...'
  },
  reasonForMoving: {
    id: 'App.OwnOrRentSection.ReasonForMoving',
    defaultMessage: 'Reason for Moving'
  },
});

export default messages;
