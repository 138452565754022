// $FlowFixMe
import { useEffect, useMemo, useState } from 'react';
import DocumentTypeService from '../../services/documentTypeService';
import AffordableService from '../../services/affordableService';
import PropertyService from '../../services/propertyService';
import { NOT_STARTED_COMPLIANCE_APPROVAL } from '../../utils/affordable';

import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

export const useFetchComplianceDocumentGroups = ({
  organizationId,
  propertyId,
}: {
  organizationId: string,
  propertyId: string,
}) => {
  const [complianceDocumentFilterGroups, setComplianceDocumentFilterGroups] =
    useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    let subscribed = true;

    const fetchCertifications = async () => {
      const documentTypeService = new DocumentTypeService();
      const options = { signal: abortController.signal };
      const results =
        await documentTypeService.getComplianceDocumentFilterGroups(
          organizationId,
          propertyId,
          options,
        );
      setComplianceDocumentFilterGroups(results);
    };

    if (subscribed) {
      fetchCertifications();
    }

    return () => {
      subscribed = false;
    };
  }, [organizationId, propertyId]);

  return complianceDocumentFilterGroups;
};

export const useFetchComplianceApprovalStatuses = ({
  flags,
  intl,
  toasterFn,
}: {
  flags: Object,
  intl: { formatMessage: Function },
  toasterFn: Function,
}) => {
  const [complianceApprovalStatuses, setComplianceApprovalStatuses] = useState(
    [],
  );
  useEffect(() => {
    const abortController = new AbortController();
    let subscribed = true;

    const manageCertificationsMovein =
      flags?.manageCertificationsMovein ?? false;

    const fetchComplianceApprovalStatuses = async () => {
      const affordableService = new AffordableService();
      const options = { signal: abortController.signal };
      try {
        const results = (
          await affordableService.getAllComplianceStatuses(options)
        ).concat(NOT_STARTED_COMPLIANCE_APPROVAL);
        const complianceApprovalStatuses = !manageCertificationsMovein
          ? results
          : results.sort((a, b) => a.name.localeCompare(b.name));
        setComplianceApprovalStatuses(complianceApprovalStatuses);
      } catch (e) {
        toasterFn({
          type: 'error',
          message: intl.formatMessage(
            messages.loadComplianceApprovalStatusesError,
          ),
          title: intl.formatMessage(messages.error),
        });
      }
    };

    if (subscribed) {
      fetchComplianceApprovalStatuses();
    }

    return () => {
      subscribed = false;
    };
  }, [intl, toasterFn, flags]);
  return complianceApprovalStatuses;
};

export const useFetchPropertyAffordablePrograms = ({
  organizationId,
  propertyId,
  intl,
  toasterFn,
}: {
  organizationId: string,
  propertyId: string,
  intl: Object,
  toasterFn: Function,
}) => {
  const [propertyAffordablePrograms, setPropertyAffordablePrograms] = useState(
    [],
  );
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController();
    let subscribed = true;

    const fetchPropertyAffordablePrograms = async () => {
      const propertyService = new PropertyService();
      const options = { signal: abortController.signal };

      try {
        const results =
          await propertyService.getPropertyMasterAffordablePrograms(
            organizationId,
            propertyId,
            options,
          );

        setPropertyAffordablePrograms(results);
      } catch (e) {
        toasterFn({
          type: 'error',
          message: intl.formatMessage(messages.loadPropertyProgramsError),
          title: intl.formatMessage(messages.error),
        });
      }
    };

    if (subscribed) {
      fetchPropertyAffordablePrograms();
    }

    return () => {
      subscribed = false;
    };
  }, [organizationId, propertyId, intl, toasterFn]);
  return propertyAffordablePrograms;
};

export const useHasFilters = (filter: string) => {
  return useMemo(() => {
    const filters = JSON.parse(filter);
    return Object.values(filters).some((filter) =>
      Object.values(filter).some((fil) => !!fil),
    );
  }, [filter]);
};
