import { put, takeLatest, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { push } from 'react-router-redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import { GET_MONTH_END_CLOSE, SUBMIT_MONTH_END_CLOSE } from './constants';
import messages from './messages';
import { getMonthEndCloseSuccess, getMonthEndCloseFailure } from './actions';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId
} from '../App/selectors';
import MonthEndService from '../../services/monthEndService';
import FinancialPeriodsService from '../../services/financialPeriodsService';
import type { Property } from '../App/types';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchMonthEndClose(): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const monthEndService = new MonthEndService();
    const response = yield monthEndService.get(
      organizationId,
      selectedProperty.id
    );
    yield put(getMonthEndCloseSuccess(response));
  } catch (err) {
    yield put(getMonthEndCloseFailure(err));
  }
}

export function* getMonthEndCloseSaga(): Saga<void> {
  yield takeLatest(GET_MONTH_END_CLOSE, fetchMonthEndClose);
}

export function* submitMonthEndClose(): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const financialPeriodsService = new FinancialPeriodsService();
    yield financialPeriodsService.close(organizationId, selectedProperty.id);
    yield put(push('/'));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true
        }
      })
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true
        }
      })
    );
  }
}

export function* watchSubmitMonthEndClose(): Saga<void> {
  yield takeLatest(SUBMIT_MONTH_END_CLOSE, submitMonthEndClose);
}

export default [getMonthEndCloseSaga, watchSubmitMonthEndClose];
