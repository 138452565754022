import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ManageCommunications.Header',
    defaultMessage: 'Create Communication',
  },
  residentName: {
    id: 'App.ManageCommunications.ResidentName',
    defaultMessage: 'Resident Name',
  },
  unitNumber: {
    id: 'App.ManageCommunications.UnitNumber',
    defaultMessage: 'Unit Number',
  },
  buildingNumber: {
    id: 'App.ManageCommunications.BuildingNumber',
    defaultMessage: 'Building Number',
  },
  balance: {
    id: 'App.ManageCommunications.Balance',
    defaultMessage: 'Balance',
  },
  leaseEndDate: {
    id: 'App.ManageCommunications.LeaseEndDate',
    defaultMessage: 'Lease End Date',
  },
  moveInDate: {
    id: 'App.ManageCommunication.MoveInDate',
    defaultMessage: 'Move-In Date',
  },
  phoneNumber: {
    id: 'App.ManageCommunications.phoneNumber',
    defaultMessage: 'Phone Number',
  },
  email: {
    id: 'App.ManageCommunications.Email',
    defaultMessage: 'Email',
  },
  search: {
    id: 'App.ManageCommunications.Search',
    defaultMessage: 'Search',
  },
  createNew: {
    id: 'App.ManageCommunications.createNew',
    defaultMessage: 'Create New',
  },
  viewCommunications: {
    id: 'App.ManageCommunications.viewCommunications',
    defaultMessage: 'View Communications',
  },
  templateBuilder: {
    id: 'App.ManageCommunications.TemplateBuilder',
    defaultMessage: 'Template Builder',
  },
  prospects: {
    id: 'App.ManageCommunications.Prospects',
    defaultMessage: 'Prospects',
  },
  applicant: {
    id: 'App.ManageCommunications.Applicant',
    defaultMessage: 'Applicants',
  },
  resident: {
    id: 'App.ManageCommunications.Resident',
    defaultMessage: 'Residents',
  },
  priorResident: {
    id: 'App.ManageComunications.PriorResident',
    defaultMessage: 'Prior Residents',
  },
  tenants: {
    id: 'App.ManageCommunications.Tenants',
    defaultMessage: 'Tenants',
  },
});

export default messages;
