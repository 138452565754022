import { useFlags } from 'launchdarkly-react-client-sdk';
import useSelectedProperty from './useSelectedProperty';
import { getIsValidProgramAndStateCode } from './utils/useMultiRaceSelect';
import { DO_NOT_WISH_PROVIDE_OPTION } from '../components/LeaseAffordableApplicantFormCommonSections/configuration';

const useMultiRaceSelect = () => {
  const { raceMultiSelect } = useFlags();
  const { state, pap = [] } = useSelectedProperty();
  const isValidProgramAndStateCode = getIsValidProgramAndStateCode(
    pap,
    state.code,
  );

  const handleItem = (item, onChange) => {
    const firstAdded = item[0];
    const lastAdded = item[item.length - 1];

    if (firstAdded === DO_NOT_WISH_PROVIDE_OPTION.value && item.length > 1) {
      const removeNotWish = item.filter(
        (value) => value !== DO_NOT_WISH_PROVIDE_OPTION.value,
      );
      onChange(removeNotWish);
    } else if (lastAdded === DO_NOT_WISH_PROVIDE_OPTION.value) {
      onChange([DO_NOT_WISH_PROVIDE_OPTION.value]);
    } else {
      onChange(item);
    }
  };

  return {
    showMultiSelect: isValidProgramAndStateCode && raceMultiSelect,
    stateCode: state.code,
    handleItem,
  };
};

export default useMultiRaceSelect;
