import React, { useState } from 'react';
import { Modal, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import SubmitButton from '../../../components/SubmitButton';

type Props = {
  onHide: Function,
  onSubmit: Function,
  show: boolean,
};

const ConfirmPostDepositModal = (props: Props) => {
  const { onHide, onSubmit, show } = props;
  const [isClicked, setIsClicked] = useState(false);
  return (
    <div className="static-modal">
      <Modal
        bsSize="small"
        dialogClassName="modal-dialog modal-alert"
        show={show}
        onHide={onHide}
        autoFocus={true}
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <h2 className="text-center">
                <FormattedMessage {...messages.confirmDialogMessageTitle} />
              </h2>
              <p>
                <FormattedMessage {...messages.note1} />
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button type="button" bsStyle="default" onClick={onHide}>
              <FormattedMessage {...messages.no} />
            </Button>
            <SubmitButton
              type="submit"
              bsStyle="primary"
              classes="pull-left"
              clickHandler={() => {
                setIsClicked(true);
                onSubmit();
              }}
              isSubmitting={isClicked}
            >
              <FormattedMessage {...messages.yes} />
            </SubmitButton>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmPostDepositModal;
