import React from 'react';

import { formatPeriod } from '../../../utils/redux-form-helper.js';
import messages from './messages';
import type { Period } from '../types.js';
import ElementWithPermissions from '../../../components/ElementWithPermissions';

type Props = {
  formatMessage: Function,
  periods: Array<Period>,
  onPeriodChange: Function,
  createMiscTransactionClick: Function
};

export const periodDropDowns = (periods: Array<Period>): Array<any> =>
  periods.map((period) => (
    <option
      key={period.period}
      value={period.id}
      data-test="misc-transactions-period"
    >
      {formatPeriod(period.period)}
    </option>
  ));

const MiscTransactionsHeader = ({
  formatMessage,
  periods,
  onPeriodChange,
  createMiscTransactionClick
}: Props) => {
  return (
    <div
      className="container-fluid header-with-filters"
      data-test="misc-transactions-header"
    >
      <div className="row">
        <div className="col-xs-12 col-md-6 col-lg-8">
          <h1>{formatMessage(messages.title)}</h1>
        </div>
        <div className="col-xs-12 col-md-6 col-lg-4">
          <div className="row">
            <div className="col-xs-6">
              <label>{formatMessage(messages.filterByPeriod)}</label>
              <select
                className="form-control input-lg"
                onChange={onPeriodChange}
              >
                {periods ? periodDropDowns(periods) : ''}
              </select>
            </div>
            <div className="col-xs-6">
              <ElementWithPermissions scope={['misc-income-create']}>
                <button
                  className="btn btn-shout pull-right"
                  href="#"
                  onClick={createMiscTransactionClick}
                >
                  {formatMessage(messages.createNewPayment)}
                </button>
              </ElementWithPermissions>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiscTransactionsHeader;
