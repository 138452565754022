import type { Action } from '../App/types';
import * as ActionTypes from './constants';

export function logError(
  stack: string,
  message: string,
  userId: string,
  action: string
): Action<any> {
  return {
    type: ActionTypes.LOG_ERROR_MESSAGE,
    payload: { stack, message, userId, action }
  };
}

export function logErrorSuccess(): Action<any> {
  return {
    type: ActionTypes.LOG_ERROR_MESSAGE_SUCCESS,
    payload: undefined
  };
}
