import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';

import { RowDetailsGridForm, SaveCancelDetailsRowGrid } from './styled';
import type { SetAsideProgramTableRowDetailsProps } from './types';
import {
  renderDateField,
  renderCurrencyField,
} from '../../../../utils/redux-form-helper';
import { PPL } from './constants';
import validate from './validate';
import { getPplFieldName } from './utils';

const SetAsideProgramsTableRowDetails = ({
  intl,
  valid,
  store,
  data,
  handleSubmit,
  isNewEntry,
  onCancel = () => {},
  onStartEditing = (id: any) => {},
  onStopEditing = (id: any) => {},
  reset,
  userPermissions,
  isEditMode,
  index,
  showEditButton,
}: SetAsideProgramTableRowDetailsProps): any => {
  const save = () => {
    handleSubmit();
    onStopEditing(index);
  };

  const cancel = () => {
    onCancel();
    reset();
    onStopEditing(index);
  };

  const startEditing = () => {
    onStartEditing(index);
  };

  const hasEditPermission = userPermissions.some(
    (p) => p.scope === 'compliance-tab-edit-set-aside-program',
  );
  const disableFields = !(isNewEntry || isEditMode);
  const showLineEdit =
    hasEditPermission && !isNewEntry && !isEditMode && showEditButton;
  const showSaveCancelLinks = isNewEntry || isEditMode;

  return (
    <Fragment>
      <RowDetailsGridForm>
        <Field
          store={store}
          name="effectiveDate"
          component={renderDateField}
          classPicker="modal-fixed-position"
          bsSize="lg"
          disabled={!isNewEntry}
        />
        {PPL.map((number) => (
          <div key={`rowDetailLabel-${number}ppl-${data.id}`}>
            <Field
              name={getPplFieldName(number)}
              component={renderCurrencyField}
              bsSize="lg"
              min="0"
              max="1000000"
              disabled={disableFields}
            />
          </div>
        ))}
        <div>
          {showLineEdit && (
            <a onClick={startEditing}>
              <i className="icon et-pencil" />
            </a>
          )}
        </div>
      </RowDetailsGridForm>

      {showSaveCancelLinks && (
        <SaveCancelDetailsRowGrid>
          <div>
            <a onClick={save} disabled={!valid} className="text-success">
              Save <i className="et-isolated-check" />
            </a>
          </div>
          <div>
            <a onClick={cancel} className="text-danger">
              Cancel X
            </a>
          </div>
        </SaveCancelDetailsRowGrid>
      )}
    </Fragment>
  );
};

export default reduxForm({
  validate,
})(SetAsideProgramsTableRowDetails);
