import { getComputedElementStyle } from '../../utils/element-helpers';
import { APP_BAR_HEIGHT, PADDING } from './constants';
const getComputedMargin = (element) => {
  return (
    getComputedElementStyle(element, 'margin-top', true) +
    getComputedElementStyle(element, 'margin-bottom', true)
  );
};

export const calculatePageHeight = ({
  homeTabContentRef,
  homeTabRef,
  previousParentHeight,
  body,
}) => {
  previousParentHeight.current = body.clientHeight;
  let pageElementsHeight = 0;

  const pageElements = [homeTabRef.current];

  pageElements.forEach((element) => {
    if (element) {
      pageElementsHeight += element.clientHeight;
      pageElementsHeight += getComputedMargin(element);
    }
  });

  // add padding from home tab content
  if (homeTabContentRef.current) {
    pageElementsHeight += getComputedMargin(homeTabContentRef.current);
  }

  return window.innerHeight - (APP_BAR_HEIGHT + pageElementsHeight);
};

export const calculateChildrenHeight = ({
  children,
  containerHeight,
  name,
}) => {
  let childrenElementsHeight = 0;

  // loop through children and set height
  Array.from(children).forEach((child) => {
    if (!child) return;
    if (!child.id?.startsWith('table-wrapper_')) {
      childrenElementsHeight += child.clientHeight + getComputedMargin(child);
    }
  });

  return containerHeight - (PADDING + childrenElementsHeight);
};
