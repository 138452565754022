import React, { useEffect } from 'react';
import {
  Field,
  FormSection,
  formValueSelector,
  getFormSyncWarnings,
  reduxForm,
  SubmissionError,
} from 'redux-form';
import { connect } from 'react-redux';
import { Prompt } from 'react-router';
import {
  all,
  find,
  isEmpty,
  isNil,
  keys,
  omit,
  partial,
  pathOr,
  pipe,
  prop,
  propEq,
} from 'ramda';
import { Col, Row } from 'react-bootstrap';
import { NoPrint } from 'react-easy-print';
import classNames from 'classnames';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import validateProgress from './validateProgress';
import validateComplete from './validateComplete';
import messages from './messages';
import confirm from '../../../components/ConfirmDialogModal';
/* eslint-disable max-len */
import BasicInformationSection from '../../../components/LeaseAffordableApplicantFormCommonSections/BasicInformation';
import ContactInformationSection from '../../../components/LeaseApplicantFormCommonSections/ContactInformationSection';
import MaritalStatusSection from '../../../components/LeaseApplicantFormCommonSections/MaritalStatusSection';
import AdditionalOccupantSection from '../AdditionalOcupantSection';
import ResidenceInformationSection from '../../../components/LeaseApplicantFormCommonSections/ResidenceInformationSection';
import EmploymentInformationSection from '../../../components/LeaseAffordableApplicantFormCommonSections/EmploymentInformationSection';
import StudentInformationSection from '../../../components/LeaseAffordableApplicantFormCommonSections/StudentInformationSection';
import ApplicantsEmployerSection from '../../../components/LeaseApplicantFormCommonSections/ApplicantsEmployerSection';
import OtherInformationSection from '../OtherInformationSection';
import SignSection from '../../../components/LeaseApplicantFormCommonSections/SignatureSection';
import LoadingSpinner from '../../../components/Spinner';

/* eslint-enable max-len */
import { renderRadioGroupField } from '../../../utils/redux-form-helper';
import {
  maritalStatuses,
  studentsTypes,
} from '../../../components/LeaseAffordableApplicantFormCommonSections/configuration';
import LeaseApplicationGuidelines from '../../../components/LeaseApplicationGuidelines';
import LeaseFormSignatureAndRepresentations from '../../../components/LeaseFormSignatureAndRepresentations';
import type { GlobalState, SelectOption } from '../../App/types';
import { resetField } from '../../PrimaryLeaseApplication/utilityFunctions';
import { DEFAULT_EMPTY_EMPLOYER_INFO, FORM_NAME } from '../constants';
import { getDisabilityValues } from '../../../components/LeaseAffordableApplicantFormCommonSections/utils';

import type { Disability } from '../../../components/LeaseAffordableApplicantFormCommonSections/types';
import useMultiRaceSelect from '../../../hooks/useMultiRaceSelect';

type StateProps = {
  formSyncWarnings: Object,
};

type Props = {
  applicantId: string,
  applicationId: string,
  locale: string,
  intl: Object,
  countries: Array<SelectOption>,
  suffixes: Array<SelectOption>,
  states: Array<SelectOption>,
  maritalStatuses: Array<SelectOption>,
  employmentStatuses: Array<SelectOption>,
  propertyName: string,
  applicationIncomeMultiplier: string,
  handleSubmit: Function,
  handleCancel: Function,
  handlePrint: Function,
  dirty: boolean,
  valid: boolean,
  submitSucceeded: boolean,
  warning: string,
  onSubmit: Function,
  initialValues: Object,
  change: Function,
  bankruptcy: string,
  resetSection: Function,
  employmentInformation: Object,
  isSubmitting: boolean,
  isComplete: boolean,
  isPrinting: boolean,
  propertyState: string,
  propertyCity: string,
  flags: Object,
  hasAnyDisability: string | null,
  disability: Disability,
  basicInformationMultiRace: string[],
};

const saveProgress = (complete: boolean, props: Props, values: any) => {
  const progressErrors = validateProgress(values, props);

  if (keys(progressErrors).length > 0) {
    return new Promise(() => {
      throw new SubmissionError(progressErrors);
    });
  }
  props.onSubmit(false, complete, values);
};

function confirmComplete(props: Props, values: any) {
  const completeErrors = validateComplete(values, props);
  if (keys(completeErrors).length > 0) {
    return new Promise(() => {
      throw new SubmissionError(completeErrors);
    });
  }
  confirm(props.intl.formatMessage(messages.completeConfirmation), {
    intl: props.intl,
  })
    .then(() => props.onSubmit(true, false, values))
    .catch(() => {});
}

const completeApplicationInformation = (formSyncWarnings: any) => {
  const birthday = pathOr(
    undefined,
    ['basicInformation', 'birthday'],
    formSyncWarnings,
  );
  const firstName = pathOr(
    undefined,
    ['basicInformation', 'firstName'],
    formSyncWarnings,
  );
  const phoneNumber = pathOr(
    undefined,
    ['contactInformation', 'phoneNumber'],
    formSyncWarnings,
  );
  const maritalStatus = pathOr(
    undefined,
    ['maritalInformation', 'emailAddress'],
    formSyncWarnings,
  );

  return all(isNil)([firstName, birthday, phoneNumber, maritalStatus])
    ? null
    : true;
};

const completeAdditionalOccupants = (formSyncWarnings: any) => {
  const anyAdult = pathOr(
    undefined,
    ['additionalOccupants', 'anyAdult'],
    formSyncWarnings,
  );
  const anyMinor = pathOr(
    undefined,
    ['additionalOccupants', 'anyMinor'],
    formSyncWarnings,
  );

  return all(isNil)([anyAdult, anyMinor]) ? null : true;
};

const completeResidentialInformation = (formSyncWarnings: any) => {
  const street = pathOr(
    undefined,
    ['currentResidenceInformation', 'address', 'street'],
    formSyncWarnings,
  );
  const city = pathOr(
    undefined,
    ['currentResidenceInformation', 'address', 'city'],
    formSyncWarnings,
  );
  const state = pathOr(
    undefined,
    ['currentResidenceInformation', 'address', 'state'],
    formSyncWarnings,
  );
  const zipCode = pathOr(
    undefined,
    ['currentResidenceInformation', 'address', 'zipCode'],
    formSyncWarnings,
  );
  const dateMovedIn = pathOr(
    undefined,
    ['currentResidenceInformation', 'dateMovedIn'],
    formSyncWarnings,
  );
  const monthlyPayment = pathOr(
    undefined,
    ['currentResidenceInformation', 'monthlyPayment'],
    formSyncWarnings,
  );
  const ownOrRentOrNotCurrently = pathOr(
    undefined,
    ['currentResidenceInformation', 'ownOrRent', 'ownOrRentOrNotCurrently'],
    formSyncWarnings,
  );
  const lenderName = pathOr(
    undefined,
    ['currentResidenceInformation', 'ownOrRent', 'lenderName'],
    formSyncWarnings,
  );
  const lenderPhoneNumber = pathOr(
    undefined,
    ['currentResidenceInformation', 'ownOrRent', 'lenderPhoneNumber'],
    formSyncWarnings,
  );
  const landlordName = pathOr(
    undefined,
    ['currentResidenceInformation', 'ownOrRent', 'landlordName'],
    formSyncWarnings,
  );
  const landlordPhoneNumber = pathOr(
    undefined,
    ['currentResidenceInformation', 'ownOrRent', 'landlordPhoneNumber'],
    formSyncWarnings,
  );
  const doNotCurrentlyOwnRentExplanation = pathOr(
    undefined,
    [
      'currentResidenceInformation',
      'ownOrRent',
      'doNotCurrentlyOwnRentExplanation',
    ],
    formSyncWarnings,
  );

  return all(isNil)([
    street,
    city,
    state,
    zipCode,
    dateMovedIn,
    monthlyPayment,
    ownOrRentOrNotCurrently,
    lenderName,
    lenderPhoneNumber,
    landlordName,
    landlordPhoneNumber,
    doNotCurrentlyOwnRentExplanation,
  ])
    ? null
    : true;
};

const completeEmploymentInformation = (formSyncWarnings: any) => {
  const status = pathOr(
    undefined,
    ['employmentInformation', 'status'],
    formSyncWarnings,
  );
  const position = pathOr(
    undefined,
    ['applicantsCurrentEmployer', 'position'],
    formSyncWarnings,
  );
  const annualSalary = pathOr(
    undefined,
    ['applicantsCurrentEmployer', 'annualSalary'],
    formSyncWarnings,
  );
  const from = pathOr(
    undefined,
    ['applicantsCurrentEmployer', 'from'],
    formSyncWarnings,
  );
  const to = pathOr(
    undefined,
    ['applicantsCurrentEmployer', 'to'],
    formSyncWarnings,
  );
  const employerName = pathOr(
    undefined,
    ['applicantsCurrentEmployer', 'employerName'],
    formSyncWarnings,
  );
  const applicantPhoneNumber = pathOr(
    undefined,
    ['applicantsCurrentEmployer', 'phoneNumber'],
    formSyncWarnings,
  );
  const supervisorName = pathOr(
    undefined,
    ['applicantsCurrentEmployer', 'supervisorName'],
    formSyncWarnings,
  );
  const applicantStreet = pathOr(
    undefined,
    ['applicantsCurrentEmployer', 'address', 'street'],
    formSyncWarnings,
  );
  const applicantCity = pathOr(
    undefined,
    ['applicantsCurrentEmployer', 'address', 'city'],
    formSyncWarnings,
  );
  const applicantState = pathOr(
    undefined,
    ['applicantsCurrentEmployer', 'address', 'state'],
    formSyncWarnings,
  );
  const applicantZipCode = pathOr(
    undefined,
    ['applicantsCurrentEmployer', 'address', 'zipCode'],
    formSyncWarnings,
  );

  return all(isNil)([
    status,
    position,
    annualSalary,
    from,
    to,
    employerName,
    applicantPhoneNumber,
    supervisorName,
    applicantStreet,
    applicantCity,
    applicantState,
    applicantZipCode,
  ])
    ? null
    : true;
};

const completeOtherInformation = (formSyncWarnings: any) => {
  const pets = pathOr(
    undefined,
    ['otherInformation', 'pets'],
    formSyncWarnings,
  );

  const convicted = pathOr(
    undefined,
    ['otherInformation', 'convicted'],
    formSyncWarnings,
  );
  const convictedDescription = pathOr(
    undefined,
    ['otherInformation', 'convictedDescription'],
    formSyncWarnings,
  );

  const bankruptcy = pathOr(
    undefined,
    ['otherInformation', 'bankruptcy'],
    formSyncWarnings,
  );

  const bankruptcyDescription = pathOr(
    undefined,
    ['otherInformation', 'bankruptcyDescription'],
    formSyncWarnings,
  );
  const bankruptcyCaseFiled = pathOr(
    undefined,
    ['otherInformation', 'bankruptcyCaseFiled'],
    formSyncWarnings,
  );
  const bankruptcyDateFiled = pathOr(
    undefined,
    ['otherInformation', 'bankruptcyDateFiled'],
    formSyncWarnings,
  );
  const bankruptcyDischarged = pathOr(
    undefined,
    ['otherInformation', 'bankruptcyDischarged'],
    formSyncWarnings,
  );

  return all(isNil)([
    pets,
    convicted,
    convictedDescription,
    bankruptcy,
    bankruptcyDescription,
    bankruptcyCaseFiled,
    bankruptcyDateFiled,
    bankruptcyDischarged,
  ])
    ? null
    : true;
};

const completeSignature = (formSyncWarnings: any) => {
  const hasSignedCopy = pathOr(
    undefined,
    ['signature', 'hasSignedCopy'],
    formSyncWarnings,
  );

  return isNil(hasSignedCopy) ? null : true;
};

function canComplete(formSyncWarnings: any, warning: string, valid: boolean) {
  const applicationInformation =
    completeApplicationInformation(formSyncWarnings);
  const additionalOccupants = completeAdditionalOccupants(formSyncWarnings);
  const residentialInformation =
    completeResidentialInformation(formSyncWarnings);
  const employmentInformation = completeEmploymentInformation(formSyncWarnings);
  const otherInformation = completeOtherInformation(formSyncWarnings);
  const signature = completeSignature(formSyncWarnings);

  return (
    all(isNil)([
      applicationInformation,
      additionalOccupants,
      residentialInformation,
      employmentInformation,
      otherInformation,
      signature,
      warning,
    ]) && valid
  );
}

const setUnemployed = (change: Function) => () => {
  change('applicantsCurrentEmployer', DEFAULT_EMPTY_EMPLOYER_INFO);
  change('applicantsSecondCurrentEmployer', DEFAULT_EMPTY_EMPLOYER_INFO);
};
const resetEmploymentInfo = (resetSection: Function) => () => {
  resetSection('applicantsCurrentEmployer');
  resetSection('applicantsSecondCurrentEmployer');
};

const NonPrimaryForm = (props: Props & StateProps) => {
  const {
    locale,
    intl,
    countries,
    suffixes,
    states,
    employmentStatuses,
    propertyName,
    handleSubmit,
    handleCancel,
    handlePrint,
    dirty,
    valid,
    submitSucceeded,
    formSyncWarnings,
    warning,
    initialValues,
    change,
    bankruptcy,
    resetSection,
    employmentInformation,
    isSubmitting,
    isComplete,
    applicationIncomeMultiplier,
    isPrinting,
    propertyState,
    propertyCity,
    flags,
    hasAnyDisability,
    disability,
    basicInformationMultiRace,
  } = props;
  const applicationsPrintIconRemoved =
    flags?.applicationsPrintIconRemoved ?? false;
  const doNotWishToProvideDisability =
    flags?.doNotWishToProvideDisability ?? false;
  const bankruptcyDischargedDisabled = !(bankruptcy === 'yes');
  const employmentStatusId = pathOr(null, ['status'], employmentInformation);
  const notEmployedId = pipe(
    find(propEq('text', 'Not Employed')),
    // $FlowFixMe
    prop('value'),
  )(employmentStatuses);
  const unemployed = notEmployedId === employmentStatusId;

  // Hooks
  const { showMultiSelect } = useMultiRaceSelect();

  useEffect(() => {
    if (doNotWishToProvideDisability && !isNil(hasAnyDisability)) {
      const disabilityValues: Disability = getDisabilityValues(
        initialValues?.basicInformation?.disability,
        disability,
        hasAnyDisability,
      );
      change(
        'basicInformation.disability.disabilityMobile',
        disabilityValues?.disabilityMobile,
      );
      change(
        'basicInformation.disability.disabilityHearing',
        disabilityValues?.disabilityHearing,
      );
      change(
        'basicInformation.disability.disabilityVisual',
        disabilityValues?.disabilityVisual,
      );
      change(
        'basicInformation.disability.disabilityOther',
        disabilityValues?.disabilityOther,
      );
    }

    // For previously filled forms before this change, if all no, then mark the general
    // setting "No" option.
    if (
      doNotWishToProvideDisability &&
      isNil(initialValues?.basicInformation?.disability)
    ) {
      const areAllNo = Object.values(
        omit(
          ['hasDisability'],
          initialValues?.basicInformation?.disability ?? {},
        ),
      ).every((val) => val === 'no');
      if (areAllNo) {
        change('basicInformation.disability.hasDisability', 'no');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doNotWishToProvideDisability, hasAnyDisability]);

  useEffect(() => {
    // For previously filled forms before this change, if all no, then mark the general
    // setting "No" option.
    if (
      doNotWishToProvideDisability &&
      !isNil(initialValues?.basicInformation?.disability)
    ) {
      const disabilities = omit(
        ['hasDisability'],
        initialValues?.basicInformation?.disability ?? {},
      );
      const areAllNo =
        !isEmpty(Object.keys(disabilities)) &&
        Object.values(disabilities).every((val) => val === 'no');
      if (areAllNo) {
        change('basicInformation.disability.hasDisability', 'no');
      }
    }
    // intentional so it runs on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabilitiesDisabled = doNotWishToProvideDisability
    ? hasAnyDisability !== 'yes'
    : false;
  const disabilitiesRequired = doNotWishToProvideDisability
    ? hasAnyDisability === 'yes'
    : true;

  return (
    <React.Fragment>
      <form>
        <Prompt
          when={dirty && !submitSucceeded}
          message={intl.formatMessage(messages.cancelConfirmation)}
        />
        <div className="section-leaseappform form-wrapper">
          <Row>
            <Col xs={10} md={8} className="mastertitle">
              <div className="leaseform-header">
                <h1>{intl.formatMessage(messages.title, { propertyName })}</h1>
                <div className="divider" />
                <span>*Required Fields</span>
              </div>
            </Col>
            {!applicationsPrintIconRemoved &&
              (isPrinting ? (
                <Col xs={2} md={4} mdPush={1}>
                  <LoadingSpinner />
                </Col>
              ) : (
                <Col xs={2} md={4} mdPush={3}>
                  <a
                    className="btn btn-tertiary btn-print no-print"
                    title="Print this form"
                    onClick={handlePrint}
                  >
                    <i className="icon et-print" />
                  </a>
                </Col>
              ))}
            <LeaseApplicationGuidelines
              locale={locale}
              isAffordable={true}
              applicationIncomeMultiplier={applicationIncomeMultiplier}
            />
            <Row>
              <Col xs={12} md={12}>
                <div className="block-heading">
                  <h2>Application Information</h2>
                </div>
                <FormSection name="basicInformation">
                  <BasicInformationSection
                    intl={intl}
                    countries={countries}
                    suffixes={suffixes}
                    states={states}
                    isAffordable={true}
                    disabilitiesDisabled={disabilitiesDisabled}
                    disabilitiesRequired={disabilitiesRequired}
                    reduxFormOnChange={change}
                    basicInformationMultiRace={basicInformationMultiRace}
                    required
                  />
                </FormSection>
                <FormSection name="contactInformation">
                  <ContactInformationSection intl={intl} required />
                </FormSection>
                <FormSection name="maritalInformation">
                  <MaritalStatusSection
                    intl={intl}
                    maritalStatuses={maritalStatuses}
                  />
                </FormSection>
                <div className="block-heading">
                  <h2>Additional Ocuppants</h2>
                </div>
                <FormSection name="additionalOccupants">
                  <AdditionalOccupantSection intl={intl} required />
                </FormSection>
                <div className="block-heading">
                  <h2>Residential Information</h2>
                </div>
                <FormSection name="currentResidenceInformation">
                  <ResidenceInformationSection
                    title={messages.currentResidenceInformationTitle}
                    intl={intl}
                    states={states}
                    required
                  />
                </FormSection>
                <FormSection name="previousResidenceInformation">
                  <ResidenceInformationSection
                    title={messages.previousResidenceInformationTitle}
                    intl={intl}
                    states={states}
                  />
                </FormSection>
                <FormSection name="secondPreviousResidenceInformation">
                  <ResidenceInformationSection
                    title={messages.secondPreviousResidenceInformationTitle}
                    intl={intl}
                    states={states}
                  />
                </FormSection>
                <div className="block-heading">
                  <h2>Student Information</h2>
                </div>
                <FormSection name="studentInformation">
                  <StudentInformationSection
                    intl={intl}
                    studentsTypes={studentsTypes}
                    initialValues={initialValues?.studentInformation ?? {}}
                    changeCallback={change}
                  />
                </FormSection>
                <div className="block-heading">
                  <h2>Employment Information</h2>
                </div>
                <FormSection name="employmentInformation">
                  <EmploymentInformationSection
                    intl={intl}
                    employmentStatuses={employmentStatuses}
                    studentsTypes={studentsTypes}
                    setUnemployed={setUnemployed(change)}
                    resetEmploymentInfo={resetEmploymentInfo(resetSection)}
                  />
                </FormSection>
                <FormSection name="applicantsCurrentEmployer">
                  <ApplicantsEmployerSection
                    title={messages.applicantsCurrentEmployerTitle}
                    intl={intl}
                    states={states}
                    streetLabel={messages.streetAddressLabel}
                    isCurrentEmployer={true}
                    fromLabel={messages.startDate}
                    disabled={unemployed}
                    required
                  />
                </FormSection>
                <FormSection name="applicantsSecondCurrentEmployer">
                  <ApplicantsEmployerSection
                    title={messages.applicantsSecondCurrentEmployerTitle}
                    intl={intl}
                    streetLabel={messages.streetAddressLabel}
                    states={states}
                    isCurrentEmployer={true}
                    fromLabel={messages.startDate}
                    disabled={unemployed}
                  />
                </FormSection>
                <FormSection name="employmentInformation">
                  <div className="form-content">
                    <div className="form-block">
                      <Row>
                        <Col xs={12} md={8}>
                          <h3>
                            Do you anticipate a change with your current
                            employer situation?
                          </h3>
                          <p>
                            {' '}
                            <strong> Note: </strong>{' '}
                            {intl.formatMessage(messages.employerSituationNote)}
                          </p>
                        </Col>
                        <Col xs={12} md={4}>
                          <Field
                            name="anticipateChangeEmployer"
                            fieldName="anticipateChangeEmployer"
                            component={renderRadioGroupField}
                            options={[
                              { text: 'No', value: 'no', disabled: false },
                              { text: 'Yes', value: 'yes', disabled: false },
                            ]}
                            classes={classNames({ required: true })}
                            inline
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </FormSection>
                <FormSection name="applicantsPreviousEmployer">
                  <ApplicantsEmployerSection
                    title={messages.applicantsPreviousEmployerTitle}
                    intl={intl}
                    streetLabel={messages.streetAddressLabel}
                    states={states}
                    isCurrentEmployer={false}
                    fromLabel={messages.from}
                  />
                </FormSection>
                <div className="block-heading">
                  <h2>Other Information</h2>
                </div>
                <FormSection name="otherInformation">
                  <OtherInformationSection
                    intl={intl}
                    bankruptcyDischargedDisabled={bankruptcyDischargedDisabled}
                    resetBankruptcyDischarged={resetField(
                      change,
                      'otherInformation.bankruptcyDischarged',
                    )}
                    propertyState={propertyState}
                    propertyCity={propertyCity}
                    flags={flags}
                  />
                </FormSection>
                <LeaseFormSignatureAndRepresentations />
                <NoPrint>
                  <div className="block-heading">
                    <h2>Signature</h2>
                  </div>
                  <FormSection name="signature">
                    <SignSection
                      intl={intl}
                      handleCancel={handleCancel}
                      handleSubmit={handleSubmit(
                        partial(saveProgress, [
                          !canComplete(formSyncWarnings, warning, valid),
                          props,
                        ]),
                      )}
                      confirmSubmit={handleSubmit(
                        partial(confirmComplete, [
                          { ...props, showMultiSelect },
                        ]),
                      )}
                      isSubmitting={isSubmitting}
                      isComplete={isComplete}
                    />
                  </FormSection>
                </NoPrint>
              </Col>
            </Row>
          </Row>
        </div>
      </form>
    </React.Fragment>
  );
};

export const mapStateToProps = (state: GlobalState): StateProps => {
  const { form } = state;
  const selector = formValueSelector(FORM_NAME);
  return {
    formSyncWarnings: getFormSyncWarnings(FORM_NAME)(state),
    basicInformationMultiRace: selector({ form }, 'basicInformation.multiRace'),
    bankruptcy: selector({ form }, 'otherInformation.bankruptcy'),
    employmentInformation: selector({ form }, 'employmentInformation'),
    isPrinting: pathOr(false, ['primaryLeaseApplication', 'isPrinting'], state),
    disability: selector({ form }, 'basicInformation.disability'),
    hasAnyDisability: selector(
      { form },
      'basicInformation.disability.hasDisability',
    ),
  };
};

const connnected = connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  })(NonPrimaryForm),
);

export default withLDConsumer()(connnected);
