import * as ActionTypes from './constants';
import type { Application } from '../CreateApplication/types';
import type { Action, PaginationMeta, OrderValue } from '../App/types';

export function getAllApplications(
  pageNumber: number,
  limit: number,
  sorting: OrderValue,
  filters: Object,
  searchText: string,
): * {
  return {
    type: ActionTypes.GET_ALL_APPLICATIONS,
    payload: {
      paginationInfo: {
        pageNumber,
        limit,
      },
      filters,
      sorting,
      searchText,
    },
  };
}

export function getAllApplicationsSuccess(
  applications: Array<Application>,
  meta: PaginationMeta,
) {
  return {
    type: ActionTypes.GET_ALL_APPLICATIONS_SUCCESS,
    payload: applications,
    meta,
  };
}

export function getAllApplicationStatuses(payload: Object): Action<any> {
  return {
    type: ActionTypes.GET_ALL_APPLICATION_STATUSES,
    payload,
  };
}

export function getAllApplicationStatusesSuccess(
  statuses: Array<Object>,
): Action<any> {
  return {
    type: ActionTypes.GET_ALL_APPLICATION_STATUSES_SUCCESS,
    payload: statuses,
  };
}

export function getAllApplicationStatusesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_APPLICATION_STATUSES_ERROR,
    payload: error,
  };
}

export function createApplicationProspectActivityError(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.CREATE_APPLICATION_PROSPECT_ACTIVITY_ERROR,
    payload: error,
  };
}

export function createApplicationProspectActivitySuccess(): Action<any> {
  return {
    type: ActionTypes.CREATE_APPLICATION_PROSPECT_ACTIVITY_SUCCESS,
    payload: undefined,
  };
}
export function applicationUpdateProspectActivity(
  activity: any,
  pageNumber: number,
  limit: number,
  currentSorting: Object,
): Action<any> {
  return {
    type: ActionTypes.APPLICATION_UPDATE_PROSPECT_ACTIVITY,
    payload: {
      activity,
      paginationInfo: {
        pageNumber,
        limit,
      },
      sorting: currentSorting,
    },
  };
}

export function createApplicationProspectActivity(
  activity: any,
  pageNumber: number,
  limit: number,
  currentSorting: Object,
): Action<any> {
  return {
    type: ActionTypes.CREATE_APPLICATION_PROSPECT_ACTIVITY,
    payload: {
      activity: Object.assign(activity, {
        customerStatus: ActionTypes.APPLICANT,
      }),
      paginationInfo: {
        pageNumber,
        limit,
      },
      sorting: currentSorting,
    },
  };
}
export function deleteApplicationProspectActivity(
  activity: any,
  pageNumber: number,
  limit: number,
  currentSorting: Object,
): Action<any> {
  return {
    type: ActionTypes.APPLICATION_DELETE_PROSPECT_ACTIVITY,
    payload: {
      activity,
      paginationInfo: {
        pageNumber,
        limit,
      },
      sorting: currentSorting,
    },
  };
}
export function getAllApplicationsFail(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_APPLICATIONS_FAIL,
    payload: error,
  };
}

export function updateColumnsSortValue(field: string, value: string) {
  return {
    type: ActionTypes.UPDATE_COLUMNS_SORT_VALUE,
    payload: { [field]: value },
  };
}

export function cleanLoadedApplications(): Action<any> {
  return {
    type: ActionTypes.CLEAN_LOADED_APPLICATIONS,
    payload: undefined,
  };
}

export function showNoDataToastr(): Action<any> {
  return {
    type: ActionTypes.SHOW_NO_DATA_TOASTR,
    payload: undefined,
  };
}
