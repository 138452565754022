import React, { Fragment } from 'react';
import { CreateWorkOrderHeader } from './createMode';
import { EditWorkOrderHeader } from './editMode';
export const HeaderRow = ({
  isEditMode,
  detailIdReadable,
  createdAt,
  enteredByUser,
  formatMessage,
  lastStatusChangeDate,
  finishedDate,
  status,
  currentUser,
}: Object) => (
  <Fragment>
    {isEditMode ? (
      <EditWorkOrderHeader
        workOrderId={detailIdReadable}
        createdAt={createdAt}
        enteredByUser={enteredByUser}
        formatMessage={formatMessage}
        lastStatusChangeDate={lastStatusChangeDate}
        status={status}
        finishedDate={finishedDate}
      />
    ) : (
      <CreateWorkOrderHeader
        formatMessage={formatMessage}
        currentUser={currentUser}
        workOrderId={detailIdReadable}
        createdAt={createdAt}
      />
    )}
  </Fragment>
);
