// @flow

export const ValidationStates = {
  OK: 200,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_ACCEPTABLE: 406
};

export type ValidationState = 200 | 401 | 403 | 406;
