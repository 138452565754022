import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';

function parseError(error) {
  let returnError = error;
  if (error) {
    if (error.networkError?.message === 'Failed to fetch') {
      returnError = new Error('Unable to reach server. Please try again.');
    }
  }

  return returnError;
}

export function useGqlQuery(query, variables) {
  const { error, data, ...rest } = useQuery(query, {
    variables,
    fetchPolicy: 'network-only',
  });

  // graphql handles multiple queries and the resulting data
  // is named by the query name.
  //
  // This assumes there is only 1 query in the typical format
  // and returns the data for that query
  const selectionNameGuess = _.get(
    query,
    'definitions[0].selectionSet.selections[0].name.value',
  );

  return [
    selectionNameGuess ? data?.[selectionNameGuess] : data,
    {
      ...rest,
      error: parseError(error),
      originalError: error,
    },
  ];
}

export function useGqlMutation(query) {
  const [mutate, { loading }] = useMutation(query, {
    fetchPolicy: 'network-only',
  });

  async function gqlMutate(...args) {
    const result = await mutate(...args).catch((error) => {
      throw parseError(error);
    });
    return result;
  }

  return [gqlMutate, loading];
}
