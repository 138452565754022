import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import type { Image, OnDeleteImageEvent, OnDownloadImageEvent } from './types';

type Props = {
  image: Image,
  onDeleteImage: OnDeleteImageEvent,
  onDownloadImage: OnDownloadImageEvent,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageBox = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f8f8f8;
  padding: 2%;
`;

const ImageMeta = styled.div`
  display: flex;
  flex-direction: column;
  overflow-wrap: anywhere;
`;

const ImageButtons = styled.div`
  display: flex;
`;

const ImageName = styled.a`
  font-size: 14px;
`;

const ImageUploadDate = styled.p`
  font-size: 12px;
  margin: 0;
`;

const ImageButton = styled.button`
  border: none;
  background: none;
  color: #4d73ff;
`;

const MaximumNote = styled.p`
  align-self: center;
`;

function ImageBlock({ image, onDeleteImage, onDownloadImage }: Props) {
  const formattedDate = image.uploadDate
    ? moment(image.uploadDate).format('MMMM D, YYYY')
    : '';
  return (
    <Wrapper>
      <ImageBox>
        <ImageMeta>
          <ImageName onClick={onDownloadImage}>{image.name}</ImageName>
          {image.uploadDate && (
            <ImageUploadDate>
              Added <span>{formattedDate}</span>
            </ImageUploadDate>
          )}
        </ImageMeta>
        <ImageButtons>
          <ImageButton title="Download Image" onClick={onDownloadImage}>
            <i className="et-download" />
          </ImageButton>
          <ImageButton
            title="Delete Image"
            onClick={onDeleteImage}
            style={{
              marginLeft: 10,
            }}
          >
            <i className="et-trash" />
          </ImageButton>
        </ImageButtons>
      </ImageBox>
      <MaximumNote>Maximum of 1 image allowed to be uploaded</MaximumNote>
    </Wrapper>
  );
}

export default ImageBlock;
