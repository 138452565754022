import { handleSocketExpiredSession } from './actions';

/**
 * Consumed by Socket context to register listeners
 */
const logoutExpiredSocket = (dispatch: Function, socket: any) => {
  socket.on('auth.session.exp', (message) => {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV) console.debug(JSON.stringify(message));
    dispatch(handleSocketExpiredSession());
  });
};

export default logoutExpiredSocket;
