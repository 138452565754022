import type { Action, ErrorAction } from '../App/types';
import type { Delinquencies } from './types';
import {
  GET_ALL_DELINQUENCIES,
  GET_ALL_DELINQUENCIES_SUCCESS,
  GET_ALL_DELINQUENCIES_ERROR,
  CLEAN_LOADED_DELINQUENCIES
} from './constants';

export function getAllDelinquencies(): Action<any> {
  return {
    type: GET_ALL_DELINQUENCIES,
    payload: undefined
  };
}

export function getAllDelinquenciesSuccess(
  delinquencies: Array<Delinquencies>
): Action<Array<Delinquencies>> {
  return {
    type: GET_ALL_DELINQUENCIES_SUCCESS,
    payload: delinquencies
  };
}

export function getAllDelinquenciesError(error: Error): ErrorAction {
  return {
    type: GET_ALL_DELINQUENCIES_ERROR,
    error
  };
}

export function cleanLoadedDelinquencies(): Action<any> {
  return {
    type: CLEAN_LOADED_DELINQUENCIES,
    payload: undefined
  };
}
