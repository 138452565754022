import React, { useContext, useMemo } from 'react';
import { Field } from 'redux-form';
import { NoPrint } from 'react-easy-print';

import {
  renderDateField,
  renderSelectField,
  renderTextAreaField,
  extractDateFormat,
} from '../../../../utils/redux-form-helper';
import messages from './messages';
import { isStatusSelected } from '../validate';
import { useFlags } from 'launchdarkly-react-client-sdk';
import MultiPhotoUpload from '../../MultiPhotoUpload';
import { withRouter } from 'react-router';
import { WorkOrderContext } from '../..';
import { useOnHoldReasons } from './hooks';

type Props = {
  intl: any,
  statuses?: Array<Object>,
  invalid: Array<string>,
  selectedStatusId: string,
  onStatusIdChange: Function,
  workOrderId: string,
  selectedPropertyId: string,
  organizationId: string,
  changeCallback: Function,
};

const parseReasonOptions = (options: Array<string>) => {
  const defaultOption = [
    { disabled: true, readableId: 0, text: 'Choose', value: '' },
  ];
  const mappedOptions = options.map((option) => {
    return {
      text: option,
      value: option,
    };
  });

  return [...defaultOption, ...mappedOptions];
};

const AfterService = ({
  intl,
  statuses,
  invalid,
  selectedStatusId,
  onStatusIdChange,
  setIsDirty,
  match,
  selectedPropertyId,
  organizationId,
  changeCallback,
}: Props) => {
  const workOrderId = match.params.workOrderId;
  const { workOrderToEdit } = useContext(WorkOrderContext);
  const { afterServiceImages } = workOrderToEdit;
  const reasonOptions = useOnHoldReasons();

  const dateFormat = extractDateFormat(intl);
  const backgroundColor = { backgroundColor: '#FFF1DB' };
  const { mobileCommunicationsToResidents, fortressWorkOrdersPhotos } =
    useFlags();
  const isStatusCompleted = useMemo(() => {
    const selectedStatus = statuses.find((s) => s.value === selectedStatusId);
    return selectedStatus?.text === 'Completed';
  }, [selectedStatusId, statuses]);

  let showReasonField =
    mobileCommunicationsToResidents &&
    isStatusSelected(statuses, selectedStatusId);
  return (
    <div data-test="edit-wo-after-service">
      <div className="row padtop10">
        <div className="col-xs-12 padtop30">
          <h3>{intl.formatMessage(messages.title)}</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-3 col-sm-2 padtop10">
          <div className="form-group">
            <Field
              selectClassNames="input-lg"
              name="statusId"
              onChange={(e) => {
                onStatusIdChange(e);
                setIsDirty(true);
              }}
              component={renderSelectField}
              label={intl.formatMessage(messages.status)}
              options={statuses}
              style={
                invalid && invalid.includes('statuses') ? backgroundColor : null
              }
            />
          </div>
        </div>
        {showReasonField && (
          <div className="col-xs-12 col-md-3 col-sm-3 padtop10">
            <Field
              selectClassNames="input-lg"
              name="holdReason"
              onChange={() => {
                setIsDirty(true);
              }}
              component={renderSelectField}
              options={parseReasonOptions(reasonOptions)}
              label={intl.formatMessage(messages.reason)}
              style={
                invalid && invalid.includes('statusId') ? backgroundColor : null
              }
            />
          </div>
        )}

        <div className="col-xs-12 col-md-3 col-sm-3 padtop10">
          <Field
            name="lastStatusChangeDate"
            component={renderDateField}
            bsSize="lg"
            placeholder={dateFormat}
            dateFormat={dateFormat}
            label={intl.formatMessage(messages.statusChangeDate)}
            classes="input"
            disabled
          />
        </div>
        <div className="col-xs-12 col-md-3 col-sm-3 padtop10">
          <Field
            name="finishedDate"
            component={renderDateField}
            bsSize="lg"
            placeholder={dateFormat}
            dateFormat={dateFormat}
            onChange={() => {
              setIsDirty(true);
            }}
            label={intl.formatMessage(messages.finishedDate)}
            classes="input"
            disabled={!isStatusCompleted}
          />
        </div>
        <div className="col-xs-12 padtop10">
          <div className="form-group">
            <NoPrint force>
              <label>{intl.formatMessage(messages.afterServiceNote)}</label>
              <Field
                name="assigneeNoteAfterService"
                className="form-control textarea-short"
                placeholder="Start typing..."
                maxLength="500"
                component={renderTextAreaField}
              />
            </NoPrint>
          </div>
        </div>

        <NoPrint force>
          <div className="col-xs-12 padtop10">
            <div className="form-group">
              <label>{intl.formatMessage(messages.vendorNotes)}</label>
              <Field
                name="thirdPartyVendorNotes"
                className="form-control textarea-short"
                placeholder="Start typing..."
                maxLength="500"
                component={renderTextAreaField}
              />
            </div>
          </div>
        </NoPrint>

        {fortressWorkOrdersPhotos && (
          <div className="col-xs-12 padtop10">
            <div className="form-group">
              <label>{intl.formatMessage(messages.photos)}</label>
              <MultiPhotoUpload
                images={afterServiceImages}
                fieldName="afterServiceImages"
                intl={intl}
                workOrderId={workOrderId}
                selectedPropertyId={selectedPropertyId}
                organizationId={organizationId}
                changeCallback={changeCallback}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(AfterService);
