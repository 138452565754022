/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  label: {
    id: 'App.PropertyInfo.LeaseAndPropertySetup.SetLeaseEndDate.label',
    defaultMessage: 'Set Lease End Date to Last Day of the Previous Month',
  },
  tooltip1: {
    id: 'App.PropertyInfo.LeaseAndPropertySetup.SetLeaseEndDate.Header.Icon.Tooltip.1',
    defaultMessage:
      'When this setting is “On,” the Lease End Date will auto-populate with the last day of the calendar month prior to the date resulting from the selected start date and lease term.',
  },
  tooltip2: {
    id: 'App.PropertyInfo.LeaseAndPropertySetup.SetLeaseEndDate.Header.Icon.Tooltip.2',
    defaultMessage:
      'When this setting is “Off,” the Lease End Date will auto-populate with the date resulting from the selected start date and lease term.',
  },
});

export default messages;
