import { defineMessages } from 'react-intl';

const messages = defineMessages({
  status: {
    id: 'App.ActivityContents.Status',
    defaultMessage: 'Status',
  },
  type: {
    id: 'App.ActivityContents.Type',
    defaultMessage: 'Type',
  },
  autoOrManual: {
    id: 'App.ActivityContents.AutoOrManual',
    defaultMessage: 'Automated/Manual',
  },
  date: {
    id: 'App.ActivityContents.Date',
    defaultMessage: 'Date',
  },
  range: {
    id: 'App.ActivityContents.Range',
    defaultMessage: 'Range',
  },
  filterBy: {
    id: 'App.ActivityContents.FilterBy',
    defaultMessage: 'Filter By',
  },
  appliedFilters: {
    id: 'App.ActivityContents.AppliedFilters',
    defaultMessage: 'Applied Filters:',
  },
  clearAll: {
    id: 'App.ActivityContents.ClearAll',
    defaultMessage: 'Clear All',
  },
  createActivity: {
    id: 'App.ActivityContents.CreateActivity',
    defaultMessage: 'Create Activity',
  },
  upcoming: {
    id: 'App.ActivityContents.Upcoming',
    defaultMessage: 'Upcoming',
  },
  pastDue: {
    id: 'App.ActivityContents.PastDue',
    defaultMessage: 'Past Due',
  },
  automatedActivityNote: {
    id: 'App.ActivityContents.AutomatedActivityNote',
    defaultMessage: 'A*: Check mark indicates an automated activity',
  },
  automated: {
    id: 'App.ActivityContents.Automated',
    defaultMessage: 'A*',
  },
  dateTime: {
    id: 'App.ActivityContents.DateTime',
    defaultMessage: 'Date/Time',
  },
  edit: {
    id: 'App.ActivityContents.Edit',
    defaultMessage: 'Edit',
  },
  time: {
    id: 'App.ActivityContents.Time',
    defaultMessage: 'Time',
  },
  view: {
    id: 'App.ActivityContents.View',
    defaultMessage: 'View',
  },
  notes: {
    id: 'App.ActivityContents.notes',
    defaultMessage: 'Notes',
  },
  scheduledfor: {
    id: 'App.ActivityContents.scheduledfor',
    defaultMessage: 'Scheduled for',
  },
  sortAscending: {
    id: 'App.ActivityContents.SortAscending',
    defaultMessage: 'Oldest to Newest',
  },
  sortDescending: {
    id: 'App.ActivityContents.SortDescending',
    defaultMessage: 'Newest to Oldest',
  },
  scheduledActivityStatusName: {
    id: 'App.ActivityContents.ScheduledActivityStatusName',
    defaultMessage: 'Upcoming',
  },
  user: {
    id: 'App.ActivityContents.user',
    defaultMessage: 'User',
  },
  today: {
    id: 'App.ActivityContents.Today',
    defaultMessage: 'Today',
  },
  activityHistory: {
    id: 'App.ActivityContents.ActivityHistory',
    defaultMessage: 'Activity History',
  },
  noActivitiesFound: {
    id: 'App.ActivityContents.NoActivitiesFound',
    defaultMessage: 'No Activities Found',
  },
});

export default messages;
