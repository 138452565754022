import React from 'react';
import DocumentTitle from 'react-document-title';
import useIntl from '../../hooks/useIntl';
import ForecastRentsHeader from './ForecastRentsHeader';
import messages from './ForecastRentsHeader/messages';

const ForecastRents = () => {
  const intl = useIntl();

  return (
    <DocumentTitle title={intl.formatMessage(messages.title)}>
      <div className="bodywrap" data-test="forecast-rents">
        <ForecastRentsHeader />
      </div>
    </DocumentTitle>
  );
};

export default ForecastRents;
