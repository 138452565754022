import { defineMessages } from 'react-intl';

const messages = defineMessages({
  rangeTitle: {
    id: 'App.ManageLeaseExpirations.Filter.RangeTitle',
    defaultMessage: 'Lease End Date Range',
  },
  fromPlaceholder: {
    id: 'App.ManageLeaseExpirations.Filter.FromPlaceholder',
    defaultMessage: 'From',
  },
  toPlaceholder: {
    id: 'App.ManageLeaseExpirations.Filter.ToPlaceholder',
    defaultMessage: 'To',
  },
  toBeforeFrom: {
    id: 'App.ManageLeaseExpirations.Filter.ToBeforeFrom',
    defaultMessage: 'To date cannot be before From date',
  },
});

export default messages;
