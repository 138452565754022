import React from 'react';
import { NoPrint } from 'react-easy-print';
import { CreateWorkOrderButtonsRow } from './createMode';
import { EditWorkOrderButtonsRow } from './editMode';
export const ButtonsRow = ({
  isEditMode,
  history,
  intl,
  valid,
  pristine,
  handleSubmit,
  onSubmit,
  isSubmitting,
}: Object) => (
  <NoPrint>
    {isEditMode ? (
      <EditWorkOrderButtonsRow
        history={history}
        formatMessage={intl.formatMessage}
        disabled={pristine || !valid || isSubmitting}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
    ) : (
      <CreateWorkOrderButtonsRow
        history={history}
        formatMessage={intl.formatMessage}
        disabled={pristine || !valid || isSubmitting}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
    )}
  </NoPrint>
);
