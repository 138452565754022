import {
  compose,
  equals,
  keys,
  defaultTo,
  length,
  head,
  mergeAll,
  reject,
  values,
} from 'ramda';

/* eslint-disable max-len */
import validateApplicantsEmployerSection from '../../../components/LeaseApplicantFormCommonSections/ApplicantsEmployerSection/validate';
/* eslint-enable max-len */

const validateProgress = (formValues: any, { intl }: Object) => {
  const applicantsCurrentEmployer = validateApplicantsEmployerSection(
    formValues.applicantsCurrentEmployer,
    { intl },
  );
  const applicantsSecondCurrentEmployer = validateApplicantsEmployerSection(
    formValues.applicantsSecondCurrentEmployer,
    {
      intl,
    },
  );
  const applicantsPreviousEmployer = validateApplicantsEmployerSection(
    formValues.applicantsPreviousEmployer,
    { intl },
  );

  const arrayOfErrors: any = [
    { applicantsCurrentEmployer },
    { applicantsSecondCurrentEmployer },
    { applicantsPreviousEmployer },
  ];

  const hasError = compose(
    equals(0),
    length,
    keys,
    defaultTo({}),
    head,
    values,
  );

  const errors = mergeAll(reject(hasError, arrayOfErrors));

  return errors;
};

export default validateProgress;
