import { useMemo } from 'react';
const useFooterRow = ({ sortedAndFilteredResults }) => {
  return useMemo(() => {
    const totals = sortedAndFilteredResults.reduce(
      (acc, result) => {
        acc.sqFeet += result.sqFeet;
        acc.marketRent += result.marketRent;
        acc.netMaxRent += result.netMaxRent;
        acc.priorLeasedRent += result.priorLeasedRent;
        acc.currentLeasedRent += result.currentLeasedRent;
        acc.quotingRent += result.quotingRent;
        acc.otherUnitFeesAvailable += result.otherUnitFeesAvailable;
        return acc;
      },
      {
        sqFeet: 0,
        marketRent: 0,
        netMaxRent: 0,
        priorLeasedRent: 0,
        currentLeasedRent: 0,
        quotingRent: 0,
        otherUnitFeesAvailable: 0,
      },
    );
    return {
      sqFeet: {
        variant: 'number',
        value: totals.sqFeet,
      },
      marketRent: {
        variant: 'currency',
        value: totals.marketRent,
      },
      netMaxRent: {
        variant: 'currency',
        value: totals.netMaxRent,
      },
      priorLeasedRent: {
        variant: 'currency',
        value: totals.priorLeasedRent,
      },
      currentLeasedRent: {
        variant: 'currency',
        value: totals.currentLeasedRent,
      },
      quotingRent: {
        variant: 'number',
        value: totals.quotingRent,
      },
      otherUnitFeesAvailable: {
        variant: 'number',
        value: totals.otherUnitFeesAvailable,
      },
    };
  }, [sortedAndFilteredResults]);
};

export default useFooterRow;
