import request, { apiURL } from '../utils/api';
import type { FilterValueAndPagination } from '../containers/App/types';
import { path } from 'ramda';
import moment from 'moment';

export default class ExpiringLeasesService {
  get(
    organizationId: string,
    propertyId: string,
    options: FilterValueAndPagination,
  ) {
    const pageNumber =
      path(['paginationInfo', 'pageNumber'], options) &&
      `page=${options.paginationInfo.pageNumber}`;

    const limit =
      path(['paginationInfo', 'limit'], options) &&
      `limit=${options.paginationInfo.limit}`;

    const sortName =
      path(['sorting', 'fieldName'], options) &&
      `sort=${options.sorting.fieldName}`;

    const sortOrder =
      path(['sorting', 'order'], options) && `order=${options.sorting.order}`;

    const query =
      path(['searchText'], options) && `query=${options.searchText}`;

    const expirationPeriod =
      path(['expirationPeriod'], options) &&
      `expirationPeriod=${options.expirationPeriod}`;

    const renewalStatus =
      path(['renewalStatus'], options) &&
      `renewalStatus=${options.renewalStatus}`;

    const from =
      path(['expirationRange', 'from'], options) &&
      `from=${moment(options.expirationRange.from).toISOString()}`;

    const to =
      path(['expirationRange', 'to'], options) &&
      `to=${moment(options.expirationRange.to).toISOString()}`;

    const queryString = [
      pageNumber,
      limit,
      sortName,
      sortOrder,
      query,
      expirationPeriod,
      renewalStatus,
      from,
      to,
    ]
      .filter((param) => param)
      .join('&');

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/expiring-leases?${queryString}`,
      requestOptions,
    );
  }
}
