import React, { Component } from 'react';
import { type Data as Props } from '../types';
import { DATA_UNDEFINED } from '../constants';
export default class Data extends Component<Props> {
  render() {
    const { className, children, ...props } = this.props;
    return (
      <td className={className} {...props}>
        {children ? children : DATA_UNDEFINED}
      </td>
    );
  }
}
