import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MedicalServicesIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { HouseholdSection } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import {
  Radio,
  TableCell,
  TableRow,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import AddEditEmergencyContactModal from './AddEditEmergencyContactModal';
import { EMERGENCY_CONTACTS_PERMISSION } from './constants';
import {
  useFetchEmergencyContacts,
  useAddEmergencyContact,
  useEditEmergencyContact,
  useSetEmergencyContactAsPrimary,
  useDeleteEmergencyContact,
} from './hooks.js';
import messages from './messages';
import headerMessages from './EmergencyContactsSectionHeader/messages';
import type { BaseEmergencyContactsHookProps } from './types.js';
import usePermissionsHook from '../../../components/ElementWithPermissions/usePermissionsHook';
import DashesIfNullOrUndefined from '../../../components/DashesIfNullOrUndefined';
import { phoneFormatter } from '../../../utils/redux-form-helper';

const EmergencyContactsSection = ({
  intl,
  organizationId,
  propertyId,
  householdId,
  promptToaster,
}: BaseEmergencyContactsHookProps) => {
  const hooksArgs = {
    intl,
    propertyId,
    organizationId,
    householdId,
    promptToaster,
  };
  const { emergencyContacts, refresh } = useFetchEmergencyContacts(hooksArgs);
  const onAddEmergencyContact = useAddEmergencyContact({
    ...hooksArgs,
    refresh,
  });
  const onEditEmergencyContact = useEditEmergencyContact({
    ...hooksArgs,
    refresh,
  });
  const onSetEmergencyContactAsPrimary = useSetEmergencyContactAsPrimary({
    ...hooksArgs,
    refresh,
  });
  const onDeleteEmergencyContact = useDeleteEmergencyContact({
    ...hooksArgs,
    refresh,
  });
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const hasPermissions = usePermissionsHook({
    scope: EMERGENCY_CONTACTS_PERMISSION,
  });
  const onEmergencyContactClicked = (contact: Object) => {
    setSelectedContact(contact);
    setShowAddEditModal(true);
  };

  const onAddEmergencyContactClicked = () => {
    setSelectedContact(null);
    setShowAddEditModal(true);
  };

  const onAddEditModalClose = () => {
    setShowAddEditModal(false);
    setSelectedContact(null);
  };

  return (
    <>
      <HouseholdSection
        Icon={MedicalServicesIcon}
        title={intl.formatMessage(headerMessages.title)}
        buttonProps={
          hasPermissions
            ? {
                onClick: onAddEmergencyContactClicked,
              }
            : undefined
        }
        headers={[messages.primary, messages.name, messages.phoneNumber]}
        rows={
          emergencyContacts?.length
            ? emergencyContacts.map((contact) => {
                return (
                  <TableRow
                    key={contact.id}
                    sx={{ backgroundColor: 'transparent !important' }}
                  >
                    <TableCell>
                      <Radio
                        sx={{ padding: 0.5 }}
                        checked={contact.isPrimaryContact}
                        onChange={() =>
                          onSetEmergencyContactAsPrimary(contact.id)
                        }
                        disabled={contact.isPrimaryContact}
                      />
                    </TableCell>
                    <TableCell>
                      {hasPermissions ? (
                        <a onClick={() => onEmergencyContactClicked(contact)}>
                          <DashesIfNullOrUndefined data={contact.contactName} />
                        </a>
                      ) : (
                        <DashesIfNullOrUndefined data={contact.contactName} />
                      )}
                    </TableCell>
                    <TableCell>
                      <DashesIfNullOrUndefined
                        data={phoneFormatter(contact.phoneNumber.toString())}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            : []
        }
        EmptyStateComponent={
          <FormattedMessage {...messages.noEmergencyContact} />
        }
      ></HouseholdSection>
      {showAddEditModal && (
        <AddEditEmergencyContactModal
          emergencyContact={selectedContact}
          householdId={householdId}
          onHide={onAddEditModalClose}
          onEdit={onEditEmergencyContact}
          onAdd={onAddEmergencyContact}
          onDelete={onDeleteEmergencyContact}
        />
      )}
    </>
  );
};

export default EmergencyContactsSection;
