import React from 'react';
import { Dropdown, MenuItem, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { pathOr } from 'ramda';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import ElementWithPermissions from '../../../components/ElementWithPermissions';

type Props = {
  application: Object,
  applicationId?: string,
  applicationStatus?: string,
  assignCallback?: Function,
  assignedApplicationId?: string,
  assignedUnits: Object,
  isSelfAssigned: boolean,
  quoteCallback: Function,
  unassignCallback?: Function,
  unit: Object,
  unitId: string,
};

const ActionsMenu = ({
  assignedUnits,
  unitId,
  assignedApplicationId,
  isSelfAssigned,
  applicationId,
  applicationStatus,
  quoteCallback,
  assignCallback,
  unassignCallback,
  application,
  unit,
}: Props) => {
  const renderAssign = () => {
    const isFloorPlanAffordable = pathOr(
      false,
      ['rows', 'floorPlan', 'isAffordable'],
      unit,
    );
    const applicationClass = pathOr(
      null,
      ['propertyClass', 'name'],
      application,
    );
    const isApplicationAffordable = applicationClass === 'Affordable';
    const unitTypeValidated = isFloorPlanAffordable === isApplicationAffordable;
    const canAssign =
      ['Approved', 'In Process', 'Converted to Resident'].indexOf(
        applicationStatus,
      ) !== -1;

    if (!assignedApplicationId) {
      return (
        <ElementWithPermissions scope={['manage-units-update']}>
          <MenuItem
            onSelect={assignCallback}
            eventKey="1"
            disabled={!canAssign || isSelfAssigned || !unitTypeValidated}
          >
            <FormattedMessage {...messages.assign} />
          </MenuItem>
        </ElementWithPermissions>
      );
    }

    if (applicationId && assignedApplicationId === applicationId) {
      return (
        <ElementWithPermissions scope={['manage-units-update']}>
          <MenuItem onSelect={unassignCallback} eventKey="1">
            <FormattedMessage {...messages.unassign} />
          </MenuItem>
        </ElementWithPermissions>
      );
    }

    if (applicationId && assignedApplicationId !== applicationId) {
      return (
        <ElementWithPermissions scope={['manage-units-update']}>
          <MenuItem onSelect={assignCallback} eventKey="1" disabled={true}>
            <FormattedMessage {...messages.assign} />
          </MenuItem>
        </ElementWithPermissions>
      );
    }
  };
  const isApplicationCommercial = pathOr(false, ['isCommercial'], application);
  const isCommercial = pathOr(
    false,
    ['rows', 'floorPlan', 'isCommercial'],
    unit,
  );
  const isCommercialLeasingActive = pathOr(
    false,
    ['rows', 'floorPlan', 'isCommercialLeasingActive'],
    unit,
  );
  const isFloorPlanCommercial = isCommercial && isCommercialLeasingActive;
  const renderTooltip = (
    <Tooltip id="tooltip">Cannot provide quote on commercial unit</Tooltip>
  );
  const renderDisabledQuote = (
    <ElementWithPermissions scope={['manage-units-update']}>
      <OverlayTrigger placement="top" overlay={renderTooltip}>
        <MenuItem eventKey="1" disabled={true}>
          <FormattedMessage {...messages.quote} />
        </MenuItem>
      </OverlayTrigger>
    </ElementWithPermissions>
  );
  const renderQuote = (
    <ElementWithPermissions scope={['manage-units-update']}>
      <MenuItem onSelect={quoteCallback} eventKey="1">
        <FormattedMessage {...messages.quote} />
      </MenuItem>
    </ElementWithPermissions>
  );
  const renderDisabledAssign = (
    <ElementWithPermissions scope={['manage-units-update']}>
      <MenuItem onSelect={assignCallback} eventKey="1" disabled={true}>
        <FormattedMessage {...messages.assign} />
      </MenuItem>
    </ElementWithPermissions>
  );

  return (
    <Dropdown bsSize="small" id={'action-menu-{title}'}>
      <Dropdown.Toggle>
        <i className="et-bolt" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {!isFloorPlanCommercial ? renderQuote : renderDisabledQuote}
        {applicationId
          ? (isApplicationCommercial && isFloorPlanCommercial) ||
            (!isApplicationCommercial && !isFloorPlanCommercial)
            ? renderAssign()
            : renderDisabledAssign
          : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionsMenu;
