import messages from './messages';
import moment from 'moment';
import { type } from 'ramda';

const validate = (values: Object, { intl, mode, error }: Object) => {
  const errors = {};
  let datesValid = true;
  if (error && error.async) {
    errors._error = error;
  }
  if (!values.notes) {
    errors.notes = intl.formatMessage(messages.notesRequired);
  }
  const startDate = moment(values.startDate);
  const startTime = moment(values.startTime);
  const prospectCreationDate = moment(values.prospectCreationDate);
  const allDay = values.allDay;

  if (!startDate.isValid() || type(values.startDate) === 'String') {
    errors.startDate = intl.formatMessage(messages.invalidDate);
    datesValid = false;
  }

  if (!startTime.isValid() || type(values.startTime) === 'String') {
    errors.startTime = intl.formatMessage(messages.invalidTime);
    datesValid = false;
  }
  if (!values.activityTypeId || values.activityTypeId === 'default') {
    errors.activityTypeId = intl.formatMessage(messages.requiredFields);
  }
  if (!values.activityCompletionStatusId || values.activityCompletionStatusId === 'default') {
    errors.activityCompletionStatusId = intl.formatMessage(messages.requiredFields);
  }

  if (datesValid) {
    startDate.set({
      hour: startTime.hours(),
      minute: startTime.minutes(),
      second: 0,
      millisecond: 0
    });

    if (mode === 'record' && startDate.toDate() > Date.now()) {
      errors._error = {
        sync: true,
        async: false,
        message: intl.formatMessage(messages.noStartDateInFuture)
      };
    }

    if (mode === 'record' && startDate.add(7, 'days').isBefore(prospectCreationDate.startOf('day'))) {
      errors._error = {
        sync: true,
        async: false,
        message: intl.formatMessage(messages.tooFarPast)
      };
    }

    if (mode === 'schedule') {
      if (startDate.toDate() < Date.now()) {
        errors._error = {
          sync: true,
          async: false,
          message: intl.formatMessage(messages.noStartDateInPast)
        };
      }

      const endDate = moment(startDate);
      const endTime = moment(values.endTime);

      if (endTime.isValid() && type(values.endTime) !== 'String') {
        endDate.set({
          hour: values.endTime.hours(),
          minute: values.endTime.minutes(),
          second: 0,
          millisecond: 0
        });
        if (startDate.toDate() > endDate.toDate() && !allDay) {
          errors._error = {
            sync: true,
            async: false,
            message: intl.formatMessage(messages.noEndDateBeforeStartDate)
          };
        }
      } else {
        errors.endTime = intl.formatMessage(messages.invalidTime);
      }
    }
  }

  return errors;
};

export default validate;
