import {
  GET_ALL_FLOORPLAN_PRICING,
  GET_ALL_FLOORPLAN_PRICING_FAILURE,
  GET_ALL_FLOORPLAN_PRICING_SUCCESS,
  CREATE_FLOORPLAN_PRICING,
  CREATE_FLOORPLAN_PRICING_FAILURE,
  CREATE_FLOORPLAN_PRICING_SUCCESS,
  UPDATE_FLOORPLAN_PRICING,
  UPDATE_FLOORPLAN_PRICING_FAILURE,
  UPDATE_FLOORPLAN_PRICING_SUCCESS,
  DELETE_FLOORPLAN_PRICING,
  DELETE_FLOORPLAN_PRICING_FAILURE,
  DELETE_FLOORPLAN_PRICING_SUCCESS
} from './constants';

export const initialState = {};

export default function floorPlanPricingReducer(
  state: Object = {},
  { type, error, meta, payload }: Object
) {
  switch (type) {
    case CREATE_FLOORPLAN_PRICING_FAILURE:
    case UPDATE_FLOORPLAN_PRICING_FAILURE:
    case DELETE_FLOORPLAN_PRICING_FAILURE:
    case GET_ALL_FLOORPLAN_PRICING_FAILURE: {
      return { ...state, error };
    }
    case GET_ALL_FLOORPLAN_PRICING_SUCCESS: {
      return payload;
    }
    case GET_ALL_FLOORPLAN_PRICING:
    case CREATE_FLOORPLAN_PRICING:
    case CREATE_FLOORPLAN_PRICING_SUCCESS:
    case UPDATE_FLOORPLAN_PRICING:
    case UPDATE_FLOORPLAN_PRICING_SUCCESS:
    case DELETE_FLOORPLAN_PRICING:
    case DELETE_FLOORPLAN_PRICING_SUCCESS:
    default:
      return state;
  }
}
