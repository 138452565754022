import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.PropertyNotSelectedPage.header',
    defaultMessage: 'No property selected!'
  },
  message: {
    id: 'App.PropertyNotSelectedPage.message',
    defaultMessage: 'You have access to multiple properties.'
  },
  message2: {
    id: 'App.PropertyNotSelectedPage.message2',
    defaultMessage: 'Please select a property first.'
  },
  button: {
    id: 'App.PropertyNotSelectedPage.button',
    defaultMessage: 'Go Back'
  }
});

export default messages;
