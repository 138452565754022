import { get, post, patch, del } from '../utils/api';

class InsurancesService {
  getInsuranceTypes(organizationId: string, options?: Object) {
    return get(`/${organizationId}/insurance-types`, options);
  }

  getTenantInsurances(
    organizationId: string,
    propertyId: string,
    householdId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/households/${householdId}/insurances`,
      options,
    );
  }

  create(
    organizationId: string,
    propertyId: string,
    householdId: string,
    insurance: Object,
    options?: Object,
  ) {
    const body = JSON.stringify(insurance);
    return post(
      `/${organizationId}/${propertyId}/households/${householdId}/insurances`,
      body,
      options,
    );
  }

  edit(
    organizationId: string,
    propertyId: string,
    householdId: string,
    insuranceId: string,
    insurance: Object,
    options?: Object,
  ) {
    const body = JSON.stringify(insurance);
    return patch(
      `/${organizationId}/${propertyId}/households/${householdId}/insurances/${insuranceId}`,
      body,
      options,
    );
  }

  delete(
    organizationId: string,
    propertyId: string,
    householdId: string,
    insuranceId: string,
    options?: Object,
  ) {
    return del(
      `/${organizationId}/${propertyId}/households/${householdId}/insurances/${insuranceId}`,
      options,
    );
  }
}

export default InsurancesService;
