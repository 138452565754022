import { useEffect, useRef, useState } from 'react';
import PropertyService from '../../../services/propertyService';

export const useUpdateLeaseExpirationLimit = (
  isLeaseExpirationLimitsActive,
  organizationId,
  propertyId,
  toasterFn,
) => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [isActive, setIsActive] = useState(isLeaseExpirationLimitsActive);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    safeSet(setIsActive)(isLeaseExpirationLimitsActive);
  }, [isLeaseExpirationLimitsActive]);

  const updateLeaseExpirationLimit = async (value) => {
    const propertyService = new PropertyService();

    try {
      safeSet(setIsLoading)(true);
      const response = await propertyService.updateLeaseExpirationLimit(
        organizationId,
        propertyId,
        value,
      );

      if (response) {
        safeSet(setIsActive)(response.isLeaseExpirationLimitsActive);
        toasterFn({
          message: `Lease expiration limits ${
            response.isLeaseExpirationLimitsActive ? 'activated' : 'deactivated'
          }.`,
          title: 'Success',
        });
      }
    } catch {
      toasterFn({
        type: 'error',
        message: 'Failed to update the lease expiration limits setting.',
        title: Error,
      });
    } finally {
      safeSet(setIsLoading)(false);
    }
  };

  const safeSet = (setFn: Function) => (value: string) => {
    if (isMounted.current) {
      setFn(value);
    }
  };

  return [isActive, isLoading, updateLeaseExpirationLimit];
};
