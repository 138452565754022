const { request, apiURL } = require('../utils/api');

export default class CamReconciliationService {
  getOptions(method: string, signal?: Object): Object {
    const options: Object = {
      method,
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    if (signal) {
      options.signal = signal;
    }
    return options;
  }

  getAllReconciliations(
    organizationId: string,
    propertyId: string,
    camRecordsId: string,
    signal?: Object,
  ) {
    const options = this.getOptions('GET', signal);
    return request(
      `${apiURL}/${organizationId}/${propertyId}/cam-records/${camRecordsId}/cam-reconciliations`,
      options,
    );
  }

  postReconciliation(
    organizationId: string,
    propertyId: string,
    camRecordsId: string,
    payload: Object,
    signal?: Object,
  ) {
    const options = this.getOptions('POST', signal);
    options.body = JSON.stringify(payload);
    return request(
      `${apiURL}/${organizationId}/${propertyId}/cam-records/${camRecordsId}/cam-reconciliations`,
      options,
    );
  }
}
