/* eslint-disable no-unused-vars */
import React from 'react';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import useAllUnitAvailability from './useAllUnitAvailability';
import { connect } from 'react-redux';
import useBaseTableProps from '../../hooks/useBaseTableProps';
import TableLayout from '../../components/TableLayout';

function AllUnitAvailability({ intl, currentUser }) {
  const baseTableProps = useBaseTableProps();
  const {
    allColumnsHidden,
    columnOptions,
    count,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    isLoading,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    totalCount,
    onCSVButtonClick,
  } = useAllUnitAvailability({
    intl,
    currentUserId: currentUser.id,
  });

  return (
    <DocumentTitle title={'Manage Unit Availability'}>
      <TableLayout name={name} childrenElementsHeight={56 + 8}>
        {(tableHeight) => (
          <>
            <TableHeader
              title={'Manage Unit Availability'}
              count={count}
              totalCount={totalCount}
            />
            <Table
              {...baseTableProps}
              allColumnsHidden={allColumnsHidden}
              columnOptions={columnOptions}
              count={count}
              dateState={dateState}
              filterState={filterState}
              filterTypeState={filterTypeState}
              handleColumnChange={handleColumnChange}
              handleDateSubmit={handleDateSubmit}
              handleFilterChange={handleFilterChange}
              handleFilterTypeChange={handleFilterTypeChange}
              handleSearchSubmit={handleSearchSubmit}
              handleSortChange={handleSortChange}
              headers={filteredHeaders}
              height={tableHeight}
              intl={intl}
              isLoading={isLoading}
              name={name}
              onCSVButtonClick={onCSVButtonClick}
              order={order}
              orderBy={orderBy}
              rows={rows}
              searchState={searchState}
              selectedColumns={selectedColumns}
              totalCount={totalCount}
            />
          </>
        )}
      </TableLayout>
    </DocumentTitle>
  );
}

const mapStateToProps = (state: Object): Object => {
  const {
    app: { currentUser },
  } = state;
  return {
    currentUser: currentUser.user,
  };
};

export default connect(mapStateToProps)(injectIntl(AllUnitAvailability));
