const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

export type CreateDepositPayload = {
  selectedForDeposit: { id: string, isBatch: boolean }[],
  header: {
    depositTotal: number,
    bankAccountId: string,
    note?: string
  }
};

class DepositsService {
  getDeposits(organizationId: string, propertyId: string, period: string = '') {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };

    const periodQuery = period !== '' ? `?period=${period}` : '';
    return request(
      `${apiURL}/${organizationId}/${propertyId}/manage-deposits${periodQuery}`,
      options
    );
  }
  createDeposit(
    payload: CreateDepositPayload,
    propertyId: string,
    organizationId: string
  ): Promise<void> {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/deposit`,
      options
    );
  }
}

export default DepositsService;
