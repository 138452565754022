import * as ActionTypes from './constants';
import type { Report } from './types';
import type { Action, PaginationMeta, OrderValue } from '../App/types';

//TODO: still used by ManageDelinquency, ManageRentRoll and ManageCommercialRentRoll
export function getAllReports(sorting: OrderValue, searchText: string = ''): * {
  return {
    type: ActionTypes.GET_ALL_REPORTS,
    payload: {
      sorting,
      searchText,
    },
  };
}

export function getAllReportsSuccess(
  reports: Array<Report>,
  meta: PaginationMeta,
) {
  const reportsToOmit = ['Portfolio-Summary'];
  return {
    type: ActionTypes.GET_ALL_REPORTS_SUCCESS,
    payload: reports.filter(
      (report) => reportsToOmit.indexOf(report.referenceId) === -1,
    ),
    meta,
  };
}

export function getAllReportsFail(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_REPORTS_ERROR,
    payload: error,
  };
}
