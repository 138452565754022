import { defineMessages } from 'react-intl';

const messages = defineMessages({
  cancel: {
    id: 'App.AddGroup.Cancel',
    defaultMessage: 'Cancel',
  },
  addToBatch: {
    id: 'App.AddGroup.addToBatch',
    defaultMessage: 'Add To Batch',
  },
  goBackCancelConfirmation: {
    id: 'App.AddGroupHeader.goBackCancelConfirmation',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
});

export default messages;
