import { useMemo } from 'react';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
  calculateRentWithAmenities,
  getMonthlyRecurringCharges,
} from '../../utils/lease-helpers';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import {
  getUnitMoveInReadyDate,
  getUnitMoveOutDate,
} from '../AllUnitAvailability/utils';

const useParseResults = ({ results, propertyPrimaryHUDCode, fromUnit }) => {
  return useMemo(() => {
    const today = moment();
    const { units } = results;
    const filteredUnits = isEmpty(fromUnit ?? '')
      ? units
      : units.filter((unit) => unit.number !== fromUnit);
    return filteredUnits.map((unit) => {
      const {
        floorPlan,
        currentAssignation,
        nextAssignation,
        priorAssignation,
        unitStatus,
        unitFees,
      } = unit;
      const { nBeds, nBaths, nHalfBaths } = floorPlan;
      const { isAvailable, isVacant } = unitStatus;
      const { lease, application } = currentAssignation;
      const isTransfer = nextAssignation?.lease?.isTransfer;
      const household = lease?.household || application?.household;
      const bedsBaths = `${+nBeds}/${+nBaths + +nHalfBaths * 0.5}`;
      const turnMoveOutDays = unit?.property?.turnMoveOutDays ?? 0;
      const lastVacancyStartDate = isVacant ? unit?.lastVacancyStartDate : null;
      const moveOutDate = getUnitMoveOutDate(
        lastVacancyStartDate,
        currentAssignation?.lease?.noticeToVacate?.moveOutDate ??
          priorAssignation?.lease?.moveOutDate,
      );
      const {
        firstName,
        lastName,
        rc: { residentId },
      } = household?.mainCus || { rc: {} };

      return {
        assignedApplicationId: nextAssignation?.application?.id,
        nBeds,
        unitId: unit.id,
        unit: unit.number,
        unitLink: getUrlWithSelectedPropertyId(
          `/unit/${unit.id}?prev=available`,
        ),
        floorPlan: floorPlan.internalName,
        floorPlanLink: getUrlWithSelectedPropertyId(
          `/floorplan/${floorPlan.id}`,
        ),
        isFloorPlanAffordable: floorPlan.isAffordable,
        isFloorPlanCommercial:
          floorPlan?.isCommercial && floorPlan?.isCommercialLeasingActive,
        floorPlanHudCode:
          floorPlan?.fpap?.[0]?.hudCode ?? propertyPrimaryHUDCode,
        bedsBaths,
        sqFeet: floorPlan.grossSquareFeet,
        status: unitStatus.description,
        leased: (() => {
          const hasApplicant = Boolean(nextAssignation?.id);
          const { application, lease } = nextAssignation;
          const status = application?.applicationStatus?.name;
          const isTransferring = lease?.isTransfer;

          if (
            status === 'Converted to Resident' &&
            currentAssignation &&
            !isTransferring
          ) {
            return false;
          }

          return hasApplicant;
        })(),
        resident: (() => {
          if (!currentAssignation) {
            return '';
          }

          let name;

          if (firstName && lastName) {
            name = `${lastName}, ${firstName}`;
          } else {
            name = firstName + lastName;
          }

          const isUnitCommercial =
            floorPlan?.isCommercial && floorPlan?.isCommercialLeasingActive;
          if (isUnitCommercial) {
            name = firstName;
          }

          return name;
        })(),
        residentLink: getUrlWithSelectedPropertyId(`/resident/${residentId}`),
        residentId,
        marketRent: unit.rentCalculations.marketRent,
        netMaxRent: unit.rentCalculations.netMaxRent,
        priorLeasedRent: priorAssignation?.lease?.leasedRent,
        currentLeasedRent: (() => {
          let rent = 0;
          if (!isVacant) {
            rent = currentAssignation?.lease?.leasedRent || 0;
          } else {
            const applicantStatus =
              nextAssignation?.application?.applicationStatus?.name || '';
            if (applicantStatus.includes('Approved')) {
              rent = nextAssignation?.lease?.leasedRent || 0;
            }
          }

          return rent;
        })(),
        moveOutDate,
        moveInReady: isAvailable
          ? getUnitMoveInReadyDate(
              unitStatus.description,
              unit.makeReadyDate ?? null,
              turnMoveOutDays,
              moveOutDate,
            )
          : null,
        notes: unit.notes,
        daysVacant: (() => {
          if (!isAvailable || !isVacant) {
            return null;
          }

          const moveOut = priorAssignation?.lease?.moveOutDate;
          const lastVacancyStartDate = unit?.lastVacancyStartDate;

          if (
            !moveOut &&
            unitStatus?.isAvailable &&
            isVacant &&
            lastVacancyStartDate
          ) {
            return moment().diff(moment(lastVacancyStartDate), 'days');
          }

          if (moment(moveOut).isAfter(today)) {
            return null;
          }

          return moment().diff(moment(moveOut), 'days');
        })(),
        quotingRent: (() => {
          if (!isAvailable) {
            return null;
          }

          let startingRent = unit.quotingRentAmount;

          if (!startingRent) {
            startingRent = floorPlan.quotingRentAmount;
            if (!startingRent) {
              return null;
            }
          }

          return calculateRentWithAmenities(unit, startingRent);
        })(),
        otherUnitFeesAvailable: (() => {
          const arr = getMonthlyRecurringCharges(unit);

          if (!arr.length) {
            return null;
          }

          return arr.reduce((prev, fee) => {
            prev += fee.value;
            return prev;
          }, 0);
        })(),
        // {applicant, applicantLink}
        ...(() => {
          const result = { applicant: null, applicantLink: null };
          if (!nextAssignation?.application) {
            return result;
          }

          const applicationStatus =
            nextAssignation.application.applicationStatus?.name || '';

          if (
            applicationStatus === 'Converted to Resident' &&
            currentAssignation &&
            !isTransfer
          ) {
            return result;
          }

          const customer =
            nextAssignation.lease?.household?.mainCus ||
            nextAssignation.application.household.mainCus;

          if (!customer) {
            return result;
          }

          const applicationId = nextAssignation.application.id || '';
          result.applicant =
            customer.lastName && customer.firstName
              ? `${customer.lastName}, ${customer.firstName}`
              : customer.firstName + customer.lastName;

          const isUnitCommercial =
            floorPlan?.isCommercial && floorPlan?.isCommercialLeasingActive;
          if (isUnitCommercial) {
            result.applicant = customer.firstName || '';
          }

          const residentId =
            nextAssignation.lease?.household?.mainCus?.rc?.residentId ||
            nextAssignation?.application?.household?.mainCus?.rc?.residentId;

          result.applicantLink = getUrlWithSelectedPropertyId(
            isTransfer
              ? `/resident/${residentId}`
              : `/application/${applicationId}`,
          );

          return result;
        })(),
        applicantStatus: (() => {
          let applicantStatus =
            nextAssignation?.application?.applicationStatus?.name;

          if (applicantStatus === 'Converted to Resident') {
            if (!currentAssignation) {
              applicantStatus = 'Awaiting Move In';
            } else if (isTransfer && currentAssignation) {
              const unitNumber =
                nextAssignation?.lease?.household?.activeLease?.units?.[0]
                  ?.number ||
                nextAssignation?.application?.household?.activeLease?.units?.[0]
                  ?.number ||
                '';
              applicantStatus = `Transferring from ${unitNumber}`;
            }
          }

          return applicantStatus;
        })(),
        scheduledMoveInDate: (() => {
          const status = nextAssignation?.application?.applicationStatus?.name;
          const isMovedIn = nextAssignation?.lease?.isMovedIn ?? true;
          const moveInDate = nextAssignation?.lease?.moveInDate;

          if (
            status === 'Converted to Resident' &&
            currentAssignation &&
            !isTransfer
          ) {
            return null;
          }

          if (isMovedIn || !moveInDate) {
            return null;
          }

          return moveInDate;
        })(),
        amenities: unitFees.length
          ? unitFees
              .map(({ propertyFees }) => {
                return propertyFees?.displayNameOnQuote;
              })
              .join(', ')
          : null,
      };
    });
  }, [fromUnit, propertyPrimaryHUDCode, results]);
};

export default useParseResults;
