const { get, post } = require('../utils/api');

class SubsidyPaymentService {
  getAllSubsidyPaymentBatches(
    organizationId: string,
    propertyId: string,
    signal?: Object,
  ): Promise<any> {
    const options = signal ? { signal } : {};
    return get(`/${organizationId}/${propertyId}/subsidyPaymentBatch`, options);
  }

  getHouseholdsWithSubjournalBalance(
    organizationId: string,
    propertyId: string,
    subjournalTypeId: string,
    options?: Object,
  ): Promise<any> {
    return get(
      `/${organizationId}/${propertyId}/subjournal/${subjournalTypeId}/households`,
      options,
    );
  }

  saveSubsidyPaymentBatchHeader(
    organizationId: string,
    propertyId: string,
    payload: Object,
  ): any {
    return post(
      `/${organizationId}/${propertyId}/subsidyPaymentBatchHeader`,
      JSON.stringify(payload),
    );
  }
}

export default SubsidyPaymentService;
