import request, { apiURL } from '../utils/api';

export default class TransactionTypeService {
  getTransactionTypes() {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(`${apiURL}/transaction-types`, requestOptions);
  }
}
