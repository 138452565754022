import type { OrganizationId } from '../containers/App/types';
import request, { apiURL } from '../utils/api';
import { get } from '../utils/api';

export default class PropertyTransactionCodesService {
  getPropertyTransactionCodes(
    organizationId: OrganizationId,
    propertyId: string,
    options: Object = {},
  ) {
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/property-transaction-codes`,
      requestOptions,
    );
  }

  getPropertySubsidyBatchTransactionCodes(
    organizationId: OrganizationId,
    propertyId: string,
    options: Object = {},
  ) {
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };
    return get(
      `/${organizationId}/${propertyId}/property-subsidy-batch-transaction-codes`,
      requestOptions,
    );
  }

  getByMasterTransactionCode(propertyId: string, mtc: string) {
    return get(`/${propertyId}/property-transaction-codes/${mtc}`);
  }
}
