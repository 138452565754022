import { defineMessages } from 'react-intl';

const messages = defineMessages({
  resident: {
    id: 'App.Ledger.Resident',
    defaultMessage: 'Resident',
  },
  security: {
    id: 'App.Ledger.Security',
    defaultMessage: 'Security',
  },
  allSubjournals: {
    id: 'App.Ledger.AllSubjournals',
    defaultMessage: 'All Subjournals',
  },
  statusDefinition: {
    id: 'App.Ledger.StatusDefinition',
    defaultMessage:
      'St*: Indicates transaction status (P-Posted, RD- Reversed, RL-Reversal)',
  },
  manualDefinition: {
    id: 'App.Ledger.ManualDefinition',
    defaultMessage:
      'M**: Check mark indicates a manual transaction (not a batch transaction)',
  },
  pendingHeaderReversal: {
    id: 'App.Ledger.pendingHeaderReversal',
    defaultMessage: 'Pending',
  },
  successHeaderReversal: {
    id: 'App.Ledger.successHeaderReversal',
    defaultMessage: 'Success',
  },
  errorHeaderReversal: {
    id: 'App.Ledger.errorHeaderReversal',
    defaultMessage: 'Error',
  },
  pendingDescriptionReversal: {
    id: 'App.Ledger.pendingDescriptionReversal',
    defaultMessage: 'Reverse Transaction pending.',
  },
  successDescriptionReversal: {
    id: 'App.Ledger.successDescriptionReversal',
    defaultMessage: 'Transaction reversed.',
  },
  errorDescriptionReversal: {
    id: 'App.Ledger.errorDescriptionReversal',
    defaultMessage: 'Transaction could not be reversed.',
  },
  updatePaymentRestrictions: {
    id: 'App.Ledger.updatePaymentRestrictions',
    defaultMessage: 'Payment restrictions saved',
  },
  applicantRefundStatement: {
    id: 'App.Ledger.applicantRefundStatement',
    defaultMessage: 'Applicant Refund Statement Sent',
  },
  successHeaderRefund: {
    id: 'App.Ledger.successHeaderRefund',
    defaultMessage: 'Success',
  },
  successDescriptionRefund: {
    id: 'App.Ledger.successDescriptionRefund',
    defaultMessage: 'Refund posted.',
  },
  errorDescriptionRefund: {
    id: 'App.Ledger.errorDescriptionRefund',
    defaultMessage: 'Error posting redund to applicant.',
  },
  pendingHeaderRefund: {
    id: 'App.Ledger.pendingHeaderRefund',
    defaultMessage: 'Pending',
  },
  pendingMessageRefund: {
    id: 'App.Ledger.pendingMessageRefund',
    defaultMessage: 'Applicant refund request received, please wait...',
  },
});

export default messages;
