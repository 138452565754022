import messages from './messages';
import ActivityService from '../../services/activityService';
import moment from 'moment';
import { throttle } from 'lodash';
import type { Activity } from '../../containers/ProspectProfile/types';
const getActivity = (
  mode: 'record' | 'schedule',
  {
    startDate,
    startTime,
    endTime,
    notes,
    activityTypeId,
    activityType,
    assignedToId,
    allDay,
    prospect,
    activityCompletionStatusId,
  }: Object,
  prospectId: string,
): ?Activity => {
  try {
    if (!activityTypeId || activityTypeId === 'default') {
      return;
    }
    const startDateCopy = moment(startDate);

    startDateCopy.set({
      hour: allDay ? 0 : startTime.hours(),
      minute: allDay ? 0 : startTime.minutes(),
      second: 0,
      millisecond: 0,
    });

    const endDate = moment(startDateCopy);

    if (mode === 'schedule') {
      endDate.set({
        hour: allDay ? 23 : endTime.hours(),
        minute: allDay ? 59 : endTime.minutes(),
        second: 0,
        millisecond: 0,
      });
    }

    return {
      startTime: startDateCopy.toDate(),
      endTime: endDate.toDate(),
      activityCompletionStatusId:
        mode === 'record' ? activityCompletionStatusId : undefined,
      notes: notes,
      activityTypeId: activityTypeId,
      prospectId: prospectId || '',
      assignedToId: assignedToId,
      allDay: allDay,
      propertyId: prospect.propertyId,
      activityType,
      prospect,
    };
  } catch (error) {}
};

const validateOverlap = (warnings, actions, intl, mode, values, prospect) => {
  const activityService = new ActivityService();
  const activity = getActivity(mode, values, prospect.id);
  if (activity) {
    activityService.validate(activity).then((response: Object) => {
      if (!response.success) {
        warnings._warning = intl.formatMessage(
          messages.noOverlappingActivities,
        );
        actions.updateSyncWarnings(
          'recordActivity',
          warnings,
          warnings._warning,
        );
      }
    });
  }
};

const warn = (values: Object, { intl, mode, prospect, actions }: Object) => {
  const throttledValidateOverlap = throttle(validateOverlap, 1000);
  const warnings = {};
  if (values.notes && values.notes.length > 1980) {
    warnings.notes = intl.formatMessage(messages.notesLengthWarning, {
      current: values.notes.length,
      max: 2000,
    });
  }

  throttledValidateOverlap(warnings, actions, intl, mode, values, prospect);

  return warnings;
};

export default warn;
