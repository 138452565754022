import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';

import { formatPeriod } from '../../../utils/redux-form-helper.js';
import messages from './messages';
import IntacctStatusSection from './IntacctStatusSection';

const StyledIntacctDepositDiv = styled.div`
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  align-items: center;
`;

type Props = {
  intl: Object,
  headerData: Object,
};

const ViewBankDepositHeader = ({ intl, headerData }: Props) => {
  const { viewIntacctDetailsInDeposits } = useFlags();
  const {
    batchTotal,
    propertyBankAccounts: { bankAccountType },
    batchDate,
    batchNote,
    name,
    propertyFiscalPeriods: { period },
  } = headerData;
  const { formatMessage, formatDate, formatNumber } = intl;
  const faraStatus = headerData?.intacctFARAStatus ?? null;
  const farcStatus = headerData?.intacctFARCStatus ?? null;
  return (
    <div className="container-fluid" data-test="view-bank-deposit-header">
      <div className="row form-header">
        <div className="col-md-5">
          <i className="et-cash float-left" />
          <h2 className="float-left">{formatMessage(messages.title)}</h2>
        </div>
        <div className="col-md-7 text-right">
          <div className="highlight-details highlight-details--nobg">
            <span>
              {formatMessage(messages.batchId)}: {name}
            </span>
            <span className="no-third">
              {formatMessage(messages.period)}: {formatPeriod(period)}
            </span>
          </div>
        </div>
      </div>
      <div className="row padtop10">
        <div className="col-md-2 col-lg-2">
          <div className="block-data block-white-shadow">
            <h5>{formatMessage(messages.batchTotal)}</h5>
            <span>
              {formatNumber(batchTotal, {
                style: 'currency',
                currency: 'USD',
              })}
            </span>
          </div>
        </div>
        <div className="col-md-2">
          <div className="block-data block-white-shadow">
            <h5>{formatMessage(messages.bankAccount)}</h5>
            <span>{bankAccountType}</span>
          </div>
        </div>
        <div className="col-md-2">
          <div className="block-data block-white-shadow">
            <h5>{formatMessage(messages.depositDate)}</h5>
            <span>
              {formatDate(batchDate, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
            </span>
          </div>
        </div>
        <div className="col-md-2">
          <div className="block-data block-white-shadow">
            <h5>{formatMessage(messages.note)}</h5>
            <span>{batchNote}</span>
          </div>
        </div>
        {viewIntacctDetailsInDeposits && (
          <div className="col-md-4">
            <StyledIntacctDepositDiv className="block-data block-white-shadow">
              <IntacctStatusSection
                faraStatus={faraStatus}
                farcStatus={farcStatus}
              />
            </StyledIntacctDepositDiv>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewBankDepositHeader;
