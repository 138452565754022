import React, { Component } from 'react';
import { Grid, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { any, equals, pathOr, partial } from 'ramda';
import {
  reduxForm,
  Field,
  FieldArray,
  formValueSelector,
  getFormSyncErrors,
} from 'redux-form';
import { connect } from 'react-redux';
import ElementWithPermissions from '../../../../components/ElementWithPermissions';
import {
  renderCheckboxField,
  renderDateField,
} from '../../../../utils/redux-form-helper';
import messages from '.././messages';
import validate from '.././validate';

import type { Props, InjectedProps, ReduxFormProps } from '../types';
const renderTransferActions = ({ transferActions }) => (
  <ul className="list-simple list-movein-actions">
    {transferActions.map((option, index) => (
      <li key={`actions__${index}`}>
        <Field
          name={`transferActions[${option.id}]`}
          component={renderCheckboxField}
          bsSize="lg"
          label={option.text}
        />
      </li>
    ))}
  </ul>
);
class TransferCheckForm extends Component<
  Props & InjectedProps & ReduxFormProps,
> {
  onCancel = () => {
    this.props.handleCancel();
  };
  getStatus = (status: Object) => {
    return status.valid
      ? 'status status-ball status-green'
      : 'status status-ball status-red';
  };
  getCompletionLabel = (status: Object) => {
    return status.valid ? `${status.label}` : `Not ${status.label}`;
  };
  render() {
    const {
      residentName,
      handleSubmit,
      invalid,
      transferActions,
      steps,
      submitting,
      unitNumber,
      loadingRequirements,
      formSyncErrors,
      handleTransferDateUpdate,
      actualTransferDateTouched,
      transferActionsField,
    } = this.props;
    const transferDateStatus = {
      statusText: Object.keys(formSyncErrors).includes('actualTransferDate')
        ? 'Not Confirmed'
        : 'Confirmed',
      valid: !Object.keys(formSyncErrors).includes('actualTransferDate'),
    };
    let transferDateErrorMsg;
    return (
      !loadingRequirements && (
        <form className="form-container container-fluid resident-convert-form resident-expand-container">
          <Grid fluid>
            <div className="applicant-convert-header">
              <Row>
                <Col xs={6} sm={12}>
                  <a className="btn-text" onClick={this.onCancel}>
                    <i className="et-chevron-left" />
                    Go Back to Resident profile
                  </a>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12}>
                  <h1>
                    Are you sure you want to transfer this Resident to a new
                    Unit? {residentName}
                  </h1>
                  <h4>New Transfer Unit: {unitNumber}</h4>
                  <p>Please review and confirm before you continue.</p>
                </Col>
              </Row>
              <div className="form-container">
                <Row>
                  <Col xs={12} sm={7}>
                    <h3>Steps Completed And Documents Uploaded:</h3>
                    <div className="checklist-small">
                      <ul>
                        {steps.map((step, index) => (
                          <li key={`steps__${index}`}>
                            <div className="item-property">{step.name}</div>
                            <div className="item-value text-right">
                              {this.getCompletionLabel(step)}
                              <span className={this.getStatus(step)} />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                  <Col xs={12} sm={5}>
                    <h3>Transfer Actions Completed:</h3>
                    <FieldArray
                      name="transferActions"
                      component={renderTransferActions}
                      transferActions={transferActions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="padtop10">
                    <h3>Confirm Transfer Date:</h3>
                  </Col>
                  <Col xs={12} sm={7}>
                    <div className="checklist-small no-margin-bottom">
                      <ul>
                        <li>
                          <div className="item-property">
                            <Field
                              name="actualTransferDate"
                              onChange={partial(handleTransferDateUpdate, [
                                transferActionsField,
                              ])}
                              component={renderDateField}
                              bsSize="lg"
                            />
                            {transferDateErrorMsg && (
                              <p
                                className={
                                  actualTransferDateTouched
                                    ? 'text-red'
                                    : 'text-gray'
                                }
                              >
                                {transferDateErrorMsg}
                              </p>
                            )}
                          </div>
                          <div className="item-value text-right">
                            {transferDateStatus.statusText}
                            <span
                              className={this.getStatus(transferDateStatus)}
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <p>
                      <b>Note: </b>
                      Completing this action will move resident into new unit
                      and will move resident out of old unit. Please ensure
                      possesion of old apartment has been received before
                      completing action.
                    </p>
                  </Col>
                </Row>
                <hr />
              </div>
            </div>
            <Col xs={6}>
              <Button
                bsStyle="default"
                className="btn btn-default pull-right"
                onClick={this.onCancel}
              >
                Cancel
              </Button>
            </Col>
            <Col xs={6}>
              <ElementWithPermissions scope={['resident-create']}>
                <Button
                  disabled={any(equals(true), [submitting, invalid])}
                  className="btn btn-primary pull-left"
                  onClick={handleSubmit}
                >
                  <FormattedMessage {...messages.submitButton} />
                </Button>
              </ElementWithPermissions>
            </Col>
          </Grid>
        </form>
      )
    );
  }
}
let TransferForm = reduxForm({
  form: 'TransferCheck',
  validate: validate,
  enableReinitialize: true,
})(TransferCheckForm);

const selector = formValueSelector('TransferCheck');
const mapFormStateToProps = (state: Object): any => {
  const { form } = state;
  return {
    transferActionsField: selector(state, 'transferActions') || [],
    actualTransferDate: selector(state, 'actualTransferDate'),
    formSyncErrors: getFormSyncErrors('TransferCheck')(state),
    actualTransferDateTouched: pathOr(
      false,
      ['TransferCheck', 'fields', 'actualTransferDate', 'touched'],
      form,
    ),
  };
};
TransferForm = connect(mapFormStateToProps)(TransferForm);

export { TransferForm };
