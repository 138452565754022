import { defineMessages } from 'react-intl';

const messages = defineMessages({
  AssignPropertyHeader: {
    id: 'App.EditUser.AssignUserProperty.AssignPropertyHeader',
    defaultMessage: 'Assign Properties',
  },
  SelectAllProperties: {
    id: 'App.EditUser.AssignUserProperty.SelectAllProperties',
    defaultMessage: 'Select All Properties',
  },
  AssignPropertiesDescription: {
    id: 'App.EditUser.AssignUserProperty.AssignPropertiesDescription',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Check item(s) on the left to assign, this will be reflected on the right column. Any Property selected will not be unselected if you unselect any Property Group',
  },
  NoDataTitle: {
    id: 'App.EditUser.AssignUserProperty.NoDataTitle',
    defaultMessage: 'No Property Groups and/or Properties Selected',
  },
  NoDataSubtitle: {
    id: 'App.EditUser.AssignUserProperty.NoDataSubtitle',
    defaultMessage: 'Select from the left column to assign ',
  },
  GroupSearchPlaceholder: {
    id: 'App.EditUser.AssignUserProperty.GroupSearchPlaceholder',
    defaultMessage: 'Search for a Group...',
  },
  PropertySearchPlaceholder: {
    id: 'App.EditUser.AssignUserProperty.PropertySearchPlaceholder',
    defaultMessage: 'Search for a Property...',
  },
  NoneSelectedMessage: {
    id: 'App.EditUser.AssignUserProperty.NoneSelectedMessage',
    defaultMessage: 'Please select at least 1 property or group',
  },
  PropertyGroups: {
    id: 'App.EditUser.AssignUserProperty.PropertyGroups',
    defaultMessage: 'Property Groups',
  },
  PropertyTypes: {
    id: 'App.EditUser.AssignUserProperty.PropertyTypes',
    defaultMessage: 'Property Types',
  },
  Properties: {
    id: 'App.EditUser.AssignUserProperty.Properties',
    defaultMessage: 'Properties',
  },
  SelectedItems: {
    id: 'App.EditUser.AssignUserProperty.SelectedItems',
    defaultMessage: 'Selected Items ',
  },
  NoPropertyGroups: {
    id: 'App.EditUser.AssignUserProperty.NoPropertyGroups',
    defaultMessage: 'No Property Groups',
  },
  Loading: {
    id: 'App.EditUser.AssignUserProperty.Loading',
    defaultMessage: 'Loading...',
  },
  GroupLabel: {
    id: 'App.EditUser.AssignUserProperty.GroupLabel',
    defaultMessage: 'Group',
  },
  PropertyLabel: {
    id: 'App.EditUser.AssignUserProperty.PropertyLabel',
    defaultMessage: 'Property',
  },
  All: {
    id: 'App.EditUser.AssignUserProperty.All',
    defaultMessage: 'All',
  },
  Affordable: {
    id: 'App.EditUser.AssignUserProperty.Affordable',
    defaultMessage: 'Affordable',
  },
  Conventional: {
    id: 'App.EditUser.AssignUserProperty.Conventional',
    defaultMessage: 'Conventional',
  },
  Commercial: {
    id: 'App.EditUser.AssignUserProperty.Commercial',
    defaultMessage: 'Commercial',
  },
  PropertyGroupToolTip: {
    id: 'App.EditUser.AssignUserProperty.PropertyGroupToolTip',
    defaultMessage:
      // eslint-disable-next-line max-len
      'By assigning a property group, any properties added or removed from a property group, will be automatically reflected in the list of assigned properties for the user.',
  },
  AssignPropertiesNote: {
    id: 'App.EditUser.AssignUserProperty.AssignPropertiesNote',
    defaultMessage:
      // eslint-disable-next-line max-len
      "indicates the property is within a selected group and will be included, even if you uncheck the property's checkbox.",
  },
  Note: {
    id: 'App.EditUser.AssignUserProperty.Note',
    defaultMessage: 'Note: ',
  },
});

export default messages;
