import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field } from 'redux-form';
import { isNil } from 'ramda';

import * as appSelectors from '../../App/selectors';
import * as selectors from '../selectors';
import * as actions from '../actions';

import {
  renderTextField,
  renderSelectField,
  required,
  requiredString,
  validateName,
  validateNonRequiredName,
  requiredSelect,
} from '../../../utils/redux-form-helper';

const MinorFields = (props: any) => {
  const {
    enabledMinors,
    enableMinor,
    disableMinor,
    suffixOptions,
    relationshipOptions,
    affordableRelationshipOptions,
    fields,
    leaseLocked,
  } = props;

  return props.fields.map((field, index) => {
    const isEnabled = enabledMinors.includes(index);
    const onPencilClickAction = isEnabled ? disableMinor : enableMinor;
    const isNew = isNil(fields.get(index).id);

    return [
      <div className="container-of-inputs" key={field}>
        <div className="col col-xs-6 col-sm-2">
          <Field
            name={`${field}.firstName`}
            component={renderTextField}
            validate={[required, requiredString, validateName]}
            disabled={(!isEnabled && !isNew) || leaseLocked}
          />
        </div>
        <div className="col col-xs-6 col-sm-2">
          <Field
            name={`${field}.middleName`}
            component={renderTextField}
            disabled={(!isEnabled && !isNew) || leaseLocked}
            validate={[validateNonRequiredName]}
          />
        </div>
        <div className="col col-xs-6 col-sm-2">
          <Field
            name={`${field}.lastName`}
            component={renderTextField}
            validate={[required, requiredString, validateName]}
            disabled={(!isEnabled && !isNew) || leaseLocked}
          />
        </div>
        <div className="col col-xs-6 col-sm-1">
          <Field
            name={`${field}.suffixId`}
            component={renderSelectField}
            options={suffixOptions}
            disabled={(!isEnabled && !isNew) || leaseLocked}
          />
        </div>
        <div className="col col-xs-6 col-sm-1">
          <Field
            name={`${field}.preferredName`}
            component={renderTextField}
            disabled={(!isEnabled && !isNew) || leaseLocked}
            validate={[validateNonRequiredName]}
          />
        </div>
        <div className="col col-xs-4 col-sm-1">
          <Field
            name={`${field}.relationshipId`}
            component={renderSelectField}
            options={relationshipOptions}
            validate={requiredSelect}
            disabled={(!isEnabled && !isNew) || leaseLocked}
          />
        </div>
        <div className="col col-xs-4 col-sm-1">
          <Field
            name={`${field}.affordableRelationshipId`}
            component={renderSelectField}
            options={affordableRelationshipOptions}
            validate={requiredSelect}
            disabled={(!isEnabled && !isNew) || leaseLocked}
          />
        </div>
        <div className="col col-xs-4 col-sm-2">
          <Field
            name={`${field}.age`}
            component={renderTextField}
            disabled={(!isEnabled && !isNew) || leaseLocked}
          />
        </div>
      </div>,
      <div className="container-of-trash" key={`${field}-trash`}>
        <div className="row-remove-btn">
          <a onClick={() => onPencilClickAction(index)} disabled={leaseLocked}>
            <i className="et-pencil" />
          </a>
          <a
            style={{ marginLeft: '10%' }}
            onClick={() => props.deleteMinor(index)}
            disabled={leaseLocked}
          >
            <i className="et-trash" />
          </a>
        </div>
      </div>,
    ];
  });
};

export default connect(
  createStructuredSelector({
    enabledMinors: selectors.getEnabledMinors,
    suffixOptions: appSelectors.getNameSuffixOptions,
    relationshipOptions: appSelectors.getMinorRelationshipOptions,
    affordableRelationshipOptions:
      appSelectors.getMinorAffordableRelationshipOptions,
  }),
  {
    enableMinor: actions.enableMinorEdit,
    disableMinor: actions.disableMinorEdit,
  },
)(MinorFields);
