export const GET_ALL_WORK_ORDERS = 'app/ManageWorkOrders/GET_ALL_WORK_ORDERS';
export const GET_ALL_WORK_ORDERS_SUCCESS =
  'app/ManageWorkOrders/GET_ALL_WORK_ORDERS_SUCCESS';
export const GET_ALL_WORK_ORDERS_ERROR =
  'app/ManageWorkOrders/GET_ALL_WORK_ORDERS_ERROR';

export const UPDATE_WORK_ORDER_DROPDOWN =
  'app/ManageWorkOrders/UPDATE_WORK_ORDER_DROPDOWN';
export const UPDATE_WORK_ORDER_DROPDOWN_SUCCESS =
  'app/ManageWorkOrders/UPDATE_WORK_ORDER_DROPDOWN_SUCCESS';
export const UPDATE_WORK_ORDER_DROPDOWN_ERROR =
  'app/ManageWorkOrders/UPDATE_WORK_ORDER_DROPDOWN_ERROR';

export const UPDATE_WORK_ORDERS_QUERY_PARAMS =
  'app/ManageWorkOrders/UPDATE_WORK_ORDERS_QUERY_PARAMS';

export const UPDATE_COLUMNS_SORT_VALUE =
  'app/ManageWorkOrders/UPDATE_COLUMNS_SORT_VALUE';
export const CLEAN_LOADED_WORK_ORDERS =
  'app/ManageWorkOrders/CLEAN_LOADED_WORK_ORDERS';
