export const cleanUpFormObject = (formObject) => {
  return Object.entries(formObject)
    .filter(([, value]) => {
      if (Array.isArray(value)) {
        const cleanedUpArray = value.filter((arrayValue) => arrayValue);
        return cleanedUpArray.length > 0;
      }

      if (/all/gi.test(value)) {
        return false;
      }

      return value;
    })
    .reduce(
      (finalObject, [key, value]) => ({
        ...finalObject,
        [key]: value,
      }),
      {},
    );
};
