import SocketProvider from '../services/socketService';

/**
 * Redux Middleware for handling Socket related actions
 */
export default (storeAPI: Object) => (next: Function) => (action: Function) => {
  switch (action.type) {
    case 'app/SOCKET_CONNECT': {
      SocketProvider.connect(storeAPI.dispatch);
      break;
    }
    case 'app/SOCKET_DISCONNECT': {
      if (SocketProvider.socket) {
        SocketProvider.disconnect();
      }
      break;
    }
    case 'app/SOCKET_RE_AUTH': {
      SocketProvider.updateAuthToken(action.payload);
      break;
    }
    default: {
      // Handoff to next Middleware
      return next(action);
    }
  }
};
