import { defineMessages } from 'react-intl';

const messages = defineMessages({
  position: {
    id: 'App.ApplicantsEmployerSection.Position',
    defaultMessage: 'Position'
  },
  annualSalary: {
    id: 'App.ApplicantsEmployerSection.AnnualSalary',
    defaultMessage: 'Annual Income'
  },
  annualSalaryPlaceholder: {
    id: 'App.ApplicantsEmployerSection.AnnualSalaryPlaceholder',
    defaultMessage: 'Enter Amount'
  },
  from: {
    id: 'App.ApplicantsEmployerSection.From',
    defaultMessage: 'Start'
  },
  to: {
    id: 'App.ApplicantsEmployerSection.To',
    defaultMessage: 'To'
  },
  employerName: {
    id: 'App.ApplicantsEmployerSection.EmployerName',
    defaultMessage: 'Employer Name'
  },
  phoneNumber: {
    id: 'App.ApplicantsEmployerSection.PhoneNumber',
    defaultMessage: 'Phone Number'
  },
  phoneNumberPlaceHolder: {
    id: 'App.ApplicantsEmployerSection.PhoneNumberPlaceHolder',
    defaultMessage: 'Employer Phone Number'
  },
  supervisorName: {
    id: 'App.ApplicantsEmployerSection.SupervisorName',
    defaultMessage: 'Supervisor Name'
  }
});

export default messages;
