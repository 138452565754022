import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Checkbox,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  SearchInput,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  BuildingsIcon,
  InactiveIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { MenuButton } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import messages from './messages';

const PropertySearchMenu = ({
  selectedProperty,
  onSelectProperty,
  onSelectPortfolioSummary,
  userProperties,
}) => {
  const [includeInactive, setIncludeInactive] = useState(false);
  const [searchText, setSearchText] = useState('');

  const options = useMemo(() => {
    let filteredProperties = [];
    if (includeInactive) {
      filteredProperties = userProperties;
    } else {
      filteredProperties = userProperties.filter(
        (property) => property?.propertyStatus?.description !== 'Inactive',
      );
    }

    return filteredProperties.map((property) => {
      const isInactive = property?.propertyStatus?.description === 'Inactive';
      return {
        value: property.name,
        text: property?.name,
        icon: <BuildingsIcon color={isInactive ? 'warning' : undefined} />,
        onClick: onSelectProperty(property),
        secondaryAction: isInactive ? <InactiveIcon color={'warning'} /> : null,
      };
    });
  }, [includeInactive, onSelectProperty, userProperties]);

  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.text.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [options, searchText]);

  return (
    <MenuButton
      id={'property-search-menu'}
      ButtonProps={{ sx: { color: 'white' } }}
      MenuProps={{
        closeOnClick: true,
        disablePortal: true,
        keepMounted: false,
        sx: {
          '& .MuiPaper-root': {
            width: { xs: 'calc(100vw - 32px)', sm: 400 },
          },
        },
      }}
      variant={'text'}
      value={selectedProperty?.name}
      placeholder={selectedProperty?.name}
      options={filteredOptions}
      menuHeader={
        <Box sx={{ width: '100%', minWidth: { sm: '100%' }, paddingX: 1 }}>
          <ListItemButton
            id={'portfolio-summary'}
            onClick={() => {
              onSelectPortfolioSummary();
              // click outside menu to close
              document.getElementById('property-search-menu-button').click();
            }}
          >
            <ListItemIcon>
              <BuildingsIcon />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage {...messages.portfolioSummary} />}
            />
          </ListItemButton>
          <SearchInput
            name={'property-search'}
            isSubmitted={searchText.length > 0}
            sx={{ marginTop: 1, marginBottom: 1 }}
            value={searchText}
            onClearClick={() => setSearchText('')}
            inputProps={{
              onKeyDown: (e) => {
                e.stopPropagation(); // prevents <ul> focus when pressing "p" ???
              },
            }}
            onChange={(e) => setSearchText(e.target.value)}
            HelperTextProps={{ sx: { display: 'none' } }}
          />
          <ListItemButton onClick={() => setIncludeInactive(!includeInactive)}>
            <ListItemIcon>
              <Checkbox checked={includeInactive} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage {...messages.includeInactive} />}
            />
            <ListItemSecondaryAction>
              <InactiveIcon color={includeInactive ? 'warning' : undefined} />
            </ListItemSecondaryAction>
          </ListItemButton>
          <Divider />
        </Box>
      }
    >
      {!selectedProperty?.name ? (
        <FormattedMessage {...messages.portfolioSummary} />
      ) : null}
    </MenuButton>
  );
};

export default PropertySearchMenu;
