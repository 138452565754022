import { put, select, takeLatest } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';

import { GET_ALL_WORK_ORDERS, UPDATE_WORK_ORDER_DROPDOWN } from './constants';
import {
  getAllWorkOrders,
  getAllWorkOrdersError,
  getAllWorkOrdersSuccess,
  updateWorkOrderError,
  updateWorkOrderSuccess,
} from './actions';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import WorkOrderService from '../../services/workOrderService';
import {
  selectCurrentFilter,
  selectCurrentSorting,
  selectSearchText,
} from './selectors';
import {
  renderNoDataToastr,
  renderTranslatedMessage,
} from '../../utils/redux-form-helper';
import messages from './messages';
import type {
  Action,
  FilterValue,
  FilterValueAndPagination,
  OrderValue,
  Property,
} from '../App/types';
import type { WorkOrderAndPagination } from './types';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetAllWorkOrdersSaga({
  payload,
}: Action<FilterValueAndPagination>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const workOrderService = new WorkOrderService();
    const response = yield workOrderService.getAllWorkOrders(
      payload.paginationInfo.pageNumber,
      payload.paginationInfo.limit,
      payload.filterValue,
      payload.sorting,
      payload.searchText,
      organizationId,
      selectedProperty.id,
      payload.defaultFilterActive,
    );
    if (response.results.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getAllWorkOrdersSuccess(response.results, response.meta));
  } catch (err) {
    yield put(getAllWorkOrdersError(err));
  }
}

export function* getAllWorkOrdersSaga(): Saga<void> {
  yield takeLatest(GET_ALL_WORK_ORDERS, fetchGetAllWorkOrdersSaga);
}

export function* putUpdateWorkOrder({
  payload,
}: Action<WorkOrderAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const filterValue: FilterValue = yield select(selectCurrentFilter);
    const currentSorting: OrderValue = yield select(selectCurrentSorting);
    const searchText: string = yield select(selectSearchText);
    const workOrdersService = new WorkOrderService();
    yield workOrdersService.update(payload.workOrder, organizationId);
    yield put(updateWorkOrderSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(
      getAllWorkOrders(
        payload.paginationInfo.pageNumber,
        payload.paginationInfo.limit,
        filterValue,
        currentSorting,
        searchText,
      ),
    );
  } catch (err) {
    yield put(updateWorkOrderError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* updateWorkOrder(): Saga<void> {
  yield takeLatest(UPDATE_WORK_ORDER_DROPDOWN, putUpdateWorkOrder);
}

export default [updateWorkOrder, getAllWorkOrdersSaga];
