import React from 'react';
import SearchField from '../../containers/Fields/Search';
import { SubmitButton } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { DownloadIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

interface Props {
  filterButton?: {
    disabled?: boolean,
    hasActiveFilters: boolean,
    onClick: Function,
  };
  searchField: {
    disabled?: boolean,
    onSubmit: Function,
    form: string,
    placeholder: string,
  };
  csvDownloadButton?: {
    disabled?: boolean,
    onClick: Function,
  };
  pdfDownloadButton?: {
    disabled?: boolean,
    onClick: Function,
  };
}
const SearchActions = ({
  filterButton,
  searchField,
  csvDownloadButton,
  pdfDownloadButton,
}: Props) => {
  return (
    <div className="search-actions-small">
      {filterButton?.onClick && (
        <button
          data-testid={'filterButton'}
          className={`button-filter ${
            filterButton.hasActiveFilters ? 'filters-are-active' : ''
          }`}
          disabled={filterButton.disabled}
          onClick={filterButton.onClick}
          style={{ minHeight: 40 }}
        >
          <i className="et-filter" />
        </button>
      )}

      <SearchField
        className="search-input-active"
        form={searchField.form}
        disabled={searchField.disabled}
        placeholder={searchField.placeholder}
        onSubmit={searchField.onSubmit}
      />

      {csvDownloadButton?.onClick && (
        <SubmitButton
          data-testid={'csvDownloadButton'}
          style={{
            marginTop: 0,
            marginRight: pdfDownloadButton?.onClick ? 4 : 0,
            float: 'unset',
          }}
          startIcon={<DownloadIcon />}
          onClick={csvDownloadButton.onClick}
          isSubmitting={csvDownloadButton.disabled}
        >
          CSV
        </SubmitButton>
      )}

      {pdfDownloadButton?.onClick && (
        <SubmitButton
          data-testid={'pdfDownloadButton'}
          isSubmitting={pdfDownloadButton.disabled}
          onClick={pdfDownloadButton.onClick}
          style={{ marginTop: 0, float: 'unset' }}
          startIcon={<DownloadIcon />}
        >
          PDF
        </SubmitButton>
      )}
    </div>
  );
};

export default SearchActions;
