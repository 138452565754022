import { defineMessages } from 'react-intl';

const messages = defineMessages({
  addRentSchedule: {
    id: 'App.Commercial.Rent.Modal.AddRentSchedule',
    defaultMessage: 'Add Rent Schedule',
  },
  cancel: {
    id: 'App.Commercial.Rent.Modal.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.Commercial.Rent.Modal.Save',
    defaultMessage: 'Save',
  },
  monthlyAmountLabel: {
    id: 'App.Commercial.Rent.Modal.MonthlyAmountLabel',
    defaultMessage: 'Monthly Amount *',
  },
  notesLabel: {
    id: 'App.Commercial.Rent.Modal.NotesLabel',
    defaultMessage: 'Notes',
  },
  notesPlaceholder: {
    id: 'App.Commercial.Rent.Modal.NotesPlaceholder',
    defaultMessage: 'Enter Note',
  },
  startDateLabel: {
    id: 'App.Commercial.Rent.Modal.StartDateLabel',
    defaultMessage: 'Start Date *',
  },
  holdoverLabel: {
    id: 'App.Commercial.Rent.Modal.HoldoverLabel',
    defaultMessage: 'Holdover: *',
  },
  noIncrease: {
    id: 'App.Commercial.Rent.Modal.NoIncrease',
    defaultMessage: 'No Increase',
  },
  addRent: {
    id: 'App.CommercialLeaseDataTab.Rent.Modal.AddRent',
    defaultMessage: 'Add Rent',
  },
});

export default messages;
