import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import messages from './messages';
import type { Option } from './types';
import { ReadOnlyField, TwoColumnField } from './Common';
import {
  renderSelectFieldTwoColumns,
  renderRadioGroupField,
  renderDateField,
  extractCurrentDateFormat,
  validDate,
} from '../../../../utils/redux-form-helper';
import { getUnitAddress } from '../../../../utils/unit-helpers';
import * as constants from './constants';
import { calcLeaseTerm } from './utils';

type FormRightColumnProps = {
  intl: Object,
  retailTypeOptions: Option[],
  unit: Object,
  showRetailTypeField: boolean,
  isEditable: boolean,
  onLeaseEdit: Function,
  commencementDate: Date,
  leaseEndDate: Date,
  isRenewal: true,
};

const yesNoOptions = [
  { value: 'true', text: 'Yes' },
  { value: 'false', text: 'No' },
];

function FormRightColumn({
  intl,
  retailTypeOptions,
  unit,
  showRetailTypeField,
  isEditable,
  onLeaseEdit,
  commencementDate,
  leaseEndDate,
  isRenewal,
  noticeToVacate,
}: FormRightColumnProps) {
  const { changeLeaseTermCalculationCommercial } = useFlags();
  const currentDateFormat = extractCurrentDateFormat(intl);
  const leaseTerm = calcLeaseTerm(
    leaseEndDate,
    commencementDate,
    changeLeaseTermCalculationCommercial,
  );
  return (
    <Col xs={12} lg={6}>
      {showRetailTypeField && (
        <Row>
          <Col xs={12}>
            <Field
              name="retailTypeId"
              options={retailTypeOptions || []}
              component={renderSelectFieldTwoColumns}
              col1={[4, 12]}
              col2={[8, 12]}
              label={intl.formatMessage(messages.retailTypeField)}
              disabled={!isEditable}
            />
          </Col>
        </Row>
      )}
      <Row>
        <ReadOnlyField
          label={intl.formatMessage(messages.unitNumber)}
          value={unit.number}
        />
      </Row>
      <Row>
        <ReadOnlyField
          label={intl.formatMessage(messages.unitAddress)}
          value={getUnitAddress(unit)}
        />
      </Row>
      <Row>
        <TwoColumnField
          name="scheduledMoveInDate"
          component={renderDateField}
          label={intl.formatMessage(messages.scheduledMoveInField)}
          bsSize="md"
          className="input-group"
          placeholder={currentDateFormat}
          dateFormat={extractCurrentDateFormat(intl)}
          disabled={!isEditable || isRenewal}
          validate={[validDate]}
        />
      </Row>
      <Row>
        <ReadOnlyField
          label={intl.formatMessage(messages.leaseTerm)}
          value={leaseTerm}
        />
      </Row>
      <Row>
        <TwoColumnField
          name="leaseEffectiveDate"
          component={renderDateField}
          label={intl.formatMessage(messages.leaseEffectiveDateField)}
          bsSize="md"
          className="input-group"
          placeholder={currentDateFormat}
          dateFormat={currentDateFormat}
          disabled={!isEditable}
          validate={[validDate]}
          showPencil={!isEditable}
          onPencilClick={onLeaseEdit}
        />
      </Row>
      <Row>
        <TwoColumnField
          name="commencementDate"
          component={renderDateField}
          label={intl.formatMessage(messages.commencementDateField)}
          bsSize="md"
          className="input-group"
          placeholder={currentDateFormat}
          dateFormat={currentDateFormat}
          disabled={!isEditable}
          validate={[validDate]}
          showPencil={!isEditable}
          onPencilClick={onLeaseEdit}
        />
      </Row>
      <Row>
        <TwoColumnField
          name="leaseEndDate"
          component={renderDateField}
          label={intl.formatMessage(messages.leaseEndDateField)}
          bsSize="md"
          className="input-group"
          placeholder={currentDateFormat}
          dateFormat={currentDateFormat}
          disabled={!isEditable}
          validate={[validDate]}
          showPencil={!isEditable}
          onPencilClick={onLeaseEdit}
        />
      </Row>
      <Row>
        <TwoColumnField
          name="rentStartDate"
          component={renderDateField}
          label={intl.formatMessage(messages.rentStartDateField)}
          bsSize="md"
          className="input-group"
          placeholder={currentDateFormat}
          dateFormat={currentDateFormat}
          disabled={!isEditable}
          validate={[validDate]}
          showPencil={!isEditable}
          onPencilClick={onLeaseEdit}
          noticeToVacate={noticeToVacate}
        />
      </Row>
      <Row>
        <TwoColumnField
          name="isRentDateEstimated"
          label={intl.formatMessage(messages.estimatedRentDateField)}
          component={renderRadioGroupField}
          options={yesNoOptions}
          labelClass="padright20"
          className="form-options"
          inline
          disabled={!isEditable}
          showPencil={!isEditable}
          onPencilClick={onLeaseEdit}
        />
      </Row>
    </Col>
  );
}

const selector = formValueSelector(constants.FORM_NAME);

const mapStateToProps = (state): any => ({
  commencementDate: selector(state, 'commencementDate'),
  leaseEndDate: selector(state, 'leaseEndDate'),
});

export default connect(mapStateToProps)(FormRightColumn);
