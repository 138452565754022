import {
  Stack,
  Spinner,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import DateTimeSection from './DateTimeSection';
import NotesSection from './NotesSection';
import { WaitlistSectionProps } from './types';

const getNotesSectionProps = (
  props: Partial<WaitlistSectionProps>,
): Partial<WaitlistSectionProps> => {
  const { localWaitlistInfo, waitlistActions } = props;
  return { localWaitlistInfo, waitlistActions };
};

const getDateTimeSectionProps = (
  props: Partial<WaitlistSectionProps>,
): Partial<WaitlistSectionProps> => {
  const { localWaitlistInfo, waitlistActions } = props;
  return { localWaitlistInfo, waitlistActions };
};

const WaitlistInfoDisplayer = (props: Partial<WaitlistSectionProps>) => {
  const { isLoading, ...restProps } = props;

  const dateTimeSectionProps = getDateTimeSectionProps(restProps);
  const notesSectionProps = getNotesSectionProps(restProps);

  return isLoading ? (
    <Spinner />
  ) : (
    <Stack spacing={2}>
      <DateTimeSection {...dateTimeSectionProps} />
      <NotesSection {...notesSectionProps} />
    </Stack>
  );
};

export default WaitlistInfoDisplayer;
