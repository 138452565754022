import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDateInfo } from '../hooks';

const LeaseInfoCards = ({ assignedUnits }) => {
  const items = useDateInfo(assignedUnits);

  return (
    <div className="row padtop10">
      {items.map((item, i) => (
        <Card key={`leaseInfoCard_${i}`} {...item} />
      ))}
    </div>
  );
};

export default LeaseInfoCards;

const Card = ({ title, subtitle, count }) => {
  return (
    <div data-testid={'leaseInfoCard'} className="col-md-2 col-lg-2">
      <div className="block-data block-white-shadow" style={{ minHeight: 133 }}>
        <h5 className="padleft30 padright30">
          <FormattedMessage {...title} />
          {subtitle && <FormattedMessage {...subtitle} />}
        </h5>
        <p>
          <strong>{count}</strong>
        </p>
      </div>
    </div>
  );
};
