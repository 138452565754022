import { FormattedMessage } from 'react-intl';
export const processTableFilterOptions = (
  options: string[],
  formattedMessages?: Object,
) => {
  // Remove duplicates, sort, and map to label/value objects
  Object.keys(options).forEach((key) => {
    // count the number of times each value appears
    const counts = {};
    options[key].forEach((value) => {
      counts[value] = (counts[value] || 0) + 1;
    });
    options[key] = Array.from(new Set(options[key]))
      .sort()
      .map((value) => {
        const hasFormattedMessages =
          formattedMessages && formattedMessages[value];
        return {
          text: hasFormattedMessages ? (
            <FormattedMessage
              {...formattedMessages[value]}
              values={{ count: counts[value] }}
            />
          ) : (
            `${value} (${counts[value]})`
          ),
          value: value?.toLowerCase(),
        };
      });
  });
};
