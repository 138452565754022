import moment from 'moment';
import { isNil, isEmpty, pathOr } from 'ramda';

export const formatSqFtStr = (num: number): string | number => {
  return +num > 0 ? `${num} SqFt` : num;
};

export const getLatestStartDate = (histories: any = []): string | null => {
  const latestHistory: Object =
    !isNil(histories) && !isEmpty(histories)
      ? histories
          .sort(
            (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
          )
          .slice(-1)[0]
      : {};
  return pathOr(null, ['startDate'], latestHistory);
};
