import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.CreateTransaction.Title',
    defaultMessage: 'Create Transaction',
  },
  cancel: {
    id: 'App.CreateTransaction.Cancel',
    defaultMessage: 'Cancel',
  },
  post: {
    id: 'App.CreateTransaction.Post',
    defaultMessage: 'Post',
  },
  propertyName: {
    id: 'App.CreateTransaction.PropertyName',
    defaultMessage: 'Property Name',
  },
  unitId: {
    id: 'App.CreateTransaction.UnitId',
    defaultMessage: 'Unit Number',
  },
  status: {
    id: 'App.CreateTransaction.Status',
    defaultMessage: 'Status',
  },
  residentName: {
    id: 'App.CreateTransaction.ResidentName',
    defaultMessage: 'Name',
  },
  subjournal: {
    id: 'App.CreateTransaction.Subjournal',
    defaultMessage: 'Subjournal',
  },
  transactionType: {
    id: 'App.CreateTransaction.TransactionType',
    defaultMessage: 'Transaction Type',
  },
  applyToCamPool: {
    id: 'App.CreateTransaction.ApplyToCamPool',
    defaultMessage: 'Apply to CAM Pool?',
  },
  camPeriod: {
    id: 'App.CreateTransaction.CamPeriod',
    defaultMessage: 'CAM Start Date / Period',
  },
  camPool: {
    id: 'App.CreateTransaction.CamPool',
    defaultMessage: 'CAM Pool',
  },
  receivedFrom: {
    id: 'App.CreateTransaction.ReceivedFrom',
    defaultMessage: 'Received From',
  },
  code: {
    id: 'App.CreateTransaction.Code',
    defaultMessage: 'Code',
  },
  date: {
    id: 'App.CreateTransaction.Date',
    defaultMessage: 'Date',
  },
  documentNumber: {
    id: 'App.CreateTransaction.DocumentNumber',
    defaultMessage: 'Document Number',
  },
  note: {
    id: 'App.CreateTransaction.Note',
    defaultMessage: 'Note',
  },
  notePlaceholder: {
    id: 'App.CreateTransaction.NotePlaceholder',
    defaultMessage: '(Optional)',
  },
  priorBalance: {
    id: 'App.CreateTransaction.PriorBalance',
    defaultMessage: 'Prior Balance',
  },
  amount: {
    id: 'App.CreateTransaction.Amount',
    defaultMessage: 'Amount',
  },
  accountBalance: {
    id: 'App.CreateTransaction.AccountBalance',
    defaultMessage: 'New Balance',
  },
  createTransactionSuccessHeader: {
    id: 'App.CreateTransaction.createTransactionSuccessHeader',
    defaultMessage: 'Success',
  },
  createTransactionFailureHeader: {
    id: 'App.CreateTransaction.createTransactionFailureHeader',
    defaultMessage: 'Error',
  },
  createTransactionPendingHeader: {
    id: 'App.CreateTransaction.createTransactionPendingHeader',
    defaultMessage: 'Pending',
  },
  createTransactionSuccessDescription: {
    id: 'App.CreateTransaction.createTransactionSuccessDescription',
    defaultMessage: 'Transaction posted.',
  },
  createTransactionFailureDescription: {
    id: 'App.CreateTransaction.createTransactionFailureDescription',
    defaultMessage: 'Transaction could not be posted.',
  },
  createTransactionPendingDescription: {
    id: 'App.CreateTransaction.createTransactionPendingDescription',
    defaultMessage: 'Transaction pending.',
  },
  yes: {
    id: 'App.CreateTransaction.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.CreateTransaction.no',
    defaultMessage: 'No',
  },
  cancelConfirmationHeader: {
    id: 'App.CreateTransaction.cancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  required: {
    id: 'App.CreateTransaction.Required',
    defaultMessage: 'This field is required',
  },
  validNumber: {
    id: 'App.CreateTransaction.Number',
    defaultMessage: 'Please enter a valid number greater than 0',
  },
  validDate: {
    id: 'App.CreateTransaction.validDate',
    defaultMessage: 'Please enter a valid date',
  },
  validDateFuture: {
    id: 'App.CreateTransaction.validDateFuture',
    defaultMessage: "Can't select a date in the future",
  },
  validDatePast: {
    id: 'App.CreateTransaction.validDatePast',
    defaultMessage: 'Must be in the current financial period',
  },
  subjournalRequired: {
    id: 'App.CreateTransaction.Required.Subjournal',
    defaultMessage: 'Please select a subjournal',
  },
});

export default messages;
