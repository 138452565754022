import { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import LimitsVsActualsGraph from '../../ManageLeaseExpirationLimits/LimitsVsActualsCard/LimitsVsActualsGraph';
import ActualLeasesGraph from '../../ManageLeaseExpirationLimits/LimitsVsActualsCard/ActualLeasesGraph';
import {
  generateActualLeasesGraphProps,
  generateLimitsVsActualsGraphProps,
} from '../../ManageLeaseExpirationLimits/LimitsVsActualsCard/utils';
import { PageContext } from '../../ManageLeaseExpirationLimits/context';
import type { GraphData } from '../../../services/leaseExpirationLimitService';

type Props = {
  intl: { formatMessage: Function },
  graphData: GraphData,
  isDataReady: boolean,
  selectedYears: number[],
  onColumnClick: Function,
  isLeaseExpirationLimitsActive: boolean,
};

function Graph({
  intl: { formatMessage },
  graphData,
  isDataReady,
  selectedYears,
  onColumnClick,
  isLeaseExpirationLimitsActive,
}: Props) {
  const graphProps = useMemo(() => {
    if (!isDataReady || !graphData) return {};
    return isLeaseExpirationLimitsActive
      ? generateLimitsVsActualsGraphProps(graphData, selectedYears)
      : generateActualLeasesGraphProps(graphData, selectedYears);
  }, [graphData, selectedYears, isDataReady, isLeaseExpirationLimitsActive]);

  return (
    <PageContext.Provider
      value={{
        formatMessage,
      }}
    >
      {isLeaseExpirationLimitsActive ? (
        <LimitsVsActualsGraph
          {...graphProps}
          onColumnClick={onColumnClick}
          hideLimitsOnYAxis
        />
      ) : (
        <ActualLeasesGraph {...graphProps} onColumnClick={onColumnClick} />
      )}
    </PageContext.Provider>
  );
}

export default injectIntl(Graph);
