import moment from 'moment';
import React, { useState } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import styled from 'styled-components';
import Spacer from '../../../../../components/Spacer';
import { Intl } from '../../../../../types';
import {
  renderCurrencyField,
  renderDateField,
  renderRadioGroupField,
  renderTextField,
  required,
  requiredString,
  validDate,
  yesNoBooleanOptions,
} from '../../../../../utils/redux-form-helper';
import { FileInput } from './FileInput';
import messages from './messages';
import { validate } from './validate';
import { ViewFile } from './ViewFile';

type Props = {
  intl: Intl,
  isViewModal: boolean,
  organizationId: string,
  propertyId: string,
  selectedInsurance: Object,
};

const FieldContainer = styled.div`
  width: ${(props) => props.width ?? 250}px;
  display: inline-block;
  vertical-align: top;
`;

const MAX_INSURANCE_LIABILITY = 9999999.99;

const onFocus = (setState) => () => setState(true);
const onBlur = (setState) => () => setState(false);

const parseLiabilityCoverage = (value) => {
  const [integerPart, fractionalPart] = (value ?? '').split('.');
  const newValue = parseFloat(
    `${integerPart}.${fractionalPart?.[0]}${fractionalPart?.[1]}`,
  );

  if (isNaN(newValue)) return 0;
  if (newValue <= MAX_INSURANCE_LIABILITY) return newValue;
  return MAX_INSURANCE_LIABILITY;
};

const viewComponent =
  (format: Function = (v) => v, capitalize: boolean = false) =>
  (props) => {
    return (
      <>
        {props.label && <label>{props.label}</label>}
        <span
          style={{
            display: 'block',
            textTransform: capitalize ? 'capitalize' : undefined,
          }}
        >
          {format(props.input.value)}
        </span>
      </>
    );
  };

const getLabel = (label: string, required: boolean, isViewModal: boolean) =>
  `${label}${required && !isViewModal ? '*' : ''}`;

let InsuranceForm = ({
  intl,
  handleSubmit,
  isViewModal,
  organizationId,
  propertyId,
  selectedInsurance,
}: Props & InjectedFormProps) => {
  const [isLiabilityCoverageFocused, setIsLiabilityCoverageFocused] =
    useState(false);

  const formatLiabilityCoverage = (value) => {
    if (value === 0) return '0';
    if (value) return intl.formatNumber(value);
    return '';
  };

  return (
    <form onSubmit={handleSubmit}>
      <FieldContainer>
        <Field
          name="providerName"
          component={isViewModal ? viewComponent() : renderTextField}
          label={getLabel(
            intl.formatMessage(messages.providerName),
            true,
            isViewModal,
          )}
          placeholder="Company Name"
          showLabel
          bsSize="lg"
          validate={[requiredString]}
          maxLength={255}
        />
      </FieldContainer>
      <Spacer h={55} inline />
      <FieldContainer>
        <Field
          name="liabilityCoverage"
          component={
            isViewModal ? viewComponent((v) => `$${v}`) : renderCurrencyField
          }
          label={getLabel(
            intl.formatMessage(messages.liabilityCoverage),
            true,
            isViewModal,
          )}
          placeholder="0.00"
          step={0.01}
          min={0}
          max={MAX_INSURANCE_LIABILITY}
          bsSize="lg"
          validate={[required]}
          onFocus={onFocus(setIsLiabilityCoverageFocused)}
          onBlur={onBlur(setIsLiabilityCoverageFocused)}
          format={
            isLiabilityCoverageFocused ? undefined : formatLiabilityCoverage
          }
          type={isLiabilityCoverageFocused ? 'number' : 'text'}
          style={{ paddingLeft: 6 }}
          parse={parseLiabilityCoverage}
        />
      </FieldContainer>
      <Spacer h={55} inline />
      <FieldContainer>
        <Field
          name="policyId"
          component={isViewModal ? viewComponent() : renderTextField}
          label={getLabel(
            intl.formatMessage(messages.policyID),
            true,
            isViewModal,
          )}
          placeholder="i.e 12345B"
          showLabel
          bsSize="lg"
          validate={[requiredString]}
          maxLength={255}
        />
      </FieldContainer>

      <Spacer v={isViewModal ? 40 : 20} />
      <FieldContainer>
        <Field
          name="policyStartDate"
          component={
            isViewModal
              ? viewComponent((v) => moment(v).format('MM/DD/YYYY'))
              : renderDateField
          }
          label={getLabel(
            intl.formatMessage(messages.policyStartDate),
            true,
            isViewModal,
          )}
          placeholder="MM/DD/YYYY"
          className="modal-fixed-position"
          bsSize="lg"
          validate={isViewModal ? [] : [required, validDate]}
        />
      </FieldContainer>
      <Spacer h={55} inline />
      <FieldContainer>
        <Field
          name="policyEndDate"
          component={
            isViewModal
              ? viewComponent((v) => moment(v).format('MM/DD/YYYY'))
              : renderDateField
          }
          label={getLabel(
            intl.formatMessage(messages.policyEndDate),
            true,
            isViewModal,
          )}
          placeholder="MM/DD/YYYY"
          className="modal-fixed-position"
          bsSize="lg"
          validate={isViewModal ? [] : [required, validDate]}
        />
      </FieldContainer>
      <Spacer h={55} inline />
      <FieldContainer>
        <label htmlFor="isPropertyListedAsInterestedParty">
          {getLabel(
            intl.formatMessage(messages.propertyListedAsInterestedParty),
            true,
            isViewModal,
          )}
        </label>
        <Field
          name="isPropertyListedAsInterestedParty"
          component={
            isViewModal ? viewComponent(undefined, true) : renderRadioGroupField
          }
          options={yesNoBooleanOptions}
          validate={[required]}
        />
      </FieldContainer>

      <Spacer v={isViewModal ? 40 : 20} />
      <FieldContainer width={402}>
        <Field
          name="proofOfCoverage"
          component={isViewModal ? ViewFile : FileInput}
          label={getLabel(
            intl.formatMessage(messages.proofOfCoverage),
            false,
            isViewModal,
          )}
          organizationId={organizationId}
          propertyId={propertyId}
          selectedInsurance={selectedInsurance}
        />
      </FieldContainer>
      <Spacer h={55} inline />
      <FieldContainer width={402}>
        <Field
          name="notes"
          component={renderTextField}
          label={getLabel(
            intl.formatMessage(messages.notes),
            false,
            isViewModal,
          )}
          placeholder="(Optional)"
          showLabel
          bsSize="lg"
        />
      </FieldContainer>
    </form>
  );
};

InsuranceForm = reduxForm({
  form: 'InsuranceForm',
  validate,
  enableReinitialize: true,
})(InsuranceForm);

export default InsuranceForm;
