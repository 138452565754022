import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { confirmable, createConfirmation } from 'react-confirm';
import styled from 'styled-components';
import type Moment from 'moment';
import moment from 'moment';
import { partial } from 'ramda';
import messages from './messages';
import { bindActionCreators } from 'redux';
import type { Activity } from '../../containers/ProspectProfile/types';
import type {
  ActivityCompletionStatus,
  DropdownOption,
  Property,
} from '../../containers/App/types';
import ElementWithPermissions from '../../components/ElementWithPermissions';
import ScheduleForm from './ScheduleForm';
import RecordForm from './RecordForm';
import { isInvalid, submit } from 'redux-form';
import { FORM_NAME, FORM_TYPE, STAGE } from './constants';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type State = {
  assigneeActivities: Array<Activity>,
  activityModalMode: boolean,
  applicantName: string,
};

type ActivityType = {
  completionStatusOptions: Array<ActivityCompletionStatus>,
  canBeOverlapped: boolean,
};

type ProspectData = {
  id: string,
  firstName: string,
  lastName: string,
  createdAt: string,
};

type Props = {
  valid: boolean,
  warning: string,
  error: Object,
  change: Function,
  asyncValidate: Function,
  show: boolean,
  proceed: Function,
  dismiss: Function,
  intl: Object,
  store: Object,
  assignedToId: string,
  prospect: ProspectData,
  activityTypesList: Array<DropdownOption & ActivityType>,
  assigneeList: Array<DropdownOption>,
  stage: string,
  urlId: string,
  onHandleDismiss?: Function,
  showViewProfileBtn: boolean,
};

type StateProps = {
  actions: Function,
  locale: string,
  startDate: Moment,
  startTime: Moment,
  endTime: Moment,
  notes: string,
  activityTypeId: string,
  activityCompletionStatusId: string,
  assignedToId: string,
  allDay: boolean,
  selectedProperty: Property,
  organizationId: string,
  invalidSchedule: boolean,
  invalidRecord: boolean,
};

const RadioContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const SubHeading = styled.h2`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-right: 10px;
  display: inline;
`;

const Subtitles = styled.h2`
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.0059em;
  text-align: left;
  margin: 0;
  padding: 0;
  display: inline;
`;

const Label = styled.p`
  font-size: 14px;
  margin: 0 !important;
  padding: 0 !important;
`;

const Bold = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin: 0 !important;
  padding: 0 !important;
`;

export class ActivityModal extends Component<StateProps & Props, State> {
  constructor(props: StateProps & Props) {
    super(props);
    this.handleSubmit.bind(this);
    this.state = {
      assigneeActivities: [],
      activityModalMode: '',
      applicantName: `${this.props.prospect.firstName || '--'} ${
        this.props.prospect.lastName || '--'
      }`,
    };
  }

  scheduleFormInitialValues = (assigneeList, activityTypesList) => {
    const currentDate = moment();
    const startDate = moment(currentDate).add(
      60 - currentDate.minutes(),
      'minutes',
    );
    const endDate = moment(startDate).add(30, 'minutes');
    return {
      assignedToId:
        (assigneeList || []).length > 0 ? assigneeList[0].value : undefined,
      activityTypeId:
        (activityTypesList || []).length > 0
          ? activityTypesList[0].value
          : undefined,
      startDate: startDate,
      startTime: startDate,
      endTime: endDate,
      allDay: false,
    };
  };

  recordFormInitialValues = (assigneeList, activityTypesList, prospect) => {
    return {
      assignedToId:
        (assigneeList || []).length > 0 ? assigneeList[0].value : undefined,
      activityTypeId:
        (activityTypesList || []).length > 0
          ? activityTypesList[0].value
          : undefined,
      activityCompletionStatusId: 'default',
      prospectCreationDate: moment(prospect.createdAt),
      startDate: moment(),
      startTime: moment(),
      allDay: false,
    };
  };

  submitForm = (evt) => {
    evt.preventDefault();
    const { submit } = this.props.actions;
    if (this.state.activityModalMode === FORM_TYPE.RECORD) {
      submit(FORM_NAME.RECORD);
    } else if (this.state.activityModalMode === FORM_TYPE.SCHEDULE) {
      submit(FORM_NAME.SCHEDULE);
    }
  };

  handleSubmit(values) {
    const {
      startDate,
      startTime,
      endTime,
      notes,
      activityTypeId,
      activityCompletionStatusId,
      assignedToId,
      allDay,
    } = values;

    startDate.set({
      hour: allDay ? 0 : startTime.hours(),
      minute: allDay ? 0 : startTime.minutes(),
      second: 0,
      millisecond: 0,
    });

    const endDate = moment(startDate);

    if (this.state.activityModalMode === FORM_TYPE.SCHEDULE) {
      endDate.set({
        hour: allDay ? 23 : endTime.hours(),
        minute: allDay ? 59 : endTime.minutes(),
        second: 0,
        millisecond: 0,
      });
    }

    const activity = {
      startTime: startDate.toDate(),
      endTime: endDate.toDate(),
      activityCompletionStatusId:
        this.state.activityModalMode === FORM_TYPE.RECORD
          ? activityCompletionStatusId
          : undefined,
      notes: notes,
      activityTypeId: activityTypeId,
      prospectId: this.props.prospect.id || '',
      assignedToId: assignedToId,
      allDay: allDay,
      propertyId: this.props.selectedProperty.id,
    };

    this.props.proceed(activity);
  }

  render() {
    const handleClose = this.props.onHandleDismiss
      ? partial(this.props.onHandleDismiss, [this.props.dismiss])
      : this.props.dismiss;
    const stage =
      this.props.stage === STAGE.APPLICANT
        ? STAGE.APPLICATION
        : this.props.stage;
    const url = getUrlWithSelectedPropertyId(
      `/${stage}/${this.props.urlId || ''}`,
    );
    return (
      <form>
        <Modal
          backdrop
          bsSize="lg"
          show={this.props.show}
          onHide={this.props.dismiss}
        >
          <Modal.Header closeButton>
            <i
              className={
                this.state.activityModalMode === FORM_TYPE.SCHEDULE
                  ? 'et-calendar'
                  : 'et-pencil'
              }
            />
            <Modal.Title componentClass="h1">
              {this.state.activityModalMode === '' &&
                this.props.intl.formatMessage(messages.createTitle)}
              {this.state.activityModalMode === FORM_TYPE.RECORD &&
                this.props.intl.formatMessage(messages.recordTitle)}
              {this.state.activityModalMode === FORM_TYPE.SCHEDULE &&
                this.props.intl.formatMessage(messages.scheduleTitle)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col xs={12} md={6}>
                <SubHeading>
                  {this.props.intl.formatMessage(messages.activityDetails)}
                </SubHeading>
                <Subtitles>
                  {this.props.intl.formatMessage(messages.allFieldsRequired)}
                </Subtitles>
              </Col>
              <Col xs={12} md={6} className="text-right">
                <SubHeading>
                  {this.props.intl.formatMessage(messages[this.props.stage])}
                </SubHeading>

                <span style={{ marginRight: '10px' }}>
                  {this.state.applicantName}
                </span>
                {this.props.showViewProfileBtn && (
                  <a
                    className="btn btn-tertiary"
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: 'inline' }}
                  >
                    <span>
                      {this.props.intl.formatMessage(
                        messages.viewProfileButton,
                      )}
                    </span>
                  </a>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={2} lg={2}>
                <RadioContainer
                  id="record-container"
                  onClick={() => {
                    this.setState({ activityModalMode: FORM_TYPE.RECORD });
                  }}
                >
                  <input
                    type="radio"
                    value={this.state.activityModalMode}
                    checked={this.state.activityModalMode === FORM_TYPE.RECORD}
                    name="create-activity"
                    style={{ marginRight: '12px' }}
                    readOnly
                  />
                  {this.state.activityModalMode === FORM_TYPE.RECORD ? (
                    <Bold>Record</Bold>
                  ) : (
                    <Label>Record</Label>
                  )}
                </RadioContainer>
              </Col>
              <Col>
                <RadioContainer
                  id="schedule-container"
                  onClick={() => {
                    this.setState({ activityModalMode: FORM_TYPE.SCHEDULE });
                  }}
                >
                  <input
                    type="radio"
                    value={this.state.activityModalMode}
                    checked={
                      this.state.activityModalMode === FORM_TYPE.SCHEDULE
                    }
                    name="create-activity"
                    style={{ marginRight: '12px' }}
                    readOnly
                  />
                  {this.state.activityModalMode === FORM_TYPE.SCHEDULE ? (
                    <Bold>Schedule</Bold>
                  ) : (
                    <Label>Schedule</Label>
                  )}
                </RadioContainer>
              </Col>
            </Row>
            <Row>
              {this.state.activityModalMode === FORM_TYPE.SCHEDULE && (
                <ScheduleForm
                  intl={this.props.intl}
                  store={this.props.store}
                  activityTypesList={this.props.activityTypesList}
                  allDay={this.props.allDay}
                  assigneeList={this.props.assigneeList}
                  prospect={this.props.prospect}
                  startDate={this.props.startDate}
                  mode={FORM_TYPE.SCHEDULE}
                  onSubmit={this.handleSubmit.bind(this)}
                  initialValues={this.scheduleFormInitialValues(
                    this.props.assigneeList,
                    this.props.activityTypesList,
                  )}
                />
              )}
              {this.state.activityModalMode === FORM_TYPE.RECORD && (
                <RecordForm
                  intl={this.props.intl}
                  locale={this.props.locale}
                  store={this.props.store}
                  activityTypesList={this.props.activityTypesList}
                  assigneeList={this.props.assigneeList}
                  activityTypeId={this.props.activityTypeId}
                  prospect={this.props.prospect}
                  change={this.props.change}
                  mode={FORM_TYPE.RECORD}
                  onSubmit={this.handleSubmit.bind(this)}
                  initialValues={this.recordFormInitialValues(
                    this.props.assigneeList,
                    this.props.activityTypesList,
                    this.props.prospect,
                  )}
                />
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col xs={6}>
                <Button
                  bsStyle="default"
                  className="pull-right"
                  onClick={handleClose}
                >
                  {this.props.intl.formatMessage(messages.cancel)}
                </Button>
              </Col>
              <Col xs={6}>
                <ElementWithPermissions
                  scope={['activity-create']}
                  store={this.props.store}
                >
                  <Button
                    bsStyle="primary"
                    className="pull-left"
                    disabled={
                      this.state.activityModalMode === '' ||
                      this.props.invalidSchedule ||
                      this.props.invalidRecord ||
                      this.props.notes === '' ||
                      this.props.activityTypeId === 'default'
                    }
                    onClick={this.submitForm.bind(this)}
                  >
                    {this.props.intl.formatMessage(messages.addActivity)}
                  </Button>
                </ElementWithPermissions>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </form>
    );
  }
}

const mapStateToProps = (state): StateProps => {
  if (state.app.selectedProperty) {
    return {
      locale: state.languageProvider.locale,
      invalidSchedule: isInvalid(FORM_NAME.SCHEDULE)(state),
      invalidRecord: isInvalid(FORM_NAME.RECORD)(state),
      selectedProperty: state.app.selectedProperty,
      organizationId: state?.app?.currentUser?.user?.organizationId ?? '',
      notes:
        state?.form?.scheduleForm?.values?.notes ??
        state?.form?.recordForm?.values?.notes ??
        '',
      activityTypeId: state?.form?.scheduleForm?.values?.activityTypeId ?? '',
    };
  }
  throw new Error('A property must be selected.');
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ submit }, dispatch),
});

let InjectedActivityModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivityModal);

InjectedActivityModal = confirmable(InjectedActivityModal);

const dialog = createConfirmation(InjectedActivityModal);

export const createActivity = (props): Promise<any> => {
  const {
    store,
    intl,
    prospect,
    activityTypesList,
    assigneeList,
    stage,
    urlId,
    showViewProfileBtn,
    onHandleDismiss,
  } = props;
  return new Promise((resolve, reject) => {
    dialog({
      store,
      intl,
      activityTypesList,
      assigneeList,
      stage,
      urlId,
      prospect,
      showViewProfileBtn,
      ...(onHandleDismiss && { onHandleDismiss }),
    }).then(
      (data: any) => resolve(data),
      (error: Object) => reject(error),
    );
  });
};
