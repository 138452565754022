import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import {
  renderSelectField,
  renderCurrencyField,
} from '../../../../../utils/redux-form-helper';
import messages from './messages';
export const OneTimeLeaseChargesOrCreditsRightColumn = ({
  intl,
  securityDeposits,
  isEditable,
}: Object) => (
  <Col xs={12} sm={6}>
    <Row>
      <Col xs={7}>
        <label>
          {intl.formatMessage(messages.securityDepositAlternativeLabel)}
        </label>
      </Col>
      <Col xs={5}>
        <Field
          name="securityDepositAlternativeId"
          component={renderSelectField}
          options={securityDeposits}
          disabled={!isEditable}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={7}>
        <label>{intl.formatMessage(messages.nonRefundableAdminFeeLabel)}</label>
      </Col>
      <Col xs={5}>
        <div className="form-group">
          <Field
            name="nonRefundableAdminFee"
            component={renderCurrencyField}
            step="0.01"
            disabled={!isEditable}
          />
        </div>
      </Col>
    </Row>
    <Row>
      <Col xs={7}>
        <label>{intl.formatMessage(messages.satelliteDepositLabel)}</label>
      </Col>
      <Col xs={5}>
        <div className="form-group">
          <Field
            name="satelliteDeposit"
            component={renderCurrencyField}
            step="0.01"
            disabled={!isEditable}
          />
        </div>
      </Col>
    </Row>
  </Col>
);
