import React from 'react';
import styled from 'styled-components';

const OuterPadding = styled.div`
  padding: 15px;
`;
const Border = styled.div`
  border: 1px solid #d1d1d1;
`;
const ContentPadding = styled.div`
  padding: 25px;
`;

const HorizontalRuler = styled.hr`
  height: 1px;
  border-width: 0;
  background-color: #d1d1d1;
  width: 95%;
  margin-top: 0;
  margin-bottom: 0;
`;

type Props = {
  appliedFilters: Object,
  topSection: Object,
  bottomSection: Object,
  ledgerTable: Object,
  isFilterActive: boolean,
};

export const LedgerFilterLayout = (props: Props) => {
  const {
    appliedFilters,
    topSection,
    bottomSection,
    ledgerTable,
    isFilterActive,
  } = props;
  return (
    <OuterPadding>
      <Border>
        <ContentPadding>{topSection}</ContentPadding>
        {appliedFilters}
        {isFilterActive && (
          <>
            <HorizontalRuler id="horizontal-ruler" />
            <ContentPadding id="content">{bottomSection}</ContentPadding>
          </>
        )}
      </Border>
      {ledgerTable}
    </OuterPadding>
  );
};
