import React from 'react';
import { Button } from 'react-bootstrap';
import Spinner from '../Spinner';

type Props = {
  bsStyle?: string,
  classes?: string,
  clickHandler: Function,
  isSubmitting?: boolean,
  children: any,
  hidden?: boolean,
  disabled?: boolean,
};

const SubmitButton = ({
  bsStyle,
  classes,
  clickHandler,
  isSubmitting,
  children,
  hidden,
  disabled,
}: Props) => {
  if (hidden) return null;
  const styles = {};
  if (bsStyle) {
    styles.bsStyle = bsStyle;
  }
  if (classes) {
    styles.className = classes;
  }
  return (
    <Button
      disabled={disabled || isSubmitting}
      onClick={() => clickHandler()}
      {...styles}
    >
      {isSubmitting ? <Spinner small /> : children}
    </Button>
  );
};

export default SubmitButton;
