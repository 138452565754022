import React from 'react';
import messages from './messages';
import { FormGroup, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { TouchedPhoneNumberInput } from './TouchedPhoneNumberInput';
import { TouchedTextInput } from './TouchedTextInput';
import { removeNonNumericCharacters } from '../../../utils';
import { validateEmail } from '../../../utils/validations';
import { StateSelect } from '../../ManageBuildings/BuildingsTable/StateSelect';
import type { State } from '../../ManageBuildings/types';

type Props = {
  intl: Object,
  data: Object,
  onChange: Function,
  states: State[],
};

export const BusinessInformationForm = ({
  intl,
  data,
  onChange,
  states,
}: Props) => {
  const {
    tenantLegalName,
    doingBusinessAs,
    parentCompany,
    phoneNumber,
    emailAddress,
    street,
    street2,
    city,
    state,
    zipCode,
  } = data;

  return (
    <FormGroup className="applicant-grp">
      <Row className="block-heading">
        <h2>{intl.formatMessage(messages.businessInformation)}</h2>
      </Row>
      <Row>
        <div className="container-of-inputs">
          <Col md={2}>
            <label>
              <FormattedMessage {...messages.tenantLegalName} />*
            </label>
          </Col>
          <Col md={2}>
            <label>
              <FormattedMessage {...messages.dba} />
            </label>
          </Col>
          <Col md={2}>
            <label>
              <FormattedMessage {...messages.parentCompany} />
            </label>
          </Col>
          <Col md={2}>
            <label>
              <FormattedMessage {...messages.phoneNumber} />*
            </label>
          </Col>
          <Col md={4}>
            <label>
              <FormattedMessage {...messages.email} />*
            </label>
          </Col>
        </div>
      </Row>
      <Row>
        <div className="container-of-inputs">
          <Col md={2}>
            <TouchedTextInput
              name="tenantLegalName"
              value={tenantLegalName}
              editMode={true}
              onChange={onChange}
              required={true}
              placeholder="Tenant Legal Name"
            />
          </Col>
          <Col md={2}>
            <TouchedTextInput
              name="doingBusinessAs"
              value={doingBusinessAs}
              editMode={true}
              onChange={onChange}
              placeholder="Doing Business As"
            />
          </Col>
          <Col md={2}>
            <TouchedTextInput
              name="parentCompany"
              value={parentCompany}
              editMode={true}
              onChange={onChange}
              placeholder="Parent Company"
            />
          </Col>
          <Col md={2}>
            <TouchedPhoneNumberInput
              name="phoneNumber"
              value={phoneNumber}
              editMode={true}
              onChange={onChange}
              required={true}
              placeholder="(xxx) xxx-xxxx"
            />
          </Col>
          <Col md={4}>
            <TouchedTextInput
              name="emailAddress"
              value={emailAddress}
              editMode={true}
              onChange={onChange}
              required={true}
              type="email"
              validateFunction={(value) => value && validateEmail(value)}
              message={messages.invalidEmailAddress}
              placeholder="Enter Email"
            />
          </Col>
        </div>
      </Row>
      <Row>
        <div className="container-of-inputs">
          <Col md={2}>
            <label>
              <FormattedMessage {...messages.address} />
            </label>
          </Col>
          <Col md={2}>
            <label>
              <FormattedMessage {...messages.address2} />
            </label>
          </Col>
          <Col md={2}>
            <label>
              <FormattedMessage {...messages.city} />
            </label>
          </Col>
          <Col md={1}>
            <label>
              <FormattedMessage {...messages.state} />
            </label>
          </Col>
          <Col md={2}>
            <label>
              <FormattedMessage {...messages.zipCode} />
            </label>
          </Col>
        </div>
      </Row>
      <Row>
        <div className="container-of-inputs">
          <Col md={2}>
            <TouchedTextInput
              name="street"
              value={street}
              editMode={true}
              onChange={onChange}
              placeholder="Address"
            />
          </Col>
          <Col md={2}>
            <TouchedTextInput
              name="street2"
              value={street2}
              editMode={true}
              onChange={onChange}
              placeholder="Address 2"
            />
          </Col>
          <Col md={2}>
            <TouchedTextInput
              name="city"
              value={city}
              editMode={true}
              onChange={onChange}
              placeholder="City"
            />
          </Col>
          <Col md={1}>
            <StateSelect
              name="state"
              value={state}
              states={[{ id: null, code: '' }, ...states]}
              onChange={onChange}
              maxWidth="100%"
              className="input-lg form-control"
            />
          </Col>
          <Col md={2}>
            <TouchedTextInput
              name="zipCode"
              value={zipCode}
              editMode={true}
              onChange={({ target: { name, value } }) => {
                onChange({
                  target: {
                    name,
                    value: removeNonNumericCharacters(value),
                  },
                });
              }}
              maxLength={5}
              validateFunction={(value) => value && value.length === 5}
              message={messages.invalidZipCode}
              placeholder="Zip Code / Postal Code"
              required={zipCode}
            />
          </Col>
        </div>
      </Row>
    </FormGroup>
  );
};
