import React from 'react';
import useFormatMessage from '../../../../../hooks/useFormatMessage';
import messages from './messages';

const handleChange =
  (handler) =>
  ({ target: { files } }) =>
    handler(files.length ? { file: files[0], name: files[0].name } : {});

export const FileInput = ({
  input: { onChange, onBlur, value: file, ...inputProps },
  meta: _meta,
  propertyId: _propertyId,
  organizationId: _organizationId,
  selectedInsurance: _selectedInsurance,
  ...props
}) => {
  const formatMessage = useFormatMessage();

  return (
    <>
      <div>
        <label className="input">{props.label}</label>
      </div>
      <input
        className="inputfile inputfile-custom"
        id={inputProps.name}
        type="file"
        onChange={handleChange(onChange)}
        onBlur={handleChange(onBlur)}
        {...inputProps}
        {...props}
      />
      <label
        htmlFor={inputProps.name}
        style={{ width: '100%', height: 40, fontSize: 14 }}
      >
        <span
          style={{
            width: '75%',
            paddingLeft: 6,
            paddingRight: 6,
            color: file?.name ? '#444444' : undefined,
          }}
        >
          {file?.name || props.placeholder}
        </span>
        <strong style={{ width: '25%', textAlign: 'center', borderRadius: 3 }}>
          {formatMessage(messages.browse)}
        </strong>
      </label>
    </>
  );
};
