import React, { useState } from 'react';
import { PhoneNumberInput } from '../../ViewProperty/BasicDetails/PhoneNumberInput';

type Props = {
  required: boolean,
  rest: any[],
};

export const TouchedPhoneNumberInput = ({ required, ...rest }: Props) => {
  const [touched, setTouched] = useState(false);

  return (
    <PhoneNumberInput
      {...rest}
      required={touched && required}
      onBlur={() => {
        setTouched(true);
      }}
      className="input-lg form-control"
    />
  );
};
