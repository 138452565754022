import { MutateOptions, useMutation } from 'react-query';
import AffordableQualificationService from '../../services/affordableQualificationService';
import useAbortController from '../../hooks/useAbortController';

const useCompleteCertificationMutation = ({
  organizationId,
  propertyId,
  affordableQualificationId,
  options,
}: {
  organizationId: string,
  propertyId: string,
  affordableQualificationId: string,
  options: MutateOptions,
}) => {
  const abortControllerOptions = useAbortController();
  return useMutation(async ({ ...payload }) => {
    return await new AffordableQualificationService().completeCertification(
      organizationId,
      propertyId,
      affordableQualificationId,
      false,
      null,
      payload.ownerSignatureDate,
      abortControllerOptions,
    );
  }, options);
};

export default useCompleteCertificationMutation;
