import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import {
  renderDateField,
  validateDateIsNotFuture,
} from '../../utils/redux-form-helper';

type Report = {
  runByNonFutureDate: boolean,
};

type Props = {
  store: Object,
  typeRange?: string,
  report?: Report,
  startDateFieldName?: string,
  endDateFieldName?: string,
};

function ByDateControls({
  store,
  typeRange = 'range',
  report = {},
  startDateFieldName = 'startDate',
  endDateFieldName = 'endDate',
}: Props) {
  const isValidDate = report.runByNonFutureDate
    ? validateDateIsNotFuture
    : null;
  return (
    <>
      <Row className={'padtop5'}>
        <Col className="col-md-6 col-xs-6 has-range has-range-nolabel">
          <Field
            store={store}
            name={startDateFieldName}
            component={renderDateField}
            classPicker="modal-fixed-position"
            bsSize="lg"
            disabled={typeRange !== 'range'}
            isValidDate={isValidDate}
            placeholder="MM/DD/YYYY"
          />
        </Col>
        <Col className="col-md-6 col-xs-6">
          <Field
            store={store}
            name={endDateFieldName}
            component={renderDateField}
            classPicker="modal-fixed-position"
            bsSize="lg"
            disabled={typeRange !== 'range'}
            isValidDate={isValidDate}
            placeholder="MM/DD/YYYY"
          />
        </Col>
      </Row>
    </>
  );
}

export default ByDateControls;
