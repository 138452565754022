import { OverlayTrigger, Popover } from 'react-bootstrap';
import styled from 'styled-components';

type Props = {
  placement: string,
  trigger: Array<string> | string,
  message: React.Element<any>,
};

const StyledSpan = styled.span`
  margin: 2px;
  position: absolute;
`;

const InfoIconToolTip = (props: Props) => {
  const { placement, message } = props;

  const infoPopover = (
    <Popover id={`popover-positioned-${placement}`}>{message}</Popover>
  );
  return (
    <OverlayTrigger
      overlay={infoPopover}
      placement={placement ? placement : 'bottom'}
    >
      <StyledSpan>
        <i className="icon et-alert-info" />
      </StyledSpan>
    </OverlayTrigger>
  );
};

export default InfoIconToolTip;
