import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';

import messages from './messages';
import BankDepositService from '../../../../services/bankDepositService';

type HooksArgs = {
  intl: Object,
  propertyId: string,
  organizationId: string,
  depositId: string,
  promptToaster: Function,
};

export const useUploadAttachment = ({
  intl,
  propertyId,
  organizationId,
  depositId,
  promptToaster,
}: HooksArgs) => {
  const [document, setDocument] = useState(null);
  const [callback, setCallback] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const onUploadDocument = (document: Object, callback?: Function) => {
    setDocument(document);
    setCallback(callback);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const uploadAttachment = async (document: Object, callback?: Function) => {
      try {
        if (!document || !document.file) {
          throw new Error('Could not upload file');
        }

        setIsSaving(true);
        const attachment = new FormData();
        attachment.append('file', document.file);
        attachment.append('notes', document.notes);

        const bankDepositService = new BankDepositService();
        await bankDepositService.uploadDepositAttachment(
          organizationId,
          propertyId,
          depositId,
          attachment,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.attachmentUploadSuccessTitle),
          message: intl.formatMessage(messages.attachmentUploadSuccessMsg),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.attachmentUpdateErrorTitle),
          message: pathOr(err.toString(), ['message'], err),
        });
      } finally {
        setIsSaving(false);
        if (callback) {
          callback();
          setCallback(null);
        }
      }
    };

    if (document) {
      uploadAttachment(document, callback);
    }

    return () => {
      setDocument(null);
      setCallback(null);
    };
  }, [
    organizationId,
    propertyId,
    depositId,
    promptToaster,
    intl,
    document,
    callback,
    isSaving,
  ]);

  return [isSaving, onUploadDocument];
};

export const useUpdateAttachment = ({
  intl,
  propertyId,
  organizationId,
  depositId,
  promptToaster,
}: HooksArgs) => {
  const [document, setDocument] = useState(null);
  const [notes, setNotes] = useState(null);
  const [callback, setCallback] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const onUpdateDocument = (
    document: Object,
    notes: string,
    callback?: Function,
  ) => {
    setDocument(document);
    setNotes(notes);
    setCallback(callback);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const updateAttachment = async (
      document: Object,
      notes: string,
      callback?: Function,
    ) => {
      setIsSaving(true);

      try {
        const attachmentId = pathOr(null, ['id'], document);
        if (!attachmentId) {
          throw new Error('Could not update attachment');
        }

        const payload = { notes };

        const bankDepositService = new BankDepositService();
        await bankDepositService.updateDepositAttachment(
          organizationId,
          propertyId,
          depositId,
          attachmentId,
          payload,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.attachmentUpdateSuccessTitle),
          message: intl.formatMessage(messages.attachmentUpdateSuccessMsg),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.attachmentUpdateErrorTitle),
          message: pathOr(err.toString(), ['message'], err),
        });
      } finally {
        setIsSaving(false);
        if (callback) {
          callback();
          setCallback(null);
        }
      }
    };

    if (document) {
      updateAttachment(document, notes, callback);
    }

    return () => {
      setDocument(null);
      setNotes(null);
      setCallback(null);
    };
  }, [
    organizationId,
    propertyId,
    depositId,
    promptToaster,
    intl,
    document,
    callback,
    isSaving,
    notes,
  ]);

  return [isSaving, onUpdateDocument];
};
