import type { Action } from '../App/types';
import type { ManageReportsState } from './types';
import * as ActionTypes from './constants';

const initialState: any = {
  meta: {
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  reports: [],
  historicalReports: [],
  historicalSearch: false,
  periods: [],
  columnOrder: {
    reportName: 'sortable',
    reportType: 'sortable',
    reportLastCreated: 'sortable',
  },
  currentSorting: {
    fieldName: '',
    order: '',
  },
};

const manageReportsReducer = (
  state: ManageReportsState = initialState,
  action: Action<*, *>,
): ManageReportsState => {
  const { type, payload, meta } = action;
  switch (type) {
    case ActionTypes.GET_ALL_REPORTS: {
      return {
        ...state,
        currentSorting: payload.sorting,
      };
    }
    case ActionTypes.GET_ALL_REPORTS_SUCCESS: {
      return { ...state, reports: payload, meta: meta };
    }
    default:
      return state;
  }
};

export default manageReportsReducer;
