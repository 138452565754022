import SetupGridItem from '../../SetupGridItem';
import usePaymentConfigData from './usePaymentConfigData';

function FortressPaymentsConfig() {
  const paymentConfigData = usePaymentConfigData();

  return (
    <>
      <SetupGridItem label="Operating Bank Account">
        {paymentConfigData.operatingBankAccount}
      </SetupGridItem>
      <SetupGridItem label="Acceptable Forms of Payment">
        {paymentConfigData.acceptableFormsOfPayment}
      </SetupGridItem>
      <SetupGridItem label="Security Bank Account">
        {paymentConfigData.securityBankAccount}
      </SetupGridItem>
      <SetupGridItem label="Partial Pay Allowed">
        {paymentConfigData.allowsPartialPayments}
      </SetupGridItem>
      <SetupGridItem label="Miscellaneous Bank Account">
        {paymentConfigData.miscBankAccount}
      </SetupGridItem>
      <SetupGridItem label="Overpay Allowed">
        {paymentConfigData.allowsOverpay}
      </SetupGridItem>
      <SetupGridItem label="Overpay Percentage">
        {paymentConfigData.overpayPct}
      </SetupGridItem>
    </>
  );
}

export default FortressPaymentsConfig;
