import React from 'react';

import { formatPeriod } from '../../../utils/redux-form-helper.js';
import messages from './messages';
import type { Period } from '../types.js';

type Props = {
  formatMessage: Function,
  periods: Array<Period>,
  onPeriodChange: Function
};

export const periodDropDowns = (periods: Array<any>): Array<any> =>
  periods.map((period) => (
    <option
      key={period.period}
      value={period.id}
      data-test="manage-deposits-period"
    >
      {formatPeriod(period.period)}
    </option>
  ));

const ManageDepositsHeader = ({
  formatMessage,
  periods,
  onPeriodChange
}: Props) => {
  return (
    <div
      className="container-fluid header-with-filters"
      data-test="manage-deposits-header"
    >
      <div className="row">
        <div className="col-xs-12 col-lg-4">
          <h1>{formatMessage(messages.title)}</h1>
        </div>
        <div className="col-xs-12 col-lg-8">
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="row">
                <div className="col-xs-3 float-right">
                  <label>{formatMessage(messages.filterByPeriod)}</label>
                  <select
                    className="form-control input-lg"
                    onChange={onPeriodChange}
                  >
                    {periods ? periodDropDowns(periods) : ''}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageDepositsHeader;
