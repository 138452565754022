import styled from 'styled-components';
import { blue } from '@fortress-technology-solutions/fortress-component-library/design';

export const SectionHeader = styled.div`
  padding: 10px;
  & h2 {
    margin: 0;
  }
`;
export const Section = styled.div`
  padding-bottom: 20px;
  border-bottom: solid 1px #e4e4e4;
  &:last-child {
    border-bottom: none;
  }
`;
export const EditedByBlock = styled.div`
  background: #ebf5ff;
  border: 1px solid ${blue.dark};
  box-sizing: border-box;
  border-radius: 2px;
  color: ${blue.dark};
  padding: 10px 18px;
  max-width: 90%;
  margin-left: 10px;
`;
