import { isIdentificationNumberMissing } from './utils';
import { VALID_ID, VALID_ID_AFFORDABLE } from './constants';

export const warn = (values: Object, props: Object) => {
  const warnings = {
    personalInformation: {},
  };

  const { type, personalInformation } = values;
  const { isAffordable, initialValues } = props;
  const validMessage = isAffordable ? VALID_ID_AFFORDABLE : VALID_ID;
  const isIdentificationNumberBypassed = isIdentificationNumberMissing(
    initialValues.personalInformation,
  );

  if (
    type === 'adult' &&
    isIdentificationNumberBypassed &&
    isIdentificationNumberMissing(personalInformation)
  ) {
    warnings.personalInformation.identificationNumber = 'Required';
    warnings.personalInformation.stateIdNumber = validMessage;
    warnings.personalInformation.passportNumber = validMessage;
    warnings.personalInformation.socialSecurityNumber = validMessage;
    warnings.personalInformation.alienRegistration = validMessage;
  }

  return warnings;
};

export default warn;
