import React from 'react';
import messages from './messages';
import type { Transaction } from '../types.js';
import LedgerRow from '../LedgerRow';

type Props = {
  ledgerData: Array<Transaction>,
  formatMessage: Function,
  formatNumber: Function,
  isOps: boolean,
  isAllSubjournals: boolean,
  onViewTransaction: Function,
  viewTransactionDisabled: boolean,
};

const LedgerDetails = (props: Props) => {
  const {
    ledgerData,
    formatMessage,
    formatNumber,
    isOps,
    isAllSubjournals,
    onViewTransaction,
    viewTransactionDisabled,
  } = props;

  return (
    <div className="container-fluid table-scroll table-units-container">
      <table className="table table-prospects table-striped">
        <thead className="table-header">
          <tr>
            <th>{formatMessage(messages.unit)}</th>
            <th>{formatMessage(messages.date)}</th>
            {isOps ? <th>{formatMessage(messages.subjournal)}</th> : null}
            <th>{formatMessage(messages.period)}</th>
            <th>{formatMessage(messages.code)}</th>
            <th>{formatMessage(messages.status)}</th>
            <th>{formatMessage(messages.manual)}</th>
            <th>{formatMessage(messages.description)}</th>
            <th>{formatMessage(messages.documentNumber)}</th>
            <th>{formatMessage(messages.charges)}</th>
            <th>{formatMessage(messages.credits)}</th>
            <th>{formatMessage(messages.balance)}</th>
            <th>{formatMessage(messages.transactionNotes)}</th>
            <th>{formatMessage(messages.view)}</th>
          </tr>
        </thead>
        <tbody data-test="ledger-details">
          <LedgerRow
            transactions={ledgerData}
            formatMessage={formatMessage}
            formatNumber={formatNumber}
            isOps={isOps}
            isAllSubjournals={isAllSubjournals}
            onViewTransaction={onViewTransaction}
            viewTransactionDisabled={viewTransactionDisabled}
          />
        </tbody>
      </table>
    </div>
  );
};

export default LedgerDetails;
