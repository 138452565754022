import {
  Card,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import messages from '../messages';
import useFormatMessage from '../../../hooks/useFormatMessage';

function PermissionRequiredCard() {
  const formatMessage = useFormatMessage();

  return (
    <Card>
      <Grid container padding={4} spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h2" textAlign="center">
            {formatMessage(messages.permissionRequiredCardTitle)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography textAlign="center">
            {formatMessage(messages.the)}{' '}
            <b>{formatMessage(messages.permissionName)}</b>{' '}
            {formatMessage(messages.permissionRequiredCardText)}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default PermissionRequiredCard;
