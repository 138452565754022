import React from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import ElementWithPermissions from '../../../../components/ElementWithPermissions';

type Props = {
  openUploadDocumentModal: Function,
  openEditGRCertModal: Function,
  handleCancelCertification: Function,
  handleCompleteCertification: Function,
  disableGenerate59: boolean,
  hasGenerated59a: boolean,
};

export const ActionsMenu = ({
  openUploadDocumentModal,
  openEditGRCertModal,
  handleCancelCertification,
  handleCompleteCertification,
  uploadedSigned59A,
  disableGenerate59,
  hasGenerated59a,
}: Props) => {
  return (
    <Dropdown bsSize="small" id={'action-menu-{title}'}>
      <Dropdown.Toggle>
        <i className="et-bolt" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <MenuItem
          onSelect={openUploadDocumentModal}
          disabled={disableGenerate59 || !hasGenerated59a}
        >
          <FormattedMessage {...messages.uploadDocument} />
        </MenuItem>
        <MenuItem onSelect={openEditGRCertModal}>
          <FormattedMessage {...messages.editCert} />
        </MenuItem>
        <ElementWithPermissions scope={['compliance-approval']}>
          <MenuItem
            onSelect={handleCompleteCertification}
            disabled={disableGenerate59 || !uploadedSigned59A}
          >
            <FormattedMessage {...messages.completeCert} />
          </MenuItem>
        </ElementWithPermissions>
        <ElementWithPermissions
          scope={['manage-certifications-gr-cert-cancel']}
        >
          <MenuItem onSelect={handleCancelCertification}>
            <FormattedMessage {...messages.cancelCert} />
          </MenuItem>
        </ElementWithPermissions>
      </Dropdown.Menu>
    </Dropdown>
  );
};
