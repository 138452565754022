export const GET_ALL_PERMISSIONS = 'app/CreteRole/GET_ALL_PERMISSIONS';
export const GET_ALL_PERMISSIONS_SUCCESS = 'app/CreteRole/GET_ALL_PERMISSIONS_SUCCESS';
export const GET_ALL_PERMISSIONS_FAIL = 'app/CreteRole/GET_ALL_PERMISSIONS_FAIL';
export const NONE_PERMISSIONS_SELECTED = 'app/CreteRole/NONE_PERMISSIONS_SELECTED';
export const SET_SELECTED_PERMISSIONS = 'app/CreteRole/SET_SELECTED_PERMISSIONS';
export const GET_ALL_ROLE_TYPES = 'app/CreteRole/GET_ALL_ROLE_TYPES';
export const GET_ALL_ROLE_TYPES_SUCCESS = 'app/CreteRole/GET_ALL_ROLE_TYPES_SUCCESS';
export const GET_ALL_ROLE_TYPES_FAIL = 'app/CreteRole/GET_ALL_ROLE_TYPES_FAIL';
export const CREATE_ROLE = 'app/CreteRole/CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = 'app/CreteRole/CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAIL = 'app/CreteRole/CREATE_ROLE_FAIL';
export const RESET_CREATE_ROLE_FORM = 'app/CreteRole/RESET_CREATE_ROLE_FORM';
export const SHOW_ROLE_PERMIT_ASSIGNEED = 'app/CreateRole/SHOW_ROLE_PERMIT_ASSIGNEED';