import React, { Component } from 'react';
import { injectIntl, type InjectIntlProvidedProps } from 'react-intl';
import DocumentTitle from 'react-document-title';
import messages from './messages';
import MonthEndCloseForm from './MonthEndCloseForm';

export class MonthEndClose extends Component<InjectIntlProvidedProps> {
  render() {
    const { intl, ...props } = this.props;

    return (
      <DocumentTitle title={intl.formatMessage(messages.domTitle)}>
        <MonthEndCloseForm {...props} intl={intl} />
      </DocumentTitle>
    );
  }
}

export default injectIntl(MonthEndClose);
