import {
  GET_CREATE_CHARGE_BATCH,
  GET_CREATE_CHARGE_BATCH_FAILURE,
  GET_CREATE_CHARGE_BATCH_SUCCESS
} from './constants';
import type { Action } from '../App/types';

export const getCreateChargeBatch = () => ({
  type: GET_CREATE_CHARGE_BATCH,
  payload: undefined
});

export const getCreateChargeBatchFailure = (err: Error) => ({
  type: GET_CREATE_CHARGE_BATCH_FAILURE,
  payload: err,
  error: true
});

export const getCreateChargeBatchSuccess = (payload: Action<Object>) => ({
  type: GET_CREATE_CHARGE_BATCH_SUCCESS,
  payload
});
