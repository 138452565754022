import { defineMessages } from 'react-intl';

const messages = defineMessages({
  yes: {
    id: 'App.ConfirmDialogModal.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.ConfirmDialogModal.no',
    defaultMessage: 'No',
  },
  cancel: {
    id: 'App.ConfirmDialogModal.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'App.ConfirmDialogModal.confirm',
    defaultMessage: 'Confirm',
  },
});

export default messages;
