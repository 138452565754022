import { defineMessages } from 'react-intl';

const messages = defineMessages({
  saveChanges: {
    id: 'EditFeeModal.saveChanges',
    defaultMessage: 'Save Changes',
  },
  cancel: {
    id: 'EditFeeModal.cancel',
    defaultMessage: 'Cancel',
  },
  editAmountFor: {
    id: 'EditFeeModal.editAmountFor',
    defaultMessage: 'Edit Amount for {fee}',
  },
  feeAmount: {
    id: 'EditFeeModal.feeAmount',
    defaultMessage: 'Fee Amount',
  },
  feeDescriptionAmount: {
    id: 'EditFeeModal.feeDescriptionAmount',
    defaultMessage: 'Fee Amount Description',
  },
  cancelConfirmationHeader: {
    id: 'EditFeeModal.cancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  yes: {
    id: 'EditFeeModal.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'EditFeeModal.no',
    defaultMessage: 'No',
  },
  requiredError: {
    id: 'EditFeeModal.requiredError',
    defaultMessage: 'Field is required',
  },
  maxLengthError: {
    id: 'EditFeeModal.maxLengthError',
    defaultMessage: 'Max amount of characters is 75',
  },
  editTitle: {
    id: 'EditFeeModal.editTitle',
    defaultMessage: 'Edit Amount for Fee - {code}',
  },
  successTitle: {
    id: 'EditFeeModal.successTitle',
    defaultMessage: 'Success',
  },
  successMessage: {
    id: 'EditFeeModal.successMessage',
    defaultMessage: 'All changes were succesfully saved',
  },
  minimumValueError: {
    id: 'EditFeeModal.minimumValueError',
    defaultMessage: 'Minimum value is',
  },
  maxValueError: {
    id: 'EditFeeModal.maxValueError',
    defaultMessage: 'Maximum value is',
  },
});

export default messages;
