import {
  Card,
  Switch,
  Typography,
  Divider,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import SetupGridItem from '../SetupGridItem';
import OnOffTypography from '../OnOffTypography';

type Props = {
  editMode: boolean,
  config: Object,
  onChange: Function,
  disabled: boolean,
};

function MobileAppSetup({
  editMode: editModeProp,
  config,
  onChange,
  disabled,
}: Props) {
  const { residentCommunicationEnabled } = config || {};

  const editMode = editModeProp && !disabled;

  const handleSwitchChange = (event) => {
    const value = event.target.checked;
    onChange({
      target: {
        name: 'config.residentCommunicationEnabled',
        value,
      },
    });
  };
  return (
    <Card sx={{ padding: 2, height: '100%' }}>
      <Typography variant={'h3'}>
        <FormattedMessage {...messages.mobileAppSetup} />
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Grid container spacing={2}>
        <SetupGridItem sm={12}>
          <Typography variant={'formSubheading'}>
            <FormattedMessage {...messages.workOrders} />
          </Typography>
        </SetupGridItem>

        <SetupGridItem
          label={<FormattedMessage {...messages.residentCommunication} />}
          TooltipProps={{
            title: <FormattedMessage {...messages.tooltipText} />,
          }}
        >
          {editMode ? (
            <Switch
              name="residentCommunications"
              id="residentCommunications-switch"
              checked={residentCommunicationEnabled}
              disabled={!editMode}
              onChange={handleSwitchChange}
              inputProps={{
                'data-checked': residentCommunicationEnabled,
              }}
            />
          ) : (
            <OnOffTypography on={residentCommunicationEnabled}>
              {residentCommunicationEnabled ? (
                <FormattedMessage {...messages.on} />
              ) : (
                <FormattedMessage {...messages.off} />
              )}
            </OnOffTypography>
          )}
        </SetupGridItem>
      </Grid>
    </Card>
  );
}

export default MobileAppSetup;
