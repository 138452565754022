import moment from 'moment';
import { type, not, pathOr } from 'ramda';
import { validateRequired } from '../../../utils/validations';

import messages from './messages';

import type { NoticeToVacateForm } from '../types';

const validate = (
  values: NoticeToVacateForm,
  {
    intl,
    forwardingAddressRequired,
    unit,
    lease,
    isUnitAffordableConventional,
    isAffordableMixedProperty,
    moveOutReasons,
  }: Object,
) => {
  const errors = {};
  const endOfToday = moment().endOf('day');
  const thirtyDaysAgo = moment().subtract(30, 'days');
  let emptyForeignAddresses = 0;

  ['noticeDate', 'moveOutDate', 'moveOutReasonId', 'notes'].forEach(
    (fieldName) => {
      if (!validateRequired(fieldName, values)) {
        errors[fieldName] = intl.formatMessage(messages.required);
      }
    },
  );

  ['street', 'city', 'state', 'zipCode'].forEach((fieldName) => {
    if (
      forwardingAddressRequired &&
      values['noForwardingAddress'] === false &&
      not(values['isForeignAddress']) &&
      !validateRequired(fieldName, values)
    ) {
      errors[fieldName] = intl.formatMessage(messages.required);
    }
  });
  ['foreignAddressLine1', 'foreignAddressLine2', 'foreignAddressLine3'].forEach(
    (fieldName) => {
      if (!validateRequired(fieldName, values)) {
        emptyForeignAddresses += 1;
      }
    },
  );
  ['foreignAddressLine1', 'foreignAddressLine2', 'foreignAddressLine3'].forEach(
    (fieldName) => {
      if (
        values['noForwardingAddress'] === false &&
        values['isForeignAddress'] &&
        !validateRequired(fieldName, values) &&
        emptyForeignAddresses > 1
      ) {
        errors[fieldName] = intl.formatMessage(messages.required);
      }
    },
  );

  if (forwardingAddressRequired === false) {
    const noticeDate = moment(values.noticeDate);
    const moveOutDate = moment(values.moveOutDate);

    if (!noticeDate.isValid() || type(values.noticeDate) === 'String') {
      errors.noticeDate = intl.formatMessage(messages.invalidDate);
    }

    if (!moveOutDate.isValid() || type(values.moveOutDate) === 'String') {
      errors.moveOutDate = intl.formatMessage(messages.invalidDate);
    }

    if (!errors.noticeDate && noticeDate.isAfter(endOfToday)) {
      errors.noticeDate = intl.formatMessage(messages.dateInFuture);
    }

    if (
      !errors.moveOutDate &&
      moveOutDate.isBefore(thirtyDaysAgo) &&
      !forwardingAddressRequired
    ) {
      errors.moveOutDate = intl.formatMessage(messages.dateMustBeGreater);
    }

    if (!errors.moveOutDate && moveOutDate.isBefore(lease.startDate)) {
      errors.moveOutDate = lease.commercialLeaseTypeId
        ? intl.formatMessage(messages.dateMustBeOnOrAfterCommencementDate)
        : intl.formatMessage(messages.dateMustBeOnOrAfterLeaseStartDate);
    }
    const moveInDate = pathOr(
      false,
      ['nextAssignation', 'lease', 'moveInDate'],
      unit,
    );
    if (moveInDate && moveOutDate.isSameOrAfter(moveInDate)) {
      errors.moveOutDate = intl.formatMessage(
        messages.dateMustBeBeforeNextAssignation,
      );
    }
  }

  const selectedValue = (moveOutReasons ?? []).find(
    (mr) => mr.value === values?.moveOutReasonId,
  );
  const shouldValidateHouseSituation =
    selectedValue?.text?.startsWith('WA -') &&
    isAffordableMixedProperty &&
    isUnitAffordableConventional;

  if (
    shouldValidateHouseSituation &&
    !validateRequired('housingSituationId', values)
  ) {
    errors.housingSituationId = intl.formatMessage(messages.required);
  }

  return errors;
};

export default validate;
