type Props = {
  active?: boolean,
  height?: number,
};

const caretStyle = {
  fontSize: 8,
  marginLeft: -6,
};

function FilterIconButton({ active = false, height, ...buttonProps }: Props) {
  const className = `button-filter btn-default btn-tertiary btn ${buttonProps.className}`;
  const caretClass = active ? 'et-caret-up' : 'et-caret-down';
  const buttonStyle =
    active && !buttonProps.disabled
      ? { background: '#EBF5FF', border: '1px solid #4D73FF' }
      : {};

  return (
    <button
      {...buttonProps}
      aria-label="filter-btn"
      className={className}
      style={height ? { ...buttonStyle, height } : buttonStyle}
    >
      <i className="et-filter" />
      <i className={caretClass} style={caretStyle} />
    </button>
  );
}

export default FilterIconButton;
