import {
  Spinner,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { TaskTableRow } from '../TaskTableRow';
import React from 'react';

type Props = {
  isTaskLoading: boolean,
  sortedItems: Array,
  assignees: Array,
  onChangeAssignee: Function,
};

export const TaskTableBody = (props: Props) => {
  if (props.isTasksLoading) {
    return (
      <tr>
        <td colSpan="100%">
          <Spinner />
        </td>
      </tr>
    );
  }

  if (props.sortedItems.length > 0) {
    return props.sortedItems.map((item, index) => (
      <TaskTableRow
        key={index}
        item={item}
        assignees={props.assignees}
        onChangeAssignee={props.onChangeAssignee}
      />
    ));
  }

  return (
    <tr>
      <td colSpan="100%">
        <Typography variant="body1">No data found</Typography>
      </td>
    </tr>
  );
};
