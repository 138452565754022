import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import {
  renderSelectField,
  renderTextField,
  socialSecurityNumberParser,
  socialSecurityNumberFormatter,
} from '../../../utils/redux-form-helper';

import messages from './messages';

import type { SelectOption } from '../../../containers/App/types';

type IdentificationRowProps = {
  intl: Object,
  countries: Array<SelectOption>,
};

export const AffordableIdentificationRow = ({
  intl,
  countries,
}: IdentificationRowProps) => {
  return (
    <Row>
      <Col xs={12} md={3}>
        <Field
          name="socialSecurityNumber"
          component={renderTextField}
          className="input-lg"
          label={`${intl.formatMessage(messages.socialSecurityNumberLabel)}**`}
          placeholder={intl.formatMessage(messages.socialSecurityNumberLabel)}
          showLabel
          parse={socialSecurityNumberParser}
          format={socialSecurityNumberFormatter}
        />
      </Col>
      <Col xs={12} md={3}>
        <Field
          name="passportNumber"
          component={renderTextField}
          className="input-lg"
          label={`${intl.formatMessage(messages.passportLabel)}**`}
          placeholder={intl.formatMessage(messages.passportNumberPlaceholder)}
          showLabel
          maxLength="20"
        />
      </Col>
      <Col xs={12} md={3}>
        <Field
          name="passportCountry"
          component={renderSelectField}
          options={countries}
          label={intl.formatMessage(messages.passportCountryLabel)}
          bsSize="lg"
        />
      </Col>
      <Col xs={12} md={3}>
        <Field
          name="alienRegistration"
          component={renderTextField}
          className="input-lg"
          label={`${intl.formatMessage(messages.alienRegistrationLabel)}**`}
          placeholder={intl.formatMessage(
            messages.alienRegistrationPlaceholder,
          )}
          showLabel
          maxLength="20"
        />
      </Col>
    </Row>
  );
};

export default AffordableIdentificationRow;
