import React, { useState } from 'react';
import { pathOr, isNil, isEmpty } from 'ramda';
import { Row, Col, Button, Form } from 'react-bootstrap';

import { getTransactionReversalCamInfo } from '../../../utils/cam';

import messages from '../messages';
import ModifyTransactionCamPoolForm from '../ModifyTransactionCamPoolModal/form';

const DEFAULT_OPTION = { value: 'default', text: 'Choose', disabled: true };

type Props = {
  intl: any,
  singleTransaction: Object,
  callReverse: Function,
  onClose: Function,
  isCamApplicable: boolean,
};

const ReverseTransactionModal = ({
  intl,
  singleTransaction,
  callReverse,
  onClose,
  currentCamPool,
  camPools,
}: Props) => {
  const [notes, setNotes] = useState('');
  const [isNsfReversalOption, setIsNsfReversalOption] = useState(null);

  const initialCamPool = pathOr(null, ['camAllocationsId'], currentCamPool);
  const initialCamRecord = pathOr(null, ['camRecordsId'], currentCamPool);
  const initialApplyToCamPool =
    !isNil(initialCamPool) && !isNil(initialCamRecord);
  const [camFormValues, setCamFormValues] = useState({
    applyToCamPool: initialApplyToCamPool,
    camRecord: initialCamRecord || DEFAULT_OPTION.value,
    camPool: initialCamPool || DEFAULT_OPTION.value,
  });

  const { applyToCamPool, camRecord, camPool } = camFormValues;

  const { useCamForReversal, useSameCamPool, showCamPoolFields } =
    getTransactionReversalCamInfo({
      singleTransaction,
      currentCamPool,
      camPools,
    });

  const currentNote = pathOr('', ['transactionNote'], singleTransaction);
  const isPayment = pathOr(
    false,
    ['propertyTransactionCode', 'transactionCode', 'isPayment'],
    singleTransaction,
  );
  const securityTransaction =
    pathOr(
      '',
      ['propertyTransactionCode', 'transactionCode', 'transactionType', 'name'],
      singleTransaction,
    ) === 'Security Payment';
  const isOpsPayment = isPayment && !securityTransaction;
  const extraHeightStyle =
    isOpsPayment || showCamPoolFields ? { minHeight: '400px' } : {};

  const isCamPoolValid =
    !useCamForReversal ||
    useSameCamPool ||
    (!isNil(camRecord) &&
      !isNil(camPool) &&
      camRecord !== DEFAULT_OPTION.value &&
      camPool !== DEFAULT_OPTION.value);

  const notesEmpty = isNil(notes) || isEmpty(notes);
  const disableReverseButton =
    notesEmpty === true ||
    !isCamPoolValid ||
    (isOpsPayment && isNsfReversalOption === null);

  const getCamPoolToApply = () => {
    if (!applyToCamPool) {
      return null;
    }

    if (useSameCamPool) {
      return initialCamPool;
    }

    return isCamPoolValid ? camPool : null;
  };

  const onReverseClicked = () => {
    const allNotes = [notes, currentNote].filter((n) => !!n).join(' | ');
    const camPoolToApply = getCamPoolToApply();
    callReverse(allNotes, isNsfReversalOption, camPoolToApply);
  };

  return (
    <Form className="modal-body" style={extraHeightStyle}>
      <div className="modal-confirm modal-confirm__withinput">
        <Row>
          <h1>{intl.formatMessage(messages.confirmReverse)}</h1>
          <Col sm={10} smOffset={1}>
            <label className="required">
              {intl.formatMessage(messages.ifYesAddNote)}
            </label>
            <textarea
              maxLength={100}
              onChange={(evt) => setNotes(evt.target.value)}
              className={'form-control'}
              rows="3"
              placeholder="(required)"
            />
          </Col>
        </Row>
        {showCamPoolFields && (
          <Row>
            <Col sm={10} smOffset={1} className="padtop20">
              <ModifyTransactionCamPoolForm
                title={intl.formatMessage(messages.selectCamPoolForReversal)}
                intl={intl}
                formValues={camFormValues}
                setFormValues={setCamFormValues}
                camPools={camPools}
                lockApplyToCamPool={true}
                compact={true}
                showApplyToCamPool={false}
              />
            </Col>
          </Row>
        )}
        {isOpsPayment && (
          <Row>
            <Col xs={11} xsOffset={1} style={{ marginTop: '10px' }}>
              <Col xs={6}>
                <label className="required">
                  {intl.formatMessage(messages.isNSFReversal)}
                </label>
              </Col>
              <Col xs={2}>
                <label>
                  <input
                    type="radio"
                    name="isNsfReversal"
                    value="yes"
                    checked={isNsfReversalOption === 'yes'}
                    onChange={(evt) => setIsNsfReversalOption(evt.target.value)}
                  />
                  &nbsp;Yes
                </label>
              </Col>
              <Col xs={2}>
                <label>
                  <input
                    type="radio"
                    name="isNsfReversal"
                    value="no"
                    checked={isNsfReversalOption === 'no'}
                    onChange={(evt) => setIsNsfReversalOption(evt.target.value)}
                  />
                  &nbsp;No
                </label>
              </Col>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={6}>
            <Button
              bsStyle={'primary'}
              className="pull-right"
              disabled={disableReverseButton}
              onClick={onReverseClicked}
            >
              {intl.formatMessage(messages.reverse)}
            </Button>
          </Col>
          <Col xs={6}>
            <Button bsStyle={'default'} className="pull-left" onClick={onClose}>
              {intl.formatMessage(messages.actualCancel)}
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default ReverseTransactionModal;
