import React from 'react';

import Table from '../../../components/Table/index.js';
import Row from '../../../components/Table/Row';
import Data from '../../../components/Table/Data';

type Props = {
  rows: Array<Object>,
  headers: Array<Object>,
  name: string,
  onSort: Function
};

export const ManagePriorResidentsDetails = ({
  rows,
  headers,
  name,
  onSort
}: Props): any => {
  return (
    <Table name={name} headers={headers} onSort={onSort}>
      {rows
        ? rows.map(
            (row: Object, key: number): any => (
              <Row
                key={key}
                className="table-row"
                data-test="prior-residents-row"
              >
                {row.columns.map((column: any, key: number) => {
                  return <Data key={key}>{column}</Data>;
                })}
              </Row>
            )
          )
        : null}
    </Table>
  );
};

export default ManagePriorResidentsDetails;
