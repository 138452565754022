import { useCallback } from 'react';
import useFetchAndSetData from './useFetchAndSetData';
import TheWorkNumberService from '../../services/theWorkNumberService';
import { toastr } from 'react-redux-toastr';

function useFetchWorkNumberEmploymentRecords(
  organizationId,
  propertyId,
  householdId,
) {
  const dataFetcher = useCallback(
    () =>
      new TheWorkNumberService().getScreeningDetails(
        organizationId,
        propertyId,
        householdId,
      ),
    [organizationId, propertyId, householdId],
  );
  const initialState = [];

  const [data, setData, isLoading, reload] = useFetchAndSetData(
    initialState,
    dataFetcher,
  );

  const submitScreening = async (applicantId: string) => {
    const twn = new TheWorkNumberService();
    try {
      const resp = await twn.submitAffordableScreening(
        propertyId,
        organizationId,
        applicantId,
      );
      // update data
      setData((prevData) => {
        const index = prevData.findIndex(
          (applicant) => applicant.id === applicantId,
        );
        if (index !== -1) {
          const newData = prevData.slice();
          newData[index].twnEmploymentHistory = [resp.employmentHistory];
          newData[index].isRescreen = false;
          return newData;
        }
        return prevData;
      });
      if (resp.employmentHistory.statusMessage !== 'SUCCESS') {
        throw resp.employmentHistory.statusMessage;
      }
      toastr.success(
        'Success!',
        'Employment Records screening completed successfully.',
      );
    } catch (error) {
      switch (error) {
        case 'Employee not found in database.':
          toastr.error(
            'Error',
            'Employee data does not exist. Ensure Name and SSN/ARN are accurate.',
          );
          break;
        case 'Bad Request':
          toastr.error(
            'Error',
            'Bad Request. Ensure Name and SSN/ARN are accurate.',
          );
          break;
        default:
          toastr.error(
            'Error',
            'Something went wrong with employment history request.',
          );
          break;
      }
      // reset data to previous
      setData(data.slice());
    }
  };

  const submitPaystubScreening = async (
    applicantId: string,
    employmentRecordId: string,
  ) => {
    const twn = new TheWorkNumberService();
    try {
      const resp = await twn.submitAffordablePaystubScreening(
        propertyId,
        organizationId,
        applicantId,
        employmentRecordId,
      );
      toastr.success(
        'Success!',
        'Paystub Details screening completed successfully.',
      );
      return resp;
    } catch (error) {
      toastr.error(
        'Error',
        'Something went wrong with employment paystub request.',
      );
      // reset data to previous
      setData(data.slice());
    }
  };

  const requestPaystubPDF = async (
    paystubDetailsId: string,
    fileName = 'paystub_details',
  ) => {
    const twn = new TheWorkNumberService();
    try {
      const resp = await twn.generatePaystubDetailsPDF(
        propertyId,
        organizationId,
        paystubDetailsId,
        fileName,
      );
      return resp;
    } catch (error) {
      toastr.error('Error', 'Something went wrong generating paystub PDF.');
    }
  };

  const actions = {
    submitScreening,
    submitPaystubScreening,
    requestPaystubPDF,
    reload,
  };

  return [data, actions, isLoading];
}

export default useFetchWorkNumberEmploymentRecords;
