// $FlowFixMe
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ConfirmOverlay from '../../../../../../components/ConfirmOverlay';
import SubmitButton from '../../../../../../components/SubmitButton';

import { uploadLease } from '../../../../actions';

import messages from './messages';

const ONE_HUNDRED_MB = 100 * 1000 * 1000;

const NotesTextArea = styled.textarea`
  border: 1px solid #d1d1d1;
`;

type UploadLeaseModalProps = {
  actions: Object,
  applicationId: string,
  isResident: boolean,
  leaseId: string,
  onHide: Function,
  profileId: string,
  show: boolean,
};

const UploadLeaseModal = ({
  actions,
  applicationId,
  isResident,
  leaseId,
  onHide,
  profileId,
  show,
}: UploadLeaseModalProps) => {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [fileInfo, setFileInfo] = useState({ filename: '', file: null });
  const [notes, setNotes] = useState('');
  const [maxFileSizeExceeded, setMaxFileSizeExceeded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = (event: Object) => {
    const filename = event.target.value.replace(/.*(\/|\\)/, '');
    const file = event.target.files[0];
    if (file.size > ONE_HUNDRED_MB) {
      setMaxFileSizeExceeded(true);
    } else {
      setFileInfo({ filename, file });
    }
  };

  const handleNoteChange = ({ target: { value } }) => {
    setNotes(value);
  };
  const hideCancelConfirmation = () => setShowCancelConfirmation(false);

  const handleHide = () => {
    if (!showCancelConfirmation) {
      setShowCancelConfirmation(true);
      return;
    }
    setShowCancelConfirmation(false);
    setIsSubmitting(false);
    if (onHide) onHide();
  };

  const handleSubmit = () => {
    const { file } = fileInfo;
    setIsSubmitting(true);
    actions.uploadLease(
      applicationId,
      file,
      leaseId,
      notes,
      profileId,
      isResident,
    );
  };

  const disableSubmittal =
    showCancelConfirmation || !fileInfo.file || maxFileSizeExceeded;

  return (
    <Modal show={show} onHide={handleHide} autoFocus>
      <Modal.Header closeButton>
        <i className="icon et-checklist" />
        <h1>
          <FormattedMessage {...messages.header} />
        </h1>
      </Modal.Header>
      <Modal.Body>
        {showCancelConfirmation && (
          <ConfirmOverlay
            title={<FormattedMessage {...messages.confirmationTitle} />}
            rejectMessage={
              <FormattedMessage {...messages.confirmationCancel} />
            }
            confirmMessage={<FormattedMessage {...messages.confirm} />}
            onReject={hideCancelConfirmation}
            onConfirm={handleHide}
          />
        )}
        <div className="row">
          <div className="col-md-7">
            <div className="form-group">
              <label className="input ">Filename</label>
              <input
                className="inputfile inputfile-custom"
                id="upload1"
                type="file"
                name="file"
                onChange={handleFileChange}
              />
              <label htmlFor="upload1">
                <span>{fileInfo.filename}</span>
                <strong>Browse</strong>
              </label>
              {maxFileSizeExceeded && (
                <span className="has-error">
                  <FormattedMessage {...messages.maxFileSizeExceeded} />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="input">Document Notes (Optional)</label>
              <NotesTextArea onChange={handleNoteChange} value={notes} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col-xs-6">
            <button
              className="btn btn-default pull-right"
              onClick={handleHide}
              disabled={showCancelConfirmation}
            >
              <FormattedMessage {...messages.cancel} />
            </button>
          </div>
          <div className="col-xs-6">
            <SubmitButton
              classes="btn btn-primary pull-left"
              clickHandler={handleSubmit}
              disabled={disableSubmittal}
              isSubmitting={isSubmitting}
            >
              <FormattedMessage {...messages.save} />
            </SubmitButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Object): Object => ({
  actions: bindActionCreators({ uploadLease }, dispatch),
});

export default connect(null, mapDispatchToProps)(UploadLeaseModal);
