import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { omit, pathOr } from 'ramda';
import { Collapse, Button } from 'react-bootstrap';
import messages from '../messages';
import ActionsMenu from '../ActionsMenu';
import type { User } from '../../App/types';
import RelativeDateField from '../../../components/RelativeDateField';

type Props = {
  users: Array<User>,
  intl: Object,
  locale: string,
  handleEditUserClick: Function
};

type State = {
  toggles: Object
};

class UserDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      toggles: {}
    };
  }

  onToggleClick = (user: User) => {
    const id = user.id || '';
    const newStateToggles = omit([id], this.state.toggles);
    newStateToggles[id] = !this.state.toggles[id];
    this.setState({
      toggles: newStateToggles
    });
  };

  renderUserDetailRow = (user: User, className: string) => {
    const id = user.id || '';
    // $FlowFixMe
    const userStatus = pathOr('---', ['userStatus', 'name'], user);

    return (
      <tr key={`${id}-RW`} className={className}>
        <td className="hidden-xs">{user.lastName}</td>
        <td className="hidden-xs">{user.firstName}</td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.username)}:
          </span>
          {user.username}
        </td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.email)}:
          </span>
          {user.emailAddress && (
            <a href={`mailto:${user.emailAddress}`}>{user.emailAddress}</a>
          )}
        </td>
        <td className="hidden-xs">
          {user.userTitle && user.userTitle.translations[this.props.locale]}
          {!user.userTitle && '---'}
        </td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.role)}:
          </span>
          {user.userRole && (
            <Link to={`/edit-role/${user.userRole.id}`} target="_blank">
              {user.userRole.name}
            </Link>
          )}
          {!user.userRole && '---'}
        </td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.status)}:
          </span>
          {userStatus}
        </td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.lastAccess)}:
          </span>
          {user.lastSystemAccess && (
            <RelativeDateField value={user.lastSystemAccess} />
          )}
          {!user.lastSystemAccess && '---'}
        </td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.properties)}:
          </span>
          {user.allProperties
            ? this.props.intl.formatMessage(messages.allProperties)
            : user.totalProperties}
        </td>
        <td className="dropdown">
          <ActionsMenu user={user} />
        </td>
        <td>
          <Button
            bsStyle="default"
            bsSize="small"
            onClick={() => this.props.handleEditUserClick(user.id)}
          >
            <i className="et-pencil" />
          </Button>
        </td>
      </tr>
    );
  };
  renderUserToggleDetail = (user: User) => {
    const id = user.id || '';
    return (
      <Collapse key={`${id}-DT`} in={this.state.toggles[id]}>
        {this.renderUserDetailRow(
          user,
          'table-section hidden-sm hidden-md hidden-lg'
        )}
      </Collapse>
    );
  };
  renderUserToggleHeader = (user: User) => {
    const id = user.id || '';
    const className = this.state.toggles[id] ? 'expanded' : 'collapsed';
    return (
      <tr
        key={`${id}-HD`}
        className="visible-xs table-section"
        onClick={() => this.onToggleClick(user)}
      >
        <th className={`visible-xs clickable ${className}`}>
          {user.lastName}, {user.firstName}
        </th>
      </tr>
    );
  };

  render() {
    return (
      <tbody>
        {this.props.users.reduce((previous, user) => {
          return previous.concat([
            this.renderUserToggleHeader(user),
            this.renderUserToggleDetail(user),
            this.renderUserDetailRow(user, 'table-section hidden-xs')
          ]);
        }, [])}
      </tbody>
    );
  }
}

export default UserDetails;
