import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import messages from './messages';
import {
  renderCurrencyField,
  renderSelectField,
} from '../../../../utils/redux-form-helper';
import validate from './validate';
import Spinner from '../../../../components/Spinner';
import { useGetTransactionTypes } from '../../hooks';
import {
  useFetchPropertyTransactionCodesForProperty,
  useFetchSubjournalsForProperty,
} from '../../../App/hooks';
import {
  getSubjournalsOptions,
  getPropertyTransactionCodesOptions,
  getTransactionTypeOptions,
} from '../../CreateTransactionBatchDetails/utils';
import { transactionCodesProvider } from '../../../../utils/transaction-helpers';

type Props = {
  history: object,
  propertyId: string,
  organizationId: string,
  onGoBackClicked: Function,
  change: Function,
};

const AddGroupHeader = (props: Props) => {
  const { propertyId, organizationId, change, onGoBackClicked } = props;

  const [selectedTransactionType, setSelectedTransactionType] = useState(null);
  const [filteredSubjournals, setFilteredSubjournals] = useState([
    { value: '', text: 'Choose', disabled: true },
  ]);
  const [filteredPTC, setFilteredPTC] = useState([
    { value: '', text: 'Choose', disabled: true },
  ]);

  const [isSubjournalDisabled, setIsSubjournalDisabled] = useState(true);
  const [isTransactionCodeDisabled, setIsTransactionCodeDisabled] =
    useState(true);
  const [transactionTypes, isTranscationTypeLoading] = useGetTransactionTypes();
  const transactionTypesOptions = getTransactionTypeOptions(transactionTypes);
  const [subjournals, isSubjournalsLoading] = useFetchSubjournalsForProperty({
    propertyId,
    organizationId,
  });
  const [propertyTransactionCodes, isPropertyTransactionCodesLoading] =
    useFetchPropertyTransactionCodesForProperty({
      propertyId,
      organizationId,
    });
  const isLoading =
    isTranscationTypeLoading ||
    isSubjournalsLoading ||
    isPropertyTransactionCodesLoading;

  // filter resident transaction codes
  const residentTransactionCodes = transactionCodesProvider(
    'Resident',
    propertyTransactionCodes,
  );

  const getFilteredSubjournals = (target, value) => {
    // reset subjournal and transactionCode dropdown fields
    change('subjournal', '');
    change('transactionCode', '');
    setFilteredPTC([{ value: '', text: 'Choose', disabled: true }]);
    const availableCodes = residentTransactionCodes.filter(
      (code) => code.transactionCode.transactionTypeId === value,
    );
    const availableSubjournals = availableCodes.map(
      (code) => code.transactionCode.subjournalId,
    );
    setFilteredSubjournals(
      getSubjournalsOptions(
        subjournals.filter((subjournal) =>
          availableSubjournals.find((e) => e === subjournal.masterSubjournalId),
        ),
      ),
    );
    setIsSubjournalDisabled(false);
    setSelectedTransactionType(value);
  };

  const getFilteredCodes = (target, value) => {
    const selectedType = transactionTypes.find((type) => {
      return type.id === selectedTransactionType;
    });
    const selectedSubjournal = subjournals.find((s) => s.id === value);
    setFilteredPTC(
      getPropertyTransactionCodesOptions(
        residentTransactionCodes
          .filter(
            (code) =>
              selectedTransactionType ===
              (code?.transactionCode?.transactionTypeId ?? ''),
          )
          .filter(
            (code) =>
              (!!selectedType && !selectedType.hasSubjournals) ||
              (selectedSubjournal &&
                (code?.transactionCode?.masterSubjournal?.id ?? '') ===
                  selectedSubjournal.masterSubjournalId),
          ),
      ),
    );
    setIsTransactionCodeDisabled(false);
  };

  return (
    <div className="container-fluid">
      <a className="btn-text" onClick={onGoBackClicked}>
        <i className="et-chevron-left" />
        <FormattedMessage {...messages.goBack} />
      </a>
      <form>
        <Row className="form-header">
          <Col md={5}>
            <h2 className="float-left">
              <FormattedMessage {...messages.addGroupTitle} />
            </h2>
          </Col>
        </Row>
        <Row className="padtop10 padleft10">
          <Col md={8} style={{ background: 'white' }}>
            <Row className="padtop20 padbottom10">
              <Col md={3}>
                {isLoading ? (
                  <Spinner small />
                ) : (
                  <Field
                    name="transactionType"
                    label="Transaction Type"
                    component={renderSelectField}
                    options={transactionTypesOptions}
                    onChange={getFilteredSubjournals}
                  />
                )}
              </Col>
              <Col md={3}>
                {isLoading ? (
                  <Spinner small />
                ) : (
                  <Field
                    name="subjournal"
                    label="Subjournal"
                    component={renderSelectField}
                    options={filteredSubjournals}
                    disabled={isSubjournalDisabled}
                    onChange={getFilteredCodes}
                  />
                )}
              </Col>
              <Col md={3}>
                {isLoading ? (
                  <Spinner small />
                ) : (
                  <Field
                    name="transactionCode"
                    label="Transaction Code"
                    component={renderSelectField}
                    options={filteredPTC}
                    disabled={isTransactionCodeDisabled}
                  />
                )}
              </Col>
              <Col md={3}>
                {isLoading ? (
                  <Spinner small />
                ) : (
                  <Field
                    name="amount"
                    label="Amount"
                    component={renderCurrencyField}
                    placeholder="0.00"
                  />
                )}
              </Col>
            </Row>
          </Col>
          <Col md={4}></Col>
        </Row>
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'AddGroupHeader',
  enableReinitialize: true,
  validate,
})(AddGroupHeader);
