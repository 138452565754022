import React, { useState, useEffect } from 'react';
import {
  Switch,
  Typography,
  Input,
  Divider,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  AlertInfoIcon,
  MoneyIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { useConfirmModal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isNil } from 'ramda';
import { removeNonNumericCharacters, formatCurrency } from '../../../../utils';
import generalMessages from '../../../App/messages';
import componentMessages from './messages';
import {
  COLLECTIONS_DEFAULT_MINIMUM_AMOUNT,
  COLLECTIONS_DEFAULT_DAYS_AFTER_FAS,
} from '../../constants';
import ConfirmationModal from './components/ConfirmationModal';
import SetupGridItem from '../../SetupGridItem';

const messages = { ...generalMessages, ...componentMessages };
type Props = {
  intl: any,
  propertyDetails: Object,
  editMode: boolean,
  onChange: Function,
  onSwitchChange: Function,
  onRadioGroupChange: Function,
  disabled: boolean,
};

const intialCollectionSetting = {
  enabled: false,
  minimumAmount: COLLECTIONS_DEFAULT_MINIMUM_AMOUNT,
  daysAfterFAS: COLLECTIONS_DEFAULT_DAYS_AFTER_FAS,
};
export const CollectionsSetupSection = (props: Props) => {
  const { intl, propertyDetails, editMode, onChange, onSwitchChange } = props;
  let { propertyCollectionSetting } = propertyDetails;
  const [collectionSettings, setCollectionSettings] = useState(
    intialCollectionSetting,
  );
  const { closeConfirmModal, openConfirmModal, openConfirm } =
    useConfirmModal(false);

  useEffect(() => {
    setCollectionSettings(propertyCollectionSetting);
    if (
      isEmpty(propertyCollectionSetting) ||
      isNil(propertyCollectionSetting)
    ) {
      setCollectionSettings(intialCollectionSetting);
    }
  }, [propertyCollectionSetting]);
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    if (value === true) {
      openConfirmModal();
      return;
    }
    setCollectionSettings({ ...collectionSettings, enabled: false });
    onSwitchChange(event);
  };

  const confirmAutomatedCollections = () => {
    setCollectionSettings({ ...collectionSettings, enabled: true });
    onSwitchChange({
      target: {
        name: 'propertyCollectionSetting.enabled',
        checked: true,
      },
    });
  };

  const handleNumericFieldChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionSettings({
      ...collectionSettings,
      [name.split('.')[1]]: removeNonNumericCharacters(value),
    });
    onChange({
      target: {
        name,
        value: removeNonNumericCharacters(value),
      },
    });
  };
  const { enabled, minimumAmount, daysAfterFAS } = collectionSettings;

  const daysBeforeInvalid = daysAfterFAS < 1 || daysAfterFAS > 999;
  const minimumAmountInvalid = minimumAmount < 100 || minimumAmount > 999;

  return (
    <>
      <Grid item xs={12}>
        <Divider sx={{ marginY: 2 }} />
        <Typography variant="h3">
          <FormattedMessage {...messages.collections} />
        </Typography>
      </Grid>
      <SetupGridItem
        TooltipProps={{
          title: intl.formatMessage(messages.collectionsTooltip),
          children: <AlertInfoIcon />,
        }}
        label={<FormattedMessage {...messages.collections} />}
      >
        {editMode ? (
          <Switch
            name="propertyCollectionSetting.enabled"
            id="collections-setting-switch"
            disabled={!editMode}
            onChange={handleSwitchChange}
            checked={enabled}
            inputProps={{ 'data-checked': enabled }}
          />
        ) : (
          <Typography color={enabled ? 'primary' : 'action.disabled'}>
            {enabled ? (
              <FormattedMessage {...messages.on} />
            ) : (
              <FormattedMessage {...messages.off} />
            )}
          </Typography>
        )}
      </SetupGridItem>
      {enabled && (
        <>
          <SetupGridItem
            label={<FormattedMessage {...messages.daysAfterFAS} />}
          >
            {editMode && enabled ? (
              <Input
                name="propertyCollectionSetting.daysAfterFAS"
                id="collection-settings-days-after-fas"
                value={daysAfterFAS}
                onChange={handleNumericFieldChange}
                required
                type="number"
                error={daysBeforeInvalid}
                helperText={
                  daysBeforeInvalid && (
                    <Typography variant="caption" color="error">
                      {intl.formatMessage(messages.daysAfterFASError)}
                    </Typography>
                  )
                }
              />
            ) : (
              <Typography>{daysAfterFAS}</Typography>
            )}
          </SetupGridItem>
          <SetupGridItem label={<FormattedMessage {...messages.minAmount} />}>
            {editMode && enabled ? (
              <Input
                name="propertyCollectionSetting.minimumAmount"
                id="collection-settings-minimum-amount"
                value={minimumAmount}
                onChange={handleNumericFieldChange}
                required
                type="number"
                startAdornment={<MoneyIcon />}
                error={minimumAmountInvalid}
                helperText={
                  minimumAmountInvalid && (
                    <Typography variant="helper" color="error">
                      {intl.formatMessage(messages.minAmountError)}
                    </Typography>
                  )
                }
              />
            ) : (
              <Typography>{formatCurrency(intl, minimumAmount)}</Typography>
            )}
          </SetupGridItem>
        </>
      )}
      <ConfirmationModal
        isOpen={openConfirm}
        closeModal={closeConfirmModal}
        confirm={confirmAutomatedCollections}
        propertyName={propertyDetails?.name}
      />
    </>
  );
};
