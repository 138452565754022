import { useState, useMemo, useEffect } from 'react';
import * as R from 'ramda';
import FileSaver from 'file-saver';
import FloorPlanService from '../../../services/floorPlanService';
import * as fileHelpers from '../../../utils/file-helpers';
import confirm from '../../../components/ConfirmDialogModal';

export function useFloorPlanImage(
  floorPlan: Object,
  propertyId: string,
  organizationId: string,
  toasterFn: Function,
  onFloorPlanImageChange: Function,
) {
  const floorPlansService = new FloorPlanService();
  const [isLoaderShown, setIsLoaderShown] = useState(false);

  const floorPlanImage = useMemo(() => {
    const imageName = R.prop('imageFileName', floorPlan);
    const uploadDate = R.prop('imageUploadDate', floorPlan);
    return imageName
      ? {
          name: fileHelpers.getFileNameWithNoTimestamp(imageName),
          uploadDate,
        }
      : undefined;
  }, [floorPlan]);

  useEffect(() => {
    setIsLoaderShown(R.isNil(floorPlan));
  }, [floorPlan]);

  const handleImageUpload = async (e: Object) => {
    const file = R.path(['target', 'files', '0'], e);

    if (R.isNil(file)) return;
    if (file.size > 100 * 1000 * 1000) {
      toasterFn({
        message: 'Floor plan image size cannot exceed 100MB.',
        title: 'Error',
        type: 'error',
      });
      return;
    }

    setIsLoaderShown(true);

    try {
      await floorPlansService.uploadImage(
        R.prop('id', floorPlan),
        file,
        organizationId,
        propertyId,
      );
      onFloorPlanImageChange();
      toasterFn({
        message: 'Image uploaded successfully.',
        title: 'Success',
      });
    } catch (error) {
      toasterFn({
        message: error.message || error.toString(),
        title: 'Error',
        type: 'error',
      });
    } finally {
      setIsLoaderShown(false);
    }
  };

  const handleDeleteImage = async () => {
    confirm('Are you sure you want to remove the image for this floor plan? ', {
      body: 'A floor plan image will not be visible on any quotes generated until a new image is uploaded.',
    }).then(async () => {
      setIsLoaderShown(true);

      try {
        await floorPlansService.update(
          R.prop('id', floorPlan),
          organizationId,
          propertyId,
          {
            imageFileName: null,
            imageUploadDate: null,
          },
        );
        onFloorPlanImageChange();
        toasterFn({
          message: 'Image deleted successfully.',
          title: 'Success',
        });
      } catch (error) {
        toasterFn({
          message: error.toString(),
          title: 'Error',
          type: 'error',
        });
      } finally {
        setIsLoaderShown(false);
      }
    });
  };

  const handleDownloadImage = async () => {
    try {
      const file = await floorPlansService.downloadImage(
        R.prop('id', floorPlan),
        organizationId,
        propertyId,
      );
      FileSaver.saveAs(file, floorPlanImage.name);
    } catch (error) {
      toasterFn({
        message: error.toString(),
        title: 'Error',
        type: 'error',
      });
    }
  };

  return {
    floorPlanImage,
    handleDeleteImage,
    handleDownloadImage,
    handleImageUpload,
    isLoaderShown,
  };
}
