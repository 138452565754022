// $FlowFixMe
import React from 'react';
import { Panel, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Header, Title } from '../../components/ProfileVisualComponents';
import IconTitle from '../../components/ProfileVisualComponents/IconTitle';

import type { GlobalState } from '../App/types';
import { PORTAL_SIGNATURE_STATUS_SIGNED } from '../../utils/lease-helpers';

import messages from './messages';
import LeaseHolderRow from './LeaseHolderRow';
import FooterMessages from './FooterMessages';

type Props = {
  leaseSignatureStatuses: Object,
};

const LeaseSigningPanel = styled(Panel).attrs({
  className: 'block block-household block-white-shadow',
})``;

const LeaseSigning = ({ leaseSignatureStatuses }: Props) => {
  const { signatures, latestLog } = leaseSignatureStatuses;
  // Detect when a single leaseHolder has not signed and set 'allSigned' to false
  const notSignedLeases = signatures.filter(
    (sig) => sig.signatureStatus !== PORTAL_SIGNATURE_STATUS_SIGNED,
  );

  return (
    <LeaseSigningPanel>
      <Header>
        <Title>
          <Row>
            <Col xs={12} sm={9}>
              <IconTitle
                icon={<i key="1" className="et-checklist" />}
                message={messages.leaseSigning}
              />
            </Col>
          </Row>
        </Title>
      </Header>
      <Panel.Body>
        <Row className="row">
          <Col xs={12}>
            <table className="table table-simple">
              <tbody>
                <tr>
                  <th>
                    <FormattedMessage {...messages.leaseholder} />
                  </th>
                  <th className="text-center">
                    <FormattedMessage {...messages.portalActive} />
                  </th>
                  <th className="text-center">
                    <FormattedMessage {...messages.leaseSigned} />
                  </th>
                </tr>
                {signatures.map((leaseHolder) => (
                  <LeaseHolderRow
                    key={leaseHolder.customerId}
                    leaseHolder={leaseHolder}
                  />
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
        <div className="panel-footer">
          <FooterMessages
            executeReady={notSignedLeases.length === 0}
            latestLog={latestLog}
          />
        </div>
      </Panel.Body>
    </LeaseSigningPanel>
  );
};

const mapStateToProps = ({ app }: GlobalState): Object => {
  const { selectedProperty } = app;
  return { selectedProperty };
};

export default connect(mapStateToProps)(LeaseSigning);
