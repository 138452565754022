const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class PayLeaseService {
  sendResidentEmails(
    organizationId: string,
    propertyId: string,
    applicationId: string,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        propertyId,
        applicationId,
      }),
    };
    return request(`${apiURL}/${organizationId}/paylease/emails`, options);
  }

  sendIndividualResidentEmail(
    organizationId: string,
    propertyId: string,
    customerId: string,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        propertyId,
        customerId,
      }),
    };
    return request(
      `${apiURL}/${organizationId}/paylease/email-resident`,
      options,
    );
  }
}

export default PayLeaseService;
