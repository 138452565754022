import { defineMessages } from 'react-intl';

const messages = defineMessages({
  type: {
    id: 'App.ViewUnit.FloorplanDetails.Type',
    defaultMessage: 'Floor Plan Type:',
  },
  bedrooms: {
    id: 'App.ViewUnit.FloorplanDetails.Bedrooms',
    defaultMessage: 'Bedroom(s)',
  },
  bathrooms: {
    id: 'App.ViewUnit.FloorplanDetails.Bathrooms',
    defaultMessage: 'Bathroom(s)',
  },
  halfBaths: {
    id: 'App.ViewUnit.FloorplanDetails.HalfBaths',
    defaultMessage: '1/2 Bathroom(s)',
  },
  minMaxOccupants: {
    id: 'App.ViewUnit.FloorplanDetails.MinMaxOccupants',
    defaultMessage: 'Min / Max Occupants',
  },
  unit: {
    id: 'App.ViewUnit.FloorplanDetails.Unit',
    defaultMessage: 'Unit',
  },
  floorplan: {
    id: 'App.ViewUnit.FloorplanDetails.Floorplan',
    defaultMessage: 'Floor Plan',
  },
  sqft: {
    id: 'App.ViewUnit.FloorplanDetails.Sqft',
    defaultMessage: 'Gross Sqft',
  },
  numberOfUnits: {
    id: 'App.ViewUnit.FloorplanDetails.NumberOfUnits',
    defaultMessage: 'Number of Units',
  },
  marketingDescription: {
    id: 'App.ViewUnit.FloorplanDetails.MarketingDescription',
    defaultMessage: 'Marketing Description',
  },
  currentIa: {
    id: 'App.ViewUnit.FloorplanDetails.CurrentIa',
    defaultMessage: 'Current IA - ',
  },
  edit: {
    id: 'App.ViewUnit.FloorplanDetails.edit',
    defaultMessage: 'Edit',
  },
});

export default messages;
