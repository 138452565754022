import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field, change } from 'redux-form';
import { isNil, propEq } from 'ramda';

import * as appSelectors from '../../App/selectors';

import {
  renderTextField,
  renderSelectField,
  renderNumberField,
  renderCheckboxField,
  required,
  requiredString,
  requiredSelect,
  petTag,
} from '../../../utils/redux-form-helper';

const PetFields = (props: any) => {
  const { petTypeOptions, fields, isPendingApplicant, leaseLocked } = props;

  return props.fields.map((field, index) => {
    const values = fields.get(index);
    const isNew = isNil(values.id);
    const selectedPetType = petTypeOptions.find(
      propEq('value', values.petTypeId),
    ) || { petBreeds: [] };
    const weightPlaceholder = selectedPetType.requiresWeight
      ? 'Required'
      : 'Optional';
    const petBreedOptions = selectedPetType.petBreeds;
    return [
      isPendingApplicant ? (
        <div className="col-xs-12">
          <h4>Pending Pet(s)</h4>
        </div>
      ) : (
        undefined
      ),
      <div className="labels-head container-of-inputs" key={field}>
        <div className="col col-xs-6 col-sm-2">
          <Field
            name={`${field}.name`}
            component={renderTextField}
            validate={[required, requiredString]}
            disabled={(!isNew && !isPendingApplicant) || leaseLocked}
          />
        </div>
        <div className="col col-xs-6 col-sm-2">
          <Field
            name={`${field}.petTypeId`}
            component={renderSelectField}
            validate={requiredSelect}
            options={petTypeOptions}
            onChange={() =>
              props.change(
                'editResidentHousehold',
                `${field}.petBreedId`,
                'default',
              )
            }
            disabled={(!isNew && !isPendingApplicant) || leaseLocked}
          />
        </div>
        <div className="col col-xs-6 col-sm-2">
          <Field
            name={`${field}.petBreedId`}
            component={renderSelectField}
            options={petBreedOptions}
            disabled={(!isNew && !isPendingApplicant) || leaseLocked}
          />
        </div>
        <div className="col col-xs-6 col-sm-1">
          <Field
            name={`${field}.weight`}
            component={renderNumberField}
            disabled={(!isNew && !isPendingApplicant) || leaseLocked}
            placeholder={weightPlaceholder}
            validate={selectedPetType.requiresWeight ? required : []}
            showLabel
          />
        </div>
        <div className="col col-xs-6 col-sm-2">
          <Field
            name={`${field}.petTag`}
            component={renderTextField}
            disabled={(!isNew && !isPendingApplicant) || leaseLocked}
            validate={petTag}
          />
        </div>
        <div className="col col-xs-6 col-sm-1 text-center">
          <Field
            name={`${field}.isServiceAnimal`}
            component={renderCheckboxField}
            disabled={(!isNew && !isPendingApplicant) || leaseLocked}
          />
        </div>
      </div>,
      <div className="container-of-trash" key={`${field}-trash`}>
        <div className="row-remove-btn">
          <a onClick={() => props.deletePet(index)} disabled={leaseLocked}>
            <i className="et-trash" />
          </a>
        </div>
      </div>,
    ];
  });
};

export default connect(
  createStructuredSelector({
    petTypeOptions: appSelectors.getPetTypeOptions,
  }),
  { change },
)(PetFields);
