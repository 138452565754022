import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { isNil, omit, pick } from 'ramda';

import PropertyService from '../../../services/propertyService';
import PropertyClassService from '../../../services/propertyClassService';
import PropertyGroupService from '../../../services/propertyGroupService';
import { parseProperties } from '../utils';

import type { PropertyGroup as DbPropertyGroup } from '../../App/types';
import type { Option } from '../types';

import generalMessages from '../../App/messages';
import componentMessages from '../messages';

const messages = { ...generalMessages, ...componentMessages };

export const useFetchProperties = ({
  organizationId,
  promptToaster,
  intl,
}: Object) => {
  const propertyService = useMemo(() => {
    return new PropertyService();
  }, []);

  return useQuery(
    'ManagePropertyGroups > Get Properties',
    async () => {
      const rawProperties =
        await propertyService.getActivePropertiesWithPropertyClass(
          organizationId,
        );

      return parseProperties(rawProperties ?? []);
    },
    {
      staleTime: 60000,
      onError: (error: any) => {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.error),
          message: error.toString(),
        });
      },
    },
  );
};

export const useFetchPropertyClasses = ({
  promptToaster,
  intl,
  locale,
}: Object) => {
  const propertyClassService = useMemo(() => {
    return new PropertyClassService();
  }, []);

  return useQuery(
    'ManagePropertyGroups > Get Property Classes',
    async () => {
      const propertyClasses = await propertyClassService.getAll();
      const options: Array<Option<string>> = [
        { id: 'all', name: intl.formatMessage(messages.all) },
        ...(propertyClasses ?? [])
          .concat({
            id: 'commercial',
            name: intl.formatMessage(messages.commercial),
          })
          .sort((a, b) => {
            const aField = a?.translations?.[locale] ?? a?.name;
            const bField = b?.translations?.[locale] ?? b?.name;
            return aField.localeCompare(bField, locale ?? 'en', {
              ignorePunctuation: true,
              numeric: true,
              sensitivity: 'base',
            });
          }),
      ].map((pc) => ({
        value: pc.id,
        text: pc?.translations?.[locale] ?? pc?.name,
      }));

      return options;
    },
    {
      staleTime: 5 * 60 * 1000,
      onError: (error: any) => {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.error),
          message: error.toString(),
        });
      },
    },
  );
};

export const useCreatePropertyGroup = ({ promptToaster, intl }: Object) => {
  const propertyGroupService = useMemo(() => {
    return new PropertyGroupService();
  }, []);

  const { mutate: mutateCreatePropertyGroup, isLoading: isCreateSubmitting } =
    useMutation(
      ({ organizationId, payload }: Object) => {
        const createPayload = {
          propertyGroup: omit(
            ['properties', 'propertyIds', 'selectAll'],
            payload,
          ),
          propertyIds: payload?.propertyIds ?? [],
        };
        return propertyGroupService.create(createPayload, organizationId);
      },
      {
        onError: (error: any) => {
          promptToaster({
            type: 'error',
            title: intl.formatMessage(messages.error),
            message: error.toString(),
          });
        },
      },
    );

  return [mutateCreatePropertyGroup, isCreateSubmitting];
};

export const useFetchPropertyGroup = ({
  propertyGroupId,
  organizationId,
  promptToaster,
  intl,
  locale,
}: Object) => {
  const propertyGroupService = useMemo(() => {
    return new PropertyGroupService();
  }, []);

  return useQuery(
    'ManagePropertyGroups > Get Property Group',
    async () => {
      const propertyGroup: DbPropertyGroup = isNil(propertyGroupId)
        ? {}
        : await propertyGroupService.getById(propertyGroupId, organizationId);

      return propertyGroup;
    },
    {
      staleTime: 5 * 60 * 1000,
      onError: (error: any) => {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.error),
          message: error.toString(),
        });
      },
    },
  );
};

export const useUpdatePropertyGroup = ({
  callbackFn,
  promptToaster,
  intl,
}: Object) => {
  const propertyGroupService = useMemo(() => {
    return new PropertyGroupService();
  }, []);

  const { mutate: mutateUpdatePropertyGroup, isLoading: isUpdateSubmitting } =
    useMutation(
      ({ organizationId, propertyGroupId, payload }: Object) => {
        const updatePayload = {
          ...pick(['name', 'description'], payload),
          propertyIds: payload?.propertyIds ?? [],
        };
        return propertyGroupService.update(
          updatePayload,
          propertyGroupId,
          organizationId,
        );
      },
      {
        onError: (error: any) => {
          promptToaster({
            type: 'error',
            title: intl.formatMessage(messages.error),
            message: error.toString(),
          });
        },
        onSuccess: () => {
          promptToaster({
            type: 'success',
            title: intl.formatMessage(messages.success),
            message: intl.formatMessage(messages.propertyGroupUpdateSuccess),
          });
          callbackFn();
        },
      },
    );

  return [mutateUpdatePropertyGroup, isUpdateSubmitting];
};
