import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  Field,
  getFormValues,
  isPristine,
  isValid,
  reduxForm,
} from 'redux-form';
import styled from 'styled-components';
import { useModal } from '../../../hooks/useModal';
import type { FeatureFlags } from '../../../types';
import {
  renderBlockDataDateField,
  renderBlockDataTextField,
  renderRadioButton,
} from '../../../utils/redux-form-helper';
import { getSelectedPropertyName } from '../../App/selectors';
import EmailModal from './EmailModal';
import { useDownloadProrateCalcScreen } from './hooks';
import messages from './messages';
import validate from './validate';

type Props = {
  organizationId: string,
  propertyId: string,
  initialValues: Object,
};

const BlockWhiteShadowContainer = styled.div`
  border-radius: 5px;
  .block-white-shadow {
    box-shadow: initial;
    text-align: initial;
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  }
  .block-white-shadow h5 {
    font-size: 1.1em;
    text-transform: initial;
  }
  label {
    font-size: 1.1em;
  }
`;

const ProrateCalculatorHeader = (props: Props) => {
  const {
    organizationId,
    propertyId,
    formValues,
    headerFormValues,
    isHeaderFormValid,
    isFormValid,
    isFormPristine,
    propertyName,
  } = props;

  const { prorateCalculatorEmail }: FeatureFlags = useFlags();

  const handleDownload = useDownloadProrateCalcScreen({
    organizationId,
    propertyId,
    formValues: formValues,
    headerFormValues,
  });

  const enableActions = isHeaderFormValid && isFormValid && !isFormPristine;

  const [showEmailModal, openEmailModal, closeEmailModal] = useModal();

  return (
    <>
      {prorateCalculatorEmail && (
        <EmailModal
          show={showEmailModal}
          onHide={closeEmailModal}
          organizationId={organizationId}
          propertyId={propertyId}
          headerFormValues={headerFormValues}
          propertyName={propertyName}
          formValues={formValues}
        />
      )}
      <div className="container-fluid">
        <Row>
          <Col xs={12} md={6}>
            <h1 className="padtop30">
              <i className="text-blue icons et-calculator padright10" />
              <FormattedMessage {...messages.header} />
            </h1>
          </Col>
          <Col xs={12} md={6}>
            <button
              className="button-filter btn-default btn-tertiary pull-right btn"
              disabled={!enableActions}
              onClick={handleDownload}
            >
              <i className="et-download" />
            </button>
            {prorateCalculatorEmail && (
              <button
                className="button-filter btn-default btn-tertiary pull-right btn"
                style={{ marginRight: 16 }}
                disabled={!enableActions}
                onClick={openEmailModal}
              >
                <i className="et-mail" />
              </button>
            )}
          </Col>
        </Row>
        <BlockWhiteShadowContainer
          className="block-white-shadow"
          style={{ paddingLeft: '25px' }}
        >
          <Row>
            <h2 style={{ marginTop: '20px' }}>Transaction Details</h2>
          </Row>
          <Row>
            <hr style={{ margin: '0px' }} />
          </Row>
          <Row>
            <form>
              <div
                style={{
                  display: 'flex',
                  paddingRight: '10%',
                  alignItems: 'center',
                  height: '160px',
                }}
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ display: 'flex' }}>
                    <Field
                      name="moveInMoveOut"
                      fieldName="moveInMoveOut"
                      option={{ value: 'moveIn' }}
                      component={renderRadioButton}
                      disabled={false}
                    />
                    <label className="padleft10">Move-In</label>
                  </div>
                  <div style={{ display: 'flex', marginLeft: '40px' }}>
                    <Field
                      name="moveInMoveOut"
                      fieldName="moveInMoveOut"
                      option={{ value: 'moveOut' }}
                      component={renderRadioButton}
                      disabled={false}
                    />
                    <label className="padleft10">Move-Out</label>
                  </div>
                </div>
                <Field
                  name="moveDate"
                  label="Move Date"
                  required
                  component={renderBlockDataDateField}
                  readOnly={false}
                  inputProps={{ placeholder: 'MM/DD/YYYY', autoFocus: true }}
                />
                <Field
                  name="unitNumber"
                  label="Unit Number"
                  component={renderBlockDataTextField}
                  placeholder="(Optional)"
                  readOnly={false}
                />
                <Field
                  name="leaseholderName"
                  label="Leaseholder Name"
                  component={renderBlockDataTextField}
                  placeholder="(Optional)"
                  readOnly={false}
                />
              </div>
            </form>
          </Row>
        </BlockWhiteShadowContainer>
      </div>
    </>
  );
};

const mapStateToProps = (state: GlobalState): Object => {
  const formValues = getFormValues('prorateCalculatorForm')(state);
  const headerFormValues = getFormValues('ProrateCalculatorHeaderForm')(state);
  const isHeaderFormValid = isValid('ProrateCalculatorHeaderForm')(state);
  const isFormValid = isValid('prorateCalculatorForm')(state);
  const isFormPristine = isPristine('prorateCalculatorForm')(state);
  const propertyName = getSelectedPropertyName(state);

  return {
    formValues,
    headerFormValues,
    isHeaderFormValid,
    isFormValid,
    isFormPristine,
    propertyName,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'ProrateCalculatorHeaderForm',
    enableReinitialize: true,
    validate,
  })(ProrateCalculatorHeader),
);
