import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from './messages';

type Props = {
  onHide: Function,
  onConfirm: Function,
  totalTransactions: number,
  totalHouseholds: number,
  totalBatchAmount: number,
  isAllCommercial: boolean,
};

const ConfirmPostBatchModal = (props: Props) => {
  const {
    onHide,
    onConfirm,
    totalTransactions,
    totalHouseholds,
    totalBatchAmount,
    isAllCommercial,
  } = props;

  const pluralTransaction = totalTransactions > 1 ? 's' : '';
  const pluralHouseholds = totalHouseholds > 1 ? 's' : '';

  return (
    <div className="static-modal">
      <Modal
        bsSize="small"
        dialogClassName="modal-dialog modal-alert"
        show={true}
        onHide={onHide}
        autoFocus={true}
        handleSubmit={onConfirm}
      >
        <Modal.Body>
          <Row style={{ marginTop: '20px' }}>
            <Col xs={12}>
              <h1 className="text-center">
                <FormattedMessage {...messages.title} />
              </h1>

              <p className="text-center" data-test="modal-message">
                The following action will create{' '}
                <b>
                  {totalTransactions} transaction{pluralTransaction}
                </b>{' '}
                for the{' '}
                <b>
                  {totalHouseholds} {isAllCommercial ? 'unit' : 'household'}
                  {pluralHouseholds}
                </b>{' '}
                selected and will add{' '}
                <b>
                  <FormattedNumber
                    className="pull-right"
                    value={totalBatchAmount}
                    style={{ value: 'currency' }.value}
                    currency="USD"
                    data-test="modal-total-amount"
                  />
                </b>{' '}
                as a total to the selected{' '}
                {isAllCommercial ? "tenants'" : "residents'"} ledgers. This
                action cannot be reversed. Are you sure you want to proceed?
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row style={{ marginBottom: '20px' }}>
            <Col xs={6}>
              <Button className="btn btn-default pull-right" onClick={onHide}>
                <FormattedMessage {...messages.no} />
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="btn btn-primary pull-left"
                onClick={onConfirm}
                variant="primary"
              >
                <FormattedMessage {...messages.yes} />
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmPostBatchModal;
