import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { AddIcon, EditLink } from '../../../components/ProfileVisualComponents';
import DashesIfNullOrUndefined from '../../../components/DashesIfNullOrUndefined';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import type { Contact } from './types';
import { phoneFormatter } from '../../../utils/redux-form-helper';

type OnAddClickFn = Function;
type OnNameClickFn = (c: Contact) => void;

const contactsPermissions = ['resident-edit'];

type SectionHeaderProps = {
  onAddClick: OnAddClickFn,
};

const SectionHeader = ({ onAddClick }: SectionHeaderProps) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 13px 8px 13px',
    }}
  >
    <div>
      <i className="icon et-contacts" style={{ marginRight: 5 }} />
      <FormattedMessage {...messages.contacts} />
    </div>
    <div>
      <ElementWithPermissions scope={contactsPermissions}>
        <EditLink onClick={onAddClick}>
          <AddIcon />
          <FormattedMessage {...messages.add} />
        </EditLink>
      </ElementWithPermissions>
    </div>
  </div>
);

type ContactsCheckboxProps = {
  checked: boolean,
  disabled: boolean,
  onClick: Function,
};

export const ContactsCheckbox = ({
  checked,
  disabled,
  onClick,
}: ContactsCheckboxProps) => (
  <tr>
    <td style={{ padding: '0 0 0 3px' }}>
      <div className="custom-checkbox" style={{ marginBottom: '5px' }}>
        <label>
          <input
            data-testid="contacts-checkbox"
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onClick={onClick}
          />
          <span className="custom-check-square" />
          <FormattedMessage {...messages.noContacts} />
        </label>
      </div>
    </td>
  </tr>
);

type ContactsProps = {
  contacts: Contact[],
  onNameClick: OnNameClickFn,
};

const Contacts = ({ contacts, onNameClick }: ContactsProps) => (
  <React.Fragment>
    <tr>
      <th>
        <FormattedMessage {...messages.contactName} />
      </th>
      <th>
        <FormattedMessage {...messages.contactType} />
      </th>
      <th>
        <FormattedMessage {...messages.contactPhone} />
      </th>
    </tr>
    {contacts.map((contact) => (
      <tr key={contact.id}>
        <td>
          <ElementWithPermissions scope={contactsPermissions}>
            <a onClick={() => onNameClick(contact)}>
              <DashesIfNullOrUndefined data={contact.contactName} />
            </a>
          </ElementWithPermissions>
        </td>
        <td>
          <DashesIfNullOrUndefined data={contact.commercialContactType.name} />
        </td>
        <td>
          <DashesIfNullOrUndefined
            data={phoneFormatter(contact.phoneNumber.toString())}
          />
        </td>
      </tr>
    ))}
  </React.Fragment>
);

type ContactsSectionProps = {
  contacts: Contact[],
  onAddClick: OnAddClickFn,
  onNameClick: OnNameClickFn,
  checkboxChecked: boolean,
  disableCheckbox: boolean,
  onCheckboxClick: Function,
};

const ContactsSection = ({
  contacts = [],
  onAddClick,
  onNameClick,
  checkboxChecked,
  disableCheckbox,
  onCheckboxClick,
}: ContactsSectionProps) => {
  const displayCheckbox = contacts.length === 0;

  return (
    <React.Fragment>
      <SectionHeader onAddClick={onAddClick} />
      <table className="table table-simple">
        <tbody>
          {displayCheckbox ? (
            <ContactsCheckbox
              checked={checkboxChecked}
              disabled={disableCheckbox}
              onClick={onCheckboxClick}
            />
          ) : (
            <Contacts contacts={contacts} onNameClick={onNameClick} />
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default ContactsSection;
