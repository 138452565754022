import { defineMessages } from 'react-intl';

/* eslint-disable max-len */
const messages = defineMessages({
  title: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Title',
    defaultMessage: 'The Work Number',
  },
  titleNote: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Title.Note.New.New',
    defaultMessage:
      'Cannot screen individual leaseholders until their Name and SSN/ARN on application are saved.',
  },
  employersName: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.EmployerName',
    defaultMessage: "Employer's Name",
  },
  jobTitle: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.JobTitle',
    defaultMessage: 'Job Title',
  },
  datesEmployed: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.DatesEmployed',
    defaultMessage: 'Dates Employed',
  },
  paystubDetails: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.PaystubDetails',
    defaultMessage: 'Paystub Details',
  },
  cancel: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.Cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.Confirm',
    defaultMessage: 'Confirm',
  },
  modalHeader: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.ModalHeader',
    defaultMessage: 'Are you sure you want to submit this screening?',
  },
  confirmTextBold: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.ConfirmTextBold',
    defaultMessage: 'I understand and certify:',
  },
  confirmScreeningRequest: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Screening.Request.Confirm',
    defaultMessage:
      'I am requesting employment records for this leaseholder because I have not received the proof of documentation from this leaseholder',
  },
  confirmMessageTwo: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.ConfirmMessageTwo',
    defaultMessage: '2. This screening will incur a fee to my property.',
  },
  confirmPaystubMessageOne: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.ConfirmPaystubMessageOne',
    defaultMessage:
      '1. I am requesting additional paystub details for this employment record because I have not received the proof of documentation from this leaseholder',
  },
  confirmPaystubMessageNote: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.ConfirmPaystubMessageNote',
    defaultMessage:
      'Note: Only users with permission can submit more than one Paystub Details screening',
  },
  lastDateOfPay: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.LastDateOfPay',
    defaultMessage: 'Last Date of Pay',
  },
  employmentStatus: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.EmploymentStatus',
    defaultMessage: 'Employment Status',
  },
  activeRecord: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.ActiveRecord',
    defaultMessage: 'Active Record',
  },
  inactiveRecord: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Details.InactiveRecord',
    defaultMessage: 'Inactive Record',
  },
  failedPdfAutoGen: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Toaster.FailedPdfAutoGen',
    defaultMessage: 'PDF Autogeneration Failed',
  },
  pleaseClickDownload: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Toaster.PleaseClickDownload',
    defaultMessage:
      'Please click on the download button to download report manually.',
  },
  missingSsnArn: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Error.MissingSsnArn',
    defaultMessage: 'Error: SSN/ARN missing',
  },
  missingEmployee: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.TheWorkNumber.Error.MissingEmployee',
    defaultMessage:
      'Employee data does not exist. Ensure Name and SSN/ARN are accurate.',
  },
});

export default messages;
