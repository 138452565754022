import { useState, useEffect } from 'react';

/**
 * Hook to manage the checkboxes column state
 * ---
 * @param {*} param0
 * @returns
 */
const useCheckboxColumn = ({ parsedResults, grossRentEnhancementsFlag }) => {
  const [checkboxList, setCheckboxList] = useState([]);
  const initAndfillArrayWith = (value) =>
    [...Array(parsedResults?.length).keys()].map(() => value);

  useEffect(() => {
    setCheckboxList(initAndfillArrayWith(false));
  }, [parsedResults, grossRentEnhancementsFlag]);

  const handleSelectDeSelectAllRows = (event) => {
    event.persist();
    const checked = event.target.checked;
    setCheckboxList(
      checked ? initAndfillArrayWith(true) : initAndfillArrayWith(false),
    );
  };

  const handleSelectRow = (index) => {
    setCheckboxList((prev) =>
      prev.map((value, i) => (i === index ? !value : value)),
    );
  };

  return {
    checkboxList,
    handleSelectDeSelectAllRows,
    handleSelectRow,
  };
};

export default useCheckboxColumn;
