import { defineMessages } from 'react-intl';

const messages = defineMessages({
  returnToResidentProfile: {
    id: 'App.ResidentProfile.ResidentMoveOut.ReturnToResidentProfile',
    defaultMessage: 'Go Back to Residents Profile',
  },
  returnToTenantProfile: {
    id: 'App.ResidentProfile.ResidentMoveOut.ReturnToTenantProfile',
    defaultMessage: 'Go Back to Tenants Profile',
  },
  confirmTitle: {
    id: 'App.ResidentProfile.ResidentMoveOut.ConfirmTitle',
    defaultMessage:
      'Are You Sure You Want to Move This Resident out of the Unit?',
  },
  confirmTitleTenant: {
    id: 'App.ResidentProfile.ResidentMoveOut.ConfirmTitleTenant',
    defaultMessage:
      'Are You Sure You Want to Move This Tenant out of the Unit?',
  },
  unitNumber: {
    id: 'App.ResidentProfile.ResidentMoveOut.UnitNumber',
    defaultMessage: 'Unit Number',
  },
  moveOutDate: {
    id: 'App.ResidentProfile.ResidentMoveOut.MoveOutDate',
    defaultMessage: 'Confirm Move-Out date',
  },
  stepsCompleted: {
    id: 'App.ResidentProfile.ResidentMoveOut.StepsCompleted',
    defaultMessage: 'Steps completed',
  },
  noticeToVacateEntered: {
    id: 'App.ResidentProfile.ResidentMoveOut.NoticeToVacateEntered',
    defaultMessage: 'Notice to Vacate Entered',
  },
  entered: {
    id: 'App.ResidentProfile.ResidentMoveOut.Entered',
    defaultMessage: 'Entered',
  },
  moveOutActionsCompleted: {
    id: 'App.ResidentProfile.ResidentMoveOut.MoveOutActionsCompleted',
    defaultMessage: 'Move-Out Actions Completed',
  },
  paidAmountDue: {
    id: 'App.ResidentProfile.ResidentMoveOut.PaidAmountDue',
    defaultMessage: 'Resident Paid Amount Due at Move-Out',
  },
  paidAmountDueTenant: {
    id: 'App.ResidentProfile.ResidentMoveOut.PaidAmountDueTenant',
    defaultMessage: 'Tenant Paid Amount Due at Move-Out',
  },
  turnedInKeys: {
    id: 'App.ResidentProfile.ResidentMoveOut.TurnedInKeys',
    defaultMessage: 'Turned In keys',
  },
  moveOutChecklistCompleted: {
    id: 'App.ResidentProfile.ResidentMoveOut.MoveOutChecklistCompleted',
    defaultMessage: 'Move-Out checklist completed',
  },
  cancel: {
    id: 'App.ResidentProfile.ResidentMoveOut.Cancel',
    defaultMessage: 'Cancel',
  },
  moveResidentOutOfThisUnit: {
    id: 'App.ResidentProfile.ResidentMoveOut.MoveResidentOutOfThisUnit',
    defaultMessage: 'Move Resident Out of this Unit',
  },
  moveTenantOutOfThisUnit: {
    id: 'App.ResidentProfile.ResidentMoveOut.MoveTenantOutOfThisUnit',
    defaultMessage: 'Move Tenant Out of this Unit',
  },
  invalidDateFormat: {
    id: 'App.ResidentProfile.ResidentMoveOut.InvalidDateFormat',
    defaultMessage: 'Invalid date format.',
  },
  invalidDateValue: {
    id: 'App.ResidentProfile.ResidentMoveOut.InvalidDateValue',
    defaultMessage: 'Invalid date value, must be in the past 30 days',
  },
  required: {
    id: 'App.ResidentProfile.ResidentMoveOut.required',
    defaultMessage: 'Required',
  },
  invalidProRatedCredit: {
    id: 'App.ResidentProfile.ResidentMoveOut.invalidProRatedCredit',
    defaultMessage: 'Invalid value format',
  },
  note: {
    id: 'App.ResidentProfile.ResidentMoveOut.Note',
    defaultMessage: 'Note',
  },
  proRateNote: {
    id: 'App.ResidentProfile.ResidentMoveOut.ProRateNote',
    defaultMessage:
      // eslint-disable-next-line max-len
      'If these charges are not correct, you can correct by (1) closing this screen and updating the Monthly Lease Charges or Credits on the Lease Data Tab or (2) reversing the charges/credits from the ledger after move-out. ',
  },
  proRateAmountDateNote: {
    id: 'App.ResidentProfile.ResidentMoveOut.ProRateAmountDateNote',
    defaultMessage: 'IMPORTANT: This date must be the actual date of move-out.',
  },
  proRateAmountsToLedgerHeader: {
    id: 'App.ResidentProfile.ResidentMoveOut.ProRateAmountsToLedgerHeader',
    defaultMessage: '{type} amounts posting to ledger:',
  },
  proRate: {
    id: 'App.ResidentProfile.ResidentMoveOut.ProRate',
    defaultMessage: 'Prorate',
  },
  full: {
    id: 'App.ResidentProfile.ResidentMoveOut.Full',
    defaultMessage: 'Full',
  },
  roundProratedAmounts: {
    id: 'App.ResidentProfile.ResidentMoveOut.roundProratedAmounts',
    defaultMessage:
      'This property is configured to round prorated amounts to the nearest dollar.',
  },
});

export default messages;
