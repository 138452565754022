import React from 'react';

export const GET_ALL_KPIS = 'app/Overview/GET_ALL_KPIS';
export const GET_ALL_KPIS_SUCCESS = 'app/Overview/GET_ALL_KPIS_SUCCESS';
export const GET_ALL_KPIS_ERROR = 'app/Overview/GET_ALL_KPIS_ERROR';

export const GET_KPI = 'app/Overview/GET_KPI';
export const GET_KPI_SUCCESS = 'app/Overview/GET_KPI_SUCCESS';

export const CLEAN_LOADED_KPIS = 'app/Overview/CLEAN_LOADED_KPIS';

export const SPINNER = (
  <div className="spinner" data-testid="spinner">
    <div className="spinnerbar spnbar1" />
    <div className="spinnerbar spnbar2" />
    <div className="spinnerbar spnbar3" />
    <div className="spinnerbar spnbar4" />
    <div className="spinnerbar spnbar5" />
  </div>
);

export const OPTIONS = [
  { value: '0', text: '7 Days' },
  { value: '1', text: '30 Days' },
  { value: '2', text: '60 Days' },
  { value: '3', text: '90 Days' },
  { value: '4', text: 'End Of Month' },
];

export const USER_KPIS = [
  'evictions',
  'occupancy',
  'netOccupancy',
  'concessions',
  'workOrders',
  'makeReadies',
  'vacantNotLeasedByFloorPlan',
  'income',
  'currentMonthRenewals',
  'activity',
  'leaseExpirationExposure',
];

export const OWNER_KPIS = [...USER_KPIS, 'delinquency'];

export const LEASE_EXPIRATION_EXPOSURE_OPTIONS = [
  { value: '12', text: '12 Months' },
  { value: '18', text: '18 Months' },
  { value: '24', text: '24 Months' },
];

export const DEFAULT_LEASE_EXPIRATION_EXPOSURE_MONTHS = '12';
