import { isNil } from 'ramda';

const { put, get, post, del, patch } = require('../utils/api');

export default class CamRecordService {
  getAll(
    organizationId: string,
    propertyId: string,
    householdId: string,
    signal?: Object,
  ) {
    const options = signal ? { signal } : {};
    return get(
      `/${organizationId}/${propertyId}/households/${householdId}/cam-records`,
      options,
    );
  }

  add(
    organizationId: string,
    propertyId: string,
    householdId: string,
    payload: Object,
  ) {
    return post(
      `/${organizationId}/${propertyId}/households/${householdId}/cam-records`,
      JSON.stringify(payload),
    );
  }

  remove(
    organizationId: string,
    propertyId: string,
    householdId: string,
    camRecordId: string,
    transactionsToReassign?: Array<Object>,
  ) {
    const payload = isNil(transactionsToReassign)
      ? {}
      : { transactionsToReassign };
    return del(
      `/${organizationId}/${propertyId}/households/${householdId}/cam-records/${camRecordId}`,
      JSON.stringify(payload),
    );
  }

  modify(
    organizationId: string,
    propertyId: string,
    householdId: string,
    camRecordId: string,
    payload: Object,
  ) {
    return put(
      `/${organizationId}/${propertyId}/households/${householdId}/cam-records/${camRecordId}`,
      JSON.stringify(payload),
    );
  }

  getMonthlyCharges(
    organizationId: string,
    propertyId: string,
    camRecordId: string,
    signal?: Object,
  ) {
    const options = signal ? { signal } : {};
    return get(
      `/${organizationId}/${propertyId}/cam-records/${camRecordId}/monthly-charges`,
      options,
    );
  }

  updateMonthlyCharges(
    newAmount: number,
    newStartDate: Date,
    endDate: Date,
    organizationId: string,
    propertyId: string,
    camCalculationsHouseholdMonthlyTransactionsId: string,
    options?: Object,
  ) {
    return patch(
      `/${organizationId}/${propertyId}/cam-records/${camCalculationsHouseholdMonthlyTransactionsId}/monthly-charges`,
      JSON.stringify({ amount: newAmount, startDate: newStartDate, endDate }),
      options,
    );
  }

  getStatus(
    organizationId: string,
    propertyId: string,
    camRecordId: string,
    signal?: Object,
  ) {
    const options = signal ? { signal } : {};
    return get(
      `/${organizationId}/${propertyId}/cam-records/${camRecordId}/status`,
      options,
    );
  }

  startNewCam(
    organizationId: string,
    propertyId: string,
    camRecordId: string,
    payload: Object,
  ) {
    return post(
      `/${organizationId}/${propertyId}/cam-records/${camRecordId}/start-new-cam`,
      JSON.stringify(payload),
    );
  }

  getCamPools(
    organizationId: string,
    propertyId: string,
    householdId: string,
    includeUnconfirmed: boolean,
    signal?: Object,
  ) {
    return get(
      // eslint-disable-next-line max-len
      `/${organizationId}/${propertyId}/households/${householdId}/cam-pools-grouped-by-cam?includeUnconfirmed=${includeUnconfirmed}`,
    );
  }

  getRetroactiveTransactions(
    organizationId: string,
    propertyId: string,
    camRecordsId: string,
  ) {
    return get(
      `/${organizationId}/${propertyId}/cam-records/${camRecordsId}/retroactive-transactions`,
    );
  }

  getTransactions(
    organizationId: string,
    propertyId: string,
    camRecordsId: string,
  ) {
    return get(
      `/${organizationId}/${propertyId}/cam-records/${camRecordsId}/transactions`,
    );
  }
}
