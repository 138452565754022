import { validateDate, validateRequired } from '../../../utils/validations';
import moment from 'moment';

const validate = (values: Object, props: Object) => {
  const errors = {};
  const { batchDate, documentNumber, batchNote } = values;

  /**
   * Required Fields
   */
  ['batchDate', 'batchTotal', 'documentNumber', 'batchNote'].forEach(
    (fieldName) => {
      if (!validateRequired(fieldName, values)) {
        errors[fieldName] = 'This field is required';
      }
    },
  );
  /**
   * Valid Dates
   */
  if (!errors['batchDate']) {
    if (!validateDate(batchDate)) {
      errors['batchDate'] = 'Date must be a valid date format (MM/DD/YYYY).';
    } else if (
      moment(batchDate).isBefore(moment().subtract(30, 'days')) ||
      moment(batchDate).isAfter(moment().add(30, 'days'))
    ) {
      errors['batchDate'] =
        'Date can be up to 30 days in the past or future from today.';
    }
  }

  if (!errors['documentNumber'] && documentNumber.length > 25) {
    errors['documentNumber'] = 'Maximum Length is 25 characters.';
  }
  if (!errors['batchNote'] && batchNote.length > 100) {
    errors['batchNote'] = 'Maximum Length is 100 characters.';
  }

  return errors;
};

export default validate;
