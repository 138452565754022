import React from 'react';

type MarginYProps = {
  value: number,
};

export const MarginY = ({ value }: MarginYProps) => (
  <div
    style={{
      marginBottom: value >= 0 ? value : 0,
      marginTop: value < 0 ? value : 0,
    }}
  />
);
