import { range, pathOr, is } from 'ramda';
import moment from 'moment';

export const sortAlpha = (stringArray: string[]) => {
  return stringArray.sort((a, b) => a.localeCompare(b));
};

export const sortAndFilterRows = (
  rows: Array<Object>,
  field: string,
  dir: string = 'ASC',
  searchStr: string = '',
): Array<Object> => {
  let filteredRows = rows;
  if (searchStr) {
    filteredRows = rows.filter((row) => {
      const columns = Object.values(row);
      for (let i = 0; i < columns.length; i++) {
        const column = columns[i];
        const notNull = column !== null && column !== '---';
        const isString = typeof column === 'string';
        // $FlowFixMe
        if (notNull && isString && RegExp(searchStr, 'gi').test(column)) {
          return true;
        }
      }
      return false;
    });
  }
  const isAscending = dir === 'ASC';
  let sortedRows = filteredRows;
  if (field !== '') {
    sortedRows = filteredRows.sort((a: Object, b: Object): number => {
      const aVal = pathOr('', [field], a);
      const bVal = pathOr('', [field], b);

      if (field.match(/Date$/i)) {
        const aDate =
          aVal === ''
            ? moment('01/01/1970', 'MM/DD/YYYY').unix()
            : moment(aVal).unix();
        const bDate =
          bVal === ''
            ? moment('01/01/1970', 'MM/DD/YYYY').unix()
            : moment(bVal).unix();
        return isAscending ? aDate - bDate : bDate - aDate;
      }

      if (is(Number, aVal) && is(Number, bVal)) {
        return isAscending ? aVal - bVal : bVal - aVal;
      }

      return isAscending
        ? ('' + aVal).localeCompare(bVal, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        : ('' + bVal).localeCompare(aVal, undefined, {
            numeric: true,
            sensitivity: 'base',
          });
    });
  }
  return sortedRows;
};

export default function generateNumberOfOptions(amount: number) {
  const amountValues = range(0, amount).map((n) => ({
    value: n,
    text: n.toString(),
    disabled: false,
  }));

  amountValues.unshift({
    value: 'default',
    text: 'Choose',
    disabled: true,
  });
  return amountValues;
}

export const formatCurrency = (
  intl: any,
  value?: number,
  allowCero?: boolean,
) =>
  !!value
    ? intl.formatNumber(+value, {
        style: 'currency',
        currency: 'USD',
      })
    : allowCero
    ? intl.formatNumber(+value, {
        style: 'currency',
        currency: 'USD',
      })
    : '---';

export const removeNonNumericCharacters = (value: string): string =>
  value?.replace(/[\D]/g, '');

export const removeNonZipCharacters = (value: string): string =>
  value.replace(/[^[a-zA-Z0-9-_]+$]/g, '');

export const GUID_REGEX =
  /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gi;
