import {
  compose,
  equals,
  length,
  keys,
  defaultTo,
  head,
  mergeAll,
  reject,
  values,
  mergeDeepLeft,
} from 'ramda';

/* eslint-disable max-len */
import warnApplicantsEmployerSection from '../../../components/LeaseApplicantFormCommonSections/ApplicantsEmployerSection/warn';
import warnResidenceInformationSection from '../../../components/LeaseApplicantFormCommonSections/ResidenceInformationSection/warn';
import warnBasicInformation from '../../../components/LeaseApplicantFormCommonSections/BasicInformationSection/warn';
import warnContactInformation from '../../../components/LeaseApplicantFormCommonSections/ContactInformationSection/warn';
import warnMaritalInformation from '../../../components/LeaseApplicantFormCommonSections/MaritalStatusSection/warn';
import warnEmploymentInformation from '../../../components/LeaseApplicantFormCommonSections/EmploymentInformationSection/warn';
import warnSignatureSection from '../../../components/LeaseApplicantFormCommonSections/SignatureSection/warn';
/* eslint-enable max-len */
import warnAdditionalOccupantSection from '../AdditionalOcupantSection/warn';
import warnOtherInformationSection from '../OtherInformationSection/warn';
import validateProgress from './validateProgress';

const validateComplete = (
  formValues: any,
  { intl, employmentStatuses, propertyState, propertyCity, flags }: Object,
) => {
  const basicInfoWarning = warnBasicInformation(formValues.basicInformation, {
    intl,
    isAffordable: false,
  });

  const basicInformation = basicInfoWarning;
  const contactInformation = warnContactInformation(
    formValues.contactInformation,
    { intl },
  );

  const maritalInformation = warnMaritalInformation(
    formValues.maritalInformation,
    { intl },
  );

  const applicantsCurrentEmployer = warnApplicantsEmployerSection(
    formValues.applicantsCurrentEmployer,
    formValues.employmentInformation.status,
    { intl, employmentStatuses },
  );
  const currentResidenceInformation = warnResidenceInformationSection(
    formValues.currentResidenceInformation,
    { intl },
  );

  const employmentInformation = warnEmploymentInformation(
    formValues.applicantsCurrentEmployer,
    formValues.employmentInformation.status,
    'non-primary',
    { intl, employmentStatuses },
  );

  const additionalOccupants = warnAdditionalOccupantSection(
    formValues.additionalOccupants,
    { intl },
  );

  const otherInformation = warnOtherInformationSection(
    formValues.otherInformation,
    { intl, propertyState, propertyCity, flags },
  );

  const signature = warnSignatureSection(formValues.signature, { intl });

  const arrayOfErrors: any = [
    { basicInformation },
    { maritalInformation },
    { applicantsCurrentEmployer },
    { contactInformation },
    { employmentInformation },
    { additionalOccupants },
    { signature },
    { otherInformation },
    { currentResidenceInformation },
  ];

  const hasError = compose(
    equals(0),
    length,
    keys,
    defaultTo({}),
    head,
    values,
  );

  const errorsComplete = mergeAll(reject(hasError, arrayOfErrors));
  const errorsProgress = validateProgress(formValues, {
    intl,
    propertyState,
    propertyCity,
    flags,
  });
  const errors = mergeDeepLeft(errorsComplete, errorsProgress);

  return errors;
};

export default validateComplete;
