import React from 'react';
// eslint-disable-next-line max-len
import { UnitSpecials as FortressUnitSpecials } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import useUnitSpecials from './hooks.useUnitSpecials';
import useSelectedProperty from '../../hooks/useSelectedProperty';

const UnitSpecials = () => {
  const { organizationId, id: propertyId } = useSelectedProperty();
  const UnitSpecialsProps = useUnitSpecials({
    propertyId,
    organizationId,
  });
  return <FortressUnitSpecials {...UnitSpecialsProps} />;
};

export default UnitSpecials;
