import React, { useState, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { bindActionCreators } from 'redux';
import { initialize, submit, isDirty } from 'redux-form';
import { connect } from 'react-redux';
import { NoPrint } from 'react-easy-print';
import { Row, Col } from 'react-bootstrap';
import { pathOr } from 'ramda';
import { /* FormattedMessage, */ injectIntl } from 'react-intl';
import { promptToaster } from '../App/actions';

// Components
import HeaderForm from './HeaderForm';
import PaymentsTable from './PaymentsTable';
import SubmitButton from '../../components/SubmitButton';
import PaymentModal from '../../components/PaymentModal';
import Spinner from '../../components/Spinner';
import confirm from '../../components/ConfirmDialogModal';
import { ViewSingleTransactionModal } from '../../components/ViewSingleTransactionModal';
import SocketService from '../../services/socketService';

// Selectors
import {
  formatSubjournals,
  formatPropertyTransactionCodes,
  getFiscalPeriod,
  getPaymentBatchStatus,
  getAllUniqueUnits,
  getOccupiedUnits,
  formatEditPayment,
} from './selectors';

// Hooks
import {
  useFetchPropertyTransactionCodesForProperty,
  useFetchSubjournalsForProperty,
  useFetchOpenFiscalPeriodForProperty,
  useFetchOccupiedUnitsForProperty,
} from '../App/hooks';
import {
  useFetchPaymentBatch,
  useSavePaymentBatchHeader,
  useSavePaymentBatchDetail,
  useDeletePaymentBatchDetail,
  useDeletePaymentBatch,
  usePostPaymentBatch,
} from './hooks';

// Types
import type { GlobalState } from '../App/types';

const ManagePaymentBatch = (props: Object) => {
  const {
    match: {
      params: { batchId },
    },
    actions,
  } = props;
  const propertyId = pathOr(null, ['property', 'id'], props);
  const organizationId = pathOr(null, ['property', 'organizationId'], props);
  const propertyName = pathOr(null, ['property', 'name'], props);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentEditModalOpen, setPaymentEditModalOpen] = useState(false);
  const [editInitialValues, setEditInitialValues] = useState({});
  const [occupiedUnits, occupiedUnitsLoading] =
    useFetchOccupiedUnitsForProperty({
      propertyId,
      organizationId,
    });
  const [subjournals, subjournalsLoading] = useFetchSubjournalsForProperty({
    propertyId,
    organizationId,
  });
  const [fiscalPeriod, fiscalPeriodLoading] =
    useFetchOpenFiscalPeriodForProperty({
      propertyId,
      organizationId,
    });
  const [propertyTransactionCodes, propertyTransactionCodesLoading] =
    useFetchPropertyTransactionCodesForProperty({
      propertyId,
      organizationId,
    });
  const [batch, batchRefreshFn, batchLoading] = useFetchPaymentBatch({
    propertyId,
    organizationId,
    batchId,
    history: props.history,
    initializeCallback: actions.initialize,
  });
  const [savePaymentBatchHeaderFn] = useSavePaymentBatchHeader({
    propertyId,
    organizationId,
    batch,
    promptToaster: actions.promptToaster,
    refresh: batchRefreshFn,
    history: props.history,
  });
  const [savePaymentBatchDetailFn] = useSavePaymentBatchDetail({
    propertyId,
    organizationId,
    batch,
    promptToaster: actions.promptToaster,
    refresh: batchRefreshFn,
  });
  const [deletePaymentBatchDetailFn] = useDeletePaymentBatchDetail({
    propertyId,
    organizationId,
    promptToaster: actions.promptToaster,
    refresh: batchRefreshFn,
  });
  const [deletePaymentBatchFn] = useDeletePaymentBatch({
    propertyId,
    organizationId,
    promptToaster: actions.promptToaster,
    history: props.history,
  });
  const [postPaymentBatchFn, isPostingPaymentBatch] = usePostPaymentBatch({
    organizationId,
    propertyId,
    promptToaster: actions.promptToaster,
    refresh: batchRefreshFn,
  });
  const selectedHouseholdStatus = pathOr(
    'Current Resident',
    ['paymentForm', 'values', 'householdStatus'],
    props,
  );
  const allUniqueUnits = getAllUniqueUnits(occupiedUnits);
  const allOccupiedUnits = getOccupiedUnits(
    occupiedUnits,
    selectedHouseholdStatus,
  );

  const created = !!batch;
  const batchStatus = getPaymentBatchStatus(batch);
  const postingStatus = ['Posted', 'Posting'].indexOf(batchStatus) !== -1;

  const title =
    batchId && batch ? 'View Payment Batch' : 'Create Payment Batch';
  const batchName = pathOr('Unsaved Batch', ['header', 'name'], batch);

  const dataLoading =
    occupiedUnitsLoading ||
    subjournalsLoading ||
    fiscalPeriodLoading ||
    propertyTransactionCodesLoading ||
    (batchId && batchLoading);

  const documentTitle = dataLoading
    ? 'Fortress - Payment Batch'
    : `Fortress - ${title}`;

  const payments = pathOr([], ['transactions'], batch);
  const paymentsTotal = payments.reduce(
    (ac, cur) => cur.transactionAmount + ac,
    0,
  );

  const batchTotal = pathOr(
    0,
    ['manageBatchHeaderForm', 'values', 'batchTotal'],
    props,
  );
  const batchDate = pathOr(
    null,
    ['manageBatchHeaderForm', 'values', 'batchDate'],
    props,
  );
  const validHeader = !props.manageBatchHeaderForm.syncErrors;
  const validToPost =
    validHeader &&
    (+paymentsTotal || 0).toFixed('2') === (+batchTotal || 0).toFixed('2') &&
    payments.every((payment) => payment.valid);

  useEffect(() => {
    const onPaymentBatchSuccess = () => {
      batchRefreshFn();
      actions.promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Payment batch successfully posted.',
      });
    };

    if (SocketService && SocketService.socket) {
      if (SocketService.socket) {
        SocketService.socket.off('pbtx.success');
      }
      SocketService.socket.on('pbtx.success', onPaymentBatchSuccess);
    }

    return () => {
      if (SocketService.socket) {
        SocketService.socket.off('pbtx.success');
      }
    };
  }, [batchId]); // eslint-disable-line

  const openEditPaymentMode = (paymentId: string) => {
    const payments = pathOr([], ['transactions'], batch);
    if (payments) {
      setEditInitialValues(
        formatEditPayment(
          paymentId,
          payments,
          propertyTransactionCodes,
          allOccupiedUnits,
        ),
      );
      setPaymentEditModalOpen(true);
    }
  };

  const openViewPaymentModal = (paymentId: string) => {
    const { intl } = props;
    const transaction: any = batch.transactions.find((p) => p.id === paymentId);
    if (!transaction || !transaction.customerOpsLedger) return;

    ViewSingleTransactionModal({
      intl,
      isOps: true,
      // $FlowFixMe
      singleTransaction: {
        ...transaction.customerOpsLedger,
        depositBatchName: pathOr(
          null,
          ['header', 'depositHeader', 'name'],
          batch,
        ),
      },
      // $FlowFixMe
      isReversable: false,
      onPrint: () => window.print(),
      onReverse: () => {},
      personStatus: 'Resident',
      frNames:
        transaction.financiallyResponsibleLeaseSignersInHousehold || '---',
    });
  };

  return (
    <DocumentTitle title={documentTitle}>
      <NoPrint>
        {paymentModalOpen && (
          <PaymentModal
            key="addPaymentModal"
            show={paymentModalOpen}
            editMode={false}
            batchDate={batchDate}
            propertyName={propertyName}
            onClose={() => setPaymentModalOpen(false)}
            onSave={(values) => {
              savePaymentBatchDetailFn(values);
              setPaymentModalOpen(false);
            }}
            onSaveAndAdd={savePaymentBatchDetailFn}
            units={allOccupiedUnits}
            allUnits={allUniqueUnits}
            propertyCodes={formatPropertyTransactionCodes(
              propertyTransactionCodes,
            )}
            propertySubjournals={formatSubjournals(subjournals)}
          />
        )}
        {paymentEditModalOpen && (
          <PaymentModal
            key="editPaymentModal"
            show={paymentEditModalOpen}
            editMode={true}
            batchDate={batchDate}
            propertyName={propertyName}
            onClose={() => setPaymentEditModalOpen(false)}
            onSave={(values) => {
              savePaymentBatchDetailFn(values);
              setPaymentEditModalOpen(false);
            }}
            units={allOccupiedUnits}
            allUnits={allUniqueUnits}
            propertyCodes={formatPropertyTransactionCodes(
              propertyTransactionCodes,
            )}
            propertySubjournals={formatSubjournals(subjournals)}
            initialValues={editInitialValues}
          />
        )}
        <div className="bodywrap">
          <div className="container-fluid">
            {dataLoading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : (
              <>
                <Row className="form-header">
                  <Col md={5}>
                    <i className="et-calculator float-left" />
                    <h2 className="float-left">{title}</h2>
                  </Col>
                  <Col md={7} className="text-right">
                    <div className="highlight-details highlight-details--nobg">
                      <span>Batch ID: {batchName}</span>
                      <span className="no-third">
                        Period: {getFiscalPeriod(fiscalPeriod)}
                      </span>
                    </div>
                  </Col>
                </Row>
                <HeaderForm
                  onSubmit={savePaymentBatchHeaderFn}
                  isPosted={postingStatus}
                  fiscalPeriod={fiscalPeriod}
                />
                <Row>
                  <Col xs={6}>
                    <strong>
                      {batchStatus ? `Batch Status: ${batchStatus}` : ''}
                    </strong>
                  </Col>
                  {!postingStatus ? (
                    <Col xs={12}>
                      <button
                        id="add-payment-btn"
                        className="btn btn-shout pull-right"
                        onClick={() => {
                          if (!batch) {
                            props.actions.submit('manageBatchHeaderForm');
                          }
                          if (validHeader) {
                            setPaymentModalOpen(true);
                          }
                        }}
                        disabled={postingStatus}
                      >
                        Add Payment
                      </button>
                    </Col>
                  ) : null}
                </Row>
                <PaymentsTable
                  payments={payments}
                  paymentsTotal={paymentsTotal}
                  propertyTransactionCodes={propertyTransactionCodes}
                  isPosted={postingStatus}
                  onRemove={deletePaymentBatchDetailFn}
                  onEdit={openEditPaymentMode}
                  onViewTransaction={openViewPaymentModal}
                />
                <Row>
                  <Col xs={12}>
                    {created && (
                      <div className="legend">
                        <span className="legend-color legend-color--warning" />
                        <span className="small">
                          Payment Transaction no longer valid. Please correct
                          before Posting.
                        </span>
                      </div>
                    )}
                    <p>
                      <strong>NOTES:</strong>{' '}
                    </p>
                    {!created && (
                      <p>
                        - Payments entered here do not post to Resident Ledgers
                        until the Payment Batch is "Posted."
                      </p>
                    )}
                    {created && (
                      <p>
                        - Total Payments must equal Batch Total in order to Post
                        Payment Batch.
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <div className="form-footer padtop20">
                      <div className="button-group">
                        {!!batch && !postingStatus && (
                          <button
                            className="btn btn-delete"
                            onClick={() =>
                              confirm(
                                'Are you sure you want to delete this batch?',
                              ).then(() => deletePaymentBatchFn(batchId))
                            }
                            disabled={isPostingPaymentBatch || !batch}
                          >
                            Delete Batch
                          </button>
                        )}
                        <button
                          className="btn btn-default"
                          onClick={() => props.history.goBack()}
                          disabled={isPostingPaymentBatch}
                        >
                          Cancel
                        </button>
                        {!postingStatus && (
                          <SubmitButton
                            key="submit-button-save"
                            disabled={
                              isPostingPaymentBatch ||
                              !props.headerFormDirty ||
                              !validHeader
                            }
                            classes="btn btn-primary"
                            clickHandler={() =>
                              props.actions.submit('manageBatchHeaderForm')
                            }
                          >
                            Save
                          </SubmitButton>
                        )}
                        {!postingStatus && batch && (
                          <SubmitButton
                            key="submit-button-post"
                            disabled={
                              isPostingPaymentBatch ||
                              postingStatus ||
                              !validToPost ||
                              props.headerFormDirty
                            }
                            classes="btn btn-primary"
                            clickHandler={() => {
                              postPaymentBatchFn(
                                batchId,
                                pathOr(
                                  null,
                                  ['history', 'location', 'pathname'],
                                  props,
                                ),
                              );
                            }}
                          >
                            {!isPostingPaymentBatch ? (
                              'Post'
                            ) : (
                              <Spinner small />
                            )}
                          </SubmitButton>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </NoPrint>
    </DocumentTitle>
  );
};

function mapStateToProps(state: GlobalState) {
  const property = pathOr({}, ['app', 'selectedProperty'], state);
  const manageBatchHeaderForm = pathOr(
    {},
    ['form', 'manageBatchHeaderForm'],
    state,
  );
  const paymentForm = pathOr({}, ['form', 'paymentForm'], state);
  return {
    property,
    manageBatchHeaderForm,
    paymentForm,
    headerFormDirty: isDirty('manageBatchHeaderForm')(state),
    paymentFormDirty: isDirty('paymentForm')(state),
  };
}

const mapDispatchToProps = (dispatch: any): Object => {
  const actions = bindActionCreators(
    {
      initialize,
      promptToaster,
      submit,
    },
    dispatch,
  );
  return { actions };
};

const InjectedManagePaymentBatch = injectIntl(ManagePaymentBatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedManagePaymentBatch);
