export const GET_DOCUMENT_TYPES = 'app/ManageDocuments/GET_DOCUMENT_TYPES';
export const GET_DOCUMENT_TYPES_SUCCESS =
  'app/ManageDocuments/GET_DOCUMENT_TYPES_SUCCESS';

export const GET_RESIDENT_LETTER_DOCUMENT_TYPES =
  'app/ManageDocuments/GET_RESIDENT_LETTER_DOCUMENT_TYPES';
export const GET_RESIDENT_LETTER_DOCUMENT_TYPES_SUCCESS =
  'app/ManageDocuments/GET_RESIDENT_LETTER_DOCUMENT_TYPES_SUCCESS';

export const SAVE_DOCUMENT = 'app/ManageDocuments/SAVE_DOCUMENT';
export const SAVE_DOCUMENT_SUCCESS =
  'app/ManageDocuments/SAVE_DOCUMENT_SUCCESS';
export const SAVE_DOCUMENT_ERROR = 'app/ManageDocuments/SAVE_DOCUMENT_ERROR';

export const DOCUMENTS_LIST_CHANGED =
  'app/ManageDocuments/DOCUMENTS_LIST_CHANGED';
export const DOCUMENTS_LIST_UPDATED =
  'app/ManageDocuments/DOCUMENTS_LIST_UPDATED';

export const GENERATE_RESIDENT_LETTER =
  'app/ManageDocuments/GENERATE_RESIDENT_LETTER';
export const GENERATE_RESIDENT_LETTER_SUCCESS =
  'app/ManageDocuments/GENERATE_RESIDENT_LETTER_SUCCESS';
export const GENERATE_RESIDENT_LETTER_ERROR =
  'app/ManageDocuments/GENERATE_RESIDENT_LETTER_ERROR';

export const GET_ALL_DOCUMENTS = 'app/ManageDocuments/GET_ALL_DOCUMENTS';
export const GET_ALL_DOCUMENTS_SUCCESS =
  'app/ManageDocuments/GET_ALL_DOCUMENTS_SUCCESS';

export const DELETE_DOCUMENT = 'app/ManageDocuments/DELETE_DOCUMENT';

export const UPDATE_COLUMNS_SORT_VALUE =
  'app/ManageDocuments/UPDATE_COLUMNS_SORT_VALUE';

export const DOWNLOAD_DOCUMENT = 'app/ManageDocuments/DOWNLOAD_DOCUMENT';
export const RESIDENT_LETTER_FORM_NAME = 'addResidentLetter';

export const DOCUMENTS_DEFAULT_PAGE = 1;
export const DOCUMENTS_DEFAULT_LIMIT = 15;
export const DOCUMENTS_DEFAULT_SORTING = { fieldName: '', order: '' };

export const COMPLIANCE_DOCUMENT_TYPE = 'Compliance';
