import React, { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { isDirty, isValid, submit } from 'redux-form';
import styled from 'styled-components';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as R from 'ramda';
import * as constants from './constants';
import * as hooks from './hooks';
import messages from './messages';
import { promptToaster } from '../../../../App/actions';
import SubmitButton from '../../../../../components/SubmitButton';
import RentForm from './RentForm';

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButtonMargins = styled.span`
  & button {
    margin: 0 10px;
  }
`;

const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty]);

function EditRentModal({
  onHide,
  show,
  intl,
  lease,
  isFormDirty,
  isFormValid,
  onSaveClick,
  submit,
  propertyId,
  organizationId,
  promptToaster,
  refreshRents,
  scheduledRents,
}) {
  const [deleteRent] = hooks.useDeleteRent(
    propertyId,
    organizationId,
    R.prop('id', lease),
    promptToaster,
  );
  const [updateRent] = hooks.useUpdateRent(
    propertyId,
    organizationId,
    R.prop('id', lease),
    promptToaster,
  );
  const [createRent] = hooks.useCreateRent(
    propertyId,
    organizationId,
    R.prop('id', lease),
    promptToaster,
  );
  const [updateHoldover] = hooks.useUpdateHoldover(
    propertyId,
    organizationId,
    R.prop('id', lease),
    promptToaster,
  );
  const startDate = R.prop('rentStartDate', lease);
  const leaseEndDate = R.propOr('', 'endDate', lease);
  const isSubmitDisabled = !isFormValid;

  const handleRowDelete = (rentId) => {
    return deleteRent(rentId).then(refreshRents);
  };

  const handleRowUpdate = (rentId, updates) => {
    return updateRent(rentId, updates).then(refreshRents);
  };

  const handleRentCreate = (rent) => {
    return createRent({ ...rent, leaseId: R.prop('id', lease) }).then(
      refreshRents,
    );
  };

  const handleSaveClick = () => {
    submit(constants.FORM_NAME);
  };

  const handleHide = () => {
    onHide();
  };

  const handleSubmit = (values) => {
    updateHoldover({
      dollarAmount: isNilOrEmpty(values.dollarIncrease)
        ? null
        : values.dollarIncrease,
      percentage: isNilOrEmpty(values.percentageIncrease)
        ? null
        : values.percentageIncrease,
      noIncrease:
        isNilOrEmpty(values.dollarIncrease) &&
        isNilOrEmpty(values.percentageIncrease),
    }).then(refreshRents);
    onHide();
  };

  const initialValues = useMemo(() => {
    if (R.isEmpty(scheduledRents)) return {};

    const holdoverRent = scheduledRents.find((sr) => sr.isHoldover) || {};

    return {
      rentSchedules: scheduledRents
        .filter((sr) => !sr.isHoldover)
        .map((sr) => ({
          id: sr.id,
          startDate: moment(sr.startDate),
          monthlyAmount: sr.monthlyAmount,
          notes: sr.notes,
        })),
      dollarIncrease: holdoverRent.holdoverDollarAmount,
      percentageIncrease: holdoverRent.holdoverPercentage,
    };
  }, [scheduledRents]);

  return (
    <Modal show={show} onHide={handleHide} bsSize="large" autoFocus>
      <Modal.Header closeButton>
        <i className="icon et-money" />
        <h1>
          <FormattedMessage {...messages.editRentSchedule} />
        </h1>
      </Modal.Header>
      <Modal.Body>
        <RentForm
          intl={intl}
          startDate={startDate}
          leaseEndDate={leaseEndDate}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onRowDelete={handleRowDelete}
          onRowUpdate={handleRowUpdate}
          onCreate={handleRentCreate}
        />
      </Modal.Body>
      <Modal.Footer style={{ marginTop: 10 }}>
        <FooterContainer>
          <Button type="button" bsStyle="default" onClick={handleHide}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <SubmitButtonMargins>
            <SubmitButton
              bsStyle="primary"
              disabled={isSubmitDisabled}
              clickHandler={handleSaveClick}
              isSubmitting={false}
            >
              <FormattedMessage {...messages.save} />
            </SubmitButton>
          </SubmitButtonMargins>
        </FooterContainer>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state): Object => ({
  isFormDirty: isDirty(constants.FORM_NAME)(state),
  isFormValid: isValid(constants.FORM_NAME)(state),
});

const mapDispatchToProps = { submit, promptToaster };

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(EditRentModal),
);
