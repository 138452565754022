import React from 'react';
import { TextInput } from './TextInput';
import { validatePhoneNumber } from '../../../utils/validations';
import { phoneFormatter } from '../../../utils/redux-form-helper';
import messages from './messages';
import { removeNonNumericCharacters } from '../../../utils';

type Props = {
  name: string,
  value: string,
  onChange: Function,
  editMode: boolean,
  required: boolean,
};

export const PhoneNumberInput = ({
  name,
  value,
  editMode,
  onChange,
  required,
  ...custom
}: Props) => (
  <TextInput
    name={name}
    value={phoneFormatter(value)}
    editMode={editMode}
    onChange={({ target: { name, value } }) => {
      onChange({
        target: {
          name,
          value: removeNonNumericCharacters(value),
        },
      });
    }}
    required={true}
    type="tel"
    maxLength={14}
    validateFunction={(value) =>
      required || value ? value && validatePhoneNumber(value) : true
    }
    message={messages.invalidPhoneNumber}
    {...custom}
  />
);
