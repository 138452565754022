/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'App.ViewProperty.IntegrationsSetup.CollectionsSetup.ConfirmationModal.Title',
    defaultMessage: 'Confirm Automated Collections',
  },
  intro: {
    id: 'App.ViewProperty.IntegrationsSetup.CollectionsSetup.ConfirmationModal.Intro',
    defaultMessage:
      'By enabling the Automated Collections Tracker, I understand and certify:',
  },
  contractConfirmation: {
    id: 'App.ViewProperty.IntegrationsSetup.CollectionsSetup.ConfirmationModal.ContractConfirmation',
    defaultMessage:
      'A contract with Hunter Warfield has been signed, and they have confirmed they are prepared to receive claims from {propertyName}, facilitating seamless account transfers to the collections agency.',
  },
  exclusionConfirmation: {
    id: 'App.ViewProperty.IntegrationsSetup.CollectionsSetup.ConfirmationModal.ExclusionConfirmation',
    defaultMessage:
      'I have verified the collections status of all prior resident accounts and am prepared for any account not under Payment Plan, Sent to Collections, or Account Closed statuses to have a claim created by Fortress.',
  },
  autoClaimGenerationConfirmation: {
    id: 'App.ViewProperty.IntegrationsSetup.CollectionsSetup.ConfirmationModal.AutoClaimGenerationConfirmation',
    defaultMessage:
      'I authorize Fortress to share collections/claims with Hunter Warfield for all Prior Resident households, contingent upon adherence to the predetermined criteria established by your property.',
  },
});
