import {
  isEmpty,
  isNil,
  prop,
  equals,
  keys,
  any,
  not,
  find,
  flip,
  curryN,
  propEq,
  pathOr,
} from 'ramda';
import { SubmissionError } from 'redux-form';
import { validateEmail, validatePhoneNumber } from '../../utils/validations';

import messages from './messages';
import { formModes } from './index';
import type { FormMode } from './index';

const validateName = (name) =>
  name && /^[a-zA-Z][a-zA-Z0-9 .',&-]*$/i.test(name);
const petTagRegex = (petTag) => {
  return /^[a-zA-Z0-9 .'-]*$/i.test(petTag);
};
const validateRequired = (property: string, obj: Object) =>
  not(isEmpty(prop(property, obj))) &&
  not(isNil(prop(property, obj))) &&
  !equals('default', prop(property, obj));
const validatePetTag = (intl, tagString) => {
  if (tagString && !petTagRegex(tagString)) {
    return intl.formatMessage(messages.invalidValue);
  }
};
const validateAdults = (
  adults: Array<any>,
  intl: Object,
  mode: FormMode,
  isAffordable: any,
  primaryApplicantId: string,
) =>
  adults.map((adult) => {
    const errors = {};
    let prospectAdult = pathOr(null, ['isProspect'], adult);
    if (
      mode === formModes.PROSPECT_CONVERT ||
      mode === formModes.APPLICATION_HOUSEHOLD
    ) {
      ['type', 'firstName', 'lastName', 'phone'].forEach((fieldName) => {
        if (!validateRequired(fieldName, adult))
          errors[fieldName] = intl.formatMessage(messages.required);
      });
      if (mode === formModes.APPLICATION_HOUSEHOLD) {
        prospectAdult = primaryApplicantId === adult.id;
      }
      if (!prospectAdult) {
        if (!validateRequired('relationship', adult))
          errors['relationship'] = intl.formatMessage(messages.required);
      }
      if (isAffordable && !validateRequired('affordableRelationship', adult))
        errors['affordableRelationship'] = intl.formatMessage(
          messages.required,
        );
    }

    ['firstName', 'lastName'].forEach((fieldName) => {
      const value = adult[fieldName];
      if (value && !errors[fieldName] && !validateName(value)) {
        errors[fieldName] = intl.formatMessage(messages.invalidValue);
      }
    });

    ['preferredName', 'middleName'].forEach((fieldName) => {
      const value = adult[fieldName];
      if (value && !validateName(value)) {
        errors[fieldName] = intl.formatMessage(messages.invalidValue);
      }
    });

    if (!errors.phone && !validatePhoneNumber(adult.phone)) {
      errors.phone = intl.formatMessage(messages.invalidValue);
    }

    if (!validateEmail(adult.email)) {
      errors.email = intl.formatMessage(messages.invalidValue);
    }

    return errors;
  });

const validateMinors = (
  minors: Array<any>,
  intl: Object,
  mode: FormMode,
  isAffordable: any,
) => {
  const minorsErrors = [];

  minors.forEach((minor) => {
    const thisMinorErrors = {};

    ['firstName', 'lastName', 'relationship'].forEach((fieldName) => {
      if (
        (mode === formModes.PROSPECT_CONVERT ||
          mode === formModes.APPLICATION_HOUSEHOLD) &&
        !validateRequired(fieldName, minor)
      ) {
        thisMinorErrors[fieldName] = intl.formatMessage(messages.required);
      }
      ['firstName', 'lastName'].forEach((fieldName) => {
        const value = minor[fieldName];
        if (value && !validateName(value)) {
          thisMinorErrors[fieldName] = intl.formatMessage(
            messages.invalidValue,
          );
        }
      });
    });

    ['middleName', 'preferredName'].forEach((fieldName) => {
      const value = minor[fieldName];
      if (value && !validateName(value)) {
        thisMinorErrors[fieldName] = intl.formatMessage(messages.invalidValue);
      }
    });
    if (isAffordable && !validateRequired('affordableRelationship', minor))
      thisMinorErrors['affordableRelationship'] = intl.formatMessage(
        messages.required,
      );
    minorsErrors.push(thisMinorErrors);
  });
  return minorsErrors;
};

const validatePets = (
  pets: Array<any>,
  petTypeList: Array<any>,
  intl: Object,
  mode: FormMode,
) =>
  pets.map((pet) => {
    const errors = {};
    if (
      any(equals(mode))([
        formModes.PROSPECT_CONVERT,
        formModes.APPLICATION_HOUSEHOLD,
      ])
    ) {
      ['petTypeId', 'breedId'].forEach((fieldName) => {
        if (!validateRequired(fieldName, pet))
          errors[fieldName] = intl.formatMessage(messages.required);
      });
    }

    const selectedPetType =
      find((type) => type.value === pet.petTypeId, petTypeList) || {};

    if (
      !errors.petTypeId &&
      selectedPetType.requiresWeight &&
      !validateRequired('weight', pet)
    ) {
      errors.weight = intl.formatMessage(messages.required);
    }
    if (pet && pet.petTag) {
      const tagErr = validatePetTag(intl, pet.petTag);
      if (tagErr) {
        errors.petTag = tagErr;
      }
    }

    if (
      !errors.weight &&
      !isEmpty(pet.weight) &&
      !isNil(pet.weight) &&
      pet.weight <= 0
    ) {
      errors.weight = intl.formatMessage(messages.invalidValue);
    }

    return errors;
  });

const validate = ({
  intl,
  onSubmit,
  petTypeList,
  mode,
  isAffordable,
  primaryApplicantId,
}: any) => (values: any) => {
  const { adults, minors, pets } = values;

  return new Promise((resolve) => {
    const errors = {};
    const adultsErrors = validateAdults(
      adults,
      intl,
      mode,
      isAffordable,
      primaryApplicantId,
    );

    if (any((error) => keys(error).length > 0, adultsErrors)) {
      errors.adults = adultsErrors;
    }

    const minorsErrors = validateMinors(minors, intl, mode, isAffordable);

    if (any((error) => keys(error).length > 0, minorsErrors)) {
      errors.minors = minorsErrors;
    }

    const petsErrors = validatePets(pets, petTypeList, intl, mode);

    if (any((error) => keys(error).length > 0, petsErrors)) {
      errors.pets = petsErrors;
    }

    if (
      mode === formModes.PROSPECT_CONVERT &&
      !validateRequired('unitTypeId', values)
    ) {
      errors.unitTypeId = intl.formatMessage(messages.required);
    }

    const prospectAdult = find(propEq('isProspect', true), adults);

    if (mode === formModes.PROSPECT_HOUSEHOLD && prospectAdult) {
      if (
        not(
          any(flip(curryN(2, validateRequired))(prospectAdult), [
            'firstName',
            'lastName',
            'phone',
            'email',
          ]),
        )
      ) {
        errors._error = intl.formatMessage(messages.prospectValuesRequired);
      }
    }

    if (keys(errors).length === 0) {
      onSubmit(values);
      resolve();
    }
    throw new SubmissionError(errors);
  });
};

export default validate;
