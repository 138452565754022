import {
  all,
  always,
  clone,
  concat,
  equals,
  findIndex,
  ifElse,
  map,
  pluck,
  propEq,
  type,
} from 'ramda';

import messages from './messages';
import moment from 'moment';

const validateTransactions = (transactions: Array<any>, intl: Object) => {
  const tErrors = [];
  if (transactions) {
    transactions.forEach((transaction) => {
      const thisFeeErrors = {};

      ['proratedAmount'].forEach((fieldName) => {
        if (Number(transaction[fieldName]) < 0) {
          thisFeeErrors[fieldName] = intl.formatMessage(
            messages.invalidTransactionValue,
          );
        }
      });
      tErrors.push(thisFeeErrors);
    });
  }
  return tErrors;
};

const validate = (
  values: Object = {},
  {
    moveInActions,
    steps,
    leaseStartDate,
    openFiscalPeriod,
    intl,
    leaseMoveInDate,
    isTransUnionActive,
    flags,
  }: Object,
) => {
  const errors = {};
  const moveInActionValues = map(
    (action) => ({
      ...action,
      valid: values['moveInActions'][action.id],
    }),
    moveInActions,
  );

  const { backdateScheduledMoveInDateMoreThan12Months } = flags;

  const evalSteps = clone(steps);

  if (flags.transunionOffLeaseAppOff) {
    if (isTransUnionActive) {
      const screeningStatusIndex = findIndex(
        propEq('title', 'Household Screening'),
      )(evalSteps);
      if (values.moveInWithoutScreening) {
        evalSteps[screeningStatusIndex].valid = true;
      }
    }
  } else {
    const screeningStatusIndex = findIndex(
      propEq('title', 'Household Screening'),
    )(evalSteps);
    if (values.moveInWithoutScreening) {
      evalSteps[screeningStatusIndex].valid = true;
    }
  }

  const valuesToValidate = pluck(
    'valid',
    concat(moveInActionValues, evalSteps),
  );
  errors._error = ifElse(
    all(equals(true)),
    always(undefined),
    always('error'),
  )(valuesToValidate);

  let actualMoveInDate = moment(values.actualMoveInDate);
  if (!actualMoveInDate.isValid() || type(values.startDate) === 'String') {
    errors.actualMoveInDate = intl.formatMessage(messages.invalidDate);
  } else {
    actualMoveInDate = actualMoveInDate.startOf('day');
  }

  const moveInDateFromLease = moment(leaseMoveInDate);
  if (
    !errors.actualMoveInDate &&
    !moveInDateFromLease.isSame(actualMoveInDate, 'day')
  ) {
    errors.actualMoveInDate = true;
  }

  if (actualMoveInDate.toDate() > Date.now()) {
    errors.actualMoveInDate = intl.formatMessage(messages.moveInDateInFuture);
  }

  if (
    !backdateScheduledMoveInDateMoreThan12Months &&
    !errors.actualMoveInDate &&
    actualMoveInDate.unix() <
      moment().subtract(1, 'years').startOf('day').unix()
  ) {
    errors.actualMoveInDate = intl.formatMessage(messages.moveInDateInPastYear);
  }

  if (
    !errors.actualMoveInDate &&
    actualMoveInDate
      .startOf('day')
      .isBefore(moment(values.previousLeaseMoveOutDate).startOf('day'))
  ) {
    errors.actualMoveInDate = intl.formatMessage(messages.moveInDateOverlap);
  }

  const startDate = moment(leaseStartDate);
  if (startDate.isValid()) {
    startDate.startOf('day');
  }

  if (
    !errors.actualMoveInDate &&
    startDate.isValid() &&
    actualMoveInDate.toDate() < startDate.toDate()
  ) {
    errors.actualMoveInDate = intl.formatMessage(
      messages.moveInLessThanStartDate,
    );
  }

  errors.prorated = validateTransactions(values.prorated, intl);

  return errors;
};

export default validate;
