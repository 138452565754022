import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Input,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { RadioGroup } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import messages from './messages';
const ERROR_COLOR = '#D70000';
function RadioWithInput(props) {
  const { label, showInput } = props;
  return (
    <div>
      <Typography variant={'inputLabel'}>{label}</Typography>

      {showInput && props.children}
    </div>
  );
}

function EditFeeForm({ intl }) {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const feeType = watch('feeType');
  return (
    <div style={{ margin: '20px' }}>
      <div>
        <Controller
          name="feeType"
          control={control}
          render={({
            field: { value, onChange, onBlur },
            fieldState: { invalid, error },
          }) => (
            <RadioGroup
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              items={[
                {
                  sx: { alignSelf: 'flex-start' },
                  label: (
                    <RadioWithInput
                      label={intl.formatMessage(messages.feeAmount)}
                      showInput={feeType === 'feeAmount'}
                    >
                      <div className="input-group">
                        <div className="input-group-addon">$</div>
                        <input
                          className="input-lg form-control"
                          step="0.01"
                          placeholder="0.00"
                          type="number"
                          min={0.01}
                          {...register('feeAmount', {
                            max: {
                              value: 99999.99,
                              message: `${intl.formatMessage(
                                messages.maxValueError,
                              )} $99,999.99.`,
                            },
                            min: {
                              value: feeType === 'feeAmount' ? 0.01 : 0,
                              message: `${intl.formatMessage(
                                messages.minimumValueError,
                              )} $ 0.01`,
                            },
                            required:
                              feeType === 'feeAmount' &&
                              intl.formatMessage(messages.requiredError),
                            valueAsNumber: true,
                          })}
                        />
                      </div>
                      {errors.feeAmount?.message && (
                        <Typography
                          variant={'helper'}
                          sx={{
                            color: ERROR_COLOR,
                          }}
                        >
                          {errors.feeAmount?.message}
                        </Typography>
                      )}
                    </RadioWithInput>
                  ),
                  value: 'feeAmount',
                },
                {
                  sx: { alignSelf: 'flex-start' },
                  label: (
                    <RadioWithInput
                      label={intl.formatMessage(messages.feeDescriptionAmount)}
                      showInput={feeType === 'descriptionAmount'}
                    >
                      <Input
                        hiddenLabel={true}
                        fullWidth
                        error={errors.descriptionAmount?.message}
                        helperText={errors.descriptionAmount?.message || ''}
                        {...register('descriptionAmount', {
                          required:
                            feeType === 'descriptionAmount' &&
                            intl.formatMessage(messages.requiredError),
                          maxLength: {
                            message: intl.formatMessage(
                              messages.maxLengthError,
                            ),
                            value: 75,
                          },
                        })}
                      />
                    </RadioWithInput>
                  ),
                  value: 'descriptionAmount',
                },
              ]}
            />
          )}
        />
      </div>
    </div>
  );
}

export default EditFeeForm;
