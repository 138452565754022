export const ALLOCATION_SETUP_MODAL_MODES = {
  EXCEPTION: 'Exception',
  ALLOCATION: 'Allocation',
};

export const FIELDS = {
  GL_OPTION_ALL: 'glOptionAll',
  GL_OPTION_NONE: 'glOptionNone',
  SELECTED_GL_ACCOUNTS: 'selectedGLAccounts',
};

export const BASE_AMOUNT_OPTIONS = {
  BASE_YEAR: 'baseAmountBaseYear',
  OWNER_STOP_EXPENSE: 'baseAmountOwnerStopExpense',
  NONE: 'baseAmountNone',
};

export const EXPENSE_CAPS_OPTIONS = {
  YOY: 'expenseCapYoy',
  AGGREGATE: 'expenseCapAggregate',
  CUMULATIVE: 'expenseCapCumulative',
  COMPOUNDED: 'expenseCapCompounded',
  NONE: 'expenseCapNone',
};

export const PRO_RATA_SHARE_OPTIONS = {
  ACTUAL: 'proRataActual',
  CUSTOM: 'proRataCustom',
  NONE: 'proRataNone',
};

export const GROSS_UP_OPTIONS = {
  YES: 'grossUpYes',
  NO: 'grossUpNo',
};

export const LEASE_ALLOWS_MONTHLY_EST_OPTIONS = {
  YES: 'leaseMonthlyEstYes',
  NO: 'leaseMonthlyEstNo',
};

export const GL_ACCOUNTS_OPTIONS = {
  SELECT_ALL: 'glOptionAll',
  NONE: 'glOptionNone',
};

export const ERR_NO_AMOUNT = 'Please enter an amount';
export const ERR_INVALID_AMOUNT = 'Please enter a valid amount';
export const ERR_NO_VALUE = 'Please enter a value';
export const ERR_INVALID_VALUE = 'Please enter a valid value';
export const ERR_NO_OPTION_SELECTED = 'Please select an option';
