import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dateMovedIn: {
    id: 'App.CurrentAddressSection.DateMovedIn',
    defaultMessage: 'Date you moved in'
  },
  monthlyPayment: {
    id: 'App.CurrentAddressSection.MonthlyPayment',
    defaultMessage: 'Current Monthly Payment'
  },
  streetAddressLabel: {
    id: 'App.CurrentAddressSection.StreetAddressLabel',
    defaultMessage: 'Street Address'
  },
});

export default messages;
