import { get, post } from '../utils/api';

export default class SmartPricingService {
  getPropertySmartPrices(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/revenue-management/smart-prices`,
      options,
    );
  }

  actOnSmartPrice(
    organizationId: string,
    propertyId: string,
    currentAmount: number,
    newAmount: number,
    floorPlanId: string,
    action: 'ACCEPT' | 'REJECT',
    options?: Object,
  ) {
    return post(
      `/${organizationId}/${propertyId}/revenue-management/smart-prices`,
      JSON.stringify({
        action,
        currentAmount,
        newAmount,
        floorPlanId,
      }),
      options,
    );
  }

  getPropertySmartPricesLastHistory(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/revenue-management/smart-prices/history`,
      options,
    );
  }
}
