import React from 'react';

type Props = {
  userRoleId: string,
  propertyClassId: string,
  username: string,
  organizationId: string,
  hasCommercialFloorPlans: string,
};

const WalkmeHiddenFields = ({
  userRoleId,
  propertyClassId,
  username,
  organizationId,
  hasCommercialFloorPlans,
}: Props) => {
  return [
    <input
      key="walkmePropertyClassId"
      type="hidden"
      id="propertyClassId"
      value={propertyClassId}
    />,
    <input
      key="walkmeUserRoleId"
      type="hidden"
      id="userRoleId"
      value={userRoleId}
    />,
    <input key="walkmeUsername" type="hidden" id="username" value={username} />,
    <input
      key="walkmeOrganizationId"
      type="hidden"
      id="organizationId"
      value={organizationId}
    />,
    <input
      key="walkmeHasCommercialFloorPlans"
      type="hidden"
      id="hasCommercialFloorPlans"
      value={hasCommercialFloorPlans}
    />,
  ];
};

export default WalkmeHiddenFields;
