import type { RenewalFormInfo } from '../containers/GenerateRenewalOffer/types';
const { apiURL, requestToDownload } = require('../utils/api');
const request = require('../utils/api').default;

class RenewalOfferService {
  getAllLeaseTerms(organizationId: string, propertyId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/lease-terms`,
      options
    );
  }

  getAllPreviousRenewalOffers(
    organizationId: string,
    propertyId: string,
    leaseId: string
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/lease/${leaseId}/lease-renewal`,
      options
    );
  }

  createOffer(
    organizationId: string,
    propertyId: string,
    offer: RenewalFormInfo,
    leaseId: string
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(offer)
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/lease/${leaseId}/lease-renewal`,
      options
    );
  }

  downloadOffer(leaseId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id')
      }
    };

    return requestToDownload(
      `${apiURL}/lease/${leaseId}/generateLeaseOffer`,
      options
    );
  }
}

export default RenewalOfferService;
