import React, { useState, useContext, useMemo, useCallback } from 'react';
import {
  palette,
  orange,
} from '@fortress-technology-solutions/fortress-component-library/design';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import {
  QuoteBlock,
  Grid,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  Stack,
  Typography,
  Spinner,
  Divider,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import confirm from '../../../components/ConfirmDialogModal';
import ReputationMgmtService from '../../../services/reputationManagementService';
import type { ResponseSubmitSuccessHandler, ReviewResponse } from '../types';
import * as constants from '../constants';
import messages from '../messages';
import { AppContext } from '../../App/context';
import ResponseForm from './ResponseForm';
import useHasPermission from '../../../hooks/useHasPermission';

type Props = {
  isResponseShown: boolean,
  onShowResponse: Function,
  onHideResponse: Function,
  formatMessage: Function,
  reviewId: string,
  source: string,
  canDelete: boolean,
  response?: {
    id?: string,
    text: string,
    updateTime: string,
  },
  onResponseDeleteSuccess: Function,
  onResponseSubmitSuccess: ResponseSubmitSuccessHandler,
};

const service = new ReputationMgmtService();

const NoResponse = ({
  formatMessage,
  respondBtnDisabled,
  respondBtnShown,
  onRespondToReviewClick,
}) => (
  <Stack direction="row" alignItems="center" spacing={2}>
    <span
      style={{
        border: `2px solid ${orange.main}`,
        color: orange.main,
        fontWeight: 500,
        padding: '5px 10px',
        textTransform: 'uppercase',
      }}
    >
      {formatMessage(messages.responsePending)}
    </span>
    {respondBtnShown && (
      <a disabled={respondBtnDisabled} onClick={onRespondToReviewClick}>
        {formatMessage(messages.respondToReview)}
      </a>
    )}
  </Stack>
);

function ResponseSection({
  isResponseShown,
  onShowResponse,
  onHideResponse,
  reviewId,
  source,
  response,
  canDelete,
  formatMessage,
  onResponseDeleteSuccess,
  onResponseSubmitSuccess,
}: Props) {
  const {
    selectedProperty: { name: propertyName, id: propertyId, organizationId },
  } = useContext(AppContext);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isResponseFormShown, setIsResponseFormShown] = useState(false);
  const hasRespondAndDeletePermission = useHasPermission(
    'rep-mgmt-respond-and-delete-reviews',
  );

  const handleResponseFormCancel = () => {
    setIsResponseFormShown(false);
  };

  const handleRespondToReviewClick = () => {
    setIsResponseFormShown(true);
  };

  const handleEditBtnClick = () => {
    setIsResponseFormShown(true);
  };

  const handleResponseDelete = async () => {
    setIsDeleting(true);

    try {
      await service.deleteReviewResponse(organizationId, propertyId, {
        id: response?.id || reviewId,
        source,
      });
      onResponseDeleteSuccess?.();
    } catch (error) {
      toastr.error(
        formatMessage(messages.error),
        formatMessage(messages.failedToDeleteReviewResponse),
      );
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteBtnClick = () => {
    confirm(formatMessage(messages.confirmDeleteTitle), {
      confirmText: formatMessage(messages.confirmDeleteAcceptText),
      cancelText: formatMessage(messages.confirmDeleteRejectText),
      theme: 'delete',
      reverseBtns: true,
    }).then(() => handleResponseDelete());
  };

  const handleOnResponseSubmitSuccess = useCallback(
    (reviewId: string, response: ReviewResponse) => {
      setIsResponseFormShown(false);
      onResponseSubmitSuccess(reviewId, response);
    },
    [onResponseSubmitSuccess],
  );

  const responseForm = useMemo(
    () =>
      isResponseFormShown && (
        <ResponseForm
          reviewId={reviewId}
          source={source}
          responseId={response?.id}
          initialValue={response ? { message: response.text } : undefined}
          onCancel={handleResponseFormCancel}
          onResponseSubmitSuccess={handleOnResponseSubmitSuccess}
        />
      ),
    [
      isResponseFormShown,
      reviewId,
      source,
      response,
      handleOnResponseSubmitSuccess,
    ],
  );
  const hideResponseBtn = useMemo(
    () => (
      <a onClick={onHideResponse} role="button" disabled={isResponseFormShown}>
        {formatMessage(messages.hideResponse)}
      </a>
    ),
    [formatMessage, onHideResponse, isResponseFormShown],
  );
  const responsePublishedText = useMemo(
    () => (
      <span style={{ color: '#4CBB17' }}>
        <i className="et-isolated-check" />{' '}
        {formatMessage(messages.responsePublished)}
      </span>
    ),
    [formatMessage],
  );

  if (isDeleting) return <Spinner />;

  return (
    <Stack spacing={1} paddingTop={1}>
      {response ? (
        <div>
          {!isResponseShown && (
            <>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                paddingBottom={2}
              >
                <a onClick={onShowResponse} role="button">
                  {formatMessage(messages.showResponse)}
                </a>
                {responsePublishedText}
              </Stack>
              <Divider variant="dashed" />
            </>
          )}
          {isResponseShown && !isResponseFormShown && (
            <Grid container marginTop={1}>
              <Grid item xs={12} paddingBottom={4}>
                <QuoteBlock>
                  <Stack
                    direction="row"
                    spacing={2}
                    marginBottom={2}
                    justifyContent="space-between"
                  >
                    <Stack direction="row" spacing={1}>
                      <Typography variant="h5" gutterBottom>
                        {propertyName} replied
                      </Typography>
                      <Typography color="text.secondary" gutterBottom>
                        {moment(response.updateTime).format(
                          constants.DATE_FORMAT,
                        )}
                      </Typography>
                    </Stack>
                    {hasRespondAndDeletePermission && (
                      <Stack direction="row" spacing={1}>
                        <i
                          className="et-pencil"
                          onClick={handleEditBtnClick}
                          style={{
                            color: palette.main.blue,
                            cursor: 'pointer',
                          }}
                        />
                        {canDelete && (
                          <i
                            className="et-trash"
                            onClick={handleDeleteBtnClick}
                            style={{
                              color: palette.main.red,
                              cursor: 'pointer',
                            }}
                          />
                        )}
                      </Stack>
                    )}
                  </Stack>
                  <Typography variant="body1">{response.text}</Typography>
                </QuoteBlock>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={5}
                  paddingBottom={2}
                >
                  {hideResponseBtn}
                  {responsePublishedText}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider variant="dashed" />
              </Grid>
            </Grid>
          )}
          {responseForm}
        </div>
      ) : (
        <Stack spacing={2}>
          <NoResponse
            formatMessage={formatMessage}
            respondBtnDisabled={isResponseFormShown}
            respondBtnShown={hasRespondAndDeletePermission}
            onRespondToReviewClick={handleRespondToReviewClick}
          />
          {responseForm}
          <Divider variant="dashed" />
        </Stack>
      )}
    </Stack>
  );
}

export default ResponseSection;
