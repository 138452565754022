import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import classNames from 'classnames';

import {
  renderRadioField,
  renderTextField,
  renderTextAreaField,
  renderPhoneField,
  phoneParser,
  phoneFormatter
} from '../../../utils/redux-form-helper';
import messages from './messages';

import type { SelectOption } from '../../../containers/App/types';

type Props = {
  intl: Object,
  ownOption: SelectOption,
  rentOption: SelectOption,
  notCurrentlyOwnRentOption: SelectOption,
  required?: boolean,
  error?: string
};

const OwnOrRentSection = ({
  intl,
  ownOption,
  rentOption,
  notCurrentlyOwnRentOption,
  required
}: Props) => (
  <div>
    <div className="form-block">
      <div className="form-content">
        <Row>
          <Col xs={12} md={12}>
            <h3 className={classNames({ required })}>
              {intl.formatMessage(messages.title)}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <div className="form-groupbox">
              <Row>
                <Col xs={12}>
                  <div className="form-options">
                    <div className="form-option-inline">
                      <Field
                        name="ownOrRentOrNotCurrently"
                        component={renderRadioField}
                        option={ownOption}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Field
                name="lenderName"
                component={renderTextField}
                className="input-lg"
                label={intl.formatMessage(messages.lenderName)}
                placeholder={intl.formatMessage(messages.lenderName)}
                showLabel
              />
              <Field
                name="lenderPhoneNumber"
                component={renderPhoneField}
                className="input-lg"
                label={intl.formatMessage(messages.phoneNumber)}
                placeholder={intl.formatMessage(
                  messages.lenderPhoneNumberPlaceholder
                )}
                showLabel
                parse={phoneParser}
                format={phoneFormatter}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="form-groupbox">
              <Row>
                <Col xs={12}>
                  <div className="form-options">
                    <div className="form-option-inline">
                      <Field
                        name="ownOrRentOrNotCurrently"
                        component={renderRadioField}
                        option={rentOption}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Field
                name="landlordName"
                component={renderTextField}
                className="input-lg"
                label={intl.formatMessage(messages.landlordName)}
                placeholder={intl.formatMessage(messages.landlordName)}
                showLabel
              />
              <Field
                name="landlordPhoneNumber"
                component={renderPhoneField}
                className="input-lg"
                label={intl.formatMessage(messages.phoneNumber)}
                placeholder={intl.formatMessage(
                  messages.landlordPhoneNumberPlaceholder
                )}
                showLabel
                parse={phoneParser}
                format={phoneFormatter}
              />
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div className="form-groupbox">
              <Row>
                <Col xs={12}>
                  <div className="form-options">
                    <div className="form-option-inline">
                      <Field
                        name="ownOrRentOrNotCurrently"
                        component={renderRadioField}
                        option={notCurrentlyOwnRentOption}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Field
                name="doNotCurrentlyOwnRentExplanation"
                component={renderTextAreaField}
                className="input-lg"
                placeholder={intl.formatMessage(
                  messages.textAreaFieldPlaceholder
                )}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div className="form-block">
      <div className="form-content">
        <Row>
          <Col xs={12} md={12}>
            <Field
              name="reasonForMoving"
              component={renderTextAreaField}
              className="input-lg"
              label={intl.formatMessage(messages.reasonForMoving)}
              placeholder={intl.formatMessage(
                messages.textAreaFieldPlaceholder
              )}
              showLabel
              maxLength="255"
            />
          </Col>
        </Row>
      </div>
    </div>
  </div>
);

export default OwnOrRentSection;
