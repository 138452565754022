import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewUnit.UnitLeaseInformation.Title',
    defaultMessage: 'Unit Lease Information',
  },
  currentLease: {
    id: 'App.ViewUnit.UnitLeaseInformation.CurrentLease',
    defaultMessage: 'Current Lease',
  },
  priorLease: {
    id: 'App.ViewUnit.UnitLeaseInformation.PriorLease',
    defaultMessage: 'Prior Lease',
  },
  newApplicant: {
    id: 'App.ViewUnit.UnitLeaseInformation.NewApplicant',
    defaultMessage: 'New Applicant',
  },
  moveInDate: {
    id: 'App.ViewUnit.UnitLeaseInformation.MoveInDate',
    defaultMessage: 'Move-In Date',
  },
  moveOutDate: {
    id: 'App.ViewUnit.UnitLeaseInformation.MoveOutDate',
    defaultMessage: 'Move-Out Date',
  },
  leaseEndDate: {
    id: 'App.ViewUnit.UnitLeaseInformation.LeaseEndDate',
    defaultMessage: 'Lease End Date',
  },
  leaseRent: {
    id: 'App.ViewUnit.UnitLeaseInformation.LeaseRent',
    defaultMessage: 'Lease Rent',
  },
  leasedRent: {
    id: 'App.ViewUnit.UnitLeaseInformation.LeasedRent',
    defaultMessage: 'Leased Rent',
  },
  scheduledMoveIn: {
    id: 'App.ViewUnit.UnitLeaseInformation.ScheduledMoveIn',
    defaultMessage: 'Scheduled Move-In',
  },
  applicantStatus: {
    id: 'App.ViewUnit.UnitLeaseInformation.ApplicantStatus',
    defaultMessage: 'Applicant Status',
  },
  rentStartDate: {
    id: 'App.ViewUnit.UnitLeaseInformation.RentStartDate',
    defaultMessage: 'Rent Start Date',
  },
});

export default messages;
