import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
const HeaderForm = ({ noUA = false, noteRentOnly = false }) => {
  const style = {
    alignContent: 'flex-end',
    paddingBottom: '8px',
    fontWeight: 500,
  };
  const styleDates = {
    alignContent: 'flex-end',
    paddingBottom: '8px',
    width: '155px',
    fontWeight: 500,
  };

  if (noteRentOnly) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={1} sx={style} v>
          <FormattedMessage {...messages.internalName} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.marketingName} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.noteRent} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.newNoteRent} />
        </Grid>

        <Grid item xs={2} sx={style}>
          <FormattedMessage {...messages.startDate} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.rdUtilityAllowance} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.newRdUtilityAllowance} />
        </Grid>

        <Grid item xs={2} sx={style}>
          <FormattedMessage {...messages.startDate} />
        </Grid>
      </Grid>
    );
  }

  if (noUA) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={1} sx={style} v>
          <FormattedMessage {...messages.internalName} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.marketingName} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.noteRent} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.newNoteRent} />
        </Grid>

        <Grid item xs={2} sx={style}>
          <FormattedMessage {...messages.startDate} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.basicRent} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.newBasicRent} />
        </Grid>

        <Grid item xs={2} sx={style}>
          <FormattedMessage {...messages.startDate} />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.internalName} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.marketingName} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.noteRent} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.newNoteRent} />
        </Grid>

        <Grid item sx={styleDates}>
          <FormattedMessage {...messages.startDate} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.basicRent} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.newBasicRent} />
        </Grid>

        <Grid item sx={styleDates}>
          <FormattedMessage {...messages.startDate} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.rdUtilityAllowance} />
        </Grid>

        <Grid item xs={1} sx={style}>
          <FormattedMessage {...messages.newRdUtilityAllowance} />
        </Grid>

        <Grid item sx={styleDates}>
          <FormattedMessage {...messages.startDate} />
        </Grid>
      </Grid>
    </>
  );
};

export default HeaderForm;
