import React from 'react';
import { Field } from 'redux-form';

import {
  renderSelectField,
  renderTextField,
  renderPhoneField,
  renderEmailField,
  phoneParser,
  phoneFormatter,
} from '../../../../../../utils/redux-form-helper';

import { YES_NO_SELECT_OPTIONS } from '../../constants';

import { FlexFormInput } from '../../common';

const LeasingCommissionsOptionsFormFields = ({ values }: Object) => {
  return (
    <React.Fragment>
      <FlexFormInput>
        <Field
          name="Landlord Rep Name"
          component={renderTextField}
          label="Landlord Rep Name"
          maxLength="255"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Landlord Rep Phone"
          component={renderPhoneField}
          label="Landlord Rep Phone"
          parse={phoneParser}
          format={phoneFormatter}
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Landlord Rep Email"
          className="input-md form-control"
          component={renderEmailField}
          label="Landlord Rep Email"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Tenant Rep Name"
          component={renderTextField}
          label="Tenant Rep Name"
          maxLength="255"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Tenant Rep Phone"
          component={renderPhoneField}
          label="Tenant Rep Phone"
          parse={phoneParser}
          format={phoneFormatter}
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Tenant Rep Email"
          className="input-md form-control"
          component={renderEmailField}
          label="Tenant Rep Email"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Commission Terms"
          component={renderTextField}
          label="Commission Terms"
          maxLength="255"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Agents Tied to Renewals?"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Agents Tied to Renewals?"
          options={YES_NO_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Payment Terms"
          component={renderTextField}
          label="Payment Terms"
          maxLength="255"
          showLabel
        />
      </FlexFormInput>
    </React.Fragment>
  );
};

export default LeasingCommissionsOptionsFormFields;
