import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Field } from 'redux-form';

import { renderCheckboxField } from '../../../utils/redux-form-helper';
import messages from './messages';
import SubmitButton from '../../SubmitButton';

type Props = {
  intl: Object,
  handleCancel: Function,
  handleSubmit: Function,
  confirmSubmit?: Function,
  isSubmitting?: boolean,
  isComplete?: boolean,
  isFormDisabled?: boolean,
};

const SignSection = ({
  intl,
  handleCancel,
  handleSubmit,
  confirmSubmit,
  isSubmitting,
  isComplete,
  isFormDisabled,
}: Props) => (
  <div className="form-content">
    <Row>
      <Col xs={12}>
        <div className="form-footer">
          <Field
            name="hasSignedCopy"
            component={renderCheckboxField}
            label={`${intl.formatMessage(messages.signatureLabel)} *`}
            disabled={isFormDisabled}
          />
          <div className="button-group">
            <Button bsStyle="default" onClick={handleCancel}>
              {intl.formatMessage(messages.cancel)}
            </Button>
            {!isComplete && (
              <SubmitButton
                bsStyle="primary"
                clickHandler={handleSubmit}
                isSubmitting={isSubmitting}
                disabled={false}
              >
                {intl.formatMessage(messages.saveProgress)}
              </SubmitButton>
            )}
            <SubmitButton
              bsStyle="primary"
              clickHandler={confirmSubmit ? confirmSubmit : () => {}}
              isSubmitting={isSubmitting}
              disabled={isFormDisabled}
            >
              {intl.formatMessage(messages.complete)}
            </SubmitButton>
          </div>
        </div>
      </Col>
    </Row>
  </div>
);

export default SignSection;
