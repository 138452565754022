const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class LedgerService {
  filterQueryString = {
    exactDate: null,
    toDate: null,
    fromDate: null,
    types: [],
    statuses: [],
  };

  getLedgerHeader(
    organizationId: string,
    propertyId: string,
    customerId: string,
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/ledgerHeader?customerId=${customerId}`,
      options,
    );
  }

  getAllSubjournals(
    organizationId: string,
    propertyId: string,
    customerId: string,
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/subjournals?customerId=${customerId}`,
      options,
    );
  }

  getTransactions(
    organizationId: string,
    propertyId: string,
    pageNumber: number,
    ledger: string,
    customerId: string,
    filterReversed: boolean,
    subjournal: string = '',
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    const subjournals =
      ledger === 'opsLedger' && subjournal !== ''
        ? `&subjournalId=${subjournal}`
        : '';
    const customer = `customerId=${customerId}`;
    const filter = `&filterReversed=${filterReversed.toString()}`;
    const page = `page=${pageNumber}&limit=15`;
    const queryString = `${customer}&${page}${filter}${subjournals}`;
    return request(
      `${apiURL}/${organizationId}/${propertyId}/${ledger}?${queryString}`,
      options,
    );
  }

  getTransactionCodesForCustomer(
    organizationId: string,
    propertyId: string,
    ledger: string,
    customerId: string,
    filterReversed: boolean,
    limit: number,
    options: Object = {},
  ) {
    const opts = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };
    const ledgerType = ledger === 'ops' ? 'opsLedger' : 'securityLedger';
    const filterReversedStr = `${filterReversed.toString()}`;
    // eslint-disable-next-line max-len
    const queryString = `${`customerId=${customerId}&filterReversed=${filterReversedStr}&limit=${limit}&page=1&pagination=true`}`;
    return request(
      `${apiURL}/${organizationId}/${propertyId}/${ledgerType}/transactionCodes?${queryString}`,
      opts,
    );
  }

  getTransactionsFiltered(
    organizationId: string,
    propertyId: string,
    pageNumber: number,
    ledger: string,
    customerId: string,
    filterReversed: boolean,
    filters: Object = null,
    options: Object = {},
    subjournal: string = '',
    isDateRangeChecked: boolean,
    isAmountRangeChecked: boolean,
    limit: number,
  ) {
    const opts = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };
    const ledgerType = ledger === 'ops' ? 'opsLedger' : 'securityLedger';
    const subjournals =
      ledgerType === 'opsLedger' && subjournal !== ''
        ? `&subjournalId=${subjournal}`
        : '';
    const customer = `customerId=${customerId}`;
    const filter = `&filterReversed=${filterReversed.toString()}`;
    const page = `page=${pageNumber}&limit=${limit}`;
    let filterString = '';

    if (filters.exactDate && !isDateRangeChecked) {
      filterString += filterString += '&exactDateFilter=' + filters.exactDate;
    }

    if (filters.toDate && isDateRangeChecked) {
      const fromDate = filters.fromDate || filters.exactDate;
      filterString +=
        '&fromDateFilter=' + fromDate + '&toDateFilter=' + filters.toDate;
    }

    if (filters.periods.length > 0) {
      filterString += '&propertyFiscalPeriodsId=' + filters.periods.join(',');
    }

    if (filters.transactionCodes.length > 0) {
      filterString +=
        '&propertyTransactionCodeId=' + filters.transactionCodes.join(',');
    }

    if (filters.transactionTypes.length > 0) {
      filterString +=
        '&propertyTransactionTypeId=' + filters.transactionTypes.join(',');
    }

    if (filters.exactAmount && !isAmountRangeChecked) {
      filterString += '&transactionAmount=' + filters.exactAmount;
    }

    if (filters.toAmount && isAmountRangeChecked) {
      const fromAmount = filters.fromAmount || filters.exactAmount;

      if (parseInt(fromAmount) >= parseInt(filters.toAmount)) return;
      filterString +=
        '&fromTransactionAmount=' +
        fromAmount +
        '&toTransactionAmount=' +
        filters.toAmount;
    }

    if (filters.searchTerm) {
      filterString += '&searchText=' + filters.searchTerm;
    }

    const queryString = `${customer}&${page}${filter}${subjournals}${filterString}`;

    this.filterQueryString = `${filter}${filterString}`;

    return request(
      `${apiURL}/${organizationId}/${propertyId}/${ledgerType}?${queryString}`,
      opts,
    );
  }
}

export default LedgerService;
