const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class PermissionService {
  getAllPermissions() {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(`${apiURL}/permissions`, options);
  }
}

export default PermissionService;
