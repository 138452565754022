import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = ({ location }) => {
  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, [location]);

  return null;
};

export default withRouter(ScrollToTop);
