import React, { useContext } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNil } from 'ramda';
import { AppContext } from '../../context';
import PrivateRoute from '../../../../components/PrivateRoute';
import ApplicationProfile from '../../../ApplicationProfile';
import CreateCommercialApplicant from '../../../CreateCommercialApplicant';
import AddGroup from '../../../CreateTransactionBatch/AddGroup';
import GenerateRenewalOffer from '../../../GenerateRenewalOffer';
import ForecastRents from '../../../ForecastRents';
import Home from '../../../Home';
import HouseholdProfile from '../../../HouseholdProfile';
import ManageBuildings from '../../../ManageBuildings';
import ManageCommercialRentRoll from '../../../ManageCommercialRentRoll';
import ManageCommunications from '../../../ManageCommunications';
import CreateCommunication from '../../../ManageCommunications/CreateCommunication';
import ManageDelinquency from '../../../ManageDelinquency';
import ManageDelinquencyV2 from '../../../ManageDelinquency/indexV2';
import ManageFloorPlans from '../../../ManageFloorPlans/indexv2';
import ManageProperties from '../../../ManageProperties';
import ManageProspects from '../../../ManageProspects';
import ManageProspectsV2 from '../../../ManageProspects/V2';
import ManageRentRoll from '../../../ManageRentRoll';
import ManageRentRollV2 from '../../../ManageRentRoll/indexV2';
import ManageReport from '../../../ManageReports';
import ManageUnitAvailability from '../../../ManageUnitAvailability';
import ManageUnitAvailabilityV2 from '../../../ManageUnitAvailabilityV2';
import ManageWorkOrders from '../../../ManageWorkOrders';
import ManageWorkOrdersV2 from '../../../ManageWorkOrdersV2';
import PeopleProfile from '../../../PeopleProfile';
import ProspectProfile from '../../../ProspectProfile';
import QualificationForms from '../../../QualificationForms';
import PriorQualificationForms from '../../../QualificationForms/PriorQualificationForms';
import ResidentProfile from '../../../ResidentProfile';
import CompleteRenewalPage from '../../../ResidentProfile/CompleteRenewal';
import ViewCommunications from '../../../ViewCommunications';
import ViewProperty from '../../../ViewProperty';
import ViewUnit from '../../../ViewUnit';
import WorkOrder from '../../../WorkOrder';
import AddAmenity from '../../../Amenity/AddAmenity';
import EditAmenity from '../../../Amenity/EditAmenity';
import CreateApplicationPage from '../../../CreateApplication';
import CreateChargeBatch from '../../../CreateChargeBatch';
import CreateCommercialTenantPage from '../../../CreateCommercialTenant';
import CreateResidentPage from '../../../CreateResident';
import CreateRolePage from '../../../CreateRole';
import CreateSubsidyBatch from '../../../CreateSubsidyBatch';
import CreateTransactionBatch from '../../../CreateTransactionBatch';
import CreateUserPage from '../../../CreateUser';
import EditApplicationHousehold from '../../../EditApplicationHousehold';
import EditMasterTransactionCodes from '../../../EditMasterTransactionCode';
import EditProspectHousehold from '../../../EditProspectHousehold';
import EditResidentHousehold from '../../../EditResidentHousehold';
import EditRolePage from '../../../EditRole';
import EditUserPage from '../../../EditUser';
import FinalAccountStatement from '../../../FinalAccountStatement';
import ManageAmenities from '../../../ManageAmenities';
import ManageApplicationsPageV2 from '../../../ManageApplications/indexV2';
import ManageCertifications from '../../../ManageCertifications';
import ManageCertificationsV2 from '../../../ManageCertifications/indexV2';
import ManageDeposits from '../../../ManageDeposits';
import ManageLeaseExpirationLimits from '../../../ManageLeaseExpirationLimits';
import ManageLeaseExpirations from '../../../ManageLeaseExpirations';
import ManageMiscTransactions from '../../../ManageMiscTransactions';
import ManageOnlinePayments from '../../../ManageOnlinePayments';
import ManagePaymentBatch from '../../../ManagePaymentBatch';
import ManagePriorResidents from '../../../ManagePriorResidents';
import ManagePriorResidentsV2 from '../../../ManagePriorResidents/indexV2';
import ManagePropertyGroups from '../../../ManagePropertyGroups';
import AddEditPropertyGroup from '../../../ManagePropertyGroups/AddEditPropertyGroup';
import ManageRolesPage from '../../../ManageRoles';
import ManageSubsidyPayments from '../../../ManageSubsidyPayments';
import ManageTransactionBatches from '../../../ManageTransactionBatches/index.js';
import ManageTransactionCodes from '../../../ManageTransactionCodes';
import ManageUndepositedPayments from '../../../ManageUndepositedPayments';
import ManageUsersPage from '../../../ManageUsers';
import MonthEndClose from '../../../MonthEndClose';
import CreatePropertyDataBatch from '../../../PropertyDataBatch';
import ProrateCalculator from '../../../ProrateCalculator';
import ViewBankDeposit from '../../../ViewBankDeposit';
import AllManageLeaseExpirations from '../../../AllManageLeaseExpirations';
import AllManageReports from '../../../AllManageReports';
import AllUnitAvailability from '../../../AllUnitAvailability';
import AllWorkOrders from '../../../AllWorkOrders';
import ManageFees from '../../../ManageFees';
import CentralizedManageDocuments from '../../../CentralizedManageDocuments';
import AddDocument from '../../../AddDocument';
import EditDocument from '../../../EditDocument';
import CentralizedManageDelinquency from '../../../CentralizedManageDelinquency';

function PageContent() {
  const {
    selectedProperty,
    permissions,
    userOrganizationId: organizationId,
  } = useContext(AppContext);
  const {
    transactionBatchV1: enableTransactionBatchesRoute = false,
    transactionBatchV2Addgroup: enableTxnBatchesAddGroupRoute = false,
    leaseExpirationMgmtV1 = false,
    prorateCalculatorV1 = false,
    paymentsV1: enablePaymentsV1 = false,
    adminEnhancementsPropertiesManagegroups: enableManagePropertyGroups = false,
    customerOverhaul: enableHouseholdProfile = false,
    manageAmenitiesV1,
    centralizedWorkOrders: enableCentralizedWorkOrdersRoute = false,
    centralizedUnitAvailability,
    manageRentRollNewTable: enableManageRentRollNewTable = false,
    centralizedExpiringLeases = false,
    manageDelinquentAndPrepaidTableConversion = false,
    centralizedReports = false,
    newManageCertTable = false,
    manageProspectsNewTable = false,
    managePriorResidentsTableConversion = false,
    manageWorkOrdersTableConversion: isManageWorkOrdersV2On,
    propertyMuaUpdate: propertyMuaUpdateFlag,
    forecastRents = false,
    centralizedDelinquencyAndPrepaid = false,
  } = useFlags();

  const enableProrateCalculatorRoute =
    !isNil(selectedProperty) && // Prorate calculator needs a selected property since it uses prop tx codes
    prorateCalculatorV1;

  const enableForecastRentsRoute = !isNil(selectedProperty) && forecastRents;

  return (
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        exact
        path="/property/:propertyId"
        component={Home}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read']}
        exact
        path="/property/:propertyId/management"
        component={
          manageProspectsNewTable ? ManageProspectsV2 : ManageProspects
        }
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-create']}
        exact
        path="/property/:propertyId/prospect"
        component={ProspectProfile}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read', 'prospect-update', 'prospect-assign']}
        exact
        path="/property/:propertyId/prospect/:prospectId"
        component={PeopleProfile}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read', 'prospect-update']}
        exact
        path="/property/:propertyId/prospect/:prospectId/household"
        component={EditProspectHousehold}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read', 'prospect-update', 'prospect-assign']}
        exact
        path="/property/:propertyId/application/:applicationId"
        component={ApplicationProfile}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['resident-read', 'resident-edit', 'resident-create']}
        exact
        path="/property/:propertyId/resident/:residentId"
        component={ResidentProfile}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['resident-read', 'resident-edit', 'resident-create']}
        exact
        path="/property/:propertyId/resident/:residentId/household"
        component={EditResidentHousehold}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['resident-read', 'resident-edit', 'resident-create']}
        exact
        path="/property/:propertyId/prior-resident/:residentId"
        component={ResidentProfile}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['final-account-statement-create']}
        exact
        path="/property/:propertyId/prior-resident/:residentId/final-account-statement"
        component={FinalAccountStatement}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['resident-read', 'resident-edit', 'resident-create']}
        exact
        path="/property/:propertyId/manage-prior-residents"
        component={
          managePriorResidentsTableConversion
            ? ManagePriorResidentsV2
            : ManagePriorResidents
        }
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read', 'prospect-update', 'prospect-assign']}
        exact
        path="/property/:propertyId/application/:applicationId/household"
        component={EditApplicationHousehold}
      />
      <PrivateRoute
        userPermissions={permissions}
        scope={['user-read']}
        exact
        path="/manage-users"
        component={ManageUsersPage}
      />
      {manageAmenitiesV1 && (
        <PrivateRoute
          userPermissions={permissions}
          selectedProperty={selectedProperty}
          scope={['amenities-view-add-edit']}
          exact
          path="/property/:propertyId/manage-amenities"
          component={ManageAmenities}
        />
      )}
      {manageAmenitiesV1 && (
        <PrivateRoute
          userPermissions={permissions}
          selectedProperty={selectedProperty}
          scope={['amenities-view-add-edit']}
          exact
          path="/property/:propertyId/amenity"
          component={AddAmenity}
        />
      )}
      {manageAmenitiesV1 && (
        <PrivateRoute
          userPermissions={permissions}
          selectedProperty={selectedProperty}
          scope={['amenities-view-add-edit']}
          exact
          path="/property/:propertyId/amenity/:propertyFeeId"
          component={EditAmenity}
        />
      )}
      <PrivateRoute
        userPermissions={permissions}
        scope={['user-create']}
        exact
        path="/add-user"
        component={CreateUserPage}
      />
      <PrivateRoute
        userPermissions={permissions}
        scope={['user-read', 'user-update', 'user-delete']}
        exact
        path="/edit-user/:userId"
        component={EditUserPage}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['workorder-create']}
        exact
        path="/property/:propertyId/add-work-order"
        component={WorkOrder}
      />
      <PrivateRoute
        userPermissions={permissions}
        scope={['role-create']}
        exact
        path="/add-role"
        component={CreateRolePage}
      />
      <PrivateRoute
        userPermissions={permissions}
        scope={['role-read', 'role-update', 'role-delete']}
        exact
        path="/edit-role/:roleId"
        component={EditRolePage}
      />
      <PrivateRoute
        userPermissions={permissions}
        scope={['role-read']}
        exact
        path="/manage-roles"
        component={ManageRolesPage}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read']}
        exact
        path="/property/:propertyId/manage-applications"
        component={ManageApplicationsPageV2}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-create']}
        exact
        path="/property/:propertyId/create-application/:prospectId"
        component={CreateApplicationPage}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read', 'prospect-create']}
        exact
        path="/property/:propertyId/create-resident/:applicationId"
        component={CreateResidentPage}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read', 'prospect-create']}
        exact
        path="/property/:propertyId/create-commercial-tenant/:applicationId"
        component={CreateCommercialTenantPage}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read', 'prospect-create']}
        exact
        path="/property/:propertyId/complete-lease-renewal/:residentId/:leaseId"
        component={CompleteRenewalPage}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['manage-delinquent-and-prepaid-read']}
        exact
        path="/property/:propertyId/manage-delinquent-and-prepaid"
        component={
          manageDelinquentAndPrepaidTableConversion
            ? ManageDelinquencyV2
            : ManageDelinquency
        }
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['workorder-read']}
        exact
        path="/property/:propertyId/manage-work-orders"
        component={
          isManageWorkOrdersV2On ? ManageWorkOrdersV2 : ManageWorkOrders
        }
      />
      {enableCentralizedWorkOrdersRoute && (
        <PrivateRoute
          userPermissions={permissions}
          organizationId={organizationId}
          scope={['workorder-read']}
          exact
          path="/work-orders"
          component={AllWorkOrders}
        />
      )}
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['workorder-read']}
        exact
        path="/property/:propertyId/edit-work-order/:workOrderId"
        component={WorkOrder}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['deposits-read']}
        exact
        path="/property/:propertyId/manage-deposits"
        component={ManageDeposits}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['deposits-read']}
        exact
        path="/property/:propertyId/deposit/:depositId"
        component={ViewBankDeposit}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['misc-income-read', 'misc-income-create', 'misc-income-edit']}
        exact
        path="/property/:propertyId/manage-misc-income"
        component={ManageMiscTransactions}
      />
      <PrivateRoute
        userPermissions={permissions}
        scope={['month-end-close']}
        selectedProperty={selectedProperty}
        exact
        path="/property/:propertyId/month-end-close"
        component={MonthEndClose}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['charge-batch-create']}
        exact
        path="/property/:propertyId/create-charge-batch"
        component={CreateChargeBatch}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['charge-batch-create']}
        exact
        path="/property/:propertyId/property-data-batch"
        component={CreatePropertyDataBatch}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['payment-batch-manage']}
        exact
        path="/property/:propertyId/payment-batch/:batchId?"
        component={ManagePaymentBatch}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['payment-batch-manage']}
        exact
        path="/property/:propertyId/manage-undeposited-payments"
        component={ManageUndepositedPayments}
      />
      {enableForecastRentsRoute && (
        <PrivateRoute
          userPermissions={permissions}
          selectedProperty={selectedProperty}
          // placeholder permission
          // scope={['payment-batch-manage']}
          exact
          path="/property/:propertyId/forecast-rents"
          component={ForecastRents}
        />
      )}
      <PrivateRoute
        userPermissions={permissions}
        scope={['property-read', 'property-update']}
        exact
        path="/properties"
        component={ManageProperties}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['manage-units-read']}
        exact
        path="/property/:propertyId/manage-rent-roll"
        component={
          enableManageRentRollNewTable ? ManageRentRollV2 : ManageRentRoll
        }
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['manage-units-read']}
        exact
        path="/property/:propertyId/manage-commercial-rent-roll"
        component={ManageCommercialRentRoll}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['manage-units-read']}
        exact
        path="/property/:propertyId/manage-unit-availability"
        component={
          propertyMuaUpdateFlag
            ? ManageUnitAvailabilityV2
            : ManageUnitAvailability
        }
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['manage-units-read']}
        exact
        path="/property/:propertyId/manage-lease-expirations"
        component={ManageLeaseExpirations}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['manage-units-read']}
        exact
        path="/property/:propertyId/manage-certifications"
        component={
          newManageCertTable ? ManageCertificationsV2 : ManageCertifications
        }
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['manage-units-read']}
        exact
        path="/property/:propertyId/unit/:unitId"
        component={ViewUnit}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['floor-plan-read']}
        exact
        path="/property/:propertyId/floorplan/:floorplanId"
        component={ViewUnit}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['manage-reports-read']}
        exact
        path="/property/:propertyId/manage-reports"
        component={ManageReport}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        exact
        path="/property/:propertyId/communications"
        component={ViewCommunications}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['lease-create', 'lease-read']}
        exact
        path="/property/:propertyId/generate-renewal-offer/:residentId"
        component={GenerateRenewalOffer}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read', 'prospect-update', 'prospect-assign']}
        exact
        // eslint-disable-next-line
        path="/property/:propertyId/affordable-qualifications/:affordableQualificationId/affordable-programs/:programName/income-verification/:applicantId"
        component={QualificationForms}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read', 'prospect-update', 'prospect-assign']}
        exact
        // eslint-disable-next-line
        path="/property/:propertyId/affordable-qualifications/:affordableQualificationId/affordable-programs/:programName/asset-information/:applicantId"
        component={QualificationForms}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read', 'prospect-update', 'prospect-assign']}
        exact
        // eslint-disable-next-line max-len
        path="/property/:propertyId/prior-income/:applicationId/:qualificationHistoryId/:checklistId/:residentId"
        component={PriorQualificationForms}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-read', 'prospect-update', 'prospect-assign']}
        exact
        // eslint-disable-next-line max-len
        path="/property/:propertyId/prior-assets/:applicationId/:qualificationHistoryId/:checklistId/:residentId"
        component={PriorQualificationForms}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['floor-plan-read']}
        exact
        path="/property/:propertyId/manage-floor-plan"
        component={ManageFloorPlans}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['manage-fees-view']}
        exact
        path="/property/:propertyId/manage-fees"
        component={ManageFees}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        exact
        path="/property/:propertyId/manage-communications"
        component={ManageCommunications}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['communication-create']}
        exact
        path="/property/:propertyId/create-communication"
        component={CreateCommunication}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['communication-create']}
        exact
        path="/property/:propertyId/view-communication"
        component={CreateCommunication}
      />
      <PrivateRoute
        userPermissions={permissions}
        scope={['transaction-codes-edit', 'transaction-codes-read']}
        exact
        path="/manage-transaction-codes"
        component={ManageTransactionCodes}
      />
      <PrivateRoute
        userPermissions={permissions}
        scope={['transaction-codes-edit', 'transaction-codes-read']}
        exact
        path="/edit-transaction-codes/:masterTransactionCodeId"
        component={EditMasterTransactionCodes}
      />
      <PrivateRoute
        userPermissions={permissions}
        scope={['property-read', 'property-update']}
        exact
        path="/properties/:propertyId"
        component={ViewProperty}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        exact
        path="/property/:propertyId/manage-buildings"
        component={ManageBuildings}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        scope={['prospect-create']}
        exact
        path="/property/:propertyId/commercial-applicant"
        component={CreateCommercialApplicant}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        exact
        path="/property/:propertyId/manage-subsidy-payments"
        component={ManageSubsidyPayments}
      />
      <PrivateRoute
        userPermissions={permissions}
        selectedProperty={selectedProperty}
        exact
        path="/property/:propertyId/create-subsidy-batch/:paymentBatchHeaderId?"
        component={CreateSubsidyBatch}
      />
      {leaseExpirationMgmtV1 && (
        <PrivateRoute
          userPermissions={permissions}
          selectedProperty={selectedProperty}
          scope={['lease-expirations-limit-view']}
          exact
          path="/:organizationId/manage-lease-expiration-limits/:propertyId"
          component={ManageLeaseExpirationLimits}
        />
      )}
      {enableTransactionBatchesRoute && (
        <PrivateRoute
          userPermissions={permissions}
          selectedProperty={selectedProperty}
          exact
          path="/property/:propertyId/manage-transaction-batches"
          component={ManageTransactionBatches}
        />
      )}
      {enableTransactionBatchesRoute && (
        <PrivateRoute
          userPermissions={permissions}
          selectedProperty={selectedProperty}
          exact
          path="/property/:propertyId/create-transaction-batch/:transactionBatchHeaderId?"
          component={CreateTransactionBatch}
        />
      )}
      {enableTxnBatchesAddGroupRoute && (
        <PrivateRoute
          userPermissions={permissions}
          selectedProperty={selectedProperty}
          exact
          path="/property/:propertyId/add-group/:transactionBatchHeaderId?"
          component={AddGroup}
        />
      )}
      {enableProrateCalculatorRoute && (
        <PrivateRoute
          userPermissions={permissions}
          selectedProperty={selectedProperty}
          exact
          path="/property/:propertyId/prorate-calculator"
          component={ProrateCalculator}
        />
      )}
      {enablePaymentsV1 && (
        <PrivateRoute
          userPermissions={permissions}
          selectedProperty={selectedProperty}
          exact
          path="/property/:propertyId/manage-online-payments"
          component={ManageOnlinePayments}
        />
      )}
      {enableHouseholdProfile && (
        <PrivateRoute
          userPermissions={permissions}
          organizationId={organizationId}
          exact
          path="/property/:propertyId/household/:householdId"
          component={HouseholdProfile}
        />
      )}
      {enableManagePropertyGroups && [
        <PrivateRoute
          key="manage-property-groups"
          userPermissions={permissions}
          organizationId={organizationId}
          exact
          path="/manage-property-groups"
          component={ManagePropertyGroups}
        />,
        <PrivateRoute
          key="create-property-group"
          userPermissions={permissions}
          organizationId={organizationId}
          exact
          path="/create-property-group"
          component={AddEditPropertyGroup}
        />,
        <PrivateRoute
          key="property-group"
          userPermissions={permissions}
          organizationId={organizationId}
          exact
          path="/property-group/:propertyGroupId"
          component={AddEditPropertyGroup}
        />,
      ]}
      {centralizedUnitAvailability && (
        <PrivateRoute
          userPermissions={permissions}
          organizationId={organizationId}
          scope={['manage-units-read']}
          exact
          path="/manage-unit-availability"
          component={AllUnitAvailability}
        />
      )}
      {centralizedExpiringLeases && (
        <PrivateRoute
          userPermissions={permissions}
          organizationId={organizationId}
          scope={['manage-units-read']}
          exact
          path="/lease-expirations"
          component={AllManageLeaseExpirations}
        />
      )}
      {centralizedDelinquencyAndPrepaid && (
        <PrivateRoute
          userPermissions={permissions}
          scope={['manage-delinquent-and-prepaid-read']}
          exact
          path="/manage-delinquent-and-prepaid"
          component={CentralizedManageDelinquency}
        />
      )}
      {centralizedReports && (
        <PrivateRoute
          userPermissions={permissions}
          organizationId={organizationId}
          scope={['manage-reports-read']}
          exact
          path="/manage-reports"
          component={AllManageReports}
        />
      )}
      <PrivateRoute
        userPermissions={permissions}
        organizationId={organizationId}
        scope={['centralized-docs-read']}
        exact
        path="/manage-documents"
        component={CentralizedManageDocuments}
      />
      <PrivateRoute
        userPermissions={permissions}
        organizationId={organizationId}
        scope={['centralized-docs-read', 'centralized-docs-edit']}
        exact
        path="/add-document"
        component={AddDocument}
      />
      <PrivateRoute
        userPermissions={permissions}
        organizationId={organizationId}
        scope={['centralized-docs-read']}
        exact
        path="/documents/:documentId"
        component={EditDocument}
      />
      <Route>
        <Redirect to="/404" />
      </Route>
    </Switch>
  );
}

export default PageContent;
