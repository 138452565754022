import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.Overview.CurrentMonthRenewals.Title',
    defaultMessage: 'Current Month Renewals',
  },
  inProcess: {
    id: 'App.Overview.CurrentMonthRenewals.InProcess',
    defaultMessage: 'In Process', // Started
  },
  completed: {
    id: 'App.Overview.CurrentMonthRenewals.Completed',
    defaultMessage: 'Completed', // Completed
  },
  onNTV: {
    id: 'App.Overview.CurrentMonthRenewals.OnNTV',
    defaultMessage: 'On NTV', // On NTV
  },
  notStarted: {
    id: 'App.Overview.CurrentMonthRenewals.NotStarted',
    defaultMessage: 'Not Started', // Not Started
  },
  noData: {
    id: 'App.Overview.CurrentMonthRenewals.NoData',
    defaultMessage: 'There is no data yet on the current month renewals.',
  },
});

export default messages;
