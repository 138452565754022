import type { Highlight, ScheduledMoveInsEntity } from '../types';
import messages from '../messages';
import moment from 'moment';
import { getHighlightColor } from './general/getHighlightColor';
import { getUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';

type ScheduledMoveInHeaderArgs = {
  intl: Object,
  taskTabNumberOfBedroomsFlag: boolean,
};

type ScheduledMoveInHeader = {
  id: number,
  text: Object,
  // eslint-disable-next-line flowtype/space-after-type-colon
  sortKey:
    | 'unit'
    | 'numberOfBedrooms'
    | 'applicant'
    | 'moveInDate'
    | 'assignedTo',
};

type MappedScheduledMoveIn = {
  id: string,
  unit: string,
  numberOfBedrooms?: string,
  applicant: { link: string, value: string },
  moveInDate: string,
  assignedTo: string,
  highlight: Highlight,
};

export const mapApplicationsScheduledMoveIn = ({
  scheduledMoveIns,
  taskTabNumberOfBedroomsFlag,
}: {
  scheduledMoveIns: ScheduledMoveInsEntity[],
  taskTabNumberOfBedroomsFlag: boolean,
}): MappedScheduledMoveIn[] => {
  return scheduledMoveIns.map((task) => {
    const moveInDateString = task.move_in;
    const moveInDateMoment = moveInDateString ? moment(moveInDateString) : null;
    const userAssigned = task.assigned_to;

    const isLeaseSignedAndNotExecuted =
      task.leaseSignature && task.action !== 'EXECUTED';

    const highlight = getHighlightColor({
      checkDate: task.move_in,
      status: task.userStatus,
      isLeaseSignedAndNotExecuted,
    });

    if (taskTabNumberOfBedroomsFlag) {
      return {
        id: task.id,
        unit: task.unit || '---',
        numberOfBedrooms: String(task.nBeds ?? '---'),
        applicant: {
          link: getUrlWithSelectedPropertyId(
            `/application/${task.application_id}`,
          ),
          value: task.name || 'N/A',
        },
        moveInDate: moveInDateMoment
          ? moveInDateMoment.format('MM/DD/YYYY')
          : 'N/A',
        assignedTo: userAssigned ? userAssigned : 'N/A',
        highlight,
      };
    }

    return {
      id: task.id,
      unit: task.unit || '---',
      applicant: {
        link: `/application/${task.application_id}`,
        value: task.name || 'N/A',
      },
      moveInDate: moveInDateMoment
        ? moveInDateMoment.format('MM/DD/YYYY')
        : 'N/A',
      assignedTo: userAssigned ? userAssigned : 'N/A',
      highlight,
    };
  });
};

export const scheduledMoveInsHeaders = ({
  intl,
  taskTabNumberOfBedroomsFlag,
}: ScheduledMoveInHeaderArgs): ScheduledMoveInHeader[] => {
  return taskTabNumberOfBedroomsFlag
    ? [
        {
          id: 1,
          text: intl.formatMessage(messages.unitHeader),
          sortKey: 'unit',
        },
        {
          id: 2,
          text: intl.formatMessage(messages.numberOfBedroomsHeader),
          sortKey: 'numberOfBedrooms',
        },
        {
          id: 3,
          text: intl.formatMessage(messages.applicantHeader),
          sortKey: 'applicant',
        },
        {
          id: 4,
          text: intl.formatMessage(messages.scheduledMoveInDateHeader),
          sortKey: 'moveInDate',
        },
        {
          id: 5,
          text: intl.formatMessage(messages.assignedToHeader),
          sortKey: 'assignedTo',
        },
      ]
    : [
        {
          id: 1,
          text: intl.formatMessage(messages.unitHeader),
          sortKey: 'unit',
        },
        {
          id: 2,
          text: intl.formatMessage(messages.applicantHeader),
          sortKey: 'applicant',
        },
        {
          id: 3,
          text: intl.formatMessage(messages.scheduledMoveInDateHeader),
          sortKey: 'moveInDate',
        },
        {
          id: 4,
          text: intl.formatMessage(messages.assignedToHeader),
          sortKey: 'assignedTo',
        },
      ];
};
