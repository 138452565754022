import React from 'react';
import MiniProfileFormPanel from '../../MiniProfileFormPanel';

const PANEL_TITLE = 'PayLease';

type Props = {
  openPanel: ?string,
  togglePanel: Function,
  plAccount: string,
  sendPayLeaseEmail: Function,
  noEmailAddress: boolean,
};

export const PayLeaseEmail = ({
  openPanel,
  togglePanel,
  plAccount,
  sendPayLeaseEmail,
  noEmailAddress,
}: Props) => {
  return (
    <MiniProfileFormPanel
      open={openPanel === PANEL_TITLE}
      toggleOpen={togglePanel(PANEL_TITLE)}
      title={PANEL_TITLE}
    >
      <div className="container-fluid padleft5 padright5 padtop15 padbottom15 text-center">
        <div className="row">
          {plAccount ? (
            <div className="col-xs-12">
              <p>
                <strong>PayLease Customer Account Number:</strong>
              </p>
              <p>{plAccount}</p>
            </div>
          ) : (
            <div className="col-xs-12">
              <p>
                <strong>No PayLease Account Found</strong>
              </p>
            </div>
          )}
        </div>
        <button
          className="btn btn-primary"
          onClick={() => sendPayLeaseEmail(plAccount)}
          disabled={noEmailAddress || !plAccount}
        >
          <i className="icon et-mail" />
          Resend Registration Email
        </button>
        {noEmailAddress && (
          <div className="row">
            <div className="col-xs-12 text-center">
              <small className="text-gray--darker">
                <span>
                  Resident must have an email address to receive PayLease email
                </span>
              </small>
            </div>
          </div>
        )}
      </div>
    </MiniProfileFormPanel>
  );
};

export default PayLeaseEmail;
