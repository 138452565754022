import React, { useState } from 'react';
import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';
import Styled from 'styled-components';

import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import HudLimitsForm from './HudLimitsForm';
import Spinner from '../../../components/Spinner';
import messages from './messages';
import {
  useFetchAsyncFloorPlansWithPricing,
  useAsyncBatchCreateFloorPlansPricing,
} from './hooks';

const StyledModal = Styled(Modal)`
@media (min-width: 992px) {
  .modal-lg {
    width: 920px;
  }
}
`;

const HudLimitsModal = ({
  intl,
  show,
  onClose,
  isAffordableMixedProperty,
  propertyId,
  organizationId,
  dispatch,
  promptToaster,
}: Object) => {
  const [isValidForm, setIsValidForm] = useState(false);
  const [isEmptyForm, setIsEmptyForm] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [formValues, setFormValues] = useState([]);

  const handleClose = (didHudLimitsChanged?: boolean = false) => {
    dispatch(reset('hudLimitsForm'));
    onClose(didHudLimitsChanged);
  };

  const { isLoading, floorPlans, initialFormValues } =
    useFetchAsyncFloorPlansWithPricing(
      organizationId,
      propertyId,
      show,
      promptToaster,
    );

  const { isSubmitting } = useAsyncBatchCreateFloorPlansPricing(
    organizationId,
    propertyId,
    formValues,
    handleClose,
    promptToaster,
  );

  const onFormSubmit = (values: Array<Object>) => {
    setFormValues(values);
  };

  const handleSubmit = () => {
    dispatch(submit('hudLimitsForm'));
  };

  return (
    <form>
      <StyledModal
        className="modal transaction"
        backdrop
        bsSize="lg"
        show={show}
        onHide={() => handleClose(false)}
      >
        <Modal.Header closeButton>
          <i className="icon et-money" />
          <Modal.Title componentClass="h1">
            <FormattedMessage {...messages.changeHudLimitsTitle} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="scrollable-modal__content row">
            <HudLimitsForm
              floorPlans={floorPlans}
              intl={intl}
              isLoading={isLoading}
              initialValues={initialFormValues}
              onFormSubmit={onFormSubmit}
              formState={{
                setIsEmptyForm,
                setIsValidForm,
                setIsFormSubmitting,
              }}
            />
          </div>
        </Modal.Body>
        <div
          className="modal-footer"
          style={{ border: 'none', padding: '0', margin: '5px' }}
        >
          <div className="col-xs-1 text-right" style={{ fontWeight: 500 }}>
            <FormattedMessage {...messages.notes} tagName="p" />
          </div>
          <div className="col-xs-11 text-left">
            <FormattedMessage {...messages.note1} tagName="p" />
            <FormattedMessage {...messages.note2} tagName="p" />
          </div>
        </div>
        <div className="modal-footer">
          {isSubmitting ? (
            <div className="row">
              <div className="col-xs-12">
                <Spinner />
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-xs-6">
                <button
                  className="btn btn-default pull-right"
                  onClick={() => handleClose(false)}
                  disabled={isSubmitting}
                >
                  <FormattedMessage {...messages.cancel} />
                </button>
              </div>
              <div className="col-xs-6">
                <button
                  disabled={
                    isEmptyForm ||
                    !isValidForm ||
                    isFormSubmitting ||
                    isSubmitting
                  }
                  className="btn btn-primary pull-left"
                  type="submit"
                  onClick={handleSubmit}
                >
                  <FormattedMessage {...messages.save} />
                </button>
              </div>
            </div>
          )}
        </div>
      </StyledModal>
    </form>
  );
};

export default connect()(HudLimitsModal);
