const { get, put } = require('../utils/api');

export default class CamRecordService {
  getAll(
    organizationId: string,
    propertyId: string,
    camRecordId: string,
    signal?: Object,
  ) {
    const options = signal ? { signal } : {};
    return get(
      `/${organizationId}/${propertyId}/cam-records/${camRecordId}/cam-allocations`,
      options,
    );
  }

  modify(
    organizationId: string,
    propertyId: string,
    camRecordId: string,
    camAllocationId: string,
    payload: Object,
  ) {
    return put(
      `/${organizationId}/${propertyId}/cam-records/${camRecordId}/cam-allocations/${camAllocationId}`,
      JSON.stringify(payload),
    );
  }
}
