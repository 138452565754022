const { apiURL, requestToDownload } = require('../utils/api');
const { dissocPath, pathOr } = require('ramda');
const request = require('../utils/api').default;

class ApplicantFormService {
  getApplicantInformation(
    applicantId: string,
    organizationId: string,
    propertyId: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/shortForms/${applicantId}`,
      options,
    );
  }
  save(applicant: any, organizationId: string, propertyId: string) {
    const applicantIdPath = ['formData', 'applicantInfo', 'applicantId'];
    const applicationIdPath = ['formData', 'applicantInfo', 'applicationId'];
    const applicantId = pathOr('', applicantIdPath, applicant);
    const payload = dissocPath(
      applicationIdPath,
      dissocPath(applicantIdPath, applicant),
    );

    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/shortForms/${applicantId}`,
      options,
    );
  }
  getApplicantPrimaryFormInformation(
    applicantId: string,
    organizationId: string,
    propertyId: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/primaryForms/${applicantId}`,
      options,
    );
  }
  savePrimaryForm(
    applicant: any,
    applicantId: string,
    organizationId: string,
    propertyId: string,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(applicant),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/primaryForms/${applicantId}`,
      options,
    );
  }
  getApplicantSecondaryFormInformation(
    applicantId: string,
    organizationId: string,
    propertyId: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/secondaryForms/${applicantId}`,
      options,
    );
  }
  saveSecondaryForm(
    applicant: any,
    applicantId: string,
    organizationId: string,
    propertyId: string,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(applicant),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/secondaryForms/${applicantId}`,
      options,
    );
  }

  downloadForm(
    organizationId: string,
    propertyId: string,
    applicantId: string,
    includeIncomeAndAssets: boolean,
    isPrimary?: boolean,
    isSecondary?: boolean,
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };

    const formType = isPrimary
      ? 'primaryForms'
      : isSecondary
      ? 'secondaryForms'
      : 'shortForms';
    const params = `?includeIncomeAndAssets=${
      includeIncomeAndAssets ? 'true' : 'false'
    }`;
    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/${formType}/${applicantId}/download-form${params}`,
      options,
    );
  }
}

export default ApplicantFormService;
