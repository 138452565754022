// $FlowFixMe
import { download } from '../../utils/downloadFile';
import UserService from '../../services/userService';

const downloadCSV = async (
  csvHeaders: Array<Object>,
  title: string,
  subtitle: string,
  organizationId: string,
  query: Object,
) => {
  const headers = csvHeaders
    .map((header) => {
      return header.title;
    }, [])
    .join(',');
  const fileName = `${title.toLocaleLowerCase().replace(/\s/g, '_')}.csv`;
  const userService = new UserService();

  // Request API not to paginate results
  const downloadQuery = {
    ...query,
    paginationInfo: {
      pagination: false,
    },
  };

  const users = await userService.getAllUsers(organizationId, downloadQuery);

  const rows =
    users && Array.isArray(users.results)
      ? users.results.map((user) => {
          return [
            user.lastName,
            user.firstName,
            user.username,
            user.emailAddress,
            user.userTitle.name,
            user.userRole.name,
            user.userStatus.name,
            user.lastSystemAccess,
            user.allProperties ? 'ALL' : user.totalProperties,
          ].join(',');
        })
      : [];

  const csv = [title, subtitle, '\n', headers, ...rows].join('\n');
  download(csv, fileName, 'text/csv;charset=utf-8');
};

export default downloadCSV;
