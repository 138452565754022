import moment from 'moment';

import { validateRequired } from '../../../../utils/validations';

const validate = (values: Object) => {
  const errors = {};

  ['squareFeet', 'startDate'].forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      errors[fieldName] = 'Required';
    }
  });

  if (isNaN(+values.squareFeet) || +values.squareFeet <= 0) {
    errors.squareFeet = 'Must be a positive number';
  }

  if (values.startDate) {
    const startDate = moment(values.startDate);
    if (!startDate.isValid()) {
      errors.startDate = 'Invalid date.';
    }
  }

  return errors;
};

export default validate;
