import * as R from 'ramda';
import moment from 'moment';

const getValuesFromGraphData = (
  graphData: GraphData,
  years: number[],
  key: string,
) => {
  return R.flatten(
    years.map((year) => {
      const monthKeys = R.sortWith(
        [R.ascend],
        Object.keys(graphData[year] || {}).map(Number),
      );
      return monthKeys.map((k) => graphData[year][k][key]);
    }),
  );
};

const generateLabels = (graphData: GraphData, years: number[]) =>
  years
    .map((year) => {
      const monthKeys = Object.keys(graphData[year] || {});
      return monthKeys.map((m) => [moment.monthsShort(Number(m)), year]);
    })
    .reduce((acc, cur) => [...acc, ...cur], []);

export const generateLimitsVsActualsGraphProps = (
  graphData: GraphData,
  years: number[],
) => {
  const labels = generateLabels(graphData, years);

  const limitData = getValuesFromGraphData(graphData, years, 'limit');
  const actualsData = getValuesFromGraphData(graphData, years, 'actualLeases');
  const availablesData = getValuesFromGraphData(
    graphData,
    years,
    'availableLeases',
  );
  const overLimitData = getValuesFromGraphData(graphData, years, 'overLimit');

  return {
    labels,
    limitData,
    actualsData,
    availablesData,
    overLimitData,
    unitCount: graphData.units,
    leaseMax: graphData.leaseMax,
    limitMax: graphData.limitMax,
    graphData,
  };
};

export const generateActualLeasesGraphProps = (
  graphData: GraphData,
  years: number[],
) => {
  const labels = generateLabels(graphData, years);
  const leasesData = getValuesFromGraphData(graphData, years, 'leases');

  return {
    labels,
    leasesData,
  };
};

export const generateNoLimitsGraphProps = (
  graphData: GraphData,
  years: number[],
) => {
  return {
    ...generateActualLeasesGraphProps(graphData, years),
    graphData,
  };
};
