import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageMiscTransactions.MiscTransactionsHeader.Title',
    defaultMessage: 'Manage Miscellaneous Income Payments'
  },
  filterByPeriod: {
    id: 'App.ManageMiscTransactions.MiscTransactionsHeader.FilterByPeriod',
    defaultMessage: 'Filter by Period'
  },
  createNewPayment: {
    id: 'App.ManageMiscTransactions.MiscTransactionsHeader.CreateNewPayment',
    defaultMessage: 'Create New Payment'
  }
});

export default messages;
