import AffordableCertificationsService from '../../services/affordableCertificationsService';

import { GROSS_RENT, CERTIFICATION_TYPES, OTHER_HUD } from './constants';
import { parseGrossRentCertRowsForCSV } from './ManageCertificationsContent/GrossRentCertsTable/utils';
import { parseRecertRowsForCSV } from './ManageCertificationsContent/RecertsTable/utils';
import { parseCertificationRowsForCSV } from './ManageCertificationsContent/ActiveCertifications/utils';

export const parseCertFilters = (filters: Object) => {
  const filterKeys = Object.keys(filters);
  let certFilters = {};
  filterKeys.forEach((filterKey) => {
    let certFilterKey = '';
    switch (filterKey) {
      case 'complianceDocument':
        certFilterKey = 'ComplianceDocumentFilterGroupSection';
        break;
      case 'recertDue':
        certFilterKey = 'RecertDueSection';
        break;
      case 'recertProgramName':
        certFilterKey = 'RecertProgramSection';
        break;
      case 'recertStatus':
        certFilterKey = 'RecertStatusSection';
        break;
      case 'complianceApprovalStatus':
        certFilterKey = 'ComplianceApprovalStatusSection';
        break;
      case 'certStatus':
        certFilterKey = 'CertStatusSection';
        break;
      case 'isPriorResident':
      case 'isApplicant':
        certFilterKey = 'ApplicantSection';
        break;
      case 'programName':
        certFilterKey = 'AffordableProgramSection';
        break;
      case 'certificationType':
        certFilterKey = 'CertificationTypeSection';
        break;
      default:
        certFilterKey = '';
        break;
    }
    if (certFilterKey !== '') {
      certFilters[certFilterKey] = filters[filterKey];
    }
  });
  return certFilters;
};

export const downloadCSV = (
  certificationType: string,
  organizationId: string,
  propertyId: string,
  rowheaders: Array<Object>,
  searchText: ?Object,
  filters: ?Object,
  sort: ?Object,
  hasFilters,
  intl,
  displayAll,
) => {
  const abortController = new AbortController();
  const options = { signal: abortController.signal };
  if (propertyId) {
    if (certificationType === CERTIFICATION_TYPES.RECERT) {
      const affordableRecertificationsService =
        new AffordableCertificationsService();
      affordableRecertificationsService
        .getRecerts(
          organizationId,
          propertyId,
          options,
          searchText,
          filters,
          displayAll,
        )
        .then(parseRecertRowsForCSV(rowheaders, sort, hasFilters));
    }

    if (certificationType === GROSS_RENT) {
      const affordableRecertificationsService =
        new AffordableCertificationsService();
      affordableRecertificationsService
        .getGrossRentCerts(
          organizationId,
          propertyId,
          options,
          searchText,
          filters,
        )
        .then(parseGrossRentCertRowsForCSV(rowheaders, sort, hasFilters));
    }

    if (
      [
        CERTIFICATION_TYPES.MOVE_IN,
        CERTIFICATION_TYPES.INITIAL,
        CERTIFICATION_TYPES.INITIAL_UT,
        OTHER_HUD,
      ].includes(certificationType)
    ) {
      const affordableRecertificationsService =
        new AffordableCertificationsService();
      affordableRecertificationsService
        .getActiveCertifications(
          organizationId,
          propertyId,
          certificationType,
          options,
          searchText,
          filters,
        )
        .then(
          parseCertificationRowsForCSV(
            rowheaders,
            certificationType,
            sort,
            hasFilters,
            intl,
          ),
        );
    }
  }
};
