import { pathOr } from 'ramda';
import { Field, FormSection } from 'redux-form';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import {
  getTrancationCode,
  getTrancationCodeId,
  getHouseholdLink,
} from '../../utils';
import {
  renderCurrencyField,
  renderTextField,
  renderSelectField,
} from '../../../../utils/redux-form-helper';

const SubsidyBatchRow = ({
  intl,
  transaction,
  totalDepositAmount,
  setTotalDepositAmount,
  setIsRowSaving,
  totalFinalSubBalance,
  setTotalFinalSubBalance,
  saveSubsidyPaymentBatchDetail,
  transactionCodes,
  deletePaymentBatchDetail,
  isDisabled,
}) => {
  const depositAmount = pathOr('', ['transactionAmount'], transaction);
  const status = pathOr('', ['householdStatus'], transaction);
  const resident = pathOr('', ['receivedFromHouseholdMember'], transaction);
  const unit = pathOr(null, ['unit', 'number'], transaction);
  const propertyId = pathOr(null, ['unit', 'propertyId'], transaction);
  const currentSubsidyBalance = pathOr(
    0,
    ['subjournalRunningBalance'],
    transaction,
  );
  const id = pathOr('', ['id'], transaction);
  const finalSubsidyBalance = pathOr(0, ['finalSubsidyBalance'], transaction);
  const formattedCurrentSubsidyBalance = intl.formatNumber(
    Math.abs(+currentSubsidyBalance),
    {
      style: 'currency',
      currency: 'USD',
    },
  );
  const formattedFinalSubsidyBalance = intl.formatNumber(
    Math.abs(+finalSubsidyBalance),
    {
      style: 'currency',
      currency: 'USD',
    },
  );
  const householdId = pathOr('', ['householdId'], transaction);
  const payload = {
    batchId: pathOr('', ['headerId'], transaction),
    receivedFrom: resident,
    documentNumber: pathOr('', ['documentNumber'], transaction),
    householdId,
    householdStatus: pathOr('', ['householdStatus'], transaction),
    unitId: pathOr(null, ['unitId'], transaction),
    id: pathOr(null, ['id'], transaction),
  };
  const onDepositAmountChange = ({ target: { value } }) => {
    const result = value ? currentSubsidyBalance - +value : '';
    setTotalFinalSubBalance(
      totalFinalSubBalance - (finalSubsidyBalance || 0) + result,
    );
    setTotalDepositAmount(
      +totalDepositAmount - (+depositAmount || 0) + (+value || 0),
    );
    const ptc = getTrancationCodeId(value, transactionCodes);
    saveSubsidyPaymentBatchDetail(
      {
        amount: value ? +value : 0,
        transactionCode: ptc?.id ?? '',
        transactionNote: transaction?.transactionNote ?? '',
        transactionDate: transaction?.transactionDate,
        ...payload,
      },
      true,
    ).then(() => {
      setIsRowSaving(false);
    });
  };

  const onNoteChange = ({ target: { value } }) => {
    const ptc = getTrancationCodeId(depositAmount, transactionCodes);
    saveSubsidyPaymentBatchDetail(
      {
        amount: depositAmount,
        transactionCode: pathOr('', ['id'], ptc),
        transactionNote: value,
        ...payload,
      },
      false,
    );
  };
  const linkId =
    status === 'Applicant'
      ? pathOr('', ['household', 'currentApplicationId'], transaction)
      : pathOr('', ['customer', 'rc', 'residentId'], transaction);
  const link = getHouseholdLink(status, linkId, propertyId);
  const isManual = pathOr(false, ['isManual'], transaction);
  const onDelete = () => {
    deletePaymentBatchDetail(id);
  };

  const isInvalid = transaction.invalidReasons.length > 0;
  const rowBackgroundColor = isInvalid ? '#FFF0F0' : 'initial';

  return (
    <FormSection name={id}>
      <tr key={id} style={{ backgroundColor: rowBackgroundColor }}>
        <td>
          {isInvalid ? (
            <OverlayTrigger
              overlay={<Popover>{transaction.invalidReasons[0]}</Popover>}
            >
              <i className="icon et-alert-info" />
            </OverlayTrigger>
          ) : (
            <span></span>
          )}
        </td>
        <td>
          {isManual ? (
            <Field
              name="status"
              component={renderSelectField}
              options={[{ value: status, text: status, disabled: true }]}
              disabled={true}
            />
          ) : (
            status
          )}
        </td>
        <td>
          {isManual ? (
            <Field
              name="household"
              component={renderSelectField}
              options={[
                {
                  value: unit ? `${unit}-${resident}` : resident,
                  text: unit ? `${unit} - ${resident}` : resident,
                  disabled: true,
                },
              ]}
              disabled={true}
            />
          ) : (
            <Link to={link}>
              {unit} {unit && '-'} {resident}
            </Link>
          )}
        </td>
        <td
          className={
            currentSubsidyBalance <= 0
              ? currentSubsidyBalance === 0
                ? ''
                : 'text-green'
              : 'text-red'
          }
        >
          {currentSubsidyBalance < 0
            ? `(${formattedCurrentSubsidyBalance})`
            : formattedCurrentSubsidyBalance}
        </td>
        <td>
          <Field
            name="depositAmount"
            component={renderCurrencyField}
            messageAsTooltip
            min="-10000"
            max="10000"
            onBlur={(e) => {
              setIsRowSaving(true);
              onDepositAmountChange(e);
            }}
            style={{ zIndex: '0' }}
            placeholder="0.00"
            disabled={isDisabled}
            data-test="subsidy-batch-field"
          />
        </td>
        <td>
          {finalSubsidyBalance < 0
            ? `(${formattedFinalSubsidyBalance})`
            : formattedFinalSubsidyBalance}
        </td>
        <td>{getTrancationCode(+depositAmount)}</td>
        <td>
          <Field
            name="note"
            component={renderTextField}
            maxLength="35"
            onChange={onNoteChange}
            placeholder="Note"
            disabled={isDisabled}
            data-test="subsidy-batch-field"
          />
        </td>

        <td>
          {(isManual || isInvalid) && (
            <button
              className="btn btn-sm btn-default"
              onClick={onDelete}
              type="button"
              disabled={isDisabled}
            >
              <i className="et-trash" />
            </button>
          )}
        </td>
      </tr>
    </FormSection>
  );
};

export default SubsidyBatchRow;
