import React, { useEffect } from 'react';
import {
  HouseholdRentableItems,
  HouseholdRentableItemsModalForm,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { GarageIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { useRentableItems } from './hooks';
import messages from './messages';

type Props = {
  intl: { formatMessage: (Object) => string },
  actualOrAnticipatedMoveOutDate: string,
  hasAssignedUnit: boolean,
  isPriorResident: boolean,
  householdId: boolean,
  promptToaster: Function,
  setHasHouseholdRentableItems: boolean,
  setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate: boolean,
  refetchHouseholdRentableItemsTrigger: number,
};

const RentableItems = ({
  intl,
  actualOrAnticipatedMoveOutDate,
  hasAssignedUnit,
  isPriorResident,
  householdId,
  promptToaster,
  setHasHouseholdRentableItems,
  setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate,
  refetchHouseholdRentableItemsTrigger,
}: Props) => {
  const {
    allPropertyFeesWithRentableItems,
    modalProps,
    rentableItemsProps,
    rentableItemsModalFormProps,
  } = useRentableItems({
    actualOrAnticipatedMoveOutDate,
    hasAssignedUnit,
    householdId,
    promptToaster,
    setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate,
    refetchHouseholdRentableItemsTrigger,
  });

  useEffect(() => {
    setHasHouseholdRentableItems(
      Boolean(rentableItemsProps.householdRentableItems?.length > 0),
    );
  }, [rentableItemsProps.householdRentableItems, setHasHouseholdRentableItems]);

  return allPropertyFeesWithRentableItems.hasData ? (
    <>
      <HouseholdRentableItems
        buttonProps={rentableItemsProps.buttonProps}
        householdRentableItems={rentableItemsProps.householdRentableItems}
        onItemClick={rentableItemsProps.onItemClick}
        hasAssignedUnit={hasAssignedUnit}
        isPriorResident={isPriorResident}
      />
      <Modal
        open={modalProps.open}
        onClose={modalProps.onClose}
        title={modalProps.title}
        icon={
          <GarageIcon
            sx={{
              top: -2,
              position: 'relative',
            }}
          />
        }
        actionsProps={modalProps.actionsProps}
        handleDelete={modalProps.handleDelete}
        ConfirmProps={{
          title: modalProps.ConfirmProps.title,
          open: modalProps.ConfirmProps.open,
          onClose: modalProps.ConfirmProps.onClose,
          actionsProps: modalProps.ConfirmProps.actionsProps,
        }}
      >
        <HouseholdRentableItemsModalForm
          bannerColor={'purple'}
          bannerMessage={rentableItemsModalFormProps.bannerMessage}
          datesInfoMessage={intl.formatMessage(messages.datesInfoMessage)}
          fields={rentableItemsModalFormProps.fields}
          ReactHookFormProps={rentableItemsModalFormProps.ReactHookFormProps}
        />
      </Modal>
    </>
  ) : null;
};

export default RentableItems;
