import { useMemo } from 'react';
import { useQuery, QueriesOptions } from 'react-query';
import ApplicationService from '../../services/applicationService';
import useAbortController from '../useAbortController';

const useApplicationQuery = ({
  organizationId,
  propertyId,
  applicationId,
  options,
}: {
  organizationId: string,
  propertyId: string,
  applicationId: string,
  options: QueriesOptions,
}) => {
  const applicationService = useMemo(() => new ApplicationService(), []);
  const abortControllerOptions = useAbortController();
  const queryKey = [
    'applicationService.getApplication',
    organizationId,
    propertyId,
    applicationId,
  ];

  return useQuery(
    queryKey,
    () =>
      applicationService.getApplication(
        organizationId,
        propertyId,
        applicationId,
        abortControllerOptions,
      ),
    {
      ...options,
      enabled: !!organizationId && !!propertyId && !!applicationId,
    },
  );
};

export default useApplicationQuery;
