import React, { useEffect } from 'react';
import { pathOr } from 'ramda';
import { CompleteRenewalCheckForm } from '../../../components/CompleteRenewalCheck';
import * as residentActions from '../actions';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import messages from '../messages';
import {
  checkLeaseValidityForSubmittal,
  checkLeaseValidityCommercial,
  getValidLeaseStartDate,
} from '../../../utils/lease-helpers';
import { navigateToUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
} from '../../App/selectors';

import type { GlobalState } from '../../App/types';
import {
  useFetchPropertyRenewalProrateMethod,
  useFetchLeaseRenewalProrates,
} from './hooks';

type Props = {
  actions: Object,
  residentId: string,
  leaseId: string,
  residentInfo: Object,
  renewalSaved: Object,
  validateStartDate: Object,
  intl: any,
  history: Object,
  leases: Object,
  propertyId: string,
};

export const CompleteRenewal = ({
  intl,
  history,
  residentId,
  leaseId,
  leases,
  actions: { saveLeaseDataTabResident, getOneResident },
  propertyId,
  organizationId,
}: Props) => {
  useEffect(() => {
    getOneResident(residentId);
  }, [getOneResident, residentId]);

  const [isLoadingProrateMethod, prorateMethod] =
    useFetchPropertyRenewalProrateMethod({ organizationId, propertyId });
  const [isLoadingProrates, prorates] = useFetchLeaseRenewalProrates({
    organizationId,
    propertyId,
    leaseId,
  });

  const handleCancel = () => {
    const route = `/resident/${residentId}?tab=3`; // move back to the lease data tab
    navigateToUrlWithSelectedPropertyId(route);
  };

  const handleSubmit = async (doNotProrateTransactionCodes: Array<string>) => {
    await saveLeaseDataTabResident(
      leaseId,
      { isRenewalComplete: true, doNotProrateTransactionCodes },
      residentId,
    );
    handleCancel();
    return doNotProrateTransactionCodes;
  };

  const getSteps = (
    leases: Array<Object>,
    renewalLease: Object,
  ): Array<Object> => {
    const validSavedRenewalLease = checkLeaseValidityForSubmittal(renewalLease);
    const renewalSaved = validSavedRenewalLease
      ? { valid: true, statusText: 'Completed' }
      : { valid: false, statusText: 'Not Completed' };

    // $FlowFixMe
    const validateStartDate = getValidLeaseStartDate(leases);
    const documentId = pathOr(null, ['documentId'], renewalLease);
    const leaseUploaded = !!documentId
      ? { valid: true, statusText: 'Completed' }
      : { valid: false, statusText: 'Not Completed' };

    let steps = [
      {
        ...renewalSaved,
        title: intl.formatMessage(messages.renewalSaved),
      },
      {
        ...validateStartDate,
        title: intl.formatMessage(messages.validateStartDate),
      },
      {
        ...leaseUploaded,
        title: intl.formatMessage(messages.leaseUploaded),
      },
    ];
    return steps;
  };

  const getCommercialSteps = (
    leases: Array<Object>,
    renewalLease: Object,
  ): Array<Object> => {
    const validSavedRenewalLease = checkLeaseValidityCommercial(renewalLease);
    const renewalSaved = validSavedRenewalLease
      ? { valid: true, statusText: 'Completed' }
      : { valid: false, statusText: 'Not Completed' };

    // $FlowFixMe
    const validateStartDate = getValidLeaseStartDate(leases);
    const documentId = pathOr(null, ['documentId'], renewalLease);
    const leaseUploaded = !!documentId
      ? { valid: true, statusText: 'Completed' }
      : { valid: false, statusText: 'Not Completed' };

    let steps = [];

    steps = [
      {
        ...renewalSaved,
        title: intl.formatMessage(messages.renewalSaved),
      },
      {
        ...validateStartDate,
        title: intl.formatMessage(messages.validateStartDate),
      },
      {
        ...leaseUploaded,
        title: intl.formatMessage(messages.leaseUploaded),
      },
    ];
    return steps;
  };

  const getRenewalActions = () => {
    return [
      {
        id: 1,
        text: 'Updated Vehicle Information if necessary',
      },
    ];
  };

  const getRenewalActionsCommercial = () => {
    return [
      {
        id: 1,
        text: 'Updated Contacts and Insurance Information',
      },
    ];
  };

  const renewalLease = leases.length > 0 ? leases[0] : {};
  const unit = pathOr([{}], ['units'], renewalLease)[0];
  const isCommercial = !!pathOr(false, ['commercialLeaseTypeId'], renewalLease);
  const steps = isCommercial
    ? getCommercialSteps(leases, renewalLease)
    : getSteps(leases, renewalLease);
  const completeRenewalActions = isCommercial
    ? getRenewalActionsCommercial()
    : getRenewalActions();

  const isLoadingRenewalProrateData =
    isLoadingProrateMethod || isLoadingProrates;

  return (
    <DocumentTitle title="Fortress - Complete Renewal">
      {
        <CompleteRenewalCheckForm
          steps={steps}
          completeRenewalActions={completeRenewalActions}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          unitNumber={unit.number}
          isCommercial={isCommercial}
          isLoadingRenewalProrateData={isLoadingRenewalProrateData}
          prorateMethod={prorateMethod}
          prorates={prorates}
          renewalLease={renewalLease}
        />
      }
    </DocumentTitle>
  );
};

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): Object => {
  const { residentProfile } = state;
  return {
    propertyId: pathOr(null, ['id'], selectSelectedProperty(state)),
    organizationId: selectCurrentUserOrganizationId(state),
    residentId: ownProps.match.params.residentId,
    leaseId: ownProps.match.params.leaseId,
    residentInfo: residentProfile.residentInfo,
    leases: residentProfile.leases,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...residentActions,
    },
    dispatch,
  );
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CompleteRenewal));
