import React from 'react';
import { Row, Col, FormGroup } from 'react-bootstrap';
import { Field } from 'redux-form';
import { find, propEq } from 'ramda';

import {
  renderTextAreaField,
  renderSelectField,
  renderSimpleCheckboxField,
} from '../../../../utils/redux-form-helper';
import messages from './messages';
import { NoPrint, Print } from 'react-easy-print';
import { BACKGROUND_COLOR } from '../../constants';
import useHasPermission from '../../../../hooks/useHasPermission';

type Props = {
  intl: any,
  isEditMode: boolean,
  priorityLevels?: Array<Object>,
  workOrderAssignees?: Array<Object>,
  assignedTo?: Object,
  invalid?: Array<string>,
  assignedToId?: string,
};

const OfficeNotes = ({
  intl,
  isEditMode,
  priorityLevels,
  workOrderAssignees,
  assignedTo,
  invalid,
  assignedToId,
}: Props) => {
  const hasChangeAssignedToPermission = useHasPermission(
    'change-work-order-assignee',
  );

  const hasEditPermission = useHasPermission('workorder-edit');
  const isFieldDisabled = !hasEditPermission && isEditMode;

  if (isEditMode) {
    const disabledAssignee =
      assignedTo &&
      workOrderAssignees &&
      !find(propEq('value', assignedTo.id))(workOrderAssignees)
        ? {
            value: assignedTo.id,
            text: `${assignedTo.firstName} ${assignedTo.lastName}`,
            readableId: 0,
            disabled: true,
          }
        : null;
    if (workOrderAssignees && disabledAssignee) {
      workOrderAssignees.push(disabledAssignee);
    }
  }
  return (
    <Row className="padtop20" data-test="comp-office-notes">
      <Col xs={12}>
        <h3>{intl.formatMessage(messages.title)}</h3>
      </Col>
      <Col xs={12}>
        <Row>
          <div className="col-xs-4 col-sm-4 text-left">
            <label className="padtop10">
              {intl.formatMessage(messages.previouslyReported)}
            </label>
            <Field
              name="repeatOrder"
              component={renderSimpleCheckboxField}
              bsSize="lg"
              label="Yes"
              disabled={isFieldDisabled}
            />
          </div>
          <div className="col-xs-4 col-sm-4 text-left">
            <Row>
              <Col sm={5}>
                <NoPrint force>
                  <label className="padtop10 pull-right float-left">
                    {intl.formatMessage(messages.priority)}
                  </label>
                </NoPrint>
              </Col>
              <Col sm={7}>
                <FormGroup>
                  <NoPrint force>
                    <Field
                      selectClassNames="input-lg"
                      name="priorityLevelId"
                      component={renderSelectField}
                      options={priorityLevels}
                      maxLength="35"
                      disabled={isFieldDisabled}
                    />
                  </NoPrint>
                  <Print printOnly name="workOrder">
                    <Field
                      selectClassNames="input-lg"
                      name="priorityLevelId"
                      component={renderSelectField}
                      options={priorityLevels}
                      maxLength="35"
                      label={intl.formatMessage(messages.priority)}
                      disabled={isFieldDisabled}
                    />
                  </Print>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="col-xs-4 text-left col-sm-4">
            <Row>
              <Col sm={5}>
                <NoPrint force>
                  <label className="padtop10 pull-right float-left">
                    {intl.formatMessage(messages.assignedTo)}
                  </label>
                </NoPrint>
              </Col>
              <Col xs={12} sm={7}>
                {!assignedToId ? (
                  <FormGroup>
                    <Print printOnly name="workOrder">
                      <label className="float-left">
                        {intl.formatMessage(messages.assignedTo)}
                      </label>
                      <div className="form-group">
                        <select className="input-lg form-control">
                          <option />
                        </select>
                      </div>
                    </Print>
                    <NoPrint force>
                      <Field
                        name="assignedToId"
                        component={renderSelectField}
                        options={workOrderAssignees}
                        bsSize="lg"
                        style={
                          invalid && invalid.includes('assignedTo')
                            ? BACKGROUND_COLOR
                            : null
                        }
                        disabled={!hasChangeAssignedToPermission}
                      />
                    </NoPrint>
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <NoPrint force>
                      <Field
                        name="assignedToId"
                        component={renderSelectField}
                        options={workOrderAssignees}
                        bsSize="lg"
                        disabled={!hasChangeAssignedToPermission}
                      />
                    </NoPrint>
                    <Print printOnly name="workOrder">
                      <Field
                        name="assignedToId"
                        component={renderSelectField}
                        options={workOrderAssignees}
                        bsSize="lg"
                        label={intl.formatMessage(messages.assignedTo)}
                        disabled={!hasChangeAssignedToPermission}
                      />
                    </Print>
                  </FormGroup>
                )}
              </Col>
            </Row>
          </div>
        </Row>
      </Col>
      <Col xs={12} className="padtop10">
        <FormGroup>
          <NoPrint force>
            <label>{intl.formatMessage(messages.noteToTechnician)}</label>
            <Field
              name="officeNoteBeforeService"
              className="form-control textarea-short"
              placeholder="Start typing..."
              maxLength="500"
              component={renderTextAreaField}
              disabled={isFieldDisabled}
            />
          </NoPrint>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default OfficeNotes;
