import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import messages from './messages';
import moment from 'moment';

export const validateMonth = (periodName: string) => {
  const today = moment();
  const periodDate = moment(periodName, 'MMMM YYYY');
  const isSameOrAfter = today.isSameOrAfter(periodDate, 'month');

  return !isSameOrAfter;
};

const validate = (values: Object = {}, { periodName }: Object) => {
  const errors = {};

  errors['validateDate'] = validateMonth(periodName);

  ['step-1', 'step-2', 'step-3', 'step-4'].forEach((fieldName) => {
    if (values[fieldName] !== true) {
      errors[fieldName] = renderTranslatedMessage(messages.validateAllSteps);
    }
  });

  [
    'customerOpsLedger',
    'depositBatchDetail',
    'moveIn',
    'moveOut',
    'renewal',
    'securityDeposits',
    'delinquentAndPrepaid',
    'availability'
  ].forEach((fieldName) => {
    if (values[fieldName] !== true) {
      errors['step-3'] = renderTranslatedMessage(messages.validateHaveReviewed);
    }
  });

  return errors;
};

export default validate;
