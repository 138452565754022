export const COMMERCIAL_RENT_ROLL_TABLE_HEADERS = [
  { id: 'unit', title: 'Unit', type: '' },
  { id: 'tenant', title: 'Tenant', type: '' },
  { id: 'leaseType', title: 'Lease Type', type: '' },
  { id: 'term', title: 'Term (Months)', type: 'number' },
  { id: 'rentableSqFt', title: 'Rentable Sq. Ft', type: 'number' },
  { id: 'monthlyBaseRent', title: 'Monthly Base Rent', type: 'money' },
  { id: 'rentStartDate', title: 'Rent Start Date', type: 'date' },
  { id: 'annualBaseRent', title: 'Annual Base Rent', type: 'money' },
  { id: 'annualRatePSF', title: 'Annual Rate PSF', type: 'money' },
  { id: 'monthlyCAM', title: 'Monthly  CAM', type: 'money' },
  { id: 'commencementDate', title: 'Commencement Date', type: 'date' },
  { id: 'leaseEndDate', title: 'Lease End Date', type: 'date' },
  {
    id: 'securityDepositHeld',
    title: 'Security Deposit Held',
    type: 'money',
  },
  {
    id: 'collectionNotes',
    title: 'Collections Notes',
    classes: 'table-col-width210',
    type: '',
  },
  { id: 'balance', title: 'Balance', type: 'money' },
  { id: 'type', title: 'Type', classes: 'header-darker', type: '' },
  { id: 'status', title: 'Status', classes: 'header-darker', type: '' },
  {
    id: 'applicant',
    title: 'Applicant',
    classes: 'header-darker',
    type: '',
  },
  {
    id: 'conditionalMonthlyBaseRent',
    title: 'Monthly Base Rent',
    classes: 'header-darker',
    type: 'money',
  },
  {
    id: 'conditionalMonthlyCAM',
    title: 'Monthly CAM',
    classes: 'header-darker',
    type: 'money',
  },
  {
    id: 'conditionalCommencementDate',
    title: 'Commencement Date',
    classes: 'header-darker',
    type: 'date',
  },
  {
    id: 'conditionalLeaseEndDate',
    title: 'Lease End Date',
    classes: 'header-darker',
    type: 'date',
  },
  {
    id: 'conditionalTerm',
    title: 'Term (Months)',
    classes: 'header-darker',
    type: 'number',
  },
];

export const COMMERCIAL_RENT_ROLL_TABLE_HEADERS_FLAG = [
  {
    headers: [
      {
        id: '',
        title: '',
        classes: 'main-color',
        order: null,
        props: {
          colSpan: '16',
        },
      },
      {
        id: '',
        title: 'Renewal/Application',
        classes: 'header-darker',
        order: null,
        props: {
          colSpan: '8',
        },
      },
    ],
  },
  {
    headers: [
      { id: 'unit', title: 'Unit', type: '' },
      { id: 'tenant', title: 'Tenant', type: '' },
      { id: 'leaseType', title: 'Lease Type', type: '' },
      { id: 'term', title: 'Term (Months)', type: 'number' },
      { id: 'rentableSqFt', title: 'Rentable Sq. Ft', type: 'number' },
      { id: 'monthlyBaseRent', title: 'Monthly Base Rent', type: 'money' },
      { id: 'netRentCharge', title: 'Net Rent Charge', type: 'money' },
      { id: 'rentStartDate', title: 'Rent Start Date', type: 'date' },
      { id: 'annualBaseRent', title: 'Annual Base Rent', type: 'money' },
      { id: 'annualRatePSF', title: 'Annual Rate PSF', type: 'money' },
      { id: 'monthlyCAM', title: 'Monthly  CAM', type: 'money' },
      { id: 'commencementDate', title: 'Commencement Date', type: 'date' },
      { id: 'leaseEndDate', title: 'Lease End Date', type: 'date' },
      {
        id: 'securityDepositHeld',
        title: 'Security Deposit Held',
        type: 'money',
      },
      {
        id: 'collectionNotes',
        title: 'Collections Notes',
        classes: 'table-col-width210',
        type: '',
      },
      { id: 'balance', title: 'Balance', type: 'money' },
      { id: 'type', title: 'Type', classes: 'header-darker--var', type: '' },
      {
        id: 'status',
        title: 'Status',
        classes: 'header-darker--var',
        type: '',
      },
      {
        id: 'applicant',
        title: 'Applicant',
        classes: 'header-darker--var',
        type: '',
      },
      {
        id: 'conditionalMonthlyBaseRent',
        title: 'Monthly Base Rent',
        classes: 'header-darker--var',
        type: 'money',
      },
      {
        id: 'conditionalMonthlyCAM',
        title: 'Monthly CAM',
        classes: 'header-darker--var',
        type: 'money',
      },
      {
        id: 'conditionalCommencementDate',
        title: 'Commencement Date',
        classes: 'header-darker--var',
        type: 'date',
      },
      {
        id: 'conditionalLeaseEndDate',
        title: 'Lease End Date',
        classes: 'header-darker--var',
        type: 'date',
      },
      {
        id: 'conditionalTerm',
        title: 'Term (Months)',
        classes: 'header-darker--var',
        type: 'number',
      },
    ],
  },
];

export const COMMERCIAL_RENT_ROLL_TOTAL_COLUMNS_FLAG = [
  'rentableSqFt',
  'monthlyBaseRent',
  'netRentCharge',
  'annualBaseRent',
  'annualRatePSF',
  'balance',
];

export const COMMERCIAL_RENT_ROLL_TOTAL_COLUMNS = [
  'rentableSqFt',
  'monthlyBaseRent',
  'annualBaseRent',
  'annualRatePSF',
  'balance',
];

export const DATE_INPUT_FORMAT = 'YYYY-MM-DD';
export const DATE_OUTPUT_FORMAT = 'MM/DD/YYYY';
