import { sortBy, prop } from 'ramda';
import { Checkbox } from 'react-bootstrap';

import Row from '../../../components/Table/Row';
import Data from '../../../components/Table/Data';
import ElementWithPermissions from '../../../components/ElementWithPermissions';

import type { ProrateDetailsProps, ProrateCalculation } from './types';
import { getProrateCalculationColumns } from './utils';

export const ProrateDetails = ({
  proratedCharges,
  onDoNotProrateClicked,
  doNotProrateTransactionCodes,
}: ProrateDetailsProps) => {
  const renderRow = (calculation: ProrateCalculation, idxRow: number): any => {
    const columnsData = getProrateCalculationColumns(
      calculation,
      doNotProrateTransactionCodes,
    );
    const { doNotProrateChecked, transactionCodeId, columns } = columnsData;

    return (
      <Row
        key={`prorate-${idxRow}`}
        className="table-row"
        data-test="prorate-table-row"
      >
        {columns.map((column, idxCol) => (
          <Data key={`prorate-${idxRow}-${idxCol}`}>{column}</Data>
        ))}
        <Data key={`prorate-${idxRow}-checkbox`}>
          <ElementWithPermissions
            scope={['lease-renewal-allow-do-not-prorate-selection']}
          >
            <Checkbox
              className="custom-checkbox padleft20"
              data-test="prorate-checkbox"
              checked={doNotProrateChecked}
              value={doNotProrateChecked}
              onChange={() => onDoNotProrateClicked(transactionCodeId)}
            >
              <input type="checkbox" />
              <span className="custom-check-square center-block" />
            </Checkbox>
          </ElementWithPermissions>
        </Data>
      </Row>
    );
  };

  return (
    proratedCharges &&
    sortBy(prop('transactionCode'))(proratedCharges).map(renderRow)
  );
};
