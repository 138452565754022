import { useState, useEffect } from 'react';
import { pathOr, isNil, isEmpty } from 'ramda';
import { toastr } from 'react-redux-toastr';

import CommercialLeaseOptionsService from '../../../../services/commercialLeaseOptionsService';
import LeaseService from '../../../../services/leaseService';

export const useUpdateLeaseNoOptions = (
  selectedProperty: Object,
  leaseId: string,
) => {
  const [isSaving, setIsSaving] = useState(false);
  const handleSaveLease = async (hasCommercialOptions: boolean) => {
    try {
      setIsSaving(true);
      const organizationId = pathOr('', ['organizationId'], selectedProperty);
      const propertyId = pathOr('', ['id'], selectedProperty);
      const leaseService = new LeaseService();
      const saveResponse = await leaseService.updateHasCommercialOptions(
        organizationId,
        propertyId,
        leaseId,
        hasCommercialOptions,
      );
      toastr.success('Success', 'Lease saved.');
      setIsSaving(false);
      return saveResponse;
    } catch (e) {
      toastr.error('Error', e.toString());
      setIsSaving(false);
    }
  };

  return {
    isSaving,
    handleSaveLease,
  };
};

export const useCommercialLeaseOptionsSave = (
  selectedProperty: Object,
  refreshOptions: boolean,
  refreshOptionsCallback: Function,
  setCommercialOptionSelected: Function,
) => {
  const [isSaving, setIsSaving] = useState(false);
  const handleSaveOption = async (leaseId: string, payload: Object) => {
    try {
      setIsSaving(true);
      const organizationId = pathOr('', ['organizationId'], selectedProperty);
      const propertyId = pathOr('', ['id'], selectedProperty);
      const commercialLeaseOptionsService = new CommercialLeaseOptionsService();
      let saveResponse;
      if (payload && !isEmpty(payload.id) && !isNil(payload.id)) {
        const optionId = payload.id;
        delete payload.id;
        saveResponse = await commercialLeaseOptionsService.updateLeaseOption(
          organizationId,
          propertyId,
          leaseId,
          optionId,
          payload,
        );
      } else {
        saveResponse = await commercialLeaseOptionsService.createLeaseOption(
          organizationId,
          propertyId,
          leaseId,
          payload,
        );

        const leaseService = new LeaseService();
        await leaseService.updateHasCommercialOptions(
          organizationId,
          propertyId,
          leaseId,
          null,
        );
      }
      setCommercialOptionSelected(false);
      refreshOptionsCallback(!refreshOptions);
      toastr.success('Success', 'Option saved.');
      setIsSaving(false);
      return saveResponse;
    } catch (e) {
      toastr.error('Error', e.toString());
      setIsSaving(false);
    }
  };

  return {
    isSaving,
    handleSaveOption,
  };
};

export const useCommercialLeaseOptionsDelete = (
  selectedProperty: Object,
  refreshOptions: boolean,
  refreshOptionsCallback: Function,
) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDeleteOption = async (leaseId: string, optionId: string) => {
    try {
      setIsDeleting(true);
      const organizationId = pathOr('', ['organizationId'], selectedProperty);
      const propertyId = pathOr('', ['id'], selectedProperty);
      const commercialLeaseOptionsService = new CommercialLeaseOptionsService();
      const deleteResponse = await commercialLeaseOptionsService.deleteLeaseOption(
        organizationId,
        propertyId,
        leaseId,
        optionId,
      );
      refreshOptionsCallback(!refreshOptions);
      toastr.success('Success', 'History deleted.');
      setIsDeleting(false);
      return deleteResponse;
    } catch (e) {
      toastr.error('Error', e.toString());
      setIsDeleting(false);
    }
  };

  return {
    isDeleting,
    handleDeleteOption,
  };
};

export const useAsyncCommercialLeaseOptions = (
  selectedProperty: Object,
  leaseId: string,
  refresh: boolean,
) => {
  const [commercialLeaseOptions, setCommercialLeaseOptions] = useState([]);
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };

    const fetchLeaseOptions = async () => {
      if (!isEmpty(selectedProperty) && !isNil(selectedProperty)) {
        const organizationId = pathOr('', ['organizationId'], selectedProperty);
        const propertyId = pathOr('', ['id'], selectedProperty);
        const commercialLeaseOptionsService = new CommercialLeaseOptionsService();
        const leaseOptionsResponse = await commercialLeaseOptionsService.getLeaseOptions(
          organizationId,
          propertyId,
          leaseId,
          options,
        );
        setCommercialLeaseOptions(leaseOptionsResponse);
      }
    };

    fetchLeaseOptions();
    return () => abortController.abort();
  }, [selectedProperty, leaseId, refresh]);

  return { commercialLeaseOptions };
};

export const useAsyncCommercialOptions = (selectedProperty: Object) => {
  const [commercialOptions, setCommercialOptions] = useState([]);
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };

    const fetchOptions = async () => {
      if (!isEmpty(selectedProperty) && !isNil(selectedProperty)) {
        const organizationId = pathOr('', ['organizationId'], selectedProperty);
        const propertyId = pathOr('', ['id'], selectedProperty);
        const commercialLeaseOptionsService = new CommercialLeaseOptionsService();
        const optionsResponse = await commercialLeaseOptionsService.getAllOptions(
          organizationId,
          propertyId,
          options,
        );
        setCommercialOptions(optionsResponse);
      }
    };

    fetchOptions();
    return () => abortController.abort();
  }, [selectedProperty]);

  return { commercialOptions };
};
