import { defineMessages } from 'react-intl';

const messages = defineMessages({
  cancel: {
    id: 'App.IntegrationsSetup.InsuranceModal.Cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'App.IntegrationsSetup.InsuranceModal.Confirm',
    defaultMessage: 'Confirm',
  },
  noneSelectedHeader: {
    id: 'App.IntegrationsSetup.InsuranceModal.NoneSelectedHeader',
    defaultMessage: 'None Selected',
  },
  noneSelectedBodyLine1: {
    id: 'App.IntegrationsSetup.InsuranceModal.NoneSelectedBodyLine1',
    defaultMessage: 'I certify and understand that:',
  },
  noneSelectedBodyLine2: {
    id: 'App.IntegrationsSetup.InsuranceModal.NoneSelectedBodyLine2',
    defaultMessage: '1. I want to turn off Asset Protect for my property.',
  },
  noneSelectedBodyLine3: {
    id: 'App.IntegrationsSetup.InsuranceModal.NoneSelectedBodyLine3',
    defaultMessage:
      '2. Any asset protection fees will not be applied for future leases.',
  },
  assetProtectHeader: {
    id: 'App.IntegrationsSetup.InsuranceModal.AssetProtectHeader',
    defaultMessage: 'Asset Protect Selected ',
  },
  assetProtectBodyPart1: {
    id: 'App.IntegrationsSetup.InsuranceModal.AssetProtectBodyPart1',
    defaultMessage:
      'Every household will be automatically signed up for the program. Fees are added to the',
  },
  assetProtectBodyPart2: {
    id: 'App.IntegrationsSetup.InsuranceModal.AssetProtectBodyPart2',
    defaultMessage: 'Required Non-Rent Charges',
  },
  assetProtectBodyPart3: {
    id: 'App.IntegrationsSetup.InsuranceModal.AssetProtectBodyPart3',
    defaultMessage: 'on the Lease Data tab.',
  },
  assetProtectFee: {
    id: 'App.IntegrationsSetup.InsuranceModal.AssetProtectFee',
    defaultMessage: 'Asset Protect Fee ($10.00 minimum)',
  },
});

export default messages;
