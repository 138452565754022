import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { PROPERTY_PATH_MAP } from './constants';
import messages from './messages';
import {
  useTableManageColumns,
  useTableFilterSortSearchManager,
  useTableFilterSortData,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import useUniqueTableName from '../../hooks/useUniqueTableName';
import { AppContext } from '../../containers/App/context';
import { get } from '../../utils/api';
import { useFlags } from 'launchdarkly-react-client-sdk';
import UserService from '../../services/userService';

function useHeaders({ intl, filterOptions }) {
  return useMemo(() => {
    return [
      {
        id: 'documentName',
        label: intl.formatMessage(messages.documentName),
        sortable: true,
        searchable: true,
      },
      {
        id: 'documentCategory',
        label: intl.formatMessage(messages.documentCategory),
        sortable: true,
        multiselect: true,
        filterOptions: filterOptions.documentCategoryFilterOptions,
      },
      {
        id: 'categoryDetails',
        label: intl.formatMessage(messages.categoryDetails),
        sortable: true,
        searchable: true,
        multiselect: true,
        filterOptions: filterOptions.categoryDetailsFilterOptions,
      },
      {
        id: 'numberOfAssignedProperties',
        label: intl.formatMessage(messages.numberOfAssignedProperties),
        sortable: true,
      },
      {
        id: 'actions',
        label: intl.formatMessage(messages.actions),
      },
    ];
  }, [intl, filterOptions]);
}

function useRows({ sortedAndFilteredResults, totalNumberOfProperties, intl }) {
  return useMemo(() => {
    return sortedAndFilteredResults.map((document) => {
      return {
        documentName: {
          value: document.documentName,
        },
        documentCategory: {
          value: document.documentCategory,
        },
        categoryDetails: {
          variant: 'description',
          value: document.categoryDetails,
        },
        numberOfAssignedProperties: {
          variant: 'number',
          value:
            document.numberOfAssignedProperties === totalNumberOfProperties
              ? intl.formatMessage(messages.all)
              : document.numberOfAssignedProperties,
        },
        actions: {
          variant: 'link',
          to: `/documents/${document.id}`,
          value: intl.formatMessage(messages.viewEdit),
          disabled: document.format !== 'html',
        },
      };
    });
  }, [sortedAndFilteredResults, totalNumberOfProperties, intl]);
}

function useFilterOptions({ documents }) {
  return useMemo(
    () =>
      documents.reduce(
        (filterOptions, document) => {
          if (
            !filterOptions.documentCategoryFilterOptions.find(
              (dc) => dc.value === document.documentCategory,
            )
          ) {
            filterOptions.documentCategoryFilterOptions.push({
              text: document.documentCategory,
              value: document.documentCategory,
            });
          }
          if (document.categoryDetails === undefined) {
            filterOptions.categoryDetailsFilterOptions.push({
              text: '---',
              value: '',
            });
          } else if (
            !filterOptions.categoryDetailsFilterOptions.find(
              (cd) => cd.value === document.categoryDetails,
            )
          ) {
            filterOptions.categoryDetailsFilterOptions.push({
              text: document.categoryDetails,
              value: document.categoryDetails,
            });
          }
          return filterOptions;
        },
        {
          documentCategoryFilterOptions: [],
          categoryDetailsFilterOptions: [],
        },
      ),
    [documents],
  );
}

function mapCategoryDetails(document) {
  if (document.leaseAddendum) {
    const addendum = document.leaseAddendum;
    return addendum?.isRequired === true ? 'Required' : 'Optional';
  }
  if (document.documentType) {
    const documentType = document.documentType;
    const householdBatch = [];

    if (documentType.isBatchTemplate) householdBatch.push('Batch');
    if (documentType.isHouseholdTemplate) householdBatch.push('Household');

    return householdBatch.join(', ') || '---';
  }

  return '---';
}

function mapDocumentDtoToDocumentProp(document) {
  const format =
    document.lease?.format ??
    document.leaseAddendum?.format ??
    document.documentType?.format ??
    '---';

  return {
    id: document.id,
    documentName: document.name,
    documentCategory: document.documentCategory.name,
    categoryDetails: mapCategoryDetails(document),
    numberOfAssignedProperties: document.numberOfAssignedProperties,
    format,
  };
}

const useCentralizedManageDocumentsTable = ({ intl }) => {
  const { documentManagementMvp, documentManagementLetters } = useFlags();
  const { userOrganizationId, isLdUserContextReady } = useContext(AppContext);
  const isPageEnabled = documentManagementMvp && isLdUserContextReady;
  const name = useUniqueTableName('centralized-manage-documents');
  const mergeFieldTableName = useUniqueTableName(
    'centralized-manage-documents-merge-field-inventory',
  );

  const { data: documents, isLoading: docsLoading } = useQuery(
    ['documents', userOrganizationId],
    () => {
      return get(`/${userOrganizationId}/centralized-documents`);
    },
    {
      refetchOnWindowFocus: false,
      enabled: isPageEnabled,
    },
  );
  const { data: properties, isLoading: propertiesLoading } = useQuery(
    ['properties', userOrganizationId],
    () => {
      const service = new UserService();
      return service.getAllActiveProperties(userOrganizationId);
    },
    {
      refetchOnWindowFocus: false,
      enabled: isPageEnabled,
    },
  );
  const { data: mergeFields, isLoading: mergeFieldsLoading } = useQuery(
    ['mergeFields'],
    () => {
      return get('/universal-merge-field-configurations?hideFromUsers=false');
    },
    {
      refetchOnWindowFocus: false,
      enabled: isPageEnabled,
    },
  );

  const mappedDocuments = useMemo(() => {
    return documents
      ? documents
          .filter((d) => {
            if (
              d.documentCategory.name === 'Letter' &&
              !documentManagementLetters
            )
              return false;
            return true;
          })
          .map(mapDocumentDtoToDocumentProp)
      : [];
  }, [documents, documentManagementLetters]);

  const isLoading = docsLoading || propertiesLoading;
  const filterOptions = useFilterOptions({
    documents: mappedDocuments,
  });
  const headers = useHeaders({ intl, filterOptions });

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    headers,
    initialFilterState: {},
    initialOrderBy: 'documentName',
    initialOrder: 'ASC',
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results: mappedDocuments,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP,
  });

  const rows = useRows({
    sortedAndFilteredResults,
    intl,
    totalNumberOfProperties: properties?.length ?? 0,
  });

  const {
    allColumnsHidden,
    columnOptions,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    initialColumnsState: undefined,
    firstRow: rows[0],
  });

  return {
    PROPERTY_PATH_MAP,
    allColumnsHidden,
    columnOptions,
    count: rows.length,
    dateState,
    filterState,
    filterTypeState,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    headers,
    isLoading,
    isLdUserContextReady,
    isPageEnabled,
    mergeFieldTableName,
    mergeFields,
    mergeFieldsLoading,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    totalCount: mappedDocuments?.length ?? 0,
  };
};

export default useCentralizedManageDocumentsTable;
