import type { Action } from '../../App/types';
import * as ActionTypes from './constants';

type CompleteTransferState = {
  steps: Array<Object>,
  actionsChecklist: Array<Object>,
};

export const initialState = {
  steps: [],
  actionsChecklist: [],
  loadingRequirements: false,
  submitting: false,
};

const transferResidentReducer = (
  state: CompleteTransferState = initialState,
  action: Action<*, *>,
): CompleteTransferState => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.GET_TRANSFER_REQUIREMENTS_SUCCESS: {
      return {
        ...state,
        steps: payload.steps,
        actionsChecklist: payload.checklist,
        loadingRequirements: false,
      };
    }
    case ActionTypes.GET_TRANSFER_REQUIREMENTS: {
      return {
        ...state,
        loadingRequirements: true,
      };
    }

    case ActionTypes.GET_TRANSFER_REQUIREMENTS_ERROR: {
      return {
        ...state,
        loadingRequirements: false,
      };
    }
    case ActionTypes.RESIDENT_COMPLETE_TRANSFER: {
      return {
        ...state,
        steps: payload.steps,
        actionsChecklist: payload.checklist,
        submitting: true,
      };
    }
    case ActionTypes.RESIDENT_COMPLETE_TRANSFER_SUCCESS: {
      return {
        ...state,
        submitting: false,
      };
    }

    case ActionTypes.RESIDENT_COMPLETE_TRANSFER_ERROR: {
      return {
        ...state,
        submitting: false,
      };
    }
    default:
      return state;
  }
};

export default transferResidentReducer;
