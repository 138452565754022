import { get } from '../utils/api';

export class TwoWayCommunicationService {
  async getConversationsByRecipientsIds(recipientsIds: string[]) {
    const recipientsSearchParams = recipientsIds
      .map((recipientId) => `recipientId=${recipientId}`)
      .join('&');

    return get(
      `${process.env.REACT_APP_TWO_WAY_API_URL}/conversations?${recipientsSearchParams}`,
      { isOtherApi: true },
    );
  }
  async getHasNotifications({ propertyId }) {
    if (!propertyId) return {};

    const urlSearchParams = new URLSearchParams({ propertyId });

    return get(
      `/two-way-communication/has-notifications?${urlSearchParams.toString()}`,
    );
  }
}
