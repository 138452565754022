import FileSaver from 'file-saver';
import moment from 'moment';
import { pathOr } from 'ramda';
import {
  call,
  put,
  select,
  delay,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import ApplicationService from '../../services/applicationService';
import ApplicationDecisionService from '../../services/applicationDecisionStatusService';
import ApplicantChecklistService from '../../services/applicantChecklistService';
import ApplicationStatusService from '../../services/applicationStatusService';
import TransUnionService from '../../services/transUnionService';
import AssignedUnitsService from '../../services/assignedUnitsService';
import UnitService from '../../services/unitService';
import LeaseService from '../../services/leaseService';
import PortalService from '../../services/portalService';
import DocumentService from '../../services/documentService';
import ApplicantService from '../../services/applicantService';
import * as ActionTypes from './constants';
import * as applicationActions from './actions';
import * as residentActions from '../ResidentProfile/actions';
import { getAllDocuments } from '../ManageDocuments/actions';
import {
  getProspectCompletedActivities,
  getProspectAllActivities,
} from '../PeopleProfile/actions';
import { getTaskInformation } from '../Home/actions';
import { getSignatureStatuses } from '../LeaseDataTab/actions';
import { actions as toastrActions } from 'react-redux-toastr';
import type { Saga } from 'redux-saga';
import { base64FileDowload } from '../../services/socketService';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import type { Action, Property } from '../App/types';
import type { Application } from '../CreateApplication/types';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import messages from './messages';
import { getCurrentResidentId } from '../ResidentProfile/selectors';
import { omit } from 'ramda';
import queryClient from '../../react-query-client';

import {
  DOCUMENTS_DEFAULT_PAGE,
  DOCUMENTS_DEFAULT_LIMIT,
  DOCUMENTS_DEFAULT_SORTING,
} from '../ManageDocuments/constants';
import { getSelectedOptionalAddendumIds } from '../LeaseDataTab/LeaseDataTabForm/LeaseDataTabFormSections/LeasePacket/reducer'; // eslint-disable-line
import {
  handleLeaseDownloadErrorMessage,
  handleLeaseSaveErrorMessage,
} from '../../utils/errors';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetOneApplication(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicationService = new ApplicationService();

    const response = yield applicationService.getApplication(
      organizationId,
      selectedProperty.id,
      action.payload,
    );
    yield put(applicationActions.getOneApplicationSuccess(response));
  } catch (err) {
    yield put(applicationActions.getOneApplicationError(err));
  }
}

export function* getOneApplicationSaga(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ONE_APPLICATION, fetchGetOneApplication);
}

export function* putAssignApplication({
  payload,
  onAssignationComplete,
}: Action<Application>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicationId = payload.id || '';
    if (!payload.id) {
      throw new Error('Cannot update an application without an id.');
    }
    const applicationService = new ApplicationService();
    yield applicationService.assign(payload, organizationId);
    onAssignationComplete?.();
    yield put(applicationActions.updateApplicationSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescriptionAssignee),
        title: renderTranslatedMessage(messages.successHeaderApplication),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    if (payload.tasks) {
      yield put(getTaskInformation());
    } else {
      yield put(applicationActions.getOneApplication(applicationId));
    }
  } catch (err) {
    yield put(applicationActions.updateApplicationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* assignApplication(): Saga<void> {
  yield throttle(500, ActionTypes.ASSIGN_APPLICATION, putAssignApplication);
}

export function* fetchGetAllApplicationDecisionStatus(): Saga<void> {
  try {
    const applicationDecisionService = new ApplicationDecisionService();
    const response = yield applicationDecisionService.getAll();
    yield put(
      applicationActions.getAllApplicationDecisionStatusSuccess(response),
    );
  } catch (err) {
    yield put(applicationActions.getAllApplicationDecisionStatusError(err));
  }
}

export function* getAllApplicationDecisionStatusSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_DECISION_STATUS,
    fetchGetAllApplicationDecisionStatus,
  );
}

export function* fetchGetAllApplicationStatus(): Saga<void> {
  try {
    const applicationStatusService = new ApplicationStatusService();
    const response = yield applicationStatusService.getAll();
    yield put(applicationActions.getAllApplicationStatusSuccess(response));
  } catch (err) {
    yield put(applicationActions.getAllApplicationStatusError(err));
  }
}

export function* getAllApplicationStatusSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_STATUS,
    fetchGetAllApplicationStatus,
  );
}

export function* fetchUpdateApplicantChecklist({
  payload,
}: Action<Object>): Saga<void> {
  try {
    const residentId = yield select(getCurrentResidentId);
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicantChecklistService = new ApplicantChecklistService();
    yield applicantChecklistService.updateChecklistItem(
      selectedProperty.id,
      organizationId,
      payload,
    );
    yield put(applicationActions.updateApplicantChecklistSuccess());
    if (residentId) {
      yield put(residentActions.getOneResident(residentId));
    } else {
      yield put(applicationActions.getOneApplication(payload.applicationId));
    }
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.successDescriptionApplication,
        ),
        title: renderTranslatedMessage(messages.successHeaderApplication),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(applicationActions.updateApplicantChecklistError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* putUpdateApplication({
  payload,
  toasterMessage,
  residentId,
  refreshActivityTable,
}: any): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicationId = payload.id || '';
    if (!payload.id) {
      throw new Error('Cannot update an application without an id.');
    }

    const applicationService = new ApplicationService();
    yield applicationService.update(payload, organizationId);
    yield put(applicationActions.updateApplicationSuccess());
    yield put(applicationActions.getAssignedUnits(applicationId));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages[toasterMessage]
            ? messages[toasterMessage]
            : messages.successDescriptionApplication,
        ),
        title: renderTranslatedMessage(messages.successHeaderApplication),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    if (residentId) {
      yield put(residentActions.getOneResident(residentId));
    } else {
      yield put(applicationActions.getOneApplication(applicationId));
    }
    if (refreshActivityTable) {
      refreshActivityTable?.();
    }
  } catch (err) {
    yield put(applicationActions.updateApplicationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* putUpdateCommercialApplication({
  payload,
  toasterMessage,
}: any): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicationId = payload.id || '';
    if (!payload.id) {
      throw new Error('Cannot update an application without an id.');
    }

    const applicationService = new ApplicationService();
    const response = yield applicationService.updateCommercialApplication(
      organizationId,
      selectedProperty.id,
      applicationId,
      omit(['id'], payload),
    );
    yield put(applicationActions.updateCommercialApplicationSuccess(response));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages[toasterMessage]
            ? messages[toasterMessage]
            : messages.successDescriptionApplication,
        ),
        title: renderTranslatedMessage(messages.successHeaderApplication),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(applicationActions.updateCommercialApplicationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* putUpdateApplicant({
  payload,
  toasterMessage,
}: any): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const isAdult = payload.isAdult;
    const applicantId = payload.applicantId;
    const applicantMinorId = payload.applicantMinorId;
    const idToUpdate = isAdult ? applicantId : applicantMinorId;
    if (!idToUpdate) {
      throw new Error('Cannot update an applicant without an id.');
    }
    const updatePayload = omit(
      ['isAdult', 'applicantId', 'applicantMinorId'],
      payload,
    );
    const applicantService = new ApplicantService();
    yield applicantService.updateApplicant(
      organizationId,
      selectedProperty.id,
      idToUpdate,
      updatePayload,
      isAdult,
    );
    yield put(
      applicationActions.updateApplicantSuccess(applicantId, updatePayload),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages[toasterMessage]
            ? messages[toasterMessage]
            : messages.successDescriptionApplicant,
        ),
        title: renderTranslatedMessage(messages.successHeaderApplication),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(applicationActions.updateApplicantError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* putUpdatePropertyClass({
  payload,
  toasterMessage,
}: any): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicationId = payload.id || '';
    if (!payload.id) {
      throw new Error('Cannot update an application without an id.');
    }

    const applicationService = new ApplicationService();
    yield applicationService.updatePropertyClass({
      organizationId,
      propertyId: selectedProperty.id,
      applicationId: applicationId,
      propertyClassId: payload.propertyClassId,
    });
    yield put(applicationActions.updatePropertyClassSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages[toasterMessage]
            ? messages[toasterMessage]
            : messages.successDescriptionApplication,
        ),
        title: renderTranslatedMessage(messages.successHeaderApplication),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(applicationActions.getOneApplication(applicationId));
  } catch (err) {
    yield put(applicationActions.updatePropertyClassError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* updatePropertyClassSaga(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.UPDATE_PROPERTY_CLASS,
    putUpdatePropertyClass,
  );
}

export function* updateApplicationSaga(): Saga<void> {
  yield throttle(500, ActionTypes.UPDATE_APPLICATION, putUpdateApplication);
}

export function* updateCommercialApplicationSaga(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.UPDATE_COMMERCIAL_APPLICATION,
    putUpdateCommercialApplication,
  );
}

export function* updateApplicantSaga(): Saga<void> {
  yield throttle(500, ActionTypes.UPDATE_APPLICANT, putUpdateApplicant);
}

export function* updateApplicantChecklistSaga(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.UPDATE_APPLICANT_CHECKLIST,
    fetchUpdateApplicantChecklist,
  );
}

export function* fetchGetScreeningStatus({ payload }: Object): Saga<void> {
  try {
    const transUnionService = new TransUnionService();
    let response = yield call(
      transUnionService.getScreeningStatus,
      payload.applicationId,
    );
    const code = pathOr(null, ['results', 'screen', 'resultId'], response);
    if (+code === 6 /* Pending */) {
      yield call(
        transUnionService.getScreeningApplication,
        payload.applicationId,
      );
      response = yield call(
        transUnionService.getScreeningStatus,
        payload.applicationId,
      );
    }
    if (response.error) {
      yield put(
        applicationActions.getScreeningStatusError(
          response.results,
          response.error,
        ),
      );
    } else {
      yield put(
        applicationActions.getScreeningStatusSuccess(response.results || {}),
      );
    }
  } catch (err) {
    yield put(applicationActions.getScreeningStatusError(err.toString()));
  }
}

export function* getScreeningStatus(): any {
  yield takeLatest(ActionTypes.GET_SCREENING_STATUS, fetchGetScreeningStatus);
}

export function* fetchSubmitScreeningApplication({
  payload,
}: Object): Saga<void> {
  const transUnionService = new TransUnionService();
  try {
    const applicationResponse = yield call(
      transUnionService.submitApplication,
      {
        applicationId: payload.applicationId,
        monthlyRentAmount: payload.monthlyRentAmount,
      },
    );
    if (applicationResponse.documentError) {
      yield put(
        toastrActions.add({
          type: 'error',
          message: applicationResponse.documentError,
          title: renderTranslatedMessage(messages.documentErrorHeader),
          options: {
            showCloseButton: true,
            removeOnHover: true,
          },
        }),
      );
    } else {
      yield put(
        getAllDocuments(
          payload.applicationId,
          DOCUMENTS_DEFAULT_PAGE,
          DOCUMENTS_DEFAULT_LIMIT,
          DOCUMENTS_DEFAULT_SORTING,
        ),
      );
    }
    const response = yield call(
      transUnionService.getScreeningStatus,
      payload.applicationId,
    );
    yield put(
      applicationActions.getScreeningStatusSuccess(response.results || {}),
    );
    payload?.refreshActivityTable?.();
  } catch (err) {
    const applicationId = payload.applicationId;
    yield put(applicationActions.getScreeningStatusError(err));
    const errorMessage = err
      .toString()
      .replace('The request does not conform to the request XML schema.', '')
      .trim();
    yield put(
      toastrActions.add({
        type: 'error',
        message: errorMessage,
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    const response = yield call(
      transUnionService.getScreeningStatus,
      applicationId,
    );
    yield put(
      applicationActions.getScreeningStatusSuccess(response.results || {}),
    );
  }
}

export function* submitScreeningApplication(): any {
  yield throttle(
    500,
    ActionTypes.SUBMIT_SCREENING_APPLICATION,
    fetchSubmitScreeningApplication,
  );
}

export function* fetchSaveScreeningResults({ payload }: Object): Saga<void> {
  try {
    const applicationId = payload.applicationId;
    delete payload.applicationId;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const transUnionService = new TransUnionService();
    yield call(transUnionService.save, organizationId, applicationId, payload);
    const response = yield call(
      transUnionService.getScreeningStatus,
      applicationId,
    );
    yield put(
      applicationActions.getScreeningStatusSuccess(response.results || {}),
    );
    payload?.refreshActivityTable?.();
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.finalDecision),
        title: renderTranslatedMessage(messages.successHeaderApplication),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(applicationActions.getScreeningStatusError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGenerateAdverseActionLetter({
  payload,
}: Object): Saga<void> {
  try {
    const applicationId = payload.applicationId;
    delete payload.applicationId;
    const letterType = payload.letterType;
    delete payload.letterType;
    const filename = payload.fileName;
    delete payload.fileName;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const documentService = new DocumentService();
    const response = yield documentService.saveAdverseActionLetter(
      organizationId,
      property.id,
      applicationId,
      letterType,
      payload,
    );
    FileSaver.saveAs(response, filename);
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.generateAdverseActionLetter),
        title: renderTranslatedMessage(messages.successHeaderApplication),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(applicationActions.generateAdverseActionLetterSuccess());
    yield put(
      getAllDocuments(
        applicationId,
        DOCUMENTS_DEFAULT_PAGE,
        DOCUMENTS_DEFAULT_LIMIT,
        DOCUMENTS_DEFAULT_SORTING,
      ),
    );
  } catch (err) {
    yield put(applicationActions.generateAdverseActionLetterError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchSaveLeaseDataTab({ payload }: Object): Saga<void> {
  const { applicationId, lease, hasMoveInDateChanged = false } = payload;
  try {
    const applicationService = new ApplicationService();
    const selectedProperty = yield select(selectSelectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const optionalAddendums = yield select(getSelectedOptionalAddendumIds);
    yield applicationService.saveLeaseDataTab(
      organizationId,
      selectedProperty.id,
      applicationId,
      { ...lease, optionalAddendums },
    );
    queryClient.invalidateQueries(['affordableQualifications']);
    yield put(
      applicationActions.saveLeaseDataTabSuccess(payload.applicationId),
    );
    yield put(applicationActions.getOneApplication(payload.applicationId));
    payload?.refreshActivityTable?.();
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successLeaseSaved),
        title: renderTranslatedMessage(messages.successLeaseSavedTitle),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    if (hasMoveInDateChanged) {
      yield put(
        toastrActions.add({
          type: 'success',
          message: renderTranslatedMessage(
            messages.scheduledMoveInDateUpdatedInPortal,
          ),
          title: renderTranslatedMessage(messages.successLeaseSavedTitle),
          options: {
            showCloseButton: true,
            removeOnHover: true,
          },
        }),
      );
    }
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: handleLeaseSaveErrorMessage(err),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* saveScreeningResults(): any {
  yield throttle(
    500,
    ActionTypes.SUBMIT_SCREENING_RESULTS,
    fetchSaveScreeningResults,
  );
}

export function* generateAdverseActionLetter(): any {
  yield throttle(
    500,
    ActionTypes.SUBMIT_GENERATE_ADVERSE_ACTION_LETTER,
    fetchGenerateAdverseActionLetter,
  );
}

export function* saveLeaseDataTab(): any {
  yield throttle(500, ActionTypes.SAVE_LEASE_DATA_TAB, fetchSaveLeaseDataTab);
}

export function* fetchGetAssignedUnits({ payload }: Object): Saga<void> {
  try {
    const applicationId = payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const assignedUnitsService = new AssignedUnitsService();
    const response = yield assignedUnitsService.getByApplicationId(
      organizationId,
      property.id,
      applicationId,
    );
    yield put(applicationActions.getAssignedUnitsSuccess(response));
  } catch (err) {
    yield put(applicationActions.getAssignedUnitsError(err));
  }
}

export function* getAssignedUnits(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ASSIGNED_UNITS, fetchGetAssignedUnits);
}

export function* fetchUnassignUnit({ payload }: Object): Saga<void> {
  try {
    const applicationId = payload.applicationId;
    const assignedUnitId = payload.assignedUnitId;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const assignedUnitsService = new AssignedUnitsService();
    yield assignedUnitsService.delete(
      organizationId,
      property.id,
      assignedUnitId,
    );
    yield put(applicationActions.getAssignedUnits(applicationId));
    yield put(applicationActions.getOneApplication(applicationId));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.unitUnassigned),
        title: renderTranslatedMessage(messages.successHeaderApplication),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    payload?.refreshActivityTable?.();
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* unassignUnit(): Saga<void> {
  yield throttle(500, ActionTypes.UNASSIGN_UNIT, fetchUnassignUnit);
}

export function* fetchSaveMonthlyCharge({ payload }: Object): Saga<void> {
  const { applicationId, leaseData, transaction, updateTransactionsCb } =
    payload;
  try {
    const applicationService = new ApplicationService();
    const organizationId: string = yield select(
      selectCurrentUserOrganizationId,
    );
    const property = yield select(selectSelectedProperty);

    /**
     * TODO: We are we always saving the lease and application when saving a
     * Monthly Charge.
     * ...Maybe we don't do that.
     */
    const moveInDate = pathOr(null, ['moveInDate'], leaseData);
    const applicationUpdates = {
      id: applicationId,
      propertyId: property.id,
      voucherEffectiveDate: moveInDate,
    };
    if (moveInDate) {
      // $FlowFixMe
      yield put(applicationActions.updateApplication(applicationUpdates));
    }

    const optionalAddendums = yield select(getSelectedOptionalAddendumIds);

    yield applicationService.saveLeaseDataTab(
      organizationId,
      property.id,
      applicationId,
      { ...leaseData, optionalAddendums },
    );
    yield put(applicationActions.saveLeaseDataTabSuccess(applicationId));
    yield applicationService.saveMonthlyCharge(
      organizationId,
      property.id,
      applicationId,
      transaction,
    );
    yield put(
      applicationActions.saveMonthlyChargeSuccess(payload.applicationId),
    );

    /**
     * Remove this callback when we're no longer managing the creation of
     * transactions with sagas.
     */
    updateTransactionsCb();

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successMonthlyChargeSaved),
        title: renderTranslatedMessage(messages.successMonthlyChargeTitle),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetMonthlyTransactions({ payload }: Object): Saga<void> {
  try {
    const applicationService = new ApplicationService();
    const response = yield applicationService.getMonthlyTransactions(payload);
    yield put(applicationActions.getMonthlyTransactionsSuccess(response));
  } catch (err) {
    yield put(applicationActions.getMonthlyTransactionsError(err));
  }
}

export function* fetchDeleteMonthlyCharge({ payload }: Object): any {
  const { transaction } = payload;
  try {
    const residentId = yield select(getCurrentResidentId);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const propertyId = selectedProperty.id;
    const leaseService = new LeaseService();
    const today = moment();
    yield leaseService.deleteMonthlyTransaction(
      organizationId,
      propertyId,
      transaction.id,
    );
    if (residentId) {
      yield put(residentActions.getOneResident(residentId));
    } else {
      yield put(applicationActions.getOneApplication(payload.applicationId));
    }
    if (
      moment(transaction.startDate).isSameOrBefore(today) &&
      (moment(transaction.endDate).isAfter(today) || !transaction.endDate)
    ) {
      yield put(
        toastrActions.add({
          type: 'success',
          message: renderTranslatedMessage(
            messages.successMonthlyChargeEndedStopped,
          ),
          title: renderTranslatedMessage(messages.successMonthlyChargeTitle),
          options: {
            showCloseButton: true,
            removeOnHover: true,
          },
        }),
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'success',
          message: renderTranslatedMessage(
            messages.successMonthlyChargeDeleted,
          ),
          title: renderTranslatedMessage(messages.successMonthlyChargeTitle),
          options: {
            showCloseButton: true,
            removeOnHover: true,
          },
        }),
      );
    }
  } catch (err) {
    yield put(applicationActions.deleteMonthlyChargeError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* saveMonthlyCharge(): any {
  yield throttle(500, ActionTypes.SAVE_MONTHLY_CHARGE, fetchSaveMonthlyCharge);
}

export function* deleteMonthlyCharge(): any {
  yield takeLatest(ActionTypes.DELETE_MONTHLY_CHARGE, fetchDeleteMonthlyCharge);
}

export function* getMonthlyTransactions(): any {
  yield takeLatest(
    ActionTypes.GET_MONTHLY_TRANSACTIONS,
    fetchGetMonthlyTransactions,
  );
}

export function* watchSaveMonthlyChargeSuccess(): Saga<void> {
  yield takeLatest(
    ActionTypes.SAVE_MONTHLY_CHARGE_SUCCESS,
    fetchGetMonthlyTransactions,
  );
}

export function* deleteMonthlyChargeSuccess(): Saga<void> {
  yield takeLatest(
    ActionTypes.DELETE_MONTHLY_CHARGE_SUCCESS,
    fetchGetMonthlyTransactions,
  );
}

export function* watchSaveLeaseDataTabSuccess(): Saga<void> {
  yield takeLatest(ActionTypes.SAVE_LEASE_SUCCESS, fetchGetOneApplication);
}

export function* fetchGetUnitQuote({ payload }: Object): any {
  try {
    const unitId = payload.unitId;
    const applicationId = payload.applicationId;
    const applicantId = payload.applicantId;
    const prospectId = payload.prospectId;
    const userId = payload.userId;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const unitService = new UnitService();
    const file = yield unitService.getQuote(
      property.id,
      organizationId,
      unitId,
      userId,
      applicantId,
      prospectId,
    );
    yield FileSaver.saveAs(
      file,
      `Quote-${property.name.replace(/[^a-zA-z0-9]+/gi, '-')}-${moment().format(
        'MM-DD-YYYY',
      )}.pdf`,
    );
    yield put(
      getAllDocuments(applicationId, 1, 15, { fieldName: '', order: '' }),
    );
    payload?.refreshActivityTable?.();
    yield put(getProspectCompletedActivities(prospectId));
    yield put(getProspectAllActivities(prospectId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getUnitQuote(): Saga<void> {
  yield takeLatest(ActionTypes.GET_UNIT_QUOTE, fetchGetUnitQuote);
}

export function* fetchGenerateLeaseDocument({ payload }: Object): any {
  try {
    yield put(
      toastrActions.add({
        type: 'info',
        message: renderTranslatedMessage(messages.pendingDownload),
        title: renderTranslatedMessage(messages.pendingDownloadHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    const applicationService = new ApplicationService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const optionalAddendums = yield select(getSelectedOptionalAddendumIds);
    const lease = yield applicationService.saveLeaseDataTab(
      organizationId,
      property.id,
      payload.applicationId,
      { ...payload.data, optionalAddendums },
    );
    const leaseService = new LeaseService();
    const file = yield leaseService.generateLeaseDocument(lease);

    yield FileSaver.saveAs(file, `Lease_${moment().unix()}.pdf`);
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: handleLeaseDownloadErrorMessage(err),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* generateLeaseDocument(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.GENERATE_LEASE_DOCUMENT,
    fetchGenerateLeaseDocument,
  );
}

export function* resendPayLeaseEmail({ payload }: Object): any {
  try {
    const applicationService = new ApplicationService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    yield applicationService.resendPayLeaseEmail(
      organizationId,
      property.id,
      payload,
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: 'Resent PayLease Email',
        title: 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* resendPayLeaseEmailSaga(): Saga<void> {
  yield throttle(500, ActionTypes.RESEND_PAYLEASE_EMAIL, resendPayLeaseEmail);
}

export function* sendApplicantPortalInviteEmail({
  payload,
}: Action<Object>): Saga<void> {
  try {
    const { customerId, applicationId, leaseId, applicantType } = payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const portalService = new PortalService();
    yield portalService.sendPortalInviteEmail(
      organizationId,
      selectedProperty.id,
      customerId,
      applicantType,
    );
    yield put(applicationActions.getOneApplication(applicationId));
    yield put(
      toastrActions.add({
        type: 'success',
        message: 'Portal Invite Email Sent',
        title: 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    if (leaseId) {
      yield put(getSignatureStatuses(leaseId));
    }
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

function* sendApplicantPortalInviteEmailSaga(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.SEND_APPLICANT_PORTAL_INVITE_EMAIL,
    sendApplicantPortalInviteEmail,
  );
}

export function* handleSocketArsSuccessEvent({ payload }: Object): Saga<void> {
  const { applicationId, fileData, fileName } = payload;
  yield put(
    toastrActions.add({
      type: 'success',
      message: renderTranslatedMessage(messages.arsSuccessNotification),
      title: renderTranslatedMessage(messages.successHeader),
      options: {
        showCloseButton: true,
        closeOnToastrClick: true,
        removeOnHover: true,
        progressBar: true,
        timeOut: 15000, // Default 5000
      },
    }),
  );
  yield put(
    getAllDocuments(
      applicationId,
      DOCUMENTS_DEFAULT_PAGE,
      DOCUMENTS_DEFAULT_LIMIT,
      DOCUMENTS_DEFAULT_SORTING,
    ),
  );
  yield delay(500);
  base64FileDowload(fileData, fileName, 'application/pdf');
}

function* handleSocketArsSuccessEventSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.SOCKET_EVENT_ARS_SUCCESS,
    handleSocketArsSuccessEvent,
  );
}

function* handleSocketArsErrorEvent(): Saga<void> {
  yield put(
    toastrActions.add({
      type: 'error',
      message: renderTranslatedMessage(messages.arsErrorNotification),
      title: renderTranslatedMessage(messages.errorHeader),
      options: {
        showCloseButton: true,
        closeOnToastrClick: true,
        removeOnHover: true,
        progressBar: true,
        timeOut: 15000, // Default 5000
      },
    }),
  );
}

function* handleSocketArsErrorEvenSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.SOCKET_EVENT_ARS_ERROR,
    handleSocketArsErrorEvent,
  );
}

export default [
  getOneApplicationSaga,
  assignApplication,
  getAllApplicationDecisionStatusSaga,
  getAllApplicationStatusSaga,
  updateApplicantChecklistSaga,
  updateApplicationSaga,
  updateCommercialApplicationSaga,
  updateApplicantSaga,
  updatePropertyClassSaga,
  getScreeningStatus,
  submitScreeningApplication,
  saveScreeningResults,
  saveLeaseDataTab,
  getAssignedUnits,
  unassignUnit,
  saveMonthlyCharge,
  deleteMonthlyCharge,
  deleteMonthlyChargeSuccess,
  getMonthlyTransactions,
  watchSaveMonthlyChargeSuccess,
  watchSaveLeaseDataTabSuccess,
  getUnitQuote,
  generateLeaseDocument,
  resendPayLeaseEmailSaga,
  sendApplicantPortalInviteEmailSaga,
  handleSocketArsSuccessEventSaga,
  handleSocketArsErrorEvenSaga,
  generateAdverseActionLetter,
];
