import { createSelector } from 'reselect';
import { any, defaultTo, isNil, pathOr, pick, prop, pickAll } from 'ramda';
import cloneDeep from 'lodash/cloneDeep';
import fill from 'lodash/fill';
import moment from 'moment';

import type {
  AdditionalOccupants,
  BusinessApplication,
  EmployeeStatus,
  EmployerInformation,
  MaritalInformation,
  OtherInformation,
  ResidencialInformation,
  SecundaryFormAdditionalOccupants,
  SecundaryFormOtherInformation,
} from '../App/types';

import type { PrimaryLeaseApplicationState } from './types';
import type { SecondaryLeaseApplicationState } from '../NonPrimaryLeaseApplication/types';
/* eslint-disable */
import type { BasicInformation } from '../../components/LeaseApplicantFormCommonSections/BasicInformationSection/types';
import type { ContactInformation } from '../../components/LeaseApplicantFormCommonSections/ContactInformationSection/types';

import { defaultDisability, emptyMinor, MINOR_AMOUNT } from './constants';

const getLeaseApplication = (
  leaseApplication:
    | PrimaryLeaseApplicationState
    | SecondaryLeaseApplicationState,
) => leaseApplication;
/* eslint-enable */

const defaultToDefault = defaultTo('default');

const mapApplicantToResidenceInformation = (
  residenceInformation?: Object,
): ResidencialInformation => {
  if (isNil(residenceInformation)) {
    return {
      address: {
        street: '',
        city: '',
        state: 'default',
        zipCode: '',
      },
      dateMovedIn: undefined,
      monthlyPayment: '',
      ownOrRent: {
        lenderName: '',
        lenderPhoneNumber: '',
        landlordName: '',
        landlordPhoneNumber: '',
        doNotCurrentlyOwnRentExplanation: '',
        reasonForMoving: '',
        ownOrRentOrNotCurrently: '',
      },
    };
  }

  const address = pick(['street', 'city', 'state', 'zipCode'])(
    residenceInformation,
  );
  address.state = defaultToDefault(address.state);

  const dateMovedIn = residenceInformation.moveInDate
    ? moment(residenceInformation.moveInDate)
    : undefined;

  return {
    address,
    dateMovedIn,
    monthlyPayment: residenceInformation.monthlyPay,
    ownOrRent: {
      lenderName:
        residenceInformation.type === 'own'
          ? residenceInformation.lenderName
          : '',
      lenderPhoneNumber:
        residenceInformation.type === 'own'
          ? residenceInformation.lenderPhoneNumber
          : '',
      landlordName:
        residenceInformation.type === 'rent'
          ? residenceInformation.lenderName
          : '',
      landlordPhoneNumber:
        residenceInformation.type === 'rent'
          ? residenceInformation.lenderPhoneNumber
          : '',
      doNotCurrentlyOwnRentExplanation: residenceInformation.reasonOther,
      reasonForMoving: residenceInformation.reasonForMoving,
      ownOrRentOrNotCurrently: residenceInformation.type,
    },
  };
};

const mapApplicantToEmployerInformation = (
  employerInformation?: Object,
): EmployerInformation => {
  if (isNil(employerInformation)) {
    return {
      address: {
        street: '',
        city: '',
        state: 'default',
        zipCode: '',
      },
      position: '',
      annualSalary: '',
      from: undefined,
      to: undefined,
      employerName: '',
      phoneNumber: '',
      supervisorName: '',
    };
  }
  // $FlowFixMe
  const incompleteAddress = pick(['city', 'state', 'zipCode'])(
    employerInformation,
  );
  // $FlowFixMe
  const employerInfo = pick([
    'position',
    'annualSalary',
    'employerName',
    'supervisorName',
  ])(employerInformation);
  const from = employerInformation.from
    ? moment(employerInformation.from)
    : undefined;
  const to = employerInformation.to
    ? moment(employerInformation.to)
    : undefined;
  return {
    address: {
      ...incompleteAddress,
      street: employerInformation.employerStreetAddress,
      state: defaultToDefault(incompleteAddress.state),
    },
    phoneNumber: employerInformation.employerPhone,
    from,
    to,
    ...employerInfo,
  };
};

export const mapApplicantToCurrentResidence = createSelector(
  getLeaseApplication,
  ({ applicant }) => {
    const applicantInfo = defaultTo({}, applicant);
    const residenceInfo = pathOr(undefined, [
      'residentialInfo',
      'currentAddress',
    ])(applicantInfo);
    return mapApplicantToResidenceInformation(residenceInfo);
  },
);

export const mapApplicantToPreviousResidence = createSelector(
  getLeaseApplication,
  ({ applicant }) => {
    const applicantInfo = defaultTo({}, applicant);
    const residenceInfo = pathOr(undefined, [
      'residentialInfo',
      'previousAddress',
    ])(applicantInfo);
    return mapApplicantToResidenceInformation(residenceInfo);
  },
);
export const mapApplicantToSecondPreviousResidence = createSelector(
  getLeaseApplication,
  ({ applicant }) => {
    const applicantInfo = defaultTo({}, applicant);
    const residenceInfo = pathOr(undefined, [
      'residentialInfo',
      'secondPreviousAddress',
    ])(applicantInfo);
    return mapApplicantToResidenceInformation(residenceInfo);
  },
);

export const mapApplicantToCurrentEmployer = createSelector(
  getLeaseApplication,
  ({ applicant }) => {
    const applicantInfo = defaultTo({}, applicant);
    const employerInfo = pathOr(undefined, ['employmentInfo', 'currentJob'])(
      applicantInfo,
    );
    return mapApplicantToEmployerInformation(employerInfo);
  },
);

export const mapApplicantToSecondEmployer = createSelector(
  getLeaseApplication,
  ({ applicant }) => {
    const applicantInfo = defaultTo({}, applicant);
    const employerInfo = pathOr(undefined, ['employmentInfo', 'secondJob'])(
      applicantInfo,
    );
    return mapApplicantToEmployerInformation(employerInfo);
  },
);

export const mapApplicantToPreviousEmployer = createSelector(
  getLeaseApplication,
  ({ applicant }) => {
    const applicantInfo = defaultTo({}, applicant);
    const employerInfo = pathOr(undefined, ['employmentInfo', 'previousJob'])(
      applicantInfo,
    );
    return mapApplicantToEmployerInformation(employerInfo);
  },
);

export const mapApplicantToBasicInformation = createSelector(
  getLeaseApplication,
  ({ applicant }): BasicInformation => {
    if (isNil(applicant)) {
      return {
        firstName: '',
        lastName: '',
        birthday: undefined,
        suffix: 'default',
        stateIdOrigin: 'default',
        passportCountry: 'default',
        socialSecurityNumber: '',
        stateIdNumber: '',
        alienRegistration: '',
      };
    }

    const { basicInfo } = applicant.applicantInfo;

    return {
      ...basicInfo,
      disability: basicInfo?.disability ?? defaultDisability,
      birthday: basicInfo.birthday ? moment(basicInfo.birthday) : undefined,
      suffix: defaultToDefault(basicInfo.suffix),
      stateIdOrigin: defaultToDefault(basicInfo.stateIdOrigin),
      passportCountry: defaultToDefault(basicInfo.passportCountry),
    };
  },
);

export const mapApplicantToContactInformation = createSelector(
  getLeaseApplication,
  ({ applicant }): ContactInformation => {
    if (isNil(applicant)) {
      return {
        phoneNumber: '',
        emailAddress: '',
      };
    }

    return applicant.applicantInfo.contactInfo;
  },
);

export const mapApplicantToAdditionalOccupants = createSelector(
  getLeaseApplication,
  ({ applicant }): AdditionalOccupants => {
    if (isNil(applicant)) {
      return {
        adults: {
          adults: null,
          howManyAdults: 'default',
          adultsDecription: '',
        },
        minors: {
          minors: null,
          minorsInformation: fill(
            new Array(MINOR_AMOUNT),
            cloneDeep(emptyMinor),
          ),
        },
        anticipateChangeHousehold: null,
        needsUnitAccessible: null,
      };
    }
    const { additionalOccupants } = applicant;
    const newAdditionalOccupants = Object.assign({}, additionalOccupants);
    const cleanedMinors = newAdditionalOccupants.minors.minorsInformation.map(
      (minor) => {
        const newMinor = {
          ...pickAll(
            [
              'id',
              'applicantId',
              'firstName',
              'middleName',
              'lastName',
              'preferredName',
              'gender',
              'socialSecurityNumber',
              'studentType',
              'ethnicity',
              'race',
              'multiRace',
              'raceSource',
              'jointCustody',
              'disability',
            ],
            minor,
          ),
          affordableRelationship:
            minor?.applicant?.affordableRelationshipId ?? 'default',
          suffixId: minor?.suffixId ?? 'default',
          dateOfBirth: !isNil(minor?.dateOfBirth)
            ? moment(minor.dateOfBirth)
            : undefined,
        };

        return newMinor;
      },
    );

    newAdditionalOccupants.adults.howManyAdults =
      newAdditionalOccupants.adults.howManyAdults ?? 'default';
    newAdditionalOccupants.minors.minorsInformation = cleanedMinors;

    const minorsLengths =
      newAdditionalOccupants.minors.minorsInformation.length;
    if (minorsLengths < MINOR_AMOUNT) {
      const length = MINOR_AMOUNT - minorsLengths;
      const emptyElements = fill(new Array(length), cloneDeep(emptyMinor));
      newAdditionalOccupants.minors.minorsInformation.push(...emptyElements);
    }

    const savedAnyMinor = isNil(newAdditionalOccupants.minors.anyMinor)
      ? null
      : newAdditionalOccupants.minors.anyMinor
      ? 'yes'
      : 'no';
    const anyMinor = any(prop('id'))(cleanedMinors) ? 'yes' : savedAnyMinor;

    return {
      adults: {
        ...newAdditionalOccupants.adults,
        anyAdult: isNil(newAdditionalOccupants.adults.anyAdult)
          ? null
          : newAdditionalOccupants.adults.anyAdult
          ? 'yes'
          : 'no',
      },
      minors: {
        ...newAdditionalOccupants.minors,
        anyMinor,
      },
      anticipateChangeHousehold: isNil(
        newAdditionalOccupants.anticipateChangeHousehold,
      )
        ? null
        : newAdditionalOccupants.anticipateChangeHousehold
        ? 'yes'
        : 'no',
      needsUnitAccessible: isNil(newAdditionalOccupants.needsUnitAccessible)
        ? null
        : newAdditionalOccupants.needsUnitAccessible
        ? 'yes'
        : 'no',
      anticipateChangeHouseholdPregnancy: pathOr(
        null,
        ['anticipateChangeHouseholdPregnancy'],
        newAdditionalOccupants,
      ),
      anticipateChangeHouseholdAdoption: pathOr(
        null,
        ['anticipateChangeHouseholdAdoption'],
        newAdditionalOccupants,
      ),
      anticipateChangeHouseholdFoster: pathOr(
        null,
        ['anticipateChangeHouseholdFoster'],
        newAdditionalOccupants,
      ),
    };
  },
);

const cleanPets = (pets: Array<Object>) =>
  pets.map((pet) => {
    const newPet = {};
    newPet.petTypeId = defaultToDefault(pet.petTypeId);
    newPet.breedId = defaultToDefault(pet.breedId);
    newPet.weight = pet.weight;
    newPet.name = pet.name;
    newPet.petTag = pet.petTag;
    newPet.isServiceAnimal = pet.isServiceAnimal;

    if (!isNil(pet.id)) {
      newPet.id = pet.id;
    }
    if (!isNil(pet.applicantId)) {
      newPet.applicantId = pet.applicantId;
    }
    return newPet;
  });

const mapRadioButton = (value) => {
  return isNil(value) ? null : value ? 'yes' : 'no';
};

export const mapApplicantToSecundaryFormAdditionalOccupants = createSelector(
  getLeaseApplication,
  ({ applicant }): SecundaryFormAdditionalOccupants => {
    if (isNil(applicant)) {
      return {
        anyAdult: null,
        anyMinor: null,
      };
    }

    const { additionalOccupants } = applicant;

    return {
      anyAdult: isNil(additionalOccupants.anyAdult)
        ? null
        : additionalOccupants.anyAdult
        ? 'yes'
        : 'no',
      anyMinor: isNil(additionalOccupants.anyMinor)
        ? null
        : additionalOccupants.anyMinor
        ? 'yes'
        : 'no',
    };
  },
);

export const mapApplicantToOtherInformation = createSelector(
  getLeaseApplication,
  ({ applicant }): OtherInformation => {
    if (isNil(applicant)) {
      return {
        pets: false,
        petsInformation: [{}, {}, {}, {}],
        aboutProperty: '',
        convicted: false,
        convictedDescription: '',
        bankruptcy: false,
        bankruptcyDescription: '',
        bankruptcyCaseFiled: '',
        bankruptcyDateFiled: '',
        bankruptcyDischarged: false,
      };
    }
    const { otherInformation } = applicant;

    const newOtherInformation = Object.assign({}, otherInformation);
    const cleanedPets = cleanPets(newOtherInformation.petsInformation);
    newOtherInformation.petsInformation = cleanedPets;

    const petsLengths = newOtherInformation.petsInformation.length;
    const fieldsToDisplay = 4;
    if (petsLengths < fieldsToDisplay) {
      const length = fieldsToDisplay - petsLengths;
      const emptyElements = [...Array(length)].map(() => ({
        petTypeId: defaultToDefault(null),
        breedId: defaultToDefault(null),
      }));
      newOtherInformation.petsInformation.push(...emptyElements);
    }
    const pets = any(prop('id'))(cleanedPets)
      ? 'yes'
      : mapRadioButton(newOtherInformation.pets);
    return {
      ...newOtherInformation,
      pets,
      convicted: mapRadioButton(newOtherInformation.convicted),
      bankruptcy: mapRadioButton(newOtherInformation.bankruptcy),
      bankruptcyDateFiled: newOtherInformation.bankruptcyDateFiled
        ? moment(newOtherInformation.bankruptcyDateFiled)
        : undefined,
      bankruptcyDischarged: mapRadioButton(
        newOtherInformation.bankruptcyDischarged,
      ),
    };
  },
);

export const mapApplicantSecundaryFormToOtherInformation = createSelector(
  getLeaseApplication,
  ({ applicant }): SecundaryFormOtherInformation => {
    if (isNil(applicant)) {
      return {
        pets: false,
        convicted: false,
        convictedDescription: '',
        bankruptcy: false,
        bankruptcyDescription: '',
        bankruptcyCaseFiled: '',
        bankruptcyDateFiled: '',
        bankruptcyDischarged: false,
      };
    }
    const { otherInformation } = applicant;

    return {
      ...otherInformation,
      pets: mapRadioButton(otherInformation.pets),
      convicted: mapRadioButton(otherInformation.convicted),
      bankruptcy: mapRadioButton(otherInformation.bankruptcy),
      bankruptcyDateFiled: otherInformation.bankruptcyDateFiled
        ? moment(otherInformation.bankruptcyDateFiled)
        : undefined,
      bankruptcyDischarged: mapRadioButton(
        otherInformation.bankruptcyDischarged,
      ),
    };
  },
);

export const mapApplicantToBusinessApplication = createSelector(
  getLeaseApplication,
  ({ applicant }): BusinessApplication => {
    if (isNil(applicant)) {
      return {
        businessName: '',
        feid: '',
        duns: '',
        streetAddress: '',
        city: '',
        state: 'default',
        zipCode: '',
        creditBusinessName: '',
        creditPointOfContact: '',
        creditPhoneNumber: '',
        creditEmail: '',
        creditBusinessName2: '',
        creditPointOfContact2: '',
        creditPhoneNumber2: '',
        creditEmail2: '',
      };
    }

    const businessApplicationState = pathOr(
      null,
      ['businessApplication', 'state'],
      applicant,
    );
    const businessApplication = {
      ...applicant.businessApplication,
      state: businessApplicationState || null,
    };
    return {
      ...businessApplication,
      state: defaultToDefault(businessApplication.state),
    };
  },
);

export const mapApplicantToMaritalInformation = createSelector(
  getLeaseApplication,
  ({ applicant }): MaritalInformation => {
    if (isNil(applicant)) {
      return {
        maritalStatus: undefined,
        firstName: '',
        middleName: '',
        lastName: '',
        preferredName: '',
      };
    }

    const { maritalInfo } = applicant.applicantInfo;

    return {
      ...maritalInfo.spouse,
      maritalStatus: maritalInfo.maritalStatus,
    };
  },
);

export const mapApplicantToSignature = createSelector(
  getLeaseApplication,
  ({ applicant }) => {
    if (isNil(applicant)) {
      return {
        hasSignedCopy: false,
      };
    }

    return {
      hasSignedCopy: applicant.hasSignedCopy,
    };
  },
);

export const mapApplicantToStudentStatus = createSelector(
  getLeaseApplication,
  ({ applicant }): Object => {
    const isStudent = pathOr(null, ['studentInfo', 'isStudent'], applicant);
    const studentType = pathOr('', ['studentInfo', 'studentType'], applicant);
    const TANFAssistance = pathOr(
      null,
      ['studentInfo', 'TANFAssistance'],
      applicant,
    );
    const jobTrainingProgram = pathOr(
      null,
      ['studentInfo', 'jobTrainingProgram'],
      applicant,
    );
    const singleParentDependantChild = pathOr(
      null,
      ['studentInfo', 'singleParentDependantChild'],
      applicant,
    );
    const marriedJointReturn = pathOr(
      null,
      ['studentInfo', 'marriedJointReturn'],
      applicant,
    );
    const fosterProgram = pathOr(
      null,
      ['studentInfo', 'fosterProgram'],
      applicant,
    );
    const otherStudentStatus = pathOr(
      null,
      ['studentInfo', 'otherStudentStatus'],
      applicant,
    );
    return {
      isStudent: isStudent !== null ? `${isStudent}` : null,
      studentType: studentType !== null ? `${studentType}` : null,
      TANFAssistance: TANFAssistance !== null ? `${TANFAssistance}` : null,
      jobTrainingProgram:
        jobTrainingProgram !== null ? `${jobTrainingProgram}` : null,
      singleParentDependantChild:
        singleParentDependantChild !== null
          ? `${singleParentDependantChild}`
          : null,
      marriedJointReturn:
        marriedJointReturn !== null ? `${marriedJointReturn}` : null,
      fosterProgram: fosterProgram !== null ? `${fosterProgram}` : null,
      otherStudentStatus:
        otherStudentStatus !== null ? `${otherStudentStatus}` : null,
    };
  },
);

export const mapApplicantToEmploymentStatus = createSelector(
  getLeaseApplication,
  ({ applicant }): EmployeeStatus => {
    if (isNil(applicant)) {
      return {
        status: '',
        anticipateChangeEmployer: null,
        studentType: null,
      };
    }

    return {
      status: applicant.employmentInfo.employmentStatusId,
      studentType: applicant.employmentInfo.studentType,
      anticipateChangeEmployer: isNil(
        applicant.employmentInfo.anticipateChangeEmployer,
      )
        ? null
        : applicant.employmentInfo.anticipateChangeEmployer
        ? 'yes'
        : 'no',
    };
  },
);

export const getPropertyName = createSelector(
  getLeaseApplication,
  ({ applicant }) => pathOr('', ['propertyName'], defaultTo({}, applicant)),
);

export const getApplicationIncomeMultiplier = createSelector(
  getLeaseApplication,
  ({ applicant }) =>
    pathOr(
      'Three (3)',
      ['applicationIncomeMultiplier'],
      defaultTo({}, applicant),
    ),
);

export const getPropertyAffordable = createSelector(
  getLeaseApplication,
  ({ applicant }) => {
    const propertyAffordable = pathOr(
      false,
      ['propertyAffordable'],
      defaultTo({}, applicant),
    );
    const applicationAffordable = pathOr(
      false,
      ['applicationAffordable'],
      defaultTo({}, applicant),
    );
    return propertyAffordable || applicationAffordable;
  },
);
