import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Field } from 'redux-form';

import {
  renderSelectField,
  renderRadioOption,
} from '../../utils/redux-form-helper';
import ByDateControls from './ByDateControls';

type Props = {
  store: Object,
};

const LEASE_TYPE_OPTIONS = [
  { value: '', text: 'Choose', disabled: true },
  { value: 'new-lease', text: 'New Lease', disabled: false },
  { value: 'renewal', text: 'Renewal', disabled: false },
  { value: 'all', text: 'All', disabled: false },
];

const typeRangeOption = {
  value: 'range',
  text: 'Current Lease Start Date',
};

function LeaseChangeOutForm({ store }: Props) {
  return (
    <Stack>
      <Field
        store={store}
        name="typeRange"
        fieldName="typeRange"
        title={
          'Date range will produce results for new and renewal leases with a lease start date in the selected range.'
        }
        option={typeRangeOption}
        component={renderRadioOption}
        bsSize="lg"
        inline
      />
      <ByDateControls store={store} />
      <div className="form-group">
        <label>Lease Type</label>
        <Field
          store={store}
          name="leaseType"
          component={renderSelectField}
          bsSize="lg"
          options={LEASE_TYPE_OPTIONS}
        />
      </div>
    </Stack>
  );
}

export default LeaseChangeOutForm;
