import React from 'react';
import { IconButton } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  ExpandLessIcon,
  MenuIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import PropertySearchMenu from './PropertySearchMenu';
import UserMenu from './UserMenu';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ToolbarChildren = ({
  open,
  setOpen,
  isXs,
  userMenuAnchorRef,
  userMenuOpen,
  handleUserMenuToggle,
  handleUserMenuClose,
  logout,
  selectedProperty,
  onSelectProperty,
  onSelectPortfolioSummary,
  fullName,
  userProperties,
}) => {
  const { leftsideNavButton } = useFlags();

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={() => setOpen(!open)}
        sx={{
          ml: 0.5,
          mr: 1,
          ...(leftsideNavButton && {
            display: { md: 'none' },
          }),
        }}
      >
        {open ? (
          <ExpandLessIcon sx={{ transform: 'rotate(-90deg)' }} />
        ) : (
          <MenuIcon />
        )}
      </IconButton>

      <PropertySearchMenu
        selectedProperty={selectedProperty}
        onSelectProperty={onSelectProperty}
        onSelectPortfolioSummary={onSelectPortfolioSummary}
        userProperties={userProperties}
      />

      <UserMenu
        userMenuAnchorRef={userMenuAnchorRef}
        userMenuOpen={userMenuOpen}
        handleUserMenuToggle={handleUserMenuToggle}
        handleUserMenuClose={handleUserMenuClose}
        logout={logout}
        fullName={fullName}
        isXs={isXs}
      />
    </>
  );
};

export default ToolbarChildren;
