import React, { Component } from 'react';
import PaginationFooter from '../PaginationFooter';
import { type Pagination } from '../PaginationFooter/types';

type Props = {
  children?: any,
  actions: any,
  title: any,
  pagination?: Pagination,
};

export default class ManageLayout extends Component<Props> {
  render() {
    const { children, title, actions, pagination, className } = this.props;
    return (
      <div className={`bodywrap container-fluid ${className ?? ''}`}>
        <div className="row">
          <div className="col-xs-12">
            <div className="row section-header">
              {title}
              {actions}
            </div>
            {children}
            {pagination && pagination.pageCount > 1 && (
              <PaginationFooter {...pagination} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
