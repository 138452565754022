import type { Action } from '../App/types';
import * as ActionTypes from './constants';

export function getAllKpis(signal: Object): Action<any> {
  return {
    type: ActionTypes.GET_ALL_KPIS,
    payload: {
      signal,
    },
  };
}

export function getAllKpisSuccess(kpis: Object): Action<any> {
  return {
    type: ActionTypes.GET_ALL_KPIS_SUCCESS,
    payload: {
      ...kpis,
      dataLoaded: true,
    },
  };
}

export function getKpi(
  kpi: string,
  signal: Object,
  query: string = '',
): Action<any> {
  return {
    type: ActionTypes.GET_KPI,
    payload: {
      kpi,
      query,
      signal,
    },
  };
}

export function getKpiSuccess(kpi: string, results: Object): Action<any> {
  return {
    type: ActionTypes.GET_KPI_SUCCESS,
    payload: {
      [kpi]: { value: { ...results }, kpiLoaded: true },
    },
  };
}

export function getAllKpisError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_KPIS_ERROR,
    payload: error,
  };
}

export function cleanLoadedKpis(): Action<any> {
  return {
    type: ActionTypes.CLEAN_LOADED_KPIS,
    payload: undefined,
  };
}
