import { defineMessages } from 'react-intl';

const messages = defineMessages({
  businessInformation: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.BusinessInformation',
    defaultMessage: 'Business Information',
  },
  tenantLegalName: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.TenantLegalName',
    defaultMessage: 'Tenant Legal Name',
  },
  dba: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.DBA',
    defaultMessage: 'DBA (Doing Business As)',
  },
  parentCompany: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.ParentCompany',
    defaultMessage: 'Parent Company',
  },
  phoneNumber: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.PhoneNumber',
    defaultMessage: 'Phone Number',
  },
  email: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.Email',
    defaultMessage: 'Email',
  },
  invalidEmailAddress: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.InvalidEmailAddress',
    defaultMessage: 'Invalid email address',
  },
  address: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.Address',
    defaultMessage: 'Address',
  },
  address2: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.Address2',
    defaultMessage: 'Address 2',
  },
  city: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.City',
    defaultMessage: 'City',
  },
  state: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.State',
    defaultMessage: 'State / Province',
  },
  zipCode: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.ZipCode',
    defaultMessage: 'Zip Code / Postal Code',
  },
  invalidZipCode: {
    id: 'App.CreateCommercialApplicant.BusinessInformationForm.InvalidZipCode',
    defaultMessage: 'Invalid Zip Code / Postal Code',
  },
});

export default messages;
