import moment from 'moment';
import {
  calculateRentWithAmenities,
  getMonthlyRecurringCharges,
} from '../../utils/lease-helpers';

const calculateBaths = (baths: number, halfBaths: number) => {
  if (!halfBaths) return baths;
  const totalBaths = baths + halfBaths * 0.5;
  return totalBaths;
};

export const getUnitMoveOutDate = (
  lastVacancyStartDate: string,
  ntvMoveOutDate: string,
) => {
  const moveOutDate = ntvMoveOutDate || lastVacancyStartDate;
  const moveOut = moveOutDate ? moment(moveOutDate).format('MM/DD/YYYY') : null;
  return moveOut;
};

const getUnitDaysVacant = (lastVacancyStartDate: Object) => {
  const daysVacant = lastVacancyStartDate
    ? moment().diff(moment(lastVacancyStartDate), 'days')
    : '';
  return daysVacant;
};

export const getUnitMoveInReadyDate = (
  unitStatusDescription: string,
  makeReadyDate: string,
  turnMoveOutDays: number,
  moveOut: string,
): ReturnObject => {
  if (unitStatusDescription === 'Vacant - Ready') {
    return moment();
  }

  if (
    makeReadyDate &&
    (unitStatusDescription === 'Vacant / Not Ready' ||
      unitStatusDescription === 'Occupied / On Notice to Vacate')
  ) {
    const makeReadyVal = moment
      .utc(makeReadyDate, 'YYYY-MM-DD')
      .format('MM/DD/YYYY');
    return makeReadyVal;
  }

  if (moveOut) {
    const makeReadyByTurnOutDays = moment(moveOut)
      .add(turnMoveOutDays, 'days')
      .format('MM/DD/YYYY');
    return makeReadyByTurnOutDays;
  }
  return null;
};

const getQuotingRent = (unit: Object): ReturnObject => {
  const isAvailable = unit?.unitStatus?.isAvailable;
  if (!isAvailable) {
    return null;
  }

  const startingRent =
    unit.quotingRentAmount ?? unit?.floorPlan?.quotingRentAmount;
  if (!startingRent) {
    return null;
  }

  const rent = calculateRentWithAmenities(unit, startingRent);

  return rent ? rent : null;
};

const getRequiredUnitFees = (unit: Object): ReturnObject => {
  const arr = getMonthlyRecurringCharges(unit);
  const nonRentCharges = arr.reduce((prev, fee) => (prev += fee.value), 0);

  return nonRentCharges ? nonRentCharges : null;
};

export const parseRowValues = (unit: Object) => {
  const propertyName = unit?.property?.name;
  const floorPlan = unit?.floorPlan ?? {};
  const floorPlanName = floorPlan?.internalName;
  const floorPlanId = floorPlan?.id;
  const sqFeet = floorPlan?.rentableSquareFeet;
  const unitStatus = unit?.unitStatus;
  const status = unitStatus?.description;
  const notes = unit?.notes;
  const ntvMoveOutDate =
    unit?.currentResidentHousehold?.activeLease?.noticeToVacate?.moveOutDate ??
    null;
  const isVacant = unit?.unitStatus?.isVacant ?? false;
  const isAvailable = unit?.unitStatus?.isAvailable ?? false;
  const unitStatusDescription = unit?.unitStatus?.description ?? null;
  const makeReadyDate = unit?.makeReadyDate ?? null;
  const turnMoveOutDays = unit?.property?.turnMoveOutDays ?? 0;
  const lastVacancyStartDate = isVacant ? unit?.lastVacancyStartDate : null;

  const moveOut = getUnitMoveOutDate(lastVacancyStartDate, ntvMoveOutDate);
  const daysVacant = isVacant ? getUnitDaysVacant(lastVacancyStartDate) : '';
  const moveInReady = isAvailable
    ? getUnitMoveInReadyDate(
        unitStatusDescription,
        makeReadyDate,
        turnMoveOutDays,
        moveOut,
      )
    : null;

  const quotingRent = getQuotingRent(unit);
  const requiredUnitFees = getRequiredUnitFees(unit);
  const bedsBaths = `${floorPlan?.nBeds}/${calculateBaths(
    floorPlan?.nBaths,
    floorPlan?.nHalfBaths,
  )}`;

  return {
    property: propertyName,
    floorPlan: floorPlanName,
    floorPlanId,
    bedsBaths,
    sqFeet,
    status,
    notes,
    moveOut,
    daysVacant,
    moveInReady,
    quotingRent,
    requiredUnitFees,
  };
};
