/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  changeHudLimitsTitle: {
    id: 'App.ManageFloorPlan.HudLimitsModal.ChangeHudLimitsTitle',
    defaultMessage: 'HUD Gross Rent Changes',
  },
  floorplan: {
    id: 'App.ManageFloorPlan.HudLimitsModal.Floorplan',
    defaultMessage: 'Floor Plan',
  },
  marketingName: {
    id: 'App.ManageFloorPlan.HudLimitsModal.FloorPlansFormTable.MarketingName',
    defaultMessage: 'Marketing Name',
  },
  rent: {
    id: 'App.ManageFloorPlan.HudLimitsModal.FloorPlansFormTable.Rent',
    defaultMessage: 'Rent',
  },
  newHudGrossRent: {
    id:
      'App.ManageFloorPlan.HudLimitsModal.FloorPlansFormTable.NewHudGrossRent',
    defaultMessage: 'New HUD Gross Rent',
  },
  startDate: {
    id: 'App.ManageFloorPlan.HudLimitsModal.FloorPlansFormTable.StartDate',
    defaultMessage: 'Start Date',
  },
  hudUtilityAllowance: {
    id:
      'App.ManageFloorPlan.HudLimitsModal.FloorPlansFormTable.HudUtilityAllowance',
    defaultMessage: 'HUD Utility Allowance',
  },
  newHudUtilityAllowance: {
    id:
      'App.ManageFloorPlan.HudLimitsModal.FloorPlansFormTable.NewHudUtilityAllowance',
    defaultMessage: 'New HUD Utility Allowance',
  },
  save: {
    id: 'App.ManageFloorPlan.HudLimitsModal.Save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'App.ManageFloorPlan.HudLimitsModal.Cancel',
    defaultMessage: 'Cancel',
  },
  notes: {
    id: 'App.ManageFloorPlan.HudLimitsModal.Notes',
    defaultMessage: 'Notes:',
  },
  note1: {
    id: 'App.ManageFloorPlan.HudLimitsModal.Note1',
    defaultMessage:
      '(1) Saving these gross rent changes will update all units assigned to these floor plans and open a Gross Rent Certification for all current residents assigned. A Gross Rent cert will be created every time this modal is saved.',
  },
  note2: {
    id: 'App.ManageFloorPlan.HudLimitsModal.Note2',
    defaultMessage:
      '(2) Rent and Utility Allowance amounts include the most recent updates scheduled or current amount listed for that floor plan.',
  },
  dateErrorMessage: {
    id: 'App.ManageFloorPlan.HudLimitsModal.DateErrorMessage',
    defaultMessage:
      'Start date conflicts with ending date of an existing value, please update to a further start date.',
  },
});

export default messages;
