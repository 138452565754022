import { createSelector } from 'reselect';
import {
  compose,
  findIndex,
  pathOr,
  prop,
  propEq,
  sortBy,
  toLower,
  uniq,
} from 'ramda';

import type { AppState, WorkOrder } from '../App/types';
import type { ManageWorkOrdersState } from './types';

const getAppState = (app: AppState) => app;
const getManageWorkOrdersState = (workOrders: Array<WorkOrder>) => workOrders;

export const getAssignees = createSelector(
  getManageWorkOrdersState,
  (workOrders) => {
    const assignees = [];
    workOrders.forEach((workOrder) => {
      const assigneeIndex = findIndex(propEq('value', workOrder.assignedToId))(
        assignees,
      );
      const assignee = workOrder.assignedTo ? workOrder.assignedTo : {};
      if (assigneeIndex < 0 && workOrder.assignedToId) {
        assignees.push({
          value: workOrder.assignedToId,
          text: `${assignee.firstName} ${assignee.lastName}`,
        });
      }
    });
    return sortBy(compose(toLower, prop('text')))(assignees);
  },
);

export const getDropDownAssignees = createSelector(
  getAppState,
  ({ workOrderAssignees }) => {
    if (!workOrderAssignees) {
      return [];
    }
    const assignees = workOrderAssignees.map((assignee) => {
      return {
        value: assignee.id,
        text: `${assignee.firstName} ${assignee.lastName}`,
        status: prop('name', assignee.userStatus),
      };
    });
    return [{ value: '', text: 'Choose', disabled: true }].concat(
      uniq(assignees),
    );
  },
);

export const getPriorityLevels = createSelector(
  getAppState,
  ({ priorityLevels }) => {
    if (!priorityLevels) {
      return [];
    }
    return priorityLevels.map((level) => ({
      value: level.priorityLevelId,
      text: level.priorityLevelDescription,
    }));
  },
);

export const getWorkOrderStatusList = createSelector(
  getAppState,
  ({ workOrderStatusList }) => {
    if (!workOrderStatusList) {
      return [];
    }
    return workOrderStatusList.map((wo) => {
      // $FlowFixMe
      const value = pathOr('', ['status', 'statusId'], wo);
      // $FlowFixMe
      const text = pathOr('', ['status', 'statusDescription'], wo);
      // $FlowFixMe
      const isDefaultFilter = pathOr(false, ['status', 'isDefaultFilter'], wo);
      return { value, text, isDefaultFilter };
    });
  },
);

export const selectCurrentFilter = (state: {
  manageWorkOrders: ManageWorkOrdersState,
}) => state.manageWorkOrders.currentFilter;

export const selectCurrentSorting = (state: {
  manageWorkOrders: ManageWorkOrdersState,
}) => state.manageWorkOrders.currentSorting;

export const selectSearchText = (state: {
  manageWorkOrders: ManageWorkOrdersState,
}) => state.manageWorkOrders.searchText;
