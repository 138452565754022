// $FlowFixMe
import { useEffect, useState } from 'react';
import HouseholdService from '../../../services/householdService';

export const useCheckPrimaryApplicant = (
  organizationId: string,
  propertyId: string,
  householdId: string,
  applicantId: string,
  toasterFn: Function,
) => {
  const [info, setInfo] = useState({
    isLoading: true,
    isPrimary: false,
  });

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    let subscribed = true;

    const fetchCheckPrimary = async () => {
      const householdService = new HouseholdService();
      const response = await householdService.checkIfApplicantIsPrimary(
        organizationId,
        propertyId,
        householdId,
        applicantId,
        options,
      );

      if (response.error) {
        toasterFn({
          type: 'error',
          title: 'Error',
          message: `Unable to retrieve Primary Applicant: ${response.error}`,
        });

        if (subscribed) {
          setInfo({
            isLoading: false,
            isPrimary: false,
          });
        }
      } else {
        if (subscribed) {
          setInfo({
            isLoading: false,
            isPrimary: response,
          });
        }
      }
    };

    if (subscribed) {
      fetchCheckPrimary();
    }

    return () => {
      subscribed = false;
    };
  }, [
    organizationId,
    propertyId,
    householdId,
    applicantId,
    setInfo,
    toasterFn,
  ]);

  return info;
};
