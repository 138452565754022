export const GET_ALL_RESIDENTS = 'app/ManageCommunications/GET_ALL_RESIDENTS';
export const GET_ALL_PROSPECTS = 'app/ManageCommunications/GET_ALL_PROSPECTS';
export const GET_ALL_APPLICANTS = 'app/ManageCommunications/GET_ALL_APPLICANTS';
export const GET_ALL_PROSPECTS_SUCCESS =
  'app/ManageCommunications/GET_ALL_PROSPECTS_SUCCESS';
export const GET_ALL_APPLICANTS_SUCCESS =
  'app/ManageCommunications/GET_ALL_APPLICANTS_SUCCESS';
export const GET_ALL_RESIDENTS_SUCCESS =
  'app/ManageCommunications/GET_ALL_RESIDENTS_SUCCESS';
export const GET_ALL_RESIDENTS_FAIL =
  'app/ManageCommunications/GET_ALL_RESIDENTS_FAIL';

export const FilterType = Object.freeze({
  Select: 'Select',
  Checkbox: 'Checkbox',
});

export const RESIDENT_FILTERS = [
  {
    fieldName: 'residents',
    fieldDescription: 'Lease Expiry / Balance Type',
    options: [
      { value: 'delinquentBalance', text: 'Delinquent Balance' },
      { value: 'expiring0_30Days', text: 'Expiring 0 - 30 Days' },
      { value: 'expiring31_60Days', text: 'Expiring 31 - 60 Days' },
      { value: 'expiring61_90Days', text: 'Expiring 61 - 90 Days' },
      { value: 'expiring91_120Days', text: 'Expiring 91 - 120 Days' },
      { value: 'monthToMonthLeases', text: 'Month to Month Leases' },
      { value: 'onntv', text: 'On NTV' },
      { value: 'prepaidBalance', text: 'Prepaid Balance' },
    ],
  },
  {
    fieldName: 'residentsNumberOfBeds',
    fieldDescription: '# of Bedrooms',
    options: [],
  },
  {
    fieldName: 'residentsUnitLevel',
    fieldDescription: 'Unit Level',
    options: [],
  },
  {
    fieldName: 'residentsBuildingNumber',
    fieldDescription: 'Building Number',
    options: [],
  },
  {
    fieldName: 'residentsUnitAmenities',
    fieldDescription: 'Unit Amenities',
    options: [],
  },
];

export const APPLICANTS_FILTERS = [
  {
    fieldName: 'applicants',
    fieldDescription: 'Applicants Filters',
    options: [
      { value: 'approved', text: 'Approved' },
      { value: 'pendingApproval', text: 'Pending Approval' },
    ],
  },
];

export const PROSPECT_FILTERS = [
  {
    fieldName: 'prospectsNumberOfBeds',
    fieldDescription: '# of Bedrooms',
    options: [
      { value: 'one', text: '1' },
      { value: 'two', text: '2' },
      { value: 'three', text: '3' },
    ],
  },
  {
    fieldName: 'prospects',
    fieldDescription: 'Status',
    options: [
      { value: 'active', text: 'Active' },
      { value: 'hold', text: 'Hold' },
      { value: 'deadOrLost', text: 'Lost/Dead' },
    ],
    type: FilterType.Checkbox,
  },
];

export const FILTERS_MAP = {
  residents: RESIDENT_FILTERS,
  applicants: APPLICANTS_FILTERS,
  prospects: PROSPECT_FILTERS,
};

export const SET_SELECTED_RESIDENTS =
  'app/ManageCommunications/SET_SELECTED_RESIDENTS';
export const GET_DOCUMENT_TYPES = 'app/ManageCommunications/GET_DOCUMENT_TYPES';
export const GET_DOCUMENT_TYPES_SUCCESS =
  'app/ManageCommunications/GET_DOCUMENT_TYPES_SUCCESS';
export const GENERATE_COMMUNICATION_BATCH =
  'app/ManageCommunications/GENERATE_COMMUNICATION_BATCH';
export const GENERATE_COMMUNICATION_BATCH_SUCCESS =
  'app/ManageCommunications/GENERATE_COMMUNICATION_BATCH_SUCCESS';
export const GENERATE_COMMUNICATION_BATCH_FAIL =
  'app/ManageCommunications/GENERATE_COMMUNICATION_BATCH_FAIL';
export const RESET_COMMUNICATIONS_GENERATED =
  'app/ManageCommunications/RESET_COMMUNICATIONS_GENERATED';
export const RESET_LISTS = 'app/ManageCommunications/RESET_LISTS';
export const DOWNLOAD_COMMUNICATION =
  'app/ViewCommunication/DOWNLOAD_COMMUNICATION';
export const DOWNLOAD_COMMUNICATION_SUCCESS =
  'app/ViewCommunication/DOWNLOAD_COMMUNICATION_SUCCESS';
export const DOWNLOAD_COMMUNICATION_FAIL =
  'app/ViewCommunication/DOWNLOAD_COMMUNICATION_FAIL';
export const GET_VALIDATE_RECPIENTS =
  'app/ManageCommunications/GET_VALIDATE_RECPIENTS';
export const GET_VALIDATE_RECPIENTS_SUCCESS =
  'app/ManageCommunications/GET_VALIDATE_RECPIENTS_SUCCESS';
export const GET_VALIDATE_RECPIENTS_ERROR =
  'app/ManageCommunications/GET_VALIDATE_RECPIENTS_ERROR';
export const GET_VALIDATE_EMAIL = 'app/ManageCommunications/GET_VALIDATE_EMAIL';
export const GET_VALIDATE_EMAIL_SUCCESS =
  'app/ManageCommunications/GET_VALIDATE_EMAIL_SUCCESS';
export const GET_VALIDATE_EMAIL_ERROR =
  'app/ManageCommunications/GET_VALIDATE_EMAIL_ERROR';
export const SHOW_NO_DATA_TOASTR =
  'app/ManageCommunications/SHOW_NO_DATA_TOASTR';
