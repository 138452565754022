import type { Activity } from '../ProspectProfile/types';
import type { Action } from '../App/types';
import type { TaskInformation } from './types';
import * as ActionTypes from './constants';

export function getAllActivitiesByAssignee(
  assigneeId: string,
  dates: Object = null,
): Action<any> {
  return {
    type: ActionTypes.GET_ALL_ACTIVITIES_BY_ASSIGNEE,
    payload: {
      assigneeId,
      dates,
    },
  };
}

export function getAllActivitiesByAssigneeSuccess(
  activities: Array<Activity>,
): Action<Array<Activity>> {
  return {
    type: ActionTypes.GET_ALL_ACTIVITIES_BY_ASSIGNEE_SUCCESS,
    payload: activities,
  };
}

export function clearActivitiesByAssignee(): Action<Object> {
  return {
    type: ActionTypes.CLEAR_ACTIVITIES_BY_ASSIGNEE,
    payload: null,
  };
}

export function getAllActivitiesByAssigneeError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_ACTIVITIES_BY_ASSIGNEE_ERROR,
    payload: error,
  };
}

export function setSelectedAssignee(selectedAssignee: string): Action<any> {
  return {
    type: ActionTypes.SET_SELECTED_ASSIGNEE,
    payload: selectedAssignee,
  };
}

export function setActiveKey(key: string): Action<any> {
  return {
    type: ActionTypes.SET_ACTIVE_KEY,
    payload: key,
  };
}

export function clearTaskInformation(): Action<any> {
  return {
    type: ActionTypes.CLEAR_TASK_INFORMATION,
    payload: undefined,
  };
}

export function getTaskInformation(isLoadingToggle?: Function): Action<any> {
  return {
    type: ActionTypes.GET_TASK_INFORMATION,
    payload: { isLoadingToggle },
  };
}

export function getTaskInformationSuccess(
  taskInformation: TaskInformation,
): Action<TaskInformation> {
  return {
    type: ActionTypes.GET_TASK_INFORMATION_SUCCESS,
    payload: taskInformation,
  };
}

export function getTaskInformationError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_TASK_INFORMATION_ERROR,
    payload: error,
  };
}
