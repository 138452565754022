import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import ApplicationFee from './ApplicationFee';

import messages from './messages';

import type { FieldArrayProps } from 'redux-form';

type Props = {
  fields: FieldArrayProps,
  propertyTransactionCodes: Array<Object>,
  change: Function,
  intl: Object,
  applicationFees: ?Object,
};

const FeeList = ({
  fields,
  propertyTransactionCodes,
  intl,
  change,
  applicationFees,
}: Props) => {
  return (
    <div className="applicant-grp">
      <Row className="block-heading">
        <Col xs={6} sm={10}>
          <h2>
            <FormattedMessage {...messages.feesTitleNoQuestion} />
          </h2>
        </Col>
      </Row>
      <Row className="container-fluid">
        <ApplicationFee applicationFees={applicationFees} intl={intl} />
      </Row>
    </div>
  );
};

export default FeeList;
