// $FlowFixMe
import React, { useState, useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row } from 'react-bootstrap';
import { Field, change } from 'redux-form';
import { pathOr } from 'ramda';
import styled from 'styled-components';
import { renderSelectField } from '../../../../../utils/redux-form-helper';

import {
  ELECTRONIC_SIGNING_METHOD,
  generateLeaseSigningOptions,
  MANUAL_SIGNING_METHOD,
} from '../../../../../utils/lease-helpers';

import messages from './messages';

const PaddedP = styled.p`
  padding: 10px;
  margin: 10px;
`;

type Props = {
  hasNonFR: boolean,
  intl: Object,
  isEditable: boolean,
  lease: Object,
  selectedProperty: Object,
};

export const LeaseSigningMethod = ({
  hasNonFR,
  intl,
  isEditable,
  lease,
  selectedProperty,
}: Props) => {
  const [sigMethod, setSigMethod] = useState(
    lease.desiredSignatureMethod || null,
  );
  const hasDocument = pathOr(false, ['documentId'], lease);

  const { iliElectronicLease: isIliElectronicSigningFlagOn } = useFlags();

  const isIli = useMemo(() => {
    const orgName = selectedProperty?.organization?.name;
    return orgName === 'Independent Living';
  }, [selectedProperty]);

  const updateSignatureMethodNotes = (e) => {
    change('FormLeaseDataTab', 'desiredSignatureMethod', e.target.value);
    setSigMethod(e.target.value);
  };

  const isResidentPortalActive = pathOr(
    false,
    ['isResidentPortalActive'],
    selectedProperty,
  );
  const isElectronicLeaseSigningActive = pathOr(
    false,
    ['isElectronicLeaseSigningActive'],
    selectedProperty,
  );

  return (
    <div>
      <Row className="padbottom10">
        <Col xs={12}>
          <h2 className="padtop20 required">
            <i className="et-checklist text-blue padright10" />
            <FormattedMessage {...messages.leaseSigningMethod} />
          </h2>
        </Col>
        {hasNonFR && (
          <PaddedP className="highlight-details">
            <strong>{intl.formatMessage(messages.noteOnSigningMethod)}</strong>
            {intl.formatMessage(messages.lockedNonFinancial)}
          </PaddedP>
        )}
        <Col xs={12}>
          <Row>
            <Col xs={12} className="padleft20">
              {lease.desiredSignatureMethod === 'default' ? (
                <p>
                  <FormattedMessage {...messages.note} />
                </p>
              ) : null}
            </Col>
          </Row>
          <Row className="padtop10">
            <Col xs={3} className="text-right">
              <label>
                <FormattedMessage {...messages.signingMethod} />
              </label>
            </Col>
            <Col xs={4}>
              <Field
                name="desiredSignatureMethod"
                component={renderSelectField}
                options={generateLeaseSigningOptions({
                  isResidentPortalActive,
                  isElectronicLeaseSigningActive,
                  hasNonFR,
                  isEditable,
                  sigMethod,
                  isIli,
                  isIliElectronicSigningFlagOn,
                })}
                onChange={updateSignatureMethodNotes}
                disabled={!isEditable}
              />
            </Col>
          </Row>
          <Row>
            {!hasDocument && (
              <Col xs={12}>
                {sigMethod === MANUAL_SIGNING_METHOD &&
                  lease.desiredSignatureMethod !== 'default' && (
                    <p>
                      <FormattedMessage {...messages.manualNote} />
                    </p>
                  )}
                {sigMethod === ELECTRONIC_SIGNING_METHOD &&
                  lease.desiredSignatureMethod !== 'default' && (
                    <p>
                      <FormattedMessage {...messages.electronicNote} />
                    </p>
                  )}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default injectIntl(LeaseSigningMethod);
