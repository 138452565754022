import type { Action } from '../App/types';
// type import type { ManageWorkOrdersState } from './types';
import * as ActionTypes from './constants';

export const initialState = {
  priorResidents: [],
  meta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: ''
  },
  columnOrder: {
    unit: 'sortable',
    moveInDate: 'sortable',
    moveOutDate: 'sortable',
    balance: 'sortable'
  },
  currentSorting: {
    fieldName: '',
    order: ''
  },
  searchText: ''
};

const managePriorResidentsReducer = (
  state: Object = initialState,
  action: Action<*, *>
): Object => {
  // type
  const { type, payload, meta, error } = action;
  switch (type) {
    case ActionTypes.GET_ALL_PRIOR_RESIDENTS_SUCCESS: {
      return { ...state, priorResidents: payload, meta };
    }
    case ActionTypes.UPDATE_COLUMNS_SORT_VALUE: {
      return {
        ...state,
        columnOrder: Object.assign({}, { ...initialState.columnOrder }, payload)
      };
    }
    case ActionTypes.GET_ALL_PRIOR_RESIDENTS_ERROR: {
      return { ...state, error };
    }
    case ActionTypes.CLEAN_LOADED_PRIOR_RESIDENTS: {
      return { ...initialState };
    }
    case ActionTypes.GET_ALL_PRIOR_RESIDENTS:
    default:
      return state;
  }
};

export default managePriorResidentsReducer;
