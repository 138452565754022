import { useMutation, MutationOptions } from 'react-query';
import DocumentService from '../../services/documentService';
import FileSaver from 'file-saver';
const useDownloadDocumentMutation = ({
  organizationId,
  propertyId,
  options,
}: {
  organizationId: string,
  propertyId: string,
  options?: MutationOptions,
}) => {
  return useMutation(
    async ({
      documentId,
      fileName,
    }: {
      documentId: string,
      fileName: string,
    }) => {
      const file = await new DocumentService().download(
        organizationId,
        documentId,
        propertyId,
      );
      FileSaver.saveAs(file, fileName);
    },
    { ...options },
  );
};

export default useDownloadDocumentMutation;
