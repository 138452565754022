import { useMemo, useState } from 'react';
import Datetime from 'react-datetime';
import { ControlLabel, HelpBlock, FormGroup } from 'react-bootstrap';

import styled from 'styled-components';
import moment from 'moment';
import * as R from 'ramda';
import * as formHelpers from '../../utils/redux-form-helper';

type Props = {
  from?: string,
  to?: string,
  label?: string,
  fromName?: string,
  toName?: string,
  fromPlaceholder?: string,
  toPlaceholder?: string,
  isFromDateValid?: Function,
  isToDateValid?: Function,
  exactDateValue?: string,
  exactDateLabel?: string,
  exactDatePlaceholder?: string,
  exactDateName?: string,
  onExactDateChange: Function,
  onFromChange: Function,
  onToChange: Function,
  clearExactDate: Function,
  clearDateRange: Function,
  width: string,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${R.prop('width')};

  .date-range-ctrl {
    height: 33px;
  }
`;

const Separator = styled.div`
  width: 16px;
  height: 0px;
  border: 1px solid #b4b4b4;
  margin: 0px 10px;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const dateTimeClassname = 'date-range-ctrl';

const isToDateNotSameOrAfterFromDate = (fromDate?: string, toDate?: string) => {
  if (!fromDate || !toDate) return false;

  const momentFrom = moment(fromDate);
  const momentTo = moment(toDate);

  if (!momentFrom.isValid() || !momentTo.isValid()) return false;

  return !momentTo.isSameOrAfter(momentFrom);
};

function VariableDateControl(props: Props) {
  const [isRange, setIsRange] = useState(false);
  const showError = useMemo(
    () => isToDateNotSameOrAfterFromDate(props.from, props.to),
    [props.from, props.to],
  );

  return (
    <Wrapper width={props.width}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: '120px',
        }}
      >
        <div>{props.label && <ControlLabel>{props.label}</ControlLabel>}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'start',
            gap: '8px',
          }}
        >
          <input
            type="checkbox"
            onChange={() => {
              isRange ? props.clearDateRange() : props.clearExactDate();
              setIsRange(!isRange);
            }}
          />
          <ControlLabel>Range</ControlLabel>
        </div>
      </div>
      {isRange && (
        <FormGroup validationState={showError ? 'error' : null}>
          <InputContainer>
            <Datetime
              name={props.fromName}
              value={props.from}
              inputProps={{
                role: 'fromDate',
                placeholder: props.fromPlaceholder,
                className: dateTimeClassname,
              }}
              timeFormat={false}
              closeOnSelect={true}
              onChange={props.onFromChange}
              renderInput={formHelpers.renderDatePickerInput}
            />
            <Separator />
            <Datetime
              name={props.toName}
              value={props.to}
              inputProps={{
                role: 'toDate',
                placeholder: props.toPlaceholder,
                className: dateTimeClassname,
              }}
              timeFormat={false}
              closeOnSelect={true}
              onChange={props.onToChange}
              renderInput={formHelpers.renderDatePickerInput}
            />
          </InputContainer>
          {showError && (
            <HelpBlock className="has-error">
              To date cannot be before From date
            </HelpBlock>
          )}
        </FormGroup>
      )}
      {!isRange && (
        <FormGroup>
          <InputContainer>
            <Datetime
              name={props.exactDateName}
              value={props.exactDateValue}
              inputProps={{
                role: 'exactDate',
                placeholder: props.exactDatePlaceholder,
                className: dateTimeClassname,
              }}
              timeFormat={false}
              closeOnSelect={true}
              onChange={props.onExactDateChange}
              renderInput={formHelpers.renderDatePickerInput}
            />
          </InputContainer>
        </FormGroup>
      )}
    </Wrapper>
  );
}

export default VariableDateControl;
