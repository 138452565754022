import { useState, useEffect } from 'react';
import { isNil } from 'ramda';

import ResidentService from '../../services/residentService';
import TransactionTypeService from '../../services/transactionTypesService';
import TransactionBatchService from '../../services/transactionBatchService';
import type { TxBatchPayload } from './types';

export const useFetchResidents = ({
  organizationId,
  propertyId,
  promptToaster,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentResidents, setCurrentResidents] = useState(null);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    const fetchResidents = async () => {
      setIsLoading(true);
      try {
        const residentService = new ResidentService();
        const residents = await residentService.getAllResidents(
          organizationId,
          propertyId,
        );
        setCurrentResidents(residents);
      } catch (err) {
        promptToaster({
          type: 'error',
          title: 'Error',
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (refresh) {
      fetchResidents();
      setRefresh(false);
    }
  }, [organizationId, propertyId, promptToaster, refresh]);

  return [currentResidents, isLoading];
};

export const useGetTransactionTypes = () => {
  const [transactionTypes, setTransactionTypes] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getTransactionTypes = async () => {
      setIsLoading(true);
      const transactionTypeService = new TransactionTypeService();
      const response = await transactionTypeService.getTransactionTypes();
      setTransactionTypes(response);
      setIsLoading(false);
    };
    if (!transactionTypes) getTransactionTypes();
  });

  return [transactionTypes, isLoading];
};

export const useSaveTransactionBatch = ({
  organizationId,
  propertyId,
  onComplete,
  promptToaster,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const saveTxBatch = async (payload: TxBatchPayload, isAddGroup: boolean) => {
    setIsLoading(true);
    var results;
    try {
      const txBatchService = new TransactionBatchService();
      results = await txBatchService.save(organizationId, propertyId, payload);
      promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Transaction Batch Saved',
      });
      onComplete(isAddGroup, results);
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
    }
    return results;
  };
  return [saveTxBatch, isLoading];
};

export const useFetchTransactionBatch = ({
  organizationId,
  propertyId,
  transactionBatchHeaderId,
  promptToaster,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactionBatch, setTransactionBatch] = useState(null);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    const fetchTransactionBatchHeader = async () => {
      setIsLoading(true);
      try {
        const transactionBatchService = new TransactionBatchService();
        const transactionBatch = await transactionBatchService.get(
          transactionBatchHeaderId,
          propertyId,
          organizationId,
        );
        setTransactionBatch(transactionBatch);
      } catch (err) {
        promptToaster({
          type: 'error',
          title: 'Error',
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (refresh && transactionBatchHeaderId) {
      fetchTransactionBatchHeader();
      setRefresh(false);
    }
  }, [
    organizationId,
    propertyId,
    transactionBatchHeaderId,
    promptToaster,
    refresh,
  ]);

  return [transactionBatch, isLoading];
};

export const usePostTransactionBatch = ({
  organizationId,
  propertyId,
  onComplete,
  promptToaster,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const postTxBatch = async (payload: TxBatchPayload) => {
    setIsLoading(true);
    try {
      const txBatchService = new TransactionBatchService();
      await txBatchService.post(organizationId, propertyId, payload);
      promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Transaction Batch Posting',
      });
      onComplete();
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
    }
  };
  return [postTxBatch, isLoading];
};

export const useDeleteTransactionBatch = ({
  organizationId,
  propertyId,
  transactionBatchHeaderId,
  onComplete,
  promptToaster,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteTxBatch = async () => {
    setIsLoading(true);
    try {
      if (
        !isNil(organizationId) &&
        !isNil(propertyId) &&
        !isNil(transactionBatchHeaderId)
      ) {
        const txBatchService = new TransactionBatchService();
        await txBatchService.delete(
          organizationId,
          propertyId,
          transactionBatchHeaderId,
        );
        promptToaster({
          type: 'success',
          title: 'Success',
          message: 'Transaction Batch Deleted',
        });
        onComplete();
      }
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
    }
  };
  return [deleteTxBatch, isLoading];
};
