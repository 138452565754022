import type { Action } from '../App/types';
import type { Prospect } from './types';
import * as ActionTypes from './constants';

export function saveProspectProfile(prospectProfile: Prospect): Action<Prospect> {
  return {
    type: ActionTypes.SAVE_PROSPECT_PROFILE,
    payload: prospectProfile
  };
}

export function saveProspectProfileSuccess(): Action<any> {
  return {
    type: ActionTypes.SAVE_PROSPECT_PROFILE_SUCCESS,
    payload: undefined
  };
}
