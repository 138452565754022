import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  Typography,
  Button,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';

import generalMessages from '../../App/messages';
import componentMessages from '../messages';
import moment from 'moment';

const messages = { ...generalMessages, ...componentMessages };

const StyledModal = styled(Modal)`
  .modal-content {
    width: 460px;
  }
`;

const StyledClose = styled(Button)`
  & > span {
    padding: 11px 64px;
  }
`;

const StyledTypography = styled(Typography)`
  font-size: 14px !important;
  &.bold {
    font-weight: 500;
  }
`;

export const CertHistoryBody = ({ certHistory, intl }) => {
  const { oldestSubmissionDocumentDate, certOpenedDate } = certHistory;
  const oldestSubmissionDocumentDateMoment = moment(
    oldestSubmissionDocumentDate,
  );
  const certOpenedDateMoment = moment(certOpenedDate);
  const daysSince1stSubmissionPacketUpload =
    oldestSubmissionDocumentDateMoment.isValid()
      ? moment().diff(oldestSubmissionDocumentDateMoment, 'days')
      : '---';
  const daysSinceCertOpened = certOpenedDateMoment.isValid()
    ? moment().diff(certOpenedDateMoment, 'days')
    : '---';
  return (
    <Grid container>
      <Grid item xs={12}>
        <StyledTypography className="bold">
          <FormattedMessage {...messages.daysInProcess} />
        </StyledTypography>
      </Grid>
      <Grid item xs={12}>
        <StyledTypography className="bold" component="span">
          <i className="icon et-tasks" style={{ paddingRight: '10px' }} />
          <FormattedMessage
            {...messages.daysSince}
            values={{ message: intl.formatMessage(messages.certOpened) }}
          />
          :
        </StyledTypography>
        <StyledTypography id="daysSinceCertOpened" component="span" pl="5px">
          {daysSinceCertOpened}
        </StyledTypography>
      </Grid>
      <Grid item xs={12}>
        <StyledTypography className="bold" component="span">
          <i className="icon et-forward" style={{ paddingRight: '10px' }} />
          <FormattedMessage
            {...messages.daysSince}
            values={{
              message: intl.formatMessage(messages.firstSubmissionPacketUpload),
            }}
          />
          :
        </StyledTypography>
        <StyledTypography
          id="daysSince1stSubmissionPacketUpload"
          component="span"
          pl="5px"
        >
          {daysSince1stSubmissionPacketUpload}
        </StyledTypography>
      </Grid>
    </Grid>
  );
};

const CertHistoryDetails = ({
  certHistory,
  intl,
  onCloseCertHistoryDetailsClick,
  show,
}: Object) => {
  const { headOfHousehold } = certHistory;

  return (
    <StyledModal
      className="modal transaction"
      backdrop
      show={show}
      onHide={onCloseCertHistoryDetailsClick}
    >
      <Modal.Header closeButton>
        <i className="icon et-revert" style={{ paddingRight: '10px' }} />
        <Modal.Title componentClass="h1">
          <FormattedMessage
            {...messages.certHistoryFor}
            values={{ name: headOfHousehold }}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="scrollable-modal__content row">
          <CertHistoryBody certHistory={certHistory} intl={intl} />
        </div>
      </Modal.Body>
      <div className="modal-footer">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <StyledClose
              variant="default"
              onClick={onCloseCertHistoryDetailsClick}
              size="large"
            >
              <FormattedMessage {...messages.close} />
            </StyledClose>
          </Grid>
        </Grid>
      </div>
    </StyledModal>
  );
};

export default CertHistoryDetails;
