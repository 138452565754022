import { get, post, del, put } from '../utils/api';

class commercialScheduledRentsService {
  getAll(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/leases/${leaseId}/commercial-scheduled-rents`,
      options,
    );
  }
  createRents(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    scheduledRents: Object[],
    holdover?: Object,
    options?: Object,
  ) {
    const body = JSON.stringify({
      scheduledRents,
      holdover,
    });
    return post(
      `/${organizationId}/${propertyId}/leases/${leaseId}/bulk-commercial-scheduled-rents`,
      body,
      options,
    );
  }
  deleteOne(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    rentId: string,
    options?: Object,
  ) {
    return del(
      `/${organizationId}/${propertyId}/leases/${leaseId}/commercial-scheduled-rents/${rentId}`,
      options,
    );
  }
  updateOne(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    rentId: string,
    updates: Object,
    options?: Object,
  ) {
    const body = JSON.stringify(updates);
    return put(
      `/${organizationId}/${propertyId}/leases/${leaseId}/commercial-scheduled-rents/${rentId}`,
      body,
      options,
    );
  }
  createOne(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    rent: Object,
    options?: Object,
  ) {
    const body = JSON.stringify(rent);
    return post(
      `/${organizationId}/${propertyId}/leases/${leaseId}/commercial-scheduled-rents`,
      body,
      options,
    );
  }
  updateHoldover(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    holdover: Object,
    options?: Object,
  ) {
    const body = JSON.stringify(holdover);
    return put(
      `/${organizationId}/${propertyId}/leases/${leaseId}/commercial-holdover-rents`,
      body,
      options,
    );
  }
}

export default commercialScheduledRentsService;
