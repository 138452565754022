import { pathOr } from 'ramda';

import formMessages from '../../../containers/ShortLeaseApplication/messages';

const warn = (values: Object, { intl, studentsTypes }: Object) => {
  const warnings = {};
  if (!values || !values.isStudent) {
    warnings.isStudent = intl.formatMessage(formMessages.requiredForCompletion);
    return warnings;
  }
  if (values.isStudent === 'true' && !values.studentType) {
    warnings.studentType = intl.formatMessage(
      formMessages.requiredForCompletion,
    );
    return warnings;
  }

  if (pathOr('', ['studentType'], values).indexOf('f_t') !== -1) {
    if (!values.TANFAssistance) {
      warnings.TANFAssistance = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }
    if (!values.jobTrainingProgram) {
      warnings.jobTrainingProgram = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }
    if (!values.singleParentDependantChild) {
      warnings.singleParentDependantChild = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }
    if (!values.marriedJointReturn) {
      warnings.marriedJointReturn = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }
    if (!values.fosterProgram) {
      warnings.fosterProgram = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }

    return warnings;
  }
  return warnings;
};

export default warn;
