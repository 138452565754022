import { defineMessages } from 'react-intl';

const messages = defineMessages({
  submitButton: {
    id: 'App.CreateResident.submitButton',
    defaultMessage: 'Move-in',
  },
  invalidDate: {
    id: 'App.CreateResident.InvalidDate',
    defaultMessage: 'Invalid date',
  },
  moveInDateInFuture: {
    id: 'App.CreateResident.MoveInDateInFuture',
    defaultMessage: 'Move-in Date cannot be in the future.',
  },
  moveInDateInPast: {
    id: 'App.CreateResident.MoveInDateInPast',
    defaultMessage: 'Move-in Date cannot be more than 30 days in the past.',
  },
  moveInDateInPastYear: {
    id: 'App.CreateResident.MoveInDateInPastYear',
    defaultMessage: 'Move-in Date cannot be more than 1 year in the past.',
  },
  moveInDateOverlap: {
    id: 'App.CreateResident.moveInDateOverlap',
    defaultMessage: 'Move-in Date cannot overlap with the previous resident.',
  },
  moveInLessThanStartDate: {
    id: 'App.CreateResident.MoveInLessThanStartDate',
    defaultMessage: 'Move-in Date cannot be before lease start date.',
  },
  moveInWithoutScreening: {
    id: 'App.CreateResident.MoveInWithoutScreening',
    defaultMessage: 'Approve Move-In without Screening',
  },
  invalidTransactionValue: {
    id: 'App.CreateResident.invalidTransactionValue',
    defaultMessage: 'Please input a positive value or 0.',
  },
  beforeFiscalPeriod: {
    id: 'App.CreateResident.beforeFiscalPeriod',
    defaultMessage:
      'You cannot select a move-in date that is in a prior/closed fiscal period.',
  },
  proRateAmountsToLedgerHeader: {
    id: 'App.CreateResident.ProRateAmountsToLedgerHeader',
    defaultMessage: '{type} amounts posting to ledger:',
  },
  roundProratedAmounts: {
    id: 'App.CreateResident.roundProratedAmounts',
    defaultMessage:
      'This property is configured to round prorated amounts to the nearest dollar.',
  },
  proRate: {
    id: 'App.CreateResident.ProRate',
    defaultMessage: 'Prorate',
  },
  full: {
    id: 'App.CreateResident.Full',
    defaultMessage: 'Full',
  },
});

export default messages;
