import messages from './messages';
import { validateDate } from '../../../utils/validations';
import type Moment from 'moment';

export const validateFrom = (
  moveInDateFrom: Moment,
  range: boolean,
  moveInDateTo: Moment,
) => {
  return validateDate(moveInDateFrom)
    ? !range && moveInDateFrom.isSameOrBefore(new Date())
      ? false
      : range && moveInDateTo && moveInDateFrom.isAfter(moveInDateTo)
      ? false
      : true
    : false;
};

export const validateFromMessage = (
  moveInDateFrom: Moment,
  range: boolean,
  intl: Object,
  moveInDateTo: Moment,
) => {
  return validateDate(moveInDateFrom)
    ? !range && moveInDateFrom.isSameOrBefore(new Date())
      ? intl.formatMessage(messages.valueMustBeGreaterThanToday)
      : range && moveInDateTo && moveInDateFrom.isAfter(moveInDateTo)
      ? intl.formatMessage(messages.fromValueMustLessOrEqual)
      : undefined
    : intl.formatMessage(messages.invalidDate);
};

export const validateTo = (moveInDateTo: Moment, range: boolean) => {
  return validateDate(moveInDateTo)
    ? range && moveInDateTo.isSameOrBefore(new Date())
      ? false
      : true
    : false;
};

export const validateToMessage = (
  moveInDateTo: Moment,
  range: boolean,
  intl: Object,
) => {
  return validateDate(moveInDateTo)
    ? range && moveInDateTo.isSameOrBefore(new Date())
      ? intl.formatMessage(messages.valueMustBeGreaterThanToday)
      : undefined
    : intl.formatMessage(messages.invalidDate);
};
