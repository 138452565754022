import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { partial, lt, ifElse, equals, always, isNil } from 'ramda';

import Adult from './Adult';
import messages from './messages';
import { getStateMappers } from './EditHouseholdStates';

import type { FieldArrayProps } from 'redux-form';

export const formModes = {
  PROSPECT_CONVERT: 'PROSPECT_CONVERT',
  PROSPECT_HOUSEHOLD: 'PROSPECT_HOUSEHOLD',
  APPLICATION_HOUSEHOLD: 'APPLICATION_HOUSEHOLD',
  DEFAULT_MODE: 'DEFAULT_MODE',
};

type Props = {
  fields: FieldArrayProps,
  onAddNew: Function,
  onDelete: Function,
  onPrimaryChange: Function,
  dispatch: Function,
  suffixList: Array<Object>,
  relationshipList: Array<Object>,
  affordableRelationship: Array<Object>,
  applicantTypeList: Array<Object>,
  maxOccupants: number,
  fieldsRequired: boolean,
  fieldsReadOnly: boolean,
  showEditButton: boolean,
  onEdit: Function,
  isAffordable: boolean,
  primaryApplicantId: string,
  mode: string,
  leaseLocked: boolean,
  prevPrimaryId: string,
  initialValues: Object,
  guarantorOptions: Object,
  liveInCaretakerOptions: Object,
  change: Function,
};

const AdultList = ({
  fields,
  suffixList,
  relationshipList,
  affordableRelationship,
  applicantTypeList,
  onAddNew,
  onDelete,
  maxOccupants,
  fieldsRequired,
  fieldsReadOnly,
  showEditButton,
  isAffordable,
  onPrimaryChange,
  onEdit,
  primaryApplicantId,
  mode,
  leaseLocked,
  prevPrimaryId,
  initialValues,
  dispatch,
  guarantorOptions,
  liveInCaretakerOptions,
  change,
}: Props) => {
  const showAddButton = lt(fields.length, maxOccupants);
  const requiredIndicator = ifElse(
    equals(true),
    always('*'),
    always(''),
  )(fieldsRequired);
  const isProspectMode = mode === formModes.PROSPECT_HOUSEHOLD;
  const isCreateApplicationMode = mode === formModes.PROSPECT_CONVERT;
  const affordableRelationshipStateManager =
    getStateMappers(formModes)[mode] ??
    getStateMappers(formModes)[formModes.DEFAULT_MODE];

  return (
    <div className="applicant-grp">
      <Row className="block-heading">
        <h2>
          <FormattedMessage {...messages.adultsTitle} />
        </h2>
      </Row>
      <Row>
        <div className="labels-head container-of-inputs">
          {fieldsRequired && (
            <Col xs={6} sm={2}>
              <label>
                <FormattedMessage {...messages.applicantType} />
                {requiredIndicator}
              </label>
            </Col>
          )}
          <Col xs={6} sm={1}>
            <label>
              <FormattedMessage {...messages.firstName} />
              {requiredIndicator}
            </label>
          </Col>
          <Col xs={6} sm={1}>
            <label>
              <FormattedMessage {...messages.middleName} />
            </label>
          </Col>
          <Col xs={6} sm={1}>
            <label>
              <FormattedMessage {...messages.lastName} />
              {requiredIndicator}
            </label>
          </Col>
          <Col xs={6} sm={1}>
            <label>
              <FormattedMessage {...messages.nameSuffix} />
            </label>
          </Col>
          <Col xs={6} sm={isAffordable ? 1 : 2}>
            <label>
              <FormattedMessage {...messages.preferredName} />
            </label>
          </Col>
          {!isCreateApplicationMode && (
            <Col xs={6} sm={1} className="text-center">
              <label>Primary/HOH</label>
            </Col>
          )}
          {isAffordable && (
            <Col xs={6} sm={1}>
              <label>
                <FormattedMessage {...messages.affordableRelation} />
                {requiredIndicator}
              </label>
            </Col>
          )}
          <Col xs={6} sm={1}>
            <label>
              <FormattedMessage {...messages.relationShip} />
              {requiredIndicator}
            </label>
          </Col>
          <Col xs={6} sm={!isProspectMode ? 1 : 2}>
            <label>
              <FormattedMessage {...messages.phone} />
              {requiredIndicator}
            </label>
          </Col>
          <Col xs={6} sm={!isProspectMode && !isCreateApplicationMode ? 1 : 2}>
            <label>
              <FormattedMessage {...messages.email} />
            </label>
          </Col>
        </div>
        {!showEditButton && (
          <div className="container-of-trash">
            <div className="row-remove-btn">
              <label>
                <FormattedMessage {...messages.delete} />
              </label>
            </div>
          </div>
        )}
      </Row>
      {fields.map((member, index) => {
        const memberId = fields.get(index).id;
        const isPreviousPrimary = memberId === prevPrimaryId;
        const isPrimaryApplicant = memberId === primaryApplicantId;

        const affordableRelationshipBundle =
          affordableRelationshipStateManager.getAffordableRelationshipBundle({
            affordableRelationship,
            leaseLocked,
            isPreviousPrimary,
            isPrimaryApplicant,
            index,
          });
        return (
          <Adult
            id={fields.get(index).id}
            field={member}
            isProspectMode={isProspectMode}
            isProspect={fields.get(index).isProspect}
            type={fields.get(index).type}
            isNew={isNil(fields.get(index).id)}
            primaryApplicantId={primaryApplicantId}
            key={`${member}${index}`}
            index={index}
            onDelete={partial(onDelete, [index])}
            showEditButton={showEditButton}
            onEdit={partial(onEdit, [fields.get(index).id])}
            suffixList={suffixList}
            relationshipList={relationshipList}
            applicantTypeList={applicantTypeList}
            showApplicantType={fieldsRequired}
            fieldsReadOnly={fieldsReadOnly}
            isAffordable={isAffordable}
            onPrimaryChange={onPrimaryChange}
            isCreateApplicationMode={isCreateApplicationMode}
            leaseLocked={leaseLocked}
            prevPrimaryId={prevPrimaryId}
            adultInitialValues={initialValues.adults[index] || {}}
            affordableRelationshipBundle={affordableRelationshipBundle}
            guarantorOptions={guarantorOptions}
            liveInCaretakerOptions={liveInCaretakerOptions}
            change={change}
          />
        );
      })}
      {showAddButton && (
        <Row>
          <a className="btn-text" onClick={onAddNew} disabled={leaseLocked}>
            <FormattedMessage {...messages.addAdult} />
          </a>
        </Row>
      )}
    </div>
  );
};

export default AdultList;
