import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { confirmable, createConfirmation } from 'react-confirm';
import { Field, Form, formValueSelector, reduxForm } from 'redux-form';
import { curryN, defaultTo, isNil } from 'ramda';
import messages from './messages';
import validate from './validate.js';
import ColumnHeader from '../../components/ColumnHeader';
import ReportsViewHistoryDetails from './ReportsViewHistoryDetails/index';
import ErrorMessage from '../../components/ErrorMessage';
import {
  extractCurrentDateFormat,
  renderDateField,
} from '../../utils/redux-form-helper';
import moment from 'moment';
import { downloadLastReportFunc, getHistoricalReportsFunc } from './utils';
import { promptToaster } from '../../containers/App/actions';

type StateProps = {
  selectedProperty: string,
  organizationId: string,
};

type State = {
  reportWasUpdated: boolean,
};

type Props = {
  intl: any,
  pristine: boolean,
  valid: boolean,
  show: boolean,
  error: Object,
  dismiss: Function,
  proceed: Function,
  store: Object,
  reports: any,
  typeRange: string,
  organizationId: string,
  propertyId: string,
  selectedProperty: { id: string },
  mainReportId: string,
  actions: any,
  historicalSearch: boolean,
  historicalReports: Array<Object>,
};

export const ViewHistoryModal = (props: Props & StateProps & State) => {
  const {
    show,
    error,
    dismiss,
    store,
    mainReportId,
    formValues,
    intl,
    reports,
    selectedProperty,
    actions,
  } = props;

  const propertyId = selectedProperty?.id ?? null;
  const organizationId = selectedProperty?.organizationId ?? null;
  const [historicalReports, setHistoricalReports] = useState([]);
  const [historicalSearch, setHistoricalSearch] = useState(false);
  const downloadLastReport = (report: any) => {
    downloadLastReportFunc({
      id: report?.id,
      propertyId: propertyId,
      filename: report?.filename,
      mainReportId: mainReportId,
      createdAt: report?.createdAt,
      organizationId: organizationId,
      promptToaster: actions.promptToaster,
    });
  };

  const loadHistoricalReports = () => {
    const { dateFrom } = formValues;
    const formattedDate = moment(dateFrom).format('MM/DD/YY');
    getHistoricalReportsFunc({
      organizationId: organizationId,
      propertyId: selectedProperty?.id,
      dateTo: formattedDate,
      dateFrom: formattedDate,
      reportId: mainReportId,
      promptToaster: actions.promptToaster,
      setHistoricalReports: setHistoricalReports,
      setHistoricalSearch: setHistoricalSearch,
    });
  };

  const close = () => {
    setHistoricalReports([]);
    setHistoricalSearch(false);
    dismiss();
  };

  const downloadPreviousReportCurried = curryN(2, downloadLastReport);
  const disableFilter = !isNil(error);
  let reportsToDisplay = reports;
  if (historicalSearch) {
    reportsToDisplay = historicalReports;
  }
  return (
    <Form onSubmit={loadHistoricalReports}>
      <Modal backdrop bsSize="small" show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title componentClass="h1">
            {intl.formatMessage(messages.header)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {error && (
              <Row>
                <ErrorMessage message={error.message} />
              </Row>
            )}
            <Col xs={12} md={6}>
              <Field
                store={store}
                name="dateFrom"
                component={renderDateField}
                bsSize="lg"
                label={'Report Date'}
                classPicker="modal-fixed-position"
                placeholder={extractCurrentDateFormat(intl)}
                dateFormat={extractCurrentDateFormat(intl)}
              />
            </Col>
            <Col xs={12} md={6}>
              <Button
                className="btn btn-primary pull-left padtop20"
                onClick={loadHistoricalReports}
                disabled={disableFilter}
              >
                Search
              </Button>
            </Col>
          </Row>
          <Table striped className="table-prospects">
            <thead className="table-header hidden-xs">
              <tr>
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.reportGeneratedDate)}
                  sortable={false}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.reportFormat)}
                  sortable={false}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.AME)}
                  sortable={false}
                />
              </tr>
            </thead>
            <ReportsViewHistoryDetails
              intl={intl}
              reports={reportsToDisplay}
              downloadLastReport={downloadPreviousReportCurried}
              historicalSearch={historicalSearch}
            />
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={12} className="text-center">
              <Button bsStyle="default" onClick={close}>
                Close
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

let InjectedViewHistoryReportModal = reduxForm({
  form: 'viewHistoryReport',
})(ViewHistoryModal);

const selector = formValueSelector('createReport');

export const mapStateToProps = (state): StateProps => {
  const defaultToDefault = defaultTo('default');
  if (state.app.selectedProperty) {
    return {
      historicalReports: state?.manageReports?.historicalReports ?? [],
      historicalSearch: state?.manageReports?.historicalSearch ?? false,
      format: defaultToDefault(selector(state, 'format')),
      selectedProperty: state.app.selectedProperty,
      formValues: state?.form?.filterHistoricReports?.values ?? {},
      organizationId: state.app.currentUser
        ? state.app.currentUser.user.organizationId
        : '',
    };
  }
  throw new Error('A property must be selected.');
};

export const mapDispatchToProps = (dispatch: any): Object => {
  const bindedActions = bindActionCreators({ promptToaster }, dispatch);
  return { actions: bindedActions };
};

InjectedViewHistoryReportModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedViewHistoryReportModal);

InjectedViewHistoryReportModal = confirmable(InjectedViewHistoryReportModal);
const connectedDialog = reduxForm({
  form: 'filterHistoricReports',
  enableReinitialize: true,
  touchOnChange: true,
  initialValues: {
    dateFrom: new Date(),
  },
  validate: validate,
  asyncBlurFields: [],
})(InjectedViewHistoryReportModal);

const dialog = createConfirmation(connectedDialog);

export default function (
  store: any,
  intl: any,
  reports: any,
  mainReportId: string,
): Promise<any> {
  return new Promise((resolve, reject) => {
    dialog({
      store,
      intl,
      reports,
      mainReportId,
    }).then(
      (data) => resolve(data),
      (error) => reject(error),
    );
  });
}
