import { defineMessages } from 'react-intl';

const messages = defineMessages({
  leaseSigning: {
    id: 'App.LeaseSigning.LeaseSigning',
    defaultMessage: 'Lease Signing',
  },
  leaseholder: {
    id: 'App.LeaseSigning.Leaseholder',
    defaultMessage: 'Leaseholder',
  },
  portalActive: {
    id: 'App.LeaseSigning.PortalActive',
    defaultMessage: 'Portal Active',
  },
  leaseSigned: {
    id: 'App.LeaseSigning.LeaseSigned',
    defaultMessage: 'Signed',
  },
  leaseSigningStatusDefault: {
    id: 'App.LeaseSigning.Status.Default',
    defaultMessage: 'Lease has not been sent to Portal',
  },
  leaseSigningStatusDefaultSubtitle: {
    id: 'App.LeaseSigning.Status.Default.Subtitle',
    defaultMessage: 'Send to Portal on Lease Data tab.',
  },
  leaseSigningStatusSent: {
    id: 'App.LeaseSigning.Status.Sent',
    defaultMessage: 'Lease has been sent to Portal',
  },
  leaseSigningStatusExecuteReady: {
    id: 'App.LeaseSigning.Status.Execute.Ready',
    defaultMessage: 'Lease is Ready to Execute',
  },
  leaseSigningStatusExecuted: {
    id: 'App.LeaseSigning.Status.Executed',
    defaultMessage: 'Lease has been Executed',
  },
  needsResending: {
    id: 'App.LeaseSigning.Status.NeedsResending',
    defaultMessage: 'Lease needs to be resent to Portal',
  },
});

export default messages;
