import { get, post, patch } from '../utils/api';

export default class PropertyFeesService {
  getAllFeesByOrgAndMTC(
    organizationId: string,
    masterTransactionCodeId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/property-fees/${masterTransactionCodeId}`,
      options,
    );
  }
  getAll(query?: Object) {
    const queryStr = query ? `?${new URLSearchParams(query).toString()}` : '';
    return get(`/property-fees${queryStr}`);
  }
  create(payload: Object) {
    const body = JSON.stringify(payload);
    return post('/property-fees', body);
  }
  getById(id: string, query?: Object) {
    const queryStr = query ? `?${new URLSearchParams(query).toString()}` : '';
    return get(`/property-fees/${id}${queryStr}`);
  }
  updatePropertyFee(id: string, payload: Object) {
    const body = JSON.stringify(payload);
    return patch(`/property-fees/${id}`, body);
  }
  updateAssignedUnitFees(id: string, payload: Object[]) {
    const body = JSON.stringify(payload);
    return post(`/property-fees/${id}/unitFees`, body);
  }
  getAllFeesByProperty(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(`/${organizationId}/${propertyId}/all-fees`, options);
  }
  updatePropertyFeeWithAssociations(
    organizationId: string,
    propertyId: string,
    id: string,
    payload: Object,
  ) {
    const body = JSON.stringify(payload);
    return patch(
      `/${organizationId}/${propertyId}/property-fee-associations/${id}`,
      body,
    );
  }
}
