import React, { useState } from 'react';
import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';
import { Box } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Banner } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  FloppyIcon,
  SettingsIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { FormattedMessage } from 'react-intl';
import SetAsideSetupForm from './SetAsideSetupForm';
import messages from './messages';

import { initializeFormValues } from './utils';
import { useBulkEditSetAsidePrograms } from './hooks';

const SetAsideSetupModal = ({
  setAsidePrograms,
  intl,
  show,
  onClose,
  dispatch,
  propertyId,
  organizationId,
  promptToaster,
  refresh,
}: Object) => {
  const setAsideProgramsReduced = setAsidePrograms.map(
    (setAside) => setAside.setAsideProgram,
  );
  const initialValues = initializeFormValues(setAsideProgramsReduced);
  const [isValidForm, setIsValidForm] = useState(false);
  const [isEmptyForm, setIsEmptyForm] = useState(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isFormPristine, setIsFormPristine] = useState(true);
  const [formValues, setFormValues] = useState([]);

  const handleClose = () => {
    dispatch(reset('setAsideSetupForm'));
    onClose();
  };

  const { isSubmitting } = useBulkEditSetAsidePrograms(
    organizationId,
    propertyId,
    formValues,
    handleClose,
    promptToaster,
    intl,
    refresh,
  );

  const onFormSubmit = (values: Array<Object>) => {
    setFormValues(values);
  };

  const handleSubmit = () => {
    dispatch(submit('setAsideSetupForm'));
  };

  return (
    <form>
      <Modal
        title={<FormattedMessage {...messages.title} />}
        icon={<SettingsIcon />}
        open={show}
        onClose={() => handleClose(false)}
        actionsProps={[
          {
            children: <FormattedMessage {...messages.cancel} />,
            disabled: isSubmitting,
            onClick: () => handleClose(false),
            variant: 'text',
          },
          {
            children: <FormattedMessage {...messages.save} />,
            startIcon: <FloppyIcon />,
            isSubmitting,
            disabled:
              isEmptyForm ||
              !isValidForm ||
              isFormSubmitting ||
              isSubmitting ||
              isFormPristine,
            onClick: handleSubmit,
            submitButton: true,
            type: 'submit',
          },
        ]}
      >
        <Box sx={{ overflowX: 'auto', width: { xs: 'calc(100vw - 32px)' } }}>
          <Box minWidth={800}>
            <SetAsideSetupForm
              initialValues={initialValues}
              setAsidePrograms={setAsidePrograms}
              intl={intl}
              onFormSubmit={onFormSubmit}
              formState={{
                setIsEmptyForm,
                setIsValidForm,
                setIsFormSubmitting,
                setIsFormPristine,
              }}
            />
          </Box>
        </Box>

        <Banner
          BoxProps={{ width: '100%', maxWidth: '100%' }}
          TypographyProps={{ component: 'div', noWrap: false }}
          text={<FormattedMessage {...messages.noteDescription} />}
        />
      </Modal>
    </form>
  );
};

export default connect()(SetAsideSetupModal);
