import React from 'react';
import { reduxForm } from 'redux-form';
import LeftColumn from './FormLeftColumn';
import RightColumn from './FormRightColumn';
import * as constants from './constants';
import validate from './validate';
import type { LeaseBasicsFormProps } from './types';

function LeaseBasicsForm({
  intl,
  leaseTypeOptions,
  lateMethodOptions,
  retailTypeOptions,
  unit,
  customer,
  onSubmit,
  handleSubmit,
  isRetailTypeFieldShown,
  isEditable,
  onLeaseEdit,
  isRenewal,
  noticeToVacate,
}: LeaseBasicsFormProps) {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LeftColumn
        intl={intl}
        leaseTypeOptions={leaseTypeOptions}
        lateMethodOptions={lateMethodOptions}
        customer={customer}
        isEditable={isEditable}
        onLeaseEdit={onLeaseEdit}
        unit={unit}
      />
      <RightColumn
        intl={intl}
        retailTypeOptions={retailTypeOptions}
        unit={unit}
        showRetailTypeField={isRetailTypeFieldShown}
        isEditable={isEditable}
        onLeaseEdit={onLeaseEdit}
        isRenewal={isRenewal}
        noticeToVacate={noticeToVacate}
      />
    </form>
  );
}

export default reduxForm({
  form: constants.FORM_NAME,
  initialValues: {
    leaseTypeId: 'default',
    retailTypeId: 'default',
    lateMethodId: 'default',
  },
  enableReinitialize: true,
  validate: validate,
})(LeaseBasicsForm);
