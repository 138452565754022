const { request, apiURL } = require('../utils/api');

export default class CamExceptionService {
  getOptions(method: string, signal?: Object): Object {
    const options: Object = {
      method,
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    if (signal) {
      options.signal = signal;
    }
    return options;
  }

  create(organizationId: string, propertyId: string, payload: Object) {
    const options = this.getOptions('POST');
    options.body = JSON.stringify(payload);
    return request(
      `${apiURL}/${organizationId}/${propertyId}/cam-exceptions`,
      options,
    );
  }

  remove(
    organizationId: string,
    propertyId: string,
    deleteCamExceptionId: string,
  ) {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/cam-exceptions/${deleteCamExceptionId}`,
      options,
    );
  }
}
