import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { propEq, find, pathOr } from 'ramda';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { getIncomeTypeOptions, getStateOptions } from '../../App/selectors';
import { updateQualificationChecklist, printIncomeAssetForm } from '../actions';
import { getAllStates, getAllIncomeTypes } from '../../App/actions';
import messages from '../messages.js';
import type { SelectOption } from '../../App/types';
import IncomeVerificationForm from '../IncomeVerificationForm';
import AssetInformationForm from '../AssetInformationForm';
import IncomeVerificationAlternateForm from '../IncomeVerificationAlternateForm';

import { INITIAL_INCOME_VALUES } from '../IncomeVerificationForm/constants';
import { INITIAL_ASSET_VALUES } from '../AssetInformationForm/constants';
import { getQualificationHistory } from '../../AffordableQualificationHistoryTab/actions';
import { getIsAltForm } from '../utils';
import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

type Props = {
  params: Object,
  states: Array<SelectOption>,
  qualificationHistory: Array<Object>,
  userPermissions: Array<Object>,
};

type InjectedProps = {
  intl: any,
  actions: Object,
  history: Object,
  match: Object,
};

export class PriorQualification extends Component<Props & InjectedProps> {
  componentDidMount() {
    const {
      qualificationHistory,
      actions: { getAllStates, getQualificationHistory, getAllIncomeTypes },
      params: { applicationId },
    } = this.props;
    if (qualificationHistory.length === 0) {
      getQualificationHistory(applicationId);
    }
    getAllStates();
    getAllIncomeTypes();
  }

  handleIncomeSubmit = (values: Object, annualIncome: string) => {
    const {
      params: { residentId, checklistId },
      actions: { updateQualificationChecklist },
    } = this.props;
    updateQualificationChecklist(
      {
        incomeChecklist: values,
        annualIncome,
      },
      checklistId,
      residentId,
    );
  };

  handleAssetSubmit = (
    values: Object,
    monthlyIncomeFromAssets: string,
    imputedIncome: string,
  ) => {
    const {
      params: { residentId, checklistId },
      actions: { updateQualificationChecklist },
    } = this.props;
    updateQualificationChecklist(
      {
        assetChecklist: values,
        monthlyIncomeFromAssets,
        imputedIncome,
      },
      checklistId,
      residentId,
    );
  };

  goBack = () => {
    const {
      params: { residentId },
    } = this.props;
    this.props.history.push(
      getUrlWithSelectedPropertyId(`/resident/${residentId}/?tab=8`),
    );
  };

  handlePrintIncomeAssetForm = (type: string) => {
    const {
      actions: { printIncomeAssetForm },
      organizationId,
      affordableQualificationId,
      applicantId,
      applicationId,
      propertyId,
      frMembers,
      programName,
    } = this.props;
    printIncomeAssetForm(
      organizationId,
      applicationId,
      applicantId,
      propertyId,
      affordableQualificationId,
      type,
      frMembers[applicantId],
      programName,
    );
  };

  render() {
    const {
      states,
      incomeTypes,
      qualificationHistory,
      match: { path },
      params: { qualificationHistoryId, checklistId },
      intl,
      userPermissions,
      flags,
    } = this.props;
    const qualification =
      find(propEq('id', qualificationHistoryId))(qualificationHistory) || {};
    const qualificationChecklists = pathOr([], ['checklists'], qualification);
    const applicantChecklists = find(propEq('id', checklistId))(
      qualificationChecklists,
    );
    const applicantName = pathOr(
      '',
      ['applicant', 'name'],
      applicantChecklists,
    );
    const incomeChecklist = pathOr(
      {},
      ['incomeChecklist'],
      applicantChecklists,
    );
    const assetChecklist = pathOr({}, ['assetChecklist'], applicantChecklists);
    const initialIncomeValues = {
      ...INITIAL_INCOME_VALUES,
      ...incomeChecklist,
    };
    const initialAssetValues = {
      ...INITIAL_ASSET_VALUES,
      ...assetChecklist,
    };

    const qualForm = path.includes('income')
      ? 'incomeVerification'
      : 'assetInformation';

    const hasCompliancePermission = !!find(
      propEq('scope', 'compliance-approval'),
    )(userPermissions);

    const isAltForm = getIsAltForm(
      qualForm,
      { incomeChecklist, assetChecklist },
      flags,
    );

    return (
      <div className="container-fluid padtop30">
        <div className="row">
          <div className="col-xs-6 col-sm-12">
            <a className="btn-text" onClick={this.goBack}>
              <i className="et-chevron-left" />
              <FormattedMessage {...messages.goBackToProfile} />
            </a>
          </div>
        </div>
        {qualForm === 'incomeVerification' ? (
          isAltForm ? (
            <IncomeVerificationAlternateForm
              intl={intl}
              states={states}
              incomeTypes={incomeTypes}
              onSubmit={this.handleIncomeSubmit}
              initialValues={initialIncomeValues}
              applicantName={applicantName}
              hasCompliancePermission={hasCompliancePermission}
              isPrior={true}
              isAltForm={isAltForm}
              flags={flags}
            />
          ) : (
            <IncomeVerificationForm
              intl={intl}
              states={states}
              incomeTypes={incomeTypes}
              initialValues={initialIncomeValues}
              applicantName={applicantName}
              isPrior={true}
              hasCompliancePermission={hasCompliancePermission}
              onSubmit={this.handleIncomeSubmit}
              handlePrintIncomeAssetForm={this.handlePrintIncomeAssetForm}
            />
          )
        ) : (
          <AssetInformationForm
            intl={intl}
            initialValues={initialAssetValues}
            applicantName={applicantName}
            isPrior={true}
            hasCompliancePermission={hasCompliancePermission}
            onSubmit={this.handleAssetSubmit}
            handlePrintIncomeAssetForm={this.handlePrintIncomeAssetForm}
            isAltForm={isAltForm}
            flags={flags}
          />
        )}
      </div>
    );
  }
}

export const mapStateToProps = (state: Object, ownProps: Object): Object => {
  const {
    qualification: { qualificationHistory },
    app: { currentUser },
  } = state;
  return {
    params: ownProps.match.params,
    states: getStateOptions(state),
    incomeTypes: getIncomeTypeOptions(state),
    qualificationHistory,
    userPermissions: pathOr([], ['permissions'], currentUser),
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    actions: bindActionCreators(
      {
        getAllStates,
        getAllIncomeTypes,
        getQualificationHistory,
        updateQualificationChecklist,
        printIncomeAssetForm,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(injectIntl(PriorQualification)));
