import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatAmenities } from '../utils';
import messages from './messages';

const UnitAmenities = ({ intl, amenities, unitNumber }: Object) => {
  const formattedAmenities = useMemo(
    () => formatAmenities(intl, amenities).join(','),
    [amenities, intl],
  );
  return (
    <div className="panel unit-information panel-withheader">
      <div className="panel-head">
        <h2>
          {unitNumber !== '' ? (
            <FormattedMessage {...messages.unitTitle} />
          ) : (
            <FormattedMessage {...messages.floorplanTitle} />
          )}
        </h2>
      </div>
      <div className="container-fluid unit-details panel__details">
        <div className="row">
          <div className="col-xs-12">
            {formattedAmenities.length > 0 ? (
              formattedAmenities
            ) : (
              <FormattedMessage {...messages.none} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitAmenities;
