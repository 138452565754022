import { defineMessages } from 'react-intl';

const messages = defineMessages({
  collections: {
    id: 'App.IntegrationsSetup.CollectionsSetup.Collections',
    defaultMessage: 'Collections',
  },
  collectionsType: {
    id: 'App.IntegrationsSetup.CollectionsSetup.CollectionsType',
    defaultMessage: 'Collections Type',
  },
  automatic: {
    id: 'App.IntegrationsSetup.CollectionsSetup.Automatic',
    defaultMessage: 'Automatic',
  },
  manual: {
    id: 'App.IntegrationsSetup.CollectionsSetup.Manual',
    defaultMessage: 'Manual',
  },
  daysAfterFAS: {
    id: 'App.IntegrationsSetup.CollectionsSetup.DaysAfterFAS',
    defaultMessage: 'Days after FAS to send to collections',
  },
  daysAfterFASError: {
    id: 'App.IntegrationsSetup.CollectionsSetup.daysAfterFASError',
    defaultMessage: 'Must be a number between 1 and 999',
  },
  minAmount: {
    id: 'App.IntegrationsSetup.CollectionsSetup.MinAmount',
    defaultMessage: 'Minimum amount to send to collections',
  },
  minAmountError: {
    id: 'App.IntegrationsSetup.CollectionsSetup.MinAmountError',
    defaultMessage: 'Minimum amount must be between $100 and $999',
  },
  collectionsTooltip: {
    id: 'App.IntegrationsSetup.CollectionsSetup.CollectionsTooltip',
    defaultMessage:
      'Activate this feature to streamline your collections process. ' +
      'Set parameters for Days after FAS (Final Account Statement) and Minimum ' +
      'Balance Owed. Once enabled, eligible prior resident accounts meeting these criteria ' +
      'will automatically generate claims and be submitted to Hunter Warfield.',
  },
});

export default messages;
