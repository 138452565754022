import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.Household.EmergencyContactsSection.Header.Title',
    defaultMessage: 'Emergency Contacts',
  },
  edit: {
    id: 'App.Household.EmergencyContactsSection.Header.Edit',
    defaultMessage: 'Edit',
  },
  add: {
    id: 'App.Household.EmergencyContactsSection.Header.Add',
    defaultMessage: 'Add',
  },
});

export default messages;
