import { defineMessages } from 'react-intl';

const messages = defineMessages({
  manage: {
    id: 'App.PeopleProfile.ProfileDetails.Manage',
    defaultMessage: 'Manage',
  },
  edit: {
    id: 'App.PeopleProfile.ProfileDetails.Edit',
    defaultMessage: 'Edit',
  },
  status: {
    id: 'App.PeopleProfile.ProfileDetails.Status',
    defaultMessage: 'Status:',
  },
  notes: {
    id: 'App.PeopleProfile.ProfileDetails.Notes',
    defaultMessage: 'Notes:',
  },
  contactInformation: {
    id: 'App.PeopleProfile.ProfileDetails.ContactInformation',
    defaultMessage: 'Contact Information',
  },
  phone: {
    id: 'App.PeopleProfile.ProfileDetails.Phone',
    defaultMessage: 'Phone:',
  },
  email: {
    id: 'App.PeopleProfile.ProfileDetails.Email',
    defaultMessage: 'Email:',
  },
  address: {
    id: 'App.PeopleProfile.ProfileDetails.Address',
    defaultMessage: 'Address:',
  },
  city: {
    id: 'App.PeopleProfile.ProfileDetails.City',
    defaultMessage: 'City:',
  },
  state: {
    id: 'App.PeopleProfile.ProfileDetails.State',
    defaultMessage: 'State:',
  },
  zipCode: {
    id: 'App.PeopleProfile.ProfileDetails.ZipCode',
    defaultMessage: 'Zip Code / Postal Code:',
  },
  addNewNote: {
    id: 'App.PeopleProfile.ProfileDetails.AddNewNote',
    defaultMessage: 'Add a new note...',
  },
  notesRequired: {
    id: 'App.PeopleProfile.ProfileDetails.NotesRequired',
    defaultMessage: 'A note is required for this status.',
  },
  invalidName: {
    id: 'App.PeopleProfile.ProfileDetails.invalidName',
    defaultMessage: 'First name and last name must have letters or digits only',
  },
  invalidEmail: {
    id: 'App.PeopleProfile.ProfileDetails.InvalidEmail',
    defaultMessage: 'Invalid email',
  },
  invalidPhoneNumber: {
    id: 'App.PeopleProfile.ProfileDetails.InvalidPhoneNumber',
    defaultMessage: 'Invalid phone number',
  },
});

export default messages;
