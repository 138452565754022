import FileSaver from 'file-saver';

// $FlowFixMe
import io from 'socket.io-client';

/**
 * Component Socket Event Handlers
 */
import createMiscTransactionEvents from '../containers/ManageMiscTransactions/CreateMiscTransaction/sockets.js';
import createTransactionEvents from '../components/CreateTransaction/sockets';
import paymentBatchEvents from '../containers/ManagePaymentBatch/sockets.js';
import residentProfileEvents from '../containers/ResidentProfile/sockets';
import applicationProfileEvents from '../containers/ApplicationProfile/sockets';
import reverseMiscTransactionEvents from '../containers/ManageMiscTransactions/sockets.js';
import reverseTransactionEvents from '../containers/Ledger/sockets.js';

// Auth
import logoutExpiredSocket from '../containers/Login/sockets.js';

/**
 * Provide authenticated socket.io connection
 */
class SocketProvider {
  socket: any;

  /**
   * Create an authenticated socket.io connection
   */
  connect(dispatch: Function) {
    const sessionString = localStorage.getItem('session_id');
    if (!sessionString) {
      this.disconnect();
      throw new Error('Unable to attain socket while logged out.');
    }
    this.socket = io(
      // Associate this Socket connection with current User
      process.env.REACT_APP_API_SOCKET_URL || 'http://localhost:8000',
      {
        reconnectionDelay: 15000, // Initially wait 15 seconds to reconnect
        reconnectionDelayMax: 180000, // Wait a maximum of 3 minutes to reconnect
        // Randomize the reconnectionDelay by (delay * 2^<try> * (something between -0.3 and 1.3))
        randomizationFactor: 0.3,
        timeout: 10000,
        transportOptions: {
          timestampRequests: true,
          polling: {
            extraHeaders: {
              authorization: sessionString,
            },
          },
        },
        withCredentials: true, // Allows Sticky Cookie to be used in Cross-Origin request
      },
    );

    /**
     * Register Socket Event Hanlders
     */
    createMiscTransactionEvents(dispatch, this.socket);
    createTransactionEvents(dispatch, this.socket);
    logoutExpiredSocket(dispatch, this.socket);
    paymentBatchEvents(dispatch, this.socket);
    residentProfileEvents(dispatch, this.socket);
    applicationProfileEvents(dispatch, this.socket);
    reverseMiscTransactionEvents(dispatch, this.socket);
    reverseTransactionEvents(dispatch, this.socket);
  }

  /**
   * Disconnect Socket connection and remove all handlers
   */
  disconnect() {
    if (this.socket) {
      this.socket.removeAllListeners();
      this.socket.disconnect();
      this.socket = null;
    }
  }

  /**
   * Replace Auth token used in Authorization header for this c
   */
  updateAuthToken({ token }: { token: string }) {
    if (this.socket.connected) {
      this.socket.io.opts.transportOptions.polling.extraHeaders.authorization =
        token;
      this.socket.emit('session.renew', token);
    }
  }
}

/**
 * Generate file from Base64 encoded binary data and prompts user for download
 */
export const base64FileDowload = (
  b64Data: string,
  filename: string,
  contentType: string,
) => {
  // Decode base64 string
  const byteCharacters = atob(b64Data);

  // Get Bytevalue of each characters
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: `${contentType}:base64` });
  FileSaver.saveAs(blob, filename);
};

const socket = new SocketProvider();
export default socket;
