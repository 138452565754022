import React from 'react';
import {
  Box,
  Button,
  Typography,
  Stack,
  SubmitButton,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import ElementWithPermissions from '../../../components/ElementWithPermissions';
import confirm from '../../../components/ConfirmDialogModal';
import { navigateToUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

import messages from './messages';

type Props = {
  applicationId: string,
  doNotRenew: boolean,
  history: Object,
  intl: Object,
  lease: Object,
  removeLease: Function,
  residentId: string,
  selectPreviousLease: Function,
  underEviction: boolean,
  canComplete: boolean,
};

export const RenewalButtons = ({
  applicationId,
  doNotRenew,
  history,
  intl,
  lease,
  removeLease,
  residentId,
  selectPreviousLease,
  underEviction,
  canComplete,
}: Props) => {
  const renderConfirmDialog = () => {
    confirm(intl.formatMessage(messages.cancelRenewalConfirmationHeader), {
      body: intl.formatMessage(messages.cancelRenewalConfirmationBody),
      altBody: (
        <>
          <p key={1}>
            {intl.formatMessage(messages.cancelRenewalConfirmationBody1)}
          </p>
          <p key={2}>
            {intl.formatMessage(messages.cancelRenewalConfirmationBody2)}
          </p>
          <p key={3}>
            {intl.formatMessage(messages.cancelRenewalConfirmationBody3)}
          </p>
        </>
      ),
    }).then(() => {
      selectPreviousLease();
      removeLease(applicationId, lease.id, residentId);
    });
  };

  const url = `/complete-lease-renewal/${residentId}/${lease.id}`;
  const handleCompleteRenewLease = () =>
    navigateToUrlWithSelectedPropertyId(url);

  const { isRenewalComplete } = lease;
  const disableCompleteRenewal =
    isRenewalComplete || underEviction || doNotRenew || !canComplete;
  const getCompleteRenewalHelpBlockMessage = () => {
    if (isRenewalComplete)
      return messages.completeDisabledRenewalAlreadyCompleted;
    if (underEviction) return messages.completeDisabledUnderEviction;
    if (doNotRenew) return messages.completeDisabledDoNotRenew;
    if (!canComplete) return messages.completeDisabled;
    return messages.completeDisabledDirty;
  };

  return (
    <Col md={6}>
      <Box>
        <Stack spacing={1} direction={'row'} paddingBottom={1}>
          <ElementWithPermissions scope={['lease-create']}>
            <SubmitButton
              sx={{ flex: 1 }}
              variant={'primary'}
              disabled={disableCompleteRenewal}
              onClick={handleCompleteRenewLease}
            >
              <FormattedMessage {...messages.completeRenewalButton} />
            </SubmitButton>
          </ElementWithPermissions>
          <ElementWithPermissions scope={['lease-cancel']}>
            <Button
              sx={{ flex: 1 }}
              variant={'shoutSubtle'}
              onClick={renderConfirmDialog}
              data-testid={'cancelRenewalButton'}
            >
              <FormattedMessage {...messages.cancelRenewalButton} />
            </Button>
          </ElementWithPermissions>
        </Stack>
        {disableCompleteRenewal && (
          <Typography variant={'caption'}>
            <FormattedMessage {...getCompleteRenewalHelpBlockMessage()} />
          </Typography>
        )}
      </Box>
    </Col>
  );
};

export default injectIntl(RenewalButtons);
