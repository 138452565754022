import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewCommunication.Title',
    defaultMessage: 'Fortress - View Communications',
  },
  successHeader: {
    id: 'App.ViewCommunication.SuccessHeader',
    defaultMessage: 'Success',
  },
  returnToCreateCommunications: {
    id: 'App.ViewCommunication.returnToCreateCommunications',
    defaultMessage: 'Back to Create Communication',
  },
});

export default messages;
