import { useState, useEffect } from 'react';
import { omit } from 'ramda';

import type { BaseSetAsideHooksArgs, EditSetAsideHookArgs } from './types';
import messages from './messages';
import { parseSetAsidePrograms } from './utils';
import AffordableService from '../../../../services/affordableService';

export const useFetchPropertySetAsidePrograms = ({
  intl,
  organizationId,
  propertyId,
  promptToaster,
}: BaseSetAsideHooksArgs): Array<any> => {
  const [setAsides, setSetAsides] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [previousPropertyId, setPreviousPropertyId] = useState(null);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetch = async () => {
      setIsLoading(true);
      try {
        const affordableService = new AffordableService();
        const response = await affordableService.getAllSetAsideProgramsForProperty(
          organizationId,
          propertyId,
          abortController.signal,
        );
        const parsedSetAsides = parseSetAsidePrograms(response);
        setSetAsides(parsedSetAsides);
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.setAsidesFetchError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    };

    if ((shouldRefresh || propertyId !== previousPropertyId) && !isLoading) {
      fetch();
      setPreviousPropertyId(propertyId);
    }

    return () => {
      setShouldRefresh(false);
    };
  }, [
    organizationId,
    propertyId,
    promptToaster,
    intl,
    shouldRefresh,
    isLoading,
    previousPropertyId,
  ]);

  return [isLoading, setAsides, () => setShouldRefresh(true)];
};

export const useEditSetAsideProgram = ({
  intl,
  organizationId,
  propertyId,
  promptToaster,
  refresh,
}: EditSetAsideHookArgs) => {
  const [isLoading, setIsLoading] = useState(false);
  const [setAsideProgram, setSetAsideProgram] = useState(null);

  const editSetAsideProgram = (setAside: Object) => {
    setSetAsideProgram(setAside);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const editSetAside = async (setAside: Object) => {
      setIsLoading(true);
      try {
        if (!propertyId || !organizationId || !setAside || !setAside.id) {
          throw new Error('Could not edit Set Aside Program');
        }

        const affordableService = new AffordableService();
        await affordableService.modifySetAsideProgram(
          organizationId,
          propertyId,
          setAside,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.editSetAsideProgramSuccessTitle),
          message: intl.formatMessage(
            messages.editSetAsideProgramSuccessMessage,
          ),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.editSetAsideProgramError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        refresh();
      }
    };

    if (!isLoading && setAsideProgram) {
      editSetAside(setAsideProgram);
    }

    return () => {
      setSetAsideProgram(null);
    };
  }, [
    organizationId,
    propertyId,
    promptToaster,
    intl,
    isLoading,
    refresh,
    setAsideProgram,
  ]);

  return editSetAsideProgram;
};

export const useAddSetAsideIncomeLimits = ({
  intl,
  organizationId,
  propertyId,
  promptToaster,
  refresh,
}: EditSetAsideHookArgs) => {
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState(null);

  const addSetAsideProgramLimits = (newPayload: Object) => {
    setPayload(newPayload);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const addSetAsideLimits = async (newPayload: Object) => {
      setIsLoading(true);
      try {
        if (!propertyId || !organizationId || !newPayload) {
          throw new Error('Could not add Set Aside Income Limits');
        }

        const newPayloadWithoutId = omit(['duplicateFromId'], newPayload);

        const affordableService = new AffordableService();
        await affordableService.addSetAsideIncomeLimits(
          organizationId,
          propertyId,
          newPayloadWithoutId,
          newPayload.duplicateFromId,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(
            messages.addSetAsideIncomeLimitsSuccessTitle,
          ),
          message: intl.formatMessage(
            messages.addSetAsideIncomeLimitsSuccessMessage,
          ),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.addSetAsideIncomeLimitsError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        refresh();
      }
    };

    if (!isLoading && payload) {
      addSetAsideLimits(payload);
    }

    return () => {
      setPayload(null);
    };
  }, [
    organizationId,
    propertyId,
    promptToaster,
    intl,
    isLoading,
    refresh,
    payload,
  ]);

  return addSetAsideProgramLimits;
};
