import { put, takeLatest, select } from 'redux-saga/effects';
import { GET_ALL_COMMUNICATIONS, GET_ONE_COMMUNICATION } from './constants';
import {
  getAllCommunicationsFailed,
  getAllCommunicationsSuccess,
  getOneCommunicationSuccess,
  getOneCommunicationError,
} from './actions';
import type { Action, FilterValueAndPagination } from '../App/types';
import { isNil } from 'ramda';

import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import ViewCommunicationService from '../../services/communicationService';
import { renderNoDataToastr } from '../../utils/redux-form-helper';
import type { Saga } from 'redux-saga';

export function* fetchGetAllCommunicationsSaga({
  payload,
}: Action<FilterValueAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const communicationService = new ViewCommunicationService();
    const response = yield communicationService.getAllCommunications(
      organizationId,
      property.id,
      payload.sorting,
      payload.filterValue,
    );
    if (response.results.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getAllCommunicationsSuccess(response.results, response.meta));
  } catch (err) {
    yield put(getAllCommunicationsFailed(err));
  }
}

export function* getAllCommunciationsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_COMMUNICATIONS, fetchGetAllCommunicationsSaga);
}

export function* fetchGetOneCommunicationSaga({ payload }: Object): Saga<void> {
  const { headerId, status } = payload;
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const communicationService = new ViewCommunicationService();
    const response = yield communicationService.getOneCommunication(
      organizationId,
      property.id,
      headerId,
    );
    if (isNil(response)) {
      yield put(renderNoDataToastr());
    }
    yield put(getOneCommunicationSuccess({ ...response, status }));
  } catch (err) {
    yield put(getOneCommunicationError(err));
  }
}

export function* getOneCommunciationSaga(): Saga<void> {
  yield takeLatest(GET_ONE_COMMUNICATION, fetchGetOneCommunicationSaga);
}

export default [getAllCommunciationsSaga, getOneCommunciationSaga];
