import { defineMessages } from 'react-intl';

const messages = defineMessages({
  successHeader: {
    id: 'App.PrimaryLeaseApplication.SuccessHeader',
    defaultMessage: 'Success'
  },
  progressSuccessDescription: {
    id: 'App.PrimaryLeaseApplication.ProgressSuccessDescription',
    defaultMessage: 'Lease application saved successfully.'
  },
  completionSuccessDescription: {
    id: 'App.PrimaryLeaseApplication.CompletionSuccessDescription',
    defaultMessage: 'Lease application completed successfully.'
  },
  errorHeader: {
    id: 'App.PrimaryLeaseApplication.errorHeader',
    defaultMessage: 'Error'
  },
  confirmDeleteMinors: {
    id: 'App.PrimaryLeaseApplication.ConfirmDeleteMinors',
    defaultMessage: "Are you sure you want to delete the applicant's minor(s)?"
  },
  confirmDeletePets: {
    id: 'App.PrimaryLeaseApplication.ConfirmDeletePets',
    defaultMessage: "Are you sure you want to delete the applicant's pet(s)?"
  }
});

export default messages;
