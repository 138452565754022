import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import {
  Tooltip,
  Stack,
  Box,
  FormLabel,
  StatusIndicator,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Table } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { Accordion } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  Status,
  Button,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import Spinner from '../Spinner';
import messages from './messages';
import { DEFAULT, DISABLED_SUBMIT_SCREENING } from './constants';

const SubmitButton = (props) => (
  <Button {...props} variant="shout" disableElevation onClick={props.onSubmit}>
    Submit Screening
  </Button>
);

const Loading = () => (
  <div style={{ width: '130px', margin: '0 auto' }}>
    <Spinner />
  </div>
);

const PaystubButtonWrapper = (props) => {
  const {
    paystubDetails,
    onSubmit,
    disabled,
    isLoading,
    onDownload,
    onRequestPaystubPdf,
    jobTitle,
  } = props;
  const hasPaystubDetails = (paystubDetails?.length ?? 0) > 0;
  return isLoading ? (
    <Spinner small={true} />
  ) : hasPaystubDetails ? (
    <Button
      variant="primary"
      disableElevation={true}
      onClick={() => {
        const documentId = paystubDetails?.[0]?.documentId ?? '';

        if (documentId) {
          const fileName = `${jobTitle}_paystub_details.pdf`;
          onDownload(documentId, fileName);
        } else {
          const { id: paystubDetailsId } = paystubDetails?.[0];
          onRequestPaystubPdf(paystubDetailsId, jobTitle);
        }
      }}
      sx={{ minWidth: '24px' }}
    >
      <i className="icon et-download" />
    </Button>
  ) : (
    <Button
      variant="primary"
      disableElevation={true}
      onClick={onSubmit}
      disabled={disabled}
    >
      Get Paystub Details
    </Button>
  );
};

export const getRightPartAccordion = ({
  status: statusType,
  applicantId,
  onSubmit,
  screeningState = DEFAULT,
}) => {
  const screeningStateToControlMap = {
    Loading: <Loading />,
    SubmitScreening: <SubmitButton onSubmit={() => onSubmit(applicantId)} />,
    DisabledSubmitScreening: <SubmitButton disabled />,
    Submitted: <Status statusType={statusType} />,
    Default: null,
  };
  return (
    screeningStateToControlMap[screeningState] ??
    screeningStateToControlMap.DisabledSubmitScreening
  );
};

export const screeningStateToSummary = (
  screeningState: string,
  name: string,
  isError: boolean,
  twnStatus: string,
) => {
  const errorMessage = isError ? (
    <FormattedMessage {...messages.missingEmployee} />
  ) : (
    <FormattedMessage {...messages.missingSsnArn} />
  );
  const map = Object.freeze({
    [DISABLED_SUBMIT_SCREENING]: (
      <Stack direction="row" alignItems="baseline" spacing={2}>
        <Box flexBasis="150px">{name}</Box>
        <FormLabel component={'small'} error sx={{ fontSize: 12 }}>
          {errorMessage}
        </FormLabel>
      </Stack>
    ),
    [DEFAULT]: name,
  });
  return map[screeningState] ?? map[DEFAULT];
};

export const generateSummary = (
  { name, status, screeningState, applicantId, twnStatus, isError },
  onSubmit,
) => ({
  left: screeningStateToSummary(screeningState, name, isError, twnStatus),
  right: getRightPartAccordion({
    status,
    applicantId,
    onSubmit,
    screeningState,
  }),
});

export const getDateRangeString = (startDate: string, endDate: string) => {
  const startString = getFormattedDateString(startDate);
  const endString = getFormattedDateString(endDate, 'Present');
  return `${startString} - ${endString}`;
};

export const getFormattedDateString = (
  rawDateString: string,
  stringIfInvalid = '---',
): string => {
  const formatString = 'MM/DD/YYYY';
  const date = rawDateString ? moment(rawDateString) : null;

  return date?.format(formatString) ?? stringIfInvalid;
};

export const getHeaderRows = () => [
  [
    <FormattedMessage
      key={messages.employmentStatus.id}
      {...messages.employmentStatus}
    />,
    <FormattedMessage
      key={messages.employersName.id}
      {...messages.employersName}
    />,
    <FormattedMessage
      key={messages.datesEmployed.id}
      {...messages.datesEmployed}
    />,
    <FormattedMessage
      key={messages.lastDateOfPay.id}
      {...messages.lastDateOfPay}
    />,
    <FormattedMessage
      key={messages.paystubDetails.id}
      {...messages.paystubDetails}
    />,
  ],
];

export const getTooltipProps = (
  isEmployeeActive: boolean,
  applicantId: string,
) => {
  const message = isEmployeeActive ? 'activeRecord' : 'inactiveRecord';
  const title = <FormattedMessage {...messages[message]} />;
  return {
    title,
    arrow: true,
    placement: 'right',
    key: applicantId,
  };
};

export const getStatusCircleProps = (isEmployeeActive) => {
  const status = isEmployeeActive ? 'SUCCESS' : 'DISABLED';
  const size = 'small';
  return {
    status,
    size,
    color: 'black',
  };
};

export const getEmploymentStatusComponent = (isEmployeeActive, applicantId) => {
  const tooltipProps = getTooltipProps(isEmployeeActive, applicantId);
  const statusCircleProps = getStatusCircleProps(isEmployeeActive);

  const ForwardRefStatusCircle = React.forwardRef((props, ref) => (
    <div {...props} ref={ref}>
      <StatusIndicator {...props} />
    </div>
  ));

  return (
    <Tooltip {...tooltipProps}>
      <ForwardRefStatusCircle {...statusCircleProps} />
    </Tooltip>
  );
};

export const generateDetails = (
  { screeningDetails },
  onSubmit,
  onDownload,
  onRequestPaystubPdf,
) => {
  const rows =
    screeningDetails?.map((rawData, index) => {
      const {
        id,
        applicantId,
        employerName,
        startDate,
        terminationDate,
        mostRecentPayDate,
        isEmployeeActive,
        isLoading,
        isDisabled,
        paystubs,
        jobTitle,
      } = rawData;
      const employmentPeriod = getDateRangeString(startDate, terminationDate);
      const lastDateOfPay = getFormattedDateString(mostRecentPayDate);
      const employmentStatus = getEmploymentStatusComponent(
        isEmployeeActive,
        applicantId,
      );

      return [
        employmentStatus,
        employerName,
        employmentPeriod,
        lastDateOfPay,
        <PaystubButtonWrapper
          key={id}
          applicantId={applicantId}
          employmentRecordId={id}
          isLoading={isLoading}
          paystubDetails={paystubs}
          onSubmit={() => {
            onSubmit(id, applicantId);
          }}
          onRequestPaystubPdf={onRequestPaystubPdf}
          onDownload={onDownload}
          disabled={isDisabled}
          jobTitle={jobTitle}
        />,
      ];
    }) ?? [];
  const table = {
    headerRows: getHeaderRows(),
    dataRows: rows,
  };
  return {
    content: <Table {...table} />,
  };
};

const AccordionContainer = (props) => {
  const {
    accordions,
    onEmployerHistorySubmit,
    onPaystubSubmit,
    onDownloadPaystubPDF,
    onRequestPaystubPdf,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div>
      {accordions.map((accordion, index) => {
        const augmentedAccordion = {
          ...accordion,
        };
        const accordionKey = `accordion${index}`;
        return (
          <Accordion
            expanded={expanded === accordionKey}
            key={accordionKey}
            expandable={augmentedAccordion?.expandable ?? true}
            summary={generateSummary(
              augmentedAccordion,
              onEmployerHistorySubmit,
            )}
            details={generateDetails(
              augmentedAccordion,
              onPaystubSubmit,
              onDownloadPaystubPDF,
              onRequestPaystubPdf,
            )}
            onChange={handleChange(accordionKey)}
          />
        );
      })}
    </div>
  );
};

export default AccordionContainer;
