import * as R from 'ramda';
import messages from './messages';
import {
  validateLeaseEffectiveDate,
  validateCommencementDate,
  validateLeaseEndDate,
  validateRentStartDate,
  validateCommencementNotOverlap,
} from './common-validations';

// $FlowFixMe
const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty]);

const requiredFields = ['commencementDate', 'leaseEndDate', 'rentStartDate'];

const validateInitialLease = (
  values,
  { errors, intl, isRenewal, priorLease }: Object,
) => {
  const context = { values, errors, intl, isRenewal, priorLease };
  return [
    validateLeaseEffectiveDate(values.leaseEffectiveDate, context),
    validateCommencementDate(values.commencementDate, context),
    validateLeaseEndDate(values.leaseEndDate, context),
    validateRentStartDate(values.rentStartDate, context),
  ].reduce(
    (errors, validationResult) => ({ ...errors, ...validationResult }),
    errors,
  );
};

const validateRenewalLease = (
  values: Object,
  { intl, isRenewal, priorLease }: Object,
) => {
  const errors: Object = requiredFields.reduce(
    (errors, field) =>
      isNilOrEmpty(values[field])
        ? { ...errors, [field]: intl.formatMessage(messages.required) }
        : errors,
    {},
  );
  const context = { values, errors, intl, priorLease, isRenewal: true };

  return [
    validateLeaseEffectiveDate(values.leaseEffectiveDate, context),
    validateCommencementDate(values.commencementDate, context),
    validateLeaseEndDate(values.leaseEndDate, context),
    validateRentStartDate(values.rentStartDate, context),
    validateCommencementNotOverlap(values.commencementDate, context),
  ].reduce(
    (errors, validationResult) => ({ ...errors, ...validationResult }),
    errors,
  );
};

function validate(
  values: Object,
  { intl, isRenewal, priorLease, ...props }: Object,
) {
  const errors: Object = requiredFields.reduce(
    (errors, field) =>
      isNilOrEmpty(values[field])
        ? { ...errors, [field]: intl.formatMessage(messages.required) }
        : errors,
    {},
  );
  const context = { values, errors, intl, isRenewal, priorLease };
  if (isRenewal) {
    return validateRenewalLease(values, context);
  } else {
    return validateInitialLease(values, context);
  }
}

export default validate;
