import { defineMessages } from 'react-intl';

const messages = defineMessages({
  successHeader: {
    id: 'App.SecondaryLeaseApplication.SuccessHeader',
    defaultMessage: 'Success'
  },
  progressSuccessDescription: {
    id: 'App.SecondaryLeaseApplication.ProgressSuccessDescription',
    defaultMessage: 'Lease application saved successfully.'
  },
  completionSuccessDescription: {
    id: 'App.SecondaryLeaseApplication.CompletionSuccessDescription',
    defaultMessage: 'Lease application completed successfully.'
  },
  errorHeader: {
    id: 'App.SecondaryLeaseApplication.errorHeader',
    defaultMessage: 'Error'
  }
});

export default messages;
