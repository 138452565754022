import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import messages from './messages';
import { createConfirmation, confirmable } from 'react-confirm';
import { ASSET_PROTECT, NONE } from '../constants';
import { InsuranceFeeInput } from '../InsuranceFeeInput';

type Props = {
  show: boolean,
  dismiss: Function,
  proceed: Function,
  intl: Object,
  insuranceType: string,
};

const NoneSelectedBody = (props: Props) => (
  <Col xs={12} className="text-center">
    <h2>{props.intl.formatMessage(messages.noneSelectedHeader)}</h2>
    <br />
    <strong>{props.intl.formatMessage(messages.noneSelectedBodyLine1)}</strong>
    <br />
    {props.intl.formatMessage(messages.noneSelectedBodyLine2)}
    <br />
    {props.intl.formatMessage(messages.noneSelectedBodyLine3)}
  </Col>
);

const AssetProtectSelectedBody = (props: Props) => {
  const { state, setState } = props;

  useEffect(() => {
    setState({ insuranceFee: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      disableConfirm: state.insuranceFee < 10,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.insuranceFee]);

  return (
    <>
      <Col xs={12} className="text-center">
        <h2>{props.intl.formatMessage(messages.assetProtectHeader)}</h2>
        <br />
        {props.intl.formatMessage(messages.assetProtectBodyPart1)}{' '}
        <strong>
          {props.intl.formatMessage(messages.assetProtectBodyPart2)}
        </strong>{' '}
        {props.intl.formatMessage(messages.assetProtectBodyPart3)}
      </Col>
      <Col xs={2} />
      <Col xs={8} style={{ marginTop: 20 }}>
        <strong>{props.intl.formatMessage(messages.assetProtectFee)}</strong>
        <InsuranceFeeInput
          insuranceFeeAmount={state.insuranceFee}
          onChange={(event) => {
            setState({ ...state, insuranceFee: event.target.value });
          }}
          intl={props.intl}
        />
      </Col>
    </>
  );
};

function InsuranceModal(props: Props) {
  const [state, setState] = useState({});

  return (
    <Modal
      bsSize="small"
      dialogClassName="modal-dialog modal-alert"
      show={props.show}
      onHide={props.dismiss}
      autoFocus={true}
    >
      <Modal.Body>
        <Row>
          {props.insuranceType === NONE && <NoneSelectedBody {...props} />}
          {props.insuranceType === ASSET_PROTECT && (
            <AssetProtectSelectedBody
              {...props}
              state={state}
              setState={setState}
            />
          )}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button type="button" bsStyle="default" onClick={props.dismiss}>
            {props.intl.formatMessage(messages.cancel)}
          </Button>
          <Button
            type="button"
            bsStyle="primary"
            onClick={() => props.proceed(state)}
            disabled={state.disableConfirm}
          >
            {props.intl.formatMessage(messages.confirm)}
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
}

const confirm = createConfirmation(confirmable(InsuranceModal));

export function confirmInsuranceModal(intl, insuranceType) {
  return confirm({ intl, insuranceType });
}
