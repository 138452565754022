import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import classNames from 'classnames';
import {
  renderDateField,
  renderSelectField,
  renderTextField,
  extractCurrentDateFormat,
} from '../../../utils/redux-form-helper';

import type { SelectOption } from '../../../containers/App/types';

import AffordableIdentificationRow from './AffordableIdentificationRow.js';
import IdentificationRow from './IdentificationRow.js';

import messages from './messages';

type Props = {
  countries: Array<SelectOption>,
  suffixes: Array<SelectOption>,
  states: Array<SelectOption>,
  intl: Object,
  required?: boolean,
  isAffordable: ?boolean,
};

const BasicInformationSection = ({
  countries,
  suffixes,
  states,
  intl,
  required,
  isAffordable,
}: Props) => (
  <div className="form-content">
    <h3>{`${intl.formatMessage(messages.title)}`}</h3>
    <Row>
      <Col xs={12} md={3}>
        <Field
          name="firstName"
          component={renderTextField}
          className="input-lg"
          label={intl.formatMessage(messages.firstNameLabel)}
          placeholder={intl.formatMessage(messages.firstNameLabel)}
          showLabel
          classes={classNames({ required })}
        />
      </Col>
      <Col xs={12} md={3}>
        <Field
          name="middleName"
          component={renderTextField}
          className="input-lg"
          label={intl.formatMessage(messages.middleNameLabel)}
          placeholder={intl.formatMessage(messages.middleNameLabel)}
          showLabel
        />
      </Col>
      <Col xs={12} md={3}>
        <Field
          name="lastName"
          component={renderTextField}
          className="input-lg"
          label={intl.formatMessage(messages.lastNameLabel)}
          placeholder={intl.formatMessage(messages.lastNameLabel)}
          showLabel
          classes={classNames({ required })}
        />
      </Col>
      <Col xs={4} md={1}>
        <Field
          name="suffix"
          component={renderSelectField}
          options={suffixes}
          label="Suffix"
          bsSize="lg"
        />
      </Col>
      <Col xs={8} md={2}>
        <Field
          name="preferredName"
          component={renderTextField}
          className="input-lg"
          label={intl.formatMessage(messages.preferredNameLabel)}
          placeholder={intl.formatMessage(messages.preferredNameLabel)}
          showLabel
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={6}>
        <Field
          name="birthday"
          component={renderDateField}
          bsSize="lg"
          label={intl.formatMessage(messages.birthDateLabel)}
          placeholder={extractCurrentDateFormat(intl)}
          dateFormat={extractCurrentDateFormat(intl)}
          classes={classNames({ required })}
        />
      </Col>
      <Col xs={12} md={3}>
        <Field
          name="stateIdNumber"
          component={renderTextField}
          className="input-lg"
          label={`${intl.formatMessage(messages.stateIdNumberLabel)}**`}
          placeholder={intl.formatMessage(messages.stateIdNumberLabel)}
          showLabel
          maxLength="20"
        />
      </Col>
      <Col xs={12} md={3}>
        <Field
          name="stateIdOrigin"
          component={renderSelectField}
          options={states}
          label={intl.formatMessage(messages.stateIdIssuerLabel)}
          bsSize="lg"
        />
      </Col>
    </Row>
    {!isAffordable ? (
      <IdentificationRow
        intl={intl}
        countries={countries}
        isAffordable={isAffordable}
      />
    ) : (
      <AffordableIdentificationRow
        intl={intl}
        countries={countries}
        isAffordable={isAffordable}
      />
    )}
    <Row>
      {!isAffordable ? (
        <Col xs={12}>
          <p className="form-footernote">
            {intl.formatMessage(messages.requiredFieldsHint)}
          </p>
        </Col>
      ) : (
        <Col xs={12}>
          <p className="form-footernote">
            {intl.formatMessage(messages.requiredFieldsAffordableHint)}
          </p>
        </Col>
      )}
    </Row>
  </div>
);

export default BasicInformationSection;
