import React, { useState, useEffect, useMemo } from 'react';
import messages from './messages';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { propEq, isNil } from 'ramda';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import CreateTransactionBatchHeader from './CreateTransactionBatchHeader';
import CreateTransactionBatchDetails from './CreateTransactionBatchDetails';
import ConfirmDeleteBatchModal from './ConfirmDeleteBatchModal';
import { promptToaster } from '../../containers/App/actions';
import {
  useFetchPropertyTransactionCodesForProperty,
  useFetchSubjournalsForProperty,
  useFetchOpenFiscalPeriodForProperty,
} from '../App/hooks';
import {
  useFetchResidents,
  useGetTransactionTypes,
  useSaveTransactionBatch,
  useFetchTransactionBatch,
  usePostTransactionBatch,
  useDeleteTransactionBatch,
} from './hooks';
import { TRANSACTION_BATCH_STATUSES } from './CreateTransactionBatchDetails/constants';
import { getCustomerUnits } from './CreateTransactionBatchDetails/utils';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type Props = {
  history: Object,
  intl: any,
  propertyId: string,
  organizationId: string,
  transactionBatchHeaderId: string,
  actions: Object,
  selectedResidentsAddGroup: Object,
  isAllCommercial: boolean,
};

export const CreateTransactionBatch = (props: Props) => {
  const {
    intl,
    history,
    propertyId,
    organizationId,
    actions,
    transactionBatchHeaderId,
    selectedResidentsAddGroup,
    isAllCommercial,
  } = props;
  const [showConfirmDeleteBatchModal, setShowConfirmDeleteBatchModal] =
    useState(false);

  const [transactionBatch, isTransactionBatchLoading] =
    useFetchTransactionBatch({
      propertyId,
      organizationId,
      transactionBatchHeaderId,
      promptToaster: actions.promptToaster,
    });
  const batchId = transactionBatch?.header?.name ?? null;
  const [transactionTypes, isTranscationTypeLoading] = useGetTransactionTypes();
  const [fiscalPeriod, isFiscalPeriodLoading] =
    useFetchOpenFiscalPeriodForProperty({
      propertyId,
      organizationId,
    });
  const period = fiscalPeriod?.period ?? null;
  const fiscalPeriodStartDate = fiscalPeriod?.startDate ?? null;
  const [subjournals, isSubjournalsLoading] = useFetchSubjournalsForProperty({
    propertyId,
    organizationId,
  });
  const [propertyTransactionCodes, isPropertyTransactionCodesLoading] =
    useFetchPropertyTransactionCodesForProperty({
      propertyId,
      organizationId,
    });
  const [currentResidents, isCurrentResidentsLoading] = useFetchResidents({
    propertyId,
    organizationId,
    promptToaster: actions.promptToaster,
  });

  const returnToManageTxBatches = (isAddGroup, results) => {
    if (!isAddGroup) {
      navigateToUrlWithSelectedPropertyId('/manage-transaction-batches');
    } else {
      const headerId = results?.header?.id;
      navigateToUrlWithSelectedPropertyId(`/add-group/${headerId || ''}`);
    }
  };

  const onTxBatchPosted = () => {
    navigateToUrlWithSelectedPropertyId('/manage-transaction-batches');
  };

  const [saveTxBatch, isTxBatchSaving] = useSaveTransactionBatch({
    propertyId,
    organizationId,
    promptToaster: actions.promptToaster,
    onComplete: returnToManageTxBatches,
  });

  const [deleteTxBatch, isTxBatchDeleting] = useDeleteTransactionBatch({
    propertyId,
    organizationId,
    transactionBatchHeaderId,
    promptToaster: actions.promptToaster,
    onComplete: returnToManageTxBatches,
  });

  const [postTxBatch, isTxBatchPosting] = usePostTransactionBatch({
    propertyId,
    organizationId,
    promptToaster: actions.promptToaster,
    onComplete: onTxBatchPosted,
  });

  const onSaveAndClose = (data: any, isAddGroup: boolean) => {
    return saveTxBatch(data, isAddGroup);
  };

  const onPost = (data: any) => {
    return postTxBatch({
      transactionBatchHeaderId:
        transactionBatchHeaderId ?? data?.transactionBatchHeaderId,
    });
  };

  const isLoading =
    isFiscalPeriodLoading ||
    isSubjournalsLoading ||
    isPropertyTransactionCodesLoading ||
    isCurrentResidentsLoading ||
    isTranscationTypeLoading ||
    isTxBatchSaving ||
    isTransactionBatchLoading ||
    isTxBatchPosting ||
    isTxBatchDeleting;

  const batchDate = transactionBatch?.header?.batchDate;
  const initialHeaderValues = {
    batchDate: moment.utc(batchDate),
    batchNote: transactionBatch?.header?.batchNote ?? '',
  };

  const [initialGridValues, setInitialGridValues] = useState({});
  useEffect(() => {
    const initialDetailsValues = () => {
      try {
        const transactionDetails = transactionBatch?.transactions ?? null;
        const rows =
          transactionDetails &&
          transactionDetails.reduce((acc, td) => {
            const subjournal = subjournals.find(
              propEq('masterSubjournalId', td?.ptc?.tc?.subjournalId),
            );
            acc[`inline${td.id}`] = {
              id: td.id,
              transactionType: td?.ptc?.tc?.tt?.id,
              subjournal: subjournal?.id,
              transactionCode: td?.propertyTransactionCodeId,
              amount: td?.amount,
              customer: td.customerId,
            };
            return acc;
          }, {});
        return rows;
      } catch (err) {}
    };
    const getSelectedRows = () => {
      const selected =
        selectedResidentsAddGroup &&
        selectedResidentsAddGroup?.selectedResidents.reduce(
          (acc, row) => {
            acc.rows[`inlineunsaved_row-${acc.count}`] = {
              id: `unsaved_row-${acc.count}`,
              transactionType:
                selectedResidentsAddGroup?.addGroupHeaderFormValues
                  ?.transactionType,
              subjournal:
                selectedResidentsAddGroup?.addGroupHeaderFormValues?.subjournal,
              transactionCode:
                selectedResidentsAddGroup?.addGroupHeaderFormValues
                  ?.transactionCode,
              amount:
                selectedResidentsAddGroup?.addGroupHeaderFormValues?.amount,
              customer: row.customerId,
            };
            acc.count++;
            return acc;
          },
          { count: 0, rows: {} },
        );
      return selected?.rows;
    };
    const rows = initialDetailsValues();
    const addGrid = getSelectedRows();
    setInitialGridValues({ ...rows, ...addGrid });
  }, [selectedResidentsAddGroup, transactionBatch, subjournals]);

  const batchStatus =
    transactionBatch?.header?.batchStatus === TRANSACTION_BATCH_STATUSES.SAVED
      ? TRANSACTION_BATCH_STATUSES.UNPOSTED
      : transactionBatch?.header?.batchStatus;

  const showDeleteBtn = !isNil(transactionBatchHeaderId);
  const isBatchEditable = [
    TRANSACTION_BATCH_STATUSES.SAVED,
    TRANSACTION_BATCH_STATUSES.UNPOSTED,
  ].includes(batchStatus);
  const enableDeleteBtn = showDeleteBtn && isBatchEditable;

  const [deletedTxRowsIds, setDeletedTxRowsIds] = useState([]);
  const customerUnits = useMemo(
    () => getCustomerUnits(currentResidents),
    [currentResidents],
  );

  const onSaveAndCloseClicked = (
    headerFormValues,
    formValues,
    isAddGroup = false,
  ) => {
    const formValuesKeys = Object.keys(formValues || {}) || [];
    const transactions = formValuesKeys.reduce((acc, key) => {
      const id = key.startsWith('inlineunsaved_row')
        ? undefined
        : key.replace('inline', ''); // No id if new row, otherwise keep same ID
      const customerId = formValues?.[key]?.customer || null;
      if (!customerId) {
        return acc;
      }
      const unitId = customerUnits?.[customerId];
      if (!customerId) {
        return acc;
      }
      return [
        ...acc,
        {
          id,
          customerId,
          unitId,
          propertyTransactionCodeId: formValues?.[key]?.transactionCode || null,
          amount: +formValues?.[key]?.amount || null,
        },
      ];
    }, []);
    const header = {
      id: transactionBatchHeaderId,
      batchDate: headerFormValues.batchDate,
      batchNote: headerFormValues.batchNote,
    };
    const data = {
      header,
      transactions,
      deletedTxRowsIds,
    };
    return onSaveAndClose(data, isAddGroup);
  };

  return (
    <React.Fragment>
      {showConfirmDeleteBatchModal && (
        <ConfirmDeleteBatchModal
          onHide={() => setShowConfirmDeleteBatchModal(false)}
          onConfirm={() => {
            deleteTxBatch();
          }}
        />
      )}
      <DocumentTitle
        title={intl.formatMessage(messages.createTransactionBatch)}
      >
        <div className="bodywrap" data-test="wrap">
          <CreateTransactionBatchHeader
            batchId={batchId}
            period={period}
            history={history}
            onSubmit={(value) => {}}
            isLoading={isLoading}
            initialValues={initialHeaderValues}
            batchStatus={batchStatus}
            fiscalPeriodStartDate={fiscalPeriodStartDate}
            intl={intl}
            transactionBatchHeaderId={transactionBatchHeaderId}
            isAllCommercial={isAllCommercial}
            onSaveAndCloseClicked={onSaveAndCloseClicked}
            isAddGroupActive={
              selectedResidentsAddGroup?.selectedResidents?.length ?? 0
            }
            selectedPropertyId={propertyId}
          />
          <CreateTransactionBatchDetails
            intl={intl}
            promptToaster={actions.promptToaster}
            selectedPropertyId={propertyId}
            selectedOrganizationId={organizationId}
            subjournals={subjournals}
            propertyTransactionCodes={propertyTransactionCodes}
            currentResidents={currentResidents}
            transactionTypes={transactionTypes}
            isLoading={isLoading}
            onDelete={() => {
              setShowConfirmDeleteBatchModal(true);
            }}
            initialValues={initialGridValues}
            transactionBatch={transactionBatch}
            enableDeleteBtn={enableDeleteBtn}
            showDeleteBtn={showDeleteBtn}
            onPost={onPost}
            transactionBatchHeaderId={transactionBatchHeaderId}
            isBatchEditable={isBatchEditable}
            isAllCommercial={isAllCommercial}
            deletedTxRowsIds={deletedTxRowsIds}
            setDeletedTxRowsIds={setDeletedTxRowsIds}
            onSaveAndCloseClicked={onSaveAndCloseClicked}
          />
        </div>
      </DocumentTitle>
    </React.Fragment>
  );
};
const InjectedCreateTransactionBatches = injectIntl(CreateTransactionBatch);
const mapDispatchToProps = (dispatch: any): Object => {
  const actions = bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  );
  return { actions };
};

export const mapStateToProps = (state: GlobalState, ownProps: Object): any => {
  const {
    app: { currentUser, selectedProperty },
  } = state;
  return {
    organizationId: currentUser.user.organizationId,
    propertyId: selectedProperty.id,
    transactionBatchHeaderId: ownProps.match.params.transactionBatchHeaderId,
    selectedResidentsAddGroup: ownProps.location.state,
    isAllCommercial: selectedProperty?.hasCommercialFloorPlans === 'ALL',
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedCreateTransactionBatches);
