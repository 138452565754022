import { defineMessages } from 'react-intl';

const messages = defineMessages({
  affordableTransferBanner: {
    id: 'App.ManageUnitAvailability.AffordableTransferBanner',
    defaultMessage:
      // eslint-disable-next-line max-len
      'This project has more than one contract. You are only able to transfer from within the same contract, unless you perform a termination cert from the prior contract. Cancel the open Transfer on the Lease Data tab to enable the Start Certification button.',
  },
});

export default messages;
