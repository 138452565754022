import { useState, ChangeEvent } from 'react';
import moment from 'moment/moment';
import {
  Divider,
  SizedBox,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import messages from './messages';
import { Pagination } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';

const DATE_FORMAT = 'MM/DD/YYYY';
const tableGridSx = {
  maxHeight: 300,
  overflowY: 'auto',
  maxWidth: '100%',
  overflowX: 'auto',
};

const pageSize = 10;

const ResidentHistoryTable = ({ rows = [], onResidentClick, intl }) => {
  const formatMessage = intl?.formatMessage;
  const [page, setPage] = useState(1);
  if (rows.length === 0) {
    return (
      <Typography textAlign="center">
        {formatMessage?.(messages.emptyStateMessage)}
      </Typography>
    );
  }
  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };
  const paginationProps = {
    count: pageSize,
    currentPage: page,
    intl,
    onPageChange: handleChange,
    pageCount: Math.ceil(rows.length / pageSize),
    perPage: pageSize,
    totalCount: rows.length,
  };

  const pageRows = rows.slice(
    (paginationProps.currentPage - 1) * paginationProps.perPage,
    paginationProps.currentPage * paginationProps.perPage,
  );

  return (
    <Grid container role="table" aria-label="Unit History Table">
      <Grid
        container
        paddingX={2}
        role="row"
        aria-label="Unit History Table Header Row"
      >
        <Grid item xs={4} role="cell">
          <Typography variant="h4">
            {formatMessage?.(messages.priorResident)}
          </Typography>
        </Grid>
        <Grid item xs={4} role="cell">
          <Typography variant="h4">
            {formatMessage?.(messages.moveInDate)}
          </Typography>
        </Grid>
        <Grid item xs={4} role="cell">
          <Typography variant="h4">
            {formatMessage?.(messages.moveOutDate)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SizedBox h={5} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        container
        role="rowgroup"
        aria-label="Unit History Table Rows"
        marginTop={1}
        paddingX={2}
        sx={tableGridSx}
      >
        {pageRows.map((rowData, i) => (
          <Grid container key={i} role="row">
            <Grid item xs={12}>
              <SizedBox h={5} />
            </Grid>
            <Grid container alignItems="center">
              <Grid item xs={4} role="cell">
                <Typography
                  variant="tableLink"
                  fontSize={14}
                  role="button"
                  onClick={() => {
                    onResidentClick?.(rowData.residentId);
                  }}
                >
                  {rowData.residentName}
                </Typography>
              </Grid>
              <Grid item xs={4} role="cell">
                <Typography>
                  {' '}
                  {moment(rowData.moveInDate).format(DATE_FORMAT)}
                </Typography>
              </Grid>
              <Grid item xs={4} role="cell">
                <Typography>
                  {moment(rowData.moveOutDate).format(DATE_FORMAT)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SizedBox h={5} />
            </Grid>
          </Grid>
        ))}
      </Grid>
      {/* {Only show if pagination is needed} */}
      {rows.length > pageSize && (
        <Grid item xs={12}>
          <Pagination {...paginationProps}></Pagination>
        </Grid>
      )}
    </Grid>
  );
};

export default ResidentHistoryTable;
