import type { Action } from '../App/types';
import {
  GET_ALL_SUBJOURNALS,
  GET_ALL_SUBJOURNALS_SUCCESS,
  GET_ALL_SUBJOURNALS_ERROR,
  GET_CUSTOMER_LEDGER_HEADER,
  GET_CUSTOMER_LEDGER_HEADER_SUCCESS,
  GET_CUSTOMER_LEDGER_HEADER_ERROR,
  GET_CUSTOMER_OPS_TRANSACTIONS,
  GET_CUSTOMER_OPS_TRANSACTIONS_SUCCESS,
  GET_CUSTOMER_OPS_TRANSACTIONS_ERROR,
  GET_CUSTOMER_SECURITY_TRANSACTIONS,
  GET_CUSTOMER_SECURITY_TRANSACTIONS_SUCCESS,
  GET_CUSTOMER_SECURITY_TRANSACTIONS_ERROR,
  CLEAR_LOADED_LEDGER,
  REFRESH_LEDGER,
  LOCK_LEDGER_ACTIONS,
  FINANCIAL_TRANSACTION_SUCCESS,
} from './constants';

export const initialState = {
  headerData: {
    status: '---',
    period: '---',
    balance: '$0.00',
    currentCharges: '$0.00',
    deposits: {
      runningSecurityBalanceOwed: '$0.00',
      runningSecurityBalanceHeld: '$0.00',
    },
  },
  ledgerEditsDisabled: false,
  subjournals: [],
  ops: {
    transactions: [],
    meta: {
      count: 0,
      pageCount: 1,
      totalCount: 0,
      next: '',
      previous: '',
      self: '',
      first: '',
      last: '',
      subjournal: '',
    },
  },
  security: {
    transactions: [],
    meta: {
      count: 0,
      pageCount: 1,
      totalCount: 0,
      next: '',
      previous: '',
      self: '',
      first: '',
      last: '',
    },
  },
};

const ledgerReducer = (
  state: Object = initialState,
  action: Action<*, *>,
): Object => {
  const { type, payload, meta, error } = action;
  switch (type) {
    case GET_ALL_SUBJOURNALS_SUCCESS: {
      return { ...state, subjournals: payload };
    }
    case GET_ALL_SUBJOURNALS_ERROR: {
      return { ...state, subjournalsError: payload, error };
    }
    case GET_CUSTOMER_LEDGER_HEADER_SUCCESS: {
      return { ...state, headerData: payload };
    }
    case GET_CUSTOMER_LEDGER_HEADER_ERROR: {
      return { ...state, headerDataError: payload, error };
    }
    case GET_CUSTOMER_OPS_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        ops: {
          transactions: payload,
          meta: { ...meta, subjournal: meta && meta.subjournal },
        },
      };
    }
    case GET_CUSTOMER_OPS_TRANSACTIONS_ERROR: {
      return { ...state, opsError: payload, error };
    }
    case GET_CUSTOMER_SECURITY_TRANSACTIONS_SUCCESS: {
      return { ...state, security: { transactions: payload, meta } };
    }
    case GET_CUSTOMER_SECURITY_TRANSACTIONS_ERROR: {
      return { ...state, securityError: payload, error };
    }
    case CLEAR_LOADED_LEDGER: {
      return { ...initialState };
    }
    case GET_CUSTOMER_OPS_TRANSACTIONS: {
      return {
        ...state,
        ops: {
          ...state.ops,
          meta: { ...state.ops.meta, subjournal: meta && meta.subjournal },
        },
      };
    }
    case FINANCIAL_TRANSACTION_SUCCESS: {
      return {
        ...state,
        ops: {
          ...state.ops,
          meta: { ...initialState.ops.meta },
        },
        security: {
          ...state.security,
          meta: { ...initialState.security.meta },
        },
      };
    }
    case REFRESH_LEDGER: {
      return { ...state, ledgerEditsDisabled: false };
    }
    case LOCK_LEDGER_ACTIONS: {
      return { ...state, ledgerEditsDisabled: true };
    }
    case GET_CUSTOMER_SECURITY_TRANSACTIONS:
    case GET_ALL_SUBJOURNALS:
    case GET_CUSTOMER_LEDGER_HEADER:
    default:
      return state;
  }
};

export default ledgerReducer;
