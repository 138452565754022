import React from 'react';
// eslint-disable-next-line max-len
import { ProspectProfileFormPreferences } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useLookingFor } from './LookingFor.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Moment } from 'moment';

type DateValue = Moment | null | undefined;
type NumericInputValue = number | string;
type Props = {
  intl: any,
  reduxFormOnChange: (name: string, value: any) => void,
  floorPlans: Object[],
  prospectInfoEditMode?: boolean,
  initialValues: {
    leaseTermInMonths: string,
    moveInDateTo: DateValue,
    moveInDateFrom: DateValue,
    nBeds?: NumericInputValue,
    nBedsArr: '' | string[],
    nBaths: NumericInputValue,
    nHalfBaths: NumericInputValue,
    noMoveInDate?: boolean,
    notWants: string,
    preferredFloorPlanId: string,
    priceFrom?: NumericInputValue,
    priceTo?: NumericInputValue,
    wants: string,
  },
};

const LookingFor = ({
  intl,
  floorPlans,
  reduxFormOnChange,
  prospectInfoEditMode,
  initialValues,
}: Props) => {
  const { propertySettingApplyWithoutAUnit } = useFlags();
  const {
    formState,
    handleChange,
    leaseTermOptions,
    floorPlanOptions,
    nBedSelectOptions,
  } = useLookingFor({
    intl,
    initialValues,
    floorPlans,
    reduxFormOnChange,
  });

  return (
    <ProspectProfileFormPreferences
      intl={intl}
      containerSx={{
        margin: prospectInfoEditMode ? '0 8px 2rem 8px' : '0 -8px 2rem -8px',
      }}
      formState={formState}
      handleChange={handleChange}
      leaseTermOptions={leaseTermOptions}
      floorPlanOptions={floorPlanOptions}
      nBedSelectOptions={nBedSelectOptions}
      propertySettingApplyWithoutAUnit={propertySettingApplyWithoutAUnit}
      prospectInfoEditMode={prospectInfoEditMode}
    />
  );
};

LookingFor.defaultProps = {
  initialValues: {
    leaseTermInMonths: '',
    moveInDateTo: null,
    moveInDateFrom: null,
    nBaths: '',
    nBedsArr: [],
    nHalfBaths: '',
    noMoveInDate: false,
    notWants: '',
    preferredFloorPlanId: '',
    priceFrom: '',
    priceTo: '',
    wants: '',
  },
};

export default LookingFor;
