import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { toastr } from 'react-redux-toastr';
import useSelectedProperty from '../../../../hooks/useSelectedProperty';
import { get, post, patch, del } from '../../../../utils/api';
import messages from './messages';

export default function useCollectionsTrack({
  intl,
  householdId,
  fasGeneratedDate,
  balance,
}) {
  const [isProcessingAction, setIsProcessingAction] = useState(false);
  const selectedProperty = useSelectedProperty();
  const {
    propertyCollectionSettings,
    isLoading: isPropertyCollectionSettingsLoading,
  } = useEnabledCollectionSettings({ intl });
  const {
    householdCollection,
    refetchHouseholdCollection,
    isLoading: isHouseholdCollectionLoading,
  } = useHouseholdCollection({ householdId, intl });
  const { claim, isLoading: isClaimLoading } = useClaim({
    intl,
    collectionId: householdCollection?.id,
  });
  const onActivatePaymentPlan = useActivatePaymentPlan({
    householdId,
    selectedProperty,
    balance,
    refetchCollection: refetchHouseholdCollection,
    intl,
    setIsProcessingAction,
  });
  const onCloseAccount = useCloseAccount({
    selectedProperty,
    refetchCollection: refetchHouseholdCollection,
    intl,
    balance,
    collectionId: householdCollection?.id,
    householdId,
    setIsProcessingAction,
  });
  const onReOpenAccount = useReOpenAccount({
    intl,
    refetchCollection: refetchHouseholdCollection,
    balance,
    collectionId: householdCollection?.id,
    selectedProperty,
    hasClaim: !!claim,
    setIsProcessingAction,
  });
  const onRemovePaymentPlan = useRemovePaymentPlan({
    intl,
    refetchCollection: refetchHouseholdCollection,
    balance,
    collectionId: householdCollection?.id,
    selectedProperty,
    setIsProcessingAction,
  });
  const onSentToCollections = useSentToCollections({
    selectedProperty,
    householdId,
    balance,
    intl,
    refetchCollection: refetchHouseholdCollection,
    setIsProcessingAction,
  });
  const onRemoveSentToCollections = useRemoveSentToCollections({
    collectionId: householdCollection?.id,
    selectedProperty,
    balance,
    refetchCollection: refetchHouseholdCollection,
    intl,
    setIsProcessingAction,
  });

  const enabled =
    isPropertyCollectionSettingsLoading === false &&
    isHouseholdCollectionLoading === false &&
    isClaimLoading === false &&
    isProcessingAction === false;

  const uiProps = {
    intl,
    collectionsSettings: propertyCollectionSettings && {
      daysAfterFAS: propertyCollectionSettings.daysAfterFAS,
      minumumAmount: propertyCollectionSettings.minimumAmount,
    },
    integrationOff: !propertyCollectionSettings,
    status: householdCollection?.collectionsStatus ?? 'null',
    fasGeneratedDate,
    onActivatePaymentPlan,
    onCloseAccount,
    onReOpenAccount,
    onRemovePaymentPlan,
    claimCreatedDate: claim?.createdAt,
    accountClosedDate: householdCollection?.accountClosedDate,
    balanceDue: balance,
    onSentToCollections,
    onRemoveSentToCollections,
    enabled,
    isAutomatedClaim: claim
      ? claim.collectionAgencyName !== 'manual'
      : undefined,
  };

  return {
    uiProps,
  };
}

function useEnabledCollectionSettings({ intl }) {
  const selectedProperty = useSelectedProperty();
  const { data: propertyCollectionSettings, isLoading } = useQuery(
    ['propertyCollectionSettings', selectedProperty],
    () =>
      get(
        `/${selectedProperty.organizationId}/${selectedProperty.id}/propertyCollectionSetting`,
      ).then(({ propertyCollectionSettings: settings }) => {
        if (!settings) return null;
        if (Array.isArray(settings)) return settings.find((pcs) => pcs.enabled);
        return settings.enabled ? settings : null;
      }),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        toastr.error(
          intl.formatMessage(messages.error),
          intl.formatMessage(messages.failedToLoadCollectionSettings),
        );
      },
    },
  );

  return { propertyCollectionSettings, isLoading };
}

function useHouseholdCollection({ householdId, intl }) {
  const selectedProperty = useSelectedProperty();
  const {
    data: householdCollection,
    refetch,
    isLoading,
  } = useQuery(
    ['householdCollection', selectedProperty, householdId],
    () =>
      get(
        `/${selectedProperty.organizationId}/${selectedProperty.id}/${householdId}/collections`,
      ),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        toastr.error(
          intl.formatMessage(messages.error),
          intl.formatMessage(messages.failedToLoadCollectionData),
        );
      },
    },
  );

  return {
    householdCollection,
    refetchHouseholdCollection: refetch,
    isLoading,
  };
}

function useActivatePaymentPlan({
  householdId,
  selectedProperty,
  balance,
  refetchCollection,
  intl,
  setIsProcessingAction,
}) {
  return useCallback(async () => {
    try {
      setIsProcessingAction(true);
      await createCollection({
        organizationId: selectedProperty.organizationId,
        propertyId: selectedProperty.id,
        householdId,
        collectionsStatus: 'PAYMENT_PLAN',
        balanceDue: balance,
      });
      toastr.success(
        intl.formatMessage(messages.success),
        intl.formatMessage(messages.paymentPlanActivated),
      );
      refetchCollection();
    } catch {
      toastr.error(
        intl.formatMessage(messages.error),
        intl.formatMessage(messages.failedToActivatePaymentPlan),
      );
    } finally {
      setIsProcessingAction(false);
    }
  }, [
    householdId,
    selectedProperty,
    balance,
    refetchCollection,
    intl,
    setIsProcessingAction,
  ]);
}

function useCloseAccount({
  collectionId,
  selectedProperty,
  refetchCollection,
  intl,
  balance,
  householdId,
  setIsProcessingAction,
}) {
  return useCallback(async () => {
    try {
      setIsProcessingAction(true);

      const closeAccountPromise = collectionId
        ? updateCollection({
            organizationId: selectedProperty.organizationId,
            propertyId: selectedProperty.id,
            collectionId,
            collectionsStatus: 'ACCOUNT_CLOSED',
            balanceDue: balance,
          })
        : createCollection({
            organizationId: selectedProperty.organizationId,
            propertyId: selectedProperty.id,
            householdId,
            collectionsStatus: 'ACCOUNT_CLOSED',
            balanceDue: balance,
          });

      await closeAccountPromise;

      toastr.success(
        intl.formatMessage(messages.success),
        intl.formatMessage(messages.accountClosed),
      );
      refetchCollection();
    } catch {
      toastr.error(
        intl.formatMessage(messages.error),
        intl.formatMessage(messages.failedToCloseAccount),
      );
    } finally {
      setIsProcessingAction(false);
    }
  }, [
    collectionId,
    selectedProperty,
    refetchCollection,
    intl,
    balance,
    householdId,
    setIsProcessingAction,
  ]);
}

function useReOpenAccount({
  intl,
  refetchCollection,
  balance,
  hasClaim,
  collectionId,
  selectedProperty,
  setIsProcessingAction,
}) {
  return useCallback(async () => {
    try {
      setIsProcessingAction(true);

      const reOpenPromise = hasClaim
        ? updateCollection({
            organizationId: selectedProperty.organizationId,
            propertyId: selectedProperty.id,
            collectionId,
            collectionsStatus: 'CLAIM_CREATED',
            balanceDue: balance,
          })
        : deleteCollection({
            id: collectionId,
            organizationId: selectedProperty.organizationId,
            propertyId: selectedProperty.id,
            balanceDue: balance,
          });

      await reOpenPromise;

      toastr.success(
        intl.formatMessage(messages.success),
        intl.formatMessage(messages.accountReOpened),
      );
      refetchCollection();
    } catch {
      toastr.error(
        intl.formatMessage(messages.error),
        intl.formatMessage(messages.failedToReOpenAccount),
      );
    } finally {
      setIsProcessingAction(false);
    }
  }, [
    intl,
    refetchCollection,
    balance,
    hasClaim,
    collectionId,
    selectedProperty,
    setIsProcessingAction,
  ]);
}

function useRemovePaymentPlan({
  intl,
  refetchCollection,
  balance,
  collectionId,
  selectedProperty,
  setIsProcessingAction,
}) {
  return useCallback(async () => {
    try {
      setIsProcessingAction(true);

      await deleteCollection({
        id: collectionId,
        organizationId: selectedProperty.organizationId,
        propertyId: selectedProperty.id,
        balanceDue: balance,
      });

      toastr.success(
        intl.formatMessage(messages.success),
        intl.formatMessage(messages.paymentPlanRemoved),
      );
      refetchCollection();
    } catch {
      toastr.error(
        intl.formatMessage(messages.error),
        intl.formatMessage(messages.failedToRemovePaymentPlan),
      );
    } finally {
      setIsProcessingAction(false);
    }
  }, [
    intl,
    refetchCollection,
    balance,
    collectionId,
    selectedProperty,
    setIsProcessingAction,
  ]);
}

function useSentToCollections({
  selectedProperty,
  householdId,
  balance,
  intl,
  refetchCollection,
  setIsProcessingAction,
}) {
  return useCallback(async () => {
    try {
      setIsProcessingAction(true);

      await createCollection({
        organizationId: selectedProperty.organizationId,
        propertyId: selectedProperty.id,
        householdId,
        collectionsStatus: 'CLAIM_CREATED',
        balanceDue: balance,
      });
      toastr.success(
        intl.formatMessage(messages.success),
        intl.formatMessage(messages.sentToCollectionsSuccess),
      );
      refetchCollection();
    } catch {
      toastr.error(
        intl.formatMessage(messages.error),
        intl.formatMessage(messages.failedToSendToCollections),
      );
    } finally {
      setIsProcessingAction(false);
    }
  }, [
    householdId,
    selectedProperty,
    balance,
    refetchCollection,
    intl,
    setIsProcessingAction,
  ]);
}

function useRemoveSentToCollections({
  collectionId,
  selectedProperty,
  balance,
  refetchCollection,
  intl,
  setIsProcessingAction,
}) {
  return useCallback(async () => {
    try {
      setIsProcessingAction(true);

      await deleteCollection({
        id: collectionId,
        organizationId: selectedProperty.organizationId,
        propertyId: selectedProperty.id,
        balanceDue: balance,
      });

      toastr.success(
        intl.formatMessage(messages.success),
        intl.formatMessage(messages.removeSentToCollectionsSuccess),
      );
      refetchCollection();
    } catch {
      toastr.error(
        intl.formatMessage(messages.error),
        intl.formatMessage(messages.failedToRemoveSentToCollections),
      );
    } finally {
      setIsProcessingAction(false);
    }
  }, [
    intl,
    refetchCollection,
    balance,
    collectionId,
    selectedProperty,
    setIsProcessingAction,
  ]);
}

function useClaim({ intl, collectionId }) {
  const { data: claim, isLoading } = useQuery(
    ['claim', collectionId],
    () =>
      get(
        `${process.env.REACT_APP_COLLECTIONS_API_URL}/claims/collection/${collectionId}`,
        {
          isOtherApi: true,
        },
      ).then((data) => data?.claim),
    {
      refetchOnWindowFocus: false,
      enabled: !!collectionId,
      onError: () => {
        toastr.error(
          intl.formatMessage(messages.error),
          intl.formatMessage(messages.failedToLoadClaimData),
        );
      },
    },
  );

  return { claim, isLoading };
}

function createCollection({
  organizationId,
  propertyId,
  householdId,
  collectionAgencyId,
  collectionsStatus,
  balanceDue,
}) {
  return post(
    `/${organizationId}/${propertyId}/collections`,
    JSON.stringify({
      collection: {
        householdId,
        collectionAgencyId,
        collectionsStatus,
      },
      balanceDue,
    }),
  );
}

function updateCollection({
  organizationId,
  propertyId,
  collectionId,
  collectionsStatus,
  balanceDue,
}) {
  return patch(
    `/${organizationId}/${propertyId}/collections/${collectionId}`,
    JSON.stringify({
      collection: {
        collectionsStatus,
      },
      balanceDue,
    }),
  );
}

function deleteCollection({ id, organizationId, propertyId, balanceDue }) {
  return del(
    `/${organizationId}/${propertyId}/collections/${id}`,
    JSON.stringify({
      balanceDue,
    }),
  );
}
