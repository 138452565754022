import React, { useMemo } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useDelinquentAndPrepaid } from './hooksV2';
import withReduxStore from './withReduxStore';
import useBaseTableProps from '../../hooks/useBaseTableProps';
import TableLayout from '../../components/TableLayout';

const NAME = 'manage-delinquent-and-prepaid';
const TITLE = 'Manage Delinquent and Prepaid';

const ManageDelinquentAndPrepaid = ({
  selectedProperty,
  intl: injectedIntl,
  locale,
  store,
}) => {
  const intl = useMemo(() => injectedIntl, [injectedIntl]);
  const baseTableProps = useBaseTableProps();

  const {
    allColumnsHidden,
    columnOptions,
    count,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    footerRow,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    isLoading,
    name,
    onCSVButtonClick,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    totalCount,
  } = useDelinquentAndPrepaid({
    selectedProperty,
    NAME,
    intl,
    locale,
    store,
  });

  return (
    <DocumentTitle data-testid={NAME} title={TITLE}>
      <TableLayout name={name} childrenElementsHeight={56 + 8}>
        {(height) => (
          <>
            <TableHeader title={TITLE} count={count} totalCount={totalCount} />
            <Table
              {...baseTableProps}
              allColumnsHidden={allColumnsHidden}
              columnOptions={columnOptions}
              count={count}
              dateState={dateState}
              filterState={filterState}
              filterTypeState={filterTypeState}
              footerRow={footerRow}
              handleColumnChange={handleColumnChange}
              handleDateSubmit={handleDateSubmit}
              handleFilterChange={handleFilterChange}
              handleFilterTypeChange={handleFilterTypeChange}
              handleSearchSubmit={handleSearchSubmit}
              handleSortChange={handleSortChange}
              headers={filteredHeaders}
              height={height}
              isLoading={isLoading}
              name={name}
              onCSVButtonClick={onCSVButtonClick}
              order={order}
              orderBy={orderBy}
              rows={rows}
              searchState={searchState}
              selectedColumns={selectedColumns}
              totalCount={totalCount}
            />
          </>
        )}
      </TableLayout>
    </DocumentTitle>
  );
};

export const mapStateToProps = (state: GlobalState): Object => {
  const { app, languageProvider } = state;
  return {
    selectedProperty: app.selectedProperty,
    locale: languageProvider.locale,
  };
};

export default connect(
  mapStateToProps,
  null,
)(injectIntl(withReduxStore(ManageDelinquentAndPrepaid)));
