import { defineMessages } from 'react-intl';

const messages = defineMessages({
  modalTitle: {
    id: 'App.CamTab.CamAllocationsSetup.ModalTitle',
    defaultMessage: 'Add CAM Custom Pool',
  },
  camAllocationsSetup: {
    id: 'App.CamTab.CamAllocationsSetup.CamAllocationsSetup',
    defaultMessage: 'CAM Allocations Setup',
  },
  status: {
    id: 'App.CamTab.CamAllocationsSetup.Status',
    defaultMessage: 'Status',
  },
  addCAMException: {
    id: 'App.CamTab.CamAllocationsSetup.AddCAMException',
    defaultMessage: '+ Add CAM Custom Pool',
  },
  primaryCamPool: {
    id: 'App.CamTab.CamAllocationsSetup.PrimaryCamPool',
    defaultMessage: 'CAM Pools',
  },
  glAccounts: {
    id: 'App.CamTab.CamAllocationsSetup.GLAccounts',
    defaultMessage: 'GL Accounts Included',
  },
  caps: {
    id: 'App.CamTab.CamAllocationsSetup.Caps',
    defaultMessage: 'Caps',
  },
  baseAmount: {
    id: 'App.CamTab.CamAllocationsSetup.BaseAmount',
    defaultMessage: 'Base Amount',
  },
  grossUp: {
    id: 'App.CamTab.CamAllocationsSetup.GrossUp',
    defaultMessage: 'Gross Up',
  },
  annualBudgetedExpenses: {
    id: 'App.CamTab.CamAllocationsSetup.AnnualBudgetedExpenses',
    defaultMessage: 'Annual Budgeted Expenses',
  },
  allocationAndPercentage: {
    id: 'App.CamTab.CamAllocationsSetup.AllocationAndPercentage',
    defaultMessage: 'Allocation and Percentage',
  },
  setup: {
    id: 'App.CamTab.CamAllocationsSetup.Setup',
    defaultMessage: 'Setup',
  },
  getCalculations: {
    id: 'App.CamTab.CamAllocationsSetup.GetCalculations',
    defaultMessage: 'Get Calculations',
  },
  refreshCalculations: {
    id: 'App.CamTab.CamAllocationsSetup.RefreshCalculations',
    defaultMessage: 'Refresh Calculations',
  },
  confirmCalculations: {
    id: 'App.CamTab.CamAllocationsSetup.ConfirmCalculations',
    defaultMessage: 'Confirm Calculations',
  },
  cancel: {
    id: 'App.CamTab.CamAllocationsSetup.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.CamTab.CamAllocationsSetup.Save',
    defaultMessage: 'Save',
  },
  exceptionName: {
    id: 'App.CamTab.CamAllocationsSetup.ExceptionName',
    defaultMessage: 'Custom Pool Name:*',
  },
  allocationSetup: {
    id: 'App.CamTab.CamAllocationsSetup.AllocationSetup',
    defaultMessage: 'Allocation Setup',
  },
  allocationSetupException: {
    id: 'App.CamTab.CamAllocationsSetup.AllocationSetupException',
    defaultMessage: 'Allocation Setup - Exception',
  },
  glAccountsIncluded: {
    id: 'App.CamTab.CamAllocationsSetup.GLAccountsIncluded',
    defaultMessage: 'GL Accounts:*',
  },
  selectAll: {
    id: 'App.CamTab.CamAllocationsSetup.SelectAll',
    defaultMessage: 'Select All',
  },
  insurancePool: {
    id: 'App.CamTab.CamAllocationsSetup.InsurancePool',
    defaultMessage: 'Insurance Pool',
  },
  proRataShare: {
    id: 'App.CamTab.CamAllocationsSetup.proRataShare',
    defaultMessage: 'Pro-Rata Share',
  },
  baseYear: {
    id: 'App.CamTab.CamAllocationsSetup.BaseYear',
    defaultMessage: 'Base Year',
  },
  yoy: {
    id: 'App.CamTab.CamAllocationsSetup.YOY',
    defaultMessage: 'YOY',
  },
  aggregate: {
    id: 'App.CamTab.CamAllocationsSetup.Aggregate',
    defaultMessage: 'Aggregate',
  },
  cumulative: {
    id: 'App.CamTab.CamAllocationsSetup.Cumulative',
    defaultMessage: 'Cumulative',
  },
  compounded: {
    id: 'App.CamTab.CamAllocationsSetup.Compounded',
    defaultMessage: 'Compounded',
  },
  ownerStopExpense: {
    id: 'App.CamTab.CamAllocationsSetup.OwnerStopExpense',
    defaultMessage: 'Owner Stop Expense',
  },
  none: {
    id: 'App.CamTab.CamAllocationsSetup.None',
    defaultMessage: 'None',
  },
  expenseCaps: {
    id: 'App.CamTab.CamAllocationsSetup.ExpenseCaps',
    defaultMessage: 'Expense Caps:*',
  },
  actual: {
    id: 'App.CamTab.CamAllocationsSetup.Actual',
    defaultMessage: 'Actual',
  },
  custom: {
    id: 'App.CamTab.CamAllocationsSetup.Custom',
    defaultMessage: 'Custom',
  },
  accountsExcluded: {
    id: 'App.CamTab.CamAllocationsSetup.AccountsExcluded',
    defaultMessage: 'Accounts Excluded from Gross Up',
  },
  accountsExcludedCap: {
    id: 'App.CamTab.CamAllocationsSetup.AccountsExcludedExpenseCap',
    defaultMessage: 'Accounts Excluded from Expense Cap',
  },
  grossUpPercentage: {
    id: 'App.CamTab.CamAllocationsSetup.GrossUpPercentage',
    defaultMessage: 'Gross Up Percentage',
  },
  estimatedAvgOccupancy: {
    id: 'App.CamTab.CamAllocationsSetup.EstimatedAvgOccupancy',
    defaultMessage: 'Estimated Avg. Occupancy',
  },
  yes: {
    id: 'App.CamTab.CamAllocationsSetup.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.CamTab.CamAllocationsSetup.No',
    defaultMessage: 'No',
  },
  squareFoot: {
    id: 'App.CamTab.CamAllocationsSetup.squareFoot',
    defaultMessage: '/ square foot',
  },
  selectedGLAccounts: {
    id: 'App.CamTab.CamAllocationsSetup.selectedGLAccounts',
    defaultMessage: 'Selected GL Accounts',
  },
  transactionCode: {
    id: 'App.CamTab.CamAllocationsSetup.transactionCode',
    defaultMessage: 'Transaction Code:*',
  },
  reconciliationTransactionCode: {
    id: 'App.CamTab.CamAllocationsSetup.ReconciliationTransactionCode',
    defaultMessage: 'Transaction Code (Reconciliation):*',
  },
  leaseAllows: {
    id: 'App.CamTab.CamAllocationsSetup.LeaseAllows',
    defaultMessage: 'Lease Allows for Monthly Estimate*',
  },
  premisesSqFt: {
    id: 'App.CamTab.CamAllocationsSetup.PremisesSqFt',
    defaultMessage: 'Premises Sq.Ft',
  },
  totalPropertySqFt: {
    id: 'App.CamTab.CamAllocationsSetup.TotalPropertySqFt',
    defaultMessage: 'Total Property Sq.Ft',
  },
  pool: {
    id: 'App.CamTab.CamAllocationsSetup.pool',
    defaultMessage: ' Pool',
  },
  fetchIntacctGlAccountsError: {
    id: 'App.CamTab.FetchIntacctGlAccountsError',
    defaultMessage: 'Error fetching GL accounts',
  },
  fetchSubjournalsError: {
    id: 'App.CamTab.FetchSubjournalsError',
    defaultMessage: 'Error fetching subjournals',
  },
  fetchChargeCodesError: {
    id: 'App.CamTab.FetchChargeCodesError',
    defaultMessage: 'Error fetching charge codes',
  },
  totalAnnualExpense: {
    id: 'App.CamTab.CamAllocationsSetup.TotalAnnualExpense',
    defaultMessage: 'Total Annual Expense',
  },
  leaseStartYear: {
    id: 'App.CamTab.CamAllocationsSetup.LeaseStartYear',
    defaultMessage: 'Lease Start Year',
  },
  fetchCamAllocationsError: {
    id: 'App.CamTab.CamAllocationsSetup.FetchCamAllocationsError',
    defaultMessage: 'Error fetching the CAM Allocation',
  },
  modifyCamAllocationSuccessTitle: {
    id: 'App.CamTab.CamAllocationsSetup.ModifyCamAllocationSuccessTitle',
    defaultMessage: 'CAM Allocation updated',
  },
  modifyCamAllocationSuccessMessage: {
    id: 'App.CamTab.CamAllocationsSetup.ModifyCamAllocationSuccessMessage',
    defaultMessage: 'The CAM Allocation was updated successfully.',
  },
  modifyCamAllocationError: {
    id: 'App.CamTab.CamAllocationsSetup.ModifyCamAllocationError',
    defaultMessage: 'Error updating the CAM Allocation',
  },
  addCamExceptionSuccessTitle: {
    id: 'App.CamTab.Sections.AddCamExceptionSuccessTitle',
    defaultMessage: 'CAM Exception added',
  },
  addCamExceptionSuccessMessage: {
    id: 'App.CamTab.Sections.AddCamExceptionSuccessMessage',
    defaultMessage: 'The CAM Exception was added successfully.',
  },
  addCamExceptionError: {
    id: 'App.CamTab.Sections.AddCamExceptionError',
    defaultMessage: 'Error adding the CAM Exception',
  },
  deleteConfirmationMsg: {
    id: 'App.CamTab.CamAllocationsSetup.DeleteConfirmationMsg',
    defaultMessage: ' Are you sure you want to delete?',
  },
  fetchStartYearExpensesError: {
    id: 'App.CamTab.CamAllocationsSetup.FetchStartYearExpensesError',
    defaultMessage: 'Error fetching start year expenses',
  },
  removeCamExceptionSuccessTitle: {
    id: 'App.CamTab.CamAllocationsSetup.RemoveCamExceptionSuccessTitle',
    defaultMessage: 'CAM Exception deleted',
  },
  removeCamExceptionSuccessMessage: {
    id: 'App.CamTab.CamAllocationsSetup.RemoveCamExceptionSuccessMessage',
    defaultMessage: 'The CAM Exception was deleted successfully.',
  },
  removeCamExceptionError: {
    id: 'App.CamTab.CamAllocationsSetup.RemoveCamExceptionError',
    defaultMessage: 'Error deleting the CAM Record',
  },
  allowMontlyEstimate: {
    id: 'App.CamTab.CamAllocationsSetup.AllowMontlyEstimate',
    defaultMessage: 'Allow Monthly Estimate?',
  },
  includeGLAccounts: {
    id: 'App.CamTab.CamAllocationsSetup.IncludeGLAccounts',
    defaultMessage: 'Include GL Accounts',
  },
  doNotSetupPool: {
    id: 'App.CamTab.CamAllocationsSetup.DoNotSetupPool',
    defaultMessage: 'Do Not Setup Pool',
  },
});

export default messages;
