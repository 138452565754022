import * as ActionTypes from './constants';
import type { Action } from '../App/types';
import type { CreateRoleState } from './types';

const initialState = {
  permissions: [],
  selectedPermissions: [],
  roleTypes: [],
  nonePermissionsSelected: false,
  rolePermitsAssignees: false,
  isSubmitting: false,
};

const createRoleReducer = (
  state: CreateRoleState = initialState,
  action: Action<*, *>,
): CreateRoleState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ALL_PERMISSIONS_SUCCESS: {
      return { ...state, permissions: payload };
    }
    case ActionTypes.SET_SELECTED_PERMISSIONS: {
      return { ...state, selectedPermissions: payload };
    }
    case ActionTypes.GET_ALL_ROLE_TYPES_SUCCESS: {
      return { ...state, roleTypes: payload };
    }
    case ActionTypes.NONE_PERMISSIONS_SELECTED: {
      return { ...state, nonePermissionsSelected: payload };
    }
    case ActionTypes.SHOW_ROLE_PERMIT_ASSIGNEED: {
      return { ...state, rolePermitsAssignees: payload };
    }
    case ActionTypes.RESET_CREATE_ROLE_FORM: {
      return {
        ...state,
        rolePermitsAssignees: false,
        selectedPermissions: [],
        nonePermissionsSelected: false,
      };
    }
    case ActionTypes.CREATE_ROLE: {
      return {
        ...state,
        isSubmitting: true,
      };
    }
    case ActionTypes.CREATE_ROLE_SUCCESS:
    case ActionTypes.CREATE_ROLE_FAIL: {
      return {
        ...state,
        isSubmitting: false,
      };
    }
    default:
      return state;
  }
};

export default createRoleReducer;
