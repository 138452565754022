import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.Overview.Activity.Title',
    defaultMessage: 'Activity'
  },
  moveIns: {
    id: 'App.Overview.Activity.MoveIns',
    defaultMessage: 'MTD Move-Ins'
  },
  moveOuts: {
    id: 'App.Overview.Activity.MoveOuts',
    defaultMessage: 'MTD Move-Outs'
  },
  noData: {
    id: 'App.Overview.Activity.NoData',
    defaultMessage:
      'There have not been any move-ins or move-outs yet this month.'
  }
});

export default messages;
