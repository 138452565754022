import { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  useTableFilterSortData,
  useTableFilterSortSearchManager,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';

import { HEADERS, PROPERTY_PATH_MAP } from './constants';
import ReportService from '../../../../services/reportService';
import useUniqueTableName from '../../../../hooks/useUniqueTableName';

export const useViewCentralizedReportsHistory = ({
  NAME,
  organizationId,
  report,
  fromDate,
  toDate,
}) => {
  const name = useUniqueTableName(NAME);
  const { results, isLoading } = useFetchCentralizedReportsHistory({
    organizationId,
    reportId: report?.id,
  });
  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleDateSubmit,
    handleDateReset,
  } = useTableFilterSortSearchManager({
    name,
    headers: HEADERS,
    initialDateState: {
      createdAt: {
        fromDate,
        toDate,
        FromDateProps: {
          disableFuture: true,
        },
        ToDateProps: {
          disableFuture: true,
        },
      },
    },
  });
  const sortedAndFilteredResults = useTableFilterSortData({
    results,
    order,
    orderBy,
    filterState,
    filterTypeState,
    dateState,
    PROPERTY_PATH_MAP,
  });

  async function handleDownload(row) {
    const reportService = new ReportService();
    await reportService.downloadCentralizedReport(
      organizationId,
      row.reportId,
      row.filename.split('/')[1],
    );
  }
  const rows = useRows({
    sortedAndFilteredResults,
    handleDownload,
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers: HEADERS,
    firstRow: rows[0],
  });

  return {
    name,
    allColumnsHidden,
    columnOptions,
    count: rows?.length ?? 0,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
    handleSortChange,
    isLoading,
    order,
    orderBy,
    rows,
    filterState,
    dateState,
    handleDateSubmit,
    handleDateReset,
    totalCount: results?.length ?? 0,
  };
};

export const useFetchCentralizedReportsHistory = ({
  organizationId,
  reportId,
}) => {
  const reportsService = new ReportService();
  const abortController = new AbortController();
  const queryKey = ['CentralizedReports', 'history', reportId];
  const options = { signal: abortController.signal };
  const { data, isLoading } = useQuery(
    queryKey,
    () =>
      reportsService.getCentralizedHistoricalReports({
        organizationId,
        reportId,
        options,
      }),
    {
      enabled: !!reportId && !!organizationId,
      refetchOnWindowFocus: false,
    },
  );

  return {
    results: data || [],
    isLoading,
  };
};
export const useRows = ({ sortedAndFilteredResults, handleDownload }) => {
  return useMemo(
    () =>
      sortedAndFilteredResults.map((row) => {
        const { createdAt } = row;
        return {
          createdAt: {
            variant: 'dateTime',
            value: createdAt,
          },

          download: {
            variant: 'iconButton',
            iconName: 'DownloadIcon',
            color: 'primary',
            onClick: () => handleDownload(row),
            sx: {
              width: '100%',
            },
          },
        };
      }),
    [sortedAndFilteredResults, handleDownload],
  );
};
