import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.LeaseAffordableApplicantNonPrimaryForm.Title',
    defaultMessage:
      'Affordable Non-Primary Lease Application for {propertyName}'
  },
  currentResidenceInformationTitle: {
    id:
      'App.LeaseAffordableApplicantNonPrimaryForm.CurrentResidenceInformationTitle',
    defaultMessage: 'Current Residence Information'
  },
  previousResidenceInformationTitle: {
    id:
      'App.LeaseAffordableApplicantNonPrimaryForm.PreviousResidenceInformationTitle',
    defaultMessage: 'Previous Residence Information'
  },
  applicantsCurrentEmployerTitle: {
    id:
      'App.LeaseAffordableApplicantNonPrimaryForm.ApplicantsCurrentEmployerTitle',
    defaultMessage: "Applicant's Current Employer"
  },
  applicantsSecondCurrentEmployerTitle: {
    id:
      'App.LeaseAffordableApplicantNonPrimaryForm.ApplicantsSecondCurrentEmployerTitle',
    defaultMessage: "Applicant's 2nd Current Employer (If Applicable)"
  },
  applicantsPreviousEmployerTitle: {
    id:
      'App.LeaseAffordableApplicantNonPrimaryForm.ApplicantsPreviousEmployerTitle',
    defaultMessage: "Applicant's Previous Employer"
  },
  completeConfirmation: {
    id: 'App.LeaseAffordableApplicantNonPrimaryForm.CompleteConfirmation',
    defaultMessage: 'Are you sure you want to complete this application?'
  },
  cancelConfirmation: {
    id: 'App.LeaseAffordableApplicantNonPrimaryForm.CancelConfirmation',
    defaultMessage: 'Are you sure you want to cancel?'
  },
  streetAddressLabel: {
    id: 'App.LeaseAffordableApplicantNonPrimaryForm.StreetAddressLabel',
    defaultMessage: 'Employer Street Address'
  },
  passportNumberLabel: {
    id: 'App.LeaseAffordableApplicantNonPrimaryForm.PassportNumberLabel',
    defaultMessage: 'Passport Number (if no SSN)'
  },
  from: {
    id: 'App.LeaseAffordableApplicantNonPrimaryForm.From',
    defaultMessage: 'From'
  },
  startDate: {
    id: 'App.LeaseAffordableApplicantNonPrimaryForm.StartDate',
    defaultMessage: 'Start Date'
  },
  employerSituationNote: {
    id:
      'App.LeaseAffordableApplicantNonPrimaryForm.Affordable.employerSituationNote',
    defaultMessage:
      'You must inform us if your employment situation changes prior to move in'
  },
  secondPreviousResidenceInformationTitle: {
    id:
      'App.LeaseAffordableApplicantNonPrimaryForm.SecondPreviousResidenceInformationTitle',
    defaultMessage: '2nd Previous Residence Information'
  }
});

export default messages;
