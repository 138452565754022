import React, { useMemo } from 'react';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { FilterCards } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';

const LeaseInfoCards = ({ metrics, onChange, selectedMetric }) => (
  <FilterCards
    options={useOptions({ metrics })}
    onChange={onChange}
    value={selectedMetric}
    sx={{
      padding: 2,
      overflowX: 'auto',
      width: '100%',
    }}
  />
);

const useOptions = ({ metrics }) => {
  return useMemo(() => {
    return metrics?.map((metric) => {
      return {
        id: metric.subtitle,
        component: (
          <Box>
            <Typography variant="body2" marginBottom={1}>
              {metric.title}
              <br />
              {metric.subtitle}
            </Typography>
            <Typography variant="body2">
              <strong>{metric.count}</strong>
            </Typography>
          </Box>
        ),
        sx: {
          width: 260,
        },
        tooltip: metric.description,
      };
    });
  }, [metrics]);
};

export default LeaseInfoCards;
