import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { Col, Row } from 'react-bootstrap';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import MissingTransactionCodeModal from './missingTransactionCodeModal';
import { useGetPropertySubsidyTransactionCode } from './hooks';
import { checkTransactionCodes } from './utils';
import SubmitButton from '../../../components/SubmitButton';

type Props = {
  redirectToCreateSubsidyBatch: Function,
  propertyId: string,
  organizationId: string,
};

const ManageSubsidyPaymentsHeader = (props: Props) => {
  const { redirectToCreateSubsidyBatch, propertyId, organizationId } = props;
  const [transactionCodes, transactionCodeLoading] =
    useGetPropertySubsidyTransactionCode({
      propertyId,
      organizationId,
    });
  const showTransactionCodeMissingModal =
    !checkTransactionCodes(transactionCodes);
  const [showTrnCodeMissingModal, setshowTrnCodeMissingModal] = useState(false);
  return (
    <div className="container-fluid">
      <MissingTransactionCodeModal
        show={showTransactionCodeMissingModal && showTrnCodeMissingModal}
        onHide={() => setshowTrnCodeMissingModal(false)}
      />
      <Row>
        <Col xs={6} lg={8}>
          <h1>
            <FormattedMessage {...messages.header} />
          </h1>
        </Col>
        <Col xs={6} lg={4}>
          <ElementWithPermissions scope={['subsidy-payment-create-edit']}>
            <SubmitButton
              type="button"
              bsStyle="warning"
              classes="pull-right"
              href="#"
              clickHandler={() =>
                showTransactionCodeMissingModal
                  ? setshowTrnCodeMissingModal(true)
                  : redirectToCreateSubsidyBatch()
              }
              isSubmitting={transactionCodeLoading}
            >
              <FormattedMessage {...messages.createSubsidyBatch} />
            </SubmitButton>
          </ElementWithPermissions>
        </Col>
      </Row>
    </div>
  );
};
export default ManageSubsidyPaymentsHeader;
