import { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import * as graphCommon from './graph-common';
import { PageContext } from '../context';
import messages from '../messages';

export type Props = {
  labels: string[],
  leasesData: number[],
  onColumnClick: Function,
  height?: number,
  graphData: GraphData,
};

function Graph(props: Props) {
  const { formatMessage } = useContext(PageContext);

  return (
    <Bar
      height={props.height || graphCommon.DEFAULT_HEIGHT}
      data={{
        labels: props.labels,
        datasets: [
          graphCommon.getBarConfig(
            formatMessage(messages.leasesPlural),
            graphCommon.COLORS.LEASES,
            props.leasesData,
          ),
        ],
      }}
      options={{
        tooltips: {
          bodyFontStyle: 'bold',
          footerFontStyle: 'normal',
          callbacks: {
            title: () => '',
            label: graphCommon.labelConfig,
            footer: (items, data) => {
              const { xLabel } = items[0];

              if (!xLabel) return '';

              const [month, year] = xLabel;

              const monthNum = moment().month(month).month();
              const monthData = props.graphData[year][monthNum];
              const leaseLimit = graphCommon.padText(
                `${formatMessage(messages.leaseLimit)}: --`,
                graphCommon.TOOLTIP_PADDING.BODY,
              );

              return [
                leaseLimit,
                graphCommon.padText(
                  `${formatMessage(messages.leases)}: ${monthData.leases}`,
                  graphCommon.TOOLTIP_PADDING.BODY,
                ),
                graphCommon.padText(
                  `${formatMessage(messages.activeLeases)}: ${
                    monthData.activeLeases
                  }`,
                  graphCommon.TOOLTIP_PADDING.FOOTER,
                ),
                graphCommon.padText(
                  `${formatMessage(messages.renewalInProcess)}: ${
                    monthData.renewalLeases
                  }`,
                  graphCommon.TOOLTIP_PADDING.FOOTER,
                ),
                graphCommon.padText(
                  `${formatMessage(messages.moveInInProcess)}: ${
                    monthData.moveInProcess
                  }`,
                  graphCommon.TOOLTIP_PADDING.FOOTER,
                ),
              ];
            },
          },
        },
        legend: {
          labels: {
            generateLabels: (c) => [
              graphCommon.getBarLegend(
                formatMessage(messages.leasesPlural),
                0,
                graphCommon.COLORS.LEASES,
              ),
            ],
            usePointStyle: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: false,
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
      }}
      onElementsClick={props.onColumnClick}
    />
  );
}

export default Graph;
