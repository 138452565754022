import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'App.ViewProperty.BankInformationModal.Title',
    defaultMessage: 'Bank Account Information',
  },
  save: {
    id: 'App.ViewProperty.BankInformationModal.Save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'App.ViewProperty.BankInformationModal.Cancel',
    defaultMessage: 'Cancel',
  },
  operatingAccount: {
    id:
      'App.ViewProperty.BankInformationModal.BankInformation.OperatingAccount',
    defaultMessage: 'Operating Account',
  },
  securityAccount: {
    id: 'App.ViewProperty.BankInformationModal.BankInformation.SecurityAccount',
    defaultMessage: 'Security Account',
  },
  bankName: {
    id: 'App.ViewProperty.BankInformationModal.BankInformation.BankName',
    defaultMessage: 'Bank Name',
  },
  accountNumber: {
    id: 'App.ViewProperty.BankInformationModal.BankInformation.AccountNumber',
    defaultMessage: 'Account Number',
  },
  routingNumber: {
    id: 'App.ViewProperty.BankInformationModal.BankInformation.RoutingNumber',
    defaultMessage: 'Routing Number',
  },
  comment: {
    id: 'App.ViewProperty.BankInformationModal.BankInformation.Comment',
    defaultMessage: 'Comment',
  },
  success: {
    id: 'App.ViewProperty.BankInformationModal.BankInformation.Update.Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'App.ViewProperty.BankInformationModal.BankInformation.Update.Error',
    defaultMessage: 'Error',
  },
  successBody: {
    id:
      'App.ViewProperty.BankInformationModal.BankInformation.Update.successBody',
    defaultMessage: 'Bank Accounts updated',
  },
  errorBody: {
    id:
      'App.ViewProperty.BankInformationModal.BankInformation.Update.errorBody',
    defaultMessage: 'Error updating Bank Accounts',
  },
});
