import type { Action } from '../App/types';
import {
  GET_ALL_DELINQUENCIES_SUCCESS,
  GET_ALL_DELINQUENCIES_ERROR,
  CLEAN_LOADED_DELINQUENCIES,
} from './constants';

export const initialState = {
  delinquencies: [],
  loading: true,
};

const manageDelinquenciesReducer = (
  state: Object = initialState,
  action: Action<*, *>,
): Object => {
  const { type, payload, error } = action;
  switch (type) {
    case GET_ALL_DELINQUENCIES_SUCCESS: {
      return { ...state, delinquencies: payload, loading: false };
    }
    case GET_ALL_DELINQUENCIES_ERROR: {
      return { ...state, error, loading: false };
    }
    case CLEAN_LOADED_DELINQUENCIES: {
      return initialState;
    }
    default:
      return state;
  }
};

export default manageDelinquenciesReducer;
