import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { palette } from '@fortress-technology-solutions/fortress-component-library/design';
import { useMemo } from 'react';
import styled from 'styled-components';
import DateRangeControl from '../../../../../components/FilterControls/DateRangeControl';
import MultiCheckboxControl from '../../../../../components/FilterControls/MultiCheckboxControl';
import MultiSelectControl from '../../../../../components/FilterControls/MultiSelectControl';
import { FilterType } from '../../../constants';

type Props = {
  values: Object,
  onChange: (
    key: string,
    payload: { type: string, values: Array<any> },
  ) => void,
  customerStatus: string,
  dateRange: { to: string, from: string },
  onDateChange: Function,
  customerFilters: Array,
};

const StyledDiv = styled.div`
  margin-top: 28px !important;
  margin-bottom: -5px !important;
`;

function FilterControls({
  onChange,
  values,
  customerStatus,
  dateRange,
  onDateChange,
  customerFilters,
}: Props) {
  const handleMultiSelectChange = (key: string) => (e: Object) => {
    onChange(key, {
      type: 'multi-select',
      values: e.target.value,
    });
  };
  const handleMultiCheckboxChange = (key: string) => (e: Object) => {
    const currentValues = values[key] ?? [];

    let newValues = [];
    if (e.target.checked) newValues = [...currentValues, e.target.name];
    else newValues = currentValues.filter((v) => v !== e.target.name);

    onChange(key, {
      type: 'multi-checkbox',
      values: newValues,
    });
  };

  const showDateRange = useMemo(
    () =>
      customerStatus === 'prospects' &&
      (values['prospects'] ?? []).includes('deadOrLost'),
    [customerStatus, values],
  );

  return (
    <div>
      <div>
        <h5 style={{ color: palette.main.blue }}>Filter By</h5>
      </div>

      <Stack justifyContent="start" spacing={2} direction="row">
        {customerFilters
          .filter((f) => !f.hide)
          .map((filter) => {
            switch (filter.type) {
              case FilterType.Select:
              default:
                return (
                  <MultiSelectControl
                    key={filter.fieldName}
                    name={filter.fieldName}
                    label={filter.fieldDescription}
                    options={filter.options}
                    onChange={handleMultiSelectChange(filter.fieldName)}
                    value={values[filter.fieldName] ?? []}
                    width="175px"
                    placeholder="Choose"
                  />
                );
              case FilterType.Checkbox:
                return (
                  <MultiCheckboxControl
                    key={filter.fieldName}
                    label={filter.fieldDescription}
                    options={filter.options}
                    onChange={handleMultiCheckboxChange(filter.fieldName)}
                    value={values[filter.fieldName] ?? []}
                  />
                );
            }
          })}
        {showDateRange && (
          <StyledDiv>
            <DateRangeControl
              fromPlaceholder="MM/DD/YYYY"
              toPlaceholder="MM/DD/YYYY"
              from={dateRange.from}
              to={dateRange.to}
              onFromChange={onDateChange('dateFrom')}
              onToChange={onDateChange('dateTo')}
            />
          </StyledDiv>
        )}
      </Stack>
    </div>
  );
}

export default FilterControls;
