import { useEffect, useCallback, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import FileSaver from 'file-saver';
import DocumentService from '../../../services/documentService';
import FinalAccountStatementReversalService from '../../../services/finalAccountStatementReversalService';
import FinalAccountStatementService from '../../../services/finalAccountStatementService';
import messages from './messages';

export const useAsyncDownloadFASDocument = (
  organizationId: string,
  propertyId: string,
  applicationId: string,
  mode: string,
  unitNumber: string,
  toasterFn: ?Function,
  intl: ?Object,
) => {
  const [downloadDocument, setDownloadDocument] = useState(false);

  const handleDownloadFASDocument = () => setDownloadDocument(true);

  const fetchDownloadDocument = useCallback(async () => {
    if (
      downloadDocument &&
      organizationId &&
      propertyId &&
      applicationId &&
      mode &&
      unitNumber
    ) {
      // $FlowFixMe
      const abortController = new AbortController(); // eslint-disable-line
      const options = { signal: abortController.signal };
      const documentService = new DocumentService();

      try {
        const file = await documentService.downloadFASDocument(
          organizationId,
          propertyId,
          applicationId,
          mode,
          options,
        );
        if (mode === 'pdf')
          FileSaver.saveAs(
            file,
            `Final_Account_Statement_${unitNumber}.${mode}`,
          );
        else {
          const blob = new Blob([file], {
            type: 'data:application/vnd.ms-excel;base64',
          });
          FileSaver.saveAs(
            blob,
            `Final_Account_Statement_${unitNumber}.${mode}`,
          );
        }
      } catch (err) {
        if (toasterFn && intl) {
          toasterFn({
            type: 'error',
            message: err
              ? err
              : intl.formatMessage(messages.failedFasDocumentDownload),
            title: 'Error while downloading',
          });
        }
      } finally {
        setDownloadDocument(false);
      }
    }
  }, [
    downloadDocument,
    applicationId,
    organizationId,
    propertyId,
    toasterFn,
    intl,
    mode,
    unitNumber,
  ]);

  useEffect(() => {
    fetchDownloadDocument();
    return () => {
      setDownloadDocument(false);
    };
  }, [fetchDownloadDocument]);

  return handleDownloadFASDocument;
};

export const useGetFinalAccountStatement = (residentInfo) => {
  const [finalAccountStatement, setFinalAccountStatement] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  useEffect(
    function fetchFinalAccountStatement() {
      const makeRequest = async () => {
        setIsLoading(true);
        const finalAccountStatementService = new FinalAccountStatementService();
        const results = await finalAccountStatementService
          .getAll({
            householdId: residentInfo.householdId,
          })
          .then((results) => {
            setFinalAccountStatement(results[0]);
            setIsLoading(false);
            return results;
          })
          .catch((error) => {
            toastr.error('Error', error.message || error.networkError || error);
            setIsLoading(false);
          });
        return results;
      };
      makeRequest();
    },
    [setFinalAccountStatement, residentInfo],
  );

  return {
    data: finalAccountStatement,
    setData: setFinalAccountStatement,
    isLoading,
  };
};

export const useGetFinalAccountStatementReversal = (finalAccountStatement) => {
  const [finalAccountStatementReversal, setFinalAccountStatementReversal] =
    useState(null);
  const [isLoading, setIsLoading] = useState(null);
  useEffect(
    function fetchFinalAccountStatementReversal() {
      const makeRequest = async () => {
        const finalAccountStatementReversalService =
          new FinalAccountStatementReversalService();
        const results = await finalAccountStatementReversalService
          .getAll({
            householdId: finalAccountStatement.householdId,
            finalAccountStatementId: finalAccountStatement.id,
          })
          .then((results) => {
            setFinalAccountStatementReversal(results[0]);
            setIsLoading(false);
            return results;
          })
          .catch((error) => {
            toastr.error('Error', error.message || error.networkError || error);
            setIsLoading(false);
          });
        return results;
      };
      if (finalAccountStatement) {
        makeRequest();
      }
    },
    [finalAccountStatement, setFinalAccountStatementReversal],
  );

  return {
    data: finalAccountStatementReversal,
    setData: setFinalAccountStatementReversal,
    isLoading,
  };
};
