import React from 'react';
import { Row, Col, Grid } from 'react-bootstrap';
import { Field, reduxForm, initialize } from 'redux-form';
import messages from './messages';
import validate from './validateEditForm';
import * as constants from './constants';
import { TwoColumnField } from './Common';
import {
  renderTextAreaTwoColumns,
  renderDateField,
  extractCurrentDateFormat,
  validDate,
  renderRadioGroupField,
} from '../../../../utils/redux-form-helper';

type Props = {
  intl: Object,
  onSubmit: Function,
  handleSubmit: Function,
};

const yesNoOptions = [
  { value: 'true', text: 'Yes' },
  { value: 'false', text: 'No' },
];

function LeaseBasicsEditForm({ intl, onSubmit, handleSubmit }: Props) {
  const currentDateFormat = extractCurrentDateFormat(intl);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid fluid>
        <Row>
          <TwoColumnField
            name="leaseEffectiveDate"
            component={renderDateField}
            label={intl.formatMessage(messages.leaseEffectiveDateField)}
            bsSize="md"
            classPicker="modal-fixed-position"
            placeholder={currentDateFormat}
            dateFormat={currentDateFormat}
            validate={[validDate]}
          />
        </Row>
        <Row>
          <TwoColumnField
            name="commencementDate"
            component={renderDateField}
            label={intl.formatMessage(messages.commencementDateField)}
            bsSize="md"
            classPicker="modal-fixed-position"
            placeholder={currentDateFormat}
            dateFormat={currentDateFormat}
            validate={[validDate]}
          />
        </Row>
        <Row>
          <TwoColumnField
            name="leaseEndDate"
            component={renderDateField}
            label={intl.formatMessage(messages.leaseEndDateField)}
            bsSize="md"
            classPicker="modal-fixed-position"
            placeholder={currentDateFormat}
            dateFormat={currentDateFormat}
            validate={[validDate]}
          />
        </Row>
        <Row>
          <TwoColumnField
            name="rentStartDate"
            component={renderDateField}
            label={intl.formatMessage(messages.rentStartDateField)}
            bsSize="md"
            classPicker="modal-fixed-position"
            placeholder={currentDateFormat}
            dateFormat={currentDateFormat}
            validate={[validDate]}
          />
        </Row>
        <Row>
          <TwoColumnField
            name="isRentDateEstimated"
            label={intl.formatMessage(messages.estimatedRentDateField)}
            component={renderRadioGroupField}
            options={yesNoOptions}
            labelClass="padright20"
            className="form-options"
            inline
          />
        </Row>
        <Row>
          <Col xs={12}>
            <Field
              name="overallLeaseComments"
              label={intl.formatMessage(messages.overallLeaseCommentsField)}
              placeholder={intl.formatMessage(
                messages.overallLeaseCommentsPlaceholder,
              )}
              component={renderTextAreaTwoColumns}
              col1={[4, 12]}
              col2={[8, 12]}
            />
          </Col>
        </Row>
      </Grid>
    </form>
  );
}

export default reduxForm({
  form: constants.EDIT_FORM_NAME,
  enableReinitialize: true,
  validate,
  onSubmitSuccess(result, dispatch) {
    dispatch(initialize(constants.FORM_NAME, result));
  },
})(LeaseBasicsEditForm);
