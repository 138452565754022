import { defineMessages } from 'react-intl';

const messages = defineMessages({
  tableTitle: {
    id: 'manageWorkOrdersTable.tableTitle',
    defaultMessage: 'Manage Work Orders',
  },
  id: {
    id: 'manageWorkOrdersTable.id',
    defaultMessage: 'ID',
  },
  location: {
    id: 'manageWorkOrdersTable.location',
    defaultMessage: 'Location',
  },
  buildingNumber: {
    id: 'manageWorkOrdersTable.buildingNumber',
    defaultMessage: 'Building Number',
  },
  issueDescription: {
    id: 'manageWorkOrdersTable.issueDescription',
    defaultMessage: 'Issue Description',
  },
  creationDate: {
    id: 'manageWorkOrdersTable.creationDate',
    defaultMessage: 'Creation Date',
  },
  assignedTo: {
    id: 'manageWorkOrdersTable.assignedTo',
    defaultMessage: 'Assigned To',
  },
  priority: {
    id: 'manageWorkOrdersTable.priority',
    defaultMessage: 'Priority',
  },
  status: {
    id: 'manageWorkOrdersTable.status',
    defaultMessage: 'Status',
  },
  daysOpen: {
    id: 'manageWorkOrdersTable.daysOpen',
    defaultMessage: 'Days Open',
  },
  dueDate: {
    id: 'manageWorkOrdersTable.dueDate',
    defaultMessage: 'Due Date',
  },
  completionDate: {
    id: 'manageWorkOrdersTable.completionDate',
    defaultMessage: 'Completion Date',
  },
  requestor: {
    id: 'manageWorkOrdersTable.requestor',
    defaultMessage: 'Requestor',
  },
  createWorkOrder: {
    id: 'manageWorkOrdersTable.createWorkOrder',
    defaultMessage: 'Create Work Order',
  },
  assigneeInactiveLegendItem: {
    id: 'manageWorkOrdersTable.assigneeInactiveLegendItem',
    defaultMessage: 'Assignee inactive or no longer has access',
  },
  pastDueLegendItem: {
    id: 'manageWorkOrdersTable.pastDueLegendItem',
    defaultMessage: 'Past Due',
  },
  includeSwitchLabel: {
    id: 'manageWorkOrdersTable.includeSwitchLabel',
    defaultMessage: 'Include Canceled and Completed Work Orders',
  },
});

export default messages;
