import { defineMessages } from 'react-intl';

const messages = defineMessages({
  petAlert: {
    id: 'App.WorkOrder.WorkOrderForm.PetSection.PetAlert',
    defaultMessage: 'Pet Alert',
  },
  petType: {
    id: 'App.WorkOrder.WorkOrderForm.PetSection.petType',
    defaultMessage: 'Pet Type',
  },
  petBreed: {
    id: 'App.WorkOrder.WorkOrderForm.PetSection.petBreed',
    defaultMessage: 'Breed',
  },
  petTag: {
    id: 'App.WorkOrder.WorkOrderForm.PetSection.petTag',
    defaultMessage: 'Pet Tag',
  },
  willPetBeCaged: {
    id: 'App.WorkOrder.WorkOrderForm.PetSection.willPetBeCaged',
    defaultMessage:
      'Will the pet be caged or closed into a room not effected by work order?*',
  },
  yes: {
    id: 'App.WorkOrder.WorkOrderForm.PetSection.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.WorkOrder.WorkOrderForm.PetSection.no',
    defaultMessage: 'No',
  },
  note: {
    id: 'App.WorkOrder.WorkOrderForm.PetSection.note',
    defaultMessage: 'NOTE: ',
  },
  editNote: {
    id: 'App.WorkOrder.WorkOrderForm.PetSection.editNote',
    defaultMessage:
      // eslint-disable-next-line max-len
      'To edit or add pet information to this work order, you will need to edit their household information on the resident profile page.',
  },
});

export default messages;
