import React, { useState } from 'react';

import { formatSqFtStr, getLatestStartDate } from '../helpers';
import { useSqFtHistoryDelete, useSqFtHistorySave } from '../hooks';

import UnitSqFtInformationForm from '../UnitSqFtInformationForm';
import UnitSqFtInformationHistory from '../UnitSqFtInformationHistory';
import ElementWithPermissions from '../../../../components/ElementWithPermissions';

const UnitSqFtInformationList = ({
  label,
  editable,
  sqFt,
  histories,
  selectedProperty,
  unitId,
  type,
  handleRefresh,
  refresh,
  intl,
  open,
  setOpen,
  refreshUnitCallback,
}: Object) => {
  const [openForm, setOpenForm] = useState(false);
  const [editing, setEditing] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const closeAll = () => {
    setOpen(null);
    toggleAndResetForm();
  };

  const toggleAndResetForm = (open: boolean = false) => {
    setOpenForm(open);
    setInitialValues({});
  };

  const { isDeleting, handleDeleteHistory } = useSqFtHistoryDelete({
    selectedProperty,
    unitId,
    type,
    refreshUnitCallback,
  });

  const { handleSaveHistory } = useSqFtHistorySave({
    selectedProperty,
    unitId,
    type,
    refreshUnitCallback,
  });

  const saveAndRefreshCallback = async (payload: Object) => {
    await handleSaveHistory(payload);
    handleRefresh(!refresh);
    toggleAndResetForm();
    setEditing(false);
  };

  const deleteAndRefreshCallback = async (id: string) => {
    await handleDeleteHistory(id);
    handleRefresh(!refresh);
    toggleAndResetForm();
    setEditing(false);
  };

  const latestStartDate = getLatestStartDate(histories);
  return (
    <div className="panel panel-default no-border-top">
      <div className="panel-heading">
        <div className="panel-title">
          <div className="container-fluid">
            <div className="row">
              {open === type ? (
                <React.Fragment>
                  <div className="col-xs-2">
                    <label>
                      <strong>{label}</strong>
                    </label>
                  </div>
                  <div className="col-xs-3">
                    <label>
                      <strong>SqFt</strong>
                    </label>
                  </div>
                  <div className="col-xs-3 text-center">
                    <label>
                      <strong>Effective Date</strong>
                    </label>
                  </div>
                  <div className="col-xs-2 text-center">
                    <label>
                      <strong>Actions</strong>
                    </label>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="col-md-5">
                    <label>{label}</label>
                  </div>
                  <div className="col-md-5">{formatSqFtStr(sqFt)}</div>
                </React.Fragment>
              )}

              {editable ? (
                <div className="col-md-2">
                  <div className="panel-button">
                    <ElementWithPermissions
                      scope={['manage-units-edit-squarefeet']}
                    >
                      {open !== type ? (
                        <button
                          className="btn btn-text btn-text--small text-right"
                          onClick={() => setOpen(type)}
                          disabled={open !== null}
                        >
                          Edit
                          <i className="icon et-pencil" />
                        </button>
                      ) : (
                        <button
                          className="btn btn-text btn-text--small text-right"
                          onClick={() => closeAll()}
                        >
                          Close
                          <i className="icon et-delete-3" />
                        </button>
                      )}
                    </ElementWithPermissions>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {open === type ? (
            <div className="panel-collapse">
              <div className="panel-body">
                <div className="container-fluid">
                  {histories ? (
                    <UnitSqFtInformationHistory
                      histories={histories}
                      isDeleting={isDeleting}
                      handleDeleteHistory={deleteAndRefreshCallback}
                      handleSetEditing={setEditing}
                      setInitialValues={setInitialValues}
                      toggleFormCallback={setOpenForm}
                      handleRefresh={handleRefresh}
                    />
                  ) : null}
                  {openForm ? (
                    <UnitSqFtInformationForm
                      toggleFormCallback={setOpenForm}
                      histories={histories}
                      initialValues={initialValues}
                      handleSaveHistory={saveAndRefreshCallback}
                      handleSetEditing={setEditing}
                      handleRefresh={handleRefresh}
                      latestStartDate={latestStartDate}
                      intl={intl}
                      editing={editing}
                    />
                  ) : null}
                  <div>
                    <a
                      onClick={() => toggleAndResetForm(true)}
                      className="btn-text"
                      disabled={openForm}
                    >
                      + Add New {label}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UnitSqFtInformationList;
