import moment from 'moment';
import { useState, useEffect } from 'react';
import { isEmpty, isNil, is } from 'ramda';

import ActivityService from '../../services/activityService';

// Pull in events
export const useAsyncAssigneeEvents = (
  selectedProperty: Object,
  assignedToId: string = 'all',
  dates: ?Object,
) => {
  const [activities, setActivities] = useState([]);
  const [activitiesLoading, setActivitiesLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };

    const fetchOptions = async () => {
      setActivitiesLoading(true);
      if (!isEmpty(selectedProperty) && !isNil(selectedProperty)) {
        const allFutureActivities = assignedToId === 'all';
        let start = allFutureActivities
          ? moment().startOf('day')
          : moment().startOf('month').subtract(7, 'days');
        let end = moment().endOf('month').add(7, 'days');

        if (!isEmpty(dates) && !isNil(dates)) {
          if (is(Array, dates)) {
            if (!allFutureActivities) {
              start = dates[0];
            }
            end = dates[dates.length - 1];
          } else {
            if (!allFutureActivities) {
              start = dates.start;
            }
            end = dates.end;
          }
        }

        const organizationId = selectedProperty?.organizationId ?? '';
        const propertyId = selectedProperty?.id ?? '';
        const activityService = new ActivityService();
        const optionsResponse = await activityService.getByAssignee(
          assignedToId,
          propertyId,
          organizationId,
          { start, end },
          options,
        );
        setActivitiesLoading(false);
        setActivities(optionsResponse);
      }
    };

    fetchOptions();
    return () => abortController.abort();
  }, [selectedProperty, assignedToId, dates, refresh]);

  return {
    activities,
    activitiesLoading,
    refreshFn: () => setRefresh(!refresh),
  };
};
