import React, { useContext, useEffect, useRef, useState } from 'react';
import messages from '../WorkOrderForm/AfterService/messages';
import UploadedPhotoList from './uploadedPhotoList';
import confirm from '../../../components/ConfirmDialogModal';
import { palette } from '@fortress-technology-solutions/fortress-component-library/design';
import {
  Button,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { WorkOrderContext } from '..';
type Props = {
  intl: any,
  workOrderId: string,
  selectedPropertyId: string,
  organizationId: string,
  changeCallback: Function,
  fieldName: string,
  alwaysEnabled: boolean,
};

const MAX_SIZE_MB = 10;
const MAX_SIZE_IN_BYTES = MAX_SIZE_MB * 1000 * 1000;

const MAX_LIMIT_IMAGES = 5;

function MultiPhotoUpload({
  intl,
  workOrderId,
  selectedPropertyId,
  organizationId,
  changeCallback,
  fieldName,
  images,
  alwaysEnabled,
}: Props) {
  const { workOrderToEdit } = useContext(WorkOrderContext);
  const fileInput = useRef(null);
  const handleUploadClick = () => {
    fileInput.current.click();
  };
  useEffect(() => {
    if (images) {
      setValidImages(images);
    }
  }, [images]);

  const [validImages, setValidImages] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [errorMessageKey, setErrorMessageKey] = useState(false);

  const validateImages = (images: FileList) => {
    let error;
    images.forEach((image) => {
      if (image.size >= MAX_SIZE_IN_BYTES) {
        error = 'uploadError';
        return;
      }
    });

    if (Math.abs(images.length + validImages.length) > MAX_LIMIT_IMAGES) {
      error = 'uploadError';
    }
    return error;
  };

  const onChange = async (event) => {
    const files = [...event.target.files];
    const errorMessage = validateImages(files);

    if (errorMessage) {
      setErrorMessageKey(errorMessage);
    } else {
      setErrorMessageKey(null);
      setValidImages([...files, ...validImages]);
      changeCallback(fieldName, [...files, ...validImages]);

      files.forEach((file) => {
        file.path = URL.createObjectURL(file);
      });
    }
  };

  const deletePhoto = async (id, name) => {
    validImages.forEach((img, index) => {
      if (name === img.name) {
        setImagesToDelete([...imagesToDelete, img]);
        const newImages = [...validImages];
        newImages.splice(index, 1);
        setValidImages(newImages);

        if (id) {
          changeCallback('imagesToDelete', [...imagesToDelete, img]);
        }
      }
    });
  };

  const onDeletePhoto = async (id: string, name: string) => {
    confirm(intl.formatMessage(messages.deleteConfirmation), {
      intl,
      centered: true,
    })
      .then(() => {
        deletePhoto(id, name);
      })
      .catch((error) => {});
  };

  const isDisabled = alwaysEnabled
    ? false
    : validImages.length >= 5 || !workOrderToEdit.detailId;

  const buttonStyle = {
    height: '40px',
    marginTop: '5px',
  };

  return (
    <div>
      <UploadedPhotoList images={validImages} onDeletePhoto={onDeletePhoto} />
      <Typography
        sx={{ color: palette.light.grey, marginBottom: '5px', maginTop: '3px' }}
      >
        {intl.formatMessage(messages.photosUploaded, {
          current: validImages.length,
          max: 5,
        })}
      </Typography>
      <div>
        <Button
          onClick={handleUploadClick}
          disabled={isDisabled}
          style={
            isDisabled
              ? {
                  ...buttonStyle,
                }
              : {
                  ...buttonStyle,
                  backgroundColor: 'white',
                }
          }
        >
          <i
            className="icon et-camera"
            style={{ fontSize: 18, marginRight: '8px' }}
          />
          <Typography sx={{ color: isDisabled ? 'white' : palette.main.grey }}>
            {intl.formatMessage(messages.uploadPhotosButton)}
          </Typography>
        </Button>
      </div>

      <input
        className="inputfile inputfile-custom"
        ref={fileInput}
        data-testid="file-input"
        type="file"
        name="file"
        accept=".png, .jpg, .jpeg"
        onChange={onChange}
        multiple
      />
      {errorMessageKey && (
        <div>
          <Typography color={palette.dark.red}>
            {intl.formatMessage(messages['uploadError'])}
          </Typography>
          <ul style={{ color: palette.dark.red }}>
            <li>
              <Typography color={palette.dark.red}>
                {intl.formatMessage(messages['errorFormat'])}
              </Typography>
            </li>
            <li>
              <Typography color={palette.dark.red}>
                {intl.formatMessage(messages['errorSize'])}
              </Typography>
            </li>
            <li>
              <Typography color={palette.dark.red}>
                {intl.formatMessage(messages['errorMaxImages'])}
              </Typography>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default MultiPhotoUpload;
