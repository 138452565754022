import { defineMessages } from 'react-intl';

const messages = defineMessages({
  modalTitle: {
    id: 'App.CamTab.CamStartDateModal.ModalTitle',
    defaultMessage: 'CAM Start Date',
  },
  note: {
    id: 'App.CamTab.CamStartDateModal.Note',
    defaultMessage:
      ' Note: CAM Start Date selected cannot be more than 18 months in the past or 18 months in the future.',
  },
  cancel: {
    id: 'App.CamTab.CamStartDateModal.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.CamTab.CamStartDateModal.Save',
    defaultMessage: 'Save',
  },
});

export default messages;
