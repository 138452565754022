import React from 'react';
import styled from 'styled-components';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import * as constants from './constants';
import validate from './validate';
import {
  renderDateField,
  renderRadioGroupField,
  renderSelectField,
  renderTextField,
  extractCurrentDateFormat,
  requiredString,
  nonEmptyString,
  validDate,
} from '../../../utils/redux-form-helper';
import * as hooks from './hooks';
import Spinner from '../../../components/Spinner';
import messages from '../messages';

const FormGrid = styled.div`
  column-gap: 15px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 10px;
  row-gap: 20px;
`;

const GridItem = styled.div`
  ${({ columnStart, columnEnd, rowStart, rowEnd, justifySelf, alignSelf }) => `
    grid-column-start: ${columnStart};
    grid-column-end: ${columnEnd};
    grid-row-start: ${rowStart};
    grid-row-end: ${rowEnd};
    ${justifySelf ? `justify-self: ${justifySelf};` : ''}
    ${alignSelf ? `align-self: ${alignSelf};` : ''}
  `}
`;

const DateLabelMargin = styled.span`
  & label {
    margin-bottom: 5px;
  }
`;

const yesNoOptions = [
  { value: 'true', text: 'Yes' },
  { value: 'false', text: 'No' },
];
const tenDayClauseOptions = [...yesNoOptions];
const additionalInsuredOptions = [...yesNoOptions];

function InsuranceForm({ organizationId, handleSubmit, onSubmit, intl }) {
  const [
    insuranceTypeOptions,
    insuranceTypeOptionsLoaded,
  ] = hooks.useInsuranceTypeOptions(organizationId);
  const DATE_FORMAT = extractCurrentDateFormat(intl);

  if (!insuranceTypeOptionsLoaded) return <Spinner />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGrid>
        <GridItem columnStart={1} columnEnd={4} rowStart={1} rowEnd={1}>
          <Field
            name="commercialInsuranceTypeId"
            component={renderSelectField}
            options={insuranceTypeOptions}
            selectClassNames="input form-control"
            label={<FormattedMessage {...messages.insuranceTypeField} />}
          />
        </GridItem>
        <GridItem columnStart={4} columnEnd={9} rowStart={1} rowEnd={1}>
          <Field
            name="limits"
            component={renderTextField}
            label={<FormattedMessage {...messages.insuranceLimitsField} />}
            className="input form-control"
            placeholder="Enter Text"
            showLabel
            validate={[requiredString]}
            maxLength="100"
          />
        </GridItem>
        <GridItem columnStart={9} columnEnd={11} rowStart={1} rowEnd={1}>
          <Field
            name="tenDayClause"
            component={renderSelectField}
            options={tenDayClauseOptions}
            selectClassNames="input form-control"
            label={<FormattedMessage {...messages.tenDayClauseField} />}
          />
        </GridItem>
        <GridItem columnStart={1} columnEnd={8} rowStart={2} rowEnd={2}>
          <Field
            name="requirements"
            component={renderTextField}
            label={
              <FormattedMessage {...messages.insuranceRequirementsField} />
            }
            className="input form-control"
            placeholder="Enter Text"
            showLabel
            validate={[nonEmptyString]}
            maxLength="200"
          />
        </GridItem>
        <GridItem columnStart={8} columnEnd={11} rowStart={2} rowEnd={2}>
          <DateLabelMargin>
            <Field
              name="effectiveDate"
              component={renderDateField}
              bsSize="md"
              label={<FormattedMessage {...messages.effectiveDateField} />}
              placeholder={DATE_FORMAT}
              dateFormat={DATE_FORMAT}
              classPicker="modal-fixed-position"
              validate={[validDate]}
            />
          </DateLabelMargin>
        </GridItem>
        <GridItem columnStart={1} columnEnd={4} rowStart={3} rowEnd={3}>
          <DateLabelMargin>
            <Field
              name="expirationDate"
              component={renderDateField}
              bsSize="md"
              label={<FormattedMessage {...messages.expirationDateField} />}
              placeholder={DATE_FORMAT}
              dateFormat={DATE_FORMAT}
              classPicker="modal-fixed-position"
              validate={[validDate]}
            />
          </DateLabelMargin>
        </GridItem>
        <GridItem
          columnStart={5}
          columnEnd={12}
          rowStart={3}
          rowEnd={3}
          alignSelf="center"
        >
          <Field
            name="propertyListedAsAdditionalInsured"
            component={renderRadioGroupField}
            options={additionalInsuredOptions}
            label={
              <FormattedMessage
                {...messages.propertyListedAsAdditionalInsuredField}
              />
            }
            labelClass="padright20"
            className="form-options"
            inline
          />
        </GridItem>
      </FormGrid>
    </form>
  );
}

export default injectIntl(
  reduxForm({
    form: constants.FORM_NAME,
    initialValues: {
      tenDayClause: 'true',
      propertyListedAsAdditionalInsured: 'true',
    },
    validate,
  })(InsuranceForm),
);
