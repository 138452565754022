import { put, select, takeLatest } from 'redux-saga/effects';
import { GET_ALL_REPORTS } from './constants';
import { getAllReportsFail, getAllReportsSuccess } from './actions';
import type { Action, FilterValueAndPagination } from '../App/types';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import ReportsService from '../../services/reportService';
import { renderNoDataToastr } from '../../utils/redux-form-helper';
import type { Saga } from 'redux-saga';

//TODO: still used by ManageDelinquency, ManageRentRoll and ManageCommercialRentRoll
export function* fetchGetAllReportsSaga({
  payload,
}: Action<FilterValueAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const reportService = new ReportsService();
    const response = yield reportService.getAllReports(
      organizationId,
      property.id,
      payload.sorting,
      payload.searchText,
    );
    if (response.results.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getAllReportsSuccess(response.results, response.meta));
  } catch (err) {
    yield put(getAllReportsFail(err));
  }
}

export function* getAllReportsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_REPORTS, fetchGetAllReportsSaga);
}

export default [getAllReportsSaga];
