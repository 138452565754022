import { useMemo } from 'react';
import { HEADERS } from './constants';
import { processTableFilterOptions } from '../../utils/table-helpers';

const useBuildFilterOptions = ({ parsedResults }) => {
  return useMemo(() => {
    const options = {
      amenities: [],
      bedsBaths: [],
      floorPlan: [],
      status: [],
      moveInReadyStatus: [],
      applicantStatus: [],
    };

    if (parsedResults) {
      parsedResults?.forEach((result) => {
        if (result.status) {
          options.status.push(result.status);
        }

        if (result.moveInReadyStatus) {
          options.moveInReadyStatus.push(result.moveInReadyStatus);
        }

        if (result.applicantStatus) {
          options.applicantStatus.push(result.applicantStatus);
        }

        if (result.amenities) {
          options.amenities.push(result.amenities);
        }

        if (result.bedsBaths) {
          options.bedsBaths.push(result.bedsBaths);
        }

        if (result.floorPlan) {
          options.floorPlan.push(result.floorPlan);
        }
      });
    }

    processTableFilterOptions(options);

    return options;
  }, [parsedResults]);
};
export const useHeaders = ({
  hasCommercialFloorPlans,
  hasManageUnitsUpdatePermission,
  parsedResults,
  isAffordableOrMixedProperty,
}) => {
  const filterOptions = useBuildFilterOptions({ parsedResults });

  return useMemo(() => {
    const headerIdsToRemove = [];
    if (hasCommercialFloorPlans === 'ALL') {
      headerIdsToRemove.push('resident');
    } else {
      headerIdsToRemove.push('tenant');
    }

    if (!hasManageUnitsUpdatePermission) {
      headerIdsToRemove.push('actions');
    }

    if (!isAffordableOrMixedProperty) {
      headerIdsToRemove.push('netMaxRent');
    }

    return HEADERS.filter(({ id }) => !headerIdsToRemove.includes(id)).map(
      (header) => {
        return {
          ...header,
          filterOptions: header.filterOptions ?? filterOptions[header.id],
        };
      },
    );
  }, [
    filterOptions,
    hasCommercialFloorPlans,
    hasManageUnitsUpdatePermission,
    isAffordableOrMixedProperty,
  ]);
};

export default useHeaders;
