
import { validatePhoneNumber } from '../../../utils/validations';

import formMessages from '../../../containers/ShortLeaseApplication/messages';

import type { OwnOrRent } from '../../../containers/App/types';


const validate = (values: OwnOrRent, { intl }: Object) => {
  const errors = { };

  if (!errors.lenderPhoneNumber && !validatePhoneNumber(values.lenderPhoneNumber)) {
    errors.lenderPhoneNumber = intl.formatMessage(formMessages.invalidValue);
  }

  if (!errors.landlordPhoneNumber && !validatePhoneNumber(values.landlordPhoneNumber)) {
    errors.landlordPhoneNumber = intl.formatMessage(formMessages.invalidValue);
  }

  return errors;
};

export default validate;
