import { pathOr, pick, reverse } from 'ramda';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { GROUPING_PROPERTIES, PPL_FRIENDLY } from './constants';

export const getPplFieldName = (number: number): string => {
  if (number === 1) {
    return 'onePerson';
  }
  return `${PPL_FRIENDLY[number - 1]}People`;
};

/**
 * Set asides are grouped by their properties that are shown in the Set Asides table's main rows.
 * A set aside may have multiple income limits and effective dates, and they should
 * be displayed in the drawer under the same row of the table.
 * i.e. set aside "50%" may have multiple income limits in its drawer, but it should be a single row in the table
 * @param  {Array<any>} setAsides
 * @returns Array
 */
export const parseSetAsidePrograms = (
  setAsides: Array<Object>,
): Array<Object> => {
  if (!setAsides) {
    return [];
  }

  // Acc in the reduce below is format:
  // [
  //   setAsideProgram: { properties in SetAsideMainProps}, // displayed in the main table rows
  //   fullSetAsides: [ full setAside objects under that main program ] // displayed when expanding a row's drawer
  // ]
  const setAsidesGrouped = setAsides.reduce((acc: Array<any>, setAside) => {
    const setAsideProgram = pick(
      [...GROUPING_PROPERTIES, 'numberOfUnits'],
      setAside,
    );

    const existingGroup = acc.find((group) => {
      if (!group || !group.fullSetAsides) {
        return false;
      }

      return GROUPING_PROPERTIES.every((propName) => {
        const groupPropValue = pathOr(
          null,
          ['setAsideProgram', propName],
          group,
        );
        return setAsideProgram[propName] === groupPropValue;
      });
    });

    if (!existingGroup) {
      acc.push({
        setAsideProgram: {
          id: uuidv4(),
          ...setAsideProgram,
        },
        fullSetAsides: [setAside],
      });
    } else {
      existingGroup.fullSetAsides.push(setAside);
    }

    return acc;
  }, []);

  // Sort the main array by setAsideShortName
  setAsidesGrouped.sort((a, b) => {
    const nameA = pathOr('', ['setAsideProgram', 'setAsideShortName'], a);
    const nameB = pathOr('', ['setAsideProgram', 'setAsideShortName'], b);
    return nameA.localeCompare(nameB, undefined, { sensitivity: 'base' });
  });

  setAsidesGrouped.forEach((setAsideGroup) => {
    const { fullSetAsides } = setAsideGroup;
    // Sort the set aside details by effectiveDate
    fullSetAsides.sort((a, b) => {
      const dateA = pathOr(null, ['effectiveDate'], a);
      const dateB = pathOr(null, ['effectiveDate'], b);
      if (!dateA && !dateB) {
        return 0;
      }

      const momentDateA = dateA ? moment(dateA) : moment(dateB).add(-1, 'day');
      const momentDateB = dateB ? moment(dateB) : moment(dateA).add(-1, 'day');
      return momentDateA.diff(momentDateB);
    });

    // Use the numberOfUnits from the latest, currently effective program
    const fullSetAsidesReversed = reverse(fullSetAsides);
    const setAsideWithNumberOfUnits = fullSetAsidesReversed.find(
      (fullSetAside) => {
        const effectiveDate = pathOr(null, ['effectiveDate'], fullSetAside);
        const numberOfUnits = pathOr(null, ['numberOfUnits'], fullSetAside);
        if (!effectiveDate || numberOfUnits === null) {
          return false;
        }
        return moment(effectiveDate) <= moment();
      },
    );
    if (setAsideWithNumberOfUnits !== undefined) {
      setAsideGroup.setAsideProgram.numberOfUnits =
        setAsideWithNumberOfUnits.numberOfUnits;
    }
  });

  return setAsidesGrouped;
};
