import React from 'react';
import { isNil, pathOr, sortBy, has } from 'ramda';
import moment from 'moment';
import Styled from 'styled-components';

import { Field, FormSection } from 'redux-form';
import { FormattedNumber } from 'react-intl';

import {
  renderCurrencyField,
  renderDateField,
} from '../../../utils/redux-form-helper';
import { dateSort, isValidDateBuilder } from './utils';

const StyledCurrencyFieldColumn = Styled('td')`
  min-width: 125px;
`;

const FloorplanDetail = ({ floorPlan, intl }: Object) => {
  const today = moment.utc().startOf('date');

  const allowances =
    pathOr([], ['fpua'], floorPlan).length > 0
      ? pathOr([], ['fpua'], floorPlan)
      : pathOr([], ['allowances'], floorPlan);
  const hudGrossRents = pathOr([], ['hudGrossRents'], floorPlan);

  const latestAllowanceStartDate = pathOr(
    today.format('MM/DD/YYYY'),
    ['startDate'],
    allowances.find((allowance) => isNil(allowance.endDate)),
  );
  // $FlowFixMe
  const recentlyUpdatedAllowance = sortBy(dateSort, allowances)[0] || {};
  const recentlyUpdatedAllowanceAmount = has(
    'feeAmount',
    recentlyUpdatedAllowance,
  )
    ? pathOr(0, ['feeAmount'], recentlyUpdatedAllowance)
    : pathOr(0, ['utilityAllowanceAmount'], recentlyUpdatedAllowance);

  const latestHudGrossRentStartDate = pathOr(
    today.format('MM/DD/YYYY'),
    ['startDate'],
    hudGrossRents.find((rent) => isNil(rent.endDate)),
  );
  // $FlowFixMe
  const recentlyUpdatedHudGrossRent = sortBy(dateSort, hudGrossRents)[0] || {};
  const recentlyUpdatedHudGrossRentAmount = pathOr(
    0,
    ['feeAmount'],
    recentlyUpdatedHudGrossRent,
  );

  const isValidRentDate = isValidDateBuilder(latestHudGrossRentStartDate);
  const isValidAllowanceDate = isValidDateBuilder(latestAllowanceStartDate);

  return (
    <FormSection name={floorPlan.id}>
      <tr key={floorPlan.id}>
        <td>{floorPlan.internalName}</td>
        <td>{floorPlan.marketingName}</td>
        <td>
          <FormattedNumber
            value={recentlyUpdatedHudGrossRentAmount}
            style="currency" // eslint-disable-line react/style-prop-object
            currency="USD"
          />
        </td>
        <StyledCurrencyFieldColumn>
          <Field
            name={'newHudGrossRent'}
            component={renderCurrencyField}
            messageAsTooltip
            min="0"
            max="9999.99"
            step=".01"
            precision={2}
          />
        </StyledCurrencyFieldColumn>
        <td>
          <Field
            name={'newHudGrossRentStartDate'}
            isValidDate={isValidRentDate}
            component={renderDateField}
            messageAsTooltip
            dateFormat="MM/DD/YYYY"
            bsSize="md"
            utc
          />
        </td>
        <td>
          <FormattedNumber
            value={recentlyUpdatedAllowanceAmount}
            style="currency" // eslint-disable-line react/style-prop-object
            currency="USD"
          />
        </td>
        <StyledCurrencyFieldColumn>
          <Field
            name={'newHudUa'}
            component={renderCurrencyField}
            messageAsTooltip
            min="0"
            max="9999.99"
            step=".01"
            precision={2}
          />
        </StyledCurrencyFieldColumn>
        <td>
          <Field
            name={'newHudUaStartDate'}
            isValidDate={isValidAllowanceDate}
            component={renderDateField}
            messageAsTooltip
            dateFormat="MM/DD/YYYY"
            bsSize="md"
            className="right-calendar"
            utc
          />
        </td>
      </tr>
    </FormSection>
  );
};

export default FloorplanDetail;
