import { useMemo } from 'react';
import { HEADERS } from './constantsV2';
import { COMPLIANCE_TYPES } from './constants';
import {
  CERTIFICATION_TYPE_DISPLAY_NAMES,
  certificationTypeMessagesDisplayNameMap,
  INITIAL_CERT_TYPE,
  MOVE_IN_CERT_TYPE,
} from '../../constants/affordableCertificationTypes';
import { processTableFilterOptions } from '../../utils/table-helpers';
// eslint-disable-next-line max-len
import { affordableComplianceApprovalStatusesMessagesUppercaseMap } from '../../constants/affordableComplianceApprovalStatuses';
import { RD_PROGRAM_NAME } from '../../constants/affordableProgramNames';

const useBuildFilterOptions = ({ results }) => {
  return useMemo(() => {
    const options = {
      certificationType: [],
      certStatus: [],
      complianceApprovalStatus: [],
      complianceDocumentType: [],
      propertyName: [],
      programType: [],
    };

    if (results) {
      results.forEach((result) => {
        if (result.certificationType) {
          const isRDInitial =
            result.certificationType === MOVE_IN_CERT_TYPE &&
            result.programType === RD_PROGRAM_NAME;

          // RD MOVE_IN is called INITIAL
          if (isRDInitial) {
            options.certificationType.push(
              CERTIFICATION_TYPE_DISPLAY_NAMES[INITIAL_CERT_TYPE],
            );
          } else {
            options.certificationType.push(
              CERTIFICATION_TYPE_DISPLAY_NAMES[result.certificationType] ??
                result.certificationType,
            );
          }
        }
        if (result.certStatus) {
          options.certStatus.push(result.certStatus);
        }
        if (result.hasOwnProperty('complianceApprovalStatus')) {
          options.complianceApprovalStatus.push(
            result.complianceApprovalStatus ?? 'None',
          );
        }
        if (result.complianceDocumentType) {
          options.complianceDocumentType.push(result.complianceDocumentType);
        }
        if (result.propertyName) {
          options.propertyName.push(result.propertyName);
        }
        if (result.programType) {
          options.programType.push(result.programType);
        }
      });
    }

    // Remove duplicates, sort, and map to label/value objects
    processTableFilterOptions(options, {
      ...certificationTypeMessagesDisplayNameMap,
      ...affordableComplianceApprovalStatusesMessagesUppercaseMap,
    });

    return options;
  }, [results]);
};
const useHeaders = ({
  results,
  complianceType,
  complianceOverviewReviewedAndNumberOfDays,
  includeNotStartedCerts,
}) => {
  const filterOptions = useBuildFilterOptions({
    results,
  });

  return useMemo(() => {
    const headers = [];

    HEADERS.filter(({ id }) => {
      let removeHeader = true;

      if (
        complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW &&
        complianceOverviewReviewedAndNumberOfDays &&
        ['isReviewed'].includes(id)
      ) {
        return false;
      }

      if (complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW) {
        removeHeader = ![
          'isReviewed',
          'certHistory',
          'isAttentionRequired',
        ].includes(id);
      }

      if (includeNotStartedCerts === false || !includeNotStartedCerts) {
        removeHeader = !['certStatus'].includes(id);
      }

      return removeHeader;
    }).forEach((header) => {
      let finalFilterOptions = header.filterOptions ?? filterOptions[header.id];
      finalFilterOptions = [...(finalFilterOptions ?? [])];

      if (
        complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW &&
        header.id === 'voucherEffectiveDate'
      ) {
        // remove { value: 'attentionRequired' } from filter options
        finalFilterOptions.splice(1, 1);
      }

      headers.push({
        ...header,
        filterOptions: finalFilterOptions,
      });
    });

    return headers;
  }, [
    complianceOverviewReviewedAndNumberOfDays,
    complianceType,
    filterOptions,
    includeNotStartedCerts,
  ]);
};

export default useHeaders;
