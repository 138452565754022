import { validateRequired, validateZipCode } from '../../utils/validations';

const validate = (values: Object, props: Object) => {
  const errors = {};
  const zipCodeMessage = 'Invalid Zip Code / Postal Code';
  /**
   * Required Fields
   */
  if (!values.hasConfirmed) {
    errors.hasConfirmed = 'Confirmation is Required';
  }
  if (!values.mailingAddress) {
    errors.mailingAddress = 'Please Select Mailing Address';
  }

  /**
   * Valid Address Fields
   */
  if (values.mailingAddress === 'forwardingAddress') {
    ['city', 'address1', 'state', 'zipCode'].forEach((fieldName) => {
      if (!validateRequired(fieldName, values)) {
        errors[fieldName] =
          fieldName === 'zipCode' ? zipCodeMessage : 'required';
      }
    });

    if (values?.zipCode && !validateZipCode(values.zipCode)) {
      errors.zipCode = zipCodeMessage;
    }
  }

  return errors;
};

export default validate;
