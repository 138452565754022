import { useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FilterGroup } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import Select from '../Forms/SingleSelect';
import MultiSelect from '../Forms/MultiSelect';
import Checkbox from '../Forms/Checkbox/';

import { cleanUpFormObject } from './utils.js';

import { Props } from './types';

const componentMap = Object.freeze({
  Select,
  Checkbox,
  MultiSelect,
  default: MultiSelect,
});

const RhfFilterGroup = (props: Props) => {
  const { fieldDescriptors, onChange, defaultValues, locale, ...rest } = props;
  const { control, watch, setValue, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    const subscription = watch((data) => onChange?.(cleanUpFormObject(data)));
    return () => subscription.unsubscribe();
  }, [watch, onChange]);

  const fields = (fieldDescriptors ?? []).map((descriptor) => ({
    ...descriptor,
    control,
  }));

  const currentValues = cleanUpFormObject(watch());

  const appliedFilters = useMemo(
    () =>
      fields
        .filter(({ name }) => currentValues[name])
        .flatMap(({ label, name, subLabel, type, options }) => {
          const value = currentValues[name];

          if (Array.isArray(value)) {
            return value.map((individualValue) => {
              const relatedOption =
                (options ?? []).find(
                  (option) => option.value === individualValue,
                ) ?? {};
              return {
                label: `${label} - ${relatedOption?.text ?? individualValue}`,
                onDelete: () => {
                  const inactiveValue = value.filter(
                    (innerValue) => innerValue !== individualValue,
                  );
                  setValue(name, inactiveValue, { shouldTouch: true });
                },
              };
            });
          }

          const relatedOption =
            (options ?? []).find((option) => option.value === value) ?? {};
          return {
            label: `${label} - ${relatedOption?.text ?? value}`,
            onDelete: () => {
              const inactiveValue = defaultValues?.[name];
              setValue(name, inactiveValue, { shouldTouch: true });
            },
          };
        }),
    [currentValues, defaultValues, fields, setValue],
  );

  const onClearAll = useCallback(() => {
    reset();
  }, [reset]);

  const filterGroupProps = {
    componentMap,
    fields,
    appliedFilters,
    onClearAll,
    ...rest,
  };

  return <FilterGroup {...filterGroupProps} />;
};

export default RhfFilterGroup;
