import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import SubmitButton from '../../../../components/SubmitButton';
import messages from '../messages';
import { navigateToUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';
export const EditWorkOrderButtonsRow = ({
  history,
  formatMessage,
  disabled,
  handleSubmit,
  onSubmit,
  isSubmitting,
}: Object) => (
  <Row>
    <Col xs={12}>
      <div className="form-footer padtop20">
        <div className="button-group">
          <Button
            bsStyle="default"
            onClick={() =>
              navigateToUrlWithSelectedPropertyId('/manage-work-orders')
            }
          >
            {formatMessage(messages.cancel)}
          </Button>
          <SubmitButton
            bsStyle="primary"
            disabled={disabled}
            clickHandler={handleSubmit(onSubmit(false))}
            isSubmitting={isSubmitting}
          >
            {formatMessage(messages.save)}
          </SubmitButton>
          <SubmitButton
            bsStyle="primary"
            disabled={disabled}
            clickHandler={handleSubmit(onSubmit(true))}
            isSubmitting={isSubmitting}
          >
            {formatMessage(messages.saveAndPrint)}
          </SubmitButton>
        </div>
      </div>
    </Col>
  </Row>
);
