import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Card } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { OverallAvgRatingSummaryColumn } from '../OverallAvgRatingSummaryColumn';
import { OverallAvgRatingSourceFilterColumn } from '../OverallAvgRatingSourceFilterColumn';
import { OverallAvgRatingLinearProgressColumn } from '../OverallAvgRatingLinearProgressColumn';
import { RatingBreakdown } from '../types';
import Spinner from '../../../components/Spinner';

export type OnSourceChangeHandler = (value: string) => void;

export type Props = {
  avgRating?: number,
  reviewCount?: number,
  sourceCount?: number,
  ratingCount?: number,
  recommendedPercent?: number,
  notRecommendedPercent?: number,
  recommendedCount?: number,
  notRecommendedCount?: number,
  ratingBreakdown?: RatingBreakdown,
  showSpinner?: boolean,
  selectedRatings: 'all' | StarSelection[],
  onRatingSelect: LinearProgressSelectHandler,
  selectedSource: string,
  onSourceChange: OnSourceChangeHandler,
  ratingType: string,
};

const defaultBreakdown = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
};

function OverallAvgRatingCard({
  avgRating = 0,
  reviewCount = 0,
  sourceCount = 0,
  ratingCount = 0,
  recommendedPercent = 0,
  notRecommendedPercent = 0,
  recommendedCount = 0,
  notRecommendedCount = 0,
  ratingBreakdown = defaultBreakdown,
  showSpinner,
  selectedRatings,
  onRatingSelect,
  selectedSource,
  onSourceChange,
  ratingType,
}: Props) {
  if (showSpinner)
    return (
      <Card>
        <Spinner />
      </Card>
    );

  return (
    <Card>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        padding={3}
        spacing={4}
      >
        <Grid item sm={12} md={2}>
          <OverallAvgRatingSummaryColumn
            ratingType={ratingType}
            avgRating={avgRating}
            reviewCount={reviewCount}
            sourceCount={sourceCount}
            ratingCount={ratingCount}
            recommendedPercent={recommendedPercent}
            notRecommendedPercent={notRecommendedPercent}
          />
        </Grid>
        <Grid item sm={12} md={8}>
          <OverallAvgRatingLinearProgressColumn
            ratingType={ratingType}
            recommendedCount={recommendedCount}
            notRecommendedCount={notRecommendedCount}
            ratingBreakdown={ratingBreakdown}
            selectedRatings={selectedRatings}
            onRatingSelect={onRatingSelect}
          />
        </Grid>
        <Grid item sm={12} md={2}>
          <OverallAvgRatingSourceFilterColumn
            selectedSource={selectedSource}
            onSourceChange={onSourceChange}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default OverallAvgRatingCard;
