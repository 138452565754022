import { useMemo } from 'react';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  Select,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  palette,
  grey,
} from '@fortress-technology-solutions/fortress-component-library/design';
import VariableDateControl from '../VariableDateControl/index';
import AppliedFilters from '../../../components/AppliedFilters';
import type {
  ClearAllEventHandler,
  RemoveFilterEventHandler,
  Filter as AppliedFilter,
} from '../../../components/AppliedFilters/types';
import MultiSelectControl from '../../../components/FilterControls/MultiSelectControl';
import { MULTISELECT_OPTIONS } from '../constants';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import type { FilterValues } from '../../../services/reputationManagementService';
import type {
  DateFilterType,
  FilterValuesChangeHandler,
  DateFilterTypeChangeHandler,
} from './types';
import { mapFilterValuesToAppliedFilters } from '../utils';
import * as constants from '../constants';

type Props = {
  values: FilterValues,
  dateFilterType: DateFilterType,
  onFilterValuesChange: FilterValuesChangeHandler,
  onDateFilterTypeChange: DateFilterTypeChangeHandler,
  onClearAll: ClearAllEventHandler,
  onRemoveFilter: RemoveFilterEventHandler,
  ratingType: string,
};

export const ReviewsFilters = ({
  values,
  dateFilterType,
  onFilterValuesChange,
  onDateFilterTypeChange,
  onClearAll,
  onRemoveFilter,
  ratingType,
}: Props) => {
  const appliedFilters: AppliedFilter[] = useMemo(
    () => mapFilterValuesToAppliedFilters(values, MULTISELECT_OPTIONS),
    [values],
  );

  const handleDateRangeChange = (key: string) => (newValue: Object) => {
    onFilterValuesChange?.(
      key,
      typeof newValue === 'string' ? newValue : newValue.toDate(),
    );
  };

  const handleChange = (key: string) => (e: Object) => {
    onFilterValuesChange?.(key, e.target.value);
  };

  return (
    <Grid
      container
      spacing={2}
      padding={1}
      sx={{ border: `1px solid ${grey.lighter}`, marginLeft: 1 }}
    >
      {appliedFilters.length > 0 && (
        <Stack marginTop={1} marginLeft={2}>
          <AppliedFilters
            filters={appliedFilters}
            onRemoveFilter={onRemoveFilter}
            onClearAll={onClearAll}
          />
        </Stack>
      )}
      <Grid item lg={12}>
        <Typography
          sx={{ marginBottom: 1, color: palette.main.blue }}
          variant="h3"
        >
          <FormattedMessage {...messages.filterBy} />
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <VariableDateControl
                label="Date"
                toDate={values.toDate}
                fromDate={values.fromDate}
                timeFrame={values.timeFrame}
                dateFilterType={dateFilterType}
                onDateFilterTypeChange={onDateFilterTypeChange}
                onFromDateChange={handleDateRangeChange('fromDate')}
                onToDateChange={handleDateRangeChange('toDate')}
                onTimeFrameChange={handleChange('timeFrame')}
                exactDatePlaceholder="MM/DD/YYYY"
              />
            </Grid>
            <Grid item xs={4}>
              {ratingType === constants.RECOMMEND_BASED ? (
                <>
                  <Typography
                    marginBottom={1}
                    sx={{ display: 'inline-block' }}
                    variant="h4"
                  >
                    <FormattedMessage {...messages.category} />
                  </Typography>
                  <Select
                    placeholder="All Categories"
                    value={values.category}
                    options={constants.CATEGORY_OPTIONS}
                    onChange={handleChange('category')}
                    fullWidth
                  />
                </>
              ) : (
                <MultiSelectControl
                  name="ratings"
                  label="Ratings"
                  placeholder="All Ratings"
                  aria-label="ratings-filter"
                  options={MULTISELECT_OPTIONS.ratings}
                  onChange={handleChange('ratings')}
                  value={values.ratings}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <Typography
                marginBottom={1}
                sx={{ display: 'inline-block' }}
                variant="h4"
              >
                <FormattedMessage {...messages.status} />
              </Typography>
              <Select
                placeholder="All Status"
                value={values.status}
                options={MULTISELECT_OPTIONS.status}
                onChange={handleChange('status')}
                fullWidth
              />
            </Grid>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};
