import { useQuery, QueryOptions } from 'react-query';
import PropertySpecialsService from '../../services/propertySpecialsService';
import useAbortController from '../../hooks/useAbortController';

const usePropertySpecialsQuery = ({
  propertyId,
  options,
}: {
  propertyId: string,
  options?: QueryOptions,
}) => {
  const queryKey = ['usePropertySpecialsQuery', { propertyId }];
  const abortControllerOptions = useAbortController();

  return useQuery(
    queryKey,
    () =>
      new PropertySpecialsService().getPropertySpecials(
        propertyId,
        abortControllerOptions,
      ),
    { enabled: Boolean(propertyId?.length), ...options },
  );
};

export default usePropertySpecialsQuery;
