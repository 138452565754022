import React from 'react';
import ActivityTableBody from '../ActivityTableBody';
import ActivityTableHeader from '../ActivityTableHeader';
import ActivityTableFooter from '../ActivityTableFooter';
import type { ActivityData } from '../types';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';

type Props = {
  intl: Object,
  columnOrder: string,
  order: string,
  activitiesByProspect: ActivityData,
  page: number,
  limit: number,
  setPage: Function,
  locale: string,
  onEditActivity: Function,
  onSortOrder: Function,
};

function ActivityTable(props: Props) {
  return (
    <Stack>
      <table
        aria-label="Activity Table"
        className="table table-prospects table-striped"
      >
        <ActivityTableHeader
          intl={props.intl}
          columnOrder={props.columnOrder}
          order={props.order}
          isLoading={props.isLoading}
          onSortOrder={props.onSortOrder}
          activitiesByProspect={props.activitiesByProspect}
        />
        <ActivityTableBody
          intl={props.intl}
          locale={props.locale}
          isLoading={props.isLoading}
          onEditActivity={props.onEditActivity}
          handleRefresh={props.handleRefresh}
          activitiesByProspect={props.activitiesByProspect}
        />
      </table>
      <ActivityTableFooter
        page={props.page}
        limit={props.limit}
        setPage={props.setPage}
        isLoading={props.isLoading}
        activitiesByProspect={props.activitiesByProspect}
      />
    </Stack>
  );
}

export default ActivityTable;
