import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import React from 'react';
import { Panel } from 'react-bootstrap';
import { Header, Title } from '../ProfileVisualComponents';

type Props = {
  open: boolean,
  toggleOpen: Function,
  title: string,
  children: any,
  bypassedFields?: string[],
};

const MiniProfileFormPanel = ({
  open,
  toggleOpen,
  title,
  children,
  bypassedFields,
}: Props) => {
  return (
    <Panel expanded={open} onToggle>
      <Header>
        <Title>
          <div onClick={() => toggleOpen()} className="container-fluid">
            <div className="col-md-11 name-display">
              <h5 className="h5-alt disp-inline">
                <strong>{title}</strong>
              </h5>
            </div>
            <div className="col-md-1">
              <div className="panel-button">
                <i className={`icon ${open ? 'et-minus' : 'et-plus'}`} />
              </div>
            </div>
            <div className="col-md-12">
              {bypassedFields && bypassedFields.length > 0 && (
                <Typography variant="helper" color="#D70000">
                  *Required field{bypassedFields.length === 1 ? ' is' : 's are'}{' '}
                  empty: {bypassedFields.join(', ')}
                </Typography>
              )}
            </div>
          </div>
        </Title>
        <Panel.Collapse>
          <Panel.Body>{children}</Panel.Body>
        </Panel.Collapse>
      </Header>
    </Panel>
  );
};

export default MiniProfileFormPanel;
