import { createSelector } from 'reselect';
import { pathOr, pathEq, uniq } from 'ramda';

import type { GlobalState } from '../App/types';

const getManageDocumentsState = (state: GlobalState) => state.manageDocuments;
const getApplicationProfileState = (
  state: GlobalState,
  customerType: ?string = null,
) =>
  customerType === 'resident'
    ? state.residentProfile
    : state.applicationProfile;
const getCurrentLocale = (state: GlobalState) => state.languageProvider.locale;

export const getDocumentTypesOptions = createSelector(
  [getManageDocumentsState, getCurrentLocale],
  ({ documentTypes }, locale) => {
    const documentTypesOptions = uniq(documentTypes)
      .filter((doc) => doc.showForManualUploads)
      .map((documentType) => ({
        value: documentType.id,
        text: documentType.translations[locale] || documentType.name,
        disabled: false,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
    documentTypesOptions.unshift({
      value: 'default',
      text: 'Choose an option',
      disabled: true,
    });
    return documentTypesOptions;
  },
);
export const getApplicantsOptions = createSelector(
  [getApplicationProfileState],
  (profile: Object) => {
    const residentApp = pathOr(null, ['applicationInfo'], profile);
    const applicantApp = pathOr(null, ['currentApplication'], profile);
    if (!profile && (!residentApp || !applicantApp)) return [];
    const application = residentApp ? residentApp : applicantApp;
    const applicants = pathOr([], ['applicants'], application);

    const applicantsOptions = applicants
      .filter(
        (applicant) => !pathEq(['resident', 'status'], 'Prior', applicant),
      )
      .map((applicant) => {
        if (applicant.applicantCustomer) {
          const customer = applicant.applicantCustomer.customer;
          return {
            value: applicant.id,
            text: `${customer.firstName} ${customer.lastName}`,
            disabled: false,
          };
        } else if (applicant.applicantPet) {
          const pet = applicant.applicantPet;
          return {
            value: applicant.id,
            text: `${pet.name}`,
            disabled: false,
          };
        } else {
          const minor = applicant.applicantMinor;
          const firstName = pathOr('', ['firstName'], minor);
          const lastName = pathOr('', ['lastName'], minor);
          return {
            value: applicant.id,
            text: `${firstName} ${lastName}`,
            disabled: false,
          };
        }
      });
    applicantsOptions.unshift({
      value: 'default',
      text: 'Choose an option',
      disabled: true,
    });
    return applicantsOptions;
  },
);

export const getCurrentResidentState = (state: GlobalState) =>
  state.residentProfile;

export const isResidentStage = createSelector(
  getCurrentResidentState,
  (residentState) => {
    // $FlowFixMe
    return pathOr(null, ['residentInfo', 'id'], residentState);
  },
);
