export const GET_PORTFOLIO = 'app/Portfolio/GET_PORTFOLIO';
export const GET_PORTFOLIO_SUCCESS = 'app/Portfolio/GET_PORTFOLIO_SUCCESS';
export const GET_PORTFOLIO_ERROR = 'app/Portfolio/GET_PORTFOLIO_ERROR';

export const CLEAN_LOADED_PORTFOLIO = 'app/Portfolio/CLEAN_LOADED_PORTFOLIO';

export const HEADER_ROWS = [
  {
    headers: [
      {
        id: '',
        title: '',
        props: {
          colSpan: '2',
        },
        classes: 'bg-lighter-gray',
        order: null,
      },
      {
        id: '',
        title: 'Occupancy',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '3',
        },
      },
      {
        id: '',
        title: 'Collections',
        classes: 'header-darker',
        order: null,
      },
      {
        id: '',
        title: 'PTD',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '4',
        },
      },
      {
        id: '',
        title: 'Work Orders',
        classes: 'header-darker',
        order: null,
        props: {
          colSpan: '2',
        },
      },
    ],
  },
  {
    headers: [
      {
        id: 'propertyName',
        title: 'Property',
        classes: 'text-gray--darker bg-lighter-gray',
      },
      {
        id: 'numTotalUnits',
        title: 'Total Units',
        classes: 'text-gray--darker bg-lighter-gray',
      },
      {
        id: 'numOccupiedUnits',
        title: 'Occupied Units',
      },
      {
        id: 'currentOccupancy',
        title: 'Current Occupancy %',
      },
      {
        id: 'netOccupancy',
        title: '30-Day Net Occupancy %',
      },
      {
        id: 'delinquencyPercent',
        title: 'DQ %',
        classes: 'header-darker--var',
      },
      {
        id: 'delinquencyChargesTotal',
        title: 'Charges',
      },
      {
        id: 'mtdDeposits',
        title: 'Deposits',
      },
      {
        id: 'pendingDeposits',
        title: 'Pending Deposits',
      },
      {
        id: 'concessions',
        title: 'Concessions',
      },
      {
        id: 'numWorkOrdersOpen',
        title: 'Open Work Orders',
        classes: 'header-darker--var',
      },
      {
        id: 'avgWorkOrderDaysOpen',
        title: 'Average Days Open',
        classes: 'header-darker--var',
      },
    ],
  },
];

export const PERCENT_FORMAT_OBJ = {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const CURRENCY_USD = {
  style: 'currency',
  currency: 'USD',
};
