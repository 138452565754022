import { Tooltip } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  green,
  purple,
  red,
  blue,
} from '@fortress-technology-solutions/fortress-component-library/design';
import React from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import messages from './messages';

type Props = {
  status: 'Accepted' | 'Waiting Approval' | 'Rejected' | 'No Change',
  updatedBy: { name: string, date: string },
  intl: IntlShape,
};

const STATUS_COLOR_STYLES = {
  Accepted: css`
    color: ${green.dark};
    background-color: ${green.lighter};
  `,
  'Waiting Approval': css`
    color: ${purple.dark};
    background-color: ${purple.lighter};
  `,
  Rejected: css`
    color: ${red.dark};
    background-color: ${red.lighter};
  `,
  'No Change': css`
    color: ${blue.dark};
    background-color: ${blue.lighter};
  `,
};

const Badge = styled.div`
  padding: 0px 4px;
  border-radius: 4px;
  display: inline;

  ${({ colors }) => colors}
`;

function SmartPriceStatus(props: Props) {
  return (
    <Tooltip
      placement="top"
      title={
        props.updatedBy
          ? props.intl.formatMessage(messages.updatedBy, props.updatedBy)
          : ''
      }
      disableHoverListener={!props.updatedBy}
      variant="dark"
      style={{ width: 'auto' }}
    >
      <Badge colors={STATUS_COLOR_STYLES[props.status]}>{props.status}</Badge>
    </Tooltip>
  );
}

export default injectIntl(SmartPriceStatus);
