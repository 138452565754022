import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { intersection, pathOr } from 'ramda';

import StatusBox from './StatusBox';
import messages from './messages';
import ScreeningStatusModal from '../../../../components/ScreeningStatusModal';
import AdverseActionLetterModal from './AdverseActionLetterModal';
import Spinner from '../../../../components/Spinner';
import {
  SCREENING_RESULTS_APPROVED,
  SCREENING_RESULTS_NEEDING_NOTES,
} from '../../constants';
import { red } from '@fortress-technology-solutions/fortress-component-library/design';

const ADVERSE_LETTER_APPROVED_WITH_CONDITIONS_FILENAME =
  'Adverse Action Letter - Approved with conditions.pdf';
const ADVERSE_LETTER_DECLINED_FILENAME = 'Adverse Action Letter - Denied.pdf';

type Props = {
  intl: any,
  screening: Object,
  applicationId: string,
  onEditCallback?: ?Function,
  onResubmitCallback?: ?Function,
  refreshActivityTable?: ?Function,
  saveScreeningResults?: Function,
  generateAdverseActionLetter?: Function,
  screeningLetterTypes: Array<any>,
  currentUser: Object,
  isResident: boolean,
  promptToaster: Function,
  isTransUnionActive: boolean,
  transunionOffLeaseAppOff: boolean,
  adverseActionDeadlineDays?: number | null,
};

type ScreeningStatusBoxProps = {
  intl: any,
  screening: Object,
  onResubmitCallback?: ?Function,
  updateMonthlyRentAmount: Function,
  monthlyRentAmount?: ?string | ?number,
  initialRentAmount?: ?string | ?number,
  error?: ?string,
  isResident?: boolean,
};

type ResultsStatusBoxProps = {
  intl: any,
  screening: Object,
  onEditCallback: ?Function,
  onGenerateScreeningLetter?: Function,
  checkPermission: boolean,
  checkApprovedPermission: boolean,
  isResident: boolean,
};

type State = {
  screeningLetterOpen: boolean,
  screeningResultsOpen: boolean,
  finalStatus: Object,
  finalStatusSaving: boolean,
  isClosing: boolean,
  monthlyRentAmount?: ?string | ?number,
  error: ?string,
};

type ResultsProps = {
  type: string,
  heading: string,
  status?: any,
  subStatus?: string,
  meta?: string,
  onEditCallback?: ?Function,
  onGenerateScreeningLetter?: Function,
  finalDecisionNote?: string,
};
// prettier-ignore
const screeningTypes: Object = {
  '1': 'approved',
  '2': 'approved', // Low accept
  '3': 'conditions',
  '4': 'declined',
  '5': 'conditions', // Refer
  '6': 'disabled', // Pending
  '7': 'error',
  '8': 'unavailable',
};

export const ScreeningStatusBox = ({
  intl,
  screening,
  onResubmitCallback,
  updateMonthlyRentAmount,
  monthlyRentAmount,
  initialRentAmount,
  error,
  isResident,
}: ScreeningStatusBoxProps) => {
  const MISSING_APPLICATION_DETAILS_ERROR = 1;

  const screen: Object = screening.results || {};
  const hasScreening = screen && screen.screen;
  const screenError = screening && screening.error;
  if (+screenError === MISSING_APPLICATION_DETAILS_ERROR) {
    return (
      <StatusBox
        intl={intl}
        type="unavailable"
        heading={intl.formatMessage(messages.transUnionScreening)}
        subStatus={intl.formatMessage(messages.missingApplications)}
        isResident={isResident}
      />
    );
  }

  if (screenError) {
    return (
      <StatusBox
        intl={intl}
        type="error"
        heading={intl.formatMessage(messages.transUnionScreening)}
        subStatus="Unable to retrieve screening"
        error={screening.results}
      />
    );
  }

  if (!hasScreening) {
    return (
      <StatusBox
        intl={intl}
        type="upload"
        heading={intl.formatMessage(messages.transUnionScreening)}
        updateMonthlyRentAmount={updateMonthlyRentAmount}
        status={
          <a onClick={onResubmitCallback} disabled={!monthlyRentAmount}>
            <FormattedMessage {...messages.submit} />
          </a>
        }
        error={error}
        isResident={isResident}
        initialRentAmount={initialRentAmount}
      />
    );
  }

  const {
    screen: { resultId, resultMessage, reasons, createdAt, createdBy },
  } = screen;
  const props: Object = {
    type: screeningTypes[resultId],
    heading: intl.formatMessage(messages.transUnionScreening),
    status: resultMessage ? resultMessage : '',
    onResubmitCallback: monthlyRentAmount ? onResubmitCallback : null,
    updateMonthlyRentAmount,
    monthlyRentAmount,
    initialRentAmount,
    error,
    isResident,
    checkPermission: true,
    checkApprovedPermission: true,
    reasons,
  };

  if (createdBy) {
    props.meta = `
      ${intl.formatMessage(messages.submittedOn)}${' '}
      ${moment(createdAt).format('MM/DD/YYYY')}
    `;
  }

  return <StatusBox {...props} intl={intl} />;
};

export const ResultsStatusBox = ({
  intl,
  screening,
  onEditCallback,
  checkPermission,
  checkApprovedPermission,
  isResident,
  onGenerateScreeningLetter,
}: ResultsStatusBoxProps) => {
  const screen: Object = screening.results || {};
  const screenStatusCode = pathOr(0, ['screen', 'resultId'], screen);
  const hasFinalStatus = screen && screen.status;
  const finalStatusCode = parseInt(
    pathOr(0, ['status', 'resultId'], screen),
    10,
  );
  const finalStatusThreeOrFour = finalStatusCode === 3 || finalStatusCode === 4;
  const GenerateAdverseActionLetter = ({ onGenerateScreeningLetter }) => (
    <div className="col-sm-6">
      <div className="row">
        <div className="col-sm-2" />
        <div className="col-sm-10">
          <a
            className="btn-text"
            role="button"
            onClick={onGenerateScreeningLetter}
          >
            <FormattedMessage {...messages.createAdverseActionLetter} />
          </a>
        </div>
      </div>
    </div>
  );

  if (!screen || !screen.screen || +screenStatusCode === 7) {
    return (
      <>
        <StatusBox
          intl={intl}
          type="disabled"
          heading={intl.formatMessage(messages.finalScreeningStatus)}
          subStatus={intl.formatMessage(messages.firstSubmit)}
        />
        {finalStatusThreeOrFour && (
          <GenerateAdverseActionLetter
            onGenerateScreeningLetter={onGenerateScreeningLetter}
          />
        )}
      </>
    );
  }

  if (!hasFinalStatus) {
    return (
      <>
        <StatusBox
          intl={intl}
          type="disabled"
          heading={intl.formatMessage(messages.finalScreeningStatus)}
          status={
            !isResident && (
              <button
                className="btn btn-tertiary"
                onClick={onEditCallback}
                disabled={screenStatusCode > 5}
              >
                <FormattedMessage {...messages.makeDecision} />
              </button>
            )
          }
        />
        {finalStatusThreeOrFour && (
          <GenerateAdverseActionLetter
            onGenerateScreeningLetter={onGenerateScreeningLetter}
          />
        )}
      </>
    );
  }

  const {
    status: {
      resultId,
      resultMessage,
      createdBy,
      updatedAt,
      finalDecisionNote,
    },
  } = screen;
  const props: ResultsProps = {
    type: screeningTypes[resultId],
    heading: intl.formatMessage(messages.finalScreeningStatus),
    status: resultMessage ? resultMessage : '',
    onEditCallback: !isResident ? onEditCallback : null,
    onGenerateScreeningLetter: onGenerateScreeningLetter,
    checkPermission,
    checkApprovedPermission,
    finalDecisionNote,
  };

  if (createdBy) {
    props.meta = `
      ${intl.formatMessage(messages.on)}${' '}
      ${moment(updatedAt).format('MM/DD/YYYY')}
      ${intl.formatMessage(messages.by)}${' '}
      ${createdBy.firstName} ${createdBy.lastName}
    `;
  }

  return (
    <>
      <StatusBox {...props} intl={intl} />{' '}
      {finalStatusThreeOrFour && (
        <GenerateAdverseActionLetter
          onGenerateScreeningLetter={onGenerateScreeningLetter}
        />
      )}
    </>
  );
};

class CreditCriminalStatus extends Component<Props, State> {
  state = {
    screeningLetterOpen: false,
    screeningResultsOpen: false,
    finalStatus: {},
    finalDecisionNote: null,
    finalStatusSaving: false,
    requireFinalDecisionNote: false,
    isClosing: false,
    monthlyRentAmount: null,
    error: null,
    isFlagActive: false,
  };
  updateMonthlyRentAmount = (e: Event, val: number) => {
    this.setState({
      error: null,
      monthlyRentAmount: val,
    });
  };
  submitScreening = () => {
    const { intl } = this.props;
    const { monthlyRentAmount } = this.state;

    const { onResubmitCallback, applicationId } = this.props;

    if (!monthlyRentAmount || +monthlyRentAmount <= 0) {
      this.setState({
        error: intl.formatMessage(messages.screeningError),
      });
      return false;
    }
    // $FlowFixMe

    this.setState({
      screeningResultsOpen: true,
      finalStatus: {},
      finalStatusSaving: false,
      isClosing: false,
      error: null,
    });
    onResubmitCallback(
      applicationId,
      monthlyRentAmount,
      this.props.refreshActivityTable,
    );
  };
  toggleScreeningLetter = () => {
    this.setState({
      screeningLetterOpen: !this.state.screeningLetterOpen,
    });
  };
  toggleScreeningResults = () => {
    this.setState({
      screeningResultsOpen: !this.state.screeningResultsOpen,
      finalStatus: {},
      finalStatusSaving: false,
      finalDecisionNote: null,
      isClosing: false,
      isFlagActive: true,
    });
  };
  updateFinalStatusField = (event: Object) => {
    try {
      const newStatus = JSON.parse(event.target.value);

      // Check if final approval notes are required
      // User must enter notes if either:
      //  1. final approval status is "Denied" or "Approved with Conditions"
      //  2. final approval status goes agains TU status
      const selectedFinalStatusCode = +pathOr(null, ['statusId'], newStatus);
      const finalCodeIsApproval =
        SCREENING_RESULTS_APPROVED.indexOf(selectedFinalStatusCode) !== -1;
      const screening = this.props.screening;
      const screeningCode = +pathOr(
        0,
        ['results', 'screen', 'resultId'],
        screening,
      );
      const screeningCodeIsApproval =
        SCREENING_RESULTS_APPROVED.indexOf(screeningCode) !== -1;
      const requireNotes = !!(
        (
          selectedFinalStatusCode &&
          // conditions, declined, refer => need notes:
          (SCREENING_RESULTS_NEEDING_NOTES.indexOf(selectedFinalStatusCode) !==
            -1 ||
            finalCodeIsApproval !== screeningCodeIsApproval)
        ) // final decision goes agains TU
      );
      this.setState({
        finalStatus: newStatus,
        requireFinalDecisionNote: requireNotes,
      });
    } catch (e) {}
  };
  updateFinalDecisionNote = (event: Object) => {
    this.setState({
      finalDecisionNote: event.target.value,
    });
  };
  toggleSaveScreening = () => {
    this.setState({
      finalStatusSaving: !this.state.finalStatusSaving,
      finalStatus: !this.state.finalStatusSaving ? this.state.finalStatus : {},
      isClosing: false,
      error: null,
    });
  };
  toggleCloseScreeningLetter = () => {
    this.setState({
      screeningLetterOpen: false,
    });
  };
  toggleClose = () => {
    this.setState({
      isClosing: !this.state.isClosing,
    });
  };
  parseLetterTypeAndFileName = (
    resultId: number,
    screeningLetterTypes: Array<any>,
  ): Object => {
    // Approved with Conditions
    if (resultId === 3) {
      return {
        fileName: ADVERSE_LETTER_APPROVED_WITH_CONDITIONS_FILENAME,
        letterType: pathOr(
          '',
          ['id'],
          screeningLetterTypes.find(
            (letter) =>
              letter.name ===
              'Adverse Action Letter - Approved with conditions',
          ),
        ),
      };
      // Declined
    } else if (resultId === 4) {
      return {
        fileName: ADVERSE_LETTER_DECLINED_FILENAME,
        letterType: pathOr(
          '',
          ['id'],
          screeningLetterTypes.find(
            (letter) => letter.name === 'Adverse Action Letter - Denied',
          ),
        ),
      };
    }
    return {
      fileName: '',
      letterType: '',
    };
  };
  onGenerateAdverseActionLetter = (values: Object) => {
    const {
      applicationId,
      generateAdverseActionLetter,
      screeningLetterTypes,
      screening: {
        results: { status },
      },
    } = this.props;
    const resultId = parseInt(status.resultId, 10);
    const { letterType, fileName } = this.parseLetterTypeAndFileName(
      resultId,
      screeningLetterTypes,
    );
    generateAdverseActionLetter({
      fileName,
      applicationId,
      letterType,
      ...values,
    });
    this.setState({
      screeningLetterOpen: false,
    });
  };
  saveScreening = () => {
    const { applicationId, saveScreeningResults, refreshActivityTable } =
      this.props;
    const { isFlagActive } = this.state;

    // $FlowFixMe
    saveScreeningResults({
      applicationId,
      ...this.state.finalStatus,
      finalDecisionNote: this.state.finalDecisionNote,
      isFlagActive,
      refreshActivityTable,
    });
    this.setState({
      finalStatusSaving: false,
      screeningResultsOpen: false,
      isClosing: false,
      error: null,
    });
  };
  checkUserPermissions = (testScope: Array<string>) => {
    const { currentUser } = this.props;
    const userScopes = currentUser.permissions.map(
      (permission) => permission.scope,
    );
    return intersection(testScope, userScopes).length > 0;
  };

  render() {
    const {
      intl,
      screening,
      isResident,
      promptToaster,
      transunionOffLeaseAppOff,
      adverseActionDeadlineDays,
    } = this.props;

    const checkPermission = this.checkUserPermissions([
      'screening-application-save-final-decision',
    ]);
    const checkApprovedPermission = this.checkUserPermissions([
      'screening-application-can-make-final-decision-on-status-approved',
    ]);
    const rentAmount = pathOr(
      0.0,
      ['results', 'screen', 'rentAmount'],
      screening,
    );
    const screeningResultsReasons = pathOr(
      {},
      ['results', 'screen', 'reasons'],
      screening,
    );
    const finalScreeningStatusResultMessage =
      screening?.results?.status?.resultMessage;

    return (
      <div className="creditstatus-page">
        {transunionOffLeaseAppOff ? (
          <div className="notes-expanded container-fluid">
            {this.props.isTransUnionActive && (
              <div className="creditstatus-container row">
                {screening && !screening.submitting ? (
                  <>
                    <ScreeningStatusBox
                      intl={intl}
                      screening={screening}
                      onResubmitCallback={this.submitScreening}
                      updateMonthlyRentAmount={this.updateMonthlyRentAmount}
                      monthlyRentAmount={this.state.monthlyRentAmount}
                      initialRentAmount={rentAmount}
                      error={this.state.error}
                      isResident={isResident}
                    />
                    <div className="right-arrow">
                      <i className="icon et-chevron-right" />
                    </div>
                    <ResultsStatusBox
                      intl={intl}
                      screening={screening}
                      onEditCallback={this.toggleScreeningResults}
                      checkPermission={checkPermission}
                      checkApprovedPermission={checkApprovedPermission}
                      isResident={!!isResident}
                      onGenerateScreeningLetter={this.toggleScreeningLetter}
                    />
                  </>
                ) : (
                  <div className="row">
                    <Spinner />
                  </div>
                )}
                {screening.message && (
                  <div style={{ textAlign: 'center' }}>
                    <small style={{ color: red.main }} className="unavailable">
                      {screening.message}
                    </small>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="notes-expanded container-fluid">
            <div className="creditstatus-container row">
              {screening && !screening.submitting ? (
                <>
                  <ScreeningStatusBox
                    intl={intl}
                    screening={screening}
                    onResubmitCallback={this.submitScreening}
                    updateMonthlyRentAmount={this.updateMonthlyRentAmount}
                    monthlyRentAmount={this.state.monthlyRentAmount}
                    initialRentAmount={rentAmount}
                    error={this.state.error}
                    isResident={isResident}
                  />
                  <div className="right-arrow">
                    <i className="icon et-chevron-right" />
                  </div>
                  <ResultsStatusBox
                    intl={intl}
                    screening={screening}
                    onEditCallback={this.toggleScreeningResults}
                    checkPermission={checkPermission}
                    checkApprovedPermission={checkApprovedPermission}
                    isResident={!!isResident}
                    onGenerateScreeningLetter={this.toggleScreeningLetter}
                  />
                </>
              ) : (
                <div className="row">
                  <Spinner />
                </div>
              )}
              {screening.message && (
                <div style={{ textAlign: 'center' }}>
                  <small style={{ color: '#D70000' }} className="unavailable">
                    {screening.message}
                  </small>
                </div>
              )}
            </div>
          </div>
        )}
        <AdverseActionLetterModal
          intl={intl}
          show={this.state.screeningLetterOpen}
          screeningResultReasons={screeningResultsReasons}
          toggleClose={this.toggleCloseScreeningLetter}
          onSubmit={this.onGenerateAdverseActionLetter}
          promptToaster={promptToaster}
          adverseActionDeadlineDays={adverseActionDeadlineDays}
          finalScreeningStatusResultMessage={finalScreeningStatusResultMessage}
        />
        <ScreeningStatusModal
          intl={intl}
          show={this.state.screeningResultsOpen}
          dismiss={this.toggleScreeningResults}
          toggleClose={this.toggleClose}
          onResubmitCallback={this.submitScreening}
          saveCallback={this.saveScreening}
          updateFinalStatusField={this.updateFinalStatusField}
          updateFinalDecisionNote={this.updateFinalDecisionNote}
          finalDecisionNote={this.state.finalDecisionNote}
          requireFinalDecisionNote={this.state.requireFinalDecisionNote}
          screening={screening}
          values={this.state.finalStatus}
          finalStatusSavingCallback={this.toggleSaveScreening}
          finalStatusSaving={this.state.finalStatusSaving}
          isClosing={this.state.isClosing}
          checkPermission={checkPermission}
          checkApprovedPermission={checkApprovedPermission}
        />
      </div>
    );
  }
}

export default CreditCriminalStatus;
