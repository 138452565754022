import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import RenewalOfferForm from './RenewalOfferForm/index.js';
import PreviousOffersDetails from './PreviousOffersDetails/index.js';
import {
  getRenewalOfferTerms,
  createRenewalOffer,
  getPreviousRenewalOffers,
  clearLoadedRenewalOffers,
} from './actions.js';
import { getOneResident } from '../ResidentProfile/actions';
import { getPropertyTransactionCodes } from '../../components/CreateTransaction/actions';
import messages from './messages.js';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';

type Props = {
  residentId: string,
  leaseTermOptions: Array<Object>,
  residentName: string,
  unit: Object,
  leaseId: string,
  previousOffers: Array<Object>,
};

type InjectedProps = {
  intl: any,
  actions: Object,
  history: Object,
};

type HeaderProps = {
  name: string,
  unitNumber: string,
  goBack: Function,
};

export const RenewalOfferHeader = ({
  name,
  unitNumber,
  goBack,
}: HeaderProps) => (
  <div data-test="generate-renewal-offer-header">
    <div className="row">
      <div className="col-xs-6 col-sm-12">
        {goBack && (
          <a className="btn-text" onClick={goBack}>
            <i className="et-chevron-left" />
            <FormattedMessage {...messages.goBack} />{' '}
          </a>
        )}
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12 col-sm-12">
        <h1>
          <FormattedMessage {...messages.generateRenewalOffer} />: {name}
        </h1>
        <h4>
          <FormattedMessage {...messages.unitNumber} /> {unitNumber}
        </h4>
      </div>
    </div>
  </div>
);

export class GenerateRenewalOffer extends Component<Props & InjectedProps> {
  componentWillMount() {
    this.props.actions.getRenewalOfferTerms();
    if (!this.props.leaseId) {
      this.props.actions.getOneResident(this.props.residentId);
      this.props.actions.getPropertyTransactionCodes();
    } else {
      this.props.actions.getPreviousRenewalOffers(this.props.leaseId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.leaseId && nextProps?.leaseId) {
      this.props.actions.getPreviousRenewalOffers(nextProps.leaseId);
    }
  }

  componentWillUnmount() {
    this.props.actions.clearLoadedRenewalOffers();
  }

  goBack = () => {
    this.props.history.goBack();
  };

  mapTxCodeData = (offer) => {
    // 1. Create list of sorted txCode prop names
    const sortedTxCodePropNames = Object.keys(offer)
      .filter((propertyName) => {
        return propertyName.indexOf('transactionCode') === 0;
      })
      .sort();

    // 2. determine number of txCodes included
    const numberOfTransactionCodes = Math.max(
      ...Object.keys(offer)
        .filter((propertyName) => {
          return propertyName.indexOf('transactionCode') === 0;
        })
        .sort()
        .map((x) => {
          return Number(x.replace(/[^0-9]/g, ''));
        }),
    );

    let txArray = [];

    // 3. for each txCode included create an txCode obj { id: '', values []}
    for (let i = 0; i <= numberOfTransactionCodes; i++) {
      const transactionCodeOfferAmmountKeys = sortedTxCodePropNames.filter(
        (x) => {
          const indexRegex = new RegExp(`${i}`, 'i'); // has the index
          const offerAmountRegex = new RegExp('Offer', 'i'); // hass the term offer
          return indexRegex.test(x) && offerAmountRegex.test(x);
        },
      );

      const amounts = transactionCodeOfferAmmountKeys.map((x) => {
        return offer[x];
      });

      txArray.push({
        id: offer[`transactionCode${i}`],
        values: amounts,
      });
    }

    return txArray;
  };

  handleSubmit = (offer: Object) => {
    const renewalOffer = {
      ...offer,
      unitId: this.props.unit.id,
      offerDate: new Date(),
    };

    this.props.actions.createRenewalOffer(renewalOffer, this.props.leaseId);
  };

  parseLeaseTerms = (terms: Array<Object>): Array<Object> => {
    const defaultOption = [{ value: '', text: 'Choose' }];
    const parsedOptions = terms.reduce((options, term) => {
      const newOptions = options.concat([
        {
          value: term.id,
          text: `${term.masterLeaseTerm.nMonths} Months`,
        },
      ]);
      return newOptions;
    }, []);
    return defaultOption.concat(parsedOptions);
  };

  render() {
    const {
      intl,
      leaseTermOptions,
      residentName,
      unit: { number },
      previousOffers,
      location,
    } = this.props;
    const termsDropdown = this.parseLeaseTerms(leaseTermOptions);
    const searchParams = new URLSearchParams(location?.search ?? '');
    const prev = searchParams.get('prev');
    return (
      <div className="container-fluid" data-test="generate-renewal-offer">
        <RenewalOfferHeader
          name={residentName}
          unitNumber={number}
          goBack={prev === 'unavailable' ? null : this.goBack}
        />
        <div className="row">
          <div className="col-xs-12 col-md-10">
            <RenewalOfferForm
              intl={intl}
              termsDropDown={termsDropdown}
              onSubmit={this.handleSubmit}
            />
            <h2>
              <strong>
                <FormattedMessage {...messages.previousOffers} />
              </strong>
            </h2>
            {this.props.leaseId ? (
              previousOffers.length > 0 ? (
                previousOffers.map((offer, i) => (
                  <PreviousOffersDetails
                    key={`${offer.id}_${i}`}
                    offer={offer}
                    intl={intl}
                  />
                ))
              ) : (
                <h4>
                  <FormattedMessage {...messages.zeroPreviousRenewalOffers} />
                </h4>
              )
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state: Object, ownProps: Object): Props => {
  const {
    renewalOffer: {
      leaseTermOptions,
      residentName,
      unit,
      leaseId,
      previousOffers,
    },
  } = state;
  return {
    residentId: ownProps.match.params.residentId,
    leaseTermOptions,
    residentName,
    unit,
    leaseId,
    previousOffers,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    actions: bindActionCreators(
      {
        getRenewalOfferTerms,
        createRenewalOffer,
        getPreviousRenewalOffers,
        clearLoadedRenewalOffers,
        getOneResident,
        getPropertyTransactionCodes,
      },
      dispatch,
    ),
  };
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GenerateRenewalOffer));

export default withLDConsumer()(connected);
