import { pathOr } from 'ramda';
import moment from 'moment';
import { FEE_MODES } from '../../ViewUnit/EditFees/constants';

export const processFloorPlanResult = (
  floorPlans: Array<Object>,
): Array<Object> => {
  let result = floorPlans;

  const rdFloorPlans = result.filter((floorPlan) =>
    floorPlan.floorPlanAffordablePrograms.some(
      (program) =>
        program.propertyAffordableProgram.masterAffordableProgram.name === 'RD',
    ),
  );

  return rdFloorPlans.map((floorPlan) => {
    const fpua = pathOr([], ['fpua'], floorPlan);
    const allowances = pathOr([], ['allowances'], floorPlan);
    return {
      ...floorPlan,
      fpua: fpua.filter((uaAllowance) => {
        return uaAllowance?.ua?.pha?.name === 'RD';
      }),
      allowances: allowances.filter((allowance) => {
        return allowance?.pha?.name === 'RD';
      }),
    };
  });
};

export const shouldDisableDate = (date) => {
  if (date) {
    const today = moment().utc().startOf('day');
    const selectedDate = moment(date).utc().startOf('day');
    return selectedDate.isBefore(today, 'day');
  }
  return false;
};

export const mapperFloorPlans = (floorPlans = {}) => {
  const mappedFloorplans = Object.values(floorPlans)
    .map((floorplan) => {
      const { newBasicRent, newNoteRent, startDateBasicRent, startDateRent } =
        floorplan;

      const result = [];

      if (newBasicRent) {
        result.push({
          floorPlanId: floorplan.id,
          pricingType: FEE_MODES.BASIC_RENT,
          feeAmount: newBasicRent,
          implementationDate: startDateBasicRent
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
          startDate: startDateBasicRent.format('YYYY-MM-DD'),
        });
      }

      if (newNoteRent) {
        result.push({
          floorPlanId: floorplan.id,
          pricingType: FEE_MODES.NOTE_RENT,
          feeAmount: newNoteRent,
          implementationDate: startDateRent
            .subtract(1, 'days')
            .format('YYYY-MM-DD'),
          startDate: startDateRent.format('YYYY-MM-DD'),
        });
      }

      return result;
    })
    .flat();

  return mappedFloorplans;
};
