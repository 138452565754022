import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { find, pipe, prop, propEq } from 'ramda';

import {
  renderRadioGroupField,
  renderCurrencyField,
} from '../../../utils/redux-form-helper';

import messages from './messages';

import type { SelectOption } from '../../../containers/App/types';

type Props = {
  employmentStatuses: Array<SelectOption>,
  intl: Object,
  showAnnualIncome?: boolean,
  setUnemployed?: Function,
  resetEmploymentInfo?: Function,
};

const EmploymentInformationSection = ({
  employmentStatuses,
  intl,
  showAnnualIncome,
  setUnemployed,
  resetEmploymentInfo,
}: Props) => {
  const checkForNotEmployed = (e) => {
    if (!setUnemployed || !resetEmploymentInfo) {
      return;
    }
    const selectedValue = e.target.value;
    const notEmployedId = pipe(
      find(propEq('text', 'Not Employed')),
      // $FlowFixMe
      prop('value'),
    )(employmentStatuses);
    if (selectedValue === notEmployedId) {
      setUnemployed();
    } else {
      resetEmploymentInfo();
    }
  };
  return (
    <div className="form-content">
      <Row>
        <Col xs={12} md={6}>
          <h3 className="required">
            {intl.formatMessage(messages.statusLabel)}
          </h3>
          <Row>
            <Col xs={12}>
              <Field
                name="status"
                fieldName="status"
                options={employmentStatuses}
                component={renderRadioGroupField}
                onClick={checkForNotEmployed}
                inline
              />
            </Col>
          </Row>
        </Col>
        {showAnnualIncome && (
          <Col xs={12} md={6}>
            <h3 className="required">
              {intl.formatMessage(messages.incomeLabel)}
            </h3>
            <Field
              name="annualIncome"
              component={renderCurrencyField}
              className="input-lg"
              placeholder={intl.formatMessage(messages.incomePlaceholder)}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default EmploymentInformationSection;
