import { createSelector } from 'reselect';
import type { GlobalState } from '../App/types';

const getEditProspectHouseholdState = (state: GlobalState) =>
  state.editProspectHousehold;

export const getProspect = createSelector(
  getEditProspectHouseholdState,
  ({ prospect }) => prospect,
);

export const getProspectName = createSelector(getProspect, (prospect) =>
  prospect ? `${prospect.firstName} ${prospect.lastName}` : '---',
);

export const getProspectId = createSelector(getProspect, (prospect) =>
  prospect ? prospect.id : '',
);

export const getPropertyId = createSelector(getProspect, (prospect) =>
  prospect ? prospect.propertyId : '',
);
