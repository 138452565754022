import { defineMessages } from 'react-intl';

const messages = defineMessages({
  camMonthlyCharges: {
    id: 'App.CamTab.CamMontlyCharges.CamMonthlyCharges',
    defaultMessage: 'CAM Monthly Charges',
  },
  fetchMonthlyChargesError: {
    id: 'App.CamTab.CamMontlyCharges.FetchMonthlyChargesError',
    defaultMessage: 'Error fetching CAM Monthly Charges',
  },
  camPool: {
    id: 'App.CamTab.CamMontlyCharges.CamPool',
    defaultMessage: 'CAM Pools',
  },
  transactionType: {
    id: 'App.CamTab.CamMontlyCharges.TransactionType',
    defaultMessage: 'Transaction Type',
  },
  transactionCode: {
    id: 'App.CamTab.CamMontlyCharges.TransactionCode',
    defaultMessage: 'Transaction Code',
  },
  amount: {
    id: 'App.CamTab.CamMontlyCharges.Amount',
    defaultMessage: 'Amount',
  },
  startDate: {
    id: 'App.CamTab.CamMontlyCharges.StartDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'App.CamTab.CamMontlyCharges.EndDate',
    defaultMessage: 'End Date',
  },
  edit: {
    id: 'App.CamTab.CamMontlyCharges.Edit',
    defaultMessage: 'Edit',
  },
  currentAmount: {
    id: 'App.CamTab.CamMontlyCharges.CurrentAmount',
    defaultMessage: 'Current Amount',
  },
  changeAmount: {
    id: 'App.CamTab.CamMontlyCharges.ChangeAmount',
    defaultMessage: 'Change Amount',
  },
  endCharge: {
    id: 'App.CamTab.CamMontlyCharges.EndCharge',
    defaultMessage: 'End Charge',
  },
  save: {
    id: 'App.CamTab.CamMontlyCharges.Save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'App.CamTab.CamMontlyCharges.Cancel',
    defaultMessage: 'Cancel',
  },
  editCharges: {
    id: 'App.CamTab.CamMontlyCharges.EditCharges',
    defaultMessage: 'Edit Charges',
  },
  updateMonthlyChargesError: {
    id: 'App.CamTab.CamMontlyCharges.UpdateMonthlyChargesError',
    defaultMessage: 'Error Updating CAM Monthly Charges',
  },
  updateMonthlyChargesSuccess: {
    id: 'App.ManageProperties.WOHouseholdPreferences.UpdateMonthlyChargesSuccess',
    defaultMessage: 'Success',
  },
  updateMonthlyChargesSuccessBody: {
    id: 'App.ManageProperties.WOHouseholdPreferences.UpdateMonthlyChargesSuccessBody',
    defaultMessage: 'Updated CAM Monthly Charges',
  },
});

export default messages;
