import * as constants from '../constants';
import {
  LinearProgressRatings,
  RecommendedRatings,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import useFormatMessage from '../../../hooks/useFormatMessage';
import { RatingBreakdown } from '../types';
import messages from '../messages';

type Props = {
  ratingType: string,
  recommendedCount?: number,
  notRecommendedCount?: number,
  ratingBreakdown?: RatingBreakdown,
  selectedRatings: 'all' | StarSelection[],
  onRatingSelect: LinearProgressSelectHandler,
};

export const OverallAvgRatingLinearProgressColumn = ({
  ratingType,
  recommendedCount,
  notRecommendedCount,
  ratingBreakdown,
  selectedRatings,
  onRatingSelect,
}: Props) => {
  const formatMessage = useFormatMessage();
  return (
    <>
      {ratingType === constants.RECOMMEND_BASED ? (
        <RecommendedRatings
          data={{
            recommended: recommendedCount,
            notRecommended: notRecommendedCount,
          }}
          recommendedTooltip={formatMessage(messages.recommendedTooltip)}
          notRecommendedTooltip={formatMessage(messages.notRecommendedTooltip)}
        />
      ) : (
        <LinearProgressRatings
          data={{
            oneStar: ratingBreakdown[1],
            twoStars: ratingBreakdown[2],
            threeStars: ratingBreakdown[3],
            fourStars: ratingBreakdown[4],
            fiveStars: ratingBreakdown[5],
          }}
          selected={selectedRatings}
          onSelect={onRatingSelect}
        />
      )}
    </>
  );
};
