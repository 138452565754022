import React from 'react';
import { Modal } from 'react-bootstrap';

import messages from './messages';
import { formatPeriod } from '../../../utils/redux-form-helper.js';
import GenericModal from '../../../components/GenericModal/index.js';
import confirm from '../../../components/ConfirmDialogModal';

const onViewTransactionClick = (
  intl: Object,
  data: Array<Object>,
  onReverseMiscTranactionClick: Function,
  period: string,
  status: string,
  canReverse: boolean,
  showReverse: boolean = true,
) => {
  const { formatMessage } = intl;

  const onReverasalClick = (dismiss: Function) => {
    confirm(formatMessage(messages.confirmReversal), {
      intl,
    }).then(
      () => {
        onReverseMiscTranactionClick();
        dismiss();
      },
      () => {},
    );
  };

  const modalHeader = () => (
    <Modal.Header closeButton data-test="misc-trans-modal-header">
      <i className="icon et-money" />
      <h1>{formatMessage(messages.modalHeader)}</h1>
    </Modal.Header>
  );

  const modalBodyHeader = (dismiss: Function) => (
    <div className="row">
      <div className="col-md-6 col-xs-12">
        <span>
          <strong>
            {formatMessage(messages.period)}: {formatPeriod(period)}
          </strong>
        </span>
      </div>
      <div className="col-md-6 col-xs-12 text-right">
        <a
          className="btn btn-tertiary no-margin"
          style={
            status === 'Posted' && showReverse ? null : { visibility: 'hidden' }
          }
          onClick={() => onReverasalClick(dismiss)}
          disabled={!canReverse}
        >
          <i className="icon et-pencil" />
          {formatMessage(messages.modalReverse)}
        </a>
      </div>
    </div>
  );

  const modalBodyItem = (item: Object) => (
    <div className="row form-group" key={item.text}>
      <div className="col-md-6 col-xs-12">
        <label>{item.text}</label>
      </div>
      <div className="col-md-6 col-xs-12">
        {item.isImage ? (
          <a href={item.value} target="_blank" rel="noreferrer">
            <img src={item.value} alt={item.text} style={{ width: '100%' }} />
          </a>
        ) : (
          item.value
        )}
      </div>
    </div>
  );

  const modalBody = (dismiss: Function) => (
    <Modal.Body data-test="misc-trans-modal-body">
      <div className="scrollable-modal__content row">
        <div className="container-fluid">
          <form>
            {modalBodyHeader(dismiss)}
            {data ? data.map((datum) => modalBodyItem(datum)) : ''}
          </form>
        </div>
      </div>
    </Modal.Body>
  );

  const modalFooter = (dismiss: Function) => (
    <Modal.Footer data-test="misc-trans-modal-footer">
      <div className="row">
        <div className="col-xs-12">
          <button className="btn btn-default center-block" onClick={dismiss}>
            {formatMessage(messages.modalFooterClose)}
          </button>
        </div>
      </div>
    </Modal.Footer>
  );

  GenericModal({
    formatMessage,
    modalHeader,
    modalBody,
    modalFooter,
  });
};

export default onViewTransactionClick;
