import { put, select, throttle } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import { CREATE_VEHICLE, UPDATE_VEHICLE, DELETE_VEHICLE } from './constants';
import type { Action } from '../../containers/App/types';
import VehiclesService from '../../services/vehiclesService';

import messages from './messages';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../../containers/App/selectors';

export function* createVehicle(action: Action<any>): Saga<void> {
  try {
    const { householdId, vehicle, updateHousholdVehicles } = action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const vehiclesService = new VehiclesService();

    yield vehiclesService.create(
      organizationId,
      selectedProperty.id,
      householdId,
      [vehicle],
    );

    /**
     * This is a callback that is refreshing the list of household vehicles
     * displayed on the customer profile.
     */
    updateHousholdVehicles();

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.createVehicleSuccessDescription,
        ),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(
          messages.createVehicleFailureDescription,
        ),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* createVehicleSaga(): Saga<void> {
  yield throttle(500, CREATE_VEHICLE, createVehicle);
}

export function* updateVehicle(action: Action<any>): Saga<void> {
  try {
    const {
      householdId,
      vehicleId,
      vehicle,
      updateHousholdVehicles,
    } = action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const vehiclesService = new VehiclesService();

    yield vehiclesService.update(
      organizationId,
      selectedProperty.id,
      householdId,
      vehicleId,
      vehicle,
    );

    /**
     * This is a callback that is refreshing the list of household vehicles
     * displayed on the customer profile.
     */
    updateHousholdVehicles();

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.updateVehicleSuccessDescription,
        ),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(
          messages.updateVehicleErrorDescription,
        ),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* updateVehicleSaga(): Saga<void> {
  yield throttle(500, UPDATE_VEHICLE, updateVehicle);
}

export function* deleteVehicle(action: Action<any>): Saga<void> {
  try {
    const { householdId, vehicleId, updateHousholdVehicles } = action.payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const vehiclesService = new VehiclesService();

    yield vehiclesService.delete(
      organizationId,
      selectedProperty.id,
      householdId,
      vehicleId,
    );

    /**
     * This is a callback that is refreshing the list of household vehicles
     * displayed on the customer profile.
     */
    updateHousholdVehicles();

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.deleteVehicleSuccessDescription,
        ),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(
          messages.deleteVehicleErrorDescription,
        ),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* deleteVehicleSaga(): Saga<void> {
  yield throttle(500, DELETE_VEHICLE, deleteVehicle);
}

export default [createVehicleSaga, updateVehicleSaga, deleteVehicleSaga];
