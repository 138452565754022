import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, Field, reduxForm, change } from 'redux-form';
import { pathOr } from 'ramda';
import moment from 'moment';
import {
  FormattedMessage,
  injectIntl,
  type InjectIntlProvidedProps,
} from 'react-intl';
import messages from './messages';
import { FORM_NAME } from './constants';
import { createChargeBatch } from './actions';
import validate from './validate';
import {
  renderDateField,
  renderCheckboxField,
} from '../../../utils/redux-form-helper';
import FileField from '../../../components/Fields/FileField';

type FormRowProps = {
  label: any,
  children: any,
};

export const FormRow = ({ label, children }: FormRowProps) => (
  <div className="row">
    <div className="col-md-4 col-xs-12">
      <label>{label}</label>
    </div>
    <div className="col-md-8 col-xs-12">{children}</div>
  </div>
);

type OptionType = { value: ?string, text: string };

type DefaultProps = {
  onSubmit?: Function,
  defaultOption: OptionType,
  formValues: Object,
};

type ReduxFormProps = {
  form: string,
  change: Function,
  handleSubmit: Function,
  initialValues: Object,
};

type ConnectedMethods = {
  actions: {
    createChargeBatch: Function,
  },
};

type Props = InjectIntlProvidedProps &
  ConnectedMethods &
  DefaultProps &
  ReduxFormProps & {
    history: {
      goBack: Function,
    },
    propertyName: string,
  };

type State = {
  files: Array<any>,
};

export class CreateChargeBatchForm extends Component<Props, State> {
  static defaultProps = {
    onSubmit: () => {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      files: [],
    };
  }

  handleFileChange = (files: Array<any>) => {
    const { change } = this.props;
    if (files && files.length > 0) {
      this.setState({ files }, () => change('batchFile', files[0].name));
    } else {
      this.setState({ files: [] }, () => change('batchFile', null));
    }
  };

  handleSubmit = () => {
    const {
      history,
      formValues: { startDate, endDate, noEndDate },
      actions: { createChargeBatch },
      onSubmit,
    } = this.props;
    const { files } = this.state;
    if (!files || files.length === 0) return;
    createChargeBatch({
      file: files[0],
      startDate,
      endDate,
      noEndDate,
    });
    if (onSubmit) onSubmit();
    history.goBack();
  };

  generateOptions = (data: Array<Object>, shape: Object): Array<Object> => {
    return data.map((datum) => {
      return {
        value: pathOr('', shape.value, datum),
        text: pathOr('', shape.text, datum),
      };
    });
  };

  render() {
    const {
      intl: { formatMessage },
      handleSubmit,
      propertyName,
      formValues,
    } = this.props;
    const { noEndDate } = formValues;
    return (
      <Form
        encType="multipart/form-data"
        onSubmit={handleSubmit(this.handleSubmit)}
      >
        <FormRow label={<FormattedMessage {...messages.propertyName} />}>
          <div className="form-group">{propertyName}</div>
        </FormRow>
        <FormRow label={<FormattedMessage {...messages.uploadFile} />}>
          <FileField name="batchFile" onChange={this.handleFileChange} />
        </FormRow>
        <FormRow label={formatMessage(messages.startDate)}>
          <Field
            name="startDate"
            component={renderDateField}
            classPicker="modal-fixed-position"
            bsSize="lg"
          />
        </FormRow>
        <FormRow label={formatMessage(messages.endDate)}>
          <Field
            name="endDate"
            component={renderDateField}
            classPicker="modal-fixed-position"
            disabled={noEndDate}
            bsSize="lg"
          />
        </FormRow>

        <FormRow label={formatMessage(messages.NoEndDate)}>
          <Field
            name="noEndDate"
            component={renderCheckboxField}
            classPicker="modal-fixed-position"
            bsSize="lg"
          />
        </FormRow>
        <FormRow label={<FormattedMessage {...messages.subjournal} />}>
          <div className="form-group">Resident</div>
        </FormRow>
        <FormRow label={<FormattedMessage {...messages.transactionType} />}>
          <div className="form-group">Charge</div>
        </FormRow>
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <p>
              <FormattedMessage {...messages.uploadText} />
            </p>
            <p>
              <FormattedMessage {...messages.emailText} />
            </p>
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = ({ form }) => ({
  formValues: pathOr({}, [FORM_NAME, 'values'], form),
});

const mapDispatchToProps = (dispatch: Object): ConnectedMethods => ({
  actions: bindActionCreators(
    {
      createChargeBatch,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  injectIntl(
    reduxForm({
      form: FORM_NAME,
      initialValues: {
        code: 'default',
        noEndDate: false,
        startDate: moment()
          .endOf('month')
          .subtract(1, 'day')
          .startOf('day'),
        endDate: moment()
          .add(1, 'month')
          .endOf('month')
          .startOf('day'),
      },
      touchOnChange: true,
      validate,
      change,
    })(CreateChargeBatchForm),
  ),
);
