export const filtersExpirationLeasesRanges = [
  {
    name: 'monthToMonthFilter',
    text: 'Month To Month leases',
  },
  {
    name: 'below30Filter',
    text: '0-30 day expirations',
  },
  {
    name: 'between30And60Filter',
    text: '31-60 day expirations',
  },
  {
    name: 'between60And90Filter',
    text: '61-90 day expirations',
  },
  {
    id: 4,
    name: 'between90And120Filter',
    text: '91-120 day expirations',
  },
  {
    id: 5,
    name: 'over120Filter',
    text: '121+ day expirations',
  },
];

export const filterRenewalLeasesStatus = [
  {
    name: 'completed',
    text: 'Completed',
  },
  {
    name: 'inProcess',
    text: 'In Process',
  },
  {
    name: 'incomplete',
    text: 'Not Started',
  },
  {
    name: 'onNotice',
    text: 'On Notice',
    textSecondary: 'On NTV',
  },
];

export const filterFormInitialValues = {
  ExpirationSection: {
    monthToMonthFilter: true,
    below30Filter: true,
    between30And60Filter: true,
    between60And90Filter: true,
    between90And120Filter: true,
  },
};
