import { useQuery } from 'react-query';
import useSelectedProperty from '../useSelectedProperty';
import UserService from '../../services/userService';

function useProspectAssignees() {
  const selectedProperty = useSelectedProperty();
  const { data, isLoading } = useQuery(
    ['prospectAssignees', selectedProperty],
    () => {
      const service = new UserService();

      if (!selectedProperty) return Promise.resolve([]);

      return service.getProspectAssignees(
        selectedProperty.organizationId,
        selectedProperty.id,
      );
    },
    {
      staleTime: 60000,
    },
  );

  return {
    prospectAssignees: data ?? [],
    isLoading,
  };
}

export default useProspectAssignees;
