import { defineMessages } from 'react-intl';

const messages = defineMessages({
  unitTitle: {
    id: 'App.ViewUnit.UnitAmenities.UnitTitle',
    defaultMessage: 'Unit Amenities',
  },
  floorplanTitle: {
    id: 'App.ViewUnit.UnitAmenities.FloorplanTitle',
    defaultMessage: 'Floorplan Amenities',
  },
  none: {
    id: 'App.ViewUnit.UnitAmenities.None',
    defaultMessage: 'There are 0 unit amenities',
  },
});

export default messages;
