import {
  Button,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Table } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import React from 'react';
import styled from 'styled-components';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import generalMessages from '../../App/messages';
import NewQuotingRent from './NewQuotingRent';
import { useActOnSmartPrice, useFetchUnleasedUnitsByFloorPlan } from './hooks';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

const GridWithStaticEmptyMessage = styled(Grid)`
  div[style*='position: absolute']:has(p) {
    position: static !important;
  }
`;

const VacantUnitsModal = ({
  show,
  onClose,
  propertyId,
  organizationId,
  floorPlanId,
  floorInternalName,
  selectedSmartPrice,
  floorplanMarketingName,
  numUnits,
  intl,
  quotingRent,
  getAllFloorPlans,
  refetchSmartPricesHistory,
  smartPriceStatus,
  smartPriceLastLog,
}: Object) => {
  const {
    availabilityQuery: { data },
    isLoading,
  } = useFetchUnleasedUnitsByFloorPlan({
    organizationId,
    propertyId,
    floorInternalName,
    selectedSmartPrice,
    show,
    intl,
  });

  const [actOnSmartPrice, actOnSmartPriceIsLoading] = useActOnSmartPrice(
    organizationId,
    propertyId,
    async () => {
      getAllFloorPlans();
      refetchSmartPricesHistory();
      onClose();
    },
  );

  const onAccept = async () => {
    await actOnSmartPrice(
      quotingRent,
      selectedSmartPrice,
      floorPlanId,
      'ACCEPT',
    );
  };

  const onReject = async () => {
    await actOnSmartPrice(
      quotingRent,
      selectedSmartPrice,
      floorPlanId,
      'REJECT',
    );
  };

  const numberOfNotLeasedUnits = data?.length || 0;

  return (
    <Modal
      open={show}
      onClose={onClose}
      actions={
        <>
          <Button variant="text" onClick={onClose}>
            {intl.formatMessage(messages.cancel)}
          </Button>
          <ElementWithPermissions
            scope={['floor-plan-update']}
            tooltip={{
              title: intl.formatMessage(
                smartPriceStatus === 'Rejected'
                  ? messages.priceAlreadyRejected
                  : smartPriceStatus === 'Accepted' ||
                    smartPriceStatus === 'No Change'
                  ? messages.noValidAction
                  : messages.forbidden,
              ),
              placement: 'top',
              arrow: true,
              variant: 'light',
            }}
            forceDisable={
              smartPriceStatus === 'Rejected' ||
              smartPriceStatus === 'Accepted' ||
              smartPriceStatus === 'No Change'
            }
            showTooltip
          >
            <Button
              variant="delete"
              onClick={onReject}
              sx={{ marginLeft: 1 }}
              disabled={actOnSmartPriceIsLoading || !floorPlanId}
            >
              {intl.formatMessage(messages.reject)}
            </Button>
          </ElementWithPermissions>
          <ElementWithPermissions
            scope={['floor-plan-update']}
            tooltip={{
              title: intl.formatMessage(
                smartPriceStatus === 'Accepted' ||
                  smartPriceStatus === 'No Change'
                  ? messages.priceAlreadyAccepted
                  : smartPriceStatus === 'Rejected'
                  ? messages.noValidAction
                  : messages.forbidden,
              ),
              placement: 'top',
              arrow: true,
              variant: 'light',
            }}
            forceDisable={
              smartPriceStatus === 'Accepted' ||
              smartPriceStatus === 'Rejected' ||
              smartPriceStatus === 'No Change'
            }
            showTooltip
          >
            <Button
              variant="primary"
              onClick={onAccept}
              disabled={actOnSmartPriceIsLoading || !floorPlanId}
            >
              {intl.formatMessage(messages.accept)}
            </Button>
          </ElementWithPermissions>
        </>
      }
      title={`${intl.formatMessage(
        messages.smartPriceFor,
      )} ${floorInternalName} - ${floorplanMarketingName}`}
      ModalProps={{
        PaperProps: {
          sx: { width: '100%', maxWidth: 'lg' },
        },
      }}
    >
      <Grid container>
        <Grid item md={12}>
          <Typography variant="h5" sx={{ marginBottom: '16px' }}>
            {intl.formatMessage(messages.noOfUnits)} - {numberOfNotLeasedUnits}
          </Typography>
        </Grid>
        <GridWithStaticEmptyMessage item md={12}>
          <Table
            headers={[
              {
                id: 'unit',
                label: intl.formatMessage(messages.unit),
                sortable: false,
              },
              {
                id: 'status',
                label: intl.formatMessage(messages.status),
                sortable: false,
              },
              {
                id: 'moveOutDate',
                label: intl.formatMessage(messages.moveOutDate),
                sortable: false,
              },
              {
                id: 'moveInReady',
                label: intl.formatMessage(messages.moveInReady),
                sortable: false,
              },
              {
                id: 'daysVacant',
                label: intl.formatMessage(messages.daysVacant),
                sortable: false,
              },
              {
                id: 'priorLeasedRent',
                label: intl.formatMessage(messages.priorLeasedRent),
                sortable: false,
              },
              {
                id: 'currentLeasedRent',
                label: intl.formatMessage(messages.currentLeasedRent),
                sortable: false,
              },
              {
                id: 'quotingRent',
                label: intl.formatMessage(messages.quotingRent),
                sortable: false,
              },
              {
                id: 'smartPrice',
                label: intl.formatMessage(messages.newQuotingRent),
                sortable: false,
                highlighted: true,
                sx: { align: 'center' },
              },
            ]}
            rows={data}
            emptyStateMessage={intl.formatMessage(messages.emptyStateMessage)}
            count={data?.length || 0}
            isLoading={isLoading || actOnSmartPriceIsLoading}
            showSettings={false}
            sx={{ padding: '0px' }}
          />
        </GridWithStaticEmptyMessage>
        <Grid item md={12}>
          <NewQuotingRent
            floorPlanName={floorInternalName}
            currentPrice={quotingRent}
            suggestedPrice={selectedSmartPrice}
            startDate={smartPriceLastLog?.createdAt ?? new Date()}
            smartPriceStatus={smartPriceStatus}
            numberOfNotLeasedUnits={numberOfNotLeasedUnits}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export { VacantUnitsModal };
