import React from 'react';
import { Print } from 'react-easy-print';
import messages from './messages';
export const PrintBoxComment = ({ comment, labelText, intl }: Object) => (
  <Print printOnly name="workOrder">
    <div
      className="col-xs-12 padbottom30 box-dotted"
      style={{ pageBreakInside: 'avoid' }}
    >
      <label>{intl.formatMessage(messages[labelText])}:</label>{' '}
      {comment ? (
        comment
          .split('\n')
          .map((line, i) => <p key={`${i}-${line.length}`}>{line}</p>)
      ) : (
        <span>None</span>
      )}
    </div>
  </Print>
);
