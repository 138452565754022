import { defineMessages } from 'react-intl';

const messages = defineMessages({
  datesInfoMessage: {
    id: 'App.Household.RentableItems.datesInfoMessage',
    defaultMessage:
      'Dates will not be available to select if this rentable item is already reserved by another household.',
  },
});

export default messages;
