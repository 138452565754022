import React, { useRef } from 'react';
import { Button } from 'react-bootstrap';

export type Props = {
  accept?: string,
  onFileUpload: Function,
};

function CustomFileButton({ onFileUpload, accept, ...buttonProps }: Props) {
  const fileInput = useRef(null);

  const handleClick = () => {
    fileInput.current.click();
  };

  return (
    <>
      <Button {...buttonProps} onClick={handleClick} />
      <input
        className="inputfile inputfile-custom"
        ref={fileInput}
        data-testid="file-input"
        type="file"
        name="file"
        accept={accept}
        onChange={onFileUpload}
      />
    </>
  );
}

export default CustomFileButton;
