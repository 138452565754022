import type { Action } from '../App/types';
import type {
  Transaction,
  Subjournal,
  LedgerHeaderData,
  LedgerResponse,
  LedgerRequest,
} from './types.js';
import {
  GET_ALL_SUBJOURNALS,
  GET_ALL_SUBJOURNALS_SUCCESS,
  GET_ALL_SUBJOURNALS_ERROR,
  GET_CUSTOMER_LEDGER_HEADER,
  GET_CUSTOMER_LEDGER_HEADER_SUCCESS,
  GET_CUSTOMER_LEDGER_HEADER_ERROR,
  GET_CUSTOMER_OPS_TRANSACTIONS,
  GET_CUSTOMER_OPS_TRANSACTIONS_SUCCESS,
  GET_CUSTOMER_OPS_TRANSACTIONS_ERROR,
  GET_CUSTOMER_SECURITY_TRANSACTIONS,
  GET_CUSTOMER_SECURITY_TRANSACTIONS_SUCCESS,
  GET_CUSTOMER_SECURITY_TRANSACTIONS_ERROR,
  HANDLE_SOCKET_FINANCIAL_TRANSACTION_FAILURE,
  HANDLE_SOCKET_FINANCIAL_TRANSACTION_SUCCESS,
  CLEAR_LOADED_LEDGER,
  REVERSE_TRANSACTION,
  FINANCIAL_TRANSACTION_SUCCESS,
  REFRESH_LEDGER,
  LOCK_LEDGER_ACTIONS,
  UPDATE_PAYMENT_RESTRICTIONS,
  UPDATE_PAYMENT_RESTRICTIONS_SUCCESS,
  CREATE_APPLICANT_REFUND_STATEMENT,
} from './constants';

export function getAllSubjournals(customerId: string): Action<string> {
  return {
    type: GET_ALL_SUBJOURNALS,
    payload: customerId,
  };
}

export function getAllSubjournalsSuccess(
  subjournals: any,
): Action<Array<Subjournal>> {
  return {
    type: GET_ALL_SUBJOURNALS_SUCCESS,
    payload: subjournals,
  };
}

export function getAllSubjournalsError(error: Error): Action<Error> {
  return {
    type: GET_ALL_SUBJOURNALS_ERROR,
    payload: error,
    error: true,
  };
}

export function getCustomerLedgerHeader(customerId: string): Action<string> {
  return {
    type: GET_CUSTOMER_LEDGER_HEADER,
    payload: customerId,
  };
}

export function getCustomerLedgerHeaderSuccess(
  headerData: LedgerHeaderData,
): Action<LedgerHeaderData> {
  return {
    type: GET_CUSTOMER_LEDGER_HEADER_SUCCESS,
    payload: headerData,
  };
}

export function getCustomerLedgerHeaderError(error: Error): Action<Error> {
  return {
    type: GET_CUSTOMER_LEDGER_HEADER_ERROR,
    payload: error,
    error: true,
  };
}

export function getCustomerOpsTransactions(
  pageNumber: number,
  customerId: string,
  filterReversed: boolean,
  subjournal: string = '',
): Action<LedgerRequest> {
  return {
    type: GET_CUSTOMER_OPS_TRANSACTIONS,
    payload: {
      ledger: 'opsLedger',
      pageNumber,
      customerId,
      filterReversed,
      subjournal,
    },
    meta: {
      subjournal,
    },
  };
}

export function getCustomerOpsTransactionsSuccess(
  response: LedgerResponse,
): Action<Array<Transaction>> {
  return {
    type: GET_CUSTOMER_OPS_TRANSACTIONS_SUCCESS,
    payload: response.results,
    meta: response.meta,
  };
}

export function getCustomerOpsTransactionsError(error: Error): Action<Error> {
  return {
    type: GET_CUSTOMER_OPS_TRANSACTIONS_ERROR,
    payload: error,
    error: true,
  };
}

export function getCustomerSecurityTransactions(
  pageNumber: number,
  customerId: string,
  filterReversed: boolean,
): Action<LedgerRequest> {
  return {
    type: GET_CUSTOMER_SECURITY_TRANSACTIONS,
    payload: {
      ledger: 'securityLedger',
      pageNumber,
      customerId,
      filterReversed,
    },
  };
}

export function getCustomerSecurityTransactionsSuccess(
  response: LedgerResponse,
): Action<Array<Transaction>> {
  return {
    type: GET_CUSTOMER_SECURITY_TRANSACTIONS_SUCCESS,
    payload: response.results,
    meta: response.meta,
  };
}

export function getCustomerSecurityTransactionsError(
  error: Error,
): Action<Error> {
  return {
    type: GET_CUSTOMER_SECURITY_TRANSACTIONS_ERROR,
    error: true,
    payload: error,
  };
}

export function clearLoadedLedger(): Action<any> {
  return {
    type: CLEAR_LOADED_LEDGER,
    payload: undefined,
  };
}

export function reverseTransaction(
  transactionId: string,
  ledger: string,
  period: string,
  notes: string,
  customerId: string,
  residentId: ?string,
  filterReversed: boolean,
  isNsf: boolean = false,
  camAllocationsId: string = null,
): Action<any> {
  return {
    type: REVERSE_TRANSACTION,
    payload: {
      transactionId,
      ledger,
      period,
      notes,
      customerId,
      residentId,
      filterReversed,
      isNsf,
      camAllocationsId,
    },
  };
}

export function financialTransactionSuccess(customerId: string): Action<any> {
  return {
    type: FINANCIAL_TRANSACTION_SUCCESS,
    payload: customerId,
  };
}

export const refreshLedger = (customerId: string, filterReversed: boolean) => ({
  type: REFRESH_LEDGER,
  payload: {
    customerId,
    filterReversed,
  },
});

export function lockLedger(): Action<any> {
  return {
    type: LOCK_LEDGER_ACTIONS,
    payload: {},
  };
}

export function updatePaymentRestrictions(
  paymentRestrictions: Object,
  householdId: string,
  isResident: boolean,
  profileId: string,
): Action<any> {
  return {
    type: UPDATE_PAYMENT_RESTRICTIONS,
    payload: { paymentRestrictions, householdId, isResident, profileId },
  };
}

export function updatePaymentRestrictionsSuccess(
  paymentRestriction: Object,
): Action<Object> {
  return {
    type: UPDATE_PAYMENT_RESTRICTIONS_SUCCESS,
    payload: paymentRestriction,
  };
}

export function handleSocketFinancialTransactionFailure({
  error,
  title,
}: Object): Action<any> {
  return {
    type: HANDLE_SOCKET_FINANCIAL_TRANSACTION_FAILURE,
    payload: {
      error,
      title,
    },
  };
}

export function handleSocketFinancialTransactionSuccess({
  payload,
  title,
  message,
}: Object): Action<any> {
  return {
    type: HANDLE_SOCKET_FINANCIAL_TRANSACTION_SUCCESS,
    payload: { ...payload, title, message },
  };
}

export function createApplicantRefundStatement(payload: Object): Action<any> {
  return {
    type: CREATE_APPLICANT_REFUND_STATEMENT,
    payload,
  };
}
