import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ManageOnlinePayments.Header.header',
    defaultMessage: 'Manage Online Payments',
  },
});

export default messages;
