import { useState, useEffect } from 'react';
import ContactsService from '../../../services/commercialContactsService';
import StateService from '../../../services/stateService';

export const DEFAULT_OPTION = { value: '', text: 'Choose', disabled: true };

export function useCommercialContactTypeOptions(organizationId: string) {
  const [
    commercialContactTypeOptions,
    setCommercialContactTypeOptions,
  ] = useState([DEFAULT_OPTION]);
  const [optionsAreLoading, setOptionsAreLoading] = useState(false);
  const [optionsLoaded, setOptionsLoaded] = useState(false);

  useEffect(() => {
    if (optionsLoaded || optionsAreLoading) return;

    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const contactsService = new ContactsService();

    const fetchCommercialContactTypes = async () => {
      const commercialContactTypes = await contactsService.getCommercialContactTypes(
        organizationId,
        { signal: abortController.signal },
      );

      setCommercialContactTypeOptions(
        [DEFAULT_OPTION].concat(
          commercialContactTypes.map((it) => ({
            value: it.id,
            text: it.name,
          })),
        ),
      );
      setOptionsLoaded(true);
      setOptionsAreLoading(false);
    };

    fetchCommercialContactTypes();
    setOptionsAreLoading(true);
  }, [
    commercialContactTypeOptions,
    optionsLoaded,
    organizationId,
    optionsAreLoading,
  ]);

  return [commercialContactTypeOptions, optionsLoaded];
}

export function useStates() {
  const [states, setStates] = useState([DEFAULT_OPTION]);
  const [statesLoaded, setStatesLoaded] = useState(false);
  const [statesAreLoading, setStatesAreLoading] = useState(false);

  useEffect(() => {
    if (statesLoaded || statesAreLoading) return;

    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const statesService = new StateService();

    const fetchStates = async () => {
      const statesRes = await statesService.getAll({
        signal: abortController.signal,
      });

      setStates(
        [DEFAULT_OPTION].concat(
          statesRes.map((s) => ({
            value: s.id,
            text: s.code,
          })),
        ),
      );
      setStatesLoaded(true);
      setStatesAreLoading(false);
    };

    fetchStates();
    setStatesAreLoading(true);
  }, [statesAreLoading, statesLoaded]);

  return [states, statesLoaded];
}
