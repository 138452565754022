import { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import type { GraphData } from './types';
import * as graphCommon from './graph-common';
import { PageContext } from '../context';
import messages from '../messages';

export type Props = {
  labels: string[],
  limitData: number[],
  actualsData: number[],
  availablesData: number[],
  overLimitData: number[],
  unitCount: number,
  leaseMax: number,
  limitMax: number,
  graphData: GraphData,
  onColumnClick: Function,
  hideLimitsOnYAxis: boolean,
  height?: number,
};

const INDEXES = {
  LIMITS: 0,
  ACTUALS: 1,
  AVAILABLES: 2,
  OVER_LIMITS: 3,
};

const barExtraConfig = {
  yAxisID: 'leases',
  stack: true,
};

const calcLeaseLimit = (expirationLimit: number, unitCount: number) =>
  Math.ceil((expirationLimit * unitCount) / 100);

function Graph(props: Props) {
  const { formatMessage } = useContext(PageContext);

  return (
    <Bar
      height={props.height || graphCommon.DEFAULT_HEIGHT}
      data={{
        labels: props.labels,
        datasets: [
          {
            type: 'line',
            label: formatMessage(messages.limitLegend),
            borderColor: graphCommon.COLORS.LIMITS,
            pointBackgroundColor: graphCommon.COLORS.LIMITS,
            borderWidth: 3,
            data: props.limitData,
            yAxisID: 'limits',
            fill: false,
          },
          graphCommon.getBarConfig(
            formatMessage(messages.actualsLegend),
            graphCommon.COLORS.ACTUALS,
            props.actualsData,
            barExtraConfig,
          ),
          graphCommon.getBarConfig(
            formatMessage(messages.availablesLegend),
            graphCommon.COLORS.AVAILABLES,
            props.availablesData,
            barExtraConfig,
          ),
          graphCommon.getBarConfig(
            formatMessage(messages.overLimitLegend),
            graphCommon.COLORS.OVER_LIMITS,
            props.overLimitData,
            barExtraConfig,
          ),
        ],
      }}
      options={{
        tooltips: {
          bodyFontStyle: 'bold',
          footerFontStyle: 'normal',
          callbacks: {
            title: () => '',
            label: graphCommon.labelConfig,
            footer: (items, data) => {
              const { datasetIndex, value, xLabel } = items[0];
              const datasetLabel = data.datasets[datasetIndex].label;

              if (!xLabel) return '';

              const [month, year] = xLabel;

              const monthNum = moment().month(month).month();
              const monthData = props.graphData[year][monthNum];
              const leaseLimit = graphCommon.padText(
                `${formatMessage(messages.leaseLimit)}: ${
                  monthData.leaseLimit
                }`,
                graphCommon.TOOLTIP_PADDING.BODY,
              );

              if (datasetIndex === INDEXES.LIMITS) return leaseLimit;
              if (datasetIndex === INDEXES.ACTUALS)
                return [
                  leaseLimit,
                  graphCommon.padText(
                    `${formatMessage(messages.leases)}: ${monthData.leases}`,
                    graphCommon.TOOLTIP_PADDING.BODY,
                  ),
                  graphCommon.padText(
                    `${formatMessage(messages.activeLeases)}: ${
                      monthData.activeLeases
                    }`,
                    graphCommon.TOOLTIP_PADDING.FOOTER,
                  ),
                  graphCommon.padText(
                    `${formatMessage(messages.renewalInProcess)}: ${
                      monthData.renewalLeases
                    }`,
                    graphCommon.TOOLTIP_PADDING.FOOTER,
                  ),
                  graphCommon.padText(
                    `${formatMessage(messages.moveInInProcess)}: ${
                      monthData.moveInProcess
                    }`,
                    graphCommon.TOOLTIP_PADDING.FOOTER,
                  ),
                ];

              return graphCommon.padText(
                `${formatMessage(messages.leases)} ${datasetLabel}: ${value}`,
                graphCommon.TOOLTIP_PADDING.BODY,
              );
            },
          },
        },
        legend: {
          labels: {
            generateLabels: (c) => [
              {
                text: formatMessage(messages.limitLegend),
                datasetIndex: INDEXES.LIMITS,
                pointStyle: 'line',
                fillStyle: graphCommon.COLORS.LIMITS,
                strokeStyle: graphCommon.COLORS.LIMITS,
              },
              graphCommon.getBarLegend(
                formatMessage(messages.actualsLegend),
                INDEXES.ACTUALS,
                graphCommon.COLORS.ACTUALS,
              ),
              graphCommon.getBarLegend(
                formatMessage(messages.availablesLegend),
                INDEXES.AVAILABLES,
                graphCommon.COLORS.AVAILABLES,
              ),
              graphCommon.getBarLegend(
                formatMessage(messages.overLimitLegend),
                INDEXES.OVER_LIMITS,
                graphCommon.COLORS.OVER_LIMITS,
              ),
            ],
            usePointStyle: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: false,
            },
          ],
          yAxes: [
            {
              id: 'limits',
              display: true,
              ticks: {
                callback: (value: number, index: number, values: number[]) => {
                  const leaseLimit = calcLeaseLimit(value, props.unitCount);
                  return props.hideLimitsOnYAxis
                    ? leaseLimit
                    : [
                        `${value.toFixed(1)}%`,
                        value > 0
                          ? `${leaseLimit} ${formatMessage(
                              value.toString() === '1'
                                ? messages.leasesSingular
                                : messages.leasesPlural,
                            )}`
                          : '',
                      ];
                },
                beginAtZero: true,
                max: props.limitMax ?? 20,
              },
            },
            {
              id: 'leases',
              display: false,
              ticks: {
                beginAtZero: true,
                max: props.leaseMax ?? 34,
              },
            },
          ],
        },
        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
      }}
      onElementsClick={props.onColumnClick}
    />
  );
}

export default Graph;
