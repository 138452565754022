import React from 'react';
import classnames from 'classnames';
import { Tooltip } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { HelpIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

type Props = {
  hasTwoButtons?: boolean,
  hasFilter?: boolean,
  columnLabel: any,
  ascendingLabel?: string,
  descendingLabel?: string,
  order?: string,
  sortable?: boolean,
  icon?: string,
  onOrderClick?: Function,
  darker?: boolean,
  bold?: boolean,
};

const ColumnHeader = ({
  hasTwoButtons = false,
  hasFilter = false,
  columnLabel,
  ascendingLabel = '',
  descendingLabel = '',
  order = '',
  sortable = false,
  icon = 'sortable',
  onOrderClick = () => {},
  darker = false,
  bold = false,
  tooltip,
}: Props) => (
  <th
    className={classnames({
      'selected-filter': hasFilter,
      'text-center': hasTwoButtons,
      'header-darker--var': darker,
      'font-bold-important': bold,
    })}
  >
    {tooltip && (
      <Tooltip
        title={tooltip}
        arrow
        placement="top"
        style={{
          display: 'inline-flex',
          marginLeft: '2px',
          verticalAlign: 'middle',
        }}
        variant="light"
      >
        <HelpIcon />
      </Tooltip>
    )}
    {!sortable && columnLabel}
    {sortable && (
      <span>
        {columnLabel}
        <i className={`et-${icon} sort-option`} onClick={onOrderClick()} />
      </span>
    )}
  </th>
);

export default ColumnHeader;
