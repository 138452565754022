/**
 * DEATH CODE
 * ---
 * This form is NOT USED ANYMORE. It was used to handle old residents with a certain data format.
 * This is the form that is used now instead:
 * - src/containers/QualificationForms/IncomeVerificationAlternateForm/index.js
 */
import React from 'react';
import { Field } from 'redux-form';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { pick, pathOr } from 'ramda';
import { withRouter } from 'react-router-dom';
import { NoPrint } from 'react-easy-print';

import {
  renderTextField,
  renderSelectField,
  renderCurrencyField,
} from '../../../utils/redux-form-helper';
import { ORGANIZATION_FIELDS_MAP } from './constants.js';
import type { SelectOption } from '../../App/types';
import type {
  IncomeAssetRow,
  IncomeMoneyColumnProps,
  IncomeRowProps,
  IncomeOtherFieldProps,
} from '../types';
import {
  YesNoColumns,
  IncomeAssetCurrencyField,
  SecurityClaimNumberRow,
} from '../SharedFormFields';
import messages from './messages';
import validate from './validate';

type Props = {
  incomeTypes: Array<SelectOption>,
  states: Array<SelectOption>,
  onSubmit: Function,
  handleSubmit: Function,
  change: Function,
  valid: boolean,
  applicantName: string,
  employmentIncome: string,
  selfEmploymentIncome: string,
  militaryPayIncome: string,
  unemploymentBenefitsIncome: string,
  socialSecurityIncome: string,
  supplementalSecurityIncome: string,
  vaBenefitsIncome: string,
  tanfIncome: string,
  disabilityIncome: string,
  recurringGiftIncome: string,
  retirementIncome: string,
  trustIncome: string,
  absentFamilyMemberIncome: string,
  otherIncome: string,
  courtOrderedChildSupportIncome: string,
  receivingChildSupportIncome: string,
  isPrior?: boolean,
  location: {
    state: {
      isDisabled: boolean,
    },
  },
  hasCompliancePermission: boolean,
  intl: Object,
  isSubmitting: boolean,
  handlePrintIncomeAssetForm: Function,
  selectedProperty: Object,
  isFormDisabled?: boolean,
};

export const IncomeTableHeader = (props) => {
  const typeOfIncomeHeaderClassName = 'col-xs-4';
  const requiredFormHeaderClassName = 'col-xs-4';
  const annualGrossReceivingHeaderClassName = 'col-xs-3';
  return (
    <div className="row faux-table__head">
      <div className={typeOfIncomeHeaderClassName}>
        <div className="row">
          <div className="col-xs-2">
            <label>#</label>
          </div>
          <div className="col-xs-10">
            <label>
              <FormattedMessage {...messages.typeOfIncome} />
            </label>
          </div>
        </div>
      </div>
      <div className="col-xs-2 text-center">
        <label>
          <FormattedMessage {...messages.receiving} />
        </label>
        <div className="row">
          <div className="col-xs-6">
            <label>
              <FormattedMessage {...messages.no} />
            </label>
          </div>
          <div className="col-xs-6">
            <label>
              <FormattedMessage {...messages.yes} />
            </label>
          </div>
        </div>
      </div>
      <div className={annualGrossReceivingHeaderClassName}>
        <label>
          <FormattedMessage {...messages.annualGrossReceiving} />
        </label>
      </div>
      <div className={requiredFormHeaderClassName}>
        <label>
          <FormattedMessage {...messages.requiredForm} />
        </label>
      </div>
    </div>
  );
};

export const OtherIncomeField = ({
  type,
  incomeTypes,
  disabled,
}: IncomeOtherFieldProps) => {
  const colSize = '3';
  return (
    <div className={`col-xs-${colSize}`}>
      <Field
        name={'otherIncome'}
        component={renderCurrencyField}
        className={'input-lg'}
        step="0.01"
        disabled={disabled}
      />
      <Field
        selectClassNames="input-md form-control"
        name={'otherIncomeType'}
        component={renderSelectField}
        bsSize="lg"
        options={incomeTypes}
        maxLength="35"
        disabled={disabled}
      />
    </div>
  );
};

export const NumberAndSourceColumn = ({
  row: { number, name },
  isPrior,
}: IncomeAssetRow) => {
  const colClassName = 'col-xs-4';
  return (
    <div className={colClassName}>
      <div className="row">
        <div className="col-xs-2">
          <span>{number}</span>
        </div>
        <div className="col-xs-10">
          {name !== 'other' ? (
            <FormattedMessage {...messages[name]} />
          ) : (
            <Field
              name="otherIncomeName"
              component={renderTextField}
              maxLength="35"
              className="input-lg"
              placeholder="Other"
              disabled={isPrior}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const IncomeAmountField = ({
  name,
  disabled,
  isPrior,
}: IncomeMoneyColumnProps) => {
  const colSize = '3';
  if (name === 'courtOrderedChildSupport') {
    return (
      <IncomeAssetCurrencyField
        name={name}
        type={'Income'}
        disabled={disabled || !!isPrior}
        label="Amount Ordered:"
        colSize={colSize}
      />
    );
  } else if (name === 'receivingChildSupport') {
    return (
      <IncomeAssetCurrencyField
        name={name}
        type={'Income'}
        disabled={disabled || !!isPrior}
        label="Amount Receiving:"
        colSize={colSize}
      />
    );
  } else if (name === 'publicHousing') {
    return (
      <IncomeAssetCurrencyField
        name={name}
        type={'Income'}
        disabled={disabled || !!isPrior}
        label="If yes, Public Housing Authority:"
        colSize={colSize}
      />
    );
  } else {
    return (
      <IncomeAssetCurrencyField
        name={name}
        type={'Income'}
        disabled={disabled || !!isPrior}
        colSize={colSize}
      />
    );
  }
};

export const StateCountyOrMiscField = ({
  name,
  states,
  type,
  disabled,
  isPrior,
}: IncomeMoneyColumnProps) => {
  const statesColSize = '3';
  if (type === 'states') {
    return (
      <div className={`col-xs-${statesColSize}`}>
        <label>
          <FormattedMessage {...messages.state} />
        </label>
        <Field
          selectClassNames="input-md form-control"
          name={`${name}State`}
          component={renderSelectField}
          bsSize="lg"
          options={states}
          maxLength="35"
          disabled={disabled || isPrior}
        />
        <label>
          <FormattedMessage {...messages.county} />
        </label>
        <Field
          name={`${name}County`}
          component={renderTextField}
          maxLength="35"
          disabled={disabled || isPrior}
          className="input-md"
        />
      </div>
    );
  } else {
    return (
      <div className={`col-xs-${statesColSize}`}>
        <label>
          <FormattedMessage {...messages[`${name}Text`]} />
        </label>
        <Field
          name={`${name}Text`}
          component={renderTextField}
          maxLength="35"
          disabled={disabled || isPrior}
          className="input-lg"
        />
      </div>
    );
  }
};

export const IncomeRow = ({
  row,
  states,
  incomeTypes,
  fields,
  change,
  isPrior,
  intl,
}: IncomeRowProps) => {
  const { name, type, hasForm } = row;

  const disabled = fields[name] !== 'yes';
  const noClicked = () => {
    if (type === 'income') {
      change(`${name}Income`, '0.00');
      change(`${name}PayFrequency`, null);
      change(`${name}PayRate`, 0);
      change(`${name}HoursWeeksMonths`, null);
    }
    if (type === 'states') {
      change(`${name}State`, null);
      change(`${name}County`, null);
    }
    if (type === 'text') {
      change(`${name}Text`, null);
    }
    if (name === 'other') {
      change('otherIncome', '0.00');
      change('otherIncomeName', null);
      change('otherIncomeType', null);
      change('otherPayFrequency', null);
      change('otherPayRate', 0);
      change('otherHoursWeeksMonths', null);
    }
    if (name === 'socialSecurity') {
      change('socialSecurityClaimNumber', null);
    }
    if (name === 'supplementalSecurity') {
      change('supplementalSecurityClaimNumber', null);
    }
  };

  const hasFormColClass = 'col-xs-3';
  const securityClaimTextOffset = '4';
  const securityClaimTextCol = '2';
  const securityClaimInputCol = '3';
  const securityClaimInputOffset = null;
  const offsetForReqFormTextField = '1';
  const offsetForReqFormStatesField = '3';

  return (
    <div>
      <div className="col-xs-12">
        <NumberAndSourceColumn row={row} />
        <YesNoColumns name={name} noClicked={noClicked} isPrior={isPrior} />
        {type === 'income' && (
          <IncomeAmountField name={name} disabled={disabled || !!isPrior} />
        )}
        {type === 'states' && (
          <StateCountyOrMiscField
            name={name}
            states={states}
            type={type}
            disabled={disabled || !!isPrior}
          />
        )}
        {type === 'otherIncome' && (
          <OtherIncomeField
            disabled={disabled || !!isPrior}
            incomeTypes={incomeTypes}
          />
        )}
        {type === 'text' && (
          // For Spacing
          <div className="col-xs-3">
            <input className="input-lg" style={{ visibility: 'hidden' }} />
          </div>
        )}
        {type === 'text' && hasForm && (
          <div className={`col-xs-${offsetForReqFormTextField}`}></div>
        )}
        {type === 'states' && hasForm && (
          <div className={`col-xs-${offsetForReqFormStatesField}`}></div>
        )}
        <div className={hasFormColClass}>
          {hasForm ? <FormattedMessage {...messages[`${name}Forms`]} /> : ''}
        </div>
      </div>
      {(name === 'socialSecurity' || name === 'supplementalSecurity') &&
      type === 'income' ? (
        <SecurityClaimNumberRow
          name={`${name}ClaimNumber`}
          disabled={disabled}
          intl={intl}
          textOffset={securityClaimTextOffset}
          textCol={securityClaimTextCol}
          inputCol={securityClaimInputCol}
          inputOffset={securityClaimInputOffset}
        />
      ) : null}
    </div>
  );
};

const IncomeVerificationForm = (props: Props) => {
  const {
    incomeTypes,
    states,
    handleSubmit,
    applicantName,
    onSubmit,
    change,
    valid,
    isPrior,
    hasCompliancePermission,
    intl,
    isSubmitting,
    handlePrintIncomeAssetForm,
    isFormDisabled,
  } = props;
  const { INCOME_VALUE_FIELDS, FORM_INFO, INCOME_ROW_NAMES } =
    ORGANIZATION_FIELDS_MAP.default;

  const totalAnnualIncome = INCOME_VALUE_FIELDS.reduce((prevTotal, key) => {
    const amount = +props[key] > 0 ? +props[key] + prevTotal : prevTotal;
    return amount;
  }, 0);
  // $FlowFixMe
  const stateDisabled = pathOr(
    false,
    ['location', 'state', 'isDisabled'],
    props,
  );
  const isDisabled = stateDisabled && !hasCompliancePermission;
  // $FlowFixMe
  const fields = pick(INCOME_ROW_NAMES, props);
  const priorAndNotCompliance = isPrior && !hasCompliancePermission;
  const incomeRows = FORM_INFO.income.map((row, i) => (
    <div className="row faux-table__row" key={`income-${i}`}>
      <IncomeRow
        row={row}
        incomeTypes={incomeTypes}
        fields={fields}
        change={change}
        isPrior={priorAndNotCompliance || isDisabled || isFormDisabled}
        intl={intl}
      />
    </div>
  ));
  const childSupportRows = FORM_INFO.childSupport.map((row, i) => (
    <IncomeRow
      key={`cs-${i}`}
      row={row}
      incomeTypes={incomeTypes}
      states={states}
      fields={fields}
      change={change}
      isPrior={priorAndNotCompliance || isDisabled || isFormDisabled}
      intl={intl}
    />
  ));

  const miscRows = FORM_INFO.misc.map((row, i) => (
    <div className="row faux-table__row" key={`misc-${i}`}>
      <IncomeRow
        row={row}
        incomeTypes={incomeTypes}
        states={states}
        fields={fields}
        change={change}
        isPrior={priorAndNotCompliance || isDisabled || isFormDisabled}
        intl={intl}
      />
    </div>
  ));

  const submit = (values: Object) => {
    onSubmit({ ...values, isAltForm: false }, totalAnnualIncome.toString());
  };

  const printIncomeForm = () => {
    handlePrintIncomeAssetForm('income');
  };

  const annualIncomeTotalClassName = 'col-xs-6';

  return (
    <div className="row">
      <div className="col-xs-12 col-lg-11">
        <div className="panel block block-white-shadow">
          <div className="panel-head">
            <div className="row">
              <div className="col-xs-10 col-md-11 col-lg-11">
                <h2>
                  <FormattedMessage {...messages.incomeVerification} />{' '}
                  {applicantName}
                </h2>
                <NoPrint>
                  <div className="row">
                    <div className="col-xs-12">
                      <p className="no-margin-bottom">
                        <FormattedMessage {...messages.incomeHeaderNote} />
                      </p>
                    </div>
                  </div>
                </NoPrint>
              </div>
              {!isPrior && (
                <div className="col-xs-2 col-md-1 col-lg-1 text-right">
                  <a
                    className="btn btn-tertiary btn-print no-print"
                    title="Print this form"
                    style={{ fontSize: '1.8em' }}
                    onClick={printIncomeForm}
                  >
                    <i className="icon et-print" />
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="panel-body">
            <div className="container-fluid">
              <div className="row container-fluid faux-table">
                <div className="col-xs-12">
                  <form onSubmit={handleSubmit(submit)}>
                    <IncomeTableHeader />
                    {incomeRows}
                    <div className="row faux-table__row">
                      {childSupportRows}
                    </div>
                    {miscRows}
                    <div className="row highlighted-footer padtop10 padbottom10">
                      <div className={annualIncomeTotalClassName}>
                        <FormattedMessage {...messages.totalAnnualIncome} />
                      </div>
                      <div className="col-xs-6">
                        <FormattedNumber
                          value={totalAnnualIncome}
                          style={{ value: 'currency' }.value}
                          currency="USD"
                        />
                      </div>
                    </div>
                    {!isPrior || hasCompliancePermission ? (
                      <button
                        className="btn btn-primary center-block padbottom20"
                        type="submit"
                        disabled={
                          !valid || isDisabled || isSubmitting || isFormDisabled
                        }
                      >
                        <FormattedMessage {...messages.saveIncomeInformation} />
                      </button>
                    ) : null}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (
  { form }: Object,
  { initialValues }: Object,
): Object => {
  const selector = formValueSelector('incomeVerification');
  const statePropsMap = {
    initialValues,
    employmentIncome: selector({ form }, 'employmentIncome'),
    selfEmploymentIncome: selector({ form }, 'selfEmploymentIncome'),
    militaryPayIncome: selector({ form }, 'militaryPayIncome'),
    unemploymentBenefitsIncome: selector(
      { form },
      'unemploymentBenefitsIncome',
    ),
    socialSecurityIncome: selector({ form }, 'socialSecurityIncome'),
    supplementalSecurityIncome: selector(
      { form },
      'supplementalSecurityIncome',
    ),
    vaBenefitsIncome: selector({ form }, 'vaBenefitsIncome'),
    tanfIncome: selector({ form }, 'tanfIncome'),
    disabilityIncome: selector({ form }, 'disabilityIncome'),
    recurringGiftIncome: selector({ form }, 'recurringGiftIncome'),
    retirementIncome: selector({ form }, 'retirementIncome'),
    trustIncome: selector({ form }, 'trustIncome'),
    absentFamilyMemberIncome: selector({ form }, 'absentFamilyMemberIncome'),
    otherIncome: selector({ form }, 'otherIncome'),
    receivingChildSupportIncome: selector(
      { form },
      'receivingChildSupportIncome',
    ),
    absentFamilyMember: selector({ form }, 'absentFamilyMember'),
    anticipateChildSupport: selector({ form }, 'anticipateChildSupport'),
    collectChildSupport: selector({ form }, 'collectChildSupport'),
    courtOrderedChildSupport: selector({ form }, 'courtOrderedChildSupport'),
    disability: selector({ form }, 'disability'),
    employment: selector({ form }, 'employment'),
    vaBenefits: selector({ form }, 'vaBenefits'),
    housingAssistance: selector({ form }, 'housingAssistance'),
    militaryPay: selector({ form }, 'militaryPay'),
    notWorking: selector({ form }, 'notWorking'),
    otherIncomeName: selector({ form }, 'otherIncomeName'),
    otherIncomeType: selector({ form }, 'otherIncomeType'),
    otherIncomeTypeId: selector({ form }, 'otherIncomeTypeId'),
    other: selector({ form }, 'other'),
    publicHousing: selector({ form }, 'publicHousing'),
    receivingChildSupport: selector({ form }, 'receivingChildSupport'),
    recurringGift: selector({ form }, 'recurringGift'),
    retirement: selector({ form }, 'retirement'),
    selfEmployment: selector({ form }, 'selfEmployment'),
    socialSecurity: selector({ form }, 'socialSecurity'),
    supplementalSecurity: selector({ form }, 'supplementalSecurity'),
    tanf: selector({ form }, 'tanf'),
    trust: selector({ form }, 'trust'),
    unemploymentBenefits: selector({ form }, 'unemploymentBenefits'),
    payFrequency: selector({ form }, 'payFrequency'),
    unearnedIncome17AndUnder: selector({ form }, 'unearnedIncome17AndUnder'),
    incomeFromOnlineSources: selector({ form }, 'incomeFromOnlineSources'),
    pension: selector({ form }, 'pension'),
    unearnedIncome17AndUnderIncome: selector(
      { form },
      'unearnedIncome17AndUnderIncome',
    ),
    incomeFromOnlineSourcesIncome: selector(
      { form },
      'incomeFromOnlineSourcesIncome',
    ),
    pensionIncome: selector({ form }, 'pensionIncome'),
  };

  return statePropsMap;
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: 'incomeVerification',
      validate,
      enableReinitialize: true,
    })(IncomeVerificationForm),
  ),
);
