// $FlowFixMe
import React, { useState } from 'react';
import { pathOr } from 'ramda';
import { Grid, Row, Col } from 'react-bootstrap';
import type { DropdownOption } from '../../App/types';
import messages from './messages';
import AssigneeCalendar from '../../../components/AssigneeCalendar/index';

type Props = {
  intl: Object,
  assignees: Array<DropdownOption>,
  onChangeAssignee: Function,
  selectedAssignee: string,
  store: any,
};

const AssigneeSelect = ({
  assignees,
  intl,
  onChangeAssignee,
  selectedAssignee,
}: Object) => {
  return (
    <div>
      <h5>{intl.formatMessage(messages.viewCalendarFor)}:</h5>
      <select
        className="form-control input-lg"
        onChange={onChangeAssignee}
        value={selectedAssignee}
      >
        <option value="all">All (Future Events)</option>
        {assignees.map((assignee) => {
          return (
            <option key={assignee.value} value={assignee.value}>
              {assignee.text}
            </option>
          );
        })}
      </select>
    </div>
  );
};

const ActivitiesSelect = ({ intl, setFilterCallback }: Object) => {
  return (
    <div>
      <h5>{intl.formatMessage(messages.filterBy)}:</h5>
      <select
        className="form-control input-lg"
        onChange={(e) =>
          setFilterCallback(pathOr('all', ['target', 'value'], e))
        }
      >
        <option value="all">
          {intl.formatMessage(messages.allActivities)}
        </option>
        <option value="scheduled">
          {intl.formatMessage(messages.scheduledActivities)}
        </option>
        <option value="recorded">
          {intl.formatMessage(messages.recordedActivities)}
        </option>
      </select>
    </div>
  );
};

const CalendarTab = (props: Props) => {
  const [filter, setFilter] = useState('all');
  const { assignees, intl, selectedAssignee, store, onChangeAssignee } = props;
  return (
    <Row>
      <Col xs={12}>
        <Grid fluid>
          <Col xs={4}>
            {assignees.length > 0 && <AssigneeSelect {...props} />}
          </Col>
          <Col xs={4}>
            {assignees.length > 0 && (
              <ActivitiesSelect {...props} setFilterCallback={setFilter} />
            )}
          </Col>
        </Grid>
        <Grid fluid>
          <div className="widget widget-calendar box-calendar">
            {assignees.length > 0 ? (
              // $FlowFixMe
              <AssigneeCalendar
                defaultView="month"
                intl={intl}
                locale="en"
                selectedAssignee={selectedAssignee}
                store={store}
                currentFilter={filter}
                onChangeAssignee={onChangeAssignee}
              />
            ) : (
              <div className="calendar-empty">
                <i className="icon et-calendar" />
                <h2>{intl.formatMessage(messages.noAssigneesAvailable)}</h2>
                <p>{intl.formatMessage(messages.contactSysAdmin)}</p>
              </div>
            )}
          </div>
        </Grid>
      </Col>
    </Row>
  );
};

export default CalendarTab;
