import React from 'react';
import styled from 'styled-components';
import type { OnUploadImageEvent } from './types';
import FileButton from '../../../../components/CustomFileButton';

type Props = {
  onUploadImage: OnUploadImageEvent,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const buttonStyle = { minWidth: 160 };

function ImageUploadBlock({ onUploadImage }: Props) {
  return (
    <Wrapper>
      <p>Add an image for this floor plan. Max 1.</p>
      <FileButton
        className="btn-tertiary"
        style={buttonStyle}
        onFileUpload={onUploadImage}
        accept=".png, .jpg, .jpeg"
      >
        Upload Image
      </FileButton>
    </Wrapper>
  );
}

export default ImageUploadBlock;
