import { useEffect, useState } from 'react';
import UnitService from '../../services/unitService';

export const useCommercialRentRoll = (
  selectedProperty: Object,
  promptToaster: Function,
) => {
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let subscribed = true;
    const fetchCommercialRentRoll = async () => {
      try {
        const { id, organizationId } = selectedProperty;
        const unitService = new UnitService();
        const results = await unitService.getCommercialRentRoll(
          id,
          organizationId,
        );

        setRows(results);
        setLoaded(true);
      } catch (e) {
        promptToaster({
          type: 'error',
          title: 'Error Loading Commercial Rent Roll',
          message: e.toString(),
        });
      }
    };

    if (subscribed) {
      fetchCommercialRentRoll();
    }

    return () => {
      subscribed = false;
    };
  }, [selectedProperty, promptToaster]);

  return [rows, loaded];
};
