import { isNil, omit } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import styled from 'styled-components';
import Spacer from '../../../../components/Spacer';
import Spinner from '../../../../components/Spinner';
import { Intl } from '../../../../types';
import { NO_VALUE, YES_VALUE } from '../../../../utils/redux-form-helper';
import InsuranceForm from './InsuranceForm';
import messages from './messages';
import { StopCoverageForm } from './StopCoverageForm';

const BoldAnchor = styled.a`
  font-weight: 500;
`;

type Props = {
  intl: Intl,
  show: boolean,
  handleClose: () => void,
  addInsurancePolicy: () => void,
  isLoading: boolean,
  wasPolicyCreated: boolean,
  isViewModal: boolean,
  selectedInsurance: Object,
  editInsurancePolicy: () => void,
  handleEditInsurance: () => void,
  organizationId: string,
  propertyId: string,
};

const AddHeader = ({ intl, selectedInsurance }) => (
  <>
    <i className="icon et-notes" />
    <Spacer h={8} inline />
    <h1>
      {isNil(selectedInsurance)
        ? intl.formatMessage(messages.addInsurance)
        : intl.formatMessage(messages.editRenewCoverage)}
    </h1>
    <Spacer h={15} inline />
    <span>* {intl.formatMessage(messages.allFieldsAreRequired)}</span>
  </>
);

const ViewHeader = ({ intl, showStopCoverage }) => (
  <>
    <i className="icon et-notes" />
    <Spacer h={8} inline />
    <h1>{intl.formatMessage(messages.viewInsurance)}</h1>
    {!showStopCoverage && (
      <>
        <Spacer h={15} inline />
        <BoldAnchor>{intl.formatMessage(messages.seeHistory)}</BoldAnchor>
      </>
    )}
  </>
);

const AddFooter = ({ intl, handleClose, isLoading, reduxFormSubmit, form }) => (
  <>
    <Button onClick={handleClose} disabled={isLoading}>
      {intl.formatMessage(messages.cancel)}
    </Button>
    <Spacer h={30} inline />
    <Button
      bsStyle="primary"
      onClick={() => reduxFormSubmit('InsuranceForm')}
      disabled={Boolean(form?.syncErrors) || isLoading}
    >
      {isLoading ? <Spinner small /> : intl.formatMessage(messages.saveChanges)}
    </Button>
  </>
);

const ViewFooter = ({
  intl,
  handleClose,
  isLoading,
  reduxFormSubmit,
  form,
  selectedInsurance,
  handleEditInsurance,
  handleShowStopCoverage,
}) => (
  <>
    <Button
      className="btn-danger-inverse"
      onClick={handleShowStopCoverage}
      disabled={isLoading}
    >
      {intl.formatMessage(messages.stopCoverage)}
    </Button>
    <Spacer h={15} inline />
    <Button onClick={handleClose} disabled={isLoading}>
      {intl.formatMessage(messages.cancel)}
    </Button>
    <Spacer h={15} inline />
    <Button
      bsStyle="primary"
      onClick={() => reduxFormSubmit('InsuranceForm')}
      disabled={
        (form?.values?.notes === selectedInsurance?.notes &&
          form?.values?.proofOfCoverage?.name ===
            selectedInsurance?.proofOfCoverage?.name) ||
        isLoading
      }
    >
      {isLoading ? <Spinner small /> : intl.formatMessage(messages.saveChanges)}
    </Button>
    <Spacer h={15} inline />
    <Button
      className="btn-primary-inverse"
      onClick={handleEditInsurance}
      disabled={isLoading}
    >
      {intl.formatMessage(messages.editRenewCoverage)}
    </Button>
  </>
);

function InsuranceModal({
  intl,
  show,
  handleClose,
  reduxFormSubmit,
  form,
  addInsurancePolicy,
  isLoading,
  wasPolicyCreated,
  isViewModal,
  selectedInsurance,
  editInsurancePolicy,
  handleEditInsurance,
  organizationId,
  propertyId,
}: Props) {
  const submit = (values) => {
    const isPropertyListedAsInterestedParty =
      values.isPropertyListedAsInterestedParty === YES_VALUE;
    if (values.id) {
      editInsurancePolicy(
        values.id,
        omit(
          [
            'id',
            'householdId',
            'createdAt',
            'updatedAt',
            'deletedAt',
            'documentId',
          ],
          {
            ...values,
            isPropertyListedAsInterestedParty,
          },
        ),
      );
    } else {
      addInsurancePolicy({ ...values, isPropertyListedAsInterestedParty });
    }
  };

  const [showStopCoverage, setShowStopCoverage] = useState(false);

  const handleShowStopCoverage = () => {
    setShowStopCoverage(true);
  };

  const handleHideStopCoverage = () => {
    setShowStopCoverage(false);
  };

  const submitStopCoverage = (values) => {
    submit({ ...selectedInsurance, ...values });
  };

  useEffect(() => {
    if (wasPolicyCreated) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasPolicyCreated]);

  useEffect(() => {
    handleHideStopCoverage();
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} bsSize="lg">
      <Modal.Header closeButton>
        {isViewModal ? (
          <ViewHeader intl={intl} showStopCoverage={showStopCoverage} />
        ) : (
          <AddHeader intl={intl} selectedInsurance={selectedInsurance} />
        )}
      </Modal.Header>
      <Modal.Body
        style={{
          margin: 'unset',
          ...(showStopCoverage ? { minHeight: 390 } : {}),
        }}
      >
        {showStopCoverage ? (
          <StopCoverageForm
            onSubmit={submitStopCoverage}
            onCancel={handleHideStopCoverage}
            isLoading={isLoading}
            intl={intl}
            insurance={selectedInsurance}
          />
        ) : (
          <InsuranceForm
            intl={intl}
            onSubmit={submit}
            initialValues={
              selectedInsurance
                ? {
                    ...selectedInsurance,
                    isPropertyListedAsInterestedParty:
                      selectedInsurance.isPropertyListedAsInterestedParty
                        ? YES_VALUE
                        : NO_VALUE,
                  }
                : {}
            }
            isViewModal={isViewModal}
            organizationId={organizationId}
            propertyId={propertyId}
            selectedInsurance={selectedInsurance}
          />
        )}
      </Modal.Body>
      {!showStopCoverage && (
        <Modal.Footer style={{ textAlign: 'center' }}>
          {isViewModal ? (
            <ViewFooter
              intl={intl}
              handleClose={handleClose}
              reduxFormSubmit={reduxFormSubmit}
              isLoading={isLoading}
              form={form}
              selectedInsurance={selectedInsurance}
              handleEditInsurance={handleEditInsurance}
              handleShowStopCoverage={handleShowStopCoverage}
            />
          ) : (
            <AddFooter
              intl={intl}
              handleClose={handleClose}
              reduxFormSubmit={reduxFormSubmit}
              isLoading={isLoading}
              form={form}
            />
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
}

const mapStateToProps = ({ form }) => ({
  form: form['InsuranceForm'],
});

const mapDispatchToProps = {
  reduxFormSubmit: submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceModal);
