import { useEffect } from 'react';
import moment from 'moment';
import { formatCurrency } from '@fortress-technology-solutions/fortress-component-library/utils';
import type { UseManageEffects } from './types';
const useManageEffects = ({
  editMode,
  hasEndDateAfterAnticipatedMoveOutDate,
  open,
  refetchHouseholdRentableItems,
  reset,
  selectedHouseholdRentableItemData,
  selectedPropertyFeeWithRentableItem,
  setEditMode,
  setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate,
  setHouseholdRentableItemId,
  setValue,
  refetchHouseholdRentableItemsTrigger,
}: UseManageEffects): void => {
  // For Resident Profile to disable Move Out if Household Rentable Items
  // has an end date after the anticipated move out date
  useEffect(() => {
    if (setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate) {
      setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate(
        hasEndDateAfterAnticipatedMoveOutDate,
      );
    }
  }, [
    hasEndDateAfterAnticipatedMoveOutDate,
    setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate,
    setValue,
  ]);
  // Refetch household rentable items when refetchHouseholdRentableItemsTrigger changes
  useEffect(() => {
    if (refetchHouseholdRentableItemsTrigger > 0) {
      refetchHouseholdRentableItems();
    }
  }, [refetchHouseholdRentableItemsTrigger, refetchHouseholdRentableItems]);

  // Reset form when modal closes
  useEffect(() => {
    if (!open) {
      if (editMode) {
        setHouseholdRentableItemId(null);
        setEditMode(false);
      }
      reset();
    }
  }, [open, reset, editMode, setEditMode, setHouseholdRentableItemId]);

  // Set price of item when type is selected
  useEffect(() => {
    if (selectedPropertyFeeWithRentableItem) {
      setValue('priceOfItem', selectedPropertyFeeWithRentableItem.feeAmount);
    }
  }, [selectedPropertyFeeWithRentableItem, setValue]);

  // Set form values when editing
  useEffect(() => {
    if (Object.keys(selectedHouseholdRentableItemData)?.length) {
      setValue(
        'type',
        selectedHouseholdRentableItemData.propertyRentableItem.propertyFeeId,
      );
      setValue(
        'numberId',
        selectedHouseholdRentableItemData.propertyRentableItem.id,
      );
      setValue(
        'priceOfItem',
        formatCurrency(
          selectedHouseholdRentableItemData.propertyRentableItem.propertyFee
            .feeAmount,
          { style: undefined },
        ),
      );
      setValue(
        'startDate',
        moment(selectedHouseholdRentableItemData.startDate).utc(),
      );
      if (selectedHouseholdRentableItemData.endDate) {
        setValue(
          'endDate',
          moment(selectedHouseholdRentableItemData.endDate).utc(),
        );
      }
    }
  }, [selectedHouseholdRentableItemData, setValue]);
};

export default useManageEffects;
