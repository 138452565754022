import { defineMessages } from 'react-intl';

const messages = defineMessages({
  headsUp: {
    id: 'App.ExtendSessionModal.headsUp',
    defaultMessage: 'Heads up! Your session is about to expire!'
  },
  details: {
    id: 'App.ExtendSessionModal.details',
    defaultMessage: 'Your session is about to close due to inactivity. You will be automatically signed out in:'
  },
  instructions: {
    id: 'App.ExtendSessionModal.instructions',
    defaultMessage: 'Select continue to keep your current session active.'
  },
  continue: {
    id: 'App.ExtendSessionModal.continue',
    defaultMessage: 'Continue'
  },
  signOut: {
    id: 'App.ExtendSessionModal.signOut',
    defaultMessage: 'Sign out'
  }
});

export default messages;
