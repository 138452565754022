import { put, takeLatest, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../../utils/redux-form-helper';

import {
  CREATE_MISC_TRANSACTION,
  HANDLE_SOCKET_CREATE_MISC_TRANSACTION_ERROR,
  HANDLE_SOCKET_CREATE_MISC_TRANSACTION_SUCCESS,
} from './constants';
import type { Action, Property } from '../../App/types';
import MiscTransactionsService from '../../../services/miscTransactionsService';
import {
  createMiscTransactionSuccess,
  createMiscTransactionFailure,
} from './actions';
import {
  selectCurrentLocationPath,
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../../App/selectors';
import messages from './messages';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchCreateMiscTransaction(action: Action<any>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const { id: propertyId } = selectedProperty;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const currentRoutePath = yield select(selectCurrentLocationPath);
    const notificationAttributes = {
      routePath: currentRoutePath,
      propertyId,
    };
    const transaction = action.payload;
    const transactionService = new MiscTransactionsService();
    yield transactionService.create(
      organizationId,
      propertyId,
      transaction,
      notificationAttributes,
    );

    yield put(
      toastrActions.add({
        type: 'info',
        message: renderTranslatedMessage(
          messages.createMiscTransactionPendingDescription,
        ),
        title: renderTranslatedMessage(
          messages.createMiscTransactionPendingHeader,
        ),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(
          messages.createMiscTransactionFailureHeader,
        ),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(createMiscTransactionFailure(err));
  }
}

export function* createMiscTransactionSaga(): Saga<void> {
  yield takeLatest(CREATE_MISC_TRANSACTION, fetchCreateMiscTransaction);
}

export function* handleSocketCreateMiscTransactionError(
  action: Object,
): Saga<void> {
  const {
    payload: { error },
  } = action;
  yield put(
    toastrActions.add({
      type: 'error',
      message: error,
      title: renderTranslatedMessage(
        messages.createMiscTransactionFailureHeader,
      ),
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    }),
  );
}

export function* handleSocketCreateMiscTransactionErrorSaga(): Saga<void> {
  yield takeLatest(
    HANDLE_SOCKET_CREATE_MISC_TRANSACTION_ERROR,
    handleSocketCreateMiscTransactionError,
  );
}

export function* handleSocketCreateMiscTransactionSuccess(
  action: Object,
): Saga<void> {
  const {
    payload: { propertyId, routePath },
  } = action;
  const { id: currentPropertyId } = yield select(selectSelectedProperty);
  const currentRoutePath = yield select(selectCurrentLocationPath);

  yield put(
    toastrActions.add({
      type: 'success',
      message: renderTranslatedMessage(
        messages.createMiscTransactionSuccessDescription,
      ),
      title: renderTranslatedMessage(
        messages.createMiscTransactionSuccessHeader,
      ),
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    }),
  );

  if (currentPropertyId === propertyId && currentRoutePath === routePath) {
    yield put(createMiscTransactionSuccess());
  }
}

export function* handleSocketCreateMiscTransactionSuccessSaga(): Saga<void> {
  yield takeLatest(
    HANDLE_SOCKET_CREATE_MISC_TRANSACTION_SUCCESS,
    handleSocketCreateMiscTransactionSuccess,
  );
}

export default [
  createMiscTransactionSaga,
  handleSocketCreateMiscTransactionErrorSaga,
  handleSocketCreateMiscTransactionSuccessSaga,
];
