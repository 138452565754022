import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageTransactionCodes.Title',
    defaultMessage: 'Manage Transaction Codes',
  },
  search: {
    id: 'App.ManageTransactionCodes.search',
    defaultMessage: 'Search',
  },
  transactionCode: {
    id: 'App.ManageTransactionCodes.transactionCode',
    defaultMessage: 'Transaction Code',
  },
  transactionType: {
    id: 'App.ManageTransactionCodes.transactionType',
    defaultMessage: 'Transaction Type',
  },
  subjournal: {
    id: 'App.ManageTransactionCodes.subjournal',
    defaultMessage: 'Subjournal',
  },
  description: {
    id: 'App.ManageTransactionCodes.description',
    defaultMessage: 'Description',
  },
  debitGLAccount: {
    id: 'App.ManageTransactionCodes.debitGLAccount',
    defaultMessage: 'Default Debit GL Account',
  },
  creditGLAccount: {
    id: 'App.ManageTransactionCodes.creditGLAccount',
    defaultMessage: 'Default Credit GL Account',
  },
  edit: {
    id: 'App.ManageTransactionCodes.edit',
    defaultMessage: 'Edit',
  },
  requiredCannotBeEdited: {
    id: 'App.ManageTransactionCodes.requiredCannotBeEdited',
    defaultMessage: '* Required transaction codes cannot be edited',
  },
  allCodes: {
    id: 'App.ManageTransactionCodes.allCodes',
    defaultMessage: 'All codes',
  },
  selectProperty: {
    id: 'App.ManageTransactionCodes.selectProperty',
    defaultMessage: 'Select Property',
  },
});

export default messages;
