import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.CreateUser.CreateUserForm.Header',
    defaultMessage: 'Add user'
  },
  subheader: {
    id: 'App.CreateUser.CreateUserForm.Subheader',
    defaultMessage: 'All fields are required'
  },
  requiredFields: {
    id: 'App.CreateUser.CreateUserForm.RequiredFields',
    defaultMessage: 'Required'
  },
  invalidName: {
    id: 'App.CreateUser.CreateUserForm.InvalidName',
    defaultMessage: 'First name and last name must have letters or digits only'
  },
  invalidEmailAddress: {
    id: 'App.CreateUser.CreateUserForm.InvalidEmailAddress',
    defaultMessage: 'Invalid email address'
  },
  invalidUsername: {
    id: 'App.CreateUser.CreateUserForm.InvalidUsername',
    defaultMessage: 'Username must have letters or digits only with no spaces'
  },
  successHeader: {
    id: 'App.CreateUser.CreateUserForm.SuccessHeader',
    defaultMessage: 'User Added'
  },
  successDescription: {
    id: 'App.CreateUser.CreateUserForm.SuccessDescription',
    defaultMessage: 'The user was added successfully'
  }
});

export default messages;
