import React from 'react';
import type { Role } from '../../App/types';
import messages from '../messages';
import { Button } from 'react-bootstrap';

type Props = {
  intl: Object,
  locale: string,
  roles: Array<Role>,
  onViewRole: Function,
};

export const RoleDetailRow = ({ role, intl, locale, onViewRole }) => {
  return (
    <tr className="table-section">
      <td>{role.name}</td>
      <td>{role.description}</td>
      <td>
        <span className="row-detail">
          {intl.formatMessage(messages.roleType)}:
        </span>
        {role.userRoleType.translations[locale]}
      </td>
      <td>
        <span className="row-detail">
          {intl.formatMessage(messages.assignedUsers)}:
        </span>
        {role.count}
      </td>
      <td>
        <Button bsStyle="default" bsSize="small" onClick={onViewRole(role)}>
          <i className="et-pencil" />
        </Button>
      </td>
    </tr>
  );
};

export const RoleDetails = (props: Props) => {
  const { intl, locale, roles, onViewRole } = props;

  return (
    <tbody>
      {roles.map((role: Role) => (
        <RoleDetailRow
          key={role.id}
          intl={intl}
          role={role}
          locale={locale}
          onViewRole={onViewRole}
        />
      ))}
    </tbody>
  );
};

export default RoleDetails;
