export const GET_ALL_COMMUNICATIONS =
  'app/ViewCommunication/GET_ALL_COMMUNICATIONS';
export const GET_ALL_COMMUNICATIONS_SUCCESS =
  'app/ViewCommunication/GET_ALL_COMMUNICATIONS_SUCCESS';
export const GET_ALL_COMMUNICATIONS_ERROR =
  'app/ViewCommunication/GET_ALL_COMMUNICATIONS_ERROR';
export const UPDATE_COLUMNS_SORT_VALUE =
  'app/ViewCommunication/UPDATE_COLUMNS_SORT_VALUE';
export const CLEAN_LOADED_COMMUNICATIONS =
  'app/ViewCommunication/CLEAN_LOADED_COMMUNICATIONS';
export const GET_ONE_COMMUNICATION =
  'app/ViewCommunication/GET_ONE_COMMUNICATION';
export const GET_ONE_COMMUNICATION_SUCCESS =
  'app/ViewCommunication/GET_ONE_COMMUNICATION_SUCCESS';
export const GET_ONE_COMMUNICATION_ERROR =
  'app/ViewCommunication/GET_ONE_COMMUNICATION_ERROR';
