import { merge, append, without } from 'ramda';
import * as actionTypes from './constants';
import type { State } from './types';

const initialState: State = {
  loadingHousehold: false,
  enabledAdults: [],
  enabledMinors: [],
  pendingHousehold: [],
  loadingHouseholdHistory: false,
  householdHistory: undefined,
  householdHistoryLoadError: undefined,
  leases: [],
  householdHasOpenCerts: undefined,
};

export default function (state: State = initialState, action: any): State {
  switch (action.type) {
    case actionTypes.CLEAR_STATE:
      return initialState;
    case actionTypes.GET_RESIDENT_HOUSEHOLD:
    case actionTypes.GET_ALL_PENDING_APPLICANTS:
      return merge(state, {
        loadingHousehold: true,
        householdLoadError: undefined,
      });
    case actionTypes.GET_RESIDENT_HOUSEHOLD_SUCCESS:
      return merge(state, {
        loadingHousehold: false,
        household: action.payload,
      });
    case actionTypes.GET_ALL_PENDING_APPLICANTS_SUCCESS:
      return merge(state, {
        loadingHousehold: false,
        pendingHousehold: action.payload,
      });
    case actionTypes.GET_RESIDENT_HOUSEHOLD_ERROR:
      return merge(state, {
        loadingHousehold: false,
        householdLoadError: action.payload,
      });
    case actionTypes.ENABLE_ADULT_EDIT:
      const exists = state.enabledAdults.includes(action.payload);
      return exists
        ? state
        : merge(state, {
            enabledAdults: append(action.payload, state.enabledAdults),
          });
    case actionTypes.DISABLE_ADULT_EDIT:
      return merge(state, {
        enabledAdults: without([action.payload], state.enabledAdults),
      });
    case actionTypes.ENABLE_MINOR_EDIT:
      const minorsToEdit = state.enabledMinors.includes(action.payload);
      return minorsToEdit
        ? state
        : merge(state, {
            enabledMinors: append(action.payload, state.enabledMinors),
          });
    case actionTypes.DISABLE_MINOR_EDIT:
      return merge(state, {
        enabledMinors: without([action.payload], state.enabledMinors),
      });
    case actionTypes.GET_LATEST_HOUSEHOLD_HISTORY:
      return merge(state, {
        loadingHouseholdHistory: true,
        householdHistory: undefined,
      });
    case actionTypes.GET_LATEST_HOUSEHOLD_HISTORY_SUCCESS:
      return merge(state, {
        loadingHouseholdHistory: false,
        householdHistory: action.payload,
      });
    case actionTypes.GET_LATEST_HOUSEHOLD_HISTORY_ERROR:
      return merge(state, {
        loadingHouseholdHistory: false,
        householdHistoryLoadError: action.payload,
      });
    case actionTypes.GET_HOUSEHOLD_LEASES_BY_RESIDENT_ID_SUCCESS:
      return { ...state, leases: action.payload };
    case actionTypes.GET_HOUSEHOLD_HAS_OPEN_CERTS_SUCCESS:
      return merge(state, {
        householdHasOpenCerts: action.payload,
      });
    default:
      return state;
  }
}
