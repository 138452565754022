import { defineMessages } from 'react-intl';

const messages = defineMessages({
  income: {
    id: 'App.Overview.Income.Income',
    defaultMessage: 'Income',
  },
  mtdCharges: {
    id: 'App.Overview.Income.MTDCharges',
    defaultMessage: 'MTD Charges',
  }, // remove with addDetailsToIncomeKpis
  ptdIncome: {
    id: 'App.Overview.Income.PTDIncome',
    defaultMessage: 'PTD Income:',
  },
  ptdRentCharges: {
    id: 'App.Overview.Income.PTDRentCharges',
    defaultMessage: 'PTD Rent Charges',
  },
  ptdOtherCharges: {
    id: 'App.Overview.Income.PTDOtherCharges',
    defaultMessage: 'PTD Other Charges',
  },
  ptdCredits: {
    id: 'App.Overview.Income.PTDCredits',
    defaultMessage: 'PTD Credits',
  },
  ptdWriteOffs: {
    id: 'App.Overview.Income.PTDWriteOffs',
    defaultMessage: 'PTD Write-Offs',
  },
  totalNetCharges: {
    id: 'App.Overview.Income.TotalNetCharges',
    defaultMessage: 'Total Net Charges',
  },
  totalPTDDeposits: {
    id: 'App.Overview.Income.TotalPTDDeposits',
    defaultMessage: 'Total PTD Deposits',
  },
  depositsInBank: {
    id: 'App.Overview.Income.DepositsInBank',
    defaultMessage: 'Deposits In Bank',
  },
  depositsAsPercentage: {
    id: 'App.Overview.Income.DepositsAsPercentage',
    defaultMessage: 'Deposits As A % Of All PTD Charges Minus PTD Credits',
  },
  ptdCharges: {
    id: 'App.Overview.Income.PTDCharges',
    defaultMessage: 'PTD Charges',
  },
  mtdDeposits: {
    id: 'App.Overview.Income.MTDDeposits',
    defaultMessage: 'MTD Deposits',
  },
  ptdDeposits: {
    id: 'App.Overview.Income.PTDDeposits',
    defaultMessage: 'PTD Deposits',
  }, // remove with addDetailsToIncomeKpis
  ptdDepositsAddDetailsToKpis: {
    // rename prop to 'ptdDeposits' addDetailsToIncomeKpis
    id: 'App.Overview.Income.ptdDepositsAddDetailsToKpis',
    defaultMessage: 'PTD Deposits:',
  },
  pendingDeposits: {
    id: 'App.Overview.Income.PendingDeposits',
    defaultMessage: 'Pending Deposits',
  },
  depositsPercentOfCharges: {
    id: 'App.Overview.Income.DepositsPercentOfCharges',
    defaultMessage: 'Deposits Percent of Charges',
  },
  charges: {
    id: 'App.Overview.Income.Charges',
    defaultMessage: 'Charges',
  },
  deposits: {
    id: 'App.Overview.Income.Deposits',
    defaultMessage: 'Deposits',
  },
  lastMonthCharges: {
    id: 'App.Overview.Income.LastMonthcharges',
    defaultMessage: 'Last Month Charges:',
  },
  lastPeriodCharges: {
    id: 'App.Overview.Income.LastPeriodcharges',
    defaultMessage: 'Last Period Charges:',
  },
  lastMonthDeposits: {
    id: 'App.Overview.Income.LastMonthDeposits:',
    defaultMessage: 'Last Month Deposits:',
  },
  lastPeriodDeposits: {
    id: 'App.Overview.Income.LastPeriodDeposits:',
    defaultMessage: 'Last Period Deposits:',
  },
  noData: {
    id: 'App.Overview.Income.NoData',
    defaultMessage:
      'There have not been any charges or deposits yet this month.',
  },
});

export default messages;
