import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Fields } from 'redux-form';
import AdultIndividual from './AdultIndividual';
import messages from './messages';
import { curryN } from 'ramda';

type Props = {
  fields: Fields,
  meta: {
    error: Object
  },
  intl: any,
  suffixes: Array<Object>,
  relationships: Array<Object>
};

const AdultRows = ({ fields, meta: { error }, intl, suffixes, relationships }: Props) => {
  const renderRowHeaders = fields && fields.length > 0 ? (
    <Row>
      <div className="adults-header labels-head container-of-inputs">
        <Col xs={6} sm={2}>
          <label><FormattedMessage {...messages.relationship} /></label>
        </Col>
        <Col  xs={6} sm={1}>
          <label><FormattedMessage {...messages.firstName} /></label>
        </Col>
        <Col xs={6} sm={1}>
          <label><FormattedMessage {...messages.middleName} /></label>
        </Col>
        <Col  xs={6} sm={2}>
          <label><FormattedMessage {...messages.lastName} /></label>
        </Col>
        <Col  xs={6} sm={1}>
          <label><FormattedMessage {...messages.suffix} /></label>
        </Col>
        <Col  xs={6} sm={1}>
          <label><FormattedMessage {...messages.preferredName} /></label>
        </Col>
        <Col  xs={6} sm={2}>
          <label><FormattedMessage {...messages.phone} /></label>
        </Col>
        <Col  xs={6} sm={2}>
          <label><FormattedMessage {...messages.email} /></label>
        </Col>
      </div>
      <div className="container-of-trash">
        <div className="row-remove-btn">
          <label><FormattedMessage {...messages.delete} /></label>
        </div>
      </div>
    </Row>
   ) : null;

  const fieldRemoveCurried = curryN(2, fields.remove);
  const renderRows = fields && fields.length > 0
    ? fields.map((adult, index) => (
      <AdultIndividual
        intl={intl}
        key={index}
        adult={adult}
        onDelete={fieldRemoveCurried(index)}
        suffixes={suffixes}
        relationships={relationships}
      />))
    : null;

  return (
    <div>
      {renderRowHeaders}
      {renderRows}
      {fields.length < 10 && (
        <Row >
          <a className="btn-text" onClick={() => fields.push()}>
            <FormattedMessage {...messages.addDetail} />
          </a>
        </Row>
      )}
    </div>
  );
};

export default AdultRows;
