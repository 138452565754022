import { omit } from 'ramda';
import messages from './messages';
import { put, select, takeLatest, throttle } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import ProspectProfileService from '../../services/prospectProfileService';
import ActivityService from '../../services/activityService';
import PortalService from '../../services/portalService';
import * as ActionTypes from './constants';
import * as peopleProfileActions from './actions';

import type { Saga } from 'redux-saga';
import { selectSortingMeta } from './selectors';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';

import { renderTranslatedMessage } from '../../utils/redux-form-helper';

import type { Action, Property } from '../App/types';
import type { Activity, Prospect } from '../ProspectProfile/types';
import type { StatusChange } from './types';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetOneProspect(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const prospectsService = new ProspectProfileService();
    const response = yield prospectsService.getOne(
      action.payload,
      selectedProperty.id,
      organizationId,
    );
    yield put(peopleProfileActions.getOneProspectSuccess(response));
  } catch (err) {
    yield put(peopleProfileActions.getOneProspectError(err));
  }
}

export function* getOneProspectSaga(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ONE_PROSPECT, fetchGetOneProspect);
}

export function* postCreateProspectActivity({
  payload,
}: Action<Activity>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const activityService = new ActivityService();
    yield activityService.save(payload, organizationId);
    payload?.refresh?.();
    yield put(peopleProfileActions.createProspectActivitySuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.successActivityCreateDescription,
        ),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(peopleProfileActions.getOneProspect(payload.prospectId));
    const sorting = yield select(selectSortingMeta);
    const sortBy = Object.keys(sorting)[Object.keys(sorting).length - 1];
    const order =
      sorting[sortBy] === 'sortable'
        ? 'ASC'
        : sorting[sortBy] === 'ascending'
        ? 'ASC'
        : 'DESC';
    yield put(
      peopleProfileActions.getProspectCompletedActivities(
        payload.prospectId,
        1,
        10,
        sortBy,
        order,
      ),
    );
    yield put(
      peopleProfileActions.getProspectAllActivities(
        payload.prospectId,
        1,
        10,
        sortBy,
        order,
      ),
    );
    yield put(
      peopleProfileActions.getProspectPendingActivities(payload.prospectId),
    );
  } catch (err) {
    yield put(peopleProfileActions.createProspectActivityError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* createProspectActivity(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.CREATE_PROSPECT_ACTIVITY,
    postCreateProspectActivity,
  );
}

export function* fetchGetProspectPendingActivities(action: Object): Saga<void> {
  try {
    const activityService = new ActivityService();
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const response = yield activityService.getPendingByProspect(
      action.payload,
      selectedProperty.id,
      organizationId,
    );
    yield put(
      peopleProfileActions.getProspectPendingActivitiesSuccess(response),
    );
  } catch (err) {
    yield put(peopleProfileActions.getProspectPendingActivitiesError(err));
  }
}

export function* getProspectPendingActivitiesSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_PROSPECT_PENDING_ACTIVITIES,
    fetchGetProspectPendingActivities,
  );
}

export function* fetchGetProspectCompletedActivities(
  action: Object,
): Saga<void> {
  try {
    const activityService = new ActivityService();
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const response = yield activityService.getCompletedByProspect(
      action.payload.id,
      selectedProperty.id,
      action.payload.pageNumber,
      action.payload.limit,
      action.payload.sort,
      action.payload.order,
      'history',
      organizationId,
    );
    yield put(
      peopleProfileActions.getProspectCompletedActivitiesSuccess(
        response.results,
        response.meta,
      ),
    );
  } catch (err) {
    yield put(peopleProfileActions.getProspectCompletedActivitiesError(err));
  }
}

export function* getProspectCompletedActivitiesSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_PROSPECT_COMPLETED_ACTIVITIES,
    fetchGetProspectCompletedActivities,
  );
}

export function* fetchGetProspectAllActivities(action: Object): Saga<void> {
  try {
    const activityService = new ActivityService();
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const response = yield activityService.getAllByProspect(
      action.payload.id,
      selectedProperty.id,
      action.payload.pageNumber,
      action.payload.limit,
      action.payload.sort,
      action.payload.order,
      'history',
      organizationId,
    );
    yield put(
      peopleProfileActions.getProspectAllActivitiesSuccess(
        response.results,
        response.meta,
      ),
    );
  } catch (err) {
    yield put(peopleProfileActions.getProspectAllActivitiesError(err));
  }
}

export function* getProspectAllActivitiesSaga(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_PROSPECT_ALL_ACTIVITIES,
    fetchGetProspectAllActivities,
  );
}

export function* deleteDeleteProspectActivity({
  payload,
}: Action<Activity>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const activityService = new ActivityService();
    yield activityService.delete(payload, organizationId);
    payload?.refresh?.();
    yield put(peopleProfileActions.deleteProspectActivitySuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.successActivityDeleteDescription,
        ),
        title: renderTranslatedMessage(messages.successHeaderDeleteActivity),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(peopleProfileActions.getOneProspect(payload.prospectId));
    const sorting = yield select(selectSortingMeta);
    const sortBy = Object.keys(sorting)[Object.keys(sorting).length - 1];
    const order =
      sorting[sortBy] === 'sortable'
        ? 'ASC'
        : sorting[sortBy] === 'ascending'
        ? 'ASC'
        : 'DESC';
    yield put(
      peopleProfileActions.getProspectCompletedActivities(
        payload.prospectId,
        1,
        10,
        sortBy,
        order,
      ),
    );
    yield put(
      peopleProfileActions.getProspectAllActivities(
        payload.prospectId,
        1,
        10,
        sortBy,
        order,
      ),
    );
    yield put(
      peopleProfileActions.getProspectPendingActivities(payload.prospectId),
    );
  } catch (err) {
    yield put(peopleProfileActions.deleteProspectActivityError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* deleteProspectActivity(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.DELETE_PROSPECT_ACTIVITY,
    deleteDeleteProspectActivity,
  );
}

export function* putUpdateProspectActivity({
  payload,
}: Action<Activity>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const activityService = new ActivityService();

    const editPayload = omit(
      [
        'id',
        // $FlowFixMe
        'saveAndClose',
        // $FlowFixMe
        'saveAndAddNew',
        'prospect',
        'createdAt',
        'updatedAt',
        'deletedAt',
        'lastUpdatedById',
        'ownedBy',
        'completionStatus',
        'activityType',
        // $FlowFixMe
        'updatedById',
        // $FlowFixMe
        'createdById',
        // $FlowFixMe
        'updatedBy',
      ],
      payload,
    );
    yield activityService.edit(editPayload, payload.id, organizationId);
    payload?.refresh?.();
    yield put(peopleProfileActions.updateProspectActivitySuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.successActivityUpdateDescription,
        ),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(peopleProfileActions.getOneProspect(payload.prospectId));
    const sorting = yield select(selectSortingMeta);
    const sortBy = Object.keys(sorting)[Object.keys(sorting).length - 1];
    const order =
      sorting[sortBy] === 'sortable'
        ? 'ASC'
        : sorting[sortBy] === 'ascending'
        ? 'ASC'
        : 'DESC';
    yield put(
      peopleProfileActions.getProspectCompletedActivities(
        payload.prospectId,
        1,
        10,
        sortBy,
        order,
      ),
    );
    yield put(
      peopleProfileActions.getProspectAllActivities(
        payload.prospectId,
        1,
        10,
        sortBy,
        order,
      ),
    );
    yield put(
      peopleProfileActions.getProspectPendingActivities(payload.prospectId),
    );
  } catch (err) {
    yield put(peopleProfileActions.updateProspectActivityError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* updateProspectActivity(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.UPDATE_PROSPECT_ACTIVITY,
    putUpdateProspectActivity,
  );
}

export function* putUpdateProspect({
  payload: { prospect, statusChange },
}: Action<{ prospect: Prospect, statusChange: StatusChange }>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const prospectId = prospect.id || '';
    if (!prospect.id) {
      throw new Error('Cannot update a prospect without an id.');
    }
    const prospectService = new ProspectProfileService();
    const editPayload = omit(
      [
        'createdAt',
        'updatedAt',
        'assignedTo',
        'currentProspectStatus',
        'tourStatus',
        'prospectStatusChangeLog',
        'referralType',
        'isOptedOut',
      ],
      prospect,
    );
    yield prospectService.update(editPayload, organizationId);
    if (statusChange) {
      yield prospectService.changeStatus(
        prospect,
        statusChange.statusId,
        statusChange.notes,
        organizationId,
      );
    }
    yield put(peopleProfileActions.updateProspectSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescriptionProspect),
        title: renderTranslatedMessage(messages.successHeaderProspect),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(peopleProfileActions.getOneProspect(prospectId));
    const sorting = yield select(selectSortingMeta);
    const sortBy = Object.keys(sorting)[Object.keys(sorting).length - 1];
    const order =
      sorting[sortBy] === 'sortable'
        ? 'ASC'
        : sorting[sortBy] === 'ascending'
        ? 'ASC'
        : 'DESC';
    yield put(
      peopleProfileActions.getProspectCompletedActivities(
        prospectId,
        1,
        10,
        sortBy,
        order,
      ),
    );
    yield put(
      peopleProfileActions.getProspectAllActivities(
        prospectId,
        1,
        10,
        sortBy,
        order,
      ),
    );
    yield put(peopleProfileActions.getProspectPendingActivities(prospectId));
  } catch (err) {
    yield put(peopleProfileActions.updateProspectError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* updateProspect(): Saga<void> {
  yield throttle(500, ActionTypes.UPDATE_PROSPECT, putUpdateProspect);
}

export function* putAssignProspect({ payload }: Action<Prospect>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const prospectId = payload.id || '';
    if (!payload.id) {
      throw new Error('Cannot update a prospect without an id.');
    }
    const prospectService = new ProspectProfileService();
    const editPayload = omit(
      [
        'createdAt',
        'updatedAt',
        'assignedTo',
        'currentProspectStatus',
        'tourStatus',
      ],
      payload,
    );
    yield prospectService.assign(editPayload, organizationId);
    yield put(peopleProfileActions.updateProspectSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescriptionProspect),
        title: renderTranslatedMessage(messages.successHeaderProspect),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(peopleProfileActions.getOneProspect(prospectId));
  } catch (err) {
    yield put(peopleProfileActions.updateProspectError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* assignProspect(): Saga<void> {
  yield throttle(500, ActionTypes.ASSIGN_PROSPECT, putAssignProspect);
}

export function* sendProspectPortalClaimEmail({
  payload,
}: Action<Object>): Saga<void> {
  try {
    const { prospectId } = payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const portalService = new PortalService();
    yield portalService.sendPortalInviteEmail(
      organizationId,
      selectedProperty.id,
      prospectId,
      'prospect',
    );
    yield put(peopleProfileActions.getOneProspect(prospectId));
    yield put(
      toastrActions.add({
        type: 'success',
        message: 'Portal Invite Email Sent',
        title: 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

function* sendProspectPortalClaimEmailSaga(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.SEND_PROSPECT_PORTAL_INVITE_EMAIL,
    sendProspectPortalClaimEmail,
  );
}

export default [
  getOneProspectSaga,
  createProspectActivity,
  getProspectPendingActivitiesSaga,
  getProspectCompletedActivitiesSaga,
  getProspectAllActivitiesSaga,
  deleteProspectActivity,
  updateProspectActivity,
  updateProspect,
  assignProspect,
  sendProspectPortalClaimEmailSaga,
];
