import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewProperty.AffordableSetup.HOMESetup.title',
    defaultMessage: 'HOME',
  },
  fixed: {
    id: 'App.ViewProperty.AffordableSetup.HOMESetup.fixed',
    defaultMessage: 'Fixed',
  },
  floating: {
    id: 'App.ViewProperty.AffordableSetup.HOMESetup.floating',
    defaultMessage: 'Floating',
  },
  hudSection8: {
    id: 'App.ViewProperty.AffordableSetup.HOMESetup.hudSection8',
    defaultMessage: 'HUD Section 8',
  },
  censusLongFormOrIRS1040: {
    id: 'App.ViewProperty.AffordableSetup.HOMESetup.censusLongFormOrIRS1040',
    defaultMessage: 'Census Long Form or IRS 1040',
  },
  homeGroupHome: {
    id: 'App.ViewProperty.AffordableSetup.HOMESetup.homeGroupHome',
    defaultMessage: 'HOME Group Home',
  },
  none: {
    id: 'App.ViewProperty.AffordableSetup.HOMESetup.none',
    defaultMessage: 'None',
  },
});

export default messages;
