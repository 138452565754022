import React from 'react';

export const ManageAvailabilityTableFooter = (counts: Object, intl: Object) => {
  return () => {
    return (
      <tfoot className="table-footer">
        <tr>
          <td />
          <td />
          <td />
          <td>{counts.sqFeet ? intl.formatNumber(+counts.sqFeet) : ''}</td>
          <td />
          <td />
          <td />
          <td>
            {counts.marketRent
              ? intl.formatNumber(+counts.marketRent, {
                  style: 'currency',
                  currency: 'USD',
                })
              : ''}
          </td>
          <td>
            {counts.priorLeasedRent
              ? intl.formatNumber(+counts.priorLeasedRent, {
                  style: 'currency',
                  currency: 'USD',
                })
              : ''}
          </td>
          <td>
            {counts.currentLeasedRent
              ? intl.formatNumber(+counts.currentLeasedRent, {
                  style: 'currency',
                  currency: 'USD',
                })
              : ''}
          </td>
          <td />
          <td />
          <td />
          <td />
          <td>
            {counts.quotingRent
              ? intl.formatNumber(+counts.quotingRent, {
                  style: 'currency',
                  currency: 'USD',
                })
              : ''}
          </td>
          <td>
            {counts.otherUnitFees
              ? intl.formatNumber(+counts.otherUnitFees, {
                  style: 'currency',
                  currency: 'USD',
                })
              : ''}
          </td>
          <td />
          <td />
          <td />
          <td />
        </tr>
      </tfoot>
    );
  };
};
