import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import { defaultTo, find, omit, propEq, reject } from 'ramda';
import * as editProspectHouseholdActions from './actions';
import {
  getAllApplicantTypes,
  getAllNameSuffixes,
  getAllPetTypes,
  getAllPropertyClasses,
  getAllRelationships,
} from '../App/actions';
import { EditProspectHouseholdForm } from '../../components/EditHouseholdForm';
import {
  generateInitialAdult,
  generateInitialMinor,
  generateInitialPet,
} from '../../components/EditHouseholdForm/utils';

import {
  getApplicablePropertyClassOptions,
  getApplicantTypeOptions,
  getMinorRelationshipOptions,
  getNameSuffixOptions,
  getPetTypeOptions,
  getRelationshipOptions,
} from '../App/selectors';
import { getPropertyId, getProspectName } from './selectors';
import messages from './messages';

import type { GlobalState, Property } from '../App/types';
import type { Prospect } from '../ProspectProfile/types';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type Option = {
  value: string,
  text: string,
  disabled: boolean,
};

type StateProps = {
  prospectId: string,
  prospect?: Prospect,
  prospectName: string,
  propertyId: string,
  primaryApplicantId: string,
  selectedProperty?: Property,
  nameSuffixes: Array<Option>,
  propertyClasses: Array<Option>,
  relationships: Array<Option>,
  minorRelationships: Array<Option>,
  applicantTypes: Array<Option>,
  petTypes: Array<Option & { petBreeds: Array<Option> }>,
  locale: string,
};
type Props = {
  intl: any,
  history: Object,
  actions: {
    clean: () => void,
    getOneProspect: (string) => void,
    editHousehold: (Prospect) => void,
    getAllNameSuffixes: () => void,
    getAllPropertyClasses: () => void,
    getAllApplicantTypes: () => void,
    getAllRelationships: () => void,
    getAllPetTypes: () => void,
  },
};

type State = {};

export class EditProspectHousehold extends Component<
  StateProps & Props,
  State,
> {
  componentWillMount() {
    this.props.actions.clean();
  }

  componentDidMount() {
    this.props.actions.getAllNameSuffixes();
    this.props.actions.getAllApplicantTypes();
    this.props.actions.getAllRelationships();
    this.props.actions.getAllPropertyClasses();
    this.props.actions.getAllPetTypes();
    this.props.actions.getOneProspect(this.props.prospectId);
  }

  removeDefaultFromSelectFields = (values: any) => ({
    ...values,
    adults: values.adults.map((adult) => ({
      ...adult,
      suffixId: adult.suffixId === 'default' ? undefined : adult.suffixId,
      relationship:
        adult.relationship === 'default' ? undefined : adult.relationship,
    })),
    minors: values.minors.map((minor) => ({
      ...minor,
      suffixId: minor.suffixId === 'default' ? undefined : minor.suffixId,
      relationship:
        minor.relationship === 'default' ? undefined : minor.relationship,
    })),
    pets: values.pets.map((pet) => ({
      ...pet,
      petTypeId: pet.petTypeId === 'default' ? undefined : pet.petTypeId,
      breedId: pet.breedId === 'default' ? undefined : pet.breedId,
      weight: pet.weight === '' ? undefined : pet.weight,
      petTag: pet.petTag === '' ? undefined : pet.petTag,
    })),
  });
  handleCancel = () => {
    const route = `/prospect/${this.props.prospectId}`;
    navigateToUrlWithSelectedPropertyId(route);
  };
  getProspectFromValues = (values: any): ?Prospect => {
    const { prospect } = this.props;
    if (!prospect) {
      return undefined;
    }

    const isProspectFunc = propEq('id', values.primaryApplicantId);

    const updatedProspect = defaultTo({}, find(isProspectFunc, values.adults));

    const scrubbedProspect = omit(['portalUser'], prospect);

    return {
      ...scrubbedProspect,
      primaryApplicantId: values.primaryApplicantId,
      firstName: updatedProspect.firstName,
      lastName: updatedProspect.lastName,
      middleName: updatedProspect.middleName,
      phoneNumber: updatedProspect.phone,
      emailAddress: updatedProspect.email,
      preferredName: updatedProspect.preferredName,
      suffixId: updatedProspect.suffixId,
      additionalOccupants: [
        ...reject(isProspectFunc, values.adults).map((adult) => ({
          id: adult.id,
          firstName: adult.firstName,
          middleName: adult.middleName,
          lastName: adult.lastName,
          emailAddress: adult.email,
          phoneNumber: adult.phone,
          type: 'Other Adult',
          preferredName: adult.preferredName,
          relationshipId: adult.relationship,
          suffixId: adult.suffixId,
        })),
        ...values.minors.map((minor) => ({
          id: minor.id,
          firstName: minor.firstName,
          middleName: minor.middleName,
          lastName: minor.lastName,
          age: minor.age,
          type: 'Minor',
          preferredName: minor.preferredName,
          relationshipId: minor.relationship,
          suffixId: minor.suffixId,
        })),
      ],
      petOccupants: [
        ...values.pets.map((pet) => ({
          id: pet.id,
          name: pet.name,
          weight: pet.weight,
          serviceAnimal: pet.isServiceAnimal,
          petTypeId: pet.petTypeId,
          petBreedId: pet.breedId,
          petTag: pet.petTag,
        })),
      ],
    };
  };
  handleSubmit = (values: any) => {
    const prospectToUpdate = this.getProspectFromValues(
      this.removeDefaultFromSelectFields(values),
    );
    if (values.HOHChange?.previousHOHId && values.HOHChange?.newHOHId) {
      prospectToUpdate.HOHChange = values.HOHChange;
    }
    if (prospectToUpdate) {
      this.props.actions.editHousehold(prospectToUpdate);
    }
  };

  render() {
    const isLoading =
      this.props.nameSuffixesIsLoading ||
      this.props.propertyClassesIsLoading ||
      this.props.applicantTypesIsLoading ||
      this.props.relationshipsIsLoading ||
      this.props.petTypesIsLoading;

    const { prospect, prospectName, propertyId, prospectId } = this.props;

    const adults = generateInitialAdult(prospect);
    const minors = generateInitialMinor(prospect);
    const pets = generateInitialPet(prospect);

    return (
      <>
        {isLoading ? (
          <Spinner
            sx={{
              height: '100vh',
              width: '100%',
              position: 'fixed',
              zIndex: 1,
            }}
          />
        ) : (
          <DocumentTitle title="Fortress - Edit Household">
            {prospect && (
              <EditProspectHouseholdForm
                initialValues={{
                  adults,
                  minors,
                  pets,
                  prospectId,
                  propertyId,
                  primaryApplicantId: prospect.primaryApplicantId,
                }}
                pageTitle={`${this.props.intl.formatMessage(
                  messages.title,
                )} ${prospectName}`}
                suffixList={this.props.nameSuffixes}
                relationshipList={this.props.relationships}
                minorRelationshipList={this.props.minorRelationships}
                applicantTypeList={this.props.applicantTypes}
                propertyClassList={this.props.propertyClasses}
                handleCancel={this.handleCancel}
                onSubmit={this.handleSubmit}
                petTypeList={this.props.petTypes}
                primaryApplicantId={this.props.primaryApplicantId}
                handleEditHouseholdMember={() => {}}
              />
            )}
          </DocumentTitle>
        )}
      </>
    );
  }
}

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): StateProps => {
  const { app, languageProvider, editProspectHousehold } = state;
  const prospect = editProspectHousehold.prospect;
  return {
    prospectId: ownProps.match.params.prospectId,
    prospect,
    selectedProperty: app.selectedProperty,
    nameSuffixes: getNameSuffixOptions(state),
    prospectName: getProspectName(state),
    propertyId: getPropertyId(state),
    propertyClasses: getApplicablePropertyClassOptions(state),
    relationships: getRelationshipOptions(state),
    minorRelationships: getMinorRelationshipOptions(state),
    applicantTypes: getApplicantTypeOptions(state),
    petTypes: getPetTypeOptions(state),
    locale: languageProvider.locale,
    primaryApplicantId: editProspectHousehold.primaryApplicantId,
    relationshipsIsLoading: state.app.relationshipsIsLoading,
    nameSuffixesIsLoading: state.app.nameSuffixesIsLoading,
    propertyClassesIsLoading: state.app.propertyClassesIsLoading,
    applicantTypesIsLoading: state.app.applicantTypesIsLoading,
    petTypesIsLoading: state.app.petTypesIsLoading,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...editProspectHouseholdActions,
      getAllNameSuffixes,
      getAllPropertyClasses,
      getAllApplicantTypes,
      getAllRelationships,
      getAllPetTypes,
    },
    dispatch,
  );
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EditProspectHousehold));
