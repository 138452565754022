import type { Action } from '../App/types';
import * as ActionTypes from './constants';

const initialState = {
  assignedUnits: {},
  counts: {},
  meta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  specials: [],
  statuses: [],
  units: [],
  isLoading: false,
};

const manageUnitAvailabilityReducer = (
  state: Object = initialState,
  action: Action<*, *>,
): Object => {
  const { type, payload, meta, error, counts } = action;
  switch (type) {
    case ActionTypes.GET_ALL_UNITS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ActionTypes.GET_ALL_UNITS_SUCCESS: {
      return {
        ...state,
        units: payload?.units ?? [],
        amenities: payload?.amenities ?? [],
        meta,
        counts,
        isLoading: false,
      };
    }
    case ActionTypes.GET_ALL_UNITS_ERROR: {
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case ActionTypes.GET_ALL_UNIT_STATUSES_SUCCESS: {
      return { ...state, statuses: payload };
    }
    case ActionTypes.GET_ASSIGNED_UNITS_SUCCESS: {
      return { ...state, assignedUnits: payload };
    }
    case ActionTypes.GET_ALL_PROPERTY_SPECIALS_SUCCESS: {
      return { ...state, specials: payload };
    }
    case ActionTypes.GET_ASSIGNED_UNITS_ERROR:
    case ActionTypes.GET_ALL_UNIT_STATUSES_ERROR:
    case ActionTypes.GET_ALL_PROPERTY_SPECIALS_ERROR: {
      return { ...state, error };
    }
    case ActionTypes.CLEAN_LOADED_UNITS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default manageUnitAvailabilityReducer;
