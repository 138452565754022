import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { pathOr, update } from 'ramda';

import {
  renderCheckboxField,
  renderRadioOption,
  renderTextField,
  renderSelectField,
} from '../../utils/redux-form-helper';
import type { SelectOption } from '../../containers/App/types';
import { getStateOptions } from '../../containers/App/selectors';
import { getAllStates } from '../../containers/App/actions';
import { bindActionCreators } from 'redux';
import validate from './validate';
import messages from './messages';

type Props = {
  show: boolean,
  onClose: Function,
  handleSubmit: Function,
  onSubmit: Function,
  valid: boolean,
  reset: Function,
  headerData: Object,
  property: Object,
  currentAddress: Object,
  states: Array<SelectOption>,
  actions: any,
  pristine: boolean,
  invalid: boolean,
};

const ApplicantRefundModal = (props: Props) => {
  const handleClose = () => {
    const { reset, onClose } = props;
    reset();
    onClose();
  };

  const [disableAddressFields, setDisableAddressFields] = useState(true);

  useEffect(() => {
    props.actions.getAllStates();
  }, [props.actions]);

  const renderModalHeader = () => {
    return (
      <Modal.Header closeButton>
        <i className="icon et-money" />
        <Modal.Title componentClass="h1">
          <FormattedMessage {...messages.refundtoApplicant} />
        </Modal.Title>
      </Modal.Header>
    );
  };

  const renderModalFooter = () => {
    const { handleSubmit, reset, pristine, invalid } = props;

    return (
      <Modal.Footer>
        <Row>
          <Col xs={6}>
            <Button
              bsStyle="default"
              className="pull-right"
              onClick={handleClose}
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              bsStyle="primary"
              className="pull-left"
              type="submit"
              onClick={() => {
                handleSubmit();
                reset();
              }}
              disabled={invalid || pristine}
            >
              <FormattedMessage {...messages.generateRefund} />
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    );
  };

  const handleForwardAddressFields = (option) => {
    const { reset } = props;
    if (option === 'forwardingAddress') setDisableAddressFields(false);
    else {
      reset();
      setDisableAddressFields(true);
    }
  };

  const renderModalFormBody = () => {
    const { headerData, property, currentAddress, states, pristine } = props;

    const depositHeld = parseFloat(
      headerData.deposits.runningSecurityBalanceHeld,
    );
    const subsidy = pathOr('0.0', ['Subsidy'], headerData.subjournalsBalance);
    const residentLedgerBalance =
      parseFloat(headerData.balance) - parseFloat(subsidy);

    const currentAddress1 = pathOr('', ['street'], currentAddress);
    const currentCity = pathOr('', ['city'], currentAddress);
    const currentState = pathOr('', ['state'], currentAddress);
    const currentZip = pathOr('', ['zipCode'], currentAddress);
    const propertyAddress1 = pathOr('', ['physicalStreetAddress1'], property);
    const propertyAddress2 = pathOr('', ['physicalStreetAddress2'], property);
    const propertyCity = pathOr('', ['physicalCity'], property);
    const propertyState = pathOr('', ['physicalState'], property);
    const propertyZip = pathOr('', ['physicalZip'], property);

    const stateOptions = update(
      0,
      { value: '', text: 'Choose', disabled: false },
      states,
    );

    return (
      <Row className="scrollable-modal__content">
        <div className="container-fluid">
          <Row>
            <Col xs={12} md={5}>
              <Row>
                <strong>
                  <FormattedMessage {...messages.refundAmount} />
                </strong>
              </Row>
              <Row>
                <Col xs={6} ml={1}>
                  <Row>
                    <FormattedMessage {...messages.depositHeld} />
                  </Row>
                  <Row>
                    <FormattedMessage {...messages.residentLedgerBalance} />
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <div className="text-right">
                      <FormattedNumber
                        className="pull-right"
                        value={depositHeld}
                        style={{ value: 'currency' }.value}
                        currency="USD"
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="text-right">
                      <FormattedNumber
                        value={residentLedgerBalance}
                        style={{ value: 'currency' }.value}
                        currency="USD"
                      />
                    </div>
                  </Row>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={6}>
                  <Row>
                    <FormattedMessage {...messages.totalRefund} />
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <div className="text-right">
                      <FormattedNumber
                        value={depositHeld - residentLedgerBalance}
                        style={{ value: 'currency' }.value}
                        currency="USD"
                      />
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="col-xs-offset-1" xs={12} md={6}>
              <Row>
                <strong>
                  <FormattedMessage {...messages.selectMailingAddress} />
                </strong>
              </Row>
              <Row className={'padtop10'}>
                <Field
                  name="mailingAddress"
                  option={{
                    value: 'primaryApplicantCurrentAddress',
                    text: (
                      <strong>
                        <FormattedMessage
                          {...messages.primaryApplicantCurrentAddress}
                        />
                      </strong>
                    ),
                  }}
                  component={renderRadioOption}
                  disabled={!currentAddress}
                  onChange={() =>
                    handleForwardAddressFields('primaryApplicantCurrentAddress')
                  }
                />
                <Col xs={12}>
                  {!currentAddress ? (
                    <div>
                      <FormattedMessage {...messages.noneAvailable} />
                    </div>
                  ) : (
                    <div>
                      <div>{currentAddress1}</div>
                      <div>
                        {currentCity} {currentState} {currentZip}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className={'padtop10'}>
                <Field
                  name="mailingAddress"
                  option={{
                    value: 'propertyAddress',
                    text: (
                      <strong>
                        <FormattedMessage {...messages.propertyAddress} />
                      </strong>
                    ),
                  }}
                  component={renderRadioOption}
                  onChange={() => handleForwardAddressFields('propertyAddress')}
                />
                <Col xs={12}>
                  <div>
                    {propertyAddress1}
                    {propertyAddress2}
                  </div>
                  <div>
                    {propertyCity} {propertyState} {propertyZip}
                  </div>
                </Col>
              </Row>
              <Row className={'padtop10'}>
                <Field
                  name="mailingAddress"
                  option={{
                    value: 'forwardingAddress',
                    text: (
                      <strong>
                        <FormattedMessage
                          {...messages.otherForwardingAddress}
                        />
                      </strong>
                    ),
                  }}
                  component={renderRadioOption}
                  onChange={() =>
                    handleForwardAddressFields('forwardingAddress')
                  }
                />
                <Row className={'padtop10'}>
                  <Col sm={12} md={6}>
                    <Field
                      name="address1"
                      component={renderTextField}
                      bsSize="lg"
                      placeholder="Address Line 1"
                      maxLength="35"
                      disabled={disableAddressFields || pristine}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <Field
                      name="address2"
                      component={renderTextField}
                      bsSize="lg"
                      placeholder="Address Line 2"
                      maxLength="35"
                      disabled={disableAddressFields || pristine}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <Field
                      name="city"
                      component={renderTextField}
                      bsSize="lg"
                      placeholder="City"
                      maxLength="35"
                      disabled={disableAddressFields || pristine}
                    />
                  </Col>
                  <Col sm={6} md={3}>
                    <Field
                      name="state"
                      component={renderSelectField}
                      options={stateOptions}
                      bsSize="lg"
                      disabled={disableAddressFields || pristine}
                    />
                  </Col>
                  <Col sm={6} md={3}>
                    <Field
                      name="zipCode"
                      component={renderTextField}
                      bsSize="lg"
                      placeholder="Zip Code / Postal Code"
                      disabled={disableAddressFields || pristine}
                    />
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
          <Row>
            <Field
              name="hasConfirmed"
              component={renderCheckboxField}
              label="I confirm that the amount above should be refunded to the applicant. I have ensured all applicable
              charges and credits have been applied to the ledger and payments are accurate and settled by the bank."
            />
          </Row>
        </div>
      </Row>
    );
  };

  const { show } = props;
  return (
    <form>
      <Modal bsSize="lg" backdrop show={show} onHide={() => handleClose()}>
        {renderModalHeader()}
        <Modal.Body>{renderModalFormBody()}</Modal.Body>
        {renderModalFooter()}
      </Modal>
    </form>
  );
};

export const mapStateToProps = (state: Object): Object => {
  return {
    states: getStateOptions(state),
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    actions: bindActionCreators(
      {
        getAllStates,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'ApplicantRefundForm',
    validate: validate,
  })(ApplicantRefundModal),
);
