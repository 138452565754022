import React, { Component } from 'react';
import { Grid, Form, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';

import ElementWithPermissions from '../../../components/ElementWithPermissions';
import SubmitButton from '../../../components/SubmitButton';

import messages from '../messages';

import {
  Code,
  Description,
  TransactionType,
  Subjournal,
  DefaultDebitGLAccount,
  DefaultCreditGLAccount,
  PropertySelector,
} from './FormFields';

type Props = {
  intl: any,
  history: any,
  properties: Array<Object>,
  handleSubmit: Function,
  change: Function,
  valid: boolean,
  submitting: boolean,
  selectedProperties: Array<string>,
  handleMultiselectChange: Function,
  nonePropertiesSelected: boolean,
  hideMultiselect: boolean,
  handleCancel: Function,
  showErrorMessage: boolean,
  errorMessage: string,
  pristine: boolean,
  handlePropertiesSelectedError: Function,
  isSubmitting: boolean,
  transactionTypeOptions: Array<Object>,
  subjournalOptions: Array<Object>,
};

class EditMasterTransactionCodeForm extends Component<Props> {
  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.props.change('properties', nextProps.selectedProperties);
      if (nextProps.selectedProperties.length >= 1) {
        this.props.handlePropertiesSelectedError(false);
      }
    }
  }

  render() {
    const {
      intl,
      history,
      properties,
      handleSubmit,
      selectedProperties,
      handleMultiselectChange,
      nonePropertiesSelected,
      isSubmitting,
      hideMultiselect,
      transactionTypeOptions,
      subjournalOptions,
    } = this.props;
    const selectedCount = selectedProperties.length;
    const availableCount = properties.length - selectedCount;

    return (
      <Grid className="bodywrap col-centered" fluid={true}>
        <Form>
          <Row>
            <a className="btn-text" onClick={() => history.goBack()}>
              <i className="et-chevron-left" />
              {intl.formatMessage(messages.goback)}
            </a>
          </Row>
          <Row className={'padtop20'}>
            <Col xs={12}>
              <h1
                className="title"
                style={{ display: 'inline-block', marginRight: '5px' }}
              >
                {intl.formatMessage(messages.header)}
              </h1>
              <p>
                * <FormattedMessage {...messages.propertiesDisabled} />
              </p>
            </Col>
          </Row>

          <Row className={'padtop20'}>
            <Code disabled intl={intl} />
            <Description disabled intl={intl} />
          </Row>
          <Row className={'padtop20'}>
            <TransactionType
              disabled
              transactionTypes={transactionTypeOptions}
              intl={intl}
            />
            <Subjournal disabled intl={intl} subjournals={subjournalOptions} />
          </Row>
          <Row className={'padtop20'}>
            <DefaultDebitGLAccount disabled intl={intl} />
            <DefaultCreditGLAccount disabled intl={intl} />
          </Row>
          <Row className={'padtop20'}>
            <PropertySelector
              intl={intl}
              availableCount={availableCount}
              selectedCount={selectedCount}
              properties={properties}
              selectedProperties={selectedProperties}
              hideMultiselect={hideMultiselect}
              nonePropertiesSelected={nonePropertiesSelected}
              handleMultiselectChange={handleMultiselectChange}
            />
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <Button
                onClick={this.props.handleCancel}
                className="pull-right btn-xs-block"
                type="button"
                bsStyle="default"
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
            </Col>
            <Col xs={12} sm={6}>
              <ElementWithPermissions scope={['transaction-codes-edit']}>
                <SubmitButton
                  classes="btn btn-primary btn-xs-block"
                  clickHandler={handleSubmit}
                  isSubmitting={isSubmitting}
                >
                  <FormattedMessage {...messages.save} />
                </SubmitButton>
              </ElementWithPermissions>
            </Col>
          </Row>
        </Form>
      </Grid>
    );
  }
}

EditMasterTransactionCodeForm = reduxForm({
  form: 'editMasterTransactionCodes',
  enableReinitialize: true,
})(EditMasterTransactionCodeForm);

export default EditMasterTransactionCodeForm;
