import { defineMessages } from 'react-intl';

const messages = defineMessages({
  uploadDateTime: {
    id: 'App.ComplianceOverview_DEPRECATED.UploadDateTime',
    defaultMessage: 'Upload Date/Time',
  },
  certHistory: {
    id: 'App.ComplianceOverview_DEPRECATED.CertHistory',
    defaultMessage: 'Cert History',
  },
  needsReview: {
    id: 'App.ComplianceOverview_DEPRECATED.NeedsReview',
    defaultMessage: 'Needs Review',
  },
  allOpenCerts: {
    id: 'App.ComplianceOverview_DEPRECATED.AllOpenCerts',
    defaultMessage: 'All Open Certs',
  },
  waitlistApplicant: {
    id: 'App.ComplianceOverview_DEPRECATED.WaitlistApplicant',
    defaultMessage: 'Waitlist Applicants',
  },
  doNotInclude: {
    id: 'App.ComplianceOverview_DEPRECATED.DoNotInclude',
    defaultMessage: 'Do Not Include',
  },
  attentionRequired: {
    id: 'App.ComplianceOverview_DEPRECATED.AttentionRequired',
    defaultMessage: 'Attention Required',
  },
  getComplianceOverviewError: {
    id: 'App.ComplianceOverview_DEPRECATED.GetComplianceOverviewError',
    defaultMessage: 'There was an error getting the compliance overview data.',
  },
  effectivePastDue: {
    id: 'App.ComplianceOverview_DEPRECATED.EffectivePastDue',
    defaultMessage: 'Effective Date Past Due',
  },
  certHistoryFor: {
    id: 'App.ComplianceOverview_DEPRECATED.CertHistoryFor',
    defaultMessage: 'Cert History for {name}',
  },
  certOpened: {
    id: 'App.ComplianceOverview_DEPRECATED.CertOpened',
    defaultMessage: 'Cert Opened',
  },
  firstSubmissionPacketUpload: {
    id: 'App.ComplianceOverview_DEPRECATED.FirstSubmissionPacketUpload',
    defaultMessage: '1st Submission Packet Upload',
  },
  daysInProcess: {
    id: 'App.ComplianceOverview_DEPRECATED.DaysInProcess',
    defaultMessage: 'Days in Process',
  },
});

export default messages;
