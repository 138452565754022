import { defineMessages } from 'react-intl';

const messages = defineMessages({
  date: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.Date',
    defaultMessage: 'Date',
  },
  period: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.Period',
    defaultMessage: 'Period',
  },
  paymentCode: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.PaymentCode',
    defaultMessage: 'Payment Code',
  },
  chargeCode: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.ChargeCode',
    defaultMessage: 'Charge Code',
  },
  status: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.Status',
    defaultMessage: 'Status',
  },
  documentNumber: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.DocumentNumber',
    defaultMessage: 'Doc #',
  },
  notes: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.Notes',
    defaultMessage: 'Notes',
  },
  amount: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.Amount',
    defaultMessage: 'Amount',
  },
  view: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.View',
    defaultMessage: 'View',
  },
  modalHeader: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.ModalHeader',
    defaultMessage: 'View Misc. Income Payment',
  },
  modalFooterClose: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.ModalFooterClose',
    defaultMessage: 'Close',
  },
  modalReverse: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.ModalReverse',
    defaultMessage: 'Reverse',
  },
  transactionStatus: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.TransactionStatus',
    defaultMessage: 'Transaction Status',
  },
  chargeTransactionCode: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.ChargeTransactionCode',
    defaultMessage: 'Charge Transaction Code',
  },
  paymentTransactionCode: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.PaymentTransactionCode',
    defaultMessage: 'Payment Transaction Code',
  },
  documentNumberModal: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.DocumentNumberModal',
    defaultMessage: 'Document Number',
  },
  confirmReversal: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.ConfirmReversal',
    defaultMessage: 'Are you sure you want to reverse this transaction?',
  },
  frontCheck: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.frontCheck',
    defaultMessage: 'Front Check',
  },
  backCheck: {
    id: 'App.ManageMiscTransactions.MiscTransactionsDetails.backCheck',
    defaultMessage: 'Back Check',
  },
});

export default messages;
