import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import LegalDocumentPageLayout from '../../components/LegalDocumentPageLayout';
import {
  BigBreak,
  SectionTitle,
  RegularText,
  Subtitle,
} from '../../components/LegalDocumentPageLayout/TextHelpers';
import { LINKS } from './constants';

function TermsOfUse() {
  return (
    <DocumentTitle title="Fortress - Terms of Use">
      <LegalDocumentPageLayout links={LINKS}>
        <section id="overview">
          <SectionTitle>Effective: July 7, 2022</SectionTitle>
          <br />
          <SectionTitle>
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS
            SITE. THIS IS A BINDING LEGAL AGREEMENT. BY ACCESSING AND CONTINUING
            TO USE THIS SITE, YOU AGREE TO EACH AND EVERY ONE OF THESE TERMS AND
            CONDITIONS. PLEASE DO NOT USE THE SITE IF YOU DO NOT AGREE TO EACH
            AND EVERY ONE OF THESE TERMS AND CONDITIONS.
          </SectionTitle>
          <br />
          <RegularText>
            Fortress Technology Solutions, Inc. with its corporate headquarters
            at 118 16th Avenue South, Suite 200, Nashville, Tennessee 37203,
            United States of America (collectively, “Fortress”, “we”, “our”, or
            “us”) provides content and various services, including through this
            website (“Site”). These Terms and Conditions, together with any
            other applicable agreements or other documents as may be expressly
            incorporated by reference (collectively, “Terms”), govern your
            access and use of our Site, including any content, functionality,
            and services as may be offered through our Site as a visitor or
            registered user. Our handling of any information collected or
            provided through use of our Site is governed by our [
            <Link to="privacypolicy">Privacy Policy</Link>]. Our Site is
            controlled and operated from the United States and is subject to
            United States Law.
            <BigBreak />
            By using our Site, you hereby warrant that you are of legal age or
            have parental or guardian consent to enter into, and you hereby
            agree to be bound by these Terms. If you do not agree to all these
            Terms, you are not authorized to use the Site.
            <BigBreak />
            Fortress reserves the right to modify these Terms from time to time,
            and we will make you aware of material changes to these Terms by
            visibly posting them on the Site. All changes are effective
            immediately when we post them and apply to all access to and use of
            our Site thereafter. Your continued use of the Site following the
            posting of revised Terms constitutes your acceptance to the changes
            and your agreement to be bound by them.
            <BigBreak />
            The content and information displayed on the Site is Fortress's
            property and is collectively referred to as “Fortress Information”.
            The downloading, reproduction, or re-transmission of Fortress
            Information, other than for non-commercial personal use, is strictly
            prohibited, except to the extent as authorized by express prior
            written consent by Fortress.
          </RegularText>
        </section>
        <BigBreak />
        <section id="use-of-our-site">
          <SectionTitle>Use of Our Site</SectionTitle>
          <br />
          <RegularText>
            We reserve the right in our sole discretion to revoke or deny your
            access to our Site, including, without limitation, if you violate
            any of the provisions of our Terms.
            <BigBreak />
            The Site is to be used by you for your personal use only. You agree
            not to represent to be any other person, impersonate another person,
            or falsely claim to be representative of any entity, whether actual
            or fictitious, including an employee or agent of Fortress or any
            external party that may provide services related to the Site or
            other Services as may be offered by Fortress.
            <BigBreak />
            You agree that Fortress is under no obligation to but may monitor
            and review information you transmit over the Site. You agree that
            Fortress may censor, edit, remove or prohibit the transmission or
            receipt of any information that Fortress deems to be in violation of
            our Terms or otherwise improper. You agree Fortress may use any such
            information as necessary to protect the Site or to protect the
            rights or property of Fortress. You agree that Fortress may also
            monitor and review stored information without restriction. You
            hereby acknowledge and consent to such monitoring and reviewing.
            <BigBreak />
            You further agree that <b>you will not:</b>
          </RegularText>
          <br />
          <ul>
            <li>
              <RegularText>
                Use our Site for Chain letters, junk mail, "spamming",
                solicitations or bulk communications of any kind including but
                not limited to distribution lists to any person who has not
                given specific permission to be included in such a list;
              </RegularText>
            </li>
            <li>
              <RegularText>
                Use our Site to create a hypertext link from any web site
                controlled by you or otherwise, to the Site without the express
                written permission and authorization of Fortress;
              </RegularText>
            </li>
            <li>
              <RegularText>
                Use any device, software or other instrumentality to interfere
                or attempt to interfere with the proper working of our Site;
              </RegularText>
            </li>
            <li>
              <RegularText>
                Take any action that imposes an unreasonable or
                disproportionately large backend load or burden on our
                information technology infrastructure;
              </RegularText>
            </li>
            <li>
              <RegularText>
                Use any robot, spider, other automatic device, or manual process
                to monitor, scrape, or copy our Site or any Fortress Information
                contained therein, without the prior express authorization and
                consent from Fortress or an authorized Fortress representative
                (such consent is deemed given for standard search engine
                technology employed by Internet search websites to direct
                individuals to our Site);
              </RegularText>
            </li>
            <li>
              <RegularText>
                Interrupt, disrupt, alter, destroy, impair, restrict tamper, or
                otherwise affect the proper operation and performance of our
                Site in any way, including, without limitation, through the use
                of any malicious or unauthorized code, virus, worm, Trojan
                horse, malware, or program;
              </RegularText>
            </li>
            <li>
              <RegularText>
                Use our Site in any threatening, libelous, slanderous,
                defamatory, obscene, inflammatory, pornographic, discriminatory,
                or otherwise offensive manner;
              </RegularText>
            </li>
            <li>
              <RegularText>
                Use our Site for any purpose that is illegal, unlawful, or
                prohibited by these Terms.
              </RegularText>
            </li>
          </ul>
          <br />
          <Subtitle>Social Areas</Subtitle>
          <br />
          <RegularText>
            "Social Area" means any forum, message board, or similar chat
            functionality and posting service offered in conjunction with the
            Site. If you participate in a Social Area, you agree that, in
            addition to complying with the Terms, you will not: defame, abuse,
            harass, threaten, or make any discriminatory statements about
            others; advocate illegal activity; use indecent, obscene or
            discourteous language or images; infringe or violate the rights of
            others (including the intellectual property rights of others); or
            provide content that is not related to the designated topic or theme
            of the Social Area. You shall remain solely responsible and liable
            for your use of any Social Area that may be offered on our Site.
            Fortress reserves the right to remove or edit content from any
            Social Area at any time and for any reason, however, Fortress is
            under no duty to do so, and is not responsible for the content or
            accuracy of any information in a Social Area. If you believe content
            posted in a Social Area on our Site may violate your rights
            (including your intellectual property rights), we encourage you to
            contact us either by <a>privacy@fortresstech.net</a> or by calling
            us at <a>615-490-6727</a>.
          </RegularText>
          <br />
          <Subtitle>Intellectual Property</Subtitle>
          <br />
          <RegularText>
            Our Site may contain or reference trademarks, patents, copyrighted
            materials, trade secrets, technologies, products, processes or other
            proprietary rights of Fortress. No license to or right in any such
            trademarks, patents, copyrighted materials, trade secrets,
            technologies, products, processes and other proprietary rights of
            Fortress and/or other parties is granted to or conferred upon you by
            use of our Site or otherwise under these Terms.
          </RegularText>
          <br />
          <Subtitle>Feedback</Subtitle>
          <br />
          <RegularText>
            By submitting any information, suggestions, enhancement notations,
            comments, or ideas and other feedback to Fortress through a Social
            Area or otherwise with respect to our Site or services as may be
            offered by Fortress (collectively, "Feedback"); you agree that such
            Feedback shall be deemed, and shall remain, Fortress's property.
            Feedback shall not be subject to any obligation of confidentiality
            on Fortress's part and Fortress shall not be liable for any use or
            disclosure of any Feedback. Fortress shall own all rights and
            interests related to Feedback (including without limitation all
            intellectual property rights therein) and shall be entitled to use
            any Feedback without restriction for any purpose whatsoever,
            commercial or otherwise, without compensation to you.
          </RegularText>
        </section>
        <BigBreak />
        <section id="privacy">
          <SectionTitle>Privacy</SectionTitle>
          <br />
          <RegularText>
            Collection or submission of information to our Site is subject to
            our [<Link to="privacypolicy">Privacy Policy</Link>].
          </RegularText>
        </section>
        <BigBreak />
        <section id="confidentiality">
          <SectionTitle>Confidentiality</SectionTitle>
          <br />
          <RegularText>
            With respect to all communications you make to us regarding Fortress
            Information including but not limited to Feedback, questions,
            comments, suggestions and the like, except as otherwise provided for
            in our [<Link to="privacypolicy">Privacy Policy</Link>]: (a) you
            shall have no right of confidentiality in your communications and we
            shall have no obligation to protect your communications from
            disclosure; (b) we shall be free to reproduce, use, disclose and
            distribute your communications to others without limitation; and (c)
            we shall be free to use any ideas, concepts, know-how, content or
            techniques contained in your communications for any purpose
            whatsoever, including but not limited to the development, production
            and marketing of products and services that incorporate such
            information.
          </RegularText>
        </section>
        <BigBreak />
        <section id="fortress-information-accuracy">
          <SectionTitle>Fortress Information Accuracy</SectionTitle>
          <br />
          <RegularText>
            Fortress Information may contain technical inaccuracies and
            typographical errors, including but not limited to inaccuracies
            relating to pricing or availability applicable to your interactions
            with Fortress. Fortress assumes no responsibility or liability for
            any such inaccuracies, errors or omissions. Fortress is not
            responsible for incorrect or inaccurate entry information, whether
            caused by user(s) or by any of the equipment or programming
            associated with our Site, or by any technical or human error that
            may occur in the processing of any information related to our Site.
            Fortress reserves the right to make changes, corrections, and/or
            improvements to Fortress Information, and to the products and
            services described in such information, at any time without notice,
            including after confirmation of a transaction.
          </RegularText>
        </section>
        <BigBreak />
        <section id="communication-errors">
          <SectionTitle>Communication Errors</SectionTitle>
          <br />
          <RegularText>
            Fortress is not responsible for communication malfunctions,
            failures, or lost, stolen, or otherwise misdirected, transmissions,
            messages or entries, or the security of any such communications.
          </RegularText>
        </section>
        <BigBreak />
        <section id="service-availability">
          <SectionTitle>Service Availability</SectionTitle>
          <br />
          <RegularText>
            Our Site contains information on various Fortress products and
            services, not all of which may be available in every location. A
            reference to a Fortress product or service on our Site does not
            imply that such product or service is or will be available in a
            particular location.
          </RegularText>
        </section>
        <BigBreak />
        <section id="third-party-sites">
          <SectionTitle>Third-Party Sites</SectionTitle>
          <br />
          <RegularText>
            If you choose to leave our Site via links to other third-party
            sites, including those of advertisers, our Terms and our [
            <Link to="privacypolicy">Privacy Policy</Link>] will no longer
            apply. Fortress does not control the websites, services, or
            applications that may be offered by third parties and is not
            responsible for the content that may be present or how your personal
            information may be handled by other third parties. Accordingly,
            because Fortress exercises no control over such third-party sites
            and resources, you acknowledge and agree that Fortress is not
            responsible for the availability of such external party sites or
            resources, and that Fortress does not endorse and is not responsible
            or liable for any content, advertising, products, or other materials
            on or available from such third-party sites or resources.
          </RegularText>
        </section>
        <BigBreak />
        <section id="warranty">
          <SectionTitle>Warranty</SectionTitle>
          <br />
          <RegularText>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, OUR SITE AND FORTRESS
            INFORMATION ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND,
            EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, OR NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR ANY GUARANTY
            OR ASSURANCE THAT OUR SITE AND/OR FORTRESS INFORMATION WILL BE
            AVAILABLE, ADEQUATE, ACCURATE, UNINTERRUPTED, COMPLETE OR ERROR
            FREE. WE ARE NEITHER RESPONSIBLE NOR LIABLE FOR ANY MALICIOUS OR
            UNAUTHORIZED CODE AND YOU ARE SOLELY RESPONSIBLE FOR ENSURING YOU
            HAVE APPROPRIATE SCANNING AND PROTECTIVE MECHANISMS FOR THE SECURITY
            OF YOUR DEVICES, PROGRAMS AND INFORMATION. BY USING OUR SITE AND/OR
            FORTRESS INFORMATION, YOU ARE ASSUMING ALL RISK OF LOSS THAT MAY
            ARISE OR BE ASSOCIATED WITH THAT USE.
          </RegularText>
        </section>
        <BigBreak />
        <section id="limitation-of-liability-and-indemnification">
          <SectionTitle>
            Limitation of Liability and Indemnification
          </SectionTitle>
          <br />
          <RegularText>
            To the maximum extent permitted by law, Fortress hereby expressly
            excludes any liability for any direct, indirect or consequential
            loss or damage incurred by any user in connection with our Site or
            in connection with the use, inability to use, or results of the use
            of our Site, any websites linked to our Site and any materials
            posted on our Site, including, without limitation any liability for
            loss of income or revenue; loss of business; loss of profits or
            contracts; loss of anticipated savings; loss of data; loss of
            goodwill; wasted management or office time; and for any other loss
            or damage of any kind, however arising and whether caused by tort
            (including negligence), breach of contract or otherwise, even if
            foreseeable, provided that this condition shall not prevent claims
            for loss of or damage to your tangible property or any other claims
            for direct financial loss that are not excluded by any of the
            categories set out above.
            <BigBreak />
            IN THE EVENT FORTRESS IS HELD LIABLE FOR ANY DAMAGES RELATED TO OUR
            SITE, TO THE FULLEST EXTENT PERMITTED BY LAW, YOUR SOLE AND
            EXCLUSIVE REMEDY WILL BE LIMITED TO REIMBURSEMENT OF THE CHARGES FOR
            SERVICES OR PRODUCTS PAID BY YOU TO FORTRESS. IN THE EVENT NO
            PAYMENT HAS BEEN MADE BY YOU TO FORTRESS FOR SERVICES OR PRODUCTS,
            FORTRESS’S LIABILITY ARISING OUT OF OR RELATED TO OUR SITE WILL NOT
            EXCEED $100 (ONE HUNDRED USD).
            <BigBreak />
            TO THE FULLEST EXTENT PERMITTED BY LAW, YOU HEREBY WAIVE ANY AND ALL
            RIGHTS TO BRING ANY CLAIM OR ACTION RELATED TO THIS SITE BEYOND ONE
            (1) YEAR AFTER THE FIRST OCCURRENCE OF THE KIND OF ACT, EVENT,
            CONDITION OR OMISSION UPON WHICH SUCH CLAIM OR ACTION IS BASED.
            <BigBreak />
            You shall defend us against any demands, claims or actions brought
            against us or arising as a result of your use of the Site and/or any
            breach or violation of these Terms by you (“Claim”) and you shall
            indemnify and hold us harmless from and against any and all losses,
            damages, costs and expenses (including attorneys' fees) resulting
            from any such Claim. We have the right, at our expense, to assume
            exclusive defense against any Claim and all negotiations for
            settlement and you agree to cooperate with us in the defense of any
            such Claim, at our request.
          </RegularText>
        </section>
        <BigBreak />
        <section id="governing-law-and-dispute-resolution">
          <SectionTitle>Governing Law and Dispute Resolution</SectionTitle>
          <br />
          <RegularText>
            These Terms are to be construed and interpreted in accordance with
            the laws of the state of Tennessee and the United States, excluding
            any choice of law principle that may require the application of law
            of another jurisdiction. Any controversy or claim arising out of or
            relating to the Terms, or the breach thereof, is to be settled
            exclusively by arbitration administered by the American Arbitration
            Association in accordance with its Commercial Arbitration Rules
            before one independent and disinterested arbitrator in Davidson
            County, Tennessee. Consistent with the expedited nature of
            arbitration, pre-hearing information exchange is to be limited to
            the reasonable production of relevant, non-privileged documents
            explicitly referred to by a party for the purpose of supporting
            relevant facts presented in its case, carried out expeditiously. The
            arbitrator will grant such legal or equitable remedies and relief in
            compliance with applicable law that the arbitrator deems just and
            equitable, but only to the extent that such remedies or relief could
            be granted by a federal, state, or local court located in Davidson
            County, Tennessee and further to the extent such remedies are not
            specifically excluded or limited herein. The arbitrator must render
            their award by application of the substantive law of the state of
            Tennessee and U.S. federal law and must render a written opinion
            setting forth findings of fact and conclusions of law with the
            reasons therefor stated. The award rendered by the arbitrator will
            be final and specifically enforceable under applicable law, and
            judgment may be entered upon it in any court having jurisdiction
            thereof. Either party may seek injunctive relief in court without
            prior arbitration for the purpose of avoiding immediate and
            irreparable harm. Any claim by you against Fortress must be brought
            within twelve (12) months following the date such claim arose.
          </RegularText>
        </section>
        <BigBreak />
        <section id="contact-us">
          <SectionTitle>Contact Us</SectionTitle>
          <br />
          <RegularText>
            If you have any questions regarding our Terms or about use of our
            Site, please feel free to contact us at:
            <BigBreak />
            <b>Fortress Technology Solutions, Inc.</b>
            <br />
            188 16th Ave. South
            <br />
            Suite 200
            <br />
            Nashville, TN 37203
            <BigBreak />
            Email: <a>privacy@fortresstech.net</a>
            <br />
            Phone Number: <a>615-490-6727</a>
          </RegularText>
        </section>
      </LegalDocumentPageLayout>
    </DocumentTitle>
  );
}

export default TermsOfUse;
