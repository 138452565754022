import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { promptToaster } from '../App/actions';
import type { GlobalState } from '../App/types';
import ApplicantHousehold from './ApplicantHoushold';
import EmergencyContactsSection from './EmergencyContactsSection';
import InsuranceSection from './InsuranceSection';
import ResidentHousehold from './ResidentHousehold';
import RentableItems from './RentableItems';
// eslint-disable-next-line max-len
import { Household as HouseholdContainer } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import usePermissionsHook from '../../components/ElementWithPermissions/usePermissionsHook';
import Vehicles from './Vehicles';
import WorkOrderPreferences from './WorkOrderPreferences';
import CashPayDetailsSection from './CashPayDetailsSection';

type Props = {
  intl: any,
  actions: any,
  applicationId: string,
  disableEdit: boolean,
  editHouseholdMember: Function,
  handleNoVehiclesChange?: Function,
  householdId: string,
  householdMembers: Array<Object>,
  onEdit: Function,
  residentId?: string,
  isOnNTV?: boolean,
  isPriorResident?: boolean,
  hasAssignedUnit?: boolean,
  selectedProperty: Object,
  setHasHouseholdRentableItems: Function,
  refetchHouseholdRentableItemsTrigger: number,
  vehicleCount: number | null,
  unitId?: string,
};

const Household = ({
  intl,
  actions,
  actualOrAnticipatedMoveOutDate = null,
  applicationId,
  disableEdit,
  editHouseholdMember,
  handleNoVehiclesChange,
  householdId,
  hasAssignedUnit,
  isPriorResident,
  unitId,
  householdMembers,
  onEdit,
  residentId,
  selectedProperty,
  setHasHouseholdRentableItems = () => {},
  setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate,
  refetchHouseholdRentableItemsTrigger = 0,
  vehicleCount,
}: Props) => {
  const flags = useFlags();
  const editHouseholdPermissions = residentId
    ? ['resident-edit']
    : ['prospect-update'];

  const hasHouseholdEditPermissions = usePermissionsHook({
    scope: editHouseholdPermissions,
  });

  return (
    <HouseholdContainer
      intl={intl}
      buttonProps={
        hasHouseholdEditPermissions
          ? {
              onClick: onEdit,
              disabled: disableEdit,
              buttonVariant: 'edit',
            }
          : null
      }
    >
      <>
        {residentId ? (
          <ResidentHousehold
            intl={intl}
            editHouseholdMember={editHouseholdMember}
            householdMembers={householdMembers}
          />
        ) : (
          <ApplicantHousehold
            intl={intl}
            editHouseholdMember={editHouseholdMember}
            householdMembers={householdMembers}
          />
        )}
        <Vehicles
          intl={intl}
          applicationId={applicationId}
          residentId={residentId}
          handleNoVehiclesChange={handleNoVehiclesChange}
          vehicleCount={vehicleCount}
          selectedProperty={selectedProperty}
          householdId={householdId}
        />
        {flags.rentableItems && (
          <RentableItems
            intl={intl}
            actualOrAnticipatedMoveOutDate={actualOrAnticipatedMoveOutDate}
            hasAssignedUnit={hasAssignedUnit}
            isPriorResident={isPriorResident}
            householdId={householdId}
            promptToaster={actions.promptToaster}
            refetchHouseholdRentableItemsTrigger={
              refetchHouseholdRentableItemsTrigger
            }
            setHasHouseholdRentableItems={setHasHouseholdRentableItems}
            setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate={
              setHasHouseholdRentableItemEndDateAfterAnticipatedMoveOutDate
            }
          />
        )}
        <EmergencyContactsSection
          intl={intl}
          propertyId={selectedProperty.id}
          organizationId={selectedProperty.organizationId}
          householdId={householdId}
          promptToaster={actions.promptToaster}
        />
        {flags.walkInPayments && (
          <CashPayDetailsSection
            intl={intl}
            householdId={householdId}
            propertyId={selectedProperty.id}
          />
        )}
        {residentId && !isPriorResident && (
          <WorkOrderPreferences
            intl={intl}
            householdId={householdId}
            unitId={unitId}
          />
        )}
        {flags.riskMgmtHouseholdSnapshot &&
          selectedProperty.insuranceType === 'None' && (
            <InsuranceSection
              intl={intl}
              propertyId={selectedProperty.id}
              organizationId={selectedProperty.organizationId}
              householdId={householdId}
              applicationId={applicationId}
            />
          )}
      </>
    </HouseholdContainer>
  );
};

const mapStateToProps = ({ app }: GlobalState): Object => {
  const { selectedProperty } = app;
  return { selectedProperty };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    actions: bindActionCreators(
      {
        promptToaster,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Household));
