import React from 'react';

import { formatPeriod } from '../../../utils/redux-form-helper.js';
import messages from './messages';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import {
  Box,
  Button,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { SettingsIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { TableHeaderExportButtons } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PADOption from './PADOption';
import { useFetchPropertyConfig } from '../../App/hooks';
import CollectionsTrack from './CollectionsTrack';
import DownloadOptions from './DownloadOptions';

type HeaderData = {
  status: string,
  period: string,
  balance: string,
  currentCharges: string,
  deposits: {
    runningSecurityBalanceOwed: string,
    runningSecurityBalanceHeld: string,
  },
};

type Props = {
  formatMessage: Function,
  formatNumber: Function,
  headerData: HeaderData,
  downloadLedger: Function,
  fasReady?: boolean,
  accountFinalized?: boolean,
  handleFasReadyClick?: Function,
  balanceWrittenOff?: number,
  isAllCommercial: boolean,
  paymentRestrictions: Object,
  onPaymentRestrictionChange: Function,
  propertyPaymentProvider: string,
  propertyId: string,
  organizationId: string,
  isPrior: boolean,
  householdMembers: Object[],
  lateMethodId?: string,
  unitNumber?: string,
  householdId: string,
  fasDate?: string,
};

const LedgerHeader = ({
  formatMessage,
  formatNumber,
  headerData,
  downloadLedger,
  fasReady,
  accountFinalized,
  handleFasReadyClick,
  balanceWrittenOff,
  isAllCommercial,
  paymentRestrictions,
  onPaymentRestrictionChange,
  createTransactionDisabled,
  showCreateTransaction,
  refundToApplicantAmount,
  onClickApplicantRefund,
  residentId,
  isPrior,
  propertyPaymentProvider,
  propertyId,
  organizationId,
  householdMembers,
  lateMethodId,
  unitNumber,
  householdId,
  fasDate,
}: Props) => {
  const {
    status,
    period,
    balance,
    currentCharges,
    deposits: { runningSecurityBalanceOwed, runningSecurityBalanceHeld },
  } = headerData;
  const { paymentsV1, collectionsOneWay, ledgerActualPostDateAndSort } =
    useFlags();
  const { propertyConfig, isLoading } = useFetchPropertyConfig({
    organizationId,
    propertyId,
    flags: { paymentsV1 },
  });
  const shouldAcceptPartialPayment =
    !isLoading && propertyConfig[0]?.shouldAcceptPartialPayment;

  return (
    <div data-test="ledger-header">
      <Box padding={2} mt={-4}>
        <Stack
          width={'100%'}
          direction={'row'}
          spacing={2}
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant={'h1'}>
            {formatMessage(messages.ledger)}
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={1}
          >
            <ElementWithPermissions scope={['financial-transaction-create']}>
              <Button
                variant={'shout'}
                href="#"
                disabled={createTransactionDisabled}
                onClick={showCreateTransaction}
              >
                <i className="icon et-money" />
                {formatMessage(messages.createTransaction)}
              </Button>
            </ElementWithPermissions>
            {!residentId && (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                marginLeft={1}
              >
                <ElementWithPermissions
                  scope={['final-account-statement-create']}
                >
                  <Button
                    variant={'shout'}
                    disabled={!(refundToApplicantAmount < 0)}
                    onClick={onClickApplicantRefund}
                  >
                    {formatMessage(messages.refundtoApplicant)}
                  </Button>
                </ElementWithPermissions>
                <Typography variant={'caption'}>
                  {refundToApplicantAmount === 0
                    ? formatMessage(messages.noRefundAvailable)
                    : refundToApplicantAmount > 0
                    ? formatMessage(messages.outstandingBalanceDue)
                    : null}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack spacing={2}>
          <PADOption
            residentId={residentId}
            isPrior={isPrior}
            householdMembers={householdMembers}
            propertyConfig={propertyConfig[0]}
            allowHouseholdPartialPayments={
              paymentRestrictions.doNotAcceptPartialPayments !== true
            }
            lateMethodId={lateMethodId}
            unitNumber={unitNumber}
            balance={balance}
            householdId={householdId}
          />
          {status === 'Prior Resident' && collectionsOneWay && (
            <CollectionsTrack
              householdId={householdId}
              fasGeneratedDate={fasDate}
              balance={balance}
            />
          )}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent={'flex-start'}
              spacing={2}
            >
              <Stack spacing={1} direction={'row'} alignItems={'flex-start'}>
                <SettingsIcon />
                <Typography variant={'formLabel'}>
                  Payment Restrictions
                </Typography>
              </Stack>

              <Stack
                className="checkbox custom-checkbox"
                spacing={1}
                direction={{ lg: 'column', xl: 'row' }}
              >
                <label>
                  <ElementWithPermissions scope={['payment-restrictions-edit']}>
                    <input
                      type="checkbox"
                      checked={paymentRestrictions.certifiedOnly}
                      onChange={onPaymentRestrictionChange('certifiedOnly')}
                    />
                  </ElementWithPermissions>
                  <span className="custom-check-square" />
                  Certified Only
                </label>
                <label>
                  <ElementWithPermissions scope={['payment-restrictions-edit']}>
                    <input
                      type="checkbox"
                      checked={paymentRestrictions.doNotAccept}
                      onChange={onPaymentRestrictionChange('doNotAccept')}
                    />
                  </ElementWithPermissions>
                  <span className="custom-check-square" />
                  Do Not Accept
                </label>
                {shouldAcceptPartialPayment &&
                  propertyPaymentProvider === 'FORTRESS' &&
                  paymentsV1 && (
                    <label>
                      <ElementWithPermissions
                        scope={['payment-restrictions-edit']}
                      >
                        <input
                          type="checkbox"
                          checked={
                            paymentRestrictions.doNotAcceptPartialPayments
                          }
                          onChange={onPaymentRestrictionChange(
                            'doNotAcceptPartialPayments',
                          )}
                        />
                      </ElementWithPermissions>
                      <span className="custom-check-square" />
                      Do Not Accept Partial Payments
                    </label>
                  )}
              </Stack>
            </Stack>
            <Stack>
              {!ledgerActualPostDateAndSort && (
                <TableHeaderExportButtons
                  onPDFButtonClick={() => downloadLedger('pdf')}
                  onCSVButtonClick={() => downloadLedger('csv')}
                />
              )}
              {ledgerActualPostDateAndSort && (
                <DownloadOptions
                  downloadLedgerSortByPostDateCSV={() =>
                    downloadLedger('csv', 'createdAt')
                  }
                  downloadLedgerSortByPostDatePDF={() =>
                    downloadLedger('pdf', 'createdAt')
                  }
                  downloadLedgerSortByTransactionDateCSV={() =>
                    downloadLedger('csv', 'transactionDate')
                  }
                  downloadLedgerSortByTransactionDatePDF={() =>
                    downloadLedger('pdf', 'transactionDate')
                  }
                />
              )}
            </Stack>
            {status === 'Prior Resident' && (
              <div className="form-group">
                <div className="custom-checkbox padleft20 checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={fasReady}
                      onChange={handleFasReadyClick}
                      disabled={accountFinalized}
                    />
                    <span className="custom-check-square" />
                    {formatMessage(messages.fasReady)}
                  </label>
                </div>
              </div>
            )}
          </Stack>
        </Stack>
      </Box>

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-2 col-lg-2">
            <div className="block-data block-gray">
              <h5>{formatMessage(messages.status)}</h5>
              <span>
                {isAllCommercial
                  ? status === 'Prior Resident'
                    ? 'Prior Tenant'
                    : status === 'Current Resident'
                    ? 'Current Tenant'
                    : status
                  : status}
              </span>
            </div>
          </div>
          <div className="col-md-2 col-lg-2">
            <div className="block-data block-gray">
              <h5>{formatMessage(messages.period)}</h5>
              <span>{formatPeriod(period)}</span>
            </div>
          </div>
          <div className="col-md-3 col-lg-3">
            <div className="block-data block-gray">
              <h5>{formatMessage(messages.balance)}</h5>
              <span>
                <strong>
                  {formatNumber(balance, {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </strong>
                {balanceWrittenOff && balanceWrittenOff > 0 ? (
                  <p>
                    Written Off:
                    {formatNumber(balanceWrittenOff, {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </p>
                ) : null}
              </span>
            </div>
          </div>
          <div className="col-md-2 col-lg-2">
            <div className="block-data block-gray">
              <h5>{formatMessage(messages.currentCharges)}</h5>
              <span>
                {formatNumber(currentCharges, {
                  style: 'currency',
                  currency: 'USD',
                })}
              </span>
            </div>
          </div>
          <div className="col-md-3 col-lg-3">
            <div className="block-data block-gray">
              <h5>{formatMessage(messages.deposits)}</h5>
              <div className="block-data-inner">
                <span>{formatMessage(messages.held)}</span>
                <span className="block-data-inner__amount">
                  {formatNumber(runningSecurityBalanceHeld, {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </span>
              </div>
              <div className="block-data-inner">
                <span>{formatMessage(messages.owed)}</span>
                <span className="block-data-inner__amount">
                  {formatNumber(runningSecurityBalanceOwed, {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LedgerHeader;
