import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import store, { history, persistor } from './store';
import { PersistGate } from 'redux-persist/es/integration/react';
import './css/fonts';
import './css/bootstrap.css';
import './css/bootstrap-float-label.min.css';
import './css/et-custom.css';
import './css/react-datetime.css';
import './index.css';
import './css/et-print.css';
import App from './containers/App';
// $FlowFixMe
import { unregister } from './registerServiceWorker';
import appSagas from './containers/App/sagas';
import prospectProfileSagas from './containers/ProspectProfile/sagas';
import workOrderSagas from './containers/WorkOrder/sagas';
import affordableQualificationHistoryTabSagas from './containers/AffordableQualificationHistoryTab/sagas';
import applicationProfileSagas from './containers/ApplicationProfile/sagas';
import residentProfileSagas from './containers/ResidentProfile/sagas';
import manageProspectsSagas from './containers/ManageProspects/sagas';
import createUserSagas from './containers/CreateUser/sagas';
import editUserSagas from './containers/EditUser/sagas';
import peopleProfileSagas from './containers/PeopleProfile/sagas';
import resetPasswordSagas from './containers/ResetPassword/sagas';
import loginSagas from './containers/Login/sagas';
import forgotPasswordSagas from './containers/ForgotPassword/sagas';
import createRoleSagas from './containers/CreateRole/sagas';
import editRoleSagas from './containers/EditRole/sagas';
import homeSagas from './containers/Home/sagas';
import manageApplicationsSagas from './containers/ManageApplications/sagas';
import managePriorResidentsSagas from './containers/ManagePriorResidents/sagas';
import manageDelinquenciesSagas from './containers/ManageDelinquency/sagas';
import createApplicationSagas from './containers/CreateApplication/sagas';
import manageDocumentsSagas from './containers/ManageDocuments/sagas';
import shortLeaseApplicationSagas from './containers/ShortLeaseApplication/sagas';
import editProspectHouseholdSagas from './containers/EditProspectHousehold/sagas';
import manageWorkOrdersSagas from './containers/ManageWorkOrders/sagas';
import primaryLeaseApplicationSagas from './containers/PrimaryLeaseApplication/sagas';
import secondaryLeaseApplicationSagas from './containers/NonPrimaryLeaseApplication/sagas';
import editApplicationHouseholdSagas from './containers/EditApplicationHousehold/sagas';
import createResidentSagas from './containers/CreateResident/sagas';
import createCommercialTenantSagas from './containers/CreateCommercialTenant/sagas';
import miscTransactionSagas from './containers/ManageMiscTransactions/sagas';
import manageDepositsSagas from './containers/ManageDeposits/sagas';
import viewBankDepositSagas from './containers/ViewBankDeposit/sagas';
import createMiscTransactionSagas from './containers/ManageMiscTransactions/CreateMiscTransaction/sagas';
import manageUnitAvailabilitySagas from './containers/ManageUnitAvailability/sagas';
import leaseDataTabSagas from './containers/LeaseDataTab/sagas';
import ledgerSagas from './containers/Ledger/sagas';
import createTransactionSagas from './components/CreateTransaction/sagas';
import getChargeBatchSagas from './containers/CreateChargeBatch/sagas';
import createChargeBatchSagas from './containers/CreateChargeBatch/CreateChargeBatchForm/sagas';
import viewUnitSagas from './containers/ViewUnit/sagas';
import householdVehiclesSagas from './containers/HouseholdVehicles/sagas';
import monthEndCloseSagas from './containers/MonthEndClose/sagas';
import expiringLeasesSaga from './containers/ManageLeaseExpirations/sagas';
import manageUndepositedPaymentsSagas from './containers/ManageUndepositedPayments/sagas';
import renewalOfferSagas from './containers/GenerateRenewalOffer/sagas';
import LanguageProvider from './containers/LanguageProvider';
import finalAccountStatementSagas from './containers/FinalAccountStatement/sagas';
import qualificationSagas from './containers/QualificationForms/sagas';
import viewUnitEditFeesSagas from './containers/ViewUnit/EditFees/sagas';
import errorBoundarySagas from './containers/ErrorBoundary/sagas';
import editResidentHouseholdSagas from './containers/EditResidentHousehold/sagas';
import reportSagas from './containers/ManageReports/sagas';
import viewCommunicationSagas from './containers/ViewCommunications/sagas';
import leasingActivitySagas from './containers/LeasingActivity/sagas';
import overviewSagas from './containers/Overview/sagas';
import manageFloorPlansSagas from './containers/ManageFloorPlans/sagas';
import recertificationApplicationSagas from './containers/RecertificationApplication/sagas';
import manageCommunicationsSagas from './containers/ManageCommunications/sagas';
import portfolioSagas from './containers/Portfolio/sagas';
import transferResidentsagas from './containers/ResidentProfile/ResidentTransfer/sagas';
import propertyPortalSagas from './containers/ManageProperties/PropertyPortalModal/sagas';
import tenantSagas from './containers/Tenant/sagas';
import i18n from './i18n';
import { addLocaleData } from 'react-intl';
// $FlowFixMe
import en from 'react-intl/locale-data/en';
// $FlowFixMe
import es from 'react-intl/locale-data/es';
import TagManager from 'react-gtm-module';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { selectProperty } from './containers/App/actions';
import { parsePropertyIdFromPath } from './utils/navigation-helpers';
import {
  initializeDatadog,
  startDatadogSession,
  addFeatureFlagEvaluation,
} from './utils/datadog';

addLocaleData([...en, ...es]);

if (process.env.NODE_ENV === 'production') {
  require('loggly-jslogger');
}

const authLink = setContext((_, { headers }) => {
  const authToken = localStorage.getItem('session_id');
  const newHeaders = {
    ...headers,
    authorization: authToken ? `Bearer ${authToken}` : null,
  };
  return {
    headers: newHeaders,
  };
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_PAYMENTS_API_URL,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

// $FlowFixMe
appSagas.map(store.runSaga);
// $FlowFixMe
prospectProfileSagas.map(store.runSaga);
// $FlowFixMe
workOrderSagas.map(store.runSaga);
// $FlowFixMe
affordableQualificationHistoryTabSagas.map(store.runSaga);
// $FlowFixMe
applicationProfileSagas.map(store.runSaga);
// $FlowFixMe
residentProfileSagas.map(store.runSaga);
// $FlowFixMe
manageProspectsSagas.map(store.runSaga);
// $FlowFixMe
createUserSagas.map(store.runSaga);
// $FlowFixMe
editUserSagas.map(store.runSaga);
// $FlowFixMe
peopleProfileSagas.map(store.runSaga);
// $FlowFixMe
resetPasswordSagas.map(store.runSaga);
// $FlowFixMe
loginSagas.map(store.runSaga);
// $FlowFixMe
forgotPasswordSagas.map(store.runSaga);
// $FlowFixMe
createRoleSagas.map(store.runSaga);
// $FlowFixMe
editRoleSagas.map(store.runSaga);
// $FlowFixMe
homeSagas.map(store.runSaga);
// $FlowFixMe
manageApplicationsSagas.map(store.runSaga);
// $FlowFixMe
createApplicationSagas.map(store.runSaga);
// $FlowFixMe
manageDocumentsSagas.map(store.runSaga);
// $FlowFixMe
shortLeaseApplicationSagas.map(store.runSaga);
// $FlowFixMe
editProspectHouseholdSagas.map(store.runSaga);
// $FlowFixMe
manageWorkOrdersSagas.map(store.runSaga);
// $FlowFixMe
primaryLeaseApplicationSagas.map(store.runSaga);
// $FlowFixMe
secondaryLeaseApplicationSagas.map(store.runSaga);
// $FlowFixMe
editApplicationHouseholdSagas.map(store.runSaga);
// $FlowFixMe
createResidentSagas.map(store.runSaga);
// $FlowFixMe
createCommercialTenantSagas.map(store.runSaga);
// $FlowFixMe
manageUnitAvailabilitySagas.map(store.runSaga);
// $FlowFixMe
leaseDataTabSagas.map(store.runSaga);
// $FlowFixMe
ledgerSagas.map(store.runSaga);
// $FlowFixMe
createTransactionSagas.map(store.runSaga);
// $FlowFixMe
miscTransactionSagas.map(store.runSaga);
// $FlowFixMe
getChargeBatchSagas.map(store.runSaga);
// $FlowFixMe
createChargeBatchSagas.map(store.runSaga);
// $FlowFixMe
monthEndCloseSagas.map(store.runSaga);
// $FlowFixMe
manageDepositsSagas.map(store.runSaga);
// $FlowFixMe
viewBankDepositSagas.map(store.runSaga);
// $FlowFixMe
createMiscTransactionSagas.map(store.runSaga);
// $FlowFixMe
viewUnitSagas.map(store.runSaga);
// $FlowFixMe
householdVehiclesSagas.map(store.runSaga);
// $FlowFixMe
managePriorResidentsSagas.map(store.runSaga);
// $FlowFixMe
manageDelinquenciesSagas.map(store.runSaga);
// $FlowFixMe
expiringLeasesSaga.map(store.runSaga);
// $FlowFixMe
manageUndepositedPaymentsSagas.map(store.runSaga);
// $FlowFixMe
renewalOfferSagas.map(store.runSaga);
// $FlowFixMe
finalAccountStatementSagas.map(store.runSaga);
// $FlowFixMe
qualificationSagas.map(store.runSaga);
// $FlowFixMe
viewUnitEditFeesSagas.map(store.runSaga);
// $FlowFixMe
errorBoundarySagas.map(store.runSaga);
// $FlowFixMe
editResidentHouseholdSagas.map(store.runSaga);
// $FlowFixMe
reportSagas.map(store.runSaga);
// $FlowFixMe
overviewSagas.map(store.runSaga);
// $FlowFixMe
manageFloorPlansSagas.map(store.runSaga);
// $FlowFixMe
recertificationApplicationSagas.map(store.runSaga);
// $FlowFixMe
leasingActivitySagas.map(store.runSaga);
// $FlowFixMe
viewCommunicationSagas.map(store.runSaga);
// $FlowFixMe
manageCommunicationsSagas.map(store.runSaga);
// $FlowFixMe
portfolioSagas.map(store.runSaga);
// $FlowFixMe
transferResidentsagas.map(store.runSaga);
// $FlowFixMe
propertyPortalSagas.map(store.runSaga);
// $FlowFixMe
tenantSagas.map(store.runSaga);
const tagManagerArgs = {
  // $FlowFixMe
  gtmId: `${process.env.REACT_APP_TAG_MANAGER_ID}`,
};

TagManager.initialize(tagManagerArgs);
(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: `${process.env.REACT_APP_LAUNCHDARKLY_TOKEN}`,
    context: {
      kind: 'user',
      key: 'fortressUser',
    },
    options: {
      inspectors: [
        {
          type: 'flag-used',
          name: 'dd-inspector',
          method: (key: string, detail: LDClient.LDEvaluationDetail) => {
            addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  });
  ReactDOM.render(
    <ApolloProvider client={client}>
      <LDProvider>
        <Provider store={store}>
          <PersistGate
            loading={null}
            persistor={persistor}
            onBeforeLift={() => {
              const storeSnapshot = store.getState();
              const propertyId = parsePropertyIdFromPath(
                history.location.pathname,
              );
              const userProperties =
                storeSnapshot?.app?.currentUser?.user?.properties ?? [];
              const selectedPropertyFromURL = userProperties.find(
                (userProperty) => userProperty.id === propertyId,
              );
              store.dispatch(selectProperty(selectedPropertyFromURL));
            }}
          >
            <LanguageProvider messages={i18n.messages}>
              <ConnectedRouter history={history}>
                <App />
              </ConnectedRouter>
            </LanguageProvider>
          </PersistGate>
        </Provider>
      </LDProvider>
    </ApolloProvider>,
    // $FlowFixMe
    document.getElementById('root'),
  );
  unregister();
})();

initializeDatadog();
startDatadogSession();
