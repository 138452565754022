import { FormattedMessage } from 'react-intl';
import messages from './messages';

const TransactionBatchDetailsTableFooter = ({
  totalBatchAmount,
  totalHouseholds,
  totalTransactions = 0,
  addRow,
  isViewMode,
  isAllCommercial,
}) => (
  <tr>
    {!isViewMode ? (
      <td colSpan={2}>
        <a
          onClick={() => {
            addRow();
          }}
        >
          <strong>
            <FormattedMessage {...messages.addNewTransaction} />
          </strong>
        </a>
      </td>
    ) : (
      <td colSpan={2}></td>
    )}
    <td className="text-right">
      <FormattedMessage {...messages.totalBatchAmount} />
    </td>
    <td className="text-left">
      <span className="padleft10">${totalBatchAmount}</span>
    </td>
    <td></td>
    <td className="text-right">
      {isAllCommercial ? (
        <FormattedMessage {...messages.units} />
      ) : (
        <FormattedMessage {...messages.households} />
      )}{' '}
      {totalHouseholds}
    </td>
    <td colSpan={2} className="text-right">
      <FormattedMessage {...messages.totalTransactions} /> {totalTransactions}
    </td>
  </tr>
);

export default TransactionBatchDetailsTableFooter;
