import { defineMessages } from 'react-intl';

const messages = defineMessages({
  modalTitle: {
    id: 'App.CamTab.CamStartDateModal.HeaderSection',
    defaultMessage: 'CAM Start Date',
  },
  cancelCam: {
    id: 'App.CamTab.HeaderSection.CancelCam',
    defaultMessage: 'Cancel CAM',
  },
  startNewCam: {
    id: 'App.CamTab.HeaderSection.StartNewCam',
    defaultMessage: 'Start New CAM',
  },
  squareFeet: {
    id: 'App.CamTab.HeaderSection.SquareFeet',
    defaultMessage: 'Square Feet:',
  },
  avgPropertyOccupancy: {
    id: 'App.CamTab.HeaderSection.AvgPropertyOccupancy',
    defaultMessage: 'Average Occupancy:',
  },
  status: {
    id: 'App.CamTab.HeaderSection.Status',
    defaultMessage: 'Status:',
  },
});

export default messages;
