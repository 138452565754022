import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { sortAndFilterRows } from '../../../../utils';
import { download } from '../../../../utils/downloadFile.js';

import { COMPLIANCE_APPROVAL_STATUSES } from '../../../../utils/affordable';
import { isEmpty, isNil, pathOr } from 'ramda';
import { appendFilterTextToCSV } from '../../../../utils/csv-helpers';
import messages from './messages';
import manageCertsMessages from '../../messages';
import {
  CERTIFICATION_TYPES,
  OTHER_HUD,
  CERTIFICATION_TYPES_INTL,
  OTHER_LIHTC,
} from '../../constants';

export const getStatus = (isStarted: boolean, complianceStatus: string) => {
  if (isStarted && complianceStatus) {
    return complianceStatus;
  } else if (isStarted && !complianceStatus) {
    return 'Started';
  } else {
    return 'Not Started';
  }
};

export const getCertificationStatus = (complianceStatus: string) => {
  const complianceStatusMap = Object.freeze({
    [COMPLIANCE_APPROVAL_STATUSES.APPROVED]: (
      <span className={'status status-ball--small status-green'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.DENIED]: (
      <span className={'status status-ball--small status-red'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.ON_NOTICE_HOLD]: (
      <span className={'status status-ball--small status-red'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.CORRECTION_NEEDED]: (
      <span className={'status status-ball--small status-purple'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.CORRECTION_NEEDED_FINAL]: (
      <span className={'status status-ball--small status-dark-purple'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.APPROVED_PENDING_SIGN]: (
      <span className={'status status-ball--small status-blue'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.PENDING]: (
      <span className={'status status-ball--small status-orange'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.PENDING_FINAL_APPROVAL]: (
      <span className={'status status-ball--small status-dark-orange'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.STARTED]: (
      <span>
        <FormattedMessage {...messages.started} />
      </span>
    ),
    [COMPLIANCE_APPROVAL_STATUSES.COMPLETE]: (
      <span>
        <FormattedMessage {...messages.complete} />
      </span>
    ),
    [COMPLIANCE_APPROVAL_STATUSES.NOT_STARTED]: <span>---</span>,
  });
  return pathOr('', [complianceStatus], complianceStatusMap);
};

export const parseCertificationRowsForCSV =
  (rowheaders, certificationType, sort, hasFilters, intl) => (data) => {
    const { result } = data;
    let documentName;
    const headers = rowheaders
      .filter((header) => header.id !== 'view')
      .map((header) => header.title.props.defaultMessage)
      .join(',');

    const certifications = sort
      ? sortAndFilterRows(result, sort.fieldName, sort.order)
      : result;

    const rows = certifications.map((certification) => {
      const {
        complianceApprovalStatus: { name: complianceStatus },
        programName,
        unit,
        floorplan,
        headOfHousehold,
        moveInDate,
        leaseStartDate,
        isApplicant,
        balance,
        isCorrection,
        latestDocument,
        certificationNotes,
        voucherEffectiveDate,
        isPriorResident,
        lastCertifiedDate,
        nextRecertDueDate,
      } = certification;

      const certType = !isNil(
        CERTIFICATION_TYPES_INTL[certification?.certificationType],
      )
        ? intl.formatMessage(
            manageCertsMessages[
              CERTIFICATION_TYPES_INTL[certification.certificationType]
            ],
          )
        : '';

      const scheduledMoveInDateFormatted = isEmpty(moveInDate ?? '')
        ? ''
        : moment.utc(moveInDate).format('MM/DD/YYYY');
      const leaseStartDateFormatted = isEmpty(leaseStartDate ?? '')
        ? ''
        : moment.utc(leaseStartDate).format('MM/DD/YYYY');
      const voucherEffectiveDateFormatted = isEmpty(voucherEffectiveDate ?? '')
        ? ''
        : moment.utc(voucherEffectiveDate).format('MM/DD/YYYY');

      const latestDocumentUploadDate = latestDocument?.createdAt;
      const latestComplianceDocument =
        isNil(latestDocument) || isEmpty(latestDocumentUploadDate ?? '')
          ? ''
          : `${moment
              .utc(latestDocumentUploadDate)
              .format('MM/DD/YYYY hh:mm a')} - ${
              latestDocument?.documentType?.name ?? ''
            }`;

      const daysSinceLastUpload = isNil(latestDocument)
        ? ''
        : moment().diff(moment.utc(latestDocumentUploadDate), 'days');

      const lastCertifiedDateFormatted = isEmpty(lastCertifiedDate ?? '')
        ? ''
        : moment.utc(lastCertifiedDate).format('MM/DD/YYYY');

      const nextRecertDueDateFormatted = isEmpty(nextRecertDueDate ?? '')
        ? ''
        : moment.utc(nextRecertDueDate).format('MM/DD/YYYY');

      let effectiveScheduledMoveInDate;
      let customerStatus;

      switch (certificationType) {
        case CERTIFICATION_TYPES.MOVE_IN:
          effectiveScheduledMoveInDate = scheduledMoveInDateFormatted;
          customerStatus = isApplicant ? '' : 'Yes';
          documentName = ' - Move-Ins';
          break;
        case CERTIFICATION_TYPES.INITIAL:
          effectiveScheduledMoveInDate = voucherEffectiveDateFormatted;
          customerStatus = isPriorResident ? 'Yes' : '';
          documentName = ' - Initial Certs';
          break;
        case OTHER_HUD:
          effectiveScheduledMoveInDate = voucherEffectiveDateFormatted;
          customerStatus = isPriorResident ? 'Yes' : '';
          documentName = ' - Other Hud';
          break;
        case OTHER_LIHTC:
          effectiveScheduledMoveInDate = voucherEffectiveDateFormatted;
          customerStatus = isPriorResident ? 'Yes' : '';
          documentName = ' - Interim Certs';
          break;
        default:
          effectiveScheduledMoveInDate = '';
          customerStatus = '';
          documentName = '';
      }

      const balanceCorrectionData = [balance, isCorrection ? 'Yes' : ''];

      const finalFields = [
        complianceStatus,
        certificationType !== OTHER_HUD ? programName : certType,
        ` ${unit}`,
        `="${floorplan}"`,
        `"${headOfHousehold}"`,
        effectiveScheduledMoveInDate,
        ...(certificationType !== OTHER_HUD
          ? [leaseStartDateFormatted]
          : [lastCertifiedDateFormatted, nextRecertDueDateFormatted]),
        customerStatus,
        ...(certificationType !== OTHER_HUD
          ? balanceCorrectionData
          : balanceCorrectionData.reverse()),
        latestComplianceDocument,
        daysSinceLastUpload,
        certificationNotes || '',
      ];
      return finalFields.join(',');
    });

    const csv = appendFilterTextToCSV({
      headers,
      rows,
      hasAnyFilters: hasFilters,
    });

    download(
      csv,
      `Manage Certifications${documentName}.csv`,
      'text/csv;charset=utf-8',
    );
  };

export const getDynamicProperties = (
  date: string,
  isResident: boolean,
  nonResidentPath: string,
): Object => {
  return {
    linkPath: isResident ? 'resident' : nonResidentPath ?? 'resident',
    customerStatus: isResident ?? false,
    effectiveScheduledMoveInDate: date ?? '---',
  };
};
