/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  unit: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.Unit',
    defaultMessage: 'Unit',
  },
  status: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.Status',
    defaultMessage: 'Status',
  },
  moveOutDate: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.MoveOutDate',
    defaultMessage: 'Move-Out Date',
  },
  moveInReady: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.MoveInReady',
    defaultMessage: 'Move-In Ready',
  },
  daysVacant: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.DaysVacant',
    defaultMessage: 'Days Vacant',
  },
  currentLeasedRent: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.CurrentLeasedRent',
    defaultMessage: 'Current Leased Rent',
  },
  priorLeasedRent: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.PriorLeasedRent',
    defaultMessage: 'Prior Leased Rent',
  },
  quotingRent: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.QuotingRent',
    defaultMessage: 'Current Quoting Rent',
  },
  emptyStateMessage: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.EmptyStateMessage',
    defaultMessage: 'No units for lease on this Floorplan',
  },
  smartPriceFor: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.SmartPriceFor',
    defaultMessage: 'Smart Price for Floor Plan',
  },
  noOfUnits: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.NoOfUnits',
    defaultMessage: '# of Units Not Leased',
  },
  newQuotingRent: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.NewQuotingRent',
    defaultMessage: 'New Quoting Rent',
  },
  floorPlan: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.FloorPlan',
    defaultMessage: 'Floor Plan',
  },
  currentQuotingRent: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.CurrentQuotingRent',
    defaultMessage: 'Current Quoting Rent',
  },
  suggestedSmartPrice: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.SuggestedSmartPrice',
    defaultMessage: 'Suggested Smart Price',
  },
  startDate: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.StartDate',
    defaultMessage: 'Start Date',
  },
  smartPriceStatus: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.SmartPriceStatus',
    defaultMessage: 'Smart Price Status',
  },
  note: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.Note',
    defaultMessage:
      'Note: This action will update the unit price of {numberOfNotLeasedUnits} unit{plural} not leased for this floor plan.',
  },
  forbidden: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.Forbidden',
    defaultMessage: 'Requires the Building: Floor Plan Update permission',
  },
  priceAlreadyAccepted: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.PriceAlreadyAccepted',
    defaultMessage: 'Price has already been accepted.',
  },
  priceAlreadyRejected: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.PriceAlreadyRejected',
    defaultMessage: 'Price has already been rejected.',
  },
  noValidAction: {
    id: 'App.ManageFloorPlan.VacantUnitsModal.NoValidAction',
    defaultMessage: 'Edit the quoting rent manually to change the price.',
  },
});

export default messages;
