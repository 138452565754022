import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';

import type { GlobalState } from '../App/types';
import * as resetPasswordActions from './actions';
import ResetPasswordForm from './ResetPasswordForm';
import messages from './messages';

type Props = {
  state: Object,
  token: string,
  history: any
}

type InjectedProps = {
  actions: Object,
  intl: any,
  history: Object
}

export class ResetPasswordPage extends Component<Props & InjectedProps> {
  componentDidMount() {
    if(this.props.token) this.props.actions.verifyToken({ token: this.props.token });
  }

  handleSubmit = (resetPassword: any) => {
    const password = resetPassword['password'];

    this.props.actions.resetPassword({
      password,
      token: this.props.token
    });
  }
  
  render() {
    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <ResetPasswordForm 
          intl={this.props.intl}
          onSubmit={this.handleSubmit}
          showSuccessMessage={this.props.state.showSuccessMessage}
          validToken={this.props.state.verifyTokenSuccess}
          showErrorMessage={this.props.state.showErrorMessage}
          errorMessage={this.props.state.errorMessage}
          history = {this.props.history}
        />
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = ({ resetPassword }: GlobalState, ownedProps: any) => {
  return { 
    state: resetPassword,
    token: ownedProps.location ? ownedProps.location.search.replace('?token=', '') : '',
  };
};

export function mapDispatchToProps(dispatch: any) {
  return { 
    actions: bindActionCreators(resetPasswordActions, dispatch)
  }
}

const InjectedResetPasswordPage = injectIntl(ResetPasswordPage);
export default connect(mapStateToProps, mapDispatchToProps)(InjectedResetPasswordPage);
