import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  IconButton,
  Box,
  Stack,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  CheckIcon,
  CloseIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { renderPercentageField } from '../../../../../utils/redux-form-helper';
import validate from './validate';
import type { RequiredIncomeAveragingFormProps } from './types';

const RequiredIncomeAveragingForm = ({
  valid,
  store,
  handleSubmit,
  onCancel,
}: RequiredIncomeAveragingFormProps): any => {
  return (
    <Box sx={{ minWidth: 250 }}>
      <form>
        <Field
          store={store}
          name="incomeAveragingPercent"
          component={renderPercentageField}
          bsSize="lg"
          min="0"
          max="100"
          disabled={false}
        />
        <Stack direction={'row'} spacing={2}>
          <IconButton
            onClick={handleSubmit}
            disabled={!valid}
            color={'success'}
          >
            <CheckIcon color={'success'} />
          </IconButton>
          <IconButton onClick={onCancel} color={'error'}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </form>
    </Box>
  );
};

export default reduxForm({
  form: 'requiredIncomeAveragingForm',
  validate,
})(RequiredIncomeAveragingForm);
