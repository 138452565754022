import type { Role } from '../../App/types';
import messages from './messages';

const validateOnlyLettersAndNumbersAndSomeSpChars = (intl, name, text) => {
  if (name && !/^[a-zA-Z\d\-_.#@()/ ]*$/i.test(name)) {
    return intl.formatMessage(text);
  }
};

const validate = (values: Role, { intl }: Object) => {
  const errors = {};

  if (!values.name) {
    errors.name = intl.formatMessage(messages.requiredFields);
  }

  if (!values.description) {
    errors.description = intl.formatMessage(messages.requiredFields);
  }

  if (!values.roleTypeId || values.roleTypeId === 'default') {
    errors.roleTypeId = intl.formatMessage(messages.requiredFields);
  }

  if (!errors.name) {
    errors.name = validateOnlyLettersAndNumbersAndSomeSpChars(
      intl,
      values.name,
      messages.invalidRoleName
    );
    if (values.name.length > 50) {
      errors.name = intl.formatMessage(messages.nameCharacterMax)
    }
  } 
  
  if (!errors.description && values.description.length > 250 ) {
      errors.description = intl.formatMessage(messages.descriptionCharacterMax);
  }
  return errors;
};

export default validate;
