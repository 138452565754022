import React, { Component } from 'react';
import cn from 'classnames';
import { Modal } from 'react-bootstrap';
import ConfirmOverlay from '../ConfirmOverlay';

type DefaultProps = {
  confirm: boolean,
  show: boolean,
};

type Props = DefaultProps & {
  className?: string,
  children: any,
  header: any,
  footer: any,
  title: any,
  rejectMessage: any,
  confirmMessage: any,
  size: string,
  onReject: Function,
  onConfirm: Function,
  onHide: Function,
  backdrop: boolean | string,
};

export default class ConfirmModal extends Component<Props> {
  static defaultProps = {
    confirm: false,
    show: true,
  };

  handleHide = () => {
    const { onHide } = this.props;
    onHide();
  };

  render() {
    const {
      className,
      confirm,
      children,
      header,
      footer,
      title,
      rejectMessage,
      confirmMessage,
      show,
      onReject,
      onConfirm,
      size,
      backdrop = true,
    } = this.props;
    return (
      <Modal
        className={className}
        show={show}
        autoFocus
        onHide={this.handleHide}
        bsSize={size}
        backdrop={backdrop}
      >
        {header && <Modal.Header closeButton={!confirm}>{header}</Modal.Header>}
        <Modal.Body>
          {confirm && (
            <ConfirmOverlay
              title={title}
              rejectMessage={rejectMessage}
              confirmMessage={confirmMessage}
              onReject={onReject}
              onConfirm={onConfirm}
            />
          )}
          {children}
        </Modal.Body>
        <Modal.Footer
          className={cn({
            disabled: confirm,
          })}
        >
          {footer}
        </Modal.Footer>
      </Modal>
    );
  }
}
