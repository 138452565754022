import { useEffect, useState } from 'react';
import ReportsService from '../../services/reportService';
import FinancialPeriodsService from '../../services/financialPeriodsService';
import { toastr } from 'react-redux-toastr';

import { renderNoDataToastr } from '../../utils/redux-form-helper';

export const useFetchAllReports = ({
  propertyId,
  organizationId,
  currentSorting,
  searchText,
  generatedReport,
  flags,
}: Object): any => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const abortController = new AbortController();
    const options = { signal: abortController.signal };
    const fetchData = async () => {
      try {
        const reportsService = new ReportsService();
        const response = await reportsService.getAllReports(
          organizationId,
          propertyId,
          currentSorting,
          searchText,
          options,
        );

        const reports = response.results;
        if (reports.length === 0) {
          renderNoDataToastr();
        }

        const reportsToOmit = ['Portfolio-Summary'];
        if (flags?.omitUndepositedV2Report) {
          reportsToOmit.push('Undeposited-Online-Payments-V2');
        }

        const result = {
          reports: reports.filter(
            (report) => !reportsToOmit.includes(report.referenceId),
          ),
          meta: response.meta,
        };
        setData(result);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        toastr.error('Error', e.message || e.networkError || e);
      }
    };

    fetchData();
    return () => abortController.abort();
  }, [
    propertyId,
    organizationId,
    currentSorting,
    searchText,
    generatedReport,
    flags,
  ]);
  return data;
};

export const useFetchClosedPeriods = (payload) => {
  const { propertyId, organizationId } = payload;
  const [data, setData] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    const options = { signal: abortController.signal };
    const fetchData = async () => {
      try {
        const financialPeriodsService = new FinancialPeriodsService();
        const response = await financialPeriodsService.getAllClosedPeriods(
          organizationId,
          propertyId,
          options,
        );
        setData(response);
      } catch (e) {
        toastr.error('Error', e);
      }
    };

    fetchData();
    return () => abortController.abort();
  }, [organizationId, propertyId]);
  return data;
};
