import { RadioGroup } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from '../messages';
import * as constants from '../constants';
import useFormatMessage from '../../../hooks/useFormatMessage';
import { OnSourceChangeHandler } from '../OverallAvgRatingCard';

type Props = {
  selectedSource: string,
  onSourceChange: OnSourceChangeHandler,
};

export const OverallAvgRatingSourceFilterColumn = ({
  selectedSource,
  onSourceChange,
}: Props) => {
  const formatMessage = useFormatMessage();

  return (
    <Stack>
      <Typography variant="h5">
        {formatMessage(messages.sourcePlural)}
      </Typography>
      <RadioGroup
        value={selectedSource}
        items={[
          {
            label: constants.GOOGLE_SOURCE_TEXT,
            value: constants.GOOGLE_SOURCE_VALUE,
          },
        ]}
        onChange={(e) => onSourceChange(e.target.value)}
      />
    </Stack>
  );
};
