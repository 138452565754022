import {
  Card,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import GoogleButton from 'react-google-button';
import FacebookLogin from 'react-facebook-login';
import messages from '../messages';
import useFormatMessage from '../../../hooks/useFormatMessage';
import fbIcon from '../../../img/facebook icon white.svg';

const FbLogo = () => (
  <img
    style={{ marginTop: -3, marginRight: 5 }}
    src={fbIcon}
    alt="Facebook Icon"
  />
);

function ConnectCard() {
  const formatMessage = useFormatMessage();

  return (
    <Card>
      <Grid container padding={4} spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h2" textAlign="center">
            {formatMessage(messages.connectCardTitle)}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography textAlign="center" sx={{ maxWidth: 468 }}>
            {formatMessage(messages.connectCardSelectSourceText)}
          </Typography>
        </Grid>
        <Grid container item xs={12} justifyContent="center">
          <GoogleButton style={{ marginRight: 10 }} />
          <FacebookLogin
            cssClass="ft-connect-with-fb"
            textButton={formatMessage(messages.connectWithFb)}
            icon={<FbLogo />}
          />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography textAlign="center" sx={{ maxWidth: 522 }}>
            {formatMessage(messages.connectCardSyncDataText)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="caption"
            sx={{ display: 'block' }}
            textAlign="center"
          >
            {formatMessage(messages.connectCardOneSourceText)}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ConnectCard;
