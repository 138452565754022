import React from 'react';
import { pathOr } from 'ramda';
import { Button } from 'react-bootstrap';

import {
  renderCheckboxField,
  renderDateField,
} from '../../utils/redux-form-helper';
import type {
  FilterValue,
  FilterOptionValue,
} from '../../containers/App/types';
import messages from './messages';

type FilterOption = {
  value: string,
  text: string,
};

type Filter = {
  fieldName: string,
  fieldDescription: string,
  options?: Array<FilterOption>,
  isDateFilter?: boolean,
};

type Props = {
  filters: Array<Filter>,
  currentFilter: FilterValue,
  onFilterChange: Function,
  dateInfo?: Object,
  onCloseClick: Function,
  onApplyClick: Function,
  clearFilters: Function,
  show: boolean,
  formatMessage: Function,
  useCloseBtn?: boolean,
  note?: any,
};

const renderFilterOption = (
  option: FilterOption,
  onFilterChange: Function,
  checked: boolean,
) => {
  return renderCheckboxField({
    label: option.text,
    key: option.value,
    input: {
      onChange: onFilterChange(option.value),
      checked,
    },
    meta: {},
  });
};

/*Note from Carlos Salgado:
  I know this looks stupid, BUT, now the ManageCommunications Prospect filter have more
  than one level of filters so I'm doing this to render the date filter on the right place
  instead of having a random set of date fields at the  end of the drawer.
*/
const renderFilter = (
  filter: Filter,
  onFilterChange: Function,
  currentFilterValue: FilterOptionValue,
  dateInfo?: Object,
  currentFilter?: Object,
) => (
  <div key={filter.fieldName}>
    <h4>{filter.fieldDescription}</h4>
    {filter.options
      ? filter.options.map((option) => [
          renderFilterOption(
            option,
            onFilterChange(filter.fieldName),
            currentFilterValue[option.value] || false,
          ),
          dateInfo &&
          dateInfo.location === 'down' &&
          currentFilter.prospects.deadOrLost &&
          option.value === 'deadOrLost'
            ? renderDateFilter(dateInfo, currentFilter)
            : null,
        ])
      : ''}
  </div>
);

const renderDateFilter = (dateInfo, currentFilter) => {
  const {
    onDateFilterChange,
    errors: { dateFromError, dateToError },
    disabled,
  } = dateInfo;
  const { dateFrom, dateTo } = currentFilter;
  const isValidDate = (currentDate: Object) => currentDate.isBefore(new Date());
  return (
    <div className="row">
      <div className="col-xs-8 col-md-8">
        {renderDateField({
          input: {
            onChange: onDateFilterChange('dateFrom'),
          },
          bsSize: 'lg',
          meta: { error: dateFromError, touched: !!dateFromError },
          placeholder: 'From',
          isControlled: true,
          value: dateFrom,
          isValidDate,
          disabled,
        })}
        {renderDateField({
          input: {
            onChange: onDateFilterChange('dateTo'),
          },
          bsSize: 'lg',
          meta: { error: dateToError, touched: !!dateToError },
          placeholder: 'To',
          isControlled: true,
          value: dateTo,
          isValidDate,
          disabled,
        })}
      </div>
    </div>
  );
};

const FilterDrawer = ({
  filters,
  currentFilter,
  onFilterChange,
  onCloseClick,
  onApplyClick,
  clearFilters,
  show,
  formatMessage,
  dateInfo,
  useCloseBtn,
}: Props) => {
  let disabled = false;
  let location;
  if (dateInfo) {
    location = dateInfo.location;
    disabled =
      !!pathOr(false, ['errors', 'dateFromError'], dateInfo) ||
      !!pathOr(false, ['errors', 'dateToError'], dateInfo);
  }
  return (
    <nav className={`drawer ${show ? 'drawer-is-open' : ''}`}>
      <h3>{formatMessage(messages.filterBy)}</h3>
      <a className="closebtn" onClick={() => onCloseClick(false)}>
        <i className="et-delete-2" />
      </a>
      <div className="nav-filters">
        {dateInfo && location !== 'down'
          ? renderDateFilter(dateInfo, currentFilter)
          : null}
        {filters.map((filter) =>
          renderFilter(
            filter,
            onFilterChange,
            currentFilter[filter.fieldName] || {},
            dateInfo,
            currentFilter,
          ),
        )}
      </div>
      <div className="drawer-actions">
        <Button bsStyle="default" onClick={clearFilters}>
          {formatMessage(messages.clearFilters)}
        </Button>
        <Button bsStyle="primary" onClick={onApplyClick} disabled={disabled}>
          {useCloseBtn
            ? formatMessage(messages.close)
            : formatMessage(messages.apply)}
        </Button>
      </div>
    </nav>
  );
};

export default FilterDrawer;
