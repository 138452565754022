import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    id: 'App.AllManageReports.Create',
    defaultMessage: 'Create',
  },
  createdBy: {
    id: 'App.AllManageReports.CreatedBy',
    defaultMessage: 'Created By',
  },
  creationDate: {
    id: 'App.AllManageReports.CreationDate',
    defaultMessage: 'Creation Date',
  },
  download: {
    id: 'App.AllManageReports.Download',
    defaultMessage: 'Download',
  },
  info: {
    id: 'App.AllManageReports.Info',
    defaultMessage: 'Info',
  },
  inProgress: {
    id: 'App.AllManageReports.InProgress',
    defaultMessage: 'In progress',
  },
  manageReports: {
    id: 'App.AllManageReports.ManageReports',
    defaultMessage: 'Manage Reports',
  },
  report: {
    id: 'App.AllManageReports.Report',
    defaultMessage: 'Report',
  },
  reportCreated: {
    id: 'App.AllManageReports.ReportCreated',
    defaultMessage: 'Report created',
  },
  reportGenerationInProgress: {
    id: 'App.AllManageReports.ReportGenerationInProgress',
    defaultMessage: `Report creation is in progress and may take a few minutes.
      You can download the finished report from the table once it is ready`,
  },
  reportNotFound: {
    id: 'App.AllManageReports.ReportNotFound',
    defaultMessage: 'Report cannot be found',
  },
  reportsCreated: {
    id: 'App.AllManageReports.ReportsCreated',
    defaultMessage: 'Reports Created',
  },
  successful: {
    id: 'App.AllManageReports.Successful',
    defaultMessage: 'Successful',
  },
  type: {
    id: 'App.AllManageReports.Type',
    defaultMessage: 'Type',
  },
  viewHistory: {
    id: 'App.AllManageReports.ViewHistory',
    defaultMessage: 'View History',
  },
});
