import { parse } from 'qs';
import { curryN, pathOr, values } from 'ramda';
import Row from '../../../components/Table/Row';
import Data from '../../../components/Table/Data';
import Spinner from '../../../components/Spinner';
import ActionsMenu from '../ActionsMenu';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import React from 'react';
import { navigateToUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

export const TableRows = ({
  intl,
  headers,
  application,
  assignUnitCallback,
  unassignUnitCallback,
  assignedUnits,
  getUnitQuote,
  user,
  location,
  isLoading,
  filteredUnits,
}: Object): any => {
  const { applicantId, applicationId, prospectId } = parse(
    location.search.replace('?', ''),
  );
  const assignUnitCallbackCurried = curryN(2, assignUnitCallback);
  const unassignUnitCallbackCurried = curryN(2, unassignUnitCallback);

  if (isLoading) {
    return (
      <Row key={1} className="table-row">
        <Data colSpan={headers.length}>
          <Spinner />
        </Data>
      </Row>
    );
  }

  return filteredUnits && filteredUnits.length > 0 ? (
    filteredUnits.map((columns: Object, key: number): any => {
      let isSelfAssigned = false;
      const quoteObj: Object = {
        unitId: columns.id,
        userId: user.id,
        applicantId: columns.applicantId,
      };
      if (prospectId) {
        quoteObj.applicantId = null;
        quoteObj.prospectId = prospectId;
      }
      if (applicantId && applicationId) {
        quoteObj.applicantId = applicantId;
        quoteObj.applicationId = applicationId;
      }
      if (columns.applicationId === applicationId) {
        isSelfAssigned = true;
      }
      return (
        <Row key={key} className="table-row">
          {values(columns.rows).map(({ value, link }, key) => {
            if (key === 0) {
              return (
                <Data key={key}>
                  <a
                    onClick={() =>
                      navigateToUrlWithSelectedPropertyId(
                        `/unit/${columns.id}?prev=available`,
                      )
                    }
                  >
                    {value}
                  </a>
                </Data>
              );
            }
            return link ? (
              <Data key={key}>
                <a onClick={() => navigateToUrlWithSelectedPropertyId(link)}>
                  {value}
                </a>
              </Data>
            ) : (
              <Data key={key}>{value}</Data>
            );
          })}
          <Data>
            <ActionsMenu
              assignedUnits={assignedUnits}
              unitId={columns.id}
              assignedApplicationId={columns.applicationId}
              applicationId={application.id}
              isSelfAssigned={isSelfAssigned}
              applicationStatus={pathOr(
                '',
                ['applicationStatus', 'name'],
                application,
              )}
              application={application}
              unit={columns}
              quoteCallback={getUnitQuote.bind(null, quoteObj)}
              assignCallback={() =>
                assignUnitCallbackCurried(columns.id, application, intl)
              }
              unassignCallback={() =>
                unassignUnitCallbackCurried(
                  columns.assignedUnitId,
                  application,
                  intl,
                )
              }
            />
          </Data>
        </Row>
      );
    })
  ) : (
    <Row key={1} className="table-row">
      <Data colSpan={headers.length}>
        <FormattedMessage {...messages.noUnitsAvailable} />
      </Data>
    </Row>
  );
};
