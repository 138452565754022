export const statusNameConstants = {
  completed: 'Completed',
  notCompleted: "Didn't Complete",
  scheduledPastDue: 'Scheduled - Past Due',
  scheduledUpcoming: 'Scheduled - Upcoming',
};

export const autoOrManualNameConstants = {
  automatedActivity: 'Automated Only',
  manualActivity: 'Manual Only',
};

export const autoOrManualOptions = [
  { value: 'automatedActivity', text: 'Automated Only' },
  { value: 'manualActivity', text: 'Manual Only' },
];
