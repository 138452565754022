type FormModeState = {
  affordableRelationship: Array,
  leaseLocked: boolean,
  isPreviousPrimary: boolean,
  isPrimaryApplicant: boolean,
  index: number,
};

export function BaseMode(disablingLogic) {
  return {
    ...(disablingLogic ?? {}),

    disableDropdownOption(relation: Object, disablingConditions) {
      const disabled =
        disablingConditions?.some((condition) => condition) ?? false;
      return {
        ...relation,
        disabled,
      };
    },

    disableDropdownOptions(state: FormModeState): Array {
      const { affordableRelationship } = state;
      return affordableRelationship.map((relation) => {
        const disablingConditions =
          this?.getDisablingConditions(relation, state) ?? [];
        return this.disableDropdownOption(relation, disablingConditions);
      });
    },

    getAffordableRelationshipBundle(state: FormModeState): Object {
      const disabled = this?.isAffordableRelationDisabled(state) ?? false;
      const processedAffordableRelationships =
        this.disableDropdownOptions(state);
      return {
        disabled,
        processedAffordableRelationships,
      };
    },
  };
}

export function ProspectMode() {
  return new BaseMode({
    isAffordableRelationDisabled({
      isPrimaryApplicant,
    }: FormModeState): boolean {
      return isPrimaryApplicant;
    },
    getDisablingConditions(
      relation: Object,
      state: FormModeState,
    ): Array<boolean> {
      const { isPrimaryApplicant } = state;
      const { value, text } = relation;
      return [
        /default/i.test(value),
        /Head of Household/i.test(text) && !isPrimaryApplicant,
      ];
    },
  });
}

export function CreateApplicationMode() {
  return new BaseMode({
    isAffordableRelationDisabled({ index }: FormModeState): boolean {
      return index === 0;
    },
    getDisablingConditions(relation: Object): Array<boolean> {
      const { value, text } = relation;
      return [/default/i.test(value), /Head of Household/i.test(text)];
    },
  });
}

export function DefaultMode() {
  return new BaseMode({
    isAffordableRelationDisabled({
      isPreviousPrimary,
      leaseLocked,
      isPrimaryApplicant,
    }): boolean {
      return (!isPreviousPrimary && leaseLocked) || isPrimaryApplicant;
    },
    getDisablingConditions(relation, state): Array<boolean> {
      const { value } = relation;
      return [/default/i.test(value)];
    },
  });
}

export function getStateMappers({
  PROSPECT_HOUSEHOLD,
  PROSPECT_CONVERT,
  APPLICATION_HOUSEHOLD,
  DEFAULT_MODE,
}: Object) {
  return Object.freeze({
    [PROSPECT_CONVERT]: new CreateApplicationMode(),
    [PROSPECT_HOUSEHOLD]: new ProspectMode(),
    [APPLICATION_HOUSEHOLD]: new ProspectMode(),
    [DEFAULT_MODE]: new DefaultMode(),
  });
}
