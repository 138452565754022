import { put, takeLatest, call, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { push, goBack } from 'react-router-redux';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import ApplicantFormService from '../../services/applicantFormService';
import * as ActionTypes from './constants';
import * as secondaryFormActions from './actions';
import messages from './messages';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';

import type { Saga } from 'redux-saga';
import type { Action } from '../App/types';

export function* fetchgetApplicantSecondaryFormInformation(
  action: Action<string>,
): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const applicantFormService = new ApplicantFormService();
    const response = yield call(
      applicantFormService.getApplicantSecondaryFormInformation,
      action.payload,
      organizationId,
      selectedProperty.id,
    );
    yield put(
      secondaryFormActions.getApplicantSecondaryFormInformationSuccess(
        response,
      ),
    );
  } catch (err) {
    yield put(
      secondaryFormActions.getApplicantSecondaryFormInformationError(err),
    );
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getApplicantSecondaryFormInformation(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICANT_SECONDARY_FORM_INFORMATION,
    fetchgetApplicantSecondaryFormInformation,
  );
}

export function* fetchSaveSecondaryForm(action: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const successMessage = action.payload.applicant.complete
      ? messages.completionSuccessDescription
      : messages.progressSuccessDescription;
    const applicantFormService = new ApplicantFormService();
    yield applicantFormService.saveSecondaryForm(
      action.payload.applicant,
      action.payload.applicantId,
      organizationId,
      selectedProperty.id,
    );
    yield put(secondaryFormActions.saveSecondaryFormSuccess());
    if (action.payload.convertedResident) {
      yield put(goBack());
    } else {
      yield put(
        push(
          getUrlWithSelectedPropertyId(
            `/application/${action.payload.applicationId}/?tab=5`,
          ),
        ),
      );
    }
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(successMessage),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* saveSecondaryForm(): Saga<void> {
  yield takeLatest(ActionTypes.SAVE_SECONDARY_FORM, fetchSaveSecondaryForm);
}

export default [getApplicantSecondaryFormInformation, saveSecondaryForm];
