import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pathOr, isNil, either, reject, compose, omit } from 'ramda';

import {
  mapApplicantToBasicInformation,
  mapApplicantToContactInformation,
  mapApplicantToMaritalInformation,
  mapApplicantToSecundaryFormAdditionalOccupants,
  mapApplicantToCurrentResidence,
  mapApplicantToPreviousResidence,
  mapApplicantToSecondPreviousResidence,
  mapApplicantToEmploymentStatus,
  mapApplicantToStudentStatus,
  mapApplicantToCurrentEmployer,
  mapApplicantToSecondEmployer,
  mapApplicantToPreviousEmployer,
  mapApplicantSecundaryFormToOtherInformation,
  mapApplicantToSignature,
  getPropertyName,
  getPropertyAffordable,
  getApplicationIncomeMultiplier,
} from '../PrimaryLeaseApplication/selectors';
import * as secondaryLeaseApplicationActions from './actions';
import {
  getAllNameSuffixes,
  getAllCountries,
  getAllStates,
  getAllEmploymentStatuses,
} from '../App/actions';
import { printApplicationForm } from '../PrimaryLeaseApplication/actions';
import {
  getNameSuffixOptions,
  getCountryOptions,
  getStateOptions,
  getEmploymentStatusesOptions,
  getSelectedPropertyState,
  selectSelectedProperty,
} from '../App/selectors';
import SecondaryForm from './NonPrimaryForm';
import AffordableSecondaryForm from './NonPrimaryAffordableForm/index';
import { DEFAULT_EMPTY_EMPLOYER_INFO } from './constants';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import type {
  GlobalState,
  SelectOption,
  ResidencialInformation,
} from '../App/types';

type StateProps = {
  applicant?: any,
  applicantId: string,
  applicationId: string,
  locale: string,
  suffixes: Array<SelectOption>,
  countries: Array<SelectOption>,
  states: Array<SelectOption>,
  propertyName: string,
  propertyAffordable: boolean,
  employmentStatuses: Array<SelectOption>,
  basicInformation: Object,
  contactInformation: Object,
  additionalOccupants: Object,
  currentResidenceInformation: Object,
  previousResidenceInformation: Object,
  secondPreviousResidenceInformation: Object,
  employmentInformation: Object,
  studentInformation: Object,
  applicantsCurrentEmployer: Object,
  applicantsSecondCurrentEmployer: Object,
  applicantsPreviousEmployer: Object,
  otherInformation: Object,
  signature: Object,
  maritalInformation: Object,
  isSubmitting: boolean,
  propertyState: string,
  propertyCity: string,
};

type InjectedProps = {
  intl: Object,
  history: Object,
  actions: {
    getApplicantSecondaryFormInformation: (string) => void,
    getAllNameSuffixes: () => void,
    getAllCountries: () => void,
    getAllStates: () => void,
    getAllEmploymentStatuses: () => void,
    saveSecondaryForm: (any, string, string, string) => void,
    cleanApplicantSecondaryFormInformation: () => void,
    printApplicationForm: (
      string,
      string,
      string,
      string,
      boolean,
      boolean,
      boolean,
    ) => void,
  },
};

export class SecondaryLeaseApplicationPage extends Component<
  StateProps & InjectedProps,
> {
  componentDidMount() {
    this.props.actions.getApplicantSecondaryFormInformation(
      this.props.applicantId,
    );
    this.props.actions.getAllNameSuffixes();
    this.props.actions.getAllCountries();
    this.props.actions.getAllStates();
    this.props.actions.getAllEmploymentStatuses();
  }

  getApplicantId = () => {
    // $FlowFixMe
    return pathOr('', ['applicant', 'applicationId'], this.props);
  };

  isConvertedResident = () => {
    // $FlowFixMe
    return pathOr(false, ['applicant', 'convertedResident'], this.props);
  };
  mapResidenceInformationToSubmit = (
    residenceInformation: ResidencialInformation,
  ) => {
    let lenderName = '';
    let lenderPhoneNumber = '';
    let doNotCurrentlyOwnRentExplanation = '';

    if (residenceInformation.ownOrRent.ownOrRentOrNotCurrently === 'own') {
      // $FlowFixMe
      lenderName = pathOr(
        '',
        ['ownOrRent', 'lenderName'],
        residenceInformation,
      );
      // $FlowFixMe
      lenderPhoneNumber = pathOr(
        '',
        ['ownOrRent', 'lenderPhoneNumber'],
        residenceInformation,
      );
    } else if (
      residenceInformation.ownOrRent.ownOrRentOrNotCurrently === 'rent'
    ) {
      // $FlowFixMe
      lenderName = pathOr(
        '',
        ['ownOrRent', 'landlordName'],
        residenceInformation,
      );
      // $FlowFixMe
      lenderPhoneNumber = pathOr(
        '',
        ['ownOrRent', 'landlordPhoneNumber'],
        residenceInformation,
      );
    } else {
      // $FlowFixMe
      doNotCurrentlyOwnRentExplanation = pathOr(
        '',
        ['ownOrRent', 'doNotCurrentlyOwnRentExplanation'],
        residenceInformation,
      );
    }

    return {
      ...residenceInformation,
      ownOrRent: {
        lenderName,
        lenderPhoneNumber,
        doNotCurrentlyOwnRentExplanation,
        // $FlowFixMe
        reasonForMoving: pathOr(
          '',
          ['ownOrRent', 'reasonForMoving'],
          residenceInformation,
        ),
        // $FlowFixMe
        ownOrRentOrNotCurrently: pathOr(
          '',
          ['ownOrRent', 'ownOrRentOrNotCurrently'],
          residenceInformation,
        ),
      },
    };
  };

  mapFormValuesToApplicant = (
    complete: boolean,
    nonComplete: boolean,
    values: Object,
  ) => {
    const isDefault = (val) => val === 'default' || val === '';
    const isNilOrDefault = either(isNil, isDefault);
    const parseEmployerInfo = (employerInfo: Object) => {
      const employerInfoWithDefaults = {
        ...DEFAULT_EMPTY_EMPLOYER_INFO,
        ...employerInfo,
      };
      return {
        ...reject(isNilOrDefault, employerInfoWithDefaults),
        address: reject(isNilOrDefault, employerInfoWithDefaults.address),
      };
    };

    const currentResidenceInformation = this.mapResidenceInformationToSubmit(
      values.currentResidenceInformation,
    );
    const previousResidenceInformation = this.mapResidenceInformationToSubmit(
      values.previousResidenceInformation,
    );

    let secondPreviousResidenceInformation = null;
    if (values.secondPreviousResidenceInformation) {
      secondPreviousResidenceInformation = this.mapResidenceInformationToSubmit(
        values.secondPreviousResidenceInformation,
      );
    }
    let returnedSecondPreviousResidenceInformation = undefined;
    if (secondPreviousResidenceInformation !== null) {
      returnedSecondPreviousResidenceInformation = {
        ...reject(isNilOrDefault, secondPreviousResidenceInformation),
        address: reject(
          isNilOrDefault,
          secondPreviousResidenceInformation.address,
        ),
        ownOrRent: reject(
          isNilOrDefault,
          secondPreviousResidenceInformation.ownOrRent,
        ),
      };
    }
    return {
      applicantsCurrentEmployer: parseEmployerInfo(
        values.applicantsCurrentEmployer,
      ),
      applicantsPreviousEmployer: parseEmployerInfo(
        values.applicantsPreviousEmployer,
      ),
      applicantsSecondCurrentEmployer: parseEmployerInfo(
        values.applicantsSecondCurrentEmployer,
      ),
      additionalOccupants: {
        ...reject(isNilOrDefault, values.additionalOccupants),
      },
      currentResidenceInformation: {
        ...reject(isNilOrDefault, currentResidenceInformation),
        address: reject(isNilOrDefault, currentResidenceInformation.address),
        ownOrRent: reject(
          isNilOrDefault,
          currentResidenceInformation.ownOrRent,
        ),
      },
      secondPreviousResidenceInformation:
        returnedSecondPreviousResidenceInformation,
      previousResidenceInformation: {
        ...reject(isNilOrDefault, previousResidenceInformation),
        address: reject(isNilOrDefault, previousResidenceInformation.address),
        ownOrRent: reject(
          isNilOrDefault,
          previousResidenceInformation.ownOrRent,
        ),
      },
      studentInformation: {
        ...reject(isNilOrDefault, values.studentInformation),
      },
      employmentInformation: {
        ...reject(isNilOrDefault, values.employmentInformation),
      },
      basicInformation: {
        ...reject(
          isNilOrDefault,
          omit(['disabilities'], values.basicInformation),
        ),
      },
      contactInformation: {
        ...reject(isNilOrDefault, values.contactInformation),
      },
      maritalInformation: {
        ...reject(isNilOrDefault, values.maritalInformation),
      },
      otherInformation: {
        ...reject(isNilOrDefault, values.otherInformation),
      },
      signature: values.signature,
      complete,
      nonComplete,
    };
  };

  handleCancel = () => {
    const applicationId = this.getApplicantId();
    const convertedResident = this.isConvertedResident();
    if (convertedResident) {
      this.props.history.goBack();
    } else {
      this.props.history.push(
        getUrlWithSelectedPropertyId(`/application/${applicationId}/?tab=5`),
      );
    }
  };

  handleSubmit = (complete: boolean, nonComplete: boolean, values: any) => {
    const applicationId = this.getApplicantId();
    const convertedResident = this.isConvertedResident();
    const formData = this.mapFormValuesToApplicant(
      complete,
      nonComplete,
      values,
    );
    this.props.actions.saveSecondaryForm(
      formData,
      this.props.applicantId,
      applicationId,
      convertedResident,
    );
  };

  handlePrint = () => {
    const { applicationId, applicantId, propertyAffordable, applicant } =
      this.props;
    this.props.actions.printApplicationForm(
      applicationId,
      applicantId,
      applicant.applicantInfo.basicInfo.firstName,
      applicant.applicantInfo.basicInfo.lastName,
      propertyAffordable,
      true,
      false,
      true,
    );
  };

  render() {
    if (isNil(this.props.applicant)) {
      return <div />;
    }

    const {
      intl,
      suffixes,
      countries,
      states,
      propertyName,
      employmentStatuses,
      basicInformation,
      contactInformation,
      maritalInformation,
      additionalOccupants,
      currentResidenceInformation,
      previousResidenceInformation,
      secondPreviousResidenceInformation,
      employmentInformation,
      studentInformation,
      applicantsCurrentEmployer,
      applicantsSecondCurrentEmployer,
      applicantsPreviousEmployer,
      otherInformation,
      signature,
      propertyAffordable,
      isSubmitting,
      applicationIncomeMultiplier,
      applicant: { isComplete },
      propertyState,
      propertyCity,
    } = this.props;
    if (propertyAffordable) {
      const affordableEmployments = employmentStatuses.filter(
        (status) => status.text !== 'Student',
      );
      return (
        <AffordableSecondaryForm
          onSubmit={this.handleSubmit}
          intl={intl}
          suffixes={suffixes}
          countries={countries}
          states={states}
          employmentStatuses={affordableEmployments}
          propertyName={propertyName}
          applicationIncomeMultiplier={applicationIncomeMultiplier}
          handlePrint={this.handlePrint}
          handleCancel={compose(
            this.handleCancel,
            this.props.actions.cleanApplicantSecondaryFormInformation,
          )}
          initialValues={{
            basicInformation,
            contactInformation,
            maritalInformation,
            additionalOccupants,
            currentResidenceInformation,
            previousResidenceInformation,
            secondPreviousResidenceInformation,
            employmentInformation,
            studentInformation,
            applicantsCurrentEmployer,
            applicantsSecondCurrentEmployer,
            applicantsPreviousEmployer,
            otherInformation,
            signature,
          }}
          isSubmitting={isSubmitting}
          isComplete={isComplete}
          propertyState={propertyState}
          propertyCity={propertyCity}
        />
      );
    }

    return (
      <SecondaryForm
        onSubmit={this.handleSubmit}
        intl={intl}
        suffixes={suffixes}
        countries={countries}
        states={states}
        employmentStatuses={employmentStatuses}
        propertyName={propertyName}
        applicationIncomeMultiplier={applicationIncomeMultiplier}
        handlePrint={this.handlePrint}
        handleCancel={compose(
          this.handleCancel,
          this.props.actions.cleanApplicantSecondaryFormInformation,
        )}
        initialValues={{
          basicInformation,
          contactInformation,
          maritalInformation,
          additionalOccupants,
          currentResidenceInformation,
          previousResidenceInformation,
          employmentInformation,
          studentInformation,
          applicantsCurrentEmployer,
          applicantsSecondCurrentEmployer,
          applicantsPreviousEmployer,
          otherInformation,
          signature,
        }}
        isSubmitting={isSubmitting}
        isComplete={isComplete}
        propertyState={propertyState}
        propertyCity={propertyCity}
      />
    );
  }
}

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): StateProps => {
  const { secondaryLeaseApplication, languageProvider } = state;
  const property = selectSelectedProperty(state);
  return {
    applicantId: ownProps.match.params.applicantId,
    applicationId: ownProps.match.params.applicationId,
    applicant: secondaryLeaseApplication.applicant,
    locale: languageProvider.locale,
    suffixes: getNameSuffixOptions(state),
    countries: getCountryOptions(state),
    states: getStateOptions(state),
    propertyState: getSelectedPropertyState(state),
    propertyCity: property?.physicalCity,
    propertyAffordable: getPropertyAffordable(secondaryLeaseApplication),
    employmentStatuses: getEmploymentStatusesOptions(state),
    propertyName: getPropertyName(secondaryLeaseApplication),
    applicationIncomeMultiplier: getApplicationIncomeMultiplier(
      secondaryLeaseApplication,
    ),
    basicInformation: mapApplicantToBasicInformation(secondaryLeaseApplication),
    contactInformation: mapApplicantToContactInformation(
      secondaryLeaseApplication,
    ),
    maritalInformation: mapApplicantToMaritalInformation(
      secondaryLeaseApplication,
    ),
    additionalOccupants: mapApplicantToSecundaryFormAdditionalOccupants(
      secondaryLeaseApplication,
    ),
    currentResidenceInformation: mapApplicantToCurrentResidence(
      secondaryLeaseApplication,
    ),
    previousResidenceInformation: mapApplicantToPreviousResidence(
      secondaryLeaseApplication,
    ),
    secondPreviousResidenceInformation: mapApplicantToSecondPreviousResidence(
      secondaryLeaseApplication,
    ),
    studentInformation: mapApplicantToStudentStatus(secondaryLeaseApplication),
    employmentInformation: mapApplicantToEmploymentStatus(
      secondaryLeaseApplication,
    ),
    applicantsCurrentEmployer: mapApplicantToCurrentEmployer(
      secondaryLeaseApplication,
    ),
    applicantsSecondCurrentEmployer: mapApplicantToSecondEmployer(
      secondaryLeaseApplication,
    ),
    applicantsPreviousEmployer: mapApplicantToPreviousEmployer(
      secondaryLeaseApplication,
    ),
    otherInformation: mapApplicantSecundaryFormToOtherInformation(
      secondaryLeaseApplication,
    ),
    signature: mapApplicantToSignature(secondaryLeaseApplication),
    isSubmitting: secondaryLeaseApplication.isSubmitting,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...secondaryLeaseApplicationActions,
      getAllNameSuffixes,
      getAllCountries,
      getAllStates,
      getAllEmploymentStatuses,
      printApplicationForm,
    },
    dispatch,
  );
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(SecondaryLeaseApplicationPage));
