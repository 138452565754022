import { not, isEmpty, prop, isNil, equals } from 'ramda';
import moment from 'moment';
import { removeNonNumericCharacters } from './';
import type Moment from 'moment';

export const validateEmail = (email: string) =>
  !(email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(email));

export const validateRange = (value: number, min: number, max: number) =>
  value && value >= min && value <= max;

export const validatePhoneNumber = (phoneNumber: string) =>
  !(
    phoneNumber &&
    !/^[2-9]{1}\d{9}$/.test(removeNonNumericCharacters(phoneNumber))
  );

export const validateName = (name?: string) =>
  name && /^[a-zA-Z][a-zA-Z0-9 .',&-]*$/i.test(name);

export const validateBusinessName = (name?: string) =>
  name && /^[a-zA-Z0-9][a-zA-Z0-9 .',&-]*$/i.test(name);

export const validateRequired = (property: string, obj: Object) =>
  not(isEmpty(prop(property, obj))) &&
  not(isNil(prop(property, obj))) &&
  !equals('default', prop(property, obj));

export const validateAlphaNumericValue = (value?: string) =>
  value && /^[a-zA-Z0-9]*$/i.test(value);

export const validateSocialSecurityNumber = (ssn: string) =>
  !(ssn && !/^\d{9}$/.test(ssn));

export const validateNumber = (num?: string) =>
  num && /^[0-9-+()]*$/i.test(num);

export const validateDunsNumber = (duns: string) =>
  duns && /^(\d+-?)+\d+$/.test(duns);

export const validatePositiveNumber = (num?: string) =>
  num && /^\d*[0-9]\d*$/i.test(num);

export const validatePositiveMoney = (num?: string) =>
  num && /^[0-9]+(\.[0-9]{1,2})?$/i.test(num);

export const validateDate = (date?: string | Date | Moment) => {
  if (typeof date === 'string') {
    return (
      date && /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}$/i.test(date)
    );
  } else {
    return (
      date &&
      /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}$/i.test(
        moment(date).format('MM/DD/YYYY'),
      )
    );
  }
};

export const validateZipCode = (zipCode?: string) => {
  const ZipCodeReg = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/);
  return ZipCodeReg.test(zipCode);
};
