import React, { Component } from 'react';
import { Modal, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import { createConfirmation, confirmable } from 'react-confirm';
import { pathOr, values } from 'ramda';
import messages from './messages';
import SubmitButton from '../SubmitButton';
import { formatNewLine, TEXT_ALIGN_OPTIONS } from '../../utils/formatter';

type Props = {
  show: boolean,
  dismiss: Function,
  confirmation: any,
  options: Object,
  proceed: Function,
};

type State = {
  isSubmitting: boolean,
};

const THEME_TO_BS_STYLE = {
  delete: 'danger',
};

export class ConfirmDialog extends Component<Props, State> {
  constructor(props: State & Props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  render() {
    const { show, dismiss, confirmation, options, proceed } = this.props;
    const { centered } = options;
    const formatMessage = pathOr(
      (msg) => msg.defaultMessage,
      ['intl', 'formatMessage'],
      options,
    );
    const confirmationBody = pathOr(null, ['body'], options);
    const altConfirmationBody = pathOr(null, ['altBody'], options);
    const cancelFunc = pathOr(null, ['cancelFunc'], options);
    const cancelConfirm = pathOr(false, ['cancelConfirm'], options);
    const reverseBtns = options?.reverseBtns ?? false;
    const theme = options?.theme;
    const cancelText = options?.cancelText;
    const confirmText = options?.confirmText;

    const confirmationBodyTextAlign = values(TEXT_ALIGN_OPTIONS).includes(
      options?.bodyTextAlign,
    )
      ? options?.bodyTextAlign
      : TEXT_ALIGN_OPTIONS.CENTER;

    const altConfirmationBodyTextAlign = values(TEXT_ALIGN_OPTIONS).includes(
      options?.altBodyTextAlign,
    )
      ? options?.altBodyTextAlign
      : TEXT_ALIGN_OPTIONS.CENTER;

    const onHideOrCancel = () => {
      if (cancelFunc) {
        cancelFunc();
      }
      dismiss();
    };
    const cancelNo = formatMessage(
      cancelConfirm ? messages.cancel : messages.no,
    );
    const confirmYes = formatMessage(
      cancelConfirm ? messages.confirm : messages.yes,
    );

    const btns = [
      <Button key={1} type="button" bsStyle="default" onClick={onHideOrCancel}>
        {cancelText || cancelNo}
      </Button>,
      <SubmitButton
        key={2}
        bsStyle={THEME_TO_BS_STYLE[theme] || 'primary'}
        className="pull-left"
        disabled={options.disableSubmit || this.state.isSubmitting}
        clickHandler={() => {
          this.setState({ isSubmitting: true }, proceed('proceed'));
        }}
        isSubmitting={this.state.isSubmitting}
      >
        {confirmText || confirmYes}
      </SubmitButton>,
    ];

    return (
      <div className="static-modal">
        <Modal
          bsSize="small"
          dialogClassName={`modal-dialog ${
            centered ? 'modal-dialog-centered' : ''
          } modal-alert`}
          show={show}
          onHide={onHideOrCancel}
          autoFocus={true}
          data-testid="confirm-modal"
          centered={true}
        >
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <h2 className="text-center">{confirmation}</h2>
                {confirmationBody && (
                  <p className={`text-${confirmationBodyTextAlign}`}>
                    {formatNewLine(confirmationBody)}
                  </p>
                )}
                {altConfirmationBody && (
                  <div className={`text-${altConfirmationBodyTextAlign}`}>
                    {formatNewLine(altConfirmationBody)}
                  </div>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar>{reverseBtns ? btns.reverse() : btns}</ButtonToolbar>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const confirm = createConfirmation(confirmable(ConfirmDialog));

// This is optional. But I recommend to define your confirm function easy to call.
export default function (confirmation: any, options: {} = {}) {
  // You can pass whatever you want to the component. These arguments will be your Component's props
  return confirm({ confirmation, options });
}
