import React from 'react';
import styled from 'styled-components';

export type Props = {
  key: number,
  bedroomSize: number,
  leased: number,
  status: number,
};

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Paragraph = styled.p`
  margin: 0;
  font-weight: 300;
`;

const SummaryBlock = ({ bedroomSize, leased, status }: Props) => {
  const blockTitle = bedroomSize === '0' ? 'STUDIO' : `${bedroomSize} BEDROOM`;
  const leasedCount = leased['Yes'] || 0;
  const notLeasedCount = leased['No'] || 0;
  const readyCount = status['Vacant - Ready'] || 0;
  const notReadyCount =
    (status['Vacant / Not Ready'] || 0) +
    (status['Occupied / On Notice to Vacate'] || 0);

  return (
    <div className="col-md-2 col-lg-2">
      <div className="block-data block-white-shadow">
        <h5 className="padleft30 padright30">{blockTitle}</h5>
        <Content>
          <div className="padleft5 text-left">
            <Paragraph>
              <strong>Leased: </strong>
              {leasedCount}
            </Paragraph>
            <Paragraph>
              <strong>Not Leased: </strong>
              {notLeasedCount}
            </Paragraph>
          </div>
          <div className="padright5 text-left">
            <Paragraph>
              <strong>Ready: </strong>
              {readyCount}
            </Paragraph>
            <Paragraph>
              <strong>Not Ready: </strong>
              {notReadyCount}
            </Paragraph>
          </div>
        </Content>
      </div>
    </div>
  );
};

export default SummaryBlock;
