import { defineMessages } from 'react-intl';

const messages = defineMessages({
  tabTitle: {
    id: 'ManageAmenities.TabTitle',
    defaultMessage: 'Fortress - Manage Amenities',
  },
  title: {
    id: 'ManageAmenities.Title',
    defaultMessage: 'Manage Amenities',
  },
  addAmenityButton: {
    id: 'ManageAmenities.AddAmenityButton',
    defaultMessage: 'Add New Amenity',
  },
  amenityColumn: {
    id: 'ManageAmenities.amenityColumn',
    defaultMessage: 'Amenity',
  },
  valueColumn: {
    id: 'ManageAmenities.valueColumn',
    defaultMessage: 'Value',
  },
  numOfUnitsColumn: {
    id: 'ManageAmenities.numOfUnitsColumn ',
    defaultMessage: '# of Units',
  },
  valueXUnitsColumn: {
    id: 'ManageAmenities.valueXUnitsColumn',
    defaultMessage: 'Value x Units',
  },
  editColumn: {
    id: 'ManageAmenities.editColumn',
    defaultMessage: 'Edit',
  },
  emptyStateMessage: {
    id: 'ManageAmenities.emptyStateMessage',
    defaultMessage: 'No amenities in this property.',
  },
});

export default messages;
