import messages from './messages';
import ActivityService from '../../services/activityService';
import { FORM_TYPE } from './constants';

const getReturnPromise = (errorFromForm: any, errorToReturn: any) => {
  const errors = {};
  if (errorFromForm && errorFromForm.sync) {
    errors._error = errorFromForm;
  }

  if (errorToReturn) {
    errors._error = {
      sync: false,
      async: true,
      message: errorToReturn,
    };
  }

  if (errors._error) {
    return Promise.reject(errors);
  } else {
    return Promise.resolve();
  }
};

const asyncValidate = async (
  values: Object,
  dispatch: Function,
  { intl, mode, error, prospect, organizationId }: Object,
): Promise<void> => {
  if (mode === FORM_TYPE.SCHEDULE && !values.noTimes) {
    const activityService = new ActivityService();
    let errorToReturn;
    const propertyId = prospect.propertyId
      ? prospect.propertyId
      : prospect?.nextAct?.propertyId;
    const assigneeResponse = await activityService.getPendingByAssignee(
      values.assignedToId,
      propertyId,
      organizationId,
    );
    if (assigneeResponse.length >= 201) {
      errorToReturn = intl.formatMessage(messages.tooManyActivitiesForAssignee);
    }
    const prospectResponse = await activityService.getPendingByProspect(
      prospect.id,
      propertyId,
      organizationId,
    );
    if (prospectResponse.length >= 5) {
      errorToReturn = intl.formatMessage(messages.tooManyActivitiesForProspect);
    }

    return getReturnPromise(error, errorToReturn);
  }

  return getReturnPromise(error);
};

export default asyncValidate;
