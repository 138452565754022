import React from 'react';
import { renderTextField, renderSelectField } from '../../../../utils/redux-form-helper';
import { Field } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import messages from '../messages';
import deleteConfirmation from '../../../../components/DeleteConfirmation';

type ComponentProps = {
  intl: any,
  minor: any,
  onDelete: Function,
  suffixes: Array<Object>
};

const MinorIndividual = ({ minor, onDelete, suffixes, intl }: ComponentProps) => {
  const deleteItemCallback = (options) => {
    deleteConfirmation(intl.formatMessage(messages.deleteItemConfirmation), {
      intl
    })
      .then(() => onDelete(options))
      .catch(() => {});
  };

  return (
    <Row>
      <div className="container-of-inputs">
        <Col xs={6} sm={2}>
          <Field className="input-lg" name={`${minor}.firstName`} component={renderTextField} maxLength="35" />
        </Col>
        <Col xs={6} sm={2}>
          <Field className="input-lg" name={`${minor}.middleName`} component={renderTextField} maxLength="35" />
        </Col>
        <Col xs={6} sm={2}>
          <Field className="input-lg" name={`${minor}.lastName`} component={renderTextField} maxLength="35" />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            selectClassNames="input-lg"
            name={`${minor}.suffixId`}
            component={renderSelectField}
            options={suffixes}
          />
        </Col>
        <Col xs={6} sm={2}>
          <Field className="input-lg" name={`${minor}.preferredName`} component={renderTextField} maxLength="35" />
        </Col>
        <Col xs={6} sm={2}>
          <Field className="input-lg" name={`${minor}.age`} component={renderTextField} maxLength="35" />
        </Col>
      </div>
      <div className="container-of-trash">
        <div className="row-remove-btn">
          <a onClick={deleteItemCallback}>
            <i className="et-trash" />
          </a>
        </div>
      </div>
    </Row>
  );
};

export default MinorIndividual;
