import React from 'react';
import styled from 'styled-components';
import { isNil, isEmpty } from 'ramda';
import IntacctStatusDescription from './IntacctStatusDescription';

const NormalText = styled.span`
  font-weight: normal;
  display: inline !important;
`;

const HorizontalRuler = styled.hr`
  height: 1px;
  border-width: 0;
  background-color: #d1d1d1;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const LeftAlign = styled.div`
  text-align: left;
`;

type IntacctJEStatus = {
  id: string,
  referenceNumber: string,
  status: 'Pending' | 'Sent' | 'Error',
};

type Props = {
  faraStatus: IntacctJEStatus,
  farcStatus: IntacctJEStatus,
};

const getReferenceNumber = (jeStatus: IntacctJEStatus) => {
  const refNumber = jeStatus?.referenceNumber;
  const status = jeStatus?.status;
  return isNil(refNumber) || isEmpty(refNumber) || status !== 'Sent'
    ? '---'
    : refNumber;
};

const IntacctStatusSection = ({ faraStatus, farcStatus }: Props) => {
  const faraRefNumber = getReferenceNumber(faraStatus);
  const farcRefNumber = getReferenceNumber(farcStatus);
  return (
    <LeftAlign>
      <div>
        <b>FARA Status:</b> <IntacctStatusDescription status={faraStatus} />
      </div>
      <div>
        <b>Reference #:</b> <NormalText>{faraRefNumber}</NormalText>
      </div>
      <HorizontalRuler />
      <div>
        <b>FARC Status:</b> <IntacctStatusDescription status={farcStatus} />
      </div>
      <div>
        <b>Reference #:</b> <NormalText>{farcRefNumber}</NormalText>
      </div>
    </LeftAlign>
  );
};

export default IntacctStatusSection;
