export const GET_RENEWAL_OFFER_TERMS =
  'app/GenerateRenewalOffer/GET_RENEWAL_OFFER_TERMS';
export const GET_RENEWAL_OFFER_TERMS_SUCCESS =
  'app/GenerateRenewalOffer/GET_RENEWAL_OFFER_TERMS_SUCCESS';
export const GET_RENEWAL_OFFER_TERMS_ERROR =
  'app/GenerateRenewalOffer/GET_RENEWAL_OFFER_TERMS_ERROR';

export const CREATE_RENEWAL_OFFER =
  'app/GenerateRenewalOffer/CREATE_RENEWAL_OFFER';
export const CREATE_RENEWAL_OFFER_ERROR =
  'app/GenerateRenewalOffer/CREATE_RENEWAL_OFFER_ERROR';

export const GET_PREVIOUS_RENEWAL_OFFERS =
  'app/GenerateRenewalOffer/GET_PREVIOUS_RENEWAL_OFFERS';
export const GET_PREVIOUS_RENEWAL_OFFERS_SUCCESS =
  'app/GenerateRenewalOffer/GET_PREVIOUS_RENEWAL_OFFERS_SUCCESS';
export const GET_PREVIOUS_RENEWAL_OFFERS_ERROR =
  'app/GenerateRenewalOffer/GET_PREVIOUS_RENEWAL_OFFERS_ERROR';

export const CLEAR_RENEWAL_OFFER_TERMS =
  'app/GenerateRenewalOffer/CLEAR_RENEWAL_OFFER_TERMS';
