import { promptToaster } from '../App/actions';

/**
 * Consumed by Socket context to register listeners
 */
const paymentBatchSockethandlers = (dispatch: Function, socket: any) => {
  socket.on('pbtx.error', (message) => {
    const { text } = message;
    dispatch(
      promptToaster({
        type: 'error',
        title: 'Error',
        message: text,
      }),
    );
  });

  socket.on('pbtx.success', () => {
    dispatch(
      promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Payment batch successfully posted.',
      }),
    );
  });
};

export default paymentBatchSockethandlers;
