import { defineMessages } from 'react-intl';

const messages = defineMessages({
  phone: {
    id: 'App.ResidentProfile.ProfileDetails.Phone',
    defaultMessage: 'Phone',
  },
  email: {
    id: 'App.ResidentProfile.ProfileDetails.Email',
    defaultMessage: 'Email',
  },
  currentResident: {
    id: 'App.ResidentProfile.ProfileDetails.CurrentResident',
    defaultMessage: 'Current Resident',
  },
  unitNumber: {
    id: 'App.ResidentProfile.ProfileDetails.Unit',
    defaultMessage: 'Unit Number',
  },
  priorResident: {
    id: 'App.ResidentProfile.ProfileDetails.PriorResident',
    defaultMessage: 'Prior Resident',
  },
  currentTenant: {
    id: 'App.ResidentProfile.ProfileDetails.CurrentTenant',
    defaultMessage: 'Current Tenant',
  },
  dba: {
    id: 'App.ResidentProfile.ProfileDetails.dba',
    defaultMessage: 'DBA',
  },
  priorTenant: {
    id: 'App.ResidentProfile.ProfileDetails.PriorTenant',
    defaultMessage: 'Prior Tenant',
  },
});

export default messages;
