import React from 'react';
import { gt, cond, always, T, isEmpty, identity, isNil } from 'ramda';

type Props = {
  data: string,
  maxLength: number
};

export const getTrimmedNotes = (notes: string, maxLength: number) => {
  const getLength = (notes) => (notes && notes.length ? notes.length : 0);
  const needsTrim = (notes) => gt(getLength(notes), maxLength);
  const trim = (notes) =>
    cond([
      [isEmpty || isNil, always('---')],
      [needsTrim, (notes) => notes.slice(0, maxLength).concat('...')],
      [T, identity]
    ])(notes);
  return trim(notes);
};
const TextWithMaxLengthOrDashes = ({ data, maxLength }: Props) => <div>{getTrimmedNotes(data, maxLength)}</div>;

export default TextWithMaxLengthOrDashes;
