import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import EditFees from '../EditFees';

const FloorPlanPricing = ({
  id,
  deposit,
  allowances,
  isAffordable,
  isAffordableMixedProperty,
  isHUDFloorPlan,
  isRDFloorPlan,
  isLIHTCFloorPlan,
  isAffordableMixedLIHTC,
  isSection236,
  rdRents,
  intl,
}: Object) => {
  return (
    <div className="col-xs-12 col-md-7" data-test="floorplan-pricing">
      <div className="panel unit-information panel-withheader">
        <div className="panel-head">
          <h2>
            <FormattedMessage {...messages.FloorplanPricingInformation} />
          </h2>
        </div>
        <div className="container-fluid">
          <EditFees
            id={id}
            allowances={allowances}
            isAffordable={isAffordable}
            isAffordableMixedProperty={isAffordableMixedProperty}
            isHUDFloorPlan={isHUDFloorPlan}
            isRDFloorPlan={isRDFloorPlan}
            isAffordableMixedLIHTC={isAffordableMixedLIHTC}
            isLIHTCFloorPlan={isLIHTCFloorPlan}
            rdRents={rdRents}
            isSection236={isSection236}
            intl={intl}
          />
        </div>
      </div>
    </div>
  );
};

export default FloorPlanPricing;
