import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import AffordableQualificationDocumentService from '../../services/affordableQualificationDocumentService';
import KpiService from '../../services/kpiService';

import { useQuery } from 'react-query';
import { COMPLIANCE_TYPES } from './constants';
import { queryBasedOnComplianceType } from './utils';

export const useFetchComplianceOverview = ({
  organizationId,
  complianceType,
}: {
  organizationId: string,
  complianceType: string,
}) => {
  const { deleteDocumentsFeature, complianceOverviewReviewedAndNumberOfDays } =
    useFlags();
  let compType = complianceType;
  if (!complianceOverviewReviewedAndNumberOfDays) {
    compType = COMPLIANCE_TYPES.NEEDS_REVIEW;
  }
  const affordableQualificationDocumentService = useMemo(() => {
    return new AffordableQualificationDocumentService();
  }, []);
  const kpiService = useMemo(() => {
    return new KpiService();
  }, []);

  return useQuery(
    ['ComplianceOverviewData', compType],
    () =>
      queryBasedOnComplianceType(
        compType,
        organizationId,
        affordableQualificationDocumentService,
        kpiService,
      ),
    { enabled: deleteDocumentsFeature, staleTime: 60000 },
  );
};
