import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FormSection, formValueSelector } from 'redux-form';
import {
  renderCheckboxField,
  renderDateField,
  validDate,
} from '../../../utils/redux-form-helper';
import SubmitButton from '../../../components/SubmitButton';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';
import {
  filterRenewalLeasesStatus,
  filtersExpirationLeasesRanges,
} from './filterConfigurations';
import classNames from 'classnames';
import validate from './validate';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  onSubmit: Function,
  hide: Function,
  show: boolean,
  change: Function,
  handleSubmit: Function,
  isFetching: boolean,
  intl: Object,
  formValues: Object,
  invalid: boolean,
};

const ExpiredLeasesFormFilter = (props: Props) => {
  const {
    change,
    onSubmit,
    handleSubmit,
    hide,
    show,
    isFetching,
    intl,
    formValues,
    invalid,
  } = props;
  const { currentMonthRenewalsKpiDrillDown } = useFlags();

  const clearFilters = () => {
    change('LeaseEndDateRangeSection', { from: '', to: '' });
    change('ExpirationSection', {});
    change('RenewalStatusSection', {});
  };

  const disableLeaseRange = filtersExpirationLeasesRanges.some(
    (range) => formValues[range.name] === true,
  );
  const disableLeaseExpiration = !!formValues.to || !!formValues.from;

  const isValidDate = (selectedDate: Object) => {
    const { from } = formValues;

    if (!from) {
      return true;
    }
    return moment(selectedDate).isSameOrAfter(from, 'day');
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        <nav className={classNames('drawer', { 'drawer-is-open': show })}>
          <h3>Filter by</h3>
          <a data-testid={'closeButton'} className="closebtn" onClick={hide}>
            <i className="icon et-delete-2" />
          </a>
          <div className="nav-filters">
            <FormSection
              name="LeaseEndDateRangeSection"
              style={{ marginBottom: '40px' }}
            >
              <h4>
                <FormattedMessage {...messages.rangeTitle} />
              </h4>
              <div>
                <Field
                  name="from"
                  component={renderDateField}
                  placeholder={intl.formatMessage(messages.fromPlaceholder)}
                  bsSize="lg"
                  disabled={disableLeaseRange}
                  validate={[validDate]}
                />
                <Field
                  name="to"
                  component={renderDateField}
                  placeholder={intl.formatMessage(messages.toPlaceholder)}
                  bsSize="lg"
                  disabled={disableLeaseRange}
                  validate={[validDate]}
                  isValidDate={isValidDate}
                />
              </div>
            </FormSection>
            <FormSection name="ExpirationSection">
              <h4>
                Expiration Period
                <div className="nav-filters">
                  {filtersExpirationLeasesRanges.map((range) => (
                    <Field
                      data-testid={'expirationLeasesCheckbox'}
                      key={range.name}
                      name={range.name}
                      component={renderCheckboxField}
                      label={range.text}
                      disabled={disableLeaseExpiration}
                    />
                  ))}
                </div>
              </h4>
            </FormSection>
            <FormSection name="RenewalStatusSection">
              <h4 className="padtop30">
                Lease Renewal Status
                <div className="nav-filters">
                  {(currentMonthRenewalsKpiDrillDown
                    ? filterRenewalLeasesStatus
                    : filterRenewalLeasesStatus.filter(
                        ({ name }) =>
                          name !== filterRenewalLeasesStatus[3].name, // onNotice
                      )
                  ).map((status) => (
                    <Field
                      data-testid={'leaseRenewalStatus'}
                      key={status.name}
                      name={status.name}
                      component={renderCheckboxField}
                      bsSize="lg"
                      label={status.text}
                    />
                  ))}
                </div>
              </h4>
            </FormSection>
          </div>
          <div className="drawer-actions">
            <button className="btn btn-default" onClick={clearFilters}>
              Clear filters
            </button>
            <SubmitButton
              classes="btn btn-primary"
              disabled={isFetching || invalid}
              clickHandler={handleSubmit(onSubmit)}
              isSubmitting={isFetching}
            >
              Apply
            </SubmitButton>
          </div>
        </nav>
      </div>
    </div>
  );
};

const selector = formValueSelector('filterForm');

const mapStateToProps = (state) => ({
  formValues: {
    to: selector(state, 'LeaseEndDateRangeSection')?.to,
    from: selector(state, 'LeaseEndDateRangeSection')?.from,
    ...filtersExpirationLeasesRanges.reduce((acc, range) => {
      acc[range.name] = selector(state, 'ExpirationSection')?.[range.name];
      return acc;
    }, {}),
  },
});

export default connect(mapStateToProps)(
  injectIntl(
    reduxForm({
      form: 'filterForm',
      validate,
      touchOnChange: true,
    })(ExpiredLeasesFormFilter),
  ),
);
