import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { pathOr } from 'ramda';
import {
  GROSS_RENT,
  HUD_PROGRAM_NAME,
  LIHTC_PROGRAM_NAME,
  CERTIFICATION_TYPES,
  OTHER_HUD,
  OTHER_LIHTC,
} from './constants';

import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

type SelectionButtonsProps = {
  certificationType: string,
  onSelectCertificationType: Function,
  selectedProperty: Object,
  flags: Object,
};

function SelectionButtons({
  certificationType,
  onSelectCertificationType,
  selectedProperty,
  flags,
}: SelectionButtonsProps) {
  const recertsActive =
    certificationType === CERTIFICATION_TYPES.RECERT ? ' active' : '';
  const moveInsActive =
    certificationType === CERTIFICATION_TYPES.MOVE_IN ? ' active' : '';
  const initialActive =
    certificationType === CERTIFICATION_TYPES.INITIAL ? ' active' : '';
  const grossRentCertsActive =
    certificationType === GROSS_RENT ? ' active' : '';
  const otherHudActive = certificationType === OTHER_HUD ? ' active' : '';
  const otherLihtcActive = certificationType === OTHER_LIHTC ? ' active' : '';

  const propertyAffordablePrograms = pathOr([], ['pap'], selectedProperty);
  const hasHUD = !!propertyAffordablePrograms.find(
    ({ masterAffordableProgram }) =>
      pathOr(null, ['name'], masterAffordableProgram) === HUD_PROGRAM_NAME,
  );
  const hasLIHTC = (propertyAffordablePrograms ?? [])
    .map((pap) => pap?.masterAffordableProgram?.name)
    .includes(LIHTC_PROGRAM_NAME);

  const {
    manageCertificationsMovein = false,
    manageCertsInitialCerts = false,
    manageCertsOtherHud = false,
    iliInterimCert = false,
  } = flags ?? {};

  return (
    <ButtonGroup data-testid={'selectionButtons'}>
      {manageCertificationsMovein && (
        <button
          id="recertsToggle"
          className={`btn btn-primary--ghost-white${moveInsActive}`}
          type="button"
          onClick={() => onSelectCertificationType(CERTIFICATION_TYPES.MOVE_IN)}
        >
          <FormattedMessage {...messages.moveIns} />
        </button>
      )}
      {manageCertsInitialCerts && (
        <button
          id="recertsToggle"
          className={`btn btn-primary--ghost-white${initialActive}`}
          type="button"
          onClick={() => onSelectCertificationType(CERTIFICATION_TYPES.INITIAL)}
        >
          <FormattedMessage {...messages.initialCerts} />
        </button>
      )}
      <button
        id="recertsToggle"
        className={`btn btn-primary--ghost-white${recertsActive}`}
        type="button"
        onClick={() => onSelectCertificationType(CERTIFICATION_TYPES.RECERT)}
      >
        <FormattedMessage {...messages.recerts} />
      </button>
      {hasLIHTC && iliInterimCert && (
        <button
          id="ohterLihtcToggle"
          className={`btn btn-primary--ghost-white${otherLihtcActive}`}
          type="button"
          onClick={() => onSelectCertificationType(OTHER_LIHTC)}
        >
          <FormattedMessage {...messages.interim} />
        </button>
      )}
      {hasHUD && [
        manageCertsOtherHud ? (
          <button
            id="recertsToggle"
            className={`btn btn-primary--ghost-white${otherHudActive}`}
            type="button"
            onClick={() => onSelectCertificationType(OTHER_HUD)}
          >
            <FormattedMessage {...messages.otherHud} />
          </button>
        ) : null,
        <button
          id="recertsToggle"
          className={`btn btn-primary--ghost-white${grossRentCertsActive}`}
          type="button"
          onClick={() => onSelectCertificationType(GROSS_RENT)}
        >
          <FormattedMessage {...messages.grCerts} />
        </button>,
      ]}
    </ButtonGroup>
  );
}

export default SelectionButtons;
