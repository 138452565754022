export const TASK_TYPE = Object.freeze({
  BASIC: 'basic',
  PENDING_FAS: 'PendingFAS',
  WORK_ORDERS: 'WorkOrders',
  PROSPECTS_FOLLOW_UP: 'ProspectsFollowUp',
});

export const STATUS = Object.freeze({
  NONE: 'none',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
});

export const ASSIGN_TYPE = Object.freeze({
  APPLICATION: 'application',
  PROSPECT: 'prospect',
});

export const CLASS_NAME = Object.freeze({
  SORT_DEFAULT: 'et-sortable sort-option',
  SORT_ASCENDING: 'et-ascending sort-option',
  SORT_DESCENDING: 'et-descending sort-option',
});
