import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import { promptToaster } from '../../../../App/actions';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Field, FieldArray } from 'redux-form';
import { pathOr } from 'ramda';
import * as hooks from './hooks';

import {
  renderCurrencyField,
  renderSimpleCheckboxField,
} from '../../../../../utils/redux-form-helper';
import { formatCurrency } from '../../../../../utils';

import EditLeaseDataLink from '../../EditLeaseDataLink';
import EditLinkWithPermissions from '../../../../../components/EditLinkWithPermissions';
import generalMessages from '../../../../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

const FeeRow = ({ idx, fee }: any) => (
  <Row key={idx}>
    <Col xs={7}>
      <label>{fee.label}</label>
    </Col>
    <Col xs={5}>
      <div className="form-group">
        {fee.value !== 0 ? (
          <FormattedNumber
            value={fee.value}
            style={{ value: 'currency' }.value}
            currency="USD"
          />
        ) : (
          fee.descriptiveValue
        )}
      </div>
    </Col>
  </Row>
);
export const LeaseMonthlyRequiredFees = ({
  monthlyRecurringCharges,
}: Object) => (
  <React.Fragment>
    {monthlyRecurringCharges.map((fee, index) => {
      return <FeeRow fee={fee} idx={index} key={index}></FeeRow>;
    })}
  </React.Fragment>
);

export const MonthlyLeaseCharges = ({
  monthlyRecurringCharges,
  intl,
  isAffordable,
  applicableRents,
  isEditable,
  marketRent,
  nonRentCharges,
  quotingRent,
  noteRent,
  basicRent,
  affordableValues,
  showEditLink,
  showLeaseDataModal,
  showLeaseReqFeesModal,
  changeFn,
  monthlyRequiredFeesEditable = true,
  isDisabledMonthly,
  lease,
  canComplete,
  promptToaster,
  selectedProperty,
  nonOptionalCharge,
  isHUD = false,
  isRD = false,
  isSection236,
  hud236BasicRent,
  hud236MarketRent,
}: Object) => {
  useEffect(() => {
    // Set quoting and market rent from either lease or floorplan values
    changeFn('marketRentValue', marketRent);
    changeFn('quotingRentValue', quotingRent);
    changeFn('nonOptionalCharge', nonOptionalCharge);
    changeFn('basicRent', basicRent);
    changeFn('noteRent', noteRent);
    if (isSection236) {
      changeFn('hud236BasicRent', hud236BasicRent);
      changeFn('hud236MarketRent', hud236MarketRent);
    }
  }, [
    changeFn,
    marketRent,
    quotingRent,
    basicRent,
    noteRent,
    nonOptionalCharge,
    isSection236,
    hud236BasicRent,
    hud236MarketRent,
  ]);
  const leaseId = pathOr('', ['id'], lease);
  const [updateReceivesHousingAssistance] =
    hooks.useUpdateReceivesHousingAssistance(
      pathOr('', ['organizationId'], selectedProperty),
      pathOr('', ['id'], selectedProperty),
      leaseId,
      promptToaster,
    );
  const { utilityAllowanceAmount, utilityAllowanceName } = affordableValues;
  const { isLocked, isMovedIn, lockedFees } = lease;
  const notCurrentMovedInLease = isLocked && !isMovedIn;
  const disableEditFeesLink =
    !canComplete || isDisabledMonthly || notCurrentMovedInLease || !lockedFees;

  const isLeaseCreated = typeof leaseId === 'string' && leaseId.length > 0;

  const handleIsReceivingAssistanceChange = (e) => {
    if (isLeaseCreated) {
      const currentValue = e.target.value === 'true';
      updateReceivesHousingAssistance(!currentValue);
    }
  };

  const nonRentChargesTooltip = (
    <Tooltip key={'tooltip-nrc'} id={'tooltip-nrc'}>
      <FormattedMessage {...messages.tooltipNRC} />
    </Tooltip>
  );

  return (
    <div>
      <Row>
        <Col xs={12}>
          <h2>
            <i className="et-money text-blue padright10" />
            <FormattedMessage {...messages.monthlyLeaseCharges} />
          </h2>
        </Col>
      </Row>
      <Col xs={7} sm={6}>
        <Row>
          <Col xs={12} sm={6}>
            <h3 className="padtop10">
              <FormattedMessage {...messages.rentCharges} />
            </h3>
          </Col>
          <form>
            {isAffordable && isRD && applicableRents?.noteRent && (
              <Row>
                <Col xs={7}>
                  <label>{intl.formatMessage(messages.noteRent)}</label>
                </Col>
                <Col xs={4}>
                  <Field
                    name="noteRent"
                    component={renderCurrencyField}
                    step="0.01"
                    disabled={true}
                  />
                </Col>
              </Row>
            )}
            {isAffordable && isRD && applicableRents?.basicRent && (
              <Row>
                <Col xs={7}>
                  <label>{intl.formatMessage(messages.basicRent)}</label>
                </Col>
                <Col xs={4}>
                  <Field
                    name="basicRent"
                    component={renderCurrencyField}
                    step="0.01"
                    disabled={true}
                  />
                </Col>
              </Row>
            )}
            {isSection236 && [
              <Row>
                <Col xs={7}>
                  <label>{intl.formatMessage(messages.hud236BasicRent)}</label>
                </Col>
                <Col xs={4}>
                  <Field
                    name="hud236BasicRent"
                    component={renderCurrencyField}
                    step="0.01"
                    disabled={true}
                  />
                </Col>
              </Row>,
              <Row>
                <Col xs={7}>
                  <label>{intl.formatMessage(messages.hud236MarketRent)}</label>
                </Col>
                <Col xs={4}>
                  <Field
                    name="hud236MarketRent"
                    component={renderCurrencyField}
                    step="0.01"
                    disabled={true}
                  />
                </Col>
              </Row>,
            ]}
            {!isSection236 && (
              <Row>
                <Col xs={7}>
                  <label>
                    {intl.formatMessage(
                      isAffordable
                        ? applicableRents?.hudGrossRent && isHUD
                          ? messages.hudGrossRentLimit
                          : messages.grossRentLimit
                        : messages.marketRentAmount,
                    )}
                  </label>
                </Col>
                <Col xs={4}>
                  <div className="form-group">
                    <Field
                      name="marketRentValue"
                      component={renderCurrencyField}
                      step="0.01"
                      disabled={true}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {utilityAllowanceName && (
              <Row>
                <Col xs={7}>
                  <label>
                    {utilityAllowanceName}{' '}
                    {intl.formatMessage(messages.utilityAllowance)}
                  </label>
                </Col>
                <Col xs={4}>
                  {!utilityAllowanceAmount ? (
                    <Spinner small />
                  ) : (
                    <div className="form-group">
                      <Field
                        name="utilityAllowanceAmount"
                        component={renderCurrencyField}
                        step="0.01"
                        disabled={true}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={7}>
                <label>{intl.formatMessage(messages.quotingRentAmount)}</label>
              </Col>
              <Col xs={4}>
                <div className="form-group">
                  <Field
                    name="quotingRentValue"
                    component={renderCurrencyField}
                    step="0.01"
                    disabled={true}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={7}>
                <label className="required">
                  {intl.formatMessage(messages.leaseRentAmount)}
                </label>
              </Col>
              <Col xs={4}>
                <div className="form-group">
                  <Field
                    name="leasedRent"
                    component={renderCurrencyField}
                    step="0.01"
                    disabled={!isEditable}
                  />
                </div>
              </Col>
              <Col xs={1}>
                <EditLeaseDataLink
                  showEditLink={showEditLink}
                  showLeaseDataModal={showLeaseDataModal}
                />
              </Col>
            </Row>
            {isAffordable && (
              <Row>
                <Col xs={7}>
                  <label>
                    {intl.formatMessage(messages.receivesHousingAssistance)}
                  </label>
                </Col>
                <Col xs={5}>
                  <Field
                    name="isReceivingAssistance"
                    component={renderSimpleCheckboxField}
                    label="Yes"
                    onChange={handleIsReceivingAssistanceChange}
                    disabled={!isEditable}
                  />
                </Col>
              </Row>
            )}
          </form>

          <label style={{ marginRight: '10px' }}>
            <FormattedMessage {...messages.requiredNonRentCharges} />
          </label>

          {isEditable && (
            <OverlayTrigger
              key={'tooltip-overlay'}
              placement="bottom"
              delayHide={50}
              trigger={['hover', 'focus']}
              overlay={nonRentChargesTooltip}
            >
              <i className="icon et-alert-info padright10" />
            </OverlayTrigger>
          )}

          {!disableEditFeesLink && (
            <EditLinkWithPermissions
              showEditLink={isEditable}
              linkFn={showLeaseReqFeesModal}
              scope={['lease-edit-fees']}
              disabled={disableEditFeesLink}
            />
          )}
          <div className="form-group form-groupbox form-groupbox--scrollable no-margin-bottom">
            <FieldArray
              name="monthlyReqFees"
              component={LeaseMonthlyRequiredFees}
              monthlyRecurringCharges={monthlyRecurringCharges}
            ></FieldArray>
          </div>
          <div className="highlighted-footer">
            <Row>
              <Col xs={7}>
                <span>
                  <FormattedMessage {...messages.totalCharges} />
                </span>
              </Col>
              <Col xs={5}>
                <span>{formatCurrency(intl, nonRentCharges)}</span>
              </Col>
            </Row>
          </div>
        </Row>
      </Col>
    </div>
  );
};

export default connect(undefined, { promptToaster })(MonthlyLeaseCharges);
