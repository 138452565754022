import React, { Component } from 'react';
import { Modal, Col, Button } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import * as R from 'ramda';
import {
  reduxForm,
  getFormInitialValues,
  getFormSyncWarnings,
} from 'redux-form';
import ResidentPortal from './ResidentPortal';
import { getSentAndExecutedStatus } from '../../utils/lease-helpers';
import GeneralCommercialInformation from '../EditOccupantModal/GeneralCommercialInformation';
import { validate } from './validate';
import ReportingInformationPanel from '../ReportingInformationPanel';
export const PERSONAL_INFORMATION_FORM = 'personalInformationForm';

const GUARANTOR_LEASE_SIGNER = 'Guarantor Lease Signer';

const EMPTY_REPORTING_VALUES = {
  specialNeedsDesignationIds: [],
  annualIncome: '0.00',
};

type ReduxFormProps = {
  anyTouched: boolean,
  onClose: Function,
  pristine: boolean,
  reset: Function,
  submit: Function,
  valid: boolean,
};

type Drawer = {
  display: boolean,
};

type ResidentPortalDrawerInfo = Drawer & {
  sendPortalInviteEmail: Function,
};

type Props = ReduxFormProps & {
  show: boolean,
  unitNumber: string,
  personalInfo: Object,
  residentPortalInfo: ?ResidentPortalDrawerInfo,
  isCommercial: boolean,
  isAffordable: boolean,
  states: Array<Object>,
  flags: Object,
  applicant: Object,
  property: Object,
  unit: Object,
  lease?: Object,
};

type State = {
  showCancelConfirmation: boolean,
  openPanel: ?string,
};

export const getSectionBypassedFields = (
  targetSection: string,
  bypassedFields: [string, string][],
) => {
  return bypassedFields.reduce(
    (sbf: string[], [sectionName, fieldName]: [string, string]) => {
      if (targetSection !== sectionName) return sbf;
      return [...sbf, fieldName];
    },
    [],
  );
};

export const getRequiredSyncWarnFields = createSelector(
  getFormSyncWarnings(PERSONAL_INFORMATION_FORM),
  (syncWarns) => {
    if (!syncWarns) return null;

    const sectionPairs = R.toPairs(syncWarns);

    const requiredSyncWarnFields = sectionPairs.reduce(
      (rse, [sectionName, warns]) => {
        const warnPairs = R.toPairs(warns);

        const requiredWarnPairs = warnPairs.filter(
          ([fieldName, warn]) => warn === 'Required',
        );

        if (requiredWarnPairs.length === 0) return rse;

        const fields = requiredWarnPairs.map(([fieldName]) => [
          sectionName,
          fieldName,
        ]);

        return [...rse, ...fields];
      },
      [],
    );

    return requiredSyncWarnFields;
  },
);

export const getBypassedRequiredFields = createSelector(
  getFormInitialValues(PERSONAL_INFORMATION_FORM),
  getRequiredSyncWarnFields,
  (initialValues, requiredSyncWarnFields) => {
    const bypassedRequiredFields = requiredSyncWarnFields.filter(
      (fieldPath) => {
        const initialValue = R.path(fieldPath, initialValues);
        const wasInitiallyEmpty =
          R.isNil(initialValue) || R.isEmpty(initialValue);
        return wasInitiallyEmpty;
      },
    );

    return bypassedRequiredFields;
  },
);
export class PersonalInformationForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showCancelConfirmation: false,
      openPanel: '',
    };
  }

  setCancelConfirmation = (visible: boolean) => {
    this.setState({
      showCancelConfirmation: visible,
    });
  };

  showConfirmation = () => this.setCancelConfirmation(true);

  hideConfirmation = () => this.setCancelConfirmation(false);

  handleClose = () => {
    const { anyTouched, onClose, reset } = this.props;
    const { showCancelConfirmation } = this.state;
    if (anyTouched && !showCancelConfirmation) {
      this.showConfirmation();
      return;
    } else if (anyTouched && showCancelConfirmation) {
      reset();
    }
    this.hideConfirmation();
    if (onClose) {
      this.setState({ openPanel: null });
      onClose();
    }
  };

  submit = (evt: Object) => {
    evt.preventDefault();
    const { onClose } = this.props;
    const { submit } = this.props;
    this.setState({ openPanel: null });
    submit(PERSONAL_INFORMATION_FORM);
    onClose();
  };

  renderCancelConfirmation = () => {
    return (
      <div className="modal-confirm">
        <h1>Are you sure you want to leave without saving?</h1>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="primary"
            className="pull-right"
            onClick={this.handleClose}
          >
            Yes
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="default"
            className="pull-left"
            onClick={this.hideConfirmation}
          >
            No
          </Button>
        </Col>
      </div>
    );
  };

  togglePanel = (panelName: string) => () => {
    if (this.state.openPanel === panelName) {
      this.setState({ openPanel: null });
    } else {
      this.setState({ openPanel: panelName });
    }
  };

  render() {
    const {
      show,
      personalInfo,
      isCommercial,
      unit,
      states,
      lease,
      pristine,
      valid,
      flags,
      applicant,
      property,
      initialValues,
      residentPortalInfo,
      onClose,
      bypassedRequiredFields,
    } = this.props;

    const { name, unitNumber } = personalInfo;

    const { leaseExecuted, leaseSentToPortal } = getSentAndExecutedStatus(
      lease || {},
    );

    const specialNeedsAffordableChange =
      flags?.specialNeedsAffordableChange ?? false;
    const showSaveButton = isCommercial || specialNeedsAffordableChange;
    const isFinanciallyResponsibleAdult =
      applicant?.applicantType?.financiallyResponsible ?? false;
    const isGuarantor =
      applicant?.applicantType?.name === GUARANTOR_LEASE_SIGNER;

    return (
      <Modal
        className="transaction"
        bsSize="small"
        show={show}
        autoFocus
        onHide={this.handleClose}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <h1>{name}</h1>
          {unitNumber && (
            <p>
              <i className="icon et-home"> </i>
              <strong>Unit Number: </strong>
              {unitNumber}
            </p>
          )}
        </Modal.Header>
        <Modal.Body className="no-padding">
          {this.state.showCancelConfirmation && this.renderCancelConfirmation()}
          <div
            className="clearfix"
            style={this.state.showCancelConfirmation ? { display: 'none' } : {}}
          >
            <div className="accordion accordion-applicationmanager panel-group">
              {isCommercial && (
                <GeneralCommercialInformation
                  openPanel={this.state.openPanel}
                  togglePanel={this.togglePanel}
                  leaseExecuted={leaseExecuted}
                  leaseSentToPortal={leaseSentToPortal}
                  states={states}
                  sectionInitialValues={initialValues.generalInformation}
                  bypassedFields={getSectionBypassedFields(
                    'generalInformation',
                    bypassedRequiredFields,
                  )}
                />
              )}

              <ResidentPortal
                isCommercial={isCommercial}
                specialNeedsAffordableChange={specialNeedsAffordableChange}
                isGuarantor={isGuarantor}
                onClose={onClose}
                openPanel={this.state.openPanel}
                personalInfo={personalInfo}
                residentPortalInfo={residentPortalInfo}
                togglePanel={this.togglePanel}
              />

              {specialNeedsAffordableChange && !isGuarantor && (
                <ReportingInformationPanel
                  openPanel={this.state.openPanel}
                  togglePanel={this.togglePanel}
                  changeFunc={this.props.change}
                  property={property}
                  unit={unit}
                  initialValues={
                    initialValues?.reportingInformation ??
                    EMPTY_REPORTING_VALUES
                  }
                  isFinanciallyResponsibleAdult={isFinanciallyResponsibleAdult}
                  customerType="Applicant"
                />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          className={this.state.showCancelConfirmation ? 'disabled ' : ''}
        >
          <div className="row">
            {!showSaveButton && <div className="col-xs-3" />}
            <div className={`col-xs-6 ${showSaveButton ? '' : 'text-center'}`}>
              <button className="btn btn-default" onClick={this.handleClose}>
                {showSaveButton ? 'Cancel' : 'Close'}
              </button>
            </div>
            {showSaveButton && (
              <div className="col-xs-6">
                <button
                  className="btn btn-primary pull-left"
                  onClick={this.submit}
                  disabled={pristine || !valid}
                >
                  Save
                </button>
              </div>
            )}
          </div>
          {!valid && (
            <div className="row">
              <div className="col-xs-12 text-center">
                <small className="text-gray--darker">
                  <span>
                    Must fill out the required fields of all sections to save.
                  </span>
                </small>
              </div>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bypassedRequiredFields: getBypassedRequiredFields(state),
  };
};

export default connect(mapStateToProps)(
  injectIntl(
    reduxForm({
      form: PERSONAL_INFORMATION_FORM,
      enableReinitialize: true,
      validate,
    })(PersonalInformationForm),
  ),
);
