import React from 'react';

import type { SetAsideSummaryComponentProps } from '../types';
import messages from './messages';
import { SummaryTableGrid } from './styled';

type KPI = {
  numRequiredUnits?: number,
  numActualUnits?: number,
  numLeasedUnits?: number,
};
type Totals = { required: number, actual: number, leased: number };

export const calculateTotals = (kpi: KPI[]): Totals =>
  kpi.reduce(
    (counts: Totals, currentKpi: KPI) => {
      const currentRequired = currentKpi?.numRequiredUnits ?? 0;
      const currentActual = currentKpi?.numActualUnits ?? 0;
      const currentLeased = currentKpi?.numLeasedUnits ?? 0;

      return {
        ...counts,
        required: counts.required + currentRequired,
        actual: counts.actual + currentActual,
        leased: counts.leased + currentLeased,
      };
    },
    {
      required: 0,
      actual: 0,
      leased: 0,
    },
  );

const TotalsRow = ({
  intl,
  kpi,
  setAsideKpiLeasedUnits,
}: SetAsideSummaryComponentProps) => {
  const totals = React.useMemo(() => calculateTotals(kpi), [kpi]);

  return (
    <SummaryTableGrid
      className="lead"
      setAsideKpiLeasedUnits={setAsideKpiLeasedUnits}
      role="row"
    >
      <div>{intl.formatMessage({ ...messages.total })}</div>
      <div>{totals.required}</div>
      <div>{totals.actual}</div>
      {setAsideKpiLeasedUnits && <div>{totals.leased}</div>}
    </SummaryTableGrid>
  );
};

export default TotalsRow;
