export const GET_ALL_PRIOR_RESIDENTS =
  'app/ManagePriorResidents/GET_ALL_PRIOR_RESIDENTS';
export const GET_ALL_PRIOR_RESIDENTS_SUCCESS =
  'app/ManagePriorResidents/GET_ALL_PRIOR_RESIDENTS_SUCCESS';
export const GET_ALL_PRIOR_RESIDENTS_ERROR =
  'app/ManagePriorResidents/GET_ALL_PRIOR_RESIDENTS_ERROR';

export const UPDATE_COLUMNS_SORT_VALUE =
  'app/ManagePriorResidents/UPDATE_COLUMNS_SORT_VALUE';
export const CLEAN_LOADED_PRIOR_RESIDENTS =
  'app/ManagePriorResidents/CLEAN_LOADED_PRIOR_RESIDENTS';
