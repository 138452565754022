/* eslint-disable max-len */

import { get } from '../utils/api';

export default class HouseholdService {
  getHousehold(
    organizationId: string,
    propertyId: string,
    householdId: string,
    options?: Object,
  ): any {
    return get(
      `/${organizationId}/${propertyId}/households/${householdId}`,
      options,
    );
  }

  getPrimaryApplicant(
    organizationId: string,
    propertyId: string,
    householdId: string,
    options?: Object,
  ): any {
    return get(
      `/${organizationId}/${propertyId}/households/${householdId}/applicants/primary`,
      options,
    );
  }

  checkIfApplicantIsPrimary(
    organizationId: string,
    propertyId: string,
    householdId: string,
    applicantId: string,
    options?: Object,
  ): any {
    return get(
      `/${organizationId}/${propertyId}/households/${householdId}/applicants/${applicantId}/is-primary`,
      options,
    );
  }

  getAllHouseholds(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ): any {
    return get(`/${organizationId}/${propertyId}/households`, options);
  }

  getHouseholdUserFriendlyId(householdId) {
    return get(`/households/${householdId}/user-friendly-id`);
  }
}
