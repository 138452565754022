// $FlowFixMe
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  FormSection,
  SubmissionError,
  Field,
} from 'redux-form';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { find, keys, pathOr, partial, pipe, prop, propEq, omit } from 'ramda';
import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';

/* eslint-disable max-len */
import StudentInformationSection from '../../../components/LeaseAffordableApplicantFormCommonSections/StudentInformationSection';
import EmploymentInformationSection from '../../../components/LeaseApplicantFormCommonSections/EmploymentInformationSection';
import ApplicantsEmployerSection from '../../../components/LeaseApplicantFormCommonSections/ApplicantsEmployerSection';
import SignatureSection from '../../../components/LeaseApplicantFormCommonSections/SignatureSection';
import { studentsTypes } from '../../PrimaryLeaseApplication/AffordablePrimaryForm/configuration';
import {
  yesNoBooleanOptions,
  numbers0To10Options,
} from '../../../components/LeaseAffordableApplicantFormCommonSections/configuration';
/* eslint-enable max-len */
import confirm from '../../../components/ConfirmDialogModal';
import { FORM_NAME } from '../constants';
import messages from './messages';
import validateComplete from './validateComplete';
import validateProgress from './validateProgress';
import {
  renderRadioGroupField,
  renderSelectField,
} from '../../../utils/redux-form-helper';
import { DEFAULT_EMPTY_EMPLOYER_INFO } from '../constants';

import type { GlobalState, SelectOption } from '../../App/types';
import { useCheckPrimaryApplicant } from './hooks';
import { promptToaster } from '../../App/actions';

type Props = {
  actions: Object,
  intl: Object,
  change: Function,
  applicantId: string,
  householdId: string,
  employmentStatuses: Array<SelectOption>,
  states: Array<SelectOption>,
  onSubmit: Function,
  handleSubmit: Function,
  handleCancel: Function,
  resetSection: Function,
  employmentInformation: Object,
  anticipateChangeHousehold: string,
  isComplete: boolean,
  handlePrintRecertApplicationForm: Function,
  isInitialCertForNonHUD: boolean,
  isFormDisabled: boolean,
  initialValues: Object,
};

type StateProps = {
  isLoading: boolean,
  selectedProperty: Object,
};

type ConnectedMethods = {
  actions: {
    promptToaster: Function,
  },
};

const NoBottomMarginH3 = styled.h3`
  margin-bottom: 0;
`;

const RecertificationApplicationForm = (props: Props & StateProps) => {
  const {
    actions,
    intl,
    change,
    applicantId,
    householdId,
    selectedProperty,
    employmentStatuses,
    states,
    onSubmit,
    handleSubmit,
    handleCancel,
    resetSection,
    employmentInformation,
    anticipateChangeHousehold,
    isComplete,
    handlePrintRecertApplicationForm,
    isInitialCertForNonHUD,
    isFormDisabled,
    initialValues,
  } = props;

  const organizationId = pathOr('', ['organizationId'], selectedProperty);
  const propertyId = pathOr('', ['id'], selectedProperty);

  const employmentStatusId = pathOr(null, ['status'], employmentInformation);
  const notEmployedId = pipe(
    find(propEq('text', 'Not Employed')),
    // $FlowFixMe
    prop('value'),
  )(employmentStatuses);

  const unemployed = notEmployedId === employmentStatusId;
  const propertyName = pathOr('', ['name'], selectedProperty);

  const [isSubmitting, setSubmitting] = useState(false);

  const { isLoading, isPrimary } = useCheckPrimaryApplicant(
    organizationId,
    propertyId,
    householdId,
    applicantId,
    actions.promptToaster,
  );

  const handleChangeHousehold = ({ target: { value } }: any) => {
    if (value === 'no') {
      change(
        'changeHouseholdInformation.anticipateChangeHouseholdPregnancy',
        0,
      );
      change('changeHouseholdInformation.anticipateChangeHouseholdAdoption', 0);
      change('changeHouseholdInformation.anticipateChangeHouseholdFoster', 0);
    }
  };

  const saveProgress = (props: Props, setSubmitting: Function, values: any) => {
    setSubmitting(true);
    const newProps = {
      ...props,
      studentsTypes,
    };

    /**
     * changeHouseholdInformation is only required for Primary Members
     */
    let filteredValues = values;
    if (isPrimary === false) {
      filteredValues = omit(['changeHouseholdInformation'], values);
    }

    const progressErrors = validateProgress(filteredValues, newProps);
    if (keys(progressErrors).length > 0) {
      setSubmitting(false);
      return new Promise(() => {
        throw new SubmissionError(progressErrors);
      });
    }
    onSubmit({
      ...filteredValues,
      isComplete: false,
    });
  };

  const confirmComplete = (
    props: Props,
    setSubmitting: Function,
    values: any,
  ) => {
    const newProps = {
      ...props,
      studentsTypes,
    };

    /**
     * changeHouseholdInformation is only required for Primary Members
     */
    let filteredValues = values;
    if (isPrimary === false) {
      filteredValues = omit(['changeHouseholdInformation'], values);
    }

    const completeErrors = validateComplete(filteredValues, newProps);
    if (keys(completeErrors).length > 0) {
      return new Promise(() => {
        throw new SubmissionError(completeErrors);
      });
    }
    confirm(
      props.intl.formatMessage(
        isInitialCertForNonHUD
          ? messages.miniApplicationCompleteConfirmation
          : messages.recertCompleteConfirmation,
      ),
      {
        intl: props.intl,
      },
    )
      .then(() => {
        setSubmitting(true);
        props.onSubmit({
          ...filteredValues,
          isComplete: true,
        });
      })
      .catch(() => {});
  };

  const setUnemployed = () => {
    change('applicantsCurrentEmployer', DEFAULT_EMPTY_EMPLOYER_INFO);
    change('applicantsSecondCurrentEmployer', DEFAULT_EMPTY_EMPLOYER_INFO);
  };

  const resetEmploymentInfo = () => {
    resetSection('applicantsCurrentEmployer');
    resetSection('applicantsSecondCurrentEmployer');
  };
  /**
   * Hold on rendering the form until we know if the applicant is the
   * primary household member or not.
   */
  if (isLoading) {
    return null;
  }

  return (
    <div className="section-leaseappform">
      <fieldset disabled={isFormDisabled}>
        <div className="leaseform-header">
          <div className="row">
            <div className="col-xs-10 col-md-11 col-lg-11">
              <h1>Lease Application for {propertyName}</h1>
            </div>
            <div className="col-xs-2 col-md-1 col-lg-1 text-right">
              <a
                className="btn btn-tertiary btn-print no-print"
                title="Print this form"
                style={{ fontSize: '1.8em' }}
                onClick={handlePrintRecertApplicationForm}
              >
                <i className="icon et-print" />
              </a>
            </div>
          </div>
          <div className="divider" />
          <span>*Required Fields</span>
        </div>
        {isPrimary === true && (
          <React.Fragment>
            <div className="block-heading">
              <h2>Household Information</h2>
            </div>
            <FormSection name="changeHouseholdInformation">
              <div className="form-block">
                <Row>
                  <Col xs={12} md={8}>
                    <NoBottomMarginH3>
                      Do you anticipate having a change in household occupants
                      in the next 12 months?
                    </NoBottomMarginH3>
                    If "yes", how many additional occupants are expected due to:
                  </Col>
                  <Col xs={12} md={4}>
                    <Field
                      name="anticipateChangeHousehold"
                      fieldName="anticipateChangeHousehold"
                      component={renderRadioGroupField}
                      options={yesNoBooleanOptions}
                      classes={classNames({ required: true })}
                      onChange={handleChangeHousehold}
                      inline
                    />
                  </Col>
                </Row>
                <Row className="link-availability">
                  <Col xs={12} md={6}>
                    <Col xs={12} md={4}>
                      <Field
                        name={'anticipateChangeHouseholdPregnancy'}
                        component={renderSelectField}
                        options={numbers0To10Options}
                        bsSize="lg"
                        className="head-selector"
                        classes={classNames({ required: true })}
                        label="Pregnancy?"
                        disabled={anticipateChangeHousehold === 'no'}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Field
                        name={'anticipateChangeHouseholdAdoption'}
                        component={renderSelectField}
                        options={numbers0To10Options}
                        bsSize="lg"
                        className="head-selector"
                        classes={classNames({ required: true })}
                        label="Adoption?"
                        disabled={anticipateChangeHousehold === 'no'}
                      />
                    </Col>
                    <Col xs={12} md={4}>
                      <Field
                        name={'anticipateChangeHouseholdFoster'}
                        component={renderSelectField}
                        options={numbers0To10Options}
                        bsSize="lg"
                        className="head-selector"
                        classes={classNames({ required: true })}
                        label="Foster Child(ren)?"
                        disabled={anticipateChangeHousehold === 'no'}
                      />
                    </Col>
                  </Col>
                </Row>
              </div>
            </FormSection>
          </React.Fragment>
        )}
        <div className="block-heading">
          <h2>Student Information</h2>
        </div>
        <FormSection name="studentInformation">
          <StudentInformationSection
            intl={intl}
            studentsTypes={studentsTypes}
            changeCallback={change}
            initialValues={initialValues?.studentInformation ?? {}}
          />
        </FormSection>
        <div className="block-heading">
          <h2>Employment Information</h2>
        </div>
        <FormSection name="employmentInformation">
          <EmploymentInformationSection
            intl={intl}
            employmentStatuses={employmentStatuses}
            setUnemployed={setUnemployed}
            resetEmploymentInfo={resetEmploymentInfo}
          />
        </FormSection>
        <FormSection name="applicantsCurrentEmployer">
          <ApplicantsEmployerSection
            title={messages.applicantsCurrentEmployerTitle}
            intl={intl}
            states={states}
            streetLabel={messages.streetAddressLabel}
            isCurrentEmployer={true}
            fromLabel={messages.startDate}
            disabled={unemployed}
            required
          />
        </FormSection>
        <FormSection name="applicantsSecondCurrentEmployer">
          <ApplicantsEmployerSection
            title={messages.applicantsSecondCurrentEmployerTitle}
            intl={intl}
            states={states}
            streetLabel={messages.streetAddressLabel}
            isCurrentEmployer={true}
            fromLabel={messages.startDate}
            disabled={unemployed}
          />
        </FormSection>
        <FormSection name="employmentInformation">
          <div className="form-content">
            <div className="form-block">
              <Row>
                <Col xs={12} md={8}>
                  <h3>
                    Do you anticipate a change with your current employer
                    situation?
                  </h3>
                  <p>
                    {' '}
                    <strong> Note: </strong> You must inform us if your
                    employment situation changes prior to the new lease start
                    date.
                  </p>
                </Col>
                <Col xs={12} md={4}>
                  <Field
                    name="anticipateChangeEmployer"
                    fieldName="anticipateChangeEmployer"
                    component={renderRadioGroupField}
                    options={[
                      { text: 'No', value: 'no', disabled: false },
                      { text: 'Yes', value: 'yes', disabled: false },
                    ]}
                    classes={classNames({ required: true })}
                    inline
                  />
                </Col>
              </Row>
            </div>
          </div>
        </FormSection>
        <FormSection name="applicantsPreviousEmployer">
          <ApplicantsEmployerSection
            title={messages.applicantsPreviousEmployerTitle}
            intl={intl}
            states={states}
            streetLabel={messages.streetAddressLabel}
            isCurrentEmployer={false}
            fromLabel={messages.from}
          />
        </FormSection>
      </fieldset>
      <div className="block-heading">
        <h2>Signature</h2>
      </div>
      <FormSection name="signature">
        <SignatureSection
          intl={intl}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit(
            partial(saveProgress, [props, setSubmitting]),
          )}
          confirmSubmit={handleSubmit(
            partial(confirmComplete, [props, setSubmitting]),
          )}
          isSubmitting={isSubmitting}
          isComplete={isComplete}
          isFormDisabled={isFormDisabled}
        />
      </FormSection>
    </div>
  );
};

export const mapStateToProps = (state: GlobalState): Object => {
  const selector = formValueSelector(FORM_NAME);
  const {
    form,
    app: { selectedProperty },
  } = state;

  return {
    selectedProperty,
    employmentInformation: selector({ form }, 'employmentInformation'),
    anticipateChangeHousehold: selector(
      { form },
      'changeHouseholdInformation.anticipateChangeHousehold',
    ),
  };
};

export const mapDispatchToProps = (dispatch: Object): ConnectedMethods => ({
  actions: bindActionCreators({ promptToaster }, dispatch),
});

const InjectedRecertificationApplicationForm = injectIntl(
  RecertificationApplicationForm,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  })(InjectedRecertificationApplicationForm),
);
