import { pathOr } from 'ramda';
import moment from 'moment';

const parseGrossRentEdits = (values: Object) => {
  const { grossRentEdits } = values;
  const rawAnticipatedVoucherDate = pathOr(
    null,
    ['anticipatedVoucherDate'],
    grossRentEdits,
  );
  const anticipatedVoucherDate = moment(rawAnticipatedVoucherDate);
  return {
    ...grossRentEdits,
    anticipatedVoucherDate,
  };
};

const parseCorrection = (values: Object) => {
  const { correction } = values;
  const rawEIVIndicator = pathOr(null, ['eivIndicator'], correction);
  if (!rawEIVIndicator) {
    return null;
  }

  const eivIndicator = rawEIVIndicator === 'Yes';
  return { ...correction, eivIndicator };
};

const parsePrevCert = (values: Object) => {
  const { prevMissingCert } = values;
  if (!prevMissingCert) {
    return null;
  }
  const rawPriorCertificationDate = pathOr(
    null,
    ['priorCertificationDate'],
    prevMissingCert,
  );
  const priorCertificationDate = moment(rawPriorCertificationDate);

  const yesNoQualifyForRentOverride = pathOr(
    null,
    ['qualifyForRentOverride'],
    prevMissingCert,
  );
  const qualifyForRentOverride = yesNoQualifyForRentOverride
    ? yesNoQualifyForRentOverride === 'yes'
    : null;
  return {
    ...prevMissingCert,
    priorCertificationDate,
    qualifyForRentOverride,
  };
};

export const parseSubmitValues = (values: Object) => {
  const grossRentEdits = parseGrossRentEdits(values);
  const correction = parseCorrection(values);
  const prevMissingCert = parsePrevCert(values);
  return {
    grossRentEdits,
    correction,
    prevMissingCert,
  };
};
