import React from 'react';
import ColumnHeader from '../../ColumnHeader';
import { ProRatedDetails } from '../ProRatedDetails';
import messages from './messages';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  monthlyTransactions: Array<Object>,
  moveInApplyFullAmountToProRate: boolean,
  intl: Function,
};

export const ProRatedAmounts = ({
  monthlyTransactions,
  intl,
  moveInApplyFullAmountToProRate,
  roundProratedRents,
}: Props): any => {
  const { correctProrateOnMoveinScreen } = useFlags();
  const proRatedColumnLabel =
    intl.formatMessage(
      correctProrateOnMoveinScreen
        ? messages.moveInAmount
        : messages.moveOutCredit,
      {
        type: moveInApplyFullAmountToProRate
          ? intl.formatMessage(messages.full)
          : intl.formatMessage(messages.proRated),
      },
    ) + (roundProratedRents ? '*' : '');
  return (
    <table className="table table-prospects table-striped">
      <thead className="table-header">
        <tr>
          <ColumnHeader
            columnLabel={intl.formatMessage(messages.transactionCode)}
          />
          <ColumnHeader
            columnLabel={intl.formatMessage(messages.description)}
          />
          <ColumnHeader
            columnLabel={intl.formatMessage(messages.transactionType)}
          />
          <ColumnHeader
            columnLabel={intl.formatMessage(messages.monthlyRecurringAmount)}
          />
          <ColumnHeader columnLabel={proRatedColumnLabel} darker={true} />
        </tr>
      </thead>
      <tbody>
        {monthlyTransactions &&
          monthlyTransactions.map((monthlyTransaction, index) => (
            <ProRatedDetails
              monthlyTransaction={monthlyTransaction}
              key={index}
              roundProratedRents={roundProratedRents}
            />
          ))}
      </tbody>
    </table>
  );
};

export default ProRatedAmounts;
