import { FormattedMessage } from 'react-intl';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { grey } from '@fortress-technology-solutions/fortress-component-library/design';
import messages from './messages';

const Notes = () => {
  return (
    <>
      <Box sx={{ marginTop: 4, maxWidth: 650 }}>
        <Typography variant={'labelMedium'} color={grey.light}>
          <FormattedMessage {...messages.notes} tagName="p" />
        </Typography>
        <Box component={'ol'} sx={{ paddingLeft: '20px' }}>
          <Typography component={'li'} variant={'bodySmall'} color={grey.light}>
            <FormattedMessage {...messages.note1} tagName="p" />
          </Typography>
          <Typography component={'li'} variant={'bodySmall'} color={grey.light}>
            <FormattedMessage {...messages.note2} tagName="p" />
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Notes;
