// $FlowFixMe
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import confirm from '../../../../../../components/ConfirmDialogModal';
import ElementWithPermissions from '../../../../../../components/ElementWithPermissions';

import { editLease } from '../../../../actions';

import UploadLeaseModal from '../ButtonActionModals/UploadLeaseModal';
import messages from './messages';

type ManualMethodButtonsProps = {
  allAffordableTabsApproved: boolean,
  applicationId: string,
  canComplete: boolean,
  difference: number,
  isResident: boolean,
  leaseId: string,
  onGenerate?: Function,
  profileId: string,
};

type ManualMethodButtonsInjectedProps = {
  actions: Object,
  intl: Object,
};

export const ManualMethodButtons = ({
  actions,
  allAffordableTabsApproved,
  applicationId,
  canComplete,
  difference,
  intl,
  isResident,
  leaseId,
  onGenerate,
  profileId,
  selectedProperty,
}: ManualMethodButtonsProps & ManualMethodButtonsInjectedProps) => {
  const [isEditLeaseMode, setIsEditLeaseMode] = useState(false);

  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleShowUploadClick = () => setShowUploadModal(true);
  const handleHideUploadClick = () => setShowUploadModal(false);

  const confirmEditDialog = () => {
    confirm(intl.formatMessage(messages.confirmEditLease)).then(() => {
      actions.editLease(
        leaseId,
        profileId,
        isResident,
        undefined,
        undefined,
        undefined,
        setIsEditLeaseMode,
      );
    });
  };

  const { automateMonthlyLeaseTransactionsMvp: automateMLTsFlag } = useFlags();
  const notReconciled = automateMLTsFlag ? false : difference !== 0;

  return (
    <>
      <UploadLeaseModal
        applicationId={applicationId}
        isResident={isResident}
        leaseId={leaseId}
        onHide={handleHideUploadClick}
        profileId={profileId}
        show={showUploadModal}
      />
      <ElementWithPermissions scope={['lease-create']}>
        <Button
          className="btn btn-tertiary"
          onClick={confirmEditDialog}
          disabled={isEditLeaseMode}
          data-testid={'editButton'}
        >
          {isEditLeaseMode ? (
            <Spinner small />
          ) : (
            intl.formatMessage(messages.edit)
          )}
        </Button>
        {!isEditLeaseMode && (
          <Button
            className="btn btn-shout"
            onClick={handleShowUploadClick}
            disabled={
              !canComplete || notReconciled || !allAffordableTabsApproved
            }
            data-testid={'uploadSignedLeaseButton'}
          >
            {intl.formatMessage(messages.uploadSignedLease)}
          </Button>
        )}
        {onGenerate && !isEditLeaseMode && (
          <Button
            className="btn btn-primary"
            disabled={
              !canComplete || notReconciled || !allAffordableTabsApproved
            }
            onClick={onGenerate}
          >
            {intl.formatMessage(messages.downloadLease)}
          </Button>
        )}
      </ElementWithPermissions>
      {!allAffordableTabsApproved && (
        <div className="text-center" style={{ marginRight: '3%' }}>
          {intl.formatMessage(messages.notApproved)}
        </div>
      )}
      {notReconciled && (
        <div className="text-center" style={{ marginRight: '3%' }}>
          {intl.formatMessage(messages.notReconciled)}
        </div>
      )}
    </>
  );
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators({ editLease }, dispatch);
  return { actions };
}

const InjectedManualMethodButtons = injectIntl(ManualMethodButtons);

export default connect(null, mapDispatchToProps)(InjectedManualMethodButtons);
