import styled from 'styled-components';

export const FlexFormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  width: ${(props) => (props.addpadding ? '90%' : '100%')};
`;
export const FlexFormInput = styled.div`
  flex-grow: 1;
  width: 33%;
  max-width: 33%;
  padding: 0 10px;
`;
export const FlexFormInputFull = styled.div`
  flex-grow: 1;
  width: 100%;
  padding: 0 10px;
`;
export const FlexFormInputHalf = styled.div`
  flex-grow: 1;
  width: 50%;
  max-width: 50%;
  padding: 0 10px;
`;
export const FlexFormInputQuarter = styled.div`
  flex-grow: 1;
  width: 25%;
  max-width: 25%;
  padding: 0 10px;
  border-left: ${(props) => (props.addsideborder ? '0' : '1px solid black')};
`;
export const TextButtonRightContainer = styled.div`
  text-align: right;
  width: 100%;
  padding: 0 10px 0 0;
`;
export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const FlexFormInputGrow = styled.div`
  flex-grow: ${(props) => props.size};
  padding: 0 10px;
`;
