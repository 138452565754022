import type { Highlight, OpenWorkOrdersEntity } from '../types';
import moment from 'moment';
import { STATUS, TASK_TYPE } from '../constants';
import { getHighlightColor } from './general/getHighlightColor';
import messages from '../messages';

type OpenWorkOrderArgs = {
  openWorkOrders: OpenWorkOrdersEntity[],
  workOrderDueDateTaskTabFlag: boolean,
};

type OpenWorkOrderHeader = {
  id: number,
  text: Object,
  sortKey: 'unit' | 'issue' | 'priority' | 'daysOpen' | 'dueDate',
  initialSortDirection?: string,
};

type OpenWorkOrderHeaderArgs = {
  intl: Object,
  workOrderDueDateTaskTabFlag: boolean,
};

type MappedOpenWorkOrders = {
  id: string,
  unit: string,
  issue: string,
  priority: string,
  daysOpen: number | 'N/A',
  highlight: Highlight,
  dueDate?: string,
};

export const mapOpenWorkOrders = ({
  openWorkOrders,
  workOrderDueDateTaskTabFlag,
}: OpenWorkOrderArgs): MappedOpenWorkOrders[] => {
  const today = moment();
  const workOrders = openWorkOrders.sort((a, b) => {
    const dueDateA = moment(a.created_at).add(a.numDaysDue, 'days');
    const dueDateB = moment(b.created_at).add(b.numDaysDue, 'days');
    return dueDateA - dueDateB;
  });
  return workOrders.map((task) => {
    const createdAt = moment(task.created_at);
    const daysOpen = today.diff(createdAt, 'days') || 0;
    const dueDate = createdAt.add(task.numDaysDue, 'days');

    const highlight = getHighlightColor({
      checkDate: task.created_at,
      checkEndDate: dueDate,
      type: TASK_TYPE.WORK_ORDERS,
      daysDue: task.numDaysDue,
      workOrderDueDateTaskTabFlag: workOrderDueDateTaskTabFlag,
    });

    if (
      !highlight.red &&
      (!task.assignedToId ||
        (task.assignedToId && task.user_status === STATUS.INACTIVE))
    ) {
      highlight.yellow = true;
    }

    if (workOrderDueDateTaskTabFlag) {
      const dateDue =
        task.numDaysDue !== null ? moment(dueDate).format('MM/DD/YYYY') : '---';

      return {
        id: task.id,
        unit: task.unit || 'N/A',
        issue: task.issue,
        priority: task.priority,
        daysOpen: daysOpen,
        highlight,
        dueDate: dateDue,
      };
    } else {
      return {
        id: task.id,
        unit: task.unit || 'N/A',
        issue: task.issue,
        priority: task.priority,
        daysOpen: daysOpen,
        highlight,
      };
    }
  });
};

export const openWorkOrdersHeaders = ({
  intl,
  workOrderDueDateTaskTabFlag,
}: OpenWorkOrderHeaderArgs): OpenWorkOrderHeader[] => {
  return workOrderDueDateTaskTabFlag
    ? [
        {
          id: 1,
          text: intl.formatMessage(messages.unitHeader),
          sortKey: 'unit',
        },
        {
          id: 2,
          text: intl.formatMessage(messages.issueHeader),
          sortKey: 'issue',
        },
        {
          id: 3,
          text: intl.formatMessage(messages.priorityHeader),
          sortKey: 'priority',
        },
        {
          id: 4,
          text: intl.formatMessage(messages.daysOpenHeader),
          sortKey: 'daysOpen',
          initialSortDirection: 'descending',
        },
        {
          id: 5,
          text: intl.formatMessage(messages.dueDateHeader),
          sortKey: 'dueDate',
        },
      ]
    : [
        {
          id: 1,
          text: intl.formatMessage(messages.unitHeader),
          sortKey: 'unit',
        },
        {
          id: 2,
          text: intl.formatMessage(messages.issueHeader),
          sortKey: 'issue',
        },
        {
          id: 3,
          text: intl.formatMessage(messages.priorityHeader),
          sortKey: 'priority',
        },
        {
          id: 4,
          text: intl.formatMessage(messages.daysOpenHeader),
          sortKey: 'daysOpen',
          initialSortDirection: 'descending',
        },
      ];
};
