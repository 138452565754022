import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { renderTextAreaField } from '../../utils/redux-form-helper';

import messages from './messages';

export class UpdateSpecialsForm extends Component<Object> {
  myClickOutsideHandler() {
    this.props.handleClickOutside();
  }
  render() {
    const { handleSubmit, valid, dirty } = this.props;
    return (
      <div className="container-fluid">
        <div className="notes-container row">
          <div className="col-xs-6">
            <h4>
              <FormattedMessage {...messages.editCurrentSpecials} />
            </h4>
          </div>
          <div className="col-xs-6 text-right">
            <a
              className="btn-text"
              onClick={handleSubmit}
              disabled={!valid || !dirty}
            >
              <i className="icon et-floppy" />
              <FormattedMessage {...messages.saveSpecials} />
            </a>
          </div>
          <div className="col-xs-12">
            <Field
              name="specials"
              component={renderTextAreaField}
              maxLength={500}
            />
          </div>
        </div>
      </div>
    );
  }
}

const clickOutsideConfig = {
  handleClickOutside: function (instance: any) {
    return instance.myClickOutsideHandler;
  },
};

UpdateSpecialsForm = onClickOutside(UpdateSpecialsForm, clickOutsideConfig);

// $FlowFixMe
export default reduxForm({
  form: 'updateUnitSpecials',
  enableReinitialize: true,
})(UpdateSpecialsForm);
