import { defineMessages } from 'react-intl';

const messages = defineMessages({
  adultsHowMany:{
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Adults.HowMany',
    defaultMessage: 'How Many?'
  },
  other: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Adults.Other',
    defaultMessage: 'Other Adult(s)'
  },
  addDetail: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Adults.AddDetail',
    defaultMessage: '+ Add adult detail'
  },
  relationship: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Adults.Relationship',
    defaultMessage: 'Relationship'
  },
  firstName: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Adults.FirstName',
    defaultMessage: 'First'
  },
  middleName: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Adults.MiddleName',
    defaultMessage: 'Middle'
  },
  lastName: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Adults.LastName',
    defaultMessage: 'Last'
  },
  suffix: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Adults.SuffixName',
    defaultMessage: 'Suffix'
  },
  preferredName: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Adults.PreferredName',
    defaultMessage: 'Preferred Name'
  },
  phone: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Adults.Phone',
    defaultMessage: 'Phone'
  },
  email: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Adults.Email',
    defaultMessage: 'Email'
  },
  delete: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Adults.Delete',
    defaultMessage: 'Delete'
  }
});

export default messages;