import React from 'react';
import PhotoDescriptionCard from './photoDescriptionCard';

type Props = {
  images: any[],
  onDeletePhoto: Function,
};

function UploadedPhotoList({ images = [], onDeletePhoto }: Props) {
  return (
    <div style={{ display: 'flex' }}>
      {images.map((image, index) => (
        <PhotoDescriptionCard
          index={index}
          key={index}
          id={image.id}
          name={image.name}
          url={image.url || image.path}
          onDelete={onDeletePhoto}
        />
      ))}
    </div>
  );
}

export default UploadedPhotoList;
