export const GET_ROLE = 'app/EditRole/GET_ROLE';
export const GET_ROLE_SUCCESS = 'app/EditRole/GET_ROLE_SUCCESS';
export const GET_ROLE_FAIL = 'app/EditRole/GET_ROLE_FAIL';
export const EDIT_ROLE = 'app/EditRole/EDIT_ROLE';
export const EDIT_ROLE_SUCCESS = 'app/EditRole/EDIT_ROLE_SUCCESS';
export const EDIT_ROLE_FAIL = 'app/EditRole/EDIT_ROLE_FAIL';
export const NONE_PERMISSIONS_SELECTED = 'app/EditRole/NONE_PERMISSIONS_SELECTED';
export const SET_SELECTED_PERMISSIONS = 'app/EditRole/SET_SELECTED_PERMISSIONS';
export const RESET_CREATE_ROLE_FORM = 'app/EditRole/RESET_CREATE_ROLE_FORM';
export const SHOW_ROLE_PERMIT_ASSIGNEED = 'app/EditRole/SHOW_ROLE_PERMIT_ASSIGNEED';
