import moment from 'moment';

type RentRollRow = {
  collectionNoteStartTime?: string,
  collectionNote?: string,
};

export const getCollectionNotesValues = (row: RentRollRow) => {
  const collectionStartTimeStr = row?.collectionNoteStartTime || null;

  const collectionStartTime = collectionStartTimeStr
    ? moment(collectionStartTimeStr).format('MM/DD/YYYY')
    : null;

  return collectionStartTime
    ? [collectionStartTime, row?.collectionNote || null].join(' - ')
    : row?.collectionNote || null;
};
