import { put, takeLatest, select, throttle } from 'redux-saga/effects';
import messages from './messages';
import {
  GET_ALL_APPLICATIONS,
  GET_ALL_APPLICATION_STATUSES,
  APPLICATION_UPDATE_PROSPECT_ACTIVITY,
  CREATE_APPLICATION_PROSPECT_ACTIVITY,
  APPLICATION_DELETE_PROSPECT_ACTIVITY,
  SHOW_NO_DATA_TOASTR,
} from './constants';
import {
  createApplicationProspectActivityError,
  createApplicationProspectActivitySuccess,
  getAllApplicationsFail,
  getAllApplicationsSuccess,
  getAllApplicationStatusesError,
  getAllApplicationStatusesSuccess,
} from './actions';
import type { Action, FilterValueAndPagination } from '../App/types';
import type { ActivityAndPagination } from '../ManageProspects/types';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import ApplicationService from '../../services/applicationService';
import ApplicationStatusService from '../../services/applicationStatusService';
import ActivityService from '../../services/activityService';
import type { Saga } from 'redux-saga';
import {
  renderTranslatedMessage,
  renderNoDataToastr,
} from '../../utils/redux-form-helper';
import { actions as toastrActions } from 'react-redux-toastr';
import { omit } from 'ramda';

export function* fetchGetAllApplicationsSaga({
  payload,
}: Action<FilterValueAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const applicationService = new ApplicationService();
    const response = yield applicationService.getAllApplicationsPaginate(
      organizationId,
      property.id,
      payload.paginationInfo.pageNumber,
      payload.paginationInfo.limit,
      payload.sorting,
      payload.filters,
      payload.searchText,
    );
    if (response?.results?.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getAllApplicationsSuccess(response.results, response.meta));
  } catch (err) {
    yield put(getAllApplicationsFail(err));
  }
}

export function* fetchGetAllApplicationStatuses(): Saga<void> {
  try {
    const unitService = new ApplicationStatusService();
    const response = yield unitService.getAll();
    yield put(getAllApplicationStatusesSuccess(response));
  } catch (err) {
    yield put(getAllApplicationStatusesError(err));
  }
}

export function* getAllApplicationStatuses(): Saga<void> {
  yield takeLatest(
    GET_ALL_APPLICATION_STATUSES,
    fetchGetAllApplicationStatuses,
  );
}

export function* createApplicationProspectActivity({
  payload,
}: Action<FilterValueAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const activityService = new ActivityService();
    // $FlowFixMe
    yield activityService.save(payload.activity, organizationId);
    yield put(createApplicationProspectActivitySuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successActivityDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    // $FlowFixMe
    yield fetchGetAllApplicationsSaga({ payload });
  } catch (err) {
    yield put(createApplicationProspectActivityError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* createApplicationProspectActivitySaga(): Saga<void> {
  yield throttle(
    500,
    CREATE_APPLICATION_PROSPECT_ACTIVITY,
    createApplicationProspectActivity,
  );
}

export function* updateProspectActivity({
  payload,
}: Action<ActivityAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);

    const activityService = new ActivityService();
    const editPayload = omit(
      [
        'id',
        // $FlowFixMe
        'saveAndClose',
        // $FlowFixMe
        'saveAndAddNew',
        'createdAt',
        'updatedAt',
        // $FlowFixMe
        'createdById',
        // $FlowFixMe
        'updatedById',
        'updatedBy',
        'deletedAt',
        'lastUpdatedById',
        'ownedBy',
        'prospect',
        'completionStatus',
        'activityType',
      ],
      payload.activity,
    );
    yield activityService.edit(
      editPayload,
      payload.activity.id,
      organizationId,
    );
    // $FlowFixMe
    yield fetchGetAllApplicationsSaga({ payload });
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.successEditActivityDescription,
        ),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* deleteApplicationProspectActivity({
  payload,
}: Action<ActivityAndPagination>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const activityService = new ActivityService();
    yield activityService.delete(payload.activity, organizationId);
    // $FlowFixMe
    yield fetchGetAllApplicationsSaga({ payload });
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.successDeleteActivityDescription,
        ),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* showNoDataToastr(): Saga<void> {
  yield put(renderNoDataToastr());
}

export function* getAllApplicationsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_APPLICATIONS, fetchGetAllApplicationsSaga);
}

export function* updateProspectActivitySaga(): Saga<void> {
  yield throttle(
    500,
    APPLICATION_UPDATE_PROSPECT_ACTIVITY,
    updateProspectActivity,
  );
}

export function* deleteApplicationProspectActivitySaga(): Saga<void> {
  yield throttle(
    500,
    APPLICATION_DELETE_PROSPECT_ACTIVITY,
    deleteApplicationProspectActivity,
  );
}

export function* showNoDataToastrSaga(): Saga<void> {
  yield takeLatest(SHOW_NO_DATA_TOASTR, showNoDataToastr);
}

export default [
  createApplicationProspectActivitySaga,
  deleteApplicationProspectActivitySaga,
  getAllApplicationsSaga,
  getAllApplicationStatuses,
  updateProspectActivitySaga,
  showNoDataToastrSaga,
];
