import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as reduxForm from 'redux-form';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import * as R from 'ramda';

import messages from './messages';
import { EMERGENCY_CONTACTS_FORM_FIELDS, FORM_NAME } from './constants';
import SubmitButton from '../../../../components/SubmitButton';
import ConfirmOverlay from '../../../../components/ConfirmOverlay';
import AddEditEmergencyContactForm from './AddEditEmergencyContactForm';
import type { AddEditEmergencyContactModalProps } from './types.js';

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButtonMargins = styled.span`
  & button {
    margin: 0 10px;
  }
`;

const TrashRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  & i {
    font-size: 20px;
  }
`;

const AddEditEmergencyContactModal = ({
  emergencyContact,
  householdId,
  form,
  submit,
  onHide,
  onEdit,
  onAdd,
  onDelete,
}: AddEditEmergencyContactModalProps) => {
  const [showConfirmDeleteOverlay, setShowConfirmDeleteOverlay] = useState(
    false,
  );
  const isAddForm = R.isNil(emergencyContact);
  const headerMessage = isAddForm ? messages.headerAdd : messages.headerEdit;

  const onHideClicked = () => {
    setShowConfirmDeleteOverlay(false);
    onHide();
  };

  const onDeleteRejected = () => {
    setShowConfirmDeleteOverlay(false);
  };

  const onDeleteConfirmed = () => {
    if (!isAddForm) {
      // not sure how this could happen when adding, but just to be safe...
      onDelete(emergencyContact.id);
    }
    onHideClicked();
  };

  const onDeleteClicked = () => {
    if (!isAddForm) {
      // not sure how this could happen when adding, but just to be safe...
      setShowConfirmDeleteOverlay(true);
    }
  };

  const onFormSubmit = (values) => {
    if (isAddForm) {
      onAdd(values);
    } else {
      onEdit(values);
    }

    onHideClicked();
  };

  const onSubmitButtonClicked = () => {
    submit(FORM_NAME);
  };

  const isSubmitDisabled = () => {
    return !!(R.prop('syncErrors', form) || showConfirmDeleteOverlay);
  };

  const initialValues = useMemo(
    () =>
      isAddForm
        ? null
        : R.pick(EMERGENCY_CONTACTS_FORM_FIELDS, emergencyContact),
    [emergencyContact, isAddForm],
  );

  return (
    <Modal onHide={onHideClicked} show={true} bsSize="large" autoFocus>
      <Modal.Header style={{ paddingBottom: 0 }} closeButton>
        <i className="icon et-notes" />
        <h1>
          <FormattedMessage {...headerMessage} />
        </h1>
        <p style={{ marginLeft: '1%', fontWeight: 400 }}>
          * <FormattedMessage {...messages.requiredField} />
        </p>
      </Modal.Header>
      <Modal.Body>
        {showConfirmDeleteOverlay && (
          <ConfirmOverlay
            title={<FormattedMessage {...messages.confirmDelete} />}
            rejectMessage={<FormattedMessage {...messages.no} />}
            confirmMessage={<FormattedMessage {...messages.yes} />}
            onReject={onDeleteRejected}
            onConfirm={onDeleteConfirmed}
          />
        )}
        {!isAddForm && (
          <TrashRow>
            <a>
              <i className="icon et-trash" onClick={onDeleteClicked} />
            </a>
          </TrashRow>
        )}
        <AddEditEmergencyContactForm
          initialValues={initialValues}
          onSubmit={onFormSubmit}
        />
      </Modal.Body>
      <Modal.Footer>
        <FooterContainer>
          <Button type="button" bsStyle="default" onClick={onHideClicked}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <SubmitButtonMargins>
            <SubmitButton
              bsStyle="primary"
              clickHandler={onSubmitButtonClicked}
              disabled={isSubmitDisabled()}
              isSubmitting={false}
            >
              <FormattedMessage {...messages.save} />
            </SubmitButton>
          </SubmitButtonMargins>
        </FooterContainer>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({ form }) => ({
  form: form[FORM_NAME],
});

const mapDispatchToProps = {
  submit: reduxForm.submit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddEditEmergencyContactModal);
