import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Link,
  Tooltip,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from './messages';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  areApplicationFeesPaid: boolean,
  hasAssignedUnit: boolean,
  routeToAvailableUnits: Function,
  unassignUnit: Function,
  propertyConfigs: Object,
  isDisabled: boolean,
};

const AssignUnitLink = ({
  areApplicationFeesPaid,
  hasAssignedUnit,
  routeToAvailableUnits,
  unassignUnit,
  propertyConfigs,
  isDisabled,
}: Props) => {
  const { assigningUnitsAtApplication } = useFlags();

  const isUnitSelectionEnabled = assigningUnitsAtApplication
    ? propertyConfigs?.isUnitSelectionEnabled ?? false
    : false;

  const { onClick, message, disabled } = useMemo(() => {
    const linkProps = {
      onClick: () => {},
      message: {},
      disabled: false,
    };

    if (hasAssignedUnit) {
      linkProps.onClick = unassignUnit;
      linkProps.message = messages.unAssignUnit;
      linkProps.disabled = isDisabled;
    }

    if (!hasAssignedUnit) {
      linkProps.onClick = routeToAvailableUnits;
      linkProps.message = messages.assignUnit;
      linkProps.disabled = isUnitSelectionEnabled
        ? !areApplicationFeesPaid
        : false;
    }
    return linkProps;
  }, [
    hasAssignedUnit,
    unassignUnit,
    isDisabled,
    routeToAvailableUnits,
    isUnitSelectionEnabled,
    areApplicationFeesPaid,
  ]);

  return (
    <Tooltip
      component={'span'}
      title={
        isDisabled && hasAssignedUnit ? (
          <FormattedMessage {...messages.unassignDisabled} />
        ) : disabled ? (
          <FormattedMessage {...messages.assignUnitDisabled} />
        ) : (
          ''
        )
      }
    >
      <Link onClick={onClick} disabled={disabled}>
        <FormattedMessage {...message} />
      </Link>
    </Tooltip>
  );
};

AssignUnitLink.displayName = 'AssignUnitLink';

export default AssignUnitLink;
