import React from 'react';
import { includes } from 'ramda';
import { FormattedMessage } from 'react-intl';
import { config } from './configuration';
import messages from './messages';
import { EXCLUDED_FOR_COMMERCIAL_COLUMNS } from './constants';

export const allOrderedColumns = [
  {
    id: 'unit',
    title: <FormattedMessage {...messages.unit} />,
    rawTitle: { ...messages.unit },
    type: '',
  },
  {
    id: 'floorPlan',
    title: <FormattedMessage {...messages.floorPlan} />,
    rawTitle: { ...messages.floorPlan },
    type: '',
  },
  {
    id: 'bedsBaths',
    title: <FormattedMessage {...messages.bedsBaths} />,
    rawTitle: { ...messages.bedsBaths },
    type: '',
  },
  {
    id: 'sqFt',
    title: <FormattedMessage {...messages.sqFeet} />,
    rawTitle: { ...messages.sqFeet },
    type: '',
  },
  {
    id: 'status',
    title: <FormattedMessage {...messages.status} />,
    rawTitle: { ...messages.status },
    type: '',
  },
  {
    id: 'resident',
    title: <FormattedMessage {...messages.resident} />,
    rawTitle: { ...messages.resident },
    type: '',
  },
  {
    id: 'otherHouseholdMembers',
    title: <FormattedMessage {...messages.otherHouseholdMembers} />,
    rawTitle: { ...messages.otherHouseholdMembers },
    type: '',
  },
  {
    id: 'requestedHardshipExemption',
    title: <FormattedMessage {...messages.hardship} />,
    rawTitle: { ...messages.hardship },
    type: '',
  },
  {
    id: 'setAside',
    title: <FormattedMessage {...messages.setAside} />,
    rawTitle: { ...messages.setAside },
    type: '',
  },
  {
    id: 'moveInDate',
    title: <FormattedMessage {...messages.moveInDate} />,
    rawTitle: { ...messages.moveInDate },
    type: 'date',
  },
  {
    id: 'leaseStartDate',
    title: <FormattedMessage {...messages.leaseStartDate} />,
    rawTitle: { ...messages.leaseStartDate },
    type: 'date',
  },
  {
    id: 'leaseEndDate',
    title: <FormattedMessage {...messages.leaseEndDate} />,
    rawTitle: { ...messages.leaseEndDate },
    type: 'date',
  },
  {
    id: 'collectionNote',
    title: <FormattedMessage {...messages.collectionsNotes} />,
    rawTitle: { ...messages.collectionsNotes },
    classes: 'table-col-width210',
    type: 'string',
  },
  {
    id: 'underEviction',
    title: <FormattedMessage {...messages.underEviction} />,
    rawTitle: { ...messages.underEviction },
    type: 'boolean',
  },
  {
    id: 'baseFloorPlanMarketRent',
    title: <FormattedMessage {...messages.floorPlanBaseGPR} />,
    rawTitle: { ...messages.floorPlanBaseGPR },
    type: 'moneyZeroNull',
    tooltip: { ...messages.floorPlanBaseGPRTooltip },
  },

  {
    id: 'amenityFees', // total amenity fees
    title: <FormattedMessage {...messages.amenityFees} />,
    rawTitle: { ...messages.amenityFees },
    type: 'moneyZeroNull',
  },
  {
    id: 'marketRent',
    title: <FormattedMessage {...messages.unitGPRMarketRent} />,
    rawTitle: { ...messages.unitGPRMarketRent },
    type: 'moneyZeroNull',
    tooltip: { ...messages.unitGPRMarketRentTooltip },
  },
  {
    id: 'totalGrossRent',
    title: <FormattedMessage {...messages.totalGrossRent} />,
    rawTitle: { ...messages.totalGrossRent },
    type: 'moneyZeroNull',
    tooltip: { ...messages.totalGrossRentTooltip },
  },
  {
    id: 'grossRentLimit',
    title: <FormattedMessage {...messages.grossRentLimit} />,
    rawTitle: { ...messages.grossRentLimit },
    type: 'moneyZeroNull',
    tooltip: { ...messages.grossRentLimitTooltip },
  },
  {
    id: 'netMaxRent',
    title: <FormattedMessage {...messages.netMaxRent} />,
    rawTitle: { ...messages.netMaxRent },
    type: 'moneyZeroNull',
    tooltip: { ...messages.netMaxRentTooltip },
  },
  {
    id: 'priorLeasedRent',
    title: <FormattedMessage {...messages.priorLeasedRent} />,
    rawTitle: { ...messages.priorLeasedRent },
    type: 'moneyZeroNull',
  },
  {
    id: 'leasedRent',
    title: <FormattedMessage {...messages.currentLeasedRent} />,
    rawTitle: { ...messages.currentLeasedRent },
    type: 'moneyZeroNull',
  },
  {
    id: 'nonOptionalCharge',
    title: <FormattedMessage {...messages.nonOptionalCharge} />,
    rawTitle: { ...messages.nonOptionalCharge },
    type: 'moneyZeroNull',
  },
  {
    id: 'actualUa',
    title: <FormattedMessage {...messages.actualUa} />,
    rawTitle: { ...messages.actualUa },
    type: 'moneyZeroNull',
    tooltip: { ...messages.actualUaTooltip },
  },
  {
    id: 'utilityAllowances',
    title: <FormattedMessage {...messages.utilityAllowance} />,
    rawTitle: { ...messages.utilityAllowance },
    type: 'moneyZeroNull',
    tooltip: { ...messages.utilityAllowanceTooltip },
  },
  {
    id: 'residentRent',
    title: <FormattedMessage {...messages.residentChargedRent} />,
    rawTitle: { ...messages.residentChargedRent },
    type: 'moneyZeroNull',
  },
  {
    id: 'subsidyRent',
    title: <FormattedMessage {...messages.subsidyRent} />,
    rawTitle: { ...messages.subsidyRent },
    type: 'moneyZeroNull',
  },
  {
    id: 'basicRent',
    title: <FormattedMessage {...messages.basicRent} />,
    rawTitle: { ...messages.basicRent },
    type: 'moneyZeroNull',
  },
  {
    id: 'noteRent',
    title: <FormattedMessage {...messages.noteRent} />,
    rawTitle: { ...messages.noteRent },
    type: 'moneyZeroNull',
  },
  {
    id: 'chargedUnitFees',
    title: <FormattedMessage {...messages.chargedUnitFees} />,
    rawTitle: { ...messages.chargedUnitFees },
    type: 'moneyZeroNull',
  },
  {
    id: 'balance',
    title: <FormattedMessage {...messages.balance} />,
    rawTitle: { ...messages.balance },
    type: 'moneyZeroNull',
  },
  {
    id: 'moveOutDate',
    title: <FormattedMessage {...messages.moveOutDate} />,
    rawTitle: { ...messages.moveOutDate },
    classes: 'header-darker',
    type: 'date',
  },
  {
    id: 'moveInReady',
    title: <FormattedMessage {...messages.moveInReady} />,
    rawTitle: { ...messages.moveInReady },
    classes: 'header-darker',
    type: 'dateOrString',
  },
  {
    id: 'daysVacant',
    title: <FormattedMessage {...messages.daysVacant} />,
    rawTitle: { ...messages.daysVacant },
    classes: 'header-darker',
    type: 'numberZeroNull',
  },
  {
    id: 'quotingRent',
    title: <FormattedMessage {...messages.quotingRent} />,
    rawTitle: { ...messages.quotingRent },
    classes: 'header-darker',
    type: 'moneyZeroNull',
  },
  {
    id: 'applicant',
    title: <FormattedMessage {...messages.applicant} />,
    rawTitle: { ...messages.applicant },
    classes: 'header-darker',
    type: '',
  },
  {
    id: 'applicantStatus',
    title: <FormattedMessage {...messages.applicantStatus} />,
    rawTitle: { ...messages.applicantStatus },
    classes: 'header-darker',
    type: '',
  },
  {
    id: 'scheduledMoveInDate',
    title: <FormattedMessage {...messages.scheduledMoveInDate} />,
    rawTitle: { ...messages.scheduledMoveInDate },
    classes: 'header-darker',
    type: 'date',
  },
  {
    id: 'amenityDetails',
    title: <FormattedMessage {...messages.amenityDetails} />,
    rawTitle: { ...messages.amenityDetails },
    type: 'moneyZeroNull',
  },
];

// Key is flag, array is columns
const FEATURE_FLAGGED_COLUMNS = {
  rentRollNewColumns: ['otherHouseholdMembers', 'totalGrossRent', 'actualUa'],
};

const getFlaggedColumnsToBeFiltered = (flags?: Object = {}): Array<string> => {
  const featureFlagsToFilter = Object.keys(FEATURE_FLAGGED_COLUMNS);
  return featureFlagsToFilter.reduce((acc, flag) => {
    const isOff = !(flags?.[flag] ?? false);
    return [...acc, ...(isOff ? FEATURE_FLAGGED_COLUMNS?.[flag] ?? [] : [])];
  }, []);
};

export const getAllColumns = (
  propertyType: string,
  flags: Object = {},
  isCommercial?: boolean = false,
) => {
  const conf = config[propertyType];
  let columns = conf.columns;
  const rentRollNewColumns = flags?.rentRollNewColumns;
  if (!rentRollNewColumns || isCommercial) {
    columns = columns.filter(
      (column) => !EXCLUDED_FOR_COMMERCIAL_COLUMNS.includes(column),
    );
  }
  const columnsToFilter = getFlaggedColumnsToBeFiltered(flags);
  columns = columns.filter((column) => !columnsToFilter.includes(column));
  return allOrderedColumns.filter((x) => includes(x.id, columns));
};

export const getSwitchableColumns = ({
  storedShowHideColumns,
  propertyClass,
  intl,
  flags,
}) => {
  const conf = config[propertyClass];
  const columnsToFilter = getFlaggedColumnsToBeFiltered(flags);
  const filteredConf = Object.keys(conf).reduce((acc, columnList) => {
    const columns = conf?.[columnList] ?? [];
    return {
      ...acc,
      [columnList]: columns.filter(
        (column) => !columnsToFilter.includes(column),
      ),
    };
  }, {});
  const filteredColumns = allOrderedColumns.filter((x) =>
    includes(x.id, filteredConf?.showHideColumns || []),
  );
  return filteredColumns.map((c) => ({
    id: c.id,
    text: intl.formatMessage(c.rawTitle),
    selected: false,
  }));
};

export const getTotalsList = (propertyType) => {
  const conf = config[propertyType];
  return conf.totals;
};
