import { defineMessages } from 'react-intl';

const messages = defineMessages({
  edit: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.Edit',
    defaultMessage: 'Edit',
  },
  resubmit: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.Resubmit',
    defaultMessage: 'Resubmit',
  },
  on: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.On',
    defaultMessage: 'On',
  },
  by: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.By',
    defaultMessage: 'by',
  },
  submittedOn: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.SubmittedOn',
    defaultMessage: 'Submitted On',
  },
  submit: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.Submit',
    defaultMessage: 'Submit to TransUnion',
  },
  makeDecision: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.MakeDecision',
    defaultMessage: 'Make Decision',
  },
  transUnionScreening: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.TransUnionScreening',
    defaultMessage: 'TransUnion Screening',
  },
  finalScreeningStatus: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.FinalScreeningStatus',
    defaultMessage: 'Final Screening Status',
  },
  missingApplications: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.MissingApplications',
    defaultMessage: 'Missing Completed Applications',
  },
  firstSubmit: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.FirstSubmit',
    defaultMessage: 'Please first submit to TransUnion',
  },
  pleaseWait: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.PleaseWait',
    defaultMessage: 'Please wait.',
  },
  estimatedMonthlyRentAmount: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.EstimatedMonthlyRentAmount',
    defaultMessage: 'ESTIMATED MONTHLY RENT AMOUNT',
  },
  screeningError: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.ScreeningError',
    defaultMessage: 'Must be a dollar value greater than $0.00',
  },
  createAdverseActionLetter: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.CreditCriminalStatus.CreateAdverseActionLetter',
    defaultMessage: 'Create Adverse Action Letter',
  },
});

export default messages;
