import React, { useMemo } from 'react';
import Datetime from 'react-datetime';
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap';

import styled from 'styled-components';
import * as R from 'ramda';
import * as formHelpers from '../../../../../../utils/redux-form-helper';
import { isToDateNotSameOrAfterFromDate } from '../../../../../../utils/date-helpers';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

type Props = {
  from?: string,
  to?: string,
  label?: string,
  fromName?: string,
  toName?: string,
  fromPlaceholder?: string,
  toPlaceholder?: string,
  isFromDateValid?: Function,
  isToDateValid?: Function,
  exactDateValue?: string,
  exactDateLabel?: string,
  exactDatePlaceholder?: string,
  exactDateName?: string,
  onExactDateChange: Function,
  onFromChange: Function,
  onToChange: Function,
  clearExactDate: Function,
  clearDateRange: Function,
  width: string,
  disabled: boolean,
  isRange: boolean,
  setIsRange: Function,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${R.prop('width')};
  .date-range-ctrl {
    height: 33px;
  }
`;

const Separator = styled.div`
  width: 16px;
  height: 0;
  border: 1px solid #b4b4b4;
  margin: 0 10px;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .rdt {
    flex-grow: 1;!important;
  }
`;

const dateTimeClassname = 'date-range-ctrl';

function VariableDateControl(props: Props) {
  const showError = useMemo(
    () =>
      isToDateNotSameOrAfterFromDate(
        props.from || props.exactDateValue,
        props.to,
      ),
    [props.exactDateValue, props.from, props.to],
  );

  const onRangeCheckboxChange = () => {
    if (props.from || props.to) {
      props.clearDateRange();
    }
    props.setIsRange(!props.isRange);
  };

  return (
    <Wrapper width={props.width}>
      <Stack direction="row" justifyContent="space-between">
        <div>{props.label && <ControlLabel>{props.label}</ControlLabel>}</div>
        <Stack direction="row" spacing={1}>
          <input
            type="checkbox"
            onChange={onRangeCheckboxChange}
            checked={props.isRange}
            disabled={props.disabled}
          />
          <ControlLabel>
            <FormattedMessage {...messages.range} />
          </ControlLabel>
        </Stack>
      </Stack>
      {props.isRange && (
        <FormGroup
          controlId="toDate"
          validationState={showError ? 'error' : null}
        >
          <InputContainer>
            <Datetime
              name={props.fromName}
              value={props.from || props.exactDateValue}
              inputProps={{
                placeholder: props.fromPlaceholder,
                className: dateTimeClassname,
              }}
              timeFormat={false}
              closeOnSelect={true}
              onChange={props.onFromChange}
              renderInput={formHelpers.renderDatePickerInput}
            />
            <Separator />
            <Datetime
              name={props.toName}
              value={props.to}
              inputProps={{
                placeholder: props.toPlaceholder,
                className: dateTimeClassname,
              }}
              timeFormat={false}
              closeOnSelect={true}
              onChange={props.onToChange}
              renderInput={formHelpers.renderDatePickerInput}
            />
          </InputContainer>
          {showError && (
            <HelpBlock className="has-error">
              <FormattedMessage {...messages.dateValidationError} />
            </HelpBlock>
          )}
        </FormGroup>
      )}
      {!props.isRange && (
        <FormGroup>
          <InputContainer>
            <Datetime
              name={props.exactDateName}
              value={props.exactDateValue}
              inputProps={{
                placeholder: props.exactDatePlaceholder,
                className: dateTimeClassname,
                disabled: props.disabled,
              }}
              timeFormat={false}
              closeOnSelect={true}
              onChange={props.onExactDateChange}
              renderInput={formHelpers.renderDatePickerInput}
            />
          </InputContainer>
        </FormGroup>
      )}
    </Wrapper>
  );
}

export default VariableDateControl;
