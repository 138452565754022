import React from 'react';
import { Alert, FormGroup, Row, Col } from 'react-bootstrap';
type Props = { label: any, children?: any };

export const TwoColumnText = ({ label, children }: Props) => (
  <Row>
    <Col md={4} xs={12}>
      <label>{label}</label>
    </Col>
    <Col md={8} xs={12}>
      <FormGroup>{children}</FormGroup>
    </Col>
  </Row>
);

export const TwoColumnTotal = ({ label, children }: Props) => (
  <Alert bsStyle="info">
    <Row>
      <Col md={4} xs={12}>
        {label}
      </Col>
      <Col md={8} xs={12} className="text-right">
        {children}
      </Col>
    </Row>
  </Alert>
);
