import React, { Fragment } from 'react';
import {
  PORTAL_INVITE_STATUS_NOT_SENT,
  PORTAL_INVITE_STATUS_SENT,
  PORTAL_SIGNATURE_STATUS_NOT_SIGNED,
  PORTAL_SIGNATURE_STATUS_SIGNED,
} from '../../utils/lease-helpers';

type Props = {
  leaseHolder: Object,
};

export const LeaseHolderRow = ({ leaseHolder }: Props) => {
  return (
    <tr>
      <td>
        {leaseHolder.firstName} {leaseHolder.lastName}
      </td>
      <td className="text-center">
        {leaseHolder.portalStatus === PORTAL_INVITE_STATUS_NOT_SENT && (
          <Fragment>---</Fragment>
        )}
        {leaseHolder.portalStatus === PORTAL_INVITE_STATUS_SENT && (
          <Fragment>Not Registered</Fragment>
        )}
        {leaseHolder.portalStatus === 'claimed' && <Fragment>Yes</Fragment>}
      </td>
      <td className="text-center">
        {!leaseHolder.signatureStatus && <Fragment>---</Fragment>}
        {leaseHolder.signatureStatus === PORTAL_SIGNATURE_STATUS_NOT_SIGNED && (
          <Fragment>No</Fragment>
        )}
        {leaseHolder.signatureStatus === PORTAL_SIGNATURE_STATUS_SIGNED && (
          <Fragment>Yes</Fragment>
        )}
      </td>
    </tr>
  );
};

export default LeaseHolderRow;
