import { put, takeLatest, select, throttle } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import { reset } from 'redux-form';
import FileSaver from 'file-saver';

import {
  GET_RENEWAL_OFFER_TERMS,
  CREATE_RENEWAL_OFFER,
  GET_PREVIOUS_RENEWAL_OFFERS,
} from './constants';
import type { Action, Property } from '../App/types';
import RenewalOfferService from '../../services/renewalOfferService';
import {
  getRenewalOfferTermsSuccess,
  getRenewalOfferTermsError,
  getPreviousRenewalOffers,
  getPreviousRenewalOffersSuccess,
  getPreviousRenewalOffersError,
  createRenewalOfferError,
} from './actions';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
} from '../App/selectors';
import messages from './messages';
import moment from 'moment';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* createRenewalOffer(action: Action<any>): any {
  try {
    const { renewalOffer, leaseId } = action.payload;
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const propertyId = selectedProperty.id;
    const property = yield select(selectSelectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const renewalOfferService = new RenewalOfferService();
    const response = yield renewalOfferService.createOffer(
      organizationId,
      propertyId,
      renewalOffer,
      leaseId,
    );

    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(
          messages.createRenewalOfferSuccessDescription,
        ),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );

    yield put(reset('renewalOffer'));
    yield put(getPreviousRenewalOffers(leaseId));

    const pdf = yield renewalOfferService.downloadOffer(response.id);
    FileSaver.saveAs(
      pdf,
      `Lease-Renewal-Offer-${property.name.replace(
        /[^a-zA-z0-9]+/gi,
        '-',
      )}-${moment().format('MM-DD-YYYY')}.pdf`,
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(createRenewalOfferError(err));
  }
}

export function* createRenewalOfferSaga(): Saga<void> {
  yield throttle(500, CREATE_RENEWAL_OFFER, createRenewalOffer);
}

export function* fetchRenewalOfferTerms(): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const renewalOfferService = new RenewalOfferService();
    const leaseOfferTerms = yield renewalOfferService.getAllLeaseTerms(
      organizationId,
      selectedProperty.id,
    );
    yield put(getRenewalOfferTermsSuccess(leaseOfferTerms));
  } catch (err) {
    yield put(getRenewalOfferTermsError(err));
  }
}

export function* fetchRenewalOfferTermsSaga(): Saga<void> {
  yield takeLatest(GET_RENEWAL_OFFER_TERMS, fetchRenewalOfferTerms);
}

export function* fetchPreviousRenewalOffers(action: Action<any>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const propertyId = selectedProperty.id;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const renewalOfferService = new RenewalOfferService();
    const previousRenewalOffers = yield renewalOfferService.getAllPreviousRenewalOffers(
      organizationId,
      propertyId,
      action.payload,
    );
    yield put(getPreviousRenewalOffersSuccess(previousRenewalOffers));
  } catch (err) {
    yield put(getPreviousRenewalOffersError(err));
  }
}

export function* fetchPreviousRenewalOffersSaga(): Saga<void> {
  yield takeLatest(GET_PREVIOUS_RENEWAL_OFFERS, fetchPreviousRenewalOffers);
}

export default [
  fetchRenewalOfferTermsSaga,
  fetchPreviousRenewalOffersSaga,
  createRenewalOfferSaga,
];
