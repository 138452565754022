import type { Action } from '../App/types';
import type { ViewCommunicationsState } from './types';
import * as ActionTypes from './constants';

const initialState: any = {
  meta: {
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  communications: [],
  columnOrder: {
    method: 'sortable',
    type: 'sortable',
    date: 'sortable',
    time: 'sortable',
    status: 'sortable',
  },
  currentSorting: {
    fieldName: '',
    order: '',
  },
  currentCommunication: {},
  isGetCommunication: false,
  isLoading: false,
};

const manageCommunicationReducer = (
  state: ViewCommunicationsState = initialState,
  action: Action<*, *>,
): ViewCommunicationsState => {
  const { type, payload, meta } = action;
  switch (type) {
    case ActionTypes.GET_ALL_COMMUNICATIONS: {
      return {
        ...state,
        currentSorting: payload.sorting,
        isLoading: true,
      };
    }
    case ActionTypes.GET_ALL_COMMUNICATIONS_SUCCESS: {
      return {
        ...state,
        communications: payload,
        meta: meta,
        isLoading: false,
      };
    }
    case ActionTypes.GET_ONE_COMMUNICATION: {
      return {
        ...state,
        headerId: payload,
        isGetCommunication: true,
      };
    }
    case ActionTypes.GET_ONE_COMMUNICATION_SUCCESS: {
      return {
        ...state,
        currentCommunication: payload,
      };
    }
    case ActionTypes.UPDATE_COLUMNS_SORT_VALUE: {
      return {
        ...state,
        columnOrder: Object.assign(
          {},
          { ...initialState.columnOrder },
          payload,
        ),
      };
    }
    case ActionTypes.CLEAN_LOADED_COMMUNICATIONS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default manageCommunicationReducer;
