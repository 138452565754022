import type { Action } from '../App/types';
import { GET_SIGNATURE_STATUSES_SUCCESS } from './constants';

type LeaseSignatureStatusesState = {
  leaseSignatureStatuses: {
    signatures: Array<Object>,
    latestLog: Object,
  },
};

export const initialState = {
  leaseSignatureStatuses: {
    signatures: [],
    latestLog: { action: null, createdAt: null, createdBy: null },
  },
};

const leaseSignatureStatusesReducer = (
  state: LeaseSignatureStatusesState = initialState,
  action: Action<*, *>,
): LeaseSignatureStatusesState => {
  const { type, payload } = action;

  switch (type) {
    case GET_SIGNATURE_STATUSES_SUCCESS: {
      return {
        ...state,
        leaseSignatureStatuses: payload.signaturesInfo,
      };
    }
    default:
      return state;
  }
};

export default leaseSignatureStatusesReducer;
