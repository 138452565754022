import { defineMessages } from 'react-intl';

const messages = defineMessages({
  integrationsSetup: {
    id: 'App.IntegrationsSetup.IntegrationsSetup',
    defaultMessage: 'Integrations Setup',
  },
  general: {
    id: 'App.IntegrationsSetup.General',
    defaultMessage: 'General',
  },
  domuso: {
    id: 'App.IntegrationsSetup.Domuso',
    defaultMessage: 'Domuso',
  },
  domusoPropertyCode: {
    id: 'App.IntegrationsSetup.DomusoPropertyCode',
    defaultMessage: 'Domuso Code',
  },
  conservice: {
    id: 'App.IntegrationsSetup.Conservice',
    defaultMessage: 'Conservice',
  },
  munibilling: {
    id: 'App.IntegrationsSetup.Munibilling',
    defaultMessage: 'Munibilling',
  },
  screenings: {
    id: 'App.IntegrationsSetup.Screenings',
    defaultMessage: 'Screenings',
  },
  transunion: {
    id: 'App.IntegrationsSetup.TransUnion',
    defaultMessage: 'TransUnion',
  },
  workNumber: {
    id: 'App.IntegrationsSetup.WorkNumber',
    defaultMessage: 'The Work Number',
  },
  ilsIntegrations: {
    id: 'App.BasicDetails.ILSIntegrations',
    defaultMessage: 'ILS Integrations',
  },
  aptCom: {
    id: 'App.BasicDetails.AptCom',
    defaultMessage: 'Apt.com',
  },
  on: {
    id: 'App.IntegrationsSetup.On',
    defaultMessage: 'On',
  },
  off: {
    id: 'App.IntegrationsSetup.Off',
    defaultMessage: 'Off',
  },
  accept: {
    id: 'App.IntegrationsSetup.Accept',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'App.IntegrationsSetup.Cancel',
    defaultMessage: 'Cancel',
  },
  transunionCode: {
    id: 'App.IntegrationsSetup.TransUnionCode',
    defaultMessage: 'TransUnion Code',
  },
  domusoCodeLength: {
    id: 'App.IntegrationsSetup.DomusoCodeLength',
    defaultMessage: 'Invalid Domuso Code: Code must be a 4 or 5 digit number.',
  },
  confirmTitleTWN: {
    id: 'App.IntegrationsSetup.ConfirmTitleTWN',
    defaultMessage: 'Are you sure you want to turn on The Work Number?',
  },
  confirmModalTitle: {
    id: 'App.IntegrationsSetup.confirmModalTitle',
    defaultMessage: 'Are you sure you want to turn on this integration?',
  },
  note: {
    id: 'App.IntegrationsSetup.Note',
    defaultMessage: 'Note:',
  },
  workNumberAffordableUnits: {
    id: 'App.IntegrationsSetup.WorkNumberAffordableUnits',
    defaultMessage: 'Affordable Units',
  },
  workNumberDescription: {
    id: 'App.IntegrationsSetup.WorkNumberDescription',
    // eslint-disable-next-line max-len
    defaultMessage:
      'This feature allows you to retrieve employment records from The Work Number in two steps.',
  },
  workNumberDescriptionStepOne: {
    id: 'App.IntegrationsSetup.WorkNumberDescriptionStepOne',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Step 1: The initial screening returns employer information for the applicant. It is free and does not provide salary details.',
  },
  workNumberDescriptionStepTwo: {
    id: 'App.IntegrationsSetup.WorkNumberDescriptionStepTwo',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Step 2: Additional screenings will return individual paystub reports per employer. You will be charged for each report you pull.',
  },
  workNumberDescriptionNote: {
    id: 'App.IntegrationsSetup.WorkNumberDescriptionNote',
    defaultMessage:
      // eslint-disable-next-line max-len
      'In order to screen multiple paystub reports, users must be assigned the permission: “The Work Number: Paystub Details - Unlimited Screenings per Adult.”',
  },
  insuranceEnrollment: {
    id: 'App.IntegrationsSetup.InsuranceEnrollment',
    defaultMessage: 'Insurance Enrollment',
  },
  assetProtect: {
    id: 'App.IntegrationsSetup.AssetProtect',
    defaultMessage: 'Asset Protect',
  },
  assetProtectInfo: {
    id: 'App.IntegrationsSetup.AssetProtectInfo',
    defaultMessage:
      // eslint-disable-next-line max-len
      'For properties that are enrolled in Asset Protect, every household is automatically signed up for the program and fees are added to the Required Non-Rent Charges on the Lease Data tab.',
  },
  none: {
    id: 'App.IntegrationsSetup.None',
    defaultMessage: 'None',
  },
  noneInfo: {
    id: 'App.IntegrationsSetup.NoneInfo',
    defaultMessage:
      // eslint-disable-next-line max-len
      'By selecting none, your property will not be enrolled in the Asset Protect program; however, third-party insurance coverage can be manually added to individual households.',
  },
  assetProtectFee: {
    id: 'App.IntegrationsSetup.AssetProtectFee',
    defaultMessage: 'Asset Protect Fee',
  },
  assetProtectFeeValidation: {
    id: 'App.IntegrationsSetup.AssetProtectFeeValidation',
    defaultMessage: 'Asset Protect Fee must be greater or equal to $10.00',
  },
  editedBy: {
    id: 'App.IntegrationsSetup.EditedBy',
    defaultMessage: 'Edited by',
  },
  transUnionLogHistoryMsg: {
    id: 'App.IntegrationsSetup.TransUnionLogHistoryMsg',
    defaultMessage:
      'Turned {transUnionToggleState} by {userName} on {formatDate} @ {formatTime}',
  },
});

export default messages;
