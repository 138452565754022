const { request, apiURL, post } = require('../utils/api');

export default class CamCalculationService {
  getOptions(method: string, signal?: Object): Object {
    const options: Object = {
      method,
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    if (signal) {
      options.signal = signal;
    }
    return options;
  }

  getAllByRecordId(
    organizationId: string,
    propertyId: string,
    camRecordsId: string,
    signal?: Object,
  ) {
    const options = this.getOptions('GET', signal);
    return request(
      `${apiURL}/${organizationId}/${propertyId}/cam-records/${camRecordsId}/cam-calculations`,
      options,
    );
  }

  generateCamCalculations(
    organizationId: string,
    propertyId: string,
    camRecordsId: string,
    signal?: Object,
  ) {
    const options = this.getOptions('GET', signal);
    return request(
      `${apiURL}/${organizationId}/${propertyId}/cam-records/${camRecordsId}/generate-calculations`,
      options,
    );
  }

  confirmCamEstimates(
    organizationId: string,
    propertyId: string,
    camRecordsId: string,
    transactionsToReassign?: Array<Object>,
  ) {
    return post(
      `/${organizationId}/${propertyId}/cam-records/${camRecordsId}/confirm-estimates`,
      JSON.stringify({ transactionsToReassign }),
    );
  }
}
