import { keys } from 'ramda';
import moment from 'moment';

import { extractCurrentDateFormat } from '../../../utils/redux-form-helper';
import formMessages from '../../../containers/ShortLeaseApplication/messages';
import validateOwnOrRentSection from '../OwnOrRentSection/validate';
import { validateRequired } from '../../../utils/validations';

import type { ResidencialInformation } from '../../../containers/App/types';

const validate = (
  values: ResidencialInformation,
  { intl, required }: Object
) => {
  const errors = {};

  const ownOrRent = validateOwnOrRentSection(values.ownOrRent, { intl });

  if (keys(ownOrRent).length > 0) {
    errors.ownOrRent = ownOrRent;
  }

  if (values.monthlyPayment && parseFloat(values.monthlyPayment) < 0) {
    errors.monthlyPayment = intl.formatMessage(formMessages.invalidValue);
  }

  if (values.dateMovedIn) {
    const currentDateFormat = extractCurrentDateFormat(intl);
    const dateMovedIn = moment(values.dateMovedIn, currentDateFormat, true);
    if (!dateMovedIn.isValid()) {
      errors.dateMovedIn = intl.formatMessage(formMessages.invalidValue);
    } else if (dateMovedIn.isAfter()) {
      errors.dateMovedIn = intl.formatMessage(formMessages.invalidValue);
    }
  }
  if (
    !validateRequired('ownOrRentOrNotCurrently', values.ownOrRent) &&
    required
  ) {
    errors.ownOrRent = {
      ownOrRentOrNotCurrently: intl.formatMessage(
        formMessages.ownOrRentRequired
      )
    };
  }

  return errors;
};

export default validate;
