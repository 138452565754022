export const RENDER_STATE = Object.freeze({
  EDIT: 'EDIT',
  DISPLAY: 'DISPLAY',
  LOAD: 'LOAD',
});

export interface ControlProps {
  isSetLeaseEndDateToEndOfMonth: boolean;
  onFormChangeHandler: Function;
}

export interface SetLeaseEndDateProps {
  isEditMode?: boolean;
  onFormChangeHandler?: Function;
  isSetLeaseEndDateToEndOfMonth?: boolean;
  propertyDetailsIsLoading?: boolean;
}
