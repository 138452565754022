import type { Action } from '../App/types';
import type { QualificationState } from './types';
import { GET_ONE_APPLICATION_SUCCESS } from '../ApplicationProfile/constants';
import { GET_ONE_RESIDENT_SUCCESS } from '../ResidentProfile/constants';
import {
  GET_ONE_QUALIFICATION_CHECKLIST_SUCCESS,
  SAVE_QUALIFICATION_CHECKLIST,
  SAVE_QUALIFICATION_CHECKLIST_ERROR,
  SAVE_QUALIFICATION_CHECKLIST_SUCCESS,
} from './constants.js';
import {
  GET_QUALIFICATION_HISTORY_SUCCESS,
  GET_QUALIFICATION_HISTORY_ERROR,
} from '../AffordableQualificationHistoryTab/constants';

export const initialState: QualificationState = {
  frMembers: {},
  applicationId: '',
  applicationChecklists: [],
  profile: '',
  residentId: '',
  qualificationHistory: [],
  isSubmitting: false,
};

const qualificationReducer = (
  state: QualificationState = initialState,
  action: Action<*, *>,
): QualificationState => {
  const { type, payload } = action;
  switch (type) {
    case GET_ONE_APPLICATION_SUCCESS: {
      const { id, applicants, affordableQualifyingChecklist, householdInfo } =
        payload;
      const frMembers = (applicants || [])
        .filter((applicant) => applicant.applicantType.financiallyResponsible)
        .reduce((members, frMember) => {
          members[frMember.id] = frMember.name;
          return members;
        }, {});

      const financiallyResponsibleMembersAndMinors = (applicants || [])
        .filter(
          (applicant) =>
            applicant.applicantType.financiallyResponsible ||
            applicant.applicantType.name === 'Minor',
        )
        .reduce((members, frMember) => {
          members[frMember.id] = frMember.name;
          return members;
        }, {});

      const isMinor = Object.keys(
        financiallyResponsibleMembersAndMinors,
      ).reduce((obj, id) => {
        const applicant = applicants.find((applicant) => applicant.id === id);
        return {
          ...obj,
          [id]: applicant.applicantType.name === 'Minor',
        };
      }, {});

      return {
        ...state,
        frMembers,
        financiallyResponsibleMembersAndMinors,
        isMinor,
        applicationId: id,
        applicationChecklists: affordableQualifyingChecklist,
        profile: 'application',
        householdId: householdInfo.id,
        residentId: '',
      };
    }
    case GET_ONE_RESIDENT_SUCCESS: {
      const {
        affordableQualifyingChecklist,
        applicantInfo: { id, applicants: currentApplicants },
        applicants: { pendingApplicants },
        residentInfo,
        householdInfo,
      } = payload;

      const applicants = currentApplicants.concat(pendingApplicants) || [];

      const frMembers = applicants
        .filter((applicant) => applicant.applicantType.financiallyResponsible)
        .reduce((members, frMember) => {
          members[frMember.id] = frMember.name;
          return members;
        }, {});

      const financiallyResponsibleMembersAndMinors = applicants
        .filter(
          (applicant) =>
            applicant.applicantType.financiallyResponsible ||
            applicant.applicantType.name === 'Minor',
        )
        .reduce((members, frMember) => {
          members[frMember.id] = frMember.name;
          return members;
        }, {});

      const isMinor = Object.keys(
        financiallyResponsibleMembersAndMinors,
      ).reduce((obj, id) => {
        const applicant = applicants.find((applicant) => applicant.id === id);
        return {
          ...obj,
          [id]: applicant.applicantType.name === 'Minor',
        };
      }, {});

      return {
        ...state,
        frMembers,
        financiallyResponsibleMembersAndMinors,
        isMinor,
        applicationId: id,
        applicationChecklists: affordableQualifyingChecklist,
        profile: 'resident',
        householdId: householdInfo.id,
        residentId: residentInfo.id,
      };
    }
    case GET_QUALIFICATION_HISTORY_SUCCESS: {
      return {
        ...state,
        qualificationHistory: payload,
      };
    }
    case GET_QUALIFICATION_HISTORY_ERROR: {
      return {
        ...state,
        qualificationHistory: [],
      };
    }
    case GET_ONE_QUALIFICATION_CHECKLIST_SUCCESS: {
      return {
        ...state,
        applicationChecklists: [payload],
      };
    }
    case SAVE_QUALIFICATION_CHECKLIST: {
      return {
        ...state,
        isSubmitting: true,
      };
    }
    case SAVE_QUALIFICATION_CHECKLIST_SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
      };
    }
    case SAVE_QUALIFICATION_CHECKLIST_ERROR: {
      return {
        ...state,
        isSubmitting: false,
      };
    }
    default:
      return state;
  }
};

export default qualificationReducer;
