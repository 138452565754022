import { defineMessages } from 'react-intl';

const messages = defineMessages({
  fileName: {
    id:
      'App.App.ViewBankDeposit.AddBankDepositDocument.DocumentSelection.FileName',
    defaultMessage: 'File Name',
  },
  browse: {
    id:
      'App.App.ViewBankDeposit.AddBankDepositDocument.DocumentSelection.Browse',
    defaultMessage: 'Browse',
  },
  documentType: {
    id:
      'App.App.ViewBankDeposit.AddBankDepositDocument.DocumentSelection.DocumentType',
    defaultMessage: 'Document Type',
  },
});

export default messages;
