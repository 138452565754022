import React, { Component } from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import isEmpty from 'lodash/isEmpty';

// Redux
import { getStateOptions, getIncomeTypeOptions } from '../App/selectors';
import { getAllStates, getAllIncomeTypes } from '../App/actions';
import { saveQualificationChecklist, printIncomeAssetForm } from './actions';
import { getOneResident } from '../ResidentProfile/actions';
import { isDirty } from 'redux-form';

// Components
import {
  Spinner,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import IncomeVerificationForm from './IncomeVerificationForm';
import IncomeVerificationAlternateForm from './IncomeVerificationAlternateForm';
import AssetInformationForm from './AssetInformationForm';

// Constants
import messages from './messages.js';
import {
  INITIAL_INCOME_VALUES,
  STATE_INITIAL_INCOME_VALUES,
} from './IncomeVerificationForm/constants';
import {
  INITIAL_ASSET_VALUES,
  ALTERNATE_FORM_INITIAL_VALUES,
} from './AssetInformationForm/constants';
import { CERTIFICATION_TYPES } from '../ManageCertifications/constants.js';
import { COMPLIANCE_APPROVAL_IDS_TO_DISABLE_FORMS } from './constants';

// Services
import AffordableQualificationService from '../../services/affordableQualificationService';
import ApplicantService from '../../services/applicantService';
import ApplicantionService from '../../services/applicationService';
import ResidentService from '../../services/residentService';
import StateAffordableProgramConfigService from '../../services/stateAffordableProgramConfigService';
import HouseholdProfileService from '../../services/householdProfileService.js';

// Utils
import { propEq, find, pathOr } from 'ramda';
import {
  parseAffordableQualificationChecklistValues,
  getIsAltForm,
  getCurrentTabAffordableProgram,
} from './utils';
import { filterHouseholdMembers } from '../GeneralAffordable/utils.js';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import type { SelectOption } from '../App/types';

type Props = {
  incomeTypes: Array<SelectOption>,
  states: Array<SelectOption>,
  financiallyResponsibleMembersAndMinors: Object,
  affordableQualificationId: string,
  applicantId: string,
  programName: string,
  applicationId: string,
  applicationChecklists: Array<Object>,
  profile: string,
  residentId: string,
  householdId: string,
  userPermissions: Array<Object>,
  isSubmitting: boolean,
  organizationId: string,
  propertyId: string,
};

type InjectedProps = {
  intl: any,
  actions: Object,
  history: Object,
  match: Object,
};

const UseReactQuery = (props) => {
  return props.children(useQuery(props.keyName, props.fn, props.options));
};

export class Qualification extends Component<Props & InjectedProps> {
  constructor() {
    super();
    this.stateProgramConfigService = new StateAffordableProgramConfigService();
    this.affordableQualificationService = new AffordableQualificationService();
    this.applicantService = new ApplicantService();
    this.residentService = new ResidentService();
    this.applicationService = new ApplicantionService();
    this.houseHoldProfileService = new HouseholdProfileService();
  }
  async componentDidMount() {
    // TODO: remove this reliance on redux
    this.props.actions.getAllStates();
    this.props.actions.getAllIncomeTypes();
  }

  handlePrintIncomeAssetForm = (type: string) => {
    const {
      actions: { printIncomeAssetForm },
      organizationId,
      affordableQualificationId,
      applicantId,
      applicationId,
      propertyId,
      financiallyResponsibleMembersAndMinors,
      programName,
    } = this.props;
    printIncomeAssetForm(
      organizationId,
      applicationId,
      applicantId,
      propertyId,
      affordableQualificationId,
      type,
      financiallyResponsibleMembersAndMinors[applicantId],
      programName,
    );
  };

  handleIncomeSubmit = (values: Object, annualIncome: string) => {
    const {
      applicantId,
      applicationId,
      profile,
      residentId,
      affordableQualificationId,
      programName,
      actions: { saveQualificationChecklist },
    } = this.props;
    saveQualificationChecklist(
      {
        incomeChecklist: values,
        annualIncome,
      },
      applicationId,
      applicantId,
      profile,
      residentId,
      affordableQualificationId,
      programName,
      true,
    );
  };

  handleAssetSubmit = (
    values: Object,
    monthlyIncomeFromAssets: string,
    imputedIncome: string,
  ) => {
    const {
      applicantId,
      applicationId,
      profile,
      residentId,
      affordableQualificationId,
      programName,
      actions: { saveQualificationChecklist },
    } = this.props;
    saveQualificationChecklist(
      {
        assetChecklist: values,
        monthlyIncomeFromAssets,
        imputedIncome,
      },
      applicationId,
      applicantId,
      profile,
      residentId,
      affordableQualificationId,
      programName,
      false,
    );
  };

  goBack = () => {
    const { applicationId, profile, residentId, programName } = this.props;
    const urlProgramName = (programName ?? '').replace(/\//g, '_');
    const id = residentId ? residentId : applicationId;
    this.props.history.push(
      getUrlWithSelectedPropertyId(`/${profile}/${id}/?tab=${urlProgramName}`),
    );
  };

  fetchData = async () => {
    const {
      organizationId,
      propertyId,
      affordableQualificationId,
      programName,
      selectedProperty,
      applicantId,
      residentId,
      match: { path },
      flags: { rolloverIncomeAndAssets },
    } = this.props;
    const { physicalState: state } = selectedProperty;
    const aq = await this.fetchAffordableQualification(
      organizationId,
      propertyId,
      affordableQualificationId,
    );

    const effectiveDate = aq?.voucherEffectiveDate;
    const today = moment();
    const isTodayAfter2024 = today.isAfter('2024-01-01');
    let hotmaStatus = null;

    if (isTodayAfter2024 || !isEmpty(effectiveDate ?? '')) {
      hotmaStatus = await this.fetchStateProgramHotmaStatus({
        programName,
        effectiveDate: effectiveDate ?? today.toISOString(),
        state,
      });
    }

    const applicant = await this.fetchApplicantData(
      organizationId,
      propertyId,
      applicantId,
    );

    const currentAffordableProgram = getCurrentTabAffordableProgram(
      programName,
      selectedProperty?.pap,
    );

    let incomeAndAssetsDataFromMostRecentCompletedAQ = undefined;
    const isRecertification =
      aq?.certificationType === CERTIFICATION_TYPES.RECERT;
    if (rolloverIncomeAndAssets && isRecertification) {
      const formCompletionStatuses =
        await this.fetchIncomeAndAssetsCompletionStatuses(
          organizationId,
          propertyId,
          aq?.householdId,
          currentAffordableProgram?.id,
          applicantId,
        );

      const qualForm = /income-verification/gi.test(path)
        ? 'incomeVerification'
        : 'assetInformation';

      incomeAndAssetsDataFromMostRecentCompletedAQ = formCompletionStatuses
        ? await this.fetchResidentIncomeAndAssetsDataFromMostRecentCompletedAQ(
            selectedProperty.setup,
            propertyId,
            residentId,
            applicantId,
            aq?.householdId,
            programName,
            formCompletionStatuses,
            qualForm,
          )
        : undefined;
    }

    return {
      affordableQualification: aq,
      isHotmaActive: hotmaStatus?.hasAdoptedHOTMA ?? false,
      applicant,
      incomeAndAssetsDataFromMostRecentCompletedAQ,
    };
  };

  /**
   *
   * @param {*} param
   * programName (LIHTC, HUD, etc.)
   * state (US-TN, US-CA, etc.)
   * effectiveDate (YYYY-MM-DD)
   * @returns
   */
  fetchStateProgramHotmaStatus = async ({
    programName,
    state,
    effectiveDate,
  }) => {
    const res = await this.stateProgramConfigService.determineHotmaStatus({
      programName,
      state,
      effectiveDate,
    });
    return res;
  };

  fetchAffordableQualification = async (
    organizationId,
    propertyId,
    affordableQualificationId,
  ) => {
    const affordableQual = await this.affordableQualificationService.getById(
      organizationId,
      propertyId,
      affordableQualificationId,
    );
    return affordableQual;
  };

  fetchApplicantData = async (organizationId, propertyId, applicantId) => {
    const applicant = await this.applicantService.getApplicant(
      organizationId,
      propertyId,
      applicantId,
    );
    return applicant;
  };

  /**
   * Will fetch the income&assets completion statuses for the current household member
   * ---
   * @note Another endpoint based on the one used here could be created to fetch only the necessary data, however
   * I won't invest time analysing the BE data to make it more concize and creating a new endpoint (for this case)
   * since I haven't noticed performance issues regarding the extra data
   * @param {*} organizationId
   * @param {*} propertyId
   * @param {*} householdId
   * @param {*} propertyAffordableProgramId
   * @returns
   */
  fetchIncomeAndAssetsCompletionStatuses = async (
    organizationId,
    propertyId,
    householdId,
    propertyAffordableProgramId,
    applicantId,
  ): Promise<Object | null> => {
    const data =
      await this.houseHoldProfileService.getHouseholdProfileAffordableQualificationsByProgram(
        organizationId,
        propertyId,
        householdId,
        propertyAffordableProgramId,
      );
    const filteredAffordableQualifications =
      data.affordableQualifications?.filter(
        ({ qualificationData: { isActive, recertificationStarted } }) =>
          isActive || recertificationStarted,
      );
    const currentAffordableQualification =
      filteredAffordableQualifications?.find(
        ({ qualificationData: { isActive } }) => isActive,
      ) || {};
    const householdMembers = filterHouseholdMembers(
      currentAffordableQualification.eventHouseholdMembers,
    );
    const currentHouseholdMember = householdMembers.find(
      (member) => member.applicantId === applicantId,
    );
    return currentHouseholdMember
      ? {
          completedAssetForm: currentHouseholdMember?.completedAssetForm,
          completedIncomeForm: currentHouseholdMember?.completedIncomeForm,
        }
      : null;
  };

  /**
   * Will fetch the income and assets data necessary to fill the form when starting a RECERT
   * ---
   * @note this will only be used when rolloverIncomeAndAssets flag is active and the property
   * is configured respectively
   * @param {*} propertyId
   * @param {*} residentId
   * @param {*} householdId
   * @param {*} affodableProgramName
   * @returns
   */
  fetchResidentIncomeAndAssetsDataFromMostRecentCompletedAQ = async (
    affordableSetup,
    propertyId,
    residentId,
    applicantId,
    householdId,
    currentTabAffordableProgram,
    formCompletionStatuses,
    qualForm,
  ): Promise<Object> => {
    const autorollOverIncomeAndAssetsSetup = {
      HUD: affordableSetup.HUDAutoRolloverIncomeAndAssets,
      LIHTC: affordableSetup.LIHTCAutoRolloverIncomeAndAssets,
      HOME: affordableSetup.HOMEAutoRolloverIncomeAndAssets,
      RD: affordableSetup.RDAutoRolloverIncomeAndAssets,
    };
    const autofillIncomeOrAssetsForm =
      qualForm === 'incomeVerification'
        ? !formCompletionStatuses?.completedIncomeForm
        : !formCompletionStatuses?.completedAssetForm;

    const autofillForm =
      autorollOverIncomeAndAssetsSetup[currentTabAffordableProgram] &&
      autofillIncomeOrAssetsForm;

    const incomeAndAssetsDataFromMostRecentCompletedAQ = autofillForm
      ? await this.residentService.getResidentIncomeAndAssetsDataFromMostRecentCompletedAQ(
          propertyId,
          residentId,
          applicantId,
          householdId,
          currentTabAffordableProgram,
        )
      : [];
    return incomeAndAssetsDataFromMostRecentCompletedAQ[0];
  };

  render() {
    const {
      states,
      incomeTypes,
      applicationChecklists,
      isMinor,
      applicantId,
      affordableQualificationId,
      match: { path },
      intl,
      userPermissions,
      isSubmitting,
      selectedProperty,
      flags,
      wasAssetFormEdited,
      wasIncomeFormEdited,
    } = this.props;

    const qualForm = /income-verification/gi.test(path)
      ? 'incomeVerification'
      : 'assetInformation';

    const hasCompliancePermission = !!find(
      propEq('scope', 'compliance-approval'),
    )(userPermissions);
    return (
      <UseReactQuery
        keyName={`fetchDataForIncomeAssetForm-${affordableQualificationId}`}
        fn={() => this.fetchData()}
        options={{ refetchOnWindowFocus: false }}
      >
        {({ data, isLoading, isError, refetch }) => {
          if (isError)
            return (
              <Grid container align="center" justify="center">
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '12px' }}>
                    <FormattedMessage {...messages.loadError} />
                    <button onClick={refetch}>
                      <FormattedMessage {...messages.retry} />
                    </button>
                  </Typography>
                </Grid>
              </Grid>
            );
          if (isLoading) return <Spinner />;
          const {
            affordableQualification,
            isHotmaActive,
            incomeAndAssetsDataFromMostRecentCompletedAQ,
          } = data;

          const currentApplicationChecklists = applicationChecklists
            ? applicationChecklists
            : [];
          const { incomeChecklist, assetChecklist } =
            parseAffordableQualificationChecklistValues({
              currentApplicationChecklists,
              applicantId,
              affordableQualificationId,
              incomeAndAssetsDataFromMostRecentCompletedAQ,
              indicators: {
                isHotmaActive,
                rolloverIncomeAndAssets: flags.rolloverIncomeAndAssets,
                isRecertification:
                  affordableQualification?.certificationType ===
                  CERTIFICATION_TYPES.RECERT,
              },
            });
          const initialStateValues = {};
          Object.keys(STATE_INITIAL_INCOME_VALUES).forEach((key) => {
            initialStateValues[key] = pathOr(
              '',
              ['value'],
              states.find((s) => s.value !== 'default'),
            );
          });
          const defaultOtherIncomeType = pathOr(
            '',
            ['value'],
            incomeTypes.find((s) => s.value !== 'default'),
          );
          const initialIncomeValues = {
            ...INITIAL_INCOME_VALUES,
            ...initialStateValues,
            ...incomeChecklist,
            otherIncomeType: pathOr(
              defaultOtherIncomeType,
              ['otherIncomeType'],
              incomeChecklist,
            ),
          };
          // REPLACE TRUE WITH FLAG
          const initialAssetFormValues = true
            ? ALTERNATE_FORM_INITIAL_VALUES
            : INITIAL_ASSET_VALUES;
          const initialAssetValues = {
            ...initialAssetFormValues,
            ...assetChecklist,
            disposedDate: assetChecklist.disposedDate
              ? moment(assetChecklist.disposedDate).format('MM-DD-YYYY')
              : null,
          };
          const isAltForm = getIsAltForm(
            qualForm,
            {
              incomeChecklist,
              assetChecklist,
            },
            flags,
          );
          const isFormDisabled =
            COMPLIANCE_APPROVAL_IDS_TO_DISABLE_FORMS.includes(
              affordableQualification?.complianceApprovalId,
            ) ||
            (flags?.affordableTransfers &&
              affordableQualification?.certificationType ===
                CERTIFICATION_TYPES.UNIT_TRANSFER);
          return (
            <div className="container-fluid padtop30">
              <div className="row">
                <div className="col-xs-6 col-sm-12">
                  <a className="btn-text" onClick={this.goBack}>
                    <i className="et-chevron-left" />
                    <FormattedMessage {...messages.goBackToProfile} />
                  </a>
                </div>
              </div>
              {qualForm === 'incomeVerification' ? (
                isAltForm ? (
                  <IncomeVerificationAlternateForm
                    intl={intl}
                    states={states}
                    incomeTypes={incomeTypes}
                    onSubmit={this.handleIncomeSubmit}
                    initialValues={initialIncomeValues}
                    applicantName={data?.applicant?.name ?? ''}
                    isMinor={isMinor[applicantId]}
                    hasCompliancePermission={hasCompliancePermission}
                    isSubmitting={isSubmitting}
                    handlePrintIncomeAssetForm={this.handlePrintIncomeAssetForm}
                    selectedProperty={selectedProperty}
                    isFormDisabled={isFormDisabled}
                    flags={flags}
                    wasFormEdited={wasIncomeFormEdited}
                  />
                ) : (
                  <IncomeVerificationForm
                    intl={intl}
                    states={states}
                    incomeTypes={incomeTypes}
                    onSubmit={this.handleIncomeSubmit}
                    initialValues={initialIncomeValues}
                    applicantName={data?.applicant?.name ?? ''}
                    hasCompliancePermission={hasCompliancePermission}
                    isSubmitting={isSubmitting}
                    handlePrintIncomeAssetForm={this.handlePrintIncomeAssetForm}
                    selectedProperty={selectedProperty}
                    isFormDisabled={isFormDisabled}
                  />
                )
              ) : (
                <AssetInformationForm
                  intl={intl}
                  onSubmit={this.handleAssetSubmit}
                  initialValues={initialAssetValues}
                  applicantName={data?.applicant?.name ?? ''}
                  hasCompliancePermission={hasCompliancePermission}
                  isSubmitting={isSubmitting}
                  handlePrintIncomeAssetForm={this.handlePrintIncomeAssetForm}
                  selectedProperty={selectedProperty}
                  isAltForm={isAltForm}
                  isFormDisabled={isFormDisabled}
                  isHotmaActive={isHotmaActive}
                  flags={flags}
                  wasFormEdited={wasAssetFormEdited}
                />
              )}
            </div>
          );
        }}
      </UseReactQuery>
    );
  }
}

export const mapStateToProps = (state: Object, ownProps: Object): Object => {
  const {
    qualification: {
      isMinor = {},
      applicationId,
      applicationChecklists,
      profile,
      residentId,
      householdId,
      isSubmitting,
      financiallyResponsibleMembersAndMinors,
    },
    app: { currentUser, selectedProperty },
  } = state;
  return {
    affordableQualificationId: ownProps.match.params.affordableQualificationId,
    applicantId: ownProps.match.params.applicantId,
    programName: (ownProps.match.params.programName ?? '').replace(/_/g, '/'),
    states: getStateOptions(state),
    incomeTypes: getIncomeTypeOptions(state),
    financiallyResponsibleMembersAndMinors,
    isMinor,
    applicationId,
    applicationChecklists,
    profile,
    residentId,
    householdId,
    userPermissions: pathOr([], ['permissions'], currentUser),
    isSubmitting,
    organizationId: pathOr([], ['organizationId'], selectedProperty),
    propertyId: pathOr([], ['id'], selectedProperty),
    selectedProperty,
    wasAssetFormEdited: isDirty('assetInformation')(state),
    wasIncomeFormEdited: isDirty('incomeVerification')(state),
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    actions: bindActionCreators(
      {
        getAllStates,
        getAllIncomeTypes,
        getOneResident,
        saveQualificationChecklist,
        printIncomeAssetForm,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(injectIntl(Qualification)));
