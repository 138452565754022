import {
  put,
  takeLatest,
  call,
  select,
  all,
  fork,
  take,
} from 'redux-saga/effects';
import moment from 'moment';
import { actions as toastrActions } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';

import ApplicantFormService from '../../services/applicantFormService';
import * as ActionTypes from './constants';
import { GET_ONE_QUALIFICATION_CHECKLIST_SUCCESS } from '../QualificationForms/constants';
import * as primaryFormActions from './actions';

import { fetchGetOneQualificationChecklistSaga } from '../QualificationForms/sagas';
import messages from './messages';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import type { Saga } from 'redux-saga';
import type { Action } from '../App/types';

import {
  fetchGetAllNameSuffixes,
  fetchGetAllCountries,
  fetchGetAllStates,
  fetchGetAllEmploymentStatus,
  fetchGetAllAffordableRelationships,
  fetchGetAllPetTypes,
  promptPrintDialogSaga,
} from '../App/sagas';

import FileSaver from 'file-saver';

export function* fetchgetApplicantPrimaryFormInformation(
  action: Action<string>,
): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const applicantFormService = new ApplicantFormService();
    const response = yield call(
      applicantFormService.getApplicantPrimaryFormInformation,
      action.payload,
      organizationId,
      selectedProperty.id,
    );
    yield put(
      primaryFormActions.getApplicantPrimaryFormInformationSuccess(response),
    );
  } catch (err) {
    yield put(primaryFormActions.getApplicantPrimaryFormInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getApplicantPrimaryFormInformation(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICANT_PRIMARY_FORM_INFORMATION,
    fetchgetApplicantPrimaryFormInformation,
  );
}

export function* fetchSavePrimaryForm(action: Action<any>): Saga<void> {
  try {
    const successMessage = action.payload.applicant.complete
      ? messages.completionSuccessDescription
      : messages.progressSuccessDescription;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const applicantFormService = new ApplicantFormService();
    yield applicantFormService.savePrimaryForm(
      action.payload.applicant,
      action.payload.applicantId,
      organizationId,
      selectedProperty.id,
    );
    yield put(primaryFormActions.savePrimaryFormSuccess());
    yield put(
      push(
        getUrlWithSelectedPropertyId(
          `/application/${action.payload.applicationId}/?tab=5`,
        ),
      ),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(successMessage),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(primaryFormActions.savePrimaryFormError());
  }
}

export function* savePrimaryForm(): Saga<void> {
  yield takeLatest(ActionTypes.SAVE_PRIMARY_FORM, fetchSavePrimaryForm);
}

export function* fetchAllInformation(action: Action<any>): Saga<void> {
  yield all([
    call(fetchgetApplicantPrimaryFormInformation, action),
    call(fetchGetAllNameSuffixes),
    call(fetchGetAllCountries),
    call(fetchGetAllStates),
    call(fetchGetAllEmploymentStatus),
    call(fetchGetAllAffordableRelationships),
    call(fetchGetAllPetTypes),
  ]);
}

export function* getAllPrimaryFormInformation(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_ALL_APPLICANT_PRIMARY_FORM_INFORMATION,
    fetchAllInformation,
  );
}

export function* fetchWindowPrintApplicationForm(
  action: Action<any>,
): Saga<void> {
  const { isAffordable } = action.payload;
  if (isAffordable) {
    yield fork(fetchGetOneQualificationChecklistSaga, action);
    yield take([GET_ONE_QUALIFICATION_CHECKLIST_SUCCESS]);
    yield call(promptPrintDialogSaga);
  } else {
    yield call(promptPrintDialogSaga);
  }
}

export function* fetchPrintApplicationForm({ payload }: Object): any {
  try {
    const {
      applicantId,
      isPrimary,
      isSecondary,
      applicantFirstName,
      applicantLastName,
      isAffordable,
      includeIncomeAndAssets,
    } = payload;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const applicantFormService = new ApplicantFormService();
    const date = moment().format('YYYY-MM-DD');
    const fileName = !isAffordable
      ? `${date}_${applicantFirstName}_${applicantLastName}_Online_Application.pdf`
      : `${date}_${applicantFirstName}_${applicantLastName}_Online_Application_Affordable.pdf`;
    const response = yield applicantFormService.downloadForm(
      organizationId,
      selectedProperty.id,
      applicantId,
      includeIncomeAndAssets,
      isPrimary,
      isSecondary,
    );
    FileSaver.saveAs(response, fileName);
    yield put(primaryFormActions.printApplicationFormSuccess());
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: 'Error while downloading',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(primaryFormActions.printApplicationFormError());
  }
}

export function* printApplicationform(): Saga<void> {
  yield takeLatest(
    ActionTypes.PRINT_APPLICATION_FORM,
    fetchPrintApplicationForm,
  );
}

export function* printWindowApplicationform(): Saga<void> {
  yield takeLatest(
    ActionTypes.PRINT_WINDOW_APPLICATION_FORM,
    fetchWindowPrintApplicationForm,
  );
}

export default [
  getApplicantPrimaryFormInformation,
  savePrimaryForm,
  getAllPrimaryFormInformation,
  printApplicationform,
  printWindowApplicationform,
];
