import React, { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { OverviewIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import messages from './messages';

const useModalProps = () => {
  const { open, openModal, closeModal } = useModal(false);
  const [modalType, setModalType] = useState(null);
  const [modalState, setModalState] = useState({});
  const handleOpen = useCallback(
    (modalType, modalState) => {
      setModalType(modalType);
      openModal();
      if (Object.keys(modalState).length) {
        setModalState({
          ...modalState,
        });
      }
    },
    [openModal],
  );

  const handleClose = useCallback(() => {
    setModalType(null);
    closeModal();
    setModalState({});
  }, [closeModal]);

  return {
    title: modalState.applicantName ? (
      <FormattedMessage
        {...messages.certHistoryFor}
        values={{ name: modalState.applicantName ?? '' }}
      />
    ) : null,
    icon: <OverviewIcon />,
    onClose: handleClose,
    actionsProps: [{ children: 'Close', onClick: handleClose }],
    handleOpen,
    modalState,
    modalType,
    open,
  };
};

export default useModalProps;
