import { get } from '../utils/api';

export class MasterLeaseTermsService {
  getAllMasterLeaseTerms(organizationId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return get(`/${organizationId}/master-lease-terms`, options);
  }
}
