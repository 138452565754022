import * as ActionTypes from './constants';
import type { Role, Permission } from '../App/types';

export function getRole(roleId: string): * {
  return {
    type: ActionTypes.GET_ROLE,
    roleId,
  };
}

export function getRoleSuccess(role: Role): * {
  return {
    type: ActionTypes.GET_ROLE_SUCCESS,
    payload: role,
  };
}

export function editRole(roleId: string, role: Role): * {
  return {
    type: ActionTypes.EDIT_ROLE,
    payload: { roleId, role },
  };
}

export function editRoleSuccess(): * {
  return {
    type: ActionTypes.EDIT_ROLE_SUCCESS,
  };
}

export function editRoleFail(error: Error): * {
  return {
    type: ActionTypes.EDIT_ROLE_FAIL,
    payload: error,
  };
}

export function setSelectedPermissions(permissions: Array<Permission>): * {
  return {
    type: ActionTypes.SET_SELECTED_PERMISSIONS,
    payload: permissions,
  };
}

export function showNonePermissionsSelectedError(
  nonePermissionsSelected: boolean,
): * {
  return {
    type: ActionTypes.NONE_PERMISSIONS_SELECTED,
    payload: nonePermissionsSelected,
  };
}

export function showRolePermitsAssignees(rolePermitAssgnees: boolean): * {
  return {
    type: ActionTypes.SHOW_ROLE_PERMIT_ASSIGNEED,
    payload: rolePermitAssgnees,
  };
}
