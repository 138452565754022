import React from 'react';
import { createConfirmation, confirmable } from 'react-confirm';
import { Modal } from 'react-bootstrap';

type Props = {
  formatMessage: Function,
  show: boolean,
  dismiss: Function,
  modalHeader: Function,
  modalBody: Function,
  modalFooter: Function
};

type InjectedProps = {
  formatMessage: Function,
  modalHeader: Function,
  modalBody: Function,
  modalFooter: Function
};

export const GenericModal = ({
  modalHeader,
  modalBody,
  modalFooter,
  dismiss,
  show
}: Props) => (
  <Modal
    className="modal transaction"
    backdrop
    bsSize="sm"
    show={show}
    onHide={dismiss}
    data-test="generic-modal"
  >
    {modalHeader()}
    {modalBody(dismiss)}
    {modalFooter(dismiss)}
  </Modal>
);

const DecoratedGenericModal = confirmable(GenericModal);

const dialog = createConfirmation(DecoratedGenericModal);

const viewGenericModal = function({
  formatMessage,
  modalHeader,
  modalBody,
  modalFooter
}: InjectedProps): void {
  dialog({
    formatMessage,
    modalHeader,
    modalBody,
    modalFooter
  });
};

export default viewGenericModal;
