import * as ActionTypes from './constants';
import type { Action } from '../App/types';

export function getAllResidents() {
  return {
    type: ActionTypes.GET_ALL_RESIDENTS,
  };
}

export function getAllProspects(prospectStatusList: Array<Object>) {
  return {
    type: ActionTypes.GET_ALL_PROSPECTS,
    payload: { prospectStatusList },
  };
}

export function getAllApplicants() {
  return {
    type: ActionTypes.GET_ALL_APPLICANTS,
  };
}

export function getAllApplicantsSuccess(applicants: Array<any>) {
  return {
    type: ActionTypes.GET_ALL_APPLICANTS_SUCCESS,
    payload: applicants,
  };
}

export function getAllProspectsSuccess(prospects: Array<any>) {
  return {
    type: ActionTypes.GET_ALL_PROSPECTS_SUCCESS,
    payload: prospects,
  };
}

export function getAllResidentsSuccess(residents: Array<any>) {
  return {
    type: ActionTypes.GET_ALL_RESIDENTS_SUCCESS,
    payload: residents,
  };
}

export function setSelectedResidents(residents: Array<any>) {
  return {
    type: ActionTypes.SET_SELECTED_RESIDENTS,
    payload: residents,
  };
}

export function getDocumentTypes(): Action<any> {
  return {
    type: ActionTypes.GET_DOCUMENT_TYPES,
    payload: undefined,
  };
}

export function getDocumentTypesSuccess(
  documentTypes: Array<any>,
): Action<any> {
  return {
    type: ActionTypes.GET_DOCUMENT_TYPES_SUCCESS,
    payload: documentTypes,
  };
}

export function generateCommunicationBatch({
  communicationType,
  customerStatus,
  recipients,
  emailLetter,
  payload,
}: Object) {
  return {
    type: ActionTypes.GENERATE_COMMUNICATION_BATCH,
    payload: {
      communicationType,
      customerStatus,
      recipients,
      emailLetter,
      ...payload,
    },
  };
}

export function generateCommunicationBatchSuccess(payload: Object) {
  return {
    type: ActionTypes.GENERATE_COMMUNICATION_BATCH_SUCCESS,
    payload,
  };
}

export function generateCommunicationBatchError(payload: Object) {
  return {
    type: ActionTypes.GENERATE_COMMUNICATION_BATCH_FAIL,
    payload,
  };
}

export function resetCommunicationsGenerated() {
  return {
    type: ActionTypes.RESET_COMMUNICATIONS_GENERATED,
  };
}

export function resetLists() {
  return {
    type: ActionTypes.RESET_LISTS,
  };
}

export function downloadCommunication(
  communicationId: string,
  documentName: string,
): Action<any> {
  return {
    type: ActionTypes.DOWNLOAD_COMMUNICATION,
    payload: { communicationId, documentName },
  };
}

export function downloadCommunicationSuccess() {
  return {
    type: ActionTypes.DOWNLOAD_COMMUNICATION_SUCCESS,
  };
}

export function downloadCommunicationFail(payload: Object) {
  return { type: ActionTypes.DOWNLOAD_COMMUNICATION_FAIL, payload };
}

export function getValidateRecipients(payload: Object) {
  return {
    type: ActionTypes.GET_VALIDATE_RECPIENTS,
    payload,
  };
}

export function getValidateRecipientsSuccess(payload: Object) {
  return {
    type: ActionTypes.GET_VALIDATE_RECPIENTS_SUCCESS,
    payload,
  };
}

export function getValidateRecipientsError(payload: Object) {
  return {
    type: ActionTypes.GET_VALIDATE_RECPIENTS_ERROR,
    payload,
  };
}

export function getValidateEmail(payload: Object) {
  return {
    type: ActionTypes.GET_VALIDATE_EMAIL,
    payload,
  };
}

export function getValidateEmailSuccess(payload: Object) {
  return {
    type: ActionTypes.GET_VALIDATE_EMAIL_SUCCESS,
    payload,
  };
}

export function getValidateEmailError(payload: Object) {
  return {
    type: ActionTypes.GET_VALIDATE_EMAIL_ERROR,
    payload,
  };
}

export function showNoDataToastr(): Action<any> {
  return {
    type: ActionTypes.SHOW_NO_DATA_TOASTR,
    payload: undefined,
  };
}
