const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class TaskService {
  getAll(propertyId: string, orgId: string) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/properties/${propertyId}/activity`, options);
  }
}

export default TaskService;
