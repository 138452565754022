import React, { useState } from 'react';
import { TextInput } from '../../ViewProperty/BasicDetails/TextInput';

type Props = {
  required: boolean,
  rest: any[],
};

export const TouchedTextInput = ({ required, ...rest }: Props) => {
  const [touched, setTouched] = useState(false);

  return (
    <TextInput
      {...rest}
      required={touched && required}
      onBlur={() => {
        setTouched(true);
      }}
      className="input-lg form-control"
    />
  );
};
