import * as R from 'ramda';
import moment from 'moment';

const getIndex = (name: string) => {
  const indexOfLeftBrace = name.indexOf('[');
  const indexOfRightBrace = name.indexOf(']');
  const length = indexOfRightBrace - indexOfLeftBrace - 1;
  return Number(name.substr(indexOfLeftBrace + 1, length));
};

export function afterPreviousStartDate(
  value: any,
  values: Object,
  props: Object,
  name: string,
) {
  const dateValue = typeof value === 'string' ? moment(value) : value;
  const indexOfPreviousStartDate = getIndex(name) - 1;

  if (indexOfPreviousStartDate < 0) return;

  const prevStartDate = R.path(
    ['rentSchedules', indexOfPreviousStartDate, 'startDate'],
    values,
  );

  if (!prevStartDate) return;

  const momentPrevStartDate =
    typeof prevStartDate === 'string' ? moment(prevStartDate) : prevStartDate;

  return !dateValue.isAfter(momentPrevStartDate, 'day')
    ? 'Must be after previous Start Date.'
    : undefined;
}

export function beforeLeaseEndDate(
  value: any,
  values: Object,
  props: Object,
  name: string,
) {
  const dateValue = typeof value === 'string' ? moment(value) : value;

  if (!props.leaseEndDate) return;

  const leaseEndDate = moment(props.leaseEndDate);

  if (!leaseEndDate.isValid()) return;

  return dateValue.isAfter(leaseEndDate, 'day')
    ? 'Cannot be after Lease End Date.'
    : undefined;
}

export function greaterThanLastRentMonthlyAmount(
  value: any,
  values: Object,
  props: Object,
  name: string,
) {
  const numValue = Number(value);

  const lastRentMonthlyAmount = Number(
    R.prop(
      // $FlowFixMe
      'monthlyAmount',
      R.last(values.rentSchedules),
    ),
  );

  if (isNaN(lastRentMonthlyAmount)) return;

  return numValue <= lastRentMonthlyAmount
    ? "Must be greater than the last rent's Monthly Amount."
    : undefined;
}
