import { TextingIndicator } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import FormattedDateOrDashes from '../../components/FormattedDateOrDashes';
import PaginationFooter from '../../components/PaginationFooter/index.js';
import {
  getUrlWithSelectedPropertyId,
  navigateToUrlWithSelectedPropertyId,
} from '../../utils/navigation-helpers';
import type { OrderValue, PaginationMeta, Property } from '../App/types.js';
import PriorResidentDetails from './PriorResidentDetails/index.js';
import SearchBox from './SearchBox/index.js';
import {
  cleanLoadedPriorResidents,
  getAllPriorResidents,
  updateColumnsSortValue,
} from './actions.js';
import { injectConversations } from './hooks.js';
import messages from './messages.js';
import type { ManagePriorResidentColumnOrder, PriorResidents } from './types';

type SearchFormValues = {
  searchText: string,
};

type StateProps = {
  headers: Array<Object>,
};

type Props = {
  intl: any,
  history: Object,
  actions: Object,
  priorResidents: Array<PriorResidents>,
  meta: PaginationMeta,
  columnOrder: ManagePriorResidentColumnOrder,
  selectedProperty: Property,
  householdConversationsStatusDictionary: Object,
  showTextingColumn: boolean,
};

type State = {
  currentPage: number,
  limit: number,
  searchText: string,
  currentSorting: OrderValue,
};

export class ManagePriorResidents extends React.Component<
  StateProps & Props,
  State,
> {
  constructor() {
    super();
    this.state = {
      currentPage: 1,
      limit: 25,
      searchText: '',
      currentSorting: {
        fieldName: '',
        order: '',
      },
    };
  }

  static defaultProps = {
    headers: [
      {
        id: 'priorTenants',
        title: <FormattedMessage {...messages.priorTenants} />,
        order: null,
      },
      {
        id: 'priorResidents',
        title: <FormattedMessage {...messages.priorResidents} />,
        order: null,
      },
      {
        id: 'texting',
        title: <FormattedMessage {...messages.texting} />,
        order: null,
      },
      {
        id: 'unit',
        title: <FormattedMessage {...messages.unit} />,
        order: null,
      },
      {
        id: 'moveInDate',
        title: <FormattedMessage {...messages.moveInDate} />,
      },
      {
        id: 'moveOutDate',
        title: <FormattedMessage {...messages.moveOutDate} />,
      },
      {
        id: 'FASDate',
        title: <FormattedMessage {...messages.FASDate} />,
      },
      {
        id: 'monthsLateFeesCharges',
        title: <FormattedMessage {...messages.lateFees} />,
        order: null,
      },
      {
        id: 'underEviction',
        title: <FormattedMessage {...messages.underEviction} />,
        order: null,
      },
      {
        id: 'doNotRenew',
        title: <FormattedMessage {...messages.doNotRenew} />,
        order: null,
      },
      {
        id: 'residentBalance',
        title: <FormattedMessage {...messages.residentBalance} />,
        order: null,
      },
      {
        id: 'subsidyBalance',
        title: <FormattedMessage {...messages.subsidyBalance} />,
        order: null,
      },
    ],
  };
  getHeaders(headers: any, removeHeaderId: string, showTextingColumn) {
    return headers.filter(
      (header) =>
        header.id !== removeHeaderId &&
        (header.id === 'texting' ? showTextingColumn : true),
    );
  }
  componentWillUnmount() {
    this.props.actions.cleanLoadedPriorResidents();
  }

  componentDidMount() {
    this.onPageChange(this.state.currentPage);
  }

  handleViewPriorResident = (id: string) => {
    navigateToUrlWithSelectedPropertyId(`/prior-resident/${id}`);
  };

  onPageChange = (nextPage: number) => {
    this.props.actions.getAllPriorResidents(
      nextPage,
      this.state.limit,
      this.state.currentSorting,
      this.state.searchText,
    );
    this.setState({
      currentPage: nextPage,
    });
  };

  handleSearchSubmit = ({ searchText = '' }: SearchFormValues) => {
    const currentPage = 1;
    this.props.actions.getAllPriorResidents(
      currentPage,
      this.state.limit,
      this.state.currentSorting,
      searchText,
    );
    this.setState({ searchText, currentPage });
  };

  handleSort = ({ fieldName, order }: OrderValue) => {
    const sorting = { fieldName, order };
    const currentSorting = { ...this.state.currentSorting, ...sorting };
    this.props.actions.getAllPriorResidents(
      this.state.currentPage,
      this.state.limit,
      currentSorting,
      this.state.searchText,
    );
    this.setState({ ...this.state, currentSorting });
  };

  parsePriorResidents = (
    priorResidents: Array<Object>,
    formatNumber: Function,
    isAllCommercial: boolean,
    householdConversationsStatusDictionary: Object,
    showTextingColumn: boolean,
  ): Array<Object> => {
    if (priorResidents.length > 0) {
      return priorResidents.map((priorResident) => {
        const householdConversationsStatus =
          householdConversationsStatusDictionary[priorResident.residentId];

        return {
          names: isAllCommercial ? (
            <a
              href={getUrlWithSelectedPropertyId(
                `/prior-resident/${priorResident.residentId}`,
              )}
            >
              {priorResident.firstName.trim()}
            </a>
          ) : (
            <a
              href={getUrlWithSelectedPropertyId(
                `/prior-resident/${priorResident.residentId}`,
              )}
            >
              {priorResident.priorActiveResidents &&
                priorResident.priorActiveResidents
                  .map((res) => res.applicant.name.trim())
                  .join(', ')}
            </a>
          ),
          ...(showTextingColumn
            ? {
                texting:
                  householdConversationsStatus.TOTAL > 0 ? (
                    <Link
                      to={getUrlWithSelectedPropertyId(
                        `/prior-resident/${priorResident.residentId}?tab=texting`,
                      )}
                    >
                      <TextingIndicator {...householdConversationsStatus} />
                    </Link>
                  ) : (
                    <TextingIndicator {...householdConversationsStatus} />
                  ),
              }
            : {}),
          unit: (
            <Link
              to={getUrlWithSelectedPropertyId(`/unit/${priorResident.unitId}`)}
            >
              {priorResident.unit}
            </Link>
          ),
          moveInDate: (
            <FormattedDateOrDashes value={priorResident.moveInDate} />
          ),
          moveOutDate: (
            <FormattedDateOrDashes value={priorResident.moveOutDate} />
          ),
          FASDate: <FormattedDateOrDashes value={priorResident.FASDate} />,
          monthsLateFeesCharges: priorResident.monthsLateFeesCharges
            ? priorResident.monthsLateFeesCharges
            : '',
          underEviction: priorResident.underEviction ? (
            <i className="icon et-alert-urgent text-red" />
          ) : (
            ''
          ),
          doNotRenew: priorResident.doNotRenew ? (
            <i className="icon et-alert-urgent text-red" />
          ) : (
            ''
          ),
          balance: formatNumber(priorResident.balance, {
            style: 'currency',
            currency: 'USD',
          }),
          subsidyBalance: formatNumber(priorResident.subsidyBalance, {
            style: 'currency',
            currency: 'USD',
          }),
        };
      });
    }
    return [];
  };

  buildRows = (rows: Array<Object>): any => {
    if (!rows) return [];
    return rows.map((row, i) => ({
      id: row.id ? row.id : i,
      columns: Object.values(row),
    }));
  };

  render() {
    const {
      intl,
      headers,
      meta,
      priorResidents,
      selectedProperty,
      householdConversationsStatusDictionary,
      showTextingColumn,
    } = this.props;
    const isAllCommercial =
      selectedProperty.hasCommercialFloorPlans === 'ALL' ? true : false;
    const rows = this.buildRows(
      this.parsePriorResidents(
        priorResidents,
        intl.formatNumber,
        isAllCommercial,
        householdConversationsStatusDictionary,
        showTextingColumn,
      ),
    );

    return (
      <DocumentTitle
        title={
          isAllCommercial
            ? intl.formatMessage(messages.docTitleCommercial)
            : intl.formatMessage(messages.docTitle)
        }
      >
        <div
          className="bodywrap container-fluid"
          data-test="manage-prior-residents"
        >
          <div className="row">
            <div className="col-xs-12">
              <div className="row section-header">
                <div className="col-xs-12 col-sm-4">
                  <h1>
                    {isAllCommercial
                      ? intl.formatMessage(messages.titleCommercial)
                      : intl.formatMessage(messages.title)}
                  </h1>
                </div>
                <div className="col-xs-12 col-sm-8">
                  <SearchBox onSubmit={this.handleSearchSubmit} />
                </div>
              </div>
              {isAllCommercial ? (
                <PriorResidentDetails
                  rows={rows}
                  headers={this.getHeaders(
                    headers,
                    'priorResidents',
                    showTextingColumn,
                  )}
                  name="PriorTenants"
                  onSort={this.handleSort}
                />
              ) : (
                <PriorResidentDetails
                  rows={rows}
                  headers={this.getHeaders(
                    headers,
                    'priorTenants',
                    showTextingColumn,
                  )}
                  name="PriorResidents"
                  onSort={this.handleSort}
                />
              )}
              {meta && meta.pageCount > 1 && (
                <PaginationFooter
                  currentPage={this.state.currentPage}
                  limit={this.state.limit}
                  count={meta.count}
                  totalCount={meta.totalCount}
                  pageCount={meta.pageCount}
                  onPageChange={this.onPageChange}
                />
              )}
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = ({
  app,
  managePriorResidents: { priorResidents, meta, columnOrder },
}: any): any => {
  return {
    priorResidents,
    columnOrder,
    meta,
    selectedProperty: app.selectedProperty,
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: bindActionCreators(
      {
        getAllPriorResidents,
        updateColumnsSortValue,
        cleanLoadedPriorResidents,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(injectConversations(ManagePriorResidents)));
