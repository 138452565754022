import { isEmpty } from 'ramda';
import messages from './messages';

export const validate = (values: Object, props: Object) => {
  const { intl } = props;
  const errors = {};
  const outOfRange = (feeValue: any) =>
    Number(feeValue) > 9999 ||
    Number(feeValue) < -9999 ||
    Number(feeValue) === 0;

  const { monthlyReqFees } = values;

  monthlyReqFees.forEach((fee, idx) => {
    if (fee.type === 'numeric' && (outOfRange(fee.value) || fee.value === '')) {
      errors['monthlyReqFees'] = [];
      errors[`monthlyReqFees[${idx}].value`] = intl.formatMessage(
        messages.invalidValue,
      );
    }
  });
  if (!isEmpty(errors)) {
    errors._error =
      'Amounts should be non-zero within a range of $-9999 to $9999. Negative amounts are only for amenities';
  }
  return errors;
};

export default validate;
