// $FlowFixMe
import { useEffect, useState } from 'react';
import VehiclesService from '../../services/vehiclesService';

export const useAsyncHouseholdVehicles = (
  selectedProperty: Object,
  householdId: string,
) => {
  const [householdVehicles, setHouseholdVehicles] = useState(null);
  const [updateVehicles, setUpdateVehicles] = useState(false);

  const updateHousholdVehicles = () => setUpdateVehicles(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchHouseholdVehicles = async () => {
      if (!householdVehicles || updateVehicles) {
        const { id, organizationId } = selectedProperty;
        const vehiclesService = new VehiclesService();
        const options = { signal: abortController.signal };
        const householdVehiclesRes = await vehiclesService.getAllHouseholdVehicles(
          organizationId,
          id,
          householdId,
          options,
        );
        setHouseholdVehicles(householdVehiclesRes);
        setUpdateVehicles(false);
      }
    };

    if (subscribed) {
      fetchHouseholdVehicles();
    }

    return () => {
      subscribed = false;
    };
  }, [
    selectedProperty,
    householdId,
    householdVehicles,
    setHouseholdVehicles,
    updateVehicles,
    setUpdateVehicles,
  ]);

  return [householdVehicles, updateHousholdVehicles];
};
