import { AddIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  TableHeader,
  Table,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useManageWorkOrdersV2 } from './hooks';
import useBaseTableProps from '../../hooks/useBaseTableProps';
import TableLayout from '../../components/TableLayout';
import messages from './messages';
import { ASSIGNE_INACTIVE_COLOR, PAST_DUE_COLOR } from './constants';
import ElementWithPermissions from '../../components/ElementWithPermissions';

function ManageWorkOrders() {
  const baseTableProps = useBaseTableProps();
  const { onCreateWorkOrderClick, ...props } = useManageWorkOrdersV2({
    intl: baseTableProps.intl,
  });

  return (
    <TableLayout name={props.name} childrenElementsHeight={80 + 8}>
      {(tableHeight) => (
        <>
          <TableHeader
            title={baseTableProps.intl.formatMessage(messages.tableTitle)}
            legend={[
              {
                label: baseTableProps.intl.formatMessage(
                  messages.assigneeInactiveLegendItem,
                ),
                color: ASSIGNE_INACTIVE_COLOR,
              },
              {
                label: baseTableProps.intl.formatMessage(
                  messages.pastDueLegendItem,
                ),
                color: PAST_DUE_COLOR,
              },
            ]}
            count={props.count}
            totalCount={props.totalCount}
            actions={
              <ElementWithPermissions scope={['workorder-create']}>
                <Button
                  variant="shout"
                  onClick={onCreateWorkOrderClick}
                  startIcon={<AddIcon />}
                >
                  {baseTableProps.intl.formatMessage(messages.createWorkOrder)}
                </Button>
              </ElementWithPermissions>
            }
          />
          <Table {...baseTableProps} {...props} height={tableHeight} />
        </>
      )}
    </TableLayout>
  );
}

export default ManageWorkOrders;
