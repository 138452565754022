import { defineMessages } from 'react-intl';

const messages = defineMessages({
  unitADA: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.ADAUnit',
    defaultMessage: 'Unit ADA?',
  },
  unitAddress: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.UnitAddress',
    defaultMessage: 'Unit Address',
  },
  confirmationTitle: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.ConfirmationTitle',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  confirmationCancel: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.ConfirmationCancel',
    defaultMessage: 'No',
  },
  confirmation: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.Confirmation',
    defaultMessage: 'Yes',
  },
  button: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.Button',
    defaultMessage: 'Edit',
  },
  errorHeader: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.ErrorHeader',
    defaultMessage: 'Error',
  },
  invalidZipCode: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.invalidZipCode',
    defaultMessage: 'Invalid Zip Code / Postal Code',
  },
  modalCancel: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.Cancel',
    defaultMessage: 'Cancel',
  },
  modalHeader: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.Header',
    defaultMessage: 'Edit General Information',
  },
  modalSubmit: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.Submit',
    defaultMessage: 'Save',
  },
  outOfRangeFloorLevel: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.outOfRangeFloorLevel',
    defaultMessage: 'Must be between 1 and 25',
  },
  success: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.success',
    defaultMessage: 'Success',
  },
  updateUnitSuccess: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.UpdateUnitSuccess',
    defaultMessage: 'Successfully updated Unit Information',
  },
  unitLevel: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.UnitLevel',
    defaultMessage: 'Unit Level',
  },
  unitFloorLevel: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.UnitFloorLevel',
    defaultMessage: 'Unit Floor Level',
  },
  required: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.Required',
    defaultMessage: 'Required',
  },
  altXMLUnitNumber: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditModal.AltXMLUnitNumber',
    defaultMessage: 'Alternative Unit Name (XML)',
  },
});

export default messages;
