import { createSelector } from 'reselect';
import {
  all,
  always,
  any,
  compose,
  concat,
  cond,
  equals,
  filter,
  find,
  isNil,
  none,
  path,
  pathOr,
  pick,
  prop,
  propEq,
  sortBy,
  toLower,
} from 'ramda';
import type { GlobalState } from '../App/types';
import moment from 'moment';
import type { HouseholdMember } from '../../services/residentService';
import {
  DEFAULT_RESIDENT_UNIT,
  leaseText,
  getSelectedLease,
  validField,
} from '../../utils/lease-helpers';
import { PICK_ARRAYS } from './constants';

export const getCurrentResidentState = (state: GlobalState) =>
  state.residentProfile;
const getManageDocumentsState = (state: GlobalState) => state.manageDocuments;
export const getAppState = (state: GlobalState) => state.app;

const getEditResidentHouseholdState = (state: GlobalState) =>
  state.editResidentHousehold;

export const getCurrentUser = createSelector(getAppState, ({ currentUser }) => {
  let permissions = [];
  if (currentUser && currentUser.permissions) {
    permissions = currentUser.permissions;
  }
  return { ...currentUser, permissions };
});

const parseGeneralInfo = (
  customer: Object,
  type: string,
  relationshipId: string,
  applicantType: Object,
  isPrimary: boolean = false,
) => {
  const suffixId = pathOr('', ['suffixId'], customer);
  const generalInformation = pick(
    PICK_ARRAYS[type].generalInformation,
    customer,
  );
  const adultFields =
    type !== 'adult'
      ? {}
      : {
          applicantTypeId: applicantType.id || 'default',
          relationshipId: isPrimary ? 'default' : relationshipId,
        };
  return {
    ...generalInformation,
    ...(generalInformation.preferredName && {
      preferredName: generalInformation.preferredName.trim(),
    }),
    ...(generalInformation.middleName && {
      middleName: generalInformation.middleName.trim(),
    }),
    suffixId,
    ...adultFields,
  };
};

const parsePersonalInfo = (
  applicantInfo: Object,
  customer: Object,
  type: string,
) => {
  const basePersonalInformation = pick(
    PICK_ARRAYS[type].personalInformation,
    customer,
  );
  if (type !== 'adult') {
    const dateOfBirth = basePersonalInformation.dateOfBirth
      ? moment(basePersonalInformation.dateOfBirth).format('MM/DD/YYYY')
      : null;
    return { ...basePersonalInformation, dateOfBirth };
  } else {
    const birthday = basePersonalInformation.birthday
      ? moment(basePersonalInformation.birthday).format('MM/DD/YYYY')
      : null;
    const maritalStatus = pathOr(
      '',
      ['applicantCustomer', 'maritalStatus'],
      applicantInfo,
    );
    return {
      ...basePersonalInformation,
      maritalStatus,
      birthday,
    };
  }
};

const parseAffordableInfo = (
  applicantInfo: Object,
  customer: Object,
  type: string,
) => {
  const affordableRelationshipId = pathOr(
    'default',
    ['affordableRelationshipId'],
    applicantInfo,
  );
  const affordableInformation = pick(
    PICK_ARRAYS[type].affordableInformation,
    customer,
  );
  const disability = pathOr(null, ['customerDisability'], customer);

  return {
    ...affordableInformation,
    disability,
    affordableRelationshipId,
  };
};

const parseResidentInfo = (
  applicantInfo: Object,
  path: Array<string>,
  type: string,
  isPrimary: boolean,
  relationshipId?: string = '',
  address?: Object = {},
) => {
  const customer = pathOr({}, path, applicantInfo);
  const applicantType = pathOr({}, ['applicantType'], applicantInfo);
  const generalInformation = parseGeneralInfo(
    customer,
    type,
    relationshipId,
    applicantType,
    isPrimary,
  );
  const personalInformation = parsePersonalInfo(applicantInfo, customer, type);
  const affordableInformation = parseAffordableInfo(
    applicantInfo,
    customer,
    type,
  );
  let id;
  let minorsResId;
  if (type === 'adult') {
    id = pathOr('', ['resident', 'id'], applicantInfo);
  } else if (type === 'minor') {
    id = pathOr('', ['applicantMinor', 'id'], applicantInfo);
    minorsResId = pathOr('', ['resident', 'id'], applicantInfo);
  }
  const adultObj = {
    id,
    minorsResId,
    type,
    generalInformation: { ...generalInformation, ...address },
    personalInformation,
    affordableInformation,
  };
  return type === 'adult' ? adultObj : { ...adultObj, minorsResId };
};

export const isCurrentResident = createSelector(
  getEditResidentHouseholdState,
  getCurrentResidentState,
  ({ household }) => {
    const currentResidents: HouseholdMember[] = household || [];
    const primaryResident = filter(
      // $FlowFixMe
      (a) => !isNil(a.rc) && pathOr(false, ['rc', 'isPrimary'], a) === true,
      currentResidents,
    );
    return primaryResident
      ? // $FlowFixMe
        pathOr(false, ['0', 'rc', 'customer', 'status'], primaryResident) !==
          'Prior Resident'
      : false;
  },
);

export const getHouseholdResidentMembers = createSelector(
  getEditResidentHouseholdState,
  getCurrentResidentState,
  ({ household }, residentState) => {
    // $FlowFixMe
    const applicants = pathOr(
      {},
      ['applicationInfo', 'applicants'],
      residentState,
    );
    // $FlowFixMe
    const referralType = pathOr(
      {},
      ['prospectInfo', 'referralType', 'name'],
      residentState,
    );
    let householdMembers = [];
    const currentResidents: HouseholdMember[] = household || [];
    const adultMembers = filter((a) => !isNil(a.rc), currentResidents).map(
      (adult) => {
        // $FlowFixMe
        const adultApplicantId = pathOr('', ['applicant', 'id'], adult);
        const applicantInfo = find(propEq('id', adultApplicantId))(applicants);
        const financiallyResponsible = pathOr(
          false,
          ['applicantType', 'financiallyResponsible'],
          applicantInfo,
        );
        // $FlowFixMe
        const plAccount = pathOr(
          null,
          ['applicantCustomer', 'customer', 'plAccount', 'customerId'],
          applicantInfo,
        );
        const customerId = pathOr(
          null,
          ['applicantCustomer', 'customer', 'id'],
          applicantInfo,
        );
        // $FlowFixMe
        const isPrimary = pathOr(false, ['rc', 'isPrimary'], adult);
        // $FlowFixMe
        const relationshipId = pathOr('default', ['relationshipId'], adult);
        const address = pathOr(
          null,
          ['applicantCustomer', 'address'],
          applicantInfo,
        );
        const residentInfo = parseResidentInfo(
          applicantInfo,
          ['applicantCustomer', 'customer'],
          'adult',
          isPrimary,
          relationshipId,
          address,
        );
        // $FlowFixMe
        const portalUser = pathOr({}, ['rc', 'customer', 'portalUser'], adult);
        const primaryForm = pathOr(null, ['primaryForm'], applicantInfo);
        const secondaryForm = pathOr(null, ['secondaryForm'], applicantInfo);
        const shortForm = pathOr(null, ['shortForm'], applicantInfo);
        const emailAddress = pathOr(
          null,
          ['applicantCustomer', 'customer', 'emailAddress'],
          applicantInfo,
        );

        let formData;
        if (primaryForm) {
          formData = { ...primaryForm, type: 'primary' };
        } else if (secondaryForm) {
          formData = { ...secondaryForm, type: 'secondary' };
        } else if (shortForm) {
          formData = { ...shortForm, type: 'short' };
        } else {
          formData = {};
        }
        return {
          id: adult.id,
          name: [
            adult.rc.customer.firstName,
            adult.rc.customer.lastName,
            adult.rc.customer.suffix ? adult.rc.customer.suffix.name : '',
          ].join(' '),
          relationship: adult.relationship ? adult.relationship.name : '---',
          relationshipId: adult?.relationship?.id,
          status: adult.status,
          portalUser,
          startDate: adult.startDate,
          endDate: adult.endDate,
          applicantType: applicantInfo?.applicantType?.name ?? '',
          applicantTypeId: applicantInfo?.applicantType?.id ?? '',
          type: 'adult',
          referralType,
          formData,
          residentInfo,
          financiallyResponsible,
          plAccount,
          customerId,
          emailAddress,
          isPrimary,
          firstName: adult.rc.customer.firstName,
          preferredName: adult.rc.customer.preferredName
            ? adult.rc.customer.preferredName
            : '---',
          // $FlowFixMe
          parentCompany: pathOr(
            '---',
            ['rc', 'customer', 'parentCompany'],
            adult,
          ),
        };
      },
    );
    const minorMembers = filter(
      (a) => !isNil(a.residentMinor),
      currentResidents,
    ).map((minor) => {
      // $FlowFixMe
      const minorApplicantId = pathOr('', ['applicant', 'id'], minor);
      const applicantInfo = find(propEq('id', minorApplicantId))(applicants);
      const residentInfo = parseResidentInfo(
        applicantInfo,
        ['applicantMinor'],
        'minor',
        false,
      );
      return {
        id: minor.id,
        name: [
          minor.residentMinor.firstName,
          minor.residentMinor.lastName,
          minor.residentMinor.suffix ? minor.residentMinor.suffix.name : '',
        ].join(' '),
        relationship: minor.relationship ? minor.relationship.name : 'Minor',
        status: minor.status,
        startDate: minor.startDate,
        endDate: minor.endDate,
        type: 'minor',
        residentInfo,
      };
    });
    const petMembers = filter(
      (a) => !isNil(a.residentPet),
      currentResidents,
    ).map((pet) => ({
      id: pet.id,
      name: `${pet.residentPet.name}`,
      relationship: pet.residentPet.petType
        ? pet.residentPet.petType.name
        : 'Pet',
      isServiceAnimal: pet.residentPet?.isServiceAnimal,
      status: pet.status,
      startDate: pet.startDate,
      endDate: pet.endDate,
      type: 'pet',
    }));
    householdMembers = concat(adultMembers, concat(minorMembers, petMembers));

    return householdMembers;
  },
);

export const getHouseholdMembers = createSelector(
  getCurrentResidentState,
  (resident) => {
    let householdMembers = [];
    if (resident) {
      const currentApplicants = resident.applicationInfo.applicants || [];
      const adultMembers = filter(
        (a) => !isNil(a.applicantCustomer),
        currentApplicants,
      ).map((adult) => ({
        id: adult.id,
        name: [
          adult.applicantCustomer.customer.firstName,
          adult.applicantCustomer.customer.lastName,
          adult.applicantCustomer.customer.suffix
            ? adult.applicantCustomer.customer.suffix.name
            : '',
        ].join(' '),
        relationship: adult.relationship ? adult.relationship.name : '---',
        type: 'adult',
      }));
      const minorMembers = filter(
        (a) => !isNil(a.applicantMinor),
        currentApplicants,
      ).map((minor) => ({
        id: minor.id,
        name: [
          minor.applicantMinor.firstName,
          minor.applicantMinor.lastName,
          minor.applicantMinor.suffix ? minor.applicantMinor.suffix.name : '',
        ].join(' '),
        relationship: minor.relationship ? minor.relationship.name : 'Minor',
        type: 'minor',
      }));
      const petMembers = filter(
        (a) => !isNil(a.applicantPet),
        currentApplicants,
      ).map((pet) => ({
        id: pet.id,
        name: `${pet.applicantPet.name}`,
        relationship: pet.applicantPet.petType
          ? pet.applicantPet.petType.name
          : '---',
        type: 'pet',
      }));
      householdMembers = concat(adultMembers, concat(minorMembers, petMembers));
    }

    return householdMembers;
  },
);

export const getHouseholdPendingMembers = createSelector(
  getCurrentResidentState,
  (resident) => {
    let householdMembers = [];
    if (resident) {
      // $FlowFixMe
      const pendingApplicants = pathOr(
        [],
        ['applicants', 'pendingApplicants'],
        resident,
      );
      // $FlowFixMe
      const canceledApplicants = pathOr(
        [],
        ['applicants', 'canceledApplicants'],
        resident,
      );
      const pendingAdultMembers = filter(
        (a) => !isNil(a.applicantCustomer),
        pendingApplicants,
      ).map((adult) => ({
        id: adult.id,
        name: [
          adult.applicantCustomer.customer.firstName,
          adult.applicantCustomer.customer.lastName,
          adult.applicantCustomer.customer.suffix
            ? adult.applicantCustomer.customer.suffix.name
            : '',
        ].join(' '),
        status: 'Pending',
        type: adult.applicantType.name,
        relationship: pathOr('---', ['relationship', 'name'], adult),
      }));
      const pendingPetMembers = filter(
        (a) => !isNil(a.applicantPet),
        pendingApplicants,
      ).map((pet) => ({
        id: pet.id,
        name: `${pet.applicantPet.name}`,
        relationship: pet.applicantPet.petType
          ? pet.applicantPet.petType.name
          : '---',
        type: 'pet',
        status: 'Pending',
      }));
      const canceledAdultMembers = filter(
        (a) => !isNil(a.applicantCustomer),
        canceledApplicants,
      ).map((adult) => ({
        id: adult.id,
        name: [
          adult.applicantCustomer.customer.firstName,
          adult.applicantCustomer.customer.lastName,
          adult.applicantCustomer.customer.suffix
            ? adult.applicantCustomer.customer.suffix.name
            : '',
        ].join(' '),
        status: 'Denied',
        type: adult.applicantType.name,
        relationship: pathOr('---', ['relationship', 'name'], adult),
      }));
      const canceledPetMembers = filter(
        (a) => !isNil(a.applicantPet),
        canceledApplicants,
      ).map((pet) => ({
        id: pet.id,
        name: `${pet.applicantPet.name}`,
        relationship: pet.applicantPet.petType
          ? pet.applicantPet.petType.name
          : '---',
        type: 'pet',
        status: 'Denied',
      }));
      householdMembers = [].concat.apply(
        [],
        [
          pendingAdultMembers,
          pendingPetMembers,
          canceledPetMembers,
          canceledAdultMembers,
        ],
      );
    }
    return householdMembers;
  },
);

export const getApprovedResidents = createSelector(
  getEditResidentHouseholdState,
  getCurrentResidentState,
  ({ household }, resident: any) => {
    let householdMembers = [];
    let currentResidents: HouseholdMember[] = household || [];

    const approvedApplicants = pathOr(
      [],
      ['applicants', 'approvedApplicants'],
      resident,
    );
    currentResidents = currentResidents.concat(approvedApplicants);
    const adultMembers = filter(
      (a) => !isNil(a.rc) && a.checklist,
      currentResidents,
    ).map((adult) => ({
      id: adult.id,
      name: [
        adult.rc.customer.firstName,
        adult.rc.customer.lastName,
        adult.rc.customer.suffix ? adult.rc.customer.suffix.name : '',
      ].join(' '),
      status: adult.status,
      type: adult.applicant.applicantType.name,
    }));
    const petMembers = filter(
      (a) => !isNil(a.residentPet) && a.checklist,
      currentResidents,
    ).map((pet) => ({
      id: pet.id,
      name: `${pet.residentPet.name}`,
      relationship: pet.residentPet.petType
        ? pet.residentPet.petType.name
        : 'Pet',
      status: pet.status,
      type: 'Pet',
    }));

    householdMembers = concat(adultMembers, petMembers);

    return householdMembers;
  },
);

export const getFinanciallyResponsibleFullNames = createSelector(
  getCurrentResidentState,
  (resident) =>
    resident
      ? resident.applicationInfo.applicants
          .filter(
            (a) =>
              a.applicantCustomer && a.applicantType.financiallyResponsible,
          )
          .map((a) => a.applicantCustomer)
          .map((ac) => ac.customer)
          .map(
            (c) =>
              `${c.firstName} ${c.middleName ? `${c.middleName} ` : ''}${
                c.lastName
              }${c.suffix ? ` ${c.suffix.name}` : ''}`,
          )
          .sort()
      : [],
);

export const getCurrentResidentId = createSelector(
  getCurrentResidentState,
  (resident) => path(['residentInfo', 'id'], resident),
);

export const getLateMethods = createSelector(
  getCurrentResidentState,
  (state) => {
    if (!state.lateMethods) {
      return [
        {
          value: 'default',
          text: 'Choose',
          disabled: true,
        },
      ];
    }
    return [
      {
        value: 'default',
        text: 'Choose',
        disabled: true,
      },
      ...state.lateMethods.map((lm) => ({
        value: lm.id,
        text: lm.masterLateMethod.lateMethodDescription,
        disabled: false,
      })),
    ];
  },
);

export const getSecurityDeposits = createSelector(
  getCurrentResidentState,
  (state) => {
    if (!state.securityDepositAlternatives) {
      return [
        {
          value: 'default',
          text: 'Choose',
          disabled: true,
        },
      ];
    }

    return [
      {
        value: 'default',
        text: 'Choose',
        disabled: false,
      },
      ...state.securityDepositAlternatives.map((lm) => ({
        value: lm.id,
        text: lm.masterSecurityPropertyAlternative.securityDepositDesc,
        disabled: false,
      })),
    ];
  },
);

export const getCurrentLease = createSelector(
  getCurrentResidentState,
  ({ leases, currentLeaseId, residentInfo }) => {
    const customerStatus = pathOr(
      null,
      ['residentCustomer', 'customer', 'status'],
      residentInfo,
    );
    return { ...getSelectedLease(leases, currentLeaseId), customerStatus };
  },
);

// export const getAllMonthlyTransactions = createSelector(
export const getMonthlyTransactions = createSelector(
  getCurrentResidentState,
  ({ monthlyTransactions, selectedMonthlyOption }) => {
    // $FlowFixMe
    if (monthlyTransactions.length < 1) {
      return [];
    }
    // $FlowFixMe
    const newMonthlyTransactions = monthlyTransactions.slice();
    const today = moment();
    const filteredMonthlyTransactions = newMonthlyTransactions.filter(
      (transaction) => {
        const startDate = transaction.startDate
          ? moment(transaction.startDate)
          : null;
        const endDate = transaction.endDate
          ? moment(transaction.endDate)
          : null;
        if (selectedMonthlyOption === 'past') {
          return endDate ? endDate.isBefore(today, 'day') : false;
        }
        if (selectedMonthlyOption === 'future') {
          return startDate ? startDate.isAfter(today, 'day') : false;
        }

        const filter = cond([
          [
            (startDate, endDate) => always(none(isNil, [startDate, endDate]))(),
            (startDate, endDate) =>
              any(equals(true))([
                // $FlowFixMe
                startDate.isSame(today, 'day'),
                // $FlowFixMe
                startDate.isBefore(today, 'day') &&
                  // $FlowFixMe
                  endDate.isAfter(today, 'day'),
                // $FlowFixMe
                endDate.isSame(today, 'day'),
              ]),
          ],
          [
            (startDate) => always(!isNil(startDate))(),
            (startDate) =>
              any(equals(true), [
                // $FlowFixMe
                startDate.isSame(today, 'day'),
                // $FlowFixMe
                startDate.isBefore(today, 'day'),
              ]),
          ],
          [(startDate) => always(isNil(startDate))(), always(false)],
        ])(startDate, endDate);

        return filter;
      },
    );

    return filteredMonthlyTransactions;
  },
);

export const getMonthlyTransactionsForProrateCalculator = createSelector(
  getCurrentResidentState,
  ({ monthlyTransactions }) => {
    if (monthlyTransactions.length < 1) return [];
    return monthlyTransactions;
  },
);

export const getRecompleteCertificationStatus = createSelector(
  getCurrentResidentState,
  (residentState) => {
    // $FlowFixMe
    return pathOr(
      false,
      ['applicationInfo', 'reCompleteCertificationStarted'],
      residentState,
    );
  },
);

export const getApplicationStatusOptions = createSelector(
  getCurrentResidentState,
  ({ applicationStatuses }) => {
    if (!applicationStatuses) {
      return [];
    }

    return [
      ...applicationStatuses.map((status) => ({
        value: status.id,
        text: status.name,
        disabled: false,
      })),
    ];
  },
);

export const getLeasesOptions = createSelector(
  getCurrentResidentState,
  ({ leases, residentInfo }) => {
    if (!leases) {
      return [];
    }
    const residentStatus = pathOr(
      null,
      ['residentCustomer', 'customer', 'status'],
      residentInfo,
    );
    const isResident = residentStatus === 'Current Resident';
    return [
      ...leases.map((lease, index, leases) => {
        return {
          value: lease.id,
          text: leaseText(leases, index, isResident),
          disabled: false,
        };
      }),
    ];
  },
);

export const getPreviousLeaseId = createSelector(
  getCurrentResidentState,
  ({ leases }) => {
    if (!leases) {
      return null;
    }
    const currentLease = leases[0] ? leases[0] : DEFAULT_RESIDENT_UNIT.lease;

    return currentLease.id;
  },
);

export const getLabelEndDate = createSelector(
  getCurrentResidentState,
  getCurrentLease,
  ({ labelEndDate }, currentLease) => {
    if (labelEndDate) {
      return labelEndDate;
    }
    return currentLease.endDate
      ? moment(currentLease.endDate).format('MM/DD/YYYY')
      : null;
  },
);
export const hasOpenRenewalLease = createSelector(
  getCurrentResidentState,
  ({ leases }) => {
    if (!leases) {
      return false;
    }
    const renewalLease = leases.find((lease) => {
      return any(equals(true))([lease.isRenewal]);
    });

    return renewalLease ? true : false;
  },
);
export const hasOpenTransferLease = createSelector(
  getCurrentResidentState,
  ({ leases }) => {
    if (!leases) {
      return false;
    }
    const transferLease = leases.find((lease) => {
      return any(equals(true))([lease.isTransfer]);
    });

    return transferLease ? true : false;
  },
);

export const getIsFutureLease = createSelector(
  getCurrentResidentState,
  ({ leases }) => {
    if (!leases) {
      return false;
    }
    const isFutureLease = leases.find((lease) => {
      const leaseStartDate = moment(lease.startDate).format('YYYY-MM-DD');
      const today = moment.utc().format('YYYY-MM-DD');
      return moment(leaseStartDate).isAfter(moment(today), 'day');
    });

    return isFutureLease ? true : false;
  },
);

export const getCustomerId = createSelector(
  // $FlowFixMe
  getCurrentResidentState,
  path(['residentInfo', 'residentCustomer', 'customer', 'id']),
);

export const getRenewalSaved = createSelector(
  getCurrentLease,
  (currentLease) => {
    if (!currentLease) {
      return { valid: false, statusText: 'Not Completed' };
    }

    const savedLease = all(validField)([
      currentLease.numberOfKeys,
      currentLease.lateMethodId,
      currentLease.leaseTermId,
      currentLease.moveInDate,
      currentLease.startDate,
    ]);

    return savedLease
      ? { valid: true, statusText: 'Completed' }
      : { valid: false, statusText: 'Not Completed' };
  },
);

export const isRenewalComplete = createSelector(
  getCurrentLease,
  (currentLease) => currentLease.isRenewalComplete === true,
);

export const getFinanciallyResponsiblePendingApplicants = createSelector(
  getCurrentResidentState,
  (resident) => {
    let FRPendingApplicants = [];
    const sortByNameCaseInsensitive = sortBy(compose(toLower, prop('name')));
    if (resident) {
      // $FlowFixMe
      const currentPendingApplicants = pathOr(
        [],
        ['applicants', 'pendingApplicants'],
        resident,
      );
      FRPendingApplicants = filter(
        (applicant) =>
          applicant.applicantCustomer &&
          applicant.applicantType.financiallyResponsible,
        currentPendingApplicants,
      );
      const adultMembers = FRPendingApplicants.map((applicant) => {
        const { firstName, lastName, middleName, suffix } =
          applicant.applicantCustomer.customer;
        return {
          id: applicant.id,
          name: [firstName, lastName, suffix ? suffix.name : ''].join(' '),
          status: pathOr(null, ['resident', 'status'], applicant),
          type: applicant.applicantType.name,
          recerts: applicant.recerts,
          completeName: `${firstName} ${
            middleName ? `${middleName} ` : ''
          }${lastName}${suffix ? ` ${suffix.name}` : ''}`,
        };
      });
      FRPendingApplicants = sortByNameCaseInsensitive(adultMembers);
    }
    return FRPendingApplicants;
  },
);

export const getFinanciallyResponsibleResidents = createSelector(
  getCurrentResidentState,
  (resident) => {
    let FRHouseholdMembers = [];
    const sortByNameCaseInsensitive = sortBy(compose(toLower, prop('name')));
    if (resident) {
      const currentApplicants = resident.applicationInfo.applicants || [];
      // $FlowFixMe
      const canceledApplicants = pathOr(
        [],
        ['applicants', 'canceledApplicants'],
        resident,
      );
      const canceledApplicantsIds = canceledApplicants.map((a) => a.id);
      const adultMembers = filter(
        (a) =>
          a.applicantCustomer &&
          a.applicantType.financiallyResponsible &&
          pathOr('', ['resident', 'status'], a) !== 'Prior' &&
          !canceledApplicantsIds.includes(a.id),
        currentApplicants,
      ).map((adult) => {
        const recerts = pathOr(false, ['recert'], adult);
        const recertComplete = pathOr(false, ['isComplete'], recerts[0]); // TODO: AFFORDABLE_59 get rid of this
        const { firstName, lastName, middleName, suffix } =
          adult.applicantCustomer.customer;
        return {
          id: adult.id,
          name: [firstName, lastName, suffix ? suffix.name : ''].join(' '),
          status: pathOr(null, ['resident', 'status'], adult),
          type: adult.applicantType.name,
          recertComplete,
          recerts,
          completeName: `${firstName} ${
            middleName ? `${middleName} ` : ''
          }${lastName}${suffix ? ` ${suffix.name}` : ''}`,
          specialNeedsDesignationIds: adult?.specialNeedsDesignationIds ?? [],
          residentId: adult?.resident?.id,
        };
      });

      FRHouseholdMembers = sortByNameCaseInsensitive(adultMembers);
    }

    return FRHouseholdMembers;
  },
);

export const getScreeningLetterTypes = createSelector(
  getManageDocumentsState,
  ({ documentTypes }) => {
    return documentTypes.filter((doc) => doc.type === 'Screening Letter');
  },
);
