export const GET_APPLICANT_INFORMATION_ERROR =
  'app/CreateResident/GET_APPLICANT_INFORMATION_ERROR';
export const GET_APPLICATION_CHECKLIST_STATUS =
  'app/CreateResident/GET_APPLICATION_CHECKLIST_STATUS';
export const GET_APPLICATION_SCREENING_STATUS =
  'app/CreateResident/GET_APPLICATION_SCREENING_STATUS';
export const GET_APPLICATION_SIGNED_LEASE_STATUS =
  'app/CreateResident/GET_APPLICATION_SIGNED_LEASE_STATUS';
export const GET_APPLICATION_VEHICLE_STATUS =
  'app/CreateResident/GET_APPLICATION_VEHICLE_STATUS';
export const GET_APPLICATION_MOVE_IN_ACTION =
  'app/CreateResident/GET_APPLICATION_MOVE_IN_ACTION';
export const GET_VEHICLE_STATUS_SUCCESS =
  'app/CreateResident/GET_VEHICLE_STATUS_SUCCESS';
export const GET_MOVE_IN_ACTIONS_SUCCESS =
  'app/CreateResident/GET_MOVE_IN_ACTIONS_SUCCESS';
export const GET_ONE_APPLICATION = 'app/CreateResident/GET_ONE_APPLICATION';
export const GET_ONE_APPLICATION_SUCCESS =
  'app/CreateResident/GET_ONE_APPLICATION_SUCCESS';
export const GET_ONE_APPLICATION_ERROR =
  'app/CreateResident/GET_ONE_APPLICATION_ERROR';
export const GET_CHECKLIST_STATUS_SUCCESS =
  'app/CreateResident/GET_CHECKLIST_STATUS_SUCCESS';
export const GET_SCREENING_STATUS_SUCCESS =
  'app/CreateResident/GET_SCREENING_STATUS_SUCCESS';
export const GET_SIGNED_LEASE_STATUS_SUCCESS =
  'app/CreateResident/GET_SIGNED_LEASE_STATUS_SUCCESS';
export const GET_APPLICATION_LEASE_STATUS =
  'app/CreateResident/GET_APPLICATION_LEASE_STATUS';
export const GET_APPLICATION_LEASE_STATUS_SUCCESS =
  'app/CreateResident/GET_APPLICATION_LEASE_STATUS_SUCCESS';

export const GET_APPLICATION_LEASE_START_DATE_STATUS =
  'app/CreateResident/GET_APPLICATION_LEASE_START_DATE_STATUS';

export const GET_APPLICATION_LEASE_START_DATE_STATUS_SUCCESS =
  'app/CreateResident/GET_APPLICATION_LEASE_START_DATE_STATUS_SUCCESS';

export const CLEAN = 'app/CreateResident/CLEAN';
export const MOVEIN = 'app/CreateResident/MOVEIN';
export const MOVEIN_SUCCESS = 'app/CreateResident/MOVEIN_SUCCESS';
export const MOVEIN_ERROR = 'app/CreateResident/MOVEIN_ERROR';

export const GET_UNIT_AVAILABILITY_STATUS =
  'app/CreateResident/GET_UNIT_AVAILABILITY_STATUS';
export const GET_UNIT_AVAILABILITY_STATUS_SUCCESS =
  'app/CreateResident/GET_UNIT_AVAILABILITY_STATUS_SUCCESS';

export const GET_ONE_PROPERTY = 'app/CreateResident/GET_ONE_PROPERTY';
export const GET_ONE_PROPERTY_SUCCESS =
  'app/CreateResident/GET_ONE_PROPERTY_SUCCESS';
