import { formatCurrency } from '@fortress-technology-solutions/fortress-component-library/utils/index';

export const buildGrandTotalForCSVExport = ({
  totals,
  filteredHeaders,
  footerRow,
}) => {
  return filteredHeaders.map(({ id }, index) => {
    let result = '';
    if (index === 0 && !totals[id]) {
      result = '"Totals"';
    }
    if (totals[id]) {
      const { value } = footerRow[id];
      result = `"${formatCurrency(value)}"`;
    }
    return result;
  });
};
