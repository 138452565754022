import { reset } from 'redux-form';
import { push } from 'react-router-redux';
import { is, omit } from 'ramda';
import moment from 'moment';
import { actions as toastrActions } from 'react-redux-toastr';
import { put, select, takeLatest, throttle } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import {
  CREATE_WORK_ORDER,
  GET_ALL_UNITS,
  GET_WORK_ORDER,
  SAVE_WORK_ORDER,
} from './constants';
import {
  createWorkOrderSuccess,
  getAllUnitsError,
  getAllUnitsSuccess,
  getWorkOrderError,
  getWorkOrderSuccess,
  saveWorkOrderSuccess,
} from './actions';
import WorkOrderService from '../../services/workOrderService';
import UnitService from '../../services/unitService';
import type { Saga } from 'redux-saga';
import type { Action, Property } from '../App/types';
import messages from './messages';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

const uploadImages = async (
  images: Array<{ imageData: any, type: string }>,
  workOrderId: string,
  organizationId: string,
  propertyId: string,
) => {
  const service = new WorkOrderService();
  const allPromises = images.map((image) => {
    return service.uploadPhoto(
      workOrderId,
      organizationId,
      propertyId,
      image.type,
      image.imageData,
    );
  });

  await Promise.all(allPromises);
};

const deleteImages = async (
  images: Array<any>,
  organizationId: string,
  propertyId: string,
) => {
  const service = new WorkOrderService();
  const allPromises = images.map((image) => {
    return service.deletePhoto(image.id, organizationId, propertyId);
  });

  await Promise.all(allPromises);
};

const parseImages = (imageArray: any, type: string) => {
  if (!imageArray || !imageArray?.length) {
    return [];
  }
  const imagesToUpload = imageArray.filter((img) => !img.id);
  const imagesToUploadWithType = imagesToUpload.map((img) => {
    return { imageData: img, type };
  });
  return imagesToUploadWithType;
};

export function* createWorkOrder(action: Action<Object>): Saga<void> {
  const { workOrder, print } = action.payload;
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const workOrderService = new WorkOrderService();
    const workOrderFormData = new FormData();
    Object.keys(workOrder)
      .filter((key) => workOrder[key] !== null)
      .forEach((key) => {
        const val =
          is(Object, workOrder[key]) && !is(moment, workOrder[key])
            ? JSON.stringify(workOrder[key])
            : workOrder[key];
        workOrderFormData.append(key, val);
      });
    const res = yield workOrderService.save(
      workOrderFormData,
      organizationId,
      property.id,
    );
    yield put(createWorkOrderSuccess(res.detailIdReadable, res.createdAt));

    const { issueImages } = workOrder;

    let imagesWithTypeIssue = parseImages(issueImages, 'issue');

    yield uploadImages(
      imagesWithTypeIssue,
      res.detailId,
      organizationId,
      property.id,
    );

    if (print) {
      const pdf = yield workOrderService.printWorkOrder(
        organizationId,
        property.id,
        res.detailId,
        moment().format('Z'),
      );
      yield FileSaver.saveAs(
        pdf,
        `Work-Order-${moment().format('YYYYMMDD')}.pdf`,
      );
    }
    yield put(reset('workOrderForm'));
    yield put(push(getUrlWithSelectedPropertyId('/manage-work-orders')));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.createdSuccessDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* createWorkOrderSaga(): Saga<void> {
  yield takeLatest(CREATE_WORK_ORDER, createWorkOrder);
}

export function* fetchGetAllUnits(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const unitService = new UnitService();
    const response = yield unitService.getAll(property.id, organizationId);

    yield put(getAllUnitsSuccess(response));
  } catch (err) {
    yield put(getAllUnitsError(err));
  }
}

export function* getAllUnits(): Saga<void> {
  yield takeLatest(GET_ALL_UNITS, fetchGetAllUnits);
}

export function* fetchWorkOrder(action: any): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const workOrderService = new WorkOrderService();
    const response = yield workOrderService.getOne(
      organizationId,
      property.id,
      action.payload,
    );
    yield put(getWorkOrderSuccess(response));
  } catch (error) {
    yield put(getWorkOrderError(error));
  }
}

export function* getWorkOrder(): Saga<void> {
  yield takeLatest(GET_WORK_ORDER, fetchWorkOrder);
}

export function* fetchSaveWorkOrder(action: Action<Object>): Saga<void> {
  const { workOrder, print } = action.payload;
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const workOrderService = new WorkOrderService();
    const { afterServiceImages, issueImages, imagesToDelete } = workOrder;
    let imagesWithTypeAfterService = parseImages(
      afterServiceImages,
      'afterService',
    );
    let imagesWithTypeIssue = parseImages(issueImages, 'issue');
    const allImages = [...imagesWithTypeAfterService, ...imagesWithTypeIssue];
    if (allImages.length) {
      yield uploadImages(
        allImages,
        workOrder.detailId,
        organizationId,
        property.id,
      );
    }

    if (imagesToDelete) {
      yield deleteImages(imagesToDelete, organizationId, property.id);
    }

    const updated = yield workOrderService.update(
      omit(['unitSelectionUpdated'], workOrder),
      organizationId,
    );
    if (print) {
      const pdf = yield workOrderService.printWorkOrder(
        organizationId,
        property.id,
        updated.detailId,
        moment().format('Z'),
      );
      yield FileSaver.saveAs(
        pdf,
        `Work-Order-${moment().format('YYYYMMDD')}.pdf`,
      );
    }
    yield put(saveWorkOrderSuccess());
    yield put(reset('workOrderForm'));
    yield put(push(getUrlWithSelectedPropertyId('/manage-work-orders')));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.editedSuccessDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* saveWorkOrderSaga(): Saga<void> {
  yield throttle(500, SAVE_WORK_ORDER, fetchSaveWorkOrder);
}

export default [
  createWorkOrderSaga,
  getAllUnits,
  getWorkOrder,
  saveWorkOrderSaga,
];
