import * as React from 'react';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Divider } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import type { Review } from '../types';

type Props = {
  reviews: Review[],
  totalReviews: number,
  onLoadMore: Function,
  showSpinner: boolean,
};

export const ReviewsCardFooter = (props: Props) => {
  const { reviews, totalReviews, onLoadMore, showSpinner } = props;

  if ((reviews && reviews.length === 0) || showSpinner) {
    return null;
  }

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Divider variant="dashed" />
      </Grid>
      <Grid item>
        <a
          role="button"
          onClick={onLoadMore}
          disabled={totalReviews === reviews.length}
        >
          {`Load More Reviews (${totalReviews - reviews.length})`}
        </a>
      </Grid>
    </Grid>
  );
};
