import { useContext, useMemo } from 'react';
import { AppContext } from '../containers/App/context';

const useUniqueTableName = (tableName: string) => {
  const { userId, userOrganizationId, selectedProperty } =
    useContext(AppContext);

  return useMemo(
    () =>
      `table_${tableName}_${userId}_${userOrganizationId}_${
        selectedProperty?.id ?? ''
      }`,
    [selectedProperty?.id, tableName, userId, userOrganizationId],
  );
};

export default useUniqueTableName;
