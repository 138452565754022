import AffordableQualificationService from '../../services/affordableQualificationService';
import useAbortController from '../../hooks/useAbortController';
import { useMutation, UseMutationOptions } from 'react-query';

const useCancelCertificationMutation = ({
  affordableQualificationId,
  organizationId,
  propertyId,
  options,
}: {
  affordableQualificationId: string,
  organizationId: string,
  propertyId: string,
  options?: UseMutationOptions,
}) => {
  const abortControllerOptions = useAbortController();

  return useMutation(
    () =>
      new AffordableQualificationService().cancelCertification(
        organizationId,
        propertyId,
        affordableQualificationId,
        abortControllerOptions,
      ),
    options,
  );
};

export default useCancelCertificationMutation;
