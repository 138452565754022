import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { find, propEq } from 'ramda';
import confirm from '../../components/ConfirmDialogModal';
import * as createRoleActions from './actions';
import CreateRoleForm from './CreateRoleForm';
import messages from './messages';
import type { GlobalState, Permission, Role } from '../App/types';
import type { CreateRoleState } from './types';

type Props = {
  locale: string,
  organizationId: string,
  state: CreateRoleState,
  isSubmitting: boolean,
};

type InjectedProps = {
  actions: Object,
  intl: any,
  history: any,
  state: CreateRoleState,
};
type StateProps = {
  rolePermitsAssignees: boolean,
};

export class CreateRolePage extends Component<
  Props & InjectedProps,
  StateProps,
> {
  componentDidMount() {
    this.props.actions.getAllPermissions();
    this.props.actions.getAllRoleTypes();
  }

  handlePermissionsSelectedError = (newState: boolean) => {
    this.props.actions.showNonePermissionsSelectedError(newState);
  };

  handleSubmit = (role: Role) => {
    if (role.permissions.length === 0) {
      this.handlePermissionsSelectedError(true);
    } else {
      this.props.actions.createRole(role);
      this.handlePermissionsSelectedError(false);
    }
  };

  handleMultiselectChange = (permission: Array<Permission>) => {
    this.props.actions.setSelectedPermissions(permission);
  };

  handleRoleTypeChange = (roleTypeId: string) => {
    const selectedRole = find(propEq('id', roleTypeId))(
      this.props.state.roleTypes,
    ) || { canBeAssigned: null };
    this.props.actions.showRolePermitsAssignees(selectedRole.canBeAssigned);
  };

  handleCancel = () => {
    confirm(this.props.intl.formatMessage(messages.cancelConfirmation), {
      intl: this.props.intl,
    }).then(() => {
      this.props.history.replace('/manage-roles');
      this.handlePermissionsSelectedError(false);
      this.props.actions.resetCreateRoleForm();
    });
  };

  generateRoleTypes() {
    const roleTypes = this.props.state.roleTypes.map((roleType) => ({
      value: roleType.id,
      text: roleType.translations[this.props.locale] || roleType.name,
      disabled: false,
    }));
    roleTypes.unshift({
      value: '',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return roleTypes;
  }

  generatePermissions() {
    return this.props.state.permissions.map((permission) => ({
      value: permission.id,
      label: `${permission.module}: ${permission.name}`,
    }));
  }

  render() {
    const permissions = this.generatePermissions();
    const roleTypes = this.generateRoleTypes();
    const { isSubmitting } = this.props;

    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <CreateRoleForm
          header={this.props.intl.formatMessage(messages.header)}
          onSubmit={this.handleSubmit}
          handleMultiselectChange={this.handleMultiselectChange}
          handlePermissionsSelectedError={this.handlePermissionsSelectedError}
          handleRoleTypeChange={this.handleRoleTypeChange}
          permissions={permissions}
          roleTypes={roleTypes}
          intl={this.props.intl}
          selectedPermissions={this.props.state.selectedPermissions}
          nonePermissionsSelected={this.props.state.nonePermissionsSelected}
          rolePermitsAssignees={this.props.state.rolePermitsAssignees}
          handleCancel={this.handleCancel}
          submitPermissions={['role-create']}
          initialValues={{
            organizationId: this.props.organizationId,
          }}
          isSubmitting={isSubmitting}
        />
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = ({
  app,
  createRole,
  languageProvider,
}: GlobalState): Props => {
  return {
    state: createRole,
    locale: languageProvider.locale,
    organizationId: app.currentUser ? app.currentUser.user.organizationId : '',
    isSubmitting: createRole.isSubmitting,
  };
};

export function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(createRoleActions, dispatch),
  };
}

const InjectedCreateRolePage = injectIntl(CreateRolePage);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedCreateRolePage);
