import { find, propEq, isNil, isEmpty, anyPass } from 'ramda';
import type { StatusChangeValue } from '../types';

const validate = (values: StatusChangeValue, { intl, statusList }: Object) => {
  const errors = {};
  const selectedStatus = find(propEq('value', values.newStatus))(statusList) || {};
  if (selectedStatus.requiresNote && anyPass([isNil, isEmpty])(values.notes)) {
    errors.notes = 'Required';
  }

  return errors;
};

export default validate;
