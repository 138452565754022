import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FieldArray } from 'redux-form';
import AdultFields from './AdultFields';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as appSelectors from '../../App/selectors';
import * as residentSelectors from '../selectors';

type Props = {
  deleteAdult: Function,
  applicantTypes: Array<any>,
  addAdult: Function,
  applicationType: string,
  isAffordableApplication: boolean,
  primaryResidentId: string,
  onPrimaryChange: Function,
  leaseLocked: boolean,
  prevPrimaryId: string,
  change: Function,
};

class Adults extends React.Component<Props> {
  render() {
    const isAffordable =
      this.props.applicationType === 'Affordable' ||
      (this.props.applicationType === 'Mixed' &&
        this.props.isAffordableApplication);
    const { leaseLocked, householdHasOpenCerts } = this.props;

    return (
      <div className="applicant-grp">
        <div className="block-heading row">
          <h2>Adult(s)</h2>
        </div>
        <Row>
          <div className="labels-head container-of-inputs">
            <Col xs={6} sm={1}>
              <label>Type*</label>
            </Col>
            <Col xs={6} sm={1}>
              <label>First*</label>
            </Col>
            <Col xs={6} sm={1}>
              <label>Middle</label>
            </Col>
            <Col xs={6} sm={1}>
              <label>Last*</label>
            </Col>
            <Col xs={6} sm={1}>
              <label>Suffix</label>
            </Col>
            <Col xs={6} sm={1}>
              <label>Preferred Name</label>
            </Col>

            <Col xs={6} sm={1} className="text-center">
              <label>Primary/HOH</label>
            </Col>

            <Col xs={6} sm={isAffordable ? 1 : 2}>
              <label>Relation*</label>
            </Col>
            {isAffordable && (
              <Col xs={6} sm={1}>
                <label>Affordable Relation*</label>
              </Col>
            )}

            <Col xs={6} sm={1}>
              <label>Phone*</label>
            </Col>
            <Col xs={6} sm={2}>
              <label>Email</label>
            </Col>
          </div>
        </Row>
        <Row>
          <FieldArray
            name="adults"
            component={AdultFields}
            deleteAdult={this.props.deleteAdult}
            primaryResidentId={this.props.primaryResidentId}
            onPrimaryChange={this.props.onPrimaryChange}
            leaseLocked={leaseLocked}
            householdHasOpenCerts={householdHasOpenCerts}
            prevPrimaryId={this.props.prevPrimaryId}
            change={this.props.change}
          />
        </Row>
        <div className="row">
          <a
            className="btn-text"
            onClick={this.props.addAdult}
            disabled={leaseLocked}
          >
            + Add Adult
          </a>
        </div>
      </div>
    );
  }
}

export default connect(
  createStructuredSelector({
    applicationType: appSelectors.getSelectedPropertyClassType,
    isAffordableApplication: residentSelectors.isAffordableApplication,
  }),
  {},
)(Adults);
