const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class ApplicantChecklistService {
  updateChecklistItem(
    propertyId: string,
    organizationId: string,
    modification: Object
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        checklistItemId: modification.checklistItemId,
        checklistItemOptionId: modification.checklistItemOptionId,
        applicantChecklistDecisionId: modification.applicantChecklistDecisionId
      })
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/applicantChecklist/${
        modification.applicantChecklistId
      }`,
      options
    );
  }
}

export default ApplicantChecklistService;
