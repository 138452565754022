import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Legend } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { palette } from '@fortress-technology-solutions/fortress-component-library/design';
import { COMPLIANCE_TYPES } from './constantsV2';
import messages from './messages';

const ComplianceOverviewLegend = ({ complianceType }) => {
  return complianceType === COMPLIANCE_TYPES.ALL_OPEN_CERTS ? (
    <Legend
      sx={{ paddingX: 2 }}
      data={[
        {
          key: 'attentionRequired',
          label: <FormattedMessage {...messages.attentionRequired} />,
          sx: { backgroundColor: palette.lighter.purple },
        },
        {
          key: 'effectivePastDue',
          label: <FormattedMessage {...messages.effectivePastDue} />,
          sx: { backgroundColor: palette.lighter.red },
        },
      ]}
    />
  ) : null;
};

export default ComplianceOverviewLegend;
