import { useState } from 'react';
import { renderDateField } from '../../../utils/redux-form-helper';
import type Moment from 'moment';

type Props = {
  hidden: boolean,
  error?: string,
  name: string,
  value: string | Moment,
  onChange: Function,
};

export const TouchedDateInput = ({
  hidden,
  error,
  name,
  value,
  onChange,
}: Props) => {
  const [touched, setTouched] = useState(false);

  return renderDateField({
    bsSize: 'lg',
    hidePicker: hidden,
    meta: {
      touched,
      error,
    },
    placeholder: 'MM/DD/YYYY',
    input: {
      onChange: (value) => {
        onChange({ target: { name, value } });
      },
      onBlur: () => {
        setTouched(true);
      },
    },
    value,
    isControlled: true,
  });
};
