import { useMemo } from 'react';

import SpecialNeedsDesignationService from '../../services/specialNeedsDesignationService';

import { useQuery } from 'react-query';

export const useFetchSpecialNeedsDesignationOptions = () => {
  const specialNeedsDesignationService = useMemo(() => {
    return new SpecialNeedsDesignationService();
  }, []);

  return useQuery(
    'SpecialNeedsDesignationGetAll',
    () => specialNeedsDesignationService.getAll(),
    {
      staleTime: Infinity,
    },
  );
};
