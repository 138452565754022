import { useEffect, useState } from 'react';
import SubsidyPaymentService from '../../../services/subsidyPaymentService';

export const useFetchSubsidyPaymentBatches = ({
  organizationId,
  propertyId,
}) => {
  const [subsidyPaymentBatches, setSubsidyPaymentBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchSubsidyPaymentBatches = async () => {
      setIsLoading(true);
      const subsidyPaymentService = new SubsidyPaymentService();
      const response = await subsidyPaymentService.getAllSubsidyPaymentBatches(
        organizationId,
        propertyId,
        abortController.signal,
      );

      setSubsidyPaymentBatches(response);
      setIsLoading(false);
    };
    if (organizationId && propertyId) fetchSubsidyPaymentBatches();

    return () => abortController.abort();
  }, [organizationId, propertyId]);

  return { subsidyPaymentBatches, isLoading };
};
