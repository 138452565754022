// $FlowFixMe
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { Grid, Form } from 'react-bootstrap';
import { Prompt } from 'react-router-dom';
import { find, pathOr, isEmpty, isNil } from 'ramda';
import moment from 'moment';
import { useUnitEntryPreferences, useAsyncResidentPets } from './hooks';
import messages from './messages';
import validate from './validate';
import IssueInfo from './IssueInfo';
import RequestorPreferences from './RequestorPreferences';
import OfficeNotes from './OfficeNotes';
import AfterService from './AfterService';
import UnitIssuesObserved from './UnitIssuesObserved';
import { FORM_NAME } from '../constants';
import { PrintBoxComment } from './PrintBoxComment';
import { HeaderRow } from './Header/HeaderRow';
import { ButtonsRow } from './FooterActions/ButtonsRow';
import type { Props } from './types';
import CommunicationToResidentSection from './CommunicationToResidentSection';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useHasPermission from '../../../hooks/useHasPermission';

const validateSelections = (
  initialValues,
  unitLocations,
  commonAreaLocations,
  entryPermissions,
  priorityLevels,
  workOrderAssignees,
  statuses,
  location,
) => {
  const invalidSelections = [];
  const verifier = (array, prop) => {
    return array.some((e) => e && e.value === initialValues[prop]);
  };
  const unitLocationVerifier = verifier(unitLocations, 'unitLocationId');
  const commonAreaLocationVerifier = verifier(
    commonAreaLocations,
    'commonAreaLocationId',
  );
  const entryPermissionsVerifier = verifier(
    entryPermissions,
    'entryPermissionPreferenceId',
  );
  const priorityLevelsVerifier = verifier(priorityLevels, 'priorityLevelId');
  const workOrderAssigneesVerifier = verifier(
    workOrderAssignees,
    'assignedToId',
  );
  const statuesVerifier = verifier(statuses, 'statusId');

  if (
    !unitLocationVerifier &&
    location === 'unit' &&
    initialValues.unitLocationId !== null
  ) {
    invalidSelections.push('unitLocation');
  }
  if (
    !commonAreaLocationVerifier &&
    location === 'commonArea' &&
    initialValues.commonAreaLocationId !== null
  ) {
    invalidSelections.push('commonAreaLocation');
  }
  if (initialValues.entryPermissionPreferenceId && !entryPermissionsVerifier) {
    invalidSelections.push('entryPreference');
  }
  if (!priorityLevelsVerifier) {
    invalidSelections.push('priorityLevels');
  }
  if (!workOrderAssigneesVerifier) {
    invalidSelections.push('assignedTo');
  }
  if (!statuesVerifier) {
    invalidSelections.push('statuses');
  }
  return invalidSelections;
};

export const WorkOrderForm = ({
  pristine,
  handleSubmit,
  handleCancel,
  isSaving,
  valid,
  change,
  reset,
  intl,
  dirty,
  searchIssues,
  searchUnits,
  unitNumbers,
  unitLocations,
  commonAreaLocations,
  entryPermissions,
  priorityLevels,
  location,
  assignedToId,
  noTimeSpecified,
  workOrderAssignees,
  workOrderIssues,
  unitLocationId,
  commonAreaLocationId,
  issueSearch,
  unitSearch,
  issueId,
  categoryId,
  requestIssueComment,
  requestCommentEntryAvailability,
  officeNoteBeforeService,
  assigneeNoteAfterService,
  thirdPartyVendorNotes,
  history,
  isEditMode,
  initialValues,
  statuses,
  currentUser,
  onSubmit,
  selectedProperty,
  workOrder: {
    createdAt,
    detailIdReadable,
    enteredByUser,
    assignedTo,
    finishedDate,
    status,
    requestorName,
    issue,
    lastStatusChangeDate,
    property,
    customer,
  },
  workOrder,
  isSubmitting,
  changeSelectedUnit,
  unitSelectionUpdated,
  formValues,
  statusId,
}: Props) => {
  let invalidSelections = [];
  if (isEditMode) {
    invalidSelections = validateSelections(
      initialValues,
      unitLocations,
      commonAreaLocations,
      entryPermissions,
      priorityLevels,
      workOrderAssignees,
      statuses,
      location,
    );
  }
  const {
    unitId,
    customerId,
    finishedDate: selectedFinishedDate,
    requestorPhone,
    requestorEmail,
  } = formValues;

  const {
    fortressCommunicationToResidentsWorkOrders,
    fortressWorkOrdersPhotos,
  } = useFlags();

  const hasEditPermission = useHasPermission('workorder-edit');
  const isFieldsDisabled = !hasEditPermission && isEditMode;

  useEffect(() => {
    if ((unitId || customerId) && unitSelectionUpdated !== true) {
      handleUnitAutoSelectUnit({
        unitNumbers,
        formValues,
        change,
        setSelectedUnit,
      });
    }
  }, [unitSelectionUpdated]); // eslint-disable-line

  const [selectedUnit, setSelectedUnit] = useState({});

  if (isEmpty(selectedUnit) && initialValues.unitId && !isEmpty(unitNumbers)) {
    const selectedUnit = find(
      (u) => u.unitId === initialValues.unitId,
      unitNumbers,
    );
    if (selectedUnit) {
      setSelectedUnit(selectedUnit);
    }
  }

  const context = {
    property: selectedProperty,
    unitId: pathOr(null, ['unitId'], selectedUnit),
    householdId: pathOr(null, ['resident', 'householdId'], selectedUnit),
    isEditMode,
    initialAlertValue: !!initialValues.petAlert,
  };
  const [selectedUnitPreferences] = useUnitEntryPreferences(
    context,
    selectedUnit.id,
    change,
  );
  const { residentPetsList } = useAsyncResidentPets(
    context,
    selectedUnit.id,
    initialValues.petAlerts,
    change,
  );

  const propertyId = pathOr('', ['id'], selectedProperty);
  const organizationId = pathOr('', ['organizationId'], selectedProperty);

  const toggleStaffRequested = (e) => {
    const checked = e.target.checked;
    if (checked) {
      change('requestorName', null);
      change('requestorPhone', null);
      change('requestorEmail', null);
      change('requestDate', moment());
    } else {
      if (unitId) {
        const unit = find((u) => u.unitId === unitId, unitNumbers);
        if (unit) {
          setSelectedUnitObj(unit);
        }
      }
    }
  };

  const setSelectedUnitObj = (unit: Object) => {
    if (unit) {
      const householdId =
        !isEmpty(unit.householdId) && !isNil(unit.householdId)
          ? unit.householdId
          : '';
      const customerId =
        !isEmpty(unit.resident.customerId) && !isNil(unit.resident.customerId)
          ? unit.resident.customerId
          : '';
      change('unitSearch', unit.description);
      change('unitId', unit.unitId);
      change('unitNumber', unit.description);
      change('householdId', householdId || null);
      change('customerId', customerId || null);
      change('unitSearch', {
        unitId,
        customerId,
        householdId,
      });

      change('requestorName', unit.resident.name);
      change('requestorPhone', unit.resident.phoneNumber);
      change('requestorEmail', unit.resident.emailAddress);

      if (
        isEmpty(unit.resident.customerId) ||
        isNil(unit.resident.customerId)
      ) {
        change('staffRequested', true);
      } else {
        change('staffRequested', false);
      }
    }
  };

  const handleUnitAutoSelectUnit = (values: Object): void => {
    const { unitNumbers, formValues, change } = values;
    change('unitSelectionUpdated', true);
    const name = pathOr(null, ['requestorName'], formValues);
    const phoneNumber = pathOr(null, ['requestorPhone'], formValues);
    const emailAddress = pathOr(null, ['requestorEmail'], formValues);
    const unitId = pathOr('', ['unitId'], formValues);
    const customerUnit = find((u) => {
      return (
        (!isEmpty(name) && !isNil(name) && u.resident.name === name) ||
        (!isEmpty(emailAddress) &&
          !isNil(emailAddress) &&
          u.resident.emailAddress === emailAddress)
      );
    }, unitNumbers);

    if (customerUnit) {
      setSelectedUnitObj(customerUnit);
    } else {
      const unit = find((u) => u.unitId === unitId, unitNumbers);
      if (unit) {
        setSelectedUnitObj({
          ...unit,
          description: unit.description,
          householdId: null,
          resident: {
            customerId: null,
            name,
            phoneNumber,
            emailAddress,
          },
        });
      }
    }
  };

  const changeHouseholdCallback = (householdObj: Object) => {
    if (householdObj && householdObj.value) {
      const { unitId, customerId, householdId } = householdObj.value;

      const household = unitNumbers.find(
        (household) =>
          household.resident.customerId === customerId &&
          household.householdId === householdId &&
          household.unitId === unitId,
      );

      if (household) {
        setSelectedUnit(household);
        setSelectedUnitObj(household);
      }
    }
  };

  const [isDirty, setIsDirty] = useState(false);

  return (
    <Grid fluid data-test="work-order-form">
      <Prompt
        when={dirty && isSaving === false}
        message={intl.formatMessage(messages.deleteConfirmation)}
      />
      <div className="form--narrow form-wrapper">
        <Form>
          <div data-test="comp-issue-info" style={{ maxWidth: '97%' }}>
            <fieldset disabled={isFieldsDisabled}>
              <HeaderRow
                isEditMode={isEditMode}
                detailIdReadable={detailIdReadable}
                createdAt={createdAt}
                enteredByUser={enteredByUser}
                formatMessage={intl.formatMessage}
                lastStatusChangeDate={lastStatusChangeDate}
                finishedDate={finishedDate}
                status={status}
                currentUser={currentUser}
              />
              <IssueInfo
                isEditMode={isEditMode}
                invalid={invalidSelections}
                intl={intl}
                unitNumbers={unitNumbers}
                searchIssues={searchIssues}
                searchUnits={searchUnits}
                issueSearch={issueSearch}
                unitSearch={unitSearch}
                unitLocation={location}
                unitLocations={unitLocations}
                commonAreaLocations={commonAreaLocations}
                noTimeSpecified={noTimeSpecified}
                changeCallback={change}
                reset={reset}
                workOrderIssues={workOrderIssues}
                unitLocationId={unitLocationId}
                commonAreaLocationId={commonAreaLocationId}
                issueId={issueId}
                categoryId={categoryId}
                data-test="wo-issues"
                formValues={formValues}
                initialValues={initialValues}
                changeSelectedUnit={setSelectedUnit}
                setSelectedUnit={setSelectedUnitObj}
                changeHouseholdCallback={changeHouseholdCallback}
                flags={{ fortressWorkOrdersPhotos }}
                organizationId={organizationId}
              />
              <PrintBoxComment
                comment={requestIssueComment}
                labelText="issueComment"
                intl={intl}
              />
              <RequestorPreferences
                intl={intl}
                invalid={invalidSelections}
                location={location}
                changeCallback={change}
                noTimeSpecified={noTimeSpecified}
                entryPermissions={entryPermissions}
                data-test="wo-requestor-preferences"
                requestorName={requestorName}
                householdPreferences={selectedUnitPreferences}
                householdPets={residentPetsList}
                toggleStaffRequested={toggleStaffRequested}
                unitNumbers={unitNumbers}
                changeSelectedUnit={setSelectedUnit}
                setSelectedUnit={setSelectedUnitObj}
                searchUnits={searchUnits}
                changeHouseholdCallback={changeHouseholdCallback}
                formValues={formValues}
              />
              <PrintBoxComment
                comment={requestCommentEntryAvailability}
                labelText="entryInstructions"
                intl={intl}
              />
            </fieldset>
            <OfficeNotes
              intl={intl}
              isEditMode={isEditMode}
              invalid={invalidSelections}
              priorityLevels={priorityLevels}
              workOrderAssignees={workOrderAssignees}
              assignedTo={assignedTo}
              assignedToId={assignedToId}
              data-test="wo-office-notes"
            />
            <fieldset disabled={isFieldsDisabled}>
              <PrintBoxComment
                comment={officeNoteBeforeService}
                labelText="noteToTechnician"
                intl={intl}
              />
              {isEditMode ? (
                <Fragment>
                  <AfterService
                    intl={intl}
                    invalid={invalidSelections}
                    changeCallback={change}
                    statuses={statuses}
                    data-test="wo-after-service"
                    selectedStatusId={statusId}
                    onStatusIdChange={() => {
                      change('holdReason', '');
                      change('finishedDate', '');
                    }}
                    setIsDirty={setIsDirty}
                    workOrderId={workOrder.id}
                    selectedPropertyId={selectedProperty?.id}
                    organizationId={organizationId}
                  />
                  <PrintBoxComment
                    comment={assigneeNoteAfterService}
                    labelText="afterServiceNote"
                    intl={intl}
                  />
                  <PrintBoxComment
                    comment={thirdPartyVendorNotes}
                    labelText="vendorNotes"
                    intl={intl}
                  />
                  {fortressCommunicationToResidentsWorkOrders &&
                    location !== 'commonArea' && (
                      <CommunicationToResidentSection
                        intl={intl}
                        statuses={statuses}
                        selectedStatusId={statusId}
                        change={change}
                        workOrder={{
                          issue,
                          lastStatusChangeDate,
                          property,
                          holdReason: formValues.holdReason,
                        }}
                        property={{
                          email: selectedProperty?.propertyEmail,
                          phone: selectedProperty?.phoneNumber,
                          name: selectedProperty?.name,
                        }}
                        selectedFinishedDate={selectedFinishedDate}
                        requestor={{
                          name: requestorName,
                          phoneNumber: requestorPhone,
                          email: requestorEmail,
                        }}
                        selectedPropertyId={selectedProperty?.id}
                        customer={{
                          ...workOrder?.customer,
                          name: [
                            workOrder?.customer?.firstName,
                            workOrder?.customer?.lastName,
                          ]
                            .join(' ')
                            .trim(),
                        }}
                        fieldsDirty={isDirty}
                      />
                    )}
                  {location === 'unit' && (
                    <UnitIssuesObserved
                      propertyId={propertyId}
                      organizationId={organizationId}
                      initialValues={initialValues}
                      changeCallback={change}
                      location={location}
                    />
                  )}
                </Fragment>
              ) : null}
            </fieldset>
            <ButtonsRow
              isEditMode={isEditMode}
              history={history}
              intl={intl}
              valid={valid}
              pristine={pristine}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
            />
          </div>
        </Form>
      </div>
    </Grid>
  );
};

export const mapStateToProps = (
  { app, form }: Object,
  { initialValues }: Object,
): Object => {
  const selector = formValueSelector('workOrderForm');
  return {
    initialValues,
    currentUser: app.currentUser.user,
    assignedToId: selector({ form }, 'assignedToId'),
    location: selector({ form }, 'location'),
    noTimeSpecified: selector({ form }, 'noTimeSpecified'),
    unitLocationId: selector({ form }, 'unitLocationId'),
    commonAreaLocationId: selector({ form }, 'commonAreaLocationId'),
    issueSearch: selector({ form }, 'issueSearch'),
    unitSearch: selector({ form }, 'unitSearch'),
    issueId: selector({ form }, 'issueId'),
    categoryId: selector({ form }, 'categoryId'),
    requestIssueComment: selector({ form }, 'requestIssueComment'),
    requestCommentEntryAvailability: selector(
      { form },
      'requestCommentEntryAvailability',
    ),
    officeNoteBeforeService: selector({ form }, 'officeNoteBeforeService'),
    assigneeNoteAfterService: selector({ form }, 'assigneeNoteAfterService'),
    thirdPartyVendorNotes: selector({ form }, 'thirdPartyVendorNotes'),
    selectedProperty: app.selectedProperty,
    formValues: pathOr({}, ['workOrderForm', 'values'], form),
    statusId: selector({ form }, 'statusId'),
  };
};

// $FlowFixMe
export default connect(mapStateToProps)(
  reduxForm({
    validate,
    form: FORM_NAME,
    enableReinitialize: true,
  })(WorkOrderForm),
);
