import * as ActionTypes from './constants';
import type { Action } from '../App/types';
import type { FloorPlan } from './types';

export function getAllFloorPlans(): * {
  return {
    type: ActionTypes.GET_ALL_FLOOR_PLANS,
    payload: {},
  };
}

export function getAllFloorPlansSuccess(floorPlans: Array<FloorPlan>) {
  return {
    type: ActionTypes.GET_ALL_FLOOR_PLANS_SUCCESS,
    payload: floorPlans,
  };
}

export function getAllFloorPlansFail(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_FLOOR_PLANS_FAIL,
    payload: error,
  };
}

export function clearAllFloorPlans(): * {
  return {
    type: ActionTypes.CLEAR_ALL_FLOOR_PLANS,
    payload: null,
  };
}

export function getAllSmartPrices(): * {
  return {
    type: ActionTypes.GET_ALL_SMART_PRICES,
    payload: {},
  };
}

export function getAllSmartPricesSuccess(floorPlans: Array<FloorPlan>) {
  return {
    type: ActionTypes.GET_ALL_SMART_PRICES_SUCCESS,
    payload: floorPlans,
  };
}

export function getAllSmartPricesFail(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_SMART_PRICES_FAIL,
    payload: error,
  };
}
