import { useContext, useMemo } from 'react';
import * as R from 'ramda';
import { AppContext } from '../containers/App/context';

/**
 * Hook to determine if current user has permission
 * @param {*} permission scope string of permission
 * @returns boolean
 */
function useHasPermission(permission: string): boolean {
  const { permissions } = useContext(AppContext);
  const hasPermission = useMemo(
    () =>
      R.find(R.propEq('scope', permission), permissions ?? []) !== undefined,
    [permissions, permission],
  );

  return hasPermission;
}

export default useHasPermission;
