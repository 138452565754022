import type { Action } from '../App/types';
import * as ActionTypes from './constants';

export function getAllLeasingKpis(signal: Object): Action<any> {
  return {
    type: ActionTypes.GET_ALL_LEASING_KPIS,
    payload: {
      signal,
    },
  };
}

export function getAllLeasingKpisSuccess(kpis: Object): Action<any> {
  return {
    type: ActionTypes.GET_ALL_LEASING_KPIS_SUCCESS,
    payload: {
      ...kpis,
      dataLoaded: true,
    },
  };
}

export function getAllLeasingKpisError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_LEASING_KPIS_ERROR,
    payload: error,
  };
}

export function getLeasingKpi(kpi: string, query: string): Action<any> {
  return {
    type: ActionTypes.GET_LEASING_KPI,
    payload: {
      kpi,
      query,
    },
  };
}

export function getLeasingKpiSuccess(
  kpi: string,
  results: Object,
): Action<any> {
  return {
    type: ActionTypes.GET_LEASING_KPI_SUCCESS,
    payload: {
      [kpi]: { value: { ...results }, kpiLoaded: true },
    },
  };
}

export function cleanLoadedLeasingKpis(): Action<any> {
  return {
    type: ActionTypes.CLEAN_LOADED_LEASING_KPIS,
    payload: undefined,
  };
}
