import { defineMessages } from 'react-intl';

const messages = defineMessages({
  grCertsFound: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.GrossRentCertsFound',
    defaultMessage: 'GR Certs Found',
  },
  noEntriesFound: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.NoEntriesFound',
    defaultMessage: 'No Entries Found',
  },
  uploadDocument: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.UploadDocument',
    defaultMessage: 'Upload Document',
  },
  cancelCert: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.CancelCert',
    defaultMessage: 'Cancel Cert',
  },
  completeCert: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.CompleteCert',
    defaultMessage: 'Complete Cert',
  },
  refreshCert: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.RefreshCert',
    defaultMessage: 'Refresh Cert',
  },
  editCert: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.EditCert',
    defaultMessage: 'Edit Cert',
  },
  confirmationTitle: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.Confirmation.Title',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  confirmationCancel: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.Confirmation.Cancel',
    defaultMessage: 'No',
  },
  confirm: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.Confirmation.Confirm',
    defaultMessage: 'Yes',
  },
  editGRModalTitle: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.EditGRModal.Title',
    defaultMessage: 'Edit Gross Rent Certification',
  },
  anticipatedVoucherDate: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.EditGRModal.AnticipatedVoucherDate',
    defaultMessage: 'Anticipated Voucher Date',
  },
  anticipatedVoucherOverride: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.EditGRModal.AnticipatedVoucherOverride',
    defaultMessage: 'Override',
  },
  createGrossRentPacketModalTitle: {
    id: 'App.ManageCertificationsContent.GrossRentCerts.CreateGrossRentPacketModal.Title',
    defaultMessage: 'Create Gross Rent Packet',
  },
});

export default messages;
