import { defineMessages } from 'react-intl';

const messages = defineMessages({
  chooseOption: {
    id:
      'App.ApplicationProfile.LeaseApplicationTab.ApplicantChecklistToggle.chooseOption',
    defaultMessage: 'Choose'
  },
  applicationLabel: {
    id:
      'App.ApplicationProfile.LeaseApplicationTab.ApplicantChecklistToggle.applicationLabel',
    defaultMessage: 'Application'
  }
});

export default messages;
