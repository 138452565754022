import { useState } from 'react';
import CommercialScheduledRentsService from '../../../../../services/commercialScheduledRentsService';

export function useDeleteRent(
  propertyId: string,
  organizationId: string,
  leaseId: string,
  toasterFn: Function,
) {
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteRent = async (rentId: string) => {
    if (isDeleting || !rentId) return Promise.resolve();

    const service = new CommercialScheduledRentsService();

    setIsDeleting(true);

    try {
      await service.deleteOne(organizationId, propertyId, leaseId, rentId);
      toasterFn({
        message: 'Rent deleted successfully.',
        title: 'Success',
      });

      setIsDeleting(false);

      return Promise.resolve();
    } catch (e) {
      toasterFn({
        type: 'error',
        message: 'Failed to delete rent.',
        title: 'Error',
      });

      setIsDeleting(false);

      return Promise.reject(e);
    }
  };

  return [deleteRent, isDeleting];
}

export function useUpdateRent(
  propertyId: string,
  organizationId: string,
  leaseId: string,
  toasterFn: Function,
) {
  const [isUpdating, setIsUpdating] = useState(false);

  const updateRent = async (rentId: string, updates: Object) => {
    if (isUpdating || !rentId) return Promise.resolve();

    const service = new CommercialScheduledRentsService();

    setIsUpdating(true);

    try {
      await service.updateOne(
        organizationId,
        propertyId,
        leaseId,
        rentId,
        updates,
      );
      toasterFn({
        message: 'Rent updated successfully.',
        title: 'Success',
      });

      setIsUpdating(false);

      return Promise.resolve();
    } catch (e) {
      toasterFn({
        type: 'error',
        message: 'Failed to update rent.',
        title: 'Error',
      });

      setIsUpdating(false);

      return Promise.reject(e);
    }
  };

  return [updateRent, isUpdating];
}

export function useCreateRent(
  propertyId: string,
  organizationId: string,
  leaseId: string,
  toasterFn: Function,
) {
  const [isCreating, setIsCreating] = useState(false);

  const createRent = async (rent: Object) => {
    if (isCreating || !rent) return Promise.resolve();

    const service = new CommercialScheduledRentsService();

    setIsCreating(true);

    try {
      await service.createOne(organizationId, propertyId, leaseId, rent);
      toasterFn({
        message: 'Rent created successfully.',
        title: 'Success',
      });

      setIsCreating(false);

      return Promise.resolve();
    } catch (e) {
      toasterFn({
        type: 'error',
        message: 'Failed to create rent.',
        title: 'Error',
      });

      setIsCreating(false);

      return Promise.reject(e);
    }
  };

  return [createRent, isCreating];
}

export function useUpdateHoldover(
  propertyId: string,
  organizationId: string,
  leaseId: string,
  toasterFn: Function,
) {
  const [isUpdating, setIsUpdating] = useState(false);

  const updateHoldover = async (holdover: Object) => {
    if (isUpdating || !holdover) return Promise.resolve();

    const service = new CommercialScheduledRentsService();

    setIsUpdating(true);

    try {
      await service.updateHoldover(
        organizationId,
        propertyId,
        leaseId,
        holdover,
      );
      toasterFn({
        message: 'Saved successfully.',
        title: 'Success',
      });

      setIsUpdating(false);

      return Promise.resolve();
    } catch (e) {
      toasterFn({
        type: 'error',
        message: 'Failed to save.',
        title: 'Error',
      });

      setIsUpdating(false);

      return Promise.reject(e);
    }
  };

  return [updateHoldover, isUpdating];
}
