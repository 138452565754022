import { omit } from 'ramda';
import type { OrderValue } from '../containers/App/types';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils/index';

import {
  apiURL,
  post,
  request,
  patch,
  getRequestOptions,
  put,
} from '../utils/api';

type ApplicantInfo = {
  propertyId: string,
  applicationId: string,
};

export default class ApplicationService {
  getAllApplicationsPaginate(
    organizationId: string,
    propertyId: string,
    pageNumber: number,
    limit: number,
    sorting: OrderValue,
    filters: Object,
    searchText: string,
    dateState = {},
  ) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    const dateFilters = Object.keys(dateState)?.length
      ? Object.entries(dateState ?? {})?.reduce(
          (object, [property, { fromDate, toDate }]) => {
            if (fromDate) {
              object[`${property}FromDate`] = formatDateDB(fromDate);
            }

            if (toDate) {
              object[`${property}ToDate`] = formatDateDB(toDate);
            }

            return object;
          },
          {},
        )
      : {};

    const searchParams = {
      sort: sorting?.fieldName,
      order: sorting?.order,
      unitAssigned: filters?.unitNumber,
      applicationStatusId: filters?.applicationStatusId,
      query: searchText,
      ...dateFilters,
    };

    Object.keys(searchParams).forEach((searchParamKey) => {
      if (!searchParams[searchParamKey]) {
        delete searchParams[searchParamKey];
      }
    });

    const queryString = new URLSearchParams(searchParams).toString();

    return request(
      `${apiURL}/${organizationId}/${propertyId}/applications?${queryString}`,
      options,
    );
  }

  getApplication(
    organizationId: string,
    propertyId: string,
    applicationId: string,
  ) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/applications/${applicationId}`,
      options,
    );
  }

  setRecompleteStarted(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    voucherEffectiveDate: any,
  ) {
    const options = {
      method: 'PUT',
      ...getRequestOptions(),
      body: JSON.stringify({ voucherEffectiveDate }),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/applications/${applicationId}/reCompleteStarted`,
      options,
    );
  }

  setRecertStatusStarted(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    voucherEffectiveDate: any,
  ) {
    const options = {
      method: 'PUT',
      ...getRequestOptions(),
      body: JSON.stringify({ voucherEffectiveDate }),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/applications/${applicationId}/recertStarted`,
      options,
    );
  }

  getApplicationChecklistStatus({ propertyId, applicationId }: ApplicantInfo) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${propertyId}/applications/${applicationId}/checklist-status`,
      options,
    );
  }

  getApplicationScreeningStatus({ propertyId, applicationId }: ApplicantInfo) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${propertyId}/applications/${applicationId}/screening-status`,
      options,
    );
  }

  getApplicationSignedLeaseStatus({
    propertyId,
    applicationId,
  }: ApplicantInfo) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${propertyId}/applications/${applicationId}/signed-lease-status`,
      options,
    );
  }

  getApplicationVehicleStatus({ propertyId, applicationId }: ApplicantInfo) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${propertyId}/applications/${applicationId}/vehicle-status`,
      options,
    );
  }

  getApplicationLeaseStatus({ propertyId, applicationId }: ApplicantInfo) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${propertyId}/applications/${applicationId}/lease-status`,
      options,
    );
  }

  getApplicationLeaseStartDateStatus({
    propertyId,
    applicationId,
  }: ApplicantInfo) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${propertyId}/applications/${applicationId}/lease-start-date-status`,
      options,
    );
  }

  getUnitAvailabilityStatus({ propertyId, applicationId }: ApplicantInfo) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${propertyId}/applications/${applicationId}/unit-availability-status`,
      options,
    );
  }

  getApplicationMoveInActions({ propertyId }) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${propertyId}/applications/move-in-actions`,
      options,
    );
  }

  save(application: any, organizationId: string) {
    const options = {
      method: 'POST',
      ...getRequestOptions(),
      body: JSON.stringify(application),
    };

    return request(
      `${apiURL}/${organizationId}/${application.propertyId}/applications`,
      options,
    );
  }

  resendPayLeaseEmail(
    organizationId: string,
    propertyId: string,
    customerId: string,
  ) {
    const options = {
      method: 'POST',
      ...getRequestOptions(),

      body: JSON.stringify({ customerId }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/resend-paylease-email`,
      options,
    );
  }

  getRecertData(
    organizationId: string,
    propertyId: string,
    applicantId: string,
  ) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/applicant/${applicantId}/recert-form`,
      options,
    );
  }

  saveRecert(
    formData: Object,
    organizationId: string,
    propertyId: string,
    applicantId: string,
    applicationId: string,
  ) {
    const options = {
      method: 'PUT',
      ...getRequestOptions(),
      body: JSON.stringify(formData),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/${applicationId}/${applicantId}/recert-form`,
      options,
    );
  }

  update(application: any, organizationId: string) {
    const applicationToUpdate = omit(['propertyClass'], application);
    const options = {
      method: 'PUT',
      ...getRequestOptions(),
      body: JSON.stringify(applicationToUpdate),
    };
    return request(
      `${apiURL}/${organizationId}/${application.propertyId}/applications/${application.id}`,
      options,
    );
  }

  assign(application: any, organizationId: string) {
    const id = application.id || '';
    const propertyId = application.propertyId
      ? application.propertyId
      : application.property.id;

    const body = JSON.stringify({
      id: application.id,
      assignedToId: application.assignedToId,
      prospectId: application?.prospectInfo?.id,
      propertyId,
    });

    return put(
      `/${organizationId}/${propertyId}/applications/${id}/assignee`,
      body,
    );
  }

  updateHousehold(application: any, organizationId: string) {
    const options = {
      method: 'PUT',
      ...getRequestOptions(),
      body: JSON.stringify(application),
    };
    return request(
      `${apiURL}/${organizationId}/${application.propertyId}/applications/${application.id}/applicants`,
      options,
    );
  }

  saveLeaseDataTab(
    organizationId: string,
    propertyId: string,
    applicationId: any,
    lease: Object,
  ) {
    const options = {
      method: 'POST',
      ...getRequestOptions(),
      body: JSON.stringify(lease),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/${applicationId}/lease`,
      options,
    );
  }

  completeRecertification(
    organizationId: string,
    propertyId: string,
    applicationId: any,
    isReComplete: boolean,
    options?: Object,
  ) {
    const body = JSON.stringify({ isReComplete });
    return post(
      `/${organizationId}/${propertyId}/application/${applicationId}/recertify`,
      body,
      options,
    );
  }

  saveMonthlyCharge(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    transaction: string,
  ) {
    const options = {
      method: 'POST',
      ...getRequestOptions(),
      body: JSON.stringify(transaction),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/${applicationId}/leaseMonthlyTransaction`,
      options,
    );
  }

  moveIn(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    actualMoveInDate: any,
    requiredActions: Array<any>,
    moveInWithoutScreening: boolean,
    proratedTransactions: Array<any>,
  ) {
    const payload = {
      actualMoveInDate,
      moveInWithoutScreening,
      requiredActions,
      proratedTransactions,
    };
    const options = {
      method: 'PUT',
      ...getRequestOptions(),
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/applications/${applicationId}/movein`,
      options,
    );
  }

  commercialMoveIn(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    actualMoveInDate: any,
    proratedTransactions: Array<any>,
  ) {
    const payload = {
      actualMoveInDate,
      proratedTransactions,
    };
    const options = {
      method: 'PUT',
      ...getRequestOptions(),
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/applications/${applicationId}/commercial-move-in`,
      options,
    );
  }

  getMonthlyTransactions(applicationId: string) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${applicationId}/leaseMonthlyTransaction`,
      options,
    );
  }

  getQualificationHistory(
    organizationId: string,
    propertyId: string,
    applicationId: string,
  ) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/${applicationId}/qualification-history`,
      options,
    );
  }

  approveApplicant(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    applicantId: string,
  ) {
    const payload = null;
    const options = {
      method: 'PUT',
      ...getRequestOptions(),
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/applications/${applicationId}/applicants/${applicantId}/approve`,
      options,
    );
  }
  cancelApplicant(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    applicantId: string,
    cancelReasonId: string,
  ) {
    const payload = { cancelReasonId };
    const options = {
      method: 'PUT',
      ...getRequestOptions(),
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/applications/${applicationId}/applicants/${applicantId}/cancellation`,
      options,
    );
  }

  getComplianceReviewed(
    organizationId: string,
    propertyId: string,
    applicationId: string,
  ) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/applications/${applicationId}/complianceReview`,
      options,
    );
  }

  updateComplianceReviewed(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    isComplianceReviewed: boolean,
  ) {
    const options = {
      method: 'PUT',
      ...getRequestOptions(),
      body: JSON.stringify({ isComplianceReviewed }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/applications/${applicationId}/complianceReview`,
      options,
    );
  }

  /**
   * Updates the Property Class id for an application and cleans up Affordable data if needed
   * @param {string} organizationId
   * @param {string} propertyId
   * @param {string} applicationId
   * @param {string} propertyClassId
   * @returns {Promise | Promise<unknown>}
   */
  updatePropertyClass({
    organizationId,
    propertyId,
    applicationId,
    propertyClassId,
  }: Object) {
    const options = {
      method: 'PUT',
      ...getRequestOptions(),
      body: JSON.stringify({ applicationId, propertyClassId }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/applications/convert-property-class`,
      options,
    );
  }

  createCommercialApplication(
    organizationId: string,
    propertyId: string,
    payload: Object,
  ) {
    return post(
      `/${organizationId}/${propertyId}/commercial-applications`,
      JSON.stringify(payload),
    );
  }

  getContactStatus(propertyId: string, applicationId: string) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${propertyId}/applications/${applicationId}/contact-status`,
      options,
    );
  }

  getInsuranceStatus(propertyId: string, applicationId: string) {
    const options = {
      method: 'GET',
      ...getRequestOptions(),
    };

    return request(
      `${apiURL}/${propertyId}/applications/${applicationId}/insurance-status`,
      options,
    );
  }

  updateApplicationReceived(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    applicationReceived: Date,
  ) {
    const options = {
      method: 'PUT',
      ...getRequestOptions(),
      body: JSON.stringify({ applicationReceived }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/applications/${applicationId}/applicationReceived`,
      options,
    );
  }

  updateCommercialApplication(
    organizationId: string,
    propertyId: string,
    applicationId: string,
    payload: Object,
  ) {
    return patch(
      `/${organizationId}/${propertyId}/commercial-applications/${applicationId}`,
      JSON.stringify(payload),
    );
  }
}
