/**
 * Returns a substring from the provided string starting from the index
 * after the last instance of the provided char.
 * Example: ('/path/to/filename', '/') => 'filename'
 * @param {string} str
 * @param {string} char
 */
export const substrAfterLastCharIndex = (str: string, char: string) => {
  const lastIndexOfChar = str.lastIndexOf(char);

  if (lastIndexOfChar < 0) return str;

  return str.substr(lastIndexOfChar + 1);
};

export function pluralizeWord(singularWord, pluralWord, count) {
  return count === 1 ? singularWord : pluralWord;
}
