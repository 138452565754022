export const UTC_TIMESTAMP_REGEX =
  /\d{4}-(0\d|1[0-2])-([0-2]\d|3[0-1])T([0-1]\d|2[0-4]):([0-5]\d):([0-5]\d)(\.\d{1,3})?Z/g;

export const convertTimestampsToLocaleDates = (
  stringToChange: string,
): string => {
  return stringToChange.replace(UTC_TIMESTAMP_REGEX, (match) =>
    new Date(match).toLocaleString(navigator.language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }),
  );
};

export const processActivityNotes = (notes: string) => {
  try {
    const processors = [convertTimestampsToLocaleDates];
    return processors.reduce(
      (modifiedNotes, processor) => processor(modifiedNotes),
      notes,
    );
  } catch (e) {
    return notes;
  }
};
