import moment from 'moment';
import { reset } from 'redux-form';
import { push } from 'react-router-redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { put, select, takeLatest, throttle } from 'redux-saga/effects';
import {
  renderTranslatedMessage,
  renderNoDataToastr,
} from '../../utils/redux-form-helper';

import {
  SAVE_QUALIFICATION_CHECKLIST,
  UPDATE_QUALIFICATION_CHECKLIST,
  GET_ONE_QUALIFICATION_CHECKLIST,
  PRINT_INCOME_ASSET_FORM,
} from './constants';
import {
  saveQualificationChecklistSuccess,
  saveQualificationChecklistError,
  getOneQualificationChecklistSuccess,
} from './actions';
import AffordableService from '../../services/affordableService';
import AffordableQualificationService from '../../services/affordableQualificationService';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import type { Saga } from 'redux-saga';
import type { Action } from '../App/types';
import messages from './messages';
import { isNil } from 'ramda';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

export function* fetchSaveQualificationChecklist(
  action: Action<any>,
): Saga<void> {
  const {
    applicationId,
    checklistValues,
    applicantId,
    profile,
    residentId,
    affordableQualificationId,
    programName,
    isIncome,
  } = action.payload;
  try {
    const id = residentId ? residentId : applicationId;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const affordableQualificationService = new AffordableQualificationService();
    const successDescription = isIncome
      ? 'incomeSuccessDescription'
      : 'assetsSuccessDescription';
    const urlProgramName = (programName ?? '').replace(/\//g, '_');

    yield affordableQualificationService.saveQualificationChecklistApplicant(
      checklistValues,
      affordableQualificationId,
      organizationId,
      selectedProperty.id,
      applicantId,
    );

    yield put(saveQualificationChecklistSuccess());
    yield put(reset('incomeVerification'));
    yield put(
      push(
        getUrlWithSelectedPropertyId(
          `/${profile}/${id}/?tab=${urlProgramName}`,
        ),
      ),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages[successDescription]),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(saveQualificationChecklistError());
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* saveQualificationChecklist(): Saga<void> {
  yield throttle(
    500,
    SAVE_QUALIFICATION_CHECKLIST,
    fetchSaveQualificationChecklist,
  );
}

export function* fetchUpdateQualificationChecklist(
  action: Action<any>,
): Saga<void> {
  const { checklistValues, residentId, affordableChecklistHistoryId } =
    action.payload;
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const affordableService = new AffordableService();
    yield affordableService.updateQualificationChecklist(
      organizationId,
      selectedProperty.id,
      affordableChecklistHistoryId,
      checklistValues,
    );
    yield put(reset('incomeVerification'));
    yield put(
      push(getUrlWithSelectedPropertyId(`/resident/${residentId}/?tab=8`)),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: 'Successfully Updated Qualification History',
        title: 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: 'Error',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* updateQualificationChecklist(): Saga<void> {
  yield throttle(
    500,
    UPDATE_QUALIFICATION_CHECKLIST,
    fetchUpdateQualificationChecklist,
  );
}

export function* fetchGetOneQualificationChecklistSaga(
  action: Object,
): Saga<void> {
  try {
    const affordableService = new AffordableService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const response = yield affordableService.getQualificationChecklist(
      organizationId,
      selectedProperty.id,
      action.payload.applicationId,
      action.payload.applicantId,
    );
    if (response.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getOneQualificationChecklistSuccess(response));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: 'Error',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getOneQualificationChecklistSaga(): Saga<void> {
  yield takeLatest(
    GET_ONE_QUALIFICATION_CHECKLIST,
    fetchGetOneQualificationChecklistSaga,
  );
}

export function* fetchIncomeAssetForm({ payload }: Object): any {
  try {
    const {
      applicationId,
      applicantId,
      propertyId,
      affordableQualificationId,
      type,
      applicantName,
      organizationId,
      programName,
    } = payload;
    const affordableQualificationService = new AffordableQualificationService();
    const date = moment().format('YYYY-MM-DD');
    const program = `${programName ? `${programName.toUpperCase()}_` : ''}`;
    const fileName = `${date}_${applicantName
      .trim()
      .replace(' ', '_')}_Online_${program}${
      type === 'income' ? 'Income' : 'Assets'
    }_Form.pdf`;
    if (isNil(affordableQualificationId) || affordableQualificationId === '') {
      yield affordableQualificationService.downloadDefaultIncomeAssetForm(
        organizationId,
        applicationId,
        applicantId,
        propertyId,
        type,
        fileName,
      );
    } else {
      yield affordableQualificationService.downloadIncomeAssetForm(
        organizationId,
        propertyId,
        affordableQualificationId,
        applicantId,
        type,
        fileName,
      );
    }
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: 'Error while downloading',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* printIncomeAssetForm(): Saga<void> {
  yield takeLatest(PRINT_INCOME_ASSET_FORM, fetchIncomeAssetForm);
}

export default [
  saveQualificationChecklist,
  updateQualificationChecklist,
  getOneQualificationChecklistSaga,
  printIncomeAssetForm,
];
