import { FormattedMessage } from 'react-intl';
import React from 'react';
import { MissingSmartPricesModalTooltip } from './MissingSmartPricesModal/tooltip';
import {
  green,
  purple,
  red,
  blue,
} from '@fortress-technology-solutions/fortress-component-library/design';

import componentMessages from './messages';
import generalMessages from '../App/messages';
const messages = { ...generalMessages, ...componentMessages };

export const TABLE_NAME = 'manageFloorplansTable';
export const HEADERS = ({
  isAffordableMixedProperty,
  hasHUD,
  hasLIHTC,
  smartPricingEnabled,
  allSmartPricesLoaded,
  openMissingSmartPricesModal,
  showSection236,
  showRDNoteRent,
  showRDBasicRent,
}) => [
  {
    id: 'floorPlanName',
    label: <FormattedMessage {...messages.floorPlanName} />,
    searchable: true,
    sortable: true,
    multiselect: true,
    dataType: 'string',
  },
  {
    id: 'floorPlanMarketingName',
    label: <FormattedMessage {...messages.floorPlanMarketingName} />,
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'bedsBaths',
    label: <FormattedMessage {...messages.beds} />,
    dataType: 'string',
    searchable: true,
    sortable: true,
    multiselect: true,
  },
  {
    id: 'sqFt',
    label: <FormattedMessage {...messages.squareFeet} />,
    dataType: 'number',
    searchable: true,
    sortable: true,
  },
  {
    id: 'numUnits',
    label: <FormattedMessage {...messages.numberOfUnits} />,
    dataType: 'number',
    searchable: true,
    sortable: true,
  },
  ...(showRDBasicRent
    ? [
        {
          id: 'basicRent',
          label: <FormattedMessage {...messages.basicRent} />,
          dataType: 'currency',
          searchable: true,
          sortable: true,
        },
      ]
    : []),
  ...(showRDNoteRent
    ? [
        {
          id: 'noteRent',
          label: <FormattedMessage {...messages.noteRent} />,
          dataType: 'currency',
          searchable: true,
          sortable: true,
        },
      ]
    : []),
  {
    id: 'baseFloorPlanMarketRent',
    label: <FormattedMessage {...messages.marketRent} />,
    info: isAffordableMixedProperty ? (
      <FormattedMessage {...messages.marketRentGrossUa} />
    ) : null,
    dataType: 'number',
    searchable: true,
    sortable: true,
  },
  // General Affordable Program
  ...(isAffordableMixedProperty
    ? [
        {
          id: 'financialMarketRent',
          label: <FormattedMessage {...messages.financialMarketRent} />,
          dataType: 'number',
          searchable: true,
          sortable: true,
        },
      ]
    : []),
  {
    id: 'quotingRent',
    label: <FormattedMessage {...messages.quotingRent} />,
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  ...(showSection236
    ? [
        {
          id: 'hud236BasicRent',
          label: <FormattedMessage {...messages.hud236BasicRent} />,
          searchable: true,
          sortable: true,
          dataType: 'number',
        },
        {
          id: 'hud236MarketRent',
          label: <FormattedMessage {...messages.hud236MarketRent} />,
          searchable: true,
          sortable: true,
          dataType: 'number',
        },
      ]
    : []),
  ...(isAffordableMixedProperty
    ? [
        {
          id: 'grossRentLimit',
          label: <FormattedMessage {...messages.grossRentLimit} />,
          searchable: true,
          sortable: true,
          dataType: 'string',
        },
      ]
    : []),
  // HUD Program
  ...(hasHUD
    ? [
        {
          id: 'hudGrossRentLimit',
          label: <FormattedMessage {...messages.HUDGrossRentLimit} />,
          searchable: true,
          sortable: true,
          dataType: 'string',
        },
      ]
    : []),
  {
    id: 'suggestedDeposit',
    label: <FormattedMessage {...messages.suggestedDeposit} />,
    dataType: 'number',
    searchable: true,
    sortable: true,
  },
  ...(smartPricingEnabled
    ? [
        {
          id: 'smartPrice',
          label: <FormattedMessage {...messages.smartPrice} />,
          info: allSmartPricesLoaded ? null : (
            <MissingSmartPricesModalTooltip
              onClickFn={openMissingSmartPricesModal}
            />
          ),
          dataType: 'string',
          // highlighted: true,
        },
        {
          id: 'smartPriceStatus',
          label: <FormattedMessage {...messages.smartPriceStatus} />,
          dataType: 'status',
        },
      ]
    : []),
];

export const PROPERTY_PATH_MAP = ({
  showSection236,
  showRDBasicRent,
  showRDNoteRent,
}) => ({
  floorPlanName: {
    path: 'floorPlanName',
    dataType: 'string',
  },
  floorPlanMarketingName: {
    path: 'floorPlanMarketingName',
    dataType: 'string',
  },
  bedsBaths: {
    path: 'bedsBaths',
    dataType: 'string',
  },
  sqFt: {
    path: 'sqFt',
    dataType: 'number',
  },
  numUnits: {
    path: 'numUnits',
    dataType: 'number',
  },
  ...(showRDNoteRent
    ? {
        noteRent: {
          path: 'noteRent',
          dataType: 'number',
        },
      }
    : {}),
  ...(showRDBasicRent
    ? {
        basicRent: {
          path: 'basicRent',
          dataType: 'number',
        },
      }
    : {}),
  baseFloorPlanMarketRent: {
    path: 'baseFloorPlanMarketRent',
    dataType: 'number',
  },
  financialMarketRent: {
    path: 'financialMarketRent',
    dataType: 'number',
  },
  quotingRent: {
    path: 'quotingRent',
    dataType: 'number',
  },
  grossRentLimit: {
    path: 'grossRentLimit',
    dataType: 'number',
  },
  hudGrossRentLimit: {
    path: 'hudGrossRentLimit',
    dataType: 'number',
  },
  suggestedDeposit: {
    path: 'suggestedDeposit',
    dataType: 'number',
  },
  ...(showSection236
    ? {
        hud236BasicRent: {
          path: 'hud236BasicRent',
          dataType: 'number',
        },
        hud236MarketRent: {
          path: 'hud236MarketRent',
          dataType: 'number',
        },
      }
    : {}),
});

export const TOTAL_COLUMNS = {
  sqFt: { variant: 'number', value: 0 },
  numUnits: { variant: 'number', value: 0 },
  baseFloorPlanMarketRent: { variant: 'currency', value: 0.0 },
  quotingRent: { variant: 'currency', value: 0.0 },
  grossRentLimit: { variant: 'currency', value: 0.0 },
  hudGrossRentLimit: { variant: 'currency', value: 0.0 },
  financialMarketRent: { variant: 'currency', value: 0.0 },
  smartPrice: { variant: 'currency', value: 0.0 },
  noteRent: { variant: 'currency', value: 0.0 },
  basicRent: { variant: 'currency', value: 0.0 },
  hud236BasicRent: { variant: 'currency', value: 0.0 },
  hud236MarketRent: { variant: 'currency', value: 0.0 },
};

export const SP_STATUS_COLOR_STYLES = {
  Accepted: {
    color: green.dark,
    background: green.lighter,
  },
  'Waiting Approval': {
    color: purple.dark,
    background: purple.lighter,
  },
  Rejected: {
    color: red.dark,
    background: red.lighter,
  },
  'No Change': {
    color: blue.dark,
    background: blue.lighter,
  },
};
