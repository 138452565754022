import moment from 'moment';

export const TOOLTIP_PADDING = {
  TITLE: 1,
  BODY: 6,
  FOOTER: 8,
};

export const COLORS = {
  LIMITS: '#4E4E4E',
  ACTUALS: '#98BEFF',
  LEASES: '#98BEFF',
  AVAILABLES: '#EBF5FF',
  OVER_LIMITS: '#C5B7FB',
};

export const DEFAULT_HEIGHT = 600;

export const getBarConfig = (
  label: string,
  color: string,
  data: number[],
  extra?: Object = {},
) => ({
  type: 'bar',
  barThickness: 20,
  backgroundColor: color,
  data,
  label,
  ...extra,
});

export const getBarLegend = (
  text: string,
  datasetIndex: number,
  color: string,
) => ({
  pointStyle: 'circle',
  lineWidth: 0,
  fillStyle: color,
  text,
  datasetIndex,
});

export const padText = (text: string, padding: number) => {
  const len = text.length + padding;
  return text.padStart(len);
};

export const labelConfig = (item, data) => {
  const { xLabel } = item;

  if (!xLabel) return '';

  const [month, year] = xLabel;

  return padText(
    `${moment().month(month).format('MMMM')} ${year}`,
    TOOLTIP_PADDING.TITLE,
  );
};
