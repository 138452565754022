import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

const LeaseDataTab = ({ isCommercial }: Object) => (
  <div className="container-fluid">
    <Row>
      <Col xs={12}>
        <div className="status-icon-big status-declined text-center disp-inline padright10 padbottom5">
          <i className="icon et-alert-urgent" />
        </div>
        <h2 className="disp-inline">
          <FormattedMessage {...messages.title} />
        </h2>
        <p>
          <FormattedMessage
            {...messages[
              isCommercial ? 'commercialDescription' : 'description'
            ]}
            values={{
              leaseAppTab: <strong>Lease App Tab</strong>,
              unitAssigment: <strong>Unit Assignment</strong>,
              applicationStatus: <strong>Application Status</strong>,
            }}
          />
        </p>
      </Col>
    </Row>
  </div>
);

export default LeaseDataTab;
