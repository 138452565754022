import request, { apiURL, requestToDownload, get } from '../utils/api';
import qs from 'qs';

class FinalAccountStatementService {
  get(organizationId: string, propertyId: string, customerId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/customer/${customerId}/final-statement`,
      options,
    );
  }

  generateFinalAccountStatement(
    organizationId: string,
    propertyId: string,
    customerId: string,
    sendEmail: boolean,
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/customer/${customerId}/generate-final-account-statement?sendEmail=${sendEmail}`, // eslint-disable-line
      options,
    );
  }

  getAll(query) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const url = '/finalAccountStatement?' + qs.stringify(query);
    return get(url, options);
  }
}

export default FinalAccountStatementService;
