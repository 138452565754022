import TerminationOptionsFormFields from './forms/termination';
import RenewalOptionsFormFields from './forms/renewalOptions';
import PercentageRentOptionsFormFields from './forms/percentageRent';
import ExclusiveUseOptionsFormFields from './forms/exclusiveUse';
import ExpansionOptionsFormFields from './forms/expansion';
import ContractionOptionsFormFields from './forms/contraction';
import OtherRightsOptionsFormFields from './forms/otherRights';
import LeasingCommissionsOptionsFormFields from './forms/leasingCommissions';
import TennantImprovementsOptionsFormFields from './forms/tenantImprovements';
import RightOfFirstRefusalOptionsFormFields from './forms/rightOfFirstRefusal';
import RightOfFirstOfferOptionsFormFields from './forms/rightOfFirstOffer';
import SecurityDepositOptionsFormFields from './forms/securityDeposit';
import SignageOptionsFormFields from './forms/signage';
import RelocationOptionsFormFields from './forms/relocation';

export default {
  Termination: TerminationOptionsFormFields,
  'Renewal Options': RenewalOptionsFormFields,
  'Percentage Rent': PercentageRentOptionsFormFields,
  'Exclusive Use': ExclusiveUseOptionsFormFields,
  Relocation: RelocationOptionsFormFields,
  Expansion: ExpansionOptionsFormFields,
  Contraction: ContractionOptionsFormFields,
  'Other Rights': OtherRightsOptionsFormFields,
  'Leasing Commissions': LeasingCommissionsOptionsFormFields,
  Signage: SignageOptionsFormFields,
  'Tenant Improvements': TennantImprovementsOptionsFormFields,
  'Right of First Refusal': RightOfFirstRefusalOptionsFormFields,
  'Right of First Offer': RightOfFirstOfferOptionsFormFields,
  'Security Deposit': SecurityDepositOptionsFormFields,
};
