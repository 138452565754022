import { defineMessages } from 'react-intl';

const messages = defineMessages({
  viewTransaction: {
    id: 'App.ViewTransactionModal.viewTransaction',
    defaultMessage: 'View Transaction',
  },
  transactionInformation: {
    id: 'App.ViewTransactionModal.transactionInformation',
    defaultMessage: 'Transaction Information',
  },
  period: {
    id: 'App.ViewTransactionModal.period',
    defaultMessage: 'Period',
  },
  transactionStatus: {
    id: 'App.ViewTransactionModal.transactionStatus',
    defaultMessage: 'Transaction Status',
  },
  manual: {
    id: 'App.ViewTransactionModal.manual',
    defaultMessage: 'Manual',
  },
  batchId: {
    id: 'App.ViewTransactionModal.batchId',
    defaultMessage: 'Batch ID',
  },
  propertyName: {
    id: 'App.ViewTransactionModal.propertyName',
    defaultMessage: 'Property Name',
  },
  unitNumber: {
    id: 'App.ViewTransactionModal.unitNumber',
    defaultMessage: 'Unit Number',
  },
  status: {
    id: 'App.ViewTransactionModal.status',
    defaultMessage: 'Status',
  },
  name: {
    id: 'App.ViewTransactionModal.name',
    defaultMessage: 'Name',
  },
  subjournal: {
    id: 'App.ViewTransactionModal.subjournal',
    defaultMessage: 'Subjournal',
  },
  transactionType: {
    id: 'App.ViewTransactionModal.transactionType',
    defaultMessage: 'Transaction Type',
  },
  transactionAmount: {
    id: 'App.ViewTransactionModal.transactionAmount',
    defaultMessage: 'Transaction Amount',
  },
  receivedFrom: {
    id: 'App.ViewTransactionModal.receivedFrom',
    defaultMessage: 'Received From',
  },
  code: {
    id: 'App.ViewTransactionModal.code',
    defaultMessage: 'Code',
  },
  applyToCamPool: {
    id: 'App.ViewTransactionModal.ApplyToCamPool',
    defaultMessage: 'Apply to CAM Pool?',
  },
  assignToCamPool: {
    id: 'App.ViewTransactionModal.AssignToCamPool',
    defaultMessage: 'Assign to CAM Pool',
  },
  date: {
    id: 'App.ViewTransactionModal.date',
    defaultMessage: 'Date',
  },
  documentNumber: {
    id: 'App.ViewTransactionModal.documentNumber',
    defaultMessage: 'Document Number',
  },
  note: {
    id: 'App.ViewTransactionModal.note',
    defaultMessage: 'Note',
  },
  cancel: {
    id: 'App.ViewTransactionModal.cancel',
    defaultMessage: 'Close',
  },
  actualCancel: {
    id: 'App.ViewTransactionModal.actualCancel',
    defaultMessage: 'Cancel',
  },
  print: {
    id: 'App.ViewTransactionModal.print',
    defaultMessage: 'Print',
  },
  reverse: {
    id: 'App.ViewTransactionModal.reverse',
    defaultMessage: 'Reverse',
  },
  confirmReverse: {
    id: 'App.ViewTransactionModal.confirmReverse',
    defaultMessage: 'Are you sure you want to reverse?',
  },
  ifYesAddNote: {
    id: 'App.ViewTransactionModal.ifYesAddNote',
    defaultMessage: 'If yes, add a note',
  },
  yes: {
    id: 'App.ViewTransactionModal.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.ViewTransactionModal.No',
    defaultMessage: 'No',
  },
  createdBy: {
    id: 'App.ViewTransactionModal.createdBy',
    defaultMessage: 'Created By',
  },
  updatedBy: {
    id: 'App.ViewTransactionModal.updatedBy',
    defaultMessage: 'Modified by',
  },
  isNSFReversal: {
    id: 'App.ViewTransactionModal.isNSFReversal',
    defaultMessage: 'Is this reversal due to NSF?',
  },
  selectCamPoolForReversal: {
    id: 'App.ViewTransactionModal.selectCamPoolForReversal',
    defaultMessage: 'Select CAM Pool for Reversal',
  },
  frontCheck: {
    id: 'App.ViewTransactionModal.FrontCheck',
    defaultMessage: 'Front Check',
  },
  backCheck: {
    id: 'App.ViewTransactionModal.BackCheck',
    defaultMessage: 'Back Check',
  },
});

export default messages;
