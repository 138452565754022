import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import ConfirmModal from '../../../../../../components/ConfirmModal';
import messages from './messages';
import { CHARGE_PERIOD_TYPES } from '../constants';

type Props = {
  intl: any,
  chargePeriodType: string,
  closeDeleteChargeModal: Function,
  handleChargeDelete: Function,
};

const H1Centered = styled.h1`
  text-align: center !important;
  margin-bottom: 0;
  display: block !important;
`;

const BodyText = styled.p`
  text-align: center;
  margin: 10px 10px 25px 10px !important;
`;

export const DeleteChargeModal = ({
  intl,
  chargePeriodType,
  closeDeleteChargeModal,
  handleChargeDelete,
}: Props) => {
  const header =
    chargePeriodType === CHARGE_PERIOD_TYPES.PRORATE
      ? messages.confirmDeleteProratedHeader
      : null;
  const getBody = () => {
    switch (chargePeriodType) {
      case CHARGE_PERIOD_TYPES.PRIOR_CURRENT:
        return messages.confirmEnd;
      case CHARGE_PERIOD_TYPES.FUTURE:
        return messages.confirmDelete;
      case CHARGE_PERIOD_TYPES.PRORATE:
        return messages.confirmDeleteProrated;
      default:
        return messages.confirmDelete;
    }
  };

  return (
    <ConfirmModal
      header={header && <H1Centered> {intl.formatMessage(header)}</H1Centered>}
      children={
        header ? (
          <BodyText>{intl.formatMessage(getBody())}</BodyText>
        ) : (
          <h1 className="text-center">{intl.formatMessage(getBody())}</h1>
        )
      }
      footer={
        <div>
          <Button
            className="btn btn-default pull-left"
            onClick={closeDeleteChargeModal}
          >
            Cancel
          </Button>
          <Button
            className="btn btn-primary pull-right"
            onClick={handleChargeDelete}
          >
            Delete
          </Button>
        </div>
      }
      size="small"
      onReject={closeDeleteChargeModal}
      onHide={closeDeleteChargeModal}
      onConfirm={handleChargeDelete}
      confirmMessage={''}
      rejectMessage={''}
      title={''}
    />
  );
};
