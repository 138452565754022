import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import SubmitButton from '../../../components/SubmitButton';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import confirm from '../../../components/ConfirmDialogModal';
import { Tooltip } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import messages from './messages';
import CaptionButton from '../../../components/CaptionButton';
import { CaptionButtonContainer, ButtonsContainer } from './styled';

type Props = {
  disableAffordableTransfer: boolean,
  hasOpenAffordableQualifications: boolean,
  hasOpenGrossRentCertification: boolean,
  handleTransfer: Function,
  isDisabledRenewal: boolean,
  isDisabledTransfer: boolean,
  leaseRenewalHandler: Function,
  submittingRenewal: boolean,
  showTransferDisabledReason: boolean,
  transferEnabled: boolean,
  startTransferMessage?: string,
  startRenewalMessage?: string,
};

const Header = styled.p`
  color: #d70000;
`;

export const ResidentButtons = ({
  disableAffordableTransfer,
  hasOpenAffordableQualifications,
  hasOpenGrossRentCertification,
  handleTransfer,
  isDisabledRenewal,
  isDisabledTransfer,
  leaseRenewalHandler,
  submittingRenewal,
  transferEnabled,
  startTransferMessage,
  startRenewalMessage,
  noticeToVacate,
}: Props) => {
  const confirmRenewalModal = async () => {
    if (noticeToVacate) {
      await confirm(<Header>{messages.headsUp.defaultMessage} </Header>, {
        body: <FormattedMessage {...messages.confirmationMessage} />,
      });
      leaseRenewalHandler();
    } else {
      leaseRenewalHandler();
    }
  };

  const openCertMessage = useMemo(() => {
    if (hasOpenGrossRentCertification) {
      return (
        <FormattedMessage {...messages.openGrossRentCertificationTooltip} />
      );
    }

    if (hasOpenAffordableQualifications) {
      return <FormattedMessage {...messages.openQualificationTooltip} />;
    }
    return null;
  }, [hasOpenAffordableQualifications, hasOpenGrossRentCertification]);
  return (
    <Col md={6}>
      <ButtonsContainer className="pull-right padtop20 padbottom10">
        <CaptionButtonContainer>
          <ElementWithPermissions scope={['lease-create']}>
            <CaptionButton
              ButtonComp={SubmitButton}
              classes="btn btn-tertiary up10"
              clickHandler={confirmRenewalModal}
              disabled={isDisabledRenewal}
              isSubmitting={submittingRenewal}
              caption={startRenewalMessage}
            >
              <FormattedMessage {...messages.leaseRenewalButton} />
            </CaptionButton>
          </ElementWithPermissions>
        </CaptionButtonContainer>
        {transferEnabled && (
          <Tooltip
            title={disableAffordableTransfer ? openCertMessage : null}
            placement="top"
            variant="light"
          >
            <CaptionButtonContainer>
              <ElementWithPermissions scope={['lease-transfer-start']}>
                <CaptionButton
                  disabled={!!isDisabledTransfer || disableAffordableTransfer}
                  className="btn btn-callto up10"
                  onClick={handleTransfer}
                  caption={startTransferMessage}
                >
                  <FormattedMessage {...messages.transferButton} />
                </CaptionButton>
              </ElementWithPermissions>
            </CaptionButtonContainer>
          </Tooltip>
        )}
      </ButtonsContainer>
    </Col>
  );
};

export default withLDConsumer()(ResidentButtons);
