import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  IconButton,
  Button,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Menu } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  LogoutIcon,
  AvatarIcon,
  ExpandMoreIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import {
  ModalConfirm,
  useConfirmModal,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import messages from './messages';
import appMessages from '../../containers/App/messages';

const UserMenu = ({
  userMenuAnchorRef,
  handleUserMenuToggle,
  userMenuOpen,
  handleUserMenuClose,
  logout,
  fullName,
  isXs,
}) => {
  const { openConfirm, openConfirmModal, closeConfirmModal } =
    useConfirmModal();

  const [firstName, lastName] = fullName?.split(' ') ?? [];
  return (
    <>
      {isXs ? (
        <IconButton
          ref={userMenuAnchorRef}
          onClick={handleUserMenuToggle}
          sx={{
            marginLeft: 'auto',
          }}
        >
          <AvatarIcon sx={{ color: 'white' }} />
        </IconButton>
      ) : (
        <Button
          ref={userMenuAnchorRef}
          onClick={handleUserMenuToggle}
          startIcon={<AvatarIcon />}
          endIcon={<ExpandMoreIcon />}
          sx={{
            color: 'white',
            marginLeft: 'auto',
            mr: 2,
          }}
          variant={'text'}
        >
          <span id={'user_first-name'} style={{ marginRight: 4 }}>
            {firstName?.trim()}
          </span>
          <span id={'user_last-name'}>{lastName?.trim()}</span>
        </Button>
      )}
      <Menu
        id={'userMenu'}
        open={userMenuOpen}
        closeOnClick={true}
        anchorEl={userMenuAnchorRef.current}
        options={[
          {
            icon: <LogoutIcon />,
            text: <FormattedMessage {...messages.logOut} />,
            value: 'logout',
            onClick: openConfirmModal,
          },
        ]}
        handleClose={handleUserMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />

      <ModalConfirm
        title={<FormattedMessage {...messages.confirmLogOut} />}
        open={openConfirm}
        onClose={closeConfirmModal}
        actionsProps={[
          {
            children: <FormattedMessage {...appMessages.cancel} />,
            onClick: closeConfirmModal,
            variant: 'text',
          },
          {
            startIcon: <LogoutIcon />,
            children: <FormattedMessage {...messages.logOut} />,
            onClick: () => {
              logout();
              closeConfirmModal();
            },
          },
        ]}
      />
    </>
  );
};

export default UserMenu;
