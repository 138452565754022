import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import useHasPermission from '../../../hooks/useHasPermission';
import ConnectCard from './ConnectCard';
import PermissionRequiredCard from './PermissionRequiredCard';

function NoMappingScreen() {
  const hasEditSourcesPermission = useHasPermission(
    'rep-mgmt-add-edit-sources',
  );

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} md={9}>
        {hasEditSourcesPermission ? (
          <ConnectCard />
        ) : (
          <PermissionRequiredCard />
        )}
      </Grid>
    </Grid>
  );
}

export default NoMappingScreen;
