import React, { useState } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import { isNil, isEmpty, pathOr, without } from 'ramda';

import validate from './validate';
import type { Props } from './types';
import type { ProrateCalculationsTotals } from './RenewalProrates/types';

import {
  PRORATE_METHODS,
  PRORATE_PERIOD_TYPES,
} from './RenewalProrates/constants';

import { CompleteRenewalHeader } from './header';
import { CompleteRenewalFooter } from './footer';
import { StepsCompleted } from './StepsCompleted';
import { ActionsCompleted } from './ActionsCompleted';
import { RenewalProrates } from './RenewalProrates';
import { ConfirmPriorMonthProratesModal } from './RenewalProrates/ConfirmPriorMonthProratesModal';
import Spinner from '../Spinner';

const FullWidth = styled.form`
  max-width: 100%;
  margin: 20px 0 0 20px;
`;

export const CompleteRenewalCheck = (props: Props) => {
  const [
    showConfirmPriorMonthProratesModal,
    setShowConfirmPriorMonthProratesModal,
  ] = useState(false);

  const [doNotProrateTransactionCodes, setDoNotProrateTransactionCodes] =
    useState([]);

  const { isLoadingRenewalProrateData, prorateMethod, handleSubmit } = props;
  const proratedCharges = pathOr([], ['prorates', 'proratedCharges'], props);
  const proratePeriod = pathOr(null, ['prorates', 'proratePeriod'], props);
  const hasProrates = !isNil(proratedCharges) && !isEmpty(proratedCharges);

  const showProrateSection =
    hasProrates && prorateMethod === PRORATE_METHODS.YES_ALWAYS;

  const renewalIsInPastMonth =
    !isLoadingRenewalProrateData &&
    showProrateSection &&
    proratePeriod === PRORATE_PERIOD_TYPES.PAST_MONTH;

  const getTotals = (): ProrateCalculationsTotals => {
    const prorateLedger = pathOr(false, ['prorates', 'prorateLedger'], props);
    const totals = proratedCharges.reduce(
      (acc, charge) => {
        const transactionCodeId = pathOr(null, ['transactionCodeId'], charge);
        const doNotProrate =
          doNotProrateTransactionCodes.includes(transactionCodeId);

        const accProrate = acc.prorate;
        const accCharged = acc.charged;
        const accAdj = acc.adjustmentsToPost;
        const chargeProrate = doNotProrate
          ? charge.totalCharged
          : charge.totalProrate;
        const chargeCharged = charge.totalCharged;
        const chargeAdj = charge.adjustmentsToPost;
        return {
          prorate: accProrate + chargeProrate,
          charged: prorateLedger ? accCharged + chargeCharged : null,
          adjustmentsToPost: accAdj + chargeAdj,
        };
      },
      {
        prorate: 0,
        charged: 0,
        adjustmentsToPost: 0,
      },
    );

    const adjustmentsToPost =
      isNil(totals.charged) && isNil(totals.prorate)
        ? null
        : (totals.prorate || 0) - (totals.charged || 0);

    return {
      charged: isNil(totals.charged)
        ? null
        : Math.round(totals.charged * 100) / 100,
      adjustmentsToPost:
        isNil(adjustmentsToPost) || adjustmentsToPost === 0
          ? null
          : Math.round(adjustmentsToPost * 100) / 100,
      prorate: isNil(totals.prorate)
        ? null
        : Math.round(totals.prorate * 100) / 100,
    };
  };

  const totals = getTotals();

  const onCancelConfirmPriorMonthProratesModal = () => {
    setShowConfirmPriorMonthProratesModal(false);
  };

  const onComplete = (values: any) => {
    if (renewalIsInPastMonth && !showConfirmPriorMonthProratesModal) {
      setShowConfirmPriorMonthProratesModal(true);
      return;
    }

    handleSubmit(doNotProrateTransactionCodes);
    setShowConfirmPriorMonthProratesModal(false);
  };

  const onDoNotProrateClicked = (transactionCodeId) => {
    // If code is already there, remove it. Otherwise, add it.
    if (doNotProrateTransactionCodes.includes(transactionCodeId)) {
      setDoNotProrateTransactionCodes(
        without([transactionCodeId], doNotProrateTransactionCodes),
      );
    } else {
      setDoNotProrateTransactionCodes([
        ...doNotProrateTransactionCodes,
        transactionCodeId,
      ]);
    }
  };

  return (
    <FullWidth className="form-container container-fluid">
      <Grid fluid>
        <div className="applicant-convert-header">
          <CompleteRenewalHeader {...props} />
          <hr />
          <div className="form-container">
            {!isLoadingRenewalProrateData ? (
              <Row>
                <Col xs={12} sm={7}>
                  <StepsCompleted {...props} />
                </Col>
                <Col xs={12} sm={5}>
                  <ActionsCompleted {...props} />
                </Col>
              </Row>
            ) : (
              <div className="loading-container text-center">
                <Spinner small data-test="prorate-spinner" />
              </div>
            )}
            {showProrateSection && (
              <Row>
                <RenewalProrates
                  {...props}
                  doNotProrateTransactionCodes={doNotProrateTransactionCodes}
                  onDoNotProrateClicked={onDoNotProrateClicked}
                  totals={totals}
                />
              </Row>
            )}
          </div>
          <CompleteRenewalFooter {...props} handleSubmit={onComplete} />
          <ConfirmPriorMonthProratesModal
            show={showConfirmPriorMonthProratesModal}
            onCancel={onCancelConfirmPriorMonthProratesModal}
            onConfirm={onComplete}
          />
        </div>
      </Grid>
    </FullWidth>
  );
};

export const CompleteRenewalCheckForm = reduxForm({
  form: 'CompleteRenewal',
  validate: validate,
})(CompleteRenewalCheck);
