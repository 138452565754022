import { defineMessages } from 'react-intl';

const messages = defineMessages({
  refundtoApplicant: {
    id: 'App.ApplicantRefundModal.RefundtoApplicant',
    defaultMessage: 'Refund to Applicant',
  },
  cancel: {
    id: 'App.ApplicantRefundModal.Cancel',
    defaultMessage: 'Cancel',
  },
  generateRefund: {
    id: 'App.ApplicantRefundModal.GenerateRefund',
    defaultMessage: 'Generate Refund',
  },
  selectMailingAddress: {
    id: 'App.ApplicantRefundModal.SelectMailingAddress',
    defaultMessage: 'Select Mailing Address',
  },
  primaryApplicantCurrentAddress: {
    id: 'App.ApplicantRefundModal.PrimaryApplicantCurrentAddress',
    defaultMessage: 'Primary Applicant Current Address',
  },
  noneAvailable: {
    id: 'App.ApplicantRefundModal.NoneAvailable',
    defaultMessage: 'None Available',
  },
  propertyAddress: {
    id: 'App.ApplicantRefundModal.PropertyAddress',
    defaultMessage: 'Property Address',
  },
  otherForwardingAddress: {
    id: 'App.ApplicantRefundModal.OtherForwardingAddress',
    defaultMessage: 'Other Forwarding Address',
  },
  refundAmount: {
    id: 'App.ApplicantRefundModal.RefundAmount',
    defaultMessage: 'Refund Amount',
  },
  depositHeld: {
    id: 'App.ApplicantRefundModal.DepositHeld',
    defaultMessage: 'Deposit Held:',
  },
  residentLedgerBalance: {
    id: 'App.ApplicantRefundModal.ResidentLedgerBalance:',
    defaultMessage: 'Resident Ledger Balance:',
  },
  totalRefund: {
    id: 'App.ApplicantRefundModal.TotalRefund',
    defaultMessage: 'Total Refund:',
  },
});

export default messages;
