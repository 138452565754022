import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';

type Props = {
  text: string,
  handleClickOutside: Function,
  handleChange?: Function,
};

type State = {
  actualText: string,
};

export class ClickableTextArea extends Component<Props, State> {
  myClickOutsideHandler() {
    this.props.handleClickOutside();
  }
  render() {
    return (
      <textarea
        value={this.props.text}
        maxLength={2000}
        onChange={this.props.handleChange}
        className={'form-control'}
        rows="3"
        placeholder="Add a note here..."
      />
    );
  }
}

const clickOutsideConfig = {
  handleClickOutside: function (instance: any) {
    return instance.myClickOutsideHandler;
  },
};

const ClickToEditTextArea = onClickOutside(
  ClickableTextArea,
  clickOutsideConfig,
);
export default ClickToEditTextArea;
