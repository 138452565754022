// $FlowFixMe
import { useEffect, useState } from 'react';
import ContactsService from '../../services/commercialContactsService';
import InsurancesService from '../../services/insurancesService';
import type { SelectedProperty, RefreshInsurancesFn } from './types';
import type { Contact } from './ContactsSection/types';
import type { Insurance } from './InsuranceSection/types';

export function useTenantInsurances(
  householdId: string,
  selectedProperty: SelectedProperty,
): [Insurance[], RefreshInsurancesFn] {
  const [tenantInsurances, setTenantInsurances] = useState(null);
  const [updateInsurances, setUpdateInsurances] = useState(false);

  const updateTenantInsurances = () => setUpdateInsurances(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchTenantInsurances = async () => {
      if (!tenantInsurances || updateInsurances) {
        const { id, organizationId } = selectedProperty;
        const insurancesService = new InsurancesService();
        const options = { signal: abortController.signal };

        const insurances = await insurancesService.getTenantInsurances(
          organizationId,
          id,
          householdId,
          options,
        );

        setTenantInsurances(insurances);
        setUpdateInsurances(false);
      }
    };

    if (subscribed) {
      fetchTenantInsurances();
    }

    return () => {
      subscribed = false;
    };
  }, [
    selectedProperty,
    householdId,
    tenantInsurances,
    setTenantInsurances,
    updateInsurances,
    setUpdateInsurances,
  ]);

  return [tenantInsurances, updateTenantInsurances];
}

export function useTenantContacts(
  householdId: string,
  selectedProperty: SelectedProperty,
): [Contact[], RefreshInsurancesFn] {
  const [contacts, setContacts] = useState(null);
  const [refreshContacts, setRefreshContacts] = useState(false);

  const updateContacts = () => setRefreshContacts(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchContacts = async () => {
      if (!contacts || refreshContacts) {
        const { id, organizationId } = selectedProperty;
        const contactsService = new ContactsService();
        const options = { signal: abortController.signal };

        const contacts = await contactsService.getCommercialContacts(
          organizationId,
          id,
          householdId,
          options,
        );

        setContacts(contacts);
        setRefreshContacts(false);
      }
    };

    if (subscribed) {
      fetchContacts();
    }

    return () => {
      subscribed = false;
    };
  }, [
    selectedProperty,
    householdId,
    contacts,
    setContacts,
    refreshContacts,
    setRefreshContacts,
  ]);

  return [contacts, updateContacts];
}
