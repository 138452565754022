import { call, put, takeLatest, select, throttle } from 'redux-saga/effects';
import { reset } from 'redux-form';
import { push } from 'react-router-redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import ApplicationService from '../../services/applicationService';
import * as ActionTypes from './constants';
import * as applicationActions from './actions';
import type { Saga } from 'redux-saga';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import messages from './messages';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import type { Action, Property } from '../App/types';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetOneApplication(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplication,
      organizationId,
      selectedProperty.id,
      action.payload,
    );
    yield put(applicationActions.getOneApplicationSuccess(response));
  } catch (err) {
    yield put(applicationActions.getOneApplicationError(err));
  }
}

export function* getOneApplicationSaga(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ONE_APPLICATION, fetchGetOneApplication);
}

export function* putEditApplicationHousehold(action: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicationService = new ApplicationService();
    yield call(
      applicationService.updateHousehold,
      action.payload,
      organizationId,
    );
    yield put(applicationActions.editApplicationHouseholdSuccess());
    yield put(reset('editHousehold'));
    const urlToNavigateTo = getUrlWithSelectedPropertyId(
      `/application/${action.payload.id}`,
    );
    yield put(push(urlToNavigateTo));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* editApplicationHousehold(): Saga<void> {
  yield throttle(
    500,
    ActionTypes.EDIT_APPLICATION_HOUSEHOLD,
    putEditApplicationHousehold,
  );
}

export default [getOneApplicationSaga, editApplicationHousehold];
