import { useMemo, useRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';

type Props = {
  value: number,
  onChange: (number) => void,
};

const formatValue = (value: number) => `${(value || 0).toFixed(1)}%`;

function LimitInput({ value, onChange, ...otherProps }: Props) {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const formattedValue = useMemo(() => formatValue(value), [value]);

  const handleChange = (e) => {
    const [integerPart, fractionalPart] = (e.target.value ?? '').split('.');
    const newValue = parseFloat(`${integerPart}.${fractionalPart?.[0]}`);

    if (newValue > 100) onChange?.(100);
    else onChange?.(newValue);
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);

    if (isNaN(value)) {
      onChange?.(0);
    }
  };

  const onSelect = (event) => {
    // select input only when selected with "tab"
    if (event.nativeEvent.type === 'selectionchange') inputRef.current.select();
  };

  return (
    <FormControl
      {...otherProps}
      style={{ minWidth: 80, paddingLeft: 15, ...otherProps.style }}
      value={isFocused ? (value || 0).toString() : formattedValue}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      type={isFocused ? 'number' : 'text'}
      max={100}
      min={0}
      inputRef={(ref) => (inputRef.current = ref)}
      onSelect={onSelect}
      step={0.1}
    />
  );
}

export default LimitInput;
