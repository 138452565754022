import useIsExpirationLimitsActive from './useIsExpirationLimitsActive';

export default function useExpirationLimitsPropertyToggle(
  organizationId: string,
  propertyId: string,
) {
  const results = useIsExpirationLimitsActive(organizationId, propertyId);

  return {
    isLeaseExpirationLimitsActive:
      results[0]?.isLeaseExpirationLimitsActive ?? false,
    refreshData: results[3],
  };
}

export const withExpirationLimitsPropertyToggle = (Component) => (props) => {
  const { isLeaseExpirationLimitsActive, refreshData } =
    useExpirationLimitsPropertyToggle(
      props.selectedProperty.organizationId,
      props.selectedProperty.id,
    );

  return (
    <Component
      {...props}
      isLeaseExpirationLimitsActive={isLeaseExpirationLimitsActive}
      refreshIsLeaseExpirationLimitsActive={refreshData}
    />
  );
};
