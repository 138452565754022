import { useContext, useMemo, useState } from 'react';
import * as R from 'ramda';
import { Button } from 'react-bootstrap';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { toastr } from 'react-redux-toastr';
import { AppContext } from '../../App/context';
import ReputationMgmtService from '../../../services/reputationManagementService';
import TextArea from '../../../components/TextArea';
import type { ResponseSubmitSuccessHandler } from '../types';
import messages from '../messages';
import useFormatMessage from '../../../hooks/useFormatMessage';
import * as constants from '../constants';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';

export type ResponseFormValues = { message: string };
export type CancelEventHandler = Function;

export type Props = {
  reviewId: string,
  responseId?: string,
  source: string,
  initialValue: ResponseFormValues,
  onCancel: CancelEventHandler,
  onResponseSubmitSuccess: ResponseSubmitSuccessHandler,
};

const service = new ReputationMgmtService();

const ResponseForm = ({
  onCancel,
  reviewId,
  responseId,
  initialValue,
  source,
  onResponseSubmitSuccess,
}: Props) => {
  const formatMessage = useFormatMessage();
  const [value, setValue] = useState(initialValue || { message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { selectedProperty } = useContext(AppContext);

  const isSubmitBtnDisabled = useMemo(
    () => value.message.trim().length === 0,
    [value],
  );

  const handleMessageChange = (e) => {
    setValue({
      ...value,
      message: e.target.value,
    });
  };

  const handleSubmit = async () => {
    const propertyId = selectedProperty?.id;
    const organizationId = selectedProperty?.organizationId;
    const isUpdate = !R.isNil(initialValue);
    const serviceCall = isUpdate
      ? service.editReviewResponse
      : service.respondToReview;
    const idKey = isUpdate ? constants.ID_KEY_ID : constants.REVIEW_ID;

    setIsSubmitting(true);

    try {
      const response = await serviceCall(organizationId, propertyId, {
        [idKey]: responseId || reviewId,
        message: value.message.trim(),
        source,
      });

      onResponseSubmitSuccess(reviewId, response);

      toastr.success(
        formatMessage(messages.success),
        isUpdate
          ? formatMessage(messages.updateResponseSuccess)
          : formatMessage(messages.respondSuccess),
      );
    } catch (error) {
      toastr.error(
        formatMessage(messages.error),
        isUpdate
          ? formatMessage(messages.failedToEditResponse)
          : formatMessage(messages.failedToRespondToReview),
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitResponseClick = () => {
    if (isSubmitting) return;

    handleSubmit();
  };

  return (
    <>
      <Stack marginTop={1}>
        <TextArea
          value={value}
          placeholder={formatMessage(messages.respondToThisReview)}
          disabled={isSubmitting}
          onChange={handleMessageChange}
        />
      </Stack>
      <Grid direction="row" container justifyContent="flex-end">
        <Button
          style={{ marginRight: '20px' }}
          onClick={onCancel}
          disabled={isSubmitting}
        >
          {formatMessage(messages.cancel)}
        </Button>
        <Button
          bsStyle="primary"
          onClick={handleSubmitResponseClick}
          disabled={isSubmitBtnDisabled}
        >
          {isSubmitting ? (
            <Spinner small />
          ) : (
            formatMessage(messages.submitResponse)
          )}
        </Button>
      </Grid>
    </>
  );
};

export default ResponseForm;
