import React from 'react';
import Control from './Control';
import { FormattedMessage } from 'react-intl';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import SetupGridItem from '../../SetupGridItem';
import { SetLeaseEndDateProps as Props } from './types';
import messages from './messages';

const SetLeaseEndDate = (props: Props) => {
  const {
    isSetLeaseEndDateToEndOfMonth = false,
    onFormChangeHandler = () => {},
    isEditMode = false,
    propertyDetailsIsLoading = false,
  } = props;

  const controlProps = {
    isEditMode,
    isSetLeaseEndDateToEndOfMonth,
    onFormChangeHandler,
  };

  return (
    <SetupGridItem
      label={
        <>
          <FormattedMessage {...messages.label} />*
        </>
      }
      TooltipProps={{
        title: (
          <>
            <FormattedMessage {...messages.tooltip1} />.
            <br />
            <br />
            <FormattedMessage {...messages.tooltip2} />.
          </>
        ),
        children: <AlertInfoIcon />,
      }}
      aria-label="Set Lease End Date Toggle"
    >
      {propertyDetailsIsLoading ? (
        <Spinner small />
      ) : (
        <Control {...controlProps} />
      )}
    </SetupGridItem>
  );
};

export default SetLeaseEndDate;
