import { validateRequired } from '../../../utils/validations';
import { VEHICLE_VEHICLE_TYPE_ID } from './constants';
import messages from './messages';

const VEHICLE_TYPE_REQUIED_FIELDS = [
  'vehicleTypeId',
  'vehicleMakeId',
  'vehicleModelId',
  'vehicleColorId',
  'licensePlateNumber',
  'licensePlateStateId',
];
const NON_VEHICLE_TYPE_REQUIED_FIELDS = [
  'vehicleTypeId',
  'vehicleColorId',
  'licensePlateNumber',
  'licensePlateStateId',
];

const validate = (values: Object = {}, { intl }: Object) => {
  const errors = {};

  const requiredFields =
    values.vehicleTypeId === VEHICLE_VEHICLE_TYPE_ID
      ? VEHICLE_TYPE_REQUIED_FIELDS
      : NON_VEHICLE_TYPE_REQUIED_FIELDS;

  requiredFields.forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      errors[fieldName] = intl.formatMessage(messages.required);
    }
  });

  return errors;
};

export default validate;
