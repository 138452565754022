import moment from 'moment';
import { range } from 'ramda';

export const FORM_NAME = 'commercialLeaseOptions';
export const EMPTY_SELECT_VALUE = {
  value: '',
  text: 'Choose an option',
  disabled: true,
};
export const DAYS_SELECT_OPTIONS = (maxDay: number = 31): Array<Object> => {
  const result = range(1, maxDay + 1).map((day) => {
    return {
      value: day,
      text: day,
    };
  });
  return [EMPTY_SELECT_VALUE].concat(result);
};
export const MONTHS_SELECT_OPTIONS = () => {
  const months = [EMPTY_SELECT_VALUE];
  const dateStart = moment();
  const dateEnd = moment().add(11, 'month');
  while (dateEnd.diff(dateStart, 'months') >= 0) {
    months.push({
      value: dateStart.format('MMMM'),
      text: dateStart.format('MMMM'),
    });
    dateStart.add(1, 'month');
  }
  return months;
};
export const TENANT_LANDLORD_SELECT_OPTIONS = [
  EMPTY_SELECT_VALUE,
  { value: 'Tenant', text: 'Tenant' },
  { value: 'Landlord', text: 'Landlord' },
];
export const TERMINATION_TYPE_OPTIONS = [
  EMPTY_SELECT_VALUE,
  { value: 'Amount', text: 'Amount' },
  { value: 'Description', text: 'Description' },
];
export const RENT_RATE_OPTIONS = [
  EMPTY_SELECT_VALUE,
  { value: 'CPI', text: 'CPI' },
  { value: 'Then Market Rate', text: 'Then Market Rate' },
];
export const GOSS_SALES_DUE_OPTIONS = [
  EMPTY_SELECT_VALUE,
  { value: 'Monthly', text: 'Monthly' },
  { value: 'Annually', text: 'Annually' },
];
export const DAYS_MONTHS_SELECT_OPTIONS = [
  EMPTY_SELECT_VALUE,
  { value: 'Days', text: 'Days' },
  { value: 'Months', text: 'Months' },
];
export const YES_NO_SELECT_OPTIONS = [
  EMPTY_SELECT_VALUE,
  { value: 'Yes', text: 'Yes' },
  { value: 'No', text: 'No' },
];
export const CURRENCY_TYPE_SELECT_OPTIONS = [
  EMPTY_SELECT_VALUE,
  { value: 'Dollar Amount', text: 'Dollar Amount' },
  { value: '$/Sq. Foot', text: '$/Sq. Foot' },
];
export const OFFER_TYPE_SELECT_OPTIONS = [
  EMPTY_SELECT_VALUE,
  { value: 'Lease', text: 'Lease' },
  { value: 'Buy', text: 'Buy' },
];
export const PAYS_FOR_SIGNAGE_SELECT_OPTIONS = [
  EMPTY_SELECT_VALUE,
  { value: 'LL', text: 'LL' },
  { value: 'TT', text: 'TT' },
  { value: 'NA', text: 'NA' },
];
export const FEE_TYPE_SELECT_OPTIONS = [
  EMPTY_SELECT_VALUE,
  { value: 'Monthly', text: 'Monthly' },
  { value: 'Annual', text: 'Annual' },
  { value: 'NA', text: 'NA' },
];
export const FULL_DATE_FORMAT = 'MM/DD/YYYY';

export const EMAIL_FIELDS = ['Landlord Rep Email', 'Tenant Rep Email'];
export const PHONE_FIELDS = ['Landlord Rep Phone', 'Tenant Rep Phone'];
export const NAME_FIELDS = ['Landlord Rep Name', 'Tenant Rep Name'];
export const NUMBER_FIELDS = [
  'Response Deadline',
  'Day of Month Due',
  'Percentage of Sales Payable Above Breakpoint',
  'Start Date',
  'Expiration Date',
  'Notice Date',
  'Date Notice Required',
];
export const MONEY_FIELDS = [
  'Breakpoint',
  'Rent Rate Amount',
  'Amount of Original SD',
  'Returnable Amount',
  'Signage Fees',
  'Allowance by LL Amount',
];

export const DATE_FIELDS = [
  'Fiscal Year Start Date',
  'Fiscal Year End Date',
  'Date to Return SD',
  'Task Due Date',
];
