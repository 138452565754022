import { isNil } from 'ramda';
import moment from 'moment';
import { isBetweenDates } from '../../../utils/date-helpers';

export const getSubjournalsOptions = (subjournals) => {
  return !!subjournals
    ? [
        { value: '', text: 'Choose', disabled: true },
        ...subjournals.map((subjournals) => {
          const sujType = subjournals?.masterSubjournalType?.description ?? '';
          return {
            value: subjournals?.id,
            text: sujType,
            disabled: false,
          };
        }),
      ]
    : [{ value: '', text: 'Choose', disabled: true }];
};
export const getPropertyTransactionCodesOptions = (
  propertyTransactionCodes,
) => {
  return !!propertyTransactionCodes
    ? [
        { value: '', text: 'Choose', disabled: true },
        ...propertyTransactionCodes.map((tr) => {
          const trCode = tr?.transactionCode?.code ?? '';
          const description = tr?.transactionCode?.description ?? '';

          return {
            value: tr?.id,
            text: `${trCode} - ${description}`,
            disabled: false,
          };
        }),
      ]
    : [{ value: '', text: 'Choose', disabled: true }];
};

export const getStartingResidentsBalances = (currentResidents) => {
  const residents = currentResidents?.results;
  const resBalances = (residents || []).reduce((acc, row) => {
    const customerId = row?.customerId;
    const balance = row?.balance ?? 0;
    if (isNil(customerId) || !isNil(acc[customerId])) {
      return acc;
    }

    return { ...acc, [customerId]: balance };
  }, {});
  return resBalances;
};

export const getCustomerUnits = (currentResidents) => {
  const residents = currentResidents?.results;
  const resUnits = (residents || []).reduce((acc, row) => {
    const customerId = row?.customerId;
    const unitId = row?.unitId ?? 0;
    if (isNil(customerId) || !isNil(acc[customerId])) {
      return acc;
    }

    return { ...acc, [customerId]: unitId };
  }, {});
  return resUnits;
};

export const getCurrentResidentsOptions = (currentResidents) => {
  const residents = currentResidents?.results;
  const currentRes = (residents || [])
    .sort((a, b) =>
      (a?.unitNumber ?? null) < (b?.unitNumber ?? null) ? -1 : 1,
    )
    .map((row) => {
      const name = row?.customerName;
      const unitNumber = row?.unitNumber ?? '';
      const unitId = row?.unitId;
      const customerId = row?.customerId;
      return !isNil(unitId) && !isNil(customerId)
        ? {
            value: customerId,
            label: `${unitNumber} - ${name}`,
          }
        : null;
    })
    .filter((row) => !!row);
  return currentRes;
};

export const getTransactionTypeOptions = (transactionTypes) => {
  return !!transactionTypes
    ? [
        { value: '', text: 'Choose', disabled: true },
        ...transactionTypes
          .filter((tt) => tt?.name === 'Credit' || tt?.name === 'Charge')
          .map((tt) => {
            return {
              value: tt?.id,
              text: tt?.name,
              disabled: false,
            };
          }),
      ]
    : [{ value: '', text: 'Choose', disabled: true }];
};

export const isValidBatchDate = (batchDate: Object) => {
  const minBatchDate = moment().subtract(30, 'days');
  const maxBatchDate = moment().add(30, 'days');
  const mBatchDate = moment(batchDate);

  return (
    !isNil(batchDate) &&
    isBetweenDates(mBatchDate, minBatchDate, maxBatchDate, 'days')
  );
};

export const getCustomerNameWithUnit = (
  customerId,
  currentResidentsOptions,
) => {
  const rowCustomerObj = isNil(customerId)
    ? null
    : currentResidentsOptions.find((t) => t.value === customerId);
  const rowCustomerName = rowCustomerObj?.label ?? null;
  return rowCustomerName;
};
