import { FormattedMessage } from 'react-intl';
import { blue } from '@fortress-technology-solutions/fortress-component-library/design';

import componentMessages from './messages';
import containerMessages from '../../messages';

const messages = { ...containerMessages, ...componentMessages };

export const HEADERS = [
  {
    id: 'createdAt',
    label: <FormattedMessage {...messages.reportGeneratedDate} />,
    sx: {
      backgroundColor: blue.main,
    },
    sortable: true,
    searchable: true,
    dataType: 'date',
  },
  {
    id: 'download',
    label: <FormattedMessage {...messages.download} />,
    sx: {
      backgroundColor: blue.main,
      width: 150,
      display: 'flex',
      justifyContent: 'center',
    },
  },
];
export const PROPERTY_PATH_MAP = {
  createdAt: {
    path: 'createdAt',
    dataType: 'date',
  },
};
