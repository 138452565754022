import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';

// Components
import HousingAssistanceVoucherModals from './HousingAssistanceVoucherModals';
import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import AddNewVoucherButton from './AddNewVoucherButton';

// Hooks
import useHousingAssistanceVoucher from './hooks';

// Others
import { HousingAssistanceVoucher } from '../App/types';
import messages from './messages';

type Props = {
  organizationId: string,
  propertyId: string,
  householdId: string,
  intl: Object,
};

/**
 * The central component for the Housing Assistance Voucher feature.
 *
 * @NOTE
 *  - All the housing assistance types will behave as vouchers, so that's why we added 'voucher' to the name
 * @param {*} param0
 * @returns
 */
function HousingAssistanceVoucherTab({
  organizationId,
  propertyId,
  householdId,
  intl,
}: Props) {
  const {
    modalProps,
    // Table
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    isLoading,
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
    count,
    totalCount,
    name,
    order,
    orderBy,
    // Data
    refetchVouchers,
    rows,
  } = useHousingAssistanceVoucher({
    organizationId,
    propertyId,
    householdId,
  });

  const housingAssistanceVouchers: Array<HousingAssistanceVoucher> = rows;

  return (
    <>
      <TableHeader
        title={<FormattedMessage {...messages.title} />}
        count={count}
        totalCount={totalCount}
        actions={
          <AddNewVoucherButton
            organizationId={organizationId}
            propertyId={propertyId}
            householdId={householdId}
            intl={intl}
            handleOpenModal={modalProps.handleOpen}
            disabled={isLoading}
          />
        }
      />
      <Table
        ReactBeautifulDnD={{
          DragDropContext,
          Droppable,
          Draggable,
        }}
        allColumnsHidden={allColumnsHidden}
        columnOptions={columnOptions}
        count={count}
        totalCount={totalCount}
        handleColumnChange={handleColumnChange}
        handleSortChange={handleSortChange}
        handleFilterChange={handleFilterChange}
        handleFilterTypeChange={handleFilterTypeChange}
        handleSearchSubmit={handleSearchSubmit}
        handleDateSubmit={handleDateSubmit}
        headers={filteredHeaders}
        height={500}
        intl={intl}
        isLoading={isLoading}
        name={name}
        order={order}
        orderBy={orderBy}
        rows={housingAssistanceVouchers}
        selectedColumns={selectedColumns}
        showSettings={false}
      />
      <HousingAssistanceVoucherModals
        organizationId={organizationId}
        propertyId={propertyId}
        householdId={householdId}
        refetchVouchers={refetchVouchers}
        intl={intl}
        {...modalProps}
      />
    </>
  );
}

export default HousingAssistanceVoucherTab;
