import moment from 'moment';
import { pathOr } from 'ramda';

import { sortAndFilterRows } from '../../../../utils';
import { download } from '../../../../utils/downloadFile.js';
import { formatMoney, formatDate } from '../../../../utils/formatter';
import { appendFilterTextToCSV } from '../../../../utils/csv-helpers';

const getHouseholdValues = (grCert: Object) => {
  const {
    unit,
    headOfHousehold,
    grEffectiveDate,
    isCorrection,
    affordableQualificationId,
    priorCertificationDate,
    hasPriorHUDCerts,
  } = grCert;
  return {
    unit,
    headOfHousehold,
    grEffectiveDate,
    isCorrection,
    affordableQualificationId,
    priorCertificationDate,
    hasPriorHUDCerts,
  };
};

const parseAQInitialValues = (grCert: Object) => {
  const { anticipatedVoucherDate, anticipatedVoucherOverride } = grCert;
  return {
    anticipatedVoucherDate: moment(anticipatedVoucherDate),
    anticipatedVoucherOverride,
  };
};

const parsePrevMissingCertInitialValues = (grCert: Object) => {
  const { prevMissingCert, priorCertificationDate } = grCert;
  if (!prevMissingCert) {
    return null;
  }
  const rawQualifyForRentOverride = pathOr(
    null,
    ['qualifyForRentOverride'],
    prevMissingCert,
  );
  const qualifyForRentOverride =
    rawQualifyForRentOverride === null
      ? null
      : rawQualifyForRentOverride === true
      ? 'yes'
      : 'no';
  return {
    ...prevMissingCert,
    priorCertificationDate: moment(priorCertificationDate),
    qualifyForRentOverride,
  };
};

const parseCorrectionInitialValues = (grCert: Object) => {
  const { correction } = grCert;
  if (!correction) {
    return null;
  }

  const rawEIVIndicator = pathOr(null, ['eivIndicator'], correction);
  const eivIndicator =
    rawEIVIndicator === null ? null : rawEIVIndicator === true ? 'yes' : 'no';

  return {
    ...correction,
    eivIndicator,
  };
};

const getInitialValues = (grCert: Object) => {
  const grossRentEdits = parseAQInitialValues(grCert);
  const prevMissingCert = parsePrevMissingCertInitialValues(grCert);
  const correction = parseCorrectionInitialValues(grCert);

  return {
    grossRentEdits,
    prevMissingCert,
    correction,
  };
};

export const parseEditGRCertValues = (grCert: Object) => {
  return {
    householdValues: getHouseholdValues(grCert),
    initialValues: getInitialValues(grCert),
  };
};

export const determineIf59aIsDisabled = (row: Object): boolean => {
  if (!row || typeof row !== 'object') {
    return false;
  }
  const { isCorrection, correction, priorCertificationDate, priorActiveCert } =
    row;
  const disableForMissingCorrection = isCorrection && !correction;
  const disableGenerate59ForRow =
    !priorCertificationDate || disableForMissingCorrection || !!priorActiveCert;
  return disableGenerate59ForRow;
};

const parseAssistanceDetailValue = (
  hasPriorHUDCerts: boolean,
  value: number,
) => {
  const missingPriorCertAndValue = !hasPriorHUDCerts && value === null;
  if (missingPriorCertAndValue) {
    return '***';
  }
  const parsedValue = formatMoney(value);
  return parsedValue === '---' ? 'NC*' : parsedValue;
};

export const parseNewAssistanceDetails = (grCert: Object) => {
  const {
    newSubsidy,
    newResidentRent,
    newUtilityReimbursement,
    hasPriorHUDCerts,
  } = grCert;
  const subsidy = parseAssistanceDetailValue(hasPriorHUDCerts, newSubsidy);
  const residentRent = parseAssistanceDetailValue(
    hasPriorHUDCerts,
    newResidentRent,
  );
  const utilityReimbursement = parseAssistanceDetailValue(
    hasPriorHUDCerts,
    newUtilityReimbursement,
  );
  return {
    subsidy,
    residentRent,
    utilityReimbursement,
  };
};

export const missingPrevValuesTextRedCheck = (grCert: Object) => {
  const { priorCertificationDate, hasPriorHUDCerts, prevMissingCert } = grCert;
  if (!priorCertificationDate || (!hasPriorHUDCerts && !prevMissingCert)) {
    return 'text-red';
  }
  return '';
};

const COLUMNS_TO_EXCLUDE = ['generate59a', 'action'];

export const parseGrossRentCertRowsForCSV =
  (rowheaders, sort, hasFilters) => (res) => {
    const headers = rowheaders
      .filter((header) => !COLUMNS_TO_EXCLUDE.includes(header.id))
      .map((header) => header.title.props.defaultMessage)
      .join(',');

    const grossRentCertsData = pathOr([], ['data', 'grossRentCerts'], res);
    const grCerts = sort
      ? sortAndFilterRows(grossRentCertsData, sort.fieldName, sort.order)
      : grossRentCertsData;
    const rows = grCerts.map((grCert) => {
      const {
        unit,
        floorPlanName,
        headOfHousehold,
        newGrossRent,
        newHUDUA,
        grEffectiveDate,
        anticipatedVoucherDate,
        priorCertificationDate,
        otherCertInProgress,
        residentSignatureRequired,
        isCorrection,
        generated59a,
        occupiedOnNTV,
      } = grCert;
      const uploadedDocument = pathOr('', ['document', 'createdAt'], grCert);

      const { subsidy, residentRent, utilityReimbursement } =
        parseNewAssistanceDetails(grCert);

      const finalFields = [
        unit,
        floorPlanName,
        headOfHousehold.split(', ').reverse().join(' '),
        formatMoney(newGrossRent).replace(/,/g, ''),
        formatMoney(newHUDUA).replace(/,/g, ''),
        formatDate(grEffectiveDate),
        formatDate(anticipatedVoucherDate),
        !priorCertificationDate ? '---' : formatDate(priorCertificationDate),
        otherCertInProgress,
        occupiedOnNTV,
        subsidy === '***' ? '---' : subsidy.replace(/,/g, ''),
        residentRent === '***' ? '---' : residentRent.replace(/,/g, ''),
        utilityReimbursement === '***'
          ? '---'
          : utilityReimbursement.replace(/,/g, ''),
        !priorCertificationDate ? '---' : residentSignatureRequired,
        isCorrection,
        generated59a ? 'Yes' : '---',
        formatDate(uploadedDocument),
      ];
      return finalFields.join(',');
    });

    const csv = appendFilterTextToCSV({
      headers,
      rows,
      hasAnyFilters: hasFilters,
    });

    download(csv, 'Gross_Rent_Certifications.csv', 'text/csv;charset=utf-8');
  };
