import moment from 'moment';
import messages from '../messages';
import { getHighlightColor } from './general/getHighlightColor';
import type { Highlight, TransfersInProcessEntity } from '../types';
import { getUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';

type TransfersInProcessHeader = {
  id: number,
  text: Object,
  sortKey: 'unit' | 'resident' | 'scheduledTransferDate' | 'assignedTo',
};

type MappedTransfersInProcess = {
  id: string,
  unit: string,
  resident: string,
  scheduledTransferDate: string,
  assignedTo: string,
  highlight: Highlight,
};
export const mapTransfersInProcess = (
  transfersInProcess: TransfersInProcessEntity[],
): MappedTransfersInProcess => {
  return transfersInProcess.map((task: TransfersInProcessEntity) => {
    const transferDeadline = task.transfer_movein_date;
    const userAssigned = task.assigned_to;
    const primaryResident = task.name;
    const p2 = primaryResident
      ? {
          link: getUrlWithSelectedPropertyId(`/resident/${task.resident_id}`),
          value: primaryResident,
        }
      : 'N/A';

    const highlight = getHighlightColor({
      checkDate: task.transfer_movein_date,
      status: task.userStatus,
    });

    return {
      id: task.id,
      unit: task.unit,
      resident: p2,
      scheduledTransferDate: transferDeadline
        ? moment(transferDeadline).format('MM/DD/YYYY')
        : 'N/A',
      assignedTo: userAssigned ? userAssigned : 'N/A',
      highlight,
    };
  });
};

export const transferInProcessHeaders = (
  intl: Object,
): TransfersInProcessHeader[] => [
  {
    id: 1,
    text: intl.formatMessage(messages.unitHeader),
    sortKey: 'unit',
  },
  {
    id: 2,
    text: intl.formatMessage(messages.residentHeader),
    sortKey: 'resident',
  },
  {
    id: 3,
    text: intl.formatMessage(messages.scheduledTransferDateHeader),
    sortKey: 'scheduledTransferDate',
  },
  {
    id: 4,
    text: intl.formatMessage(messages.assignedToHeader),
    sortKey: 'assignedTo',
  },
];
