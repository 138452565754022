import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FullContent from './FullContent';
import NoMappingScreen from './NoMappingScreen';
import LoadingCard from './LoadingCard';
import { useIsPropertyMapped } from './hooks';
import messages from './messages';
import useFormatMessage from '../../hooks/useFormatMessage';

function ReputationMgmt() {
  const { reputationMgmtRelease2 } = useFlags();
  const formatMessage = useFormatMessage();
  const { isPropertyMapped, isLoading } = useIsPropertyMapped();

  const renderContent = () => {
    if (!reputationMgmtRelease2) return <FullContent />;
    if (isLoading) return <LoadingCard />;
    return isPropertyMapped ? <FullContent /> : <NoMappingScreen />;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">{formatMessage(messages.title)}</Typography>
      </Grid>
      <Grid item xs={12}>
        {renderContent()}
      </Grid>
    </Grid>
  );
}

export default ReputationMgmt;
