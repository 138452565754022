import { get, post, postDownload } from '../utils/api';

export default class ProrateCalculatorService {
  downloadProrateCalcScreen({
    organizationId,
    propertyId,
    formData,
    fileName,
    options,
  }: Object) {
    return postDownload(
      `/${organizationId}/${propertyId}/prorate-calculator/download`,
      fileName,
      JSON.stringify({ formData }),
      options,
    );
  }

  async sendProrateCalculatorEmail(organizationId, propertyId, payload) {
    return post(
      `/${organizationId}/${propertyId}/prorate-calculator/send-email`,
      JSON.stringify({ formData: payload }),
    );
  }

  async getProrateCalculatorRecipients(organizationId, propertyId) {
    return get(
      `/${organizationId}/${propertyId}/prorate-calculator/recipients`,
    );
  }
}
