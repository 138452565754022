export const GET_UNDEPOSITED_PAYMENT_BATCHES =
  'app/ManageUndepositedPayments/GET_UNDEPOSITED_PAYMENT_BATCHES';
export const GET_UNDEPOSITED_PAYMENT_BATCHES_SUCCESS =
  'app/ManageUndepositedPayments/GET_UNDEPOSITED_PAYMENT_BATCHES_SUCCESS';
export const GET_UNDEPOSITED_PAYMENT_BATCHES_FAILURE =
  'app/ManageUndepositedPayments/GET_UNDEPOSITED_PAYMENT_BATCHES_FAILURE';

export const GET_UNDEPOSITED_PAYMENTS =
  'app/ManageUndepositedPayments/GET_UNDEPOSITED_PAYMENTS';
export const GET_UNDEPOSITED_PAYMENTS_SUCCESS =
  'app/ManageUndepositedPayments/GET_UNDEPOSITED_PAYMENTS_SUCCESS';
export const GET_UNDEPOSITED_PAYMENTS_FAILURE =
  'app/ManageUndepositedPayments/GET_UNDEPOSITED_PAYMENTS_FAILURE';

export const CREATE_DEPOSIT = 'app/ManageUndepositedPayments/CREATE_DEPOSIT';
export const CREATE_DEPOSIT_SUCCESS =
  'app/ManageUndepositedPayments/CREATE_DEPOSIT_SUCCESS';
export const CREATE_DEPOSIT_FAILURE =
  'app/ManageUndepositedPayments/CREATE_DEPOSIT_FAILURE';

export const SELECT_FOR_DEPOSIT =
  'app/ManageUndepositedPayments/SELECT_FOR_DEPOSIT';
export const UNSELECT_FOR_DEPOSIT =
  'app/ManageUndepositedPayments/UNSELECT_FOR_DEPOSIT';

export const CLICK_VIEW_PAYMENT_BATCH =
  'app/ManageUndepositedPayments/CLICK_VIEW_PAYMENT_BATCH';

export const CLEAR_STATE = 'app/ManageUndepositedPayments/CLEAR_STATE';

export const CLEAR_SELECTIONS =
  'app/ManageUndepositedPayments/CLEAR_SELECTIONS';
