import { useMemo } from 'react';
import { Card } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import type { Review, ResponseSubmitSuccessHandler } from '../types';
import useFormatMessage from '../../../hooks/useFormatMessage';
import { ReviewsCardHeader } from '../ReviewsCardHeader';
import { ReviewsCardContent } from '../ReviewsCardContent';
import { ReviewsCardFooter } from '../ReviewsCardFooter';
import { ReviewsFilters } from '../ReviewsFilter';
import type {
  FilterValues,
  DateFilterType,
  DateFilterTypeChangeHandler,
} from '../ReviewsFilter/types';
import type {
  ClearAllEventHandler,
  RemoveFilterEventHandler,
} from '../../../components/AppliedFilters/types';

export type Props = {
  reviews: Review[],
  avgRating: number,
  recommendedPercent: number,
  totalReviews: number,
  showSpinner?: boolean,
  onSearch: (searchValue: string) => void,
  onLoadMore: Function,
  selectedSortingOption: 'most-recent' | 'oldest' | 'highest' | 'lowest',
  onSortingOptionChange: (sortingValue: string) => void,
  filterValues: FilterValues,
  onFilterValuesChange: () => void,
  showFilters: boolean,
  onFilterToggle: (isShown: boolean) => void,
  dateFilterType: DateFilterType,
  onDateFilterTypeChange: DateFilterTypeChangeHandler,
  onClearAllFilters: ClearAllEventHandler,
  onRemoveFilter: RemoveFilterEventHandler,
  onResponseDeleteSuccess: Function,
  ratingType: string,
  onResponseSubmitSuccess: ResponseSubmitSuccessHandler,
};

function ReviewsCard({
  reviews,
  totalReviews,
  avgRating,
  showSpinner,
  onSearch,
  onLoadMore,
  selectedSortingOption,
  onSortingOptionChange,
  filterValues,
  onFilterValuesChange,
  showFilters,
  onFilterToggle,
  dateFilterType,
  onDateFilterTypeChange,
  onClearAllFilters,
  onRemoveFilter,
  onResponseDeleteSuccess,
  ratingType,
  recommendedPercent,
  onResponseSubmitSuccess,
}: Props) {
  const formatMessage = useFormatMessage();
  const isFiltersPopulated = useMemo(() => {
    return (
      filterValues.timeFrame ||
      filterValues.fromDate ||
      filterValues.toDate ||
      filterValues.status ||
      filterValues.ratings?.length > 0
    );
  }, [filterValues]);

  return (
    <Card>
      <Grid container padding={4}>
        <ReviewsCardHeader
          formatMessage={formatMessage}
          reviewCount={totalReviews}
          avgRating={avgRating}
          showSpinner={showSpinner}
          onSearch={onSearch}
          selectedSortingOption={selectedSortingOption}
          onSortingOptionChange={onSortingOptionChange}
          isFilterActive={showFilters}
          onFilterToggle={onFilterToggle}
          recommendedPercent={recommendedPercent}
          ratingType={ratingType}
        />
        {showFilters && (
          <ReviewsFilters
            values={filterValues}
            dateFilterType={dateFilterType}
            onFilterValuesChange={onFilterValuesChange}
            onDateFilterTypeChange={onDateFilterTypeChange}
            onClearAll={onClearAllFilters}
            onRemoveFilter={onRemoveFilter}
            ratingType={ratingType}
          />
        )}
        <ReviewsCardContent
          reviews={reviews}
          formatMessage={formatMessage}
          showSpinner={showSpinner}
          isFiltersPopulated={isFiltersPopulated}
          onResponseDeleteSuccess={onResponseDeleteSuccess}
          onResponseSubmitSuccess={onResponseSubmitSuccess}
        />
        <ReviewsCardFooter
          reviews={reviews}
          totalReviews={totalReviews}
          onLoadMore={onLoadMore}
          showSpinner={showSpinner}
        />
      </Grid>
    </Card>
  );
}

export default ReviewsCard;
