import { useState, useEffect } from 'react';

import UnitService from '../../services/unitService';
import FiscalPeriodsService from '../../services/fiscalPeriodsService';
import PropertySubjournalsService from '../../services/propertySubjournalTypesService';
import PropertyTransactionCodesService from '../../services/propertyTransactionCodesService';
import OnlinePaymentsService from '../../services/onlinePaymentsService';

export const useFetchPropertyTransactionCodesForProperty = ({
  propertyId,
  organizationId,
}: FetchCamRecordStatusHookProps): any => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };

    const fetchData = async () => {
      if (organizationId && propertyId) {
        setIsLoading(true);
        const ptcService = new PropertyTransactionCodesService();
        const response = await ptcService.getPropertyTransactionCodes(
          organizationId,
          propertyId,
          options,
        );
        setData(response);
        setIsLoading(false);
      }
    };

    fetchData();
    setShouldRefresh(false);

    return () => abortController.abort();
  }, [organizationId, propertyId, shouldRefresh]);

  return [data, isLoading, () => setShouldRefresh(true)];
};

export const useFetchSubjournalsForProperty = ({
  propertyId,
  organizationId,
}: FetchCamRecordStatusHookProps): any => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetchData = async () => {
      if (organizationId && propertyId) {
        setIsLoading(true);
        const subjournalsService = new PropertySubjournalsService();
        const response = await subjournalsService.getPropertySubjournalTypes(
          organizationId,
          propertyId,
          abortController.signal,
        );
        setData(response);
        setIsLoading(false);
      }
    };

    fetchData();
    setShouldRefresh(false);

    return () => abortController.abort();
  }, [organizationId, propertyId, shouldRefresh]);

  return [data, isLoading, () => setShouldRefresh(true)];
};

export const useFetchOpenFiscalPeriodForProperty = ({
  propertyId,
  organizationId,
}: FetchCamRecordStatusHookProps): any => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };

    const fetchData = async () => {
      if (organizationId && propertyId) {
        setIsLoading(true);
        const fiscalPeriodsService = new FiscalPeriodsService();
        const response = await fiscalPeriodsService.getPropertyOpenFiscalPeriod(
          propertyId,
          organizationId,
          options,
        );
        setData(response);
        setIsLoading(false);
      }
    };

    fetchData();
    setShouldRefresh(false);

    return () => abortController.abort();
  }, [organizationId, propertyId, shouldRefresh]);

  return [data, isLoading, () => setShouldRefresh(true)];
};

export const useFetchOccupiedUnitsForProperty = ({
  propertyId,
  organizationId,
}: FetchCamRecordStatusHookProps): any => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };

    const fetchData = async () => {
      if (organizationId && propertyId) {
        setIsLoading(true);
        const unitsService = new UnitService();
        const response = await unitsService.getOccupiedUnitsForProperty(
          propertyId,
          organizationId,
          options,
        );
        setData(response);
        setIsLoading(false);
      }
    };

    fetchData();
    setShouldRefresh(false);

    return () => abortController.abort();
  }, [organizationId, propertyId, shouldRefresh]);

  return [data, isLoading, () => setShouldRefresh(true)];
};

export const useFetchPropertyConfig = ({
  organizationId,
  propertyId,
  flags,
}) => {
  const [propertyConfig, setPropertyConfig] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { paymentsV1 } = flags;
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchPropertyConfig = async () => {
      setIsLoading(true);
      const onlinePaymentsService = new OnlinePaymentsService();
      const response = await onlinePaymentsService.getPropertyConfig(
        organizationId,
        propertyId,
        abortController.signal,
      );
      setPropertyConfig(response);
      setIsLoading(false);
    };
    if (organizationId && propertyId && paymentsV1) fetchPropertyConfig();

    return () => abortController.abort();
  }, [organizationId, propertyId, paymentsV1]);

  return { propertyConfig, isLoading };
};
