import React, { useState } from 'react';

import { useAsyncSqFtHistories } from './hooks';
import { formatSqFtStr } from './helpers';

import UnitSqFtInformationList from './UnitSqFtInformationList';

const UnitSqFtInformation = ({
  sqFt: { grossSqFt, rentableSqFt, usableSqFt },
  selectedProperty,
  unitId,
  intl,
  refreshUnitCallback,
}: Object) => {
  const [open, setOpen] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { usable, rentable } = useAsyncSqFtHistories(
    selectedProperty,
    unitId,
    refresh,
  );

  return (
    <div className="panel__details">
      <div className="row">
        <div className="col-xs-12">
          <h3>Unit sq ft information</h3>
        </div>
        <div className="accordion panel-group">
          <UnitSqFtInformationListStatic sqFt={grossSqFt} label="Gross SqFt" />
          <UnitSqFtInformationList
            label="Rentable SqFt"
            editable={true}
            sqFt={rentableSqFt}
            histories={rentable}
            selectedProperty={selectedProperty}
            unitId={unitId}
            type="rentable"
            handleRefresh={setRefresh}
            refresh={refresh}
            intl={intl}
            open={open}
            setOpen={setOpen}
            refreshUnitCallback={refreshUnitCallback}
          />
          <UnitSqFtInformationList
            label="Usable SqFt"
            editable={true}
            sqFt={usableSqFt}
            histories={usable}
            selectedProperty={selectedProperty}
            unitId={unitId}
            type="usable"
            handleRefresh={setRefresh}
            refresh={refresh}
            intl={intl}
            open={open}
            setOpen={setOpen}
            refreshUnitCallback={refreshUnitCallback}
          />
        </div>
      </div>
    </div>
  );
};

const UnitSqFtInformationListStatic = ({ label, sqFt }) => {
  return (
    <div className="panel panel-default no-border-top">
      <div className="panel-heading">
        <div className="panel-title">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5">
                <label>{label}</label>
              </div>
              <div className="col-md-5">{formatSqFtStr(sqFt)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitSqFtInformation;
