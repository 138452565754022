import React from 'react';
import { Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import { pathOr, isNil } from 'ramda';

import { formatCurrency } from '../../../../utils/index';
import {
  getCamPeriodsDropdownOptions,
  getCamPoolsDropdownOptions,
} from '../../../../utils/cam';
import DashesIfNullOrUndefined from '../../../../components/DashesIfNullOrUndefined';
import FormattedDateOrDashes from '../../../../components/FormattedDateOrDashes';

import type { TransactionRowProps } from './types';
import { DEFAULT_OPTION } from './constants';

const TransactionRow = ({
  transaction,
  setTransaction,
  camPools,
  intl,
}: TransactionRowProps) => {
  const postDate = pathOr(null, ['transactionDate'], transaction);
  const code = pathOr(null, ['transactionCode'], transaction);
  const amount = pathOr(null, ['transactionAmount'], transaction);
  const camRecord = pathOr(DEFAULT_OPTION.value, ['camRecordsId'], transaction);
  const camPool = pathOr(
    DEFAULT_OPTION.value,
    ['camAllocationsId'],
    transaction,
  );
  const colId = pathOr(null, ['customerOpsLedgerId'], transaction);

  const formattedAmount = amount === 0 ? '$0' : formatCurrency(intl, +amount);
  const displayAmount = amount !== null ? formattedAmount : '---';

  const showRequiredCamRecord =
    isNil(camRecord) || camRecord === DEFAULT_OPTION.value;
  const showRequiredCamPool =
    isNil(camPool) || camPool === DEFAULT_OPTION.value;

  const onCamRecordChange = (evt: any) => {
    // Cam Period changes, update it and select default for the Pool
    const camRecordsId = evt.target.value;
    const camAllocationsId = DEFAULT_OPTION.value;
    setTransaction(colId, camRecordsId, camAllocationsId);
  };

  const onCamPoolChange = (evt: any) => {
    const camAllocationsId = evt.target.value;
    setTransaction(colId, camRecord, camAllocationsId);
  };

  const setFormValues = (values: Object) => {
    const newCamPool = values.camPool || DEFAULT_OPTION.value;
    const newCamRecord = values.camRecord || DEFAULT_OPTION.value;
    setTransaction(colId, newCamRecord, newCamPool);
  };

  const getCamPeriodsOptions = () =>
    getCamPeriodsDropdownOptions(camPools, DEFAULT_OPTION, setFormValues, {
      camRecord,
      camPool,
    });

  const getCamPoolsOptions = () =>
    getCamPoolsDropdownOptions(camPools, DEFAULT_OPTION, setFormValues, {
      camRecord,
      camPool,
    });

  return (
    <Row key={colId}>
      <Col md={2} xs={4}>
        <FormattedDateOrDashes value={postDate} format="MM/DD/YYYY" />
      </Col>
      <Col md={2} xs={4}>
        <DashesIfNullOrUndefined data={code} />
      </Col>
      <Col md={2} xs={4}>
        {displayAmount}
      </Col>
      <Col md={3} xs={6}>
        <FormGroup>
          <FormControl
            data-default-value-selected={camRecord !== DEFAULT_OPTION.value}
            componentClass="select"
            onChange={onCamRecordChange}
            input={{ value: camRecord }}
            value={camRecord}
          >
            {getCamPeriodsOptions()}
          </FormControl>
          {showRequiredCamRecord && <p className="text-danger">Required</p>}
        </FormGroup>
      </Col>
      <Col md={3} xs={6}>
        <FormGroup>
          <FormControl
            data-default-value-selected={camPool !== DEFAULT_OPTION.value}
            componentClass="select"
            onChange={onCamPoolChange}
            input={{ value: camPool }}
            value={camPool}
          >
            {getCamPoolsOptions()}
          </FormControl>
          {showRequiredCamPool && <p className="text-danger">Required</p>}
        </FormGroup>
      </Col>
    </Row>
  );
};

export default TransactionRow;
