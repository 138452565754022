import { any, defaultTo, or } from 'ramda';
import { validateRequired } from '../../../utils/validations';

import formMessages from './messages';

import type { BasicInformation } from './types';

const warn = (
  basicInfo: BasicInformation,
  { intl, isAffordable, validateMultiRace = false }: Object,
) => {
  const warnings = {};
  const values = defaultTo({})(basicInfo);

  if (!validateRequired('firstName', values)) {
    warnings.firstName = intl.formatMessage(formMessages.requiredForCompletion);
  }
  if (!validateRequired('lastName', values)) {
    warnings.lastName = intl.formatMessage(formMessages.requiredForCompletion);
  }
  if (!validateRequired('birthday', values)) {
    warnings.birthday = intl.formatMessage(formMessages.requiredForCompletion);
  }
  if (isAffordable === true && !validateRequired('gender', values)) {
    warnings.gender = intl.formatMessage(formMessages.requiredForCompletion);
  }

  if (
    !validateMultiRace &&
    isAffordable === true &&
    !validateRequired('race', values)
  ) {
    warnings.race = intl.formatMessage(formMessages.requiredForCompletion);
  }

  if (
    isAffordable === true &&
    validateMultiRace &&
    !validateRequired('multiRace', values)
  ) {
    warnings.multiRace = intl.formatMessage(formMessages.requiredForCompletion);
  }

  if (isAffordable === true && !validateRequired('veteran', values)) {
    warnings.veteran = intl.formatMessage(formMessages.requiredForCompletion);
  }
  if (
    !or(
      validateRequired('socialSecurityNumber', values),
      any((fieldName) => validateRequired(fieldName, values))([
        'stateIdNumber',
        'passportNumber',
        'alienRegistration',
      ]),
    )
  ) {
    const provideMessage = !isAffordable
      ? intl.formatMessage(formMessages.provideOne)
      : intl.formatMessage(formMessages.provideOneAffordable);
    warnings.socialSecurityNumber = provideMessage;
    warnings.stateIdNumber = provideMessage;
    warnings.passportNumber = provideMessage;
    warnings.alienRegistration = provideMessage;
  }

  return warnings;
};

export default warn;
