import type { Action } from '../App/types';
import {
  GET_MONTH_END_CLOSE,
  GET_MONTH_END_CLOSE_SUCCESS,
  GET_MONTH_END_CLOSE_FAILURE
} from './constants';

export const initialState = {
  hasSavedPayments: true,
  hasUndepositedPayments: true,
  period: ''
};

const monthEndCloseReducer = (
  state: Object = initialState,
  { type, payload }: Action<*, *>
) => {
  switch (type) {
    case GET_MONTH_END_CLOSE:
      return initialState;
    case GET_MONTH_END_CLOSE_SUCCESS:
      return payload;
    case GET_MONTH_END_CLOSE_FAILURE:
      return { ...state, payload, error: true };
    default:
      return state;
  }
};
export default monthEndCloseReducer;
