import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector, reset, submit } from 'redux-form';
import moment from 'moment';
import { Col, Modal, Row } from 'react-bootstrap';
// $FlowFixMe
import { isNil, pathOr, propEq, flatten, sortBy, prop } from 'ramda';
import PaymentForm from './PaymentForm';
import type {
  OccupiedUnit,
  PaymentFormValues,
  PropertyTransactionCode,
  Subjournal,
} from './types';

type StateProps = {
  formValues: PaymentFormValues,
};

type FormProps = {
  resetForm: Function,
  submitForm: Function,
};

type Props = {
  batchDate?: string,
  detailId?: string,
  initialValues?: {
    unitId: string,
    subjournal: string,
    receivedFrom: string,
    transactionCode: string,
    documentNumber?: string,
    note?: string,
    amount?: number,
    householdId: string,
    householdStatus: string,
  },
  onClose: Function,
  onSave: Function,
  onSaveAndAdd?: Function,
  reloadHouseholds: Function,
  propertyCodes: PropertyTransactionCode[],
  propertyName: string,
  propertySubjournals: Subjournal[],
  show: boolean,
  units: OccupiedUnit[],
  allUnits: OccupiedUnit[],
  editMode: boolean,
} & StateProps &
  FormProps;

type State = {
  submitType: string,
  canceling: boolean,
  heldFormValues: PaymentFormValues | null,
};

const SAVE_AND_CLOSE = 'SAVE_AND_CLOSE';
const SAVE_AND_NEW = 'SAVE_AND_NEW';

class PaymentModal extends React.Component<Props, State> {
  state = {
    submitType: SAVE_AND_CLOSE,
    canceling: false,
    heldFormValues: null,
  };
  static defaultProps = {
    initialValues: {
      householdStatus: 'Current Resident',
      householdId: '',
      unitSearch: {},
      unitId: 'default',
      subjournal: 'default',
      receivedFrom: 'default',
      transactionCode: 'default',
    },
  };
  onSubmit = (values: any) => {
    const { submitType } = this.state;
    const { onSave, onSaveAndAdd, resetForm, detailId } = this.props;

    if (submitType === SAVE_AND_CLOSE) onSave(values, detailId);
    else if (submitType === SAVE_AND_NEW && onSaveAndAdd) {
      onSaveAndAdd(values, detailId);
      resetForm('paymentForm');
    }
  };
  onHouseholdStatusChange = () => {
    const { reloadHouseholds } = this.props;
    reloadHouseholds();
  };

  getUnitFrNames(unitId: string) {
    const { units } = this.props;
    const selectedUnits = units.filter(propEq('id', unitId));
    if (!selectedUnits || selectedUnits.length === 0) return [];
    return flatten(selectedUnits.map((u) => u.frMembers)).sort();
  }

  getSearchableUnitAndNames() {
    const sortByUnit = sortBy(prop('label'));
    const { units } = this.props;
    const optionsMap = units.map((unit) => {
      return unit.frMembers.map((member) => ({
        value: {
          unitId: unit.id,
          receivedFrom: member,
          householdId: unit.householdId,
        },
        label: `${unit.number} - ${member}`,
      }));
    });
    if (!optionsMap) return [];
    return sortByUnit(flatten(optionsMap));
  }
  getResidentLedgerBalance(householdId) {
    const { units } = this.props;

    const unit = units.find(propEq('householdId', householdId));

    return unit?.balances?.['Resident'] ?? 0;
  }
  handleClose = () => {
    this.setState({ heldFormValues: null });
    this.props.onClose();
  };
  showCancelation = () =>
    this.setState({
      ...this.state,
      canceling: true,
      heldFormValues: this.props.formValues,
    });
  hideCancelation = () => this.setState({ ...this.state, canceling: false });
  renderCancelConfirmation = () => {
    return (
      <div className="modal-confirm">
        <h1>Are you sure you want to leave without saving?</h1>
        <Col xs={12} sm={6}>
          <button
            className="btn btn-primary pull-right"
            onClick={this.handleClose}
          >
            Yes
          </button>
        </Col>
        <Col xs={12} sm={6}>
          <button
            className="btn btn-default pull-left"
            onClick={this.hideCancelation}
          >
            No
          </button>
        </Col>
      </div>
    );
  };
  renderForm() {
    const {
      batchDate,
      formValues,
      initialValues,
      propertyCodes,
      propertyName,
      propertySubjournals,
      units,
      allUnits,
      editMode,
    } = this.props;
    const { heldFormValues } = this.state;
    const { unitId, householdId } = formValues;
    const frNames = this.getUnitFrNames(unitId);
    return (
      <div className="scrollable-modal__content row">
        <div className="container-fluid">
          <PaymentForm
            date={batchDate ? moment(batchDate).format('MM/DD/YYYY') : null}
            financiallyResponsibleNames={frNames}
            formValues={formValues}
            initialValues={heldFormValues || initialValues}
            onSubmit={this.onSubmit}
            currentResidentLedgerBalance={this.getResidentLedgerBalance(
              householdId,
            )}
            searchableValues={this.getSearchableUnitAndNames()}
            propertyName={propertyName}
            frNames={frNames}
            subjournals={propertySubjournals}
            propertyCodes={propertyCodes}
            units={units}
            allUnits={allUnits}
            onHouseholdStatusChange={this.onHouseholdStatusChange}
            editMode={editMode}
          />
        </div>
      </div>
    );
  }
  renderFooter() {
    return (
      <Modal.Footer>
        <Row>
          <Col xs={4}>
            <button
              className="btn btn-default pull-left"
              onClick={this.showCancelation}
            >
              Cancel
            </button>
          </Col>
          <Col xs={4}>
            <button
              className="btn btn-primary"
              type="submit"
              onClick={() => {
                this.props.submitForm('paymentForm');
                this.setState({ ...this.state, submitType: SAVE_AND_CLOSE });
              }}
            >
              Save
            </button>
          </Col>
          <Col xs={4}>
            <button
              className="btn btn-primary pull-left"
              disabled={isNil(this.props.onSaveAndAdd)}
              type="submit"
              onClick={() => {
                this.props.submitForm('paymentForm');
                this.setState({ ...this.state, submitType: SAVE_AND_NEW });
              }}
            >
              Save & Add New
            </button>
          </Col>
        </Row>
      </Modal.Footer>
    );
  }
  render() {
    const { canceling } = this.state;

    return (
      <Modal
        className="transaction"
        bsSize="small"
        show={this.props.show}
        onEnter={() => this.setState({ ...this.state, canceling: false })}
        onHide={this.showCancelation}
        autoFocus
      >
        <Modal.Header closeButton>
          <i className="icon et-money" />
          <h1>Payment Transaction</h1>
        </Modal.Header>
        <Modal.Body>
          {canceling ? this.renderCancelConfirmation() : this.renderForm()}
        </Modal.Body>
        {!canceling && this.renderFooter()}
      </Modal>
    );
  }
}

const selector = formValueSelector('paymentForm');

export default connect(
  (state) => ({
    selectedUnitId: selector(state, 'unitId'),
    selectedSubjournalId: selector(state, 'subjournal'),
    formValues: pathOr({}, ['form', 'paymentForm', 'values'], state),
  }),
  { submitForm: submit, resetForm: reset },
)(PaymentModal);
