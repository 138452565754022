import { defineMessages } from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'App.ProspectProfile.ProspectProfileForm.PhoneNumber.Placeholder',
    defaultMessage: 'Phone number'
  },
  title: {
    id: 'App.ProspectProfile.ProspectProfileForm.PhoneNumber.Title',
    defaultMessage: 'Phone number  *'
  }
});

export default messages;
