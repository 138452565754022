import React from 'react';

import { Col } from 'react-bootstrap';
import { pathOr } from 'ramda';

import { formatDate } from '../../../utils/formatter';
import Table from '../../../components/Table/index.js';
import Spinner from '../../../components/Spinner';
import { HEADER_ROWS, HEADER_NOTES, FOOTER_NOTE } from './constants';
import { ProrateDetails } from './ProrateDetails';
import { ProrateTotals } from './ProrateTotals';

import type { RenewalProratesProps } from './types';

export const RenewalProrates = (props: RenewalProratesProps) => {
  const {
    isLoadingRenewalProrateData,
    prorates,
    renewalLease,
    doNotProrateTransactionCodes,
    onDoNotProrateClicked,
    totals,
  } = props;

  const renewalStartDate = pathOr(null, ['startDate'], renewalLease);
  const proratedCharges = pathOr([], ['prorates', 'proratedCharges'], props);
  const proratePeriod = pathOr(null, ['proratePeriod'], prorates);

  const headerNote = pathOr('', [proratePeriod], HEADER_NOTES);

  return (
    <>
      {isLoadingRenewalProrateData && (
        <div className="loading-container text-center">
          <Spinner small data-test="prorate-spinner" />
        </div>
      )}

      <Col xs={12} data-test="prorate-container">
        <h3>PRORATE AMOUNTS FOR LEASE RENEWAL</h3>
        <h4 data-test="prorate-start-date-header">
          Lease Renewal Start Date: {formatDate(renewalLease.startDate)}
        </h4>
        <p data-test="prorate-header-note">{headerNote}</p>
        <Table name="Prorates" headerRows={HEADER_ROWS} onSort={() => {}}>
          <ProrateDetails
            proratedCharges={proratedCharges}
            onDoNotProrateClicked={onDoNotProrateClicked}
            doNotProrateTransactionCodes={doNotProrateTransactionCodes}
          />

          <ProrateTotals
            totals={totals}
            renewalStartDate={renewalStartDate}
            proratePeriod={proratePeriod}
          />
        </Table>

        <p data-test="prorate-footer-note">{FOOTER_NOTE}</p>
      </Col>
    </>
  );
};
