import { defineMessages } from 'react-intl';

const messages = defineMessages({
  manageUnitAmenities: {
    id: 'App.ViewUnit.UnitAmenitiesV2.ManageUnitAmenities',
    defaultMessage: 'Manage Unit Amenities',
  },
  navigateToAddNewPropertyAmenity: {
    id: 'App.ViewUnit.UnitAmenitiesV2.NavigateToAddNewPropertyAmenity',
    defaultMessage: 'Navigate to Add New Property Amenity',
  },
  allUnitAmenitiesIncludedInMarketAndQuotingRent: {
    id: 'App.ViewUnit.UnitAmenitiesV2.AllUnitAmenitiesIncludedInMarketAndQuotingRent',
    defaultMessage:
      'All unit amenities are included in Market Rent and Quoting Rent',
  },
});

export default messages;
