import React from 'react';
import moment from 'moment';
import { Col, Grid, Row } from 'react-bootstrap';

import messages from './messages';
import TasksTable from './TasksTableDeprecated';
import type { TaskInformation } from '../types';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  tasks: TaskInformation,
  assignees?: Array<Object>,
  selectedPropertyClass: string,
  intl: Object,
  onChangeAssignee?: Function,
  isTasksLoading: boolean,
};

export const assignType = {
  APPLICATION: 'application',
  PROSPECT: 'prospect',
};

let flag;
const getHighlightColor = (
  checkDate,
  status = 'none',
  checkEndDate = undefined,
  type = 'basic',
  isRed: number = 0,
  isLeaseSignedAndNotExecuted = false,
  daysDue,
) => {
  const today = moment();
  const highlight = { red: false, yellow: false, green: false };

  if (isLeaseSignedAndNotExecuted) {
    highlight.green = true;
  } else {
    // yellow takes precedence
    if (status === 'Inactive') {
      highlight.yellow = true;
    } else {
      switch (type) {
        case 'PendingFAS':
          if (checkDate) {
            highlight.red = moment(checkDate).isBefore(today.add(-14, 'days'));
          }
          break;
        case 'WorkOrders':
          if (checkDate) {
            if (flag && daysDue) {
              highlight.red = moment(checkEndDate).isBefore(
                today.add(-1, 'days'),
              );
            } else if (flag) {
              const daysOpen = today.diff(checkDate, 'days');
              highlight.red = daysOpen >= 3;
            } else {
              const daysOpen = today.diff(checkDate, 'days');
              highlight.red = daysOpen >= 3;
            }
          }
          break;
        case 'ProspectFollowUp':
          highlight.red = isRed === 1;
          break;
        default:
          highlight.red = moment(checkDate ? checkDate : checkEndDate).isBefore(
            today,
            'day',
          );
      }
    }
  }

  return highlight;
};

const mapRenewalsInProcess = (renewalsInProcess) => {
  return renewalsInProcess.map((task) => {
    const endDate = task.end_date;
    const userAssigned = task.assigned_to;
    const primaryResident = task.name;
    const p2 = primaryResident
      ? {
          link: `/resident/${task.resident_id}`,
          value: primaryResident,
        }
      : 'N/A';

    const isLeaseSignedAndNotExecuted =
      task.leaseSignature && task.action !== 'EXECUTED';

    const highlight = getHighlightColor(
      task.end_date,
      task.userStatus,
      null,
      null,
      null,
      isLeaseSignedAndNotExecuted,
    );

    return {
      id: task.id,
      p1: task.unit,
      p2,
      p3: endDate ? moment(endDate).format('MM/DD/YYYY') : 'N/A',
      p4: userAssigned ? userAssigned : 'N/A',
      highlight,
    };
  });
};
const mapTransfersInProcess = (transfersInProcess) => {
  return transfersInProcess.map((task) => {
    const transferDeadline = task.transfer_movein_date;
    const userAssigned = task.assigned_to;
    const primaryResident = task.name;
    const p2 = primaryResident
      ? {
          link: `/resident/${task.resident_id}`,
          value: primaryResident,
        }
      : 'N/A';
    const highlight = getHighlightColor(
      task.transfer_movein_date,
      task.userStatus,
    );
    return {
      id: task.id,
      p1: task.unit,
      p2,
      p3: transferDeadline
        ? moment(transferDeadline).format('MM/DD/YYYY')
        : 'N/A',
      p4: userAssigned ? userAssigned : 'N/A',
      highlight,
    };
  });
};
const mapScheduledMoveOuts = (scheduledMoveOuts) => {
  return scheduledMoveOuts.map((task) => {
    const moveOutDate = moment(task.move_out);
    const userAssigned = task.assigned_to;
    const primaryResident = task.name;
    const p2 = primaryResident
      ? {
          link: `/resident/${task.resident_id}`,
          value: primaryResident,
        }
      : 'N/A';
    const highlight = getHighlightColor(task.move_out, task.userStatus);

    return {
      id: task.id,
      p1: task.unit,
      p2,
      p3: moveOutDate ? moveOutDate.format('MM/DD/YYYY') : 'N/A',
      p4: userAssigned ? userAssigned : 'N/A',
      highlight,
    };
  });
};
const mapPendingFAS = (pendingFAS) => {
  return pendingFAS.map((task) => {
    const moveOutDate = task.move_out ? moment(task.move_out) : null;
    const primaryResident = task.name;
    const p2 = primaryResident
      ? {
          link: `/prior-resident/${task.resident_id}`,
          value: primaryResident,
        }
      : 'N/A';

    let highlight = getHighlightColor(task.move_out, null, null, 'PendingFAS');

    return {
      id: task.id,
      p1: task.unit,
      p2,
      p3: moveOutDate ? moveOutDate.format('MM/DD/YYYY') : 'N/A',
      p4: moveOutDate
        ? moment(moveOutDate).add(14, 'days').format('MM/DD/YYYY')
        : 'N/A',
      highlight,
    };
  });
};
const mapApplicationsInProcess = (
  applicationsInProcess,
  taskTabNumberOfBedroomsFlag,
) => {
  return applicationsInProcess.map((task) => {
    const moveInDateFrom = task.move_in_from;
    const moveInDateFromMoment = moment(moveInDateFrom);
    const moveInDateTo = task.move_in_to;
    const moveInDateToMoment = moment(moveInDateTo);
    let desiredMoveInDate = moveInDateFrom
      ? moveInDateFromMoment.format('MM/DD/YYYY')
      : '';
    if (moveInDateFrom && moveInDateTo) {
      desiredMoveInDate += ' - ' + moveInDateToMoment.format('MM/DD/YYYY');
    }
    const userAssigned = task.assigned_to;

    let highlight = getHighlightColor(
      task.move_in_from,
      task.userStatus,
      task.move_in_to,
    );
    if (taskTabNumberOfBedroomsFlag) {
      return {
        id: task.id,
        p1: task.unit || '---',
        p2: String(task.nBeds ?? '---'),
        p3: {
          link: `/application/${task.application_id}`,
          value: task.name || 'N/A',
        },
        p4: desiredMoveInDate || 'N/A',
        p5: task.portalUser,
        p6: userAssigned
          ? {
              value: task.assignedToId,
              text: userAssigned,
              disabled: false,
              dropDownMenuAssign: assignType.APPLICATION,
            }
          : 'N/A',
        highlight,
      };
    } else {
      return {
        id: task.id,
        p1: task.unit || '---',
        p2: {
          link: `/application/${task.application_id}`,
          value: task.name || 'N/A',
        },
        p3: desiredMoveInDate || 'N/A',
        p4: task.portalUser,
        p5: userAssigned
          ? {
              value: task.assignedToId,
              text: userAssigned,
              disabled: false,
              dropDownMenuAssign: assignType.APPLICATION,
            }
          : 'N/A',
        highlight,
      };
    }
  });
};
const mapApplicationsScheduledMoveIn = (
  applicationsInProcess,
  taskTabNumberOfBedroomsFlag,
) => {
  return applicationsInProcess.map((task) => {
    const moveInDateString = task.move_in;
    const moveInDateMoment = moveInDateString ? moment(moveInDateString) : null;
    const userAssigned = task.assigned_to;

    const isLeaseSignedAndNotExecuted =
      task.leaseSignature && task.action !== 'EXECUTED';

    const highlight = getHighlightColor(
      task.move_in,
      task.userStatus,
      null,
      null,
      null,
      isLeaseSignedAndNotExecuted,
    );
    if (taskTabNumberOfBedroomsFlag) {
      return {
        id: task.id,
        p1: task.unit || '---',
        p2: String(task.nBeds ?? '---'),
        p3: {
          link: `/application/${task.application_id}`,
          value: task.name || 'N/A',
        },
        p4: moveInDateMoment ? moveInDateMoment.format('MM/DD/YYYY') : 'N/A',
        p5: userAssigned ? userAssigned : 'N/A',
        highlight,
      };
    } else {
      return {
        id: task.id,
        p1: task.unit || '---',
        p2: {
          link: `/application/${task.application_id}`,
          value: task.name || 'N/A',
        },
        p3: moveInDateMoment ? moveInDateMoment.format('MM/DD/YYYY') : 'N/A',
        p4: userAssigned ? userAssigned : 'N/A',
        highlight,
      };
    }
  });
};
const mapOpenWorkOrders = (openWorkOrders) => {
  var today = moment();
  const workOrders = openWorkOrders.sort((a, b) => {
    const dueDateA = moment(a.created_at).add(a.numDaysDue, 'days');
    const dueDateB = moment(b.created_at).add(b.numDaysDue, 'days');
    return dueDateA - dueDateB;
  });
  return workOrders.map((task) => {
    const createdAt = moment(task.created_at);
    const daysOpen = today.diff(createdAt, 'days');
    const dueDate = createdAt.add(task.numDaysDue, 'days');

    const highlight = getHighlightColor(
      task.created_at,
      null,
      dueDate,
      'WorkOrders',
      null,
      null,
      task.numDaysDue,
    );
    if (
      !highlight.red &&
      (!task.assignedToId ||
        (task.assignedToId && task.user_status === 'Inactive'))
    ) {
      highlight.yellow = true;
    }

    if (flag) {
      const dateDue =
        task.numDaysDue !== null ? moment(dueDate).format('MM/DD/YYYY') : '---';

      return {
        id: task.id,
        p1: task.unit || 'N/A',
        p2: task.issue,
        p3: task.priority,
        p4: createdAt ? daysOpen : 'N/A',
        highlight,
        p5: dateDue,
      };
    } else {
      return {
        id: task.id,
        p1: task.unit || 'N/A',
        p2: task.issue,
        p3: task.priority,
        p4: createdAt ? daysOpen : 'N/A',
        highlight,
      };
    }
  });
};
const mapProspectsToFollowUp = (prospectsFollowUp) => {
  return prospectsFollowUp.map((task) => {
    const highlight = getHighlightColor(
      null,
      task.userStatus,
      null,
      'ProspectFollowUp',
      task.isRed,
    );

    return {
      id: task.prospectId,
      p1: { link: `/prospect/${task.prospectId}`, value: task.prospectName },
      p2: task.desiredMoveIn,
      p4: task.daysSinceContact,
      p5: task.activityCounts,
      p6: {
        value: task.assignedToId,
        text: task.assignedToName,
        disabled: false,
        dropDownMenuAssign: assignType.PROSPECT,
      },
      highlight,
    };
  });
};

const renewalsInProcesHeaders = (intl) => [
  { id: 1, text: intl.formatMessage(messages.unitHeader) },
  { id: 2, text: intl.formatMessage(messages.residentHeader) },
  { id: 3, text: intl.formatMessage(messages.leaseExpirationHeader) },
  { id: 4, text: intl.formatMessage(messages.assignedToHeader) },
];
const scheduledMoveOutHeaders = (intl) => [
  { id: 1, text: intl.formatMessage(messages.unitHeader) },
  { id: 2, text: intl.formatMessage(messages.residentHeader) },
  { id: 3, text: intl.formatMessage(messages.scheduledMoveOutDate) },
  { id: 4, text: intl.formatMessage(messages.assignedToHeader) },
];
const pendingFASHeaders = (intl) => [
  { id: 1, text: intl.formatMessage(messages.unitHeader) },
  { id: 2, text: intl.formatMessage(messages.priorResidentHeader) },
  { id: 3, text: intl.formatMessage(messages.moveOutDateHeader) },
  { id: 4, text: intl.formatMessage(messages.dueDateHeader) },
];

const openWorkOrdersHeaders = (intl) => {
  if (flag) {
    return [
      { id: 1, text: intl.formatMessage(messages.unitHeader) },
      { id: 2, text: intl.formatMessage(messages.issueHeader) },
      { id: 3, text: intl.formatMessage(messages.priorityHeader) },
      { id: 4, text: intl.formatMessage(messages.daysOpenHeader) },
      { id: 5, text: intl.formatMessage(messages.dueDateHeader) },
    ];
  } else {
    return [
      { id: 1, text: intl.formatMessage(messages.unitHeader) },
      { id: 2, text: intl.formatMessage(messages.issueHeader) },
      { id: 3, text: intl.formatMessage(messages.priorityHeader) },
      { id: 4, text: intl.formatMessage(messages.daysOpenHeader) },
    ];
  }
};

const scheduledMoveInsHeaders = (intl, taskTabNumberOfBedroomsFlag) => {
  return taskTabNumberOfBedroomsFlag
    ? [
        { id: 1, text: intl.formatMessage(messages.unitHeader) },
        { id: 2, text: intl.formatMessage(messages.numberOfBedroomsHeader) },
        { id: 3, text: intl.formatMessage(messages.applicantHeader) },
        { id: 4, text: intl.formatMessage(messages.scheduledMoveInDateHeader) },
        { id: 5, text: intl.formatMessage(messages.assignedToHeader) },
      ]
    : [
        { id: 1, text: intl.formatMessage(messages.unitHeader) },
        { id: 2, text: intl.formatMessage(messages.applicantHeader) },
        { id: 3, text: intl.formatMessage(messages.scheduledMoveInDateHeader) },
        { id: 4, text: intl.formatMessage(messages.assignedToHeader) },
      ];
};

const transferInProcessHeaders = (intl) => [
  { id: 1, text: intl.formatMessage(messages.unitHeader) },
  { id: 2, text: intl.formatMessage(messages.residentHeader) },
  { id: 3, text: intl.formatMessage(messages.scheduledTransferDateHeader) },
  { id: 4, text: intl.formatMessage(messages.assignedToHeader) },
];

const applicationsInProcessHeaders = (intl, taskTabNumberOfBedroomsFlag) => {
  return taskTabNumberOfBedroomsFlag
    ? [
        { id: 1, text: intl.formatMessage(messages.unitHeader) },
        { id: 2, text: intl.formatMessage(messages.numberOfBedroomsHeader) },
        { id: 3, text: intl.formatMessage(messages.applicantHeader) },
        { id: 4, text: intl.formatMessage(messages.desiredMoveInDateHeader) },
        { id: 5, text: intl.formatMessage(messages.portalUser) },
        { id: 6, text: intl.formatMessage(messages.assignedToHeader) },
      ]
    : [
        { id: 1, text: intl.formatMessage(messages.unitHeader) },
        { id: 2, text: intl.formatMessage(messages.applicantHeader) },
        { id: 3, text: intl.formatMessage(messages.desiredMoveInDateHeader) },
        { id: 4, text: intl.formatMessage(messages.portalUser) },
        { id: 5, text: intl.formatMessage(messages.assignedToHeader) },
      ];
};

const prospectFollowUpHeaders = (intl) => [
  { id: 1, text: intl.formatMessage(messages.prospectNameHeader) },
  { id: 2, text: intl.formatMessage(messages.desiredMoveInDateHeader) },
  { id: 3, text: intl.formatMessage(messages.daysSinceContactHeader) },
  { id: 4, text: intl.formatMessage(messages.numOfActivitiesHeader) },
  { id: 5, text: intl.formatMessage(messages.assignedToHeader) },
];

const TaskTab = ({
  tasks,
  isTasksLoading,
  intl,
  selectedPropertyClass,
  assignees,
  onChangeAssignee,
}: Props) => {
  const { workOrderDueDateTaskTab, taskTabNumberOfBedrooms } = useFlags();
  flag = workOrderDueDateTaskTab;
  const TRANSFER_FUNCTIONALITY_ENABLED =
    selectedPropertyClass === 'Conventional';

  return (
    <Row>
      <Col xs={12}>
        <Grid fluid>
          <Row>
            <Col xs={12} className="padright10 padleft15">
              <h1 className="font25 no-margin-bottom">
                {intl.formatMessage(messages.taskSummary)}
              </h1>
              <div className="legend padtop10">
                <span className="legend-color legend-color--warning" />
                <span className="small">
                  {intl.formatMessage(messages.legendRed)}
                </span>
                <span className="legend-color legend-color--inactive" />
                <span className="small">
                  {intl.formatMessage(messages.legendYellow)}
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={5} className="padright10 padleft15">
              <h1>
                <i className="et-people padright10 text-blue" />
                {intl.formatMessage(messages.residentActivity)}
              </h1>
              <TasksTable
                title={intl.formatMessage(messages.renewalsInProcessTitle)}
                headers={renewalsInProcesHeaders(intl)}
                items={mapRenewalsInProcess(tasks.renewalsInProcess)}
                intl={intl}
                showLegendGreenIcon={true}
                isTasksLoading={isTasksLoading}
              />
              {TRANSFER_FUNCTIONALITY_ENABLED && (
                <TasksTable
                  title={intl.formatMessage(messages.transfersInProcessTitle)}
                  headers={transferInProcessHeaders(intl)}
                  items={mapTransfersInProcess(tasks.transfersInProcess)}
                  intl={intl}
                  isTasksLoading={isTasksLoading}
                />
              )}
              <TasksTable
                title={intl.formatMessage(messages.scheduledMoveOutTitle)}
                headers={scheduledMoveOutHeaders(intl)}
                items={mapScheduledMoveOuts(tasks.scheduledMoveOuts)}
                intl={intl}
                isTasksLoading={isTasksLoading}
              />
              <TasksTable
                title={intl.formatMessage(messages.pendingFASTitle)}
                headers={pendingFASHeaders(intl)}
                items={mapPendingFAS(tasks.pendingFAS)}
                intl={intl}
                isTasksLoading={isTasksLoading}
              />
              <Row>
                <Col xs={12}>
                  <h1>
                    {' '}
                    <i className="et-services padright10 text-blue" />
                    Work Orders
                  </h1>
                </Col>
              </Row>
              <TasksTable
                title={intl.formatMessage(messages.openWorkOrdersTitle)}
                headers={openWorkOrdersHeaders(intl)}
                items={mapOpenWorkOrders(tasks.openWorkOrders)}
                intl={intl}
                isTasksLoading={isTasksLoading}
              />
            </Col>
            <Col xs={12} md={5} className="padright10 padleft10">
              <h1>
                {' '}
                <i className="et-sales padright10 text-blue" />
                Applicant Activity
              </h1>
              <TasksTable
                title={intl.formatMessage(messages.scheduledMoveInsTitle)}
                headers={scheduledMoveInsHeaders(intl, taskTabNumberOfBedrooms)}
                items={mapApplicationsScheduledMoveIn(
                  tasks.scheduledMoveIns,
                  taskTabNumberOfBedrooms,
                )}
                intl={intl}
                showLegendGreenIcon={true}
                isTasksLoading={isTasksLoading}
              />
              <TasksTable
                title={intl.formatMessage(messages.applicationsInProcessTitle)}
                headers={applicationsInProcessHeaders(
                  intl,
                  taskTabNumberOfBedrooms,
                )}
                assignees={assignees}
                onChangeAssignee={onChangeAssignee}
                items={mapApplicationsInProcess(
                  tasks.applicationsInProcess,
                  taskTabNumberOfBedrooms,
                )}
                intl={intl}
                isTasksLoading={isTasksLoading}
              />
              <TasksTable
                title={intl.formatMessage(messages.prospectsFollowUpTitle)}
                headers={prospectFollowUpHeaders(intl)}
                assignees={assignees}
                onChangeAssignee={onChangeAssignee}
                items={mapProspectsToFollowUp(tasks.prospectsToFollowUp)}
                intl={intl}
                isTasksLoading={isTasksLoading}
              />
            </Col>
          </Row>
        </Grid>
      </Col>
    </Row>
  );
};

export default TaskTab;
