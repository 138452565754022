import React, { useState, useMemo, useEffect, useCallback } from 'react';
import {
  Box,
  Tooltip,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { disabledStatuses } from '../../constants';

type Props = {
  hasPermission: boolean,
  hideUnitFromOnlineAvailability: boolean,
  unitStatusId: string,
  unitStatuses: { id: string, description: string }[],
  updateUnit: Function,
};

const HideUnitFromOnlineAvailabilityCheckbox = ({
  hasPermission,
  hideUnitFromOnlineAvailability,
  unitStatusId,
  unitStatuses,
  updateUnit,
}: Props) => {
  const [checked, setChecked] = useState(false);

  // [updateUnit] is not a hook and will cause issues if not memoized
  const memoizedUpdateUnit = useMemo(
    () => updateUnit,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onChange = useCallback(
    (e, checked) => {
      setChecked(checked);
      memoizedUpdateUnit(checked);
    },
    [setChecked, memoizedUpdateUnit],
  );

  const currentUnitStatus = useMemo(
    () => unitStatuses.find(({ id }) => id === unitStatusId),
    [unitStatuses, unitStatusId],
  );

  const isDisabledStatus = useMemo(() => {
    let isDisabled = false;

    if (disabledStatuses.includes(currentUnitStatus?.description)) {
      isDisabled = true;
    }

    return isDisabled;
  }, [currentUnitStatus]);

  useEffect(() => {
    if (hideUnitFromOnlineAvailability !== null) {
      let checkedValue = isDisabledStatus
        ? true
        : hideUnitFromOnlineAvailability;

      setChecked(checkedValue);
    }
  }, [hideUnitFromOnlineAvailability, isDisabledStatus]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox
        disabled={
          isDisabledStatus ||
          !hasPermission ||
          hideUnitFromOnlineAvailability === null
        }
        label={<FormattedMessage {...messages.hideUnitCheckboxLabel} />}
        onChange={onChange}
        checked={checked}
      />
      <Tooltip
        variant={'light'}
        title={<FormattedMessage {...messages.tooltipLabel} />}
      >
        <AlertInfoIcon />
      </Tooltip>
    </Box>
  );
};

HideUnitFromOnlineAvailabilityCheckbox.defaultProps = {
  hasPermission: false,
  hideUnitFromOnlineAvailability: null,
  unitStatusId: null,
  unitStatuses: [],
  updateUnit: () => {},
};

HideUnitFromOnlineAvailabilityCheckbox.displayName =
  'HideUnitFromOnlineAvailabilityCheckbox';

export default HideUnitFromOnlineAvailabilityCheckbox;
