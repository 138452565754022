import { curryN } from 'ramda';
import React from 'react';
import { ButtonGroup, Col, Grid, Row, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ColumnHeader from '../../../components/ColumnHeader';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import SearchField from '../../Fields/Search';
import Status from '../../ManageProspects/Status';
import CommunicationsDetails from '../CommunicationsDetails';
import { Filters } from './Filters';
import headers from './headers';
import messages from './messages';
import { FilterButton } from './FilterButton';
import { navigateToUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

type Props = {
  handleSubmit: Function,
  intl: any,
  isLoading: boolean,
  onCustomerStatusChange: Function,
  customerStatus: string,
  selectedAll: boolean,
  onSelectAllClicked: Function,
  rowsOnTable: Array<any>,
  toggleSelectedRow: Function,
  handleFilterClick: Function,
  hasFilters: boolean,
  children: any,
  filterApplied: boolean,
  onSortChange: Function,
  sortOrder: string,
  currentSortedColumnName: string,
  history: Object,
  handleCreateClick: Function,
  isAllCommercial: boolean,
  onDateFilterChange: Function,
  customerFilters: Array,
};

const CommunicationsTable = ({
  handleSubmit,
  intl,
  isLoading,
  onCustomerStatusChange,
  customerStatus,
  selectedAll,
  onSelectAllClicked,
  rowsOnTable,
  toggleSelectedRow,
  handleFilterClick,
  children,
  filterApplied,
  onSortChange,
  sortOrder,
  currentSortedColumnName,
  handleCreateClick,
  isAllCommercial,
  currentFilter,
  onFilterChange,
  onApplyFiltersClick,
  showFilter,
  onDateFilterChange,
  customerFilters,
}: Props) => {
  const isSectionActive = (section: string) =>
    customerStatus === section ? 'active' : '';
  const onOrderClickCurried = curryN(2, onSortChange);
  const orderIcon = sortOrder === 'ASC' ? 'ascending' : 'descending';
  const isDisabled = !rowsOnTable.find((resident) => resident.selected);

  return (
    <Grid className="bodywrap container-fluid" fluid>
      <Row>
        <Col xs={12}>
          <Row className="section-header">
            <Col xs={12} md={3}>
              <h1>
                <FormattedMessage {...messages.header} />
              </h1>
            </Col>
            <Col xs={12} md={9}>
              <div className="search-actions-small">
                <FilterButton
                  showFilter={showFilter}
                  customerStatus={customerStatus}
                  handleFilterClick={handleFilterClick}
                />
                <SearchField
                  className="search-input-active"
                  form={'ManageCommunications'}
                  placeholder={`${intl.formatMessage(messages.search)}`}
                  onSubmit={handleSubmit}
                  formClasses={'padbottom10'}
                />
                <ElementWithPermissions scope={['communication-create']} hidden>
                  <button
                    className="btn btn-shout no-margin-top"
                    onClick={handleCreateClick}
                    disabled={isDisabled}
                  >
                    <FormattedMessage {...messages.createNew} />
                  </button>
                </ElementWithPermissions>
                <button
                  className="btn btn-callto no-margin-top"
                  onClick={() =>
                    navigateToUrlWithSelectedPropertyId('/communications')
                  }
                >
                  <FormattedMessage {...messages.viewCommunications} />
                </button>
              </div>
            </Col>
          </Row>
          <Row className="padtop20 padbottom10">
            <Col xs={12} md={12}>
              <ButtonGroup>
                {!isAllCommercial && (
                  <button
                    className={`btn btn-primary--ghost-white ${isSectionActive(
                      'prospects',
                    )}`}
                    type="button"
                    onClick={() => onCustomerStatusChange('prospects')}
                  >
                    <FormattedMessage {...messages.prospects} />
                  </button>
                )}
                <button
                  className={`btn btn-primary--ghost-white ${isSectionActive(
                    'applicants',
                  )}`}
                  type="button"
                  onClick={() => onCustomerStatusChange('applicants')}
                  data-testid="applicants-button"
                >
                  <FormattedMessage {...messages.applicant} />
                </button>
                <button
                  id="residentToggle"
                  className={`btn btn-primary--ghost-white ${isSectionActive(
                    'residents',
                  )}`}
                  type="button"
                  onClick={() => onCustomerStatusChange('residents')}
                >
                  {isAllCommercial ? (
                    <FormattedMessage {...messages.tenants} />
                  ) : (
                    <FormattedMessage {...messages.resident} />
                  )}
                </button>
                <button
                  className={`btn btn-primary--ghost-white ${isSectionActive(
                    'priorResidents',
                  )}`}
                  type="button"
                  //TODO: Remove style when button needed
                  style={{ display: 'none' }}
                  onClick={() => onCustomerStatusChange('priorResidents')}
                >
                  <FormattedMessage {...messages.priorResident} />
                </button>
              </ButtonGroup>
            </Col>
          </Row>
          <Col xs={12} id="table-row">
            {customerStatus === 'none' ? (
              <p style={{ marginTop: '25vh', textAlign: 'center' }}>
                Please click one of the options above to get started
              </p>
            ) : (
              <>
                <Filters
                  onFilterChange={onFilterChange}
                  currentFilter={currentFilter}
                  onApplyFiltersClick={onApplyFiltersClick}
                  customerStatus={customerStatus}
                  showFilter={showFilter}
                  onDateFilterChange={onDateFilterChange}
                  customerFilters={customerFilters}
                />
                <Table striped className="table-prospects">
                  <thead className="table-header hidden-xs">
                    <tr>
                      <ElementWithPermissions
                        scope={['communication-create']}
                        hidden
                      >
                        <ColumnHeader
                          columnLabel={
                            <div
                              className="checkbox custom-checkbox checkbox-lg"
                              style={{ margin: 0 }}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  checked={selectedAll}
                                  onChange={onSelectAllClicked}
                                />{' '}
                                <span className="custom-check-square" />
                              </label>
                            </div>
                          }
                        />
                      </ElementWithPermissions>
                      {customerStatus === 'prospects' && (
                        <th>
                          <Status color="white" />
                        </th>
                      )}
                      {headers[customerStatus].headers.map((header, index) => {
                        return (
                          <ColumnHeader
                            key={index}
                            columnLabel={
                              header.displayName === 'Resident Name' &&
                              isAllCommercial
                                ? 'Tenant Name'
                                : header.displayName
                            }
                            sortable={true}
                            onOrderClick={onOrderClickCurried(header.name)}
                            icon={
                              currentSortedColumnName !== header.name
                                ? 'sortable'
                                : orderIcon
                            }
                          />
                        );
                      })}
                    </tr>
                  </thead>
                  <CommunicationsDetails
                    rowsOnTable={rowsOnTable}
                    intl={intl}
                    toggleSelectedRow={toggleSelectedRow}
                    isLoading={isLoading}
                    customerStatus={customerStatus}
                    filterApplied={filterApplied}
                  />
                </Table>
              </>
            )}
          </Col>
        </Col>
      </Row>
      {children}
    </Grid>
  );
};

export default CommunicationsTable;
