import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from '../messages.js';
import FormattedDateOrDashes from '../../../components/FormattedDateOrDashes';

import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  intl: Object,
  offer: Object,
};

type HeaderProps = {
  offerDate: string,
  offerExpirationDate: string,
};

type TermProps = {
  termLength: string,
};

type CostProps = {
  cost: string,
  formatNumber: Function,
};

export const RenderHeader = ({
  offerDate,
  offerExpirationDate,
}: HeaderProps) => (
  <div className="panel-head" data-test="previous-offer-header">
    <div className="row">
      <div className="col-xs-12 col-sm-4">
        <h4>
          <i className="icon et-calendar padright10"> </i>
          <strong>
            <FormattedMessage {...messages.offerDate} />:
          </strong>{' '}
          <FormattedDateOrDashes value={offerDate} format="MM/DD/YYYY" />
        </h4>
      </div>
      <div className="col-xs-12 col-sm-5">
        <h4>
          <i className="icon et-calendar padright10"> </i>
          <strong>
            <FormattedMessage {...messages.offerExpires} />:
          </strong>{' '}
          <FormattedDateOrDashes
            value={offerExpirationDate}
            format="MM/DD/YYYY"
          />
        </h4>
      </div>
    </div>
  </div>
);

export const RenderTermLength = ({ termLength }: TermProps) => (
  <div className="col-xs-12 col-sm-3" data-test="previous-offer-term">
    <div className="form-group">
      <input
        className="input-lg form-control"
        value={`${termLength}`}
        disabled
      />
    </div>
  </div>
);

export const RenderTermCost = ({ cost, formatNumber }: CostProps) => (
  <div className="col-xs-12 col-sm-3" data-test="previous-offer-cost">
    <div className="input-group">
      <div className="input-group-addon">$</div>
      <input
        className="input-lg form-control"
        value={
          isNaN(cost)
            ? '---'
            : formatNumber(cost, {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
        }
        disabled
      />
    </div>
  </div>
);

export const RenderTransactionCodeRows = ({ lotx, formatNumber }) => {
  return lotx
    ? lotx.map((lot, index) => {
        return (
          <RenderTransactionCodeRow lot={lot} formatNumber={formatNumber} />
        );
      })
    : null;
};

export const RenderTransactionCodeRow = ({ formatNumber, lot }) => {
  return (
    <div className="row">
      <Stack alignItems="flex-end" flexDirection="row" spacing={2}>
        <RenderTransactionCodes transactionCode={lot.transactionCode} />
        <RenderTermCost cost={lot.offer1amount} formatNumber={formatNumber} />
        <RenderTermCost cost={lot.offer2amount} formatNumber={formatNumber} />
        <RenderTermCost cost={lot.offer3amount} formatNumber={formatNumber} />
      </Stack>
    </div>
  );
};

export const RenderTransactionCodes = ({
  transactionCode,
}: TransactionCodesProps) => (
  <div className="col-xs-12 col-sm-3" data-test="render-term-length">
    <label>
      <FormattedMessage {...messages.transactionCode} />
    </label>
    <input
      className="input-lg form-control"
      value={`${transactionCode.code}`}
      disabled
    />
  </div>
);

const PreviousOffersDetails = ({ offer, intl: { formatNumber } }: Props) => {
  const { renewalOfferLetterRows } = useFlags();

  const {
    offerDate,
    offerExpirationDate,
    offer1Rent,
    offer1TermLength,
    offer2Rent,
    offer2TermLength,
    offer3Rent,
    offer3TermLength,
    leaseOfferTransactions,
  } = offer;

  return (
    <div
      className="panel unit-information panel-withheader"
      data-test="previous-offer-details"
    >
      <RenderHeader
        offerDate={offerDate}
        offerExpirationDate={offerExpirationDate}
      />
      <div className="container-fluid unit-details panel__details padbottom20">
        <div className="row">
          <div className="col-xs-12 col-sm-3 padtop20">
            <h3>
              <FormattedMessage {...messages.term} />
            </h3>
          </div>
          <RenderTermLength termLength={offer1TermLength} />
          <RenderTermLength termLength={offer2TermLength} />
          <RenderTermLength termLength={offer3TermLength} />
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-3 padtop10">
            <h3>
              <FormattedMessage {...messages.leaseRent} />
            </h3>
          </div>
          <RenderTermCost cost={offer1Rent} formatNumber={formatNumber} />
          <RenderTermCost cost={offer2Rent} formatNumber={formatNumber} />
          <RenderTermCost cost={offer3Rent} formatNumber={formatNumber} />
        </div>
        {renewalOfferLetterRows ? (
          <RenderTransactionCodeRows
            lotx={leaseOfferTransactions}
            formatNumber={formatNumber}
          />
        ) : null}
      </div>
    </div>
  );
};

export default PreviousOffersDetails;
