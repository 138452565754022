import React, { useState } from 'react';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import messages from './messages';
import ManageOnlinePaymentsHeader from './ManageOnlinePaymentsHeader';
import ManageOnlinePaymentsDetails from './ManageOnlinePaymentsDetails';
import { useFetchOnlinePayments } from './hooks';

type Props = {
  intl: any,
  propertyId: string,
  organizationId: string,
  history: Object,
  isAllCommercial: boolean,
};

const ManageOnlinePayments = (props: Props) => {
  const { intl, organizationId, propertyId } = props;
  const { onlinePayments, isLoading, isAllCommercial } = useFetchOnlinePayments(
    {
      organizationId,
      propertyId,
    },
  );
  const [disableRejectAcceptBtn, setDisableRejectAcceptBtn] = useState(true);
  return (
    <DocumentTitle title={intl.formatMessage(messages.header)}>
      <div className="bodywrap">
        <ManageOnlinePaymentsHeader
          totalPayments={onlinePayments?.length}
          disableRejectAcceptBtn={disableRejectAcceptBtn}
        />
        <ManageOnlinePaymentsDetails
          onlinePayments={onlinePayments}
          isLoading={isLoading}
          isAllCommercial={isAllCommercial}
          disableRejectAcceptBtn={disableRejectAcceptBtn}
          setDisableRejectAcceptBtn={setDisableRejectAcceptBtn}
        />
      </div>
    </DocumentTitle>
  );
};
const InjectedComponent = injectIntl(ManageOnlinePayments);
export const mapStateToProps = ({
  app: { currentUser, selectedProperty },
}: any): any => {
  return {
    organizationId: currentUser.user.organizationId,
    propertyId: selectedProperty.id,
    isAllCommercial: selectedProperty?.hasCommercialFloorPlans === 'ALL',
  };
};
export default connect(mapStateToProps)(InjectedComponent);
