import { defineMessages } from 'react-intl';

const messages = defineMessages({
  allDay: {
    id: 'App.ManageProspects.Calendar.allDay',
    defaultMessage: 'all day'
  },
  previous: {
    id: 'App.ManageProspects.Calendar.previous',
    defaultMessage: 'previous'
  },
  next: {
    id: 'App.ManageProspects.Calendar.next',
    defaultMessage: 'next'
  },
  today: {
    id: 'App.ManageProspects.Calendar.today',
    defaultMessage: 'today'
  },
  month: {
    id: 'App.ManageProspects.Calendar.month',
    defaultMessage: 'month'
  },
  week: {
    id: 'App.ManageProspects.Calendar.week',
    defaultMessage: 'week'
  },
  day: {
    id: 'App.ManageProspects.Calendar.day',
    defaultMessage: 'day'
  },
  agenda: {
    id: 'App.ManageProspects.Calendar.agenda',
    defaultMessage: 'agenda'
  },
  date: {
    id: 'App.ManageProspects.Calendar.date',
    defaultMessage: 'date'
  },
  event: {
    id: 'App.ManageProspects.Calendar.event',
    defaultMessage: 'event'
  },
  time: {
    id: 'App.ManageProspects.Calendar.time',
    defaultMessage: 'time'
  },
  showMore: {
    id: 'App.ManageProspects.Calendar.showMore',
    defaultMessage: 'show more'
  },
  chooseOption: {
    id: 'App.ManageProspects.Calendar.ChooseAnOption',
    defaultMessage: 'Choose an option'
  }
});

export default messages;
