import * as ActionTypes from './constants';

export function sendForgotPasswordEmail(payload: any): * {
  return {
    type: ActionTypes.FORGOT_PASSWORD_EMAIL_SENT,
    payload,
  };
}

export function sendForgotPasswordEmailError(payload: any): * {
  return {
    type: ActionTypes.FORGOT_PASSWORD_EMAIL_ERROR,
    payload,
  };
}

export function sendForgotPasswordEmailSuccess(payload: any): * {
  return {
    type: ActionTypes.FORGOT_PASSWORD_EMAIL_SENT_SUCCESS,
    payload,
  };
}

export function clearMessages(): * {
  return {
    type: ActionTypes.CLEAR_MESSAGES,
  };
}