import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import { getCurrentUser } from '../App/selectors';
import * as manageApplicationsActions from './actions';
import messages from './messages';
import PropTypes from 'prop-types';
import type { GlobalState } from '../App/types';
import { getAllActivityTypes, getProspectAssignees } from '../App/actions';
import ApplicationsTableV2 from './ApplicationsTable/ApplicationsTable_V2';
import { useManageApplicationsPageV2 } from './hooks';
import store from '../../store';

type Props = {
  actions: Object,
  intl: any,
  history: Object,
  locale: string,
  currentUser: any,
};

const ManageApplicationsPageV2 = ({
  intl,
  locale,
  currentUser: {
    permissions,
    user: { id: currentUserId },
  },
  actions,
  selectedProperty,
  statuses,
  activityTypes,
  users,
}: Props) => {
  const {
    organizationId,
    propertyId,
    affordablePrograms,
    onRecordActivityClick,
    onCreateActivityClick,
    onEditActivity,
    onScheduleActivityClick,
  } = useManageApplicationsPageV2({
    intl,
    locale,
    actions,
    selectedProperty,
    store,
    users,
    activityTypes,
  });
  return (
    <DocumentTitle title={intl.formatMessage(messages.title)}>
      <ApplicationsTableV2
        intl={intl}
        affordablePrograms={affordablePrograms}
        locale={locale}
        onEditActivity={onEditActivity}
        onRecordActivity={onRecordActivityClick}
        onScheduleActivity={onScheduleActivityClick}
        onCreateActivity={onCreateActivityClick}
        applicationStatuses={statuses}
        permissions={permissions}
        currentUserId={currentUserId}
        organizationId={organizationId}
        propertyId={propertyId}
        selectedProperty={selectedProperty}
      />
    </DocumentTitle>
  );
};

export const mapStateToProps = (state: GlobalState) => {
  const { app, manageApplications, languageProvider } = state;
  return {
    activityTypes: app.activityTypes,
    locale: languageProvider.locale,
    statuses: manageApplications.statuses,
    selectedProperty: app.selectedProperty,
    users: app.prospectAssignees,
    currentUser: getCurrentUser(state),
  };
};
ManageApplicationsPageV2.contextTypes = {
  store: PropTypes.any,
};

export function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    actions: bindActionCreators(
      {
        ...manageApplicationsActions,
        getAllActivityTypes,
        getProspectAssignees,
      },
      dispatch,
    ),
  };
}

const InjectedManageApplicationsPage = injectIntl(ManageApplicationsPageV2);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedManageApplicationsPage);
