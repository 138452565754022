import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

type Props = {
  start: number,
  end: number,
  total: number
};

const PaginationItemsCounter = ({ start, end, total }: Props) => (
  <span className="results-pagination small pull-left">
    <FormattedMessage {...messages.value} values={{ start, end, total }} />
  </span>
);

export default PaginationItemsCounter;
