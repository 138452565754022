import type { ChangeEventHandler, Option } from './types';
import MultiSelectControl from './MultiSelectControl';
import VariableDateControl from './VariableDateControl';
import { sortLists } from './utils';

type Props = {
  statuses: Option[],
  types: Option[],
  onChange: ChangeEventHandler,
  clearExactDate: Function,
  clearDateRange: Function,
  values: Object,
};

const MULTI_SELECT_CONTROL_WIDTH = '20%';

function FilterControls({
  statuses,
  types,
  values,
  onChange,
  setPage,
  clearExactDate,
  clearDateRange,
}: Props): Object {
  const statusOptions = sortLists(statuses);
  const typeOptions = sortLists(types);

  const handleMultiSelectChange = (key: string) => (e: Object) => {
    onChange?.(key, {
      type: 'multi-select',
      values: e.target.value,
    });
    setPage?.(1);
  };
  const handleDateChange = (key: string) => (newValue: Object | string) => {
    onChange?.(key, {
      type: 'date',
      value: typeof newValue === 'string' ? newValue : newValue,
    });
    setPage?.(1);
  };

  return (
    <div aria-label="filter-controls">
      <div>
        <h5 style={{ color: '#4D73FF' }}>Filter By</h5>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: '24px',
        }}
      >
        <VariableDateControl
          label="Date"
          from={values.fromDate}
          to={values.toDate}
          fromPlaceholder="From"
          toPlaceholder="To"
          onFromChange={handleDateChange('fromDate')}
          onToChange={handleDateChange('toDate')}
          onExactDateChange={handleDateChange('exactDate')}
          exactDateValue={values.exactDate}
          exactDateLabel="Date"
          exactDatePlaceholder="Choose"
          exactDateName="Exact Date"
          clearDateRange={clearDateRange}
          clearExactDate={clearExactDate}
        />
        <MultiSelectControl
          name="status"
          label="Status"
          placeholder="Choose"
          aria-label="activity-filter"
          options={statusOptions}
          onChange={handleMultiSelectChange('statuses')}
          value={values.statuses}
          width={MULTI_SELECT_CONTROL_WIDTH}
        />
        <MultiSelectControl
          name="type"
          label="Type"
          placeholder="Choose"
          aria-label="activity-filter"
          options={typeOptions}
          onChange={handleMultiSelectChange('types')}
          value={values.types}
          width={MULTI_SELECT_CONTROL_WIDTH}
        />
      </div>
    </div>
  );
}

export default FilterControls;
