export const GET_ONE_PROSPECT = 'app/PeopleProfile/GET_ONE_PROSPECT';
export const GET_ONE_PROSPECT_SUCCESS =
  'app/PeopleProfile/GET_ONE_PROSPECT_SUCCESS';
export const GET_ONE_PROSPECT_ERROR =
  'app/PeopleProfile/GET_ONE_PROSPECT_ERROR';

export const CREATE_PROSPECT_ACTIVITY =
  'app/PeopleProfile/CREATE_PROSPECT_ACTIVITY';
export const CREATE_PROSPECT_ACTIVITY_SUCCESS =
  'app/PeopleProfile/CREATE_PROSPECT_ACTIVITY_SUCCESS';
export const CREATE_PROSPECT_ACTIVITY_ERROR =
  'app/PeopleProfile/CREATE_PROSPECT_ACTIVITY_ERROR';

export const GET_PROSPECT_PENDING_ACTIVITIES =
  'app/PeopleProfile/GET_PROSPECT_PENDING_ACTIVITIES';
export const GET_PROSPECT_PENDING_ACTIVITIES_SUCCESS =
  'app/PeopleProfile/GET_PROSPECT_PENDING_ACTIVITIES_SUCCESS';
export const GET_PROSPECT_PENDING_ACTIVITIES_ERROR =
  'app/PeopleProfile/GET_PROSPECT_PENDING_ACTIVITIES_ERROR';

export const GET_PROSPECT_COMPLETED_ACTIVITIES =
  'app/PeopleProfile/GET_PROSPECT_COMPLETED_ACTIVITIES';
export const GET_PROSPECT_COMPLETED_ACTIVITIES_SUCCESS =
  'app/PeopleProfile/GET_PROSPECT_COMPLETED_ACTIVITIES_SUCCESS';
export const GET_PROSPECT_COMPLETED_ACTIVITIES_ERROR =
  'app/PeopleProfile/GET_PROSPECT_COMPLETED_ACTIVITIES_ERROR';
export const CLEAR_PEOPLE_PROFILE_MESSAGES =
  'app/PeopleProfile/CLEAR_PEOPLE_PROFILE_MESSAGES';

export const GET_PROSPECT_ALL_ACTIVITIES =
  'app/PeopleProfile/GET_PROSPECT_ALL_ACTIVITIES';
export const GET_PROSPECT_ALL_ACTIVITIES_SUCCESS =
  'app/PeopleProfile/GET_PROSPECT_ALL_ACTIVITIES_SUCCESS';
export const GET_PROSPECT_ALL_ACTIVITIES_ERROR =
  'app/PeopleProfile/GET_PROSPECT_ALL_ACTIVITIES_ERROR';

export const UPDATE_PROSPECT_ACTIVITY =
  'app/PeopleProfile/UPDATE_PROSPECT_ACTIVITY';
export const UPDATE_PROSPECT_ACTIVITY_SUCCESS =
  'app/PeopleProfile/UPDATE_PROSPECT_ACTIVITY_SUCCESS';
export const UPDATE_PROSPECT_ACTIVITY_ERROR =
  'app/PeopleProfile/UPDATE_PROSPECT_ACTIVITY_ERROR';

export const DELETE_PROSPECT_ACTIVITY =
  'app/PeopleProfile/DELETE_PROSPECT_ACTIVITY';
export const DELETE_PROSPECT_ACTIVITY_SUCCESS =
  'app/PeopleProfile/DELETE_PROSPECT_ACTIVITY_SUCCESS';
export const DELETE_PROSPECT_ACTIVITY_ERROR =
  'app/PeopleProfile/DELETE_PROSPECT_ACTIVITY_ERROR';

export const ASSIGN_PROSPECT = 'app/PeopleProfile/ASSIGN_PROSPECT';
export const UPDATE_PROSPECT = 'app/PeopleProfile/UPDATE_PROSPECT';
export const UPDATE_PROSPECT_SUCCESS =
  'app/PeopleProfile/UPDATE_PROSPECT_SUCCESS';
export const UPDATE_PROSPECT_ERROR = 'app/PeopleProfile/UPDATE_PROSPECT_ERROR';

export const UPDATE_COLUMNS_SORT_VALUE =
  'app/PeopleProfile/UPDATE_COLUMNS_SORT_VALUE';

export const SEND_PROSPECT_PORTAL_INVITE_EMAIL =
  'app/ApplicationProfile/SEND_PROSPECT_PORTAL_INVITE_EMAIL';

export const PROSPECT = 'Prospect';
