import { createSelector } from 'reselect';
import { curryN, pathOr, prop, propEq, sortBy } from 'ramda';
import { getFormValues, isDirty } from 'redux-form';
import moment from 'moment';
import type { PaymentSingleTransaction } from '../../services/paymentSingleTransactionService';
import type { BatchHeader } from '../../services/paymentBatchService';
import type { DepositHeaderFormValues, GlobalState } from '../App/types';
import type { State } from './types';
import type { Option } from '../../types';
import type { UndepositedPaymentRow } from '../../components/UndepositedPaymentsTable/types';

const securityCodesForOperatingBankAccount = ['DEPOSITO', 'PETO', 'SATELLITEO'];

const getLocalState = (state: GlobalState) => state.manageUndepositedPayments;

const getDepositHeaderFormValues = getFormValues('depositHeaderForm');

export const getSelectedBankAccount = createSelector(
  getDepositHeaderFormValues,
  (form?: DepositHeaderFormValues) => (form ? form.bankAccountId : 'default'),
);

export const getSelectedForDeposit = createSelector(
  getLocalState,
  (state: State) => state.selectedForDeposit,
);

export const getUndepositedPayments = createSelector(
  getLocalState,
  (state: State) => state.undepositedPayments,
);

export const getPartialTotal = createSelector(
  getLocalState,
  ({
    undepositedBatches,
    undepositedPayments,
    selectedForDeposit,
  }: State): number => {
    if (selectedForDeposit.length === 0) return 0;

    const selectedBatchAmounts: number[] = undepositedBatches
      .filter((b) => selectedForDeposit.find((s) => s.isBatch && s.id === b.id))
      // $FlowFixMe
      .map(prop('batchTotal'));
    const selectPaymentAmounts: number[] = undepositedPayments
      .filter((p) =>
        selectedForDeposit.find((s) => !s.isBatch && s.id === p.id),
      )
      .map((p: PaymentSingleTransaction) => {
        const amount =
          p.paymentType === 'Ops'
            ? p.customerOpsLedger.transactionAmount
            : p.paymentType === 'Security'
            ? p.customerSecurityLedger.transactionAmount
            : p.propertyMiscIncomeLedger.transactionAmount;
        return p.treatAsNegative ? amount * -1 : amount;
      });
    return Number(
      selectedBatchAmounts
        .concat(selectPaymentAmounts)
        .reduce((ac, cur) => ac + cur, 0)
        .toFixed(2),
    );
  },
);

export const getBankAccountOptions = (state: GlobalState): Option[] =>
  [{ value: 'default', text: 'Choose', disabled: false }].concat(
    state.app.selectedPropertyBankAccounts.map((b) => ({
      value: b.id,
      text: b.bankAccountType,
    })),
  );

export const getFiscalPeriodText = (state: GlobalState): string => {
  const period =
    (state.app.selectedPropertyFiscalPeriod &&
      state.app.selectedPropertyFiscalPeriod.period) ||
    '';
  return state.app.fiscalPeriodLoaded
    ? `${period.substr(0, 4)}-${period.substr(4)}`
    : '';
};

const batchToRow = curryN(
  3,
  (
    state: State,
    depositHeaderFormValues: DepositHeaderFormValues,
    b: BatchHeader,
  ): UndepositedPaymentRow => {
    const isSelected =
      state.selectedForDeposit.find((s) => s.isBatch && s.id === b.id) !==
      undefined;
    const disableSelect =
      depositHeaderFormValues.bankAccountId === 'default' || !b.isPosted;
    return {
      id: `batch-${b.id}`,
      selectedForDeposit: !disableSelect && isSelected,
      mustBeSingleDeposit: b.mustBeSingleDeposit,
      isPosted: b.isPosted,
      isPosting: b.isPosting,
      type: 'Batch',
      bankAccountType: b.propertyBankAccount
        ? b.propertyBankAccount.bankAccountType
        : '',
      notes: b.batchNote,
      batchId: b.name,
      date: moment(b.batchDate).format('MM/DD/YYYY'),
      period: b.propertyFiscalPeriod ? b.propertyFiscalPeriod.period : '',
      amount: b.batchTotal,
      numberOfItems: b.numberOfItems,
      adjustments: b.adjustments ? 'Yes' : undefined,
      disableSelect,
    };
  },
);

const paymentToRow = curryN(
  3,
  (
    state: State,
    depositHeaderFormValues: DepositHeaderFormValues,
    p: PaymentSingleTransaction,
  ): UndepositedPaymentRow => {
    const isSelected =
      state.selectedForDeposit.find((s) => !s.isBatch && s.id === p.id) !==
      undefined;
    const disableSelect = depositHeaderFormValues.bankAccountId === 'default';
    const ledgerKey: string =
      p.paymentType === 'Ops'
        ? 'customerOpsLedger'
        : p.paymentType === 'Security'
        ? 'customerSecurityLedger'
        : 'propertyMiscIncomeLedger';

    return {
      id: `single-${p.id}`,
      selectedForDeposit: !disableSelect && isSelected,
      mustBeSingleDeposit: false,
      isPosted: true,
      isPosting: false,
      type: 'Single',
      bankAccountType:
        p.paymentType === 'Ops' ||
        p.paymentType === 'Misc' ||
        securityCodesForOperatingBankAccount.includes(
          p[ledgerKey].propertyTransactionCode.transactionCode.code,
        )
          ? 'Operating'
          : 'Security',
      notes: p[ledgerKey].transactionNote,
      paymentId: String(p.readableId),
      date: moment(p[ledgerKey].transactionDate).format('MM/DD/YYYY'),
      period:
        p.paymentType === 'Misc'
          ? p.propertyMiscIncomeLedger.propertyFiscalPeriods.period
          : p[ledgerKey].propertyFiscalPeriod.period,
      amount: p[ledgerKey].transactionAmount * (p.treatAsNegative ? -1 : 1),
      numberOfItems: 1,
      // $FlowFixMe
      batchId: pathOr(
        null,
        ['customerOpsLedger', 'batchPaymentDetail', 'header', 'name'],
        p,
      ),
      adjustments: ['Reversed', 'Reversal'].includes(p.transactionStatus)
        ? 'Yes'
        : undefined,
      disableSelect,
    };
  },
);

export const getUndepositedPaymentRows = createSelector(
  getLocalState,
  getDepositHeaderFormValues,
  getBankAccountOptions,
  (
    state: State,
    depositHeaderFormValues: DepositHeaderFormValues,
    bankAccountOptions: Option[],
  ): UndepositedPaymentRow[] => {
    if (!depositHeaderFormValues || bankAccountOptions.length === 1) return [];

    const batchRows = state.undepositedBatches.map(
      batchToRow(state, depositHeaderFormValues),
    );
    const paymentRows = state.undepositedPayments.map(
      paymentToRow(state, depositHeaderFormValues),
    );
    const selectedBankAccountOption: ?Option = bankAccountOptions.find(
      (opt) => opt.value === depositHeaderFormValues.bankAccountId,
    );
    const sortedRows = sortBy(prop('date'), batchRows.concat(paymentRows));

    return selectedBankAccountOption &&
      selectedBankAccountOption.value !== 'default'
      ? sortedRows.filter(
          propEq('bankAccountType', selectedBankAccountOption.text),
        )
      : sortedRows;
  },
);

export const dataIsReady = (state: GlobalState) =>
  state.app.bankAccountsLoaded && state.app.fiscalPeriodLoaded;

export const disableCancel = createSelector(
  getLocalState,
  (state: State) => state.creating,
);

export const disableSubmit = createSelector(
  getLocalState,
  getPartialTotal,
  getDepositHeaderFormValues,
  (state: State, partialTotal: number, formValues?: DepositHeaderFormValues) =>
    state.creating ||
    !state.selectedForDeposit.length ||
    !formValues ||
    partialTotal !== formValues.depositTotal,
);

export const enablePrompt = createSelector(
  isDirty('depositHeaderForm'),
  getLocalState,
  (formIsDirty: boolean, state: State) =>
    !state.viewPaymentBatchClicked &&
    (formIsDirty || state.selectedForDeposit.length > 0),
);
