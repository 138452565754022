import { isEmpty, isNil } from 'ramda';
import { isIdentificationNumberMissing } from './utils';
import { VALID_ID, VALID_ID_AFFORDABLE } from './constants';
import moment from 'moment';
import {
  validateEmail,
  validateBusinessName,
  validatePhoneNumber,
  validateSocialSecurityNumber,
} from '../../utils/validations';
import { extractCurrentDateFormat } from '../../utils/redux-form-helper';

const validateBirthday = (birthday, intl) => {
  const currentDateFormat = extractCurrentDateFormat(intl);
  const bday = moment(birthday, currentDateFormat, true);
  if (!bday.isValid()) {
    return 'Invalid Value';
  }
  const birthdayStr = moment(birthday).format();
  const longTimeAgo = moment('01/01/1901', 'MM/DD/YYYY').format();
  if (birthdayStr < longTimeAgo) {
    return 'Invalid Value';
  }
};

export const validate = (values: Object, props: Object) => {
  const errors = {
    personalInformation: {},
    affordableInformation: {},
    generalInformation: {},
    reportingInformation: {},
  };

  const {
    type,
    personalInformation,
    generalInformation,
    reportingInformation,
    affordableInformation,
  } = values;

  const { intl, isAffordable, initialValues } = props;
  const personalInformationErrors = {};
  const affordableInformationErrors = { disability: {} };

  if (type === 'adult') {
    const {
      birthday,
      passportCountry,
      passportNumber,
      socialSecurityNumber,
      stateIdNumber,
      stateIdOrigin,
    } = personalInformation;
    const validMessage = isAffordable ? VALID_ID_AFFORDABLE : VALID_ID;
    const isIdentificationNumberBypassed = isIdentificationNumberMissing(
      initialValues.personalInformation,
    );

    if (
      !isIdentificationNumberBypassed &&
      isIdentificationNumberMissing(personalInformation)
    ) {
      personalInformationErrors.stateIdNumber = validMessage;
      personalInformationErrors.passportNumber = validMessage;
      personalInformationErrors.socialSecurityNumber = validMessage;
      personalInformationErrors.alienRegistration = validMessage;
    }

    if (stateIdNumber && !stateIdOrigin) {
      personalInformationErrors.stateIdOrigin =
        'Must select State if # is provided';
      personalInformationErrors.stateIdNumber = 'State must also be provided';
    }

    if ((isEmpty(stateIdNumber) || isNil(stateIdNumber)) && stateIdOrigin) {
      personalInformationErrors.stateIdOrigin =
        'Must provide ID # if State is selected';
    }

    if (passportNumber && !passportCountry) {
      personalInformationErrors.passportCountry =
        'Must select Country if # is provided';
      personalInformationErrors.passportNumber =
        'Country must also be provided';
    }

    if ((isEmpty(passportNumber) || isNil(passportNumber)) && passportCountry) {
      personalInformationErrors.passportCountry =
        'Must provide ID # if Country is selected';
    }

    if (birthday) {
      personalInformationErrors.birthday = validateBirthday(birthday, intl);
    }

    if (
      socialSecurityNumber &&
      !validateSocialSecurityNumber(socialSecurityNumber)
    ) {
      personalInformationErrors.socialSecurityNumber =
        'Must provide a valid SSN';
    }

    errors.personalInformation = personalInformationErrors;
  } else if (type === 'minor') {
    const { dateOfBirth, socialSecurityNumber } = personalInformation;
    if (dateOfBirth) {
      personalInformationErrors.dateOfBirth = validateBirthday(
        dateOfBirth,
        intl,
      );
    }
    if (
      socialSecurityNumber &&
      !validateSocialSecurityNumber(socialSecurityNumber)
    ) {
      personalInformationErrors.socialSecurityNumber = 'Invalid SSN';
    }
    errors.personalInformation = personalInformationErrors;
  }
  const generalInformationErrors = {};
  if (generalInformation) {
    const { emailAddress, phoneNumber } = generalInformation;
    if (!validateEmail(emailAddress) && type === 'adult') {
      generalInformationErrors.emailAddress = 'Invalid email address';
    }
    if (!validatePhoneNumber(phoneNumber) && type === 'adult') {
      generalInformationErrors.phoneNumber = 'Invalid phone number';
    }
    ['firstName', 'lastName', 'middleName', 'preferredName'].forEach(
      (fieldName) => {
        if (
          !generalInformationErrors[fieldName] &&
          generalInformation[fieldName] &&
          !validateBusinessName(generalInformation[fieldName])
        ) {
          generalInformationErrors[fieldName] = 'Invalid Name';
        }
      },
    );

    errors.generalInformation = { ...generalInformationErrors };
  }
  const reportingInformationErrors = {};
  if (reportingInformation) {
    if (
      reportingInformation?.annualIncome &&
      reportingInformation.annualIncome < 0
    ) {
      reportingInformationErrors.annualIncome = 'Must be positive';
    }

    errors.reportingInformation = { ...reportingInformationErrors };
  }

  const disabilityMobile = affordableInformation?.disability?.disabilityMobile;
  const disabilityHearing =
    affordableInformation?.disability?.disabilityHearing;
  const disabilityVisual = affordableInformation?.disability?.disabilityVisual;
  const disabilityOther = affordableInformation?.disability?.disabilityOther;
  const hasDisability = affordableInformation?.disability?.hasDisability;

  if (
    hasDisability === 'yes' &&
    (isNil(disabilityHearing) || disabilityHearing === 'Choose')
  ) {
    affordableInformationErrors.disability.disabilityHearing = 'Required';
  }
  if (
    hasDisability === 'yes' &&
    (isNil(disabilityVisual) || disabilityVisual === 'Choose')
  ) {
    affordableInformationErrors.disability.disabilityVisual = 'Required';
  }
  if (
    hasDisability === 'yes' &&
    (isNil(disabilityMobile) || disabilityMobile === 'Choose')
  ) {
    affordableInformationErrors.disability.disabilityMobile = 'Required';
  }
  if (
    hasDisability === 'yes' &&
    (isNil(disabilityOther) || disabilityOther === 'Choose')
  ) {
    affordableInformationErrors.disability.disabilityOther = 'Required';
  }

  if (isNil(hasDisability)) {
    affordableInformationErrors.disability.hasDisability = 'Required';
  }

  errors.affordableInformation.disability = {
    ...affordableInformationErrors.disability,
  };

  return errors;
};

export default validate;
