import React, { Component } from 'react';
import { Button, Col, Grid, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { any, equals, pathOr } from 'ramda';
import {
  Field,
  formValueSelector,
  getFormSyncErrors,
  reduxForm,
} from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';

import ElementWithPermissions from '../../../components/ElementWithPermissions';
import { renderDateField } from '../../../utils/redux-form-helper';
import messages from './messages';
import validate from './validate';
import { ProrateSection } from '../../../components/ProRateSection';

type Props = {
  applicantName: string,
  handleCancel: Function,
  handleSubmit: Function,
  handleMoveInDateUpdate: Function,
  intl: Object,
  invalid: boolean,
  steps: Array<Object>,
  submitting: boolean,
  unitNumber: string,
  array: any,
  openFiscalPeriod?: Object,
  formSyncErrors: any,
  leaseMoveInDate: string,
  actualMoveInDateTouched: boolean,
  monthlyTransactions: Array<Object>,
};

type InjectedProps = {
  actualMoveInDate: any,
  array: any,
};
type ReduxFormProps = {
  change: Function,
  form: string,
  handleSubmit: Function,
  initialValues: Object,
  resetSection: Function,
  array: any,
};

export class MoveInCheckForm extends Component<
  Props & InjectedProps & ReduxFormProps,
> {
  onCancel = () => {
    this.props.handleCancel();
  };

  getStatus = (status: Object) => {
    return status.valid
      ? 'status status-ball status-green'
      : 'status status-ball status-red';
  };

  render() {
    const {
      applicantName,
      handleSubmit,
      invalid,
      steps,
      submitting,
      unitNumber,
      handleMoveInDateUpdate,
      formSyncErrors,
      leaseMoveInDate,
      actualMoveInDate,
      actualMoveInDateTouched,
      monthlyTransactions,
      intl,
      moveInApplyFullAmountToProRate,
    } = this.props;
    const MoveInDateStatus = {
      statusText: Object.keys(formSyncErrors).includes('actualMoveInDate')
        ? 'Not Confirmed'
        : 'Confirmed',
      valid: !Object.keys(formSyncErrors).includes('actualMoveInDate'),
    };

    const moveInDateFromLease = moment(leaseMoveInDate);
    const displayMoveInDateError = !moveInDateFromLease.isSame(
      actualMoveInDate,
      'day',
    );
    const formattedLeaseMoveIn = moveInDateFromLease.format('MM/DD/YYYY');
    const moveInDateErrorMsg = displayMoveInDateError
      ? `Move-in date must match the lease's Scheduled Move-In date (${formattedLeaseMoveIn})`
      : null;

    return (
      <form className="form-container container-fluid resident-convert-form resident-expand-container">
        <Grid fluid>
          <div className="applicant-convert-header">
            <Row>
              <Col xs={6} sm={12}>
                <a className="btn-text" onClick={this.onCancel}>
                  <i className="et-chevron-left" />
                  Go Back to Applicant profile
                </a>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12}>
                <h1>
                  Are you sure you want to convert this Applicant to a Tenant?{' '}
                  {applicantName}
                </h1>
                <h4>Unit Number: {unitNumber}</h4>
                <p>Please review and confirm before you continue.</p>
              </Col>
            </Row>
            <div className="form-container">
              <Row>
                <Col xs={12} sm={8}>
                  <h3>Steps Approved And Documents Uploaded:</h3>
                  <div className="checklist-small">
                    <ul>
                      {steps.map((step, index) => (
                        <li key={`steps__${index}`}>
                          <div className="item-property"> {step.title}</div>
                          <div className="item-value text-right">
                            {step.statusText}
                            <span className={this.getStatus(step)} />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="padtop10">
                  <h3>Confirm Move-In Date:</h3>
                </Col>
                <Col xs={12} sm={8}>
                  <div className="checklist-small no-margin-bottom">
                    <ul>
                      <li>
                        <div className="item-property">
                          <Field
                            name="actualMoveInDate"
                            onChange={handleMoveInDateUpdate}
                            component={renderDateField}
                            bsSize="lg"
                          />
                          {moveInDateErrorMsg && (
                            <p
                              className={
                                actualMoveInDateTouched
                                  ? 'text-red'
                                  : 'text-gray'
                              }
                            >
                              {moveInDateErrorMsg}
                            </p>
                          )}
                        </div>
                        <div className="item-value text-right">
                          {MoveInDateStatus.statusText}
                          <span className={this.getStatus(MoveInDateStatus)} />
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs={12}>
                  <p>
                    <strong>
                      IMPORTANT: This date must be the actual date of move-in.
                    </strong>
                  </p>
                  <p>
                    <b>Note: </b>
                    Move-In Date cannot be in the future. Changes will recompute
                    the prorated values below.
                  </p>
                </Col>
              </Row>
              <ProrateSection
                headerText={
                  'Prorate amounts posting to ledger (based on Rent Start Date):'
                }
                noteText={`If these charges are not correct, you can correct by :
                  (1) closing this screen and updating the Monthly Lease Charges or Credits on the Lease Data Tab or
                  (2) reversing the charges/credits from the ledger after move-in.`}
                monthlyTransactions={monthlyTransactions}
                moveInDate={leaseMoveInDate}
                intl={intl}
                moveInApplyFullAmountToProRate={moveInApplyFullAmountToProRate}
              />
              <hr />
            </div>
          </div>
          <Col xs={6}>
            <Button
              bsStyle="default"
              className="btn btn-default pull-right"
              onClick={this.onCancel}
            >
              Cancel
            </Button>
          </Col>
          <Col xs={6}>
            <ElementWithPermissions scope={['resident-create']}>
              <Button
                disabled={any(equals(true), [submitting, invalid])}
                className="btn btn-primary pull-left"
                onClick={handleSubmit}
              >
                <FormattedMessage {...messages.submitButton} />
              </Button>
            </ElementWithPermissions>
          </Col>
        </Grid>
      </form>
    );
  }
}

const MoveInCheckApplicationForm = reduxForm({
  form: 'MoveInCheck',
  validate: validate,
  enableReinitialize: true,
})(MoveInCheckForm);

const selector = formValueSelector('MoveInCheck');

const mapStateToProps = (state: Object): any => {
  const { form } = state;
  return {
    actualMoveInDate: selector(state, 'actualMoveInDate'),
    formSyncErrors: getFormSyncErrors('MoveInCheck')(state),
    actualMoveInDateTouched: pathOr(
      false,
      ['MoveInCheck', 'fields', 'actualMoveInDate', 'touched'],
      form,
    ),
  };
};

export const MoveInCheckApplicationScreen = connect(mapStateToProps)(
  MoveInCheckApplicationForm,
);
