import React from 'react';
import styled from 'styled-components';
import { Button, Col, Row } from 'react-bootstrap';
import { FormattedNumber } from 'react-intl';
import type { UndepositedPaymentRow } from './types';

type Props = {
  rows: UndepositedPaymentRow[],
  onSelect: Function,
  onUnselect: Function,
  onViewPaymentBatch: Function,
  onViewSinglePayment: Function,
};

const Wrapper = styled.div`
  & {
    tbody {
      display: block;
    }
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
    .table-prospects .table-header {
      border-radius: 0;
    }
  }
`;

const UndepositedPaymentTable = (props: Props) => (
  <Wrapper>
    <table className="table table-prospects table-fixed-headers table-striped">
      <thead className="table-header">
        <tr>
          <th>Select for Deposit</th>
          <th>Must be Single Deposit</th>
          <th>Posted</th>
          <th>Type</th>
          <th>Bank Account</th>
          <th>Notes</th>
          <th>Batch ID</th>
          <th>Payment ID</th>
          <th>Date</th>
          <th>Period</th>
          <th>Amount</th>
          <th>Number of Items</th>
          <th>Adjustments</th>
          <th>View</th>
        </tr>
      </thead>
      <tbody className="container-scrollable">
        {props.rows.map((row: UndepositedPaymentRow) => (
          <tr key={row.id}>
            <td>
              <div className="custom-checkbox checkbox checkbox-lg">
                <label>
                  <input
                    type="checkbox"
                    checked={row.selectedForDeposit}
                    disabled={row.disableSelect}
                    onChange={() => {
                      /* Avoiding a warning */
                    }}
                    onClick={() => {
                      const selection = {
                        id: row.id,
                        isBatch: row.type === 'Batch',
                      };
                      if (row.selectedForDeposit) props.onUnselect(selection);
                      else props.onSelect(selection);
                    }}
                  />
                  <input type="checkbox" />
                  <span className="custom-check-square"> </span>
                </label>
              </div>
            </td>
            <td>
              {' '}
              {row.mustBeSingleDeposit && (
                <i className="icon et-alert-urgent text-red" />
              )}
            </td>
            <td>{row.isPosting ? 'Pending' : row.isPosted ? 'Yes' : 'No'}</td>
            <td>{row.type}</td>
            <td>{row.bankAccountType}</td>
            <td>{row.notes || '---'}</td>
            <td>{row.batchId ? row.batchId : ''}</td>
            <td>{row.type === 'Single' ? row.paymentId : ''}</td>
            <td>{row.date}</td>
            <td>{row.period}</td>
            <td>
              <FormattedNumber
                value={row.amount}
                style={{ value: 'currency' }.value}
                currency="USD"
              />
            </td>
            <td>{row.numberOfItems}</td>
            <td>{row.adjustments || ''}</td>
            <td>
              <Button
                bsSize="small"
                onClick={() =>
                  row.type === 'Batch'
                    ? props.onViewPaymentBatch(row.id)
                    : props.onViewSinglePayment(row.id)
                }
              >
                <i className="et-pencil" />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <div className="table-footer">
      <Row>
        <Col xs={12}>
          <span className="table-total">
            Total Selected Deposits:{' '}
            <FormattedNumber
              value={props.rows
                .filter((r) => r.selectedForDeposit)
                .reduce((ac, cur) => cur.amount + ac, 0)}
              style={{ value: 'currency' }.value}
              currency="USD"
            />
          </span>
          <span className="text-darkgray">
            Number of items:{' '}
            {props.rows.filter((r) => r.selectedForDeposit).length}
          </span>
        </Col>
      </Row>
    </div>
  </Wrapper>
);

export default UndepositedPaymentTable;
