export const bigNumberStyle = {
  fontFamily: 'Roboto',
  fontSize: '74px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '93px',
  letterSpacing: '-0.0059em',
  textAlign: 'left',
};

export const bodyText = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '18px',
  letterSpacing: '-0.0059em',
  textAlign: 'center',
};
