import React, { Component } from 'react';
import { Panel, Col } from 'react-bootstrap';
import classnames from 'classnames';
import {
  Header,
  Title,
} from '../../../../../components/ProfileVisualComponents';
import messages from './messages';

type Props = {
  intl: Object,
  resident: any,
};

type State = {
  open: boolean,
};

const CurrentResident = ({ isPriorResident }: Object) => (
  <span
    className={classnames({
      'prosp-status-icon prosp-status-green': !isPriorResident,
      'prosp-status-icon prosp-status-grey': isPriorResident,
    })}
  >
    <i className={'icon et-circle-check'} />
  </span>
);
class ApprovedResidentList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const resident = this.props.resident;
    let color = null;
    const isPriorResident = resident.status !== 'Current';
    if (isPriorResident) {
      color = '#A9A9A9';
    }
    return (
      <Panel expanded={this.state.open} onToggle>
        <Header>
          <Title>
            <div className="container-fluid">
              <Col md={6}>
                <CurrentResident isPriorResident={isPriorResident} />
                <h4 className="prosp-name" style={{ color }}>
                  {resident.name}
                </h4>
              </Col>
              <Col md={4}>
                <div className="prosp-label float-right">{resident.type}</div>
              </Col>
              <Col md={2} className={'text-right'}>
                <a className={'btn btn-text no-margin disabled'}>
                  {this.props.intl.formatMessage(messages.applicationLabel)}
                  <i className="icon et-pencil" />
                </a>
              </Col>
            </div>
          </Title>
        </Header>
      </Panel>
    );
  }
}

export default ApprovedResidentList;
