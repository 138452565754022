import { put, takeLatest, select, throttle } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import { push } from 'react-router-redux';

import {
  GET_FINAL_ACCOUNT_STATEMENT,
  GENERATE_FINAL_ACCOUNT_STATEMENT,
} from './constants';
import {
  getFinalAccountStatementSuccess,
  getFinalAccountStatementFailure,
} from './actions';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
} from '../App/selectors';
import FinalAccountStatementService from '../../services/finalAccountStatementService';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import type { Action, Property } from '../App/types';
import messages from './messages';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchFinalAccountStatementSaga(
  action: Action<Object>,
): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const finalAccountStatement = new FinalAccountStatementService();
    const { customerId } = action.payload;
    const response = yield finalAccountStatement.get(
      organizationId,
      selectedProperty.id,
      customerId,
    );
    yield put(getFinalAccountStatementSuccess(response));
  } catch (err) {
    yield put(getFinalAccountStatementFailure(err));
  }
}

export function* getFinalAccountStatementSaga(): Saga<void> {
  yield takeLatest(GET_FINAL_ACCOUNT_STATEMENT, fetchFinalAccountStatementSaga);
}

export function* generateFAS(action: Action<any>): any {
  try {
    const { customerId, residentId, sendEmail } = action.payload;
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const propertyId = selectedProperty.id;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const finalAccountStatement = new FinalAccountStatementService();

    const response = yield finalAccountStatement.generateFinalAccountStatement(
      organizationId,
      propertyId,
      customerId,
      sendEmail,
    );
    if (response.error) {
      throw new Error(response.error);
    }

    yield put(
      push(
        getUrlWithSelectedPropertyId(
          `/prior-resident/${residentId}?fasComplete=true`,
        ),
      ),
    );
    yield put(
      toastrActions.add({
        type: 'info',
        message: renderTranslatedMessage(messages.submitted),
        title: renderTranslatedMessage(messages.success),
        options: {
          showCloseButton: true,
          removeOnHover: true,
          progressBar: true,
          timeOut: 15000, // Default 5000
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* generateFASSaga(): Saga<void> {
  yield throttle(500, GENERATE_FINAL_ACCOUNT_STATEMENT, generateFAS);
}

export default [getFinalAccountStatementSaga, generateFASSaga];
