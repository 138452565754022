export const GET_RESIDENT_HOUSEHOLD =
  'App/EditResidentHousehold/GET_RESIDENT_HOUSEHOLD';
export const GET_RESIDENT_HOUSEHOLD_SUCCESS =
  'App/EditResidentHousehold/GET_RESIDENT_HOUSEHOLD_SUCCESS';
export const GET_RESIDENT_HOUSEHOLD_ERROR =
  'App/EditResidentHousehold/GET_RESIDENT_HOUSEHOLD_ERROR';

export const CLEAR_STATE = 'App/EditResidentHousehold/CLEAR_STATE';

export const ENABLE_ADULT_EDIT = 'App/EditResidentHousehold/ENABLE_ADULT_EDIT';
export const DISABLE_ADULT_EDIT =
  'App/EditResidentHousehold/DISABLE_ADULT_EDIT';
export const ENABLE_MINOR_EDIT = 'App/EditResidentHousehold/ENABLE_MINOR_EDIT';
export const DISABLE_MINOR_EDIT =
  'App/EditResidentHousehold/DISABLE_MINOR_EDIT';

export const EDIT_RESIDENT_HOUSEHOLD =
  'App/EditResidentHousehold/EDIT_RESIDENT_HOUSEHOLD';
export const EDIT_RESIDENT_HOUSEHOLD_SUCCESS =
  'App/EditResidentHousehold/EDIT_RESIDENT_HOUSEHOLD_SUCCESS';
export const EDIT_RESIDENT_HOUSEHOLD_ERROR =
  'App/EditResidentHousehold/EDIT_RESIDENT_HOUSEHOLD_ERROR';

export const GET_ALL_PENDING_APPLICANTS =
  'App/EditResidentHousehold/GET_ALL_PENDING_APPLICANTS';

export const GET_ALL_PENDING_APPLICANTS_SUCCESS =
  'App/EditResidentHousehold/GET_ALL_PENDING_APPLICANTS_SUCCESS';

export const EDIT_PENDING_APPLICANTS =
  'App/EditResidentHousehold/EDIT_PENDING_APPLICANTS';

export const EDIT_PENDING_APPLICANTS_SUCCESS =
  'App/EditResidentHousehold/EDIT_PENDING_APPLICANTS_SUCCESS';

export const GET_LATEST_HOUSEHOLD_HISTORY =
  'App/EditResidentHousehold/GET_LATEST_HOUSEHOLD_HISTORY';
export const GET_LATEST_HOUSEHOLD_HISTORY_SUCCESS =
  'App/EditResidentHousehold/GET_LATEST_HOUSEHOLD_HISTORY_SUCCESS';
export const GET_LATEST_HOUSEHOLD_HISTORY_ERROR =
  'App/EditResidentHousehold/GET_LATEST_HOUSEHOLD_HISTORY_ERROR';

export const GET_HOUSEHOLD_LEASES_BY_RESIDENT_ID =
  'App/EditResidentHousehold/GET_HOUSEHOLD_LEASES_BY_RESIDENT_ID';
export const GET_HOUSEHOLD_LEASES_BY_RESIDENT_ID_SUCCESS =
  'App/EditResidentHousehold/GET_HOUSEHOLD_LEASES_BY_RESIDENT_ID_SUCCESS';
export const GET_HOUSEHOLD_LEASES_BY_RESIDENT_ID_ERROR =
  'App/EditResidentHousehold/GET_HOUSEHOLD_LEASES_BY_RESIDENT_ID_ERROR';

export const GET_HOUSEHOLD_HAS_OPEN_CERTS =
  'App/EditResidentHousehold/GET_HOUSEHOLD_HAS_OPEN_CERTS';
export const GET_HOUSEHOLD_HAS_OPEN_CERTS_SUCCESS =
  'App/EditResidentHousehold/GET_HOUSEHOLD_HAS_OPEN_CERTS_SUCCESS';
export const GET_HOUSEHOLD_HAS_OPEN_CERTS_ERROR =
  'App/EditResidentHousehold/GET_HOUSEHOLD_HAS_OPEN_CERTS_ERROR';
