import React, { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  FortressLogo,
  FortressTextIcon,
  BuildingsIcon,
  HomeIcon,
  RealEstateAgentIcon,
  PeopleIcon,
  HandyManIcon,
  ReportsIcon,
  SettingsIcon,
  CalculateIcon,
  MoneyAltIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import NavListItem, { NavListIcon } from './NavListItem';

const NAV_ICON_MAP = {
  Home: <HomeIcon sx={{ fontSize: '16px' }} />,
  Sales: <RealEstateAgentIcon sx={{ fontSize: '16px' }} />,
  People: <PeopleIcon sx={{ fontSize: '16px' }} />,
  Property: <BuildingsIcon sx={{ fontSize: '16px' }} />,
  Financials: <MoneyAltIcon sx={{ fontSize: '16px' }} />,
  Maintenance: <HandyManIcon sx={{ fontSize: '16px' }} />,
  Reporting: <ReportsIcon sx={{ fontSize: '16px' }} />,
  Admin: <SettingsIcon sx={{ fontSize: '16px' }} />,
  'Prorate Calculator': <CalculateIcon sx={{ fontSize: '16px' }} />,
};

const FortressLogoListItem = ({ children }) => (
  <ListItem
    sx={{
      height: 70,
      paddingLeft: '12px',
      paddingTop: '4px',
      paddingBottom: 2,
    }}
  >
    <ListItemIcon sx={{ minWidth: 'unset', marginRight: 1 }}>
      <FortressLogo sx={{ fontSize: '20px' }} />
    </ListItemIcon>
    {children}
  </ListItem>
);

const DrawerContent = ({
  drawerWidth,
  showNavText,
  environmentName,
  navOptionsArray,
  isXs,
}) => {
  const initialOpenState = navOptionsArray.reduce((acc, option) => {
    acc[option.value] = false;
    return acc;
  }, {});
  const [open, setOpen] = useState(initialOpenState);

  const baseListSX = {
    position: 'absolute',
    top: 0,
    paddingTop: 0,
    height: '100%',
    overflowY: 'auto',
    willChange: 'opacity',
    transition: ({ transitions: { create, duration, easing } }) =>
      create('opacity', {
        duration: duration.short,
        easing: easing.easeOut,
        delay: easing.shortest,
      }),
  };
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        marginTop: { sm: '40px' },
      }}
    >
      <List
        sx={{
          ...baseListSX,
          right: 0,
          width: drawerWidth.closed,
          opacity: showNavText ? 0 : 1,
        }}
      >
        <FortressLogoListItem />

        {navOptionsArray.map((navOption) => {
          return (
            <NavListIcon
              key={navOption.value}
              icon={NAV_ICON_MAP[navOption.value]}
              notification={navOption.notification}
              selected={navOption.selected}
              value={navOption.value}
            />
          );
        })}
      </List>
      <List
        sx={{
          ...baseListSX,
          left: 0,
          width: '100%',
          opacity: showNavText ? 1 : 0,
        }}
      >
        <FortressLogoListItem>
          <ListItemText
            primary={
              <FortressTextIcon
                color={'primary'}
                sx={{
                  fontSize: '100px !important',
                  position: 'absolute',
                  top: -38,
                }}
              />
            }
            secondary={environmentName}
            primaryTypographyProps={{
              height: 26,
              position: 'relative',
            }}
            secondaryTypographyProps={{ variant: 'caption' }}
          />
        </FortressLogoListItem>

        {navOptionsArray.map((navOption) => {
          return (
            <NavListItem
              key={navOption.value}
              {...navOption}
              icon={NAV_ICON_MAP[navOption.value]}
              isXs={isXs}
              open={open[navOption.value]}
              setOpen={(value) =>
                setOpen({
                  ...initialOpenState,
                  [navOption.value]: value ?? true,
                })
              }
            />
          );
        })}
      </List>
    </Box>
  );
};

DrawerContent.defaultProps = {
  showNavText: true,
  environmentName: '',
  navOptionsArray: [],
};

export default DrawerContent;
