import React, { Fragment } from 'react';
import { Row, Col, FormGroup } from 'react-bootstrap';
import { Field } from 'redux-form';
import { pathOr } from 'ramda';
import { FormattedMessage } from 'react-intl';
import {
  renderRadioGroupField,
  renderCheckboxField,
} from '../../../../utils/redux-form-helper';

import messages from './messages';

const PetRow = ({ intl, pet, petValues }: Object) => (
  <Row key={pathOr('', ['residentPet', 'id'], pet)} className="padtop10">
    <Col md={2} xs={4} sm={2} className=" text-left">
      <span>{pathOr('---', ['residentPet', 'petType', 'name'], pet)}</span>
    </Col>
    <Col md={2} xs={2} sm={2} className=" text-left">
      <span>{pathOr('---', ['residentPet', 'breed', 'name'], pet)}</span>
    </Col>
    <Col md={2} xs={2} sm={2} className=" text-left">
      <span>{pathOr('---', ['residentPet', 'petTag'], pet)}</span>
    </Col>
    <Col md={4} xs={4} sm={4} className=" text-left">
      <Field
        name={`petAlerts.${pathOr('', ['residentPet', 'id'], pet)}.isCaged`}
        fieldName={`petAlerts.${pathOr(
          '',
          ['residentPet', 'id'],
          pet,
        )}.isCaged`}
        component={renderRadioGroupField}
        className="form-options"
        options={[
          {
            text: intl.formatMessage(messages.no),
            value: 'no',
            disabled: false,
          },
          {
            text: intl.formatMessage(messages.yes),
            value: 'yes',
            disabled: false,
          },
        ]}
        inline
      />
    </Col>
  </Row>
);
const PetSection = ({ intl, location, householdPets }: Object) => (
  <Fragment>
    <Row className="padtop20">
      <Col xs={6} md={1} className="nopad">
        <FormGroup className="text-center input-group__pet-control">
          <label>
            <i className="et-pet" />
            {intl.formatMessage(messages.petAlert)}
          </label>
          <FormGroup>
            <Field
              name="petAlert"
              component={renderCheckboxField}
              bsSize="lg"
              label="Yes"
              disabled={location === 'commonArea'}
            />
          </FormGroup>
        </FormGroup>
      </Col>
    </Row>
    <Row className="padtop20">
      <Col md={2} xs={4} sm={2} className=" text-left">
        <label>{intl.formatMessage(messages.petType)}</label>
      </Col>
      <Col md={2} xs={4} sm={2} className=" text-left">
        <label>{intl.formatMessage(messages.petBreed)}</label>
      </Col>
      <Col md={2} xs={4} sm={2} className=" text-left">
        <label>{intl.formatMessage(messages.petTag)}</label>
      </Col>
      <Col md={4} xs={4} sm={4} className=" text-left">
        <label>{intl.formatMessage(messages.willPetBeCaged)}</label>
      </Col>
    </Row>
    {householdPets.map((pet, index) => (
      <PetRow
        key={index}
        intl={intl}
        pet={pet}
        petValues={householdPets[index]}
      />
    ))}
    <Row className="padtop20">
      <Col md={8} xs={4} sm={8} className="no-print text-left">
        <b>
          {' '}
          <FormattedMessage {...messages.note} />
        </b>
        <span>
          {' '}
          <FormattedMessage {...messages.editNote} />
        </span>
      </Col>
    </Row>
  </Fragment>
);
export default PetSection;
