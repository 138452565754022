import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.Title',
    defaultMessage: 'Prospect Information',
  },
  overview: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.Overview',
    defaultMessage: 'Overview',
  },
  cancel: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.Save',
    defaultMessage: 'Save Changes',
  },
  editInfo: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.EditInfo',
    defaultMessage: 'Edit Information',
  },
  details: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.Details',
    defaultMessage: 'Details',
  },
  noBeds: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.NoBeds',
    defaultMessage: '# Beds',
  },
  noBaths: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.NoBaths',
    defaultMessage: '# Baths',
  },
  moveInDate: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.MoveInDate',
    defaultMessage: 'Move-in Date',
  },
  priceRange: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.PriceRange',
    defaultMessage: 'Price Range',
  },
  leaseTerm: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.LeaseTerm',
    defaultMessage: 'Lease Term',
  },
  wants: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.Wants',
    defaultMessage: 'Wants',
  },
  doesntWant: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.DoesntWant',
    defaultMessage: "Doesn't Want",
  },
  contactType: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.ContactType',
    defaultMessage: 'Contact Type',
  },
  source: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.Source',
    defaultMessage: 'How did you hear about us?',
  },
  additionalOccupants: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.AdditionalOccupants',
    defaultMessage: 'Additional Occupant(s)',
  },
  spouse: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.Spouse',
    defaultMessage: 'Spouse',
  },
  otherAdults: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.OtherAdults',
    defaultMessage: 'Other Adults',
  },
  kids: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.Kids',
    defaultMessage: 'Minors',
  },
  pets: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.Pets',
    defaultMessage: 'Pets',
  },
  wantsPlaceholder: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.WantPlaceholder',
    defaultMessage: 'Start typing...',
  },
  notWantPlaceholder: {
    id: 'App.PeopleProfile.ProspectInfoTabEditMode.NotWantPlaceholder',
    defaultMessage: 'Start typing...',
  },
  invalidName: {
    id: 'App.ProspectProfile.ProspectInfoTabEditMode.InvalidName',
    defaultMessage: 'First name and last name must have letters or digits only',
  },
  invalidEmailAddress: {
    id: 'App.ProspectProfile.ProspectInfoTabEditMode.InvalidEmailAddress',
    defaultMessage: 'Invalid email address',
  },
  invalidPhoneNumber: {
    id: 'App.ProspectProfile.ProspectInfoTabEditMode.invalidPhoneNumber',
    defaultMessage: 'Invalid phone number',
  },
  invalidBedsOrBaths: {
    id: 'App.ProspectProfile.ProspectInfoTabEditMode.InvalidBedsOrBaths',
    defaultMessage: 'Value must be between 0 and 9',
  },
  invalidRange: {
    id: 'App.ProspectProfile.ProspectInfoTabEditMode.InvalidRange',
    defaultMessage: 'From value must be less or equal than To value.',
  },
  invalidDateRange: {
    id: 'App.ProspectProfile.ProspectInfoTabEditMode.InvalidDateRange',
    defaultMessage: 'Initial value needed.',
  },
  invalidValue: {
    id: 'App.ProspectProfile.ProspectInfoTabEditMode.invalidValue',
    defaultMessage: 'Invalid value',
  },
  invalidKidAge: {
    id: 'App.ProspectProfile.ProspectInfoTabEditMode.InvalidKidAge',
    defaultMessage: 'The age of a kid must be between 0 and 17 years.',
  },
  introductoryNote: {
    id: 'App.ProspectProfile.ProspectInfoTabEditMode.introductoryNote',
    defaultMessage: 'Introductory Note',
  },
  introductoryNotePlaceholder: {
    id:
      'App.ProspectProfile.ProspectInfoTabEditMode.IntroductoryNotePlaceholder',
    defaultMessage: 'Start typing introductory note...',
  },
  invalidDateValue: {
    id: 'App.ProspectProfile.ProspectInfoTabEditMode.InvalidDateValue',
    defaultMessage: 'Value must be greater than today.',
  },
});

export default messages;
