import moment from 'moment';
import type { Activity } from '../containers/ProspectProfile/types';

const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class ActivityService {
  save(activity: Activity, organizationId: string) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(activity),
    };
    return request(
      `${apiURL}/${organizationId}/${activity.propertyId}/activities`,
      options,
    );
  }

  validate(activity: Activity) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(activity),
    };
    return request(
      `${apiURL}/${activity.propertyId}/activities/check`,
      options,
    );
  }

  getByAssignee(
    assignedToId: string,
    propertyId: string,
    organizationId: string,
    dates: Object,
    options?: Object = {},
  ) {
    let query = '';
    if (dates?.start && dates?.end) {
      const queryArr = [];
      queryArr.push(`start=${moment(dates.start).format('MM-DD-YYYY')}`);
      queryArr.push(`end=${moment(dates.end).format('MM-DD-YYYY')}`);
      query = `?${queryArr.join('&')}`;
    }

    if (!assignedToId) {
      assignedToId = 'all';
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/activities/byAssignee/${assignedToId}${query}`,
      requestOptions,
    );
  }

  getPendingByAssignee(
    assignedToId: string,
    propertyId: string,
    organizationId: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/activities/pendingByAssignee/${assignedToId}`,
      options,
    );
  }

  getPendingByProspect(
    prospectId: string,
    propertyId: string,
    organizationId: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/activities/pendingByProspect/${prospectId}`,
      options,
    );
  }

  getCompletedByProspect(
    prospectId: string,
    propertyId: string,
    pageNumber: number,
    limit: number,
    sort: string,
    order: string,
    mode: string,
    organizationId: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/activities?prospectId=${prospectId}&page=${pageNumber}&limit=${limit}&sort=${sort}&order=${order}&mode=${mode}`, // eslint-disable-line max-len
      options,
    );
  }

  getAllByProspect(
    prospectId: string,
    propertyId: string,
    pageNumber: number,
    limit: number,
    sort: string,
    order: string,
    mode: string,
    organizationId: string,
    filters,
    options: Object = {},
  ) {
    const opts = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };

    const {
      statuses = [],
      types = [],
      fromDate = null,
      toDate = null,
      autoOrManual = null,
      isRange = false,
    } = filters;

    let filterString = '';

    if (statuses.length > 0) {
      filterString += '&statusFilter=' + statuses.join(',');
    }
    if (types.length > 0) {
      filterString += '&typeFilter=' + types.join(',');
    }
    if (autoOrManual) {
      filterString += '&autoOrManualFilter=' + autoOrManual;
    }
    if (fromDate && toDate && isRange) {
      filterString +=
        '&fromDateFilter=' +
        fromDate.toDate() +
        '&toDateFilter=' +
        toDate.toDate();
    }
    if (fromDate && !isRange) {
      filterString += '&exactDateFilter=' + fromDate.toDate();
    }

    return request(
      `${apiURL}/${organizationId}/${propertyId}/activities?prospectId=${prospectId}&page=${pageNumber}&limit=${limit}&sort=${sort}&order=${order}${filterString}`, // eslint-disable-line max-len
      opts,
    );
  }

  delete(activity: Activity, organizationId: string) {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(activity),
    };
    return request(
      `${apiURL}/${organizationId}/${activity.propertyId}/activities/${
        activity.id || ''
      }`,
      options,
    );
  }

  edit(activity: Object, activityId?: string, organizationId: string) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(activity),
    };
    return request(
      `${apiURL}/${organizationId}/${activity.propertyId}/activities/${
        activityId || ''
      }`,
      options,
    );
  }

  getActivityFilterOptions(
    prospectId: string,
    propertyId: string,
    organizationId: string,
    options: Object,
  ) {
    const opts = {
      ...options,
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/activity-options?prospectId=${prospectId}`,
      opts,
    );
  }
}

export default ActivityService;
