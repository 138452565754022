import { defineMessages } from 'react-intl';

const messages = defineMessages({
  oneTimeLeaseChargesOrCreditsLabel: {
    id:
      'App.LeaseDataTabForm.OneTimeLeaseChanges.OneTimeLeaseChargesOrCreditsLabel',
    defaultMessage: 'One-Time Lease Charges or Credits',
  },
  securityDepositLabel: {
    id: 'App.LeaseDataTabForm.OneTimeLeaseChanges.SecurityDepositLabel',
    defaultMessage: 'Security Deposit ',
  },
  proratedRentLabel: {
    id: 'App.LeaseDataTabForm.OneTimeLeaseChanges.ProratedRentLabel',
    defaultMessage: 'Prorated Rent ',
  },
  proratedOtherLabel: {
    id: 'App.LeaseDataTabForm.OneTimeLeaseChanges.ProratedOtherLabel',
    defaultMessage: 'Prorated Other ',
  },
  securityDepositAlternativeLabel: {
    id:
      'App.LeaseDataTabForm.OneTimeLeaseChanges.SecurityDepositAlternativeLabel',
    defaultMessage: 'Security Deposit Alternative',
  },
  nonRefundableAdminFeeLabel: {
    id: 'App.LeaseDataTabForm.OneTimeLeaseChanges.NonRefundableAdminFeeLabel',
    defaultMessage: 'Non-Refundable Admin Fee',
  },
  satelliteDepositLabel: {
    id: 'App.LeaseDataTabForm.OneTimeLeaseChanges.SatelliteDepositLabel',
    defaultMessage: 'Satellite Deposit',
  },
});

export default messages;
