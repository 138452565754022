import { defineMessages } from 'react-intl';

const messages = defineMessages({
  domTitle: {
    id: 'App.ManageWorkOrders.domTitle',
    defaultMessage: 'Fortress - Manage Work Orders',
  },
  header: {
    id: 'App.ManageWorkOrders.Header',
    defaultMessage: 'Manage Work Orders',
  },
  createWorkOrder: {
    id: 'App.ManageWorkOrders.createWorkOrder',
    defaultMessage: 'Create Work Order',
  },
  id: {
    id: 'App.ManageWorkOrders.Id',
    defaultMessage: 'ID',
  },
  location: {
    id: 'App.ManageWorkOrders.Location',
    defaultMessage: 'Location',
  },
  issueDescription: {
    id: 'App.ManageWorkOrders.issueDescription',
    defaultMessage: 'Issue Description',
  },
  creationDate: {
    id: 'App.ManageWorkOrders.creationDate',
    defaultMessage: 'Creation Date',
  },
  assignedTo: {
    id: 'App.ManageWorkOrders.assignedTo',
    defaultMessage: 'Assigned To',
  },
  priority: {
    id: 'App.ManageWorkOrders.priority',
    defaultMessage: 'Priority',
  },
  status: {
    id: 'App.ManageWorkOrders.status',
    defaultMessage: 'Status',
  },
  daysOpen: {
    id: 'App.ManageWorkOrders.daysOpen',
    defaultMessage: 'Days Open',
  },
  dueDate: {
    id: 'App.ManageWorkOrders.dueDate',
    defaultMessage: 'Due Date',
  },
  requestor: {
    id: 'App.ManageWorkOrders.requestor',
    defaultMessage: 'Requestor',
  },
  print: {
    id: 'App.ManageWorkOrders.print',
    defaultMessage: 'Print',
  },
  view: {
    id: 'App.ManageWorkOrders.view',
    defaultMessage: 'View',
  },
  searchPlaceholder: {
    id: 'App.ManageWorkOrders.searchPlaceholder',
    defaultMessage: 'Search for a work order...',
  },
  unassigned: {
    id: 'App.ManageWorkOrders.Unassigned',
    defaultMessage: 'Unassigned',
  },
  inactiveAssigneeWarning: {
    id: 'App.ManageWorkOrders.InactiveAssigneeWarning',
    defaultMessage: 'Assignee inactive or no longer has access',
  },
  pastDue: {
    id: 'App.ManageWorkOrders.pastDue',
    defaultMessage: 'Past Due',
  },
  successHeader: {
    id: 'App.ManageWorkOrders.SuccessHeader',
    defaultMessage: 'Success',
  },
  successDescription: {
    id: 'App.ManageWorkOrders.SuccessDescription',
    defaultMessage: 'The work order was updated.',
  },
  errorHeader: {
    id: 'App.ManageWorkOrders.ErrorHeader',
    defaultMessage: 'Error performing action',
  },
  workOrders: {
    id: 'App.ManageWorkOrders.WorkOrders',
    defaultMessage: 'Work Orders',
  },
  completionDate: {
    id: 'App.ManageWorkOrders.CompletionDate',
    defaultMessage: 'Completion Date',
  },
  notes: {
    id: 'App.ManageWorkOrders.Notes',
    defaultMessage: 'Notes',
  },
  viewingItems: {
    id: 'App.ManageWorkOrders.Pagination',
    defaultMessage: 'Viewing items {lower} - {higher} of {total}',
  },
  noWorkOrdersFound: {
    id: 'App.ManageWorkOrders.noWorkOrdersFound',
    defaultMessage: 'No work orders found',
  },
});

export default messages;
