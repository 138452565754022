export const GET_ALL_UNITS = 'app/ManageUnitAvailability/GET_ALL_UNITS';
export const GET_ALL_UNITS_SUCCESS =
  'app/ManageUnitAvailability/GET_ALL_UNITS_SUCCESS';
export const GET_ALL_UNITS_ERROR =
  'app/ManageUnitAvailability/GET_ALL_UNITS_ERROR';

export const GET_ALL_UNIT_STATUSES =
  'app/ManageUnitAvailability/GET_ALL_UNIT_STATUSES';
export const GET_ALL_UNIT_STATUSES_SUCCESS =
  'app/ManageUnitAvailability/GET_ALL_UNIT_STATUSES_SUCCESS';
export const GET_ALL_UNIT_STATUSES_ERROR =
  'app/ManageUnitAvailability/GET_ALL_UNIT_STATUSES_ERROR';

export const GET_ASSIGNED_UNITS =
  'app/ManageUnitAvailability/GET_ASSIGNED_UNITS';
export const GET_ASSIGNED_UNITS_SUCCESS =
  'app/ManageUnitAvailability/GET_ASSIGNED_UNITS_SUCCESS';
export const GET_ASSIGNED_UNITS_ERROR =
  'app/ManageUnitAvailability/GET_ASSIGNED_UNITS_ERROR';

export const SAVE_PROPERTY_SPECIALS =
  'app/ManageUnitAvailability/SAVE_PROPERTY_SPECIALS';
export const SAVE_PROPERTY_SPECIALS_SUCCESS =
  'app/ManageUnitAvailability/SAVE_PROPERTY_SPECIALS_SUCCESS';
export const SAVE_PROPERTY_SPECIALS_ERROR =
  'app/ManageUnitAvailability/SAVE_PROPERTY_SPECIALS_ERROR';

export const GET_ALL_PROPERTY_SPECIALS =
  'app/ManageUnitAvailability/GET_ALL_PROPERTY_SPECIALS';
export const GET_ALL_PROPERTY_SPECIALS_SUCCESS =
  'app/ManageUnitAvailability/GET_ALL_PROPERTY_SPECIALS_SUCCESS';
export const GET_ALL_PROPERTY_SPECIALS_ERROR =
  'app/ManageUnitAvailability/GET_ALL_PROPERTY_SPECIALS_ERROR';

export const UPDATE_COLUMNS_SORT_VALUE =
  'app/ManageUnitAvailability/UPDATE_COLUMNS_SORT_VALUE';
export const CLEAN_LOADED_UNITS =
  'app/ManageUnitAvailability/CLEAN_LOADED_UNITS';

export const ASSIGN_UNIT = 'app/ManageUnitAvailability/ASSIGN_UNIT';
export const ASSIGN_UNIT_SUCCESS =
  'app/ManageUnitAvailability/ASSIGN_UNIT_SUCCESS';
export const ASSIGN_UNIT_ERROR = 'app/ManageUnitAvailability/ASSIGN_UNIT_ERROR';

export const UNASSIGN_UNIT = 'app/ManageUnitAvailability/UNASSIGN_UNIT';
export const UNASSIGN_UNIT_SUCCESS =
  'app/ManageUnitAvailability/UNASSIGN_UNIT_SUCCESS';
export const UNASSIGN_UNIT_ERROR =
  'app/ManageUnitAvailability/UNASSIGN_UNIT_ERROR';

export const GET_UNIT_QUOTE = 'app/ManageUnitAvailability/GET_UNIT_QUOTE';
export const GET_UNIT_QUOTE_SUCCESS =
  'app/ManageUnitAvailability/GET_UNIT_QUOTE_SUCCESS';
export const GET_UNIT_QUOTE_ERROR =
  'app/ManageUnitAvailability/GET_UNIT_QUOTE_ERROR';

export const AMENITY_PROPERTY_FEE = 'Amenity';
