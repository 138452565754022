import React from 'react';
import messages from '../messages';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { SORT_DIRECTION, useSortableData } from '../../../../utils/data-sorter';
import { green } from '@fortress-technology-solutions/fortress-component-library/design';
import { TaskTableBody } from '../TaskTableBody';
import { CLASS_NAME } from '../constants';
import { sortTaskTables } from '../utils/general/sortFunctions';

type Header = {
  id: string,
  text: Object,
  sortKey: string,
  initialSortDirection: string,
};

type Props = {
  title: string,
  headers: Header[],
  items: Array<Object>,
  intl: Object,
  onChangeAssignee?: Function,
  assignees?: Array<Object>,
  isTasksLoading: boolean,
};

const TaskTable = (props: Props) => {
  const { items, requestSort, sortConfig } = useSortableData(
    props.items,
    sortTaskTables,
  );

  const getClassNameFor = (name) => {
    if (props.isTasksLoading) return CLASS_NAME.SORT_DEFAULT;

    if (!sortConfig) {
      return CLASS_NAME.SORT_DEFAULT;
    }

    if (sortConfig.key === name) {
      return sortConfig.direction === SORT_DIRECTION.ASCENDING
        ? CLASS_NAME.SORT_ASCENDING
        : CLASS_NAME.SORT_DESCENDING;
    }

    return CLASS_NAME.SORT_DEFAULT;
  };

  const handleSort = (sortKey: string, sortDirection: string) => {
    if (props.isTasksLoading) return;

    if (sortDirection) {
      return requestSort(sortKey, sortDirection);
    }

    return requestSort(sortKey);
  };

  return (
    <div className="panel task-summary panel-withheader panel-has-table">
      <div className="panel-head">
        <Typography variant={'formSubheading'}>{props.title}</Typography>
      </div>
      <div className="panel__details--full">
        {props.showLegendGreenIcon && (
          <div>
            <span
              className="legend-color"
              style={{ backgroundColor: green.lighter }}
            />
            <span className="small">
              {props.intl.formatMessage(messages.legendGreen)}
            </span>
          </div>
        )}
        <Box sx={{ overflowX: 'auto' }}>
          <table className="table-prospects table table-striped">
            <thead>
              <tr className="table-header">
                {props.headers.map((header: Header) => (
                  <th key={header.id}>
                    {header.text}
                    <span>
                      <i
                        className={getClassNameFor(header.sortKey)}
                        onClick={() =>
                          handleSort(
                            header.sortKey,
                            header?.initialSortDirection,
                          )
                        }
                      ></i>
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <TaskTableBody
                isTasksLoading={props.isTasksLoading}
                assignees={props.assignees}
                onChangeAssignee={props.onChangeAssignee}
                sortedItems={items}
              />
            </tbody>
          </table>
        </Box>
      </div>
    </div>
  );
};

export default TaskTable;
