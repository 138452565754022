export const LOGIN = 'app/Login/LOGIN';
export const LOGIN_FAIL = 'app/Login/LOGIN_FAIL';
export const LOGIN_SUCCESS = 'app/Login/LOGIN_SUCCESS';
export const FORGET_ME = 'app/Login/FORGET_ME';
export const HANDLE_SOCKET_LOGOUT_EXPIRED_SESSION =
  'app/Login/HANDLE_SOCKET_LOGOUT_EXPIRED_SESSION';
export const CLEAR_MESSAGES = 'app/Login/CLEAR_MESSAGES';
export const START_TOKEN_RENEWAL = 'app/Login/START_TOKEN_RENEWAL';
export const START_USER_IDLE_DETECTION = 'app/Login/START_USER_IDLE_DETECTION';
export const STOPPED_TOKEN_RENEWAL_PROCESS =
  'app/Login/STOPPED_TOKEN_RENEWAL_PROCESS';
export const RENEW_USER_TOKEN = 'app/Login/RENEW_USER_TOKEN';
export const RENEW_USER_TOKEN_SUCCESS = 'app/Login/RENEW_USER_TOKEN_SUCCESS';
export const USER_IS_IDLE = 'app/Login/USER_IS_IDLE';
export const USER_IS_ACTIVE = 'app/Login/USER_IS_ACTIVE';
export const CANCELLING_USER_IDLE_PROCESS =
  'app/Login/CANCELLING_USER_IDLE_PROCESS';
