export const BACKGROUND_COLORS = ['#2136E1', '#98BEFF', '#EBF5FF', '#B4B4B4'];
export const HOVER_BACKGROUND_COLORS = [
  '#96F066',
  '#FF5757',
  '#C5B7FB',
  '#C5B7FB',
];
export const BORDER_WIDTH = 0;
export const LARGER_TEXT = 7;
export const SMALLER_TEXT = 10;
export const PERCENTAGE_OFFSET = 0.7;
export const BAR_PERCENTAGE = 0.5;
export const STACKED_BAR_PADDING = 10;
