// $FlowFixMe
import { useEffect, useState, useCallback } from 'react';
import { pathOr, contains, isEmpty, difference, concat, map } from 'ramda';
import MasterTransactionCodesService from '../../services/masterTransactionCodesService';
import PropertyFeesService from '../../services/propertyFeesService';
import PropertyService from '../../services/propertyService';
import messages from './messages';

const mapTransactionCodePayload = (txCode: Object) => {
  return {
    ...txCode,
    description: txCode.description.trim(),
    transactionType: pathOr('---', ['transactionType', 'name'], txCode),
    masterSubjournal: pathOr(
      '---',
      ['masterSubjournal', 'description'],
      txCode,
    ),
  };
};

const parseProperties = (properties: Array<Object>) => {
  return properties.map((property) => ({
    value: property.id,
    label: property.name,
  }));
};

const parsePropertyFees = (propertyFees: Array<Object>) => {
  return propertyFees.map((pf) => pathOr(null, ['propertyId'], pf));
};

export const useMasterTransacionCode = (
  organizationId: string,
  masterTransactionCodeId: string,
  setMasterTransactionCode: Function,
  setSelectedProperties: Function,
) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    let subscribed = true;

    const fetchOneMasterTransactionCode = async () => {
      const masterTransactionCodesService = new MasterTransactionCodesService();
      const response = await masterTransactionCodesService.getOneMasterTransactionCode(
        organizationId,
        masterTransactionCodeId,
        options,
      );
      if (response) {
        const codeEnabledProperties = pathOr(
          [],
          ['enabledProperties'],
          mapTransactionCodePayload(response),
        );
        setMasterTransactionCode(mapTransactionCodePayload(response));
        setSelectedProperties(codeEnabledProperties);
      } else {
        setMasterTransactionCode({});
      }
      setIsLoading(false);
    };

    if (subscribed && !isEmpty(organizationId)) {
      fetchOneMasterTransactionCode();
    }
    return () => {
      subscribed = false;
    };
  }, [
    organizationId,
    masterTransactionCodeId,
    setIsLoading,
    setMasterTransactionCode,
    setSelectedProperties,
  ]);

  return isLoading;
};

export const useAllActiveProperties = (
  organizationId: string,
  setAllActiveProperties: Function,
  propertiesWithFees: Array<string>,
  codeSubjournalId: string,
) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    let subscribed = true;

    const fetchActiveProperties = async () => {
      const propertiesService = new PropertyService();
      const response = await propertiesService.getActiveProperties(
        organizationId,
        options,
      );
      if (response) {
        const allPropertiesFilteredByFees = filterSelectableCodeProperties(
          response,
          codeSubjournalId,
          propertiesWithFees,
        );
        setAllActiveProperties(allPropertiesFilteredByFees);
      } else {
        setAllActiveProperties([]);
      }
      setIsLoading(false);
    };

    if (subscribed && !isEmpty(organizationId)) {
      fetchActiveProperties();
    }
    return () => {
      subscribed = false;
    };
  }, [
    organizationId,
    setAllActiveProperties,
    propertiesWithFees,
    codeSubjournalId,
  ]);

  return isLoading;
};

export const useCodePropertyFees = (
  organizationId: string,
  masterTransactionCodeId: string,
  setPropertiesWithFees: Function,
) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    let subscribed = true;

    const fetchActiveProperties = async () => {
      const propertyFeesService = new PropertyFeesService();
      const response = await propertyFeesService.getAllFeesByOrgAndMTC(
        organizationId,
        masterTransactionCodeId,
        options,
      );
      if (response) {
        setPropertiesWithFees(parsePropertyFees(response));
      } else {
        setPropertiesWithFees([]);
      }
      setIsLoading(false);
    };

    if (
      subscribed &&
      !isEmpty(organizationId) &&
      !isEmpty(masterTransactionCodeId)
    ) {
      fetchActiveProperties();
    }
    return () => {
      subscribed = false;
    };
  }, [organizationId, masterTransactionCodeId, setPropertiesWithFees]);

  return isLoading;
};

export const useAsyncSaveMasterTransactionCode = ({
  intl,
  history,
  masterTransactionCode,
  organizationId,
  properties,
  setIsSaving,
  toasterFn,
}: Object) => {
  const [subscribed, setSubscribed] = useState(true);
  const [submitting, setSubmitting] = useState(null);

  const handleSaveMasterTransaction = () => {
    setSubmitting(true);
    setIsSaving(true);
  };

  const fetchSaveMasterTransactionCode = useCallback(async () => {
    if (submitting && organizationId && masterTransactionCode) {
      // $FlowFixMe
      const abortController = new AbortController(); // eslint-disable-line
      const options = { signal: abortController.signal };
      const transactionCodesService = new MasterTransactionCodesService();
      try {
        setSubmitting(null);

        await transactionCodesService.setMasterTransactionCodeToProperties(
          organizationId,
          masterTransactionCode,
          properties,
          options,
        );
        if (subscribed) {
          toasterFn({
            message: intl.formatMessage(messages.saveSuccess),
            title: intl.formatMessage(messages.successTitle),
          });
          history.push('/manage-transaction-codes');
        }
        setIsSaving(false);
      } catch (error) {
        if (subscribed) {
          toasterFn({
            type: 'error',
            message: error.toString(),
            title: intl.formatMessage(messages.errorTitle),
          });
        }
        setIsSaving(false);
      }
    }
  }, [
    submitting,
    masterTransactionCode,
    organizationId,
    properties,
    intl,
    history,
    toasterFn,
    setIsSaving,
    subscribed,
  ]);

  useEffect(() => {
    setSubscribed(true);
    fetchSaveMasterTransactionCode();
    return () => {
      setSubscribed(false);
    };
  }, [fetchSaveMasterTransactionCode]);

  return handleSaveMasterTransaction;
};

function filterSelectableCodeProperties(
  response,
  codeSubjournalId,
  propertiesWithFees,
) {
  let propertiesWithCodeSubjournal = response;
  /**
   * Filter out properties that do not contain the subjournal of the code
   * (Hides them)
   */
  if (codeSubjournalId !== '---') {
    propertiesWithCodeSubjournal = response.filter((property) => {
      const propertySubjournalIds = map(
        pathOr(null, ['masterSubjournalType', 'description']),
        property.subjournals,
      );
      return contains(codeSubjournalId, propertySubjournalIds);
    });
  }

  const allParsedProperties = parseProperties(propertiesWithCodeSubjournal);
  /**
   * Filter out properties that contain active fees with the code
   * (disables them)
   */
  const selectableProperties = allParsedProperties.filter(
    (p) => !contains(p.value, propertiesWithFees),
  );
  let nonSelectableProperties = difference(
    allParsedProperties,
    selectableProperties,
  );
  nonSelectableProperties = nonSelectableProperties.map(
    (nonSelectableCode) => ({
      ...nonSelectableCode,
      label: `${nonSelectableCode.label} *`,
      disabled: true,
    }),
  );
  const allPropertiesFilteredByFees = concat(
    selectableProperties,
    nonSelectableProperties,
  );
  return allPropertiesFilteredByFees;
}
