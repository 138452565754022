import { useEffect, useState } from 'react';
import CamRecordService from '../../../../services/camRecordService';

export const useFetchCamPools = ({
  propertyId,
  organizationId,
  householdId,
}: FetchCamPoolsProps): any => {
  const [camPools, setCamPools] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetchCamRecords = async () => {
      if (organizationId && propertyId && householdId) {
        setIsLoading(true);
        const camRecordService = new CamRecordService();
        const response = await camRecordService.getCamPools(
          organizationId,
          propertyId,
          householdId,
          true,
          abortController.signal,
        );
        setCamPools(response);
        setIsLoading(false);
      }
    };

    fetchCamRecords();
    setShouldRefresh(false);

    return () => abortController.abort();
  }, [organizationId, propertyId, householdId, shouldRefresh]);

  return [isLoading, camPools, () => setShouldRefresh(true)];
};
