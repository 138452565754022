import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  calculateRentWithAmenities,
  calculateMonthlyRecurringChargesTotal,
  getMonthlyRecurringCharges,
} from '../../../utils/lease-helpers';
import { formatCurrency } from '../../../utils';
import messages from './messages';

const MonthlyRecurringCharges = ({ unit, intl }: Object) => {
  const quotingRentAmount = calculateRentWithAmenities(unit);
  const monthlyRentAmount = calculateMonthlyRecurringChargesTotal(unit);
  const recurringFees = getMonthlyRecurringCharges(unit);
  return (
    <div className="panel unit-charges panel-has-table">
      <div className="panel-head">
        <h2>
          <FormattedMessage {...messages.title} />
        </h2>
      </div>
      <div className="unit-details">
        <table className="table table-striped">
          <tbody>
            <tr>
              <td style={{ width: '70%' }}>
                <FormattedMessage {...messages.unitQuotingRentAmount} />
              </td>
              <td>{formatCurrency(intl, quotingRentAmount)}</td>
            </tr>
            {recurringFees
              .sort((a, b) => b.value - a.value)
              .map((fee, key) => (
                <tr key={key}>
                  <td>{fee.label}</td>
                  <td>
                    {fee.descriptiveValue
                      ? fee.descriptiveValue
                      : formatCurrency(intl, fee.value)}
                  </td>
                </tr>
              ))}
            <tr className="table-section table-active">
              <td>
                <FormattedMessage {...messages.total} />
              </td>
              <td>{formatCurrency(intl, monthlyRentAmount)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MonthlyRecurringCharges;
