import type { Action } from '../App/types';
import * as ActionTypes from './constants';
import type { RenewalFormInfo } from './types.js';

export function getRenewalOfferTerms(): Action<any> {
  return {
    type: ActionTypes.GET_RENEWAL_OFFER_TERMS,
    payload: undefined
  };
}

export function getRenewalOfferTermsSuccess(terms: any): Action<any> {
  return {
    type: ActionTypes.GET_RENEWAL_OFFER_TERMS_SUCCESS,
    payload: terms
  };
}

export function getRenewalOfferTermsError(payload: Object): Action<any> {
  return {
    type: ActionTypes.GET_RENEWAL_OFFER_TERMS_ERROR,
    error: true,
    payload
  };
}

export function getPreviousRenewalOffers(leaseId: string): Action<any> {
  return {
    type: ActionTypes.GET_PREVIOUS_RENEWAL_OFFERS,
    payload: leaseId
  };
}

export function getPreviousRenewalOffersSuccess(offers: any): Action<any> {
  return {
    type: ActionTypes.GET_PREVIOUS_RENEWAL_OFFERS_SUCCESS,
    payload: offers
  };
}

export function getPreviousRenewalOffersError(payload: Object): Action<any> {
  return {
    type: ActionTypes.GET_PREVIOUS_RENEWAL_OFFERS_ERROR,
    error: true,
    payload
  };
}

export function createRenewalOffer(
  renewalOffer: RenewalFormInfo,
  leaseId: string
): Action<any> {
  return {
    type: ActionTypes.CREATE_RENEWAL_OFFER,
    payload: {
      renewalOffer,
      leaseId
    }
  };
}

export function createRenewalOfferError(payload: Object): Action<any> {
  return {
    type: ActionTypes.CREATE_RENEWAL_OFFER_ERROR,
    error: true,
    payload
  };
}

export function clearLoadedRenewalOffers(): Action<any> {
  return {
    type: ActionTypes.CLEAR_RENEWAL_OFFER_TERMS,
    payload: undefined
  };
}
