import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import {
  RECERT_CERT_TYPE,
  GROSS_RENT_CERT_TYPE,
  MOVE_IN_CERT_TYPE,
  INITIAL_CERT_TYPE,
  OTHER_HUD,
  OTHER,
  INTERIM_CERT_TYPE,
} from '../../constants/affordableCertificationTypes';
import generalMessages from '../App/messages';
import componentMessages from './messages';
import { isRecertLate } from './utilsV2';

const messages = { ...generalMessages, ...componentMessages };

export const COUNT_NAME = <FormattedMessage {...messages.certifications} />;

const MULTI_HEADERS = {
  certificationNotes: {
    id: 'certificationNotes',
    label: <FormattedMessage {...messages.certificationNotes} />,
    sortable: true,
    searchable: true,
    dataType: 'string',
  },
  complianceDocument: {
    id: 'complianceDocument',
    label: <FormattedMessage {...messages.complianceDocument} />,
    sortable: true,
    searchable: true,
    multiselect: true,
    dataType: 'string',
  },
  unit: {
    id: 'unit',
    label: <FormattedMessage {...messages.unit} />,
    sortable: true,
    searchable: true,
    dataType: 'string',
  },
  occupiedOnNTV: {
    id: 'occupiedOnNTV',
    label: <FormattedMessage {...messages.noticeToVacate} />,
    sortable: true,
    searchable: false,
    filterOptions: [{ text: 'Yes', value: 'yes' }],
    dataType: 'string',
  },
};

const OTHER_HEADERS = [
  {
    id: 'status',
    label: <FormattedMessage {...messages.status} />,
    sortable: true,
    searchable: false,
    multiselect: true,
    dataType: 'string',
  },
  {
    id: 'certificationType',
    label: <FormattedMessage {...messages.certificationType} />,
    sortable: true,
    searchable: false,
    multiselect: true,
    dataType: 'string',
  },
  MULTI_HEADERS.unit,
  {
    id: 'floorPlan',
    label: <FormattedMessage {...messages.floorPlan} />,
    sortable: true,
    searchable: true,
    dataType: 'string',
  },
  {
    id: 'headOfHousehold',
    label: <FormattedMessage {...messages.headOfHousehold} />,
    sortable: true,
    searchable: true,
    dataType: 'string',
  },
  {
    id: 'effectiveDate',
    label: <FormattedMessage {...messages.effectiveDate} />,
    sortable: true,
    searchable: true,
    dataType: 'date',
  },
  {
    id: 'lastCertifiedDate',
    label: <FormattedMessage {...messages.lastCertifiedDate} />,
    sortable: true,
    searchable: true,
    dataType: 'date',
  },
  {
    id: 'nextRecertDueDate',
    label: <FormattedMessage {...messages.nextRecertDueDate} />,
    sortable: true,
    searchable: true,
    dataType: 'date',
  },
  {
    id: 'isPriorResident',
    label: <FormattedMessage {...messages.priorResident} />,
    sortable: true,
    searchable: false,
    filterOptions: [
      { text: 'Yes', value: 'yes' },
      { text: 'No', value: 'no' },
    ],
    dataType: 'string',
  },
  {
    id: 'isCorrection',
    label: <FormattedMessage {...messages.correction} />,
    sortable: true,
    searchable: false,
    filterOptions: [
      { text: 'Yes', value: 'yes' },
      { text: 'No', value: 'no' },
    ],
    dataType: 'string',
  },
  {
    id: 'balance',
    label: <FormattedMessage {...messages.balance} />,
    sortable: true,
    searchable: false,
    dataType: 'number',
  },
  {
    id: 'latestComplianceDocument',
    label: <FormattedMessage {...messages.complianceDocument} />,
    sortable: true,
    searchable: false,
    dataType: 'string',
  },
  {
    id: 'daysSinceLastUpload',
    label: <FormattedMessage {...messages.daysSinceLastUpload} />,
    sortable: true,
    searchable: false,
    dataType: 'number',
  },
  MULTI_HEADERS.certificationNotes,
];
export const HEADERS = {
  [RECERT_CERT_TYPE]: [
    {
      id: 'status',
      label: <FormattedMessage {...messages.status} />,
      sortable: true,
      searchable: true,
      multiselect: true,
      dataType: 'string',
    },
    {
      id: 'programName',
      label: <FormattedMessage {...messages.programName} />,
      sortable: true,
      searchable: true,
      multiselect: true,
      dataType: 'string',
    },
    MULTI_HEADERS.unit,
    {
      id: 'floorPlan',
      label: <FormattedMessage {...messages.floorPlan} />,
      sortable: true,
      searchable: true,
      dataType: 'string',
    },
    {
      id: 'headOfHousehold',
      label: <FormattedMessage {...messages.headOfHousehold} />,
      sortable: true,
      searchable: true,
      dataType: 'string',
    },
    {
      id: 'moveInDate',
      label: <FormattedMessage {...messages.moveIn} />,
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'leaseStartDate',
      label: <FormattedMessage {...messages.leaseStartDate} />,
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'lastCertified',
      label: <FormattedMessage {...messages.lastCertified} />,
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'recertDue',
      label: <FormattedMessage {...messages.recertDue} />,
      sortable: true,
      searchable: true,
      filterOptions: [
        { text: <FormattedMessage {...messages.late} />, value: 'late' },
        { text: <FormattedMessage {...messages.under30} />, value: 'under30' },
        {
          text: <FormattedMessage {...messages.between31And60} />,
          value: 'between31And60',
        },
        {
          text: <FormattedMessage {...messages.between61And90} />,
          value: 'between61And90',
        },
        {
          text: <FormattedMessage {...messages.between90And120} />,
          value: 'between90And120',
        },
        {
          text: <FormattedMessage {...messages.upcoming} />,
          value: 'upcoming',
        },
      ],
      multiselect: true,
      dataType: 'date',
    },
    MULTI_HEADERS.occupiedOnNTV,
    {
      id: 'doNotRenew',
      label: <FormattedMessage {...messages.doNotRenew} />,
      sortable: true,
      searchable: false,
      dataType: 'boolean',
    },
    {
      id: 'underEviction',
      label: <FormattedMessage {...messages.underEviction} />,
      sortable: true,
      searchable: false,
      dataType: 'boolean',
    },
    {
      id: 'balance',
      label: <FormattedMessage {...messages.balance} />,
      sortable: true,
      searchable: true,
      dataType: 'number',
    },
    {
      id: 'notice120',
      label: <FormattedMessage {...messages.notice120} />,
      sortable: true,
      searchable: false,
      filterOptions: [{ text: 'Yes', value: 'yes' }],
      dataType: 'string',
    },
    {
      id: 'notice90',
      label: <FormattedMessage {...messages.notice90} />,
      sortable: true,
      searchable: false,
      filterOptions: [{ text: 'Yes', value: 'yes' }],
      dataType: 'string',
    },
    {
      id: 'notice60',
      label: <FormattedMessage {...messages.notice60} />,
      sortable: true,
      searchable: false,
      filterOptions: [{ text: 'Yes', value: 'yes' }],
      dataType: 'string',
    },
    {
      id: 'notice30',
      label: <FormattedMessage {...messages.notice30} />,
      sortable: true,
      searchable: false,
      filterOptions: [{ text: 'Yes', value: 'yes' }],
      dataType: 'string',
    },
    MULTI_HEADERS.complianceDocument,
    MULTI_HEADERS.certificationNotes,
  ],
  [GROSS_RENT_CERT_TYPE]: [
    MULTI_HEADERS.unit,
    {
      id: 'floorPlan',
      label: <FormattedMessage {...messages.floorPlan} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'headOfHousehold',
      label: <FormattedMessage {...messages.headOfHousehold} />,
      sortable: true,
      searchable: true,
      dataType: 'string',
    },
    {
      id: 'newGrossRent',
      label: <FormattedMessage {...messages.newGrossRent} />,
      sortable: true,
      searchable: true,
      dataType: 'number',
    },
    {
      id: 'newHUDUA',
      label: <FormattedMessage {...messages.newHUDUA} />,
      sortable: true,
      searchable: true,
      dataType: 'number',
    },
    {
      id: 'grEffectiveDate',
      label: <FormattedMessage {...messages.grEffectiveDate} />,
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'anticipatedVoucherDate',
      label: <FormattedMessage {...messages.anticipatedVoucherDate} />,
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'priorCertificationDate',
      label: <FormattedMessage {...messages.priorCertificationDate} />,
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'otherCertInProgress',
      label: <FormattedMessage {...messages.otherCertInProgress} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    { ...MULTI_HEADERS.occupiedOnNTV, filterOptions: undefined },
    {
      id: 'newSubsidy',
      label: <FormattedMessage {...messages.newSubsidy} />,
      sortable: true,
      searchable: true,
      dataType: 'number',
      sx: { backgroundColor: 'primary.dark' },
    },
    {
      id: 'newResidentRent',
      label: <FormattedMessage {...messages.newResidentRent} />,
      sortable: true,
      searchable: true,
      dataType: 'number',
      sx: { backgroundColor: 'primary.dark' },
    },
    {
      id: 'newUtilityReimbursement',
      label: <FormattedMessage {...messages.newUtilityReimbursement} />,
      sortable: true,
      searchable: true,
      dataType: 'number',
      sx: { backgroundColor: 'primary.dark' },
    },
    {
      id: 'residentSignatureRequired',
      label: <FormattedMessage {...messages.residentSignatureRequired} />,
      sortable: true,
      searchable: true,
      dataType: 'string',
    },
    {
      id: 'correction',
      label: <FormattedMessage {...messages.correction} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'generated59a',
      label: <FormattedMessage {...messages.generated59a} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'uploadedDocument',
      label: <FormattedMessage {...messages.uploadedDocument} />,
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'generate59a',
      label: <FormattedMessage {...messages.generate59a} />,
      sortable: false,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'action',
      label: <FormattedMessage {...messages.action} />,
      sortable: false,
      searchable: false,
      dataType: 'string',
    },
  ],
  [MOVE_IN_CERT_TYPE]: [
    {
      id: 'status',
      label: <FormattedMessage {...messages.status} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'programName',
      label: <FormattedMessage {...messages.programName} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    MULTI_HEADERS.unit,
    {
      id: 'floorPlan',
      label: <FormattedMessage {...messages.floorPlan} />,
      sortable: true,
      searchable: true,
      dataType: 'string',
    },
    {
      id: 'headOfHousehold',
      label: <FormattedMessage {...messages.headOfHousehold} />,
      sortable: true,
      searchable: true,
      dataType: 'string',
    },
    {
      id: 'moveInDate',
      label: <FormattedMessage {...messages.scheduledMoveInDate} />,
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'leaseStartDate',
      label: <FormattedMessage {...messages.leaseStartDate} />,
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'isResident',
      label: <FormattedMessage {...messages.currentResident} />,
      sortable: true,
      searchable: false,
      filterOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      dataType: 'string',
    },
    {
      id: 'balance',
      label: <FormattedMessage {...messages.balance} />,
      sortable: true,
      searchable: true,
      dataType: 'number',
    },
    {
      id: 'isCorrection',
      label: <FormattedMessage {...messages.correction} />,
      sortable: true,
      searchable: false,
      filterOptions: [{ text: 'Yes', value: 'yes' }],
      dataType: 'string',
    },
    MULTI_HEADERS.complianceDocument,
    {
      id: 'daysSinceLastUpload',
      label: <FormattedMessage {...messages.daysSinceLastUpload} />,
      sortable: true,
      searchable: true,
      dataType: 'number',
    },
    MULTI_HEADERS.certificationNotes,
  ],
  [INITIAL_CERT_TYPE]: [
    {
      id: 'certStatus',
      label: <FormattedMessage {...messages.certStatus} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'programName',
      label: <FormattedMessage {...messages.programName} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    MULTI_HEADERS.unit,
    {
      id: 'floorplan',
      label: <FormattedMessage {...messages.floorPlan} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'headOfHousehold',
      label: <FormattedMessage {...messages.headOfHousehold} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'effectiveDate',
      label: <FormattedMessage {...messages.effectiveDate} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'leaseStartDate',
      label: <FormattedMessage {...messages.leaseStartDate} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'isPriorResident',
      label: <FormattedMessage {...messages.priorResident} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'balance',
      label: <FormattedMessage {...messages.balance} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'isCorrection',
      label: <FormattedMessage {...messages.correction} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'latestComplianceDocument',
      label: <FormattedMessage {...messages.complianceDocument} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'daysSinceLastUpload',
      label: <FormattedMessage {...messages.daysSinceLastUpload} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    MULTI_HEADERS.certificationNotes,
  ],
  [INTERIM_CERT_TYPE]: [
    {
      id: 'status',
      label: <FormattedMessage {...messages.status} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'programName',
      label: <FormattedMessage {...messages.programName} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    MULTI_HEADERS.unit,
    {
      id: 'floorPlan',
      label: <FormattedMessage {...messages.floorPlan} />,
      sortable: true,
      searchable: true,
      dataType: 'string',
    },
    {
      id: 'headOfHousehold',
      label: <FormattedMessage {...messages.headOfHousehold} />,
      sortable: true,
      searchable: true,
      dataType: 'string',
    },
    {
      id: 'effectiveDate',
      label: <FormattedMessage {...messages.effectiveDate} />,
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'lastCertifiedDate',
      label: <FormattedMessage {...messages.lastCertifiedDate} />,
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'nextRecertDueDate',
      label: <FormattedMessage {...messages.nextRecertDueDate} />,
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'isPriorResident',
      label: <FormattedMessage {...messages.priorResident} />,
      sortable: true,
      searchable: false,
      filterOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      dataType: 'string',
    },
    {
      id: 'isCorrection',
      label: <FormattedMessage {...messages.correction} />,
      sortable: true,
      searchable: false,
      filterOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
      dataType: 'string',
    },
    {
      id: 'balance',
      label: <FormattedMessage {...messages.balance} />,
      sortable: true,
      searchable: false,
      dataType: 'number',
    },
    {
      id: 'latestComplianceDocument',
      label: <FormattedMessage {...messages.complianceDocument} />,
      sortable: true,
      searchable: false,
      dataType: 'string',
    },
    {
      id: 'daysSinceLastUpload',
      label: <FormattedMessage {...messages.daysSinceLastUpload} />,
      sortable: true,
      searchable: false,
      dataType: 'number',
    },
    MULTI_HEADERS.certificationNotes,
  ],
  [OTHER_HUD]: OTHER_HEADERS,
  [OTHER]: OTHER_HEADERS,
};

const OTHER_PROPERTY_PATH_MAP = {
  status: {
    path: 'complianceApprovalStatus.name',
    dataType: 'string',
  },
  certificationType: {
    path: 'certificationType',
    dataType: 'string',
  },
  unit: {
    path: 'unit',
    dataType: 'string',
  },
  floorPlan: {
    path: 'floorplan',
    dataType: 'string',
  },
  headOfHousehold: {
    path: 'headOfHousehold',
    dataType: 'string',
  },
  effectiveDate: {
    path: 'voucherEffectiveDate',
    dataType: 'date',
  },
  lastCertifiedDate: {
    path: 'lastCertifiedDate',
    dataType: 'date',
  },
  nextRecertDueDate: {
    path: 'nextRecertDueDate',
    dataType: 'date',
  },
  isPriorResident: {
    path: 'isPriorResident',
    dataType: 'string',
  },
  isCorrection: {
    path: 'isCorrection',
    dataType: 'string',
  },
  balance: {
    path: 'balance',
    dataType: 'number',
  },
  latestComplianceDocument: {
    path: 'latestDocument.documentType.name',
    dataType: 'string',
  },
  daysSinceLastUpload: {
    path: 'daysSinceLastUpload',
    dataType: 'number',
  },
  certificationNotes: {
    path: 'certificationNotes',
    dataType: 'string',
  },
};
export const PROPERTY_PATH_MAP = {
  [RECERT_CERT_TYPE]: {
    status: {
      path: 'recertStatus',
      dataType: 'string',
    },
    programName: {
      path: 'programName',
      dataType: 'string',
    },
    unit: {
      path: 'unit',
      dataType: 'string',
    },
    floorPlan: {
      path: 'floorPlan',
      dataType: 'string',
    },
    headOfHousehold: {
      path: 'headOfHousehold',
      dataType: 'string',
    },
    moveInDate: {
      path: 'moveInDate',
      dataType: 'date',
    },
    leaseStartDate: {
      path: 'leaseStartDate',
      dataType: 'date',
    },
    lastCertified: {
      path: 'lastCertified',
      dataType: 'date',
    },
    recertDue: {
      path: 'recertDue',
      dataType: 'date',
      filterFn: (
        filterBy: [
          | 'late'
          | 'under30'
          | 'between31And60'
          | 'between61And90'
          | 'between90And120'
          | 'upcoming',
        ],
        filterValue: string,
      ) => {
        let shouldIncludeArray = [];
        const today = moment();
        if (filterBy.length === 0) {
          shouldIncludeArray.push(true);
        }
        if (filterBy.includes('late')) {
          shouldIncludeArray.push(isRecertLate(filterValue));
        }
        if (filterBy.includes('under30')) {
          shouldIncludeArray.push(
            moment(filterValue).isBetween(today, moment(today).add(30, 'days')),
          );
        }
        if (filterBy.includes('between31And60')) {
          shouldIncludeArray.push(
            moment(filterValue).isBetween(
              moment(today).add(30, 'days'),
              moment(today).add(60, 'days'),
            ),
          );
        }
        if (filterBy.includes('between61And90')) {
          shouldIncludeArray.push(
            moment(filterValue).isBetween(
              moment(today).add(60, 'days'),
              moment(today).add(90, 'days'),
            ),
          );
        }
        if (filterBy.includes('between90And120')) {
          shouldIncludeArray.push(
            moment(filterValue).isBetween(
              moment(today).add(90, 'days'),
              moment(today).add(120, 'days'),
            ),
          );
        }
        if (filterBy.includes('upcoming')) {
          shouldIncludeArray.push(
            moment(filterValue).isAfter(moment(today).add(120, 'days')),
          );
        }

        return shouldIncludeArray.includes(true);
      },
    },
    occupiedOnNTV: {
      path: 'occupiedOnNTV',
      dataType: 'string',
    },
    doNotRenew: {
      path: 'doNotRenew',
      dataType: 'boolean',
    },
    underEviction: {
      path: 'underEviction',
      dataType: 'boolean',
    },
    balance: {
      path: 'balance',
      dataType: 'number',
    },
    notice120: {
      path: 'notice120',
      dataType: 'string',
    },
    notice90: {
      path: 'notice90',
      dataType: 'string',
    },
    notice60: {
      path: 'notice60',
      dataType: 'string',
    },
    notice30: {
      path: 'notice30',
      dataType: 'string',
    },
    complianceDocument: {
      path: 'complianceDocument',
      dataType: 'string',
    },
    certificationNotes: {
      path: 'certificationNotes',
      dataType: 'string',
    },
  },
  [GROSS_RENT_CERT_TYPE]: {
    unit: {
      path: 'unit',
      dataType: 'string',
    },
    floorPlan: {
      path: 'floorPlanName',
      dataType: 'string',
    },
    headOfHousehold: {
      path: 'headOfHousehold',
      dataType: 'string',
    },
    newGrossRent: {
      path: 'newGrossRent',
      dataType: 'number',
    },
    newHUDUA: {
      path: 'newHUDUA',
      dataType: 'number',
    },
    grEffectiveDate: {
      path: 'grEffectiveDate',
      dataType: 'date',
    },
    anticipatedVoucherDate: {
      path: 'anticipatedVoucherDate',
      dataType: 'date',
    },
    priorCertificationDate: {
      path: 'priorCertificationDate',
      dataType: 'date',
    },
    otherCertInProgress: {
      path: 'otherCertInProgress',
      dataType: 'string',
    },
    occupiedOnNTV: {
      path: 'occupiedOnNTV',
      dataType: 'string',
    },
    newSubsidy: {
      path: 'newSubsidy',
      dataType: 'number',
    },
    newResidentRent: {
      path: 'newResidentRent',
      dataType: 'number',
    },
    newUtilityReimbursement: {
      path: 'newUtilityReimbursement',
      dataType: 'number',
    },
    residentSignatureRequired: {
      path: 'residentSignatureRequired',
      dataType: 'string',
    },
    correction: {
      path: 'isCorrection',
      dataType: 'string',
    },
    generated59a: {
      path: 'generated59a',
      dataType: 'string',
    },
    uploadedDocument: {
      path: 'document.createdAt',
      dataType: 'date',
    },
  },
  [MOVE_IN_CERT_TYPE]: {
    status: {
      path: 'complianceApprovalStatus.name',
      dataType: 'string',
    },
    programName: {
      path: 'programName',
      dataType: 'string',
    },
    unit: {
      path: 'unit',
      dataType: 'string',
    },
    floorPlan: {
      path: 'floorplan',
      dataType: 'string',
    },
    headOfHousehold: {
      path: 'headOfHousehold',
      dataType: 'string',
    },
    moveInDate: {
      path: 'moveInDate',
      dataType: 'date',
    },
    leaseStartDate: {
      path: 'leaseStartDate',
      dataType: 'date',
    },
    isResident: {
      path: 'isResident',
      dataType: 'string',
    },
    balance: {
      path: 'balance',
      dataType: 'number',
    },
    isCorrection: {
      path: 'isCorrection',
      dataType: 'string',
    },
    complianceDocument: {
      path: 'latestDocument.documentType.name',
      dataType: 'string',
    },
    daysSinceLastUpload: {
      path: 'daysSinceLastUpload',
      dataType: 'number',
    },
    certificationNotes: {
      path: 'certificationNotes',
      dataType: 'string',
    },
  },
  [INITIAL_CERT_TYPE]: {
    status: {
      path: 'complianceApprovalStatus.name',
      dataType: 'string',
    },
    programName: {
      path: 'programName',
      dataType: 'string',
    },
    unit: {
      path: 'unit',
      dataType: 'string',
    },
    floorPlan: {
      path: 'floorplan',
      dataType: 'string',
    },
    headOfHousehold: {
      path: 'headOfHousehold',
      dataType: 'string',
    },
    moveInDate: {
      path: 'moveInDate',
      dataType: 'date',
    },
    leaseStartDate: {
      path: 'leaseStartDate',
      dataType: 'date',
    },
    isResident: {
      path: 'isResident',
      dataType: 'string',
    },
    balance: {
      path: 'balance',
      dataType: 'number',
    },
    isCorrection: {
      path: 'isCorrection',
      dataType: 'string',
    },
    latestComplianceDocument: {
      path: 'latestDocument.documentType.name',
      dataType: 'string',
    },
    daysSinceLastUpload: {
      path: 'daysSinceLastUpload',
      dataType: 'number',
    },
    certificationNotes: {
      path: 'certificationNotes',
      dataType: 'string',
    },
  },
  // INTERIM = OTHER_LIHTC
  [INTERIM_CERT_TYPE]: {
    status: {
      path: 'complianceApprovalStatus.name',
      dataType: 'string',
    },
    programName: {
      path: 'programName',
      dataType: 'string',
    },
    unit: {
      path: 'unit',
      dataType: 'string',
    },
    floorPlan: {
      path: 'floorplan',
      dataType: 'string',
    },
    headOfHousehold: {
      path: 'headOfHousehold',
      dataType: 'string',
    },
    effectiveDate: {
      path: 'voucherEffectiveDate',
      dataType: 'date',
    },
    lastCertifiedDate: {
      path: 'lastCertifiedDate',
      dataType: 'date',
    },
    nextRecertDueDate: {
      path: 'nextRecertDueDate',
      dataType: 'date',
    },
    isPriorResident: {
      path: 'isPriorResident',
      dataType: 'string',
    },
    isCorrection: {
      path: 'isCorrection',
      dataType: 'string',
    },
    balance: {
      path: 'balance',
      dataType: 'number',
    },
    latestComplianceDocument: {
      path: 'latestDocument.documentType.name',
      dataType: 'string',
    },
    daysSinceLastUpload: {
      path: 'daysSinceLastUpload',
      dataType: 'number',
    },
    certificationNotes: {
      path: 'certificationNotes',
      dataType: 'string',
    },
  },
  [OTHER_HUD]: OTHER_PROPERTY_PATH_MAP,
  [OTHER]: OTHER_PROPERTY_PATH_MAP,
};
