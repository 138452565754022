import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ManageProperties.PropertyPortal.Header',
    defaultMessage: 'Portal',
  },
  cancel: {
    id: 'App.ManageProperties.PropertyPortal.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.ManageProperties.PropertyPortal.Save',
    defaultMessage: 'Save Changes',
  },
  confirmationTitle: {
    id: 'App.ManageProperties.PropertyPortal.Title',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  confirmDeleteTitle: {
    id: 'App.ManageProperties.PropertyPortal.Confirmation.Delete',
    defaultMessage: 'Are you sure you want to delete this vehicle?',
  },
  confirmationCancel: {
    id: 'App.ManageProperties.PropertyPortal.Confirmation.Cancel',
    defaultMessage: 'No',
  },
  confirm: {
    id: 'App.ManageProperties.PropertyPortal.Confirmation.Confirm',
    defaultMessage: 'Yes',
  },
  success: {
    id: 'App.ManageProperties.PropertyPortal.Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'App.ManageProperties.PropertyPortal.Error',
    defaultMessage: 'Error',
  },
  successfullyUpdatedPortal: {
    id: 'App.ManageProperties.PropertyPortal.Success.EnabledPortal',
    defaultMessage: 'Portal Settings have been successfully saved',
  },
  errorUpdatingPortal: {
    id: 'App.ManageProperties.PropertyPortal.Error.EnablingPortal',
    defaultMessage: 'Failed to update Portal',
  },
  successfullyOnboardedPortal: {
    id: 'App.ManageProperties.PropertyPortal.Success.OnboardedPortal',
    defaultMessage: 'Portal Successfully Onboarded',
  },
  errorOnboardingPortal: {
    id: 'App.ManageProperties.PropertyPortal.Error.OnboardingPortal',
    defaultMessage: 'Failed to onboard Portal',
  },
});

export default messages;
