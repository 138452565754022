import { defineMessages } from 'react-intl';

const messages = defineMessages({
  errorHeader: {
    id: 'App.CreateResident.errorHeader',
    defaultMessage: 'Error',
  },
  applicationCheckList: {
    id: 'App.CreateResident.ApplicationChecklist',
    defaultMessage: 'Application Checklist(s)',
  },
  householdScreening: {
    id: 'App.CreateResident.HouseholdScreening',
    defaultMessage: 'Household Screening',
  },
  signedLease: {
    id: 'App.CreateResident.SignedLease',
    defaultMessage: 'Signed Lease',
  },
  leaseStatus: {
    id: 'App.CreateResident.LeaseStatus',
    defaultMessage: 'Lease Data Saved in Lease Data Tab',
  },
  vehicleInformation: {
    id: 'App.CreateResident.VehicleInformation',
    defaultMessage: 'Vehicle Information',
  },
  unitAvailabilityStatus: {
    id: 'App.CreateResident.UnitAvailabilityStatus',
    defaultMessage: 'Assigned Unit is Vacant',
  },
  convertConfirmation: {
    id: 'App.CreateResident.ConvertConfirmation',
    defaultMessage:
      'Are you sure you want to convert this Applicant to a Resident and Confirm Move-In?',
  },
  successHeader: {
    id: 'App.CreateResident.SuccessHeader',
    defaultMessage: 'Success',
  },
  successDescription: {
    id: 'App.CreateResident.SuccessDescription',
    defaultMessage: 'Applicant converted to Resident successfully.',
  },
  leaseStartDateStatus: {
    id: 'App.CreateResident.LeaseStartDateStatus',
    defaultMessage: 'Lease Start Date on Lease Data Tab is on or before Today.',
  },
  complianceApproval: {
    id: 'App.CreateResident.ComplianceApproval',
    defaultMessage: 'Compliance Approval on Qualification Tab',
  },
  monthlyLeaseCharges: {
    id: 'App.CreateResident.monthlyLeaseCharges',
    defaultMessage: 'Monthly Lease Charges on Lease Data Tab Reconciled',
  },
});

export default messages;
