import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  PencilIcon,
  PlusIcon,
  AlertIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import {
  Grid,
  Checkbox,
  Banner,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  Button,
  InputLabel,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils';

import RentStep from './RentStep';
import messages from '../messages';
import { validateLeaseRent as validate } from '../validate';
import { useRentSteps } from './hooks';

const LeaseRentAmountModal = ({
  intl,
  open,
  closeModal,
  leaseRentPercentage,
  unit,
  receivingAssistance,
  affordableValues,
  applicableRents,
  lease,
  property,
  hud236BasicRent,
  hud236MarketRent,
  renewalLeaseStartDate,
  renewalIsRenewalComplete,
  refreshLDT,
}) => {
  const flags = useFlags();
  const {
    rentSteps,
    setRentSteps,
    resetRentSteps,
    saveRentSteps,
    receivingAsstState,
    setReceivingAsstState,
    rentStepsSaving,
    setRentStepsToDelete,
    setRentStepsToCreate,
    setRentStepsToUpdate,
  } = useRentSteps({
    lease,
    receivingAssistance,
    closeModal,
    refreshLDT,
    open,
  });

  function handleModalClose() {
    closeModal();
    resetRentSteps();
  }

  const rentStepHasError = rentSteps.some(
    (step) => !!step.errors?.amount || !!step.errors?.startDate,
  );

  const rentStepHasMissingRequiredFields = rentSteps.some((step) => {
    return !step.amount || !step.startDate;
  });

  // Simplified future rent step check - we only care about the last step
  const hasFutureRentStep =
    rentSteps.length > 0 &&
    moment(rentSteps[rentSteps.length - 1].startDate).isAfter(
      moment().startOf('day'),
    );

  const actions = (
    <>
      <Button
        variant="text"
        onClick={handleModalClose}
        disabled={rentStepsSaving}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={saveRentSteps}
        disabled={
          rentStepHasError ||
          rentStepHasMissingRequiredFields ||
          rentStepsSaving
        }
      >
        Save
      </Button>
    </>
  );

  // Simplified add rent step function - we only add if there's no future step
  function addRentStep() {
    if (hasFutureRentStep) return;

    setRentSteps((prev) => {
      const newRentStep = {
        id: prev.length,
        idx: prev.length,
        startDate: null,
        endDate: null,
      };
      setRentStepsToCreate((prev) => [...prev, newRentStep.id]);
      return [...prev, newRentStep];
    });
  }

  function onRentStepChange({ id, startDate, amount, name, idx }) {
    setRentSteps((prevRentSteps) => {
      const isExistingRentStep = typeof id === 'string';
      const currentStep = prevRentSteps.find((step) => step.idx === idx);
      const isLastStep = idx === prevRentSteps.length - 1;

      if (name === 'date') {
        const priorStep = prevRentSteps.find((step) => step.idx === idx - 1);

        // Simplified end date calculation since we know there's at most one future step
        const endDate = isLastStep
          ? null
          : formatDateDB(moment(startDate).clone().subtract(1, 'days'), false);

        const updatedCurrentStep = {
          ...currentStep,
          startDate,
          endDate,
        };

        const updatedPriorStep = priorStep
          ? {
              ...priorStep,
              endDate: formatDateDB(
                moment(startDate).clone().subtract(1, 'days'),
                false,
              ),
            }
          : null;

        if (isExistingRentStep) {
          setRentStepsToUpdate((prev) => [
            ...prev,
            updatedCurrentStep.id,
            ...(updatedPriorStep ? [updatedPriorStep.id] : []),
          ]);
        }

        return _.orderBy(
          [
            ...prevRentSteps.filter(
              (step) => step.id !== currentStep.id && step.id !== priorStep?.id,
            ),
            updatedCurrentStep,
            ...(updatedPriorStep ? [updatedPriorStep] : []),
          ],
          'idx',
          'asc',
        );
      } else {
        const updatedStep = {
          ...currentStep,
          amount,
          errors: {
            amount: validate({
              leasedRent: amount,
              leaseRentPercentage,
              receivingAssistance: receivingAsstState,
              affordableValues,
              applicableRents,
              lease,
              unit,
              intl,
              flags,
              selectedProperty: property,
              hud236BasicRent,
              hud236MarketRent,
            }).leasedRent,
          },
        };

        if (isExistingRentStep) {
          setRentStepsToUpdate((prev) => [...prev, updatedStep.id]);
        }

        return _.orderBy(
          [...prevRentSteps.filter((step) => step.id !== id), updatedStep],
          'idx',
          'asc',
        );
      }
    });
  }

  function onDeleteRentStep({ id, idx }) {
    if (idx === 0) return; // Prevent deleting first step

    const isExistingRentStep = typeof id === 'string';

    setRentSteps((prevRentSteps) => {
      const stepToDelete = prevRentSteps.find((step) => step.id === id);
      const priorStep = prevRentSteps.find((step) => step.idx === idx - 1);

      if (isExistingRentStep) {
        setRentStepsToDelete((prev) => [...prev, stepToDelete.id]);
        setRentStepsToUpdate((prev) =>
          prev.filter((stepId) => stepId !== stepToDelete.id),
        );
      }

      setRentStepsToCreate((prev) => prev.filter((stepId) => stepId !== id));

      // Since we can only have one future step, updating the prior step's end date is simpler
      const updatedPriorStep = {
        ...priorStep,
        endDate: null,
      };

      if (isExistingRentStep) {
        setRentStepsToUpdate((prev) => [...prev, updatedPriorStep.id]);
      }

      return _.orderBy(
        [
          ...prevRentSteps.filter(
            (step) => step.id !== id && step.id !== priorStep.id,
          ),
          updatedPriorStep,
        ],
        'idx',
        'asc',
      ).map((step, idx) => ({ ...step, idx }));
    });
  }

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      icon={<PencilIcon />}
      title={'Lease Rent Amount'}
      actions={actions}
      ModalProps={{
        PaperProps: {
          sx: {
            width: {
              xs: 'calc(100% - 32px)',
              sm: 600,
            },
          },
        },
      }}
    >
      <Grid container spacing={1} columnSpacing={3}>
        {/* Header */}
        <>
          <Grid item xs={4}>
            <Typography variant="labelLarge">Start Date</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="labelLarge">End Date</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="labelLarge">Lease Rent Amount</Typography>
          </Grid>
        </>
        {/* Rent Steps */}
        {rentSteps.map(({ id, startDate, endDate, amount, errors }, idx) => (
          <RentStep
            key={id}
            id={id}
            idx={idx}
            initialStartDate={startDate}
            initialEndDate={endDate}
            initialAmount={amount}
            onChange={onRentStepChange}
            onDelete={onDeleteRentStep}
            priorRentStep={rentSteps[idx - 1] ?? null}
            errors={errors}
            leaseEndDate={lease.endDate}
            renewalLeaseStartDate={renewalLeaseStartDate}
            renewalOngoing={renewalIsRenewalComplete === false}
            saving={rentStepsSaving}
            canDelete={idx !== 0}
          />
        ))}
        {/* Add Rent Button */}
        <Grid item xs={12} paddingBottom={1}>
          <Button
            variant="text"
            startIcon={<PlusIcon />}
            onClick={addRentStep}
            disabled={
              rentStepHasError ||
              rentStepHasMissingRequiredFields ||
              rentStepsSaving ||
              hasFutureRentStep
            }
          >
            Add Rent
          </Button>
        </Grid>
        {/* Housing Assistance */}
        {affordableValues.isAffordable && (
          <Grid item xs={12}>
            <InputLabel>Receives Housing Assistance</InputLabel>
            <Checkbox
              label="Yes"
              checked={receivingAsstState}
              onChange={(e) => setReceivingAsstState(e.target.checked)}
            />
          </Grid>
        )}
        {/* Banner */}
        <Grid item xs={12}>
          <Banner
            text={
              <Typography sx={{ textWrap: 'wrap' }} color={'text.secondary'}>
                {intl.formatMessage(messages.leaseRentBannerText)}
              </Typography>
            }
            color="lightGrey"
            icon={<AlertIcon />}
            hasBorder={false}
            BoxProps={{
              sx: { height: 'auto' },
            }}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default LeaseRentAmountModal;
