const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class MonthEndService {
  get(organizationId: string, propertyId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/month-end-close`,
      options
    );
  }
}

export default MonthEndService;
