import React from 'react';
import { IntlShape, injectIntl, FormattedMessage } from 'react-intl';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { RenewCircleIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

import ElementWithPermissions from '../../../components/ElementWithPermissions';
import messages from '../messages';

type Props = {
  hasRD: boolean,
  openRDLimitsEdit: Function,
  intl: IntlShape,
};

const RDLimitsBanner = (props: Props) => {
  const { hasRD, openRDLimitsEdit } = props;
  return (
    <>
      {hasRD && (
        <ElementWithPermissions scope={['rd-limits-edit']}>
          <Button
            variant={'primarySubtle'}
            onClick={openRDLimitsEdit}
            type="button"
            startIcon={<RenewCircleIcon />}
          >
            <FormattedMessage {...messages.changeRDLimits} />
          </Button>
        </ElementWithPermissions>
      )}
    </>
  );
};

export default injectIntl(RDLimitsBanner);
