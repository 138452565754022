import { defineMessages } from 'react-intl';

const messages = defineMessages({
  feeTransactionCode: {
    id: 'App.ManageFees.feeTransactionCode',
    defaultMessage: 'Fee Transaction Code',
  },
  transactionDescription: {
    id: 'App.ManageFees.transactionDescription',
    defaultMessage: 'Transaction Description',
  },
  chargeAt: {
    id: 'App.ManageFees.chargeAt',
    defaultMessage: 'Charge at',
  },
  displayNameOnQuote: {
    id: 'App.ManageFees.displayNameOnQuote',
    defaultMessage: 'Display on Quote',
  },
  nameOnQuote: {
    id: 'App.ManageFees.nameOnQuote',
    defaultMessage: 'Name on Quote',
  },
  oneTimeCharge: {
    id: 'App.ManageFees.oneTimeCharge',
    defaultMessage: 'One-Time Charge?',
  },
  feeAmount: {
    id: 'App.ManageFees.feeAmount',
    defaultMessage: 'Fee Amount',
  },
  feeDescriptionAmount: {
    id: 'App.ManageFees.feeDescriptionAmount',
    defaultMessage: 'Fee Amount Description',
  },
  lasUpdatedOnBy: {
    id: 'App.ManageFees.lasUpdatedOnBy',
    defaultMessage: 'Last Update On / By',
  },
  emptyStateMessage: {
    id: 'App.ManageFees.emptyMessage',
    defaultMessage: 'No fees configured in this property',
  },
  floorPlanFee: {
    id: 'App.ManageFees.floorPlanFee',
    defaultMessage: 'Floor Plan',
  },
  unitFee: {
    id: 'App.ManageFees.unitFee',
    defaultMessage: 'Unit',
  },
  propertyFee: {
    id: 'App.ManageFees.propertyFee',
    defaultMessage: 'Property',
  },
  title: {
    id: 'App.ManageFees.title',
    defaultMessage: 'Manage Fees',
  },
  editHeader: {
    id: 'App.ManageFees.editHeader',
    defaultMessage: 'Edit',
  },
});

export default messages;
