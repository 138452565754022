export const FORM_INFO = {
  income: [
    {
      name: 'employment',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'selfEmployment',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'militaryPay',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'notWorking',
      type: 'text',
      hasForm: true,
    },
    {
      name: 'unemploymentBenefits',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'socialSecurity',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'supplementalSecurity',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'vaBenefits',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'tanf',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'disability',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'recurringGift',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'retirement',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'trust',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'absentFamilyMember',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'other',
      type: 'income',
      hasForm: true,
    },
  ],
  childSupport: [
    {
      name: 'courtOrderedChildSupport',
      type: 'income',
      hasForm: false,
    },
    {
      name: 'receivingChildSupport',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'collectChildSupport',
      type: 'states',
      hasForm: false,
    },
  ],
  misc: [
    {
      name: 'anticipateChildSupport',
      type: 'states',
      hasForm: true,
    },
    {
      name: 'publicHousing',
      type: 'text',
      hasForm: true,
    },
  ],
};

export const INCOME_VALUE_FIELDS = [
  'employmentIncome',
  'selfEmploymentIncome',
  'militaryPayIncome',
  'unemploymentBenefitsIncome',
  'socialSecurityIncome',
  'supplementalSecurityIncome',
  'vaBenefitsIncome',
  'tanfIncome',
  'disabilityIncome',
  'recurringGiftIncome',
  'retirementIncome',
  'trustIncome',
  'absentFamilyMemberIncome',
  'otherIncome',
  'receivingChildSupportIncome',
];

export const PAYRATE_VALUE_FIELDS = [
  'selfEmploymentPayRate',
  'militaryPayPayRate',
  'unemploymentBenefitsPayRate',
  'socialSecurityPayRate',
  'supplementalSecurityPayRate',
  'vaBenefitsPayRate',
  'tanfPayRate',
  'disabilityPayRate',
  'recurringGiftPayRate',
  'retirementPayRate',
  'trustPayRate',
  'absentFamilyMemberPayRate',
  'receivingChildSupportPayRate',
];

export const HOURSWEEKSMONTHS_VALUE_FIELDS = [
  'selfEmploymentHoursWeeksMonths',
  'militaryPayHoursWeeksMonths',
  'unemploymentBenefitsHoursWeeksMonths',
  'socialSecurityHoursWeeksMonths',
  'supplementalSecurityHoursWeeksMonths',
  'vaBenefitsHoursWeeksMonths',
  'tanfHoursWeeksMonths',
  'disabilityHoursWeeksMonths',
  'recurringGiftHoursWeeksMonths',
  'retirementHoursWeeksMonths',
  'trustHoursWeeksMonths',
  'absentFamilyMemberHoursWeeksMonths',
  'receivingChildSupportHoursWeeksMonths',
];

export const INCOME_ROW_NAMES = [
  'employment',
  'selfEmployment',
  'militaryPay',
  'notWorking',
  'unemploymentBenefits',
  'socialSecurity',
  'supplementalSecurity',
  'vaBenefits',
  'tanf',
  'disability',
  'recurringGift',
  'retirement',
  'trust',
  'other',
  'absentFamilyMember',
  'courtOrderedChildSupport',
  'receivingChildSupport',
  'collectChildSupport',
  'anticipateChildSupport',
  'publicHousing',
];

export const INITIAL_INCOME_VALUES = {
  employmentIncome: '0.00',
  selfEmploymentIncome: '0.00',
  militaryPayIncome: '0.00',
  unemploymentBenefitsIncome: '0.00',
  socialSecurityIncome: '0.00',
  supplementalSecurityIncome: '0.00',
  vaBenefitsIncome: '0.00',
  tanfIncome: '0.00',
  disabilityIncome: '0.00',
  recurringGiftIncome: '0.00',
  retirementIncome: '0.00',
  trustIncome: '0.00',
  absentFamilyMemberIncome: '0.00',
  otherIncome: '0.00',
  receivingChildSupportIncome: '0.00',
  courtOrderedChildSupportIncome: '0.00',
  hapPaymentIncome: '0.00',
  residentPaymentIncome: '0.00',
};

export const STATE_INITIAL_INCOME_VALUES = {
  anticipateChildSupportState: '',
  collectChildSupportState: '',
};

export const ALTERNATE_FORM_INFO = {
  income: [
    {
      number: '1',
      name: 'employment',
      type: 'income',
      hasForm: true,
    },
    {
      number: '2',
      name: 'selfEmployment',
      type: 'income',
      hasForm: true,
    },
    {
      number: '3',
      name: 'militaryPay',
      type: 'income',
      hasForm: true,
    },
    {
      number: '4',
      name: 'notWorking',
      type: 'text',
      hasForm: true,
    },
    {
      number: '5',
      name: 'unemploymentBenefits',
      type: 'income',
      hasForm: true,
    },
    {
      number: '6',
      name: 'socialSecurity',
      type: 'income',
      hasForm: true,
    },
    {
      number: '7',
      name: 'pension',
      type: 'income',
      hasForm: false,
    },
    {
      name: 'supplementalSecurity',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'vaBenefits',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'tanf',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'disability',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'incomeFromOnlineSources',
      type: 'income',
      hasForm: false,
    },
    {
      name: 'unearnedIncome17AndUnder',
      type: 'income',
      hasForm: false,
    },
    {
      name: 'recurringGift',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'retirement',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'trust',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'absentFamilyMember',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'other',
      type: 'income',
      hasForm: true,
    },
  ],
  childSupport: [
    {
      name: 'courtOrderedChildSupport',
      type: 'income',
      hasForm: false,
    },
    {
      name: 'receivingChildSupport',
      type: 'income',
      hasForm: true,
    },
    {
      name: 'collectChildSupport',
      type: 'states',
      hasForm: false,
    },
  ],
  misc: [
    {
      name: 'anticipateChildSupport',
      type: 'states',
      hasForm: true,
    },
    {
      name: 'publicHousing',
      type: 'text',
      hasForm: true,
    },
  ],
};

const ALTERNATE_INCOME_VALUE_FIELDS = [
  'employmentList',
  'selfEmploymentIncome',
  'militaryPayIncome',
  'unemploymentBenefitsIncome',
  'socialSecurityIncome',
  'supplementalSecurityIncome',
  'pensionIncome',
  'vaBenefitsIncome',
  'tanfIncome',
  'disabilityIncome',
  'recurringGiftIncome',
  'unearnedIncome17AndUnderIncome',
  'incomeFromOnlineSourcesIncome',
  'retirementIncome',
  'trustIncome',
  'absentFamilyMemberIncome',
  'otherList',
  'receivingChildSupportIncome',
];

export const ALTERNATE_INCOME_ROW_NAMES = [
  'employment',
  'selfEmployment',
  'militaryPay',
  'notWorking',
  'unemploymentBenefits',
  'socialSecurity',
  'pension',
  'supplementalSecurity',
  'vaBenefits',
  'tanf',
  'disability',
  'incomeFromOnlineSources',
  'unearnedIncome17AndUnder',
  'recurringGift',
  'retirement',
  'trust',
  'other',
  'absentFamilyMember',
  'courtOrderedChildSupport',
  'receivingChildSupport',
  'collectChildSupport',
  'anticipateChildSupport',
  'publicHousing',
];

export const ORGANIZATION_FIELDS_MAP = {
  alternate: {
    INCOME_VALUE_FIELDS: [...ALTERNATE_INCOME_VALUE_FIELDS],
    FORM_INFO: { ...ALTERNATE_FORM_INFO },
    INCOME_ROW_NAMES: [...ALTERNATE_INCOME_ROW_NAMES],
    PAYRATE_VALUE_FIELDS: [...PAYRATE_VALUE_FIELDS],
    HOURSWEEKSMONTHS_VALUE_FIELDS: [...HOURSWEEKSMONTHS_VALUE_FIELDS],
  },
  default: {
    INCOME_ROW_NAMES,
    INCOME_VALUE_FIELDS,
    FORM_INFO,
  },
};
