import { defineMessages } from 'react-intl';

const messages = defineMessages({
  totalPayments: {
    id: 'App.ViewBankDeposit.TotalPayments',
    defaultMessage: 'Total Payments'
  },
  numberOfItems: {
    id: 'App.ViewBankDeposit.NumberOfItems',
    defaultMessage: 'Number of items'
  },
  cancel: {
    id: 'App.ViewBankDeposit.Cancel',
    defaultMessage: 'Cancel'
  }
});

export default messages;
