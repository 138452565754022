import { defineMessages } from 'react-intl';

const messages = defineMessages({
  save: {
    id: 'App.LeaseDataTabForm.LeaseActionButtons.SaveLabel',
    defaultMessage: 'Save',
  },
  header: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.Header',
    defaultMessage: 'Upload Signed Lease',
  },
  confirmationTitle: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.Confirmation.Title',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  confirmationCancel: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.Confirmation.Cancel',
    defaultMessage: 'No',
  },
  confirm: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.Confirmation.Confirm',
    defaultMessage: 'Yes',
  },
  cancel: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.Cancel',
    defaultMessage: 'Cancel',
  },
  maxFileSizeExceeded: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.MaxFileSizeExceeded',
    defaultMessage: 'Maximum file size is 100 MB',
  },
  signAndCompleteHeader: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.SignAndCompleteHeader',
    defaultMessage: 'Sign and Complete Lease',
  },
  signAndExecute: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.SignAndExecute',
    defaultMessage: 'Sign and Execute',
  },
  yourSignature: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.YourSignature',
    defaultMessage: 'Your Signature',
  },
  clear: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.Clear',
    defaultMessage: 'Clear',
  },
});

export default messages;
