import { curryN } from 'ramda';
import React from 'react';
import { Col, Grid, Row, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ColumnHeader from '../../../components/ColumnHeader';
import Spinner from '../../../components/Spinner';
import CommunicationDetails from '../CommunicationDetails';
import messages from './messages';

type Props = {
  intl: any,
  history: any,
  columnOrder: any,
  communications: Array<any>,
  handleOrderClick: Function,
  getOrder: Function,
  hasActiveFilters: boolean,
  openFilter: Function,
  handleViewCommunicationClick: Function,
  isAllCommercial: boolean,
  isLoading: boolean,
};

const CommunicationsTable = ({
  intl,
  columnOrder,
  communications,
  handleOrderClick,
  getOrder,
  history,
  hasActiveFilters,
  openFilter,
  handleViewCommunicationClick,
  isAllCommercial,
  isLoading,
}: Props) => {
  const onOrderClickCurried = curryN(2, handleOrderClick);
  return (
    <Grid fluid>
      <Row className="section-header">
        <Col xs={6}>
          <h1>
            <FormattedMessage {...messages.header} />
          </h1>
          <p>
            <FormattedMessage {...messages.subHeader} />
          </p>
        </Col>
        <Col xs={6} className="text-right">
          <button
            data-test="filter-btn"
            className={`button-filter ${
              hasActiveFilters ? 'filters-are-active' : ''
            }`}
            onClick={() => openFilter(true)}
          >
            <i className="et-filter" />
          </button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} id="table-row">
          <Table striped className="table-prospects">
            <thead className="table-header hidden-xs">
              <tr>
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.method)}
                  sortable={true}
                  icon={columnOrder.method}
                  order={getOrder('method')}
                  onOrderClick={onOrderClickCurried('method')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.type)}
                  sortable={true}
                  icon={columnOrder.type}
                  order={getOrder('type')}
                  onOrderClick={onOrderClickCurried('type')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.createdDate)}
                  sortable={true}
                  icon={columnOrder.createdDate}
                  order={getOrder('createdDate')}
                  onOrderClick={onOrderClickCurried('createdDate')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.createdTime)}
                  sortable={true}
                  icon={columnOrder.createdTime}
                  order={getOrder('createdTime')}
                  onOrderClick={onOrderClickCurried('createdTime')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.title)}
                  sortable
                  icon={columnOrder.title}
                  order={getOrder('title')}
                  onOrderClick={onOrderClickCurried('title')}
                />
                <ColumnHeader columnLabel={intl.formatMessage(messages.view)} />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.status)}
                />
              </tr>
            </thead>
            {isLoading ? (
              <td colSpan="7">
                <Spinner />
              </td>
            ) : (
              <CommunicationDetails
                intl={intl}
                communications={communications}
                history={history}
                handleViewCommunicationClick={handleViewCommunicationClick}
                isAllCommercial={isAllCommercial}
              />
            )}
          </Table>
        </Col>
      </Row>
    </Grid>
  );
};

export default CommunicationsTable;
