import { defaultTo, comparator, lt } from 'ramda';

import type { Prospect } from '../../containers/ProspectProfile/types';

export function getAffordableRelationshipDefault(
  prospect: Object,
  hohRelationshipId: string,
): string {
  const { id, primaryApplicantId } = prospect;
  return id === primaryApplicantId ? hohRelationshipId ?? 'default' : 'default';
}

export function generateInitialAdult(
  prospect?: Prospect,
  headOfHouseholdRelationship?: Object = {},
) {
  const { value: hohRelationshipId } = headOfHouseholdRelationship;
  const defaultToDefault = defaultTo('default');
  const byCreationDate = comparator((a, b) => lt(a.createdAt, b.createdAt));
  if (prospect) {
    const prospectApplicant = {
      id: prospect.id,
      firstName: prospect.firstName,
      middleName: prospect.middleName,
      lastName: prospect.lastName,
      preferredName: prospect.preferredName,
      phone: prospect.phoneNumber,
      email: prospect.emailAddress,
      type: 'default',
      relationship: 'default',
      affordableRelationship: getAffordableRelationshipDefault(
        prospect,
        hohRelationshipId,
      ),
      suffixId: defaultToDefault(prospect.suffixId),
      isProspect: true,
    };

    const adults = prospect.additionalOccupants
      ? [
          prospectApplicant,
          ...prospect.additionalOccupants
            .filter((occupant) => occupant.type !== 'Minor')
            .sort(byCreationDate)
            .map((occupant) => ({
              id: occupant.id,
              firstName: occupant.firstName,
              middleName: occupant.middleName,
              lastName: occupant.lastName,
              preferredName: occupant.preferredName,
              phone: occupant.phoneNumber,
              email: occupant.emailAddress,
              type: 'default',
              relationship: defaultToDefault(occupant.relationshipId),
              affordableRelationship: defaultToDefault(
                occupant.affordableRelationshipId,
              ),
              suffixId: defaultToDefault(occupant.suffixId),
              isProspect: false,
            })),
        ]
      : [];
    return adults;
  }
  return [];
}

export function generateInitialMinor(prospect?: Prospect) {
  const defaultToDefault = defaultTo('default');
  const byCreationDate = comparator((a, b) => lt(a.createdAt, b.createdAt));
  if (prospect) {
    return prospect.additionalOccupants
      ? prospect.additionalOccupants
          .filter((ao) => ao.type === 'Minor')
          .sort(byCreationDate)
          .map((occupant) => ({
            id: occupant.id,
            firstName: occupant.firstName,
            middleName: occupant.middleName,
            lastName: occupant.lastName,
            preferredName: occupant.preferredName,
            relationship: defaultToDefault(occupant.relationshipId),
            affordableRelationship: defaultToDefault(
              occupant.affordableRelationshipId,
            ),
            suffixId: defaultToDefault(occupant.suffixId),
            age: (occupant.age || '').toString(),
          }))
      : [];
  }
  return [];
}

export function generateInitialPet(prospect?: Prospect) {
  const defaultToBlank = defaultTo('');
  const defaultToDefault = defaultTo('default');
  if (prospect) {
    return prospect.petOccupants
      ? prospect.petOccupants.map((occupant) => ({
          id: occupant.id,
          name: occupant.name,
          petTypeId: defaultToDefault(occupant.petTypeId),
          breedId: defaultToDefault(occupant.petBreedId),
          weight: defaultToBlank(occupant.weight),
          petTag: defaultToBlank(occupant.petTag),
          isServiceAnimal: occupant.serviceAnimal,
        }))
      : [];
  }
  return [];
}

export default {
  getAffordableRelationshipDefault,
  generateInitialAdult,
  generateInitialPet,
  generateInitialMinor,
};
