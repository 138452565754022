export const SAVE_QUALIFICATION_CHECKLIST =
  'app/QualificationForms/SAVE_QUALIFICATION_CHECKLIST';

export const GET_ONE_QUALIFICATION_CHECKLIST =
  'app/QualificationForms/GET_ONE_QUALIFICATION_CHECKLIST';

export const GET_ONE_QUALIFICATION_CHECKLIST_SUCCESS =
  'app/QualificationForms/GET_ONE_QUALIFICATION_CHECKLIST_SUCCESS';

export const SAVE_QUALIFICATION_CHECKLIST_SUCCESS =
  'app/QualificationForms/SAVE_QUALIFICATION_CHECKLIST_SUCCESS';

export const SAVE_QUALIFICATION_CHECKLIST_ERROR =
  'app/QualificationForms/SAVE_QUALIFICATION_CHECKLIST_ERROR';

export const UPDATE_QUALIFICATION_CHECKLIST =
  'app/QualificationForms/UPDATE_QUALIFICATION_CHECKLIST';

export const PRINT_INCOME_ASSET_FORM =
  'app/QualificationForms/PRINT_INCOME_ASSET_FORM';

export const COMPLIANCE_APPROVAL_IDS_TO_DISABLE_FORMS = [
  '05b3d98d-1edd-46f5-878b-dcffcd95d035',
  '34f3eaf2-0cbc-4f9b-887e-52a97e8bde78',
  '6acf60c0-c90a-4de6-8766-0d7e6b4a5054',
];
