import React, { Fragment } from 'react';
import { pathOr } from 'ramda';
import { FormattedMessage } from 'react-intl';

import FormattedDateOrDashes from '../../components/FormattedDateOrDashes';
import {
  PORTAL_ACTION_EXECUTED,
  PORTAL_ACTION_REMOVED,
  PORTAL_ACTION_SENT,
} from '../../utils/lease-helpers';

import messages from './messages';

type Props = {
  executeReady: boolean,
  latestLog: any,
};

export const FooterMessages = ({ executeReady = false, latestLog }: Props) => {
  const portalAction = pathOr(null, ['action'], latestLog);
  const portalActionDate = pathOr(null, ['createdAt'], latestLog);
  const portalActionUser = pathOr(null, ['createdBy'], latestLog);
  const leaseExecuted = portalAction === PORTAL_ACTION_EXECUTED;
  const leaseSent = portalAction === PORTAL_ACTION_SENT;
  const leaseRemoved = portalAction === PORTAL_ACTION_REMOVED;

  return (
    <div className="row text-center">
      {leaseExecuted && (
        <Fragment>
          <strong>
            <FormattedMessage {...messages.leaseSigningStatusExecuted} />
          </strong>
          <div>
            Executed on <FormattedDateOrDashes value={portalActionDate} /> by
            {portalActionUser &&
              ` ${portalActionUser.firstName} ${portalActionUser.lastName}`}
          </div>
        </Fragment>
      )}
      {leaseSent && executeReady && (
        <Fragment>
          <strong>
            <FormattedMessage {...messages.leaseSigningStatusExecuteReady} />
          </strong>
          <div>
            Sent on <FormattedDateOrDashes value={portalActionDate} /> by
            {portalActionUser &&
              ` ${portalActionUser.firstName} ${portalActionUser.lastName}`}
          </div>
        </Fragment>
      )}
      {leaseSent && !executeReady && (
        <Fragment>
          <strong>
            <FormattedMessage {...messages.leaseSigningStatusSent} />
          </strong>
          <div>
            Sent on <FormattedDateOrDashes value={portalActionDate} /> by
            {portalActionUser &&
              ` ${portalActionUser.firstName} ${portalActionUser.lastName}`}
          </div>
        </Fragment>
      )}
      {leaseRemoved && (
        <Fragment>
          <strong>
            <FormattedMessage {...messages.needsResending} />
          </strong>
          <div>
            Removed on <FormattedDateOrDashes value={portalActionDate} /> by
            {portalActionUser &&
              ` ${portalActionUser.firstName} ${portalActionUser.lastName}`}
          </div>
        </Fragment>
      )}
      {!portalAction && (
        <Fragment>
          <strong>
            <FormattedMessage {...messages.leaseSigningStatusDefault} />
          </strong>
          <div>
            <FormattedMessage {...messages.leaseSigningStatusDefaultSubtitle} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default FooterMessages;
