import React from 'react';
import { pathOr, isNil, omit } from 'ramda';
import moment from 'moment';
import { Link } from 'react-router-dom';
import type { FilterValue, OrderValue } from '../App/types';
import { sortAndFilterRows } from '../../utils';
import { phoneFormatter } from '../../utils/redux-form-helper';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import { valueListToQueryStr } from '../../utils/query-helpers';
import { STATUS_NAMES_TO_VALUES } from './constants';
import * as api from '../../utils/api';

export type BuildDelinquencyTableArgs = {
  intl: Object,
  delinquentRows: Array<any>,
  allHeaders: Array<any>,
  searchText: string,
  currentSorting: OrderValue,
  appliedFilter: FilterValue,
};

export type DelinquencyTableData = {
  headers: Array<any>,
  rows: Array<any>,
  getFooter: Function,
};

type OptionalColumnsShouldShow = {
  showCAMOnlyBalances: boolean,
  showSubsidyOnlyBalances: boolean,
};

/**
 * CAM and Subsidy only balance columns may be hidden if no row has values for them:
 * 1. "CAM Only Balance" should be shown only if any row has CAMOnlyBalance > 0
 * 2. "Subsidy Only Balance" shoud be shown if:
 *   2.1. any row has subsidyOnlyBalance > 0
 *   2.2. no row has subsidyOnlyBalance > 0 and no row has CAMOnlyBalance > 0
 * @param  {Array<any>} rows
 * @returns boolean
 */
const getShowOptionalColumns = (
  rows: Array<any>,
): OptionalColumnsShouldShow => {
  const someRowsHaveCAM = rows.some((row) =>
    Object.prototype.hasOwnProperty.call(row, 'CAMOnlyBalance'),
  );
  const someRowsHaveSubsidy = rows.some((row) =>
    Object.prototype.hasOwnProperty.call(row, 'subsidyOnlyBalance'),
  );
  const showCAMOnlyBalances = someRowsHaveCAM;
  const showSubsidyOnlyBalances = someRowsHaveSubsidy || !someRowsHaveCAM;

  return { showCAMOnlyBalances, showSubsidyOnlyBalances };
};

const formatDateField = (value: string, formatDate: Function): ?string => {
  if (!value) {
    return null;
  } else {
    return formatDate(moment(value), {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }
};

const parseDelinquencyRow = (
  row: Object,
  formatDate: Function,
  maxOrder: number,
) => {
  const { firstName, lastName, moveInDate, moveOutDate } = row;
  const name =
    firstName && lastName
      ? `${lastName}, ${firstName}`
      : `${lastName}${firstName}`;
  const unitNum = pathOr('', ['unitNumber'], row);
  return {
    ...row,
    name,
    moveInDate: formatDateField(moveInDate, formatDate),
    moveOutDate: formatDateField(moveOutDate, formatDate),
    defaultOrder: pathOr(
      unitNum ? maxOrder + 1 : maxOrder + 2,
      ['unitOrder'],
      row,
    ),
  };
};

const parseDelinquencies = (
  {
    intl: { formatNumber, formatDate },
    delinquentRows,
    searchText,
    currentSorting: { fieldName = '', order = 'ASC' },
    appliedFilter: { balances, statuses, eviction },
  }: BuildDelinquencyTableArgs,
  { showCAMOnlyBalances, showSubsidyOnlyBalances }: OptionalColumnsShouldShow,
): Array<any> => {
  if (delinquentRows.length > 0) {
    const maxOrder = delinquentRows.reduce((acc, row) => {
      const order = pathOr(0, ['unitOrder'], row);
      return order > acc ? order : acc;
    }, 0);
    const parsedRows = delinquentRows.map((row) =>
      parseDelinquencyRow(row, formatDate, maxOrder),
    );

    const rowFilters = (row: Object): boolean => {
      let balanceFilterMatch = false;
      if (!Object.values(balances).includes(true)) {
        // No "balance" filter selected => don't filter on balance
        balanceFilterMatch = true;
      } else {
        const possibleBalanceFilters = Object.keys(balances);
        const balanceFilterHits = possibleBalanceFilters.map((columnName) =>
          balances[columnName] ? !!row[columnName] : false,
        );
        balanceFilterMatch = balanceFilterHits.includes(true);
      }

      let statusFilterMatch = false;
      if (!Object.values(statuses).includes(true)) {
        // No "status" filter selected => don't filter on status
        statusFilterMatch = true;
      } else {
        const possibleStatusFilters = Object.keys(statuses)
          .map((statusName) =>
            statuses[statusName] === true
              ? STATUS_NAMES_TO_VALUES[statusName]
              : null,
          )
          .filter((s) => s);
        const statusFilterHits = possibleStatusFilters.map((value) => {
          const rowStatus = pathOr(null, ['status'], row);
          return rowStatus === value;
        });

        statusFilterMatch = statusFilterHits.includes(true);
      }

      const evictionFilterMatch =
        isNil(eviction.underEviction) || !eviction.underEviction
          ? true
          : pathOr(false, ['underEviction'], row) === eviction.underEviction;

      return statusFilterMatch && balanceFilterMatch && evictionFilterMatch;
    };

    const filteredRows = parsedRows.filter(rowFilters);

    const field = fieldName ? fieldName : 'defaultOrder';
    const ordering = order ? order : 'ASC';

    const sortedRows = sortAndFilterRows(
      filteredRows,
      field,
      ordering,
      searchText,
    );

    return sortedRows.map((row) => {
      const formatCurrencyField = (value: string): ?string => {
        if (!value) {
          return null;
        } else {
          return formatNumber(value, {
            style: 'currency',
            currency: 'USD',
          });
        }
      };

      const { applicationId, residentId, underEviction, name, status } = row;

      let profileLink;
      if (residentId && status === 'Prior Resident') {
        profileLink = getUrlWithSelectedPropertyId(
          `/prior-resident/${residentId}`,
        );
      } else if (residentId) {
        profileLink = getUrlWithSelectedPropertyId(`/resident/${residentId}`);
      } else {
        profileLink = getUrlWithSelectedPropertyId(
          `/application/${applicationId}`,
        );
      }

      const evictionField = underEviction ? (
        <i className="icon et-alert-urgent text-red" />
      ) : (
        '---'
      );

      const customerRow = {
        id: row.customerId,
        unitNumber: row.unitNumber,
        status: row.status,
        floorPlan: row.floorPlan,
        name: <Link to={profileLink}>{`${name}`}</Link>,
        phoneNumber: phoneFormatter(row.phoneNumber),
        moveInDate: row.moveInDate,
        moveOutDate: row.moveOutDate,
        collectionsNotes: row.collectionsNotes,
        underEviction: evictionField,
        prepaidBalance: formatCurrencyField(row.prepaidBalance),
        delinquentBalance: formatCurrencyField(row.delinquentBalance),
        writtenOff: formatCurrencyField(row.writtenOff),
        subsidyOnlyBalance: formatCurrencyField(row.subsidyOnlyBalance),
        CAMOnlyBalance: formatCurrencyField(row.CAMOnlyBalance),
        residentOnlyBalance: formatCurrencyField(row.residentOnlyBalance),
        rentOnlyBalance: formatCurrencyField(row.rentOnlyBalance),
        current: formatCurrencyField(row.current),
        thirtyOneToSixty: formatCurrencyField(row.thirtyOneToSixty),
        sixtyOneToNinety: formatCurrencyField(row.sixtyOneToNinety),
        ninetyOnePlus: formatCurrencyField(row.ninetyOnePlus),
        depositsHeld: formatCurrencyField(row.depositsHeld),
        depositsOwed: formatCurrencyField(row.depositsOwed),
        lateFees: row.lateFees,
        nsfFees: row.nsfFees,
      };

      if (!showSubsidyOnlyBalances) {
        delete customerRow.subsidyOnlyBalance;
      }
      if (!showCAMOnlyBalances) {
        delete customerRow.CAMOnlyBalance;
      }

      return customerRow;
    });
  }
  return [];
};

const buildRows = (rows: Array<Object>): any => {
  if (!rows) return [];
  return rows.map((row, i) => ({
    id: row.id ? row.id : i,
    columns: Object.values(omit(['id'], row)),
  }));
};

const sumColumn = (intl: Object, rows: Array<Object>, fieldName: string) => {
  const { formatNumber } = intl;
  const parseCurrency = (value: string): number => {
    if (value === null || value === '---') {
      return 0;
    } else {
      const num = Number(
        value
          .split('')
          .filter((c) => c !== '$' && c !== ',')
          .join(''),
      );
      return num;
    }
  };
  const summedValue = rows.reduce((sum, row) => {
    return (sum += parseCurrency(row[fieldName]));
  }, 0);
  return formatNumber(summedValue, {
    style: 'currency',
    currency: 'USD',
  });
};

const sumColumns = (
  intl: Object,
  rows: Array<Object>,
  { showCAMOnlyBalances, showSubsidyOnlyBalances }: OptionalColumnsShouldShow,
): any => {
  let fieldNames = [
    'prepaidBalance',
    'delinquentBalance',
    'writtenOff',
    'subsidyOnlyBalance',
    'CAMOnlyBalance',
    'residentOnlyBalance',
    'rentOnlyBalance',
    'current',
    'thirtyOneToSixty',
    'sixtyOneToNinety',
    'ninetyOnePlus',
    'depositsHeld',
    'depositsOwed',
  ];
  if (!showCAMOnlyBalances) {
    fieldNames = fieldNames.filter((name) => name !== 'CAMOnlyBalance');
  }
  if (!showSubsidyOnlyBalances) {
    fieldNames = fieldNames.filter((name) => name !== 'subsidyOnlyBalance');
  }
  return fieldNames.reduce((sums, field) => {
    sums[field] = sumColumn(intl, rows, field);
    return sums;
  }, {});
};

const GetFooter = (
  sums: Object,
  { showCAMOnlyBalances, showSubsidyOnlyBalances }: OptionalColumnsShouldShow,
) => {
  return () => {
    return (
      <tfoot className="table-footer">
        <tr>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td>{sums.prepaidBalance}</td>
          <td>{sums.delinquentBalance}</td>
          <td>{sums.writtenOff}</td>
          {showSubsidyOnlyBalances && <td>{sums.subsidyOnlyBalance}</td>}
          {showCAMOnlyBalances && <td>{sums.CAMOnlyBalance}</td>}
          <td>{sums.residentOnlyBalance}</td>
          <td>{sums.rentOnlyBalance}</td>
          <td>{sums.current}</td>
          <td>{sums.thirtyOneToSixty}</td>
          <td>{sums.sixtyOneToNinety}</td>
          <td>{sums.ninetyOnePlus}</td>
          <td>{sums.depositsHeld}</td>
          <td>{sums.depositsOwed}</td>
          <td />
          <td />
          <td />
        </tr>
      </tfoot>
    );
  };
};

const buildHeaders = (
  allHeaders: Array<any>,
  { showCAMOnlyBalances, showSubsidyOnlyBalances }: OptionalColumnsShouldShow,
): Array<any> => {
  if (showCAMOnlyBalances && showSubsidyOnlyBalances) {
    return allHeaders;
  }

  if (!allHeaders || allHeaders.length === 0 || !allHeaders[0].headers) {
    // Shouldn't happen but just to be safe...
    return allHeaders;
  }

  // Painful, but the headers have format:
  // [
  //   { headers: [ { id, title, rowSpan }] },
  //   { headers: [ { id, title, rowSpan }] }
  // ]
  const firstHeaderGroupFiltered = allHeaders[0].headers.filter((header) => {
    if (!showCAMOnlyBalances && header.id === 'CAMOnlyBalance') {
      return false;
    }
    if (!showSubsidyOnlyBalances && header.id === 'subsidyOnlyBalance') {
      return false;
    }
    return true;
  });

  return [
    {
      headers: firstHeaderGroupFiltered,
    },
    allHeaders[1],
  ];
};

export const buildDelinquencyTable = (
  data: BuildDelinquencyTableArgs,
): DelinquencyTableData => {
  const { intl, delinquentRows, allHeaders } = data;
  const showOptionalColumns = getShowOptionalColumns(delinquentRows);

  const parsedDelinquencies = parseDelinquencies(data, showOptionalColumns);
  const rows = buildRows(parsedDelinquencies);
  const summedColumns = sumColumns(
    intl,
    parsedDelinquencies,
    showOptionalColumns,
  );
  const headers = buildHeaders(allHeaders, showOptionalColumns);

  return {
    headers,
    rows,
    getFooter: GetFooter(summedColumns, showOptionalColumns),
  };
};

const filterValuesString = (filterValues) => {
  const queryValues = [];
  Object.entries(filterValues).forEach(([fieldName, values]) => {
    if (values) {
      const item = { fieldName, list: [] };
      Object.entries(values).forEach(([value, boolean]) => {
        if (boolean) {
          item.list.push(value);
        }
      });

      queryValues.push(item);
    }
  });

  return queryValues.map((item) => {
    return valueListToQueryStr(item.list, item.fieldName);
  });
};

export const handleDownload = async ({
  fileType = 'csv',
  organizationId,
  propertyId,
  propertyName,
  reportId,
  query: {
    sorting: { fieldName = '', order = '' },
    searchText = '',
  },
  filterValues,
}: {
  fileType: 'csv' | 'pdf',
  organizationId: string,
  propertyId: string,
  propertyName: string,
  reportId: string,
  query: {
    sorting: { fieldName: string, order: string },
    searchText: string,
  },
  filterValues: Object,
}) => {
  let queryArray = [],
    queryString = '';

  if (fieldName?.length) queryArray.push(`&fieldName=${fieldName}`);

  if (order?.length) queryArray.push(`&order=${order}`);

  if (searchText?.length) queryArray.push(`&searchText=${searchText}`);

  queryArray.push(...filterValuesString(filterValues));

  if (queryArray.length) {
    queryArray.unshift('?');
    queryString = queryArray.join('');
  }

  return api.getDownload(
    `/${organizationId}/${propertyId}/${fileType}-reports/${reportId}${queryString}`,
    `${propertyName}_${moment().format('YYYYMMDD')}.${fileType}`,
  );
};

export const getDefaultFilters = (redirectedFromHomeKpi: boolean) => ({
  balances: {},
  statuses: {
    occupiedNoNotice: true,
    occupiedOnNotice: true,
    applicant: true,
    priorResident: !redirectedFromHomeKpi,
  },
  eviction: {
    underEviction: redirectedFromHomeKpi,
  },
});
