import { defineMessages } from 'react-intl';

export default defineMessages({
  providerName: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.ProviderName',
    defaultMessage: 'Provider Name',
  },
  liabilityCoverage: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.LiabilityCoverage',
    defaultMessage: 'Liability Coverage',
  },
  policyID: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.PolicyID',
    defaultMessage: 'Policy ID',
  },
  policyStartDate: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.PolicyStartDate',
    defaultMessage: 'Policy Start Date',
  },
  policyEndDate: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.PolicyEndDate',
    defaultMessage: 'Policy End Date',
  },
  propertyListedAsInterestedParty: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.PropertyListedAsInterestedParty',
    defaultMessage: 'Property listed as interested party?',
  },
  yes: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.No',
    defaultMessage: 'No',
  },
  proofOfCoverage: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.ProofOfCoverage',
    defaultMessage: 'Proof of Coverage',
  },
  notes: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.Notes',
    defaultMessage: 'Notes',
  },
  endDateBeforeStartDateError: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.EndDateBeforeStartDateError',
    defaultMessage: 'Policy End Date cannot be before Policy Start Date',
  },
  browse: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.Browse',
    defaultMessage: 'Browse',
  },
  added: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.Added',
    defaultMessage: 'Added',
  },
  proofOfCoverageMessage: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.ProofOfCoverageMessage',
    defaultMessage:
      'Editing/Renewing this policy will remove the existing proof of coverage.',
  },
  error: {
    id: 'App.Household.InsuranceSection.InsuranceModal.InsuranceForm.Error',
    defaultMessage: 'Error',
  },
});
