import { defineMessages } from 'react-intl';

const messages = defineMessages({
  requiredFields: {
    id: 'App.ResetPassword.ResetPasswordForm.RequiredFields',
    defaultMessage: 'Required'
  },
  matchPasswords: {
    id: 'App.ResetPassword.ResetPasswordForm.MatchPasswords',
    defaultMessage: 'Password and password confirmation must match'
  },
  missingMin: {
    id: 'App.ResetPassword.ResetPasswordForm.MissingMin',
    defaultMessage: 'Password must fulfill all minimum requirements'
  },
  length: {
    id: 'App.ResetPassword.ResetPasswordForm.Length',
    defaultMessage: 'Password must be less than 50 characters'
  },
});

export default messages;
