import { isNil } from 'ramda';

import { validateRequired } from '../../../utils/validations';

import formMessages from '../../../containers/ShortLeaseApplication/messages';

import type { ContactInformation } from './types';

const warn = (values: ContactInformation, { intl }: Object) => {
  const warnings = {};

  if (isNil(values)) {
    warnings.phoneNumber = intl.formatMessage(
      formMessages.requiredForCompletion
    );
    return warnings;
  }
  if (!validateRequired('emailAddress', values)) {
    warnings.emailAddress = intl.formatMessage(
      formMessages.requiredForCompletion
    );
  }
  if (!validateRequired('phoneNumber', values)) {
    warnings.phoneNumber = intl.formatMessage(
      formMessages.requiredForCompletion
    );
  }

  return warnings;
};

export default warn;
