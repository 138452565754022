import { isDirty } from 'redux-form';
import {
  pathOr,
  path,
  isNil,
  values,
  flatten,
  prop,
  uniqBy,
  keys,
  propOr,
} from 'ramda';
import type {
  GlobalState,
  PropertySubjournalType,
  PropertyTransactionCode,
} from '../App/types';
import type {
  OccupiedUnit,
  PropertyTransactionCode as TransactionCode,
  Subjournal,
} from '../../components/PaymentModal/types';

export const getPaymentBatchStatus = (batch: Object = {}): ?string => {
  const batchId = pathOr(null, ['header', 'id'], batch);
  if (!batch || !batchId) {
    return null;
  }
  const isPosting = pathOr(false, ['header', 'isPosting'], batch);
  if (isPosting) {
    return 'Posting';
  }
  const isPosted = pathOr(false, ['header', 'isPosted'], batch);
  if (isPosted) {
    return 'Posted';
  }
  return 'Saved';
};

export const getOccupiedUnits = (
  units: Object = [],
  selectedHouseholdStatus: string,
): OccupiedUnit[] => {
  if (selectedHouseholdStatus !== 'Applicant') {
    return getUnitsWithHouseholdInformation(selectedHouseholdStatus, units);
  } else {
    return getApplicantsWithHouseholdInformation(units);
  }
};

export const getAllUniqueUnits = (units: Object = {}): OccupiedUnit[] => {
  const allUnits = flatten(
    keys(units).map((key) => getUniqueSelectableUnits(key, units)),
  );
  // $$FlowFixMe
  return uniqBy(prop('id'), allUnits);
};

export const formatEditPayment = (
  selectedPaymentId: Object,
  payments: Array<Object>,
  transactionCodes: Array<Object>,
  allUniqueUnits: Object,
): Object => {
  const selectedPayment = payments.find((p) => p.id === selectedPaymentId);
  if (!selectedPayment) return undefined;
  const code = transactionCodes.find(
    (t) => t.id === selectedPayment.propertyTransactionCodeId,
  );
  const unit = allUniqueUnits.find((u) => u.id === selectedPayment.unitId);
  if (!code) return undefined;
  return {
    id: selectedPaymentId,
    unitId: propOr(null, 'id', unit),
    householdStatus: selectedPayment.householdStatus,
    householdId: selectedPayment.householdId,
    subjournal: code.transactionCode.subjournalId,
    receivedFrom: selectedPayment.receivedFromHouseholdMember,
    transactionCode: code.id,
    documentNumber: selectedPayment.documentNumber,
    note: selectedPayment.transactionNote,
    amount: String(selectedPayment.transactionAmount),
  };
};

// USED
export const formatSubjournals = (
  subjournals: Array<Object> = [],
): Subjournal[] =>
  subjournals.map((subjournal: PropertySubjournalType) => ({
    id: subjournal.masterSubjournalId,
    name: subjournal.masterSubjournalType.description,
  }));

// USED
export const formatPropertyTransactionCodes = (
  transactionCodes: GlobalState,
): TransactionCode[] =>
  transactionCodes
    .filter(
      (code: PropertyTransactionCode) =>
        code.userSelectable &&
        code.transactionCode.transactionType.name === 'Payment' &&
        code.transactionCode.isPayment,
    )
    .map((code: PropertyTransactionCode) => ({
      id: code.id,
      code: code.transactionCode.code,
      description: code.transactionCode.description,
      subjournalId: code.transactionCode.subjournalId,
    }));

// USED
export const getFiscalPeriod = (periodObj: GlobalState): ?string => {
  return pathOr(null, ['period'], periodObj);
};

export const isBatchCreated = (state: GlobalState): boolean =>
  state.paymentBatch.batch.header !== undefined;

export const dataIsReady = (state: GlobalState): boolean => {
  const appState = state.app;
  return (
    appState.occupiedUnitsLoaded &&
    appState.transactionCodesLoaded &&
    appState.subjournalsLoaded &&
    appState.fiscalPeriodLoaded &&
    !state.paymentBatch.fetching
  );
};

export const unsavedChanges = (state: GlobalState): boolean => {
  const { paymentsDirty } = state.paymentBatch;
  return paymentsDirty || isDirty('batchHeaderForm')(state);
};

export const hasInvalidPayments = (state: GlobalState): boolean => {
  return (
    state.paymentBatch.batch.transactions.find((tx) => !tx.valid) !== undefined
  );
};

export const batchTotalMatchPaymentsTotal = (state: GlobalState): boolean => {
  const transactionsTotal = pathOr(
    [],
    ['paymentBatch', 'batch', 'transactions'],
    state,
  ).reduce((acc, t) => {
    return acc + +pathOr(0, ['transactionAmount'], t);
  }, 0);
  const batchTotal = +pathOr(
    0,
    ['form', 'batchHeaderForm', 'values', 'batchTotal'],
    state,
  );
  return transactionsTotal > 0 && transactionsTotal === batchTotal;
};

export const disableSave = (state: GlobalState): boolean => {
  const { paymentBatch } = state;
  const noPayments = paymentBatch.batch.transactions.length === 0;
  const isNewBatch = isNil(path(['batch', 'header', 'id'], paymentBatch));
  return (
    !unsavedChanges(state) ||
    (isNewBatch && noPayments) ||
    paymentBatch.saving ||
    paymentBatch.posting ||
    paymentBatch.fetching
  );
};

export const disablePost = (state: GlobalState): boolean => {
  const { paymentBatch } = state;
  return (
    unsavedChanges(state) ||
    hasInvalidPayments(state) ||
    // !batchTotalMatchPaymentsTotal(state) ||
    paymentBatch.saving ||
    paymentBatch.posting ||
    paymentBatch.fetching
  );
};

export const disableCancel = (state: GlobalState): boolean => {
  const { paymentBatch } = state;
  return paymentBatch.saving || paymentBatch.posting;
};

// Selectable units for Residents and Prior Residents
function getUniqueSelectableUnits(
  householdstatusKey: string,
  units: Object,
): Object[] {
  // $$FlowFixMe
  const householdsToDisplay = pathOr([], [householdstatusKey], units);
  return flatten(
    householdsToDisplay.map((unit) => {
      const individualLeases = values({
        ...pathOr([], ['leases'], unit),
      });
      return individualLeases.map((lease) => ({
        id: pathOr(null, ['id'], unit),
        number: pathOr(null, ['number'], unit),
      }));
    }),
  );
}

function getUnitsWithHouseholdInformation(
  householdstatusKey: string,
  units: Object = {},
): OccupiedUnit[] {
  const householdsToDisplay = pathOr([], [householdstatusKey], units);
  let balance = (field: string) => ['household', field];
  const individualLeases = flatten(
    householdsToDisplay.map((unit) => {
      const individualLeases = values({
        ...pathOr([], ['leases'], unit),
      });
      return individualLeases.map((lease) => ({
        ...lease,
        unit: { id: unit.id, number: unit.number },
      }));
    }),
  );

  return individualLeases.map((lease: any) => {
    return {
      id: pathOr('N/A', ['unit', 'id'], lease),
      number: pathOr('N/A', ['unit', 'number'], lease),
      frMembers: values(pathOr({}, ['resident'], lease))
        .filter((resident) => pathOr('', ['status'], resident) !== 'Prior')
        .map((resident) => {
          const customer = pathOr(
            { firstName: '', lastName: '' },
            ['rc', 'customer'],
            resident,
          );
          return `${customer.firstName} ${
            customer.middleName ? `${customer.middleName} ` : ''
          }${customer.lastName}${
            customer.suffix ? ` ${customer.suffix.name}` : ''
          }`;
        }),
      balances: {
        Resident: pathOr(0, balance('balance'), lease),
        Subsidy: pathOr(0, balance('subsidyBalance'), lease),
      },
      householdId: lease.householdId,
    };
  });
}

function getApplicantsWithHouseholdInformation(units: Object): OccupiedUnit[] {
  let balance = (field: string) => ['household', field];
  // $FlowFixMe
  const householdsToDisplay = pathOr([], ['Applicant'], units);
  const ret = householdsToDisplay.map((applicant: any) => {
    return {
      id: pathOr(null, ['aus', '0', 'unitId'], applicant),
      number: pathOr('N/A', ['aus', '0', 'unit', 'number'], applicant),
      frMembers: pathOr({}, ['applicants'], applicant).map((applicant) => {
        const customer = pathOr(
          { firstName: '', lastName: '' },
          ['applicantCustomer', 'customer'],
          applicant,
        );
        return `${customer.firstName} ${
          customer.middleName ? `${customer.middleName} ` : ''
        }${customer.lastName}${
          customer.suffix ? ` ${customer.suffix.name}` : ''
        }`;
      }),
      balances: {
        Resident: pathOr(0, balance('balance'), applicant),
        Subsidy: pathOr(0, balance('subsidyBalance'), applicant),
      },
      householdId: applicant.householdId,
    };
  });
  return ret;
}
