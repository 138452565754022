import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.CreateCommunication.Title',
    defaultMessage: 'Fortress - Create Communication',
  },
  viewTitle: {
    id: 'App.CreateCommunication.viewTitle',
    defaultMessage: 'Fortress - View Communication',
  },
  prospectDisclaimer: {
    id: 'App.CreateCommunication.prospectDisclaimer',
    defaultMessage:
      'To text Prospects, you may only select one prospect at a time.',
  },
  noPhoneNumber: {
    id: 'App.CreateCommunication.NoPhoneNumber',
    defaultMessage:
      "The property doesn't have a phone number assigned for texting. Please contact your administrator.",
  },
});

export default messages;
