import {
  Spinner,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import DocumentTitle from 'react-document-title';
import useFormatMessage from '../../hooks/useFormatMessage';
import AmenityForm from './AmenityForm';
import messages from './messages';

function Layout({ isEdit, isLoading, amenityFormProps }) {
  const formatMessage = useFormatMessage();

  return (
    <DocumentTitle
      title={formatMessage(
        isEdit ? messages.editAmenityTabTitle : messages.addAmenityTabTitle,
      )}
    >
      <Stack padding={8}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <Typography variant="h2">
              {formatMessage(
                isEdit ? messages.editAmenityTitle : messages.addAmenityTitle,
              )}
            </Typography>
            <div style={{ marginTop: 40 }} />
            <AmenityForm {...amenityFormProps} />
          </>
        )}
      </Stack>
    </DocumentTitle>
  );
}

export default Layout;
