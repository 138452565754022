import {
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_FAILURE,
  GET_TRANSACTION_TYPES,
  GET_TRANSACTION_TYPES_FAILURE,
  GET_TRANSACTION_TYPES_SUCCESS,
  GET_PROPERTY_TRANSACTION_CODES,
  GET_PROPERTY_TRANSACTION_CODES_FAILURE,
  GET_PROPERTY_TRANSACTION_CODES_SUCCESS,
  GET_PROPERTY_SUBJOURNAL_TYPES,
  GET_PROPERTY_SUBJOURNAL_TYPES_FAILURE,
  GET_PROPERTY_SUBJOURNAL_TYPES_SUCCESS,
} from './constants';
import type { Action } from '../../containers/App/types';

export const initialState = {};

export function transactionTypesReducer(
  state: Object = initialState,
  { type, payload, error }: Action<Error | Array<Object>>,
) {
  switch (type) {
    case GET_TRANSACTION_TYPES:
      return initialState;
    case GET_TRANSACTION_TYPES_SUCCESS:
      return { types: payload };
    case GET_TRANSACTION_TYPES_FAILURE:
      return { ...state, payload, error };
    default:
      return state;
  }
}

export function propertyTransactionCodesReducer(
  state: Object = initialState,
  { type, payload, error }: Action<Error | Array<Object>>,
) {
  switch (type) {
    case GET_PROPERTY_TRANSACTION_CODES:
      return initialState;
    case GET_PROPERTY_TRANSACTION_CODES_SUCCESS:
      return { codes: payload };
    case GET_PROPERTY_TRANSACTION_CODES_FAILURE:
      return { ...state, payload, error };
    default:
      return state;
  }
}

export function propertySubjournalTypesReducer(
  state: Object = initialState,
  { type, payload, error }: Action<Error | Array<Object>>,
) {
  switch (type) {
    case GET_PROPERTY_SUBJOURNAL_TYPES:
      return initialState;
    case GET_PROPERTY_SUBJOURNAL_TYPES_SUCCESS:
      return { types: payload };
    case GET_PROPERTY_SUBJOURNAL_TYPES_FAILURE:
      return { ...state, payload, error };
    default:
      return state;
  }
}

export const initialCreateTransactionState = {
  isSubmitting: false,
};

export function createTransactionReducer(
  state: Object = initialCreateTransactionState,
  { type, payload, error }: Action<Error | Array<Object>>,
) {
  switch (type) {
    case CREATE_TRANSACTION:
      return { isSubmitting: true };
    case CREATE_TRANSACTION_SUCCESS:
      return { isSubmitting: false };
    case CREATE_TRANSACTION_FAILURE:
      return { isSubmitting: false, error };
    default:
      return { ...state };
  }
}
