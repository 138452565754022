import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageFloorPlan.Title',
    defaultMessage: 'Fortress - Manage Floor Plan',
  },
  header: {
    id: 'App.ManageFloorPlan.Header',
    defaultMessage: 'Manage Floor Plan',
  },
  floorPlanName: {
    id: 'App.ManageFloorPlan.FloorPlansTable.FloorPlanName',
    defaultMessage: 'Name',
  },
  floorPlanMarketingName: {
    id: 'App.ManageFloorPlan.FloorPlansTable.FloorPlanMarketingName',
    defaultMessage: 'Marketing Name',
  },
  beds: {
    id: 'App.ManageFloorPlan.FloorPlansTable.Beds',
    defaultMessage: 'Beds/Baths',
  },
  squareFeet: {
    id: 'App.ManageFloorPlan.FloorPlansTable.SquareFeet',
    defaultMessage: 'Sq.Ft.',
  },
  numberOfUnits: {
    id: 'App.ManageFloorPlan.FloorPlansTable.NumberOfUnits',
    defaultMessage: '# Units',
  },
  marketRent: {
    id: 'App.ManageFloorPlan.FloorPlansTable.BaseMarketRent',
    defaultMessage: 'Base Market Rent',
  },
  basicRent: {
    id: 'App.ManageFloorPlan.FloorPlansTable.basicRent',
    defaultMessage: 'Basic Rent',
  },
  noteRent: {
    id: 'App.ManageFloorPlan.FloorPlansTable.noteRent',
    defaultMessage: 'Note Rent',
  },
  grossRentLimit: {
    id: 'App.ManageFloorPlan.FloorPlansTable.GrossRentLimit',
    defaultMessage: 'Gross Rent Limit',
  },
  financialMarketRent: {
    id: 'App.ManageFloorPlan.FloorPlansTable.FinancialMarketRent',
    defaultMessage: 'Financial Market Rent',
  },
  marketRentGrossUa: {
    id: 'App.ManageFloorPlan.FloorPlansTable.BaseMarketRentGrossUa',
    defaultMessage: '(Gross Rent - Largest UA - Non-Optional Charge)',
  },
  HUDGrossRentLimit: {
    id: 'App.ManageFloorPlan.FloorPlansTable.HUDGrossRentLimit',
    defaultMessage: 'HUD Gross Rent Limit',
  },
  quotingRent: {
    id: 'App.ManageFloorPlan.FloorPlansTable.QuotingRent',
    defaultMessage: 'Quoting Rent',
  },
  suggestedDeposit: {
    id: 'App.ManageFloorPlan.FloorPlansTable.SuggestedDeposit',
    defaultMessage: 'Suggested Deposit',
  },
  view: {
    id: 'App.ManageFloorPlan.FloorPlansTable.View',
    defaultMessage: 'View',
  },
  changeHudLimits: {
    id: 'App.ManageFloorPlan.FloorPlansTable.ChangeHudLimits',
    defaultMessage: 'Change HUD Limits',
  },
  changeRDLimits: {
    id: 'App.ManageFloorPlan.FloorPlansTable.ChangeRDLimits',
    defaultMessage: 'Change RD Limits',
  },
  smartPrice: {
    id: 'App.ManageFloorPlan.FloorPlansTable.SmartPrice',
    defaultMessage: 'Smart Price',
  },
  smartPriceLastUpdate: {
    id: 'App.ManageFloorPlan.FloorPlansTable.smartPriceLastUpdate',
    defaultMessage: 'Smart Price last updated on:',
  },
  smartPriceStatus: {
    id: 'App.ManageFloorPlan.FloorPlansTable.SmartPriceStatus',
    defaultMessage: 'Smart Price Status',
  },
  updatedBy: {
    id: 'App.ManageFloorPlan.FloorPlansTable.UpdatedBy',
    defaultMessage: 'By {name} on {date}',
  },
  smartPriceErrorLoading: {
    id: 'App.ManageFloorPlan.FloorPlansTable.smartPriceErrorLoading',
    defaultMessage: "Why I don't see a Smart Price on every floor plan?",
  },
  smartPriceErrorLoadingLink: {
    id: 'App.ManageFloorPlan.FloorPlansTable.smartPriceErrorLoadingLink',
    defaultMessage: "Here's why",
  },
});

export default messages;
