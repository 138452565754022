/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.CreateWorkOrder.communicationToResident.Title',
    defaultMessage: 'Communication to Resident',
  },
  note: {
    id: 'App.CreateWorkOrder.communicationToResident.Note',
    defaultMessage:
      'No changes have been made to this work order. Select a new Status or Reason above to send a new communication. Any past communication(s) for this work order can be viewed on the household’s Activity tab.',
  },
  noteOnHoldOrCompleted: {
    id: 'App.CreateWorkOrder.communicationToResident.NoteOnHoldOrCompleted',
    defaultMessage:
      'Select the communication(s) below to automatically send to the resident when you “Save” this work order. A preview of each message is displayed below the contact options.',
  },
  emailToResident: {
    id: 'App.CreateWorkOrder.communicationToResident.emailToResident',
    defaultMessage: 'Email to Resident',
  },
  textToResident: {
    id: 'App.CreateWorkOrder.communicationToResident.textToResident',
    defaultMessage: 'Text to Resident',
  },
  noCommunicationInfoNote: {
    id: 'App.CreateWorkOrder.communicationToResident.noCommunicationInfoNote',
    defaultMessage:
      'There are no household members associated to this work order, so there are no communication to be sent.',
  },
});

export default messages;
