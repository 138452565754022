import React from 'react';
import { Modal, Button, ButtonToolbar, Row, Col } from 'react-bootstrap';
import { confirmable } from 'react-confirm';
import messages from './messages';

type Props = {
  show: boolean,
  proceed: Function,
  dismiss: Function,
  cancel: Function,
  confirmation: any,
  options: Object
};

export const DeleteDialog = ({ show, proceed, dismiss, cancel, confirmation, options }: Props) => (
  <div className="static-modal">
    <Modal bsSize="small"  dialogClassName="modal-dialog modal-alert"  show={show} onHide={cancel} autoFocus={true}>
      <Modal.Body>
        <Row>
          <Col xs={12} >
            <h2 className="text-center">{confirmation}</h2>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar>
          <Button type="button" bsStyle="default" onClick={() => cancel('cancels')}>
            {options.intl ? options.intl.formatMessage(messages.cancel) : 'Cancel'}
          </Button>
          <Button type="button" bsStyle="primary" onClick={() => proceed('proceed')}>
            {options.intl ? options.intl.formatMessage(messages.ok) : 'Yes, delete'}
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  </div>
);

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(DeleteDialog);
