import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import type { HeaderProps } from './types';

const RowWarning = styled(Row)`
  .highlight-details {
    padding: 10px;
    margin: 10px;
  }
`;

export const Header = (props: HeaderProps) => (
  <div className="applicant-convert-header">
    <Row>
      <Col xs={6} sm={12}>
        <Link
          to={getUrlWithSelectedPropertyId(`/resident/${props.residentId}`)}
          className="btn-text"
        >
          <i className="et-chevron-left" />
          Go Back to Resident profile
        </Link>
      </Col>
    </Row>
    {props.leaseExecuted && (
      <RowWarning>
        <div className="highlight-details">
          A Lease for this household has been Executed. If you need to edit the
          household, please go to the Lease Data tab and cancel the outstanding
          Lease.
        </div>
      </RowWarning>
    )}
    {props.leaseSentToPortal && (
      <RowWarning>
        <div className="highlight-details">
          A Lease for this household has been sent to Portal. If you need to
          edit the household, please go to the Lease Data tab and cancel the
          outstanding Lease.
        </div>
      </RowWarning>
    )}
    <Row>
      <Col xs={6} sm={7}>
        <h1>
          Edit Household
          {props.financiallyResponsibleNames.length
            ? ` for ${props.financiallyResponsibleNames.join(', ')}`
            : ''}
        </h1>
        <span>(*) Required Fields</span>
      </Col>
    </Row>
  </div>
);

export default Header;
