import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { prop, isEmpty, pathOr, is } from 'ramda';
import moment from 'moment';
import messages from './messages';

import {
  useCommercialLeaseOptionsSave,
  useCommercialLeaseOptionsDelete,
  useAsyncCommercialLeaseOptions,
  useAsyncCommercialOptions,
  useUpdateLeaseNoOptions,
} from './hooks';

import { DATE_FIELDS, FULL_DATE_FORMAT } from './constants';

import LeaseOptionsModalForm from './LeaseOptionsModalForm';

import { FlexFormContainer, FlexFormInputQuarter } from './common';

type Props = {
  intl: Object,
  selectedProperty: Object,
  lease: Object,
};

function convertFormValues(obj) {
  return obj
    ? Object.keys(obj).reduce((acc, key) => {
        const val = obj[key];
        if (is(Array, val)) {
          acc[key] = val.map((newObj, key) => {
            return convertFormValues(newObj);
          });
        } else if (DATE_FIELDS.indexOf(key) !== -1) {
          acc[key] = val ? moment(val).format(FULL_DATE_FORMAT) : '';
        } else {
          acc[key] = val;
        }
        return acc;
      }, {})
    : null;
}

function EmptyLeaseOptionsBody({
  intl,
  setOpenFormDisabled,
  leaseId,
  handleSaveLease,
  commercialOptionSelected,
  setCommercialOptionSelected,
}) {
  return (
    <div className="custom-checkbox" style={{ marginBottom: '5px' }}>
      <label>
        <input
          type="checkbox"
          onChange={(e) => {
            handleSaveLease(e.target.checked);
            setCommercialOptionSelected(e.target.checked);
          }}
          disabled={!leaseId}
          checked={commercialOptionSelected}
        />
        <span className="custom-check-square" />
        {`${intl.formatMessage(messages.noOptions)}`}
      </label>
    </div>
  );
}

function AddLeaseOptionBody({
  intl,
  setOpenOptionForm,
  openFormDisabled,
  handleLoadFormValues,
  leaseId,
}) {
  return (
    <div className="col-xs-12">
      <a
        className="btn-text"
        onClick={() => {
          handleLoadFormValues(null);
          setOpenOptionForm(true);
        }}
        disabled={openFormDisabled || !leaseId}
      >
        {intl.formatMessage(messages.addOption)}
      </a>
    </div>
  );
}

function DisplayOptionsBody({
  commercialLeaseOptions,
  handleLoadFormValues,
}: Object) {
  return (
    <FlexFormContainer style={{ textAlign: 'center' }}>
      {commercialLeaseOptions
        .sort((a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix())
        .map((val, index) => (
          <FlexFormInputQuarter
            addsideborder={index === 0 || index % 4 === 0}
            style={{ marginBottom: '20px' }}
            key={val.id}
          >
            <a
              onClick={() => {
                const commercialLeaseOptionId = pathOr(
                  null,
                  ['option', 'id'],
                  val,
                );
                const valStr = pathOr(null, ['optionValues'], val);
                const valObj = JSON.parse(valStr);
                const payload = convertFormValues(valObj);
                handleLoadFormValues({
                  id: val.id,
                  commercialLeaseOptionId,
                  ...payload,
                });
              }}
            >
              {/** SORRY */}
              {val.option.option}
            </a>
          </FlexFormInputQuarter>
        ))}
    </FlexFormContainer>
  );
}

function LeaseOptions({ intl, selectedProperty, lease }: Props) {
  const leaseId = prop('id', lease);
  const hasCommercialOptions = prop('hasCommercialOptions', lease);
  const [commercialOptionSelected, setCommercialOptionSelected] = useState(
    hasCommercialOptions,
  );
  const [refreshLeaseOptions, setRefreshLeaseOptions] = useState(false);
  const [openOptionForm, setOpenOptionForm] = useState(null);
  const [openFormDisabled, setOpenFormDisabled] = useState(false);
  const [formValues, setFormValues] = useState(null);

  const { commercialOptions } = useAsyncCommercialOptions(selectedProperty);
  const { commercialLeaseOptions } = useAsyncCommercialLeaseOptions(
    selectedProperty,
    leaseId,
    refreshLeaseOptions,
  );
  const { isSaving, handleSaveOption } = useCommercialLeaseOptionsSave(
    selectedProperty,
    refreshLeaseOptions,
    setRefreshLeaseOptions,
    setCommercialOptionSelected,
  );
  const { isDeleting, handleDeleteOption } = useCommercialLeaseOptionsDelete(
    selectedProperty,
    refreshLeaseOptions,
    setRefreshLeaseOptions,
  );
  const { handleSaveLease } = useUpdateLeaseNoOptions(
    selectedProperty,
    leaseId,
  );

  const loadFormValues = (values: Object) => {
    setFormValues(values);
    setOpenOptionForm(true);
  };

  return (
    <React.Fragment>
      <Row className="padtop10 padbottom10">
        <Col xs={12}>
          <h2>
            <i className="et-checklist text-blue padright10" />
            {intl.formatMessage(messages.options)}
          </h2>
        </Col>
      </Row>
      <Row className="container-fluid">
        <div>
          {isEmpty(commercialLeaseOptions) ? (
            <EmptyLeaseOptionsBody
              intl={intl}
              setOpenFormDisabled={setOpenFormDisabled}
              leaseId={leaseId}
              handleSaveLease={handleSaveLease}
              commercialOptionSelected={commercialOptionSelected}
              setCommercialOptionSelected={setCommercialOptionSelected}
            />
          ) : (
            <DisplayOptionsBody
              commercialLeaseOptions={commercialLeaseOptions}
              handleLoadFormValues={loadFormValues}
            />
          )}
          <AddLeaseOptionBody
            intl={intl}
            setOpenOptionForm={setOpenOptionForm}
            openFormDisabled={openFormDisabled}
            commercialLeaseOptions={commercialLeaseOptions}
            handleLoadFormValues={loadFormValues}
            leaseId={leaseId}
          />
        </div>
      </Row>
      <LeaseOptionsModalForm
        intl={intl}
        openOptionForm={openOptionForm}
        setOpenOptionFormCallback={setOpenOptionForm}
        commercialOptions={commercialOptions}
        isSaving={isSaving}
        handleSaveOption={handleSaveOption}
        isDeleting={isDeleting}
        handleDeleteOption={handleDeleteOption}
        leaseId={leaseId}
        initialValues={formValues}
      />
    </React.Fragment>
  );
}

export default LeaseOptions;
