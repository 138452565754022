import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';

type Props = {};

function Label(props: Props) {
  return (
    <Typography marginBottom={1} variant={'inputLabel'}>
      {props.children}
    </Typography>
  );
}

export default Label;
