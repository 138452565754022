import { useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { useFlags } from 'launchdarkly-react-client-sdk';
import RhfFilterGroup from '../../../components/FilterGroup';
import { FORM_DEFAULTS } from './constants';

import { translateFilterTexts } from './utils';

import { FieldDescriptor } from '../../../components/FilterGroup/types';
import messages from './messages';
import { Props } from './types';
import {
  useFilterGroupSectionFieldDescriptors,
  useLocalization,
} from './hooks';

const FilterGroupSection = (props: Props) => {
  const { organizationId, onChange, showBody, complianceType, intl } = props;
  const { newFilterStyleCompliance } = useFlags();
  const [rawDescriptors] = useFilterGroupSectionFieldDescriptors(
    organizationId,
    complianceType,
  );
  const currentLocale = navigator.language;
  const [locale] = useLocalization(organizationId, currentLocale, intl);

  const fieldDescriptors = useMemo(
    (): Array<FieldDescriptor> =>
      rawDescriptors.map((filter) => {
        return translateFilterTexts(filter, locale, navigator.language);
      }),
    [rawDescriptors, locale],
  );

  const title = intl.formatMessage(messages.filterBy);
  const clearAllText = intl.formatMessage(messages.clearAll);

  const filterGroupProps = {
    showBody,
    fieldDescriptors,
    onChange,
    title,
    clearAllText,
    defaultValues: FORM_DEFAULTS,
    locale,
  };

  return newFilterStyleCompliance ? (
    <RhfFilterGroup {...filterGroupProps} />
  ) : null;
};

export default injectIntl(FilterGroupSection);
