import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useState } from 'react';
import { FILTERS_MAP } from './constants';

const sortFilters = (filters) => {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    acc[key] = [...value].sort();

    return acc;
  }, {});
};

const useFilters = (customerStatus: string, residents: Array<any>) => {
  const { communicationFilters } = useFlags();

  const residentFiltersData = useMemo(() => {
    const defaultData = {
      nBeds: new Set(),
      unitFloorLevel: new Set(),
      buildingNumber: new Set(),
      unitAmenities: new Set(),
    };
    if (customerStatus !== 'residents' || !communicationFilters)
      return sortFilters(defaultData);

    const filtersData = residents.reduce((acc, row) => {
      if (row.nBeds) acc.nBeds.add(row.nBeds);
      if (row.unitFloorLevel) acc.unitFloorLevel.add(row.unitFloorLevel);
      if (row.buildingNumber) acc.buildingNumber.add(row.buildingNumber);
      if (row.unitAmenities)
        row.unitAmenities.forEach(acc.unitAmenities.add, acc.unitAmenities);

      return acc;
    }, defaultData);

    return sortFilters(filtersData);
  }, [communicationFilters, customerStatus, residents]);

  const customerFilters = useMemo(() => {
    const filters = FILTERS_MAP[customerStatus] ?? [];

    if (customerStatus === 'residents') {
      const nBedsFilter = filters.find(
        (f) => f.fieldName === 'residentsNumberOfBeds',
      );
      nBedsFilter.options = residentFiltersData.nBeds.map((d) => ({
        value: `residentsNumberOfBeds-${d}`,
        text: d,
      }));
      if (residentFiltersData.nBeds.length < 2) nBedsFilter.hide = true;
      else nBedsFilter.hide = false;

      const unitFloorLevelFilter = filters.find(
        (f) => f.fieldName === 'residentsUnitLevel',
      );
      unitFloorLevelFilter.options = residentFiltersData.unitFloorLevel.map(
        (d) => ({
          value: `residentsUnitLevel-${d}`,
          text: d,
        }),
      );
      if (residentFiltersData.unitFloorLevel.length < 2)
        unitFloorLevelFilter.hide = true;
      else unitFloorLevelFilter.hide = false;

      const buildingNumberFilter = filters.find(
        (f) => f.fieldName === 'residentsBuildingNumber',
      );
      buildingNumberFilter.options = residentFiltersData.buildingNumber.map(
        (d) => ({
          value: `residentsBuildingNumber-${d}`,
          text: d,
        }),
      );
      if (residentFiltersData.buildingNumber.length < 2)
        buildingNumberFilter.hide = true;
      else buildingNumberFilter.hide = false;

      const unitAmenitiesFilter = filters.find(
        (f) => f.fieldName === 'residentsUnitAmenities',
      );
      unitAmenitiesFilter.options = residentFiltersData.unitAmenities.map(
        (d) => ({
          value: `residentsUnitAmenities-${d}`,
          text: d,
        }),
      );
      if (residentFiltersData.unitAmenities.length < 2)
        unitAmenitiesFilter.hide = true;
      else unitAmenitiesFilter.hide = false;
    }

    return filters;
  }, [customerStatus, residentFiltersData]);

  return [customerFilters];
};

export const injectFilters = (Component) => (props) => {
  const [customerStatus, setCustomerStatus] = useState('');
  const [residents, setResidents] = useState([]);

  const [customerFilters] = useFilters(customerStatus, residents);

  return (
    <Component
      setCustomerStatus={setCustomerStatus}
      customerFilters={customerFilters}
      setResidents={setResidents}
      {...props}
    />
  );
};
