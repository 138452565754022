import styled from 'styled-components';
import type {
  Filter,
  ClearAllEventHandler,
  RemoveFilterEventHandler,
} from './types';

type Props = {
  filters: Filter[],
  onClearAll: ClearAllEventHandler,
  onRemoveFilter: RemoveFilterEventHandler,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & a:hover {
    text-decoration: none;
  }
`;

const Title = styled.span`
  color: #6a6a6a;
  font-weight: 500;
  margin-right: 7px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
`;

const FilterChip = styled.div`
  background-color: #ebf5ff;
  border-radius: 25px;
  color: #2136e1;
  font-weight: 500;
  padding: 7px 13px;
  margin: 1px;
`;

const DeleteIcon = styled.i`
  color: #2136e1;
  font-size: 10px;
  font-weight: 500;
`;

const ClearAllButton = styled.a`
  margin-left: 7px;
`;

function AppliedFilters({ filters, onClearAll, onRemoveFilter }: Props) {
  return (
    <Wrapper>
      <Title>Applied Filters: </Title>
      <FilterWrapper>
        {filters.map((f) => (
          <FilterChip key={f.id}>
            <span data-testid={f.text}>{f.text} </span>
            <a
              data-testid={`remove-filter-${f.id}`}
              onClick={() => onRemoveFilter(f)}
            >
              <DeleteIcon className="et-delete" />
            </a>
          </FilterChip>
        ))}
      </FilterWrapper>
      <ClearAllButton role="button" onClick={onClearAll}>
        Clear All
      </ClearAllButton>
    </Wrapper>
  );
}

export default AppliedFilters;
