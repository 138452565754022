import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  formatDateTimeDisplayUTC,
  formatDateDisplayLocal,
} from '@fortress-technology-solutions/fortress-component-library/utils';
import {
  GROSS_RENT_CERT_TYPE,
  INITIAL_CERT_TYPE,
  MOVE_IN_CERT_TYPE,
  RECERT_CERT_TYPE,
  OTHER_HUD,
  OTHER,
  INTERIM_CERT_TYPE,
} from '../../constants/affordableCertificationTypes';
import { isRecertLate } from './utilsV2';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import useHasPermission from '../../hooks/useHasPermission';
import moment from 'moment/moment';
import messages from './ManageCertificationsContent/GrossRentCertsTable/messages';
import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Atoms';

const shouldRowBeHighlighted = ({ uploadedSigned59A }) => {
  if (!uploadedSigned59A) {
    return { backgroundColor: 'error.lighter' };
  }
  return undefined;
};

const useRows = ({
  handleDownloadDocument,
  handleGenerate59A,
  modalProps: { handleOpen: handleOpenModal, handleOpenConfirmModal },
  sortedAndFilteredResults = [],
  selectedCertificationType,
  propertySettings: { completeGRCertsWithoutSigned59As },
  grossRentEnhancementsFlag,
  checkBoxListProps: { checkboxList, handleSelectRow },
}) => {
  const hasComplianceApprovalPermission = useHasPermission(
    'compliance-approval',
  );
  const hasManageCertificationsGRCertCancelPermission = useHasPermission(
    'manage-certifications-gr-cert-cancel',
  );

  return useMemo(() => {
    if (!selectedCertificationType) return sortedAndFilteredResults;
    return sortedAndFilteredResults?.map(
      (
        {
          affordableQualificationId,
          anticipatedVoucherDate,
          anticipatedVoucherOverride,
          balance,
          certificationNotes,
          certificationType,
          complianceApprovalStatus,
          complianceDocument,
          correction,
          daysSinceLastUpload,
          doNotRenew,
          document,
          eivIndicator,
          floorPlan,
          floorPlanName,
          floorplan,
          generated59a,
          grEffectiveDate,
          hasPriorHUDCerts,
          headOfHousehold,
          headOfHouseholdId,
          isCorrection,
          isPriorResident,
          isResident,
          lastCertified,
          lastCertifiedDate,
          latestDocument,
          leaseStartDate,
          moveInDate,
          newGrossRent,
          newHUDUA,
          newResidentRent,
          newSubsidy,
          newUtilityReimbursement,
          nextRecertDueDate,
          notice120,
          notice30,
          notice60,
          notice90,
          occupiedOnNTV,
          otherCertInProgress,
          prevMissingCert,
          priorActiveCert,
          priorCertificationDate,
          programName,
          recertDue,
          recertStatus,
          residentId,
          residentSignatureRequired,
          underEviction,
          unit,
          voucherEffectiveDate,
        },
        index,
      ) => {
        const isMissingPreviousCertification =
          (selectedCertificationType === GROSS_RENT_CERT_TYPE &&
            !priorCertificationDate) ||
          (!hasPriorHUDCerts && !prevMissingCert);

        // GROSS_RENT
        const disableGenerate59A = !priorCertificationDate || !!priorActiveCert;
        const uploadedSigned59A = Boolean(document?.id);
        const sx = shouldRowBeHighlighted({ uploadedSigned59A });

        // ALL
        const certificationNotesCell = {
          variant: 'icon',
          iconName: 'NotesIcon',
          color: Boolean(certificationNotes) ? 'action' : 'disabled',
          tooltip: Boolean(certificationNotes?.length),
          tooltipContent: certificationNotes,
          value: certificationNotes,
        };
        // INITIAL, INTERIM, OTHER_HUD
        const headOfHouseholdCell = {
          variant: 'link',
          to: getUrlWithSelectedPropertyId(
            `/${
              isPriorResident ? 'prior-resident' : 'resident'
            }/${headOfHouseholdId}`,
          ),
          value: headOfHousehold,
        };

        // newSubsidy, newResidentRent, newUtilityReimbursement
        const getGrossRentCurrencyCell = (value) => {
          const isZero = value === 0;
          const missingPriorCertAndValue = !hasPriorHUDCerts && value === null;
          const hasValue = typeof value === 'number' && !isZero;

          let finalValue;
          if (missingPriorCertAndValue) {
            finalValue = '***';
          } else if (isZero) {
            finalValue = 'NC*';
          } else {
            finalValue = value;
          }

          return {
            align: 'right',
            variant: hasValue ? 'currency' : undefined,
            value: finalValue,
            color: missingPriorCertAndValue ? 'error' : null,
          };
        };

        const OTHER_ROW = [OTHER_HUD, OTHER].includes(selectedCertificationType)
          ? {
              status: {
                variant: 'complianceApprovalStatus',
                value: complianceApprovalStatus?.name,
              },
              certificationType: {
                value: certificationType,
              },
              unit: {
                value: unit,
              },
              floorPlan: {
                value: floorplan,
              },
              headOfHousehold: headOfHouseholdCell,
              effectiveDate: {
                variant: 'date',
                value: voucherEffectiveDate,
              },
              lastCertifiedDate: {
                variant: 'date',
                value: lastCertifiedDate,
              },
              nextRecertDueDate: {
                variant: 'date',
                value: nextRecertDueDate,
              },
              isPriorResident: {
                value: isPriorResident === 'yes' ? 'Yes' : 'No',
              },
              isCorrection: {
                value: isCorrection === 'yes' ? 'Yes' : null,
              },
              balance: {
                variant: 'currency',
                value: balance,
              },
              latestComplianceDocument: {
                value: latestDocument?.createdAt
                  ? [
                      formatDateTimeDisplayUTC(latestDocument.createdAt),
                      latestDocument.documentType.name,
                    ]
                  : null,
              },
              daysSinceLastUpload: {
                variant: 'number',
                value: daysSinceLastUpload,
              },
              certificationNotes: certificationNotesCell,
            }
          : {};

        return {
          [RECERT_CERT_TYPE]: {
            status: {
              variant: 'complianceApprovalStatus',
              value: recertStatus,
            },
            programName: {
              value: programName,
            },
            unit: {
              value: unit,
            },
            floorPlan: {
              value: floorPlan,
            },
            headOfHousehold: {
              variant: 'link',
              to: getUrlWithSelectedPropertyId(`/resident/${residentId}`),
              value: headOfHousehold,
            },
            moveInDate: {
              variant: 'date',
              value: moveInDate,
            },
            leaseStartDate: {
              variant: 'date',
              value: leaseStartDate,
            },
            lastCertified: {
              variant: 'date',
              value: lastCertified,
            },
            recertDue: {
              variant: 'date',
              value: recertDue,
              ...(isRecertLate(recertDue) && {
                sx: {
                  color: 'error.main',
                },
              }),
            },
            occupiedOnNTV: {
              variant: 'string',
              value: occupiedOnNTV === 'yes' ? 'Yes' : null,
            },
            doNotRenew: {
              variant: 'boolean',
              color: 'error',
              value: doNotRenew,
            },
            underEviction: {
              variant: 'boolean',
              color: 'error',
              value: underEviction,
            },
            balance: {
              variant: 'currency',
              value: balance,
            },
            notice120: {
              variant: 'string',
              value: notice120,
            },
            notice90: {
              variant: 'string',
              value: notice90,
            },
            notice60: {
              variant: 'string',
              value: notice60,
            },
            notice30: {
              variant: 'string',
              value: notice30,
            },
            complianceDocument: {
              variant: 'description',
              value: complianceDocument,
            },
            certificationNotes: certificationNotesCell,
          },
          [GROSS_RENT_CERT_TYPE]: {
            ...(grossRentEnhancementsFlag
              ? {
                  checkbox: {
                    value: (
                      <Checkbox
                        checked={checkboxList[index] ?? false}
                        onChange={() => handleSelectRow(index)}
                      />
                    ),
                  },
                  sx,
                }
              : []),
            unit: {
              variant: 'string',
              value: unit,
            },
            floorPlan: {
              variant: 'string',
              value: floorPlanName,
            },
            headOfHousehold: {
              variant: 'link',
              to: getUrlWithSelectedPropertyId(
                occupiedOnNTV === 'Prior'
                  ? `/prior-resident/${residentId}`
                  : `/resident/${residentId}`,
              ),
              value: headOfHousehold,
            },
            newGrossRent: {
              variant: 'currency',
              value: newGrossRent,
            },
            newHUDUA: {
              variant: 'currency',
              value: newHUDUA,
            },
            grEffectiveDate: {
              variant: 'date',
              value: grEffectiveDate,
            },
            anticipatedVoucherDate: {
              variant: 'date',
              value: anticipatedVoucherDate,
            },
            priorCertificationDate: {
              variant: 'date',
              value: priorCertificationDate,
            },
            otherCertInProgress: {
              variant: 'string',
              value: otherCertInProgress,
            },
            occupiedOnNTV: {
              variant: 'string',
              value: occupiedOnNTV,
            },
            newSubsidy: getGrossRentCurrencyCell(newSubsidy),
            newResidentRent: getGrossRentCurrencyCell(newResidentRent),
            newUtilityReimbursement: getGrossRentCurrencyCell(
              newUtilityReimbursement,
            ),
            residentSignatureRequired: {
              variant: 'string',
              value: priorCertificationDate ? residentSignatureRequired : '***',
              color: isMissingPreviousCertification ? 'error' : null,
            },
            correction: {
              variant: 'string',
              value: isCorrection === 'yes' ? 'Yes' : null,
              color: isCorrection === 'yes' && !correction ? 'error' : null,
            },
            generated59a: {
              variant: 'string',
              value: generated59a === 'yes' ? 'Yes' : null,
            },
            uploadedDocument: {
              value: uploadedSigned59A
                ? formatDateDisplayLocal(document?.createdAt)
                : null,
              variant: 'date',
              component: 'a',
              disabled: !!!document?.id,
              sx: {
                textDecoration: 'underline',
                color: 'primary.main',
              },
              onClick: () =>
                handleDownloadDocument.mutate({
                  documentId: document?.id,
                  fileName: `50059A_${moment().unix()}.pdf`,
                }),
            },
            generate59a: {
              variant: 'iconButton',
              iconName: 'DownloadIcon',
              color: 'primary',
              disabled: disableGenerate59A,
              onClick: () =>
                handleGenerate59A.mutate(affordableQualificationId),
            },
            action: {
              variant: 'menu',
              name: 'actionsMenu',
              iconName: 'BoltIcon',
              color: 'primary',
              closeOnClick: true,
              options: [
                {
                  disabled: disableGenerate59A || uploadedSigned59A,
                  value: 'uploadDocument',
                  text: <FormattedMessage {...messages.uploadDocument} />,
                  onClick: ({ value }) =>
                    handleOpenModal({
                      affordableQualificationId,
                      modalType: value,
                    }),
                },
                {
                  value: 'editCert',
                  text: <FormattedMessage {...messages.editCert} />,
                  onClick: ({ value }) =>
                    handleOpenModal({
                      affordableQualificationId,
                      grossRentCert: {
                        anticipatedVoucherDate,
                        anticipatedVoucherOverride,
                        eivIndicator,
                        correction,
                        prevMissingCert,
                        unit,
                        headOfHousehold,
                        grEffectiveDate,
                        isCorrection,
                        affordableQualificationId,
                        priorCertificationDate,
                        hasPriorHUDCerts,
                      },
                      modalType: value,
                    }),
                },
                ...(hasComplianceApprovalPermission
                  ? [
                      {
                        disabled:
                          grossRentEnhancementsFlag &&
                          completeGRCertsWithoutSigned59As
                            ? disableGenerate59A
                            : disableGenerate59A || !uploadedSigned59A,
                        value: 'completeCert',
                        text: <FormattedMessage {...messages.completeCert} />,
                        onClick: ({ value }) =>
                          handleOpenModal({
                            affordableQualificationId,
                            modalType: value,
                            grossRentCert: {
                              unit,
                              headOfHousehold,
                            },
                          }),
                      },
                    ]
                  : []),
                ...(hasManageCertificationsGRCertCancelPermission
                  ? [
                      {
                        value: 'cancelCert',
                        text: <FormattedMessage {...messages.cancelCert} />,
                        onClick: ({ value }) =>
                          handleOpenConfirmModal({
                            affordableQualificationId,
                            modalType: value,
                            grossRentCert: {
                              unit,
                              headOfHousehold,
                            },
                          }),
                      },
                    ]
                  : []),
              ],
            },
          },
          [MOVE_IN_CERT_TYPE]: {
            status: {
              variant: 'complianceApprovalStatus',
              value: complianceApprovalStatus?.name,
            },
            programName: {
              value: programName,
            },
            unit: {
              value: unit,
            },
            floorPlan: {
              value: floorplan,
            },
            headOfHousehold: headOfHouseholdCell,
            moveInDate: {
              variant: 'date',
              value: moveInDate,
            },
            leaseStartDate: {
              variant: 'date',
              value: leaseStartDate,
            },
            isResident: {
              value: isResident === 'yes' ? 'Yes' : 'No', // parsed from [isApplicant]
            },
            balance: {
              variant: 'currency',
              value: balance,
            },
            isCorrection: {
              value: isCorrection === 'yes' ? 'Yes' : null,
            },
            complianceDocument: {
              value: latestDocument?.createdAt
                ? [
                    formatDateTimeDisplayUTC(latestDocument.createdAt),
                    latestDocument.documentType.name,
                  ]
                : null,
            },
            daysSinceLastUpload: {
              variant: 'number',
              value: daysSinceLastUpload,
            },
            certificationNotes: certificationNotesCell,
          },
          [INITIAL_CERT_TYPE]: {
            status: {
              variant: 'complianceApprovalStatus',
              value: complianceApprovalStatus?.name,
            },
            programName: {
              value: programName,
            },
            unit: {
              value: unit,
            },
            floorPlan: {
              value: floorPlan,
            },
            headOfHousehold: headOfHouseholdCell,
            effectiveDate: {
              variant: 'date',
              value: voucherEffectiveDate,
            },
            leaseStartDate: {},
            isPriorResident: {},
            balance: {
              variant: 'currency',
              value: balance,
            },
            isCorrection: {
              value: isCorrection === 'yes' ? 'Yes' : null,
            },
            latestComplianceDocument: {
              value: latestDocument?.createdAt
                ? [
                    formatDateTimeDisplayUTC(latestDocument.createdAt),
                    latestDocument.documentType.name,
                  ]
                : null,
            },
            daysSinceLastUpload: {
              variant: 'number',
              value: daysSinceLastUpload,
            },
            certificationNotes: certificationNotesCell,
          },
          [INTERIM_CERT_TYPE]: {
            status: {
              variant: 'complianceApprovalStatus',
              value: complianceApprovalStatus?.name,
            },
            programName: {
              value: programName,
            },
            unit: {
              value: unit,
            },
            floorPlan: {
              value: floorplan,
            },
            headOfHousehold: headOfHouseholdCell,
            effectiveDate: {
              variant: 'date',
              value: voucherEffectiveDate,
            },
            lastCertifiedDate: {
              variant: 'date',
              value: lastCertifiedDate,
            },
            nextRecertDueDate: {
              variant: 'date',
              value: nextRecertDueDate,
            },
            isPriorResident: {
              value: isPriorResident === 'yes' ? 'Yes' : 'No',
            },
            isCorrection: {
              value: isCorrection === 'yes' ? 'Yes' : null,
            },
            balance: {
              variant: 'currency',
              value: balance,
            },
            latestComplianceDocument: {
              value: latestDocument?.createdAt
                ? [
                    formatDateTimeDisplayUTC(latestDocument.createdAt),
                    latestDocument.documentType.name,
                  ]
                : null,
            },
            daysSinceLastUpload: {
              variant: 'number',
              value: daysSinceLastUpload,
            },
            certificationNotes: certificationNotesCell,
          },
          [OTHER_HUD]: OTHER_ROW,
          [OTHER]: OTHER_ROW,
        }[selectedCertificationType];
      },
    );
  }, [
    handleDownloadDocument,
    handleGenerate59A,
    handleOpenConfirmModal,
    handleOpenModal,
    hasComplianceApprovalPermission,
    hasManageCertificationsGRCertCancelPermission,
    selectedCertificationType,
    sortedAndFilteredResults,
    checkboxList,
    handleSelectRow,
    grossRentEnhancementsFlag,
    completeGRCertsWithoutSigned59As,
  ]);
};

export default useRows;
