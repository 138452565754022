import { FormattedRelative, FormattedDate } from 'react-intl';

import React from 'react';

type Props = {
  value: Date,
  differenceInMs?: number,
};

const ValidateThreshold = function (date, differenceInMs) {
  const difference = Math.floor(
    Math.abs(Date.now() - new Date(date)) / differenceInMs,
  );
  return difference > 0 ? true : false;
};

const RelativeDateField = ({
  value,
  differenceInMs = 1000 * 60 * 60 * 24 * 7,
}: Props) => (
  <span>
    {ValidateThreshold(value, differenceInMs) && (
      <FormattedDate value={value} year="numeric" month="short" day="2-digit" />
    )}
    {!ValidateThreshold(value, differenceInMs) && (
      <FormattedRelative value={value} />
    )}
  </span>
);

export default RelativeDateField;
