import React from 'react';
import {
  Banner,
  Grid,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { AlertIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useIntl from '../../hooks/useIntl';
import messages from './messages';

const AffordableTransferBanner = ({ show }) => {
  const { affordableTransfers } = useFlags();
  const intl = useIntl();

  if (!show || !affordableTransfers) {
    return null;
  }

  return (
    <Grid container fluid>
      <Grid item xs={12}>
        <Banner
          color={'lightGrey'}
          BoxProps={{
            sx: {
              marginLeft: '16px',
              marginRight: '16px',
              maxWidth: '700px',
              textOverflow: '',
              backgroundColor: '#F0F0F0',
            },
          }}
          TypographyProps={{
            noWrap: false,
          }}
          hasBorder={false}
          text={
            intl?.formatMessage(messages.affordableTransferBanner) ??
            messages.affordableTransferBanner.defaultMessage
          }
          icon={<AlertIcon />}
        />
      </Grid>
    </Grid>
  );
};

export default AffordableTransferBanner;
