import moment from 'moment';
import { put, takeLatest, select, throttle } from 'redux-saga/effects';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import { actions as toastrActions } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import type { Saga } from 'redux-saga';
import type { Action } from '../App/types';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import type { Property } from '../App/types';
import {
  GET_RECERTIFICATION_FORM_INFORMATION,
  PRINT_RECERTIFICATION_APPLICATION_FORM,
  SAVE_RECERTIFICATION_FORM,
} from './constants';
import {
  getRecertificationFormInformationSuccess,
  saveRecertificationFormSuccess,
} from './actions';
import AffordableQualificationService from '../../services/affordableQualificationService';
import messages from './messages';
import { omit } from 'ramda';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* getRecertificationInformation(
  action: Action<Object>,
): Saga<void> {
  const selectedProperty = yield select(selectSelectedProperty);
  validateSelectedProperty(selectedProperty);
  const organizationId = yield select(selectCurrentUserOrganizationId);

  const { applicantId, affordableQualificationId } = action.payload;

  const affordableQualificationService = new AffordableQualificationService();
  const response = yield affordableQualificationService.getRecertForm(
    organizationId,
    selectedProperty.id,
    applicantId,
    affordableQualificationId,
  );
  yield put(getRecertificationFormInformationSuccess(response));
}

export function* getRecertificationInformationSaga(): Saga<void> {
  yield takeLatest(
    GET_RECERTIFICATION_FORM_INFORMATION,
    getRecertificationInformation,
  );
}

export function* saveRecertificationForm(action: Action<any>): Saga<void> {
  try {
    const {
      recertificationData,
      residentId,
      applicantId,
      programName,
      affordableQualificationId,
    } = action.payload;
    let successMessage;
    if (recertificationData?.isInitialCertForNonHUD) {
      successMessage = recertificationData.complete
        ? messages.initialCertificationCompletionSuccessDescription
        : messages.initialCertificationProgressSuccessDescription;
    } else {
      successMessage = recertificationData.complete
        ? messages.completionSuccessDescription
        : messages.progressSuccessDescription;
    }
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);

    const affordableQualificationService = new AffordableQualificationService();
    yield affordableQualificationService.saveRecertForm(
      omit(['isInitialCertForNonHUD'], recertificationData),
      organizationId,
      selectedProperty.id,
      applicantId,
      affordableQualificationId,
    );
    const urlProgramName = (programName ?? '').replace(/\//g, '_');

    yield put(saveRecertificationFormSuccess());
    yield put(
      push(
        getUrlWithSelectedPropertyId(
          `/resident/${residentId}/?tab=${urlProgramName}`,
        ),
      ),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(successMessage),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchRecertificationApplicationForm({ payload }: Object): any {
  try {
    const {
      applicantId,
      propertyId,
      affordableQualificationId,
      propertyName,
      organizationId,
      programName,
    } = payload;
    const affordableQualificationService = new AffordableQualificationService();
    const date = moment().format('YYYY-MM-DD');
    const fileName = `${date}_${propertyName
      .trim()
      .replace(
        ' ',
        '_',
      )}_${programName}_Online_Recertification_Application_Form.pdf`;

    yield affordableQualificationService.downloadRecertificationForm(
      organizationId,
      propertyId,
      applicantId,
      affordableQualificationId,
      fileName,
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: 'Error while downloading',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* saveRecertificationFormSaga(): Saga<void> {
  yield throttle(500, SAVE_RECERTIFICATION_FORM, saveRecertificationForm);
}

export function* printRecertificationApplicationForm(): Saga<void> {
  yield takeLatest(
    PRINT_RECERTIFICATION_APPLICATION_FORM,
    fetchRecertificationApplicationForm,
  );
}

export default [
  getRecertificationInformationSaga,
  saveRecertificationFormSaga,
  printRecertificationApplicationForm,
];
