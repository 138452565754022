import React from 'react';
import { pathOr } from 'ramda';
import { Field, FormSection } from 'redux-form';
import { getProgramShortName } from './utils';

import {
  renderNumberField,
  renderPercentageField,
  renderTextField,
} from '../../../../../utils/redux-form-helper';

const SetAsideProgramDetails = ({ setAsideProgram, intl }: Object) => {
  const programShortNameRaw = pathOr(
    '',
    ['setAsideShortName'],
    setAsideProgram,
  );
  const programShortName = getProgramShortName(programShortNameRaw);

  return (
    <FormSection name={setAsideProgram.id}>
      <tr key={setAsideProgram.id}>
        <td>{setAsideProgram.programName}</td>
        <td>{programShortName}</td>
        <td>
          <Field
            name={'numberOfUnits'}
            component={renderNumberField}
            messageAsTooltip
            min="0"
            step="1"
          />
        </td>

        <td style={{ minWidth: '140px' }}>
          <Field
            name={'incomeLimit'}
            component={renderTextField}
            messageAsTooltip
            required
          />
        </td>
        <td>
          <Field
            name={'maximumIncomePercentage'}
            component={renderPercentageField}
            messageAsTooltip
            min="0"
            step="1"
            required
          />
        </td>
        <td>
          <Field
            name={'maximumRentPercentage'}
            component={renderPercentageField}
            messageAsTooltip
            min="0"
            step="1"
            required
          />
        </td>
        <td>
          <Field
            name={'overIncomeCalc'}
            component={renderPercentageField}
            messageAsTooltip
            min="0"
            step="1"
            required
          />
        </td>
      </tr>
    </FormSection>
  );
};

export default SetAsideProgramDetails;
