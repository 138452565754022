import { pathOr, last } from 'ramda';

import { BACKGROUND_COLORS } from '../../../../components/Graphs/constants';

/**
 * Formats the raw KPI to an array that looks like:
 * ( background color added to match the graph colors)
 * [
 *   {
 *     "setAside": "30",
 *     "numRequiredUnits": 5,
 *     "numActualUnits": 0,
 *     "numLeasedUnits": 0,
 *     "backgroundColor": ...
 *   },
 *   {
 *     "setAside": "50",
 *     "numRequiredUnits": 10,
 *     "numActualUnits": 0,
 *     "numLeasedUnits": 0,
 *     "backgroundColor": ...
 *   },
 * ]
 * @param  {Object} kpi
 * @returns Array
 */
export const parseKpiRows = (kpi: Object): Array<Object> => {
  const setAsideNames = Object.keys(kpi).sort();
  const rowsData = setAsideNames.map((setAside, index) => {
    const counts = kpi[setAside];
    const numRequiredUnits = counts?.numRequiredUnits ?? 0;
    const numActualUnits = counts?.numActualUnits ?? 0;
    const numLeasedUnits = counts?.numLeasedUnits ?? 0;

    const hasColor = index < BACKGROUND_COLORS.length;
    const backgroundColor = hasColor
      ? BACKGROUND_COLORS[index]
      : last(BACKGROUND_COLORS);
    return {
      setAside,
      numRequiredUnits,
      numActualUnits,
      numLeasedUnits,
      backgroundColor,
    };
  });
  return rowsData;
};

export const calculateActualIncomeAverage = (
  kpi: Object | Array<Object>,
): number | null => {
  if (!kpi || !Array.isArray(kpi)) {
    return null;
  }

  const counts = kpi.reduce((totals, entry) => {
    const setAsidePercent = +pathOr(0, ['setAside'], entry);
    const numActualUnits = +pathOr(0, ['numActualUnits'], entry);
    const currentTotalActual = +pathOr(0, ['totalActual'], totals);
    const currentCountActual = +pathOr(0, ['actual'], totals);

    return {
      actual: currentCountActual + setAsidePercent * numActualUnits,
      totalActual: currentTotalActual + numActualUnits,
    };
  }, {});

  const actualIncomeAvg =
    counts.totalActual !== 0
      ? Math.round((counts.actual / counts.totalActual) * 100) / 100
      : 0;

  return actualIncomeAvg;
};
