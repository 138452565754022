import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Panel, Row, Col } from 'react-bootstrap';
import * as R from 'ramda';
import { Header, Title } from '../../components/ProfileVisualComponents';
import IconTitle from '../../components/ProfileVisualComponents/IconTitle';
import ContactsSection from './ContactsSection';
import InsuranceSection from './InsuranceSection';
import PeopleSection from './PeopleSection';
import messages from './messages';
import InsuranceModal from './InsuranceModal';
import * as hooks from './hooks';
import type { SelectedProperty } from './types';
import ContactModal from './ContactModal';

type Props = {
  householdId: string,
  selectedProperty: Object,
  householdMembers: Array<Object>,
  editHouseholdMember: Function,
  residentId?: string,
  anyContact?: boolean,
  anyInsurance?: boolean,
  onContactCheckboxClick: Function,
  onInsuranceCheckboxClick: Function,
};

const TenantPanel = styled(Panel).attrs({
  className: 'block block-household block-white-shadow',
})``;

const PanelRow = ({ children, hideFooter = false }) => (
  <Row>
    {children}
    {!hideFooter && <div className="panel-footer" />}
  </Row>
);

const ProfileHeader = () => (
  <Header>
    <Title>
      <Row>
        <Col xs={12} sm={9}>
          <IconTitle
            icon={<i className="et-people" />}
            message={messages.tenant}
          />
        </Col>
      </Row>
    </Title>
  </Header>
);

const ProfileBody = ({
  contacts,
  insurances,
  onInsuranceModalOpen,
  onInsuranceSelect,
  onContactModalOpen,
  onContactSelect,
  householdMembers,
  editHouseholdMember,
  residentId,
  onContactCheckboxClick,
  onInsuranceCheckboxClick,
  anyContact,
  anyInsurance,
}) => {
  const disableCheckbox = !R.isNil(residentId);
  const contactCheckboxChecked = anyContact === false;
  const insuranceCheckboxChecked = anyInsurance === false;

  return (
    <Panel.Body>
      <PanelRow>
        <PeopleSection
          householdMembers={householdMembers}
          editHouseholdMember={editHouseholdMember}
          residentId={residentId}
        />
      </PanelRow>
      <PanelRow>
        <ContactsSection
          contacts={contacts}
          onAddClick={onContactModalOpen}
          onNameClick={onContactSelect}
          checkboxChecked={contactCheckboxChecked}
          disableCheckbox={disableCheckbox}
          onCheckboxClick={onContactCheckboxClick}
        />
      </PanelRow>
      <PanelRow hideFooter>
        <InsuranceSection
          insurances={insurances}
          onAddClick={onInsuranceModalOpen}
          onTypeClick={onInsuranceSelect}
          checkboxChecked={insuranceCheckboxChecked}
          disableCheckbox={disableCheckbox}
          onCheckboxClick={onInsuranceCheckboxClick}
        />
      </PanelRow>
    </Panel.Body>
  );
};

function Tenant({
  householdId,
  selectedProperty,
  householdMembers,
  editHouseholdMember,
  residentId,
  anyContact,
  anyInsurance,
  onContactCheckboxClick,
  onInsuranceCheckboxClick,
}: Props) {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isInsuranceModalOpen, setIsInsuranceModalOpen] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [insurances, refreshInsurances] = hooks.useTenantInsurances(
    householdId,
    selectedProperty,
  );
  const [contacts, refreshContacts] = hooks.useTenantContacts(
    householdId,
    selectedProperty,
  );
  const organizationId = R.prop('organizationId', selectedProperty);

  const handleContactModalOpen = () => {
    setIsContactModalOpen(true);
  };

  const handleContactModalClose = () => {
    setIsContactModalOpen(false);
    if (selectedContact) setSelectedContact(null);
  };

  const handleContactSelect = (contact) => {
    setSelectedContact(contact);
    handleContactModalOpen();
  };

  const handleInsuranceModalOpen = () => {
    setIsInsuranceModalOpen(true);
  };

  const handleInsuranceModalClose = () => {
    setIsInsuranceModalOpen(false);
    if (selectedInsurance) setSelectedInsurance(null);
  };

  const handleInsuranceSelect = (insurance) => {
    setSelectedInsurance(insurance);
    handleInsuranceModalOpen();
  };

  return (
    <TenantPanel>
      <ProfileHeader />
      <ProfileBody
        contacts={contacts || []}
        insurances={insurances || []}
        onInsuranceSelect={handleInsuranceSelect}
        onInsuranceModalOpen={handleInsuranceModalOpen}
        onContactSelect={handleContactSelect}
        onContactModalOpen={handleContactModalOpen}
        householdMembers={householdMembers}
        editHouseholdMember={editHouseholdMember}
        residentId={residentId}
        anyContact={anyContact}
        anyInsurance={anyInsurance}
        onContactCheckboxClick={onContactCheckboxClick}
        onInsuranceCheckboxClick={onInsuranceCheckboxClick}
      />
      <ContactModal
        show={isContactModalOpen}
        onHide={handleContactModalClose}
        contact={selectedContact}
        organizationId={organizationId}
        householdId={householdId}
        refreshContacts={refreshContacts}
      />
      <InsuranceModal
        show={isInsuranceModalOpen}
        onHide={handleInsuranceModalClose}
        insurance={selectedInsurance}
        organizationId={organizationId}
        householdId={householdId}
        refreshInsurances={refreshInsurances}
      />
    </TenantPanel>
  );
}

function mapStateToProps(state): { selectedProperty: SelectedProperty } {
  return {
    selectedProperty: R.path(['app', 'selectedProperty'], state),
  };
}

export default connect(mapStateToProps)(Tenant);
