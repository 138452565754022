import { defineMessages } from 'react-intl';

const messages = defineMessages({
  convertConfirmation: {
    id: 'App.CreateApplication.ConvertConfirmation',
    defaultMessage: 'Are you sure you want to convert this Prospect to an Applicant?'
  },
  successHeader: {
    id: 'App.CreateApplication.SuccessHeader',
    defaultMessage: 'Success'
  },
  successDescription: {
    id: 'App.CreateApplication.SuccessDescription',
    defaultMessage: 'The prospect was converted.'
  },
  errorHeader: {
    id: 'App.CreateApplication.errorHeader',
    defaultMessage: 'Error'
  }
});

export default messages;
