import { defineMessages } from 'react-intl';

const messages = defineMessages({
  moveInsFound: {
    id: 'App.ManageCertificationsContent.ActiveCertifications.MoveInsFound',
    defaultMessage: 'Move-In Certifications Found',
  },
  initialsFound: {
    id: 'App.ManageCertificationsContent.ActiveCertifications.InitialsFound',
    defaultMessage: 'Initial Certifications Found',
  },
  otherFound: {
    id: 'App.ManageCertificationsContent.ActiveCertifications.OtherFound',
    defaultMessage: 'Certifications Found',
  },
  noEntriesFound: {
    id: 'App.ManageCertificationsContent.ActiveCertifications.NoEntriesFound',
    defaultMessage: 'No Entries Found',
  },
  yes: {
    id: 'App.ManageCertificationsContent.ActiveCertifications.Yes',
    defaultMessage: 'Yes',
  },
  started: {
    id: 'App.ManageCertificationsContent.ActiveCertifications.Started',
    defaultMessage: 'Started',
  },
  complete: {
    id: 'App.ManageCertificationsContent.ActiveCertifications.Complete',
    defaultMessage: 'Complete',
  },
  interimsFound: {
    id: 'App.ManageCertificationsContent.ActiveCertifications.InterimsFound',
    defaultMessage: 'Interim Certifications Found',
  },
});

export default messages;
