import { defineMessages } from 'react-intl';

const messages = defineMessages({
  confirmDelete: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseChargesOrCredits.DeleteChargeModal.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this transaction?',
  },
  confirmEnd: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseChargesOrCredits.DeleteChargeModal.confirmEnd',
    defaultMessage: 'Are you sure you want to stop/end this transaction?',
  },
  confirmDeleteProratedHeader: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseChargesOrCredits.DeleteChargeModal.confirmDeleteProratedHeader',
    defaultMessage: 'Warning!',
  },
  confirmDeleteProrated: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseChargesOrCredits.DeleteChargeModal.confirmDeleteProrated',
    defaultMessage:
      // eslint-disable-next-line max-len
      'This scheduled lease charge is connected to a future Prorate. Changes to this Scheduled charge may affect the amount and frequency of the renewal lease. Do you want to continue?',
  },
});

export default messages;
