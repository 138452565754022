import React from 'react';
import { Doughnut, Chart } from 'react-chartjs-2';
import {
  BACKGROUND_COLORS,
  HOVER_BACKGROUND_COLORS,
  BORDER_WIDTH,
  LARGER_TEXT,
  SMALLER_TEXT,
  PERCENTAGE_OFFSET,
} from '../constants';

const getFontSize = (height: number, heightDivisor: number) =>
  +(height / heightDivisor);
const getTextXCoord = (context: Object, canvasWidth: number, text: string) =>
  Math.round((canvasWidth - context.measureText(text).width) / 2);
const getTextYCoord = (
  canvasHeight: number,
  fontSize: number,
  isTop?: boolean = true,
) =>
  isTop
    ? canvasHeight / 2 - fontSize * PERCENTAGE_OFFSET
    : canvasHeight / 2 + fontSize * PERCENTAGE_OFFSET;

const originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
  draw: function () {
    originalDoughnutDraw.apply(this, arguments);
    const {
      chart: {
        width,
        height,
        ctx,
        config: {
          data: { datasets, showCenterText },
        },
      },
    } = this.chart;
    if (showCenterText) {
      const [value1, value2] = datasets[0].data;
      const total = value1 + value2;
      const percentageFontSize = getFontSize(height, LARGER_TEXT);
      ctx.textBaseline = 'middle';
      ctx.font = percentageFontSize + 'px sans-serif';
      const percentage = Math.round((value1 / total) * 10000) / 100;
      const percentageText = `${percentage}%`;
      const percentageTextX = getTextXCoord(ctx, width, percentageText);
      const percentageTextY = getTextYCoord(height, percentageFontSize);
      ctx.fillText(percentageText, percentageTextX, percentageTextY);

      const ratioText = `${value1} / ${total}`;
      const ratioFontSize = getFontSize(height, SMALLER_TEXT);
      ctx.font = ratioFontSize + 'px sans-serif';
      const ratioTextX = getTextXCoord(ctx, width, ratioText);
      const ratioTextY = getTextYCoord(height, ratioFontSize, false);
      ctx.fillText(ratioText, ratioTextX, ratioTextY);
    }
  },
});

type Props = {
  labels: Array<string>,
  data: Array<number>,
  datasets?: Object,
  options?: Object,
  showCenterText?: boolean,
  height?: number,
};

const DoughnutGraph = (props: Props) => {
  const {
    labels,
    data,
    datasets,
    options,
    showCenterText,
    height = 180,
    getElementAtEvent,
  } = props;

  const graphData = {
    showCenterText,
    labels,
    datasets: [
      {
        data,
        backgroundColor: BACKGROUND_COLORS,
        hoverBackgroundColor: HOVER_BACKGROUND_COLORS,
        borderWidth: new Array(4).fill(BORDER_WIDTH),
      },
    ],
  };

  const graphOptions = {
    cutoutPercentage: 80,
    legend: { display: false },
    ...options,
  };
  if (datasets) {
    graphData.datasets = datasets;
  }

  if (getElementAtEvent) {
    graphOptions.onHover = (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    };
  }

  return (
    <Doughnut
      data={graphData}
      options={graphOptions}
      height={height}
      getElementAtEvent={getElementAtEvent}
    />
  );
};

export default DoughnutGraph;
