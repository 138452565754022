import { assocPath, isEmpty, isNil } from 'ramda';

import {
  validatePositiveNumber,
  validateRequired,
} from '../../../../../utils/validations';

const validate = (values: Object, props: Object) => {
  let errors = {};

  const numericValues = [];
  const numberOfUnitsValues = [];
  const requiredValues = [];

  Object.keys(values).forEach((key) => {
    numericValues.splice(
      numericValues.length,
      0,
      [key, 'maximumIncomePercentage'],
      [key, 'maximumRentPercentage'],
      [key, 'overIncomeCalc'],
    );

    numberOfUnitsValues.splice(numberOfUnitsValues.length, 0, [
      key,
      'numberOfUnits',
    ]);

    requiredValues.splice(
      requiredValues.length,
      0,
      [key, 'isHUD'],
      [key, 'incomeLimit'],
      [key, 'maximumIncomePercentage'],
      [key, 'maximumRentPercentage'],
      [key, 'overIncomeCalc'],
    );
  });

  requiredValues.forEach((fieldPath) => {
    const key = fieldPath[0];
    const fieldName = fieldPath[1];
    if (!validateRequired(fieldName, values[key])) {
      errors = assocPath(
        [key, fieldName],
        // $FlowFixMe
        'This field is required.',
        errors,
      );
    }
  });

  numericValues.forEach((fieldPath) => {
    const key = fieldPath[0];
    const fieldName = fieldPath[1];
    if (!validatePositiveNumber(values[key][fieldName])) {
      errors = assocPath(
        [key, fieldName],
        // $FlowFixMe
        'Value must be greather than 0.',
        errors,
      );
    }
  });

  numberOfUnitsValues.forEach((fieldPath) => {
    const key = fieldPath[0];
    const fieldName = fieldPath[1];
    if (
      !isNil(values[key][fieldName]) &&
      !isEmpty(values[key][fieldName]) &&
      !validatePositiveNumber(values[key][fieldName])
    ) {
      errors = assocPath(
        [key, fieldName],
        // $FlowFixMe
        'Value must be greather than 0.',
        errors,
      );
    }
  });

  return errors;
};

export default validate;
