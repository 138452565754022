export const BEDROOM_OPTIONS = [
  {
    value: '0',
    text: 'Studio',
  },
  {
    value: '1',
    text: '1 Bedroom',
  },
  {
    value: '2',
    text: '2 Bedrooms',
  },
  {
    value: '3',
    text: '3 Bedrooms',
  },
  {
    value: '4',
    text: '4 Bedrooms',
  },
  {
    value: '5',
    text: '5 Bedrooms',
  },
];
