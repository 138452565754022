import React, { Component } from 'react';
import classnames from 'classnames';
import messages from '../messages';
import type { Application } from '../../../CreateApplication/types';
import TextWithMaxLengthOrDashes from '../../../../components/TextWithMaxLengthOrDashes';
import ClickToEditTextArea from './ClickToEditTextArea';
import { Col, Row } from 'react-bootstrap';
import { trim, isNil } from 'ramda';
type Props = {
  intl: Object,
  currentRecord: Application,
  handleNotesChange?: Function,
  maxLength: number,
  isResident?: boolean,
  prospectUpdateAllowed?: boolean
};

type ButtonProps = {
  editMode: boolean,
  text: string,
  handleClick: ?Function,
  intl: Object,
  isResident?: boolean,
  prospectUpdateAllowed?: boolean
};

type State = {
  editMode: boolean,
  notes: string
};

export const SaveEditButton = ({
  text,
  editMode,
  handleClick,
  intl,
  isResident,
  prospectUpdateAllowed
}: ButtonProps) => (
  <a
    disabled={!prospectUpdateAllowed}
    onClick={handleClick}
    className={`btn-text ignore-react-onclickoutside ${
      isResident || !prospectUpdateAllowed ? 'disabled' : ''
    }`}
  >
    <i
      className={classnames({
        'icon et-floppy': editMode,
        'icon et-pencil': !editMode
      })}
    />
    {editMode
      ? intl.formatMessage(messages.saveNote)
      : intl.formatMessage(messages.editNote)}
  </a>
);

class ApplicationNotes extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editMode: false,
      notes: this.props.currentRecord.notes
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.currentRecord.notes !== prevProps.currentRecord.notes) {
      this.setState({ notes: this.props.currentRecord.notes });
    }
  }

  toggleEditMode = () => {
    const { handleNotesChange } = this.props;
    this.state.editMode
      ? this.setState({ editMode: false }, () => {
          if (
            trim(this.props.currentRecord.notes) !== trim(this.state.notes) &&
            !isNil(handleNotesChange)
          ) {
            handleNotesChange(trim(this.state.notes));
          }
        })
      : this.setState({ editMode: true });
  };
  handleNotesChange = (event: any) =>
    this.setState({
      notes: event.target.value
    });
  render() {
    const {
      intl,
      handleNotesChange,
      isResident,
      maxLength,
      prospectUpdateAllowed
    } = this.props;

    return (
      <Row className="notes-container">
        <Col xs={6}>
          <h4>{intl.formatMessage(messages.applicationNotes)}:</h4>
        </Col>
        <Col xs={6} className="text-right">
          <SaveEditButton
            text={'Save Note'}
            handleClick={handleNotesChange ? this.toggleEditMode : null}
            editMode={this.state.editMode}
            intl={intl}
            isResident={isResident}
            prospectUpdateAllowed={prospectUpdateAllowed}
          />
        </Col>
        <Col xs={12}>
          {this.state.editMode ? (
            <ClickToEditTextArea
              handleClickOutside={this.toggleEditMode}
              handleChange={handleNotesChange ? this.handleNotesChange : null}
              text={this.state.notes}
            />
          ) : (
            <div
              className="note-content"
              onClick={prospectUpdateAllowed ? this.toggleEditMode : null}
            >
              <TextWithMaxLengthOrDashes
                data={this.state.notes}
                maxLength={maxLength}
              />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default ApplicationNotes;
