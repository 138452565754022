/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewProperty.AffordableSetup.Programs.LIHTC.Title',
    defaultMessage: 'LIHTC',
  },
  stateProjectIdXml: {
    id: 'App.ViewProperty.AffordableSetup.Programs.LIHTC.StateProjectIdXml',
    defaultMessage: 'State / Project ID (XML)*',
  },
  stateProjectIdTicHec: {
    id: 'App.ViewProperty.AffordableSetup.Programs.LIHTC.StateProjectIdTicHec',
    defaultMessage: 'State / Project ID (TIC/HEC)*',
  },
  initialCertForNonHUD: {
    id: 'App.ViewProperty.AffordableSetup.Programs.LIHTC.InitialCertForNonHUD',
    defaultMessage: 'Initial Certs for LIHTC and Other Programs',
  },
  initialCertForNonHUDTip: {
    id: 'App.ViewProperty.AffordableSetup.Programs.LIHTC.InitialCertForNonHUDTip',
    defaultMessage:
      'This feature will allow users to start and Initital Certification from the LIHTC and Other Programs Qualification tab for any households that do not have an Initial Cert record completed in Fortress.',
  },
  altXMLPropertyName: {
    id: 'App.ViewProperty.AffordableSetup.Programs.LIHTC.AltXMLPropertyName',
    defaultMessage: 'Affordable Property Name (XML)',
  },
  altXMLProjectName: {
    id: 'App.ViewProperty.AffordableSetup.Programs.LIHTC.AltXMLProjectName',
    defaultMessage: 'Affordable Project Name (XML)',
  },
  altXMLProjectNameTooltip: {
    id: 'App.ViewProperty.AffordableSetup.Programs.LIHTC.AltXMLProjectNameTooltip',
    defaultMessage:
      'To change the XML name of different sites within your project, navigate to the Manage Buildings screen.',
  },
  overrideQualification: {
    id: 'App.ViewProperty.AffordableSetup.Programs.LIHTC.OverrideQualification',
    defaultMessage: 'Override Qualification',
  },
  interimCerts: {
    id: 'App.ViewProperty.AffordableSetup.Programs.LIHTC.InterimCerts',
    defaultMessage: 'Interim Certs',
  },
});

export default messages;
