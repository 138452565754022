import { defineMessages } from 'react-intl';

const messages = defineMessages({
  applicationLabel: {
    id: 'App.ApprovedResident.ResidentsApplication',
    defaultMessage: 'Application',
  },
});

export default messages;
