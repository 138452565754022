import {
  put,
  takeLatest,
  call,
  all,
  take,
  cancel,
  cancelled,
  spawn,
  delay,
} from 'redux-saga/effects';
import {
  HANDLE_SOCKET_LOGOUT_EXPIRED_SESSION,
  LOGIN,
  LOGIN_SUCCESS,
  START_TOKEN_RENEWAL,
  START_USER_IDLE_DETECTION,
  RENEW_USER_TOKEN,
  USER_IS_ACTIVE,
  USER_IS_IDLE,
} from './constants';
import { LOG_OUT } from '../App/constants';
import {
  loginFail,
  clearMessages,
  loginSuccess,
  startTokenRenewal,
  startUserIdleDetection,
  tokenRenewalProcessStopped,
  renewUserToken,
  renewUserTokenSuccess,
  cancellingUserIdleProcess,
} from './actions';
import {
  updateCurrentUser,
  selectProperty,
  showExtendUserSessionModal,
  hideExtendUserSessionModal,
  logOut,
  socketDisconnect,
  socketReAuthenticate,
} from '../App/actions';
import UserService from '../../services/userService';
import type { Action } from '../App/types';
import type { LoginPayload } from './types';
import { push } from 'react-router-redux';
import { omit } from 'ramda';
import type { Saga } from 'redux-saga';
import { pathOr } from 'ramda';
import { setDatadogUser } from '../../utils/datadog';

export function* fetchLogin(action: Action<LoginPayload>): Saga<void> {
  try {
    const userService = new UserService();
    const { user, token, permissions } = yield userService.login(
      omit(['rememberMe'], action.payload),
    );
    setDatadogUser(user);
    localStorage.setItem('session_id', token);
    yield put(updateCurrentUser(user, permissions));
    if (user.properties.length === 1) {
      yield put(selectProperty(user.properties[0]));
    }
    yield put(loginSuccess());
    yield put(push('/'));
  } catch (err) {
    yield put(loginFail(err));
    yield delay(5000);
    yield put(clearMessages());
  }
}

export function* tokenRenewal(): Saga<void> {
  const userService = new UserService();

  const env = process.env;
  const getMinutes = () =>
    // $FlowFixMe
    pathOr(1, ['REACT_APP_TIME_MINUTES_RENEW_TOKEN'], env);

  const convertTimeToMilliseconds = 60000 * getMinutes();
  try {
    while (true) {
      yield delay(convertTimeToMilliseconds);
      const oldToken = localStorage.getItem('session_id');
      const newToken = yield call(userService.extendUserSession, oldToken);
      yield put(renewUserToken(newToken.token));
    }
  } finally {
    if (yield cancelled()) yield put(tokenRenewalProcessStopped());
  }
}

export function* renewerUserTokenSaga(action: any): Saga<void> {
  localStorage.setItem('session_id', action.payload);
  yield put(socketReAuthenticate(action.payload));
  yield put(renewUserTokenSuccess());
}

export function* showUserIdleModal(minutes: number): Saga<void> {
  try {
    const aMinute = 60000;
    const timeToShowModal = minutes - aMinute;

    yield delay(timeToShowModal);
    yield put(showExtendUserSessionModal());
    yield delay(aMinute);
  } finally {
    if (yield cancelled()) {
      yield put(hideExtendUserSessionModal());
    }
  }
}

export function* logOutUserIdle(minutes: number): Saga<void> {
  try {
    yield delay(minutes);
    yield put(socketDisconnect());
    yield put(loginFail('You have been logged out due to inactivity'));
    yield put(logOut());
  } finally {
    if (yield cancelled()) {
      yield put(cancellingUserIdleProcess());
    }
  }
}

export function* userIdleProcess(): Saga<void> {
  const env = process.env;
  // $FlowFixMe
  const getMinutes = () => pathOr(1, ['REACT_APP_TIME_MINUTES_USER_IDLE'], env);
  const minutes = 60000 * getMinutes();

  while (true) {
    yield take(USER_IS_IDLE);

    const tasks = yield all([
      spawn(showUserIdleModal, [minutes]),
      spawn(logOutUserIdle, [minutes]),
    ]);

    yield take([LOG_OUT, USER_IS_ACTIVE]);

    yield all(tasks.map((task) => cancel(task)));
  }
}

export function* userIdleBackgroundProcess(): Saga<void> {
  yield takeLatest(START_USER_IDLE_DETECTION, userIdleProcess);
}

export function* tokenBackgroundRenewallProcess(): Saga<void> {
  while (true) {
    yield take(START_TOKEN_RENEWAL);

    const task = yield spawn(tokenRenewal);
    yield take(LOG_OUT);

    yield cancel(task);
  }
}

export function* handleExpiredSocket(): Saga<void> {
  yield put(socketDisconnect());
  yield put(
    loginFail('You have been logged out due to to an inactive session'),
  );
  yield put(logOut());
}

export function* expiredSocketSessionSaga(): Saga<void> {
  yield takeLatest(HANDLE_SOCKET_LOGOUT_EXPIRED_SESSION, handleExpiredSocket);
}

export function* forkMaintenanceProcess(): Saga<void> {
  yield all([put(startTokenRenewal()), put(startUserIdleDetection())]);
}

export function* userMaintenanceSaga(): Saga<void> {
  yield takeLatest(LOGIN_SUCCESS, forkMaintenanceProcess);
}

export function* loginSaga(): Saga<void> {
  yield takeLatest(LOGIN, fetchLogin);
}

export function* userTokenWatcherSaga(): Saga<void> {
  yield takeLatest(RENEW_USER_TOKEN, renewerUserTokenSaga);
}

export default [
  expiredSocketSessionSaga,
  loginSaga,
  userMaintenanceSaga,
  tokenBackgroundRenewallProcess,
  userTokenWatcherSaga,
  userIdleBackgroundProcess,
  userIdleProcess,
];
