// @flow
import { assoc } from 'ramda';
import request, { apiURL } from '../utils/api';

type PTC = {
  id: string,
  transactionCode: {
    id: string,
    code: string,
  },
};

type LedgerCommon = {
  id: string,
  transactionAmount: number,
  transactionDate: Date,
  transactionNote?: string,
  documentNumber: string,
};

export type CustomerLedger = LedgerCommon & {
  unit?: { id: string, number: string },
  customerStatus: string,
  propertyTransactionCode: PTC,
  isManual: boolean,
  propertyFiscalPeriod: { period: string },
};

type SecurityLedger = CustomerLedger & {};

type OpsLedger = CustomerLedger & {
  subjournal: { id: string, description: string },
};

export type MiscLedger = LedgerCommon & {
  ptcCharge: PTC,
  ptcPayment: PTC,
  propertyFiscalPeriods: { period: string },
};

type PaymentCommon = {
  id: string,
  readableId: number,
  propertyId: string,
  organizationId: string,
  receivedFrom: string,
  financiallyResponsibleLeaseSignersInHousehold: string,
  paymentDepositStatus: string,
  depositBatchName?: string,
  depositBatchDetailId?: string,
  transactionStatus: string,
  reversedPaymentId?: string,
  treatAsNegative: boolean,
};

type OpsPayment = PaymentCommon & {
  paymentType: 'Ops',
  customerOpsLedgerId: string,
  customerOpsLedger: OpsLedger,
};

type SecurityPayment = PaymentCommon & {
  paymentType: 'Security',
  customerSecurityLedgerId: string,
  customerSecurityLedger: SecurityLedger,
};

type MiscPayment = PaymentCommon & {
  paymentType: 'Misc',
  propertyMiscIncomeLedgerId: string,
  propertyMiscIncomeLedger: MiscLedger,
};

export type PaymentSingleTransaction =
  | OpsPayment
  | SecurityPayment
  | MiscPayment;

class PaymentSingleTransactionService {
  async getAllUndepositedForProperty(
    propertyId: string,
    organizationId: string,
  ): Promise<PaymentSingleTransaction[]> {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const payments = await request(
      `${apiURL}/${organizationId}/${propertyId}/payment-single-transactions/undeposited`,
      options,
    );
    return payments.map((p) => {
      if (p.customerOpsLedger) return assoc('paymentType', 'Ops', p);
      if (p.customerSecurityLedger) return assoc('paymentType', 'Security', p);
      if (p.propertyMiscIncomeLedger) return assoc('paymentType', 'Misc', p);
      throw new Error('Invalid payment.');
    });
  }
}

export default PaymentSingleTransactionService;
