import { Link } from 'react-router-dom';
import * as R from 'ramda';
import styled from 'styled-components';

type Props = {
  text: string,
  iconClass: string,
  to?: string,
  onClick?: Function,
  iconFontSize?: number,
  color?: string,
  disabledColor?: string,
};

const Wrapper = styled.span`
  & a:hover,
  & a:focus {
    text-decoration: none;
  }
  & a {
    color: ${R.propOr('#4D73FF', 'linkColor')};
  }
  & a:disabled {
    color: ${R.propOr('gray', 'linkDisabledColor')};
  }
  display: inline-block;
`;

function IconLink({
  to,
  onClick,
  text,
  iconClass,
  iconFontSize = 17,
  color,
  disabledColor,
  ...otherProps
}: Props) {
  const labelId = `icon-link--${text}`;

  const linkContent = (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <i
        className={iconClass}
        style={{ marginRight: 5, fontSize: iconFontSize }}
      />{' '}
      <span id={labelId}>{text}</span>
    </span>
  );
  return (
    <Wrapper linkColor={color} linkDisabledColor={disabledColor}>
      {R.isNil(to) ? (
        <a
          onClick={onClick}
          role="button"
          aria-labelledby={labelId}
          {...otherProps}
        >
          {linkContent}
        </a>
      ) : (
        <Link {...otherProps} to={to}>
          {linkContent}
        </Link>
      )}
    </Wrapper>
  );
}

export default IconLink;
