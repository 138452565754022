import React, { useState, useEffect } from 'react';
import messages from '../messages';
import ConfirmModal from '../../../../components/ConfirmModal';
import { FormattedMessage } from 'react-intl';
import {
  Divider,
  Switch,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { Button } from 'react-bootstrap';
import { WORK_NUMBER_AFFORDABLE_SWITCH_NAME } from './constants';
import SetupGridItem from '../../SetupGridItem';
type Props = {
  orgIntegrationDetails: Object,
  propIntegrationDetails: Object,
  editMode: boolean,
  onChange: Function,
};

const TheWorkNumberSetup = (props: Props) => {
  const { editMode, onChange, orgIntegrationDetails, propIntegrationDetails } =
    props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isTheWorkNumberActive, setIsWorkNumberActive] = useState(false);

  useEffect(() => {
    setIsWorkNumberActive(orgIntegrationDetails.isActive);
    if (orgIntegrationDetails.isActiveForAllProperties) {
      // Org has it set to all true
      setIsWorkNumberActive(orgIntegrationDetails.isActiveForAllProperties);
      // TODO: disable toggle
      setDisabled(true);
    } else {
      if (propIntegrationDetails) {
        // If the property integration exists
        setIsWorkNumberActive(propIntegrationDetails.isActive);
      } else {
        setIsWorkNumberActive(false);
      }
    }
  }, [orgIntegrationDetails, propIntegrationDetails]);

  const { integrationId } = orgIntegrationDetails;
  const onToggleChange = (evt, value) => {
    const switchValue = evt.target.checked;
    const evtForOnChange = {
      target: {
        name: WORK_NUMBER_AFFORDABLE_SWITCH_NAME,
        value: {
          integrationId,
          value: switchValue,
        },
      },
    };
    // If switch is off show confirmation to toggle on
    if (!isTheWorkNumberActive) {
      setShowConfirm(true);
      setIsWorkNumberActive(true);
      onChange(evtForOnChange);
    } else {
      // set isWorkNumberActive to false
      setIsWorkNumberActive(false);
      onChange(evtForOnChange);
    }
  };

  const onAccept = () => {
    // Accepted so close
    setShowConfirm(false);
  };

  const onCancel = () => {
    // Set the work number off since it was cancelled
    setIsWorkNumberActive(false);
    const evtForOnChange = {
      target: {
        name: WORK_NUMBER_AFFORDABLE_SWITCH_NAME,
        value: {
          integrationId,
          value: false,
        },
      },
    };
    onChange(evtForOnChange);
    // Hide confirmation
    setShowConfirm(false);
  };

  const onHide = () => {
    // Set the work number off since it was cancelled
    setIsWorkNumberActive(false);
    // Hide confirmation
    setShowConfirm(false);
  };

  const modalHeader = (
    <h1>
      <FormattedMessage {...messages.confirmTitleTWN} />
    </h1>
  );

  const modalFooter = (
    <div className="row">
      <div className="col-sm-6">
        <Button
          type="button"
          bsStyle="default"
          className="pull-right"
          onClick={onCancel}
        >
          <FormattedMessage {...messages.cancel} />
        </Button>
      </div>
      <div className="col-sm-6">
        <Button
          type="submit"
          bsStyle="primary"
          className="pull-left"
          onClick={onAccept}
        >
          <FormattedMessage {...messages.accept} />
        </Button>
      </div>
    </div>
  );

  const modalBody = (
    <div className="row" style={{ textAlign: 'center' }}>
      <p style={{ margin: '10px 85px 3px 85px' }}>
        <strong>
          <FormattedMessage {...messages.workNumberDescription} />
        </strong>
      </p>
      <p style={{ margin: '2px 50px' }}>
        <FormattedMessage {...messages.workNumberDescriptionStepOne} />
      </p>
      <p style={{ margin: '2px 40px' }}>
        <FormattedMessage {...messages.workNumberDescriptionStepTwo} />
      </p>
      <p style={{ margin: '10px 3px' }}>
        <strong>
          <FormattedMessage {...messages.note} />
        </strong>
        &nbsp;
        <FormattedMessage {...messages.workNumberDescriptionNote} />
      </p>
    </div>
  );

  const infoTipMessage = (
    <>
      <p style={{ fontSize: '12px', textAlign: 'center' }}>
        <strong>
          <FormattedMessage {...messages.workNumberDescription} />
        </strong>
        <br />
        <FormattedMessage {...messages.workNumberDescriptionStepOne} />
        <br />
        <FormattedMessage {...messages.workNumberDescriptionStepTwo} />
        <br />
        <strong>
          <FormattedMessage {...messages.note} />
          &nbsp;
        </strong>
        <FormattedMessage {...messages.workNumberDescriptionNote} />
      </p>
    </>
  );

  return (
    <>
      <ConfirmModal
        show={showConfirm}
        header={modalHeader}
        footer={modalFooter}
        onHide={onHide}
      >
        {modalBody}
      </ConfirmModal>
      <>
        <SetupGridItem sm={12}>
          <Divider sx={{ marginY: 2 }} />

          <Typography variant={'h3'}>
            <FormattedMessage {...messages.workNumber} />
          </Typography>
        </SetupGridItem>

        <SetupGridItem
          label={<FormattedMessage {...messages.workNumberAffordableUnits} />}
          TooltipProps={{ title: infoTipMessage, children: <AlertInfoIcon /> }}
        >
          {editMode ? (
            <Switch
              name={WORK_NUMBER_AFFORDABLE_SWITCH_NAME}
              id="work-number-switch"
              checked={isTheWorkNumberActive}
              disabled={disabled}
              onChange={onToggleChange}
              // For automated testing
              inputProps={{ 'data-checked': isTheWorkNumberActive }}
            />
          ) : (
            <Typography
              color={isTheWorkNumberActive ? 'primary' : 'action.disabled'}
            >
              {isTheWorkNumberActive ? (
                <FormattedMessage {...messages.on} />
              ) : (
                <FormattedMessage {...messages.off} />
              )}
            </Typography>
          )}
        </SetupGridItem>
      </>
    </>
  );
};

export default TheWorkNumberSetup;
