import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import type { State } from '../types';

type Props = {
  name: string,
  states: State[],
  value: string,
  onChange: Function,
  maxWidth: string,
  custom: any[],
};

const StyledSelect = styled.select`
  max-width: ${(props) => props.maxWidth};
  padding: 4px 2px
  display: inline;
  margin: 0 0.375%;
`;

export const StateSelect = ({
  name,
  states,
  value,
  onChange,
  maxWidth = '10%',
  ...custom
}: Props): FunctionComponent<Props> => (
  <StyledSelect
    name={name}
    className="form-control"
    value={value}
    onChange={onChange}
    maxWidth={maxWidth}
    {...custom}
  >
    {states.map((state) => (
      <option key={`states-${state.id}`} value={state.id}>
        {state.code}
      </option>
    ))}
  </StyledSelect>
);
