import { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import { pathOr, isNil, sortBy, prop, pickBy } from 'ramda';
import type {
  ChangeEventHandler,
  ChangePayload,
  FilterValues,
} from './FilterControls/types';
import * as utils from './utils';
import * as constants from './constants';

import UnitService from '../../services/unitService';
import PropertySpecialsService from '../../services/propertySpecialsService';

type UseUnitAmenityHeadersPayload = {
  unitRows: Object[],
};

export const DEFAULT_FILTER_VALUES = {
  floorPlan: [],
  floorPlanType: [],
  unitStatus: [],
  bedrooms: [],
  bathrooms: [],
};

export const useRentRoll = (
  selectedProperty: Object,
  promptToaster: Function,
) => {
  const [rows, setRows] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    const fetchRentRoll = async () => {
      try {
        const { id, organizationId } = selectedProperty;
        const unitService = new UnitService();
        const results = await unitService.getRentRoll(
          id,
          organizationId,
          options,
        );
        setRows(results.units);
        setLoaded(true);
      } catch (e) {
        promptToaster({
          type: 'error',
          title: 'Error Loading Rent Roll',
          message: e.toString(),
        });
      }
    };

    fetchRentRoll();

    return () => abortController.abort();
  }, [selectedProperty, promptToaster]);

  return [rows, loaded];
};

export const usePropertySpecials = (
  selectedProperty: Object,
  promptToaster: Function,
  refreshSpecials: boolean,
) => {
  const [specials, setSpecials] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    const fetchPropertySpecials = async () => {
      try {
        const { id } = selectedProperty;
        const propertySpecialsService = new PropertySpecialsService();
        const response = await propertySpecialsService.getPropertySpecials(
          id,
          options,
        );

        setSpecials(response);
        setLoaded(true);
      } catch (e) {
        promptToaster({
          type: 'error',
          title: 'Error loading property specials',
          message: e.toString(),
        });
      }
    };

    fetchPropertySpecials();

    return () => abortController.abort();
  }, [selectedProperty, promptToaster, refreshSpecials]);

  return [specials, loaded];
};

export const usePropertySpecialsSave = (
  selectedProperty: Object,
  promptToaster: Function,
  specials: Array<Object>,
) => {
  const handleSaveSpecial = async (payload: Object) => {
    const description = pathOr('', ['values', 'specials'], payload);
    // $FlowFixMe
    const payloadId = pathOr(null, ['0', 'id'], specials);
    try {
      const propertySpecialsService = new PropertySpecialsService();
      if (payloadId) {
        await propertySpecialsService.updatePropertySpecial(
          selectedProperty.organizationId,
          selectedProperty.id,
          payloadId,
          {
            endDate: moment().toDate(),
          },
        );
      }
      const response = await propertySpecialsService.createPropertySpecial(
        selectedProperty.organizationId,
        selectedProperty.id,
        {
          startDate: moment().toDate(),
          description,
          organizationId: selectedProperty.organizationId,
          propertyId: selectedProperty.id,
        },
      );

      promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Special saved.',
      });
      return response;
    } catch (e) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: e.toString(),
      });
    }
  };

  return [handleSaveSpecial];
};

const DEFAULT_OPTIONS_VALUE = {
  floorPlanOptions: [],
  floorPlanTypeOptions: [],
  unitStatusOptions: [],
  bedroomOptions: [],
  bathroomOptions: [],
};

const sortOptions = sortBy(prop('text'));

const getNewOptions = (
  currentOptions: Object[],
  unitValue: string | number,
  formatLabel?: (val: string | number) => string,
) => {
  const valueIsInvalid =
    isNil(unitValue) || unitValue === '---' || unitValue === '';

  if (valueIsInvalid) return currentOptions;

  const valueIsAlreadyIncluded =
    currentOptions.find((o) => o.value === unitValue) !== undefined;

  if (valueIsAlreadyIncluded) return currentOptions;

  return sortOptions(
    currentOptions.concat([
      {
        value: unitValue,
        text: formatLabel?.(unitValue) || unitValue,
      },
    ]),
  );
};

const formatBedroomLabel = (count: number) =>
  count === 1 ? '1 bed' : `${count} beds`;
const formatBathroomLabel = (count: number) =>
  count === 1 ? '1 bath' : `${count} baths`;

export const useFilterControls = (units: Object[]) => {
  const [filterValues, setFilterValues] = useState(DEFAULT_FILTER_VALUES);
  const [options, setOptions] = useState(DEFAULT_OPTIONS_VALUE);

  useEffect(() => {
    setOptions(
      units.reduce((_options: Object, unit: Object) => {
        return {
          floorPlanOptions: getNewOptions(
            _options.floorPlanOptions,
            unit.floorPlan,
          ),
          floorPlanTypeOptions: getNewOptions(
            _options.floorPlanTypeOptions,
            unit.floorPlanType,
          ),
          unitStatusOptions: getNewOptions(
            _options.unitStatusOptions,
            unit.status,
          ),
          bedroomOptions: getNewOptions(
            _options.bedroomOptions,
            unit.nBeds,
            formatBedroomLabel,
          ),
          bathroomOptions: getNewOptions(
            _options.bathroomOptions,
            unit.nBaths,
            formatBathroomLabel,
          ),
        };
      }, DEFAULT_OPTIONS_VALUE),
    );
  }, [units]);

  const handleFilterControlsChange: ChangeEventHandler = (
    key: string,
    payload: ChangePayload,
  ) => {
    if (payload.type === 'multi-select') {
      setFilterValues({
        ...filterValues,
        [key]: payload.values,
      });
    }
    if (payload.type === 'date') {
      setFilterValues({
        ...filterValues,
        [key]: payload.value,
      });
    }
  };

  const clearAllFilterValues = () => {
    setFilterValues(DEFAULT_FILTER_VALUES);
  };

  const clearMoveInDateRange = () => {
    setFilterValues({
      ...filterValues,
      moveInDateFrom: undefined,
      moveInDateTo: undefined,
    });
  };

  return {
    filterValues,
    options,
    handleFilterControlsChange,
    clearAllFilterValues,
    clearMoveInDateRange,
  };
};

export const useAppliedFilters = (
  filterValues: FilterValues,
  handleFilterControlsChange: ChangeEventHandler,
  clearAllFilterValues: Function,
  clearMoveInDateRange: Function,
) => {
  const appliedFilters = useMemo(
    () => utils.mapFilterValuesToAppliedFilters(filterValues),
    [filterValues],
  );

  const handleRemoveFilter = ({ meta }) => {
    if (meta.isMoveInDateRangeFilter) {
      clearMoveInDateRange();
      return;
    }

    const { key, value } = meta;

    if (!filterValues[key]) return;

    const newFilterValues = filterValues[key].filter((val) => val !== value);

    handleFilterControlsChange(key, {
      type: 'multi-select',
      values: newFilterValues,
    });
  };

  const handleClearAll = () => {
    clearAllFilterValues();
  };

  return {
    appliedFilters,
    handleRemoveFilter,
    handleClearAll,
  };
};

export const useUnitAmenityHeaders = ({
  unitRows,
}: UseUnitAmenityHeadersPayload) => {
  return useMemo(
    () =>
      unitRows.reduce((accHeaders, curRow) => {
        const amenities = pickBy(
          (val, key) => key.startsWith(constants.AMENITY_DETAILS_PREFIX),
          curRow,
        );
        const newAmenities = Object.keys(amenities).filter(
          (feeName) => accHeaders.find((h) => h.id === feeName) === undefined,
        );
        const newHeaders = newAmenities.map((a) => ({
          id: a,
          title: `${a.replace(
            constants.AMENITY_DETAILS_PREFIX,
            '',
          )} Unit Amenity`,
          type: 'moneyZeroNull',
        }));
        return [...accHeaders, ...newHeaders];
      }, []),
    [unitRows],
  );
};
