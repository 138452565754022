import { put, takeLatest, select, throttle } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import * as R from 'ramda';

import {
  GET_ALL_USER_STATUSES,
  GET_ALL_USER_TITLES,
  GET_ALL_USER_ROLES,
  GET_ALL_PROPERTIES,
  CREATE_USER,
} from './constants';
import {
  getAllUserStatusesSuccess,
  getAllUserStatusesFail,
  getAllUserTitlesSuccess,
  getAllUserTitlesFail,
  getAllUserRolesSuccess,
  getAllUserRolesFail,
  getAllPropertiesSuccess,
  getAllPropertiesFail,
  createUserSuccess,
  createUserFail,
} from './actions';
import UserService from '../../services/userService';
import { selectCurrentUserOrganizationId } from '../App/selectors';
import type { Action, User } from '../App/types';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import messages from './messages';
import type { Saga } from 'redux-saga';

const sortedByName = R.sortBy(R.prop('name'));

export function* fetchGetAllUserStatuses(): Saga<void> {
  try {
    const userService = new UserService();
    const response = yield userService.getAllUserStatuses();
    yield put(getAllUserStatusesSuccess(sortedByName(response)));
  } catch (err) {
    yield put(getAllUserStatusesFail(err));
  }
}

export function* getAllUserStatusesSaga(): Saga<void> {
  yield takeLatest(GET_ALL_USER_STATUSES, fetchGetAllUserStatuses);
}

export function* fetchGetAllUserTitles(): Saga<void> {
  try {
    const userService = new UserService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const response = yield userService.getAllUserTitles(organizationId);
    yield put(getAllUserTitlesSuccess(sortedByName(response)));
  } catch (err) {
    yield put(getAllUserTitlesFail(err));
  }
}

export function* getAllUserTitlesSaga(): Saga<void> {
  yield takeLatest(GET_ALL_USER_TITLES, fetchGetAllUserTitles);
}

export function* fetchGetAllUserRoles(): Saga<void> {
  try {
    const userService = new UserService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const response = yield userService.getAllUserRoles(organizationId);
    yield put(getAllUserRolesSuccess(sortedByName(response)));
  } catch (err) {
    yield put(getAllUserRolesFail(err));
  }
}

export function* getAllUserRolesSaga(): Saga<void> {
  yield takeLatest(GET_ALL_USER_ROLES, fetchGetAllUserRoles);
}

export function* fetchGetAllProperties(): Saga<void> {
  try {
    const userService = new UserService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const response = yield userService.getAllProperties(organizationId);
    yield put(getAllPropertiesSuccess(response));
  } catch (err) {
    yield put(getAllPropertiesFail(err));
  }
}

export function* getAllPropertiesSaga(): Saga<void> {
  yield takeLatest(GET_ALL_PROPERTIES, fetchGetAllProperties);
}

export function* postCreateUser(action: Action<User>): Saga<void> {
  try {
    const userService = new UserService();
    yield userService.createUser(action.payload);
    yield put(push('/manage-users'));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(createUserSuccess());
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(createUserFail(err));
  }
}

export function* createUserSaga(): Saga<void> {
  yield throttle(500, CREATE_USER, postCreateUser);
}

export default [
  getAllUserStatusesSaga,
  getAllUserTitlesSaga,
  getAllUserRolesSaga,
  getAllPropertiesSaga,
  createUserSaga,
];
