import { defineMessages } from 'react-intl';

const messages = defineMessages({
  overview: {
    id: 'App.Overview.KPI.Overview',
    defaultMessage: 'Overview',
  },
  concessions: {
    id: 'App.Overview.KPI.Concessions',
    defaultMessage: 'Concessions',
  },
  workOrders: {
    id: 'App.Overview.KPI.WorkOrders',
    defaultMessage: 'Work Orders',
  },
  mtdConcesions: {
    id: 'App.Overview.KPI.MTDConcessions',
    defaultMessage: 'MTD Concessions',
  },
  ptdConcesions: {
    id: 'App.Overview.KPI.PTDConcessions',
    defaultMessage: 'PTD Concessions',
  },
  openWorkOrders: {
    id: 'App.Overview.KPI.AverageOpenWorkOrders',
    defaultMessage: 'Open',
  },
});

export default messages;
