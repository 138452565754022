import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';

import { renderDateField } from '../../utils/redux-form-helper';

import validate from './validate';

type Props = {
  show: boolean,
  dismiss: Function,
  handleSubmit: Function,
  onSubmit: Function,
  pristine: boolean,
  valid: boolean,
  reset: Function,
  dirty: boolean,
  initialValues: Object,
};

const EditPropertyMoveInDateModal = (props: Props) => {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const cancelConfirmationToggle = (visible: boolean) => {
    setShowCancelConfirmation(visible);
  };

  const onClose = () => {
    const { dirty, reset, dismiss } = props;

    if (dirty && !showCancelConfirmation) {
      cancelConfirmationToggle(true);
      return;
    } else if (dirty && showCancelConfirmation) {
      reset();
    }

    cancelConfirmationToggle(false);
    dismiss();
  };

  const renderModalHeader = () => {
    return (
      <Modal.Header closeButton>
        <i className="icon et-pencil" />
        <Modal.Title componentClass="h1">
          Edit Property Move-In Date
        </Modal.Title>
      </Modal.Header>
    );
  };

  const renderModalFooter = () => {
    const { pristine, valid, handleSubmit } = props;
    const submitEnabled = pristine || (!pristine && valid);

    return (
      <Modal.Footer className={showCancelConfirmation ? 'disabled' : ''}>
        <Row>
          <Col xs={6}>
            <Button bsStyle="default" className="pull-right" onClick={onClose}>
              Cancel
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              bsStyle="primary"
              className="pull-left"
              type="submit"
              onClick={handleSubmit}
              disabled={!submitEnabled}
            >
              Save Changes
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    );
  };

  const renderModalFormBody = () => {
    return (
      <Row className="scrollable-modal__content">
        <div className="container-fluid">
          <Row>
            <Col md={5} xs={12}>
              <label>Property Move-In Date</label>
            </Col>
            <Col md={7} xs={12}>
              <Field
                name="editPropertyMoveInDate"
                component={renderDateField}
                bsSize="md"
              />
            </Col>
          </Row>
        </div>
      </Row>
    );
  };

  const renderModalCancelBody = () => {
    return (
      <div className="modal-confirm">
        <Row>
          <Col xs={12}>
            <h1>Are you sure you want to leave without saving?</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Button bsStyle="primary" className="pull-right" onClick={onClose}>
              Yes
            </Button>
          </Col>
          <Col xs={12} sm={6}>
            <Button
              bsStyle="default"
              className="pull-left"
              onClick={() => cancelConfirmationToggle(false)}
            >
              No
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  const { show } = props;
  return (
    <form>
      <Modal
        className="edit-move-in-date"
        bsSize="sm"
        backdrop
        show={show}
        onHide={() => onClose()}
      >
        {renderModalHeader()}
        <Modal.Body>
          {renderModalFormBody()}
          {showCancelConfirmation && renderModalCancelBody()}
        </Modal.Body>
        {renderModalFooter()}
      </Modal>
    </form>
  );
};

export const mapStateToProps = (
  { form }: Object,
  { initialValues }: Object,
): Object => {
  return {
    initialValues,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'editPropertyMoveInDateForm',
    enableReinitialize: true,
    validate,
  })(EditPropertyMoveInDateModal),
);
