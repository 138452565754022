import { useMutation, MutationOptions } from 'react-query';
import PropertySpecialsService from '../../services/propertySpecialsService';
import useAbortController from '../../hooks/useAbortController';
import moment from 'moment/moment';

const usePropertySpecialsMutation = ({
  propertySpecials,
  organizationId,
  propertyId,
  options,
}: {
  organizationId: string,
  propertyId: string,
  options?: MutationOptions,
}) => {
  const abortControllerOptions = useAbortController();

  return useMutation(async (description) => {
    const payloadId = propertySpecials[0]?.id;
    const propertySpecialsService = new PropertySpecialsService();

    if (payloadId) {
      await propertySpecialsService.updatePropertySpecial(
        organizationId,
        propertyId,
        payloadId,
        {
          endDate: moment().toDate(),
        },
        abortControllerOptions,
      );
    }
    return propertySpecialsService.createPropertySpecial(
      organizationId,
      propertyId,
      {
        startDate: moment().toDate(),
        description,
        organizationId,
        propertyId,
      },
      abortControllerOptions,
    );
  }, options);
};

export default usePropertySpecialsMutation;
