import { put, takeLatest, select, throttle } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import { refreshLedger } from '../../containers/Ledger/actions';

import {
  CREATE_TRANSACTION,
  GET_TRANSACTION_TYPES,
  GET_PROPERTY_SUBJOURNAL_TYPES,
  GET_PROPERTY_TRANSACTION_CODES,
  HANDLE_SOCKET_TRANSACTION_SUCCESS,
  HANDLE_SOCKET_TRANSACTION_FAILURE,
} from './constants';
import type { Action, Property } from '../../containers/App/types';
import TransactionsService from '../../services/transactionsService';
import TransactionTypesService from '../../services/transactionTypesService';
import PropertyTransactionCodes from '../../services/propertyTransactionCodesService';
import PropertySubjournalTypes from '../../services/propertySubjournalTypesService';
import {
  createTransactionSuccess,
  createTransactionFailure,
  getTransactionTypesFailure,
  getTransactionTypesSuccess,
  getPropertyTransactionCodesFailure,
  getPropertyTransactionCodesSuccess,
  getPropertySubjournalTypesFailure,
  getPropertySubjournalTypesSuccess,
} from './actions';
import { getOneResident } from '../../containers/ResidentProfile/actions.js';
import { getAllSubjournals } from '../../containers/Ledger/actions.js';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
  selectCurrentLocationPath,
} from '../../containers/App/selectors';
import messages from './messages';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchCreateTransaction(action: Action<any>): Saga<void> {
  try {
    const { filterReversed, residentId, transaction } = action.payload;
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const propertyId = selectedProperty.id;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const transactionService = new TransactionsService();
    const currentRoutePath = yield select(selectCurrentLocationPath);
    const notificationAttributes = {
      currentRoutePath,
      filterReversed,
      residentId,
    };
    const tx = yield transactionService.create(
      organizationId,
      propertyId,
      transaction,
      notificationAttributes,
    );
    if (tx && !tx.messageId) {
      // If queued, will return a messageId.
      yield put(refreshLedger(transaction.prospectCustomerId, filterReversed));
      yield put(
        toastrActions.add({
          type: 'success',
          message: renderTranslatedMessage(
            messages.createTransactionSuccessDescription,
          ),
          title: renderTranslatedMessage(
            messages.createTransactionSuccessHeader,
          ),
          options: {
            showCloseButton: true,
            removeOnHover: true,
          },
        }),
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'info',
          message: renderTranslatedMessage(
            messages.createTransactionPendingDescription,
          ),
          title: renderTranslatedMessage(
            messages.createTransactionPendingHeader,
          ),
          options: {
            showCloseButton: true,
            removeOnHover: true,
          },
        }),
      );
    }
    yield put(createTransactionSuccess(transaction.prospectCustomerId));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.createTransactionFailureHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(createTransactionFailure(err));
  }
}

export function* handleSocketTransactionFailure(
  action: Action<any>,
): Saga<void> {
  const error = action.payload;
  yield put(
    toastrActions.add({
      type: 'error',
      message: error,
      title: renderTranslatedMessage(messages.createTransactionFailureHeader),
      options: {
        showCloseButton: true,
        removeOnHover: true,
      },
    }),
  );
  yield put(createTransactionFailure({ error }));
}

export function* handleSocketTransactionSuccess(
  action: Action<any>,
): Saga<void> {
  const {
    currentRoutePath: notificationRoutePath,
    filterReversed,
    residentId,
    prospectCustomerId,
    text,
  } = action.payload;
  const currentRoutePath = yield select(selectCurrentLocationPath);
  const txtContent =
    text ||
    renderTranslatedMessage(messages.createTransactionSuccessDescription);
  yield put(
    toastrActions.add({
      type: 'success',
      message: txtContent,
      title: renderTranslatedMessage(messages.createTransactionSuccessHeader),
      options: {
        showCloseButton: true,
        removeOnHover: true,
        closeOnToastrClick: true,
        progressBar: true,
        timeOut: txtContent.length > 150 ? 10000 : 5000, // Default 5000
      },
    }),
  );
  // Only update UI if on current Applicant or Resident profile
  if (currentRoutePath === notificationRoutePath) {
    yield put(refreshLedger(prospectCustomerId, filterReversed));
    yield put(getAllSubjournals(prospectCustomerId));
    if (residentId) {
      yield put(getOneResident(residentId));
    }
  }
}

export function* createTransactionSaga(): Saga<void> {
  yield throttle(500, CREATE_TRANSACTION, fetchCreateTransaction);
}

export function* handleSocketTransactionFailureSaga(): Saga<void> {
  yield throttle(
    500,
    HANDLE_SOCKET_TRANSACTION_FAILURE,
    handleSocketTransactionFailure,
  );
}

export function* handleSocketTransactionSuccessSaga(): Saga<void> {
  yield throttle(
    500,
    HANDLE_SOCKET_TRANSACTION_SUCCESS,
    handleSocketTransactionSuccess,
  );
}

export function* fetchTransactionTypes(): Saga<void> {
  try {
    const transactionTypesService = new TransactionTypesService();
    const transactionTypes =
      yield transactionTypesService.getTransactionTypes();
    yield put(getTransactionTypesSuccess(transactionTypes));
  } catch (err) {
    yield put(getTransactionTypesFailure(err));
  }
}

export function* getTransactionTypesSaga(): Saga<void> {
  yield takeLatest(GET_TRANSACTION_TYPES, fetchTransactionTypes);
}

export function* fetchPropertyTransactionCodes(): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const propertyId = selectedProperty.id;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const propertyTransactionCodesService = new PropertyTransactionCodes();
    const propertyTransactionCodes =
      yield propertyTransactionCodesService.getPropertyTransactionCodes(
        organizationId,
        propertyId,
      );
    yield put(getPropertyTransactionCodesSuccess(propertyTransactionCodes));
  } catch (err) {
    yield put(getPropertyTransactionCodesFailure(err));
  }
}

export function* getPropertyTransactionCodesSaga(): Saga<void> {
  yield takeLatest(
    GET_PROPERTY_TRANSACTION_CODES,
    fetchPropertyTransactionCodes,
  );
}

export function* fetchPropertySubjournalTypes(): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const propertyId = selectedProperty.id;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const getPropertySubjournalTypesService = new PropertySubjournalTypes();
    const propertySubjournalTypes =
      yield getPropertySubjournalTypesService.getPropertySubjournalTypes(
        organizationId,
        propertyId,
      );
    yield put(getPropertySubjournalTypesSuccess(propertySubjournalTypes));
  } catch (err) {
    yield put(getPropertySubjournalTypesFailure(err));
  }
}

export function* getPropertySubjournalTypesSaga(): Saga<void> {
  yield takeLatest(GET_PROPERTY_SUBJOURNAL_TYPES, fetchPropertySubjournalTypes);
}

export default [
  createTransactionSaga,
  handleSocketTransactionFailureSaga,
  handleSocketTransactionSuccessSaga,
  getTransactionTypesSaga,
  getPropertyTransactionCodesSaga,
  getPropertySubjournalTypesSaga,
];
