import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import CaptionButton from '../../../../../components/CaptionButton';
import SubmitButton from '../../../../../components/SubmitButton';
import ElementWithPermissions from '../../../../../components/ElementWithPermissions';
import confirm from '../../../../../components/ConfirmDialogModal';

import messages from '../messages';

type Props = {
  isDisabledRenewal: boolean,
  leaseRenewalHandler: Function,
  submittingRenewal: boolean,
  startRenewalMessage?: string,
  noticeToVacate: Object | null,
};

const Header = styled.p`
  color: #d70000;
`;

export const ResidentButtons = ({
  isDisabledRenewal,
  leaseRenewalHandler,
  submittingRenewal,
  startRenewalMessage,
  noticeToVacate,
}: Props) => {
  const confirmRenewalModal = async () => {
    if (noticeToVacate) {
      await confirm(<Header>{messages.headsUp.defaultMessage} </Header>, {
        body: <FormattedMessage {...messages.confirmationMessage} />,
      });
      leaseRenewalHandler();
    } else {
      leaseRenewalHandler();
    }
  };
  return (
    <Col md={6} className="pull-right" mdPush={1} lgPush={3}>
      <div className="form-footer padtop20">
        <Row>
          <Col xs={12} lg={6}>
            <ElementWithPermissions scope={['lease-create']}>
              <CaptionButton
                ButtonComp={SubmitButton}
                classes="btn btn-tertiary up10"
                disabled={isDisabledRenewal}
                clickHandler={confirmRenewalModal}
                isSubmitting={submittingRenewal}
                caption={startRenewalMessage}
                data-testid="lease Renewal"
              >
                <FormattedMessage {...messages.leaseRenewalButton} />
              </CaptionButton>
            </ElementWithPermissions>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default withLDConsumer()(ResidentButtons);
