export const LINKS = [
  {
    label: 'Overview',
    id: 'overview',
  },
  {
    label: 'Use of Our Site',
    id: 'use-of-our-site',
  },
  {
    label: 'Privacy',
    id: 'privacy',
  },
  {
    label: 'Confidentiality',
    id: 'confidentiality',
  },
  {
    label: 'Fortress Information Accuracy',
    id: 'fortress-information-accuracy',
  },
  {
    label: 'Communication Errors',
    id: 'communication-errors',
  },
  {
    label: 'Service Availability',
    id: 'service-availability',
  },
  {
    label: 'Third-Party Sites',
    id: 'third-party-sites',
  },
  {
    label: 'Warranty',
    id: 'warranty',
  },
  {
    label: 'Limitation of Liability and Indemnification',
    id: 'limitation-of-liability-and-indemnification',
  },
  {
    label: 'Governing Law and Dispute Resolution',
    id: 'governing-law-and-dispute-resolution',
  },
  {
    label: 'Contact Us',
    id: 'contact-us',
  },
];
