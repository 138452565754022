import {
  Rating,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import styled from 'styled-components';
import * as constants from '../constants';
import messages from '../messages';
import type { FormatMessageFn } from '../../../types';
import { pluralizeWord } from '../../../utils/string-helpers';
import { bigNumberStyle, bodyText } from '../OverallAvgRatingCard/styles';

export type Props = {
  formatMessage: FormatMessageFn,
  avgRating?: number,
  reviewCount?: number,
  sourceCount?: number,
  ratingCount?: number,
};

const UnsetInheritedGlobalStyles = styled('Rating')({
  label: {
    fontSize: 'unset !important',
    marginBottom: 'unset !important',
  },
});

const StarBasedAvgRating = ({
  formatMessage,
  avgRating,
  ratingCount,
  reviewCount,
  sourceCount,
}: Props) => (
  <>
    <Typography variant="h3">
      {formatMessage(messages.overallAvgRatingSectionTitle)}
    </Typography>
    <Typography sx={bigNumberStyle}>
      {avgRating?.toFixed(constants.RATING_FRACTION_DIGITS) ?? '0.00'}
    </Typography>
    <UnsetInheritedGlobalStyles>
      <Rating
        size="large"
        readOnly
        value={avgRating}
        precision={constants.STAR_RATING_PRECISION}
      />
    </UnsetInheritedGlobalStyles>
    <Typography sx={bodyText}>
      {`${ratingCount.toLocaleString()} ${pluralizeWord(
        formatMessage(messages.ratingSingular),
        formatMessage(messages.ratingPlural),
        ratingCount,
      )} / ${reviewCount.toLocaleString()} ${pluralizeWord(
        formatMessage(messages.reviewSingular),
        formatMessage(messages.reviewPlural),
        reviewCount,
      )} from ${sourceCount.toLocaleString()} ${pluralizeWord(
        formatMessage(messages.sourceSingular),
        formatMessage(messages.sourcePlural),
        sourceCount,
      )}`}
    </Typography>
  </>
);

export default StarBasedAvgRating;
