import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import messages from './messages';

type DefaultProps = {
  dismiss: any,
  proceed: any
};

type Props = DefaultProps & {
  show: boolean,
  message: any,
  onDismiss: Function,
  onProceed: Function
};

export default class Alert extends Component<Props> {
  static defaultProps = {
    dismiss: <FormattedMessage {...messages.no} />,
    proceed: <FormattedMessage {...messages.yes} />
  };
  render() {
    const {
      show,
      onDismiss,
      dismiss,
      message,
      proceed,
      onProceed
    } = this.props;
    return (
      <div className="static-modal">
        <Modal
          bsSize="small"
          dialogClassName="modal-dialog modal-alert"
          show={show}
          onHide={onDismiss}
          autoFocus={true}
        >
          <Modal.Body>
            <Row>
              <Col xs={12}>
                <h2 className="text-center">{message}</h2>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar>
              <Button type="button" bsStyle="default" onClick={onDismiss}>
                {dismiss}
              </Button>
              <Button type="button" bsStyle="primary" onClick={onProceed}>
                {proceed}
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
