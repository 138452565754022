import {
  handleSocketFasErrorEvent,
  handleSocketFasSuccessEvent,
  handleSocketFasReversalErrorEvent,
  handleSocketFasReversalSuccessEvent,
} from './actions';
import queryClient from '../../react-query-client';

/**
 * Consumed by Socket context to register listeners
 */
const residentProfileSocketHandlers = (dispatch: Function, socket: any) => {
  socket.on('fas.success', (message) => {
    const {
      file: { data: fileData, name: fileName },
      payload: { applicationId, customerId, residentId },
    } = message;
    const payload = {
      applicationId,
      customerId,
      fileData,
      fileName,
      residentId,
    };
    dispatch(handleSocketFasSuccessEvent(payload));
  });

  socket.on('fas.error', (message) => {
    dispatch(handleSocketFasErrorEvent(message));
  });

  socket.on('createFinalAccountStatementReversal.success', (message) => {
    refreshCollections();
    dispatch(handleSocketFasReversalSuccessEvent(message));
  });

  socket.on('createFinalAccountStatementReversal.error', (message) => {
    dispatch(handleSocketFasReversalErrorEvent(message));
  });
};

export default residentProfileSocketHandlers;

const refreshCollections = () => {
  queryClient.invalidateQueries('householdCollection');
  queryClient.invalidateQueries('claim');
};
