import React from 'react';
import { Grid, Row, Col, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { curryN } from 'ramda';

import messages from './messages';
import ColumnHeader from '../../../components/ColumnHeader';
import ReportsDetails from '../ReportsDetails';
import SearchField from '../../Fields/Search';
import type { Report } from '../types';

type Props = {
  intl: any,
  history: any,
  columnOrder: any,
  reports: Array<Report>,
  periods: Array<any>,
  handleOrderClick: Function,
  getOrder: Function,
  handleSubmit: Function,
  onCreateReport: Function,
  onViewHistory: Function,
  downloadLastReport: Function,
};

const ReportsTable = ({
  intl,
  columnOrder,
  reports,
  periods,
  history,
  handleOrderClick,
  getOrder,
  handleSubmit,
  onCreateReport,
  onViewHistory,
  downloadLastReport,
}: Props) => {
  const onOrderClickCurried = curryN(2, handleOrderClick);
  return (
    <Grid className="bodywrap" fluid>
      <Row className="section-header">
        <Col xs={6}>
          <h1>
            <FormattedMessage {...messages.header} />
          </h1>
        </Col>
        <Col xs={6} className="text-right">
          <SearchField
            className="search-input-active"
            form={'ManageApplications'}
            placeholder={`${intl.formatMessage(messages.search)}`}
            onSubmit={handleSubmit}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} id="table-row">
          <Table striped className="table-prospects">
            <thead className="table-header hidden-xs">
              <tr>
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.reportName)}
                  sortable={true}
                  icon={columnOrder.reportName}
                  order={getOrder('reportName')}
                  onOrderClick={onOrderClickCurried('reportName')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.reportType)}
                  sortable={true}
                  icon={columnOrder.reportType}
                  order={getOrder('reportType')}
                  onOrderClick={onOrderClickCurried('reportType')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.reportLastCreated)}
                  sortable={true}
                  icon={columnOrder.reportLastCreated}
                  order={getOrder('reportLastCreated')}
                  onOrderClick={onOrderClickCurried('reportLastCreated')}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.create)}
                />
                <ColumnHeader
                  columnLabel={intl.formatMessage(messages.viewHistory)}
                />
              </tr>
            </thead>
            <ReportsDetails
              intl={intl}
              reports={reports}
              periods={periods}
              history={history}
              onCreateReport={onCreateReport}
              onViewHistory={onViewHistory}
              downloadLastReport={downloadLastReport}
            />
          </Table>
        </Col>
      </Row>
    </Grid>
  );
};

export default ReportsTable;
