import type { Action } from '../App/types';
import * as ActionTypes from './constants';

const initialState = {
  unit: {},
  unitStatuses: [],
};

const manageUnitAvailabilityReducer = (
  state: Object = initialState,
  action: Action<*, *>,
): Object => {
  const { type, payload, meta, error } = action;
  switch (type) {
    case ActionTypes.GET_UNIT_BY_ID_SUCCESS: {
      return { ...state, unit: payload, meta };
    }
    case ActionTypes.GET_FLOORPLAN_BY_ID_SUCCESS: {
      return { ...state, floorplanDetails: payload };
    }
    case ActionTypes.GET_UNIT_STATUSES_SUCCESS: {
      return { ...state, unitStatuses: payload };
    }
    case ActionTypes.UPDATE_UNIT_SUCCESS: {
      return { ...state, unit: { ...state.unit, ...payload } };
    }
    case ActionTypes.GET_UNIT_STATUSES_ERROR:
    case ActionTypes.GET_UNIT_BY_ID_ERROR: {
      return { ...state, error };
    }
    case ActionTypes.CLEAN_LOADED_UNITS: {
      return initialState;
    }
    case ActionTypes.GET_AVERAGE_INCOME_DATA_SUCCESS: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};

export default manageUnitAvailabilityReducer;
