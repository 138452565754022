export const MARKETING_INFO_UPDATE_ERROR = 'MARKETING_INFO_UPDATE_ERROR';
export const PROPERTY_UPDATE_ERROR = 'PROPERTY_UPDATE_ERROR';
export const INTEGRATION_UPDATE_ERROR = 'INTEGRATION_UPDATE_ERROR';

export const N2W_LOCALE_MAP = {
  en: 'EN_US',
  es: 'ES_ES',
};

export const COLLECTIONS_DEFAULT_MINIMUM_AMOUNT = 100;
export const COLLECTIONS_DEFAULT_DAYS_AFTER_FAS = 30;
