import { get } from '../utils/api';

class StateAffordableProgramConfigService {
  getAll(options?: Object) {
    return get('/stateAffordableProgramConfig', options);
  }
  determineHotmaStatus({ state, programName, effectiveDate, options = {} }) {
    if (!state || !programName || !effectiveDate) {
      throw new Error(
        'StateAffordableProgramConfigService.determineHotmaStatus: state, programName, and effectiveDate are required',
      );
    }
    const queryParams = new URLSearchParams({
      state,
      programName,
      effectiveDate,
    });
    return get(
      '/stateAffordableProgramConfig/determineHotmaStatus?' + queryParams,
      options,
    );
  }
}

export default StateAffordableProgramConfigService;
