// @flow

import { any, innerJoin, isNil } from 'ramda';
import type { ValidationState } from '../types';
import { ValidationStates } from '../types';
import type { Property, UserPermission } from '../../../containers/App/types';

export default (
  userPermissions: UserPermission[],
  scope: string[],
  selectedProperty: ?Property,
  socketDisconnect: Function,
  path: string,
): ValidationState => {
  if (!localStorage.getItem('session_id')) {
    socketDisconnect();
    return ValidationStates.UNAUTHORIZED;
  }

  const permissions = innerJoin(
    (userPermission, singleScope) => userPermission.scope === singleScope,
    userPermissions,
    scope,
  );

  if (permissions.length === 0 && scope.length > 0) {
    return ValidationStates.FORBIDDEN;
  }

  if (
    any((permissions) => permissions.propertyLevel === true)(permissions) &&
    isNil(selectedProperty) &&
    ![
      '/application/:applicationId',
      '/resident/:residentId',
      '/prior-resident/:residentId',
      '/:organizationId/manage-lease-expiration-limits/:propertyId',
      '/work-orders',
      '/edit-work-order/:workOrderId',
      '/manage-unit-availability',
      '/lease-expirations',
      '/manage-reports',
      '/manage-documents',
      '/add-document',
      '/documents/:documentId',
      '/manage-delinquent-and-prepaid',
    ].includes(path)
  ) {
    return ValidationStates.NOT_ACCEPTABLE;
  }

  return ValidationStates.OK;
};
