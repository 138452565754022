import moment from 'moment';
import { isNil, isEmpty } from 'ramda';

type Props = {
  value: any,
  format?: string,
  isUtc?: boolean,
};

const FormattedDateOrDashes = ({
  value,
  format = 'MMM DD, YYYY',
  isUtc = false,
}: Props) => {
  if (isNil(value) || isEmpty(value)) return '---';
  const utcFormattedDate = moment(value).utc().format(format).toLocaleString();
  const nonUtcFormattedDate = moment(value).format(format).toLocaleString();
  return isUtc ? utcFormattedDate : nonUtcFormattedDate;
};

export default FormattedDateOrDashes;
