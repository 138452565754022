import React from 'react';
import { Link } from 'react-router-dom';
import FormatPhone from '../../../../../components/FormatPhone';

type Props = {
  isEmailCommunication: boolean,
  communicationType: string,
  isSmsCommunication: boolean,
  showMetricsInformation: boolean,
  isEmailOrSmsCommunication: boolean,
  validPhones: Array<any>,
  phonesValidated: boolean,
  emailsValidated: boolean,
  recipientsStatus: Array<Object>,
  validEmails: Array<any>,
  successRecipients: Array<any>,
  selectedRecipients: Array<any>,
  communicationBatchGenerated: boolean,
  selectedPropertyId: string,
};

type HeaderProps = {
  isEmailCommunication: boolean,
  communicationType: string,
  isSmsCommunication: boolean,
  showMetricsInformation: boolean,
  isEmailOrSmsCommunication: boolean,
  communicationBatchGenerated: boolean,
};

const Headers = (props: HeaderProps) => {
  const {
    isEmailCommunication,
    communicationType,
    isSmsCommunication,
    showMetricsInformation,
    isEmailOrSmsCommunication,
    communicationBatchGenerated,
  } = props;
  return (
    <tr className="bg-accent2">
      <th className="text-center" style={{ width: '24%' }}>
        Applicant Name
      </th>
      {!isEmailCommunication && <th className="text-center">Assigned Unit</th>}
      <th className="text-center">
        {communicationType === 'SMS' ? 'Phone Number' : 'Email'}
      </th>
      {isEmailCommunication && <th className="text-center">Validation</th>}
      {isSmsCommunication && <th className="text-center">Opted-In</th>}
      {(showMetricsInformation ||
        (isEmailOrSmsCommunication && communicationBatchGenerated)) && (
        <th className="text-center">Delivery Status</th>
      )}
    </tr>
  );
};

const Content = (props: Props) => {
  const {
    isEmailCommunication,
    communicationType,
    isSmsCommunication,
    showMetricsInformation,
    isEmailOrSmsCommunication,
    validPhones,
    phonesValidated,
    emailsValidated,
    recipientsStatus,
    validEmails,
    successRecipients,
    selectedRecipients,
    communicationBatchGenerated,
    selectedPropertyId,
  } = props;
  return selectedRecipients.map((applicant) => {
    let phoneNumber = applicant.phoneNumber;
    let emailAddress = applicant.emailAddress;
    let validationStatus = 'Pending';
    let communicationStatus = '';
    let phoneOptedInClass = '';
    if (phonesValidated && !validPhones.includes(phoneNumber)) {
      phoneOptedInClass = 'et-circle-delete text-red';
    } else if (phonesValidated && validPhones.includes(phoneNumber)) {
      phoneOptedInClass = 'et-circle-check text-success';
    }
    if (emailsValidated && !validEmails.includes(emailAddress)) {
      validationStatus = 'Fail';
    } else if (emailsValidated && validEmails.includes(emailAddress)) {
      validationStatus = 'Pass';
    }
    if (communicationBatchGenerated) {
      if (isEmailCommunication) {
        if (successRecipients.includes(emailAddress)) {
          communicationStatus = 'Delivered';
        } else {
          communicationStatus = 'Failure';
        }
      }
      if (isSmsCommunication) {
        if (successRecipients.includes(phoneNumber)) {
          communicationStatus = 'Delivered';
        } else {
          communicationStatus = 'Failure';
        }
      }
    }
    if (showMetricsInformation) {
      const recipient: any = recipientsStatus.find(
        (recipient: any) => recipient.id === applicant.id,
      );
      if (isSmsCommunication) {
        phoneNumber = recipient.phoneNumber;
      } else if (isEmailCommunication) {
        emailAddress = recipient.emailAddress;
      }
      if (recipient.status === 'SENT') {
        communicationStatus = 'Delivered';
      } else {
        communicationStatus = 'Failure';
      }
    }
    let validationStatusClass = 'text-gray';
    if (validationStatus === 'Pass') {
      validationStatusClass = 'text-success';
    } else if (validationStatus === 'Fail') {
      validationStatusClass = 'text-red';
    }
    let rowClass;
    if (
      isEmailCommunication &&
      (validationStatus === 'Fail' || communicationStatus === 'Failure')
    ) {
      rowClass = 'highlight text-red';
    } else if (isSmsCommunication && communicationStatus === 'Failure') {
      rowClass = 'highlight text-red';
    } else {
      rowClass = '';
    }
    return (
      <tr key={applicant.id} className={rowClass}>
        <td className="text-center">
          <Link
            to={`/property/${selectedPropertyId}/application/${applicant.applicationId}`}
            className="btn-text"
          >
            <b>{applicant.name}</b>
          </Link>
        </td>
        {!isEmailCommunication && (
          <td className="text-center">{applicant.assignedUnit}</td>
        )}
        <td className="text-center">
          {communicationType === 'SMS' ? (
            <FormatPhone phoneNumber={phoneNumber} />
          ) : (
            <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
          )}
        </td>
        {isEmailCommunication && (
          <td className={`text-center ${validationStatusClass}`}>
            {validationStatus}
          </td>
        )}
        {isSmsCommunication && (
          <td className="text-center">
            {phonesValidated && <i className={`${phoneOptedInClass} font18`} />}
          </td>
        )}
        {(showMetricsInformation ||
          (isEmailOrSmsCommunication && communicationBatchGenerated)) && (
          <td className="text-center">
            <label
              className={
                communicationStatus === 'Delivered'
                  ? 'text-success'
                  : 'text-red'
              }
            >
              {communicationStatus}
            </label>
          </td>
        )}
      </tr>
    );
  });
};

const ApplicantRecipients = (props: Props) => {
  const {
    selectedRecipients,
    communicationBatchGenerated,
    isEmailCommunication,
    communicationType,
    isSmsCommunication,
    showMetricsInformation,
    isEmailOrSmsCommunication,
    validPhones,
    phonesValidated,
    emailsValidated,
    recipientsStatus,
    validEmails,
    successRecipients,
    selectedPropertyId,
  } = props;
  return (
    <React.Fragment>
      <Headers
        isEmailCommunication={isEmailCommunication}
        communicationType={communicationType}
        isSmsCommunication={isSmsCommunication}
        showMetricsInformation={showMetricsInformation}
        isEmailOrSmsCommunication={isEmailOrSmsCommunication}
        communicationBatchGenerated={communicationBatchGenerated}
      />
      <Content
        selectedRecipients={selectedRecipients}
        communicationBatchGenerated={communicationBatchGenerated}
        isEmailCommunication={isEmailCommunication}
        communicationType={communicationType}
        isSmsCommunication={isSmsCommunication}
        showMetricsInformation={showMetricsInformation}
        isEmailOrSmsCommunication={isEmailOrSmsCommunication}
        validPhones={validPhones}
        phonesValidated={phonesValidated}
        emailsValidated={emailsValidated}
        recipientsStatus={recipientsStatus}
        validEmails={validEmails}
        successRecipients={successRecipients}
        selectedPropertyId={selectedPropertyId}
      />
    </React.Fragment>
  );
};

export default ApplicantRecipients;
