import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import messages from './ManageSubsidyPaymentsHeader/messages';
import ManageSubsidyPaymentsDetails from './ManageSubsidyPaymentsDetails';
import ManageSubsidyPaymentsHeader from './ManageSubsidyPaymentsHeader';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type Props = {
  intl: any,
  propertyId: string,
  organizationId: string,
  history: Object,
};

const ManageSubsidyPayments = (props: Props) => {
  const { intl, organizationId, propertyId } = props;
  const redirectToCreateSubsidyBatch = (paymentBatchHeaderId?: string) => {
    navigateToUrlWithSelectedPropertyId(
      `/create-subsidy-batch/${paymentBatchHeaderId || ''}`,
    );
  };

  return (
    <DocumentTitle title={intl.formatMessage(messages.header)}>
      <div className="bodywrap">
        <ManageSubsidyPaymentsHeader
          redirectToCreateSubsidyBatch={redirectToCreateSubsidyBatch}
          propertyId={propertyId}
          organizationId={organizationId}
        />
        <ManageSubsidyPaymentsDetails
          organizationId={organizationId}
          propertyId={propertyId}
          redirectToCreateSubsidyBatch={redirectToCreateSubsidyBatch}
        />
      </div>
    </DocumentTitle>
  );
};

const InjectedManageSubsidyPayments = injectIntl(ManageSubsidyPayments);
export const mapStateToProps = ({
  app: { currentUser, selectedProperty },
}: any): any => {
  return {
    organizationId: currentUser.user.organizationId,
    propertyId: selectedProperty.id,
  };
};
export default connect(mapStateToProps)(InjectedManageSubsidyPayments);
