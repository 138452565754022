import React, { Component } from 'react';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import queryClient from '../../react-query-client';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NoPrint } from 'react-easy-print';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

// Redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import * as applicationActions from './actions';
import * as prospectActions from '../PeopleProfile/actions';
import { editLease, getSignatureStatuses } from '../LeaseDataTab/actions';
import {
  getAllActivityTypes,
  getAllAssignees,
  getAllContactTypes,
  getAllFloorPlans,
  getAllIncomeTypes,
  getAllPetBreeds,
  getAllPetTypes,
  getAllPropertyClasses,
  getAllProspectStatus,
  getAllReferralTypes,
  getAllSpecialNeedsDesignations,
  getAllStates,
  promptToaster,
  selectProperty,
  updateSelectedProperty,
} from '../App/actions';
import * as manageDocumentActions from '../ManageDocuments/actions';
import {
  getIncomeTypeOptions,
  getProspectAssigneesOptions,
  getSelectedPropertyClassType,
  getSpecialNeedsDesignationOptions,
  getStateOptions,
} from '../App/selectors';
import {
  getApplication,
  getApplicationHousehold,
  getApplicationStatusOptions,
  getCurrentUser,
  getCustomerId,
  getFinanciallyResponsibleApplicants,
  getFinanciallyResponsibleFullNames,
  getLateMethods,
  getNonfinanciallyResponsibleAdults,
  getScreeningLetterTypes,
  getSecurityDeposits,
} from './selectors';

// Components
import DocumentTitle from 'react-document-title';
import { Col, Grid, Panel, Row, Tab, Tabs } from 'react-bootstrap';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { SizedBox } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { LazyLoad } from '@fortress-technology-solutions/fortress-component-library/HOC';
import TextingTab from '../TextingTab';
import TextingTabIcon from '../TextingTabIcon';
import ProfileDetails from './ProfileDetails';
import Snapshot from './Snapshot';
import Household from '../Household';
import LeaseSigning from '../LeaseSigning';
import ManageDocuments from '../ManageDocuments';
import LeaseApplicationTab from './LeaseApplicationTab';
import Ledger from '../Ledger';
import confirm from '../../components/ConfirmDialogModal';
import alert from '../../components/AlertDialogModal';
import ProspectInfoTab from '../PeopleProfile/ProspectInfoTab';
import ProspectInfoTabEditMode from '../PeopleProfile/ProspectInfoTabEditMode';
import LeaseDataTab from '../LeaseDataTab';
import LeaseDataTabEmpty from '../LeaseDataTabEmpty';
import HousingAssistanceVoucherTab from '../HousingAssistanceVoucher';
import {
  recordActivity,
  scheduleActivity,
} from '../../components/ActivityModal';
import { createActivity } from '../../components/CreateActivityModal';
import { editCompletedActivity } from '../../components/EditCompletedActivityModal';
import { editPendingActivity } from '../../components/EditPendingActivityModal';
import ActivityTableDeprecated from '../../components/ActivityTableDeprecated';
import Spinner from '../../components/GifSpinner';
import PersonalInformationModal, {
  PERSONAL_INFORMATION_FORM,
} from '../../components/PersonalInformationModal';
import CamTab from '../CamTab';
import CommercialLeaseDataTab from '../CommercialLeaseDataTab';
import Tenant from '../Tenant';
import ActivityContainer from '../../components/ActivityContents';

// Types
import type {
  ActivityType,
  ContactType,
  DropdownOption,
  GlobalState,
  PaginationMeta,
  PetBreed,
  PetType,
  ReferralType,
  SelectOption,
} from '../App/types';
import type { Applicant, Application } from '../CreateApplication/types';
import type { DecisionStatus, HouseholdMember } from './types';
import type { Activity, FormProspect } from '../ProspectProfile/types';
import type { FloorPlan } from '../ManageFloorPlans/types';

// Services
import ActivityTypeService from '../../services/activityTypeService';

// Constants
import messages from './messages';

// Utils
import {
  and,
  curryN,
  equals,
  filter,
  head,
  intersection,
  isEmpty,
  isNil,
  not,
  path,
  pathEq,
  pathOr,
  pick,
  propEq,
} from 'ramda';
import moment from 'moment';
import { parse } from 'qs';
import {
  ELECTRONIC_SIGNING_METHOD,
  getSentAndExecutedStatus,
  MANUAL_SIGNING_METHOD,
  mapCommercialLeaseBasicsToLeaseData,
  mapFormValuesLeaseData,
} from '../../utils/lease-helpers';
import {
  configureResidentApplicantAffordableTabsDisplay,
  HUD_PROGRAM_NAME,
  HUD_SUBSIDY_CODES,
} from '../AffordableQualificationTab/Shared/utils';
import {
  getIsReceivingAssistance,
  getNonOptionalChargeFromQualifications,
  chkAllTabsApproved,
  chkAllTabsForComplianceApprovalStatuses,
  COMPLIANCE_APPROVAL_STATUSES,
} from '../../utils/affordable';
import {
  getProspectToSubmit,
  nBedsArrInitialValueBuilder,
} from '../../utils/prospectPreferences-helpers';
import {
  navigateToUrlWithSelectedPropertyId,
  getUrlWithSelectedPropertyId,
} from '../../utils/navigation-helpers';
import {
  getDesiredMoveInDate,
  getScheduledMoveInDate,
} from '../ManageProspects/utils';

const GeneralAffordableNonHUD = LazyLoad(() =>
  import('../GeneralAffordable/GeneralAffordable.NonHUD'),
);
const GeneralAffordableHUD = LazyLoad(() =>
  import('../GeneralAffordable/GeneralAffordable.HUD'),
);
const GeneralAffordableRD = LazyLoad(() =>
  import('../GeneralAffordable/GeneralAffordable.RD'),
);
const LoadableAffordableQualificationTab = Loadable({
  loader: () => import('../AffordableQualificationTab/GeneralAffordableTab'),
  loading: Spinner,
});
const LoadableHUDQualificationTab = Loadable({
  loader: () => import('../AffordableQualificationTab/HUDTab'),
  loading: Spinner,
});

type Props = {
  customerId: string,
  activeTab: string,
  currentUser: any,
  users: Array<DropdownOption>,
  applicationHousehold: Array<HouseholdMember>,
  activityTypes: Array<ActivityType>,
  locale: string,
  petTypes: Array<PetType>,
  petBreeds: Array<PetBreed>,
  contactTypes: Array<ContactType>,
  referralTypes: Array<ReferralType>,
  pendingActivities: Array<Activity>,
  completedActivities: Array<Activity>,
  completedActivitiesMeta: PaginationMeta,
  allActivities: Array<Activity>,
  allActivitiesMeta: PaginationMeta,
  columnOrder: Object,
  screening: Object,
  frNames: string[],
  nonfrNames: string[],
  assignedUnits: Array<Object>,
  financiallyResponsibleHousehold: Array<HouseholdMember>,
  propertyClassType: string,
  openFiscalPeriod: Object,
  deletedApplicants: Array<Object>,
  selectedProperty: ?Object,
  leaseSignatureStatuses: Object,
  propertyClasses: Array<Object>,
  affordableQualifications: Array<Object>,
  fpNonOptionalCharges: Array<Object>,
  floorPlans: Array<FloorPlan>,
  relationships: Array<Object>,
};
type InjectedProps = {
  intl: any,
  actions: Object,
  currentApplicationId: string,
  currentApplication: Application,
  leaseRentPercentage: string,
  leaseTerms: Array<any>,
  lateMethods: Array<any>,
  screeningLetterTypes: Array<any>,
  securityDeposits: Array<any>,
  applicationDecisionStatus: Array<DecisionStatus>,
  applicationStatuses: Array<DropdownOption>,
  history: {
    push: Function,
  },
  monthlyTransactions: Array<Object>,
  selectedMonthlyOption: string,
  openFiscalPeriod: Object,
  states: Array<SelectOption>,
};
type State = {
  activityHistoryField: string,
  activityHistoryOrder: string,
  activityHistoryCurrentPage: number,
  activityHistoryLimit: number,
  editMode: boolean,
  editModeProspectInfo: boolean,
  editModeRequiresNote: boolean,
  retrievedPendingActivities: boolean,
  modal: ?string,
  personToEdit: Object,
  key: string,
  activityTableUpdateTrigger: boolean,
};

export class ApplicationProfile extends Component<
  Props & InjectedProps,
  State,
> {
  constructor(props: Props & InjectedProps) {
    super(props);
    this.state = {
      activityHistoryField: 'dateTime',
      activityHistoryOrder: 'DESC',
      activityHistoryPageCount: 0,
      activityHistoryCurrentPage: 1,
      activityHistoryCurrentCount: 0,
      activityHistoryTotalCount: 0,
      activityHistoryLimit: 10,
      editMode: false,
      editModeProspectInfo: false,
      editModeRequiresNote: false,
      hasHouseholdRentableItems: false,
      retrievedPendingActivities: false,
      refetchHouseholdRentableItemsTrigger: 0,
      modal: null,
      personToEdit: {},
      key: this.props.activeTab,
      activityTableUpdateTrigger: false, // tells activity table to re-query activities
    };
    this.props.actions.getAllPropertyClasses();
  }

  componentDidMount() {
    this.props.actions.getAllActivityTypes();
    this.props.actions.getAllAssignees();
    this.props.actions.getAllApplicationDecisionStatus();
    this.props.actions.getAllApplicationStatus();
    this.props.actions.getAllPetTypes();
    this.props.actions.getAllPetBreeds();
    this.props.actions.getAllContactTypes();
    this.props.actions.getAllFloorPlans();
    this.props.actions.getAllReferralTypes();
    this.props.actions.updateSelectedProperty();
    this.props.actions.getOneApplication(this.props.currentApplicationId);
    this.props.actions.updateColumnsSortValue('dateTime', 'descending');
    this.props.actions.getScreeningStatus(this.props.currentApplicationId);
    this.props.actions.getAssignedUnits(this.props.currentApplicationId);
    this.props.actions.getMonthlyTransactions(this.props.currentApplicationId);
    this.props.actions.getAllStates();
    this.props.actions.getAllIncomeTypes();
    this.props.actions.getAllSpecialNeedsDesignations();
    this.props.actions.getDocumentTypes(this.props.currentApplicationId);
    this.props.actions.getResidentLetterDocumentTypes(
      this.props.currentApplicationId,
    );
  }

  componentWillUnmount() {
    this.props.actions.cleanLoadedApplication();
  }

  componentDidUpdate(prevProps: Object) {
    const { actions, currentApplication, currentApplicationId } = this.props;

    // To trigger a refresh in the ActivityTable
    if (
      typeof prevProps.currentApplication?.assignedTo?.id === 'string' &&
      typeof currentApplication?.assignedTo?.id === 'string' &&
      prevProps.currentApplication?.assignedTo?.id !==
        currentApplication?.assignedTo?.id
    ) {
      this.refreshActivityTable();
    }

    if (
      this.state.retrievedPendingActivities === false &&
      currentApplication.prospectInfo.id
    ) {
      this.setState({ ...this.state, retrievedPendingActivities: true });
      actions.getProspectPendingActivities(currentApplication.prospectInfo.id);
      actions.getScreeningStatus(currentApplicationId);
      this.refreshActivities();
    }
    const prevLeaseId = pathOr(
      null,
      ['currentApplication', 'lease', 'id'],
      prevProps,
    );
    const prevDesiredSignatureMethod = pathOr(
      null,
      ['currentApplication', 'lease', 'desiredSignatureMethod'],
      prevProps,
    );
    // $FlowFixMe
    const leaseId = pathOr(null, ['lease', 'id'], currentApplication);
    // $FlowFixMe
    const desiredSignatureMethod = pathOr(
      null,
      ['lease', 'desiredSignatureMethod'],
      currentApplication,
    );
    if (
      (prevLeaseId !== leaseId ||
        prevDesiredSignatureMethod !== desiredSignatureMethod) &&
      desiredSignatureMethod === ELECTRONIC_SIGNING_METHOD
    ) {
      actions.getSignatureStatuses(leaseId);
    }
  }

  static getDerivedStateFromProps(props: any) {
    const queryString = parse(
      pathOr('', ['location', 'search'], props).replace('?', ''),
    );
    const selectedPropertyId = pathOr('', ['selectedProperty', 'id'], props);
    const { propertyId: queryPropertyId } = queryString;
    const userProperties = pathOr(
      [],
      ['currentUser', 'user', 'properties'],
      props,
    );
    if (
      queryPropertyId &&
      queryPropertyId !== selectedPropertyId &&
      userProperties.some((p) => p.id === queryPropertyId)
    ) {
      const newProperty = userProperties.find((p) => p.id === queryPropertyId);
      props.actions.selectProperty(newProperty);
    } else if (!selectedPropertyId) {
      props.history.push('/');
    }
    return null;
  }

  onActivityHistoryOrder = (field: string) => {
    const columnOrder = this.props.columnOrder[field];
    const order = columnOrder === 'ascending' ? 'DESC' : 'ASC';
    const icon =
      columnOrder === 'sortable'
        ? 'ascending'
        : columnOrder === 'ascending'
        ? 'descending'
        : 'ascending';
    this.props.actions.updateColumnsSortValue(field, icon);
    this.setState({ activityHistoryField: field, activityHistoryOrder: order });
    this.props.actions.getProspectAllActivities(
      this.props.currentApplication.prospectInfo.id,
      this.state.activityHistoryCurrentPage,
      this.state.activityHistoryLimit,
      field,
      order,
    );
    this.props.actions.getProspectCompletedActivities(
      this.props.currentApplication.prospectInfo.id,
      this.state.activityHistoryCurrentPage,
      this.state.activityHistoryLimit,
      field,
      order,
    );
  };
  checkUserPermissions = (testScope: Array<string>) => {
    const userScopes = this.props.currentUser.permissions.map(
      (permission) => permission.scope,
    );
    return intersection(testScope, userScopes).length > 0;
  };

  refreshActivities() {
    if (!!this.props.currentApplication) {
      this.props.actions.getProspectAllActivities(
        this.props.currentApplication.prospectInfo.id,
        this.state.activityHistoryCurrentPage,
        this.state.activityHistoryLimit,
        this.state.activityHistoryField,
        this.state.activityHistoryOrder,
      );
      this.props.actions.getProspectCompletedActivities(
        this.props.currentApplication.prospectInfo.id,
        this.state.activityHistoryCurrentPage,
        this.state.activityHistoryLimit,
        this.state.activityHistoryField,
        this.state.activityHistoryOrder,
      );
    }
  }

  onActivityHistoryPageChange(pageNumber: number) {
    this.setState({ activityHistoryCurrentPage: pageNumber }, () =>
      this.refreshActivities(),
    );
  }

  onRecordActivityClick = async () => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.props.users;
    /* istanbul ignore else */
    if (this.props?.currentApplication?.prospectInfo?.id != null) {
      const data: Activity = await recordActivity(
        this.context.store,
        this.props.intl,
        this.props.currentApplication.prospectInfo,
        activityTypesList,
        users,
        'applicant',
        this.props.currentApplicationId,
      );
      this.props.actions.createProspectActivity(
        Object.assign(data, { customerStatus: 'Applicant' }),
      );
      this.setState({ activityHistoryCurrentPage: 1 });
    }
  };

  onScheduleActivityClick = async () => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.props.users;
    /* istanbul ignore else */
    if (this.props?.currentApplication?.prospectInfo?.id != null) {
      const data: Activity = await scheduleActivity(
        this.context.store,
        this.props.intl,
        this.props.currentApplication.prospectInfo,
        activityTypesList,
        users,
        'applicant',
        this.props.currentApplicationId,
      );
      this.props.actions.createProspectActivity({
        ...data,
        customerStatus: 'Applicant',
      });
      this.setState({ activityHistoryCurrentPage: 1 });
    }
  };

  onCreateActivityClick = async (refresh?: Function) => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.props.users;
    if (this.props?.currentApplication?.prospectInfo?.id != null) {
      const data: Activity = await createActivity({
        store: this.context.store,
        intl: this.props.intl,
        prospect: this.props.currentApplication.prospectInfo,
        activityTypesList: activityTypesList,
        assigneeList: users,
        stage: 'applicant',
        urlId: this.props.currentApplicationId,
      });
      this.props.actions.createProspectActivity(
        {
          ...data,
          customerStatus: 'Applicant',
        },
        refresh,
      );
      this.setState({ activityHistoryCurrentPage: 1 });
    }
  };
  onEditActivity = (activity: Activity, refresh?: Function) => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.props.users;
    const stageInfo = {
      currentStage: 'applicant',
      urlId: this.props.currentApplicationId,
    };

    const editPromise = activity.activityCompletionStatusId
      ? editCompletedActivity(
          this.context.store,
          this.props.intl,
          activity,
          stageInfo,
        )
      : editPendingActivity(
          this.context.store,
          this.props.intl,
          activity,
          activityTypesList,
          users,
          this.props.currentApplication.prospectInfo,
          stageInfo,
        );

    return editPromise.then((data: Activity) => {
      if (data.saveAndClose) {
        this.props.actions.updateProspectActivity(data, refresh);
      } else if (data.saveAndAddNew) {
        this.props.actions.updateProspectActivity(data, refresh);
        this.onCreateActivityClick(this.forceRefreshActivityTable);
      } else {
        this.props.actions.deleteProspectActivity(data, refresh);
      }
      this.setState({ activityHistoryCurrentPage: 1 });
    });
  };
  handleAssignedToChange = (event: Object, newUserId: string) => {
    this.props.actions.assignApplication({
      ...this.props.currentApplication,
      assignedToId: newUserId,
    });
  };

  handleConvertToResident = () => {
    const route = `/create-resident/${this.props.currentApplicationId}`;
    this.convertApplication(route);
  };

  handleConvertToCommercialTenant = () => {
    const route = `/create-commercial-tenant/${this.props.currentApplicationId}`;
    this.convertApplication(route);
  };

  convertApplication = async (route: string) => {
    navigateToUrlWithSelectedPropertyId(route);
  };

  updateApplication = (changes: any) => {
    const { currentApplication } = this.props;
    this.props.actions.updateApplication(
      {
        // $FlowFixMe
        ...pick(
          [
            'id',
            'unitTypeId',
            'prospectId',
            'propertyId',
            'notes',
            'vehicleCount',
            'applicationStatusId',
            'updatedById',
            'propertyClassId',
            'adults',
            'minors',
            'pets',
            'fees',
            'affordableQualifyingChecklist',
            'publicHousingAuthority',
            'hudIncomeLimitId',
            'affordableDocuments',
            'reCompleteCertificationStarted',
            'householdUtilityAllowanceId',
            'voucherEffectiveDate',
            'rentalAssistanceSourceId',
            'complianceApprovalId',
            'setAsideProgramId',
          ],
          currentApplication,
        ),
        ...changes,
      },
      changes.notes
        ? 'successDescriptionNotes'
        : 'successDescriptionApplication',
      null,
      () => {
        this.refreshActivityTable();
        this.refreshHouseholdRentableItems();
      },
    );
    this.props.actions.getOneApplication(this.props.currentApplicationId);
  };

  updatePropertyClass = (changes: any) => {
    const { currentApplication } = this.props;
    this.props.actions.updatePropertyClass({
      // $FlowFixMe
      ...pick(['id', 'propertyClassId'], currentApplication),
      ...changes,
    });
  };

  handleApplicationStatusChange = (event: Object, newStatusId: string) => {
    this.updateApplication({ applicationStatusId: newStatusId });
  };

  handleApplicationNoteUpdate = (changes: Object) => {
    this.updateApplication({ notes: changes });
  };

  handleApplicationPropertyClassUpdate = (changes: Object) => {
    if (!!this.props.currentApplication.au) {
      alert('To change unit type, please remove assigned unit first.');
    } else {
      confirm(this.props.intl.formatMessage(messages.convertClass), {
        intl: this.props.intl,
      }).then(() => {
        this.updatePropertyClass({ propertyClassId: changes.propertyClassId });
      });
    }
  };

  handleCommercialContactsCheckboxClick = (e: any) => {
    this.updateApplication({ anyContact: !e.target.checked });
  };

  handleCommercialInsuranceCheckboxClick = (e: any) => {
    this.updateApplication({ anyInsurance: !e.target.checked });
  };

  handleNoVehiclesChange = (vehicleCount: number | null) => () => {
    vehicleCount === null
      ? this.updateApplication({ vehicleCount: 0 })
      : this.updateApplication({ vehicleCount: null });
  };

  handleChecklistOptionChange = (
    applicant: Object,
    item: Object,
    type: string,
    { target: { value } }: Object,
  ) => {
    const checklistChanges = {
      applicationId: this.props.currentApplicationId,
      applicantChecklistId: applicant.applicantChecklistId,
      checklistItemId: item.checklistItemId,
      checklistItemOptionId: type === 'option' ? value : item.selectedOption,
      applicantChecklistDecisionId: type === 'decision' ? value : null,
    };
    return this.props.actions.updateApplicantChecklist(checklistChanges);
  };
  handleProspectInfoEdit = () => {
    this.setState({ editModeProspectInfo: !this.state.editModeProspectInfo });
  };

  getDropDownOptions = (list: Array<ContactType | ReferralType>) => {
    const options: Array<Object> = list
      .map((item) => ({
        value: item.id,
        text: item.translations[this.props.locale] || item.name,
      }))
      .sort((a: Object, b: Object) => a.text.localeCompare(b.text));
    options.unshift({
      value: '',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return options;
  };

  getDropDownOptionsForPets = (list: Array<PetType>): Array<Object> =>
    list.map((item) => ({
      value: item.id,
      text: item.translations[this.props.locale] || item.name,
      breeds: item.petBreeds.map((itemBreed) => ({
        value: itemBreed.id,
        text: itemBreed.translations[this.props.locale] || itemBreed.name,
      })),
    }));

  getProspectForForm = (): FormProspect | {} => {
    if (!this.props.currentApplication.prospectInfo) return {};
    const prospect = this.props.currentApplication.prospectInfo;
    // $FlowFixMe
    const objP = pick(
      [
        'firstName',
        'lastName',
        'emailAddress',
        'phoneNumber',
        'contactTypeId',
        'referralTypeId',
        'assignedTo',
        'prospectStatusId',
      ],
      this.props.currentApplication.prospectInfo,
    );
    const spouse = filter(
      propEq('type', 'Spouse'),
      prospect.additionalOccupants,
    );
    const kids = filter(propEq('type', 'Minor'), prospect.additionalOccupants);
    const otherAdults = filter(
      propEq('type', 'Other Adult'),
      prospect.additionalOccupants,
    );
    const { moveInDateFrom, moveInDateTo } = getDesiredMoveInDate({
      prospectPreferences: prospect.prospectPreferences,
      isUnitAssigned: Boolean(this.props?.currentApplication?.au?.unitId),
    });
    return {
      ...objP,
      spouse: spouse,
      otherAdults: otherAdults,
      kids: kids,
      pets: prospect.petOccupants,
      spouseChecked: spouse.length > 0,
      otherAdultsChecked: otherAdults.length > 0,
      otherAdultsAmount: otherAdults.length,
      kidsChecked: kids.length > 0,
      kidsAmount: kids.length,
      petsChecked: prospect.petOccupants.length > 0,
      petsAmount: prospect.petOccupants.length,
      nBaths: prospect.prospectPreferences.nBaths,
      nBedsArr: nBedsArrInitialValueBuilder(
        prospect.prospectPreferences.nBedsArr,
        prospect.prospectPreferences.nBeds,
      ),
      nHalfBaths: prospect.prospectPreferences?.nHalfBaths,
      nBeds: prospect.prospectPreferences.nBeds,
      moveInDateFrom: this.props.flags.assigningUnitsMoveInDates
        ? moveInDateFrom
        : prospect.prospectPreferences.moveInDateFrom,
      moveInDateTo: this.props.flags.assigningUnitsMoveInDates
        ? moveInDateTo
        : prospect.prospectPreferences.moveInDateTo,
      moveInDateScheduled: prospect.prospectPreferences.moveInDateScheduled,
      unitId: prospect.prospectPreferences.unitId,
      isUnitAssigned: Boolean(this.props.currentApplication.au),
      priceFrom: prospect.prospectPreferences.priceFrom,
      priceTo: prospect.prospectPreferences.priceTo,
      leaseTermInMonths: prospect.prospectPreferences.leaseTermInMonths,
      helpText: prospect.prospectPreferences.helpText,
      wants: prospect.prospectPreferences.wants,
      notWants: prospect.prospectPreferences.notWants,
      noMoveInDate: Boolean(!moveInDateFrom),
      preferredFloorPlanId: prospect.prospectPreferences.preferredFloorPlanId,
    };
  };

  generateRelationships(locale: string) {
    const relationships = this.props.relationships
      ? this.props.relationships.map((suffix) => ({
          value: suffix.id,
          text: suffix.translations[locale] || suffix.name,
        }))
      : [];
    relationships.unshift({
      value: '',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return relationships;
  }

  handleSubmitProspectInfoTab = (prospect: FormProspect) => {
    const { prospectInfo } = this.props.currentApplication;
    const relationships = this.generateRelationships(this.props.intl.locale);
    const prospectToSubmit = getProspectToSubmit({
      prospect,
      propertyId: prospectInfo.propertyId,
      currentProspect: prospectInfo,
      relationships,
    });
    this.props.actions.updateProspect(prospectToSubmit, null);
    this.setState({
      editModeProspectInfo: false,
      activityHistoryCurrentPage: 1,
    });
  };

  handleCancelProspectInfoTab = (pristine: boolean) => {
    if (pristine) {
      this.setState({ editModeProspectInfo: false });
    } else {
      confirm(this.props.intl.formatMessage(messages.cancelConfirmation), {
        intl: this.props.intl,
      }).then(
        () => {
          this.setState({ editModeProspectInfo: false });
        },
        () => {
          this.setState({ editModeProspectInfo: true });
        },
      );
    }
  };

  handleEditHousehold = () => {
    const url = `/application/${this.props.currentApplicationId}/household`;
    navigateToUrlWithSelectedPropertyId(url);
  };

  handleEditApplicationForm = (applicant: Applicant) => {
    const { currentApplicationId } = this.props;
    const isProspect = () =>
      and(
        not(isNil(applicant)),
        pathEq(['applicantCustomer', 'isProspect'], true)(applicant),
      );

    const isFinanciallyResponsible = () =>
      and(
        and(
          not(isNil(applicant)),
          not(isNil(path(['applicantCustomer'], applicant))),
        ),
        and(
          not(isProspect()),
          pathEq(['applicantType', 'financiallyResponsible'], true)(applicant),
        ),
      );

    const isNotFinanciallyResponsible = () =>
      and(
        and(
          not(isNil(applicant)),
          not(isNil(path(['applicantCustomer'], applicant))),
        ),
        and(not(isProspect()), not(isFinanciallyResponsible())),
      );

    if (isProspect()) {
      window.location.assign(
        getUrlWithSelectedPropertyId(
          `/primary-form/${currentApplicationId}/${applicant.id}`,
        ),
      );
    }
    if (isFinanciallyResponsible()) {
      navigateToUrlWithSelectedPropertyId(
        `/non-primary-form/${currentApplicationId}/${applicant.id}`,
      );
    }
    if (isNotFinanciallyResponsible()) {
      navigateToUrlWithSelectedPropertyId(
        `/shortApplication/${currentApplicationId}/${applicant.id}`,
      );
    }
  };

  handleCommercialLeaseBasicsSubmit = (leaseId?: string, values: any) => {
    const {
      actions: { saveLeaseDataTab },
      currentApplicationId,
      currentApplication,
    } = this.props;
    const unitId = path(['au', 'unit', 'id'], currentApplication);
    const leaseData = {
      ...mapCommercialLeaseBasicsToLeaseData(values),
      unitId,
      desiredSignatureMethod: MANUAL_SIGNING_METHOD,
    };

    saveLeaseDataTab(leaseData, currentApplicationId);
  };

  leaseDataHandleSubmit = (values: any) => {
    const { currentApplicationId, nonfrNames, currentApplication, flags } =
      this.props;
    const desiredSignatureMethod =
      values.desiredSignatureMethod === ELECTRONIC_SIGNING_METHOD &&
      nonfrNames.length > 0
        ? 'default'
        : values.desiredSignatureMethod;
    const formData = mapFormValuesLeaseData({
      ...values,
      desiredSignatureMethod,
    });
    const currentMoveInDate = moment(
      currentApplication?.lease?.moveInDate,
    ).format('YYYY-MM-DD');
    const updatedMoveInDate = moment(formData?.moveInDate).format('YYYY-MM-DD');
    const hasMoveInDateChanged = flags.assigningUnitsMoveInDates
      ? updatedMoveInDate !== currentMoveInDate
      : false;

    this.props.actions.saveLeaseDataTab(
      formData,
      currentApplicationId,
      this.refreshActivityTable,
      hasMoveInDateChanged,
    );

    return values;
  };

  leaseDataHandleGenerateLease = (values: any) => {
    const applicationId = this.props.currentApplicationId;
    const data = mapFormValuesLeaseData(values);
    this.props.actions.generateLeaseDocument({ applicationId, data });
    return values;
  };

  validateRequired = (value: any) =>
    not(isEmpty(value)) && not(isNil(value)) && !equals('default', value);

  onResendPayleaseClick = () => {
    const {
      customerId,
      actions: { resendPayLeaseEmail },
    } = this.props;
    resendPayLeaseEmail(customerId);
  };

  resetModal = () => {
    this.setState({ modal: null });
  };

  editHouseholdMember = (member: Object) => {
    const { currentApplication } = this.props;
    // $FlowFixMe
    const unit = pathOr(null, ['au', 'unit'], currentApplication);
    // $FlowFixMe
    const personToEdit = {
      ...member,
      ...(!!unit && { unitNumber: unit.unitNumber }),
      customerStatusTitle: 'Applicant',
      isCommercial: currentApplication.isCommercial,
    };
    this.setState({
      personToEdit,
      modal: PERSONAL_INFORMATION_FORM,
    });
  };

  sendPortalInviteEmail = (customerId: string) => {
    const { actions, currentApplication, currentApplicationId } = this.props;
    // $FlowFixMe
    const leaseId = pathOr(null, ['lease', 'id'], currentApplication);
    actions.sendApplicantPortalInviteEmail(
      customerId,
      currentApplicationId,
      leaseId,
      currentApplication.isCommercial ? 'commercial-applicant' : 'applicant',
    );
  };

  refreshActivityTable = () => {
    this.setState({
      activityTableUpdateTrigger: !this.state.activityTableUpdateTrigger,
    });
  };

  refreshHousingAssistanceVouchersTable = () => {
    queryClient.invalidateQueries(['getAllVouchersByHouseholdId']);
  };

  refreshHouseholdRentableItems = () => {
    this.setState({
      refetchHouseholdRentableItemsTrigger:
        this.state.refetchHouseholdRentableItemsTrigger + 1,
    });
  };

  // At the moment of triggering the above from the modals it doesn't actually refreshes the table properly
  forceRefreshActivityTable = () => {
    this.setState(
      {
        activityTableUpdateTrigger: !this.state.activityTableUpdateTrigger,
      },
      () => this.refreshActivities(),
    );
  };

  unassignUnitHandler = async () => {
    const {
      actions: { unassignUnit, editLease },
      assignedUnits,
      currentApplication,
      currentApplicationId,
    } = this.props;
    const assignedUnit =
      assignedUnits && assignedUnits.length > 0 ? assignedUnits[0] : null;
    if (assignedUnit) {
      const {
        lease,
        lease: { id: leaseId, documentId },
      } = currentApplication;
      const { leaseExecuted, leaseSentToPortal } =
        getSentAndExecutedStatus(lease);
      if (leaseId && (leaseExecuted || leaseSentToPortal)) {
        await editLease(
          leaseId,
          currentApplicationId,
          false,
          currentApplicationId,
          !!documentId,
        );
      } else {
        await unassignUnit(assignedUnit.id, currentApplicationId, () => {
          this.refreshActivityTable();
          this.refreshHouseholdRentableItems();
          this.refreshHousingAssistanceVouchersTable();
        });
      }
    }
  };

  handleEditApplication = ({
    generalInformation,
    reportingInformation,
  }: Object) => {
    if (this.props.currentApplication.isCommercial) {
      const nullEmptyFields = (data: Object) =>
        Object.entries(data).reduce((acc, [key, value]) => {
          acc[key] = value ? value : null;
          return acc;
        }, {});

      const payload = nullEmptyFields({
        tenantLegalName: generalInformation.firstName,
        doingBusinessAs: generalInformation.preferredName,
        parentCompany: generalInformation.parentCompany,
        phoneNumber: generalInformation.phoneNumber,
        emailAddress: generalInformation.emailAddress,
        street: generalInformation.street,
        street2: generalInformation.street2,
        city: generalInformation.city,
        state: generalInformation.state,
        zipCode: generalInformation.zipCode,
      });

      this.props.actions.updateCommercialApplication({
        ...payload,
        id: this.props.currentApplicationId,
      });
    }

    const selectedApplicantId = this.state.personToEdit.id;
    const currentApplicant =
      this.props.currentApplication?.applicants?.find(
        (applicant) => applicant.id === selectedApplicantId,
      ) ?? null;
    if (!currentApplicant) {
      return;
    }
    const applicantType = currentApplicant?.applicantType?.type ?? 'Adult';
    const isAdult = applicantType === 'Adult';

    const specialNeedsDesignationIds =
      reportingInformation?.specialNeedsDesignationIds ?? [];
    const annualIncome = reportingInformation?.annualIncome ?? '0.00';
    const applicantMinorId = currentApplicant?.applicantMinor?.id ?? '';
    const payload = {
      specialNeedsDesignationIds,
      annualIncome,
      isAdult,
      applicantId: selectedApplicantId,
      applicantMinorId,
    };
    this.props.actions.updateApplicant(payload);
    queryClient.invalidateQueries([
      'getHouseholdProfileAffordableQualificationsByProgram',
    ]);
  };

  onZeroBalance = () => {
    const {
      currentApplication,
      actions: { getOneApplication },
      currentApplicationId,
    } = this.props;
    const customerStatus = currentApplication?.householdInfo?.mainCus?.status;
    const applicationStatus = currentApplication?.applicationStatus?.name;
    const areFeesPaid = currentApplication?.areFeesPaid ?? false;
    if (
      !areFeesPaid &&
      (customerStatus === 'Applicant' || applicationStatus === 'In Process')
    ) {
      getOneApplication(currentApplicationId);
    }
  };

  setHasHouseholdRentableItems = (hasHouseholdRentableItems: boolean) => {
    this.setState({ hasHouseholdRentableItems });
  };

  render() {
    const {
      affordableQualifications,
      intl,
      currentApplication,
      financiallyResponsibleHousehold,
      currentApplicationId,
      currentApplication: {
        lease = {},
        householdId,
        affordableException = {},
        affordableSetup = {},
        anyContact = true,
        anyInsurance = true,
      },
      frNames,
      nonfrNames,
      propertyClassType,
      customerId,
      applicationDecisionStatus,
      applicationStatuses,
      users,
      deletedApplicants,
      selectedProperty,
      selectedMonthlyOption,
      leaseSignatureStatuses,
      fpNonOptionalCharges,
      flags,
      locale,
    } = this.props;

    const { firstName, lastName } = this.props.currentApplication.prospectInfo;
    if (!this.props.currentApplication.prospectInfo.id) {
      return <AsyncBox loading={true} sx={{ height: 'calc(100vh - 40px)' }} />;
    }
    const onChecklistOptionChangeCurried = curryN(
      4,
      this.handleChecklistOptionChange.bind(this),
    );

    const members = this.props.applicationHousehold;
    const application = this.props.currentApplication;
    const isUnitAssigned = currentApplication?.au?.unitId;
    const showDocumentsTab = this.checkUserPermissions(['document-read']);
    const showLeaseAppTab = this.checkUserPermissions(['application-update']);

    const isCommercial = application.isCommercial === true;

    // $FlowFixMe
    const moveInDate = pathOr(
      null,
      ['lease', 'moveInDate'],
      currentApplication,
    );
    const voucherEffectiveDate = moveInDate ? moment(moveInDate) : null;
    // $FlowFixMe
    const unit = pathOr(null, ['au', 'unit'], application);
    // $FlowFixMe
    const leaseRentPercentage = pathOr(
      '20',
      ['currentApplication', 'leaseRentPercentage'],
      this.props,
    );
    // $FlowFixMe
    const complianceNotes = pathOr('', ['complianceNotes'], application);
    // $FlowFixMe
    const leasedRent = pathOr(0, ['lease', 'leasedRent'], application);
    const isResidentPortalActive = pathOr(
      false,
      ['isResidentPortalActive'],
      selectedProperty,
    );
    // $FlowFixMe
    const vehicleCount = pathOr(null, ['vehicleCount'], application);
    // $FlowFixMe
    const household = pathOr({}, ['householdInfo'], application);
    // $FlowFixMe
    const householdMembers = pathOr([], ['applicants'], application)
      .filter((applicant) => applicant.applicantPet === null)
      .map((applicant) => ({ label: applicant.name, value: applicant.id }));

    /**
     * TODO: An honest discussion about why some of these need to be on the
     * Application object
     */
    const leaseDataTabPropsFromCurrentApp = pick(
      [
        'au',
        'applicationStatus',
        'basicLeaseFees',
        'labelEndDate',
        'leaseTerms',
        'prospectInfo',
        'softDeletedLeaseTerms',
      ],
      // $FlowFixMe
      currentApplication,
    );
    const { applicationStatus, au } = leaseDataTabPropsFromCurrentApp;
    const showLeaseDataTab =
      applicationStatus.name === 'Approved' && !isNil(au);
    const leaseUnit = pathOr({}, ['unit'], au);

    const paymentRestrictions = {
      doNotAccept: household.paymentsDoNotAccept,
      certifiedOnly: household.paymentsCertifiedOnly,
    };

    const hasCompletedQualifications = (affordableQualifications || []).some(
      (aq) => !aq.isActive,
    );

    const { affordableTabs, hudTab, householdUtilityAllowanceId } =
      configureResidentApplicantAffordableTabsDisplay(
        affordableQualifications || [],
        selectedProperty,
        unit,
        flags,
        true,
      );

    const allAffordableTabsApproved = chkAllTabsApproved(
      [...affordableTabs, hudTab],
      affordableQualifications,
    );

    const disableUnassignUnitLink = chkAllTabsForComplianceApprovalStatuses(
      [...affordableTabs, hudTab],
      affordableQualifications,
      [
        COMPLIANCE_APPROVAL_STATUSES.APPROVED,
        COMPLIANCE_APPROVAL_STATUSES.APPROVED_PENDING_SIGN,
      ],
    );

    const isReceivingAssistance = getIsReceivingAssistance(
      affordableTabs || [],
    );

    const customer = pathOr(
      {},
      ['applicantCustomer', 'customer'],
      head(application.applicants),
    );

    const address = pathOr(
      {},
      ['applicantCustomer', 'address'],
      head(application.applicants),
    );

    const showCamTab = false;
    const showTextingTab =
      selectedProperty.isTwoWayCommunicationActive &&
      this.checkUserPermissions(['communication-create']);

    const nonOptionalCharge = getNonOptionalChargeFromQualifications(
      affordableQualifications || [],
    );

    const applicantPropertyClass = application?.propertyClass?.name ?? '';
    const isAffordable =
      propertyClassType === 'Affordable' ||
      (propertyClassType === 'Mixed' &&
        applicantPropertyClass === 'Affordable');

    const miniProfileApplicant =
      application?.applicants?.find((applicant) => {
        return applicant?.id === this.state.personToEdit?.id;
      }) ?? null;

    // set default annual income value from applicantCustomer (which comes from application forms
    const reportingInformationInitialValues = {
      specialNeedsDesignationIds:
        miniProfileApplicant?.specialNeedsDesignationIds ?? [],
      annualIncome: miniProfileApplicant?.annualIncome
        ? miniProfileApplicant.annualIncome
        : miniProfileApplicant?.applicantCustomer?.annualIncome ?? '0.00',
    };
    const scheduledMoveInDate = this.props.flags.assigningUnitsMoveInDates
      ? getScheduledMoveInDate({
          lease,
          isUnitAssigned,
          prospectPreferences:
            currentApplication?.prospectInfo?.prospectPreferences,
        })
      : lease.moveInDate;

    const appliesForPhaseIn =
      currentApplication?.householdInfo?.householdPhaseInEligibility
        ?.appliesForPhaseIn;

    const section236Flag = this.props.flags?.section236;
    let isSection236 = false;
    if (hudTab && section236Flag) {
      const floorplan = unit?.floorPlan;
      const fpHudCode = (floorplan?.floorPlanAffordablePrograms ?? []).find(
        (fp) =>
          fp?.propertyAffordableProgram?.masterAffordableProgram?.name ===
          HUD_PROGRAM_NAME,
      )?.hudCode;
      const propertyHUDSubsidy = (
        selectedProperty?.propertyHUDSubsidy ?? []
      ).map((hud) => hud?.masterHUDSubsidy?.code);

      isSection236 =
        propertyHUDSubsidy.includes(HUD_SUBSIDY_CODES.SECTION_236) ||
        fpHudCode === HUD_SUBSIDY_CODES.SECTION_236;
    }

    return (
      <DocumentTitle
        title={this.props.intl.formatMessage(messages.title)}
        className="creditstatus-page"
      >
        <NoPrint>
          <PersonalInformationModal
            onSubmit={this.handleEditApplication}
            personalInfo={this.state.personToEdit}
            onClose={this.resetModal}
            show={this.state.modal === PERSONAL_INFORMATION_FORM}
            residentPortalInfo={{
              display: isResidentPortalActive,
              sendPortalInviteEmail: this.sendPortalInviteEmail,
            }}
            isCommercial={isCommercial}
            isAffordable={isAffordable}
            unit={unit}
            states={this.props.states}
            lease={lease}
            initialValues={{
              generalInformation: { ...customer, ...address },
              reportingInformation: reportingInformationInitialValues,
            }}
            flags={this.props.flags}
            applicant={miniProfileApplicant}
            property={selectedProperty}
          />
          <Grid fluid>
            <Row>
              <Col xs={12} md={5} className="people-profile-left">
                <ProfileDetails
                  handleClassChange={this.handleApplicationPropertyClassUpdate}
                  propertyClassType={propertyClassType}
                  propertyClasses={this.props.propertyClasses}
                  intl={this.props.intl}
                  currentRecord={application}
                  handleNotesChange={this.handleApplicationNoteUpdate}
                  isResident={false}
                  prospectUpdateAllowed={this.checkUserPermissions([
                    'prospect-update',
                  ])}
                  assignedUnits={this.props.assignedUnits}
                  applicationStatuses={applicationStatuses}
                  handleStatusChange={this.handleApplicationStatusChange}
                  checkUserPermissions={this.checkUserPermissions}
                  applicationStatusId={application.applicationStatusId}
                />
                <Snapshot
                  initialValues={{
                    assignedToId: application.assignedToId,
                  }}
                  applicationId={this.props.currentApplicationId}
                  currentRecord={application}
                  currentUser={this.props.currentUser.user}
                  users={users}
                  activityTypes={this.props.activityTypes}
                  intl={this.props.intl}
                  handleAssignedToChange={this.handleAssignedToChange}
                  handleConvertToResident={this.handleConvertToResident}
                  handleConvertToCommercialTenant={
                    this.handleConvertToCommercialTenant
                  }
                  hasCompletedQualifications={hasCompletedQualifications}
                  hasHouseholdRentableItems={
                    this.state.hasHouseholdRentableItems
                  }
                  history={this.props.history}
                  assignedUnits={this.props.assignedUnits}
                  scheduledMoveInDate={scheduledMoveInDate}
                  unassignUnitCallback={this.unassignUnitHandler}
                  getUnitQuote={this.props.actions.getUnitQuote}
                  prospectId={this.props.currentApplication.prospectInfo.id}
                  paymentRestrictions={paymentRestrictions}
                  rentStartDate={lease.rentStartDate}
                  refreshActivityTable={this.refreshActivityTable}
                  areApplicationFeesPaid={currentApplication?.areFeesPaid}
                  propertyId={this.props.selectedProperty?.id}
                  organizationId={this.props.selectedProperty?.organizationId}
                  disableUnassignUnitLink={disableUnassignUnitLink}
                  isAffordable={isAffordable}
                />
                {isCommercial === true && (
                  <Tenant
                    householdId={householdId}
                    householdMembers={members}
                    editHouseholdMember={this.editHouseholdMember}
                    anyContact={anyContact}
                    anyInsurance={anyInsurance}
                    onContactCheckboxClick={
                      this.handleCommercialContactsCheckboxClick
                    }
                    onInsuranceCheckboxClick={
                      this.handleCommercialInsuranceCheckboxClick
                    }
                  />
                )}
                {isCommercial === false && (
                  <Household
                    hasAssignedUnit={isUnitAssigned}
                    householdMembers={members}
                    onEdit={this.handleEditHousehold}
                    applicationId={application.id}
                    vehicleCount={vehicleCount}
                    editHouseholdMember={this.editHouseholdMember}
                    handleNoVehiclesChange={this.handleNoVehiclesChange}
                    householdId={householdId}
                    hasCompletedQualifications={hasCompletedQualifications}
                    setHasHouseholdRentableItems={
                      this.setHasHouseholdRentableItems
                    }
                    refetchHouseholdRentableItemsTrigger={
                      this.state.refetchHouseholdRentableItemsTrigger
                    }
                  />
                )}
                {lease.desiredSignatureMethod === ELECTRONIC_SIGNING_METHOD && (
                  <LeaseSigning
                    leaseSignatureStatuses={leaseSignatureStatuses}
                  />
                )}
              </Col>
              <Col xs={12} md={7} className="people-profile-right">
                <Panel className="block block-white-shadow">
                  <Tabs
                    id="peoples-tab"
                    activeKey={this.state.key}
                    onSelect={(key) => this.setState({ ...this.state, key })}
                    mountOnEnter
                  >
                    {isCommercial === false && (
                      <Tab
                        eventKey="1"
                        title={
                          <FormattedMessage {...messages.prospectInformation} />
                        }
                      >
                        <Panel.Body>
                          {!this.state.editModeProspectInfo &&
                            !!this.props.currentApplication.prospectInfo && (
                              <ProspectInfoTab
                                intl={this.props.intl}
                                handleProspectInfoEdit={
                                  this.handleProspectInfoEdit
                                }
                                prospect={
                                  this.props.currentApplication.prospectInfo
                                }
                                petTypes={this.props.petTypes}
                                contactTypes={this.props.contactTypes}
                                referralTypes={this.props.referralTypes}
                                floorPlans={this.props.floorPlans}
                              />
                            )}
                          {this.state.editModeProspectInfo && (
                            <ProspectInfoTabEditMode
                              intl={this.props.intl}
                              handleProspectInfoEdit={
                                this.handleProspectInfoEdit
                              }
                              petTypes={this.getDropDownOptionsForPets(
                                this.props.petTypes,
                              )}
                              contactTypes={this.getDropDownOptions(
                                this.props.contactTypes,
                              )}
                              referralTypes={this.getDropDownOptions(
                                this.props.referralTypes,
                              )}
                              floorPlans={this.props.floorPlans}
                              initialValues={this.getProspectForForm()}
                              onSubmit={this.handleSubmitProspectInfoTab}
                              handleCancel={this.handleCancelProspectInfoTab}
                            />
                          )}
                        </Panel.Body>
                      </Tab>
                    )}
                    <Tab
                      eventKey="2"
                      title={<FormattedMessage {...messages.activityTab} />}
                      disabled={!this.checkUserPermissions(['activity-read'])}
                    >
                      <Panel.Body>
                        {this.props.flags
                          .activityFilterHideAutomatedActivitiesCheckbox ? (
                          <ActivityContainer
                            intl={this.props.intl}
                            onCreateActivityClick={this.onCreateActivityClick}
                            onEditActivity={this.onEditActivity}
                            prospectId={
                              this.props.currentApplication.prospectInfo.id
                            }
                            updateTrigger={
                              this.state.activityTableUpdateTrigger
                            }
                            locale={this.props.locale}
                          />
                        ) : (
                          <ActivityTableDeprecated
                            intl={this.props.intl}
                            selectedProperty={selectedProperty}
                            prospectId={
                              this.props.currentApplication.prospectInfo.id
                            }
                            activities={this.props.allActivities}
                            locale={this.props.locale}
                            onEditActivity={this.onEditActivity}
                            onCreateActivityClick={this.onCreateActivityClick}
                            updateTrigger={
                              this.state.activityTableUpdateTrigger
                            }
                          />
                        )}
                      </Panel.Body>
                    </Tab>
                    {showTextingTab && (
                      <Tab
                        eventKey="texting"
                        title={
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {this.props.intl.formatMessage(messages.texting)}{' '}
                            <SizedBox w={3} />
                            <TextingTabIcon householdId={householdId} />
                          </span>
                        }
                      >
                        <Panel.Body>
                          <TextingTab householdId={householdId} />
                        </Panel.Body>
                      </Tab>
                    )}
                    <Tab
                      eventKey="3"
                      title={<FormattedMessage {...messages.documentsTab} />}
                      disabled={!showDocumentsTab}
                    >
                      <Panel.Body>
                        {showDocumentsTab && (
                          <ManageDocuments
                            intl={this.props.intl}
                            applicationId={this.props.currentApplicationId}
                            application={this.props.currentApplication}
                            customerType="applicant"
                            deletedApplicants={deletedApplicants}
                          />
                        )}
                      </Panel.Body>
                    </Tab>
                    <Tab
                      eventKey="4"
                      title={<FormattedMessage {...messages.ledgerTab} />}
                    >
                      <Ledger
                        customerStatus="Applicant"
                        customerId={customerId}
                        customerName={`${firstName} ${lastName}`}
                        frMembers={this.props.financiallyResponsibleHousehold}
                        frNames={this.props.frNames}
                        unit={unit}
                        resendClick={this.onResendPayleaseClick}
                        household={household}
                        applicationId={this.props.currentApplicationId}
                        isResident={false}
                        onZeroBalance={this.onZeroBalance}
                        householdMembers={members}
                        lateMethodId={lease?.lateMethodId}
                      />
                    </Tab>
                    {isCommercial === false && (
                      <Tab
                        eventKey="5"
                        title={
                          <FormattedMessage {...messages.applicationTab} />
                        }
                        disabled={!showLeaseAppTab}
                      >
                        {showLeaseAppTab && (
                          <Panel.Body>
                            <LeaseApplicationTab
                              intl={this.props.intl}
                              currentRecord={application}
                              initialValues={{
                                applicationStatusId:
                                  application.applicationStatusId,
                              }}
                              applicationId={this.props.currentApplicationId}
                              applicationDecisionStatus={
                                applicationDecisionStatus
                              }
                              applicationStatuses={applicationStatuses}
                              handleChecklistOptionChange={
                                onChecklistOptionChangeCurried
                              }
                              checkUserPermissions={this.checkUserPermissions}
                              handleStatusChange={
                                this.handleApplicationStatusChange
                              }
                              handleNotesChange={
                                this.handleApplicationNoteUpdate
                              }
                              screening={this.props.screening}
                              screeningLetterTypes={
                                this.props.screeningLetterTypes
                              }
                              submitScreeningApplication={
                                this.props.actions.submitScreeningApplication
                              }
                              refreshActivityTable={this.refreshActivityTable}
                              saveScreeningResults={
                                this.props.actions.saveScreeningResults
                              }
                              generateAdverseActionLetter={
                                this.props.actions.generateAdverseActionLetter
                              }
                              handleEditApplicationForm={
                                this.handleEditApplicationForm
                              }
                              currentUser={this.props.currentUser}
                              promptToaster={this.props.actions.promptToaster}
                              selectedProperty={selectedProperty}
                              householdId={householdId}
                            />
                          </Panel.Body>
                        )}
                      </Tab>
                    )}
                    {isCommercial === true && (
                      <Tab
                        eventKey="6"
                        title={<FormattedMessage {...messages.leaseDataTab} />}
                      >
                        <Panel.Body>
                          {!showLeaseDataTab ? (
                            <LeaseDataTabEmpty isCommercial={true} />
                          ) : (
                            <CommercialLeaseDataTab
                              frNames={frNames}
                              leases={[{ ...lease, units: [leaseUnit] }]}
                              isResident={false}
                              labelEndDate={
                                leaseDataTabPropsFromCurrentApp.labelEndDate
                              }
                              customer={customer}
                              unit={leaseUnit}
                              lateMethods={this.props.lateMethods || []}
                              selectedMonthlyOption={selectedMonthlyOption}
                              onLeaseBasicsSubmit={
                                this.handleCommercialLeaseBasicsSubmit
                              }
                              applicationId={this.props.currentApplicationId}
                              prospectInfo={
                                leaseDataTabPropsFromCurrentApp.prospectInfo
                              }
                            />
                          )}
                        </Panel.Body>
                      </Tab>
                    )}
                    {isCommercial === false && (
                      <Tab
                        eventKey="6"
                        title={<FormattedMessage {...messages.leaseDataTab} />}
                      >
                        <Panel.Body>
                          {!showLeaseDataTab ? (
                            <LeaseDataTabEmpty />
                          ) : (
                            <LeaseDataTab
                              {...leaseDataTabPropsFromCurrentApp}
                              allAffordableTabsApproved={
                                allAffordableTabsApproved
                              }
                              applicationId={this.props.currentApplicationId}
                              frNames={frNames}
                              lateMethods={this.props.lateMethods}
                              leaseRentPercentage={leaseRentPercentage}
                              leases={[{ ...lease, units: [leaseUnit] }]}
                              leaseSignatureStatuses={leaseSignatureStatuses}
                              monthlyTransactions={
                                this.props.monthlyTransactions
                              }
                              hasNonFR={nonfrNames.length > 0}
                              onGenerate={this.leaseDataHandleGenerateLease}
                              onSubmit={this.leaseDataHandleSubmit}
                              openFiscalPeriod={this.props.openFiscalPeriod}
                              securityDeposits={this.props.securityDeposits}
                              selectedMonthlyOption={selectedMonthlyOption}
                              utilityAllowanceId={householdUtilityAllowanceId}
                              isReceivingAssistance={isReceivingAssistance}
                              nonOptionalCharge={nonOptionalCharge}
                            />
                          )}
                        </Panel.Body>
                      </Tab>
                    )}
                    {this.props.flags.housingChoiceVouchers &&
                      selectedProperty?.config?.housingChoiceVouchers && (
                        <Tab
                          eventKey="housing-assistance-tab"
                          title={
                            <FormattedMessage
                              {...messages.housingAssistanceVoucherTab}
                            />
                          }
                        >
                          <Panel.Body>
                            <HousingAssistanceVoucherTab
                              organizationId={selectedProperty.organizationId}
                              propertyId={selectedProperty.id}
                              householdId={householdId}
                              intl={this.props.intl}
                            />
                          </Panel.Body>
                        </Tab>
                      )}
                    {showCamTab && (
                      <Tab
                        eventKey="8"
                        title={<FormattedMessage {...messages.camTab} />}
                      >
                        {this.state.key === '8' && (
                          <Panel.Body>
                            <CamTab
                              unit={unit}
                              selectedProperty={selectedProperty}
                              householdId={householdId}
                              intl={intl}
                              promptToaster={this.props.actions.promptToaster}
                            ></CamTab>
                          </Panel.Body>
                        )}
                      </Tab>
                    )}
                    {affordableTabs &&
                      affordableTabs.map(
                        ({
                          affordableQualificationId,
                          programId,
                          programName,
                          affordableQualification,
                          propertyAffordableProgramId,
                        }) => {
                          const urlProgramName = (programName ?? '').replace(
                            /\//g,
                            '_',
                          );
                          return (
                            <Tab
                              key={`${urlProgramName}`}
                              eventKey={`${urlProgramName}`}
                              title={`${programName} Qualification`}
                            >
                              <Panel.Body>
                                {programName !== 'RD' ? (
                                  <>
                                    {this.props.flags
                                      .customerOverhaulAffordableTabs ? (
                                      <GeneralAffordableNonHUD
                                        tabKey={this.state.key}
                                        affordableQualificationId={
                                          affordableQualificationId
                                        }
                                        applicationId={currentApplicationId}
                                        householdId={householdId}
                                        intl={intl}
                                        programName={programName}
                                        programId={programId}
                                        propertyAffordableProgramId={
                                          propertyAffordableProgramId
                                        }
                                        unit={unit}
                                        refetchProfile={() =>
                                          this.props.actions.getOneApplication(
                                            this.props.currentApplicationId,
                                          )
                                        }
                                        locale={locale}
                                      />
                                    ) : (
                                      <LoadableAffordableQualificationTab
                                        affordableSetup={affordableSetup}
                                        currentApplicationId={
                                          currentApplicationId
                                        }
                                        frMembers={
                                          financiallyResponsibleHousehold
                                        }
                                        checklists={
                                          currentApplication.affordableQualifyingChecklist
                                        }
                                        affordableQualification={{
                                          ...affordableQualification,
                                          voucherEffectiveDate,
                                        }}
                                        unit={unit}
                                        complianceNotes={complianceNotes}
                                        leasedRent={leasedRent}
                                        affordableQualificationId={
                                          affordableQualificationId
                                        }
                                        householdId={householdId}
                                        updateApplication={
                                          this.updateApplication
                                        }
                                        programId={programId}
                                        programName={programName}
                                        propertyAffordableProgramId={
                                          propertyAffordableProgramId
                                        }
                                        fpNonOptionalCharges={
                                          fpNonOptionalCharges
                                        }
                                        editOccupant={this.editHouseholdMember}
                                      />
                                    )}
                                  </>
                                ) : null}
                                {programName === 'RD' ? (
                                  <GeneralAffordableRD
                                    tabKey={this.state.key}
                                    affordableQualificationId={
                                      affordableQualificationId
                                    }
                                    applicationId={currentApplicationId}
                                    householdId={householdId}
                                    programId={programId}
                                    programName={programName}
                                    propertyAffordableProgramId={
                                      propertyAffordableProgramId
                                    }
                                    appliesForPhaseIn={appliesForPhaseIn}
                                    intl={intl}
                                    locale={locale}
                                  />
                                ) : null}
                              </Panel.Body>
                            </Tab>
                          );
                        },
                      )}
                    {hudTab && (
                      <Tab
                        key={`${hudTab.programName}`}
                        eventKey={`${hudTab.programName}`}
                        title={`${hudTab.programName} Qualification`}
                      >
                        <Panel.Body>
                          {this.props.flags
                            .customerOverhaulHudQualificationTab ? (
                            <GeneralAffordableHUD
                              tabKey={this.state.key}
                              affordableQualificationId={
                                hudTab.affordableQualificationId
                              }
                              applicationId={currentApplicationId}
                              householdId={householdId}
                              programId={hudTab.programId}
                              programName={hudTab.programName}
                              propertyAffordableProgramId={
                                hudTab.propertyAffordableProgramId
                              }
                              appliesForPhaseIn={appliesForPhaseIn}
                              isSection236={isSection236}
                              intl={intl}
                              locale={locale}
                            />
                          ) : (
                            <LoadableHUDQualificationTab
                              currentApplicationId={currentApplicationId}
                              frMembers={financiallyResponsibleHousehold}
                              householdMembers={householdMembers}
                              checklists={
                                currentApplication.affordableQualifyingChecklist
                              }
                              affordableQualification={{
                                ...hudTab.affordableQualification,
                                voucherEffectiveDate,
                              }}
                              affordableExceptionValues={affordableException}
                              unit={unit}
                              complianceNotes={complianceNotes}
                              leasedRent={leasedRent}
                              affordableQualificationId={
                                hudTab.affordableQualificationId
                              }
                              householdId={householdId}
                              updateApplication={this.updateApplication}
                              affordableSetup={affordableSetup}
                              programId={hudTab.programId}
                              programName={hudTab.programName}
                              propertyAffordableProgramId={
                                hudTab.propertyAffordableProgramId
                              }
                              appliesForPhaseIn={appliesForPhaseIn}
                              isSection236={isSection236}
                            />
                          )}
                        </Panel.Body>
                      </Tab>
                    )}
                  </Tabs>
                </Panel>
              </Col>
            </Row>
          </Grid>
        </NoPrint>
      </DocumentTitle>
    );
  }
}

ApplicationProfile.contextTypes = {
  store: PropTypes.any,
};

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): Props => {
  const { app, applicationProfile, leaseDataTab, languageProvider } = state;
  return {
    activeTab: pathOr(
      '2',
      ['tab'],
      parse(ownProps.location.search.replace('?', '')),
    ),
    activityTypes: app.activityTypes,
    affordableQualifications: applicationProfile.affordableQualifications,
    applicationDecisionStatus: applicationProfile.applicationDecisionStatuses,
    applicationHousehold: getApplicationHousehold(state),
    applicationStatuses: getApplicationStatusOptions(state),
    assignedUnits: applicationProfile.assignedUnits,
    columnOrder: applicationProfile.columnOrder,
    completedActivities: applicationProfile.completedActivities,
    completedActivitiesMeta: applicationProfile.completedActivitiesMeta,
    allActivities: applicationProfile.allActivities,
    allActivitiesMeta: applicationProfile.allActivitiesMeta,
    contactTypes: app.contactTypes,
    currentApplication: getApplication(state),
    currentApplicationId: ownProps.match.params.applicationId,
    currentUser: getCurrentUser(state),
    customerId: getCustomerId(state),
    deletedApplicants: state.applicationProfile.deletedApplicants,
    financiallyResponsibleHousehold: getFinanciallyResponsibleApplicants(state),
    frNames: getFinanciallyResponsibleFullNames(state),
    nonfrNames: getNonfinanciallyResponsibleAdults(state),
    lateMethods: getLateMethods(state),
    screeningLetterTypes: getScreeningLetterTypes(state),
    leaseSignatureStatuses: leaseDataTab.leaseSignatureStatuses,
    locale: languageProvider.locale,
    monthlyTransactions: applicationProfile.monthlyTransactions,
    openFiscalPeriod: applicationProfile.openFiscalPeriod,
    // $FlowFixMe
    organizationId: pathOr('', ['selectedProperty', 'organizationId'], app),
    propertyClasses: app.propertyClasses,
    pendingActivities: applicationProfile.pendingActivities,
    petBreeds: app.petBreeds,
    petTypes: app.petTypes,
    propertyClassType: getSelectedPropertyClassType(state),
    // $FlowFixMe
    propertyId: pathOr('', ['selectedProperty', 'id'], app),
    relationships: app.relationships,
    referralTypes: app.referralTypes,
    screening: applicationProfile.screening,
    securityDeposits: getSecurityDeposits(state),
    selectedMonthlyOption: applicationProfile.selectedMonthlyOption,
    selectedProperty: app.selectedProperty,
    users: getProspectAssigneesOptions(state),
    states: getStateOptions(state),
    incomeTypes: getIncomeTypeOptions(state),
    specialNeedsDesignations: getSpecialNeedsDesignationOptions(state),
    fpNonOptionalCharges: applicationProfile?.fpNonOptionalCharges ?? [],
    floorPlans: app.floorPlans,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...applicationActions,
      ...prospectActions,
      ...manageDocumentActions,
      change,
      editLease,
      getAllActivityTypes,
      getAllAssignees,
      getAllPetTypes,
      getAllPropertyClasses,
      getAllPetBreeds,
      getAllReferralTypes,
      getAllContactTypes,
      getAllProspectStatus,
      getSignatureStatuses,
      selectProperty,
      updateSelectedProperty,
      getAllStates,
      getAllIncomeTypes,
      getAllSpecialNeedsDesignations,
      getAllFloorPlans,
      promptToaster,
    },
    dispatch,
  );
  return { actions };
}

const InjectedApplicationProfile = injectIntl(ApplicationProfile);
export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(InjectedApplicationProfile),
);
