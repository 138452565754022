import React from 'react';
import { FormattedMessage } from 'react-intl';
import { COMPLIANCE_APPROVAL_STATUSES } from '../../utils/affordable';

import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

export const FORM_NAME = 'CertificationSearch';
export const GROSS_RENT = 'GROSS_RENT';
export const HUD_PROGRAM_NAME = 'HUD';
export const LIHTC_PROGRAM_NAME = 'LIHTC';
export const CERTIFICATION_TYPES = {
  MOVE_IN: 'MOVE_IN',
  RECERT: 'RECERT',
  INITIAL: 'INITIAL',
  INITIAL_UT: 'INITIAL_UT',
  INTERIM: 'INTERIM',
  UNIT_TRANSFER: 'UNIT_TRANSFER',
  TERMINATION: 'TERMINATION',
  MOVE_OUT: 'MOVE_OUT',
};

export const OTHER_HUD_CERTIFICATION_TYPES = [
  'INTERIM',
  'TERMINATION',
  'MOVE_OUT',
  'UNIT_TRANSFER',
  'RECERT_UT',
  'INITIAL_UT',
  'INTERIM_UT',
];

export const OTHER_LIHTC_CERTIFICATION_TYPES = [
  'INTERIM',
  'INTERIM_UT',
  'UNIT_TRANSFER',
];

export const CERTIFICATION_TYPES_FOUND_INTL = {
  MOVE_IN: 'moveInsFound',
  INITIAL: 'initialsFound',
  OTHER_HUD: 'otherFound',
  OTHER_LIHTC: 'interimsFound',
};

export const CERTIFICATION_TYPES_INTL = {
  INTERIM: 'interim',
  INTERIM_UT: 'interimUt',
  UNIT_TRANSFER: 'unitTransfer',
  TERMINATION: 'termination',
  MOVE_OUT: 'moveOut',
  OTHER_HUD: 'otherHud',
  OTHER_LIHTC: 'interim',
  INITIAL: 'initialCert',
  INITIAL_UT: 'initialUt',
  MOVE_IN: 'moveInCert',
  RECERT_UT: 'recertUt',
};

export const OTHER_HUD = 'OTHER_HUD';
export const OTHER_LIHTC = 'OTHER_LIHTC';

export const INITIAL_RECERT_FILTER_SELECTIONS = {
  recertDue: {
    late: false,
    below30: false,
    between30And60: false,
    between60And90: false,
    between90And120: false,
  },
  recertStatus: {
    approved: false,
    'approved pending signatures': false,
    pending: false,
    denied: false,
    notStarted: false,
  },
  recertProgramName: {
    HUD: false,
    LIHTC: false,
  },
  complianceDocument: {},
};

export const RECERT_FILTER_OPTIONS = (
  complianceDocumentFilterGroups: Array<string>,
) => {
  const complianceDocumentOptions: Array<{ value: string, text: string }> =
    complianceDocumentFilterGroups
      .map((value) => ({
        value,
        text: value,
      }))
      .concat({
        value: '---',
        text: '--- (No document uploaded)',
      });
  return [
    {
      fieldName: 'recertDue',
      fieldDescription: 'Recert Due',
      options: [
        { value: 'lateRecerts', text: 'Late' },
        { value: 'under30', text: '0-30 day recerts' },
        { value: 'between31And60', text: '31-60 day recerts' },
        { value: 'between61And90', text: '61-90 day recerts' },
        { value: 'between90And120', text: '91-120 day recerts' },
      ],
    },
    {
      fieldName: 'recertStatus',
      fieldDescription: 'Recert Status',
      options: [
        COMPLIANCE_APPROVAL_STATUSES.APPROVED,
        COMPLIANCE_APPROVAL_STATUSES.APPROVED_PENDING_SIGN,
        COMPLIANCE_APPROVAL_STATUSES.PENDING,
        COMPLIANCE_APPROVAL_STATUSES.PENDING_FINAL_APPROVAL,
        COMPLIANCE_APPROVAL_STATUSES.CORRECTION_NEEDED,
        COMPLIANCE_APPROVAL_STATUSES.CORRECTION_NEEDED_FINAL,
        COMPLIANCE_APPROVAL_STATUSES.DENIED,
        COMPLIANCE_APPROVAL_STATUSES.ON_NOTICE_HOLD,
        COMPLIANCE_APPROVAL_STATUSES.NOT_STARTED,
      ].map((s) => ({ value: s, text: s })),
    },
    {
      fieldName: 'recertProgramName',
      fieldDescription: 'Program Name',
      options: [
        { value: 'HUD', text: 'HUD' },
        { value: 'LIHTC', text: 'LIHTC' },
      ],
    },
    {
      fieldName: 'complianceDocument',
      fieldDescription: 'Compliance Document',
      options: complianceDocumentOptions,
    },
  ];
};

export const getCertificationsInitialFilterOptions = (
  certificationType: string,
) => {
  let certificationTypeSpecificOptions = {};
  if (certificationType === CERTIFICATION_TYPES.MOVE_IN) {
    certificationTypeSpecificOptions = {
      isApplicant: {
        applicant: false,
        resident: true,
      },
    };
  }

  if (certificationType === CERTIFICATION_TYPES.INITIAL) {
    certificationTypeSpecificOptions = {
      isPriorResident: {
        resident: true,
        priorResident: false,
      },
    };
  }

  if (certificationType === OTHER_HUD) {
    certificationTypeSpecificOptions = {
      isPriorResident: {
        resident: true,
        priorResident: true,
      },
      certificationType: {},
    };
  }

  if (certificationType === OTHER_LIHTC) {
    certificationTypeSpecificOptions = {
      isPriorResident: {
        resident: true,
        priorResident: true,
      },
    };
  }

  return {
    ...certificationTypeSpecificOptions,
    ...(![OTHER_HUD, OTHER_LIHTC].includes(certificationType)
      ? { programName: {} }
      : {}),
    complianceApprovalStatus: {},
    complianceDocument: {},
  };
};

export const getCertificationsDefaultFilterOptions = (
  certificationType: string,
  complianceDocumentFilterGroups: Array<string>,
  complianceApprovalStatuses: Array<Object>,
  propertyAffordablePrograms: Array<Object>,
  intl: Object,
): Array<Object> => {
  const complianceDocumentOptions: Array<{ value: string, text: string }> = (
    complianceDocumentFilterGroups ?? []
  )
    .map((value) => ({
      value,
      text: value,
    }))
    .concat({
      value: '---',
      text: '--- (No document uploaded)',
    });
  const complianceApprovalStatusesOptions: Array<{
    value: string,
    text: string,
  }> = (complianceApprovalStatuses ?? []).map((status) => ({
    value: status.id,
    text: status.name,
  }));

  const programOptions: Array<{
    value: string,
    text: string,
  }> = (propertyAffordablePrograms ?? []).map((status) => ({
    value: status.id,
    text: status.name,
  }));

  let certificationTypeSpecificOptions = [];

  if (certificationType === CERTIFICATION_TYPES.MOVE_IN) {
    certificationTypeSpecificOptions = [
      {
        fieldName: 'isApplicant',
        fieldDescription: 'Household Type',
        options: [
          { value: 'applicant', text: 'Applicant' },
          { value: 'resident', text: 'Resident' },
        ],
      },
    ];
  }

  if (certificationType === CERTIFICATION_TYPES.INITIAL) {
    certificationTypeSpecificOptions = [
      {
        fieldName: 'isPriorResident',
        fieldDescription: 'Household Type',
        options: [
          { value: 'resident', text: 'Resident' },
          { value: 'priorResident', text: 'Prior Resident' },
        ],
      },
    ];
  }

  if (certificationType === OTHER_HUD) {
    certificationTypeSpecificOptions = [
      {
        fieldName: 'isPriorResident',
        fieldDescription: 'Household Type',
        options: [
          { value: 'resident', text: 'Resident' },
          { value: 'priorResident', text: 'Prior Resident' },
        ],
      },
      {
        fieldName: 'certificationType',
        fieldDescription: 'Cert Type',
        options: OTHER_HUD_CERTIFICATION_TYPES.map((certType) => ({
          value: certType,
          text: intl.formatMessage(
            messages[CERTIFICATION_TYPES_INTL[certType]],
          ),
        })),
      },
    ];
  }

  if (certificationType === OTHER_LIHTC) {
    certificationTypeSpecificOptions = [
      {
        fieldName: 'isPriorResident',
        fieldDescription: 'Household Type',
        options: [
          { value: 'resident', text: 'Resident' },
          { value: 'priorResident', text: 'Prior Resident' },
        ],
      },
    ];
  }

  const defaultOptions = [
    {
      fieldName: 'complianceApprovalStatus',
      fieldDescription: 'Certification Status',
      options: complianceApprovalStatusesOptions,
    },
    ...(certificationType !== OTHER_HUD
      ? [
          {
            fieldName: 'programName',
            fieldDescription: 'Program Name',
            options: programOptions,
          },
        ]
      : []),
    {
      fieldName: 'complianceDocument',
      fieldDescription: 'Compliance Document',
      options: complianceDocumentOptions,
    },
  ];

  return [...certificationTypeSpecificOptions, ...defaultOptions];
};

export const HEADERS = {
  [CERTIFICATION_TYPES.RECERT]: [
    {
      id: 'recertStatus',
      title: <FormattedMessage {...messages.recertStatus} />,
    },
    {
      id: 'programName',
      title: <FormattedMessage {...messages.programName} />,
    },
    { id: 'unit', title: <FormattedMessage {...messages.unit} /> },
    {
      id: 'floorPlan',
      title: <FormattedMessage {...messages.floorPlan} />,
    },
    {
      id: 'headOfHousehold',
      title: <FormattedMessage {...messages.headOfHousehold} />,
    },
    {
      id: 'moveInDateRaw',
      title: <FormattedMessage {...messages.moveIn} />,
    },
    {
      id: 'leaseStartDateRaw',
      title: <FormattedMessage {...messages.leaseStartDate} />,
    },
    {
      id: 'lastCertifiedDateRaw',
      title: <FormattedMessage {...messages.lastCertified} />,
    },
    {
      id: 'recertDueDate',
      title: <FormattedMessage {...messages.recertDue} />,
    },
    {
      id: 'occupiedOnNTV',
      title: <FormattedMessage {...messages.noticeToVacate} />,
    },
    {
      id: 'doNotRenew',
      title: <FormattedMessage {...messages.doNotRenew} />,
    },
    {
      id: 'underEviction',
      title: <FormattedMessage {...messages.underEviction} />,
    },
    {
      id: 'balance',
      title: <FormattedMessage {...messages.balance} />,
    },
    {
      id: 'notice120',
      title: <FormattedMessage {...messages.notice120} />,
    },
    {
      id: 'notice90',
      title: <FormattedMessage {...messages.notice90} />,
    },
    {
      id: 'notice60',
      title: <FormattedMessage {...messages.notice60} />,
    },
    {
      id: 'notice30',
      title: <FormattedMessage {...messages.notice30} />,
    },
    {
      id: 'complianceDocument',
      title: <FormattedMessage {...messages.complianceDocument} />,
    },
    {
      id: 'certificationNotes',
      title: <FormattedMessage {...messages.certificationNotes} />,
    },
  ],
  [GROSS_RENT]: [
    {
      id: 'unit',
      title: <FormattedMessage {...messages.unit} />,
    },
    {
      id: 'floorPlanName',
      title: <FormattedMessage {...messages.floorPlan} />,
    },
    {
      id: 'headOfHousehold',
      title: <FormattedMessage {...messages.headOfHousehold} />,
    },
    {
      id: 'newGrossRent',
      title: <FormattedMessage {...messages.newGrossRent} />,
    },
    {
      id: 'newHUDUA',
      title: <FormattedMessage {...messages.newHUDUA} />,
    },
    {
      id: 'grEffectiveDate',
      title: <FormattedMessage {...messages.grEffectiveDate} />,
    },
    {
      id: 'anticipatedVoucherDate',
      title: <FormattedMessage {...messages.anticipatedVoucherDate} />,
    },
    {
      id: 'priorCertificationDate',
      title: <FormattedMessage {...messages.priorCertificationDate} />,
    },
    {
      id: 'otherCertInProgress',
      title: <FormattedMessage {...messages.otherCertInProgress} />,
    },
    {
      id: 'occupiedOnNTV',
      title: <FormattedMessage {...messages.noticeToVacate} />,
    },
    {
      id: 'newSubsidy',
      title: <FormattedMessage {...messages.newSubsidy} />,
      classes: 'header-darker',
    },
    {
      id: 'newResidentRent',
      title: <FormattedMessage {...messages.newResidentRent} />,
      classes: 'header-darker',
    },
    {
      id: 'newUtilityReimbursement',
      title: <FormattedMessage {...messages.newUtilityReimbursement} />,
      classes: 'header-darker',
    },
    {
      id: 'residentSignatureRequired',
      title: <FormattedMessage {...messages.residentSignatureRequired} />,
    },
    {
      id: 'correction',
      title: <FormattedMessage {...messages.correction} />,
    },
    {
      id: 'generated59a',
      title: <FormattedMessage {...messages.generated59a} />,
    },
    {
      id: 'uploadedDocument',
      title: <FormattedMessage {...messages.uploadedDocument} />,
    },
    {
      id: 'generate59a',
      title: <FormattedMessage {...messages.generate59a} />,
      order: null,
    },
    {
      id: 'action',
      title: <FormattedMessage {...messages.action} />,
      order: null,
    },
  ],
  [CERTIFICATION_TYPES.MOVE_IN]: [
    {
      id: 'certStatus',
      title: <FormattedMessage {...messages.certStatus} />,
    },
    {
      id: 'programName',
      title: <FormattedMessage {...messages.programType} />,
    },
    {
      id: 'unit',
      title: <FormattedMessage {...messages.unit} />,
    },
    {
      id: 'floorplan',
      title: <FormattedMessage {...messages.floorPlan} />,
    },
    {
      id: 'headOfHousehold',
      title: <FormattedMessage {...messages.headOfHousehold} />,
    },
    {
      id: 'scheduledMoveInDate',
      title: <FormattedMessage {...messages.scheduledMoveInDate} />,
    },
    {
      id: 'leaseStartDate',
      title: <FormattedMessage {...messages.leaseStartDate} />,
    },
    {
      id: 'isResident',
      title: <FormattedMessage {...messages.currentResident} />,
    },
    {
      id: 'balance',
      title: <FormattedMessage {...messages.balance} />,
    },
    {
      id: 'isCorrection',
      title: <FormattedMessage {...messages.correction} />,
    },
    {
      id: 'latestComplianceDocument',
      title: <FormattedMessage {...messages.complianceDocument} />,
    },
    {
      id: 'daysSinceLastUpload',
      title: <FormattedMessage {...messages.daysSinceLastUpload} />,
    },
    {
      id: 'certificationNotes',
      title: <FormattedMessage {...messages.certificationNotes} />,
    },
  ],
  [CERTIFICATION_TYPES.INITIAL]: [
    {
      id: 'certStatus',
      title: <FormattedMessage {...messages.certStatus} />,
    },
    {
      id: 'programName',
      title: <FormattedMessage {...messages.programType} />,
    },
    {
      id: 'unit',
      title: <FormattedMessage {...messages.unit} />,
    },
    {
      id: 'floorplan',
      title: <FormattedMessage {...messages.floorPlan} />,
    },
    {
      id: 'headOfHousehold',
      title: <FormattedMessage {...messages.headOfHousehold} />,
    },
    {
      id: 'effectiveDate',
      title: <FormattedMessage {...messages.effectiveDate} />,
    },
    {
      id: 'leaseStartDate',
      title: <FormattedMessage {...messages.leaseStartDate} />,
    },
    {
      id: 'isPriorResident',
      title: <FormattedMessage {...messages.priorResident} />,
    },
    {
      id: 'balance',
      title: <FormattedMessage {...messages.balance} />,
    },
    {
      id: 'isCorrection',
      title: <FormattedMessage {...messages.correction} />,
    },
    {
      id: 'latestComplianceDocument',
      title: <FormattedMessage {...messages.complianceDocument} />,
    },
    {
      id: 'daysSinceLastUpload',
      title: <FormattedMessage {...messages.daysSinceLastUpload} />,
    },
    {
      id: 'certificationNotes',
      title: <FormattedMessage {...messages.certificationNotes} />,
    },
  ],
  [OTHER_LIHTC]: [
    {
      id: 'certStatus',
      title: <FormattedMessage {...messages.certStatus} />,
    },
    {
      id: 'programName',
      title: <FormattedMessage {...messages.programType} />,
    },
    {
      id: 'unit',
      title: <FormattedMessage {...messages.unit} />,
    },
    {
      id: 'floorplan',
      title: <FormattedMessage {...messages.floorPlan} />,
    },
    {
      id: 'headOfHousehold',
      title: <FormattedMessage {...messages.headOfHousehold} />,
    },
    {
      id: 'effectiveDate',
      title: <FormattedMessage {...messages.effectiveDate} />,
    },
    {
      id: 'lastCertifiedDate',
      title: <FormattedMessage {...messages.lastCertifiedDate} />,
    },
    {
      id: 'nextRecertDueDate',
      title: <FormattedMessage {...messages.nextRecertDueDate} />,
    },
    {
      id: 'isPriorResident',
      title: <FormattedMessage {...messages.priorResident} />,
    },
    {
      id: 'isCorrection',
      title: <FormattedMessage {...messages.correction} />,
    },
    {
      id: 'balance',
      title: <FormattedMessage {...messages.balance} />,
    },
    {
      id: 'latestComplianceDocument',
      title: <FormattedMessage {...messages.complianceDocument} />,
    },
    {
      id: 'daysSinceLastUpload',
      title: <FormattedMessage {...messages.daysSinceLastUpload} />,
    },
    {
      id: 'certificationNotes',
      title: <FormattedMessage {...messages.certificationNotes} />,
    },
  ],
  [OTHER_HUD]: [
    {
      id: 'certStatus',
      title: <FormattedMessage {...messages.certStatus} />,
    },
    {
      id: 'certificationType',
      title: <FormattedMessage {...messages.certType} />,
    },
    {
      id: 'unit',
      title: <FormattedMessage {...messages.unit} />,
    },
    {
      id: 'floorplan',
      title: <FormattedMessage {...messages.floorPlan} />,
    },
    {
      id: 'headOfHousehold',
      title: <FormattedMessage {...messages.headOfHousehold} />,
    },
    {
      id: 'effectiveDate',
      title: <FormattedMessage {...messages.effectiveDate} />,
    },
    {
      id: 'lastCertifiedDate',
      title: <FormattedMessage {...messages.lastCertifiedDate} />,
    },
    {
      id: 'nextRecertDueDate',
      title: <FormattedMessage {...messages.nextRecertDueDate} />,
    },
    {
      id: 'isPriorResident',
      title: <FormattedMessage {...messages.priorResident} />,
    },
    {
      id: 'isCorrection',
      title: <FormattedMessage {...messages.correction} />,
    },
    {
      id: 'balance',
      title: <FormattedMessage {...messages.balance} />,
    },
    {
      id: 'latestComplianceDocument',
      title: <FormattedMessage {...messages.complianceDocument} />,
    },
    {
      id: 'daysSinceLastUpload',
      title: <FormattedMessage {...messages.daysSinceLastUpload} />,
    },
    {
      id: 'certificationNotes',
      title: <FormattedMessage {...messages.certificationNotes} />,
    },
  ],
};
