import { defineMessages } from 'react-intl';

const messages = defineMessages({
  accept: {
    id: 'App.General.Accept',
    defaultMessage: 'Accept',
  },
  actions: {
    id: 'App.General.Actions',
    defaultMessage: 'Actions',
  },
  active: {
    id: 'App.General.Active',
    defaultMessage: 'Active',
  },
  actual: {
    id: 'App.General.Actual',
    defaultMessage: 'Actual',
  },
  add: {
    id: 'App.General.Add',
    defaultMessage: 'Add',
  },
  addNewCustom: {
    id: 'App.General.AddNewCustom',
    defaultMessage: 'Add New {custom}',
  },
  all: {
    id: 'App.General.All',
    defaultMessage: 'All',
  },
  allFieldsAreRequired: {
    id: 'App.General.AllFieldsAreRequired',
    defaultMessage: 'All fields are required',
  },
  amount: {
    id: 'App.General.Amount',
    defaultMessage: 'Amount',
  },
  appliedFilters: {
    id: 'App.General.AppliedFilters',
    defaultMessage: 'Applied Filters',
  },
  apply: {
    id: 'App.General.Apply',
    defaultMessage: 'Apply',
  },
  applicant: {
    id: 'App.General.Applicant',
    defaultMessage: 'Applicant',
  },
  approved: {
    id: 'App.General.Approved',
    defaultMessage: 'Approved',
  },
  approvedPendingSign: {
    id: 'App.General.ApprovedPendingSign',
    defaultMessage: 'Approved Pending Signature',
  },
  assets: {
    id: 'App.General.Assets',
    defaultMessage: 'Assets',
  },
  assignCustom: {
    id: 'App.General.AssignCustom',
    defaultMessage: 'Assign {custom}',
  },
  assigned: {
    id: 'App.General.Assigned',
    defaultMessage: 'Assigned',
  },
  attentionRequired: {
    id: 'App.General.AttentionRequired',
    defaultMessage: 'Attention Required',
  },
  backTo: {
    id: 'App.General.BackTo',
    defaultMessage: 'Back to {route}',
  },
  browse: {
    id: 'App.General.Browse',
    defaultMessage: 'Browse',
  },
  cancel: {
    id: 'App.General.Cancel',
    defaultMessage: 'Cancel',
  },
  cancelProgress: {
    id: 'App.General.CancelProgress',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  certificationType: {
    id: 'App.General.CertificationType',
    defaultMessage: 'Certification Type',
  },
  choose: {
    id: 'App.General.Choose',
    defaultMessage: 'Choose',
  },
  changedOn: {
    id: 'App.General.ChangedOn',
    defaultMessage: 'Changed On',
  },
  changedBy: {
    id: 'App.General.ChangedBy',
    defaultMessage: 'Changed By',
  },
  clearAll: {
    id: 'App.General.ClearAll',
    defaultMessage: 'Clear All',
  },
  clearFilters: {
    id: 'App.General.ClearFilters',
    defaultMessage: 'Clear Filters',
  },
  close: {
    id: 'App.General.Close',
    defaultMessage: 'Close',
  },
  commercial: {
    id: 'App.General.Commercial',
    defaultMessage: 'Commercial',
  },
  compliance: {
    id: 'App.General.Compliance',
    defaultMessage: 'Compliance',
  },
  status: {
    id: 'App.General.Status',
    defaultMessage: 'Status',
  },
  certificationNotes: {
    id: 'App.General.CertificationNotes',
    defaultMessage: 'Certification Notes',
  },
  certifyAndGenerateCustom: {
    id: 'App.General.CertifyAndGenerateCustom',
    defaultMessage: 'Certify & Generate {custom}',
  },
  complianceApprovalStatus: {
    id: 'App.General.ComplianceApprovalStatus',
    defaultMessage: 'Compliance Approval Status',
  },
  complianceDocument: {
    id: 'App.General.ComplianceDocument',
    defaultMessage: 'Compliance Document',
  },
  complianceNotes: {
    id: 'App.General.ComplianceNotes',
    defaultMessage: 'Compliance Notes',
  },
  confirm: {
    id: 'App.General.Confirm',
    defaultMessage: 'Confirm',
  },
  correctionNeeded: {
    id: 'App.General.CorrectionNeeded',
    defaultMessage: 'Correction Needed',
  },
  correctionNeededFinal: {
    id: 'App.General.CorrectionNeededFinal',
    defaultMessage: 'Correction Needed - Final Packet',
  },
  createdByOn: {
    id: 'App.General.CreatedByOn',
    defaultMessage: 'Created By/On',
  },
  currentMarketRent: {
    id: 'App.General.CurrentMarketRent',
    defaultMessage: 'Current Base Market Rent',
  },
  currentQuotingRent: {
    id: 'App.General.CurrentQuotingRent',
    defaultMessage: 'Current Quoting Rent',
  },
  currentSuggestedDeposit: {
    id: 'App.General.CurrentSuggestedDeposit',
    defaultMessage: 'Current Suggested Deposit',
  },
  dateByOn: {
    id: 'App.General.DateByOn',
    defaultMessage: '{by} on {date}',
  },
  daysSince: {
    id: 'App.General.DaysSince',
    defaultMessage: 'Days Since {message}',
  },
  description: {
    id: 'App.General.Description',
    defaultMessage: 'Description',
  },
  documentNotes: {
    id: 'App.General.DocumentNotes',
    defaultMessage: 'Document Notes',
  },
  documentType: {
    id: 'App.General.DocumentType',
    defaultMessage: 'Document Type',
  },
  doNotInclude: {
    id: 'App.General.DoNotInclude',
    defaultMessage: 'Do not include',
  },
  duplicateIdentifiers: {
    id: 'App.General.Validation.Identifiers.Duplicate',
    defaultMessage: '{item} must have unique {identifiers}',
  },
  duplicates: {
    id: 'App.General.Duplicates',
    defaultMessage: 'Duplicates',
  },
  edit: {
    id: 'App.General.Edit',
    defaultMessage: 'Edit',
  },
  editHouseholdBlocked: {
    id: 'App.General.EditHouseholdBlocked',
    defaultMessage:
      // eslint-disable-next-line max-len
      'There is an open certification for this household. In order to make changes to this household, you will need to cancel or complete the open certification.',
  },
  effectiveDate: {
    id: 'App.General.EffectiveDate',
    defaultMessage: 'Effective Date',
  },
  effectiveDateDisabledTooltip: {
    id: 'App.General.EffectiveDateDisabledTooltip',
    defaultMessage: 'You do not have permission to edit this date.',
  },
  endDate: {
    id: 'App.General.EndDate',
    defaultMessage: 'End Date',
  },
  enterCustom: {
    id: 'App.General.EnterCustom',
    defaultMessage: 'Enter {custom}',
  },
  error: {
    id: 'App.General.Error',
    defaultMessage: 'Error',
  },
  errorDownloading: {
    id: 'App.General.ErrorDownloading',
    defaultMessage: 'Error while downloading',
  },
  fail: {
    id: 'App.General.Fail',
    defaultMessage: 'Fail',
  },
  failedDocumentDownload: {
    id: 'App.General.FailedDocumentDownload',
    defaultMessage: 'This file is not available to display',
  },
  filename: {
    id: 'App.General.Filename',
    defaultMessage: 'Filename',
  },
  filterBy: {
    id: 'App.General.FilterBy',
    defaultMessage: 'Filter By',
  },
  filterByCustom: {
    id: 'App.General.FilterByCustom',
    defaultMessage: 'Filter by {custom}',
  },
  final: {
    id: 'App.General.Final',
    defaultMessage: 'Final',
  },
  finalSignedPacket: {
    id: 'App.General.FinalSignedPacket',
    defaultMessage: 'Final Signed Packet',
  },
  financialMarketRent: {
    id: 'App.General.FinancialMarketRent',
    defaultMessage: 'Financial Market Rent',
  },
  floorPlan: {
    id: 'App.General.FloorPlan',
    defaultMessage: 'Floor Plan',
  },
  fortressTitle: {
    id: 'App.General.FortressTitle',
    defaultMessage: 'Fortress - {title}',
  },
  grossRentLimit: {
    id: 'App.General.GrossRentLimit',
    defaultMessage: 'Gross Rent Limit',
  },
  group: {
    id: 'App.General.Group',
    defaultMessage: 'Group',
  },
  groupName: {
    id: 'App.General.GroupName',
    defaultMessage: 'Group Name',
  },
  groups: {
    id: 'App.General.Groups',
    defaultMessage: 'Groups',
  },
  household: {
    id: 'App.General.Household',
    defaultMessage: 'Household',
  },
  hud: {
    id: 'App.General.Hud',
    defaultMessage: 'HUD',
  },
  hud236BasicRent: {
    id: 'App.General.Hud236BasicRent',
    defaultMessage: '236 Basic Rent',
  },
  hud236MarketRent: {
    id: 'App.General.Hud236MarketRent',
    defaultMessage: '236 Market Rent',
  },
  hudFinalSignedPacket: {
    id: 'App.General.HudFinalSignedPacket',
    defaultMessage: 'HUD Final Signed Packet',
  },
  hudGrossRentLimit: {
    id: 'App.General.HUDGrossRentLimit',
    defaultMessage: 'HUD Gross Rent Limit',
  },
  kpiData: {
    id: 'App.General.KPIData',
    defaultMessage: 'KPI Data',
  },
  noteRent: {
    id: 'App.General.NoteRent',
    defaultMessage: 'Note Rent',
  },
  basicRent: {
    id: 'App.General.BasicRent',
    defaultMessage: 'Basic Rent',
  },
  hudReviewedBy: {
    id: 'App.General.HudReviewedBy',
    defaultMessage: 'HUD Reviewed By',
  },
  impute: {
    id: 'App.General.Impute',
    defaultMessage: 'Impute',
  },
  income: {
    id: 'App.Generall.Income',
    defaultMessage: 'Income',
  },
  incomeLimit: {
    id: 'App.Generall.IncomeLimit',
    defaultMessage: 'Income Limit',
  },
  initial: {
    id: 'App.General.Initial',
    defaultMessage: 'Initial',
  },
  interim: {
    id: 'App.General.Interim',
    defaultMessage: 'Interim',
  },
  lastEditToPrimary: {
    id: 'App.General.LastEditToPrimary',
    defaultMessage: 'Last edit to Primary on {dateTime}',
  },
  lastModifiedByOn: {
    id: 'App.General.LastModifiedByOn',
    defaultMessage: 'Last Modified By/On',
  },
  lihtc: {
    id: 'App.General.Lihtc',
    defaultMessage: 'LIHTC',
  },
  manage: {
    id: 'App.General.Manage',
    defaultMessage: 'Manage',
  },
  managePropertyGroups: {
    id: 'App.General.ManagePropertyGroups',
    defaultMessage: 'Manage Property Groups',
  },
  mhaUtilityAllowance: {
    id: 'App.General.MHAUtilityAllowance',
    defaultMessage: 'MHA Utility Allowance',
  },
  mhdaUtilityAllowance: {
    id: 'App.General.MHDAUtilityAllowance',
    defaultMessage: 'MHDA Utility Allowance',
  },
  moveIn: {
    id: 'App.General.MoveIn',
    defaultMessage: 'Move-In',
  },
  moveOut: {
    id: 'App.General.MoveOut',
    defaultMessage: 'Move-Out',
  },
  name: {
    id: 'App.General.Name',
    defaultMessage: 'Name',
  },
  no: {
    id: 'App.General.No',
    defaultMessage: 'No',
  },
  noDataDescription: {
    id: 'App.General.NoDataDescription',
    defaultMessage: 'There is currently no data to display.',
  },
  noDataFound: {
    id: 'App.General.NoDataFound',
    defaultMessage: 'No Data Found',
  },
  noDataFoundCustom: {
    id: 'App.General.NoDataFoundCustom',
    defaultMessage: 'No {custom} Found',
  },
  noDataSelectedCustom: {
    id: 'App.General.NoDataSelectedCustom',
    defaultMessage: 'No {custom} Selected',
  },
  noDocumentUploaded: {
    id: 'App.General.NoDocumentUploaded',
    defaultMessage: '--- (No document uploaded)',
  },
  nonOptionalCharge: {
    id: 'App.General.NonOptionalCharge',
    defaultMessage: 'Non-Optional Charge',
  },
  nonOptionalCharges: {
    id: 'App.General.NonOptionalCharges',
    defaultMessage: 'Non-Optional Charges',
  },
  none: {
    id: 'App.General.None',
    defaultMessage: 'None',
  },
  noteCustom: {
    id: 'App.General.NoteCustom',
    defaultMessage: 'Note: {note}',
  },
  notes: {
    id: 'App.General.Notes',
    defaultMessage: 'Notes',
  },
  numberOfCustom: {
    id: 'App.General.NumberOfCustom',
    defaultMessage: '# of {custom}',
  },
  off: {
    id: 'App.General.Off',
    defaultMessage: 'Off',
  },
  on: {
    id: 'App.General.On',
    defaultMessage: 'On',
  },
  onNoticeHold: {
    id: 'App.General.OnNoticeHold',
    defaultMessage: 'Household on Notice / Hold',
  },
  optional: {
    id: 'App.General.Optional',
    defaultMessage: 'Optional',
  },
  override: {
    id: 'App.General.Override',
    defaultMessage: 'Override',
  },
  overridden: {
    id: 'App.General.Overridden',
    defaultMessage: 'Overridden',
  },
  pass: {
    id: 'App.General.Pass',
    defaultMessage: 'Pass',
  },
  pastDue: {
    id: 'App.General.PastDue',
    defaultMessage: 'Past Due',
  },
  people: {
    id: 'App.General.People',
    defaultMessage: 'People',
  },
  pending: {
    id: 'App.General.Pending',
    defaultMessage: 'Pending',
  },
  pendingFinalApproval: {
    id: 'App.General.PendingFinalApproval',
    defaultMessage: 'Pending Final Approval',
  },
  person: {
    id: 'App.General.Person',
    defaultMessage: 'Person',
  },
  programType: {
    id: 'App.General.ProgramType',
    defaultMessage: 'Program Type',
  },
  properties: {
    id: 'App.General.Properties',
    defaultMessage: 'Properties',
  },
  property: {
    id: 'App.General.Property',
    defaultMessage: 'Property',
  },
  propertyGroup: {
    id: 'App.General.PropertyGroup',
    defaultMessage: 'Property Group',
  },
  propertyGroups: {
    id: 'App.General.PropertyGroups',
    defaultMessage: 'Property Groups',
  },
  propertyProgram: {
    id: 'App.General.PropertyProgram',
    defaultMessage: 'Property Program',
  },
  propertyType: {
    id: 'App.General.PropertyType',
    defaultMessage: 'Property Type',
  },
  reasonForCorrection: {
    id: 'App.General.ReasonForCorrection',
    defaultMessage: 'Reason for Correction',
  },
  recert: {
    id: 'App.General.Recert',
    defaultMessage: 'Recert',
  },
  reject: {
    id: 'App.General.Reject',
    defaultMessage: 'Reject',
  },
  removeAll: {
    id: 'App.General.RemoveAll',
    defaultMessage: 'Remove All',
  },
  required: {
    id: 'App.General.Required',
    defaultMessage: 'Required',
  },
  resident: {
    id: 'App.General.Resident',
    defaultMessage: 'Resident',
  },
  reviewed: {
    id: 'App.General.Reviewed',
    defaultMessage: 'Reviewed',
  },
  reviewedBy: {
    id: 'App.General.ReviewedBy',
    defaultMessage: 'Reviewed By',
  },
  save: {
    id: 'App.General.Save',
    defaultMessage: 'Save',
  },
  saveAndClose: {
    id: 'App.General.SaveAndClose',
    defaultMessage: 'Save and Close',
  },
  search: {
    id: 'App.General.Search',
    defaultMessage: 'Search',
  },
  searchForACustom: {
    id: 'App.General.SearchForA',
    defaultMessage: 'Search for a {custom}',
  },
  select: {
    id: 'App.General.Select',
    defaultMessage: 'Select',
  },
  selectAll: {
    id: 'App.General.SelectAll',
    defaultMessage: 'Select All',
  },
  selectAllCustom: {
    id: 'App.General.SelectAllCustom',
    defaultMessage: 'Select All {custom}',
  },
  selectFromLeft: {
    id: 'App.General.SelectFromLeft',
    defaultMessage: 'Select from the left column to assign',
  },
  selectedItems: {
    id: 'App.General.SelectedItems',
    defaultMessage: 'Selected Items',
  },
  setting: {
    id: 'App.General.Setting',
    defaultMessage: 'Setting',
  },
  skipped: {
    id: 'App.General.Skipped',
    defaultMessage: 'Skipped',
  },
  specialNeeds: {
    id: 'App.General.SpecialNeeds',
    defaultMessage: 'Special Needs',
  },
  specialNeedsCount: {
    id: 'App.General.SpecialNeedsCount',
    defaultMessage: 'Special Needs ({count})',
  },
  startDate: {
    id: 'App.General.StartDate',
    defaultMessage: 'Start Date',
  },
  submission: {
    id: 'App.General.Submission',
    defaultMessage: 'Submission',
  },
  success: {
    id: 'App.General.Success',
    defaultMessage: 'Success',
  },
  suggestedDeposit: {
    id: 'App.General.SuggestedDeposit',
    defaultMessage: 'Suggested Deposit',
  },
  summary: {
    id: 'App.General.Summary',
    defaultMessage: 'Summary',
  },
  termination: {
    id: 'App.General.Termination',
    defaultMessage: 'Termination',
  },
  thdaUtilityAllowance: {
    id: 'App.General.THDAUtilityAllowance',
    defaultMessage: 'THDA Utility Allowance',
  },
  total: {
    id: 'App.General.Total',
    defaultMessage: 'Total',
  },
  tripleDash: {
    id: 'App.General.TripleDash',
    defaultMessage: '---',
  },
  type: {
    id: 'App.General.Type',
    defaultMessage: 'Type',
  },
  unit: {
    id: 'App.General.Unit',
    defaultMessage: 'Unit',
  },
  unitTransfer: {
    id: 'App.General.UnitTransfer',
    defaultMessage: 'Unit Transfer',
  },
  utilityAllowance: {
    id: 'App.General.UtilityAllowance',
    defaultMessage: 'Utility Allowance',
  },
  utilityType: {
    id: 'App.General.UtilityType',
    defaultMessage: 'Utility Type',
  },
  view: {
    id: 'App.General.View',
    defaultMessage: 'View',
  },
  yes: {
    id: 'App.General.Yes',
    defaultMessage: 'Yes',
  },
  selected: {
    id: 'App.General.Selected',
    defaultMessage: 'Selected',
  },
});

export default messages;
