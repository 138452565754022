import { defineMessages } from 'react-intl';

const messages = defineMessages({
  defaultLeaseEndDate: {
    id: 'App.LeaseSetup.defaultLeaseEndDate',
    defaultMessage: 'Default Lease End Date',
  },
  lease12MonthsGreater: {
    id: 'App.LeaseSetup.lease12MonthsGreater',
    defaultMessage: 'Lease 12 months+',
  },
  lease12MonthsLesser: {
    id: 'App.LeaseSetup.lease12MonthsLesser',
    defaultMessage: 'Lease < 12 months',
  },
  tooltipLine1: {
    id: 'App.LeaseSetup.tooltipLine1',
    /* eslint-disable max-len */
    defaultMessage:
      '“Calculated date” applies an end date that is the exact length of time resulting from the lease start date and lease term.',
  },
  tooltipLine2: {
    id: 'App.LeaseSetup.tooltipLine2',
    defaultMessage:
      '“Last day of month” applies an end date that is the last day of the calendar month resulting from the lease start date and lease term.',
  },
  tooltipLine3: {
    id: 'App.LeaseSetup.tooltipLine3',
    defaultMessage:
      '“Last day of prior month” applies an end date that is the last day of the calendar month prior to the calendar month resulting from the lease start date and lease term.',
  },
  tooltipTitle: {
    id: 'App.LeaseSetup.tooltipTitle',
    defaultMessage: 'For each lease length, you may choose from three options:',
  },
});

export default messages;
