import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import { renderCurrencyField } from '../../../../../utils/redux-form-helper';
import messages from './messages';

export const PetFeesAndRent = ({ intl, isEditable }: Object) => (
  <Row>
    <Col xs={12} md={8} className="padtop20">
      <h2>
        <i className="et-pet text-blue padright10" />
        {intl.formatMessage(messages.petFeesAndRentLabel)}
      </h2>
      <Col xs={12}>
        <form>
          <Col xs={12} sm={4}>
            <label>Pet Deposit </label>
            <div className="form-group">
              <Field
                name="petDeposit"
                component={renderCurrencyField}
                step="0.01"
                disabled={!isEditable}
              />
            </div>
          </Col>
          <Col xs={12} sm={4}>
            <label>Monthly Pet Rent</label>
            <div className="form-group">
              <Field
                name="monthlyPetRent"
                component={renderCurrencyField}
                step="0.01"
                disabled={!isEditable}
              />
            </div>
          </Col>
          <Col xs={12} sm={4}>
            <label>Pet Fee</label>
            <div className="form-group">
              <Field
                name="petFee"
                component={renderCurrencyField}
                step="0.01"
                disabled={!isEditable}
              />
            </div>
          </Col>
        </form>
      </Col>
    </Col>
    <Col
      xs={12}
      md={4}
      className="col-xs-12 col-md-4 divider-vertical padtop20 padbottom10"
    >
      <h2>
        <i className="et-money text-blue padright10" />
        Short Term Fee
      </h2>
      <div className="col-xs-12">
        <form>
          <Col xs={12} sm={12}>
            <label>{intl.formatMessage(messages.feeAmount)}</label>
            <div className="form-group">
              <Field
                name="shortTermFee"
                component={renderCurrencyField}
                step="0.01"
                disabled={!isEditable}
              />
            </div>
          </Col>
        </form>
      </div>
    </Col>
  </Row>
);

export default PetFeesAndRent;
