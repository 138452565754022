import { pathOr, last } from 'ramda';

export const getSubjournalId = (data: Object): any => {
  if (data.length > 0) {
    return data
      .filter((row) => {
        const subjournalType = pathOr(
          '',
          ['masterSubjournalType', 'description'],
          row,
        );
        return subjournalType === 'Subsidy';
      })
      .map((row) => pathOr('', ['masterSubjournalId'], row));
  }
};

export const getTrancationCode = (depositAmount: string): string => {
  return (
    (depositAmount < 0 && 'SUBTAKEBACK') ||
    ((!depositAmount || Math.abs(depositAmount) === 0) && '---') ||
    (depositAmount > 0 && 'GOVTSUB')
  );
};

export const getTrancationCodeId = (
  depositAmount: string,
  transactionCodes: Object,
): string => {
  const transactionCode = getTrancationCode(depositAmount);
  const ptc = transactionCodes.filter((tc) => {
    const tCode = transactionCode === '---' ? 'GOVTSUB' : transactionCode;
    return tc.transactionCode.code === tCode;
  });
  return ptc[0];
};

export const getHouseholdLink = (
  status: string,
  linkId: string,
  propertyId: string,
) => {
  switch (status) {
    case 'Current Resident':
      return `/property/${propertyId}/resident/${linkId}`;
    case 'Prior Resident':
      return `/property/${propertyId}/prior-resident/${linkId}`;
    case 'Applicant':
      return `/property/${propertyId}/application/${linkId}`;
    default:
      break;
  }
};

export const sortHouseholds = (
  households: Object,
  filterCanceledOrdDeniedApplicants: boolean = false,
) => {
  return households.reduce(
    (acc, row) => {
      const status = row?.household?.mainCus?.status ?? '';
      const householdId = row?.household?.id ?? '';
      const firstName = row?.household?.mainCus?.firstName ?? '';
      const lastName = row?.household?.mainCus?.lastName ?? '';
      const unitNumber =
        row?.household?.mainCus?.rc?.resident?.lease?.unit?.number ?? null;

      const name = unitNumber
        ? unitNumber + ' - ' + firstName + ' ' + lastName
        : firstName + ' ' + lastName;

      switch (status) {
        case 'Current Resident':
          acc.current.push({ value: { householdId }, label: name });
          break;
        case 'Prior Resident':
          acc.prior.push({ value: { householdId }, label: name });
          break;
        case 'Applicant':
          if (
            filterCanceledOrdDeniedApplicants &&
            ['Canceled', 'Denied'].includes(
              row?.auApplicant?.app?.applicationStatus?.name,
            )
          ) {
            break;
          }

          const applicantUnitNumber =
            row?.auApplicant?.app?.au?.unit?.number ?? null;

          const applicantName = applicantUnitNumber
            ? applicantUnitNumber + ' - ' + firstName + ' ' + lastName
            : firstName + ' ' + lastName;

          acc.applicant.push({
            value: { householdId },
            label: applicantName,
          });
          break;
        default:
          break;
      }
      return acc;
    },
    {
      current: [],
      prior: [],
      applicant: [],
    },
  );
};

export const getHouseholdOption = (status, householdList) => {
  if (householdList) {
    switch (status) {
      case 'current resident':
        return householdList.current;
      case 'prior resident':
        return householdList.prior;
      case 'applicant':
        return householdList.applicant;
      default:
        return [];
    }
  }
  return [];
};

export const calculateCurrentSubsidyBalance = (
  householdArray,
  householdId,
  notInitialLoad,
) => {
  const data = householdArray.filter((row) => row.householdId === householdId);
  return data && data.length
    ? !notInitialLoad
      ? pathOr(0, ['subjournalRunningBalance'], last(data))
      : pathOr(0, ['finalSubsidyBalance'], last(data))
    : 0;
};
