import { defineMessages } from 'react-intl';

const messages = defineMessages({
  portfolioSummary: {
    id: 'App.AppBarNavDrawer.PropertySearchMenu.portfolioSummary',
    defaultMessage: 'Portfolio Summary',
  },
  includeInactive: {
    id: 'App.AppBarNavDrawer.PropertySearchMenu.includeInactive',
    defaultMessage: 'Include Inactive',
  },
  logOut: {
    id: 'App.AppBarNavDrawer.UserMenu.logOut',
    defaultMessage: 'Log Out',
  },
  confirmLogOut: {
    id: 'App.AppBarNavDrawer.UserMenu.confirmLogOut',
    defaultMessage: 'Are you sure you want to log out?',
  },
});

export default messages;
