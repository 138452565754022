import { useRef, useEffect, useState } from 'react';
import PropertyService from '../../../services/propertyService';
import messages from './messages';

export const useUpdateGPR = (
  currentGPR: string,
  propertyId: string,
  organizationId: string,
  promptToaster: Function,
  intl: Object,
  onHide: Function,
  setPropertyChangeHistoryLog: Function,
) => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [gpr, setGPR] = useState(currentGPR);

  useEffect(() => {
    setGPR(currentGPR);
  }, [currentGPR]);

  const updateGPR = async (newGPR: string) => {
    const propertyService = new PropertyService();

    try {
      safeSetWrapper(setIsLoading)(true);
      const response = await propertyService.updateGPR(
        organizationId,
        propertyId,
        { gpr: newGPR },
      );

      if (response) {
        safeSetWrapper(setGPR)(response.gpr);
        setPropertyChangeHistoryLog(response.propertyChangeHistoryLog);
      }

      promptToaster({
        title: intl.formatMessage(messages.success),
        message: intl.formatMessage(messages.successBody),
      });

      onHide();
    } catch (_) {
      promptToaster({
        type: 'error',
        title: intl.formatMessage(messages.error),
        message: intl.formatMessage(messages.errorBody),
      });
      safeSetWrapper(setGPR)(gpr);
    } finally {
      safeSetWrapper(setIsLoading)(false);
    }
  };

  const safeSetWrapper = (setFn: Function) => (value: string) => {
    if (isMounted.current) {
      setFn(value);
    }
  };

  return [gpr, safeSetWrapper(setGPR), updateGPR, isLoading];
};
