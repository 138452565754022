export const drawerWidth = { closed: 64, open: 160 };

export const featureFlagNameMap = {
  'Prorate Calculator': 'prorateCalculatorV1',
  'Manage Online Payments': 'paymentsV1',
  'Manage Amenities': 'manageAmenitiesV1',
  'Manage Fees': 'manageFeesV1',
  'Forecast Rents': 'forecastRents',
  'Sales-portfolioSummaryOnly': 'centralizedUnitAvailability',
  'People-portfolioSummaryOnly': 'centralizedExpiringLeases',
  'Manage Expiring Leases-portfolioSummaryOnly': 'centralizedExpiringLeases',
  'Reporting-portfolioSummaryOnly': 'centralizedReports',
  'Manage Reports-portfolioSummaryOnly': 'centralizedReports',
  'Manage Documents-portfolioSummaryOnly': 'documentManagementMvp',
  'Manage Delinquent and Prepaid-portfolioSummaryOnly':
    'centralizedDelinquencyAndPrepaid',
};
