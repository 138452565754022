import { any, defaultTo, isEmpty, or, pathOr } from 'ramda';
import { validateRequired } from '../../../utils/validations';

import formMessages from './messages';

import type { BasicInformation } from './types';

const warn = (
  basicInfo: BasicInformation,
  { intl, flags, validateMultiRace }: Object,
) => {
  const values = defaultTo({})(basicInfo);
  const warnings = {};

  const { raceMultiSelect = false } = flags;
  const doNotWishToProvideDisability =
    flags?.doNotWishToProvideDisability ?? false;

  // $FlowFixMe
  const disability = pathOr({}, ['disability'], values);
  if (Object.keys(disability).length === 0) {
    values.disability = {};
  }

  if (!validateRequired('firstName', values)) {
    warnings.firstName = intl.formatMessage(formMessages.requiredForCompletion);
  }
  if (!validateRequired('lastName', values)) {
    warnings.lastName = intl.formatMessage(formMessages.requiredForCompletion);
  }
  if (!validateRequired('birthday', values)) {
    warnings.birthday = intl.formatMessage(formMessages.requiredForCompletion);
  }
  if (!validateRequired('gender', values)) {
    warnings.gender = intl.formatMessage(formMessages.requiredForCompletion);
  }
  if (!validateRequired('ethnicity', values)) {
    warnings.ethnicity = intl.formatMessage(formMessages.requiredForCompletion);
  }
  if (!raceMultiSelect && !validateRequired('race', values)) {
    warnings.race = intl.formatMessage(formMessages.requiredForCompletion);
  }

  if (
    raceMultiSelect &&
    !validateMultiRace &&
    !validateRequired('race', values)
  ) {
    warnings.race = intl.formatMessage(formMessages.requiredForCompletion);
  }

  if (
    raceMultiSelect &&
    !validateRequired('multiRace', values) &&
    validateMultiRace
  ) {
    warnings.multiRace = intl.formatMessage(formMessages.requiredForCompletion);
  }
  if (!validateRequired('veteran', values)) {
    warnings.veteran = intl.formatMessage(formMessages.requiredForCompletion);
  }
  const hasDisability = disability?.hasDisability;
  let allRequired =
    doNotWishToProvideDisability && hasDisability === 'notWishToProvide'
      ? false
      : true;

  if (
    doNotWishToProvideDisability &&
    !validateRequired('hasDisability', disability)
  ) {
    warnings.disability = {};
    warnings.disability.hasDisability = intl.formatMessage(
      formMessages.requiredForCompletion,
    );
    allRequired = false;
  }
  if (allRequired) {
    const disabilityWarnings = {};
    if (!validateRequired('disabilityMobile', disability)) {
      disabilityWarnings.disabilityMobile = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }
    if (!validateRequired('disabilityHearing', disability)) {
      disabilityWarnings.disabilityHearing = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }
    if (!validateRequired('disabilityVisual', disability)) {
      disabilityWarnings.disabilityVisual = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }
    if (!validateRequired('disabilityOther', disability)) {
      disabilityWarnings.disabilityOther = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }

    if (!isEmpty(disabilityWarnings)) {
      warnings.disability = disabilityWarnings;
    }
  }
  if (
    !or(
      validateRequired('socialSecurityNumber', values),
      any((fieldName) => validateRequired(fieldName, values))([
        'stateIdNumber',
        'passportNumber',
        'alienRegistration',
      ]),
    )
  ) {
    const provideMessage = intl.formatMessage(formMessages.provideOne);
    warnings.socialSecurityNumber = provideMessage;
    warnings.stateIdNumber = provideMessage;
    warnings.passportNumber = provideMessage;
    warnings.alienRegistration = provideMessage;
  }

  return warnings;
};

export default warn;
