import React from 'react';
import DoughnutCard from '../DoughnutCard';

import messages from './messages';

const Activity = ({
  data,
  formatMessage,
  dataLoaded,
  fetching,
  kpiLoaded
}: Object) => {
  const placeholder = formatMessage(messages.noData);
  const moveInsLabel = formatMessage(messages.moveIns);
  const moveOutsLabel = formatMessage(messages.moveOuts);
  const labels = [moveInsLabel, moveOutsLabel];
  const [mtdMoveIns, mtdMoveOuts] = data;
  return (
    <DoughnutCard
      data={data}
      labels={labels}
      header={formatMessage(messages.title)}
      dataLoaded={dataLoaded}
      placeholder={placeholder}
      fetching={fetching}
    >
      <div className="row padtop10">
        <div className="col-xs-6 col-sm-12 text-center">
          <span className="status-ball--small status-darker-blue" />
          <span className="font-10">
            {' '}
            {mtdMoveIns} {moveInsLabel}
          </span>
        </div>
        <div className="col-xs-6 col-sm-12 text-center">
          <span className="status-ball--small status-lighter-blue" />
          <span className="font-10">
            {' '}
            {mtdMoveOuts} {moveOutsLabel}
          </span>
        </div>
      </div>
    </DoughnutCard>
  );
};

export default Activity;
