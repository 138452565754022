import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSummary.Title',
    defaultMessage: 'Set-Aside Summary',
  },
  setAsideSummaryFetchError: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSummaryFetchError',
    defaultMessage: 'Error loading set-aside summary',
  },
  incomeAveragingPercentFetchError: {
    id: 'App.Home.PropertyComplianceTab.IncomeAveragingPercentFetchError',
    defaultMessage: 'Error loading income averaging percent',
  },
  updateIncomeAveragingPercentSuccessTitle: {
    id:
      'App.Home.PropertyComplianceTab.UpdateIncomeAveragingPercentSuccessTitle',
    defaultMessage: 'Income averaging percent updated',
  },
  updateIncomeAveragingPercentSuccessMessage: {
    id:
      'App.Home.PropertyComplianceTab.UpdateIncomeAveragingPercentSuccessMessage',
    defaultMessage: 'The income averaging percent was updated',
  },
  updateIncomeAveragingPercentError: {
    id: 'App.Home.PropertyComplianceTab.UpdateIncomeAveragingPercentError',
    defaultMessage: 'Error updating income averaging percent',
  },
});

export default messages;
