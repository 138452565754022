export const sortLists = (list) => {
  return list?.sort((a, b) => {
    let aVal = a?.text ?? '';
    let bVal = b?.text ?? '';
    if (typeof aVal !== 'string' && aVal?.length) {
      aVal = aVal.toString();
    }
    if (typeof bVal !== 'string' && bVal?.length) {
      bVal = bVal.toString();
    }
    return aVal.localeCompare(bVal);
  });
};
