import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type Props = {
  maxWidth: string,
  display: string,
  margin: string,
  value: string,
  setValue: Function,
  custom: any[],
};

const StyledInput = styled.input`
  max-width: ${(props) => props.maxWidth};
  display: ${(props) => props.display};
  margin: ${(props) => props.margin};
`;

export const Input = ({
  maxWidth,
  display,
  margin,
  value,
  setValue,
  ...custom
}: Props): FunctionComponent<Props> => (
  <StyledInput
    className="input-md form-control"
    maxWidth={maxWidth}
    display={display}
    margin={margin}
    value={value}
    onChange={(e) => setValue(e.target.value)}
    {...custom}
  />
);
