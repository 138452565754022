export const LINKS = [
  {
    label: 'Overview',
    id: 'overview',
  },
  {
    label: 'Information We Collect and Why',
    id: 'information-we-collect-and-why',
  },
  {
    label: 'Information We May Share',
    id: 'information-we-may-share',
  },
  {
    label: 'Fortress Services with Partners and Third-Party Links and Services',
    id: 'fortress-services-with-partners-and-third-party-links-and-services',
  },
  {
    label: 'Security',
    id: 'security',
  },
  {
    label: 'Use of Our Services by Children',
    id: 'use-of-our-services-by-children',
  },
  {
    label: 'Consent',
    id: 'consent',
  },
  {
    label: 'Do not Track',
    id: 'do-not-track',
  },
  {
    label: 'California Privacy Rights',
    id: 'california-privacy-rights',
  },
  {
    label: 'International Users of Fortress Services',
    id: 'international-users-of-fortress-services',
  },
  {
    label: 'Privacy Policy Updates',
    id: 'privacy-policy-updates',
  },
  {
    label: 'Contact Us',
    id: 'contact-us',
  },
];
