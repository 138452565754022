import { FormattedMessage } from 'react-intl';

import messages from '../App/messages';
import { N2W_LOCALE_MAP } from './constants';
import { MOVE_OUT_PRORATE_MEHTODS } from '../ResidentProfile/constants';
import { PRORATE_METHODS as DEPRECATED_METHODS } from '../../components/CompleteRenewalCheck/RenewalProrates/constants';

export const getBooleanDisplayValue = (value?: boolean = false): ReactNode => {
  return value ? (
    <FormattedMessage {...messages.on} />
  ) : (
    <FormattedMessage {...messages.off} />
  );
};

export const getN2WLocale = (locale: string = ''): string => {
  const subLocale = locale.substring(0, 2);

  return N2W_LOCALE_MAP[subLocale] ?? 'EN_US';
};

export const getEquivalentDeprecatedMoveOutProrateMethodId = (
  moveOutProrateMethodId,
  deprecatedProrateMethods,
  moveOutProrateMethods,
) => {
  const newYesOptions = [
    MOVE_OUT_PRORATE_MEHTODS.YES_FULL_MONTH,
    MOVE_OUT_PRORATE_MEHTODS.YES_MONTH_START,
  ];
  const deprecatedYesOption = deprecatedProrateMethods.find((method) => {
    return method.name === DEPRECATED_METHODS.YES_ALWAYS;
  });
  const deprecatedNoOption = deprecatedProrateMethods.find((method) => {
    return method.name === DEPRECATED_METHODS.NO_NEVER;
  });
  const methodName = moveOutProrateMethods.find((method) => {
    return method.id === moveOutProrateMethodId;
  }).name;

  return newYesOptions.includes(methodName)
    ? deprecatedYesOption.id
    : deprecatedNoOption.id;
};

export const getEquivalentMoveOutProrateMethodId = (
  deprecated_moveOutProrateMethodId,
  deprecatedProrateMethods,
  moveOutProrateMethods,
) => {
  const newYesOption = moveOutProrateMethods.find(
    (method) => method.name === MOVE_OUT_PRORATE_MEHTODS.YES_FULL_MONTH,
  );
  const newNoOption = moveOutProrateMethods.find(
    (method) => method.name === MOVE_OUT_PRORATE_MEHTODS.NO_NEVER,
  );
  const deprecatedMethodName = deprecatedProrateMethods.find((method) => {
    return method.id === deprecated_moveOutProrateMethodId;
  }).name;

  return deprecatedMethodName === DEPRECATED_METHODS.YES_ALWAYS
    ? newYesOption.id
    : newNoOption.id;
};
