import React from 'react';
import { Row, Col } from 'react-bootstrap';
import * as R from 'ramda';
import type {
  Image,
  OnDeleteImageEvent,
  OnDownloadImageEvent,
  OnUploadImageEvent,
} from './types';
import ImageUploadBlock from './ImageUploadBlock';
import ImageBlock from './ImageBlock';
import Spinner from '../../../../components/Spinner';

export type Props = {
  image?: Image,
  loading?: boolean,
  onDeleteImage: OnDeleteImageEvent,
  onDownloadImage: OnDownloadImageEvent,
  onUploadImage: OnUploadImageEvent,
};

function FloorPlanImage({
  image,
  loading,
  onDeleteImage,
  onDownloadImage,
  onUploadImage,
}: Props) {
  const isImageUploaded = !R.isNil(image);

  const getContentBlock = () => {
    if (loading) return <Spinner />;
    return isImageUploaded ? (
      <ImageBlock
        image={image}
        onDeleteImage={onDeleteImage}
        onDownloadImage={onDownloadImage}
      />
    ) : (
      <ImageUploadBlock onUploadImage={onUploadImage} />
    );
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <h2>Floor Plan Image</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>{getContentBlock()}</Col>
      </Row>
    </div>
  );
}

export default FloorPlanImage;
