import React from 'react';
import messages from './ApplicationsTable/messages';
import { FormattedMessage } from 'react-intl';

export const GET_ALL_APPLICATIONS =
  'app/ManageApplications/GET_ALL_APPLICATIONS';
export const GET_ALL_APPLICATIONS_SUCCESS =
  'app/ManageApplications/GET_ALL_APPLICATIONS_SUCCESS';
export const GET_ALL_APPLICATIONS_FAIL =
  'app/ManageApplications/GET_ALL_APPLICATIONS_FAIL';

export const GET_ALL_APPLICATION_STATUSES =
  'app/ManageApplications/GET_ALL_APPLICATION_STATUSES';
export const GET_ALL_APPLICATION_STATUSES_SUCCESS =
  'app/ManageApplications/GET_ALL_APPLICATION_STATUSES_SUCCESS';
export const GET_ALL_APPLICATION_STATUSES_ERROR =
  'app/ManageApplications/GET_ALL_APPLICATION_STATUSES_ERROR';

export const UPDATE_COLUMNS_SORT_VALUE =
  'app/ManageApplications/UPDATE_COLUMNS_SORT_VALUE';

export const CLEAN_LOADED_APPLICATIONS =
  'app/ManageApplications/CLEAN_LOADED_APPLICATIONS';

export const APPLICATION_UPDATE_PROSPECT_ACTIVITY =
  'app/ManageApplications/APPLICATION_UPDATE_PROSPECT_ACTIVITY';

export const CREATE_APPLICATION_PROSPECT_ACTIVITY =
  'app/ManageApplications/CREATE_APPLICATION_PROSPECT_ACTIVITY';

export const CREATE_APPLICATION_PROSPECT_ACTIVITY_SUCCESS =
  'app/ManageApplications/CREATE_APPLICATION_PROSPECT_ACTIVITY_SUCCESS';

export const CREATE_APPLICATION_PROSPECT_ACTIVITY_ERROR =
  'app/ManageApplications/CREATE_APPLICATION_PROSPECT_ACTIVITY_ERROR';

export const APPLICATION_DELETE_PROSPECT_ACTIVITY =
  'app/ManageApplications/APPLICATION_DELETE_PROSPECT_ACTIVITY';

export const SHOW_NO_DATA_TOASTR = 'app/ManageApplications/SHOW_NO_DATA_TOASTR';

export const APPLICANT = 'Applicant';

export const HEADERS = [
  {
    id: 'applicationCode',
    title: <FormattedMessage {...messages.applicationCode} />,
  },
  { id: 'applicants', title: <FormattedMessage {...messages.applicants} /> },
  {
    id: 'status',
    title: <FormattedMessage {...messages.status} />,
  },
  {
    id: 'complianceApproval',
    title: <FormattedMessage {...messages.complianceApproval} />,
  },
  {
    id: 'complianceDocument',
    title: <FormattedMessage {...messages.complianceDocument} />,
  },
  {
    id: 'complianceApprovalHUD',
    title: <FormattedMessage {...messages.complianceApprovalHUD} />,
  },
  {
    id: 'complianceDocumentHUD',
    title: <FormattedMessage {...messages.complianceDocumentHUD} />,
  },
  {
    id: 'assignedUnitNumber',
    title: <FormattedMessage {...messages.assignedUnitNumber} />,
  },
  {
    id: 'phoneNumber',
    title: <FormattedMessage {...messages.phoneNumber} />,
  },
  {
    id: 'email',
    title: <FormattedMessage {...messages.email} />,
  },
  {
    id: 'lastActivity',
    title: <FormattedMessage {...messages.lastActivity} />,
  },
  {
    id: 'nextActivity',
    title: <FormattedMessage {...messages.nextActivity} />,
  },
  {
    id: 'desiredMoveInDate',
    title: <FormattedMessage {...messages.desiredMoveInDate} />,
  },
  {
    id: 'scheduleMoveInDate',
    title: <FormattedMessage {...messages.scheduleMoveInDate} />,
  },
  {
    id: 'applicantNotes',
    title: <FormattedMessage {...messages.applicantNotes} />,
  },
  {
    id: 'applicantConversionDate',
    title: <FormattedMessage {...messages.applicantConversionDate} />,
  },
  {
    id: 'assignedTo',
    title: <FormattedMessage {...messages.assignedTo} />,
  },
];
