import React from 'react';
import EditLinkWithPermissions from '../../../../components/EditLinkWithPermissions';

export const EditLeaseDataLink = ({
  showEditLink,
  showLeaseDataModal,
}: Object) => {
  return (
    <EditLinkWithPermissions
      showEditLink={showEditLink}
      scope={['lease-current-lease-end-date-edit']}
      linkFn={showLeaseDataModal}
    />
  );
};

export default EditLeaseDataLink;
