import { get, post, patch, del } from '../utils/api';

const TimeFrame = {
  ALL_TIME: 'All Time',
  THIRTY_DAYS: 'Last 30 days',
  NINETY_DAYS: 'Last 90 days',
  SIX_MONTHS: 'Last 6 months',
  TWELVE_MONTHS: 'Last 12 months',
};

export type FilterValues = {
  timeFrame: string,
  toDate: string,
  fromDate: string,
  ratings: string[],
  status: string,
  category: string,
};

export type FetchReviewParams = {
  sources: string[],
  search?: string,
  order?: 'asc' | 'desc',
  orderBy?: 'updateTime' | 'rating',
  page?: number,
  pageSize?: number,
} & FilterValues;

type RespondToReviewPayload = {
  reviewId: string,
  message: string,
  source: string,
};

type EditReviewResponsePayload = {
  id: string, // reviewId for sources without response.id (i.e. Google)
  message: string,
  source: string,
};

type DeleteReviewResponsePayload = {
  id: string, // reviewId for sources without response.id (i.e. Google)
  source: string,
};

export default class ReputationManagementService {
  getPropertyReviews(
    organizationId: string,
    propertyId: string,
    {
      sources,
      search,
      order,
      orderBy,
      page,
      pageSize,
      timeFrame,
      toDate,
      fromDate,
      ratings,
      status,
      category,
    }: FetchReviewParams,
  ) {
    let filterString = '';

    if (timeFrame) {
      if (timeFrame === TimeFrame.ALL_TIME) {
        filterString += filterString += '&timeFrame=all-time';
      }
      if (timeFrame === TimeFrame.THIRTY_DAYS) {
        filterString += filterString += '&timeFrame=30d';
      }
      if (timeFrame === TimeFrame.NINETY_DAYS) {
        filterString += filterString += '&timeFrame=90d';
      }
      if (timeFrame === TimeFrame.SIX_MONTHS) {
        filterString += filterString += '&timeFrame=6mos';
      }
      if (timeFrame === TimeFrame.TWELVE_MONTHS) {
        filterString += filterString += '&timeFrame=12mos';
      }
    }

    if (category) filterString += `&category=${category}`;

    if (fromDate && toDate) {
      filterString += '&startDate=' + fromDate + '&endDate=' + toDate;
    }

    if (ratings.length > 0) {
      filterString += '&ratings=' + ratings.join(',');
    }

    if (status && status !== 'all-status') {
      filterString += '&status=' + status;
    }

    return get(
      `/${organizationId}/${propertyId}/reputation-management/reviews?sources=${sources.join(
        ',',
      )}${search ? `&search=${search}` : ''}${order ? `&order=${order}` : ''}${
        orderBy ? `&orderBy=${orderBy}` : ''
      }${page ? `&page=${page}` : ''}${
        pageSize ? `&pageSize=${pageSize}` : ''
      }${filterString}`,
    );
  }
  getRatingBreakdown(
    organizationId: string,
    propertyId: string,
    sources: string[],
  ) {
    return get(
      `/${organizationId}/${propertyId}/reputation-management/ratings/breakdown?sources=${sources.join(
        ',',
      )}`,
    );
  }
  getReviewStats(
    organizationId: string,
    propertyId: string,
    sources: string[],
  ) {
    return get(
      `/${organizationId}/${propertyId}/reputation-management/reviews/stats?sources=${sources.join(
        ',',
      )}`,
    );
  }
  respondToReview(
    organizationId: string,
    propertyId: string,
    payload: RespondToReviewPayload,
  ) {
    return post(
      `/${organizationId}/${propertyId}/reputation-management/reviews/response`,
      JSON.stringify({
        id: payload.reviewId,
        message: payload.message,
        source: payload.source,
      }),
    );
  }
  editReviewResponse(
    organizationId: string,
    propertyId: string,
    payload: EditReviewResponsePayload,
  ) {
    return patch(
      `/${organizationId}/${propertyId}/reputation-management/reviews/response`,
      JSON.stringify({
        id: payload.id,
        message: payload.message,
        source: payload.source,
      }),
    );
  }
  deleteReviewResponse(
    organizationId: string,
    propertyId: string,
    payload: DeleteReviewResponsePayload,
  ) {
    return del(
      `/${organizationId}/${propertyId}/reputation-management/reviews/response`,
      JSON.stringify({
        id: payload.id,
        source: payload.source,
      }),
    );
  }
  getPropertyMappedSources(organizationId: string, propertyId: string) {
    return get(
      `/${organizationId}/${propertyId}/reputation-management/mapped-sources`,
    );
  }
}
