import {
  GET_CREATE_CHARGE_BATCH,
  GET_CREATE_CHARGE_BATCH_FAILURE,
  GET_CREATE_CHARGE_BATCH_SUCCESS
} from './constants';
import type { Action } from '../App/types';

export const initialState = {};

export function createChargeBatchReducer(
  state: Object = initialState,
  { type, payload, error }: Action<Error | Array<Object>>
) {
  switch (type) {
    case GET_CREATE_CHARGE_BATCH:
      return initialState;
    case GET_CREATE_CHARGE_BATCH_SUCCESS:
      return { payload };
    case GET_CREATE_CHARGE_BATCH_FAILURE:
      return { ...state, payload, error };
    default:
      return state;
  }
}
