import {
  CREATE_MISC_TRANSACTION,
  CREATE_MISC_TRANSACTION_FAILURE,
  CREATE_MISC_TRANSACTION_SUCCESS,
  HANDLE_SOCKET_CREATE_MISC_TRANSACTION_ERROR,
  HANDLE_SOCKET_CREATE_MISC_TRANSACTION_SUCCESS,
} from './constants';
import type { CreateMiscTransaction } from './types';
import type { Action } from '../../App/types';

export function createMiscTransaction(
  payload: CreateMiscTransaction,
): Action<any> {
  return {
    type: CREATE_MISC_TRANSACTION,
    payload,
  };
}

export function createMiscTransactionSuccess(): Action<any> {
  return {
    type: CREATE_MISC_TRANSACTION_SUCCESS,
    payload: undefined,
  };
}

export function createMiscTransactionFailure(payload: Object): Action<any> {
  return {
    type: CREATE_MISC_TRANSACTION_FAILURE,
    error: true,
    payload,
  };
}

export function handleSocketCreateMiscTransactionError(
  payload: Object,
): Action<any> {
  return {
    type: HANDLE_SOCKET_CREATE_MISC_TRANSACTION_ERROR,
    payload,
  };
}

export function handleSocketCreateMiscTransactionSuccess(
  payload: Object,
): Action<any> {
  return {
    type: HANDLE_SOCKET_CREATE_MISC_TRANSACTION_SUCCESS,
    payload,
  };
}
