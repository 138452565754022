import { defineMessages } from 'react-intl';

const messages = defineMessages({
  areYouSureText: {
    id: 'App.LeaseDataTabForm.AreYouSureText',
    defaultMessage:
      'Are you sure you want to generate a lease for applicants: {primaryApplicantName}?',
  },
  requiredFieldsText: {
    id: 'App.LeaseDataTabForm.RequiredFieldsText',
    /* eslint-disable max-len */
    defaultMessage:
      'Please review and make any changes before you generate the lease. * Required Fields.',
  },
});

export default messages;
