import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import { promptToaster } from '../../containers/App/actions';
import messages from './messages';
import {
  handleSocketFinancialTransactionFailure,
  handleSocketFinancialTransactionSuccess,
} from './actions';

/**
 * Consumed by Socket context to register listeners
 */
const reverseTransactionEvents = (dispatch: Function, socket: any) => {
  socket.on('rtx.error', (message) => {
    const { text } = message;
    dispatch(
      handleSocketFinancialTransactionFailure({
        error: text,
        title: renderTranslatedMessage(messages.errorHeaderReversal),
      }),
    );
  });

  socket.on('rtx.success', (message) => {
    const { payload } = message;
    dispatch(
      handleSocketFinancialTransactionSuccess({
        message: renderTranslatedMessage(messages.successDescriptionReversal),
        title: renderTranslatedMessage(messages.successHeaderReversal),
        payload,
      }),
    );
  });

  socket.on('apprefund.pending', (message) => {
    dispatch(
      promptToaster({
        type: 'info',
        message: renderTranslatedMessage(messages.pendingMessageRefund),
        title: renderTranslatedMessage(messages.pendingHeaderRefund),
      }),
    );
  });

  socket.on('apprefund.error', (message) => {
    const { text } = message;
    dispatch(
      handleSocketFinancialTransactionFailure({
        error: text,
        title: renderTranslatedMessage(messages.errorHeaderReversal),
      }),
    );
  });

  socket.on('apprefund.success', (message) => {
    const { payload } = message;
    dispatch(
      handleSocketFinancialTransactionSuccess({
        payload,
        message: renderTranslatedMessage(messages.successDescriptionRefund),
        title: renderTranslatedMessage(messages.successHeaderRefund),
      }),
    );
  });
};

export default reverseTransactionEvents;
