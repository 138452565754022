import React, { FunctionComponent, useState } from 'react';
import ColumnHeader from '../../../components/ColumnHeader';
import messages from '../messages';
import { Row } from './Row';
import { Table } from 'react-bootstrap';
import useIsAffordableProperty from '../../../hooks/useIsAffordableProperty';
import type { Building, State } from '../types';
import useIsRDProperty from '../../../hooks/useIsRDProperty';

type Props = {
  buildings: Building[],
  updateBuilding: Function,
  states: State[],
  selectedProperty: Object,
  intl: Object,
  promptToaster: Function,
};

export const BuildingsTable = ({
  buildings,
  updateBuilding,
  states,
  selectedProperty,
  promptToaster,
  intl,
}: Props): FunctionComponent<Props> => {
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const isAffordableProperty = useIsAffordableProperty(selectedProperty);
  const isRDProperty = useIsRDProperty(selectedProperty);
  return (
    <Table striped className="table-prospects">
      <thead className="table-header">
        <tr>
          <ColumnHeader
            columnLabel={intl.formatMessage(messages.buildingNumber)}
          />
          <ColumnHeader
            columnLabel={intl.formatMessage(messages.buildingAddress)}
          />
          {isAffordableProperty && (
            <ColumnHeader
              columnLabel={intl.formatMessage(messages.xmlAltSiteName)}
            />
          )}
          <ColumnHeader
            columnLabel={intl.formatMessage(messages.buildingPISD)}
          />
          <ColumnHeader
            columnLabel={intl.formatMessage(messages.buildingBIN)}
          />
          {isAffordableProperty && isRDProperty && (
            <ColumnHeader columnLabel={intl.formatMessage(messages.rdSiteId)} />
          )}
          {isAffordableProperty && (
            <ColumnHeader
              columnLabel={intl.formatMessage(messages.heraSpecial)}
            />
          )}
          <ColumnHeader columnLabel={intl.formatMessage(messages.edit)} />
        </tr>
      </thead>
      <tbody>
        {buildings.map((building) => (
          <Row
            key={building.id}
            building={building}
            updateBuilding={updateBuilding}
            states={states}
            setSelectedBuilding={setSelectedBuilding}
            disabled={
              selectedBuilding ? building.id !== selectedBuilding : false
            }
            selectedProperty={selectedProperty}
            promptToaster={promptToaster}
            intl={intl}
          />
        ))}
      </tbody>
    </Table>
  );
};
