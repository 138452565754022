import React from 'react';
import ApprovedResident from './ApprovedResident/index';

export default ({ intl, currentRecord }: any) => {
  const residents = currentRecord.approvedApplicants;
  return (
    <div className="container-fluid">
      <div className="accordion accordion-applicationmanager panel-group">
        {residents.map((resident) => (
          <ApprovedResident key={resident.id} resident={resident} intl={intl} />
        ))}
      </div>
    </div>
  );
};
