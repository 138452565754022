import { defineMessages } from 'react-intl';

const messages = defineMessages({
  status: {
    id: 'App.ManageDeposits.ManageDepositsDetails.Status',
    defaultMessage: 'Status'
  },
  bankAccount: {
    id: 'App.ManageDeposits.ManageDepositsDetails.BankAccount',
    defaultMessage: 'Bank Account'
  },
  depositId: {
    id: 'App.ManageDeposits.ManageDepositsDetails.DepositId',
    defaultMessage: 'Deposit Id'
  },
  date: {
    id: 'App.ManageDeposits.ManageDepositsDetails.Date',
    defaultMessage: 'Date'
  },
  period: {
    id: 'App.ManageDeposits.ManageDepositsDetails.Period',
    defaultMessage: 'Period'
  },
  amount: {
    id: 'App.ManageDeposits.ManageDepositsDetails.Amount',
    defaultMessage: 'Amount'
  },
  notes: {
    id: 'App.ManageDeposits.ManageDepositsDetails.Notes',
    defaultMessage: 'Notes'
  },
  adjustments: {
    id: 'App.ManageDeposits.ManageDepositsDetails.Adjustments',
    defaultMessage: 'Adjustments'
  },
  view: {
    id: 'App.ManageDeposits.ManageDepositsDetails.View',
    defaultMessage: 'View'
  }
});

export default messages;
