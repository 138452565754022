import { defineMessages } from 'react-intl';

/**
 * Related to different Housing Assistance types that will behave as "vouchers"
 *
 */
const messages = defineMessages({
  title: {
    id: 'App.HousingAssistanceVouchers.Title',
    defaultMessage: 'Housing Assistance',
  },
  voucherSource: {
    id: 'App.HousingAssistanceVouchers.VoucherSource',
    defaultMessage: 'Source',
  },
  voucherAmount: {
    id: 'App.HousingAssistanceVouchers.VoucherAmount',
    defaultMessage: 'Amount',
  },
  residentRent: {
    id: 'App.HousingAssistanceVouchers.ResidentRent',
    defaultMessage: 'Resident Rent',
  },
  voucherStartDate: {
    id: 'App.HousingAssistanceVouchers.VoucherStartDate',
    defaultMessage: 'Start Date',
  },
  voucherStartDateError: {
    id: 'App.HousingAssistanceVouchers.StartDateError',
    defaultMessage: 'Must be first day of the month',
  },
  voucherEndDate: {
    id: 'App.HousingAssistanceVouchers.VoucherEndDate',
    defaultMessage: 'End Date',
  },
  voucherEndDateError: {
    id: 'App.HousingAssistanceVouchers.EndDateError',
    defaultMessage: 'Must be last day of the month',
  },
  actions: {
    id: 'App.HousingAssistanceVouchers.Actions',
    defaultMessage: 'Actions',
  },
  addNewVoucher: {
    id: 'App.HousingAssistanceVouchers.AddNewVoucher',
    defaultMessage: 'Add New Assistance',
  },
  editVoucher: {
    id: 'App.HousingAssistanceVouchers.EditVoucher',
    defaultMessage: 'Edit Assistance',
  },
  deleteVoucher: {
    id: 'App.HousingAssistanceVouchers.DeleteVoucher',
    defaultMessage: 'Delete Assistance',
  },
  deleteVoucherConfirmation: {
    id: 'App.HousingAssistanceVouchers.DeleteVoucherConfirmation',
    defaultMessage: 'Are you sure you want to delete this assistance?',
  },
});

export default messages;
