import messages from '../messages';
import type { Highlight, Resident, ScheduledMoveOutsEntity } from '../types';
import moment from 'moment';
import { getHighlightColor } from './general/getHighlightColor';
import { getUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';

type ScheduledMoveOutHeader = {
  id: number,
  text: Object,
  sortKey: 'id' | 'unit' | 'resident' | 'moveOutDate' | 'assignedTo',
};
type MappedScheduledMoveOuts = {
  id: string,
  unit: string,
  resident: Resident,
  moveOutDate: string,
  assignedTo: string,
  highlight: Highlight,
};

export const scheduledMoveOutHeaders = (
  intl: Object,
): ScheduledMoveOutHeader[] => [
  {
    id: 1,
    text: intl.formatMessage(messages.unitHeader),
    sortKey: 'unit',
  },
  {
    id: 2,
    text: intl.formatMessage(messages.residentHeader),
    sortKey: 'resident',
  },
  {
    id: 3,
    text: intl.formatMessage(messages.scheduledMoveOutDate),
    sortKey: 'moveOutDate',
  },
  {
    id: 4,
    text: intl.formatMessage(messages.assignedToHeader),
    sortKey: 'assignedTo',
  },
];

export const mapScheduledMoveOuts = (
  scheduledMoveOuts: ScheduledMoveOutsEntity[],
): MappedScheduledMoveOuts[] => {
  return scheduledMoveOuts.map((task) => {
    const moveOutDate = moment(task.move_out);
    const userAssigned = task.assigned_to;
    const primaryResident = task.name;
    const p2 = primaryResident
      ? {
          link: getUrlWithSelectedPropertyId(`/resident/${task.resident_id}`),
          value: primaryResident,
        }
      : 'N/A';
    const highlight = getHighlightColor({
      checkDate: task.move_out,
      status: task.userStatus,
    });

    return {
      id: task.resident_id,
      unit: task.unit,
      resident: p2,
      moveOutDate: moveOutDate ? moveOutDate.format('MM/DD/YYYY') : 'N/A',
      assignedTo: userAssigned ? userAssigned : 'N/A',
      highlight,
    };
  });
};
