import React from 'react';
import DoughnutCard from '../DoughnutCard';

import messages from './messages';

const Occupancy = ({
  occupancyData,
  netOccupancyData,
  formatMessage,
  dataLoaded,
  fetching,
}: Object) => {
  const labels = ['Occupied', 'Unoccupied'];
  const { occupied, unoccupied } = occupancyData;
  const totalUnits = occupied + unoccupied;
  const { downUnits } = netOccupancyData;
  const updatedOccupied = occupied - downUnits;
  const updatedUnoccupied = totalUnits - occupied + downUnits;
  const data = [updatedOccupied, updatedUnoccupied];
  const downUnitsOccupancyPercent =
    Math.round((10000 * occupied) / totalUnits) / 100;
  return (
    <DoughnutCard
      data={data}
      labels={labels}
      header={formatMessage(messages.title)}
      dataLoaded={dataLoaded}
      fetching={fetching}
      showCenterText={true}
    >
      <div className="row padtop10">
        <div className="col-xs-12 text-center">
          <span className="font-10">{`${formatMessage(
            messages.downUnits,
          )} `}</span>
          <strong className="font-10 disp-inline">
            {downUnits}
            {downUnitsOccupancyPercent
              ? `- ${downUnitsOccupancyPercent}%`
              : null}
          </strong>
        </div>
      </div>
    </DoughnutCard>
  );
};

export default Occupancy;
