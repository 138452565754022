import React, { Component } from 'react';
import { update, isNil, isEmpty } from 'ramda';
import moment from 'moment';
import { FormSection } from 'redux-form';
import { Field } from 'redux-form';
import MiniProfileFormPanel from '../../MiniProfileFormPanel';
import {
  renderDateField,
  renderTextField,
  renderSelectField,
  socialSecurityNumberParser,
  socialSecurityNumberFormatter,
  ssnMasker,
  extractDateFormat,
} from '../../../utils/redux-form-helper';
import { maritalStatuses } from '../../../containers/PrimaryLeaseApplication/AffordablePrimaryForm/configuration.js';
import DashesIfNullOrUndefined from '../../DashesIfNullOrUndefined';
import { requiredVal } from '../utils';

const PANEL_TITLE = 'Personal Information';
const DEFAULT_OPTION = { value: '', text: 'Choose', disabled: true };

type Props = {
  openPanel: ?string,
  togglePanel: Function,
  isAdult: boolean,
  formData: Object,
  intl: Object,
  states: Array<Object>,
  countries: Array<Object>,
  isAffordable: boolean,
  bypassedFields?: string[],
};

type State = {
  editable: boolean,
};

const parseBooleanToYesNo = (bool: ?boolean) => {
  if (typeof bool === 'boolean') {
    return bool === true ? 'Yes' : 'No';
  }
  return null;
};

const FIELD_TO_LABEL_MAP = {
  maritalStatus: 'Marital Status',
  birthday: 'Date of Birth',
  stateIdNumber: "Driver's License / State ID #",
  stateIdOrigin: "Driver's License / State ID State",
  socialSecurityNumber: 'Social Security Number',
  passportNumber: 'Passport #',
  passportCountry: 'Passport Country',
  alienRegistration: 'Alien Registration',
  dateOfBirth: 'Date of Birth',
  identificationNumber: 'Identification Number',
};

const PersonalInformationAdultFormSection = ({
  countries,
  dateFormat,
  disabled,
  isAffordable,
  formData,
  states,
  sectionInitialValues,
}) => {
  const {
    convicted,
    bankruptcy,
    bankruptcyCaseFiled,
    bankruptcyDischarged,
    bankruptcyDateFiled,
    type,
  } = formData;
  const parsedConvicted = parseBooleanToYesNo(convicted);
  const parsedBankruptcy = parseBooleanToYesNo(bankruptcy);
  const parsedBankruptcyDischarged = parseBooleanToYesNo(bankruptcyDischarged);
  const parsedBankruptcyDate = bankruptcyDateFiled
    ? moment(bankruptcyDateFiled).format('MM/DD/YYYY')
    : null;
  const maritalStatusOptions = [DEFAULT_OPTION].concat(maritalStatuses);
  const isMaritalStatusBypassed =
    isNil(sectionInitialValues.maritalStatus) ||
    isEmpty(sectionInitialValues.maritalStatus) ||
    sectionInitialValues.maritalStatus === DEFAULT_OPTION.value;
  const isBirthdayBypassed =
    isNil(sectionInitialValues.birthday) ||
    isEmpty(sectionInitialValues.birthday);

  return (
    <FormSection name="personalInformation">
      <div className="row">
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="maritalStatus"
              selectClassNames="input-md form-control"
              component={renderSelectField}
              label={`${FIELD_TO_LABEL_MAP.maritalStatus}*`}
              disabled={disabled}
              options={maritalStatusOptions}
              warn={isMaritalStatusBypassed ? requiredVal : undefined}
              validate={isMaritalStatusBypassed ? undefined : requiredVal}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="birthday"
              bsSize="md"
              component={renderDateField}
              placeholder={dateFormat}
              dateFormat={dateFormat}
              label={`${FIELD_TO_LABEL_MAP.birthday}*`}
              disabled={disabled}
              classes="has-float-label"
              warn={isBirthdayBypassed ? requiredVal : undefined}
              validate={isBirthdayBypassed ? undefined : requiredVal}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="stateIdNumber"
              className="input-md form-control"
              component={renderTextField}
              placeholder={disabled ? '---' : ''}
              label={`${FIELD_TO_LABEL_MAP.stateIdNumber}**`}
              showLabel={true}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="stateIdOrigin"
              selectClassNames="input-md form-control"
              component={renderSelectField}
              options={states}
              label={FIELD_TO_LABEL_MAP.stateIdOrigin}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="socialSecurityNumber"
              className="input-md form-control"
              component={renderTextField}
              placeholder={disabled ? '---' : ''}
              label={`${FIELD_TO_LABEL_MAP.socialSecurityNumber}**`}
              showLabel={true}
              disabled={disabled}
              parse={socialSecurityNumberParser}
              format={disabled ? ssnMasker : socialSecurityNumberFormatter}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="passportNumber"
              className="input-md form-control"
              component={renderTextField}
              placeholder={disabled ? '---' : ''}
              label={`${FIELD_TO_LABEL_MAP.passportNumber}**`}
              showLabel={true}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="passportCountry"
              selectClassNames="input-md form-control"
              component={renderSelectField}
              label={FIELD_TO_LABEL_MAP.passportCountry}
              options={countries}
              disabled={disabled}
            />
          </div>
        </div>
        {isAffordable && (
          <div className="col-sm-4">
            <div className="form-group">
              <Field
                name="alienRegistration"
                className="input-md form-control"
                component={renderTextField}
                placeholder={disabled ? '---' : ''}
                label={`${FIELD_TO_LABEL_MAP.alienRegistration}**`}
                showLabel={true}
                disabled={disabled}
              />
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <label>Have you ever been convicted of a felony? </label>
            <p>
              <DashesIfNullOrUndefined data={parsedConvicted} />
            </p>
          </div>
        </div>
      </div>
      {type !== 'short' && (
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label>Have you ever filed backruptcy? </label>
              <p>
                <DashesIfNullOrUndefined data={parsedBankruptcy} />
              </p>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label>Type Filed</label>
              <p>
                <DashesIfNullOrUndefined data={bankruptcyCaseFiled} />
              </p>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group">
              <label>Date Filed</label>
              <p>
                <DashesIfNullOrUndefined data={parsedBankruptcyDate} />
              </p>
            </div>
          </div>
        </div>
      )}
      {type !== 'short' && (
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label>Has the bankruptcy been discharged? </label>
              <p>
                <DashesIfNullOrUndefined data={parsedBankruptcyDischarged} />
              </p>
            </div>
          </div>
        </div>
      )}
    </FormSection>
  );
};

const PersonalInformationMinorFormSection = ({
  disabled,
  dateFormat,
  isAffordable,
  sectionInitialValues,
}) => {
  const isSocialSecurityNumberBypassed =
    isNil(sectionInitialValues.socialSecurityNumber) ||
    isEmpty(sectionInitialValues.socialSecurityNumber);
  const isDateOfBirthBypassed =
    isNil(sectionInitialValues.dateOfBirth) ||
    isEmpty(sectionInitialValues.dateOfBirth);

  return (
    <FormSection name="personalInformation">
      <div className="row">
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="socialSecurityNumber"
              className="input-md form-control"
              component={renderTextField}
              placeholder={disabled ? '---' : ''}
              label={`${FIELD_TO_LABEL_MAP.socialSecurityNumber}${
                isAffordable ? '*' : ''
              }`}
              showLabel={true}
              disabled={disabled}
              parse={socialSecurityNumberParser}
              format={disabled ? ssnMasker : socialSecurityNumberFormatter}
              warn={
                isSocialSecurityNumberBypassed && isAffordable
                  ? requiredVal
                  : undefined
              }
              validate={
                isSocialSecurityNumberBypassed || !isAffordable
                  ? undefined
                  : requiredVal
              }
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group padtop20">
            <Field
              name="dateOfBirth"
              bsSize="md"
              component={renderDateField}
              placeholder={dateFormat}
              dateFormat={dateFormat}
              label={`${FIELD_TO_LABEL_MAP.dateOfBirth}${
                isAffordable ? '*' : ''
              }`}
              disabled={disabled}
              warn={
                isDateOfBirthBypassed && isAffordable ? requiredVal : undefined
              }
              validate={
                isDateOfBirthBypassed || !isAffordable ? undefined : requiredVal
              }
            />
          </div>
        </div>
      </div>
    </FormSection>
  );
};

export class PersonalInformation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editable: false,
    };
  }

  toggleEdit = () => {
    this.setState({ editable: !this.state.editable });
  };

  render() {
    const {
      openPanel,
      togglePanel,
      isAdult,
      formData,
      intl,
      states,
      countries,
      isAffordable,
      bypassedFields = [],
      sectionInitialValues,
    } = this.props;
    const mappedBypassedFields = bypassedFields.map(
      (bf) => FIELD_TO_LABEL_MAP[bf],
    );

    const dateFormat = extractDateFormat(intl);
    const stateOptions = update(0, { value: '', text: 'Choose' }, states);
    const countryOptions = update(0, { value: '', text: 'Choose' }, countries);
    return (
      <MiniProfileFormPanel
        open={openPanel === PANEL_TITLE}
        toggleOpen={togglePanel(PANEL_TITLE)}
        title={PANEL_TITLE}
        bypassedFields={mappedBypassedFields}
      >
        <div className="container-fluid padleft5 padright5 padtop15 padbottom15">
          <div className="row">
            <div className="col-md-12">
              <a
                onClick={() => this.toggleEdit()}
                className="small simple-link pull-right"
              >
                <i className="icon et-pencil" />
                <span>Edit </span>
              </a>
            </div>
          </div>
          {isAdult ? (
            <PersonalInformationAdultFormSection
              disabled={!this.state.editable}
              formData={formData}
              dateFormat={dateFormat}
              states={stateOptions}
              countries={countryOptions}
              isAffordable={isAffordable}
              sectionInitialValues={sectionInitialValues}
            />
          ) : (
            <PersonalInformationMinorFormSection
              disabled={!this.state.editable}
              dateFormat={dateFormat}
              isAffordable={isAffordable}
              sectionInitialValues={sectionInitialValues}
            />
          )}
        </div>
      </MiniProfileFormPanel>
    );
  }
}

export default PersonalInformation;
