import { defineMessages } from 'react-intl';

/* eslint-disable max-len */
const messages = defineMessages({
  save: {
    id: 'App.LeaseDataTabForm.LeaseActionButtons.ButtonStates.SaveLabel',
    defaultMessage: 'Save',
  },
  edit: {
    id: 'App.LeaseDataTabForm.LeaseActionButtons.ButtonStates.Edit',
    defaultMessage: 'Edit',
  },
  uploadSignedLease: {
    id: 'App.LeaseDataTabForm.LeaseActionButtons.ButtonStates.UploadSignedLease',
    defaultMessage: 'Upload Signed Lease',
  },
  downloadLease: {
    id: 'App.LeaseDataTabForm.LeaseActionButtons.ButtonStates.DownloadLeaseLabel',
    defaultMessage: 'Download Lease',
  },
  confirmEditLease: {
    id: 'App.LeaseDataTabForm.LeaseActionButtons.ButtonStates.ConfirmEditLease',
    defaultMessage:
      'Editing the Lease Data Tab will render any previously downloaded lease and signatures invalid. Are you sure you want to continue?',
  },
  cancel: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.Cancel',
    defaultMessage: 'Cancel',
  },
  sendToPortal: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.SendToPortal',
    defaultMessage: 'Send to Portal',
  },
  sentToPortal: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.SentToPortal',
    defaultMessage: 'Lease sent to Portal',
  },
  executeLease: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.ExecuteLease',
    defaultMessage: 'Execute Lease',
  },
  electronicallyExecuted: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.ElectronicallyExecuted',
    defaultMessage: 'Lease electronically executed',
  },
  signedLeaseUploaded: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.SignedLeaseUploaded',
    defaultMessage: 'Signed lease has been uploaded to the Documents Tab',
  },
  confirmEditElectronicLease: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.ConfirmEditElectronicLease',
    defaultMessage:
      'Editing the Lease Data Tab will remove the lease from Portal and remove any signatures. Are you sure you want to continue?',
  },
  warning: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.Warning',
    defaultMessage: 'WARNING',
  },
  cancelElectronic: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.CancelElectronic',
    defaultMessage:
      'Cancelling the executed Lease will remove the lease from Portal and the Documents Tab and render the previously executed lease and signatures invalid.',
  },
  cancelManual: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.CancelManual',
    defaultMessage:
      'Cancelling the executed Lease will render the previously executed lease and signatures invalid, and remove the lease from the Documents Tab.',
  },
  areYouSure: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.AreYouSure',
    defaultMessage: 'Are you sure you want to continue?',
  },
  notReconciled: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.NotReconciled',
    defaultMessage:
      'Monthly Lease Charges have not been reconciled. Click "Edit" to unlock the Lease Data tab, add all monthly charges, and click save to continue.',
  },
  notApproved: {
    id: 'App.LeaseDataTabForm.UploadLeaseModal.ButtonStates.NotApproved',
    defaultMessage:
      'The compliance approval status must be Approved or Approved Pending Signatures in order to generate the lease.',
  },
});

export default messages;
