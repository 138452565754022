import { defineMessages } from 'react-intl';

const messages = defineMessages({
  submitButton: {
    id: 'App.TransferResident.submitButton',
    defaultMessage: 'Complete Transfer',
  },
  invalidDate: {
    id: 'App.TransferResident.InvalidDate',
    defaultMessage: 'Invalid date',
  },
  moveInDateInFuture: {
    id: 'App.TransferResident.MoveInDateInFuture',
    defaultMessage: 'Transfer Date cannot be in the future.',
  },
  moveInDateInPast: {
    id: 'App.TransferResident.MoveInDateInPast',
    defaultMessage: 'Transfer Date cannot be more than 30 days in the past.',
  },
  moveInLessThanStartDate: {
    id: 'App.TransferResident.MoveInLessThanStartDate',
    defaultMessage: 'Transfer Date cannot be before lease start date.',
  },
  beforeFiscalPeriod: {
    id: 'App.TransferResident.beforeFiscalPeriod',
    defaultMessage:
      'You cannot select a Transfer date that is in a prior/closed fiscal period.',
  },
  transferError: {
    id: 'App.TransferResident.transferError',
    defaultMessage: 'Transfer error',
  },
  transferSuccess: {
    id: 'App.TransferResident.transferSuccess',
    defaultMessage: 'Household transfer complete.',
  },
  transferSuccessHeader: {
    id: 'App.TransferResident.transferSuccessHeader',
    defaultMessage: 'Transfer successful',
  },
  confirmTransferHeader: {
    id: 'App.TransferResident.confirmTransferHeader',
    defaultMessage: 'Complete Transfer?',
  },
  confirmTransferBody: {
    id: 'App.TransferResident.confirmTransferBody',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Clicking "Yes" will complete the Transfer to the new unit. Prorated credits will automatically be posted to the resident\'s ledger for the prior unit, and prorated charges will automatically be posted to the resident\'s ledger for the new unit.',
  },
});

export default messages;
