import moment from 'moment';
import {
  validateRequired,
  validatePositiveMoney,
  validateDate,
} from '../../../../../../utils/validations';
import leaseMessages from './messages';
import formMessages from '../../../../../ShortLeaseApplication/messages';

const validate = (values: Object, { intl }: Object) => {
  const { startDate, endDate, noEndDate, amount } = values;
  const selectedStartDate = moment(startDate);
  const selectedEndDate = moment(endDate);
  const errors = {};

  [
    'subjournalId',
    'transactionType',
    'propertyTransactionCodeId',
    'startDate',
  ].forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      errors[fieldName] = intl.formatMessage(formMessages.required);
    }
  });

  ['startDate'].forEach((fieldName) => {
    if (!noEndDate && selectedStartDate.isAfter(selectedEndDate, 'day')) {
      errors[fieldName] = intl.formatMessage(
        leaseMessages.validDateAfterEndDate,
      );
    }
  });

  ['endDate'].forEach((fieldName) => {
    if (!noEndDate && !validateRequired(fieldName, values)) {
      errors[fieldName] = intl.formatMessage(formMessages.required);
    }

    if (!noEndDate && selectedStartDate.isAfter(selectedEndDate, 'day')) {
      errors[fieldName] = intl.formatMessage(
        leaseMessages.validDateBeforeStartDate,
      );
    }
  });

  ['amount'].forEach((fieldName) => {
    if (!validatePositiveMoney(amount)) {
      errors[fieldName] = intl.formatMessage(formMessages.validNumber);
    }
    if (parseFloat(amount) <= 0) {
      errors[fieldName] = intl.formatMessage(formMessages.validNumber);
    }
  });

  if (!validateDate(startDate)) {
    errors.startDate = intl.formatMessage(formMessages.validFormat);
  }

  if (endDate && !validateDate(endDate)) {
    errors.endDate = intl.formatMessage(formMessages.validFormat);
  }

  return errors;
};

export default validate;
