import { Form } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { useMemo, useCallback, useEffect } from 'react';
import useReactHookFormProps from '../../../hooks/useReactHookFormProps';
import { shouldDisableDate } from './utils';
import HeaderForm from './HeaderForm';

const RDLimitForm = ({
  floorPlan,
  withHeader,
  noteRentOnly,
  onChange,
  handleIsValid,
  handleIsDirty,
}) => {
  const { allowances = [] } = floorPlan;
  const utilityAllowanceAmount = allowances[0]?.utilityAllowanceAmount;

  //Note: No UA - WHEN: A floor plan does not have an RD Utility Allowance
  const noUA = !utilityAllowanceAmount;

  const ReactHookFormProps = useReactHookFormProps({
    defaultValues: {
      id: floorPlan.id,
      internalName: floorPlan?.internalName ?? '',
      marketingName: floorPlan?.marketingName ?? '',
      noteRent: floorPlan?.noteRent ?? '',
      basicRent: floorPlan?.basicRent ?? '',
      rdUtilityAllowance: utilityAllowanceAmount ?? 0,
    },
  });

  const { getValues, isValid, isDirty, setValue, watch } = ReactHookFormProps;

  useEffect(() => {
    handleIsValid(floorPlan.id, isValid);
    handleIsDirty(floorPlan.id, isDirty);
  }, [handleIsValid, handleIsDirty, floorPlan, isValid, isDirty]);

  useEffect(() => {
    if (isValid) {
      onChange(getValues());
    }
  }, [isValid, getValues, onChange]);

  const startDateRent = watch('startDateRent');

  useEffect(() => {
    if (startDateRent && noUA) {
      setValue('startDateBasicRent', startDateRent);
    }
  }, [startDateRent, setValue, noUA]);

  const noUAFields = useMemo(
    () => [
      {
        variant: 'text',
        name: 'internalName',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'text',
        name: 'marketingName',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'noteRent',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'newNoteRent',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          required: ({ getValues }) => {
            const startDate = getValues('startDateRent');
            return startDate && startDate.isValid();
          },
          validate: (value) => {
            const startDate = getValues('startDateRent');
            return !(startDate && !value);
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'date',
        name: 'startDateRent',
        rules: {
          required: ({ getValues }) =>
            Boolean(getValues('newNoteRent')?.length),
          validate: (value) => {
            const newNoteRent = getValues('newNoteRent');
            return !(newNoteRent && !value);
          },
        },
        format: 'YYYY-MM-DD',
        placeholder: 'YYYY-MM-DD',
        GridProps: {
          xs: 2,
        },
        shouldDisableDate,
      },
      {
        variant: 'number',
        name: 'basicRent',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'newBasicRent',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          required: ({ getValues }) => {
            const startDateBasicRent = getValues('startDateBasicRent');
            return startDateBasicRent && startDateBasicRent.isValid();
          },
          validate: (value) => {
            const startDateBasicRent = getValues('startDateBasicRent');
            if (startDateBasicRent && !value) {
              return 'New Note Rent is required when Start Date is provided';
            }
            return true;
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'date',
        name: 'startDateBasicRent',
        rules: {
          required: ({ getValues }) =>
            Boolean(getValues('newBasicRent')?.length),
          validate: (value) => {
            const newBasicRent = getValues('newBasicRent');
            if (newBasicRent && !value) {
              return 'newBasicRent is required when start date is provided';
            }
            return true;
          },
        },
        format: 'YYYY-MM-DD',
        placeholder: 'YYYY-MM-DD',
        GridProps: {
          xs: 2,
        },
        shouldDisableDate,
      },
    ],
    [getValues],
  );

  const noteRentOnlyFields = useMemo(
    () => [
      {
        variant: 'text',
        name: 'internalName',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'text',
        name: 'marketingName',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'noteRent',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },

      {
        variant: 'number',
        name: 'newNoteRent',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          required: ({ getValues }) => {
            const startDate = getValues('startDateRent');
            return startDate && startDate.isValid();
          },
          validate: (value) => {
            const startDate = getValues('startDateRent');
            return !(startDate && !value);
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'date',
        name: 'startDateRent',
        format: 'YYYY-MM-DD',
        placeholder: 'YYYY-MM-DD',
        rules: {
          required: ({ getValues }) =>
            Boolean(getValues('newNoteRent')?.length),
          validate: (value) => {
            const newNoteRent = getValues('newNoteRent');
            return !(newNoteRent && !value);
          },
        },
        GridProps: {
          xs: 2,
        },
        shouldDisableDate,
      },
      {
        variant: 'number',
        name: 'rdUtilityAllowance',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'newRdUtilityAllowance',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          required: ({ getValues }) => {
            const startDateUtilityAllowance = getValues(
              'startDateUtilityAllowance',
            );
            return (
              startDateUtilityAllowance && startDateUtilityAllowance.isValid()
            );
          },
          validate: (value) => {
            const startDateUtilityAllowance = getValues(
              'startDateUtilityAllowance',
            );
            if (startDateUtilityAllowance && !value) {
              return 'New Note Rent is required when Start Date is provided';
            }
            return true;
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'date',
        name: 'startDateUtilityAllowance',
        format: 'YYYY-MM-DD',
        placeholder: 'YYYY-MM-DD',
        rules: {
          required: ({ getValues }) =>
            Boolean(getValues('newRdUtilityAllowance')?.length),
          validate: (value) => {
            const newRdUtilityAllowance = getValues('newRdUtilityAllowance');
            if (newRdUtilityAllowance && !value) {
              return 'newBasicRent is required when start date is provided';
            }
            return true;
          },
        },
        GridProps: {
          xs: 2,
        },
        shouldDisableDate,
      },
    ],
    [getValues],
  );

  const allFields = useMemo(
    () => [
      {
        variant: 'text',
        name: 'internalName',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'text',
        name: 'marketingName',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'noteRent',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },

      {
        variant: 'number',
        name: 'newNoteRent',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          required: ({ getValues }) => {
            const startDate = getValues('startDateRent');
            return startDate && startDate.isValid();
          },
          validate: (value) => {
            const startDate = getValues('startDateRent');
            return !(startDate && !value);
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'date',
        name: 'startDateRent',
        format: 'YYYY-MM-DD',
        placeholder: 'YYYY-MM-DD',
        rules: {
          required: ({ getValues }) =>
            Boolean(getValues('newNoteRent')?.length),
          validate: (value) => {
            const newNoteRent = getValues('newNoteRent');
            return !(newNoteRent && !value);
          },
        },
        GridProps: {
          sx: {
            width: '155px',
          },
        },
        shouldDisableDate,
      },
      {
        variant: 'number',
        name: 'basicRent',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'newBasicRent',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          required: ({ getValues }) => {
            const startDateBasicRent = getValues('startDateBasicRent');
            return startDateBasicRent && startDateBasicRent.isValid();
          },
          validate: (value) => {
            const startDateBasicRent = getValues('startDateBasicRent');
            if (startDateBasicRent && !value) {
              return 'New Note Rent is required when Start Date is provided';
            }
            return true;
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'date',
        name: 'startDateBasicRent',
        format: 'YYYY-MM-DD',
        placeholder: 'YYYY-MM-DD',
        rules: {
          required: ({ getValues }) =>
            Boolean(getValues('newBasicRent')?.length),
          validate: (value) => {
            const newBasicRent = getValues('newBasicRent');
            if (newBasicRent && !value) {
              return 'newBasicRent is required when start date is provided';
            }
            return true;
          },
        },
        GridProps: {
          sx: {
            width: '155px',
          },
        },
        shouldDisableDate,
      },
      {
        variant: 'number',
        name: 'rdUtilityAllowance',
        adornment: '$',
        readOnly: true,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'number',
        name: 'newRdUtilityAllowance',
        adornment: '$',
        rules: {
          min: 1,
          step: 1,
          required: ({ getValues }) => {
            const startDateUtilityAllowance = getValues(
              'startDateUtilityAllowance',
            );
            return (
              startDateUtilityAllowance && startDateUtilityAllowance.isValid()
            );
          },
          validate: (value) => {
            const startDateUtilityAllowance = getValues(
              'startDateUtilityAllowance',
            );
            if (startDateUtilityAllowance && !value) {
              return 'New Note Rent is required when Start Date is provided';
            }
            return true;
          },
        },
        disabled: false,
        GridProps: {
          xs: 1,
        },
      },
      {
        variant: 'date',
        name: 'startDateUtilityAllowance',
        format: 'YYYY-MM-DD',
        placeholder: 'YYYY-MM-DD',
        rules: {
          required: ({ getValues }) =>
            Boolean(getValues('newRdUtilityAllowance')?.length),
          validate: (value) => {
            const newRdUtilityAllowance = getValues('newRdUtilityAllowance');
            if (newRdUtilityAllowance && !value) {
              return 'newBasicRent is required when start date is provided';
            }
            return true;
          },
        },
        GridProps: {
          sx: {
            width: '155px',
          },
        },
        shouldDisableDate,
      },
    ],
    [getValues],
  );

  const formFields = useCallback(() => {
    if (noUA) {
      return noUAFields;
    }
    if (noteRentOnly) {
      return noteRentOnlyFields;
    }
    return allFields;
  }, [noUAFields, noteRentOnlyFields, allFields, noUA, noteRentOnly]);

  const { formProps } = useMemo(() => {
    const props = {
      formProps: {},
    };

    props.formProps = {
      fields: formFields(),
    };

    return props;
  }, [formFields]);

  return (
    <>
      {withHeader && <HeaderForm noUA={noUA} noteRentOnly={noteRentOnly} />}
      <Form ReactHookFormProps={ReactHookFormProps} {...formProps} />
    </>
  );
};

export default RDLimitForm;
