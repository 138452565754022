import { defineMessages } from 'react-intl';

const messages = defineMessages({
  household: {
    id: 'App.Household.Household',
    defaultMessage: 'Household',
  },
  name: {
    id: 'App.Household.Name',
    defaultMessage: 'Name',
  },
  relation: {
    id: 'App.Household.Relation',
    defaultMessage: 'Relation',
  },
  status: {
    id: 'App.Household.Status',
    defaultMessage: 'Status',
  },
  startDate: {
    id: 'App.Household.StartDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'App.Household.EndDate',
    defaultMessage: 'End Date',
  },
  edit: {
    id: 'App.Household.Edit',
    defaultMessage: 'Edit',
  },
  add: {
    id: 'App.Household.Add',
    defaultMessage: 'Add',
  },
  vehicles: {
    id: 'App.Household.Vehicles',
    defaultMessage: 'Vehicles',
  },
  vehicleModel: {
    id: 'App.Household.VehicleModel',
    defaultMessage: 'Model',
  },
  licensePlate: {
    id: 'App.Household.LicensePlate',
    defaultMessage: 'License Plate',
  },
  workOrderPreferences: {
    id: 'App.Household.workOrderPreferences',
    defaultMessage: 'Work Order Preferences',
  },
  dba: {
    id: 'App.Household.dba',
    defaultMessage: 'DBA',
  },
  tenant: {
    id: 'App.Household.Tenant',
    defaultMessage: 'Tenant',
  },
});

export default messages;
