// $FlowFixMe
import { useCallback, useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import messages from './messages';
import StateService from '../../../../services/stateService';
import UnitService from '../../../../services/unitService';

/**
 *
 * The state value in the Units table is stored as '<State>' and not '<Country-Code>-<State Code>'
 *
 */
const parseStateOptions = (arrOfUnparsedOptions: Array<Object>) => {
  const parsedStateOptions = arrOfUnparsedOptions.map(({ id, code }) => ({
    value: code,
    text: code,
  }));
  return parsedStateOptions;
};

export const useAsyncStateOptions = () => {
  const [unitOptions, setUnitOptions] = useState(null);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchFormOptions = async () => {
      const options = { signal: abortController.signal };
      if (!unitOptions) {
        const stateService = new StateService();
        const [statesRes] = await Promise.all([stateService.getAll(options)]);

        const stateOptions = parseStateOptions(statesRes);

        setUnitOptions({
          stateOptions,
        });
      }
    };

    if (subscribed) {
      fetchFormOptions();
    }

    return () => {
      subscribed = false;
    };
  }, [unitOptions, setUnitOptions]);
  return unitOptions;
};

export const useAsyncSaveUnit = (
  unitId: string,
  intl: Object,
  selectedProperty: Object,
  toasterFn: Function,
  refreshUnit: Function,
) => {
  const [subscribed, setSubscribed] = useState(true);
  const [submitValues, setSubmitValues] = useState(null);

  const handleSaveUnit = (values: Object) => {
    setSubmitValues({
      ...values,
      isADA: values.isADA === 'true',
    });
  };

  const selectedPropertyId = pathOr(null, ['id'], selectedProperty);
  const organizationId = pathOr(null, ['organizationId'], selectedProperty);

  const fetchSaveUnit = useCallback(async () => {
    if (submitValues !== null && selectedPropertyId && organizationId) {
      // $FlowFixMe
      const abortController = new AbortController(); // eslint-disable-line
      const options = { signal: abortController.signal };
      const unitService = new UnitService();

      try {
        setSubmitValues(null);
        await unitService.updateUnit(
          selectedPropertyId,
          organizationId,
          unitId,
          submitValues,
          options,
        );

        // Update unit values in ViewUnit component
        refreshUnit(submitValues);

        if (subscribed) {
          toasterFn({
            title: intl.formatMessage(messages.success),
            message: intl.formatMessage(messages.updateUnitSuccess),
          });
        }
      } catch (error) {
        if (subscribed) {
          toasterFn({
            type: 'error',
            message: error.toString(),
            title: intl.formatMessage(messages.errorHeader),
          });
        }
      }
    }
  }, [
    unitId,
    submitValues,
    selectedPropertyId,
    organizationId,
    subscribed,
    intl,
    refreshUnit,
    toasterFn,
  ]);

  useEffect(() => {
    setSubscribed(true);
    fetchSaveUnit();
    return () => {
      setSubscribed(false);
    };
  }, [fetchSaveUnit]);

  return handleSaveUnit;
};
