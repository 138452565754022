import { is, pathOr, isNil, isEmpty } from 'ramda';
import moment from 'moment';

export const applySort =
  (currentSort: Object, columns: Object) => (a: Object, b: Object) => {
    const { order, columnName } = currentSort;
    const isAscending = order === 'ASC';
    if (columnName === '') return 1;

    const columnHeaders = pathOr([], ['headers'], columns);
    // $FlowFixMe
    const header = columnHeaders.find((h) => h.name === columnName);
    // $FlowFixMe
    const getValue = pathOr(pathOr('', [columnName]), ['pathOr'], header);

    // $FlowFixMe
    const aVal = getValue(a);
    // $FlowFixMe
    const bVal = getValue(b);

    if (is(Number, aVal) && is(Number, bVal)) {
      return isAscending ? aVal - bVal : bVal - aVal;
    }

    if (columnName.toLowerCase().includes('date')) {
      const aMoment = moment.utc(aVal);
      const bMoment = moment.utc(bVal);

      return isAscending ? aMoment.diff(bMoment) : bMoment.diff(aMoment);
    }

    return isAscending
      ? ('' + aVal).localeCompare(bVal, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      : ('' + bVal).localeCompare(aVal, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
  };

export const mapActiveCertsData = (
  activeCerts: Array<Object>,
): Array<Object> => {
  return activeCerts.map((certification) => {
    const certStatus = certification?.complianceApprovalStatus?.name;
    const scheduledMoveInDate = isEmpty(certification?.moveInDate ?? '')
      ? undefined
      : moment.utc(certification?.moveInDate).format('MM/DD/YYYY');
    const leaseStartDate = isEmpty(certification?.leaseStartDate ?? '')
      ? undefined
      : moment.utc(certification?.moveInDate).format('MM/DD/YYYY');
    const effectiveDate = isEmpty(certification?.voucherEffectiveDate ?? '')
      ? undefined
      : moment.utc(certification?.voucherEffectiveDate).format('MM/DD/YYYY');

    const latestComplianceDocument =
      isNil(certification?.latestDocument) ||
      isEmpty(certification?.latestDocument?.createdAt ?? '')
        ? '---'
        : `${moment
            .utc(certification?.latestDocument?.createdAt)
            .format('MM/DD/YYYY hh:mm a')} - ${
            certification?.latestDocument?.documentType?.name ?? '---'
          }`;

    const daysSinceLastUpload = isEmpty(
      certification?.latestDocument?.createdAt ?? '',
    )
      ? '---'
      : moment().diff(
          moment.utc(certification?.latestDocument?.createdAt),
          'days',
        );

    const lastCertifiedDate = isEmpty(certification?.lastCertifiedDate ?? '')
      ? undefined
      : moment.utc(certification?.lastCertifiedDate).format('MM/DD/YYYY');

    const nextRecertDueDate = isEmpty(certification?.nextRecertDueDate ?? '')
      ? undefined
      : moment.utc(certification?.nextRecertDueDate).format('MM/DD/YYYY');

    return {
      ...certification,
      certStatus,
      scheduledMoveInDate,
      leaseStartDate,
      latestComplianceDocument,
      daysSinceLastUpload,
      effectiveDate,
      lastCertifiedDate,
      nextRecertDueDate,
    };
  });
};
