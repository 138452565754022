import { defineMessages } from 'react-intl';

const messages = defineMessages({
  quotingRentAmount: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseCharges.QuotingRentAmount',
    defaultMessage: 'Quoting Rent Amount',
  },
  totalCharges: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseCharges.totalCharges',
    defaultMessage: 'Total Charges',
  },
  monthlyLeaseCharges: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseCharges.requiredMonthlyLeaseCharges',
    defaultMessage: 'Monthly Lease Charges',
  },
  requiredNonRentCharges: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseCharges.requiredNonRentCharges',
    defaultMessage: 'Required Non-Rent Charges',
  },
  rentCharges: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseCharges.rentCharges',
    defaultMessage: 'Rent Charges',
  },
  marketRentAmount: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseCharges.marketRentAmount',
    defaultMessage: 'Market Rent Amount',
  },
  grossRentLimit: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseCharges.grossRentLimit',
    defaultMessage: 'Gross Rent Limit',
  },
  hudGrossRentLimit: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseCharges.hudGrossRentLimit',
    defaultMessage: 'HUD Gross Rent Limit',
  },
  leaseRentAmount: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseCharges.leaseRentAmount',
    defaultMessage: 'Lease Rent Amount',
  },
  receivesHousingAssistance: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseCharges.receivesHousingAssistance',
    defaultMessage: 'Receives Housing Assistance',
  },
  utilityAllowance: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseCharges.UtilityAllowance',
    defaultMessage: 'Utility Allowance',
  },
  tooltipNRC: {
    id: 'App.LeaseDataTabForm.MonthlyLeaseCharges.TooltipNRC',
    defaultMessage:
      'Users with permission can edit these fees after the Lease Basics have been saved.',
  },
});

export default messages;
