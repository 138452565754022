import * as ActionTypes from './constants';

export function verifyToken(payload: any): * {
  return {
    type: ActionTypes.VERIFY_TOKEN,
    payload,
  };
}

export function verifyTokenSuccess(message: any): * {
  return {
    type: ActionTypes.VERIFY_TOKEN_SUCCESS,
    payload: message,
  };
}

export function verifyTokenFail(error: Error): * {
  return {
    type: ActionTypes.VERIFY_TOKEN_FAIL,
    payload: error,
  };
}

export function resetPassword(message: any): * {
  return {
    type: ActionTypes.RESET_PASSWORD,
    payload: message,
  };
}

export function resetPasswordSuccess(message: any): * {
  return {
    type: ActionTypes.RESET_PASSWORD_SUCCESS,
    payload: message,
  };
}

export function resetPasswordFail(error: Error): * {
  return {
    type: ActionTypes.RESET_PASSWORD_FAIL,
    payload: error,
  };
}