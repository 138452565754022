import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import CaptionButton from '../../../../../components/CaptionButton';
import SubmitButton from '../../../../../components/SubmitButton';
import ElementWithPermissions from '../../../../../components/ElementWithPermissions';
import confirm from '../../../../../components/ConfirmDialogModal';
import { navigateToUrlWithSelectedPropertyId } from '../../../../../utils/navigation-helpers';
import messages from '../messages';

type Props = {
  applicationId: string,
  doNotRenew: boolean,
  history: Object,
  intl: Object,
  lease: Object,
  removeLease: Function,
  residentId: string,
  selectPreviousLease: Function,
  canComplete: boolean,
  underEviction: boolean,
  formDirty: boolean,
};

export const RenewalButtons = ({
  applicationId,
  doNotRenew,
  history,
  intl,
  lease,
  removeLease,
  residentId,
  selectPreviousLease,
  canComplete,
  underEviction,
  formDirty,
}: Props) => {
  const renderConfirmDialog = () => {
    confirm(intl.formatMessage(messages.cancelRenewalConfirmationHeader), {
      body: intl.formatMessage(messages.cancelRenewalConfirmationBody),
      altBody: (
        <>
          <p> {intl.formatMessage(messages.cancelRenewalConfirmationBody1)}</p>
          <p> {intl.formatMessage(messages.cancelRenewalConfirmationBody2)}</p>
          <p> {intl.formatMessage(messages.cancelRenewalConfirmationBody3)}</p>
        </>
      ),
    }).then(() => {
      selectPreviousLease();
      removeLease(applicationId, lease.id, residentId);
    });
  };

  const url = `/complete-lease-renewal/${residentId}/${lease.id}`;
  const handleCompleteRenewLease = () =>
    navigateToUrlWithSelectedPropertyId(url);

  const { isRenewalComplete } = lease;
  const disableCompleteRenewal =
    isRenewalComplete ||
    underEviction ||
    doNotRenew ||
    !canComplete ||
    formDirty;

  const getCompleteRenewalHelpBlockMessage = () => {
    if (isRenewalComplete) return messages.completeDisabledAlreadyCompleted;
    if (underEviction) return messages.completeDisabledUnderEviction;
    if (doNotRenew) return messages.completeDisabledDoNotRenew;
    if (!canComplete) return messages.completeDisabled;
    return messages.completeDisabledDirty;
  };

  return (
    <Col md={16}>
      <div className="form-footer padtop20">
        <Row>
          <Col xs={12} lg={6} lgPush={4}>
            <ElementWithPermissions scope={['lease-create']}>
              <CaptionButton
                ButtonComp={SubmitButton}
                disabled={disableCompleteRenewal}
                classes="btn btn-tertiary up10"
                clickHandler={handleCompleteRenewLease}
                caption={
                  disableCompleteRenewal && (
                    <FormattedMessage
                      {...getCompleteRenewalHelpBlockMessage()}
                    />
                  )
                }
              >
                <FormattedMessage {...messages.completeRenewalButton} />
              </CaptionButton>
            </ElementWithPermissions>
          </Col>
          <Col xs={12} lg={6} lgPush={1}>
            <ElementWithPermissions scope={['lease-cancel']}>
              <Button
                className="btn btn-callto up10"
                onClick={renderConfirmDialog}
              >
                <FormattedMessage {...messages.cancelRenewalButton} />
              </Button>
            </ElementWithPermissions>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default injectIntl(RenewalButtons);
