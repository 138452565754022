import { useMutation, MutationOptions } from 'react-query';
import AffordableQualificationService from '../../services/affordableQualificationService';
import moment from 'moment/moment';

const useGenerate59AMutation = ({
  organizationId,
  propertyId,
  options,
}: {
  organizationId: string,
  propertyId: string,
  options?: MutationOptions,
}) => {
  return useMutation(
    ['AffordableQualificationService.generate59', organizationId, propertyId],
    async (affordableQualificationId: string) => {
      const fileName = `50059A_${moment().unix()}.pdf`;
      await new AffordableQualificationService().generate59(
        organizationId,
        propertyId,
        affordableQualificationId,
        fileName,
      );
    },
    {
      enabled: Boolean(organizationId && propertyId),
      ...options,
    },
  );
};

export default useGenerate59AMutation;
