import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import { renderCurrencyField } from '../../../../../utils/redux-form-helper';
import messages from './messages';

export const OneTimeLeaseChargesOrCreditsLeftColumn = ({
  intl,
  isEditable,
}: Object) => (
  <Col xs={12} sm={6}>
    <Row>
      <Col xs={7}>
        <label>{intl.formatMessage(messages.securityDepositLabel)}</label>
      </Col>
      <Col xs={5}>
        <div className="form-group">
          <Field
            name="securityDeposit"
            component={renderCurrencyField}
            step="0.01"
            disabled={!isEditable}
          />
        </div>
      </Col>
    </Row>
    <Row>
      <Col xs={7}>
        <label>{intl.formatMessage(messages.proratedRentLabel)}</label>
      </Col>
      <Col xs={5}>
        <div className="form-group">
          <Field
            name="proratedRent"
            component={renderCurrencyField}
            step="0.01"
            disabled={!isEditable}
          />
        </div>
      </Col>
    </Row>
    <Row>
      <Col xs={7}>
        <label>{intl.formatMessage(messages.proratedOtherLabel)}</label>
      </Col>
      <Col xs={5}>
        <div className="form-group">
          <Field
            name="proratedOther"
            component={renderCurrencyField}
            step="0.01"
            disabled={!isEditable}
          />
        </div>
      </Col>
    </Row>
  </Col>
);
