import { defineMessages } from 'react-intl';

const messages = defineMessages({
  csv: {
    id: 'App.Ledger.LedgerHeader.DownloadOptions.CSV',
    defaultMessage: 'CSV',
  },
  delimeter: {
    id: 'App.Ledger.LedgerHeader.DownloadOptions.Delimeter',
    defaultMessage: '______________________________',
  },
  placeholder: {
    id: 'App.Ledger.LedgerHeader.DownloadOptions.Placeholder',
    defaultMessage: 'Download options',
  },
  pdf: {
    id: 'App.Ledger.LedgerHeader.DownloadOptions.PDF',
    defaultMessage: 'PDF',
  },
  sortByPostDate: {
    id: 'App.Ledger.LedgerHeader.DownloadOptions.SortByPostDate',
    defaultMessage: 'Sorted by Actual Post Date',
  },
  sortByTransactionDate: {
    id: 'App.Ledger.LedgerHeader.DownloadOptions.SortByTransactionDate',
    defaultMessage: 'Sorted by Transaction Date',
  },
});

export default messages;
