import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.Overview.Evictions.Evictions',
    defaultMessage: 'Evictions'
  },
  evictionDQ: {
    id: 'App.Overview.Evictions.evictionDQ',
    defaultMessage: 'Eviction DQ'
  },
  units: {
    id: 'App.Overview.Evictions.units',
    defaultMessage: 'Units'
  }
});

export default messages;
