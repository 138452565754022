import { useQuery } from 'react-query';
import ActivityTypeService from '../../services/activityTypeService';

function useActivityTypes() {
  const { data, isLoading } = useQuery(
    ['activityTypes'],
    () => {
      const service = new ActivityTypeService();

      return service.getAll();
    },
    {
      staleTime: 60000,
    },
  );

  return {
    activityTypes: data ?? [],
    isLoading,
  };
}

export default useActivityTypes;
