// $FlowFixMe
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { BuildingService } from '../../services/buildingService';
import StateService from '../../services/stateService';
import PropertyService from '../../services/propertyService';
import messages from './messages';
import type { Building, UpdateBuilding, State } from './types';
import type { Configs } from '../App/types';

export const useAsyncBuildings = (selectedProperty: Object) => {
  const [buildings, setBuildings]: [
    Building[],
    Dispatch<SetStateAction<Building[]>>,
  ] = useState([]);
  const [refreshBuildings, setRefreshBuildings] = useState(true);

  const updateBuilding = async (
    selectedProperty: Object,
    id: string,
    data: UpdateBuilding,
    promptToaster: Function,
    intl: Object,
  ) => {
    const buildingService = new BuildingService();
    const { id: propertyId, organizationId } = selectedProperty;

    try {
      const buildingData = data;
      await buildingService.update(
        organizationId,
        propertyId,
        id,
        buildingData,
      );

      promptToaster({
        title: intl.formatMessage(messages.success),
        message: intl.formatMessage(messages.successBody),
      });
    } catch (error) {
      promptToaster({
        type: 'error',
        title: intl.formatMessage(messages.error),
        message: intl.formatMessage(
          messages[error.message] || messages.errorBody,
        ),
      });
    } finally {
      setRefreshBuildings(true);
    }
  };

  useEffect(() => {
    (async () => {
      if (refreshBuildings) {
        const buildingService = new BuildingService();
        const { id, organizationId } = selectedProperty;

        const buildings = await buildingService.getAll(organizationId, id);

        setBuildings(buildings);
        setRefreshBuildings(false);
      }
    })();
  }, [selectedProperty, refreshBuildings]);

  return { buildings, updateBuilding };
};

export const useAsyncStates = () => {
  const [states, setStates]: [State[], Dispatch<SetStateAction<Building[]>>] =
    useState([]);

  useEffect(() => {
    (async () => {
      const stateService = new StateService();

      const states = await stateService.getAll();

      setStates(states);
    })();
  }, []);

  return { states };
};

export const useAsyncPropertyConfigs = (
  selectedProperty: Object,
  promptToaster: Function,
  intl: Object,
) => {
  const [singleBuildingElection, setSingleBuildingElection]: [
    boolean,
    Dispatch<SetStateAction<boolean>>,
  ] = useState(false);
  const [refreshConfigs, setRefreshConfigs] = useState(true);

  const updateSingleBuildingElection = async (value: boolean) => {
    const propertyService = new PropertyService();
    const { id: propertyId, organizationId } = selectedProperty;

    try {
      await propertyService.updatePropertyConfigs(organizationId, propertyId, {
        singleBuildingElection: value,
      });

      setSingleBuildingElection(value);

      promptToaster({
        title: intl.formatMessage(messages.success),
        message: intl.formatMessage(messages.successSettingMessage),
      });
    } catch (error) {
      promptToaster({
        type: 'error',
        title: intl.formatMessage(messages.error),
        message: intl.formatMessage(
          messages[error.message] || messages.errorSettingMessage,
        ),
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (refreshConfigs) {
        const propertyService = new PropertyService();
        const { id: propertyId, organizationId } = selectedProperty;

        const configs: Configs = await propertyService.getPropertyConfigs(
          organizationId,
          propertyId,
        );

        setSingleBuildingElection(configs?.singleBuildingElection ?? false);
        setRefreshConfigs(false);
      }
    })();
  }, [selectedProperty, refreshConfigs]);

  return { updateSingleBuildingElection, singleBuildingElection };
};
