import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ReportCreateModal.Header',
    defaultMessage: 'Create Report',
  },
  cancel: {
    id: 'App.ReportCreateModal.Cancel',
    defaultMessage: 'Cancel',
  },
  saveAndClose: {
    id: 'App.ReportCreateModal.SaveAndClose',
    defaultMessage: 'Save and Close',
  },
  cancelConfirmationHeader: {
    id: 'App.ReportCreateModal.CancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to close without saving?',
  },
  yes: {
    id: 'App.ReportCreateModal.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.ReportCreateModal.No',
    defaultMessage: 'No',
  },
  invalidDate: {
    id: 'App.ReportCreateModal.InvalidDate',
    defaultMessage: 'Invalid date value',
  },
  noFutureDatesAllowed: {
    id: 'App.ReportCreateModal.NoFutureDatesAllowed',
    defaultMessage: 'Dates cannot be in the future.',
  },
  noEndDateBeforeStartDate: {
    id: 'App.ReportCreateModal.NoEndDateBeforeStartDate',
    defaultMessage: 'Start date needs to be before end date.',
  },
  noEndPeriodBeforeStartPeriod: {
    id: 'App.ReportCreateModal.NoEndPeriodBeforeStartPeriod',
    defaultMessage: 'Start period needs to be before end period.',
  },
  scheduledChargesPeriodLabel: {
    id: 'App.ReportCreateModal.ScheduledChargesPeriodLabel',
    defaultMessage: 'Scheduled Charges Period',
  },
  actualChargesPeriodLabel: {
    id: 'App.ReportCreateModal.ActualChargesPeriodLabel',
    defaultMessage: 'Actual Charges Period',
  },
  scheduledAndActualPeriodsRequired: {
    id: 'App.ReportCreateModal.ScheduledAndActualPeriodsRequired',
    defaultMessage: 'Scheduled and Actual Charges Periods are required.',
  },
  noActualPeriodAfterScheduledPeriod: {
    id: 'App.ReportCreateModal.NoActualPeriodAfterScheduledPeriod',
    defaultMessage:
      'Actual Charges Period must be on or before Scheduled Charges Period.',
  },
  noActualPeriodAfterNow: {
    id: 'App.ReportCreateModal.NoActualPeriodAfterNow',
    defaultMessage: 'Actual Charges Period can not be in the future.',
  },
  leaseTypeRequiredError: {
    id: 'App.ReportCreateModal.LeaseTypeRequiredError',
    defaultMessage: 'Lease Type is required.',
  },
});

export default messages;
