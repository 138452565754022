// @flow

import React from 'react';

import ErrorImage from '../../img/errorpage-404.svg';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const NotFound: any = () => (
  <div className="errorpage-wrapper text-center">
    <div className="errorpage-illustration">
      <div className="errornumber">
        <h1>404</h1>
      </div>
      <div className="errorgraphic">
        <img src={ErrorImage} alt="forbidden" />
      </div>
    </div>
    <div className="errorpage-message">
      <h2>
        <FormattedMessage {...messages.header} />
      </h2>
      <p>
        <FormattedMessage {...messages.message} />
        <br /> <FormattedMessage {...messages.message2} />
      </p>
      <p>
        <Button className="btn-tertiary" href="/">
          <FormattedMessage {...messages.button} />
        </Button>
      </p>
    </div>
  </div>
);

export default NotFound;
