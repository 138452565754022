import messages from './messages';

export const INIT_PAG_PROPERTIES = {
  results: [],
  meta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
};

export const INIT_QUERY = {
  sorting: { fieldName: 'lastName', order: 'ASC' },
  paginationInfo: { pageNumber: 1, limit: 15 },
  searchText: '',
  filterValue: {},
};

export const CSV_HEADERS = [
  {
    id: 'lastName',
    title: messages.lastName.defaultMessage,
  },
  {
    id: 'firstName',
    title: messages.firstName.defaultMessage,
  },
  {
    id: 'username',
    title: messages.username.defaultMessage,
  },
  {
    id: 'email',
    title: messages.email.defaultMessage,
  },
  {
    id: 'title',
    title: messages.title.defaultMessage,
  },
  {
    id: 'role',
    title: messages.role.defaultMessage,
  },
  {
    id: 'status',
    title: messages.status.defaultMessage,
  },
  {
    id: 'lastAccess',
    title: messages.lastAccess.defaultMessage,
  },
  {
    id: 'properties',
    title: messages.properties.defaultMessage,
  },
];
