/* eslint-disable max-len */
// @flow
import { omit } from 'ramda';
import request, { apiURL, del, get } from '../utils/api';

type HouseholdMemberCommon = {
  id: string,
  applicantId: string,
  applicant: {
    id: string,
    applicantTypeId: string,
    applicantType: {
      name: string,
      financiallyResponsible: boolean,
    },
    affordableRelationshipId: string,
  },
  rc: any,
  residentMinor: any,
  residentPet: any,
  relationshipId: string,
  relationship: Object,
  status: string,
  startDate: Date,
  endDate: Date,
  checklist: any,
};

export type AdultHouseholdMember = {
  type: 'adult',
  rc: {
    id: string,
    customerId: string,
    customer: {
      firstName: string,
      middleName?: string,
      lastName: string,
      preferredName?: string,
      suffixId?: string,
      suffix: Object,
      phoneNumber: string,
      emailAddress?: string,
      parentCompany?: string,
    },
    relationshipId: string,
    isPrimary: boolean,
  },
} & HouseholdMemberCommon;

export type MinorHouseholdMember = {
  type: 'minor',
  residentMinor: {
    firstName: string,
    middleName?: string,
    lastName: string,
    preferredName?: string,
    suffixId?: string,
    suffix: Object,
    age?: string,
  },
} & HouseholdMemberCommon;

export type PetHouseholdMember = {
  type: 'pet',
  residentPet: {
    name: string,
    breedId: string,
    petTypeId: string,
    petType: Object,
    weight?: number,
    petTag?: string,
    isServiceAnimal?: boolean,
  },
} & HouseholdMemberCommon;

export type HouseholdMember =
  | AdultHouseholdMember
  | MinorHouseholdMember
  | PetHouseholdMember;

export default class ResidentService {
  getResident(
    organizationId: string,
    propertyId: string,
    residentId: string,
    includeSoftDeletedUnits: boolean = false,
  ): any {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/resident/${residentId}${
        includeSoftDeletedUnits ? '?includeSoftDeletedUnits=true' : ''
      }`,
      options,
    );
  }

  moveOut(
    organizationId: string,
    propertyId: string,
    residentId: string,
    moveOutData: Object,
  ): any {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(moveOutData),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/resident/${residentId}/move-out`,
      options,
    );
  }
  saveMonthlyChargeResident(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    transaction: string,
  ): any {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(transaction),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/lease/${leaseId}/leaseMonthlyTransactionResident`,
      options,
    );
  }

  getMonthlyTransactionsResident(leaseId: string): any {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(
      `${apiURL}/lease/${leaseId}/leaseMonthlyTransactionResident`,
      options,
    );
  }

  renewLease(previousLeaseId: string, lease: any): any {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(lease),
    };
    return request(
      `${apiURL}/previousLease/${previousLeaseId}/lease-renewal-save`,
      options,
    );
  }

  startTransferLease(
    organizationId: string,
    propertyId: string,
    residentId: string,
    applicationId: string,
    unitId: string,
    userId: string,
  ): any {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ residentId, applicationId, userId, unitId }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/household/start-transfer`,
      options,
    );
  }
  completeTransfer(
    organizationId: string,
    propertyId: string,
    actualTransferDate: string,
    leaseId: string,
    userId: string,
  ): any {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ actualTransferDate, userId, leaseId }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/household/complete-transfer`,
      options,
    );
  }

  getTransferRequirements(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    userId: string,
  ): any {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/lease/${leaseId}/transfer-requirements`,
      options,
    );
  }

  saveLeaseDataTabResident(
    organizationId: string,
    propertyId: string,
    leaseId: string,
    lease: Object,
  ): any {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(lease),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/lease/${leaseId}/lease-resident`,
      options,
    );
  }

  removeLease(leaseId: string, options?: Object): any {
    return del(`/lease/${leaseId}`, options);
  }

  getResidentHousehold(
    residentId: string,
    organizationId: string,
    propertyId: string,
  ): Promise<HouseholdMember[]> {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/residents/${residentId}/household`,
      options,
    );
  }
  editResidentHousehold(
    residentId: string,
    payload: any,
    orgId: string,
    propertyId: string,
  ): Promise<any> {
    const options = {
      method: 'PATCH',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${orgId}/${propertyId}/residents/${residentId}/household`,
      options,
    );
  }

  editPendingApplicants(
    residentId: string,
    payload: any,
    orgId: string,
    propertyId: string,
  ): Promise<any> {
    const options = {
      method: 'PATCH',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${orgId}/${propertyId}/residents/${residentId}/pending-applicants`,
      options,
    );
  }

  updateResident(
    orgId: string,
    propertyId: string,
    id: string,
    residentInfo: any,
    isAdult?: boolean,
  ): any {
    const resident = isAdult
      ? residentInfo
      : {
          ...omit(['minorsResId'], residentInfo),
          residentId: residentInfo.minorsResId,
        };

    if (
      isAdult &&
      // Minors require these values, Adults do not
      (resident.hasOwnProperty('minorsResId') ||
        resident.hasOwnProperty('applicantId'))
    ) {
      delete resident.minorsResId;
      delete resident.applicantId;
    }

    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resident),
    };
    const path = isAdult ? 'resident' : 'applicantMinor';
    return request(
      `${apiURL}/${orgId}/${propertyId}/${path}/${id}/update`,
      options,
    );
  }

  getPendingApplicants(
    residentId: string,
    organizationId: string,
    propertyId: string,
  ): Promise<HouseholdMember[]> {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/residents/${residentId}/pending-applicants`,
      options,
    );
  }

  updateResidentMoveInDate(
    residentId: string,
    organizationId: string,
    propertyId: string,
    modify: Object,
  ): any {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(modify),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/residents/${residentId}/update-resident-move-in-date`,
      options,
    );
  }

  updatePriorResidentMoveOutDate(
    residentId: string,
    organizationId: string,
    propertyId: string,
    modify: Object,
  ): any {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(modify),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/prior-residents/${residentId}/update-move-out-date`,
      options,
    );
  }

  updateResidentPropertyMoveInDate(
    residentId: string,
    organizationId: string,
    propertyId: string,
    modify: Object,
  ): any {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(modify),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/residents/${residentId}/update-resident-property-move-in-date`,
      options,
    );
  }

  getLatestHouseholdHistory(
    residentId: string,
    organizationId: string,
    propertyId: string,
  ): any {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return get(
      `/${organizationId}/${propertyId}/residents/${residentId}/household-history`,
      options,
    );
  }

  getHouseholdLeasesByResidentId(
    organizationId: string,
    propertyId: string,
    residentId: string,
    options?: Object,
  ): any {
    return get(
      `/${organizationId}/${propertyId}/residents/${residentId}/leases`,
      options,
    );
  }

  getFirstUnitMoveInAfterPriorResident(
    organizationId: string,
    propertyId: string,
    residentId: string,
  ): any {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/prior-residents/${residentId}/next-unit-move-in`,
      options,
    );
  }

  getAllResidents(organizationId: string, propertyId: string): any {
    return get(
      `/${organizationId}/${propertyId}/residents?financialResponsable=true&nameUnitAndBalanceOnly=true`,
    );
  }
  /**
   *
   *
   * @param {string} organizationId
   * @param {string} propertyId
   * @param {string} residentId
   * @param {boolean} [checkForGrossRents=false]
   * @return {(Promise<Object>|Promise<boolean>)} When checkForGrossRents = true then Promise<{hasNonGrossRentOpenCert: boolean, hasGrossRentOpenCert: boolean}> otherwise Promise<boolean>
   * @memberof ResidentService
   */
  getHouseholdHasOpenCerts(
    organizationId: string,
    propertyId: string,
    residentId: string,
    checkForGrossRents?: boolean = false,
  ): Promise<boolean> {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return get(
      `/${organizationId}/${propertyId}/resident/${residentId}/has-${
        checkForGrossRents ? 'any-' : ''
      }open-certs`,
      options,
    );
  }

  /**
   * Will return the INCOME & ASSETS CHECKLISTS of the previous MOST RECENT COMPLETED AFFORDABLE QUALIFICATION
   * related to the person (household member) identified by the residentId
   * ---
   * @param {*} organizationId
   * @param {*} propertyId
   * @param {*} residentId
   * @param {*} applicantId
   * @returns the income and assets lists
   */
  getResidentIncomeAndAssetsDataFromMostRecentCompletedAQ(
    propertyId: string,
    residentId: string,
    applicantId: string,
    householdId: string,
    affordableProgramName: string,
  ): Promise<Array<Object>> {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    const queryString = `affordableProgramName=${affordableProgramName}`;

    return get(
      // eslint-disable-next-line max-len
      `/${propertyId}/resident/${residentId}/${applicantId}/${householdId}/income-and-assets-data-from-most-recent-completed-aq?${queryString}`,
      options,
    );
  }
}
