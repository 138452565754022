import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { NoPrint, Print } from 'react-easy-print';
import { FormattedDate } from 'react-intl';
import moment from 'moment';
import { pathOr, isNil } from 'ramda';
import messages from '../messages';
export const EditWorkOrderHeader = ({
  workOrderId,
  createdAt,
  enteredByUser,
  formatMessage,
  lastStatusChangeDate,
  status,
  finishedDate,
}: Object) => {
  let time, daysOpen;
  if (!createdAt) {
    time = '---';
    daysOpen = '---';
  } else {
    // $FlowFixMe
    const statusDescription = pathOr('', ['statusDescription'], status);

    const dateToCompare =
      statusDescription === 'Open' || statusDescription === 'On Hold'
        ? moment().toDate()
        : statusDescription === 'Canceled'
          ? lastStatusChangeDate
          : isNil(finishedDate)
            ? lastStatusChangeDate
            : finishedDate;
    const lastStatusChangeDateObject = moment(dateToCompare);
    const requestDateFormatted = moment(createdAt);
    daysOpen = lastStatusChangeDateObject.diff(requestDateFormatted, 'days');

    time = (
      <FormattedDate
        value={createdAt}
        year="numeric"
        month="numeric"
        day="2-digit"
        hour="numeric"
        minute="numeric"
      />
    );
  }
  const { firstName = '', lastName = '' } = enteredByUser;
  return (
    <Row>
      <Col className="form-header" xs={12}>
        <i className="et-tasks float-left" />
        <h2 className="float-left">
          <NoPrint force>{formatMessage(messages.edit)}</NoPrint>
          <Print printOnly name="workOrder">
            {formatMessage(messages.maintenanceRequest).toUpperCase()}
          </Print>
        </h2>
        <div className="col-xs-12 col-lg-9">
          <div className="highlight-details">
            <p>
              <span>
                <b>{formatMessage(messages.workOrderId)}: </b>
                {workOrderId ? workOrderId : '---'}
              </span>
              <span>
                {formatMessage(messages.createdAt)} {time} by{' '}
                {`${firstName} ${lastName}`}
              </span>
              <span>
                {' '}
                <b>{formatMessage(messages.daysOpen)}: </b>
                {daysOpen}
              </span>
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
};
