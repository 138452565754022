import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import moment from 'moment';
import { Button, Row, Col } from 'react-bootstrap';
import { pathOr } from 'ramda';
import Spinner from '../../../../components/Spinner';

import {
  CAM_STATUSES_CANCELLABLE,
  CAM_STATUSES_CAN_START_NEW,
  CAM_STATUSES_NOT_CONFIRMED,
} from '../../constants';
import messages from './messages';

type Props = {
  camRecords: Array<Object>,
  selectedCamRecord: Object,
  selectedCamRecordId: string,
  status: string,
  isLoadingStatus: boolean,
  avgSqftOccupancy: number,
  isLoadingAvgSqftOccupancy: boolean,
  onCamRecordSelected: Function,
  onShowEditCamRecordModal: Function,
  onCamRecordCancelClicked: Function,
  onStartNewCamRecordClicked: Function,
  isPriorResident: boolean,
};

const HeaderSection = ({
  camRecords,
  selectedCamRecord,
  selectedCamRecordId,
  status,

  isLoadingStatus,
  avgSqftOccupancy,
  isLoadingAvgSqftOccupancy,
  onCamRecordSelected,
  onShowEditCamRecordModal,
  onCamRecordCancelClicked,
  onStartNewCamRecordClicked,
  isPriorResident,
}: Props) => {
  const rentableSqFt = pathOr(
    null,
    ['unitRentableSquareFeet'],
    selectedCamRecord,
  );
  const camOptions = camRecords.map((camRecord, idx) => {
    const mStartDate = moment(camRecord.startDate);
    const formattedStartDate = mStartDate.format('M/D/YYYY');
    return (
      <option key={idx} value={camRecord.id}>
        {formattedStartDate}
      </option>
    );
  });

  const showCancelCamButton = CAM_STATUSES_CANCELLABLE.includes(status);
  const showStartNewCamButton =
    !showCancelCamButton && CAM_STATUSES_CAN_START_NEW.includes(status);
  const showEditStartDateButton = CAM_STATUSES_NOT_CONFIRMED.includes(status);

  const avgSqftOccupancyAsOf = moment().format('MM/DD/YYYY');

  return (
    <React.Fragment>
      <Col xs={12} md={7} lg={6}>
        <Row className="padtop10 padbottom10 padleft10">
          <Col xs={6} sm={3} md={4}>
            <Row>
              <h2>
                <FormattedMessage {...messages.modalTitle} />
              </h2>
            </Row>
            <Row>
              <FormattedMessage {...messages.squareFeet} />
            </Row>
            <Row>
              <FormattedMessage {...messages.avgPropertyOccupancy} />
            </Row>
            <Row>
              <FormattedMessage {...messages.status} />
            </Row>
          </Col>
          <Col xs={6} sm={9} md={8}>
            <Row>
              <Col xs={8}>
                <select
                  name="camStartDate"
                  className="form-control"
                  value={selectedCamRecordId}
                  onChange={(evt) => onCamRecordSelected(evt.target.value)}
                >
                  {camOptions}
                </select>
              </Col>
              {showEditStartDateButton && (
                <Col xs={2}>
                  <a onClick={onShowEditCamRecordModal} className="simple-link">
                    <i className="icon et-pencil"></i>
                  </a>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={12}>
                {rentableSqFt ? (
                  <FormattedNumber value={rentableSqFt} />
                ) : (
                  '---'
                )}
              </Col>
            </Row>
            <Row>
              {isLoadingAvgSqftOccupancy ? (
                <Spinner small />
              ) : avgSqftOccupancy ? (
                <Col xs={12}>
                  <FormattedNumber value={avgSqftOccupancy} />
                  {`% (as of ${avgSqftOccupancyAsOf})`}
                </Col>
              ) : (
                '---'
              )}
            </Row>
            <Row>
              {isLoadingStatus ? (
                <Spinner small />
              ) : (
                <Col xs={12}>{status}</Col>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={12} md={5} lg={6}>
        <Col>
          {showCancelCamButton && (
            <Button
              className="btn btn-tertiary pull-right"
              onClick={onCamRecordCancelClicked}
            >
              <FormattedMessage {...messages.cancelCam} />
            </Button>
          )}
          {showStartNewCamButton && (
            <Button
              className="btn btn-tertiary pull-right"
              onClick={onStartNewCamRecordClicked}
              disabled={isPriorResident}
            >
              <FormattedMessage {...messages.startNewCam} />
            </Button>
          )}
        </Col>
      </Col>
    </React.Fragment>
  );
};

export default HeaderSection;
