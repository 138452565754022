import { useMemo } from 'react';

/**
 * Hook to determine if property is affordable. Must have propertyClass object
 * @param {*} property property object with propertyClass object
 * const property = {
 *    propertyClass: {
 *      name: 'Mixed'
 *    }
 * }
 * @returns boolean
 */
function useIsAffordableProperty(property: object): boolean {
  const isAffordableProperty = useMemo(
    () =>
      property?.propertyClass?.name === 'Mixed' ||
      property?.propertyClass?.name === 'Affordable',
    [property],
  );

  return isAffordableProperty;
}

export default useIsAffordableProperty;
