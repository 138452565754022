import moment from 'moment';

import { COMPLIANCE_TYPES } from './constants';
import { sortAndFilterRows } from '../../utils';
import { COMPLIANCE_APPROVAL_STATUSES } from '../../utils/affordable';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import messages from '../App/messages';

const parseComplianceOverviewEntries = (
  complianceOverviewData: Array<Object>,
  currentSorting: Object,
  hasFilters: boolean,
  filter: Object,
  searchText: string,
): Array<Object> => {
  const { fieldName, order } = currentSorting;
  let entries = complianceOverviewData;
  if (hasFilters) {
    const filters = Object.entries(filter).reduce((acc, f) => {
      const [key, value] = f;
      Object.entries(value).forEach((filterOptions) => {
        const [k, v] = filterOptions;
        if (v) {
          if (acc[key]) {
            acc[key].push(k);
          } else {
            acc[key] = [k];
          }
        }
      });
      return acc;
    }, {});
    entries = entries.filter((entry) => {
      return Object.entries(filters).every((f) => {
        const [key, value] = f;
        // $FlowFixMe
        if (
          key === 'waitlistApplicant' &&
          (value === 'Do Not Include' ||
            (value ?? []).includes('Do Not Include'))
        ) {
          return !entry?.waitlistApplicant;
        }
        return value
          .map((val) => (val === 'None' ? null : val))
          .includes(entry[key]);
      });
    });
  }
  return sortAndFilterRows(entries, fieldName, order, searchText);
};

export const buildRows = (
  complianceOverviewData: Array<Object>,
  complianceType: string,
  currentSorting: Object,
  hasFilters: boolean,
  filter: Object,
  searchText: string,
  onViewCertHistory: Function,
  flags?: Object,
): Array<Object> => {
  const complianceOverviewReviewedAndNumberOfDays =
    flags?.complianceOverviewReviewedAndNumberOfDays ?? false;
  const complianceCertHistorySummary =
    flags?.complianceCertHistorySummary ?? false;
  const rowEntries = parseComplianceOverviewEntries(
    complianceOverviewData,
    currentSorting,
    hasFilters,
    filter,
    searchText,
  );
  if (!rowEntries) return [];
  const recertLate = moment().hour(14).businessSubtract(7, 'days');
  const moveInLate = moment().hour(14).businessSubtract(2, 'days');
  const rows = rowEntries.map((row, i) => {
    const {
      id,
      applicationId,
      headOfHousehold,
      documentDate,
      documentName,
      programType,
      isResident,
      propertyName,
      residentId,
      complianceApprovalStatus,
      isActive,
      type,
      unitNumber,
      voucherEffectiveDate,
      isComplianceReviewed,
      isReviewed,
      oldestSubmissionDocumentDate,
      certOpenedDate,
      propertyId,
    } = row;
    const isRecert = type === 'Recert';
    const uploadDate = documentDate ? moment(documentDate) : '';
    const lateDate = isRecert ? recertLate : moveInLate;
    let customerType = 'application';
    if (type === 'Move-out') {
      customerType = 'prior-resident';
    } else if (isResident === 'Yes') {
      customerType = 'resident';
    }
    const customerId = isResident === 'Yes' ? residentId : applicationId;
    const rowId = customerId || id;
    const customerUrl = `/property/${propertyId}/${customerType}/${customerId}`;
    const error =
      complianceOverviewReviewedAndNumberOfDays &&
      complianceType === COMPLIANCE_TYPES.ALL_OPEN_CERTS
        ? moment(voucherEffectiveDate).hours(14).isBefore(moment().hours(14))
        : [
            COMPLIANCE_APPROVAL_STATUSES.PENDING,
            COMPLIANCE_APPROVAL_STATUSES.PENDING_FINAL_APPROVAL,
            null,
          ].includes(complianceApprovalStatus) &&
          uploadDate &&
          uploadDate.isBefore(lateDate);

    const needsReview =
      !complianceOverviewReviewedAndNumberOfDays ||
      complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW
        ? undefined
        : !isComplianceReviewed;

    const certHistory = {
      headOfHousehold,
      oldestSubmissionDocumentDate,
      certOpenedDate,
    };

    const onViewClick = () => {
      onViewCertHistory(certHistory);
    };

    return {
      id: rowId,
      error,
      needsReview,
      certHistory,
      columns: [
        i + 1,
        type,
        propertyName,
        unitNumber,
        <a href={customerUrl} target="_blank" rel="noreferrer">
          {headOfHousehold}
        </a>,
        documentName,
        uploadDate ? uploadDate.format('MM/DD/YYYY hh:mm A') : '',
        ...(complianceOverviewReviewedAndNumberOfDays ? [isReviewed] : []),
        programType,
        voucherEffectiveDate
          ? moment(voucherEffectiveDate).format('MM/DD/YYYY')
          : '',
        ...(complianceCertHistorySummary
          ? [
              <button
                id={`onViewCertHistory-${id}`}
                className={cn('btn btn-text btn-text--small text-right')}
                onClick={onViewClick}
              >
                <FormattedMessage {...messages.view} />
              </button>,
            ]
          : []),
        complianceApprovalStatus,
        isActive,
        isResident,
      ],
    };
  });
  return rows;
};
