import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { pathOr, propOr } from 'ramda';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Data from '../../../components/Table/Data';
import FormattedDateOrDashes from '../../../components/FormattedDateOrDashes';
import Spinner from '../../../components/Spinner';
import FormatPhone from '../../../components/FormatPhone';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import Status from '../../ManageProspects/Status';
import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

type Props = {
  intl: any,
  rowsOnTable: Array<any>,
  toggleSelectedRow: Function,
  isLoading: boolean,
  customerStatus: string,
  filterApplied: boolean,
};

type prospectProps = {
  id: string,
  name: string,
  createdAt: any,
  assignedTo: string,
  desiredMoveInDate: any,
  phoneNumber: string,
  emailAddress: string,
  prospectStatusChangeLog: Array<Object>,
  currentProspectStatus: Object,
  prospectPreferences: Object,
};

type residentProps = {
  name: string,
  unitNumber: string,
  buildingNumber: string,
  balance: number,
  endDate: Date,
  moveInDate: Date,
  phoneNumber: string,
  emailAddress: string,
  id: string,
};

class CommunicationsDetails extends Component<Props> {
  renderDetails = (customerStatus: string, row: Object, intl: Object) => {
    switch (customerStatus) {
      case 'residents':
        return this.renderResidentRow(row, intl);
      case 'prospects':
        return this.renderProspectRow(row);
      case 'applicants':
        return this.renderApplicantRow(row);
      default:
        return '';
    }
  };

  renderResidentRow = (
    {
      name,
      unitNumber,
      buildingNumber,
      balance,
      endDate,
      moveInDate,
      phoneNumber,
      emailAddress,
      id,
    }: residentProps,
    intl: Object,
  ) => {
    return (
      <React.Fragment>
        <td className="hidden-xs">
          <Link to={getUrlWithSelectedPropertyId(`/resident/${id}`)}>
            {name}
          </Link>
        </td>
        <td className="hidden-xs">{unitNumber}</td>
        <td className="hidden-xs">{buildingNumber}</td>
        <td className="hidden-xs">
          {intl.formatNumber(balance, {
            style: 'currency',
            currency: 'USD',
          })}
        </td>
        <td className="hidden-xs">
          <FormattedDateOrDashes value={endDate} format="MMM DD, YYYY" />
        </td>
        <td className="hidden-xs">
          <FormattedDateOrDashes value={moveInDate} format="MMM DD, YYYY" />
        </td>
        <td className="hidden-xs">
          <FormatPhone phoneNumber={phoneNumber} />
        </td>
        <td className="hidden-xs">
          <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
        </td>
      </React.Fragment>
    );
  };

  renderProspectRow = ({
    id = '',
    name = '',
    createdAt = '',
    assignedTo = '',
    desiredMoveInDate = '',
    phoneNumber = '',
    emailAddress = '',
    prospectStatusChangeLog,
    currentProspectStatus,
    prospectPreferences,
  }: prospectProps) => {
    const statusColor = propOr('gray', 'color', currentProspectStatus);
    const statusName = propOr('', 'name', currentProspectStatus);
    const numberOfBedrooms = pathOr('---', ['nBeds'], prospectPreferences);
    const tooltip = <Tooltip id="tooltip">{statusName}</Tooltip>;
    return (
      <React.Fragment>
        <td>
          <span className="row-detail">Status:</span>
          <OverlayTrigger
            container={window.document.getElementById('table-row')}
            placement="right"
            delayHide={200}
            trigger={['hover', 'focus']}
            overlay={tooltip}
          >
            <span>
              <Status color={statusColor} />
            </span>
          </OverlayTrigger>
        </td>
        <td className="hidden-xs">
          <Link
            to={getUrlWithSelectedPropertyId(`/prospect/${id}`)}
          >{`${name}`}</Link>
        </td>
        <td className="hidden-xs">
          <FormattedDateOrDashes value={createdAt} format="MM/DD/YYYY" />
        </td>
        <td className="hidden-xs">
          <FormattedDateOrDashes
            // $FlowFixMe
            value={pathOr('', ['0', 'createdAt'], prospectStatusChangeLog)}
            format="MM/DD/YYYY"
          />
        </td>
        <td className="hidden-xs">
          <FormattedDateOrDashes
            value={desiredMoveInDate}
            format="MM/DD/YYYY"
          />
        </td>
        <td className="hidden-xs">{`${numberOfBedrooms}`}</td>
        <td className="hidden-xs">{`${assignedTo}`}</td>
        <td className="hidden-xs">
          <FormatPhone phoneNumber={phoneNumber} />
        </td>
        <td className="hidden-xs">
          <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
        </td>
      </React.Fragment>
    );
  };

  renderApplicantRow = ({
    applicationId = '',
    name = '',
    assignedUnit = '',
    status = '',
    scheduledMoveInDate = '',
    assignedTo = '',
    phoneNumber = '',
    emailAddress = '',
  }: Object) => {
    return (
      <React.Fragment>
        <td className="hidden-xs">
          <Link
            to={getUrlWithSelectedPropertyId(`/application/${applicationId}`)}
          >{`${name}`}</Link>
        </td>
        <td className="hidden-xs">{`${assignedUnit}`}</td>
        <td className="hidden-xs">{`${status}`}</td>
        <td className="hidden-xs">
          <FormattedDateOrDashes
            value={scheduledMoveInDate}
            format="MMM DD, YYYY"
          />
        </td>
        <td className="hidden-xs">{`${assignedTo}`}</td>
        <td className="hidden-xs">
          <FormatPhone phoneNumber={phoneNumber} />
        </td>
        <td className="hidden-xs">
          <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
        </td>
      </React.Fragment>
    );
  };

  render() {
    const {
      rowsOnTable,
      toggleSelectedRow,
      isLoading,
      customerStatus,
      intl,
      filterApplied,
    } = this.props;
    const rowsToRender = filterApplied
      ? rowsOnTable.filter((row) => {
          return row.applyFilter === true;
        })
      : rowsOnTable;
    return (
      <tbody>
        {isLoading ? (
          <tr>
            <Data colSpan={9}>
              <Spinner />
            </Data>
          </tr>
        ) : (
          rowsToRender.map((row) => {
            return (
              <tr key={row.id} className="table-section hidden-xs">
                <ElementWithPermissions scope={['communication-create']} hidden>
                  <td className="hidden-xs">
                    <div
                      className="checkbox custom-checkbox checkbox-lg"
                      style={{ margin: 0 }}
                    >
                      <label>
                        <input
                          type="checkbox"
                          checked={row.selected}
                          onChange={() => toggleSelectedRow(row.id)}
                        />{' '}
                        <span className="custom-check-square" />
                      </label>
                    </div>
                  </td>
                </ElementWithPermissions>
                {this.renderDetails(customerStatus, row, intl)}
              </tr>
            );
          })
        )}
      </tbody>
    );
  }
}

export default CommunicationsDetails;
