import { find, pathOr, propEq } from 'ramda';
import isNil from 'lodash/isNil';

type ParseAffordableQualificationIndicators = {
  isHotmaActive: boolean,
  rolloverIncomeAndAssets: boolean,
  isRecertification: boolean,
};

type ParseAffordableQualificationChecklistType = {
  currentApplicationChecklists: Array<Object>,
  applicantId: string,
  affordableQualificationId: string,
  incomeAndAssetsDataFromMostRecentCompletedAQ: Object,
  indicators: ParseAffordableQualificationIndicators,
};

export const parseChecklistValues = (
  checkLists: Array<Object>,
  applicantId: string,
): Object => {
  const applicantChecklist = find(propEq('applicantId', applicantId))(
    checkLists,
  );
  const incomes = pathOr({}, ['incomeChecklist'], applicantChecklist);
  const assets = pathOr({}, ['assetChecklist'], applicantChecklist);

  return {
    incomeChecklist: {
      ...incomes,
    },
    assetChecklist: {
      ...assets,
    },
  };
};

export const getCurrentTabAffordableProgram = (
  propertyAffordableProgramName: string,
  propertyAffordablePrograms: Array<Object>,
): string => {
  const currentTabAffordableProgram = propertyAffordablePrograms.find(
    (program) =>
      program?.masterAffordableProgram?.name === propertyAffordableProgramName,
  );

  return currentTabAffordableProgram || null;
};

export const parseAffordableQualificationChecklistValues = ({
  currentApplicationChecklists,
  applicantId,
  affordableQualificationId,
  incomeAndAssetsDataFromMostRecentCompletedAQ,
  indicators: { isHotmaActive, rolloverIncomeAndAssets, isRecertification },
}: ParseAffordableQualificationChecklistType): Object => {
  let incomes = {};
  let assets = {};
  if (
    rolloverIncomeAndAssets &&
    isRecertification &&
    incomeAndAssetsDataFromMostRecentCompletedAQ
  ) {
    incomes = incomeAndAssetsDataFromMostRecentCompletedAQ.incomeChecklist;
    assets = incomeAndAssetsDataFromMostRecentCompletedAQ.assetChecklist;
  } else {
    const checklistsForApplicantId = currentApplicationChecklists.filter(
      (checkList) => {
        const checklistApplicantId = pathOr('', ['applicantId'], checkList);
        return checklistApplicantId === applicantId;
      },
    );

    const applicantChecklist = find(
      propEq('affordableQualificationId', affordableQualificationId),
    )(checklistsForApplicantId);

    incomes = pathOr({}, ['incomeChecklist'], applicantChecklist);
    assets = pathOr({}, ['assetChecklist'], applicantChecklist);
  }

  if (isHotmaActive) {
    const assetsLists = Object.keys(assets)
      .filter((key) => key.includes('List'))
      .reduce((acc, key) => {
        const list = assets[key];
        return {
          ...acc,
          [key]: list.map((item) => ({
            ...item,
            actualImpute: item?.actualImpute ?? '-',
          })),
        };
      }, {});
    assets = {
      ...assets,
      ...assetsLists,
    };
  }

  return {
    incomeChecklist: {
      ...incomes,
    },
    assetChecklist: {
      ...assets,
    },
  };
};

export const getIsAltForm = (qualForm, checklistsObj, flags) => {
  const { incomeAndAssetUpdatesIl } = flags;
  const checklist =
    qualForm === 'incomeVerification'
      ? checklistsObj.incomeChecklist
      : checklistsObj.assetChecklist;
  return isNil(checklist?.isAltForm)
    ? incomeAndAssetUpdatesIl
    : checklist?.isAltForm === true;
};
