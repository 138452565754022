import React from 'react';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import PhotoIcon from '../../../img/photoIcon.svg';
import IconLink from '../../../components/IconLink';

const containerStyle = {
  display: 'flex',
  border: 'solid 1px',
  margin: '5px',
  marginRight: '20px',
  padding: '8px',
  borderRadius: '4px',
  borderColor: '#B4B4B4',
  alignItems: 'center',
  justifyContent: 'space-between',
};

type Props = {
  name: string,
  onDelete: Function,
  onDownload: Function,
  url: string,
  id: string,
  index: number,
};
function PhotoDescriptionCard({
  name,
  onDelete,
  onDownload,
  url,
  id,
  index,
}: Props) {
  return (
    <div style={{ ...containerStyle, marginLeft: index === 0 ? '0px' : '5px' }}>
      <img src={PhotoIcon} alt="" />
      <Typography
        style={{
          width: '100%',
          maxWidth: '80px',
          marginLeft: '10px',
          marginRight: index === 0 ? '0px' : '10px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'center',
        }}
      >
        {name}
      </Typography>
      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        <a download={name} href={url} title={name}>
          <IconLink iconClass="et-download" onClick={onDownload} />
        </a>
      </div>
      <IconLink
        iconClass="et-trash"
        onClick={() => {
          onDelete(id, name);
        }}
      />
    </div>
  );
}

export default PhotoDescriptionCard;
