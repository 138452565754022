import { defineMessages } from 'react-intl';

const messages = defineMessages({
  tableTitle: {
    id: 'centralizedManageDocuments.tableTitle',
    defaultMessage: 'Manage Documents',
  },
  documentName: {
    id: 'centralizedManageDocuments.documentName',
    defaultMessage: 'Document Name',
  },
  documentCategory: {
    id: 'centralizedManageDocuments.documentCategory',
    defaultMessage: 'Document Category',
  },
  categoryDetails: {
    id: 'centralizedManageDocuments.categoryDetails',
    defaultMessage: 'Category Details',
  },
  numberOfAssignedProperties: {
    id: 'centralizedManageDocuments.numberOfAssignedProperties',
    defaultMessage: '# of Assigned Properties',
  },
  actions: {
    id: 'centralizedManageDocuments.actions',
    defaultMessage: 'Action',
  },
  all: {
    id: 'centralizedManageDocuments.all',
    defaultMessage: 'All',
  },
  addNewDocument: {
    id: 'centralizedManageDocuments.addNewDocument',
    defaultMessage: 'Add New Document',
  },
  viewEdit: {
    id: 'centralizedManageDocuments.viewEdit',
    defaultMessage: 'View/Edit',
  },
});

export default messages;
