import moment from 'moment';

import { validatePhoneNumber, validateName } from '../../../utils/validations';
import { extractCurrentDateFormat } from '../../../utils/redux-form-helper';
import formMessages from '../../../containers/ShortLeaseApplication/messages';

import type { EmployerInformation } from '../../../containers/App/types';

const validate = (values: EmployerInformation, { intl }: Object) => {
  const errors = {};
  ['employerName', 'supervisorName'].forEach((fieldName) => {
    if (
      !errors[fieldName] &&
      values[fieldName] &&
      !validateName(values[fieldName])
    ) {
      errors[fieldName] = intl.formatMessage(formMessages.invalidValue);
    }
  });

  if (!errors.phoneNumber && !validatePhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = intl.formatMessage(formMessages.invalidValue);
  }

  if (values.annualSalary && parseFloat(values.annualSalary) < 0) {
    errors.annualSalary = intl.formatMessage(formMessages.invalidValue);
  }

  if (values.from) {
    const currentDateFormat = extractCurrentDateFormat(intl);
    const from = moment(values.from, currentDateFormat, true);
    if (!from.isValid()) {
      errors.from = intl.formatMessage(formMessages.invalidValue);
    }
  }

  if (values.to) {
    const currentDateFormat = extractCurrentDateFormat(intl);
    const to = moment(values.to, currentDateFormat, true);
    if (!to.isValid()) {
      errors.to = intl.formatMessage(formMessages.invalidValue);
    }
  }

  if (values.from && values.to) {
    const currentDateFormat = extractCurrentDateFormat(intl);
    const from = moment(values.from, currentDateFormat, true);
    const to = moment(values.to, currentDateFormat, true);
    if (to.isBefore(from)) {
      errors.to = intl.formatMessage(formMessages.mustBeAfterFrom);
    }
  }

  return errors;
};

export default validate;
