import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.Title',
    defaultMessage: 'Document Upload'
  },
  fileName: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.FileName',
    defaultMessage: 'File Name'
  },
  browse: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.Browse',
    defaultMessage: 'Browse'
  },
  documentType: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.DocumentType',
    defaultMessage: 'Document Type'
  },
  selectAType: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.SelectAType',
    defaultMessage: 'Select a Type'
  },
  householdMember: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.HouseholdMember',
    defaultMessage: 'Household Member'
  },
  selectAMember: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.SelectAMember',
    defaultMessage: 'Select a member'
  },
  documentNotes: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.DocumentNotes',
    defaultMessage: 'Document Notes (Optional)'
  },
  uploading: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.Uploading',
    defaultMessage: 'Uploading, please wait...'
  },
  cancel: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.Cancel',
    defaultMessage: 'Cancel'
  },
  saveAndClose: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.SaveAndClose',
    defaultMessage: 'Save and Close'
  },
  saveAndAddNew: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.SaveAndAddNew',
    defaultMessage: 'Save and Add New'
  },
  required: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.Required',
    defaultMessage: 'Required'
  },
  maxFileSize: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.MaxFileSize',
    defaultMessage: 'File maximum size is 100 MB'
  },
  cancelConfirmationHeader: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.CancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to close without saving?'
  },
  yes: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.Yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'App.ResidentProfile.ManageDocuments.Uploader.No',
    defaultMessage: 'No'
  }
});

export default messages;
