const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class MoveOutReasonService {
  getAll(organizationId: string, propertyId: string) {
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/lease-move-out-reasons`,
      requestOptions,
    );
  }
}

export default MoveOutReasonService;
