import { get, post, patch, del } from '../utils/api';

class CommercialContactsService {
  getCommercialContactTypes(organizationId: string, options?: Object) {
    return get(`/${organizationId}/commercial-contact-types`, options);
  }

  getCommercialContacts(
    organizationId: string,
    propertyId: string,
    householdId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/households/${householdId}/commercial-contacts`,
      options,
    );
  }

  create(
    organizationId: string,
    propertyId: string,
    householdId: string,
    contact: Object,
    options?: Object,
  ) {
    const body = JSON.stringify(contact);
    return post(
      `/${organizationId}/${propertyId}/households/${householdId}/commercial-contacts`,
      body,
      options,
    );
  }

  edit(
    organizationId: string,
    propertyId: string,
    householdId: string,
    contactId: string,
    contact: Object,
    options?: Object,
  ) {
    const body = JSON.stringify(contact);
    return patch(
      `/${organizationId}/${propertyId}/households/${householdId}/commercial-contacts/${contactId}`,
      body,
      options,
    );
  }

  delete(
    organizationId: string,
    propertyId: string,
    householdId: string,
    contactId: string,
    options?: Object,
  ) {
    return del(
      `/${organizationId}/${propertyId}/households/${householdId}/commercial-contacts/${contactId}`,
      options,
    );
  }
}

export default CommercialContactsService;
