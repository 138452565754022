import camelCase from 'lodash/camelCase';
import {
  blue,
  grey,
} from '@fortress-technology-solutions/fortress-component-library/design/index';

export const getHeaders = ({
  propertyRoles,
  propertyRoleAssignments,
  pastDueColumn,
}) => {
  const propertyRoleHeaders = propertyRoleAssignments
    ? propertyRoles.map((role) => ({
        id: camelCase(role),
        label: role,
        sortable: true,
        searchable: true,
        dataType: 'string',
        sx: { backgroundColor: blue.light },
        multiselect: true,
      }))
    : [];
  const pastDueCertificationsHeader = {
    id: 'pastDueCertifications',
    label: 'Past Due Certifications',
    sortable: true,
    sx: { backgroundColor: blue.main },
    dataType: 'number',
  };
  const HEADERS = [
    {
      id: 'propertyName',
      label: 'Property',
      sortable: true,
      searchable: true,
      dataType: 'string',
      sx: { backgroundColor: grey.lighter, color: 'text.primary' },
    },
    {
      id: 'numTotalUnits',
      label: 'Total Units',
      sortable: true,
      sx: { backgroundColor: grey.lighter, color: 'text.primary' },
      dataType: 'number',
    },
    {
      id: 'numOccupiedUnits',
      label: 'Occupied Units',
      sortable: true,
      sx: { backgroundColor: blue.light },
      dataType: 'number',
    },
    {
      id: 'vacantUnits',
      label: 'Vacant Units',
      sortable: true,
      sx: { backgroundColor: blue.light },
      dataType: 'number',
    },
    {
      id: 'currentOccupancy',
      label: 'Current Occupancy',
      sortable: true,
      sx: { backgroundColor: blue.light },
      dataType: 'number',
    },
    {
      id: 'netOccupancy',
      label: '30-Day Net %',
      sortable: true,
      sx: { backgroundColor: blue.light },
      dataType: 'number',
    },
    {
      id: 'delinquencyPercent',
      label: 'Collection Deposits % of Charges',
      sortable: true,
      sx: { backgroundColor: blue.main },
      dataType: 'number',
    },
    {
      id: 'delinquencyChargesTotal',
      label: 'PTD Charges',
      sortable: true,
      sx: { backgroundColor: blue.light },
      dataType: 'number',
    },
    {
      id: 'mtdDeposits',
      label: 'PTD Deposits',
      sortable: true,
      sx: { backgroundColor: blue.light },
      dataType: 'number',
    },
    {
      id: 'pendingDeposits',
      label: 'PTD Pending Deposits',
      sortable: true,
      sx: { backgroundColor: blue.light },
      dataType: 'number',
    },
    {
      id: 'concessions',
      label: 'PTD Concessions',
      sortable: true,
      sx: { backgroundColor: blue.light },
      dataType: 'number',
    },
    {
      id: 'numWorkOrdersOpen',
      label: 'Open Work Orders',
      sortable: true,
      sx: { backgroundColor: blue.main },
      dataType: 'number',
    },
    {
      id: 'avgWorkOrderDaysOpen',
      label: 'Avg Days Open',
      sortable: true,
      sx: { backgroundColor: blue.main },
      dataType: 'number',
    },
    {
      id: 'numNtvCurrentMonth',
      label: 'NTV - Current Month',
      sortable: true,
      sx: { backgroundColor: blue.light },
      dataType: 'number',
    },
    {
      id: 'numNtvNextMonth',
      label: 'NTV - Next Mo',
      sortable: true,
      sx: { backgroundColor: blue.light },
      dataType: 'number',
    },
    {
      id: 'numGrossLeasesApproved',
      label: 'Gross Leases - Approved',
      sortable: true,
      sx: { backgroundColor: blue.main },
      dataType: 'number',
    },
    {
      id: 'numGrossLeasesNotApproved',
      label: 'Gross Leases - Not Approved',
      sortable: true,
      sx: { backgroundColor: blue.main },
      dataType: 'number',
    },
    {
      id: 'numAvailable',
      label: 'Available Units',
      sortable: true,
      sx: { backgroundColor: blue.main },
      dataType: 'number',
    },
    ...(pastDueColumn ? [pastDueCertificationsHeader] : []),
    ...propertyRoleHeaders,
  ];

  return HEADERS;
};

export const SECTION_HEADERS = [
  { label: '', colSpan: 2, sx: { backgroundColor: grey.lighter } },
  {
    label: 'Occupancy',
    colSpan: 4,
    sx: { backgroundColor: blue.main },
  },
  {
    label: 'Collections',
    colSpan: 1,
    sx: { backgroundColor: blue.dark },
  },
  { label: 'PTD', colSpan: 4, sx: { backgroundColor: blue.main } },
  {
    label: 'Work Orders',
    colSpan: 2,
    sx: { backgroundColor: blue.dark },
  },
  {
    label: 'Notice to Vacates',
    colSpan: 2,
    sx: { backgroundColor: blue.main },
  },
  {
    label: 'Leasing',
    colSpan: 3,
    sx: { backgroundColor: blue.dark },
  },
];

export const getPropertyPathMap = ({ propertyRoles, pastDueColumn }) => {
  const propertyRolePathMap = propertyRoles
    .map((role) => ({
      [camelCase(role)]: {
        path: camelCase(role),
        dataType: 'string',
      },
    }))
    .reduce((acc, prop) => {
      return {
        ...acc,
        ...prop,
      };
    }, {});

  const pastDueCertificationsPropertyPathMap = {
    pastDueCertifications: {
      path: 'pastDueCertifications',
      dataType: 'number',
    },
  };
  const PROPERTY_PATH_MAP = {
    propertyName: {
      path: 'propertyName',
      dataType: 'string',
    },
    numTotalUnits: {
      path: 'numTotalUnits',
      dataType: 'number',
    },
    numOccupiedUnits: {
      path: 'numOccupiedUnits',
      dataType: 'number',
    },
    vacantUnits: {
      path: 'vacantUnits',
      dataType: 'number',
    },
    currentOccupancy: {
      path: 'currentOccupancy',
      dataType: 'number',
    },
    netOccupancy: {
      path: 'netOccupancy',
      dataType: 'number',
    },
    delinquencyPercent: {
      path: 'delinquencyPercent',
      dataType: 'number',
    },
    delinquencyChargesTotal: {
      path: 'delinquencyChargesTotal',
      dataType: 'number',
    },
    mtdDeposits: {
      path: 'mtdDeposits',
      dataType: 'number',
    },
    pendingDeposits: {
      path: 'pendingDeposits',
      dataType: 'number',
    },
    concessions: {
      path: 'concessions',
      dataType: 'number',
    },
    numWorkOrdersOpen: {
      path: 'numWorkOrdersOpen',
      dataType: 'number',
    },
    numNtvCurrentMonth: {
      path: 'numNtvCurrentMonth',
      dataType: 'number',
    },
    numNtvNextMonth: {
      path: 'numNtvNextMonth',
      dataType: 'number',
    },
    numGrossLeasesApproved: {
      path: 'numGrossLeasesApproved',
      dataType: 'number',
    },
    numGrossLeasesNotApproved: {
      path: 'numGrossLeasesNotApproved',
      dataType: 'number',
    },
    numAvailable: {
      path: 'numAvailable',
      dataType: 'number',
    },
    ...(pastDueColumn ? pastDueCertificationsPropertyPathMap : {}),
    ...propertyRolePathMap,
  };
  return PROPERTY_PATH_MAP;
};
