import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { clone, isNil, pathOr } from 'ramda';
import { renderRadioOption } from '../../../utils/redux-form-helper';

import messages from './messages';
import { FloorplanEditConfirmation } from '../FloorplansEditConfirmationModal';
import { calculateActualIncomeAverage } from '../../Home/PropertyComplianceTab/SetAsideSummary/utils';

const RenderModalHeader = () => {
  return (
    <Modal.Header closeButton>
      <Modal.Title componentClass="h1">
        <FormattedMessage {...messages.floorplanEditTitle} />
      </Modal.Title>
    </Modal.Header>
  );
};

const closeModal = (reset: Function, onClose: Function) => {
  reset();
  onClose();
};

const RenderModalFooter = ({ onClose, handleSubmit, disableSaveChanges }) => {
  return (
    <div className="modal-footer">
      <div className="row">
        <div className="col-xs-6">
          <button className="btn btn-default pull-right" onClick={onClose}>
            <FormattedMessage {...messages.cancel} />
          </button>
        </div>
        <div className="col-xs-6">
          <button
            className="btn btn-primary pull-left"
            type="submit"
            onClick={handleSubmit}
            disabled={disableSaveChanges}
          >
            <FormattedMessage {...messages.saveChanges} />
          </button>
        </div>
      </div>
    </div>
  );
};

const RenderModalBody = ({ children }) => {
  return (
    <Modal.Body>
      <div className="scrollable-modal__content row">
        <div className="container-fluid">
          <p className="no-margin-top">
            <FormattedMessage {...messages.selectTypeDescription} />
          </p>
          {children}
        </div>
      </div>
    </Modal.Body>
  );
};

const RenderFloorplansList = ({ floorplans }) => {
  const floorplansList = floorplans.map(
    ({ id, internalName, marketingName }) => {
      const text = `${internalName} "${marketingName}"`;

      return (
        <li key={id}>
          <div className="form-group">
            <div className="radio">
              <Field
                name="updatedFloorplanId"
                component={renderRadioOption}
                option={{ value: id, text }}
              />
            </div>
          </div>
        </li>
      );
    },
  );

  return <ul className="list-select">{floorplansList}</ul>;
};

const ViewFloorplansForm = ({
  incomeAveragingPercent,
  actualIncomeAvg,
  setAsideSummaryKPI,
  isUnitCommercial,
  isAffordableMixedProperty,
  floorplans,
  onClose,
  show,
  handleSubmit,
  onSubmit,
  initialValues,
  formValues,
  reset,
}: Object) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [projectedIncomeAvg, setProjectedIncomeAvg] = useState(actualIncomeAvg);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);

  useEffect(() => {
    const initialUpdatedFloorplanId = pathOr(
      '',
      ['updatedFloorplanId'],
      initialValues,
    );
    const updatedFloorplanId = pathOr('', ['updatedFloorplanId'], formValues);
    setDisableSaveChanges(initialUpdatedFloorplanId === updatedFloorplanId);
  }, [formValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeSubmit = () => {
    if (!isUnitCommercial && isAffordableMixedProperty) {
      const setAsidePrograms = clone(setAsideSummaryKPI);
      const initialUpdatedFloorplanId = pathOr(
        '',
        ['updatedFloorplanId'],
        initialValues,
      );
      const updatedFloorplanId = pathOr('', ['updatedFloorplanId'], formValues);
      if (
        initialUpdatedFloorplanId !== updatedFloorplanId &&
        updatedFloorplanId !== ''
      ) {
        const previousFloorplan = floorplans.find(
          (fp) => fp.id === initialUpdatedFloorplanId,
        );
        const selectedFloorplan = floorplans.find(
          (fp) => fp.id === updatedFloorplanId,
        );

        const previousUnitRentRestriction = (pathOr(
          '',
          ['unitRentRestriction'],
          previousFloorplan,
        ).match(/\d+/) || [])[0];
        const selectedUnitRentRestriction = (pathOr(
          '',
          ['unitRentRestriction'],
          selectedFloorplan,
        ).match(/\d+/) || [])[0];

        if (previousUnitRentRestriction !== selectedUnitRentRestriction) {
          const previousSetAsideProgram = isNil(previousUnitRentRestriction)
            ? undefined
            : setAsidePrograms.find(
                (setAsideProgram) =>
                  setAsideProgram.setAside === previousUnitRentRestriction,
              );

          const selectedSetAsideProgram = isNil(selectedUnitRentRestriction)
            ? undefined
            : setAsidePrograms.find(
                (setAsideProgram) =>
                  setAsideProgram.setAside === selectedUnitRentRestriction,
              );

          if (!isNil(previousSetAsideProgram)) {
            previousSetAsideProgram.numActualUnits--;
          }

          if (!isNil(selectedSetAsideProgram)) {
            selectedSetAsideProgram.numActualUnits++;
          }

          setProjectedIncomeAvg(
            calculateActualIncomeAverage(setAsidePrograms) || 0,
          );
          setShowConfirmModal(true);
        } else {
          handleSubmit();
        }
      } else {
        handleSubmit();
      }
    } else {
      handleSubmit();
    }
  };

  const handleEditConfirmSubmit = () => {
    setShowConfirmModal(false);
    handleSubmit();
  };

  const handleOnConfirmationClose = () => {
    setShowConfirmModal(false);
  };

  return (
    <form>
      <FloorplanEditConfirmation
        show={showConfirmModal}
        handleSubmit={handleEditConfirmSubmit}
        onSubmit={onSubmit}
        onClose={handleOnConfirmationClose}
        reset={reset}
        projectedIncomeAvg={projectedIncomeAvg}
        incomeAveragingPercent={incomeAveragingPercent}
        floorplans={floorplans}
      />
      <Modal
        className="modal transaction"
        backdrop
        bsSize="sm"
        show={show}
        onHide={() => closeModal(reset, onClose)}
      >
        <div className="modal-content">
          <RenderModalHeader />
          <RenderModalBody>
            <RenderFloorplansList floorplans={floorplans} />
          </RenderModalBody>
          <RenderModalFooter
            disableSaveChanges={disableSaveChanges}
            onClose={() => closeModal(reset, onClose)}
            handleSubmit={handleChangeSubmit}
          />
        </div>
      </Modal>
    </form>
  );
};

export const mapStateToProps = (
  { form: { floorplansForm } }: Object,
  { initialValues }: Object,
): Object => {
  return {
    initialValues,
    formValues: pathOr({}, ['values'], floorplansForm),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'floorplansForm',
    enableReinitialize: true,
  })(ViewFloorplansForm),
);
