import { defineMessages } from 'react-intl';

const messages = defineMessages({
  applicationId: {
    id: 'App.ManageApplicants.ApplicationId',
    defaultMessage: 'Application Id',
  },
  applicationCode: {
    id: 'App.ManageApplicants.ApplicationCode',
    defaultMessage: 'Application Code',
  },
  header: {
    id: 'App.ManageApplicants.Header',
    defaultMessage: 'Manage Applications',
  },
  applicants: {
    id: 'App.ManageApplicants.applicants',
    defaultMessage: 'Applicants',
  },
  texting: {
    id: 'App.ManageApplicants.Texting',
    defaultMessage: 'Texting',
  },
  status: {
    id: 'App.ManageApplicants.Status',
    defaultMessage: 'Status',
  },
  unitApp: {
    id: 'App.ManageApplicants.UnitApp',
    defaultMessage: 'Unit App',
  },
  assignedUnitNumber: {
    id: 'App.ManageApplicants.AssignedUnitNumber',
    defaultMessage: 'Assigned Unit #',
  },
  phoneNumber: {
    id: 'App.ManageApplicants.PhoneNumber',
    defaultMessage: 'Phone Number',
  },
  email: {
    id: 'App.ManageApplicants.Email',
    defaultMessage: 'Email',
  },
  lastActivity: {
    id: 'App.ManageApplicants.LastActivity',
    defaultMessage: 'Last Activity',
  },
  nextActivity: {
    id: 'App.ManageApplicants.NextActivity',
    defaultMessage: 'Next Activity',
  },
  assignedTo: {
    id: 'App.ManageApplicants.AssignedTo',
    defaultMessage: 'Assigned to',
  },
  view: {
    id: 'App.ManageApplicants.View',
    defaultMessage: 'View',
  },
  lastFollowUp: {
    id: 'App.ManageApplicants.LastFollowUp',
    defaultMessage: 'Last Activity',
  },
  nextFollowUp: {
    id: 'App.ManageApplicants.NextFollowUp',
    defaultMessage: 'Next Activity',
  },
  actions: {
    id: 'App.ManageApplicants.Actions',
    defaultMessage: 'Actions',
  },
  desiredMoveInDate: {
    id: 'App.ManageApplicants.desiredMoveInDate',
    defaultMessage: 'Desired Move-In Date',
  },
  scheduleMoveInDate: {
    id: 'App.ManageApplicants.ScheduleMoveInDate',
    defaultMessage: 'Scheduled Move-In Date',
  },
  search: {
    id: 'App.ManageApplicants.Search',
    defaultMessage: 'Search',
  },
  complianceApproval: {
    id: 'App.ManageApplicants.ComplianceApproval',
    defaultMessage: 'Compliance Approval',
  },
  complianceDocument: {
    id: 'App.ManageApplicants.ComplianceDocument',
    defaultMessage: 'Compliance Document',
  },
  complianceApprovalHUD: {
    id: 'App.ManageApplicants.ComplianceApprovalHUD',
    defaultMessage: 'HUD Compliance Approval',
  },
  complianceDocumentHUD: {
    id: 'App.ManageApplicants.ComplianceDocumentHUD',
    defaultMessage: 'HUD Compliance Document',
  },
  applicantConversionDate: {
    id: 'App.ManageApplicants.applicantConversionDate',
    defaultMessage: 'Applicant Conversion Date',
  },
  applicantNotes: {
    id: 'App.ManageApplicants.applicantNotes',
    defaultMessage: 'Applicant Notes',
  },
  waitlistDateTime: {
    id: 'App.ManageApplicants.waitlistDateTime',
    defaultMessage: 'Waitlist Date and Time',
  },
  waitlistNotes: {
    id: 'App.ManageApplicants.waitlistNotes',
    defaultMessage: 'Waitlist Notes',
  },
  viewingItems: {
    id: 'App.Pagination',
    defaultMessage: 'Viewing items {lower} - {higher} of {total}',
  },
});

export default messages;
