import { defineMessages } from 'react-intl';

const messages = defineMessages({
  lookingFor: {
    id: 'App.ProspectProfile.LookingFor.LookingFor',
    defaultMessage: 'What are you looking for in your apartment home?',
  },
  preferredFloorPlanLabel: {
    id: 'App.ProspectProfile.LookingFor.FloorPlanLabel',
    defaultMessage: 'Floor Plan',
  },
  preferredFloorPlanPlaceholder: {
    id: 'App.ProspectProfile.LookingFor.PreferredFloorPlanPlaceholder',
    defaultMessage: 'Select Floor Plan',
  },
  bedsLabel: {
    id: 'App.ProspectProfile.LookingFor.BedsLabel',
    defaultMessage: '# Beds',
  },
  bedsPlaceholder: {
    id: 'App.ProspectProfile.LookingFor.BedsPlaceholder',
    defaultMessage: '0',
  },
  bedsPlaceholderWithFlag: {
    id: 'App.ProspectProfile.LookingFor.BedsPlaceholdeWithFlag',
    defaultMessage: 'Select # of beds',
  },
  bathsLabel: {
    id: 'App.ProspectProfile.LookingFor.BathsLabel',
    defaultMessage: '# Baths',
  },
  bathsPlaceholder: {
    id: 'App.ProspectProfile.LookingFor.BathsPlaceholder',
    defaultMessage: '0',
  },
  halfBathsLabel: {
    id: 'App.ProspectProfile.LookingFor.HalfBathsLabel',
    defaultMessage: '# Half Baths',
  },
  halfBathsPlaceholder: {
    id: 'App.ProspectProfile.LookingFor.HalfBathsPlaceholder',
    defaultMessage: '0',
  },
  priceRangeLabel: {
    id: 'App.ProspectProfile.LookingFor.PriceRangeLabel',
    defaultMessage: 'Price Range',
  },
  priceFromLabel: {
    id: 'App.ProspectProfile.LookingFor.PriceFromLabel',
    defaultMessage: 'Price Range From',
  },
  priceToLabel: {
    id: 'App.ProspectProfile.LookingFor.PriceToLabel',
    defaultMessage: 'Price Range To',
  },
  leaseTermLabel: {
    id: 'App.ProspectProfile.LookingFor.LeaseTermLabel',
    defaultMessage: 'Lease Term',
  },
  month: {
    id: 'App.ProspectProfile.LookingFor.Month',
    defaultMessage: 'month',
  },
  months: {
    id: 'App.ProspectProfile.LookingFor.Months',
    defaultMessage: 'months',
  },
  movinDateFromlabel: {
    id: 'App.ProspectProfile.LookingFor.MoveInDateFromLabel',
    defaultMessage: 'Move-in Date *',
  },
  movinDateFromRequiredlabel: {
    id: 'App.ProspectProfile.LookingFor.MoveInDateFromRequiredLabel',
    defaultMessage: 'Move-in Date *',
  },
  noMoveInDateFromlabel: {
    id: 'App.ProspectProfile.LookingFor.NoMovinDateFromlabel',
    defaultMessage: 'No Move-in Date',
  },
  noPreference: {
    id: 'App.ProspectProfile.LookingFor.NoPreferenceLabel',
    defaultMessage: 'No Preference',
  },
  floorPlan: {
    id: 'App.ProspectProfile.LookingFor.FloorPlanLabel',
    defaultMessage: 'Floor Plan',
  },
  range: {
    id: 'App.ProspectProfile.LookingFor.Range',
    defaultMessage: 'Range',
  },
  chooseOption: {
    id: 'App.ProspectProfile.LookingFor.chooseOption',
    defaultMessage: 'Choose an option',
  },
  wants: {
    id: 'App.ProspectProfile.LookingFor.Wants',
    defaultMessage: 'Wants',
  },
  notWants: {
    id: 'App.ProspectProfile.LookingFor.NotWants',
    defaultMessage: "Doesn't Want",
  },
});

export default messages;
