import { defineMessages } from 'react-intl';

const messages = defineMessages({
  addTransaction: {
    id: 'App.GenerateRenewalOffer.addTransaction',
    defaultMessage: '+ Add Transaction',
  },
  goBack: {
    id: 'App.GenerateRenewalOffer.GoBack',
    defaultMessage: 'Go Back',
  },
  generateRenewalOffer: {
    id: 'App.GenerateRenewalOffer.GenerateRenewalOffer',
    defaultMessage: 'Generate Renewal Offer',
  },
  unitNumber: {
    id: 'App.GenerateRenewalOffer.UnitNumber',
    defaultMessage: 'Unit Number:',
  },
  zeroPreviousRenewalOffers: {
    id: 'App.GenerateRenewalOffer.ZeroPreviousRenewalOffers',
    defaultMessage: 'There are zero previous renewal offers',
  },
  previousOffers: {
    id: 'App.GenerateRenewalOffer.PreviousOffers',
    defaultMessage: 'Previous Renewal Offers Generated:',
  },
  newRenewalOffer: {
    id: 'App.GenerateRenewalOffer.NewRenewalOffer',
    defaultMessage: 'New Renewal Offer',
  },
  offerExpires: {
    id: 'App.GenerateRenewalOffer.OfferExpires',
    defaultMessage: 'Offer Expires',
  },
  term: {
    id: 'App.GenerateRenewalOffer.Term',
    defaultMessage: 'TERM',
  },
  requiredTerm: {
    id: 'App.GenerateRenewalOffer.RequiredTerm',
    defaultMessage: 'TERM *',
  },
  offer: {
    id: 'App.GenerateRenewalOffer.Offer',
    defaultMessage: 'Offer',
  },
  months: {
    id: 'App.GenerateRenewalOffer.Months',
    defaultMessage: 'Months',
  },
  leaseRent: {
    id: 'App.GenerateRenewalOffer.LeaseRent',
    defaultMessage: 'LEASE RENT',
  },
  requiredLeaseRent: {
    id: 'App.GenerateRenewalOffer.RequiredLeaseRent',
    defaultMessage: 'LEASE RENT *',
  },
  note: {
    id: 'App.GenerateRenewalOffer.Note',
    defaultMessage: 'NOTE:',
  },
  noteText: {
    id: 'App.GenerateRenewalOffer.NoteText',
    defaultMessage:
      'Only one Offer is required. Leave Offer 2 and 3 blank if you only want to provide one Offer.',
  },
  offerDate: {
    id: 'App.GenerateRenewalOffer.OfferDate',
    defaultMessage: 'Offer Date',
  },
  required: {
    id: 'App.GenerateRenewalOffer.Required',
    defaultMessage: 'Required',
  },
  invalidDateFormat: {
    id: 'App.GenerateRenewalOffer.InvalidDateFormat',
    defaultMessage: 'Invalid Date Format',
  },
  invalidDateValue: {
    id: 'App.GenerateRenewalOffer.InvalidDateValue',
    defaultMessage:
      'Date must be in between tomorrow and 120 days in the future',
  },
  mustSelectADate: {
    id: 'App.GenerateRenewalOffer.MustSelectADate',
    defaultMessage: 'Must select a date',
  },
  mustHaveTransactionCode: {
    id: 'App.GenerateRenewalOffer.MustHaveTransactionCode',
    defaultMessage: 'Must Choose Code',
  },
  mustHaveOfferAmount: {
    id: 'App.GenerateRenewalOffer.MustHaveOfferAmount',
    defaultMessage: 'Must have offer amount',
  },
  mustEnterALeaseRentAmount: {
    id: 'App.GenerateRenewalOffer.MustEnterLeaseRentAmount',
    defaultMessage: 'Must enter a Lease Rent amount',
  },
  mustChooseATermLength: {
    id: 'App.GenerateRenewalOffer.MustAlsoProvideLength',
    defaultMessage: 'Must choose a Term length',
  },
  success: {
    id: 'App.GenerateRenewalOffer.Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'App.GenerateRenewalOffer.Error',
    defaultMessage: 'Error',
  },
  createRenewalOfferSuccessDescription: {
    id: 'App.GenerateRenewalOffer.createRenewalOfferSuccessDescription',
    defaultMessage: 'Offer Generated',
  },
  valueGreaterThanZero: {
    id: 'App.GenerateRenewalOffer.ValueGreaterThanZero',
    defaultMessage: 'Please enter a valid number greater than 0',
  },
  valuePositive: {
    id: 'App.GenerateRenewalOffer.ValuePositive',
    defaultMessage: 'Please enter a positive value',
  },
  mustHaveOfferTwo: {
    id: 'App.GenerateRenewalOffer.mustHaveOfferTwo',
    defaultMessage: 'Please complete Offer 2',
  },
  earlierDateValue: {
    id: 'App.GenerateRenewalOffer.EarlierDateValue',
    defaultMessage: 'Date must be less than 90 days in the future',
  },
  transactionCode: {
    id: 'App.GenerateRenewalOffer.TransactionCode',
    defaultMessage: 'Transaction Code',
  },
  type: {
    id: 'App.CreateTransaction.Type',
    defaultMessage: 'Type',
  },
});

export default messages;
