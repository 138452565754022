import { useEffect, useState } from 'react';
import DocumentService from '../../../../../services/documentService';

export const useFetchScreeningLetterOptions = (
  isModalShown: boolean,
  promptToaster: Function,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [screeningLetterOptions, setScreeningLetterOptions] = useState([]);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchFloorPlansWithPricing = async () => {
      try {
        setIsLoading(true);
        const docService = new DocumentService();
        const options = { signal: abortController.signal };
        const result = await docService.getScreeningLetterOptions(options);
        setScreeningLetterOptions(result);
      } catch (error) {
        promptToaster({
          type: 'error',
          title: 'Error loading screening letter options.',
          message: error.toString(),
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (subscribed && isModalShown) {
      fetchFloorPlansWithPricing();
    }

    return () => {
      subscribed = false;
    };
  }, [promptToaster, isModalShown]);
  return { isLoading, screeningLetterOptions };
};

export const useFetchConditionsForApprovalOptions = (
  isModalShown: boolean,
  promptToaster: Function,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [conditionsForApprovalOptions, setConditionsForApprovalOptions] =
    useState([]);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchConditionsForApproval = async () => {
      try {
        setIsLoading(true);
        const docService = new DocumentService();
        const options = { signal: abortController.signal };
        const result = await docService.getAdverseActionConditionsForApproval(
          options,
        );
        setConditionsForApprovalOptions(result);
      } catch (error) {
        promptToaster({
          type: 'error',
          title: 'Error loading screening letter options.',
          message: error.toString(),
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (subscribed && isModalShown) {
      fetchConditionsForApproval();
    }

    return () => {
      subscribed = false;
    };
  }, [promptToaster, isModalShown]);
  return { isLoading, conditionsForApprovalOptions };
};
