import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from '../messages';
import type { FormatMessageFn } from '../../../types';
import { pluralizeWord } from '../../../utils/string-helpers';
import { bigNumberStyle, bodyText } from '../OverallAvgRatingCard/styles';
import RecommendIcon from '../../../img/Frame 45@3x.png';

export type Props = {
  formatMessage: FormatMessageFn,
  recommendedPercent?: number,
  reviewCount?: number,
  sourceCount?: number,
};

const RecommendBasedAvgRating = ({
  formatMessage,
  recommendedPercent,
  reviewCount,
  sourceCount,
}: Props) => (
  <>
    <Typography variant="h3">
      {formatMessage(messages.recommendBasedAvgRatingTitle)}
    </Typography>
    <Typography sx={bigNumberStyle}>{recommendedPercent ?? 0}%</Typography>
    <Typography sx={bodyText}>
      <img
        src={RecommendIcon}
        alt={formatMessage(messages.recommendedIcon)}
        style={{ width: 25, height: 25 }}
      />{' '}
      {formatMessage(messages.recommend)}
    </Typography>
    <Typography sx={bodyText}>
      {`${reviewCount.toLocaleString()} ${pluralizeWord(
        formatMessage(messages.reviewSingular),
        formatMessage(messages.reviewPlural),
        reviewCount,
      )} from ${sourceCount.toLocaleString()} ${pluralizeWord(
        formatMessage(messages.sourceSingular),
        formatMessage(messages.sourcePlural),
        sourceCount,
      )}`}
    </Typography>
  </>
);

export default RecommendBasedAvgRating;
