import { pathOr } from 'ramda';

export const allocationNameSorting = (
  allocA: Object,
  allocB: Obbject,
): number => {
  const nameA = pathOr(
    pathOr('', ['intacctGLAccountGroup', 'name'], allocA),
    ['camException', 'name'],
    allocA,
  );
  const nameB = pathOr(
    pathOr('', ['intacctGLAccountGroup', 'name'], allocB),
    ['camException', 'name'],
    allocB,
  );
  return nameA.toLowerCase() < nameB.toLowerCase() ? -1 : 1;
};
