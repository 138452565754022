import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import { Modal, Button } from 'react-bootstrap';
import { Field } from 'redux-form';

import { renderSelectField } from '../../../../../utils/redux-form-helper';

import SubmitButton from '../../../../../components/SubmitButton';
import Forms from '../LeaseOptionsFormBuilder';

import { FORM_NAME, EMPTY_SELECT_VALUE } from '../constants';

import { FlexFormContainer, FlexFormInput } from '../common';

import validate from './validate';

const CancelConfirmationBody = ({
  closeFormCallback,
  setCancelConfirmation,
}) => {
  return (
    <div className="modal-confirm">
      <h1>Are you sure you want to leave without saving?</h1>
      <div className="col-xs-12 col-md-6">
        <Button
          bsStyle="primary"
          className="btn-delete pull-right"
          onClick={closeFormCallback}
        >
          Yes
        </Button>
      </div>
      <div className="col-xs-12 col-md-6">
        <Button
          bsStyle="default"
          className="pull-left"
          onClick={() => setCancelConfirmation(false)}
        >
          No
        </Button>
      </div>
    </div>
  );
};

const DeleteConfirmationBody = ({
  handleDeleteOption,
  setDeleteConfirmation,
}) => {
  return (
    <div className="modal-confirm">
      <h1>Are you sure you want to delete this option record?</h1>
      <div className="col-xs-12 col-md-6">
        <Button
          bsStyle="primary"
          className="btn-delete pull-right"
          onClick={handleDeleteOption}
        >
          Yes
        </Button>
      </div>
      <div className="col-xs-12 col-md-6">
        <Button
          bsStyle="default"
          className="pull-left"
          onClick={() => setDeleteConfirmation(false)}
        >
          No
        </Button>
      </div>
    </div>
  );
};

const LeaseOptionsModalForm = ({
  selectedProperty,
  leaseId,
  openOptionForm,
  setOpenOptionFormCallback,
  handleSubmit,
  formValues,
  reset,
  change,
  commercialOptions,
  isSaving,
  handleSaveOption,
  isDeleting,
  handleDeleteOption,
  initialValues,
  intl,
  dirty,
  valid,
}: Object) => {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const selectedOption =
    formValues && formValues.commercialLeaseOptionId
      ? commercialOptions.find(
          (option) => option.id === formValues.commercialLeaseOptionId,
        )
      : null;

  const selectedForm =
    selectedOption && Forms[selectedOption.option]
      ? Forms[selectedOption.option]({ values: formValues, intl })
      : null;

  const header =
    formValues && formValues.commercialLeaseOptionId && selectedOption
      ? `Add ${selectedOption.option} Option`
      : 'Add Option';

  const closeForm = () => {
    reset();
    setOpenOptionFormCallback(false);
    setDeleteConfirmation(false);
    setCancelConfirmation(false);
  };

  const commercialOptionsFormatted = [EMPTY_SELECT_VALUE].concat(
    commercialOptions
      .map((val) => ({
        value: val.id,
        text: val.option,
      }))
      .sort((a, b) => a.text.localeCompare(b.text)),
  );

  const saveOption = async () => {
    await handleSaveOption(leaseId, formValues);
    closeForm();
  };

  const deleteOption = async () => {
    if (formValues && formValues.id) {
      await handleDeleteOption(leaseId, formValues.id);
    }
    closeForm();
    setDeleteConfirmation(false);
  };

  const cancelOrCloseForm = () => {
    if (dirty) {
      setCancelConfirmation(true);
    } else {
      closeForm();
    }
  };

  return (
    <Modal
      show={openOptionForm}
      onHide={cancelOrCloseForm}
      bsSize="large"
      autoFocus
    >
      <form>
        <Modal.Header closeButton>
          <i className="icon et-checklist" />
          <h1>{header}</h1>
        </Modal.Header>
        <Modal.Body>
          {deleteConfirmation && (
            <DeleteConfirmationBody
              handleDeleteOption={deleteOption}
              setDeleteConfirmation={setDeleteConfirmation}
            />
          )}
          {cancelConfirmation && (
            <CancelConfirmationBody
              closeFormCallback={closeForm}
              setCancelConfirmation={setCancelConfirmation}
            />
          )}
          {!cancelConfirmation && !deleteConfirmation && (
            <React.Fragment>
              {formValues && formValues.id && (
                <div className="pull-right padright30">
                  <div className="container-of-trash">
                    <div className="row-remove-btn">
                      <a onClick={() => setDeleteConfirmation(true)}>
                        <i className="et-trash" />
                      </a>
                    </div>
                  </div>
                </div>
              )}
              <FlexFormContainer addpadding={formValues && !!formValues.id}>
                <FlexFormInput>
                  <Field
                    name="commercialLeaseOptionId"
                    selectClassNames="input-md form-control"
                    component={renderSelectField}
                    label="Option Type"
                    onChange={reset}
                    options={commercialOptionsFormatted}
                    disabled={
                      initialValues && initialValues.commercialLeaseOptionId
                    }
                  />
                </FlexFormInput>
                {selectedForm}
              </FlexFormContainer>
            </React.Fragment>
          )}
        </Modal.Body>
        {!cancelConfirmation && !deleteConfirmation && (
          <Modal.Footer>
            <div className="row">
              <div className="col-xs-6">
                <button
                  className="btn btn-default pull-right"
                  onClick={cancelOrCloseForm}
                  disabled={false}
                >
                  Cancel
                </button>
              </div>
              <div className="col-xs-6">
                <SubmitButton
                  classes="btn btn-primary pull-left"
                  clickHandler={handleSubmit(saveOption)}
                  disabled={!dirty || !valid}
                  isSubmitting={false}
                >
                  Save
                </SubmitButton>
              </div>
            </div>
          </Modal.Footer>
        )}
      </form>
    </Modal>
  );
};

export const mapStateToProps = (state: Object): Object => {
  const {
    app: { selectedProperty },
  } = state;
  return {
    selectedProperty,
    formValues: getFormValues(FORM_NAME)(state),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate,
  })(LeaseOptionsModalForm),
);
