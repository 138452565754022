import React from 'react';
import { Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import DashesIfNullOrUndefined from '../../../../components/DashesIfNullOrUndefined';
import PencilButton from '../../../../components/Common/PencilButton';
import * as constants from './constants';

type ReadOnlyFieldProps = {
  label: string,
  value: string,
};

export const ReadOnlyField = ({ label, value }: ReadOnlyFieldProps) => (
  <React.Fragment>
    <Col xs={6}>
      <label>{label}</label>
    </Col>
    <Col xs={6}>
      <div className="form-group">
        <DashesIfNullOrUndefined data={value} />
      </div>
    </Col>
  </React.Fragment>
);

type DateFieldProps = {
  label: string,
  showPencil?: boolean,
  onPencilClick?: Function,
};

export const TwoColumnField = ({
  label,
  showPencil,
  onPencilClick,
  ...fieldProps
}: DateFieldProps) => (
  <React.Fragment>
    <Col xs={6}>
      <label>{label}</label>
    </Col>
    <Col xs={showPencil ? 5 : 6}>
      <Field {...fieldProps} />
    </Col>
    {showPencil && (
      <Col xs={1}>
        <PencilButton
          scope={constants.EDIT_LEASE_SCOPE}
          onClick={onPencilClick}
        />
      </Col>
    )}
  </React.Fragment>
);
