import { del, get, post, put } from '../utils/api';
import type { Vehicle } from '../containers/HouseholdVehicles/types';

class VehiclesService {
  getVehicleOptions(options?: Object) {
    return get('/vehicle-options', options);
  }

  getMakeOptions(makeId: string, options?: Object) {
    return get(`/vehicle-models?makeId=${makeId}`, options);
  }

  getAllHouseholdVehicles(
    organizationId: string,
    propertyId: string,
    householdId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/household-vehicles/${householdId}`,
      options,
    );
  }

  create(
    organizationId: string,
    propertyId: string,
    householdId: string,
    vehicles: Array<Vehicle>,
    options?: Object,
  ) {
    const body = JSON.stringify(vehicles);
    return post(
      `/${organizationId}/${propertyId}/household-vehicles/${householdId}`,
      body,
      options,
    );
  }

  update(
    organizationId: string,
    propertyId: string,
    householdId: string,
    vehicleId: string,
    vehicle: Vehicle,
    options?: Object,
  ) {
    const body = JSON.stringify(vehicle);
    return put(
      `/${organizationId}/${propertyId}/household-vehicles/${householdId}/${vehicleId}`,
      body,
      options,
    );
  }

  delete(
    organizationId: string,
    propertyId: string,
    householdId: string,
    vehicleId: string,
    options?: Object,
  ) {
    return del(
      `/${organizationId}/${propertyId}/household-vehicles/${householdId}/${vehicleId}`,
      options,
    );
  }
}

export default VehiclesService;
