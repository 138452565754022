const { apiURL, requestToDownload, get, put } = require('../utils/api');

const request = require('../utils/api').default;

class KpiService {
  getKPI(
    organizationId: string,
    propertyId: string,
    kpiName: string,
    signal: Object,
  ) {
    const options: Object = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    if (signal) {
      options.signal = signal;
    }
    return request(
      `${apiURL}/${organizationId}/${propertyId}/kpi/${kpiName}`,
      options,
    );
  }

  getPortfolio(organizationId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/${organizationId}/kpi/portfolio`, options);
  }

  getComplianceOverview(organizationId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/kpi/compliance-overview`,
      options,
    );
  }

  getComplianceOverviewNeedsReview(organizationId: string, options?: Object) {
    return get(
      `/${organizationId}/kpi/compliance-overview/needs-review`,
      options,
    );
  }

  // Deprecating this after delete-documents-feature
  getComplianceOverviewAllOpenCerts(organizationId: string, options?: Object) {
    return get(
      `/${organizationId}/kpi/compliance-overview/all-open-certs`,
      options,
    );
  }
  // New way after delete-documents-feature
  getComplianceOverviewOpenCerts(
    organizationId: string,
    includeNotStartedCerts?: boolean,
    options?: Object,
  ) {
    const queryString = `?includeNotStartedCerts=${includeNotStartedCerts}`;
    return get(
      `/${organizationId}/kpi-helper/compliance-overview/open-certs${queryString}`,
      options,
    );
  }

  downloadKpiHomeScreenPrint(
    imageData: string,
    canvasWidth: string,
    canvasHeight: string,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ canvasWidth, canvasHeight, imageData }),
    };

    return requestToDownload(
      `${apiURL}/kpi/download-home-screen-print`,
      options,
    );
  }

  /**
   * Will call the endpoint to update the pastDueCertifications portfolio column
   * ---
   * @note errors can be related to the propertyId uuid validation
   * @param {*} organizationId
   * @param {*} pastDueCertsData
   * @returns a promise
   */
  updatePastDueCertificationsColumn(organizationId: string, payload: Object) {
    return put(
      `/${organizationId}/kpi/portfolio/past-due-certs`,
      JSON.stringify(payload),
    );
  }
}

export default KpiService;
