import { defineMessages } from 'react-intl';

const messages = defineMessages({
  FloorplanPricingInformation: {
    id: 'App.ViewUnit.EditFees.FloorplanPricingInformation',
    defaultMessage: 'Floor Plan Pricing Information',
  },
  addedNewPricing: {
    id: 'App.ViewUnit.EditFees.AddedNewPricing',
    defaultMessage: 'Added New Pricing',
  },
  updatedPricing: {
    id: 'App.ViewUnit.EditFees.UpdatedPricing',
    defaultMessage: 'Pricing Updated',
  },
  deletedPricing: {
    id: 'App.ViewUnit.EditFees.DeletedPricing',
    defaultMessage: 'Pricing Deleted',
  },
  addedNewCharge: {
    id: 'App.ViewUnit.EditFees.AddedNewCharge',
    defaultMessage: 'Added New Charge',
  },
  updatedCharge: {
    id: 'App.ViewUnit.EditFees.UpdatedCharge',
    defaultMessage: 'Charge Updated',
  },
  deletedCharge: {
    id: 'App.ViewUnit.EditFees.DeletedCharge',
    defaultMessage: 'Charge Deleted',
  },
});

export default messages;
