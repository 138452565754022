import { defineMessages } from 'react-intl';

const messages = defineMessages({
  statusLabel: {
    id: 'App.EmploymentInformationSection.StatusLabel',
    defaultMessage: 'Status'
  },
  incomeLabel: {
    id: 'App.EmploymentInformationSection.IncomeLabel',
    defaultMessage: 'If you are employed, provide your annual income'
  },
  incomePlaceholder: {
    id: 'App.EmploymentInformationSection.IncomePlaceholder',
    defaultMessage: 'Enter Amount'
  }
});

export default messages;
