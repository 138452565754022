import { put, takeLatest, select, throttle } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as toastrActions } from 'react-redux-toastr';

import { GET_USER, EDIT_USER } from './constants';
import {
  getUserSuccess,
  getUserFail,
  setSelectedProperties,
  editUserSuccess,
  editUserFail,
  setSelectedPropertyGroups,
} from './actions';
import UserService from '../../services/userService';
import { selectCurrentUserOrganizationId } from '../App/selectors';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import messages from './messages';
import type { Saga } from 'redux-saga';

export function* fetchGetUser(action: Object): Saga<void> {
  try {
    const userService = new UserService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const user = yield userService.getUser(action.userId, organizationId); //agregar user id como parametro.
    yield put(getUserSuccess(user));
    yield put(
      setSelectedProperties(user.properties.map((property) => property.id)),
    );
    if (user.propertyGroups) {
      const selectedPropertyGroupIds = user.propertyGroups.map((pg) => pg.id);
      if (user.hasAffordableGroup) {
        selectedPropertyGroupIds.push('AffordableGroup');
      }
      if (user.hasConventionalGroup) {
        selectedPropertyGroupIds.push('ConventionalGroup');
      }
      if (user.hasCommercialGroup) {
        selectedPropertyGroupIds.push('CommercialGroup');
      }
      yield put(setSelectedPropertyGroups(selectedPropertyGroupIds));
    }
  } catch (err) {
    yield put(getUserFail(err));
  }
}

export function* getUserSaga(): Saga<void> {
  yield takeLatest(GET_USER, fetchGetUser);
}

export function* postEditUser(action: any): Saga<void> {
  try {
    const userService = new UserService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    yield userService.editUser(action.payload, action.id, organizationId);
    yield put(push('/manage-users'));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(editUserSuccess());
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(editUserFail(err));
  }
}

export function* editUserSaga(): Saga<void> {
  yield throttle(500, EDIT_USER, postEditUser);
}

export default [getUserSaga, editUserSaga];
