export const EMERGENCY_CONTACTS_FORM_FIELDS = [
  'id',
  'contactName',
  'phoneNumber',
  'extensionNumber',
  'secondaryPhoneNumber',
  'secondaryExtensionNumber',
  'emailAddress',
  'relationship',
  'contactAddress',
  'contactAddress2',
  'city',
  'stateId',
  'zipCode',
];

export const FORM_NAME = 'Emergency_Contact';
