import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { promptToaster } from '../../../containers/App/actions';
import { Row } from 'react-bootstrap';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';
import AddGroupGrid from './AddGroupGrid';
import AddGroupHeader from './AddGroupHeader';
import { useFetchResidents } from './hooks';
import confirm from '../../../components/ConfirmDialogModal';
import { isValid, isPristine, getFormValues } from 'redux-form';
import moment from 'moment';

type Props = {
  intl: any,
  organizationId: string,
  propertyId: string,
  history: Object,
  actions: Object,
  isHeaderFormValid: boolean,
  isHeaderFormPristine: boolean,
  addGroupHeaderFormValues: object,
  transactionBatchHeaderId: string,
  isAllCommercial: boolean,
};

const AddGroup = (props: Props) => {
  const {
    organizationId,
    propertyId,
    history,
    actions,
    intl,
    isHeaderFormValid,
    isHeaderFormPristine,
    addGroupHeaderFormValues,
    transactionBatchHeaderId,
    isAllCommercial,
  } = props;
  const [currentResidents, isCurrentResidentsLoading] = useFetchResidents({
    propertyId,
    organizationId,
    promptToaster: actions.promptToaster,
  });
  const [currentResidentRows, setCurrentResidentRows] = useState([]);
  const [disableAddToBatch, setDisableAddToBatch] = useState(false);
  useEffect(() => {
    if (
      !isCurrentResidentsLoading &&
      (currentResidents?.results?.length ?? 0) > 0
    ) {
      setCurrentResidentRows(
        [
          ...currentResidents?.results.map((row) => {
            return {
              status: row?.status,
              unitNumber: row?.unitNumber,
              floorPlan: row?.floorPlan,
              customerName: row?.customerName,
              leaseStartDate: moment(row?.leaseStartDate).format('MM/DD/YYYY'),
              leaseEndDate: moment(row?.leaseEndDate).format('MM/DD/YYYY'),
              currentLedgerBalance: `$${row?.balance?.toFixed(2)}`,
              selected: true,
              customerId: row?.customerId,
              residentId: row?.residentId,
            };
          }),
        ].sort((a, b) =>
          (a?.unitNumber ?? null) < (b?.unitNumber ?? null) ? -1 : 1,
        ),
      );
    }
  }, [currentResidents, isCurrentResidentsLoading]);

  const onGoBackClicked = () => {
    const shouldShowConfirm = !isHeaderFormPristine;
    if (!shouldShowConfirm) {
      return history.push(
        `/property/${propertyId}/create-transaction-batch/${
          transactionBatchHeaderId || ''
        }`,
      );
    }
    const formattedMessage = intl.formatMessage(
      messages.goBackCancelConfirmation,
    );
    confirm(formattedMessage).then(async () => {
      return history.push(
        `/property/${propertyId}/create-transaction-batch/${
          transactionBatchHeaderId || ''
        }`,
      );
    });
  };

  const handleAddToBatch = () => {
    const selectedResidents = currentResidentRows
      .filter((row) => row.selected)
      .map((row) => {
        return { customerId: row.customerId };
      });
    history.push({
      pathname: `/property/${propertyId}/create-transaction-batch/${
        transactionBatchHeaderId || ''
      }`,
      state: { selectedResidents, addGroupHeaderFormValues },
    });
  };
  return (
    <div className="bodywrap">
      <AddGroupHeader
        organizationId={organizationId}
        propertyId={propertyId}
        onGoBackClicked={onGoBackClicked}
      />
      <AddGroupGrid
        intl={intl}
        isCurrentResidentsLoading={isCurrentResidentsLoading}
        currentResidents={currentResidents}
        currentResidentRows={currentResidentRows}
        setCurrentResidentRows={setCurrentResidentRows}
        setDisableAddToBatch={setDisableAddToBatch}
        isAllCommercial={isAllCommercial}
      />
      <Row className="padtop30 text-center">
        <Button onClick={onGoBackClicked} variant="default" sx={{ mr: 2 }}>
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Button
          onClick={handleAddToBatch}
          variant="primary"
          disabled={
            disableAddToBatch || isHeaderFormPristine || !isHeaderFormValid
          }
        >
          <FormattedMessage {...messages.addToBatch} />
        </Button>
      </Row>
    </div>
  );
};
const InjectedAddGroup = injectIntl(AddGroup);
const mapDispatchToProps = (dispatch: any): Object => {
  const actions = bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  );
  return { actions };
};

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): Object => {
  const {
    app: { currentUser, selectedProperty },
  } = state;
  const isHeaderFormValid = isValid('AddGroupHeader')(state);
  const isHeaderFormPristine = isPristine('AddGroupHeader')(state);
  const addGroupHeaderFormValues = getFormValues('AddGroupHeader')(state);
  return {
    organizationId: currentUser.user.organizationId,
    propertyId: selectedProperty.id,
    isHeaderFormValid,
    isHeaderFormPristine,
    addGroupHeaderFormValues,
    transactionBatchHeaderId: ownProps.match.params.transactionBatchHeaderId,
    isAllCommercial: selectedProperty?.hasCommercialFloorPlans === 'ALL',
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InjectedAddGroup);
