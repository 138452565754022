import React from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import ElementWithPermissions from '../../../components/ElementWithPermissions';

type Props = {
  onStatusChange?: ?Function,
  onRecordActivity: Function,
  onScheduleActivity: Function,
  onCreateActivity: Function,
};

const ActionsMenu = ({
  onStatusChange,
  onRecordActivity,
  onScheduleActivity,
  onCreateActivity,
}: Props) => {
  return (
    <Dropdown bsSize="small" id={'action-menu-{title}'}>
      <Dropdown.Toggle>
        <i className="et-bolt" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {onStatusChange && (
          <ElementWithPermissions scope={['prospect-update']}>
            <MenuItem onSelect={onStatusChange} eventKey="1">
              <FormattedMessage {...messages.changeStatus} />
            </MenuItem>
          </ElementWithPermissions>
        )}

        <ElementWithPermissions scope={['activity-create']}>
          <MenuItem onSelect={onCreateActivity} eventKey="2">
            <FormattedMessage {...messages.createActivity} />
          </MenuItem>
        </ElementWithPermissions>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionsMenu;
