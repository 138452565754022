import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { pathOr } from 'ramda';

import {
  ELECTRONIC_SIGNING_METHOD,
  MANUAL_SIGNING_METHOD,
} from '../../../../../utils/lease-helpers';

import UnselectedMethodSaveButton from './ButtonStates/UnselectedMethodSaveButton';
import ManualMethodButtons from './ButtonStates/ManualMethodButtons';
import ElectronicMethodButtons from './ButtonStates/ElectronicMethodButtons';
import ExecutedCancelButton from './ButtonStates/ExecutedCancelButton';

type LeaseActionButtonsProps = {
  allAffordableTabsApproved: boolean,
  applicationId: ?string,
  canComplete: boolean,
  difference: number,
  handleSubmit: Function,
  hasNonFR: boolean,
  isRenewalComplete?: boolean,
  lease: Object,
  leaseSignatureStatuses: Object,
  onGenerate: Function | null,
  onSubmit: Function,
  prospectId: string,
  residentId: ?string,
  asyncValidating: boolean,
  requiredFeesUpdated: boolean,
};

export const LeaseActionButtons = ({
  allAffordableTabsApproved,
  applicationId,
  canComplete,
  difference,
  handleSubmit,
  hasNonFR,
  isRenewalComplete,
  lease,
  leaseSignatureStatuses,
  onGenerate,
  onSubmit,
  prospectId,
  residentId,
  submitting,
  asyncValidating,
  requiredFeesUpdated = true,
}: LeaseActionButtonsProps) => {
  const signatureMethod = pathOr(null, ['desiredSignatureMethod'], lease);
  const documentId = pathOr(null, ['documentId'], lease);

  const manualMethod = signatureMethod === MANUAL_SIGNING_METHOD;
  const electronicMethod = signatureMethod === ELECTRONIC_SIGNING_METHOD;
  const disable =
    !requiredFeesUpdated ||
    asyncValidating ||
    (submitting && !lease.lockedFees);
  return (
    <div className="container-fluid">
      <Row>
        <Col xs={12} md={12}>
          {!isRenewalComplete ? (
            <div className="form-footer padtop20">
              <div className="button-group">
                {lease.isLocked !== true && (
                  <UnselectedMethodSaveButton
                    canComplete={canComplete}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    disable={disable}
                  />
                )}
                {!documentId && manualMethod && lease.isLocked === true && (
                  <ManualMethodButtons
                    allAffordableTabsApproved={allAffordableTabsApproved}
                    applicationId={applicationId}
                    canComplete={canComplete}
                    difference={difference}
                    leaseId={lease.id}
                    profileId={residentId || applicationId}
                    isResident={!!residentId}
                    onGenerate={onGenerate}
                  />
                )}
                {!documentId && electronicMethod && lease.isLocked === true && (
                  <ElectronicMethodButtons
                    allAffordableTabsApproved={allAffordableTabsApproved}
                    applicationId={applicationId}
                    canComplete={canComplete}
                    difference={difference}
                    hasNonFR={hasNonFR}
                    leaseId={lease.id}
                    leaseSignatureStatuses={leaseSignatureStatuses}
                    profileId={residentId || applicationId}
                    prospectId={prospectId}
                    isResident={!!residentId}
                    onGenerate={onGenerate}
                  />
                )}
                {documentId && (
                  <ExecutedCancelButton
                    applicationId={applicationId}
                    lease={lease}
                    leaseId={lease.id}
                    profileId={residentId || applicationId}
                    prospectId={prospectId}
                    isResident={!!residentId}
                  />
                )}
              </div>
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default LeaseActionButtons;
