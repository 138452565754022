import React from 'react';
import ColumnHeader from '../../../../components/ColumnHeader';
import { ProRatedDetails } from '../ProRatedDetails';
import messages from './messages';

type Props = {
  monthlyTransactions: Array<Object>,
  moveOutDate: any,
  handleProRatedAmountChange: Function,
  moveInApplyFullAmountToProRate: boolean,
  intl: Function,
  roundProratedRents: boolean,
};

export const ProRatedAmounts = ({
  monthlyTransactions,
  moveOutDate,
  handleProRatedAmountChange,
  moveInApplyFullAmountToProRate,
  intl,
  roundProratedRents,
}: Props): any => {
  const moveOutProrateColumnLabel =
    intl.formatMessage(messages.moveOutCredit, {
      type: moveInApplyFullAmountToProRate
        ? intl.formatMessage(messages.full)
        : intl.formatMessage(messages.proRated),
    }) + (roundProratedRents ? '*' : '');
  return (
    <table className="table table-prospects table-striped">
      <thead className="table-header">
        <tr>
          <ColumnHeader
            columnLabel={intl.formatMessage(messages.transactionCode)}
          />
          <ColumnHeader
            columnLabel={intl.formatMessage(messages.description)}
          />
          <ColumnHeader
            columnLabel={intl.formatMessage(messages.transactionType)}
          />
          <ColumnHeader
            columnLabel={intl.formatMessage(messages.monthlyRecurringAmount)}
          />
          <ColumnHeader columnLabel={moveOutProrateColumnLabel} darker={true} />
        </tr>
      </thead>
      <tbody>
        {monthlyTransactions &&
          monthlyTransactions.map((monthlyTransaction, index) => {
            return (
              <ProRatedDetails
                field={`proRatedTransactions[${index}]`}
                monthlyTransaction={monthlyTransaction}
                key={index}
                moveOutDate={moveOutDate}
                handleProRatedAmountChange={handleProRatedAmountChange}
              />
            );
          })}
      </tbody>
    </table>
  );
};

export default ProRatedAmounts;
