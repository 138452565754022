// $FlowFixMe
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import moment from 'moment';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import { promptToaster, selectProperty } from '../App/actions';
import ManageLayout from '../../components/ManageLayout';
import Spinner from '../../components/Spinner';
import Table from '../../components/Table';
import Data from '../../components/Table/Data';
import Row from '../../components/Table/Row';
import SearchField from '../Fields/Search';
import FilterDrawer from '../../components/FilterDrawer';
import downloadCsv from './downloadCsv';
import type { OrderValue } from '../App/types';

import { NAME } from './constants';
import { CSV_HEADERS, FIRST_PAGE, HEADERS } from './constants';
import { useAsyncProperties, useAsyncOwners } from './hooks';
import PropertyDetailRow from './PropertyDetailRow';
import PropertyPortalModal from './PropertyPortalModal';
import { getFilters } from './utils';

import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

const ManagePropertiesLayout = styled(ManageLayout)`
  & {
    div.section-header,
    div.row.paginationFooter {
      margin-left: 2%;
      margin-right: 2%;
    }

    div.section-header {
      margin-bottom: 10px;
    }

    div.section-header,
    div.paginationFooter {
      & > :first-child {
        padding-left: 0;
      }
      & > :last-child {
        padding-right: 0;
      }
    }
  }
`;

type ConnectedProps = {
  organizationId: string,
  propertyId: string,
  userId: string,
  actions: {
    promptToaster: Function,
  },
};

const ManageProperties = ({
  intl: { formatMessage },
  organizationId,
  propertyId,
  userId,
  actions: { selectProperty },
  history,
  userProperties,
}) => {
  /**
   * State
   */
  const [modal, setModal] = useState(false);
  const [propertyInfo, setPropertyInfo] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [currentFilters, setCurrentFilters] = useState({});
  const ldClient = useLDClient();
  const { adminEnhancementsPropertiesManagegroups, contactUsWidget } =
    useFlags();

  const resetModal = () => {
    setPropertyInfo({});
    setModal(false);
  };

  const handleEditPropertyPortal = (property) => {
    setPropertyInfo(property);
    setModal(true);
  };

  const {
    meta: { count, totalCount, pageCount },
    results: properties,
    query,
    updateQuery,
  } = useAsyncProperties(organizationId, setActiveFilters, setCurrentFilters);

  const { owners } = useAsyncOwners(
    organizationId,
    promptToaster,
    formatMessage,
  );

  const FILTERS = getFilters(owners);

  const handleFilterOpen = () => {
    setShowFilter(true);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
  };

  const handleFilterApply = () => {
    updateQuery({
      ...query,
      filterValue: currentFilters,
      paginationInfo: FIRST_PAGE,
    });
    setActiveFilters(query.filterValue);
    setShowFilter(false);
  };

  const handleFilterChange =
    (field) =>
    (value) =>
    ({ target: { checked } }) => {
      setCurrentFilters({
        ...currentFilters,
        [field]: {
          ...currentFilters[field],
          [value]: checked,
        },
      });
    };

  const handleFilterClear = () => {
    setCurrentFilters({});
  };

  const hasActiveFilters = () => {
    let filters = 0;
    Object.keys(activeFilters).map((af) =>
      Object.keys(activeFilters[af]).map((av) =>
        activeFilters[af][av] === true ? (filters += 1) : false,
      ),
    );
    return filters;
  };

  const handleSubmit = ({ searchText }: Object) => {
    const { paginationInfo } = query;
    updateQuery({
      ...query,
      paginationInfo: { ...paginationInfo, pageNumber: 1 },
      searchText,
    });
  };

  const handleSort = ({ fieldName, order }: OrderValue) => {
    const sorting = { fieldName, order };
    updateQuery({ ...query, sorting });
  };

  const handlePageChange = (pageNumber: number) => {
    const {
      paginationInfo: { limit },
    } = query;
    const paginationInfo = { pageNumber, limit };
    updateQuery({ ...query, paginationInfo });
  };

  const onManagePropertyGroupsClick = () => {
    history.push('/manage-property-groups');
  };

  const updateProperties = () => updateQuery(query);

  const { pageNumber, limit } = query.paginationInfo;
  const pagination = {
    onPageChange: handlePageChange,
    currentPage: pageNumber,
    limit,
    count,
    totalCount,
    pageCount,
  };

  useEffect(() => {
    if (isNil(propertyId)) {
      ldClient.identify({
        kind: 'user',
        key: userId ?? 'fortressUser',
        organizationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DocumentTitle title={formatMessage(messages.domTitle)}>
      <ManagePropertiesLayout
        pagination={pagination}
        title={
          <div className="col-xs-12 col-md-7">
            <h1>
              <FormattedMessage {...messages.header} />
            </h1>
            <span className="small">
              <FormattedMessage {...messages.subHeader} />
            </span>
          </div>
        }
        actions={
          <div className="col-xs-12 col-md-5">
            <div className="search-actions-small">
              <button
                data-test="filter-btn"
                className={`button-filter ${
                  hasActiveFilters() ? 'filters-are-active' : ''
                }`}
                onClick={handleFilterOpen}
              >
                <i className="et-filter" />
              </button>
              <SearchField
                className="search-input-active"
                form={NAME}
                placeholder={formatMessage(messages.searchProperties)}
                onSubmit={handleSubmit}
              />
              <button
                className="btn btn-tertiary"
                style={{
                  marginTop: 0,
                  float: 'unset',
                }}
                onClick={() =>
                  downloadCsv(
                    CSV_HEADERS,
                    'Manage Properties Export',
                    `Run Date: ${moment().format('MM/DD/YYYY')}`,
                    organizationId,
                    query,
                  )
                }
              >
                <i className="et-cloud-download" />
                <span style={{ marginLeft: '8px' }}>CSV </span>
              </button>
              {adminEnhancementsPropertiesManagegroups && (
                <Button
                  variant="primarySubtle"
                  style={{
                    marginTop: -10,
                    marginLeft: 4,
                    float: 'unset',
                  }}
                  sx={{
                    backgroundColor: 'white',
                    minHeight: '40px',
                  }}
                  onClick={onManagePropertyGroupsClick}
                >
                  <FormattedMessage {...messages.managePropertyGroups} />
                </Button>
              )}
            </div>
          </div>
        }
      >
        <FilterDrawer
          filters={FILTERS}
          show={showFilter}
          onCloseClick={handleFilterClose}
          onApplyClick={handleFilterApply}
          onFilterChange={handleFilterChange}
          clearFilters={handleFilterClear}
          currentFilter={currentFilters}
          formatMessage={formatMessage}
        />
        <PropertyPortalModal
          show={modal}
          onHide={resetModal}
          property={propertyInfo}
          updateProperties={updateProperties}
        />
        <div className="table-scroll">
          <Table name={NAME} headers={HEADERS} onSort={handleSort}>
            {properties === null && (
              <Row>
                <Data colSpan={HEADERS.length}>
                  <Spinner />
                </Data>
              </Row>
            )}
            {properties && properties.length === 0 && (
              <Row>
                <Data colSpan={HEADERS.length}>
                  <FormattedMessage
                    {...messages.noDataFoundCustom}
                    values={{
                      custom: (
                        formatMessage(messages.properties) ?? ''
                      ).toLowerCase(),
                    }}
                  />
                </Data>
              </Row>
            )}
            {properties &&
              properties.length > 0 &&
              properties.map((property) => {
                return (
                  <PropertyDetailRow
                    key={property.id}
                    property={property}
                    organizationId={organizationId}
                    handleEditPropertyPortal={handleEditPropertyPortal}
                    history={history}
                    selectProperty={selectProperty}
                    userProperties={userProperties}
                    contactUsWidget={contactUsWidget}
                  />
                );
              })}
          </Table>
        </div>
      </ManagePropertiesLayout>
    </DocumentTitle>
  );
};

const mapStateToProps = ({ app }): ConnectedProps => ({
  organizationId: app.currentUser ? app.currentUser.user.organizationId : '',
  actions: {
    promptToaster,
  },
  userProperties: app.currentUser ? app.currentUser.user.properties : [],
  propertyId: app?.selectedProperty?.id,
  userId: app?.currentUser?.user?.id,
});

export const mapDispatchToProps = (dispatch: any): Object => {
  const actions = bindActionCreators(
    {
      selectProperty,
    },
    dispatch,
  );
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ManageProperties));
