import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sendForgotPasswordEmail } from '../../ForgotPassword/actions';
import { Dropdown, MenuItem } from 'react-bootstrap';
import Alert from '../../../components/Alert';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

type ConnectedMethods = {
  onResetPassword: Function,
};

type Props = ConnectedMethods & {
  user: Object,
};

type State = {
  showConfirmation: boolean,
};

export class ActionsMenu extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
      showConfirmation: false,
    };
  }

  handleShowConfirm = () => {
    this.setState({ showConfirmation: true });
  };

  handleHideConfirm = () => {
    this.setState({ showConfirmation: false });
  };

  handleResetPassword = () => {
    const { onResetPassword } = this.props;
    onResetPassword();
    this.handleHideConfirm();
  };

  render() {
    const { showConfirmation } = this.state;
    return (
      <Dropdown bsSize="small" id={'action-menu-{title}'}>
        <Alert
          message={<FormattedMessage {...messages.confirmResetPassword} />}
          onDismiss={this.handleHideConfirm}
          onProceed={this.handleResetPassword}
          show={showConfirmation}
        />
        <Dropdown.Toggle>
          <i className="et-bolt" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuItem onClick={this.handleShowConfirm} eventKey="2">
            <FormattedMessage {...messages.resetPassword} />
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

function mapDispatchToProps(
  dispatch: Object,
  { user: { username } }: Object,
): ConnectedMethods {
  return bindActionCreators(
    {
      onResetPassword: () => sendForgotPasswordEmail({ username }),
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(ActionsMenu);
