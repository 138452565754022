import { useEffect } from 'react';
import SocketService from '../services/socketService';

function useSocketOverride(
  socketEvent: string,
  eventHandler: Function,
  dependencies: any[],
) {
  useEffect(() => {
    if (SocketService && SocketService.socket) {
      if (SocketService.socket) {
        SocketService.socket.off(socketEvent);
      }
      SocketService.socket.on(socketEvent, eventHandler);
    }

    return () => {
      if (SocketService.socket) {
        SocketService.socket.off(socketEvent);
      }
    };
  }, [...dependencies, socketEvent, eventHandler]); // eslint-disable-line react-hooks/exhaustive-deps
}

export default useSocketOverride;
