import type { Action } from '../App/types';
import type { OverviewState } from './types';
import * as ActionTypes from './constants';
import { SELECT_PROPERTY } from '../App/constants';

const initialState: OverviewState = {
  income: {
    value: {
      mtdCharges: 0, // remove with addDetailsToIncomeKpis
      mtdRentCharges: 0,
      mtdOtherCharges: 0,
      mtdCredits: 0,
      mtdWriteOffs: 0,
      totalNetCharges: 0,
      mtdDeposits: 0,
      pendingDeposits: 0,
      totalPTDDeposits: 0,
      lastMonthCharges: 0,
      lastMonthDeposits: 0,
    },
    kpiLoaded: false,
  },
  occupancy: {
    value: {
      occupied: 0,
      unoccupied: 0,
    },
    kpiLoaded: false,
  },
  netOccupancy: {
    value: {
      moveIns: 0,
      moveOuts: 0,
      approved: 0,
      inProcess: 0,
      downUnits: 0,
    },
    kpiLoaded: false,
  },
  activity: {
    mtdMoveIns: 0,
    mtdMoveOuts: 0,
    kpiLoaded: false,
  },
  currentMonthRenewals: {
    value: {
      pending: 0,
      completed: 0,
      onNtv: 0,
      notStarted: 0,
    },
    kpiLoaded: false,
  },
  evictions: {
    value: {
      evictionUnits: 0,
      evictionDQBalance: 0,
      evictionDQBalancePercentage: 0,
    },
    kpiLoaded: false,
  },
  makeReadies: {
    value: {
      leased: {
        notReady: 0,
        ready: 0,
      },
      notLeased: {
        notReady: 0,
        ready: 0,
      },
    },
    kpiLoaded: false,
  },
  vacantNotLeasedByFloorPlan: {
    value: 0,
    kpiLoaded: false,
  },
  leaseExpirationExposure: {
    value: {
      monthToMonth: 0,
    },
    kpiLoaded: false,
  },
  delinquency: {
    value: {
      all: 0,
      currentResident: 0,
      ntv: 0,
      subsidy: 0,
      priorResident: 0,
      applicant: 0,
    },
    kpiLoaded: false,
  },
  concessions: {
    value: 0,
    kpiLoaded: false,
  },
  workOrders: {
    value: 0,
    kpiLoaded: false,
  },
};

const manageProspectsReducer = (
  state: OverviewState = initialState,
  action: Action<*, *>,
): OverviewState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ALL_KPIS: {
      return { ...initialState };
    }
    case ActionTypes.GET_ALL_KPIS_SUCCESS: {
      return { ...state, ...payload };
    }
    case ActionTypes.GET_KPI: {
      const { kpi } = payload;
      return {
        ...state,
        [kpi]: {
          ...state[kpi],
          kpiLoaded: false,
        },
      };
    }
    case SELECT_PROPERTY: {
      return { ...state };
    }
    case ActionTypes.CLEAN_LOADED_KPIS: {
      return initialState;
    }
    case ActionTypes.GET_KPI_SUCCESS: {
      return { ...state, ...payload, dataLoaded: true };
    }
    default:
      return state;
  }
};

export default manageProspectsReducer;
