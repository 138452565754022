// import type { OrderValue } from '../containers/App/types';
const { apiURL, requestToDownload } = require('../utils/api');

const request = require('../utils/api').default;

class FloorPlanService {
  getAll(organizationId: string, propertyId: string, requestOptions: Object) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...requestOptions,
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/floorPlans`,
      options,
    );
  }

  getAllWithPricing(
    organizationId: string,
    propertyId: string,
    options: Object,
  ) {
    const requestOptions = {
      ...options,
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/floor-plans-pricing`,
      requestOptions,
    );
  }

  uploadImage(
    floorPlanId: string,
    file: Object,
    organizationId: string,
    propertyId: string,
  ) {
    const body = new FormData();
    body.append('file', file);

    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
      body,
      fullError: true,
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/floor-plans/${floorPlanId}/image-upload`,
      options,
    );
  }

  update(
    floorPlanId: string,
    organizationId: string,
    propertyId: string,
    payload: Object,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/floor-plans/${floorPlanId}`,
      options,
    );
  }

  downloadImage(
    floorPlanId: string,
    organizationId: string,
    propertyId: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };

    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/floor-plans/${floorPlanId}/image-download`,
      options,
    );
  }

  getAllUnitsByFloorPlan(
    organizationId: string,
    propertyId: string,
    floorPlanId: string,
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/floorPlan/${floorPlanId}/units`,
      options,
    );
  }
}

export default FloorPlanService;
