import React, { Component } from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import { isNil, pick, defaultTo, pathOr, reject, either } from 'ramda';
import Loadable from 'react-loadable';
import Spinner from '../../components/GifSpinner';

import {
  getNameSuffixOptions,
  getCountryOptions,
  getStateOptions,
  getEmploymentStatusesOptions,
  getSelectedPropertyState,
  selectSelectedProperty,
} from '../App/selectors';

import * as shortLeaseApplicationActions from './actions';
import {
  getAllNameSuffixes,
  getAllCountries,
  getAllStates,
  getAllEmploymentStatuses,
  promptPrintDialog,
} from '../App/actions';
import { printApplicationForm } from '../PrimaryLeaseApplication/actions';
import { raceOptions } from '../PrimaryLeaseApplication/AffordablePrimaryForm/configuration';

import type { GlobalState, SelectOption } from '../App/types';

/* eslint-disable max-len */
import type { ShortLeaseBasicInformation } from '../../components/LeaseApplicantFormCommonSections/ShortLeaseBasicInformationSection/types';
import type { ContactInformation } from '../../components/LeaseApplicantFormCommonSections/ContactInformationSection/types';
import type { EmploymentInformation } from '../../components/LeaseApplicantFormCommonSections/EmploymentInformationSection/types';
/* eslint-enable max-len */

import { getSelectedPropertyClassType } from '../App/selectors';
import { mapApplicantToStudentStatus } from '../PrimaryLeaseApplication/selectors';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import { getIsValidProgramAndStateCode } from '../../hooks/utils/useMultiRaceSelect';

type StateProps = {
  locale: string,
  applicant?: any,
  applicantId: string,
  applicationId: string,
  suffixes: Array<SelectOption>,
  countries: Array<SelectOption>,
  states: Array<SelectOption>,
  employmentStatuses: Array<SelectOption>,
  isSubmitting: boolean,
  propertyState: string,
  propertyCity: string,
};
const LoadableShortForm = Loadable({
  loader: () => import('./ShortLeaseApplicationForm'),
  loading() {
    return <Spinner />;
  },
});

const LoadableAffordableShortForm = Loadable({
  loader: () => import('./AffordableShortLeaseApplicationForm'),
  loading() {
    return <Spinner />;
  },
});

type InjectedProps = {
  intl: any,
  history: Object,
  actions: {
    clean: () => void,
    getApplicantInformation: (string) => void,
    saveApplicantForm: (any, any) => void,
    getAllNameSuffixes: () => void,
    getAllCountries: () => void,
    getAllStates: () => void,
    getAllEmploymentStatuses: () => void,
    promptPrintDialog: () => void,
    printApplicationForm: (
      string,
      string,
      string,
      string,
      boolean,
      boolean,
      boolean,
    ) => void,
  },
  isAffordable: boolean,
  validateMultiRace: boolean,
};

export class ShortLeaseApplication extends Component<
  StateProps & InjectedProps,
> {
  componentWillMount() {
    this.props.actions.clean();
  }
  componentDidMount() {
    this.props.actions.getAllNameSuffixes();
    this.props.actions.getAllCountries();
    this.props.actions.getAllStates();
    this.props.actions.getAllEmploymentStatuses();
    this.props.actions.getApplicantInformation(this.props.applicantId);
  }
  isConvertedResident = () => {
    // $FlowFixMe
    return pathOr(false, ['applicant', 'convertedResident'], this.props);
  };
  handleCancel = () => {
    const convertedResident = this.isConvertedResident();
    // $FlowFixMe
    const applicationId = pathOr(
      '',
      ['applicant', 'applicationId'],
      this.props,
    );
    if (convertedResident) {
      this.props.history.goBack();
    } else {
      this.props.history.push(
        getUrlWithSelectedPropertyId(`/application/${applicationId}/?tab=5`),
      );
    }
  };
  mapApplicantToBasicInformation = (): ShortLeaseBasicInformation => {
    if (isNil(this.props.applicant)) {
      return {
        firstName: '',
        lastName: '',
        birthday: undefined,
        suffix: 'default',
        stateIdOrigin: 'default',
        passportCountry: 'default',
        socialSecurityNumber: '',
        stateIdNumber: '',
        veteran: '',
        race: '',
      };
    }

    const { applicantInfo } = this.props.applicant;
    const basicInfo = pick([
      'firstName',
      'middleName',
      'lastName',
      'suffix',
      'preferredName',
      'birthday',
      'alienRegistration',
      'stateIdNumber',
      'stateIdOrigin',
      'socialSecurityNumber',
      'passportNumber',
      'passportCountry',
      'race',
      'multiRace',
      'raceSource',
      'veteran',
      'gender',
    ])(applicantInfo);

    const defaultToDefault = defaultTo('default');
    return {
      ...basicInfo,
      birthday: basicInfo.birthday ? moment(basicInfo.birthday) : undefined,
      suffix: defaultToDefault(basicInfo.suffix),
      stateIdOrigin: defaultToDefault(basicInfo.stateIdOrigin),
      passportCountry: defaultToDefault(basicInfo.passportCountry),
    };
  };
  mapApplicantToContactInformation = (): ContactInformation => {
    if (isNil(this.props.applicant)) {
      return {
        phoneNumber: '',
        emailAddress: '',
      };
    }

    const { applicantInfo } = this.props.applicant;
    const contactInfo = pick(['phoneNumber', 'emailAddress'])(applicantInfo);

    return contactInfo;
  };
  mapApplicantToEmploymentInformation = (): EmploymentInformation => {
    if (isNil(this.props.applicant)) {
      return {
        status: '',
        annualIncome: 0,
      };
    }

    const { employmentInfo } = this.props.applicant;

    const { employmentStatusId, annualIncome } = employmentInfo;
    return { status: employmentStatusId, annualIncome };
  };
  mapApplicantToSignature = () => {
    if (isNil(this.props.applicant)) {
      return {
        hasSignedCopy: false,
      };
    }

    return {
      hasSignedCopy: this.props.applicant.hasSignedCopy,
    };
  };
  mapApplicantToResidentialInformation = (): Object => {
    if (isNil(this.props.applicant)) {
      return {
        street: '',
        city: '',
        state: 'default',
        zipCode: '',
      };
    }

    const currentAddress = pathOr(
      {},
      ['residentialInfo', 'currentAddress'],
      this.props.applicant,
    );

    const residentialInfo = pick(['street', 'city', 'state', 'zipCode'])(
      currentAddress,
    );

    const defaultToDefault = defaultTo('default');
    return {
      ...residentialInfo,
      state: defaultToDefault(residentialInfo.state),
    };
  };
  mapApplicantToOtherInformation = () => {
    if (isNil(this.props.applicant)) {
      return {
        convicted: null,
        convictedDescription: null,
      };
    }
    const { otherInfo } = this.props.applicant;
    return {
      convicted: isNil(otherInfo.convicted)
        ? null
        : otherInfo.convicted
        ? 'yes'
        : 'no',
      convictedDescription: otherInfo.convictedDescription,
    };
  };
  mapFormValuesToApplicant = (complete: boolean, values: any) => {
    const applicantId = this.props.applicantId;
    // $FlowFixMe
    const applicationId = pathOr(
      '',
      ['applicant', 'applicationId'],
      this.props,
    );
    const isDefault = (val) => val === 'default' || val === '';
    const isNilOrDefault = either(isNil, isDefault);

    return {
      formData: {
        hasSignedCopy: values.signature.hasSignedCopy,
        employmentInfo: reject(isNilOrDefault, values.employmentInformation),
        studentInfo: reject(isNilOrDefault, values.studentInformation),
        applicantInfo: {
          applicantId,
          applicationId,
          ...reject(isNilOrDefault, values.basicInformation),
          ...reject(isNilOrDefault, values.contactInformation),
        },
        residentialInfo: {
          currentAddress: {
            ...reject(isNilOrDefault, values.currentResidenceInformation),
          },
        },
        otherInfo: {
          convicted: isNil(values.otherInformation.convicted)
            ? null
            : values.otherInformation.convicted === 'yes',
          convictedDescription: values.otherInformation.convictedDescription,
        },
      },
      complete,
    };
  };
  // $FlowFixMe
  getPropertyName = () => pathOr('', ['applicant', 'propertyName'], this.props);
  // $FlowFixMe
  getApplicationIncomeMultiplier = () =>
    pathOr(
      'Three (3)',
      ['applicant', 'applicationIncomeMultiplier'],
      this.props,
    );

  handleSubmit = (complete: boolean, values: any) => {
    const convertedResident = this.isConvertedResident();

    const applicant = this.mapFormValuesToApplicant(complete, values);

    this.props.actions.saveApplicantForm(applicant, convertedResident);
  };

  handlePrint = () => {
    const { applicationId, applicantId, applicationAffordable, applicant } =
      this.props;
    this.props.actions.printApplicationForm(
      applicationId,
      applicantId,
      applicant.applicantInfo.firstName,
      applicant.applicantInfo.lastName,
      applicationAffordable,
      false,
    );
  };

  render() {
    if (isNil(this.props.applicant)) {
      return <div />;
    }

    const basicInformation = this.mapApplicantToBasicInformation();
    const contactInformation = this.mapApplicantToContactInformation();
    const employmentInformation = this.mapApplicantToEmploymentInformation();
    const studentInformation = mapApplicantToStudentStatus(this.props);
    const currentResidenceInformation =
      this.mapApplicantToResidentialInformation();
    const signature = this.mapApplicantToSignature();
    const otherInformation = this.mapApplicantToOtherInformation();
    const propertyName = this.getPropertyName();
    const applicationIncomeMultiplier = this.getApplicationIncomeMultiplier();

    const componentProps = {
      locale: this.props.locale,
      raceOptions: raceOptions,
      countries: this.props.countries,
      suffixes: this.props.suffixes,
      states: this.props.states,
      employmentStatuses: this.props.employmentStatuses,
      propertyName,
      applicationIncomeMultiplier,
      intl: this.props.intl,
      handleCancel: this.handleCancel,
      handlePrint: this.handlePrint,
      onSubmit: this.handleSubmit,
      initialValues: {
        basicInformation,
        contactInformation,
        currentResidenceInformation,
        employmentInformation,
        studentInformation,
        signature,
        otherInformation,
      },
      isAffordable: this.props.isAffordable,
      validateMultiRace: this.props.validateMultiRace,
      isSubmitting: this.props.isSubmitting,
      isComplete: this.props.applicant && this.props.applicant.isComplete,
      propertyState: this.props.propertyState,
      propertyCity: this.props.propertyCity,
    };
    return (
      <DocumentTitle title="Lease Application Form">
        {!this.props.isAffordable ? (
          <LoadableShortForm {...componentProps} />
        ) : (
          <LoadableAffordableShortForm {...componentProps} />
        )}
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): StateProps => {
  const { shortLeaseApplication, languageProvider } = state;
  const propertyClassType = getSelectedPropertyClassType(state);
  const property = selectSelectedProperty(state);
  // $FlowFixMe
  const applicationAffordable = pathOr(
    false,
    ['applicant', 'applicationAffordable'],
    shortLeaseApplication,
  );
  return {
    applicantId: ownProps.match.params.applicantId,
    applicationId: ownProps.match.params.applicationId,
    applicant: shortLeaseApplication.applicant,
    locale: languageProvider.locale,
    suffixes: getNameSuffixOptions(state),
    countries: getCountryOptions(state),
    states: getStateOptions(state),
    employmentStatuses: getEmploymentStatusesOptions(state),
    isAffordable:
      propertyClassType === 'Affordable' ||
      (propertyClassType === 'Mixed' && applicationAffordable),
    validateMultiRace: getIsValidProgramAndStateCode(
      property?.pap,
      property?.state?.code,
    ),
    isSubmitting: shortLeaseApplication.isSubmitting,
    propertyState: getSelectedPropertyState(state),
    propertyCity: property?.physicalCity,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...shortLeaseApplicationActions,
      getAllNameSuffixes,
      getAllCountries,
      getAllStates,
      getAllEmploymentStatuses,
      promptPrintDialog,
      printApplicationForm,
    },
    dispatch,
  );
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ShortLeaseApplication));
