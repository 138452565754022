import { defineMessages } from 'react-intl';

const messages = defineMessages({
  petsHowMany: {
    id:
      'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Pets.HowMany',
    defaultMessage: 'How Many?',
  },
  petsChooseOption: {
    id:
      'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Pets.Choose',
    defaultMessage: 'Choose an option',
  },
  other: {
    id:
      'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Pets.Other',
    defaultMessage: 'Pet(s)',
  },
  addDetail: {
    id:
      'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Pets.AddDetail',
    defaultMessage: '+ Add pet detail',
  },
  name: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Pets.Name',
    defaultMessage: 'Name',
  },
  pet: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Pets.Pet',
    defaultMessage: 'Pet*',
  },
  type: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Pets.Type',
    defaultMessage: 'Type*',
  },
  weight: {
    id:
      'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Pets.Weight',
    defaultMessage: 'Weight (lbs)',
  },
  petTag: {
    id:
      'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Pets.petTag',
    defaultMessage: 'Pet Tag',
  },
  serviceAnimal: {
    id:
      'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Pets.ServiceAnimal',
    defaultMessage: 'Service Animal?',
  },
  delete: {
    id:
      'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Pets.Delete',
    defaultMessage: 'Delete',
  },
});

export default messages;
