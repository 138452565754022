import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import {
  curryN,
  omit,
  pickBy,
  keys,
  length,
  findIndex,
  sortBy,
  propEq,
  compose,
  toLower,
  prop,
} from 'ramda';
import { Grid, Row, Col, Table, Button } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

import * as manageProspectActions from './actions';
import {
  getProspectAssignees,
  getAllProspectStatus,
  getAllActivityTypes,
} from '../App/actions';
import ProspectDetails from './ProspectDetails';
import SearchActions from './SearchActions';
import FilterDrawer from '../../components/FilterDrawer';
import ColumnHeader from '../../components/ColumnHeader';
import Status from './Status';
import ChangeStatusModal from './ChangeStatusModal';
import { createActivity } from '../../components/CreateActivityModal';
import { editCompletedActivity } from '../../components/EditCompletedActivityModal';
import { editPendingActivity } from '../../components/EditPendingActivityModal';
import ElementWithPermissions from '../../components/ElementWithPermissions';
import messages from './messages';
import type { Prospect, Activity } from '../ProspectProfile/types';
import type { StatusChangeValue } from './types';
import type {
  GlobalState,
  PaginationMeta,
  User,
  ProspectStatus,
  ActivityType,
  Property,
  FilterValue,
  OrderValue,
} from '../App/types';
import PaginationFooter from '../../components/PaginationFooter';
// $FlowFixMe
import { download } from '../../utils/downloadFile';
import ProspectProfileService from '../../services/prospectProfileService';
import { appendFilterTextToCSV } from '../../utils/csv-helpers';
import { parseCSV } from './utils';
import {
  recordActivity,
  scheduleActivity,
} from '../../components/ActivityModal';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import ActivityTypeService from '../../services/activityTypeService';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import { injectConversations } from './hooks';
import { SubmitButton } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { DownloadIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

type SearchFormValues = {
  searchText: string,
};

type StateProps = {
  prospects: Array<Prospect>,
  users: Array<User>,
  prospectStatusList: Array<ProspectStatus>,
  activityTypes: Array<ActivityType>,
  meta: PaginationMeta,
  locale: string,
  selectedProperty?: Property,
};

type Props = {
  actions: Object,
  intl: any,
  history: Object,
  columnOrder: {
    lastName: string,
    firstName: string,
    phoneNumber: string,
    emailAddress: string,
    assignedTo: string,
    nextActivity: string,
    lastActivity: string,
    creationDate: string,
    moveInDate: string,
    nBeds: string,
  },
};
type State = {
  currentPage: number,
  limit: number,
  showFilter: boolean,
  currentFilter: FilterValue,
  searchText: string,
  currentSorting: OrderValue,
};

export class ManageProspects extends Component<StateProps & Props, State> {
  constructor(props: StateProps & Props) {
    super(props);
    this.onPageChange.bind(this);
    this.state = {
      prospects: [],
      currentPage: 1,
      limit: 25,
      showFilter: false,
      searchText: '',
      currentFilter: {},
      currentSorting: {
        fieldName: '',
        order: '',
      },
    };
  }

  componentWillMount() {
    this.props.actions.cleanLoadedProspects();
    window.document.addEventListener('keydown', this._handleEscKey.bind(this));
  }

  componentDidMount() {
    this.props.actions.getProspectAssignees();
    this.props.actions.getAllProspectStatus();
    this.props.actions.clearManageProspectMessages();
    this.props.actions.getAllActivityTypes();
    this.onPageChange(this.state.currentPage);
  }

  onPageChange = (nextPage: number) => {
    let limit = 0;
    if (window.matchMedia('(min-width: 767px)').matches) {
      limit = 25;
    } else {
      limit = 10;
    }
    this.props.actions.getAllProspects(
      nextPage,
      limit,
      this.state.currentFilter,
      this.state.currentSorting,
      this.state.searchText,
    );
    this.setState({
      currentPage: nextPage,
      limit: limit,
    });
  };

  onAssigneeChange(prospect: Prospect, { target: { value } }: Object) {
    prospect.assignedToId = value;
    const prospectToUpdate = omit(
      ['createdAt', 'updatedAt', 'assignedTo', 'currentProspectStatus'],
      prospect,
    );
    this.props.actions.assignProspect(
      prospectToUpdate,
      this.state.currentPage,
      this.state.limit,
    );
  }

  onFilterChange(
    field: string,
    value: string,
    { target: { checked } }: Object,
  ) {
    const currentFilter = this.state.currentFilter;
    const filteredField = currentFilter[field] || {};
    filteredField[value] = checked;
    currentFilter[field] = filteredField;
    this.setState({
      currentFilter,
    });
  }

  toggleFilter(show: boolean) {
    this.setState({
      showFilter: show,
    });
  }

  onApplyFilterClick() {
    this.props.actions.getAllProspects(
      1,
      this.state.limit,
      this.state.currentFilter,
      this.state.currentSorting,
      this.state.searchText,
    );
    this.setState({
      currentPage: 1,
      showFilter: false,
    });
  }

  handleSearchSubmit({ searchText = '' }: SearchFormValues) {
    this.props.actions.getAllProspects(
      1,
      this.state.limit,
      this.state.currentFilter,
      {
        fieldName: '',
        order: '',
      },
      searchText,
    );
    this.setState({
      searchText,
      currentPage: 1,
      showFilter: false,
    });
  }

  onOrderClick = (field: string) => {
    const columnOrder = this.props.columnOrder[field];
    const order = columnOrder === 'ascending' ? 'DESC' : 'ASC';
    const icon =
      columnOrder === 'sortable'
        ? 'ascending'
        : columnOrder === 'ascending'
        ? 'descending'
        : 'ascending';
    this.props.actions.updateColumnsSortValue(field, icon);
    this.props.actions.getAllProspects(
      this.state.currentPage,
      this.state.limit,
      this.state.currentFilter,
      {
        fieldName: field,
        order: order,
      },
      this.state.searchText,
    );
    this.setState({
      currentSorting: {
        fieldName: field,
        order: order,
      },
    });
  };

  clearFilters() {
    this.setState({
      currentPage: 1,
      currentFilter: {},
    });
  }

  onChangeStatusClick(prospect: Prospect) {
    const statusList = this.generateStatusList();
    ChangeStatusModal(
      this.context.store,
      this.props.intl,
      statusList,
      prospect.prospectStatusId,
    ).then((data: StatusChangeValue) => {
      this.props.actions.changeProspectStatus(
        prospect,
        data.newStatus,
        data.notes,
        this.state.currentPage,
        this.state.limit,
      );
    });
  }

  async onRecordActivityClick(prospect: Prospect) {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.generateUsers();
    const data: Activity = await recordActivity(
      this.context.store,
      this.props.intl,
      prospect,
      activityTypesList,
      users,
      'prospect',
      prospect.id,
    );
    this.props.actions.createProspectActivity(
      data,
      this.state.currentPage,
      this.state.limit,
    );
  }

  async onScheduleActivityClick(prospect: Prospect) {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.generateUsers();
    const data: Activity = await scheduleActivity(
      this.context.store,
      this.props.intl,
      prospect,
      activityTypesList,
      users,
      'prospect',
      prospect.id,
    );
    this.props.actions.createProspectActivity(
      data,
      this.state.currentPage,
      this.state.limit,
    );
  }

  async onCreateActivityClick(prospect: Prospect) {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.generateUsers();
    const data: Activity = await createActivity({
      store: this.context.store,
      intl: this.props.intl,
      prospect: prospect,
      activityTypesList: activityTypesList,
      assigneeList: users,
      stage: 'prospect',
      urlId: prospect.id,
      showViewProfileBtn: true,
    });
    this.props.actions.createProspectActivity(
      data,
      this.state.currentPage,
      this.state.limit,
    );
  }

  onViewProspectClick(prospect: Prospect) {
    const id = prospect.id || '';
    navigateToUrlWithSelectedPropertyId(`/prospect/${id}`);
  }

  onEditActivity = (activity: Activity) => {
    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList(
      this.props,
    );
    const users = this.generateUsers();
    const stageInfo = {
      currentStage: 'prospect',
      urlId: activity.prospectId,
    };

    activity.activityType = this.props.activityTypes.filter(
      (at) => at.id === activity.activityTypeId,
    )[0];

    if (activity.activityType) {
      activity.completionStatus =
        activity.activityType.completionStatusOptions.filter(
          (s) => s.id === activity.activityCompletionStatusId,
        )[0];
    }

    const editPromise = activity.activityCompletionStatusId
      ? editCompletedActivity(
          this.context.store,
          this.props.intl,
          activity,
          stageInfo,
        )
      : editPendingActivity(
          this.context.store,
          this.props.intl,
          activity,
          activityTypesList,
          users,
          activity.prospect,
          stageInfo,
        );

    const editFunction = () => this.onCreateActivityClick(activity.prospect);

    editPromise.then((data: Activity) => {
      if (data.saveAndClose) {
        this.props.actions.updateProspectActivity(
          data,
          this.state.currentPage,
          this.state.limit,
        );
      } else if (data.saveAndAddNew) {
        this.props.actions.updateProspectActivity(
          data,
          this.state.currentPage,
          this.state.limit,
        );
        editFunction();
      } else {
        this.props.actions.deleteProspectActivity(
          data,
          this.state.currentPage,
          this.state.limit,
        );
      }
    });
  };
  onNewProspectClick = () => {
    navigateToUrlWithSelectedPropertyId('/prospect');
  };

  generateStatusList() {
    return this.props.prospectStatusList.map((prospectStatus) => ({
      value: prospectStatus.id,
      text:
        prospectStatus.translations[this.props.locale] || prospectStatus.name,
      color: prospectStatus.color,
      requiresNote: prospectStatus.requiresNote,
      isDefault: prospectStatus.isDefault,
    }));
  }

  generateUsers() {
    return this.props && this.props.users
      ? this.props.users.map((user) => ({
          value: user.id,
          text: `${user.firstName} ${user.lastName}`,
          disabled: false,
        }))
      : [];
  }

  generateAsignees() {
    const assignees = [];
    this.props.prospects.forEach((prospect) => {
      const assigneeIndex = findIndex(propEq('value', prospect.assignedToId))(
        assignees,
      );
      const assignee = prospect.assignedTo ? prospect.assignedTo : {};
      if (assigneeIndex < 0) {
        assignees.push({
          value: prospect.assignedToId,
          text: `${assignee.firstName} ${assignee.lastName}`,
        });
      }
    });
    return sortBy(compose(toLower, prop('text')))(assignees);
  }

  generateFilters() {
    return [
      {
        fieldName: 'prospectStatusId',
        fieldDescription: 'Status',
        options: this.generateStatusList(),
      },
      {
        fieldName: 'assignedTo',
        fieldDescription: 'Assigned to',
        options: this.generateAsignees(),
      },
    ];
  }

  hasFilter(fieldName: string) {
    const filterValue = this.state.currentFilter[fieldName] || {};
    const trueProps = pickBy((val) => val === true, filterValue);
    return length(keys(trueProps)) > 0;
  }

  hasAnyFilter() {
    const filters = this.state.currentFilter || {};
    let flattenedFilters = {};
    Object.values(filters).forEach((filter) =>
      Object.assign(flattenedFilters, filter),
    );
    const trueProps = pickBy((val) => val === true, flattenedFilters);
    return length(keys(trueProps)) > 0;
  }

  getOrder = (fieldName: string) => {
    return fieldName === this.state.currentSorting.fieldName
      ? this.state.currentSorting.order
      : '';
  };

  _handleEscKey(event: any) {
    if (event.keyCode === 27) {
      this.toggleFilter(false);
    }
  }

  async downloadCSV() {
    const { state, props } = this;
    const prospectsService = new ProspectProfileService();
    const response = await prospectsService.getAll(
      1,
      props?.meta?.totalCount ?? 100,
      state.currentFilter,
      state.currentSorting,
      state.searchText,
      // $FlowFixMe
      props.selectedProperty.id,
      // $FlowFixMe
      props.selectedProperty.organizationId,
    );

    const hasAnyFilters =
      Object.keys(state.currentFilter).length || state.searchText.length;

    const { headers, rows } = parseCSV(response);

    const csv = appendFilterTextToCSV({ headers, rows, hasAnyFilters });

    download(csv, 'ManageProspects.csv', 'text/csv;charset=utf-8');
    return true;
  }

  render() {
    if (this.props.selectedProperty.hasCommercialFloorPlans === 'ALL') {
      this.props.history.push('/404');
    }
    const users = this.generateUsers();
    const filters = this.generateFilters();
    const onAssigneeChangeCurried = curryN(2, this.onAssigneeChange.bind(this));
    const onFilterChangeCurried = curryN(3, this.onFilterChange.bind(this));
    const onOrderClickCurried = curryN(2, this.onOrderClick);
    const onChangeStatusClickCurried = curryN(
      2,
      this.onChangeStatusClick.bind(this),
    );
    const onRecordActivityClickCurried = curryN(
      2,
      this.onRecordActivityClick.bind(this),
    );
    const onScheduleActivityClickCurried = curryN(
      2,
      this.onScheduleActivityClick.bind(this),
    );
    const onCreateActivityClickCurried = curryN(
      2,
      this.onCreateActivityClick.bind(this),
    );
    const onViewProspectClickCurried = curryN(
      2,
      this.onViewProspectClick.bind(this),
    );
    const onEditActivityClickCurried = curryN(
      2,
      this.onEditActivity.bind(this),
    );
    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <Grid className="bodywrap" fluid>
          <FilterDrawer
            filters={filters}
            onFilterChange={onFilterChangeCurried}
            onCloseClick={this.toggleFilter.bind(this)}
            onApplyClick={this.onApplyFilterClick.bind(this)}
            show={this.state.showFilter}
            clearFilters={this.clearFilters.bind(this)}
            currentFilter={this.state.currentFilter}
            formatMessage={this.props.intl.formatMessage}
          />
          <Row className="section-header">
            <Col xs={5}>
              <h1>
                <FormattedMessage {...messages.header} />
              </h1>
              <div className="legend">
                <span className="legend-color legend-color--inactive" />
                <span className="small">
                  {this.props.intl.formatMessage(messages.yellowLegend)}
                </span>
              </div>
            </Col>
            <Col style={{ marginRight: '10px' }}>
              <SearchActions
                onFilterClick={this.toggleFilter.bind(this)}
                hasActiveFilters={this.hasAnyFilter()}
                placeholder={this.props.intl.formatMessage(
                  messages.searchPlaceholder,
                )}
                onSubmit={this.handleSearchSubmit.bind(this)}
                style={{ right: '246px' }}
              />
              <ElementWithPermissions scope={['prospect-create']}>
                <Button
                  onClick={this.onNewProspectClick}
                  className="btn btn-tertiary"
                >
                  <FormattedMessage {...messages.addProspect} />
                </Button>
              </ElementWithPermissions>
              <SubmitButton
                className="btn"
                sx={{ margin: '10px 4px 0 0', minWidth: 0 }}
                startIcon={<DownloadIcon />}
                onClick={() => this.downloadCSV()}
              >
                CSV
              </SubmitButton>
            </Col>
          </Row>
          <Row>
            <Col xs={12} id="table-row">
              <Table striped className="table-prospects">
                <thead className="table-header hidden-xs">
                  <tr>
                    <th>
                      <Status color="white" />
                    </th>
                    <ColumnHeader
                      columnLabel={this.props.intl.formatMessage(
                        messages.lastName,
                      )}
                      sortable={true}
                      icon={this.props.columnOrder.lastName}
                      order={this.getOrder('lastName')}
                      onOrderClick={onOrderClickCurried('lastName')}
                    />
                    <ColumnHeader
                      columnLabel={this.props.intl.formatMessage(
                        messages.firstName,
                      )}
                      sortable={true}
                      icon={this.props.columnOrder.firstName}
                      order={this.getOrder('firstName')}
                      onOrderClick={onOrderClickCurried('firstName')}
                    />
                    <ColumnHeader
                      columnLabel={this.props.intl.formatMessage(
                        messages.phoneNumber,
                      )}
                      sortable={true}
                      icon={this.props.columnOrder.phoneNumber}
                      order={this.getOrder('phoneNumber')}
                      onOrderClick={onOrderClickCurried('phoneNumber')}
                    />
                    {this.props.showTextingColumn && (
                      <ColumnHeader columnLabel="Texting" />
                    )}
                    <ColumnHeader
                      columnLabel={this.props.intl.formatMessage(
                        messages.email,
                      )}
                      sortable={true}
                      icon={this.props.columnOrder.emailAddress}
                      order={this.getOrder('emailAddress')}
                      onOrderClick={onOrderClickCurried('emailAddress')}
                    />
                    <ColumnHeader
                      columnLabel={this.props.intl.formatMessage(
                        messages.creationDate,
                      )}
                      sortable={true}
                      icon={this.props.columnOrder.creationDate}
                      order={this.getOrder('creationDate')}
                      onOrderClick={onOrderClickCurried('creationDate')}
                    />
                    <ColumnHeader
                      columnLabel={this.props.intl.formatMessage(
                        messages.lastFollowUp,
                      )}
                      sortable={true}
                      icon={this.props.columnOrder.lastActivity}
                      order={this.getOrder('lastActivity')}
                      onOrderClick={onOrderClickCurried('lastActivity')}
                    />
                    <ColumnHeader
                      columnLabel={this.props.intl.formatMessage(
                        messages.nextFollowUp,
                      )}
                      sortable={true}
                      icon={this.props.columnOrder.nextActivity}
                      order={this.getOrder('nextActivity')}
                      onOrderClick={onOrderClickCurried('nextActivity')}
                    />
                    <ColumnHeader
                      columnLabel={this.props.intl.formatMessage(
                        messages.moveInDate,
                      )}
                      sortable={true}
                      icon={this.props.columnOrder.moveInDate}
                      order={this.getOrder('moveInDate')}
                      onOrderClick={onOrderClickCurried('moveInDate')}
                    />
                    <ColumnHeader
                      columnLabel={this.props.intl.formatMessage(
                        messages.nBeds,
                      )}
                      sortable={true}
                      icon={this.props.columnOrder.nBeds}
                      order={this.getOrder('nBeds')}
                      onOrderClick={onOrderClickCurried('nBeds')}
                    />
                    <ColumnHeader
                      columnLabel={this.props.intl.formatMessage(
                        messages.assignedTo,
                      )}
                      hasFilter={this.hasFilter.bind(this)('assignedTo')}
                      sortable={true}
                      icon={this.props.columnOrder.assignedTo}
                      order={this.getOrder('assignedTo')}
                      onOrderClick={onOrderClickCurried('assignedTo')}
                    />
                    <ColumnHeader
                      columnLabel={this.props.intl.formatMessage(
                        messages.actions,
                      )}
                    />
                  </tr>
                </thead>
                <ProspectDetails
                  prospects={this.props.prospects}
                  users={users}
                  intl={this.props.intl}
                  onAssigneeChange={onAssigneeChangeCurried}
                  onStatusChange={onChangeStatusClickCurried}
                  onRecordActivity={onRecordActivityClickCurried}
                  onScheduleActivity={onScheduleActivityClickCurried}
                  onCreateActivity={onCreateActivityClickCurried}
                  onViewProspect={onViewProspectClickCurried}
                  onEditActivity={onEditActivityClickCurried}
                  propertySettingApplyWithoutAUnit={
                    this.props.flags?.propertySettingApplyWithoutAUnit
                  }
                  showTextingColumn={this.props.showTextingColumn}
                  householdConversationsStatusDictionary={
                    this.props.householdConversationsStatusDictionary
                  }
                />
              </Table>
            </Col>
          </Row>
          {this.props.meta.pageCount > 1 && (
            <PaginationFooter
              currentPage={this.state.currentPage}
              limit={this.state.limit}
              count={this.props.meta.count}
              totalCount={this.props.meta.totalCount}
              pageCount={this.props.meta.pageCount}
              onPageChange={this.onPageChange}
            />
          )}
        </Grid>
      </DocumentTitle>
    );
  }
}

ManageProspects.contextTypes = {
  store: PropTypes.any,
};

export const mapStateToProps = ({
  app,
  manageProspects,
  languageProvider,
}: GlobalState): StateProps => {
  return {
    prospects: manageProspects.prospects,
    users: app.prospectAssignees,
    prospectStatusList: app.prospectStatusList,
    activityTypes: app.activityTypes,
    selectedProperty: app.selectedProperty,
    meta: manageProspects.meta,
    locale: languageProvider.locale,
    columnOrder: manageProspects.columnOrder,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...manageProspectActions,
      getProspectAssignees,
      getAllProspectStatus,
      getAllActivityTypes,
    },
    dispatch,
  );
  return { actions };
}

const InjectedManageProspects = injectIntl(ManageProspects);

export default withLDConsumer()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(injectConversations(InjectedManageProspects)),
);
