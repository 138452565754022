const normalizeProRatedAmount = (value: any, previousValue: any) => {
  if (!value) {
    return previousValue;
  }
  const validFieldRegex = new RegExp(/^\d*\.?\d*$/);
  if (validFieldRegex.test(value) === true) {
    return value;
  }
};
export default normalizeProRatedAmount;
