import type { OrganizationId } from '../containers/App/types';
import { get } from '../utils/api';

export default class ResidentPetService {
  getResidentPetsForHousehold(
    organizationId: OrganizationId,
    propertyId: string,
    householdId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/households/${householdId}/residents/pets`,
      options,
    );
  }
}
