import React from 'react';
import { Field } from 'redux-form';

import {
  renderSelectField,
  renderTextField,
  renderFlexNumberField,
  renderTextAreaField,
} from '../../../../../../utils/redux-form-helper';

import {
  OFFER_TYPE_SELECT_OPTIONS,
  DAYS_MONTHS_SELECT_OPTIONS,
} from '../../constants';

import { FlexFormInput, FlexFormInputHalf } from '../../common';

const RightOfFirstRefusalOptionsFormFields = ({ values }: Object) => {
  return (
    <React.Fragment>
      <FlexFormInput>
        <Field
          name="Right of First Refusal Type"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Right of First Refusal Type"
          options={OFFER_TYPE_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Notice Period Type"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Notice Period Type"
          options={DAYS_MONTHS_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Notice Period"
          component={renderFlexNumberField}
          label="Notice Period"
          max="1000"
          min="0"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Response Deadline Type"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Response Deadline Type"
          options={DAYS_MONTHS_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Response Deadline"
          component={renderFlexNumberField}
          label="Response Deadline"
          className="input form-control"
          max="1000"
          min="0"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInputHalf>
        <Field
          name="Details"
          component={renderTextAreaField}
          label="Details"
          rows="2"
          maxLength="1000"
          showLabel
        />
      </FlexFormInputHalf>
      <FlexFormInputHalf>
        <Field
          name="Additional Notices"
          component={renderTextAreaField}
          label="Additional Notices"
          rows="2"
          maxLength="1000"
          showLabel
        />
      </FlexFormInputHalf>
      <FlexFormInputHalf>
        <Field
          name="Termination of Rights"
          component={renderTextField}
          label="Termination of Rights"
          maxLength="255"
          showLabel
        />
      </FlexFormInputHalf>
      <FlexFormInputHalf>
        <Field
          name="Property Desired"
          component={renderTextField}
          label="Property Desired"
          maxLength="255"
          showLabel
        />
      </FlexFormInputHalf>
    </React.Fragment>
  );
};

export default RightOfFirstRefusalOptionsFormFields;
