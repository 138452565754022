import messages from './messages';
import { isEmpty, isNil, is } from 'ramda';

export const validate = (values: Object, props: Object) => {
  const { intl } = props;
  const errors = {};
  if (
    values.isAlarmActive === 'yes' &&
    (isEmpty(values.alarmCode) ||
      isNil(values.alarmCode) ||
      (is(String, values.alarmCode) && isEmpty(values.alarmCode.trim())))
  ) {
    errors.alarmCode = intl.formatMessage(messages.required);
  }
  if (
    values.isDoorCodeActive === 'yes' &&
    (isEmpty(values.doorCode) ||
      isNil(values.doorCode) ||
      (is(String, values.doorCode) && isEmpty(values.doorCode.trim())))
  ) {
    errors.doorCode = intl.formatMessage(messages.required);
  }
  return errors;
};

export default validate;
