import { useQuery, useQueryClient } from 'react-query';
import LeaseService from '../../../services/leaseService';

export const useAsyncMonthlyLeaseTransactions = (leaseId) => {
  const queryClient = useQueryClient();
  const leaseService = new LeaseService();

  const fetchLeaseMonthlyTransactions = async ({ queryKey }) => {
    const [, id] = queryKey;
    if (!id) return [];

    const abortController = new AbortController();
    const options = { signal: abortController.signal };

    try {
      return await leaseService.getMonthlyLeaseTransactionsForResident(
        id,
        options,
      );
    } catch (error) {
      if (error.name === 'AbortError') {
        console.error('Request was aborted');
      }
      throw error;
    }
  };

  const { data: allMonthlyTransactions = [] } = useQuery(
    ['monthlyLeaseTransactions', leaseId],
    fetchLeaseMonthlyTransactions,
    {
      enabled: !!leaseId,
      refetchOnWindowFocus: false,
    },
  );

  const updateTransactionsList = () => {
    queryClient.invalidateQueries(['monthlyLeaseTransactions', leaseId]);
  };

  return {
    allMonthlyTransactions,
    updateTransactionsList,
  };
};
