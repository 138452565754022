export const hasDuplicates = (
  arrayToCheck: Array = [],
  valueToCheck?: any,
  elementProperty?: string,
): boolean => {
  const seenSet = new Set();
  return arrayToCheck.some((element): boolean => {
    const valueToCompare = element?.[elementProperty] ?? element;
    const specificValueToCheck = valueToCheck ?? valueToCompare;
    if (specificValueToCheck !== valueToCompare) {
      return false;
    }
    const valueWasSeen = seenSet.has(valueToCompare);
    seenSet.add(valueToCompare);
    return valueWasSeen;
  });
};

export default {
  hasDuplicates,
};
