import { useState, useEffect } from 'react';
import CommercialLeaseTypesService from '../../../../services/commercialLeaseTypesService';
import CommercialRetailTypesService from '../../../../services/commercialRetailTypesService';
import ResidentService from '../../../../services/residentService';
import LeaseService from '../../../../services/leaseService';

const DEFAULT_OPTION = { value: 'default', text: 'Choose', disabled: true };

export function useLeaseTypeOptions(organizationId: string) {
  const [leaseTypeOptions, setLeaseTypeOptions] = useState([DEFAULT_OPTION]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);

  useEffect(() => {
    if (optionsLoading || optionsLoaded) return;

    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const service = new CommercialLeaseTypesService();

    async function fetchLeaseTypes() {
      const leaseTypes = await service.getAll(organizationId, {
        signal: abortController.signal,
      });

      setLeaseTypeOptions(
        [DEFAULT_OPTION].concat(
          leaseTypes.map((lt) => ({
            value: lt.id,
            text: lt.name,
            disabled: false,
          })),
        ),
      );
      setOptionsLoaded(true);
      setOptionsLoading(false);
    }

    fetchLeaseTypes();
    setOptionsLoading(true);
  }, [optionsLoaded, optionsLoading, organizationId]);

  return [leaseTypeOptions, optionsLoaded];
}

export function useRetailTypeOptions(organizationId: string) {
  const [retailTypeOptions, setRetailTypeOptions] = useState([DEFAULT_OPTION]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(false);

  useEffect(() => {
    if (optionsLoading || optionsLoaded) return;
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const service = new CommercialRetailTypesService();

    async function fetchRetailTypes() {
      const retailTypes = await service.getAll(organizationId, {
        signal: abortController.signal,
      });

      setRetailTypeOptions(
        [DEFAULT_OPTION].concat(
          retailTypes.map((lt) => ({
            value: lt.id,
            text: lt.name,
            disabled: false,
          })),
        ),
      );
      setOptionsLoaded(true);
      setOptionsLoading(false);
    }

    fetchRetailTypes();
    setOptionsLoading(true);
  }, [optionsLoaded, optionsLoading, organizationId]);

  return [retailTypeOptions, optionsLoaded];
}
export const useAsyncStartLeaseRenewal = ({
  previousLeaseId,
  leaseData,
  refreshLeases,
  toasterFn,
}: Object) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleStartLeaseRenewal = async () => {
    setIsSubmitting(true);

    try {
      const residentService = new ResidentService();
      const response = await residentService.renewLease(
        previousLeaseId,
        leaseData,
      );
      setIsSubmitting(false);
      toasterFn({
        message: 'Started Renewal Lease',
        title: 'Success',
      });
      refreshLeases();

      if (response.error) {
        toasterFn({
          type: 'error',
          message: `Error starting renewal lease: ${response.error}`,
          title: 'Error',
        });
      }
      return true;
    } catch (e) {
      setIsSubmitting(false);
      toasterFn({
        type: 'error',
        message: `Error starting renewal lease: ${e}`,
        title: 'Error',
      });
      return true;
    }
  };

  return {
    isSubmittingStartRenewal: isSubmitting,
    handleStartLeaseRenewal: handleStartLeaseRenewal,
  };
};

export const useAsyncCancelLeaseRenewal = ({
  leaseId,
  refreshLeases,
  toasterFn,
}: Object) => {
  const handleCancelLeaseRenewal = async () => {
    try {
      const residentService = new ResidentService();
      await residentService.removeLease(leaseId);
      toasterFn({
        message: 'Removed Renewal Lease',
        title: 'Success',
      });
      refreshLeases();
      return true;
    } catch (e) {
      toasterFn({
        type: 'error',
        message: `Error removing renewal lease: ${e}`,
        title: 'Error',
      });
      return true;
    }
  };

  return {
    handleCancelLeaseRenewal: handleCancelLeaseRenewal,
  };
};

export const useAsyncGenerateLeaseAbstract = (toasterFn: Function) => {
  const [isLoading, setIsLoading] = useState(false);

  const generateLeaseAbstract = async (
    organizationId: string,
    propertyId: string,
    leaseId: string,
  ) => {
    if (isLoading) return;

    const service = new LeaseService();

    try {
      setIsLoading(true);
      await service.generateCommercialLeaseAbstract(
        organizationId,
        propertyId,
        leaseId,
        'lease-abstract',
      );
    } catch (err) {
      toasterFn({
        type: 'error',
        message: 'Failed to generate abstract.',
        title: 'Error',
      });
    }

    setIsLoading(false);
  };

  return [generateLeaseAbstract, isLoading];
};
