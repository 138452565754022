import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { omit, propEq } from 'ramda';
import type { Selection } from './types';
import type { Option } from '../../types';
import type { PaymentSingleTransaction } from '../../services/paymentSingleTransactionService';
import type { UndepositedPaymentRow } from '../../components/UndepositedPaymentsTable/types';
import ManageUndepositedPayments from '../../components/ManageUndepositedPayments';
import confirm from '../../components/ConfirmDialogModal';
import {
  getSelectedPropertyBankAccounts,
  getSelectedPropertyFiscalPeriods,
} from '../App/actions';
import { getSelectedPropertyName } from '../App/selectors';
import {
  getUndepositedPaymentBatches,
  getUndepositedPayments,
  selectForDeposit,
  unselectForDeposit,
  createDeposit,
  clickViewPaymentBatch,
  clearState,
  clearSelections,
} from './actions';
import * as selectors from './selectors';
import { viewCustomerTransaction, viewMiscTransaction } from './modals';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type Props = {
  rows: UndepositedPaymentRow[],
  bankAccountOptions: Option[],
  undepositedPayments: PaymentSingleTransaction[],
  period: string,
  dataIsReady: boolean,
  disableCancel: boolean,
  disableSubmit: boolean,
  enablePrompt: boolean,
  propertyName: string,
  selectedBankAccount: string,
  clearSelections: Function,
  push: Function,
  clearState: Function,
  clickViewPaymentBatch: Function,
  createDeposit: Function,
  selectForDeposit: Function,
  unselectForDeposit: Function,
  getSelectedPropertyBankAccounts: Function,
  getUndepositedPaymentBatches: Function,
  getUndepositedPayments: Function,
  getSelectedPropertyFiscalPeriods: Function,
} & { intl: Object, store: any, location: any };

const removeIdPrefix = (id: string) => {
  const indexOfDash = id.indexOf('-');
  if (indexOfDash < 0) return id;
  return id.substr(indexOfDash + 1);
};

class ManageUndepositedPaymentsContainer extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.clearState();
    this.props.getSelectedPropertyBankAccounts();
    this.props.getSelectedPropertyFiscalPeriods();
    this.props.getUndepositedPaymentBatches();
    this.props.getUndepositedPayments();
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.selectedBankAccount !== this.props.selectedBankAccount)
      this.props.clearSelections();
  }
  onViewPaymentBatch = (id: string) => {
    this.props.clickViewPaymentBatch(removeIdPrefix(id));
    const url = getUrlWithSelectedPropertyId(
      `/payment-batch/${removeIdPrefix(id)}`,
    );
    var win = window.open(url, '_blank');
    win.focus();
  };
  onViewSinglePayment = (id: string) => {
    const payment = this.props.undepositedPayments.find(
      propEq('id', removeIdPrefix(id)),
    );
    if (!payment) return;
    if (payment.paymentType === 'Ops' || payment.paymentType === 'Security') {
      viewCustomerTransaction(
        payment,
        payment.paymentType === 'Ops'
          ? payment.customerOpsLedger
          : payment.customerSecurityLedger,
        this.props.propertyName,
        this.props.intl,
        this.props.store,
      );
    } else if (payment.paymentType === 'Misc')
      viewMiscTransaction(
        payment,
        payment.propertyMiscIncomeLedger,
        this.props.intl,
      );
  };
  onCancel = () =>
    confirm('Are you sure you want to leave without depositing?').then(() =>
      this.props.push('/'),
    );
  selectForDeposit = (selection: Selection) =>
    this.props.selectForDeposit({
      id: removeIdPrefix(selection.id),
      isBatch: selection.isBatch,
    });
  unselectForDeposit = (selection: Selection) =>
    this.props.unselectForDeposit({
      id: removeIdPrefix(selection.id),
      isBatch: selection.isBatch,
    });
  onSubmit = () => {
    confirm('Are you sure you want to create this deposit?').then(() =>
      this.props.createDeposit(),
    );
  };
  render() {
    const props = omit(
      [
        'clearState',
        'undepositedPayments',
        'clickViewPaymentBatch',
        'propertyName',
        'createDeposit',
        'dataIsReady',
        'push',
        'selectForDeposit',
        'selectedBankAccount',
        'clearSelections',
        'unselectForDeposit',
        'getSelectedPropertyBankAccounts',
        'getUndepositedPaymentBatches',
        'getUndepositedPayments',
        'getSelectedPropertyFiscalPeriods',
      ],
      this.props,
    );
    return this.props.dataIsReady ? (
      <ManageUndepositedPayments
        {...props}
        batchDate={moment().format('MM/DD/YYYY')}
        selectForDeposit={this.selectForDeposit}
        unselectForDeposit={this.unselectForDeposit}
        onViewPaymentBatch={this.onViewPaymentBatch}
        onViewSinglePayment={this.onViewSinglePayment}
        onSubmit={this.onSubmit}
        onCancel={this.onCancel}
      />
    ) : null;
  }
}

ManageUndepositedPaymentsContainer.contextTypes = {
  store: PropTypes.any,
};

export default connect(
  createStructuredSelector({
    rows: selectors.getUndepositedPaymentRows,
    bankAccountOptions: selectors.getBankAccountOptions,
    period: selectors.getFiscalPeriodText,
    dataIsReady: selectors.dataIsReady,
    disableCancel: selectors.disableCancel,
    disableSubmit: selectors.disableSubmit,
    undepositedPayments: selectors.getUndepositedPayments,
    propertyName: getSelectedPropertyName,
    enablePrompt: selectors.enablePrompt,
    selectedBankAccount: selectors.getSelectedBankAccount,
  }),
  {
    getSelectedPropertyBankAccounts,
    getUndepositedPaymentBatches,
    getUndepositedPayments,
    getSelectedPropertyFiscalPeriods,
    push,
    selectForDeposit,
    unselectForDeposit,
    createDeposit,
    clickViewPaymentBatch,
    clearState,
    clearSelections,
  },
)(injectIntl(ManageUndepositedPaymentsContainer));
