import React from 'react';
import { pathOr } from 'ramda';
import FormatPhone from '../../../components/FormatPhone';
import Row from '../../../components/Table/Row';
import Data from '../../../components/Table/Data';
import {
  determinePortalStatus,
  determinedPortalActiveColumn,
  determineLinkColumn,
  getStreetAddress,
} from '../utils';

const PropertyDetailRow = ({
  property,
  organizationId,
  showHide,
  handleEditPropertyPortal,
  history,
  selectProperty,
  userProperties,
  contactUsWidget,
}: Object) => {
  const portalStatus = determinePortalStatus(property);
  const portalActiveColumnValue = determinedPortalActiveColumn(
    { ...property, portalStatus },
    handleEditPropertyPortal,
  );
  const orgPropUrl = `${organizationId}/${property.id}`;
  const linkColumnValue = determineLinkColumn(
    portalStatus,
    orgPropUrl,
    contactUsWidget,
  );
  const streetAddress = getStreetAddress(
    property.physicalStreetAddress1,
    property.physicalStreetAddress2,
  );

  const periodName = property.pfp.periodName;
  const periodColumnValue = property.pfp.isClosePending
    ? `${periodName} (Closing Started)`
    : periodName;

  const handlePropertyClick = (propertyId: string) => {
    history.push(`/properties/${propertyId}`);
  };

  const gpr = pathOr(
    '---',
    ['propertyReportConfiguration', '0', 'config1Value'],
    property,
  );

  return (
    <Row className="table-row">
      <Data>{property.propertyStatus.description}</Data>
      <Data>
        <a onClick={() => handlePropertyClick(property.id)}>{property.name}</a>
      </Data>
      <Data>{property.shortName}</Data>
      <Data>{pathOr('---', ['propertyOwner', 'name'], property)}</Data>
      <Data>{property.propertyClass.propertyType}</Data>
      <Data>
        <div>{streetAddress}</div>
        <div>{`${property.physicalCity}, ${property.state.code} ${property.physicalZip}`}</div>
      </Data>
      <Data>
        <FormatPhone phoneNumber={property.phoneNumber} />
      </Data>
      <Data>
        <a href={`mailto:${property.propertyEmail}`}>
          {property.propertyEmail}
        </a>
      </Data>
      <Data>{periodColumnValue}</Data>
      <Data>{property.autoLateFeesPostingActive}</Data>
      <Data>
        {property.leaseRentPercentage &&
          // Some database entries contain '%' symbols
          property.leaseRentPercentage.replace('%', '') + '%'}
      </Data>
      <Data>{gpr}</Data>
      <Data>{portalActiveColumnValue}</Data>
      <Data>{linkColumnValue}</Data>
    </Row>
  );
};

export default PropertyDetailRow;
