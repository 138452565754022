const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class UnitSqFtService {
  getRentableHistory({ organizationId, propertyId, unitId, options }: Object) {
    const opts = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/units/${unitId}/rentable-square-feet`,
      opts,
    );
  }

  createRentableSqFt({ organizationId, propertyId, unitId, payload }: Object) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/units/${unitId}/rentable-square-feet`,
      options,
    );
  }

  updateRentableSqFt({ organizationId, propertyId, unitId, payload }: Object) {
    const id = payload.id;
    delete payload.id;
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/units/${unitId}/rentable-square-feet/${id}`,
      options,
    );
  }

  deleteRentableSqFt({ organizationId, propertyId, unitId, id }: Object) {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/units/${unitId}/rentable-square-feet/${id}`,
      options,
    );
  }

  getUsableHistory({ organizationId, propertyId, unitId, options }: Object) {
    const opts = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/units/${unitId}/usable-square-feet`,
      opts,
    );
  }

  createUsableSqFt({ organizationId, propertyId, unitId, payload }: Object) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/units/${unitId}/usable-square-feet`,
      options,
    );
  }

  updateUsableSqFt({ organizationId, propertyId, unitId, payload }: Object) {
    const id = payload.id;
    delete payload.id;
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/units/${unitId}/usable-square-feet/${id}`,
      options,
    );
  }

  deleteUsableSqFt({ organizationId, propertyId, unitId, id }: Object) {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/units/${unitId}/usable-square-feet/${id}`,
      options,
    );
  }
}

export default UnitSqFtService;
