// import { UnitHistory } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import UnitHistory from './UnitHistory';
import { useUnitHistory, useHouseholdUnitHistory } from './hooks';

function UnitHistoryContainer({
  intl,
  unitId,
  floorPlanId,
  history,
  propertyId,
}) {
  const props = useUnitHistory({ floorPlanId, unitId, history, propertyId });
  const { rows, isLoading, isError, onResidentClick } = useHouseholdUnitHistory(
    {
      unitId,
      propertyId,
      history,
    },
  );
  const householdUnitHistoryProps = {
    rows,
    isLoading,
    isError,
    onResidentClick,
  };
  return (
    <UnitHistory
      {...props}
      intl={intl}
      householdUnitHistoryProps={householdUnitHistoryProps}
    />
  );
}

export default UnitHistoryContainer;
