import React from 'react';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import { getFormValues, reduxForm, isPristine, isValid } from 'redux-form';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import styled from 'styled-components';

import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import AddInlineRow from './AddInlineRow';
import validate from './validate';
import ProrateCalculatorDetailsTableFooter from './ProrateCalculatorDetailsTableFooter';
import { getPropertyTransactionCodesOptions } from '../../CreateTransactionBatch/CreateTransactionBatchDetails/utils';
import { calculateProrateAmount } from './utils';
import { transactionCodesProvider } from '../../../utils/transaction-helpers';

const StyledForm = styled.form`
  .form-group {
    margin-bottom: 0;
  }
`;

type Props = {
  formValues: Object,
  propertyTransactionCodes: Object,
  transactionTypes: Object,
  isLoading: boolean,
  headerFormValues: Object,
  change: Function,
  counter: number,
  setCounter: Function,
  inlineRowsArray: Array<Object>,
  setInlineRowsArray: Function,
  defaultRowId: string,
};

export const ProrateCalculatorDetails = (props: Props) => {
  const {
    propertyTransactionCodes,
    transactionTypes,
    isLoading,
    change,
    headerFormValues,
    formValues,
    counter,
    setCounter,
    inlineRowsArray,
    setInlineRowsArray,
    defaultRowId,
  } = props;

  const deleteRow = (id: string) => {
    const newArray = inlineRowsArray.filter((row) => row.id !== id);
    setInlineRowsArray(newArray);
    change(`inline-${id}`, null);
  };

  const addRow = () => {
    const newRow = {
      id: `${defaultRowId}-${counter + 1}`,
      key: counter + 1,
      balance: 0,
    };
    setCounter(counter + 1);
    setInlineRowsArray([...inlineRowsArray, newRow]);
  };

  const summaryRow = {
    totalProrateDue: (0).toFixed(2),
  };

  // filter resident transaction codes
  const residentTransactionCodes = transactionCodesProvider(
    'Resident',
    propertyTransactionCodes,
  );

  const getRowsWithBalances = () => {
    const rv = inlineRowsArray.reduce(
      (acc, row) => {
        const emptyOption = [{ value: '', text: 'Choose', disabled: true }];
        const rowFormValues = formValues?.[`inline-${row.id}`];
        if (!rowFormValues) {
          const newRowValue = {
            ...row,
            balance: null,
            newBalance: null,
            initialSubjournalOptions: emptyOption,
            initialTransactionCodeOptions: emptyOption,
          };
          return {
            allRows: [...acc.allRows, newRowValue],
            balances: acc.balances,
          };
        }

        const rowAmountStr = rowFormValues?.amount;
        const rowAmount = +(rowAmountStr ?? 0);
        const rowTransactionTypeId = rowFormValues.transactionType;
        const rowTransactionTypeObj = isNil(rowTransactionTypeId)
          ? null
          : transactionTypes.find((t) => t.id === rowTransactionTypeId);
        const rowTransactionType = rowTransactionTypeObj?.name ?? null;
        const addedAmount =
          isNil(rowAmount) || isNil(rowTransactionType)
            ? 0
            : rowTransactionType === 'Credit'
            ? -1 * rowAmount
            : rowAmount;

        const prorateAmount = rowFormValues?.doNotProrate
          ? addedAmount
          : calculateProrateAmount({
              amount: addedAmount,
              date: headerFormValues?.moveDate,
              computeForMoveIn: headerFormValues?.moveInMoveOut === 'moveIn',
            });
        summaryRow.totalProrateDue = (
          +summaryRow.totalProrateDue + +prorateAmount
        ).toFixed(2);

        formValues[`inline-${row.id}`].prorateAmount = (
          +prorateAmount ?? 0
        ).toFixed(2);

        const txCodeOptions = getPropertyTransactionCodesOptions(
          residentTransactionCodes.filter(
            (code) =>
              rowTransactionTypeId ===
              (code?.transactionCode?.transactionTypeId ?? ''),
          ),
        );
        const initialTransactionCodeOptions = !rowTransactionTypeId
          ? [{ value: '', text: 'Choose', disabled: true }]
          : txCodeOptions;
        const rowTransactionCodeId = rowFormValues.transactionCode;
        const rowTransactionCodeObj = isNil(rowTransactionCodeId)
          ? null
          : initialTransactionCodeOptions.find(
              (t) => t.value === rowTransactionCodeId,
            );
        const rowTransactionCode = rowTransactionCodeObj?.text ?? null;

        const newRowValue = {
          ...row,
          prorateAmount,
          initialTransactionCodeOptions,
          amount: rowAmountStr,
          transactionType: rowTransactionType,
          transactionCode: rowTransactionCode,
        };
        return {
          allRows: [...acc.allRows, newRowValue],
          balances: acc.balances,
        };
      },
      { allRows: [], balances: {} },
    );

    return rv.allRows;
  };

  const dynamicHeight = !isLoading
    ? inlineRowsArray?.length > 1
      ? 125 + 60 * inlineRowsArray?.length
      : 170
    : 200;

  return (
    <React.Fragment>
      <div className="container-fluid" style={{ height: `${dynamicHeight}px` }}>
        <StyledForm>
          <div className="table-deposits-container">
            <table className="table table-prospects table-striped">
              <thead className="table-header">
                <tr>
                  <th width="15%">
                    <FormattedMessage {...messages.transactionType} />
                  </th>
                  <th width="20%">
                    <FormattedMessage {...messages.transactionCode} />
                  </th>
                  <th width="15%">
                    <FormattedMessage {...messages.enterTotalAmount} />
                  </th>
                  <th width="20%">
                    <FormattedMessage {...messages.note} />
                  </th>
                  <th width="15%">
                    <FormattedMessage {...messages.prorateAmount} />
                  </th>
                  <th width="10%">
                    <FormattedMessage {...messages.doNotProrate} />
                  </th>
                  <th width="5%">
                    <FormattedMessage {...messages.delete} />
                  </th>
                </tr>
              </thead>
              <tbody data-test="details-table">
                {isLoading ? (
                  <tr>
                    <td colSpan={8}>
                      <Spinner />
                    </td>
                  </tr>
                ) : (
                  getRowsWithBalances().map((row, i, a) => (
                    <AddInlineRow
                      key={row.key}
                      id={row.id}
                      deleteRow={deleteRow}
                      propertyTransactionCodes={propertyTransactionCodes}
                      transactionTypes={transactionTypes}
                      change={change}
                      prorateAmount={row.prorateAmount}
                      residentTransactionCodes={residentTransactionCodes}
                      initialTransactionCodeOptions={
                        row.initialTransactionCodeOptions
                      }
                      amount={row.amount}
                      transactionType={row.transactionType}
                      transactionCode={row.transactionCode}
                      isDeleteDisabled={a.length === 1}
                    />
                  ))
                )}
              </tbody>
              <tfoot className="table-footer">
                <ProrateCalculatorDetailsTableFooter
                  addRow={addRow}
                  totalProrateDue={summaryRow.totalProrateDue}
                />
              </tfoot>
            </table>
          </div>
        </StyledForm>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: GlobalState): Object => {
  const formValues = getFormValues('prorateCalculatorForm')(state);
  const headerFormValues = getFormValues('ProrateCalculatorHeaderForm')(state);

  const isHeaderFormPristine = isPristine('ProrateCalculatorHeaderForm')(state);
  const isHeaderFormValid = isValid('ProrateCalculatorHeaderForm')(state);
  return {
    formValues,
    headerFormValues,
    isHeaderFormPristine,
    isHeaderFormValid,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'prorateCalculatorForm',
    enableReinitialize: true,
    validate,
  })(ProrateCalculatorDetails),
);
