import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import * as constants from '../constants';
import RecommendBasedAvgRating from './RecommendBasedAvgRating';
import StarBasedAvgRating from './StarBasedAvgRating';
import useFormatMessage from '../../../hooks/useFormatMessage';

type Props = {
  ratingType: string,
  avgRating?: number,
  reviewCount?: number,
  sourceCount?: number,
  ratingCount?: number,
  recommendedPercent?: number,
};

export const OverallAvgRatingSummaryColumn = ({
  ratingType,
  avgRating,
  reviewCount,
  sourceCount,
  ratingCount,
  recommendedPercent,
}: Props) => {
  const formatMessage = useFormatMessage();

  return (
    <Stack spacing={1} alignItems="center">
      {ratingType === constants.RECOMMEND_BASED ? (
        <RecommendBasedAvgRating
          formatMessage={formatMessage}
          recommendedPercent={recommendedPercent}
          reviewCount={reviewCount}
          sourceCount={sourceCount}
        />
      ) : (
        <StarBasedAvgRating
          formatMessage={formatMessage}
          avgRating={avgRating}
          ratingCount={ratingCount}
          reviewCount={reviewCount}
          sourceCount={sourceCount}
        />
      )}
    </Stack>
  );
};
