import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import { FORM_NAME } from './constants';
import validate from './validate';
import {
  phoneFormatter,
  phoneParser,
  renderPhoneField,
  renderSelectField,
  renderTextField,
  validateName,
  validateNonRequiredName,
  validateNonRequiredAddress,
} from '../../../../utils/redux-form-helper';
import { useStates } from '../../../Tenant/ContactModal/hooks';
import Spinner from '../../../../components/Spinner';
import messages from './messages';
import type { AddEditEmergencyContactFormProps } from './types';

const FormGrid = styled.div`
  column-gap: 15px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(3, 1fr);
  padding: 10px;
  row-gap: 20px;
`;

const GridItem = styled.div`
  ${({ columnStart, columnEnd, rowStart, rowEnd, justifySelf, alignSelf }) => `
    grid-column-start: ${columnStart};
    grid-column-end: ${columnEnd};
    grid-row-start: ${rowStart};
    grid-row-end: ${rowEnd};
    ${justifySelf ? `justify-self: ${justifySelf};` : ''}
    ${alignSelf ? `align-self: ${alignSelf};` : ''}
  `}
`;

const AddEditEmergencyContactForm = ({
  handleSubmit,
  onSubmit,
}: AddEditEmergencyContactFormProps) => {
  const [states, statesLoaded] = useStates();

  if (!statesLoaded) return <Spinner />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormGrid>
        <GridItem columnStart={1} columnEnd={4} rowStart={1} rowEnd={1}>
          <Field
            name="contactName"
            component={renderTextField}
            label={<FormattedMessage {...messages.contactNameField} />}
            className="input form-control"
            placeholder="Enter Name"
            maxLength={35}
            showLabel
            validate={[validateName]}
          />
        </GridItem>

        <GridItem columnStart={4} columnEnd={8} rowStart={1} rowEnd={1}>
          <Field
            name="relationship"
            component={renderTextField}
            label={<FormattedMessage {...messages.relationshipField} />}
            className="input form-control"
            maxLength={35}
            showLabel
            validate={[validateName]}
          />
        </GridItem>

        <GridItem columnStart={1} columnEnd={3} rowStart={2} rowEnd={2}>
          <Field
            name="phoneNumber"
            component={renderPhoneField}
            label={<FormattedMessage {...messages.phoneNumberField} />}
            className="input form-control"
            placeholder="(xxx) xxx-xxxx"
            parse={phoneParser}
            format={phoneFormatter}
            showLabel
          />
        </GridItem>

        <GridItem columnStart={3} columnEnd={5} rowStart={2} rowEnd={2}>
          <Field
            name="extensionNumber"
            component={renderTextField}
            label={<FormattedMessage {...messages.extensionNumber} />}
            className="input form-control"
            placeholder="Enter Phone Extension"
            maxLength="255"
            showLabel
          />
        </GridItem>
        <GridItem columnStart={5} columnEnd={8} rowStart={2} rowEnd={2}>
          <Field
            name="emailAddress"
            component={renderTextField}
            label={<FormattedMessage {...messages.email} />}
            className="input form-control"
            placeholder="Enter Email"
            maxLength="150"
            showLabel
          />
        </GridItem>
        <GridItem columnStart={1} columnEnd={4} rowStart={3} rowEnd={3}>
          <Field
            name="secondaryPhoneNumber"
            component={renderPhoneField}
            label={<FormattedMessage {...messages.secondaryPhoneNumberField} />}
            className="input form-control"
            placeholder="(xxx) xxx-xxxx"
            parse={phoneParser}
            format={phoneFormatter}
            showLabel
          />
        </GridItem>

        <GridItem columnStart={4} columnEnd={8} rowStart={3} rowEnd={3}>
          <Field
            name="secondaryExtensionNumber"
            component={renderTextField}
            label={<FormattedMessage {...messages.secondaryExtensionNumber} />}
            className="input form-control"
            placeholder="Enter Secondary Phone Extension"
            maxLength="255"
            showLabel
          />
        </GridItem>
        <GridItem columnStart={1} columnEnd={4} rowStart={4} rowEnd={4}>
          <Field
            name="contactAddress"
            component={renderTextField}
            label={<FormattedMessage {...messages.contactAddressField} />}
            className="input form-control"
            placeholder="Address"
            showLabel
            validate={[validateNonRequiredAddress]}
            maxLength="100"
          />
        </GridItem>
        <GridItem columnStart={4} columnEnd={8} rowStart={4} rowEnd={4}>
          <Field
            name="contactAddress2"
            component={renderTextField}
            label={<FormattedMessage {...messages.contactAddress2Field} />}
            className="input form-control"
            placeholder="Address 2"
            showLabel
            validate={[validateNonRequiredAddress]}
            maxLength="100"
          />
        </GridItem>
        <GridItem columnStart={1} columnEnd={4} rowStart={5} rowEnd={5}>
          <Field
            name="city"
            component={renderTextField}
            label={<FormattedMessage {...messages.cityField} />}
            className="input form-control"
            placeholder="City"
            showLabel
            validate={[validateNonRequiredName]}
            maxLength="70"
          />
        </GridItem>
        <GridItem columnStart={4} columnEnd={6} rowStart={5} rowEnd={5}>
          <Field
            name="stateId"
            component={renderSelectField}
            options={states}
            selectClassNames="input form-control"
            label={<FormattedMessage {...messages.stateField} />}
          />
        </GridItem>
        <GridItem columnStart={6} columnEnd={8} rowStart={5} rowEnd={5}>
          <Field
            name="zipCode"
            component={renderTextField}
            label={<FormattedMessage {...messages.zipCodeField} />}
            className="input form-control"
            placeholder="Zip Code / Postal Code"
            showLabel
          />
        </GridItem>
      </FormGrid>
    </form>
  );
};

export default reduxForm({
  form: FORM_NAME,
  validate,
})(AddEditEmergencyContactForm);
