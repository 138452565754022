import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.UploadDocumentModal.header',
    defaultMessage: 'Compliance Packet Upload',
  },
  confirmationTitle: {
    id: 'App.UploadDocumentModal.Confirmation.Title',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  confirmationCancel: {
    id: 'App.UploadDocumentModal.Confirmation.Cancel',
    defaultMessage: 'No',
  },
  confirm: {
    id: 'App.UploadDocumentModal.Confirmation.Confirm',
    defaultMessage: 'Yes',
  },
  maxFileSizeExceeded: {
    id: 'App.UploadDocumentModal.MaxFileSizeExceeded',
    defaultMessage: 'Maximum file size is 100 MB',
  },
  save: {
    id: 'App.UploadDocumentModal.SaveLabel',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'App.UploadDocumentModal.Cancel',
    defaultMessage: 'Cancel',
  },
});

export default messages;
