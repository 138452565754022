// $FlowFixMe
import { useEffect } from 'react';
import VehiclesService from '../../../services/vehiclesService';
import StateService from '../../../services/stateService';
import { GEN_YEAR_OPTIONS, DEFAULT_OPTION } from './constants';

const parseOptions = (arrOfUnparsedOptions: Array<Object>) => {
  const parsedOptions = arrOfUnparsedOptions.map(({ id, name }) => ({
    value: id,
    text: name,
  }));
  return [DEFAULT_OPTION].concat(parsedOptions);
};

export const useAsyncVehicleOptions = (
  makeId: string,
  fetchingModel: boolean,
  setFetchingModel: Function,
  vehicleOptions: Object,
  setVehicleOptions: Function,
) => {
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchFormOptions = async () => {
      const vehiclesService = new VehiclesService();
      const options = { signal: abortController.signal };
      if (!vehicleOptions) {
        const stateService = new StateService();
        const [vehicleOptionsRes, statesRes] = await Promise.all([
          vehiclesService.getVehicleOptions(options),
          stateService.getAll(options),
        ]);

        const typeOptions = parseOptions(vehicleOptionsRes.types);
        const makeOptions = parseOptions(vehicleOptionsRes.makes);
        const colorOptions = parseOptions(vehicleOptionsRes.colors);
        const stateOptions = parseOptions(statesRes);
        const yearOptions = parseOptions(GEN_YEAR_OPTIONS());

        setVehicleOptions({
          colorOptions,
          makeOptions,
          stateOptions,
          typeOptions,
          yearOptions,
        });
      }
      if (makeId && vehicleOptions && fetchingModel) {
        const modelOptionsRes = await vehiclesService.getMakeOptions(
          makeId,
          options,
        );
        const modelOptions = parseOptions(modelOptionsRes);
        setFetchingModel(false);
        setVehicleOptions({
          ...vehicleOptions,
          modelOptions,
        });
      }
    };

    if (subscribed) {
      fetchFormOptions();
    }

    return () => {
      subscribed = false;
    };
  }, [
    makeId,
    fetchingModel,
    setFetchingModel,
    vehicleOptions,
    setVehicleOptions,
  ]);
};
