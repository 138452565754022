import { useMemo } from 'react';
import { useQuery } from 'react-query';
import PropertyService from '../../../services/propertyService';
import type { PropertyChangeHistoryLog } from '../types';

export const useFetchLatestChangeHistoryLog = (
  organizationId,
  propertyId,
  changeName,
  flag,
) => {
  const propertyService = useMemo(() => {
    return new PropertyService();
  }, []);
  return useQuery(
    [`LatestChangeHistoryLog-${changeName}`, propertyId],
    (): PropertyChangeHistoryLog =>
      propertyService.getLatestChangeHistoryLog(
        organizationId,
        propertyId,
        changeName,
      ),
    { enabled: flag, staleTime: 60000 },
  );
};
