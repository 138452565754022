import { defineMessages } from 'react-intl';

const messages = defineMessages({
  issueLocationHeader: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.IssueLocationHeader',
    defaultMessage: 'Where is the issue?',
  },
  issueLocationSelection: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.IssueLocationSelection',
    defaultMessage: 'Select one of these options before you continue:',
  },
  unit: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.Unit',
    defaultMessage: 'Unit',
  },
  commonArea: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.CommonArea',
    defaultMessage: 'Common Area',
  },
  issueType: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.IssueType',
    defaultMessage: 'What is the issue?',
  },
  issueSearch: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.IssueSearch',
    defaultMessage: 'Search for an issue...',
  },
  issueDescription: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.IssueDescription',
    defaultMessage: 'Issue Description*',
  },
  unitLocation: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.UnitLocation',
    defaultMessage: 'Unit Location',
  },
  commonAreaLocation: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.CommonAreaLocation',
    defaultMessage: 'Common Area Location',
  },
  issueDetails: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.IssueDetails',
    defaultMessage: 'Issue Details',
  },
  issueComment: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.IssueComment',
    defaultMessage: 'Issue Comment',
  },
  startTyping: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.StartTyping',
    defaultMessage: 'Start typing...',
  },
  nothingFound: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.NothingFound',
    defaultMessage: 'Nothing found. Please search again',
  },
  notListedAbove: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.notListedAbove',
    defaultMessage: 'Issue not list above?',
  },
  issueNotListedAbove: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.issueNotListedAbove',
    defaultMessage: 'Issue not listed above?',
  },
  otherIssue: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.otherIssue',
    defaultMessage: 'Other Issue',
  },
  household: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.household',
    defaultMessage: 'Household',
  },
  otherLocation: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.otherLocation',
    defaultMessage: 'Other Location',
  },
  photosOfIssue: {
    id: 'App.WorkOrder.WorkOrderForm.IssueInfo.photosOfIssue',
    defaultMessage: 'Photos of Issue',
  },
});

export default messages;
