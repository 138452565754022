import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.Title',
    defaultMessage: 'Create Transaction',
  },
  uploadFile: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.UploadFile',
    defaultMessage: 'Upload Document (CSV)',
  },
  cancel: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.Cancel',
    defaultMessage: 'Cancel',
  },
  post: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.Upload',
    defaultMessage: 'Upload',
  },
  propertyName: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.PropertyName',
    defaultMessage: 'Property Name',
  },
  subjournal: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.Subjournal',
    defaultMessage: 'Subjournal',
  },
  transactionType: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.TransactionType',
    defaultMessage: 'Transaction Type',
  },
  code: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.Code',
    defaultMessage: 'Code',
  },
  note: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.Note',
    defaultMessage: 'Note',
  },
  endDate: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.EndDate',
    defaultMessage: 'End Date',
  },
  startDate: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.start',
    defaultMessage: 'Start Date',
  },
  NoEndDate: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.NoEndDate',
    defaultMessage: 'No end date (Charges will last until lease end)',
  },
  notePlaceholder: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.NotePlaceholder',
    defaultMessage: '(Optional)',
  },
  createChargeBatchSuccessHeader: {
    id:
      'App.CreateChargeBatch.CreateChargeBatchForm.createChargeBatchSuccessHeader',
    defaultMessage: 'Success',
  },
  createChargeBatchFailureHeader: {
    id:
      'App.CreateChargeBatch.CreateChargeBatchForm.createChargeBatchFailureHeader',
    defaultMessage: 'Error',
  },
  createChargeBatchSuccessDescription: {
    id:
      'App.CreateChargeBatch.CreateChargeBatchForm.createChargeBatchSuccessDescription',
    defaultMessage:
      'Utility batch uploaded - A confirmation email will be sent when it finishes',
  },
  createChargeBatchFailureDescription: {
    id:
      'App.CreateChargeBatch.CreateChargeBatchForm.createChargeBatchFailureDescription',
    defaultMessage: 'Utility batch file could not be uploaded.',
  },
  yes: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.no',
    defaultMessage: 'No',
  },
  cancelConfirmationHeader: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.cancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  required: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.Required',
    defaultMessage: 'This field is required',
  },
  uploadText: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.UploadText',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Clicking Upload will send your file to Fortress to process for posting to the Resident Ledgers. You will receive a success or failure email shortly.',
  },
  emailText: {
    id: 'App.CreateChargeBatch.CreateChargeBatchForm.EmailText',
    defaultMessage:
      // eslint-disable-next-line max-len
      'If you receive a failure email then the upload did not post as a whole to the Resident Ledgers and you should verify the errors listed on the email.',
  },
});

export default messages;
