import {
  blue,
  grey,
} from '@fortress-technology-solutions/fortress-component-library/design';

export const filterPillSx = {
  backgroundColor: `${blue.lighter}`,
  borderRadius: '25px',
  color: `${blue.dark}`,
  fontWeight: 500,
  padding: '7px 13px',
  margin: '1px',
};

export const deleteIconSx = {
  cursor: 'pointer',
  color: `${blue.dark}`,
};

export const baseFontStyles = {
  fontSize: '14px',
  textAlign: 'left',
};

export const appliedFilterSx = {
  ...baseFontStyles,
  fontWeight: 500,
  lineHeight: '18px',
  letterSpacing: '-0.5863633751869202px',
  color: `${grey.main}`,
};

export const clearAllSx = {
  ...baseFontStyles,
  fontSize: '14px',
  fontWeight: 500,
  letterSpacing: '0em',
  color: `${blue.main}`,
};

export const pillFontSx = {
  ...baseFontStyles,
  fontWeight: 500,
  lineHeight: '9px',
  letterSpacing: '-0.5863633751869202px',
  color: `${blue.dark}`,
  whiteSpace: 'nowrap',
};
