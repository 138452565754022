// $FlowFixMe
import React, { Fragment, useState } from 'react';
import { reduxForm, Form, Field } from 'redux-form';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import {
  renderTextField,
  renderSelectField,
  renderDateField,
  extractDateFormat,
} from '../../../utils/redux-form-helper';

import Spinner from '../../../components/Spinner';
import { FORM_NAME } from '../constants';
import type { Vehicle } from '../types';

import { DEFAULT_OPTION, VEHICLE_VEHICLE_TYPE_ID } from './constants';
import { useAsyncVehicleOptions } from './hooks';
import validate from './validate';

type ReduxFormProps = {
  change: Function,
  handleSubmit: Function,
  untouch: Function,
};

type Props = ReduxFormProps & {
  onSubmit: Function,
  initialValues: Object,
  handleDeleteVehicle: Function,
  intl: any,
};

const VehicleGrid = styled.div`
  display: grid;
  grid-gap: 0 10px;
  padding: 0 0 10px 0;
`;

const GridHeader = styled(VehicleGrid)`
  grid-template-columns: 1fr 1fr;
`;

const VehicleGridTop = styled(VehicleGrid)`
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr 0.75fr;
`;

const VehicleGridMiddle = styled(VehicleGrid)`
  grid-template-columns: 1fr 1fr 1.25fr 0.75fr;
`;

const VehicleGridBottom = styled(VehicleGrid)`
  grid-template-columns: 2fr 2fr;
`;

const DeleteIcon = styled.i`
  font-size: 20px;
  margin: 0 10px;
`;

const VehiclesForm = ({
  change,
  handleSubmit,
  handleDeleteVehicle,
  initialValues,
  onSubmit,
  untouch,
  intl,
}: Props) => {
  const isEdit = Object.keys(initialValues).length > 0;
  const [vehicleOptions, setVehicleOptions] = useState(null);
  const [makeId, setMakeId] = useState(initialValues.vehicleMakeId);
  const [fetchingModel, setFetchingModel] = useState(isEdit);
  const [isVehicle, setIsVehicle] = useState(
    initialValues.vehicleTypeId === VEHICLE_VEHICLE_TYPE_ID,
  );

  useAsyncVehicleOptions(
    makeId,
    fetchingModel,
    setFetchingModel,
    vehicleOptions,
    setVehicleOptions,
  );

  const handleMakeChange = ({ target: { value } }) => {
    change('vehicleModelId', null);
    untouch('vehicleModelId');
    setFetchingModel(true);
    setMakeId(value);
  };

  const handleTypeChange = ({ target: { value } }) => {
    if (vehicleOptions && vehicleOptions.typeOptions) {
      if (value === VEHICLE_VEHICLE_TYPE_ID) {
        setIsVehicle(true);
      } else {
        change('vehicleMakeId', null);
        untouch('vehicleMakeId');
        change('vehicleModelId', null);
        untouch('vehicleModelId');
        setIsVehicle(false);
      }
    }
  };

  const handleUpperCasing = (value: string) => value.toUpperCase();

  const onHandleSubmit = (values: Vehicle) => {
    if (onSubmit) onSubmit(values);
  };

  return (
    <Fragment>
      {!vehicleOptions ? (
        <Spinner />
      ) : (
        <Form onSubmit={handleSubmit(onHandleSubmit)}>
          <GridHeader>
            <h2>{isEdit ? 'Edit ' : 'Add '}Vehicle</h2>
            {isEdit && (
              <a onClick={handleDeleteVehicle} style={{ textAlign: 'right' }}>
                <DeleteIcon className="et-trash" />
              </a>
            )}
          </GridHeader>
          <VehicleGridTop>
            <Field
              name="vehicleTypeId"
              component={renderSelectField}
              options={vehicleOptions.typeOptions}
              selectClassNames="input form-control"
              onChange={handleTypeChange}
              label="Type*"
            />
            <Field
              name="year"
              component={renderSelectField}
              options={vehicleOptions.yearOptions}
              selectClassNames="input form-control"
              label="Year"
            />
            <Field
              name="vehicleMakeId"
              component={renderSelectField}
              options={vehicleOptions.makeOptions}
              selectClassNames="input form-control"
              onChange={handleMakeChange}
              disabled={!isVehicle}
              label={`Make${isVehicle ? '*' : ''}`}
            />
            <Field
              name="vehicleModelId"
              component={renderSelectField}
              options={vehicleOptions.modelOptions || [DEFAULT_OPTION]}
              selectClassNames="input form-control"
              disabled={!isVehicle}
              label={`Model${isVehicle ? '*' : ''}`}
            />
            <Field
              name="vehicleColorId"
              component={renderSelectField}
              options={vehicleOptions.colorOptions}
              selectClassNames="input form-control"
              label="Color*"
            />
          </VehicleGridTop>
          <VehicleGridMiddle>
            <Field
              name="licensePlateNumber"
              component={renderTextField}
              label="License Plate*"
              className="input form-control"
              maxLength="10"
              normalize={handleUpperCasing}
              showLabel
            />
            <Field
              name="licensePlateStateId"
              component={renderSelectField}
              options={vehicleOptions.stateOptions}
              selectClassNames="input form-control"
              label="State*"
            />
            <Field
              name="decalTagNumber"
              component={renderTextField}
              label="Decal/Tag Number/Remote Number"
              className="input form-control"
              maxLength="10"
              normalize={handleUpperCasing}
              showLabel
            />
            <Field
              name="parkingSpace"
              component={renderTextField}
              label="Parking Space Number"
              className="input form-control"
              maxLength="10"
              normalize={handleUpperCasing}
              showLabel
            />
          </VehicleGridMiddle>
          <VehicleGridBottom>
            <Field
              name="registrationExpirationDate"
              component={renderDateField}
              label="Registration Expiration Date"
              classPicker="modal-fixed-position"
              bsSize="lg"
              dateFormat={extractDateFormat(intl)}
              placeholder={extractDateFormat(intl)}
              utc
            />
            <Field
              name="insuranceInformation"
              component={renderTextField}
              label="Insurance Information"
              className="input form-control"
              maxLength="255"
              showLabel
            />
          </VehicleGridBottom>
        </Form>
      )}
    </Fragment>
  );
};

export default injectIntl(
  reduxForm({
    form: FORM_NAME,
    touchOnChange: true,
    validate,
  })(VehiclesForm),
);
