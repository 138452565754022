import { defineMessages } from 'react-intl';

const messages = defineMessages({
  viewCalendarFor: {
    id: 'App.Home.CalendarTab.ViewCalendarFor',
    defaultMessage: 'View Calendar for'
  },
  noAssigneesAvailable: {
    id: 'App.Home.CalendarTab.NoAssigneesAvailable',
    defaultMessage: 'No assignees available'
  },
  contactSysAdmin: {
    id: 'App.Home.CalendarTab.ContactSysAdmin',
    defaultMessage: 'Please contact your system administrator.'
  },
  filterBy: {
    id: 'App.Home.CalendarTab.FilterBy',
    defaultMessage: 'Filter By'
  },
  allActivities: {
    id: 'App.Home.CalendarTab.allActivities',
    defaultMessage: 'All Activities'
  },
  recordedActivities: {
    id: 'App.Home.CalendarTab.recordedActivities',
    defaultMessage: 'Recorded Activities'
  },
  scheduledActivities: {
    id: 'App.Home.CalendarTab.scheduledActivities',
    defaultMessage: 'Scheduled Activities'
  },
});

export default messages;
