import * as ActionTypes from './constants';
import type { HouseholdMember } from '../../services/residentService';
import type { EditResidentHouseholdFormValues } from './Form/types';

export const getResidentHousehold = (residentId: string) => ({
  type: ActionTypes.GET_RESIDENT_HOUSEHOLD,
  payload: { residentId },
});

export const getAllPendingApplicants = (residentId: string) => ({
  type: ActionTypes.GET_ALL_PENDING_APPLICANTS,
  payload: { residentId },
});

export const getPendingApplicantsSuccess = (household: any[]) => ({
  type: ActionTypes.GET_ALL_PENDING_APPLICANTS_SUCCESS,
  payload: household,
});
export const getResidentHouseholdSuccess = (household: HouseholdMember[]) => ({
  type: ActionTypes.GET_RESIDENT_HOUSEHOLD_SUCCESS,
  payload: household,
});

export const getResidentHouseholdError = (error: string) => ({
  type: ActionTypes.GET_RESIDENT_HOUSEHOLD_ERROR,
  payload: error,
});

export const clearState = () => ({
  type: ActionTypes.CLEAR_STATE,
});

export const enableAdultEdit = (index: number) => ({
  type: ActionTypes.ENABLE_ADULT_EDIT,
  payload: index,
});

export const disableAdultEdit = (index: number) => ({
  type: ActionTypes.DISABLE_ADULT_EDIT,
  payload: index,
});

export const enableMinorEdit = (index: number) => ({
  type: ActionTypes.ENABLE_MINOR_EDIT,
  payload: index,
});

export const disableMinorEdit = (index: number) => ({
  type: ActionTypes.DISABLE_MINOR_EDIT,
  payload: index,
});

export const editResidentHousehold = (
  residentId: string,
  values: EditResidentHouseholdFormValues,
) => ({
  type: ActionTypes.EDIT_RESIDENT_HOUSEHOLD,
  payload: { residentId, values },
});

export const updatePendingApplicants = (residentId: string, values: any) => ({
  type: ActionTypes.EDIT_PENDING_APPLICANTS,
  payload: { residentId, values },
});

export const editResidentHouseholdSuccess = (edits: any[]) => ({
  type: ActionTypes.EDIT_RESIDENT_HOUSEHOLD_SUCCESS,
  payload: edits,
});

export const editPendingApplicantSuccess = (edits: any[]) => ({
  type: ActionTypes.EDIT_PENDING_APPLICANTS_SUCCESS,
  payload: edits,
});

export const editResidentHouseholdError = (error: any) => ({
  type: ActionTypes.EDIT_RESIDENT_HOUSEHOLD_ERROR,
  payload: error,
});

export const getLatestHouseholdHistory = (residentId: string) => ({
  type: ActionTypes.GET_LATEST_HOUSEHOLD_HISTORY,
  payload: { residentId },
});

export const getLatestHouseholdHistorySuccess = (householdHistory: Object) => ({
  type: ActionTypes.GET_LATEST_HOUSEHOLD_HISTORY_SUCCESS,
  payload: householdHistory,
});

export const getLatestHouseholdHistoryError = (error: string) => ({
  type: ActionTypes.GET_LATEST_HOUSEHOLD_HISTORY_ERROR,
  payload: error,
});

export const getHouseholdLeasesByResidentId = (residentId: string) => ({
  type: ActionTypes.GET_HOUSEHOLD_LEASES_BY_RESIDENT_ID,
  payload: residentId,
});

export const getHouseholdLeasesByResidentIdSuccess = (
  leases: Array<Object>,
) => ({
  type: ActionTypes.GET_HOUSEHOLD_LEASES_BY_RESIDENT_ID_SUCCESS,
  payload: leases,
});

export const getHouseholdLeasesByResidentIdError = (leases: Array<Object>) => ({
  type: ActionTypes.GET_HOUSEHOLD_LEASES_BY_RESIDENT_ID_ERROR,
  payload: leases,
});

export const getHouseholdHasOpenCerts = (residentId: string) => ({
  type: ActionTypes.GET_HOUSEHOLD_HAS_OPEN_CERTS,
  payload: { residentId },
});
export const getHouseholdHasOpenCertsSuccess = (hasOpenCerts: boolean) => ({
  type: ActionTypes.GET_HOUSEHOLD_HAS_OPEN_CERTS_SUCCESS,
  payload: hasOpenCerts,
});

export const getHouseholdHasOpenCertsError = (error: string) => ({
  type: ActionTypes.GET_HOUSEHOLD_HAS_OPEN_CERTS_ERROR,
  payload: error,
});
