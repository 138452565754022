import type { OrganizationId } from '../containers/App/types';
import request, { apiURL, requestToDownload, post } from '../utils/api';

export default class UnitService {
  getAllByProperty(propertyId: string, organizationId: OrganizationId) {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/unitAvailability`,
      requestOptions,
    );
  }
  getAll(
    propertyId: string,
    organizationId: OrganizationId,
    onlyUnitData?: boolean = false,
  ) {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const query = onlyUnitData === true ? `?onlyUnitData=${onlyUnitData}` : '';

    return request(
      `${apiURL}/${organizationId}/${propertyId}/units${query}`,
      requestOptions,
    );
  }
  getRentRoll(
    propertyId: string,
    organizationId: OrganizationId,
    options: Object,
  ) {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/manageUnits/rentRoll`,
      requestOptions,
    );
  }
  getCommercialRentRoll(propertyId: string, organizationId: OrganizationId) {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/commercialRentRoll`,
      requestOptions,
    );
  }
  getOccupiedUnitsForProperty(
    propertyId: string,
    organizationId: string,
    options: Object = {},
  ) {
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/units/occupied`,
      requestOptions,
    );
  }
  getById(
    propertyId: string,
    organizationId: OrganizationId,
    id: string,
    includeSoftDeleted?: boolean = false,
  ) {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/units/${id}${
        includeSoftDeleted ? '?includeSoftDeleted=true' : ''
      }`,
      requestOptions,
    );
  }
  getFloorplanById(
    propertyId: string,
    organizationId: OrganizationId,
    id: string,
  ) {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/floorPlan/${id}`,
      requestOptions,
    );
  }
  getAllUnitStatuses() {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/unitStatuses`, requestOptions);
  }
  updateUnit(
    propertyId: string,
    organizationId: OrganizationId,
    unitId: string,
    payload: any = {},
    options?: Object,
  ) {
    const body = JSON.stringify(payload);
    return post(
      `/${organizationId}/${propertyId}/units/${unitId}`,
      body,
      options,
    );
  }
  getQuote(
    propertyId: string,
    organizationId: OrganizationId,
    unitId: string,
    userId: string,
    applicantId: ?string,
    prospectId: ?string,
  ) {
    const params = applicantId
      ? `?applicantId=${applicantId}&userId=${userId}`
      : prospectId
      ? `?userId=${userId}&prospectId=${prospectId}`
      : `?userId=${userId}`;
    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/units/quote/${unitId}${params}`,
    );
  }
  getCentralizedUnitAvailability(options: any) {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };

    return request(`${apiURL}/allUnitAvailability`, requestOptions);
  }

  getUnitTypeOptions() {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(`${apiURL}/unit-type-options`, requestOptions);
  }

  getHouseholdUnitHistory({
    organizationId,
    propertyId,
    unitId,
  }: {
    organizationId: string,
    propertyId: string,
    unit: string,
  }) {
    const requestOptions = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/units/${unitId}/household-unit-history`,
      requestOptions,
    );
  }
}
