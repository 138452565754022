import React from 'react';
import { Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isNil } from 'ramda';
import styled from 'styled-components';

import Spinner from '../../../../components/Spinner';
import Table from '../../../../components/Table';
import Data from '../../../../components/Table/Data';
import Row from '../../../../components/Table/Row';

import { StatusSummaryBox } from './StatusSummaryBox';
import messages from './messages';
import ActiveCertificationRow from './ActiveCertificationRow';
import { OTHER_HUD, CERTIFICATION_TYPES_FOUND_INTL } from '../../constants';

type CerticationsTableProps = {
  headers: Array,
  intl: Object,
  currentSort: Object,
  onSortChange: Function,
  summary: Object,
  complianceApprovalStatuses: Array<Object>,
  propertyAffordablePrograms: Array<Object>,
  certificationType: string,
};

const SummaryBoxContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1.5fr;
  grid-template-columns: 1.5fr;
  grid-template-rows: 1fr;
  gap: 20px 20px;
  grid-template-areas: '.';
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
`;

function ActiveCertificationsTable({
  headers,
  intl,
  onSortChange,
  rows,
  isLoading,
  summary,
  complianceApprovalStatuses,
  propertyAffordablePrograms,
  certificationType,
}: CerticationsTableProps) {
  const foundMessage = !isNil(CERTIFICATION_TYPES_FOUND_INTL[certificationType])
    ? messages[CERTIFICATION_TYPES_FOUND_INTL[certificationType]]
    : messages[CERTIFICATION_TYPES_FOUND_INTL[OTHER_HUD]];

  return (
    <>
      <Col xs={12} md={12}>
        <p>
          <FormattedMessage {...foundMessage} />
          <span className="text-blue">{rows ? `: ${rows.length}` : ': 0'}</span>
        </p>
      </Col>

      <Col xs={12} md={12}>
        <SummaryBoxContainer className="padleft10 padright10">
          <StatusSummaryBox
            summary={summary}
            complianceApprovalStatuses={complianceApprovalStatuses}
            propertyAffordablePrograms={propertyAffordablePrograms}
          />
        </SummaryBoxContainer>
      </Col>
      <Col xs={12}>
        <div className="table-scroll table-units-container">
          <Table
            name="manage-certifications"
            headers={headers}
            onSort={onSortChange}
          >
            {isLoading ? (
              <Row>
                <Data colSpan={headers.length}>
                  <Spinner />
                </Data>
              </Row>
            ) : rows.length > 0 ? (
              rows.map((row) => (
                <ActiveCertificationRow
                  intl={intl}
                  key={`${row.id}`}
                  certification={row}
                  certificationType={certificationType}
                />
              ))
            ) : (
              <Row>
                <Data colSpan={headers.length}>
                  <FormattedMessage {...messages.noEntriesFound} />
                </Data>
              </Row>
            )}
          </Table>
        </div>
      </Col>
    </>
  );
}

export default injectIntl(ActiveCertificationsTable);
