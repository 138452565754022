import type { Action } from '../App/types';
import type { ProspectProfileState } from './types';
import * as ActionTypes from './constants';

const initialState: ProspectProfileState = {
  isSaving: false,
};

const prospectProfileReducer = (
  state: ProspectProfileState = initialState,
  action: Action<*, *>): ProspectProfileState => {
  const { type } = action;
  switch (type) {
    case ActionTypes.SAVE_PROSPECT_PROFILE: {
      return { ...state, isSaving: true };
    }
    case ActionTypes.SAVE_PROSPECT_PROFILE_SUCCESS: {
      return { ...state, isSaving: false };
    }
    default:
      return state;
  }
};

export default prospectProfileReducer;
