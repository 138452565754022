import { defineMessages } from 'react-intl';

const messages = defineMessages({
  domTitle: {
    id: 'App.MonthEndClose.domTitle',
    defaultMessage: 'Fortress - Month End Close',
  },
  header: {
    id: 'App.MonthEndClose.Header',
    defaultMessage: 'Month End Close',
  },
  closeAlert: {
    id: 'App.MonthEndClose.CloseAlert',
    defaultMessage: 'This process will close the current period: {periodName}',
  },
  pendingAlert: {
    id: 'App.MonthEndClose.PendingAlert',
    defaultMessage:
      'The current period: {periodName} is in the process of closing',
  },
  errorAlert: {
    id: 'App.MonthEndClose.errorAlert',
    defaultMessage: 'The period: {periodName} cannot be closed in {month}',
  },
  period: {
    id: 'App.MonthEndClose.Period',
    defaultMessage: 'Period',
  },
  date: {
    id: 'App.MonthEndClose.Date',
    defaultMessage: 'Date',
  },
  step: {
    id: 'App.MonthEndClose.Step',
    defaultMessage: 'Step',
  },
  cancel: {
    id: 'App.MonthEndClose.Cancel',
    defaultMessage: 'Cancel',
  },
  submit: {
    id: 'App.MonthEndClose.Submit',
    defaultMessage: 'Close Period',
  },
  noUnposted: {
    id: 'App.MonthEndClose.NoUnposted',
    defaultMessage: 'I have no undeposited payments in Create Deposit.*',
  },
  ifUnposted: {
    id: 'App.MonthEndClose.IfUnposted',
    defaultMessage:
      /* eslint-disable max-len */
      '*If this option is not enabled, you have undeposited single payments or payment batches that need to be posted. Go to Create Deposit and view these undeposited payments and make the appropriate deposits.',
  },
  noUnpostedPayments: {
    id: 'App.MonthEndClose.NoUnpostedPayments',
    defaultMessage: 'I have no undeposited payments in Create Deposit.*',
  },
  ifUnpostedPayments: {
    id: 'App.MonthEndClose.IfUnpostedPayments',
    defaultMessage:
      /* eslint-disable max-len */
      '*If this option is not enabled, you have undeposited single payments or payment batches that need to be posted. Go to Create Deposit and view these undeposited payments and make the appropriate deposits.',
  },
  upToDate: {
    id: 'App.MonthEndClose.UpToDate',
    defaultMessage:
      'I have entered all of the current month deposits, entered all of my current month move-ins, move-outs & transfers, and closed-out all work orders that have been completed to date.',
  },
  haveReviewed: {
    id: 'App.MonthEndClose.HaveReviewed',
    defaultMessage:
      'I have reviewed the following reports to ensure accuracy prior to closing the period and processing this month end close:',
  },
  customerOpsLedger: {
    id: 'App.MonthEndClose.customerOpsLedger',
    defaultMessage: 'Customer Ops Ledger',
  },
  depositBatchDetail: {
    id: 'App.MonthEndClose.depositBatchDetail',
    defaultMessage: 'Deposit Batch Detail',
  },
  moveIn: {
    id: 'App.MonthEndClose.moveIn',
    defaultMessage: 'Move-In',
  },
  moveOut: {
    id: 'App.MonthEndClose.moveOut',
    defaultMessage: 'Move-Out',
  },
  renewal: {
    id: 'App.MonthEndClose.renewal',
    defaultMessage: 'Renewal',
  },
  securityDeposits: {
    id: 'App.MonthEndClose.securityDeposits',
    defaultMessage: 'Security Deposits',
  },
  delinquentAndPrepaid: {
    id: 'App.MonthEndClose.delinquentAndPrepaid',
    defaultMessage: 'Delinquent and Prepaid',
  },
  availability: {
    id: 'App.MonthEndClose.availability',
    defaultMessage: 'Availability',
  },
  confirm: {
    id: 'App.MonthEndClose.Confirm',
    defaultMessage:
      'I confirm that all information entered for this month is accurate and complete.',
  },
  successHeader: {
    id: 'App.MonthEndClose.SuccessHeader',
    defaultMessage: 'Success',
  },
  successDescription: {
    id: 'App.MonthEndClose.SuccessDescription',
    defaultMessage: 'You have closed the period and opened a new period.',
  },
  errorHeader: {
    id: 'App.MonthEndClose.ErrorHeader',
    defaultMessage: 'Error closing month',
  },
  validateAllSteps: {
    id: 'App.MonthEndClose.ValidateAllSteps',
    defaultMessage: 'Please confirm this step',
  },
  validateHaveReviewed: {
    id: 'App.MonthEndClose.ValidateHaveReviewed',
    defaultMessage: 'Please select all checkboxes in Step 3 to confirm',
  },
  confirmMessage: {
    id: 'App.MonthEndClose.ConfirmMessage',
    defaultMessage: 'Are you sure you want to close this period',
  },
});

export default messages;
