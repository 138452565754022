import React, { useState } from 'react';
import styled from 'styled-components';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { Grid, Col, Row, Radio } from 'react-bootstrap';
import * as constants from './constants';
import * as validations from './validations';
import * as commonValidations from '../common-validations';
import messages from './messages';
import commonMessages from '../messages';
import RentScheduleFieldArray from './RentScheduleFieldArray';
import {
  number,
  positive,
  renderCurrencyField,
  renderPercentageField,
  required,
} from '../../../../../utils/redux-form-helper';

const HoldoverLabel = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.703637px;
  color: #4e4e4e;
`;

const Note = styled.span`
  & b,
  & span {
    line-height: 18px;
    letter-spacing: -0.43px;
    font-size: 14px;
  }
`;

const HOLDOVER_VALUES = {
  NO_INCREASE: 0,
  DOLLAR: 1,
  PERCENTAGE: 2,
};

function RentForm({ intl, startDate, change, handleSubmit, onSubmit }) {
  const [holdoverValue, setHoldoverValue] = useState(
    HOLDOVER_VALUES.NO_INCREASE,
  );

  const setHoldoverToNoIncrease = () => {
    setHoldoverValue(HOLDOVER_VALUES.NO_INCREASE);
    change('dollarIncrease', '');
    change('percentageIncrease', '');
  };
  const setHoldoverToDollar = () => {
    setHoldoverValue(HOLDOVER_VALUES.DOLLAR);
    change('percentageIncrease', '');
  };
  const setHoldoverToPercentage = () => {
    setHoldoverValue(HOLDOVER_VALUES.PERCENTAGE);
    change('dollarIncrease', '');
  };

  return (
    <form
      style={{ paddingLeft: 55, paddingRight: 114 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid fluid>
        <FieldArray
          name="rentSchedules"
          component={RentScheduleFieldArray}
          startDate={startDate}
          intl={intl}
        />
      </Grid>
      <Grid fluid>
        <Row>
          <Col xs={12} md={2}>
            <HoldoverLabel>
              {intl.formatMessage(messages.holdoverLabel)}
            </HoldoverLabel>
          </Col>
          <Col xs={12} md={2}>
            <Radio
              inline
              value={HOLDOVER_VALUES.NO_INCREASE}
              checked={holdoverValue === HOLDOVER_VALUES.NO_INCREASE}
              onClick={setHoldoverToNoIncrease}
            >
              {intl.formatMessage(messages.noIncrease)}
            </Radio>
          </Col>
          <Col xs={12} md={4}>
            <Row>
              <Col xs={1}>
                <Radio
                  inline
                  value={HOLDOVER_VALUES.DOLLAR}
                  checked={holdoverValue === HOLDOVER_VALUES.DOLLAR}
                  onClick={setHoldoverToDollar}
                />
              </Col>
              <Col xs={11}>
                <Field
                  name="dollarIncrease"
                  step="0.01"
                  component={renderCurrencyField}
                  style={{ height: constants.INPUT_HEIGHT }}
                  disabled={holdoverValue !== HOLDOVER_VALUES.DOLLAR}
                  validate={
                    holdoverValue === HOLDOVER_VALUES.DOLLAR
                      ? [
                          required,
                          number,
                          positive,
                          validations.greaterThanLastRentMonthlyAmount,
                        ]
                      : undefined
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4}>
            <Row>
              <Col xs={1}>
                <Radio
                  inline
                  value={HOLDOVER_VALUES.PERCENTAGE}
                  checked={holdoverValue === HOLDOVER_VALUES.PERCENTAGE}
                  onClick={setHoldoverToPercentage}
                />
              </Col>
              <Col xs={11}>
                <Field
                  name="percentageIncrease"
                  step="0.01"
                  component={renderPercentageField}
                  style={{ height: constants.INPUT_HEIGHT }}
                  disabled={holdoverValue !== HOLDOVER_VALUES.PERCENTAGE}
                  validate={
                    holdoverValue === HOLDOVER_VALUES.PERCENTAGE
                      ? [
                          required,
                          number,
                          positive,
                          commonValidations.validateHoldoverPercentLimits,
                        ]
                      : undefined
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 18 }}>
          <Col xs={12}>
            <Note>
              <b>NOTE:&nbsp;</b>
              <span>{intl.formatMessage(commonMessages.rentFormNote)}</span>
            </Note>
          </Col>
        </Row>
      </Grid>
    </form>
  );
}

export default reduxForm({
  form: constants.FORM_NAME,
})(RentForm);
