import {
  validateEmail,
  validateName,
  validatePhoneNumber,
} from '../../utils/validations';

export const validate = (values: Object, props: Object) => {
  const errors = {
    generalInformation: {},
  };

  const { generalInformation } = values;

  const generalInformationErrors = {};
  if (generalInformation) {
    const {
      emailAddress,
      firstName,
      lastName,
      phoneNumber,
    } = generalInformation;
    if (!firstName) {
      generalInformationErrors.firstName = 'Required';
    }
    if (!lastName) {
      generalInformationErrors.lastName = 'Required';
    }
    if (!phoneNumber) {
      generalInformationErrors.phoneNumber = 'Required';
    }
    if (!emailAddress) {
      generalInformationErrors.emailAddress = 'Required';
    }
    if (!validateEmail(emailAddress)) {
      generalInformationErrors.emailAddress = 'Invalid email address';
    }
    if (!validatePhoneNumber(phoneNumber)) {
      generalInformationErrors.phoneNumber = 'Invalid phone number';
    }
    ['firstName', 'lastName', 'middleName', 'preferredName'].forEach(
      (fieldName) => {
        if (
          !generalInformationErrors[fieldName] &&
          generalInformation[fieldName] &&
          !validateName(generalInformation[fieldName])
        ) {
          generalInformationErrors[fieldName] = 'Invalid Name';
        }
      },
    );

    errors.generalInformation = { ...generalInformationErrors };
  }

  return errors;
};
