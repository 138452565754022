import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { parsePropertiesToEnumListItem, parseValueToListItem } from './util';

import type { Property, EnumListItem, Option } from '../types';

import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  MultiSelectList,
  SimpleTreeViewList,
} from '@fortress-technology-solutions/fortress-component-library/Organisms';

import generalMessages from '../../App/messages';
import componentMessages from '../messages';

const messages = { ...generalMessages, ...componentMessages };

const StyledSpinner = styled(Spinner)`
  z-index: 100;
  position: absolute;
  height: calc(100% - 16px);
  width: 100%;
  background: rgba(0, 0, 0, 0.02);
  left: 0;
`;

type Props = {
  intl: Object,
  value: Array<string>,
  properties: Array<Property>,
  onChange: Function,
  filterOptions: Array<Option<string>>,
  isSubmitting?: boolean,
  allSelected?: boolean,
};

const SelectList = ({
  intl,
  value = [],
  properties = [],
  onChange,
  filterOptions = [],
  isSubmitting = false,
  allSelected = false,
}: Props) => {
  const selectedGroupLabel = intl.formatMessage(messages.property);

  const items: Array<EnumListItem> = parsePropertiesToEnumListItem(properties);
  const [selectedItems, setSelectedItems] = useState(
    parseValueToListItem(value, properties, selectedGroupLabel),
  );

  const onSelectChange = (value: Array<string>) => {
    onChange(value);
  };

  useEffect(() => {
    setSelectedItems(
      parseValueToListItem(value, properties, selectedGroupLabel),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, properties, selectedGroupLabel]);

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid
        item
        xs={12}
        pt={'16px'}
        style={{
          position: 'relative',
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="stretch"
          columnSpacing={3}
        >
          {isSubmitting && <StyledSpinner key="select-list-loader" />}
          {!allSelected && (
            <Grid item xs={6}>
              <MultiSelectList
                id="property-group-select-properties"
                key="property-group-select-properties"
                title={intl.formatMessage(messages.properties)}
                removeLabel={intl.formatMessage(messages.removeAll)}
                searchPlaceholder={intl.formatMessage(
                  messages.searchForACustom,
                  {
                    custom: intl.formatMessage(messages.property),
                  },
                )}
                listHeight={500}
                itemSize={35}
                onChange={onSelectChange}
                items={items}
                enableFilter
                filterOptions={filterOptions}
                filterPlaceholder={intl.formatMessage(messages.filterByCustom, {
                  custom: intl.formatMessage(messages.propertyType),
                })}
                selected={value}
              />
            </Grid>
          )}

          <Grid
            item
            xs={allSelected ? 12 : 6}
            style={{
              height: allSelected ? '640px' : 'auto', // Height is based on the multiselect min height of 500px
            }}
          >
            <SimpleTreeViewList
              id="property-group-selected-properties"
              key="property-group-selected-properties"
              title={intl.formatMessage(messages.selectedItems)}
              items={selectedItems}
              noDataTitle={intl.formatMessage(messages.noDataSelectedCustom, {
                custom: intl.formatMessage(messages.properties),
              })}
              noDataSubtitle={intl.formatMessage(messages.selectFromLeft)}
              itemSize={27}
              showCount={true}
              boxHeight={626}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectList;
