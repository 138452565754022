import { isEmpty, pathOr } from 'ramda';
import React, { useState } from 'react';
import {
  useAsyncResetPortalAccount,
  useAsyncSendPortalClaimEmail,
  useAsyncSendPortalPasswordResetEmail,
  useAsyncTogglePortalAccountStatus,
  useAsyncUpdatePortalUser,
  usePortalInformation,
} from './hooks';
import Spinner from '../../Spinner';
import MiniProfileFormPanel from '../../MiniProfileFormPanel';
import moment from 'moment';

const PANEL_TITLE = 'Portal';

type Props = {
  occupantInfo: Object,
  openPanel: ?string,
  togglePanel: Function,
  propertyId: string,
  organizationId: string,
  toastr: Function,
  isCommercial: boolean,
};

type PortalUser = {
  id: string,
  claimToken: string,
  isClaimed: boolean,
  lastSystemAccess: Date,
  isActive: boolean,
  claimRequestedAt: Date,
  numberFailedLoginAttempts: number,
};

export const ResidentPortalEmail = ({
  occupantInfo,
  openPanel,
  togglePanel,
  propertyId,
  organizationId,
  toastr,
}: Props) => {
  const [updated, setUpdated] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const customerId = pathOr('', ['customerId'], occupantInfo);
  const { portalStatus, setPortalStatus } = usePortalInformation(
    customerId,
    propertyId,
    organizationId,
    updated,
    setUpdated,
  );
  // $FlowFixMe
  const portalUser: PortalUser = pathOr({}, ['portalUser'], portalStatus);
  const emailAddress =
    portalStatus &&
    portalStatus.emailAddress &&
    portalStatus.emailAddress !== 'batch_default@mail.com'
      ? portalStatus.emailAddress
      : '---';
  const lastLoginDate = moment(portalUser.lastSystemAccess).format(
    'MM/DD/YYYY',
  );
  const lastLoginTime = moment(portalUser.lastSystemAccess).format('hh:mm a');
  const lastLogin = portalUser.lastSystemAccess
    ? `${lastLoginDate} @ ${lastLoginTime}`
    : '---';

  const invitationSentDate = moment(portalUser.claimRequestedAt).format(
    'MM/DD/YYYY',
  );
  const invitationSentTime = moment(portalUser.claimRequestedAt).format(
    'hh:mma',
  );
  const invitationSent = portalUser.claimRequestedAt
    ? `${invitationSentDate} ${invitationSentTime}`
    : 'Not Sent';
  let note = '';
  if (portalStatus.canResetLoginAttempts) {
    note += `Portal user is locked out, please reset.
    `;
  }
  if (emailAddress === '---' && !portalUser.isClaimed) {
    note += `Residents must have an email address to receive a ${PANEL_TITLE} invite.`;
  }
  if (emailAddress === '---' && portalUser.isClaimed) {
    note += 'Need Valid Email to send Reset Password link.';
  }
  if (portalStatus.canUnblockAccount) {
    note += 'Residents will not be able to log in if their account is blocked.';
  }
  if (portalStatus.isEmailClaimed && isEmpty(portalUser)) {
    note += `This email is already in use for another person in your community.
    Emails must be unique for each Portal account.`;
  }

  const { handleResetPortalAccount } = useAsyncResetPortalAccount(
    organizationId,
    propertyId,
    portalUser.id,
    portalStatus,
    setPortalStatus,
    setShowConfirmation,
    setUpdated,
    toastr,
  );

  const { handleSendPortalClaimEmail } = useAsyncSendPortalClaimEmail(
    organizationId,
    propertyId,
    customerId,
    setUpdated,
    toastr,
  );

  const { handleTogglePortalAccountStatus } = useAsyncTogglePortalAccountStatus(
    organizationId,
    propertyId,
    portalUser.id,
    portalStatus,
    setPortalStatus,
    setUpdated,
    toastr,
  );

  const { handleSendPortalPasswordResetEmail } =
    useAsyncSendPortalPasswordResetEmail(
      organizationId,
      propertyId,
      emailAddress,
      setUpdated,
      toastr,
    );

  const { handleUpdatePortalUser } = useAsyncUpdatePortalUser(
    organizationId,
    propertyId,
    portalUser.id,
    {
      numberFailedLoginAttempts: 0,
      isActive: true,
    },
    portalStatus,
    setPortalStatus,
    setUpdated,
    toastr,
  );

  const sendPortalInvite = (
    <div>
      <a
        onClick={() => handleSendPortalClaimEmail()}
        disabled={emailAddress === '---' || portalStatus.isEmailClaimed}
      >
        <i className="et-mail" />
        <span> Send Portal invite </span>
      </a>
    </div>
  );

  const blockAccount = (
    <div>
      <a onClick={() => handleTogglePortalAccountStatus()}>
        <i className="et-user-delete-1" />
        <span> Block Account </span>
      </a>
    </div>
  );

  const unblockAccount = (
    <div>
      <a onClick={() => handleTogglePortalAccountStatus()}>
        <i className="et-user-check" />
        <span> Unblock Account </span>
        {portalStatus.isEmailClaimed && !portalUser.isClaimed && (
          <div>
            <span>This email is already claimed.</span>
          </div>
        )}
      </a>
    </div>
  );

  const resetPassword = (
    <div>
      <a
        onClick={() => handleSendPortalPasswordResetEmail()}
        disabled={emailAddress === '---'}
      >
        <i className="et-mail" />
        <span> Reset Password </span>
      </a>
    </div>
  );

  const resetAccount = (
    <div>
      <a onClick={() => setShowConfirmation(true)}>
        <i className="et-refresh-1" />
        <span> Reset Account </span>
      </a>
    </div>
  );

  const updatePortalUser = (
    <div>
      <a onClick={() => handleUpdatePortalUser()}>
        <i className="et-refresh-1" />
        <span> Reset Login Attempts </span>
      </a>
    </div>
  );

  return (
    <MiniProfileFormPanel
      open={openPanel === PANEL_TITLE}
      toggleOpen={togglePanel(PANEL_TITLE)}
      title={PANEL_TITLE}
    >
      {isEmpty(portalStatus) ? (
        <Spinner />
      ) : (
        <div>
          <div className="container-fluid padleft5 padright5 padtop15 padbottom15 text-center">
            {showConfirmation ? (
              <div className="row padleft20">
                <div className="col-md-11">
                  <div className="row">
                    <strong>Are you sure you want to reset the account?</strong>
                  </div>
                  <div className="row">
                    <span>
                      Resetting the account will require the portal user to
                      re-claim their account.
                    </span>
                  </div>
                  <div className="row">
                    <button
                      className="btn btn-default padright5"
                      onClick={() => setShowConfirmation(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary padleft5"
                      onClick={() => handleResetPortalAccount()}
                    >
                      Reset Account
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="col-md-7">
                  <div className="text-left">
                    <strong>Email: </strong>
                    <span>{emailAddress}</span>
                  </div>
                  <div className="text-left">
                    <strong>Invite Sent: </strong>
                    <span>{invitationSent}</span>
                  </div>
                  <div className="text-left">
                    <strong>Claimed: </strong>
                    <span>{portalUser.isClaimed ? 'Yes' : 'No'}</span>
                  </div>
                  <div className="text-left">
                    <strong>Last Login: </strong>
                    <span>{lastLogin}</span>
                  </div>
                  <div className="text-left">
                    {portalStatus.canUnblockAccount && (
                      <div>
                        <span className="status status-ball status-red" />
                        <span className="padright5"> Account is blocked. </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="text-right">
                    {portalStatus.canSendPortalInvite &&
                      (sendPortalInvite ? sendPortalInvite : resetPassword)}
                    {!portalStatus.canSendPortalInvite &&
                      !portalStatus.isEmailValid && (
                        <div className="text-red">Invalid Email</div>
                      )}
                  </div>
                  <div className="text-right">
                    {portalStatus.canResetPassword && resetPassword}
                  </div>
                  <div className="text-right">
                    {portalUser.id &&
                      (portalStatus.canBlockAccount
                        ? blockAccount
                        : unblockAccount)}
                  </div>
                  <div className="text-right">
                    {portalStatus.canResetAccount && resetAccount}
                  </div>
                </div>
              </div>
            )}
          </div>
          {note && !showConfirmation && (
            <div className="col-md-12 text-center">
              <strong>Note: </strong>
              <span> {note} </span>
              <div>
                {portalStatus.canResetLoginAttempts && updatePortalUser}
              </div>
            </div>
          )}
        </div>
      )}
    </MiniProfileFormPanel>
  );
};

export default ResidentPortalEmail;
