import React from 'react';
import { dateDiffInDays } from '../../utils/date-helpers';
import { not, pathOr, isEmpty } from 'ramda';
import { download } from '../../utils/downloadFile';
import moment from 'moment';
import * as constants from './constants';
import * as csvHelpers from '../../utils/csv-helpers';
import { filterRenewalLeasesStatus } from './Filter/filterConfigurations';

export const downloadCSV = async ({
  assignedUnits,
  selectedProperty,
  hasActiveFilters,
}: {
  assignedUnits: Object<Array>,
  selectedProperty: Object,
  hasActiveFilters: boolean,
}) => {
  const isAllCommercial = selectedProperty.hasCommercialFloorPlans === 'ALL';

  if (assignedUnits?.length) {
    const { headers, rows } = parseCSV({
      assignedUnits,
      isAllCommercial,
    });

    const csv = csvHelpers.appendFilterTextToCSV({
      headers,
      rows,
      hasAnyFilters: hasActiveFilters,
    });

    download(csv, 'ManageLeaseExpiration.csv', 'text/csv;charset=utf-8');
  }
};

export const getCsvStatus = ({ isRenewal, isRenewalComplete }: Object) => {
  if (isRenewal && isRenewalComplete) {
    return filterRenewalLeasesStatus[0].text; // Completed
  } else if (isRenewal && not(isRenewalComplete)) {
    return filterRenewalLeasesStatus[1].text; // Started
  } else {
    return '---';
  }
};

export const getFilterFormInitialValues = ({
  expirationPeriod,
  expirationRange,
  renewalStatus,
}) => {
  const parseString = (value: string) => {
    if (!value) {
      return {};
    }
    return value.split(',').reduce((acc, filter) => {
      acc[filter] = true;
      return acc;
    }, {});
  };
  return {
    ExpirationSection: parseString(expirationPeriod),
    LeaseEndDateRangeSection: expirationRange,
    RenewalStatusSection: parseString(renewalStatus),
  };
};

/**
 * return latest renewal note entered with in 120 days before the current lease end date
 */
export const getLatestRenewalNote = (renewalNotes, leaseStartDate) => {
  const sortedRenewalNotes = renewalNotes.sort(
    (o1, o2) => moment.utc(o2.endTime) - moment.utc(o1.endTime),
  );
  const latestRenewalNote = sortedRenewalNotes.find((row) => {
    const renewalNoteDate = pathOr(null, ['endTime'], row);
    return moment
      .utc(renewalNoteDate)
      .isSameOrAfter(moment.utc(leaseStartDate));
  });
  let note = latestRenewalNote || {
    notes: null,
    by: { firstName: null },
    cs: { isCompleted: false },
    startTime: null,
  };

  // Not all existing renewal notes are completed
  if (!note?.cs) {
    note.cs = { isCompleted: false };
  }
  // Account for missing user information
  if (!note?.by) {
    note.by = { firstName: null };
  }

  return note;
};

export const getPrimaryResident = (residents: Array<Object>) =>
  residents.find((resident: Object) =>
    resident?.residentCustomer?.isPrimary ? resident : false,
  ) || residents[0];

export const getRenewalStatus = ({
  isRenewal,
  isRenewalComplete,
  isCsv,
}: Object) => {
  if (isCsv) {
    return getCsvStatus({ isRenewal, isRenewalComplete });
  }
  if (isRenewal && isRenewalComplete) {
    return <span className={'status status-ball status-green'} />;
  } else if (isRenewal && not(isRenewalComplete)) {
    return <span className={'status status-ball status-red'} />;
  } else {
    return '---';
  }
};

export const parseCSV = ({
  assignedUnits,
  isAllCommercial,
}: {
  assignedUnits: Array<Object>,
  isAllCommercial: boolean,
}) => {
  const filteredHeaders = isAllCommercial
    ? csvHelpers.getHeaders(constants.headers, 'resident')
    : csvHelpers.getHeaders(constants.headers, 'tenant');

  const headers = filteredHeaders
    .filter((header) => header.id !== 'view')
    .map((header) => header.title.props.defaultMessage)
    .join(',');

  const today = moment.utc();

  const rows = assignedUnits.map((assignedUnit) => {
    const { lease, unit, lastLease } = assignedUnit;
    const {
      unitStatus,
      number,
      floorPlan: { internalName, quotingRentAmount, baseMarketRentAmount },
    } = unit;

    const residents = Array.isArray(lease?.resident) ? lease.resident : [];
    const primaryResident = getPrimaryResident(residents);

    let {
      firstName,
      lastName,
      opLedger: [{ allSubjournalRunningBalance }],
      underEviction,
    } = primaryResident?.residentCustomer?.customer || {
      firstName: '',
      lastName: '',
      underEviction: false,
      opLedger: [{ allSubjournalRunningBalance: '' }],
    };

    const { doNotRenew } = lease?.household || {
      doNotRenew: false,
    };

    if (lastName.includes(',')) {
      // remove extra comma to avoid csv formatting issues
      lastName = lastName.split(',').join('');
    }

    const isRenewal = lastLease?.isRenewal || false;
    const isRenewalComplete = lastLease?.isRenewalComplete || false;

    const finalFields = [
      getRenewalStatus({ isRenewal, isRenewalComplete, isCsv: true }),
      number,
      internalName,
      firstName.length || lastName.length ? `${firstName} ${lastName}` : '---',
      moment.utc(lease.moveInDate).format('MM/DD/YYYY'),
      moment.utc(lease.startDate).format('MM/DD/YYYY'),
      moment.utc(lease.endDate).format('MM/DD/YYYY'),
      dateDiffInDays(
        new Date(today),
        new Date(moment.utc(lease.endDate)),
      ).toString(),
      unitStatus?.description === 'Occupied / On Notice to Vacate'
        ? 'Yes'
        : ' ',
      doNotRenew ? 'Yes' : '',
      underEviction ? 'Yes' : '',
      !Number.isNaN(baseMarketRentAmount) && baseMarketRentAmount > 0
        ? csvHelpers.formatCurrency(baseMarketRentAmount)
        : '---',
      parseFloat(lease?.priorLeasedRent) > 0
        ? csvHelpers.formatCurrency(lease.priorLeasedRent)
        : '---',
      lease?.leasedRent ? csvHelpers.formatCurrency(lease.leasedRent) : '---',
      '---',
      allSubjournalRunningBalance
        ? csvHelpers.formatCurrency(allSubjournalRunningBalance)
        : '---',
      csvHelpers.formatCurrency(quotingRentAmount),
    ];
    return finalFields.join(',');
  });

  return { headers, rows };
};

const getExpirationRangeFilters = (params: URLSearchParams) => {
  const month = params.get('month');
  const year = params.get('year');

  if (
    month &&
    moment.monthsShort().includes(month) &&
    year &&
    parseInt(year) >= 0
  ) {
    return {
      from: moment().utc().year(year).month(month).startOf('month'),
      to: moment().utc().year(year).month(month).endOf('month'),
    };
  }
  return {};
};

const getExpirationPeriodFilters = (params) => {
  const isMonthToMonthFilter = params.get('month') === 'mtm';

  if (isMonthToMonthFilter) {
    return 'monthToMonthFilter';
  }
  return '';
};

const getRenewalStatusFilters = (params) => params.get('renewalStatus') || '';

export const getParamFilters = (location) => {
  const searchParams = new URLSearchParams(location.search);
  const expirationRangeFilters = getExpirationRangeFilters(searchParams);
  const expirationPeriodFilters = getExpirationPeriodFilters(searchParams);
  const renewalStatusFilters = getRenewalStatusFilters(searchParams);
  if (
    isEmpty(expirationRangeFilters) &&
    isEmpty(expirationPeriodFilters) &&
    !renewalStatusFilters?.length
  ) {
    return {};
  }

  return {
    expirationRange: expirationRangeFilters,
    expirationPeriod: expirationPeriodFilters,
    renewalStatus: renewalStatusFilters,
  };
};

export const hasNewState = ({
  state,
  paramFilters,
}: {
  state: Object,
  paramFilters: Object,
}) => {
  return Object.entries(paramFilters).some(([property, value]) => {
    if (property === 'expirationRange') {
      return (
        state[property].from.format('MMM-YYYY') !==
          value.from.format('MMM-YYYY') &&
        state[property].to.format('MMM-YYYY') !== value.to.format('MMM-YYYY')
      );
    }
    return state[property] !== value;
  });
};
