import { useMemo, useState } from 'react';

export const SORT_DIRECTION = Object.freeze({
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
});

export const useSortableData = (items: any, sortTables: Function): any => {
  const { ASCENDING, DESCENDING } = SORT_DIRECTION;

  const [sortConfig, setSortConfig] = useState(null);
  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        return sortTables(a, b, sortConfig);
      });
    }
    return sortableItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, sortConfig]);

  const requestSort = (key, initialSortDirection = ASCENDING) => {
    if (initialSortDirection === DESCENDING) {
      const direction =
        sortConfig?.key === key && sortConfig?.direction === DESCENDING
          ? ASCENDING
          : DESCENDING;
      setSortConfig({ key, direction });
      return;
    }

    const direction =
      sortConfig?.key === key && sortConfig?.direction === ASCENDING
        ? DESCENDING
        : ASCENDING;
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export const sortByString = (
  a: Object,
  b: Object,
  prop: string,
  descending?: boolean = false,
): number => {
  const aVal = (!descending ? a?.[prop] : b?.[prop]) ?? '';
  const bVal = (!descending ? b?.[prop] : a?.[prop]) ?? '';

  return aVal.localeCompare(bVal, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};
