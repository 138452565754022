import React from 'react';
import messages from '../messages';
import { FormattedMessage } from 'react-intl';
import {
  Stack,
  Typography,
  Link,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

export const MissingSmartPricesModalTooltip = ({ onClickFn }) => (
  <React.Fragment>
    <Stack sx={{ maxWidth: '200px' }}>
      <Typography>
        <FormattedMessage {...messages.smartPriceErrorLoading} />
      </Typography>
      <Link onClick={onClickFn} sx={{ marginLeft: '130px' }}>
        <FormattedMessage {...messages.smartPriceErrorLoadingLink} />
      </Link>
    </Stack>
  </React.Fragment>
);
