import React from 'react';
import { is, head, prop } from 'ramda';
import { FormattedMessage } from 'react-intl';

import UpdateSpecialsForm from '../../components/UpdateSpecialsForm';
import DashesIfNullOrUndefined from '../../components/DashesIfNullOrUndefined';
import ElementWithPermissions from '../ElementWithPermissions';

import type { UnitSpecialsProps } from './types';

import messages from './messages';

export const UnitSpecials = ({
  intl,
  specials,
  editable,
  toggleEditCallback,
  submitSpecialsCallback,
}: UnitSpecialsProps) => {
  const description =
    is(Array, specials) && head(specials)
      ? // $FlowFixMe
        prop('description', head(specials))
      : null;
  return (
    <div className="row units-specials">
      {editable ? (
        <UpdateSpecialsForm
          initialValues={{
            specials: description,
          }}
          handleSubmit={submitSpecialsCallback}
          handleClickOutside={toggleEditCallback}
        />
      ) : (
        <div className="alert alert-primary">
          <div className="container-fluid">
            <div className="col-xs-10 col-md-11">
              <strong>
                <FormattedMessage {...messages.currentSpecials} />
              </strong>
              <p>
                {/* $FlowFixMe */}
                <DashesIfNullOrUndefined data={description} />
              </p>
            </div>
            <div className="col-xs-2 col-md-1">
              <ElementWithPermissions scope={['create-property-special']}>
                <a className="btn btn-text" onClick={toggleEditCallback}>
                  <i className="icon et-pencil" />
                  <FormattedMessage {...messages.edit} />
                </a>
              </ElementWithPermissions>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UnitSpecials;
