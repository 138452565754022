import {
  useConfirmModal,
  useModal,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { useState, useCallback } from 'react';

const useModalProps = () => {
  const { open, openModal, closeModal } = useModal(false);
  const { openConfirm, openConfirmModal, closeConfirmModal } =
    useConfirmModal();
  const [modalType, setModalType] = useState(null);
  const [modalState, setModalState] = useState({});
  const handleOpen = useCallback(
    ({ modalType, ...modalState }) => {
      setModalType(modalType);
      openModal();
      if (Object.keys(modalState).length) {
        setModalState(modalState);
      }
    },
    [openModal],
  );

  const handleClose = useCallback(() => {
    setModalType(null);
    closeModal();
    setModalState({});
  }, [closeModal]);

  const handleOpenConfirmModal = useCallback(
    ({ modalType, ...modalState }) => {
      setModalType(modalType);
      openConfirmModal();
      if (Object.keys(modalState).length) {
        setModalState(modalState);
      }
    },
    [openConfirmModal],
  );

  const handleCloseConfirmModal = useCallback(() => {
    closeConfirmModal();
    setModalState({});
  }, [closeConfirmModal]);

  return {
    closeConfirmModal,
    handleClose,
    handleCloseConfirmModal,
    handleOpen,
    handleOpenConfirmModal,
    modalState,
    modalType,
    open,
    openConfirm,
    openConfirmModal,
  };
};

export default useModalProps;
