import type { Transaction } from '../containers/Ledger/types';

const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

export type BatchHeader = {
  id: string,
  name: string,
  batchTotal: number,
  numberOfItems: number,
  batchDate: Date,
  mustBeSingleDeposit: boolean,
  batchNote: string,
  depositStatus: string,
  isPosted: boolean,
  isPosting: boolean,
  partialTotal: number,
  organizationId: string,
  propertyId: string,
  propertyBankAccountsId: string,
  propertyBankAccount?: {
    bankAccountType: string,
  },
  propertyFiscalPeriods: string,
  propertyFiscalPeriod?: {
    period: string,
  },
  adjustments?: boolean,
};

export type BatchDetail = {
  id: string,
  readableId: number,
  documentNumber: string,
  transactionDate: Date,
  transactionAmount: number,
  receivedFromHouseholdMember: string,
  financiallyResponsibleSignersInHouseHold: string,
  transactionNote?: string,
  status: string,
  headerId: string,
  customerId: string,
  propertyTransactionCodeId: string,
  propertyFiscalPeriodsId: string,
  unitId: string,
  customerOpsLedgerId?: string,
  customerOpsLedger?: Transaction,
  householdStatus: string,
  householdId: string,
  valid: boolean,
};

export type PaymentBatch = {
  header: BatchHeader,
  details: BatchDetail[],
};

export type PaymentBatchNotificationAttributes = {
  currentRoutePath: string,
  batchHeaderId: string,
};

class PaymentBatchService {
  savePaymentBatchHeader(
    organizationId: string,
    propertyId: string,
    payload: Object,
  ): any {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/paymentBatchHeader`,
      options,
    );
  }
  savePaymentBatchDetail(
    organizationId: string,
    propertyId: string,
    payload: Object,
  ): any {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/paymentBatchDetail`,
      options,
    );
  }
  deletePaymentBatchDetail(
    organizationId: string,
    propertyId: string,
    detailId: Object,
  ): any {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/paymentBatchDetail/${detailId}`,
      options,
    );
  }
  deletePaymentBatch(
    organizationId: string,
    propertyId: string,
    batchHeaderId: Object,
  ): any {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/paymentBatch/${batchHeaderId}`,
      options,
    );
  }
  get(id: string, propertyId: string, orgId: string): Promise<any> {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${orgId}/${propertyId}/paymentBatch/${id}`,
      options,
    );
  }
  postPaymentBatch(
    id: string,
    propertyId: string,
    orgId: string,
    notificationAttributes?: PaymentBatchNotificationAttributes,
  ): Promise<any> {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id,
        notificationAttributes,
      }),
    };
    return request(
      `${apiURL}/${orgId}/${propertyId}/paymentBatch/post`,
      options,
    );
  }
  getAllUndepositedBatchesForProperty(
    propertyId: string,
    organizationId: string,
  ): Promise<BatchHeader[]> {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/paymentBatch/undeposited`,
      options,
    );
  }

  /* update subsidy payment batch header */
  updatePaymentBatchHeader(
    id: string,
    orgId: string,
    propertyId: string,
    paymentBatch: Object,
  ): Promise<any> {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...paymentBatch,
      }),
    };
    return request(
      `${apiURL}/${orgId}/${propertyId}/paymentBatch/${id}`,
      options,
    );
  }
}

export default PaymentBatchService;
