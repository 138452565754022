import type { Action } from '../App/types';
import {
  GET_DEPOSIT,
  GET_DEPOSIT_SUCCESS,
  GET_DEPOSIT_ERROR,
  CLEAR_LOADED_DEPOSIT
} from './constants';

export const initialState = {
  header: {
    batchTotal: '0.00',
    batchDate: '',
    batchNote: '',
    name: '',
    propertyFiscalPeriods: {
      period: ''
    },
    propertyBankAccounts: {
      bankAccountType: ''
    },
    numberOfTransactions: 0
  },
  transactions: []
};

const depositsReducer = (
  state: Object = initialState,
  action: Action<*, *>
): Object => {
  const { type, payload, error } = action;
  switch (type) {
    case GET_DEPOSIT_SUCCESS: {
      return { ...state, ...payload };
    }
    case GET_DEPOSIT_ERROR: {
      return { ...state, depositsError: payload, error };
    }
    case CLEAR_LOADED_DEPOSIT: {
      return { ...initialState };
    }
    case GET_DEPOSIT:
    default:
      return state;
  }
};

export default depositsReducer;
