import messages from './messages';

export const getOptions = (formatMessage, propertyName) => [
  {
    id: 1,
    textContent: formatMessage(messages.contractConfirmation, {
      propertyName,
    }),
  },
  {
    id: 2,
    textContent: formatMessage(messages.exclusionConfirmation),
  },
  {
    id: 3,
    textContent: formatMessage(messages.autoClaimGenerationConfirmation),
  },
];
