import React, { Component } from 'react';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { pathOr, is, keys } from 'ramda';
import moment from 'moment';
import Income from './Income';
import Occupancy from './Occupancy';
import NetOccupancy from './NetOccupancy';
import Activity from './Activity';
import CurrentMonthRenewals from './CurrentMonthRenewals';
import Eviction from './Evictions';
import MakeReadies from './MakeReadies';
import Delinquency from './Delinquency';
import VacantNotLeasedByFloorplan from './VacantNotLeasedByFloorplan';
import LeaseExpirationExposure from './LeaseExpirationExposure';
import KpiBox from './KpiBox';
import messages from './messages';
import { getAllKpis, getKpi, cleanLoadedKpis } from './actions';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
  LEASE_EXPIRATION_EXPOSURE_OPTIONS,
  DEFAULT_LEASE_EXPIRATION_EXPOSURE_MONTHS,
} from './constants';
import { withExpirationLimitsPropertyToggle } from '../../hooks/data-fetching/useExpirationLimitsPropertyToggle';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type Props = {
  intl: any,
  actions: Object,
  overview: Object,
  selectedProperty: Object,
  userRole: Object,
  history: Object,
  isLeaseExpirationLimitsActive: boolean,
  refreshIsLeaseExpirationLimitsActive: Function,
  tabKey: string,
  activeKey: string,
};

type State = {
  signal: ?Object,
  controller: ?Object,
  selectedOptionNumber: Object,
  showDelinquency: boolean,
  selectedLeaseExpirationMonths: string,
};

const DEFAULT_NET_OCCUPANCY_OPTION_NUMBER = 1;

export class Overview extends Component<Props, State> {
  constructor(props: Object) {
    super(props);

    // $FlowFixMe
    const controller = new AbortController(); // eslint-disable-line
    const signal = controller.signal;
    this.state = {
      controller,
      signal,
      selectedOptionNumber: {
        netOccupancy: DEFAULT_NET_OCCUPANCY_OPTION_NUMBER,
      },
      showDelinquency: false,
      selectedLeaseExpirationMonths: DEFAULT_LEASE_EXPIRATION_EXPOSURE_MONTHS,
    };
  }

  componentDidMount() {
    const {
      actions: { getAllKpis },
      userRole,
    } = this.props;
    const isOwner = pathOr('', ['name'], userRole) === 'Owner';
    if (isOwner) {
      this.setState({ showDelinquency: true });
    }
    getAllKpis(this.state.signal);
  }

  componentWillUnmount() {
    if (this.state.controller && is(Function, this.state.controller.abort)) {
      this.state.controller.abort();
    }
    this.props.actions.cleanLoadedKpis();
  }

  componentDidUpdate(prev: Object) {
    const {
      actions: { getAllKpis },
      overview: {
        occupancy: { value: occupancyVal },
        makeReadies: { value: makeReadiesVal },
      },
    } = this.props;
    if (
      prev.activeKey !== this.props.activeKey &&
      this.props.activeKey === this.props.tabKey
    ) {
      this.setState({
        selectedLeaseExpirationMonths: DEFAULT_LEASE_EXPIRATION_EXPOSURE_MONTHS,
      });
      this.props.refreshIsLeaseExpirationLimitsActive();
      getAllKpis(this.state.signal);
    }
    if (prev.selectedProperty.id !== this.props.selectedProperty.id) {
      const { selectedOptionNumber } = this.state;
      const defaultSelections = keys(selectedOptionNumber).reduce(
        (prev, kpiName) => {
          if (kpiName === 'netOccupancy') {
            prev[kpiName] = DEFAULT_NET_OCCUPANCY_OPTION_NUMBER;
            return prev;
          }
          prev[kpiName] = 0;
          return prev;
        },
        {},
      );
      const { userRole } = this.props;
      const isOwner = pathOr('', ['name'], userRole) === 'Owner';
      this.setState({
        selectedOptionNumber: defaultSelections,
        showDelinquency: isOwner,
        selectedLeaseExpirationMonths: DEFAULT_LEASE_EXPIRATION_EXPOSURE_MONTHS,
      });
    }

    if (
      prev.overview.occupancy.value !== occupancyVal ||
      prev.overview.makeReadies.value !== makeReadiesVal
    ) {
      this.setWalkMeDateToWindow({
        occupancyVal,
        makeReadiesVal,
      });
    }
  }

  changeSelectedRange =
    (kpi: string) =>
    ({ target: { value } }: Object) => {
      const { getKpi } = this.props.actions;
      const { signal } = this.state;
      const selectedValue = +value;
      let endDate;

      if (selectedValue === 0) {
        endDate = moment().add(6, 'days').format('MM/DD/YYYY');
      } else if (selectedValue === 1) {
        endDate = moment().add(29, 'days').format('MM/DD/YYYY');
      } else if (selectedValue === 2) {
        endDate = moment().add(59, 'days').format('MM/DD/YYYY');
      } else if (selectedValue === 3) {
        endDate = moment().add(89, 'days').format('MM/DD/YYYY');
      } else if (selectedValue === 4) {
        endDate = moment().endOf('month').format('MM/DD/YYYY');
      } else {
        endDate = null;
      }
      const query = endDate ? `?endDate=${moment(endDate).format()}` : '';
      const selectedOptionNumber = { [kpi]: selectedValue };
      this.setState({ selectedOptionNumber });
      getKpi(kpi, signal, query);
    };

  handleSelectedLeaseExpirationsMonthsChange = ({
    target: { value },
  }: Object) => {
    const { getKpi } = this.props.actions;
    const { signal } = this.state;
    this.setState({
      selectedLeaseExpirationMonths: value,
    });
    getKpi('leaseExpirationExposure', signal, `?months=${value}`);
  };

  handleShowDelinquecy = () => {
    const { getKpi } = this.props.actions;
    const { signal } = this.state;
    this.setState({ showDelinquency: true });
    getKpi('delinquency', signal);
  };

  setWalkMeDateToWindow = ({ occupancyVal, makeReadiesVal }) => {
    if (
      Object.keys(occupancyVal)?.length &&
      Object.keys(makeReadiesVal)?.length
    ) {
      const walkMeData = {
        occupancyTotal: occupancyVal.occupied + occupancyVal.unoccupied,
        occupancyPercentage:
          Math.round(
            (occupancyVal.occupied /
              (occupancyVal.occupied + occupancyVal.unoccupied)) *
              10000,
          ) / 100,
        makeReadiesLeasedNotReady: makeReadiesVal?.leased?.notReady ?? null,
      };

      if (Object.values(walkMeData).every((value) => !isNaN(value))) {
        // create object if it doesn't exist
        if (!window.hasOwnProperty('walkMeData')) {
          window.walkMeData = {};
        }
        // replace values by key
        Object.entries(walkMeData).forEach(([key, value]) => {
          window.walkMeData[key] = value;
        });
      }
    }
  };

  render() {
    const {
      intl: { formatMessage, formatNumber },
      history,
      overview: {
        fetching,
        income,
        netOccupancy,
        occupancy,
        activity,
        currentMonthRenewals,
        workOrders,
        concessions,
        evictions,
        makeReadies,
        vacantNotLeasedByFloorPlan,
        leaseExpirationExposure,
        delinquency,
      },
      isLeaseExpirationLimitsActive,
      isExportingPDF,
    } = this.props;
    const { selectedOptionNumber, selectedLeaseExpirationMonths } = this.state;
    const incomeVal = pathOr(0, ['value'], income);
    const netOccupancyVal = pathOr({}, ['value'], netOccupancy);
    const occupancyVal = pathOr({}, ['value'], occupancy);
    const activityVal = pathOr({}, ['value'], activity);
    const currentMonthRenewalsVal = pathOr(0, ['value'], currentMonthRenewals);
    const concessionsVal = pathOr(0, ['value'], concessions);
    const workOrdersVal = pathOr(0, ['value'], workOrders);
    const evictionsVal = pathOr(0, ['value'], evictions);
    const makeReadiesVal = pathOr({}, ['value'], makeReadies);
    const vacantNotLeasedByFloorPlanVal = pathOr(
      {},
      ['value'],
      vacantNotLeasedByFloorPlan,
    );
    const leaseExpirationExposureVal = pathOr(
      {},
      ['value'],
      leaseExpirationExposure,
    );
    const delinquencyVal = pathOr({}, ['value'], delinquency);

    return (
      <Grid container spacing={2} id="1a">
        <Grid
          item
          {...(isExportingPDF ? { xs: 12 } : { xs: 12, md: 6, xl: 4 })}
        >
          <Income
            data={incomeVal}
            formatMessage={formatMessage}
            formatNumber={formatNumber}
            dataLoaded={income.kpiLoaded}
            fetching={fetching}
            isExportingPDF={isExportingPDF}
          />
        </Grid>
        <Grid
          item
          {...(isExportingPDF ? { xs: 3 } : { xs: 12, sm: 6, md: 3, xl: 2 })}
        >
          <Occupancy
            occupancyData={occupancyVal}
            netOccupancyData={netOccupancyVal}
            formatMessage={formatMessage}
            dataLoaded={occupancy.kpiLoaded}
            fetching={fetching}
          />
        </Grid>
        <Grid
          item
          {...(isExportingPDF ? { xs: 3 } : { xs: 12, sm: 6, md: 3, xl: 2 })}
        >
          <NetOccupancy
            occupancyData={{
              occupied: occupancyVal.occupied,
              unoccupied: occupancyVal.unoccupied,
            }}
            netOccupancyData={netOccupancyVal}
            formatMessage={formatMessage}
            dataLoaded={netOccupancy.kpiLoaded && occupancy.kpiLoaded}
            fetching={fetching}
            selectedOptionNumber={selectedOptionNumber.netOccupancy}
            changeSelectedRange={this.changeSelectedRange('netOccupancy')}
          />
        </Grid>
        <Grid
          item
          {...(isExportingPDF ? { xs: 3 } : { xs: 12, sm: 6, md: 6, xl: 2 })}
        >
          <Activity
            data={[activityVal.mtdMoveIns, activityVal.mtdMoveOuts]}
            formatMessage={formatMessage}
            dataLoaded={activity.kpiLoaded}
            fetching={fetching}
          />
        </Grid>
        <Grid
          item
          {...(isExportingPDF ? { xs: 3 } : { xs: 12, sm: 6, md: 6, xl: 2 })}
        >
          <CurrentMonthRenewals
            currentMonthRenewalsData={currentMonthRenewalsVal}
            formatMessage={formatMessage}
            dataLoaded={currentMonthRenewals.kpiLoaded}
            fetching={fetching}
            history={history}
          />
        </Grid>
        <Grid item {...(isExportingPDF ? { xs: 4 } : { xs: 12, sm: 4, md: 4 })}>
          <KpiBox
            data={concessionsVal}
            topTitle={messages.concessions}
            firstDescription={messages.ptdConcesions}
            formatNumber={formatNumber}
            dataLoaded={concessions.kpiLoaded}
            isCurrency={true}
          />
        </Grid>
        <Grid item {...(isExportingPDF ? { xs: 4 } : { xs: 12, sm: 4, md: 4 })}>
          <KpiBox
            redirectTo={getUrlWithSelectedPropertyId('/manage-work-orders')}
            data={workOrdersVal}
            topTitle={messages.workOrders}
            firstDescription={messages.openWorkOrders}
            formatNumber={formatNumber}
            dataLoaded={workOrders.kpiLoaded}
          />
        </Grid>
        <Grid item {...(isExportingPDF ? { xs: 4 } : { xs: 12, sm: 4, md: 4 })}>
          <Eviction
            data={evictionsVal}
            formatMessage={formatMessage}
            formatNumber={formatNumber}
            dataLoaded={evictions.kpiLoaded}
          />
        </Grid>
        <Grid item {...(isExportingPDF ? { xs: 6 } : { xs: 12, md: 6 })}>
          <MakeReadies
            data={makeReadiesVal}
            dataLoaded={makeReadies.kpiLoaded}
          />
        </Grid>
        <Grid item {...(isExportingPDF ? { xs: 6 } : { xs: 12, md: 6 })}>
          <VacantNotLeasedByFloorplan
            data={vacantNotLeasedByFloorPlanVal}
            dataLoaded={vacantNotLeasedByFloorPlan.kpiLoaded}
          />
        </Grid>
        <Grid item {...(isExportingPDF ? { xs: 6 } : { xs: 12, md: 6 })}>
          <Delinquency
            data={delinquencyVal}
            formatNumber={formatNumber}
            dataLoaded={delinquency.kpiLoaded}
            showDelinquency={this.state.showDelinquency}
            handleShowDelinquency={this.handleShowDelinquecy}
          />
        </Grid>

        <Grid item {...(isExportingPDF ? { xs: 6 } : { xs: 12, md: 6 })}>
          <LeaseExpirationExposure
            data={leaseExpirationExposureVal}
            dataLoaded={leaseExpirationExposure.kpiLoaded}
            options={LEASE_EXPIRATION_EXPOSURE_OPTIONS}
            selectedMonths={selectedLeaseExpirationMonths}
            onSelectedMonthsChange={
              this.handleSelectedLeaseExpirationsMonthsChange
            }
            history={history}
            isLeaseExpirationLimitsActive={isLeaseExpirationLimitsActive}
          />
        </Grid>
      </Grid>
    );
  }
}

export const mapStateToProps = ({
  app: {
    currentUser: {
      user: { userRole },
    },
    selectedProperty,
  },
  overview,
}: any) => {
  return {
    userRole,
    overview,
    selectedProperty,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      getAllKpis,
      getKpi,
      cleanLoadedKpis,
    },
    dispatch,
  );
  return { actions };
}

const InjectedOverview = injectIntl(Overview);

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withExpirationLimitsPropertyToggle(InjectedOverview));

export default withLDConsumer()(connected);
