import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { pathOr } from 'ramda';
import { injectIntl, type InjectIntlProvidedProps } from 'react-intl';
import DocumentTitle from 'react-document-title';
import messages from './messages';
import { getCreateChargeBatch } from './actions';
import { FORM_NAME } from './CreateChargeBatchForm/constants';
import { Button } from 'react-bootstrap';
import ConfirmModal from '../../components/ConfirmModal';
import CreateChargeBatchForm from './CreateChargeBatchForm';

type ConnectedProps = {
  anyTouched: boolean,
  invalid: boolean,
};

type ConnectedMethods = {
  actions: {
    submit: Function,
    getCreateChargeBatch: Function,
  },
};

type ReactRouterProps = {
  history: Object,
};

type Props = InjectIntlProvidedProps &
  ConnectedProps &
  ConnectedMethods &
  ReactRouterProps;

type State = {
  confirm: ?string,
};

const CANCEL_CONFIRM = 'CANCEL_CONFIRM';

export class CreateChargeBatch extends Component<Props, State> {
  constructor() {
    super();
    this.state = {
      confirm: null,
    };
  }

  componentDidMount() {
    const {
      actions: { getCreateChargeBatch },
    } = this.props;
    getCreateChargeBatch();
  }

  handleCancelConfirm = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleCancelReject = () => {
    this.setState({ confirm: null });
  };

  handleCancel = () => {
    const { confirm } = this.state;
    if (confirm !== CANCEL_CONFIRM) {
      this.setState({ confirm: CANCEL_CONFIRM });
    }
  };

  submitForm = () => {
    const {
      actions: { submit },
    } = this.props;
    submit(FORM_NAME);
  };

  showConfirmation = () => {
    const { confirm } = this.state;
    return confirm != null && [CANCEL_CONFIRM].includes(confirm);
  };

  render() {
    const { intl, anyTouched, invalid, ...props } = this.props;
    const showConfirmation = this.showConfirmation();
    return (
      <DocumentTitle title={intl.formatMessage(messages.title)}>
        <ConfirmModal
          size="small"
          show
          onHide={this.handleCancel}
          header={
            <Fragment>
              <i className="icon et-money" />
              <h1>
                <FormattedMessage {...messages.title} />
              </h1>
            </Fragment>
          }
          footer={
            <div className="row">
              <div className="col-xs-6">
                <Button
                  type="button"
                  bsStyle="default"
                  className="pull-right"
                  onClick={this.handleCancel}
                >
                  <FormattedMessage {...messages.cancel} />
                </Button>
              </div>
              <div className="col-xs-6">
                <Button
                  type="submit"
                  onClick={this.submitForm}
                  disabled={showConfirmation || invalid}
                  bsStyle="primary"
                  className="pull-left"
                >
                  <FormattedMessage {...messages.submit} />
                </Button>
              </div>
            </div>
          }
          confirm={showConfirmation}
          title={<FormattedMessage {...messages.confirmTitle} />}
          confirmMessage={<FormattedMessage {...messages.confirmMessage} />}
          onConfirm={this.handleCancelConfirm}
          rejectMessage={<FormattedMessage {...messages.rejectMessage} />}
          onReject={this.handleCancelReject}
        >
          <CreateChargeBatchForm {...props} intl={intl} />
        </ConfirmModal>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = ({
  form,
  createChargeBatch,
  app: { selectedProperty },
}: Object): ConnectedProps => {
  const anyTouched = pathOr(false, [FORM_NAME, 'anyTouched'], form);
  const invalid =
    Object.keys(pathOr({}, [FORM_NAME, 'syncErrors'], form)).length > 0;
  return {
    anyTouched,
    invalid,
    propertyName: selectedProperty.name,
  };
};

const mapDispatchToProps = (dispatch: Object): ConnectedMethods => ({
  actions: bindActionCreators(
    {
      getCreateChargeBatch,
      submit,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CreateChargeBatch));
