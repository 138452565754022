import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import DocumentTitle from 'react-document-title';
import {
  and,
  defaultTo,
  differenceWith,
  equals,
  find,
  isNil,
  not,
  path,
  pathEq,
  pathOr,
  propEq,
} from 'ramda';
import * as editApplicationHouseholdActions from './actions';
import {
  getAllAffordableRelationships,
  getAllApplicantTypes,
  getAllNameSuffixes,
  getAllPetTypes,
  getAllPropertyClasses,
  getAllRelationships,
} from '../App/actions';
import { EditApplicationHouseholdForm } from '../../components/EditHouseholdForm';
import confirm from '../../components/ConfirmDialogModal';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import {
  getAffordableRelationshipOptions,
  getApplicablePropertyClassOptions,
  getApplicantTypeOptions,
  getMinorRelationshipOptions,
  getNameSuffixOptions,
  getPetTypeOptions,
  getRelationshipOptions,
  getSelectedPropertyClassType,
  getGuarantorOptions,
  getLiveInCaretakerOptions,
} from '../App/selectors';
import { getApplicantNames, getPropertyId } from './selectors';
import messages from './messages';

import type { GlobalState } from '../App/types';
import type { Application } from '../CreateApplication/types';
import { getSentAndExecutedStatus } from '../../utils/lease-helpers';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type Option = {
  value: string,
  text: string,
  disabled: boolean,
};

type StateProps = {
  applicationId: string,
  application?: Application,
  propertyId: string,
  applicantNames: string,
  primaryApplicantId: string,
  nameSuffixes: Array<Option>,
  propertyClasses: Array<Option>,
  relationships: Array<Option>,
  minorRelationship: Array<Option>,
  applicantTypes: Array<Option>,
  petTypes: Array<Option & { petBreeds: Array<Option> }>,
  locale: string,
  isAffordable: boolean,
  affordableRelationship: any,
  isHouseholdSubmitting: boolean,
};
type Props = {
  intl: any,
  history: Object,
  actions: {
    clean: () => void,
    getOneApplication: (string) => void,
    editApplicationHousehold: (Application) => void,
    getAllNameSuffixes: () => void,
    getAllPropertyClasses: () => void,
    getAllApplicantTypes: () => void,
    getAllRelationships: () => void,
    getAllAffordableRelationships: () => void,
    getAllPetTypes: () => void,
  },
};

const BlockIcon = styled.i`
  display: block;
  font-size: 40px;
  margin: 10px;
`;

export const EditApplicationHousehold = (props: Props) => {
  const {
    intl,
    actions,
    applicantNames,
    application,
    applicationId,
    propertyId,
    nameSuffixes,
    relationships,
    minorRelationship,
    affordableRelationship,
    applicantTypes,
    propertyClasses,
    petTypes,
    isHouseholdSubmitting,
    isAffordable,
    relationshipsIsLoading,
    affordableRelationshipsIsLoading,
    nameSuffixesIsLoading,
    propertyClassesIsLoading,
    applicantTypesIsLoading,
    petTypesIsLoading,
    applicationIsLoading,
    guarantorOptions,
    liveInCaretakerOptions,
  } = props;

  useEffect(() => {
    actions.clean();
    actions.getAllNameSuffixes();
    actions.getAllApplicantTypes();
    actions.getAllRelationships();
    actions.getAllAffordableRelationships();
    actions.getAllPropertyClasses();
    actions.getAllPetTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    actions.getOneApplication(applicationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);

  const getApplicationFromFormValues = (values: any) => {
    const areOccupantsEqual = (occupantA, occupantB) =>
      equals(occupantA.id, occupantB.id);
    const deletedAdults = differenceWith(
      areOccupantsEqual,
      generateInitialAdults(),
      values.adults,
    );
    const deletedMinors = differenceWith(
      areOccupantsEqual,
      generateInitialMinors(),
      values.minors,
    );
    const deletedPets = differenceWith(
      areOccupantsEqual,
      generateInitialPets(),
      values.pets,
    );

    return {
      ...values,
      adults: [
        ...values.adults.map((adult) => ({
          ...adult,
          suffixId: adult.suffixId === 'default' ? undefined : adult.suffixId,
          relationship:
            adult.relationship === 'default' ? null : adult.relationship,
          deleted: false,
          affordableRelationship:
            !isAffordable || adult.affordableRelationship === 'default'
              ? undefined
              : adult.affordableRelationship,
        })),
        ...deletedAdults.map((adult) => ({
          ...adult,
          suffixId: adult.suffixId === 'default' ? undefined : adult.suffixId,
          relationship:
            adult.relationship === 'default' ? undefined : adult.relationship,
          affordableRelationship:
            !isAffordable || adult.affordableRelationship === 'default'
              ? undefined
              : adult.affordableRelationship,
          deleted: true,
        })),
      ],
      minors: [
        ...values.minors.map((minor) => ({
          ...minor,
          suffixId: minor.suffixId === 'default' ? undefined : minor.suffixId,
          relationship:
            minor.relationship === 'default' ? undefined : minor.relationship,
          affordableRelationship:
            !isAffordable || minor.affordableRelationship === 'default'
              ? undefined
              : minor.affordableRelationship,
          deleted: false,
        })),
        ...deletedMinors.map((minor) => ({
          ...minor,
          suffixId: minor.suffixId === 'default' ? undefined : minor.suffixId,
          relationship:
            minor.relationship === 'default' ? undefined : minor.relationship,
          affordableRelationship:
            !isAffordable || minor.affordableRelationship === 'default'
              ? undefined
              : minor.affordableRelationship,
          deleted: true,
        })),
      ],
      pets: [
        ...values.pets.map((pet) => ({
          ...pet,
          petTypeId: pet.petTypeId === 'default' ? undefined : pet.petTypeId,
          breedId: pet.breedId === 'default' ? undefined : pet.breedId,
          weight: pet.weight === '' ? undefined : pet.weight,
          deleted: false,
        })),
        ...deletedPets.map((pet) => ({
          ...pet,
          petTypeId: pet.petTypeId === 'default' ? undefined : pet.petTypeId,
          breedId: pet.breedId === 'default' ? undefined : pet.breedId,
          weight: pet.weight === '' ? undefined : pet.weight,
          deleted: true,
        })),
      ],
    };
  };
  const handleCancel = () => {
    const route = `/application/${applicationId}`;
    navigateToUrlWithSelectedPropertyId(route);
  };
  const getHouseholdMembersHaveChanged = (
    applicationToUpdate: Object,
  ): boolean => {
    const initialAdults = generateInitialAdults();
    const initialMinors = generateInitialMinors();

    const { adults: updatedAdults, minors: updatedMinors } =
      applicationToUpdate;

    if (initialAdults.length === updatedAdults.length) {
      if (updatedAdults.some((adult) => isNil(adult.id))) {
        return true;
      }

      if (updatedAdults.some((adult) => adult.deleted)) {
        return true;
      }
    } else {
      return true;
    }

    if (initialMinors.length === updatedMinors.length) {
      if (updatedMinors.some((adult) => isNil(adult.id))) {
        return true;
      }

      if (updatedMinors.some((adult) => adult.deleted)) {
        return true;
      }
    } else {
      return true;
    }

    return false;
  };
  const handleSubmit = (values: any) => {
    const applicationToUpdate = getApplicationFromFormValues(values);
    const haveHouseholdMembersChanged =
      getHouseholdMembersHaveChanged(applicationToUpdate);
    const hasCompletedQualifications = (
      application?.affordableQualificationHistories || []
    ).some((aqh) => !aqh.isActive);

    if (applicationToUpdate) {
      if (haveHouseholdMembersChanged && hasCompletedQualifications) {
        const confirmMessage = (
          <span>
            <BlockIcon className="icon et-alert-critical" />
            {intl.formatMessage(messages.warning)}
          </span>
        );
        const altBody = intl.formatMessage(
          messages.confirmHouseholdEditWithCompletedCertifications,
          { br: '\n' },
        );
        confirm(confirmMessage, {
          altBody,
          intl,
        })
          .then(() => actions.editApplicationHousehold(applicationToUpdate))
          .catch(() => {});
      } else {
        actions.editApplicationHousehold(applicationToUpdate);
      }
    }
  };
  const handleEditApplicant = (id: string) => {
    if (application) {
      const { applicants } = application;
      const prospect = defaultTo(
        { id: '' },
        find(pathEq(['applicantCustomer', 'isProspect'], true))(applicants),
      );
      const applicantId = defaultTo(prospect.id, id);

      const applicantToEdit = find(propEq('id', applicantId), applicants);

      if (applicantToEdit) {
        const isProspect = () =>
          and(
            not(isNil(applicantToEdit)),
            pathEq(['applicantCustomer', 'isProspect'], true)(applicantToEdit),
          );

        const isFinanciallyResponsible = () =>
          and(
            and(
              not(isNil(applicantToEdit)),
              not(isNil(path(['applicantCustomer'], applicantToEdit))),
            ),
            and(
              not(isProspect()),
              pathEq(
                ['applicantType', 'financiallyResponsible'],
                true,
              )(applicantToEdit),
            ),
          );

        const isNotFinanciallyResponsible = () =>
          and(
            and(
              not(isNil(applicantToEdit)),
              not(isNil(path(['applicantCustomer'], applicantToEdit))),
            ),
            and(not(isProspect()), not(isFinanciallyResponsible())),
          );

        if (isProspect()) {
          navigateToUrlWithSelectedPropertyId(
            `/primary-form/${applicantToEdit.applicationId}/${applicantToEdit.id}`,
          );
        }
        if (isFinanciallyResponsible()) {
          navigateToUrlWithSelectedPropertyId(
            `/non-primary-form/${applicantToEdit.applicationId}/${applicantToEdit.id}`,
          );
        }
        if (isNotFinanciallyResponsible()) {
          navigateToUrlWithSelectedPropertyId(
            `/shortApplication/${applicantToEdit.applicationId}/${applicantToEdit.id}`,
          );
        }
      }
    }
  };
  const generateInitialAdults = () => {
    const defaultToDefault = defaultTo('default');
    const isAdult = compose(
      not,
      isNil,
      pathOr(undefined, ['applicantCustomer']),
    );
    if (application) {
      return application.applicants.filter(isAdult).map((applicant) => ({
        id: applicant.id,
        firstName: applicant.applicantCustomer.customer.firstName,
        middleName: applicant.applicantCustomer.customer.middleName,
        lastName: applicant.applicantCustomer.customer.lastName,
        preferredName: applicant.applicantCustomer.customer.preferredName,
        phone: applicant.applicantCustomer.phoneNumber,
        email: applicant.applicantCustomer.emailAddress,
        type: defaultToDefault(applicant.applicantTypeId),
        relationship: defaultToDefault(applicant.relationshipId),
        affordableRelationship: defaultToDefault(
          applicant.affordableRelationshipId,
        ),
        suffixId: defaultToDefault(
          applicant.applicantCustomer.customer.suffixId,
        ),
        isProspect: applicant.applicantCustomer.isProspect,
      }));
    }

    return [];
  };
  const getPrimaryId = (adults: any) => {
    return pathOr(
      '',
      ['id'],
      adults.find((a) => a.isProspect),
    );
  };
  const generateInitialMinors = () => {
    const defaultToDefault = defaultTo('default');
    const isMinor = compose(not, isNil, pathOr(undefined, ['applicantMinor']));
    if (application) {
      return application.applicants.filter(isMinor).map((applicant) => ({
        id: applicant.id,
        firstName: applicant.applicantMinor.firstName,
        middleName: applicant.applicantMinor.middleName,
        lastName: applicant.applicantMinor.lastName,
        preferredName: applicant.applicantMinor.preferredName,
        age: applicant.applicantMinor.age,
        relationship: defaultToDefault(applicant.relationshipId),
        affordableRelationship: defaultToDefault(
          applicant.affordableRelationshipId,
        ),
        suffixId: defaultToDefault(applicant.applicantMinor.suffixId),
      }));
    }

    return [];
  };
  const generateInitialPets = () => {
    const defaultToDefault = defaultTo('default');
    const isPet = compose(not, isNil, pathOr(undefined, ['applicantPet']));
    if (application) {
      return application.applicants.filter(isPet).map((applicant) => ({
        id: applicant.id,
        name: applicant.applicantPet.name,
        weight: applicant.applicantPet.weight,
        petTag: applicant.applicantPet.petTag,
        isServiceAnimal: applicant.applicantPet.isServiceAnimal,
        petTypeId: defaultToDefault(applicant.applicantPet.petTypeId),
        breedId: defaultToDefault(applicant.applicantPet.breedId),
      }));
    }

    return [];
  };

  const adults = generateInitialAdults();
  const minors = generateInitialMinors();
  const pets = generateInitialPets();
  const primaryApplicantId = getPrimaryId(adults);
  // $FlowFixMe
  const lease = pathOr({}, ['au', 'lease'], application);
  const { leaseExecuted, leaseSentToPortal } = getSentAndExecutedStatus(lease);

  const isLoading =
    relationshipsIsLoading ||
    affordableRelationshipsIsLoading ||
    nameSuffixesIsLoading ||
    propertyClassesIsLoading ||
    applicantTypesIsLoading ||
    petTypesIsLoading ||
    applicationIsLoading;

  if (isLoading) {
    return (
      <Spinner
        sx={{
          height: '100vh',
          width: '100%',
          position: 'fixed',
          zIndex: 1,
        }}
      />
    );
  }

  return (
    <DocumentTitle title="Fortress - Edit Household">
      {application && (
        <EditApplicationHouseholdForm
          initialValues={{
            id: application.id,
            adults,
            minors,
            pets,
            propertyId,
            primaryApplicantId: primaryApplicantId,
          }}
          pageTitle={`${intl.formatMessage(messages.title)} ${applicantNames}`}
          suffixList={nameSuffixes}
          relationshipList={relationships}
          minorRelationshipList={minorRelationship}
          affordableRelationship={affordableRelationship}
          applicantTypeList={applicantTypes}
          propertyClassList={propertyClasses}
          handleCancel={handleCancel}
          onSubmit={handleSubmit}
          petTypeList={petTypes}
          isHouseholdSubmitting={isHouseholdSubmitting}
          handleEditHouseholdMember={handleEditApplicant}
          isAffordable={isAffordable}
          primaryApplicantId={primaryApplicantId}
          leaseExecuted={leaseExecuted}
          leaseSentToPortal={leaseSentToPortal}
          guarantorOptions={guarantorOptions}
          liveInCaretakerOptions={liveInCaretakerOptions}
        />
      )}
    </DocumentTitle>
  );
};

export const mapStateToProps = (
  state: GlobalState,
  ownProps: Object,
): StateProps => {
  const { languageProvider, editApplicationHousehold } = state;
  const propertyClassType = getSelectedPropertyClassType(state);
  const application = editApplicationHousehold.currentApplication;
  const isHouseholdSubmitting = editApplicationHousehold.isHouseholdSubmitting;
  const applicationIsLoading = editApplicationHousehold.applicationIsLoading;
  // $FlowFixMe
  const applicantPropertyClass = pathOr(
    '',
    ['propertyClass', 'name'],
    application,
  );
  return {
    applicationId: ownProps.match.params.applicationId,
    application,
    propertyId: getPropertyId(state),
    applicantNames: getApplicantNames(state),
    nameSuffixes: getNameSuffixOptions(state),
    propertyClasses: getApplicablePropertyClassOptions(state),
    relationships: getRelationshipOptions(state),
    minorRelationship: getMinorRelationshipOptions(state),
    affordableRelationship: getAffordableRelationshipOptions(state),
    applicantTypes: getApplicantTypeOptions(state),
    petTypes: getPetTypeOptions(state),
    relationshipsIsLoading: state.app.relationshipsIsLoading,
    affordableRelationshipsIsLoading:
      state.app.affordableRelationshipsIsLoading,
    nameSuffixesIsLoading: state.app.nameSuffixesIsLoading,
    propertyClassesIsLoading: state.app.propertyClassesIsLoading,
    applicantTypesIsLoading: state.app.applicantTypesIsLoading,
    petTypesIsLoading: state.app.petTypesIsLoading,
    applicationIsLoading,
    isHouseholdSubmitting,
    isAffordable:
      propertyClassType === 'Affordable' ||
      (propertyClassType === 'Mixed' &&
        applicantPropertyClass === 'Affordable'),
    // $FlowFixMe
    primaryApplicantId: editApplicationHousehold.primaryApplicantId,
    locale: languageProvider.locale,
    guarantorOptions: getGuarantorOptions(state),
    liveInCaretakerOptions: getLiveInCaretakerOptions(state),
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  const actions = bindActionCreators(
    {
      ...editApplicationHouseholdActions,
      getAllNameSuffixes,
      getAllPropertyClasses,
      getAllApplicantTypes,
      getAllRelationships,
      getAllAffordableRelationships,
      getAllPetTypes,
    },
    dispatch,
  );
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EditApplicationHousehold));
