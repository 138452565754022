import { defineMessages } from 'react-intl';

export default defineMessages({
  addInsurance: {
    id: 'App.Household.InsuranceSection.InsuranceModal.AddInsurance',
    defaultMessage: 'Add Insurance',
  },
  allFieldsAreRequired: {
    id: 'App.Household.InsuranceSection.InsuranceModal.AllFieldsAreRequired',
    defaultMessage: 'All Fields are Required',
  },
  cancel: {
    id: 'App.Household.InsuranceSection.InsuranceModal.Cancel',
    defaultMessage: 'Cancel',
  },
  saveChanges: {
    id: 'App.Household.InsuranceSection.InsuranceModal.SaveChanges',
    defaultMessage: 'Save Changes',
  },
  viewInsurance: {
    id: 'App.Household.InsuranceSection.InsuranceModal.ViewInsurance',
    defaultMessage: 'View Insurance',
  },
  seeHistory: {
    id: 'App.Household.InsuranceSection.InsuranceModal.SeeHistory',
    defaultMessage: 'See History',
  },
  stopCoverage: {
    id: 'App.Household.InsuranceSection.InsuranceModal.StopCoverage',
    defaultMessage: 'Stop Coverage',
  },
  editRenewCoverage: {
    id: 'App.Household.InsuranceSection.InsuranceModal.EditRenewCoverage',
    defaultMessage: 'Edit/Renew Coverage',
  },
  stopCoveragePrompt: {
    id: 'App.Household.InsuranceSection.InsuranceModal.StopCoveragePrompt',
    defaultMessage:
      'Are you sure you want to stop coverage with {providerName}?',
  },
  stopCoverageMessage: {
    id: 'App.Household.InsuranceSection.InsuranceModal.StopCoverageMessage',
    defaultMessage:
      'In order to continue, please select a new Policy End Date:',
  },
  policyEndDate: {
    id: 'App.Household.InsuranceSection.InsuranceModal.PolicyEndDate',
    defaultMessage: 'Policy End Date',
  },
  endDateBeforeStartDateError: {
    id: 'App.Household.InsuranceSection.InsuranceModal.EndDateBeforeStartDateError',
    defaultMessage:
      'Policy End Date cannot be prior to the Policy Start Date of {policyStartDate}',
  },
  confirm: {
    id: 'App.Household.InsuranceSection.InsuranceModal.Confirm',
    defaultMessage: 'Confirm',
  },
});
