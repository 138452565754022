import { put, takeLatest, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import { GET_ALL_DEPOSITS } from './constants';
import { getAllDepositsSuccess, getAllDepositsError } from './actions';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId
} from '../App/selectors';
import { renderNoDataToastr } from '../../utils/redux-form-helper';
import DepositsService from '../../services/depositsService';
import type { Action, Property } from '../App/types';
import type { DepositRequest } from './types';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchAllDeposits({
  payload
}: Action<DepositRequest>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const depositsService = new DepositsService();
    const response = yield depositsService.getDeposits(
      organizationId,
      selectedProperty.id,
      payload.period
    );
    if (response.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(getAllDepositsSuccess(response));
  } catch (err) {
    yield put(getAllDepositsError(err));
  }
}

export function* getAllDepositsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_DEPOSITS, fetchAllDeposits);
}

export default [getAllDepositsSaga];
