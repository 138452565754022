import {
  EDIT_LEASE,
  EXECUTE_LEASE,
  GET_SIGNATURE_STATUSES,
  GET_SIGNATURE_STATUSES_SUCCESS,
  GET_SIGNATURE_STATUSES_ERROR,
  SEND_TO_PORTAL,
  UPLOAD_LEASE,
} from './constants';
import type { Action } from '../App/types';

export function editLease(
  leaseId: string,
  profileId: string,
  isResident: boolean,
  prospectId?: string = '',
  applicationId?: string = '',
  isCancel?: boolean = false,
  setIsEditLeaseMode?: Function,
): Action<any> {
  return {
    type: EDIT_LEASE,
    payload: {
      applicationId,
      leaseId,
      isCancel,
      isResident,
      profileId,
      prospectId,
      setIsEditLeaseMode,
    },
  };
}

export function uploadLease(
  applicationId: string,
  file: any,
  leaseId: string,
  notes: string,
  profileId: string,
  isResident: boolean,
): Action<any> {
  return {
    type: UPLOAD_LEASE,
    payload: {
      applicationId,
      file,
      leaseId,
      notes,
      profileId,
      isResident,
    },
  };
}

export function sendToPortal(
  leaseId: string,
  profileId: string,
  isResident: boolean,
  prospectId: string,
): Action<any> {
  return {
    type: SEND_TO_PORTAL,
    payload: {
      leaseId,
      profileId,
      isResident,
      prospectId,
    },
  };
}

export function executeLease(
  applicationId: string,
  leaseId: string,
  profileId: string,
  isResident: boolean,
  prospectId: string,
  signature: string,
  hideModalCb: Function,
): Action<any> {
  return {
    type: EXECUTE_LEASE,
    payload: {
      applicationId,
      leaseId,
      profileId,
      isResident,
      signature,
      hideModalCb,
      prospectId,
    },
  };
}

export function getSignatureStatuses(leaseId: string): Action<any> {
  return {
    type: GET_SIGNATURE_STATUSES,
    payload: {
      leaseId,
    },
  };
}

export function getSignatureStatusesSuccess(
  signaturesInfo: Object,
): Action<any> {
  return {
    type: GET_SIGNATURE_STATUSES_SUCCESS,
    payload: {
      signaturesInfo,
    },
  };
}

export function getSignatureStatusesError(err: Object): Action<any> {
  return {
    type: GET_SIGNATURE_STATUSES_ERROR,
    payload: {
      err,
    },
  };
}
