import { defineMessages } from 'react-intl';

const messages = defineMessages({
  adultRadioButtonLabel: {
    id:
      'App.LeaseApplicantionPrimaryForm.AdditionalOccupantsSection.AdultRadioButtonLabel',
    defaultMessage: 'Adults (Over 18)'
  },
  minorRadioButtonLabel: {
    id:
      'App.LeaseApplicantionPrimaryForm.AdditionalOccupantsSection.MinorRadioButtonLabel',
    defaultMessage: 'Minors (Under 19)'
  },
  note: {
    id: 'App.LeaseApplicantionPrimaryForm.AdditionalOccupantsSection.Note',
    defaultMessage:
      'Note:  All occupants over 18 must complete separate lease applications.'
  }
});

export default messages;
