import { FormattedMessage } from 'react-intl';
import messages from './messages';

const ProrateCalculatorDetailsTableFooter = ({ totalProrateDue, addRow }) => (
  <tr>
    <td colSpan={2}>
      <a
        onClick={() => {
          addRow();
        }}
      >
        <strong>
          <FormattedMessage {...messages.addNewTransaction} />
        </strong>
      </a>
    </td>
    <td></td>
    <td className="text-right">
      <FormattedMessage {...messages.totalProrateDue} />
    </td>
    <td className="text-center">
      <span className="padleft10">${totalProrateDue}</span>
    </td>
    <td colSpan={2}></td>
  </tr>
);

export default ProrateCalculatorDetailsTableFooter;
