import React, { useMemo } from 'react';
import { ModalConfirm as Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  Box,
  Typography,
  SimpleCheckbox as Checkbox,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

import generalMessages from '../../../../../App/messages';
import componentMessages from './messages';
import useFormatMessage from '../../../../../../hooks/useFormatMessage';
import { getOptions } from './constants';

const messages = { ...generalMessages, ...componentMessages };

const ConfirmationModal = ({ isOpen, closeModal, confirm, propertyName }) => {
  const formatMessage = useFormatMessage();
  const title = formatMessage(messages.title);
  const options = useMemo(
    () => getOptions(formatMessage, propertyName),
    [propertyName, formatMessage],
  );
  const [checkedOptions, setCheckedOptions] = React.useState([]);
  const reset = () => {
    setCheckedOptions([]);
    closeModal();
  };
  const handleChange = (changedOption) => {
    const checkedOptionsIds = checkedOptions.map((co) => co.id);
    if (checkedOptionsIds.includes(changedOption.id)) {
      setCheckedOptions(
        checkedOptions.filter((co) => co.id !== changedOption.id),
      );
    } else {
      setCheckedOptions([...checkedOptions, changedOption]);
    }
  };
  const handleCancel = () => {
    reset();
  };
  const handleConfirm = () => {
    confirm();
    reset();
  };
  const actionsProps = [
    {
      variant: 'text',
      onClick: handleCancel,
      children: formatMessage(messages.cancel),
    },
    {
      variant: 'primary',
      onClick: handleConfirm,
      disabled: checkedOptions.length !== options.length,
      children: formatMessage(messages.confirm),
    },
  ];
  return (
    <Modal
      open={isOpen}
      actionsProps={actionsProps}
      onClose={reset}
      title={title}
    >
      <Box sx={{ width: 480 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="body1">
            {formatMessage(messages.intro)}
          </Typography>
        </Box>
        {options.map((option) => {
          return (
            <>
              <Box
                key={option.id}
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 1,
                  mb: 2,
                }}
                onChange={() => handleChange(option)}
              >
                <Checkbox />
                <Typography variant="body1">{option.textContent}</Typography>
              </Box>
            </>
          );
        })}
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;
