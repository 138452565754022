import * as ActionTypes from './constants';

import type { Action } from '../App/types';
import type { Prospect } from '../ProspectProfile/types';
import type { Application } from './types';

export function getOneProspect(prospectId: string): Action<string> {
  return {
    type: ActionTypes.GET_ONE_PROSPECT,
    payload: prospectId,
  };
}

export function getOneProspectSuccess(prospect: Prospect): Action<Prospect> {
  return {
    type: ActionTypes.GET_ONE_PROSPECT_SUCCESS,
    payload: prospect,
  };
}

export function getOneProspectError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ONE_PROSPECT_ERROR,
    payload: error,
  };
}

export function createApplication(application: any): Action<Application> {
  return {
    type: ActionTypes.CREATE_APPLICATION,
    payload: application,
  };
}

export function createApplicationSuccess(): Action<any> {
  return {
    type: ActionTypes.CREATE_APPLICATION_SUCCESS,
    payload: undefined,
  };
}

export function createApplicationError(error: Error): Action<Error> {
  return {
    type: ActionTypes.CREATE_APPLICATION_ERROR,
    payload: error,
  };
}

export function clean(): Action<any> {
  return {
    type: ActionTypes.CLEAN,
    payload: undefined,
  };
}

export function getApplicationFees(numAdults: number): Action<any> {
  return {
    type: ActionTypes.GET_APPLICATION_FEES,
    payload: numAdults,
  };
}

export function getApplicationFeesSuccess(fees: Object): Action<any> {
  return {
    type: ActionTypes.GET_APPLICATION_FEES_SUCCESS,
    payload: fees,
  };
}

export function getApplicationFeesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_APPLICATION_FEES_ERROR,
    payload: error,
  };
}
