import { call, put, select, takeLatest, throttle } from 'redux-saga/effects';
import { reset } from 'redux-form';
import { push } from 'react-router-redux';
import { omit } from 'ramda';
import { actions as toastrActions } from 'react-redux-toastr';

import ProspectProfileService from '../../services/prospectProfileService';

import * as ActionTypes from './constants';
import * as editProspectHouseholdActions from './actions';
import * as peopleProfileActions from './actions';
import messages from './messages';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';

import type { Saga } from 'redux-saga';
import type { Action } from '../App/types';
import type { Prospect } from '../ProspectProfile/types';

export function* fetchGetOneProspect(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const prospectsService = new ProspectProfileService();
    const response = yield call(
      prospectsService.getOne,
      action.payload,
      selectedProperty.id,
      organizationId,
    );
    yield put(editProspectHouseholdActions.getOneProspectSuccess(response));
  } catch (err) {
    yield put(editProspectHouseholdActions.getOneProspectError(err));
  }
}

export function* getOneProspectSaga(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ONE_PROSPECT, fetchGetOneProspect);
}

export function* putUpdateProspect({
  payload: prospect,
}: Action<Prospect>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    if (!prospect.id) {
      throw new Error('Cannot update a prospect without an id.');
    }
    const prospectService = new ProspectProfileService();
    const editPayload = omit(
      [
        'createdAt',
        'updatedAt',
        'assignedTo',
        'currentProspectStatus',
        'tourStatus',
        'prospectStatusChangeLog',
        'referralType',
        'isOptedOut',
      ],
      prospect,
    );
    yield call(prospectService.update, editPayload, organizationId);
    yield put(editProspectHouseholdActions.editHouseholdSuccess());
    yield put(reset('editHousehold'));
    const urlToNavigateTo = getUrlWithSelectedPropertyId(
      `/prospect/${prospect.id}`,
    );
    yield put(push(urlToNavigateTo));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(peopleProfileActions.editHouseholdError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* updateProspect(): Saga<void> {
  yield throttle(500, ActionTypes.EDIT_PROSPECT_HOUSEHOLD, putUpdateProspect);
}

export default [getOneProspectSaga, updateProspect];
