import { forEachObjIndexed } from 'ramda';

import type { Prospect } from '../containers/ProspectProfile/types';
import type { FilterValue, OrderValue } from '../containers/App/types';
import { put, apiURL } from '../utils/api';

const request = require('../utils/api').default;

class ProspectProfileService {
  save(prospect: Prospect, organizationId: string) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(prospect),
    };
    return request(
      `${apiURL}/${organizationId}/${prospect.propertyId}/prospects`,
      options,
    );
  }
  update(prospect: Prospect, organizationId: string) {
    const id = prospect.id || '';
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(prospect),
    };
    return request(
      `${apiURL}/${organizationId}/${prospect.propertyId}/prospects/${id}`,
      options,
    );
  }

  assign(prospect: Prospect, organizationId: string) {
    const id = prospect.id || '';

    const body = JSON.stringify({
      id: prospect.id,
      assignedToId: prospect.assignedToId,
      propertyId: prospect.propertyId,
    });

    return put(
      `/${organizationId}/${prospect.propertyId}/prospects/${id}/assignee`,
      body,
    );
  }
  changeStatus(
    prospect: Prospect,
    prospectStatusId: string,
    notes: string,
    organizationId: string,
  ) {
    const id = prospect.id || '';
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prospectStatusId,
        notes,
      }),
    };
    return request(
      `${apiURL}/${organizationId}/${prospect.propertyId}/prospects/${id}/status`,
      options,
    );
  }
  getAllProspects({ organizationId, propertyId, getAllStatuses = false }) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    const urlSearchParams = new URLSearchParams({
      pagination: false,
      getAllStatuses,
    });

    return request(
      `${apiURL}/${organizationId}/${propertyId}/prospects?${urlSearchParams}`,
      options,
    );
  }
  getAll(
    pageNumber: number,
    limit: number,
    filterValue: FilterValue,
    sorting: OrderValue,
    searchText: string,
    propertyId: string,
    organizationId: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const filters: Array<string> = [];
    forEachObjIndexed((valueFilter, keyFilter) => {
      forEachObjIndexed((valueOption, keyOption) => {
        if (valueOption) {
          filters.push(`${keyFilter}=${keyOption}`);
        }
      }, valueFilter);
    }, filterValue);
    const sort =
      sorting.fieldName === ''
        ? ''
        : `&sort=${sorting.fieldName}&order=${sorting.order}`;
    const query = searchText === '' ? '' : `&query=${searchText}`;
    const queryString = `page=${pageNumber}&limit=${limit}${query}${sort}&${filters.join(
      '&',
    )}`;
    return request(
      `${apiURL}/${organizationId}/${propertyId}/prospects?${queryString}`,
      options,
    );
  }
  getOne(prospectId: string, propertyId: string, organizationId: string) {
    const id = prospectId;
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/prospects/${id}`,
      options,
    );
  }
  getByPhoneNumber(
    phoneNumber: string,
    propertyId: string,
    organizationId: string,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const queryString = `query=${phoneNumber}`;
    return request(
      `${apiURL}/${organizationId}/${propertyId}/prospects?${queryString}`,
      options,
    );
  }
}

export default ProspectProfileService;
