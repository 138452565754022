import React from 'react';
import moment from 'moment';

import { sortAndFilterRows } from '../../../../utils';
import { download } from '../../../../utils/downloadFile.js';

import { COMPLIANCE_APPROVAL_STATUSES } from '../../../../utils/affordable';
import { pathOr } from 'ramda';
import { appendFilterTextToCSV } from '../../../../utils/csv-helpers';

export const getStatus = (isStarted: boolean, complianceStatus: string) => {
  if (isStarted && complianceStatus) {
    return complianceStatus;
  } else if (isStarted && !complianceStatus) {
    return 'Started';
  } else {
    return 'Not Started';
  }
};

export const isRecertLate = (
  recertDue: string = '',
  voucherEffectiveDate?: string = '',
): boolean => {
  const today = moment().startOf('day');
  const effectiveDateMoment = moment(voucherEffectiveDate);
  const recertDueMoment = moment(recertDue);
  if (!effectiveDateMoment.isValid() && !recertDueMoment.isValid()) {
    return false;
  }

  const lastDayToCertify = effectiveDateMoment.isValid()
    ? effectiveDateMoment
    : recertDueMoment;
  lastDayToCertify.subtract(1, 'months').endOf('month');

  return today.isAfter(lastDayToCertify);
};

export const getRecertDueStatus = (
  recertDue: string,
  voucherEffectiveDate?: string,
  isCSV = false,
) => {
  const recertDueDate = moment(recertDue).format('MM/DD/YYYY');
  const isLate = isRecertLate(recertDue, voucherEffectiveDate);
  if (isCSV && isLate) {
    return `${moment.utc(recertDue).format('MM/DD/YYYY')} (Late)`;
  }
  if (isCSV) {
    return moment.utc(recertDue).format('MM/DD/YYYY');
  }
  if (isLate) {
    return <span style={{ color: 'red' }}>{recertDueDate}</span>;
  }
  return recertDueDate;
};

export const getRecertStatus = (recertStatus: string) => {
  const recertStatusMap = Object.freeze({
    [COMPLIANCE_APPROVAL_STATUSES.APPROVED]: (
      <span className={'status status-ball--small status-green'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.DENIED]: (
      <span className={'status status-ball--small status-red'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.ON_NOTICE_HOLD]: (
      <span className={'status status-ball--small status-red'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.CORRECTION_NEEDED]: (
      <span className={'status status-ball--small status-purple'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.CORRECTION_NEEDED_FINAL]: (
      <span className={'status status-ball--small status-dark-purple'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.APPROVED_PENDING_SIGN]: (
      <span className={'status status-ball--small status-blue'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.PENDING]: (
      <span className={'status status-ball--small status-orange'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.PENDING_FINAL_APPROVAL]: (
      <span className={'status status-ball--small status-dark-orange'} />
    ),
    [COMPLIANCE_APPROVAL_STATUSES.STARTED]: <span>Started</span>,
    [COMPLIANCE_APPROVAL_STATUSES.COMPLETE]: <span>Complete</span>,
    [COMPLIANCE_APPROVAL_STATUSES.NOT_STARTED]: <span>---</span>,
  });
  return pathOr('', [recertStatus], recertStatusMap);
};

export const parseRecertRowsForCSV =
  (rowheaders, sort, hasFilters) => (data) => {
    const headers = rowheaders
      .filter((header) => header.id !== 'view')
      .map((header) => header.title.props.defaultMessage)
      .join(',');
    const recerts = sort
      ? sortAndFilterRows(data.results, sort.fieldName, sort.order)
      : data.results;
    const rows = recerts.map((recert) => {
      const {
        recertStatus,
        programName,
        unit,
        floorPlan,
        headOfHousehold,
        moveInDate,
        leaseStartDate,
        lastCertified,
        recertDue,
        occupiedOnNTV,
        doNotRenew,
        underEviction,
        balance,
        notice120,
        notice90,
        notice60,
        notice30,
        voucherEffectiveDate,
        certificationNotes,
      } = recert;
      const complianceDocumentName = getComplianceDocumentName(recert);
      const finalFields = [
        recertStatus,
        programName,
        ` ${unit}`,
        `="${floorPlan}"`,
        headOfHousehold,
        moveInDate,
        leaseStartDate,
        lastCertified ? moment.utc(lastCertified).format('MM/DD/YYYY') : '---',
        getRecertDueStatus(recertDue, voucherEffectiveDate, true),
        occupiedOnNTV ? 'Yes' : '',
        doNotRenew ? 'Yes' : '',
        underEviction ? 'Yes' : '',
        balance,
        notice120 ? 'Yes' : '',
        notice90 ? 'Yes' : '',
        notice60 ? 'Yes' : '',
        notice30 ? 'Yes' : '',
        complianceDocumentName,
        certificationNotes ?? '',
      ];
      return finalFields.join(',');
    });

    const csv = appendFilterTextToCSV({
      headers,
      rows,
      hasAnyFilters: hasFilters,
    });

    download(csv, 'ManageRecertifications.csv', 'text/csv;charset=utf-8');
  };

export const getComplianceDocumentName = (certification) => {
  const { complianceDocument, complianceDocumentDate } = certification;
  const complianceDocumentName = complianceDocument
    ? `${complianceDocument} ${moment(complianceDocumentDate)
        .format('MM/DD/YYYY h:mm A')
        .toLocaleString()}`
    : '';
  return complianceDocumentName;
};

export default {
  isRecertLate,
};
