import React from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { partialRight } from 'ramda';

type Props = {
  handleInputTextChange: Function,
  smsText: string,
  smsTitle: string,
  communicationBatchGenerated: boolean,
  selectedResidents: Array<Object>,
  isLoading: boolean,
  createdAt: Date,
  isViewCommunication: boolean,
  metricsInformation: Object,
};

const SMSInput = (props: Props) => {
  const recipientsLength = Array.isArray(props.selectedResidents)
    ? props.selectedResidents.length
    : 0;
  const outOfText =
    props.metricsInformation.successRecipients !== recipientsLength
      ? ` out of ${recipientsLength}`
      : '';
  const isEditing =
    !props.isViewCommunication && !props.communicationBatchGenerated;
  const maxMessageLength = 160;
  return (
    <div className="panel unit-information panel-withheader">
      <div className="panel-head">
        <Row>
          <Col xs={12} sm={6}>
            <h2>Text (SMS)</h2>
          </Col>
        </Row>
      </div>
      <div className="panel__details">
        <Row>
          <Col xs={6} sm={3} md={2} className="padtop20">
            <label>
              <strong>Title</strong>
            </label>
          </Col>
          <Col xs={12} sm={6} md={8} className="padtop10 form-group">
            <Row>
              <input
                className="form-control input-md"
                maxLength={100}
                value={props.smsTitle}
                onChange={partialRight(props.handleInputTextChange, [
                  'smsTitle',
                ])}
                disabled={!isEditing || props.isLoading}
              />
            </Row>
            <Row className="text-center padbottom20 ">
              <small className="text-gray--darker">
                Title for documentation only and is not included in sent
                message.
              </small>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={3} md={2} className="padtop20">
            <label>
              <strong>Text Message</strong>
            </label>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={8}
            className="padtop10 padbottom30 form-group"
          >
            <Row>
              <textarea
                className="form-control input-lg"
                rows="6"
                maxLength={maxMessageLength}
                value={props.smsText}
                onChange={partialRight(props.handleInputTextChange, [
                  'smsText',
                ])}
                disabled={!isEditing || props.isLoading}
              />
            </Row>
            {isEditing && (
              <Row className="text-center">
                <p>
                  {props.smsText ? props.smsText.length : 0} /{' '}
                  {maxMessageLength}
                </p>
                <small className="text-gray--darker">
                  {`Text Messages are limited to ${maxMessageLength} characters
                  (including spaces) due to phone carrier restrictions.`}
                </small>
              </Row>
            )}
          </Col>
        </Row>
        {!isEditing && (
          <Row>
            <Col md={12}>
              <div className="alert alert-success">
                {`Text message has been sent to ${
                  props.metricsInformation.successRecipients
                }${outOfText} recipients on ${moment(props.createdAt).format(
                  'dddd, MMM D, YYYY, LT',
                )} GMT-5`}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default SMSInput;
