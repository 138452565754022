import Joi from 'joi';

export const FormSchema = Joi.object({
  isResidentPortalActive: Joi.boolean().required(),
  sendRegistrationEmails: Joi.boolean(),
  isElectronicLeaseSigningActive: Joi.boolean(),
  isUnitSelectionEnabled: Joi.boolean().optional(),
  propertySettingApplyWithoutAUnit: Joi.boolean(),
  applyWithoutUnit: Joi.boolean().when('isResidentPortalActive', {
    is: true,
    then: Joi.when('isUnitSelectionEnabled', {
      is: true,
      then: Joi.when('propertySettingApplyWithoutAUnit', {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional().allow(null),
      }),
      otherwise: Joi.optional().allow(null),
    }),
    otherwise: Joi.optional().allow(null),
  }),
}).required();
