import { defineMessages } from 'react-intl';

const messages = defineMessages({
  submitButton: {
    id: 'App.CreateCommercialTenant.submitButton',
    defaultMessage: 'Move-in',
  },
  invalidDate: {
    id: 'App.CreateCommercialTenant.InvalidDate',
    defaultMessage: 'Invalid date',
  },
  moveInDateInFuture: {
    id: 'App.CreateCommercialTenant.MoveInDateInFuture',
    defaultMessage: 'Move-in Date cannot be in the future.',
  },
  moveInDateInPast: {
    id: 'App.CreateCommercialTenant.MoveInDateInPast',
    defaultMessage: 'Move-in Date cannot be more than 30 days in the past.',
  },
  moveInDateInPastYear: {
    id: 'App.CreateCommercialTenant.MoveInDateInPastYear',
    defaultMessage: 'Move-in Date cannot be more than 1 year in the past.',
  },
  moveInDateOverlap: {
    id: 'App.CreateCommercialTenant.moveInDateOverlap',
    defaultMessage: 'Move-in Date cannot overlap with the previous resident.',
  },
  moveInLessThanStartDate: {
    id: 'App.CreateCommercialTenant.MoveInLessThanStartDate',
    defaultMessage: 'Move-in Date cannot be before lease start date.',
  },
  beforeFiscalPeriod: {
    id: 'App.CreateCommercialTenant.beforeFiscalPeriod',
    defaultMessage:
      'You cannot select a move-in date that is in a prior/closed fiscal period.',
  },
});

export default messages;
