
import formMessages from '../../../containers/ShortLeaseApplication/messages';
import {
  validateName,
} from '../../../utils/validations';

import type { MaritalInformation } from '../../../containers/App/types';

const validate = (values: MaritalInformation, { intl }: Object) => {
  const errors = {};

  ['firstName', 'middleName', 'lastName', 'preferredName'].forEach((fieldName) => {
    if (!errors[fieldName] && values[fieldName] && !validateName(values[fieldName])) {
      errors[fieldName] = intl.formatMessage(formMessages.invalidValue);
    }
  });

  return errors;
};

export default validate;
