import { useEffect, useState } from 'react';
import { pathOr } from 'ramda';

import AffordableService from '../../../../../services/affordableService';

import { parseDropdownsOptionsWithCodesAndNames } from '../../../../AffordableQualificationTab/Shared/utils';

export const useAsyncCorrectionCodeOptions = (selectedProperty: Object) => {
  const [correctionCodeOptions, setCorrectionCodeOptions] = useState([]);

  const selectedPropertyId = pathOr(null, ['id'], selectedProperty);
  const organizationId = pathOr(null, ['organizationId'], selectedProperty);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchCorrectionOptions = async () => {
      if (organizationId && selectedPropertyId) {
        const options = { signal: abortController.signal };
        const affordableService = new AffordableService();

        const correctionCodesResponse =
          await affordableService.getAffordableCorrectionCodes(
            organizationId,
            selectedPropertyId,
            options,
          );

        const parsedCorrectionCodes = parseDropdownsOptionsWithCodesAndNames(
          correctionCodesResponse,
        );
        if (subscribed) {
          setCorrectionCodeOptions(parsedCorrectionCodes);
        }
      }
    };

    fetchCorrectionOptions();

    return () => {
      subscribed = false;
    };
  }, [organizationId, selectedPropertyId]);

  return correctionCodeOptions;
};
