import { LOCATION_CHANGE } from 'react-router-redux';

import type { Action } from '../App/types';
import type { CreateUserState } from './types';
import * as ActionTypes from './constants';

const initialState: CreateUserState = {
  userStatuses: [],
  userTitles: [],
  userRoles: [],
  properties: [],
  selectedProperties: [],
  selectedPropertyGroups: [],
  hideMultiselect: false,
  nonePropertiesSelected: false,
  isSubmitting: false,
  user: {
    allProperties: false,
  },
};

const createUserReducer = (
  state: CreateUserState = initialState,
  action: Action<*, *>,
): CreateUserState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ALL_USER_STATUSES_SUCCESS: {
      return { ...state, userStatuses: payload };
    }
    case ActionTypes.GET_ALL_USER_TITLES_SUCCESS: {
      return { ...state, userTitles: payload };
    }
    case ActionTypes.GET_ALL_USER_ROLES_SUCCESS: {
      return { ...state, userRoles: payload };
    }
    case ActionTypes.GET_ALL_PROPERTIES_SUCCESS: {
      return { ...state, properties: payload };
    }
    case ActionTypes.NONE_PROPERTIES_SELECTED: {
      return { ...state, nonePropertiesSelected: payload };
    }
    case ActionTypes.SET_SELECTED_PROPERTIES: {
      return { ...state, selectedProperties: payload };
    }
    case ActionTypes.SET_SELECTED_PROPERTY_GROUPS: {
      return { ...state, selectedPropertyGroups: payload };
    }
    case ActionTypes.SET_USER_ALL_PROPERTIES: {
      return {
        ...state,
        user: {
          ...state.user,
          allProperties: payload,
        },
      };
    }
    case ActionTypes.TOGGLE_MULTISELECT: {
      return { ...state, hideMultiselect: payload };
    }
    case ActionTypes.CREATE_USER: {
      return { ...state, isSubmitting: true };
    }
    case ActionTypes.CREATE_USER_FAIL:
    case ActionTypes.CREATE_USER_SUCCESS: {
      return { ...state, isSubmitting: false };
    }
    case LOCATION_CHANGE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default createUserReducer;
