import React from 'react';
import { IntlShape, injectIntl, FormattedMessage } from 'react-intl';
import ElementWithPermissions from '../../components/ElementWithPermissions';
import messages from './messages';
import {
  Box,
  Button,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';

type Props = {
  isLoading: boolean,
  hasHud: boolean,
  openHudLimitsEdit: Function,
  intl: IntlShape,
};

function HudLimitsBanner(props: Props) {
  if (props.hasHud) {
    return (
      <Grid
        item
        directin="row"
        xs={4}
        paddingRight={5}
        sx={{ display: 'flex', justifyContent: 'end' }}
      >
        <Box
          sx={{
            position: 'relative',
            borderRadius: 0,
          }}
        >
          <ElementWithPermissions scope={['hud-limits-edit']}>
            {
              <Button
                variant={'primarySubtle'}
                onClick={props.openHudLimitsEdit}
                type="button"
              >
                <FormattedMessage {...messages.changeHudLimits} />
              </Button>
            }
          </ElementWithPermissions>
        </Box>
      </Grid>
    );
  } else return null;
}

export default injectIntl(HudLimitsBanner);
