import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import {
  YesNoColumns,
  IncomeAssetCurrencyField,
  InterestRateField,
} from '../../SharedFormFields';
import messages from '../messages';
import { renderTextField } from '../../../../utils/redux-form-helper';
import { ALT_FORM_FIRST_COL_SECTION, ALT_FORM_SECOND_COL_SECTION } from '..';
import { ExpandableRetirementRows } from './ExpandableRetirementRows';

export const AlternateRetirementPlanRows = ({
  assets,
  fields,
  noClicked,
  isPrior,
  handleAdd,
  calculateCashValue,
  calculateAnnualIncome,
  withRowNumber = false,
}: Object) => {
  return (
    <div className="row faux-table__row">
      <div className="col-xs-5">
        <div className="row padbottom10">
          <div className="col-xs-5">
            <div className="row">
              {withRowNumber && (
                <div className="col-xs-3">
                  <span>9</span>
                </div>
              )}
              <div className="col-xs-9">
                <FormattedMessage {...messages.retirementPlanAccount} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-7" style={{ height: '50px' }}></div>
      {assets.map((asset) => {
        const disabled = fields[asset.name] !== 'yes';
        const expandable = asset?.expandable ?? false;

        return expandable ? (
          <ExpandableRetirementRows
            key={asset.name}
            asset={asset}
            fields={fields}
            isPrior={isPrior}
            assetType={asset.type}
            assetName={asset.name}
            handleAdd={handleAdd}
            noClicked={noClicked}
            calculateCashValue={calculateCashValue}
            calculateAnnualIncome={calculateAnnualIncome}
          />
        ) : (
          <React.Fragment key={asset.name}>
            <div className={ALT_FORM_FIRST_COL_SECTION}>
              <div className="row">
                {asset.name === 'otherRetirementAccount' ? (
                  <div className="col-xs-5">
                    <Field
                      name="otherRetirementAccountName"
                      component={renderTextField}
                      maxLength="35"
                      className="input-lg"
                      placeholder="Other"
                      disabled={disabled || isPrior}
                      required={!disabled}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="col-xs-5 text-right">
                      <FormattedMessage {...messages[`${asset.name}`]} />
                    </div>
                  </div>
                )}
                <YesNoColumns
                  name={asset.name}
                  noClicked={() => noClicked(asset.name)}
                  isPrior={isPrior}
                  colSize="3"
                />
                <IncomeAssetCurrencyField
                  name={asset.name}
                  type={'AssetMarketValue'}
                  disabled={disabled || isPrior}
                  colSize="4"
                  onBlur={() => {
                    calculateCashValue(asset.name);
                    calculateAnnualIncome(asset.name);
                  }}
                />
              </div>
            </div>

            <div className={ALT_FORM_SECOND_COL_SECTION}>
              <div className="row">
                <div className="col-xs-11">
                  <div className="row">
                    <InterestRateField
                      name={asset.name}
                      type={'AssetInterestRate'}
                      disabled={disabled || isPrior}
                      colSize="3"
                      onBlur={() => calculateAnnualIncome(asset.name)}
                    />
                    <IncomeAssetCurrencyField
                      name={asset.name}
                      type={'AssetFeesToConvertCash'}
                      disabled={disabled || isPrior}
                      colSize="3"
                      onBlur={() => calculateCashValue(asset.name)}
                    />
                    <IncomeAssetCurrencyField
                      name={asset.name}
                      type={'AssetCashValue'}
                      disabled={true}
                      colSize="3"
                    />
                    <IncomeAssetCurrencyField
                      name={asset.name}
                      type={'AssetAnnualIncome'}
                      disabled={true}
                      colSize="3"
                    />
                  </div>
                </div>
                {/* Space for delete button on expanded rows */}
                <div className="col-xs-1"></div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
