import {
  handleSocketReverseMiscTransactionError,
  handleSocketReverseMiscTransactionSuccess,
} from './actions';

/**
 * Consumed by Socket context to register listeners
 */

const reverseMiscTransactionEvents = (dispatch: Function, socket: any) => {
  socket.on('rmtx.error', (message) => {
    const data = message;
    dispatch(handleSocketReverseMiscTransactionError(data));
  });

  socket.on('rmtx.success', (message) => {
    const { payload } = message;
    dispatch(handleSocketReverseMiscTransactionSuccess(payload));
  });
};

export default reverseMiscTransactionEvents;
