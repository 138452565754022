import React from 'react';

export const GET_ALL_LEASING_KPIS = 'app/Overview/GET_ALL_LEASING_KPIS';
export const GET_ALL_LEASING_KPIS_SUCCESS =
  'app/Overview/GET_ALL_LEASING_KPIS_SUCCESS';
export const GET_ALL_LEASING_KPIS_ERROR =
  'app/Overview/GET_ALL_LEASING_KPIS_ERROR';

export const GET_LEASING_KPI = 'app/Overview/GET_LEASING_KPI';
export const GET_LEASING_KPI_SUCCESS = 'app/Overview/GET_LEASING_KPI_SUCCESS';

export const CLEAN_LOADED_LEASING_KPIS =
  'app/Overview/CLEAN_LOADED_LEASING_KPIS';

export const SPINNER = (
  <div className="spinner">
    <div className="spinnerbar spnbar1" />
    <div className="spinnerbar spnbar2" />
    <div className="spinnerbar spnbar3" />
    <div className="spinnerbar spnbar4" />
    <div className="spinnerbar spnbar5" />
  </div>
);

export const OPTIONS = [
  { value: 'days7', text: 'Last 7 Days' },
  { value: 'days30', text: 'Last 30 Days' },
  { value: 'toDateWeek', text: 'Week to Date' },
  { value: 'toDateMonth', text: 'Month to Date' },
];
