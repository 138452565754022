import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FormattedNumber } from 'react-intl';
import { pathOr } from 'ramda';
import styled from 'styled-components';
import confirm from '../../components/ConfirmDialogModal';
export type PaymentRow = {
  id: string,
  unitNumber: string,
  financiallyResponsibleLeaseHolderNames: string[],
  transactionCode: string,
  documentNumber: string,
  amount: number,
  valid: boolean,
  note?: string,
  reversed?: boolean,
  householdId: string,
  householdStatus: string,
};

type CommonEvents = {
  onRemove: Function,
  onEdit: Function,
  onViewTransaction: Function,
};

type TableActionProps = {
  disableEdit: boolean,
} & CommonEvents;

type Props = {
  payments: PaymentRow[],
  isPosted: boolean,
} & CommonEvents;

type RowProps = { paymentNumber: number, isPosted: boolean } & PaymentRow &
  CommonEvents;

type Ctx = {
  isPosted: boolean,
};

const TableContext = React.createContext();

const Wrapper = styled.div`
  & {
    tbody {
      display: block;
    }
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
    .table-prospects .table-header {
      border-radius: 0;
    }
  }
`;

const TableActions = (props: TableActionProps) => (
  <TableContext.Consumer>
    {
      // $FlowFixMe
      (ctx: Ctx) => [
        <td key="view">
          <button
            className="btn btn-sm btn-default"
            onClick={ctx.isPosted ? props.onViewTransaction : props.onEdit}
            disabled={props.disableEdit}
            style={
              props.payment.status === 'Posted' || !props.payment.isPosted
                ? undefined
                : { visibility: 'hidden' }
            }
          >
            <i className="et-pencil" />
          </button>
        </td>,
        !ctx.isPosted ? (
          <td key="delete">
            <button
              className="btn btn-sm btn-default"
              onClick={() =>
                confirm('Are you sure you want to delete this item?').then(
                  props.onRemove,
                )
              }
            >
              <i className="et-trash" />
            </button>
          </td>
        ) : null,
      ]
    }
  </TableContext.Consumer>
);

const numberFormatStyle = { value: 'currency' };

const TableRow = (props: RowProps, index: number) => {
  const isInvalid = props.invalidReasons.length > 0;
  const rowBackgroundColor = isInvalid ? '#FFF0F0' : 'initial';
  return (
    <tr
      style={props.valid ? undefined : { backgroundColor: rowBackgroundColor }}
    >
      <td style={{ width: '20px', padding: '0px', paddingLeft: '10px' }}>
        {isInvalid ? (
          <OverlayTrigger
            overlay={<Popover>{props.invalidReasons[0]}</Popover>}
          >
            <i className="icon et-alert-info" />
          </OverlayTrigger>
        ) : (
          <span></span>
        )}
      </td>
      <td>{pathOr('', ['paymentNumber'], props)}</td>
      <td>{pathOr('', ['unit', 'number'], props)}</td>
      <td>{pathOr('', ['householdStatus'], props)}</td>
      <td>{pathOr('', ['receivedFromHouseholdMember'], props)}</td>
      <td>{pathOr('', ['code', 'transactionCode', 'code'], props)}</td>
      <td>{pathOr('', ['documentNumber'], props)}</td>
      <td>
        <FormattedNumber
          value={pathOr('', ['transactionAmount'], props)}
          style={numberFormatStyle.value}
          currency="USD"
        />
      </td>
      <td>
        {pathOr(pathOr('---', ['note'], props), ['transactionNote'], props)}
      </td>
      {props.isPosted ? <td>{props.reversed ? 'Yes' : '   '}</td> : null}
      <TableActions
        onViewTransaction={props.onViewTransaction}
        onRemove={props.onRemove}
        onEdit={props.onEdit}
        payment={props}
      />
    </tr>
  );
};

const BatchTable = ({
  payments = [],
  paymentsTotal,
  isPosted,
  propertyTransactionCodes,
  onRemove,
  onEdit,
  onViewTransaction,
}: Props) => (
  <TableContext.Provider value={{ isPosted }}>
    <Wrapper>
      <table
        className="table table-prospects table-fixed-headers table-striped"
        style={{ marginBottom: '0px' }}
      >
        <thead className="table-header">
          <tr>
            <th
              style={{ width: '20px', padding: '0px', paddingLeft: '10px' }}
            ></th>
            <th>#</th>
            <th>Unit</th>
            <th>Household Status</th>
            <th>Resident</th>
            <th>Transaction Code</th>
            <th>Doc #</th>
            <th>Amount</th>
            <th>Note</th>
            {isPosted && <th>Adjustments</th>}
            <th>View</th>
            {!isPosted && <th>Delete</th>}
          </tr>
        </thead>
        <tbody className="container-scrollable">
          {payments && payments.length > 0 ? (
            payments.map((payment, i) => {
              const code = propertyTransactionCodes.find(
                (t) => t.id === payment.propertyTransactionCodeId,
              );
              return (
                <TableRow
                  key={payment.id}
                  paymentNumber={i + 1}
                  isPosted={isPosted}
                  code={code}
                  {...payment}
                  onRemove={() => onRemove(payment.id)}
                  onEdit={() => onEdit(payment.id)}
                  onViewTransaction={() => onViewTransaction(payment.id)}
                />
              );
            })
          ) : (
            <tr>
              <td>No payments</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="table-footer">
        <Row>
          <Col xs={12}>
            <span className="table-total">
              Total Payments:{' '}
              <FormattedNumber
                value={paymentsTotal}
                style={numberFormatStyle.value}
                currency="USD"
              />
            </span>
            <span className="text-darkgray">
              Number of items: {payments.length}
            </span>
          </Col>
        </Row>
      </div>
    </Wrapper>
  </TableContext.Provider>
);

export default BatchTable;
