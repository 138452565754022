import { renderDateField } from '../../utils/redux-form-helper';
import type Moment from 'moment';

type Props = {
  hidden: boolean,
  error?: string,
  name: string,
  value: string | Moment,
  onChange: Function,
};

const DateInput = ({
  hidden,
  error,
  name,
  value,
  onChange,
  isControlled = true,
  ...otherProps
}: Props) =>
  renderDateField({
    bsSize: 'md',
    hidePicker: hidden,
    meta: {
      error,
      touched: true,
    },
    input: {
      onChange,
    },
    value,
    isControlled,
    ...otherProps,
  });

export default DateInput;
