import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tooltip } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { HeartPlusIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

const ServiceAnimalIndicator = () => {
  const { householdViewMorePetInfo } = useFlags();
  return householdViewMorePetInfo ? (
    <Tooltip
      title={'Service Animal'}
      placement={'top'}
      containerSX={{ height: 21 }}
    >
      <HeartPlusIcon />
    </Tooltip>
  ) : null;
};

export default ServiceAnimalIndicator;
