import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { injectIntl } from 'react-intl';

import { selectProperty } from '../../containers/App/actions';
import ElementWithPermissions from '../ElementWithPermissions';
import IconLink from '../IconLink';
import messages from './messages';

type Props = {
  propertyId: string,
  organizationId: string,
  disabled: boolean,
  longText?: boolean,
};

const ManageLeaseExpirationLimitsLink = ({
  propertyId,
  organizationId,
  disabled,
  longText,
  history,
  actions: { selectProperty },
  userProperties,
  intl: { formatMessage },
}: Props) => (
  <ElementWithPermissions scope={['lease-expirations-limit-view']}>
    <IconLink
      onClick={() => {
        const property = R.find(R.propEq('id', propertyId), userProperties);
        selectProperty(property);

        history.push(
          `/${organizationId}/manage-lease-expiration-limits/${propertyId}`,
        );
      }}
      text={formatMessage(longText ? messages.longText : messages.shortText)}
      iconClass="et-gear"
      disabled={disabled || !organizationId || !propertyId}
    />
  </ElementWithPermissions>
);

export const mapStateToProps = (state: Object): Object => {
  const {
    app: { currentUser },
  } = state;
  return {
    userProperties: currentUser?.user.properties ?? [],
  };
};

export const mapDispatchToProps = (dispatch: any): Object => {
  const actions = bindActionCreators(
    {
      selectProperty,
    },
    dispatch,
  );
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ManageLeaseExpirationLimitsLink));
