import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.Title',
    defaultMessage: 'Document Upload',
  },
  cancel: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.Save',
    defaultMessage: 'Save',
  },
  saveAndClose: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.SaveClose',
    defaultMessage: 'Save and Close',
  },
  saveAndAddNew: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.SaveAddNew',
    defaultMessage: 'Save and Add New',
  },
  confirmCancel: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.ConfirmCancel',
    defaultMessage: 'Are you sure you want to close without saving?',
  },
  yes: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.No',
    defaultMessage: 'No',
  },
  uploading: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.Uploading',
    defaultMessage: 'Uploading, please wait...',
  },
  attachmentUpdateSuccessTitle: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.AttachmentUpdateSuccessTitle',
    defaultMessage: 'Attachment updated',
  },
  attachmentUpdateSuccessMsg: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.AttachmentUpdateSuccessMsg',
    defaultMessage: 'The attachment was updated successfully',
  },
  attachmentUpdateErrorTitle: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.AttachmentUpdateErrorTitle',
    defaultMessage: 'Error updating attachment',
  },
  attachmentUploadSuccessTitle: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.AttachmentUploadSuccessTitle',
    defaultMessage: 'Attachment uploaded',
  },
  attachmentUploadSuccessMsg: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.AttachmentUploadSuccessMsg',
    defaultMessage: 'The attachment was uploaded successfully',
  },
  attachmentUploadErrorTitle: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.AttachmentUploadErrorTitle',
    defaultMessage: 'Error uploading attachment',
  },
  fileSelectionError: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.FileSelectionError',
    defaultMessage: 'An error occurred, please try again later.',
  },

  notes: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.Notes',
    defaultMessage: 'Document Notes (Optional)',
  },
  fileName: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.FileName',
    defaultMessage: 'File Name',
  },
  browse: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.Browse',
    defaultMessage: 'Browse',
  },
  documentType: {
    id: 'App.CreateSubsidyBatch.AddDocumentModal.DocumentType',
    defaultMessage: 'Document Type',
  },
});

export default messages;
