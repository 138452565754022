import React, { useState } from 'react';
import cn from 'classnames';

import Header from './Header';

import {
  SORT_ASCENDING,
  SORT_DESCENDING,
  DEFAULT_SORT_ORDER,
} from './constants';
import type { OrderValue } from '../../containers/App/types';
import type { ColumnHeader, SortDirection } from './types';

type DefaultProps = {
  defaultSort: OrderValue,
};

type Props = DefaultProps & {
  className?: string,
  children: any,
  headers?: Array<ColumnHeader>,
  headerRows?: Array<Object>,
  id?: string,
  onSort?: Function,
  footer?: Function,
  tooltip?: Object,
};

export const Table = ({
  className,
  children,
  headers,
  headerRows,
  id,
  onSort,
  footer,
}: Props) => {
  const [columnSort, setColumnSort] = useState({
    fieldName: '',
    order: DEFAULT_SORT_ORDER,
  });

  const handleSort = (fieldName: string) => {
    const allHeaderRowHeaders = headerRows
      ? headerRows.reduce((allHeaders, row) => {
          row.headers.map((header) => allHeaders.push(header));
          return allHeaders;
        }, [])
      : [];
    const header = headerRows
      ? allHeaderRowHeaders.find((header) => header.id === fieldName)
      : headers.find((header) => header.id === fieldName);
    const defaultOrder =
      header && header.order ? header.order : DEFAULT_SORT_ORDER;
    const order =
      columnSort.fieldName !== fieldName
        ? defaultOrder
        : columnSort.order === SORT_DESCENDING
        ? SORT_ASCENDING
        : SORT_DESCENDING;

    setColumnSort({ fieldName, order });

    if (onSort) onSort({ fieldName, order });
  };

  const isActive = (id: string): ?SortDirection => {
    return columnSort.fieldName === id ? columnSort.order : null;
  };
  return (
    <table
      className={cn('table table-prospects table-striped', className)}
      id={id}
    >
      <thead className="table-header">
        {headerRows ? (
          headerRows.map((row, rowIndex) => {
            const { classes = '', headers } = row;
            return (
              <tr className={classes} key={rowIndex}>
                {headers.map((header, index) => (
                  <Header
                    key={index}
                    id={header.id}
                    onSort={header.order !== null ? handleSort : undefined}
                    title={header.title}
                    active={isActive(header.id)}
                    classes={header.classes}
                    rowSpan={header.rowSpan}
                    tooltip={header.tooltip}
                    {...header.props}
                  />
                ))}
              </tr>
            );
          })
        ) : (
          <tr>
            {headers &&
              headers.map((header, index) => (
                <Header
                  key={index}
                  id={header.id}
                  onSort={header.order !== null ? handleSort : undefined}
                  title={header.title}
                  active={isActive(header.id)}
                  classes={header.classes}
                  tooltip={header.tooltip}
                  {...header.props}
                />
              ))}
          </tr>
        )}
      </thead>
      <tbody>{children}</tbody>
      {footer ? footer() : null}
    </table>
  );
};

export default Table;
