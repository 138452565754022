import { useEffect, useState } from 'react';
import { isNil } from 'ramda';

import messages from './messages';

import CustomerOpsLedgerCAMAllocationsService from '../../../services/customerOpsLedgerCAMAllocationsService';

type ModifyTransactionCamPoolProps = {
  organizationId: string,
  propertyId: string,
  householdId: string,
  intl: any,
  promptToaster: Function,
  onSuccess: Function,
};

export const useModifyTransactionCamPool = ({
  organizationId,
  propertyId,
  householdId,
  intl,
  promptToaster,
  onSuccess,
}: ModifyTransactionCamPoolProps): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [customerOpsLedgerId, setCustomerOpsLedgerId] = useState(null);
  const [camAllocationsId, setCamAllocationsId] = useState(null);

  const returnedFn = (colId: string, allocId: string) => {
    setCustomerOpsLedgerId(colId);
    setCamAllocationsId(allocId);
  };

  useEffect(() => {
    const modifyTransCamPool = async () => {
      if (!isNil(customerOpsLedgerId) && !isNil(camAllocationsId)) {
        try {
          setIsLoading(true);
          const service = new CustomerOpsLedgerCAMAllocationsService();
          await service.modify(
            organizationId,
            propertyId,
            householdId,
            customerOpsLedgerId,
            camAllocationsId,
          );
          promptToaster({
            message: intl.formatMessage(
              messages.modifyCustomerOpsCamPoolSuccessMsg,
            ),
            title: intl.formatMessage(
              messages.modifyCustomerOpsCamPoolSuccessTitle,
            ),
          });
          onSuccess();
        } catch (err) {
          promptToaster({
            type: 'error',
            title: intl.formatMessage(
              messages.modifyCustomerOpsCamPoolSuccessError,
            ),
            message: err.toString(),
          });
        } finally {
          setIsLoading(false);
          setCustomerOpsLedgerId(null);
          setCamAllocationsId(null);
        }
      }
    };

    if (!isLoading && !isNil(customerOpsLedgerId) && !isNil(camAllocationsId)) {
      modifyTransCamPool();
    }

    return () => {
      setCustomerOpsLedgerId(null);
      setCamAllocationsId(null);
    };
  }, [
    organizationId,
    propertyId,
    householdId,
    intl,
    promptToaster,
    customerOpsLedgerId,
    camAllocationsId,
    isLoading,
    onSuccess,
  ]);

  return [isLoading, returnedFn];
};

export const useDeleteTransactionCamPool = ({
  organizationId,
  propertyId,
  householdId,
  intl,
  promptToaster,
  onSuccess,
}: ModifyTransactionCamPoolProps): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [customerOpsLedgerId, setCustomerOpsLedgerId] = useState(null);

  const returnedFn = (colId: string) => {
    setCustomerOpsLedgerId(colId);
  };

  useEffect(() => {
    const modifyTransCamPool = async () => {
      if (!isNil(customerOpsLedgerId)) {
        try {
          setIsLoading(true);
          const service = new CustomerOpsLedgerCAMAllocationsService();
          await service.delete(
            organizationId,
            propertyId,
            householdId,
            customerOpsLedgerId,
          );
          promptToaster({
            message: intl.formatMessage(
              messages.deleteCustomerOpsCamPoolSuccessMsg,
            ),
            title: intl.formatMessage(
              messages.deleteCustomerOpsCamPoolSuccessTitle,
            ),
          });
          onSuccess();
        } catch (err) {
          promptToaster({
            type: 'error',
            title: intl.formatMessage(
              messages.deleteCustomerOpsCamPoolSuccessError,
            ),
            message: err.toString(),
          });
        } finally {
          setIsLoading(false);
          setCustomerOpsLedgerId(null);
        }
      }
    };

    if (!isLoading && !isNil(customerOpsLedgerId)) {
      modifyTransCamPool();
    }

    return () => {
      setCustomerOpsLedgerId(null);
    };
  }, [
    organizationId,
    propertyId,
    householdId,
    intl,
    promptToaster,
    customerOpsLedgerId,
    isLoading,
    onSuccess,
  ]);

  return [isLoading, returnedFn];
};
