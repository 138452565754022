import { defineMessages } from 'react-intl';

const messages = defineMessages({
  camAllocationsSetup: {
    id: 'App.CamTab.CamCalculations.CamCalculations',
    defaultMessage: 'CAM Calculations',
  },
  primaryCamPool: {
    id: 'App.CamTab.CamCalculations.PrimaryCamPool',
    defaultMessage: 'CAM Pools',
  },
  estimates: {
    id: 'App.CamTab.CamCalculations.Estimates',
    defaultMessage: 'Estimates - Approved Bill psf/a',
  },

  monthlyCAMBill: {
    id: 'App.CamTab.CamCalculations.MonthlyCAMBill',
    defaultMessage: 'Monthly CAM Bill',
  },
  billedYTD: {
    id: 'App.CamTab.CamCalculations.BilledYTD',
    defaultMessage: 'Billed YTD',
  },
  actualsYTD: {
    id: 'App.CamTab.CamCalculations.ActualsYTD',
    defaultMessage: 'Actuals YTD',
  },
  actualsYTDBudget: {
    id: 'App.CamTab.CamCalculations.actualsYTDBudget',
    defaultMessage: 'Actuals YTD + Budget',
  },
  annualAllocation: {
    id: 'App.CamTab.CamCalculations.AnnualAllocation',
    defaultMessage: 'Annual Allocation',
  },
  proratedAllocation: {
    id: 'App.CamTab.CamCalculations.ProratedAllocation',
    defaultMessage: 'Prorated Allocation',
  },
  estimatedReconciliation: {
    id: 'App.CamTab.CamCalculations.estimatedReconciliation',
    defaultMessage: 'Estimated Reconciliation',
  },
  total: {
    id: 'App.CamTab.CamCalculations.Total',
    defaultMessage: 'Total',
  },
  confirm: {
    id: 'App.CamTab.CamCalculations.Confirm',
    defaultMessage: 'Confirm Estimates',
  },
  refreshAndPreview: {
    id: 'App.CamTab.CamCalculations.refreshAndPreview',
    defaultMessage: 'Refresh and Preview',
  },
  postReconciliation: {
    id: 'App.CamTab.CamCalculations.postReconciliation',
    defaultMessage: 'Post Reconciliation',
  },
  noteHeader: {
    id: 'App.CamTab.CamCalculations.NoteHeader',
    defaultMessage: 'NOTE ON CALCULATIONS:',
  },
  note: {
    id: 'App.CamTab.CamCalculations.Note',
    defaultMessage:
      'The Actual YTD and amounts will only include the yearly amount starting with the month of the CAM Start Date',
  },
  note1: {
    id: 'App.CamTab.CamCalculations.Note1',
    defaultMessage:
      '(1) The Actuals YTD amounts will only include the yearly amount starting with the month of the CAM Start Date.',
  },
  note2: {
    id: 'App.CamTab.CamCalculations.Note2',
    defaultMessage:
      // eslint-disable-next-line max-len
      '(2) CAM Pools/Exceptions that do not allow monthly estimates will not create monthly charge and have an asterisk (*).',
  },
  note3: {
    id: 'App.CamTab.CamCalculations.Note3',
    defaultMessage:
      // eslint-disable-next-line max-len
      '(3) The prorated allocation applies to tenants with a move-out date and will be used for the reconciliation, if available. This prorate will be the Annual Allocation divided by the number of days in the year, then multiplied by the number of days the tenant occupied the space since the Cam Start Date.',
  },
  fetchCalculationsError: {
    id: 'App.CamTab.CamCalculations.fetchCalculationsError',
    defaultMessage: 'Error fetching calculations',
  },
  generateCalculationsError: {
    id: 'App.CamTab.CamCalculations.generateCalculationsError',
    defaultMessage: 'Error generating calculations',
  },
  generateCalculationsSuccessTitle: {
    id: 'App.CamTab.CamCalculations.generateCalculationsSuccessTitle',
    defaultMessage: 'CAM calculations generated.',
  },
  generateCalculationsSuccessMessage: {
    id: 'App.CamTab.CamCalculations.generateCalculationsSuccessMessage',
    defaultMessage: 'The CAM calculations were generated successfully.',
  },
  confirmCAMestimatesError: {
    id: 'App.CamTab.CamCalculations.confirmCAMestimatesError',
    defaultMessage: 'Error confirming CAM estimates',
  },
  confirmCAMestimatesSuccessTitle: {
    id: 'App.CamTab.CamCalculations.confirmCAMestimatesSuccessTitle',
    defaultMessage: 'CAM estimates confirmed.',
  },
  confirmCAMestimatesSuccessMessage: {
    id: 'App.CamTab.CamCalculations.confirmCAMestimatesSuccessMessage',
    defaultMessage: 'The CAM estimates were confirmed successfully.',
  },
  reconcileCAMError: {
    id: 'App.CamTab.CamCalculations.reconcileCAMError',
    defaultMessage: 'Error reconciling CAM',
  },
  reconcileCAMSuccessTitle: {
    id: 'App.CamTab.CamCalculations.reconcileCAMSuccessTitle',
    defaultMessage: 'CAM reconciled.',
  },
  reconcileCAMSuccessMessage: {
    id: 'App.CamTab.CamCalculations.reconcileCAMSuccessMessage',
    defaultMessage: 'The CAM was reconciled successfully.',
  },
  reconciliationPendingMessage: {
    id: 'App.CamTab.CamCalculations.reconciliationPendingMessage',
    defaultMessage: 'CAM reconciliation pending.',
  },
  confirmEstimatesDialogMessageTitle: {
    id: 'App.CamTab.CamCalculations.confirmEstimatesDialogMessageTitle',
    defaultMessage: 'Are you sure you want to Confirm Estimates for this CAM?',
  },
  confirmEstimatesDialogMessageLine1: {
    id: 'App.CamTab.CamCalculations.confirmEstimatesDialogMessageLine1',
    defaultMessage: 'Once the estimates have been confirmed:',
  },
  confirmEstimatesDialogMessageLine2: {
    id: 'App.CamTab.CamCalculations.confirmEstimatesDialogMessageLine2',
    defaultMessage: '1. The CAM pool setup cannot be changed.',
  },
  confirmEstimatesDialogMessageLine3: {
    id: 'App.CamTab.CamCalculations.confirmEstimatesDialogMessageLine3',
    defaultMessage:
      '2. The monthly charges will be created for CAM pools that allow monthly estimates.',
  },
  confirmEstimatesDialogMessageLine4: {
    id: 'App.CamTab.CamCalculations.confirmEstimatesDialogMessageLine4',
    defaultMessage:
      'Ensure your estimated calculations are correct before moving forward. This can only be done once.',
  },
  yes: {
    id: 'App.CamTab.CamCalculations.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.CamTab.CamCalculations.no',
    defaultMessage: 'No',
  },
  postReconciliationModalHeader: {
    id: 'App.CamTab.CamCalculations.PostReconciliationModalHeader',
    defaultMessage: 'Are you sure you want to reconcile?',
  },
  amountToPost: {
    id: 'App.CamTab.CamCalculations.AmountToPost',
    defaultMessage: 'Amount To Post:',
  },
  chooseReconciliationType: {
    id: 'App.CamTab.CamCalculations.ChooseReconciliationType',
    defaultMessage: 'Choose a reconciliation type:',
  },
  reconcile: {
    id: 'App.CamTab.CamCalculations.Reconcile',
    defaultMessage: 'Reconcile',
  },
  cancel: {
    id: 'App.CamTab.CamCalculations.Cancel',
    defaultMessage: 'Cancel',
  },
  yearEnd: {
    id: 'App.CamTab.CamCalculations.yearEnd',
    defaultMessage: 'Year End',
  },
  overUnderEstimated: {
    id: 'App.CamTab.CamCalculations.OverUnderEstimated',
    defaultMessage: 'Over/Under Estimated',
  },
  moveOut: {
    id: 'App.CamTab.CamCalculations.MoveOut',
    defaultMessage: 'Move-Out',
  },
  interim: {
    id: 'App.CamTab.CamCalculations.Interim',
    defaultMessage: 'Interim',
  },
});

export default messages;
