import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import DocumentTitle from 'react-document-title';
import messages from './Header/messages';
import ManageTransactionBatchesHeader from './Header';
import TransactionBatchesGrid from './TransactionBatchesGrid';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type Props = {
  intl: any,
  propertyId: string,
  organizationId: string,
  history: Object,
  headerFormValues: Object,
  isAllCommercial: boolean,
};

const ManageTransactionBatches = (props: Props) => {
  const {
    intl,
    propertyId,
    organizationId,
    headerFormValues,
    isAllCommercial,
  } = props;
  const filterBy = headerFormValues?.filterBy;
  const redirectToCreateTransactionBatch = (
    transactionBatchHeaderId?: string,
  ) => {
    navigateToUrlWithSelectedPropertyId(
      `/create-transaction-batch/${transactionBatchHeaderId || ''}`,
    );
  };

  return (
    <DocumentTitle title={intl.formatMessage(messages.header)}>
      <div className="bodywrap">
        <ManageTransactionBatchesHeader
          organizationId={organizationId}
          propertyId={propertyId}
          onCreateNewBatchClicked={() => {
            redirectToCreateTransactionBatch();
          }}
        />
        <TransactionBatchesGrid
          organizationId={organizationId}
          propertyId={propertyId}
          redirectToCreateTransactionBatch={redirectToCreateTransactionBatch}
          filterBy={filterBy}
          isAllCommercial={isAllCommercial}
        />
      </div>
    </DocumentTitle>
  );
};

const InjectedComponent = injectIntl(ManageTransactionBatches);
export const mapStateToProps = (state: any): any => {
  const headerFormValues = getFormValues('ManageTransactionBatchesHeaderForm')(
    state,
  );
  return {
    organizationId: state?.app?.currentUser?.user?.organizationId ?? '',
    propertyId: state?.app?.selectedProperty?.id,
    headerFormValues,
    isAllCommercial:
      state?.app?.selectedProperty?.hasCommercialFloorPlans === 'ALL',
  };
};

export default connect(mapStateToProps)(InjectedComponent);
