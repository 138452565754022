import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export const PropertySelectionPanel = ({ options }: Object) => {
  return (
    <div className="panel column-eq-height">
      <div className="panel-body">
        <div className="row padbottom10">
          <div className="col-xs-10 col-sm-16">
            <h3 className="padtop10">
              <FormattedMessage {...messages.selectProperty} />
            </h3>
          </div>
          <div className="col-xs-10 col-sm-16">
            <select className="input-lg form-control" disabled>
              {options.map((option) => (
                <option key={option.key} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
