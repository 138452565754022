import React, { useState } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import PriorResidentsTable from './PriorResidentsTable';

import messages from './messages';

const LIMIT_PER_PAGE = 20;

const ManagePriorResidents = ({
  intl,
  userId,
  organizationId,
  selectedProperty,
  locale,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const isAllCommercial =
    selectedProperty.hasCommercialFloorPlans === 'ALL' ? true : false;

  return (
    <DocumentTitle
      title={
        isAllCommercial
          ? intl.formatMessage(messages.docTitleCommercial)
          : intl.formatMessage(messages.docTitle)
      }
    >
      <PriorResidentsTable
        intl={intl}
        selectedProperty={selectedProperty}
        organizationId={organizationId}
        propertyId={selectedProperty?.id}
        userId={userId}
        isAllCommercial={isAllCommercial}
        onPageChange={onPageChange}
        currentPage={currentPage}
        perPage={LIMIT_PER_PAGE}
        locale={locale}
      />
    </DocumentTitle>
  );
};

export const mapStateToProps = ({ app, languageProvider }: Object): Object => {
  return {
    organizationId: app.currentUser?.user?.organizationId || '',
    selectedProperty: app.selectedProperty,
    userId: app.currentUser?.user?.id ?? '',
    locale: languageProvider?.locale,
  };
};
export default connect(mapStateToProps, null)(injectIntl(ManagePriorResidents));
