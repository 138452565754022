import { defineMessages } from 'react-intl';

const messages = defineMessages({
  affordableRelationshipWarning: {
    id: 'App.AffordableInformation.AffordableRelationshipWarning',
    defaultMessage:
      // eslint-disable-next-line max-len
      'To change the Affordable Relation, go to the “Edit Household” screen and update the Affordable Relation. Any saved changes there will display here.',
  },
});

export default messages;
