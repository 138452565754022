import { defineMessages } from 'react-intl';

const messages = defineMessages({
  successHeader: {
    id: 'App.RecertificationApplication.SuccessHeader',
    defaultMessage: 'Success',
  },
  progressSuccessDescription: {
    id: 'App.RecertificationApplication.ProgressSuccessDescription',
    defaultMessage: 'Recertification application saved successfully.',
  },
  initialCertificationProgressSuccessDescription: {
    id: 'App.RecertificationApplication.InitialCertificationProgressSuccessDescription',
    defaultMessage: 'Mini application saved successfully.',
  },
  completionSuccessDescription: {
    id: 'App.RecertificationApplication.CompletionSuccessDescription',
    defaultMessage: 'Recertification application completed successfully.',
  },
  initialCertificationCompletionSuccessDescription: {
    id: 'App.RecertificationApplication.InitialCertificationCompletionSuccessDescription',
    defaultMessage: 'Mini application completed successfully.',
  },
  errorHeader: {
    id: 'App.RecertificationApplication.errorHeader',
    defaultMessage: 'Error',
  },
  retry: {
    id: 'App.RecertificationApplication.Retry',
    defaultMessage: 'Retry',
  },
  loadError: {
    id: 'App.RecertificationApplication.LoadError',
    defaultMessage: 'Failed to load data.',
  },
});

export default messages;
