import * as ActionTypes from './constants';

import type { Action } from '../App/types';

export function getApplicantPrimaryFormInformation(
  applicantId: string,
): Action<string> {
  return {
    type: ActionTypes.GET_APPLICANT_PRIMARY_FORM_INFORMATION,
    payload: applicantId,
  };
}

export function getApplicantPrimaryFormInformationSuccess(
  applicant: any,
): Action<any> {
  return {
    type: ActionTypes.GET_APPLICANT_PRIMARY_FORM_INFORMATION_SUCCESS,
    payload: applicant,
  };
}

export function getApplicantPrimaryFormInformationError(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.GET_APPLICANT_PRIMARY_FORM_INFORMATION_ERROR,
    payload: error,
  };
}

export function savePrimaryForm(
  applicant: any,
  applicantId: string,
  applicationId: string,
): Action<any> {
  return {
    type: ActionTypes.SAVE_PRIMARY_FORM,
    payload: {
      applicant,
      applicantId,
      applicationId,
    },
  };
}

export function printApplicationForm(
  applicationId: string,
  applicantId: string,
  applicantFirstName: string,
  applicantLastName: string,
  isAffordable: boolean,
  includeIncomeAndAssets: boolean,
  isPrimary?: boolean,
  isSecondary?: boolean,
): Action<any> {
  return {
    type: ActionTypes.PRINT_APPLICATION_FORM,
    payload: {
      applicantId,
      applicantFirstName,
      applicantLastName,
      applicationId,
      isAffordable,
      includeIncomeAndAssets,
      isPrimary,
      isSecondary,
    },
  };
}

export function printApplicationFormSuccess(): Action<any> {
  return {
    type: ActionTypes.PRINT_APPLICATION_FORM_SUCCESS,
  };
}

export function printApplicationFormError(): Action<any> {
  return {
    type: ActionTypes.PRINT_APPLICATION_FORM_ERROR,
  };
}

export function printWindowApplicationform(
  applicationId: string,
  applicantId: string,
  isAffordable: boolean,
): Action<any> {
  return {
    type: ActionTypes.PRINT_WINDOW_APPLICATION_FORM,
    payload: {
      applicantId,
      applicationId,
      isAffordable,
    },
  };
}

export function savePrimaryFormSuccess(): Action<any> {
  return {
    type: ActionTypes.SAVE_PRIMARY_FORM_SUCCESS,
    payload: undefined,
  };
}

export function savePrimaryFormError(error: Error): Action<any> {
  return {
    type: ActionTypes.SAVE_PRIMARY_FORM_ERROR,
    payload: error,
  };
}

export function cleanApplicantPrimaryFormInformation(): Action<any> {
  return {
    type: ActionTypes.CLEAN_APPLICANT_PRIMARY_FORM_INFORMATION,
    payload: undefined,
  };
}

export function getAllPrimaryFormInformation(applicantId: string): Action<any> {
  return {
    type: ActionTypes.GET_ALL_APPLICANT_PRIMARY_FORM_INFORMATION,
    payload: applicantId,
  };
}
