import type { Action } from '../App/types';
import type { ForgotPasswordState } from './types';
import * as ActionTypes from './constants';

const initialState: ForgotPasswordState = {
  username: '',
  showSuccessMessage: false,
  showErrorMessage: false,
  errorMessage: '',
  emailSent: false,
};

const forgotPasswordReducer = (
  state: ForgotPasswordState = initialState,
  action: Action<*, *>
): ForgotPasswordState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.FORGOT_PASSWORD_EMAIL_ERROR: {
      return {
        ...state,
        showErrorMessage: true,
        errorMessage: payload.toString(),
        showSuccessMessage: false,
        emailSent: false,
      };
    }
    case ActionTypes.FORGOT_PASSWORD_EMAIL_SENT_SUCCESS: {
      return {
        ...state,
        showErrorMessage: false,
        emailSent: true,
      };
    }
    case ActionTypes.CLEAR_MESSAGES: {
      return {
        ...state,
        showErrorMessage: false,
        emailSent: false,
      };
    }
    default:
      return state;
  }
};

export default forgotPasswordReducer;
