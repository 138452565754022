import { defineMessages } from 'react-intl';

const messages = defineMessages({
  success: {
    id: 'App.LeaseDataTab.Success',
    defaultMessage: 'Success',
  },
  unlockedLeaseSuccess: {
    id: 'App.LeaseDataTab.UnlockedLeaseSuccess',
    defaultMessage: 'Successfully Unlocked Lease',
  },
  error: {
    id: 'App.LeaseDataTab.Error',
    defaultMessage: 'Error',
  },
  unlockedLeaseError: {
    id: 'App.LeaseDataTab.UnlockedLeaseError',
    defaultMessage: 'Error Unlocking Lease',
  },
  uploadedLeaseSuccess: {
    id: 'App.LeaseDataTab.UploadedLeaseSuccess',
    defaultMessage: 'Successfully Uploaded Lease',
  },
  uploadedLeaseError: {
    id: 'App.LeaseDataTab.UploadedLeaseError',
    defaultMessage: 'Error Uploading Lease',
  },
  leaseSentToPortal: {
    id: 'App.LeaseDataTab.LeaseSentToPortal',
    defaultMessage: 'Lease Sent to Portal',
  },
  sendToPortalError: {
    id: 'App.LeaseDataTab.SendToPortalError',
    defaultMessage: 'Error Sending Lease to Portal',
  },
  leaseSuccessfullyExecuted: {
    id: 'App.LeaseDataTab.LeaseSuccessfullyExecuted',
    defaultMessage: 'Lease Successfully Executed',
  },
  errorExecutingLease: {
    id: 'App.LeaseDataTab.ErrorExecutingLease',
    defaultMessage: 'Error Executing Lease',
  },
});

export default messages;
