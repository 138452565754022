import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.Home.PropertyComplianceTab.Title',
    defaultMessage: 'Set Aside Programs',
  },
  setAsidesFetchError: {
    id: 'App.Home.PropertyComplianceTab.SetAsidesFetchError',
    defaultMessage: 'Error loading set aside programs',
  },
  effectiveDate: {
    id: 'App.Home.PropertyComplianceTab.EffectiveDate',
    defaultMessage: 'Effective Date',
  },
  editSetAsideProgramSuccessTitle: {
    id: 'App.Home.PropertyComplianceTab.EditSetAsideProgramSuccessTitle',
    defaultMessage: 'Set Aside Program updated',
  },
  editSetAsideProgramSuccessMessage: {
    id: 'App.Home.PropertyComplianceTab.EditSetAsideProgramSuccessMessage',
    defaultMessage: 'The Set Aside program was updated successfully.',
  },
  editSetAsideProgramError: {
    id: 'App.Home.PropertyComplianceTab.EditSetAsideProgramError',
    defaultMessage: 'Error updating the Set Aside program',
  },
  addSetAsideIncomeLimitsSuccessTitle: {
    id: 'App.Home.PropertyComplianceTab.AddSetAsideIncomeLimitsSuccessTitle',
    defaultMessage: 'Income Limits created',
  },
  addSetAsideIncomeLimitsSuccessMessage: {
    id: 'App.Home.PropertyComplianceTab.AddSetAsideIncomeLimitsSuccessMessage',
    defaultMessage: 'The Income Limits were created successfully.',
  },
  addSetAsideIncomeLimitsError: {
    id: 'App.Home.PropertyComplianceTab.AddSetAsideIncomeLimitsError',
    defaultMessage: 'Error creating the Income Limits',
  },
  setAsideSetup: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSetup',
    defaultMessage: 'Set Aside Setup',
  },
});

export default messages;
