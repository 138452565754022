export const TABLE_HEADERS = [
  'Program Name',
  'Set Aside',
  '# of Units',
  'HUD',
  'Income Limit %',
  '% Median Income',
  'Max Rent %',
  'Over Income at Recert %',
  'Details',
];

export const PPL = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const PPL_FRIENDLY = [
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
];

export const PPL_PROPERTIES: Array<string> = PPL_FRIENDLY.map((number) => {
  return number === 'one' ? 'onePerson' : `${number}People`;
});

export const GROUPING_PROPERTIES = [
  'programName',
  'setAsideShortName',
  'isHUD',
  'incomeLimit',
  'maximumIncomePercentage',
  'maximumRentPercentage',
  'overIncomeCalc',
];
