import React from 'react';
import styled from 'styled-components';

type Props = {
  color: string,
};

const Status = ({ color, noMargin }: Props) => {
  const StyledSpan = styled.span`
    width: 10px;
    height: 10px;
    display: inline-block;
    vertical-align: top;
    margin: ${noMargin ? '0px' : '4px auto 0'};
    border-radius: 50em;
    background-color: ${color};
  `;

  return <StyledSpan />;
};

export default Status;
