import { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toastr } from 'react-redux-toastr';
import { useModal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';

import PropertyFeesService from '../../../services/propertyFeesService';
import UnitFeesService from '../../../services/unitFeesService';

const propertyFeesService = new PropertyFeesService();
const unitFeesService = new UnitFeesService();

export const useUnitAmenities = (propertyId, unitId, refreshUnit) => {
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const { open, openModal, closeModal } = useModal(false);
  const { data: propertyAmenities } = useFetchPropertyAmenities({ propertyId });
  const { data: unitAmenities } = useFetchUnitAmenities({
    unitId,
    propertyAmenities,
  });
  const updateUnitAmenities = useUpdateUnitAmenities({
    propertyAmenities,
    propertyId,
    unitId,
    selectedAmenities,
    refreshUnit,
  });

  useEffect(() => {
    const selected = unitAmenities.map((item) => item.propertyFeeId) || [];
    setSelectedAmenities(selected);
  }, [unitAmenities, propertyAmenities, open]);

  return {
    unitAmenities,
    selectedAmenities,
    setSelectedAmenities,
    propertyAmenities: propertyAmenities?.map((amenity) => ({
      id: amenity.id,
      name: amenity.displayNameOnQuote,
      price: amenity.feeAmount,
    })),
    updateUnitAmenities,
    editAmenitiesModalOpen: open,
    openEditAmenitiesModal: openModal,
    closeEditAmenitiesModal: closeModal,
  };
};

export const useFetchPropertyAmenities = ({ propertyId }) => {
  const queryKey = ['propertyAmenities', propertyId];
  const options = {
    initialData: [],
  };
  const requestQuery = {
    propertyId,
    feeType: 'Amenity',
    endDate: null,
  };
  const { data, isLoading } = useQuery(
    queryKey,
    () => propertyFeesService.getAll(requestQuery),
    options,
  );

  return { data, isLoading };
};

export const useFetchUnitAmenities = ({ unitId, propertyAmenities }) => {
  const queryKey = ['unitAmenities', unitId];
  const options = {
    enabled: propertyAmenities?.length > 0,
    initialData: [],
  };
  const amenityPTCId = propertyAmenities?.[0]?.propertyTransactionCodeId;
  const requestQuery = {
    unitId,
    propertyTransactionCodeId: amenityPTCId,
    endDate: null,
  };
  const { data, isLoading } = useQuery(
    queryKey,
    () => unitFeesService.getAll(requestQuery),
    options,
  );

  return { data, isLoading };
};

export const useUpdateUnitAmenities = ({
  propertyId,
  unitId,
  selectedAmenities,
  propertyAmenities,
  refreshUnit,
}) => {
  const queryClient = useQueryClient();
  const newAssignedUnitAmenities = selectedAmenities.map((propertyFeeId) => {
    const propertyAmenity = propertyAmenities.find(
      (amenity) => amenity.id === propertyFeeId,
    );
    return {
      propertyId,
      unitId,
      propertyTransactionCodeId: propertyAmenity?.propertyTransactionCodeId,
      propertyFeeId,
      startDate: new Date(),
      endDate: null,
      feeAmount: propertyAmenity?.feeAmount,
    };
  });
  const updateAssignedUnitAmenities = () => {
    if (propertyAmenities.length === 0) return;
    const propertyAmenityPTCID = propertyAmenities[0].propertyTransactionCodeId;
    return unitFeesService.updateAssignedUnitAmenities({
      unitId,
      newAssignedUnitAmenities,
      propertyAmenityPTCID,
    });
  };

  const updateAssignedUnitAmenitiesMutation = useMutation(
    updateAssignedUnitAmenities,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['unitAmenities']);
        toastr.success('Success', 'The unit amenities have been updated.');
        refreshUnit();
      },
      onError: () => {
        toastr.error('Error', 'Failed to update unit amenities');
      },
    },
  );
  return updateAssignedUnitAmenitiesMutation.mutate;
};
