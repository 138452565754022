import { useState, useEffect } from 'react';
import SubsidyPaymentService from '../../services/subsidyPaymentService';
import PropertySubjournalsService from '../../services/propertySubjournalTypesService';
import PaymentBatchService from '../../services/paymentBatchService';
import PropertyTransactionCodesService from '../../services/propertyTransactionCodesService';
import HouseholdService from '../../services/householdService';
import { getSubjournalId } from './utils';
import { navigateToUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import { pick } from 'ramda';
import moment from 'moment';

export const useFetchSubsidyPaymentBatch = ({
  organizationId,
  propertyId,
  subsidyPaymentBatchHeaderId,
  promptToaster,
  setInitialValues,
  setIsSaved,
  history,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [batchHeader, setBatchHeader] = useState(null);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    const fetchSubsidyBatchHeader = async () => {
      setIsLoading(true);
      try {
        const paymentBatchService = new PaymentBatchService();
        const paymentBatchHeader = await paymentBatchService.get(
          subsidyPaymentBatchHeaderId,
          propertyId,
          organizationId,
        );
        setInitialValues({
          batchTotal: paymentBatchHeader.header.batchTotal,
          batchDate: moment(paymentBatchHeader.header.batchDate).toDate(),
          batchNote: paymentBatchHeader.header.batchNote,
          documentNumber: paymentBatchHeader.header.documentNumber,
        });
        navigateToUrlWithSelectedPropertyId(
          `/create-subsidy-batch/${paymentBatchHeader.header.id}`,
        );
        setIsSaved(true);
        setBatchHeader(paymentBatchHeader);
      } catch (err) {
        promptToaster({
          type: 'error',
          title: 'Error',
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (refresh && subsidyPaymentBatchHeaderId) {
      fetchSubsidyBatchHeader();
      setRefresh(false);
    }
  }, [
    organizationId,
    propertyId,
    subsidyPaymentBatchHeaderId,
    promptToaster,
    setInitialValues,
    setIsSaved,
    history,
    refresh,
  ]);

  return [batchHeader, () => setRefresh(true), isLoading];
};

export const useSaveSubsidyPaymentBatchHeader = ({
  organizationId,
  propertyId,
  setIsSaved,
  promptToaster,
  history,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const createSubsidyBatchHeader = async (payload: Object) => {
    setIsLoading(true);
    try {
      const subsidyPaymentService = new SubsidyPaymentService();
      const paymentBatchHeader =
        await subsidyPaymentService.saveSubsidyPaymentBatchHeader(
          organizationId,
          propertyId,
          payload,
        );
      promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Subsidy Payment Batch Saved',
      });
      navigateToUrlWithSelectedPropertyId(
        `/create-subsidy-batch/${paymentBatchHeader.id}`,
      );
      setIsSaved(true);
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
    }
  };
  return [createSubsidyBatchHeader, isLoading];
};

export const useGetHouseholdsWithSubjournalBalance = ({
  organizationId,
  propertyId,
  promptToaster,
}: Object): any => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchData = async () => {
      const subjournalsService = new PropertySubjournalsService();
      const response = await subjournalsService.getPropertySubjournalTypes(
        organizationId,
        propertyId,
      );
      const subjournalTypeId = getSubjournalId(response)[0];
      if (organizationId && propertyId && refresh && subjournalTypeId) {
        try {
          setIsLoading(true);
          const subsidyPaymentService = new SubsidyPaymentService();
          const options = { signal: abortController.signal };
          const response =
            await subsidyPaymentService.getHouseholdsWithSubjournalBalance(
              organizationId,
              propertyId,
              subjournalTypeId,
              options,
            );
          setIsLoading(false);
          setData(response);
        } catch (err) {
          promptToaster({
            type: 'error',
            title: 'Error',
            message: err.toString(),
          });
        }
      }
    };

    if (!data) {
      fetchData();
      setRefresh(false);
    }
  }, [organizationId, propertyId, promptToaster, data, refresh]);

  return [data, isLoading];
};

export const useSaveSubsidyPaymentBatchDetail = ({
  organizationId,
  propertyId,
  promptToaster,
  refresh,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);
  const saveSubsidyPaymentBatchDetail = async (
    payload: Object,
    shouldRefresh: boolean,
  ) => {
    setIsLoading(true);
    const batchDetail = {
      headerId: payload.batchId,
      transactionAmount: payload.amount,
      receivedFromHouseholdMember: payload.receivedFrom,
      propertyTransactionCodeId: payload.transactionCode,
      transactionDate:
        payload.transactionDate ?? moment().startOf('day').toDate(),
      ...pick([
        'transactionNote',
        'documentNumber',
        'householdId',
        'householdStatus',
        'unitId',
        'isManual',
      ])(payload),
    };
    if (payload.id) {
      batchDetail.id = payload.id;
    }
    try {
      const paymentBatchService = new PaymentBatchService();
      const response = await paymentBatchService.savePaymentBatchDetail(
        organizationId,
        propertyId,
        batchDetail,
      );
      return response;
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
      shouldRefresh && refresh();
    }
  };

  return [saveSubsidyPaymentBatchDetail, isLoading];
};

export const useGetPropertySubsidyBatchTransactionCode = ({
  organizationId,
  propertyId,
}: Object): any => {
  const [transactionCodes, setTransactionCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };

    const getPropertySubsidyBatchTransactionCode = async () => {
      if (organizationId && propertyId) {
        setIsLoading(true);
        const propertyTransactionCodesService =
          new PropertyTransactionCodesService();
        const propertyTransactionCodes =
          await propertyTransactionCodesService.getPropertySubsidyBatchTransactionCodes(
            organizationId,
            propertyId,
            options,
          );
        setTransactionCodes(propertyTransactionCodes);
        setIsLoading(false);
      }
    };

    getPropertySubsidyBatchTransactionCode();

    return () => abortController.abort();
  }, [organizationId, propertyId]);

  return [transactionCodes, isLoading];
};

export const useGetAllHousehold = ({
  organizationId,
  propertyId,
}: Object): any => {
  const [households, setHouseholds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };

    const getPropertySubsidyBatchTransactionCode = async () => {
      if (organizationId && propertyId) {
        setIsLoading(true);
        const householdService = new HouseholdService();
        const response = await householdService.getAllHouseholds(
          organizationId,
          propertyId,
          options,
        );
        setHouseholds(response);
        setIsLoading(false);
      }
    };

    getPropertySubsidyBatchTransactionCode();

    return () => abortController.abort();
  }, [organizationId, propertyId]);

  return [households, isLoading];
};

export const useDeletePaymentBatchDetail = ({
  organizationId,
  propertyId,
  promptToaster,
  refresh,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const deletePaymentBatchDetail = async (detailId: Object) => {
    setIsLoading(true);

    try {
      const paymentBatchService = new PaymentBatchService();
      await paymentBatchService.deletePaymentBatchDetail(
        organizationId,
        propertyId,
        detailId,
      );
      promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Payment transaction removed from batch.',
      });
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
      refresh();
    }
  };

  return [deletePaymentBatchDetail, isLoading];
};

export const usePostAndDepositSubsidyBatch = ({
  organizationId,
  propertyId,
  promptToaster,
  history,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const postAndDepositSubsidyBatch = async (
    batchHeaderId: string,
    currentRoutePath: string,
  ) => {
    setIsLoading(true);
    promptToaster({
      type: 'success',
      title: 'Success',
      message:
        'Subsidy Batch is pending and will post and deposit when complete.',
    });

    try {
      const paymentBatchService = new PaymentBatchService();
      await paymentBatchService.postPaymentBatch(
        batchHeaderId,
        propertyId,
        organizationId,
        {
          currentRoutePath,
          batchHeaderId,
        },
      );
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
      navigateToUrlWithSelectedPropertyId('/manage-subsidy-payments');
    }
  };

  return [postAndDepositSubsidyBatch, isLoading];
};

export const useUpdateBatchHeader = ({
  organizationId,
  propertyId,
  promptToaster,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);
  const updateSubsidyBatchHeader = async (
    payload: Object,
    callback: Function,
  ) => {
    setIsLoading(true);
    try {
      const paymentService = new PaymentBatchService();
      await paymentService.updatePaymentBatchHeader(
        payload?.header?.id ?? null,
        organizationId,
        propertyId,
        payload,
      );
      promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Subsidy Payment Batch Header Updated.',
      });
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
      callback();
    }
  };
  return [updateSubsidyBatchHeader, isLoading];
};

export const useDeleteSubsidyPaymentBatch = ({
  organizationId,
  propertyId,
  promptToaster,
  history,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteSubsidyPaymentBatch = async (batchHeaderId: Object) => {
    setIsLoading(true);
    try {
      const paymentBatchService = new PaymentBatchService();
      await paymentBatchService.deletePaymentBatch(
        organizationId,
        propertyId,
        batchHeaderId,
      );
      promptToaster({
        type: 'success',
        title: 'Success',
        message: 'Payment batch successfully deleted.',
      });
      navigateToUrlWithSelectedPropertyId('/manage-subsidy-payments');
    } catch (err) {
      promptToaster({
        type: 'error',
        title: 'Error',
        message: err.toString(),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return [deleteSubsidyPaymentBatch, isLoading];
};
