import type { Action } from '../App/types';
import type { ManageProspectsState } from './types';
import * as ActionTypes from './constants';

const initialState: ManageProspectsState = {
  prospects: [],
  meta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  currentFilter: {},
  columnOrder: {
    lastName: 'sortable',
    firstName: 'sortable',
    phoneNumber: 'sortable',
    emailAddress: 'sortable',
    lastActivity: 'sortable',
    nextActivity: 'sortable',
    assignedTo: 'sortable',
    nBeds: 'sortable',
  },
  currentSorting: {
    fieldName: '',
    order: '',
  },
  searchText: '',
};

const manageProspectsReducer = (
  state: ManageProspectsState = initialState,
  action: Action<*, *>,
): ManageProspectsState => {
  const { type, payload, meta } = action;
  switch (type) {
    case ActionTypes.GET_ALL_PROSPECTS: {
      return {
        ...state,
        currentFilter: payload.filterValue,
        currentSorting: payload.sorting,
        searchText: payload.searchText,
      };
    }
    case ActionTypes.GET_ALL_PROSPECTS_SUCCESS: {
      return { ...state, prospects: payload, meta: meta };
    }
    case ActionTypes.UPDATE_COLUMNS_SORT_VALUE: {
      return {
        ...state,
        columnOrder: Object.assign(
          {},
          { ...initialState.columnOrder },
          payload,
        ),
      };
    }
    case ActionTypes.CLEAN_LOADED_PROSPECTS: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default manageProspectsReducer;
