import moment from 'moment';
import { isEmpty, isNil, pathOr } from 'ramda';
import { validateDate } from '../../utils/validations';

const validate = (values: Object, props: Object) => {
  const errors = {};

  const { editPropertyMoveInDate } = values;

  /**
   * Required Fields
   */
  if (isNil(editPropertyMoveInDate) || isEmpty(editPropertyMoveInDate)) {
    errors['editPropertyMoveInDate'] = 'Property Move-In Date is required.';
  }

  /**
   * Valid Dates
   */
  if (
    !errors['editPropertyMoveInDate'] &&
    !validateDate(editPropertyMoveInDate)
  ) {
    errors['editPropertyMoveInDate'] =
      'Property Move-In Date must be a valid date format (MM/DD/YYYY).';
  }

  /**
   * Move-In Date cannot be in the current fiscal period.
   */
  const fiscalPeriodStartDate = pathOr(
    null,
    ['openFiscalPeriod', 'startDate'],
    props,
  );
  if (editPropertyMoveInDate && fiscalPeriodStartDate) {
    const momentMoveInDate = moment(editPropertyMoveInDate);
    const momentStartDate = moment(fiscalPeriodStartDate);

    if (momentMoveInDate.isSameOrAfter(momentStartDate, 'days')) {
      const formattedStartDate = momentStartDate.format('MM-DD-YYYY');
      errors[
        'editPropertyMoveInDate'
      ] = `Property Move-In Date cannot be a date in the current fiscal period (on or after ${formattedStartDate}).`;
    }
  }

  return errors;
};

export default validate;
