import messages from './messages';
import moment from 'moment';
import { type, pathOr } from 'ramda';
import { LEASE_CHANGE_OUT_REF_ID } from './constants';

const validate = (values: Object, { intl, error, report }: Object) => {
  const runByNonFutureDate = pathOr(false, ['runByNonFutureDate'], report);
  const errors = {};
  let datesValid = true;
  if (error && error.async) {
    errors._error = error;
  }
  const startDate = moment(values.startDate);
  const endDate = moment(values.endDate);
  const date = moment(values.date);
  const today = moment();

  if (report.referenceId === LEASE_CHANGE_OUT_REF_ID && !values.leaseType) {
    errors.leaseType = intl.formatMessage(messages.leaseTypeRequiredError);
  }

  if (!startDate.isValid() || type(values.startDate) === 'String') {
    errors.startDate = intl.formatMessage(messages.invalidDate);
    datesValid = false;
  }

  if (!endDate.isValid() || type(values.endDate) === 'String') {
    errors.endDate = intl.formatMessage(messages.invalidDate);
    datesValid = false;
  }
  if (datesValid) {
    if (startDate.toDate() > endDate.toDate()) {
      errors._error = {
        sync: true,
        async: false,
        message: intl.formatMessage(messages.noEndDateBeforeStartDate),
      };
    }
    if (
      runByNonFutureDate &&
      (today.isBefore(startDate, 'day') || today.isBefore(endDate, 'day'))
    ) {
      errors._error = {
        sync: true,
        async: false,
        message: intl.formatMessage(messages.noFutureDatesAllowed),
      };
    }
    if (values.startPeriod > values.endPeriod) {
      errors._error = {
        sync: true,
        async: false,
        message: intl.formatMessage(messages.noEndPeriodBeforeStartPeriod),
      };
    }
  }

  if (!date.isValid() || type(values.date) === 'String') {
    errors.date = intl.formatMessage(messages.invalidDate);
  }

  if (values.actualPeriod) {
    // Scheduled period can be anytime (past, now, future)
    // Actual period can be before or same as the scheduled period,
    // but can't be after the scheduled period, and can't be in the future from now.
    const nowPeriod = moment().format('YYYYMM');
    if (values.actualPeriod > values.scheduledPeriod) {
      errors._error = {
        sync: true,
        async: false,
        message: intl.formatMessage(
          messages.noActualPeriodAfterScheduledPeriod,
        ),
      };
    } else if (values.actualPeriod > nowPeriod) {
      errors._error = {
        sync: true,
        async: false,
        message: intl.formatMessage(messages.noActualPeriodAfterNow),
      };
    }
  }

  return errors;
};

export default validate;
