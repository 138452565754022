import { defineMessages } from 'react-intl';

/* eslint-disable max-len */
const messages = defineMessages({
  waitlistUpdateSuccess: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.WaitlistSection.Update.Success',
    defaultMessage: 'Waitlist details updated successfully.',
  },
  waitlistUpdateFailure: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.WaitlistSection.Update.Failure',
    defaultMessage: 'Something went wrong. Waitlist details not updated.',
  },
  waitlistNotesTitle: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.WaitlistSection.WaitlistNotes.Title',
    defaultMessage: 'Waitlist Notes',
  },
  waitlistDateTimeTitle: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.WaitlistSection.WaitlistDateTime.Title',
    defaultMessage: 'Waitlist Date and Time',
  },
  editViewNote: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.WaitlistSection.Inputs.EditViewNote',
    defaultMessage: 'Edit/View Note',
  },
  waitlistDateTimeInFuture: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.WaitlistSection.Inputs.WaitlistDateTime.Invalid.InFuture',
    defaultMessage: 'Waitlist start date and time cannot be in the future.',
  },
  saveChanges: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.WaitlistSection.Inputs.WaitlistNotes.SaveChanges',
    defaultMessage: 'Save Changes',
  },
  cancel: {
    id: 'App.ApplicationProfile.LeaseApplicationTab.WaitlistSection.Inputs.WaitlistNotes.Cancel',
    defaultMessage: 'Cancel',
  },
});

export default messages;
