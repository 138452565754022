import {
  formatCurrency,
  formatPercentage,
  formatNumberToString,
  formatDateDisplayLocal,
} from '@fortress-technology-solutions/fortress-component-library/utils/index';
import { RENT_ROLL_TOTAL_COLUMNS } from './constantsV2';

export const buildFloorPlanSummaryForCSVExport = ({ results }) => {
  const floorPlanSummary = [
    { id: 'floorPlan', label: 'Floor plan' },
    { id: 'units', label: 'Units' },
    { id: 'sqFt', label: 'SqFt' },
    { id: 'marketRent', label: 'Market Rent' },
    { id: 'quotingRent', label: 'Quoting Rent' },
    { id: 'leasedRentAverage', label: 'Leased Rent Average' },
    { id: 'marketRentSqFt', label: 'Market Rent/SqFt' },
    { id: 'leasedRentSqFt', label: 'Leased Rent/SqFt' },
    { id: 'occupiedUnits', label: 'Occupied Units' },
    { id: 'occupancyPct', label: 'Occupancy %' },
  ];

  const floorPlans = {};
  results.forEach((unit) => {
    const floorPlanValues = floorPlans[unit.floorPlan] || {
      leasedRentTotal: 0,
      leasedUnits: 0,
      leasedSqFt: 0,
      marketRentSqFt: 0,
      marketRentTotal: 0,
      marketRentUnits: 0,
      netMaxRentSqFt: 0,
      netMaxRentTotal: 0,
      netMaxRentUnits: 0,
      occupiedUnits: 0,
      quotingRent: 0,
      sqFt: '',
      units: 0,
    };

    floorPlanValues.sqFt = unit.sqFt;

    const currentLeasedRentValue = unit.leasedRent || 0;
    if (currentLeasedRentValue) {
      floorPlanValues.leasedRentTotal += currentLeasedRentValue;
      floorPlanValues.leasedUnits += 1;
      floorPlanValues.leasedSqFt += unit.sqFt;
    }

    const floorPlanMarketRent = unit.marketRent;
    if (floorPlanMarketRent) {
      floorPlanValues.marketRentTotal += floorPlanMarketRent;
      floorPlanValues.marketRentUnits += 1;
      floorPlanValues.marketRentSqFt += unit.sqFt;
    }

    const netMaxRent = unit.netMaxRent;
    if (netMaxRent) {
      floorPlanValues.netMaxRentTotal += netMaxRent;
      floorPlanValues.netMaxRentUnits += 1;
      floorPlanValues.netMaxRentSqFt += unit.sqFt;
    }

    floorPlanValues.units += 1;
    floorPlanValues.occupiedUnits += !unit.isVacant ? 1 : 0;
    floorPlanValues.quotingRent =
      unit.maxRentPercent > 0
        ? (unit.maxRentPercent / 100) * unit.fpQuotingRent
        : unit.fpQuotingRent;

    floorPlans[unit.floorPlan] = floorPlanValues;
  });

  /**
   * Rent Roll - Floor Plan Summaries
   */

  let fpLeasedRentAverageTotal = 0;
  let fpLeasedUnits = 0;
  let fpLeasedSqFt = 0;
  let fpMarketRentAverageTotal = 0;
  let fpMarketRentSqFt = 0;
  let fpMarketRentUnits = 0;
  // let fpNetMaxRentAverageTotal = 0;
  // let fpNetMaxRentSqFt = 0;
  // let fpNetMaxRentUnits = 0;
  let fpOccupiedUnits = 0;
  let quotingRentTotal = 0;
  let fpSqFtTotal = 0;
  let fpUnits = 0;

  let floorPlanRows = Object.keys(floorPlans).map((key) => {
    const fp = floorPlans[key];
    const {
      leasedRentTotal,
      leasedUnits,
      leasedSqFt,
      marketRentSqFt,
      marketRentTotal,
      marketRentUnits,
      netMaxRentSqFt,
      netMaxRentTotal,
      netMaxRentUnits,
      occupiedUnits,
      quotingRent,
      sqFt,
      units: numUnits,
    } = fp;
    const fpOccupancyPct = occupiedUnits / fp.units;
    const leasedRentAverage =
      leasedUnits > 0 ? leasedRentTotal / leasedUnits : 0;
    const leasedRentSqFt =
      leasedUnits > 0 ? leasedRentAverage / (leasedSqFt / leasedUnits) : 0;
    const marketRentAverage =
      marketRentUnits > 0 ? marketRentTotal / marketRentUnits : 0;
    const marketRentSqFeet =
      marketRentAverage > 0
        ? marketRentAverage / (marketRentSqFt / marketRentUnits)
        : 0;
    const netMaxRentAverage =
      netMaxRentUnits > 0 ? netMaxRentTotal / netMaxRentUnits : 0;
    const netMaxRentSqFeet =
      netMaxRentAverage > 0
        ? netMaxRentAverage / (netMaxRentSqFt / netMaxRentUnits)
        : 0;
    const occupancyPct = fpOccupancyPct;

    // Totals used in Grand Total Calculations
    fpLeasedRentAverageTotal += leasedRentAverage * leasedUnits;
    fpLeasedSqFt += leasedSqFt;
    fpLeasedUnits += leasedUnits;
    fpMarketRentAverageTotal += marketRentAverage * marketRentUnits;
    fpMarketRentSqFt += marketRentSqFt;
    fpMarketRentUnits += marketRentUnits;
    // fpNetMaxRentAverageTotal += netMaxRentAverage * netMaxRentUnits;
    // fpNetMaxRentSqFt += netMaxRentSqFt;
    // fpNetMaxRentUnits += netMaxRentUnits;
    fpOccupiedUnits += occupiedUnits;
    quotingRentTotal += quotingRent * numUnits;
    fpSqFtTotal += sqFt * numUnits;
    fpUnits += numUnits;

    return {
      floorPlan: key,
      leasedRentAverage: `"${formatCurrency(leasedRentAverage)}"`,
      leasedRentSqFt: `"${formatNumberToString(leasedRentSqFt)}"`,
      marketRent: `"${formatCurrency(marketRentAverage)}"`,
      marketRentSqFt: marketRentSqFeet,
      netMaxRent: Math.round(netMaxRentAverage),
      netMaxRentSqFt: Math.round(netMaxRentSqFeet),
      occupancyPct: formatPercentage(occupancyPct),
      occupiedUnits: Math.round(occupiedUnits),
      quotingRent: Math.round(quotingRent),
      sqFt: `"${formatNumberToString(sqFt)}"`,
      units: `"${formatNumberToString(numUnits)}"`,
    };
  });

  const floorPlanColumnsRow = Object.values(floorPlanSummary).map(
    ({ label }) => label,
  );

  const floorPlanSummaryRows = [
    'Floor Plan Summary',
    floorPlanColumnsRow.join(','),
  ];

  floorPlanRows.forEach((floorPlanRow) => {
    floorPlanSummaryRows.push(
      Object.values(floorPlanSummary)
        .map(({ id }) => floorPlanRow[id])
        .join(','),
    );
  });

  const fpSqFtGrand = Math.round(fpSqFtTotal / fpUnits, 0);

  const fpLeasedRentAverageGrand =
    fpLeasedUnits > 0 ? fpLeasedRentAverageTotal / fpLeasedUnits : 0;
  const fpLeasedRentSqFtGrand =
    fpLeasedUnits > 0
      ? fpLeasedRentAverageGrand / (fpLeasedSqFt / fpLeasedUnits)
      : 0;
  const fpMarketRentGrand =
    fpMarketRentUnits > 0 ? fpMarketRentAverageTotal / fpMarketRentUnits : 0;
  const fpMarketRentSqFtGrand =
    fpMarketRentUnits > 0
      ? fpMarketRentGrand / (fpMarketRentSqFt / fpMarketRentUnits)
      : 0;
  // const fpNetMaxRentGrand =
  //   fpNetMaxRentUnits > 0 ? fpNetMaxRentAverageTotal / fpNetMaxRentUnits : 0;
  // const fpNetMaxRentSqFtGrand =
  //   fpNetMaxRentUnits > 0
  //     ? fpNetMaxRentGrand / (fpNetMaxRentSqFt / fpNetMaxRentUnits)
  //     : 0;
  const fpOccupancyPctGrand = formatPercentage(
    !isNaN(fpOccupiedUnits / fpUnits) ? fpOccupiedUnits / fpUnits : 0,
  );
  const quotingRentGrand = quotingRentTotal / fpUnits;

  floorPlanSummaryRows.push([
    'Grand Total',
    fpUnits,
    !isNaN(fpSqFtGrand) ? fpSqFtGrand : 0,
    `"${formatCurrency(Math.round(fpMarketRentGrand))}"`,
    `"${formatCurrency(
      !isNaN(quotingRentGrand) ? Math.round(quotingRentGrand) : 0,
    )}"`,
    `"${formatCurrency(
      !isNaN(fpLeasedRentAverageGrand)
        ? Math.round(fpLeasedRentAverageGrand)
        : 0,
    )}"`,
    fpMarketRentSqFtGrand,
    fpLeasedRentSqFtGrand,
    fpOccupiedUnits,
    fpOccupancyPctGrand,
  ]);

  return floorPlanSummaryRows;
};

export const buildFloorPlanGrandTotalForCSVExport = ({
  totals,
  filteredHeaders,
}) => {
  return filteredHeaders.map(({ id }, index) => {
    let result = '';
    if (index === 0) {
      result = '"Grand Total"';
    }
    if (totals[id]) {
      const { variant, value } = totals[id];
      result =
        variant === 'currency'
          ? `"${formatCurrency(value)}"`
          : `"${formatNumberToString(value)}"`;
    }
    return result;
  });
};

export const buildFloorPlanRowsFormCSVExport = ({
  rows,
  filteredHeaders,
  totals,
}) => {
  const format = {
    applicant: (value) => value ?? '',
    collectionNote: (value) => (Array.isArray(value) ? value?.join(', ') : ''),
    resident: (value) => (value ? `${value}` : ''),
    otherHouseholdMembers: (value) =>
      Array.isArray(value) ? `${value?.join(', ')}` : '',
    currency: (value) => formatCurrency(value),
    boolean: (value) => (value ? 'Yes' : ''),
    date: (value) => (value ? formatDateDisplayLocal(value) : ''),
    moveInReady: (value) =>
      value ? (value === 'Ready' ? value : formatDateDisplayLocal(value)) : '',
    texting: ({ props }) => {
      return `New = ${props?.NEW ?? 0}, Unresolved = ${props.UNRESOLVED ?? 0}`;
    },
  };
  return rows.reduce((prev, result) => {
    const csvRow = [];

    filteredHeaders.forEach(({ id }) => {
      const variant = result[id]?.variant;
      const resultValue = result[id]?.value;
      let value = variant === 'currency' && !resultValue ? 0 : resultValue;

      if (RENT_ROLL_TOTAL_COLUMNS[id] && value) {
        totals[id].value = parseFloat(totals[id].value) + parseFloat(value);
      }

      if (format[id] && value) {
        value = format[id](value);
      } else if (format[variant]) {
        value = format[variant](value);
      }

      let cellValue = value ?? '';
      const idsToPreventExcelFromChangingToDates = ['bedsBaths'];
      if (idsToPreventExcelFromChangingToDates.includes(id)) {
        csvRow.push(`'${cellValue}'`);
      } else {
        csvRow.push(`"${cellValue}"`);
      }
    });

    prev.push(
      csvRow.join(',').replaceAll('null', '').replaceAll('undefined', ''),
    );
    return prev;
  }, []);
};
