import moment from 'moment';
import messages from './messages';
import { type } from 'ramda';

const validate = (values: Object, { intl, fiscalPeriod }: Object) => {
  const { batchDate } = values;
  const errors = {};

  const selectedDate = moment(batchDate);
  const fiscalPeriodStartDate = moment(fiscalPeriod?.startDate);
  const today = moment();

  if (selectedDate.isValid() && type(batchDate) !== 'String') {
    if (selectedDate.isBefore(fiscalPeriodStartDate, 'day')) {
      errors.batchDate = intl.formatMessage(messages.dateInPast);
    } else if (selectedDate.isAfter(today, 'day')) {
      errors.batchDate = intl.formatMessage(messages.dateInFuture);
    }
  } else {
    errors.batchDate = intl.formatMessage(messages.invalidDate);
  }

  return errors;
};

export default validate;
