import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { ControlLabel } from 'react-bootstrap';
import messages from '../../../messages';
import FilterIconButton from '../../../../../components/FilterIconButton';
import React, { useEffect } from 'react';
import { SearchBar } from './SearchBar';
const OPS = 'ops';

type Props = {
  isFilterActive: boolean,
  setIsFilterActive: Function,
  filterReversed: Function,
  formatMessage: Function,
  ledger: string,
  onFilterChange: Function,
  onJournalChange: Function,
  onLedgerChange: Function,
  subjournal: string,
  subJournals: Array,
  onChange: Function,
  clearSearch: Function,
  resetSearch: Function,
};

export const TopFiltersAndSearchBar = (props: Props) => {
  const {
    ledger,
    onChange,
    subjournal,
    subJournals,
    clearSearch,
    resetSearch,
    formatMessage,
    filterReversed,
    onFilterChange,
    onJournalChange,
    onLedgerChange,
    isFilterActive,
    setIsFilterActive,
    transactions,
    isFiltersPopulated,
  } = props;

  useEffect(() => {
    if ((!transactions || transactions.length === 0) && !isFiltersPopulated) {
      setIsFilterActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions]);
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item lg={12} xl={7}>
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="space-evenly"
          alignContent="flexStart"
          alignItems="center"
          spacing={1}
        >
          <Stack direction="column" sx={{ flexGrow: 1 }}>
            <ControlLabel>Ledger</ControlLabel>
            <select onChange={onLedgerChange} className="form-control input-lg">
              <option value="ops">{formatMessage(messages.resident)}</option>
              <option value="security">
                {formatMessage(messages.security)}
              </option>
            </select>
          </Stack>
          {ledger === OPS && (
            <Stack direction="column" sx={{ flexGrow: 1 }}>
              <ControlLabel>Subjournal</ControlLabel>
              <select
                onChange={onJournalChange}
                className="form-control input-lg subjournal-dropdown"
                value={subjournal}
              >
                <option key={''} value={''}>
                  {formatMessage(messages.allSubjournals)}
                </option>
                {Array.isArray(subJournals) &&
                  subJournals.map((journal) => {
                    return (
                      <option key={journal.value} value={journal.value}>
                        {journal.text}
                      </option>
                    );
                  })}
              </select>
            </Stack>
          )}
          <div
            className="checkbox custom-checkbox"
            style={{ minWidth: '200px' }}
          >
            <label>
              <input
                type="checkbox"
                checked={filterReversed}
                onChange={onFilterChange}
              />
              <span className="custom-check-square" />
              Hide Reversed Transactions
            </label>
          </div>
        </Stack>
      </Grid>
      <Grid item lg={12} xl={5}>
        <Stack
          direction="row"
          justifyContent={{ lg: 'center', xl: 'flex-end' }}
          alignItems="center"
        >
          <FilterIconButton
            className="pull-right"
            active={isFilterActive}
            onClick={() => setIsFilterActive(!isFilterActive)}
            disabled={
              (!transactions || transactions.length === 0) &&
              !isFiltersPopulated
            }
          />
          <SearchBar
            onChange={onChange}
            clearSearch={clearSearch}
            resetSearch={resetSearch}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
