import React from 'react';
import { Field } from 'redux-form';
import { Col, Row } from 'react-bootstrap';

import messages from './messages';
import {
  extractCurrentDateFormat,
  renderDateField,
  renderRadioGroupField,
  renderTextAreaField,
  renderTextField,
} from '../../../utils/redux-form-helper';

type Props = {
  intl: Object,
  bankruptcyDischargedDisabled: boolean,
  resetBankruptcyDischarged: Function,
};

const RenderConvictedFelonyField = ({ intl, propertyState }) => (
  <Row>
    <Col xs={12} md={10}>
      <h3 className="required">
        {intl.formatMessage(messages.beenConvictedAFelonyLabel)}
      </h3>
    </Col>
    <Col xs={12} md={2} className="text-right">
      <Field
        name="convicted"
        fieldName="convicted"
        component={renderRadioGroupField}
        options={[
          { text: 'Yes', value: 'yes', disabled: false },
          { text: 'No', value: 'no', disabled: false },
        ]}
        inline
        className="form-options"
      />
    </Col>
    <Col xs={12} md={12}>
      <Field
        name="convictedDescription"
        component={renderTextAreaField}
        className="input-lg"
        placeholder={intl.formatMessage(messages.ifYesPleaseExplainPlaceholder)}
        maxLength="500"
      />
    </Col>
  </Row>
);

const RenderdBanckruptcyFields = ({
  intl,
  bankruptcyDischargedDisabled,
  resetBankruptcyDischarged,
}) => (
  <div>
    <Row>
      <Col xs={12} md={10}>
        <h3 className="required">
          {intl.formatMessage(messages.filedOrInvolvedBanckruptcyLabel)}
        </h3>
      </Col>
      <Col xs={12} md={2} className="text-right">
        <Field
          name="bankruptcy"
          fieldName="bankruptcy"
          component={renderRadioGroupField}
          options={[
            { text: 'Yes', value: 'yes', disabled: false },
            { text: 'No', value: 'no', disabled: false },
          ]}
          onClick={resetBankruptcyDischarged}
          inline
          className="form-options"
        />
      </Col>
      <Col xs={12} md={12}>
        <Field
          name="bankruptcyDescription"
          component={renderTextAreaField}
          className="input-lg"
          placeholder={intl.formatMessage(
            messages.ifYesPleaseExplainPlaceholder,
          )}
          maxLength="500"
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={4}>
        <label> Type Filed </label>
        <Field
          name="bankruptcyCaseFiled"
          component={renderTextField}
          placeholder={intl.formatMessage(messages.typeFiledPlaceholder)}
          className="input-lg"
        />
      </Col>
      <Col xs={12} md={4}>
        <label> Date Filed </label>
        <Field
          name="bankruptcyDateFiled"
          component={renderDateField}
          bsSize="lg"
          placeholder={extractCurrentDateFormat(intl)}
          dateFormat={extractCurrentDateFormat(intl)}
        />
      </Col>
      <Col xs={12} md={4} className="text-center">
        <label>Has the bankruptcy been discharged?</label>
        <Field
          name="bankruptcyDischarged"
          fieldName="bankruptcyDischarged"
          component={renderRadioGroupField}
          options={[
            { text: 'Yes', value: 'yes', disabled: false },
            { text: 'No', value: 'no', disabled: false },
          ]}
          inline
          disabled={bankruptcyDischargedDisabled}
          className="form-options"
        />
      </Col>
    </Row>
  </div>
);

const OtherInformation = ({
  intl,
  bankruptcyDischargedDisabled,
  resetBankruptcyDischarged,
  propertyState,
  flags,
  propertyCity,
}: Props) => (
  <div className="form-block">
    <div className="form-content">
      <Row>
        <Col xs={12} md={1}>
          <h3 className="required">{intl.formatMessage(messages.petsLabel)}</h3>
        </Col>
        <Col xs={12} md={11}>
          <Field
            name="pets"
            fieldName="pets"
            component={renderRadioGroupField}
            options={[
              { text: 'Yes', value: 'yes', disabled: false },
              { text: 'No', value: 'no', disabled: false },
            ]}
            inline
            className="form-options"
          />
        </Col>
      </Row>
    </div>
    <div className="form-content">
      {propertyCity !== 'Seattle' && <RenderConvictedFelonyField intl={intl} />}
      <RenderdBanckruptcyFields
        intl={intl}
        bankruptcyDischargedDisabled={bankruptcyDischargedDisabled}
        resetBankruptcyDischarged={resetBankruptcyDischarged}
      />
    </div>
  </div>
);

export default OtherInformation;
