import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import { and, not } from 'ramda';
import {
  renderTextField,
  renderSelectField,
} from '../../utils/redux-form-helper';

type Props = {
  field: string,
  onDelete: Function,
  suffixList: Array<Object>,
  minorRelationshipList: Array<Object>,
  affordableRelationship: Array<Object>,
  fieldsReadOnly: boolean,
  isNew: boolean,
  isAffordable: boolean,
  leaseLocked: boolean,
};

const Minor = ({
  field,
  suffixList,
  minorRelationshipList,
  affordableRelationship,
  onDelete,
  isNew,
  fieldsReadOnly,
  isAffordable,
  leaseLocked,
}: Props) => {
  const disableFields = and(not(isNew), fieldsReadOnly);
  return (
    <Row>
      <div className="container-of-inputs">
        <Col xs={6} sm={2}>
          <Field
            name={`${field}.firstName`}
            component={renderTextField}
            maxLength="35"
            disabled={disableFields || leaseLocked}
          />
        </Col>
        <Col xs={6} sm={2}>
          <Field
            name={`${field}.middleName`}
            component={renderTextField}
            maxLength="35"
            disabled={disableFields || leaseLocked}
          />
        </Col>
        <Col xs={6} sm={2}>
          <Field
            name={`${field}.lastName`}
            component={renderTextField}
            maxLength="35"
            disabled={disableFields || leaseLocked}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.suffixId`}
            component={renderSelectField}
            options={suffixList}
            disabled={disableFields || leaseLocked}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.preferredName`}
            component={renderTextField}
            maxLength="35"
            disabled={disableFields || leaseLocked}
          />
        </Col>
        {isAffordable && (
          <Col xs={6} sm={1}>
            <Field
              name={`${field}.affordableRelationship`}
              component={renderSelectField}
              options={affordableRelationship}
              disabled={leaseLocked}
            />
          </Col>
        )}
        <Col xs={6} sm={2}>
          <Field
            name={`${field}.relationship`}
            component={renderSelectField}
            options={minorRelationshipList}
            disabled={leaseLocked}
          />
        </Col>
        <Col xs={6} sm={isAffordable ? 1 : 2}>
          <Field
            name={`${field}.age`}
            component={renderTextField}
            maxLength="35"
            disabled={disableFields || leaseLocked}
          />
        </Col>
      </div>
      <div className="container-of-trash">
        <div className="row-remove-btn">
          <a onClick={onDelete} disabled={leaseLocked}>
            <i className="et-trash" />
          </a>
        </div>
      </div>
    </Row>
  );
};

export default Minor;
