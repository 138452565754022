import Header from './Header';
import AccordionContainer from './AccordionContainer';
import { Col, Button } from 'react-bootstrap';
import { useEffect, useState, useMemo } from 'react';
import useFetchSetWorkNumber from '../../hooks/data-fetching/useFetchSetWorkNumber';
import { isAppCompleted, checkDocumentExists } from './utils';
import ConfirmModal from '../ConfirmModal';
import {
  DISABLED_SUBMIT_SCREENING,
  SUBMIT_SCREENING,
  SUBMITTED,
  SCREENING_COMPLETE,
  LOADING,
  EMPLOYER_HISTORY_MODAL,
  EMPLOYER_PAYSTUB_MODAL,
} from './constants';
import { FormattedMessage, injectIntl } from 'react-intl';
import messages from './messages';
import Spinner from '../Spinner';
import { useAsyncDownloadDocumentV2 } from '../../hooks/downloadDocument';
import { toastr } from 'react-redux-toastr';

export const generateTableRowObjects = (applicant, permissions) => {
  const latestScreening = applicant?.twnEmploymentHistory?.[0] ?? {};
  const employmentHistory = applicant?.twnEmploymentHistory?.[0] ?? {};
  const { applicantId, twnEmploymentRecords = [] } = latestScreening;
  const hasMultipleSubmitPerm = permissions.some(
    (perm) => perm.scope === 'twn-affordable-unlimited-screening-submit',
  );
  const isDisabled =
    !hasMultipleSubmitPerm && employmentHistory.hasPaystubRequested;
  const augmentedRecords = twnEmploymentRecords.map((record) => {
    // Should be disabled if paystub already submitted
    return {
      isLoading: false,
      isDisabled,
      applicantId,
      ...record,
    };
  });
  return augmentedRecords;
};

export const getScreeningState = (appState) => {
  const { twnEmploymentHistory, isRescreen, ssn, arn } = appState;
  const hasRecords = (twnEmploymentHistory?.length ?? 0) > 0;
  const isError = twnEmploymentHistory?.[0]?.isError ?? false;
  const appCompleted = isAppCompleted(ssn, arn);
  if (isRescreen) {
    return SUBMIT_SCREENING;
  }

  if (!appCompleted || isError) {
    return DISABLED_SUBMIT_SCREENING;
  }

  if (!hasRecords && !isError) {
    return SUBMIT_SCREENING;
  }

  return SUBMITTED;
};

export const generateAccordions = (rawData, permissions) => {
  return rawData.map((applicant) => {
    const {
      id: applicantId,
      twnEmploymentHistory,
      applicantCustomer: {
        customer: {
          firstName,
          lastName,
          socialSecurityNumber: ssn,
          alienRegistration: arn,
        },
      },
      isRescreen,
    } = applicant;
    const name = `${firstName} ${lastName}`;
    const status = SCREENING_COMPLETE;
    const screeningState = getScreeningState({
      twnEmploymentHistory,
      isRescreen,
      ssn,
      arn,
    });
    const expandable =
      screeningState !== SUBMIT_SCREENING &&
      screeningState !== DISABLED_SUBMIT_SCREENING;
    const isError = applicant?.twnEmploymentHistory?.[0]?.isError ?? false;
    const twnStatus =
      applicant?.twnEmploymentHistory?.[0]?.statusMessage ?? 'SUCCESS';
    return {
      applicantId,
      name,
      status,
      screeningState,
      expandable,
      isError,
      twnStatus,
      screeningDetails: generateTableRowObjects(applicant, permissions),
    };
  });
};

export const requestPdf = async (
  paystubDetailsId,
  jobTitle,
  workNumberActions,
  intl,
) => {
  const fileName = `${jobTitle}_paystub_details`;

  const pdfResponse = await workNumberActions.requestPaystubPDF(
    paystubDetailsId,
    fileName,
  );
  if (!checkDocumentExists(pdfResponse)) {
    toastr.info(
      intl.formatMessage(messages.failedPdfAutoGen),
      intl.formatMessage(messages.pleaseClickDownload),
    );
  }
  return workNumberActions.reload(false);
};

const DataDisplayer = (props) => {
  const {
    householdId,
    organizationId,
    propertyId,
    currentUser,
    intl,
    ...rest
  } = props;
  const userPermissions = useMemo(
    () => currentUser?.permissions ?? [],
    [currentUser],
  );
  const [workNumberRawData, workNumberActions, isLoadingWorkNumberData] =
    useFetchSetWorkNumber(organizationId, propertyId, householdId);
  const [accordions, setAccordions] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [modalType, setModalType] = useState(EMPLOYER_HISTORY_MODAL);
  const [selectedApplicantId, setSelectedApplicantId] = useState('');
  const [selectedEmploymentRecordId, setSelectedEmploymentRecordId] =
    useState('');
  useEffect(() => {
    const processedData = generateAccordions(
      workNumberRawData,
      userPermissions,
    );
    setAccordions(processedData);
  }, [workNumberRawData, userPermissions]);
  const onEmployerHistorySubmit = (applicantId: string) => {
    setModalType(EMPLOYER_HISTORY_MODAL);
    setShowConfirm(true);
    setSelectedApplicantId(applicantId);
  };
  const onPaystubSubmit = (employmentRecordId: string, applicantId: string) => {
    setModalType(EMPLOYER_PAYSTUB_MODAL);
    setShowConfirm(true);
    setSelectedApplicantId(applicantId);
    setSelectedEmploymentRecordId(employmentRecordId);
  };

  const onCancel = () => {
    setShowConfirm(false);
  };

  const handleDownloadDocument = useAsyncDownloadDocumentV2(
    propertyId,
    organizationId,
  );

  const onEmployerHistoryRequestConfirm = async () => {
    setShowConfirm(false);
    // set loading state for submit button
    setAccordions((prevAccordions) => {
      const index = prevAccordions.findIndex(
        (accordian) => accordian.applicantId === selectedApplicantId,
      );
      if (index !== -1) {
        const newAccordions = prevAccordions;
        newAccordions[index].screeningState = LOADING;
        return newAccordions;
      }
      return prevAccordions;
    });
    // request screening data
    await workNumberActions.submitScreening(selectedApplicantId);
  };

  const onRequestPaystubPdf = (paystubDetailsId, jobTitle) =>
    requestPdf(paystubDetailsId, jobTitle, workNumberActions, intl);

  const onPaystubRequestConfirm = async () => {
    setShowConfirm(false);
    // set loading state for the button
    setAccordions((prevAccordions) => {
      const index = prevAccordions.findIndex(
        (accordian) => accordian.applicantId === selectedApplicantId,
      );
      if (index !== -1) {
        const newAccordions = prevAccordions;
        const screeningDetails = newAccordions[index].screeningDetails;
        screeningDetails.forEach((detail) => {
          detail.isDisabled = true;
        });
        const erIndex = screeningDetails.findIndex(
          (employmentRecordDetail) =>
            employmentRecordDetail.id === selectedEmploymentRecordId,
        );
        if (erIndex !== -1) {
          screeningDetails[erIndex].isLoading = true;
        }
        return newAccordions;
      }
      return prevAccordions;
    });
    // submit request
    const resp = await workNumberActions.submitPaystubScreening(
      selectedApplicantId,
      selectedEmploymentRecordId,
    );

    if (!resp) {
      // something went wrong with paystub request so don't generate document
    } else {
      // create and get pdf on success
      // TODO: need to handle case where submit paystub succeeds but this generation doesn't
      const {
        paystubDetailsId,
        updatedEmploymentRecord: { jobTitle },
      } = resp;
      await requestPdf(paystubDetailsId, jobTitle, workNumberActions, intl);
    }
  };

  const historyModalFooter = (
    <div className="row">
      <div className="col-sm-6">
        <Button
          type="button"
          bsStyle="default"
          className="pull-right"
          onClick={onCancel}
        >
          <FormattedMessage {...messages.cancel} />
        </Button>
      </div>
      <div className="col-sm-6">
        <Button
          type="submit"
          bsStyle="primary"
          className="pull-left"
          onClick={onEmployerHistoryRequestConfirm}
        >
          <FormattedMessage {...messages.confirm} />
        </Button>
      </div>
    </div>
  );

  const historyModalBody = (
    <div className="row" style={{ textAlign: 'center' }}>
      <h2 style={{ margin: '20px 50px 3px 50px', fontSize: '20px' }}>
        <strong>
          <FormattedMessage {...messages.modalHeader} />
        </strong>
      </h2>
      <p style={{ margin: '20px 15px 3px 15px', fontSize: '14px' }}>
        <strong>
          <FormattedMessage {...messages.confirmTextBold} />
        </strong>
        <br />
        <FormattedMessage {...messages.confirmScreeningRequest} />.
      </p>
    </div>
  );

  const paystubModalBody = (
    <div className="row" style={{ textAlign: 'center' }}>
      <h2 style={{ margin: '20px 50px 3px 50px', fontSize: '20px' }}>
        <strong>
          <FormattedMessage {...messages.modalHeader} />
        </strong>
      </h2>
      <p style={{ margin: '20px 15px 3px 15px', fontSize: '14px' }}>
        <strong>
          <FormattedMessage {...messages.confirmTextBold} />
        </strong>
        <br />
        <FormattedMessage {...messages.confirmPaystubMessageOne} />
        <br />
        <FormattedMessage {...messages.confirmMessageTwo} />
      </p>
      <p style={{ margin: '20px 15px 3px 15px', fontSize: '14px' }}>
        <FormattedMessage {...messages.confirmPaystubMessageNote} />
      </p>
    </div>
  );

  const paystubModalFooter = (
    <div className="row">
      <div className="col-sm-6">
        <Button
          type="button"
          bsStyle="default"
          className="pull-right"
          onClick={onCancel}
        >
          <FormattedMessage {...messages.cancel} />
        </Button>
      </div>
      <div className="col-sm-6">
        <Button
          type="submit"
          bsStyle="primary"
          className="pull-left"
          onClick={onPaystubRequestConfirm}
        >
          <FormattedMessage {...messages.confirm} />
        </Button>
      </div>
    </div>
  );

  const modalBody =
    modalType === EMPLOYER_HISTORY_MODAL ? historyModalBody : paystubModalBody;
  const modalFooter =
    modalType === EMPLOYER_HISTORY_MODAL
      ? historyModalFooter
      : paystubModalFooter;
  return (
    <>
      <ConfirmModal
        show={showConfirm}
        footer={modalFooter}
        size={'small'}
        onHide={() => {
          setShowConfirm(false);
        }}
      >
        {modalBody}
      </ConfirmModal>
      <div className="container-fluid panel-group">
        <Col xs={12}>
          <Header />
          {isLoadingWorkNumberData ? (
            <Spinner />
          ) : (
            <AccordionContainer
              accordions={accordions}
              onEmployerHistorySubmit={onEmployerHistorySubmit}
              onPaystubSubmit={onPaystubSubmit}
              onDownloadPaystubPDF={handleDownloadDocument}
              onRequestPaystubPdf={onRequestPaystubPdf}
              {...rest}
            />
          )}
        </Col>
      </div>
    </>
  );
};

export default injectIntl(DataDisplayer);
