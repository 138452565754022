export const GET_TRANSFER_REQUIREMENTS =
  'app/ResidentProfile/ResidentTransfer/GET_TRANSFER_REQUIREMENTS';
export const GET_TRANSFER_REQUIREMENTS_SUCCESS =
  'app/ResidentProfile/ResidentTransfer/GET_TRANSFER_REQUIREMENTS_SUCCESS';
export const GET_TRANSFER_REQUIREMENTS_ERROR =
  'app/ResidentProfile/ResidentTransfer/GET_TRANSFER_REQUIREMENTS_ERROR';
export const RESIDENT_COMPLETE_TRANSFER =
  'app/ResidentProfile/ResidentTransfer/RESIDENT_COMPLETE_TRANSFER';
export const RESIDENT_COMPLETE_TRANSFER_ERROR =
  'app/ResidentProfile/ResidentTransfer/RESIDENT_COMPLETE_TRANSFER_ERROR';
export const RESIDENT_COMPLETE_TRANSFER_SUCCESS =
  'app/ResidentProfile/ResidentTransfer/RESIDENT_COMPLETE_TRANSFER_SUCCESS';
