import { defineMessages } from 'react-intl';

const messages = defineMessages({
  transactionType: {
    id: 'App.CreateTransactionBatchDetails.TransactionType',
    defaultMessage: 'Transaction Type',
  },
  unitResident: {
    id: 'App.CreateTransactionBatchDetails.UnitResident',
    defaultMessage: 'Unit/Resident',
  },
  unitTenant: {
    id: 'App.CreateTransactionBatchDetails.UnitTenant',
    defaultMessage: 'Unit/Tenant',
  },
  subjournal: {
    id: 'App.CreateTransactionBatchDetails.Subjournal',
    defaultMessage: 'Subjournal',
  },
  transactionCode: {
    id: 'App.CreateTransactionBatchDetails.TransactionCode',
    defaultMessage: 'Transaction Code',
  },
  amount: {
    id: 'App.CreateTransactionBatchDetails.Amount',
    defaultMessage: 'Amount',
  },
  currentLedgerBalance: {
    id: 'App.CreateTransactionBatchDetails.CurrentLedgerBalance',
    defaultMessage: 'Current Ledger Balance',
  },
  note: {
    id: 'App.CreateTransactionBatchDetails.Note',
    defaultMessage: 'Note',
  },
  newLedgerBalance: {
    id: 'App.CreateTransactionBatchDetails.NewLedgerBalance',
    defaultMessage: 'New Ledger Balance',
  },
  delete: {
    id: 'App.CreateTransactionBatchDetails.Delete',
    defaultMessage: 'Delete',
  },
  deleteBatch: {
    id: 'App.CreateTransactionBatchDetails.deleteBatch',
    defaultMessage: 'Delete Batch',
  },
  saveClose: {
    id: 'App.CreateTransactionBatchDetails.SaveClose',
    defaultMessage: 'Save & Close',
  },
  post: {
    id: 'App.CreateTransactionBatchDetails.Post',
    defaultMessage: 'Post',
  },
  tooltipResident: {
    id: 'App.CreateTransactionBatchDetails.TooltipResident',
    defaultMessage:
      "This is the balance for the resident's ledger and does not include security balance.",
  },
  tooltipTenant: {
    id: 'App.CreateTransactionBatchDetails.TooltipTenant',
    defaultMessage:
      "This is the balance for the tenant's ledger and does not include security balance.",
  },
  addNewTransaction: {
    id: 'App.CreateTransactionBatchDetails.AddNewTransaction',
    defaultMessage: '+ Add New Transaction',
  },
  totalBatchAmount: {
    id: 'App.CreateTransactionBatchDetails.totalBatchAmount',
    defaultMessage: 'Total Batch Amount:',
  },
  households: {
    id: 'App.CreateTransactionBatchDetails.Households',
    defaultMessage: 'Households:',
  },
  units: {
    id: 'App.CreateTransactionBatchDetails.Units',
    defaultMessage: 'Units:',
  },
  totalTransactions: {
    id: 'App.CreateTransactionBatchDetails.TotalTransactions',
    defaultMessage: 'Total Transactions:',
  },
  errorMsgResident: {
    id: 'App.CreateTransactionBatchDetails.ErrorMsgResident',
    defaultMessage:
      // eslint-disable-next-line max-len
      'One or more residents moved out since the batch was last created or edited. Please remove the highlighted transaction(s) in order to save or post.',
  },
  errorMsgTenant: {
    id: 'App.CreateTransactionBatchDetails.ErrorMsgTenant',
    defaultMessage:
      // eslint-disable-next-line max-len
      'One or more tenants moved out since the batch was last created or edited. Please remove the highlighted transaction(s) in order to save or post.',
  },
});

export default messages;
