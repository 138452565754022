import { put, takeLatest, select, throttle } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { actions as toastrActions } from 'react-redux-toastr';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import type { Property } from '../App/types';
import {
  GET_QUALIFICATION_HISTORY,
  UPDATE_HOUSEHOLD_HISTORY,
} from './constants';
import {
  getQualificationHistory as gqh,
  getQualificationHistorySuccess,
  getQualificationHistoryError,
} from './actions';
import ApplicationService from '../../services/applicationService';
import AffordableService from '../../services/affordableService';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* getQualificationHistory(action: Object): Saga<void> {
  const selectedProperty = yield select(selectSelectedProperty);
  validateSelectedProperty(selectedProperty);
  const organizationId = yield select(selectCurrentUserOrganizationId);
  const applicationService = new ApplicationService();
  try {
    const response = yield applicationService.getQualificationHistory(
      organizationId,
      selectedProperty.id,
      action.payload,
    );
    yield put(getQualificationHistorySuccess(response));
  } catch (err) {
    yield put(getQualificationHistoryError(err));
  }
}

export function* getQualificationHistorySaga(): Saga<void> {
  yield takeLatest(GET_QUALIFICATION_HISTORY, getQualificationHistory);
}

export function* updateHouseholdHistory({ payload }: Object): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);

    const { applicationId, qualificationHistoryId, values } = payload;

    const affordableService = new AffordableService();
    yield affordableService.updateHouseholdQualificationHistory(
      organizationId,
      property.id,
      qualificationHistoryId,
      values,
    );
    yield put(gqh(applicationId));
    yield put(
      toastrActions.add({
        type: 'success',
        message: 'Updated household record',
        title: 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: 'Error',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* updateHouseholdQualificationHistorySaga(): Saga<void> {
  yield throttle(500, UPDATE_HOUSEHOLD_HISTORY, updateHouseholdHistory);
}

export default [
  getQualificationHistorySaga,
  updateHouseholdQualificationHistorySaga,
];
