import FileSaver from 'file-saver';
import moment from 'moment';
import { pathOr } from 'ramda';
import { put, select, takeLatest, throttle } from 'redux-saga/effects';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import { actions as toastrActions } from 'react-redux-toastr';
import {
  renderNoDataToastr,
  renderTranslatedMessage,
} from '../../utils/redux-form-helper';
import {
  ASSIGN_UNIT,
  GET_ALL_PROPERTY_SPECIALS,
  GET_ALL_UNIT_STATUSES,
  GET_ALL_UNITS,
  GET_ASSIGNED_UNITS,
  GET_UNIT_QUOTE,
  SAVE_PROPERTY_SPECIALS,
  UNASSIGN_UNIT,
  AMENITY_PROPERTY_FEE,
} from './constants';
import * as manageUnitAvailablityActions from './actions';
import {
  getAllPropertySpecialsError,
  getAllPropertySpecialsSuccess,
  getAllUnitsError,
  getAllUnitsSuccess,
  getAllUnitStatusesError,
  getAllUnitStatusesSuccess,
  getAssignedUnitsError,
  getAssignedUnitsSuccess,
} from './actions';
import messages from './messages';
import { getAllDocuments } from '../ManageDocuments/actions';

import UnitService from '../../services/unitService';
import PropertySpecialsService from '../../services/propertySpecialsService';
import AssignedUnitsService from '../../services/assignedUnitsService';
import FloorPlanPricingService from '../../services/floorPlanPricingService';

import { buildUnitAvailability } from '../../utils/unit-helpers';

import type { Action, Property } from '../App/types';
import type { Saga } from 'redux-saga';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetAllUnits({ payload }: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const unitService = new UnitService();
    const response = yield unitService.getAllByProperty(
      property.id,
      organizationId,
    );
    const amenities = (response?.propertyFees ?? [])
      .filter((pf) => pf?.feeType === AMENITY_PROPERTY_FEE)
      .map((amenity) => ({
        id: amenity?.id,
        displayNameOnQuote: amenity?.displayNameOnQuote,
      }));
    const units = response.units.map((unit) => {
      const property = {
        ...unit.property,
        propertyFees: response.propertyFees,
      };
      return { ...unit, property };
    });
    const floorPlanPricingService = new FloorPlanPricingService();
    const marketRents =
      yield floorPlanPricingService.getAllMarketRentsByProperty(
        organizationId,
        property.id,
      );
    const { arr, counts } = buildUnitAvailability(
      units,
      payload.intl,
      marketRents,
    );
    if (arr.length === 0) {
      yield put(renderNoDataToastr());
    }
    yield put(
      getAllUnitsSuccess(
        arr,
        amenities,
        { totalCount: response.totalCount },
        counts,
      ),
    );
  } catch (err) {
    yield put(getAllUnitsError(err));
  }
}

export function* getAllUnits(): Saga<void> {
  yield takeLatest(GET_ALL_UNITS, fetchGetAllUnits);
}

export function* fetchGetAllUnitStatuses(): Saga<void> {
  try {
    const unitService = new UnitService();
    const response = yield unitService.getAllUnitStatuses();
    yield put(getAllUnitStatusesSuccess(response));
  } catch (err) {
    yield put(getAllUnitStatusesError(err));
  }
}

export function* getAllUnitStatuses(): Saga<void> {
  yield takeLatest(GET_ALL_UNIT_STATUSES, fetchGetAllUnitStatuses);
}

export function* fetchGetPropertySpecials(): Saga<void> {
  try {
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const propertySpecialsService = new PropertySpecialsService();
    const response = yield propertySpecialsService.getPropertySpecials(
      property.id,
    );
    yield put(getAllPropertySpecialsSuccess(response));
  } catch (err) {
    yield put(getAllPropertySpecialsError(err));
  }
}

export function* getAllPropertySpecials(): Saga<void> {
  yield takeLatest(GET_ALL_PROPERTY_SPECIALS, fetchGetPropertySpecials);
}

export function* fetchSavePropertySpecial({
  payload,
}: Action<any>): Saga<void> {
  const organizationId = yield select(selectCurrentUserOrganizationId);
  const property = yield select(selectSelectedProperty);
  validateSelectedProperty(property);
  try {
    const propertySpecialsService = new PropertySpecialsService();
    if (payload.id) {
      const payloadId = payload.id;
      delete payload.id;
      yield propertySpecialsService.updatePropertySpecial(
        organizationId,
        property.id,
        payloadId,
        {
          endDate: moment().toDate(),
        },
      );
    }
    yield propertySpecialsService.createPropertySpecial(
      organizationId,
      property.id,
      payload,
    );
    yield put(manageUnitAvailablityActions.getAllPropertySpecials(property.id));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(manageUnitAvailablityActions.getAllPropertySpecials(property.id));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* savePropertySpecial(): Saga<void> {
  yield throttle(500, SAVE_PROPERTY_SPECIALS, fetchSavePropertySpecial);
}

export function* fetchGetAssignedUnits(): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const assignedUnitsService = new AssignedUnitsService();
    const response = yield assignedUnitsService.getAll(
      organizationId,
      property.id,
    );
    yield put(
      getAssignedUnitsSuccess(
        response
          .sort(
            (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
          )
          .reduce((prev, val) => {
            prev[val.unitId] =
              ['Converted to Resident'].indexOf(
                pathOr('', ['application', 'applicationStatus', 'name'], val),
              ) === -1
                ? val.applicationId
                : null;
            return prev;
          }, {}),
      ),
    );
  } catch (err) {
    yield put(getAssignedUnitsError(err));
  }
}

export function* getAssignedUnits(): Saga<void> {
  yield takeLatest(GET_ASSIGNED_UNITS, fetchGetAssignedUnits);
}

export function* fetchAssignUnit({ payload }: Object): Saga<void> {
  try {
    const applicationId = payload.applicationId;
    const unitId = payload.unitId;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const assignedUnitsService = new AssignedUnitsService();
    yield assignedUnitsService.save(organizationId, property.id, {
      unitId,
      applicationId,
    });
    yield put(manageUnitAvailablityActions.getAssignedUnits({}));
    yield put(
      manageUnitAvailablityActions.getAllUnits({
        available: true,
        intl: payload.intl,
      }),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.assignUnitSuccess),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* assignUnit(): Saga<void> {
  yield throttle(500, ASSIGN_UNIT, fetchAssignUnit);
}

export function* fetchUnassignUnit({ payload }: Object): Saga<void> {
  try {
    const assignedUnitId = payload.assignedUnitId;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const assignedUnitsService = new AssignedUnitsService();
    yield assignedUnitsService.delete(
      organizationId,
      property.id,
      assignedUnitId,
    );
    yield put(
      manageUnitAvailablityActions.getAllUnits({
        available: true,
        intl: payload.intl,
      }),
    );
    yield put(manageUnitAvailablityActions.getAssignedUnits({}));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.unitUnassigned),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* unassignUnit(): Saga<void> {
  yield throttle(500, UNASSIGN_UNIT, fetchUnassignUnit);
}

export function* fetchGetUnitQuote({ payload }: Object): any {
  try {
    const unitId = payload.unitId;
    const applicationId = payload.applicationId;
    const applicantId = payload.applicantId;
    const prospectId = payload.prospectId;
    const userId = payload.userId;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const unitService = new UnitService();

    const file = yield unitService.getQuote(
      property.id,
      organizationId,
      unitId,
      userId,
      applicantId ? applicantId : null,
      prospectId ? prospectId : null,
    );
    yield FileSaver.saveAs(
      file,
      `Quote-${property.name.replace(/[^a-zA-z0-9]+/gi, '-')}-${moment().format(
        'MM-DD-YYYY',
      )}.pdf`,
    );
    if (applicationId) {
      yield put(
        getAllDocuments(applicationId, 1, 15, { fieldName: '', order: '' }),
      );
    }
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getUnitQuote(): Saga<void> {
  yield takeLatest(GET_UNIT_QUOTE, fetchGetUnitQuote);
}

export default [
  getAllUnits,
  getAllUnitStatuses,
  getAllPropertySpecials,
  savePropertySpecial,
  getAssignedUnits,
  assignUnit,
  unassignUnit,
  getUnitQuote,
];
