import { defineMessages } from 'react-intl';

const messages = defineMessages({
  editCurrentSpecials: {
    id: 'App.UpdateSpecialsForm.EditCurrentSpecials',
    defaultMessage: 'Edit Current Specials'
  },
  saveSpecials: {
    id: 'App.UpdateSpecialsForm.SaveSpecials',
    defaultMessage: 'Save Specials'
  }
});

export default messages;
