import { useState, useEffect, useCallback } from 'react';

export default function useFetchAndSetData(
  initialState: Object,
  dataFetcher: Function,
  handleSuccess?: Function,
  handleError?: Function,
): Object {
  const [data, setData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAndSetData = useCallback(
    async (setLoading = true) => {
      try {
        if (setLoading) {
          setIsLoading(true);
        }
        const result = await dataFetcher();
        setIsLoading(false);
        handleSuccess?.(result);
        setData(result);
        setIsLoading(false);
      } catch (error) {
        handleError?.(error);
        setIsLoading(false);
      }
    },
    [dataFetcher, handleSuccess, handleError],
  );
  useEffect(() => {
    fetchAndSetData();
  }, [dataFetcher, handleSuccess, handleError, fetchAndSetData]);

  return [data, setData, isLoading, fetchAndSetData];
}
