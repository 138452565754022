import moment from 'moment';
import styled from 'styled-components';

import { PRORATE_PERIOD_TYPES } from './constants';
import { formatMoney } from '../../../utils/formatter';
import Row from '../../../components/Table/Row';
import Data from '../../../components/Table/Data';

import type { ProrateTotalsProps } from './types';

export const BoldBlack = styled.h3`
  font-weight: 500;
  color: black;
`;

export const BoldBlue = styled.h3`
  font-weight: 500;
  color: #4d73ff; // .table-header th
`;

export const ProrateTotals = ({
  totals,
  renewalStartDate,
  proratePeriod,
}: ProrateTotalsProps) => {
  const mRenewalStart = moment(renewalStartDate);
  const renewalStartMonth = mRenewalStart.isValid()
    ? mRenewalStart.format('(MM/YYYY)')
    : '';
  const dueMonth =
    proratePeriod === PRORATE_PERIOD_TYPES.PAST_MONTH
      ? moment().format('(MM/YYYY)')
      : renewalStartMonth;
  return (
    // using the style prop here because it takes priority over the tr:nth-child in our css
    // styled components do not.
    <Row key="prorate-totals" style={{ backgroundColor: 'transparent' }}>
      <Data colSpan="2" data-test="totals-due-date">
        <h4>{`Total Amount Due ${dueMonth}`}</h4>
      </Data>
      <Data>
        <BoldBlack data-test="totals-charged">
          {formatMoney(totals.charged)}
        </BoldBlack>
      </Data>
      <Data>
        <BoldBlue data-test="totals-prorate">
          {formatMoney(totals.prorate)}
        </BoldBlue>
      </Data>
      <Data>
        <BoldBlack data-test="totals-adjustmentsToPost">
          {formatMoney(totals.adjustmentsToPost)}
        </BoldBlack>
      </Data>
      <Data colSpan="7"> </Data>
    </Row>
  );
};
