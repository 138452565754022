import React from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { createConfirmation, confirmable } from 'react-confirm';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { renderTextAreaField, renderSelectField } from '../../../utils/redux-form-helper';
import messages from './messages';
import validate from './validate';

import type { StatusChangeValue } from '../types';

type StatusOption = {
  value: string,
  text: string,
  noteRequired: boolean
};

type Props = {
  valid: boolean,
  show: boolean,
  proceed: Function,
  dismiss: Function,
  intl: Object,
  store: Object,
  statusList: Array<StatusOption>,
  initialStatus: string
};

type StateProps = {
  notes: string,
  newStatus: string
};

export const ChangeStatusModal = ({
  valid,
  show,
  proceed,
  dismiss,
  intl,
  store,
  notes,
  initialStatus,
  newStatus,
  statusList
}: Props & StateProps) => (
  <form>
    <Modal className="modal-status-change" backdrop bsSize="sm" show={show} onHide={dismiss}>
      <Modal.Header closeButton>
        <Modal.Title componentClass="h1">{intl.formatMessage(messages.title)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <p>{intl.formatMessage(messages.message)}</p>
            <h3>{intl.formatMessage(messages.newStatusLabel)}</h3>
            <Field
              store={store}
              name="newStatus"
              value={newStatus}
              component={renderSelectField}
              options={statusList}
              bsSize="lg"
            />
          </Col>
          <Col xs={12}>
            <h3>{intl.formatMessage(messages.reasonLabel)}</h3>
            <Field
              store={store}
              name="notes"
              component={renderTextAreaField}
              placeholder={intl.formatMessage(messages.reasonPlaceholder)}
              rows="3"
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col xs={6}>
            <Button bsStyle="default" className="pull-right" onClick={dismiss}>
              {intl.formatMessage(messages.cancel)}
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              bsStyle="primary"
              className="pull-left"
              disabled={initialStatus === newStatus || !valid}
              onClick={() => proceed({ newStatus, notes })}
            >
              {intl.formatMessage(messages.confirm)}
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  </form>
);

let DecoratedChangeStatusModal = reduxForm({
  form: 'changeStatus',
  validate: validate
})(ChangeStatusModal);

const selector = formValueSelector('changeStatus');

export const mapStateToProps = (state: Object): StateProps => ({
  notes: selector(state, 'notes'),
  newStatus: selector(state, 'newStatus')
});

DecoratedChangeStatusModal = connect(mapStateToProps)(DecoratedChangeStatusModal);

DecoratedChangeStatusModal = confirmable(DecoratedChangeStatusModal);

const dialog = createConfirmation(DecoratedChangeStatusModal);

export default function(store: any, intl: any, statusList: Array<Object>, initialStatus: string): Promise<any> {
  return new Promise((resolve, reject) => {
    dialog({
      store,
      intl,
      statusList,
      initialStatus,
      initialValues: {
        newStatus: initialStatus
      }
    }).then((data: StatusChangeValue) => resolve(data), (error: Object) => reject(error));
  });
}
