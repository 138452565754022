import type { FormProspect } from '../../ProspectProfile/types';
import messages from './messages';
import {
  validateEmail,
  validatePhoneNumber,
  validateRange,
} from '../../../utils/validations';
import { prop } from 'ramda';

const validateNameAndLastNameForProspect = (intl, name) => {
  if (name && !/^[a-zA-Z][a-zA-Z0-9 ]*$/i.test(name)) {
    return intl.formatMessage(messages.invalidName);
  }
};

const validateNameAndLastNameForOccupant = (intl, name) => {
  if (name && !/^[a-zA-Z][a-zA-Z0-9 ]*$/i.test(name)) {
    return intl.formatMessage(messages.invalidValue);
  }
};

const validateEmailString = (intl, email) => {
  if (!validateEmail(email)) {
    return intl.formatMessage(messages.invalidEmailAddress);
  }
};

const validateAge = (intl, age, min, max) => {
  if (!validateRange(age, min, max)) {
    return intl.formatMessage(messages.invalidKidAge);
  }
};

const validatePhoneNumberString = (intl, phoneNumber) => {
  if (!validatePhoneNumber(phoneNumber)) {
    return intl.formatMessage(messages.invalidPhoneNumber);
  }
};

const validateAdults = (intl, adultsArray) => {
  if (adultsArray && adultsArray.length > 0) {
    const errors = [];
    adultsArray.forEach((adult) => {
      const adultError = {};
      adultError.firstName = validateNameAndLastNameForOccupant(
        intl,
        adult.firstName,
      );
      adultError.lastName = validateNameAndLastNameForOccupant(
        intl,
        adult.lastName,
      );
      adultError.emailAddress = validateEmailString(intl, adult.emailAddress);
      adultError.phoneNumber = validatePhoneNumberString(
        intl,
        adult.phoneNumber,
      );
      errors.push(adultError);
    });
    return errors;
  }
};

const validateKids = (intl, kidsArray) => {
  if (kidsArray && kidsArray.length > 0) {
    const errors = [];
    kidsArray.forEach((kid) => {
      const kidError = {};
      kidError.firstName = validateNameAndLastNameForOccupant(
        intl,
        kid.firstName,
      );
      kidError.lastName = validateNameAndLastNameForOccupant(
        intl,
        kid.lastName,
      );
      kidError.age = validateAge(intl, kid.age, 0, 17);
      errors.push(kidError);
    });
    return errors;
  }
};

const validateBedsAndBaths = (intl, amount) => {
  if (amount < 0 || amount > 9) {
    return intl.formatMessage(messages.invalidBedsOrBaths);
  }
};

const validatePrice = (intl, amountToValidate, amountFrom, amountTo) => {
  if (amountToValidate < 0 || amountToValidate > 99999) {
    return intl.formatMessage(messages.invalidValue);
  }

  if (amountFrom > amountTo) {
    return intl.formatMessage(messages.invalidRange);
  }
};

const validateMoveInDate = (intl, dateFrom, dateTo) => {
  if (dateFrom > dateTo) {
    return intl.formatMessage(messages.invalidRange);
  }
};

const validateMoveInDateRange = (intl, dateFrom) => {
  if (dateFrom === undefined || dateFrom == null) {
    return intl.formatMessage(messages.invalidDateRange);
  }
  return false;
};

const validate = (values: FormProspect, { intl }: Object) => {
  const errors = {};

  if (!errors.firstName) {
    errors.firstName = validateNameAndLastNameForProspect(
      intl,
      values.firstName,
    );
  }
  if (!errors.lastName) {
    errors.lastName = validateNameAndLastNameForProspect(intl, values.lastName);
  }
  if (!errors.emailAddress) {
    errors.emailAddress = validateEmailString(intl, values.emailAddress);
  }
  if (!errors.phoneNumber) {
    errors.phoneNumber = validatePhoneNumberString(intl, values.phoneNumber);
  }

  errors.spouse = validateAdults(intl, values.spouse);
  errors.otherAdults = validateAdults(intl, values.otherAdults);
  errors.kids = validateKids(intl, values.kids);
  errors.nBeds = validateBedsAndBaths(intl, values.nBeds);
  errors.nBaths = validateBedsAndBaths(intl, values.nBaths);

  errors.priceFrom = validatePrice(
    intl,
    parseInt(values.priceFrom, 10),
    parseInt(values.priceFrom, 10),
    parseInt(values.priceTo, 10),
  );
  errors.priceTo = validatePrice(
    intl,
    parseInt(values.priceTo, 10),
    parseInt(values.priceFrom, 10),
    parseInt(values.priceTo, 10),
  );

  if (values.moveInDateToVisible) {
    errors.moveInDateTo = validateMoveInDate(
      intl,
      values.moveInDateFrom,
      values.moveInDateTo,
    );
    errors.moveInDateFrom = validateMoveInDateRange(
      intl,
      values.moveInDateFrom,
    );
  }

  if (values.noMoveInDate && prop('moveInDateFrom')(errors)) {
    delete errors.moveInDateFrom;
  }

  return errors;
};

export default validate;
