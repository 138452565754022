import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import * as validations from './validations';
import * as constants from './constants';
import messages from './messages';
import {
  extractDateFormat,
  nonEmptyString,
  number,
  greaterOrEqual,
  ReadOnlyField,
  renderCurrencyField,
  renderDateField,
  renderTextField,
  required,
  validDate,
} from '../../../../../utils/redux-form-helper';
import FormattedDateOrDashes from '../../../../../components/FormattedDateOrDashes';

const LabelOverrides = styled.span`
  & label {
    margin-bottom: 16px;
  }
`;

const TrashButton = styled.a`
  display: block;
  font-size: 18px;
  margin-top: 4px;
`;

function RentScheduleFieldArray({ fields, startDate, intl }: Object) {
  const notesMd = fields.length > 1 ? 4 : 5;
  const DATE_FORMAT = extractDateFormat(intl);

  const handleAddRentClick = () => {
    fields.push({});
  };
  const handleTrashButtonClick = () => {
    fields.pop();
  };

  return (
    <React.Fragment>
      <Row style={{ maxHeight: 250, overflowY: 'auto' }}>
        {fields.map((field, index) => {
          const isFirstRow = index === 0;
          const isTrashButtonShown = !isFirstRow && index === fields.length - 1;
          const dateField = isFirstRow ? (
            <LabelOverrides>
              <ReadOnlyField
                label={intl.formatMessage(messages.startDateLabel)}
                value={
                  <FormattedDateOrDashes
                    value={startDate}
                    format={DATE_FORMAT}
                  />
                }
              />
            </LabelOverrides>
          ) : (
            <Field
              name={`${field}.startDate`}
              component={renderDateField}
              bsSize="lg"
              placeholder={DATE_FORMAT}
              dateFormat={DATE_FORMAT}
              validate={[
                validDate,
                required,
                validations.afterPreviousStartDate,
                validations.beforeLeaseEndDate,
              ]}
            />
          );
          const monthlyAmountLabel = isFirstRow
            ? intl.formatMessage(messages.monthlyAmountLabel)
            : undefined;
          const notesLabel = isFirstRow
            ? intl.formatMessage(messages.notesLabel)
            : undefined;

          return (
            <Row key={index}>
              <Col xs={12} md={3}>
                {dateField}
              </Col>
              <Col xs={12} md={4}>
                <Field
                  name={`${field}.monthlyAmount`}
                  label={monthlyAmountLabel}
                  placeholder="0.00"
                  step="0.01"
                  component={renderCurrencyField}
                  required
                  className="input-md form-control"
                  style={{ height: constants.INPUT_HEIGHT }}
                  validate={[required, greaterOrEqual, number]}
                />
              </Col>
              <Col xs={12} md={notesMd}>
                <Field
                  name={`${field}.notes`}
                  label={notesLabel}
                  placeholder={intl.formatMessage(messages.notesPlaceholder)}
                  component={renderTextField}
                  showLabel={isFirstRow}
                  className="input-md form-control"
                  maxLength="200"
                  style={{ height: constants.INPUT_HEIGHT }}
                  validate={[nonEmptyString]}
                />
              </Col>
              {isTrashButtonShown && (
                <Col xs={12} md={1}>
                  <TrashButton onClick={handleTrashButtonClick}>
                    <i className="icon et-trash" />
                  </TrashButton>
                </Col>
              )}
            </Row>
          );
        })}
      </Row>
      <Row>
        <Col xs={12}>
          <a onClick={handleAddRentClick}>
            + {intl.formatMessage(messages.addRent)}
          </a>
        </Col>
      </Row>
      <div style={{ marginBottom: fields.length < 3 ? 80 : 30 }} />
    </React.Fragment>
  );
}

export default RentScheduleFieldArray;
