import { get, post, postDownload } from '../utils/api';

class TheWorkNumberService {
  async submitAffordableScreening(
    propertyId: string,
    orgId: string,
    applicantId: string,
  ) {
    return post(
      `/${orgId}/${propertyId}/twn-affordable/submit-screening/${applicantId}`,
    );
  }

  async submitAffordablePaystubScreening(
    propertyId: string,
    orgId: string,
    applicantId: string,
    employmentRecordId: string,
  ) {
    return post(
      `/${orgId}/${propertyId}/twn-affordable/applicant/${applicantId}/employmentRecord/${employmentRecordId}`,
    );
  }

  async generatePaystubDetailsPDF(
    propertyId: string,
    orgId: string,
    paystubDetailsId: string,
    fileName: string,
  ): Promise<any> {
    return postDownload(
      `/${orgId}/${propertyId}/twn-paystub-details/${paystubDetailsId}/generate-paystub-details`,
      fileName,
    );
  }

  async getScreeningDetails(
    organizationId: string,
    propertyId: string,
    householdId: string,
  ): Promise<Array<Object>> {
    return get(
      `/${organizationId}/${propertyId}/integrations/twn-affordable/households/${householdId}`,
    );
  }
}

export default TheWorkNumberService;
