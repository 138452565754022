const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class WriteOffService {
  writeOff(customerId: string, propertyId: string, orgId: string) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(
      `${apiURL}/${orgId}/${propertyId}/customers/${customerId}/write-off`,
      options
    );
  }
}

export default WriteOffService;
