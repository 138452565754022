import { pluck, concat, ifElse, equals, always, all, map } from 'ramda';

const validate = (
  values: Object,
  { completeRenewalActions, steps }: Object
) => {
  const errors = {};

  const moveInActionValues = map(
    (action) => ({
      ...action,
      valid: values[action.id]
    }),
    completeRenewalActions
  );

  const valuesToValidate = pluck('valid', concat(moveInActionValues, steps));
  errors._error = ifElse(all(equals(true)), always(undefined), always('error'))(
    valuesToValidate
  );
  return errors;
};

export default validate;
