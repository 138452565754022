import { defaultTo } from 'ramda';
import { validateRequired } from '../../../utils/validations';

import formMessages from '../../../containers/ShortLeaseApplication/messages';

import type { MaritalInformation } from '../../../containers/App/types';

const warn = (maritalInfo: MaritalInformation, { intl }: Object) => {
  const warnings = {};
  const values = defaultTo({})(maritalInfo);

  if (!validateRequired('maritalStatus', values)) {
    warnings.maritalStatus = intl.formatMessage(
      formMessages.requiredForCompletion
    );
  }

  return warnings;
};

export default warn;
