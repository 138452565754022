import React from 'react';
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { pathOr, isNil } from 'ramda';
import { Link } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import Row from '../../../../components/Table/Row';
import Data from '../../../../components/Table/Data';

import {
  getRecertStatus,
  getRecertDueStatus,
  getComplianceDocumentName,
} from './utils';

import { getUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';

const StyledNote = styled(Button)`
  cursor: initial;
`;

const RecertRow = ({ recertification }: Object) => {
  const status = pathOr('---', ['recertStatus'], recertification);
  const tooltip = !['Started', 'Complete'].includes(status) ? (
    <Tooltip id="tooltip">{status}</Tooltip>
  ) : null;
  const statusName = getRecertStatus(status);
  const complianceDocumentName = getComplianceDocumentName(recertification);
  return (
    <Row key={`${recertification.programName}${recertification.unit}`}>
      <Data>
        {tooltip ? (
          <OverlayTrigger
            container={window.document.getElementById('table-row')}
            placement="right"
            delayHide={200}
            trigger={['hover', 'focus']}
            overlay={tooltip}
          >
            <span>{statusName}</span>
          </OverlayTrigger>
        ) : (
          <span>{statusName}</span>
        )}
      </Data>
      <Data>{recertification.programName}</Data>
      <Data>{recertification.unit}</Data>
      <Data>{recertification.floorPlan}</Data>
      <Data>
        {recertification.headOfHousehold && recertification.residentId ? (
          <Link
            to={getUrlWithSelectedPropertyId(
              `/resident/${recertification.residentId}`,
            )}
          >
            {recertification.headOfHousehold}
          </Link>
        ) : (
          recertification.headOfHousehold
        )}
      </Data>
      <Data>
        {recertification.moveInDate ? recertification.moveInDate : '---'}
      </Data>
      <Data>
        {recertification.leaseStartDate
          ? recertification.leaseStartDate
          : '---'}
      </Data>
      <Data>
        {recertification.lastCertified ? recertification.lastCertified : '---'}
      </Data>
      <Data>
        {recertification.recertDue
          ? getRecertDueStatus(
              recertification.recertDue,
              recertification.voucherEffectiveDate,
            )
          : '---'}
      </Data>
      <Data>{recertification.occupiedOnNTV ? 'Yes' : '---'}</Data>
      <Data>
        {recertification.doNotRenew && (
          <i className="icon et-alert-urgent text-red" />
        )}
      </Data>
      <Data>
        {recertification.underEviction && (
          <i className="icon et-alert-urgent text-red" />
        )}
      </Data>
      <Data>
        <FormattedNumber
          value={recertification.balance}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency="USD"
        />
      </Data>
      <Data>{recertification.notice120 ? 'Yes' : '---'}</Data>
      <Data>{recertification.notice90 ? 'Yes' : '---'}</Data>
      <Data>{recertification.notice60 ? 'Yes' : '---'}</Data>
      <Data>{recertification.notice30 ? 'Yes' : '---'}</Data>
      <Data>{complianceDocumentName}</Data>
      <Data>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip-notes">
              {recertification.certificationNotes}
            </Tooltip>
          }
        >
          <StyledNote
            bsStyle="default"
            bsSize="small"
            className="btn-comments"
            disabled={
              isNil(recertification.certificationNotes) ||
              recertification.certificationNotes.length === 0
            }
          >
            <i className="icon et-comment-words" />
          </StyledNote>
        </OverlayTrigger>
      </Data>
    </Row>
  );
};

export default RecertRow;
