import { useMutation, MutationOptions } from 'react-query';
import useAbortController from '../../hooks/useAbortController';
import AssignedUnitsService from '../../services/assignedUnitsService';

const useUnAssignUnitMutation = ({
  organizationId,
  propertyId,
  assignedUnitId,
  options,
}: {
  organizationId: string,
  propertyId: string,
  assignedUnitId: string,
  options?: MutationOptions,
}) => {
  const abortControllerOptions = useAbortController();
  return useMutation(
    () =>
      new AssignedUnitsService().delete(
        organizationId,
        propertyId,
        assignedUnitId,
        abortControllerOptions,
      ),
    options,
  );
};

export default useUnAssignUnitMutation;
