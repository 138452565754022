export const MOVE_IN_CERT_TYPE = 'MOVE_IN';
export const RECERT_CERT_TYPE = 'RECERT';
export const INITIAL_CERT_TYPE = 'INITIAL';
export const INTERIM_CERT_TYPE = 'INTERIM';
export const CORRECTION_CERT_TYPE = 'CORRECTION';
export const TERMINATION_CERT_TYPE = 'TERMINATION';
export const MOVE_OUT_CERT_TYPE = 'MOVE_OUT';

export const certificationTypeNameMap = {
  MOVE_IN: 'Move-in (MI)',
  RECERT: 'Annual Recert (AR)',
  INITIAL: 'Initial Certification (IC)',
  INTERIM: 'Interim Recert (IR)',
  CORRECTION: 'Correction for Recert',
  TERMINATION: 'Termination (TM)',
  MOVE_OUT: 'Move-out (MO)',
};

export const MOVE_IN_HUD_CERT_TYPE = 'MOVE_IN';
export const RECERT_HUD_CERT_TYPE = 'RECERT';
export const INITIAL_HUD_CERT_TYPE = 'INITIAL';
export const INTERIM_HUD_CERT_TYPE = 'INTERIM';
export const MOVE_OUT_HUD_CERT_TYPE = 'MOVE_OUT';
export const TERMINATION_HUD_CERT_TYPE = 'TERMINATION';
export const GROSS_RENT_HUD_CERT_TYPE = 'GROSS_RENT';
