import { defineMessages } from 'react-intl';

const messages = defineMessages({
  noDataDescription: {
    id: 'App.ManageOnlinePaymentsDetails.NoDataDescription',
    defaultMessage: 'There is currently no data to display',
  },
  paymentId: {
    id: 'App.ManageOnlinePaymentsDetails.PaymentId',
    defaultMessage: 'Payment ID',
  },
  paymentDateAndTime: {
    id: 'App.ManageOnlinePaymentsDetails.PaymentDateAndTime',
    defaultMessage: 'Payment Date and Time',
  },
  resident: {
    id: 'App.ManageOnlinePaymentsDetails.Resident',
    defaultMessage: 'Resident',
  },
  tenant: {
    id: 'App.ManageOnlinePaymentsDetails.Tenant',
    defaultMessage: 'Tenant',
  },
  unit: {
    id: 'App.ManageOnlinePaymentsDetails.Unit',
    defaultMessage: 'Unit',
  },
  residentStatus: {
    id: 'App.ManageOnlinePaymentsDetails.ResidentStatus',
    defaultMessage: 'Resident Status',
  },
  tenantStatus: {
    id: 'App.ManageOnlinePaymentsDetails.TenantStatus',
    defaultMessage: 'Tenant Status',
  },
  balanceAtPayment: {
    id: 'App.ManageOnlinePaymentsDetails.BalanceAtPayment',
    defaultMessage: 'Balance At Payment',
  },
  paymentAmount: {
    id: 'App.ManageOnlinePaymentsDetails.PaymentAmount',
    defaultMessage: 'Payment Amount',
  },
  paymentMethod: {
    id: 'App.ManageOnlinePaymentsDetails.PaymentMethod',
    defaultMessage: 'Payment Method',
  },
  paymentStatus: {
    id: 'App.ManageOnlinePaymentsDetails.PaymentStatus',
    defaultMessage: 'Payment Status',
  },
  acceptedByOn: {
    id: 'App.ManageOnlinePaymentsDetails.AcceptedByOn',
    defaultMessage: 'Accepted By/On',
  },
  rejectedByOn: {
    id: 'App.ManageOnlinePaymentsDetails.RejectedByOn',
    defaultMessage: 'Rejected By/On',
  },
  rejectReason: {
    id: 'App.ManageOnlinePaymentsDetails.RejectReason',
    defaultMessage: 'Reject Reason',
  },
  voidByOn: {
    id: 'App.ManageOnlinePaymentsDetails.VoidByOn',
    defaultMessage: 'Void By/On',
  },
  voidReason: {
    id: 'App.ManageOnlinePaymentsDetails.VoidReason',
    defaultMessage: 'Void Reason',
  },
});

export default messages;
