import { Button } from 'react-bootstrap';

function CaptionButton({
  caption,
  children,
  ButtonComp = Button,
  ...buttonProps
}: any) {
  return caption ? (
    <>
      <ButtonComp {...buttonProps}>{children}</ButtonComp>
      <figcaption className="help-block">{caption}</figcaption>
    </>
  ) : (
    <ButtonComp {...buttonProps}>{children}</ButtonComp>
  );
}

export default CaptionButton;
