import React, { useContext } from 'react';
import {
  Modal,
  ModalConfirm,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Form } from '@fortress-technology-solutions/fortress-component-library/Organisms';
import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import useReactHookFormProps from '../../hooks/useReactHookFormProps';
import { AppContext } from '../App/context';
import { usePropertyBatchModal } from './hooks';
import { dropdownValues } from './dropdownValues';
import messages from './messages';

const CreatePropertyDataBatch = (props): Props => {
  const { selectedProperty } = useContext(AppContext);

  const handleCancelConfirm = () => {
    const { history } = props;
    history.goBack();
  };
  const ReactHookFormProps = useReactHookFormProps({});
  const isHud = selectedProperty.pap.find(
    (fpap) => fpap?.masterAffordableProgram?.name === 'HUD',
  );
  const isRD = selectedProperty.pap.find(
    (fpap) => fpap?.masterAffordableProgram?.name === 'RD',
  );

  const filteredDropdownValues = dropdownValues.filter((value) => {
    if (value.rd && !isRD) {
      return false;
    }
    if (value.hud && !isHud) {
      return false;
    }
    return true;
  });

  const { modalProps, confirmModalProps, formatMessage } =
    usePropertyBatchModal({
      propertyName: selectedProperty.name,
      propertyId: selectedProperty.id,
      organizationId: selectedProperty.organizationId,
      handleCancelConfirm,
      formProps: ReactHookFormProps,
    });

  const formProps = {
    fields: [
      {
        variant: 'fileUploader',
        name: 'file',
        requiredFileType: ['text/xml', 'text/csv', 'text/plain'],
        sx: { margin: 'auto' },
        rules: { required: true },
        GridProps: {
          xs: 12,
        },
      },
      {
        name: 'note',
        variant: 'custom',
        component: (
          <Typography variant={'caption'}>
            {formatMessage(messages.uploadFileNote)}
          </Typography>
        ),
        GridProps: { xs: 12 },
      },
      {
        name: 'propertyName',
        label: formatMessage(messages.propertyName),
        variant: 'text',
        disabled: true,
        value: selectedProperty.name,
        GridProps: { xs: 12 },
      },
      {
        variant: 'singleSelect',
        name: 'fileType',
        label: formatMessage(messages.fileType),
        options: filteredDropdownValues,
        rules: { required: true },
        GridProps: { xs: 12 },
      },
      {
        name: 'helperText',
        variant: 'custom',
        component: (
          <Typography variant={'caption'}>
            {formatMessage(messages.uploadText)}
            {formatMessage(messages.emailText)}
          </Typography>
        ),
        GridProps: { xs: 12 },
      },
    ],
  };

  return (
    <>
      <Modal onClose={handleCancelConfirm} {...modalProps}>
        <Form ReactHookFormProps={ReactHookFormProps} {...formProps} />
      </Modal>
      <ModalConfirm onClose={handleCancelConfirm} {...confirmModalProps} />
    </>
  );
};

export default CreatePropertyDataBatch;
