import type { Action } from '../App/types';
import type { MonthEndClose } from './types';
import {
  GET_MONTH_END_CLOSE,
  GET_MONTH_END_CLOSE_SUCCESS,
  GET_MONTH_END_CLOSE_FAILURE,
  SUBMIT_MONTH_END_CLOSE
} from './constants';

export function getMonthEndClose(): Action<void> {
  return {
    type: GET_MONTH_END_CLOSE,
    payload: undefined
  };
}

export function getMonthEndCloseSuccess(
  payload: MonthEndClose
): Action<MonthEndClose> {
  return {
    type: GET_MONTH_END_CLOSE_SUCCESS,
    payload
  };
}

export function getMonthEndCloseFailure(error: Error): Action<Error> {
  return {
    type: GET_MONTH_END_CLOSE_FAILURE,
    payload: error,
    error: true
  };
}

export function submitMonthEndClose(): Action<void> {
  return {
    type: SUBMIT_MONTH_END_CLOSE,
    payload: undefined
  };
}
