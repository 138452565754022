import React, { useState, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { pathOr, isEmpty, isNil } from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import * as api from '../../utils/api';
import ManageLayout from '../../components/ManageLayout';
import Table from '../../components/Table';
import Row from '../../components/Table/Row';
import Data from '../../components/Table/Data';
import SearchField from '../Fields/Search';
import { getAllReports } from '../ManageReports/actions';
import { promptToaster } from '../App/actions';
import { useCommercialRentRoll } from './hooks';
import { formatColumnType } from './helpers';
import { sortAndFilterUnits } from '../../utils/unit-helpers';
import * as rentRollUtils from '../../utils/rent-rolls';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import {
  COMMERCIAL_RENT_ROLL_TABLE_HEADERS,
  COMMERCIAL_RENT_ROLL_TABLE_HEADERS_FLAG,
  COMMERCIAL_RENT_ROLL_TOTAL_COLUMNS,
  COMMERCIAL_RENT_ROLL_TOTAL_COLUMNS_FLAG,
  DATE_INPUT_FORMAT,
} from './constants';
import { SubmitButton } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { DownloadIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

type ManageCommercialRentRollProps = {
  intl: Object,
  selectedProperty: Object,
  organizationId: string,
  reports: Array<Object>,
  actions: {
    getAllReports: Function,
  },
};

const commercialRentRollHeaders =
  COMMERCIAL_RENT_ROLL_TABLE_HEADERS_FLAG[1].headers;

const ManageCommercialRentRoll = (props: ManageCommercialRentRollProps) => {
  useEffect(() => {
    // TODO: use search/sorting to match the page exactly.
    props.actions.getAllReports({});
  }, []); // eslint-disable-line

  const { commercialBaseRent } = useFlags();
  const { intl, organizationId, reports, selectedProperty } = props;
  const propertyId = selectedProperty?.id;
  const propertyName = selectedProperty?.name;

  const [rows, loaded] = useCommercialRentRoll(selectedProperty, promptToaster);
  const [query, setQuery] = useState({});
  const [mappedRows, setMappedRows] = useState([]);

  useEffect(() => {
    const mappedRows = rows.map((row) => ({
      rows: Object.entries(row).reduce((acc, [key, value]) => {
        let header;

        if (commercialBaseRent) {
          header = commercialRentRollHeaders.find(({ id }) => id === key);
        } else {
          header = COMMERCIAL_RENT_ROLL_TABLE_HEADERS.find(
            ({ id }) => id === key,
          );
        }
        acc[key] = {
          raw: value,
          value: header ? formatColumnType(intl, value, header) : value,
        };
        return acc;
      }, {}),
    }));
    setMappedRows(mappedRows);
  }, [intl, rows, commercialBaseRent]);

  const handleSort = ({ fieldName, order }: Object) => {
    const sorting = { fieldName, order };
    setQuery({ ...query, sorting });
  };

  const handleSearch = ({ searchText }: Object) => {
    setQuery({ ...query, searchText });
  };
  const reportId = pathOr(
    '',
    ['id'],
    reports?.find(
      (r) => pathOr('', ['referenceId'], r) === 'Commercial-Rent-Roll',
    ),
  );

  return (
    <DocumentTitle title="Manage Commercial Rent Roll">
      <ManageLayout
        title={
          <div className="col-xs-12 col-md-7">
            <h1>Manage Commercial Rent Roll</h1>
            <p>
              Units found: <span className="text-blue">{rows.length}</span>
            </p>
          </div>
        }
        actions={
          <div className="col-xs-12 col-md-5">
            <SubmitButton
              className="pull-right btn"
              startIcon={<DownloadIcon />}
              onClick={() =>
                api.getDownload(
                  `/${organizationId}/${propertyId}/pdf-reports/${reportId}`,
                  `${propertyName}_${moment().format('YYYYMMDD')}.pdf`,
                )
              }
            >
              PDF
            </SubmitButton>
            <SubmitButton
              className="pull-right btn"
              startIcon={<DownloadIcon />}
              sx={{ 'margin-right': '7px' }}
              onClick={() =>
                api.getDownload(
                  `/${organizationId}/${propertyId}/csv-reports/${reportId}`,
                  `${propertyName}_${moment().format('YYYYMMDD')}.csv`,
                )
              }
            >
              CSV
            </SubmitButton>

            <div className="search-actions search-align-right pull-right">
              <SearchField
                className="search-input search-input-active"
                form="ManageCommercialRentRoll"
                placeholder="Search"
                onSubmit={handleSearch}
              />
            </div>
          </div>
        }
      >
        <div className="table-scroll table-units-container">
          <Table
            id="ManageCommercialRentRoll"
            name="ManageCommercialRentRoll"
            onSort={handleSort}
            footer={() => FooterRow({ intl, rows, loaded, commercialBaseRent })}
            {...(commercialBaseRent
              ? { headerRows: COMMERCIAL_RENT_ROLL_TABLE_HEADERS_FLAG }
              : { headers: COMMERCIAL_RENT_ROLL_TABLE_HEADERS })}
          >
            <TableRows
              rows={mappedRows}
              loaded={loaded}
              query={query}
              flag={commercialBaseRent}
              data-testid="CommercialtTableRows"
            />
          </Table>
        </div>
      </ManageLayout>
    </DocumentTitle>
  );
};

export const FooterRow = ({
  intl,
  rows,
  loaded,
  commercialBaseRent,
}: Object) => {
  const headers = commercialBaseRent
    ? COMMERCIAL_RENT_ROLL_TOTAL_COLUMNS_FLAG
    : COMMERCIAL_RENT_ROLL_TOTAL_COLUMNS;

  const totals = rows.reduce(
    (acc, row) => {
      headers.forEach((column) => {
        acc[column] += +pathOr(0, [column], row);
      });
      return acc;
    },
    {
      rentableSqFt: 0,
      monthlyBaseRent: 0,
      netRentCharge: 0,
      annualBaseRent: 0,
      annualRatePSF: 0,
      balance: 0,
    },
  );

  return loaded ? (
    <tfoot className="table-footer">
      <tr>
        {commercialBaseRent
          ? commercialRentRollHeaders.map((header) => {
              if (
                COMMERCIAL_RENT_ROLL_TOTAL_COLUMNS_FLAG.indexOf(header.id) !==
                -1
              ) {
                return (
                  <td key={header.id}>
                    {formatColumnType(intl, totals[header.id], header)}
                  </td>
                );
              }
              return <td key={header.id} />;
            })
          : COMMERCIAL_RENT_ROLL_TABLE_HEADERS.map((header) => {
              if (
                COMMERCIAL_RENT_ROLL_TOTAL_COLUMNS.indexOf(header.id) !== -1
              ) {
                return (
                  <td key={header.id}>
                    {formatColumnType(intl, totals[header.id], header)}
                  </td>
                );
              }
              return <td key={header.id} />;
            })}
      </tr>
    </tfoot>
  ) : null;
};

export const TableRows = ({ rows, loaded, query, flag }: Object): any => {
  const fieldName = pathOr('', ['sorting', 'fieldName'], query);
  const order = pathOr('', ['sorting', 'order'], query);
  const searchText = pathOr('', ['searchText'], query);

  const filtered = sortAndFilterUnits(
    rows,
    fieldName,
    order,
    searchText,
    DATE_INPUT_FORMAT,
  );

  const emptyRowOrSpinner = () => {
    const headers = flag
      ? commercialRentRollHeaders
      : COMMERCIAL_RENT_ROLL_TABLE_HEADERS;
    if (loaded) {
      return <Data colSpan={headers.length}>No units available</Data>;
    } else {
      return (
        <Data colSpan={headers.length}>
          <div className="spinner">
            <div className="spinnerbar spnbar1" />
            <div className="spinnerbar spnbar2" />
            <div className="spinnerbar spnbar3" />
            <div className="spinnerbar spnbar4" />
            <div className="spinnerbar spnbar5" />
          </div>
        </Data>
      );
    }
  };

  const normalizedRows = filtered.map(({ rows }) =>
    Object.entries(rows).reduce((acc, [key, { value }]: any) => {
      acc[key] = value;
      return acc;
    }, {}),
  );

  return normalizedRows.length > 0 ? (
    normalizedRows.map((row: Object, key: number): any => (
      <Row key={`row${key}`} className="table-row">
        {flag
          ? commercialRentRollHeaders.reduce((acc, header, otherKey) => {
              const value = row[header.id];
              if (value) {
                if (
                  header.id === 'applicant' &&
                  !isEmpty(row.applicationId) &&
                  !isNil(row.applicationId)
                ) {
                  acc.push(
                    <Data key={otherKey}>
                      <Link
                        to={getUrlWithSelectedPropertyId(
                          `/application/${row.applicationId}`,
                        )}
                      >
                        {value}
                      </Link>
                    </Data>,
                  );
                } else if (
                  header.id === 'tenant' &&
                  !isEmpty(row.residentId) &&
                  !isNil(row.residentId)
                ) {
                  acc.push(
                    <Data key={otherKey}>
                      <Link
                        to={getUrlWithSelectedPropertyId(
                          `/resident/${row.residentId}`,
                        )}
                      >
                        {value}
                      </Link>
                    </Data>,
                  );
                } else if (header.id === 'unit') {
                  acc.push(
                    <Data key={otherKey}>
                      <Link
                        to={getUrlWithSelectedPropertyId(
                          `/unit/${row.unitId}?prev=manage-commercial`,
                        )}
                      >
                        {value}
                      </Link>
                    </Data>,
                  );
                } else if (header.id === 'collectionNotes') {
                  acc.push(
                    <Data key={otherKey}>
                      {rentRollUtils.getCollectionNotesValues({
                        collectionNoteStartTime: row.collectionNoteStartTime,
                        collectionNote: row.collectionNotes,
                      })}
                    </Data>,
                  );
                } else {
                  acc.push(<Data key={otherKey}>{value}</Data>);
                }
              } else {
                acc.push(<Data key={otherKey} />);
              }
              return acc;
            }, [])
          : COMMERCIAL_RENT_ROLL_TABLE_HEADERS.reduce(
              (acc, header, otherKey) => {
                const value = row[header.id];
                if (value) {
                  if (
                    header.id === 'applicant' &&
                    !isEmpty(row.applicationId) &&
                    !isNil(row.applicationId)
                  ) {
                    acc.push(
                      <Data key={otherKey}>
                        <Link
                          to={getUrlWithSelectedPropertyId(
                            `/application/${row.applicationId}`,
                          )}
                        >
                          {value}
                        </Link>
                      </Data>,
                    );
                  } else if (
                    header.id === 'tenant' &&
                    !isEmpty(row.residentId) &&
                    !isNil(row.residentId)
                  ) {
                    acc.push(
                      <Data key={otherKey}>
                        <Link
                          to={getUrlWithSelectedPropertyId(
                            `/resident/${row.residentId}`,
                          )}
                        >
                          {value}
                        </Link>
                      </Data>,
                    );
                  } else if (header.id === 'unit') {
                    acc.push(
                      <Data key={otherKey}>
                        <Link
                          to={getUrlWithSelectedPropertyId(
                            `/unit/${row.unitId}?prev=manage-commercial`,
                          )}
                        >
                          {value}
                        </Link>
                      </Data>,
                    );
                  } else if (header.id === 'collectionNotes') {
                    acc.push(
                      <Data key={otherKey}>
                        {rentRollUtils.getCollectionNotesValues({
                          collectionNoteStartTime: row.collectionNoteStartTime,
                          collectionNote: row.collectionNotes,
                        })}
                      </Data>,
                    );
                  } else {
                    acc.push(<Data key={otherKey}>{value}</Data>);
                  }
                } else {
                  acc.push(<Data key={otherKey} />);
                }
                return acc;
              },
              [],
            )}
      </Row>
    ))
  ) : (
    <Row key={1} className="table-row">
      {emptyRowOrSpinner()}
    </Row>
  );
};

export const mapDispatchToProps = (dispatch: any): Object => {
  const actions = bindActionCreators(
    {
      getAllReports,
    },
    dispatch,
  );
  return { actions };
};

export const mapStateToProps = ({
  app,
  manageReports: { reports },
}: Object): Object => {
  return {
    organizationId: app.currentUser ? app.currentUser.user.organizationId : '',
    selectedProperty: app.selectedProperty,
    reports,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ManageCommercialRentRoll));
