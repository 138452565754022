import { defineMessages } from 'react-intl';

const messages = defineMessages({
  signatureLabel: {
    id: 'App.SignatureSection.SignatureLabel',
    defaultMessage: 'We have received a signed copy of this applicant\'s lease application.'
  },
  cancel: {
    id: 'App.SignatureSection.Cancel',
    defaultMessage: 'Cancel'
  },
  saveProgress: {
    id: 'App.SignatureSection.SaveProgress',
    defaultMessage: 'Save Progress'
  },
  complete: {
    id: 'App.SignatureSection.Complete',
    defaultMessage: 'Complete'
  }
});

export default messages;
