import moment from 'moment';
import { validateDate, validateRequired } from '../../../utils/validations';
import { isBetweenDates } from '../../../utils/date-helpers';

const validate = (values: Object, props: Object) => {
  const errors = {};
  const { batchDate } = values;

  // Required Fields
  ['batchDate'].forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      errors[fieldName] = 'This field is required';
    }
  });

  const mBatchDate = moment(batchDate);

  // Valid Dates
  if (!errors['batchDate']) {
    if (!validateDate(mBatchDate)) {
      errors['batchDate'] = 'Please enter a valid date';
    }
  }

  // Check the date is not more than 30 days in the past or future from today
  const minBatchDate = moment().subtract(30, 'days');
  const maxBatchDate = moment().add(30, 'days');
  if (
    !errors['batchDate'] &&
    !isBetweenDates(mBatchDate, minBatchDate, maxBatchDate, 'days')
  ) {
    errors['batchDate'] =
      'Date can be up to 30 days in the past or future from today.';
  }

  return errors;
};

export default validate;
