import { FormattedMessage, injectIntl } from 'react-intl';
import { useMemo, useState } from 'react';
import moment from 'moment';
import {
  Button,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Collapse } from '@fortress-technology-solutions/fortress-component-library/Transitions';
import {
  VisibilityIcon,
  VisibilityOffIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { SetupLabel } from '../shared';

import { formatPercentage } from '@fortress-technology-solutions/fortress-component-library/utils/index';
import { isDateBetweenRange } from '../../utils';
import { formatDate } from '../../../../../utils/formatter';

import type { PassbookRate } from '../shared';

import generalMessages from '../../../../App/messages';
import componentMessages from './messages';
const messages = { ...generalMessages, ...componentMessages };

type Props = {
  programName: string,
  isLoadingPassbookRateData: boolean,
  passbookRates: Array<PassbookRate>,
  sx?: Object,
  intl: Object,
};

const PassbookRateRow = ({ passbookRate }: { passbookRate?: PassbookRate }) => {
  const { amount, startDate, endDate } = passbookRate ?? {};
  return (
    <Stack direction={'row'} spacing={4}>
      <div>
        <Typography variant={'inputLabel'}>
          <FormattedMessage {...messages.amount} />
        </Typography>
        <Typography variant={'body1'}>
          {formatPercentage(amount ?? 0)}
        </Typography>
      </div>
      <div>
        <Typography variant={'inputLabel'}>
          <FormattedMessage {...messages.startDate} />
        </Typography>
        <Typography variant={'body1'}>{formatDate(startDate)}</Typography>
      </div>
      <div>
        <Typography variant={'inputLabel'}>
          <FormattedMessage {...messages.endDate} />
        </Typography>

        <Typography variant={'body1'}>{formatDate(endDate)}</Typography>
      </div>
    </Stack>
  );
};

const ProgramPassbookRate = ({
  passbookRates,
  programName,
  intl,
  isLoadingPassbookRateData,
  sx,
}: Props) => {
  const [showHistory, setShowHistory] = useState(false);

  const currentPassbookRate = useMemo(() => {
    const mToday = moment();
    return passbookRates?.find((rate) => {
      return isDateBetweenRange(mToday, rate?.startDate, rate?.endDate);
    });
  }, [passbookRates]);

  const onShowHistory = () => {
    setShowHistory(!showHistory);
  };

  return (
    <AsyncBox
      loading={isLoadingPassbookRateData}
      sx={{
        ...sx,
      }}
    >
      <SetupLabel
        {...messages.passbookRateTitle}
        values={{
          programName,
        }}
      />
      <Collapse in={!showHistory} sx={{ width: '100%' }}>
        <PassbookRateRow
          key={currentPassbookRate?.id}
          passbookRate={currentPassbookRate}
        />
      </Collapse>
      <Collapse in={showHistory} sx={{ width: '100%' }}>
        <Stack spacing={1}>
          {passbookRates?.map((passbookRate) => (
            <PassbookRateRow
              key={passbookRate?.id}
              passbookRate={passbookRate}
            />
          ))}
        </Stack>
      </Collapse>

      <Stack
        key={`${programName}_passbookRateGeneral_actions`}
        direction="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{ marginY: 1, marginTop: { xs: 0 } }}
      >
        <Button
          variant="text"
          onClick={onShowHistory}
          startIcon={showHistory ? <VisibilityOffIcon /> : <VisibilityIcon />}
        >
          <FormattedMessage
            {...messages[showHistory ? 'hideHistory' : 'showHistory']}
          />
        </Button>
      </Stack>
    </AsyncBox>
  );
};

export default injectIntl(ProgramPassbookRate);
