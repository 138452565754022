import request, { apiURL } from '../utils/api';
import { omit } from 'ramda';
import type { CreateTransaction } from '../components/CreateTransaction/types';

class TransactionsService {
  create(
    organizationId: string,
    propertyId: string,
    transaction: CreateTransaction,
    notificationAttributes: Object = {},
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...omit(['customerStatus', 'unitId'], transaction),
        notificationAttributes,
      }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/transactions`,
      options,
    );
  }

  reverseTransaction(
    organizationId: string,
    propertyId: string,
    transactionId: string,
    ledger: string,
    period: string,
    notes: string,
    notificationAttributes?: Object,
    camAllocationsId?: string,
  ) {
    const reversePayload = {
      notes,
      propertyFiscalPeriod: period,
      camAllocationsId,
    };
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...reversePayload, notificationAttributes }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/transactions/${ledger}/reverse/${transactionId}`,
      options,
    );
  }
}

export default TransactionsService;
