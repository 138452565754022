import { get, post } from '../utils/api';

class MasterAffordableProgramDocumentTypeService {
  getAllAffordableDocumentsByAffordableProgram(
    organizationId: string,
    propertyId: string,
    masterAffordableProgramId: string,
    options?: Object,
  ) {
    const orgAndProp = `/${organizationId}/${propertyId}`;
    return get(
      `${orgAndProp}/master-affordable-programs/${masterAffordableProgramId}/document-types`,
      options,
    );
  }

  getAllAffordableDocuments(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    const orgAndProp = `/${organizationId}/${propertyId}`;
    return get(
      `${orgAndProp}/master-affordable-program-document-types`,
      options,
    );
  }

  /**
   * Uploads a compliance document to the server
   * -
   * @param {*} organizationId
   * @param {*} propertyId
   * @param {*} affordableQualificationId
   * @param {*} document the file to upload
   * @param {*} options
   * @notes Have to make this requestOptions object to override the way that
   * currently POST is forcing Content-Type to be application/json.
   * @todo AFFORDABLE_59: deprecate if we can get rid of AQH
   */
  uploadCompliancePacket(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    document: Object,
    options?: Object,
  ): Promise<any> {
    const requestOptions = {
      ...options,
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };
    return post(
      `/${organizationId}/${propertyId}/affordable-qualifications/${affordableQualificationId}/upload-packet`,
      document,
      requestOptions,
    );
  }
}

export default MasterAffordableProgramDocumentTypeService;
