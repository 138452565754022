import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ForgotPassword.Title',
    defaultMessage: 'Fortress - Forgot password'
  },
  help: {
    id: 'App.ForgotPassword.help',
    defaultMessage:
      'Having trouble signing in? Please contact your system administrator for assistance.'
  },
  resetPasswordInstructions: {
    id: 'App.ForgotPassword.ResetPasswordInstructions',
    defaultMessage:
      'Enter your Fortress Username and we will send a link to your associated email to reset your password.'
  },
  yourUsername: {
    id: 'App.ForgotPassword.YourUsername',
    defaultMessage: 'Your Username'
  },
  forgotPassword: {
    id: 'App.ForgotPassword.ForgotPassword',
    defaultMessage: 'Forgot Password?'
  },
  returnSignIn: {
    id: 'App.ForgotPassword.RetunSignIn',
    defaultMessage: 'Return to Sign In'
  },
  sendEmail: {
    id: 'App.ForgotPassword.SendEmail',
    defaultMessage: 'Reset Password'
  },
  emailSentFailure: {
    id: 'App.ForgotPassword.EmailSentFaliure',
    defaultMessage: 'Email failed to send'
  },
  emailSent: {
    id: 'App.ForgotPassword.EmailSent',
    defaultMessage: 'Email sent to user'
  },
  emailSentTitle: {
    id: 'App.ForgotPassword.EmailSentTitle',
    defaultMessage: 'Success'
  },
  emailSentDescription: {
    id: 'App.ForgotPassword.EmailSentDesc',
    defaultMessage: `We've sent an email to your associated account with reset instructions.
                     You may return to the Sign in page or close this window.`
  },
  gotoSignIn: {
    id: 'App.ForgotPassword.GoToSignIn',
    defaultMessage: 'Go to to Sign In'
  }
});

export default messages;
