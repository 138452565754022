import { put, takeLatest, select } from 'redux-saga/effects';
import { LOG_ERROR_MESSAGE } from './constants';
import { LoggerService } from '../../services/logger';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty
} from '../App/selectors';
import type { Saga } from 'redux-saga';
import type { Action } from '../App/types';
import { logErrorSuccess } from './actions';

export function* fetchLogError(action: Action<any>): Saga<void> {
  const organizationId = yield select(selectCurrentUserOrganizationId);
  const property = yield select(selectSelectedProperty);
  const loggerService = new LoggerService();
  yield loggerService.log(action.payload, property.id, organizationId);
  yield put(logErrorSuccess());
}

export function* logErrorSaga(): Saga<void> {
  yield takeLatest(LOG_ERROR_MESSAGE, fetchLogError);
}

export default [logErrorSaga];
