import React from 'react';
import moment from 'moment';
import { pathOr } from 'ramda';
import ElementWithPermissions from '../../../../components/ElementWithPermissions';

const UnitSqFtInformationHistory = ({
  histories,
  isDeleting,
  handleDeleteHistory,
  handleSetEditing,
  setInitialValues,
  toggleFormCallback,
}: Object) => {
  const isModifiable = (history: Object, index: number) => {
    const today = moment().startOf('day');
    return (
      moment(history.startDate).startOf('day').unix() > today.unix() &&
      index + 1 === histories.length
    );
  };

  const setFormValues = (historyObj: Object) => {
    handleSetEditing(true);
    const startDate = pathOr(null, ['startDate'], historyObj);
    setInitialValues({
      id: pathOr(null, ['id'], historyObj),
      squareFeet: pathOr(null, ['squareFeet'], historyObj),
      startDate: startDate ? moment(startDate).format('MM/DD/YYYY') : null,
    });
    toggleFormCallback(true);
  };

  return (
    <div className="row padbottom10">
      {histories
        .sort((a, b) => moment(a.startDate).unix() - moment(b.startDate).unix())
        .map((history, index) => {
          return (
            <React.Fragment key={history.id}>
              <div className="col-xs-offset-2 col-xs-3 text-left">
                {history.squareFeet}
              </div>
              <div className="col-xs-3 text-center">
                {moment(history.startDate).format('MM/DD/YYYY')}
              </div>
              <div className="col-xs-3 text-left">
                {isModifiable(history, index) && (
                  <ElementWithPermissions
                    scope={['manage-units-edit-squarefeet']}
                  >
                    <button
                      onClick={() => setFormValues(history)}
                      className="btn btn-link btn-tiny"
                      disabled={isDeleting}
                    >
                      <i className="icon et-pencil" />
                    </button>
                    <button
                      onClick={() => handleDeleteHistory(history.id)}
                      className="btn btn-link btn-tiny"
                      disabled={isDeleting}
                    >
                      <i className="icon et-trash" />
                    </button>
                  </ElementWithPermissions>
                )}
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default UnitSqFtInformationHistory;
