import React, { useState } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import ColumnHeader from '../../../components/ColumnHeader';
import DashesIfNullOrUndefined from '../../../components/DashesIfNullOrUndefined';
import { Status } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { PAYMENT_STATUS } from './constants';
import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

type Props = {
  isLoading: boolean,
  onlinePayments: Array<Object>,
  isAllCommercial: boolean,
  setDisableRejectAcceptBtn: boolean,
  disableRejectAcceptBtn: boolean,
};

const renderPaymentsRows = (payments, selected, toggleSelectedRow) => {
  return payments && payments.length > 0 ? (
    payments
      .sort((a, b) => moment(b.date) - moment(a.date))
      .map((payment, i) => {
        return (
          <tr key={i}>
            <td className="hidden-xs">
              <div
                className="checkbox custom-checkbox checkbox-lg"
                style={{ margin: 0 }}
              >
                <label>
                  <input
                    type="checkbox"
                    checked={
                      selected?.find((o) => o === payment?.paymentId) ?? false
                    }
                    onChange={() => {
                      toggleSelectedRow(payment?.paymentId);
                    }}
                  />
                  <span className="custom-check-square" />
                </label>
              </div>
            </td>
            <td> {payment.paymentId}</td>
            <td>{moment(payment.date).format('MM/DD/YYYY hh:mm a')}</td>
            <td>
              <a
                href={getUrlWithSelectedPropertyId(
                  `/resident/${payment?.residentId}`,
                )}
                target="_blank"
                rel="noreferrer"
              >
                {payment.customerLastName}, {payment.customerFirstName}
              </a>
            </td>
            <td>
              <a
                href={getUrlWithSelectedPropertyId(`/unit/${payment?.unitId}`)}
                target="_blank"
                rel="noreferrer"
              >
                {payment.unitNumber}
              </a>
            </td>
            <td>{payment.residentStatus}</td>
            <td>{payment.balanceAtPayment ?? 0}</td>
            <td>{payment.amount}</td>
            <td>{payment.method}</td>
            <td>
              <Status statusType={undefined} color={'#C2A04A'}>
                {payment.status}
              </Status>
            </td>
            <td>
              <DashesIfNullOrUndefined data={payment.acceptedBy} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={payment.rejectedBy} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={payment.rejectReason} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={payment.voidBy} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={payment.voidReason} />
            </td>
          </tr>
        );
      })
  ) : (
    <tr>
      <td colSpan={16}>
        <div className="text-center">
          <FormattedMessage {...messages.noDataDescription} />
        </div>
      </td>
    </tr>
  );
};

const ManageOnlinePaymentsDetails = (props: Props) => {
  const {
    isLoading,
    onlinePayments,
    isAllCommercial,
    setDisableRejectAcceptBtn,
    disableRejectAcceptBtn,
  } = props;
  const [selected, setSelected] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  const onSelectAllClicked = () => {
    if (!selectedAll) {
      setSelected([...onlinePayments.map((o) => o.paymentId)]);
      setSelectedAll(true);
      const notAuthorized = onlinePayments?.filter(
        (o) => o.status !== PAYMENT_STATUS.AUTHORIZED,
      );
      !notAuthorized.length
        ? setDisableRejectAcceptBtn(false)
        : setDisableRejectAcceptBtn(true);
    } else {
      setSelected([]);
      setSelectedAll(false);
      setDisableRejectAcceptBtn(true);
    }
  };
  const allNonAuthorizedPayments = onlinePayments.filter(
    (o) => o.status !== PAYMENT_STATUS.AUTHORIZED,
  );
  const toggleSelectedRow = (paymentId: string) => {
    if (selected?.find((o) => o === paymentId)) {
      setSelected([...selected.filter((item) => item !== paymentId)]);
      setSelectedAll(false);
      if (selected?.length > 1 && disableRejectAcceptBtn) {
        const selectedNonAuthorizedList = allNonAuthorizedPayments?.filter(
          (o) => {
            const id = o.paymentId;
            return selected.includes(id);
          },
        );
        const notAuthorized = selectedNonAuthorizedList.filter(
          (o) => o.paymentId !== paymentId,
        );
        !notAuthorized.length
          ? setDisableRejectAcceptBtn(false)
          : setDisableRejectAcceptBtn(true);
      } else if (selected?.length === 1) {
        setDisableRejectAcceptBtn(true);
      }
    } else {
      setSelected([...selected, paymentId]);
      selected.length + 1 === onlinePayments.length && setSelectedAll(true);

      if (selected?.length !== 0 && !disableRejectAcceptBtn) {
        const payment = onlinePayments?.find((o) => o.paymentId === paymentId);
        payment?.status !== PAYMENT_STATUS.AUTHORIZED &&
          setDisableRejectAcceptBtn(true);
      } else if (selected?.length === 0 && disableRejectAcceptBtn) {
        const payment = onlinePayments?.find((o) => o.paymentId === paymentId);
        payment?.status === PAYMENT_STATUS.AUTHORIZED &&
          setDisableRejectAcceptBtn(false);
      }
    }
  };

  return (
    <div className="container-fluid" data-test="payment-details">
      <div className="table-scroll table-deposits-container">
        <table className="table table-prospects table-striped">
          <thead className="table-header">
            <tr>
              <ColumnHeader
                columnLabel={
                  <div
                    className="checkbox custom-checkbox checkbox-lg"
                    style={{ margin: 0 }}
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedAll}
                        onChange={onSelectAllClicked}
                      />
                      <span className="custom-check-square" />
                    </label>
                  </div>
                }
              />
              <th>
                <FormattedMessage {...messages.paymentId} />
              </th>
              <th>
                <FormattedMessage {...messages.paymentDateAndTime} />
              </th>
              <th>
                {isAllCommercial ? (
                  <FormattedMessage {...messages.tenant} />
                ) : (
                  <FormattedMessage {...messages.resident} />
                )}
              </th>
              <th>
                <FormattedMessage {...messages.unit} />
              </th>
              <th>
                {isAllCommercial ? (
                  <FormattedMessage {...messages.tenantStatus} />
                ) : (
                  <FormattedMessage {...messages.residentStatus} />
                )}
              </th>
              <th>
                <FormattedMessage {...messages.balanceAtPayment} />
              </th>
              <th>
                <FormattedMessage {...messages.paymentAmount} />
              </th>
              <th>
                <FormattedMessage {...messages.paymentMethod} />
              </th>
              <th>
                <FormattedMessage {...messages.paymentStatus} />
              </th>
              <th>
                <FormattedMessage {...messages.acceptedByOn} />
              </th>
              <th>
                <FormattedMessage {...messages.rejectedByOn} />
              </th>
              <th>
                <FormattedMessage {...messages.rejectReason} />
              </th>
              <th>
                <FormattedMessage {...messages.voidByOn} />
              </th>
              <th>
                <FormattedMessage {...messages.voidReason} />
              </th>
            </tr>
          </thead>
          <tbody>
            {!isLoading && onlinePayments ? (
              renderPaymentsRows(onlinePayments, selected, toggleSelectedRow)
            ) : (
              <tr>
                <td colSpan={16}>
                  <div className="text-center">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="padtop30"></div>
    </div>
  );
};
export default ManageOnlinePaymentsDetails;
