import { useMemo } from 'react';
import useHistory from '../../hooks/useHistory';

const useSearchParams = () => {
  const history = useHistory();

  return useMemo(() => {
    const { isTransfer, applicationId, prospectId, residentId, fromUnit } =
      Object.fromEntries(new URLSearchParams(history.location.search));

    return {
      isTransfer: isTransfer === 'true',
      applicationId,
      prospectId,
      residentId,
      fromUnit,
    };
  }, [history.location.search]);
};

export default useSearchParams;
