import { useState, useEffect } from 'react';
import ResidentService from '../../../services/residentService';

export const useFetchResidents = ({
  organizationId,
  propertyId,
  promptToaster,
}: Object): any => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentResidents, setCurrentResidents] = useState(null);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    const fetchResidents = async () => {
      setIsLoading(true);
      try {
        const residentService = new ResidentService();
        const residents = await residentService.getAllResidents(
          organizationId,
          propertyId,
        );
        setCurrentResidents(residents);
      } catch (err) {
        promptToaster({
          type: 'error',
          title: 'Error',
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (refresh) {
      fetchResidents();
      setRefresh(false);
    }
  }, [organizationId, propertyId, promptToaster, refresh]);

  return [currentResidents, isLoading];
};
