import React from 'react';
import * as csvHelpers from '../../../utils/csv-helpers';
import * as constants from '../constants';
import Spinner from '../../../components/Spinner';
import * as utils from '../utils';
import {
  Button,
  Dropdown,
  MenuItem,
  OverlayTrigger,
  Popover,
  Tooltip,
} from 'react-bootstrap';
import Table from '../../../components/Table';
import Row from '../../../components/Table/Row';
import Data from '../../../components/Table/Data';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import moment from 'moment';
import { dateDiffInDays } from '../../../utils/date-helpers';
import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';
import messages from '../messages';
import { useAssignedUnitData } from '../hooks';

interface Props {
  assignedUnits: Object<Array>;
  handleSort: Function;
  isAllCommercial: boolean;
  isFetching: boolean;
  quoteLeaseRenewalClick: Function;
  selectLeaseForRenewal: Function;
  today: Date;
}

const ManageExpiringLeasesTable = ({
  assignedUnits,
  handleSort,
  isAllCommercial,
  isFetching,
  quoteLeaseRenewalClick,
  selectLeaseForRenewal,
  today,
}: Props) => {
  return (
    <div className="table-scroll table-units-container">
      <Table
        name="manage-lease-expirations"
        headers={
          isAllCommercial
            ? csvHelpers.getHeaders(constants.headers, 'resident')
            : csvHelpers.getHeaders(constants.headers, 'tenant')
        }
        onSort={handleSort}
        footer={() => (
          <tfoot className="table-footer">
            <tr>
              <td style={{ padding: '13px', background: '#F8F8F8' }}>&nbsp;</td>
            </tr>
          </tfoot>
        )}
      >
        {Array.isArray(assignedUnits) && !isFetching ? (
          assignedUnits.map((assignedUnit) => (
            <ManageExpiringLeasesRow
              key={assignedUnit.id}
              today={today}
              selectLeaseForRenewal={selectLeaseForRenewal}
              quoteLeaseRenewalClick={quoteLeaseRenewalClick}
              assignedUnit={assignedUnit}
            />
          ))
        ) : (
          <tr>
            <td colSpan="19">
              <Spinner />
            </td>
          </tr>
        )}
      </Table>
    </div>
  );
};

export default ManageExpiringLeasesTable;

export const ManageExpiringLeasesRow = ({
  assignedUnit,
  today,
  selectLeaseForRenewal,
  quoteLeaseRenewalClick,
}: {
  assignedUnit: Object,
  today: Date,
  selectLeaseForRenewal: Function,
  quoteLeaseRenewalClick: Function,
}) => {
  const {
    assignedTo,
    balance,
    baseMarketRentAmount,
    disableNote,
    doNotRenew,
    firstName,
    floorPlanId,
    internalName,
    isRenewal,
    isRenewalComplete,
    isRenewalNoteComplete,
    lastName,
    lease,
    notes,
    primaryResident,
    primaryResidentId,
    quotingRentAmount,
    renewalNoteDate,
    selectedLease,
    statusName,
    underEviction,
    unitId,
    unitStatus,
  } = useAssignedUnitData(assignedUnit);

  return (
    <Row>
      <Data>
        <OverlayTrigger
          container={window.document.getElementById('table-row')}
          placement="right"
          delayHide={200}
          trigger={['hover', 'focus']}
          overlay={
            <Tooltip id="tooltip">
              {statusName === '---' ? 'Not Started' : statusName}
            </Tooltip>
          }
        >
          <span>
            {utils.getRenewalStatus({
              isRenewal,
              isRenewalComplete,
              isCsv: false,
            })}
          </span>
        </OverlayTrigger>
      </Data>
      <Data>
        <Link to={getUrlWithSelectedPropertyId(`/unit/${unitId}`)}>
          {selectedLease.unit.number}
        </Link>
      </Data>
      <Data>
        <Link to={getUrlWithSelectedPropertyId(`/floorplan/${floorPlanId}`)}>
          {internalName}
        </Link>
      </Data>
      <Data>
        {primaryResident?.id ? (
          <Link
            to={getUrlWithSelectedPropertyId(`/resident/${primaryResident.id}`)}
          >{`${lastName.length ? `${lastName}, ` : ''}${firstName}`}</Link>
        ) : (
          '---'
        )}
      </Data>
      <Data>{moment.utc(lease.actualMoveInDate).format('MM/DD/YYYY')}</Data>
      <Data>{moment.utc(lease.startDate).format('MM/DD/YYYY')}</Data>
      <Data>{moment.utc(lease.endDate).format('MM/DD/YYYY')}</Data>
      <Data>
        <strong>
          {dateDiffInDays(
            new Date(today),
            new Date(moment.utc(lease.endDate)),
          ).toString()}
        </strong>
      </Data>
      <Data>
        {unitStatus?.description === 'Occupied / On Notice to Vacate'
          ? 'Yes'
          : ' '}
      </Data>
      <Data>
        {doNotRenew && <i className="icon et-alert-urgent text-red" />}
      </Data>
      <Data>
        {underEviction && <i className="icon et-alert-urgent text-red" />}
      </Data>
      <Data>
        {!Number.isNaN(baseMarketRentAmount) && baseMarketRentAmount > 0 ? (
          <FormattedNumber
            value={baseMarketRentAmount}
            style={constants.currencyStyle.value}
            currency="USD"
          />
        ) : (
          '---'
        )}
      </Data>
      <Data>
        {lease?.priorLeasedRent && parseFloat(lease.priorLeasedRent) > 0 ? (
          <FormattedNumber
            value={lease.priorLeasedRent}
            style={constants.currencyStyle.value}
            currency="USD"
          />
        ) : (
          '---'
        )}
      </Data>
      <Data>
        {lease?.leasedRent && parseFloat(lease.leasedRent) > 0 ? (
          <FormattedNumber
            value={lease.leasedRent}
            style={constants.currencyStyle.value}
            currency="USD"
          />
        ) : (
          '---'
        )}
      </Data>
      <Data>---</Data>
      <Data>
        {balance ? (
          <FormattedNumber
            value={balance}
            style={constants.currencyStyle.value}
            currency="USD"
          />
        ) : (
          '---'
        )}
      </Data>
      <Data>
        <FormattedNumber
          value={quotingRentAmount}
          style={constants.currencyStyle.value}
          currency="USD"
        />
      </Data>
      <Data>
        <OverlayTrigger
          placement="left"
          overlay={
            <Popover id={`document-${document.id}`}>
              {notes}
              {!notes && '(No notes)'}
              {notes && isRenewalNoteComplete && (
                <span className="notes-date">
                  {moment.utc(renewalNoteDate).format('MMM DD, YYYY')} -{' '}
                  {assignedTo}
                </span>
              )}
            </Popover>
          }
        >
          <Button
            bsStyle="default"
            bsSize="small"
            className="btn-comments"
            disabled={disableNote}
          >
            <i className="icon et-comment-words" />
          </Button>
        </OverlayTrigger>
      </Data>
      <Data>
        <Dropdown
          data-testid={'actionMenuDropdown'}
          bsSize="small"
          pullRight
          id={'action-menu-{title}'}
          disabled={!primaryResidentId}
        >
          <Dropdown.Toggle onClick={() => selectLeaseForRenewal(selectedLease)}>
            <i className="et-bolt" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem
              eventKey="1"
              onClick={() => {
                quoteLeaseRenewalClick(primaryResidentId);
              }}
            >
              <FormattedMessage {...messages.quoteLeaseRenewal} />
            </MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </Data>
    </Row>
  );
};
