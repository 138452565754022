import React from 'react';
import { pathOr } from 'ramda';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import {
  renderCurrencyField,
  renderDateField,
} from '../../../../utils/redux-form-helper';
import styled from 'styled-components';

const StyledDiv = styled.div`
  margin-top: -20px;
`;

export const generateEditOptions = (selectedOption: string) => {
  return [
    {
      value: 'changeAmount',
      text: (
        <Row className="padbottom30">
          <Col xs={2}>
            <FormattedMessage {...messages.changeAmount} />
          </Col>
          <Col xs={3}>
            <StyledDiv>
              <Field
                name="newAmount"
                component={renderCurrencyField}
                disabled={selectedOption !== 'changeAmount'}
                label="New Amount"
              />
            </StyledDiv>
          </Col>
          <Col xs={3}>
            <StyledDiv>
              <Field
                name="newStartDate"
                component={renderDateField}
                bsSize="md"
                label="New Start Date"
                disabled={selectedOption !== 'changeAmount'}
              />
            </StyledDiv>
          </Col>
        </Row>
      ),
      disabled: false,
    },
    {
      value: 'endCharge',
      text: (
        <Row>
          <Col xs={2}>
            <FormattedMessage {...messages.endCharge} />
          </Col>
          <Col xs={3}>
            <StyledDiv>
              <Field
                name="endDate"
                component={renderDateField}
                bsSize="md"
                label="End Date"
                disabled={selectedOption !== 'endCharge'}
              />
            </StyledDiv>
          </Col>
        </Row>
      ),
      disabled: false,
    },
  ];
};

/**
  Get the list of monthly charges to display based on the "selected period (past/current/future)"
  This function also checks which charge can be edited or not. Only the latest charge (by createdAt)
  for a given CAM Allocation can be edited.

  Argument `monthlyCharges` is: { past: [charge1, charge2], current: [charge3], future: [charge4] }
 */
export const getMonthlyChargesToDisplay = (
  monthlyCharges: Object,
  selectedPeriod: string,
): Array<Object> => {
  const allMonthlyCharges = [
    ...pathOr([], ['past'], monthlyCharges),
    ...pathOr([], ['current'], monthlyCharges),
    ...pathOr([], ['future'], monthlyCharges),
  ];
  const latestMonthlyChargesPerCamAllocationsId = allMonthlyCharges.reduce(
    (acc, currentCharge) => {
      const allocationsId = pathOr(null, ['camAllocationsId'], currentCharge);
      if (!allocationsId) {
        return { ...acc };
      }

      const latestCreatedAt = pathOr(null, [allocationsId, 'createdAt'], acc);
      const chargeCreatedAt = pathOr(null, ['createdAt'], currentCharge);
      const mLatestCreatedAt = moment(latestCreatedAt);
      const mChargeCreatedAt = moment(chargeCreatedAt);
      if (
        !latestCreatedAt ||
        !mLatestCreatedAt.isValid() ||
        mChargeCreatedAt.isSameOrAfter(mLatestCreatedAt)
      ) {
        return {
          ...acc,
          [allocationsId]: currentCharge,
        };
      }
      return { ...acc };
    },
    {},
  );

  const rawMonthlyChargesToDisplay = pathOr(
    [],
    [selectedPeriod],
    monthlyCharges,
  );
  const monthlyChargesToDisplay = rawMonthlyChargesToDisplay.map((charge) => {
    const allocationsId = pathOr(null, ['camAllocationsId'], charge);
    if (!allocationsId) {
      return {
        ...charge,
        allowEditing: false,
      };
    }

    const chargeId = pathOr(
      null,
      ['camCalculationsHouseholdMonthlyTransactionsId'],
      charge,
    );
    const latestChargeIdForAllocation = pathOr(
      null,
      [allocationsId, 'camCalculationsHouseholdMonthlyTransactionsId'],
      latestMonthlyChargesPerCamAllocationsId,
    );
    return {
      ...charge,
      allowEditing: chargeId === latestChargeIdForAllocation,
    };
  });

  return monthlyChargesToDisplay;
};
