import * as ActionTypes from './constants';

import type { Action } from '../App/types';

export function getApplicantSecondaryFormInformation(
  applicantId: string,
): Action<string> {
  return {
    type: ActionTypes.GET_APPLICANT_SECONDARY_FORM_INFORMATION,
    payload: applicantId,
  };
}

export function getApplicantSecondaryFormInformationSuccess(
  applicant: any,
): Action<any> {
  return {
    type: ActionTypes.GET_APPLICANT_SECONDARY_FORM_INFORMATION_SUCCESS,
    payload: applicant,
  };
}

export function getApplicantSecondaryFormInformationError(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.GET_APPLICANT_SECONDARY_FORM_INFORMATION_ERROR,
    payload: error,
  };
}

export function saveSecondaryForm(
  applicant: any,
  applicantId: string,
  applicationId: string,
  convertedResident: boolean,
): Action<any> {
  return {
    type: ActionTypes.SAVE_SECONDARY_FORM,
    payload: {
      applicant,
      applicantId,
      applicationId,
      convertedResident,
    },
  };
}

export function saveSecondaryFormSuccess(): Action<any> {
  return {
    type: ActionTypes.SAVE_SECONDARY_FORM_SUCCESS,
    payload: undefined,
  };
}

export function saveSecondaryFormError(error: Error): Action<any> {
  return {
    type: ActionTypes.SAVE_SECONDARY_FORM_ERROR,
    payload: error,
  };
}
export function cleanApplicantSecondaryFormInformation(): Action<any> {
  return {
    type: ActionTypes.CLEAN_APPLICANT_SECONDARY_FORM_INFORMATION,
    payload: undefined,
  };
}
