import type { Screening } from '../containers/ApplicationProfile/types';

const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class TransUnionService {
  getScreeningStatus(applicationId: string) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/screening/status/${applicationId}`, options);
  }
  getScreeningApplication(applicationId: string) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/screening/application/${applicationId}`, options);
  }
  submitApplication(payload: Object) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      timeout: 1000 * 540,
    };
    return request(`${apiURL}/screening/application`, options);
  }
  save(organizationId: string, applicationId: string, screening: Screening) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        applicationId,
        screening,
      }),
    };
    return request(`${apiURL}/${organizationId}/screening`, options);
  }
}

export default TransUnionService;
