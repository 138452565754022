import React from 'react';
import { Panel, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import {
  Header,
  Title,
  EditLink,
  EditIcon,
  EditColumn,
} from '../../../components/ProfileVisualComponents';
import styled from 'styled-components';

import type {
  AdditionalOccupant,
  PetOccupant,
} from '../../ProspectProfile/types.js';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import ServiceAnimalIndicator from '../../Household/ServiceAnimalIndicator';
import IconTitle from '../../../components/ProfileVisualComponents/IconTitle';

type Props = {
  additionalOccupants: Array<AdditionalOccupant>,
  currentProspect: Object,
  petOccupants: Array<PetOccupant>,
  onEdit: Function,
  editHouseholdMember: Function,
  isResidentPortalActive: boolean,
};
const dashesIfNullOrUndefined = (data: string | number) =>
  !data || data === null ? '---' : data;
const HouseholdPanel = styled(Panel).attrs({
  className: 'block block-household block-white-shadow',
})``;
const Household = ({
  additionalOccupants,
  currentProspect,
  petOccupants,
  onEdit,
  editHouseholdMember,
  isResidentPortalActive,
}: Props) => {
  const spouse = additionalOccupants.filter((f) => f.type === 'Spouse');
  const otherAdult = additionalOccupants.filter(
    (f) => f.type === 'Other Adult',
  );
  const kid = additionalOccupants.filter((f) => f.type === 'Minor');
  return (
    <HouseholdPanel>
      <Header>
        <Title>
          <Row>
            <Col xs={12} sm={9}>
              <IconTitle
                icon={<i key="1" className="et-people" />}
                message={messages.household}
              />
            </Col>
            <EditColumn className="text-right">
              <ElementWithPermissions scope={['prospect-update']}>
                <EditLink onClick={onEdit}>
                  <EditIcon />
                  <FormattedMessage {...messages.edit} />
                </EditLink>
              </ElementWithPermissions>
            </EditColumn>
          </Row>
        </Title>
      </Header>
      <Panel.Body>
        <Row className="row">
          <Col xs={12}>
            <table className="table table-simple">
              <tbody>
                <tr>
                  <th style={{ width: '60%' }}>
                    <FormattedMessage {...messages.name} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.relation} />
                  </th>
                </tr>
                <tr key={currentProspect.id}>
                  <td>
                    {isResidentPortalActive ? (
                      <a onClick={() => editHouseholdMember(currentProspect)}>
                        {`${dashesIfNullOrUndefined(
                          currentProspect.firstName,
                        )} ${currentProspect.lastName || ''}`}
                      </a>
                    ) : (
                      `${dashesIfNullOrUndefined(currentProspect.firstName)} ${
                        currentProspect.lastName || ''
                      }`
                    )}
                  </td>
                  <td>---</td>
                </tr>
                {spouse.map((occupant) => (
                  <tr key={occupant.id}>
                    <td>{`${dashesIfNullOrUndefined(occupant.firstName)} ${
                      occupant.lastName || ''
                    }`}</td>
                    <td>{occupant.type}</td>
                  </tr>
                ))}
                {otherAdult.map((occupant) => (
                  <tr key={occupant.id}>
                    <td>{`${dashesIfNullOrUndefined(occupant.firstName)} ${
                      occupant.lastName || ''
                    }`}</td>
                    <td>{occupant.type}</td>
                  </tr>
                ))}
                {kid.map((occupant) => (
                  <tr key={occupant.id}>
                    <td>{`${dashesIfNullOrUndefined(occupant.firstName)} ${
                      occupant.lastName || ''
                    }`}</td>
                    <td>{occupant.type}</td>
                  </tr>
                ))}
                {petOccupants.map((occupant) => (
                  <tr key={occupant.id}>
                    <td>{dashesIfNullOrUndefined(occupant.name)}</td>
                    <td>
                      <Stack
                        direction={'row'}
                        spacing={0.5}
                        alignItems={'center'}
                      >
                        <span>Pet</span>
                        {occupant.serviceAnimal && <ServiceAnimalIndicator />}
                      </Stack>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </Panel.Body>
    </HouseholdPanel>
  );
};

export default Household;
