import { put, takeLatest, throttle } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { omit } from 'ramda';
import { reset } from 'redux-form';

import {
  getAllPermissionsFail,
  getAllPermissionsSuccess,
  getAllRoleTypesSuccess,
  getAllRoleTypesFail,
  createRoleSuccess,
  createRoleFail,
  resetCreateRoleForm,
} from './actions';
import PermissionService from '../../services/permissionService';
import RoleService from '../../services/roleService';
import {
  GET_ALL_PERMISSIONS,
  GET_ALL_ROLE_TYPES,
  CREATE_ROLE,
} from './constants';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import messages from './messages';
import type { Action, Role } from '../App/types';
import type { Saga } from 'redux-saga';

export function* fetchGetAllPermissions(): Saga<void> {
  try {
    const permissionService = new PermissionService();
    const response = yield permissionService.getAllPermissions();
    yield put(getAllPermissionsSuccess(response));
  } catch (err) {
    yield put(getAllPermissionsFail(err));
  }
}

export function* getAllPermissionsSaga(): Saga<void> {
  yield takeLatest(GET_ALL_PERMISSIONS, fetchGetAllPermissions);
}

export function* fetchGetAllRoleTypes(): Saga<void> {
  try {
    const roleService = new RoleService();
    const response = yield roleService.getAllRoleTypes();
    yield put(getAllRoleTypesSuccess(response));
  } catch (err) {
    yield put(getAllRoleTypesFail(err));
  }
}

export function* getAllRoleTypesSaga(): Saga<void> {
  yield takeLatest(GET_ALL_ROLE_TYPES, fetchGetAllRoleTypes);
}

export function* postCreateRole(action: Action<Role>): Saga<void> {
  try {
    const newRole = omit(['shouldRedirect'], action.payload);
    const roleService = new RoleService();
    yield roleService.createRole(newRole);
    yield put(createRoleSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    if (action.payload.shouldRedirect) yield put(push('/manage-roles'));
    yield put(reset('createRole'));
    yield put(resetCreateRoleForm());
  } catch (err) {
    yield put(createRoleFail(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* createRoleSaga(): Saga<void> {
  yield throttle(500, CREATE_ROLE, postCreateRole);
}

export default [getAllPermissionsSaga, getAllRoleTypesSaga, createRoleSaga];
