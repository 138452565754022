export const getIsUnitAssignDisabled = ({
  assignedApplicationId,
  applicantToAssignUnitId,
  applicantApplicationStatus,
  applicantPropertyClass,
  isFloorPlanAffordable,
  isFloorPlanCommercial,
  isSelfAssigned,
  applicationIsCommercial,
  isTransfer,
  isNotSameHUDTypeFloorplan,
  hasMultipleHUDSubsidies,
  lastCertIsTermination,
}) => {
  const canAssignUnit = [
    'Approved',
    'In Process',
    'Converted to Resident',
  ].includes(applicantApplicationStatus?.name);

  const applicationMatchesPropertyClass = checkApplicationMatchesPropertyClass({
    applicationPropertyClass: applicantPropertyClass?.name,
    isFloorPlanAffordable,
    isFloorPlanCommercial,
    applicationIsCommercial,
  });

  let disabled = false;

  if (!canAssignUnit || isSelfAssigned || !applicationMatchesPropertyClass)
    disabled = true;

  if (
    applicantToAssignUnitId &&
    assignedApplicationId &&
    applicantToAssignUnitId !== assignedApplicationId
  ) {
    disabled = true;
  }

  if (
    isTransfer &&
    isNotSameHUDTypeFloorplan &&
    hasMultipleHUDSubsidies &&
    !lastCertIsTermination
  ) {
    disabled = true;
  }

  return disabled;
};

export const checkShouldRenderAssignOption = ({
  applicationId,
  assignedApplicationId,
}) => {
  return (
    !!applicationId &&
    (!assignedApplicationId ||
      (assignedApplicationId && assignedApplicationId !== applicationId))
  );
};

export const checkApplicationMatchesPropertyClass = ({
  applicationPropertyClass,
  isFloorPlanAffordable,
  isFloorPlanCommercial,
  applicationIsCommercial,
}) => {
  const isApplicationAffordable = applicationPropertyClass === 'Affordable';
  const applicationIsAffordableAndMatchesAffordablePropertyClass =
    isApplicationAffordable &&
    isApplicationAffordable === isFloorPlanAffordable;

  const applicationIsCommercialAndMatchesCommercialPropertyClass =
    applicationIsCommercial &&
    applicationIsCommercial === isFloorPlanCommercial;

  const applicationIsConventional = applicationPropertyClass === 'Conventional';
  const applicationIsConventionalAndMatchesConventionalPropertyClass =
    applicationIsConventional &&
    applicationIsConventional ===
      (!isFloorPlanCommercial &&
        !isApplicationAffordable &&
        !applicationIsCommercial);

  const applicationMatchesPropertyClass =
    applicationIsAffordableAndMatchesAffordablePropertyClass ||
    applicationIsCommercialAndMatchesCommercialPropertyClass ||
    applicationIsConventionalAndMatchesConventionalPropertyClass;

  return applicationMatchesPropertyClass;
};
