import { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import PropertyFeesService from '../../services/propertyFeesService';
import PropertyTransactionCodesService from '../../services/propertyTransactionCodesService';
import { useEditAmenity } from './hooks';
import Layout from './Layout';
import useFormatMessage from '../../hooks/useFormatMessage';
import messages from './messages';

function EditAmenity({
  history,
  match: {
    params: { propertyFeeId, propertyId },
  },
}) {
  const formatMessage = useFormatMessage();
  const selectedProperty = useSelectedProperty();
  const { isPageLoading, amenityFormProps } = useEditAmenity({
    propertyFeeId,
    history,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updatePropertyFee = async (data: Object, amenityPtcId: string) => {
    try {
      const pfService = new PropertyFeesService();

      return await pfService.updatePropertyFee(propertyFeeId, {
        feeAmount: data.feeAmount,
        displayNameOnQuote: data.amenityName,
      });
    } catch (e) {
      toastr.error(
        formatMessage(messages.error),
        formatMessage(messages.amenityUpdatedError),
      );
    }
  };

  const updateAssignedUnitFees = async (data: Object, amenityPtcId: string) => {
    try {
      const newAssignedUnitFees = data.selectedUnitIds.map((unitId) => ({
        propertyId: selectedProperty.id,
        unitId,
        propertyTransactionCodeId: amenityPtcId,
        propertyFeeId: propertyFeeId,
        startDate: new Date(),
        endDate: null,
        feeAmount: data.feeAmount,
      }));
      const pfService = new PropertyFeesService();

      return pfService.updateAssignedUnitFees(
        propertyFeeId,
        newAssignedUnitFees,
      );
    } catch (error) {
      toastr.error(
        formatMessage(messages.error),
        formatMessage(messages.assignUnitErrorOnUpdate),
      );
    }
  };

  const handleSubmit = async (data) => {
    setIsSubmitting(true);

    const ptcService = new PropertyTransactionCodesService();

    const amenityPtc = await ptcService.getByMasterTransactionCode(
      selectedProperty.id,
      'AMENITY',
    );

    const updatedPropertyFee = await updatePropertyFee(data, amenityPtc.id);

    if (!updatedPropertyFee) {
      setIsSubmitting(false);
      return;
    }

    const unitFees = await updateAssignedUnitFees(data, amenityPtc.id);

    if (unitFees !== undefined)
      toastr.success(
        formatMessage(messages.success),
        formatMessage(messages.amenityUpdatedSuccess),
      );

    history.push(`/property/${propertyId}/manage-amenities`);
  };

  return (
    <Layout
      isEdit
      isLoading={isPageLoading || isSubmitting}
      amenityFormProps={{
        ...amenityFormProps,
        onSubmit: handleSubmit,
      }}
    />
  );
}

export default EditAmenity;
