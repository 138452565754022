import { useCallback, useEffect, useRef } from 'react';

export function useSafeSet() {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const safeSet = useCallback(
    (setFn: Function) => (value: any) => {
      if (isMounted.current) {
        setFn(value);
      }
    },
    [],
  );

  return [safeSet];
}
