// $FlowFixMe
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { pathOr } from 'ramda';

import ConfirmOverlay from '../../components/ConfirmOverlay';

import messages from './messages';
import { FORM_NAME } from './constants';
import { createVehicle, updateVehicle, deleteVehicle } from './actions';
import VehiclesForm from './VehiclesForm';
import type { Vehicle } from './types';

type ConnectedProps = {
  anyTouched: boolean,
  invalid: boolean,
};

type ConnectedMethods = {
  actions: {
    submit: Function,
    createVehicle: Function,
    updateVehicle: Function,
    deleteVehicle: Function,
  },
};

type Props = ConnectedProps &
  ConnectedMethods & {
    applicationId: string,
    onHide: Function,
    residentId?: string,
    show: boolean,
    vehicleCount: ?number,
    householdId: string,
    updateHousholdVehicles: Function,
    vehicleToEdit: Vehicle,
  };

const HouseholdVehicles = ({
  actions,
  anyTouched,
  applicationId,
  householdId,
  invalid,
  onHide,
  updateHousholdVehicles,
  residentId,
  show,
  vehicleCount,
  vehicleToEdit,
}: Props) => {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const hideCancelConfirmation = () => setShowCancelConfirmation(false);
  const hideDeleteConfirmation = () => setShowDeleteConfirmation(false);
  const handleDeleteVehicleClick = () => setShowDeleteConfirmation(true);

  const handleHide = () => {
    if (anyTouched && !showCancelConfirmation) {
      setShowCancelConfirmation(true);
      return;
    }
    setShowCancelConfirmation(false);
    if (onHide) onHide();
  };

  const handleSubmit = (vehicle) => {
    const { createVehicle, updateVehicle } = actions;
    if (Object.keys(vehicleToEdit).length === 0) {
      createVehicle({ householdId, vehicle, updateHousholdVehicles });
    } else {
      const vehicleId = vehicleToEdit.id;
      updateVehicle({
        householdId,
        vehicleId,
        vehicle,
        updateHousholdVehicles,
      });
    }
  };

  const submit = (evt: Object) => {
    evt.preventDefault();
    const { submit } = actions;
    submit(FORM_NAME);
    if (onHide) onHide();
  };

  const handleDeleteVehicleConfirm = () => {
    const { deleteVehicle } = actions;
    const vehicleId = vehicleToEdit.id;
    deleteVehicle({ householdId, vehicleId, updateHousholdVehicles });
    setShowDeleteConfirmation(false);
    if (onHide) onHide();
  };

  return (
    <Modal show={show} onHide={handleHide} bsSize="large" autoFocus>
      <Modal.Header closeButton>
        <i className="icon et-drive" />
        <h1>
          <FormattedMessage {...messages.header} />
        </h1>
      </Modal.Header>
      <Modal.Body className="modal-vehicle-hh">
        {showCancelConfirmation && (
          <ConfirmOverlay
            title={<FormattedMessage {...messages.confirmationTitle} />}
            rejectMessage={
              <FormattedMessage {...messages.confirmationCancel} />
            }
            confirmMessage={<FormattedMessage {...messages.confirm} />}
            onReject={hideCancelConfirmation}
            onConfirm={handleHide}
          />
        )}
        {showDeleteConfirmation && (
          <ConfirmOverlay
            title={<FormattedMessage {...messages.confirmDeleteTitle} />}
            rejectMessage={
              <FormattedMessage {...messages.confirmationCancel} />
            }
            confirmMessage={<FormattedMessage {...messages.confirm} />}
            onReject={hideDeleteConfirmation}
            onConfirm={handleDeleteVehicleConfirm}
          />
        )}
        <VehiclesForm
          applicationId={applicationId}
          vehicleCount={vehicleCount}
          onSubmit={handleSubmit}
          initialValues={vehicleToEdit}
          handleDeleteVehicle={handleDeleteVehicleClick}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col-xs-6">
            <button
              className="btn btn-default pull-right"
              onClick={handleHide}
              disabled={showCancelConfirmation}
            >
              <FormattedMessage {...messages.cancel} />
            </button>
          </div>
          <div className="col-xs-6">
            <button
              className="btn btn-primary pull-left"
              onClick={submit}
              disabled={showCancelConfirmation || invalid || !anyTouched}
            >
              <FormattedMessage {...messages.save} />
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({ form }: Object): ConnectedProps => {
  const anyTouched = pathOr(false, [FORM_NAME, 'anyTouched'], form);
  const invalid =
    Object.keys(pathOr({}, [FORM_NAME, 'syncErrors'], form)).length > 0;
  return { anyTouched, invalid };
};

const mapDispatchToProps = (dispatch: Object): ConnectedMethods => ({
  actions: bindActionCreators(
    { submit, createVehicle, updateVehicle, deleteVehicle },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(HouseholdVehicles);
