import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from './messages';
import confirm from '../../../components/ConfirmDialogModal';

type Props = {
  intl: any,
  onClearAll: Function,
};

const ProrateCalculatoFooter = ({ intl, onClearAll }: Props) => {
  const confirmClearAll = () => {
    confirm(intl.formatMessage(messages.confirmClearAll)).then(
      () => onClearAll(),
      () => {},
    );
  };

  return (
    <div className="container-fluid text-center">
      <Button
        onClick={confirmClearAll}
        variant="primarySubtle"
        disabled={false}
        className="text-center"
        data-testid="clear-all-btn"
      >
        <FormattedMessage {...messages.clearAllFields} />
      </Button>
    </div>
  );
};

export default ProrateCalculatoFooter;
