import { useCallback, useContext } from 'react';
import * as R from 'ramda';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AppContext } from '../App/context';
import ReputationManagementService, {
  FetchReviewParams,
} from '../../services/reputationManagementService';
import { toastr } from 'react-redux-toastr';
import useFetchAndSetData from '../../hooks/data-fetching/useFetchAndSetData';
import useFormatMessage from '../../hooks/useFormatMessage';
import * as constants from './constants';
import messages from './messages';
import type { Review, RatingBreakdown, ReviewResponse } from './types';

export type ReviewStats = {
  avgRating: number,
  ratingCount: number,
  reviewCount: number,
  sourceCount: number,
  recommendedPercent: number,
  notRecommendedPercent: number,
  recommendedCount: number,
  notRecommendedCount: number,
};

export type ReviewData = {
  reviews: Review[],
} & ReviewStats;

export type UseFetchReviewsResponse = {
  reviewData: ReviewData | null,
  isLoading: boolean,
  refreshReviews: Function,
};

export type UseFetchOverallStatsResponse = {
  overallStats: ReviewStats | null,
  isLoading: boolean,
};

export type UseFetchRatingBreakdownResponse = {
  ratingBreakdown: RatingBreakdown | null,
  isLoading: boolean,
};

export type UseMappedSourcesResponse = {
  mappedSources: string[],
  isLoading: boolean,
};

export type UseIsPropertyMappedResponse = {
  isPropertyMapped: boolean,
  isLoading: boolean,
};

export function useFetchReviews(
  params: FetchReviewParams,
): UseFetchReviewsResponse {
  const formatMessage = useFormatMessage();
  const { selectedProperty } = useContext(AppContext);
  const propertyId = selectedProperty?.id;
  const organizationId = selectedProperty?.organizationId;
  const initialState = null;

  const fetcherCallback = useCallback(() => {
    const reputationManagementService = new ReputationManagementService();
    return reputationManagementService.getPropertyReviews(
      organizationId,
      propertyId,
      params,
    );
  }, [organizationId, propertyId, params]);

  const handleError = useCallback(() => {
    toastr.error(
      formatMessage(messages.error),
      formatMessage(messages.errorFetchReviews),
    );
  }, [formatMessage]);

  const result = useFetchAndSetData(
    initialState,
    fetcherCallback,
    undefined,
    handleError,
  );

  const updateReviewResponse = (reviewId: string, response: ReviewResponse) => {
    const setReviewData = result[1];
    const reviewData = result[0];

    if (!reviewData) return;

    const { reviews } = reviewData;

    const reviewIndex = reviews.findIndex((r) => r.id === reviewId);

    if (reviewIndex < 0) return;

    const review = reviews[reviewIndex];

    const newReview = { ...review, response };

    setReviewData({
      ...reviewData,
      reviews: R.update(reviewIndex, newReview, reviews),
    });
  };

  return {
    reviewData: result[0],
    isLoading: result[2],
    refreshReviews: result[3],
    updateReviewResponse,
  };
}

export function useOverallStats(
  sources: string[],
): UseFetchOverallStatsResponse {
  const formatMessage = useFormatMessage();
  const { selectedProperty } = useContext(AppContext);
  const propertyId = selectedProperty?.id;
  const organizationId = selectedProperty?.organizationId;
  const initialState = null;

  const fetcherCallback = useCallback(() => {
    const reputationManagementService = new ReputationManagementService();
    return reputationManagementService.getReviewStats(
      organizationId,
      propertyId,
      sources,
    );
  }, [organizationId, propertyId, sources]);

  const handleError = useCallback(() => {
    toastr.error(
      formatMessage(messages.error),
      formatMessage(messages.errorFetchOverallStats),
    );
  }, [formatMessage]);

  const result = useFetchAndSetData(
    initialState,
    fetcherCallback,
    undefined,
    handleError,
  );

  return {
    overallStats: result[0],
    isLoading: result[2],
    refreshStats: result[3],
  };
}

export function useRatingBreakdown(
  sources: string[],
  ratingType: string,
): UseFetchRatingBreakdownResponse {
  const formatMessage = useFormatMessage();
  const { selectedProperty } = useContext(AppContext);
  const propertyId = selectedProperty?.id;
  const organizationId = selectedProperty?.organizationId;
  const initialState = null;

  const fetcherCallback = useCallback(() => {
    if (ratingType === constants.RECOMMEND_BASED) return;

    const reputationManagementService = new ReputationManagementService();
    return reputationManagementService.getRatingBreakdown(
      organizationId,
      propertyId,
      sources,
    );
  }, [organizationId, propertyId, sources, ratingType]);

  const handleError = useCallback(() => {
    toastr.error(
      formatMessage(messages.error),
      formatMessage(messages.errorFetchBreakdown),
    );
  }, [formatMessage]);

  const result = useFetchAndSetData(
    initialState,
    fetcherCallback,
    undefined,
    handleError,
  );

  return {
    ratingBreakdown: result[0],
    isLoading: result[2],
    refreshBreakdown: result[3],
  };
}

export function useMappedSources(): UseMappedSourcesResponse {
  const { reputationMgmtRelease2 } = useFlags();
  const formatMessage = useFormatMessage();
  const { selectedProperty } = useContext(AppContext);
  const propertyId = selectedProperty?.id;
  const organizationId = selectedProperty?.organizationId;
  const initialState = null;

  const fetcherCallback = useCallback(() => {
    if (!reputationMgmtRelease2) return Promise.resolve(null);

    const reputationManagementService = new ReputationManagementService();
    return reputationManagementService.getPropertyMappedSources(
      organizationId,
      propertyId,
    );
  }, [organizationId, propertyId, reputationMgmtRelease2]);

  const handleError = useCallback(() => {
    toastr.error(
      formatMessage(messages.error),
      formatMessage(messages.errorFetchingMappedSources),
    );
  }, [formatMessage]);

  const result = useFetchAndSetData(
    initialState,
    fetcherCallback,
    undefined,
    handleError,
  );

  return {
    mappedSources: result[0],
    isLoading: result[2],
  };
}

export function useIsPropertyMapped(): UseIsPropertyMappedResponse {
  const { mappedSources, isLoading } = useMappedSources();

  return {
    isPropertyMapped: mappedSources && mappedSources?.length !== 0,
    isLoading,
  };
}
