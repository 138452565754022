import React from 'react';

type Props = {
  hasFilter?: boolean,
  columnLabel: string,
  ascendingLabel?: string,
  descendingLabel?: string,
  order?: string,
  sortable?: boolean,
  icon?: string,
  onOrderClick?: Function,
};

const ColumnHeader = ({
  hasFilter = false,
  columnLabel,
  ascendingLabel = '',
  descendingLabel = '',
  order = '',
  sortable = false,
  icon = 'sortable',
  onOrderClick = () => {},
}: Props) => (
  <th className={hasFilter ? 'selected-filter' : ''}>
    {!sortable && columnLabel}
    {sortable && (
      <span>
        {columnLabel}
        <i className={`et-${icon} sort-option`} onClick={onOrderClick()} />
      </span>
    )}
  </th>
);

export default ColumnHeader;
