import { defineMessages } from 'react-intl';

const messages = defineMessages({
  screening: {
    id: 'App.ScreeningStatusModal.Screening',
    defaultMessage: 'Screening'
  },
  results: {
    id: 'App.ScreeningStatusModal.Results',
    defaultMessage: 'Results from TransUnion:'
  },
  save: {
    id: 'App.ScreeningStatusModal.Save',
    defaultMessage: 'Save'
  },
  finalDecision: {
    id: 'App.ScreeningStatusModal.FinalDecision',
    defaultMessage: 'Final Decision'
  },
  submitted: {
    id: 'App.ScreeningStatusModal.Submitted',
    defaultMessage: 'Submitted by'
  },
  submittedOn: {
    id: 'App.ScreeningStatusModal.SubmittedOn',
    defaultMessage: 'Submitted on'
  },
  submitting: {
    id: 'App.ScreeningStatusModal.Submitting',
    defaultMessage: 'Submitting info to TransUnion...'
  },
  status: {
    id: 'App.ScreeningStatusModal.Status',
    defaultMessage: 'Status: '
  },
  on: {
    id: 'App.ScreeningStatusModal.On',
    defaultMessage: 'on'
  },
  choose: {
    id: 'App.ScreeningStatusModal.Choose',
    defaultMessage: 'Choose'
  },
  approved: {
    id: 'App.ScreeningStatusModal.Approved',
    defaultMessage: 'Approved'
  },
  approvedWithConditions: {
    id: 'App.ScreeningStatusModal.ApprovedwithConditions',
    defaultMessage: 'Approved with Conditions'
  },
  decline: {
    id: 'App.ScreeningStatusModal.Decline',
    defaultMessage: 'Decline'
  },
  cannotRetrieve: {
    id: 'App.ScreeningStatusModal.CannotRetrieveResults',
    defaultMessage: 'Cannot Retrieve Results'
  },
  submitAgain: {
    id: 'App.ScreeningStatusModal.SubmitAgain',
    defaultMessage: 'Submit Again'
  },
  pleaseWait: {
    id: 'App.ScreeningStatusModal.PleaseWait',
    defaultMessage: 'Please wait.'
  },
  confirmApprove: {
    id: 'App.ScreeningStatusModal.ConfirmApprove',
    defaultMessage: 'Are you sure you want to approve?'
  },
  confirmConditions: {
    id: 'App.ScreeningStatusModal.ConfirmConditions',
    defaultMessage: 'Are you sure you want to approve with conditions?'
  },
  confirmDecline: {
    id: 'App.ScreeningStatusModal.ConfirmDecline',
    defaultMessage: 'Are you sure you want to decline?'
  },
  yes: {
    id: 'App.ScreeningStatusModal.Yes',
    defaultMessage: 'Yes'
  },
  no: {
    id: 'App.ScreeningStatusModal.No',
    defaultMessage: 'No'
  },
  cancelConfirmationHeader: {
    id: 'App.ScreeningStatusModal.CancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to close without saving?'
  }
});

export default messages;
