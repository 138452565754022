import React, { useEffect } from 'react';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import { pathOr, find, propEq } from 'ramda';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import {
  renderCurrencyField,
  renderDateField,
  renderRadioGroupField,
  renderSelectField,
  renderSimpleCheckboxField,
  yesNoBooleanOptions,
  required,
  positive,
  positiveOrZero,
  validDate,
} from '../../../../../utils/redux-form-helper';

import messages from '../messages';
import { EDIT_GR_CERT_FORM_NAME } from '../constants';

const EditGRCertForm = ({
  onHide,
  show,
  userPermissions,
  anticipatedVoucherOverride,
  intl,
  householdValues = {},
  pristine,
  valid,
  correctionCodes,
  updateDisableSubmittal,
  initialValues,
  qualifyForRentOverride,
}: Object) => {
  const hasPermissionEditAnticipatedVoucher = !!find(
    propEq('scope', 'edit-anticipated-voucher-date'),
  )(userPermissions);

  const {
    unit,
    headOfHousehold,
    grEffectiveDate,
    isCorrection,
    hasPriorHUDCerts,
  } = householdValues;

  const effectiveDate = grEffectiveDate
    ? moment(grEffectiveDate).format('MM/DD/YYYY')
    : '---';

  useEffect(() => {
    updateDisableSubmittal(pristine || !valid);
  }, [pristine, valid, updateDisableSubmittal]);

  const disableTTPbeforeOverride = qualifyForRentOverride !== 'yes';

  return (
    <>
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>
            Unit #{unit} - {headOfHousehold}
          </h2>
          <h2>Effective Date: {effectiveDate}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <Field
            name="grossRentEdits.anticipatedVoucherDate"
            component={renderDateField}
            placeholder="MM/YYYY"
            dateFormat="MM/YYYY"
            classPicker="modal-fixed-position"
            bsSize="lg"
            disabled={!anticipatedVoucherOverride}
            label={intl.formatMessage(messages.anticipatedVoucherDate)}
            validate={[required, validDate]}
          />
          <Field
            name="grossRentEdits.anticipatedVoucherOverride"
            component={renderSimpleCheckboxField}
            label={intl.formatMessage(messages.anticipatedVoucherOverride)}
            disabled={!hasPermissionEditAnticipatedVoucher}
            style={{ position: 'relative', top: '-10px' }}
          />
        </div>
      </div>
      {isCorrection && (
        <div className="row">
          <div className="col-md-6">
            <Field
              name="correction.masterCorrectionCodeId"
              component={renderSelectField}
              options={correctionCodes}
              bsSize="lg"
              label="Correction Type"
            />
          </div>
          <div className="col-md-6">
            <label>EIV Indicator</label>
            <Field
              name="correction.eivIndicator"
              component={renderRadioGroupField}
              options={yesNoBooleanOptions}
              className="form-options"
            />
          </div>
        </div>
      )}
      {!hasPriorHUDCerts && (
        <>
          <div className="row">
            <h3>Prior Certification Info</h3>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Certification Date</label>
              <Field
                name="prevMissingCert.priorCertificationDate"
                component={renderDateField}
                bsSize="lg"
                classPicker="modal-fixed-position"
                validate={[required, validDate]}
              />
            </div>
            <div className="col-md-6">
              <label>Prior HUD Gross Rent</label>
              <Field
                name="prevMissingCert.prevHUDGrossRentLimit"
                component={renderCurrencyField}
                bsSize="lg"
                validate={[required, positive]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Field
                name="prevMissingCert.adjustedAnnualIncome"
                component={renderCurrencyField}
                bsSize="lg"
                label="Adjusted Annual Income"
                validate={[required, positiveOrZero]}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="prevMissingCert.ttp"
                component={renderCurrencyField}
                bsSize="lg"
                label="Total Tenant Payment"
                validate={[required, positive]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Unit qualifies for rent override</label>
              <Field
                name="prevMissingCert.qualifyForRentOverride"
                component={renderRadioGroupField}
                options={yesNoBooleanOptions}
                className="form-options"
                validate={[required]}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="prevMissingCert.TTPbeforeOverride"
                component={renderCurrencyField}
                bsSize="lg"
                label="TTP before Override"
                disabled={disableTTPbeforeOverride}
                validate={disableTTPbeforeOverride ? [] : [required, positive]}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const mapStateToProps = ({
  app: { currentUser },
  form,
}: Object): Object => {
  const selector = formValueSelector(EDIT_GR_CERT_FORM_NAME);

  return {
    anticipatedVoucherDate: selector(
      { form },
      'grossRentEdits.anticipatedVoucherDate',
    ),
    anticipatedVoucherOverride: selector(
      { form },
      'grossRentEdits.anticipatedVoucherOverride',
    ),
    qualifyForRentOverride: selector(
      { form },
      'prevMissingCert.qualifyForRentOverride',
    ),
    userPermissions: pathOr([], ['permissions'], currentUser),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: EDIT_GR_CERT_FORM_NAME,
  })(injectIntl(EditGRCertForm)),
);
