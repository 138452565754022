import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function withReduxStore(Comp) {
  class ReduxStoreWrapper extends React.Component {
    render() {
      return <Comp {...this.props} store={this.context.store} />;
    }
  }

  ReduxStoreWrapper.contextTypes = {
    store: PropTypes.any,
  };

  return connect()(ReduxStoreWrapper);
}

export default withReduxStore;
