import { Col, Row } from 'react-bootstrap';
import messages from '../messages';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import {
  renderCheckboxField,
  renderDateField,
  renderSelectField,
  renderTimeField,
} from '../../../utils/redux-form-helper';
import React, { useEffect, useRef, useState } from 'react';
import AssigneeCalendar from '../../AssigneeCalendar';
import { contains, find, propEq } from 'ramda';
import type {
  ActivityType,
  DropdownOption,
} from '../../../containers/App/types';
import asyncValidate from '../asyncValidate';
import validate from '../validate';
import warn from '../warn';
import { bindActionCreators } from 'redux';
import actions from 'redux-form/lib/actions';
import { connect } from 'react-redux';
import FormTemplate from '../FormTemplate';
import { FORM_NAME } from '../constants';

type Props = {
  intl: Object,
  store: any,
  locale: string,
  activityTypesList: Array<DropdownOption & ActivityType>,
  allDay: boolean,
  assigneeList: Array<DropdownOption>,
  assignedToId: string,
  prospect: Object,
  error: Object,
  warning: Object,
  startDate: string,
  asyncValidate: Function,
  mode: string,
  handleSubmit: Function,
};

const ScheduleForm = (props: Props) => {
  const {
    intl,
    store,
    locale,
    activityTypesList,
    allDay,
    assigneeList,
    assignedToId,
    error,
    warning,
    startDate,
    asyncValidate,
    handleSubmit,
    onSubmit,
  } = props;

  const [calendarView, setCalendarView] = useState(false);
  const [runAsyncValidation, setRunAsyncValidation] = useState(true);
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current && runAsyncValidation) {
      setRunAsyncValidation(false);
      asyncValidate('startDate', startDate, 'blur');
    }
    didMountRef.current = true;
  }, [runAsyncValidation, startDate, calendarView, asyncValidate]);

  const assignee = find(propEq('value', assignedToId))(assigneeList) || {
    text: '---',
  };

  const onHandleSubmit = (formValues) => {
    if (onSubmit) onSubmit(formValues);
  };

  if (calendarView)
    return (
      <Row>
        <Col xs={12} sm={6}>
          <a onClick={() => setCalendarView(false)}>
            <i className="et-chevron-left" />
            Back to follow-up details
          </a>
          <h2 className="calendar__title">
            <span>{`${intl.formatMessage(messages.calendarFor)}: ${
              assignee.text
            }`}</span>
          </h2>
        </Col>
        <Col xs={12}>
          <div className="box-calendar">
            <AssigneeCalendar
              defaultView={'week'}
              intl={intl}
              locale={locale}
              selectedAssignee={assignedToId}
              store={store}
            />
          </div>
        </Col>
      </Row>
    );

  const scheduleForm = (
    <form encType="multipart/form-data" onSubmit={handleSubmit(onHandleSubmit)}>
      <Row>
        <Col md={12}>
          <Field
            label={intl.formatMessage(messages.activityTypeLabel)}
            store={store}
            name="activityTypeId"
            component={renderSelectField}
            options={activityTypesList}
            bsSize="lg"
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Field
            label={intl.formatMessage(messages.activityDateLabel)}
            store={store}
            name="startDate"
            component={renderDateField}
            classPicker="modal-fixed-position"
            bsSize="lg"
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field
            label={intl.formatMessage(messages.scheduleStartTimeLabel)}
            store={store}
            name="startTime"
            component={renderTimeField}
            bsSize="lg"
            disabled={allDay}
          />
        </Col>
        <Col md={6}>
          <Field
            label={intl.formatMessage(messages.scheduleEndTimeLabel)}
            store={store}
            name="endTime"
            component={renderTimeField}
            bsSize="lg"
            disabled={allDay}
          />
        </Col>
        <Col xs={6}>
          <Field
            store={store}
            name="allDay"
            component={renderCheckboxField}
            bsSize="lg"
            label={intl.formatMessage(messages.noTimeLabel)}
          />
        </Col>
        <Col xs={6}>
          <a className="link-availability pull-right">
            <i className="et-calendar"> </i>
            <span className="small" onClick={() => setCalendarView(true)}>
              Check Availability
            </span>
          </a>
        </Col>
      </Row>
      {assigneeList.length > 0 ? (
        <Row>
          <Col xs={12}>
            <Field
              label={intl.formatMessage(messages.scheduleByLabel)}
              store={store}
              name="assignedToId"
              component={renderSelectField}
              options={assigneeList}
              bsSize="lg"
            />
          </Col>
        </Row>
      ) : (
        <span className="has-error">
          {intl.formatMessage(messages.emptyAssignessListError)}
        </span>
      )}
      <Row>
        <Col md={12}>
          <sub>{intl.formatMessage(messages.footerNote)}</sub>
        </Col>
      </Row>
    </form>
  );

  return (
    <FormTemplate
      formFields={scheduleForm}
      intl={intl}
      store={store}
      error={error}
      warning={warning}
    />
  );
};

const selector = formValueSelector(FORM_NAME.SCHEDULE);

const mapStateToProps = (state) => {
  if (state.app.selectedProperty) {
    return {
      locale: state.languageProvider.locale,
      startDate: selector(state, 'startDate'),
      startTime: selector(state, 'startTime'),
      endTime: selector(state, 'endTime'),
      notes: selector(state, 'notes'),
      activities: state.home.activities,
      activityTypeId: selector(state, 'activityTypeId'),
      activityCompletionStatusId: selector(state, 'activityCompletionStatusId'),
      assignedToId: selector(state, 'assignedToId'),
      allDay: selector(state, 'allDay'),
      selectedProperty: state.app.selectedProperty,
      organizationId: state?.app?.currentUser?.user?.organizationId ?? '',
    };
  }
  throw new Error('A property must be selected.');
};

const mapDispatchToProps = (dispatch: any): Object => {
  const bindedActions = bindActionCreators(
    { updateSyncWarnings: actions.updateSyncWarnings },
    dispatch,
  );
  return {
    actions: bindedActions,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: FORM_NAME.SCHEDULE,
    touchOnChange: true,
    validate: validate,
    warn: warn,
    asyncValidate: asyncValidate,
    shouldAsyncValidate: (params) => {
      if (!params.syncValidationPasses) {
        return false;
      }
      if (params.trigger === 'change') {
        return false;
      }
      return contains(params.blurredField, [
        'startDate',
        'startTime',
        'endTime',
        'activityTypeId',
        'assignedToId',
      ]);
    },
  })(ScheduleForm),
);
