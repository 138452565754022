import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

type Props = {
  frNames: Array<string>,
};

export const ApplicantHeader = ({ frNames }: Props) => {
  return (
    <Row>
      <Col xs={12}>
        <h2>
          <FormattedMessage
            {...messages.areYouSureText}
            values={{
              primaryApplicantName: <strong>{frNames.join(', ')}</strong>,
            }}
          />
        </h2>
        <p>
          <FormattedMessage {...messages.requiredFieldsText} />
        </p>
      </Col>
    </Row>
  );
};

export default ApplicantHeader;
