import { defineMessages } from 'react-intl';

const messages = defineMessages({
  headerAdd: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.HeaderAdd',
    defaultMessage: 'Add Emergency Contact',
  },
  headerEdit: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.HeaderEdit',
    defaultMessage: 'Edit Emergency Contact',
  },
  requiredField: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.RequiredField',
    defaultMessage: 'Required Field',
  },
  confirmDelete: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.ConfirmDelete',
    defaultMessage: 'Are you sure you want to delete this Emergency Contact?',
  },
  no: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.No',
    defaultMessage: 'No',
  },
  yes: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.Yes',
    defaultMessage: 'Yes',
  },
  cancel: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.Save',
    defaultMessage: 'Save',
  },
  contactNameField: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.ContactNameField',
    defaultMessage: 'Contact Name *',
  },
  phoneNumberField: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.PhoneNumberField',
    defaultMessage: 'Phone Number *',
  },
  extensionNumber: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.extensionNumber',
    defaultMessage: 'Ext.',
  },
  secondaryPhoneNumberField: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.secondaryPhoneNumberField',
    defaultMessage: 'Secondary Phone Number',
  },
  secondaryExtensionNumber: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.secondaryExtensionNumber',
    defaultMessage: 'Secondary Ext.',
  },
  email: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.email',
    defaultMessage: 'Email',
  },
  contactAddressField: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.ContactAddressField',
    defaultMessage: 'Contact Address',
  },
  contactAddress2Field: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.ContactAddress2Field',
    defaultMessage: 'Contact Address 2',
  },
  cityField: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.CityField',
    defaultMessage: 'City',
  },
  stateField: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.StateField',
    defaultMessage: 'State / Province',
  },
  zipCodeField: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.ZipCodeField',
    defaultMessage: 'Zip Code / Postal Code',
  },
  relationshipField: {
    id: 'App.Household.EmergencyContactsSection.AddEditEmergencyContactModal.RelationshipField',
    defaultMessage: 'Relationship *',
  },
});

export default messages;
