import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.FinalAccountStatement.ConfirmationModal.Title',
    defaultMessage: 'Final Account Statement',
  },
  body: {
    id: 'App.FinalAccountStatement.ConfirmationModal.Body',
    defaultMessage: `Are you sure all charges are correct and that you want to Generate the Final Account Statement and write off all remaining balances?`, // eslint-disable-line
  },
  bodyonce: {
    id: 'App.FinalAccountStatement.ConfirmationModal.BodyOnce',
    defaultMessage: 'Final Account Statement can only be generated once.',
  },
  emailConfirmation: {
    id: 'App.FinalAccountStatement.ConfirmationModal.EmailConfirmation',
    defaultMessage: 'Email a copy of FAS to all leaseholders once generated',
  },
  cancel: {
    id: 'App.FinalAccountStatement.ConfirmationModal.Cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'App.FinalAccountStatement.ConfirmationModal.Confirm',
    defaultMessage: 'Confirm',
  },
});

export default messages;
