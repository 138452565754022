export const CREATE_MISC_TRANSACTION = 'app/CREATE_MISC_TRANSACTION';
export const CREATE_MISC_TRANSACTION_SUCCESS =
  'app/CREATE_MISC_TRANSACTION_SUCCESS';
export const CREATE_MISC_TRANSACTION_FAILURE =
  'app/CREATE_MISC_TRANSACTION_FAILURE';

export const HANDLE_SOCKET_CREATE_MISC_TRANSACTION_ERROR =
  'app/MiscTransactions/HANDLE_SOCKET_CREATE_MISC_TRANSACTION_ERROR';
export const HANDLE_SOCKET_CREATE_MISC_TRANSACTION_SUCCESS =
  'app/MiscTransactions/HANDLE_SOCKET_CREATE_MISC_TRANSACTION_SUCCESS';

export const FORM_NAME = 'createMiscTransaction';
export const DOCUMENT_NUMBER_MAX_LENGTH = 25;
export const NOTE_MAX_LENGTH = 200;
