export const GET_ALL_ACTIVITIES_BY_ASSIGNEE =
  'app/Home/GET_ALL_ACTIVITIES_BY_ASSIGNEE';
export const GET_ALL_ACTIVITIES_BY_ASSIGNEE_SUCCESS =
  'app/Home/GET_ALL_ACTIVITIES_BY_ASSIGNEE_SUCCESS';
export const GET_ALL_ACTIVITIES_BY_ASSIGNEE_ERROR =
  'app/Home/GET_ALL_ACTIVITIES_BY_ASSIGNEE_ERROR';
export const CLEAR_ACTIVITIES_BY_ASSIGNEE =
  'app/Home/CLEAR_ACTIVITIES_BY_ASSIGNEE';
export const SET_SELECTED_ASSIGNEE = 'app/Home/SET_SELECTED_ASSIGNEE';
export const SET_ACTIVE_KEY = 'app/Home/SET_ACTIVE_KEY';
export const CLEAR_TASK_INFORMATION = 'app/Home/CLEAR_TASK_INFORMATION';
export const GET_TASK_INFORMATION = 'app/Home/GET_TASK_INFORMATION';
export const GET_TASK_INFORMATION_SUCCESS =
  'app/Home/GET_TASK_INFORMATION_SUCCESS';
export const GET_TASK_INFORMATION_ERROR = 'app/Home/GET_TASK_INFORMATION_ERROR';
