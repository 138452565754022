import * as R from 'ramda';

import type { RequiredIncomeAveragingFormValues } from './types';

function validate(values: RequiredIncomeAveragingFormValues) {
  const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty]);
  if (isNilOrEmpty(values.incomeAveragingPercent)) {
    return {
      incomeAveragingPercent: 'Required field',
    };
  }

  const incomeAveragingPercent = +values.incomeAveragingPercent;
  if (incomeAveragingPercent < 0 || incomeAveragingPercent > 100) {
    return {
      incomeAveragingPercent: 'Must be between 0 and 100',
    };
  }

  return {};
}

export default validate;
