import { useMemo } from 'react';
import { useQuery } from 'react-query';

import PropertyService from '../../services/propertyService';

export const useActiveProperties = (
  organizationId: string,
  reactQueryOptions?: Object,
): Array => {
  const propertyService = useMemo(() => new PropertyService(), []);

  const defaultReactQueryOptions = {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    initialData: [],
  };

  const { data, isLoading } = useQuery(
    ['active-properties', organizationId],
    async (): Promise<Array> =>
      propertyService.getActiveProperties(organizationId),
    { ...defaultReactQueryOptions, ...reactQueryOptions },
  );

  return [data, isLoading];
};
