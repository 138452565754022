import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import classNames from 'classnames';
import { renderRadioGroupField } from '../../../utils/redux-form-helper';

import {
  genderOptions,
  veteranOptions,
} from '../../LeaseAffordableApplicantFormCommonSections/configuration';

import messages from './messages';

type Props = {
  intl: Object,
  required?: boolean,
};

const GenderSection = ({ intl, required }: Props) => (
  <div className="form-content">
    <Row>
      <Col xs={12} md={6}>
        <label>{`${intl.formatMessage(messages.gender)}*`}</label>
        <Field
          name="gender"
          fieldName="gender"
          options={genderOptions}
          component={renderRadioGroupField}
          classes={classNames({ required })}
        />
      </Col>
      <Col xs={12} md={6}>
        <label>{`${intl.formatMessage(messages.veteran)}*`}</label>
        <Field
          name="veteran"
          fieldName="veteran"
          options={veteranOptions}
          component={renderRadioGroupField}
          classes={classNames({ required })}
        />
      </Col>
    </Row>
  </div>
);

export default GenderSection;
