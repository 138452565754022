import { Field, FormSection } from 'redux-form';
import {
  renderCurrencyField,
  renderTextField,
  renderSelectField,
  renderSearchDropDown,
} from '../../../../utils/redux-form-helper';
import { getTrancationCodeId, getHouseholdOption } from '../../utils';
import { STATUS_OPTION } from '../../constants';
import DashesIfNullOrUndefined from '../../../../components/DashesIfNullOrUndefined';
import { v4 as uuid } from 'uuid';

const InlineSubsidyBatchRow = ({
  index,
  intl,
  setInlineRowsArray,
  inlineRowsArray,
  mode,
  id,
  householdList,
  saveSubsidyPaymentBatchDetail,
  batchHeader,
  households,
  transactionCodes,
}) => {
  const initialSave = (payload) => {
    saveSubsidyPaymentBatchDetail(
      { ...payload, transactionDate: batchHeader?.batchDate },
      true,
    );
    setInlineRowsArray([
      {
        mode: 'default',
        id: uuid(),
        householdOption: [],
      },
    ]);
  };
  const addNewRow = ({ target: { value } }) => {
    const inlineRows = [...inlineRowsArray];
    inlineRows[index].status = value;
    inlineRows[index].householdOption = getHouseholdOption(
      value,
      householdList,
    );
    setInlineRowsArray([...inlineRows]);
    if (mode === 'default') {
      setInlineRowsArray([
        ...inlineRowsArray.map((row) => ({ ...row, mode: '' })),
        {
          mode: 'default',
          id: uuid(),
          householdOption: [],
        },
      ]);
    }
  };
  const onSelectHousehold = ({ value: { householdId } }) => {
    const selectedHouseholdDetails = households.filter(
      (row) => row?.household?.id === householdId,
    )[0];
    const receivedFrom =
      (selectedHouseholdDetails?.household?.mainCus?.firstName ?? '') +
      ' ' +
      (selectedHouseholdDetails?.household?.mainCus?.lastName ?? '');

    const ptc = getTrancationCodeId(0, transactionCodes);
    const householdStatus =
      selectedHouseholdDetails?.household?.mainCus?.status ?? '';

    const unitId =
      householdStatus === 'Applicant'
        ? selectedHouseholdDetails?.auApplicant?.app?.au?.unitId ?? null
        : selectedHouseholdDetails?.household?.mainCus?.rc?.resident?.lease
            ?.unitId ?? null;

    const payload = {
      amount: 0,
      transactionCode: ptc?.id ?? '',
      transactionNote: '',
      batchId: batchHeader?.id ?? '',
      documentNumber: batchHeader?.documentNumber ?? '',
      receivedFrom,
      householdId,
      householdStatus,
      unitId,
      isManual: true,
    };
    initialSave(payload);
  };

  const deleteRow = () => {
    inlineRowsArray.splice(index, 1);
    setInlineRowsArray([...inlineRowsArray]);
  };
  const selectedStatus = inlineRowsArray?.index?.status ?? '';
  const isMisc = selectedStatus === 'miscellaneous';
  return (
    <FormSection name={`inline${id}`}>
      <tr>
        <td style={{ width: '20px', padding: '0px', paddingLeft: '10px' }}></td>
        <td>
          <Field
            name="status"
            component={renderSelectField}
            options={STATUS_OPTION}
            onChange={addNewRow}
          />
        </td>
        <td style={{ paddingRight: '0px', paddingLeft: '5px' }}>
          <Field
            classNames="search-actions searchbox-default searchbox__hasautocomplete"
            component={renderSearchDropDown}
            name="household"
            placeholder="Select Unit/Resident"
            onChangeCallback={onSelectHousehold}
            options={inlineRowsArray[index].householdOption}
            disabled={isMisc}
            fieldValue={''}
          />
        </td>
        <td>
          <DashesIfNullOrUndefined
            data={intl.formatNumber('0', {
              style: 'currency',
              currency: 'USD',
            })}
          />
        </td>
        <td>
          <Field
            name="depositAmount"
            component={renderCurrencyField}
            messageAsTooltip
            min="-10000"
            max="10000"
            style={{ zIndex: '0' }}
            placeholder="0.00"
          />
        </td>
        <td>
          <DashesIfNullOrUndefined
            data={intl.formatNumber('0', {
              style: 'currency',
              currency: 'USD',
            })}
          />
        </td>
        <td>---</td>
        <td>
          <Field
            name="note"
            component={renderTextField}
            maxLength="35"
            placeholder="Note"
          />
        </td>
        <td>
          <button
            className="btn btn-sm btn-default"
            onClick={deleteRow}
            type="button"
            disabled={true}
          >
            <i className="et-trash" />
          </button>
        </td>
      </tr>
    </FormSection>
  );
};

export default InlineSubsidyBatchRow;
