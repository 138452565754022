import React from 'react';
import { Field } from 'redux-form';
import type { DropdownOption } from '../../../App/types';
import { renderSelectField } from '../../../../utils/redux-form-helper';

type Props = {
  assignees: Array<DropdownOption>
};

const AssignTo = ({ assignees }: Props) => (
  <Field name="assignedToId" component={renderSelectField} options={assignees} bsSize="lg" />
);

export default AssignTo;
