import React from 'react';
import {
  TableCell,
  TableRow,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { MoneyAltIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { HouseholdSection } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';

import { useCashPayDetails } from './hooks';

import messages from './messages';

const CashPayDetailsSection = ({ intl, householdId, propertyId }) => {
  const { billerName, receiveCode, userFriendlyId, shouldAcceptCashPay } =
    useCashPayDetails({
      householdId,
      propertyId,
    });
  return shouldAcceptCashPay ? (
    <HouseholdSection
      Icon={MoneyAltIcon}
      title={intl.formatMessage(messages.title)}
      headers={[
        messages.billerName,
        messages.receiveCode,
        messages.accountNumber,
      ]}
      rows={[
        <TableRow sx={{ backgroundColor: 'transparent !important' }}>
          <TableCell>{billerName}</TableCell>
          <TableCell>{receiveCode}</TableCell>
          <TableCell>{userFriendlyId}</TableCell>
        </TableRow>,
      ]}
    />
  ) : null;
};

export default CashPayDetailsSection;
