import { QueryClient } from 'react-query';

const QueryClientSingleton = (function () {
  let instance;

  function getInstance() {
    if (!instance) {
      instance = new QueryClient();
    }

    return instance;
  }

  return { getInstance };
})();

export default QueryClientSingleton.getInstance();
