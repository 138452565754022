import { defineMessages } from 'react-intl';

const messages = defineMessages({
  successHeader: {
    id: 'App.EditUser.SuccessHeader',
    defaultMessage: 'Success',
  },
  successDescription: {
    id: 'App.EditUser.SuccessDescription',
    defaultMessage: 'The user was updated.',
  },
  errorHeader: {
    id: 'App.EditUser.ErrorHeader',
    defaultMessage: 'Error',
  },
  title: {
    id: 'App.EditUser.Title',
    defaultMessage: 'Fortress - Edit user',
  },
  header: {
    id: 'App.EditUser.Header',
    defaultMessage: 'Edit user',
  },
  subheader: {
    id: 'App.EditUser.Subheader',
    defaultMessage: 'All fields are required',
  },
  firstName: {
    id: 'App.EditUser.FirstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'App.EditUser.LastName',
    defaultMessage: 'Last name',
  },
  emailAddress: {
    id: 'App.EditUser.EmailAddress',
    defaultMessage: 'Email address',
  },
  username: {
    id: 'App.EditUser.Username',
    defaultMessage: 'Pick a username',
  },
  assignToProperty: {
    id: 'App.EditUser.AssignToProperty',
    defaultMessage: 'Assign to property',
  },
  assignAllProperties: {
    id: 'App.EditUser.AssignAllProperties',
    defaultMessage: 'Or assign all properties to this user',
  },
  chooseOption: {
    id: 'App.EditUser.ChooseAnOption',
    defaultMessage: 'Choose an option',
  },
  userStatus: {
    id: 'App.EditUser.UserStatus',
    defaultMessage: 'User status',
  },
  userTitle: {
    id: 'App.EditUser.UserTitle',
    defaultMessage: 'User title',
  },
  userRole: {
    id: 'App.EditUser.UserRole',
    defaultMessage: 'Assign a role',
  },
  cancelConfirmation: {
    id: 'App.EditUser.CancelConfirmation',
    defaultMessage: 'Are you sure you want to leave?',
  },
});

export default messages;
