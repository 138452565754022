import { useEffect, useMemo } from 'react';
import { usePrevious } from '@fortress-technology-solutions/fortress-component-library/hooks';
import useReactHookFormProps from '../../../../../hooks/useReactHookFormProps';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils';
import moment from 'moment/moment';
import { FIELDS } from './constants';
import isEqual from 'lodash/isEqual';

const getDefaultValues = (values) => {
  return {
    HOMEAffordabilityPeriodStartDate: values?.HOMEAffordabilityPeriodStartDate
      ? moment(values.HOMEAffordabilityPeriodStartDate)
      : '',
    HOMEUnitType: values?.HOMEUnitType || '',
    HOMEAnnualIncomeType: values?.HOMEAnnualIncomeType || '',
    HOMEPropertyType: values?.HOMEPropertyType || '',
  };
};
export const useHOME = ({ editMode, values, onChange }) => {
  const prevValues = usePrevious(values);
  const hasValuesChanged = !isEqual(prevValues, values);
  const ReactHookFormProps = useReactHookFormProps({
    defaultValues: getDefaultValues(values),
  });

  const memoizedFields = useMemo(() => {
    const fieldsCopy = [...FIELDS];

    return fieldsCopy.map((field) => ({
      ...field,
      readOnly: !editMode,
      placeholder: editMode ? undefined : field.placeholder,
    }));
  }, [editMode]);

  const { reset, watch } = ReactHookFormProps;

  useEffect(() => {
    if (!hasValuesChanged) return;
    reset(getDefaultValues(values));
  }, [hasValuesChanged, reset, values]);

  const HOMEAffordabilityPeriodStartDate = watch(
    'HOMEAffordabilityPeriodStartDate',
  );
  useEffect(() => {
    onChange({
      target: {
        name: 'setup.HOMEAffordabilityPeriodStartDate',
        value: formatDateDB(HOMEAffordabilityPeriodStartDate),
      },
    });
  }, [HOMEAffordabilityPeriodStartDate, onChange]);

  const HOMEUnitType = watch('HOMEUnitType');
  useEffect(() => {
    onChange({
      target: {
        name: 'setup.HOMEUnitType',
        value: HOMEUnitType,
      },
    });
  }, [HOMEUnitType, onChange]);

  const HOMEAnnualIncomeType = watch('HOMEAnnualIncomeType');
  useEffect(() => {
    onChange({
      target: {
        name: 'setup.HOMEAnnualIncomeType',
        value: HOMEAnnualIncomeType,
      },
    });
  }, [HOMEAnnualIncomeType, onChange]);

  const HOMEPropertyType = watch('HOMEPropertyType');
  useEffect(() => {
    onChange({
      target: {
        name: 'setup.HOMEPropertyType',
        value: HOMEPropertyType,
      },
    });
  }, [HOMEPropertyType, onChange]);

  return {
    ReactHookFormProps,
    memoizedFields,
  };
};
