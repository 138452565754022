import type { Action } from '../App/types';
import * as ActionTypes from './constants';
import type { ManageCommunicationsState } from './types';

const initialState = {
  isLoading: false,
  communicationBatchGenerated: false,
  residents: [],
  prospects: [],
  applicants: [],
  selectedResidents: [],
  documentTypes: [],
  response: {},
  validEmails: [],
  validPhones: [],
  emailsValidated: false,
  fromEmailValidation: false,
  phonesValidated: false,
  isInitialState: true,
};

const manageCommunicationsReducer = (
  state: ManageCommunicationsState = initialState,
  action: Action<*, *>,
) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ALL_RESIDENTS: {
      return { ...state, isLoading: true };
    }

    case ActionTypes.GET_ALL_RESIDENTS_SUCCESS: {
      return {
        ...state,
        prospects: [],
        applicants: [],
        residents: payload,
        isLoading: false,
        isInitialState: false,
      };
    }

    case ActionTypes.GET_ALL_PROSPECTS: {
      return { ...state, isLoading: true };
    }

    case ActionTypes.GET_ALL_PROSPECTS_SUCCESS: {
      return {
        ...state,
        residents: [],
        applicants: [],
        prospects: payload,
        isLoading: false,
        isInitialState: false,
      };
    }

    case ActionTypes.GET_ALL_APPLICANTS: {
      return { ...state, isLoading: true };
    }

    case ActionTypes.GET_ALL_APPLICANTS_SUCCESS: {
      return {
        ...state,
        residents: [],
        prospects: [],
        applicants: payload,
        isLoading: false,
        isInitialState: false,
      };
    }

    case ActionTypes.SET_SELECTED_RESIDENTS: {
      return { ...state, selectedResidents: payload };
    }

    case ActionTypes.GET_DOCUMENT_TYPES_SUCCESS: {
      return { ...state, documentTypes: payload };
    }

    case ActionTypes.GENERATE_COMMUNICATION_BATCH: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ActionTypes.GENERATE_COMMUNICATION_BATCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        communicationBatchGenerated: true,
        response: payload,
      };
    }

    case ActionTypes.GENERATE_COMMUNICATION_BATCH_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case ActionTypes.RESET_COMMUNICATIONS_GENERATED: {
      return {
        ...state,
        isLoading: false,
        communicationBatchGenerated: false,
        validEmails: [],
        validPhones: [],
        emailsValidated: false,
        phonesValidated: false,
        response: {},
        fromEmailValidation: false,
      };
    }

    case ActionTypes.RESET_LISTS: {
      return { ...state, residents: [], prospects: [], isInitialState: true };
    }

    case ActionTypes.DOWNLOAD_COMMUNICATION: {
      return { ...state, isLoading: true };
    }

    case ActionTypes.DOWNLOAD_COMMUNICATION_SUCCESS: {
      return { ...state, isLoading: false, letterBatchGenerated: true };
    }

    case ActionTypes.DOWNLOAD_COMMUNICATION_FAIL: {
      return { ...state, isLoading: false };
    }

    case ActionTypes.GET_VALIDATE_RECPIENTS_SUCCESS: {
      const newState = {};
      switch (payload.communicationType) {
        case 'LETTER':
        case 'EMAIL':
          newState.validEmails = payload.response;
          newState.emailsValidated = true;
          break;
        case 'SMS':
          newState.validPhones = payload.response;
          newState.phonesValidated = true;
          break;
        default:
          break;
      }
      return {
        ...state,
        ...newState,
      };
    }

    case ActionTypes.GET_VALIDATE_EMAIL: {
      return {
        ...state,
        fromEmailValidation: false,
      };
    }

    case ActionTypes.GET_VALIDATE_EMAIL_SUCCESS: {
      return {
        ...state,
        fromEmailValidation: payload,
      };
    }

    default:
      return state;
  }
};

export default manageCommunicationsReducer;
