type Integration = {
  category: string,
  id: string,
  name: string,
  slug: string,
};

type IntegrationWrapper = {
  id: string,
  integration: Integration,
  integrationId: string,
  isActive: boolean,
  isActiveForAllProperties?: boolean,
  organizationId?: string,
  propertyId?: string,
};

/**
  returns the work number integration when passed a list of integrations
  returns null if the integration object doesnt exist
 */
export function getWorkNumberIntegrationFromList(
  integrations: Array<IntegrationWrapper>,
): IntegrationWrapper | null {
  const workNumberName = 'The Work Number - Affordable Units';
  const workNumberIntegration = integrations
    ? integrations.find(
        (integration) => integration.integration.name === workNumberName,
      )
    : null;
  return workNumberIntegration ? workNumberIntegration : null;
}

/**
  returns the any integration when passed a list of integrations
  returns null if the integration object doesnt exist
 */
export function getIntegrationFromList(
  integrations: Array<IntegrationWrapper>,
  integrationName: string,
): IntegrationWrapper | null {
  const serchedIntegration = integrations
    ? integrations.find(
        (integration) => integration.integration.name === integrationName,
      )
    : null;
  return serchedIntegration ? serchedIntegration : null;
}
