import React from 'react';
import validate from './validate';
import { reduxForm } from 'redux-form';
import messages from './messages';
import { Field } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import {
  renderTextField,
  renderCurrencyField,
} from '../../../../utils/redux-form-helper';

function concessionForm({ intl, isEditable, lease }: Object) {
  return (
    <form>
      <Col xs={12} sm={7} md={6} lg={7}>
        <Row>
          <Col xs={6} md={5} lg={6}>
            <label>
              {intl.formatMessage(messages.oneTimeRentConcessionLabel)}
            </label>
          </Col>
          <Col xs={6} md={7} lg={6}>
            <Row>
              <Col xs={4} md={12} lg={4}>
                <b>{intl.formatMessage(messages.amountLabel)}: </b>
              </Col>
              <Col xs={8} md={12} lg={8}>
                <Field
                  name="oneTimeRentConcession"
                  component={renderCurrencyField}
                  step="0.01"
                  disabled={!isEditable}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={5} lg={6}>
            <label>
              {intl.formatMessage(messages.monthlyRentConcessionLabel)}
            </label>
          </Col>
          <Col xs={6} md={7} lg={6}>
            <Row>
              <Col xs={4} md={12} lg={4}>
                <b>{intl.formatMessage(messages.amountLabel)}: </b>
              </Col>
              <Col xs={8} md={12} lg={8}>
                <Field
                  name="monthlyRentConcession"
                  component={renderCurrencyField}
                  step="0.01"
                  disabled={!isEditable}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={5} lg={6}>
            <label>
              {intl.formatMessage(messages.otherDiscountConcessionLabel)}
            </label>
          </Col>
          <Col xs={6} md={7} lg={6}>
            <Row>
              <Col xs={4} md={12} lg={4}>
                <b>{intl.formatMessage(messages.amountLabel)}:</b>
              </Col>
              <Col xs={8} md={12} lg={8}>
                <Field
                  name="otherDiscountConcession"
                  component={renderCurrencyField}
                  step="0.01"
                  disabled={!isEditable}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={5} md={6} lg={5}>
        <Row>
          <Col xs={4}>
            <label>{intl.formatMessage(messages.monthsLabel)} </label>
          </Col>
          <Col xs={8}>
            <div className="form-group">
              <Field
                className="input-md form-control"
                name="oneTimeRentMonth"
                component={renderTextField}
                maxLength="25"
                disabled={!isEditable}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <label>{intl.formatMessage(messages.monthsLabel)}</label>
          </Col>
          <Col xs={8}>
            <div className="form-group">
              <Field
                className="input-md form-control"
                name="monthlyRentMonth"
                component={renderTextField}
                maxLength="25"
                disabled={!isEditable}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <label>{intl.formatMessage(messages.descriptionLabel)}</label>
          </Col>
          <Col xs={8}>
            <div className="form-group">
              <Field
                className="input-md form-control"
                name="discountDescription"
                component={renderTextField}
                maxLength="25"
                disabled={!isEditable}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Row>
        <div className="col-xs-12 padtop10">
          <Col xs={12}>
            <strong>{intl.formatMessage(messages.note)}</strong>{' '}
            {intl.formatMessage(messages.noteDescription)}
          </Col>
        </div>
      </Row>
      <div className="divider" />
    </form>
  );
}

export default reduxForm({
  form: 'Concessions',
  validate,
  enableReinitialize: true,
})(concessionForm);
