import type {
  Action,
  User,
  UserStatus,
  UserTitle,
  UserRole,
  Property,
} from '../App/types';
import * as ActionTypes from './constants';

export function getAllUserStatuses(): * {
  return {
    type: ActionTypes.GET_ALL_USER_STATUSES,
  };
}

export function getAllUserStatusesSuccess(
  userStatuses: Array<UserStatus>,
): Action<Array<UserStatus>> {
  return {
    type: ActionTypes.GET_ALL_USER_STATUSES_SUCCESS,
    payload: userStatuses,
  };
}

export function getAllUserStatusesFail(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_USER_STATUSES_FAIL,
    payload: error,
  };
}

export function getAllUserTitles(): * {
  return {
    type: ActionTypes.GET_ALL_USER_TITLES,
  };
}

export function getAllUserTitlesSuccess(
  userTitles: Array<UserTitle>,
): Action<Array<UserTitle>> {
  return {
    type: ActionTypes.GET_ALL_USER_TITLES_SUCCESS,
    payload: userTitles,
  };
}

export function getAllUserTitlesFail(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_USER_TITLES_FAIL,
    payload: error,
  };
}

export function getAllUserRoles(): * {
  return {
    type: ActionTypes.GET_ALL_USER_ROLES,
  };
}

export function getAllUserRolesSuccess(
  userRoles: Array<UserRole>,
): Action<Array<UserRole>> {
  return {
    type: ActionTypes.GET_ALL_USER_ROLES_SUCCESS,
    payload: userRoles,
  };
}

export function getAllUserRolesFail(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_USER_ROLES_FAIL,
    payload: error,
  };
}

export function getAllProperties(): * {
  return {
    type: ActionTypes.GET_ALL_PROPERTIES,
  };
}

export function getAllPropertiesSuccess(
  properties: Array<Property>,
): Action<Array<Property>> {
  return {
    type: ActionTypes.GET_ALL_PROPERTIES_SUCCESS,
    payload: properties,
  };
}

export function getAllPropertiesFail(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_PROPERTIES_FAIL,
    payload: error,
  };
}

export function showNonePropertiesSelectedError(
  nonePropertiesSelected: boolean,
): * {
  return {
    type: ActionTypes.NONE_PROPERTIES_SELECTED,
    payload: nonePropertiesSelected,
  };
}

export function createUser(user: User): Action<User> {
  return {
    type: ActionTypes.CREATE_USER,
    payload: user,
  };
}

export function createUserSuccess(): any {
  return {
    type: ActionTypes.CREATE_USER_SUCCESS,
  };
}

export function createUserFail(error: Error): Action<Error> {
  return {
    type: ActionTypes.CREATE_USER_FAIL,
    payload: error,
  };
}

export function toggleMultiselect(value: boolean): * {
  return {
    type: ActionTypes.TOGGLE_MULTISELECT,
    payload: value,
  };
}

export function setSelectedProperties(properties: Array<string>): * {
  return {
    type: ActionTypes.SET_SELECTED_PROPERTIES,
    payload: properties,
  };
}

export function setSelectedPropertyGroups(propertyGroups: Array<string>): * {
  return {
    type: ActionTypes.SET_SELECTED_PROPERTY_GROUPS,
    payload: propertyGroups,
  };
}

export function setUserAllProperties(val: boolean): * {
  return {
    type: ActionTypes.SET_USER_ALL_PROPERTIES,
    payload: val,
  };
}
