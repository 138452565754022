const { omit } = require('ramda');
const { request, apiURL } = require('../utils/api');

class EmergencyContactService {
  getOptions(method: string, signal?: Object): Object {
    const options: Object = {
      method,
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    if (signal) {
      options.signal = signal;
    }
    return options;
  }

  getAll(
    organizationId: string,
    propertyId: string,
    householdId: string,
    signal?: Object,
  ) {
    const options = this.getOptions('GET', signal);
    return request(
      `${apiURL}/${organizationId}/${propertyId}/households/${householdId}/emergency-contacts`,
      options,
    );
  }

  add(
    organizationId: string,
    propertyId: string,
    householdId: string,
    emergencyContact: Object,
  ) {
    const options = this.getOptions('POST');
    options.body = JSON.stringify(emergencyContact);
    return request(
      `${apiURL}/${organizationId}/${propertyId}/households/${householdId}/emergency-contacts`,
      options,
    );
  }

  edit(
    organizationId: string,
    propertyId: string,
    householdId: string,
    emergencyContact: Object,
  ) {
    if (!emergencyContact || !emergencyContact.id) {
      throw new Error('Could not update this Emergency Contact');
    }

    const payload = omit(['id'], emergencyContact);

    const options = this.getOptions('PUT');
    options.body = JSON.stringify(payload);
    return request(
      `${apiURL}/${organizationId}/${propertyId}/households/${householdId}/emergency-contacts/${emergencyContact.id}`,
      options,
    );
  }

  setContactAsPrimary(
    organizationId: string,
    propertyId: string,
    householdId: string,
    emergencyContactId: string,
  ) {
    if (!emergencyContactId) {
      throw new Error(
        'Could not set this Emergency Contact as primary contact',
      );
    }

    const options = this.getOptions('PUT');
    return request(
      // eslint-disable-next-line max-len
      `${apiURL}/${organizationId}/${propertyId}/households/${householdId}/emergency-contacts/${emergencyContactId}/set-primary`,
      options,
    );
  }

  delete(
    organizationId: string,
    propertyId: string,
    householdId: string,
    emergencyContactId: string,
  ) {
    if (!emergencyContactId) {
      throw new Error('Could not delete this Emergency Contact');
    }

    const options = this.getOptions('DELETE');
    return request(
      `${apiURL}/${organizationId}/${propertyId}/households/${householdId}/emergency-contacts/${emergencyContactId}`,
      options,
    );
  }
}

export default EmergencyContactService;
