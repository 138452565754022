import ElementWithPermissions from '../../components/ElementWithPermissions';
import useBaseTableProps from '../../hooks/useBaseTableProps';
import TableLayout from '../../components/TableLayout';
import {
  Button,
  Stack,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  MergeFieldInventory,
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { AddIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import messages from './messages';
import useCentralizedManageDocumentsTable from './hooks';
import { Redirect } from 'react-router-dom';

function CentralizedManageDocuments({ history }) {
  const baseTableProps = useBaseTableProps();

  const {
    count,
    totalCount,
    allColumnsHidden,
    columnOptions,
    dateState,
    filterState,
    filterTypeState,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    headers,
    isLoading,
    isLdUserContextReady,
    isPageEnabled,
    mergeFieldTableName,
    mergeFields,
    mergeFieldsLoading,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
  } = useCentralizedManageDocumentsTable({ intl: baseTableProps.intl });

  if (!isLdUserContextReady) return null;
  if (isPageEnabled === false) return <Redirect to="/404" />;

  return (
    <TableLayout name={name} childrenElementsHeight={56 + 8}>
      {(tableHeight) => (
        <>
          <TableHeader
            title={baseTableProps.intl.formatMessage(messages.tableTitle)}
            count={count}
            totalCount={totalCount}
            actions={
              <Stack spacing={1} alignItems="flex-end">
                <ElementWithPermissions scope={['centralized-docs-edit']}>
                  <Button
                    variant="shout"
                    onClick={() => history?.push('/add-document')}
                    startIcon={<AddIcon />}
                  >
                    {baseTableProps.intl.formatMessage(messages.addNewDocument)}
                  </Button>
                </ElementWithPermissions>
                <MergeFieldInventory
                  intl={baseTableProps.intl}
                  mergeFields={mergeFields}
                  isLoading={mergeFieldsLoading}
                  mergeFieldTableName={mergeFieldTableName}
                />
              </Stack>
            }
          />
          <Table
            {...baseTableProps}
            allColumnsHidden={allColumnsHidden}
            columnOptions={columnOptions}
            count={count}
            dateState={dateState}
            filterState={filterState}
            filterTypeState={filterTypeState}
            handleColumnChange={handleColumnChange}
            handleDateSubmit={handleDateSubmit}
            handleFilterChange={handleFilterChange}
            handleFilterTypeChange={handleFilterTypeChange}
            handleSearchSubmit={handleSearchSubmit}
            handleSortChange={handleSortChange}
            headers={headers}
            height={tableHeight}
            isLoading={isLoading}
            name={name}
            order={order}
            orderBy={orderBy}
            rows={rows}
            searchState={searchState}
            selectedColumns={selectedColumns}
            totalCount={totalCount}
          />
        </>
      )}
    </TableLayout>
  );
}

export default CentralizedManageDocuments;
