import * as R from 'ramda';
import messages from './messages';
import type { LeaseBasicsFormValues, LeaseBasicsFormProps } from './types';
import {
  validateScheduledMoveInDate,
  validateLeaseEffectiveDate,
  validateCommencementDate,
  validateLeaseEndDate,
  validateRentStartDate,
  validateCommencementNotOverlap,
} from './common-validations';

// $FlowFixMe
const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty, R.equals('default')]);

const baseRequiredFields = [
  'leaseTypeId',
  'lateMethodId',
  'scheduledMoveInDate',
  'commencementDate',
  'leaseEndDate',
  'rentStartDate',
  'isRentDateEstimated',
];
const validateRenewalLease = (
  values: LeaseBasicsFormValues,
  {
    intl,
    isRetailTypeFieldShown,
    priorLease,
    noticeToVacate,
  }: LeaseBasicsFormProps,
) => {
  const requiredFields = isRetailTypeFieldShown
    ? [...baseRequiredFields, 'retailTypeId']
    : baseRequiredFields;

  const errors: Object = requiredFields.reduce(
    (errors, field) =>
      isNilOrEmpty(values[field])
        ? { ...errors, [field]: intl.formatMessage(messages.required) }
        : errors,
    {},
  );

  const context = {
    values,
    errors,
    intl,
    priorLease,
    isRenewal: true,
    noticeToVacate,
  };

  return [
    validateLeaseEffectiveDate(values.leaseEffectiveDate, context),
    validateCommencementDate(values.commencementDate, context),
    validateLeaseEndDate(values.leaseEndDate, context),
    validateRentStartDate(values.rentStartDate, context),
    validateCommencementNotOverlap(values.commencementDate, context),
  ].reduce(
    (errors, validationResult) => ({ ...errors, ...validationResult }),
    errors,
  );
};

const validateInitialLease = (
  values: LeaseBasicsFormValues,
  { intl, isRetailTypeFieldShown, ...props }: LeaseBasicsFormProps,
) => {
  const requiredFields = isRetailTypeFieldShown
    ? [...baseRequiredFields, 'retailTypeId']
    : baseRequiredFields;

  const errors: Object = requiredFields.reduce(
    (errors, field) =>
      isNilOrEmpty(values[field])
        ? { ...errors, [field]: intl.formatMessage(messages.required) }
        : errors,
    {},
  );

  const context = { values, errors, intl };

  return [
    validateScheduledMoveInDate(values.scheduledMoveInDate, context),
    validateLeaseEffectiveDate(values.leaseEffectiveDate, context),
    validateCommencementDate(values.commencementDate, context),
    validateLeaseEndDate(values.leaseEndDate, context),
    validateRentStartDate(values.rentStartDate, context),
  ].reduce(
    (errors, validationResult) => ({ ...errors, ...validationResult }),
    errors,
  );
};

function validate(
  values: LeaseBasicsFormValues,
  formProps: LeaseBasicsFormProps,
) {
  if (formProps.isRenewal) {
    return validateRenewalLease(values, formProps);
  }
  return validateInitialLease(values, formProps);
}

export default validate;
