import React, { Component } from 'react';
import { FormSection } from 'redux-form';
import { Field } from 'redux-form';
import { update, isNil, isEmpty } from 'ramda';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  renderTextField,
  renderPhoneField,
  renderEmailField,
  renderSelectField,
  phoneParser,
  phoneFormatter,
} from '../../../utils/redux-form-helper';
import MiniProfileFormPanel from '../../MiniProfileFormPanel';
import messages from './messages';
import { requiredVal } from '../utils';

const RowWarning = styled.div`
  .highlight-details {
    margin: 10px;
    padding: 10px;
  }
`;

const PANEL_TITLE = 'General Information';

type Props = {
  openPanel: ?string,
  togglePanel: Function,
  states: Array<Object>,
  leaseExecuted: boolean,
  leaseSentToPortal: boolean,
  bypassedFields?: string[],
};

type State = {
  editable: boolean,
};

const FIELD_TO_LABEL_MAP = {
  firstName: 'Tenant Legal Name',
  preferredName: 'DBA(Doing Business As)',
  parentCompany: 'Parent Company',
  phoneNumber: 'Phone',
  emailAddress: 'Email Address',
  street: 'Address',
  street2: 'Address 2',
  city: 'City',
  state: 'State / Province',
  zipCode: 'Zip Code / Postal Code',
};

const CommercialGeneralInformationFormSection = ({
  disabled,
  leaseExecuted,
  leaseSentToPortal,
  stateOptions,
  sectionInitialValues,
}) => {
  const isEmailBypassed =
    isNil(sectionInitialValues.emailAddress) ||
    isEmpty(sectionInitialValues.emailAddress);
  const isPhoneNumberBypassed =
    isNil(sectionInitialValues.phoneNumber) ||
    isEmpty(sectionInitialValues.phoneNumber);
  const isFirstNameBypassed =
    isNil(sectionInitialValues.firstName) ||
    isEmpty(sectionInitialValues.firstName);

  return (
    <FormSection name="generalInformation">
      {leaseExecuted && (
        <RowWarning className="row">
          <div className="highlight-details">
            <FormattedMessage {...messages.leaseLockedExecuted} />
          </div>
        </RowWarning>
      )}
      {leaseSentToPortal && (
        <RowWarning className="row">
          <div className="highlight-details">
            <FormattedMessage {...messages.leaseLockedSentToPortal} />
          </div>
        </RowWarning>
      )}
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              name="firstName"
              className="input-md form-control"
              component={renderTextField}
              maxLength="35"
              placeholder={disabled ? '---' : ''}
              label={`${FIELD_TO_LABEL_MAP.firstName}*`}
              showLabel={true}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
              warn={isFirstNameBypassed ? requiredVal : undefined}
              validate={isFirstNameBypassed ? undefined : requiredVal}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              name="preferredName"
              className="input-md form-control"
              component={renderTextField}
              maxLength="35"
              placeholder={disabled ? '---' : ''}
              label={FIELD_TO_LABEL_MAP.preferredName}
              showLabel={true}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-7">
          <div className="form-group">
            <Field
              name="parentCompany"
              className="input-md form-control"
              component={renderTextField}
              maxLength="35"
              placeholder={disabled ? '---' : ''}
              label={FIELD_TO_LABEL_MAP.parentCompany}
              showLabel={true}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
            />
          </div>
        </div>
        <div className="col-sm-5">
          <div className="form-group">
            <Field
              name="phoneNumber"
              className="input-md form-control"
              component={renderPhoneField}
              parse={phoneParser}
              format={phoneFormatter}
              placeholder={''}
              label={`${FIELD_TO_LABEL_MAP.phoneNumber}*`}
              showLabel={true}
              disabled={disabled}
              warn={isPhoneNumberBypassed ? requiredVal : undefined}
              validate={isPhoneNumberBypassed ? undefined : requiredVal}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="form-group">
            <Field
              name="emailAddress"
              className="input-md form-control"
              component={renderEmailField}
              placeholder={''}
              label={`${FIELD_TO_LABEL_MAP.emailAddress}*`}
              showLabel={true}
              disabled={disabled}
              warn={isEmailBypassed ? requiredVal : undefined}
              validate={isEmailBypassed ? undefined : requiredVal}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              name="street"
              className="input-md form-control"
              component={renderTextField}
              maxLength="50"
              placeholder={disabled ? '---' : ''}
              label={FIELD_TO_LABEL_MAP.street}
              showLabel={true}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              name="street2"
              className="input-md form-control"
              component={renderTextField}
              maxLength="50"
              placeholder={disabled ? '---' : ''}
              label={FIELD_TO_LABEL_MAP.street2}
              showLabel={true}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-5">
          <div className="form-group">
            <Field
              name="city"
              className="input-md form-control"
              component={renderTextField}
              maxLength="30"
              placeholder={disabled ? '---' : ''}
              label={FIELD_TO_LABEL_MAP.city}
              showLabel={true}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="state"
              selectClassNames="input-md form-control"
              component={renderSelectField}
              label={FIELD_TO_LABEL_MAP.state}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
              options={stateOptions}
            />
          </div>
        </div>
        <div className="col-sm-3">
          <div className="form-group">
            <Field
              name="zipCode"
              className="input-md form-control"
              component={renderTextField}
              maxLength="5"
              placeholder={disabled ? '---' : ''}
              label={FIELD_TO_LABEL_MAP.zipCode}
              showLabel={true}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
            />
          </div>
        </div>
      </div>
    </FormSection>
  );
};

export class GeneralCommercialInformation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editable: false,
    };
  }

  toggleEdit = () => {
    this.setState({ editable: !this.state.editable });
  };

  render() {
    const {
      openPanel,
      togglePanel,
      leaseExecuted,
      leaseSentToPortal,
      states,
      bypassedFields,
      sectionInitialValues,
    } = this.props;
    const mappedBypassedFields = bypassedFields.map(
      (bf) => FIELD_TO_LABEL_MAP[bf],
    );

    const stateOptions = update(0, { value: '', text: 'Choose' }, states);
    return (
      <MiniProfileFormPanel
        open={openPanel === PANEL_TITLE}
        toggleOpen={togglePanel(PANEL_TITLE)}
        title={PANEL_TITLE}
        bypassedFields={mappedBypassedFields}
      >
        <div className="container-fluid padleft5 padright5 padtop15 padbottom15">
          <div className="row">
            <div className="col-md-12">
              <a
                onClick={() => this.toggleEdit()}
                className="small simple-link pull-right"
              >
                <i className="icon et-pencil" />
                <span>Edit </span>
              </a>
            </div>
          </div>

          <CommercialGeneralInformationFormSection
            disabled={!this.state.editable}
            leaseExecuted={leaseExecuted}
            leaseSentToPortal={leaseSentToPortal}
            stateOptions={stateOptions}
            sectionInitialValues={sectionInitialValues}
          />
        </div>
      </MiniProfileFormPanel>
    );
  }
}

export default GeneralCommercialInformation;
