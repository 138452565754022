import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageRentRoll.Title',
    defaultMessage: 'Fortress - Manage rent roll',
  },
  header: {
    id: 'App.ManageRentRoll.Header',
    defaultMessage: 'Manage Rent Roll',
  },
  subheader: {
    id: 'App.ManageRentRoll.Subheader',
    defaultMessage: 'Units found:',
  },
  unit: {
    id: 'App.ManageRentRoll.Unit',
    defaultMessage: 'Unit',
  },
  floorPlan: {
    id: 'App.ManageRentRoll.FloorPlan',
    defaultMessage: 'Floor Plan',
  },
  bedsBaths: {
    id: 'App.ManageRentRoll.BedsBaths',
    defaultMessage: 'Beds / Baths',
  },
  sqFeet: {
    id: 'App.ManageRentRoll.SqFeet',
    defaultMessage: 'Sq. Ft.',
  },
  status: {
    id: 'App.ManageRentRoll.Status',
    defaultMessage: 'Status',
  },
  resident: {
    id: 'App.ManageRentRoll.Resident',
    defaultMessage: 'Resident',
  },
  moveInDate: {
    id: 'App.ManageRentRoll.MoveInDate',
    defaultMessage: 'Move-In Date',
  },
  leaseStartDate: {
    id: 'App.ManageRentRoll.LeaseStartDate',
    defaultMessage: 'Lease Start Date',
  },
  leaseEndDate: {
    id: 'App.ManageRentRoll.LeaseEndDate',
    defaultMessage: 'Lease End Date',
  },
  gprMarketRent: {
    id: 'App.ManageRentRoll.GPRMarketRent',
    defaultMessage: 'Unit GPR (Market Rent)',
  },
  unitGPRMarketRent: {
    id: 'App.ManageRentRoll.UnitGPRMarketRent',
    defaultMessage: 'Unit GPR (Market Rent)',
  },
  floorPlanBaseGPR: {
    id: 'App.ManageRentRoll.FloorPlanBaseGPR',
    defaultMessage: 'Floor Plan Base GPR',
  },
  netMaxRent: {
    id: 'App.ManageRentRoll.NetMaxRent',
    defaultMessage: 'Net Max Rent',
  },
  priorLeasedRent: {
    id: 'App.ManageRentRoll.PriorLeasedRent',
    defaultMessage: 'Prior Leased Rent',
  },
  currentLeasedRent: {
    id: 'App.ManageRentRoll.CurrentLeasedRent',
    defaultMessage: 'Current Leased Rent',
  },
  subsidyRent: {
    id: 'App.ManageRentRoll.SubsidyRent',
    defaultMessage: 'Subsidy Rent',
  },
  basicRent: {
    id: 'App.ManageRentRoll.BasicRent',
    defaultMessage: 'Basic Rent',
  },
  noteRent: {
    id: 'App.ManageRentRoll.NoteRent',
    defaultMessage: 'Note Rent',
  },
  residentChargedRent: {
    id: 'App.ManageRentRoll.ResidentChargedRent',
    defaultMessage: 'Resident Rent',
  },
  otherUnitFees: {
    id: 'App.ManageRentRoll.OtherUnitFees',
    defaultMessage: 'Other Unit Fees',
  },
  chargedUnitFees: {
    id: 'App.ManageRentRoll.ChargedUnitFees',
    defaultMessage: 'Charged Unit Fees',
  },
  balance: {
    id: 'App.ManageRentRoll.Balance',
    defaultMessage: 'Balance',
  },
  moveOutDate: {
    id: 'App.ManageRentRoll.MoveOutDate',
    defaultMessage: 'Move-Out Date',
  },
  moveInReady: {
    id: 'App.ManageRentRoll.MoveInReady',
    defaultMessage: 'Move-In Ready',
  },
  daysVacant: {
    id: 'App.ManageRentRoll.DaysVacant',
    defaultMessage: 'Days Vacant',
  },
  quotingRent: {
    id: 'App.ManageRentRoll.QuotingRent',
    defaultMessage: 'Quoting Rent',
  },
  applicant: {
    id: 'App.ManageRentRoll.Applicant',
    defaultMessage: 'Applicant',
  },
  applicantStatus: {
    id: 'App.ManageRentRoll.ApplicantStatus',
    defaultMessage: 'Applicant Status',
  },
  scheduledMoveInDate: {
    id: 'App.ManageRentRoll.ScheduledMoveInDate',
    defaultMessage: 'Scheduled Move In Date',
  },
  view: {
    id: 'App.ManageRentRoll.View',
    defaultMessage: 'View',
  },
  search: {
    id: 'App.ManageRentRoll.Search',
    defaultMessage: 'Search...',
  },
  immediate: {
    id: 'App.ManageRentRoll.Immediate',
    defaultMessage: 'Immediate',
  },
  ready: {
    id: 'App.ManageRentRoll.Ready',
    defaultMessage: 'Ready',
  },
  unknown: {
    id: 'App.ManageRentRoll.Unknown',
    defaultMessage: 'Unknown',
  },
  successHeader: {
    id: 'App.ManageRentRoll.SuccessHeader',
    defaultMessage: 'Success',
  },
  successDescription: {
    id: 'App.ManageRentRoll.SuccessDescription',
    defaultMessage: 'Current specials were saved',
  },
  errorHeader: {
    id: 'App.ManageRentRoll.ErrorHeader',
    defaultMessage: 'Error',
  },
  saveSpecials: {
    id: 'App.ManageRentRoll.SaveSpecials',
    defaultMessage: 'Save Specials',
  },
  currentSpecials: {
    id: 'App.ManageRentRoll.CurrentSpecials',
    defaultMessage: 'Current Specials:',
  },
  edit: {
    id: 'App.ManageRentRoll.Edit',
    defaultMessage: 'Edit',
  },
  editCurrentSpecials: {
    id: 'App.ManageRentRoll.EditCurrentSpecials',
    defaultMessage: 'Edit Current Specials',
  },
  noUnitsAvailable: {
    id: 'App.ManageRentRoll.NoUnitsAvailable',
    defaultMessage: 'No units available.',
  },
  collectionsNotes: {
    id: 'App.ManageRentRoll.CollectionsNotes',
    defaultMessage: 'Collections Notes',
  },
  underEviction: {
    id: 'App.ManageRentRoll.UnderEviction',
    defaultMessage: 'Under Eviction',
  },
  baseFloorPlanMarketRent: {
    id: 'App.ManageRentRoll.BaseFloorPlanMarketRent',
    defaultMessage: 'Base Floor Plan Market Rent',
  },
  amenityFees: {
    id: 'App.ManageRentRoll.TotalUnitAmenities',
    defaultMessage: 'Total Unit Amenities',
  },
  amenityDetails: {
    id: 'App.ManageRentRoll.AmenityDetails',
    defaultMessage: 'Unit Amenities Details',
  },
  vacancyLoss: {
    id: 'App.ManageRentRoll.VacancyLoss',
    defaultMessage: 'Vacancy',
  },
  leased: {
    id: 'App.ManageRentRoll.Leased',
    defaultMessage: 'Leased',
  },
  units: {
    id: 'App.ManageRentRoll.Units',
    defaultMessage: 'Units',
  },
  leasedRentAverage: {
    id: 'App.ManageRentRoll.leasedRentAverage',
    defaultMessage: 'Leased Rent Average',
  },
  marketRentSqFeet: {
    id: 'App.ManageRentRoll.marketRentSqFeet',
    defaultMessage: 'Market Rent/SqFt',
  },
  leasedRentSqFeet: {
    id: 'App.ManageRentRoll.leasedRentSqFeet',
    defaultMessage: 'Leased Rent/SqFt',
  },
  occupiedUnits: {
    id: 'App.ManageRentRoll.occupiedUnits',
    defaultMessage: 'Occupied Units',
  },
  occupancyPct: {
    id: 'App.ManageRentRoll.occupancyPct',
    defaultMessage: 'Occupancy %',
  },
  setAside: {
    id: 'App.ManageRentRoll.setAside',
    defaultMessage: 'Set Aside',
  },
  rdRentalAssistanceRA: {
    id: 'App.ManageRentRoll.rdRentalAssistanceRA',
    defaultMessage: 'Rental Assistance (RA)',
  },
  nonOptionalCharge: {
    id: 'App.ManageRentRoll.nonOptionalCharge',
    defaultMessage: 'Non Optional Charge',
  },
  utilityAllowance: {
    id: 'App.ManageRentRoll.utilityAllowance',
    defaultMessage: 'Current Floor Plan UA',
  },
  grossRentLimit: {
    id: 'App.ManageRentRoll.grossRentLimit',
    defaultMessage: 'Gross Rent Limit',
  },
  // tooltips:

  floorPlanBaseGPRTooltip: {
    id: 'App.ManageRentRoll.floorPlanBaseGPRTooltip',
    defaultMessage:
      'Floor Plan Base Rent Amount being used for GPR Financial reporting',
  },
  unitGPRMarketRentTooltip: {
    id: 'App.ManageRentRoll.unitGPRMarketRentTooltip',
    defaultMessage:
      'Floor Plan Base Rent Amount being used for GPR Financial reporting plus Unit Amenities',
  },
  grossRentLimitTooltip: {
    id: 'App.ManageRentRoll.grossRentLimitTooltip',
    defaultMessage:
      'Floor Plan Gross Rent Limit, Maximum Allowable Rent for the Unit released by HUD',
  },
  netMaxRentTooltip: {
    id: 'App.ManageRentRoll.netMaxRentTooltip',
    defaultMessage:
      'Gross Rent Limit minus the largest UA for this floor plan minus any non-optional charges',
  },
  utilityAllowanceTooltip: {
    id: 'App.ManageRentRoll.utilityAllowanceTooltip',
    defaultMessage:
      'Largest UA currently assigned to this Floor Plan (not household specific)',
  },
  otherHouseholdMembers: {
    id: 'App.ManageRentRoll.OtherHouseholdMembers',
    defaultMessage: 'Other Household Members',
  },
  texting: {
    id: 'App.ManageRentRoll.Texting',
    defaultMessage: 'Texting',
  },
  totalGrossRent: {
    id: 'App.ManageRentRoll.TotalGrossRent',
    defaultMessage: 'Total Gross Rent',
  },
  totalGrossRentTooltip: {
    id: 'App.ManageRentRoll.TotalGrossRentTooltip',
    defaultMessage:
      'Resident Rent plus Actual UA plus any non-optional charges',
  },
  actualUa: {
    id: 'App.ManageRentRoll.ActualUa',
    defaultMessage: 'Actual UA',
  },
  actualUaTooltip: {
    id: 'App.ManageRentRoll.ActualUaTooltip',
    defaultMessage:
      'Utility allowance selected on the most recent completed Qualification',
  },
  hardship: {
    id: 'App.ManageRentRoll.Hardship',
    defaultMessage: 'Hardship',
  },
  autopayHeader: {
    id: 'App.ManageRentRoll.AutopayHeader',
    defaultMessage: 'Autopay',
  },
  padHeader: {
    id: 'App.ManageRentRoll.PadHeader',
    defaultMessage: 'PAD',
  },
  buildingNumber: {
    id: 'App.ManageRentRoll.BuildingNumber',
    defaultMessage: 'Building Number',
  },
});

export default messages;
