import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field, FormSection } from 'redux-form';
import { update } from 'ramda';
import classNames from 'classnames';

import {
  renderPhoneField,
  renderTextField,
  renderDateField,
  renderCurrencyField,
  extractCurrentDateFormat,
  phoneParser,
  phoneFormatter,
} from '../../../utils/redux-form-helper';
import AddressSection from '../../AddressSection';
import messages from './messages';

import type { SelectOption } from '../../../containers/App/types';

type Props = {
  intl: Object,
  states: Array<SelectOption>,
  title: Object,
  required?: boolean,
  streetLabel: Object,
  isCurrentEmployer: boolean,
  fromLabel?: ?Object,
  disabled?: ?boolean,
};

const ApplicantsEmployerSection = ({
  intl,
  states,
  title,
  required,
  streetLabel,
  isCurrentEmployer,
  fromLabel,
  disabled,
}: Props) => {
  const stateOptions = update(
    0,
    { value: '', text: 'Choose', disabled: false },
    states,
  );
  return (
    <div className="form-content">
      <Row>
        <div className="form-block">
          <Col xs={12} md={12}>
            <h3>{intl.formatMessage(title)}</h3>
          </Col>
          <Col xs={12} md={3}>
            <Field
              name="position"
              component={renderTextField}
              className="input-lg"
              label={intl.formatMessage(messages.position)}
              placeholder={intl.formatMessage(messages.position)}
              classes={classNames({ required })}
              showLabel
              disabled={disabled}
            />
          </Col>
          <Col xs={12} md={3}>
            <Field
              name="annualSalary"
              component={renderCurrencyField}
              className="input-lg"
              label={intl.formatMessage(messages.annualSalary)}
              placeholder={intl.formatMessage(messages.annualSalaryPlaceholder)}
              classes={classNames({ required })}
              disabled={disabled}
            />
          </Col>
          <Col xs={12} md={3}>
            <Field
              name="from"
              component={renderDateField}
              bsSize="lg"
              label={intl.formatMessage(fromLabel)}
              placeholder={extractCurrentDateFormat(intl)}
              dateFormat={extractCurrentDateFormat(intl)}
              classes={classNames({ required })}
              disabled={disabled}
            />
          </Col>
          {!isCurrentEmployer && (
            <Col xs={12} md={3}>
              <Field
                name="to"
                component={renderDateField}
                bsSize="lg"
                label={intl.formatMessage(messages.to)}
                placeholder={extractCurrentDateFormat(intl)}
                dateFormat={extractCurrentDateFormat(intl)}
                disabled={disabled}
              />
            </Col>
          )}
        </div>
      </Row>
      <Row>
        <Col xs={12} md={3}>
          <Field
            name="employerName"
            component={renderTextField}
            className="input-lg"
            label={intl.formatMessage(messages.employerName)}
            placeholder={intl.formatMessage(messages.employerName)}
            showLabel
            classes={classNames({ required })}
            disabled={disabled}
          />
        </Col>
        <Col xs={12} md={3}>
          <Field
            name="phoneNumber"
            component={renderPhoneField}
            className="input-lg"
            label={intl.formatMessage(messages.phoneNumber)}
            placeholder={intl.formatMessage(messages.phoneNumberPlaceHolder)}
            showLabel
            parse={phoneParser}
            format={phoneFormatter}
            classes={classNames({ required })}
            disabled={disabled}
          />
        </Col>
        <Col xs={12} md={6}>
          <Field
            name="supervisorName"
            component={renderTextField}
            className="input-lg"
            label={intl.formatMessage(messages.supervisorName)}
            placeholder={intl.formatMessage(messages.supervisorName)}
            showLabel
            classes={classNames({ required })}
            disabled={disabled}
          />
        </Col>
      </Row>
      <FormSection name="address">
        <AddressSection
          intl={intl}
          states={stateOptions}
          required={required}
          streetLabel={streetLabel}
          disabled={disabled}
        />
      </FormSection>
    </div>
  );
};

export default ApplicantsEmployerSection;
