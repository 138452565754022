import { useState, useEffect, useCallback } from 'react';

/**
 * Custom hook to manage the visibility of the certStatus column
 * ---
 * @param {*} param0.selectedColumns the columns that are currently selected for the table
 * @param {*} param0.handleColumnChange function to handle changes to the selected columns
 * @param {*} param0.setIncludeNotStartedCerts function to set the includeNotStartedCerts flag sent to backend
 * @param {*} param0.allOpenCertsNotStartedAndStatusFlag feature flag
 * @returns
 */
const useCertStatusColumn = ({
  selectedColumns,
  handleColumnChange,
  setIncludeNotStartedCerts,
  allOpenCertsNotStartedAndStatusFlag,
}) => {
  const [isCertStatusVisible, setIsCertStatusVisible] = useState(false);

  // Will remove certStatus column on the initial render
  useEffect(() => {
    handleColumnChange(
      selectedColumns.filter((colName) => colName !== 'certStatus'),
    );
  }, []);

  // Will show or hide certStatus column on the next renders based on the dependencies
  useEffect(() => {
    if (allOpenCertsNotStartedAndStatusFlag) {
      setIsCertStatusVisible(
        selectedColumns.includes('certStatus') ? true : false,
      );
    } else {
      // Keep isCertStatusVisible false after initial render and remove the column if it exists
      if (selectedColumns.includes('certStatus')) {
        handleColumnChange(
          selectedColumns.filter((colName) => colName !== 'certStatus'),
        );
      }
      setIncludeNotStartedCerts(false);
      setIsCertStatusVisible(false); // Explicitly set it to false
    }
  }, [
    handleColumnChange,
    allOpenCertsNotStartedAndStatusFlag,
    setIncludeNotStartedCerts,
    selectedColumns, // selectedColumns must be part of the dependencies
  ]);

  const toggleCertStatusColumn = useCallback(() => {
    const index = selectedColumns.indexOf('certStatus');
    // certStatus is in selected columns, so we are removing it
    if (index > -1) {
      handleColumnChange(
        selectedColumns.filter((colName) => colName !== 'certStatus'),
      );
      setIncludeNotStartedCerts(false);
      // certStatus is NOT in selected columns, so we are adding it
    } else {
      handleColumnChange([...selectedColumns, 'certStatus']);
      setIncludeNotStartedCerts(true);
    }
    setIsCertStatusVisible((prev) => !prev);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumns, handleColumnChange]);

  return {
    isCertStatusVisible,
    toggleCertStatusColumn,
    shouldNotStartedCertsButtonBeDisplayed: allOpenCertsNotStartedAndStatusFlag,
  };
};

export default useCertStatusColumn;
