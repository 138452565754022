import type { Action } from '../App/types';
import type { EditApplicationHouseholdState } from './types';
import * as ActionTypes from './constants';

const initialState: EditApplicationHouseholdState = {
  currentApplication: undefined,
  isHouseholdSubmitting: false,
  primaryApplicantId: undefined,
  applicationIsLoading: false,
};

const applicationProfileReducer = (
  state: EditApplicationHouseholdState = initialState,
  action: Action<*, *>,
): EditApplicationHouseholdState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.CLEAN: {
      return { ...initialState };
    }
    case ActionTypes.GET_ONE_APPLICATION: {
      return { ...state, applicationIsLoading: true };
    }
    case ActionTypes.GET_ONE_APPLICATION_SUCCESS: {
      return {
        ...state,
        currentApplication: payload,
        applicationIsLoading: false,
      };
    }
    case ActionTypes.GET_ONE_APPLICATION_ERROR: {
      return {
        ...state,
        currentApplication: initialState.currentApplication,
        applicationIsLoading: false,
      };
    }
    case ActionTypes.EDIT_APPLICATION_HOUSEHOLD: {
      return { ...state, isHouseholdSubmitting: true };
    }
    case ActionTypes.EDIT_APPLICATION_HOUSEHOLD_SUCCESS:
    case ActionTypes.EDIT_APPLICATION_HOUSEHOLD_ERROR: {
      return { ...state, isHouseholdSubmitting: false };
    }
    default:
      return state;
  }
};

export default applicationProfileReducer;
