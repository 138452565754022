export const ASSIGNE_INACTIVE_COLOR = 'warning.lighter';
export const PAST_DUE_COLOR = 'error.lighter';

export const PROPERTY_PATH_MAP = {
  id: {
    path: 'id',
    dataType: 'string',
  },
  location: {
    path: 'location',
    dataType: 'string',
  },
  buildingNumber: {
    path: 'buildingNumber',
    dataType: 'string',
  },
  issueDescription: {
    path: 'issueDescription',
    dataType: 'string',
  },
  creationDate: {
    path: 'creationDate',
    dataType: 'string',
  },
  assignedTo: {
    path: 'assignedTo',
    dataType: 'string',
    filterPath: 'assignedToId',
  },
  priority: {
    path: 'priority',
    dataType: 'string',
    filterPath: 'priorityId',
  },
  status: {
    path: 'status',
    dataType: 'string',
    filterPath: 'status',
    filterFn: (selectedStatus: string | string[], status: string) => {
      return selectedStatus.includes(status);
    },
  },
  daysOpen: {
    path: 'daysOpen',
    dataType: 'number',
  },
  dueDate: {
    path: 'dueDate',
    dataType: 'string',
  },
  completionDate: {
    path: 'completionDate',
    dataType: 'string',
  },
  requestor: {
    path: 'requestorName',
    dataType: 'string',
  },
};
