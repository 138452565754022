import { palette } from '@fortress-technology-solutions/fortress-component-library/design';
import classNames from 'classnames';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledButton = styled.button`
  display: ${(props) =>
    props.customerStatus === 'none' ? 'none' : 'inline'} !important;

  ${(props) =>
    props.showFilter
      ? css`
          border-color: ${palette.main.lightGrey} !important;
          color: white !important;
        `
      : css`
          border-color: ${palette.main.blue} !important;
          color: ${palette.main.blue} !important;

          &:hover {
            color: white !important;
          }
        `}
`;

export const FilterButton = ({
  showFilter,
  customerStatus,
  handleFilterClick,
}) => {
  return (
    <StyledButton
      className={classNames('button-filter', {
        'filters-are-active': showFilter,
      })}
      customerStatus={customerStatus}
      showFilter={showFilter}
      onClick={handleFilterClick}
    >
      <i className="et-filter" />
    </StyledButton>
  );
};
