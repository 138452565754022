import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageProspects.ChangeStatusModal.Title',
    defaultMessage: 'Change Prospect Status'
  },
  message: {
    id: 'App.ManageProspects.ChangeStatusModal.Message',
    defaultMessage: 'You’re about to change this prospect’s status. Please confirm:'
  },
  newStatusLabel: {
    id: 'App.ManageProspects.ChangeStatusModal.NewStatusLabel',
    defaultMessage: 'Select new status'
  },
  reasonLabel: {
    id: 'App.ManageProspects.ChangeStatusModal.ReasonLabel',
    defaultMessage: 'Why are you changing this status?'
  },
  reasonPlaceholder: {
    id: 'App.ManageProspects.ChangeStatusModal.ReasonPlaceHolder',
    defaultMessage: 'Start typing...'
  },
  cancel: {
    id: 'App.ManageProspects.ChangeStatusModal.Cancel',
    defaultMessage: 'Cancel'
  },
  confirm: {
    id: 'App.ManageProspects.ChangeStatusModal.Confirm',
    defaultMessage: 'Confirm changes'
  }
});

export default messages;
