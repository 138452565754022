import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { find, propEq } from 'ramda';
import { change } from 'redux-form';
import DocumentTitle from 'react-document-title';

import confirm from '../../components/ConfirmDialogModal';
import * as editRoleActions from './actions';
import * as createRoleActions from '../CreateRole/actions';
import CreateRoleForm from '../CreateRole/CreateRoleForm';
import messages from './messages';
import type { GlobalState, Permission, Role, RoleType } from '../App/types';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

type Props = {
  state: Object,
  locale: string,
  history: any,
  roleId: string,
  selectedPermissions: Array<string>,
  roleTypes: Array<RoleType>,
  nonePermissionsSelected: boolean,
  rolePermitsAssignees: boolean,
  organizationId: string,
  isSubmitting: boolean,
};

type InjectedProps = {
  roleTypes: Array<RoleType>,
  actions: Object,
  createRoleActions: Object,
  permissions: Array<Permission>,
  intl: any,
  role: Role,
  change: Function,
};
type StateProps = {
  rolePermitsAssignees: boolean,
};

export class EditRolePage extends Component<Props & InjectedProps, StateProps> {
  componentDidMount() {
    this.props.actions.getRole(this.props.roleId);
    this.props.createRoleActions.getAllPermissions();
    this.props.createRoleActions.getAllRoleTypes();
  }

  handlePermissionsSelectedError = (newState: boolean) => {
    this.props.actions.showNonePermissionsSelectedError(newState);
  };

  handleSubmit = (role: Role) => {
    if (role.permissions.length === 0) {
      this.handlePermissionsSelectedError(true);
    } else {
      this.props.actions.editRole(this.props.roleId, role);
      this.handlePermissionsSelectedError(false);
    }
  };

  handleMultiselectChange = (permission: Array<Permission>) => {
    this.props.actions.setSelectedPermissions(permission);
  };

  handleRoleTypeChange = (roleTypeId: string) => {
    const selectedRole =
      find(propEq('id', roleTypeId))(this.props.roleTypes) || {};
    this.props.actions.showRolePermitsAssignees(selectedRole.canBeAssigned);

    if (!selectedRole.canBeAssigned) {
      this.props.change('createRole', 'canBeAssignedWorkOrder', false);
      this.props.change('createRole', 'canBeAssignedProspect', false);
    }
  };

  handleCancel = () => {
    confirm(this.props.intl.formatMessage(messages.cancelConfirmation), {
      intl: this.props.intl,
    }).then(() => {
      this.props.history.replace('/manage-roles');
      this.handlePermissionsSelectedError(false);
    });
  };

  generateRoleTypes() {
    const roleTypes = this.props.roleTypes.map((roleType) => ({
      value: roleType.id,
      text: roleType.translations[this.props.locale] || roleType.name,
      disabled: false,
    }));
    roleTypes.unshift({
      value: 'default',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return roleTypes;
  }

  generatePermissions() {
    return this.props.permissions.map((permission) => ({
      value: permission.id,
      label: `${permission.module}: ${permission.name}`,
    }));
  }

  render() {
    const permissions = this.generatePermissions();
    const roleTypes = this.generateRoleTypes();
    const {
      role: {
        name,
        description,
        roleTypeId,
        canBeAssignedWorkOrder,
        canBeAssignedProspect,
      },
      isSubmitting,
    } = this.props;

    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <CreateRoleForm
          initialValues={{
            name,
            canBeAssignedWorkOrder,
            canBeAssignedProspect,
            description,
            roleTypeId,
            organizationId: this.props.organizationId,
          }}
          header={this.props.intl.formatMessage(messages.header)}
          onSubmit={this.handleSubmit}
          handleMultiselectChange={this.handleMultiselectChange}
          handlePermissionsSelectedError={this.handlePermissionsSelectedError}
          handleRoleTypeChange={this.handleRoleTypeChange}
          permissions={permissions}
          roleTypes={roleTypes}
          intl={this.props.intl}
          selectedPermissions={this.props.selectedPermissions}
          nonePermissionsSelected={this.props.nonePermissionsSelected}
          rolePermitsAssignees={this.props.rolePermitsAssignees}
          handleCancel={this.handleCancel}
          submitPermissions={['role-update']}
          isSubmitting={isSubmitting}
        />
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = (
  { createRole, editRole, languageProvider, app }: GlobalState,
  ownProps: Object,
) => {
  return {
    roleId: ownProps.match.params.roleId,
    role: editRole.role,
    permissions:
      createRole.permissions?.filter((permission) => {
        const { flags } = ownProps;

        if (flags.documentManagementMvp === true) return permission;

        return (
          ['centralized-docs-read', 'centralized-docs-edit'].includes(
            permission.scope,
          ) === false
        );
      }) ?? [],
    selectedPermissions: editRole.selectedPermissions,
    roleTypes: createRole.roleTypes,
    nonePermissionsSelected: editRole.nonePermissionsSelected,
    rolePermitsAssignees: editRole.rolePermitsAssignees,
    locale: languageProvider.locale,
    organizationId: app.currentUser ? app.currentUser.user.organizationId : '',
    isSubmitting: editRole.isSubmitting,
  };
};

export function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(editRoleActions, dispatch),
    createRoleActions: bindActionCreators(createRoleActions, dispatch),
    change: bindActionCreators(change, dispatch),
  };
}

const InjectedEditRolePage = injectIntl(EditRolePage);

export default withLDConsumer()(
  connect(mapStateToProps, mapDispatchToProps)(InjectedEditRolePage),
);
