import { defineMessages } from 'react-intl';

const messages = defineMessages({
  smartPrice: {
    id: 'App.smartPrice.smartPrice',
    defaultMessage: 'Smart Price',
  },
  on: {
    id: 'App.smartPrice.on',
    defaultMessage: 'On',
  },
  off: {
    id: 'App.smartPrice.off',
    defaultMessage: 'Off',
  },
  targetOccupancyTooltip: {
    id: 'App.smartPrice.targetOccupancyTooltip',
    defaultMessage:
      'Set your desired occupancy level to fine-tune Smart Price suggestions.',
  },
  targetOccupancy: {
    id: 'App.smartPrice.targetOccupancy',
    defaultMessage: 'Target Occupancy',
  },
  targetOccupancyError: {
    id: 'App.smartPrice.targetOccupancyError',
    defaultMessage: 'Value must be between 1 and 100',
  },
  targetTimeframe: {
    id: 'App.smartPrice.targetTimeframe',
    defaultMessage: 'Target Timeframe',
  },
  targetTimeframeTooltip: {
    id: 'App.smartPrice.targetTimeframeTooltip',
    defaultMessage:
      'Set your desired leasing period to fine-tune Smart Price suggestions.',
  },
  tooltipSmartPriceOn: {
    id: 'App.smartPrice.tooltipSmartPriceOn',
    defaultMessage: 'To turn smart Price OFF, contact your admin.',
  },
  tooltipSmartPriceOff: {
    id: 'App.smartPrice.tooltipSmartPriceOff',
    defaultMessage: `Smart Price is a premium AI-powered feature designed to suggest quoting rents with precision.
    Contact your admin to set it up.`,
  },
});

export default messages;
