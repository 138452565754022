import { defineMessages } from 'react-intl';

const messages = defineMessages({
  emergencyContactInformationLabel: {
    id:
      'App.LeaseDataTabForm.EmergencyContactInformation.EmergencyContactInformationLabel',
    defaultMessage: 'Emergency Contact Information',
  },
  emergencyContactNameLabel: {
    id:
      'App.LeaseDataTabForm.EmergencyContactInformation.EmergencyContactNameLabel',
    defaultMessage: 'Name',
  },
  emergencyContactPhoneLabel: {
    id:
      'App.LeaseDataTabForm.EmergencyContactInformation.EmergencyContactPhoneLabel',
    defaultMessage: 'Phone',
  },
  emergencyContactRelationshipLabel: {
    id:
      'App.LeaseDataTabForm.EmergencyContactInformation.EmergencyContactRelationshipLabel',
    defaultMessage: 'Relationship',
  },
});

export default messages;
