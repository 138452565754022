export const DEFAULT_OPTION = { value: '', text: 'Choose', disabled: true };
export const VEHICLE_VEHICLE_TYPE_ID = 'ab1f69bc-7796-4557-8fb8-2025a7420c3e';

export const GEN_YEAR_OPTIONS = (): Array<any> => {
  const START_YEAR = 1900;
  const CURRENT_DATE = new Date();
  const NEXT_YEAR = CURRENT_DATE.getFullYear() + 1;
  const yearOptionMapper = (empty, i) => {
    const yearOptionValue = NEXT_YEAR - i;
    return {
      id: yearOptionValue,
      name: yearOptionValue,
    };
  };

  return Array.from({ length: NEXT_YEAR - START_YEAR }, yearOptionMapper);
};
