import { connect } from 'react-redux';
import { useState, useMemo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import * as R from 'ramda';
import DocumentTitle from 'react-document-title';
import moment from 'moment';

import messages from './messages';
import { promptToaster } from '../App/actions';
import { useLeaseExpirationLimitsGraphData } from './hooks';
import IconLink from '../../components/IconLink';
import LimitsVsActualsCard from './LimitsVsActualsCard';
import Spacer from '../../components/Spacer';
import styled from 'styled-components';
import type { RouterProps } from '../../types';
import { PageContext } from './context';
import useHasPermission from '../../hooks/useHasPermission';
import useExpirationLimitsPropertyToggle from '../../hooks/data-fetching/useExpirationLimitsPropertyToggle';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type Params = {
  organizationId: string,
  propertyId: string,
};

type Props = {
  actions: { toastrFn: Function },
  intl: { formatMessage: Function },
} & RouterProps<Params>;

const defaultVerticalSpace = 14;

const Wrapper = styled.div`
  padding: 3%;
`;

const getInitialYears = () => {
  const currentYear = moment().year();
  return [currentYear, currentYear + 1, currentYear + 2];
};

function ManageLeaseExpirationLimits({
  toastrFn,
  history,
  match,
  intl: { formatMessage },
  selectedProperty,
}: Props) {
  const hasEditPermission = useHasPermission('lease-expirations-limit-edit');
  const [selectedMonth, setSelectedMonth] = useState(
    moment().format('MMMM YYYY'),
  );
  const { organizationId, propertyId } = match?.params ?? {};
  const [graphData, isDataReady, getLeaseExpirationLimitsGraphData] =
    useLeaseExpirationLimitsGraphData(
      organizationId,
      propertyId,
      promptToaster,
      formatMessage,
    );
  const limitsHaveBeenCreated = useMemo(
    () => graphData?.noLimits !== true,
    [graphData],
  );

  const [selectedYears, setSelectedYears] = useState(getInitialYears());
  const [tableDirtyStates, setTableDirtyStates] = useState({});
  const years = useMemo(() => getInitialYears(), []);
  const shouldShowCloseEditLimitsConfirm = useMemo(() => {
    const dirtyStates = Object.values(tableDirtyStates);
    return R.any(R.equals(true), dirtyStates);
  }, [tableDirtyStates]);

  const getGraphData = () => {
    getLeaseExpirationLimitsGraphData(selectedYears);
  };

  useEffect(() => {
    getGraphData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedProperty?.hasCommercialFloorPlans === 'ALL') {
      history.push('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty?.hasCommercialFloorPlans]);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleYearClick = (year: number, remove: boolean) => {
    setSelectedYears(
      remove
        ? R.without([year], selectedYears)
        : R.append(year, selectedYears).sort(),
    );
  };

  const handleSelectedMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleOnTableDirty = (year: string, isDirty: boolean) => {
    setTableDirtyStates({
      ...tableDirtyStates,
      [year]: isDirty,
    });
  };

  const { isLeaseExpirationLimitsActive } = useExpirationLimitsPropertyToggle(
    organizationId,
    propertyId,
    toastrFn,
  );

  return (
    <DocumentTitle title={formatMessage(messages.pageTitle)}>
      <PageContext.Provider
        value={{
          formatMessage,
          organizationId,
          propertyId,
          years,
          onEditLimitsSuccess: getGraphData,
          onTableDirty: handleOnTableDirty,
          graphData,
          canEdit:
            hasEditPermission &&
            isLeaseExpirationLimitsActive &&
            limitsHaveBeenCreated,
        }}
      >
        <Wrapper>
          <IconLink
            iconClass="et-chevron-left"
            text={formatMessage(messages.goBackBtn)}
            iconFontSize={10}
            onClick={handleGoBack}
          />
          <Spacer v={defaultVerticalSpace} />
          <h2>{formatMessage(messages.pageHeader)}</h2>
          <b>{formatMessage(messages.currentMtmLabel)}: </b>
          {isDataReady ? (
            <a
              href={getUrlWithSelectedPropertyId(
                '/manage-lease-expirations?month=mtm',
              )}
              target="_blank"
              rel="noreferrer"
            >
              {graphData.mtm}
            </a>
          ) : (
            <span>---</span>
          )}
          <Spacer v={defaultVerticalSpace} />
          <LimitsVsActualsCard
            years={years}
            selectedYears={selectedYears}
            onYearClick={handleYearClick}
            graphData={graphData}
            isDataReady={isDataReady}
            selectedMonth={selectedMonth}
            onSelectedMonthChange={handleSelectedMonthChange}
            shouldShowCloseEditLimitsConfirm={shouldShowCloseEditLimitsConfirm}
          />
        </Wrapper>
      </PageContext.Provider>
    </DocumentTitle>
  );
}

const mapStateToProps = (state): StateProps => ({
  selectedProperty: state.app.selectedProperty,
});

export default connect(mapStateToProps, { toastrFn: promptToaster })(
  injectIntl(ManageLeaseExpirationLimits),
);
