import type { Action } from '../App/types';
import * as ActionTypes from './constants';
import { GET_MONTHLY_TRANSACTIONS_SUCCESS } from '../ApplicationProfile/constants';
import type { CreateResidentState } from './types';

const initialState: CreateResidentState = {
  vehicleStatus: undefined,
  checklistStatus: undefined,
  screeningStatus: undefined,
  signedLeaseStatus: undefined,
  unitAvailabilityStatus: undefined,
  moveInActions: [],
  monthlyTransactions: [],
};

const createResidentReducer = (
  state: any = initialState,
  action: Action<*>,
): CreateResidentState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_VEHICLE_STATUS_SUCCESS: {
      return { ...state, vehicleStatus: payload };
    }
    case ActionTypes.GET_MOVE_IN_ACTIONS_SUCCESS: {
      return { ...state, moveInActions: payload };
    }
    case ActionTypes.GET_ONE_APPLICATION_SUCCESS: {
      return { ...state, currentApplication: payload };
    }
    case ActionTypes.GET_CHECKLIST_STATUS_SUCCESS: {
      return { ...state, checklistStatus: payload };
    }
    case ActionTypes.GET_SCREENING_STATUS_SUCCESS: {
      return { ...state, screeningStatus: payload };
    }
    case ActionTypes.GET_APPLICATION_LEASE_STATUS_SUCCESS: {
      return { ...state, leaseStatus: payload };
    }
    case ActionTypes.GET_APPLICATION_LEASE_START_DATE_STATUS_SUCCESS: {
      return { ...state, leaseStartDateStatus: payload };
    }
    case ActionTypes.GET_SIGNED_LEASE_STATUS_SUCCESS: {
      return { ...state, signedLeaseStatus: payload };
    }
    case ActionTypes.GET_UNIT_AVAILABILITY_STATUS_SUCCESS: {
      return { ...state, unitAvailabilityStatus: payload };
    }
    case ActionTypes.GET_ONE_PROPERTY_SUCCESS: {
      return { ...state, propertyDetails: payload };
    }
    case ActionTypes.GET_ONE_APPLICATION_ERROR: {
      return { ...state, currentApplication: initialState.currentApplication };
    }
    case GET_MONTHLY_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        monthlyTransactions: payload,
      };
    }
    case ActionTypes.CLEAN: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default createResidentReducer;
