export const HEADER_ROWS = [
  {
    headers: [
      {
        title: 'Data',
        props: {
          colSpan: '1',
          rowSpan: '2',
        },
        classes: 'header-darker',
        order: null,
      },
      {
        title: 'January',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '2',
        },
      },
      {
        title: 'February',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '2',
        },
      },
      {
        title: 'March',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '2',
        },
      },
      {
        title: 'April',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '2',
        },
      },
      {
        title: 'May',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '2',
        },
      },
      {
        title: 'June',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '2',
        },
      },
      {
        title: 'July',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '2',
        },
      },
      {
        title: 'August',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '2',
        },
      },
      {
        title: 'September',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '2',
        },
      },
      {
        title: 'October',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '2',
        },
      },
      {
        title: 'November',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '2',
        },
      },
      {
        title: 'December',
        classes: 'header-darker--var-b',
        order: null,
        props: {
          colSpan: '2',
        },
      },
      {
        title: 'Total',
        classes: 'header-darker--var',
        order: null,
        props: {
          colSpan: '3',
        },
      },
    ],
  },
  {
    headers: [
      {
        title: '% of Units',
        order: null,
      },
      {
        title: '# of Leases',
        order: null,
      },
      {
        title: '% of Units',
        order: null,
      },
      {
        title: '# of Leases',
        order: null,
      },
      {
        title: '% of Units',
        order: null,
      },
      {
        title: '# of Leases',
        order: null,
      },
      {
        title: '% of Units',
        order: null,
      },
      {
        title: '# of Leases',
        order: null,
      },
      {
        title: '% of Units',
        order: null,
      },
      {
        title: '# of Leases',
        order: null,
      },
      {
        title: '% of Units',
        order: null,
      },
      {
        title: '# of Leases',
        order: null,
      },
      {
        title: '% of Units',
        order: null,
      },
      {
        title: '# of Leases',
        order: null,
      },
      {
        title: '% of Units',
        order: null,
      },
      {
        title: '# of Leases',
        order: null,
      },
      {
        title: '% of Units',
        order: null,
      },
      {
        title: '# of Leases',
        order: null,
      },
      {
        title: '% of Units',
        order: null,
      },
      {
        title: '# of Leases',
        order: null,
      },
      {
        title: '% of Units',
        order: null,
      },
      {
        title: '# of Leases',
        order: null,
      },
      {
        title: '% of Units',
        order: null,
      },
      {
        title: '# of Leases',
        order: null,
      },
      {
        title: '%',
        classes: 'header-darker',
        order: null,
      },
      {
        title: 'Leases',
        classes: 'header-darker',
        order: null,
      },
      {
        title: 'Variance to # of Units',
        classes: 'header-darker',
        order: null,
      },
    ],
  },
];
