import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.App.ViewBankDeposit.ViewBankDepositDocuments.Title',
    defaultMessage: 'Deposit Documents',
  },
  date: {
    id: 'App.ViewBankDeposit.ViewBankDepositDocuments.Date',
    defaultMessage: 'Date',
  },
  name: {
    id: 'App.ViewBankDeposit.ViewBankDepositDocuments.Name',
    defaultMessage: 'Name',
  },
  notes: {
    id: 'App.ViewBankDeposit.ViewBankDepositDocuments.Notes',
    defaultMessage: 'Notes',
  },
  actions: {
    id: 'App.App.ViewBankDeposit.ViewBankDepositDocuments.Actions',
    defaultMessage: 'Actions',
  },
  addDocument: {
    id: 'App.App.ViewBankDeposit.ViewBankDepositDocuments.AddDocument',
    defaultMessage: 'Add Document',
  },
  confirmDelete: {
    id: 'App.App.ViewBankDeposit.ViewBankDepositDocuments.ConfirmDelete',
    defaultMessage: 'Are you sure you want to delete?',
  },
  attachmentDeleteSuccessTitle: {
    id:
      'App.App.ViewBankDeposit.ViewBankDepositDocuments.AttachmentDeleteSuccessTitle',
    defaultMessage: 'Deposit attachment deleted',
  },
  attachmentDeleteSuccessMessage: {
    id:
      'App.App.ViewBankDeposit.ViewBankDepositDocuments.AttachmentDeleteSuccessMessage',
    defaultMessage: 'The deposit attachment was deleted successfully.',
  },
  attachmentDeleteErrorTitle: {
    id:
      'App.App.ViewBankDeposit.ViewBankDepositDocuments.AttachmentDeleteErrorTitle',
    defaultMessage: 'Error deleting deposit attachment',
  },
  attachmentDownloadErrorTitle: {
    id:
      'App.App.ViewBankDeposit.ViewBankDepositDocuments.AttachmentDownloadErrorTitle',
    defaultMessage: 'Error downloading deposit attachment',
  },
  attachmentsLoadingErrorTitle: {
    id:
      'App.App.ViewBankDeposit.ViewBankDepositDocuments.AttachmentsLoadingErrorTitle',
    defaultMessage: 'Error loading deposit attachments',
  },
});

export default messages;
