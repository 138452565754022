import React, { useState } from 'react';
import { Button, Col, Row, Checkbox } from 'react-bootstrap';
import moment from 'moment';

import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';

type Props = {
  openResidentsLetterModal: Function,
  documentName: string,
  communicationBatchGenerated: boolean,
  selectedResidents: Array<Object>,
  isLoading: boolean,
  isViewCommunication: boolean,
  recipientsAmount?: number,
  currentCommunicationCreationDate?: any,
  toggleEmailLetter: Function,
  wasLetterEmailed: boolean,
};

const LetterInput = (props: Props) => {
  const [emailLetter, setEmailLetter] = useState(props.wasLetterEmailed);

  return (
    <div className="panel unit-information panel-withheader">
      <div className="panel-head">
        <Row>
          <Col xs={12} sm={6}>
            <h2>Letters</h2>
          </Col>
        </Row>
      </div>
      <div className="panel__details">
        <Row>
          <Col xs={6} sm={3} md={2} className="padtop20">
            <label>Letter Template</label>
          </Col>
          <Col xs={6} sm={3} md={2}>
            <Button
              className="btn-tertiary"
              onClick={props.openResidentsLetterModal}
              disabled={
                props.isViewCommunication ||
                props.isLoading ||
                props.communicationBatchGenerated
              }
            >
              <span>Choose</span>
            </Button>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={8}
            className="name-display check-status-indicator padtop20"
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs="auto">
                <span
                  className={`prosp-status-icon ${
                    props.documentName
                      ? 'prosp-status-green'
                      : 'prosp-status-grey'
                  }`}
                >
                  <i className="icon et-isolated-check" />
                </span>
              </Grid>
              <Grid item xs>
                {props.documentName ? (
                  <h4 className="prosp-name" style={{ marginBottom: 0 }}>
                    {props.documentName}
                  </h4>
                ) : (
                  <h4 className="prosp-name" style={{ marginBottom: 0 }}>
                    Not Selected
                  </h4>
                )}
              </Grid>
            </Grid>
          </Col>
        </Row>
        <Row className="padbottom20">
          <Col xs={12}>
            <label>Send letter via email once generated?</label>
            <Checkbox
              value={emailLetter}
              checked={emailLetter}
              className="custom-checkbox flying-checkbox"
              onChange={() => {
                props.toggleEmailLetter(!emailLetter);
                setEmailLetter(!emailLetter);
              }}
              disabled={
                props.isViewCommunication ||
                props.isLoading ||
                props.communicationBatchGenerated
              }
            >
              <span className="custom-check-square" />
            </Checkbox>
          </Col>
        </Row>
        {!props.isViewCommunication ? (
          props.communicationBatchGenerated && (
            <Row>
              <Col md={12}>
                <div className="alert alert-success">
                  Letters have been generated for{' '}
                  {props.selectedResidents.length} recipients
                </div>
              </Col>
            </Row>
          )
        ) : (
          <Row>
            <Col md={12}>
              <div className="alert alert-success">
                Letter has been generated for{' '}
                {
                  props.selectedResidents.filter(
                    (resident) => resident.communicationStatus === 'GENERATED',
                  ).length
                }{' '}
                recipient(s) on{' '}
                {moment(props.currentCommunicationCreationDate).format('LLLL')}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default LetterInput;
