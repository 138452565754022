import { useQuery } from 'react-query';
import ProspectStatusService from '../../services/prospectStatusService';

function useProspectStatuses() {
  const { data, isLoading } = useQuery(
    ['prospectStatuses'],
    () => {
      const service = new ProspectStatusService();

      return service.getAll();
    },
    {
      staleTime: 60000,
    },
  );

  return {
    prospectStatuses: data ?? [],
    isLoading,
  };
}

export default useProspectStatuses;
