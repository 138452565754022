import { useMemo } from 'react';
import { useQuery } from 'react-query';

import AffordableService from '../../services/affordableService';

export const useComplianceApprovalStatuses = (
  reactQueryOptions?: Object,
): Array => {
  const propertyService = useMemo(() => new AffordableService(), []);

  const defaultReactQueryOptions = useMemo(
    () => ({
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      initialData: [],
    }),
    [],
  );

  const { data, isLoading } = useQuery(
    ['compliance-approval-statuses'],
    async (): Promise<Array> => propertyService.getAllComplianceStatuses(),
    { ...defaultReactQueryOptions, ...reactQueryOptions },
  );

  return [data, isLoading];
};
