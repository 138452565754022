import { useController } from 'react-hook-form';
import { Select as SelectComponentLibrary } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import React from 'react';

function SingleSelect(props) {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  return (
    <Stack>
      <SelectComponentLibrary
        {...props}
        error={error}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        name={field.name}
        inputRef={field.ref}
        fullWidth
      />
      {error && error.message}
    </Stack>
  );
}

export default SingleSelect;
