import * as R from 'ramda';
import * as stringHelpers from './string-helpers';

export const removeTimestamp = (fileName: string): string => {
  const lastIndexOfUnderscore = fileName.lastIndexOf('_');

  if (lastIndexOfUnderscore < 0) return fileName;

  const lastIndexOfDot = fileName.lastIndexOf('.');
  const nameWOExtension = fileName.substr(0, lastIndexOfUnderscore);
  const extension = fileName.substring(lastIndexOfDot);
  return `${nameWOExtension}${extension}`;
};

export const removeFilePath = (fileUrl: string): string => {
  return stringHelpers.substrAfterLastCharIndex(fileUrl, '/');
};

export const getFileNameWithNoTimestamp = R.pipe(
  removeFilePath,
  removeTimestamp,
);
