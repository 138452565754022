import moment from 'moment';
import { equals, isEmpty, pick } from 'ramda';

import type { Property } from './types';
import type { PropertyGroup, OrderValue } from '../App/types';

import generalMessages from '../App/messages';
import { PROPERTY_COMMERCIAL_FLOORPLANS } from './AddEditPropertyGroup/constants';

export const parsePropertyGroups = (
  propertyGroups: Array<PropertyGroup>,
  intl: Object,
): Array<Object> => {
  return propertyGroups.map((pg) => {
    const createdAt = moment(pg?.createdAt);
    const createdBy = `${pg?.createdBy?.firstName ?? ''} ${
      pg?.createdBy?.lastName ?? ''
    }`.trim();
    const updatedAt = moment(pg?.updatedAt);
    const updatedBy = `${pg?.updatedBy?.firstName ?? ''} ${
      pg?.updatedBy?.lastName ?? ''
    }`.trim();
    return {
      id: pg?.id,
      name: pg?.name ?? '',
      description: pg?.description ?? '',
      numProperties: `${pg?.propertyIds?.length ?? 0}`,
      createdAtDate: createdAt.format('MM-DD-YYYYTHH:mm:ss'),
      createdByOn: intl.formatMessage(generalMessages.dateByOn, {
        by: createdBy,
        date: createdAt.format('MMM D, YYYY @ h:mm A'),
      }),
      lastModifiedAtDate: updatedAt.format('MM-DD-YYYYTHH:mm:ss'),
      lastModifiedByOn: intl.formatMessage(generalMessages.dateByOn, {
        by: updatedBy,
        date: updatedAt.format('MMM D, YYYY @ h:mm A'),
      }),
    };
  });
};

export const sortPropertyGroups = (
  propertyGroups: Array<Object>,
  currentSorting: OrderValue,
  locale: string,
): Array<Object> => {
  const { fieldName, order } = currentSorting;
  return propertyGroups.sort((a, b) => {
    const field = ['createdByOn', 'lastModifiedByOn'].includes(fieldName)
      ? fieldName.replace('ByOn', 'AtDate')
      : fieldName;
    const aField = a?.[field] ?? '';
    const bField = b?.[field] ?? '';
    const sortAsc = order === 'ASC';
    return (sortAsc ? aField : bField).localeCompare(
      sortAsc ? bField : aField,
      locale ?? 'en',
      {
        ignorePunctuation: true,
        numeric: true,
        sensitivity: 'base',
      },
    );
  });
};

export const parseProperties = (
  properties: Array<Object>,
  intl: Object,
): Array<Property> => {
  return properties.reduce((acc, property) => {
    const isCommercial = [
      PROPERTY_COMMERCIAL_FLOORPLANS.ALL,
      PROPERTY_COMMERCIAL_FLOORPLANS.SOME,
    ].includes(property?.hasCommercialFloorPlans);
    const propertyClassId = isCommercial
      ? 'commercial'
      : property?.propertyClass?.id;
    const propertyClass = isCommercial
      ? intl?.formatMessage(generalMessages.commercial) ?? 'Commercial'
      : property?.propertyClass?.name;
    return [
      ...acc,
      {
        ...pick(['id', 'name'], property),
        propertyClassId,
        propertyClass,
      },
    ];
  }, []);
};

export const areAllPropertiesSelected = (
  properties: Array<Property> = [],
  selected: Array<string> = [],
) => {
  const isSameLength = properties.length === selected.length;
  const itemIds = properties.map((property) => property.id);

  return !isEmpty(properties) && isSameLength && equals(itemIds, selected);
};
