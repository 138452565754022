import { pathOr } from 'ramda';

export const generateAdverseActionLetterOptions = (
  adverserActionLetterOptions: Array<Object>,
  locale: string,
): Array<Object> => {
  return adverserActionLetterOptions
    .map((opt) => {
      const value = pathOr('', ['id'], opt);
      const name = pathOr('', ['name'], opt);
      const label = pathOr(undefined, ['translations', locale], opt) || name;
      return {
        name,
        value,
        label,
        disabled: false,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const generateConditionsForApprovalOptions = (
  conditionsForApproval: Array<Object>,
  locale: string,
): Array<Object> => {
  const cfa = conditionsForApproval.map((opt) => {
    const value = opt?.id || '';
    const text =
      pathOr(undefined, ['translations', locale], opt) || opt?.text || '';
    return {
      text,
      value,
    };
  });
  return cfa;
};

export const reduceAdverseActionLetterSelectedOptions = (
  selectedAdverserActionLetterOptions: Array<Object>,
): Array<string> =>
  (selectedAdverserActionLetterOptions || []).map((opt) => opt.value);
