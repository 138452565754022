import React from 'react';
import { Field } from 'redux-form';

import {
  renderSelectField,
  renderFlexNumberField,
  renderTextAreaField,
} from '../../../../../../utils/redux-form-helper';

import { DAYS_MONTHS_SELECT_OPTIONS } from '../../constants';

import { FlexFormInput, FlexFormInputFull } from '../../common';

const ContractionOptionsFormFields = ({ values }: Object) => {
  return (
    <React.Fragment>
      <FlexFormInput>
        <Field
          name="Notice Period Type"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Notice Period Type"
          options={DAYS_MONTHS_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Notice Period"
          component={renderFlexNumberField}
          label="Notice Period"
          max="1000"
          min="0"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Response Deadline Type"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Response Deadline Type"
          options={DAYS_MONTHS_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Response Deadline"
          component={renderFlexNumberField}
          label="Response Deadline"
          className="input form-control"
          max="1000"
          min="0"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInputFull>
        <Field
          name="Details"
          component={renderTextAreaField}
          label="Details"
          rows="3"
          maxLength="1000"
          showLabel
        />
      </FlexFormInputFull>
    </React.Fragment>
  );
};

export default ContractionOptionsFormFields;
