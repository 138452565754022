import React from 'react';
import { Field } from 'redux-form';
import messages from './messages';
import { renderPhoneField, phoneParser, phoneFormatter } from '../../../../utils/redux-form-helper';

type Props = {
  intl: any
};

const PhoneNumber = ({ intl }: Props) => (
  <section>
    <Field
      className="input-lg"
      name="phoneNumber"
      component={renderPhoneField}
      placeholder={intl.formatMessage(messages.placeholder)}
      parse={phoneParser}
      format={phoneFormatter}
    />
  </section>
);

export default PhoneNumber;
