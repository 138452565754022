import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { curryN, isNil, cond, equals, always, anyPass, T } from 'ramda';
import { getAllReports } from '../ManageReports/actions';
import DelinquencyDetails from './DelinquencyDetails';
import FilterDrawer from '../../components/FilterDrawer';
import ManageLayout from '../../components/ManageLayout';
import SearchActions from '../../components/SearchActions';
import messages from './messages';
import { HEADER_ROWS, FILTERS } from './constants';
import { getAllDelinquencies, cleanLoadedDelinquencies } from './actions';
import type { FilterValue, OrderValue, Property } from '../App/types';
import * as utils from './utils';
import { createActivity } from '../../components/CreateActivityModal';
import ActivityTypeService from '../../services/activityTypeService';
import { getProspectAssigneesOptions } from '../App/selectors';
import { getAllActivityTypes, getAllAssignees } from '../App/actions';
import peopleProfileMessages from '../PeopleProfile/messages';
import ActivityService from '../../services/activityService';

type State = {
  currentPage: number,
  limit: number,
  searchText: string,
  currentSorting: OrderValue,
  currentFilter: FilterValue,
  appliedFilter: FilterValue,
  showFilter: boolean,
};

type DefaultProps = {
  headerRows: Array<Object>,
};

type StateProps = {
  currentSorting: any,
  delinquencies: Array<Object>,
  organizationId: any,
  reports: Array<Object>,
  selectedProperty: Property,
  loading: boolean,
  redirectedFromHomeKpi: boolean,
};

type Props = StateProps & {
  actions: Object,
  intl: Object,
};

class ManageDeliquency extends Component<DefaultProps & Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentPage: 1,
      limit: 25,
      searchText: '',
      showFilter: false,
      currentFilter: utils.getDefaultFilters(props.redirectedFromHomeKpi),
      appliedFilter: utils.getDefaultFilters(props.redirectedFromHomeKpi),
      currentSorting: () => ({ ...props.currentSorting }()),
    };
  }

  componentDidMount() {
    this.props.actions.getAllDelinquencies();
    this.props.actions.getAllReports(this.props.currentSorting);
    this.props.actions.getAllActivityTypes();
    this.props.actions.getAllAssignees();

    window.document.addEventListener('keydown', this._handleEscKey);
  }

  // We want the "Clear" button to reset all filters to be unchecked...
  getClearedFilters = () => {
    return {
      balances: {},
      statuses: {
        occupiedNoNotice: false,
        occupiedOnNotice: false,
        applicant: false,
        priorResident: false,
      },
      eviction: {},
    };
  };

  componentWillUnmount() {
    this.props.actions.cleanLoadedDelinquencies();
    window.document.removeEventListener('keydown', this._handleEscKey);
  }

  hasActiveFiltersCheck = () => {
    const allValues = {
      ...this.state.appliedFilter.balances,
      ...this.state.appliedFilter.statuses,
      ...this.state.appliedFilter.eviction,
    };
    const filterValues = Object.values(allValues);
    return filterValues.includes(true);
  };

  toggleFilter = (show: boolean) => {
    this.setState({
      showFilter: show,
    });
  };

  _handleEscKey = (event: any) => {
    if (event.keyCode === 27) {
      this.toggleFilter(false);
    }
  };

  onApplyFilterClick = () => {
    const { balances, statuses, eviction } = this.state.currentFilter;
    this.setState({
      appliedFilter: {
        balances: { ...balances },
        statuses: { ...statuses },
        eviction: { ...eviction },
      },
    });
    this.toggleFilter(false);
  };

  clearFilters = () => {
    this.setState({
      currentFilter: this.getClearedFilters(),
      appliedFilter: this.getClearedFilters(),
    });
  };

  onFilterChange = (
    field: string,
    value: string,
    { target: { checked } }: Object,
  ) => {
    const currentFilter = this.state.currentFilter;
    const filteredField = currentFilter[field] || {};
    filteredField[value] = checked;
    currentFilter[field] = filteredField;
    this.setState({
      currentFilter,
    });
  };

  handleSort = ({ fieldName, order }: OrderValue) => {
    const currentSorting = { fieldName, order };
    this.setState({ currentSorting });
  };

  handleSubmit = ({ searchText }: Object) => {
    this.setState({ searchText });
  };

  handleDownloadExport = async (reportId: string, fileType: 'csv' | 'pdf') => {
    const { organizationId, selectedProperty } = this.props;
    const { id: propertyId, name: propertyName } = selectedProperty;

    return utils.handleDownload({
      fileType,
      organizationId,
      propertyId,
      propertyName,
      reportId,
      query: {
        searchText: this.state.searchText,
        sorting: this.state.currentSorting,
      },
      filterValues: this.state.appliedFilter,
    });
  };

  postNewActivity = async (newActivity: Object): Promise<void> => {
    const { organizationId, intl } = this.props;
    try {
      const activityService = new ActivityService();

      await activityService.save(newActivity, organizationId);

      this.props.actions.getAllDelinquencies();

      toastr.success(
        intl.formatMessage(peopleProfileMessages.successHeader),
        intl.formatMessage(
          peopleProfileMessages.successActivityCreateDescription,
        ),
      );
    } catch (err) {
      toastr.error(
        intl.formatMessage(peopleProfileMessages.errorHeader),
        err.toString(),
      );
    }
  };

  handleCreateActivityAction = async (id: string) => {
    const {
      delinquencies = [],
      activityTypes,
      intl,
      locale,
      users,
    } = this.props;
    const delinquency = delinquencies.find((d) => d.customerId === id);

    if (!delinquency) return;

    const activityTypeService = new ActivityTypeService();
    const activityTypesList = activityTypeService.generateActivityTypesList({
      activityTypes,
      intl,
      locale,
    });
    const prospectInfo = {
      id: delinquency.prospectId,
      firstName: delinquency.firstName,
      lastName: delinquency.lastName,
      createdAt: delinquency.prospectCreatedAt,
    };

    const getStageProps = cond([
      [
        equals('Applicant'),
        always({ stage: 'applicant', urlId: delinquency.applicationId }),
      ],
      [
        anyPass([equals('Current Resident'), equals('Prior Resident')]),
        always({ stage: 'resident', urlId: delinquency.residentId }),
      ],
      [
        anyPass([equals('Prospect'), T]),
        always({ stage: 'prospect', urlId: delinquency.prospectId }),
      ],
    ]);
    const stageProps = getStageProps(delinquency.customerStatus);

    const newActivity = await createActivity({
      intl,
      activityTypesList,
      store: this.context.store,
      prospect: prospectInfo,
      assigneeList: users,
      showViewProfileBtn: true,
      ...stageProps,
    });

    this.postNewActivity({
      ...newActivity,
      customerStatus: delinquency.customerStatus ?? 'Prospect',
    });
  };

  render() {
    const { delinquencies, headerRows, intl, reports, loading } = this.props;

    const { formatMessage } = intl;

    const { headers, rows, getFooter } = utils.buildDelinquencyTable({
      intl,
      delinquentRows: delinquencies,
      allHeaders: headerRows,
      searchText: this.state.searchText,
      currentSorting: this.state.currentSorting,
      appliedFilter: this.state.appliedFilter,
    });

    const onFilterChangeCurried = curryN(3, this.onFilterChange);

    const reportId =
      reports.find((report) => report?.referenceId === 'Delinquent-Prepaid')
        ?.id ?? '';

    const searchActionsProps = {
      filterButton: {
        hasActiveFilters: this.hasActiveFiltersCheck(),
        onClick: this.toggleFilter,
      },
      searchField: {
        form: 'ManageDelinquency',
        placeholder: formatMessage(messages.search),
        onSubmit: this.handleSubmit,
      },
      csvDownloadButton: {
        disabled: rows.length === 0,
        onClick: () => this.handleDownloadExport(reportId, 'csv'),
      },
      pdfDownloadButton: {
        disabled: rows.length === 0,
        onClick: () => this.handleDownloadExport(reportId, 'pdf'),
      },
    };
    return (
      <ManageLayout
        title={
          <div className="col-xs-12 col-md-6">
            <h1>
              <FormattedMessage {...messages.manageDelinquency} />
            </h1>
          </div>
        }
        actions={
          <div className="col-xs-12 col-md-6">
            <SearchActions {...searchActionsProps} />
          </div>
        }
      >
        <div className="table-scroll table-units-container">
          <FilterDrawer
            filters={FILTERS}
            onFilterChange={onFilterChangeCurried}
            onCloseClick={this.toggleFilter}
            onApplyClick={this.onApplyFilterClick}
            show={this.state.showFilter}
            clearFilters={this.clearFilters}
            currentFilter={this.state.currentFilter}
            formatMessage={formatMessage}
          />
          <DelinquencyDetails
            rows={rows}
            headerRows={headers}
            name="PriorResidents"
            onSort={this.handleSort}
            footer={getFooter}
            loading={loading}
            onCreateActivity={this.handleCreateActivityAction}
          />
        </div>
      </ManageLayout>
    );
  }
}

ManageDeliquency.contextTypes = {
  store: PropTypes.any,
};

ManageDeliquency.defaultProps = {
  headerRows: HEADER_ROWS,
};

export const mapStateToProps = (state: Object, ownProps: Object): Object => {
  const {
    app: { currentUser, selectedProperty, activityTypes },
    manageDelinquencies: { delinquencies, loading },
    manageReports: { currentSorting, reports },
    languageProvider: { locale },
  } = state;
  const customFilter = ownProps.location.search.replace('?', '').split('&');
  const redirectedFromHomeKpi = !isNil(
    customFilter.find((filter) => filter.includes('redirectedFrom=home-kpi')),
  );
  return {
    redirectedFromHomeKpi,
    currentSorting,
    delinquencies,
    selectedProperty,
    reports,
    organizationId: currentUser ? currentUser.user.organizationId : '',
    loading,
    locale,
    activityTypes,
    users: getProspectAssigneesOptions(state),
  };
};

export const mapDispatchToProps = (dispatch: any): Object => {
  const actions = bindActionCreators(
    {
      getAllDelinquencies,
      getAllReports,
      cleanLoadedDelinquencies,
      getAllActivityTypes,
      getAllAssignees,
    },
    dispatch,
  );
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ManageDeliquency));
