import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.CreateCommercialApplicant.Title',
    defaultMessage: 'Add Commercial Applicant',
  },
  requiredFields: {
    id: 'App.CreateCommercialApplicant.RequiredFields',
    defaultMessage: '(*) Required fields',
  },
  cancel: {
    id: 'App.CreateCommercialApplicant.Cancel',
    defaultMessage:
      'Are you sure you want to discard the information on the form?',
  },
  save: {
    id: 'App.CreateCommercialApplicant.Save',
    defaultMessage:
      'Are you sure you want to create this commercial applicant?',
  },
  success: {
    id: 'App.CreateCommercialApplicant.Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'App.CreateCommercialApplicant.Error',
    defaultMessage: 'Error',
  },
  successBody: {
    id: 'App.CreateCommercialApplicant.successBody',
    defaultMessage: 'Commercial applicant created',
  },
  errorBody: {
    id: 'App.CreateCommercialApplicant.errorBody',
    defaultMessage: 'Error creating commercial applicant',
  },
});

export default messages;
