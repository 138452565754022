import {
  validatePositiveMoney,
  validatePositiveNumber,
  validateRange,
  validateName,
} from '../../../../utils/validations';

import {
  BASE_AMOUNT_OPTIONS,
  EXPENSE_CAPS_OPTIONS,
  PRO_RATA_SHARE_OPTIONS,
  GROSS_UP_OPTIONS,
  LEASE_ALLOWS_MONTHLY_EST_OPTIONS,
  ERR_NO_AMOUNT,
  ERR_INVALID_AMOUNT,
  ERR_NO_VALUE,
  ERR_INVALID_VALUE,
  ERR_NO_OPTION_SELECTED,
  GL_ACCOUNTS_OPTIONS,
} from './constants';

const validate = (values: Object, props: Object) => {
  const errors = {};

  const {
    exceptionName,
    glAccountsOptions,
    selectedGLAccounts,
    baseAmountOptions,
    baseYear,
    baseYearAmount,
    expenseCapsOptions,
    expenseCapAmount,
    expenseCapPercentageAggregate,
    expenseCapLeaseStartYearAggregate,
    expenseCapPercentageCumulative,
    expenseCapLeaseStartYearCumulative,
    expenseCapPercentageCompounded,
    expenseCapLeaseStartYearCompounded,
    ownerStopExpenseAmount,
    proRataShareOptions,
    proRataCustomPercentage,
    grossUpOptions,
    grossUpYesPercentage,
    grossUpYesAvgOccupancy,
    subjournal,
    chargeCode,
    reconciliationSubjournal,
    reconciliationChargeCode,
    leaseAllowsMonthlyEstimatesOptions,
  } = values;

  const { allocationSetupModalMode } = props;

  if (
    allocationSetupModalMode === 'Exception' &&
    !validateName(exceptionName)
  ) {
    errors['exceptionName'] = ERR_NO_VALUE;
  }

  // Must pick atleast one GL account for an exception
  if (
    allocationSetupModalMode === 'Exception' &&
    !(selectedGLAccounts && selectedGLAccounts.length > 0)
  ) {
    errors['selectedGLAccounts'] = 'Please select GL Accounts';
  }

  // If "None" GL Accounts selected, the rest of the form is not needed
  if (glAccountsOptions === GL_ACCOUNTS_OPTIONS.NONE) {
    return errors;
  }

  // Base Amount
  if (baseAmountOptions === BASE_AMOUNT_OPTIONS.BASE_YEAR) {
    // Base Year
    if (!baseYear) {
      errors['baseYear'] = ERR_NO_VALUE;
    } else if (!validateRange(baseYear, 1900, 2200)) {
      errors['baseYear'] = ERR_INVALID_VALUE;
    }

    // Base year amount
    if (!baseYearAmount) {
      errors['baseYearAmount'] = ERR_NO_AMOUNT;
    } else if (!validatePositiveMoney(baseYearAmount)) {
      errors['baseYearAmount'] = ERR_INVALID_AMOUNT;
    }
  } else if (baseAmountOptions === BASE_AMOUNT_OPTIONS.OWNER_STOP_EXPENSE) {
    // Owner Stop Expense Amount;
    if (!ownerStopExpenseAmount) {
      errors['ownerStopExpenseAmount'] = ERR_NO_AMOUNT;
    } else if (!validatePositiveMoney(ownerStopExpenseAmount)) {
      errors['ownerStopExpenseAmount'] = ERR_INVALID_AMOUNT;
    }
  } else if (baseAmountOptions !== BASE_AMOUNT_OPTIONS.NONE) {
    // Means that no option is selected for Base Amount
    errors['baseAmountOptions'] = ERR_NO_OPTION_SELECTED;
  }

  // Expense Caps
  if (expenseCapsOptions === EXPENSE_CAPS_OPTIONS.YOY) {
    if (!expenseCapAmount) {
      errors['expenseCapAmount'] = ERR_NO_AMOUNT;
    } else if (!validatePositiveMoney(expenseCapAmount)) {
      errors['expenseCapAmount'] = ERR_INVALID_AMOUNT;
    }
  } else if (expenseCapsOptions === EXPENSE_CAPS_OPTIONS.AGGREGATE) {
    if (!expenseCapPercentageAggregate) {
      errors['expenseCapPercentageAggregate'] = ERR_NO_VALUE;
    } else if (!validatePositiveNumber(expenseCapPercentageAggregate)) {
      errors['expenseCapPercentageAggregate'] = ERR_INVALID_VALUE;
    }

    if (!expenseCapLeaseStartYearAggregate) {
      errors['expenseCapLeaseStartYearAggregate'] = ERR_NO_VALUE;
    } else if (!validateRange(expenseCapLeaseStartYearAggregate, 1900, 2200)) {
      errors['expenseCapLeaseStartYearAggregate'] = ERR_INVALID_VALUE;
    }
  } else if (expenseCapsOptions === EXPENSE_CAPS_OPTIONS.CUMULATIVE) {
    if (!expenseCapPercentageCumulative) {
      errors['expenseCapPercentageCumulative'] = ERR_NO_VALUE;
    } else if (!validatePositiveNumber(expenseCapPercentageCumulative)) {
      errors['expenseCapPercentageCumulative'] = ERR_INVALID_VALUE;
    }

    if (!expenseCapLeaseStartYearCumulative) {
      errors['expenseCapLeaseStartYearCumulative'] = ERR_NO_VALUE;
    } else if (!validateRange(expenseCapLeaseStartYearCumulative, 1900, 2200)) {
      errors['expenseCapLeaseStartYearCumulative'] = ERR_INVALID_VALUE;
    }
  } else if (expenseCapsOptions === EXPENSE_CAPS_OPTIONS.COMPOUNDED) {
    if (!expenseCapPercentageCompounded) {
      errors['expenseCapPercentageCompounded'] = ERR_NO_VALUE;
    } else if (!validatePositiveNumber(expenseCapPercentageCompounded)) {
      errors['expenseCapPercentageCompounded'] = ERR_INVALID_VALUE;
    }

    if (!expenseCapLeaseStartYearCompounded) {
      errors['expenseCapLeaseStartYearCompounded'] = ERR_NO_VALUE;
    } else if (!validateRange(expenseCapLeaseStartYearCompounded, 1900, 2200)) {
      errors['expenseCapLeaseStartYearCompounded'] = ERR_INVALID_VALUE;
    }
  } else if (expenseCapsOptions !== EXPENSE_CAPS_OPTIONS.NONE) {
    // Means that no option is selected for Expense Caps
    errors['expenseCapsOptions'] = ERR_NO_OPTION_SELECTED;
  }

  // Pro Rata Share
  if (proRataShareOptions === PRO_RATA_SHARE_OPTIONS.CUSTOM) {
    if (!proRataCustomPercentage) {
      errors['proRataCustomPercentage'] = ERR_NO_VALUE;
    } else if (!validateRange(proRataCustomPercentage, 0, 100)) {
      errors['proRataCustomPercentage'] = ERR_INVALID_VALUE;
    }
  } else if (proRataShareOptions !== PRO_RATA_SHARE_OPTIONS.ACTUAL) {
    errors['proRataShareOptions'] = ERR_NO_OPTION_SELECTED;
  }

  // Gross Up
  if (grossUpOptions === GROSS_UP_OPTIONS.YES) {
    if (!grossUpYesPercentage) {
      errors['grossUpYesPercentage'] = ERR_NO_VALUE;
    } else if (!validateRange(grossUpYesPercentage, 0, 100)) {
      errors['grossUpYesPercentage'] = ERR_INVALID_VALUE;
    }
    if (!grossUpYesAvgOccupancy) {
      errors['grossUpYesAvgOccupancy'] = ERR_NO_VALUE;
    } else if (!validateRange(grossUpYesAvgOccupancy, 0, 100)) {
      errors['grossUpYesAvgOccupancy'] = ERR_INVALID_VALUE;
    }
  } else if (grossUpOptions !== GROSS_UP_OPTIONS.NO) {
    errors['grossUpOptions'] = ERR_NO_OPTION_SELECTED;
  }

  // Subjournal
  if (!subjournal) {
    errors['subjournal'] = ERR_NO_OPTION_SELECTED;
  }

  // Charge code
  if (!chargeCode) {
    errors['chargeCode'] = ERR_NO_OPTION_SELECTED;
  }

  // Subjournal (reconciliation)
  if (!reconciliationSubjournal) {
    errors['reconciliationSubjournal'] = ERR_NO_OPTION_SELECTED;
  }

  // Charge Code (reconciliation)
  if (!reconciliationChargeCode) {
    errors['reconciliationChargeCode'] = ERR_NO_OPTION_SELECTED;
  }

  // Lease allows for monthly estimates
  if (
    ![
      LEASE_ALLOWS_MONTHLY_EST_OPTIONS.YES,
      LEASE_ALLOWS_MONTHLY_EST_OPTIONS.NO,
    ].includes(leaseAllowsMonthlyEstimatesOptions)
  ) {
    errors['leaseAllowsMonthlyEstimatesOptions'] = ERR_NO_OPTION_SELECTED;
  }
  return errors;
};

export default validate;
