import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageCommunications.Title',
    defaultMessage: 'Fortress - Manage Communications',
  },
  errorHeader: {
    id: 'App.ManageCommunications.ErrorHeader',
    defaultMessage: 'Error',
  },
  generateConfirmation: {
    id: 'App.ManageCommunications.generateConfirmation',
    defaultMessage: 'Are you sure you want to generate this communication?',
  },
});

export default messages;
