import React from 'react';
import { FormattedMessage } from 'react-intl';

import Table from '../../../components/Table';
import Row from '../../../components/Table/Row';
import Data from '../../../components/Table/Data';
import Spinner from '../../../components/Spinner';
import messages from '../messages';
import ActionsMenu from './ActionsMenu';

type Props = {
  rows: Array<Object>,
  headerRows: Array<Object>,
  name: string,
  onSort: Function,
  footer: Function,
  loading: boolean,
  onCreateActivity: (id: string) => void,
};

export const ManageDelinqencyDetails = ({
  rows,
  headerRows,
  name,
  onSort,
  footer,
  loading,
  onCreateActivity,
}: Props): any => {
  return (
    <Table name={name} headerRows={headerRows} onSort={onSort} footer={footer}>
      {!loading ? (
        rows && rows.length > 0 ? (
          rows.map((row: Object, key: number): any => (
            <Row key={key} className="table-row" data-test="delinquency-row">
              {row.columns.map((column: any, key: number) => (
                <Data key={key}>{column}</Data>
              ))}
              <Data className="dropdown" key="actions">
                <ActionsMenu
                  onCreateActivity={() => {
                    onCreateActivity?.(row.id);
                  }}
                />
              </Data>
            </Row>
          ))
        ) : (
          <Row key={1} className="table-row">
            <Data colSpan={24}>
              <FormattedMessage {...messages.noDataAvailable} />
            </Data>
          </Row>
        )
      ) : (
        <Row key={1} className="table-row">
          <Data colSpan={24}>
            <Spinner />
          </Data>
        </Row>
      )}
    </Table>
  );
};

export default ManageDelinqencyDetails;
