import {
  validateRequired,
  validatePositiveMoney,
} from '../../../utils/validations';
import moment from 'moment';
import messages from '../messages';
import { type, pathOr } from 'ramda';

const validate = (
  values: Object,
  { intl, transactionTypes, propertyFiscalPeriod }: Object,
) => {
  const { transactionType, subjournal, amount, date, applyToCamPool } = values;
  const selectedDate = date ? moment(date) : moment().startOf('day');
  const fiscalPeriodStartDate = moment(
    pathOr(null, ['startDate'], propertyFiscalPeriod),
  );
  const today = moment().startOf('day');
  const errors = {};

  ['subjournal'].forEach((fieldName) => {
    const selectedType = transactionTypes.find((type) => {
      return type.id === transactionType;
    });
    if (
      subjournal === 'default' &&
      selectedType &&
      selectedType.hasSubjournals
    ) {
      errors[fieldName] = intl.formatMessage(messages.subjournalRequired);
    }
  });

  ['transactionType', 'code', 'date'].forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      errors[fieldName] = intl.formatMessage(messages.required);
    }
  });
  ['amount'].forEach((fieldName) => {
    if (!validatePositiveMoney(amount)) {
      errors[fieldName] = intl.formatMessage(messages.validNumber);
    }
    if (parseFloat(amount) <= 0) {
      errors[fieldName] = intl.formatMessage(messages.validNumber);
    }
  });

  if (selectedDate.isValid() && type(values.date) !== 'String') {
    if (
      selectedDate.isValid() &&
      selectedDate.isBefore(fiscalPeriodStartDate, 'day')
    ) {
      errors.date = intl.formatMessage(messages.validDatePast);
    } else if (selectedDate.isValid() && selectedDate.isAfter(today, 'day')) {
      errors.date = intl.formatMessage(messages.validDateFuture);
    }
  } else {
    errors.date = intl.formatMessage(messages.validDate);
  }

  if (applyToCamPool === true) {
    ['camRecord', 'camPool'].forEach((fieldName) => {
      if (!validateRequired(fieldName, values)) {
        errors[fieldName] = intl.formatMessage(messages.required);
      }
    });
  }

  return errors;
};

export default validate;
