import { defineMessages } from 'react-intl';

const messages = defineMessages({
  incomeVerification: {
    id: 'App.Qualification.IncomeVerificationForm.IncomeVerification',
    defaultMessage: 'Income Verification for',
  },
  incomeHeaderNote: {
    id: 'App.Qualification.IncomeHeaderNote',
    defaultMessage:
      'Please indicate each source of income that any member of your household (18 years or older) receives or anticipates receiving in the next 12 months.', // eslint-disable-line
  },
  employment: {
    id: 'App.Qualification.IncomeVerificationForm.Employment',
    defaultMessage: 'Employment / Anticipated Employment',
  },
  employmentForms: {
    id: 'App.Qualification.IncomeVerificationForm.EmploymentForms',
    defaultMessage: 'VOE or AONE & applicable documentation',
  },
  selfEmployment: {
    id: 'App.Qualification.IncomeVerificationForm.SelfEmployment',
    defaultMessage: 'Self-Employment',
  },
  selfEmploymentForms: {
    id: 'App.Qualification.IncomeVerificationForm.SelfEmploymentForms',
    defaultMessage: 'AOSE & applicable documentation',
  },
  militaryPay: {
    id: 'App.Qualification.IncomeVerificationForm.MilitaryPay',
    defaultMessage: 'Military Pay',
  },
  militaryPayForms: {
    id: 'App.Qualification.IncomeVerificationForm.MilitaryPayForms',
    defaultMessage: 'VOMP',
  },
  notWorking: {
    id: 'App.Qualification.IncomeVerificationForm.NotWorking',
    defaultMessage: 'Not Working',
  },
  notWorkingForms: {
    id: 'App.Qualification.IncomeVerificationForm.NotWorkingForms',
    defaultMessage: 'AONE',
  },
  unemploymentBenefits: {
    id: 'App.Qualification.IncomeVerificationForm.UnemploymentBenefits',
    defaultMessage: 'Unemployment Benefits',
  },
  unemploymentBenefitsForms: {
    id: 'App.Qualification.IncomeVerificationForm.UnemploymentBenefitsForms',
    defaultMessage: 'VOUB & AONE',
  },
  socialSecurity: {
    id: 'App.Qualification.IncomeVerificationForm.SocialSecurity',
    defaultMessage: 'Social Security, SSD',
  },
  socialSecurityForms: {
    id: 'App.Qualification.IncomeVerificationForm.socialSecurityForms',
    defaultMessage: 'VOSSB or SS Award Letter (EIV for HUD only)',
  },
  supplementalSecurity: {
    id: 'App.Qualification.IncomeVerificationForm.supplementalSecurityIncome',
    defaultMessage: 'Supplemental Security Income (SSI)',
  },
  supplementalSecurityForms: {
    id: 'App.Qualification.IncomeVerificationForm.supplementalSecurityIncomeForms',
    defaultMessage: 'VOSSB or SS Award Letter (EIV for HUD only)',
  },
  vaBenefits: {
    id: 'App.Qualification.IncomeVerificationForm.VaBenefits',
    defaultMessage: 'V.A. Benefits',
  },
  vaBenefitsForms: {
    id: 'App.Qualification.IncomeVerificationForm.VaBenefitsForms',
    defaultMessage: 'VOVAB',
  },
  tanf: {
    id: 'App.Qualification.IncomeVerificationForm.TANF',
    defaultMessage: 'TANF (AFDC)',
  },
  tanfForms: {
    id: 'App.Qualification.IncomeVerificationForm.TANFForms',
    defaultMessage: 'VOTANF',
  },
  disability: {
    id: 'App.Qualification.IncomeVerificationForm.Disability',
    defaultMessage: 'Disability, Worker’s Comp.',
  },
  disabilityForms: {
    id: 'App.Qualification.IncomeVerificationForm.disabilityForms',
    defaultMessage: 'VODOWC & AONE',
  },
  recurringGift: {
    id: 'App.Qualification.IncomeVerificationForm.RecurringGift',
    defaultMessage: 'Recurring Gift',
  },
  recurringGiftForms: {
    id: 'App.Qualification.IncomeVerificationForm.RecurringGiftForms',
    defaultMessage: 'AORG',
  },
  retirement: {
    id: 'App.Qualification.IncomeVerificationForm.Retirement',
    defaultMessage: 'Regular Pmts from Retirement Acct.',
  },
  retirementForms: {
    id: 'App.Qualification.IncomeVerificationForm.RetirementForms',
    defaultMessage: 'VORA',
  },
  trust: {
    id: 'App.Qualification.IncomeVerificationForm.Trust',
    defaultMessage: 'Regular Pmts from Trust Account',
  },
  trustForms: {
    id: 'App.Qualification.IncomeVerificationForm.TrustForms',
    defaultMessage: 'VOB',
  },
  absentFamilyMember: {
    id: 'App.Qualification.IncomeVerificationForm.AbsentFamilyMember',
    defaultMessage: 'Income from Temporarily Absent Family Member',
  },
  absentFamilyMemberForms: {
    id: 'App.Qualification.IncomeVerificationForm.AbsentFamilyMemberForms',
    defaultMessage: 'Applicable documentation',
  },
  otherForms: {
    id: 'App.Qualification.IncomeVerificationForm.OtherForms',
    defaultMessage: 'Applicable documentation',
  },
  courtOrderedChildSupport: {
    id: 'App.Qualification.IncomeVerificationForm.CourtOrderedChildSupport',
    defaultMessage:
      'Do you have court ordered or an agreement for child support?',
  },
  courtOrderedChildSupportText: {
    id: 'App.Qualification.IncomeVerificationForm.CourtOrderedChildSupportText',
    defaultMessage: 'Amount Ordered:',
  },
  receivingChildSupport: {
    id: 'App.Qualification.IncomeVerificationForm.ReceivingChildSupport',
    defaultMessage: 'Are you receiving the ordered child support?',
  },
  receivingChildSupportText: {
    id: 'App.Qualification.IncomeVerificationForm.ReceivingChildSupportText',
    defaultMessage: 'Amount Receiving:',
  },
  receivingChildSupportForms: {
    id: 'App.Qualification.IncomeVerificationForm.ReceivingChildSupportForms',
    defaultMessage: 'VOCSA, AOCSAP, AONCSA or other',
  },
  collectChildSupport: {
    id: 'App.Qualification.IncomeVerificationForm.CollectChildSupport',
    defaultMessage: 'Have reasonable efforts been made to collect?',
  },
  anticipateChildSupport: {
    id: 'App.Qualification.IncomeVerificationForm.AnticipateChildSupport',
    defaultMessage: 'Do you anticipate child support?',
  },
  anticipateChildSupportForms: {
    id: 'App.Qualification.IncomeVerificationForm.AnticipateChildSupportForms',
    defaultMessage: 'AONE',
  },
  housingAssistance: {
    id: 'App.Qualification.IncomeVerificationForm.HousingAssistance',
    defaultMessage: 'Housing Assistance (Voucher Holder)',
  },
  housingAssistanceForms: {
    id: 'App.Qualification.IncomeVerificationForm.HousingAssistanceForms',
    defaultMessage: 'VOS8',
  },
  housingAssistanceText: {
    id: 'App.Qualification.IncomeVerificationForm.HousingAssistanceText',
    defaultMessage: 'If yes, Public Housing Authority:',
  },
  publicHousing: {
    id: 'App.Qualification.IncomeVerificationForm.PublicHousing',
    defaultMessage: 'Public Housing',
  },
  publicHousingForms: {
    id: 'App.Qualification.IncomeVerificationForm.PublicHousingForms',
    defaultMessage: 'VOS8',
  },
  publicHousingText: {
    id: 'App.Qualification.IncomeVerificationForm.PublicHousingText',
    defaultMessage: 'If yes, Public Housing Authority:',
  },
  county: {
    id: 'App.Qualification.IncomeVerificationForm.County',
    defaultMessage: 'County',
  },
  typeOfIncome: {
    id: 'App.Qualification.IncomeVerificationForm.TypeOfIncome',
    defaultMessage: 'Type of Income',
  },
  receiving: {
    id: 'App.Qualification.IncomeVerificationForm.Receiving',
    defaultMessage: 'Currently Receiving or Anticipates Receiving',
  },
  no: {
    id: 'App.Qualification.IncomeVerificationForm.No',
    defaultMessage: 'No',
  },
  yes: {
    id: 'App.Qualification.IncomeVerificationForm.Yes',
    defaultMessage: 'Yes',
  },
  householdMemberName: {
    id: 'App.Qualification.IncomeVerificationForm.HouseholdMemberName',
    defaultMessage: 'If Yes, Household Member Name',
  },
  annualGrossReceiving: {
    id: 'App.Qualification.IncomeVerificationForm.AnnualGrossReceiving',
    defaultMessage: 'Annual Gross $ Amount Receiving',
  },
  requiredForm: {
    id: 'App.Qualification.IncomeVerificationForm.RequiredForm',
    defaultMessage: 'If Yes, Required Form',
  },
  totalMonthlyIncome: {
    id: 'App.Qualification.IncomeVerificationForm.TotalMonthlyIncome',
    defaultMessage: 'Total Monthly Income',
  },
  totalAnnualIncome: {
    id: 'App.Qualification.IncomeVerificationForm.TotalAnnualIncome',
    defaultMessage: 'Total Annual Income',
  },
  saveIncomeInformation: {
    id: 'App.Qualification.IncomeVerificationForm.SaveIncomeInformation',
    defaultMessage: 'Save Income Information',
  },
  hapPaymentText: {
    id: 'App.Qualification.IncomeVerificationForm.HapPaymentText',
    defaultMessage: 'Housing Assistance Payment (HAP) $',
  },
  residentPaymentText: {
    id: 'App.Qualification.IncomeVerificationForm.ResidentPaymentText',
    defaultMessage: 'Resident Payment $',
  },
  amountRequired: {
    id: 'App.Qualification.IncomeVerificationForm.AmountRequired',
    defaultMessage: 'Amount Required',
  },
  stateAndCountyRequired: {
    id: 'App.Qualification.IncomeVerificationForm.StateAndCountyRequired',
    defaultMessage: 'State and County Required',
  },
  sourceRequired: {
    id: 'App.Qualification.IncomeVerificationForm.SourceRequired',
    defaultMessage: 'Source Required',
  },
  publicHousingAuthorityRequired: {
    id: 'App.Qualification.IncomeVerificationForm.PublicHousingAuthorityRequired',
    defaultMessage: 'Public Housing Authority Required',
  },
  mustBePositive: {
    id: 'App.Qualification.IncomeVerificationForm.MustBePositive',
    defaultMessage: 'Amount Must Be Positive',
  },
  state: {
    id: 'App.Qualification.IncomeVerificationForm.State',
    defaultMessage: 'State / Province',
  },
  payFrequency: {
    id: 'App.Qualification.IncomeVerificationForm.PayFrequency',
    defaultMessage: 'Pay Frequency',
  },
  payDataPayFrequencyLabel: {
    id: 'App.Qualification.IncomeVerificationForm.PayDataPayFrequencyLabel',
    defaultMessage: 'Pay Frequency',
  },
  payDataPayRateLabel: {
    id: 'App.Qualification.IncomeVerificationForm.PayDataPayRateLabel',
    defaultMessage: 'Pay Rate',
  },
  payDataHoursWeeksMonthsLabel: {
    id: 'App.Qualification.IncomeVerificationForm.PayDataHoursWeeksMonthsLabel',
    defaultMessage: 'Hours/\u200bWeeks/\u200bMonths',
  },
  unearnedIncome17AndUnder: {
    id: 'App.Qualification.IncomeVerificationForm.UnearnedIncome17AndUnder',
    defaultMessage: 'Unearned Income (17 and Under)',
  },
  incomeFromOnlineSources: {
    id: 'App.Qualification.IncomeVerificationForm.IncomeFromOnlineSources',
    defaultMessage: 'Income From Online Sources',
  },
  pension: {
    id: 'App.Qualification.IncomeVerificationForm.Pension',
    defaultMessage: 'Pension',
  },
});

export default messages;
