import { isNil } from 'ramda';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Styled from 'styled-components';

import messages from './messages';

const StyledModal = Styled(Modal)`
  .modal-sm .modal-header {
    border: none;
    padding: 0;
  }

  .container-fluid > .row {
    min-height: 280px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }

  .row.ia-value [class*="col-x"]:first-child {
    text-align: end;
  }

  .row.ia-value [class*="col-x"]:last-child {
    text-align: start;
  }
`;

export const FloorplanEditConfirmation = ({
  incomeAveragingPercent,
  projectedIncomeAvg,
  floorplans,
  show,
  handleSubmit,
  onSubmit,
  onClose,
  reset,
}: Object) => {
  const projectedIncomeAvgClass =
    +projectedIncomeAvg <= +(incomeAveragingPercent || 100)
      ? 'text-success'
      : 'text-danger';
  const projectedIncomeAvgText = isNil(projectedIncomeAvg)
    ? '---'
    : `${projectedIncomeAvg}%`;
  const requiredIncomeAvgText = isNil(incomeAveragingPercent)
    ? '---'
    : `${incomeAveragingPercent}%`;

  return (
    <StyledModal
      className="modal transaction"
      backdrop
      bsSize="sm"
      show={show}
      onHide={onClose}
    >
      <div className="modal-content">
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <h2>
                <FormattedMessage {...messages.floorplanIaConfirmation} />
              </h2>
              <h4>
                <FormattedMessage {...messages.iaChangeDescription} />
              </h4>
              <div className="row ia-value">
                <div className="col-xs-6">
                  <p>
                    <FormattedMessage {...messages.iaWithChanges} />
                  </p>
                </div>
                <div className="col-xs-6">
                  <p className={projectedIncomeAvgClass}>
                    {projectedIncomeAvgText}
                  </p>
                </div>
              </div>
              <div className="row ia-value">
                <div className="col-xs-6">
                  <p>
                    <FormattedMessage {...messages.requiredIa} />
                  </p>
                </div>
                <div className="col-xs-6">
                  <p>{requiredIncomeAvgText}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-xs-6">
              <button
                className="btn btn-primary pull-right"
                type="submit"
                onClick={handleSubmit}
              >
                <FormattedMessage {...messages.yes} />
              </button>
            </div>
            <div className="col-xs-6">
              <button className="btn btn-default pull-left" onClick={onClose}>
                <FormattedMessage {...messages.no} />
              </button>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </StyledModal>
  );
};
