import { defineMessages } from 'react-intl';

/* eslint-disable max-len */
const messages = defineMessages({
  leaseLockedSentToPortal: {
    id: 'App.EditOccupantModal.Commercial.LeaseLockedSentToPortal',
    defaultMessage:
      'A Lease for this household has been sent to Portal. If you need to edit the information below, please go to the Lease Data tab and cancel the outstanding Lease.',
  },
  leaseLockedExecuted: {
    id: 'App.EditOccupantModal.Commercial.lockedSentToPortal',
    defaultMessage:
      'A Lease for this household has been Executed. If you need to edit the information below, please go to the Lease Data tab and cancel the outstanding Lease.',
  },
});

export default messages;
