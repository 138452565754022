import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewUnit.GeneralUnitInformation.Title',
    defaultMessage: 'General Unit Information',
  },
  ADAUnit: {
    id: 'App.ViewUnit.GeneralUnitInformation.ADAUnit',
    defaultMessage: 'ADA Unit',
  },
  marketingDescription: {
    id: 'App.ViewUnit.GeneralUnitInformation.MarketingDescription',
    defaultMessage: 'Marketing Description',
  },
  grossSqFt: {
    id: 'App.ViewUnit.GeneralUnitInformation.GrossSqFt',
    defaultMessage: 'Gross SqFt',
  },
  unitAddress: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitAddress',
    defaultMessage: 'Unit Address',
  },
  unitEditButton: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitEditButton',
    defaultMessage: 'Edit',
  },
  unitLevel: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitLevel',
    defaultMessage: 'Unit Level',
  },
  unitFloorLevel: {
    id: 'App.ViewUnit.GeneralUnitInformation.UnitFloorLevel',
    defaultMessage: 'Unit Floor Level',
  },
  baseMarketRentAmount: {
    id: 'App.ViewUnit.GeneralUnitInformation.BaseMarketRentAmount',
    defaultMessage: 'Floorplan Market Rent Amount',
  },
  quotingRentAmount: {
    id: 'App.ViewUnit.GeneralUnitInformation.QuotingRentAmount',
    defaultMessage: 'Floorplan Quoting Rent Amount',
  },
  estimatedDepositAmount: {
    id: 'App.ViewUnit.GeneralUnitInformation.EstimatedDepositAmount',
    defaultMessage: 'Estimated Deposit Amount',
  },
  sqFt: {
    id: 'App.ViewUnit.GeneralUnitInformation.SqFt',
    defaultMessage: 'SqFt',
  },
  buildingName: {
    id: 'App.ViewUnit.GeneralUnitInformation.BuildingName',
    defaultMessage: 'Building',
  },
  xmlAltUnitNumber: {
    id: 'App.ViewUnit.GeneralUnitInformation.xmlAltUnitNumber',
    defaultMessage: 'Alternative Unit Name (XML)',
  },
});

export default messages;
