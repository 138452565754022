import { Card } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import Spinner from '../../../components/Spinner';

function LoadingCard() {
  return (
    <Card>
      <Spinner />
    </Card>
  );
}

export default LoadingCard;
