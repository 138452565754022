import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';

import { renderRadioOption } from '../../utils/redux-form-helper';

type Props = {
  store: Object,
  report: Object,
};

const TypeRangesMap = {
  runByPeriod: { value: 'period', text: 'Period' },
  runByDate: { value: 'range', text: 'Range' },
  runByOneDate: { value: 'date', text: 'Date' },
};

export function TypeRanges(props: Props) {
  return (
    <Row>
      {Object.entries(TypeRangesMap).reduce((acc, [key, option]) => {
        if (props.report[key]) {
          acc.push(
            <Col key={key} xs={2}>
              <Field
                store={props.store}
                name="typeRange"
                fieldName="typeRange"
                option={option}
                component={renderRadioOption}
                bsSize="lg"
                inline
              />
            </Col>,
          );
        }

        return acc;
      }, [])}
    </Row>
  );
}
