import type { Action } from '../App/types';
import type { ManageFloorPlanState } from './types';
import * as ActionTypes from './constants';
import moment from 'moment';

const initialState: ManageFloorPlanState = {
  floorPlans: [],
  floorPlansLoading: true,
  smartPrices: [],
  smartPricesLoading: false,
  smartPricesLastUpdated: null,
};

const manageFloorPlansReducer = (
  state: ManageFloorPlanState = initialState,
  action: Action<*, *>,
): ManageFloorPlanState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ALL_FLOOR_PLANS: {
      return {
        ...state,
        floorPlansLoading: true,
      };
    }
    case ActionTypes.GET_ALL_FLOOR_PLANS_FAIL: {
      return {
        ...state,
        floorPlans: [],
        floorPlansLoading: false,
      };
    }
    case ActionTypes.CLEAR_ALL_FLOOR_PLANS: {
      return {
        ...state,
        floorPlans: [],
        floorPlansLoading: false,
        smartPricesLoading: false,
        smartPrices: [],
        smartPricesLastUpdated: null,
      };
    }
    case ActionTypes.GET_ALL_FLOOR_PLANS_SUCCESS: {
      return { ...state, floorPlans: payload, floorPlansLoading: false };
    }

    case ActionTypes.GET_ALL_SMART_PRICES: {
      return {
        ...state,
        smartPricesLoading: true,
      };
    }
    case ActionTypes.GET_ALL_SMART_PRICES_FAIL: {
      return {
        ...state,
        smartPrices: [],
        smartPricesLoading: false,
        smartPricesLastUpdated: moment().format('MM/DD/YYYY'),
      };
    }

    case ActionTypes.GET_ALL_SMART_PRICES_SUCCESS: {
      const results = payload.results || [];
      const smartPrices = results.map((smartPrice) => ({
        floorPlanName: smartPrice.unit_type,
        recommendedPrice: smartPrice.recommended_price
          ? smartPrice.recommended_price.toFixed(0)
          : 0,
      }));
      const lastUpdate = moment(payload.lastUpdated).format('MM/DD/YYYY');

      return {
        ...state,
        smartPrices: smartPrices,
        smartPricesLoading: false,
        smartPricesLastUpdated: lastUpdate,
      };
    }

    default:
      return state;
  }
};

export default manageFloorPlansReducer;
