import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

function CentralizedManageDelinquency() {
  return (
    <Box>
      <Typography variant="h1">Delinquency and Prepaid</Typography>
    </Box>
  );
}

export default CentralizedManageDelinquency;
