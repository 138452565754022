import { defineMessages } from 'react-intl';

const messages = defineMessages({
  leaseBasicsLabel: {
    id: 'App.LeaseDataTabForm.LeaseBasicsLabel',
    defaultMessage: 'Lease Basics',
  },
  unitNumberLabel: {
    id: 'App.LeaseDataTabForm.UnitNumberLabel',
    defaultMessage: 'Unit Number',
  },
  floorPlanLabel: {
    id: 'App.LeaseDataTabForm.LeaseBasics.FloorPlanLabel',
    defaultMessage: 'Floor Plan',
  },
  lateMethodLabel: {
    id: 'App.LeaseDataTabForm.LeaseBasics.LateMethodLabel',
    defaultMessage: 'Late Method',
  },
  basicLeaseFeesLabel: {
    id: 'App.LeaseDataTabForm.LeaseBasics.BasicLeaseFeesLabel',
    defaultMessage: 'Basic Lease Fees',
  },
  numberOfKeysLabel: {
    id: 'App.LeaseDataTabForm.LeaseBasics.NumberOfKeysLabel',
    defaultMessage: '# of Keys ',
  },
  numberOfSatellites: {
    id: 'App.LeaseDataTabForm.LeaseBasics.NumberOfSattelitesApproved',
    defaultMessage: '# of Satellites Approved',
  },
  scheduledMoveInLabel: {
    id: 'App.LeaseDataTabForm.LeaseBasics.ScheduledMoveInLabel',
    defaultMessage: 'Scheduled Move-In ',
  },
  leaseTermLabel: {
    id: 'App.LeaseDataTabForm.LeaseBasics.LeaseTermLabel',
    defaultMessage: 'Lease Term ',
  },
  leaseStartDateLabel: {
    id: 'App.LeaseDataTabForm.LeaseBasics.LeaseStartDateLabel',
    defaultMessage: 'Lease Start Date',
  },
  leaseEndDateLabel: {
    id: 'App.LeaseDataTabForm.LeaseBasics.LeaseEndDateLabel',
    defaultMessage: 'Lease End Date',
  },
  overrideLeaseExpirationLimitLabel: {
    id: 'App.LeaseDataTabForm.LeaseBasics.OverrideLeaseExpirationLimitLabel',
    defaultMessage: 'Override Expiration Limit',
  },
  overrideLeaseExpirationLimitMessage: {
    id: 'App.LeaseDataTabForm.LeaseBasics.OverrideLeaseExpirationLimitMessage',
    defaultMessage:
      'Lease Expiration Limit Violation approved: by {firstName} {lastName} on {date}',
  },
});

export default messages;
