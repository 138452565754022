import type { Option } from '../../types';

export const getPeriodOptions = (periods) => {
  const options = periods.map(({ period }: Object): Option => {
    return {
      value: period,
      text: period,
    };
  });
  options.unshift({
    value: '',
    text: 'Choose',
    disabled: true,
  });
  return options;
};
