import { isEmpty, isNil } from 'ramda';
import { validateDate } from '../../utils/validations';

const validate = (values: Object, props: Object) => {
  const errors = {};

  const moveTypeMsg = props.moveType === 'moveIn' ? 'Move-In' : 'Move-Out';

  const { editMoveDate } = values;

  /**
   * Required Fields
   */
  if (isNil(editMoveDate) || isEmpty(editMoveDate)) {
    errors['editMoveDate'] = `${moveTypeMsg} Date is required.`;
  }

  /**
   * Valid Dates
   */
  if (!errors['editMoveDate'] && !validateDate(editMoveDate)) {
    errors[
      'editMoveDate'
    ] = `${moveTypeMsg} Date must be a valid date format (MM/DD/YYYY).`;
  }

  if (props.additionalValidation) {
    const errMsg = props.additionalValidation(editMoveDate);
    if (errMsg) {
      errors['editMoveDate'] = errMsg;
    }
  }

  return errors;
};

export default validate;
