import { defineMessages } from 'react-intl';

const messages = defineMessages({
  applicantsCurrentEmployerTitle: {
    id: 'App.RecertificationApplication.ApplicantsCurrentEmployerTitle',
    defaultMessage: "Applicant's Current Employer", // eslint-disable-line
  },
  applicantsSecondCurrentEmployerTitle: {
    id: 'App.RecertificationApplication.ApplicantsSecondCurrentEmployerTitle',
    defaultMessage: "Applicant's 2nd Current Employer (If Applicable)", // eslint-disable-line
  },
  applicantsPreviousEmployerTitle: {
    id: 'App.RecertificationApplication.ApplicantsPreviousEmployerTitle',
    defaultMessage: "Applicant's Previous Employer", // eslint-disable-line
  },
  streetAddressLabel: {
    id: 'App.RecertificationApplication.StreetAddressLabel',
    defaultMessage: 'Employer Street Address',
  },
  from: {
    id: 'App.RecertificationApplication.from',
    defaultMessage: 'From',
  },
  startDate: {
    id: 'App.RecertificationApplication.startDate',
    defaultMessage: 'Start Date',
  },
  cancelConfirmation: {
    id: 'App.RecertificationApplication.CancelConfirmation',
    defaultMessage: 'Are you sure you want to cancel?',
  },
  completeConfirmation: {
    id: 'App.RecertificationApplication.CompleteConfirmation',
    defaultMessage: 'Are you sure you want to complete this application?',
  },
  recertCompleteConfirmation: {
    id: 'App.RecertificationApplication.RecertCompleteConfirmation',
    defaultMessage: 'Are you sure you want to complete this recertification?',
  },
  miniApplicationCompleteConfirmation: {
    id: 'App.RecertificationApplication.MiniApplicationCompleteConfirmation',
    defaultMessage: 'Are you sure you want to complete this mini application?',
  },
});

export default messages;
