const { get } = require('../utils/api');

export default class ProrateMethodsService {
  getAll(
    organizationId: string,
    propertyId: string,
    householdId: string,
    signal?: Object,
  ) {
    const options = signal ? { signal } : {};
    return get('/prorate-methods', options);
  }

  getMoveOutProrateMethods() {
    return get('/prorate-methods/move-out');
  }
}
