/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  RDSetupTitle: {
    id: 'App.ViewProperty.AffordableSetup.RDSetup.RDSetupTitle',
    defaultMessage: 'RD',
  },
  RDProjectNumber: {
    id: 'App.ViewProperty.AffordableSetup.RDSetup.RDProjectNumber',
    defaultMessage: 'RD Project Number',
  },
  RDProgramType: {
    id: 'App.ViewProperty.AffordableSetup.RDSetup.RDProgramType',
    defaultMessage: 'RD Program Type',
  },
  RDProjectName: {
    id: 'App.ViewProperty.AffordableSetup.RDSetup.RDProjectName',
    defaultMessage: 'RD Project Name',
  },
  RDProjectType: {
    id: 'App.ViewProperty.AffordableSetup.RDSetup.RDProjectType',
    defaultMessage: 'RD Project Type',
  },
  RDBorrowerId: {
    id: 'App.ViewProperty.AffordableSetup.RDSetup.RDBorrowerId',
    defaultMessage: 'Borrower ID',
  },
  RDRentalAssistance: {
    id: 'App.ViewProperty.AffordableSetup.RDSetup.RDRentalAssistance',
    defaultMessage: 'Rental Assistance (RA)',
  },
});

export default messages;
