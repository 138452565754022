import React from 'react';

import type { Props } from '../types';

export const StepsCompleted = ({ steps }: Props) => {
  const getStatus = (status: Object): string => {
    return status.valid
      ? 'status status-ball status-green'
      : 'status status-ball status-red';
  };

  return (
    <>
      <h3>STEPS COMPLETED: </h3>
      <div className="checklist-small">
        <ul>
          {steps.map((step, index) => (
            <li key={index}>
              <div className="item-property" data-test="renewal-step-title">
                {step.title}
              </div>
              <div
                className="item-value text-right"
                data-test="renewal-step-status"
              >
                {step.statusText}
                <span
                  className={getStatus(step)}
                  data-test="renewal-step-color"
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
