import moment from 'moment';

export const calcLeaseTerm = (
  leaseEndDate,
  commencementDate,
  changeLeaseTermCalcCommercialFlag,
) => {
  if (changeLeaseTermCalcCommercialFlag) {
    const months = Math.ceil(
      moment(leaseEndDate).diff(moment(commencementDate), 'months', true),
    );
    return months !== 0 && months ? months.toString() + ' Months' : months;
  }
  const date = moment(commencementDate).format('D');
  const lastDayMonth = moment(leaseEndDate).endOf('month');
  const months = moment(leaseEndDate).diff(commencementDate, 'months');
  const isLastDay = moment(lastDayMonth).isSame(leaseEndDate, 'day')
    ? true
    : false;
  /**
   * If the start date is the first day of any month AND the end date is the last day of any month,
   * then count all of the months in the range.
   */
  // For ex: Start date: 1/1/2020    End date: 12/31/2020 = 12 months
  if (date === '1' && isLastDay) {
    return (months + 1).toString() + ' Months';
  }
  /**
   * If the start date is anything but the 1st of any month,
   * then start counting the next month up to and including the month of the end date.
   */
  // For ex: Start date: 1/13/2020  End date: 12/15/2020 = 11 months
  return months !== 0 && months ? months.toString() + ' Months' : months;
};
