import React from 'react';
import moment from 'moment';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import {
  renderSelectField,
  renderRadioButton,
  renderCurrencyField,
  renderTextField,
  renderDateField,
  extractCurrentDateFormat,
  renderPercentageField,
  renderCheckboxField,
} from '../../../utils/redux-form-helper';
import type {
  RadioOptions,
  SelectionColumnProps,
  IncomeAssetCurrencyFieldProps,
  YesNoProps,
  InterestRateFieldProps,
} from '../types';
import messages from '../messages';

const RenderYesNoField = ({
  name,
  value,
  noClicked,
  disabled,
}: RadioOptions) => (
  <div className="col-xs-6 text-center">
    <Field
      name={name}
      fieldName={name}
      option={{ value }}
      component={renderRadioButton}
      onChange={noClicked}
      disabled={disabled}
    />
  </div>
);

export const CheckBox = ({
  name,
  colSize,
  label,
  disabled,
  onChange,
  checked,
}: {
  name: string,
  colSize: string | number,
  label: string,
  disabled?: boolean,
  onChange?: Function,
  checked?: boolean,
}) => {
  return (
    <Field
      name={name}
      component={renderCheckboxField}
      key={name}
      label={label}
      onChange={onChange}
      disabled={disabled}
      checked={checked}
    />
  );
};

export const YesNoColumns = ({
  name,
  noClicked,
  yesClicked = () => {},
  isPrior,
  colSize,
}: YesNoProps) => {
  const colClass = colSize ? `col-xs-${colSize}` : 'col-xs-2';
  return (
    <div className={colClass}>
      <div className="row">
        <RenderYesNoField
          name={name}
          value="no"
          noClicked={noClicked}
          disabled={isPrior}
        />
        <RenderYesNoField
          name={name}
          value="yes"
          disabled={isPrior}
          noClicked={yesClicked}
        />
      </div>
    </div>
  );
};

export const IncomeAssetCurrencyField = ({
  name,
  type,
  disabled,
  label,
  colSize,
  onBlur,
}: IncomeAssetCurrencyFieldProps) => {
  const defaultColSize = type === 'Income' ? '3' : '2';
  const styleCols = colSize ? colSize : defaultColSize;
  return (
    <div className={`col-xs-${styleCols}`}>
      {label ? <label>{label}</label> : null}
      <Field
        name={`${name}${type}`}
        component={renderCurrencyField}
        className={'input-lg'}
        step="0.01"
        disabled={disabled}
        onBlur={onBlur}
      />
    </div>
  );
};

export const InterestRateField = ({
  name,
  disabled,
  colSize,
  type = 'InterestRate',
  onBlur,
}: InterestRateFieldProps) => {
  const defaultColSize = '2';
  const styleCols = colSize ? colSize : defaultColSize;
  return (
    <div className={`col-xs-${styleCols}`}>
      <Field
        name={`${name}${type}`}
        component={renderPercentageField}
        className={'input-lg'}
        step="0.01"
        disabled={disabled}
        onBlur={onBlur}
      />
    </div>
  );
};

export const ApplicantSelectionColumn = ({
  name,
  options,
  type,
  disabled,
}: SelectionColumnProps) => {
  return type === 'applicants' ? (
    <div className="col-xs-2" />
  ) : (
    <div className="col-xs-2">
      <label>
        <FormattedMessage {...messages.state} />
      </label>
      <Field
        className="input-md"
        name={`${name}State`}
        component={renderSelectField}
        options={options}
        maxLength="35"
        disabled={disabled}
      />
    </div>
  );
};

export const SelectionColumn = ({
  name,
  type,
  options,
  disabled,
  placeholder,
  colSize,
}: SelectionColumnProps) => {
  const styleCols = colSize ? colSize : '2';
  return (
    <div className={`col-xs-${styleCols}`}>
      <Field
        className="input-md"
        name={`${name}${type}`}
        component={renderSelectField}
        options={options}
        maxLength="35"
        disabled={disabled}
        placeholder={placeholder}
        height={'40px'}
        style={{
          height: '40px',
        }}
      />
    </div>
  );
};

export const SecurityClaimNumberRow = ({
  name,
  disabled,
  intl,
  textOffset,
  textCol,
  inputOffset,
  inputCol,
}: Object) => {
  // Allows for custom bs col sizes
  const textOffsetSize = textOffset ? textOffset : '4';
  const textColSize = textCol ? textCol : '2';
  const inputOffsetClassName = inputOffset
    ? `col-xs-offset-${inputOffset}`
    : '';
  const inputColSize = inputCol ? textOffset : '3';
  return (
    <div className="col-xs-12">
      <div className={`col-xs-offset-${textOffsetSize} col-xs-${textColSize}`}>
        {intl.formatMessage(messages.ssnBenefitsClaimNumber)}
      </div>
      <div className={`${inputOffsetClassName} col-xs-${inputColSize}`}>
        <Field
          name={name}
          component={renderTextField}
          className={'input-lg'}
          disabled={disabled}
          placeholder={intl.formatMessage(messages.ssnBenefitsPlaceholder)}
        />
      </div>
    </div>
  );
};

export const AssetDisposedDateRow = ({
  disabled,
  intl,
  colOffset,
  colSize,
}: Object) => {
  const offset = colOffset || '5';
  const col = colSize || '2';
  const isValidDate = (date) => {
    const today = moment();
    const threeYearsPrior = moment().subtract(3, 'years');
    return date.isBetween(threeYearsPrior, today, null, '[]');
  };
  return (
    <div className="col-xs-12">
      <div className="row">
        <div className={`col-xs-offset-${offset} col-xs-${col}`}>
          <Field
            name="disposedDate"
            component={renderDateField}
            disabled={disabled}
            label="Disposed Date"
            placeholder={extractCurrentDateFormat(intl)}
            dateFormat={extractCurrentDateFormat(intl)}
            bsSize="lg"
            isValidDate={isValidDate}
          />
        </div>
      </div>
    </div>
  );
};
