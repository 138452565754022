import { defineMessages } from 'react-intl';

const messages = defineMessages({
  createSubsidyBatch: {
    id: 'App.CreateSubsidyBatchHeader.CreateSubsidyBatch',
    defaultMessage: 'Create Subsidy Batch',
  },
  viewSubsidyBatch: {
    id: 'App.CreateSubsidyBatchHeader.ViewSubsidyBatch',
    defaultMessage: 'View Subsidy Batch',
  },
  batchId: {
    id: 'App.CreateSubsidyBatchHeader.batchId',
    defaultMessage: 'Batch ID:',
  },
  period: {
    id: 'App.CreateSubsidyBatchHeader.Period',
    defaultMessage: 'Period:',
  },
  subsidyDepositDate: {
    id: 'App.CreateSubsidyBatchHeader.subsidyDepositDate',
    defaultMessage: 'Subsidy Deposit Date',
  },
  subsidyDepositTotal: {
    id: 'App.CreateSubsidyBatchHeader.subsidyDepositTotal',
    defaultMessage: 'Subsidy Deposit Total',
  },
  documentNumber: {
    id: 'App.CreateSubsidyBatchHeader.DocumentNumber',
    defaultMessage: 'Document Number',
  },
  note: {
    id: 'App.CreateSubsidyBatchHeader.Note',
    defaultMessage: 'Note',
  },
  cancel: {
    id: 'App.CreateSubsidyBatchHeader.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.CreateSubsidyBatchHeader.Save',
    defaultMessage: 'Save',
  },
  editBatchDetails: {
    id: 'App.CreateSubsidyBatchHeader.EditBatchDetails',
    defaultMessage: 'Edit Batch Details',
  },
});

export default messages;
