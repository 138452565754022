import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { pathOr } from 'ramda';

const formatCurrency = (value: number | null, intl: Object) =>
  value !== null
    ? intl.formatNumber(+value, {
        style: 'currency',
        currency: 'USD',
      })
    : '';

const LedgerRow = ({ title, description, cost, lastRow, intl }: Object) => {
  let rowStyles = {
    padding: '0 0 20px 0',
  };
  if (lastRow) {
    rowStyles = {
      ...rowStyles,
      marginBottom: '20px',
      borderBottom: '1px solid #999',
    };
  }
  const noContent = !title && !description && !cost;
  const descriptionStyles = { color: '#9B9B9B' };
  const costStyles = { textAlign: 'right' };
  return (
    <Row className="col-xs-10" style={rowStyles}>
      {noContent ? (
        <Col xs={12}>&nbsp;</Col>
      ) : (
        [
          <Col key={0} xs={4}>
            {title}
          </Col>,
          <Col key={1} xs={6} style={descriptionStyles}>
            {description}
          </Col>,
          <Col key={2} xs={2} style={costStyles}>
            {formatCurrency(cost, intl)}
          </Col>,
        ]
      )}
    </Row>
  );
};

const TotalChargedRow = ({ amount, intl }) => {
  return (
    <Row className="col-xs-10" style={{ fontWeight: 500 }}>
      <Col xs={10}>Total to be charged:</Col>
      <Col xs={2} style={{ textAlign: 'right' }}>
        {formatCurrency(amount, intl)}
      </Col>
    </Row>
  );
};

const ApplicationFee = ({
  field,
  propertyTransactionCode,
  feeApplies,
  change,
  applicationFees,
  intl,
}: Object) => {
  const applicationDescriptions = pathOr(
    [],
    ['resident', 'application', 'descriptions'],
    applicationFees,
  ).join(', ');
  const applicationFee = pathOr(
    0,
    ['resident', 'application', 'amount'],
    applicationFees,
  );
  const adminDescriptions = pathOr(
    [],
    ['resident', 'admin', 'descriptions'],
    applicationFees,
  ).join(', ');
  const adminFee = pathOr(0, ['resident', 'admin', 'amount'], applicationFees);
  const depositFee = pathOr(
    0,
    ['security', 'deposit', 'amount'],
    applicationFees,
  );
  const depositAltFee = pathOr(
    0,
    ['resident', 'depositAlternative', 'amount'],
    applicationFees,
  );
  const residentLedgerTotal = +applicationFee + +adminFee + +depositAltFee;
  const securityLedgerTotal = +depositFee;
  return (
    <Row className="container-fluid">
      <div className="col-xs-6">
        <h2>Resident Ledger</h2>
        <LedgerRow
          title="Application Fees:"
          description={applicationDescriptions}
          cost={+parseFloat(applicationFee)}
          intl={intl}
        />
        <LedgerRow
          title="Admin Fees:"
          description={adminDescriptions}
          cost={+adminFee}
          intl={intl}
        />
        <LedgerRow
          title="Deposit Alternative:"
          description=""
          cost={+depositAltFee}
          intl={intl}
          lastRow
        />
        <TotalChargedRow amount={+residentLedgerTotal} intl={intl} />
      </div>
      <div className="col-xs-6">
        <h2>Security Ledger</h2>
        <LedgerRow
          title="Deposit:"
          description=""
          cost={+depositFee}
          intl={intl}
        />
        <LedgerRow />
        <LedgerRow lastRow />
        <TotalChargedRow amount={+securityLedgerTotal} intl={intl} />
      </div>
    </Row>
  );
};

export default ApplicationFee;
