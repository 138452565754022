import React from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { renderRadioGroupField } from '../../../../utils/redux-form-helper';
import { generateReconciliationType } from './utils';
import Divider from '../../../../components/Common/Divider';
import Spinner from '../../../../components/Spinner';
import { pathOr } from 'ramda';

type Props = {
  handleSubmit: Function,
  onSubmit: Function,
  pristine: boolean,
  valid: boolean,
  reset: Function,
  initialValues: Object,
  onHide: Function,
  calculations: Array<Object>,
  reconciliationTypes: Array<Object>,
  postCAMReconciliation: Function,
  reconciliationsLoading: boolean,
};

const PostReconciliationModal = (props: Props) => {
  const {
    reset,
    onHide,
    pristine,
    valid,
    handleSubmit,
    calculations,
    reconciliationTypes,
    postCAMReconciliation,
    reconciliationsLoading,
  } = props;

  const onClose = () => {
    reset();
    onHide();
  };

  const onSubmit = (values) => {
    postCAMReconciliation(values);
  };

  const renderModalSpinner = () => {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  };

  const renderModalHeader = () => {
    return (
      <Modal.Header style={{ textAlign: 'center' }} closeButton>
        <Modal.Title componentClass="h1">
          <FormattedMessage {...messages.postReconciliationModalHeader} />
        </Modal.Title>
      </Modal.Header>
    );
  };

  const renderModalFooter = () => {
    const submitEnabled = !pristine && valid;

    return (
      <Modal.Footer>
        <Row>
          <Col xs={6}>
            <Button bsStyle="default" className="pull-right" onClick={onClose}>
              <FormattedMessage {...messages.cancel} />
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              bsStyle="primary"
              className="pull-left"
              type="submit"
              disabled={!submitEnabled || reconciliationsLoading}
            >
              <FormattedMessage {...messages.reconcile} />
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    );
  };

  const displayReconciliationAmount = (amount: number) =>
    amount < 0 ? `($${Math.abs(amount)})` : `$${amount}`;

  let totalEstimatedReconciliation = 0;
  const amountToPostRows = calculations
    .filter((row) => {
      const estimatedReconciliation = pathOr(
        0,
        ['estimatedReconciliation'],
        row,
      );
      if (estimatedReconciliation === 0) return false;
      totalEstimatedReconciliation += estimatedReconciliation;
      return true;
    })
    .map((row) => {
      const camPoolOrExceptionName = pathOr(
        pathOr('', ['camAllocation', 'intacctGLAccountGroup', 'name'], row),
        ['camAllocation', 'camException', 'name'],
        row,
      );
      const estimatedReconciliation = pathOr(
        0,
        ['estimatedReconciliation'],
        row,
      );
      return (
        <Row className="padbottom5">
          <Col xs={6}>{camPoolOrExceptionName}</Col>
          <Col xs={3}>
            <div className="float-right">
              {displayReconciliationAmount(estimatedReconciliation)}
            </div>
          </Col>
        </Row>
      );
    });

  const totalReconciliationRounded =
    Math.round(totalEstimatedReconciliation * 100) / 100;

  const renderModalFormBody = () => {
    return (
      <Row className="scrollable-modal__content padtop15">
        <div className="container-fluid padleft30 padright30">
          <Row>
            <Col xs={12} md={6}>
              <Row className="padleft30 padright30">
                <Row>
                  <h2>
                    <FormattedMessage {...messages.amountToPost} />
                  </h2>
                </Row>
                {amountToPostRows}
                <Row className="padtop10 padbottom10 padright30">
                  <Divider />
                </Row>
                <Row>
                  <Col xs={6}>
                    <h2>
                      <FormattedMessage {...messages.total} />
                    </h2>
                  </Col>
                  <Col xs={3}>
                    <h2 className="float-right">
                      {displayReconciliationAmount(totalReconciliationRounded)}
                    </h2>
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <Row>
                <h2>
                  <FormattedMessage {...messages.chooseReconciliationType} />
                </h2>
              </Row>
              <Row>
                <Col xs={6}>
                  <Field
                    name="camReconciliationTypesId"
                    fieldName="camReconciliationTypesId"
                    options={generateReconciliationType(reconciliationTypes)}
                    component={renderRadioGroupField}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Row>
    );
  };

  return (
    <Modal bsSize="lg" backdrop show={true} onHide={() => onClose()}>
      {renderModalHeader()}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          {reconciliationsLoading
            ? renderModalSpinner()
            : renderModalFormBody()}
        </Modal.Body>
        {renderModalFooter()}
      </form>
    </Modal>
  );
};

export const mapStateToProps = (
  { form }: Object,
  { initialValues }: Object,
): Object => {
  return {
    initialValues,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'postReconciliationModalForm',
    enableReinitialize: true,
  })(PostReconciliationModal),
);
