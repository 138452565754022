import React, { useEffect } from 'react';
import {
  reduxForm,
  Field,
  isPristine,
  getFormValues,
  isValid,
} from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import messages from './messages';
import {
  renderBlockDataDateField,
  renderBlockDataTextField,
} from '../../../utils/redux-form-helper';
import validate from './validate';
import Spinner from '../../../components/Spinner';
import confirm from '../../../components/ConfirmDialogModal';
import { isValidBatchDate } from '../CreateTransactionBatchDetails/utils';
import { NOTE_MAX_LENGTH } from './constants';
import SubmitButton from '../../../components/SubmitButton';

type Props = {
  history: object,
  period: string,
  batchId: string,
  initialValues: Object,
  batch: Object,
  isLoading: boolean,
  touch: Function,
  batchStatus: string,
  fiscalPeriodStartDate: string,
  intl: Object,
  formValues: Object,
  isDetailsFormPristine: boolean,
  pristine: boolean,
  transactionBatchHeaderId: string,
  isAllCommercial: boolean,
  onSaveAndCloseClicked: Function,
  detailsFormValues: Object,
  isAddGroupActive: boolean,
  isDetailsFormValid: boolean,
  isHeaderFormValid: boolean,
  selectedPropertyId: string,
};

const CreateTransactionBatchHeader = (props: Props) => {
  const {
    batchId,
    period,
    isLoading,
    history,
    touch,
    batchStatus,
    intl,
    isDetailsFormPristine,
    pristine,
    transactionBatchHeaderId,
    isAllCommercial,
    onSaveAndCloseClicked,
    formValues,
    detailsFormValues,
    isAddGroupActive,
    isDetailsFormValid,
    isHeaderFormValid,
    selectedPropertyId,
  } = props;
  useEffect(() => {
    touch('batchDate');
  });
  const { transactionBatchV2Addgroup } = useFlags();
  const onGoBackClicked = () => {
    const shouldShowConfirm =
      !(isDetailsFormPristine && pristine) || isAddGroupActive;
    if (!shouldShowConfirm) {
      return history.push(
        `/property/${selectedPropertyId}/manage-transaction-batches`,
      );
    }
    const message = messages.goBackCancelConfirmation;
    const formattedMessage = intl.formatMessage(message);
    confirm(formattedMessage).then(async () => {
      return history.push(
        `/property/${selectedPropertyId}/manage-transaction-batches`,
      );
    });
  };

  const isViewMode = batchId && batchStatus !== 'Unposted';

  const onAddGroupBtnClick = () => {
    if (!(isDetailsFormPristine && pristine) || isAddGroupActive) {
      onSaveAndCloseClicked(formValues, detailsFormValues, true);
    } else {
      return history.push(
        `/property/${selectedPropertyId}/add-group/${
          transactionBatchHeaderId || ''
        }`,
      );
    }
  };
  return (
    <div className="container-fluid">
      <a className="btn-text" onClick={onGoBackClicked}>
        <i className="et-chevron-left" />
        <FormattedMessage {...messages.goBack} />
      </a>
      <form>
        <Row className="form-header">
          <Col md={5}>
            <i className="et-calculator float-left" />
            <h2 className="float-left">
              {isLoading ? (
                <Spinner small />
              ) : batchId ? (
                batchStatus === 'Unposted' ? (
                  <FormattedMessage {...messages.editTransactionBatch} />
                ) : (
                  <FormattedMessage {...messages.viewTransactionBatch} />
                )
              ) : (
                <FormattedMessage {...messages.createTransactionBatch} />
              )}
            </h2>
          </Col>
          <Col md={7} className="text-right">
            <div className="highlight-details highlight-details--nobg">
              {batchId && (
                <span>
                  <FormattedMessage {...messages.status} />
                  {isLoading ? <Spinner small /> : batchStatus}
                </span>
              )}
              <span>
                <FormattedMessage {...messages.batchId} />
                {isLoading ? <Spinner small /> : batchId || 'Unsaved Batch'}
              </span>
              <span className="no-third">
                <FormattedMessage {...messages.period} />
                {isLoading ? <Spinner small /> : period}
              </span>
            </div>
          </Col>
        </Row>
        <Row className="padtop10">
          <Col md={2}>
            {isLoading ? (
              <Spinner small />
            ) : (
              <Field
                name="batchDate"
                label="Batch Date"
                component={renderBlockDataDateField}
                readOnly={isViewMode}
                isValidDate={isValidBatchDate}
              />
            )}
          </Col>
          <Col md={4}>
            {isLoading ? (
              <Spinner small />
            ) : (
              <Field
                name="batchNote"
                label="Note"
                component={renderBlockDataTextField}
                placeholder="(Optional)"
                readOnly={isViewMode}
                maxLength={NOTE_MAX_LENGTH}
              />
            )}
          </Col>
          {(!batchId || batchStatus === 'Unposted') &&
            !isLoading &&
            transactionBatchV2Addgroup && (
              <Col md={6}>
                <Row>
                  <SubmitButton
                    type="button"
                    bsStyle="warning"
                    href="#"
                    clickHandler={() => onAddGroupBtnClick()}
                    isSubmitting={false}
                    classes="float-right"
                    disabled={
                      !(isDetailsFormPristine && pristine) &&
                      (!isDetailsFormValid || !isHeaderFormValid)
                    }
                  >
                    <FormattedMessage {...messages.addGroup} />
                  </SubmitButton>
                </Row>
                <Row>
                  <span className="float-right">
                    {isAllCommercial ? (
                      <FormattedMessage {...messages.addGroupNoteTenant} />
                    ) : (
                      <FormattedMessage {...messages.addGroupNoteResident} />
                    )}
                  </span>
                </Row>
              </Col>
            )}
        </Row>
      </form>
    </div>
  );
};

export const mapStateToProps = (
  state: GlobalState,
  { initialValues },
): Object => {
  const isDetailsFormPristine = isPristine('transactionBatchForm')(state);
  const detailsFormValues = getFormValues('transactionBatchForm')(state);
  const formValues = getFormValues('CreateTransactionBatchHeader')(state);
  const isDetailsFormValid = isValid('transactionBatchForm')(state);
  const isHeaderFormValid = isValid('CreateTransactionBatchHeader')(state);
  return {
    initialValues,
    isDetailsFormPristine,
    formValues,
    detailsFormValues,
    isDetailsFormValid,
    isHeaderFormValid,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'CreateTransactionBatchHeader',
    enableReinitialize: true,
    validate,
  })(CreateTransactionBatchHeader),
);
