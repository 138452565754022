import { defineMessages } from 'react-intl';

const messages = defineMessages({
  reportId: {
    id: 'App.ManageReports.ReportId',
    defaultMessage: 'Report Id'
  },
  header: {
    id: 'App.ManageReports.ReportHeader',
    defaultMessage: 'Manage Reports'
  },
  reports: {
    id: 'App.ManageReports.reports',
    defaultMessage: 'Reports'
  },
  reportName: {
    id: 'App.ManageReports.reportName',
    defaultMessage: 'Reports'
  },
  reportType: {
    id: 'App.ManageReports.reportType',
    defaultMessage: 'Type'
  },
  reportLastCreated: {
    id: 'App.ManageReports.reportLastCreated',
    defaultMessage: 'Recently Created'
  },
  search: {
    id: 'App.ManageReports.Search',
    defaultMessage: 'Search'
  },
  create: {
    id: 'App.ManageReports.Create',
    defaultMessage: 'Create'
  },
  viewHistory: {
    id: 'App.ManageReports.ViewHistory',
    defaultMessage: 'View History',
  }
});

export default messages;
