import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import * as constants from '../constants';
import SearchActions from '../../../components/SearchActions';

interface Props {
  assignedUnitsCount: number;
  downloadCSV: Function;
  formatMessage: Function;
  handleSubmit: Function;
  hasActiveFilters: boolean;
  organizationId: string;
  selectedProperty: Object;
  setShowFilter: Function;
  showFilter: boolean;
}

const Header = ({
  assignedUnitsCount,
  downloadCSV,
  formatMessage,
  handleSubmit,
  hasActiveFilters,
  setShowFilter,
}: Props) => {
  return (
    <div className="row section-header">
      <div className="col-xs-3 col-md-4">
        <h1>
          <FormattedMessage {...messages.title} />
        </h1>
        <p>
          <FormattedMessage {...messages.unitsFound} />:{' '}
          <span className="text-blue">{assignedUnitsCount}</span>
        </p>
      </div>

      <SearchActions
        filterButton={{
          hasActiveFilters,
          onClick: () => setShowFilter(true),
        }}
        searchField={{
          form: constants.FORM_NAME,
          placeholder: `${formatMessage(messages.search)}`,
          onSubmit: handleSubmit,
        }}
        csvDownloadButton={{
          onClick: downloadCSV,
        }}
      />
    </div>
  );
};

export default Header;
