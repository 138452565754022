import { defineMessages } from 'react-intl';

const messages = defineMessages({
  numberOfProperties: {
    id: 'App.ManagePropertyGroups.NumberOfProperties',
    defaultMessage: '# of Properties',
  },
  editView: {
    id: 'App.ManagePropertyGroups.EditView',
    defaultMessage: 'Edit/View',
  },
  saveAndAddNew: {
    id: 'App.ManagePropertyGroups.SaveAndAddNew',
    defaultMessage: 'Save and Add New',
  },
  itemsInList: {
    id: 'App.ManagePropertyGroups.ItemsInList',
    defaultMessage: 'Items in List',
  },
  propertyGroupCreateSuccess: {
    id: 'App.ManagePropertyGroups.PropertyGroupCreateSuccess',
    defaultMessage: 'Property Group created successfully',
  },
  propertyGroupUpdateSuccess: {
    id: 'App.ManagePropertyGroups.PropertyGroupUpdateSuccess',
    defaultMessage: 'Property Group updated successfully',
  },
});

export default messages;
