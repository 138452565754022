const appLocales = ['en', 'es'];
const defaultLocale = 'en';

const messages = appLocales.reduce((languageMessages, language) => {
  // $FlowFixMe
  languageMessages[`${language}`] = require(`./locales/${language}`);
  return languageMessages;
}, {});

const i18n = {
  appLocales,
  defaultLocale,
  messages,
};

module.exports = i18n;
