import { defineMessages } from 'react-intl';

const messages = defineMessages({
  hideUnitCheckboxLabel: {
    id: 'App.ViewUnit.UnitStatus.HideUnitCheckboxLabel',
    defaultMessage: 'Hide Unit from Online Availability',
  },
  tooltipLabel: {
    id: 'App.ViewUnit.UnitStatus.TooltipLabel',
    defaultMessage:
      'When this box is checked, ' +
      'this unit will not appear in the online availability for your property website or any internet listing service.',
  },
});

export default messages;
