const PdfIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      className="pdf-icon"
      // eslint-disable-next-line max-len
      d="M15.5754 14.68C18.6034 14.854 22.0325 15.7718 22.8155 17.2128C23.0623 17.6657 23.0611 18.1615 22.8143 18.6073C22.4132 19.3272 21.7518 19.7241 20.953 19.7247C19.4653 19.7247 17.48 18.4207 15.0521 15.8486C14.003 15.8135 12.982 15.8707 12.0797 16.0125C11.7522 16.0638 11.2195 16.1347 10.8903 16.1687C10.0453 16.2557 9.17103 16.4201 8.29037 16.6561C6.31267 20.2014 4.50631 22 2.92158 22C2.34909 22 1.84034 21.7676 1.40936 21.3087C1.0392 20.9142 0.914643 20.4046 1.05791 19.8731C1.4538 18.4046 4.15662 16.6341 7.50619 15.6412C7.69097 15.2992 7.87693 14.9404 8.06347 14.5667C9.85346 10.9821 9.74879 6.46961 9.52716 4.09952C9.44821 3.6317 9.39266 3.22169 9.35582 2.91538C9.32307 2.64839 9.29559 2.41716 9.48038 2.20203C9.59441 2.07271 9.75463 2 9.92071 2C10.4394 2 10.5131 2.50834 10.5482 2.75209C10.5914 3.0584 10.6429 3.46961 10.6897 3.96424C11.0832 6.29857 12.137 10.705 14.7392 13.7372C15.024 14.0673 15.3018 14.3814 15.5754 14.68ZM2.25377 20.4845C2.46136 20.7056 2.67305 20.8081 2.92099 20.8081C3.83909 20.8081 5.194 19.4726 6.60973 17.1961C4.10399 18.1371 2.38944 19.4333 2.18535 20.1895C2.15027 20.317 2.16898 20.3945 2.25377 20.4845ZM11.9002 14.8361C11.5891 14.885 11.0838 14.9517 10.7716 14.9851C10.2037 15.0423 9.62014 15.1335 9.03127 15.2563C9.05583 15.2074 9.08039 15.1579 9.10495 15.1085C9.98269 13.351 10.5265 11.2437 10.7277 8.82837C11.5329 11.1251 12.5832 13.0358 13.8586 14.5221L13.8988 14.5691C13.9235 14.5979 13.9484 14.6269 13.9726 14.6561C13.2364 14.674 12.5417 14.7354 11.9002 14.8361ZM20.9284 18.5352C21.3243 18.5352 21.6003 18.3725 21.7945 18.022C21.8436 17.9327 21.8436 17.8832 21.7933 17.7902C21.4454 17.1496 19.5057 16.3325 16.8567 15.9934C18.5683 17.6383 19.9981 18.5352 20.9279 18.5352H20.9284Z"
    />
  </svg>
);
export default PdfIcon;
