const RENEWAL_STATUS_ARRAY = ['Completed', 'In Process', 'Not Started'];

const RENEWAL_STATUS_FILTERS = RENEWAL_STATUS_ARRAY.map((value) => ({
  text: value,
  value: value.toLowerCase(),
}));

export const HEADERS = [
  {
    id: 'propertyName',
    label: 'Property Name',
    multiselect: true,
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'leaseRenewalStatus',
    label: 'Lease Renewal Status',
    searchable: true,
    sortable: true,
    multiselect: true,
    filterOptions: RENEWAL_STATUS_FILTERS,
    dataType: 'string',
  },
  {
    id: 'unit',
    label: 'Unit',
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'floorPlan',
    label: 'Floor Plan',
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'resident',
    label: 'Resident',
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'moveInDate',
    label: 'Move-In Date',
    searchable: true,
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'leaseStartDate',
    label: 'Lease Start Date',
    searchable: true,
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'leaseEndDate',
    label: 'Lease End Date',
    searchable: true,
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'daysUntilLeaseEnd',
    label: 'Days until Lease End',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'noticeToVacate',
    label: 'Occupied / On NTV',
    sortable: true,
    searchable: false,
    multiselect: true,
    filterOptions: [
      { text: 'Yes', value: 'yes' },
      { text: 'No', value: 'no' },
    ],
    dataType: 'boolean',
  },
  {
    id: 'doNotRenew',
    label: 'Do Not Renew',
    sortable: true,
    searchable: false,
    multiselect: true,
    filterOptions: [
      { text: 'Yes', value: 'yes' },
      { text: 'No', value: 'no' },
    ],
    dataType: 'boolean',
  },
  {
    id: 'underEviction',
    label: 'Under Eviction',
    sortable: true,
    searchable: false,
    multiselect: true,
    filterOptions: [
      { text: 'Yes', value: 'yes' },
      { text: 'No', value: 'no' },
    ],
    dataType: 'boolean',
  },
  {
    id: 'gprMarketRent',
    label: 'GPR Market Rent',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'priorLeasedRent',
    label: 'Prior Leased Rent',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'currentLeasedRent',
    label: 'Current Leased Rent',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'otherUnitFees',
    label: 'Other Unit Fees',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'balance',
    label: 'Balance',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'quotingRent',
    label: 'Quoting Rent',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'actions',
    label: 'Actions',
    searchable: false,
    sortable: false,
  },
];

export const PROPERTY_PATH_MAP = {
  leaseRenewalStatus: {
    path: 'leaseRenewalStatus',
    dataType: 'string',
  },
  propertyName: {
    path: 'propertyName',
    dataType: 'string',
  },
  unit: {
    path: 'unit',
    dataType: 'string',
  },
  floorPlan: {
    path: 'floorPlan',
    dataType: 'string',
  },
  resident: {
    path: 'resident',
    dataType: 'string',
  },
  moveInDate: {
    path: 'moveInDate',
    dataType: 'date',
  },
  leaseStartDate: {
    path: 'leaseStartDate',
    dataType: 'date',
  },
  leaseEndDate: {
    path: 'leaseEndDate',
    dataType: 'date',
  },
  daysUntilLeaseEnd: {
    path: 'daysUntilLeaseEnd',
    dataType: 'number',
  },
  noticeToVacate: {
    path: 'noticeToVacate',
    dataType: 'boolean',
  },
  doNotRenew: {
    path: 'doNotRenew',
    dataType: 'boolean',
  },
  underEviction: {
    path: 'underEviction',
    dataType: 'boolean',
  },
  gprMarketRent: {
    path: 'gprMarketRent',
    dataType: 'number',
  },
  priorLeasedRent: {
    path: 'priorLeasedRent',
    dataType: 'number',
  },
  currentLeasedRent: {
    path: 'currentLeasedRent',
    dataType: 'number',
  },
  otherUnitFees: {
    path: 'otherUnitFees',
    dataType: 'string',
  },
  balance: {
    path: 'balance',
    dataType: 'number',
  },
  quotingRent: {
    path: 'quotingRent',
    dataType: 'number',
  },
  actions: {
    path: 'residentId',
    dataType: 'string',
  },
};

export const SORT_TYPE = {
  ASC: 'ASC',
  DESC: 'DESC',
};
