import React, { useCallback, useState } from 'react';
// eslint-disable-next-line max-len
import { UnitSpecials as FortressUnitSpecials } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import usePropertySpecialsQuery from '../../hooks/data-fetching/usePropertySpecialsQuery';
import usePropertySpecialsMutation from '../../hooks/data-modifying/usePropertySpecialsMutation';
import { toastr } from 'react-redux-toastr';
import useIntl from '../../hooks/useIntl';
import useSelectedProperty from '../../hooks/useSelectedProperty';

const useUnitSpecials = ({ selectedProperty }) => {
  const [editMode, setEditMode] = useState(false);

  const {
    data: propertySpecials,
    isLoading: isPropertySpecialsLoading,
    refetch: refetchPropertySpecials,
  } = usePropertySpecialsQuery({ propertyId: selectedProperty.id });

  const { mutate: handleSaveSpecial } = usePropertySpecialsMutation({
    propertySpecials,
    organizationId: selectedProperty.organizationId,
    propertyId: selectedProperty.id,
    options: {
      onSuccess: () => {
        refetchPropertySpecials();
        toastr.success('Success', 'Special saved.');
      },
      onError: (e) => {
        toastr.error('Error', e.toString());
      },
    },
  });

  const handleSubmitSpecial = useCallback(
    async (updatedSpecial) => {
      await handleSaveSpecial(updatedSpecial);
      setEditMode(false);
    },
    [handleSaveSpecial],
  );

  return {
    editMode,
    handleToggleEditMode: () => setEditMode((prevState) => !prevState),
    handleSubmitSpecial,
    isPropertySpecialsLoading,
    propertySpecial: propertySpecials?.length ? propertySpecials[0] : {},
  };
};

const UnitSpecials = () => {
  const intl = useIntl();

  const selectedProperty = useSelectedProperty();
  const {
    editMode,
    handleToggleEditMode,
    handleSubmitSpecial,
    isPropertySpecialsLoading,
    propertySpecial,
  } = useUnitSpecials({ selectedProperty });

  return (
    <FortressUnitSpecials
      editMode={editMode}
      intl={intl}
      special={propertySpecial}
      handleSubmitSpecials={handleSubmitSpecial}
      handleToggleEditMode={handleToggleEditMode}
      isLoading={isPropertySpecialsLoading}
    />
  );
};

export default UnitSpecials;
