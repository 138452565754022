export const HEADERS = [
  {
    id: 'property',
    label: 'Property Name',
    multiselect: true,
    searchable: true,
    sortable: true,
    dataType: 'string',
    filterOptions: [],
  },
  {
    id: 'unit',
    label: 'Unit',
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'floorPlan',
    label: 'Floor Plan',
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'bedsBaths',
    label: 'Bed / Baths',
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'sqFeet',
    label: 'Sq Ft',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'status',
    label: 'Status',
    multiselect: true,
    searchable: true,
    sortable: true,
    dataType: 'string',
    filterOptions: [],
  },
  {
    id: 'moveOut',
    label: 'Move-Out Date',
    searchable: true,
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'moveInReady',
    label: 'Move-In Ready',
    searchable: true,
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'notes',
    label: 'Notes',
    searchable: false,
    sortable: false,
    dataType: 'string',
  },
  {
    id: 'daysVacant',
    label: 'Days Vacant',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'quotingRent',
    label: 'Quoting Rent',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'requiredUnitFees',
    label: 'Required Unit Fees',
    searchable: true,
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'actions',
    label: 'Actions',
    searchable: false,
    sortable: false,
  },
];
