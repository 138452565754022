import { defineMessages } from 'react-intl';

const messages = defineMessages({
  studentType: {
    id: 'App.LeaseAffordable.StudentInformationSection.StudentType',
    defaultMessage: 'If yes, are you:'
  },
  currentStudent: {
    id: 'App.LeaseAffordable.StudentInformationSection.CurrentStudent',
    defaultMessage: 'Are you a current student?'
  },
  studentStatus: {
    id: 'App.LeaseAffordable.StudentInformationSection.StudentStatus',
    defaultMessage:
      'If full-time, select the options below that describe your student status:'
  },
  TANFAssistance: {
    id: 'App.LeaseAffordable.StudentInformationSection.TANFAssistance',
    defaultMessage: '1. TANF Assistance'
  },
  jobTrainingProgram: {
    id: 'App.LeaseAffordable.StudentInformationSection.jobTrainingProgram',
    defaultMessage: '2. Job Training Program'
  },
  singleParentDependantChild: {
    id:
      'App.LeaseAffordable.StudentInformationSection.singleParentDependantChild',
    defaultMessage: '3. Single Parent/Dependent Child'
  },
  marriedJointReturn: {
    id: 'App.LeaseAffordable.StudentInformationSection.marriedJointReturn',
    defaultMessage: '4. Married/Joint Return'
  },
  fosterProgram: {
    id: 'App.LeaseAffordable.StudentInformationSection.fosterProgram',
    defaultMessage: '5. Foster Program'
  },
  other: {
    id: 'App.LeaseAffordable.StudentInformationSection.other',
    defaultMessage: '6. Other'
  },
  otherStudentStatusPlaceholder: {
    id:
      'App.LeaseAffordable.StudentInformationSection.otherStudentStatusPlaceholder',
    defaultMessage: 'Other Student Status'
  }
});

export default messages;
