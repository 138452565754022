import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reset, getFormValues } from 'redux-form';
import DocumentTitle from 'react-document-title';
import messages from './Header/messages';
import ProrateCalculatorHeader from './Header';
import ProrateCalculatorDetails from './Details';
import ProrateCalculatorFooter from './Footer';
import { useGetTransactionTypes } from '../CreateTransactionBatch/hooks';
import { useFetchPropertyTransactionCodesForProperty } from '../App/hooks';
import { validateDate } from '../../utils/validations';

const DEFAULT_ROW_ID = 'prorate-calculator-transaction';

type Props = {
  intl: any,
  propertyId: string,
  organizationId: string,
  dispatch: Function,
  headerFormValues: Object,
};

const ProrateCalculator = (props: Props) => {
  const { intl, propertyId, organizationId, dispatch, headerFormValues } =
    props;

  const initialHeaderValues = {
    moveInMoveOut: 'moveIn',
  };

  const [transactionTypes] = useGetTransactionTypes();

  const [propertyTransactionCodes, isLoading] =
    useFetchPropertyTransactionCodesForProperty({
      propertyId,
      organizationId,
    });

  const [hasSelectedDateBefore, setHasSelectedDateBefore] = useState(false);
  const [counter, setCounter] = useState(0);
  const initalInlineRows = [
    {
      id: `${DEFAULT_ROW_ID}-0`,
      key: 0,
      balance: 0,
    },
  ];
  const [inlineRowsArray, setInlineRowsArray] = useState(initalInlineRows);

  const isMoveDateValid = validateDate(headerFormValues?.moveDate);

  useEffect(() => {
    if (!hasSelectedDateBefore && isMoveDateValid) {
      setHasSelectedDateBefore(true);
    }
  }, [hasSelectedDateBefore, setHasSelectedDateBefore, isMoveDateValid]);

  const onClearAll = () => {
    setInlineRowsArray(initalInlineRows);
    setCounter(0);
    dispatch(reset('prorateCalculatorForm'));
    dispatch(reset('ProrateCalculatorHeaderForm'));
    setHasSelectedDateBefore(false);
  };

  return (
    <DocumentTitle title={intl.formatMessage(messages.header)}>
      <div
        className="bodywrap"
        style={{ maxWidth: '1280px', paddingLeft: '40px' }}
        data-testid="prorate-calc-wrapper"
      >
        <ProrateCalculatorHeader
          organizationId={organizationId}
          propertyId={propertyId}
          initialValues={initialHeaderValues}
        />
        {hasSelectedDateBefore && (
          <ProrateCalculatorDetails
            intl={intl}
            isLoading={isLoading}
            propertyTransactionCodes={propertyTransactionCodes}
            transactionTypes={transactionTypes}
            counter={counter}
            setCounter={setCounter}
            inlineRowsArray={inlineRowsArray}
            setInlineRowsArray={setInlineRowsArray}
            defaultRowId={DEFAULT_ROW_ID}
          />
        )}
        {!isLoading && hasSelectedDateBefore && (
          <ProrateCalculatorFooter intl={intl} onClearAll={onClearAll} />
        )}
      </div>
    </DocumentTitle>
  );
};

const InjectedComponent = injectIntl(ProrateCalculator);
export const mapStateToProps = (state: any): any => {
  const headerFormValues = getFormValues('ProrateCalculatorHeaderForm')(state);
  return {
    organizationId: state?.app?.currentUser?.user?.organizationId ?? '',
    propertyId: state?.app?.selectedProperty?.id,
    headerFormValues,
  };
};

export default connect(mapStateToProps)(InjectedComponent);
