import {
  CREATE_CHARGE_BATCH,
  CREATE_CHARGE_BATCH_FAILURE,
  CREATE_CHARGE_BATCH_SUCCESS
} from './constants';
import type { Action } from '../../App/types';

export const createChargeBatch = (payload: Action<Object>) => ({
  type: CREATE_CHARGE_BATCH,
  payload
});

export const createChargeBatchFailure = (err: Error) => ({
  type: CREATE_CHARGE_BATCH_FAILURE,
  payload: err,
  error: true
});

export const createChargeBatchSuccess = () => ({
  type: CREATE_CHARGE_BATCH_SUCCESS,
  payload: undefined
});
