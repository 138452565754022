import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import type { DecisionStatus } from '../../types';
type Props = {
  item: Object,
  applicationDecisionStatuses: Array<DecisionStatus>,
  handleChecklistOptionChange?: ?Function,
  chooseMessage: string
};

type State = {
  checklistItemId: string
};

class ChecklistItem extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checklistItemId: props.item.id
    };
  }
  componentDidMount() {
    this.getSelectedOption();
  }

  getSelectedOption() {
    const selectedOption = this.props.item.checklistItemOptions.find(
      (item) => item.selected
    );
    if (selectedOption) {
      return {
        checklistItemId: this.props.item.id,
        selectedOption: selectedOption.id,
        selectedDecision: selectedOption.decisionStatus,
        received: selectedOption.received
      };
    } else {
      return {
        checklistItemId: this.props.item.id,
        selectedOption: null,
        selectedDecision: null,
        received: null
      };
    }
  }

  generateOptions() {
    const options = this.props.item.checklistItemOptions.map((option) => ({
      value: option.id,
      text: option.name,
      disabled: false
    }));
    options.unshift({
      value: 'default',
      text: this.props.chooseMessage,
      disabled: true
    });
    return options;
  }

  generateDecisions() {
    const decisions = this.props.applicationDecisionStatuses.map(
      (decision) => ({
        value: decision.id,
        text: decision.name,
        disabled: false
      })
    );
    decisions.unshift({
      value: 'default',
      text: this.props.chooseMessage,
      disabled: true
    });
    return decisions;
  }

  disableDecisionDropdown(activeSelections: Object) {
    return activeSelections.selectedOption === null;
  }

  renderReceivedstatus(activeSelections: Object) {
    if (activeSelections.selectedOption === null) {
      return '---';
    } else {
      return activeSelections.received ? 'Received' : 'Pending';
    }
  }

  render() {
    const item = this.props.item;
    const options = this.generateOptions();
    const decisions = this.generateDecisions();
    const activeSelections = this.getSelectedOption();
    return (
      <tr>
        <td>{item['name']}</td>
        <td>
          <FormControl
            componentClass="select"
            value={activeSelections.selectedOption || 'default'}
            onChange={
              this.props.handleChecklistOptionChange
                ? this.props.handleChecklistOptionChange(
                    activeSelections,
                    'option'
                  )
                : null
            }
            readOnly={!this.props.handleChecklistOptionChange}
          >
            {options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.text}
              </option>
            ))}
          </FormControl>
        </td>
        <td>{this.renderReceivedstatus(activeSelections)}</td>
        <td>
          <FormControl
            disabled={this.disableDecisionDropdown(activeSelections)}
            componentClass="select"
            value={activeSelections.selectedDecision || 'default'}
            onChange={
              this.props.handleChecklistOptionChange
                ? this.props.handleChecklistOptionChange(
                    activeSelections,
                    'decision'
                  )
                : null
            }
            readOnly={!this.props.handleChecklistOptionChange}
          >
            {decisions.map((decision) => (
              <option
                key={decision.value}
                value={decision.value}
                disabled={decision.disabled}
              >
                {decision.text}
              </option>
            ))}
          </FormControl>
        </td>
      </tr>
    );
  }
}

export default ChecklistItem;
