import { defineMessages } from 'react-intl';

const messages = defineMessages({
  recertsFound: {
    id: 'App.ManageCertificationsContent.Recerts.RecertsFound',
    defaultMessage: 'Recerts Found',
  },
  recertsDue: {
    id: 'App.ManageCertificationsContent.Recerts.RecertsDue',
    defaultMessage: 'Recerts Due',
  },
  lateRecerts: {
    id: 'App.ManageCertificationsContent.Recerts.LateRecerts',
    defaultMessage: 'Late Recerts',
  },
  under30: {
    id: 'App.ManageCertificationsContent.Recerts.Expiration0To30Days',
    defaultMessage: 'in 0-30 Days:',
  },
  between31And60: {
    id: 'App.ManageCertificationsContent.Recerts.Between31And60',
    defaultMessage: 'in 31-60 Days:',
  },
  between61And90: {
    id: 'App.ManageCertificationsContent.Recerts.Between61And90',
    defaultMessage: 'in 61-90 Days:',
  },
  between90And120: {
    id: 'App.ManageCertificationsContent.Recerts.Between90And120',
    defaultMessage: 'in 91-120 Days:',
  },
  noEntriesFound: {
    id: 'App.ManageCertificationsContent.Recerts.NoEntriesFound',
    defaultMessage: 'No Entries Found',
  },
  displayAll: {
    id: 'App.ManageCertificationsContent.Recerts.DisplayAll',
    defaultMessage: 'Display All',
  },
  ifDisplayAllSelected: {
    id: 'App.ManageCertificationsContent.Recerts.IfDisplayAllSelected',
    defaultMessage:
      'If selected the filter icon is disabled. To enable the filters, deselect this box.',
  },
});

export default messages;
