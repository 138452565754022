import type { Action } from '../App/types';
import * as ActionTypes from './constants';
import type { HomeState } from './types';

const initialState = {
  assignees: [],
  activities: [],
  currentUser: undefined,
  selectedProperty: undefined,
  selectedAssignee: '',
  activeKey: '1',
  taskInformation: {
    renewalsInProcess: [],
    transfersInProcess: [],
    scheduledMoveOuts: [],
    pendingFAS: [],
    openWorkOrders: [],
    scheduledMoveIns: [],
    applicationsInProcess: [],
    prospectsToFollowUp: [],
    leaseStats: [],
  },
};

const homeReducer = (state: HomeState = initialState, action: Action<*, *>) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ALL_ACTIVITIES_BY_ASSIGNEE_SUCCESS: {
      return { ...state, activities: payload };
    }
    case ActionTypes.CLEAR_ACTIVITIES_BY_ASSIGNEE: {
      return { ...state, activities: [] };
    }
    case ActionTypes.SET_SELECTED_ASSIGNEE: {
      return { ...state, selectedAssignee: payload };
    }
    case ActionTypes.SET_ACTIVE_KEY: {
      return { ...state, activeKey: payload };
    }
    case ActionTypes.CLEAR_TASK_INFORMATION: {
      return { ...state, taskInformation: { ...initialState.taskInformation } };
    }
    case ActionTypes.GET_TASK_INFORMATION_SUCCESS: {
      return { ...state, taskInformation: payload };
    }
    default:
      return state;
  }
};

export default homeReducer;
