import {
  PADSection,
  ConfigureRecurringPADsModal,
  ConfigurePADModal,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useModal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { SizedBox } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { injectIntl } from 'react-intl';
import { usePADOption } from './hooks';
import useUniqueTableName from '../../../../hooks/useUniqueTableName';

function PADOption(props) {
  const recurringPADsModal = useModal();
  const configurePADModal = useModal();

  const {
    shouldDisplay,
    householdMembers,
    lateFeeDay,
    onSubmit,
    isSubmitting,
    onDelete,
    disableOneTimeOption,
    disableRecurringOption,
    hideRecurringOption,
    PADs,
    editPAD,
    editData,
    handleOneTimePADClick,
    isOneTime,
    showUSForm,
  } = usePADOption({
    ...props,
    configurePADModal,
  });

  const tableName = useUniqueTableName('recurring-PADs');

  if (shouldDisplay === false) return null;

  return (
    <>
      <SizedBox h={10} />
      <PADSection
        intl={props.intl}
        disableOneTimeOption={disableOneTimeOption}
        disableRecurringOption={disableRecurringOption}
        hideRecurringOption={hideRecurringOption}
        onRecurringClick={recurringPADsModal.openModal}
        onOneTimeClick={handleOneTimePADClick}
      />
      {recurringPADsModal.open && (
        <ConfigureRecurringPADsModal
          intl={props.intl}
          open={recurringPADsModal.open}
          onClose={recurringPADsModal.closeModal}
          unitNumber={props.unitNumber}
          PADs={PADs}
          onAddPADClick={configurePADModal.openModal}
          onEditPADClick={editPAD}
          tableName={tableName}
        />
      )}
      {configurePADModal.open && (
        <ConfigurePADModal
          intl={props.intl}
          open={configurePADModal.open}
          onClose={configurePADModal.closeModal}
          householdMembers={householdMembers}
          lateFeeDay={lateFeeDay}
          allowPropertyPartialPayments={
            props.propertyConfig?.shouldAcceptPartialPayments
          }
          allowHouseholdPartialPayments={props.allowHouseholdPartialPayments}
          allowsOverpayment={props.propertyConfig?.shouldAcceptOverpay}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          onDeletePad={onDelete}
          editData={editData}
          isOneTime={isOneTime}
          balanceDueAmount={props.balance}
          achData={{
            feeFlat: props.propertyConfig?.achFeeFlat ?? 0,
            feePct: props.propertyConfig?.achFeePct ?? 0,
          }}
          showUSForm={showUSForm}
        />
      )}
    </>
  );
}

export default injectIntl(PADOption);
