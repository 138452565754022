import { validatePositiveMoney } from '../../../../utils/validations';
import formMessages from '../../../ShortLeaseApplication/messages';
import { isNil } from 'ramda';

const validateString = (value?: string) =>
  value && /^[a-zA-Z0-9][a-zA-Z0-9 .'-:]*$/i.test(value);

const validate = (values: any, { intl, lease, residentId }: Object) => {
  const errors = {};
  if (lease.isRenewal || lease.isTransfer || isNil(residentId)) {
    [
      'oneTimeRentConcession',
      'monthlyRentConcession',
      'otherDiscountConcession',
    ].forEach((fieldName) => {
      if (
        (values[fieldName] && !validatePositiveMoney(values[fieldName])) ||
        values[fieldName] > 11000
      ) {
        errors[fieldName] = intl.formatMessage(formMessages.validNumber);
      }
    });

    ['oneTimeRentMonth', 'monthlyRentMonth'].forEach((fieldName) => {
      if (
        values[fieldName] &&
        (!validateString(values[fieldName]) || values[fieldName].length < 1)
      ) {
        errors[fieldName] = intl.formatMessage(formMessages.invalidValue);
      }
    });

    ['discountDescription'].forEach((fieldName) => {
      if (
        values[fieldName] &&
        (!validateString(values[fieldName]) || values[fieldName].length < 2)
      ) {
        errors[fieldName] = intl.formatMessage(formMessages.invalidValue);
      }
    });
  }

  return errors;
};

export default validate;
