import React from 'react';
import messages from './messages';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import {
  renderBlockDataCurrencyField,
  renderBlockDataDateField,
  renderBlockDataTextField,
} from '../../../utils/redux-form-helper';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import validate from './validate';
import Spinner from '../../../components/Spinner';
import SubmitButton from '../../../components/SubmitButton';
import ElementWithPermissions from '../../../components/ElementWithPermissions';

type Props = {
  period: string,
  isLoading: boolean,
  isSaved: boolean,
  goBack: Function,
  handleSubmit: Function,
  valid: boolean,
  pristine: boolean,
  batchId: string,
  initialValues: Object,
  isSaveHeaderLoading: boolean,
  batch: Object,
  editHeader: boolean,
  setEditHeader: Function,
  updateSubsidyBatchHeader: Function,
  batchDate: date,
  batchTotal: sring,
  documentNumber: string,
  batchNote: string,
  reset: Function,
};

const CreateSubsidyBatchHeader = (props: Props) => {
  const {
    isSaved,
    goBack,
    handleSubmit,
    valid,
    pristine,
    batchId,
    period,
    isLoading,
    isSaveHeaderLoading,
    batch,
    editHeader,
    setEditHeader,
    updateSubsidyBatchHeader,
    batchDate,
    batchTotal,
    documentNumber,
    batchNote,
    reset,
  } = props;
  const disableSaveBtn = pristine || !valid;
  const depositId = batch?.header?.depositHeader?.id ?? null;
  const isPosting = batch?.header?.isPosting ?? false;
  const isPosted = batch?.header?.isPosted ?? false;
  const disableEditHeader = depositId || isPosting || isPosted;
  const batchHeaderId = batch?.header?.id ?? null;
  const batchTransactions = batch?.transactions ?? null;
  const transactions =
    batchTransactions &&
    batchTransactions.map((row) => ({
      id: row.id,
      documentNumber: row.documentNumber,
      transactionDate: row.transactionDate,
      transactionAmount: row.transactionAmount,
      receivedFromHouseholdMember: row.receivedFromHouseholdMember,
      transactionNote: row.transactionNote,
      householdStatus: row.householdStatus,
      householdId: row.householdId,
      unitId: row.unitId,
      propertyTransactionCodeId: row.propertyTransactionCodeId,
    }));
  return (
    <div className="container-fluid">
      <form>
        <Row className="form-header">
          <Col md={5}>
            <i className="et-calculator float-left" />
            <h2 className="float-left">
              {!(depositId || isPosting || isPosted) ? (
                <FormattedMessage {...messages.createSubsidyBatch} />
              ) : (
                <FormattedMessage {...messages.viewSubsidyBatch} />
              )}
            </h2>
          </Col>
          <Col md={7} className="text-right">
            <div className="highlight-details highlight-details--nobg">
              <span>
                <FormattedMessage {...messages.batchId} />
                {isLoading ? <Spinner small /> : batchId}
              </span>
              <span className="no-third">
                <FormattedMessage {...messages.period} />
                {isLoading ? <Spinner small /> : period}
              </span>
            </div>
          </Col>
        </Row>
        <Row className="padtop10">
          <Col md={2}>
            <Field
              name="batchDate"
              label="Subsidy Deposit Date"
              component={renderBlockDataDateField}
              readOnly={isSaved && !editHeader}
            />
          </Col>
          <Col md={2}>
            <Field
              name="batchTotal"
              label="Subsidy Deposit Total"
              placeholder="0.00"
              component={renderBlockDataCurrencyField}
              readOnly={isSaved && !editHeader}
            />
          </Col>

          <Col md={2}>
            <Field
              name="documentNumber"
              label="Document Number"
              component={renderBlockDataTextField}
              readOnly={isSaved && !editHeader}
            />
          </Col>
          <Col md={4}>
            <Field
              name="batchNote"
              label="Note"
              component={renderBlockDataTextField}
              readOnly={isSaved && !editHeader}
            />
          </Col>
          {!disableEditHeader && isSaved && (
            <Col md={2} className="text-center">
              <Row>
                <label>
                  <FormattedMessage {...messages.editBatchDetails} />
                </label>
              </Row>
              <Row>
                {!editHeader ? (
                  <ElementWithPermissions
                    scope={['subsidy-payment-create-edit']}
                  >
                    <Button
                      className="btn btn-tertiary"
                      onClick={() => setEditHeader(true)}
                    >
                      <i className="et-pencil" />
                    </Button>
                  </ElementWithPermissions>
                ) : (
                  <div>
                    <a
                      className="btn-text text-green padright5"
                      onClick={() => {
                        const payload = {
                          header: {
                            batchDate,
                            batchTotal,
                            documentNumber,
                            batchNote,
                            id: batchHeaderId,
                          },
                          transactions,
                        };
                        updateSubsidyBatchHeader(payload, () =>
                          setEditHeader(false),
                        );
                      }}
                      disabled={disableSaveBtn}
                    >
                      <FormattedMessage {...messages.save} />
                      <i className="icon et-isolated-check" />
                    </a>
                    <a
                      className="closebtn text-red"
                      onClick={() => {
                        setEditHeader(false);
                        reset();
                      }}
                    >
                      <FormattedMessage {...messages.cancel} />{' '}
                      <i className="icon et-delete-3" />
                    </a>
                  </div>
                )}
              </Row>
            </Col>
          )}
        </Row>
        {!isSaved && (
          <Row className="padtop20">
            <Col xs={6}>
              <Button
                bsStyle="default"
                className="pull-right"
                onClick={() => goBack()}
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
            </Col>
            <Col xs={6}>
              <SubmitButton
                bsStyle="primary"
                className="pull-left"
                disabled={disableSaveBtn}
                clickHandler={handleSubmit}
                isSubmitting={isSaveHeaderLoading}
              >
                <FormattedMessage {...messages.save} />
              </SubmitButton>
            </Col>
          </Row>
        )}
      </form>
    </div>
  );
};

export const mapStateToProps = (
  { form }: Object,
  { initialValues }: Object,
): Object => {
  const selector = formValueSelector('createSubsidyPaymentBatch');
  return {
    initialValues,
    batchDate: selector({ form }, 'batchDate'),
    batchTotal: selector({ form }, 'batchTotal'),
    documentNumber: selector({ form }, 'documentNumber'),
    batchNote: selector({ form }, 'batchNote'),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'createSubsidyPaymentBatch',
    enableReinitialize: true,
    validate,
  })(CreateSubsidyBatchHeader),
);
