import { defineMessages } from 'react-intl';

const messages = defineMessages({
  clearAllFields: {
    id: 'App.ProrateCalculator.Footer.clearAllFields',
    defaultMessage: 'Clear All Fields',
  },
  confirmClearAll: {
    id: 'App.ProrateCalculator.Footer.confirmClearAll',
    defaultMessage:
      'Are you sure you want to clear all the fields of the screen?',
  },
});

export default messages;
