import { defineMessages } from 'react-intl';

const messages = defineMessages({
  phone: {
    id: 'App.ApplicationProfile.ProfileDetails.Phone',
    defaultMessage: 'Phone',
  },
  email: {
    id: 'App.ApplicationProfile.ProfileDetails.Email',
    defaultMessage: 'Email',
  },
  applicationId: {
    id: 'App.ApplicationProfile.ProfileDetails.AplicationId',
    defaultMessage: 'Application ID',
  },
  applicantStatus: {
    id: 'App.ApplicationProfile.ProfileDetails.ApplicantStatus',
    defaultMessage: 'Applicant Status',
  },
  edit: {
    id: 'App.ApplicationProfile.ProfileDetails.Edit',
    defaultMessage: 'Edit',
  },
  notes: {
    id: 'App.ApplicationProfile.ProfileDetails.Notes',
    defaultMessage: 'Notes:',
  },
  applicantTenant: {
    id: 'App.ApplicationProfile.ProfileDetails.ApplicantTenant',
    defaultMessage: 'APPLICANT TENANT',
  },
  unitNumber: {
    id: 'App.ApplicationProfile.ProfileDetails.UnitNumber',
    defaultMessage: 'Unit Number',
  },
  notAssigned: {
    id: 'App.ApplicationProfile.ProfileDetails.NotAssigned',
    defaultMessage: 'Not Assigned',
  },
  dba: {
    id: 'App.ApplicationProfile.ProfileDetails.Dba',
    defaultMessage: 'DBA',
  },
  applicationStatus: {
    id: 'App.ApplicationProfile.ProfileDetails.ApplicationStatus',
    defaultMessage: 'Application Status',
  },
});

export default messages;
