import { pathOr } from 'ramda';

import { disabledStatuses, enabledStatuses } from './constants';
import { formatCurrency } from '../../utils';

export const assignHideUnitFromOnlineAvailabilityToUnit = ({
  unitStatuses,
  unit,
}: {
  unitStatuses: Array<Object>,
  unit: Object,
}) => {
  const updatedUnit = { ...unit };
  const selectedUnitStatus = unitStatuses?.find((unitStatus) => {
    return unitStatus?.id === unit?.unitStatusId;
  });

  if (enabledStatuses.includes(selectedUnitStatus?.description)) {
    updatedUnit.hideUnitFromOnlineAvailability = false;
  }

  if (disabledStatuses.includes(selectedUnitStatus?.description)) {
    updatedUnit.hideUnitFromOnlineAvailability = true;
  }

  return updatedUnit;
};

export const formatAmenities = (
  intl: any,
  amenities: Array<Object>,
  variation: boolean = false,
) => {
  return amenities.map((amenity) => {
    const name = pathOr('', ['propertyFees', 'displayNameOnQuote'], amenity);
    const includeInCalculation = pathOr(
      false,
      ['propertyFees', 'includeInMarketRentCalculation'],
      amenity,
    );
    const feeAmount = pathOr(
      pathOr(0, ['propertyFees', 'feeAmount']),
      ['feeAmount'],
      amenity,
    );

    if (includeInCalculation) {
      const formattedFeeAmount = formatCurrency(
        intl,
        parseFloat(feeAmount),
        true,
      );
      return variation
        ? { name, formattedFeeAmount, feeAmount }
        : `${name} - (${formattedFeeAmount}) included in Market Rent and Quoting Rent`;
    } else {
      return variation ? { name } : name;
    }
  });
};
