import {
  compose,
  equals,
  keys,
  defaultTo,
  length,
  head,
  mergeAll,
  reject,
  values,
} from 'ramda';

/* eslint-disable max-len */
import validateBasicInformation from '../../../components/LeaseApplicantFormCommonSections/BasicInformationSection/validate';
import validateContactInformation from '../../../components/LeaseApplicantFormCommonSections/ContactInformationSection/validate';
import validateApplicantsEmployerSection from '../../../components/LeaseApplicantFormCommonSections/ApplicantsEmployerSection/validate';
import validateResidenceInformationSection from '../../../components/LeaseApplicantFormCommonSections/ResidenceInformationSection/validate';
import validateMaritalStatusSection from '../../../components/LeaseApplicantFormCommonSections/MaritalStatusSection/validate';
/* eslint-enable max-len */
import validateOtherInformationSection from '../OtherInformationSection/validate';

const validateProgress = (
  formValues: any,
  { intl, propertyState, propertyCity, flags }: Object,
) => {
  const basicInformation = validateBasicInformation(
    formValues.basicInformation,
    {
      intl,
    },
  );
  const contactInformation = validateContactInformation(
    formValues.contactInformation,
    { intl },
  );
  const applicantsCurrentEmployer = validateApplicantsEmployerSection(
    formValues.applicantsCurrentEmployer,
    { intl },
  );
  const applicantsSecondCurrentEmployer = validateApplicantsEmployerSection(
    formValues.applicantsSecondCurrentEmployer,
    {
      intl,
    },
  );
  const applicantsPreviousEmployer = validateApplicantsEmployerSection(
    formValues.applicantsPreviousEmployer,
    { intl },
  );
  const currentResidenceInformation = validateResidenceInformationSection(
    formValues.currentResidenceInformation,
    {
      intl,
    },
  );
  const previousResidenceInformation = validateResidenceInformationSection(
    formValues.previousResidenceInformation,
    {
      intl,
    },
  );
  const secondPreviousResidenceInformation =
    validateResidenceInformationSection(
      formValues.secondPreviousResidenceInformation,
      { intl },
    );
  const maritalInformation = validateMaritalStatusSection(
    formValues.maritalInformation,
    { intl },
  );
  const otherInformation = validateOtherInformationSection(
    formValues.otherInformation,
    { intl, propertyState, propertyCity, flags },
  );

  const arrayOfErrors: any = [
    { basicInformation },
    { applicantsCurrentEmployer },
    { applicantsSecondCurrentEmployer },
    { applicantsPreviousEmployer },
    { currentResidenceInformation },
    { previousResidenceInformation },
    { secondPreviousResidenceInformation },
    { maritalInformation },
    { otherInformation },
    { contactInformation },
  ];

  const hasError = compose(
    equals(0),
    length,
    keys,
    defaultTo({}),
    head,
    values,
  );

  const errors = mergeAll(reject(hasError, arrayOfErrors));

  return errors;
};

export default validateProgress;
