export const HEADER_ROWS = [
  {
    id: 'rowNumber',
    title: '',
    order: null,
  },
  {
    id: 'certificationType',
    title: 'type',
  },
  {
    id: 'propertyName',
    title: 'property',
  },
  {
    id: 'unitNumber',
    title: 'unit',
  },
  {
    id: 'applicantName',
    title: 'name',
  },
  {
    id: 'complianceDocumentType',
    title: 'complianceDocument',
  },
  {
    id: 'uploadDate',
    title: 'uploadDateTime',
  },
  {
    id: 'isReviewed',
    title: 'reviewed',
  },
  {
    id: 'programType',
    title: 'programType',
  },
  {
    id: 'voucherEffectiveDate',
    title: 'effectiveDate',
  },
  {
    id: 'certHistory',
    title: 'certHistory',
    order: null,
  },
  {
    id: 'complianceApprovalStatus',
    title: 'complianceApprovalStatus',
  },
  {
    id: 'isActive',
    title: 'active',
  },
  {
    id: 'isResident',
    title: 'resident',
  },
];

export const HEADER_ROWS_NEEDS_REVIEW = [
  {
    id: 'rowNumber',
    title: '',
    order: null,
  },
  {
    id: 'certificationType',
    title: 'type',
  },
  {
    id: 'propertyName',
    title: 'property',
  },
  {
    id: 'unitNumber',
    title: 'unit',
  },
  {
    id: 'applicantName',
    title: 'name',
  },
  {
    id: 'complianceDocumentType',
    title: 'complianceDocument',
  },
  {
    id: 'uploadDate',
    title: 'uploadDateTime',
  },
  {
    id: 'programType',
    title: 'programType',
  },
  {
    id: 'voucherEffectiveDate',
    title: 'effectiveDate',
  },
  {
    id: 'complianceApprovalStatus',
    title: 'complianceApprovalStatus',
  },
  {
    id: 'isActive',
    title: 'active',
  },
  {
    id: 'isResident',
    title: 'resident',
  },
];

export const FILTERS: Array<string> = [
  'certificationType',
  'complianceApprovalStatus',
  'isActive',
  'isResident',
  'programType',
  'waitlistApplicant',
];

export const FILTER_DESCRIPTIONS: Object = {
  certificationType: 'certificationType',
  complianceApprovalStatus: 'complianceApprovalStatus',
  isActive: 'active',
  isResident: 'resident',
  programType: 'programType',
  waitlistApplicant: 'waitlistApplicant',
};

export const COMPLIANCE_TYPES: Object = {
  ALL_OPEN_CERTS: 'allOpenCerts',
  NEEDS_REVIEW: 'needsReview',
};

export const FEATURE_FLAG_HEADERS: Object = {
  complianceOverviewReviewedAndNumberOfDays: ['isReviewed'],
  complianceCertHistorySummary: ['certHistory'],
};
