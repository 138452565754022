import { put, takeLatest, select } from 'redux-saga/effects';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import { actions as toastrActions } from 'react-redux-toastr';

import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import UnitService from '../../services/unitService';
import KpiService from '../../services/kpiService';
import AffordableSetUpService from '../../services/affordableSetUpService';
import {
  calculateActualIncomeAverage,
  parseKpiRows,
} from '../Home/PropertyComplianceTab/SetAsideSummary/utils';

import * as viewUnitConstants from './constants';
import * as viewUnitActions from './actions';

import type { Action, Property } from '../App/types';
import type { Saga } from 'redux-saga';

import messages from './messages';
import { pathOr } from 'ramda';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetUnitById({ payload }: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const unitService = new UnitService();
    const response = yield unitService.getById(
      property.id,
      organizationId,
      payload,
    );
    yield put(viewUnitActions.getUnitByIdSuccess(response));
  } catch (err) {
    yield put(viewUnitActions.getUnitByIdError(err));
  }
}

export function* getUnitById(): Saga<void> {
  yield takeLatest(viewUnitConstants.GET_UNIT_BY_ID, fetchGetUnitById);
}

export function* fetchGetFloorplanById({ payload }: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);
    const unitService = new UnitService();
    const response = yield unitService.getFloorplanById(
      property.id,
      organizationId,
      payload,
    );
    yield put(viewUnitActions.getFloorplanByIdSuccess(response));
  } catch (err) {
    yield put(viewUnitActions.getFloorplanByIdError(err));
  }
}

export function* getFloorplanById(): Saga<void> {
  yield takeLatest(
    viewUnitConstants.GET_FLOORPLAN_BY_ID,
    fetchGetFloorplanById,
  );
}

export function* fetchGetUnitStatuses(): Saga<void> {
  try {
    const unitService = new UnitService();
    const response = yield unitService.getAllUnitStatuses();
    yield put(viewUnitActions.getUnitStatusesSuccess(response));
  } catch (err) {
    yield put(viewUnitActions.getUnitStatusesError(err));
  }
}

export function* fetchPropertyIncomeAveragingPercent({
  payload,
}: Action<any>): Saga<void> {
  try {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);

    const affordableSetUpService = new AffordableSetUpService();
    const response =
      yield affordableSetUpService.getPropertyIncomeAveragingPercent(
        organizationId,
        property.id,
        abortController.signal,
      );

    const kpiService = new KpiService();
    const kpiResponse = yield kpiService.getKPI(
      organizationId,
      property.id,
      'setAsideSummary',
      abortController.signal,
    );

    const kpiData = parseKpiRows(kpiResponse);
    const incomeAveragingPercent = pathOr(
      null,
      ['incomeAveragingPercent'],
      response,
    );

    const incomeAvgPercent = isNaN(parseInt(incomeAveragingPercent, 10))
      ? null
      : parseInt(incomeAveragingPercent, 10);

    const actualIncomeAvg = calculateActualIncomeAverage(kpiData) || 0;

    yield put(
      viewUnitActions.getIncomeAverageDataSuccess({
        actualIncomeAvg,
        setAsideSummaryKPI: kpiData,
        incomeAvgPercent,
      }),
    );
  } catch (err) {
    yield put(viewUnitActions.getIncomeAverageDataError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: renderTranslatedMessage(err.toString()),
        title: renderTranslatedMessage(messages.incomeAverageError),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getUnitStatuses(): Saga<void> {
  yield takeLatest(viewUnitConstants.GET_UNIT_STATUSES, fetchGetUnitStatuses);
}

export function* fetchUpdateUnit({ payload }: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    validateSelectedProperty(property);

    const { unitId, ...rest } = payload;

    const unitService = new UnitService();
    yield unitService.updateUnit(property.id, organizationId, unitId, rest);
    yield put(viewUnitActions.getUnitById(unitId));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.success),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
          disableCloseButtonFocus: true,
        },
      }),
    );
  } catch (err) {
    yield put(viewUnitActions.getUnitStatusesError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err,
        title: renderTranslatedMessage(messages.error),
        options: {
          showCloseButton: true,
          removeOnHover: true,
          disableCloseButtonFocus: true,
        },
      }),
    );
  }
}

export function* updateUnit(): Saga<void> {
  yield takeLatest(viewUnitConstants.UPDATE_UNIT, fetchUpdateUnit);
}

export function* getIncomeAverage(): Saga<void> {
  yield takeLatest(
    viewUnitConstants.GET_AVERAGE_INCOME_DATA,
    fetchPropertyIncomeAveragingPercent,
  );
}

export default [
  getUnitById,
  getUnitStatuses,
  updateUnit,
  getFloorplanById,
  getIncomeAverage,
];
