import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';

import {
  renderRadioGroupField,
  renderTextField,
} from '../../../utils/redux-form-helper';
import messages from './messages';

import { maritalStatuses } from '../../LeaseAffordableApplicantFormCommonSections/configuration';

type Props = {
  intl: Object,
};

const MaritalStatusSection = ({ intl }: Props) => (
  <div className="form-content">
    <div className="form-block">
      <Row>
        <Col xs={12} md={2}>
          <h3 className="required">{intl.formatMessage(messages.title)}</h3>
        </Col>
        <Col xs={12} md={10}>
          <Field
            name="maritalStatus"
            fieldName="maritalStatus"
            component={renderRadioGroupField}
            options={maritalStatuses}
            inline
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={1} className="row-title">
          {intl.formatMessage(messages.spouse)}
        </Col>
        <Col xs={12} md={11}>
          <Row>
            <Col xs={12} md={3}>
              <Field
                name="firstName"
                component={renderTextField}
                className="input-lg"
                label={intl.formatMessage(messages.firstNameLabel)}
                placeholder={intl.formatMessage(messages.firstNameLabel)}
                showLabel
              />
            </Col>
            <Col xs={12} md={3}>
              <Field
                name="middleName"
                component={renderTextField}
                className="input-lg"
                label={intl.formatMessage(messages.middleNameLabel)}
                placeholder={intl.formatMessage(messages.middleNameLabel)}
                showLabel
              />
            </Col>
            <Col xs={12} md={3}>
              <Field
                name="lastName"
                component={renderTextField}
                className="input-lg"
                label={intl.formatMessage(messages.lastNameLabel)}
                placeholder={intl.formatMessage(messages.lastNameLabel)}
                showLabel
              />
            </Col>
            <Col xs={12} md={3}>
              <Field
                name="preferredName"
                component={renderTextField}
                className="input-lg"
                label={intl.formatMessage(messages.preferredNameLabel)}
                placeholder={intl.formatMessage(messages.preferredNameLabel)}
                showLabel
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </div>
);

export default MaritalStatusSection;
