import React, { Component } from 'react';
import { Grid, Form, Row, Col, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { Field } from 'redux-form';
import MultiselectTwoSides from '@fortress-technology-solutions/react-multiselect-two-sides';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import SubmitButton from '../../../components/SubmitButton';
import AssignUserProperty from '../../EditUser/AssignUserProperty';

import type {
  UserStatus,
  UserTitle,
  UserRole,
  Property,
} from '../../App/types';
import {
  renderTextField,
  renderCheckboxField,
  renderSelectField,
} from '../../../utils/redux-form-helper';
import messages from '../messages';
import validate from './validate';

export const FirstName = ({ intl }: Object) => {
  return (
    <Col xs={12}>
      <h3>
        {' '}
        <FormattedMessage {...messages.firstName} />{' '}
      </h3>
      <section>
        <Field
          name="firstName"
          component={renderTextField}
          bsSize="lg"
          placeholder={intl.formatMessage(messages.firstName)}
        />
      </section>
    </Col>
  );
};

export const LastName = ({ intl }: Object) => {
  return (
    <Col xs={12}>
      <h3>
        {' '}
        <FormattedMessage {...messages.lastName} />{' '}
      </h3>
      <section>
        <Field
          name="lastName"
          component={renderTextField}
          bsSize="lg"
          placeholder={intl.formatMessage(messages.lastName)}
        />
      </section>
    </Col>
  );
};

export const EmailAddress = ({ intl }: Object) => {
  return (
    <Col xs={12}>
      <h3>
        {' '}
        <FormattedMessage {...messages.emailAddress} />{' '}
      </h3>
      <section>
        <Field
          name="emailAddress"
          component={renderTextField}
          bsSize="lg"
          placeholder={intl.formatMessage(messages.emailAddress)}
        />
      </section>
    </Col>
  );
};

export const Username = ({ intl }: Object) => {
  return (
    <Col xs={12}>
      <h3>
        {' '}
        <FormattedMessage {...messages.username} />{' '}
      </h3>
      <section>
        <Field
          name="username"
          component={renderTextField}
          bsSize="lg"
          className="input-lg"
          placeholder={intl.formatMessage(messages.username)}
        />
      </section>
    </Col>
  );
};

export const FieldUserStatus = ({ userStatuses }: Object) => {
  return (
    <Col xs={12}>
      <h3>
        {' '}
        <FormattedMessage {...messages.userStatus} />{' '}
      </h3>
      <section>
        <Field
          name="userStatusId"
          component={renderSelectField}
          options={userStatuses}
          bsSize="lg"
        />
      </section>
    </Col>
  );
};

export const FieldUserTitle = ({ userTitles }: Object) => {
  return (
    <Col xs={12}>
      <h3>
        {' '}
        <FormattedMessage {...messages.userTitle} />{' '}
      </h3>
      <section>
        <Field
          name="userTitleId"
          component={renderSelectField}
          options={userTitles}
          bsSize="lg"
        />
      </section>
    </Col>
  );
};

export const FieldUserRole = ({ userRoles }: Object) => {
  return (
    <Col xs={12}>
      <h3>
        {' '}
        <FormattedMessage {...messages.userRole} />{' '}
      </h3>
      <section>
        <Field
          name="userRoleId"
          component={renderSelectField}
          options={userRoles}
          bsSize="lg"
        />
      </section>
    </Col>
  );
};

type Props = {
  intl: any,
  userStatuses: Array<UserStatus>,
  userTitles: Array<UserTitle>,
  userRoles: Array<UserRole>,
  properties: Array<Property>,
  handleSubmit: Function,
  handleCancel: Function,
  valid: any,
  submitting: any,
  selectedProperties: Array<string>,
  selectedPropertyGroups: Arrray<string>,
  handleMultiselectChange: Function,
  hideMultiselect: boolean,
  toggleMultiselect: Function,
  nonePropertiesSelected: boolean,
  showErrorMessage: boolean,
  errorMessage: string,
  pristine: boolean,
  change: Function,
  isSubmitting: boolean,
  organizationId: string,
  handlePropertiesSelectedError: Function,
  handleAllPropertiesSelection: Function,
  handlePropertyGroupSelectionChange: Function,
  flags: any,
};

type State = {
  value: Array<Property>,
  hideMultiselect: boolean,
};

export class CreateUserForm extends Component<Props, State> {
  componentWillReceiveProps(nextProps: any) {
    if (nextProps !== this.props) {
      this.props.change('properties', nextProps.selectedProperties);
      if (nextProps.selectedProperties.length >= 1) {
        this.props.handlePropertiesSelectedError(false);
      }
    }
  }

  render() {
    const {
      intl,
      userStatuses,
      userTitles,
      userRoles,
      properties,
      handleSubmit,
      nonePropertiesSelected,
      valid,
      selectedProperties,
      selectedPropertyGroups,
      hideMultiselect,
      toggleMultiselect,
      handleMultiselectChange,
      isSubmitting,
      organizationId,
      propertyClasses,
      propertyList,
      handleAllPropertiesSelection,
      handlePropertySelectionChange,
      handlePropertyGroupSelectionChange,
      allPropertiesSelected,
      flags,
    } = this.props;

    const selectedCount = selectedProperties.length;
    const availableCount = properties.length - selectedCount;
    const assignUserPropertyGroupFlag =
      flags?.adminEnhancementsUsersAssigntopropertytype ?? false;
    return (
      <Grid className="bodywrap col-centered" fluid={true}>
        <Form>
          <Row>
            <Col xs={12}>
              <h1
                className="title"
                style={{ display: 'inline-block', marginRight: '5px' }}
              >
                {intl.formatMessage(messages.header)}
              </h1>
              <h5 className="small" style={{ display: 'inline-block' }}>
                *<FormattedMessage {...messages.subheader} />
              </h5>
            </Col>
          </Row>
          <Row>
            <FirstName intl={intl} />
          </Row>
          <Row>
            <LastName intl={intl} />
          </Row>
          <Row>
            <EmailAddress intl={intl} />
          </Row>
          <Row>
            <Username intl={intl} />
          </Row>
          {assignUserPropertyGroupFlag ? (
            <AssignUserProperty
              properties={propertyList}
              propertyClasses={propertyClasses}
              intl={intl}
              organizationId={organizationId}
              selectedPropertyIds={selectedProperties}
              onAllPropertiesSelection={handleAllPropertiesSelection}
              allPropertiesSelected={allPropertiesSelected}
              handlePropertySelectionChange={handlePropertySelectionChange}
              handlePropertyGroupSelectionChange={
                handlePropertyGroupSelectionChange
              }
              selectedPropertyGroupIds={selectedPropertyGroups}
              noneSelected={nonePropertiesSelected}
            />
          ) : (
            <Row>
              <Col xs={12}>
                <h3 className="hidden-xs">
                  {' '}
                  <FormattedMessage {...messages.assignToProperty} />{' '}
                </h3>
                <MultiselectTwoSides
                  className={[
                    'msts_theme_example',
                    hideMultiselect ? 'msts-disabled' : '',
                    nonePropertiesSelected ? 'msts-error' : '',
                  ].join(' ')}
                  onChange={handleMultiselectChange}
                  availableHeader="Available"
                  availableFooter={`Available: ${availableCount}`}
                  selectedHeader="Selected"
                  selectedFooter={`Selected: ${selectedCount}`}
                  placeholder="Filter…"
                  options={properties}
                  searchable={true}
                  showControls={true}
                  value={selectedProperties}
                />
                {nonePropertiesSelected && (
                  <span className="has-error">
                    Please select at least 1 property
                  </span>
                )}
                <Field
                  name="allProperties"
                  onChange={toggleMultiselect}
                  component={renderCheckboxField}
                  label={intl.formatMessage(messages.assignAllProperties)}
                  bsSize="lg"
                />
              </Col>
            </Row>
          )}
          <Row>
            <FieldUserStatus userStatuses={userStatuses} intl={intl} />
          </Row>
          <Row>
            <FieldUserTitle userTitles={userTitles} intl={intl} />
          </Row>
          <Row>
            <FieldUserRole userRoles={userRoles} intl={intl} />
          </Row>
          <Row>
            <Col xs={12} sm={6}>
              <Button
                onClick={this.props.handleCancel}
                className="pull-right btn-xs-block"
                type="button"
                bsStyle="default"
              >
                Cancel
              </Button>
            </Col>
            <Col xs={12} sm={6}>
              <ElementWithPermissions scope={['user-create']}>
                <SubmitButton
                  classes="btn btn-primary btn-xs-block"
                  disabled={isSubmitting || !valid}
                  clickHandler={handleSubmit}
                  isSubmitting={isSubmitting}
                >
                  Add User
                </SubmitButton>
              </ElementWithPermissions>
            </Col>
          </Row>
        </Form>
      </Grid>
    );
  }
}

CreateUserForm = reduxForm({
  form: 'addUser',
  validate: validate,
})(CreateUserForm);

export default CreateUserForm;
