import { defineMessages } from 'react-intl';

const messages = defineMessages({
  clearFilters: {
    id: 'App.ManageWorkOrders.FilterDrawer.ClearFilters',
    defaultMessage: 'Clear Filters'
  },
  apply: {
    id: 'App.ManageWorkOrders.FilterDrawer.Apply',
    defaultMessage: 'Apply'
  },
  filterBy: {
    id: 'App.ManageWorkOrders.FilterDrawer.FilterBy',
    defaultMessage: 'Filter by'
  },
  close: {
    id: 'App.ManageWorkOrders.FilterDrawer.Close',
    defaultMessage: 'Close'
  }
});

export default messages;
