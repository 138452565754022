import { useMemo } from 'react';
import { HEADERS } from './constantsV2';
import {
  CERTIFICATION_TYPE_DISPLAY_NAMES,
  GROSS_RENT_CERT_TYPE,
} from '../../constants/affordableCertificationTypes';
import { processTableFilterOptions } from '../../utils/table-helpers';
import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Atoms';

const useBuildFilterOptions = ({ results, selectedCertificationType }) => {
  return useMemo(() => {
    const options = {
      certificationType: [],
      status: [],
      occupiedOnNTV: [],
      programName: [],
      complianceDocument: [],
    };
    if (results) {
      results.forEach((result) => {
        // RECERT_CERT_TYPE, MOVE_IN_CERT_TYPE
        if (result.programName) {
          options.programName.push(result.programName);
        }

        // RECERT_CERT_TYPE
        if (result.recertStatus) {
          options.status.push(result.recertStatus);
        }
        if (result.complianceDocument) {
          options.complianceDocument.push(result.complianceDocument);
        }

        // MOVE_IN_CERT_TYPE
        if (result.complianceApprovalStatus?.name) {
          options.status.push(result.complianceApprovalStatus.name);
        }
        if (result.latestDocument?.documentType?.name) {
          options.complianceDocument.push(
            result.latestDocument.documentType.name,
          );
        }

        // OTHER_HUD
        if (result.certificationType) {
          // Also adding the display name in [hooks.useParseResults]
          options.certificationType.push(
            CERTIFICATION_TYPE_DISPLAY_NAMES[result.certificationType] ??
              result.certificationType,
          );
        }

        // GROSS_RENT
        if (GROSS_RENT_CERT_TYPE === selectedCertificationType) {
          if (result.occupiedOnNTV && result.occupiedOnNTV !== '---') {
            options.occupiedOnNTV.push(result.occupiedOnNTV);
          }
        }
      });
    }

    // Remove duplicates, sort, and map to label/value objects
    processTableFilterOptions(options);

    return options;
  }, [results, selectedCertificationType]);
};

const useHeaders = ({
  results,
  selectedCertificationType,
  grossRentEnhancementsFlag,
  checkBoxListProps: { handleSelectDeSelectAllRows },
}) => {
  const filterOptions = useBuildFilterOptions({
    results,
    selectedCertificationType,
  });

  return useMemo(() => {
    const headers = [];
    if (!HEADERS[selectedCertificationType]) return headers;

    HEADERS[selectedCertificationType].forEach((header) => {
      headers.push({
        ...header,
        filterOptions: header.filterOptions ?? filterOptions[header.id],
      });
    });

    if (
      grossRentEnhancementsFlag &&
      GROSS_RENT_CERT_TYPE === selectedCertificationType
    ) {
      const checkboxHeader = {
        id: 'checkbox',
        label: (
          <Checkbox
            onChange={(e) => handleSelectDeSelectAllRows(e)}
            color="secondary"
            sx={{
              color: 'white',
            }}
          />
        ),
      };
      headers.unshift(checkboxHeader);
    }

    return headers;
  }, [filterOptions, selectedCertificationType, grossRentEnhancementsFlag]);
};

export default useHeaders;
