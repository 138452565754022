import { defineMessages } from 'react-intl';

const messages = defineMessages({
  status: {
    id: 'App.CreateSubsidyBatchDetails.Status',
    defaultMessage: 'Status',
  },
  unitResident: {
    id: 'App.CreateSubsidyBatchDetails.UnitResident',
    defaultMessage: 'Unit/Resident',
  },
  currentSubsidyBalance: {
    id: 'App.CreateSubsidyBatchDetails.currentSubsidyBalance',
    defaultMessage: 'Current Subsidy Balance',
  },
  depositAmount: {
    id: 'App.CreateSubsidyBatchDetails.DepositAmount',
    defaultMessage: 'Deposit Amount',
  },
  finalSB: {
    id: 'App.CreateSubsidyBatchDetails.FinalSB',
    defaultMessage: 'Final Subsidy Balance',
  },
  note: {
    id: 'App.CreateSubsidyBatchDetails.Note',
    defaultMessage: 'Note',
  },
  transactionCode: {
    id: 'App.CreateSubsidyBatchDetails.TransactionCode',
    defaultMessage: 'Transaction Code',
  },
  noDataDescription: {
    id: 'App.CreateSubsidyBatchDetails.NoDataDescription',
    defaultMessage: 'There is currently no data to display',
  },
  notes: {
    id: 'App.CreateSubsidyBatchDetails.Notes',
    defaultMessage: 'NOTES:',
  },
  notes1: {
    id: 'App.CreateSubsidyBatchDetails.Notes1',
    defaultMessage:
      '(1) Deposit Amount must equal Subsidy Deposit Total in order to Post and Deposit.',
  },
  notes2: {
    id: 'App.CreateSubsidyBatchDetails.Notes2',
    defaultMessage:
      '(2) Amounts entered here do not post to Resident Ledgers until the Subsidy Batch is "Posted."',
  },
  notes3: {
    id: 'App.CreateSubsidyBatchDetails.Notes3',
    defaultMessage:
      '(3) Fortress will automatically enter $0.00 for any blank Deposit Amount fields when the batch is saved.',
  },
  delete: {
    id: 'App.CreateSubsidyBatchDetails.Delete',
    defaultMessage: 'Delete',
  },
  totals: {
    id: 'App.CreateSubsidyBatchDetails.Totals',
    defaultMessage: 'Totals',
  },
  saveClose: {
    id: 'App.CreateSubsidyBatchDetails.SaveClose',
    defaultMessage: 'Save and Close',
  },
  postDeposit: {
    id: 'App.CreateSubsidyBatchDetails.PostDeposit',
    defaultMessage: 'Post and Deposit',
  },
  close: {
    id: 'App.CreateSubsidyBatchDetails.Close',
    defaultMessage: 'Close',
  },
  confirmDialogMessageTitle: {
    id: 'App.CreateSubsidyBatchDetails.ConfirmDialogMessageTitle',
    defaultMessage:
      'Are you sure you want to post and deposit this subsidy batch?',
  },
  yes: {
    id: 'App.CreateSubsidyBatchDetails.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.CreateSubsidyBatchDetails.No',
    defaultMessage: 'No',
  },
  note1: {
    id: 'App.CreateSubsidyBatchDetails.Note1',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Batch may take a few minutes to post and deposit payments. The status of the batch will automatically update when complete.',
  },
  confirmDeleteMessageTitle: {
    id: 'App.CreateSubsidyBatchDetails.ConfirmDeleteMessageTitle',
    defaultMessage: 'Are you sure you want to delete this subsidy batch?',
  },
  confirmDeleteNote: {
    id: 'App.CreateSubsidyBatchDetails.ConfirmDeleteNote',
    defaultMessage:
      'This will delete any progress and nothing will post to ledgers.',
  },
});

export default messages;
