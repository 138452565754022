export const GET_ALL_SUBJOURNALS = 'app/Ledger/GET_ALL_SUBJOURNALS';
export const GET_ALL_SUBJOURNALS_SUCCESS =
  'app/Ledger/GET_ALL_SUBJOURNALS_SUCCESS';
export const GET_ALL_SUBJOURNALS_ERROR = 'app/Ledger/GET_ALL_SUBJOURNALS_ERROR';

export const GET_CUSTOMER_LEDGER_HEADER =
  'app/Ledger/GET_CUSTOMER_LEDGER_HEADER';
export const GET_CUSTOMER_LEDGER_HEADER_SUCCESS =
  'app/Ledger/GET_CUSTOMER_LEDGER_HEADER_SUCCESS';
export const GET_CUSTOMER_LEDGER_HEADER_ERROR =
  'app/Ledger/GET_CUSTOMER_LEDGER_HEADER_ERROR';

export const GET_CUSTOMER_OPS_TRANSACTIONS =
  'app/Ledger/GET_CUSTOMER_OPS_TRANSACTIONS';
export const GET_CUSTOMER_OPS_TRANSACTIONS_SUCCESS =
  'app/Ledger/GET_CUSTOMER_OPS_TRANSACTIONS_SUCCESS';
export const GET_CUSTOMER_OPS_TRANSACTIONS_ERROR =
  'app/Ledger/GET_CUSTOMER_OPS_TRANSACTIONS_ERROR';

export const GET_CUSTOMER_SECURITY_TRANSACTIONS =
  'app/Ledger/GET_CUSTOMER_SECURITY_TRANSACTIONS';
export const GET_CUSTOMER_SECURITY_TRANSACTIONS_SUCCESS =
  'app/Ledger/GET_CUSTOMER_SECURITY_TRANSACTIONS_SUCCESS';
export const GET_CUSTOMER_SECURITY_TRANSACTIONS_ERROR =
  'app/Ledger/GET_CUSTOMER_SECURITY_TRANSACTIONS_ERROR';

export const CLEAR_LOADED_LEDGER = 'app/Ledger/CLEAR_LOADED_LEDGER';

export const REVERSE_TRANSACTION = 'app/Ledger/REVERSE_TRANSACTION';
export const FINANCIAL_TRANSACTION_FAILURE =
  'app/Ledger/FINANCIAL_TRANSACTION_FAILURE';
export const REVERSE_TRANSACTION_PENDING =
  'app/Ledger/REVERSE_TRANSACTION_PENDING';
export const FINANCIAL_TRANSACTION_SUCCESS =
  'app/Ledger/FINANCIAL_TRANSACTION_SUCCESS';

export const HANDLE_SOCKET_FINANCIAL_TRANSACTION_FAILURE =
  'app/Ledger/HANDLE_SOCKET_FINANCIAL_TRANSACTION_FAILURE';
export const HANDLE_SOCKET_FINANCIAL_TRANSACTION_SUCCESS =
  'app/Ledger/HANDLE_SOCKET_FINANCIAL_TRANSACTION_SUCCESS';

export const REFRESH_LEDGER = 'app/Ledger/REFRESH_LEDGER';
export const LOCK_LEDGER_ACTIONS = 'app/Ledger/LOCK_LEDGER_ACTIONS';

export const UPDATE_PAYMENT_RESTRICTIONS =
  'app/Ledger/UPDATE_PAYMENT_RESTRICTIONS';
export const UPDATE_PAYMENT_RESTRICTIONS_SUCCESS =
  'app/Ledger/UPDATE_PAYMENT_RESTRICTIONS_SUCCESS';

export const CREATE_APPLICANT_REFUND_STATEMENT =
  'app/Ledger/CREATE_APPLICANT_REFUND_STATEMENT';
