/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import FloorPlanService from '../../../services/floorPlanService';
import FloorPlanPricingService from '../../../services/floorPlanPricingService';
import {
  initializeFormValues,
  processFloorPlanWithPricingResult,
} from './utils';

export const useFetchAsyncFloorPlansWithPricing = (
  organizationId: string,
  propertyId: string,
  isModalShown: boolean,
  promptToaster: Function,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [floorPlans, setFloorPlans] = useState([]);
  const [initialFormValues, setInitialFormValues] = useState({});

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchFloorPlansWithPricing = async () => {
      try {
        setIsLoading(true);
        const floorPlanService = new FloorPlanService();
        const options = { signal: abortController.signal };
        const result = await floorPlanService.getAllWithPricing(
          organizationId,
          propertyId,
          options,
        );
        setFloorPlans(processFloorPlanWithPricingResult(result));
        setInitialFormValues(
          initializeFormValues(result.map((floorPlan) => floorPlan.id)),
        );
      } catch (e) {
        promptToaster({
          type: 'error',
          title: 'Error loading floorplans',
          message: e.toString(),
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (subscribed && isModalShown) {
      fetchFloorPlansWithPricing();
    }

    return () => {
      subscribed = false;
    };
  }, [organizationId, propertyId, isModalShown]);

  return { isLoading, floorPlans, initialFormValues };
};

export const useAsyncBatchCreateFloorPlansPricing = (
  organizationId: string,
  propertyId: string,
  floorPlansPricings: Array<Object>,
  onClose: Function,
  promptToaster: Function,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const postFloorPlans = async () => {
      try {
        setIsSubmitting(true);
        const floorPlanPricingService = new FloorPlanPricingService();
        const options = { signal: abortController.signal };
        await floorPlanPricingService.batchCreate(
          organizationId,
          propertyId,
          floorPlansPricings,
          options,
        );
        onClose(true);
        promptToaster({
          type: 'success',
          title: 'Success',
          message: 'Hud Limits saved.',
        });
      } catch (e) {
        promptToaster({
          type: 'error',
          title: 'Error saving hud limits.',
          message: e.toString(),
        });
      } finally {
        setIsSubmitting(false);
      }
    };

    if (subscribed && floorPlansPricings.length > 0) {
      postFloorPlans();
    }

    return () => {
      subscribed = false;
    };
  }, [floorPlansPricings, promptToaster]); // eslint-disable-line react-hooks/exhaustive-deps

  return { isSubmitting };
};
