import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import { Field } from 'redux-form';
import {
  Box,
  Card,
  Stack,
  Typography,
  SubmitButton,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import ErrorMessage from '../../../components/ErrorMessage';
import {
  renderTextField,
  renderPasswordField,
  renderCheckboxField,
} from '../../../utils/redux-form-helper';
import messages from '../messages';
import validate from './validate';

export const LoginFormView = ({
  intl,
  handleSubmit,
  handleForget,
  remember,
  username,
  showErrorMessage,
  errorMessage,
  history,
  valid,
  toggleRememberMe,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (showErrorMessage && isSubmitting) {
      setIsSubmitting(false);
    }
  }, [showErrorMessage, isSubmitting]);

  return (
    <Form
      onSubmit={(login) => {
        setIsSubmitting(true);
        handleSubmit(login);
      }}
    >
      {showErrorMessage && <ErrorMessage message={errorMessage} />}

      {remember && username ? (
        <div className="login-details text-center">
          <Typography paragraph={true} variant={'h4'}>
            {intl.formatMessage(messages.welcomeBack)}
          </Typography>
          <Typography paragraph={true} variant={'h3'}>
            {username}
          </Typography>
          <Typography variant={'textLink'}>
            <a id={'handle-forget'} onClick={() => handleForget()}>
              {' '}
              {intl.formatMessage(messages.changeUser)}
            </a>
          </Typography>
        </div>
      ) : (
        <Field
          name="username"
          showLabel={true}
          classes="blue-label pull-left"
          label={intl.formatMessage(messages.username)}
          component={renderTextField}
          bsSize="lg"
        />
      )}
      <span className="pull-right">
        <a id="forgotPw" onClick={() => history.push('/forgot-password')}>
          {intl.formatMessage(messages.forgotPassword)}
        </a>
      </span>
      <br />
      <Field
        name="password"
        classes="blue-label pull-left"
        label={intl.formatMessage(messages.password)}
        component={renderPasswordField}
        bsSize="lg"
      />
      <Field
        name="rememberMe"
        component={renderCheckboxField}
        onChange={toggleRememberMe}
        label={intl.formatMessage(messages.rememberUser)}
      />
      <Stack justifyContent={'center'} alignItems={'center'}>
        <SubmitButton
          variant={'primary'}
          type="submit"
          isSubmitting={isSubmitting}
          sx={{ height: 40, width: 160 }}
          disabled={!valid || isSubmitting}
        >
          {intl.formatMessage(messages.signIn)}
        </SubmitButton>
      </Stack>
    </Form>
  );
};

export const LoginForm = ({
  intl,
  handleSubmit,
  handleForget,
  rememberMe,
  username,
  showErrorMessage,
  errorMessage,
  submitting,
  history,
  valid,
  toggleRememberMe,
  environmentName,
}: any) => {
  return (
    <Box
      sx={{
        margin: 'auto',
        position: 'relative',
        top: {
          xs: '15dvh',
          sm: '25vh',
        },
        width: {
          xs: 'calc(100vw - 32px)',
          sm: 450,
        },
      }}
    >
      <Card
        sx={{
          paddingX: 4,
          paddingY: 4,
          borderStyle: 'unset',
          borderWidth: 'unset',
          borderBottom: '5px solid transparent',
          borderColor: {
            development: '#7734e7',
            training: '#4cbb17',
            staging: '#98beff',
          }[environmentName],
        }}
      >
        <Typography
          variant={'h1'}
          className="fortress-logo-gray"
          align={'center'}
          sx={{
            height: 56,
          }}
        >
          <span style={{ visibility: 'hidden' }}>Fortress</span>
        </Typography>
        <Typography paragraph align={'center'} mb={4}>{`${environmentName
          .charAt(0)
          .toUpperCase()}${environmentName.slice(1)}`}</Typography>

        <LoginFormView
          intl={intl}
          handleSubmit={handleSubmit}
          handleForget={handleForget}
          valid={valid}
          submitting={submitting}
          showErrorMessage={showErrorMessage}
          errorMessage={errorMessage}
          remember={rememberMe}
          username={username}
          toggleRememberMe={toggleRememberMe}
          history={history}
        />
      </Card>
      <Typography variant={'caption'} paragraph={true} align={'center'} mt={2}>
        {intl.formatMessage(messages.help)}
      </Typography>
    </Box>
  );
};
export default reduxForm({
  form: 'login',
  validate: validate,
  enableReinitialize: true,
})(LoginForm);
