import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageDeposits.ManageDepositsHeader.Title',
    defaultMessage: 'Manage Deposits'
  },
  filterByPeriod: {
    id: 'App.ManageDeposits.ManageDepositsHeader.FilterByPeriod',
    defaultMessage: 'Filter by Period'
  }
});

export default messages;
