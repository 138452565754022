import type { Action } from '../App/types';
import type { OverviewState } from './types';
import * as ActionTypes from './constants';
import { SELECT_PROPERTY } from '../App/constants';

const initialState: OverviewState = {
  dataLoaded: false,
  prospectAndApplicationTrafficSources: {
    value: {},
    kpiLoaded: false,
  },
  employeeActivity: {
    value: {},
    kpiLoaded: false,
  },
  weekOverWeekActivity: {
    value: {},
    kpiLoaded: false,
  },
  leasesOverview: {
    value: {},
    kpiLoaded: false,
  },
};

const manageProspectsReducer = (
  state: OverviewState = initialState,
  action: Action<*, *>,
): OverviewState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ALL_LEASING_KPIS: {
      const dataLoaded = state.dataLoaded ? true : false;
      return { ...initialState, dataLoaded, fetching: true };
    }
    case ActionTypes.GET_ALL_LEASING_KPIS_SUCCESS: {
      return { ...state, ...payload, fetching: false };
    }
    case SELECT_PROPERTY: {
      return { ...state, fetching: true };
    }
    case ActionTypes.CLEAN_LOADED_LEASING_KPIS: {
      return initialState;
    }
    case ActionTypes.GET_LEASING_KPI: {
      return { ...state, [payload.kpi]: { value: {}, kpiLoaded: false } };
    }
    case ActionTypes.GET_LEASING_KPI_SUCCESS: {
      return { ...state, ...payload, dataLoaded: true, fetching: false };
    }
    default:
      return state;
  }
};

export default manageProspectsReducer;
