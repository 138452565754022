import { useCallback, useEffect, useRef, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import useFormatMessage from '../../../hooks/useFormatMessage';
import InsurancePolicyService from '../../../services/insurancePolicyService';
import messages from './messages';
import { REQUEST_ABORTED } from '../../../utils/api';

export const useInsurancePolicies = (
  organizationId: string,
  propertyId: string,
  householdId: string,
) => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const abortController = useRef();

  const [insurancePolicies, setInsurancePolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [wasPolicyCreated, setWasPolicyCreated] = useState(true);

  const formatMessage = useFormatMessage();

  const getInsurancePolicies = useCallback(async () => {
    const insurancePolicyService = new InsurancePolicyService();
    abortController.current = new AbortController();

    try {
      safeSet(setIsLoading)(true);
      const response = await insurancePolicyService.getInsurancePolicies(
        organizationId,
        propertyId,
        householdId,
        { signal: abortController.current.signal },
      );

      if (response) {
        safeSet(setInsurancePolicies)(response);
      }
    } catch (error) {
      if (error.networkError !== REQUEST_ABORTED) {
        toastr.error(formatMessage(messages.error), error.toString());
      }
    } finally {
      safeSet(setIsLoading)(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, propertyId, householdId]);

  useEffect(() => {
    getInsurancePolicies();

    return () => abortController.current.abort();
  }, [getInsurancePolicies]);

  const addInsurancePolicy = async (payload) => {
    safeSet(setWasPolicyCreated)(false);
    const insurancePolicyService = new InsurancePolicyService();

    try {
      safeSet(setIsLoading)(true);
      await insurancePolicyService.addInsurancePolicy(
        organizationId,
        propertyId,
        householdId,
        payload,
      );

      toastr.success(
        formatMessage(messages.success),
        formatMessage(messages.insuranceAdded),
      );
      safeSet(setWasPolicyCreated)(true);
      await getInsurancePolicies();
    } catch (error) {
      toastr.error(formatMessage(messages.error), error.toString());
    } finally {
      safeSet(setIsLoading)(false);
    }
  };

  const editInsurancePolicy = async (insuranceId, payload) => {
    safeSet(setWasPolicyCreated)(false);
    const insurancePolicyService = new InsurancePolicyService();

    try {
      safeSet(setIsLoading)(true);
      await insurancePolicyService.editInsurancePolicy(
        organizationId,
        propertyId,
        householdId,
        insuranceId,
        payload,
      );

      toastr.success(
        formatMessage(messages.success),
        formatMessage(messages.insuranceUpdated),
      );
      safeSet(setWasPolicyCreated)(true);
      await getInsurancePolicies();
    } catch (error) {
      toastr.error(formatMessage(messages.error), error.toString());
    } finally {
      safeSet(setIsLoading)(false);
    }
  };

  const resetWasPolicyCreated = () => {
    safeSet(setWasPolicyCreated)(false);
  };

  const safeSet = (setFn: Function) => (value: any) => {
    if (isMounted.current) {
      setFn(value);
    }
  };

  return [
    insurancePolicies,
    isLoading,
    addInsurancePolicy,
    wasPolicyCreated,
    editInsurancePolicy,
    resetWasPolicyCreated,
  ];
};
