import { push } from 'react-router-redux';
import { call, put, select, takeLatest, throttle } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { defaultTo, find, pathOr, propEq } from 'ramda';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';

import ApplicationService from '../../services/applicationService';
import PropertyService from '../../services/propertyService';
import PayLeaseService from '../../services/payLeaseService';
import * as ActionTypes from './constants';
import * as createResidentActions from './actions';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';
import messages from './messages';

import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';

import type { Saga } from 'redux-saga';
import type { Action, Property } from '../App/types';
import type { MoveInInfo } from './types';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchGetChecklistStatus(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicantInformation = {
      applicationId: action.payload,
      propertyId: selectedProperty.id,
    };
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplicationChecklistStatus,
      applicantInformation,
    );
    yield put(
      createResidentActions.getApplicationChecklistStatusSucess(response),
    );
  } catch (err) {
    yield put(createResidentActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetScreeningStatus(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicantInformation = {
      applicationId: action.payload,
      propertyId: selectedProperty.id,
    };
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplicationScreeningStatus,
      applicantInformation,
    );
    yield put(
      createResidentActions.getApplicationScreeningStatusSucess(response),
    );
  } catch (err) {
    yield put(createResidentActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetSignedLeaseStatus(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicantInformation = {
      applicationId: action.payload,
      propertyId: selectedProperty.id,
    };
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplicationSignedLeaseStatus,
      applicantInformation,
    );
    yield put(
      createResidentActions.getApplicationSignedLeaseStatusSucess(response),
    );
  } catch (err) {
    yield put(createResidentActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetVehicleStatus(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicantInformation = {
      applicationId: action.payload,
      propertyId: selectedProperty.id,
    };
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplicationVehicleStatus,
      applicantInformation,
    );
    yield put(createResidentActions.getVehicleStatusSuccess(response));
  } catch (err) {
    yield put(createResidentActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetLeaseStatus(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicantInformation = {
      applicationId: action.payload,
      propertyId: selectedProperty.id,
    };
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplicationLeaseStatus,
      applicantInformation,
    );
    yield put(createResidentActions.getApplicationLeaseStatusSucess(response));
  } catch (err) {
    yield put(createResidentActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetLeaseStartDateStatus(
  action: Action<string>,
): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicantInformation = {
      applicationId: action.payload,
      propertyId: selectedProperty.id,
    };
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplicationLeaseStartDateStatus,
      applicantInformation,
    );
    yield put(
      createResidentActions.getApplicationLeaseStartDateStatusSucess(response),
    );
  } catch (err) {
    yield put(createResidentActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetUnitAvailabilityStatus(
  action: Action<string>,
): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicantInformation = {
      applicationId: action.payload,
      propertyId: selectedProperty.id,
    };
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getUnitAvailabilityStatus,
      applicantInformation,
    );
    yield put(createResidentActions.getUnitAvailabilityStatusSuccess(response));
  } catch (err) {
    yield put(createResidentActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetMoveInAction(): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplicationMoveInActions,
      {
        propertyId: selectedProperty.id,
      },
    );
    yield put(createResidentActions.getMoveInSucesssStatus(response));
  } catch (err) {
    yield put(createResidentActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* fetchGetOneApplication(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicationService = new ApplicationService();
    const response = yield call(
      applicationService.getApplication,
      organizationId,
      selectedProperty.id,
      action.payload,
    );
    yield put(createResidentActions.getOneApplicationSuccess(response));
  } catch (err) {
    yield put(createResidentActions.getApplicantInformationError(err));
  }
}

export function* fetchGetOnePropertyDetails(
  action: Action<string>,
): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const propertyService = new PropertyService();
    const response = yield call(
      propertyService.getOneProperty,
      organizationId,
      selectedProperty.id,
      action.payload,
    );
    yield put(createResidentActions.getOnePropertySuccess(response));
  } catch (err) {
    yield put(createResidentActions.getApplicantInformationError(err));
  }
}

export function* putMoveIn(action: Action<MoveInInfo>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicationService = new ApplicationService();
    const residents = yield applicationService.moveIn(
      organizationId,
      selectedProperty.id,
      action.payload.applicationId,
      action.payload.actualMoveInDate,
      action.payload.requiredActions,
      action.payload.moveInWithoutScreening,
      action.payload.prorated,
    );

    if (selectedProperty && selectedProperty.isPayLeaseActive === true) {
      const payLeaseService = new PayLeaseService();
      yield payLeaseService.sendResidentEmails(
        organizationId,
        selectedProperty.id,
        action.payload.applicationId,
      );
    }

    const primary = defaultTo({}, find(propEq('isPrimary', true), residents));
    const residentId = pathOr('', ['residentId'])(primary);
    yield put(createResidentActions.moveInSuccess());
    yield put(push(getUrlWithSelectedPropertyId(`/resident/${residentId}`)));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(createResidentActions.moveInError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getApplicationChecklistStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_CHECKLIST_STATUS,
    fetchGetChecklistStatus,
  );
}

export function* getApplicationScreeningStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_SCREENING_STATUS,
    fetchGetScreeningStatus,
  );
}

export function* getApplicationSignedLeaseStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_SIGNED_LEASE_STATUS,
    fetchGetSignedLeaseStatus,
  );
}

export function* getApplicationVehicleStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_VEHICLE_STATUS,
    fetchGetVehicleStatus,
  );
}

export function* getApplicationLeaseStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_LEASE_STATUS,
    fetchGetLeaseStatus,
  );
}

export function* getApplicationLeaseStartDateStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_LEASE_START_DATE_STATUS,
    fetchGetLeaseStartDateStatus,
  );
}

export function* getApplicationMoveInActions(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICATION_MOVE_IN_ACTION,
    fetchGetMoveInAction,
  );
}

export function* getOneApplicationSaga(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ONE_APPLICATION, fetchGetOneApplication);
}

export function* getOnePropertyDetailsSaga(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ONE_PROPERTY, fetchGetOnePropertyDetails);
}

export function* moveIn(): Saga<void> {
  yield throttle(500, ActionTypes.MOVEIN, putMoveIn);
}

export function* getUnitAvailabilityStatus(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_UNIT_AVAILABILITY_STATUS,
    fetchGetUnitAvailabilityStatus,
  );
}

export default [
  getApplicationChecklistStatus,
  getApplicationScreeningStatus,
  getApplicationSignedLeaseStatus,
  getApplicationVehicleStatus,
  getApplicationLeaseStatus,
  getApplicationMoveInActions,
  getOneApplicationSaga,
  getOnePropertyDetailsSaga,
  getApplicationLeaseStartDateStatus,
  moveIn,
  getUnitAvailabilityStatus,
];
