import { useMemo } from 'react';
import { useQuery } from 'react-query';

import PropertyGroupService from '../../services/propertyGroupService';

import generalMessages from '../../containers/App/messages';

export const useFetchPropertyGroups = ({
  organizationId,
  promptToaster,
  intl,
}: Object) => {
  const propertyGroupService = useMemo(() => {
    return new PropertyGroupService();
  }, []);

  return useQuery(
    'ManagePropertyGroups > Get Property Groups',
    async () => {
      const propertyGroups = await propertyGroupService.getAllPropertyGroups(
        organizationId,
      );
      return propertyGroups ?? [];
    },
    {
      staleTime: 60000,
      refetchOnMount: true,
      refetchOnReconnect: true,
      onError: (error: any) => {
        if (promptToaster) {
          promptToaster({
            type: 'error',
            title: intl.formatMessage(generalMessages.error),
            message: error.toString(),
          });
        }
      },
    },
  );
};
