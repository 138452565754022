import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { partial, lt, ifElse, equals, always, defaultTo, isNil } from 'ramda';

import Pet from './Pet';

import messages from './messages';

import type { FieldArrayProps } from 'redux-form';

type Props = {
  fields: FieldArrayProps,
  onAddNew: Function,
  onDelete: Function,
  petTypeList: Array<Object>,
  onPetTypeChange: Function,
  maxOccupants: number,
  intl: Object,
  fieldsRequired: boolean,
  fieldsReadOnly: boolean,
  showEditButton: boolean,
  onEdit: Function,
  leaseLocked: boolean,
};

const getPetBreeds = (
  petTypes: Array<Object>,
  selectedPetType: string,
): any => {
  const selectedPetTypeRecord = petTypes.find(
    (type) => type.value === selectedPetType,
  );
  return defaultTo(
    {
      requiresWeight: false,
      petBreeds: [
        {
          value: 'default',
          text: 'Choose',
          disabled: true,
        },
      ],
    },
    selectedPetTypeRecord,
  );
};

const PetList = ({
  fields,
  onPetTypeChange,
  onAddNew,
  onDelete,
  petTypeList,
  maxOccupants,
  intl,
  fieldsRequired,
  fieldsReadOnly,
  showEditButton,
  onEdit,
  leaseLocked,
}: Props) => {
  const showAddButton = lt(fields.length, maxOccupants);
  const requiredIndicator = ifElse(
    equals(true),
    always('*'),
    always(''),
  )(fieldsRequired);

  return (
    <div className="applicant-grp">
      <Row className="block-heading">
        <Col xs={6} sm={10}>
          <h2>
            <FormattedMessage {...messages.petsTitle} />
          </h2>
        </Col>
        {showEditButton && (
          <Col xs={6} sm={2}>
            <div className="float-right">
              <a
                className="btn-text"
                onClick={partial(onEdit, [undefined])}
                disabled={leaseLocked}
              >
                <i className="icon et-pencil" />
                Edit
              </a>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col xs={12} sm={12}>
          <Row>
            <div className="labels-head container-of-inputs">
              <Col xs={6} sm={2}>
                <label>
                  <FormattedMessage {...messages.name} />
                </label>
              </Col>
              <Col xs={6} sm={2}>
                <label>
                  <FormattedMessage {...messages.petType} />
                  {requiredIndicator}
                </label>
              </Col>
              <Col xs={6} sm={2}>
                <label>
                  <FormattedMessage {...messages.petBreed} />
                  {requiredIndicator}
                </label>
              </Col>
              <Col xs={6} sm={1}>
                <label>
                  <FormattedMessage {...messages.weight} />
                </label>
              </Col>
              <Col xs={6} sm={2}>
                <label>
                  <FormattedMessage {...messages.petTag} />
                </label>
              </Col>
              <Col xs={6} sm={1} className="text-center">
                <label>
                  <FormattedMessage {...messages.serviceAnimal} />
                </label>
              </Col>
            </div>
            <div className="container-of-trash">
              <div className="row-remove-btn">
                <label>
                  <FormattedMessage {...messages.delete} />
                </label>
              </div>
            </div>
          </Row>
          {fields.map((member, index) => {
            const { petBreeds, requiresWeight } = getPetBreeds(
              petTypeList,
              fields.get(index).petTypeId,
            );
            return (
              <Pet
                field={member}
                key={`${member}${index}`}
                onDelete={partial(onDelete, [index])}
                petTypeList={petTypeList}
                petBreedList={petBreeds}
                weightRequired={requiresWeight}
                onPetTypeChange={onPetTypeChange}
                intl={intl}
                fieldsReadOnly={fieldsReadOnly}
                isNew={isNil(fields.get(index).id)}
                leaseLocked={leaseLocked}
              />
            );
          })}
        </Col>
      </Row>
      {showAddButton && (
        <Row>
          <a
            className="btn-text"
            data-testid="addPet"
            onClick={onAddNew}
            disabled={leaseLocked}
          >
            <FormattedMessage {...messages.addPet} />
          </a>
        </Row>
      )}
    </div>
  );
};

export default PetList;
