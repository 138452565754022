import { useState, useRef, useEffect } from 'react';
import ApplicationService from '../../../../services/applicationService';
import { messages } from './messages';
import moment from 'moment';

export const useAsyncApplicationReceived = (
  selectedProperty: Object,
  application: Object,
  promptToaster: Function,
  intl: Object,
) => {
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [applicationReceived, setApplicationReceived] = useState();

  const updateApplicationReceived = async (applicationReceived: Date) => {
    const applicationService = new ApplicationService();
    const { id: propertyId, organizationId } = selectedProperty;
    const {
      id: applicationId,
      applicationReceived: currentApplicationReceived,
    } = application;

    try {
      const response = await applicationService.updateApplicationReceived(
        organizationId,
        propertyId,
        applicationId,
        applicationReceived,
      );

      if (response && isMounted.current) {
        setApplicationReceived(moment(response));
        promptToaster({
          title: intl.formatMessage(messages.success),
          message: intl.formatMessage(messages.successBody),
        });
      }
    } catch (_) {
      promptToaster({
        type: 'error',
        title: intl.formatMessage(messages.error),
        message: intl.formatMessage(messages.errorBody),
      });
      if (isMounted.current) {
        setApplicationReceived(moment(currentApplicationReceived));
      }
    }
  };

  return [applicationReceived, updateApplicationReceived];
};
