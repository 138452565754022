import React, { Component } from 'react';
import { connect } from 'react-redux';
import { intersection, isEmpty, pathOr, pluck } from 'ramda';
import type { ChildrenArray, Element } from 'react';

import { Tooltip } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import type {
  UserPermission,
  GlobalState,
  Tooltip as TooltipOptions,
} from '../../containers/App/types';

type Props = {
  children: ChildrenArray<Element<any>>,
  hidden?: boolean,
  scope: Array<string>,
  showTooltip?: boolean,
  tooltip?: TooltipOptions,
  forceDisable?: boolean,
};

export type StateProps = {
  userPermissions: Array<UserPermission>,
};

export class ElementWithPermissions extends Component<StateProps & Props> {
  render() {
    const {
      children,
      hidden,
      scope,
      userPermissions,
      showTooltip = false,
      tooltip,
      forceDisable = false,
    } = this.props;
    // $FlowFixMe
    const userScopes = pluck('scope', userPermissions);
    const disabled =
      forceDisable || intersection(scope, userScopes).length === 0;
    if (disabled && hidden) {
      return null;
    }
    const clonedChildren = React.Children.map(children, (child) =>
      child
        ? React.cloneElement(child, {
            disabled: child.props.disabled || disabled,
          })
        : null,
    );
    return !showTooltip || isEmpty(tooltip?.title ?? '') || !disabled ? (
      clonedChildren
    ) : (
      <Tooltip
        id={tooltip?.id ?? 'tooltipForElementWithPermissions'}
        title={tooltip?.title}
        placement={tooltip?.placement ?? 'bottom'}
        arrow={tooltip?.arrow ?? false}
        followCursor={tooltip?.followCursor ?? false}
        variant={tooltip?.variant}
      >
        {clonedChildren}
      </Tooltip>
    );
  }
}

export const mapStateToProps = ({ app }: GlobalState): StateProps => ({
  // $FlowFixMe
  userPermissions: pathOr([], ['currentUser', 'permissions'], app),
});

export default connect(mapStateToProps)(ElementWithPermissions);
