import React from 'react';
import {
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { FormattedMessage } from 'react-intl';

const IconTitle = ({ icon, message }) => {
  return (
    <Stack direction={'row'} spacing={0.5}>
      {icon}
      <Typography variant={'h3'}>
        <FormattedMessage {...message} />
      </Typography>
    </Stack>
  );
};

export default IconTitle;
