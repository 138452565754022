import React, { useState } from 'react';
import DocumentTitle from 'react-document-title';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useModal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import CreateReportModal from './components/CreateCentralizedReportModal';
import ViewHistoryModal from './components/ViewCentralizedReportsHistoryModal';
import { useManageCentralizedReports } from './hooks';
import messages from './messages';
import useFormatMessage from '../../hooks/useFormatMessage';
import useBaseTableProps from '../../hooks/useBaseTableProps';
import TableLayout from '../../components/TableLayout';
const NAME = 'centralized-reports';

function AllManageReports({ intl, organizationId, userId, username }) {
  const [selectedReport, setSelectedReport] = useState(null);
  const formatMessage = useFormatMessage();
  const TITLE = formatMessage(messages.manageReports);
  const {
    closeModal: closeCreateReportModal,
    openModal: openCreateReportModal,
    open: createReportModalIsOpen,
  } = useModal(false);
  const {
    closeModal: closeViewHistoryModal,
    openModal,
    open: viewHistoryModalIsOpen,
  } = useModal(false);

  function openViewHistoryModal(report) {
    setSelectedReport(report);
    openModal();
  }

  const baseTableProps = useBaseTableProps();
  const {
    allColumnsHidden,
    columnOptions,
    count,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSortChange,
    isLoading,
    name,
    order,
    orderBy,
    rows,
    selectedColumns,
    handleCreateReport,
    report,
    totalCount,
  } = useManageCentralizedReports({
    NAME,
    organizationId,
    userId,
    username,
    openCreateReportModal,
    openViewHistoryModal,
  });

  return (
    <DocumentTitle title={TITLE}>
      <>
        <TableLayout name={name} childrenElementsHeight={56 + 8}>
          {(tableHeight) => (
            <>
              <TableHeader
                title={TITLE}
                totalCount={totalCount}
                count={count}
              />
              <Table
                {...baseTableProps}
                height={tableHeight}
                allColumnsHidden={allColumnsHidden}
                columnOptions={columnOptions}
                count={count}
                dateState={dateState}
                filterState={filterState}
                filterTypeState={filterTypeState}
                handleColumnChange={handleColumnChange}
                handleDateSubmit={handleDateSubmit}
                handleFilterChange={handleFilterChange}
                handleFilterTypeChange={handleFilterTypeChange}
                handleSortChange={handleSortChange}
                headers={filteredHeaders}
                isLoading={isLoading}
                name={name}
                order={order}
                orderBy={orderBy}
                rows={rows}
                selectedColumns={selectedColumns}
                totalCount={totalCount}
              />
            </>
          )}
        </TableLayout>
        <CreateReportModal
          isOpen={createReportModalIsOpen}
          closeModal={closeCreateReportModal}
          createReport={handleCreateReport}
          reportName={report?.name}
        />
        <ViewHistoryModal
          isOpen={viewHistoryModalIsOpen}
          closeModal={closeViewHistoryModal}
          intl={intl}
          organizationId={organizationId}
          report={selectedReport}
        />
      </>
    </DocumentTitle>
  );
}

const mapStateToProps = (state) => {
  const {
    app: { currentUser },
  } = state;
  return {
    organizationId: currentUser?.user?.organization?.id ?? '',
    userId: currentUser?.user?.id ?? '',
    username: currentUser?.user?.username ?? '',
  };
};

export default connect(mapStateToProps)(injectIntl(AllManageReports));
