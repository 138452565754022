const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class EmploymentStatusService {
  getAll() {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(`${apiURL}/employmentStatuses`, options);
  }
}

export default EmploymentStatusService;
