import * as ActionTypes from './constants';

import type { Action } from '../App/types';
import type { Application } from '../CreateApplication/types';

export function getApplicantInformationError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_APPLICANT_INFORMATION_ERROR,
    payload: error,
  };
}

export function getSignedLeaseStatus(applicant: any): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_SIGNED_LEASE_STATUS,
    payload: applicant,
  };
}

export function getLeaseStatus(applicant: any): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_LEASE_STATUS,
    payload: applicant,
  };
}

export function getContactStatus(application: any): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_CONTACT_STATUS,
    payload: application,
  };
}

export function getInsuranceStatus(application: any): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_INSURANCE_STATUS,
    payload: application,
  };
}

export function getLeaseStartDateStatus(applicant: any): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_LEASE_START_DATE_STATUS,
    payload: applicant,
  };
}

export function getMoveInSucesssStatus(status: any): Action<Object> {
  return {
    type: ActionTypes.GET_MOVE_IN_ACTIONS_SUCCESS,
    payload: status,
  };
}

export function getOneApplication(applicationId: string): Action<string> {
  return {
    type: ActionTypes.GET_ONE_APPLICATION,
    payload: applicationId,
  };
}

export function getOneApplicationSuccess(
  application: Application,
): Action<Application> {
  return {
    type: ActionTypes.GET_ONE_APPLICATION_SUCCESS,
    payload: application,
  };
}

export function getApplicationSignedLeaseStatusSucess(
  status: any,
): Action<Object> {
  return {
    type: ActionTypes.GET_SIGNED_LEASE_STATUS_SUCCESS,
    payload: status,
  };
}

export function getApplicationLeaseStatusSucess(status: any): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_LEASE_STATUS_SUCCESS,
    payload: status,
  };
}

export function getContactStatusSuccess(status: any): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_CONTACT_STATUS_SUCCESS,
    payload: status,
  };
}

export function getInsuranceStatusSuccess(status: any): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_INSURANCE_STATUS_SUCCESS,
    payload: status,
  };
}

export function getApplicationLeaseStartDateStatusSucess(
  status: any,
): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_LEASE_START_DATE_STATUS_SUCCESS,
    payload: status,
  };
}

export function clean(): Action<any> {
  return {
    type: ActionTypes.CLEAN,
    payload: undefined,
  };
}

export function commercialMoveIn(
  applicationId: string,
  actualMoveInDate: any,
  proratedTransactions: Array<Object>,
): Action<any> {
  return {
    type: ActionTypes.COMMERCIAL_MOVE_IN,
    payload: {
      applicationId,
      actualMoveInDate,
      proratedTransactions,
    },
  };
}

export function commercialMoveInSuccess(): Action<any> {
  return {
    type: ActionTypes.COMMERCIAL_MOVE_IN_SUCCESS,
    payload: undefined,
  };
}

export function commercialMoveInError(error: Error): Action<Error> {
  return {
    type: ActionTypes.COMMERCIAL_MOVE_IN_ERROR,
    payload: error,
  };
}

export function getUnitAvailabilityStatus(
  applicationId: string,
): Action<string> {
  return {
    type: ActionTypes.GET_UNIT_AVAILABILITY_STATUS,
    payload: applicationId,
  };
}

export function getUnitAvailabilityStatusSuccess(status: any): Action<any> {
  return {
    type: ActionTypes.GET_UNIT_AVAILABILITY_STATUS_SUCCESS,
    payload: status,
  };
}
