import { defineMessages } from 'react-intl';

const messages = defineMessages({
  floorplanIaConfirmation: {
    id: 'App.ViewUnit.FloorplanIaModal.FloorplanIaConfirmation',
    defaultMessage:
      'Are you sure you want to change the floor plan for this unit?',
  },
  iaChangeDescription: {
    id: 'App.ViewUnit.FloorplanIaModal.IaChangeDescription',
    defaultMessage:
      // eslint-disable-next-line max-len
      'This floor plan will change the Income Average to the following percentage and may be over the required limit do your property:',
  },
  iaWithChanges: {
    id: 'App.ViewUnit.FloorplanIaModal.IaWithChanges',
    defaultMessage: 'IA with Change',
  },
  requiredIa: {
    id: 'App.ViewUnit.FloorplanIaModal.RequiredIa',
    defaultMessage: 'Required IA',
  },
  yes: {
    id: 'App.ViewUnit.FloorplanIaModal.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.ViewUnit.FloorplanIaModal.No',
    defaultMessage: 'No',
  },
});

export default messages;
