import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { partial, ifElse, always, equals, and, not } from 'ramda';
import { Field } from 'redux-form';

import {
  renderTextField,
  renderNumberField,
  renderSelectField,
  renderCheckboxField,
} from '../../utils/redux-form-helper';

import messages from './messages';

type Props = {
  field: string,
  onDelete: Function,
  petTypeList: Array<Object>,
  petBreedList: Array<Object>,
  onPetTypeChange: Function,
  weightRequired: boolean,
  intl: Object,
  fieldsReadOnly: boolean,
  isNew: boolean,
  leaseLocked: boolean,
};

const Pet = ({
  field,
  onDelete,
  onPetTypeChange,
  petTypeList,
  petBreedList,
  weightRequired,
  intl,
  fieldsReadOnly,
  isNew,
  leaseLocked,
}: Props) => {
  const disableFields = and(not(isNew), fieldsReadOnly);
  const weightPlaceholder = ifElse(
    equals(true),
    always(intl.formatMessage(messages.requiredPlaceholder)),
    always(intl.formatMessage(messages.optionalPlaceholder)),
  )(weightRequired);
  return (
    <Row>
      <div className="labels-head container-of-inputs">
        <Col xs={6} sm={2}>
          <Field
            name={`${field}.name`}
            component={renderTextField}
            disabled={disableFields}
          />
        </Col>
        <Col xs={6} sm={2}>
          <Field
            data-testid="petTypeSelect"
            name={`${field}.petTypeId`}
            component={renderSelectField}
            options={petTypeList}
            onChange={partial(onPetTypeChange, [field])}
            disabled={disableFields}
          />
        </Col>
        <Col xs={6} sm={2}>
          <Field
            data-testid="petBreedSelect"
            name={`${field}.breedId`}
            component={renderSelectField}
            options={petBreedList}
            disabled={disableFields}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.weight`}
            component={renderNumberField}
            placeholder={weightPlaceholder}
            showLabel
            disabled={disableFields}
          />
        </Col>
        <Col xs={6} sm={2}>
          <Field
            name={`${field}.petTag`}
            component={renderTextField}
            disabled={disableFields}
          />
        </Col>
        <Col xs={6} sm={1} className="text-center">
          <Field
            name={`${field}.isServiceAnimal`}
            component={renderCheckboxField}
            disabled={disableFields}
          />
        </Col>
      </div>
      <div className="container-of-trash">
        <div className="row-remove-btn">
          <a onClick={onDelete} disabled={leaseLocked}>
            <i className="et-trash" />
          </a>
        </div>
      </div>
    </Row>
  );
};

export default Pet;
