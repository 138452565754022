import { useCallback, useEffect, useState, useMemo } from 'react';
import { toastr } from 'react-redux-toastr';
import { useModal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { PropertyRole } from '../App/types';
import RoleService from '../../services/roleService';
import useFormatMessage from '../../hooks/useFormatMessage';
import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

const roleService = new RoleService();

export const useConfigurePropertyRoleAssignments = ({ organizationId }) => {
  const [propertyRoles, setPropertyRoles] = useState([]);
  const { initialPropertyRoles } = useFetchPropertyRoles({
    organizationId,
  });
  useEffect(() => {
    setPropertyRoles(initialPropertyRoles);
  }, [initialPropertyRoles]);
  const { open, openModal, closeModal } = useModal(false);
  const { orgRoleOptions, isLoading } = useOrgRoleOptions({
    organizationId,
  });
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState([]);

  const validate = useCallback((propertyRoles) => {
    const atLeaseOneItemToBeDeleted = propertyRoles.some(
      (role) =>
        role?.name === '' && role?.assignedRoles.length === 0 && role?.id,
    );
    const atLeastOneInputFilled = propertyRoles.some(
      (role) => role?.name !== '' || !!role?.name,
    );
    const allInputsWithNamesHaveAssignedRoles = propertyRoles.every((role) => {
      if (!role?.name || role?.name === '') {
        return true;
      }
      if (role?.name && role?.assignedRoles.length === 0) {
        return false;
      }
      return true;
    });
    const getRoleNameErrors = (role) => {
      const roleNameIsBlank = role?.name.trim() === '';
      const isEmpty = roleNameIsBlank && role?.assignedRoles.length === 0;
      if (isEmpty) {
        return null;
      }
      if (!/^[a-zA-Z][a-zA-Z0-9\s-]*$/.test(role?.name)) {
        if (roleNameIsBlank) {
          return 'Role name cannot be blank';
        }
        return 'Role name must start with a letter and only contain letters, numbers, spaces, and hyphens';
      }
      if (role?.name.length > 50) {
        return 'Role name cannot be longer than 50 characters';
      }
      return null;
    };

    const errors = propertyRoles.map((role) => {
      return {
        name: role?.name,
        error: getRoleNameErrors(role),
      };
    });

    const noInputHasErrors = errors.every((error) => !error.error);
    const isValid =
      (atLeaseOneItemToBeDeleted || atLeastOneInputFilled) &&
      allInputsWithNamesHaveAssignedRoles &&
      noInputHasErrors;
    return [isValid, errors];
  }, []);

  useEffect(() => {
    const [valid, errors] = validate(propertyRoles);
    setIsValid((prev) => (prev !== valid ? valid : prev));
    setErrors(errors);
  }, [isValid, propertyRoles, validate]);

  const bulkCreateOrUpdatePropertyRoles = useBulkCreateOrUpdatePropertyRoles({
    organizationId,
    propertyRoles,
    initialPropertyRoles,
  });
  const handleSave = async () => {
    validate(propertyRoles);
    return bulkCreateOrUpdatePropertyRoles();
  };
  return {
    open,
    openModal,
    closeModal,
    isLoading,
    orgRoleOptions,
    handleSave,
    propertyRoles,
    setPropertyRoles,
    initialPropertyRoles,
    isValid,
    errors,
  };
};

export const useOrgRoleOptions = ({ organizationId }) => {
  const queryKey = ['orgUserRoles', organizationId];
  const options = {
    initialData: [],
  };
  const { data, isLoading } = useQuery(
    queryKey,
    () => roleService.getAllRoles(organizationId),
    options,
  );
  return {
    orgRoleOptions: data.map((datum) => ({
      value: datum.id,
      text: datum.name,
      disabled: false,
    })),
    isLoading,
  };
};

export const useBulkCreateOrUpdatePropertyRoles = ({
  organizationId,
  propertyRoles,
  initialPropertyRoles,
}: {
  organizationId: string,
  propertyRoles: PropertyRole[],
  initialPropertyRoles: PropertyRole[],
}) => {
  const queryClient = useQueryClient();
  const isUpdate = initialPropertyRoles.length > 0;
  const formatMessage = useFormatMessage();
  const bulkCreateOrUpdatePropertyRoles = async () => {
    const [rolesToUpdate, rolesToCreate] =
      getRolesToUpdateAndCreate(propertyRoles);
    if (rolesToCreate.length > 0)
      await roleService.bulkCreatePropertyRoles(organizationId, rolesToCreate);

    if (rolesToUpdate.length > 0)
      await roleService.bulkUpdatePropertyRoles(organizationId, rolesToUpdate);
  };
  const bulkCreateOrUpdatePropertyRolesMutation = useMutation(
    bulkCreateOrUpdatePropertyRoles,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('propertyRoles');
        toastr.success(
          formatMessage(messages.success),
          formatMessage(
            !isUpdate
              ? messages.propertyRoleCreated
              : messages.propertyRoleUpdated,
          ),
        );
      },
      onError: () => {
        toastr.error(
          formatMessage(messages.error),
          formatMessage(
            !isUpdate
              ? messages.failedToCreateRoles
              : messages.failedToUpdateRoles,
          ),
        );
      },
    },
  );
  return bulkCreateOrUpdatePropertyRolesMutation.mutate;
};

export const useFetchPropertyRoles = ({ organizationId }) => {
  const [initialPropertyRoles, setInitialPropertyRoles] = useState([]);
  const queryKey = ['propertyRoles', organizationId];
  const options = {
    initialData: [],
  };
  const { data, isLoading } = useQuery(
    queryKey,
    () => roleService.getAllPropertyRoles(organizationId),
    options,
  );

  const propertyRoles = useMemo(() => {
    return data.map((datum) => {
      return {
        id: datum.id,
        name: datum.name,
        assignedRoles: datum.userRoles.map((role) => role.id),
      };
    });
  }, [data]);

  useEffect(() => {
    setInitialPropertyRoles(propertyRoles);
  }, [propertyRoles]);
  return {
    initialPropertyRoles,
    isLoading,
  };
};

function getRolesToUpdateAndCreate(propertyRoles) {
  const rolesToUpdate = [];
  const rolesToCreate = [];
  propertyRoles.forEach((role) => {
    if (!role) {
      return;
    }
    if (role.id) {
      rolesToUpdate.push(role);
    } else {
      rolesToCreate.push(role);
    }
  });
  return [rolesToUpdate, rolesToCreate];
}
