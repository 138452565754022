import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  blue,
  grey,
  lightGrey,
} from '@fortress-technology-solutions/fortress-component-library/design';
import moment from 'moment';
import React from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import styled from 'styled-components';
import { formatCurrency } from '../../../utils';
import SmartPriceStatus from '../SmartPriceStatus';
import messages from './messages';

type Props = {
  intl: IntlShape,
  floorPlanName: string,
  currentPrice: number,
  suggestedPrice: number,
  startDate: Date,
  smartPriceStatus: string,
  numberOfNotLeasedUnits: number,
};

const Container = styled.div`
  border-radius: 4px;
  background: ${lightGrey.lighter};
  margin-top: 16px;
  padding: 16px;
`;

function NewQuotingRent(props: Props) {
  return (
    <Container>
      <Grid container rowSpacing={1}>
        <Grid item md={12}>
          <Typography variant="h4">
            {props.intl.formatMessage(messages.newQuotingRent)}
          </Typography>
        </Grid>

        <Grid container item md={12}>
          <Grid item md={2.4}>
            <Typography variant="h5">
              {props.intl.formatMessage(messages.floorPlan)}
            </Typography>
          </Grid>
          <Grid item md={2.4}>
            <Typography variant="h5">
              {props.intl.formatMessage(messages.currentQuotingRent)}
            </Typography>
          </Grid>
          <Grid item md={2.4}>
            <Typography variant="h5" color={blue.main}>
              {props.intl.formatMessage(messages.suggestedSmartPrice)}
            </Typography>
          </Grid>
          <Grid item md={2.4}>
            <Typography variant="h5">
              {props.intl.formatMessage(messages.startDate)}
            </Typography>
          </Grid>
          <Grid item md={2.4}>
            <Typography variant="h5">
              {props.intl.formatMessage(messages.smartPriceStatus)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item md={12}>
          <Grid item md={2.4}>
            <Typography>{props.floorPlanName}</Typography>
          </Grid>
          <Grid item md={2.4}>
            <Typography>
              {formatCurrency(props.intl, props.currentPrice)}
            </Typography>
          </Grid>
          <Grid item md={2.4}>
            <Typography color={blue.main}>
              {formatCurrency(props.intl, props.suggestedPrice)}
            </Typography>
          </Grid>
          <Grid item md={2.4}>
            <Typography>
              {props.smartPriceStatus === 'Rejected'
                ? '---'
                : moment(props.startDate).format('MM/DD/YYYY')}
            </Typography>
          </Grid>
          <Grid item md={2.4}>
            <SmartPriceStatus status={props.smartPriceStatus} />
          </Grid>
        </Grid>

        <Grid item md={12}>
          <Typography color={grey.main} sx={{ marginTop: 1 }}>
            {props.intl.formatMessage(messages.note, {
              numberOfNotLeasedUnits: props.numberOfNotLeasedUnits,
              plural: props.numberOfNotLeasedUnits !== 1 ? 's' : '',
            })}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default injectIntl(NewQuotingRent);
