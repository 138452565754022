import React, { useEffect, useState } from 'react';
import { LedgerFilterLayout } from './LedgerFilterLayout';
import { TopFiltersAndSearchBar } from './LedgerFilterLayout/TopFiltersAndSearchBar';
import { LedgerTable } from './LedgerFilterLayout/LedgerTable';
import {
  useAppliedFilters,
  useAsyncTransactionOptions,
  useFetchLastPeriod,
  useFetchTransactions,
  useTransactionFilters,
} from '../hooks';
import ledgerMessages from '../messages';
import useSocketOverride from '../../../hooks/useSocketOverride';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import AppliedFilters from '../../../components/AppliedFilters';
import FilterControls from './LedgerFilterLayout/FilterControls';

type Props = {
  filterReversed: Function,
  formatMessage: Function,
  ledger: Object,
  onFilterChange: Function,
  onJournalChange: Function,
  onLedgerChange: Function,
  subjournal: string,
  subJournals: Object,
  selectedProperty: Object,
  customerId: string,
  ledgerData: Object,
  formatNumber: Function,
  isOps: boolean,
  isAllSubjournals: Object,
  onViewTransaction: Function,
  viewTransactionDisabled: boolean,
  handleSocketFinancialTransactionSuccess: Function,
  handleSocketTransactionSuccess: Function,
  currentLedger: string,
};

export const LedgerFilterSection = (props: Props) => {
  const {
    filterReversed,
    formatMessage,
    ledger,
    onFilterChange,
    onJournalChange,
    onLedgerChange,
    subjournal,
    subJournals,
    selectedProperty,
    customerId,
    formatNumber,
    isOps,
    isAllSubjournals,
    onViewTransaction,
    viewTransactionDisabled,
    handleSocketFinancialTransactionSuccess,
    handleSocketTransactionSuccess,
    currentLedger,
    onHandleFilters,
  } = props;

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [refreshTransactionTable, setRefreshTransactionTable] = useState(false);
  const [isDateRangeChecked, setIsDateRangeChecked] = useState(false);
  const [isAmountRangeChecked, setIsAmountRangeChecked] = useState(false);

  const {
    filters,
    handleFilterControlsChange,
    clearTransactionFilters,
    clearDateRange,
    clearExactDate,
    clearAmountRange,
    clearExactAmount,
    clearSearch,
    isFiltersPopulated,
  } = useTransactionFilters();
  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  const [resetSearch, setResetSearch] = useState(false);

  const [
    allTransactions,
    transactionsIsLoading,
    page,
    setPage,
    limit,
    filtersQueryString,
  ] = useFetchTransactions({
    selectedProperty,
    customerId,
    filterReversed,
    filters,
    refreshTransactionTable,
    subjournal,
    currentLedger,
    isDateRangeChecked,
    isAmountRangeChecked,
  });

  useEffect(() => {
    onHandleFilters(filtersQueryString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersQueryString]);

  const [lastPeriod] = useFetchLastPeriod({
    selectedProperty,
    customerId,
    filterReversed,
    filters,
    refreshTransactionTable,
    subjournal,
    currentLedger,
    isDateRangeChecked,
    isAmountRangeChecked,
  });

  const [transactionOptions, transactionFilterOptionsLoaded] =
    useAsyncTransactionOptions({
      selectedProperty,
      customerId,
      filterReversed,
      currentLedger,
      filters,
      page,
      limit,
      allTransactions,
    });
  const { appliedFilters, handleRemoveFilter, handleClearAll } =
    useAppliedFilters(
      filters,
      handleFilterControlsChange,
      clearTransactionFilters,
      clearDateRange,
      clearExactDate,
      clearAmountRange,
      clearExactAmount,
      clearSearch,
      setResetSearch,
      resetSearch,
      transactionOptions,
    );

  useEffect(() => {
    handleClearAll();
    setIsDateRangeChecked(false);
    setIsAmountRangeChecked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ledger]);

  const handleReversalCreateSuccess = ({ payload }) => {
    handleSocketFinancialTransactionSuccess({
      message: formatMessage(ledgerMessages.successDescriptionReversal),
      title: formatMessage(ledgerMessages.successHeaderReversal),
      payload,
    });
    setRefreshTransactionTable(!refreshTransactionTable);
  };

  const handleRefundSuccess = ({ payload }) => {
    handleSocketFinancialTransactionSuccess({
      payload,
      message: formatMessage(ledgerMessages.successDescriptionRefund),
      title: formatMessage(ledgerMessages.successHeaderRefund),
    });
    setRefreshTransactionTable(!refreshTransactionTable);
  };

  const handleTransactionCreateSuccess = ({ payload, text }) => {
    handleSocketTransactionSuccess({ ...payload, text });
    setRefreshTransactionTable(!refreshTransactionTable);
  };

  useSocketOverride('rtx.success', handleReversalCreateSuccess, [customerId]);
  useSocketOverride('ctx.success', handleTransactionCreateSuccess, [
    customerId,
  ]);
  useSocketOverride('apprefund.success', handleRefundSuccess, [customerId]);

  const appliedFiltersComponent = (
    <>
      {appliedFilters.length > 0 && (
        <Stack marginLeft={4} marginBottom={3}>
          <AppliedFilters
            filters={appliedFilters}
            onRemoveFilter={handleRemoveFilter}
            onClearAll={handleClearAll}
          />
        </Stack>
      )}
    </>
  );

  const filterControls = (
    <FilterControls
      values={filters}
      ledger={ledger}
      transactionFilterOptionsLoaded={transactionFilterOptionsLoaded}
      transactionTypes={transactionOptions?.transactionTypes ?? []}
      transactionCodes={transactionOptions?.transactionCodes ?? []}
      periods={transactionOptions?.periods ?? []}
      onChange={handleFilterControlsChange}
      clearDateRange={clearDateRange}
      clearExactDate={clearExactDate}
      clearAmountRange={clearAmountRange}
      clearExactAmount={clearExactAmount}
      isDateRangeChecked={isDateRangeChecked}
      setIsDateRangeChecked={setIsDateRangeChecked}
      isAmountRangeChecked={isAmountRangeChecked}
      setIsAmountRangeChecked={setIsAmountRangeChecked}
      isFilterActive={isFilterActive}
      lastPeriod={lastPeriod}
    />
  );

  const topFiltersAndSearchBar = (
    <TopFiltersAndSearchBar
      isFilterActive={isFilterActive}
      setIsFilterActive={setIsFilterActive}
      filterReversed={filterReversed}
      formatMessage={formatMessage}
      ledger={ledger}
      onFilterChange={onFilterChange}
      onJournalChange={onJournalChange}
      onLedgerChange={onLedgerChange}
      subjournal={subjournal}
      subJournals={subJournals}
      onChange={handleFilterControlsChange}
      clearSearch={clearSearch}
      resetSearch={resetSearch}
      transactions={allTransactions?.results}
      isFiltersPopulated={isFiltersPopulated}
    />
  );

  const ledgerTable = (
    <LedgerTable
      formatMessage={formatMessage}
      formatNumber={formatNumber}
      isOps={isOps}
      isAllSubjournals={isAllSubjournals}
      onViewTransaction={onViewTransaction}
      viewTransactionDisabled={viewTransactionDisabled}
      allTransactions={allTransactions}
      transactionsIsLoading={transactionsIsLoading}
      page={page}
      setPage={setPage}
      limit={limit}
    />
  );

  return (
    <LedgerFilterLayout
      topSection={topFiltersAndSearchBar}
      appliedFilters={appliedFiltersComponent}
      bottomSection={filterControls}
      ledgerTable={ledgerTable}
      isFilterActive={isFilterActive}
    />
  );
};
