import * as R from 'ramda';
import moment from 'moment';
import type { InsuranceFormValues } from './types';

const requiredFields = [
  'limits',
  'commercialInsuranceTypeId',
  'tenDayClause',
  'effectiveDate',
  'expirationDate',
];

function validate(values: InsuranceFormValues) {
  const isNilOrEmpty = R.anyPass([R.isNil, R.isEmpty]);

  const errors = requiredFields.reduce(
    (errors, field) =>
      isNilOrEmpty(values[field])
        ? { ...errors, [field]: 'Required Field' }
        : errors,
    {},
  );

  if (!errors.expirationDate) {
    const expirationDate = moment(values.expirationDate);
    const effectiveDate = moment(values.effectiveDate);

    if (effectiveDate.isValid() && !expirationDate.isAfter(effectiveDate))
      return {
        ...errors,
        expirationDate: 'Must be after Effective Date.',
      };
  }

  return errors;
}

export default validate;
