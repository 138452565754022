import { put, takeLatest, throttle, select, call } from 'redux-saga/effects';
import { reset } from 'redux-form';
import { push, goBack } from 'react-router-redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { pathOr, omit } from 'ramda';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';

import ApplicantFormService from '../../services/applicantFormService';
import * as ActionTypes from './constants';
import * as shortApplicationActions from './actions';
import messages from './messages';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import type { Saga } from 'redux-saga';
import type { Action } from '../App/types';

export function* fetchGetApplicantInformation(
  action: Action<string>,
): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const applicantFormService = new ApplicantFormService();
    const response = yield call(
      applicantFormService.getApplicantInformation,
      action.payload,
      organizationId,
      selectedProperty.id,
    );
    yield put(shortApplicationActions.getApplicantInformationSuccess(response));
  } catch (err) {
    yield put(shortApplicationActions.getApplicantInformationError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getApplicantInformation(): Saga<void> {
  yield takeLatest(
    ActionTypes.GET_APPLICANT_INFORMATION,
    fetchGetApplicantInformation,
  );
}

export function* fetchSaveApplicantForm(action: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const applicationId = pathOr(
      '',
      ['formData', 'applicantInfo', 'applicationId'],
      action.payload,
    );
    const convertedResident = action.payload.convertedResident;
    const applicantFormService = new ApplicantFormService();
    const successMessage = action.payload.complete
      ? messages.completionSuccessDescription
      : messages.progressSuccessDescription;
    yield applicantFormService.save(
      omit(['convertedResident'], action.payload),
      organizationId,
      selectedProperty.id,
    );
    yield put(shortApplicationActions.saveApplicantFormSuccess());
    yield put(reset('shortLeaseApplication'));
    if (convertedResident) {
      yield put(goBack());
    } else {
      yield put(
        push(
          getUrlWithSelectedPropertyId(`/application/${applicationId}/?tab=5`),
        ),
      );
    }
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(successMessage),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(shortApplicationActions.saveApplicantFormError(err));
  }
}

export function* saveApplicantForm(): Saga<void> {
  yield throttle(500, ActionTypes.SAVE_APPLICANT_FORM, fetchSaveApplicantForm);
}

export default [getApplicantInformation, saveApplicantForm];
