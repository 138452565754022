const { apiURL } = require('../utils/api');
const request = require('../utils/api').default;

class ApplicantRefundStatementService {
  createApplicantRefundStatement(
    organizationId: string,
    propertyId: string,
    customerId: string,
    householdId: string,
    applicationId: string,
    applicantRefundMailingAddress: Object,
    notificationAttributes?: Object,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        applicationId,
        householdId,
        customerId,
        applicantRefundMailingAddress,
        notificationAttributes,
      }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/applicantRefundStatement`,
      options,
    );
  }
}
export default ApplicantRefundStatementService;
