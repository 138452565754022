import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { List } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { DASHES } from '@fortress-technology-solutions/fortress-component-library/constants';
import {
  DateRangeIcon,
  UploadIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import { getDateDifference } from '@fortress-technology-solutions/fortress-component-library/utils';
import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

const CertificationHistory = ({
  intl,
  oldestSubmissionDocumentDate,
  certOpenedDate,
}) => {
  const list = [
    {
      primary: (
        <FormattedMessage
          {...messages.daysSince}
          values={{ message: intl.formatMessage(messages.certOpened) }}
        />
      ),
      secondary: `: ${
        certOpenedDate ? getDateDifference(certOpenedDate, 'days') : DASHES
      }`,
      icon: <DateRangeIcon />,
    },
    {
      primary: (
        <FormattedMessage
          {...messages.daysSince}
          values={{
            message: intl.formatMessage(messages.firstSubmissionPacketUpload),
          }}
        />
      ),
      secondary: `: ${
        oldestSubmissionDocumentDate
          ? getDateDifference(oldestSubmissionDocumentDate, 'days')
          : DASHES
      }`,
      icon: <UploadIcon />,
    },
  ];

  return (
    <List
      subheader={
        <Typography variant={'h5'}>
          <FormattedMessage {...messages.daysInProcess} />
        </Typography>
      }
    >
      {list.map((item, index) => (
        <ListItem key={index}>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              component: 'span',
              variant: 'body2',
              sx: { fontWeight: 500, display: 'inline-block' },
            }}
            secondaryTypographyProps={{
              component: 'span',
              sx: { display: 'inline-block' },
            }}
            primary={item.primary}
            secondary={item.secondary}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default CertificationHistory;
