import { useState } from 'react';
import { renderTimeField } from '../../utils/redux-form-helper';

export interface Props {
  error?: string;
  value?: any;
  onChange?: Function;
  isValidDate?: Function;
}

const TimeInput = (props: Props) => {
  const [touched, setTouched] = useState(false);
  const { value, onChange, isValidDate, error } = props;
  const meta = {
    touched,
    error,
  };
  const onOpen = (newDatetime) => {
    if (!touched) {
      setTouched(true);
    }
  };
  return renderTimeField({
    bsSize: 'md',
    value,
    onChange,
    isValidDate,
    meta,
    onOpen,
  });
};

export default TimeInput;
