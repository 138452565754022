import React from 'react';
import { Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import messages from './messages';
import { useFetchTransactionBatches } from './hooks';
import Spinner from '../../../components/Spinner';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import { PERMISSION_CREATE_TX_BATCH } from '../constants';
import DashesIfNullOrUndefined from '../../../components/DashesIfNullOrUndefined';
import { generateFilteredTransactionBatches } from './utils';

type Props = {
  organizationId: string,
  propertyId: string,
  redirectToCreateTransactionBatch: Function,
  filterBy: string,
  isAllCommercial: boolean,
};

const batchStatusMapping = {
  Saved: 'Unposted',
  Posting: 'Pending',
};

const sortByHeaderCreatedDateDesc = (objA: Object, objB: Object): number => {
  const dateA = objA?.header?.createdAt ?? null;
  const dateB = objB?.header?.createdAt ?? null;
  const mDateA = moment(dateA);
  const mDateB = moment(dateB);
  if (!mDateA.isValid() || !mDateB.isValid()) {
    return -1;
  }
  return mDateA.isSameOrAfter(mDateB) ? -1 : 1;
};

const renderTransactionBatchesRows = (
  transactionBatches,
  redirectToCreateTransactionBatch,
) => {
  return transactionBatches && transactionBatches.length > 0 ? (
    transactionBatches
      .sort(sortByHeaderCreatedDateDesc)
      .map((transactionBatch, i) => {
        const header = transactionBatch?.header;
        return (
          <tr key={i} data-test="transaction-batches-rows">
            <td>
              {batchStatusMapping[header?.batchStatus] ?? header?.batchStatus}
            </td>
            <td data-test="transaction-batch-date">
              {moment.utc(header?.batchDate ?? null).format('MM/DD/YYYY')}
            </td>
            <td>
              {header?.updatedBy?.firstName} {header?.updatedBy?.lastName}
            </td>
            <td>{header?.pfp?.period.replace(/(\d{4})(\d{2})/, '$1-$2')}</td>
            <td>
              <Row>
                <FormattedMessage {...messages.totalBatchAmount} /> $
                {header?.totals?.totalAmount.toFixed(2)}
              </Row>
              <Row>
                <FormattedMessage {...messages.households} />{' '}
                {header?.totals?.totalHouseholds},{' '}
                <FormattedMessage {...messages.totalTransactions} />{' '}
                {transactionBatch?.transactions?.length}
              </Row>
            </td>
            <td>
              <DashesIfNullOrUndefined data={header.batchNote} />
            </td>
            <td>
              <ElementWithPermissions scope={[PERMISSION_CREATE_TX_BATCH]}>
                <button
                  className="btn btn-sm btn-default"
                  onClick={() => {
                    redirectToCreateTransactionBatch(header?.id);
                  }}
                >
                  <i className="et-pencil" />
                </button>
              </ElementWithPermissions>
            </td>
          </tr>
        );
      })
  ) : (
    <tr>
      <td colSpan={7}>
        <div className="text-center">
          <FormattedMessage {...messages.noDataDescription} />
        </div>
      </td>
    </tr>
  );
};

export const TransactionBatchesGrid = (props: Props) => {
  const {
    organizationId,
    propertyId,
    redirectToCreateTransactionBatch,
    filterBy,
    isAllCommercial,
  } = props;
  const { transactionBatches, isLoading } = useFetchTransactionBatches({
    organizationId,
    propertyId,
  });
  const filteredTransactionBatches = generateFilteredTransactionBatches(
    transactionBatches,
    filterBy,
  );
  return (
    <div className="container-fluid" data-test="payment-details">
      <div className="table-scroll table-deposits-container">
        <table className="table table-prospects table-striped">
          <thead className="table-header">
            <tr>
              <th style={{ width: '10%' }}>
                <FormattedMessage {...messages.status} />
              </th>
              <th style={{ width: '10%' }}>
                <FormattedMessage {...messages.transactionDate} />
              </th>
              <th style={{ width: '20%' }}>
                <FormattedMessage {...messages.postedOnBy} />
              </th>
              <th style={{ width: '10%' }}>
                <FormattedMessage {...messages.period} />
              </th>
              <th style={{ width: '20%' }}>
                <FormattedMessage {...messages.transactionDetails} />
              </th>
              <th style={{ width: '20%' }}>
                <FormattedMessage {...messages.note} />
              </th>
              <th style={{ width: '10%' }}>
                <FormattedMessage {...messages.editOrView} />
              </th>
            </tr>
          </thead>
          <tbody>
            {!isLoading ? (
              renderTransactionBatchesRows(
                filteredTransactionBatches,
                redirectToCreateTransactionBatch,
              )
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="text-center">
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="padtop30">
        <strong>
          <FormattedMessage {...messages.notes} />
        </strong>
        <div className="padtop5">
          <FormattedMessage {...messages.notes1} />
        </div>
        <div>
          <FormattedMessage {...messages.notes2} />
        </div>
        <div>
          {isAllCommercial ? (
            <FormattedMessage {...messages.notes3Tenants} />
          ) : (
            <FormattedMessage {...messages.notes3Residents} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionBatchesGrid;
