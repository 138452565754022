import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { pathOr } from 'ramda';

import messages from './messages';

const UnitLeaseInformation = ({ unit, intl, isUnitCommercial }: Object) => {
  const moveInDate = pathOr(
    null,
    ['currentAssignation', 'lease', 'actualMoveInDate'],
    unit,
  );
  const moveOutDate = pathOr(
    null,
    ['currentAssignation', 'lease', 'noticeToVacate', 'moveOutDate'],
    unit,
  );
  const endDate = pathOr(
    null,
    ['currentAssignation', 'lease', 'endDate'],
    unit,
  );
  const scheduledMoveInDate = pathOr(
    null,
    ['nextAssignation', 'lease', 'moveInDate'],
    unit,
  );
  const leasedRent = pathOr(
    null,
    ['currentAssignation', 'lease', 'leasedRent'],
    unit,
  );
  const rentStartDate = pathOr(
    null,
    ['currentAssignation', 'lease', 'rentStartDate'],
    unit,
  );
  const priorLeasedRent = pathOr(
    null,
    ['priorAssignation', 'lease', 'leasedRent'],
    unit,
  );
  const priorMoveInDate = pathOr(
    null,
    ['priorAssignation', 'lease', 'actualMoveInDate'],
    unit,
  );
  const priorMoveOutDate = pathOr(
    null,
    ['priorAssignation', 'lease', 'moveOutDate'],
    unit,
  );

  const applicantStatus = pathOr(
    '---',
    ['nextAssignation', 'application', 'applicationStatus', 'name'],
    unit,
  );

  const formatDate = (value: number | null) =>
    value !== null
      ? intl.formatDate(moment(value), {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })
      : '---';

  const formatCurrency = (value: number | null) =>
    value !== null
      ? intl.formatNumber(+value, {
          style: 'currency',
          currency: 'USD',
        })
      : '---';

  return (
    <div className="panel unit-information panel-withheader">
      <div className="panel-head">
        <h2>
          <FormattedMessage {...messages.title} />
        </h2>
      </div>
      <div className="container-fluid">
        <div className="panel__details">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <h3>
                <FormattedMessage {...messages.currentLease} />
              </h3>
              <div className="row">
                <div className="col-xs-6">
                  <strong>
                    <FormattedMessage {...messages.moveInDate} />
                  </strong>
                </div>
                <div className="col-xs-6">{formatDate(moveInDate)}</div>
              </div>
              <div className="row">
                <div className="col-xs-6">
                  <strong>
                    <FormattedMessage {...messages.moveOutDate} />
                  </strong>
                </div>
                <div className="col-xs-6">{formatDate(moveOutDate)}</div>
              </div>
              <div className="row">
                <div className="col-xs-6">
                  <strong>
                    <FormattedMessage {...messages.leaseEndDate} />
                  </strong>
                </div>
                <div className="col-xs-6">{formatDate(endDate)}</div>
              </div>
              <div className={isUnitCommercial ? 'row' : 'row padbottom10'}>
                <div className="col-xs-6">
                  <strong>
                    <FormattedMessage {...messages.leaseRent} />
                  </strong>
                </div>
                <div className="col-xs-6">{formatCurrency(leasedRent)}</div>
              </div>
              {isUnitCommercial && (
                <div className="row padbottom10">
                  <div className="col-xs-6">
                    <strong>
                      <FormattedMessage {...messages.rentStartDate} />
                    </strong>
                  </div>
                  <div className="col-xs-6">{formatDate(rentStartDate)}</div>
                </div>
              )}
            </div>
            <div className="col-xs-12 col-sm-6">
              <h3>Prior Lease</h3>
              <div className="row">
                <div className="col-xs-6">
                  <strong>
                    <FormattedMessage {...messages.moveInDate} />
                  </strong>
                </div>
                <div className="col-xs-6">{formatDate(priorMoveInDate)}</div>
              </div>
              <div className="row">
                <div className="col-xs-6">
                  <strong>
                    <FormattedMessage {...messages.moveOutDate} />
                  </strong>
                </div>
                <div className="col-xs-6">{formatDate(priorMoveOutDate)}</div>
              </div>
              <div className="row">
                <div className="col-xs-6">
                  <strong>
                    <FormattedMessage {...messages.leasedRent} />
                  </strong>
                </div>
                <div className="col-xs-6">
                  {formatCurrency(priorLeasedRent)}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="row">
                <div className="col-xs-12">
                  <h3 className="padtop10">
                    <FormattedMessage {...messages.newApplicant} />
                  </h3>
                </div>
                <div className="col-xs-6">
                  <div className="row">
                    <div className="col-xs-12 col-md-6">
                      <strong>
                        <FormattedMessage {...messages.scheduledMoveIn} />
                      </strong>
                    </div>
                    <div className="col-xs-12 col-md-6">
                      {formatDate(scheduledMoveInDate)}
                    </div>
                  </div>
                </div>
                <div className="col-xs-6">
                  <div className="col-xs-12 col-md-6">
                    <strong>
                      <FormattedMessage {...messages.applicantStatus} />
                    </strong>
                  </div>
                  <div className="col-xs-12 col-md-6">{applicantStatus}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitLeaseInformation;
