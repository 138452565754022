import { defineMessages } from 'react-intl';

const messages = defineMessages({
  options: {
    id: 'App.CommercialLeaseDataTab.options.options',
    defaultMessage: 'Options',
  },
  addOption: {
    id: 'App.CommercialLeaseDataTab.options.addOption',
    defaultMessage: '+ Add Option',
  },
  noOptions: {
    id: 'App.CommercialLeaseDataTab.options.noOptions',
    defaultMessage: ' No Options',
  },
});

export default messages;
