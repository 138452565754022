import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../containers/App/context';
import { FormProvider, useForm } from 'react-hook-form';
import ActivityFilters from './ActivityFilters';
import ActivityTable from './ActivityTable';
import { FormattedMessage } from 'react-intl';
import messages from '../ActivityContents/messages';
import ActivityActions from './ActivityActions';
import {
  useFetchGetActivityFilterOptions,
  useFetchGetAllByProspect,
} from './hooks';
import {
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import ActivityTableLegend from './ActivityTableLegend';

type Props = {
  onCreateActivityClick: Function,
  onEditActivity: Function,
  prospectId: string,
  updateTrigger: Function,
  intl: Object,
  locale: any,
};

function ActivityContainer(props: Props) {
  const { selectedProperty } = useContext(AppContext);
  const [filters, setFilters] = useState({});
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const methods = useForm({
    defaultValues: {
      fromDate: '',
      toDate: '',
      statuses: [],
      types: [],
      isRange: false,
      autoOrManual: null,
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, watch]);

  const {
    queryResponse: activitiesByProspect,
    page,
    limit,
    order,
    columnOrder,
    setPage,
    onSortOrder,
    currentFilter,
    setCurrentFilter,
  } = useFetchGetAllByProspect(
    props.prospectId,
    selectedProperty,
    filters,
    props.updateTrigger,
    refresh,
  );

  const {
    data: activityFilterOptions,
    isLoading: activityFilterOptionsIsLoading,
  } = useFetchGetActivityFilterOptions(
    props.prospectId,
    selectedProperty,
    refresh,
  );

  const onSubmit = (data) => {
    setFilters(data);

    const isFilter =
      data.fromDate ||
      data.toDate ||
      data.statuses.length > 0 ||
      data.types.length > 0 ||
      data.autoOrManual !== null;

    // currentFilter state checks for whether any filters are currently populated
    setCurrentFilter(isFilter);
    setPage(!currentFilter ? 1 : page);
  };

  const isLoading =
    activitiesByProspect.isLoading || activityFilterOptionsIsLoading;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => setFilters(data))}>
        <Stack alignItems={'flex-start'}>
          <Typography variant={'h2'}>
            <FormattedMessage {...messages.activityHistory} />
          </Typography>
        </Stack>
        <ActivityActions
          activitiesByProspect={activitiesByProspect.data}
          onCreateActivityClick={() =>
            props.onCreateActivityClick(() => setRefresh(!refresh))
          }
          isFilterActive={isFilterActive}
          setIsFilterActive={setIsFilterActive}
          isDirty={isDirty}
        />
        {isFilterActive && (
          <ActivityFilters
            intl={props.intl}
            activityFilterOptions={activityFilterOptions}
            prospectId={props.prospectId}
            filters={filters}
            setPage={setPage}
          />
        )}
        <ActivityTableLegend />
        <ActivityTable
          activitiesByProspect={activitiesByProspect.data}
          onEditActivity={props.onEditActivity}
          handleRefresh={() => setRefresh(!refresh)}
          intl={props.intl}
          locale={props.locale}
          columnOrder={columnOrder}
          order={order}
          page={page}
          limit={limit}
          setPage={setPage}
          isLoading={isLoading}
          onSortOrder={onSortOrder}
        />
      </form>
    </FormProvider>
  );
}

export default ActivityContainer;
