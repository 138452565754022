import React from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { path, pathOr, isNil, isEmpty } from 'ramda';

import messages from './messages';
import { renderSelectField } from '../../../../utils/redux-form-helper';
import ElementWithPermissions from '../../../../components/ElementWithPermissions';
import { formatCurrency } from '../../../../utils/index';
import {
  BASE_AMOUNT_OPTIONS,
  EXPENSE_CAPS_OPTIONS,
  PRO_RATA_SHARE_OPTIONS,
  GROSS_UP_OPTIONS,
  LEASE_ALLOWS_MONTHLY_EST_OPTIONS,
  GL_ACCOUNTS_OPTIONS,
} from './constants';

import type {
  FetchStartYearExpensesRequest,
  FetchStartYearExpensesResponse,
} from './types';

import IntacctAccountBalancesService from '../../../../services/intacctAccountBalancesService';

export const generateYearDropdownOptions = (nYears: number): Array<any> => {
  const currentYear = moment().year();
  let years = [];
  for (let i = 0; i <= nYears; i++) {
    const year = currentYear - i;
    years = [...years, year];
  }
  const yearOptions = years.map((year) => ({
    text: year,
    value: year,
    disabled: false,
  }));
  const selectOption = { text: 'Year', value: '', disabled: true };
  return [selectOption, ...yearOptions];
};

export const generateBaseAmountOptions = (
  disableOptions: boolean,
  selectedBaseAmountOptions: string,
  scope: boolean,
) => {
  const baseYearField = (
    <Field
      name="baseYear"
      component={renderSelectField}
      options={generateYearDropdownOptions(100)}
      disabled={
        disableOptions ||
        selectedBaseAmountOptions !== BASE_AMOUNT_OPTIONS.BASE_YEAR
      }
      style={{ width: 'fit-content' }}
    />
  );
  return [
    {
      value: BASE_AMOUNT_OPTIONS.BASE_YEAR,
      text: (
        <Row>
          <Col xs={6}>
            <FormattedMessage {...messages.baseYear} />
          </Col>
          <Col xs={6}>
            <Row>
              {scope ? (
                <ElementWithPermissions scope={scope}>
                  {baseYearField}
                </ElementWithPermissions>
              ) : (
                baseYearField
              )}
            </Row>
          </Col>
        </Row>
      ),
      disabled: false,
    },
    {
      value: BASE_AMOUNT_OPTIONS.OWNER_STOP_EXPENSE,
      text: (
        <div className="padbottom10">
          <FormattedMessage {...messages.ownerStopExpense} />
        </div>
      ),
      disabled: false,
    },
    {
      value: BASE_AMOUNT_OPTIONS.NONE,
      text: <FormattedMessage {...messages.none} />,
      disabled: false,
    },
  ];
};

const accountListCount = (accountsList: Array<Object>) => {
  return !isEmpty(accountsList[0]) && accountsList && accountsList.length;
};

export const generateExpenseCapsOptions = (
  extraSpaceForYoy: boolean,
  extraSpaceForAggregate: boolean,
  extraSpaceForCumulative: boolean,
  extraSpaceForCompounded: boolean,
  selectedExpenseCapYoyExcludedGLAccounts: Array<Object>,
  selectedExpenseCapAggregateExcludedGLAccounts: Array<Object>,
  selectedExpenseCapCumulativeExcludedGLAccounts: Array<Object>,
  selectedExpenseCapCompoundedExcludedGLAccounts: Array<Object>,
) => {
  const yoyPaddingBottom = accountListCount(
    selectedExpenseCapYoyExcludedGLAccounts,
  );
  const aggregatePaddingBottom = accountListCount(
    selectedExpenseCapAggregateExcludedGLAccounts,
  );
  const cumulativePaddingBottom = accountListCount(
    selectedExpenseCapCumulativeExcludedGLAccounts,
  );
  const compoundedPaddingBottom = accountListCount(
    selectedExpenseCapCompoundedExcludedGLAccounts,
  );

  return [
    {
      value: EXPENSE_CAPS_OPTIONS.YOY,
      text: (
        <div
          style={{
            'padding-bottom': extraSpaceForYoy
              ? yoyPaddingBottom > 2
                ? `${yoyPaddingBottom * 24 + 20}px`
                : '50px'
              : `${yoyPaddingBottom * 24 + 20}px`,
          }}
        >
          <FormattedMessage {...messages.yoy} />
        </div>
      ),
      disabled: false,
    },
    {
      value: EXPENSE_CAPS_OPTIONS.AGGREGATE,
      text: (
        <div
          style={{
            'padding-bottom': extraSpaceForAggregate
              ? `${aggregatePaddingBottom * 24 + 50}px`
              : `${aggregatePaddingBottom * 24 + 20}px`,
          }}
        >
          <FormattedMessage {...messages.aggregate} />
        </div>
      ),
      disabled: false,
    },
    {
      value: EXPENSE_CAPS_OPTIONS.CUMULATIVE,
      text: (
        <div
          style={{
            'padding-bottom': extraSpaceForCumulative
              ? `${cumulativePaddingBottom * 24 + 50}px`
              : `${cumulativePaddingBottom * 24 + 20}px`,
          }}
        >
          <FormattedMessage {...messages.cumulative} />
        </div>
      ),
      disabled: false,
    },
    {
      value: EXPENSE_CAPS_OPTIONS.COMPOUNDED,
      text: (
        <div
          style={{
            'padding-bottom': extraSpaceForCompounded
              ? `${compoundedPaddingBottom * 24 + 50}px`
              : `${compoundedPaddingBottom * 24 + 20}px`,
          }}
        >
          <FormattedMessage {...messages.compounded} />
        </div>
      ),
      disabled: false,
    },
    {
      value: EXPENSE_CAPS_OPTIONS.NONE,
      text: <FormattedMessage {...messages.none} />,
      disabled: false,
    },
  ];
};

export const generateProRataShareOptions = () => {
  return [
    {
      value: PRO_RATA_SHARE_OPTIONS.ACTUAL,
      text: (
        <div className="padbottom10">
          <FormattedMessage {...messages.actual} />
        </div>
      ),
      disabled: false,
    },
    {
      value: PRO_RATA_SHARE_OPTIONS.CUSTOM,
      text: (
        <div>
          <FormattedMessage {...messages.custom} />
        </div>
      ),
      disabled: false,
    },
  ];
};

export const generateGLAccountsOptions = () => {
  return [
    {
      value: GL_ACCOUNTS_OPTIONS.SELECT_ALL,
      text: (
        <div className="padbottom10">
          <FormattedMessage {...messages.includeGLAccounts} />
        </div>
      ),
      disabled: false,
    },
    {
      value: GL_ACCOUNTS_OPTIONS.NONE,
      text: (
        <div>
          <FormattedMessage {...messages.doNotSetupPool} />
        </div>
      ),
      disabled: false,
    },
  ];
};

export const generateGrossUpOptions = (selectedGrossUpExcludedGLAccounts) => {
  const grossYesPaddingBottom =
    !isEmpty(selectedGrossUpExcludedGLAccounts[0]) &&
    selectedGrossUpExcludedGLAccounts &&
    selectedGrossUpExcludedGLAccounts.length;

  return [
    {
      value: GROSS_UP_OPTIONS.YES,
      text: (
        <div
          style={{
            'padding-bottom': grossYesPaddingBottom
              ? `${grossYesPaddingBottom * 25}px`
              : '15px',
          }}
        >
          <FormattedMessage {...messages.yes} />
        </div>
      ),
      disabled: false,
    },
    {
      value: GROSS_UP_OPTIONS.NO,
      text: (
        <div className="padbottom10">
          <FormattedMessage {...messages.no} />
        </div>
      ),
      disabled: false,
    },
  ];
};

export const generateLeaseAllowsMonthlyEstimatesOptions = () => {
  return [
    {
      value: LEASE_ALLOWS_MONTHLY_EST_OPTIONS.YES,
      text: <FormattedMessage {...messages.yes} />,
      disabled: false,
    },
    {
      value: LEASE_ALLOWS_MONTHLY_EST_OPTIONS.NO,
      text: <FormattedMessage {...messages.no} />,
      disabled: false,
    },
  ];
};

export const formatGLAccountsResponse = (raw: Array<Object>): Array<Object> => {
  return raw.map((acct) => acct.account || acct);
};

export const generateIntacctGLAccountDropdownOptions = (
  glAccounts: Array<Object>,
): Array<Object> => {
  return glAccounts
    .map((account) => {
      const value = pathOr('', ['id'], account);
      const name = pathOr('', ['name'], account);
      const accountNo = pathOr('', ['accountNo'], account);
      const label = [accountNo, name].join(' - ');
      return {
        value,
        label,
        disabled: false,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const generateSubjournalDropdownOptions = (
  subjournals: Array<Object>,
) => {
  if (!subjournals) {
    return [
      {
        text: 'Loading..',
        value: '',
        disabled: true,
      },
    ];
  }
  const subjournalArray: Array<Object> = subjournals
    .map((val): Object => ({
      text: path(['masterSubjournalType', 'description'], val),
      value: path(['masterSubjournalId'], val),
      disabled: false,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));
  subjournalArray.unshift({
    text: 'Choose Subjournal',
    value: '',
    disabled: true,
  });
  return subjournalArray;
};

export const generateChargeCodeDropdownOptions = (
  chargeCodes: Array<Object>,
) => {
  if (!chargeCodes) {
    return [
      {
        text: 'Loading..',
        value: '',
        disabled: true,
      },
    ];
  }
  const chargeCodesArray: Array<Object> = chargeCodes
    .map((val): Object => {
      const value = pathOr('', ['id'], val);
      const code = pathOr('', ['transactionCode', 'code'], val);
      const description = pathOr('', ['transactionCode', 'description'], val);
      const text = [code, description].join(' - ');
      return {
        value,
        text,
        disabled: false,
      };
    })
    .sort((a, b) => a.text.localeCompare(b.text));
  chargeCodesArray.unshift({
    text: 'Choose Charge Code',
    value: '',
    disabled: true,
  });
  return chargeCodesArray;
};

const getExpenseCapDetailsForRequest = (
  expenseCapsOption: string,
  data: Object,
): Object => {
  switch (expenseCapsOption) {
    case EXPENSE_CAPS_OPTIONS.AGGREGATE:
      return {
        expenseCapPct: pathOr(null, ['expenseCapPercentageAggregate'], data),
        leaseStartYear: pathOr(
          null,
          ['expenseCapLeaseStartYearAggregate'],
          data,
        ),
        ignoredFromExpenseCapsAccounts: pathOr(
          [],
          ['expenseCapAggregateExcludedGLAccounts'],
          data,
        ),
      };
    case EXPENSE_CAPS_OPTIONS.CUMULATIVE:
      return {
        expenseCapPct: pathOr(null, ['expenseCapPercentageCumulative'], data),
        leaseStartYear: pathOr(
          null,
          ['expenseCapLeaseStartYearCumulative'],
          data,
        ),
        ignoredFromExpenseCapsAccounts: pathOr(
          [],
          ['expenseCapCumulativeExcludedGLAccounts'],
          data,
        ),
      };
    case EXPENSE_CAPS_OPTIONS.COMPOUNDED:
      return {
        expenseCapPct: pathOr(null, ['expenseCapPercentageCompounded'], data),
        leaseStartYear: pathOr(
          null,
          ['expenseCapLeaseStartYearCompounded'],
          data,
        ),
        ignoredFromExpenseCapsAccounts: pathOr(
          [],
          ['expenseCapCompoundedExcludedGLAccounts'],
          data,
        ),
      };
    case EXPENSE_CAPS_OPTIONS.YOY:
      return {
        expenseCapPct: null,
        leaseStartYear: null,
        ignoredFromExpenseCapsAccounts: pathOr(
          [],
          ['expenseCapYoyExcludedGLAccounts'],
          data,
        ),
      };
    default:
      return {
        expenseCapPct: null,
        leaseStartYear: null,
        ignoredFromExpenseCapsAccounts: [],
      };
  }
};

export const formatModifyAllocationRequest = (
  data: Object,
  allIntacctGLAccounts: Array<Object>,
  additionalData: Object,
): Object => {
  // If "None" GL accounts is selected, the rest of the config is not needed
  if (data.glAccountsOptions === GL_ACCOUNTS_OPTIONS.NONE) {
    return {
      noGLAccounts: true,
    };
  }

  const proRataShareOption = pathOr(null, ['proRataShareOptions'], data);
  const baseAmountOption = pathOr(null, ['baseAmountOptions'], data);
  const expenseCapsOption = pathOr(null, ['expenseCapsOptions'], data);
  const grossUpOption = pathOr(null, ['grossUpOptions'], data);
  const leaseMonthlyEstOption = pathOr(
    null,
    ['leaseAllowsMonthlyEstimatesOptions'],
    data,
  );

  // CAM AllocationCaps
  const isExpenseCapYoy = expenseCapsOption === EXPENSE_CAPS_OPTIONS.YOY;
  const isExpenseCapAggregate =
    expenseCapsOption === EXPENSE_CAPS_OPTIONS.AGGREGATE;
  const isExpenseCapCumulative =
    expenseCapsOption === EXPENSE_CAPS_OPTIONS.CUMULATIVE;
  const isExpenseCapCompounded =
    expenseCapsOption === EXPENSE_CAPS_OPTIONS.COMPOUNDED;
  const isExpenseCapNone =
    !isExpenseCapYoy &&
    !isExpenseCapAggregate &&
    !isExpenseCapCumulative &&
    !isExpenseCapCompounded;
  const expenseCapAmount = isExpenseCapYoy
    ? pathOr(null, ['expenseCapAmount'], data)
    : null;
  const { expenseCapPct, leaseStartYear, ignoredFromExpenseCapsAccounts } =
    getExpenseCapDetailsForRequest(expenseCapsOption, data);
  const expenseCapStartYearExpenses = !isExpenseCapNone
    ? pathOr(null, ['expenseCapStartYearExpenses'], data)
    : null;
  const isStartYearExpenseActual = !isExpenseCapNone
    ? additionalData.isStartYearExpenseActual
    : null;

  const expenseCap = {
    id: additionalData.expenseCapId,
    isExpenseCapYoy,
    isExpenseCapAggregate,
    isExpenseCapCumulative,
    isExpenseCapCompounded,
    isNone: isExpenseCapNone,
    expenseCapAmount,
    expenseCapPct,
    leaseStartYear,
    startYearExpenses: expenseCapStartYearExpenses,
    isStartYearExpenseActual,
  };

  // IntacctGLAccounts
  const selectedGLAccount = pathOr([], ['selectedGLAccounts'], data);

  const selectedGLAccountIds =
    selectedGLAccount.length === allIntacctGLAccounts.length
      ? allIntacctGLAccounts
          .map((acct) => pathOr(null, ['id'], acct))
          .filter((acct) => !!acct)
      : pathOr([], ['selectedGLAccounts'], data);
  const ignoredFromGrossUpAccounts =
    grossUpOption === GROSS_UP_OPTIONS.YES
      ? pathOr([], ['grossUpExcludedGLAccounts'], data)
      : [];
  const intacctGLAccounts = selectedGLAccountIds.map((intacctGLAccountId) => {
    const isExcludedFromGrossUps =
      ignoredFromGrossUpAccounts.includes(intacctGLAccountId);
    const isExcludedFromExpenseCaps =
      ignoredFromExpenseCapsAccounts.includes(intacctGLAccountId);
    return {
      intacctGLAccountId,
      isExcludedFromGrossUps,
      isExcludedFromExpenseCaps,
    };
  });

  // Gross ups
  const isGrossUpYes = grossUpOption === GROSS_UP_OPTIONS.YES;
  const isGrossUpNone = !isGrossUpYes;
  const grossUpPct = isGrossUpYes
    ? pathOr(null, ['grossUpYesPercentage'], data)
    : null;
  const projectedAverageOccupancy = isGrossUpYes
    ? pathOr(null, ['grossUpYesAvgOccupancy'], data)
    : null;
  const grossUp = {
    id: additionalData.grossUpId,
    isNone: isGrossUpNone,
    grossUpPct,
    projectedAverageOccupancy,
  };

  // Pro Rata Share
  const isProRataShareActual =
    proRataShareOption === PRO_RATA_SHARE_OPTIONS.ACTUAL;
  const isProRataShareCustom =
    proRataShareOption === PRO_RATA_SHARE_OPTIONS.CUSTOM;
  const isProRataShareNone = !isProRataShareActual && !isProRataShareCustom;
  const proRataShareCustomPct = isProRataShareCustom
    ? pathOr(null, ['proRataCustomPercentage'], data)
    : null;
  const proRataShareActualPct = isProRataShareActual
    ? pathOr(null, ['proRataShareActualPct'], additionalData)
    : null;
  const proRataShare = {
    id: additionalData.proRataShareId,
    isActual: isProRataShareActual,
    isCustom: isProRataShareCustom,
    isNone: isProRataShareNone,
    customPct: proRataShareCustomPct,
    actualPct: proRataShareActualPct,
  };

  // Base amounts
  const isBaseAmountBaseYear =
    baseAmountOption === BASE_AMOUNT_OPTIONS.BASE_YEAR;
  const isBaseAmountOwnerStopExpense =
    baseAmountOption === BASE_AMOUNT_OPTIONS.OWNER_STOP_EXPENSE;
  const isBaseAmountNone =
    !isBaseAmountBaseYear && !isBaseAmountOwnerStopExpense;
  const baseYear = isBaseAmountBaseYear
    ? pathOr(null, ['baseYear'], data)
    : null;
  const baseYearAmount = isBaseAmountBaseYear
    ? pathOr(null, ['baseYearAmount'], data)
    : null;
  const ownerExpenseStopAmount = isBaseAmountOwnerStopExpense
    ? pathOr(null, ['ownerStopExpenseAmount'], data)
    : null;
  const baseAmount = {
    id: additionalData.baseAmountId,
    isBaseYear: isBaseAmountBaseYear,
    isOwnerExpenseStop: isBaseAmountOwnerStopExpense,
    isNone: isBaseAmountNone,
    baseYear,
    baseYearAmount,
    ownerExpenseStopAmount,
  };

  const propertyTransactionCodeId = pathOr(null, ['chargeCode'], data);
  const reconciliationPropertyTransactionCodeId = pathOr(
    null,
    ['reconciliationChargeCode'],
    data,
  );
  const isLeaseAllowForMonthlyEstimates =
    leaseMonthlyEstOption === LEASE_ALLOWS_MONTHLY_EST_OPTIONS.YES;

  return {
    noGLAccounts: false,
    propertyTransactionCodeId,
    reconciliationPropertyTransactionCodeId,
    isLeaseAllowForMonthlyEstimates,
    intacctGLAccounts,
    expenseCap,
    grossUp,
    proRataShare,
    baseAmount,
  };
};

const getExpenseCapOptionsForInitialValues = (expenseCap: Object): string => {
  const expenseCapIsAggregate = pathOr(
    false,
    ['isExpenseCapAggregate'],
    expenseCap,
  );
  const expenseCapIsYoy = pathOr(false, ['isExpenseCapYoy'], expenseCap);
  const expenseCapIsCumulative = pathOr(
    false,
    ['isExpenseCapCumulative'],
    expenseCap,
  );
  const expenseCapIsCompounded = pathOr(
    false,
    ['isExpenseCapCompounded'],
    expenseCap,
  );
  if (expenseCapIsAggregate) {
    return EXPENSE_CAPS_OPTIONS.AGGREGATE;
  }
  if (expenseCapIsYoy) {
    return EXPENSE_CAPS_OPTIONS.YOY;
  }
  if (expenseCapIsCumulative) {
    return EXPENSE_CAPS_OPTIONS.CUMULATIVE;
  }
  if (expenseCapIsCompounded) {
    return EXPENSE_CAPS_OPTIONS.COMPOUNDED;
  }
  return EXPENSE_CAPS_OPTIONS.NONE;
};

export const parseAllocationModalInitialValues = (
  allocation: Object,
): Object => {
  const glOptionNone = pathOr(false, ['noGLAccounts'], allocation);
  const chargeCode = pathOr(
    undefined,
    ['propertyTransactionCodeId'],
    allocation,
  );
  const subjournal = pathOr(
    undefined,
    ['propertyTransactionCode', 'transactionCode', 'subjournalId'],
    allocation,
  );
  const reconciliationChargeCode = pathOr(
    undefined,
    ['reconciliationPropertyTransactionCodeId'],
    allocation,
  );
  const reconciliationSubjournal = pathOr(
    undefined,
    ['reconciliationPtc', 'transactionCode', 'subjournalId'],
    allocation,
  );

  if (!allocation) {
    // No allocation selected, this is for a new Exception. Select default fields
    return {
      proRataShareOptions: PRO_RATA_SHARE_OPTIONS.ACTUAL,
      baseAmountOptions: BASE_AMOUNT_OPTIONS.NONE,
      expenseCapsOptions: EXPENSE_CAPS_OPTIONS.NONE,
      grossUpOptions: GROSS_UP_OPTIONS.NO,
      leaseAllowsMonthlyEstimatesOptions: LEASE_ALLOWS_MONTHLY_EST_OPTIONS.YES,
    };
  }
  if (glOptionNone) {
    // If no GL account option is selected, the rest of the config doesn't matter
    return {
      glAccountsOptions: GL_ACCOUNTS_OPTIONS.NONE,
    };
  }

  // root allocation object
  const isLeaseAllowForMonthlyEstimates = pathOr(
    false,
    ['isLeaseAllowForMonthlyEstimates'],
    allocation,
  );
  const leaseAllowsMonthlyEstimatesOptions = isLeaseAllowForMonthlyEstimates
    ? LEASE_ALLOWS_MONTHLY_EST_OPTIONS.YES
    : LEASE_ALLOWS_MONTHLY_EST_OPTIONS.NO;

  // GL Accounts
  const selectedGLAccountsObjects = pathOr(
    [],
    ['intacctGLAccounts'],
    allocation,
  );
  const selectedGLAccounts = selectedGLAccountsObjects.map(
    (acct) => acct.intacctGLAccountId,
  );

  // pro rata shares
  const proRata = pathOr({}, ['proRataShare'], allocation);
  const proRataShareIsActual = pathOr(true, ['isActual'], proRata);
  const proRataShareOptions = proRataShareIsActual
    ? PRO_RATA_SHARE_OPTIONS.ACTUAL
    : PRO_RATA_SHARE_OPTIONS.CUSTOM;
  const proRataCustomPercentage = proRataShareIsActual
    ? null
    : pathOr(null, ['customPct'], proRata);

  // Base amount
  const baseAmount = pathOr({}, ['baseAmount'], allocation);
  const baseAmountIsBaseYear = pathOr(false, ['isBaseYear'], baseAmount);
  const baseAmountIsOwnerStopExpense = pathOr(
    false,
    ['isOwnerExpenseStop'],
    baseAmount,
  );
  const baseAmountOptions = baseAmountIsBaseYear
    ? BASE_AMOUNT_OPTIONS.BASE_YEAR
    : baseAmountIsOwnerStopExpense
    ? BASE_AMOUNT_OPTIONS.OWNER_STOP_EXPENSE
    : BASE_AMOUNT_OPTIONS.NONE;
  const baseYear = baseAmountIsBaseYear
    ? pathOr(null, ['baseYear'], baseAmount)
    : null;
  const baseYearAmount = baseAmountIsBaseYear
    ? pathOr(null, ['baseYearAmount'], baseAmount)
    : null;
  const ownerStopExpenseAmount = baseAmountIsOwnerStopExpense
    ? pathOr(null, ['ownerExpenseStopAmount'], baseAmount)
    : null;

  // gross up
  const grossUp = pathOr({}, ['grossUp'], allocation);
  const grossUpIsNone = pathOr(true, ['isNone'], grossUp);
  const grossUpOptions = grossUpIsNone
    ? GROSS_UP_OPTIONS.NO
    : GROSS_UP_OPTIONS.YES;
  const grossUpYesPercentage = pathOr(null, ['grossUpPct'], grossUp);
  const grossUpYesAvgOccupancy = pathOr(
    null,
    ['projectedAverageOccupancy'],
    grossUp,
  );
  const grossUpExcludedGLAccounts = !grossUpIsNone
    ? selectedGLAccountsObjects
        .filter((acct) => acct.isExcludedFromGrossUps)
        .map((acct) => acct.intacctGLAccountId)
    : [];

  // expense cap
  const expenseCap = pathOr({}, ['expenseCap'], allocation);
  const expenseCapsOptions = getExpenseCapOptionsForInitialValues(expenseCap);

  const expenseCapAmount =
    expenseCapsOptions === EXPENSE_CAPS_OPTIONS.YOY
      ? pathOr(null, ['expenseCapAmount'], expenseCap)
      : null;
  const expenseCapPercentage = [
    EXPENSE_CAPS_OPTIONS.AGGREGATE,
    EXPENSE_CAPS_OPTIONS.CUMULATIVE,
    EXPENSE_CAPS_OPTIONS.COMPOUNDED,
  ].includes(expenseCapsOptions)
    ? pathOr(null, ['expenseCapPct'], expenseCap)
    : null;
  const expenseCapLeaseStartYear =
    expenseCapsOptions !== EXPENSE_CAPS_OPTIONS.NONE
      ? pathOr(null, ['leaseStartYear'], expenseCap)
      : null;
  const expenseCapYoyExcludedGLAccounts =
    expenseCapsOptions === EXPENSE_CAPS_OPTIONS.YOY
      ? selectedGLAccountsObjects
          .filter((acct) => acct.isExcludedFromExpenseCaps)
          .map((acct) => acct.intacctGLAccountId)
      : [];
  const expenseCapExcludedGLAccounts = selectedGLAccountsObjects
    .filter((acct) => acct.isExcludedFromExpenseCaps)
    .map((acct) => acct.intacctGLAccountId);
  const expenseCapAggregateExcludedGLAccounts =
    expenseCapsOptions === EXPENSE_CAPS_OPTIONS.AGGREGATE
      ? expenseCapExcludedGLAccounts
      : [];
  const expenseCapCumulativeExcludedGLAccounts =
    expenseCapsOptions === EXPENSE_CAPS_OPTIONS.CUMULATIVE
      ? expenseCapExcludedGLAccounts
      : [];
  const expenseCapCompoundedExcludedGLAccounts =
    expenseCapsOptions === EXPENSE_CAPS_OPTIONS.COMPOUNDED
      ? expenseCapExcludedGLAccounts
      : [];
  const expenseCapStartYearExpenses =
    expenseCapsOptions !== EXPENSE_CAPS_OPTIONS.NONE
      ? pathOr(null, ['startYearExpenses'], expenseCap)
      : null;

  const expenseCapLeaseStartYearAggregate =
    expenseCapsOptions === EXPENSE_CAPS_OPTIONS.AGGREGATE
      ? expenseCapLeaseStartYear
      : null;
  const expenseCapLeaseStartYearCumulative =
    expenseCapsOptions === EXPENSE_CAPS_OPTIONS.CUMULATIVE
      ? expenseCapLeaseStartYear
      : null;
  const expenseCapLeaseStartYearCompounded =
    expenseCapsOptions === EXPENSE_CAPS_OPTIONS.COMPOUNDED
      ? expenseCapLeaseStartYear
      : null;

  const expenseCapPercentageAggregate =
    expenseCapsOptions === EXPENSE_CAPS_OPTIONS.AGGREGATE
      ? expenseCapPercentage
      : null;
  const expenseCapPercentageCumulative =
    expenseCapsOptions === EXPENSE_CAPS_OPTIONS.CUMULATIVE
      ? expenseCapPercentage
      : null;
  const expenseCapPercentageCompounded =
    expenseCapsOptions === EXPENSE_CAPS_OPTIONS.COMPOUNDED
      ? expenseCapPercentage
      : null;

  return {
    glAccountsOptions: GL_ACCOUNTS_OPTIONS.SELECT_ALL,
    selectedGLAccounts,
    leaseAllowsMonthlyEstimatesOptions,
    proRataShareOptions,
    proRataCustomPercentage,
    baseAmountOptions,
    baseYear,
    baseYearAmount,
    ownerStopExpenseAmount,
    grossUpOptions,
    grossUpYesPercentage,
    grossUpYesAvgOccupancy,
    grossUpExcludedGLAccounts,
    expenseCapsOptions,
    expenseCapAmount,
    expenseCapPercentageAggregate,
    expenseCapPercentageCumulative,
    expenseCapPercentageCompounded,
    expenseCapLeaseStartYearAggregate,
    expenseCapLeaseStartYearCumulative,
    expenseCapLeaseStartYearCompounded,
    expenseCapStartYearExpenses,
    expenseCapYoyExcludedGLAccounts,
    expenseCapAggregateExcludedGLAccounts,
    expenseCapCumulativeExcludedGLAccounts,
    expenseCapCompoundedExcludedGLAccounts,
    subjournal,
    chargeCode,
    reconciliationSubjournal,
    reconciliationChargeCode,
  };
};

export const getBaseAmountColumnText = (
  intl: any,
  allocation: Object,
): string => {
  const baseAmount = pathOr(null, ['baseAmount'], allocation);
  if (!baseAmount) {
    return '---';
  }

  const isBaseAmountBaseYear = pathOr(false, ['isBaseYear'], baseAmount);
  const isBaseAmountOwnerStopExpense = pathOr(
    false,
    ['isOwnerExpenseStop'],
    baseAmount,
  );
  const isBaseAmountNone =
    !isBaseAmountBaseYear && !isBaseAmountOwnerStopExpense;
  const baseYear = pathOr('', ['baseYear'], baseAmount);
  const baseYearAmount = pathOr('', ['baseYearAmount'], baseAmount);
  const ownerExpenseStopAmount = pathOr(
    '',
    ['ownerExpenseStopAmount'],
    baseAmount,
  );

  return isBaseAmountNone
    ? 'None'
    : isBaseAmountBaseYear
    ? `Base Year - ${baseYear}: ${formatCurrency(intl, +baseYearAmount)}`
    : `Owner Stop Expense: ${formatCurrency(intl, +ownerExpenseStopAmount)}`;
};

export const getExpenseCapsColumnText = (
  intl: any,
  allocation: Object,
): string => {
  const expenseCap = pathOr(null, ['expenseCap'], allocation);
  if (!expenseCap) {
    return '---';
  }

  const isExpenseCapYoy = pathOr(false, ['isExpenseCapYoy'], expenseCap);
  const isExpenseCapAggregate = pathOr(
    false,
    ['isExpenseCapAggregate'],
    expenseCap,
  );
  const isExpenseCapCumulative = pathOr(
    false,
    ['isExpenseCapCumulative'],
    expenseCap,
  );
  const isExpenseCapCompounded = pathOr(
    false,
    ['isExpenseCapCompounded'],
    expenseCap,
  );
  const expenseCapAmount = pathOr('', ['expenseCapAmount'], expenseCap);
  const expenseCapPct = pathOr('', ['expenseCapPct'], expenseCap);

  if (isExpenseCapYoy) {
    return `${formatCurrency(intl, +expenseCapAmount)} YOY`;
  }
  if (isExpenseCapAggregate) {
    return `${expenseCapPct}% Aggregate`;
  }
  if (isExpenseCapCumulative) {
    return `${expenseCapPct}% Cumulative`;
  }
  if (isExpenseCapCompounded) {
    return `${expenseCapPct}% Compounded`;
  }
  return '----';
};

export const getGrossUpColumnText = (intl: any, allocation: Object): string => {
  const grossUp = pathOr(null, ['grossUp'], allocation);
  if (!grossUp) {
    return '---';
  }

  const isNone = pathOr(true, ['isNone'], grossUp);
  const grossUpPct = pathOr('', ['grossUpPct'], grossUp);

  return isNone ? '---' : `${grossUpPct}%`;
};

export const getProRataShareColumnText = (
  intl: any,
  allocation: Object,
): string => {
  const proRataShare = pathOr(null, ['proRataShare'], allocation);
  if (!proRataShare) {
    return '---';
  }

  const isActual = pathOr(false, ['isActual'], proRataShare);
  const isCustom = pathOr(false, ['isCustom'], proRataShare);
  const isNone = !isActual && !isCustom;
  const customPct = pathOr('', ['customPct'], proRataShare);
  const actualPct = pathOr('', ['actualPct'], proRataShare);

  return isNone
    ? 'None - 0%'
    : isActual
    ? `Actual - ${actualPct}%`
    : `Custom - ${customPct}%`;
};

export const fetchStartYearExpenses = async ({
  allocation,
  accountsForExpenseCapStartYear,
  organizationId,
  propertyId,
  abortController,
}: FetchStartYearExpensesRequest): FetchStartYearExpensesResponse => {
  // If Expense Cap is Aggregate, we need to have the start year expense...
  // if leaseStartYearExpenses has a value it means user had to enter it manually so we can use it
  // if it has no value we need to fetch it from BE
  const isExpenseCapAggregate = pathOr(
    false,
    ['expenseCap', 'isExpenseCapAggregate'],
    allocation,
  );
  const isExpenseCapCumulative = pathOr(
    false,
    ['expenseCap', 'isExpenseCapCumulative'],
    allocation,
  );
  const isExpenseCapCompounded = pathOr(
    false,
    ['expenseCap', 'isExpenseCapCompounded'],
    allocation,
  );
  const isExpenseCapYoy = pathOr(
    false,
    ['expenseCap', 'isExpenseCapYoy'],
    allocation,
  );
  const leaseStartYear = pathOr(
    null,
    ['expenseCap', 'leaseStartYear'],
    allocation,
  );
  const leaseStartYearExpenses = pathOr(
    null,
    ['expenseCap', 'startYearExpenses'],
    allocation,
  );

  const typeNeedsExpenses =
    isExpenseCapAggregate || isExpenseCapCumulative || isExpenseCapCompounded;

  let fetchedStartYearExpenses = null;
  let isStartYearExpenseActual = false;
  let needsStartYearExpenses = false;
  if (
    typeNeedsExpenses &&
    !isNil(leaseStartYear) &&
    (isNil(leaseStartYearExpenses) || isEmpty(leaseStartYearExpenses))
  ) {
    // aggregate => use the leaseStartYear value
    const period = `${leaseStartYear}12`; // balance at the end of the year
    const payload = {
      period,
      accounts: accountsForExpenseCapStartYear || [],
    };
    const intacctAccountBalancesService = new IntacctAccountBalancesService();
    const response = await intacctAccountBalancesService.get(
      organizationId,
      propertyId,
      payload,
      abortController.signal,
    );
    fetchedStartYearExpenses = pathOr(null, ['balances'], response);
    needsStartYearExpenses = true;
    isStartYearExpenseActual = !isNil(fetchedStartYearExpenses);
  } else if (
    isExpenseCapYoy &&
    (isNil(leaseStartYearExpenses) || isEmpty(leaseStartYearExpenses))
  ) {
    const periodYear = moment().year() - 1;
    const period = `${periodYear}12`; // balance at the end of the year
    const payload = {
      period,
      accounts: accountsForExpenseCapStartYear || [],
    };
    const intacctAccountBalancesService = new IntacctAccountBalancesService();
    const response = await intacctAccountBalancesService.get(
      organizationId,
      propertyId,
      payload,
      abortController.signal,
    );
    fetchedStartYearExpenses = pathOr(null, ['balances'], response);
    needsStartYearExpenses = true;
    isStartYearExpenseActual = !isNil(fetchedStartYearExpenses);
  } else {
    fetchedStartYearExpenses = leaseStartYearExpenses;
    needsStartYearExpenses = false;
  }

  return {
    needsStartYearExpenses,
    fetchedStartYearExpenses,
    isStartYearExpenseActual,
    leaseStartYear: isExpenseCapYoy ? `${moment().year() - 1}` : leaseStartYear,
  };
};
