import { defineMessages } from 'react-intl';

const messages = defineMessages({
  headerTitle: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.HeaderTitle',
    defaultMessage: 'Add New Lease Charge',
  },
  headerTitleMonthly: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.headerTitleMonthly',
    defaultMessage: 'Add Monthly Transaction',
  },
  headerTitleMonthlyeEdit: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.headerTitleMonthlyeEdit',
    defaultMessage: 'Edit Monthly Transaction',
  },
  propertyNameLabel: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.PropertyNameLabel',
    defaultMessage: 'Property Name',
  },
  unitNumberLabel: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.UnitNumberLabel',
    defaultMessage: 'Unit Number',
  },
  statusLabel: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.StatusLabel',
    defaultMessage: 'Status',
  },
  nameLabel: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.NameLabel',
    defaultMessage: 'Name',
  },
  subjournalLabel: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.SubjournalLabel',
    defaultMessage: 'Subjournal',
  },
  transactionTypeLabel: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.TransactionTypeLabel',
    defaultMessage: 'Transaction Type',
  },
  startDateLabel: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.StartDateLabel',
    defaultMessage: 'Start Date',
  },
  endDateLabel: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.EndDateLabel',
    defaultMessage: 'End Date',
  },
  noEndDateLabel: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.NoEndDateLabel',
    defaultMessage: 'No End Date',
  },
  transactionCodeLabel: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.TransactionCodeLabel',
    defaultMessage: 'Transaction Code',
  },
  noteLabel: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.NoteLabel',
    defaultMessage: 'Note',
  },
  transactionAmountLabel: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.TransactionAmountLabel',
    defaultMessage: 'Transaction Amount',
  },
  cancelConfirmationHeader: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.cancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to cancel?',
  },
  yes: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.no',
    defaultMessage: 'No',
  },
  createdBy: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.createdBy',
    defaultMessage: 'Created By',
  },
  updatedBy: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.updatedBy',
    defaultMessage: 'Updated By',
  },
  on: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.on',
    defaultMessage: '@',
  },
  validDateAfterEndDate: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.validDateAfterEndDate',
    defaultMessage: "Can't select a date after end date",
  },
  validDateBeforeStartDate: {
    id: 'App.LeaseDataTabForm.MonthlyTransactionModal.validDateBeforeStartDate',
    defaultMessage: "Can't select a date before start date",
  },
});

export default messages;
