import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageUnitAvailability.Title',
    defaultMessage: 'Fortress - Manage Unit Availability',
  },
  header: {
    id: 'App.ManageUnitAvailability.Header',
    defaultMessage: 'Manage Unit Availability',
  },
  subHeader: {
    id: 'App.ManageUnitAvailability.SubHeader',
    defaultMessage: 'Units found:',
  },
  amenities: {
    id: 'App.ManageUnitAvailability.Amenities',
    defaultMessage: 'Amenities',
  },
  searchUnits: {
    id: 'App.ManageUnitAvailability.SearchUnits',
    defaultMessage: 'Search Units...',
  },
  noUnitsAvailable: {
    id: 'App.ManageUnitAvailability.NoUnitsAvailable',
    defaultMessage: 'No units available.',
  },
  unit: {
    id: 'App.ManageUnitAvailability.Unit',
    defaultMessage: 'Unit',
  },
  floorPlan: {
    id: 'App.ManageUnitAvailability.FloorPlan',
    defaultMessage: 'Floor Plan',
  },
  bedsBaths: {
    id: 'App.ManageUnitAvailability.BedsBaths',
    defaultMessage: 'Beds / Baths',
  },
  sqFeet: {
    id: 'App.ManageUnitAvailability.SqFeet',
    defaultMessage: 'Sq. Ft.',
  },
  status: {
    id: 'App.ManageUnitAvailability.Status',
    defaultMessage: 'Status',
  },
  leased: {
    id: 'App.ManageUnitAvailability.Leased',
    defaultMessage: 'Leased',
  },
  resident: {
    id: 'App.ManageUnitAvailability.Resident',
    defaultMessage: 'Resident',
  },
  moveInReady: {
    id: 'App.ManageUnitAvailability.MoveInReady',
    defaultMessage: 'Move-In Ready',
  },
  leaseStartDate: {
    id: 'App.ManageUnitAvailability.LeaseStartDate',
    defaultMessage: 'Lease Start Date',
  },
  leaseEndDate: {
    id: 'App.ManageUnitAvailability.LeaseEndDate',
    defaultMessage: 'Lease End Date',
  },
  marketRent: {
    id: 'App.ManageUnitAvailability.GPRMarketRent',
    defaultMessage: 'Unit GPR (Market Rent)',
  },
  netMaxRent: {
    id: 'App.ManageUnitAvailability.netMaxRent',
    defaultMessage: 'Net Max Rent',
  },
  priorLeasedRent: {
    id: 'App.ManageUnitAvailability.PriorLeasedRent',
    defaultMessage: 'Prior Leased Rent',
  },
  currentLeasedRent: {
    id: 'App.ManageUnitAvailability.CurrentLeasedRent',
    defaultMessage: 'Current Leased Rent',
  },
  otherUnitFees: {
    id: 'App.ManageUnitAvailability.OtherUnitFees',
    defaultMessage: 'Other Unit Fees',
  },
  requiredUnitFees: {
    id: 'App.ManageUnitAvailability.RequiredUnitFees',
    defaultMessage: 'Required Unit Fees',
  },
  balance: {
    id: 'App.ManageUnitAvailability.Balance',
    defaultMessage: 'Balance',
  },
  moveOutDate: {
    id: 'App.ManageUnitAvailability.MoveOutDate',
    defaultMessage: 'Move-Out Date',
  },
  moveInReadyStatus: {
    id: 'App.ManageUnitAvailability.MoveInReadyStatus',
    defaultMessage: 'Move-In Ready Status',
  },
  notes: {
    id: 'App.ManageUnitAvailability.Notes',
    defaultMessage: 'Notes',
  },
  daysVacant: {
    id: 'App.ManageUnitAvailability.DaysVacant',
    defaultMessage: 'Days Vacant',
  },
  quotingRent: {
    id: 'App.ManageUnitAvailability.QuotingRent',
    defaultMessage: 'Quoting Rent',
  },
  applicant: {
    id: 'App.ManageUnitAvailability.Applicant',
    defaultMessage: 'Applicant',
  },
  applicantStatus: {
    id: 'App.ManageUnitAvailability.ApplicantStatus',
    defaultMessage: 'Applicant Status',
  },
  scheduledMoveInDate: {
    id: 'App.ManageUnitAvailability.ScheduledMoveInDate',
    defaultMessage: 'Scheduled Move In Date',
  },
  view: {
    id: 'App.ManageUnitAvailability.View',
    defaultMessage: 'View',
  },
  immediate: {
    id: 'App.ManageUnitAvailability.Immediate',
    defaultMessage: 'Immediate',
  },
  unknown: {
    id: 'App.ManageUnitAvailability.Unknown',
    defaultMessage: 'Unknown',
  },
  successHeader: {
    id: 'App.ManageUnitAvailability.SuccessHeader',
    defaultMessage: 'Success',
  },
  successDescription: {
    id: 'App.ManageUnitAvailability.SuccessDescription',
    defaultMessage: 'Current specials were saved',
  },
  errorHeader: {
    id: 'App.ManageUnitAvailability.ErrorHeader',
    defaultMessage: 'Error',
  },
  saveSpecials: {
    id: 'App.ManageUnitAvailability.SaveSpecials',
    defaultMessage: 'Save Specials',
  },
  currentSpecials: {
    id: 'App.ManageUnitAvailability.CurrentSpecials',
    defaultMessage: 'Current Specials:',
  },
  edit: {
    id: 'App.ManageUnitAvailability.Edit',
    defaultMessage: 'Edit',
  },
  editCurrentSpecials: {
    id: 'App.ManageUnitAvailability.EditCurrentSpecials',
    defaultMessage: 'Edit Current Specials',
  },
  goBack: {
    id: 'App.ManageUnitAvailability.GoBack',
    defaultMessage: 'Go back to Applicant',
  },
  goBackProspect: {
    id: 'App.ManageUnitAvailability.GoBackProspect',
    defaultMessage: 'Go back to Prospect',
  },
  goBackResident: {
    id: 'App.ManageUnitAvailability.GoBackResident',
    defaultMessage: 'Go back to Resident',
  },
  actions: {
    id: 'App.ManageUnitAvailability.Actions',
    defaultMessage: 'Actions',
  },
  confirmAssignUnit: {
    id: 'App.ManageUnitAvailability.ConfirmAssignUnit',
    defaultMessage: 'Are you sure you want to Assign this Unit to Applicant:',
  },
  confirmTransferUnit: {
    id: 'App.ManageUnitAvailability.ConfirmTransferUnit',
    defaultMessage:
      'Are you sure you want to start a transfer to this Unit of resident:',
  },
  assignUnitSuccess: {
    id: 'App.ManageUnitAvailability.AssignUnitSuccess',
    defaultMessage: 'Success: Unit Assigned.',
  },
  unitUnassigned: {
    id: 'App.ManageUnitAvailability.UnitUnassigned',
    defaultMessage: 'Success: Unit Unassigned.',
  },
  confirmUnassignUnit: {
    id: 'App.ManageUnitAvailability.confirmUnassignUnit',
    defaultMessage:
      'Are you sure you want to unassign this Unit from Applicant:',
  },
  tenant: {
    id: 'App.ManageUnitAvailability.Tenant',
    defaultMessage: 'Tenant',
  },
  unitAmenities: {
    id: 'App.ManageUnitAvailability.UnitAmenities',
    defaultMessage: 'Unit Amenities',
  },
  quote: {
    id: 'App.ManageUnitAvailability.Quote',
    defaultMessage: 'Quote',
  },
  assign: {
    id: 'App.ManageUnitAvailability.Assign',
    defaultMessage: 'Assign',
  },
  unassign: {
    id: 'App.ManageUnitAvailability.Unassign',
    defaultMessage: 'Unassign',
  },
});

export default messages;
