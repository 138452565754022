import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ManageTransactionBatches.Header.header',
    defaultMessage: 'Manage Transaction Batches',
  },
  createNewBatchButtonLabel: {
    id: 'App.ManageTransactionBatches.Header.createNewBatchButtonLabel',
    defaultMessage: 'Create New Transaction Batch',
  },
});

export default messages;
