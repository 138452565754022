import { useEffect, useState } from 'react';
import FileSaver from 'file-saver';

import confirm from '../../../components/ConfirmDialogModal';
import BankDepositService from '../../../services/bankDepositService';
import messages from './messages';

type DocumentsHookArgs = {
  intl: Object,
  propertyId: string,
  organizationId: string,
  depositId: string,
  promptToaster: Function,
};

type DeleteDocumentHooksArgs = {
  intl: Object,
  propertyId: string,
  organizationId: string,
  depositId: string,
  promptToaster: Function,
  refresh: Function,
};

export const useFetchDocuments = ({
  intl,
  propertyId,
  organizationId,
  depositId,
  promptToaster,
}: DocumentsHookArgs) => {
  const [documents, setDocuments] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetchDepositDocuments = async () => {
      setIsLoading(true);
      try {
        const bankDepositService = new BankDepositService();
        const response = await bankDepositService.getDepositAttachments(
          organizationId,
          propertyId,
          depositId,
          abortController.signal,
        );

        setDocuments(response);
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.attachmentsLoadingErrorTitle),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    };

    if (shouldRefresh && !isLoading) {
      fetchDepositDocuments();
    }

    return () => {
      setShouldRefresh(false);
    };
  }, [
    organizationId,
    propertyId,
    depositId,
    promptToaster,
    intl,
    shouldRefresh,
    isLoading,
  ]);

  return [isLoading, documents, () => setShouldRefresh(true)];
};

export const useDownloadDocument = ({
  intl,
  propertyId,
  organizationId,
  depositId,
  promptToaster,
}: DocumentsHookArgs) => {
  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState(null);

  const onDownloadDocument = (document: Object) => {
    setDocument(document);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const downloadDocument = async (document: Object) => {
      setIsLoading(true);
      try {
        if (
          !propertyId ||
          !organizationId ||
          !depositId ||
          !document ||
          !document.id
        ) {
          throw new Error('Could not download document');
        }

        const bankDepositService = new BankDepositService();
        const file = await bankDepositService.downloadDepositAttachment(
          organizationId,
          propertyId,
          depositId,
          document.id,
        );
        FileSaver.saveAs(file, document.name);
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.attachmentDownloadErrorTitle),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (!isLoading && document) {
      downloadDocument(document);
    }

    return () => {
      setDocument(null);
    };
  }, [
    organizationId,
    propertyId,
    depositId,
    promptToaster,
    intl,
    isLoading,
    document,
  ]);

  return onDownloadDocument;
};

export const useDeleteDocument = ({
  intl,
  propertyId,
  organizationId,
  depositId,
  promptToaster,
  refresh,
}: DeleteDocumentHooksArgs) => {
  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState(null);

  const onDeleteDocument = (document: Object) => {
    setDocument(document);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const deleteDocument = async (document: Object): any => {
      setIsLoading(true);
      try {
        if (
          !propertyId ||
          !organizationId ||
          !depositId ||
          !document ||
          !document.id
        ) {
          throw new Error('Could not download document');
        }
        const bankDepositService = new BankDepositService();
        await bankDepositService.deleteDepositAttachment(
          organizationId,
          propertyId,
          depositId,
          document.id,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(messages.attachmentDeleteSuccessTitle),
          message: intl.formatMessage(messages.attachmentDeleteSuccessMessage),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.attachmentDeleteErrorTitle),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        refresh();
      }
    };

    if (!isLoading && document) {
      confirm(intl.formatMessage(messages.confirmDelete), { intl }).then(
        () => deleteDocument(document),
        () => {},
      );
    }

    return () => {
      setDocument(null);
    };
  }, [
    organizationId,
    propertyId,
    depositId,
    promptToaster,
    intl,
    isLoading,
    document,
    refresh,
  ]);

  return onDeleteDocument;
};
