import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import { YesNoColumns, IncomeAssetCurrencyField } from '../../SharedFormFields';
import messages from '../messages';
import { renderTextField } from '../../../../utils/redux-form-helper';

export const RetirementPlanRows = ({
  assets,
  fields,
  noClicked,
  isPrior,
}: Object) => {
  return (
    <div className="row faux-table__row">
      <div className="col-xs-9">
        <div className="row padbottom10">
          <div className="col-xs-1">
            <span>9</span>
          </div>
          <div className="col-xs-10">
            <FormattedMessage {...messages.retirementPlanAccount} />
          </div>
        </div>
      </div>
      <div className="col-xs-12">
        {assets.map((asset) => {
          const disabled = fields[asset.name] !== 'yes';
          return (
            <div className="row" key={asset.name}>
              {asset.name === 'otherRetirementAccount' ? (
                <div className="col-xs-3">
                  <Field
                    name="otherRetirementAccountName"
                    component={renderTextField}
                    maxLength="35"
                    className="input-lg"
                    placeholder="Other"
                    disabled={isPrior || disabled}
                    required={!disabled}
                  />
                </div>
              ) : (
                <div>
                  <div className="col-xs-3 text-right">
                    <FormattedMessage {...messages[`${asset.name}`]} />
                  </div>
                </div>
              )}
              <YesNoColumns
                name={asset.name}
                noClicked={() => noClicked(asset.name)}
                isPrior={isPrior}
              />
              <IncomeAssetCurrencyField
                name={asset.name}
                type={'AssetValue'}
                disabled={disabled || isPrior}
              />
              <IncomeAssetCurrencyField
                name={asset.name}
                type={'AssetIncome'}
                disabled={disabled || isPrior}
              />

              <div className="col-xs-2">
                {asset.forms ? (
                  <FormattedMessage {...messages[`${asset.forms}`]} />
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
