import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ConfirmModal from '../../components/ConfirmModal';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';
import { pathOr } from 'ramda';

import type { User } from '../App/types';
import * as errorBoundariesActions from './actions';
type StateProps = {
  currentUser: User,
};

type InjectedProps = {
  actions: Object,
  children: any,
  intl: any,
};

type State = {
  hasError: boolean,
};
export class ErrorBoundary extends Component<
  StateProps & InjectedProps,
  State,
> {
  error: string;
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
    this.error = '';
  }

  closeModal = () => {
    this.setState({ hasError: false });
    window.location.assign('/');
  };

  componentDidCatch(error: string, info: string) {
    this.setState({ hasError: true });
    this.error = error;
    const userId = pathOr('', ['currentUser', 'id'], this.props);
    this.props.actions.logError(error, info, userId, window.location.href);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ConfirmModal
          size="small"
          show
          onHide={this.closeModal}
          title
          header={
            <Fragment>
              <h1>
                <FormattedMessage {...messages.title} />
              </h1>
            </Fragment>
          }
          footer={
            <div className="row">
              <div className="col-xs-12">
                <Button
                  type="submit"
                  onClick={this.closeModal}
                  bsStyle="primary"
                  className={'center-block'}
                >
                  <FormattedMessage {...messages.submit} />
                </Button>
              </div>
            </div>
          }
          confirmMessage={<FormattedMessage {...messages.confirmMessage} />}
          confirm={false}
          onConfirm={this.closeModal}
          rejectMessage={''}
          onReject={this.closeModal}
        >
          <p
            className={'text-danger text-center'}
            style={{
              padding: '10px',
            }}
          >
            {this.props.intl.formatMessage(messages.confirmMessage, {
              error: this.error,
            })}{' '}
          </p>
        </ConfirmModal>
      );
    }
    return this.props.children;
  }
}
export const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  const actions = bindActionCreators(
    {
      ...errorBoundariesActions,
    },
    dispatch,
  );
  return { actions };
};

export const mapStateToProps = ({ app }: Object): any => {
  return {
    currentUser: pathOr(null, ['currentUser', 'user'], app),
  };
};
const InjectedForm = injectIntl(ErrorBoundary);

export default connect(mapStateToProps, mapDispatchToProps)(InjectedForm);
