import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { submit } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ConfirmOverlay from '../../../../../components/ConfirmOverlay';
import SubmitButton from '../../../../../components/SubmitButton';

import messages from '../messages';
import { EDIT_GR_CERT_FORM_NAME } from '../constants';

import { useAsyncCorrectionCodeOptions } from './hooks';
import EditGRCertForm from './EditGRCertForm';

import { parseSubmitValues } from './utils';

type EditGRModalProps = {
  show: boolean,
  intl: Object,
  editCertInfo: Object,
  actions: Object,
  selectedProperty: Object,
};

const EditGRModal = ({
  onHide,
  show,
  intl,
  editCertInfo = {},
  actions,
  selectedProperty,
}: EditGRModalProps) => {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [disableSubmittal, setDisableSubmittal] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const updateDisableSubmittal = (disabled: boolean) => {
    setDisableSubmittal(disabled);
  };

  const hideCancelConfirmation = () => setShowCancelConfirmation(false);

  const handleHide = () => {
    if (!showCancelConfirmation) {
      setShowCancelConfirmation(true);
      return;
    }
    setShowCancelConfirmation(false);
    if (onHide) onHide();
  };

  const handleHideCleanup = () => {
    setSubmitting(false);
    setShowCancelConfirmation(false);
    setDisableSubmittal(true);
    onHide();
  };

  const handleSubmit = (values: Object) => {
    const submitValues = parseSubmitValues(values);
    editCertInfo.handleEditGRCert(submitValues, handleHideCleanup);
  };

  const handleSubmitClick = () => {
    setSubmitting(true);
    actions.submit(EDIT_GR_CERT_FORM_NAME);
  };

  const correctionCodes = useAsyncCorrectionCodeOptions(selectedProperty);
  return (
    <Modal show={show} autoFocus onHide={handleHide}>
      <Modal.Header closeButton>
        <i className="icon et-pencil" />
        <h1>{intl.formatMessage(messages.editGRModalTitle)}</h1>
      </Modal.Header>
      <Modal.Body>
        {showCancelConfirmation && (
          <ConfirmOverlay
            title={<FormattedMessage {...messages.confirmationTitle} />}
            rejectMessage={
              <FormattedMessage {...messages.confirmationCancel} />
            }
            confirmMessage={<FormattedMessage {...messages.confirm} />}
            onReject={hideCancelConfirmation}
            onConfirm={handleHide}
          />
        )}
        {editCertInfo && (
          <EditGRCertForm
            householdValues={editCertInfo.householdValues}
            initialValues={editCertInfo.initialValues}
            onSubmit={handleSubmit}
            correctionCodes={correctionCodes}
            updateDisableSubmittal={updateDisableSubmittal}
          />
        )}
      </Modal.Body>
      <Modal.Footer className={showCancelConfirmation ? 'disabled ' : ''}>
        <div className="row">
          <div className="col-xs-6">
            <button className="btn btn-default pull-right" onClick={handleHide}>
              Cancel
            </button>
          </div>
          <div className="col-xs-6">
            <SubmitButton
              classes="btn btn-primary pull-left"
              clickHandler={handleSubmitClick}
              disabled={disableSubmittal}
              isSubmitting={submitting}
            >
              Save
            </SubmitButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({ app: { selectedProperty } }: any): any => {
  return { selectedProperty };
};

const mapDispatchToProps = (dispatch: Object): ConnectedMethods => ({
  actions: bindActionCreators({ submit }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EditGRModal));
