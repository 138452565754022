import React from 'react';
import { Field } from 'redux-form';

import {
  renderSelectField,
  renderTextField,
  renderCurrencyField,
  renderCheckboxField,
} from '../../../../../../utils/redux-form-helper';

import {
  YES_NO_SELECT_OPTIONS,
  PAYS_FOR_SIGNAGE_SELECT_OPTIONS,
  FEE_TYPE_SELECT_OPTIONS,
} from '../../constants';

import { FlexFormInput } from '../../common';

const SignageOptionsFormFields = ({ values }: Object) => {
  return (
    <React.Fragment>
      <FlexFormInput>
        <label className="control-label">Right to Signage</label>
        <Field
          name="Right to Signage.building"
          selectClassNames="input-md form-control"
          component={renderCheckboxField}
          label="Building"
        />
        <Field
          name="Right to Signage.directory"
          selectClassNames="input-md form-control"
          component={renderCheckboxField}
          label="Directory"
        />
        <Field
          name="Right to Signage.pylon/monument"
          selectClassNames="input-md form-control"
          component={renderCheckboxField}
          label="Pylon/Monument"
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Right to Signage Other"
          component={renderTextField}
          label="Right to Signage Other"
          maxLength="255"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Pays for Signage"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Pays for Signage"
          options={PAYS_FOR_SIGNAGE_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Pays for Signage Repairs"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Pays for Signage Repairs"
          options={PAYS_FOR_SIGNAGE_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Signage Fees Type"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Signage Fees Type"
          options={FEE_TYPE_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Signage Fees"
          component={renderCurrencyField}
          label="Signage Fees"
          step="0.01"
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Prorate Signage Fees"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Prorate Signage Fees"
          options={YES_NO_SELECT_OPTIONS}
        />
      </FlexFormInput>
    </React.Fragment>
  );
};

export default SignageOptionsFormFields;
