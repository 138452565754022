import { createSelector } from 'reselect';
import type { GlobalState } from '../App/types';
import { path, prop, pathOr } from 'ramda';

const getCreateResidentState = (state: GlobalState, props: Object) => {
  return { resident: state.createResident, history: props };
};

export const getApplicant = createSelector(
  getCreateResidentState,
  ({ resident, history }) => {
    if (!resident.currentApplication) return null;
    const applicants = resident.currentApplication.applicants;
    if (!applicants) {
      history.push('/');
      window.location.reload();
    }

    return applicants.find((applicant) =>
      pathOr(null, ['applicantCustomer', 'isProspect'], applicant),
    );
  },
);

export const getApplicantName = createSelector(getApplicant, (applicant) =>
  applicant ? `${applicant?.name}` : '---',
);

export const getUnit = createSelector(
  getCreateResidentState,
  ({ resident }) => {
    if (!resident.currentApplication) return null;
    const au = resident.currentApplication.au;
    return au.unit;
  },
);

export const getUnitNumber = createSelector(getUnit, (unit) =>
  unit ? `${unit.number}` : '---',
);

export const getLease = createSelector(getCreateResidentState, ({ resident }) =>
  path(['currentApplication', 'au', 'lease'], resident),
);

export const getLeaseStartDate = createSelector(getLease, (lease) =>
  prop('startDate', lease),
);
