import { useMemo } from 'react';
import { DropdownOption } from '../App/types';

export function useApplicationStatusOptions(
  applicationStatuses: Array<DropdownOption>,
  applicationStatusId: string,
): [Array<DropdownOption>] {
  const applicationStatusOptions = useMemo(() => {
    const selectedApplicationStatus = applicationStatuses.find(
      (applicationStatus) => applicationStatus.value === applicationStatusId,
    );

    return applicationStatuses.filter((applicationStatus) => {
      if (selectedApplicationStatus?.text === 'Abandoned') {
        if (
          applicationStatus.text === 'New Portal App' ||
          applicationStatus.text === 'Abandoned'
        ) {
          return true;
        }
        return false;
      }

      if (
        selectedApplicationStatus?.text !== 'New Portal App' &&
        applicationStatus.text === 'Abandoned'
      ) {
        return false;
      }

      return true;
    });
  }, [applicationStatusId, applicationStatuses]);

  return [applicationStatusOptions];
}
