import messages from './messages';
import moment from 'moment';
import { type } from 'ramda';

const validate = (values: Object, { intl, error }: Object) => {
  const errors = {};
  let datesValid = true;
  if (error && error.async) {
    errors._error = error;
  }
  if (!values.notes) {
    errors.notes = intl.formatMessage(messages.notesRequired);
  }

  const currentDate = moment();
  const startDate = moment(values.startDate);
  const startTime = moment(values.startTime);
  const allDay = values.allDay;
  const endDate = moment(startDate);
  const endTime = values.endTime ? moment(values.endTime) : null;
  const prospectCreationDate = moment(values.prospectCreationDate);

  if (!startDate.isValid() || type(values.startDate) === 'String') {
    errors.startDate = intl.formatMessage(messages.invalidDate);
    datesValid = false;
  }

  if (!startTime.isValid() || type(values.startTime) === 'String') {
    errors.startTime = intl.formatMessage(messages.invalidTime);
    datesValid = false;
  }

  if (datesValid) {
    startDate.set({
      hour: startTime.hours(),
      minute: startTime.minutes(),
      second: 0,
      millisecond: 0,
    });

    if (endTime && endTime.isValid() && type(values.endTime) !== 'String') {
      endDate.set({
        hour: values.endTime.hours(),
        minute: values.endTime.minutes(),
        second: 0,
        millisecond: 0,
      });
      if (startDate.toDate() > endDate.toDate() && !allDay) {
        errors._error = {
          sync: true,
          async: false,
          message: intl.formatMessage(messages.noEndDateBeforeStartDate),
        };
      }
    } else {
      errors.endTime = intl.formatMessage(messages.invalidTime);
    }
  }

  if (
    values.activityCompletionStatusId &&
    currentDate.isBefore(startDate.format())
  ) {
    errors._error = {
      sync: true,
      async: false,
      message: intl.formatMessage(messages.futureActivityDate),
    };
  }

  if (
    !allDay &&
    !values.activityCompletionStatusId &&
    startDate.isBefore(currentDate.format())
  ) {
    errors._error = {
      sync: true,
      async: false,
      message: intl.formatMessage(messages.pastDateNoCompletion),
    };
  }

  if (
    allDay &&
    !values.activityCompletionStatusId &&
    startDate.startOf('day').isBefore(currentDate.startOf('day'))
  ) {
    errors._error = {
      sync: true,
      async: false,
      message: intl.formatMessage(messages.pastDateNoCompletion),
    };
  }

  if (startDate.add(7, 'days').isBefore(prospectCreationDate.startOf('day'))) {
    errors._error = {
      sync: true,
      async: false,
      message: intl.formatMessage(messages.tooFarPast),
    };
  }

  return errors;
};

export default validate;
