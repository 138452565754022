import { isEmpty } from 'ramda';
import { ListItem, Property, EnumListItem } from '../types';

export const parsePropertiesToEnumListItem = (
  properties: Array<Property> = [],
): Array<EnumListItem> => {
  return properties.map((property) => ({
    value: property.id,
    label: property.name,
    filterId: property.propertyClassId,
  }));
};

export const parseValueToListItem = (
  value: Array<string> = [],
  properties: Array<Property> = [],
  groupLabel: string = '',
): Array<ListItem> => {
  const itemGroupLabel = isEmpty(groupLabel) ? undefined : groupLabel;
  return value.reduce((acc, propertyId) => {
    const property = properties.find(
      (findProperty) => findProperty.id === propertyId,
    );

    if (!isEmpty(property ?? {})) {
      return [
        ...acc,
        {
          id: property.id,
          label: property.name,
          groupLabel: itemGroupLabel,
        },
      ];
    }

    return acc;
  }, []);
};
