const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class FiscalPeriodsService {
  getPropertyOpenFiscalPeriod(
    propertyId: string,
    organizationId: string,
    options: Object = {},
  ) {
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...options,
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/financialPeriods/open`,
      requestOptions,
    );
  }
}

export default FiscalPeriodsService;
