import { defineMessages } from 'react-intl';

import generalMessages from '../../App/messages';

const localMessages = defineMessages({
  appliedFilters: {
    id: 'App.ComplianceOverview.FilterGroupSection.AppliedFilters',
    defaultMessage: 'Applied Filters',
  },
  waitlistApplicant: {
    id: 'App.ComplianceOverview.FilterGroupSection.WaitlistApplicant',
    defaultMessage: 'Waitlist Applicants',
  },
  attentionRequiredAllOpenCertsOnly: {
    id: 'App.ComplianceOverview.FilterGroupSection.AttentionRequired',
    defaultMessage: 'Attention Required (All Open Certs Only)',
  },
  complianceDocumentType: {
    id: 'App.ComplianceOverview.FilterGroupSection.ComplianceDocumentType',
    defaultMessage: 'Compliance Document Type',
  },
  propertyId: {
    id: 'App.ComplianceOverview.FilterGroupSection.PropertyId',
    defaultMessage: 'Property', // This one's local because property ID could mean something else in general messages
  },
  hudSubmission: {
    id: 'App.ComplianceOverview.FilterGroupSection.HudSubmission',
    defaultMessage: 'HUD Submission',
  },
});

const messages = {
  ...generalMessages,
  ...localMessages,
};

export default messages;
