import * as actionTypes from './constants';
import type { PaymentBatch } from '../../services/paymentBatchService';
import type { PaymentSingleTransaction } from '../../services/paymentSingleTransactionService';
import type { Selection } from './types';

export const getUndepositedPaymentBatches = () => ({
  type: actionTypes.GET_UNDEPOSITED_PAYMENT_BATCHES
});

export const getUndepositedPaymentBatchesSuccess = (
  payload: PaymentBatch[]
) => ({
  type: actionTypes.GET_UNDEPOSITED_PAYMENT_BATCHES_SUCCESS,
  payload
});

export const getUndepositedPaymentBatchesFailure = (
  payload: Error | string
) => ({
  type: actionTypes.GET_UNDEPOSITED_PAYMENT_BATCHES_FAILURE,
  payload
});

export const getUndepositedPayments = () => ({
  type: actionTypes.GET_UNDEPOSITED_PAYMENTS
});

export const getUndepositedPaymentsSuccess = (
  payload: PaymentSingleTransaction[]
) => ({
  type: actionTypes.GET_UNDEPOSITED_PAYMENTS_SUCCESS,
  payload
});

export const getUndepositedPaymentsFailure = (payload: Error | string) => ({
  type: actionTypes.GET_UNDEPOSITED_PAYMENTS_FAILURE,
  payload
});

export const createDeposit = () => ({
  type: actionTypes.CREATE_DEPOSIT
});

export const createDepositSuccess = () => ({
  type: actionTypes.CREATE_DEPOSIT_SUCCESS
});

export const createDepositFailure = (payload: Error | string) => ({
  type: actionTypes.CREATE_DEPOSIT_FAILURE,
  payload
});

export const selectForDeposit = (payload: Selection) => ({
  type: actionTypes.SELECT_FOR_DEPOSIT,
  payload
});

export const unselectForDeposit = (payload: Selection) => ({
  type: actionTypes.UNSELECT_FOR_DEPOSIT,
  payload
});

export const clickViewPaymentBatch = (batchId: string) => ({
  type: actionTypes.CLICK_VIEW_PAYMENT_BATCH,
  payload: { batchId }
});

export const clearState = () => ({
  type: actionTypes.CLEAR_STATE
});

export const clearSelections = () => ({
  type: actionTypes.CLEAR_SELECTIONS
});
