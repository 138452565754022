import type { User, FilterValueAndPagination } from '../containers/App/types';
import type { Token, ResetPassword } from '../containers/ResetPassword/types';
import type { LoginPayload } from '../containers/Login/types';
import type { ForgotPasswordPayload } from '../containers/ForgotPassword/types';
import { path, pathOr } from 'ramda';
const { apiURL } = require('../utils/api');
const request = require('../utils/api').default;

export default class UserService {
  getUser(userId: string, organizationId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/${organizationId}/users/${userId}`, options);
  }

  getAllUsers(organizationId: string, query: ?FilterValueAndPagination) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    // $FlowFixMe
    const enablePagination = pathOr(
      true,
      ['paginationInfo', 'pagination'],
      query,
    );

    const pageNumber =
      path(['paginationInfo', 'pageNumber'], query) &&
      `page=${query.paginationInfo.pageNumber}`;

    const limit =
      path(['paginationInfo', 'limit'], query) &&
      `limit=${query.paginationInfo.limit}`;

    const sortName =
      path(['sorting', 'fieldName'], query) &&
      `sort=${query.sorting.fieldName}`;

    const sortOrder =
      path(['sorting', 'order'], query) && `order=${query.sorting.order}`;

    // $FlowFixMe
    const activeFilters = pathOr([], ['filterValue'], query);
    let filtersList = [];
    Object.keys(activeFilters).map((af) =>
      Object.keys(activeFilters[af]).map((av) =>
        activeFilters[af][av] === true
          ? (filtersList = [...filtersList, `${af}=${av}`])
          : false,
      ),
    );
    const filter = filtersList.join('&');

    const search = path(['searchText'], query) && `query=${query.searchText}`;

    const queryArgs = enablePagination
      ? [pageNumber, limit, filter, sortName, sortOrder, search]
      : ['pagination=false', filter, sortName, sortOrder, search];
    const queryString = queryArgs.filter((param) => param).join('&');

    return request(`${apiURL}/${organizationId}/users?${queryString}`, options);
  }

  getAllAssignees(organizationId: string, propertyId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/users/assignees/all`,
      options,
    ); // eslint-disable-line max-len
  }

  getProspectAssignees(organizationId: string, propertyId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/users/assignees/prospects`,
      options,
    ); // eslint-disable-line max-len
  }

  getWorkOrderAssignees(organizationId: string, propertyId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/users/assignees/workOrders`,
      options,
    ); // eslint-disable-line max-len
  }

  getAllUserStatuses() {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/userStatuses`, options);
  }

  getAllUserTitles(organizationId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/${organizationId}/userTitles`, options);
  }

  getAllUserRoles(organizationId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/${organizationId}/userRoles`, options);
  }

  getAllProperties(organizationId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/${organizationId}/allProperties`, options);
  }

  getAllActiveProperties(organizationId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/${organizationId}/allProperties/active`, options);
  }

  createUser(user: User) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    };
    return request(`${apiURL}/${user.organizationId}/users`, options);
  }

  editUser(user: User, userId: string, organizationId: string) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    };
    return request(`${apiURL}/${organizationId}/users/${userId}`, options);
  }

  verifyToken(data: Token) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    return request(`${apiURL}/users/token`, options);
  }

  resetPassword(data: ResetPassword) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    return request(`${apiURL}/users/resetPassword`, options);
  }

  login(data: LoginPayload) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    return request(`${apiURL}/login`, options);
  }

  sendResetPasswordToken(data: ForgotPasswordPayload) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    return request(`${apiURL}/users/sendResetPasswordToken`, options);
  }

  extendUserSession(token: Token) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/users/extendSession`, options);
  }
}
