import messages from './messages';
import moment from 'moment';
import { type } from 'ramda';

const validate = (values: Object, { intl, error }: Object) => {
  const errors = {};
  if (error && error.async) {
    errors._error = error;
  }
  const dateFrom = moment(values.dateFrom);

  if (!dateFrom.isValid() || type(values.dateFrom) === 'String') {
    errors.dateFrom = intl.formatMessage(messages.invalidDate);
  }

  return errors;
};

export default validate;
