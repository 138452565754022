const YES_VALUE = 'yes';
const YES_TEXT = 'Yes';
const NO_VALUE = 'no';
const NO_TEXT = 'No';
const DO_NOT_WISH_TO_FURNISH_VALUE = 'notWishToFurnish';
const DO_NOT_WISH_TO_FURNISH_TEXT = 'Do not wish to provide';
const DO_NOT_WISH_OPTION = {
  value: DO_NOT_WISH_TO_FURNISH_VALUE,
  text: DO_NOT_WISH_TO_FURNISH_TEXT,
  disabled: false,
};

export const genderOptions = [
  { value: 'male', text: 'Male', disabled: false },
  { value: 'female', text: 'Female', disabled: false },
  DO_NOT_WISH_OPTION,
];

export const veteranOptions = [
  { value: YES_VALUE, text: YES_TEXT, disabled: false },
  { value: NO_VALUE, text: NO_TEXT, disabled: false },
  DO_NOT_WISH_OPTION,
];

export const ethnicityOptions = [
  { value: 'hispanic', text: 'Hispanic/Latino', disabled: false },
  { value: 'notHispanic', text: 'Not Hispanic/Latino', disabled: false },
  DO_NOT_WISH_OPTION,
];

export const raceOptions = [
  { value: 'white', text: 'White', disabled: false },
  { value: 'blackAmerican', text: 'Black/African American', disabled: false },
  { value: 'hawaiian', text: 'Hawaiian/Pacific Islander', disabled: false },
  { value: 'asian', text: 'Asian', disabled: false },
  { value: 'indian', text: 'Indian/Alaskan Native', disabled: false },
  { value: 'asianIndian', text: 'Asian/India', disabled: false },
  { value: 'chinese', text: 'Chinese', disabled: false },
  { value: 'filipino', text: 'Filipino', disabled: false },
  { value: 'Japanese', text: 'Japanese', disabled: false },
  { value: 'korean', text: 'Korean', disabled: false },
  { value: 'vietnamese', text: 'Vietnamese', disabled: false },
  { value: 'otherAsian', text: 'Other Asian', disabled: false },
  { value: 'other', text: 'Other', disabled: false },
  DO_NOT_WISH_OPTION,
];

export const studentsTypes = [
  { value: 'f_tK_12', text: 'Full-Time K-12', disabled: false },
  { value: 'f_tPost12', text: 'Full-Time Post 12', disabled: false },
  { value: 'p_t', text: 'Part-Time', disabled: false },
];

export const additionalStudentTypes = [
  { value: 'n_s', text: 'Not a Student', disabled: false },
];

export const maritalStatuses = [
  { text: 'Single', value: 'single', disabled: false },
  { text: 'Married', value: 'married', disabled: false },
  { text: 'Divorced', value: 'divorced', disabled: false },
  { text: 'Separated', value: 'separated', disabled: false },
  { text: 'Widowed', value: 'widowed', disabled: false },
];
