import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import { clone } from 'ramda';
import { connect } from 'react-redux';
import FilterDrawer from '../../components/FilterDrawer';
import {
  INITIAL_RECERT_FILTER_SELECTIONS,
  RECERT_FILTER_OPTIONS,
  CERTIFICATION_TYPES,
  getCertificationsDefaultFilterOptions,
  getCertificationsInitialFilterOptions,
  OTHER_HUD,
  OTHER_LIHTC,
} from './constants';
import { useFetchComplianceDocumentGroups } from './hooks';

type CertificationFilterDrawerProps = {
  certificationType: ?string,
  handleApplyFilter: Function,
  handleFilterClick: Function,
  intl: Object,
  organizationId: string,
  propertyId: string,
  show: boolean,
  complianceApprovalStatuses: Array<Object>,
  propertyAffordablePrograms: Array<Object>,
};

const CertificationFilterDrawer = ({
  certificationType,
  handleApplyFilter,
  handleFilterClick,
  intl,
  organizationId,
  propertyId,
  show,
  complianceApprovalStatuses,
  propertyAffordablePrograms,
  displayAll,
  setShow,
}: CertificationFilterDrawerProps) => {
  const [currentFilter, setCurrentFilter] = useState({});
  const [filterOptions, setFilterOptions] = useState([]);

  const complianceDocumentFilterGroups = useFetchComplianceDocumentGroups({
    organizationId,
    propertyId,
  });

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (certificationType === CERTIFICATION_TYPES.RECERT && isMounted.current) {
      const initialRecertFilterSelections = clone(
        INITIAL_RECERT_FILTER_SELECTIONS,
      );

      setCurrentFilter(initialRecertFilterSelections);
      handleApplyFilter(initialRecertFilterSelections);

      setFilterOptions(RECERT_FILTER_OPTIONS(complianceDocumentFilterGroups));
    }

    if (
      certificationType === CERTIFICATION_TYPES.MOVE_IN &&
      isMounted.current
    ) {
      const initialMoveInFilterSelections = clone(
        getCertificationsInitialFilterOptions(CERTIFICATION_TYPES.MOVE_IN),
      );
      setCurrentFilter(initialMoveInFilterSelections);
      handleApplyFilter(initialMoveInFilterSelections);

      setFilterOptions(
        getCertificationsDefaultFilterOptions(
          CERTIFICATION_TYPES.MOVE_IN,
          complianceDocumentFilterGroups,
          complianceApprovalStatuses,
          propertyAffordablePrograms,
          intl,
        ),
      );
    }

    if (
      certificationType === CERTIFICATION_TYPES.INITIAL &&
      isMounted.current
    ) {
      const initialInitialCertsFilterSelections = clone(
        getCertificationsInitialFilterOptions(CERTIFICATION_TYPES.INITIAL),
      );
      setCurrentFilter(initialInitialCertsFilterSelections);
      handleApplyFilter(initialInitialCertsFilterSelections);

      setFilterOptions(
        getCertificationsDefaultFilterOptions(
          CERTIFICATION_TYPES.INITIAL,
          complianceDocumentFilterGroups,
          complianceApprovalStatuses,
          propertyAffordablePrograms,
          intl,
        ),
      );
    }

    if (certificationType === OTHER_HUD && isMounted.current) {
      const initialInitialCertsFilterSelections = clone(
        getCertificationsInitialFilterOptions(OTHER_HUD),
      );
      setCurrentFilter(initialInitialCertsFilterSelections);
      handleApplyFilter(initialInitialCertsFilterSelections);

      setFilterOptions(
        getCertificationsDefaultFilterOptions(
          OTHER_HUD,
          complianceDocumentFilterGroups,
          complianceApprovalStatuses,
          propertyAffordablePrograms,
          intl,
        ),
      );
    }

    if (certificationType === OTHER_LIHTC && isMounted.current) {
      const initialInitialCertsFilterSelections = clone(
        getCertificationsInitialFilterOptions(OTHER_LIHTC),
      );
      setCurrentFilter(initialInitialCertsFilterSelections);
      handleApplyFilter(initialInitialCertsFilterSelections);

      setFilterOptions(
        getCertificationsDefaultFilterOptions(
          OTHER_LIHTC,
          complianceDocumentFilterGroups,
          complianceApprovalStatuses,
          propertyAffordablePrograms,
          intl,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    certificationType,
    complianceDocumentFilterGroups,
    complianceApprovalStatuses,
    handleApplyFilter,
    propertyAffordablePrograms,
  ]);

  const onApplyFiltersClick = () => {
    handleFilterClick();
    handleApplyFilter(currentFilter);
  };

  const onFilterChange =
    (field: any) =>
    (value: string) =>
    ({ target: { checked } }: any) => {
      const currentFilterField = currentFilter[field];
      currentFilterField[value] = checked;
      const newFilter = {
        ...currentFilter,
        [certificationType]: currentFilterField,
      };
      setCurrentFilter(newFilter);
    };

  const clearFilters = () => {
    if (certificationType === CERTIFICATION_TYPES.RECERT) {
      const defaultFilter = clone(INITIAL_RECERT_FILTER_SELECTIONS);
      setCurrentFilter(defaultFilter);
    }

    if (
      [
        CERTIFICATION_TYPES.MOVE_IN,
        CERTIFICATION_TYPES.INITIAL,
        OTHER_HUD,
        OTHER_LIHTC,
      ].includes(certificationType)
    ) {
      const defaultFilter = clone(
        getCertificationsInitialFilterOptions(certificationType),
      );
      setCurrentFilter(defaultFilter);
    }
  };

  return (
    <FilterDrawer
      show={show}
      onCloseClick={handleFilterClick}
      formatMessage={intl.formatMessage}
      currentFilter={currentFilter}
      filters={filterOptions}
      onFilterChange={onFilterChange}
      onApplyClick={onApplyFiltersClick}
      clearFilters={clearFilters}
    />
  );
};

export const mapStateToProps = ({
  app: {
    currentUser,
    selectedProperty: { id: propertyId },
  },
}: any): any => {
  return {
    organizationId: currentUser ? currentUser.user.organizationId : '',
    propertyId,
  };
};

export default connect(mapStateToProps)(injectIntl(CertificationFilterDrawer));
