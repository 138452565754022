import React from 'react';
import { Modal, Row, Col, ButtonToolbar, Button } from 'react-bootstrap';
import messages from './messages';
import { FormattedMessage } from 'react-intl';

type Props = {
  onHide: Function,
  onSubmit: Function,
};

const ConfirmEstimatesModal = (props: Props) => {
  const { onHide, onSubmit } = props;

  return (
    <div className="static-modal">
      <Modal
        bsSize="small"
        dialogClassName="modal-dialog modal-alert"
        show={true}
        onHide={onHide}
        autoFocus={true}
        handleSubmit={onSubmit}
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <h1 className="text-center">
                <FormattedMessage
                  {...messages.confirmEstimatesDialogMessageTitle}
                />
              </h1>

              <h2 className="text-center">
                <FormattedMessage
                  {...messages.confirmEstimatesDialogMessageLine1}
                />
              </h2>
              <p className="text-center">
                <FormattedMessage
                  {...messages.confirmEstimatesDialogMessageLine2}
                />
                <br />
                <FormattedMessage
                  {...messages.confirmEstimatesDialogMessageLine3}
                />
              </p>
              <h2 className="text-center">
                <FormattedMessage
                  {...messages.confirmEstimatesDialogMessageLine4}
                />
              </h2>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button type="button" bsStyle="default" onClick={onHide}>
              <FormattedMessage {...messages.no} />
            </Button>
            <Button
              type="submit"
              bsStyle="primary"
              className="pull-left"
              onClick={() => onSubmit()}
            >
              <FormattedMessage {...messages.yes} />
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmEstimatesModal;
