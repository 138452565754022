import { useMemo } from 'react';
import { isDocumentOrRecertPastDue } from './utilsV2';

/**
 * Will do the summation of past due certifications per property
 * ---
 * @param {*} param0
 * @notes
 * - if a propertyId isn't in pastDueCertsPerPropertyList means there are not open certs with
 * compliance documents for that property
 * - the pastDueCertifications column is added to the current portfolio response
 * on src/containers/Portfolio/hooksV2.js -> useParsePortfolios()
 * @reasons check https://fortress-technology.atlassian.net/browse/ENG-4086 for reference
 * @returns the past DueCertifications Per Property List
 */
const useGetPastDueCertsPerProperty = ({
  compalianceOverviewResults,
  complianceType,
  complianceOverviewReviewedAndNumberOfDays,
  pastDueColumn,
}) => {
  return useMemo(() => {
    const pastDueCertsPerPropertyList = pastDueColumn
      ? compalianceOverviewResults.reduce(
          (pastDueCertsPerPropertySumList, document) => {
            const {
              certificationType,
              complianceApprovalStatus,
              complianceDocumentType,
              uploadDate,
              voucherEffectiveDate,
              propertyId,
            } = document;
            const currentPropertyId = propertyId;
            let isPastDueCert = '';
            if (
              document.hasOwnProperty('voucherEffectiveDate') &&
              document.hasOwnProperty('certificationType')
            ) {
              isPastDueCert = isDocumentOrRecertPastDue({
                certificationType,
                complianceApprovalStatus,
                complianceDocumentType,
                complianceOverviewReviewedAndNumberOfDays,
                complianceType,
                uploadDate,
                voucherEffectiveDate,
              });
            }
            pastDueCertsPerPropertySumList[currentPropertyId] =
              isPastDueCert === ''
                ? 0
                : (pastDueCertsPerPropertySumList[currentPropertyId] || 0) +
                  (isPastDueCert ? 1 : 0);
            return pastDueCertsPerPropertySumList;
          },
          {},
        )
      : {};

    return pastDueCertsPerPropertyList;
  }, [
    complianceOverviewReviewedAndNumberOfDays,
    complianceType,
    compalianceOverviewResults,
    pastDueColumn,
  ]);
};

export default useGetPastDueCertsPerProperty;
