import * as ActionTypes from './constants';

import type { Application } from '../CreateApplication/types';
import type { Action } from '../App/types';
import type { ApplicationStatus, DecisionStatus } from './types';

export function getOneApplication(applicationId: string): Action<string> {
  return {
    type: ActionTypes.GET_ONE_APPLICATION,
    payload: applicationId,
  };
}

export function getOneApplicationSuccess(
  application: Application,
): Action<Application> {
  return {
    type: ActionTypes.GET_ONE_APPLICATION_SUCCESS,
    payload: application,
  };
}

export function getOneApplicationError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ONE_APPLICATION_ERROR,
    payload: error,
  };
}

export function assignApplication(
  application: Application,
  onAssignationComplete?: Function,
): Action<Application> {
  return {
    type: ActionTypes.ASSIGN_APPLICATION,
    payload: application,
    onAssignationComplete,
  };
}

export function updateApplication(
  application: Application,
  toasterMessage?: string,
  residentId?: string,
  refreshActivityTable?: Function,
): Action<Application> {
  return {
    type: ActionTypes.UPDATE_APPLICATION,
    payload: application,
    toasterMessage,
    residentId,
    refreshActivityTable,
  };
}

export function updateApplicationSuccess(): Action<any> {
  return {
    type: ActionTypes.UPDATE_APPLICATION_SUCCESS,
    payload: undefined,
  };
}

export function updateApplicationError(error: Error): Action<Error> {
  return {
    type: ActionTypes.UPDATE_APPLICATION_ERROR,
    payload: error,
  };
}

export function updatePropertyClass(
  application: Application,
  toasterMessage?: string,
): Action<Application> {
  return {
    type: ActionTypes.UPDATE_PROPERTY_CLASS,
    payload: application,
    toasterMessage,
  };
}

export function updatePropertyClassSuccess(): Action<any> {
  return {
    type: ActionTypes.UPDATE_PROPERTY_CLASS_SUCCESS,
    payload: undefined,
  };
}

export function updatePropertyClassError(error: Error): Action<Error> {
  return {
    type: ActionTypes.UPDATE_PROPERTY_CLASS_ERROR,
    payload: error,
  };
}

export function getAllApplicationDecisionStatus(): Action<any> {
  return {
    type: ActionTypes.GET_APPLICATION_DECISION_STATUS,
    payload: undefined,
  };
}

export function getAllApplicationDecisionStatusSuccess(
  decisionStatus: Array<DecisionStatus>,
): Action<Array<DecisionStatus>> {
  return {
    type: ActionTypes.GET_APPLICATION_DECISION_STATUS_SUCCESS,
    payload: decisionStatus,
  };
}

export function getAllApplicationDecisionStatusError(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.GET_APPLICATION_DECISION_STATUS_ERROR,
    payload: error,
  };
}

export function getAllApplicationStatus(): Action<any> {
  return {
    type: ActionTypes.GET_APPLICATION_STATUS,
    payload: undefined,
  };
}

export function getAllApplicationStatusSuccess(
  applicationStatus: Array<ApplicationStatus>,
): Action<Array<ApplicationStatus>> {
  return {
    type: ActionTypes.GET_APPLICATION_STATUS_SUCCESS,
    payload: applicationStatus,
  };
}

export function getAllApplicationStatusError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_APPLICATION_STATUS_ERROR,
    payload: error,
  };
}

export function updateApplicantChecklist(
  checklistChanges: Object,
): Action<any> {
  return {
    type: ActionTypes.UPDATE_APPLICANT_CHECKLIST,
    payload: checklistChanges,
  };
}

export function updateApplicantChecklistSuccess(): Action<any> {
  return {
    type: ActionTypes.UPDATE_APPLICANT_CHECKLIST_SUCCESS,
    payload: undefined,
  };
}

export function updateApplicantChecklistError(error: Error): Action<Error> {
  return {
    type: ActionTypes.UPDATE_APPLICANT_CHECKLIST_ERROR,
    payload: error,
  };
}

export function getScreeningStatus(applicationId: string): Action<any> {
  return {
    type: ActionTypes.GET_SCREENING_STATUS,
    payload: { applicationId },
  };
}

export function getScreeningStatusSuccess(results: Object): Action<any> {
  return {
    type: ActionTypes.GET_SCREENING_STATUS_SUCCESS,
    payload: results,
  };
}

export function getScreeningStatusError(
  message: string,
  error: any,
): Action<any> {
  return {
    type: ActionTypes.GET_SCREENING_STATUS_ERROR,
    payload: {
      error,
      message,
    },
  };
}

export function submitScreeningApplication(
  applicationId: string,
  monthlyRentAmount: number,
  refreshActivityTable?: Function,
): Action<any> {
  return {
    type: ActionTypes.SUBMIT_SCREENING_APPLICATION,
    payload: {
      applicationId,
      monthlyRentAmount,
      refreshActivityTable,
    },
  };
}

export function submitScreeningApplicationSuccess(
  results: Object,
): Action<any> {
  return {
    type: ActionTypes.SUBMIT_SCREENING_APPLICATION_SUCCESS,
    payload: results,
  };
}

export function submitScreeningApplicationError(error: Object): Action<any> {
  return {
    type: ActionTypes.SUBMIT_SCREENING_APPLICATION_ERROR,
    payload: error,
  };
}

export function saveScreeningResults(results: Object): Action<any> {
  return {
    type: ActionTypes.SUBMIT_SCREENING_RESULTS,
    payload: results,
  };
}

export function generateAdverseActionLetterSuccess(): Action<any> {
  return {
    type: ActionTypes.SUBMIT_GENERATE_ADVERSE_ACTION_LETTER_SUCCESS,
    payload: {},
  };
}

export function generateAdverseActionLetterError(error: Object): Action<any> {
  return {
    type: ActionTypes.SUBMIT_GENERATE_ADVERSE_ACTION_LETTER_ERROR,
    payload: error,
  };
}

export function generateAdverseActionLetter(options: Object): Action<any> {
  return {
    type: ActionTypes.SUBMIT_GENERATE_ADVERSE_ACTION_LETTER,
    payload: options,
  };
}

export function cleanLoadedApplication(): Action<any> {
  return {
    type: ActionTypes.CLEAN_LOADED_APPLICATION,
    payload: undefined,
  };
}

export function saveLeaseDataTab(
  lease: Object,
  applicationId: string,
  refreshActivityTable?: Function,
  hasMoveInDateChanged?: boolean,
): Action<any> {
  return {
    type: ActionTypes.SAVE_LEASE_DATA_TAB,
    payload: {
      applicationId,
      lease,
      refreshActivityTable,
      hasMoveInDateChanged,
    },
  };
}

export function getAssignedUnits(applicationId: string) {
  return {
    type: ActionTypes.GET_ASSIGNED_UNITS,
    payload: applicationId,
  };
}

export function getAssignedUnitsSuccess(payload: Object): Action<Object> {
  return {
    type: ActionTypes.GET_ASSIGNED_UNITS_SUCCESS,
    payload,
  };
}

export function getAssignedUnitsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ASSIGNED_UNITS_ERROR,
    payload: error,
  };
}

export function unassignUnit(
  assignedUnitId: string,
  applicationId: string,
  refreshActivityTable?: Function,
) {
  return {
    type: ActionTypes.UNASSIGN_UNIT,
    payload: {
      assignedUnitId,
      applicationId,
      refreshActivityTable,
    },
  };
}

export function unassignUnitSuccess(payload: Object): Action<Object> {
  return {
    type: ActionTypes.UNASSIGN_UNIT_SUCCESS,
    payload,
  };
}

export function unassignUnitError(error: Error): Action<Error> {
  return {
    type: ActionTypes.UNASSIGN_UNIT_ERROR,
    payload: error,
  };
}

export function updateLabelEndDate(label: string): Action<any> {
  return {
    type: ActionTypes.UPDATE_LABEL_END_DATE,
    payload: label,
  };
}

/**
 * TODO: Remove Cb when able (When saving takes place in Lease Data Tab)
 */
export function saveMonthlyCharge(
  applicationId: string,
  transaction: Object,
  leaseData: Object,
  updateTransactionsCb: Function,
): Action<any> {
  return {
    type: ActionTypes.SAVE_MONTHLY_CHARGE,
    payload: {
      applicationId,
      transaction,
      leaseData,
      updateTransactionsCb,
    },
  };
}

export function deleteMonthlyCharge(
  transaction: Object,
  applicationId: string,
): Action<any> {
  return {
    type: ActionTypes.DELETE_MONTHLY_CHARGE,
    payload: {
      transaction,
      applicationId,
    },
  };
}

export function deleteMonthlyChargeSuccess(applicationId: string): Action<any> {
  return {
    type: ActionTypes.DELETE_MONTHLY_CHARGE_SUCCESS,
    payload: applicationId,
  };
}

export function deleteMonthlyChargeError(applicationId: string): Action<any> {
  return {
    type: ActionTypes.DELETE_MONTHLY_CHARGE_ERROR,
    payload: applicationId,
  };
}

export function saveMonthlyChargeSuccess(applicationId: string): Action<any> {
  return {
    type: ActionTypes.SAVE_MONTHLY_CHARGE_SUCCESS,
    payload: applicationId,
  };
}

export function getMonthlyTransactions(applicationId: string): Action<any> {
  return {
    type: ActionTypes.GET_MONTHLY_TRANSACTIONS,
    payload: applicationId,
  };
}

export function getMonthlyTransactionsSuccess(payload: any): Action<any> {
  return {
    type: ActionTypes.GET_MONTHLY_TRANSACTIONS_SUCCESS,
    payload,
  };
}

export function getMonthlyTransactionsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_MONTHLY_TRANSACTIONS_ERROR,
    payload: error,
  };
}

export function setMonthlyOption(option: string): Action<any> {
  return {
    type: ActionTypes.SET_MONTHLY_OPTION,
    payload: option,
  };
}

export function saveLeaseDataTabSuccess(applicationId: string): Action<any> {
  return {
    type: ActionTypes.SAVE_LEASE_SUCCESS,
    payload: applicationId,
  };
}

export function getUnitQuote(payload: Object): Action<Object> {
  return {
    type: ActionTypes.GET_UNIT_QUOTE,
    payload,
  };
}

export function generateLeaseDocument(payload: Object): Action<Object> {
  return {
    type: ActionTypes.GENERATE_LEASE_DOCUMENT,
    payload,
  };
}

export function generateLeaseDocumentSuccess(payload: Object): Action<any> {
  return {
    type: ActionTypes.GENERATE_LEASE_DOCUMENT_SUCCESS,
    payload,
  };
}

export function generateLeaseDocumentError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GENERATE_LEASE_DOCUMENT_ERROR,
    payload: error,
  };
}

export function resendPayLeaseEmail(customerId: string): Action<any> {
  return {
    type: ActionTypes.RESEND_PAYLEASE_EMAIL,
    payload: customerId,
  };
}

export function sendApplicantPortalInviteEmail(
  customerId: string,
  applicationId: string,
  leaseId?: string,
  applicantType: string,
): Action<any> {
  return {
    type: ActionTypes.SEND_APPLICANT_PORTAL_INVITE_EMAIL,
    payload: {
      customerId,
      applicationId,
      leaseId,
      applicantType,
    },
  };
}

export function updateCommercialApplication(
  application: Object,
  toasterMessage?: string,
): Action<Application> {
  return {
    type: ActionTypes.UPDATE_COMMERCIAL_APPLICATION,
    payload: application,
    toasterMessage,
  };
}

export function updateCommercialApplicationSuccess(
  application: Application,
): Action<any> {
  return {
    type: ActionTypes.UPDATE_COMMERCIAL_APPLICATION_SUCCESS,
    payload: application,
  };
}

export function updateCommercialApplicationError(error: Error): Action<Error> {
  return {
    type: ActionTypes.UPDATE_COMMERCIAL_APPLICATION_ERROR,
    payload: error,
  };
}

export function updateApplicant(
  applicant: Object,
  toasterMessage?: string,
): Action<any> {
  return {
    type: ActionTypes.UPDATE_APPLICANT,
    payload: applicant,
    toasterMessage,
  };
}

export function updateApplicantSuccess(
  applicantId: string,
  updates: any,
): Action<any> {
  return {
    type: ActionTypes.UPDATE_APPLICANT_SUCCESS,
    payload: {
      applicantId,
      updates,
    },
  };
}

export function updateApplicantError(error: Error): Action<Error> {
  return {
    type: ActionTypes.UPDATE_APPLICANT_ERROR,
    payload: error,
  };
}

/**
 * Global Socket Actions
 */
export function handleSocketArsSuccessEvent(payload: Object): Action<any> {
  return {
    type: ActionTypes.SOCKET_EVENT_ARS_SUCCESS,
    payload,
  };
}

export function handleSocketArsErrorEvent(payload: Object): Action<any> {
  return {
    type: ActionTypes.SOCKET_EVENT_ARS_ERROR,
    payload,
  };
}
