import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageRoles.Title',
    defaultMessage: 'Fortress - Manage roles',
  },
  header: {
    id: 'App.ManageRoles.Header',
    defaultMessage: 'Manage Roles',
  },
  roleName: {
    id: 'App.ManageRoles.RoleName',
    defaultMessage: 'Role Name',
  },
  description: {
    id: 'App.ManageRoles.Description',
    defaultMessage: 'Description',
  },
  roleType: {
    id: 'App.ManageRoles.RoleType',
    defaultMessage: 'Role Type',
  },
  assignedUsers: {
    id: 'App.ManageRoles.AssignedUsers',
    defaultMessage: 'Assigned Users',
  },
  view: {
    id: 'App.ManageRoles.View',
    defaultMessage: 'View',
  },
  addRole: {
    id: 'App.ManageRoles.AddRole',
    defaultMessage: 'Add New Role',
  },
  sortAscendingLetters: {
    id: 'App.ManageRoles.SortAscendingLetters',
    defaultMessage: 'A - Z',
  },
  sortDescendingLetters: {
    id: 'App.ManageRoles.SortDescendingLetters',
    defaultMessage: 'Z - A',
  },
  configurePropertyRoleAssignments: {
    id: 'App.ManageRoles.ConfigurePropertyRoleAssignments',
    defaultMessage: 'Configure Property Role Assignments',
  },
  propertyRoleCreated: {
    id: 'App.ManageRoles.PropertyRoleCreated',
    defaultMessage: 'Property roles created successfully',
  },
  failedToCreateRoles: {
    id: 'App.ManageRoles.FailedToCreateRoles',
    defaultMessage: 'Failed to create roles',
  },
  propertyRoleUpdated: {
    id: 'App.ManageRoles.PropertyRoleUpdated',
    defaultMessage: 'Property roles updated successfully',
  },
  failedToUpdateRoles: {
    id: 'App.ManageRoles.FailedToUpdateRoles',
    defaultMessage: 'Failed to update roles',
  },
});

export default messages;
