import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import moment from 'moment';
import { PageContext } from '../context';
import messages from '../messages';
import Table from '../../../components/Table';
import { HEADER_ROWS } from './constants';
import Row from '../../../components/Table/Row';
import Data from '../../../components/Table/Data';
import IconLink from '../../../components/IconLink';
import Spacer from '../../../components/Spacer';
import LimitInput from './LimitInput';
import LastUpdatedBlock from './LastUpdatedBlock';
import { useLimitData } from './hooks';
import Spinner from '../../../components/Spinner';
import { blue } from '@fortress-technology-solutions/fortress-component-library/design';

const Wrapper = styled.div`
  & table {
    max-width: 100%;
    display: block;
    overflow-x: auto;
    border: 1px solid #d1d1d1;
  }
  & table > thead > tr > th {
    border-top: none;
  }
`;

type LimitsData = {
  leaseCount: number,
  limit?: number,
};

type Props = {
  year: string | number,
};

const HeaderCell = ({ children }) => (
  <Data style={{ backgroundColor: '#EBF5FF' }}>{children}</Data>
);

const calculateLeaseCount = (limit: number, unitCount: number) =>
  Math.ceil((limit * unitCount) / 100);
const calculateLimit = (leaseCount: number, unitCount: number) =>
  (leaseCount / unitCount) * 100;

function LimitDataByYearTable({ year }: Props) {
  const { formatMessage, canEdit } = useContext(PageContext);
  const {
    limitData,
    unitCount,
    editLimit,
    isLoading,
    isDirty,
    isSaving,
    saveChanges,
    clearChanges,
    lastUpdated,
  } = useLimitData(year);

  const tableId = useMemo(() => {
    const currentYear = moment().year();

    return year % currentYear;
  }, [year]);

  const renderMonthLimitsData = () => {
    const currentYear = moment().year();
    const currentMonth = moment().month();
    return R.flatten(
      limitData.map((ld: LimitsData, monthNum: number) => {
        const isLimitEditable =
          canEdit && (Number(year) > currentYear || monthNum >= currentMonth);
        const limit = ld.limit || 0;
        const handleChange = (value: number) => {
          editLimit(monthNum, value);
        };

        return [
          <Data key={`${monthNum}-0`}>
            {isLimitEditable ? (
              <LimitInput
                value={ld.limit}
                onChange={handleChange}
                disabled={isSaving}
              />
            ) : (
              `${limit.toFixed(1)}%`
            )}
          </Data>,
          <Data key={`${monthNum}-1`}>
            {typeof ld.limit === 'number'
              ? calculateLeaseCount(ld.limit, unitCount)
              : '---'}
          </Data>,
        ];
      }),
    );
  };

  const renderTotalsLimitsData = () => {
    const totals = limitData.reduce(
      (acc, cur) => {
        const { limitSum, leaseSum } = acc;
        const curLimit = cur.limit ?? 0;
        const curLeaseCount = calculateLeaseCount(curLimit, unitCount);

        return {
          limitSum: limitSum + curLimit,
          leaseSum: leaseSum + curLeaseCount,
        };
      },
      { limitSum: 0, leaseSum: 0 },
    );
    const variance = totals.leaseSum - unitCount;

    return [
      <Data key={0}>{totals.limitSum.toFixed(1)}%</Data>,
      <Data key={1}>{totals.leaseSum}</Data>,
      <Data key={2}>{variance}</Data>,
    ];
  };

  const getTextColorForActualsData = (limit: number, actualLimit: number) => {
    if (limit.toFixed(1) === actualLimit.toFixed(1)) return blue.dark;
    if (limit < actualLimit) return '#D70000';
    return '#4CBB17';
  };

  const renderMonthActualsData = () => {
    return R.flatten(
      limitData.map((ld: LimitsData, monthNum: number) => {
        const actualLimit = calculateLimit(ld.leaseCount, unitCount);
        const style = R.isNil(ld.limit)
          ? {}
          : {
              color: getTextColorForActualsData(ld.limit, actualLimit),
            };

        return [
          <Data key={`${monthNum}-0`} style={style}>
            {actualLimit.toFixed(1)}%
          </Data>,
          <Data key={`${monthNum}-1`} style={style}>
            {ld.leaseCount}
          </Data>,
        ];
      }),
    );
  };

  const renderTotalsActualsData = () => {
    const totals = limitData.reduce(
      (acc, cur) => {
        const { limitSum, leaseSum } = acc;
        const curLeaseCount = cur.leaseCount;
        const curLimit = calculateLimit(curLeaseCount, unitCount);

        return {
          limitSum: limitSum + curLimit,
          leaseSum: leaseSum + curLeaseCount,
        };
      },
      { limitSum: 0, leaseSum: 0 },
    );
    const variance = totals.leaseSum - unitCount;

    return [
      <Data key={0}>{totals.limitSum.toFixed(1)}%</Data>,
      <Data key={1}>{totals.leaseSum}</Data>,
      <Data key={2}>{variance}</Data>,
    ];
  };

  return (
    <Wrapper id={`limits-table-${tableId}`}>
      <h4>
        {formatMessage(messages.limitsFor)} {year}
      </h4>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Table headerRows={HEADER_ROWS}>
            <Row className="table-row">
              <HeaderCell>{formatMessage(messages.limits)}</HeaderCell>
              {renderMonthLimitsData()}
              {renderTotalsLimitsData()}
            </Row>
            <Row className="table-row">
              <HeaderCell>{formatMessage(messages.actuals)}</HeaderCell>
              {renderMonthActualsData()}
              {renderTotalsActualsData()}
            </Row>
          </Table>
          <Spacer v={5} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {lastUpdated.by && lastUpdated.on && (
              <LastUpdatedBlock by={lastUpdated.by} on={lastUpdated.on} />
            )}
            {canEdit && (
              <div style={{ display: 'flex' }}>
                <IconLink
                  text={formatMessage(messages.saveChangesBtn)}
                  iconClass="et-isolated-check"
                  color="#4CBB17"
                  iconFontSize={20}
                  disabled={!isDirty || isSaving}
                  onClick={saveChanges}
                />
                <Spacer h={14} />
                <IconLink
                  text={formatMessage(messages.cancelChangesBtn)}
                  iconClass="et-delete"
                  color="#D70000"
                  iconFontSize={12}
                  disabled={!isDirty || isSaving}
                  onClick={clearChanges}
                />
              </div>
            )}
          </div>
        </>
      )}
    </Wrapper>
  );
}

export default LimitDataByYearTable;
