import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useManagePriorResidents } from '../hooksV2';
import messages from '../messages';
import useBaseTableProps from '../../../hooks/useBaseTableProps';
import TableLayout from '../../../components/TableLayout';

const PriorResidentsTable = ({
  intl,
  selectedProperty,
  propertyId,
  organizationId,
  userId,
  priorResidents,
  isSuccess,
  isAllCommercial,
  onPageChange,
  currentPage,
  perPage,
  locale,
}) => {
  const baseTableProps = useBaseTableProps();
  const {
    filterState,
    filterTypeState,
    count,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    name,
    searchState,
    allColumnsHidden,
    columnOptions,
    selectedColumns,
    handleColumnChange,
    filteredHeaders,
    rows,
    isLoading,
    totalCount,
    handleCSVButtonClick,
  } = useManagePriorResidents({
    intl,
    isAllCommercial,
    selectedProperty,
    propertyId,
    organizationId,
    userId,
    locale,
    onPageChange,
    currentPage,
    perPage,
  });
  return (
    <TableLayout
      name={name}
      childrenElementsHeight={56 + 8}
      sx={{
        'button[disabled]': { backgroundColor: 'unset' },
      }}
    >
      {(height) => (
        <>
          <TableHeader
            title={intl.formatMessage(messages.title)}
            totalCount={totalCount}
            count={count}
          />
          <Table
            {...baseTableProps}
            allColumnsHidden={allColumnsHidden}
            columnOptions={columnOptions}
            count={count}
            dateState={dateState}
            filterState={filterState}
            filterTypeState={filterTypeState}
            handleColumnChange={handleColumnChange}
            handleDateSubmit={handleDateSubmit}
            handleFilterChange={handleFilterChange}
            handleFilterTypeChange={handleFilterTypeChange}
            handleSearchSubmit={handleSearchSubmit}
            handleSortChange={handleSortChange}
            headers={filteredHeaders}
            height={height}
            intl={intl}
            isAllCommercial={isAllCommercial}
            isLoading={isLoading}
            name={name}
            onCSVButtonClick={handleCSVButtonClick}
            order={order}
            orderBy={orderBy}
            rows={rows}
            searchState={searchState}
            selectedColumns={selectedColumns}
            totalCount={totalCount}
          />
        </>
      )}
    </TableLayout>
  );
};

export default PriorResidentsTable;
