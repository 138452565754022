import React from 'react';
import messages from '../ReportsTable/messages';
import type { Report } from '../types';
import FormattedDateOrDashes from '../../../components/FormattedDateOrDashes';
import cn from 'classnames';

type ReportsDetailsProp = {
  intl: any,
  reports: Array<Report>,
  periods: Array<any>,
  history: any,
  onCreateReport: Function,
  onViewHistory: Function,
  downloadLastReport: Function,
};

type ReportsDetailRowProps = {
  props: ReportsDetailsProp,
  report: Report,
};

export const ReportsDetails = (props: ReportsDetailsProp) => {
  const { reports } = props;

  return (
    <tbody>
      {reports.map((report: Report) => (
        <ReportDetailRow key={report.id} props={props} report={report} />
      ))}
    </tbody>
  );
};

export const ReportDetailRow = (data: ReportsDetailRowProps) => {
  const { props, report } = data;
  const { intl, periods, onCreateReport, onViewHistory, downloadLastReport } =
    props;

  const id = report.id || '';
  const name = report?.name ?? '---';
  const type = report?.type ?? '---';
  const lastCreated = report?.lastCreated ?? '';
  const username = report?.lastUser?.username ?? '---';
  const reports = report?.reports ?? [];
  const className = 'table-section hidden-xs';

  return (
    <tr key={`${id}-RW`} className={className}>
      <td id="report-name">
        <span className="row-detail">
          {intl.formatMessage(messages.reportName)}:
        </span>
        {name}
      </td>
      <td id="report-type">
        <span className="row-detail">
          {intl.formatMessage(messages.reportType)}:
        </span>
        {type}
      </td>
      <td id="report-username">
        <span className="row-detail">
          {intl.formatMessage(messages.reportLastCreated)}:
        </span>
        {report.filename === null ? (
          '---'
        ) : (
          <a id="download-last-report-btn" onClick={downloadLastReport(report)}>
            <FormattedDateOrDashes value={lastCreated} format="YYYY.MM.DD" />-
            {username}
          </a>
        )}
      </td>
      <td>
        <button
          id="on-create-report-btn"
          className={cn('btn btn-text btn-text--small text-right')}
          onClick={onCreateReport(report, periods)}
        >
          Create
          <i className="icon et-pencil" />
        </button>
      </td>
      <td>
        <button
          id="on-view-history-btn"
          className={cn('btn btn-text btn-text--small text-right')}
          onClick={onViewHistory(id, reports)}
        >
          View History
        </button>
      </td>
    </tr>
  );
};

export default ReportsDetails;
