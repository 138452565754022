import React from 'react';
import moment from 'moment';
import { reduxForm, Field } from 'redux-form';
import {
  renderNumberField,
  renderDateField,
} from '../../../../utils/redux-form-helper';

import validate from './validate';

const UnitSqFtInformationForm = ({
  toggleFormCallback,
  histories,
  initialValues,
  handleSaveHistory,
  handleSetEditing,
  handleSubmit,
  latestStartDate,
}) => {
  const isValidDate = (currentDate: Object) => {
    if (latestStartDate) {
      return moment(currentDate).isAfter(moment(latestStartDate));
    }
    return moment(currentDate).isSameOrAfter(moment().startOf('day'));
  };

  return (
    <div className="row row-active">
      <div className="padtop5">
        <form onSubmit={handleSubmit(handleSaveHistory)}>
          <div className="col-xs-offset-2 col-xs-3">
            <Field
              name="squareFeet"
              component={renderNumberField}
              bsSize="lg"
            />
          </div>
          <div className="col-xs-3">
            <Field
              name="startDate"
              component={renderDateField}
              bsSize="lg"
              isValidDate={isValidDate}
            />
          </div>
          <div className="col-xs-4">
            <button
              onClick={() => handleSetEditing(false)}
              className="btn btn-text btn-text--small text-right btn-text--green"
              disabled={false}
            >
              Save
              <i className="icon et-isolated-check" />
            </button>
            <button
              onClick={() => {
                handleSetEditing(false);
                toggleFormCallback();
              }}
              className="btn btn-text btn-text--small text-right btn-text--red"
            >
              Cancel
              <i className="icon et-delete-3" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'unitSqFtInformation',
  validate,
  enableReinitialize: true,
})(UnitSqFtInformationForm);
