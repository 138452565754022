import React from 'react';
import { FormattedMessage } from 'react-intl';
import BarGraph from '../../../components/Graphs/BarGraph';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import messages from './messages';
import KPICardContainer from '../../../components/KPICardContainer';

const LABELS = ['Current Resident', 'Prior Resident', 'Applicant'];
const PLACEHOLDER = 0;

export const labelFormatter = (formatNumber: Function) => {
  return (tooltipItem: Object, data: Object) => {
    const { datasetIndex, index } = tooltipItem;
    const { labels, datasets } = data;
    const columnLabel = labels[index];
    const dataset = datasets[datasetIndex];
    const datasetLabel = dataset.label;
    const value = dataset.data[index] || 0;
    const formattedValue = formatNumber(value, {
      style: 'currency',
      currency: 'USD',
    });
    const label =
      columnLabel !== 'Current Resident' ? columnLabel : datasetLabel;
    return `${label}: ${formattedValue}`;
  };
};

const totalFormatter = (formatNumber: Function, total: number) => {
  return (tooltipItems: Object) => {
    const tooltipItem = tooltipItems[0];
    const { index } = tooltipItem;
    const formattedTotal = formatNumber(total, {
      style: 'currency',
      currency: 'USD',
    });
    return index === 0 ? `Total: ${formattedTotal}` : '';
  };
};

const Delinquency = ({
  data,
  formatNumber,
  dataLoaded,
  showDelinquency,
  handleShowDelinquency,
}: Object) => {
  const ntv = data.ntv;
  const subsidy = data.subsidy;
  const currentResident = data.currentResident;
  const total = data.all;
  const priorResident = data.priorResident;
  const applicant = data.applicant;
  const datasets = [
    {
      label: 'Non-NTV / Non-Subsidy',
      data: [currentResident, PLACEHOLDER, PLACEHOLDER],
    },
    {
      label: 'NTV',
      data: [ntv, PLACEHOLDER, PLACEHOLDER],
    },
    {
      label: 'Subsidy',
      data: [subsidy, PLACEHOLDER, PLACEHOLDER],
    },
    {
      label: 'Prior Resident',
      data: [PLACEHOLDER, priorResident, applicant],
    },
  ];
  const stackedData = {
    labels: LABELS,
    datasets,
  };
  const label = labelFormatter(formatNumber);
  const afterTitle = totalFormatter(
    formatNumber,
    currentResident + ntv + subsidy,
  );
  return (
    <KPICardContainer title={<FormattedMessage {...messages.delinquency} />}>
      <div className="row">
        <div className="col-xs-3 col-sm-3 text-right">
          <span className="status-ball--small status-darker-blue" />
          <span className="font-10"> Non-NTV / Non-Subsidy</span>
        </div>
        <div className="col-xs-2 col-sm-2 text-right">
          <span className="status-ball--small status-lighter-blue" />
          <span className="font-10"> NTV</span>
        </div>
        <div className="col-xs-2 col-sm-2 text-right">
          <span className="status-ball--small status-lightest-blue" />
          <span className="font-10"> Subsidy</span>
        </div>
        <div className="col-xs-2 col-sm-2 text-right">
          <span className="status-ball--small status-light-gray" />
          <span className="font-10"> Non-Resident</span>
        </div>
      </div>
      <div className="row">
        {showDelinquency ? (
          <div className="col-xs-12 col-sm-12">
            <AsyncBox loading={!dataLoaded}>
              <BarGraph
                data={stackedData}
                options={{
                  tooltips: {
                    callbacks: {
                      label,
                      afterTitle,
                    },
                  },
                }}
                stacked
                currency
              />
            </AsyncBox>
          </div>
        ) : (
          <div className="text-center center-block">
            <button
              className="btn btn-primary center-block"
              onClick={handleShowDelinquency}
            >
              Load Delinquency Data
            </button>
          </div>
        )}
      </div>
      {showDelinquency && (
        <div className="text-center padtop10 padbottom10">
          Total Delinquency:{' '}
          {formatNumber(total, {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
      )}
    </KPICardContainer>
  );
};

export default Delinquency;
