import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.PeopleProfile.Title',
    defaultMessage: 'Fortress - People profile',
  },
  peopleTab: {
    id: 'App.PeopleProfile.PeopleTab',
    defaultMessage: 'Prospect Information',
  },
  activityTab: {
    id: 'App.PeopleProfile.ActivityTab',
    defaultMessage: 'Activity',
  },
  prospectInformation: {
    id: 'App.PeopleProfile.ProspectInformation',
    defaultMessage: 'Prospect Information',
  },
  successHeader: {
    id: 'App.PeopleProfile.SuccessHeader',
    defaultMessage: 'Success',
  },
  successHeaderDeleteActivity: {
    id: 'App.PeopleProfile.successHeaderDeleteActivity',
    defaultMessage: 'Success',
  },
  successHeaderProspect: {
    id: 'App.PeopleProfile.successHeaderProspect',
    defaultMessage: 'Success',
  },
  successActivityCreateDescription: {
    id: 'App.PeopleProfile.successActivityCreateDescription',
    defaultMessage: 'The activity was added.',
  },
  successActivityUpdateDescription: {
    id: 'App.PeopleProfile.successActivityUpdateDescription',
    defaultMessage: 'The activity was updated.',
  },
  successActivityDeleteDescription: {
    id: 'App.PeopleProfile.successActivityDeleteDescription',
    defaultMessage: 'The activity was deleted.',
  },
  successDescriptionProspect: {
    id: 'App.PeopleProfile.successDescriptionProspect',
    defaultMessage: 'The prospect information was updated.',
  },
  errorHeader: {
    id: 'App.PeopleProfile.ErrorHeader',
    defaultMessage: 'Error',
  },
  cancelConfirmation: {
    id: 'App.PeopleProfile.CancelConfirmation',
    defaultMessage: 'Are you sure you want to close without saving?',
  },
  chooseOption: {
    id: 'App.PeopleProfile.chooseOption',
    defaultMessage: 'Choose an option',
  },
  texting: {
    id: 'App.PeopleProfile.Texting',
    defaultMessage: 'Texting',
  },
});

export default messages;
