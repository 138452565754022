import React, { useState, useEffect } from 'react';
import {
  Typography,
  Select,
  Spinner,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { LEASE_END_DATE_SETTINGS_OPTIONS, getOptionName } from './constants';
import generalMessages from '../../../App/messages';
import componentMessages from './messages';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isNil } from 'ramda';
import SetupGridItem from '../../SetupGridItem';
const messages = { ...generalMessages, ...componentMessages };
type Props = {
  intl: any,
  propertyDetails: Object,
  propertyDetailsIsLoading: boolean,
  editMode: boolean,
  onChange: Function,
  disabled: boolean,
};

export const LeaseEndDateSetupSection = (props: Props) => {
  const { editMode, onChange, propertyDetails, propertyDetailsIsLoading } =
    props;
  let { leaseEndDateGreaterThan12m, leaseEndDateLesserThan12m } =
    propertyDetails;

  const [endDateSettings, setEndDateSettings] = useState({
    leaseEndDateGreaterThan12m,
    leaseEndDateLesserThan12m,
  });

  useEffect(() => {
    setEndDateSettings({
      leaseEndDateGreaterThan12m,
      leaseEndDateLesserThan12m,
    });
    if (
      isEmpty(leaseEndDateGreaterThan12m) ||
      isNil(leaseEndDateGreaterThan12m)
    ) {
      setEndDateSettings({
        leaseEndDateGreaterThan12m: 'CALCULATED',
        leaseEndDateLesserThan12m: 'CALCULATED',
      });
    }
  }, [leaseEndDateGreaterThan12m, leaseEndDateLesserThan12m]);

  const onSelectedValueChange = (key: string) => (e: Object) => {
    onChange({
      target: { name: key, value: e.target.value },
    });
  };
  return propertyDetailsIsLoading ? (
    <Spinner></Spinner>
  ) : (
    <>
      <SetupGridItem
        label={<FormattedMessage {...messages.defaultLeaseEndDate} />}
        TooltipProps={{
          title: infoTipMessage,
        }}
      ></SetupGridItem>

      <SetupGridItem
        label={<FormattedMessage {...messages.lease12MonthsGreater} />}
      >
        {editMode ? (
          <Select
            name="leaseEndDateGreaterThan12m"
            id="lease-end-date-greater-12m"
            disabled={!editMode}
            value={endDateSettings.leaseEndDateGreaterThan12m}
            onChange={onSelectedValueChange('leaseEndDateGreaterThan12m')}
            placeholder="Choose"
            options={LEASE_END_DATE_SETTINGS_OPTIONS}
            fullWidth
          />
        ) : (
          getOptionName(leaseEndDateGreaterThan12m)
        )}
      </SetupGridItem>

      <SetupGridItem
        label={<FormattedMessage {...messages.lease12MonthsLesser} />}
      >
        {editMode ? (
          <Select
            name="leaseEndDateLesserThan12m"
            id="lease-end-date-lesser-12m"
            disabled={!editMode}
            value={endDateSettings.leaseEndDateLesserThan12m}
            onChange={onSelectedValueChange('leaseEndDateLesserThan12m')}
            placeholder="Choose"
            options={LEASE_END_DATE_SETTINGS_OPTIONS}
            fullWidth
          />
        ) : (
          getOptionName(leaseEndDateLesserThan12m)
        )}
      </SetupGridItem>
    </>
  );
};

const infoTipMessage = (
  <Typography>
    <Typography variant="h4">
      <FormattedMessage {...messages.tooltipTitle} />
    </Typography>
    <br />
    <FormattedMessage {...messages.tooltipLine1} />
    <br />
    <br />
    <FormattedMessage {...messages.tooltipLine2} />
    <br />
    <br />
    <FormattedMessage {...messages.tooltipLine3} />
  </Typography>
);
