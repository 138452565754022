import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';

import type { GlobalState } from '../App/types';
import * as forgotPasswordActions from './actions';
import ForgotPasswordForm from './ForgotPasswordForm';
import messages from './messages';

type Props = {
  state: Object,
}

type InjectedProps = {
  actions: Object,
  intl: any,
  history: Object,
}

export class ForgotPassword extends Component<Props & InjectedProps> {
  handleSubmit = (login: any) => {
    const username = login.username;   
    this.props.actions.sendForgotPasswordEmail({ username });
  }
  handleReturnToLogin = () => {
    this.props.actions.clearMessages();
    this.props.history.push('/login');
  }

  render() {
    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <ForgotPasswordForm 
          intl={this.props.intl} 
          onSubmit={this.handleSubmit}
          handleReturnToLogin = {this.handleReturnToLogin}
          showSuccessMessage={this.props.state.showSuccessMessage}
          showErrorMessage={this.props.state.showErrorMessage}
          errorMessage={this.props.state.errorMessage}
          emailSent={this.props.state.emailSent}
          history =  {this.props.history}
        />
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = ({ forgotPassword }: GlobalState) => {
  return { 
    state: forgotPassword,
  };
};

export function mapDispatchToProps(dispatch: any) {
  return { 
    actions: bindActionCreators(forgotPasswordActions, dispatch)
  }
}

const InjectedForgotPwdPage = injectIntl(ForgotPassword);
export default connect(mapStateToProps, mapDispatchToProps)(InjectedForgotPwdPage);
