import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.EditUser.EditUserForm.Header',
    defaultMessage: 'Edit user'
  },
  subheader: {
    id: 'App.EditUser.EditUserForm.Subheader',
    defaultMessage: 'All fields are required'
  },
  requiredFields: {
    id: 'App.EditUser.EditUserForm.RequiredFields',
    defaultMessage: 'Required'
  },
  invalidName: {
    id: 'App.EditUser.EditUserForm.InvalidName',
    defaultMessage: 'First name and last name must have letters or digits only'
  },
  invalidEmailAddress: {
    id: 'App.EditUser.EditUserForm.InvalidEmailAddress',
    defaultMessage: 'Invalid email address'
  },
  invalidUsername: {
    id: 'App.EditUser.EditUserForm.InvalidUsername',
    defaultMessage: 'Username must have letters or digits only with no spaces'
  }
});

export default messages;
