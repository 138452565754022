import { defineMessages } from 'react-intl';

const messages = defineMessages({
  longText: {
    id: 'App.ManageLeaseExpirationLimitsLink.LongText',
    defaultMessage: 'Manage Lease Expiration Limits',
  },
  shortText: {
    id: 'App.ManageLeaseExpirationLimitsLink.ShortText',
    defaultMessage: 'Manage Limits',
  },
});

export default messages;
