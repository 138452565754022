import { defineMessages } from 'react-intl';

const messages = defineMessages({
  manageDelinquency: {
    id: 'App.ManageDelinquency.ManageDelinquency',
    defaultMessage: 'Manage Delinquent and Prepaid',
  },
  unitNumber: {
    id: 'App.ManageDelinquency.UnitNumber',
    defaultMessage: 'Unit Number',
  },
  status: {
    id: 'App.ManageDelinquency.Status',
    defaultMessage: 'Status',
  },
  residentApplicantName: {
    id: 'App.ManageDelinquency.ResidentApplicantName',
    defaultMessage: 'Resident / Applicant Name',
  },
  phoneNumber: {
    id: 'App.ManageDelinquency.PhoneNumber',
    defaultMessage: 'Phone Number',
  },
  moveInDate: {
    id: 'App.ManageDelinquency.MoveInDate',
    defaultMessage: 'Move-In Date',
  },
  moveOutDate: {
    id: 'App.ManageDelinquency.MoveOutDate',
    defaultMessage: 'Move-Out Date',
  },
  collectionsNotes: {
    id: 'App.ManageDelinquency.CollectionsNotes',
    defaultMessage: 'Collections Notes',
  },
  underEviction: {
    id: 'App.ManageDelinquency.UnderEviction',
    defaultMessage: 'Under Eviction',
  },
  prepaidBalance: {
    id: 'App.ManageDelinquency.PrepaidBalance',
    defaultMessage: 'Prepaid Balance',
  },
  delinquentBalance: {
    id: 'App.ManageDelinquency.DelinquentBalance',
    defaultMessage: 'Delinquent Balance',
  },
  writtenOff: {
    id: 'App.ManageDelinquency.WrittenOff',
    defaultMessage: 'Written Off',
  },
  subsidyOnlyBalance: {
    id: 'App.ManageDelinquency.subsidyOnlyBalance',
    defaultMessage: 'Subsidy Only Balance',
  },
  residentOnlyBalance: {
    id: 'App.ManageDelinquency.residentOnlyBalance',
    defaultMessage: 'Resident Only Balance',
  },
  CAMOnlyBalance: {
    id: 'App.ManageDelinquency.CAMOnlyBalance',
    defaultMessage: 'CAM Only Balance',
  },
  rentOnlyBalance: {
    id: 'App.ManageDelinquency.RentOnlyBalance',
    defaultMessage: 'Rent Only Balance',
  },
  agingBalances: {
    id: 'App.ManageDelinquency.AgingBalances',
    defaultMessage: 'Aging Balances',
  },
  depositsHeld: {
    id: 'App.ManageDelinquency.DepositsHeld',
    defaultMessage: 'Deposits Held',
  },
  depositsOwed: {
    id: 'App.ManageDelinquency.DepositsOwed',
    defaultMessage: 'Deposits Owed',
  },
  lateFees: {
    id: 'App.ManageDelinquency.LateFees',
    defaultMessage: '#Late Fees',
  },
  nsfFees: {
    id: 'App.ManageDelinquency.NSFFees',
    defaultMessage: '#NSF Fees',
  },
  current: {
    id: 'App.ManageDelinquency.Current',
    defaultMessage: 'Current',
  },
  search: {
    id: 'App.ManageDelinquency.Search',
    defaultMessage: 'Search',
  },
  noDataAvailable: {
    id: 'App.ManageDelinquency.NoDataAvailable',
    defaultMessage: 'No Data Available.',
  },
  floorPlan: {
    id: 'App.ManageDelinquency.floorPlan',
    defaultMessage: 'Floor Plan',
  },
  actions: {
    id: 'App.ManageDelinquency.actions',
    defaultMessage: 'Actions',
  },
  createActivity: {
    id: 'App.ManageDelinquency.createActivity',
    defaultMessage: 'Create Activity',
  },
});

export default messages;
