import React from 'react';
import messages from '../messages';
import { TaskRow } from '../TaskRowDeprecated';
import Spinner from '../../../../components/Spinner';

type Props = {
  title: string,
  headers: Array<any>,
  items: Array<Object>,
  intl: Object,
  onChangeAssignee?: Function,
  assignees?: Array<Object>,
  isTasksLoading: boolean,
};

const TasksTable = ({
  title,
  headers,
  items,
  assignees,
  onChangeAssignee,
  intl,
  showLegendGreenIcon,
  isTasksLoading,
}: Props) => (
  <div className="panel task-summary panel-withheader panel-has-table">
    <div className="panel-head">
      <h2>{title}</h2>
    </div>
    <div className="panel__details--full">
      {showLegendGreenIcon && (
        <div>
          <span
            className="legend-color"
            style={{ backgroundColor: '#F1FEE7' }}
          />
          <span className="small">
            {intl.formatMessage(messages.legendGreen)}
          </span>
        </div>
      )}
      <table className="table-prospects table table-striped">
        <thead>
          <tr className="table-header">
            {headers.map((header) => (
              <th key={header.id}>{header.text}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isTasksLoading ? (
            <td colSpan="100%">
              <Spinner />
            </td>
          ) : (
            items.map((item) => (
              <TaskRow
                key={item.id}
                item={item}
                assignees={assignees}
                onChangeAssignee={onChangeAssignee}
              />
            ))
          )}
        </tbody>
      </table>
    </div>
  </div>
);

export default TasksTable;
