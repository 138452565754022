import { defineMessages } from 'react-intl';

const messages = defineMessages({
  changeStatus: {
    id: 'App.ManageProspects.ActionsMenu.ChangeStatus',
    defaultMessage: 'Change status',
  },
  recordActivity: {
    id: 'App.ManageProspects.ActionsMenu.RecordActivity',
    defaultMessage: 'Record activity',
  },
  scheduleActivity: {
    id: 'App.ManageProspects.ActionsMenu.ScheduleActivity',
    defaultMessage: 'Schedule activity',
  },
  createActivity: {
    id: 'App.ManageProspects.ActionsMenu.CreateActivity',
    defaultMessage: 'Create activity',
  },
});

export default messages;
