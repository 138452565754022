import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import Spinner from '../../../components/GifSpinner';
import PropertyNotSelectedPage from '../../../components/PropertyNotSelectedPage';
import ForbiddenPage from '../../../components/ForbiddenPage';
import NotFound from '../../../components/NotFound';
import ForgotPassword from '../../ForgotPassword';
import LoginPage from '../../Login';
import SecondaryLeaseApplicationPage from '../../NonPrimaryLeaseApplication';
import PrivacyPolicyPage from '../../PrivacyPolicy';
import RecertificationApplication from '../../RecertificationApplication';
import ResetPasswordPage from '../../ResetPassword';
import ShortLeaseApplicationPage from '../../ShortLeaseApplication';
import TermsOfUsePage from '../../TermsOfUse';
import MainLayoutRoutes from './MainLayoutRoutes';

const LoadablePrimaryLeaseApplication = Loadable({
  loader: () => import('../../PrimaryLeaseApplication'),
  loading: Spinner,
});

function Routes() {
  return (
    <Switch>
      <Route exact path="/reset-password" component={ResetPasswordPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/termsofuse" component={TermsOfUsePage} />
      <Route exact path="/privacypolicy" component={PrivacyPolicyPage} />
      <Route exact path="/forbidden" component={ForbiddenPage} />
      <Route exact path="/404" component={NotFound} />
      <Route
        exact
        path="/select-property"
        component={PropertyNotSelectedPage}
      />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route
        exact
        path="/property/:propertyId/shortapplication/:applicationId/:applicantId"
        component={ShortLeaseApplicationPage}
      />
      <Route
        exact
        // eslint-disable-next-line
        path="/property/:propertyId/affordable-qualifications/:affordableQualificationId/affordable-programs/:programName/households/:householdId/applications/:applicationId/applicants/:applicantId/residents/:residentId/recertification-application"
        component={RecertificationApplication}
      />
      <Route
        exact
        path="/property/:propertyId/primary-form/:applicationId/:applicantId"
        component={LoadablePrimaryLeaseApplication}
      />
      <Route
        exact
        path="/property/:propertyId/non-primary-form/:applicationId/:applicantId"
        component={SecondaryLeaseApplicationPage}
      />
      <MainLayoutRoutes />
    </Switch>
  );
}

export default Routes;
