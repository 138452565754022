import type { OrganizationId } from '../containers/App/types';
import { get } from '../utils/api';

export default class PropertyService {
  getOwners(organizationId: OrganizationId, options?: Object) {
    return get(`/${organizationId}/owners`, options);
  }

  getPropertyOwners(organizationId: OrganizationId, options?: Object) {
    return get(`/${organizationId}/property-owners`, options);
  }
}
