import type { OrganizationId } from '../containers/App/types';
import request, { apiURL } from '../utils/api';

export default class PropertySubjournalTypesService {
  getPropertySubjournalTypes(
    organizationId: OrganizationId,
    propertyId: string,
    signal?: Object,
  ) {
    const requestOptions: Object = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    if (signal) {
      requestOptions.signal = signal;
    }
    return request(
      `${apiURL}/${organizationId}/${propertyId}/property-subjournal-types`,
      requestOptions,
    );
  }
  getPropertyChargeCodesBySubjournal(
    organizationId: OrganizationId,
    propertyId: string,
    subjournalId: string,
    signal?: Object,
  ) {
    const requestOptions: Object = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    if (signal) {
      requestOptions.signal = signal;
    }
    return request(
      `${apiURL}/${organizationId}/${propertyId}/property-subjournal-types/${subjournalId}/charge-codes`,
      requestOptions,
    );
  }
}
