import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import Spinner from '../../../../components/Spinner';
import {
  filterCamPoolsRemoveCam,
  removePoolsFromTransactions,
} from '../../../../utils/cam';

import { useFetchCamPools } from './hooks';
import messages from './messages';
import { AssignTransactionsFromCanceledCamModalProps } from './types';
import ReassignTransactionsForm from './form';

const AssignTransactionsFromCanceledCamModal = ({
  propertyId,
  organizationId,
  householdId,
  onHide,
  onSubmit,
  transactions,
  camRecordIdToCancel,
  isLoading,
  intl,
}: AssignTransactionsFromCanceledCamModalProps) => {
  const [isLoadingCamPools, camPools] = useFetchCamPools({
    propertyId,
    organizationId,
    householdId,
  });

  const showIsLoading = isLoading || isLoadingCamPools;

  const filteredCamPools = filterCamPoolsRemoveCam(
    camRecordIdToCancel,
    camPools,
  );

  // Remove cam period and pool from transactions so users have to select new ones
  const transactionsWithoutAssignedPool =
    removePoolsFromTransactions(transactions);

  return (
    <div className="static-modal">
      <Modal
        bsSize="large"
        dialogClassName="modal-dialog modal-alert"
        onHide={onHide}
        show={true}
        autoFocus={true}
        handleSubmit={onSubmit}
      >
        {showIsLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : (
          <>
            <Row>
              <Col xs={12}>
                <h1 className="text-center">
                  <FormattedMessage {...messages.cancelingCamModalTitle1} />
                </h1>
                <h1 className="text-center">
                  <FormattedMessage {...messages.cancelingCamModalTitle2} />
                </h1>

                <h2 className="text-center">
                  <FormattedMessage {...messages.cancelingCamModalSubtitle} />
                </h2>
              </Col>
            </Row>

            <ReassignTransactionsForm
              intl={intl}
              onHide={onHide}
              onSubmit={onSubmit}
              transactions={transactionsWithoutAssignedPool}
              camPools={filteredCamPools}
              footNote={messages.cancelingCamFootNote}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default AssignTransactionsFromCanceledCamModal;
