import { defineMessages } from 'react-intl';

const messages = defineMessages({
  billerName: {
    id: 'App.Household.CashPayDetailsSection.BillerName',
    defaultMessage: 'Biller Name',
  },
  receiveCode: {
    id: 'App.Household.CashPayDetailsSection.ReceiveCode',
    defaultMessage: 'Receive Code',
  },
  accountNumber: {
    id: 'App.Household.CashPayDetailsSection.AccountNumber',
    defaultMessage: 'Account Number',
  },
  title: {
    id: 'App.Household.CashPayDetailsSection.Title',
    defaultMessage: 'Cash Pay',
  },
});

export default messages;
