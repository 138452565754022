import { defineMessages } from 'react-intl';

const messages = defineMessages({
  passportLabel: {
    id: 'App.BasicAffordableInformationSection.passportLabel',
    defaultMessage: 'Passport #/Alien Registration',
  },
  provideOne: {
    id: 'App.BasicAffordableInformationSection.provideOne',
    defaultMessage:
      'You must provide either SSN, license, passport, or alien registration to complete the application.',
  },
  requiredForCompletion: {
    id: 'App.BasicAffordableInformationSection.RequiredForCompletion',
    defaultMessage: 'This field is required to complete the application',
  },
});

export default messages;
