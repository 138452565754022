import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { pathOr, uniq } from 'ramda';

import ConfirmOverlay from '../ConfirmOverlay';
import ManageFormsDropDown from '../ManageFormsDropDown';
import SubmitButton from '../SubmitButton';

import messages from './messages';

const ONE_HUNDRED_MB = 100 * 1000 * 1000;

const NotesTextArea = styled.textarea`
  border: 1px solid #d1d1d1;
`;

type DropDownOption = {
  value: ?string,
  text: string,
};

type UploadPacketModalProps = {
  onHide: Function,
  show: boolean,
  handleDocumentUpload: Function,
  title: string,
  documentTypeOptions: Array<DropDownOption>,
  validations?: Function,
};

const UploadDocumentModal = ({
  onHide,
  show,
  title,
  handleDocumentUpload,
  documentTypeOptions: documentTypes,
  fileValidations,
}: UploadPacketModalProps) => {
  const documentTypeOptions = uniq(documentTypes).sort((a, b) =>
    a.text.localeCompare(b.text),
  );
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [fileInfo, setFileInfo] = useState({ filename: '', file: null });
  const [documentTypeId, setDocumentTypeId] = useState(
    documentTypeOptions[0].value,
  );
  const [notes, setNotes] = useState('');
  const [maxFileSizeExceeded, setMaxFileSizeExceeded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleFileChange = (event: Object) => {
    const filename = event.target.value.replace(/.*(\/|\\)/, '');
    const file = event.target.files[0];
    const size = pathOr(null, ['size'], file);
    if (size > ONE_HUNDRED_MB) {
      setMaxFileSizeExceeded(true);
    }
    const fileErrors = fileValidations && fileValidations(file);
    setErrors(fileErrors);
    if (maxFileSizeExceeded === false && errors.length === 0) {
      setFileInfo({ filename, file });
    }
  };

  const handleDocTypeChange = ({ target: { value } }) => {
    setDocumentTypeId(value);
  };

  const handleNoteChange = ({ target: { value } }) => {
    setNotes(value);
  };
  const hideCancelConfirmation = () => setShowCancelConfirmation(false);

  const handleHideCleanup = () => {
    setShowCancelConfirmation(false);
    setIsSubmitting(false);
    setFileInfo({ filename: '', file: null });
    setMaxFileSizeExceeded(false);
    setNotes('');
    setDocumentTypeId(documentTypeOptions[0].value);
    setErrors([]);
    onHide();
  };

  const handleHide = () => {
    if (!showCancelConfirmation) {
      setShowCancelConfirmation(true);
      return;
    }
    handleHideCleanup();
  };

  const handleSubmit = () => {
    const { file } = fileInfo;
    setIsSubmitting(true);

    const document = new FormData();
    document.append('file', file);
    document.append('notes', notes);
    document.append('documentTypeId', documentTypeId);
    handleDocumentUpload(document, handleHideCleanup);
  };

  const disableSubmittal =
    showCancelConfirmation ||
    !fileInfo.file ||
    maxFileSizeExceeded ||
    !documentTypeId;

  const fileWarnings = fileValidations && fileValidations(fileInfo.file);

  return (
    <Modal show={show} onHide={handleHide} bsSize="large" autoFocus>
      <Modal.Header closeButton>
        <i className="icon et-checklist" />
        <h1>{title}</h1>
      </Modal.Header>
      <Modal.Body>
        {showCancelConfirmation && (
          <ConfirmOverlay
            title={<FormattedMessage {...messages.confirmationTitle} />}
            rejectMessage={
              <FormattedMessage {...messages.confirmationCancel} />
            }
            confirmMessage={<FormattedMessage {...messages.confirm} />}
            onReject={hideCancelConfirmation}
            onConfirm={handleHide}
          />
        )}
        <div className="row">
          <div className="col-md-6">
            <div>
              <label className="input ">Filename</label>
            </div>
            <input
              className="inputfile inputfile-custom"
              id="upload1"
              type="file"
              name="file"
              onChange={handleFileChange}
            />
            <label htmlFor="upload1">
              <span>{fileInfo.filename}</span>
              <strong>Browse</strong>
            </label>
          </div>
          <div className="col-md-6">
            <label className="input ">Document Type</label>
            <ManageFormsDropDown
              items={documentTypeOptions}
              changeFunction={handleDocTypeChange}
              initialValue={documentTypeId}
              bsSize="lg"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {maxFileSizeExceeded && (
              <span className="has-error">
                <FormattedMessage {...messages.maxFileSizeExceeded} />
              </span>
            )}
            {errors.length === 0 &&
              fileWarnings.length > 0 &&
              fileWarnings.map((warning) => (
                <span key={warning} className="has-warning">
                  {warning}
                </span>
              ))}
            {errors.length > 0 &&
              errors.map((error) => (
                <span key={error} className="has-error">
                  {error}
                </span>
              ))}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="input">Document Notes (Optional)</label>
              <NotesTextArea onChange={handleNoteChange} value={notes} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col-xs-6">
            <button
              className="btn btn-default pull-right"
              onClick={handleHide}
              disabled={showCancelConfirmation}
            >
              <FormattedMessage {...messages.cancel} />
            </button>
          </div>
          <div className="col-xs-6">
            <SubmitButton
              classes="btn btn-primary pull-left"
              clickHandler={handleSubmit}
              disabled={disableSubmittal}
              isSubmitting={isSubmitting}
            >
              <FormattedMessage {...messages.save} />
            </SubmitButton>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadDocumentModal;
