import type { State, Action, Selection } from './types';
import * as actionTypes from './constants';
import { merge, find, propEq, equals, filter, compose, not } from 'ramda';

const initialState: State = {
  undepositedBatches: [],
  loadingBatches: false,
  batchesError: undefined,
  undepositedPayments: [],
  loadingPayments: false,
  paymentsError: undefined,
  creating: false,
  createError: undefined,
  selectedForDeposit: [],
  viewPaymentBatchClicked: false
};

function manageUndepositedPaymentsReducer(
  state: State,
  { type, payload }: Action
): Object {
  switch (type) {
    case actionTypes.GET_UNDEPOSITED_PAYMENT_BATCHES:
      return { loadingBatches: true };
    case actionTypes.GET_UNDEPOSITED_PAYMENT_BATCHES_SUCCESS:
      return {
        loadingBatches: false,
        undepositedBatches: payload,
        batchesError: undefined
      };
    case actionTypes.GET_UNDEPOSITED_PAYMENT_BATCHES_FAILURE:
      return { loadingBatches: false, batchesError: payload };
    case actionTypes.GET_UNDEPOSITED_PAYMENTS:
      return { loadingPayments: true };
    case actionTypes.GET_UNDEPOSITED_PAYMENTS_SUCCESS:
      return {
        loadingPayments: false,
        undepositedPayments: payload,
        paymentsError: undefined
      };
    case actionTypes.GET_UNDEPOSITED_PAYMENTS_FAILURE:
      return { loadingPayments: false, paymentsError: payload };
    case actionTypes.CREATE_DEPOSIT:
      return { creating: true };
    case actionTypes.CREATE_DEPOSIT_SUCCESS:
      return {
        creating: false,
        createError: undefined,
        selectedForDeposit: [],
        undepositedBatches: state.undepositedBatches.filter(
          (b) =>
            !state.selectedForDeposit.find((s) => s.isBatch && s.id === b.id)
        ),
        undepositedPayments: state.undepositedPayments.filter(
          (p) =>
            !state.selectedForDeposit.find((s) => !s.isBatch && s.id === p.id)
        )
      };
    case actionTypes.CREATE_DEPOSIT_FAILURE:
      return { creating: false, createError: payload };
    case actionTypes.SELECT_FOR_DEPOSIT: {
      const selection: Selection = payload;
      const ls = selection.isBatch
        ? state.undepositedBatches
        : state.undepositedPayments;
      const found = find(propEq('id', selection.id), ls) !== undefined;
      return found
        ? { selectedForDeposit: state.selectedForDeposit.concat([selection]) }
        : {};
    }
    case actionTypes.UNSELECT_FOR_DEPOSIT: {
      const found = find(equals(payload), state.selectedForDeposit);
      return found
        ? {
            selectedForDeposit: filter(
              compose(
                not,
                equals(payload)
              ),
              state.selectedForDeposit
            )
          }
        : {};
    }
    case actionTypes.CLICK_VIEW_PAYMENT_BATCH:
      return { viewPaymentBatchClicked: true };
    case actionTypes.CLEAR_STATE:
      return initialState;
    case actionTypes.CLEAR_SELECTIONS:
      return { selectedForDeposit: [] };
    default:
      return {};
  }
}

export default (state: State = initialState, action: Action): State =>
  merge(state, manageUndepositedPaymentsReducer(state, action));
