import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages.js';

export default () => (
  <div>
    <h2 className="appmanagertitle">
      <FormattedMessage {...messages.title} />
    </h2>
    <h2 className="appmanagertitle" style={{ marginLeft: '24px' }}>
      <small>
        <FormattedMessage {...messages.titleNote} />
      </small>
    </h2>
  </div>
);
