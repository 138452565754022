import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.LeaseDataTabEmpty.Title',
    defaultMessage: 'Applicant Pending Approval or Unit Assigment',
  },
  description: {
    id: 'App.LeaseDataTabEmpty.Description',
    /* eslint-disable max-len */
    defaultMessage:
      "Either this applicant isn't Approved or no Unit is Assigned to this Approved Applicant. Please check Approvals on the {leaseAppTab} and {unitAssigment} in Snapshot.",
  },
  commercialDescription: {
    id: 'App.LeaseDataTabEmpty.CommercialDescription',
    /* eslint-disable max-len */
    defaultMessage:
      'Either this applicant isn’t Approved or no Unit is Assigned to this Approved Applicant. Please check the {applicationStatus} and {unitAssigment} in Snapshot.',
  },
});

export default messages;
