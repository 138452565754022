import ColumnHeader from '../../ColumnHeader';
import messages from '../messages';
import React from 'react';
import { curryN } from 'ramda';
import type { ActivityData } from '../types';

type Props = {
  intl: Object,
  columnOrder: Object,
  order: string,
  onSortOrder: Function,
  activitiesByProspect: ActivityData,
};

function ActivityTableHeader(props: Props) {
  const { intl, columnOrder, order, onSortOrder, activitiesByProspect } = props;
  const onOrderClickCurried = curryN(2, onSortOrder);
  const enableSort = props.isLoading
    ? false
    : activitiesByProspect.results.length > 0;
  return (
    <thead className="table-header">
      <tr>
        <ColumnHeader
          columnLabel={intl.formatMessage(messages.dateTime)}
          sortable={enableSort}
          icon={columnOrder.dateTime}
          order={order}
          onOrderClick={onOrderClickCurried('dateTime')}
        />
        <ColumnHeader
          columnLabel={intl.formatMessage(messages.automated)}
          sortable={enableSort}
          icon={columnOrder.automated}
          order={order}
          onOrderClick={onOrderClickCurried('automated')}
        />
        <ColumnHeader
          columnLabel={intl.formatMessage(messages.status)}
          sortable={enableSort}
          icon={columnOrder.status}
          order={order}
          onOrderClick={onOrderClickCurried('status')}
        />
        <ColumnHeader
          columnLabel={intl.formatMessage(messages.type)}
          sortable={enableSort}
          icon={columnOrder.type}
          order={order}
          onOrderClick={onOrderClickCurried('type')}
        />
        <ColumnHeader
          columnLabel={intl.formatMessage(messages.user)}
          sortable={enableSort}
          icon={columnOrder.user}
          order={order}
          onOrderClick={onOrderClickCurried('user')}
        />
        <ColumnHeader columnLabel={intl.formatMessage(messages.notes)} />
        <ColumnHeader columnLabel={intl.formatMessage(messages.edit)} />
      </tr>
    </thead>
  );
}

export default ActivityTableHeader;
