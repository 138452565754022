import styled from 'styled-components';

export const SummaryTableGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ setAsideKpiLeasedUnits }) =>
    setAsideKpiLeasedUnits ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr'};
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 15px;
`;
