import { defineMessages } from 'react-intl';

export default defineMessages({
  insurance: {
    id: 'App.Household.InsuranceSection.Insurance',
    defaultMessage: 'Insurance',
  },
  add: {
    id: 'App.Household.InsuranceSection.Add',
    defaultMessage: 'Add',
  },
  provider: {
    id: 'App.Household.InsuranceSection.Provider',
    defaultMessage: 'Provider',
  },
  policyStartDate: {
    id: 'App.Household.InsuranceSection.PolicyStartDate',
    defaultMessage: 'Policy Start Date',
  },
  policyEndDate: {
    id: 'App.Household.InsuranceSection.PolicyEndDate',
    defaultMessage: 'Policy End Date',
  },
  proofOfCoverage: {
    id: 'App.Household.InsuranceSection.ProofOfCoverage',
    defaultMessage: 'Proof of Coverage',
  },
  null: {
    id: 'App.Household.InsuranceSection.Null',
    defaultMessage: '---',
  },
  error: {
    id: 'App.Household.InsuranceSection.Error',
    defaultMessage: 'Error',
  },
  none: {
    id: 'App.Household.InsuranceSection.None',
    defaultMessage: 'None',
  },
  received: {
    id: 'App.Household.InsuranceSection.Received',
    defaultMessage: 'Received',
  },
  success: {
    id: 'App.Household.InsuranceSection.Success',
    defaultMessage: 'Success',
  },
  insuranceAdded: {
    id: 'App.Household.InsuranceSection.InsuranceAdded',
    defaultMessage: 'Insurance added successfully.',
  },
  insuranceUpdated: {
    id: 'App.Household.InsuranceSection.InsuranceUpdated',
    defaultMessage: 'Insurance updated successfully.',
  },
});
