import { toastr } from 'react-redux-toastr';
import messages from './messages';

export const isWaitlistStatus = (currentApplicationStatus = {}) => {
  return /Waitlist/gi.test(currentApplicationStatus?.name ?? '');
};

export const showWaitlistUpdateSuccess = (intl) => {
  toastr.success('Success', intl.formatMessage(messages.waitlistUpdateSuccess));
};

export const showWaitlistUpdateFail = (intl, messageId: string): void => {
  const messageIdToShow = messages[messageId] ?? messages.waitlistUpdateFailure;
  toastr.error('Error', intl.formatMessage(messageIdToShow));
};

export const getStartTimeDate = (waitlistStartTime) =>
  waitlistStartTime ? new Date(waitlistStartTime) : new Date();

export const getStartDateString = (waitlistStartTime) =>
  getStartTimeDate(waitlistStartTime)
    .toLocaleDateString(navigator.language, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replace(/\//g, '-');

export const getStartTimeString = (waitlistStartTime) =>
  getStartTimeDate(waitlistStartTime).toLocaleTimeString(navigator.language, {
    timeStyle: 'short',
  });

export default {
  isWaitlistStatus,
  getStartTimeDate,
};
