import type { Action } from '../App/types';
import {
  GET_ALL_PROPERTY_PERIODS,
  GET_ALL_PROPERTY_PERIODS_SUCCESS,
  GET_ALL_PROPERTY_PERIODS_ERROR,
  GET_ALL_MISC_TRANSACTIONS,
  GET_ALL_MISC_TRANSACTIONS_SUCCESS,
  GET_ALL_MISC_TRANSACTIONS_ERROR,
  CLEAR_LOADED_MISC_TRANSACTIONS
} from './constants';

export const initialState = {
  periods: [],
  miscTransactions: [],
  meta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: ''
  }
};

const miscTransactionsReducer = (
  state: Object = initialState,
  action: Action<*, *>
): Object => {
  const { type, payload, meta, error } = action;
  switch (type) {
    case GET_ALL_PROPERTY_PERIODS_SUCCESS: {
      return { ...state, periods: payload };
    }
    case GET_ALL_PROPERTY_PERIODS_ERROR: {
      return { ...state, propertyPeriodsError: payload, error };
    }
    case GET_ALL_MISC_TRANSACTIONS_SUCCESS: {
      return { ...state, miscTransactions: payload, meta };
    }
    case GET_ALL_MISC_TRANSACTIONS_ERROR: {
      return { ...state, miscTransactionsError: payload, error };
    }
    case CLEAR_LOADED_MISC_TRANSACTIONS: {
      return { ...initialState };
    }
    case GET_ALL_PROPERTY_PERIODS:
    case GET_ALL_MISC_TRANSACTIONS:
    default:
      return state;
  }
};

export default miscTransactionsReducer;
