import type { Action } from '../App/types';
import type {
  MiscTransaction,
  MiscTransactionsResponse,
  Period,
} from './types.js';
import {
  GET_ALL_PROPERTY_PERIODS,
  GET_ALL_PROPERTY_PERIODS_SUCCESS,
  GET_ALL_PROPERTY_PERIODS_ERROR,
  GET_ALL_MISC_TRANSACTIONS,
  GET_ALL_MISC_TRANSACTIONS_SUCCESS,
  GET_ALL_MISC_TRANSACTIONS_ERROR,
  HANDLE_SOCKET_REVERSE_MISC_TRANSACTION_ERROR,
  HANDLE_SOCKET_REVERSE_MISC_TRANSACTION_SUCCESS,
  CLEAR_LOADED_MISC_TRANSACTIONS,
  REVERSE_MISC_TRANSACTION,
  REVERSE_MISC_TRANSACTION_SUCCESS,
} from './constants';

export function getAllPropertyPeriods(): Action<any> {
  return {
    type: GET_ALL_PROPERTY_PERIODS,
    payload: undefined,
  };
}

export function getAllPropertyPeriodsSuccess(
  periods: any,
): Action<Array<Period>> {
  return {
    type: GET_ALL_PROPERTY_PERIODS_SUCCESS,
    payload: periods,
  };
}

export function getAllPropertyPeriodsError(error: Error): Action<Error> {
  return {
    type: GET_ALL_PROPERTY_PERIODS_ERROR,
    payload: error,
    error: true,
  };
}

export function getAllMiscTransactions(
  pageNumber: number,
  period: string = '',
): Action<any> {
  return {
    type: GET_ALL_MISC_TRANSACTIONS,
    payload: {
      pageNumber,
      period,
    },
  };
}

export function getAllMiscTransactionsSuccess({
  results,
  meta,
}: MiscTransactionsResponse): Action<Array<MiscTransaction>> {
  return {
    type: GET_ALL_MISC_TRANSACTIONS_SUCCESS,
    payload: results,
    meta,
  };
}

export function getAllMiscTransactionsError(error: Error): Action<Error> {
  return {
    type: GET_ALL_MISC_TRANSACTIONS_ERROR,
    payload: error,
    error: true,
  };
}

export function handleSocketReverseMiscTransactionError(
  payload: Object,
): Action<any> {
  return {
    type: HANDLE_SOCKET_REVERSE_MISC_TRANSACTION_ERROR,
    payload,
  };
}

export function handleSocketReverseMiscTransactionSuccess(
  payload: Object,
): Action<any> {
  return {
    type: HANDLE_SOCKET_REVERSE_MISC_TRANSACTION_SUCCESS,
    payload,
  };
}

export function clearLoadedMiscTransactions(): Action<any> {
  return {
    type: CLEAR_LOADED_MISC_TRANSACTIONS,
    payload: undefined,
  };
}

export function reverseMiscTransaction(transactionId: string): Action<any> {
  return {
    type: REVERSE_MISC_TRANSACTION,
    payload: {
      transactionId,
    },
  };
}

export function reverseMiscTransactionSuccess(): Action<any> {
  return {
    type: REVERSE_MISC_TRANSACTION_SUCCESS,
    payload: undefined,
  };
}
