import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import {
  Box,
  Typography,
  Card,
  Button,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  Accordion,
  Banner,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { EditAmenitiesModal } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import {
  SettingsIcon,
  LaunchIcon,
  AlertIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';

// Utils
import { palette } from '@fortress-technology-solutions/fortress-component-library/design';
import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';
import { formatAmenities } from '../utils';

// Hooks
import { useUnitAmenities } from './hooks';

// Constants
import v1Messages from '../Amenities/messages';
import v2Messages from './messages';

const messages = { ...v1Messages, ...v2Messages };

const UnitAmenitiesV2 = ({
  intl,
  amenities,
  propertyId,
  unitId,
  refreshUnit,
}: Object) => {
  const formattedAmenities = useMemo(
    () => formatAmenities(intl, amenities, true),
    [amenities, intl],
  );
  const {
    propertyAmenities,
    selectedAmenities,
    setSelectedAmenities,
    updateUnitAmenities,
    editAmenitiesModalOpen,
    openEditAmenitiesModal,
    closeEditAmenitiesModal,
  } = useUnitAmenities(propertyId, unitId, refreshUnit);

  const accordionAmenitiesSummary = (amenity) => {
    const { name, formattedFeeAmount, feeAmount } = amenity;
    const isNegative = feeAmount && formattedFeeAmount.includes('-');
    return {
      left: <Typography>{name}</Typography>,
      right: (
        <Typography color={isNegative ? 'error.main' : ''}>
          {formattedFeeAmount}
        </Typography>
      ),
    };
  };
  return (
    <>
      <Card sx={{ marginBottom: 2, padding: 2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 0,
          }}
        >
          <Typography variant="h2">
            <FormattedMessage {...messages.unitTitle} />
          </Typography>

          <ElementWithPermissions scope={['amenities-view-add-edit']}>
            <Button
              startIcon={<SettingsIcon />}
              variant="text"
              onClick={() => {
                openEditAmenitiesModal();
              }}
            >
              <FormattedMessage {...messages.manageUnitAmenities} />
            </Button>
          </ElementWithPermissions>
        </Box>
        <Box>
          {formattedAmenities.length > 0 ? (
            <>
              <Accordion
                summary={{
                  left: <Typography variant="h5">{'Amenity'}</Typography>,
                  right: <Typography variant="h5">{'Value'}</Typography>,
                }}
                expandable={false}
                sx={{
                  '.MuiAccordionSummary-root': {
                    height: 48,
                    borderTop: 'none',
                    borderBottom: `2px solid ${palette.divider}`,
                  },
                }}
              />
              {formattedAmenities.map((amenity) => (
                <Accordion
                  summary={accordionAmenitiesSummary(amenity)}
                  expandable={false}
                  sx={{
                    '.MuiAccordionSummary-root': {
                      height: 48,
                      borderTop: 'none',
                      borderBottom: `1px solid ${palette.divider}`,
                    },
                  }}
                />
              ))}
              <Banner
                text={intl.formatMessage(
                  messages.allUnitAmenitiesIncludedInMarketAndQuotingRent,
                )}
                icon={<AlertIcon color="inherit" />}
                hasBorder={false}
                color={'lightGrey'}
                BoxProps={{
                  sx: {
                    marginY: 2,
                  },
                }}
                TypographyProps={{
                  sx: {
                    textWrap: 'wrap',
                  },
                }}
              />
            </>
          ) : (
            <FormattedMessage {...messages.none} />
          )}
        </Box>
        <Box>
          <ElementWithPermissions scope={['amenities-view-add-edit']}>
            <Button
              startIcon={<LaunchIcon />}
              variant="text"
              href={getUrlWithSelectedPropertyId('/amenity')}
              target="_blank"
            >
              <FormattedMessage {...messages.navigateToAddNewPropertyAmenity} />
            </Button>
          </ElementWithPermissions>
        </Box>
      </Card>
      <EditAmenitiesModal
        isOpen={editAmenitiesModalOpen}
        close={closeEditAmenitiesModal}
        propertyAmenities={propertyAmenities}
        selectedAmenities={selectedAmenities}
        setSelectedAmenities={setSelectedAmenities}
        handleSave={updateUnitAmenities}
      />
    </>
  );
};

export default UnitAmenitiesV2;
