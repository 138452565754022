import { get } from '../utils/api';
const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class FloorPlanPricingService {
  getAllMarketRentsByProperty(organizationId: string, propertyId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/floorPlanMarketRents`,
      options,
    );
  }

  getAll(
    organizationId: string,
    propertyId: string,
    floorPlanId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/floor-plan-pricing/${floorPlanId}`,
      options,
    );
  }

  create(
    organizationId: string,
    propertyId: string,
    floorPlanId: string,
    values: Object,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/floor-plan-pricing/${floorPlanId}`,
      options,
    );
  }

  batchCreate(
    organizationId: string,
    propertyId: string,
    values: Array<Object>,
    options?: Object = {},
  ) {
    const requestOptions = {
      ...options,
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/floor-plan-pricing/batch`,
      requestOptions,
    );
  }

  update(
    organizationId: string,
    propertyId: string,
    floorPlanId: string,
    values: Object,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/floor-plan-pricing/${floorPlanId}`,
      options,
    );
  }

  delete(
    organizationId: string,
    propertyId: string,
    floorPlanId: string,
    values: Object,
  ) {
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/floor-plan-pricing/${floorPlanId}`,
      options,
    );
  }
}

export default FloorPlanPricingService;
