import { defineMessages } from 'react-intl';

const messages = defineMessages({
  tenant: {
    id: 'App.Tenant.Tenant',
    defaultMessage: 'Tenant',
  },
  insurance: {
    id: 'App.Tenant.Insurance.Insurance',
    defaultMessage: 'Insurance',
  },
  add: {
    id: 'App.Tenant.Add',
    defaultMessage: 'Add',
  },
  insuranceType: {
    id: 'App.Tenant.Insurance.Type',
    defaultMessage: 'Type',
  },
  insuranceLimits: {
    id: 'App.Tenant.Insurance.Limits',
    defaultMessage: 'Limits',
  },
  insuranceExpirationDate: {
    id: 'App.Tenant.Insurance.ExpirationDate',
    defaultMessage: 'Expiration Date',
  },
  contacts: {
    id: 'App.Tenant.Contacts',
    defaultMessage: 'Contacts',
  },
  contactName: {
    id: 'App.Tenant.Contacts.Name',
    defaultMessage: 'Name',
  },
  contactType: {
    id: 'App.Tenant.Contacts.Type',
    defaultMessage: 'Type',
  },
  contactPhone: {
    id: 'App.Tenant.Contacts.Phone',
    defaultMessage: 'Phone',
  },
  personName: {
    id: 'App.Tenant.People.Name',
    defaultMessage: 'Name',
  },
  personDba: {
    id: 'App.Tenant.People.Dba',
    defaultMessage: 'DBA',
  },
  addInsurance: {
    id: 'App.Tenant.Insurance.AddInsurance',
    defaultMessage: 'Add Insurance',
  },
  editInsurance: {
    id: 'App.Tenant.Insurance.EditInsurance',
    defaultMessage: 'Edit Insurance',
  },
  requiredField: {
    id: 'App.Tenant.RequiredField',
    defaultMessage: 'Required Field',
  },
  noInsurance: {
    id: 'App.Tenant.Insurance.NoInsurance',
    defaultMessage: 'No Insurance',
  },
  addNote: {
    id: 'App.Tenant.Insurance.AddNote',
    defaultMessage: 'NOTE:',
  },
  addNoteContent: {
    id: 'App.Tenant.Insurance.AddNoteContent',
    defaultMessage:
      'You will need to upload a copy of the insurance to the Documents tab after saving.',
  },
  insuranceDeleteConfirmationTitle: {
    id: 'App.Tenant.Insurance.DeleteConfirmTitle',
    defaultMessage: 'Are you sure you want to delete this insurance record?',
  },
  no: {
    id: 'App.Tenant.No',
    defaultMessage: 'No',
  },
  yes: {
    id: 'App.Tenant.Yes',
    defaultMessage: 'Yes',
  },
  cancel: {
    id: 'App.Tenant.Cancel',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'App.Tenant.Save',
    defaultMessage: 'Save',
  },
  noContacts: {
    id: 'App.Tenant.Contacts.NoContacts',
    defaultMessage: 'No Contacts',
  },
  choose: {
    id: 'App.Tenant.Choose',
    defaultMessage: 'Choose',
  },
  insuranceTypeField: {
    id: 'App.Tenant.Insurance.InsuranceTypeField',
    defaultMessage: 'Insurance Type *',
  },
  enterText: {
    id: 'App.Tenant.EnterText',
    defaultMessage: 'Enter Text',
  },
  insuranceLimitsField: {
    id: 'App.Tenant.Insurance.InsuranceLimitsField',
    defaultMessage: 'Insurance Limits *',
  },
  tenDayClauseField: {
    id: 'App.Tenant.Insurance.TenDayClauseField',
    defaultMessage: '10 Day Clause *',
  },
  insuranceRequirementsField: {
    id: 'App.Tenant.Insurance.InsuranceRequirementsField',
    defaultMessage: 'Insurance Requirements',
  },
  effectiveDateField: {
    id: 'App.Tenant.Insurance.EffectiveDateField',
    defaultMessage: 'Effective Date *',
  },
  expirationDateField: {
    id: 'App.Tenant.Insurance.ExpirationDateField',
    defaultMessage: 'Expiration Date *',
  },
  propertyListedAsAdditionalInsuredField: {
    id: 'App.Tenant.Insurance.PropertyListedAsAdditionalInsuredField',
    defaultMessage: 'Property listed as additional insured?',
  },
  success: {
    id: 'App.Tenant.Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'App.Tenant.Error',
    defaultMessage: 'Error',
  },
  createInsuranceSuccess: {
    id: 'App.Tenant.Insurance.CreateSuccess',
    defaultMessage: 'Insurance created.',
  },
  createInsuranceError: {
    id: 'App.Tenant.Insurance.CreateError',
    defaultMessage: 'Failed to create insurance.',
  },
  editInsuranceSuccess: {
    id: 'App.Tenant.Insurance.EditSuccess',
    defaultMessage: 'Insurance updated.',
  },
  editInsuranceError: {
    id: 'App.Tenant.Insurance.EditError',
    defaultMessage: 'Failed to update insurance.',
  },
  deleteInsuranceSuccess: {
    id: 'App.Tenant.Insurance.DeleteSuccess',
    defaultMessage: 'Insurance deleted.',
  },
  deleteInsuranceError: {
    id: 'App.Tenant.Insurance.DeleteError',
    defaultMessage: 'Failed to delete insurance.',
  },
  addContact: {
    id: 'App.Tenant.Contact.AddContact',
    defaultMessage: 'Add Contact',
  },
  editContact: {
    id: 'App.Tenant.Contact.EditContact',
    defaultMessage: 'Edit Contact',
  },
  contactDeleteConfirmationTitle: {
    id: 'App.Tenant.Contact.DeleteConfirmTitle',
    defaultMessage: 'Are you sure you want to delete this insurance record?',
  },
  commercialContactTypeField: {
    id: 'App.Tenant.Contact.CommercialContactTypeField',
    defaultMessage: 'Type *',
  },
  contactNameField: {
    id: 'App.Tenant.Contact.ContactNameField',
    defaultMessage: 'Contact Name *',
  },
  enterName: {
    id: 'App.Tenant.Contact.EnterName',
    defaultMessage: 'EnterName',
  },
  enterBusinessName: {
    id: 'App.Tenant.Contact.EnterBusinessName',
    defaultMessage: 'Enter Business Name',
  },
  businessNameField: {
    id: 'App.Tenant.Contact.BusinessNameField',
    defaultMessage: 'Business Name',
  },
  phoneNumberField: {
    id: 'App.Tenant.Contact.PhoneNumberField',
    defaultMessage: 'Business Phone Number *',
  },
  phoneNumberPlaceholder: {
    id: 'App.Tenant.Contact.PhoneNumberPlaceholder',
    defaultMessage: '(xxx) xxx-xxxx',
  },
  emailField: {
    id: 'App.Tenant.Contact.EmailField',
    defaultMessage: 'Email *',
  },
  enterEmail: {
    id: 'App.Tenant.Contact.EnterEmail',
    defaultMessage: 'Enter Email',
  },
  noteField: {
    id: 'App.Tenant.Contact.NoteField',
    defaultMessage: 'Note',
  },
  enterNote: {
    id: 'App.Tenant.Contact.EnterNote',
    defaultMessage: 'Enter Note',
  },
  contactAddressField: {
    id: 'App.Tenant.Contact.ContactAddressField',
    defaultMessage: 'Contact Address *',
  },
  address: {
    id: 'App.Tenant.Contact.Address',
    defaultMessage: 'Address',
  },
  address2: {
    id: 'App.Tenant.Contact.Address2',
    defaultMessage: 'Address 2',
  },
  contactAddress2Field: {
    id: 'App.Tenant.Contact.ContactAddress2Field',
    defaultMessage: 'Contact Address 2',
  },
  cityField: {
    id: 'App.Tenant.Contact.CityField',
    defaultMessage: 'City *',
  },
  city: {
    id: 'App.Tenant.Contact.City',
    defaultMessage: 'City',
  },
  stateField: {
    id: 'App.Tenant.Contact.StateField',
    defaultMessage: 'State *',
  },
  zipCodeField: {
    id: 'App.Tenant.Contact.ZipCodeField',
    defaultMessage: 'Zip Code / Postal Code *',
  },
  zipCode: {
    id: 'App.Tenant.Contact.ZipCode',
    defaultMessage: 'Zip Code / Postal Code',
  },
  createContactSuccess: {
    id: 'App.Tenant.Contact.CreateSuccess',
    defaultMessage: 'Contact created.',
  },
  createContactError: {
    id: 'App.Tenant.Contact.CreateError',
    defaultMessage: 'Failed to create contact.',
  },
  editContactSuccess: {
    id: 'App.Tenant.Contact.EditSuccess',
    defaultMessage: 'Contact updated.',
  },
  editContactError: {
    id: 'App.Tenant.Contact.EditError',
    defaultMessage: 'Failed to update contact.',
  },
  deleteContactSuccess: {
    id: 'App.Tenant.Contact.DeleteSuccess',
    defaultMessage: 'Contact deleted.',
  },
  deleteContactError: {
    id: 'App.Tenant.Contact.DeleteError',
    defaultMessage: 'Failed to delete contact.',
  },
  businessPhoneExtension: {
    id: 'App.Tenant.Contact.BusinessPhoneExtension',
    defaultMessage: 'Ext.',
  },
  secondaryPhoneNumberField: {
    id: 'App.Tenant.Contact.SecondaryPhoneNumberField',
    defaultMessage: 'Cell/Secondary Phone Number',
  },
});

export default messages;
