import React from 'react';
import { Button, Col } from 'react-bootstrap';
import styled from 'styled-components';

import messages from './messages';

type Props = {
  intl: any,
  edit: boolean,
  onCancel: Function,
  onConfirm: Function,
};

const BodyText = styled.p`
  text-align: center;
  margin: 10px 10px 25px 10px !important;
`;

export const AddEditChargeModal = ({
  intl,
  edit,
  onCancel,
  onConfirm,
}: Props) => {
  const body = edit ? messages.bodyEdit : messages.bodyAdd;
  return (
    <div className="modal-confirm">
      <h1>{intl.formatMessage(messages.header)}</h1>
      <BodyText>{intl.formatMessage(body)}</BodyText>
      <Col xs={12} sm={6}>
        <Button bsStyle="primary" className="pull-right" onClick={onConfirm}>
          {intl.formatMessage(messages.yes)}
        </Button>
      </Col>
      <Col xs={12} sm={6}>
        <Button bsStyle="default" className="pull-left" onClick={onCancel}>
          {intl.formatMessage(messages.no)}
        </Button>
      </Col>
    </div>
  );
};
