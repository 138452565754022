import React, { useEffect, useState } from 'react';
import { Col, Grid, Row, Table } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { curryN } from 'ramda';
import DocumentTitle from 'react-document-title';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  Button,
  Box,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  PlusIcon,
  SettingsIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';

// For some reason, prettier won't let me break this line up and ESLint doesn't like it.
// eslint-disable-next-line max-len
import { PropertyRoleAssignmentModal } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';

import ElementWithPermissions from '../../components/ElementWithPermissions';
import ColumnHeader from '../../components/Common/columnHeader';
import RoleDetails from './RoleDetails';
import messages from './messages';
import PaginationFooter from '../../components/PaginationFooter';
import { getAllRoles } from './utils';
import { promptToaster } from '../App/actions';
import type {
  GlobalState,
  OrderValue,
  PaginationMeta,
  Role,
} from '../App/types';
import { useConfigurePropertyRoleAssignments } from './hooks';

type StateProps = {
  state: Object,
  meta: PaginationMeta,
  columnOrder: {
    name: string,
    description: string,
    roleType: string,
  },
  locale: string,
  currentSorting: OrderValue,
  organizationId: string,
};

type InjectedProps = {
  actions: Object,
  intl: any,
  history: Object,
  locale: string,
};

export const ManageRolesPage = (props: StateProps & InjectedProps) => {
  const flags = useFlags();
  const { actions, history, intl, locale, organizationId } = props;

  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [roles, setRoles] = useState([]);
  const [meta, setMeta] = useState({
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  });
  const [columnOrder, setColumnOrder] = useState({
    name: 'sortable',
    description: 'sortable',
    roleType: 'sortable',
  });
  const [currentSorting, setCurrentSorting] = useState({
    fieldName: '',
    order: '',
  });
  const {
    open,
    openModal,
    closeModal,
    orgRoleOptions,
    handleSave,
    initialPropertyRoles,
    propertyRoles,
    setPropertyRoles,
    isValid,
    errors,
  } = useConfigurePropertyRoleAssignments({
    organizationId,
  });

  useEffect(() => {
    onPageChange(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentSorting]);

  const getRoles = (nextPage) => {
    getAllRoles({
      organizationId,
      nextPage,
      limit,
      currentSorting,
      setMeta,
      setRoles,
      promptToaster: actions.promptToaster,
    });
  };

  const onPageChange = (nextPage: number) => {
    if (!window.matchMedia('(min-width: 767px)').matches) {
      setLimit(10);
    }
    getRoles(nextPage);
    setCurrentPage(nextPage);
  };

  const handleAddRoleClick = () => {
    history.push('/add-role');
  };

  const handleOrderClick = (field: string) => {
    const columnOrderVar = columnOrder[field];
    const order = columnOrderVar === 'ascending' ? 'DESC' : 'ASC';
    const icon =
      columnOrderVar === 'sortable'
        ? 'ascending'
        : columnOrderVar === 'ascending'
        ? 'descending'
        : 'ascending';
    const newColumnOrder = { [field]: icon };
    setColumnOrder({ ...columnOrder, ...newColumnOrder });
    setCurrentSorting({ fieldName: field, order });
  };

  const getOrder = (fieldName: string) => {
    return fieldName === currentSorting.fieldName ? currentSorting.order : '';
  };

  const onViewRole = (role: Role) => {
    const id = role.id || '';
    history.push(`edit-role/${id}`);
  };

  const onOrderClickCurried = curryN(2, handleOrderClick);
  const onViewRoleCurried = curryN(2, onViewRole);

  return (
    <DocumentTitle title={intl.formatMessage(messages.title)}>
      <Grid className="bodywrap" fluid>
        <Row className="section-header">
          <Col xs={6}>
            <h1>
              <FormattedMessage {...messages.header} />
            </h1>
          </Col>
          {flags.propertyRoleAssignments ? (
            <Col xs={12} sm={6} className="text-right" style={{ padding: 0 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: {
                    sm: 'flex-start',
                    md: 'flex-end',
                  },
                  gap: 2,
                  paddingRight: 1,
                  paddingLeft: 1.3,
                }}
              >
                <Button
                  variant="primarySubtle"
                  onClick={openModal}
                  startIcon={<SettingsIcon />}
                >
                  <FormattedMessage
                    {...messages.configurePropertyRoleAssignments}
                  />
                </Button>
                <ElementWithPermissions scope={['role-create']}>
                  <Button
                    onClick={handleAddRoleClick}
                    variant="shout"
                    startIcon={<PlusIcon />}
                  >
                    <FormattedMessage {...messages.addRole} />
                  </Button>
                </ElementWithPermissions>
              </Box>
            </Col>
          ) : (
            <Col xs={6} className="text-right">
              <ElementWithPermissions scope={['role-create']}>
                <Button
                  onClick={handleAddRoleClick}
                  variant={'primarySubtle'}
                  startIcon={<PlusIcon />}
                >
                  <FormattedMessage {...messages.addRole} />
                </Button>
              </ElementWithPermissions>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} id="table-row">
            <Table striped className="table-prospects">
              <thead className="table-header hidden-xs">
                <tr>
                  <ColumnHeader
                    columnLabel={intl.formatMessage(messages.roleName)}
                    sortable={true}
                    icon={columnOrder.name}
                    order={getOrder('name')}
                    onOrderClick={onOrderClickCurried('name')}
                  />
                  <ColumnHeader
                    columnLabel={intl.formatMessage(messages.description)}
                    sortable={true}
                    icon={columnOrder.description}
                    order={getOrder('description')}
                    onOrderClick={onOrderClickCurried('description')}
                  />
                  <ColumnHeader
                    columnLabel={intl.formatMessage(messages.roleType)}
                    sortable={true}
                    icon={columnOrder.roleType}
                    order={getOrder('roleType')}
                    onOrderClick={onOrderClickCurried('roleType')}
                  />
                  <ColumnHeader
                    columnLabel={intl.formatMessage(messages.assignedUsers)}
                  />
                  <ColumnHeader
                    columnLabel={intl.formatMessage(messages.view)}
                  />
                </tr>
              </thead>
              <RoleDetails
                roles={roles}
                intl={intl}
                locale={locale}
                onViewRole={onViewRoleCurried}
              />
            </Table>
          </Col>
        </Row>
        {meta.pageCount > 1 && (
          <PaginationFooter
            currentPage={currentPage}
            limit={limit}
            count={meta.count}
            totalCount={meta.totalCount}
            pageCount={meta.pageCount}
            onPageChange={onPageChange}
          />
        )}
        {flags.propertyRoleAssignments && (
          <PropertyRoleAssignmentModal
            isOpen={open}
            handleSave={handleSave}
            close={closeModal}
            roleOptions={orgRoleOptions}
            initialPropertyRoles={initialPropertyRoles}
            propertyRoles={propertyRoles}
            setPropertyRoles={setPropertyRoles}
            isValid={isValid}
            errors={errors}
            intl={intl}
          />
        )}
      </Grid>
    </DocumentTitle>
  );
};

export const mapStateToProps = (state: GlobalState): StateProps => {
  const { languageProvider } = state;
  return {
    property: state?.app?.selectedProperty ?? {},
    organizationId: state?.app?.currentUser?.user?.organizationId ?? '',
    locale: languageProvider.locale,
  };
};

export function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators({ promptToaster }, dispatch),
  };
}

const InjectedManageRoles = injectIntl(ManageRolesPage);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedManageRoles);
