import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { change } from 'redux-form';
import DocumentTitle from 'react-document-title';

import type { GlobalState } from '../App/types';
import type { CreateUserState } from './types';
import * as createUserActions from './actions';
import * as appActions from '../App/actions';
import CreateUserForm from './CreateUserForm';
import messages from './messages';
import confirm from '../../components/ConfirmDialogModal';

type Props = {
  state: CreateUserState,
  organizationId: string,
  isSubmitting: boolean,
};

type InjectedProps = {
  actions: Object,
  intl: any,
  history: any,
};

export class CreateUserPage extends Component<Props & InjectedProps> {
  componentDidMount() {
    this.props.actions.getAllUserStatuses();
    this.props.actions.getAllUserTitles();
    this.props.actions.getAllUserRoles();
    this.props.actions.getAllProperties();
    this.props.appActions.getAllPropertyClasses();
  }

  generateUserStatuses() {
    const userStatuses = this.props.state.userStatuses.map((userStatus) => ({
      value: userStatus.id,
      text: userStatus.name,
      disabled: false,
    }));
    userStatuses.unshift({
      value: 'default',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return userStatuses;
  }

  generateUserTitles() {
    const userTitles = this.props.state.userTitles.map((userTitle) => ({
      value: userTitle.id,
      text: userTitle.name,
      disabled: false,
    }));
    userTitles.unshift({
      value: 'default',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return userTitles;
  }

  generateUserRoles() {
    const userRoles = this.props.state.userRoles.map((userRole) => ({
      value: userRole.id,
      text: userRole.name,
      disabled: false,
    }));
    userRoles.unshift({
      value: 'default',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return userRoles;
  }

  generateProperties() {
    return this.props.state.properties.map((property) => ({
      value: property.id,
      label: property.name,
    }));
  }

  handlePropertiesSelectedError = (newState: boolean) => {
    this.props.actions.showNonePropertiesSelectedError(newState);
  };

  handleSubmit = (user: any) => {
    if (!user.allProperties && user.properties.length === 0) {
      this.handlePropertiesSelectedError(true);
    } else {
      this.props.actions.createUser(user);
      this.handlePropertiesSelectedError(false);
    }
  };

  handleCancel = () => {
    confirm(this.props.intl.formatMessage(messages.cancelConfirmation), {
      intl: this.props.intl,
    }).then(() => {
      this.props.history.replace('/manage-users');
      this.handlePropertiesSelectedError(false);
    });
  };

  handleChange = (property: any) => {
    this.props.actions.setSelectedProperties(property);
  };

  handleAllPropertiesSelection = (val: any) => {
    this.props.reduxFormActions.change('addUser', 'allProperties', val);
    this.props.actions.setUserAllProperties(val);
  };
  handlePropertySelectionChange = (properties: Array<string>) => {
    this.props.actions.setSelectedProperties(properties);
  };
  handlePropertyGroupSelectionChange = (groups: Array<string>) => {
    // filter out predefined values affordable/conventional/commercial

    const hasAffordableGroup = groups.some((gid) => gid === 'AffordableGroup');
    const hasConventionalGroup = groups.some(
      (gid) => gid === 'ConventionalGroup',
    );
    const hasCommercialGroup = groups.some((gid) => gid === 'CommercialGroup');

    const groupIdsToFilterOut = [
      'AffordableGroup',
      'ConventionalGroup',
      'CommercialGroup',
    ];
    const filteredGroups = groups.filter(
      (groupId) => !groupIdsToFilterOut.includes(groupId),
    );
    this.props.reduxFormActions.change(
      'addUser',
      'propertyGroups',
      filteredGroups,
    );
    this.props.reduxFormActions.change(
      'addUser',
      'hasAffordableGroup',
      hasAffordableGroup,
    );
    this.props.reduxFormActions.change(
      'addUser',
      'hasConventionalGroup',
      hasConventionalGroup,
    );
    this.props.reduxFormActions.change(
      'addUser',
      'hasCommercialGroup',
      hasCommercialGroup,
    );
    this.props.reduxFormActions.change(
      'addUser',
      'propertyGroups',
      filteredGroups,
    );
    this.props.actions.setSelectedPropertyGroups(groups);
  };

  toggleMultiselect = (ev: any, value: boolean) => {
    this.props.actions.toggleMultiselect(value);
  };

  render() {
    const userStatuses = this.generateUserStatuses();
    const userTitles = this.generateUserTitles();
    const userRoles = this.generateUserRoles();
    const properties = this.generateProperties();
    const {
      isSubmitting,
      flags,
      organizationId,
      propertyClasses,
      properties: propertyList,
      user: { allProperties },
      selectedPropertyGroups,
    } = this.props;

    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <CreateUserForm
          initialValues={{
            properties: [],
            allProperties: false,
            userStatusId: 'default',
            userTitleId: 'default',
            userRoleId: 'default',
            organizationId,
          }}
          intl={this.props.intl}
          userStatuses={userStatuses}
          userTitles={userTitles}
          userRoles={userRoles}
          properties={properties}
          onSubmit={this.handleSubmit}
          handleCancel={this.handleCancel}
          selectedProperties={this.props.state.selectedProperties}
          hideMultiselect={this.props.state.hideMultiselect}
          toggleMultiselect={this.toggleMultiselect}
          handleMultiselectChange={this.handleChange}
          handlePropertiesSelectedError={this.handlePropertiesSelectedError}
          nonePropertiesSelected={this.props.state.nonePropertiesSelected}
          isSubmitting={isSubmitting}
          organizationId={organizationId}
          propertyClasses={propertyClasses}
          propertyList={propertyList}
          handleAllPropertiesSelection={this.handleAllPropertiesSelection}
          handlePropertySelectionChange={this.handlePropertySelectionChange}
          handlePropertyGroupSelectionChange={
            this.handlePropertyGroupSelectionChange
          }
          allPropertiesSelected={allProperties}
          selectedPropertyGroups={selectedPropertyGroups}
          flags={flags}
        />
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = ({ createUser, app }: GlobalState): Props => {
  return {
    state: createUser,
    organizationId: app.currentUser ? app.currentUser.user.organizationId : '',
    isSubmitting: createUser.isSubmitting,
    propertyClasses: app.propertyClasses,
    properties: createUser.properties,
    user: createUser.user,
    selectedPropertyGroups: createUser.selectedPropertyGroups,
  };
};

export function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(createUserActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    reduxFormActions: bindActionCreators(
      {
        change,
      },
      dispatch,
    ),
  };
}

const InjectedCreateUserPage = injectIntl(withLDConsumer()(CreateUserPage));

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedCreateUserPage);
