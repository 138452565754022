// @flow
import { STATUS, TASK_TYPE } from '../../constants';
import type { Highlight, HighlightColorProps } from '../../types';
import moment from 'moment';

function isDateTheseManyDaysOld(days: number): Function {
  return function (dateBeingChecked): boolean {
    return moment(dateBeingChecked).isBefore(
      moment().add(Math.abs(days) * -1, 'days'),
    );
  };
}

export const getHighlightColor = ({
  checkDate,
  status = STATUS.NONE,
  checkEndDate,
  type = TASK_TYPE.BASIC,
  isRed = 0,
  isLeaseSignedAndNotExecuted = false,
  daysDue,
  workOrderDueDateTaskTabFlag = false,
}: HighlightColorProps): Highlight => {
  const highlight = { red: false, yellow: false, green: false };

  const isDateTwoWeeksOld = isDateTheseManyDaysOld(14);
  const isDateOneDayOld = isDateTheseManyDaysOld(1);
  const isDateThreeDaysOld = isDateTheseManyDaysOld(3);

  if (isLeaseSignedAndNotExecuted) {
    highlight.green = true;
    return highlight;
  }

  if (status === STATUS.INACTIVE) {
    highlight.yellow = true;
    return highlight;
  }

  switch (type) {
    case TASK_TYPE.PENDING_FAS:
      if (checkDate) {
        highlight.red = isDateTwoWeeksOld(checkDate);
      }
      break;
    case TASK_TYPE.WORK_ORDERS:
      if (checkDate) {
        if (workOrderDueDateTaskTabFlag && daysDue) {
          highlight.red = isDateOneDayOld(checkEndDate);
          break;
        }
        highlight.red = isDateThreeDaysOld(checkDate);
      }
      break;
    case TASK_TYPE.PROSPECTS_FOLLOW_UP:
      highlight.red = isRed === 1;
      break;
    default:
      highlight.red = isDateOneDayOld(checkDate ? checkDate : checkEndDate);
  }

  return highlight;
};
