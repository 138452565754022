import { Col, Panel } from 'react-bootstrap';
import styled from 'styled-components';

export const EditLink = styled.a.attrs({
  key: '2',
  className: 'small simple-link',
})`
  font-size: 75%;
  user-select: none;
`;

export const Header = styled(Panel.Heading).attrs({
  bsStyle: 'default',
})``;
export const Title = styled(Panel.Title).attrs({
  className: 'panel-heading',
})`
  &&& {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 0;
  }
`;
export const Divider = styled.div.attrs({
  className: 'divider-centered',
})``;

export const DetailList = styled.ul.attrs({
  className: 'details-list',
})``;

export const EditIcon = styled.i.attrs({
  className: 'et-pencil',
})``;

export const AddIcon = styled.i.attrs({
  className: 'et-plus',
})``;

export const EditColumn = styled(Col).attrs({
  xs: 12,
  sm: 3,
})`
  padding-right: 0px;
`;

export const SubtitlePeopleProfile = styled.h2.attrs({
  className: 'people-profile-left',
})`
  margin-top: 0px;
  margin-bottom: 10px;
  font-weight: 500;
  padding: 0;
`;

export const ProspectNotes = styled.div.attrs({
  className: 'box-prospect-notes',
})``;

export const NotesLabel = styled.span.attrs({
  className: 'noteslabel',
})``;
export const NotesCont = styled.span.attrs({
  className: 'notes-cont',
})``;

export const LastEdit = styled.div`
  padding: 1px 20px;
  color: #98beff;
  background-color: #ebf5ff;
  border: 1px solid #98beff;
  font-size: 14px;
  margin: 12px 0px 25px;
  &&& {
    padding: 1px 20px;
  }
`;
