import { useEffect, useState } from 'react';
import OnlinePaymentsService from '../../services/onlinePaymentsService';

export const useFetchOnlinePayments = ({ organizationId, propertyId }) => {
  const [onlinePayments, setonlinePayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchOnlinePayments = async () => {
      setIsLoading(true);
      const onlinePaymentsService = new OnlinePaymentsService();
      const response = await onlinePaymentsService.getAllOnlinePayments(
        organizationId,
        propertyId,
        abortController.signal,
      );
      setonlinePayments(response);
      setIsLoading(false);
    };
    if (organizationId && propertyId) fetchOnlinePayments();

    return () => abortController.abort();
  }, [organizationId, propertyId]);

  return { onlinePayments, isLoading };
};
