import { useMutation, MutationOptions } from 'react-query';
import useAbortController from '../../hooks/useAbortController';
import ResidentService from '../../services/residentService';

const useTransferLeaseMutation = ({
  organizationId,
  propertyId,
  residentId,
  applicationId,
  userId,
  options,
}: {
  organizationId: string,
  propertyId: string,
  residentId: string,
  applicationId: string,
  userId: string,
  options?: MutationOptions,
}) => {
  const abortControllerOptions = useAbortController();

  return useMutation(
    async (unitId: string) =>
      new ResidentService().startTransferLease(
        organizationId,
        propertyId,
        residentId,
        applicationId,
        unitId,
        userId,
        abortControllerOptions,
      ),
    {
      ...options,
      enabled: Boolean(
        organizationId && propertyId && residentId && applicationId && userId,
      ),
    },
  );
};

export default useTransferLeaseMutation;
