import React from 'react';
import { isEmpty } from 'ramda';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { filterRenewalLeasesStatus } from '../../ManageLeaseExpirations/Filter/filterConfigurations';
import DoughnutCard from '../DoughnutCard';
import messages from './messages';
import { getDate } from '../../../utils/date-helpers';
import useSelectedProperty from '../../../hooks/useSelectedProperty';

const CurrentMonthRenewals = ({
  currentMonthRenewalsData,
  formatMessage,
  dataLoaded,
  fetching,
  history,
}: Object) => {
  const { currentMonthRenewalsKpiDrillDown } = useFlags();
  const { id: selectedPropertyId } = useSelectedProperty();
  const placeholder = formatMessage(messages.noData);
  const inProcessLabel = formatMessage(messages.inProcess);
  const completedLabel = formatMessage(messages.completed);
  const onNTVLabel = formatMessage(messages.onNTV);
  const notStartedLabel = formatMessage(messages.notStarted);
  const labels = [completedLabel, notStartedLabel, inProcessLabel, onNTVLabel];
  const { completed, notStarted, pending, onNtv } = currentMonthRenewalsData;
  const data = [completed, notStarted, pending, onNtv];

  const redirectToManageLeaseExpirations = (
    label: 'completed' | 'inProcess' | 'incomplete' | 'onNotice',
  ) => {
    const renewalStatus = filterRenewalLeasesStatus.find(
      ({ text, textSecondary }) => text === label || textSecondary === label,
    )?.name;

    const [month, year] = getDate().format('MMM-YYYY').split('-');

    const expirationRange = {
      year,
      month,
    };

    if (renewalStatus?.length || !isEmpty(expirationRange)) {
      const searchParams = new URLSearchParams({
        ...expirationRange,
        renewalStatus,
      });
      history.push(
        `/property/${selectedPropertyId}/manage-lease-expirations?${searchParams.toString()}`,
      );
    }
  };

  const getElementAtEvent = (elements) => {
    if (elements.length) {
      const label = elements[0]._model.label;
      redirectToManageLeaseExpirations(label);
    }
  };

  return (
    <DoughnutCard
      data={data}
      labels={labels}
      header={formatMessage(messages.title)}
      dataLoaded={dataLoaded}
      placeholder={placeholder}
      fetching={fetching}
      getElementAtEvent={
        currentMonthRenewalsKpiDrillDown ? getElementAtEvent : undefined
      }
    >
      <div className="row padtop10">
        <div className="col-xs-6 col-sm-6 text-center">
          <span className="status-ball--small status-lightest-blue" />
          <span className="font-10"> {`${pending} ${inProcessLabel}`}</span>
        </div>
        <div className="col-xs-6 col-sm-6 text-center">
          <span className="status-ball--small status-darker-blue" />
          <span className="font-10"> {`${completed} ${completedLabel}`}</span>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6 col-sm-6 text-center">
          <span className="status-ball--small status-light-gray" />
          <span className="font-10"> {`${onNtv} ${onNTVLabel}`}</span>
        </div>
        <div className="col-xs-6 col-sm-6 text-center">
          <span className="status-ball--small status-lighter-blue" />
          <span className="font-10"> {`${notStarted} ${notStartedLabel}`}</span>
        </div>
      </div>
    </DoughnutCard>
  );
};

export default CurrentMonthRenewals;
