import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.Overview.Occupancy.Title',
    defaultMessage: 'Occupancy',
  },
  downUnits: {
    id: 'App.Overview.Occupancy.DownUnits',
    defaultMessage: 'Down Units: ',
  },
});

export default messages;
