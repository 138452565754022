import { ExitConfirmationModal } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { confirmable, createConfirmation } from 'react-confirm';

function ExitConfirmationModalWrapper({
  show,
  proceed,
  dismiss,
  cancel,
  confirmation,
}) {
  return (
    <ExitConfirmationModal
      open={show}
      onClose={dismiss}
      onConfirm={proceed}
      message={confirmation}
    />
  );
}

const confirm = createConfirmation(confirmable(ExitConfirmationModalWrapper));

export default function (confirmation: string, options: {} = {}) {
  return confirm({ confirmation, options });
}
