import React from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  Field,
  getFormSyncErrors,
  FieldArray,
} from 'redux-form';
import {
  renderSelectField,
  renderDateField,
} from '../../../utils/redux-form-helper';
import validate from './validate';
import messages from './messages';
import { ElementWithPermissions } from '../../../components/ElementWithPermissions';
import type { UserPermission } from '../../App/types';
import ProRatedAmounts from './ProRatedAmounts';

const yesNoMaybe = [
  { value: 'choose', text: 'Choose' },
  { value: true, text: 'Yes' },
  { value: false, text: 'No' },
];

type Props = {
  intl: Object,
  cancel: Function,
  customerName: string,
  unitNumber: string,
  moveOutClick: Function,
  handleSubmit: Function,
  onSubmit: Function,
  valid: boolean,
  permissions: Array<UserPermission>,
  openFiscalPeriod: Object,
  isFirstFiscalPeriod: boolean,
  moveOutDateStatus: Function,
  formSyncErrors: any,
  monthlyTransactions: Array<any>,
  moveOutDate: any,
  change: Function,
  handleMoveOutDateUpdate: Function,
  isAllCommercial: boolean,
  moveInApplyFullAmountToProRate: boolean,
  moveOutProratedRent: boolean,
  roundProratedRents: boolean,
};

export const ResidentMoveOut = ({
  cancel,
  intl,
  customerName,
  unitNumber,
  moveOutClick,
  onSubmit,
  handleSubmit,
  valid,
  permissions,
  formSyncErrors,
  moveOutDateStatus,
  monthlyTransactions,
  moveOutDate,
  change,
  handleMoveOutDateUpdate,
  isAllCommercial,
  moveInApplyFullAmountToProRate,
  moveOutProratedRent,
  roundProratedRents,
}: Props) => {
  return (
    <div
      className="resident-convert-form container-fluid"
      data-test="resident-move-out-form"
    >
      <div className="row">
        <div className="col-xs-6 col-sm-12">
          <a className="btn-text" onClick={cancel}>
            <i className="et-chevron-left" />
            {isAllCommercial
              ? intl.formatMessage(messages.returnToTenantProfile)
              : intl.formatMessage(messages.returnToResidentProfile)}
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <h1>
            {isAllCommercial
              ? intl.formatMessage(messages.confirmTitleTenant)
              : intl.formatMessage(messages.confirmTitle)}{' '}
            {customerName}
          </h1>
          <h4>
            {intl.formatMessage(messages.unitNumber)}: {unitNumber}
          </h4>
        </div>
      </div>
      <div className="form-container padtop20 padbottom30">
        <div className="row">
          <div className="col-xs-12 col-sm-7">
            <h3>{intl.formatMessage(messages.stepsCompleted)}:</h3>
            <div className="checklist-small">
              <ul>
                <li>
                  <div className="item-property">
                    {intl.formatMessage(messages.noticeToVacateEntered)}
                  </div>
                  <div className="item-value text-right">
                    {intl.formatMessage(messages.entered)}
                    <span className="status status-ball status-green" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-sm-5">
            <h3>{intl.formatMessage(messages.moveOutActionsCompleted)}:</h3>
            <div className="row">
              <div className="col-md-8 col-xs-12 padtop5">
                <label>
                  {isAllCommercial
                    ? intl.formatMessage(messages.paidAmountDueTenant)
                    : intl.formatMessage(messages.paidAmountDue)}
                </label>
              </div>
              <div className="col-md-4 col-xs-12">
                <Field
                  name="residentPaidAmtDueAtMoveOut"
                  component={renderSelectField}
                  options={yesNoMaybe}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-xs-12 padtop5">
                <label>{intl.formatMessage(messages.turnedInKeys)}</label>
              </div>
              <div className="col-md-4 col-xs-12">
                <Field
                  name="turnedInKeys"
                  component={renderSelectField}
                  options={yesNoMaybe}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-xs-12 padtop5">
                <label>
                  {intl.formatMessage(messages.moveOutChecklistCompleted)}
                </label>
              </div>
              <div className="col-md-4 col-xs-12">
                <div className="form-group">
                  <Field
                    name="moveOutChecklistCompleted"
                    component={renderSelectField}
                    options={yesNoMaybe}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-7">
            <h3> {intl.formatMessage(messages.moveOutDate)}:</h3>
            <div className="checklist-small no-margin-bottom">
              <ul>
                <li>
                  <div className="item-property">
                    <div className="form-group">
                      <Field
                        name="moveOutDate"
                        component={renderDateField}
                        dateFormat="MM/DD/YYYY"
                        bsSize="lg"
                        onChange={handleMoveOutDateUpdate}
                      />
                    </div>
                  </div>
                  <div className="item-value text-right">
                    {moveOutDateStatus(formSyncErrors)
                      ? moveOutDateStatus(formSyncErrors).statusText
                      : ''}
                    <span
                      className={
                        moveOutDateStatus(formSyncErrors)
                          ? moveOutDateStatus(formSyncErrors).valid
                          : false
                      }
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-sm-7">
            <p>
              <strong>
                {intl.formatMessage(messages.proRateAmountDateNote)}
              </strong>
            </p>
          </div>
        </div>
        {moveOutProratedRent && (
          <div className="row padtop15">
            <div className="col-xs-12 col-lg-11">
              <h3>
                {intl.formatMessage(messages.proRateAmountsToLedgerHeader, {
                  type: moveInApplyFullAmountToProRate
                    ? intl.formatMessage(messages.full)
                    : intl.formatMessage(messages.proRate),
                })}
              </h3>
              <div className="table-scroll table-units-container">
                <FieldArray
                  name="proRatedTransactions"
                  component={ProRatedAmounts}
                  monthlyTransactions={monthlyTransactions}
                  moveOutDate={moveOutDate}
                  intl={intl}
                  moveInApplyFullAmountToProRate={
                    moveInApplyFullAmountToProRate
                  }
                  roundProratedRents={roundProratedRents}
                />
              </div>
              {!moveInApplyFullAmountToProRate && (
                <>
                  <p className="padtop20">
                    <strong>{intl.formatMessage(messages.note)}: </strong>
                    {intl.formatMessage(messages.proRateNote)}
                  </p>
                  {roundProratedRents && (
                    <p>
                      <strong>*</strong>
                      {intl.formatMessage(messages.roundProratedAmounts)}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="row padtop20">
        <div className="col-xs-6">
          <button className="btn btn-default pull-right" onClick={cancel}>
            {intl.formatMessage(messages.cancel)}
          </button>
        </div>
        <div className="col-xs-6">
          <ElementWithPermissions
            userPermissions={permissions}
            scope={['resident-move-out']}
          >
            <button
              className="btn btn-primary pull-left"
              onClick={() => moveOutClick(handleSubmit(onSubmit))}
              disabled={!valid}
            >
              {isAllCommercial
                ? intl.formatMessage(messages.moveTenantOutOfThisUnit)
                : intl.formatMessage(messages.moveResidentOutOfThisUnit)}
            </button>
          </ElementWithPermissions>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (
  {
    form,
    app: {
      currentUser: { permissions },
      selectedProperty,
    },
  }: Object,
  { initialValues }: Object,
): Object => {
  const selector = formValueSelector('processMoveOut');
  return {
    initialValues,
    permissions,
    moveOutDate: selector({ form }, 'moveOutDate'),
    formSyncErrors: getFormSyncErrors('processMoveOut')({ form }),
    isAllCommercial: selectedProperty.hasCommercialFloorPlans === 'ALL',
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'processMoveOut',
    validate,
    enableReinitialize: true,
  })(ResidentMoveOut),
);
