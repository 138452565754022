import React, { useState } from 'react';
import messages from './messages';
import { HandyManIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { HouseholdSection } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import usePermissionsHook from '../../components/ElementWithPermissions/usePermissionsHook';
import WorkOrderHouseholdPreferenceModal from '../../components/WorkOrderHouseholdPreferences';

const WorkOrderPreferences = ({ intl, unitId, householdId }) => {
  const [workOrderPreferencesModal, setWorkOrderPreferencesModal] =
    useState(false);
  const resetWorkOrderPreferencesModal = () =>
    setWorkOrderPreferencesModal(false);
  const editWorkOrderPreferences = () => {
    setWorkOrderPreferencesModal(true);
  };
  const hasResidentEditPermissions = usePermissionsHook({
    scope: ['resident-edit'],
  });
  return (
    <>
      <HouseholdSection
        title={intl.formatMessage(messages.workOrderPreferences)}
        Icon={HandyManIcon}
        buttonProps={
          hasResidentEditPermissions
            ? {
                onClick: editWorkOrderPreferences,
                buttonVariant: 'edit',
              }
            : undefined
        }
      />
      {unitId && (
        <WorkOrderHouseholdPreferenceModal
          show={workOrderPreferencesModal}
          onHide={resetWorkOrderPreferencesModal}
          profileContext={{ householdId, unitId }}
        />
      )}
    </>
  );
};

export default WorkOrderPreferences;
