import { validateRequired, validateZipCode } from '../../../utils/validations';
import { pathOr } from 'ramda';

import formMessages from '../../../containers/ShortLeaseApplication/messages';

import type { EmployerInformation } from '../../../containers/App/types';

const warn = (
  values: EmployerInformation,
  status: any,
  { intl, employmentStatuses }: Object,
) => {
  const warnings = {};
  warnings.address = {};
  const selectedStatus = employmentStatuses.find(
    (currentStatus) => currentStatus.value === status,
  );
  const statusText = pathOr('', ['text'], selectedStatus);

  if (statusText === 'Employed') {
    if (!validateRequired('position', values)) {
      warnings.position = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }

    if (!validateRequired('annualSalary', values)) {
      warnings.annualSalary = intl.formatMessage(formMessages.salaryRequired);
    }

    if (!validateRequired('from', values)) {
      warnings.from = intl.formatMessage(formMessages.requiredForCompletion);
    }

    if (!validateRequired('employerName', values)) {
      warnings.employerName = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }

    if (!validateRequired('phoneNumber', values)) {
      warnings.phoneNumber = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }

    if (!validateRequired('supervisorName', values)) {
      warnings.supervisorName = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }

    if (!validateRequired('street', values.address)) {
      warnings.address.street = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }

    if (!validateRequired('city', values.address)) {
      warnings.address.city = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }

    if (!validateRequired('state', values.address)) {
      warnings.address.state = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }

    if (!validateRequired('zipCode', values.address)) {
      warnings.address.zipCode = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }
  }
  if (values?.address?.zipCode && !validateZipCode(values.address.zipCode)) {
    warnings.address.zipCode = intl.formatMessage(formMessages.invalidValue);
  }
  if (
    Object.keys(warnings).length === 1 &&
    Object.keys(warnings.address).length === 0
  ) {
    return {};
  }
  return warnings;
};

export default warn;
