import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dateInPast: {
    id: 'App.ManagePaymentBatch.DateInPast',
    defaultMessage:
      'Date cannot be before the start date of the current fiscal period',
  },
  dateInFuture: {
    id: 'App.ManagePaymentBatch.DateInFuture',
    defaultMessage: 'Date cannot be in the future',
  },
  invalidDate: {
    id: 'App.ManagePaymentBatch.InvalidDate',
    defaultMessage: 'Invalid date value',
  },
});

export default messages;
