import { defineMessages } from 'react-intl';

const messages = defineMessages({
  value: {
    id: 'App.PaginationItemsCounter.Value',
    defaultMessage: 'Viewing items {start} - {end} of {total} results'
  }
});

export default messages;
