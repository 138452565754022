import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { ArrowBackIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import messages from '../ManageUnitAvailability/messages';
import useSearchParams from './hooks.useSearchParams';
import useHistory from '../../hooks/useHistory';

const GoBackLinks = ({ applicantToAssignUnit }) => {
  const history = useHistory();
  const { isTransfer, applicationId, prospectId } = useSearchParams();

  const { message, onClick } = useMemo(() => {
    let message;

    const onClick = history.goBack;

    if (prospectId) {
      message = messages.goBackProspect;
    }

    if (isTransfer) {
      message = messages.goBackResident;
    }

    if (
      !isTransfer &&
      applicationId &&
      Object.keys(applicantToAssignUnit ?? {})?.length
    ) {
      message = messages.goBack;
    }

    return {
      message,
      onClick,
    };
  }, [applicantToAssignUnit, applicationId, history, isTransfer, prospectId]);

  return onClick && message ? (
    <Button onClick={onClick} startIcon={<ArrowBackIcon />} variant={'text'}>
      <FormattedMessage {...message} />
    </Button>
  ) : null;
};

export default GoBackLinks;
