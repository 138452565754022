import { type } from 'ramda';

import messages from './messages';
import moment from 'moment';

const validate = (
  values: Object = {},
  { steps, leaseStartDate, openFiscalPeriod, intl, leaseMoveInDate }: Object,
) => {
  const errors = {};

  /**
   * Check for any `valid === false` steps.
   */
  const errorSteps = steps.filter((s) => s.valid === false);
  if (errorSteps.length > 0) {
    errors._error = 'error';
  }

  let actualMoveInDate = moment(values.actualMoveInDate);
  if (!actualMoveInDate.isValid() || type(values.startDate) === 'String') {
    errors.actualMoveInDate = intl.formatMessage(messages.invalidDate);
  } else {
    actualMoveInDate = actualMoveInDate.startOf('day');
  }

  const moveInDateFromLease = moment(leaseMoveInDate);
  if (
    !errors.actualMoveInDate &&
    !moveInDateFromLease.isSame(actualMoveInDate, 'day')
  ) {
    errors.actualMoveInDate = true;
  }

  if (actualMoveInDate.toDate() > Date.now()) {
    errors.actualMoveInDate = intl.formatMessage(messages.moveInDateInFuture);
  }

  if (
    !errors.actualMoveInDate &&
    actualMoveInDate.unix() <
      moment().subtract(1, 'years').startOf('day').unix()
  ) {
    errors.actualMoveInDate = intl.formatMessage(messages.moveInDateInPastYear);
  }

  if (
    !errors.actualMoveInDate &&
    actualMoveInDate
      .startOf('day')
      .isBefore(moment(values.previousLeaseMoveOutDate).startOf('day'))
  ) {
    errors.actualMoveInDate = intl.formatMessage(messages.moveInDateOverlap);
  }

  const startDate = moment(leaseStartDate);
  if (startDate.isValid()) {
    startDate.startOf('day');
  }

  return errors;
};

export default validate;
