export const PRORATE_METHODS = {
  YES_ALWAYS: 'Yes - Always Prorate',
  NO_NEVER: 'No - Never Prorate',
};

export const PRORATE_PERIOD_TYPES = {
  PAST_MONTH: 'past',
  CURRENT_MONTH: 'current',
  FUTURE_MONTH: 'future',
};

export const HEADER_NOTES = {
  // eslint-disable-next-line max-len
  past: 'Lease Renewal Start Date is in a prior month. Fortress will post the "Adjustments to be posted" amount displayed below for this month when the Renewal is completed. The Total Prorate will display the full month transaction amounts for the renewal lease. Any prior month adjustments must be made manually to the ledger.',
  current:
    // eslint-disable-next-line max-len
    'Lease Renewal Start Date is in the current month. Fortress will post the prorated "Adjustments to be posted" amount displayed below for this month when the Renewal is completed.',
  future:
    // eslint-disable-next-line max-len
    'Lease Renewal is in a future month. Fortress will post the "Total Prorate" amounts below in the month the renewal starts.',
};

export const FOOTER_NOTE =
  'A Renewal Pro-rate letter will be created and uploaded to the documents tab when this renewal is completed.';

export const HEADER_ROWS = [
  {
    headers: [
      {
        id: '',
        title: 'Charge/Credit',
        props: {
          colSpan: '5',
        },
        classes: 'header-darker',
        order: null,
      },
      {
        id: '',
        title: 'Current Lease',
        order: null,
        props: {
          colSpan: '3',
        },
      },
      {
        id: '',
        title: 'Renewal Lease',
        classes: 'header-lighter',
        order: null,
        props: {
          colSpan: '3',
        },
      },
      {
        id: '',
        title: 'Do Not Prorate',
        classes: 'header-darker--var-b centered-vertical',
        order: null,
        props: {
          colSpan: '1',
          rowSpan: '2',
          className: 'align-middle',
        },
      },
    ],
  },
  {
    headers: [
      {
        id: 'transactionCode',
        title: 'Transaction Code',
        classes: 'header-darker',
        order: null,
      },
      {
        id: 'transactionType',
        title: 'Charge/Credit',
        classes: 'header-darker',
        order: null,
      },
      {
        id: 'totalCharged',
        title: 'Total Charged',
        classes: 'header-darker',
        order: null,
      },
      {
        id: 'totalProrate',
        title: 'Total Prorate',
        classes: 'header-darker',
        order: null,
      },
      {
        id: 'adjustmentsToPost',
        title: 'Adjustment to Post',
        classes: 'header-darker',
        order: null,
      },

      {
        id: 'currentLeaseMonthlyAmount',
        title: 'Monthly Amount',
        order: null,
      },
      {
        id: 'currentLeaseProrateDates',
        title: 'Prorate Dates',
        order: null,
      },
      {
        id: 'currentLeaseProrate',
        title: 'Prorate',
        order: null,
      },

      {
        id: 'renewalLeaseMonthlyAmount',
        title: 'Monthly Amount',
        classes: 'header-lighter',
        order: null,
      },
      {
        id: 'renewalLeaseProrateDates',
        title: 'Prorate Dates',
        classes: 'header-lighter',
        order: null,
      },
      {
        id: 'renewalLeaseProrate',
        title: 'Prorate',
        classes: 'header-lighter',
        order: null,
      },
    ],
  },
];
