import { useEffect, useMemo, useState } from 'react';
import LedgerService from '../../services/ledgerService';
import MiscTransactionsService from '../../services/miscTransactionsService';
import TransactionTypeService from '../../services/transactionTypesService';
import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import type {
  ChangeEventHandler,
  ChangePayload,
} from '../ManageRentRoll/FilterControls/types';

const MULTI_SELECT = 'multi-select';
const DATE = 'date';
const AMOUNT = 'amount';
const SEARCH = 'search';

export const useFetchTransactions = ({
  selectedProperty,
  customerId,
  filterReversed,
  filters,
  refreshTransactionTable,
  subjournal,
  currentLedger,
  isDateRangeChecked,
  isAmountRangeChecked,
}) => {
  const [transactions, setTransactions] = useState({ results: [], meta: {} });
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [queryfilters, setQueryFilters] = useState(null);
  const limit = 15;

  useEffect(() => {
    const abortController = new AbortController();
    const options = { signal: abortController.signal };
    const fetchTransactionFilterOptions = async () => {
      setTransactionsLoading(true);
      const { id, organizationId } = selectedProperty;
      const ledgerService = new LedgerService();
      const results = await ledgerService.getTransactionsFiltered(
        organizationId,
        id,
        page,
        currentLedger,
        customerId,
        filterReversed,
        filters,
        options,
        subjournal,
        isDateRangeChecked,
        isAmountRangeChecked,
        limit,
      );
      setTransactions(results);
      setTransactionsLoading(false);
      setQueryFilters(ledgerService.filterQueryString);
    };

    fetchTransactionFilterOptions();

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedProperty,
    customerId,
    filterReversed,
    filters,
    page,
    refreshTransactionTable,
    subjournal,
    currentLedger,
  ]);

  return [
    transactions,
    transactionsLoading,
    page,
    setPage,
    limit,
    queryfilters,
  ];
};

export const useFetchLastPeriod = ({
  selectedProperty,
  customerId,
  filterReversed,
  refreshTransactionTable,
  subjournal,
  currentLedger,
  isDateRangeChecked,
  isAmountRangeChecked,
}) => {
  const [lastPeriod, setLastPeriod] = useState(null);
  const limit = 15;
  const filters = {
    periods: [],
    exactDate: null,
    fromDate: null,
    toDate: null,
    exactAmount: '',
    fromAmount: '',
    toAmount: '',
    transactionCodes: [],
    transactionTypes: [],
    searchTerm: '',
  };

  useEffect(() => {
    const abortController = new AbortController();
    const options = { signal: abortController.signal };
    const fetchTransactionFilterOptions = async () => {
      const { id, organizationId } = selectedProperty;
      const ledgerService = new LedgerService();
      const results = await ledgerService.getTransactionsFiltered(
        organizationId,
        id,
        1,
        currentLedger,
        customerId,
        filterReversed,
        filters,
        options,
        subjournal,
        isDateRangeChecked,
        isAmountRangeChecked,
        limit,
      );

      if (results?.meta?.pageCount === 1 || results?.meta?.pageCount === 0) {
        setLastPeriod(
          results?.results?.slice(-1)[0]?.propertyFiscalPeriod?.period,
        );
      } else {
        const lastPageResults = await ledgerService.getTransactionsFiltered(
          organizationId,
          id,
          results?.meta?.pageCount,
          currentLedger,
          customerId,
          filterReversed,
          filters,
          options,
          subjournal,
          isDateRangeChecked,
          isAmountRangeChecked,
          limit,
        );
        setLastPeriod(
          lastPageResults?.results?.slice(-1)[0]?.propertyFiscalPeriod?.period,
        );
      }
    };

    fetchTransactionFilterOptions();

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTransactionTable]);
  return [lastPeriod];
};

export const useIsFilterActive = ({ allTransactions, appliedFilters }) => {
  const [isFilterActive, setIsFilterActive] = useState(false);

  useEffect(() => {
    if (allTransactions?.results?.length < 1 && appliedFilters?.length < 1) {
      setIsFilterActive(false);
    }
  }, [allTransactions, appliedFilters]);

  return {
    isFilterActive,
    setIsFilterActive,
  };
};

export const useTransactionFilters = () => {
  const DEFAULT_FILTERS = {
    periods: [],
    exactDate: null,
    fromDate: null,
    toDate: null,
    exactAmount: '',
    fromAmount: '',
    toAmount: '',
    transactionCodes: [],
    transactionTypes: [],
    searchTerm: '',
  };

  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const handleFilterControlsChange: ChangeEventHandler = (
    key: string,
    payload: ChangePayload,
  ) => {
    if (payload.type === MULTI_SELECT) {
      setFilters({
        ...filters,
        [key]: payload.values,
      });
    }
    if (payload.type === DATE) {
      setFilters({
        ...filters,
        [key]: payload.value,
      });
    }
    if (payload.type === AMOUNT) {
      setFilters({
        ...filters,
        [key]: payload.value,
      });
    }
    if (payload.type === SEARCH) {
      setFilters({
        ...filters,
        [key]: payload.value,
      });
    }
  };

  const clearTransactionFilters = () => {
    setFilters(DEFAULT_FILTERS);
  };

  const clearDateRange = () => {
    setFilters({
      ...filters,
      toDate: undefined,
      fromDate: undefined,
    });
  };

  const clearExactDate = () => {
    setFilters({
      ...filters,
      exactDate: undefined,
    });
  };

  const clearAmountRange = () => {
    setFilters({
      ...filters,
      toAmount: undefined,
      fromAmount: undefined,
    });
  };

  const clearExactAmount = () => {
    setFilters({
      ...filters,
      exactAmount: undefined,
    });
  };

  const clearSearch = () => {
    setFilters({
      ...filters,
      searchTerm: undefined,
    });
  };

  const isFiltersPopulated =
    filters.exactDate ||
    filters.fromDate ||
    filters.toDate ||
    filters.exactAmount ||
    filters.fromAmount ||
    filters.toAmount ||
    filters.searchTerm ||
    filters.periods.length > 0 ||
    filters.transactionCodes.length > 0 ||
    filters.transactionTypes.length > 0;

  return {
    filters,
    handleFilterControlsChange,
    clearTransactionFilters,
    clearDateRange,
    clearExactDate,
    clearAmountRange,
    clearExactAmount,
    clearSearch,
    isFiltersPopulated,
  };
};

export const useAppliedFilters = (
  filters: FilterValues,
  handleFilterControlsChange: ChangeEventHandler,
  clearTransactionFilters: Function,
  clearDateRange: Function,
  clearExactDate: Function,
  clearAmountRange: Function,
  clearExactAmount: Function,
  clearSearch: Function,
  setResetSearch: Function,
  resetSearch: boolean,
  transactionFilterOptions: Object,
) => {
  const appliedFilters = useMemo(
    () => mapFilterValuesToAppliedFilters(filters, transactionFilterOptions),
    [filters, transactionFilterOptions],
  );

  const handleRemoveFilter = ({ meta }) => {
    if (meta.isDateRangeFilter) {
      clearExactDate();
      clearDateRange();
      return;
    }

    if (meta.isExactDateFilter) {
      clearExactDate();
      return;
    }

    if (meta.isAmountRangeFilter) {
      clearExactAmount();
      clearAmountRange();
      return;
    }

    if (meta.isExactAmountFilter) {
      clearExactAmount();
      return;
    }

    if (meta.isSearchFilter) {
      setResetSearch(!resetSearch);
      clearSearch();
      return;
    }

    const { key, value } = meta;

    if (!filters[key]) return;

    const newFilters = filters[key].filter(
      (val) =>
        val !==
        transactionFilterOptions[key].find(
          (filterOption) => filterOption.text === value,
        ).value,
    );

    handleFilterControlsChange(key, {
      type: 'multi-select',
      values: newFilters,
    });
  };

  const handleClearAll = () => {
    setResetSearch(!resetSearch);
    clearTransactionFilters();
  };

  return {
    appliedFilters,
    handleRemoveFilter,
    handleClearAll,
  };
};

const defaultOptionsState = {
  periods: [],
  transactionCodes: [],
  transactionTypes: [],
};

export const useAsyncTransactionOptions = ({
  selectedProperty,
  currentLedger,
  customerId,
  filterReversed,
  limit,
  filters,
  allTransactions,
}) => {
  const [transactionOptions, setTransactionOptions] =
    useState(defaultOptionsState);
  const [transactionFilterOptionsLoaded, setTransactionFilterOptionsLoaded] =
    useState(true);

  useEffect(() => {
    const abortController = new AbortController();
    const { id, organizationId } = selectedProperty;
    const fetchTransactionOptions = async () => {
      setTransactionFilterOptionsLoaded(false);
      const options = { signal: abortController.signal };
      const transactionTypeService = new TransactionTypeService();
      const ledgerServiceForTransactionCodes = new LedgerService();
      const miscTransactionsServiceForPeriods = new MiscTransactionsService();
      const [transactionTypes, transactionCodes, periods] = await Promise.all([
        transactionTypeService.getTransactionTypes(),
        ledgerServiceForTransactionCodes.getTransactionCodesForCustomer(
          organizationId,
          id,
          currentLedger,
          customerId,
          filterReversed,
          limit,
          options,
        ),
        miscTransactionsServiceForPeriods.getAllPeriods(organizationId, id),
      ]);

      const parsedTypes = transactionTypes
        .filter((type) => {
          return type.name !== 'Misc Charge' && type.name !== 'Other';
        })
        .map((type) => {
          return { value: type.id, text: type.name };
        });

      let parsedCodes = [];

      for (const uuid of Object.keys(transactionCodes)) {
        parsedCodes.push({ value: uuid, text: transactionCodes[uuid] });
      }

      const parsedPeriods = periods.map((period) => {
        return {
          value: period.id,
          text: period.periodName,
        };
      });

      setTransactionOptions({
        periods: parsedPeriods,
        transactionCodes: parsedCodes,
        transactionTypes: parsedTypes,
      });
      setTransactionFilterOptionsLoaded(true);
    };
    fetchTransactionOptions();

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty, currentLedger, filters, allTransactions]);
  return [transactionOptions, transactionFilterOptionsLoaded];
};

const toAppliedFilter = R.curryN(
  3,
  (key: string, textFn: Function, value: any) => ({
    id: uuid(),
    text: textFn?.(value) || value,
    meta: {
      key,
      value,
    },
  }),
);

const mapFilterValuesToAppliedFilters = (
  filterValues: FilterValues,
  options: string[],
): AppliedFilters => {
  if (!filterValues) return [];

  const preCodeFilters = filterValues.transactionCodes.map((codeId) => {
    const codeObject = options.transactionCodes.find(
      (code) => code.value === codeId,
    );
    return codeObject?.text;
  });
  const transactionCodeFilters = preCodeFilters.map(
    toAppliedFilter('transactionCodes', R.identity),
  );

  const preTypeFilters = filterValues.transactionTypes.map((typeId) => {
    const typeObject = options.transactionTypes.find(
      (type) => type.value === typeId,
    );
    return typeObject?.text;
  });
  const transactionTypeFilters = preTypeFilters.map(
    toAppliedFilter('transactionTypes', R.identity),
  );

  const prePeriodFilters = filterValues.periods.map((periodId) => {
    const periodObject = options.periods.find(
      (period) => period.value === periodId,
    );
    return periodObject?.text;
  });
  const periodFilters = prePeriodFilters.map(
    toAppliedFilter('periods', R.identity),
  );

  function createDateFilter() {
    if (
      filterValues.exactDate &&
      filterValues.toDate &&
      !filterValues.fromDate
    ) {
      return dateRangeToAppliedFilter(
        filterValues.exactDate,
        filterValues.toDate,
      );
    }
    if (filterValues.fromDate && filterValues.toDate) {
      return [];
    }
    return exactDateToAppliedFilter(filterValues.exactDate);
  }

  const exactDateFilter = createDateFilter();

  const dateRangeFilter = dateRangeToAppliedFilter(
    filterValues.fromDate,
    filterValues.toDate,
  );

  function createAmountFilter() {
    if (
      filterValues.exactAmount &&
      filterValues.toAmount &&
      !filterValues.fromAmount
    ) {
      return amountRangeToAppliedFilter(
        filterValues.exactAmount,
        filterValues.toAmount,
      );
    }
    if (filterValues.fromAmount && filterValues.toAmount) {
      return [];
    }
    return exactAmountToAppliedFilter(filterValues.exactAmount);
  }

  const exactAmountFilter = createAmountFilter();

  const amountRangeFilter = amountRangeToAppliedFilter(
    filterValues.fromAmount,
    filterValues.toAmount,
  );

  const searchFilter = searchTermToAppliedFilter(filterValues.searchTerm);

  return [
    ...transactionCodeFilters,
    ...transactionTypeFilters,
    ...periodFilters,
    ...dateRangeFilter,
    ...exactDateFilter,
    ...amountRangeFilter,
    ...exactAmountFilter,
    ...searchFilter,
  ];
};

const exactDateToAppliedFilter = (exactDate: ?string) => {
  const DATE_FORMAT = 'MM/DD/YYYY';

  if (!exactDate) return [];

  return [
    {
      id: uuid(),
      text: `${moment(exactDate).format(DATE_FORMAT)}`,
      meta: { isExactDateFilter: true },
    },
  ];
};

const dateRangeToAppliedFilter = (fromDateStr: ?string, toDateStr: ?string) => {
  const DATE_FORMAT = 'MM/DD/YYYY';

  if (!fromDateStr || !toDateStr) return [];

  const from = moment(fromDateStr);
  const to = moment(toDateStr);

  if (!from.isValid() || !to.isValid() || from.isAfter(to, 'day')) return [];

  return [
    {
      id: uuid(),
      text: `${from.format(DATE_FORMAT)} - ${to.format(DATE_FORMAT)}`,
      meta: { isDateRangeFilter: true },
    },
  ];
};

const exactAmountToAppliedFilter = (exactAmount: ?string) => {
  if (!exactAmount) return [];

  return [
    {
      id: uuid(),
      text: `$${exactAmount}`,
      meta: { isExactAmountFilter: true },
    },
  ];
};

const amountRangeToAppliedFilter = (
  fromAmountStr: ?string,
  toAmountStr: ?string,
) => {
  if (!fromAmountStr || !toAmountStr) return [];

  return [
    {
      id: uuid(),
      text: `$${fromAmountStr} - $${toAmountStr}`,
      meta: { isAmountRangeFilter: true },
    },
  ];
};

const searchTermToAppliedFilter = (searchTerm: ?string) => {
  if (!searchTerm) return [];

  return [
    {
      id: uuid(),
      text: `${searchTerm}`,
      meta: { isSearchFilter: true },
    },
  ];
};
