import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { toastr } from 'react-redux-toastr';
import SmartPricingService from '../../services/smartPricingService';

export function useSmartPricesHistory(
  organizationId: string,
  propertyId: string,
  smartPricingEnabled: boolean,
) {
  const [history, setHistory] = useState({});

  const fetchHistory = useCallback(async () => {
    const smartPricingService = new SmartPricingService();
    if (propertyId && smartPricingEnabled) {
      try {
        const response =
          await smartPricingService.getPropertySmartPricesLastHistory(
            organizationId,
            propertyId,
          );

        const mappedData = response.map((floorPlan) => ({
          id: floorPlan.id,
          lastLog: floorPlan.revMgmtLog[0],
        }));

        setHistory(_.keyBy(mappedData, 'id'));
      } catch (errorMessage) {
        toastr.error('Error', errorMessage);
      }
    }
  }, [organizationId, propertyId, smartPricingEnabled]);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  return [history, fetchHistory];
}

export function useSmartPrices(
  organizationId: string,
  propertyId: string,
  smartPricingEnabled: boolean,
) {
  const [smartPrices, setSmartPrices] = useState({});
  const [lastUpdatedAt, setLastUpdatedAt] = useState(null);

  const fetchSmartPrices = useCallback(async () => {
    const smartPricingService = new SmartPricingService();
    if (smartPricingEnabled) {
      try {
        const response = await smartPricingService.getPropertySmartPrices(
          organizationId,
          propertyId,
        );

        const mappedData = response.results.map((floorPlan) => ({
          floorPlanName: floorPlan.unit_type,
          recommendedPrice: floorPlan.recommended_price,
        }));

        setSmartPrices(_.keyBy(mappedData, 'floorPlanName'));
        setLastUpdatedAt(response.lastUpdated);
      } catch (errorMessage) {
        toastr.error('Error', errorMessage);
      }
    }
  }, [organizationId, propertyId, smartPricingEnabled]);

  useEffect(() => {
    fetchSmartPrices();
  }, [fetchSmartPrices]);

  return [{ smartPrices, lastUpdatedAt }, fetchSmartPrices];
}

export function useSmartPricingEnabled(selectedProperty: Object) {
  const [smartPricingEnabled, setSmartPricingEnabled] = useState(false);

  const fetchSmartPricingEnabled = useCallback(async () => {
    setSmartPricingEnabled(selectedProperty?.config?.smartPricingEnabled);
  }, [selectedProperty]);

  useEffect(() => {
    fetchSmartPricingEnabled();
  }, [fetchSmartPricingEnabled]);

  return smartPricingEnabled;
}

export function injectSmartPricesHistory(Component) {
  function HOC(props) {
    const [history, refetchSmartPricesHistory] = useSmartPricesHistory(
      props.property.organizationId,
      props.property.id,
      props.property.config.smartPricingEnabled,
    );

    return (
      <Component
        smartPricesHistory={history}
        refetchSmartPricesHistory={refetchSmartPricesHistory}
        {...props}
      />
    );
  }

  return HOC;
}
