import { defineMessages } from 'react-intl';

const messages = defineMessages({
  other: {
    id: 'App.CreateCommercialApplicant.OtherForm.Other',
    defaultMessage: 'Other',
  },
  desiredMoveInDate: {
    id: 'App.CreateCommercialApplicant.OtherForm.DesiredMoveInDate',
    defaultMessage: 'Desired Move-in Date',
  },
  range: {
    id: 'App.CreateCommercialApplicant.OtherForm.range',
    defaultMessage: 'Range',
  },
  noMoveInDate: {
    id: 'App.CreateCommercialApplicant.OtherForm.NoMoveInDate',
    defaultMessage: 'No Move-in Date',
  },
  required: {
    id: 'App.CreateCommercialApplicant.OtherForm.Required',
    defaultMessage: 'Field value is required.',
  },
  assignApplicant: {
    id: 'App.CreateCommercialApplicant.OtherForm.assignApplicant',
    defaultMessage: 'Assign Applicant',
  },
  invalidDate: {
    id: 'App.CreateCommercialApplicant.OtherForm.InvalidDate',
    defaultMessage: 'Invalid date',
  },
  valueMustBeGreaterThanToday: {
    id: 'App.CreateCommercialApplicant.OtherForm.ValueMustBeGreaterThanToday',
    defaultMessage: 'Value must be greater than today.',
  },
  fromValueMustLessOrEqual: {
    id: 'App.CreateCommercialApplicant.OtherForm.FromValueMustLessOrEqual',
    defaultMessage: 'From value must be less or equal than To value.',
  },
});

export default messages;
