// $FlowFixMe
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ascend, descend, sortWith, isEmpty, pathOr, prop, isNil } from 'ramda';
import Table from '../../components/Table';
import Row from '../../components/Table/Row';
import Data from '../../components/Table/Data';
import ManageLayout from '../../components/ManageLayout';
import SearchField from '../Fields/Search';
import Spinner from '../../components/Spinner';
import DocumentTitle from 'react-document-title';
import type { OrderValue } from '../App/types';

import { FormattedMessage, injectIntl } from 'react-intl';

import messages from './messages';
import { useMasterTransacionCodes } from './hooks';
import {
  FORM_NAME,
  FORM_HEADERS,
  DEFAULT_PROPERTY_SELECTION_OPTION,
} from './constants';
import { PropertySelectionPanel } from './PropertySelectionPanel';

type Props = {
  intl: any,
  history: any,
  currentUser: Object,
};

const ManageTransactionCodes = (props: Props) => {
  const { intl, currentUser } = props;
  const organizationId = pathOr('', ['organizationId'], currentUser);
  const headers = FORM_HEADERS;

  const [masterTransactionCodes, setMasterTransactionCodes] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [propertySelectorOptions] = useState(DEFAULT_PROPERTY_SELECTION_OPTION);

  const isFetching = useMasterTransacionCodes(
    organizationId,
    searchText,
    setMasterTransactionCodes,
  );

  const buildRow = (transactionCode: Object) => {
    const {
      id,
      code,
      description,
      creditAccountNumber,
      debitAccountNumber,
      transactionType,
      masterSubjournal,
      isRequiredForAllProperties,
    } = transactionCode;
    return (
      <Row key={id}>
        <Data>{code}</Data>
        <Data>{transactionType}</Data>
        <Data>{masterSubjournal}</Data>
        <Data>{description}</Data>
        <Data>{debitAccountNumber}</Data>
        <Data>{creditAccountNumber}</Data>
        <Data>
          <button
            disabled={isRequiredForAllProperties}
            className="btn btn-sm btn-default"
            onClick={() => {
              props.history.push(`/edit-transaction-codes/${id}`);
            }}
          >
            <i className="et-pencil" />
          </button>
        </Data>
      </Row>
    );
  };

  const generateRows = (masterCodes: Array<Object>, numColumns: number) => {
    if (isEmpty(masterCodes)) {
      return (
        <Row>
          <Data colSpan={numColumns}>No Entries Found</Data>
        </Row>
      );
    }
    return masterCodes.map(buildRow);
  };

  const handleSort = ({ fieldName, order }: OrderValue) => {
    const sortDirection = order === 'ASC' ? ascend : descend;
    const sortedMasterTransactionCodes = sortWith([
      sortDirection(prop(fieldName)),
    ])(masterTransactionCodes);
    setMasterTransactionCodes(sortedMasterTransactionCodes);
  };

  const handleSearch = ({ searchText }: Object) => {
    const cleanSearchText = isNil(searchText) ? undefined : searchText.trim();
    setSearchText(cleanSearchText);
  };

  return (
    <DocumentTitle title={intl.formatMessage(messages.title)}>
      <ManageLayout
        title={
          <div className="col-xs-12 col-md-4">
            <h1>
              <FormattedMessage {...messages.title} />
            </h1>
            <p>
              <FormattedMessage {...messages.requiredCannotBeEdited} />{' '}
            </p>
            <PropertySelectionPanel options={propertySelectorOptions} />
            <h3>
              <FormattedMessage {...messages.allCodes} />
            </h3>
          </div>
        }
        actions={
          <div className="col-xs-12 col-md-8">
            <div className="search-actions search-align-right pull-right">
              <SearchField
                className="search-input search-input-active"
                form={FORM_NAME}
                placeholder={intl.formatMessage(messages.search)}
                onSubmit={handleSearch}
              />
            </div>
          </div>
        }
      >
        <div className="table-scroll table-units-container">
          <Table
            name="manage-master-transaction-codes"
            onSort={handleSort}
            headers={headers}
          >
            {isFetching ? (
              <Row>
                <Data colSpan={headers.length}>
                  <Spinner />
                </Data>
              </Row>
            ) : (
              generateRows(masterTransactionCodes, headers.length)
            )}
          </Table>
        </div>
      </ManageLayout>
    </DocumentTitle>
  );
};

export const mapStateToProps = ({ app }: Object): Object => {
  return {
    currentUser: app.currentUser.user,
  };
};

const InjectedManageTransactionCodes = injectIntl(ManageTransactionCodes);

export default connect(mapStateToProps)(InjectedManageTransactionCodes);
