import React, { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';

import DocumentRows from './DocumentRows';
import Spinner from '../../../components/Spinner';
import {
  useFetchDocuments,
  useDownloadDocument,
  useDeleteDocument,
} from './hooks';
import AddDocumentModal from './AddDocumentModal';
import messages from './messages';
import Data from '../../../components/Table/Data';

type Props = {
  intl: Object,
  depositId: string,
  selectedPropertyId: string,
  selectedOrganizationId: string,
  promptToaster: Function,
};

const UploadDocuments = ({
  intl,
  depositId,
  selectedPropertyId,
  selectedOrganizationId,
  promptToaster,
}: Props) => {
  const { formatMessage } = intl;

  const hooksArgs = {
    intl,
    propertyId: selectedPropertyId,
    organizationId: selectedOrganizationId,
    depositId,
    promptToaster,
  };
  const [isLoading, documents, refresh] = useFetchDocuments(hooksArgs);
  const onDownloadDocument = useDownloadDocument(hooksArgs);
  const onDeleteDocument = useDeleteDocument({ ...hooksArgs, refresh });

  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
  const [documentForAddDocumentModal, setDocumentForAddDocumentModal] =
    useState(null);
  const [addDocumentModalMode, setAddDocumentModalMode] = useState('create');

  const onEditDocument = (document: Object): any => {
    setDocumentForAddDocumentModal(document);
    setAddDocumentModalMode('edit');
    setShowAddDocumentModal(true);
  };

  const onAddDocument = (document: Object | null): any => {
    setDocumentForAddDocumentModal(null);
    setAddDocumentModalMode('create');
    setShowAddDocumentModal(true);
  };

  const onAddDocumentClose = async (reload: boolean) => {
    setShowAddDocumentModal(false);
    if (reload) {
      refresh();
    }
  };

  return (
    <div className="bodywrap">
      <Row className="padtop20">
        <Col xs={12} className="text-right">
          <Button
            className="btn btn-shout"
            id="delete-button"
            bsStyle="default"
            bsSize="small"
            onClick={() => onAddDocument(null)}
          >
            {formatMessage(messages.addDocument)}
          </Button>
        </Col>
      </Row>
      <Row>
        <div data-test="deposit-documents">
          <div className="container-fluid">
            <div className="container-scrollable">
              <table className="table table-prospects table-fixed-headers table-striped">
                <thead className="table-header">
                  <tr>
                    <th>{formatMessage(messages.date)}</th>
                    <th>{formatMessage(messages.name)}</th>
                    <th>{formatMessage(messages.notes)}</th>
                    <th style={{ width: '15%' }}>
                      {formatMessage(messages.actions)}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <Data colSpan={5}>
                        <Spinner />
                      </Data>
                    </tr>
                  ) : documents && documents.length ? (
                    <DocumentRows
                      intl={intl}
                      documents={documents}
                      onDownloadDocument={onDownloadDocument}
                      onEditDocument={onEditDocument}
                      onDeleteDocument={onDeleteDocument}
                    />
                  ) : (
                    <tr>
                      <td colSpan={4}>
                        <div className="text-center">
                          {formatMessage(messages.noDataDescription)}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Row>

      {showAddDocumentModal && (
        <AddDocumentModal
          intl={intl}
          show={showAddDocumentModal}
          document={documentForAddDocumentModal}
          mode={addDocumentModalMode}
          selectedPropertyId={selectedPropertyId}
          selectedOrganizationId={selectedOrganizationId}
          depositId={depositId}
          onClose={onAddDocumentClose}
          promptToaster={promptToaster}
          refresh={refresh}
        />
      )}
    </div>
  );
};

export default UploadDocuments;
