import {
  GET_EXPIRING_LEASES,
  GET_EXPIRING_LEASES_SUCCESS,
  GET_EXPIRING_LEASES_FAILURE,
  CLEAN_LOADED_EXPIRING_LEASES,
} from './constants';
import type { Action } from '../App/types';

export const initialState = {
  assignedUnits: null,
  meta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  isFetching: false,
};

export default function expiringLeasesReducer(
  state: Object = initialState,
  { type, payload, error, meta }: Action<any>,
) {
  switch (type) {
    case GET_EXPIRING_LEASES_SUCCESS:
      return { assignedUnits: payload, meta, isFetching: false };
    case GET_EXPIRING_LEASES_FAILURE:
      return { ...state, error, payload, isFetching: false };
    case CLEAN_LOADED_EXPIRING_LEASES:
      return initialState;
    case GET_EXPIRING_LEASES:
      return { ...state, isFetching: true };
    default:
      return state;
  }
}
