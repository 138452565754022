import useFetchAndSetData from './useFetchAndSetData';
import { useCallback } from 'react';
import { toastr } from 'react-redux-toastr';
import PropertyService from '../../services/propertyService';

export function useOneProperty(
  organizationId: string,
  propertyId: string,
): Object {
  const initialState = { property: [], organization: [] };

  const fetcherCallback = useCallback(() => {
    const service = new PropertyService();
    return service.getOneProperty(organizationId, propertyId);
  }, [organizationId, propertyId]);

  const handleError = useCallback(() => {
    toastr.error('Error', 'Failed to load property data.');
  }, []);

  return useFetchAndSetData(
    initialState,
    fetcherCallback,
    undefined,
    handleError,
  );
}
