import { useState, useEffect } from 'react';

import { ToggleInput } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  TextArea,
  Typography,
  Stack,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

import { showWaitlistUpdateFail, showWaitlistUpdateSuccess } from './utils';
import { WaitlistSectionProps } from './types';
import messages from './messages';
import { injectIntl } from 'react-intl';

const NotesSection = (props: Partial<WaitlistSectionProps>) => {
  const {
    intl,
    localWaitlistInfo: { waitlistNotes, id: applicationId },
    waitlistActions: { updateWaitlist },
  } = props;
  const [localNotes, setLocalNotes] = useState(waitlistNotes);

  useEffect(() => setLocalNotes(waitlistNotes), [waitlistNotes]);

  const onChange = (event) => {
    const newValue = event?.target?.value;
    setLocalNotes(newValue);
  };

  const textAeraProps = {
    value: localNotes ?? '---',
    onChange,
    rows: 5,
    inputProps: {
      maxLength: 2500,
    },
  };

  const isNoChange = localNotes === waitlistNotes;

  const onSave = async () => {
    try {
      const {
        localWaitlistInfo: { waitlistStartTime },
      } = props;
      const newWaitlistInfo = {
        waitlistStartTime,
        waitlistNotes: localNotes ? localNotes : '---',
      };
      await updateWaitlist(applicationId, newWaitlistInfo);
      showWaitlistUpdateSuccess(intl);
    } catch (err) {
      showWaitlistUpdateFail(intl, err);
    }
  };

  const onCancel = () => {
    setLocalNotes(waitlistNotes);
  };

  const textArea = <TextArea {...textAeraProps} />;

  const stringsToDisplay = (waitlistNotes ?? '---')
    .split('\n')
    .map((line, index) => (
      <Typography key={`${line.substring(0, 5)}${index}`}>{line}</Typography>
    ));
  const moreThan10 = stringsToDisplay.length > 10;
  const displayArea = (
    <Stack>
      {stringsToDisplay.filter((val, index) => index < 10)}
      {moreThan10 && <Typography>...</Typography>}
    </Stack>
  );

  const controlsConfig = {
    onSave,
    onCancel,
    iconsConfig: {
      save: {
        label: intl.formatMessage(messages.saveChanges),
      },
      edit: {
        label: intl.formatMessage(messages.editViewNote),
      },
      cancel: {
        label: intl.formatMessage(messages.cancel),
      },
    },
  };

  const label = {
    text: intl.formatMessage(messages.waitlistNotesTitle),
    justifyContent: 'space-between',
  };

  const toggleInputProps = {
    label,
    isNoChange,
    controlsConfig,
    editableComponent: textArea,
    displayComponent: displayArea,
  };

  return <ToggleInput {...toggleInputProps} />;
};
export default injectIntl(NotesSection);
