import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';

import {
  IconPlusMinus,
  RowGrid,
  AddButtonRowGrid,
  RowDetailsGridForm,
} from './styled';
import type { SetAsideProgramsTableRowProps } from './types';
import SetAsideProgramsTableRowDetails from './rowDetails';
import { useEditSetAsideProgram, useAddSetAsideIncomeLimits } from './hooks';
import { PPL_PROPERTIES, PPL } from './constants';
import messages from './messages';
import ElementWithPermissions from '../../../../components/ElementWithPermissions';

const valueOrEmpty = (value: any): string => value || '---';

const trimPercentSign = (value: string): string => {
  return value ? value.replace('%', '') : value;
};

/*
 * data.setAsideProgram => Data for the main table rows
 * data.fullSetAsides => Data for the row's details drawer
 */
const SetAsideProgramsTableRow = ({
  intl,
  data,
  organizationId,
  propertyId,
  promptToaster,
  refresh,
  index,
  userPermissions,
  onOpenRowDrawer,
  onCloseRowDrawer,
  isEditMode,
  onAddEdit,
}: SetAsideProgramsTableRowProps): any => {
  const hooksArgs = {
    intl,
    organizationId,
    propertyId,
    promptToaster,
    refresh,
  };

  const [isAddMode, setIsAddMode] = useState(false);
  const editSetAsideProgram = useEditSetAsideProgram({ ...hooksArgs });
  const addSetAsideIncomeLimits = useAddSetAsideIncomeLimits({ ...hooksArgs });

  const { setAsideProgram: mainData, fullSetAsides: detailsData } = data;

  const [editingDetailsRows, setEditingDetailsRows] = useState([]);

  useEffect(() => {
    const initialEditingDetailsRows = detailsData.map(
      (_, detailsIndex) => false,
    );
    if (!isEditMode) {
      // Reset all details rows to be disabled when closing the drawer
      setEditingDetailsRows(initialEditingDetailsRows);
      setIsAddMode(false);
    }
  }, [isEditMode, detailsData]);

  useEffect(() => {
    onAddEdit({
      isAddMode,
      editingDetailsRows,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddMode, editingDetailsRows]);

  const getDetailsInitialValues = (values: Object): Object => {
    const detailsInitialValues = {
      ...values,
    };
    detailsInitialValues.effectiveDate = values.effectiveDate
      ? moment(values.effectiveDate).format('MM/DD/YYYY')
      : null;
    return detailsInitialValues;
  };

  const onStartEditRowDetails = (index: number) => {
    const newEditingRows = detailsData.map((_, idx) => idx === index);
    setEditingDetailsRows(newEditingRows);
  };

  const onStopEditRowDetails = () => {
    // close all since only one can be opened at a time
    const newEditingRows = detailsData.map((_, index) => false);
    setEditingDetailsRows(newEditingRows);
  };

  const onEditSaveRowDetails = (values: any) => {
    // Only keep ppl properties
    const payload = {};
    PPL_PROPERTIES.forEach((propName) => {
      // To avoid saving an empty string into the numeric DB fields...
      const val = values[propName];
      const value = val === '' || val === null ? null : +val;
      payload[propName] = value;
    });
    editSetAsideProgram({ ...payload, id: values.id });
  };

  const onAddClicked = () => {
    setIsAddMode(true);
  };

  const onAddSaveRowDetails = (values: any) => {
    const payload = {
      ...values,
      duplicateFromId:
        data && data.fullSetAsides && data.fullSetAsides[0]
          ? data.fullSetAsides[0].id
          : null,
    };
    PPL_PROPERTIES.forEach((propName) => {
      // To avoid saving an empty string into the numeric DB fields...
      const val = values[propName];
      const value = val === '' || val === null ? null : +val;
      payload[propName] = value;
    });
    // onCloseRowDrawer();
    addSetAsideIncomeLimits(payload);
    setIsAddMode(false);
  };

  const onAddDetailsCancel = () => {
    setIsAddMode(false);
  };

  const onCloseDrawer = () => {
    onStopEditRowDetails();
    onCloseRowDrawer(index);
  };

  const showEditButton = (index: number): boolean =>
    !isAddMode && !editingDetailsRows.some((isEditing) => isEditing === true);

  const isEditingIncomeLimits = editingDetailsRows.some(
    (isEditing) => isEditing === true,
  );
  const showAddIncomeLimitLink = !isAddMode && !isEditingIncomeLimits;
  const showNewIncomeLimitRow = isAddMode && !isEditingIncomeLimits;

  return (
    <Fragment>
      <tr key={data.id}>
        <td colSpan={9}>
          <RowGrid>
            <div key={`programName-${index}`}>{mainData.programName}</div>
            <div key={`setAsideName-${index}`}>
              {mainData.setAsideShortName}
            </div>
            <div key={`nUnits-${index}`}>
              {valueOrEmpty(mainData.numberOfUnits)}
            </div>
            <div key={`isHud-${index}`}>{mainData.isHUD ? 'Yes' : '---'}</div>
            <div key={`incomeLimit-${index}`}>
              {valueOrEmpty(trimPercentSign(mainData.incomeLimit))}
            </div>
            <div key={`maxIncome-${index}`}>
              {valueOrEmpty(trimPercentSign(mainData.maximumIncomePercentage))}
            </div>
            <div key={`maxRent-${index}`}>
              {valueOrEmpty(trimPercentSign(mainData.maximumRentPercentage))}
            </div>
            <div key={`overIncome-${index}`}>
              {valueOrEmpty(trimPercentSign(mainData.overIncomeCalc))}
            </div>
            <div key={`edit-${index}`}>
              {isEditMode ? (
                <div className="panel-button" onClick={onCloseDrawer}>
                  <IconPlusMinus className="icon et-minus" />
                </div>
              ) : (
                <div
                  className="panel-button"
                  onClick={() => onOpenRowDrawer(index)}
                >
                  <IconPlusMinus className="icon et-plus" />
                </div>
              )}
            </div>
          </RowGrid>

          {isEditMode && (
            <Fragment>
              {/* Headers for the income limits... */}
              <RowDetailsGridForm>
                <label>{intl.formatMessage(messages.effectiveDate)}</label>
                {PPL.map((number) => (
                  <div key={`rowDetailLabel-${number}ppl-${index}`}>
                    <label>{`${number}`} ppl</label>
                  </div>
                ))}
              </RowDetailsGridForm>

              {/* Existing income limits... */}
              {detailsData.map((detailData, detailsIndex) => (
                <SetAsideProgramsTableRowDetails
                  key={`addDetailsForm_${detailData.id}`}
                  intl={intl}
                  data={detailData}
                  initialValues={getDetailsInitialValues(detailData)}
                  onSubmit={(values) => onEditSaveRowDetails(values)}
                  form={`editDetailsForm_${detailData.id}`}
                  isNewEntry={false}
                  userPermissions={userPermissions}
                  onStartEditing={onStartEditRowDetails}
                  onStopEditing={onStopEditRowDetails}
                  index={detailsIndex}
                  isEditMode={editingDetailsRows[detailsIndex]}
                  showEditButton={showEditButton(index)}
                />
              ))}

              {showAddIncomeLimitLink && (
                /* Button to add a new income limit... */
                <AddButtonRowGrid>
                  <div>
                    <ElementWithPermissions scope={['compliance-tab-add']}>
                      <a onClick={() => onAddClicked()}>
                        + Add New Income Limits
                      </a>
                    </ElementWithPermissions>
                  </div>
                </AddButtonRowGrid>
              )}

              {showNewIncomeLimitRow && (
                /* New row for creating a new income limit... */
                <SetAsideProgramsTableRowDetails
                  intl={intl}
                  data={data}
                  initialValues={{}}
                  onSubmit={(values) => onAddSaveRowDetails(values)}
                  form={`addDetailsForm_${index}`}
                  isNewEntry={true}
                  onCancel={onAddDetailsCancel}
                  userPermissions={userPermissions}
                />
              )}
            </Fragment>
          )}
        </td>
      </tr>
    </Fragment>
  );
};

export default SetAsideProgramsTableRow;
