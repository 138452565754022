import { isNil, not } from 'ramda';
import { validateRequired } from '../../../utils/validations';

import formMessages from '../../../containers/ShortLeaseApplication/messages';

import type { EmploymentInformation } from './types';

const warn = (
  values: EmploymentInformation,
  status: any,
  form: string,
  { intl, employmentStatuses }: Object,
) => {
  const warnings = {};

  const notRequiredStatuses = ['Student', 'Retired', 'Not Employed', 'Other'];

  if (!status) {
    warnings.status = intl.formatMessage(formMessages.requiredForCompletion);
  }

  const selectedStatus = employmentStatuses.find(
    (currentStatus) => currentStatus.value === status,
  );
  const fieldsRequired = selectedStatus
    ? not(notRequiredStatuses.includes(selectedStatus.text))
    : true;

  if (fieldsRequired && form !== 'short') {
    if (!validateRequired('position', values)) {
      warnings.position = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }

    if (!validateRequired('annualSalary', values)) {
      warnings.annualSalary = intl.formatMessage(formMessages.salaryRequired);
    }

    if (!validateRequired('from', values)) {
      warnings.from = intl.formatMessage(formMessages.requiredForCompletion);
    }

    if (!validateRequired('employerName', values)) {
      warnings.employerName = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }

    if (!validateRequired('phoneNumber', values)) {
      warnings.phoneNumber = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }

    if (!validateRequired('supervisorName', values)) {
      warnings.supervisorName = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }
  }

  if (form === 'short') {
    if (
      !validateRequired('annualIncome', values) &&
      status === employmentStatuses[0].value
    ) {
      warnings.annualIncome = intl.formatMessage(formMessages.salaryRequired);
    }

    if (isNil(status)) {
      warnings.status = intl.formatMessage(formMessages.requiredForCompletion);
    }
  }

  return warnings;
};

export default warn;
