import { useMemo } from 'react';
import { useQuery } from 'react-query';
import useUserOrganizationId from '../../../hooks/useUserOrganizationId';
import isNil from 'lodash/isNil';
import UnitFloorPlanHistoryService from '../../../services/unitFloorPlanHistoryService';
import UnitService from '../../../services/unitService';

export function useUnitHistory({ floorPlanId, unitId, history, propertyId }) {
  const { data, isLoading } = useQuery(
    ['unitHistory', unitId, floorPlanId],
    () => {
      const service = new UnitFloorPlanHistoryService();
      return service.getByUnitId(unitId);
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );
  const rows = useMemo(() => {
    if (isLoading && isNil(data)) return [];

    return (data ?? []).map((d) => ({
      id: d.id,
      floorPlan: d.name,
      setAside: d.unitRentRestriction,
      changedOn: d.changedOn,
      changedBy: `${d.changedBy.firstName} ${d.changedBy.lastName}`,
    }));
  }, [data, isLoading]);

  return {
    rows,
    onFloorPlanClick: (id: string) => {
      const record = (data ?? []).find((d) => d.id === id);

      if (isNil(record)) return;

      history.push(`/property/${propertyId}/floorplan/${record.floorPlanId}`);
    },
    isLoading,
  };
}

export function useHouseholdUnitHistory({ unitId, propertyId, history }) {
  const organizationId = useUserOrganizationId();
  const unitService = useMemo(() => new UnitService(), []);
  const { data, isLoading, isError } = useQuery(
    ['unitHouseholdHistory', unitId],
    () => {
      return unitService.getHouseholdUnitHistory({
        unitId,
        propertyId,
        organizationId,
      });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
  );

  const rows = useMemo(() => {
    if (isLoading && isNil(data)) return [];

    return (data ?? []).map((d) => ({
      id: d.id,
      residentId: d?.household?.mainCus?.rc?.residentId,
      moveInDate: d?.moveInDate,
      moveOutDate: d?.moveOutDate,
      residentName: `${d?.household?.mainCus?.firstName} ${d?.household?.mainCus?.lastName}`,
    }));
  }, [data, isLoading]);

  return {
    rows,
    onResidentClick: (residentId: string) => {
      history.push(`/property/${propertyId}/prior-resident/${residentId}`);
    },
    isError,
  };
}
