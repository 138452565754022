import React, { useState, useMemo, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as reduxForm from 'redux-form';
import { bindActionCreators } from 'redux';
import * as R from 'ramda';

import Divider from '../../components/Common/Divider';
import {
  leaseText,
  checkLeaseEditablility,
  getIsDisabledMonthly,
} from '../../utils/lease-helpers';
import * as leaseBasicsConstants from './Sections/LeaseBasics/constants';

import type { GlobalState, Property } from '../App/types';
import type { OnLeaseBasicsSubmitEvent } from './Sections/LeaseBasics/types';

/* Tab Sections - In Order of Appearance */
import LeaseBasics from './Sections/LeaseBasics';
import Rent from './Sections/Rent';
import Concessions from './Sections/Concessions';
import LeaseOptions from './Sections/LeaseOptions';
import MonthlyLeaseChargesOrCredits from './Sections/MonthlyLeaseChargesOrCredits';
import LeaseActionButtons from '../LeaseDataTab/LeaseDataTabForm/LeaseDataTabFormSections/LeaseActionButtons';
import { promptToaster } from '../../containers/App/actions';

type Props = {
  selectedProperty: Property,
  intl: Object,
  leases: Object[],
  lateMethods: Object[],
  isResident: boolean,
  customer: Object,
  unit: Object,
  actions: {
    submit: Function,
    promptToaster: Function,
  },
  onLeaseBasicsSubmit: OnLeaseBasicsSubmitEvent,
  isSaveButtonDisabled: boolean,
  residentId?: string,
  applicationId: string,
  frNames: string[],
  labelEndDate: any,
  selectedMonthlyOption: Object,
  commencementDateValue: any,
  canComplete: boolean,
  leaseBasicsForm: Object,
  prospectInfo: Object,
  leaseBasicsIsDirty: boolean,
  formValues: Object,
  isDisabledRenewal: boolean,
  refreshLeases: Function,
  navigationHistory: any,
  doNotRenew: boolean,
  underEviction: boolean,
  startRenewalMessage?: string,
};

const getInitialLease = (leases?: Object[]) => {
  if (!Array.isArray(leases)) return null;

  return R.head(leases);
};

const getLeaseExists = (leases?: Object[]) => {
  // $FlowFixMe
  return Array.isArray(leases) && !R.isEmpty(leases) && R.head(leases).id;
};

function CommercialLeaseDataTab({
  intl,
  leases,
  isResident,
  customer,
  unit,
  lateMethods,
  selectedProperty,
  actions: { submit, promptToaster },
  onLeaseBasicsSubmit,
  isSaveButtonDisabled,
  residentId,
  applicationId,
  frNames,
  labelEndDate,
  selectedMonthlyOption,
  commencementDateValue,
  canComplete,
  formValues,
  prospectInfo,
  leaseBasicsIsDirty,
  isDisabledRenewal,
  refreshLeases,
  navigationHistory,
  doNotRenew,
  underEviction,
  startRenewalMessage,
  noticeToVacate,
}: Props) {
  const [selectedLease, setSelectedLease] = useState(getInitialLease(leases));
  const [submitting, setSubmitting] = useState(false);
  const selectedLeaseId = R.prop('id', selectedLease);
  const organizationId = R.prop('organizationId', selectedProperty);

  const handleSave = () => {
    setSubmitting(true);
    submit(leaseBasicsConstants.FORM_NAME);
  };

  useEffect(() => {
    const lease = getInitialLease(leases);

    // $FlowFixMe
    if (R.prop('id', lease)) {
      setSelectedLease(lease);
      setSubmitting(false);
    }
  }, [leases]);

  const handleLeaseChange = (ev: any) => {
    const lease = R.find(R.propEq('id', ev.target.value), leases);
    if (lease) setSelectedLease(lease);
  };

  const leaseOptions = useMemo(
    () =>
      Array.isArray(leases)
        ? leases.map((l, i) => ({
            value: l.id,
            text: leaseText(leases, i, isResident),
          }))
        : [],
    [leases, isResident],
  );

  const isEditable = checkLeaseEditablility(selectedLease, residentId);
  const isDisabledMonthly = getIsDisabledMonthly(
    selectedLease,
    leaseOptions,
    residentId,
  );
  const showGenerateMessage =
    !residentId ||
    R.prop('isRenewal', selectedLease) ||
    R.prop('isTransfer', selectedLease);
  const leaseExists = getLeaseExists(leases);
  const unitId = R.pathOr(null, ['id'], unit);
  const isRenewalComplete = R.pathOr(
    false,
    ['isRenewalComplete'],
    selectedLease,
  );
  const isRenewal =
    selectedLease.isRenewal ||
    (selectedLease.isRenewalComplete && selectedLease.isMovedIn);

  return (
    <React.Fragment>
      <LeaseBasics
        intl={intl}
        leaseOptions={leaseOptions}
        selectedLeaseId={selectedLeaseId}
        selectedLease={selectedLease}
        setSelectedLease={setSelectedLease}
        onLeaseChange={handleLeaseChange}
        unit={unit}
        customer={customer}
        lateMethodOptions={lateMethods}
        organizationId={organizationId}
        onSubmit={onLeaseBasicsSubmit}
        isEditable={isEditable}
        isResident={isResident}
        leaseExists={leaseExists}
        showLeaseDropdown={!R.isNil(residentId)}
        residentId={residentId}
        applicationId={applicationId}
        isDisabledRenewal={isDisabledRenewal}
        toasterFunction={promptToaster}
        refreshLeases={refreshLeases}
        navigationHistory={navigationHistory}
        doNotRenew={doNotRenew}
        canComplete={canComplete}
        priorLease={leases[1] || {}}
        underEviction={underEviction}
        startRenewalMessage={startRenewalMessage}
        noticeToVacate={noticeToVacate}
      />
      <Divider />
      <Rent
        propertyId={R.prop('id', selectedProperty)}
        organizationId={organizationId}
        leaseId={selectedLeaseId}
        lease={selectedLease}
        unit={unit}
        canComplete={canComplete && !leaseBasicsIsDirty}
        isResident={isResident}
        isRenewal={isRenewal}
        intl={intl}
      />
      <Divider />
      <Concessions intl={intl} isEditable={isEditable} lease={selectedLease} />
      <LeaseOptions
        intl={intl}
        selectedProperty={selectedProperty}
        lease={selectedLease}
      />
      <Divider />
      <MonthlyLeaseChargesOrCredits
        applicationId={applicationId}
        frNames={frNames}
        intl={intl}
        isLocked={R.prop('isLocked', selectedLease)}
        lease={selectedLease}
        residentId={residentId}
        showGenerateMessage={showGenerateMessage}
        isDisabledMonthly={isDisabledMonthly}
        labelEndDate={labelEndDate}
        selectedMonthlyOption={selectedMonthlyOption}
        startDate={commencementDateValue}
        canComplete={canComplete}
        formValues={formValues}
        unitId={unitId}
      />
      <Divider />
      {(!residentId || selectedLease.isRenewal || selectedLease.isTransfer) && (
        <LeaseActionButtons
          applicationId={applicationId}
          canComplete={canComplete}
          difference={0}
          handleSubmit={(func) => func}
          intl={intl}
          isRenewalComplete={isRenewalComplete}
          lease={selectedLease}
          onSubmit={handleSave}
          prospectId={prospectInfo.id}
          residentId={residentId}
          submitting={submitting}
          allAffordableTabsApproved={true}
        />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state: GlobalState): Object => {
  const {
    app: { selectedProperty },
    form,
  } = state;
  // $FlowFixMe
  const leaseBasicsForm = form[leaseBasicsConstants.FORM_NAME];
  const leaseBasicsFormValues = reduxForm.getFormValues(
    leaseBasicsConstants.FORM_NAME,
  )(state);
  const concessionsFormValues = reduxForm.getFormValues('Concessions')(state);
  return {
    selectedProperty,
    formValues: { ...leaseBasicsFormValues, ...concessionsFormValues },
    leaseBasicsIsDirty: reduxForm.isDirty(leaseBasicsConstants.FORM_NAME)(
      state,
    ),
    canComplete: reduxForm.isValid(leaseBasicsConstants.FORM_NAME)(state),
    isSaveButtonDisabled: !R.isNil(R.prop('syncErrors', leaseBasicsForm)),
    commencementDateValue: R.pathOr(
      '',
      ['values', 'commencementDate'],
      leaseBasicsForm,
    ),
  };
};

const mapDispatchToProps = (dispatch: Object): any => ({
  actions: bindActionCreators(
    { submit: reduxForm.submit, promptToaster },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CommercialLeaseDataTab));
