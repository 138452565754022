import {
  calculateLeaseEndDate,
  calculateLeaseEndDateV2,
} from '../../../utils/lease-helpers';
import LeaseExpirationLimitService from '../../../services/leaseExpirationLimitService';
import messages from './messages';

export const asyncValidate =
  (
    mapping = {
      endDate: 'endDate',
      overrideLeaseExpirationLimit: 'overrideLeaseExpirationLimit',
      startDate: 'startDate',
      leaseTermId: 'leaseTermId',
    },
  ) =>
  async (values, _, props) => {
    if (
      !props.flags.leaseExpirationMgmtV1 ||
      !props.isLeaseExpirationLimitsActive
    )
      return;

    if (
      values[mapping.endDate] &&
      !values[mapping.overrideLeaseExpirationLimit]
    ) {
      const leaseExpirationLimitService = new LeaseExpirationLimitService();
      const { leaseEndEnhance } = props.flags;
      const expectedEndDate = leaseEndEnhance
        ? calculateLeaseEndDateV2(
            values[mapping.startDate],
            values[mapping.leaseTermId],
            props.leaseTerms,
            props?.selectedProperty,
          )
        : calculateLeaseEndDate(
            values[mapping.startDate],
            values[mapping.leaseTermId],
            props.leaseTerms,
            props?.selectedProperty?.isSetLeaseEndDateToEndOfMonth,
          );

      try {
        const response =
          await leaseExpirationLimitService.validateLeaseExpirationLimit(
            props.selectedProperty.organizationId,
            props.selectedProperty.id,
            values[mapping.endDate],
            expectedEndDate,
            props.lease.id,
          );

        if (
          response.overLimit &&
          !values[mapping.overrideLeaseExpirationLimit]
        ) {
          if (response.availableMonths.length === 0) {
            return Promise.reject({
              [mapping.endDate]: props.intl.formatMessage(
                messages.leaseExpirationLimitNoMonthsAvailable,
                {
                  endDate: values[mapping.endDate].format('MMMM'),
                },
              ),
            });
          }

          return Promise.reject({
            [mapping.endDate]: props.intl.formatMessage(
              messages.leaseExpirationLimitOverLimit,
              {
                endDate: values[mapping.endDate].format('MMMM'),
                availableMonths: response.availableMonths
                  .join(', ')
                  .replace(/, ([^,]*)$/, ' and $1'),
              },
            ),
          });
        }
      } catch (error) {
        return Promise.reject({
          [mapping.endDate]: props.intl.formatMessage(
            messages.leaseExpirationLimitError,
            {
              message: error.networkError ?? error.message,
            },
          ),
        });
      }
    }
  };
