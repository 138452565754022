import React from 'react';
import moment from 'moment';

const MUPContext = React.createContext({
  period: '',
  bankAccountOptions: [{ value: 'default', text: 'Choose', disabled: true }],
  batchDate: moment().format('MM/DD/YYYY'),
  onCancel: () => {},
  onSubmit: () => {},
  disableCancel: false,
  disableSubmit: false
});

export default MUPContext;
