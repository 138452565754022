import messages from '../messages';

export const buildHeaders = ({
  intl,
  isAllCommercial,
  showTextingColumn,
  showCollectionsColumns,
}) => {
  return [
    isAllCommercial
      ? {
          id: 'priorTenants',
          label: intl.formatMessage(messages.priorTenants),
          sortable: true,
          searchable: true,
          dataType: 'string',
        }
      : {
          id: 'priorResidents',
          label: intl.formatMessage(messages.priorResidents),
          sortable: true,
          searchable: true,
          dataType: 'string',
        },
    ...(showTextingColumn
      ? [
          {
            id: 'texting',
            label: intl.formatMessage(messages.texting),
            sortable: true,
            searchable: false,
            dataType: 'number',
            multiselect: true,
            filterOptions: [
              { text: 'New', value: 'new' },
              { text: 'Unresolved', value: 'unresolved' },
            ],
          },
        ]
      : []),
    {
      id: 'unit',
      label: intl.formatMessage(messages.unit),
      sortable: true,
      searchable: true,
      dataType: 'string',
    },
    {
      id: 'moveInDate',
      label: intl.formatMessage(messages.moveInDate),
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'moveOutDate',
      label: intl.formatMessage(messages.moveOutDate),
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'FASDate',
      label: intl.formatMessage(messages.FASDate),
      sortable: true,
      searchable: true,
      dataType: 'date',
    },
    {
      id: 'monthsLateFeesCharges',
      label: intl.formatMessage(messages.lateFees),
      sortable: true,
      searchable: true,
      dataType: 'number',
    },
    {
      id: 'underEviction',
      label: intl.formatMessage(messages.underEviction),
      sortable: true,
      filterOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
    },
    {
      id: 'doNotRenew',
      label: intl.formatMessage(messages.doNotRenew),
      sortable: true,
      filterOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
    },
    ...(showCollectionsColumns
      ? [
          {
            id: 'collectionStatus',
            label: intl.formatMessage(messages.collectionStatus),
            sortable: true,
            dataType: 'string',
            sx: { backgroundColor: 'primary.dark' },
            filterOptions: [
              { text: 'Claim Created', value: 'claim_created' },
              { text: 'Payment Plan', value: 'payment_plan' },
              { text: 'Account Closed', value: 'account_closed' },
            ],
          },
          {
            id: 'claimCreated',
            label: intl.formatMessage(messages.claimCreated),
            sortable: true,
            searchable: true,
            dataType: 'date',
            sx: { backgroundColor: 'primary.dark' },
          },
        ]
      : []),
    {
      id: 'residentBalance',
      label: intl.formatMessage(messages.residentBalance),
      sortable: true,
      searchable: true,
      dataType: 'number',
    },
    {
      id: 'subsidyBalance',
      label: intl.formatMessage(messages.subsidyBalance),
      sortable: true,
      searchable: true,
      dataType: 'number',
    },
  ];
};

export const parsePriorResidents = (result: [], isCommercial = false) => {
  const value = !isCommercial
    ? (result?.priorActiveResidents ?? [])
        .map((res) => res.applicant.name.trim())
        .join(', ')
    : result.firstName.trim();
  return value;
};
