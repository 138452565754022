import messages from '../containers/ApplicationProfile/messages';
import type { Intl } from '../types';

const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

type ActivityType = {
  automatedOnly: boolean,
  canBeOverlapped: boolean,
  completionStatusOptions: Object[],
  id: string,
  name: string,
  translations: {
    [key: string]: string,
  },
};

type GenerateActivityTypesListPayload = {
  activityTypes: ActivityType[],
  intl: Intl,
  locale?: string,
};

class ActivityTypeService {
  getAll() {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/activityTypes`, options);
  }

  generateActivityTypesList(
    payload: GenerateActivityTypesListPayload,
  ): Array<any> {
    const activityTypes = payload.activityTypes
      .filter((at) => at.automatedOnly === false)
      .map((activityType) => ({
        value: activityType.id,
        text: activityType.translations[payload.locale] || activityType.name,
        canBeOverlapped: activityType.canBeOverlapped,
        completionStatusOptions: activityType.completionStatusOptions,
        disabled: false,
      }));
    activityTypes.unshift({
      value: 'default',
      text: payload.intl.formatMessage(messages.chooseOption),
      completionStatusOptions: [{ id: '' }],
      disabled: true,
      showForRecord: true,
    });
    return activityTypes;
  }
}

export default ActivityTypeService;
