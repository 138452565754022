import { useCallback } from 'react';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils';
import type { UseHandlers } from './types';
const useHandlers = ({
  allHouseholdRentableItems,
  allPropertyFeesWithRentableItems,
  closeConfirmModal,
  closeModal,
  createHouseholdRentableItem,
  deleteHouseholdRentableItem,
  editMode,
  handleSubmit,
  householdId,
  householdRentableItemId,
  openModal,
  setEditMode,
  setHouseholdRentableItemId,
  updateHouseholdRentableItem,
}: UseHandlers): {
  onSubmit: Function,
  handleDelete: Function,
  onItemClick: Function,
} => {
  const onSubmit = handleSubmit(async (data) => {
    const payload = {
      householdId: householdId,
      rentableItemId: data.numberId,
      endDate: data.endDate ? formatDateDB(data.endDate, true) : null,
      ...(data.startDate && {
        startDate: formatDateDB(data.startDate, true),
      }),
      ...(householdRentableItemId && {
        id: householdRentableItemId,
      }),
    };

    if (editMode) {
      await updateHouseholdRentableItem.mutateAsync(payload);
    } else {
      await createHouseholdRentableItem.mutateAsync(payload);
    }
    await allHouseholdRentableItems.refetch();
    await allPropertyFeesWithRentableItems.refetch();
  });
  const handleDelete = async () => {
    await deleteHouseholdRentableItem.mutateAsync(householdRentableItemId);
    closeConfirmModal();
    closeModal();
    await allHouseholdRentableItems.refetch();
    await allPropertyFeesWithRentableItems.refetch();
  };

  const onItemClick = useCallback(
    (householdRentableItem) => {
      setEditMode(true);
      setHouseholdRentableItemId(householdRentableItem.id);
      openModal();
    },
    [openModal, setEditMode, setHouseholdRentableItemId],
  );

  return {
    onSubmit,
    handleDelete,
    onItemClick,
  };
};

export default useHandlers;
