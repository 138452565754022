import { FormattedMessage } from 'react-intl';
import { blue } from '@fortress-technology-solutions/fortress-component-library/design';
import componentMessages from './messages';
import generalMessages from '../App/messages';

const messages = { ...generalMessages, ...componentMessages };

export const HEADERS = [
  {
    id: 'reports',
    label: <FormattedMessage {...messages.report} />,
    sortable: true,
    sx: { backgroundColor: blue.light },
  },
  {
    id: 'type',
    label: <FormattedMessage {...messages.type} />,
    sortable: true,
    sx: { backgroundColor: blue.light },
  },
  {
    id: 'createdBy',
    label: <FormattedMessage {...messages.createdBy} />,
    sortable: true,
    sx: { backgroundColor: blue.main },
  },
  {
    id: 'creationDate',
    label: <FormattedMessage {...messages.creationDate} />,
    sortable: true,
    sx: { backgroundColor: blue.main },
  },
  {
    id: 'status',
    label: <FormattedMessage {...messages.status} />,
    sortable: true,
    sx: { backgroundColor: blue.main },
  },
  {
    id: 'download',
    label: <FormattedMessage {...messages.download} />,
    sx: {
      backgroundColor: blue.main,
    },
    innerSx: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  {
    id: 'create',
    label: <FormattedMessage {...messages.create} />,
    sx: {
      backgroundColor: blue.light,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  {
    id: 'viewHistory',
    label: <FormattedMessage {...messages.viewHistory} />,
    sx: {
      backgroundColor: blue.light,
      display: 'flex',
      justifyContent: 'center',
    },
  },
];

export const SECTION_HEADERS = [
  {
    label: '',
    colSpan: 2,
    sx: { backgroundColor: blue.main },
  },
  {
    label: <FormattedMessage {...messages.reportsCreated} />,
    colSpan: 4,
    sx: { backgroundColor: blue.dark },
  },
  {
    label: '',
    colSpan: 2,
    sx: { backgroundColor: blue.main },
  },
];

export const PROPERTY_PATH_MAP = {
  reports: {
    path: 'name',
    dataType: 'string',
  },
  type: {
    path: 'type',
    dataType: 'string',
  },
  createdBy: {
    path: 'lastUser.username',
    dataType: 'string',
  },
  creationDate: {
    path: 'lastCreated',
    dataType: 'date',
  },
  status: {
    path: 'status',
    dataType: 'string',
  },
};

export const STATUSES = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESSFUL: 'SUCCESSFUL',
  ERROR: 'ERROR',
};
