import { useState, useEffect, useCallback } from 'react';
import { Field, FormSection } from 'redux-form';
import { ControlLabel } from 'react-bootstrap';
import styled from 'styled-components';
import {
  Grid,
  MultiSelect,
} from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  renderCurrencyField,
  renderTextField,
} from '../../../utils/redux-form-helper';

type ReportingInfoFormProps = {
  change: Function,
  disabled: boolean,
  showAnnualIncome: boolean,
  initialValues: any,
  isLoadingSpecialNeedsOptions: boolean,
  specialNeedsOptions: Array<any>,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ReportingInformationForm = (props: ReportingInfoFormProps) => {
  const {
    change,
    disabled,
    initialValues,
    showAnnualIncome,
    isLoadingSpecialNeedsOptions,
    specialNeedsOptions,
    showSpecialNeeds,
    showMINCField,
  } = props;
  const [specialNeedsDesignationValues, setSpecialNeedsDesignationValues] =
    useState(initialValues.specialNeedsDesignationIds);
  useEffect(() => {
    setSpecialNeedsDesignationValues(initialValues.specialNeedsDesignationIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSpecialNeedsChangeCallback = useCallback(
    (specialNeedsDesignationIds) => {
      setSpecialNeedsDesignationValues(specialNeedsDesignationIds);
      change(
        'reportingInformation.specialNeedsDesignationIds',
        specialNeedsDesignationIds,
      );
    },
    [setSpecialNeedsDesignationValues, change],
  );
  const onSpecialNeedsClear = useCallback(() => {
    setSpecialNeedsDesignationValues([]);
    change('reportingInformation.specialNeedsDesignationIds', []);
  }, [setSpecialNeedsDesignationValues, change]);

  if (isLoadingSpecialNeedsOptions) return <Spinner />;
  const parseSpecialNeedsOptions = (
    data,
  ): Array<{ text: string, value: any, disabled: boolean }> => {
    return data.map((snd) => {
      const text = snd?.name ?? null;
      const value = snd?.id ?? null;
      if (!value || !text) {
        // eslint-disable-next-line no-console
        console.error(
          'Missing id or name on special needs option. Check to see that db/backend has options.',
        );
      }
      return {
        text,
        value,
        disabled: false,
      };
    });
  };

  const options = parseSpecialNeedsOptions(specialNeedsOptions);
  return (
    <FormSection name="reportingInformation">
      <Grid container spacing={2}>
        {showSpecialNeeds && (
          <Grid item xs={6}>
            <Wrapper>
              <MultiSelect
                label={'Special Needs Designation'}
                options={options}
                sx={{ width: 300 }}
                placeholder={'Choose'}
                onChange={onSpecialNeedsChangeCallback}
                onClearSelected={onSpecialNeedsClear}
                value={specialNeedsDesignationValues || []}
                checkmarks={true}
                fullWidth
                disabled={disabled}
              />
            </Wrapper>
          </Grid>
        )}
        {showAnnualIncome && (
          <Grid item xs={6}>
            <Wrapper>
              <ControlLabel>Annual Income</ControlLabel>
              <Field
                className="input-md form-control"
                name="annualIncome"
                component={renderCurrencyField}
                step=".01"
                disabled={disabled}
              />
            </Wrapper>
          </Grid>
        )}
        {showMINCField && (
          <Grid item xs={6}>
            <Wrapper>
              <Field
                name="MINCNumber"
                selectClassNames="input-md form-control"
                component={renderTextField}
                maxLength="10"
                placeholder={disabled ? '---' : ''}
                disabled={disabled}
                label={'MINC #'}
                showLabel
              />
            </Wrapper>
          </Grid>
        )}
      </Grid>
    </FormSection>
  );
};

export default ReportingInformationForm;
