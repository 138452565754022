import React, { Component } from 'react';
import FormattedDateOrDashes from '../../FormattedDateOrDashes';

type Props = {
  intl: any,
  reports: Array<any>,
  downloadLastReport: Function,
  historicalSearch: boolean,
};

type State = {
  toggles: Object,
};

class ReportsDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      toggles: {},
    };
  }

  renderReportDetailRow = (report: any, className: string) => {
    const id = report.id || '';
    // $FlowFixMe
    const createdAt = report?.createdAt ? report.createdAt : '---';
    const ame = report?.AME ?? false;
    const extension = report.filename?.split('.')?.slice(1) || '---';
    return (
      <tr key={`${id}-RW`} className={className}>
        <td>
          <a onClick={this.props.downloadLastReport(report)}>
            <FormattedDateOrDashes
              value={createdAt}
              format="YYYY-MM-DD HH:mm z"
            />
          </a>
        </td>
        <td>
          <p className="font-bold text-blue">{extension}</p>
        </td>
        <td>
          <p className="font-bold text-blue">{ame ? 'Yes' : ''}</p>
        </td>
      </tr>
    );
  };

  render() {
    const isEmpty = this.props.reports.length <= 0;
    const isHistorical = this.props.historicalSearch;

    if (isEmpty) {
      return (
        <tbody>
          <tr>
            <td>
              <h2 className="padtop10">
                {isHistorical
                  ? 'No reports available for the selected date'
                  : 'No reports in the last 90 days'}
              </h2>
            </td>
          </tr>
        </tbody>
      );
    }
    return (
      <tbody>
        {this.props.reports.map((report: any) => {
          return this.renderReportDetailRow(report, 'table-section hidden-xs');
        })}
      </tbody>
    );
  }
}

export default ReportsDetails;
