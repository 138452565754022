import { post, get } from '../utils/api';
import qs from 'qs';

class FinalAccountStatementReversalService {
  create(data: { finalAccountStatementId: string }) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const url = '/finalAccountStatementReversal';
    return post(url, JSON.stringify(data), options);
  }
  getAll(query) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const url = '/finalAccountStatementReversal?' + qs.stringify(query);
    return get(url, options);
  }
}

export default FinalAccountStatementReversalService;
