// $FlowFixMe
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import confirm from '../../components/ConfirmDialogModal';
import EditMasterTransactionCodeForm from './EditMasterTransactionCodeForm';
import { bindActionCreators } from 'redux';
import { promptToaster } from '../App/actions';
import {
  useMasterTransacionCode,
  useCodePropertyFees,
  useAllActiveProperties,
  useAsyncSaveMasterTransactionCode,
} from './hooks';
import { pathOr } from 'ramda';
import messages from './messages';

type Props = {
  intl: any,
  history: any,
  properties: Array<Object>,
  organizationId: string,
  masterTransactionCodeId: string,
  actions: any,
};

const EditMasterTransactionCode = (props: Props) => {
  const {
    organizationId,
    masterTransactionCodeId,
    intl,
    history,
    actions,
  } = props;

  const [selectedProperties, setSelectedProperties] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [propertiesWithFees, setPropertiesWithFees] = useState([]);
  const [masterTransactionCode, setMasterTransactionCode] = useState({});
  const [isSaving, setIsSaving] = useState(false);

  const isFetching = useMasterTransacionCode(
    organizationId,
    masterTransactionCodeId,
    setMasterTransactionCode,
    setSelectedProperties,
  );
  const isFetchingProperties = useAllActiveProperties(
    organizationId,
    setAllProperties,
    propertiesWithFees,
    pathOr('', ['masterSubjournal'], masterTransactionCode),
  );

  const isFetchingPropertyFees = useCodePropertyFees(
    organizationId,
    masterTransactionCodeId,
    setPropertiesWithFees,
  );

  const generateTransactionTypeOptions = () => {
    const { transactionType } = masterTransactionCode;
    return [{ value: transactionType, text: transactionType }];
  };
  const generateSubjournalOptions = () => {
    const { masterSubjournal } = masterTransactionCode;
    return [{ value: masterSubjournal, text: masterSubjournal }];
  };
  const handleCancel = () => {
    confirm(intl.formatMessage(messages.cancelConfirmation), {
      intl,
    }).then(() => {
      history.push('/manage-transaction-codes');
    });
  };

  const handleMultiselectChange = (props) => {
    setSelectedProperties(props);
  };
  const handleSubmit = useAsyncSaveMasterTransactionCode({
    intl: props.intl,
    history: props.history,
    masterTransactionCode: masterTransactionCodeId,
    properties: selectedProperties,
    toasterFn: actions.promptToaster,
    setIsSaving,
    organizationId,
  });

  if (pathOr(false, ['isRequiredForAllProperties'], masterTransactionCode)) {
    history.push('/404');
  }

  return (
    <DocumentTitle title={intl.formatMessage(messages.title)}>
      <div>
        {!isFetching && (
          <EditMasterTransactionCodeForm
            intl={props.intl}
            initialValues={{
              code: masterTransactionCode.code,
              description: masterTransactionCode.description,
              defaultCreditGLAccount: masterTransactionCode.creditAccountNumber,
              defaultDebitGLAccount: masterTransactionCode.debitAccountNumber,
              subjournal: masterTransactionCode.masterSubjournal,
              transactionType: masterTransactionCode.transactionType,
            }}
            properties={allProperties}
            handleSubmit={handleSubmit}
            selectedProperties={selectedProperties}
            handleMultiselectChange={handleMultiselectChange}
            nonePropertiesSelected={false}
            hideMultiselect={
              isSaving || isFetchingProperties || isFetchingPropertyFees
            }
            handleCancel={handleCancel}
            showErrorMessage={false}
            handlePropertiesSelectedError={() => {}}
            transactionTypeOptions={generateTransactionTypeOptions()}
            subjournalOptions={generateSubjournalOptions()}
            isSubmitting={isSaving}
            history={history}
          />
        )}
      </div>
    </DocumentTitle>
  );
};

export const mapStateToProps = ({ app }: Object, ownProps: Object): Object => {
  return {
    currentUser: app.currentUser.user,
    organizationId: app.currentUser.user.organizationId,
    masterTransactionCodeId: ownProps.match.params.masterTransactionCodeId,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  );
  return { actions };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EditMasterTransactionCode));
