import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.EditProspectHousehold.Title',
    defaultMessage: 'Edit Household for '
  },
  successHeader: {
    id: 'App.EditProspectHousehold.SuccessHeader',
    defaultMessage: 'Success'
  },
  successDescription: {
    id: 'App.EditProspectHousehold.SuccessDescription',
    defaultMessage: 'Prospect household successfully updated.'
  },
  errorHeader: {
    id: 'App.EditProspectHousehold.errorHeader',
    defaultMessage: 'Error'
  }
});

export default messages;
