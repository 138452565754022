export const CREATE_TRANSACTION = 'app/CREATE_TRANSACTION';
export const CREATE_TRANSACTION_SUCCESS = 'app/CREATE_TRANSACTION_SUCCESS';
export const CREATE_TRANSACTION_FAILURE = 'app/CREATE_TRANSACTION_FAILURE';

export const GET_TRANSACTION_TYPES = 'app/GET_TRANSACTION_TYPES';
export const GET_TRANSACTION_TYPES_FAILURE =
  'app/GET_TRANSACTION_TYPES_FAILURE';
export const GET_TRANSACTION_TYPES_SUCCESS =
  'app/GET_TRANSACTION_TYPES_SUCCESS';

export const GET_PROPERTY_TRANSACTION_CODES =
  'app/GET_PROPERTY_TRANSACTION_CODES';
export const GET_PROPERTY_TRANSACTION_CODES_FAILURE =
  'app/GET_PROPERTY_TRANSACTION_CODES_FAILURE';
export const GET_PROPERTY_TRANSACTION_CODES_SUCCESS =
  'app/GET_PROPERTY_TRANSACTION_CODES_SUCCESS';

export const GET_PROPERTY_SUBJOURNAL_TYPES =
  'app/GET_PROPERTY_SUBJOURNAL_TYPES';
export const GET_PROPERTY_SUBJOURNAL_TYPES_SUCCESS =
  'app/GET_PROPERTY_SUBJOURNAL_TYPES_SUCCESS';
export const GET_PROPERTY_SUBJOURNAL_TYPES_FAILURE =
  'app/GET_PROPERTY_SUBJOURNAL_TYPES_FAILURE';

export const HANDLE_SOCKET_TRANSACTION_SUCCESS =
  'app/HANDLE_SOCKET_TRANSACTION_SUCCESS';
export const HANDLE_SOCKET_TRANSACTION_FAILURE =
  'app/HANDLE_SOCKET_TRANSACTION_FAILURE';

export const FORM_NAME = 'createTransaction';
export const DOCUMENT_NUMBER_MAX_LENGTH = 25;
export const NOTE_MAX_LENGTH = 100;
