export const RATING_FRACTION_DIGITS = 2;
export const STAR_RATING_PRECISION = 0.01;
export const ID_KEY_ID = 'id';
export const REVIEW_ID = 'reviewId';

export const GOOGLE_SOURCE_TEXT = 'Google/Google Places';
export const GOOGLE_SOURCE_VALUE = 'google';
export const FACEBOOK_SOURCE_TEXT = 'Facebook';
export const FACEBOOK_SOURCE_VALUE = 'facebook';
export const SOURCE_TO_TEXT_MAP = {
  [GOOGLE_SOURCE_VALUE]: GOOGLE_SOURCE_TEXT,
  [FACEBOOK_SOURCE_VALUE]: FACEBOOK_SOURCE_TEXT,
};

export const STAR_BASED = 'star';
export const RECOMMEND_BASED = 'recommend';

export const SOURCE_TO_RATING_TYPE_MAP = {
  [GOOGLE_SOURCE_VALUE]: STAR_BASED,
  [FACEBOOK_SOURCE_VALUE]: RECOMMEND_BASED,
};

export const PAGE_SIZE_INCREASE = 5;

export const DATE_FORMAT = 'MMMM DD, YYYY h:MM A';

export const MULTISELECT_OPTIONS = {
  ratings: [
    { value: 'one', text: '1 Star' },
    { value: 'two', text: '2 Stars' },
    { value: 'three', text: '3 Stars' },
    { value: 'four', text: '4 Stars' },
    { value: 'five', text: '5 Stars' },
  ],
  status: [
    { value: 'all-status', text: 'All Status' },
    { value: 'response-needed', text: 'Response Needed' },
    { value: 'responded', text: 'Responded' },
  ],
};

export const CATEGORY_OPTIONS = [
  {
    text: 'All Categories',
    value: 'all',
  },
  {
    text: 'Recommends',
    value: 'recommended',
  },
  {
    text: "Doesn't Recommend",
    value: 'notRecommended',
  },
];
