import React, { useState } from 'react';
import { Modal, Row, Col, Button, FormControl } from 'react-bootstrap';
import { pathOr } from 'ramda';
import { useUploadAttachment, useUpdateAttachment } from './hooks.js';
import messages from './messages';
import Spinner from '../../../../components/Spinner';
import Alert from '../../../../components/Alert';

type OpenMode = 'create' | 'edit';

type ModalProps = {
  intl: Object,
  show: boolean,
  document: Object,
  mode: OpenMode,
  depositId: string,
  selectedPropertyId: string,
  selectedOrganizationId: string,
  onClose: Function,
  promptToaster: Function,
  refresh: Function,
};

const AddDocument = ({
  intl,
  show,
  document,
  mode,
  selectedPropertyId,
  selectedOrganizationId,
  depositId,
  onClose,
  promptToaster,
  refresh,
}: ModalProps) => {
  const hooksArgs = {
    intl,
    propertyId: selectedPropertyId,
    organizationId: selectedOrganizationId,
    depositId,
    promptToaster,
    refresh,
  };

  const initialFilename = pathOr('', ['name'], document);
  const initialNotes = pathOr('', ['notes'], document);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [filename, setFilename] = useState(initialFilename);
  const [file, setFile] = useState(undefined);
  const [errorMsg, setErrorMsg] = useState('');
  const [notes, setNotes] = useState(initialNotes);

  const [uploaderIsSaving, onUploadDocument] = useUploadAttachment(hooksArgs);
  const [updaterIsSaving, onUpdateDocument] = useUpdateAttachment(hooksArgs);

  const isSaving = uploaderIsSaving || updaterIsSaving;
  const hasError = errorMsg && errorMsg.length > 0;

  const onFileSelectionChanged = (event: any) => {
    if (!(event && event.target && event.target.value)) {
      setErrorMsg(intl.formatMessage(messages.fileSelectionError));
    }

    setErrorMsg('');
    const filename = event.target.value.replace(/.*(\/|\\)/, '');
    const file = event.target.files[0];
    setFilename(filename);
    setFile(file);
  };

  const reset = () => {
    setShowCancelConfirmation(false);
    setFilename('');
    setFile(undefined);
    setErrorMsg('');
    setNotes('');
  };

  const onHide = () => {
    reset();
    onClose();
  };

  const saveAndClose = async () => {
    const callback = () => {
      refresh();
      onHide();
    };
    onUploadDocument({ file, notes }, () => callback);
  };

  const saveAndAddNew = async () => {
    const callback = () => {
      refresh();
      reset();
    };
    onUploadDocument({ file, notes }, () => callback);
  };

  const save = async () => {
    const callback = () => {
      refresh();
      onHide();
    };
    onUpdateDocument(document, notes, () => callback);
  };

  const isSaveDisabled = () => {
    const hasNoFile = !(file !== undefined);
    return showCancelConfirmation || isSaving || hasError || hasNoFile;
  };

  return (
    <form encType="multipart/form-data">
      <div className="mainbody">
        <Modal backdrop bsSize="lg" show={show} onHide={onHide}>
          <Modal.Header closeButton>
            <i className="icon et-upload" />
            <Modal.Title componentClass="h1">
              {intl.formatMessage(messages.title)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert
              message={intl.formatMessage(messages.confirmCancel)}
              onDismiss={() => setShowCancelConfirmation(false)}
              onProceed={onHide}
              show={showCancelConfirmation}
            />
            {isSaving && (
              <Row>
                <div className="loading-container text-center">
                  <Spinner small />
                  <h5>{intl.formatMessage(messages.uploading)}</h5>
                </div>
              </Row>
            )}
            {!isSaving && (
              <Row>
                <Col xs={12} sm={4}>
                  <div className="form-group">
                    <label className="input">
                      {intl.formatMessage(messages.fileName)}
                    </label>
                    <input
                      className="inputfile inputfile-custom"
                      id="uploadDocument"
                      type="file"
                      name="file"
                      onChange={onFileSelectionChanged}
                      disabled={mode === 'edit'}
                    />
                    <label htmlFor="uploadDocument">
                      <span>{filename}</span>
                      <strong
                        style={
                          mode === 'edit' ? { backgroundColor: '#9A9A9A' } : {}
                        }
                      >
                        {intl.formatMessage(messages.browse)}
                      </strong>
                    </label>
                    {hasError && <span className="has-error">{errorMsg}</span>}
                  </div>
                </Col>
                <Col xs={12} sm={4}>
                  <label className="input">
                    {intl.formatMessage(messages.documentType)}
                  </label>
                  <FormControl componentClass="select" disabled={true}>
                    <option value="Deposit Details">Deposit Details</option>
                  </FormControl>
                </Col>
              </Row>
            )}
            {!isSaving && (
              <Row>
                <Col xs={12} sm={12}>
                  <label className="input">
                    {intl.formatMessage(messages.notes)}
                  </label>
                  <textarea
                    value={notes}
                    maxLength={2000}
                    onChange={(evt) => setNotes(evt.target.value)}
                    className={'form-control'}
                    rows="6"
                    placeholder="Add your notes..."
                  />
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Row>
              {mode === 'create' ? (
                <div id="createMode">
                  <Col xs={4}>
                    <Button
                      bsStyle="default"
                      className="pull-right"
                      onClick={() => setShowCancelConfirmation(true)}
                      disabled={showCancelConfirmation || isSaving}
                    >
                      {intl.formatMessage(messages.cancel)}
                    </Button>
                  </Col>
                  <Col xs={4} className="text-center">
                    <Button
                      bsStyle="primary"
                      onClick={saveAndClose}
                      disabled={isSaveDisabled()}
                    >
                      {intl.formatMessage(messages.saveAndClose)}
                    </Button>
                  </Col>

                  <Col xs={4}>
                    <Button
                      bsStyle="primary"
                      className="pull-left"
                      onClick={saveAndAddNew}
                      disabled={isSaveDisabled()}
                    >
                      {intl.formatMessage(messages.saveAndAddNew)}
                    </Button>
                  </Col>
                </div>
              ) : (
                <div id="editMode">
                  <Col xs={12} sm={6}>
                    <Button
                      bsStyle="default"
                      className="pull-right"
                      onClick={() => setShowCancelConfirmation(true)}
                      disabled={showCancelConfirmation || isSaving}
                    >
                      {intl.formatMessage(messages.cancel)}
                    </Button>
                  </Col>
                  <Col xs={12} sm={6}>
                    <Button
                      bsStyle="primary"
                      className="pull-left"
                      onClick={save}
                    >
                      {intl.formatMessage(messages.save)}
                    </Button>
                  </Col>
                </div>
              )}
            </Row>
          </Modal.Footer>
        </Modal>
      </div>
    </form>
  );
};

export default AddDocument;
