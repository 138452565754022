import { defineMessages } from 'react-intl';

const messages = defineMessages({
  success: {
    id: 'App.FinalAccountStatement.Success',
    defaultMessage: 'Success',
  },
  submitted: {
    id: 'App.FinalAccountStatement.Submitted',
    defaultMessage: `Final Account Statement request accepted.\n
    Please wait for download to begin or check the "Documents" tab in a few moments.`,
  },
  generated: {
    id: 'App.FinalAccountStatement.Generated',
    defaultMessage:
      'Balance written off and Final Account Statement generated.',
  },
  error: {
    id: 'App.FinalAccountStatement.Error',
    defaultMessage: 'Error',
  },
});

export default messages;
