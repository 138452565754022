import moment from 'moment';
import { isNil } from 'ramda';
import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableRow,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { HouseholdSection } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { InsuranceIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change, reset } from 'redux-form';
import type { Intl } from '../../../types';
import { getAllDocuments } from '../../ManageDocuments/actions';
import {
  DOCUMENTS_DEFAULT_LIMIT,
  DOCUMENTS_DEFAULT_PAGE,
  DOCUMENTS_DEFAULT_SORTING,
} from '../../ManageDocuments/constants';
import { useInsurancePolicies } from './hooks';
import InsuranceModal from './InsuranceModal';
import messages from './messages';

type Props = {
  intl: Intl,
  propertyId: string,
  organizationId: string,
  householdId: string,
  actions: {
    change: typeof change,
    reset: typeof reset,
    getAllDocuments: typeof getAllDocuments,
  },
  applicationId: string,
};

const formatValue = (
  value: string,
  fallback: string,
  format: (v: string) => string,
) => {
  if (!value) return fallback;
  if (format) return format(value);
  return value;
};

function InsuranceSection({
  intl,
  organizationId,
  propertyId,
  householdId,
  actions,
  applicationId,
}: Props) {
  const [showInsuranceModal, setShowInsuranceModal] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [isViewMode, setIsViewMode] = useState(false);

  const [
    insurancePolicies,
    isLoading,
    addInsurancePolicy,
    wasPolicyCreated,
    editInsurancePolicy,
    resetWasPolicyCreated,
  ] = useInsurancePolicies(organizationId, propertyId, householdId);

  const handleAddInsurance = () => {
    setIsViewMode(false);
    setShowInsuranceModal(true);
  };

  const handleCloseInsuranceModal = () => {
    if (isViewMode || isNil(selectedInsurance) || wasPolicyCreated) {
      setShowInsuranceModal(false);
      setSelectedInsurance(null);
      resetWasPolicyCreated();
    } else {
      actions.reset('InsuranceForm');
      setIsViewMode(true);
    }
  };

  const handleViewInsurance = (insurance) => () => {
    setIsViewMode(true);
    setSelectedInsurance(insurance);
    setShowInsuranceModal(true);
  };

  const handleEditInsurance = () => {
    actions.change('InsuranceForm', 'policyStartDate', null);
    actions.change('InsuranceForm', 'policyEndDate', null);
    actions.change('InsuranceForm', 'isPropertyListedAsInterestedParty', null);
    actions.change('InsuranceForm', 'notes', null);
    actions.change('InsuranceForm', 'proofOfCoverage', null);
    setIsViewMode(false);
  };

  useEffect(() => {
    if (isViewMode && isNil(selectedInsurance)) {
      setShowInsuranceModal(false);
    }
  }, [isViewMode, selectedInsurance]);

  useEffect(() => {
    if (wasPolicyCreated) {
      actions.getAllDocuments(
        applicationId,
        DOCUMENTS_DEFAULT_PAGE,
        DOCUMENTS_DEFAULT_LIMIT,
        DOCUMENTS_DEFAULT_SORTING,
      );
    }
  }, [actions, applicationId, wasPolicyCreated]);

  return (
    <>
      <HouseholdSection
        title={intl.formatMessage(messages.insurance)}
        Icon={InsuranceIcon}
        buttonProps={{ onClick: handleAddInsurance }}
        headers={[
          messages.provider,
          messages.policyStartDate,
          messages.policyEndDate,
          messages.proofOfCoverage,
        ]}
        rows={
          insurancePolicies?.length
            ? insurancePolicies.map((insurance) => {
                const providerName = formatValue(
                  insurance?.providerName,
                  intl.formatMessage(messages.null),
                  (v) => <a onClick={handleViewInsurance}>{v}</a>,
                );
                const policyStartDate = formatValue(
                  insurance?.policyStartDate,
                  intl.formatMessage(messages.null),
                  (v) => moment(v).format('MMM DD, YYYY'),
                );
                const policyEndDate = formatValue(
                  insurance?.policyEndDate,
                  intl.formatMessage(messages.null),
                  (v) => moment(v).format('MMM DD, YYYY'),
                );
                const proofOfCoverage = formatValue(
                  insurance,
                  intl.formatMessage(messages.null),
                  (v) =>
                    intl.formatMessage(
                      v.proofOfCoverage?.name
                        ? messages.received
                        : messages.none,
                    ),
                );
                return (
                  <TableRow
                    key={insurance.id}
                    sx={{ backgroundColor: 'transparent !important' }}
                  >
                    <TableCell>{providerName}</TableCell>
                    <TableCell>{policyStartDate}</TableCell>
                    <TableCell>{policyEndDate}</TableCell>
                    <TableCell>{proofOfCoverage}</TableCell>
                  </TableRow>
                );
              })
            : []
        }
      />
      <InsuranceModal
        intl={intl}
        show={showInsuranceModal}
        handleClose={handleCloseInsuranceModal}
        addInsurancePolicy={addInsurancePolicy}
        isLoading={isLoading}
        wasPolicyCreated={wasPolicyCreated}
        isViewModal={isViewMode}
        selectedInsurance={selectedInsurance}
        editInsurancePolicy={editInsurancePolicy}
        handleEditInsurance={handleEditInsurance}
        organizationId={organizationId}
        propertyId={propertyId}
      />
    </>
  );
}

function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    { change, reset, getAllDocuments },
    dispatch,
  );
  return { actions };
}

export default connect(null, mapDispatchToProps)(InsuranceSection);
