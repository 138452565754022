export const requiredVal = (value) => (!value ? 'Required' : undefined);

export const isIdentificationNumberMissing = ({
  alienRegistration,
  passportCountry,
  passportNumber,
  socialSecurityNumber,
  stateIdNumber,
  stateIdOrigin,
}: Object) => {
  const driversLicenseStarted = stateIdNumber || stateIdOrigin;
  const passportStarted = passportCountry || passportNumber;

  return (
    !driversLicenseStarted &&
    !passportStarted &&
    !socialSecurityNumber &&
    !alienRegistration
  );
};
