import React from 'react';
import messages from './messages';
import TaskTable from './TaskTable';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Legend } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import type { FeatureFlags } from '../../../types';
import {
  mapRenewalsInProcess,
  renewalsInProcessHeaders,
} from './utils/renewalsInProcess.utils';
import {
  mapScheduledMoveOuts,
  scheduledMoveOutHeaders,
} from './utils/scheduledMoveOut.utils';
import { mapPendingFAS, pendingFASHeaders } from './utils/pendingFAS.utils';
import {
  mapTransfersInProcess,
  transferInProcessHeaders,
} from './utils/transfersInProcess.utils';
import {
  mapApplicationsScheduledMoveIn,
  scheduledMoveInsHeaders,
} from './utils/scheduledMoveIn.utils';
import {
  applicationsInProcessHeaders,
  mapApplicationsInProcess,
} from './utils/applicationsInProcess.utils';
import {
  mapProspectsToFollowUp,
  prospectFollowUpHeaders,
} from './utils/prospectFollowUp.utils';
import {
  mapOpenWorkOrders,
  openWorkOrdersHeaders,
} from './utils/openWorkOrder.utils';
import type { Tasks } from './types';

type Props = {
  tasks: Tasks,
  assignees?: Object[],
  selectedPropertyClass: string,
  intl: Object,
  onChangeAssignee?: Function,
  isTasksLoading: boolean,
};

export const TaskTab = ({
  tasks,
  assignees,
  selectedPropertyClass,
  intl,
  onChangeAssignee,
  isTasksLoading,
}: Props) => {
  const flags: FeatureFlags = useFlags();
  const residentActivityTaskTablesNonConventionalProperty = [
    {
      title: intl.formatMessage(messages.renewalsInProcessTitle),
      headers: renewalsInProcessHeaders(intl),
      items: mapRenewalsInProcess(tasks.renewalsInProcess),
      showLegendGreenIcon: true,
    },
    {
      title: intl.formatMessage(messages.scheduledMoveOutTitle),
      headers: scheduledMoveOutHeaders(intl),
      items: mapScheduledMoveOuts(tasks.scheduledMoveOuts),
    },
    {
      title: intl.formatMessage(messages.pendingFASTitle),
      headers: pendingFASHeaders(intl),
      items: mapPendingFAS(tasks.pendingFAS),
    },
  ];

  const residentActivityTaskTablesConventionalProperty = [
    {
      title: intl.formatMessage(messages.renewalsInProcessTitle),
      headers: renewalsInProcessHeaders(intl),
      items: mapRenewalsInProcess(tasks.renewalsInProcess),
      showLegendGreenIcon: true,
    },
    {
      title: intl.formatMessage(messages.transfersInProcessTitle),
      headers: transferInProcessHeaders(intl),
      items: mapTransfersInProcess(tasks.transfersInProcess),
      showLegendGreenIcon: true,
    },
    {
      title: intl.formatMessage(messages.scheduledMoveOutTitle),
      headers: scheduledMoveOutHeaders(intl),
      items: mapScheduledMoveOuts(tasks.scheduledMoveOuts),
    },
    {
      title: intl.formatMessage(messages.pendingFASTitle),
      headers: pendingFASHeaders(intl),
      items: mapPendingFAS(tasks.pendingFAS),
    },
  ];

  const residentActivityTaskTables =
    selectedPropertyClass === 'Conventional'
      ? residentActivityTaskTablesConventionalProperty
      : residentActivityTaskTablesNonConventionalProperty;

  const applicantActivityTaskTables = [
    {
      title: intl.formatMessage(messages.scheduledMoveInsTitle),
      headers: scheduledMoveInsHeaders({
        intl,
        taskTabNumberOfBedroomsFlag: flags.taskTabNumberOfBedrooms,
      }),
      items: mapApplicationsScheduledMoveIn({
        scheduledMoveIns: tasks.scheduledMoveIns,
        taskTabNumberOfBedroomsFlag: flags.taskTabNumberOfBedrooms,
      }),
      showLegendGreenIcon: true,
    },
    {
      title: intl.formatMessage(messages.applicationsInProcessTitle),
      headers: applicationsInProcessHeaders({
        intl,
        taskTabNumberOfBedroomsFlag: flags.taskTabNumberOfBedrooms,
      }),
      assignees: assignees,
      onChangeAssignee: onChangeAssignee,
      items: mapApplicationsInProcess({
        applicationsInProcess: tasks.applicationsInProcess,
        taskTabNumberOfBedroomsFlag: flags.taskTabNumberOfBedrooms,
        taskTabAssigneeDropdownFlag: flags.taskTabAssigneeDropdown,
      }),
    },
    {
      title: intl.formatMessage(messages.prospectsFollowUpTitle),
      headers: prospectFollowUpHeaders(intl),
      assignees: assignees,
      onChangeAssignee: onChangeAssignee,
      items: mapProspectsToFollowUp(
        tasks.prospectsToFollowUp,
        flags.taskTabAssigneeDropdown,
      ),
    },
  ];

  const openWorkOrderTaskTables = [
    {
      title: intl.formatMessage(messages.openWorkOrdersTitle),
      headers: openWorkOrdersHeaders({
        intl,
        workOrderDueDateTaskTabFlag: flags.workOrderDueDateTaskTab,
      }),
      items: mapOpenWorkOrders({
        openWorkOrders: tasks.openWorkOrders,
        workOrderDueDateTaskTabFlag: flags.workOrderDueDateTaskTab,
      }),
    },
  ];

  const PanelTitle = (props) => (
    <Typography variant="h3">
      <Stack direction="row" alignItems="center" marginBottom={2}>
        <i className={`${props.icon} padright10 text-blue`} />
        {props.title}
      </Stack>
    </Typography>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="column">
          <Typography variant="h2" paragraph>
            {intl.formatMessage(messages.taskSummary)}
          </Typography>
          <Legend
            data={[
              {
                variant: 'warning',
                label: intl.formatMessage(messages.legendRed),
              },
              {
                variant: 'inactive',
                label: intl.formatMessage(messages.legendYellow),
              },
            ]}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <PanelTitle
          icon="et-people"
          title={intl.formatMessage(messages.residentActivity)}
        />
        {residentActivityTaskTables.map((tableProps, index) => (
          <TaskTable
            key={index}
            {...tableProps}
            intl={intl}
            isTasksLoading={isTasksLoading}
          />
        ))}
        <PanelTitle
          icon="et-services"
          title={intl.formatMessage(messages.workOrders)}
        />
        {openWorkOrderTaskTables.map((tableProps) => (
          <TaskTable
            {...tableProps}
            intl={intl}
            isTasksLoading={isTasksLoading}
          />
        ))}
      </Grid>
      <Grid item xs={12} md={6}>
        <PanelTitle
          icon="et-sales"
          title={intl.formatMessage(messages.applicantActivity)}
        />
        {applicantActivityTaskTables.map((tableProps) => (
          <TaskTable
            {...tableProps}
            intl={intl}
            isTasksLoading={isTasksLoading}
          />
        ))}
      </Grid>
    </Grid>
  );
};
