import { createSelector } from 'reselect';
import type { GlobalState } from '../App/types';

const getCreateApplicationState = (state: GlobalState) => state.createApplication;

export const getProspect = createSelector(getCreateApplicationState, ({ prospect }) => prospect);

export const getProspectName = createSelector(
  getProspect,
  (prospect) => (prospect ? `${prospect.firstName} ${prospect.lastName}` : '---')
);

export const getProspectId = createSelector(getProspect, (prospect) => (prospect ? prospect.id : ''));

export const getPropertyId = createSelector(getProspect, (prospect) => (prospect ? prospect.propertyId : ''));
