import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { find, path, pathOr, propEq } from 'ramda';

import PaginationFooter from '../../components/PaginationFooter/index.js';
import CreateMiscTransaction from './CreateMiscTransaction/index.js';
import MiscTransactionsHeader from './MiscTransactionsHeader/index.js';
import MiscTransactionsDetails from './MiscTransactionsDetails/index.js';
import {
  getAllPropertyPeriods,
  getAllMiscTransactions,
  clearLoadedMiscTransactions,
  reverseMiscTransaction,
} from './actions';
import type { Period, MiscTransaction } from './types.js';
import { FORM_NAME } from './CreateMiscTransaction/constants.js';
import { getPropertyTransactionCodes } from '../../components/CreateTransaction/actions';
import type { Property } from '../App/types.js';

type Props = {
  organizationId: string,
  property: Property,
  permissions: Array<Object>,
  periods: Array<Period>,
  miscTransactions: Array<MiscTransaction>,
  transactionCodes: Array<Object>,
  meta: Object,
};

type InjectedProps = {
  intl: any,
  actions: Object,
};

type State = {
  currentPage: number,
  currentPeriod: string,
  modal: string,
};

export class ManageMiscTransactions extends Component<
  Props & InjectedProps,
  State,
> {
  constructor(props: Props & InjectedProps) {
    super(props);
    this.state = {
      currentPage: 1,
      currentPeriod: '',
      modal: '',
    };
  }

  componentDidMount() {
    const { organizationId, property } = this.props;
    this.props.actions.getPropertyTransactionCodes(organizationId, property);
    this.props.actions.getAllPropertyPeriods();
    this.props.actions.getAllMiscTransactions(this.state.currentPage);
  }

  componentWillUnmount() {
    this.props.actions.clearLoadedMiscTransactions();
  }

  showCreateMiscTransaction = () => {
    this.setState({ modal: FORM_NAME });
  };

  onPeriodChange = ({ target: { value } }: Object) => {
    this.setState({ ...this.state, currentPeriod: value, currentPage: 1 });
    this.props.actions.getAllMiscTransactions(1, value);
  };

  onPageChange = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.props.actions.getAllMiscTransactions(
      nextPage,
      this.state.currentPeriod,
    );
  };

  resetModal = () => {
    this.setState({ modal: '' });
  };

  reverseFunction = (id: string) =>
    this.props.actions.reverseMiscTransaction(id);

  filterAndParseCodes = (transactionCodes: Array<Object>): any => {
    return transactionCodes.reduce(
      (codes, code) => {
        const value = code.id;
        const transactionCode = pathOr('', ['transactionCode', 'code'], code);
        const codeDescription = pathOr(
          '',
          ['transactionCode', 'description'],
          code,
        );
        const text = `${transactionCode} - ${codeDescription}`;
        const isChargeCode =
          code.userSelectable === true &&
          path(['transactionCode', 'category'], code) === 'Misc' &&
          path(['transactionCode', 'transactionType', 'name'], code) ===
            'Misc Charge';
        const isPaymentCode =
          (code.userSelectable === true || transactionCode === 'REVPMT') &&
          path(['transactionCode', 'transactionType', 'name'], code) ===
            'Payment' &&
          path(['transactionCode', 'masterSubjournal', 'description'], code) ===
            'Resident';

        if (isChargeCode) {
          codes.chargeCodes.push({ value, text });
        } else if (isPaymentCode) {
          codes.paymentCodes.push({ value, text });
        }
        return codes;
      },
      { chargeCodes: [], paymentCodes: [] },
    );
  };

  render() {
    const {
      intl: { formatMessage },
      periods,
      miscTransactions,
      meta,
      transactionCodes,
      permissions,
    } = this.props;
    const canReverse = !!find(propEq('scope', 'misc-income-edit'))(permissions);
    const codes = transactionCodes
      ? this.filterAndParseCodes(transactionCodes)
      : { chargeCodes: [], paymentCodes: [] };
    return (
      <div className="bodywrap" data-test="manage-misc-transactions">
        <CreateMiscTransaction
          chargeCodes={codes.chargeCodes}
          paymentCodes={codes.paymentCodes}
          onClose={this.resetModal}
          show={this.state.modal === FORM_NAME}
        />
        <MiscTransactionsHeader
          formatMessage={formatMessage}
          periods={periods}
          onPeriodChange={this.onPeriodChange}
          createMiscTransactionClick={this.showCreateMiscTransaction}
          data-test="manage-misc-transactions-header"
        />
        <MiscTransactionsDetails
          intl={this.props.intl}
          miscTransactions={miscTransactions}
          reverseMiscTransaction={this.reverseFunction}
          canReverse={canReverse}
          data-test="manage-misc-transactions-details"
        />
        {this.props.meta.pageCount > 1 ? (
          <PaginationFooter
            currentPage={this.state.currentPage}
            limit={20}
            count={meta.count}
            totalCount={meta.totalCount}
            pageCount={meta.pageCount}
            onPageChange={this.onPageChange}
            data-test="manage-misc-transactions-pagination"
          />
        ) : null}
      </div>
    );
  }
}

export const mapStateToProps = ({
  app,
  currentProperty,
  financials: {
    miscTransactions: { periods, miscTransactions, meta },
  },
}: Object): Object => {
  return {
    organizationId: app.currentUser.user.organizationId,
    property: app.selectedProperty,
    permissions: app.currentUser.permissions,
    transactionCodes: currentProperty.transactionCodes.codes,
    miscTransactions,
    periods,
    meta,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    actions: bindActionCreators(
      {
        getPropertyTransactionCodes,
        getAllPropertyPeriods,
        getAllMiscTransactions,
        clearLoadedMiscTransactions,
        reverseMiscTransaction,
      },
      dispatch,
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ManageMiscTransactions));
