import type { Action } from '../App/types';
import type { ManageDocumentsState } from './types';
import * as ActionTypes from './constants';

const initialState: ManageDocumentsState = {
  applicationId: '',
  documentTypes: [],
  residentLetterDocumentTypes: [],
  application: undefined,
  isSaving: false,
  hasError: false,
  documentsListStatus: 'updated',
  documents: [],
  meta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  currentSorting: {
    fieldName: '',
    order: '',
  },
  columnOrder: {
    createdAt: 'sortable',
    documentTypeId: 'sortable',
    applicantId: 'sortable',
    name: 'sortable',
  },
};

const manageDocumentsReducer = (
  state: ManageDocumentsState = initialState,
  action: Action<*, *>,
): ManageDocumentsState => {
  const { type, payload, meta } = action;
  switch (type) {
    case ActionTypes.GET_DOCUMENT_TYPES_SUCCESS: {
      return { ...state, documentTypes: payload };
    }
    case ActionTypes.GET_RESIDENT_LETTER_DOCUMENT_TYPES_SUCCESS: {
      return { ...state, residentLetterDocumentTypes: payload };
    }
    case ActionTypes.SAVE_DOCUMENT: {
      return { ...state, isSaving: true, hasError: false };
    }
    case ActionTypes.SAVE_DOCUMENT_SUCCESS: {
      return { ...state, isSaving: false };
    }
    case ActionTypes.DOCUMENTS_LIST_CHANGED: {
      return { ...state, documentsListStatus: 'changed' };
    }
    case ActionTypes.DOCUMENTS_LIST_UPDATED: {
      return { ...state, documentsListStatus: 'updated' };
    }
    case ActionTypes.SAVE_DOCUMENT_ERROR: {
      return { ...state, isSaving: false, hasError: true };
    }
    case ActionTypes.GET_ALL_DOCUMENTS: {
      return { ...state, currentSorting: payload.sorting };
    }
    case ActionTypes.GET_ALL_DOCUMENTS_SUCCESS: {
      return { ...state, meta, documents: payload };
    }
    case ActionTypes.UPDATE_COLUMNS_SORT_VALUE: {
      return {
        ...state,
        columnOrder: Object.assign(
          {},
          { ...initialState.columnOrder },
          payload,
        ),
      };
    }
    default:
      return state;
  }
};

export default manageDocumentsReducer;
