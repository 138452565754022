import { createSelector } from 'reselect';
import type { GlobalState } from '../App/types';
import { path, prop, pathOr } from 'ramda';

const getCreateCommercialTenantState = (state: GlobalState, props: Object) => {
  return { tenant: state.createCommercialTenant, history: props };
};
export const getApplicant = createSelector(
  getCreateCommercialTenantState,
  ({ tenant, history }) => {
    if (!tenant.currentApplication) return null;
    const applicants = tenant.currentApplication.applicants;

    if (!applicants) {
      history.push('/');
      window.location.reload();
    }
    return applicants.find((applicant) =>
      pathOr(null, ['applicantCustomer', 'isProspect'], applicant),
    );
  },
);

export const getApplicantName = createSelector(getApplicant, (applicant) =>
  applicant ? `${applicant.name}` : '---',
);

export const getUnit = createSelector(
  getCreateCommercialTenantState,
  ({ tenant }) => {
    if (!tenant.currentApplication) return null;
    const au = tenant.currentApplication.au;
    return au.unit;
  },
);

export const getUnitNumber = createSelector(getUnit, (unit) =>
  unit ? `${unit.number}` : '---',
);

export const getLease = createSelector(
  getCreateCommercialTenantState,
  ({ tenant }) => path(['currentApplication', 'au', 'lease'], tenant),
);

export const getLeaseStartDate = createSelector(getLease, (lease) =>
  prop('startDate', lease),
);

export const getRentStartDate = createSelector(getLease, (lease) =>
  pathOr('---', ['rentStartDate'], lease),
);

export const getIsRentDateEstimatedStatus = createSelector(
  getLease,
  (lease) => {
    const isRentDateEstimated = prop('isRentDateEstimated', lease);

    if (isRentDateEstimated === false) {
      return {
        valid: true,
        statusText: 'Not Estimated',
      };
    }

    return {
      valid: false,
      statusText: 'Estimated',
    };
  },
);
