import React from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import messages from '../messages';

type Props = {
  onCreateActivity: Function,
};

const ActionsMenu = ({ onCreateActivity }: Props) => {
  return (
    <Dropdown bsSize="small">
      <Dropdown.Toggle>
        <i className="et-bolt" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <ElementWithPermissions scope={['activity-create']}>
          <MenuItem onSelect={onCreateActivity} eventKey="1">
            <FormattedMessage {...messages.createActivity} />
          </MenuItem>
        </ElementWithPermissions>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionsMenu;
