import { validateRequired } from '../../../../utils/validations';
import messages from '../messages';

const validate = (values: Object, { intl }: Object) => {
  const errors = {};
  [
    'propertyTransactionCodeChargeId',
    'propertyTransactionCodePaymentId',
    'documentNumber',
    'note',
    'amount'
  ].forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      errors[fieldName] = intl.formatMessage(messages.required);
    }
  });

  return errors;
};

export default validate;
