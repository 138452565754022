import { ENABLE_PORTAL, DISABLE_PORTAL, ONBOARD_PORTAL } from './constants';
import type { Action } from '../../App/types';
import type { PortalSettings } from './types';

export function enablePortal(
  organizationId: string,
  propertyId: string,
  updateProperties: Function,
  sendEmails?: boolean = false,
  settings: PortalSettings = {
    isElectronicLeaseSigningActive: true,
    isUnitSelectionEnabled: false,
    applyWithoutUnit: null,
  },
): Action<any> {
  return {
    type: ENABLE_PORTAL,
    payload: {
      organizationId,
      propertyId,
      updateProperties,
      sendEmails,
      settings,
    },
  };
}

export function disablePortal(
  organizationId: string,
  propertyId: string,
  updateProperties: Function,
): Action<any> {
  return {
    type: DISABLE_PORTAL,
    payload: { organizationId, propertyId, updateProperties },
  };
}

export function onboardPortal(
  organizationId: string,
  propertyId: string,
  updateProperties: ?Function = null,
): Action<any> {
  return {
    type: ONBOARD_PORTAL,
    payload: { organizationId, propertyId, updateProperties },
  };
}
