import { TextingIndicator } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import {
  formatDateDisplayLocal,
  formatPhoneNumber,
  getRelativeDate,
} from '@fortress-technology-solutions/fortress-component-library/utils';
import moment from 'moment';
import { intersection } from 'ramda';
import React from 'react';
import { appendFilterTextToCSV } from '../../../utils/csv-helpers';
import { download } from '../../../utils/downloadFile';
import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';
import actionsMenuMessages from '../../ManageProspects/ActionsMenu/messages';
import { UNIT_ASSIGNED_OPTIONS } from './ApplicationsTable_V2.constants';
import messages from './messages';

export const addProspect = (field: string, prospect: any) => {
  return Object.assign({}, prospect[field], { prospect });
};

export const buildHeaders = ({
  intl,
  generalAffordableProgram,
  hudProgram,
  applicationStatusOptions,
  assignedToOptions,
  showTextingColumn,
}) => [
  {
    id: 'applicationCode',
    label: intl.formatMessage(messages.applicationCode),
    sortable: true,
    dataType: 'number',
  },
  {
    id: 'applicants',
    label: intl.formatMessage(messages.applicants),
    searchable: true,
    sortable: true,
    dataType: 'string',
  },
  ...(showTextingColumn
    ? [
        {
          id: 'texting',
          label: intl.formatMessage(messages.texting),
          sortable: true,
          searchable: false,
          dataType: 'number',
          multiselect: true,
          filterOptions: [
            { text: 'New', value: 'new' },
            { text: 'Unresolved', value: 'unresolved' },
          ],
        },
      ]
    : []),
  {
    id: 'applicationStatusId',
    label: intl.formatMessage(messages.status),
    sortable: true,
    filterOptions: applicationStatusOptions,
    dataType: 'string',
    multiselect: true,
  },
  // General Affordable Program
  ...(generalAffordableProgram
    ? [
        {
          id: 'complianceApproval',
          label: intl.formatMessage(messages.complianceApproval),
          sortable: true,
          dataType: 'string',
        },
        {
          id: 'complianceDocument',
          label: intl.formatMessage(messages.complianceDocument),
          sortable: true,
          dataType: 'string',
        },
      ]
    : []),
  // HUD Program
  ...(hudProgram
    ? [
        {
          id: 'complianceApprovalHUD',
          label: intl.formatMessage(messages.complianceApprovalHUD),
          sortable: true,
          dataType: 'string',
        },
        {
          id: 'complianceDocumentHUD',
          label: intl.formatMessage(messages.complianceDocumentHUD),
          sortable: true,
          dataType: 'string',
        },
      ]
    : []),
  {
    id: 'unitNumber',
    label: intl.formatMessage(messages.assignedUnitNumber),
    sortable: true,
    searchable: true,
    filterOptions: UNIT_ASSIGNED_OPTIONS,
    dataType: 'string',
  },
  {
    id: 'phoneNumber',
    label: intl.formatMessage(messages.phoneNumber),
    searchable: true,
    dataType: 'string',
  },
  {
    id: 'email',
    label: intl.formatMessage(messages.email),
    sortable: true,
    searchable: true,
    dataType: 'string',
  },
  {
    id: 'lastActivity',
    label: intl.formatMessage(messages.lastActivity),
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'nextActivity',
    label: intl.formatMessage(messages.nextActivity),
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'desiredMoveInDate',
    label: intl.formatMessage(messages.desiredMoveInDate),
    sortable: true,
    searchable: true,
    dataType: 'date',
  },
  {
    id: 'scheduledMoveInDate',
    label: intl.formatMessage(messages.scheduleMoveInDate),
    sortable: true,
    searchable: true,
    dataType: 'date',
  },
  {
    id: 'notes',
    label: intl.formatMessage(messages.applicantNotes),
    sortable: true,
  },
  {
    id: 'applicantConversionDate',
    label: intl.formatMessage(messages.applicantConversionDate),
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'waitlistStartTime',
    label: intl.formatMessage(messages.waitlistDateTime),
    sortable: true,
    dataType: 'date',
  },
  {
    id: 'waitlistNotes',
    label: intl.formatMessage(messages.waitlistNotes),
    sortable: true,
    dataType: 'string',
  },
  {
    id: 'assignedTo',
    label: intl.formatMessage(messages.assignedTo),
    sortable: true,
    searchable: true,
    multiselect: true,
    dataType: 'string',
    filterOptions: assignedToOptions,
  },
  {
    id: 'actions',
    label: intl.formatMessage(messages.actions),
  },
];
export const buildRows = ({
  mappedApplications,
  generalAffordableProgram,
  hudProgram,
  locale,
  onCreateActivity,
  onEditActivity,
  hasActivityReadUpdateDeletePermission,
  hasActivityCreatePermission,
  intl,
}) => {
  return mappedApplications.map((application) => {
    const {
      id,
      applicants,
      applicationCode,
      applicationStatus,
      assigneeName,
      createdAt,
      unitNumber,
      prospect,
      desiredMoveInStartDate,
      moveInDate,
      emailAddress,
      phoneNumber,
      complianceApproval,
      complianceDocument,
      complianceApprovalHUD,
      complianceDocumentHUD,
      notes,
      unitId,
      wlStartTime,
      waitlistNotes,
      texting,
    } = application;

    return {
      id,
      applicationCode: {
        variant: 'number',
        value: applicationCode,
      },
      applicants: {
        variant: 'link',
        value: applicants,
        to: getUrlWithSelectedPropertyId(`/application/${application.id}`),
        target: '_blank',
      },
      texting: {
        variant: texting.TOTAL > 0 ? 'link' : undefined,
        value: <TextingIndicator {...texting} />,
        to:
          texting.TOTAL > 0
            ? getUrlWithSelectedPropertyId(
                `/application/${application.id}?tab=texting`,
              )
            : undefined,
      },
      applicationStatusId: applicationStatus?.translations[locale],
      ...(generalAffordableProgram && {
        complianceApproval: {
          variant: 'description',
          value: complianceApproval,
        },
        complianceDocument: {
          variant: 'description',
          value: complianceDocument,
        },
      }),
      ...(hudProgram && {
        complianceApprovalHUD: {
          variant: 'description',
          value: complianceApprovalHUD,
        },
        complianceDocumentHUD: {
          variant: 'description',
          value: complianceDocumentHUD,
        },
      }),
      unitNumber: unitId
        ? {
            variant: 'link',
            value: unitNumber,
            to: getUrlWithSelectedPropertyId(`/unit/${unitId}`),
          }
        : undefined,
      phoneNumber: {
        variant: 'phoneNumber',
        value: phoneNumber,
      },
      email: {
        variant: 'email',
        value: emailAddress,
      },
      lastActivity: {
        variant: hasActivityReadUpdateDeletePermission ? 'link' : undefined,
        onClick: hasActivityReadUpdateDeletePermission
          ? () =>
              onEditActivity(addProspect('lastAct', prospect), application.id)
          : undefined,
        value: prospect?.lastAct?.startTime
          ? getRelativeDate(prospect.lastAct.startTime)
          : null,
      },
      nextActivity: {
        variant: hasActivityReadUpdateDeletePermission ? 'link' : undefined,
        onClick: hasActivityReadUpdateDeletePermission
          ? () =>
              onEditActivity(addProspect('nextAct', prospect), application.id)
          : undefined,
        value: prospect?.nextAct?.startTime
          ? getRelativeDate(prospect.nextAct.startTime)
          : null,
      },
      desiredMoveInDate: { variant: 'date', value: desiredMoveInStartDate },
      scheduledMoveInDate: { variant: 'date', value: moveInDate },
      notes: { variant: 'description', value: notes },
      applicantConversionDate: { variant: 'date', value: createdAt },
      waitlistStartTime: { variant: 'dateTime', value: wlStartTime },
      waitlistNotes: {
        variant: 'description',
        value: waitlistNotes,
      },
      assignedTo: assigneeName,
      actions: {
        variant: 'menu',
        iconName: 'BoltIcon',
        options: hasActivityCreatePermission
          ? [
              {
                text: intl.formatMessage(actionsMenuMessages.createActivity),
                value: 'createActivity',
                onClick: () => onCreateActivity(prospect, application.id),
              },
            ]
          : null,
      },
    };
  });
};

export const downloadCSV = ({
  mappedApplications,
  permissions,
  locale,
  affordablePrograms,
  hasAnyFilters,
  headers = [],
  showTextingColumn,
}) => {
  const csvHeaders = headers
    .filter(
      ({ id }) =>
        !['waitlistStartTime', 'waitlistNotes', 'actions'].includes(id),
    )
    ?.map(({ label }) => label)
    .join(',');
  const rows = mappedApplications.reduce((prev, application) => {
    const {
      applicants,
      applicationCode,
      applicationStatus,
      assigneeName,
      complianceApproval = '---',
      complianceDocument = '---',
      complianceApprovalHUD = '---',
      complianceDocumentHUD = '---',
      unitNumber,
      prospect,
      desiredMoveInStartDate,
      desiredMoveInEndDate,
      moveInDate,
      emailAddress,
      phoneNumber,
      createdAt,
      filteredApplicant,
      notes = '---',
      texting,
    } = application;

    const status = applicationStatus.translations[locale];
    const mappedEmailAddress =
      emailAddress && filteredApplicant ? emailAddress : '---';
    const userScopes = permissions.map((permission) => permission.scope);
    const hasPermissions =
      intersection(
        ['activity-read', 'activity-update', 'activity-delete'],
        userScopes,
      ).length > 0;
    const lastActivity =
      prospect?.lastAct?.startTime && hasPermissions
        ? formatDateDisplayLocal(prospect.lastAct.startTime)
        : '---';
    const nextActivity =
      prospect?.nextAct?.startTime && hasPermissions
        ? formatDateDisplayLocal(prospect.nextAct.startTime)
        : '---';
    let desiredMoveInDate = desiredMoveInStartDate
      ? formatDateDisplayLocal(desiredMoveInStartDate)
      : '---';
    desiredMoveInDate !== 'Invalid date'
      ? (desiredMoveInDate +=
          desiredMoveInEndDate && desiredMoveInStartDate
            ? ` - ${moment(desiredMoveInEndDate).format('MM/DD/YYYY')}`
            : '')
      : (desiredMoveInDate = '---');

    const scheduledMoveInDate =
      moveInDate !== null ? moment(moveInDate).format('MM/DD/YYYY') : '---';

    const { hudProgram, generalAffordableProgram } = affordablePrograms;
    const generalAffordableData = generalAffordableProgram
      ? [complianceApproval, complianceDocument]
      : [];
    const hudProgramData = hudProgram
      ? [complianceApprovalHUD, complianceDocumentHUD]
      : [];
    const textingIndicatorsData = showTextingColumn
      ? [
          `"New = ${texting?.NEW ?? 0}, Unresolved = ${
            texting.UNRESOLVED ?? 0
          }"`,
        ]
      : [];

    const finalFields = [
      applicationCode,
      `"${applicants}"`,
      ...textingIndicatorsData,
      status,
      ...generalAffordableData,
      ...hudProgramData,
      unitNumber,
      formatPhoneNumber(phoneNumber),
      mappedEmailAddress,
      lastActivity,
      nextActivity,
      desiredMoveInDate,
      scheduledMoveInDate,
      `"${notes}"`,
      moment(createdAt).format('MM/DD/YYYY'),
      assigneeName,
    ];
    prev.push(finalFields.join(','));
    return prev;
  }, []);

  const csv = appendFilterTextToCSV({
    headers: csvHeaders,
    rows,
    hasAnyFilters,
  });
  download(csv, 'ManageApplication.csv', 'text/csv;charset=utf-8');
};
