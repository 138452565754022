import * as R from 'ramda';
import moment from 'moment';
import messages from './messages';

type ValidationContext = {
  values: Object,
  errors: Object,
  intl: Object,
  isRenewal?: boolean,
  priorLease?: Object,
};

export const validateScheduledMoveInDate = (
  dateStr: string,
  { values, errors, intl }: ValidationContext,
) => {
  if (R.has('scheduledMoveInDate', errors)) return {};

  const scheduledMoveInDate = moment(dateStr);
  const commencementDate = moment(values.commencementDate);

  if (!scheduledMoveInDate.isValid())
    return {
      scheduledMoveInDate: intl.formatMessage(messages.invalidDateError),
    };

  if (!commencementDate.isValid()) return {};

  commencementDate.subtract(180, 'days');

  const earliestValidMoveInDate = commencementDate;

  if (scheduledMoveInDate.isBefore(earliestValidMoveInDate, 'day'))
    return {
      scheduledMoveInDate: intl.formatMessage(
        messages.dateBeforeEarliestValidMoveInDateError,
      ),
    };

  return {};
};

export const validateLeaseEffectiveDate = (
  dateStr?: string,
  { values, intl }: ValidationContext,
) => {
  if (!dateStr) return {};

  const leaseEffectiveDate = moment(dateStr);
  const commencementDate = moment(values.commencementDate);
  const rentStartDate = moment(values.rentStartDate);
  const leaseEndDate = moment(values.leaseEndDate);

  if (!leaseEffectiveDate.isValid())
    return {
      leaseEffectiveDate: intl.formatMessage(messages.invalidDateError),
    };

  if (
    commencementDate.isValid() &&
    leaseEffectiveDate.isAfter(commencementDate, 'day')
  )
    return {
      leaseEffectiveDate: intl.formatMessage(
        messages.dateAfterCommencementError,
      ),
    };

  if (
    rentStartDate.isValid() &&
    leaseEffectiveDate.isAfter(rentStartDate, 'day')
  )
    return {
      leaseEffectiveDate: intl.formatMessage(messages.dateAfterRentStartError),
    };

  if (leaseEndDate.isValid() && leaseEffectiveDate.isAfter(leaseEndDate, 'day'))
    return {
      leaseEffectiveDate: intl.formatMessage(messages.dateAfterLeaseEndError),
    };

  return {};
};

export const validateCommencementDate = (
  dateStr: string,
  { values, errors, intl, isRenewal, noticeToVacate }: ValidationContext,
) => {
  if (R.has('commencementDate', errors)) return {};

  const commencementDate = moment(dateStr);
  const scheduledMoveInDate = moment(values.scheduledMoveInDate);
  const leaseEndDate = moment(values.leaseEndDate);

  if (!commencementDate.isValid())
    return {
      commencementDate: intl.formatMessage(messages.invalidDateError),
    };

  if (leaseEndDate.isValid() && commencementDate.isAfter(leaseEndDate, 'day'))
    return {
      commencementDate: intl.formatMessage(messages.dateAfterLeaseEndError),
    };

  if (isRenewal || !scheduledMoveInDate.isValid()) return {};

  scheduledMoveInDate.add(180, 'days');

  if (commencementDate.isAfter(scheduledMoveInDate, 'day'))
    return {
      commencementDate: intl.formatMessage(
        messages.date180DaysAfterMoveInDateError,
      ),
    };

  return {};
};

export const validateLeaseEndDate = (
  dateStr: string,
  { values, errors, intl, isRenewal }: ValidationContext,
) => {
  if (R.has('leaseEndDate', errors)) return {};

  const leaseEndDate = moment(dateStr);
  const today = moment();
  const scheduledMoveInDate = moment(values.scheduledMoveInDate);

  if (!leaseEndDate.isValid())
    return {
      leaseEndDate: intl.formatMessage(messages.invalidDateError),
    };

  if (leaseEndDate.isBefore(today, 'day') && !values.isMovedIn)
    return {
      leaseEndDate: intl.formatMessage(messages.dateBeforeTodayError),
    };

  if (
    !isRenewal &&
    scheduledMoveInDate.isValid() &&
    !leaseEndDate.isAfter(scheduledMoveInDate, 'day')
  )
    return {
      leaseEndDate: intl.formatMessage(messages.dateMustBeAfterMoveInError),
    };

  return {};
};

export const validateRentStartDate = (
  dateStr?: string,
  { values, errors, intl, noticeToVacate }: ValidationContext,
) => {
  if (R.has('rentStartDate', errors)) return {};

  const rentStartDate = moment(dateStr);
  const leaseEndDate = moment(values.leaseEndDate);
  const commencementDate = moment(values.commencementDate);

  if (!rentStartDate.isValid())
    return {
      rentStartDate: intl.formatMessage(messages.invalidDateError),
    };

  if (leaseEndDate.isValid() && rentStartDate.isAfter(leaseEndDate, 'day'))
    return {
      rentStartDate: intl.formatMessage(messages.dateAfterLeaseEndError),
    };

  if (
    commencementDate.isValid() &&
    rentStartDate.isBefore(commencementDate, 'day')
  )
    return {
      rentStartDate: intl.formatMessage(messages.dateBeforeCommencementError),
    };

  return {};
};

export const validateCommencementNotOverlap = (
  dateStr: string,
  {
    values,
    errors,
    intl,
    isRenewal,
    priorLease,
    noticeToVacate,
  }: ValidationContext,
) => {
  let vacateNotice;
  if (noticeToVacate) {
    vacateNotice = noticeToVacate ? moment(noticeToVacate.moveOutDate) : '';
  }

  if (R.has('commencementDate', errors)) return {};

  const commencementDate = moment(dateStr);

  let priorLeaseEndDateStr = R.pathOr(false, ['endDate'], priorLease);

  if (priorLeaseEndDateStr) {
    const priorLeaseEndDate = moment(priorLeaseEndDateStr);
    if (commencementDate.isBefore(priorLeaseEndDate, 'day'))
      return {
        commencementDate: intl.formatMessage(messages.validDateBeforeEndDate),
      };
  }

  if (vacateNotice && commencementDate.isAfter(vacateNotice)) {
    return {
      commencementDate: intl.formatMessage(
        messages.dateMustBeOnOrAfterRenewalDate,
      ),
    };
  }

  return {};
};
