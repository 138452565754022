import React from 'react';
import { Col, Row } from 'react-bootstrap';
import messages from './messages';
import { OneTimeLeaseChargesOrCreditsLeftColumn } from './leftColumn';
import { OneTimeLeaseChargesOrCreditsRightColumn } from './rightColumn';

export const OneTimeLeaseChargesOrCredits = ({
  intl,
  securityDeposits,
  isEditable,
}: Object) => (
  <React.Fragment>
    <Row className="padtop10 padbottom10">
      <Col xs={12}>
        <h2>
          <i className="et-money text-blue padright10" />
          {intl.formatMessage(messages.oneTimeLeaseChargesOrCreditsLabel)}
        </h2>
      </Col>
    </Row>
    <Row className="container-fluid">
      <form>
        <OneTimeLeaseChargesOrCreditsLeftColumn
          intl={intl}
          isEditable={isEditable}
        />
        <OneTimeLeaseChargesOrCreditsRightColumn
          intl={intl}
          securityDeposits={securityDeposits}
          isEditable={isEditable}
        />
      </form>
    </Row>
    <div className="divider" />
  </React.Fragment>
);

export default OneTimeLeaseChargesOrCredits;
