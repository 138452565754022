import type { Action } from '../App/types';
import * as ActionTypes from './constants';
import type { Unit, Amenity } from './types';

export function getAllUnits(payload: any): Action<any> {
  return {
    type: ActionTypes.GET_ALL_UNITS,
    payload,
  };
}

export function getAllUnitsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_UNITS_ERROR,
    payload: error,
  };
}

export function getAllUnitsSuccess(
  units: Array<Unit>,
  amenities: Array<Amenity>,
  meta: Object,
  counts: Object,
): Action<Array<Unit>, Object> {
  return {
    type: ActionTypes.GET_ALL_UNITS_SUCCESS,
    payload: { units, amenities },
    meta: meta,
    counts,
  };
}

export function getAllUnitStatuses(payload: Object): Action<any> {
  return {
    type: ActionTypes.GET_ALL_UNIT_STATUSES,
    payload,
  };
}

export function getAllUnitStatusesSuccess(
  statuses: Array<Object>,
): Action<any> {
  return {
    type: ActionTypes.GET_ALL_UNIT_STATUSES_SUCCESS,
    payload: statuses,
  };
}

export function getAllUnitStatusesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_UNIT_STATUSES_ERROR,
    payload: error,
  };
}

export function getAllPropertySpecials(payload: Object) {
  return {
    type: ActionTypes.GET_ALL_PROPERTY_SPECIALS,
    payload,
  };
}

export function getAllPropertySpecialsSuccess(payload: Object): Action<Object> {
  return {
    type: ActionTypes.GET_ALL_PROPERTY_SPECIALS_SUCCESS,
    payload,
  };
}

export function getAllPropertySpecialsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_PROPERTY_SPECIALS_ERROR,
    payload: error,
  };
}

export function savePropertySpecial(payload: Object) {
  return {
    type: ActionTypes.SAVE_PROPERTY_SPECIALS,
    payload,
  };
}

export function savePropertySpecialSuccess(payload: Object): Action<Object> {
  return {
    type: ActionTypes.SAVE_PROPERTY_SPECIALS_SUCCESS,
    payload,
  };
}

export function savePropertySpecialError(error: Error): Action<Error> {
  return {
    type: ActionTypes.SAVE_PROPERTY_SPECIALS_ERROR,
    payload: error,
  };
}

export function cleanLoadedUnits(): Object {
  return {
    type: ActionTypes.CLEAN_LOADED_UNITS,
  };
}

export function getAssignedUnits(payload: Object) {
  return {
    type: ActionTypes.GET_ASSIGNED_UNITS,
    payload,
  };
}

export function getAssignedUnitsSuccess(payload: Object): Action<Object> {
  return {
    type: ActionTypes.GET_ASSIGNED_UNITS_SUCCESS,
    payload,
  };
}

export function getAssignedUnitsError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ASSIGNED_UNITS_ERROR,
    payload: error,
  };
}

export function assignUnit(payload: Object) {
  return {
    type: ActionTypes.ASSIGN_UNIT,
    payload,
  };
}

export function assignUnitSuccess(payload: Object): Action<Object> {
  return {
    type: ActionTypes.ASSIGN_UNIT_SUCCESS,
    payload,
  };
}

export function assignUnitError(error: Error): Action<Error> {
  return {
    type: ActionTypes.ASSIGN_UNIT_ERROR,
    payload: error,
  };
}

export function unassignUnit(
  assignedUnitId: string,
  applicationId: string,
  intl: Object,
) {
  return {
    type: ActionTypes.UNASSIGN_UNIT,
    payload: {
      assignedUnitId,
      applicationId,
      intl,
    },
  };
}

export function unassignUnitSuccess(payload: Object): Action<Object> {
  return {
    type: ActionTypes.UNASSIGN_UNIT_SUCCESS,
    payload,
  };
}

export function unassignUnitError(error: Error): Action<Error> {
  return {
    type: ActionTypes.UNASSIGN_UNIT_ERROR,
    payload: error,
  };
}

export function getUnitQuote(payload: Object): Action<Object> {
  return {
    type: ActionTypes.GET_UNIT_QUOTE,
    payload,
  };
}
