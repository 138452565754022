/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  portalNeverActiveNote: {
    id: 'App.ManageProperties.PortalModal.PortalNeverActiveNote',
    defaultMessage:
      'Portal is not active for this property. Activate Portal and send initial registration emails to allow account holders to login.',
  },
  portalInactiveNote: {
    id: 'App.ManageProperties.PortalModal.PortalInactiveNote',
    defaultMessage:
      'Portal was Active for this property, but has since been Deactivated. No accounts were deleted, but account holders are not able to login. To allow account holders access to their accounts, you can make the Portal Active for this property.',
  },
  portalActiveNote: {
    id: 'App.ManageProperties.PortalModal.PortalActiveNote',
    defaultMessage:
      'Portal is Active for this property. If Portal is Deactivated, accounts will not be deleted, but account holders will not be able to login.',
  },
  portalActive: {
    id: 'App.ManageProperties.PortalModal.PortalActive',
    defaultMessage: 'Portal Active',
  },
  portalActiveHistoryLabel: {
    id: 'App.ManageProperties.PortalModal.PortalActiveHistoryLabel',
    defaultMessage: 'Portal Activated on {date} by {user}',
  },
  initialRegistrationHistoryLabel: {
    id: 'App.ManageProperties.PortalModal.InitialRegistrationHistoryLabel',
    defaultMessage: 'Initial registration emails sent on {date} by {user}',
  },
  sendInitialRegistrationEmails: {
    id: 'App.ManageProperties.PortalModal.SendInitialRegistrationEmails',
    defaultMessage: 'Send Initial Registration Emails*',
  },
  sendInitialRegistrationEmailsNote: {
    id: 'App.ManageProperties.PortalModal.sendInitialRegistrationEmailsNote',
    defaultMessage:
      '*Initial registration emails can only be sent once to valid email addresses. If you need further assistance, please contact your Fortress admin.',
  },
  electronicLeaseSigning: {
    id: 'App.ManageProperties.PortalModal.ElectronicLeaseSigning',
    defaultMessage: 'Electronic Lease Signing',
  },
  electronicLeaseSigningNote: {
    id: 'App.ManageProperties.PortalModal.ElectronicLeaseSigningNote',
    defaultMessage:
      'Electronic Lease Signing uses the Fortress lease and is automatically enabled when Portal is activated. Changing this setting will affect this feature for the entire property.',
  },
  portalSettingsNote: {
    id: 'App.ManageProperties.PortalModal.PortalSettingsNote',
    defaultMessage:
      'Making changes to these settings will affect all applicants and residents that are currently using Portal.',
  },
  onboardedLeaseholdersReport: {
    id: 'App.ManageProperties.PortalModal.OnboardedLeaseholdersReport',
    defaultMessage: 'Onboarded Leaseholders Report',
  },
  unitSelection: {
    id: 'App.ManageProperties.PortalModal.UnitSelection',
    defaultMessage: 'Unit Selection',
  },
  unitSelectionTooltip: {
    id: 'App.ManageProperties.PortalModal.UnitSelectionTooltip',
    defaultMessage:
      "When this feature is turned on, applicants will be required to select a specific unit to apply for when completing the online application process. Once they submit their application fees online, the unit they've selected will automatically be assigned to them in Fortress.",
  },
  applyWithoutUnit: {
    id: 'App.ManageProperties.PortalModal.ApplyWithoutUnit',
    defaultMessage: 'Apply Without a Unit',
  },
  will: {
    id: 'App.ManageProperties.PortalModal.Will',
    defaultMessage: 'will',
  },
  willNot: {
    id: 'App.ManageProperties.PortalModal.WillNot',
    defaultMessage: 'will not',
  },
  applyWithoutUnitTooltip: {
    id: 'App.ManageProperties.PortalModal.ApplyWithoutUnitTooltip',
    defaultMessage:
      'By selecting "Yes," applicants {willNot} be required to select a specific unit when completing the online application process. If they opt not to select a unit, they will be required to submit their desired move-in preferences instead.{br}{br}By selecting "No," applicants {will} be required to select a specific unit; otherwise, they will not be able to complete the online application process.',
  },
});

export default messages;
