import { useEffect, useState } from 'react';
import { parseDropdownsOptionsWithCodesAndNames } from '../../containers/AffordableQualificationTab/Shared/utils';

import AffordableService from '../../services/affordableService';

const useAsyncCorrectionCodeOptions = (selectedProperty: Object) => {
  const [correctionCodeOptions, setCorrectionCodeOptions] = useState([]);

  const selectedPropertyId = selectedProperty?.id || null;
  const organizationId = selectedProperty?.organizationId || null;

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchCorrectionOptions = async () => {
      if (organizationId && selectedPropertyId) {
        const options = { signal: abortController.signal };
        const affordableService = new AffordableService();

        const correctionCodesResponse =
          await affordableService.getAffordableCorrectionCodes(
            organizationId,
            selectedPropertyId,
            options,
          );

        const parsedCorrectionCodes = parseDropdownsOptionsWithCodesAndNames(
          correctionCodesResponse,
        );
        if (subscribed) {
          setCorrectionCodeOptions(parsedCorrectionCodes);
        }
      }
    };

    fetchCorrectionOptions();

    return () => {
      subscribed = false;
    };
  }, [organizationId, selectedPropertyId]);

  return correctionCodeOptions;
};

export default useAsyncCorrectionCodeOptions;
