import { isEmpty, isNil, pathOr, has, assocPath, uniq } from 'ramda';
import moment from 'moment';

import {
  validateRequired,
  validatePositiveMoney,
  validateDate,
} from '../../../utils/validations';
import { validatePairRequired } from './utils';

const validate = (values: Object, props: Object) => {
  const { floorPlans, touch } = props;

  let feeFields = [];
  let dateFields = [];
  Object.keys(values).forEach((key) => {
    feeFields.splice(
      feeFields.length,
      0,
      [key, 'newHudGrossRent'],
      [key, 'newHudUa'],
    );
    dateFields.splice(
      dateFields.length,
      0,
      [key, 'newHudGrossRentStartDate'],
      [key, 'newHudUaStartDate'],
    );
  });

  let errors = {};
  let fieldsWithErrors = [];

  dateFields.forEach((fieldPath) => {
    const floorPlanId = fieldPath[0];
    const fieldName = fieldPath[1];
    const floorPlan = !isNil(floorPlanId)
      ? floorPlans.find((floorPlan) => floorPlan.id === floorPlanId)
      : undefined;
    const startDate = values[floorPlanId][fieldName];

    if (!isNil(startDate) && !isEmpty(startDate)) {
      if (!validateDate(startDate)) {
        errors = assocPath(
          [floorPlanId, fieldName],
          // $FlowFixMe
          'Please enter a valid date',
          errors,
        );
        fieldsWithErrors.push(`${floorPlanId}.${fieldName}`);
      } else if (!isNil(floorPlan)) {
        let latestStartDate = moment.utc().startOf('date').format('YYYY-MM-DD');

        if (fieldName.includes('Rent')) {
          const hudGrossRents = pathOr([], ['hudGrossRents'], floorPlan);

          latestStartDate = pathOr(
            latestStartDate,
            ['startDate'],
            hudGrossRents.find((rent) => isNil(rent.endDate)),
          );
        } else if (fieldName.includes('HudUa')) {
          const allowances =
            pathOr([], ['fpua'], floorPlan).length > 0
              ? pathOr([], ['fpua'], floorPlan)
              : pathOr([], ['allowances'], floorPlan);

          latestStartDate = pathOr(
            latestStartDate,
            ['startDate'],
            allowances.find((allowance) => isNil(allowance.endDate)),
          );
        }

        if (startDate.startOf('date').isBefore(moment.utc(latestStartDate))) {
          errors = assocPath(
            [floorPlanId, fieldName],
            // $FlowFixMe
            'Current start date is before a start date of an existing value, please update to a further start date.',
            errors,
          );
          fieldsWithErrors.push(`${floorPlanId}.${fieldName}`);
        }
      }
    }
    const { isValidPair, requiredValue } = validatePairRequired(
      values,
      floorPlanId,
      fieldName.includes('Rent'),
    );
    if (!isValidPair && !validateRequired(requiredValue, values)) {
      errors = assocPath(
        [floorPlanId, requiredValue],
        // $FlowFixMe
        'This field is required',
        errors,
      );
      fieldsWithErrors.push(`${floorPlanId}.${fieldName}`);
    }
  });

  feeFields.forEach((fieldPath) => {
    const floorPlanId = fieldPath[0];
    const fieldName = fieldPath[1];
    const feeAmount = values[floorPlanId][fieldName];
    const floorPlan = !isNil(floorPlanId)
      ? floorPlans.find((floorPlan) => floorPlan.id === floorPlanId)
      : undefined;

    if (!isNil(feeAmount) && !isEmpty(feeAmount)) {
      if (
        !validatePositiveMoney(feeAmount) ||
        parseFloat(feeAmount) <= 0 ||
        parseFloat(feeAmount) > 9999.99
      ) {
        errors = assocPath(
          [floorPlanId, fieldName],
          // $FlowFixMe
          'Please enter a valid amount',
          errors,
        );
        fieldsWithErrors.push(`${floorPlanId}.${fieldName}`);
      }
    }

    if (
      !isNil(floorPlan) &&
      (isNil(errors[floorPlanId]) || isNil(errors[floorPlanId][fieldName]))
    ) {
      let latestFeeAmount = 0;

      if (fieldName.includes('Rent')) {
        const hudGrossRents = pathOr([], ['hudGrossRents'], floorPlan);

        latestFeeAmount = pathOr(
          latestFeeAmount,
          ['feeAmount'],
          hudGrossRents.find((rent) => isNil(rent.endDate)),
        );
      } else if (fieldName.includes('HudUa')) {
        const allowances =
          pathOr([], ['fpua'], floorPlan).length > 0
            ? pathOr([], ['fpua'], floorPlan)
            : pathOr([], ['allowances'], floorPlan);

        const latestHud = allowances.find((ua) => isNil(ua.endDate)) || {};

        latestFeeAmount = has('feeAmount', latestHud)
          ? pathOr(0, ['feeAmount'], latestHud)
          : pathOr(0, ['utilityAllowanceAmount'], latestHud);
      }

      if (parseFloat(feeAmount) === parseFloat(latestFeeAmount)) {
        errors = assocPath(
          [floorPlanId, fieldName],
          // $FlowFixMe
          'Fee amount is the same as the latest existing fee.',
          errors,
        );
        fieldsWithErrors.push(`${floorPlanId}.${fieldName}`);
      }
    }

    const { isValidPair, requiredValue } = validatePairRequired(
      values,
      floorPlanId,
      fieldName.includes('Rent'),
    );
    if (!isValidPair && !validateRequired(fieldName, values)) {
      errors = assocPath(
        [floorPlanId, requiredValue],
        // $FlowFixMe
        'This field is required',
        errors,
      );
      fieldsWithErrors.push(`${floorPlanId}.${fieldName}`);
    }
  });

  /* This is to touch fields with errors due to being required */
  fieldsWithErrors = uniq(fieldsWithErrors);
  if (fieldsWithErrors.length > 0) {
    touch.apply(null, fieldsWithErrors);
  }

  return errors;
};

export default validate;
