import { useMemo } from 'react';
import { useMutation } from 'react-query';
import HousingAssistanceVoucherService from '../../services/housingAssistanceVoucherService';

export const useCreateHousingAssistanceVoucher = ({
  organizationId,
  propertyId,
  householdId,
  options,
}) => {
  const housingAssistanceVoucherService = useMemo(
    () => new HousingAssistanceVoucherService(),
    [],
  );
  const mutation = useMutation(
    ({ payload }) =>
      housingAssistanceVoucherService.createVoucher(
        organizationId,
        propertyId,
        householdId,
        payload,
      ),
    options,
  );

  return {
    ...mutation,
  };
};

export const useEditHousingAssistanceVoucher = ({
  organizationId,
  propertyId,
  householdId,
  options,
}) => {
  const housingAssistanceVoucherService = useMemo(
    () => new HousingAssistanceVoucherService(),
    [],
  );
  const mutation = useMutation(
    ({ housingAssistanceVoucherId, payload }) =>
      housingAssistanceVoucherService.editVoucher(
        organizationId,
        propertyId,
        householdId,
        housingAssistanceVoucherId,
        payload,
      ),
    options,
  );

  return {
    ...mutation,
  };
};

export const useDeleteHousingAssistanceVoucher = ({
  organizationId,
  propertyId,
  options,
}) => {
  const housingAssistanceVoucherService = useMemo(
    () => new HousingAssistanceVoucherService(),
    [],
  );
  const mutation = useMutation(
    ({ housingAssistanceVoucherId }) =>
      housingAssistanceVoucherService.deleteVoucher(
        organizationId,
        propertyId,
        housingAssistanceVoucherId,
      ),
    options,
  );

  return {
    ...mutation,
  };
};
