import { isToDateNotSameOrAfterFromDate } from '../../../../../utils/date-helpers';
import messages from './messages';

export const validate = (values: Object, props: Object) => {
  const errors = {};

  if (
    isToDateNotSameOrAfterFromDate(values.policyStartDate, values.policyEndDate)
  ) {
    errors.policyEndDate = props.intl.formatMessage(
      messages.endDateBeforeStartDateError,
    );
  }

  return errors;
};
