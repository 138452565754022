import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Button, FormGroup, HelpBlock } from 'react-bootstrap';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import { Prompt } from 'react-router-dom';
import { renderTextAreaField } from '../../../utils/redux-form-helper';
import { FormattedMessage } from 'react-intl';
import PrimaryContactInformation from './PrimaryContactInformation';
import Adults from './Adults';
import Pets from './Pets';
import Minors from './Minors';
import LookingFor from './LookingFor';
import ContactType from './ContactType';
import ReferralType from './ReferralType';
import AssignTo from './AssignTo';
import messages from './messages';
import validate from './validate';
import { navigateToUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

import type { DropdownOption } from '../../App/types';

type Props = {
  handleSubmit: Function,
  handleCancel: Function,
  isSaving: boolean,
  onCancel: Function,
  submitting: boolean,
  valid: boolean,
  pristine: boolean,
  dirty: boolean,
  reset: Function,
  change: Function,
  array: Object,
  contactTypes: Array<DropdownOption>,
  referralTypes: Array<DropdownOption>,
  petTypes: Array<DropdownOption>,
  petBreeds: Array<DropdownOption>,
  assignees: Array<DropdownOption>,
  intl: Object,
  pets: Array<Object>,
  moveInToVisible: boolean,
  noMoveInVisible: boolean,
  selectedProperty: Object,
  errors: Object,
  error: Object,
  suffixes: Array<Object>,
  relationships: Array<Object>,
  currentUser: Object,
  history: Object,
};

export const ProspectProfileForm = ({
  handleSubmit,
  isSaving,
  valid,
  dirty,
  change,
  error,
  contactTypes,
  referralTypes,
  petTypes,
  petBreeds,
  suffixes,
  relationships,
  floorPlans,
  assignees,
  intl,
  pets,
  noMoveInDate,
  moveInDateFrom,
  selectedProperty,
  currentUser,
  history,
  initialValues,
}: Props) => {
  const isFormValid = Boolean(valid && (noMoveInDate || moveInDateFrom));
  return (
    <div className="form-wrapper container-fluid">
      <Prompt
        when={dirty && !isSaving}
        message={intl.formatMessage(messages.deleteConfirmation)}
      />
      <div className="applicant-convert-header">
        <Row>
          <Col xs={6} md={12} className="form-header">
            <h2 className="float-left">
              <FormattedMessage {...messages.header} />
            </h2>
            <small className="float-left">
              <FormattedMessage {...messages.headerRequiredFields} />
            </small>
          </Col>
        </Row>
      </div>
      <div className="form-container container-fluid">
        <Form onSubmit={handleSubmit} className="applicant-convert-form">
          <FormGroup className="applicant-grp">
            <Row className="block-heading">
              <h2>{`
              ${intl.formatMessage(messages.thankYouForChoosing)}${' '}
              ${selectedProperty.name}.${' '}
              ${intl.formatMessage(messages.thisIs)}${' '}
              ${currentUser.firstName} ${currentUser.lastName}.
              ${intl.formatMessage(messages.howMayIHelp)}
            `}</h2>
            </Row>
            <Row>
              <Field
                name="helpText"
                component={renderTextAreaField}
                placeholder={intl.formatMessage(messages.introductoryNote)}
                rows="3"
              />
            </Row>
          </FormGroup>
          <PrimaryContactInformation
            intl={intl}
            suffixes={suffixes}
            firstName={currentUser.firstName}
            error={error}
          />
          <FormGroup className="applicant-grp">
            <Row className="block-heading">
              <h2>
                <FormattedMessage {...messages.additionalOccupantsHeader} />
              </h2>
            </Row>
            <Adults
              intl={intl}
              suffixes={suffixes}
              relationships={relationships}
            />
            <Minors intl={intl} suffixes={suffixes} />
            <Pets
              intl={intl}
              pets={pets}
              petTypes={petTypes}
              petBreeds={petBreeds}
            />
          </FormGroup>
          <LookingFor
            intl={intl}
            reduxFormOnChange={change}
            floorPlans={floorPlans}
            initialValues={initialValues}
          />
          <Row>
            <FormGroup>
              <Col xs={6} sm={4}>
                <label>
                  <FormattedMessage {...messages.contactTypeLabel} />
                </label>
                <ContactType intl={intl} contactTypes={contactTypes} />
              </Col>
              <Col xs={6} sm={4}>
                <label>
                  <FormattedMessage {...messages.referralTypesLabel} />
                </label>
                <ReferralType intl={intl} referralTypes={referralTypes} />
              </Col>
              <Col xs={6} sm={4}>
                <label>
                  <FormattedMessage {...messages.assignTo} />
                </label>
                <AssignTo intl={intl} assignees={assignees} />
              </Col>
            </FormGroup>
          </Row>
          {error && (
            <Row>
              <HelpBlock className="has-error">{error.message}</HelpBlock>
            </Row>
          )}
          <Row>
            <Col xs={6}>
              <Button
                bsStyle="default"
                className="pull-right"
                onClick={() =>
                  navigateToUrlWithSelectedPropertyId('/management')
                }
              >
                <FormattedMessage {...messages.cancel} />
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                bsStyle="primary"
                type="submit"
                className="pull-left"
                disabled={!isFormValid}
              >
                <FormattedMessage {...messages.save} />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export const mapStateToProps = (
  { app, form }: Object,
  { initialValues }: Object,
): Object => {
  const selector = formValueSelector('prospectProfile');
  return {
    initialValues,
    currentUser: app.currentUser.user,
    pets: selector({ form }, 'pets'),
    noMoveInDate: selector({ form }, 'noMoveInDate'),
    moveInDateFrom: selector({ form }, 'moveInDateFrom'),
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'prospectProfile',
    validate,
  })(ProspectProfileForm),
);
