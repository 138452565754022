import {
  get,
  getDownload,
  post,
  patch,
  postDownload,
  put,
  del,
} from '../utils/api';

export default class AffordableQualificationService {
  getCompletionStatusesAndAssistanceDetails(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return get(
      `/${orgProp}/affordable-qualifications/${affordableQualificationId}/completion-and-assistance-details`,
      options,
    );
  }

  startRecertification(
    organizationId: string,
    propertyId: string,
    householdId: string,
    propertyAffordableProgramId: string,
    applicantIds: Array<string>,
    voucherEffectiveDate: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return put(
      `/${orgProp}/households/${householdId}/start-recert`,
      JSON.stringify({
        propertyAffordableProgramId,
        applicantIds,
        voucherEffectiveDate,
        client: 'management-dashboard',
      }),
      options,
    );
  }

  startInitialCertification(
    organizationId: string,
    propertyId: string,
    householdId: string,
    propertyAffordableProgramId: string,
    applicantIds?: Array<string>,
    voucherEffectiveDate?: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/households/${householdId}/start-initial-cert`,
      JSON.stringify({
        propertyAffordableProgramId,
        applicantIds,
        voucherEffectiveDate,
      }),
      options,
    );
  }

  startCoTenantToTenantCertification(
    organizationId: string,
    propertyId: string,
    householdId: string,
    propertyAffordableProgramId: string,
    applicantIds?: Array<string>,
    voucherEffectiveDate?: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/households/${householdId}/start-co-tenant-to-tenant-cert`,
      JSON.stringify({
        propertyAffordableProgramId,
        applicantIds,
        voucherEffectiveDate,
      }),
      options,
    );
  }

  startTerminationCertification(
    organizationId: string,
    propertyId: string,
    householdId: string,
    propertyAffordableProgramId: string,
    applicantIds?: Array<string>,
    voucherEffectiveDate?: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/households/${householdId}/start-termination-cert`,
      JSON.stringify({
        propertyAffordableProgramId,
        applicantIds,
        voucherEffectiveDate,
      }),
      options,
    );
  }

  startTerminateAbsenceCertification(
    organizationId: string,
    propertyId: string,
    householdId: string,
    propertyAffordableProgramId: string,
    applicantIds?: Array<string>,
    voucherEffectiveDate?: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/households/${householdId}/start-terminate-absence-cert`,
      JSON.stringify({
        propertyAffordableProgramId,
        applicantIds,
        voucherEffectiveDate,
      }),
      options,
    );
  }

  startInitiateAbsenceCertification(
    organizationId: string,
    propertyId: string,
    householdId: string,
    propertyAffordableProgramId: string,
    applicantIds?: Array<string>,
    voucherEffectiveDate?: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/households/${householdId}/start-initiate-absence-cert`,
      JSON.stringify({
        propertyAffordableProgramId,
        applicantIds,
        voucherEffectiveDate,
      }),
      options,
    );
  }

  startEvictionCertification(
    organizationId: string,
    propertyId: string,
    householdId: string,
    propertyAffordableProgramId: string,
    applicantIds?: Array<string>,
    voucherEffectiveDate?: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/households/${householdId}/start-eviction-cert`,
      JSON.stringify({
        propertyAffordableProgramId,
        applicantIds,
        voucherEffectiveDate,
      }),
      options,
    );
  }

  // Deprecate
  startInterimCertification(
    organizationId: string,
    propertyId: string,
    householdId: string,
    propertyAffordableProgramId: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/households/${householdId}/start-interim-cert`,
      JSON.stringify({ propertyAffordableProgramId }),
      options,
    );
  }

  startInterimCertificationHUD(
    organizationId: string,
    propertyId: string,
    householdId: string,
    propertyAffordableProgramId: string,
    applicantIds: Array<string>,
    voucherEffectiveDate: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/households/${householdId}/start-interim-cert/hud`,
      JSON.stringify({
        propertyAffordableProgramId,
        applicantIds,
        voucherEffectiveDate,
      }),
      options,
    );
  }

  startInterimCertificationLIHTC(
    organizationId: string,
    propertyId: string,
    householdId: string,
    propertyAffordableProgramId: string,
    applicantIds: Array<string>,
    voucherEffectiveDate: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/households/${householdId}/start-interim-cert/lihtc`,
      JSON.stringify({
        propertyAffordableProgramId,
        applicantIds,
        voucherEffectiveDate,
      }),
      options,
    );
  }

  startCorrectionCertification(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/affordable-qualifications/${affordableQualificationId}/start-correction-cert`,
      null,
      options,
    );
  }

  startAssignRemoveRACertification(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/affordable-qualifications/${affordableQualificationId}/start-assign-remove-ra-cert`,
      null,
      options,
    );
  }

  startUnitTransferCertification(
    organizationId: string,
    propertyId: string,
    householdId: string,
    propertyAffordableProgramId: string,
    applicantIds: Array<string>,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/households/${householdId}/start-unit-transfer-cert`,
      JSON.stringify({
        propertyAffordableProgramId,
        applicantIds,
      }),
      options,
    );
  }

  reOpenCertification(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/affordable-qualifications/${affordableQualificationId}/reopen-cert`,
      null,
      options,
    );
  }

  completeCertification(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    isReComplete: ?boolean,
    useInterimEffectiveDateForDueDate?: boolean,
    ownerSignatureDate?: string | null,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    const body = {
      isReComplete,
      useInterimEffectiveDateForDueDate,
      ownerSignatureDate,
    };
    return post(
      `/${orgProp}/affordable-qualifications/${affordableQualificationId}/complete-certification`,
      JSON.stringify(body),
      options,
    );
  }

  refreshCertification(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return put(
      `/${orgProp}/affordable-qualifications/${affordableQualificationId}/refresh-gross-rent`,
      null,
      options,
    );
  }

  saveAffordableQualification(
    payload: Object,
    organizationId: string,
    propertyId: string,
    householdId: string,
    affordableQualificationId: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/households/${householdId}/affordable-qualifications/${affordableQualificationId}`,
      JSON.stringify(payload),
      options,
    );
  }

  saveQualificationChecklistApplicant(
    checklist: Object,
    affordableQualificationId: any,
    organizationId: string,
    propertyId: string,
    applicantId: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    const applicationBaseUrl = `${orgProp}/affordable-qualifications/${affordableQualificationId}`;

    return put(
      `/${applicationBaseUrl}/applicants/${applicantId}/affordable-qualification-checklist`,
      JSON.stringify(checklist),
      options,
    );
  }

  getRecertForm(
    organizationId: string,
    propertyId: string,
    applicantId: string,
    affordableQualificationId: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return get(
      `/${orgProp}/affordable-qualifications/${affordableQualificationId}/applicant/${applicantId}/recert-form`,
      options,
    );
  }

  saveRecertForm(
    formData: Object,
    organizationId: string,
    propertyId: string,
    applicantId: string,
    affordableQualificationId: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return put(
      `/${orgProp}/affordable-qualifications/${affordableQualificationId}/applicants/${applicantId}/recert-form`,
      JSON.stringify(formData),
      options,
    );
  }

  saveCorrection(
    formData: Object,
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    const orgProp = `${organizationId}/${propertyId}`;
    return post(
      `/${orgProp}/affordable-qualification-corrections`,
      JSON.stringify(formData),
      options,
    );
  }

  generate59(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    fileName: string,
    options?: Object,
  ) {
    return postDownload(
      `/${organizationId}/${propertyId}/affordable-qualifications/${affordableQualificationId}/generate59`,
      fileName,
      JSON.stringify({ fileName }),
      options,
    );
  }

  generateAndDownloadAll59As(
    organizationId: string,
    propertyId: string,
    fileName: string,
    options?: Object,
  ) {
    return postDownload(
      `/${organizationId}/${propertyId}/generate-all-gross-rent-59a`,
      fileName,
      '{}',
      options,
    );
  }

  generateRD(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    fileName: string,
    options?: Object,
  ) {
    return postDownload(
      `/${organizationId}/${propertyId}/affordable-qualifications/${affordableQualificationId}/generateRD`,
      fileName,
      JSON.stringify({ fileName }),
      options,
    );
  }

  cancelCertification(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    options?: Object,
  ) {
    return del(
      `/${organizationId}/${propertyId}/affordable-qualifications/${affordableQualificationId}/cancel`,
      undefined,
      options,
    );
  }

  downloadRecertificationForm(
    organizationId: string,
    propertyId: string,
    applicantId: string,
    affordableQualificationId: string,
    fileName: string,
    options?: Object,
  ) {
    const params = `?applicantId=${applicantId}`;
    return getDownload(
      // eslint-disable-next-line max-len
      `/${organizationId}/${propertyId}/affordable-qualifications/${affordableQualificationId}/download-recert-form${params}`,
      fileName,
      { options },
    );
  }

  downloadIncomeAssetForm(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    applicantId: string,
    type: string,
    fileName: string,
    options?: Object,
  ) {
    const params = `?type=${type}&applicantId=${applicantId}`;
    return getDownload(
      `/${organizationId}/${propertyId}/affordable-qualifications/${affordableQualificationId}/download-form${params}`,
      fileName,
      { options },
    );
  }

  downloadDefaultIncomeAssetForm(
    organizationId: string,
    applicationId: string,
    applicantId: string,
    propertyId: string,
    type: string,
    fileName: string,
    options?: Object,
  ) {
    const params = `?type=${type}&applicationId=${applicationId}&applicantId=${applicantId}`;
    return getDownload(
      `/${organizationId}/${propertyId}/affordable-qualifications/download-default-form${params}`,
      fileName,
      { options },
    );
  }

  getAffordableQualificationDocuments(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
  ) {
    return get(
      // eslint-disable-next-line max-len
      `/${organizationId}/${propertyId}/affordable-qualification/${affordableQualificationId}/affordable-qualification-document`,
    );
  }

  async patchAffordableQualification(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    valuesToModify: Object,
    isEdited: boolean = false,
    options?: Object,
  ) {
    return patch(
      `/${organizationId}/${propertyId}/affordable-qualifications/${affordableQualificationId}?isEdited=${isEdited}`,
      JSON.stringify(valuesToModify),
      options,
    );
  }

  async getById(
    organizationId: string,
    propertyId: string,
    affordableQualificationId: string,
    options?: object,
  ): Promise<AffordableQualification> {
    return get(
      `/${organizationId}/${propertyId}/affordable-qualifications/${affordableQualificationId}`,
      options,
    );
  }
}
