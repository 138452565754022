import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import messages from './messages';

export const STAT_PROGRAMS = ['HUD', 'LIHTC'];
export const STAT_BOX_KEYS = [
  'lateRecerts',
  'under30',
  'between31And60',
  'between61And90',
  'between90And120',
];

export const RecertificationStatBox = ({ statistics }: Object) => {
  const StyledStatRow = styled.div`
    font-size: 14px;
    font-weight: 400;
  `;

  const StyledStatContainer = styled.div`
    :first-child {
      padding-left: 0px;
    }

    :last-child {
      padding-right: 0px;
    }
  `;

  return STAT_BOX_KEYS.map((stat: string) => {
    const statsByProgram = statistics[stat] || {};
    return (
      <StyledStatContainer className="col-md-2 col-lg-2" key={stat}>
        <div className="block-data block-white-shadow">
          <h5>
            <FormattedMessage {...messages.recertsDue} />
            <FormattedMessage {...messages[stat]} />
          </h5>
          <div data-testid={'statProgramsList'}>
            {STAT_PROGRAMS.map((program, key) => {
              const val = statsByProgram[program] || 0;
              return (
                <StyledStatRow key={key}>{`${program} - ${val}`}</StyledStatRow>
              );
            })}
          </div>
        </div>
      </StyledStatContainer>
    );
  });
};
