import { isNil } from 'ramda';

import { validateRequired } from '../../../utils/validations';

import formMessages from '../../../containers/ShortLeaseApplication/messages';

import type { EmploymentInformation } from './types';

const warn = (values: EmploymentInformation, { intl }: Object) => {
  const warnings = {};

  if (isNil(values)) {
    warnings.status = intl.formatMessage(formMessages.requiredForCompletion);
    warnings.annualIncome = intl.formatMessage(formMessages.salaryRequired);
    return warnings;
  }

  if (!validateRequired('status', values)) {
    warnings.status = intl.formatMessage(formMessages.requiredForCompletion);
  }

  if (!validateRequired('anticipateChangeEmployer', values)) {
    warnings.anticipateChangeEmployer = intl.formatMessage(
      formMessages.requiredForCompletion
    );
  }

  return warnings;
};

export default warn;
