import {
  handleSocketTransactionFailure,
  handleSocketTransactionSuccess,
} from './actions';

/**
 * Consumed by Socket context to register listeners
 */
const createTransactionSockethandlers = (dispatch: Function, socket: any) => {
  socket.on('ctx.error', (message) => {
    const { text } = message;
    dispatch(handleSocketTransactionFailure(text));
  });

  socket.on('ctx.success', (message) => {
    const { payload, text } = message;
    dispatch(handleSocketTransactionSuccess({ payload, text }));
  });
};

export default createTransactionSockethandlers;
