import React from 'react';
import ErrorImage from '../../img/error-maintenance-window.svg';
import DocumentTitle from 'react-document-title';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 16px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 180px;
  text-align: center;
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 2.8em;
`;

const P = styled.p`
  max-width: 350px;
  margin: 0 auto;
`;

const Img = styled.img`
  max-width: calc(100vw - 32px);
`;

const MaintenancePage = () => {
  return (
    <DocumentTitle title={'Fortress maintenance'}>
      <Wrapper>
        <TextWrapper>
          <Title> Fortress Under Maintenance </Title>
          <P>
            Fortress is undergoing routine maintenance, and will be back up
            shortly.
          </P>
        </TextWrapper>
        <Img
          src={ErrorImage}
          alt={'A dinosaur attacking alien spaceships in a city'}
        />
      </Wrapper>
    </DocumentTitle>
  );
};

export default MaintenancePage;
