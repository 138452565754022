import type { Saga } from 'redux-saga';
import RoleService from '../../services/roleService';
import type { Action, FilterValueAndPagination } from '../App/types';

export const getAllRoles = async (
  payload: Action<FilterValueAndPagination>,
): Saga<void> => {
  const {
    organizationId,
    nextPage,
    limit,
    currentSorting,
    setMeta,
    setRoles,
    promptToaster,
  } = payload;
  try {
    const roleService = new RoleService();
    const response = await roleService.getAllRolesPaginate(
      organizationId,
      nextPage,
      limit,
      currentSorting,
    );
    if (response.results.length === 0) {
      promptToaster({
        type: 'info',
        message: 'There is currently no data to report',
        title: 'No data found!',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      });
    }
    const meta = response.meta;
    const roles = response.results;
    setMeta(meta);
    setRoles(roles);
  } catch (err) {
    promptToaster({
      type: 'error',
      title: 'Error',
      message: 'There was a problem fetching the data',
    });
  }
};
