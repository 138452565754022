import React from 'react';
import { FieldArray } from 'redux-form';
import MinorFields from './MinorFields';

class Minors extends React.Component<any> {
  render() {
    const { leaseLocked } = this.props;
    return (
      <div className="applicant-grp">
        <div className="block-heading row">
          <h2>Minor(s) - Under 18</h2>
        </div>
        <div className="row">
          <div className="labels-head container-of-inputs">
            <div className="col col-xs-6 col-sm-2">
              <label>First*</label>
            </div>
            <div className="col col-xs-6 col-sm-2">
              <label>Middle</label>
            </div>
            <div className="col col-xs-6 col-sm-2">
              <label>Last*</label>
            </div>
            <div className="col col-xs-6 col-sm-1">
              <label>Suffix</label>
            </div>
            <div className="col col-xs-6 col-sm-1">
              <label>Prefered Name</label>
            </div>
            <div className="col col-xs-6 col-sm-1">
              <label>Relation*</label>
            </div>
            <div className="col col-xs-6 col-sm-1">
              <label>Affordable Relation*</label>
            </div>
            <div className="col col-xs-6 col-sm-2">
              <label>Age</label>
            </div>
          </div>
        </div>
        <div className="row">
          <FieldArray
            name="minors"
            component={MinorFields}
            deleteMinor={this.props.deleteMinor}
            leaseLocked={leaseLocked}
          />
        </div>
        <div className="row">
          <a
            className="btn-text"
            onClick={this.props.addMinor}
            disabled={leaseLocked}
          >
            + Add Minor
          </a>
        </div>
      </div>
    );
  }
}

export default Minors;
