import request, { apiURL } from '../../utils/api';
import { any, assoc, is, isEmpty, values } from 'ramda';
import FloorPlanPricingService from '../../services/floorPlanPricingService';
import {
  buildUnitAvailability,
  sortAndFilterUnits,
  sortUnitsByMoveInReady,
} from '../../utils/unit-helpers';
import { parse } from 'qs';

export const getCSV = async ({
  organizationId,
  selectedPropertyId,
}: {
  organizationId: string,
  selectedPropertyId: string,
}) => {
  const options = {
    headers: {
      Authorization: localStorage.getItem('session_id'),
      'Content-Type': 'application/json',
    },
  };

  return request(
    `${apiURL}/${organizationId}/${selectedPropertyId}/unitAvailability`,
    options,
  );
};

export const getHeaders = (headers: any, removeHeaderId: string) => {
  return headers.filter((header) => header.id !== removeHeaderId);
};

export const getFilteredUnits = (
  allUnits: Array<any>,
  query: any,
  location: string,
): any => {
  const { fromUnit } = parse(location.search.replace('?', ''));

  const { leased, statuses, amenities } = query;
  const sortingQuery = query?.sorting ?? {};
  const field = sortingQuery?.fieldName ?? '';
  const order = sortingQuery?.order ?? '';
  const searchText = query?.searchText ?? '';
  const sorted = sortAndFilterUnits(allUnits, field, order, searchText);
  return sorted.filter((unit) => {
    const unitLeased = (unit?.rows?.leased?.value ?? '') || 'false';
    const unitStatus = unit?.rows?.status?.value ?? '';
    const unitNumber = unit?.rows?.unit?.value ?? '';
    const unitFeesIds = (unit?.unitFees ?? []).map(
      (uf) => uf?.propertyFees?.id,
    );

    if (unitNumber === fromUnit) {
      return false;
    }
    const isValid =
      (any((s) => s === true)(values(statuses))
        ? statuses[unitStatus]
        : true) &&
      (any((l) => l === true)(values(leased)) ? leased[unitLeased] : true);

    if ((amenities ?? []).length === 0) {
      return isValid;
    }

    return (
      isValid && unitFeesIds.filter((uf) => amenities.includes(uf)).length > 0
    );
  });
};

export const hasFilters = (query: Object) => {
  const { leased, statuses, amenities } = query;
  return !isEmpty(leased) || !isEmpty(statuses) || !isEmpty(amenities);
};

export const parseCSV = async ({
  data,
  intl,
  isAllCommercial,
  organizationId,
  propsHeaders,
  selectedPropertyId,
  isColumnSorted,
}: {
  data: Array<Object>,
  intl: Array<Object>,
  isAllCommercial: boolean,
  organizationId: string,
  propsHeaders: Object[],
  selectedPropertyId: string,
  isColumnSorted: boolean,
}) => {
  const filteredHeaders = isAllCommercial
    ? getHeaders(propsHeaders, 'resident')
    : getHeaders(propsHeaders, 'tenant');

  const headers = filteredHeaders
    .filter((header) => ['view', 'actions'].indexOf(header.id) === -1)
    .map((header) => header.title.props.defaultMessage);

  const units = (data?.units ?? []).map((unit) => {
    const property = {
      ...unit.property,
      propertyFees: data.propertyFees,
    };
    return { ...unit, property };
  });

  const floorPlanPricingService = new FloorPlanPricingService();

  const marketRents = await floorPlanPricingService.getAllMarketRentsByProperty(
    organizationId,
    selectedPropertyId,
  );

  const { arr } = buildUnitAvailability(units || [], intl, marketRents);

  const sortedArr = isColumnSorted ? arr : sortUnitsByMoveInReady(arr);

  const rows = sortedArr.map((row) =>
    row && is(Object, row.rows)
      ? values(row.rows).map((data, index) => {
          const column = assoc('columnName', headers[index], data);
          if (!data || isEmpty(data.value) || data.value === '') {
            return '---';
          }
          const value = data.value;
          const columnName = column.columnName;
          if (
            value &&
            typeof value === 'string' &&
            (value.indexOf('/') !== -1 || value.indexOf('-') !== -1) &&
            columnName !== 'Resident' &&
            columnName !== 'Tenant'
          ) {
            return `="${value.toLocaleString()}"`;
          }
          return `"${value ? value.toLocaleString() : ''}"`;
        })
      : [],
  );

  return { headers, rows };
};

const counter = (arr) => {
  return arr.reduce(
    // eslint-disable-next-line no-sequences
    (prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev),
    {},
  );
};

export const getLeasedCount = (unitData) => {
  const leasedCount = counter(unitData.map((unit) => unit.leased['value']));
  const { '': No, ...rest } = leasedCount;
  return { ...rest, No };
};

export const getStatusCount = (unitData) => {
  return counter(unitData.map((unit) => unit.status['value']));
};

const sortByAscendingBedroomSize = (a, b) => {
  return parseInt(a[0]) > parseInt(b[0]) ? 1 : -1;
};

export const getSortedUnits = (units) => {
  return new Map([...units].sort(sortByAscendingBedroomSize));
};

export const getUnitsStatusSummary = (units) => {
  const unitsByBedroomSize = new Map();
  const unitsSummaryCount = new Map();

  units.forEach((value) => {
    const row = value.rows;
    const { bedsBaths, leased, status } = row;
    const unitData = { bedsBaths, leased, status };
    const bedroomSize = bedsBaths['value'].split('/')[0].trim();

    if (!unitsByBedroomSize.has(bedroomSize)) {
      unitsByBedroomSize.set(bedroomSize, [unitData]);
      return;
    }
    unitsByBedroomSize.set(bedroomSize, [
      unitData,
      ...unitsByBedroomSize.get(bedroomSize),
    ]);
  });

  unitsByBedroomSize.forEach((unitDataArray, bedroomSize) => {
    unitsSummaryCount.set(bedroomSize, {
      bedroomSize: bedroomSize,
      leased: getLeasedCount(unitDataArray),
      status: getStatusCount(unitDataArray),
    });
  });

  const unitsGreaterThanFive = new Map(
    [...unitsSummaryCount].filter(([bedroomSize]) => bedroomSize > 4),
  );

  if (
    unitsGreaterThanFive.size > 1 ||
    (unitsGreaterThanFive.size === 1 && !unitsGreaterThanFive.get('5'))
  ) {
    return combineUnitsGreaterThanFive(unitsGreaterThanFive, unitsSummaryCount);
  }

  return getSortedUnits(unitsSummaryCount);
};

export const combineUnitsGreaterThanFive = (
  unitsGreaterThanFive,
  unitsSummary,
) => {
  /** function only called in the very slim edge case that a property offers units with 5+ bedrooms **/
  const sum = (a, b) => (a || 0) + (b || 0);
  const fiveBedroomsOrMore = [...unitsGreaterThanFive];

  const leasedCount = fiveBedroomsOrMore
    .map((arr) => arr[1].leased['Yes'])
    .reduce(sum);

  const noLeaseCount = fiveBedroomsOrMore
    .map((arr) => arr[1].leased['No'])
    .reduce(sum);

  const vacantReadyCount = fiveBedroomsOrMore
    .map((arr) => arr[1].status['Vacant - Ready'])
    .reduce(sum);

  const vacantNotReadyCount = fiveBedroomsOrMore
    .map((arr) => arr[1].status['Vacant / Not Ready'])
    .reduce(sum);

  const occupiedOnNoticeToVacateCount = fiveBedroomsOrMore
    .map((arr) => arr[1].status['Occupied / On Notice to Vacate'])
    .reduce(sum);

  const unitsGreaterThanFiveCondensed = new Map(
    [...unitsSummary].filter(([bedroomSize]) => bedroomSize < 5),
  );

  unitsGreaterThanFiveCondensed.set('5', {
    bedroomSize: '5+',
    leased: { Yes: leasedCount, No: noLeaseCount },
    status: {
      'Vacant - Ready': vacantReadyCount,
      'Vacant / Not Ready': vacantNotReadyCount,
      'Occupied / On Notice to Vacate': occupiedOnNoticeToVacateCount,
    },
  });
  return getSortedUnits(unitsGreaterThanFiveCondensed);
};
