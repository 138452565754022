import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.LeaseApplicantNonPrimaryForm.Title',
    defaultMessage: 'Non-Primary Lease Application for {propertyName}'
  },
  currentResidenceInformationTitle: {
    id: 'App.LeaseApplicantNonPrimaryForm.CurrentResidenceInformationTitle',
    defaultMessage: 'Current Residence Information'
  },
  previousResidenceInformationTitle: {
    id: 'App.LeaseApplicantNonPrimaryForm.PreviousResidenceInformationTitle',
    defaultMessage: 'Previous Residence Information'
  },
  applicantsCurrentEmployerTitle: {
    id: 'App.LeaseApplicantNonPrimaryForm.ApplicantsCurrentEmployerTitle',
    defaultMessage: "Applicant's Current Employer"
  },
  applicantsSecondCurrentEmployerTitle: {
    id: 'App.LeaseApplicantNonPrimaryForm.ApplicantsSecondCurrentEmployerTitle',
    defaultMessage: "Applicant's 2nd Current Employer (If Applicable)"
  },
  applicantsPreviousEmployerTitle: {
    id: 'App.LeaseApplicantNonPrimaryForm.ApplicantsPreviousEmployerTitle',
    defaultMessage: "Applicant's Previous Employer"
  },
  completeConfirmation: {
    id: 'App.LeaseApplicantNonPrimaryForm.CompleteConfirmation',
    defaultMessage: 'Are you sure you want to complete this application?'
  },
  cancelConfirmation: {
    id: 'App.LeaseApplicantNonPrimaryForm.CancelConfirmation',
    defaultMessage: 'Are you sure you want to cancel?'
  },
  streetAddressLabel: {
    id: 'App.LeaseApplicantNonPrimaryForm.StreetAddressLabel',
    defaultMessage: 'Employer Street Address'
  },
  passportNumberLabel: {
    id: 'App.LeaseApplicantNonPrimaryForm.PassportNumberLabel',
    defaultMessage: 'Passport Number (if no SSN)'
  },
  from: {
    id: 'App.LeaseApplicantNonPrimaryForm.From',
    defaultMessage: 'From'
  },
  startDate: {
    id: 'App.LeaseApplicantNonPrimaryForm.StartDate',
    defaultMessage: 'Start Date'
  }
});

export default messages;
