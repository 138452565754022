import React, { Component } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import confirm from '../../../components/ConfirmDialogModal';
import {
  renderSimpleCheckboxField,
  renderCheckboxSwitch,
} from '../../../utils/redux-form-helper';
import { getMonthEndClose, submitMonthEndClose } from '../actions';
import { promptToaster } from '../../App/actions';
import { FORM_NAME } from '../constants';
import messages from '../messages';
import validate, { validateMonth } from '../validate';
import type { MonthEndClose } from '../types';
import moment from 'moment';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { getMonthEndCloseZipFile } from '../../ManageReports/utils';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

type ConnectedMethods = {
  actions: {
    getMonthEndClose: Function,
    submitMonthEndClose: Function,
    promptToaster: any,
  },
};

type ReduxFormProps = {
  anyTouched: boolean,
  handleSubmit: Function,
  invalid: boolean,
};

type DefaultProps = {
  today: Date,
};

type RouteProps = {
  history: Object,
};

type Props = ReduxFormProps &
  RouteProps &
  MonthEndClose &
  ConnectedMethods &
  DefaultProps & {
    onSubmit?: Function,
    intl: Object,
  };

export class MonthEndCloseForm extends Component<Props> {
  static defaultProps = {
    today: Date.now(),
  };

  componentDidMount() {
    const {
      actions: { getMonthEndClose },
    } = this.props;
    getMonthEndClose();
  }

  handleCancel = () => {
    const { history } = this.props;
    history.push('/');
  };

  handleSubmit = () => {
    const {
      actions: { submitMonthEndClose },
      intl,
      periodName,
    } = this.props;

    confirm(
      `${intl.formatMessage(messages.confirmMessage)}?:\n\n${periodName}`,
      {
        intl,
      },
    ).then(() => {
      submitMonthEndClose();
    });
  };

  renderAlertBox = ({ periodName, isClosePending }: Object) => {
    if (!periodName) return null;

    const errors = validateMonth(periodName);
    const formattedMessage = errors ? (
      <FormattedMessage
        {...messages.errorAlert}
        values={{ periodName: periodName, month: moment().format('MMMM') }}
      />
    ) : isClosePending ? (
      <FormattedMessage
        {...messages.pendingAlert}
        values={{ periodName: periodName }}
      />
    ) : (
      <FormattedMessage
        {...messages.closeAlert}
        values={{ periodName: periodName }}
      />
    );

    return (
      <div className={`alert ${errors ? 'alert-danger' : 'alert-info'}`}>
        {formattedMessage}
      </div>
    );
  };

  renderClosedByBox = ({ lastClosed }: Object) => {
    if (!lastClosed?.id) {
      return null;
    }
    const name = `${lastClosed?.user?.firstName ?? ''} ${
      lastClosed?.user?.lastName ?? ''
    }`.trim();
    const closeDateTimeObj = moment(lastClosed.endDate);
    const date = closeDateTimeObj.format('MMM D, YYYY');
    const time = closeDateTimeObj.format('h:mma');
    const formattedMessage = `Last AME by ${name} on ${date} @ ${time}`;
    return lastClosed?.id ? (
      <div className={'alert alert-primary'}>{formattedMessage}</div>
    ) : null;
  };

  getZippedFile = (fileType: string) => {
    const { property, period } = this.props;
    getMonthEndCloseZipFile(property, period, fileType, promptToaster);
  };

  render() {
    const {
      anyTouched,
      hasSavedPayments,
      hasUndepositedPayments,
      handleSubmit,
      invalid,
      period,
      periodName,
      isClosePending,
      today,
      flags,
      lastClosed,
    } = this.props;
    const { ameReportsDownloadPacket } = flags;

    const currentPeriod = `${period?.slice(0, 4)}-${period?.slice(4)}`;
    return (
      <form className="bodywrap" onSubmit={handleSubmit(this.handleSubmit)}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12 col-lg-8">
              <h1>
                <i className="text-blue icons et-checklist padright10" />
                <FormattedMessage {...messages.header} />
              </h1>
            </div>
            <div className="col-xs-12 col-lg-4">
              <strong className="float-right">
                <FormattedMessage {...messages.period} />
                {': '}
                {currentPeriod} <FormattedMessage {...messages.date} />
                {': '}
                <FormattedDate value={today} />
              </strong>
            </div>
            <div className="col-xs-12 col-lg-8">
              <Field
                name="validateDate"
                periodName={periodName}
                isClosePending={isClosePending}
                component={this.renderAlertBox}
              />
            </div>
            <div className="col-xs-12 col-lg-3 col-lg-offset-1">
              <Field
                name="lastClosedBy"
                component={this.renderClosedByBox}
                lastClosed={lastClosed}
              />
            </div>
          </div>
        </div>
        <div className="container-fluid form-steps">
          <div className="card">
            <div className="row">
              <div className="col-xs-8 col-lg-10">
                <h3>
                  <FormattedMessage {...messages.step} /> 1
                </h3>
                <p>
                  <FormattedMessage {...messages.noUnpostedPayments} />
                </p>
                <p className="text-gray">
                  <FormattedMessage {...messages.ifUnpostedPayments} />
                </p>
              </div>
              <div className="col-xs-4 col-lg-2 text-center">
                <Field
                  disabled={hasSavedPayments || hasUndepositedPayments}
                  name="step-1"
                  component={renderCheckboxSwitch}
                />
              </div>
            </div>
          </div>
          <div className="card">
            <div className="row">
              <div className="col-xs-8 col-lg-10">
                <h3>
                  <FormattedMessage {...messages.step} /> 2
                </h3>
                <p>
                  <FormattedMessage {...messages.upToDate} />
                </p>
              </div>
              <div className="col-xs-4 col-lg-2 text-center">
                <Field name="step-2" component={renderCheckboxSwitch} />
              </div>
            </div>
          </div>
          <div className="card">
            <div className="row">
              <div className="col-xs-8 col-lg-10">
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <h3 style={{ marginTop: '5px' }}>
                    <FormattedMessage {...messages.step} /> 3
                  </h3>
                  {ameReportsDownloadPacket && (
                    <>
                      <button
                        className="btn-tertiary pull-left padleft10 btn"
                        style={{ marginTop: '0' }}
                        onClick={() => this.getZippedFile('csv')}
                        disabled={!periodName}
                      >
                        <i className="et-cloud-download" />
                        <span style={{ marginLeft: '8px' }}>CSV </span>
                      </button>
                      <button
                        className="btn-tertiary pull-left padleft10 btn"
                        style={{ marginTop: '0' }}
                        onClick={() => this.getZippedFile('pdf')}
                        disabled={!periodName}
                      >
                        <i className="icon et-cloud-download" />
                        <span style={{ marginLeft: '8px' }}>PDF </span>
                      </button>
                    </>
                  )}
                </Stack>
                <p>
                  <FormattedMessage {...messages.haveReviewed} />
                </p>
                <ul>
                  <li>
                    <Field
                      name="customerOpsLedger"
                      component={renderSimpleCheckboxField}
                      label={
                        <FormattedMessage {...messages.customerOpsLedger} />
                      }
                    />
                  </li>
                  <li>
                    <Field
                      name="depositBatchDetail"
                      component={renderSimpleCheckboxField}
                      label={
                        <FormattedMessage {...messages.depositBatchDetail} />
                      }
                    />
                  </li>
                  <li>
                    <Field
                      name="moveIn"
                      component={renderSimpleCheckboxField}
                      label={<FormattedMessage {...messages.moveIn} />}
                    />
                  </li>
                  <li>
                    <Field
                      name="moveOut"
                      component={renderSimpleCheckboxField}
                      label={<FormattedMessage {...messages.moveOut} />}
                    />
                  </li>
                  <li>
                    <Field
                      name="renewal"
                      component={renderSimpleCheckboxField}
                      label={<FormattedMessage {...messages.renewal} />}
                    />
                  </li>
                  <li>
                    <Field
                      name="securityDeposits"
                      component={renderSimpleCheckboxField}
                      label={
                        <FormattedMessage {...messages.securityDeposits} />
                      }
                    />
                  </li>
                  <li>
                    <Field
                      name="delinquentAndPrepaid"
                      component={renderSimpleCheckboxField}
                      label={
                        <FormattedMessage {...messages.delinquentAndPrepaid} />
                      }
                    />
                  </li>
                  <li>
                    <Field
                      name="availability"
                      component={renderSimpleCheckboxField}
                      label={<FormattedMessage {...messages.availability} />}
                    />
                  </li>
                </ul>
              </div>
              <div className="col-xs-4 col-lg-2 text-center">
                <Field name="step-3" component={renderCheckboxSwitch} />
              </div>
            </div>
          </div>
          <div className="card">
            <div className="row">
              <div className="col-xs-8 col-lg-10">
                <h3>
                  <FormattedMessage {...messages.step} /> 4
                </h3>
                <p>
                  <FormattedMessage {...messages.confirm} />
                </p>
              </div>
              <div className="col-xs-4 col-lg-2 text-center">
                <Field name="step-4" component={renderCheckboxSwitch} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 col-lg-6 text-right">
              <button onClick={this.handleCancel} className="btn btn-default">
                <FormattedMessage {...messages.cancel} />
              </button>
            </div>
            <div className="col-xs-6 col-lg-6 text-left">
              <button
                disabled={!anyTouched || invalid || isClosePending}
                type="submit"
                className="btn btn-primary"
              >
                <FormattedMessage {...messages.submit} />
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export const mapsStateToProps = (props: Object): MonthEndClose => {
  const property = props?.app?.selectedProperty ?? {};
  return {
    ...props.monthEndClose,
    property,
  };
};

export const mapDispatchToProps = (dispatch: Object): ConnectedMethods => {
  return {
    actions: bindActionCreators(
      {
        getMonthEndClose,
        submitMonthEndClose,
        promptToaster,
      },
      dispatch,
    ),
  };
};

export default withLDConsumer()(
  connect(
    mapsStateToProps,
    mapDispatchToProps,
  )(
    reduxForm({
      form: FORM_NAME,
      touchOnChange: true,
      validate,
    })(MonthEndCloseForm),
  ),
);
