import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { createConfirmation, confirmable } from 'react-confirm';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { findIndex, sortBy } from 'lodash';
import type Moment from 'moment';
import moment from 'moment';
import { contains, defaultTo, find, propEq } from 'ramda';
import Styled from 'styled-components';
import {
  renderCheckboxField,
  RenderFortressSelectField,
  renderDateField,
  renderSelectField,
  renderTextAreaField,
  renderTimeField,
} from '../../utils/redux-form-helper';
import ErrorMessage from '../../components/ErrorMessage';
import messages from './messages';
import validate from './validate';
import warn from './warn';

import { bindActionCreators } from 'redux';
import actions from 'redux-form/lib/actions';
import type {
  Activity,
  Prospect,
} from '../../containers/ProspectProfile/types';
import type {
  ActivityCompletionStatus,
  DropdownOption,
  Property,
} from '../../containers/App/types';
import AssigneeCalendar from '../AssigneeCalendar';
import ActivityService from '../../services/activityService';
import ElementWithPermissions from '../../components/ElementWithPermissions';
import LinkedElementWithPermissions from '../../components/LinkedElementWithPermissions';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

type State = {
  runInitialAsyncValidation: boolean,
  assigneeActivities: Array<Activity>,
  calendarView: boolean,
  showDeleteConfirmation: boolean,
  showCancelConfirmation: boolean,
};

type ActivityType = {
  completionStatusOptions: Array<ActivityCompletionStatus>,
  canBeOverlapped: boolean,
};

type Props = {
  pristine: boolean,
  valid: boolean,
  warning: string,
  error: Object,
  asyncValidate: Function,
  show: boolean,
  proceed: Function,
  dismiss: Function,
  activity: Activity,
  intl: Object,
  store: Object,
  activityTypesList: Array<DropdownOption & ActivityType>,
  assigneeList: Array<DropdownOption>,
  prospect: Prospect,
  stageInfo: Object,
};

type StateProps = {
  locale: string,
  startDate: Moment,
  startTime: Moment,
  endTime: Moment,
  notes: string,
  activityTypeId: string,
  activityCompletionStatusId: string,
  assignedToId: string,
  allDay: boolean,
  selectedProperty: Property,
  organizationId: string,
};

const StyledModal = Styled(Modal)`
  .modal-lg {
    width: 910px;
  }
`;

const StyledHeader = Styled.h3`
  margin-top: 5px !important;
  padding-bottom: 4px;
`;

const StyledH5 = Styled.h5`
  margin-top: 4px;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 14px;
`;

const StyledDeleteIcon = Styled.i.attrs({
  className: 'et-trash hidden-xs',
})`
  &&& {
    color: #D70000 !important;
  }
`;

const StyledDeleteButton = Styled(Button).attrs({
  className: 'btn-tertiary btn-delete pull-left',
})`
  &:hover ${StyledDeleteIcon} {
    color: #ffffff !important;
  }
  &&& {
    background: white;
    border: 1px solid #D70000;
    border-radius: 5px;
    text-decoration: none;
    color: #D70000 !important;
    border: 1px solid #D70000 !important;
  }
`;

export class EditPendingActivityModal extends Component<
  StateProps & Props,
  State,
> {
  constructor(props: StateProps & Props) {
    super(props);
    this.getActivity.bind(this);
    this.generateCompletionStatusList.bind(this);
    this.state = {
      runInitialAsyncValidation: true,
      calendarView: false,
      assigneeActivities: [],
      showDeleteConfirmation: false,
      showCancelConfirmation: false,
    };
  }

  componentDidUpdate() {
    if (this.state.runInitialAsyncValidation) {
      this.setState(
        {
          runInitialAsyncValidation: false,
        },
        () => {
          this.props.asyncValidate('startDate', this.props.startDate, 'blur');
        },
      );
    }

    window.onpopstate = (event) => {
      this.props.dismiss();
    };
  }

  deleteConfirmationToggle = (visible: boolean) => {
    this.setState({
      showDeleteConfirmation: visible,
    });
  };

  cancelConfirmationToggle = (visible: boolean) => {
    this.setState({
      showCancelConfirmation: visible,
    });
  };

  confirmDelete = () => {
    this.props.proceed(this.props.activity);
  };

  saveAndClose = () => {
    const updatedActivity = this.getActivity();
    this.props.proceed({ ...updatedActivity, saveAndClose: true });
  };

  saveAndAddNew = () => {
    const updatedActivity = this.getActivity();
    this.props.proceed({ ...updatedActivity, saveAndAddNew: true });
  };
  cancel = () => {
    if (this.state.showDeleteConfirmation || this.state.showCancelConfirmation)
      return;
    if (!this.props.pristine) {
      this.cancelConfirmationToggle(true);
    } else {
      this.props.dismiss();
    }
  };
  renderDeleteConfirmation = () => {
    return (
      <div className="modal-confirm">
        <h1>
          {this.props.intl.formatMessage(messages.deleteConfirmationHeader)}
        </h1>
        <p>
          {this.props.intl.formatMessage(messages.deleteConfirmationText, {
            noText: this.props.intl.formatMessage(messages.no),
          })}
        </p>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="primary"
            className="btn-delete pull-right"
            onClick={this.confirmDelete}
          >
            {this.props.intl.formatMessage(messages.yes)}
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="default"
            className="pull-left"
            onClick={() => this.deleteConfirmationToggle(false)}
          >
            {this.props.intl.formatMessage(messages.no)}
          </Button>
        </Col>
      </div>
    );
  };
  renderCancelConfirmation = () => {
    return (
      <div className="modal-confirm">
        <h1>
          {this.props.intl.formatMessage(messages.cancelConfirmationHeader)}
        </h1>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="primary"
            className="btn-delete pull-right"
            onClick={this.props.dismiss}
          >
            {this.props.intl.formatMessage(messages.yes)}
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="default"
            className="pull-left"
            onClick={() => this.cancelConfirmationToggle(false)}
          >
            {this.props.intl.formatMessage(messages.no)}
          </Button>
        </Col>
      </div>
    );
  };

  generateCompletionStatusList(): Array<Object> {
    let options = [];

    if (this.props.activityTypeId) {
      const selectedActivityType = defaultTo({ completionStatusOptions: [] })(
        find(propEq('value', this.props.activityTypeId))(
          this.props.activityTypesList,
        ),
      );
      const visibleOptions = selectedActivityType.completionStatusOptions;
      options = visibleOptions.map((option) => ({
        value: option.id,
        text: option.translations[this.props.locale] || option.name,
        disabled: false,
      }));
    }
    return options;
  }

  generateActivitiesForAssignee(assignedToId: string) {
    const activityService = new ActivityService();
    activityService
      .getByAssignee(
        assignedToId,
        this.props.selectedProperty.id,
        this.props.organizationId,
      )
      .then((response: Array<Activity>) => {
        this.setState({
          assigneeActivities: response,
        });
      })
      .catch(() => {
        this.setState({
          assigneeActivities: [],
        });
      });
  }

  toggleCalendarView(show: boolean) {
    if (show) {
      this.generateActivitiesForAssignee(this.props.assignedToId);
    }
    this.setState({
      calendarView: show,
    });
  }
  getActivity() {
    this.props.startDate.set({
      hour: this.props.allDay ? 0 : this.props.startTime.hours(),
      minute: this.props.allDay ? 0 : this.props.startTime.minutes(),
      second: 0,
      millisecond: 0,
    });

    const endDate = moment(this.props.startDate);

    endDate.set({
      hour: this.props.allDay ? 23 : this.props.endTime.hours(),
      minute: this.props.allDay ? 59 : this.props.endTime.minutes(),
      second: 0,
      millisecond: 0,
    });

    return {
      id: this.props.activity.id,
      startTime: this.props.startDate.toDate(),
      endTime: endDate.toDate(),
      activityCompletionStatusId: this.props.activityCompletionStatusId,
      notes: this.props.notes,
      activityTypeId: this.props.activityTypeId,
      prospectId: this.props.activity.prospect.id || '',
      assignedToId: this.props.assignedToId,
      allDay: this.props.allDay,
      propertyId: this.props.selectedProperty.id,
    };
  }

  onAssignedToChange(event: any, value: string) {
    this.generateActivitiesForAssignee(value);
  }

  render() {
    const { pristine, valid } = this.props;
    const activityTypeName = this.props.activity.activityType
      ? this.props.activity.activityType.translations[this.props.locale] ||
        this.props.activity.activityType.name
      : '';

    const stageInfo =
      this.props.stageInfo.currentStage === 'applicant'
        ? 'application'
        : this.props.stageInfo.currentStage;

    const assignee = this.props.activity.ownedBy
      ? this.props.activity.ownedBy
      : { id: '', firstName: '---', lastName: '---' };
    const selectedAssignee = find(
      propEq('value', this.props.initialValues.assignedToId),
    )(this.props.assigneeList) || { value: '', text: '---' };

    const assigneeIndex = findIndex(
      this.props.assigneeList,
      (x) => x.value === assignee.id,
    );
    const updatedAt = this.props.activity.updatedAt || new Date();
    const updatedByName = this.props.activity.updatedBy
      ? `${this.props.activity.updatedBy.firstName} ${this.props.activity.updatedBy.lastName}`
      : '---';
    const assignees =
      assigneeIndex < 0
        ? sortBy(
            [
              {
                value: assignee.id,
                text: `${assignee.firstName} ${assignee.lastName}`,
              },
            ].concat(this.props.assigneeList),
            (x) => {
              return x.text.toLowerCase();
            },
          )
        : this.props.assigneeList;
    return (
      <form>
        <StyledModal
          enforceFocus={false}
          backdrop
          bsSize="lg"
          show={this.props.show}
          onHide={this.cancel}
        >
          <Modal.Header closeButton>
            <i className="et-calendar" />
            <Modal.Title componentClass="h1">
              {this.props.intl.formatMessage(messages.title)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.showDeleteConfirmation &&
              this.renderDeleteConfirmation()}
            {this.state.showCancelConfirmation &&
              this.renderCancelConfirmation()}
            <Row>
              <Col xs={12} md={6}>
                <h4>
                  <strong>
                    {this.props.intl.formatMessage(messages.schedulingDetails)}
                  </strong>
                </h4>
              </Col>
              <Col xs={12} md={6}>
                <div>
                  <h4>
                    <strong>
                      {this.props.intl.formatMessage(
                        messages[this.props.stageInfo.currentStage],
                      )}
                      :
                    </strong>{' '}
                    <LinkedElementWithPermissions
                      scope={[
                        'prospect-create',
                        'prospect-read',
                        'prospect-update',
                        'prospect-assign',
                      ]}
                      store={this.props.store}
                      link={getUrlWithSelectedPropertyId(
                        `/${stageInfo}/${this.props.stageInfo.urlId || ''}`,
                      )}
                    >
                      {`${this.props.activity.prospect.firstName || '---'} ${
                        this.props.activity.prospect.lastName || '---'
                      }`}
                    </LinkedElementWithPermissions>
                  </h4>
                </div>
              </Col>
            </Row>
            {this.props.error && (
              <Row>
                <ErrorMessage message={this.props.error.message} />
              </Row>
            )}
            {this.props.warning && (
              <Row>
                {' '}
                <ErrorMessage type="warning" message={this.props.warning} />
              </Row>
            )}
            {this.state.calendarView && (
              <Row>
                <Col xs={12} sm={6}>
                  <a onClick={() => this.toggleCalendarView(false)}>
                    <i className="et-chevron-left" />
                    Back to follow-up details
                  </a>
                  <h2>
                    <span>{`${this.props.intl.formatMessage(
                      messages.calendarFor,
                    )}: ${selectedAssignee.text}`}</span>
                  </h2>
                </Col>
                <Col xs={12}>
                  <div className="box-calendar">
                    <AssigneeCalendar
                      defaultView={'week'}
                      intl={this.props.intl}
                      locale={this.props.locale}
                      selectedAssignee={selectedAssignee.value}
                      store={this.props.store}
                    />
                  </div>
                </Col>
              </Row>
            )}
            <Row
              className={
                this.state.calendarView ||
                this.state.showDeleteConfirmation ||
                this.state.showCancelConfirmation
                  ? 'hide'
                  : ''
              }
            >
              <form>
                <Col xs={12} md={7}>
                  <Row>
                    <Col xs={12}>
                      <ul className="list-no-border">
                        <li className="scheduled-info--basic">
                          <StyledHeader>
                            <strong>
                              {this.props.intl.formatMessage(
                                messages.scheduledActivity,
                              )}
                            </strong>
                          </StyledHeader>
                        </li>
                        <li className="scheduled-info--basic">
                          <i className="et-team" />
                          <h4>
                            {this.props.intl.formatMessage(
                              messages.activityTypeLabel,
                            )}
                            :
                          </h4>
                          <span>{activityTypeName}</span>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <StyledH5>
                        {this.props.intl.formatMessage(
                          messages.activityDateLabel,
                        )}
                      </StyledH5>
                      <Field
                        store={this.props.store}
                        name="startDate"
                        component={renderDateField}
                        bsSize="lg"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <StyledH5>
                        {this.props.intl.formatMessage(messages.startTimeLabel)}
                      </StyledH5>
                      <Field
                        store={this.props.store}
                        name="startTime"
                        component={renderTimeField}
                        bsSize="lg"
                        disabled={this.props.allDay}
                      />
                    </Col>
                    <Col xs={12} md={6}>
                      <StyledH5>
                        {this.props.intl.formatMessage(messages.endTimeLabel)}
                      </StyledH5>
                      <Field
                        store={this.props.store}
                        name="endTime"
                        component={renderTimeField}
                        bsSize="lg"
                        disabled={this.props.allDay}
                      />
                    </Col>
                    <Col xs={6}>
                      <Field
                        store={this.props.store}
                        name="allDay"
                        component={renderCheckboxField}
                        bsSize="lg"
                        label={this.props.intl.formatMessage(
                          messages.noTimeLabel,
                        )}
                      />
                    </Col>
                    <Col xs={6}>
                      <a className="link-availability pull-right">
                        <i className="et-calendar"> </i>
                        <span
                          className="small"
                          onClick={() => this.toggleCalendarView(true)}
                        >
                          Check Availability
                        </span>
                      </a>
                    </Col>
                    <Col xs={12}>
                      <h3>
                        {this.props.intl.formatMessage(
                          messages.scheduleByLabel,
                        )}
                      </h3>
                      <Field
                        store={this.props.store}
                        name="assignedToId"
                        component={renderSelectField}
                        options={assignees}
                        bsSize="lg"
                      />
                    </Col>
                    <Col xs={12} className="scheduled-info--basic">
                      <h5>
                        <strong>
                          {this.props.intl.formatMessage(messages.note)}:
                        </strong>{' '}
                        {this.props.intl.formatMessage(messages.noteContent)}
                      </h5>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={5}>
                  <Row>
                    <Col xs={12}>
                      <h3>
                        {this.props.intl.formatMessage(messages.notesLabel)}
                      </h3>
                      <Field
                        store={this.props.store}
                        name="notes"
                        component={renderTextAreaField}
                        placeholder={this.props.intl.formatMessage(
                          messages.notesPlaceholder,
                        )}
                        rows="3"
                        maxLength="2000"
                      />
                    </Col>
                    <Col xs={12}>
                      <Field
                        store={this.props.store}
                        name="activityCompletionStatusId"
                        component={RenderFortressSelectField}
                        options={this.generateCompletionStatusList()}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="box-last-edit">
                        <p>
                          Last edit by {updatedByName} on{' '}
                          {this.props.intl.formatDate(updatedAt, {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                          })}{' '}
                          @ {this.props.intl.formatTime(updatedAt)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </form>
            </Row>
          </Modal.Body>
          {!this.state.calendarView &&
            !this.state.showDeleteConfirmation &&
            !this.state.showCancelConfirmation && (
              <Modal.Footer>
                <Row>
                  <Col xs={12} sm={2}>
                    <ElementWithPermissions
                      scope={['activity-delete']}
                      store={this.props.store}
                    >
                      <StyledDeleteButton
                        id="delete-button"
                        onClick={() => this.deleteConfirmationToggle(true)}
                        disabled={
                          this.state.showCancelConfirmation ||
                          this.state.showDeleteConfirmation
                        }
                      >
                        <StyledDeleteIcon />
                        <span className="visible-xs">
                          {this.props.intl.formatMessage(messages.delete)}
                        </span>
                      </StyledDeleteButton>
                    </ElementWithPermissions>
                  </Col>
                  <Col xs={12} sm={3}>
                    <Button
                      bsStyle="default"
                      className="pull-right"
                      onClick={this.cancel}
                      disabled={
                        this.state.showCancelConfirmation ||
                        this.state.showDeleteConfirmation
                      }
                    >
                      {this.props.intl.formatMessage(messages.cancel)}
                    </Button>
                  </Col>
                  <Col xs={12} sm={3}>
                    <ElementWithPermissions
                      scope={['activity-update']}
                      store={this.props.store}
                    >
                      <Button
                        bsStyle="primary"
                        className="center-block"
                        onClick={this.saveAndClose}
                        disabled={
                          pristine ||
                          !valid ||
                          this.state.showCancelConfirmation ||
                          this.state.showDeleteConfirmation
                        }
                      >
                        {this.props.intl.formatMessage(messages.saveAndClose)}
                      </Button>
                    </ElementWithPermissions>
                  </Col>
                  <Col xs={12} sm={3}>
                    <ElementWithPermissions
                      scope={['activity-update']}
                      store={this.props.store}
                    >
                      <Button
                        bsStyle="primary"
                        className="pull-left"
                        onClick={this.saveAndAddNew}
                        disabled={
                          pristine ||
                          !valid ||
                          this.state.showCancelConfirmation ||
                          this.state.showDeleteConfirmation
                        }
                      >
                        {this.props.intl.formatMessage(messages.saveAndAddNew)}
                      </Button>
                    </ElementWithPermissions>
                  </Col>
                </Row>
              </Modal.Footer>
            )}
        </StyledModal>
      </form>
    );
  }
}

let InjectedEditPendingActivityModal = reduxForm({
  form: 'editPendingActivity',
  touchOnChange: true,
  validate: validate,
  warn: warn,
  shouldAsyncValidate: (params) => {
    if (!params.syncValidationPasses) {
      return false;
    }

    if (params.trigger === 'change') {
      return false;
    }

    return contains(params.blurredField, [
      'startDate',
      'startTime',
      'endTime',
      'activityTypeId',
      'assignedToId',
    ]);
  },
})(EditPendingActivityModal);

const selector = formValueSelector('editPendingActivity');

const mapStateToProps = (state): StateProps => {
  if (state.app.selectedProperty) {
    return {
      locale: state.languageProvider.locale,
      startDate: selector(state, 'startDate'),
      startTime: selector(state, 'startTime'),
      endTime: selector(state, 'endTime'),
      notes: selector(state, 'notes'),
      activityTypeId: selector(state, 'activityTypeId'),
      activityCompletionStatusId: selector(state, 'activityCompletionStatusId'),
      assignedToId: selector(state, 'assignedToId'),
      allDay: selector(state, 'allDay'),
      selectedProperty: state.app.selectedProperty,
      organizationId: state.app.currentUser
        ? state.app.currentUser.user.organizationId
        : '',
    };
  }
  throw new Error('A property must be selected.');
};

export const mapDispatchToProps = (dispatch: any): Object => {
  const bindedActions = bindActionCreators(
    { updateSyncWarnings: actions.updateSyncWarnings },
    dispatch,
  );
  return { actions: bindedActions };
};

InjectedEditPendingActivityModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedEditPendingActivityModal);

InjectedEditPendingActivityModal = confirmable(
  InjectedEditPendingActivityModal,
);

const dialog = createConfirmation(InjectedEditPendingActivityModal);

export const editPendingActivity = (
  store: any,
  intl: any,
  activity: Activity,
  activityTypesList: Array<DropdownOption & ActivityType>,
  assigneeList: Array<DropdownOption>,
  prospect?: Prospect,
  stageInfo: Object,
  boolean,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    dialog({
      store,
      intl,
      activityTypesList,
      assigneeList,
      stageInfo,
      activity,
      initialValues: {
        prospectCreationDate: prospect ? moment(prospect.createdAt) : null,
        assignedToId: activity.assignedToId,
        activityTypeId: activity.activityTypeId,
        startDate: moment(activity.startTime),
        startTime: moment(activity.startTime),
        endTime: moment(activity.endTime),
        allDay: activity.allDay,
        notes: activity.notes,
      },
    }).then(
      (data: any) => resolve(data),
      (error: Object) => reject(error),
    );
  });
};
