import { defineMessages } from 'react-intl';

const messages = defineMessages({
  errorHeader: {
    id: 'App.CreateCommercialTenant.errorHeader',
    defaultMessage: 'Error',
  },
  signedLease: {
    id: 'App.CreateCommercialTenant.SignedLease',
    defaultMessage: 'Signed Lease',
  },
  leaseStatus: {
    id: 'App.CreateCommercialTenant.LeaseStatus',
    defaultMessage: 'Lease Data Saved in Lease Data Tab',
  },
  contactStatus: {
    id: 'App.CreateCommercialTenant.ContactStatus',
    defaultMessage: 'Contact Information',
  },
  insuranceStatus: {
    id: 'App.CreateCommercialTenant.InsuranceStatus',
    defaultMessage: 'Insurance Information',
  },
  rentDateEstimatedStatus: {
    id: 'App.CreateCommercialTenant.IsRentDateEstimatedStatus',
    defaultMessage: 'Rent Start Date',
  },
  unitAvailabilityStatus: {
    id: 'App.CreateCommercialTenant.UnitAvailabilityStatus',
    defaultMessage: 'Assigned Unit is Vacant',
  },
  convertConfirmation: {
    id: 'App.CreateCommercialTenant.ConvertConfirmation',
    defaultMessage:
      'Are you sure you want to convert this Applicant to a Tenant and Confirm Move-In?',
  },
  successHeader: {
    id: 'App.CreateCommercialTenant.SuccessHeader',
    defaultMessage: 'Success',
  },
  successDescription: {
    id: 'App.CreateCommercialTenant.SuccessDescription',
    defaultMessage: 'Applicant converted to Tenant successfully.',
  },
  leaseStartDateStatus: {
    id: 'App.CreateCommercialTenant.LeaseStartDateStatus',
    defaultMessage:
      'Commencement Date on Lease Data Tab is on or before Today.',
  },
  monthlyLeaseCharges: {
    id: 'App.CreateCommercialTenant.monthlyLeaseCharges',
    defaultMessage: 'Monthly Lease Charges on Lease Data Tab Reconciled',
  },
});

export default messages;
