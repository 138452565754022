import React from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import messages from './messages';
import useSelectedProperty from '../../../hooks/useSelectedProperty';
import type { GraphData } from '../../../services/leaseExpirationLimitService';
import type { History } from '../../../types';
import Graph from './Graph';
import KPICardContainer from '../../../components/KPICardContainer';

export type Props = {
  data: GraphData,
  dataLoaded: boolean,
  options: { value: string, text: string }[],
  selectedMonths: string,
  onSelectedMonthsChange: Function,
  history: History,
  isLeaseExpirationLimitsActive: boolean,
};

function LeaseExpirationExposure({
  data,
  dataLoaded,
  options,
  selectedMonths,
  onSelectedMonthsChange,
  history,
  isLeaseExpirationLimitsActive,
}: Props) {
  const { id: selectedPropertyId } = useSelectedProperty();
  const redirectToLeaseExpiration = (month: string, year: string) => {
    const searchParams = new URLSearchParams(
      R.reject(R.isNil, { month, year }),
    );
    history.push(
      `/property/${selectedPropertyId}/manage-lease-expirations?${searchParams.toString()}`,
    );
  };

  const onColumnClick = (elements) => {
    const index = elements[0]?._index;
    if (!R.isNil(index)) {
      const [month, year] = moment()
        .add(index, 'month')
        .format('MMM-YYYY')
        .split('-');
      redirectToLeaseExpiration(month, year);
    }
  };

  const getSelectedYears = () => {
    const today = moment().startOf('D');
    const finalDate = moment().add(selectedMonths, 'M').startOf('D');

    return R.range(today.year(), finalDate.year() + 1);
  };

  return (
    <KPICardContainer
      title={<FormattedMessage {...messages.title} />}
      subtitle={
        <span>
          <FormattedMessage {...messages.monthToMonthLeases} />{' '}
          {dataLoaded && (
            <a onClick={() => redirectToLeaseExpiration('mtm')}>{data.mtm}</a>
          )}
        </span>
      }
      required={true}
      action={
        <select
          className="input-sm form-control"
          value={selectedMonths}
          onChange={onSelectedMonthsChange}
          data-testid="months-select"
        >
          {options.map(({ value, text }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </select>
      }
      className="panel-body"
    >
      <div className="row">
        <div className="col-xs-12 col-sm-12 text-center">
          <AsyncBox loading={!dataLoaded}>
            <Graph
              graphData={data}
              isDataReady={dataLoaded}
              selectedYears={getSelectedYears()}
              onColumnClick={onColumnClick}
              isLeaseExpirationLimitsActive={isLeaseExpirationLimitsActive}
            />
          </AsyncBox>
        </div>
      </div>
      <div className="row" style={{ marginTop: 15 }}>
        <div className="col-xs-12 text-center">
          <b>
            <FormattedMessage {...messages.noteStart} />
          </b>
          <FormattedMessage {...messages.note} />
        </div>
      </div>
    </KPICardContainer>
  );
}

export default LeaseExpirationExposure;
