import { reset } from 'redux-form';
import { push } from 'react-router-redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { put, throttle, select } from 'redux-saga/effects';
import { pathOr } from 'ramda';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import { SAVE_PROSPECT_PROFILE } from './constants';
import { saveProspectProfileSuccess } from './actions';
import ProspectProfileService from '../../services/prospectProfileService';
import { selectCurrentUserOrganizationId } from '../App/selectors';
import type { Saga } from 'redux-saga';
import type { Action } from '../App/types';
import type { Prospect } from './types';
import messages from './messages';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

export function* fetchSaveProspectProfile(
  action: Action<Prospect>,
): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const prospectProfileService = new ProspectProfileService();
    const response = yield prospectProfileService.save(
      action.payload,
      organizationId,
    );
    const prospectId = pathOr('', ['id'], response);
    yield put(saveProspectProfileSuccess());
    yield put(reset('prospectProfile'));
    yield put(
      push(getUrlWithSelectedPropertyId(`/prospect/${prospectId}`), {
        wasJustCreated: true,
        currentProspect: response,
      }),
    );
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* saveProspectProfile(): Saga<void> {
  yield throttle(500, SAVE_PROSPECT_PROFILE, fetchSaveProspectProfile);
}

export default [saveProspectProfile];
