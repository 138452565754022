import { useMemo } from 'react';
import {
  CERTIFICATION_TYPE_DISPLAY_NAMES,
  INITIAL_CERT_TYPE,
  MOVE_IN_CERT_TYPE,
} from '../../constants/affordableCertificationTypes';
import { RD_PROGRAM_NAME } from '../../constants/affordableProgramNames';
import { COMPLIANCE_TYPES } from './constantsV2';
import { isDocumentOrRecertPastDue } from './utilsV2';

const useParseResults = ({
  results,
  complianceType,
  complianceOverviewReviewedAndNumberOfDays,
}) => {
  return useMemo(() => {
    return results.map((result, index) => {
      const parsedResult = { ...result };
      if (result.hasOwnProperty('isActive')) {
        parsedResult.isActive = Boolean(result.isActive === 'Yes');
      }

      if (result.hasOwnProperty('isResident')) {
        parsedResult.isResident = Boolean(result.isResident === 'Yes');
      }

      if (result.hasOwnProperty('isReviewed')) {
        parsedResult.isAttentionRequired =
          !complianceOverviewReviewedAndNumberOfDays ||
          complianceType === COMPLIANCE_TYPES.NEEDS_REVIEW
            ? undefined
            : !result.isReviewed;
      }

      if (result.hasOwnProperty('certificationType')) {
        const isRDInitial =
          result.certificationType === MOVE_IN_CERT_TYPE &&
          result.programType === RD_PROGRAM_NAME;

        // RD MOVE_IN is called INITIAL
        if (isRDInitial) {
          parsedResult.certificationType =
            CERTIFICATION_TYPE_DISPLAY_NAMES[INITIAL_CERT_TYPE];
        } else {
          parsedResult.certificationType =
            CERTIFICATION_TYPE_DISPLAY_NAMES[result.certificationType] ??
            result.certificationType;
        }
      }

      if (
        result.hasOwnProperty('complianceApprovalStatus') &&
        result.complianceApprovalStatus === null
      ) {
        parsedResult.complianceApprovalStatus = 'None';
      }

      if (
        result.hasOwnProperty('voucherEffectiveDate') &&
        result.hasOwnProperty('certificationType')
      ) {
        parsedResult.isPastDue = isDocumentOrRecertPastDue({
          certificationType: result.certificationType,
          complianceType,
          complianceApprovalStatus: result.complianceApprovalStatus,
          complianceDocumentType: result.complianceDocumentType,
          complianceOverviewReviewedAndNumberOfDays,
          uploadDate: result.uploadDate,
          voucherEffectiveDate: result.voucherEffectiveDate,
        });
      }

      return parsedResult;
    });
  }, [complianceOverviewReviewedAndNumberOfDays, complianceType, results]);
};

export default useParseResults;
