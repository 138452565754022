import { defineMessages } from 'react-intl';

const messages = defineMessages({
  makeReadies: {
    id: 'App.Overview.MakeReadies.MakeReadies',
    defaultMessage: 'Make Readies'
  },
  ready: {
    id: 'App.Overview.MakeReadies.Ready',
    defaultMessage: 'Ready'
  },
  notReady: {
    id: 'App.Overview.MakeReadies.NotReady',
    defaultMessage: 'Not Ready'
  }
});

export default messages;
