import React, { useState } from 'react';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import {
  Divider,
  Switch,
  Typography,
  NumericInput,
  Select,
  Card,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import SetupGridItem from '../SetupGridItem';
import OnOffTypography from '../OnOffTypography';
import { removeNonNumericCharacters } from '../../../utils';

type Props = {
  editMode: boolean,
  config: Object,
  onChange: Function,
  disabled: boolean,
};

const timeframeOptions = ['30', '60', '90'].map((timeframe) => ({
  value: timeframe,
  text: `${timeframe} days`,
  disabled: false,
}));

function SmartPriceSetup({
  editMode: editModeProp,
  config,
  onChange,
  intl,
  disabled,
}: Props) {
  const {
    smartPricingEnabled,
    smartPricingTargetOccupancy,
    smartPricingTargetTimeframe,
  } = config || {};

  const editMode = editModeProp && !disabled;

  const handleNumericFieldChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      target: {
        name,
        value: removeNonNumericCharacters(value),
      },
    });
  };

  const handleTimeframeChange = (x) => {
    const { value } = x.target;
    onChange({
      target: {
        name: 'config.smartPricingTargetTimeframe',
        value,
      },
    });
  };
  const [targetOccError, setTargetOccError] = useState(false);

  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant="h3">
        <FormattedMessage {...messages.smartPrice} />
      </Typography>
      <Divider sx={{ marginY: 2 }} />

      <Grid container spacing={2}>
        <SetupGridItem
          label={<FormattedMessage {...messages.smartPrice} />}
          TooltipProps={{
            title: (
              <FormattedMessage
                {...messages[
                  smartPricingEnabled
                    ? 'tooltipSmartPriceOn'
                    : 'tooltipSmartPriceOff'
                ]}
              />
            ),
          }}
        >
          {editMode ? (
            <Switch
              name="config.smartPricingEnabled"
              id="smartPricingEnabled-switch"
              checked={smartPricingEnabled}
              disabled={true}
              inputProps={{
                'data-checked': smartPricingEnabled,
              }}
            />
          ) : (
            <OnOffTypography on={smartPricingEnabled}>
              {smartPricingEnabled ? (
                <FormattedMessage {...messages.on} />
              ) : (
                <FormattedMessage {...messages.off} />
              )}
            </OnOffTypography>
          )}
        </SetupGridItem>

        <SetupGridItem
          label={
            <>
              <FormattedMessage {...messages.targetOccupancy} /> *
            </>
          }
          TooltipProps={{
            title: <FormattedMessage {...messages.targetOccupancyTooltip} />,
          }}
        >
          <NumericInput
            name="config.smartPricingTargetOccupancy"
            value={smartPricingTargetOccupancy}
            editMode={editMode}
            onChange={handleNumericFieldChange}
            disabled={!smartPricingEnabled || !editMode}
            required={smartPricingEnabled}
            error={targetOccError}
            onValidationChange={(error) => {
              smartPricingTargetOccupancy >= 0 &&
              smartPricingTargetOccupancy <= 100
                ? setTargetOccError(false)
                : setTargetOccError(true);
            }}
            pattern={'[0-100]'}
            adornment={<span>%</span>}
            helperText={
              targetOccError
                ? intl.formatMessage(messages.targetOccupancyError)
                : null
            }
          />
        </SetupGridItem>

        <SetupGridItem
          label={
            <>
              <FormattedMessage {...messages.targetTimeframe} /> *
            </>
          }
          TooltipProps={{
            title: <FormattedMessage {...messages.targetTimeframeTooltip} />,
          }}
        >
          <Select
            name="config.smartPricingTargetTimeframe"
            value={`${smartPricingTargetTimeframe || 30}`}
            onChange={handleTimeframeChange}
            disabled={!smartPricingEnabled || !editMode}
            options={timeframeOptions}
            fullWidth={true}
            multiple={false}
          />
        </SetupGridItem>
      </Grid>
    </Card>
  );
}

export default SmartPriceSetup;
