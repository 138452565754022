import { defineMessages } from 'react-intl';

const messages = defineMessages({
  automated: {
    id: 'App.ActivityTable.Automated',
    defaultMessage: 'A*',
  },
  status: {
    id: 'App.ActivityTable.Status',
    defaultMessage: 'Status',
  },
  type: {
    id: 'App.ActivityTable.Type',
    defaultMessage: 'Type',
  },
  date: {
    id: 'App.ActivityTable.Date',
    defaultMessage: 'Date',
  },
  dateTime: {
    id: 'App.ActivityTable.DateTime',
    defaultMessage: 'Date/Time',
  },
  edit: {
    id: 'App.ActivityTable.Edit',
    defaultMessage: 'Edit',
  },
  time: {
    id: 'App.ActivityTable.Time',
    defaultMessage: 'Time',
  },
  view: {
    id: 'App.ActivityTable.View',
    defaultMessage: 'View',
  },
  notes: {
    id: 'App.ActivityTable.notes',
    defaultMessage: 'Notes',
  },
  scheduledfor: {
    id: 'App.ActivityTable.scheduledfor',
    defaultMessage: 'Scheduled for',
  },
  sortAscending: {
    id: 'App.ActivityTable.SortAscending',
    defaultMessage: 'Oldest to Newest',
  },
  sortDescending: {
    id: 'App.ActivityTable.SortDescending',
    defaultMessage: 'Newest to Oldest',
  },
  scheduledActivityStatusName: {
    id: 'App.ActivityTable.ScheduledActivityStatusName',
    defaultMessage: 'Upcoming',
  },
  upcoming: {
    id: 'App.ActivityTable.Upcoming',
    defaultMessage: 'Upcoming',
  },
  user: {
    id: 'App.ActivityTable.user',
    defaultMessage: 'User',
  },
  today: {
    id: 'App.ActivityTable.Today',
    defaultMessage: 'Today',
  },
  pastdue: {
    id: 'App.ActivityTable.PastDue',
    defaultMessage: 'Past Due',
  },
  createActivity: {
    id: 'App.ActivityTable.CreateActivity',
    defaultMessage: 'Create Activity',
  },
  activityHistory: {
    id: 'App.ActivityTable.ActivityHistory',
    defaultMessage: 'Activity History',
  },
});

export default messages;
