import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ViewHistoryReportModal.Header',
    defaultMessage: 'View History',
  },
  reportGeneratedDate: {
    id: 'App.ViewHistoryReportModal.ReportGeneratedDate',
    defaultMessage: 'Report Generated Date',
  },
  AME: {
    id: 'App.ViewHistoryReportModal.AME',
    defaultMessage: 'AME?',
  },
  reportFormat: {
    id: 'App.ViewHistoryReportModal.reportFormat',
    defaultMessage: 'Format',
  },
  download: {
    id: 'App.ViewHistoryReportModal.Download',
    defaultMessage: 'Download',
  },
  invalidDate: {
    id: 'App.ViewHistoryReportModal.invalidDate',
    defaultMessage: 'Invalid date',
  },
  range90Days: {
    id: 'App.ViewHistoryReportModal.range90Days',
    defaultMessage: 'Please select a range of 90 days or less.',
  },
  noEndDateBeforeStartDate: {
    id: 'App.ViewHistoryReportModal.noEndDateBeforeStartDate',
    defaultMessage: 'Date range invalid',
  },
});

export default messages;
