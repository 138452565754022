import { UseManageProps } from './types';
import { formatDateDisplayUTC } from '@fortress-technology-solutions/fortress-component-library/utils/index';

const useManageProps = ({
  ReactHookFormProps,
  actualOrAnticipatedMoveOutDate,
  closeConfirmModal,
  closeModal,
  editMode,
  formFields,
  handleDelete,
  hasAssignedUnit,
  hasEndDateAfterAnticipatedMoveOutDate,
  householdRentableItems,
  isDirty,
  isValid,
  onItemClick,
  onSubmit,
  open,
  openConfirm,
  openConfirmModal,
  openModal,
}: UseManageProps) => {
  const rentableItemsProps = {
    buttonProps: {
      disabled: !hasAssignedUnit,
      onClick: () => {
        openModal();
      },
    },
    householdRentableItems,
    onItemClick,
  };

  const ConfirmProps = {
    title: 'Are you sure you want to delete this rentable item?',
    open: openConfirm,
    onClose: closeConfirmModal,
    actionsProps: [
      {
        children: 'Cancel',
        onClick: () => {
          closeConfirmModal();
        },
      },
      {
        children: 'Confirm',
        onClick: () => handleDelete(),
      },
    ],
  };

  const modalProps = {
    open,
    onClose: closeModal,
    title: editMode ? 'Rentable Item' : 'Rentable Items',
    actionsProps: [
      {
        children: 'Cancel',
        onClick: () => {
          closeModal();
        },
      },
      {
        children: 'Save',
        submitButton: true,
        sx: { width: 94 },
        isSubmitting: ReactHookFormProps.isSubmitting,
        onClick: async () => {
          await onSubmit();
          closeModal();
        },
        disabled:
          !isValid ||
          !isDirty ||
          Object.keys(ReactHookFormProps?.errors).length > 0,
      },
    ],
    ConfirmProps,
    handleDelete: editMode ? openConfirmModal : undefined,
  };

  const rentableItemsModalFormProps = {
    bannerMessage: actualOrAnticipatedMoveOutDate?.length
      ? `This household is scheduled to move-out on ${formatDateDisplayUTC(
          actualOrAnticipatedMoveOutDate,
        )}. A rentable item cannot be reserved past their scheduled move-out date.`
      : '',
    fields: formFields,
    ReactHookFormProps,
  };

  return {
    modalProps,
    rentableItemsModalFormProps,
    rentableItemsProps,
  };
};

export default useManageProps;
