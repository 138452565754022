export const UNIT_ASSIGNED_OPTIONS = [
  { value: 'yes', text: 'Yes' },
  { value: 'no', text: 'No' },
];

export const PROPERTY_PATH_MAP = (locale) => ({
  applicationCode: {
    path: 'applicationCode',
    dataType: 'number',
  },
  applicants: {
    path: 'applicants',
    dataType: 'string',
  },
  texting: {
    path: 'texting.TOTAL',
    dataType: 'number',
    filterPath: 'texting',
    filterFn: (filterByArr: string[], filterValue: any) => {
      if (filterByArr.includes('new') && filterValue.NEW > 0) {
        return true;
      }
      if (filterByArr.includes('unresolved') && filterValue.UNRESOLVED > 0) {
        return true;
      }
      return false;
    },
  },
  applicationStatusId: {
    path: 'applicationStatus.id',
    dataType: 'string',
  },
  applicantConversionDate: {
    path: 'createdAt',
    dataType: 'date',
  },
  assignedTo: {
    path: 'assigneeName',
    dataType: 'string',
  },
  complianceApprovalHUD: {
    path: 'complianceApprovalHUD',
    dataType: 'string',
  },
  complianceDocumentHUD: {
    path: 'complianceDocumentHUDDate',
    dataType: 'date',
  },
  complianceApproval: {
    path: 'complianceApproval',
    dataType: 'string',
  },
  complianceDocument: {
    path: 'complianceDocumentDate',
    dataType: 'date',
  },
  desiredMoveInDate: {
    path: 'desiredMoveInStartDate',
    dataType: 'date',
  },
  email: {
    path: 'emailAddress',
    dataType: 'string',
  },
  lastActivity: {
    path: 'prospect.lastAct.startTime',
    dataType: 'date',
  },
  nextActivity: {
    path: 'prospect.nextAct.startTime',
    dataType: 'date',
  },
  notes: {
    path: 'notes',
    dataType: 'string',
  },
  phoneNumber: {
    path: 'phoneNumber',
    dataType: 'string',
  },
  scheduledMoveInDate: {
    path: 'moveInDate',
    dataType: 'date',
  },
  unitNumber: {
    path: 'unitNumber',
    dataType: 'string',
    filterPath: 'unitNumber',
    filterFn: (filterBy: string, filterValue: string) => {
      if (filterBy === 'yes') return Boolean(filterValue?.length);
      if (filterBy === 'no') return Boolean(!filterValue);
    },
  },
  waitlistStartTime: {
    path: 'wlStartTime',
    dataType: 'date',
  },
  waitlistNotes: {
    path: 'waitlistNotes',
    dataType: 'string',
  },
});
