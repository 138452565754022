import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

const useReactHookFormProps = (
  { defaultValues, mode } = { defaultValues: {}, mode: 'onBlur' },
) => {
  const {
    handleSubmit,
    control,
    getValues,
    getFieldState,
    formState: { errors, isValid, isDirty, isSubmitting },
    setError,
    setValue,
    clearErrors,
    trigger,
    reset,
    unregister,
    watch,
  } = useForm({
    defaultValues,
    mode,
  });

  return useMemo(
    () => ({
      Controller,
      control,
      getValues,
      setError,
      clearErrors,
      errors,
      getFieldState,
      handleSubmit,
      isValid,
      isDirty,
      isSubmitting,
      setValue,
      trigger,
      reset,
      unregister,
      watch,
    }),
    [
      clearErrors,
      control,
      errors,
      getFieldState,
      getValues,
      handleSubmit,
      isDirty,
      isSubmitting,
      isValid,
      setError,
      setValue,
      trigger,
      reset,
      unregister,
      watch,
    ],
  );
};

export default useReactHookFormProps;
