import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import DocumentTitle from 'react-document-title';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import ReportsTable from './ReportsTable/index';
import messages from './messages';
import PropTypes from 'prop-types';
import { curryN } from 'ramda';
import type { GlobalState, OrderValue, PaginationMeta } from '../App/types';
import { createReport } from '../../components/CreateReportModal';
import ViewHistoryModal from '../../components/ViewHistoryModal';
import { getPeriodOptions } from './selectors';
import { promptToaster } from '../App/actions';

import { useFetchAllReports, useFetchClosedPeriods } from './hooks';
import { downloadLastReport, generateReport } from './utils';

type StateProps = {
  property: Object,
  state: Object,
  users: Array<any>,
  meta: PaginationMeta,
  currentSorting: OrderValue,
  columnOrder: {
    reportName: string,
    reportType: string,
    reportLastCreatedAt: string,
  },
  flags: Object,
};

type InjectedProps = {
  actions: Object,
  intl: any,
  history: Object,
};

const ManageReportPage = (props: StateProps & InjectedProps, context) => {
  const { property, intl, history, actions, flags } = props;
  const propertyId = property?.id ?? null;
  const organizationId = property?.organizationId ?? null;
  const [searchText, setSearchText] = useState('');
  const [columnOrder, setColumnOrder] = useState(props.columnOrder);
  const [currentSorting, setCurrentSorting] = useState(props.currentSorting);
  const [generatedReport, setGeneratedReport] = useState(null);
  const fetchedReports = useFetchAllReports({
    propertyId,
    organizationId,
    currentSorting,
    searchText,
    generatedReport,
    flags,
  });
  const fetchedPeriods = useFetchClosedPeriods({
    propertyId,
    organizationId,
  });
  const reports = fetchedReports?.reports ?? [];
  const periods = fetchedPeriods ?? [];
  const showCreateReportModal = (report: any, periods: Array<any>) => {
    createReport(context.store, intl, report, periods).then((data: any) => {
      generateReport(data, setGeneratedReport, actions.promptToaster);
    });
  };

  const showHistoryModal = (id: string, reports: any) => {
    ViewHistoryModal(context.store, intl, reports, id);
  };

  const handleOrderClick = (field: string) => {
    const columnOrderVar = columnOrder[field];
    const order = columnOrderVar === 'ascending' ? 'DESC' : 'ASC';
    const icon =
      columnOrderVar === 'sortable'
        ? 'ascending'
        : columnOrderVar === 'ascending'
        ? 'descending'
        : 'ascending';
    const newColumnOrder = { [field]: icon };
    setColumnOrder({ ...columnOrder, ...newColumnOrder });
    setCurrentSorting({ fieldName: field, order });
  };

  const getOrder = (fieldName: string) => {
    return fieldName === currentSorting.fieldName ? currentSorting.order : '';
  };

  const handleSubmit = ({ searchText }: Object) => {
    setSearchText(searchText);
  };

  const onDownloadLastReport = (report: any) => {
    downloadLastReport(
      propertyId,
      organizationId,
      report,
      actions.promptToaster,
    );
  };

  const onCreateReportClickCurried = curryN(3, showCreateReportModal);
  const onViewHistoryClickCurried = curryN(3, showHistoryModal);
  const onDownloadReportClickCurried = curryN(2, onDownloadLastReport);

  return (
    <DocumentTitle title={intl.formatMessage(messages.title)}>
      <ReportsTable
        intl={intl}
        columnOrder={columnOrder}
        reports={reports}
        periods={getPeriodOptions(periods)}
        history={history}
        handleOrderClick={handleOrderClick}
        getOrder={getOrder}
        handleSubmit={handleSubmit}
        onCreateReport={onCreateReportClickCurried}
        onViewHistory={onViewHistoryClickCurried}
        downloadLastReport={onDownloadReportClickCurried}
      />
    </DocumentTitle>
  );
};

export const mapStateToProps = (state: GlobalState) => {
  const property = state?.app?.selectedProperty ?? {};
  const { manageReports } = state;
  return {
    property,
    currentSorting: manageReports.currentSorting,
    columnOrder: manageReports.columnOrder,
  };
};
ManageReportPage.contextTypes = {
  store: PropTypes.any,
};

export function mapDispatchToProps(dispatch: Dispatch<any>) {
  return {
    actions: bindActionCreators({ promptToaster }, dispatch),
  };
}

const InjectedManageReportsPage = injectIntl(ManageReportPage);
const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedManageReportsPage);

export default withLDConsumer()(connected);
