import { useState, useMemo } from 'react';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  SearchBar,
  Select,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from '../messages';
import * as constants from '../constants';
import FilterIconButton from '../../../components/FilterIconButton';

type Props = {
  formatMessage: Function,
  reviewCount: number,
  avgRating: number,
  onSearch: (searchValue: string) => void,
  selectedSortingOption: 'most-recent' | 'oldest' | 'highest' | 'lowest',
  onSortingOptionChange: (sortingValue: string) => void,
  showSpinner: boolean,
  isFilterActive: boolean,
  onFilterToggle: (t: boolean) => void,
  recommendedPercent: number,
  ratingType: string,
};

const SORTING_OPTIONS = [
  {
    text: 'Most Recent',
    value: 'most-recent',
  },
  {
    text: 'Oldest',
    value: 'oldest',
  },
  {
    text: 'Highest',
    value: 'highest',
  },
  {
    text: 'Lowest',
    value: 'lowest',
  },
];

export const ReviewsCardHeader = ({
  formatMessage,
  reviewCount,
  avgRating,
  onSortingOptionChange,
  selectedSortingOption,
  showSpinner,
  onSearch,
  isFilterActive,
  onFilterToggle,
  recommendedPercent,
  ratingType,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const handleSearchValueChange = (e) => setSearchValue(e.target.value);
  const handleSortingChange = (e) => {
    onSortingOptionChange(e.target.value);
  };
  const ratingText = useMemo(() => {
    if (ratingType === constants.STAR_BASED)
      return `${formatMessage(messages.averageRatingOf)} ${avgRating?.toFixed(
        constants.RATING_FRACTION_DIGITS,
      )}`;

    if (ratingType === constants.RECOMMEND_BASED)
      return `${recommendedPercent}% ${formatMessage(messages.recommend)}`;
    return '';
  }, [ratingType, avgRating, recommendedPercent, formatMessage]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      marginBottom={3}
    >
      <Grid item>
        <Stack>
          <Typography variant="h1">
            {formatMessage(messages.reviewsSectionTitle)}
          </Typography>
          <Typography>
            {`${reviewCount} ${formatMessage(
              messages.reviews,
            )} | ${ratingText}`}
          </Typography>
        </Stack>
      </Grid>
      <Grid item>
        <Stack direction="row" alignItems="center" spacing={1}>
          <FilterIconButton
            className="pull-right"
            active={isFilterActive}
            onClick={() => onFilterToggle(!isFilterActive)}
            disabled={showSpinner}
          />
          <SearchBar
            value={searchValue}
            onChange={handleSearchValueChange}
            onSearch={() => onSearch(searchValue)}
            disabled={showSpinner}
          />
          <Select
            options={SORTING_OPTIONS}
            value={selectedSortingOption}
            onChange={handleSortingChange}
            disabled={showSpinner}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};
