import React, { Component } from 'react';
import messages from '../CommunicationTable/messages';
import type { Communication } from '../types';
import { pathOr } from 'ramda';
import FormattedDateOrDashes from '../../../components/FormattedDateOrDashes';

import cn from 'classnames';
type Props = {
  intl: any,
  communications: Array<Communication>,
  history: any,
  handleViewCommunicationClick: Function,
  isAllCommercial: boolean,
};

type State = {
  toggles: Object,
};

class CommunicationsDetails extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      toggles: {},
    };
  }

  renderCommunicationDetailRow = (
    communication: Communication,
    className: string,
    key: number,
  ) => {
    const id = key || '';
    // $FlowFixMe
    const method = pathOr('---', ['communicationType'], communication);
    // $FlowFixMe
    const typeValue = pathOr('---', ['customerStatus'], communication);
    // $FlowFixMe
    const title = pathOr('---', ['title'], communication);
    const type =
      this.props.isAllCommercial && typeValue === 'Current Resident'
        ? 'Current Tenant'
        : typeValue;

    // $FlowFixMe
    const created = pathOr('---', ['createdAt'], communication);
    // $FlowFixMe
    const status = pathOr('---', ['status'], communication);
    // $FlowFixMe
    const headerId = pathOr(null, ['headerId'], communication);
    const tag = `tag status-tag ${status.color}`;
    return (
      <tr key={`${id}-RW`} className={className}>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.method)}:
          </span>
          {method}
        </td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.type)}:
          </span>
          {type}
        </td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.createdDate)}:
          </span>
          <FormattedDateOrDashes value={created} format="MM/DD/YYYY" />
        </td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.createdDate)}:
          </span>
          <FormattedDateOrDashes value={created} format="LT" />
        </td>
        <td>{title}</td>
        {status.name !== 'SCHEDULED' ? (
          <td>
            <button
              className={cn('btn btn-text btn-text--small text-right')}
              onClick={() =>
                this.props.handleViewCommunicationClick(headerId, status.name)
              }
              disabled={status.name === 'IN PROGRESS'}
            >
              {status.name === 'IN PROGRESS' ? 'Processing' : 'View'}
            </button>
          </td>
        ) : (
          <td />
        )}
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.status)}:
          </span>
          <span className={tag}>{status.name}</span>
        </td>
      </tr>
    );
  };

  render() {
    return (
      <tbody>
        {this.props.communications.map((communication: any, index: number) => {
          return this.renderCommunicationDetailRow(
            communication,
            'table-section hidden-xs',
            index,
          );
        })}
      </tbody>
    );
  }
}

export default CommunicationsDetails;
