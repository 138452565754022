import { FormattedMessage } from 'react-intl';
import messages from '../messages';

export const HEADERS = ({ showTextingColumn, showAllStatuses }) => [
  {
    id: 'status',
    label: <FormattedMessage {...messages.status} />,
    dataType: 'string',
    sortable: true,
    searchable: true,
    multiselect: true,
  },
  {
    id: 'prospectName',
    label: <FormattedMessage {...messages.prospectName} />,
    dataType: 'string',
    sortable: true,
    searchable: true,
  },
  {
    id: 'phoneNumber',
    label: <FormattedMessage {...messages.phoneNumber} />,
    dataType: 'string',
    sortable: true,
    searchable: true,
  },
  ...(showTextingColumn
    ? [
        {
          id: 'texting',
          label: <FormattedMessage {...messages.texting} />,
          sortable: true,
          searchable: false,
          dataType: 'number',
          multiselect: true,
          filterOptions: [
            { text: 'New', value: 'new' },
            { text: 'Unresolved', value: 'unresolved' },
          ],
        },
      ]
    : []),
  {
    id: 'email',
    label: <FormattedMessage {...messages.email} />,
    dataType: 'string',
    sortable: true,
    searchable: true,
  },
  {
    id: 'prospectCreated',
    label: <FormattedMessage {...messages.creationDate} />,
    dataType: 'date',
    sortable: true,
    searchable: true,
  },
  {
    id: 'lastActivity',
    label: <FormattedMessage {...messages.lastFollowUp} />,
    dataType: 'date',
    sortable: true,
    searchable: true,
  },
  {
    id: 'nextActivity',
    label: <FormattedMessage {...messages.nextFollowUp} />,
    dataType: 'date',
    sortable: true,
    searchable: true,
  },
  {
    id: 'moveInDate',
    label: <FormattedMessage {...messages.moveInDate} />,
    dataType: 'date',
    sortable: true,
    searchable: true,
  },
  {
    id: 'nBeds',
    label: <FormattedMessage {...messages.nBeds} />,
    dataType: 'string',
    sortable: true,
    searchable: true,
    multiselect: true,
  },
  {
    id: 'assignedTo',
    label: <FormattedMessage {...messages.assignedTo} />,
    dataType: 'string',
    sortable: true,
    searchable: true,
  },
  {
    id: 'actions',
    label: <FormattedMessage {...messages.actions} />,
    dataType: 'string',
    sortable: false,
    searchable: false,
  },
];

export const PROPERTY_PATH_MAP = {
  status: {
    path: 'currentProspectStatus.name',
    dataType: 'string',
    filterPath: 'currentProspectStatus',
    filterFn: (selectedStatusIds: string[], status: Object) => {
      return selectedStatusIds.includes(status.id);
    },
  },
  prospectName: {
    path: 'prospectName',
    dataType: 'string',
  },
  phoneNumber: {
    path: 'phoneNumber',
    dataType: 'string',
  },
  texting: {
    path: 'texting.TOTAL',
    dataType: 'number',
    filterPath: 'texting',
    filterFn: (filterByArr: string[], filterValue: any) => {
      if (filterByArr.includes('new') && filterValue.NEW > 0) {
        return true;
      }
      if (filterByArr.includes('unresolved') && filterValue.UNRESOLVED > 0) {
        return true;
      }
      return false;
    },
  },
  email: {
    path: 'emailAddress',
    dataType: 'string',
  },
  prospectCreated: {
    path: 'createdAt',
    dataType: 'date',
  },
  lastActivity: {
    path: 'lastActivity.startTime',
    dataType: 'date',
  },
  nextActivity: {
    path: 'nextActivity.startTime',
    dataType: 'date',
  },
  moveInDate: {
    path: 'prospectPreferences.moveInDateFrom',
    dataType: 'date',
  },
  nBeds: {
    path: 'nBedsFormatted',
    dataType: 'string',
    filterPath: 'nBedsFormatted',
    filterFn: (selectedNBeds: string[], nBeds: string) => {
      return selectedNBeds.includes(nBeds);
    },
  },
  assignedTo: {
    path: 'assignedToName',
    dataType: 'string',
  },
};
