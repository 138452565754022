import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { Row, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import PetRows from './PetRows';

import generateNumberOfOptions from '../../../../utils/index.js';
import { renderSelectField } from '../../../../utils/redux-form-helper';

type ComponentProps = {
  intl: any,
  petTypes: Array<Object>,
  petBreeds: Array<Object>,
  pets: Array<Object>
};

const selectOptions = generateNumberOfOptions(10);

const Pets = ({ intl, petTypes, petBreeds, pets }: ComponentProps) => (
  <FormGroup className="applicant-subgrp">
    <Row>
      <div className="additional-occupants-head">
        <h3>
          <FormattedMessage {...messages.other} />
        </h3>
        <div className="head-subtitle">
          <FormattedMessage {...messages.petsHowMany} />
        </div>
        <div className="head-selector">
          <Field
            name="petsCount"
            component={renderSelectField}
            options={selectOptions}
            selectClassNames="input-lg"
          />
        </div>
      </div>
    </Row>
    <FieldArray
      name="pets"
      component={PetRows}
      intl={intl}
      petTypes={petTypes}
      petBreeds={petBreeds}
      pets={pets}
    />
  </FormGroup>
);

export default Pets;
