const { get } = require('../utils/api');

export default class IntacctGLAccountsService {
  getAll(organizationId: string, propertyId: string, signal?: Object) {
    const options = signal ? { signal } : {};
    return get(`/${organizationId}/${propertyId}/intacct/accounts`, options);
  }

  getByGroupId(
    organizationId: string,
    propertyId: string,
    intacctGLAccountGroupId: string,
    signal?: Object,
  ) {
    const options = signal ? { signal } : {};
    return get(
      `/${organizationId}/${propertyId}/intacct/account-groups/${intacctGLAccountGroupId}/accounts`,
      options,
    );
  }
}
