import { put, takeLatest, select, throttle } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { omit } from 'ramda';
import {
  getRoleSuccess,
  showRolePermitsAssignees,
  editRoleSuccess,
  editRoleFail,
} from './actions';
import { resetCreateRoleForm } from '../CreateRole/actions';
import RoleService from '../../services/roleService';
import { GET_ROLE, EDIT_ROLE } from './constants';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import { selectCurrentUserOrganizationId } from '../App/selectors';
import messages from './messages';
import type { Saga } from 'redux-saga';

export function* fetchRole(action: Object): Saga<void> {
  try {
    const roleService = new RoleService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const role = yield roleService.getRole(action.roleId, organizationId);
    yield put(getRoleSuccess(role));
    yield put(showRolePermitsAssignees(role.userRoleType.canBeAssigned));
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getRoleSaga(): Saga<void> {
  yield takeLatest(GET_ROLE, fetchRole);
}

export function* postEditRole(action: Object): Saga<void> {
  try {
    // $FlowFixMe
    const rolePayload = omit(
      ['shouldRedirect', 'createdAt', 'updatedAt'],
      action.payload.role,
    );
    const roleService = new RoleService();
    yield roleService.editRole(action.payload.roleId, rolePayload);
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(editRoleSuccess());
    if (action.payload.role.shouldRedirect) {
      yield put(push('/manage-roles'));
    } else {
      yield put(push('/add-role'));
      yield put(resetCreateRoleForm());
    }
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(editRoleFail(err));
  }
}

export function* editRoleSaga(): Saga<void> {
  yield throttle(500, EDIT_ROLE, postEditRole);
}

export default [editRoleSaga, getRoleSaga];
