import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import WorkOrderService from '../../services/workOrderService';
import {
  useTableFilterSortSearchManager,
  useTableFilterSortData,
  useTableManageColumns,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import {
  formatDateDB,
  formatPhoneNumber,
} from '@fortress-technology-solutions/fortress-component-library/utils/index';
import useUniqueTableName from '../../hooks/useUniqueTableName';
import { PROPERTY_PATH_MAP, HEADERS } from './constants';
import {
  appendFilterTextToCSV,
  processDataToCSV,
} from '../../utils/csv-helpers';
import { download } from '../../utils/downloadFile';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const useAllWorkOrders = () => {
  const name = useUniqueTableName('centralized-work-orders');
  const { data, isLoading } = useFetchAllWorkOrders();
  const results = useMemo(() => data?.results ?? [], [data?.results]);

  const { assigneeOptions, propertyOptions, buildingNumberOptions } =
    useGetFilterOptions({
      results,
    });

  const headers = useHeaders({
    assigneeOptions,
    propertyOptions,
    buildingNumberOptions,
  });

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    headers,
    initialOrderBy: 'created',
    initialOrder: 'DESC',
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP,
  });

  const rows = useRows({
    sortedAndFilteredResults,
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  const { onCSVButtonClick } = useCSVPDFExport({
    propertyName: name,
    hasAnyFilters:
      Object.keys(filterState)?.length ||
      Object.keys(dateState)?.length ||
      Object.keys(searchState)?.length,
    filteredHeaders,
    rows,
    results: sortedAndFilteredResults,
  });

  return {
    allColumnsHidden,
    columnOptions,
    count: rows?.length ?? 0,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    headers,
    isLoading,
    order,
    orderBy,
    name,
    rows,
    searchState,
    selectedColumns,
    onCSVButtonClick,
    totalCount: results?.length ?? 0,
  };
};
export const useFetchAllWorkOrders = () => {
  const workOrderService = useMemo(() => new WorkOrderService(), []);
  const abortController = new AbortController();
  const queryKey = [];
  const options = { signal: abortController.signal };
  return useQuery(
    ['AllWorkOrders', queryKey],
    () =>
      workOrderService.getWorkOrdersByOrganization({
        options,
      }),
    { enabled: true, refetchOnWindowFocus: false },
  );
};

const useGetFilterOptions = ({ results }) => {
  return useMemo(() => {
    const options = {
      assigneeOptions: [],
      propertyOptions: [],
      buildingNumberOptions: [],
    };

    if (results) {
      results.forEach(({ assignee, property, buildingNumber }) => {
        if (assignee?.length) options.assigneeOptions.push(assignee);
        if (property?.name?.length) options.propertyOptions.push(property.name);
        if (buildingNumber?.length)
          options.buildingNumberOptions.push(buildingNumber);
      });

      options.assigneeOptions = Array.from(new Set(options.assigneeOptions))
        .sort()
        .map((assignee) => ({
          text: assignee,
          value: assignee?.toLowerCase(),
        }));

      options.propertyOptions = Array.from(new Set(options.propertyOptions))
        .sort()
        .map((propertyName) => ({
          text: propertyName,
          value: propertyName?.toLowerCase(),
        }));

      options.buildingNumberOptions = Array.from(
        new Set(options.buildingNumberOptions),
      )
        .sort()
        .map((buildingNumber) => ({
          text: buildingNumber,
          value: buildingNumber?.toLowerCase(),
        }));
    }

    return options;
  }, [results]);
};
const useHeaders = ({
  assigneeOptions,
  propertyOptions,
  buildingNumberOptions,
}) => {
  const { manageWorkOrdersBuildingNumber = false } = useFlags();

  return useMemo(() => {
    return HEADERS.filter(
      (header) =>
        header.id !== 'buildingNumber' || manageWorkOrdersBuildingNumber,
    ).map((header) => {
      if (header.id === 'buildingNumber')
        header.filterOptions = buildingNumberOptions;
      if (header.id === 'assignee') header.filterOptions = assigneeOptions;
      if (header.id === 'property') header.filterOptions = propertyOptions;
      return header;
    });
  }, [
    assigneeOptions,
    propertyOptions,
    buildingNumberOptions,
    manageWorkOrdersBuildingNumber,
  ]);
};
export const useRows = ({ sortedAndFilteredResults }) => {
  return useMemo(
    () =>
      sortedAndFilteredResults?.map((row) => {
        const {
          assignee,
          daysOpen,
          detailId,
          property,
          propertyId,
          dueDate,
          createdAt,
          location,
          buildingNumber,
          priorityLevelDesc,
          statusDesc,
          description,
          requestorName,
          requestorPhone,
        } = row;

        return {
          property: {
            value: property?.name,
          },
          status: {
            variant: 'status',
            value: statusDesc ?? '',
          },
          location: {
            value: location,
          },
          buildingNumber: {
            variant: buildingNumber?.length > 10 ? 'description' : 'string',
            value: buildingNumber,
          },
          issueDescription: {
            variant: 'link',
            to: `/property/${propertyId}/edit-work-order/${detailId}`,
            target: '_blank',
            value: description,
            tooltip: true,
          },
          assignee: {
            value: assignee,
          },
          priority: { variant: 'priority', value: priorityLevelDesc ?? '' },
          created: {
            variant: 'dateTime',
            value: createdAt ?? '',
          },
          daysOpen: {
            variant: 'number',
            value: daysOpen,
          },
          dueDate: {
            variant: 'date',
            value: dueDate,
          },
          requestor: {
            value: [requestorName, formatPhoneNumber(requestorPhone)],
          },
        };
      }),
    [sortedAndFilteredResults],
  );
};
const useCSVPDFExport = ({
  filteredHeaders,
  rows,
  propertyName,
  hasAnyFilters,
}) => {
  const onCSVButtonClick = useCallback(() => {
    const csvHeaders = [];
    for (const { label } of filteredHeaders) {
      if (label === 'Actions') continue;
      csvHeaders.push(
        typeof label === 'string' ? label : label.props?.defaultMessage,
      );
    }

    const csvRows = processDataToCSV({ rows, filteredHeaders });

    const csv = appendFilterTextToCSV({
      headers: csvHeaders.join(','),
      rows: csvRows,
      hasAnyFilters,
    });

    download(
      csv,
      `AllWorkOrders_${propertyName?.split(' ').join('-')}_${formatDateDB(
        new Date(),
      )}.csv`,
      'text/csv;charset=utf-8',
    );
  }, [filteredHeaders, rows, hasAnyFilters, propertyName]);

  return { onCSVButtonClick };
};
