import classNames from 'classnames';
import React, { Component } from 'react';
import { type Row as Props } from '../types';

export default class Row extends Component<Props> {
  render() {
    const { className, children, error, ...props } = this.props;
    const rowClass = classNames(className, { 'highlight text-red': error });
    return (
      <tr className={rowClass} {...props}>
        {children}
      </tr>
    );
  }
}
