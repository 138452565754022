import { call, put, takeLatest, select, throttle } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { push } from 'react-router-redux';
import { initialize, change } from 'redux-form';
import type { Saga } from 'redux-saga';
import { equals, omit, pick, propEq } from 'ramda';
import * as actions from './actions';
import * as actionTypes from './constants';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
} from '../App/selectors';
import * as selectors from './selectors';
import * as appSelectors from '../App/selectors';
import ResidentService from '../../services/residentService';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

function* getResidentHousehold(action: {
  payload: { residentId: string },
}): Saga<void> {
  try {
    const service = new ResidentService();
    const orgId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);

    if (!orgId || !property) throw new Error('A property must be selected.');

    const household = yield call(
      service.getResidentHousehold,
      action.payload.residentId,
      orgId,
      property.id,
    );

    yield put(actions.getResidentHouseholdSuccess(household));

    const adults = yield select(selectors.getAdultsFormValues);
    const minors = yield select(selectors.getMinorsFormValues);
    const pets = yield select(selectors.getPetsFormValues);
    const primaryResident = adults.find((adult) => adult.isPrimary);

    yield put(
      initialize('editResidentHousehold', {
        adults,
        minors,
        pets,
        primaryResidentId: primaryResident.id,
      }),
    );
  } catch (error) {
    yield put(actions.getResidentHouseholdError(error.message || error));
    yield put(
      toastrActions.add({
        type: 'error',
        message: error.message || 'Failed to load household.',
        title: 'Error',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}
function* getAllApplicantPendingPets(action: {
  payload: { residentId: string },
}): Saga<void> {
  try {
    const service = new ResidentService();
    const orgId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);

    if (!orgId || !property) throw new Error('A property must be selected.');

    const household = yield call(
      service.getPendingApplicants,
      action.payload.residentId,
      orgId,
      property.id,
    );

    yield put(actions.getPendingApplicantsSuccess(household));
    const pets = yield select(selectors.getPendingPetsFormValues);
    yield put(change('editResidentHousehold', 'pendingPets', pets));
  } catch (error) {
    yield put(actions.getResidentHouseholdError(error.message || error));
    yield put(
      toastrActions.add({
        type: 'error',
        message: error.message || 'Failed to load household.',
        title: 'Error',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}
function* waitForGetResidentHousehold(): Saga<void> {
  yield takeLatest(actionTypes.GET_RESIDENT_HOUSEHOLD, getResidentHousehold);
}

function* editResidentHousehold(action: any): Saga<void> {
  try {
    const service = new ResidentService();
    const orgId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);

    if (!orgId || !property) throw new Error('A property must be selected.');

    const loadedAdults = yield select(selectors.getAdults);
    const loadedMinors = yield select(selectors.getMinors);
    const loadedPets = yield select(selectors.getPets);

    const omitId = omit(['id']);
    const removeDefaults = (minorData: any) => {
      return Object.keys(minorData).reduce((updatesObj, key) => {
        const currentValue = minorData[key];
        if ([undefined, 'default', ''].includes(currentValue)) {
          return { ...updatesObj, [key]: null };
        }
        return { ...updatesObj, [key]: minorData[key] };
      }, {});
    };

    const {
      values: { adults, minors, pets },
    } = action.payload;

    const newMinors = minors.filter(propEq('id', undefined));
    const newPets = pets.filter(propEq('id', undefined));
    const newAdults = adults.filter(propEq('id', undefined));

    const applicationType = select(appSelectors.getSelectedPropertyClassType);
    const isAffordableApplication = select(selectors.isAffordableApplication);
    const isAffordable =
      applicationType === 'Affordable' ||
      (applicationType === 'Mixed' && isAffordableApplication);
    const updateAdults = loadedAdults.reduce((updateArray, la) => {
      const loadedAdultValues = {
        id: la.id,
        isPrimary: la.rc.isPrimary,
        relationshipId: la.relationshipId ?? undefined,
        affordableRelationshipId: la.applicant.affordableRelationshipId,
        type: la.applicant.applicantTypeId,
        ...pick(
          [
            'emailAddress',
            'firstName',
            'lastName',
            'middleName',
            'phoneNumber',
            'preferredName',
            'suffix',
          ],
          la.rc.customer,
        ),
        suffix: la.rc.customer.suffixId,
      };

      if (!adults.find((a) => la.id === a.id)) {
        return [
          ...updateArray,
          {
            type: 'adult',
            id: la.id,
            remove: true,
            data: omitId(loadedAdultValues),
          },
        ];
      }

      const adultFormValues = adults.find(({ id }) => id === la.id);
      const scrubbedLoaded = removeDefaults(loadedAdultValues);
      const scrubbedForm = removeDefaults(adultFormValues);
      if (equals(scrubbedLoaded, scrubbedForm)) {
        return updateArray;
      }

      const changedFields = Object.keys(scrubbedLoaded).reduce((array, key) => {
        return scrubbedLoaded[key] !== scrubbedForm[key]
          ? [...array, key]
          : array;
      }, []);

      // If not affordable, remove affordable relationship from changedFields,
      if (!isAffordable) {
        if (changedFields.includes('affordableRelationshipId')) {
          changedFields.splice(
            changedFields.indexOf('affordableRelationshipId'),
            1,
          );
        }
      }

      if (changedFields.includes('isPrimary')) {
        if (scrubbedForm.isPrimary) {
          adultFormValues.previousHOH = loadedAdults.find(
            (la) => la.rc.isPrimary === true,
          );
        }
      }

      const adultUpdates = {
        type: 'adult',
        id: la.id,
        changedFields: changedFields,
        data: {
          ...omitId(adultFormValues),
        },
      };
      return [...updateArray, adultUpdates];
    }, []);

    const updateMinors = loadedMinors.reduce((editArray, lm) => {
      const loadedMinorValues = {
        id: lm.id,
        ...pick(
          [
            'firstName',
            'middleName',
            'lastName',
            'preferredName',
            'suffixId',
            'age',
          ],
          lm.residentMinor,
        ),
        relationshipId: lm.relationshipId,
        affordableRelationshipId: lm.applicant.affordableRelationshipId,
      };

      if (!minors.find((m) => lm.id === m.id)) {
        return [
          ...editArray,
          {
            type: 'minor',
            id: lm.id,
            remove: true,
            data: omitId(loadedMinorValues),
          },
        ];
      }
      const minorFormValues = minors.find(({ id }) => id === lm.id);
      const scrubbedLoaded = removeDefaults(loadedMinorValues);
      const scrubbedForm = removeDefaults(minorFormValues);
      if (equals(scrubbedLoaded, scrubbedForm)) {
        return editArray;
      }
      const changedFields = Object.keys(scrubbedLoaded).reduce((array, key) => {
        return scrubbedLoaded[key] !== scrubbedForm[key]
          ? [...array, key]
          : array;
      }, []);

      // If not affordable, remove affordable relationship from changedFields,
      if (!isAffordable) {
        if (changedFields.includes('affordableRelationshipId')) {
          changedFields.splice(
            changedFields.indexOf('affordableRelationshipId'),
            1,
          );
        }
      }

      const minorEdits = {
        type: 'minor',
        id: lm.id,
        changedFields: changedFields,
        data: {
          ...removeDefaults(omitId(minorFormValues)),
          residentMinorId: lm.residentMinor.id,
        },
      };
      return [...editArray, minorEdits];
    }, []);

    const deletedPets = loadedPets.filter(
      (p) => !pets.find((fp) => fp.id === p.id),
    );

    const editPayload = [
      ...newAdults.map((a) => ({
        type: 'adult',
        id: undefined,
        data: omitId(a),
      })),
      ...updateAdults,
      ...newMinors.map((m) => ({
        type: 'minor',
        data: removeDefaults(omitId(m)),
      })),
      ...updateMinors,
      ...newPets.map((p) => ({ type: 'pet', data: omitId(p) })),
      ...deletedPets.map((p) => ({
        type: 'pet',
        id: p.id,
        remove: true,
        data: omitId(p).residentPet,
      })),
    ];

    const edits = yield call(
      service.editResidentHousehold,
      action.payload.residentId,
      editPayload,
      orgId,
      property.id,
    );

    yield put(actions.editResidentHouseholdSuccess(edits));
    yield put(
      toastrActions.add({
        type: 'success',
        message: 'Household updated successfully.',
        title: 'Success',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
    yield put(
      push(
        getUrlWithSelectedPropertyId(`/resident/${action.payload.residentId}`),
      ),
    );
  } catch (error) {
    yield put(actions.editResidentHouseholdError(error));
    yield put(
      toastrActions.add({
        type: 'error',
        message: error,
        title: 'Error',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}
function* updatePendingApplicants(action: any): Saga<void> {
  try {
    const service = new ResidentService();
    const orgId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);

    if (!orgId || !property) throw new Error('A property must be selected.');

    const loadedPets = yield select(selectors.getPendingApplicantPets);

    const {
      values: { pendingPets },
    } = action.payload;

    const deletedPets = loadedPets.filter(
      (p) => !pendingPets.find((fp) => fp.id === p.id),
    );

    const editPayload = [
      ...pendingPets.map((pet) => ({ type: 'pet', data: pet })),
      ...deletedPets.map((p) => ({ type: 'pet', id: p.id, remove: true })),
    ];

    yield call(
      service.editPendingApplicants,
      action.payload.residentId,
      editPayload,
      orgId,
      property.id,
    );
  } catch (error) {
    yield put(actions.editResidentHouseholdError(error));
    yield put(
      toastrActions.add({
        type: 'error',
        message: error,
        title: 'Error',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

function* getLatestHouseholdHistory(action: {
  payload: { residentId: string },
}): Saga<void> {
  try {
    const service = new ResidentService();
    const orgId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);

    if (!orgId || !property) throw new Error('A property must be selected.');

    const householdHistory = yield call(
      service.getLatestHouseholdHistory,
      action.payload.residentId,
      orgId,
      property.id,
    );

    yield put(actions.getLatestHouseholdHistorySuccess(householdHistory));
  } catch (error) {
    yield put(actions.getLatestHouseholdHistoryError(error.message || error));
    yield put(
      toastrActions.add({
        type: 'error',
        message: error.message || 'Failed to load household history.',
        title: 'Error',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

function* getHouseholdLeases({ payload }: Object): Saga<void> {
  try {
    const residentService = new ResidentService();
    const orgId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);

    if (!orgId || !property) throw new Error('A property must be selected.');

    const leases = yield residentService.getHouseholdLeasesByResidentId(
      orgId,
      property.id,
      payload,
    );

    yield put(actions.getHouseholdLeasesByResidentIdSuccess(leases));
  } catch (error) {
    yield put(
      actions.getHouseholdLeasesByResidentIdError(error.message || error),
    );
    yield put(
      toastrActions.add({
        type: 'error',
        message: error.message || 'Failed to load household leases.',
        title: 'Error',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

function* waitForEditResidentHousehold(): Saga<void> {
  yield throttle(
    500,
    actionTypes.EDIT_RESIDENT_HOUSEHOLD,
    editResidentHousehold,
  );
}

function* getAllApplicantPendingPetsSaga(): Saga<void> {
  yield takeLatest(
    actionTypes.GET_ALL_PENDING_APPLICANTS,
    getAllApplicantPendingPets,
  );
}

function* waitForEditPendingApplicants(): Saga<void> {
  yield takeLatest(
    actionTypes.EDIT_PENDING_APPLICANTS,
    updatePendingApplicants,
  );
}

function* getLatestHouseholdHistorySaga(): Saga<void> {
  yield takeLatest(
    actionTypes.GET_LATEST_HOUSEHOLD_HISTORY,
    getLatestHouseholdHistory,
  );
}

function* getHouseholdLeasesSaga(): Saga<void> {
  yield takeLatest(
    actionTypes.GET_HOUSEHOLD_LEASES_BY_RESIDENT_ID,
    getHouseholdLeases,
  );
}

function* getHouseholdHasOpenCerts(action: {
  payload: { residentId: string },
}): Saga<void> {
  try {
    const service = new ResidentService();
    const orgId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);

    if (!orgId || !property) throw new Error('A property must be selected.');

    const hasOpenCerts = yield call(
      service.getHouseholdHasOpenCerts,
      orgId,
      property.id,
      action.payload.residentId,
    );

    yield put(actions.getHouseholdHasOpenCertsSuccess(hasOpenCerts));
  } catch (error) {
    yield put(actions.getHouseholdHasOpenCertsError(error.message || error));
    yield put(
      toastrActions.add({
        type: 'error',
        message:
          error.message ||
          "Failed to check if resident's household has open certs.",
        title: 'Error',
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}
function* waitForGetHouseholdHasOpenCerts(): Saga<void> {
  yield takeLatest(
    actionTypes.GET_HOUSEHOLD_HAS_OPEN_CERTS,
    getHouseholdHasOpenCerts,
  );
}

export default [
  waitForGetResidentHousehold,
  waitForEditResidentHousehold,
  getAllApplicantPendingPetsSaga,
  waitForEditPendingApplicants,
  getLatestHouseholdHistorySaga,
  getHouseholdLeasesSaga,
  waitForGetHouseholdHasOpenCerts,
];
