import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ResetPassword.Title',
    defaultMessage: 'Fortress - Reset password'
  },
  header: {
    id: 'App.ResetPassword.Header',
    defaultMessage: 'Enter a New Password'
  },
  newPassword: {
    id: 'App.ResetPassword.NewPassword',
    defaultMessage: 'New Password'
  },
  confirmPassword: {
    id: 'App.ResetPassword.ConfirmPassword',
    defaultMessage: 'Confirm Password'
  },
  passwordMustInclude: {
    id: 'App.ResetPassword.passwordMustInclude',
    defaultMessage: 'Your password must include:'
  },
  minTenCharacters: {
    id: 'App.ResetPassword.minTenCharacters',
    defaultMessage: 'A minimum of 10 characters'
  },
  minOneUppercase: {
    id: 'App.ResetPassword.minOneUppercase',
    defaultMessage: 'At least 1 uppercase'
  },
  minOneLowercase: {
    id: 'App.ResetPassword.minOneLowercase',
    defaultMessage: 'At least 1 lowercase'
  },
  minOneSymbol: {
    id: 'App.ResetPassword.minOneSymbol',
    defaultMessage: 'At least 1 symbol'
  },
  minOneNumber: {
    id: 'App.ResetPassword.minOneNumber',
    defaultMessage: 'At least 1 number'
  },
  continue: {
    id: 'App.ResetPassword.continue',
    defaultMessage: 'Continue'
  },
  goToSignIn: {
    id: 'App.ResetPassword.goToSignIn',
    defaultMessage: 'Go to Sign In'
  },
  passwordUpdateTitle: {
    id: 'App.ResetPassword.passwordUpdateTitle',
    defaultMessage: 'Password update succesful!'
  },
  passwordUpdateMessage: {
    id: 'App.ResetPassword.passwordUpdateMessage',
    defaultMessage:
      'Your password has been updated! You may now use your new password to log in.'
  },
  help: {
    id: 'App.ResetPassword.help',
    defaultMessage:
      'Having trouble signing in? Please contact your system administrator for assistance.'
  },
  failureTitle: {
    id: 'App.ResetPassword.FailureTitle',
    defaultMessage: 'Reset Password Failed'
  },
  successTitle: {
    id: 'App.ResetPassword.SuccessTitle',
    defaultMessage: 'Success'
  },
  successMessage: {
    id: 'App.ResetPassword.SuccessMessage',
    defaultMessage: 'Password Reset'
  }
});

export default messages;
