const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class ApplicantTypeService {
  getAll() {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(`${apiURL}/applicant-cancellation-reasons`, options);
  }
}

export default ApplicantTypeService;
