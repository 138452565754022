import React, { useState, useMemo } from 'react';
import { Checkbox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { DriveIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { HouseholdSection } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import {
  TableCell,
  TableRow,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from './messages';
import usePermissionsHook from '../../components/ElementWithPermissions/usePermissionsHook';
import HouseholdVehicles from '../../containers/HouseholdVehicles';
import { useAsyncHouseholdVehicles } from './hooks';
import { pick } from 'ramda';
import moment from 'moment';

const parseDisplayText = ({ make, model, type }) => {
  let displayText;
  if (make && model) {
    displayText = `${make.name} - ${model.name}`;
  } else if (make) {
    displayText = make.name;
  } else {
    displayText = type.name;
  }
  return displayText;
};

const Vehicles = ({
  intl,
  applicationId,
  residentId,
  handleNoVehiclesChange,
  vehicleCount,
  selectedProperty,
  householdId,
}) => {
  const vehiclePermissions = residentId
    ? ['resident-edit']
    : ['application-edit', 'prospect-update'];

  const [householdVehicles, updateHousholdVehicles] = useAsyncHouseholdVehicles(
    selectedProperty,
    householdId,
  );

  const currentVehicles =
    householdVehicles &&
    householdVehicles.filter(({ deletedAt }) => !deletedAt);

  // const hasNoVehicles = residentId
  //   ? householdVehicles && householdVehicles.length === 0
  //   : householdVehicles && currentVehicles.length === 0;

  const noVehiclesHandler =
    !residentId &&
    handleNoVehiclesChange &&
    handleNoVehiclesChange(vehicleCount);

  const priorVehicles =
    householdVehicles &&
    householdVehicles.filter((householdVehicle) => {
      return (
        householdVehicle.deletedAt &&
        householdVehicle.householdStatusWhenDeleted !== 'Applicant'
      );
    });

  const [vehicleModal, setVehicleModal] = useState(false);
  const [vehicleToEdit, setVehicleToEdit] = useState({});
  const resetVehicleModal = () => setVehicleModal(false);
  const handleAddVehicle = () => {
    setVehicleToEdit({});
    setVehicleModal(true);
  };
  const handleEditVehicle = (vehicle) => () => {
    const vehicleToEditInitialValues = pick(
      [
        'id',
        'vehicleTypeId',
        'year',
        'vehicleMakeId',
        'vehicleModelId',
        'vehicleColorId',
        'licensePlateNumber',
        'licensePlateStateId',
        'decalTagNumber',
        'parkingSpace',
        'registrationExpirationDate',
        'insuranceInformation',
      ],
      vehicle,
    );
    setVehicleToEdit({
      ...vehicleToEditInitialValues,
      registrationExpirationDate: moment(
        vehicleToEditInitialValues.registrationExpirationDate,
      ),
    });
    setVehicleModal(true);
  };
  const hasVehiclePermissions = usePermissionsHook({
    scope: vehiclePermissions,
  });

  const rows = useMemo(() => {
    let parsedRows = [];
    if (currentVehicles?.length) {
      const parsedCurrentVehicles = currentVehicles
        .filter(({ type }) => !!type)
        .map((vehicle) => ({ ...vehicle, isCurrent: true }));
      parsedRows.push(...parsedCurrentVehicles);
    }

    if (residentId && priorVehicles?.length) {
      const parsedPriorVehicles = priorVehicles.filter(({ type }) => !!type);
      parsedRows.push(...parsedPriorVehicles);
    }

    return parsedRows;
  }, [currentVehicles, priorVehicles, residentId]);

  return (
    <>
      <HouseholdSection
        title={intl.formatMessage(messages.vehicles)}
        Icon={DriveIcon}
        buttonProps={
          hasVehiclePermissions
            ? {
                onClick: handleAddVehicle,
              }
            : null
        }
        headers={[messages.vehicleModel, messages.licensePlate]}
        rows={rows.map((vehicle) => {
          const { id, licensePlateNumber, make, model, type, isCurrent } =
            vehicle;
          const displayText = parseDisplayText({ make, model, type });

          return (
            <TableRow
              key={id}
              sx={{
                color: 'text.secondary',
                backgroundColor: 'transparent !important',
              }}
            >
              <TableCell>
                {isCurrent ? (
                  <a onClick={handleEditVehicle(vehicle)}>{displayText}</a>
                ) : (
                  displayText
                )}
              </TableCell>
              <TableCell>{licensePlateNumber}</TableCell>
            </TableRow>
          );
        })}
        EmptyStateComponent={
          <>
            <Checkbox
              label={'No Vehicles'}
              onChange={residentId ? undefined : noVehiclesHandler}
              disabled={Boolean(residentId)}
              checked={vehicleCount === 0}
            />
          </>
        }
      />
      <HouseholdVehicles
        show={vehicleModal}
        applicationId={applicationId}
        onHide={resetVehicleModal}
        householdId={householdId}
        residentId={residentId}
        vehicleCount={vehicleCount}
        vehicleToEdit={vehicleToEdit}
        updateHousholdVehicles={updateHousholdVehicles}
      />
    </>
  );
};

Vehicles.defaultProps = {
  currentVehicles: [],
  householdVehicles: [],
  priorVehicles: [],
  vehicleCount: 0,
  vehiclePermissions: [],
};
export default Vehicles;
