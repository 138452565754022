import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import React from 'react';
import type { Activity } from '../types';
import moment from 'moment';
import messages from '../../ActivityContents/messages';
import styled from 'styled-components';
import ElementWithPermissions from '../../ElementWithPermissions';
import { Spinner } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { green } from '@fortress-technology-solutions/fortress-component-library/design';

type Props = { intl: Object, setRefresh: Function, onEditActivity: Function };

const StyledComment = styled(Button)`
  cursor: initial;
`;

function ActivityTableBody(props: Props) {
  const { activitiesByProspect } = props;

  const getUpcomingStatus = (activity: Activity) => {
    const now = activity.allDay ? moment().startOf('day') : moment();
    const activityStartTime = moment(activity.startTime);

    if (activityStartTime.isAfter(now)) {
      return props.intl.formatMessage(messages.upcoming);
    } else {
      return props.intl.formatMessage(messages.pastDue);
    }
  };

  const getUserForActivityTable = (activity: Activity): string => {
    if (!activity.completionStatus) {
      if (activity.ownedBy) {
        return `${activity.ownedBy.firstName} ${activity.ownedBy.lastName}`;
      } else {
        return '---';
      }
    } else {
      return activity?.updatedBy?.username;
    }
  };

  const getUpcomingStatusClass = (activity: Activity) => {
    const now = activity.allDay ? moment().startOf('day') : moment();
    const activityStartTime = moment(activity.startTime);
    if (activityStartTime.isAfter(now)) {
      return 'rowYellow';
    } else {
      return 'highlight';
    }
  };

  const UTC_TIMESTAMP_REGEX =
    /\d{4}-(0\d|1[0-2])-([0-2]\d|3[0-1])T([0-1]\d|2[0-4]):([0-5]\d):([0-5]\d)(\.\d{1,3})?Z/g;

  const convertTimestampsToLocaleDates = (stringToChange: string): string => {
    return stringToChange.replace(UTC_TIMESTAMP_REGEX, (match) =>
      new Date(match).toLocaleString(navigator.language, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }),
    );
  };

  const processActivityNotes = (notes: string) => {
    try {
      const processors = [convertTimestampsToLocaleDates];
      return processors.reduce(
        (modifiedNotes, processor) => processor(modifiedNotes),
        notes,
      );
    } catch (e) {
      return notes;
    }
  };

  const processWorkOrderActivityNotes = (notes: string) => {
    return (
      <p style={{ textAlign: 'left' }}>
        {notes.split('\n').map((line) => (
          <span
            style={{
              fontWeight: ['Email:', 'Text:'].includes(line)
                ? 'bold'
                : undefined,
            }}
          >
            {line}
            <br />
          </span>
        ))}
      </p>
    );
  };

  const StyledCheckmarkI = styled.i.attrs({
    className: 'icon et-isolated-check',
  })`
    color: ${green.main};
  `;

  if (props.isLoading) {
    return (
      <tbody data-testid="activitiesRows">
        <tr>
          <td colSpan="100%">
            <Spinner />
          </td>
        </tr>
      </tbody>
    );
  }

  if (activitiesByProspect.results.length > 0) {
    return (
      <tbody data-testid="activitiesRows">
        {activitiesByProspect.results.map((activity, index) => {
          const completionStatusName = activity.completionStatus
            ? activity.completionStatus.translations[props.locale] ||
              activity.completionStatus.name
            : 'Scheduled - ' + getUpcomingStatus(activity);

          const typeName = activity.activityType
            ? activity.activityType.translations[props.locale] ||
              activity.activityType.name
            : '';
          const assigneeName = getUserForActivityTable(activity);
          return (
            <tr
              key={index}
              className={`table-section ${
                activity.completionStatus
                  ? ''
                  : getUpcomingStatusClass(activity)
              }`}
            >
              <td>
                {activity.allDay && !activity.completionStatus ? (
                  <FormattedDate value={activity.startTime} />
                ) : (
                  <>
                    <FormattedDate
                      value={
                        activity.completionStatus
                          ? activity.updatedAt
                          : activity.startTime
                      }
                    />
                    -
                    <FormattedTime
                      value={
                        activity.completionStatus
                          ? activity.updatedAt
                          : activity.startTime
                      }
                    />
                  </>
                )}
              </td>
              <td data-testid="automated">
                {activity.isAutomated ? <StyledCheckmarkI /> : ''}
              </td>
              <td>{completionStatusName}</td>
              <td>{typeName}</td>
              <td>{assigneeName}</td>
              <td>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id={`toolTipActivityNote${activity.id}`} shape="">
                      {typeName === 'Work Order'
                        ? processWorkOrderActivityNotes(activity.notes)
                        : processActivityNotes(activity.notes)}
                    </Tooltip>
                  }
                >
                  <StyledComment
                    bsStyle="default"
                    bsSize="small"
                    className="btn-comments"
                    disabled={!activity.notes}
                  >
                    <i className="icon et-comment-words" />
                  </StyledComment>
                </OverlayTrigger>
              </td>
              <td>
                <ElementWithPermissions
                  scope={[
                    'activity-create',
                    'activity-read',
                    'activity-update',
                    'activity-delete',
                  ]}
                >
                  <Button
                    bsStyle="default"
                    bsSize="small"
                    onClick={() =>
                      props.onEditActivity(activity, props.handleRefresh)
                    }
                    disabled={activity.isAutomated}
                  >
                    <i className="et-pencil" />
                  </Button>
                </ElementWithPermissions>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  }

  return (
    <tbody data-testid="activitiesRows">
      <tr>
        <td colSpan={'100%'}>
          <FormattedMessage {...messages.noActivitiesFound} />
        </td>
      </tr>
    </tbody>
  );
}

export default ActivityTableBody;
