import { put, takeLatest, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import { GET_DEPOSIT } from './constants';
import { getDepositSuccess, getDepositError } from './actions';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId
} from '../App/selectors';
import BankDepositService from '../../services/bankDepositService';
import type { Action, Property } from '../App/types';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* fetchDeposit(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const bankDepositService = new BankDepositService();
    const response = yield bankDepositService.getBankDeposit(
      organizationId,
      selectedProperty.id,
      action.payload
    );
    yield put(getDepositSuccess(response));
  } catch (err) {
    yield put(getDepositError(err));
  }
}

export function* getDepositSaga(): Saga<void> {
  yield takeLatest(GET_DEPOSIT, fetchDeposit);
}

export default [getDepositSaga];
