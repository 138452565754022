import { useMemo, useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { AddPropertyIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import {
  useModal,
  useConfirmModal,
} from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import BatchDocumentService from '../../services/batchDocumentService';
import { toastr } from 'react-redux-toastr';
import messages from './messages';
import useFormatMessage from '../../hooks/useFormatMessage';

const useUploadBatchDocument = (props) => {
  const { organizationId, propertyId, options } = props;
  const batchPropertyService = new BatchDocumentService();

  return useMutation((document) => {
    return batchPropertyService.createAffordable(
      document,
      organizationId,
      propertyId,
    );
  }, options);
};

export const usePropertyBatchModal = ({
  propertyName,
  propertyId,
  organizationId,
  handleCancelConfirm,
  formProps,
} = {}) => {
  const formatMessage = useFormatMessage();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const { open, openModal, closeModal } = useModal();
  const { openConfirm, openConfirmModal, closeConfirmModal } =
    useConfirmModal();

  const { isValid, setValue } = formProps;
  useEffect(() => {
    setIsSubmitDisabled(!isValid);
  }, [isValid, setIsSubmitDisabled]);

  const uploadBatchDocumentMutation = useUploadBatchDocument({
    organizationId,
    propertyId,
    options: {
      onSuccess: () => {
        toastr.success(
          formatMessage(messages.createPropertyDataBatchSuccessHeader),
          formatMessage(messages.createPropertyDataBatchSuccessDescription),
        );
        formProps.reset();
        setValue('file', null);
        setIsSubmitting(false);
        handleCancelConfirm(); // go back
      },
      onError: (error) => {
        toastr.error(
          formatMessage(messages.createPropertyDataBatchFailureHeader),
          error.toString(),
        );
        formProps.reset();
        setValue('file', null);
        setIsSubmitting(false);
      },
    },
  });

  const modalProps = useMemo(() => {
    return {
      title: formatMessage(messages.title),
      icon: <AddPropertyIcon />,
      open: true,
      onClose: openConfirmModal,
      ContentProps: {
        sx: {
          maxWidth: 600,
        },
      },
      actionsProps: [
        {
          children: formatMessage(messages.cancel),
          onClick: () => {
            openConfirmModal();
          },
        },
        {
          children: formatMessage(messages.post),
          submitButton: true,
          disabled: isSubmitDisabled,
          isSubmitting,
          sx: { width: 103 },
          onClick: async () => {
            if (isSubmitDisabled) {
              return;
            }
            setIsSubmitting(true);
            const { file, fileType } = formProps.getValues();
            const formData = new FormData();
            formData.append('file', file);
            formData.append('propertyName', propertyName);
            formData.append('fileType', fileType);
            await uploadBatchDocumentMutation.mutateAsync(formData);
            setIsSubmitting(false);
          },
        },
      ],
    };
  }, [
    formatMessage,
    propertyName,
    isSubmitDisabled,
    isSubmitting,
    openConfirmModal,
    formProps,
    uploadBatchDocumentMutation,
  ]);

  const confirmModalProps = useMemo(() => {
    return {
      open: openConfirm,
      onClose: () => closeConfirmModal(),
      title: formatMessage(messages.title),

      children: formatMessage(messages.cancelConfirmationHeader),
      ContentProps: {
        sx: {
          maxWidth: 450,
        },
      },
      actionsProps: [
        {
          children: formatMessage(messages.no),
          onClick: () => {
            closeConfirmModal();
          },
        },
        {
          children: formatMessage(messages.yes),
          submitButton: true,
          onClick: () => {
            setIsSubmitting(false);
            setIsSubmitDisabled(false);
            closeConfirmModal();
            handleCancelConfirm();
          },
          sx: { width: 64 },
        },
      ],
    };
  }, [closeConfirmModal, handleCancelConfirm, openConfirm, formatMessage]);

  return {
    modalProps,
    confirmModalProps,
    closeModal: closeModal,
    closeConfirmModal: closeConfirmModal,
    openModal: openModal,
    open,
    openConfirmModal: openConfirm,
    isSubmitting,
    isSubmitDisabled,
    setIsSubmitting,
    setIsSubmitDisabled,
    formatMessage,
  };
};
