import { Typography } from '@fortress-technology-solutions/fortress-component-library/Atoms';

export const SectionTitle = (props) => (
  <Typography {...props} fontWeight={500} fontSize={18} />
);

export const RegularText = (props) => <Typography {...props} fontSize={14} />;

export const Subtitle = (props) => (
  <Typography {...props} color="#6A6A6A" fontWeight={500} fontSize={16} />
);

export const BigBreak = () => (
  <>
    <br />
    <br />
  </>
);
