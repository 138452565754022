import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../ManageUnitAvailability/messages';
import { YES_NO_OPTIONS } from '../../constants/selectOptions';

export const HEADERS = [
  {
    id: 'unit',
    label: <FormattedMessage {...messages.unit} />,
    dataType: 'string',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'floorPlan',
    label: <FormattedMessage {...messages.floorPlan} />,
    dataType: 'string',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'bedsBaths',
    label: <FormattedMessage {...messages.bedsBaths} />,
    dataType: 'string',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'sqFeet',
    label: <FormattedMessage {...messages.sqFeet} />,
    dataType: 'string',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'status',
    label: <FormattedMessage {...messages.status} />,
    dataType: 'string',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'leased',
    label: <FormattedMessage {...messages.leased} />,
    filterOptions: YES_NO_OPTIONS,
    dataType: 'boolean',
    sortable: true,
  },
  {
    id: 'resident',
    label: <FormattedMessage {...messages.resident} />,
    dataType: 'string',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'tenant',
    label: <FormattedMessage {...messages.tenant} />,
    dataType: 'string',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'marketRent',
    label: <FormattedMessage {...messages.marketRent} />,
    dataType: 'number',
    sortable: true,
    searchable: true,
  },
  {
    id: 'netMaxRent',
    label: <FormattedMessage {...messages.netMaxRent} />,
    dataType: 'number',
    sortable: true,
    searchable: true,
  },
  {
    id: 'priorLeasedRent',
    label: <FormattedMessage {...messages.priorLeasedRent} />,
    dataType: 'number',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'currentLeasedRent',
    label: <FormattedMessage {...messages.currentLeasedRent} />,
    dataType: 'number',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'moveOutDate',
    label: <FormattedMessage {...messages.moveOutDate} />,
    dataType: 'date',
    sortable: true,
    searchable: true,
  },
  {
    id: 'moveInReady',
    label: <FormattedMessage {...messages.moveInReady} />,
    dataType: 'date',
    sortable: true,
    searchable: true,
  },
  {
    id: 'notes',
    label: <FormattedMessage {...messages.notes} />,
    dataType: 'string',
    sortable: true,
    multiselect: true,
    searchable: true,
  },
  {
    id: 'daysVacant',
    label: <FormattedMessage {...messages.daysVacant} />,
    dataType: 'number',
    sortable: true,
    searchable: true,
  },
  {
    id: 'quotingRent',
    label: <FormattedMessage {...messages.quotingRent} />,
    dataType: 'number',
    sortable: true,
    searchable: true,
  },
  {
    id: 'otherUnitFeesAvailable',
    label: <FormattedMessage {...messages.requiredUnitFees} />,
    dataType: 'number',
    sortable: true,
    searchable: true,
  },
  {
    id: 'applicant',
    label: <FormattedMessage {...messages.applicant} />,
    dataType: 'string',
    sortable: true,
    searchable: true,
  },
  {
    id: 'applicantStatus',
    label: <FormattedMessage {...messages.applicantStatus} />,
    dataType: 'string',
    sortable: true,
    multiselect: true,
  },
  {
    id: 'scheduledMoveInDate',
    label: <FormattedMessage {...messages.scheduledMoveInDate} />,
    dataType: 'date',
    sortable: true,
    searchable: true,
  },
  {
    id: 'amenities',
    label: <FormattedMessage {...messages.amenities} />,
    dataType: 'string',
    sortable: true,
    searchable: true,
    multiselect: true,
  },
  {
    id: 'actions',
    label: <FormattedMessage {...messages.actions} />,
    dataType: 'string',
  },
];

export const PROPERTY_PATH_MAP = {
  unit: {
    path: 'unit',
    dataType: 'string',
  },
  floorPlan: {
    path: 'floorPlan',
    dataType: 'string',
  },
  bedsBaths: {
    path: 'bedsBaths',
    dataType: 'string',
  },
  sqFeet: {
    path: 'sqFeet',
    dataType: 'number',
  },
  status: {
    path: 'status',
    dataType: 'string',
  },
  leased: {
    path: 'leased',
    dataType: 'boolean',
    filterFn: (filterBy: string, filterValue: boolean) => {
      if (filterBy === 'yes') return filterValue;
      if (filterBy === 'no') return !filterValue;
    },
  },
  resident: {
    path: 'resident',
    dataType: 'string',
  },
  tenant: {
    path: 'tenant',
    dataType: 'string',
  },
  marketRent: {
    path: 'marketRent',
    dataType: 'number',
  },
  netMaxRent: {
    path: 'netMaxRent',
    dataType: 'number',
  },
  priorLeasedRent: {
    path: 'priorLeasedRent',
    dataType: 'number',
  },
  currentLeasedRent: {
    path: 'currentLeasedRent',
    dataType: 'number',
  },
  moveOutDate: {
    path: 'moveOutDate',
    dataType: 'date',
  },
  moveInReady: {
    path: 'moveInReady',
    dataType: 'date',
  },
  moveInReadyStatus: {
    path: 'moveInReadyStatus',
    dataType: 'string',
  },
  notes: {
    path: 'notes',
    dataType: 'string',
  },
  daysVacant: {
    path: 'daysVacant',
    dataType: 'number',
  },
  quotingRent: {
    path: 'quotingRent',
    dataType: 'number',
  },
  otherUnitFeesAvailable: {
    path: 'otherUnitFeesAvailable',
    dataType: 'number',
  },
  applicant: {
    path: 'applicant',
    dataType: 'string',
  },
  applicantStatus: {
    path: 'applicantStatus',
    dataType: 'string',
  },
  scheduledMoveInDate: {
    path: 'scheduledMoveInDate',
    dataType: 'date',
  },
  amenities: {
    path: 'amenities',
    dataType: 'string',
  },
};
