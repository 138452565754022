import { defineMessages } from 'react-intl';

const messages = defineMessages({
  type: {
    id: 'App.ViewBankDeposit.ViewBankDepositDetails.Type',
    defaultMessage: 'Type'
  },
  notes: {
    id: 'App.ViewBankDeposit.ViewBankDepositDetails.Notes',
    defaultMessage: 'Notes'
  },
  paymentBatch: {
    id: 'App.ViewBankDeposit.ViewBankDepositDetails.PaymentBatch',
    defaultMessage: 'Payment Batch'
  },
  paymentId: {
    id: 'App.ViewBankDeposit.ViewBankDepositDetails.PaymentId',
    defaultMessage: 'Payment ID'
  },
  date: {
    id: 'App.ViewBankDeposit.ViewBankDepositDetails.Date',
    defaultMessage: 'Date'
  },
  period: {
    id: 'App.ViewBankDeposit.ViewBankDepositDetails.Period',
    defaultMessage: 'Period'
  },
  amount: {
    id: 'App.ViewBankDeposit.ViewBankDepositDetails.Amount',
    defaultMessage: 'Amount'
  },
  numberOfItems: {
    id: 'App.ViewBankDeposit.ViewBankDepositDetails.NumberOfItems',
    defaultMessage: 'Number of Items'
  },
  adjustments: {
    id: 'App.ViewBankDeposit.ViewBankDepositDetails.Adjustments',
    defaultMessage: 'Adjustments'
  },
  view: {
    id: 'App.ViewBankDeposit.ViewBankDepositDetails.View',
    defaultMessage: 'View'
  }
});

export default messages;
