export const GET_ALL_MISC_TRANSACTIONS =
  'app/MiscTransactions/GET_ALL_MISC_TRANSACTIONS';
export const GET_ALL_MISC_TRANSACTIONS_SUCCESS =
  'app/MiscTransactions/GET_ALL_MISC_TRANSACTIONS_SUCCESS';
export const GET_ALL_MISC_TRANSACTIONS_ERROR =
  'app/MiscTransactions/GET_ALL_MISC_TRANSACTIONS_ERROR';

export const GET_ALL_PROPERTY_PERIODS =
  'app/MiscTransactions/GET_ALL_PROPERTY_PERIODS';
export const GET_ALL_PROPERTY_PERIODS_SUCCESS =
  'app/MiscTransactions/GET_ALL_PROPERTY_PERIODS_SUCCESS';
export const GET_ALL_PROPERTY_PERIODS_ERROR =
  'app/MiscTransactions/GET_ALL_PROPERTY_PERIODS_ERROR';

export const HANDLE_SOCKET_REVERSE_MISC_TRANSACTION_ERROR =
  'app/MiscTransactions/HANDLE_SOCKET_REVERSE_MISC_TRANSACTION_ERROR';
export const HANDLE_SOCKET_REVERSE_MISC_TRANSACTION_SUCCESS =
  'app/MiscTransactions/HANDLE_SOCKET_REVERSE_MISC_TRANSACTION_SUCCESS';

export const CLEAR_LOADED_MISC_TRANSACTIONS =
  'app/MiscTransactions/CLEAR_LOADED_MISC_TRANSACTIONS';

export const REVERSE_MISC_TRANSACTION =
  'app/MiscTransactions/REVERSE_MISC_TRANSACTION';
export const REVERSE_MISC_TRANSACTION_SUCCESS =
  'app/MiscTransactions/REVERSE_MISC_TRANSACTION_SUCCESS';
