import React from 'react';
import {
  renderTextField,
  renderSelectField,
  renderEmailField,
  renderPhoneField,
  phoneParser,
  phoneFormatter
} from '../../../../utils/redux-form-helper';
import { Field } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import messages from '../messages';
import deleteConfirmation from '../../../../components/DeleteConfirmation';

type ComponentProps = {
  intl: any,
  adult: any,
  onDelete: Function,
  suffixes: Array<Object>,
  relationships: Array<Object>
};

const AdultIndividual = ({ intl, adult, onDelete, suffixes, relationships }: ComponentProps) => {
  const deleteItemCallback = (options) => {
    deleteConfirmation(intl.formatMessage(messages.deleteItemConfirmation), {
      intl
    })
      .then(() => onDelete(options))
      .catch(() => {});
  };

  return (
    <Row className="adults-row">
      <div className="container-of-inputs">
        <Col xs={6} sm={2}>
          <Field
            selectClassNames="input-lg"
            name={`${adult}.relationshipId`}
            component={renderSelectField}
            options={relationships}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field className="input-lg" name={`${adult}.firstName`} component={renderTextField} maxLength="35" />
        </Col>
        <Col xs={6} sm={1}>
          <Field className="input-lg" name={`${adult}.middleName`} component={renderTextField} maxLength="35" />
        </Col>
        <Col xs={6} sm={2}>
          <Field className="input-lg" name={`${adult}.lastName`} component={renderTextField} maxLength="35" />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            selectClassNames="input-lg"
            name={`${adult}.suffixId`}
            component={renderSelectField}
            options={suffixes}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field className="input-lg" name={`${adult}.preferredName`} component={renderTextField} maxLength="35" />
        </Col>
        <Col xs={6} sm={2}>
          <Field
            className="input-lg"
            name={`${adult}.phoneNumber`}
            component={renderPhoneField}
            parse={phoneParser}
            format={phoneFormatter}
          />
        </Col>
        <Col xs={6} sm={2}>
          <Field className="input-lg" name={`${adult}.emailAddress`} component={renderEmailField} />
        </Col>
      </div>
      <div className="container-of-trash">
        <div className="row-remove-btn">
          <a onClick={deleteItemCallback}>
            <i className="et-trash" />
          </a>
        </div>
      </div>
    </Row>
  );
};

export default AdultIndividual;
