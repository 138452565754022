import { defineMessages } from 'react-intl';

const messages = defineMessages({
  cancel: {
    id: 'App.ResidentProfile.ManageDocuments.LettersModal.Cancel',
    defaultMessage: 'Cancel',
  },
  generateLetter: {
    id: 'App.ResidentProfile.ManageDocuments.LettersModal.GenerateLetter',
    defaultMessage: 'Generate Letter',
  },
  residentLetterLibrary: {
    id:
      'App.ResidentProfile.ManageDocuments.LettersModal.ResidentLetterLibrary',
    defaultMessage: 'Resident Letter Library',
  },
  selectDocuments: {
    id: 'App.ResidentProfile.ManageDocuments.LettersModal.SelectDocuments',
    defaultMessage: 'Select the resident letter you want to generate:',
  },
  tenantLetterLibrary: {
    id: 'App.ResidentProfile.ManageDocuments.LettersModal.TenantLetterLibrary',
    defaultMessage: 'Tenant Letter Library',
  },
  tenantSelectDocuments: {
    id:
      'App.ResidentProfile.ManageDocuments.LettersModal.TenantSelectDocuments',
    defaultMessage: 'Select the tenant letter you want to generate:',
  },
});

export default messages;
