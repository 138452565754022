import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.CreateTransactionBatch.ConfirmPostBatchModal.title',
    defaultMessage: 'Confirm Post for Transaction Batch',
  },
  yes: {
    id: 'App.CreateTransactionBatch.ConfirmPostBatchModal.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.CreateTransactionBatch.ConfirmPostBatchModal.no',
    defaultMessage: 'No',
  },
});

export default messages;
