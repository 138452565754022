import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.PeopleProfile.ProspectInfoTab.Title',
    defaultMessage: 'Prospect Information',
  },
  overview: {
    id: 'App.PeopleProfile.ProspectInfoTab.Overview',
    defaultMessage: 'Overview',
  },
  editInfo: {
    id: 'App.PeopleProfile.ProspectInfoTab.EditInfo',
    defaultMessage: 'Edit Information',
  },
  details: {
    id: 'App.PeopleProfile.ProspectInfoTab.Details',
    defaultMessage: 'Details',
  },
  floorPlan: {
    id: 'App.PeopleProfile.ProspectInfoTab.FloorPlan',
    defaultMessage: 'Floor Plan',
  },
  noBeds: {
    id: 'App.PeopleProfile.ProspectInfoTab.NoBeds',
    defaultMessage: '# Beds',
  },
  noBaths: {
    id: 'App.PeopleProfile.ProspectInfoTab.NoBaths',
    defaultMessage: '# Baths',
  },
  moveInDate: {
    id: 'App.PeopleProfile.ProspectInfoTab.MoveInDate',
    defaultMessage: 'Move-in Date',
  },
  priceRange: {
    id: 'App.PeopleProfile.ProspectInfoTab.PriceRange',
    defaultMessage: 'Price Range',
  },
  leaseTerm: {
    id: 'App.PeopleProfile.ProspectInfoTab.LeaseTerm',
    defaultMessage: 'Lease Term',
  },
  wants: {
    id: 'App.PeopleProfile.ProspectInfoTab.Wants',
    defaultMessage: 'Wants',
  },
  doesntWant: {
    id: 'App.PeopleProfile.ProspectInfoTab.DoesntWant',
    defaultMessage: "Doesn't Want",
  },
  contactType: {
    id: 'App.PeopleProfile.ProspectInfoTab.ContactType',
    defaultMessage: 'Contact Type',
  },
  source: {
    id: 'App.PeopleProfile.ProspectInfoTab.Source',
    defaultMessage: 'How did you hear about us?',
  },
  additionalOccupants: {
    id: 'App.PeopleProfile.ProspectInfoTab.AdditionalOccupants',
    defaultMessage: 'Additional Occupant(s)',
  },
  spouse: {
    id: 'App.PeopleProfile.ProspectInfoTab.Spouse',
    defaultMessage: 'Spouse',
  },
  otherAdults: {
    id: 'App.PeopleProfile.ProspectInfoTab.OtherAdults',
    defaultMessage: 'Other Adults',
  },
  kids: {
    id: 'App.PeopleProfile.ProspectInfoTab.Kids',
    defaultMessage: 'Minors',
  },
  pets: {
    id: 'App.PeopleProfile.ProspectInfoTab.Pets',
    defaultMessage: 'Pets',
  },
  introductoryNote: {
    id: 'App.PeopleProfile.ProspectInfoTab.introductoryNote',
    defaultMessage: 'Introductory Note',
  },
  noMoveInDate: {
    id: 'App.PeopleProfile.ProspectInfoTab.NoMovinDate',
    defaultMessage: 'No Move-in Date',
  },
});

export default messages;
