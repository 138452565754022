import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { NoPrint } from 'react-easy-print';

// Redux Form
import { reduxForm, formValueSelector } from 'redux-form';
import { Field, FieldArray } from 'redux-form';
import {
  renderTextField,
  renderSelectField,
  renderCurrencyField,
  renderNumberField,
} from '../../../utils/redux-form-helper';

// Components
import { Tooltip } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import {
  YesNoColumns,
  IncomeAssetCurrencyField,
  SecurityClaimNumberRow,
  CheckBox,
} from '../SharedFormFields';

// Types
import type { IncomeFormProps } from '../types';
import type {
  IncomeAssetRow,
  IncomeMoneyColumnProps,
  IncomeRowProps,
  IncomeOtherFieldProps,
} from '../types';

// Constants
import { ORGANIZATION_FIELDS_MAP, ALTERNATE_FORM_INFO } from './constants.js';
import messages from './messages';

// Utils
import { pick, pathOr, isNil } from 'ramda';
import validate from './validate';

export const IncomeTableHeader = (props) => {
  const hoursWeeksMonthsTooltipProps = {
    component: 'span',
    placement: 'top',
    variant: 'light',
    title: (
      <FormattedMessage
        {...messages.hoursWeeksMonthsDescription}
        values={{
          units: (
            <strong>{<FormattedMessage {...messages.hoursPerWeek} />}</strong>
          ),
        }}
      />
    ),
  };
  const typeOfIncomeHeaderClassName = 'col-xs-2';
  const requiredFormHeaderClassName = 'col-xs-1';
  const annualGrossReceivingHeaderClassName = 'col-xs-2';
  return (
    <div className="row faux-table__head">
      <div className={typeOfIncomeHeaderClassName}>
        <div className="row">
          <div className="col-xs-12">
            <label>
              <FormattedMessage {...messages.typeOfIncome} />
            </label>
          </div>
        </div>
      </div>
      <div className="col-xs-2 text-center">
        <label>
          <FormattedMessage {...messages.receiving} />
        </label>
        <div className="row">
          <div className="col-xs-6">
            <label>
              <FormattedMessage {...messages.no} />
            </label>
          </div>
          <div className="col-xs-6">
            <label>
              <FormattedMessage {...messages.yes} />
            </label>
          </div>
        </div>
      </div>
      <div className="col-xs-5">
        <div className="col-xs-5">
          <label>
            <FormattedMessage {...messages.payDataPayFrequencyLabel} />
          </label>
        </div>
        <div className="col-xs-4">
          <label>
            <FormattedMessage {...messages.payDataPayRateLabel} />
          </label>
        </div>
        <div className="col-xs-3">
          <label>
            <FormattedMessage {...messages.payDataHoursWeeksMonthsLabel} />
            <Tooltip {...hoursWeeksMonthsTooltipProps}>
              <i className="et-icon et-alert-info" />
            </Tooltip>
          </label>
        </div>
      </div>
      <div className={annualGrossReceivingHeaderClassName}>
        <label>
          <FormattedMessage {...messages.annualGrossReceiving} />
        </label>
      </div>
      <div className={requiredFormHeaderClassName}>
        <label>
          <FormattedMessage {...messages.requiredForm} />
        </label>
      </div>
    </div>
  );
};

export const NumberAndSourceColumn = ({
  row: { name },
  isPrior,
}: IncomeAssetRow) => {
  const colClassName = 'col-xs-2';
  return (
    <div className={colClassName}>
      <div className="row">
        <div className="col-xs-12">
          <FormattedMessage {...messages[name]} />
        </div>
      </div>
    </div>
  );
};

export const IncomeAmountField = ({
  name,
  disabled,
  isPrior,
}: IncomeMoneyColumnProps) => {
  const colSize = '2';
  if (name === 'courtOrderedChildSupport') {
    return (
      <IncomeAssetCurrencyField
        name={name}
        type={'Income'}
        disabled={disabled || !!isPrior}
        label="Amount Ordered:"
        colSize={colSize}
      />
    );
  } else if (name === 'receivingChildSupport') {
    return (
      <IncomeAssetCurrencyField
        name={name}
        type={'Income'}
        disabled={disabled || !!isPrior}
        label="Amount Receiving:"
        colSize={colSize}
      />
    );
  } else if (name === 'publicHousing') {
    return (
      <IncomeAssetCurrencyField
        name={name}
        type={'Income'}
        disabled={disabled || !!isPrior}
        label="If yes, Public Housing Authority:"
        colSize={colSize}
      />
    );
  } else {
    return (
      <IncomeAssetCurrencyField
        name={name}
        type={'Income'}
        disabled={disabled || !!isPrior}
        colSize={colSize}
      />
    );
  }
};

export const StateCountyOrMiscField = ({
  name,
  states,
  type,
  disabled,
  isPrior,
}: IncomeMoneyColumnProps) => {
  const statesColSize = '2';
  if (type === 'states') {
    return (
      <div className={`col-xs-${statesColSize}`}>
        <label>
          <FormattedMessage {...messages.state} />
        </label>
        <Field
          selectClassNames="input-md form-control"
          name={`${name}State`}
          component={renderSelectField}
          bsSize="lg"
          options={states}
          maxLength="35"
          disabled={disabled || isPrior}
        />
        <label>
          <FormattedMessage {...messages.county} />
        </label>
        <Field
          name={`${name}County`}
          component={renderTextField}
          maxLength="35"
          disabled={disabled || isPrior}
          className="input-md"
        />
      </div>
    );
  } else {
    return (
      <div className={`col-xs-${statesColSize}`}>
        <label>
          <FormattedMessage {...messages[`${name}Text`]} />
        </label>
        <Field
          name={`${name}Text`}
          component={renderTextField}
          maxLength="35"
          disabled={disabled || isPrior}
          className="input-lg"
        />
      </div>
    );
  }
};

export const PayDataColumns = ({
  name,
  disabled,
  hasEmptyLabelForSpacing,
  isDisableHoursWeeksMonths,
  change,
  ...props
}: Object) => {
  const disableHoursWeeksMonths = isDisableHoursWeeksMonths();
  return (
    <div className="col-xs-5">
      <div className="col-xs-5">
        {hasEmptyLabelForSpacing && <label></label>}
        <Field
          selectClassNames="input-md form-control"
          name={`${name}PayFrequency`}
          component={renderSelectField}
          bsSize="lg"
          options={[
            { value: -1, text: 'Select Frequency', disabled: true },
            { value: 'hourly', text: 'Hourly' },
            { value: 'weekly', text: 'Weekly' },
            { value: 'biWeekly', text: 'Bi-weekly' },
            { value: 'monthly', text: 'Monthly' },
            { value: 'semiMonthly', text: 'Semi-Monthly' },
            { value: 'annually', text: 'Annually' },
          ]}
          maxLength="35"
          disabled={disabled}
          onChange={(event) => {
            const chosenPayFrequency = event.target.value;
            if (
              ['weekly', 'monthly', 'annually'].includes(chosenPayFrequency)
            ) {
              const payFrequencyToWeeksMap = getPayFrequencyToWeeksMap();
              change(
                `${name}HoursWeeksMonths`,
                payFrequencyToWeeksMap[chosenPayFrequency] ??
                  payFrequencyToWeeksMap.default,
              );
            }
          }}
        />
      </div>
      <div className="col-xs-4">
        {hasEmptyLabelForSpacing && <label></label>}
        <Field
          name={`${name}PayRate`}
          component={renderCurrencyField}
          className={'input-lg'}
          step="0.01"
          disabled={disabled}
        />
      </div>
      <div className="col-xs-3">
        {hasEmptyLabelForSpacing && <label></label>}
        <Field
          name={`${name}HoursWeeksMonths`}
          component={renderNumberField}
          className={'input-lg'}
          step="0.01"
          disabled={disabled || disableHoursWeeksMonths}
        />
      </div>
    </div>
  );
};

export const EmploymentPayType = ({
  name,
  disabled,
  colSize = 3,
}: IncomeOtherFieldProps) => {
  let payTypes = [
    'Base Pay',
    'Overtime',
    'Tips',
    'Bonuses',
    'Anticipated Pay Increase',
  ]
    .map((name) => ({ text: name }))
    .map((selectOption) => {
      const { text } = selectOption;
      const noSpaces = text.replace(/\s/g, '');
      const value = noSpaces.charAt(0).toLowerCase() + noSpaces.slice(1);
      return {
        text,
        value,
      };
    })
    .map((selectOption) => ({
      ...selectOption,
      disabled: false,
    }));

  payTypes = [
    {
      text: 'Select Employment Income Type',
      value: -1,
      disabled: true,
    },
    ...payTypes,
  ];

  return (
    <div className="col-xs-4">
      <div className="row">
        <div className="col-xs-1" />
        <Field
          className="col-xs-10"
          selectClassNames="input-md form-control"
          name={`${name}.payType`}
          component={renderSelectField}
          bsSize="lg"
          options={payTypes}
          maxLength="35"
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export const OtherIncomeName = ({
  name,
  disabled,
  colSize = 3,
  incomeTypes,
}: IncomeOtherFieldProps) => {
  return (
    <div className="col-xs-4">
      <div className="row">
        <div className="col-xs-6">
          <Field
            name={`${name}.IncomeName`}
            component={renderTextField}
            maxLength="35"
            bsSize="lg"
            placeholder="Other"
            disabled={disabled}
          />
        </div>
        <div className="col-xs-5">
          <Field
            name={`${name}.IncomeType`}
            component={renderSelectField}
            bsSize="lg"
            options={incomeTypes || []}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export const EmploymentRow = (props) => {
  const {
    field,
    disabled,
    isPrior,
    hasFormColClass,
    hasEmptyLabelForSpacing,
    removeEmploymentRow,
    ...rest
  } = props;

  const payDataColsProps = {
    name: `${field}.`,
    disabled: disabled || !!isPrior,
    hasEmptyLabelForSpacing,
    ...rest,
  };
  const incomeAmountFieldProps = {
    name: `${field}.`,
    disabled: true,
  };
  const employmentPayTypeProps = {
    name: field,
    colSize: 2,
    disabled: disabled || !!isPrior,
  };
  return (
    <div className="col-xs-12" style={{ marginTop: '8px' }}>
      <EmploymentPayType {...employmentPayTypeProps} />
      <PayDataColumns {...payDataColsProps} />
      <IncomeAmountField {...incomeAmountFieldProps} />
      <a className={hasFormColClass} onClick={removeEmploymentRow}>
        <i className="et-icon et-trash" />
      </a>
    </div>
  );
};

export const OtherIncomeRow = (props) => {
  const {
    field,
    disabled,
    isPrior,
    hasFormColClass,
    hasEmptyLabelForSpacing,
    removeOtherRow,
    incomeTypes,
    ...rest
  } = props;

  const payDataColsProps = {
    name: `${field}.`,
    disabled: disabled || !!isPrior,
    hasEmptyLabelForSpacing,
    ...rest,
  };
  const incomeAmountFieldProps = {
    name: `${field}.`,
    disabled: true,
  };
  const otherIncomeNameProps = {
    name: field,
    colSize: 2,
    incomeTypes,
  };
  return (
    <div class="col-xs-12" style={{ marginTop: '8px' }}>
      <OtherIncomeName {...otherIncomeNameProps} />
      <PayDataColumns {...payDataColsProps} />
      <IncomeAmountField {...incomeAmountFieldProps} />
      <a className={hasFormColClass} onClick={removeOtherRow}>
        <i className="et-icon et-trash" />
      </a>
    </div>
  );
};

export const getEmptyEmploymentRow = () => ({
  payType: -1,
  PayRate: '0.00',
  HoursWeeksMonths: '0',
  PayFrequency: 'hourly',
  Income: '0.00',
});

export const getEmptyOtherRow = () => ({
  IncomeName: '',
  PayRate: '0.00',
  HoursWeeksMonths: '0',
  PayFrequency: 'hourly',
  Income: '0.00',
});

export const RenderEmploymentRows = (props) => {
  const { fields, rowProps, limit } = props;
  const disabled = fields.length >= limit;
  return (
    <div>
      {fields.map((field, index) => {
        return (
          <EmploymentRow
            key={index}
            field={field}
            {...rowProps}
            isDisableHoursWeeksMonths={() =>
              rowProps.isDisableHoursWeeksMonths([
                'employmentList',
                index.toString(),
                'PayFrequency',
              ])
            }
            removeEmploymentRow={() => {
              fields.remove(index);
            }}
          />
        );
      })}
      <a
        className="col-xs-12 btn-text"
        disabled={disabled}
        style={{ marginTop: '8px' }}
        onClick={() => {
          if (disabled) {
            return;
          }
          fields.push(getEmptyEmploymentRow());
        }}
      >
        + Add Employment
      </a>
    </div>
  );
};

export const RenderOtherRows = (props) => {
  const { fields, rowProps, incomeTypes, limit } = props;
  const disabled = fields.length >= limit;
  return (
    <div>
      {fields.map((field, index) => {
        return (
          <OtherIncomeRow
            key={index}
            field={field}
            {...rowProps}
            isDisableHoursWeeksMonths={() =>
              rowProps.isDisableHoursWeeksMonths([
                'otherList',
                index.toString(),
                'PayFrequency',
              ])
            }
            removeOtherRow={() => {
              fields.remove(index);
            }}
            incomeTypes={incomeTypes}
          />
        );
      })}
      <a
        className="col-xs-12 btn-text"
        disabled={disabled}
        onClick={() => {
          if (disabled) {
            return;
          }
          fields.push(getEmptyOtherRow());
        }}
      >
        + Add Other Income
      </a>
    </div>
  );
};

export const EmploymentRows = (props) => {
  const { name, disabled, limit = 4, ...rest } = props;
  const hasFormColClass = 'col-xs-1';
  const rowProps = {
    ...rest,
    hasFormColClass,
  };
  return (
    !disabled && (
      <FieldArray
        name={`${name}List`}
        component={RenderEmploymentRows}
        rowProps={rowProps}
        limit={limit}
      />
    )
  );
};

export const OtherRows = (props) => {
  const { name, disabled, incomeTypes, limit = 10, ...rest } = props;
  const hasFormColClass = 'col-xs-1';
  const rowProps = {
    ...rest,
    hasFormColClass,
  };
  return (
    !disabled && (
      <FieldArray
        name={`${name}List`}
        component={RenderOtherRows}
        rowProps={rowProps}
        incomeTypes={incomeTypes}
        limit={limit}
      />
    )
  );
};

export const calculateAnnualGrossIncomeForRow = (
  payRate: number,
  hoursWeeksMonths: number,
  payFrequency: number,
) => {
  if (isNaN(payRate) || isNaN(hoursWeeksMonths)) {
    return 0;
  }

  return payFrequency * payRate * hoursWeeksMonths;
};

export const getPayFrequencyToWeeksMap = () => ({
  hourly: 52,
  weekly: 52,
  monthly: 12,
  semiMonthly: 24,
  biWeekly: 26,
  annually: 1,
  default: 1,
});

export const IncomeRow = ({
  row,
  states,
  incomeTypes,
  fields,
  change,
  isPrior,
  intl,
  ...props
}: IncomeRowProps) => {
  const { name, type, hasForm } = row;

  const disabled = fields[name] !== 'yes';
  const noClicked = () => {
    if (type === 'income') {
      change(`${name}Income`, '0.00');
      change(`${name}PayFrequency`, -1);
      change(`${name}PayRate`, 0);
      change(`${name}HoursWeeksMonths`, 0);
    }
    if (type === 'states') {
      change(`${name}State`, null);
      change(`${name}County`, null);
    }
    if (type === 'text') {
      change(`${name}Text`, null);
    }
    // if (name === 'other') {
    //   change('otherIncome', '0.00');
    //   change('otherIncomeName', null);
    //   change('otherIncomeType', null);
    //   change('otherPayFrequency', -1);
    //   change('otherPayRate', 0);
    //   change('otherHoursWeeksMonths', null);
    // }
    if (name === 'socialSecurity') {
      change('socialSecurityClaimNumber', null);
    }
    if (name === 'supplementalSecurity') {
      change('supplementalSecurityClaimNumber', null);
    }
    if (name === 'employment') {
      change('employmentList', []);
    }
    if (name === 'other') {
      change('otherList', []);
    }
  };

  const yesClicked = () => {
    if (name === 'employment') {
      change('employmentList', [getEmptyEmploymentRow()]);
    }
    if (name === 'other') {
      change('otherList', [getEmptyOtherRow()]);
    }
  };

  const isDisableHoursWeeksMonths = (frequencyArrayPath) => {
    const chosenFrequency = pathOr('default', frequencyArrayPath, props);
    return ['weekly', 'annually'].includes(chosenFrequency);
  };

  const hasFormColClass = 'col-xs-1';
  const securityClaimTextOffset = '2';
  const securityClaimTextCol = '1';
  const securityClaimInputCol = '2';
  const securityClaimInputOffset = '1';
  const showPayDataCols = type === 'income' || type === 'otherIncome';
  const hasEmptyLabelForSpacing =
    name === 'courtOrderedChildSupport' || name === 'receivingChildSupport';
  const offsetForReqFormTextField = '4';
  const offsetForReqFormStatesField = '5';

  const employmentRowProps = {
    name,
    disabled,
    isPrior,
    hasEmptyLabelForSpacing,
    change,
    isDisableHoursWeeksMonths,
    ...props,
  };

  return (
    <div>
      <div className="col-xs-12">
        <NumberAndSourceColumn row={row} />
        <YesNoColumns
          name={name}
          noClicked={noClicked}
          yesClicked={yesClicked}
          isPrior={isPrior}
        />
        {type === 'states' && (
          <StateCountyOrMiscField
            name={name}
            states={states}
            type={type}
            disabled={disabled || !!isPrior}
          />
        )}

        {type === 'text' && (
          // For Spacing
          <div className="col-xs-3">
            <input className="input-lg" style={{ visibility: 'hidden' }} />
          </div>
        )}
        {showPayDataCols && name !== 'employment' && name !== 'other' && (
          <PayDataColumns
            name={name}
            disabled={disabled || !!isPrior}
            hasEmptyLabelForSpacing={hasEmptyLabelForSpacing}
            isDisableHoursWeeksMonths={() =>
              isDisableHoursWeeksMonths([`${name}PayFrequency`])
            }
            change={change}
            {...props}
          />
        )}
        {type === 'income' && name !== 'employment' && name !== 'other' && (
          <IncomeAmountField name={name} disabled />
        )}
        {type === 'text' && hasForm && (
          <div className={`col-xs-${offsetForReqFormTextField}`}></div>
        )}
        {type === 'states' && hasForm && (
          <div className={`col-xs-${offsetForReqFormStatesField}`}></div>
        )}
        <div className={hasFormColClass}>
          {hasForm && name !== 'employment' && name !== 'other' ? (
            <FormattedMessage {...messages[`${name}Forms`]} />
          ) : (
            ''
          )}
        </div>
      </div>
      {(name === 'socialSecurity' || name === 'supplementalSecurity') &&
      type === 'income' ? (
        <SecurityClaimNumberRow
          name={`${name}ClaimNumber`}
          disabled={disabled}
          intl={intl}
          textOffset={securityClaimTextOffset}
          textCol={securityClaimTextCol}
          inputCol={securityClaimInputCol}
          inputOffset={securityClaimInputOffset}
        />
      ) : null}
      {name === 'employment' && <EmploymentRows {...employmentRowProps} />}
      {name === 'other' && (
        <OtherRows {...employmentRowProps} incomeTypes={incomeTypes} />
      )}
    </div>
  );
};

const IncomeVerificationForm = (props: IncomeFormProps) => {
  const {
    incomeTypes,
    states,
    handleSubmit,
    applicantName,
    isMinor,
    onSubmit,
    change,
    valid,
    isPrior,
    hasCompliancePermission,
    intl,
    isSubmitting,
    handlePrintIncomeAssetForm,
    employmentList,
    otherList,
    isFormDisabled,
    initialValues: { incomeConfirmation },
    match: {
      params: { applicantId },
    },
    isAltForm,
    wasFormEdited,
    flags,
  } = props;
  const { incomeForMinors, rolloverIncomeAndAssets } = flags;
  const hasAlternateColumns = isAltForm;
  const [isIncomeFormReviewed, setIsIncomeFormReviewed] = useState(false);

  useEffect(() => {
    setIsIncomeFormReviewed(
      rolloverIncomeAndAssets
        ? isNil(incomeConfirmation) || wasFormEdited
          ? false
          : incomeConfirmation
        : true,
    );
  }, [rolloverIncomeAndAssets, incomeConfirmation, wasFormEdited]);

  const { INCOME_VALUE_FIELDS, FORM_INFO, INCOME_ROW_NAMES } =
    ORGANIZATION_FIELDS_MAP.alternate;

  const totalAnnualIncome = INCOME_VALUE_FIELDS.reduce((prevTotal, key) => {
    const value = props?.[key] ?? 0;
    const consolidatedValue = Array.isArray(value)
      ? value.reduce((total, employmentRow) => {
          let employmentAnnualIncome = Number(employmentRow?.Income ?? 0);
          employmentAnnualIncome = isNaN(employmentAnnualIncome)
            ? 0
            : employmentAnnualIncome;
          return total + employmentAnnualIncome;
        }, 0)
      : Number(value);

    const finalSum =
      prevTotal + (isNaN(consolidatedValue) ? 0 : consolidatedValue);

    return finalSum;
  }, 0);
  // $FlowFixMe
  const stateDisabled = pathOr(
    false,
    ['location', 'state', 'isDisabled'],
    props,
  );
  const isDisabled = stateDisabled && !hasCompliancePermission;
  // $FlowFixMe
  const fields = pick(INCOME_ROW_NAMES, props);
  const priorAndNotCompliance = hasAlternateColumns
    ? isPrior
    : isPrior && !hasCompliancePermission;
  const incomeRows = FORM_INFO.income
    .filter((incomeForm) => {
      if (incomeForMinors && incomeForm.name === 'unearnedIncome17AndUnder') {
        return false;
      }
      if (isMinor?.[applicantId]) {
        return !['employment', 'selfEmployment', 'militaryPay'].includes(
          incomeForm.name,
        );
      }

      return incomeForm;
    })
    .map((row, i) => (
      <div className="row faux-table__row" key={`income-${i}`}>
        <IncomeRow
          row={row}
          incomeTypes={incomeTypes}
          fields={fields}
          change={change}
          isPrior={priorAndNotCompliance || isDisabled || isFormDisabled}
          intl={intl}
          employmentList={employmentList}
          otherList={otherList}
          {...props}
        />
      </div>
    ));
  const childSupportRows = FORM_INFO.childSupport.map((row, i) => (
    <IncomeRow
      key={`cs-${i}`}
      row={row}
      incomeTypes={incomeTypes}
      states={states}
      fields={fields}
      change={change}
      isPrior={priorAndNotCompliance || isDisabled || isFormDisabled}
      intl={intl}
      {...props}
    />
  ));

  const miscRows = FORM_INFO.misc.map((row, i) => (
    <div className="row faux-table__row" key={`misc-${i}`}>
      <IncomeRow
        row={row}
        incomeTypes={incomeTypes}
        states={states}
        fields={fields}
        change={change}
        isPrior={priorAndNotCompliance || isDisabled || isFormDisabled}
        intl={intl}
        {...props}
      />
    </div>
  ));

  const submit = (values: Object) => {
    onSubmit({ ...values, isAltForm: true }, totalAnnualIncome.toString());
  };

  const printIncomeForm = () => {
    handlePrintIncomeAssetForm('income');
  };

  const annualIncomeTotalClassName = 'col-xs-4';

  return (
    <div className="row">
      <div className="col-xs-12 col-lg-11">
        <div className="panel block block-white-shadow">
          <div className="panel-head">
            <div className="row">
              <div className="col-xs-10 col-md-11 col-lg-11">
                <h2>
                  <FormattedMessage {...messages.incomeVerification} />{' '}
                  {applicantName}
                </h2>
                <NoPrint>
                  <div className="row">
                    <div className="col-xs-12">
                      <p className="no-margin-bottom">
                        <FormattedMessage {...messages.incomeHeaderNote} />
                      </p>
                    </div>
                  </div>
                </NoPrint>
              </div>
              {!isPrior && (
                <div className="col-xs-2 col-md-1 col-lg-1 text-right">
                  <a
                    className="btn btn-tertiary btn-print no-print"
                    title="Print this form"
                    style={{ fontSize: '1.8em' }}
                    onClick={printIncomeForm}
                  >
                    <i className="icon et-print" />
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="panel-body">
            <div className="container-fluid">
              <div className="row container-fluid faux-table">
                <div className="col-xs-12">
                  <form onSubmit={handleSubmit(submit)}>
                    <IncomeTableHeader />
                    {incomeRows}
                    <div className="row faux-table__row">
                      {childSupportRows}
                    </div>
                    {miscRows}
                    <div className="row highlighted-footer padtop10 padbottom10">
                      <div className={annualIncomeTotalClassName}>
                        <FormattedMessage {...messages.totalAnnualIncome} />
                      </div>
                      <div className="col-xs-6">
                        <FormattedNumber
                          value={totalAnnualIncome}
                          style={{ value: 'currency' }.value}
                          currency="USD"
                        />
                      </div>
                    </div>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={4}
                    >
                      {rolloverIncomeAndAssets && (
                        <Grid item>
                          <CheckBox
                            label={intl.formatMessage(
                              messages.incomeConfirmation,
                            )}
                            name={'incomeConfirmation'}
                            onChange={() =>
                              setIsIncomeFormReviewed(!isIncomeFormReviewed)
                            }
                            checked={isIncomeFormReviewed}
                            disabled={
                              !valid ||
                              isDisabled ||
                              isSubmitting ||
                              priorAndNotCompliance ||
                              isFormDisabled
                            }
                          />
                        </Grid>
                      )}
                      {!isPrior || hasCompliancePermission ? (
                        <Grid item>
                          <button
                            className="btn btn-primary padbottom20"
                            type="submit"
                            disabled={
                              !(valid && isIncomeFormReviewed) ||
                              isDisabled ||
                              isSubmitting ||
                              priorAndNotCompliance ||
                              isFormDisabled
                            }
                          >
                            <FormattedMessage
                              {...messages.saveIncomeInformation}
                            />
                          </button>
                        </Grid>
                      ) : null}
                    </Grid>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (
  { form, qualification: { isMinor } }: Object,
  {
    initialValues,
    match: {
      params: { applicantId },
    },
  }: Object,
): Object => {
  const selector = formValueSelector('incomeVerification');
  const statePropsMap = {
    initialValues,
    isMinor,
    employmentIncome: selector({ form }, 'employmentIncome'),
    selfEmploymentIncome: selector({ form }, 'selfEmploymentIncome'),
    militaryPayIncome: selector({ form }, 'militaryPayIncome'),
    unemploymentBenefitsIncome: selector(
      { form },
      'unemploymentBenefitsIncome',
    ),
    socialSecurityIncome: selector({ form }, 'socialSecurityIncome'),
    supplementalSecurityIncome: selector(
      { form },
      'supplementalSecurityIncome',
    ),
    vaBenefitsIncome: selector({ form }, 'vaBenefitsIncome'),
    tanfIncome: selector({ form }, 'tanfIncome'),
    disabilityIncome: selector({ form }, 'disabilityIncome'),
    recurringGiftIncome: selector({ form }, 'recurringGiftIncome'),
    retirementIncome: selector({ form }, 'retirementIncome'),
    trustIncome: selector({ form }, 'trustIncome'),
    absentFamilyMemberIncome: selector({ form }, 'absentFamilyMemberIncome'),
    otherIncome: selector({ form }, 'otherIncome'),
    receivingChildSupportIncome: selector(
      { form },
      'receivingChildSupportIncome',
    ),
    absentFamilyMember: selector({ form }, 'absentFamilyMember'),
    anticipateChildSupport: selector({ form }, 'anticipateChildSupport'),
    collectChildSupport: selector({ form }, 'collectChildSupport'),
    courtOrderedChildSupport: selector({ form }, 'courtOrderedChildSupport'),
    disability: selector({ form }, 'disability'),
    employment: selector({ form }, 'employment'),
    vaBenefits: selector({ form }, 'vaBenefits'),
    housingAssistance: selector({ form }, 'housingAssistance'),
    militaryPay: selector({ form }, 'militaryPay'),
    notWorking: selector({ form }, 'notWorking'),
    otherIncomeName: selector({ form }, 'otherIncomeName'),
    otherIncomeType: selector({ form }, 'otherIncomeType'),
    otherIncomeTypeId: selector({ form }, 'otherIncomeTypeId'),
    other: selector({ form }, 'other'),
    publicHousing: selector({ form }, 'publicHousing'),
    receivingChildSupport: selector({ form }, 'receivingChildSupport'),
    recurringGift: selector({ form }, 'recurringGift'),
    retirement: selector({ form }, 'retirement'),
    selfEmployment: selector({ form }, 'selfEmployment'),
    socialSecurity: selector({ form }, 'socialSecurity'),
    supplementalSecurity: selector({ form }, 'supplementalSecurity'),
    tanf: selector({ form }, 'tanf'),
    trust: selector({ form }, 'trust'),
    unemploymentBenefits: selector({ form }, 'unemploymentBenefits'),
    payFrequency: selector({ form }, 'payFrequency'),
    unearnedIncome17AndUnder: selector({ form }, 'unearnedIncome17AndUnder'),
    incomeFromOnlineSources: selector({ form }, 'incomeFromOnlineSources'),
    pension: selector({ form }, 'pension'),
    unearnedIncome17AndUnderIncome: selector(
      { form },
      'unearnedIncome17AndUnderIncome',
    ),
    incomeFromOnlineSourcesIncome: selector(
      { form },
      'incomeFromOnlineSourcesIncome',
    ),
    pensionIncome: selector({ form }, 'pensionIncome'),
    employmentList: selector({ form }, 'employmentList'),
    otherList: selector({ form }, 'otherList'),
    incomeConfirmation: selector({ form }, 'incomeConfirmation'),
  };

  ALTERNATE_FORM_INFO.income
    .filter(({ name }) => {
      const defaultFilters = name !== 'employment' && name !== 'other';
      if (isMinor?.[applicantId]) {
        return name !== 'unearnedIncome17AndUnder' && defaultFilters;
      }
      return defaultFilters;
    })
    .forEach(({ name }) => {
      const rateName = `${name}PayRate`;
      const hoursWeeksMonthsName = `${name}HoursWeeksMonths`;
      const payFrequencyName = `${name}PayFrequency`;
      statePropsMap[rateName] = selector({ form }, rateName);
      statePropsMap[hoursWeeksMonthsName] = selector(
        { form },
        hoursWeeksMonthsName,
      );
      statePropsMap[payFrequencyName] = selector({ form }, payFrequencyName);
    });

  ALTERNATE_FORM_INFO.childSupport
    .filter(({ name }) => name !== 'employment' && name !== 'other')
    .forEach(({ name }) => {
      const rateName = `${name}PayRate`;
      const hoursWeeksMonthsName = `${name}HoursWeeksMonths`;
      const payFrequencyName = `${name}PayFrequency`;
      statePropsMap[rateName] = selector({ form }, rateName);
      statePropsMap[hoursWeeksMonthsName] = selector(
        { form },
        hoursWeeksMonthsName,
      );
      statePropsMap[payFrequencyName] = selector({ form }, payFrequencyName);
    });

  ALTERNATE_FORM_INFO.misc
    .filter(({ name }) => name !== 'employment' && name !== 'other')
    .forEach(({ name }) => {
      const rateName = `${name}PayRate`;
      const hoursWeeksMonthsName = `${name}HoursWeeksMonths`;
      const payFrequencyName = `${name}PayFrequency`;
      statePropsMap[rateName] = selector({ form }, rateName);
      statePropsMap[hoursWeeksMonthsName] = selector(
        { form },
        hoursWeeksMonthsName,
      );
      statePropsMap[payFrequencyName] = selector({ form }, payFrequencyName);
    });

  return statePropsMap;
};

export const calculateAnnualIncomeForRow = (
  newValues,
  dispatch,
  props,
  previousValues,
) => {
  const keySuffixes = ['PayFrequency', 'PayRate', 'HoursWeeksMonths'];
  const changeCommands = Object.entries(newValues)
    .filter(([key, value]) => {
      const prevValue = previousValues[key];
      return Array.isArray(value) || value !== prevValue;
    })
    .filter(([key, value]) => {
      return (
        Array.isArray(value) ||
        keySuffixes.some((suffix) => key.includes(suffix))
      );
    })
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map((row, index) => {
            return { ...row, originalIndex: index };
          })
          .filter((row, index) => {
            const previousRow = previousValues?.[key]?.[index] ?? {};
            return Object.entries(row).some(([key, value]) => {
              return previousRow[key] !== value;
            });
          })
          .map((row) => {
            const payRate = row?.PayRate ?? 0;
            const payFrequency = row?.PayFrequency ?? '';
            const hoursWeeksMonths = row?.HoursWeeksMonths ?? 0;

            const payFrequencyToWeeksMap = getPayFrequencyToWeeksMap();
            const payFrequencyInWeeks = [
              'weekly',
              'monthly',
              'annually',
            ].includes(payFrequency)
              ? 1 // multiply by multiplicative identity to get accurate result
              : payFrequencyToWeeksMap?.[payFrequency] ??
                payFrequencyToWeeksMap.default;

            const newIncome = calculateAnnualGrossIncomeForRow(
              Number(payRate),
              Number(hoursWeeksMonths),
              payFrequencyInWeeks,
            );

            return () => {
              dispatch(
                props.change(
                  `${key}[${row.originalIndex}].Income`,
                  newIncome.toFixed(2),
                ),
              );
            };
          });
      }

      const prefix = key.replace(/(Pay(Rate|Frequency))|HoursWeeksMonths/, '');
      const payRate = newValues[`${prefix}PayRate`];
      const payFrequency = newValues[`${prefix}PayFrequency`];
      const hoursWeeksMonths = newValues[`${prefix}HoursWeeksMonths`];

      const payFrequencyToWeeksMap = getPayFrequencyToWeeksMap();
      const payFrequencyInWeeks = ['weekly', 'monthly', 'annually'].includes(
        payFrequency,
      )
        ? 1 // multiply by multiplicative identity to get accurate result
        : payFrequencyToWeeksMap?.[payFrequency] ??
          payFrequencyToWeeksMap.default;

      const newIncome = calculateAnnualGrossIncomeForRow(
        Number(payRate),
        Number(hoursWeeksMonths),
        payFrequencyInWeeks,
      );

      return [
        () => {
          dispatch(props.change(`${prefix}Income`, newIncome.toFixed(2)));
        },
      ];
    });

  changeCommands.forEach((command) => command());
};

export default withRouter(
  connect(mapStateToProps)(
    reduxForm({
      form: 'incomeVerification',
      validate,
      enableReinitialize: true,
      onChange: calculateAnnualIncomeForRow,
    })(IncomeVerificationForm),
  ),
);
