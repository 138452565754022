import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.Title',
    defaultMessage: 'Create Misc. Income Payment',
  },
  allFieldsRequired: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.AllFieldsRequired',
    defaultMessage: '* All fields are required',
  },
  cancel: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.Cancel',
    defaultMessage: 'Cancel',
  },
  post: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.Post',
    defaultMessage: 'Post',
  },
  propertyName: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.PropertyName',
    defaultMessage: 'Property Name',
  },
  unitId: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.UnitId',
    defaultMessage: 'Unit Number',
  },
  status: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.Status',
    defaultMessage: 'Status',
  },
  residentName: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.ResidentName',
    defaultMessage: 'Resident Name',
  },
  subjournal: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.Subjournal',
    defaultMessage: 'Subjournal',
  },
  chargeTransactionCode: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.ChargeTransactionCode',
    defaultMessage: 'Charge Code',
  },
  paymentTransactionCode: {
    id:
      'App.ManageMiscTransaction.CreateMiscTransaction.paymentTransactionCode',
    defaultMessage: 'Payment Code',
  },
  date: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.Date',
    defaultMessage: 'Date',
  },
  documentNumber: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.DocumentNumber',
    defaultMessage: 'Document Number',
  },
  note: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.Note',
    defaultMessage: 'Note',
  },
  notePlaceholder: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.NotePlaceholder',
    defaultMessage: '(Optional)',
  },
  priorBalance: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.PriorBalance',
    defaultMessage: 'Prior Balance',
  },
  amount: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.Amount',
    defaultMessage: 'Amount',
  },
  accountBalance: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.AccountBalance',
    defaultMessage: 'New Balance',
  },
  createMiscTransactionPendingHeader: {
    id:
      'App.ManageMiscTransaction.CreateMiscTransaction.createMiscTransactionPendingHeader',
    defaultMessage: 'Pending',
  },
  createMiscTransactionSuccessHeader: {
    id:
      'App.ManageMiscTransaction.CreateMiscTransaction.createMiscTransactionSuccessHeader',
    defaultMessage: 'Success',
  },
  createMiscTransactionFailureHeader: {
    id:
      'App.ManageMiscTransaction.CreateMiscTransaction.createMiscTransactionFailureHeader',
    defaultMessage: 'Error',
  },
  createMiscTransactionPendingDescription: {
    id:
      'App.ManageMiscTransaction.CreateMiscTransaction.createMiscTransactionPendingDescription',
    defaultMessage: 'Misc income payment pending.',
  },
  createMiscTransactionSuccessDescription: {
    id:
      'App.ManageMiscTransaction.CreateMiscTransaction.createMiscTransactionSuccessDescription',
    defaultMessage: 'Misc income payment posted.',
  },
  createMiscTransactionFailureDescription: {
    id:
      'App.ManageMiscTransaction.CreateMiscTransaction.createMiscTransactionFailureDescription',
    defaultMessage: 'Transaction could not be created.',
  },
  yes: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.no',
    defaultMessage: 'No',
  },
  cancelConfirmationHeader: {
    id:
      'App.ManageMiscTransaction.CreateMiscTransaction.cancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to cancel?',
  },
  required: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.Required',
    defaultMessage: 'This field is required',
  },
  validNumber: {
    id: 'App.ManageMiscTransaction.CreateMiscTransaction.Number',
    defaultMessage: 'Please enter a valid number',
  },
});

export default messages;
