import React from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import messages from '../../ApplicationProfile/LeaseApplicationTab/messages';
import CreditCriminalStatus from '../../ApplicationProfile/LeaseApplicationTab/CreditCriminalStatus';
import { LeaseApplication } from '../../ApplicationProfile/LeaseApplicationTab';
import ApprovedResidentContainer from './ApprovedResidentContainer/index';
import type { DecisionStatus } from '../../ApplicationProfile/types';
import type { DropdownOption, User } from '../../App/types';
import ApproveOrDisapproveApplicantForm from './ApproveOrDisapproveApplicantForm';
import ErrorMessage from '../../../components/ErrorMessage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import TheWorkNumber from '../../../components/TheWorkNumber';
import { useOneProperty } from '../../../hooks/data-fetching/useOneProperty';

type Props = {
  intl: Object,
  applicationDecisionStatus: Array<DecisionStatus>,
  applicationStatuses: Array<DropdownOption>,
  applicationId: string,
  screening: Object,
  screeningLetterTypes: Array<Object>,
  generateAdverseActionLetter: Function,
  currentUser: User,
  currentRecord: any,
  handleChecklistOptionChange?: Function,
  handleEditApplicationForm?: Function,
  applicants: Object,
  isResident: boolean,
  checkUserPermissions: Function,
  pendingApplicants: Array<any>,
  canceledApplicants: Array<any>,
  approvedApplicants: Array<any>,
  applicantCancellationReasons: Array<any>,
  approveApplicant: Function,
  cancelApplicant: Function,
  filteredResidents: Array<any>,
  isCompliant: boolean,
  selectedProperty: Object,
  householdId: string,
};

export const CompletionStatusResidentApproved = () => {
  return (
    <div className="container-fluid">
      <Row>
        <Col md={7} xs={12}>
          <h2 className="appmanagertitle">
            <FormattedMessage {...messages.approvedApplications} />
          </h2>
        </Col>
      </Row>
    </div>
  );
};

export const CompletionStatusResident = ({ title, message }: any) => {
  return (
    <div className="container-fluid">
      <Row>
        <h2 className="appmanagertitle padtop20">
          <FormattedMessage {...title} />{' '}
        </h2>
        {message && (
          <small className="padleft10">
            <FormattedMessage {...message} />
          </small>
        )}
      </Row>
    </div>
  );
};

export const LeaseApplicationTab = ({
  intl,
  currentRecord,
  applicationDecisionStatus,
  applicationStatuses,
  handleChecklistOptionChange,
  handleEditApplicationForm,
  approveApplicant,
  cancelApplicant,
  applicationId,
  screening,
  checkUserPermissions,
  currentUser,
  pendingApplicants,
  canceledApplicants,
  applicantCancellationReasons,
  filteredResidents,
  isCompliant,
  generateAdverseActionLetter,
  screeningLetterTypes,
  selectedProperty,
  householdId,
}: Props) => {
  const { theWorkNumber, transunionOffLeaseAppOff } = useFlags();
  let organizationId = currentUser?.user?.organization?.id ?? '';
  let propertyId = selectedProperty?.id ?? '';
  const theWorkNumberProps = {
    organizationId,
    propertyId,
    householdId: householdId ?? '',
    intl,
    currentUser,
  };
  const propertyDetails = useOneProperty(organizationId, propertyId);
  const isTransUnionActive = propertyDetails[0]?.isTransUnionActive;
  const noApplicantToDisplayMessage = 'There are no applications to display';
  return (
    <div className="tab-pane active" id="1a">
      <LeaseApplication
        intl={intl}
        currentRecord={currentRecord}
        applicationDecisionStatus={applicationDecisionStatus}
        applicationStatuses={applicationStatuses}
        isResident={true}
        checkUserPermissions={checkUserPermissions}
      />
      <CreditCriminalStatus
        intl={intl}
        applicationId={applicationId}
        screening={screening}
        generateAdverseActionLetter={generateAdverseActionLetter}
        screeningLetterTypes={screeningLetterTypes}
        currentUser={currentUser}
        isResident={true}
        isTransUnionActive={isTransUnionActive}
        transunionOffLeaseAppOff={transunionOffLeaseAppOff}
      />
      {theWorkNumber && <TheWorkNumber {...theWorkNumberProps} />}
      <CompletionStatusResidentApproved intl={intl} />
      <ApprovedResidentContainer
        {...{
          intl,
          currentRecord: { approvedApplicants: filteredResidents },
        }}
      />
      <CompletionStatusResident
        title={messages.pendingApplications}
        message={messages.cannotScreenPending}
      />
      <ApproveOrDisapproveApplicantForm
        {...{
          intl,
          currentRecord: { applicants: pendingApplicants },
          applicationDecisionStatus,
          handleChecklistOptionChange,
          approveApplicant,
          cancelApplicant,
          handleEditApplicationForm,
          isResident: false,
          deniedReasons: applicantCancellationReasons,
          isCompliant,
        }}
      />

      {pendingApplicants.length === 0 && (
        <ErrorMessage message={noApplicantToDisplayMessage} />
      )}
      <CompletionStatusResident title={messages.canceledApplications} />
      <ApproveOrDisapproveApplicantForm
        {...{
          intl,
          currentRecord: { applicants: canceledApplicants },
          applicationDecisionStatus,
          handleChecklistOptionChange,
          approveApplicant,
          cancelApplicant,
          handleEditApplicationForm,
          isResident: false,
          isCanceled: true,
          deniedReasons: applicantCancellationReasons,
          isCompliant,
        }}
      />
      {canceledApplicants.length === 0 && (
        <ErrorMessage message={noApplicantToDisplayMessage} />
      )}
    </div>
  );
};

export default reduxForm({
  form: 'LeaseApplicationTab',
  enableReinitialize: true,
})(LeaseApplicationTab);
