import React from 'react';
import cn from 'classnames';
import { Field, reduxForm, type FormProps } from 'redux-form';
import { Form } from 'react-bootstrap';
type Props = {
  className?: string,
  placeholder?: string,
  disabled?: boolean,
};

const SearchField = ({
  className,
  placeholder,
  handleSubmit,
  formClasses,
  disabled = false,
}: FormProps & Props) => (
  <div className={cn('search-input', className)}>
    <Form onSubmit={handleSubmit} disabled={disabled}>
      <Field
        data-testid={'searchField'}
        className={`input-lg ${
          typeof formClasses !== 'undefined' ? formClasses : ''
        }`}
        component="input"
        type="search"
        name="searchText"
        placeholder={placeholder}
        disabled={disabled}
      />
    </Form>
    <button
      data-testid={'searchFieldSubmitButton'}
      onClick={handleSubmit}
      disabled={disabled}
    >
      <i className="et-search" />
    </button>
  </div>
);

export default reduxForm()(SearchField);
