export const GET_APPLICANT_SECONDARY_FORM_INFORMATION =
  'app/NonPrimaryLeaseApplication/GET_APPLICANT_SECONDARY_FORM_INFORMATION';
export const GET_APPLICANT_SECONDARY_FORM_INFORMATION_SUCCESS =
  'app/NonPrimaryLeaseApplication/GET_APPLICANT_SECONDARY_FORM_INFORMATION_SUCCESS';
export const GET_APPLICANT_SECONDARY_FORM_INFORMATION_ERROR =
  'app/NonPrimaryLeaseApplication/GET_APPLICANT_SECONDARY_FORM_INFORMATION_ERROR';

export const SAVE_SECONDARY_FORM =
  'app/NonPrimaryLeaseApplication/SAVE_SECONDARY_FORM';
export const SAVE_SECONDARY_FORM_SUCCESS =
  'app/NonPrimaryLeaseApplication/SAVE_SECONDARY_FORM_SUCCESS';
export const SAVE_SECONDARY_FORM_ERROR =
  'app/NonPrimaryLeaseApplication/SAVE_SECONDARY_FORM_ERROR';

export const CLEAN_APPLICANT_SECONDARY_FORM_INFORMATION =
  'app/NonPrimaryLeaseApplication/CLEAN_APPLICANT_SECONDARY_FORM_INFORMATION';

export const DEFAULT_EMPTY_EMPLOYER_INFO = {
  address: {
    city: '',
    state: '',
    street: '',
    zipCode: '',
  },
  annualSalary: '',
  employerName: '',
  from: '',
  phoneNumber: '',
  position: '',
  supervisorName: '',
  to: void 0,
};

export const FORM_NAME = 'nonPrimaryForm';
