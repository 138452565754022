/* eslint-disable max-len */

import { get, post } from '../utils/api';
import { isNil, isEmpty } from 'ramda';
export default class MasterTransactionCodesService {
  getOrganizationMasterTransactionCodes(
    organizationId: string,
    search: string,
    options?: Object,
  ) {
    const requestOptions = {
      ...options,
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const query = isNil(search) || isEmpty(search) ? '' : `?search=${search}`;
    return get(
      `/${organizationId}/master-transaction-codes${query}`,
      requestOptions,
    );
  }
  getOneMasterTransactionCode(
    organizationId: string,
    masterTransactionCodeId: string,
    options?: Object,
  ) {
    const requestOptions = {
      ...options,
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return get(
      `/${organizationId}/master-transaction-codes/${masterTransactionCodeId}`,
      requestOptions,
    );
  }
  setMasterTransactionCodeToProperties(
    organizationId: string,
    masterTransactionCode: string,
    properties: Array<string>,
    options?: Object,
  ) {
    const requestOptions = {
      ...options,
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const body = JSON.stringify({ properties });
    return post(
      `/${organizationId}/property-transaction-codes/enable/${masterTransactionCode}`,
      body,
      requestOptions,
    );
  }
}
