import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { PlusIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import messages from '../messages';

const AddNewVoucherButton = ({
  organizationId,
  propertyId,
  householdId,
  disabled,
  intl,
  handleOpenModal,
}) => {
  return (
    <Button
      variant={'shout'}
      disabled={disabled}
      intl={intl}
      startIcon={<PlusIcon />}
      onClick={() => {
        handleOpenModal({
          organizationId,
          propertyId,
          householdId,
          modalType: 'createVoucher',
        });
      }}
    >
      <FormattedMessage {...messages.addNewVoucher} />
    </Button>
  );
};

export default AddNewVoucherButton;
