import { renderTimeField } from '../../../../utils/redux-form-helper';
import type Moment from 'moment';

type Props = {
  hidden: boolean,
  error?: string,
  name: string,
  value: string | Moment,
  onChange: Function,
};

export const TimeInput = ({ hidden, error, name, value, onChange }: Props) =>
  renderTimeField({
    bsSize: 'md',
    hidePicker: hidden,
    meta: {
      error,
      touched: true,
    },
    input: {
      onChange,
    },
    value,
    isControlled: true,
  });
