import { createSelector } from 'reselect';
import {
  compose,
  concat,
  defaultTo,
  filter,
  find,
  isNil,
  pathOr,
  prop,
  propEq,
  sortBy,
  toLower,
} from 'ramda';
import type { GlobalState } from '../App/types';
import moment from 'moment';
import {
  calculateRentWithAmenities,
  DEFAULT_APPLICANT_UNIT,
} from '../../utils/lease-helpers';

const getCurrentApplicationState = (state: GlobalState) =>
  state.applicationProfile;

const getManageDocumentsState = (state: GlobalState) => state.manageDocuments;
const getAppState = (state: GlobalState) => state.app;

const defaultToDefault = defaultTo('default');

const defaultProspect = {
  id: '',
  additionalOccupants: [],
  prospectPreferences: {
    moveInDateFrom: null,
    moveInDateTo: null,
  },
};

export const getScreeningLetterTypes = createSelector(
  getManageDocumentsState,
  ({ documentTypes }) => {
    return documentTypes.filter((doc) => doc.type === 'Screening Letter');
  },
);

export const getLateMethods = createSelector(
  getCurrentApplicationState,
  ({ currentApplication }) => {
    // $FlowFixMe
    const lateMethods = pathOr(null, ['lateMethods'], currentApplication);
    if (!lateMethods) {
      return [
        {
          value: 'default',
          text: 'Choose',
          disabled: true,
        },
      ];
    }
    return [
      {
        value: 'default',
        text: 'Choose',
        disabled: true,
      },
      ...lateMethods.map((lm) => ({
        value: lm.id,
        text: lm.masterLateMethod.lateMethodDescription,
        disabled: false,
      })),
    ];
  },
);

export const getSecurityDeposits = createSelector(
  getCurrentApplicationState,
  ({ currentApplication }) => {
    // $FlowFixMe
    const securityDepositAlternatives = pathOr(
      null,
      ['securityDepositAlternatives'],
      currentApplication,
    );
    if (!securityDepositAlternatives) {
      return [
        {
          value: 'default',
          text: 'Choose',
          disabled: true,
        },
      ];
    }
    return [
      {
        value: 'default',
        text: 'Choose',
        disabled: false,
      },
      ...securityDepositAlternatives.map((lm) => ({
        value: lm.id,
        text: lm.masterSecurityPropertyAlternative.securityDepositDesc,
        disabled: false,
      })),
    ];
  },
);

export const getApplication = createSelector(
  getCurrentApplicationState,
  ({ currentApplication }) => {
    if (!currentApplication || !currentApplication.prospectInfo) {
      return {
        ...currentApplication,
        prospectInfo: defaultProspect,
        au: DEFAULT_APPLICANT_UNIT,
      };
    } else {
      const prospect = currentApplication.prospectInfo;
      const preferences = prospect.prospectPreferences;
      const moveInDateFrom = preferences.moveInDateFrom
        ? moment(preferences.moveInDateFrom)
        : null;
      const moveInDateTo = preferences.moveInDateTo
        ? moment(preferences.moveInDateTo)
        : null;
      // $FlowFixMe
      const lease = pathOr(
        DEFAULT_APPLICANT_UNIT.lease,
        ['au', 'lease'],
        currentApplication,
      );
      // $FlowFixMe
      const unit = pathOr(
        DEFAULT_APPLICANT_UNIT.unit,
        ['au', 'unit'],
        currentApplication,
      );
      // $FlowFixMe
      const leaseRentPercentage = pathOr(
        '20',
        ['property', 'leaseRentPercentage'],
        currentApplication,
      );
      // $FlowFixMe
      let leasedRent = pathOr(
        null,
        ['au', 'lease', 'leasedRent'],
        currentApplication,
      );
      if (!leasedRent) {
        leasedRent = pathOr(
          pathOr(null, ['floorPlan', 'quotingRentAmount'], unit),
          ['quotingRentAmount'],
          unit,
        );
        leasedRent = calculateRentWithAmenities(unit, +leasedRent);
      }
      return {
        ...currentApplication,
        prospectInfo: {
          ...currentApplication.prospectInfo,
          prospectPreferences: {
            ...currentApplication.prospectInfo.prospectPreferences,
            moveInDateFrom,
            moveInDateTo,
          },
        },
        lease: {
          ...lease,
          lateMethodId: defaultToDefault(lease.lateMethodId),
          leaseTermId: defaultToDefault(lease.leaseTermId),
          securityDepositAlternativeId: defaultToDefault(
            lease.securityDepositAlternativeId,
          ),
          numberOfKeys: defaultToDefault(lease.numberOfKeys),
          startDate: lease.startDate ? moment(lease.startDate) : '',
          moveInDate: lease.moveInDate ? moment(lease.moveInDate) : '',
          endDate: lease.endDate
            ? moment(lease.endDate).format('MM/DD/YYYY')
            : '',
          unitId: unit.id,
          unitNumber: unit.number,
          floorPlan: unit.floorPlan ? unit.floorPlan.marketingName : '',
          leasedRent,
        },
        labelEndDate: currentApplication.labelEndDate
          ? currentApplication.labelEndDate
          : lease.endDate
          ? moment(lease.endDate).format('MM/DD/YYYY')
          : undefined,
        basicLeaseFees: currentApplication.basicLeaseFees,
        leaseRentPercentage,
      };
    }
  },
);

export const getCurrentUser = createSelector(getAppState, ({ currentUser }) => {
  let permissions = [];
  if (currentUser && currentUser.permissions) {
    permissions = currentUser.permissions;
  }
  return { ...currentUser, permissions };
});

export const getApplicationHousehold = createSelector(
  getApplication,
  (application) => {
    let householdMembers = [];
    if (application) {
      const currentApplicants = application.applicants || [];
      const adultMembers = filter(
        (a) => !isNil(a.applicantCustomer),
        currentApplicants,
      ).map((adult) => {
        const { relationship, applicantCustomer } = adult;
        const customer = pathOr({}, ['customer'], applicantCustomer);
        const {
          firstName,
          lastName,
          suffix,
          pu,
          emailAddress,
          preferredName,
          status,
        } = customer;
        return {
          id: adult.id,
          name: [firstName, lastName, suffix ? suffix.name : ''].join(' '),
          relationship: relationship ? relationship.name : '---',
          portalUser: pu,
          customerId: customer.id,
          emailAddress,
          type: 'adult',
          firstName: firstName,
          preferredName: preferredName ? preferredName : '---',
          status,
          isPrimary: applicantCustomer.isProspect,
        };
      });
      const minorMembers = filter(
        (a) => !isNil(a.applicantMinor),
        currentApplicants,
      ).map((minor) => ({
        id: minor.id,
        name: [
          minor.applicantMinor.firstName,
          minor.applicantMinor.lastName,
          minor.applicantMinor.suffix ? minor.applicantMinor.suffix.name : '',
        ].join(' '),
        relationship: minor.relationship ? minor.relationship.name : '---',
        type: 'minor',
      }));
      const petMembers = filter(
        (a) => !isNil(a.applicantPet),
        currentApplicants,
      ).map((pet) => ({
        id: pet.id,
        name: `${pet.applicantPet.name}`,
        isServiceAnimal: pet.applicantPet?.isServiceAnimal,
        relationship: pet.applicantPet.petType
          ? pet.applicantPet.petType.name
          : '---',
        type: 'pet',
      }));
      householdMembers = concat(adultMembers, concat(minorMembers, petMembers));
    }

    return householdMembers;
  },
);

export const getFinanciallyResponsibleApplicants = createSelector(
  getApplication,
  (application) => {
    let FRHouseholdMembers = [];
    const sortByNameCaseInsensitive = sortBy(compose(toLower, prop('name')));
    if (application) {
      const currentApplicants = application.applicants || [];
      const adultMembers = filter(
        (a) => a.applicantType.financiallyResponsible,
        currentApplicants,
      ).map((adult) => {
        return {
          id: adult.id,
          name: [
            adult.applicantCustomer.customer.firstName,
            adult.applicantCustomer.customer.lastName,
            adult.applicantCustomer.customer.suffix
              ? adult.applicantCustomer.customer.suffix.name
              : '',
          ].join(' '),
          type: adult.applicantType.name,
          specialNeedsDesignationIds: adult?.specialNeedsDesignationIds ?? [],
          residentId: adult?.resident?.id,
        };
      });

      FRHouseholdMembers = sortByNameCaseInsensitive(adultMembers);
    }

    return FRHouseholdMembers;
  },
);

/**
 * TODO: Remove when Portal can handle nonfinancial lease signers.
 */
export const getNonfinanciallyResponsibleAdults = createSelector(
  getApplication,
  getAppState,
  (application, app) => {
    let nonFRHouseholdMembers = [];
    const liveInCaretakerApplicantType = app.applicantTypes.find(
      (applicantType) =>
        applicantType.type === 'Adult' &&
        applicantType.name === 'Live-In-Caretaker',
    );
    // $FlowFixMe
    const sortByNameCaseInsensitive = sortBy(compose(toLower, prop('name')));
    if (application) {
      const currentApplicants = application.applicants || [];
      const adultMembers = filter(
        (a) =>
          !a.applicantType.financiallyResponsible &&
          (a?.applicantTypeId !== liveInCaretakerApplicantType?.id ||
            a?.applicantType?.name !== 'Live-In-Caretaker') &&
          a.applicantType.type === 'Adult',
        currentApplicants,
      ).map((adult) => {
        return {
          id: adult.id,
          name: [
            adult.applicantCustomer.customer.firstName,
            adult.applicantCustomer.customer.lastName,
            adult.applicantCustomer.customer.suffix
              ? adult.applicantCustomer.customer.suffix.name
              : '',
          ].join(' '),
          type: adult.applicantType.name,
        };
      });

      nonFRHouseholdMembers = sortByNameCaseInsensitive(adultMembers);
    }

    return nonFRHouseholdMembers;
  },
);

export const getFinanciallyResponsibleFullNames = createSelector(
  getApplication,
  (application) =>
    application && application.applicants
      ? application.applicants
          .filter((a) => a.applicantType.financiallyResponsible)
          .map((a) => a.applicantCustomer)
          .map((ac) => ac.customer)
          .map(
            (c) =>
              `${c.firstName} ${c.middleName ? `${c.middleName} ` : ''}${
                c.lastName
              }${c.suffix ? ` ${c.suffix.name}` : ''}`,
          )
          .sort()
      : [],
);

export const getApplicationStatusOptions = createSelector(
  getCurrentApplicationState,
  getCurrentUser,
  ({ applicationStatuses }, { permissions }) => {
    /**
     * So long as the user has the 'application-status-update' permission they
     * can update the Application Status to 'Approved'
     */
    const canUpdateToApproved = find(
      propEq('scope', 'application-status-update'),
    )(permissions);
    const applicationStatusOptions = applicationStatuses.map(({ id, name }) => {
      /**
       * Specifically disable 'Approved' option if user doesn't have the
       * 'application-status-update' permission. All other statuses are enabled.
       */
      const disabled = name !== 'Approved' ? false : !canUpdateToApproved;
      return {
        value: id,
        text: name,
        disabled,
      };
    });
    return applicationStatusOptions;
  },
);

export const getCustomerId = createSelector(
  getCurrentApplicationState,
  ({ currentApplication }) => {
    return currentApplication ? currentApplication.customerId : '';
  },
);
