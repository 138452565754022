import { useQuery } from 'react-query';
import { gql } from '@apollo/client';

import { useGqlQuery } from '../../../utils/gql';
import HouseholdService from '../../../services/householdService';

export const useCashPayDetails = ({ householdId, propertyId }) => {
  const { userFriendlyId, receiveCode, billerName } =
    useGetHouseholdCashpayDetails({
      householdId,
    });

  const { shouldAcceptCashPay } = useCashPayConfigData({ propertyId });

  return {
    billerName,
    receiveCode,
    userFriendlyId,
    shouldAcceptCashPay,
  };
};

const useGetHouseholdCashpayDetails = ({ householdId }) => {
  const householdService = new HouseholdService();
  const queryKey = 'userFriendlyId';
  const queryFn = async () => {
    const res = await householdService.getHouseholdUserFriendlyId(householdId);
    return res;
  };
  const { data } = useQuery(queryKey, queryFn, {
    refetchOnWindowFocus: false,
  });
  return data?.userFriendlyId
    ? {
        userFriendlyId: data?.userFriendlyId,
        receiveCode: '19136',
        billerName: 'Fortress Tech',
      }
    : {};
};

const useCashPayConfigData = ({ propertyId }) => {
  const [propertyConfigResults] = useGqlQuery(
    gql`
      query FindPropertyConfig($where: JSONObject) {
        testFindPropertyConfig(where: $where) {
          shouldAcceptCashPay
        }
      }
    `,
    {
      where: {
        propertyId,
      },
    },
  );
  const propertyConfig = propertyConfigResults?.[0];
  return {
    shouldAcceptCashPay: propertyConfig?.shouldAcceptCashPay,
  };
};
