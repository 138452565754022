import React from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from './messages';

type Props = {
  onHide: Function,
  onConfirm: Function,
};

const ConfirmDeleteBatchModal = (props: Props) => {
  const { onHide, onConfirm } = props;

  return (
    <div className="static-modal">
      <Modal
        bsSize="small"
        dialogClassName="modal-dialog modal-alert"
        show={true}
        onHide={onHide}
        autoFocus={true}
        handleSubmit={onConfirm}
      >
        <Modal.Body>
          <Row style={{ marginTop: '20px' }}>
            <Col xs={12}>
              <h1 className="text-center">
                <FormattedMessage {...messages.title} />
              </h1>

              <p className="text-center">
                <FormattedMessage {...messages.body} />
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row style={{ marginBottom: '20px' }}>
            <Col xs={6}>
              <Button className="btn btn-default pull-right" onClick={onHide}>
                <FormattedMessage {...messages.no} />
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="btn btn-primary pull-left"
                onClick={onConfirm}
                variant="primary"
              >
                <FormattedMessage {...messages.yes} />
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmDeleteBatchModal;
