import React, { Component } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { reduxForm } from 'redux-form';
import { Field } from 'redux-form';
import ErrorMessage from '../../../components/ErrorMessage';
import { renderTextField } from '../../../utils/redux-form-helper';
import messages from '../messages';
import validate from './validate';

type Props = {
  intl: any,
  history: Object,
  handleSubmit: Function,
  handleReturnToLogin: Function,
  change: Function,
  showErrorMessage: boolean,
  errorMessage: string,
  valid: boolean,
  submitting: boolean,
  emailSent: boolean,
};

type State = {
  rememberMe: boolean,
};

export const ForgotPasswordFormView = ({
  intl,
  handleSubmit,
  showErrorMessage,
  submitting,
  errorMessage,
  valid,
  returnToLogin,
}: any) => {
  return (
    <Form className="forgotPasswordForm" onSubmit={handleSubmit}>
      {showErrorMessage && <ErrorMessage message={errorMessage} />}
      <Field
        name="username"
        showLabel={true}
        classes="blue-label pull-left"
        label={intl.formatMessage(messages.yourUsername)}
        component={renderTextField}
        bsSize="lg"
      />
      <Button className="btn-primary" type="submit" disabled={!valid}>
        {intl.formatMessage(messages.sendEmail)}
      </Button>
      <div className="form-group text-center">
        <a className="btn-primary-outline" onClick={returnToLogin}>
          {intl.formatMessage(messages.returnSignIn)}
        </a>
      </div>
    </Form>
  );
};

export const ForgotPasswordEmailSent = ({ intl, returnToLogin }: any) => {
  return (
    <div>
      <div className="login-details text-center">
        <div className="confirm-icon">
          <i className="et-checkbox icon"></i>
        </div>
        <h4>{intl.formatMessage(messages.emailSent)}</h4>
        <p>{intl.formatMessage(messages.emailSentDescription)}</p>
      </div>
      <Button className="btn-primary" onClick={returnToLogin} type="submit">
        {intl.formatMessage(messages.gotoSignIn)}
      </Button>
    </div>
  );
};

export class ForgotPassword extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      rememberMe: false,
    };
  }
  onReturnToLoginClick() {
    this.props.handleReturnToLogin();
    this.props.history.push('/login');
  }

  render() {
    const {
      intl,
      handleSubmit,
      showErrorMessage,
      errorMessage,
      submitting,
      valid,
      emailSent,
    } = this.props;

    return (
      <div>
        <div className="loginwrapper">
          <div className="container-fluid login-container">
            <h1 className="fortress-logo-gray">
              <span>Fortress</span>
            </h1>
            <Row>
              <Col xs={12}>
                {emailSent && (
                  <ForgotPasswordEmailSent
                    intl={intl}
                    returnToLogin={this.onReturnToLoginClick.bind(this)}
                  />
                )}
                {!emailSent && (
                  <div>
                    <div className="login-details text-center">
                      <h4>{intl.formatMessage(messages.forgotPassword)}</h4>
                      <p>
                        {intl.formatMessage(messages.resetPasswordInstructions)}
                      </p>
                    </div>
                    <ForgotPasswordFormView
                      intl={intl}
                      handleSubmit={handleSubmit}
                      valid={valid}
                      submitting={submitting}
                      showErrorMessage={showErrorMessage}
                      errorMessage={errorMessage}
                      returnToLogin={this.onReturnToLoginClick.bind(this)}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
        <div className="footer-login">
          <p className="text-center">{intl.formatMessage(messages.help)}</p>
        </div>
      </div>
    );
  }
}

const ForgotPasswordForm = reduxForm({
  form: 'forgotPassword',
  validate: validate,
})(ForgotPassword);

export default ForgotPasswordForm;
