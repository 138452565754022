import { validateRequired } from '../../../utils/validations';
import messages from './messages';

const validate = (values: Object, { intl }: Object) => {
  const errors = {};
  let noEndDateSelected = false;
  let requiredValues = ['endDate', 'batchFile'];
  if (values['batchFile']) {
    const doctype = values['batchFile'].split('.').pop();
    if (doctype !== 'csv') {
      errors['batchField'] = 'Document must be a CSV';
    }
  }
  if (values['noEndDate']) {
    noEndDateSelected = true;
  }
  if (noEndDateSelected) {
    requiredValues = requiredValues.filter((x) => x !== 'endDate');
  }

  requiredValues.forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      errors[fieldName] = intl.formatMessage(messages.required);
    }
  });
  return errors;
};

export default validate;
