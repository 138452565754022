import { useEffect, useCallback } from 'react';

export type UseCustomEventParams<DetailType> = {
  eventKey: string,
  listener?: (e: CustomEvent<DetailType>) => void,
  element?: Element,
};

function useCustomEvent<DetailType = any>({
  eventKey,
  listener,
  element = window,
}: UseCustomEventParams<DetailType>) {
  const emit = useCallback(
    (detail?: DetailType) => {
      element.dispatchEvent(new CustomEvent(eventKey, { detail }));
    },
    [eventKey, element],
  );

  useEffect(() => {
    if (listener) element.addEventListener(eventKey, listener);
    return () => {
      if (listener) element.removeEventListener(eventKey, listener);
    };
  }, [listener, eventKey, element]);

  return { emit };
}

export default useCustomEvent;
