import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import { FormattedNumber } from 'react-intl';
import {
  renderSelectFieldTwoColumns,
  renderTextFieldTwoColumns,
  renderCurrencyField,
  renderSearchDropDown,
  required,
  requiredSelect,
  requiredString,
  positive,
  maxlength,
} from '../../utils/redux-form-helper';
import {
  ascend,
  eqProps,
  prop,
  propEq,
  sortWith,
  uniqWith,
  isEmpty,
  isNil,
} from 'ramda';
import { householdStatuses } from './constants';

type FormProps = {
  amount: number,
  change: Function,
  handleSubmit: Function,
};

type ReduxFormProps = {
  form: string,
  handleSubmit: Function,
  initialValues: Object,
  resetSection: Function,
};

type Props = {
  date?: string,
  financiallyResponsibleNames: string[],
  formValues: Object,
  frNames: Object[],
  onSubmit: Function,
  currentResidentLedgerBalance: number,
  propertyName: string,
  subjournals: Object[],
  propertyCodes: Object[],
  units: Object[],
  allUnits: Object[],
  onHouseholdStatusChange: Function,
} & FormProps &
  ReduxFormProps;

const noteMax = 50;
const docNumMax = 25;

const defaultOption = { value: 'default', text: 'Choose', disabled: true };

const docNumLength = maxlength(
  docNumMax,
  `Document number cannot exceed ${docNumMax} characters.`,
);
const noteLength = maxlength(
  noteMax,
  `Note cannot exceed ${noteMax} characters.`,
);

class PaymentForm extends React.Component<Props, { searchedValue: string }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchedValue: {},
    };
  }
  componentDidUpdate(prevProps) {
    const { searchedValue } = this.state;
    const { submitSucceeded } = this.props;
    if (prevProps.triggerSubmit && searchedValue !== {} && submitSucceeded) {
      this.resetSearch();
    }
  }

  componentDidMount() {
    const {
      editMode,
      initialValues: { unitId, receivedFrom, householdId },
    } = this.props;
    const { searchedValue } = this.state;
    if (editMode && isEmpty(searchedValue) && !isEmpty(unitId)) {
      // Set the "searched" value when loading an existing payment
      this.setState({
        searchedValue: {
          value: {
            unitId,
            receivedFrom,
            householdId,
          },
          label: `${this.getUnitNumber(unitId)} - ${receivedFrom}`,
        },
      });
    }
  }

  onSubjournalSelect = () => {
    const { resetSection, change } = this.props;
    resetSection('transactionCode');
    change('transactionCode', 'default');
  };
  onHouseholdStatusChange = () => {
    const { resetSection, change } = this.props;
    resetSection('subjournal', 'receivedFrom', 'transactionCode', 'unitId');
    change('householdId', null);
    change('subjournal', 'default');
    change('receivedFrom', 'default');
    change('unitId', null);
    change('transactionCode', 'default');
    this.resetSearch();
  };
  resetSearch = () => {
    this.setState({ searchedValue: {} });
  };

  codeOptions = () => {
    const {
      change,
      formValues: { subjournal },
      propertyCodes,
    } = this.props;
    const sortArray: Array<any> = [ascend(prop('code'))];
    const codeOptions = sortWith(
      sortArray,
      propertyCodes.filter(propEq('subjournalId', subjournal)),
    ).map((code) => ({
      value: code.id,
      text: `${code.code} - ${code.description}`,
      disabled: false,
    }));
    if (codeOptions.length !== 1) {
      codeOptions.unshift(defaultOption);
    } else {
      change('transactionCode', codeOptions[0].value);
    }
    return codeOptions;
  };

  receivedFromOptions = () => {
    const { frNames } = this.props;
    const fromOptions = frNames.map((name) => ({
      value: name,
      text: name,
      disabled: false,
    }));
    fromOptions.unshift(defaultOption);

    return fromOptions;
  };

  subjournalOptions() {
    const { change, subjournals } = this.props;
    const sortArray: Array<any> = [ascend(prop('name'))];
    const subjournalOptions = sortWith(sortArray, subjournals).map(
      (subjournal) => ({
        value: subjournal.id,
        text: subjournal.name,
        disabled: false,
      }),
    );
    if (subjournalOptions.length !== 1) {
      subjournalOptions.unshift(defaultOption);
    } else {
      change('subjournal', subjournalOptions[0].value);
    }
    return subjournalOptions;
  }

  unitOptions = () => {
    const { units } = this.props;
    const sortArray: Array<any> = [ascend(prop('number'))];
    const unitOptions = sortWith(sortArray, units).map((unit) => ({
      value: unit.id,
      text: unit.number,
      disabled: false,
    }));
    unitOptions.unshift(defaultOption);
    return uniqWith(eqProps, unitOptions); // filter unique units
  };
  getUnitNumber(unitId: string) {
    const { allUnits } = this.props;
    const selectedUnit = allUnits.find(propEq('id', unitId));
    if (!selectedUnit || selectedUnit.length === 0) return 'N/A';
    return selectedUnit.number;
  }

  onSearchedValueSelect = async (ev: any) => {
    const { resetSection, change } = this.props;
    const { unitId, receivedFrom, householdId } = ev.value;
    resetSection('subjournal', 'transactionCode');
    this.setState({
      searchedValue: ev,
    });
    change('householdId', householdId);
    change('unitId', unitId);
    change('receivedFrom', receivedFrom);
    change('transactionCode', 'default');
    change('subjournal', 'default');
  };

  getSearchedValue = (formValues, searchableValues) => {
    return formValues.unitId && formValues.receivedFrom
      ? searchableValues.find(
          (val) =>
            val.value.unitId === formValues.unitId ||
            (isNil(val.value.unitId) &&
              val.value.receivedFrom === formValues.receivedFrom),
        )
      : {};
  };

  render() {
    const {
      amount = 0,
      date,
      currentResidentLedgerBalance,
      propertyName,
      searchableValues,
      handleSubmit,
    } = this.props;
    const numberFormatStyle = { value: 'currency' };
    return (
      <form onSubmit={handleSubmit}>
        <div className="row form-group">
          <div className="col-md-4 col-xs-12">
            <label>Property Name</label>
          </div>
          <div className="col-md-8 col-xs-12">{propertyName}</div>
        </div>
        <Field
          name="householdStatus"
          col1={[4, 12]}
          col2={[8, 12]}
          label="Household Status"
          options={householdStatuses}
          onChange={this.onHouseholdStatusChange}
          component={renderSelectFieldTwoColumns}
          validate={requiredSelect}
        />
        <div className="row form-group">
          <div className="col-md-4 col-xs-12">
            <label className="padtop10">Unit / Resident</label>
          </div>
          <div className="col-md-8 col-xs-12 nopad">
            <Field
              classNames="search-actions searchbox-default searchbox__hasautocomplete padleft5"
              component={renderSearchDropDown}
              name="unitSearch"
              placeholder="Search Units"
              onChangeCallback={this.onSearchedValueSelect}
              options={searchableValues}
              bsSize="lg"
              fieldValue={this.state.searchedValue}
            />
          </div>
        </div>
        <Field
          name="unitId"
          col1={[4, 12]}
          col2={[8, 12]}
          label="Selected Unit"
          options={this.unitOptions()}
          component={renderSelectFieldTwoColumns}
          validate={requiredSelect}
          disabled={true}
          rowClasses={'hidden'}
        />
        <Field
          name="householdId"
          col1={[4, 12]}
          col2={[8, 12]}
          label="Household"
          component={renderTextFieldTwoColumns}
          validate={requiredSelect}
          disabled={true}
          rowClasses={'hidden'}
        />
        <Field
          name="receivedFrom"
          col1={[4, 12]}
          col2={[8, 12]}
          label="Selected Name"
          options={this.receivedFromOptions()}
          component={renderSelectFieldTwoColumns}
          validate={requiredSelect}
          disabled={true}
        />

        <Field
          name="subjournal"
          col1={[4, 12]}
          col2={[8, 12]}
          label="Subjournal*"
          options={this.subjournalOptions()}
          onChange={this.onSubjournalSelect}
          component={renderSelectFieldTwoColumns}
          validate={requiredSelect}
        />
        <div className="row form-group">
          <div className="col-md-4 col-xs-12">
            <label>Transaction Type</label>
          </div>
          <div className="col-md-8 col-xs-12">Payment</div>
        </div>

        <Field
          name="transactionCode"
          col1={[4, 12]}
          col2={[8, 12]}
          label="Code*"
          options={this.codeOptions()}
          onChange={() => {}}
          component={renderSelectFieldTwoColumns}
          validate={requiredSelect}
        />
        <div className="row form-group">
          <div className="col-md-4 col-xs-12">
            <label>Date</label>
          </div>
          <div className="col-md-8 col-xs-12">{date || ''}</div>
        </div>
        <Field
          name="documentNumber"
          component={renderTextFieldTwoColumns}
          col1={[4, 12]}
          col2={[8, 12]}
          label="Document Number*"
          autoComplete="off"
          maxLength={docNumMax}
          minLength={1}
          validate={[requiredString, docNumLength]}
        />
        <Field
          name="note"
          component={renderTextFieldTwoColumns}
          col1={[4, 12]}
          col2={[8, 12]}
          label="Note"
          placeholder="(Optional)"
          maxLength={noteMax}
          validate={noteLength}
        />
        <div className="row form-group">
          <div className="col-md-4 col-xs-12">
            <label>Prior Balance</label>
          </div>
          <div className="col-md-8 col-xs-12 text-right">
            <strong>
              <FormattedNumber
                value={currentResidentLedgerBalance}
                style={numberFormatStyle.value}
                currency="USD"
              />
            </strong>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4 col-xs-12">
            <label>Transaction Amount*</label>
          </div>
          <div className="col-md-8 col-xs-12">
            <div className="form-group">
              <Field
                name="amount"
                component={renderCurrencyField}
                className="text-right"
                placeholder="0.00"
                step="0.01"
                validate={[required, positive]}
              />
            </div>
          </div>
        </div>
        <div className="alert alert-info">
          <div className="row">
            <div className="col-md-6 col-xs-12">Account Balance</div>
            <div className="col-md-6 col-xs-12 text-right">
              <FormattedNumber
                value={currentResidentLedgerBalance - amount}
                style={numberFormatStyle.value}
                currency="USD"
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const selector = formValueSelector('paymentForm');

export default connect(
  (state) => ({
    amount: selector(state, 'amount'),
  }),
  {},
)(
  reduxForm({
    form: 'paymentForm',
    enableReinitialize: true,
  })(PaymentForm),
);
