import React, { useState, useEffect } from 'react';
import messages from '../messages';
import ConfirmModal from '../../../../components/ConfirmModal';
import { FormattedMessage } from 'react-intl';
import { Switch } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Button } from 'react-bootstrap';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { getIntegrationFromList } from '../utils';
import SetupGridItem from '../../SetupGridItem';
import OnOffTypography from '../../OnOffTypography';

type Props = {
  integrationFormValue: Object,
  integrationName: string,
  organizationIntegrations: Array<Object>,
  propertyIntegrations: Array<Object>,
  integrationKey: string,
  useModal: boolean,
  modalBody: Object,
  modalMessage: string,
  showTooltip: boolean,
  tooltipMessage: string,
  editMode: boolean,
  onChange: Function,
};

const GenericIntegrationSetup = (props: Props) => {
  const {
    editMode,
    onChange,
    organizationIntegrations,
    propertyIntegrations,
    integrationFormValue,
    useModal = true,
    integrationKey,
    integrationName,
    modalBody = null,
    modalMessage,
    tooltipMessage = '',
  } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isActive, setIsActive] = useState(false);
  // Check conservice integrations:
  const propIntegrationDetails = getIntegrationFromList(
    propertyIntegrations,
    integrationName,
  );
  const orgIntegrationDetails = getIntegrationFromList(
    organizationIntegrations,
    integrationName,
  );

  useEffect(() => {
    setIsActive(orgIntegrationDetails?.isActive || false);
    if (orgIntegrationDetails?.isActiveForAllProperties || false) {
      // Org has it set to all true
      setIsActive(orgIntegrationDetails?.isActiveForAllProperties || false);
      setDisabled(true);
    } else {
      if (propIntegrationDetails) {
        // If the property integration exists
        setIsActive(propIntegrationDetails?.isActive || false);
      } else {
        setIsActive(false);
      }
    }
  }, [orgIntegrationDetails, propIntegrationDetails, editMode]);

  const integrationId = orgIntegrationDetails?.integrationId || null;
  const onToggleChange = (evt, value) => {
    const switchValue = evt.target.checked;
    const evtForOnChange = {
      target: {
        name: integrationFormValue,
        value: {
          integrationId,
          value: switchValue,
        },
      },
    };
    // If switch is off show confirmation to toggle on
    if (!isActive) {
      useModal && setShowConfirm(true);
      setIsActive(true);
      onChange(evtForOnChange);
    } else {
      setIsActive(false);
      onChange(evtForOnChange);
    }
  };

  const onAccept = () => {
    // Accepted so close
    setShowConfirm(false);
  };

  const onCancel = () => {
    setIsActive(false);
    const evtForOnChange = {
      target: {
        name: integrationFormValue,
        value: {
          integrationId,
          value: false,
        },
      },
    };
    onChange(evtForOnChange);
    // Hide confirmation
    setShowConfirm(false);
  };

  const onHide = () => {
    setIsActive(false);
    // Hide confirmation
    setShowConfirm(false);
  };

  const modalHeader = (
    <h1>
      <FormattedMessage {...messages.confirmModalTitle} />
    </h1>
  );

  const modalFooter = (
    <div className="row">
      <div className="col-sm-6">
        <Button
          type="button"
          bsStyle="default"
          className="pull-right"
          onClick={onCancel}
        >
          <FormattedMessage {...messages.cancel} />
        </Button>
      </div>
      <div className="col-sm-6">
        <Button
          type="submit"
          bsStyle="primary"
          className="pull-left"
          onClick={onAccept}
        >
          <FormattedMessage {...messages.accept} />
        </Button>
      </div>
    </div>
  );

  const defaultModalBody = (
    <div className="row" style={{ textAlign: 'center' }}>
      <p style={{ margin: '2px 5px' }}>{modalMessage}</p>
    </div>
  );

  return (
    <>
      {useModal && (
        <ConfirmModal
          show={showConfirm}
          header={modalHeader}
          footer={modalFooter}
          onHide={onHide}
        >
          {modalBody ? modalBody : defaultModalBody}
        </ConfirmModal>
      )}
      {integrationId && (
        <SetupGridItem
          label={<FormattedMessage {...messages[integrationKey]} />}
          TooltipProps={
            tooltipMessage
              ? {
                  title: tooltipMessage,
                  children: <AlertInfoIcon />,
                }
              : null
          }
        >
          {editMode ? (
            <Switch
              name={integrationKey}
              id={`${integrationKey}-integration-switch`}
              checked={isActive}
              disabled={disabled}
              onChange={onToggleChange}
              // For automated testing
              inputProps={{ 'data-checked': isActive }}
            />
          ) : (
            <OnOffTypography on={isActive}>
              {isActive ? (
                <FormattedMessage {...messages.on} />
              ) : (
                <FormattedMessage {...messages.off} />
              )}
            </OnOffTypography>
          )}
        </SetupGridItem>
      )}
    </>
  );
};

export default GenericIntegrationSetup;
