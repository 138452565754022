import { pathOr, isNil, isEmpty } from 'ramda';

export const getProgramShortName = (setAsideProgram: string): string => {
  const matches = setAsideProgram.match(/\d+/);
  return !isNil(matches) ? matches[0] : setAsideProgram;
};

export const initializeFormValues = (
  setAsidePrograms: Array<Object>,
): Object => {
  let initialValues = {};
  (setAsidePrograms || []).forEach((program) => {
    const key = pathOr('', ['id'], program);
    const maxIncomePercent = (pathOr(
      '',
      ['maximumIncomePercentage'],
      program,
    ).match(/\d+/) || [])[0];
    const maxRentPercent = (pathOr(
      '',
      ['maximumRentPercentage'],
      program,
    ).match(/\d+/) || [])[0];
    const overIncomeCalc = (pathOr('', ['overIncomeCalc'], program).match(
      /\d+/,
    ) || [])[0];

    initialValues[key] = {
      numberOfUnits: pathOr('', ['numberOfUnits'], program),
      isHUD: pathOr(false, ['isHUD'], program).toString(),
      incomeLimit: pathOr('', ['incomeLimit'], program),
      maximumIncomePercentage: maxIncomePercent || '0',
      maximumRentPercentage: maxRentPercent || '0',
      overIncomeCalc: overIncomeCalc || '0',
    };
  });
  return initialValues;
};

export const reduceFormValues = (
  values: Object,
  setAsidePrograms: Array<Object>,
): Array<Object> => {
  let result = [];
  Object.keys(values).forEach((key) => {
    const program = setAsidePrograms.find(
      (sAProgram) => sAProgram.setAsideProgram.id === key,
    );

    const fullSetAsides = pathOr([], ['fullSetAsides'], program);
    const programValues = values[key];
    result = result.concat(
      fullSetAsides.map((sAProgram) => {
        return {
          id: sAProgram.id,
          incomeLimit: programValues['incomeLimit'],
          isHUD: programValues['isHUD'] === 'true',
          maximumIncomePercentage: `${programValues['maximumIncomePercentage']}%`,
          maximumRentPercentage: `${programValues['maximumRentPercentage']}%`,
          numberOfUnits: isEmpty(programValues['numberOfUnits'])
            ? null
            : parseInt(programValues['numberOfUnits'], 10) || null,
          overIncomeCalc: `${programValues['overIncomeCalc']}%`,
        };
      }),
    );
  });
  return result;
};
