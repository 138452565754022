import * as ActionTypes from './constants';

import type { Action } from '../App/types';

export function getRecertificationFormInformation(
  applicantId: string,
  affordableQualificationId?: string,
): Action<Object> {
  return {
    type: ActionTypes.GET_RECERTIFICATION_FORM_INFORMATION,
    payload: {
      applicantId,
      affordableQualificationId,
    },
  };
}

export function getRecertificationFormInformationSuccess(
  formData: Object,
): Action<any> {
  return {
    type: ActionTypes.GET_RECERTIFICATION_FORM_INFORMATION_SUCCESS,
    payload: formData,
  };
}

export function getRecertificationFormInformationError(
  error: Error,
): Action<Error> {
  return {
    type: ActionTypes.GET_RECERTIFICATION_FORM_INFORMATION_ERROR,
    payload: error,
  };
}

export function saveRecertificationForm(
  recertificationData: any,
  applicantId: string,
  residentId: string,
  applicationId: string,
  programName: string,
  affordableQualificationId: string,
): Action<any> {
  return {
    type: ActionTypes.SAVE_RECERTIFICATION_FORM,
    payload: {
      recertificationData,
      applicantId,
      residentId,
      applicationId,
      programName,
      affordableQualificationId,
    },
  };
}

export function saveRecertificationFormSuccess(): Action<any> {
  return {
    type: ActionTypes.SAVE_RECERTIFICATION_FORM_SUCCESS,
    payload: undefined,
  };
}

export function saveRecertificationFormError(error: Error): Action<Error> {
  return {
    type: ActionTypes.SAVE_RECERTIFICATION_FORM_ERROR,
    payload: error,
  };
}

export function cleanRecertificationFormInformation(): Action<any> {
  return {
    type: ActionTypes.CLEAN_RECERTIFICATION_FORM_INFORMATION,
    payload: undefined,
  };
}

export function printRecertificationApplicationForm(
  organizationId: string,
  propertyId: string,
  applicantId: string,
  affordableQualificationId: string,
  programName: string,
  propertyName: string,
): Action<any> {
  return {
    type: ActionTypes.PRINT_RECERTIFICATION_APPLICATION_FORM,
    payload: {
      organizationId,
      propertyId,
      applicantId,
      affordableQualificationId,
      programName,
      propertyName,
    },
  };
}
