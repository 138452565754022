import { Switch } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { FormattedMessage } from 'react-intl';
import messages from '../../../App/messages';
import { SET_LEASE_END_TO_LAST_MONTH_TOGGLE_NAME } from './constants';
import OnOffTypography from '../../OnOffTypography';
const Control = (props: Props) => {
  const { isEditMode, isSetLeaseEndDateToEndOfMonth, onFormChangeHandler } =
    props;

  const onChange = (event) => {
    const value = event.target.checked;
    const eventForFormChange = {
      target: {
        name: SET_LEASE_END_TO_LAST_MONTH_TOGGLE_NAME,
        value,
      },
    };

    onFormChangeHandler(eventForFormChange);
  };

  const switchProps = {
    defaultChecked: isSetLeaseEndDateToEndOfMonth,
    onChange,
  };
  const editableComponent = <Switch {...switchProps} />;

  const displayComponent = (
    <OnOffTypography on={isSetLeaseEndDateToEndOfMonth}>
      {isSetLeaseEndDateToEndOfMonth ? (
        <FormattedMessage {...messages.on} />
      ) : (
        <FormattedMessage {...messages.off} />
      )}
    </OnOffTypography>
  );

  return isEditMode ? editableComponent : displayComponent;
};

export default Control;
