const { apiURL } = require('../utils/api');

const { request, get } = require('../utils/api');

class CustomersService {
  doNotRenew(
    organizationId: string,
    propertyId: string,
    customers: Array<Object>,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customers,
      }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/customers/do-not-renew`,
      options,
    );
  }
  underEviction(
    organizationId: string,
    propertyId: string,
    customers: Array<Object>,
    leaseId: string,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customers,
        leaseId,
      }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/customers/under-eviction`,
      options,
    );
  }
  updatePaymentRestrictions(
    organizationId: string,
    propertyId: string,
    householdId: string,
    paymentRestrictions: Object,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        householdId,
        paymentRestrictions,
      }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/customers/payment-restrictions`,
      options,
    );
  }
  getWriteOffsForCustomer(
    customerId: string,
    propertyId: string,
    organizationId: string,
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/customers/${customerId}/write-offs`,
      options,
    );
  }

  getPortalStatus(
    customerId: string,
    propertyId: string,
    organizationId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/customers/${customerId}/portal-user/status`,
      options,
    );
  }
  autoEmailMonthlyInvoice(
    organizationId: string,
    propertyId: string,
    householdId: string,
    autoEmailMonthlyInvoices: boolean,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        householdId,
        autoEmailMonthlyInvoices,
      }),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/customers/auto-email-monthly-invoice`,
      options,
    );
  }
}

export default CustomersService;
