import React from 'react';
import { InputLabel } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { AlertInfoIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

const SetupGridItem = ({ label, children, TooltipProps, ...props }) => {
  return (
    <Grid item xs={12} sm={6} {...props}>
      {label && (
        <InputLabel
          TooltipProps={
            TooltipProps
              ? {
                  children: <AlertInfoIcon />,
                  variant: 'light',
                  ...TooltipProps,
                }
              : null
          }
        >
          {label}
        </InputLabel>
      )}
      {children}
    </Grid>
  );
};

export default SetupGridItem;
