import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import LockedLeasePacket from './LockedLeasePacket';
import UnlockedLeasePacket from './UnlockedLeasePacket';
import { updateAddendums } from './reducer';

function LeasePacket(props) {
  return props.isLeaseLocked ? (
    <LockedLeasePacket {...props} />
  ) : (
    <UnlockedLeasePacket {...props} />
  );
}

export default connect(
  (state) => {
    return {
      addendums: state.leasePacket.addendums,
    };
  },
  {
    updateAddendums,
  },
)(injectIntl(LeasePacket));
