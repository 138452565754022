import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import { AddIcon, EditLink } from '../../../components/ProfileVisualComponents';
import DashesIfNullOrUndefined from '../../../components/DashesIfNullOrUndefined';
import FormattedDateOrDashes from '../../../components/FormattedDateOrDashes';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import type { Insurance } from './types';

type OnTypeClickFn = (i: Insurance) => void;
type OnAddClickFn = Function;

const insurancePermissions = ['resident-edit'];

type SectionHeaderProps = {
  onAddClick: OnAddClickFn,
};

const SectionHeader = ({ onAddClick }: SectionHeaderProps) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 13px 8px 13px',
    }}
  >
    <div>
      <i className="icon et-notes" style={{ marginRight: 5 }} />
      <FormattedMessage {...messages.insurance} />
    </div>
    <div>
      <ElementWithPermissions scope={insurancePermissions}>
        <EditLink onClick={onAddClick}>
          <AddIcon />
          <FormattedMessage {...messages.add} />
        </EditLink>
      </ElementWithPermissions>
    </div>
  </div>
);

type InsuranceCheckboxProps = {
  checked: boolean,
  disabled: boolean,
  onClick: Function,
};

const InsuranceCheckbox = ({
  checked,
  disabled,
  onClick,
}: InsuranceCheckboxProps) => (
  <tr>
    <td style={{ padding: '0 0 0 3px' }}>
      <div className="custom-checkbox" style={{ marginBottom: '5px' }}>
        <label>
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onClick={onClick}
          />
          <span className="custom-check-square" />
          <FormattedMessage {...messages.noInsurance} />
        </label>
      </div>
    </td>
  </tr>
);

type InsurancesProps = {
  insurances: Insurance[],
  onTypeClick: OnTypeClickFn,
};

const Insurances = ({ insurances, onTypeClick }: InsurancesProps) => (
  <React.Fragment>
    <tr>
      <th>
        <FormattedMessage {...messages.insuranceType} />
      </th>
      <th>
        <FormattedMessage {...messages.insuranceLimits} />
      </th>
      <th>
        <FormattedMessage {...messages.insuranceExpirationDate} />
      </th>
    </tr>
    {insurances.map((insurance) => (
      <tr key={insurance.id}>
        <td>
          <ElementWithPermissions scope={insurancePermissions}>
            <a onClick={() => onTypeClick(insurance)}>
              <DashesIfNullOrUndefined
                data={insurance.commercialInsuranceType.name}
              />
            </a>
          </ElementWithPermissions>
        </td>
        <td>
          <DashesIfNullOrUndefined data={insurance.limits} />
        </td>
        <td>
          <FormattedDateOrDashes
            value={insurance.expirationDate}
            format="MM/DD/YYYY"
          />
        </td>
      </tr>
    ))}
  </React.Fragment>
);

type InsuranceSectionProps = {
  insurances: Insurance[],
  onAddClick: OnAddClickFn,
  onTypeClick: OnTypeClickFn,
  checkboxChecked: boolean,
  disableCheckbox: boolean,
  onCheckboxClick: Function,
};

const InsuranceSection = ({
  insurances = [],
  onAddClick,
  onTypeClick,
  checkboxChecked,
  disableCheckbox,
  onCheckboxClick,
}: InsuranceSectionProps) => {
  const displayCheckbox = insurances.length === 0;

  return (
    <React.Fragment>
      <SectionHeader onAddClick={onAddClick} />
      <table className="table table-simple">
        <tbody>
          {displayCheckbox ? (
            <InsuranceCheckbox
              checked={checkboxChecked}
              disabled={disableCheckbox}
              onClick={onCheckboxClick}
            />
          ) : (
            <Insurances insurances={insurances} onTypeClick={onTypeClick} />
          )}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default InsuranceSection;
