import { useState, useEffect } from 'react';
import messages from './messages';
import AffordableService from '../../../../../services/affordableService';

export const useBulkEditSetAsidePrograms = (
  organizationId: string,
  propertyId: string,
  formValues: Array<Object>,
  handleClose: Function,
  promptToaster: Function,
  intl: Object,
  refresh: Function,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const updateSetAsidePrograms = async () => {
      setIsSubmitting(true);
      try {
        if (!propertyId || !organizationId || !formValues) {
          throw new Error('Could not edit Set Aside Programs');
        }

        const affordableService = new AffordableService();
        const options = { signal: abortController.signal };
        await affordableService.batchEditSetAsidePrograms(
          organizationId,
          propertyId,
          formValues,
          options,
        );
        promptToaster({
          type: 'success',
          message: intl.formatMessage(messages.setAsideSetupSuccess),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.setAsideSetupError),
          message: err.toString(),
        });
      } finally {
        setIsSubmitting(false);
        handleClose();
        refresh(true);
      }
    };

    if (subscribed && (formValues || []).length > 0) {
      updateSetAsidePrograms();
    }

    return () => {
      subscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  return { isSubmitting };
};
