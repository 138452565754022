import React from 'react';
import ElementWithPermissions from '../ElementWithPermissions';

export const EditLinkWithPermissions = ({
  showEditLink,
  linkFn,
  scope = [],
  disabled = false,
}: Object) => {
  if (showEditLink) {
    return (
      <ElementWithPermissions scope={scope}>
        <a
          disabled={disabled}
          onClick={() => {
            linkFn();
          }}
        >
          <i className="icon et-pencil" />
        </a>
      </ElementWithPermissions>
    );
  } else {
    return null;
  }
};

export default EditLinkWithPermissions;
