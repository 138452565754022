import { defineMessages } from 'react-intl';

const messages = defineMessages({
  createTransactionBatch: {
    id: 'App.CreateTransactionBatchHeader.CreateTransactionBatch',
    defaultMessage: 'Create Transaction Batch',
  },
  batchId: {
    id: 'App.CreateTransactionBatchHeader.batchId',
    defaultMessage: 'Batch ID:',
  },
  period: {
    id: 'App.CreateTransactionBatchHeader.Period',
    defaultMessage: 'Period:',
  },
  batchDate: {
    id: 'App.CreateTransactionBatchHeader.BatchDate',
    defaultMessage: 'Batch Date',
  },
  batchNote: {
    id: 'App.CreateTransactionBatchHeader.BatchNote',
    defaultMessage: 'Batch Note',
  },
  goBack: {
    id: 'App.CreateTransactionBatchHeader.GoBack',
    defaultMessage: 'Back to Manage Transaction Batches',
  },
  editTransactionBatch: {
    id: 'App.CreateTransactionBatchHeader.EditTransactionBatch',
    defaultMessage: 'Edit Transaction Batch',
  },
  goBackCancelConfirmation: {
    id: 'App.CreateTransactionBatchHeader.goBackCancelConfirmation',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  status: {
    id: 'App.CreateTransactionBatchHeader.Status',
    defaultMessage: 'Status',
  },
  viewTransactionBatch: {
    id: 'App.CreateTransactionBatchHeader.ViewTransactionBatch',
    defaultMessage: 'View Transaction Batch',
  },
  addGroup: {
    id: 'App.CreateTransactionBatchHeader.AddGroup',
    defaultMessage: 'Add Group*',
  },
  addGroupNoteResident: {
    id: 'App.CreateTransactionBatchHeader.AddGroupNoteResident',
    defaultMessage: '*Add the same transaction for multiple households.',
  },
  addGroupNoteTenant: {
    id: 'App.CreateTransactionBatchHeader.AddGroupNoteTenant',
    defaultMessage: '*Add the same transaction for multiple units.',
  },
});

export default messages;
