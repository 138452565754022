import React, { useRef, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { find, pathOr, propEq } from 'ramda';
import * as selectors from '../selectors';
import * as actions from '../actions';
import { isNil } from 'ramda';
import * as appSelectors from '../../App/selectors';
import * as residentSelectors from '../selectors';

import type { AdultFieldsProps } from './types';

import {
  renderPhoneField,
  renderEmailField,
  renderTextField,
  phoneParser,
  phoneFormatter,
  required,
  phoneNumber,
  email,
  renderSelectField,
  renderRadioButton,
  validateName,
  validateNonRequiredName,
  requiredSelect,
} from '../../../utils/redux-form-helper';

export const processedAffordableRelationOptions: Function = (
  affordableRelationshipOptions: Array<Object>,
  isPrimaryResident: boolean,
  hohAffRelId: string,
): Array<Object> => {
  return affordableRelationshipOptions.map((affordableRelationOption) => {
    const { value } = affordableRelationOption;
    const disabled = [
      /default/gi.test(value),
      hohAffRelId === value && !isPrimaryResident,
    ].some((condition) => condition);
    return {
      ...affordableRelationOption,
      disabled,
    };
  });
};

export const isAffordableRelationDisabled = ({
  isPrevPrimary,
  affordableRelationshipId,
  isNew,
  isEnabled,
  leaseLocked,
  isPrimaryResident,
  isGuarantorOrLiveInCaretakerLeaseSigner,
}) => {
  return isPrevPrimary || affordableRelationshipId === 'default'
    ? false
    : (!isNew && !isEnabled) ||
        leaseLocked ||
        isPrimaryResident ||
        isGuarantorOrLiveInCaretakerLeaseSigner;
};

const AdultFields = (props: AdultFieldsProps) => {
  const {
    enabledAdults,
    enableAdult,
    disableAdult,
    applicantTypesOptions,
    suffixOptions,
    relationshipOptions,
    affordableRelationshipOptions,
    applicationType,
    isAffordableApplication,
    primaryResidentId,
    onPrimaryChange,
    leaseLocked,
    prevPrimaryId,
    householdHasOpenCerts,
    guarantorOptions,
    liveInCaretakerOptions,
    change,
    fields: propFields,
  } = props;
  const isAffordable =
    applicationType === 'Affordable' ||
    (applicationType === 'Mixed' && isAffordableApplication);

  const hohAffRelOption = find(propEq('text', 'Head of Household'))(
    affordableRelationshipOptions,
  );
  const hohAffRelId = pathOr('default', ['value'], hohAffRelOption);
  const prevTypeRef = useRef([]);
  useEffect(() => {
    prevTypeRef.current = (propFields ?? []).map((field, index) => {
      const adult = propFields.get(index);
      return adult.type;
    });
  }, [propFields]);
  return props.fields.map((field, index) => {
    const { fields } = props;
    const adult = fields.get(index);
    const id = adult.id;
    const isPrimaryResident = primaryResidentId === id;
    const affordableRelationshipId = adult.affordableRelationshipId;
    const isNew = isNil(id);
    const isEnabled = enabledAdults.includes(index);
    const onPencilClickAction = isEnabled ? disableAdult : enableAdult;
    const type = applicantTypesOptions.find(
      (type) => type.value === adult.type,
    );
    const isFinancialResponsibleType = pathOr(
      false,
      ['financiallyResponsible'],
      type,
    );
    const isPrevPrimary = prevPrimaryId === id;
    const isGuarantorOrLiveInCaretakerLeaseSigner = [
      guarantorOptions?.applicantType?.id,
      liveInCaretakerOptions?.applicantType?.id,
    ].includes(type.value);
    const relationshipIdDisabled = isPrevPrimary
      ? false
      : (!isEnabled && !isNew) ||
        primaryResidentId === id ||
        leaseLocked ||
        isGuarantorOrLiveInCaretakerLeaseSigner;
    const affRelationshipIdDisabled = isAffordableRelationDisabled({
      isPrevPrimary,
      affordableRelationshipId,
      isNew,
      isEnabled,
      leaseLocked,
      isPrimaryResident,
      isGuarantorOrLiveInCaretakerLeaseSigner,
    });

    const adultOptions = affordableRelationshipOptions.filter(
      (ar) => ar.isAdult,
    );

    const onTypeChange = (event) => {
      const value = event.target.value;
      const isGuarantorLeaseSignerType =
        value === guarantorOptions?.applicantType?.id;
      const isLiveInCaretakerType =
        value === liveInCaretakerOptions?.applicantType?.id;
      const options = isGuarantorLeaseSignerType
        ? guarantorOptions
        : isLiveInCaretakerType
        ? liveInCaretakerOptions
        : {};

      if (isGuarantorLeaseSignerType || isLiveInCaretakerType) {
        change(`${field}.relationshipId`, options?.relationship?.id);
        change(
          `${field}.affordableRelationshipId`,
          options?.affordableRelationship?.id,
        );
      }

      const isPrevTypeGuarantorOrLiveInCaretaker = [
        guarantorOptions?.applicantType?.id,
        liveInCaretakerOptions?.applicantType?.id,
      ].includes(prevTypeRef.current?.[index]);
      if (
        isPrevTypeGuarantorOrLiveInCaretaker &&
        !(isGuarantorLeaseSignerType || isLiveInCaretakerType)
      ) {
        change(`${field}.relationshipId`, 'default');
        change(`${field}.affordableRelationshipId`, 'default');
      }
    };

    return [
      <div className="container-of-inputs" key={field}>
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.type`}
            component={renderSelectField}
            options={applicantTypesOptions}
            disabled={(!isEnabled && !isNew) || leaseLocked}
            validate={requiredSelect}
            onChange={onTypeChange}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.firstName`}
            component={renderTextField}
            disabled={!isNew || leaseLocked}
            validate={[required, validateName]}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.middleName`}
            component={renderTextField}
            disabled={!isNew || leaseLocked}
            validate={[validateNonRequiredName]}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.lastName`}
            component={renderTextField}
            validate={[required, validateName]}
            disabled={!isNew || leaseLocked}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.suffix`}
            component={renderSelectField}
            options={suffixOptions}
            disabled={!isNew || leaseLocked}
          />
        </Col>
        <Col xs={6} sm={1}>
          <Field
            name={`${field}.preferredName`}
            component={renderTextField}
            disabled={!isNew || leaseLocked}
            validate={[validateNonRequiredName]}
          />
        </Col>

        <Col xs={6} sm={1} className="text-center">
          <Field
            key={`is-primary-radio-${index}`}
            name="primaryResidentId"
            option={{ value: id }}
            component={renderRadioButton}
            disabled={
              isNew || !isFinancialResponsibleType || householdHasOpenCerts
            }
            props={{
              input: {
                name: 'primaryResidentId',
                onChange: () => {
                  onPrimaryChange(id, field, hohAffRelId);
                },
                value: primaryResidentId,
              },
            }}
          />
        </Col>

        <Col xs={6} sm={isAffordable ? 1 : 2}>
          <Field
            name={`${field}.relationshipId`}
            component={renderSelectField}
            options={relationshipOptions}
            disabled={relationshipIdDisabled}
            validate={primaryResidentId === id ? undefined : requiredSelect}
          />
        </Col>
        {isAffordable && (
          <Col xs={6} sm={1}>
            <Field
              name={`${field}.affordableRelationshipId`}
              component={renderSelectField}
              options={processedAffordableRelationOptions(
                adultOptions,
                isPrimaryResident,
                hohAffRelId,
              )}
              disabled={affRelationshipIdDisabled}
              validate={requiredSelect}
            />
          </Col>
        )}

        <Col xs={6} sm={1}>
          <Field
            name={`${field}.phoneNumber`}
            component={renderPhoneField}
            parse={phoneParser}
            format={phoneFormatter}
            validate={[required, phoneNumber]}
            disabled={(!isEnabled && !isNew) || leaseLocked}
          />
        </Col>
        <Col xs={6} sm={2}>
          <Field
            name={`${field}.emailAddress`}
            component={renderEmailField}
            validate={email}
            maxLength="70"
            disabled={(!isEnabled && !isNew) || leaseLocked}
          />
        </Col>
      </div>,
      <div className="container-of-trash" key={`${field}-trash`}>
        <div className="row-remove-btn">
          <a onClick={() => onPencilClickAction(index)} disabled={leaseLocked}>
            <i className="et-pencil" />
          </a>
          <a
            style={{ marginLeft: '10%' }}
            onClick={() => props.deleteAdult(index)}
            disabled={primaryResidentId === id || leaseLocked}
          >
            <i className="et-trash" />
          </a>
        </div>
      </div>,
    ];
  });
};

export default connect(
  createStructuredSelector({
    readOnlyData: selectors.getAdultsReadData,
    enabledAdults: selectors.getEnabledAdults,
    applicantTypesOptions: appSelectors.getApplicantTypeOptions,
    suffixOptions: appSelectors.getNameSuffixOptions,
    relationshipOptions: appSelectors.getRelationshipOptions,
    affordableRelationshipOptions:
      appSelectors.getAffordableRelationshipOptions,
    applicationType: appSelectors.getSelectedPropertyClassType,
    isAffordableApplication: residentSelectors.isAffordableApplication,
    guarantorOptions: appSelectors.getGuarantorOptions,
    liveInCaretakerOptions: appSelectors.getLiveInCaretakerOptions,
  }),
  {
    enableAdult: actions.enableAdultEdit,
    disableAdult: actions.disableAdultEdit,
  },
)(AdultFields);
