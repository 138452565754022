import { defineMessages } from 'react-intl';

const messages = defineMessages({
  totalMonthlyChargesLabel: {
    id: 'App.LeaseDataTabForm.Reconciliation.TotalMonthlyChargesLabel',
    defaultMessage: 'Total Monthly Charges ',
  },
  differenceLabel: {
    id: 'App.LeaseDataTabForm.Reconciliation.DifferenceLabel',
    defaultMessage: 'Difference',
  },
  requiredMonthlyLeaseChargesLabel: {
    id: 'App.LeaseDataTabForm.Reconciliation.RequiredMonthlyLeaseChargesLabel',
    defaultMessage:
      // eslint-disable-next-line
      'This amount is a total of the "Monthly Transaction" charges you have added to the "Monthly Lease Charges or Credits Table" below. It does not include concessions or any optional charges like pet/storage fees.',
  },
  differenceMessage: {
    id: 'App.LeaseDataTabForm.Reconciliation.DifferenceMessage',
    defaultMessage:
      // eslint-disable-next-line
      'If this Difference amount is not $0, either the Lease Rent Amount or the Monthly Charges entered below need to be corrected.\t\t\t',
  },
  leaseRent: {
    id: 'App.LeaseDataTabForm.Reconciliation.LeaseDataTabForm.LeaseRent',
    defaultMessage: 'Lease Rent',
  },
  reconciliation: {
    id: 'App.LeaseDataTabForm.Reconciliation.reconciliation',
    defaultMessage: 'Reconciliation',
  },
  totalLeaseCharges: {
    id: 'App.LeaseDataTabForm.Reconciliation.totalLeaseCharges',
    defaultMessage: 'Total Lease Charges',
  },
  nonRentCharges: {
    id: 'App.LeaseDataTabForm.Reconciliation.nonRentCharges',
    defaultMessage: 'Non Rent Charges',
  },
});

export default messages;
