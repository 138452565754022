export const GET_ALL_USER_STATUSES = 'GET_ALL_USER_STATUSES';
export const GET_ALL_USER_STATUSES_SUCCESS = 'GET_ALL_USER_STATUSES_SUCCESS';
export const GET_ALL_USER_STATUSES_FAIL = 'GET_ALL_USER_STATUSES_FAIL';
export const GET_ALL_USER_TITLES = 'GET_ALL_USER_TITLES';
export const GET_ALL_USER_TITLES_SUCCESS = 'GET_ALL_USER_TITLES_SUCCESS';
export const GET_ALL_USER_TITLES_FAIL = 'GET_ALL_USER_TITLES_FAIL';
export const GET_ALL_USER_ROLES = 'GET_ALL_USER_ROLES';
export const GET_ALL_USER_ROLES_SUCCESS = 'GET_ALL_USER_ROLES_SUCCESS';
export const GET_ALL_USER_ROLES_FAIL = 'GET_ALL_USER_ROLES_FAIL';
export const GET_ALL_PROPERTIES = 'GET_ALL_PROPERTIES';
export const GET_ALL_PROPERTIES_SUCCESS = 'GET_ALL_PROPERTIES_SUCCESS';
export const GET_ALL_PROPERTIES_FAIL = 'GET_ALL_PROPERTIES_FAIL';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const NONE_PROPERTIES_SELECTED = 'NONE_PROPERTIES_SELECTED';
export const SET_SELECTED_PROPERTIES = 'SET_SELECTED_PROPERTIES';
export const SET_USER_ALL_PROPERTIES = 'CREATE_USER/SET_USER_ALL_PROPERTIES';
export const SET_SELECTED_PROPERTY_GROUPS =
  'CREATE_USER/SET_SELECTED_PROPERTY_GROUPS';
export const TOGGLE_MULTISELECT = 'TOGGLE_MULTISELECT';
export const CLEAR_FAIL_MESSAGE = 'CLEAR_FAIL_MESSAGE';
