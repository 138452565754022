import * as ActionTypes from './constants';

import type { Action } from '../App/types';

export function getApplicantInformation(applicantId: string): Action<string> {
  return {
    type: ActionTypes.GET_APPLICANT_INFORMATION,
    payload: applicantId,
  };
}

export function getApplicantInformationSuccess(applicant: any): Action<any> {
  return {
    type: ActionTypes.GET_APPLICANT_INFORMATION_SUCCESS,
    payload: applicant,
  };
}

export function getApplicantInformationError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_APPLICANT_INFORMATION_ERROR,
    payload: error,
  };
}

export function saveApplicantForm(
  applicant: any,
  convertedResident: boolean,
): Action<any> {
  return {
    type: ActionTypes.SAVE_APPLICANT_FORM,
    payload: { ...applicant, convertedResident },
  };
}

export function saveApplicantFormSuccess(): Action<any> {
  return {
    type: ActionTypes.SAVE_APPLICANT_FORM_SUCCESS,
    payload: undefined,
  };
}

export function saveApplicantFormError(error: Error): Action<any> {
  return {
    type: ActionTypes.SAVE_APPLICANT_FORM_ERROR,
    payload: error,
  };
}

export function clean(): Action<any> {
  return {
    type: ActionTypes.CLEAN,
    payload: undefined,
  };
}
