import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import ActivityService from '../../services/activityService';

const FLIP_SORT_ORDER = { ASC: 'DESC', DESC: 'ASC' };
const SORT_FULL_TEXT = { ASC: 'ascending', DESC: 'descending' };

const getFilterQueryKey = (rawFilters) => {
  const { isRange, ...filtersOmitIsRange } = rawFilters;
  // must ignore isRange property so toggling range checkbox will not invoke request
  return Object.entries(rawFilters).length > 0
    ? filtersOmitIsRange
    : {
        fromDate: '',
        toDate: '',
        statuses: [],
        types: [],
        autoOrManual: null,
      };
};

export const useFetchGetAllByProspect = (
  prospectId,
  selectedProperty,
  rawFilters,
  updateTrigger, // updates table when activity created
  refresh,
) => {
  const activityService = useMemo(() => {
    return new ActivityService();
  }, []);

  const limit = 10;
  const mode = null;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('DESC');
  const [sort, setSort] = useState('dateTime');
  const [currentFilter, setCurrentFilter] = useState(false);
  const abortController = new AbortController();
  const options = { signal: abortController.signal };
  const [columnOrder, setColumnOrder] = useState({ dateTime: 'descending' });
  const filters = getFilterQueryKey(rawFilters);

  const onSortOrder = (column) => {
    if (column === sort) {
      const newDir = FLIP_SORT_ORDER[order];
      setOrder(newDir);
      setColumnOrder({ [column]: SORT_FULL_TEXT[newDir] });
    } else {
      const newDir = 'DESC';
      setSort(column);
      setOrder(newDir);
      setColumnOrder({ [column]: SORT_FULL_TEXT[newDir] });
    }
  };

  const queryKey = {
    page,
    sort,
    order,
    columnOrder,
    refresh,
    updateTrigger,
    filters,
  };

  const queryResponse = useQuery(
    ['ActivitiesByProspect', queryKey],
    () =>
      activityService.getAllByProspect(
        prospectId,
        selectedProperty.id,
        page,
        limit,
        sort,
        order,
        mode,
        selectedProperty.organizationId,
        rawFilters,
        options,
      ),
    { enabled: true, staleTime: 15000, refetchOnMount: 'always' },
  );

  return {
    queryResponse,
    page,
    limit,
    order,
    columnOrder,
    setPage,
    onSortOrder,
    currentFilter,
    setCurrentFilter,
  };
};

export const useFetchGetActivityFilterOptions = (
  prospectId,
  selectedProperty,
  refresh,
) => {
  const activityService = useMemo(() => {
    return new ActivityService();
  }, []);
  const queryKey = { prospectId, refresh };
  const abortController = new AbortController();
  const options = { signal: abortController.signal };
  return useQuery(
    ['ActivitiesFilterOptions', queryKey],
    () =>
      activityService.getActivityFilterOptions(
        prospectId,
        selectedProperty.id,
        selectedProperty.organizationId,
        options,
      ),
    { enabled: true, staleTime: 15000 },
  );
};
