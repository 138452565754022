import { isNil, propOr } from 'ramda';
import styled from 'styled-components';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatPeriod } from '../../../../../../utils/redux-form-helper';
import { formatDateUTCToLocale } from '../../../../../../utils/date-helpers';
import messages from '../../../../LedgerDetails/messages';
import React from 'react';
import type { Transaction } from '../../../../types';

type Props = {
  transactions: Array<Transaction>,
  formatMessage: Function,
  formatNumber: Function,
  isOps: boolean,
  isAllSubjournals: boolean,
  onViewTransaction: Function,
  viewTransactionDisabled: boolean,
};

const LedgerRow = (props: Props) => {
  const {
    transactions,
    formatMessage,
    formatNumber,
    isOps,
    isAllSubjournals,
    onViewTransaction,
    viewTransactionDisabled,
  } = props;

  return transactions?.length > 0 ? (
    transactions
      .sort(
        (a: Object, b: Object) =>
          propOr(0, 'readableId', b) - propOr(0, 'readableId', a),
      )
      .map((transaction) => {
        const {
          id,
          unit,
          transactionDate,
          createdAt,
          subjournal,
          propertyFiscalPeriod,
          propertyTransactionCode,
          status,
          isManual,
          documentNumber,
          charges,
          credits,
          subjournalRunningBalance,
          allSubjournalRunningBalance,
          runningSecurityBalanceOwed,
          transactionNote,
        } = transaction;
        const transactionCode = propertyTransactionCode
          ? propertyTransactionCode.transactionCode
          : null;
        const code = transactionCode ? transactionCode.code : '---';
        const description =
          transactionCode && transactionCode.description
            ? transactionCode.description
            : '---';
        const unitNumber = unit ? unit.number : '---';
        const subjournalBalance = isAllSubjournals
          ? allSubjournalRunningBalance
          : subjournalRunningBalance;
        const chargeDisplay =
          charges === '0.00'
            ? '---'
            : formatNumber(charges, {
                style: 'currency',
                currency: 'USD',
              });
        const creditDisplay =
          credits === '0.00'
            ? '---'
            : formatNumber(credits, {
                style: 'currency',
                currency: 'USD',
              });
        const StyledComment = styled(Button)`
          cursor: initial;
        `;

        // BE returns dateTime strings in UTC, so initialize moment as utc then
        // convert to local tz only, not actual date
        const formattedTransactionDate = formatDateUTCToLocale(transactionDate);
        const formattedActualPostDate = formatDateUTCToLocale(createdAt);

        return (
          <tr key={id}>
            <td>{unitNumber}</td>
            <td>{formattedTransactionDate}</td>
            <td>{formattedActualPostDate}</td>
            {isOps ? <td>{subjournal?.description}</td> : null}
            <td style={{ whiteSpace: 'nowrap' }}>
              {formatPeriod(propertyFiscalPeriod.period)}
            </td>
            <td>{code}</td>
            <td>{formatMessage(messages[status.toLowerCase()])}</td>
            <td>{isManual ? <i className="et-isolated-check" /> : ''}</td>
            <td>{description}</td>
            <td>{documentNumber ? documentNumber : '---'}</td>
            <td>{chargeDisplay}</td>
            <td>{creditDisplay}</td>
            <td>
              {isOps
                ? formatNumber(subjournalBalance, {
                    style: 'currency',
                    currency: 'USD',
                  })
                : formatNumber(runningSecurityBalanceOwed, {
                    style: 'currency',
                    currency: 'USD',
                  })}
            </td>
            <td>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`toolTipTransactionNote${id}`}>
                    {transactionNote}
                  </Tooltip>
                }
              >
                <StyledComment
                  bsStyle="default"
                  bsSize="small"
                  className="btn-comments"
                  disabled={
                    isNil(transactionNote) || transactionNote.length === 0
                  }
                >
                  <i className="icon et-comment-words" />
                </StyledComment>
              </OverlayTrigger>
            </td>
            <td>
              <button
                className="btn btn-sm btn-default"
                disabled={viewTransactionDisabled}
                onClick={() => onViewTransaction(transaction, isOps)}
              >
                <i className="et-pencil" />
              </button>
            </td>
          </tr>
        );
      })
  ) : (
    <tr>
      <td colSpan={14}>No transactions found</td>
    </tr>
  );
};

export default LedgerRow;
