import React, { useState, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import { isDirty, isValid, submit } from 'redux-form';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as R from 'ramda';
import * as constants from './constants';
import * as hooks from './hooks';
import messages from './messages';
import { promptToaster } from '../../../../App/actions';
import SubmitButton from '../../../../../components/SubmitButton';
import RentForm from './RentForm';
import ConfirmOverlay from '../../../../../components/ConfirmOverlay';

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButtonMargins = styled.span`
  & button {
    margin: 0 10px;
  }
`;

const OverlayOverrides = styled.span`
  .modal-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    margin-top: 0;
  }
`;

function RentModal({
  onHide,
  show,
  intl,
  lease,
  isFormDirty,
  isFormValid,
  onSaveClick,
  submit,
  propertyId,
  organizationId,
  promptToaster,
  refreshRents,
}) {
  const [isCloseOverlayShown, setIsCloseOverlayShown] = useState(false);
  const [createRents] = hooks.useCreateRents(
    propertyId,
    organizationId,
    R.prop('id', lease),
    promptToaster,
    refreshRents,
  );
  const startDate = R.prop('rentStartDate', lease);
  const leaseEndDate = R.propOr('', 'endDate', lease);
  const leasedRent = R.prop('leasedRent', lease);
  const isSubmitDisabled = !isFormDirty || !isFormValid;

  const handleSaveClick = () => {
    submit(constants.FORM_NAME);
  };

  const handleCloseReject = () => {
    setIsCloseOverlayShown(false);
  };

  const handleCloseConfirm = () => {
    onHide();
    setIsCloseOverlayShown(false);
  };

  const handleHide = () => {
    if (isFormDirty) setIsCloseOverlayShown(true);
    else onHide();
  };

  const handleSubmit = (values) => {
    createRents(values);
    onHide();
  };

  const initialValues = useMemo(
    () => ({
      rentSchedules: [
        {
          startDate: startDate ? moment(startDate) : undefined,
          monthlyAmount: leasedRent,
        },
      ],
    }),
    [startDate, leasedRent],
  );

  return (
    <Modal show={show} onHide={handleHide} bsSize="large" autoFocus>
      <Modal.Header closeButton>
        <i className="icon et-money" />
        <h1>
          <FormattedMessage {...messages.addRentSchedule} />
        </h1>
      </Modal.Header>
      <Modal.Body style={{ marginTop: isCloseOverlayShown ? 0 : undefined }}>
        {isCloseOverlayShown && (
          <OverlayOverrides>
            <ConfirmOverlay
              title="Are you sure you want to leave without saving?"
              rejectMessage="No"
              confirmMessage="Yes"
              onReject={handleCloseReject}
              onConfirm={handleCloseConfirm}
            />
          </OverlayOverrides>
        )}
        <RentForm
          intl={intl}
          startDate={startDate}
          leaseEndDate={leaseEndDate}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      </Modal.Body>
      {!isCloseOverlayShown && (
        <Modal.Footer style={{ marginTop: 10 }}>
          <FooterContainer>
            <Button type="button" bsStyle="default" onClick={handleHide}>
              <FormattedMessage {...messages.cancel} />
            </Button>
            <SubmitButtonMargins>
              <SubmitButton
                bsStyle="primary"
                disabled={isSubmitDisabled}
                clickHandler={handleSaveClick}
                isSubmitting={false}
              >
                <FormattedMessage {...messages.save} />
              </SubmitButton>
            </SubmitButtonMargins>
          </FooterContainer>
        </Modal.Footer>
      )}
    </Modal>
  );
}

const mapStateToProps = (state): Object => ({
  isFormDirty: isDirty(constants.FORM_NAME)(state),
  isFormValid: isValid(constants.FORM_NAME)(state),
});

const mapDispatchToProps = { submit, promptToaster };

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(RentModal),
);
