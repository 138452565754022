export const PROPERTY_PATH_MAP = {
  priorTenants: {
    path: 'priorTenants',
    dataType: 'string',
  },
  priorResidents: {
    path: 'priorResidents',
    dataType: 'string',
  },
  texting: {
    path: 'texting.TOTAL',
    dataType: 'number',
    filterPath: 'texting',
    filterFn: (filterByArr: string[], filterValue: any) => {
      if (filterByArr.includes('new') && filterValue.NEW > 0) {
        return true;
      }
      if (filterByArr.includes('unresolved') && filterValue.UNRESOLVED > 0) {
        return true;
      }
      return false;
    },
  },
  unit: {
    path: 'unit',
    dataType: 'string',
  },
  moveInDate: {
    path: 'moveInDate',
    dataType: 'date',
  },
  moveOutDate: { path: 'moveOutDate', dataType: 'date' },
  FASDate: {
    path: 'FASDate',
    dataType: 'date',
  },
  monthsLateFeesCharges: {
    path: 'monthsLateFeesCharges',
    dataType: 'number',
  },
  underEviction: {
    path: 'underEviction',
    dataType: 'boolean',
  },
  doNotRenew: {
    path: 'doNotRenew',
    dataType: 'boolean',
  },
  collectionStatus: {
    path: 'collectionStatus',
    dataType: 'string',
  },
  claimCreated: {
    path: 'claimCreated',
    dataType: 'date',
  },
  quotingRent: {
    path: 'quotingRent',
    dataType: 'number',
  },
  residentBalance: {
    path: 'balance',
    dataType: 'number',
  },
  subsidyBalance: {
    path: 'subsidyBalance',
    dataType: 'number',
  },
};
