import type { Action } from '../App/types';
import type { PeopleProfileState } from './types';
import * as ActionTypes from './constants';

const initialState = {
  currentProspect: undefined,
  pendingActivities: [],
  completedActivities: [],
  allActivities: [],
  completedActivitiesMeta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  allActivitiesMeta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  columnOrder: {
    status: 'sortable',
    type: 'sortable',
    startTime: 'sortable',
  },
};

const peopleProfileReducer = (
  state: PeopleProfileState = initialState,
  action: Action<*, *>,
): PeopleProfileState => {
  const { type, payload, meta } = action;
  const defaultColumnOrder = { startTime: 'sortable' };
  switch (type) {
    case ActionTypes.GET_ONE_PROSPECT_SUCCESS: {
      return { ...state, currentProspect: payload };
    }
    case ActionTypes.GET_ONE_PROSPECT_ERROR: {
      return { ...state, currentProspect: initialState.currentProspect };
    }
    case ActionTypes.GET_PROSPECT_PENDING_ACTIVITIES_SUCCESS: {
      return { ...state, pendingActivities: payload };
    }
    case ActionTypes.GET_PROSPECT_COMPLETED_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        completedActivities: payload,
        completedActivitiesMeta: meta,
      };
    }
    case ActionTypes.GET_PROSPECT_ALL_ACTIVITIES_SUCCESS: {
      return { ...state, allActivities: payload, allActivitiesMeta: meta };
    }
    case ActionTypes.UPDATE_COLUMNS_SORT_VALUE: {
      return {
        ...state,
        columnOrder: Object.assign({}, defaultColumnOrder, payload),
      };
    }
    default:
      return state;
  }
};

export default peopleProfileReducer;
