import request, { apiURL } from './api';
import {
  formatCurrency as formatCurrencyLibrary,
  formatNumberToString,
  formatDateDisplayLocal,
  formatDateTimeDisplayLocal,
} from '@fortress-technology-solutions/fortress-component-library/utils';
import { DASHES } from '@fortress-technology-solutions/fortress-component-library/constants';
import { download } from '../utils/downloadFile';

export const getCSV = async ({
  organizationId,
  selectedPropertyId,
  key,
  query,
}: {
  organizationId: string,
  selectedPropertyId: string,
  key: string,
  query: string,
}) => {
  const options = {
    headers: {
      Authorization: localStorage.getItem('session_id'),
      'Content-Type': 'application/json',
    },
  };

  return request(
    `${apiURL}/${organizationId}/${selectedPropertyId}/${key}${
      query?.length ? `?${query}` : ''
    }`,
    options,
  );
};

export const getHeaders = (headers: any, removeHeaderId: string) => {
  return headers.filter((header) => header.id !== removeHeaderId);
};

export const formatCurrency = (num) =>
  `"${Number(num).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })}"`;

export const appendFilterTextToCSV = ({ headers, rows, hasAnyFilters }) => {
  let csvData = [headers, ...rows];
  // Prepends filter text if filters are present
  if (hasAnyFilters) {
    csvData.unshift('This report was filtered prior to being generated', '');
  }
  csvData = csvData.join('\n');

  return csvData;
};

// This is a dynamic function, meant to be used with Fortress Table from CL
export const exportAndDownloadCSV = ({
  excludedHeaders = [],
  filteredHeaders = '',
  fileName = '',
  hasAnyFilters = false,
  rows = [],
}: {
  excludedHeaders: string[],
  filteredHeaders: {
    id: string,
    label: string | { props: { defaultMessage: string } },
  }[],
  fileName: string,
  hasAnyFilters: boolean,
  rows: { [headerId: string]: { variant: string, value: string | number } },
}) => {
  const headers = [];
  const headerIds = [];

  filteredHeaders.forEach(({ id, label }) => {
    if (excludedHeaders.includes(id)) return; // skip excluded headers
    headers.push(
      typeof label === 'string' ? label : label.props?.defaultMessage,
    );
    headerIds.push(id);
  });

  const csvHeaders = headers.join(',');
  let csvRows = [];
  csvRows = rows.map((row) =>
    headerIds
      .map((headerId) => {
        let { variant, value } = row[headerId];
        // if value includes a comma, wrap in quotes
        if (typeof value === 'string' && value.includes(',')) {
          value = `"${value.trimEnd()}"`;
        }

        // if value is a number and the variant is currency,format as currency
        if (variant === 'currency' && typeof value === 'number') {
          value = `"${formatCurrencyLibrary(value)}"`;
        }

        // if value is an array, join with comma
        if (Array.isArray(value)) {
          value = `"${value.join(', ')}"`;
        }

        if (variant === 'date') {
          value = formatDateDisplayLocal(value);
        }

        if (variant === 'dateTime') {
          value = formatDateTimeDisplayLocal(value);
        }

        if (
          !['date', 'dateTime'].includes(variant) &&
          typeof value === 'string' &&
          /^\d+\/\d+$/.test(value) // value looks like a date (1/1)
        ) {
          value = `="${value}"`;
        }

        if (
          (typeof value === 'boolean' && value === false) ||
          ['no', 'No'].includes(value)
        ) {
          value = '';
        }

        if (typeof value === 'boolean' && value === true) {
          value = 'Yes';
        }

        // if value is falsy, set to DASHES
        if (
          !value ||
          value === '\n' ||
          (['string', 'array'].includes(typeof value) && !value?.length)
        ) {
          value = DASHES;
        }

        return value;
      })
      .join(','),
  );

  const csv = appendFilterTextToCSV({
    headers: csvHeaders,
    rows: csvRows,
    hasAnyFilters,
  });

  download(csv, fileName, 'text/csv;charset=utf-8');
};

export const removeCommasForCSVExport = (csvDataArray: string[]) => {
  return csvDataArray.map((value) => {
    if (typeof value === 'string' && value.includes(',')) {
      value = value.split(',').join('');
    }
    return value;
  });
};

export const processDataToCSV = ({ rows, filteredHeaders }) => {
  const selectFormat = ({ variant, value }, id) => {
    if (value === null || value === undefined) return '---';
    if (id === 'sqFeet') variant = 'number';
    if (id === 'requestor') variant = 'list';
    if (!variant) return `="${value}"` ?? '';
    let dataTypes = {
      link: () => value ?? '',
      description: () => value ?? '',
      currency: () => formatCurrencyLibrary(value),
      number: () => formatNumberToString(value),
      string: () => value ?? '',
      date: () => formatDateDisplayLocal(value) ?? value,
      dateTime: () => formatDateDisplayLocal(value) ?? value,
      status: () => value ?? '',
      priority: () => value ?? '',
      list: () =>
        value.filter((valor) => valor !== null && valor !== '---').join(' ') ??
        '',
    };
    return dataTypes[variant] ? `"${dataTypes[variant]()}"` : '';
  };

  return rows.map((floorPlan) => {
    const csvRow = filteredHeaders.map(({ id }) =>
      selectFormat(floorPlan[id], id),
    );
    return csvRow.join(',');
  });
};
