import { useMemo } from 'react';
import { useQuery } from 'react-query';
import useAbortController from '../../../hooks/useAbortController';
import FloorPlanService from '../../../services/floorPlanService';
import { processFloorPlanResult } from './utils';

export const useFloorPlansQuery = (
  organizationId: string,
  propertyId: string,
  isModalShown: boolean,
) => {
  const floorPlanService = useMemo(() => new FloorPlanService(), []);
  const abortControllerOptions = useAbortController();
  const queryKey = ['getAllWithPricing', organizationId, propertyId];

  const { data, isLoading } = useQuery(
    queryKey,
    () =>
      floorPlanService.getAllWithPricing(
        organizationId,
        propertyId,
        abortControllerOptions,
      ),
    {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      enabled: isModalShown,
    },
  );

  const floorPlans = data?.length ? processFloorPlanResult(data) : [];

  return {
    isLoading,
    floorPlans,
  };
};
