import { NO_PREFERENCE_OPTION } from '../containers/ProspectProfile/ProspectProfileForm/LookingFor/LookingFor.hook';
import type { FloorPlan } from '../containers/ManageFloorPlans/types';

const nBedsTextMap = {
  0: 'Studio',
  1: '1 Bedroom',
};

export const mapFloorPlanOptions = (floorPlans: FloorPlan[]) => {
  const processedFloorPlans = floorPlans
    ?.map((floorPlan) => {
      const container = { value: null, text: null, nBeds: null };

      container.value = floorPlan.id;
      container.text = `${floorPlan?.internalName} ${floorPlan?.marketingName}`;
      container.nBeds = floorPlan?.nBeds;
      container.nBaths = floorPlan?.nBaths;
      container.imageFileName = floorPlan?.imageFileName;
      container.rentableSquareFeet = floorPlan?.rentableSquareFeet;

      return container;
    })
    .sort((a, b) => a.nBeds - b.nBeds);

  return [NO_PREFERENCE_OPTION].concat(processedFloorPlans) ?? [];
};

export const getFloorPlanName = ({
  floorPlans,
  preferredFloorPlanId,
}: {
  floorPlans: FloorPlan[],
  preferredFloorPlanId: string,
}) => {
  let floorPlanName = '---';
  const hasNoPreference = NO_PREFERENCE_OPTION.value === preferredFloorPlanId;

  if (hasNoPreference) {
    floorPlanName = NO_PREFERENCE_OPTION.text;
  } else if (preferredFloorPlanId?.length) {
    const selectedFloorPlan = floorPlans?.find(
      ({ id }) => id === preferredFloorPlanId,
    );

    const hasName =
      selectedFloorPlan?.internalName && !isNaN(selectedFloorPlan?.nBeds);
    floorPlanName = hasName
      ? `${selectedFloorPlan?.internalName.toUpperCase()} (${
          nBedsTextMap[selectedFloorPlan?.nBeds] ??
          `${selectedFloorPlan?.nBeds} Bedrooms`
        })`
      : floorPlanName;
  }

  return floorPlanName;
};
