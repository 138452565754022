import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

const KPICardContainer = ({
  children,
  subtitle,
  title,
  action,
  legend,
  required,
}) => {
  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <div>
            <Typography paragraph={!subtitle} variant={'h5'}>
              {title}
              {required ? '*' : ''}
            </Typography>
            {subtitle && (
              <Typography paragraph={true} variant={'caption'}>
                {subtitle}
              </Typography>
            )}
          </div>
          {action && <Box maxWidth={200}>{action}</Box>}
          {legend && <Box>{legend}</Box>}
        </Stack>
        {children}
      </CardContent>
    </Card>
  );
};

export default KPICardContainer;
