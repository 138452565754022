import { forEachObjIndexed, omit } from 'ramda';
import moment from 'moment';

const { apiURL, requestToDownload } = require('../utils/api');
const request = require('../utils/api').default;

export default class CommunicationService {
  getResidents(organizationId: string, propertyId: string) {
    const options = {
      methods: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/communications/residents?financialResponsable=true`,
      options,
    );
  }

  getProspects(
    organizationId: string,
    propertyId: string,
    prospectStatusList: Array<Object>,
  ) {
    const options = {
      methods: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const allStatuses = prospectStatusList
      .map((status) => `prospectStatusId=${status.id}`)
      .join('&');
    return request(
      `${apiURL}/${organizationId}/${propertyId}/prospects?pagination=false&${allStatuses}`,
      options,
    );
  }

  getApplicants(organizationId: string, propertyId: string) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/communications/applications`,
      options,
    );
  }

  getOneCommunication(
    organizationId: string,
    propertyId: string,
    headerId: string,
  ) {
    const options = {
      methods: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    return request(
      `${apiURL}/${organizationId}/${propertyId}/communication?headerId=${headerId}`,
      options,
    );
  }

  getAllCommunications(
    organizationId: string,
    propertyId: string,
    sorting?: any,
    filterValue: any,
  ) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    const filters: Array<string> = [];
    const filterValues = omit(['dateFrom', 'dateTo'], filterValue);
    forEachObjIndexed((valueFilter, keyFilter) => {
      forEachObjIndexed((valueOption, keyOption) => {
        if (valueOption) {
          filters.push(`${keyFilter}=${keyOption}`);
        }
      }, valueFilter);
    }, filterValues);
    if (filterValue.dateFrom) {
      const dateFrom = moment(filterValue.dateFrom).toISOString();
      filters.push(`dateFrom=${dateFrom}`);
    }
    if (filterValue.dateTo) {
      const dateTo = moment(filterValue.dateTo).toISOString();
      filters.push(`dateTo=${dateTo}`);
    }
    const sort =
      sorting && sorting.fieldName === ''
        ? ''
        : // $FlowFixMe
          `sort=${sorting.fieldName}&order=${sorting.order}`;

    const queryString = `?${sort}&${filters.join('&')}`;

    return request(
      `${apiURL}/${organizationId}/${propertyId}/communications${queryString}`,
      options,
    );
  }

  generateCommunicationBatch({ organizationId, propertyId, payload }: Object) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
        ...payload,
      }),
    };
    let r = request;
    if (payload.communicationType === 'LETTER') {
      r = requestToDownload;
    }
    return r(
      `${apiURL}/${organizationId}/${propertyId}/generate-communication-batch`,
      options,
    );
  }

  downloadCommunication(
    organizationId: string,
    propertyId: string,
    communicationId: string,
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
    };

    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/download/${communicationId}`,
      options,
    );
  }

  getValidateRecipients({ payload }: Object) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...payload,
      }),
    };
    return request(`${apiURL}/validate-recipients`, options);
  }

  getValidateEmail({ payload }: Object) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...payload,
      }),
    };
    return request(`${apiURL}/validate-email`, options);
  }

  uploadAttachment({ attachment, organizationId, selectedPropertyId }) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
      body: attachment,
    };

    return request(
      `${apiURL}/${organizationId}/${selectedPropertyId}/upload-attachment`,
      options,
    );
  }

  downloadAttachment(
    organizationId: string,
    propertyId: string,
    attachmentUrl: Object,
    options: ?Object = {},
  ) {
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
      },
      body: JSON.stringify({ attachmentUrl }),
      ...options,
    };
    return requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/download-attachment`,
      requestOptions,
    );
  }
}
