import { prop, sortBy, uniqBy } from 'ramda';
import ManageFormsDropDown from '../../../../components/ManageFormsDropDown';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import React from 'react';

export const TaskRow = (props: Object) => {
  const { item, assignees, onChangeAssignee } = props;
  const dropDownAssign = (current, id, appOrProspect) => {
    if (assignees) {
      let currentAndAssignable = assignees.slice();
      currentAndAssignable.push(current);
      currentAndAssignable = sortBy(prop('text'))(
        uniqBy((x) => x.text, currentAndAssignable),
      );

      return (
        <td className="dropdown">
          <ManageFormsDropDown
            items={currentAndAssignable}
            changeFunction={(event) =>
              onChangeAssignee(event.target.value, id, appOrProspect)
            }
            initialValue={current.value}
            scope={['prospect-assign']}
          />
        </td>
      );
    }
    return <td></td>;
  };

  let columns = [];
  if (item) {
    const { id, highlight, ...cols } = item;
    columns = Object.keys(cols).map((key) => {
      if (cols[key] || cols[key] === 0) {
        if (cols[key].hasOwnProperty('link')) {
          return (
            <td className={classNames({ 'text-red': highlight.red })}>
              <Link to={cols[key].link}>{cols[key].value}</Link>
            </td>
          );
        } else if (cols[key].hasOwnProperty('dropDownMenuAssign')) {
          return dropDownAssign(cols[key], id, cols[key].dropDownMenuAssign);
        }
        return <td>{cols[key]}</td>;
      } else {
        return <td></td>;
      }
    });
  }

  return (
    <tr
      className={classNames({
        highlight: item.highlight.red,
        'text-red': item.highlight.red,
        rowYellow: item.highlight.yellow,
        rowGreen: item.highlight.green,
      })}
    >
      {columns}
    </tr>
  );
};
