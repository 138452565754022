import { sortBy } from 'lodash';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { toastr } from 'react-redux-toastr';
import FloorPlanPricingService from '../../../services/floorPlanPricingService';
import SmartPricingService from '../../../services/smartPricingService';
import UnitService from '../../../services/unitService';
import { buildUnitAvailability } from '../../../utils/unit-helpers';

export const useFetchUnleasedUnitsByFloorPlan = ({
  organizationId,
  propertyId,
  floorInternalName,
  selectedSmartPrice,
  show,
  intl,
}) => {
  const unitService = new UnitService();

  const floorPlanPricingService = new FloorPlanPricingService();

  const marketRentsQuery = useQuery(
    ['marketRents', propertyId, organizationId],
    async () =>
      floorPlanPricingService.getAllMarketRentsByProperty(
        organizationId,
        propertyId,
      ),
    {
      staleTime: 60000,
    },
  );
  const unitsQuery = useQuery(
    ['propertyUnits', propertyId, organizationId],
    async () => {
      const rawUnits = await unitService.getAllByProperty(
        propertyId,
        organizationId,
      );
      return rawUnits.units.map((unit) => {
        const property = {
          ...unit.property,
          propertyFees: rawUnits.propertyFees,
        };
        return { ...unit, property };
      });
    },
    {
      staleTime: 60000,
    },
  );

  const availabilityQuery = useQuery(
    ['availability', unitsQuery.data],
    () => {
      const { arr } = buildUnitAvailability(
        unitsQuery.data,
        intl,
        marketRentsQuery.data,
      );
      const notLeasedUnisforFloorplan = arr
        .filter(
          (row) =>
            row.rows.floorPlan.value === floorInternalName &&
            row.rows.leased.value !== 'Yes',
        )
        .map((item) => ({
          ...item,
          smartPricePlusAmenities: item.unitFees.reduce(
            (acc, fee) => acc + fee.feeAmount,
            Number(selectedSmartPrice),
          ),
          amenities: item.unitFees.reduce((acc, fee) => acc + fee.feeAmount, 0),
        }))
        .map((item) => ({
          unit: item.rows.unit,
          status: item.rows.status,
          moveOutDate: item.rows.moveOutDate,
          moveInReady: item.rows.moveInReady,
          daysVacant: item.rows.daysVacant,
          currentLeasedRent: item.rows.currentLeasedRent,
          priorLeasedRent: item.rows.priorLeasedRent,
          quotingRent: item.rows.quotingRent,
          amenities: item.amenities,
          originalSmartPrice: selectedSmartPrice,
          smartPrice: {
            value: intl.formatNumber(item.smartPricePlusAmenities, {
              style: 'currency',
              currency: 'USD',
            }),
            raw: item.smartPricePlusAmenities,
          },
        }));

      const sorted = sortBy(notLeasedUnisforFloorplan, [
        (unit) => unit.unit.raw,
      ]);

      return sorted || [];
    },
    {
      refetchOnMount: true,
      enabled: Boolean(
        unitsQuery.data?.length && marketRentsQuery.data && show,
      ),
    },
  );

  return {
    availabilityQuery: availabilityQuery,
    isLoading:
      unitsQuery.isLoading ||
      marketRentsQuery.isLoading ||
      availabilityQuery.isLoading,
  };
};

export function useActOnSmartPrice(
  organizationId: string,
  propertyId: string,
  callback: () => Promise<void>,
) {
  const [isLoading, setIsLoading] = useState(false);

  async function actOnSmartPrice(
    currentAmount: number,
    newAmount: number,
    floorPlanId: string,
    action: 'ACCEPT' | 'REJECT',
  ) {
    setIsLoading(true);
    const smartPricingService = new SmartPricingService();

    try {
      await smartPricingService.actOnSmartPrice(
        organizationId,
        propertyId,
        currentAmount,
        newAmount,
        floorPlanId,
        action,
      );

      toastr.success('Success', 'Smart Price decision successfully recorded.');
      await callback();
    } catch (errorMessage) {
      toastr.error('Error', errorMessage.replace('Error:', ''), {
        timeOut: 120 * 60,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return [actOnSmartPrice, isLoading];
}
