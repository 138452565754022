import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ManageOnlinePaymentsHeader.ManageOnlinePayments',
    defaultMessage: 'Manage Online Payments',
  },
  totalPayments: {
    id: 'App.ManageOnlinePaymentsHeader.TotalPayments',
    defaultMessage: 'Total Payments:',
  },
  reject: {
    id: 'App.ManageOnlinePaymentsHeader.Reject',
    defaultMessage: 'Reject',
  },
  accept: {
    id: 'App.ManageOnlinePaymentsHeader.Accept',
    defaultMessage: 'Accept',
  },
});

export default messages;
