import React from 'react';
import DocumentTitle from 'react-document-title';
import { Prompt } from 'react-router';
import { Grid, Row, Col } from 'react-bootstrap';
import { omit } from 'ramda';
import MUPContext from './Context';
import CreateDepositForm from './CreateDepositForm';
import UndepositedPaymentsTable from '../UndepositedPaymentsTable';
import type { UndepositedPaymentRow } from '../UndepositedPaymentsTable/types';
import type { Option } from '../../types';

type Props = {
  rows: UndepositedPaymentRow[],
  bankAccountOptions: Option[],
  period: string,
  batchDate: string,
  disableSubmit: boolean,
  disableCancel: boolean,
  enablePrompt: boolean,
  selectForDeposit: Function,
  unselectForDeposit: Function,
  onSubmit: Function,
  onViewPaymentBatch: Function,
  onViewSinglePayment: Function,
  onCancel: Function
};

const ManageUndepositedPayments = (props: Props) => (
  <DocumentTitle title="Fortress - Manage Undeposited Payments">
    <div className="bodywrap">
      <Prompt
        message="Are you sure you want to leave without depositing?"
        when={props.enablePrompt}
      />
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <h1>Manage Undeposited Payments</h1>
          </Col>
        </Row>
      </Grid>
      <UndepositedPaymentsTable
        rows={props.rows}
        onViewPaymentBatch={props.onViewPaymentBatch}
        onViewSinglePayment={props.onViewSinglePayment}
        onSelect={props.selectForDeposit}
        onUnselect={props.unselectForDeposit}
      />
      <MUPContext.Provider
        value={omit(
          [
            'selectForDeposit',
            'unselectForDeposit',
            'onViewPaymentBatch',
            'rows'
          ],
          props
        )}
      >
        <CreateDepositForm />
      </MUPContext.Provider>
    </div>
  </DocumentTitle>
);

export default ManageUndepositedPayments;
