import React from 'react';

type Props = {
  v: number,
  h: number,
  inline: ?boolean,
};

const Spacer = ({ v, h, inline = false }: Props) => (
  <div
    style={{
      width: h,
      height: v,
      display: inline ? 'inline-block' : undefined,
    }}
  />
);

export default Spacer;
