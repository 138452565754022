import { not, isEmpty, isNil, equals } from 'ramda';

export const validateMaxLength = (
  max: number,
  msg?: string = `Maximum Length is ${max} characters`
) => (value: ?string) =>
  typeof value === 'string' && value.length >= max ? msg : undefined;

export const validateRequired = (
  value: string,
  msg?: string = 'This field is required'
) =>
  not(isEmpty(value)) && not(isNil(value)) && !equals('default', value)
    ? undefined
    : msg;

export const validateDollarAmount = (
  value: string,
  msg?: string = 'Must be a dollar value greater than $0.00'
) => {
  const amount = parseFloat(value);
  return !Number.isNaN(amount) && amount > 0 ? undefined : msg;
};
