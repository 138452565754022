import { put, select, throttle } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { GET_CREATE_CHARGE_BATCH } from './constants';
import type { Property } from '../App/types';
import BatchDocumentService from '../../services/batchDocumentService';
import {
  getCreateChargeBatchSuccess,
  getCreateChargeBatchFailure,
} from './actions';
import {
  selectSelectedProperty,
  selectCurrentUserOrganizationId,
} from '../App/selectors';

const validateSelectedProperty = (selectedProperty: Property) => {
  if (!selectedProperty) {
    throw new Error('A property must be selected to perform this action.');
  }
};

export function* getCreateChargeBatchSaga(): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    validateSelectedProperty(selectedProperty);
    const propertyId = selectedProperty.id;
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const batchDocumentService = new BatchDocumentService();
    const chargeTransactionCodes = yield batchDocumentService.get(
      organizationId,
      propertyId,
    );

    yield put(getCreateChargeBatchSuccess(chargeTransactionCodes));
  } catch (err) {
    yield put(getCreateChargeBatchFailure(err));
  }
}

export function* watchGetCreateChargeBatchSaga(): Saga<void> {
  yield throttle(500, GET_CREATE_CHARGE_BATCH, getCreateChargeBatchSaga);
}

export default [watchGetCreateChargeBatchSaga];
