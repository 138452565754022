import React, { Component } from 'react';
import * as R from 'ramda';
import { createSelector } from 'reselect';
import {
  reduxForm,
  change,
  getFormInitialValues,
  getFormSyncWarnings,
  formValueSelector,
} from 'redux-form';
import { Button, Col, Modal } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';

import { getSentAndExecutedStatus } from '../../utils/lease-helpers';
import AffordableInformation from './AffordableInformation';
import GeneralInformation from './GeneralInformation';
import GeneralCommercialInformation from './GeneralCommercialInformation';
import PayLease from './PayLease';
import ResidentPortal from './ResidentPortal';
import PersonalInformation from './PersonalInformation';
import validate from './validate';
import warn from './warn';
import { bindActionCreators } from 'redux';
import { promptToaster } from '../../containers/App/actions';
import { connect } from 'react-redux';
import ReportingInformationPanel from '../ReportingInformationPanel';
import { RD_PROGRAM_NAME } from '../../containers/GeneralAffordable/constants';

export const FORM_NAME = 'editOccupantForm';

const GUARANTOR_LEASE_SIGNER = 'Guarantor Lease Signer';
const EMPTY_REPORTING_VALUES = {
  specialNeedsDesignationIds: [],
  annualIncome: '0.00',
};

const StyledModalBodyHeight = styled(Modal.Body)`
  max-height: 55vh;
`;
type Props = {
  actions: Object,
  affordableRelationships: Array<Object>,
  anyTouched: boolean,
  countries: Array<Object>,
  intl: Object,
  isAffordable: boolean,
  isCommercial: boolean,
  selectedProperty: Object,
  occupantInfo: Object,
  onClose: Function,
  pristine: boolean,
  reset: Function,
  sendPayLeaseEmail: Function,
  show: boolean,
  states: Array<Object>,
  submit: Function,
  suffixes: Array<Object>,
  unitNumber: string,
  valid: boolean,
  newestLease?: Object,
  relationshipOptions: Array<Object>,
  applicantTypeOptions: Array<Object>,
  flags: Object,
  applicant: Object,
};

type State = {
  showCancelConfirmation: boolean,
  openPanel: ?string,
};

const getSectionBypassedFields = (
  targetSection: string,
  bypassedFields: [string, string][],
) => {
  return bypassedFields.reduce(
    (sbf: string[], [sectionName, fieldName]: [string, string]) => {
      if (targetSection !== sectionName) return sbf;
      return [...sbf, fieldName];
    },
    [],
  );
};

export class EditOccupant extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showCancelConfirmation: false,
      openPanel: '',
    };
  }

  setCancelConfirmation = (visible: boolean) => {
    this.setState({
      showCancelConfirmation: visible,
    });
  };

  showConfirmation = () => this.setCancelConfirmation(true);

  hideConfirmation = () => this.setCancelConfirmation(false);

  handleClose = () => {
    const { anyTouched, onClose, reset } = this.props;
    const { showCancelConfirmation } = this.state;
    if (anyTouched && !showCancelConfirmation) {
      this.showConfirmation();
      return;
    } else if (anyTouched && showCancelConfirmation) {
      reset();
    }
    this.hideConfirmation();
    if (onClose) {
      this.setState({ openPanel: null });
      onClose();
    }
  };

  submit = (evt: Object) => {
    evt.preventDefault();
    const { onClose } = this.props;
    const { submit } = this.props;
    this.setState({ openPanel: null });
    submit(FORM_NAME);
    onClose();
  };

  renderCancelConfirmation = () => {
    return (
      <div className="modal-confirm">
        <h1>Are you sure you want to leave without saving?</h1>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="primary"
            className="pull-right"
            onClick={this.handleClose}
          >
            Yes
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="default"
            className="pull-left"
            onClick={this.hideConfirmation}
          >
            No
          </Button>
        </Col>
      </div>
    );
  };

  togglePanel = (panelName: string) => () => {
    if (this.state.openPanel === panelName) {
      this.setState({ openPanel: null });
    } else {
      this.setState({ openPanel: panelName });
    }
  };

  render() {
    const {
      actions,
      affordableRelationships,
      countries,
      intl,
      isAffordable,
      isCommercial,
      selectedProperty,
      occupantInfo,
      pristine,
      sendPayLeaseEmail,
      show,
      states,
      suffixes,
      unitNumber,
      valid,
      newestLease,
      relationshipOptions,
      applicantTypeOptions,
      flags,
      applicant,
      initialValues,
      unit,
      bypassedRequiredFields,
      basicInformationMultiRace,
    } = this.props;

    const {
      customerId,
      emailAddress,
      formData,
      name,
      plAccount,
      referralType,
      type,
      isPrimary,
    } = occupantInfo;

    const isAdult = type === 'adult';
    const noEmailAddress =
      !emailAddress || emailAddress === 'batch_default@mail.com';
    const { id, organizationId, isResidentPortalActive, isDomusoActive } =
      selectedProperty;

    const { leaseExecuted, leaseSentToPortal } = getSentAndExecutedStatus(
      newestLease || {},
    );
    const specialNeedsAffordableChange =
      flags?.specialNeedsAffordableChange ?? false;

    const isFinanciallyResponsibleAdult =
      applicant?.applicantType?.financiallyResponsible ?? false;
    const isGuarantor =
      applicant?.applicantType?.name === GUARANTOR_LEASE_SIGNER;

    const unitFloorplanAffordablePrograms =
      unit?.floorPlan?.floorPlanAffordablePrograms ?? [];

    const isRD = unitFloorplanAffordablePrograms.some(
      (fpProgram) =>
        fpProgram?.propertyAffordableProgram?.masterAffordableProgram?.name ===
        RD_PROGRAM_NAME,
    );

    const showReportingInformation = specialNeedsAffordableChange || isRD;

    return (
      <Modal
        className="transaction"
        bsSize="small"
        show={show}
        onHide={this.handleClose}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <h1>{name}</h1>
          <p>
            <i className="icon et-home"> </i>
            <strong>Unit Number: </strong>
            {unitNumber}
          </p>
          <div>
            <small>* Required Fields</small>
          </div>
          {this.state.openPanel === 'Personal Information' && isAdult && (
            <div>
              <small>
                ** You must provide at least one of the following: State ID,
                Passport,{' '}
                {isAffordable ? 'SSN, or Alien Registration' : 'or SSN'}
              </small>
            </div>
          )}
        </Modal.Header>
        <StyledModalBodyHeight className="no-padding">
          {this.state.showCancelConfirmation && this.renderCancelConfirmation()}
          <div
            className="clearfix"
            style={this.state.showCancelConfirmation ? { display: 'none' } : {}}
          >
            <div className="accordion accordion-applicationmanager panel-group">
              {isAffordable && (
                <AffordableInformation
                  openPanel={this.state.openPanel}
                  togglePanel={this.togglePanel}
                  isAdult={isAdult}
                  affordableRelationships={affordableRelationships}
                  sectionInitialValues={initialValues.affordableInformation}
                  bypassedFields={getSectionBypassedFields(
                    'affordableInformation',
                    bypassedRequiredFields,
                  )}
                  showRaceSourceField={isRD}
                  applicant={applicant}
                  basicInformationMultiRace={basicInformationMultiRace}
                  reduxFormOnChange={this.props.change}
                />
              )}
              {isCommercial ? (
                <GeneralCommercialInformation
                  openPanel={this.state.openPanel}
                  togglePanel={this.togglePanel}
                  leaseExecuted={leaseExecuted}
                  leaseSentToPortal={leaseSentToPortal}
                  states={states}
                  sectionInitialValues={initialValues.generalInformation}
                  bypassedFields={getSectionBypassedFields(
                    'generalInformation',
                    bypassedRequiredFields,
                  )}
                />
              ) : (
                <GeneralInformation
                  openPanel={this.state.openPanel}
                  togglePanel={this.togglePanel}
                  suffixes={suffixes}
                  referralType={referralType}
                  isAdult={isAdult}
                  leaseExecuted={leaseExecuted}
                  leaseSentToPortal={leaseSentToPortal}
                  relationshipOptions={relationshipOptions}
                  applicantTypeOptions={applicantTypeOptions}
                  isPrimary={isPrimary}
                  sectionInitialValues={initialValues.generalInformation}
                  bypassedFields={getSectionBypassedFields(
                    'generalInformation',
                    bypassedRequiredFields,
                  )}
                />
              )}
              {isCommercial ? undefined : (
                <PersonalInformation
                  intl={intl}
                  openPanel={this.state.openPanel}
                  togglePanel={this.togglePanel}
                  isAdult={isAdult}
                  formData={formData}
                  states={states}
                  countries={countries}
                  isAffordable={isAffordable}
                  sectionInitialValues={initialValues.personalInformation}
                  bypassedFields={getSectionBypassedFields(
                    'personalInformation',
                    bypassedRequiredFields,
                  )}
                />
              )}
              {plAccount && !isDomusoActive && (
                <PayLease
                  noEmailAddress={noEmailAddress}
                  openPanel={this.state.openPanel}
                  plAccount={plAccount}
                  sendPayLeaseEmail={sendPayLeaseEmail}
                  togglePanel={this.togglePanel}
                />
              )}
              {isResidentPortalActive && customerId && (
                <ResidentPortal
                  noEmailAddress={noEmailAddress}
                  occupantInfo={occupantInfo}
                  openPanel={this.state.openPanel}
                  togglePanel={this.togglePanel}
                  propertyId={id}
                  organizationId={organizationId}
                  toastr={actions.promptToaster}
                  isCommercial={isCommercial}
                />
              )}
              {showReportingInformation && !isGuarantor && (
                <ReportingInformationPanel
                  openPanel={this.state.openPanel}
                  togglePanel={this.togglePanel}
                  changeFunc={this.props.change}
                  property={selectedProperty}
                  initialValues={
                    initialValues?.reportingInformation ??
                    EMPTY_REPORTING_VALUES
                  }
                  isFinanciallyResponsibleAdult={isFinanciallyResponsibleAdult}
                  showSpecialNeeds={specialNeedsAffordableChange}
                  showMINCField={isRD}
                  unit={unit}
                  customerType="Resident"
                />
              )}
            </div>
          </div>
        </StyledModalBodyHeight>
        <Modal.Footer
          className={this.state.showCancelConfirmation ? 'disabled ' : ''}
        >
          <div className="row">
            <div className="col-xs-6">
              <button
                className="btn btn-default pull-right"
                onClick={this.handleClose}
              >
                Cancel
              </button>
            </div>
            <div className="col-xs-6">
              <button
                className="btn btn-primary pull-left"
                onClick={this.submit}
                disabled={pristine || !valid}
              >
                Save
              </button>
            </div>
          </div>
          {!valid && (
            <div className="row">
              <div className="col-xs-12 text-center">
                <small className="text-gray--darker">
                  <span>
                    Must fill out the required fields of all sections to save.
                  </span>
                </small>
              </div>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

const getRequiredSyncWarnFields = createSelector(
  getFormSyncWarnings(FORM_NAME),
  (syncWarns) => {
    if (!syncWarns) return null;

    const sectionPairs = R.toPairs(syncWarns);

    const requiredSyncWarnFields = sectionPairs.reduce(
      (rse, [sectionName, warns]) => {
        const warnPairs = R.toPairs(warns);

        const requiredWarnPairs = warnPairs.filter(
          ([fieldName, warn]) => warn === 'Required',
        );

        if (requiredWarnPairs.length === 0) return rse;

        const fields = requiredWarnPairs.map(([fieldName]) => [
          sectionName,
          fieldName,
        ]);

        return [...rse, ...fields];
      },
      [],
    );

    return requiredSyncWarnFields;
  },
);

const getBypassedRequiredFields = createSelector(
  getFormInitialValues(FORM_NAME),
  getRequiredSyncWarnFields,
  (initialValues, requiredSyncWarnFields) => {
    const bypassedRequiredFields = requiredSyncWarnFields.filter(
      (fieldPath) => {
        const initialValue = R.path(fieldPath, initialValues);
        const wasInitiallyEmpty =
          R.isNil(initialValue) || R.isEmpty(initialValue);
        return wasInitiallyEmpty;
      },
    );

    return bypassedRequiredFields;
  },
);

const mapStateToProps = (state) => {
  const { form } = state;
  const selector = formValueSelector(FORM_NAME);
  return {
    bypassedRequiredFields: getBypassedRequiredFields(state),
    basicInformationMultiRace: selector(
      { form },
      'affordableInformation.multiRace',
    ),
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      promptToaster,
      change,
    },
    dispatch,
  );
  return { actions };
}

export const changeDisabilityValue = (
  newValues,
  dispatch,
  props,
  previousValues,
) => {
  if (props.dirty) {
    const prevDisabledValue =
      previousValues?.affordableInformation?.disability?.hasDisability;
    const disabledValue =
      newValues?.affordableInformation?.disability?.hasDisability;

    const DISABLE_KEYS = [
      'affordableInformation.disability.disabilityOther',
      'affordableInformation.disability.disabilityMobile',
      'affordableInformation.disability.disabilityVisual',
      'affordableInformation.disability.disabilityHearing',
    ];

    if (
      disabledValue !== prevDisabledValue &&
      prevDisabledValue &&
      (disabledValue === 'yes' || disabledValue === 'notWishToProvide')
    ) {
      DISABLE_KEYS.forEach((key) => {
        dispatch(props.change(key, null));
      });
    }
    if (disabledValue !== prevDisabledValue && disabledValue === 'no') {
      DISABLE_KEYS.forEach((key) => {
        dispatch(props.change(key, 'no'));
      });
    }
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  injectIntl(
    reduxForm({
      form: FORM_NAME,
      enableReinitialize: true,
      validate,
      warn,
      updateUnregisteredFields: true,
      touchOnChange: true,
      shouldError: ({ props }) => props.show,
      shouldWarn: ({ props }) => props.show,
      onChange: changeDisabilityValue,
    })(EditOccupant),
  ),
);
