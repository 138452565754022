import { useState, useMemo, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from 'react-query';

// Components
import {
  useTableFilterSortSearchManager,
  useTableManageColumns,
  useTableFilterSortData,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';

// Hooks
import useCertificationsQuery from '../../hooks/data-fetching/useCertificationsQuery';
import useSelectedProperty from '../../hooks/useSelectedProperty';
import useDownloadDocumentMutation from '../../hooks/data-modifying/useDownloadDocumentMutation';
import useGenerate59AMutation from '../../hooks/data-modifying/useGenerate59AMutation';
import useUploadSigned59AMutation from '../../hooks/data-modifying/useUploadSigned59AMutation';
import useEditGrossRentCertificationMutation from './hooks.useEditGrossRentCertificationMutation';
import useRows from './hooks.useRows';
import useHeaders from './hooks.useHeaders';
import useParseResults from './hooks.useParseResults';
import useModalProps from '../../hooks/useModalProps';
import useCSVExport from './hooks.useCSVExport';
import useUniqueTableName from '../../hooks/useUniqueTableName';
import useCheckboxColumn from './hooks.useCheckboxesColumn';
import useAbortController from '../../hooks/useAbortController';

import PropertyService from '../../services/propertyService';

// Constants
import { PROPERTY_PATH_MAP, COUNT_NAME } from './constantsV2';
import {
  GROSS_RENT_CERT_TYPE,
  RECERT_CERT_TYPE,
} from '../../constants/affordableCertificationTypes';
import { COMPLIANCE_APPROVAL_STATUSES_LOWERCASE } from '../../constants/affordableComplianceApprovalStatuses';

const useFetchPropertyAffordableSetup = ({
  organizationId,
  propertyId,
}: {
  organizationId: string,
  propertyId: string,
}) => {
  const propertyService = new PropertyService();
  const abortControllerOptions = useAbortController();

  const queryKey = [
    'useFetchPropertyAffordableSetup',
    organizationId,
    propertyId,
  ];

  const { data, isLoading } = useQuery(
    queryKey,
    () =>
      propertyService.getPropertyAffordableSetUp(
        organizationId,
        propertyId,
        abortControllerOptions,
      ),
    {
      enabled: true,
      refetchInterval: 60000,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      staleTime: 59900,
    },
  );

  return {
    affordableSetup: data,
    isLoading,
  };
};

export const useManageCertifications = ({ NAME }) => {
  const selectedProperty = useSelectedProperty();
  const [selectedCertificationType, setSelectedCertificationType] =
    useState('none');

  const name = useUniqueTableName(`${NAME}_${selectedCertificationType}`);
  const hasSelectedCertificationType = useMemo(
    () =>
      Boolean(
        selectedCertificationType?.length &&
          selectedCertificationType !== 'none',
      ),
    [selectedCertificationType],
  );

  const { affordableSetup } = useFetchPropertyAffordableSetup({
    organizationId: selectedProperty?.organizationId,
    propertyId: selectedProperty?.id,
  });

  // Fetch Certifications
  // memoize query args to prevent unnecessary re-renders
  const certificationQueryArgs = useMemo(() => {
    return {
      organizationId: selectedProperty?.organizationId,
      propertyId: selectedProperty?.id,
      certificationType: selectedCertificationType,
      options: {
        enabled: Boolean(
          selectedProperty?.id &&
            selectedProperty?.organizationId &&
            hasSelectedCertificationType,
        ),
      },
    };
  }, [
    selectedProperty,
    selectedCertificationType,
    hasSelectedCertificationType,
  ]);
  const {
    data: { results, summary, signed59ADocumentType },
    isLoading,
    refetch: refetchCertifications,
  } = useCertificationsQuery(certificationQueryArgs);

  const { grossRentEnhancements: grossRentEnhancementsFlag } = useFlags();

  // Parse results
  const parsedResults = useParseResults({ results, selectedCertificationType });

  // Handle checkboxes column
  const { checkboxList, handleSelectRow, handleSelectDeSelectAllRows } =
    useCheckboxColumn({ parsedResults, grossRentEnhancementsFlag });

  // Manage Headers
  const headers = useHeaders({
    results,
    selectedCertificationType,
    grossRentEnhancementsFlag,
    checkBoxListProps: {
      handleSelectDeSelectAllRows,
    },
  });

  const initialFilterState = useMemo(
    () =>
      ({
        [RECERT_CERT_TYPE]: {
          status: [
            COMPLIANCE_APPROVAL_STATUSES_LOWERCASE.approved,
            COMPLIANCE_APPROVAL_STATUSES_LOWERCASE.pending,
            COMPLIANCE_APPROVAL_STATUSES_LOWERCASE.pendingFinalApproval,
            COMPLIANCE_APPROVAL_STATUSES_LOWERCASE.approvedPendingSign,
            COMPLIANCE_APPROVAL_STATUSES_LOWERCASE.correctionNeeded,
            COMPLIANCE_APPROVAL_STATUSES_LOWERCASE.correctionNeededFinal,
            COMPLIANCE_APPROVAL_STATUSES_LOWERCASE.notStarted,
            COMPLIANCE_APPROVAL_STATUSES_LOWERCASE.started,
            COMPLIANCE_APPROVAL_STATUSES_LOWERCASE.denied,
            COMPLIANCE_APPROVAL_STATUSES_LOWERCASE.onNoticeHold,
          ], // filter out complete by default
        },
      }[selectedCertificationType]),
    [selectedCertificationType],
  );

  const {
    filterState,
    filterTypeState,
    dateState,
    order,
    orderBy,
    handleSortChange,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    searchState,
  } = useTableFilterSortSearchManager({
    name,
    initialFilterState,
    headers,
  });

  const sortedAndFilteredResults = useTableFilterSortData({
    results: parsedResults,
    order,
    orderBy,
    filterState,
    filterTypeState,
    searchState,
    dateState,
    PROPERTY_PATH_MAP: PROPERTY_PATH_MAP[selectedCertificationType] ?? {},
  });

  const handleDownloadDocument = useDownloadDocumentMutation({
    organizationId: selectedProperty?.organizationId,
    propertyId: selectedProperty?.id,
    options: {
      enabled: Boolean(
        selectedProperty?.id &&
          selectedProperty?.organizationId &&
          hasSelectedCertificationType,
      ),
      onError: (error) => {
        toastr.error(
          'Error',
          `Failed to download document ${error.toString()}`,
        );
      },
    },
  });

  const modalProps = useModalProps();

  const handleGenerate59A = useGenerate59AMutation({
    organizationId: selectedProperty?.organizationId,
    propertyId: selectedProperty?.id,
    options: {
      enabled: Boolean(
        selectedProperty?.id &&
          selectedProperty?.organizationId &&
          hasSelectedCertificationType,
      ),
      onSuccess: () => {
        toastr.success('Success', 'Successfully generated 59A');
        refetchCertifications();
      },
      onError: (error) => {
        toastr.error('Error', `Failed to generate 59A ${error.toString()}`);
      },
    },
  });

  const uploadSigned59AMutation = useUploadSigned59AMutation({
    organizationId: selectedProperty?.organizationId,
    propertyId: selectedProperty?.id,
    options: {
      enabled: Boolean(
        selectedProperty?.id &&
          selectedProperty?.organizationId &&
          hasSelectedCertificationType,
      ),
      onSuccess: () => {
        toastr.success('Success', 'Successfully uploaded signed 59A');
        modalProps.handleClose();
        refetchCertifications();
      },
      onError: (error) => {
        toastr.error(
          'Error',
          `Failed to upload signed 59A ${error.toString()}`,
        );
      },
    },
  });

  const editGrossRentCertificationMutation =
    useEditGrossRentCertificationMutation({
      organizationId: selectedProperty?.organizationId,
      propertyId: selectedProperty?.id,
      options: {
        enabled: Boolean(
          selectedProperty?.id &&
            selectedProperty?.organizationId &&
            hasSelectedCertificationType,
        ),
        onSuccess: () => {
          toastr.success(
            'Success',
            'Successfully edited gross rent certification',
          );
          modalProps.handleClose();
          refetchCertifications();
        },
        onError: (error) => {
          toastr.error(
            'Error',
            `Failed to edit gross rent certification ${error.toString()}`,
          );
        },
      },
    });

  const rows = useRows({
    handleDownloadDocument,
    handleGenerate59A,
    modalProps,
    sortedAndFilteredResults,
    selectedCertificationType,
    propertySettings: {
      completeGRCertsWithoutSigned59As:
        selectedProperty?.setup?.HUDCompleteGRCertsWithoutSigned59As,
    },
    grossRentEnhancementsFlag,
    checkBoxListProps: {
      checkboxList,
      handleSelectRow,
    },
  });

  const {
    allColumnsHidden,
    columnOptions,
    filteredHeaders,
    selectedColumns,
    handleColumnChange,
  } = useTableManageColumns({
    name,
    headers,
    firstRow: rows[0],
  });

  useEffect(() => {
    if (grossRentEnhancementsFlag && !selectedColumns.includes('checkbox')) {
      handleColumnChange([...selectedColumns, 'checkbox']);
    }
  }, [grossRentEnhancementsFlag, selectedColumns]);

  const { onCSVButtonClick } = useCSVExport({
    selectedCertificationType,
    filteredHeaders,
    rows,
    hasAnyFilters: Boolean(Object.keys(filterState)?.length),
  });

  const disableDownloadAll59AsButton = useMemo(() => {
    if (
      selectedCertificationType !== GROSS_RENT_CERT_TYPE &&
      !sortedAndFilteredResults.length
    ) {
      return true;
    }
    const cantGenerate59A = sortedAndFilteredResults.some(
      (grossRentCertification) => {
        const {
          isCorrection,
          correction,
          priorCertificationDate,
          priorActiveCert,
        } = grossRentCertification;

        const disableForMissingCorrection =
          isCorrection === 'yes' && !correction;

        return (
          !priorCertificationDate ||
          disableForMissingCorrection ||
          !!priorActiveCert
        );
      },
    );

    return !cantGenerate59A;
  }, [selectedCertificationType, sortedAndFilteredResults]);

  const propertyAffordablePrograms =
    selectedProperty?.pap?.map(
      ({ masterAffordableProgram: { name } }) => name,
    ) ?? [];

  return {
    affordableSetup,
    allColumnsHidden,
    columnOptions,
    count: rows.length ?? 0,
    countName: COUNT_NAME,
    dateState,
    disableDownloadAll59AsButton,
    editGrossRentCertificationMutation,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    hasSelectedCertificationType,
    isLoading,
    modalProps,
    name,
    onCSVButtonClick,
    order,
    orderBy,
    propertyAffordablePrograms,
    refetchCertifications,
    rows,
    searchState,
    selectedCertificationType,
    selectedColumns,
    setSelectedCertificationType,
    signed59ADocumentType,
    sortedAndFilteredResults,
    summary,
    totalCount: results?.length ?? 0,
    uploadSigned59AMutation,
  };
};
