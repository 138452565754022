import React from 'react';
import { FormattedNumber } from 'react-intl';

type Props = {
  monthlyTransaction: Object,
  roundProratedRents: boolean,
};

export const ProRatedDetails = ({
  monthlyTransaction,
  roundProratedRents,
}: Props): any => {
  const code = monthlyTransaction?.code ?? '';
  const description = monthlyTransaction?.description ?? '';
  const name = monthlyTransaction?.name ?? '';
  const amount = monthlyTransaction?.amount ?? '';
  const proRatedAmount = monthlyTransaction?.proratedAmount ?? '';

  return (
    <tr>
      <td>{code}</td>
      <td>{description}</td>
      <td>{name}</td>
      <td>
        <FormattedNumber
          value={amount}
          style={{ value: 'currency' }.value}
          currency="USD"
        />
      </td>
      <td>
        <FormattedNumber
          value={proRatedAmount}
          style={{ value: 'currency' }.value}
          currency="USD"
        />
      </td>
    </tr>
  );
};
