import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, FormGroup } from 'react-bootstrap';
import DateInput from '../../../../components/DateInput';
import { TimeInput } from './TimeInput';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { promptToaster } from '../../../App/actions';
import { connect } from 'react-redux';
import { messages } from './messages';
import { useAsyncApplicationReceived } from './hooks';
import ElementWithPermissions from '../../../../components/ElementWithPermissions';
import { validateDateMessage, validateDateTime } from './validations';
import { validateDate } from '../../../../utils/validations';

type Props = {
  currentRecord: Object,
  intl: Object,
  selectedProperty: Object,
  actions: Object,
};

const ApplicationReceived = ({
  currentRecord,
  intl,
  actions,
  selectedProperty,
}: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [dateTime, setDateTime] = useState(
    moment(currentRecord.applicationReceived),
  );
  const [date, setDate] = useState(moment(currentRecord.applicationReceived));
  const [time, setTime] = useState(moment(currentRecord.applicationReceived));
  const [applicationReceived, updateApplicationReceived] =
    useAsyncApplicationReceived(
      selectedProperty,
      currentRecord,
      actions.promptToaster,
      intl,
    );

  useEffect(() => {
    if (applicationReceived) {
      setDateTime(applicationReceived);
    }
  }, [applicationReceived]);

  useEffect(() => {
    if (validateDate(date) && validateDate(time)) {
      setDateTime(
        moment(
          `${date.format('MM-DD-YYYY')} ${time.format('hh:mm A')}`,
          'MM-DD-YYYY hh:mm A',
        ),
      );
    } else {
      setDateTime('');
    }
  }, [date, time]);

  const handleClick = () => {
    if (!validateDateTime(dateTime)) {
      if (editMode) {
        updateApplicationReceived(dateTime);
      }
      setEditMode(!editMode);
    }
  };

  return (
    <FormGroup>
      <h4 style={{ display: 'inline-block' }}>
        {intl.formatMessage(messages.title)}
      </h4>
      <ElementWithPermissions scope={['application-received-edit']}>
        <a style={{ marginLeft: 6 }} onClick={handleClick}>
          {editMode && intl.formatMessage(messages.save)}{' '}
          <i className={`icon ${editMode ? 'icon et-floppy' : 'et-pencil'}`} />
        </a>
      </ElementWithPermissions>
      <Row>
        <Col md={editMode ? 2 : 1} style={{ paddingRight: 'unset' }}>
          {editMode ? (
            <DateInput
              value={date}
              onChange={setDate}
              error={validateDateMessage(dateTime, intl)}
            />
          ) : (
            dateTime.format('MM-DD-YYYY')
          )}
        </Col>
        <Col md={editMode ? 2 : 1}>
          {editMode ? (
            <TimeInput
              value={time}
              onChange={setTime}
              error={validateDateTime(dateTime)}
            />
          ) : (
            dateTime.format('hh:mm A')
          )}
        </Col>
      </Row>
    </FormGroup>
  );
};

const InjectedApplicationReceived = injectIntl(ApplicationReceived);

export const mapStateToProps = ({
  app: { selectedProperty },
}: Object): Object => {
  return {
    selectedProperty,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  actions: bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedApplicationReceived);
