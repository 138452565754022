import React from 'react';
import { Link } from 'react-router-dom';
import KPICardContainer from '../../../components/KPICardContainer';
import { AsyncBox } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import messages from './messages';
import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

const Eviction = ({
  data,
  formatMessage,
  dataLoaded,
  formatNumber,
}: Object) => {
  return (
    <KPICardContainer title={formatMessage(messages.title)}>
      <AsyncBox loading={!dataLoaded}>
        <div className="padtop30">
          <div className="text-center">
            <span className="font30 text-blue">
              <Link
                to={getUrlWithSelectedPropertyId(
                  '/manage-delinquent-and-prepaid?redirectedFrom=home-kpi',
                )}
                style={{ fontWeight: 400 }}
              >
                {data.evictionUnits}
              </Link>
            </span>
            <h5>{formatMessage(messages.units)}</h5>
          </div>
          <div className="row padtop20">
            <div className="col-xs-12 col-sm-12 text-center">
              <span className="font-10">
                {formatMessage(messages.evictionDQ)}:{' '}
                <strong>
                  {formatNumber(data.evictionDQBalance, {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </strong>
                <span className="text-green padleft5">
                  {formatNumber(data.evictionDQBalancePercentage, {
                    style: 'percent',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </span>
            </div>
          </div>
        </div>
      </AsyncBox>
    </KPICardContainer>
  );
};

export default Eviction;
