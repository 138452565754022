import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Legend } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import messages from './messages';
import { GROSS_RENT_CERT_TYPE } from '../../constants/affordableCertificationTypes';

const ManageCertificationsLegend = ({ certificationType }) => {
  return certificationType === GROSS_RENT_CERT_TYPE ? (
    <Legend
      sx={{ paddingX: 2 }}
      data={[
        {
          key: 'signed59AMissing',
          label: <FormattedMessage {...messages.signed59AMissing} />,
          sx: { backgroundColor: 'error.lighter' },
        },
      ]}
    />
  ) : null;
};

export default ManageCertificationsLegend;
