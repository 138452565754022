import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { change } from 'redux-form';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import DocumentTitle from 'react-document-title';

import type {
  GlobalState,
  User,
  UserStatus,
  UserTitle,
  UserRole,
  Property,
} from '../App/types';
import * as editUserActions from './actions';
import * as userActions from '../CreateUser/actions';
import * as appActions from '../App/actions';
import EditUserForm from './EditUserForm';
import messages from './messages';
import confirm from '../../components/ConfirmDialogModal';

type Props = {
  user: User,
  selectedProperties: Array<string>,
  userStatuses: Array<UserStatus>,
  userTitles: Array<UserTitle>,
  userRoles: Array<UserRole>,
  properties: Array<Property>,
  userId: string,
  nonePropertiesSelected: boolean,
  hideMultiselect: boolean,
  showSuccessMessage: boolean,
  showErrorMessage: boolean,
  errorMessage: string,
  organizationId: string,
  isSubmitting: boolean,
};

type InjectedProps = {
  userActions: Object,
  editUserActions: Object,
  intl: any,
  history: any,
};

export class EditUserPage extends Component<Props & InjectedProps> {
  componentDidMount() {
    this.props.editUserActions.getUser(this.props.userId);
    this.props.userActions.getAllUserStatuses();
    this.props.userActions.getAllUserTitles();
    this.props.userActions.getAllUserRoles();
    this.props.userActions.getAllProperties();
    this.props.appActions.getAllPropertyClasses();
  }

  generateUserStatuses() {
    const userStatuses = this.props.userStatuses.map((userStatus) => ({
      value: userStatus.id,
      text: userStatus.name,
      disabled: false,
    }));
    userStatuses.unshift({
      value: 'default',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return userStatuses;
  }

  generateUserTitles() {
    const userTitles = this.props.userTitles.map((userTitle) => ({
      value: userTitle.id,
      text: userTitle.name,
      disabled: false,
    }));
    userTitles.unshift({
      value: 'default',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return userTitles;
  }

  generateUserRoles() {
    const userRoles = this.props.userRoles.map((userRole) => ({
      value: userRole.id,
      text: userRole.name,
      disabled: false,
    }));
    userRoles.unshift({
      value: 'default',
      text: this.props.intl.formatMessage(messages.chooseOption),
      disabled: true,
    });
    return userRoles;
  }

  generateProperties() {
    return this.props.properties.map((property) => ({
      value: property.id,
      label: property.name,
    }));
  }

  handleChange = (property: string) => {
    this.props.editUserActions.setSelectedProperties(property);
  };

  handlePropertiesSelectedError = (newState: boolean) => {
    this.props.editUserActions.showNonePropertiesSelectedError(newState);
  };

  handleSubmit = (user: any) => {
    const flag = this.props.flags.adminEnhancementsUsersAssigntopropertytype;
    const hasDefaultGroupSelected =
      user?.hasAffordableGroup ||
      user?.hasCommercialGroup ||
      user?.hasConventionalGroup;
    const hasNoProperties =
      !user?.properties?.length || user?.properties?.length === 0;
    const hasNoPropertyGroups =
      !user?.propertyGroups?.length || user?.propertyGroups?.length === 0;
    const noSelection = flag
      ? !user.allProperties &&
        hasNoProperties &&
        hasNoPropertyGroups &&
        !hasDefaultGroupSelected
      : !user.allProperties && user?.properties?.length === 0;
    if (noSelection) {
      this.handlePropertiesSelectedError(true);
    } else {
      this.props.editUserActions.editUser(user, this.props.user.id);
      this.handlePropertiesSelectedError(false);
    }
  };

  handleCancel = () => {
    confirm(this.props.intl.formatMessage(messages.cancelConfirmation), {
      intl: this.props.intl,
    }).then(() => {
      this.props.history.replace('/manage-users');
      this.handlePropertiesSelectedError(false);
    });
  };

  toggleMultiselect = (ev: any, value: boolean) => {
    this.props.editUserActions.toggleMultiselect(value);
  };

  handleAllPropertiesSelection = (val: any) => {
    this.props.editUserActions.setUserAllProperties(val);
  };

  handlePropertySelectionChange = (properties: Array<string>) => {
    this.props.editUserActions.setSelectedProperties(properties);
  };

  handleDefaultGroups = (groups: Array<string>) => {
    // filter out predefined values affordable/conventional/commercial
    const hasAffordableGroup = groups.some((gid) => gid === 'AffordableGroup');
    const hasConventionalGroup = groups.some(
      (gid) => gid === 'ConventionalGroup',
    );
    const hasCommercialGroup = groups.some((gid) => gid === 'CommercialGroup');

    const groupIdsToFilterOut = [
      'AffordableGroup',
      'ConventionalGroup',
      'CommercialGroup',
    ];
    const filteredGroups = groups.filter(
      (groupId) => !groupIdsToFilterOut.includes(groupId),
    );
    this.props.reduxFormActions.change(
      'editUser',
      'propertyGroups',
      filteredGroups,
    );
    this.props.reduxFormActions.change(
      'editUser',
      'hasAffordableGroup',
      hasAffordableGroup,
    );
    this.props.reduxFormActions.change(
      'editUser',
      'hasConventionalGroup',
      hasConventionalGroup,
    );
    this.props.reduxFormActions.change(
      'editUser',
      'hasCommercialGroup',
      hasCommercialGroup,
    );
  };

  handlePropertyGroupSelectionChange = (groups: Array<string>) => {
    this.handleDefaultGroups(groups);
    this.props.editUserActions.setSelectedPropertyGroups(groups);
  };

  render() {
    const userStatuses = this.generateUserStatuses();
    const userTitles = this.generateUserTitles();
    const userRoles = this.generateUserRoles();
    const userProperties = this.generateProperties();
    const {
      user: {
        firstName,
        lastName,
        emailAddress,
        username,
        allProperties,
        userStatusId,
        userTitleId,
        userRoleId,
        hasAffordableGroup,
        hasCommercialGroup,
        hasConventionalGroup,
        propertyGroups,
      },
      properties: propertyList,
      propertyClasses,
      isSubmitting,
      organizationId,
      selectedPropertyGroups,
    } = this.props;
    const initialPropertyGroups = propertyGroups?.map((pg) => pg.id);
    return (
      <DocumentTitle title={this.props.intl.formatMessage(messages.title)}>
        <EditUserForm
          initialValues={{
            firstName,
            lastName,
            emailAddress,
            username,
            allProperties,
            userStatusId,
            userTitleId,
            userRoleId,
            organizationId,
            hasAffordableGroup,
            hasCommercialGroup,
            hasConventionalGroup,
            propertyGroups: initialPropertyGroups,
          }}
          intl={this.props.intl}
          userStatuses={userStatuses}
          userTitles={userTitles}
          userRoles={userRoles}
          properties={userProperties}
          propertyList={propertyList}
          selectedProperties={this.props.selectedProperties}
          selectedPropertyGroups={selectedPropertyGroups}
          hideMultiselect={this.props.hideMultiselect}
          toggleMultiselect={this.toggleMultiselect}
          handleCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          handleMultiselectChange={this.handleChange}
          handlePropertiesSelectedError={this.handlePropertiesSelectedError}
          handleAllPropertiesSelection={this.handleAllPropertiesSelection}
          nonePropertiesSelected={this.props.nonePropertiesSelected}
          isSubmitting={isSubmitting}
          propertyClasses={propertyClasses}
          organizationId={organizationId}
          allPropertiesSelected={allProperties}
          handlePropertySelectionChange={this.handlePropertySelectionChange}
          handlePropertyGroupSelectionChange={
            this.handlePropertyGroupSelectionChange
          }
        />
      </DocumentTitle>
    );
  }
}

export const mapStateToProps = (
  { editUser, createUser, app }: GlobalState,
  ownProps: Object,
) => {
  return {
    user: editUser.user,
    nonePropertiesSelected: editUser.nonePropertiesSelected,
    selectedProperties: editUser.selectedProperties,
    selectedPropertyGroups: editUser.selectedPropertyGroups,
    hideMultiselect: editUser.hideMultiselect,
    userStatuses: createUser.userStatuses,
    userTitles: createUser.userTitles,
    userRoles: createUser.userRoles,
    properties: createUser.properties,
    userId: ownProps.match.params.userId,
    organizationId: app.currentUser ? app.currentUser.user.organizationId : '',
    isSubmitting: editUser.isSubmitting,
    propertyClasses: app.propertyClasses,
  };
};

export function mapDispatchToProps(dispatch: any) {
  return {
    editUserActions: bindActionCreators(editUserActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    reduxFormActions: bindActionCreators(
      {
        change,
      },
      dispatch,
    ),
  };
}

const InjectedEditUserPage = injectIntl(withLDConsumer()(EditUserPage));
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedEditUserPage);
