export const START_DATE_MODAL_MODES = {
  CREATE: 'create',
  EDIT: 'edit',
  START_NEW: 'startNew',
};

export const MODAL_STATES = {
  NONE: 'none',
  CREATE_NEW_CAM: 'createNewCam',
  EDIT_START_DATE: 'editStartDate',
  START_NEW_CAM: 'startNewCam',
  REASSIGN_TRANSACTIONS: 'reassignTransactions',
};

export const CAM_STATUSES = {
  STARTED: 'Started', // CAM Record created, no allocation is set up yet
  IN_PROCESS: 'In Process', // At least one allocation has been set up
  CONFIRMED_PENDING: 'Estimates Confirmed - Pending Start Date', // estimates have been confirmed but
  // CAM Start date is not reached yet
  CURRENT: 'Current', // Estimates have been confirmed and CAM start date is today or before
  PRIOR_YEAR: 'Prior Year', // A new CAM hexists but this CAM has no reconciliation
  PRIOR_YEAR_RECONCILED: 'Prior Year - Reconciled', // A new CAM exists and this one has at least 1 reconciliation
};

export const CAM_STATUSES_CANCELLABLE = [
  CAM_STATUSES.STARTED,
  CAM_STATUSES.IN_PROCESS,
  CAM_STATUSES.CONFIRMED_PENDING,
];

export const CAM_STATUSES_CAN_START_NEW = [CAM_STATUSES.CURRENT];

export const CAM_STATUSES_CAN_POST_RECONCILIATION = [
  CAM_STATUSES.CURRENT,
  CAM_STATUSES.PRIOR_YEAR,
  CAM_STATUSES.PRIOR_YEAR_RECONCILED,
];

export const CAM_STATUSES_NOT_CONFIRMED = [
  CAM_STATUSES.STARTED,
  CAM_STATUSES.IN_PROCESS,
];
