import { pathOr, isNil, isEmpty, omit } from 'ramda';
import moment from 'moment';

import type { Transaction } from '../containers/CamTab/Sections/ReassignTransactionsModal/types';

import { isBetweenDates } from './date-helpers';

type CamInfo = {
  useCamForReversal: boolean, // Should the reversal transaction be applied to a CAM Pool
  useSameCamPool: boolean, // Should we use the same CAM Pool for the reversal
  showCamPoolFields: boolean, // Should we show CAM Pool fields to let users select a CAM Pool
};

/**
 * - If original charge/credit AND reversal happen during the CAM period (and original charge was applied to a CAM):
 *   Automatically apply reversal to the same CAM as the original charge
 * - If reversal happens OUTSIDE of the CAM period applied to the original charge:
 *   Show the CAM fields to let users select which CAM to apply the reversal to
 * @param  {} {singleTransaction
 * @param  {} currentCamPool
 * @param  {Props} }
 * @returns CamInfo
 */
export const getTransactionReversalCamInfo = ({
  singleTransaction,
  currentCamPool,
  camPools,
}: Props): CamInfo => {
  const camStartDate = pathOr(null, ['startDate'], currentCamPool);
  const camEndDate = pathOr(null, ['endDate'], currentCamPool);
  if (isNil(currentCamPool) || isNil(camStartDate) || isNil(camEndDate)) {
    // Original transaction is not applied to a CAM Pool, reversal should not be either
    return {
      useCamForReversal: false,
      useSameCamPool: false,
      showCamPoolFields: false,
    };
  }

  const mCamStartDate = moment(camStartDate);
  const mCamEndDate = moment(camEndDate);

  const originalTransactionDate = pathOr(
    null,
    ['transactionDate'],
    singleTransaction,
  );
  const mOriginalTransactionDate = moment(originalTransactionDate);
  const mReversalDate = moment();

  const isOriginalInCam = isBetweenDates(
    mOriginalTransactionDate,
    mCamStartDate,
    mCamEndDate,
    'day',
  );
  const isReversalInCam = isBetweenDates(
    mReversalDate,
    mCamStartDate,
    mCamEndDate,
    'day',
  );

  const useSameCamPool = isOriginalInCam && isReversalInCam;

  // If we're not using the same CAM pool, we should show the drop downs to let users pick a CAM pool,
  // UNLESS for some reason there's no CAM pools available for this household, in which case the reversal
  // can't be applied to any CAM Pool.
  const hasAvailableCamPools = !isNil(camPools) && !isEmpty(camPools);
  const useCamForReversal = useSameCamPool || hasAvailableCamPools;
  const showCamPoolFields = !useSameCamPool && hasAvailableCamPools;
  return {
    useSameCamPool,
    showCamPoolFields,
    useCamForReversal,
  };
};

/**
 * When applying a CAM Pool to a Transaction (CustomerOpsLedger), we only want to show CAM records that are:
 * - Current on the transaction date
 * - Future to the transaction date
 * - Immediately prior to the transaction date's CAM period
 * @param  {Date} transactionDate
 * @param  {Array<Object>} camPools
 * @returns Array
 */
export const filterPeriodsForTransactionDate = (
  transactionDate: Date,
  camPools: Array<Object>,
): Array<Object> => {
  const mTransDate = moment(transactionDate);

  // Order is by startDate desc, so we keep all until we find the current CAM for this transaction,
  // plus the next one, which would be the immediate prior period.
  let foundCurrent = false;
  const filtered = camPools.filter((p) => {
    const mPoolStartDate = moment(pathOr(null, ['startDate'], p));
    const mPoolEndDate = moment(pathOr(null, ['endDate'], p));
    const isFuture = mPoolStartDate.isSameOrAfter(mTransDate, 'day');
    const isCurrent = isBetweenDates(
      mTransDate,
      mPoolStartDate,
      mPoolEndDate,
      'day',
    );
    if (isCurrent) {
      foundCurrent = true;
    }
    const isImmediatePast =
      mPoolEndDate.isBefore(mTransDate, 'day') && foundCurrent;
    if (isImmediatePast) {
      foundCurrent = false;
    }

    return isCurrent || isFuture || isImmediatePast;
  });

  return filtered;
};

export const filterCamPoolsInYear = (
  year: number,
  camPools: Array<Object>,
): Array<Object> => {
  if (isNil(camPools)) {
    return [];
  }

  const filtered = camPools.filter((p) => {
    const mPoolStartDate = moment(pathOr(null, ['startDate'], p));
    return mPoolStartDate.year() === year;
  });
  return filtered;
};

export const filterCamPoolsRemoveCam = (
  camRecordIdToIgnore: string,
  camPools: Array<Object>,
): Array<Object> => {
  if (isNil(camPools)) {
    return [];
  }

  if (isNil(camRecordIdToIgnore)) {
    return camPools;
  }

  const filtered = camPools.filter((p) => {
    const camId = pathOr(null, ['camRecordsId'], p);
    return camId !== camRecordIdToIgnore;
  });
  return filtered;
};

export const removePoolsFromTransactions = (
  transactions: Array<Transaction>,
): Array<Transaction> => {
  return (transactions || []).map((t) =>
    omit(['camRecordsId', 'camAllocationsId'], t),
  );
};

export const generateDropdownOptions = (
  data: Array<Object>,
  shape: Object,
): Array<Object> => {
  return data.map((datum) => {
    return {
      value: pathOr('', shape.value, datum),
      text: pathOr('', shape.text, datum),
    };
  });
};

export const getCamPeriodsOptions = (
  camPools: Array<Object>,
  defaultOption: Object,
  setFormValues: Function,
  formValues: Object,
): Array<any> => {
  const camRecord = formValues.camRecord || defaultOption.value;

  const hasCamPools = !isNil(camPools) && !isEmpty(camPools);
  if (!hasCamPools) {
    return [defaultOption];
  }
  const periodsOptions = [
    ...generateDropdownOptions(camPools, {
      value: ['camRecordsId'],
      text: ['startDate'],
    }),
  ];
  if (periodsOptions.length !== 1) {
    periodsOptions.unshift(defaultOption);
  } else if (camRecord !== periodsOptions[0].value) {
    setFormValues({
      ...formValues,
      camRecord: periodsOptions[0].value,
    });
  }

  return periodsOptions;
};

export const getCamPeriodsDropdownOptions = (
  camPools: Array<Object>,
  defaultOption: Object,
  setFormValues: Function,
  formValues: Object,
): Array<any> => {
  const periodsOptions = getCamPeriodsOptions(
    camPools,
    defaultOption,
    setFormValues,
    formValues,
  );
  const rendered = periodsOptions.map((option) => (
    <option
      key={option.value || 'undefinedValue'}
      value={option.value}
      disabled={option.disabled ? option.disabled : false}
    >
      {option.text}
    </option>
  ));
  return rendered;
};

export const getCamPoolsOptions = (
  camPools: Array<Object>,
  defaultOption: Object,
  setFormValues: Function,
  formValues: Object,
): Array<any> => {
  const camPool = formValues.camPool || defaultOption.value;
  const camRecord = formValues.camRecord || defaultOption.value;

  const hasCamPools = !isNil(camPools) && !isEmpty(camPools);
  if (!hasCamPools) {
    return [defaultOption];
  }

  const selectedCam = camPools.find((p) => p.camRecordsId === camRecord);
  const pools = pathOr(null, ['camPools'], selectedCam);
  if (!pools) {
    return [defaultOption];
  }

  const poolsOptions = [
    ...generateDropdownOptions(pools, {
      value: ['camAllocationsId'],
      text: ['camPoolName'],
    }),
  ];
  if (poolsOptions.length !== 1) {
    poolsOptions.unshift(defaultOption);
  } else if (camPool !== poolsOptions[0].value) {
    setFormValues({
      ...formValues,
      camPool: poolsOptions[0].value,
    });
  }

  return poolsOptions;
};

export const getCamPoolsDropdownOptions = (
  camPools: Array<Object>,
  defaultOption: Object,
  setFormValues: Function,
  formValues: Object,
): Array<any> => {
  const poolsOptions = getCamPoolsOptions(
    camPools,
    defaultOption,
    setFormValues,
    formValues,
  );
  const rendered = poolsOptions.map((option) => (
    <option
      key={option.value || 'undefinedValue'}
      value={option.value}
      disabled={option.disabled ? option.disabled : false}
    >
      {option.text}
    </option>
  ));
  return rendered;
};
