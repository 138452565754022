import { isNil } from 'ramda';
import moment from 'moment';
import {
  validateName,
  validateRequired,
  validateSocialSecurityNumber,
  validateAlphaNumericValue,
} from '../../../utils/validations';

import { extractCurrentDateFormat } from '../../../utils/redux-form-helper';

import formMessages from '../../../containers/ShortLeaseApplication/messages';

import type { BasicInformation } from './types';

const validate = (values: BasicInformation, { intl }: Object) => {
  const errors = {};

  if (isNil(values)) {
    ['firstName', 'lastName'].forEach(
      (fieldName) =>
        (errors[fieldName] = intl.formatMessage(formMessages.required)),
    );
    return errors;
  }

  ['firstName', 'lastName', 'birthday'].forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      errors[fieldName] = intl.formatMessage(formMessages.required);
    }
  });

  ['firstName', 'middleName', 'lastName', 'preferredName'].forEach(
    (fieldName) => {
      if (
        !errors[fieldName] &&
        values[fieldName] &&
        !validateName(values[fieldName])
      ) {
        errors[fieldName] = intl.formatMessage(formMessages.invalidValue);
      }
    },
  );

  if (values.birthday) {
    const currentDateFormat = extractCurrentDateFormat(intl);
    const birthday = moment(values.birthday, currentDateFormat, true);
    if (!birthday.isValid()) {
      errors.birthday = intl.formatMessage(formMessages.invalidValue);
    }
    const birthdayStr = moment(birthday).format();
    const longTimeAgo = moment('01/01/1901', 'MM/DD/YYYY').format();
    if (birthdayStr < longTimeAgo) {
      errors.birthday = intl.formatMessage(formMessages.invalidValue);
    }
  }

  if (
    values.socialSecurityNumber &&
    !validateSocialSecurityNumber(values.socialSecurityNumber)
  ) {
    errors.socialSecurityNumber = intl.formatMessage(formMessages.invalidValue);
  }

  if (
    values.alienRegistration &&
    !validateAlphaNumericValue(values.alienRegistration)
  ) {
    errors.alienRegistration = intl.formatMessage(formMessages.invalidValue);
  }

  [
    { fieldName: 'stateIdNumber', peer: 'stateIdOrigin' },
    { fieldName: 'passportNumber', peer: 'passportCountry' },
  ].forEach((field) => {
    const val = values[field.fieldName];
    if (!val) return;

    if (!validateAlphaNumericValue(val)) {
      errors[field.fieldName] = intl.formatMessage(formMessages.invalidValue);
    }

    if (values[field.peer] === 'default') {
      const msg = intl.formatMessage(formMessages[`${field.peer}PeerRequired`]);
      errors[field.fieldName] = msg;
      errors[field.peer] = msg;
    }
  });

  return errors;
};

export default validate;
