import React from 'react';
import { Field } from 'redux-form';

import {
  renderSelectField,
  renderTextField,
  renderDateField,
  renderCurrencyField,
} from '../../../../../../utils/redux-form-helper';

import { YES_NO_SELECT_OPTIONS, FULL_DATE_FORMAT } from '../../constants';

import { FlexFormInput } from '../../common';

const SecurityDepositOptionsFormFields = ({ values }: Object) => {
  return (
    <React.Fragment>
      <FlexFormInput>
        <Field
          name="Returnable Security Deposit"
          selectClassNames="input-md form-control"
          component={renderSelectField}
          label="Returnable Security Deposit"
          options={YES_NO_SELECT_OPTIONS}
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Date to Return SD"
          component={renderDateField}
          classes="control-label"
          dateFormat={FULL_DATE_FORMAT}
          label="Date to Return SD"
          bsSize="md"
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Terms"
          component={renderTextField}
          label="Terms"
          maxLength="255"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Amount of Original SD"
          component={renderCurrencyField}
          label="Amount of Original SD"
          step="0.01"
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Returnable Amount"
          component={renderCurrencyField}
          label="Returnable Amount"
          step="0.01"
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Termination of Offer"
          component={renderTextField}
          label="Termination of Offer"
          maxLength="255"
          showLabel
        />
      </FlexFormInput>
      <FlexFormInput>
        <Field
          name="Property Desired"
          component={renderTextField}
          label="Property Desired"
          maxLength="255"
          showLabel
        />
      </FlexFormInput>
    </React.Fragment>
  );
};

export default SecurityDepositOptionsFormFields;
