import React from 'react';
import { Select } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { ControlLabel } from 'react-bootstrap';
import styled from 'styled-components';
import * as R from 'ramda';
import type { Option } from './types';

type Props = {
  label: string,
  options: Option[],
  onChange: Function,
  value: Option[],
  name: string,
  width: string,
  placeholder: string,
  renderValue?: Function,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${R.prop('width')};

  & span {
    top: 0px;
  }
`;

function MultiSelectControl({
  label,
  options,
  onChange,
  value,
  name,
  width,
  placeholder,
  renderValue,
  disabled = false,
  ...otherProps
}: Props) {
  const handleClear = () => {
    onChange({ target: { value: [] } });
  };
  return (
    <Wrapper width={width}>
      <ControlLabel>{label}</ControlLabel>
      <Select
        {...otherProps}
        options={options}
        value={value}
        name={name}
        onChange={onChange}
        onClearSelected={handleClear}
        placeholder={placeholder}
        multiple
        renderValue={renderValue}
        fullWidth
        disabled={disabled}
      />
    </Wrapper>
  );
}

export default MultiSelectControl;
