import { pathOr } from 'ramda';
import React from 'react';
import styled from 'styled-components';

type StatusSummaryBoxProps = {
  summary: Object,
  complianceApprovalStatuses: Array<Object>,
  propertyAffordablePrograms: Array<Object>,
};

export const StatusSummaryBox = ({
  summary,
  complianceApprovalStatuses,
  propertyAffordablePrograms,
}: StatusSummaryBoxProps) => {
  const StyledStatRow = styled.div`
    font-size: 14px;
    font-weight: 400;
  `;
  return complianceApprovalStatuses.map((status: Object) => {
    const { id: statusId, name: statusName } = status;
    const statusSummary = summary[statusId];
    const programs = pathOr({}, ['programs'], statusSummary);
    return (
      <div className="block-data block-white-shadow" key={statusId}>
        <h5>{statusName}</h5>
        <div>
          {propertyAffordablePrograms.map((program: Object) => {
            const { id: programId, name: programName } = program;
            const val = programs[programName] || 0;
            return (
              <StyledStatRow
                key={programId}
              >{`${programName} - ${val}`}</StyledStatRow>
            );
          })}
        </div>
      </div>
    );
  });
};
