import { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { toastr } from 'react-redux-toastr';
import FileSaver from 'file-saver';
import {
  MenuItem,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { MenuButton } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { DocumentLibraryModal } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { PlusIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useHasPermission from '../../../hooks/useHasPermission';
import useSelectedProperty from '../../../hooks/useSelectedProperty';
import { get, apiURL, requestToDownload } from '../../../utils/api';
import messages from './messages';

const MENU_WIDTH = '200px';

const handleGenerateAddenda = async ({
  organizationId,
  propertyId,
  leaseId,
  addendumIds,
}) => {
  try {
    toastr.info('Pending', 'Generating addenda...');

    const file = await requestToDownload(
      `${apiURL}/${organizationId}/${propertyId}/lease/${leaseId}/generate-addenda`,
      {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('session_id'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          addendumIds,
        }),
      },
    );

    FileSaver.saveAs(file, 'addenda.pdf');
  } catch (error) {
    let message = typeof error === 'string' ? error : error.toString();

    if (message.includes('[object Object]')) {
      message = 'Failed to generate.';
      console.error(error); // eslint-disable-line
    }

    toastr.error('Error', message);
  }
};

function CreateDocumentMenu({
  intl,
  onCreateResidentLetter,
  isAllCommercial,
  isAffordable,
  isCommercial,
  leaseId,
}) {
  const unitType = isAffordable
    ? 'Affordable'
    : isCommercial
    ? 'Commercial'
    : 'Conventional';
  const [isAddendaLibraryModalOpen, setIsAddendaLibraryModalOpen] =
    useState(false);
  const { documentManagementMvp } = useFlags();
  const hasGenerateResidentLetterPermission = useHasPermission(
    'generate-resident-letter',
  );
  const selectedProperty = useSelectedProperty();
  const {
    data: addenda,
    isLoading: isAddendaLoading,
    isRefetching,
    refetch,
  } = useQuery(
    ['addenda', selectedProperty.organizationId, selectedProperty.id],
    () =>
      get(
        `/${selectedProperty.organizationId}/centralized-documents` +
          `/lease-addendums/property/${selectedProperty.id}?unitType=${unitType}`,
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const addendaItems = useMemo(
    () =>
      addenda?.map((addendum) => ({
        value: addendum.id,
        label: addendum.name,
      })),
    [addenda],
  );

  return (
    <>
      <MenuButton
        variant="primarySubtle"
        ButtonProps={{
          startIcon: <PlusIcon />,
        }}
        MenuProps={{
          children: [
            <MenuItem
              sx={{ width: MENU_WIDTH }}
              onClick={onCreateResidentLetter}
              disabled={hasGenerateResidentLetterPermission !== true}
            >
              <Typography>
                {isAllCommercial
                  ? 'Tenant Letter Library'
                  : 'Resident Letter Library'}
              </Typography>
            </MenuItem>,
            documentManagementMvp && isCommercial === false && (
              <MenuItem
                sx={{ width: MENU_WIDTH }}
                onClick={() => {
                  refetch();
                  setIsAddendaLibraryModalOpen(true);
                }}
              >
                <Typography>Lease Addenda Library</Typography>
              </MenuItem>
            ),
          ],
        }}
        sx={{ marginRight: 1, width: MENU_WIDTH }}
      >
        {intl.formatMessage(messages.createDocument)}
      </MenuButton>
      <DocumentLibraryModal
        open={isAddendaLibraryModalOpen}
        title="Lease Addenda Library"
        subtitle={
          'If the generated addendum includes household lease details ' +
          '(e.g., Lease Start Date or End Date), it will reflect the details of the currently active lease.'
        }
        items={addendaItems}
        onClose={() => {
          setIsAddendaLibraryModalOpen(false);
        }}
        onGenerateDocument={(addendumIds) => {
          handleGenerateAddenda({
            organizationId: selectedProperty.organizationId,
            propertyId: selectedProperty.id,
            leaseId,
            addendumIds,
          });
        }}
        itemsLoading={isAddendaLoading || isRefetching}
      />
    </>
  );
}

export default CreateDocumentMenu;
