import { useEffect, useState } from 'react';

import KpiService from '../../services/kpiService';
import componentMessages from './messages';
import generalMessages from '../App/messages';
import { COMPLIANCE_TYPES } from './constants';

const messages = { ...componentMessages, ...generalMessages };

export const useFetchComplianceOverview = ({
  organizationId,
  intl,
  toasterFn,
  complianceOverviewReviewedAndNumberOfDays,
  complianceType,
  setIsLoading,
  isActive,
}: {
  organizationId: string,
  intl: { formatMessage: Function },
  toasterFn: Function,
  complianceOverviewReviewedAndNumberOfDays: boolean,
  complianceType: string,
  setIsLoading: Function,
  isActive: boolean,
}) => {
  const [complianceOverviewData, setComplianceOverviewData] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    let subscribed = true;

    const fetchComplianceOverview = async () => {
      const kpiService = new KpiService();
      const options = { signal: abortController.signal };
      try {
        let results = [];
        if (!complianceOverviewReviewedAndNumberOfDays) {
          results = await kpiService.getComplianceOverview(
            organizationId,
            options,
          );
        } else if (complianceType === COMPLIANCE_TYPES.ALL_OPEN_CERTS) {
          results = await kpiService.getComplianceOverviewAllOpenCerts(
            organizationId,
            options,
          );
        } else {
          results = await kpiService.getComplianceOverviewNeedsReview(
            organizationId,
            options,
          );
        }
        setComplianceOverviewData(results);
        if (results.length === 0) {
          toasterFn({
            type: 'info',
            message: intl.formatMessage(messages.noDataDescription),
            options: {
              showCloseButton: true,
              removeOnHover: true,
            },
          });
        }
      } catch (error) {
        toasterFn({
          type: 'error',
          message: intl.formatMessage(messages.getComplianceOverviewError),
          title: intl.formatMessage(messages.error),
        });
      } finally {
        setIsLoading(false);
      }
    };

    if (subscribed && isActive) {
      setIsLoading(true);
      fetchComplianceOverview();
    }

    return () => {
      subscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organizationId,
    complianceOverviewReviewedAndNumberOfDays,
    isActive,
    complianceType,
  ]);

  return complianceOverviewData;
};
