import type { Action } from '../App/types';
import type { Deposit } from './types.js';
import {
  GET_ALL_DEPOSITS,
  GET_ALL_DEPOSITS_SUCCESS,
  GET_ALL_DEPOSITS_ERROR,
  CLEAR_LOADED_DEPOSITS,
  SORT_DEPOSITS_BY_DATE
} from './constants';

export function getAllDeposits(period: string = ''): Action<any> {
  return {
    type: GET_ALL_DEPOSITS,
    payload: {
      period
    }
  };
}

export function getAllDepositsSuccess(
  response: Array<Deposit>
): Action<Array<Deposit>> {
  return {
    type: GET_ALL_DEPOSITS_SUCCESS,
    payload: response
  };
}

export function getAllDepositsError(error: Error): Action<Error> {
  return {
    type: GET_ALL_DEPOSITS_ERROR,
    payload: error,
    error: true
  };
}

export function clearLoadedDeposits(): Action<any> {
  return {
    type: CLEAR_LOADED_DEPOSITS,
    payload: undefined
  };
}

export const sortDepositsByDate = () => ({
  type: SORT_DEPOSITS_BY_DATE
});
