// $FlowFixMe
import React, { useState } from 'react';
import messages from './messages';
import { Modal, Col, Row } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { pathOr } from 'ramda';
import { FORM_NAME } from './constants';
import WorkOrderPreferencesForm from './WorkOrderPreferencesForm';
import ConfirmOverlay from '../ConfirmOverlay';
import { selectSelectedProperty } from '../../containers/App/selectors';
import { promptToaster } from '../../containers/App/actions';
import {
  useUnitEntryPreferences,
  useWorkOrderPreferences,
  parseFormValues,
} from './hooks';

type ConnectedProps = {
  anyTouched: boolean,
  invalid: boolean,
  context: Object,
};

type ConnectedMethods = {
  actions: {
    submit: Function,
    promptToaster: Function,
  },
};

type Props = ConnectedProps &
  ConnectedMethods & {
    onHide: Function,
    property: Object,
    show: boolean,
    profileContext: Object,
    intl: any,
    change: Function,
  };

const WorkOrderHouseholdPreferenceModal = ({
  actions,
  anyTouched,
  invalid,
  onHide,
  property,
  show,
  context,
  profileContext,
  intl,
}: Props) => {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const [entryPreferences] = useUnitEntryPreferences(context);

  const [updateWorkOrderPreferences, workOrderPreferences] =
    useWorkOrderPreferences(
      {
        ...context,
        ...profileContext,
      },
      actions.promptToaster,
      intl,
    );

  let initial = parseFormValues(workOrderPreferences, entryPreferences);
  const hideCancelConfirmation = () => setShowCancelConfirmation(false);

  const handleHide = () => {
    if (anyTouched && !showCancelConfirmation) {
      setShowCancelConfirmation(true);
      return;
    }
    setShowCancelConfirmation(false);
    if (onHide) onHide();
    onHide();
  };

  const handleSubmit = (values: Object) => {
    const { householdId, unitId } = profileContext;
    const payload = {
      ...values,
      alarmCode: values.isAlarmActive === 'yes' ? values.alarmCode : '',
      isAlarmActive: values.isAlarmActive === 'yes' ? true : false,

      doorCode: values.isDoorCodeActive === 'yes' ? values.doorCode : '',
      isDoorCodeActive: values.isDoorCodeActive === 'yes' ? true : false,

      householdId,
      unitId,
    };
    updateWorkOrderPreferences(payload);
  };

  const submit = (evt: Object) => {
    evt.preventDefault();
    const { submit } = actions;
    submit(FORM_NAME);
    if (onHide) onHide();
  };

  return (
    <Modal show={show} onHide={handleHide} bsSize="small" autoFocus>
      <Modal.Header closeButton>
        <i className="icon et-services" />
        <h1>
          <FormattedMessage {...messages.header} />
        </h1>
      </Modal.Header>
      <Modal.Body>
        {showCancelConfirmation && (
          <ConfirmOverlay
            title={<FormattedMessage {...messages.confirmationTitle} />}
            rejectMessage={
              <FormattedMessage {...messages.confirmationCancel} />
            }
            confirmMessage={<FormattedMessage {...messages.confirm} />}
            onReject={hideCancelConfirmation}
            onConfirm={handleHide}
          />
        )}
        <WorkOrderPreferencesForm
          entryPreferenceOptions={entryPreferences}
          context={{ ...context, ...profileContext }}
          onSubmit={handleSubmit}
          initialValues={initial}
        />
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col xs={6}>
            <button
              className="btn btn-default pull-right"
              onClick={handleHide}
              disabled={showCancelConfirmation}
            >
              <FormattedMessage {...messages.cancel} />
            </button>
          </Col>
          <Col xs={6}>
            <button
              className="btn btn-primary pull-left"
              onClick={submit}
              disabled={showCancelConfirmation || invalid || !anyTouched}
            >
              <FormattedMessage {...messages.save} />
            </button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state: Object): ConnectedProps => {
  const anyTouched = pathOr(false, ['form', FORM_NAME, 'anyTouched'], state);
  const invalid =
    Object.keys(pathOr({}, ['form', FORM_NAME, 'syncErrors'], state)).length >
    0;
  return {
    anyTouched,
    invalid,
    context: {
      property: selectSelectedProperty(state),
    },
  };
};

const mapDispatchToProps = (dispatch: Object): ConnectedMethods => ({
  actions: bindActionCreators({ submit, promptToaster }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(WorkOrderHouseholdPreferenceModal));
