import { get, patch } from '../utils/api';
import type { UpdateBuilding } from '../containers/ManageBuildings/types';

import { validateZipCode } from '../utils/validations';

export class BuildingService {
  getAll = (organizationId: string, propertyId: string) =>
    get(`/${organizationId}/${propertyId}/buildings`);

  update = (
    organizationId: string,
    propertyId: string,
    id: string,
    data: UpdateBuilding,
  ) => {
    if (!data.streetAddress1 || !data.city || !data.state || !data.zipCode) {
      throw new Error('errorMissingRequired');
    }
    if (data?.zipCode && !validateZipCode(data.zipCode)) {
      throw new Error('errorInvalidZipCode');
    }
    return patch(
      `/${organizationId}/${propertyId}/buildings/${id}`,
      JSON.stringify(data),
    );
  };
}
