import type { CreateMiscTransaction } from '../containers/ManageMiscTransactions/CreateMiscTransaction/types';
const { apiURL } = require('../utils/api');
const request = require('../utils/api').default;

class MiscTransactionsService {
  create(
    organizationId: string,
    propertyId: string,
    transaction: CreateMiscTransaction,
    notificationAttributes?: Object,
  ) {
    const body = {
      ...transaction,
      notificationAttributes,
    };
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/misc-transactions`,
      options,
    );
  }

  reverseMiscTransaction(
    organizationId: string,
    propertyId: string,
    transactionId: string,
    notificationAttributes?: Object,
  ) {
    const body = {
      notificationAttributes,
    };
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/transactions/misc/reverse/${transactionId}`,
      options,
    );
  }

  getAllPeriods(organizationId: string, propertyId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/financialPeriods`,
      options,
    );
  }

  getMiscTransactions(
    organizationId: string,
    propertyId: string,
    pageNumber: number,
    period: string = '',
  ) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
    };

    const periodQuery = period !== '' ? `period=${period}` : '';
    const page = `page=${pageNumber}&limit=20`;
    return request(
      `${apiURL}/${organizationId}/${propertyId}/miscTransactions?${page}&${periodQuery}`,
      options,
    );
  }
}

export default MiscTransactionsService;
