import { useMutation, UseMutationOptions } from 'react-query';
import AffordableCertificationsService from '../../services/affordableCertificationsService';
import useAbortController from '../../hooks/useAbortController';

const useEditGrossRentCertificationMutation = ({
  propertyId,
  organizationId,
  options,
}: {
  propertyId: string,
  organizationId: string,
  options?: UseMutationOptions,
}) => {
  const abortControllerOptions = useAbortController();
  return useMutation(async ({ affordableQualificationId, ...payload }) => {
    return await new AffordableCertificationsService().editGrossRentCert(
      organizationId,
      propertyId,
      affordableQualificationId,
      payload,
      abortControllerOptions,
    );
  }, options);
};

export default useEditGrossRentCertificationMutation;
