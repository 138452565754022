import { get } from '../../utils/api';
import { conversationDtoToConversation } from '../TextingTab/mappings';

export async function fetchConversations({
  householdId,
  prospectId,
  selectedProperty,
}) {
  const param = householdId
    ? `householdId=${householdId}`
    : `recipientId=${prospectId}`;

  const conversations = await get(
    `${process.env.REACT_APP_TWO_WAY_API_URL}/conversations?${param}&` +
      `organizationId=${selectedProperty.organizationId}&propertyId=${selectedProperty.id}`,
    { isOtherApi: true },
  );

  return conversations.map((c) => conversationDtoToConversation(c, {}));
}
