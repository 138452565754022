import request, { apiURL, getRequestOptions } from '../utils/api';

export default class AllExpiringLeasesService {
  getAllExpiringLeases({ options, organizationId }) {
    return request(`${apiURL}/${organizationId}/all-expiring-leases`, {
      ...options,
      ...getRequestOptions(),
    });
  }
}
