import { defineMessages } from 'react-intl';

const messages = defineMessages({
  headerSetAside: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSummary.Table.HeaderSetAside',
    defaultMessage: 'Set-Aside',
  },
  headerRequired: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSummary.Table.HeaderRequired',
    defaultMessage: 'Required Units',
  },
  headerActual: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSummary.Table.HeaderActual',
    defaultMessage: 'Actual Units',
  },
  headerLeased: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSummary.Table.HeaderLeased',
    defaultMessage: 'Leased Units',
  },
  leasedUnit: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSummary.Table.LeasedUnit',
    defaultMessage: 'Leased Unit',
  },
  tooltipPreLeasedUnit: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSummary.Table.TooltipPreLeasedUnit',
    defaultMessage: 'A',
  },
  tooltipPostLeasedUnit: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSummary.Table.TooltipPostLeasedUnit',
    defaultMessage: 'is a unit that is',
  },
  tooltipFirstItem: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSummary.Table.TooltipFirstItem',
    defaultMessage: 'Currently occupied by a resident',
  },
  tooltipSecondItem: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSummary.Table.TooltipSecondItem',
    defaultMessage: 'Or a vacant unit with an assigned applicant',
  },
  total: {
    id: 'App.Home.PropertyComplianceTab.SetAsideSummary.Table.Total',
    defaultMessage: 'Total:',
  },
});

export default messages;
