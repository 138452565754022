import { useState, useEffect } from 'react';
import { pathOr } from 'ramda';
import type {
  UpdateIncomeAgeragingPercentHookArgs,
  SetAsideHooksProps,
} from './types';
import messages from './messages';
import { parseKpiRows } from './utils';
import KpiService from '../../../../services/kpiService';
import AffordableSetUpService from '../../../../services/affordableSetUpService';

export const useFetchSetAsideSummaryKPI = ({
  intl,
  organizationId,
  propertyId,
  promptToaster,
}: SetAsideHooksProps): Array<any> => {
  const [kpi, setKpi] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [previousPropertyId, setPreviousPropertyId] = useState(null);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetch = async () => {
      setIsLoading(true);
      try {
        const kpiService = new KpiService();
        const response = await kpiService.getKPI(
          organizationId,
          propertyId,
          'setAsideSummary',
          abortController.signal,
        );
        setKpi(parseKpiRows(response));
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.setAsideSummaryFetchError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    };

    if ((shouldRefresh || propertyId !== previousPropertyId) && !isLoading) {
      fetch();
      setPreviousPropertyId(propertyId);
    }

    return () => {
      setShouldRefresh(false);
    };
  }, [
    organizationId,
    propertyId,
    promptToaster,
    intl,
    shouldRefresh,
    isLoading,
    previousPropertyId,
  ]);

  return [isLoading, kpi, () => setShouldRefresh(true)];
};

export const useFetchIncomeAveragingPercent = ({
  intl,
  organizationId,
  propertyId,
  promptToaster,
}: SetAsideHooksProps): Array<any> => {
  const [incomeAveragingPercent, setIncomeAveragingPercent] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [previousPropertyId, setPreviousPropertyId] = useState(null);

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const fetch = async () => {
      setIsLoading(true);
      try {
        const affordableSetUpService = new AffordableSetUpService();
        const response =
          await affordableSetUpService.getPropertyIncomeAveragingPercent(
            organizationId,
            propertyId,
            abortController.signal,
          );
        const value = pathOr(null, ['incomeAveragingPercent'], response);
        setIncomeAveragingPercent(value);
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.incomeAveragingPercentFetchError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        setShouldRefresh(false);
      }
    };

    if ((shouldRefresh || propertyId !== previousPropertyId) && !isLoading) {
      fetch();
      setPreviousPropertyId(propertyId);
    }

    return () => {
      setShouldRefresh(false);
    };
  }, [
    organizationId,
    propertyId,
    promptToaster,
    intl,
    shouldRefresh,
    isLoading,
    previousPropertyId,
  ]);

  return [isLoading, incomeAveragingPercent, () => setShouldRefresh(true)];
};

export const useUpdateIncomeAgeragingPercent = ({
  intl,
  organizationId,
  propertyId,
  promptToaster,
  refresh,
}: UpdateIncomeAgeragingPercentHookArgs) => {
  const [isLoading, setIsLoading] = useState(false);
  const [incomeAveragingPercent, setIncomeAveragingPercent] = useState(null);

  const updateIncomeAveragingPercent = (newValue: string) => {
    setIncomeAveragingPercent(newValue);
  };

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line

    const updateIncomeAvg = async (newValue: string) => {
      setIsLoading(true);
      try {
        if (!propertyId || !organizationId) {
          throw new Error('Could not update income averaging percent');
        }

        const affordableSetUpService = new AffordableSetUpService();
        await affordableSetUpService.modifyPropertyIncomeAveragingPercent(
          organizationId,
          propertyId,
          newValue,
        );
        promptToaster({
          type: 'success',
          title: intl.formatMessage(
            messages.updateIncomeAveragingPercentSuccessTitle,
          ),
          message: intl.formatMessage(
            messages.updateIncomeAveragingPercentSuccessMessage,
          ),
        });
      } catch (err) {
        promptToaster({
          type: 'error',
          title: intl.formatMessage(messages.updateIncomeAveragingPercentError),
          message: err.toString(),
        });
      } finally {
        setIsLoading(false);
        refresh();
      }
    };

    if (!isLoading && incomeAveragingPercent) {
      updateIncomeAvg(incomeAveragingPercent);
    }

    return () => {
      setIncomeAveragingPercent(null);
    };
  }, [
    organizationId,
    propertyId,
    promptToaster,
    intl,
    isLoading,
    refresh,
    incomeAveragingPercent,
  ]);

  return updateIncomeAveragingPercent;
};
