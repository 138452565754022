import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Typography,
  Switch,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  Table,
  OverviewKPIs,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import {
  VisibilityIcon,
  VisibilityOffIcon,
} from '@fortress-technology-solutions/fortress-component-library/Icons';
import TableLayout from '../../components/TableLayout';
import { Collapse } from '@fortress-technology-solutions/fortress-component-library/Transitions';
import { connect } from 'react-redux';
import { usePortfolio } from './hooksV2';
import { useFlags } from 'launchdarkly-react-client-sdk';
import appMessages from '../App/messages';
import useBaseTableProps from '../../hooks/useBaseTableProps';

const Portfolio = ({ organizationId, userId }) => {
  const { portfolioKpiTiles } = useFlags();

  const baseTableProps = useBaseTableProps();

  const {
    KPIData,
    allColumnsHidden,
    columnOptions,
    count,
    filteredHeaders,
    footerRow,
    handleColumnChange,
    handleSortChange,
    isCSVExportLoading,
    isLoading,
    isPDFExportLoading,
    name,
    onCSVButtonClick,
    onPDFButtonClick,
    order,
    orderBy,
    refreshedAt,
    rows,
    selectedColumns,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleDateSubmit,
    filterState,
    filterTypeState,
    dateState,
    searchState,
    totalCount,
  } = usePortfolio({ organizationId, userId });

  const [isOverviewVisible, setOverviewVisible] = useState(true);

  return (
    <TableLayout
      name={name}
      childrenElementsHeight={28 + 8 + 333 + 16 + 8}
      containerElementsHeight={48} // tabs
      watch={['portfolio-collapse']}
    >
      {(height) => {
        return (
          <>
            <>
              {portfolioKpiTiles && (
                <Switch
                  checked={isOverviewVisible}
                  onChange={() => setOverviewVisible(!isOverviewVisible)}
                  label={<FormattedMessage {...appMessages.kpiData} />}
                  icon={<VisibilityOffIcon />}
                  checkedIcon={<VisibilityIcon />}
                />
              )}

              <Collapse id={'portfolio-collapse'} in={isOverviewVisible}>
                <Box
                  sx={{
                    position: 'relative',
                    left: 0,
                    right: 0,
                    height: 325,
                    marginTop: 1,
                    paddingX: 2,
                  }}
                >
                  <OverviewKPIs
                    isLoading={isLoading}
                    data={KPIData}
                    sx={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                    }}
                  />
                </Box>
              </Collapse>
            </>

            <Table
              {...baseTableProps}
              allColumnsHidden={allColumnsHidden}
              columnOptions={columnOptions}
              count={count}
              dateState={dateState}
              filterState={filterState}
              filterTypeState={filterTypeState}
              footerRow={footerRow}
              handleColumnChange={handleColumnChange}
              handleDateSubmit={handleDateSubmit}
              handleFilterChange={handleFilterChange}
              handleFilterTypeChange={handleFilterTypeChange}
              handleSearchSubmit={handleSearchSubmit}
              handleSortChange={handleSortChange}
              headers={filteredHeaders}
              height={height}
              isLoading={isLoading}
              name={name}
              onCSVButtonClick={onCSVButtonClick}
              onPDFButtonClick={onPDFButtonClick}
              isCSVExportLoading={isCSVExportLoading}
              isPDFExportLoading={isPDFExportLoading}
              order={order}
              orderBy={orderBy}
              rows={rows}
              searchState={searchState}
              selectedColumns={selectedColumns}
              totalCount={totalCount}
            />
            <Typography
              variant={'caption'}
              component={'div'}
              textAlign={'right'}
              height={16}
              margin={1}
              marginBottom={0}
            >
              {refreshedAt ? `Last updated: ${refreshedAt}` : ''}
            </Typography>
          </>
        );
      }}
    </TableLayout>
  );
};

export const mapStateToProps = (state: Object): Object => {
  const {
    app: { currentUser },
  } = state;
  return {
    userId: currentUser?.user?.id ?? '',
    organizationId: currentUser?.user?.organizationId ?? '',
  };
};
export default connect(mapStateToProps)(Portfolio);
