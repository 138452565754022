import { validateRequired, validateZipCode } from '../../../utils/validations';
import formMessages from '../../../containers/ShortLeaseApplication/messages';

import type { ResidencialInformation } from '../../../containers/App/types';

const warn = (values: ResidencialInformation, { intl }: Object) => {
  const warnings = {};
  warnings.address = {};
  warnings.ownOrRent = {};

  ['street', 'city', 'state', 'zipCode'].forEach((fieldName) => {
    if (!validateRequired(fieldName, values.address)) {
      warnings.address[fieldName] = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }
  });

  if (values?.address?.zipCode && !validateZipCode(values.address.zipCode)) {
    warnings.address.zipCode = intl.formatMessage(formMessages.invalidValue);
  }

  ['dateMovedIn', 'monthlyPayment'].forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      warnings[fieldName] = intl.formatMessage(
        formMessages.requiredForCompletion,
      );
    }
  });

  if (!validateRequired('ownOrRentOrNotCurrently', values.ownOrRent)) {
    warnings.ownOrRent.ownOrRentOrNotCurrently = intl.formatMessage(
      formMessages.requiredForCompletion,
    );
  }

  switch (values.ownOrRent.ownOrRentOrNotCurrently) {
    case 'own':
      ['lenderName', 'lenderPhoneNumber'].forEach((fieldName) => {
        if (!validateRequired(fieldName, values.ownOrRent)) {
          warnings.ownOrRent[fieldName] = intl.formatMessage(
            formMessages.requiredForCompletion,
          );
        }
      });
      break;
    case 'rent':
      ['landlordName', 'landlordPhoneNumber'].forEach((fieldName) => {
        if (!validateRequired(fieldName, values.ownOrRent)) {
          warnings.ownOrRent[fieldName] = intl.formatMessage(
            formMessages.requiredForCompletion,
          );
        }
      });
      break;
    case 'other':
      if (
        !validateRequired('doNotCurrentlyOwnRentExplanation', values.ownOrRent)
      ) {
        warnings.ownOrRent.doNotCurrentlyOwnRentExplanation =
          intl.formatMessage(formMessages.requiredForCompletion);
      }
      break;
    default:
      break;
  }

  if (
    Object.keys(warnings).length === 2 &&
    Object.keys(warnings.address).length === 0 &&
    Object.keys(warnings.ownOrRent).length === 0
  ) {
    return {};
  }
  return warnings;
};

export default warn;
