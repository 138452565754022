import type { Highlight, PendingFASEntity, Resident } from '../types';
import moment from 'moment';
import { TASK_TYPE } from '../constants';
import messages from '../messages';
import { getHighlightColor } from './general/getHighlightColor';
import { getUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';

type PendingFASHeader = {
  id: number,
  text: Object,
  sortKey: 'unit' | 'priorResident' | 'moveOutDate' | 'dueDate',
};

type MappedPendingFAS = {
  id: string,
  unit: string,
  priorResident: Resident,
  moveOutDate: string,
  dueDate: string,
  highlight: Highlight,
};

export const mapPendingFAS = (
  pendingFAS: PendingFASEntity[],
): MappedPendingFAS[] => {
  return pendingFAS.map((task) => {
    const moveOutDate = task.move_out ? moment(task.move_out) : null;
    const primaryResident = task.name;
    const p2 = primaryResident
      ? {
          link: getUrlWithSelectedPropertyId(
            `/prior-resident/${task.resident_id}`,
          ),
          value: primaryResident,
        }
      : 'N/A';

    const highlight = getHighlightColor({
      checkDate: task.move_out,
      type: TASK_TYPE.PENDING_FAS,
    });

    return {
      id: task.id,
      unit: task.unit,
      priorResident: p2,
      moveOutDate: moveOutDate ? moveOutDate.format('MM/DD/YYYY') : 'N/A',
      dueDate: moveOutDate
        ? moment(moveOutDate).add(14, 'days').format('MM/DD/YYYY')
        : 'N/A',
      highlight,
    };
  });
};

export const pendingFASHeaders = (intl: Object): PendingFASHeader[] => [
  {
    id: 1,
    text: intl.formatMessage(messages.unitHeader),
    sortKey: 'unit',
  },
  {
    id: 2,
    text: intl.formatMessage(messages.priorResidentHeader),
    sortKey: 'priorResident',
  },
  {
    id: 3,
    text: intl.formatMessage(messages.moveOutDateHeader),
    sortKey: 'moveOutDate',
  },
  {
    id: 4,
    text: intl.formatMessage(messages.dueDateHeader),
    sortKey: 'dueDate',
  },
];
