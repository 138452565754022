import { defineMessages } from 'react-intl';

const messages = defineMessages({
  delinquency: {
    id: 'App.Overview.Delinquency.Delinquency',
    defaultMessage: 'Delinquency'
  },
  all: {
    id: 'App.Overview.Delinquency.All',
    defaultMessage: 'All'
  },
  currentPeriod: {
    id: 'App.Overview.Delinquency.CurrentPeriod',
    defaultMessage: 'Current Period'
  },
  ntv: {
    id: 'App.Overview.Delinquency.NTV',
    defaultMessage: 'NTV'
  },
  subsidy: {
    id: 'App.Overview.Delinquency.Subsidy',
    defaultMessage: 'Subsidy'
  },
  priorResident: {
    id: 'App.Overview.Delinquency.PriorResident',
    defaultMessage: 'Prior Resident'
  },
  applicant: {
    id: 'App.Overview.Delinquency.Applicant',
    defaultMessage: 'Applicant'
  },
  currentResident: {
    id: 'App.Overview.Delinquency.CurrentResident',
    defaultMessage: 'Current Resident'
  }
});

export default messages;
