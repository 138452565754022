import React from 'react';
import { injectIntl } from 'react-intl';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import ElementWithPermissions from '../../../../../../components/ElementWithPermissions';

import messages from './messages';

type Props = {
  canComplete: boolean,
  handleSubmit: Function,
  intl: Object,
  onSubmit: Function,
  disable: boolean,
};

export const UnselectedMethodSaveButton = ({
  canComplete,
  handleSubmit,
  intl,
  onSubmit,
  disable,
}: Props) => {
  return (
    <ElementWithPermissions scope={['lease-create']}>
      <Button
        sx={{ width: 200 }}
        variant={'primarySubtle'}
        disabled={!canComplete || disable}
        onClick={handleSubmit(onSubmit)}
      >
        {intl.formatMessage(messages.save)}
      </Button>
    </ElementWithPermissions>
  );
};

export default injectIntl(UnselectedMethodSaveButton);
