import React, { useMemo } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useManageRentRoll } from './hooksV2';
import UnitSpecials from '../../components/UnitSpecials/indexV2';
import TableLayout from '../../components/TableLayout';
import useBaseTableProps from '../../hooks/useBaseTableProps';

const NAME = 'manage-rent-roll';
const ManageRentRoll = ({
  intl: injectedIntl,
  selectedProperty,
  specialsForm,
}) => {
  const intl = useMemo(() => injectedIntl, [injectedIntl]);
  const baseTableProps = useBaseTableProps();

  const {
    allColumnsHidden,
    columnOptions,
    count,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    footerRow,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    isLoading,
    name,
    onCSVButtonClick,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    totalCount,
  } = useManageRentRoll({ selectedProperty, specialsForm, NAME, intl });

  return (
    <DocumentTitle data-testid={'manage-rent-roll'} title={'Manage Rent Roll'}>
      <TableLayout
        name={name}
        childrenElementsHeight={56 + 8 + 106}
        watch={['unit-specials']}
      >
        {(height) => {
          return (
            <>
              <TableHeader
                title={'Manage Rent Roll'}
                count={count}
                totalCount={totalCount}
              />
              <UnitSpecials />
              <Table
                {...baseTableProps}
                allColumnsHidden={allColumnsHidden}
                columnOptions={columnOptions}
                count={count}
                dateState={dateState}
                filterState={filterState}
                filterTypeState={filterTypeState}
                footerRow={footerRow}
                handleColumnChange={handleColumnChange}
                handleDateSubmit={handleDateSubmit}
                handleFilterChange={handleFilterChange}
                handleFilterTypeChange={handleFilterTypeChange}
                handleSearchSubmit={handleSearchSubmit}
                handleSortChange={handleSortChange}
                headers={filteredHeaders}
                height={height}
                intl={intl}
                isLoading={isLoading}
                name={name}
                onCSVButtonClick={onCSVButtonClick}
                order={order}
                orderBy={orderBy}
                rows={rows}
                searchState={searchState}
                selectedColumns={selectedColumns}
                totalCount={totalCount}
              />
            </>
          );
        }}
      </TableLayout>
    </DocumentTitle>
  );
};

export const mapStateToProps = ({ app }: Object): Object => {
  return {
    organizationId: app.currentUser?.user?.organizationId || '',
    selectedProperty: app.selectedProperty,
  };
};
export default connect(mapStateToProps, null)(injectIntl(ManageRentRoll));
