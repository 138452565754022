import {
  handleSocketCreateMiscTransactionError,
  handleSocketCreateMiscTransactionSuccess,
} from './actions';

/**
 * Consumed by Socket context to register listeners
 */

const createMiscTransactionEvents = (dispatch: Function, socket: any) => {
  socket.on('cmtx.error', (message) => {
    const data = message;
    dispatch(handleSocketCreateMiscTransactionError(data));
  });

  socket.on('cmtx.success', (message) => {
    const { payload } = message;
    dispatch(handleSocketCreateMiscTransactionSuccess(payload));
  });
};

export default createMiscTransactionEvents;
