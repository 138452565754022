import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { GROSS_RENT_CERT_TYPE } from '../../constants/affordableCertificationTypes';
import messages from './messages';

const CertificationNotes = ({
  selectedCertificationType,
  sortedAndFilteredResults,
}) => {
  const { hasPriorCertification, hasCorrection } = useMemo(() => {
    const hasPriorCertification = sortedAndFilteredResults.some(
      ({ priorCertificationDate }) => priorCertificationDate !== null,
    );
    const hasCorrection = sortedAndFilteredResults.some(
      ({ isCorrection, correction }) =>
        isCorrection === 'yes' && correction === null,
    );
    return { hasPriorCertification, hasCorrection };
  }, [sortedAndFilteredResults]);

  const shouldRender = selectedCertificationType === GROSS_RENT_CERT_TYPE;

  return shouldRender ? (
    <Box padding={2} id={'certificationNotes'}>
      {selectedCertificationType === GROSS_RENT_CERT_TYPE ? (
        <div>
          <Typography variant={'caption'} component={'li'}>
            <Typography variant={'caption'} component={'strong'}>
              *
            </Typography>
            <FormattedMessage {...messages.noChangeInGrossRentCerts} />
          </Typography>
          {hasCorrection && (
            <Typography variant={'caption'} component={'li'}>
              <Typography component={'strong'} variant={'caption'}>
                **
              </Typography>
              <FormattedMessage
                {...messages.manualCorrectionInGrossRentCerts}
              />
            </Typography>
          )}
          {hasPriorCertification && (
            <Typography variant={'caption'} component={'li'}>
              <Typography component={'strong'} variant={'caption'}>
                ***
              </Typography>
              <FormattedMessage {...messages.priorCertNotCompleted} />
            </Typography>
          )}
        </div>
      ) : null}
    </Box>
  ) : null;
};

export default CertificationNotes;
