export const GET_ALL_FLOOR_PLANS = 'app/ManageFloorPlans/GET_ALL_FLOOR_PLANS';
export const GET_ALL_FLOOR_PLANS_SUCCESS =
  'app/ManageFloorPlans/GET_ALL_FLOOR_PLANS_SUCCESS';
export const GET_ALL_FLOOR_PLANS_FAIL =
  'app/ManageFloorPlans/GET_ALL_FLOOR_PLANS_FAIL';

export const GET_ALL_SMART_PRICES = 'app/ManageFloorPlans/GET_ALL_SMART_PRICES';
export const GET_ALL_SMART_PRICES_SUCCESS =
  'app/ManageFloorPlans/GET_ALL_SMART_PRICES_SUCCESS';
export const GET_ALL_SMART_PRICES_FAIL =
  'app/ManageFloorPlans/GET_ALL_SMART_PRICES_FAIL';

export const CLEAR_ALL_FLOOR_PLANS =
  'app/ManageFloorPlans/CLEAR_ALL_FLOOR_PLANS';

export const HUD_LIMITS_FORM_NAME = 'hudLimitsForm';
