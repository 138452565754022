import React from 'react';
import { Row, Col } from 'react-bootstrap';

import messages from './messages';

type DocumentNotesProps = {
  intl: Object,
  show: boolean,
  notes: string,
  onChange: Function,
};

const DocumentNotes = ({ intl, show, notes, onChange }: DocumentNotesProps) => {
  return show ? (
    <Row>
      <Col xs={12} sm={12}>
        <label className="input">{intl.formatMessage(messages.notes)}</label>
        <textarea
          value={notes}
          maxLength={2000}
          onChange={onChange}
          className={'form-control'}
          rows="6"
          placeholder="Add your notes..."
        />
      </Col>
    </Row>
  ) : null;
};

export default DocumentNotes;
