import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import socketMiddleware from './middlewares/socketMiddleware';
import ExitConfirmationModal from './components/ExitConfirmationModal';

export const history = createBrowserHistory({
  async getUserConfirmation(message, callback) {
    const result = await ExitConfirmationModal(message);
    callback(result);
  },
});

const sagaMiddleware = createSagaMiddleware();
const initialState = {};
const enhancers = [];
const middleware = [
  sagaMiddleware,
  routerMiddleware(history),
  socketMiddleware,
];

/* istanbul ignore next */
if (process.env.NODE_ENV === 'development') {
  const devToolsExtension =
    window?.devToolsExtension ?? window?.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const persistConfig = {
  key: 'root',
  blacklist: [
    'manageProspects',
    'manageRoles',
    'form',
    'editUser',
    'applicationProfile',
    'createUser',
    'resetPassword',
    'forgotPassword',
    'toastr',
    'editRole',
    'createRole',
    'home',
    'manageApplications',
    'manageDocuments',
    'login',
    'app',
    'primaryLeaseApplication',
    'secondaryLeaseApplication',
    'paymentBatch',
    'manageUndepositedPayments',
    'manageReports',
  ],
  whitelist: [],
  storage: sessionStorage,
  debug: true,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, initialState, composedEnhancers);
export const persistor = persistStore(store);
// $FlowFixMe
store.runSaga = sagaMiddleware.run;
export default store;
