import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';
import { without } from 'ramda';
import { FEE_MODES, FORM_MODES } from '../constants';
import ElementWithPermissions from '../../../../components/ElementWithPermissions';
import FloorplanFeesForm from '../../FloorplanFeesForm';
import { connect } from 'react-redux';
import moment from 'moment';
import type Moment from 'moment';
import cn from 'classnames';
import { FormattedNumber, FormattedDate, FormattedMessage } from 'react-intl';

import generalMessages from '../../../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

type FormMode = $Values<typeof FORM_MODES>;
type FeeMode = $Values<typeof FEE_MODES>;

type ConnectedProps = {
  form: Object,
};

type DefaultProps = {
  today?: Moment,
  tomorrow?: Moment,
  fees: Array<Object>,
  collapsed: boolean,
  disabled: boolean,
  flags: Object,
  utilityAllowanceTypes: Array<Object>,
  locale: string,
  isCurrentSuggestedDeposit?: boolean,
  isRDOnly?: boolean,
};

type Props = DefaultProps &
  ConnectedProps & {
    title: string,
    type: FeeMode,
    currentRate: string,
    onCollapse: Function,
    onExpand: Function,
    onUpdate: Function,
    onCreate: Function,
    onDelete: Function,
  };

type State = {
  formMode: ?FormMode,
  pricingId: ?string,
};

function dateToUtcStart(date) {
  return moment.utc(date).startOf('date');
}

export class FeeList extends Component<Props, State> {
  static defaultProps = {
    today: dateToUtcStart(Date.now()),
    fees: [],
    collapsed: true,
    disabled: false,
  };

  constructor() {
    super();
    this.state = {
      pricingId: null,
      formMode: null,
    };
  }

  resetFormMode = () => {
    this.setState({ formMode: null, pricingId: null });
  };

  setFormMode = (formMode: FormMode) => {
    if (this.state.formMode === null) this.setState({ formMode });
  };

  setAddForm = () => {
    const { ADD } = FORM_MODES;
    this.setFormMode(ADD);
  };

  setEditForm = (pricingId: string) => {
    const { EDIT } = FORM_MODES;
    this.setState({ pricingId });
    this.setFormMode(EDIT);
  };

  handleCollapse = () => {
    const { onCollapse, type } = this.props;
    onCollapse(type);
  };

  handleExpand = () => {
    const { onExpand, type } = this.props;
    onExpand(type);
  };

  handleDelete = (pricingId: string) => {
    const { formMode } = this.state;
    if (formMode !== null) return;
    const { type, onDelete } = this.props;
    onDelete({
      pricingId,
      pricingType: type,
    });
  };

  handleUpdate = ({
    feeAmount,
    startDate,
    implementationDate,
    utilityAllowanceTypeIds,
  }: Object) => {
    const { type, onUpdate } = this.props;
    const { pricingId } = this.state;
    onUpdate({
      pricingType: type,
      pricingId,
      feeAmount,
      startDate,
      implementationDate,
      utilityAllowanceTypeIds,
    });
    this.resetFormMode();
  };

  handleCreate = ({
    feeAmount,
    startDate,
    implementationDate,
    utilityAllowanceTypeIds,
  }: Object) => {
    const { type, onCreate } = this.props;
    onCreate({
      pricingType: type,
      feeAmount,
      startDate,
      implementationDate,
      utilityAllowanceTypeIds,
    });
    this.resetFormMode();
  };

  getMostRecent = (fees: Array<Object>) =>
    fees.find((fee) => {
      return fee.endDate === null;
    });

  isValidDateAdd = (calendarDate: Moment, fieldName: string) => {
    const { fees, today } = this.props;
    const defaultValue = today;
    const mostRecentFee = this.getMostRecent(fees);

    const date =
      mostRecentFee && mostRecentFee?.[fieldName]
        ? dateToUtcStart(mostRecentFee?.[fieldName]).add(1, 'days')
        : defaultValue;

    return (
      calendarDate.isSameOrAfter(date) && calendarDate.isSameOrAfter(today)
    );
  };

  isValidDateEdit = (calendarDate: Moment, fieldName: string) => {
    const { fees, today } = this.props;
    const defaultValue = today;
    const previousFee = fees[1];
    const date =
      previousFee && previousFee?.[fieldName]
        ? dateToUtcStart(previousFee?.[fieldName]).add(1, 'days')
        : defaultValue;

    return calendarDate.isSameOrAfter(date);
  };

  editMostRecent = () => {
    const { fees, today } = this.props;
    const defaultValue = {
      feeAmount: '0.00',
      startDate: today,
      implementationDate: today,
      utilityAllowanceTypeIds: [],
    };

    const mostRecentFee = this.getMostRecent(fees);

    return mostRecentFee
      ? {
          feeAmount: mostRecentFee.feeAmount,
          startDate: dateToUtcStart(mostRecentFee.startDate),
          implementationDate: dateToUtcStart(mostRecentFee.implementationDate),
          utilityAllowanceTypeIds: without(
            [null, undefined],
            (mostRecentFee?.uaTypes ?? []).map((uaType) => uaType?.id),
          ),
        }
      : defaultValue;
  };

  render() {
    const {
      collapsed,
      disabled,
      fees,
      type,
      title,
      currentRate,
      flags,
      locale,
      utilityAllowanceTypes,
      isCurrentSuggestedDeposit = false,
      isRDOnly,
    } = this.props;
    const { formMode } = this.state;
    const { ADD, EDIT } = FORM_MODES;
    const { HUD_GROSS_RENT, NON_OPTIONAL_CHARGE, NOTE_RENT } = FEE_MODES;

    const addInitialValues = {};
    const editInitialValues = this.editMostRecent();
    const mostRecentFee = this.getMostRecent(fees);

    const currencyStyle = { value: 'currency' };

    const visibleFee = formMode === EDIT ? 1 : 0;

    const isUAFee = type.includes('UTILITY_ALLOWANCE');
    const utilityAllowanceTypesAndEffectiveDate =
      flags?.utilityAllowanceTypesAndEffectiveDate ?? false;
    const showUaTypeAndEffectiveDate =
      utilityAllowanceTypesAndEffectiveDate && isUAFee;
    const colSpan = showUaTypeAndEffectiveDate ? 2 : 3;
    const note1Key =
      type === NON_OPTIONAL_CHARGE ? 'nonOptionalChargeNote1' : 'note1';
    const note2Key =
      type === NON_OPTIONAL_CHARGE ? 'nonOptionalChargeNote2' : 'note2';
    return (
      <div className="panel panel-default no-border-top" data-test="fee-list">
        <div className="panel-heading">
          <div className="panel-title">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <h5 className="h5-alt">
                    <strong>{title}</strong>
                  </h5>
                </div>
                <div className="col-md-5 col-lg-5">
                  <span className="padtop20">
                    <FormattedNumber
                      value={currentRate}
                      style={currencyStyle.value}
                      currency="USD"
                    />
                  </span>
                </div>
                <div className="col-md-3">
                  <div className="panel-button">
                    <ElementWithPermissions scope={['floorplan-pricing-edit']}>
                      {collapsed ? (
                        <button
                          className={cn(
                            {
                              disabled,
                            },
                            'btn btn-text btn-text--small text-right',
                          )}
                          onClick={this.handleExpand}
                        >
                          <FormattedMessage {...messages.edit} />
                          <i className="icon et-pencil" />
                        </button>
                      ) : (
                        <button
                          className={cn(
                            'btn btn-text btn-text--small text-right',
                          )}
                          onClick={this.handleCollapse}
                        >
                          <FormattedMessage {...messages.close} />
                          <i className="icon et-delete-3" />
                        </button>
                      )}
                    </ElementWithPermissions>
                  </div>
                </div>
              </div>
            </div>
            <Collapse in={!collapsed}>
              <div className="panel-collapse">
                <div className="panel-body">
                  <div className="divider" />
                  <div className="container-fluid">
                    <h3>
                      <FormattedMessage {...messages.edit} /> {title}
                    </h3>
                    <div className="row padbottom10">
                      {showUaTypeAndEffectiveDate && (
                        <div className={`col-xs-${colSpan + 1}`}>
                          <label>
                            <FormattedMessage {...messages.utilityType} />
                          </label>
                        </div>
                      )}
                      <div className={`col-xs-${colSpan}`}>
                        <label>
                          <FormattedMessage {...messages.amount} />
                        </label>
                      </div>
                      <div className={`col-xs-${colSpan}`}>
                        <label>
                          <FormattedMessage {...messages.startDate} />
                        </label>
                      </div>
                      {showUaTypeAndEffectiveDate && (
                        <div className={`col-xs-${colSpan}`}>
                          <label>
                            <FormattedMessage {...messages.effectiveDate} />
                          </label>
                        </div>
                      )}
                      <div
                        className={`col-xs-${
                          showUaTypeAndEffectiveDate ? colSpan - 1 : colSpan
                        }`}
                      >
                        <label>
                          <FormattedMessage {...messages.endDate} />
                        </label>
                      </div>
                      <div className={`col-xs-${colSpan}`}>
                        <label>
                          <FormattedMessage {...messages.actions} />
                        </label>
                      </div>
                    </div>
                    {formMode === EDIT && (
                      <FloorplanFeesForm
                        form={type}
                        initialValues={editInitialValues}
                        isValidDate={this.isValidDateEdit}
                        onAdd={this.handleUpdate}
                        onCancel={this.resetFormMode}
                        showUaTypeAndEffectiveDate={showUaTypeAndEffectiveDate}
                        utilityAllowanceTypes={utilityAllowanceTypes}
                        isCurrentSuggestedDeposit={isCurrentSuggestedDeposit}
                      />
                    )}
                    {fees.slice(visibleFee, 10).map((fee, index) => (
                      <div className="row padbottom10" key={fee.id}>
                        {showUaTypeAndEffectiveDate && (
                          <div className={`col-xs-${colSpan + 1}`}>
                            <span>
                              {(fee?.uaTypes ?? []).length > 0
                                ? (fee?.uaTypes ?? [])
                                    .map(
                                      (uaType) =>
                                        uaType?.translations?.[locale] ??
                                        uaType?.name,
                                    )
                                    .join(', ')
                                    .trim()
                                : '---'}
                            </span>
                          </div>
                        )}
                        <div className={`col-xs-${colSpan}`}>
                          <FormattedNumber
                            value={fee.feeAmount}
                            style={currencyStyle.value}
                            currency="USD"
                          />
                        </div>
                        {showUaTypeAndEffectiveDate && (
                          <div className={`col-xs-${colSpan}`}>
                            {fee.implementationDate !== null ? (
                              <FormattedDate
                                value={fee.implementationDate}
                                timeZone="UTC"
                              />
                            ) : (
                              '---'
                            )}
                          </div>
                        )}
                        <div className={`col-xs-${colSpan}`}>
                          <FormattedDate value={fee.startDate} timeZone="UTC" />
                        </div>
                        <div
                          className={`col-xs-${
                            showUaTypeAndEffectiveDate ? colSpan - 1 : colSpan
                          }`}
                        >
                          {fee.endDate !== null ? (
                            <FormattedDate value={fee.endDate} timeZone="UTC" />
                          ) : (
                            '---'
                          )}
                        </div>
                        <div className={`col-xs-${colSpan}`}>
                          {moment(fee.startDate).format('YYYY-MM-DD') >
                            moment().format('YYYY-MM-DD') &&
                            mostRecentFee &&
                            mostRecentFee.id === fee.id && (
                              <React.Fragment>
                                {fees.length !== index + 1 && (
                                  <button
                                    onClick={() => this.handleDelete(fee.id)}
                                    className={cn(
                                      {
                                        disabled: formMode !== null,
                                      },
                                      'btn btn-link btn-tiny',
                                    )}
                                  >
                                    <i className="icon et-trash" />
                                  </button>
                                )}
                              </React.Fragment>
                            )}
                        </div>
                      </div>
                    ))}
                    {formMode === ADD && (
                      <FloorplanFeesForm
                        form={type}
                        initialValues={addInitialValues}
                        isValidDate={this.isValidDateAdd}
                        onAdd={this.handleCreate}
                        onCancel={this.resetFormMode}
                        showUaTypeAndEffectiveDate={showUaTypeAndEffectiveDate}
                        utilityAllowanceTypes={utilityAllowanceTypes}
                        isCurrentSuggestedDeposit={isCurrentSuggestedDeposit}
                      />
                    )}
                    <a
                      onClick={this.setAddForm}
                      className={cn(
                        { disabled: formMode !== null },
                        'btn-text',
                      )}
                    >
                      <FormattedMessage
                        {...messages.addNew}
                        values={{ title: title }}
                      />
                    </a>
                    <p>
                      <strong>
                        <FormattedMessage {...messages.notes} />:
                      </strong>
                    </p>
                    <p>
                      <FormattedMessage
                        {...messages?.[note1Key]}
                        values={{ title: title }}
                      />
                    </p>
                    <p className="no-margin-top">
                      <FormattedMessage
                        {...messages?.[note2Key]}
                        values={{ title: title }}
                      />
                    </p>
                    {type === HUD_GROSS_RENT && (
                      <p className="no-margin-top">
                        <FormattedMessage {...messages.note3} />
                      </p>
                    )}
                    {type === NOTE_RENT && isRDOnly && (
                      <p className="no-margin-top">
                        <FormattedMessage {...messages.noteNoteRent} />
                      </p>
                    )}
                    {showUaTypeAndEffectiveDate && (
                      <p className="no-margin-top">
                        <FormattedMessage {...messages.note3UaType} />
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}

export function mapStateToProps(state: Object): ConnectedProps {
  return {
    form: state.form,
  };
}

export default connect(mapStateToProps)(FeeList);
