import React from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import {
  reduxForm,
  Field,
  formValueSelector,
  change,
  untouch,
} from 'redux-form';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { renderRadioGroupField } from '../../../../utils/redux-form-helper';
import { pathOr } from 'ramda';
import { formatCurrency } from '../../../../utils';
import { generateEditOptions } from './utils';
import { editCamMonthlyCharges } from './hooks';
import styled from 'styled-components';
import validate from './validate';

const StyledForm = styled.form`
  label {
    margin-bottom: 0;
  }
`;

type Props = {
  intl: Object,
  propertyId: string,
  organizationId: string,
  show: boolean,
  handleSubmit: Function,
  onSubmit: Function,
  pristine: boolean,
  valid: boolean,
  reset: Function,
  initialValues: Object,
  onHide: Function,
  selectedCamCharge: Object,
  selectedOption: string,
  promptToaster: Function,
  refreshMonthlyChargeTable: Function,
  resetFields: Function,
};

const EditChargesModal = (props: Props) => {
  const {
    reset,
    onHide,
    selectedCamCharge,
    pristine,
    valid,
    handleSubmit,
    intl,
    selectedOption,
    show,
    propertyId,
    organizationId,
    promptToaster,
    refreshMonthlyChargeTable,
    resetFields,
  } = props;

  const onClose = () => {
    reset();
    onHide();
  };
  const camCalculationsHouseholdMonthlyTransactionsId =
    selectedCamCharge.camCalculationsHouseholdMonthlyTransactionsId;

  const onSubmit = async (values) => {
    const data = {
      ...values,
      intl,
      propertyId,
      organizationId,
      camCalculationsHouseholdMonthlyTransactionsId,
      promptToaster,
    };
    await editCamMonthlyCharges(data);
    refreshMonthlyChargeTable();
    onClose();
  };

  const handleResetFields = (selectedOption) => {
    if (selectedOption === 'endCharge') {
      resetFields({ newAmount: '', newStartDate: '' });
    } else if (selectedOption === 'changeAmount') {
      resetFields({ endDate: '' });
    }
  };
  const renderModalHeader = () => {
    return (
      <Modal.Header closeButton>
        <i className="icon et-pencil" />
        <Modal.Title componentClass="h1">
          <FormattedMessage {...messages.editCharges} />
        </Modal.Title>
      </Modal.Header>
    );
  };

  const renderModalFooter = () => {
    const submitEnabled = !pristine && valid;

    return (
      <Modal.Footer>
        <Row>
          <Col xs={6}>
            <Button bsStyle="default" className="pull-right" onClick={onClose}>
              <FormattedMessage {...messages.cancel} />
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              bsStyle="primary"
              className="pull-left"
              type="submit"
              disabled={!submitEnabled}
            >
              <FormattedMessage {...messages.save} />
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    );
  };

  const renderModalFormBody = () => {
    const camPool = pathOr(null, ['camPool'], selectedCamCharge);
    const transactionCode = pathOr(
      null,
      ['transactionCode'],
      selectedCamCharge,
    );
    const amount = pathOr(null, ['amount'], selectedCamCharge);
    const startDate = pathOr(null, ['startDate'], selectedCamCharge);
    const endDate = pathOr(null, ['endDate'], selectedCamCharge);

    return (
      <Row className="scrollable-modal__content padtop15">
        <div className="container-fluid padleft30 padright30">
          <Row className="padleft15">
            <Col xs={3}>
              <FormattedMessage {...messages.camPool} />
            </Col>
            <Col xs={2}>
              <FormattedMessage {...messages.transactionCode} />
            </Col>
            <Col xs={2}>
              <FormattedMessage {...messages.currentAmount} />
            </Col>
            <Col xs={2}>
              <FormattedMessage {...messages.startDate} />
            </Col>
            {endDate && (
              <Col xs={2}>
                <FormattedMessage {...messages.endDate} />
              </Col>
            )}
          </Row>
          <Row className="padleft15">
            <Col xs={3}>{camPool}</Col>
            <Col xs={2}>{transactionCode}</Col>
            <Col xs={2}>{formatCurrency(intl, amount)}</Col>
            <Col xs={2}>{startDate}</Col>
            {endDate && <Col xs={2}>{endDate}</Col>}
          </Row>
          <Row className="padtop30 padleft15">
            <Col xs={12} className="padtop30">
              <Field
                name="editOptions"
                fieldName="editOptions"
                options={generateEditOptions(selectedOption)}
                component={renderRadioGroupField}
                onClick={(e) => {
                  handleResetFields(e.target.value);
                }}
              />
            </Col>
          </Row>
        </div>
      </Row>
    );
  };

  return (
    <Modal bsSize="lg" backdrop show={show} onHide={() => onClose()}>
      {renderModalHeader()}
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>{renderModalFormBody()}</Modal.Body>
        {renderModalFooter()}
      </StyledForm>
    </Modal>
  );
};

export const mapStateToProps = (
  { form }: Object,
  { initialValues }: Object,
): Object => {
  const selector = formValueSelector('editChargesModalForm');
  return {
    selectedOption: selector({ form }, 'editOptions'),
    initialValues,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  resetFields: (fieldsObj) => {
    Object.keys(fieldsObj).forEach((fieldKey) => {
      //reset the field's value
      dispatch(change('editChargesModalForm', fieldKey, fieldsObj[fieldKey]));
      dispatch(untouch('editChargesModalForm', fieldKey));
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'editChargesModalForm',
    enableReinitialize: true,
    validate,
  })(EditChargesModal),
);
