import type { EditResidentHouseholdFormValues, PetFormValues } from './types';
import { isEmpty, isNil, prop, equals, not, find } from 'ramda';

const validateRequired = (property: string, obj: Object) =>
  not(isEmpty(prop(property, obj))) &&
  not(isNil(prop(property, obj))) &&
  !equals('default', prop(property, obj));

const validate = (
  values: EditResidentHouseholdFormValues,
  petTypeList: Array<any>,
  applicantTypesOptions: Array<Object>,
) => {
  return {
    adults: values.adults.map((adult) => {
      const errors = {};

      if (adult.id === values.primaryResidentId) {
        const type: any = applicantTypesOptions.find(
          (applicantType) => applicantType.value === adult.type,
        );
        if (!type.financiallyResponsible) {
          errors.type = 'Primary/HOH must be a financial responsible type';
        }
      }
      return errors;
    }),
    pets: values.pets.map((pet: PetFormValues) => {
      const errors = {};

      ['petTypeId', 'petBreedId'].forEach((fieldName) => {
        if (!validateRequired(fieldName, pet))
          errors[fieldName] = 'This field is required';
      });

      const selectedPetType =
        find((type) => type.value === pet.petTypeId, petTypeList) || {};

      if (
        !errors.petTypeId &&
        selectedPetType.requiresWeight &&
        !validateRequired('weight', pet)
      ) {
        errors.weight = 'This field is required';
      }

      if (!errors.weight && !isNil(pet.weight) && pet.weight <= 0) {
        errors.weight = 'Invalid value.';
      }

      return errors;
    }),
    pendingPets: values.pendingPets
      ? values.pendingPets.map((pet: PetFormValues) => {
          const errors = {};

          ['petTypeId', 'petBreedId'].forEach((fieldName) => {
            if (!validateRequired(fieldName, pet))
              errors[fieldName] = 'This field is required';
          });
          const selectedPetType =
            find((type) => type.value === pet.petTypeId, petTypeList) || {};

          if (
            !errors.petTypeId &&
            selectedPetType.requiresWeight &&
            !validateRequired('weight', pet)
          ) {
            errors.weight = 'This field is required';
          }

          if (!errors.weight && !isNil(pet.weight) && pet.weight <= 0) {
            errors.weight = 'Invalid value.';
          }

          return errors;
        })
      : [],
  };
};

export default validate;
