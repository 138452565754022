import { defineMessages } from 'react-intl';

const messages = defineMessages({
  successHeader: {
    id: 'App.AddUser.SuccessHeader',
    defaultMessage: 'Success',
  },
  successDescription: {
    id: 'App.AddUser.SuccessDescription',
    defaultMessage: 'The user was added.',
  },
  errorHeader: {
    id: 'App.AddUser.ErrorHeader',
    defaultMessage: 'Error',
  },
  title: {
    id: 'App.AddUser.Title',
    defaultMessage: 'Fortress - Add new user',
  },
  header: {
    id: 'App.AddUser.Header',
    defaultMessage: 'Add new user',
  },
  subheader: {
    id: 'App.AddUser.Subheader',
    defaultMessage: 'All fields are required',
  },
  firstName: {
    id: 'App.AddUser.FirstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'App.AddUser.LastName',
    defaultMessage: 'Last name',
  },
  emailAddress: {
    id: 'App.AddUser.EmailAddress',
    defaultMessage: 'Email address',
  },
  username: {
    id: 'App.AddUser.Username',
    defaultMessage: 'Pick a username',
  },
  assignToProperty: {
    id: 'App.AddUser.AssignToProperty',
    defaultMessage: 'Assign to property',
  },
  assignAllProperties: {
    id: 'App.AddUser.AssignAllProperties',
    defaultMessage: 'Or assign all properties to this user',
  },
  chooseOption: {
    id: 'App.AddUser.ChooseAnOption',
    defaultMessage: 'Choose an option',
  },
  userStatus: {
    id: 'App.AddUser.UserStatus',
    defaultMessage: 'User status',
  },
  userTitle: {
    id: 'App.AddUser.UserTitle',
    defaultMessage: 'User title',
  },
  userRole: {
    id: 'App.AddUser.UserRole',
    defaultMessage: 'Assign a role',
  },
  cancelConfirmation: {
    id: 'App.AddUser.CancelConfirmation',
    defaultMessage: 'Are you sure you want to leave?',
  },
});

export default messages;
