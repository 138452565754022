import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { get } from '../../utils/api';

export function useDocumentCategories({
  userOrganizationId,
  isPageEnabled,
  documentManagementLetters,
}) {
  const { data: documentCategoriesResponse } = useQuery(
    ['documentCategories', userOrganizationId],
    () => {
      return get(`/${userOrganizationId}/document-categories`);
    },
    {
      refetchOnWindowFocus: false,
      initialData: [],
      enabled: isPageEnabled,
    },
  );

  const documentCategories = useMemo(() => {
    if (documentManagementLetters) return documentCategoriesResponse;
    return documentCategoriesResponse?.filter(
      (category) => category.name !== 'Letter',
    );
  }, [documentCategoriesResponse, documentManagementLetters]);

  return documentCategories;
}
