import React from 'react';
import { pick, pathOr } from 'ramda';

import { formatPeriod } from '../../../utils/redux-form-helper.js';
import messages from './messages';
import type { MiscTransaction } from '../types.js';
import onViewTransactionClick from './viewMiscTransactionModal';

type Props = {
  intl: Object,
  miscTransactions: Array<MiscTransaction>,
  reverseMiscTransaction: Function,
  canReverse: boolean,
};

export const parseTransactionViewFields = (
  intl: Object,
  miscTransaction: Object,
) => [
  {
    text: intl.formatMessage(messages.transactionStatus),
    value: miscTransaction.status,
  },
  {
    text: intl.formatMessage(messages.date),
    value: intl.formatDate(miscTransaction.transactionDate, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }),
  },
  {
    text: intl.formatMessage(messages.chargeTransactionCode),
    value: `${miscTransaction.chargeCode} - ${miscTransaction.chargeCodeDescription}`,
  },
  {
    text: intl.formatMessage(messages.paymentTransactionCode),
    value: `${miscTransaction.paymentCode} - ${miscTransaction.paymentCodeDescription}`,
  },
  {
    text: intl.formatMessage(messages.documentNumberModal),
    value: miscTransaction.documentNumber,
  },
  {
    text: intl.formatMessage(messages.notes),
    value: miscTransaction.transactionNote,
  },
  {
    text: intl.formatMessage(messages.amount),
    value: intl.formatNumber(miscTransaction.transactionAmount, {
      style: 'currency',
      currency: 'USD',
    }),
  },
  ...(miscTransaction.checkImage
    ? [
        {
          text: intl.formatMessage(messages.frontCheck),
          value: miscTransaction.checkImage.front.url,
          isImage: true,
        },
        {
          text: intl.formatMessage(messages.backCheck),
          value: miscTransaction.checkImage.back.url,
          isImage: true,
        },
      ]
    : []),
];

export const parseMiscTransaction = (transaction: Object) => ({
  ...pick(
    [
      'id',
      'transactionDate',
      'ptcPayment',
      'ptcCharge',
      'status',
      'documentNumber',
      'transactionNote',
      'transactionAmount',
    ],
    transaction,
  ),
  checkImage: transaction?.domusoTransaction?.metadata?.checkImage,
  period: pathOr('', ['propertyFiscalPeriods', 'period'], transaction),
  paymentCode: pathOr(
    '',
    ['ptcPayment', 'transactionCode', 'code'],
    transaction,
  ),
  chargeCode: pathOr('', ['ptcCharge', 'transactionCode', 'code'], transaction),
  paymentCodeDescription: pathOr(
    '',
    ['ptcPayment', 'transactionCode', 'description'],
    transaction,
  ),
  chargeCodeDescription: pathOr(
    '',
    ['ptcCharge', 'transactionCode', 'description'],
    transaction,
  ),
});

export const renderMiscTransactionRow = (
  intl: Object,
  miscTransactions: Array<MiscTransaction>,
  reverseMiscTransaction: Function,
  canReverse: boolean,
): Array<any> => {
  return miscTransactions.map((transaction) => {
    const miscTransaction = parseMiscTransaction(transaction);
    const {
      id,
      period,
      transactionDate,
      paymentCode,
      chargeCode,
      status,
      documentNumber,
      transactionNote,
      transactionAmount,
    } = miscTransaction;
    const data = parseTransactionViewFields(intl, miscTransaction);
    const onReverseMiscTranactionClick = () => reverseMiscTransaction(id);
    const showReverse = paymentCode !== 'FTPMTCHECKFH';
    return (
      <tr key={id} data-test="misc-transactions-row">
        <td>
          {intl.formatDate(transactionDate, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          })}
        </td>
        <td>{formatPeriod(period)}</td>
        <td>{paymentCode}</td>
        <td>{chargeCode}</td>
        <td>{status}</td>
        <td>{documentNumber}</td>
        <td>{transactionNote}</td>
        <td>
          {intl.formatNumber(transactionAmount, {
            style: 'currency',
            currency: 'USD',
          })}
        </td>
        <td>
          <button
            className="btn btn-sm btn-default"
            onClick={() =>
              onViewTransactionClick(
                intl,
                data,
                onReverseMiscTranactionClick,
                period,
                status,
                canReverse,
                showReverse,
              )
            }
          >
            <i className="et-pencil" />
          </button>
        </td>
      </tr>
    );
  });
};

const MiscTransactionsDetails = ({
  intl,
  miscTransactions,
  reverseMiscTransaction,
  canReverse,
}: Props) => {
  const { formatMessage } = intl;
  return (
    <div className="container-fluid" data-test="misc-transactions-details">
      <table className="table table-prospects table-striped">
        <thead className="table-header">
          <tr>
            <th>{formatMessage(messages.date)}</th>
            <th>{formatMessage(messages.period)}</th>
            <th>{formatMessage(messages.paymentCode)}</th>
            <th>{formatMessage(messages.chargeCode)}</th>
            <th>{formatMessage(messages.status)}</th>
            <th>{formatMessage(messages.documentNumber)}</th>
            <th>{formatMessage(messages.notes)}</th>
            <th>{formatMessage(messages.amount)}</th>
            <th>{formatMessage(messages.view)}</th>
          </tr>
        </thead>
        {miscTransactions ? (
          <tbody data-test="misc-transactions-table">
            {renderMiscTransactionRow(
              intl,
              miscTransactions,
              reverseMiscTransaction,
              canReverse,
            )}
          </tbody>
        ) : (
          ''
        )}
      </table>
    </div>
  );
};

export default MiscTransactionsDetails;
