import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewUnit.UnitStatus.Title',
    defaultMessage: 'Unit Status',
  },
  status: {
    id: 'App.ViewUnit.UnitStatus.Status',
    defaultMessage: 'Status',
  },
  estimatedReadyDate: {
    id: 'App.ViewUnit.UnitStatus.EstimatedReadyDate',
    defaultMessage: 'Estimated Ready Date',
  },
  actualReadyDate: {
    id: 'App.ViewUnit.UnitStatus.ActualReadyDate',
    defaultMessage: 'Actual Ready Date',
  },
  choose: {
    id: 'App.ViewUnit.UnitStatus.Choose',
    defaultMessage: 'Choose',
  },
  statusNotes: {
    id: 'App.ViewUnit.UnitStatus.StatusNotes',
    defaultMessage: 'Status Notes',
  },
  notesPlaceholder: {
    id: 'App.ViewUnit.UnitStatus.NotesPlaceholder',
    defaultMessage: 'Start typing...',
  },
  changingUnitStatus: {
    id: 'App.ViewUnit.UnitStatus.ChangingUnitStatus',
    defaultMessage:
      'Changing the unit Status may also change the selection of the ' +
      'unit being hidden from the online availability.',
  },
  error: {
    id: 'App.ViewUnit.UnitStatus.Error',
    defaultMessage: 'Error',
  },
  invalidDate: {
    id: 'App.ViewUnit.UnitStatus.InvalidDate',
    defaultMessage: 'Please enter a valid date.',
  },
});

export default messages;
