import { messages } from './messages';
import { validateDate } from '../../../../utils/validations';

export const validateDateMessage = (date: any, intl: Object) => {
  if (!validateDate(date)) {
    return intl.formatMessage(messages.invalidDate);
  }
  if (date.isAfter(new Date())) {
    return intl.formatMessage(messages.dateCannotBeInTheFuture);
  }
  return false;
};

export const validateDateTime = (date: any) =>
  !validateDate(date) || date.isAfter(new Date());
