// $FlowFixMe
import { useEffect, useState, useCallback } from 'react';
import {
  isNil,
  isEmpty,
  find,
  prop,
  propEq,
  pick,
  pathOr,
  sortBy,
} from 'ramda';
import EntryPermissionsService from '../../services/entryPermissionService';
import WorkOrderPreferenceService from '../../services/workOrderPreferenceService';
import { DEFAULT_SELECTED_ENTRY_PERMISSION } from './constants';
import messages from './messages';

export const parseFormValues = (
  workOrderPreferences: Object,
  entryPreferenceOptions: Array<Object>,
) => {
  const defaultEntryPreference = find(
    propEq('text', DEFAULT_SELECTED_ENTRY_PERMISSION),
  )(entryPreferenceOptions);
  let initialValues = {
    isAlarmActive: 'no',
    isDoorCodeActive: 'no',
    defaultEntryPermissionPreferenceId: pathOr(
      null,
      ['value'],
      defaultEntryPreference,
    ),
  };
  if (!isEmpty(workOrderPreferences) && !isNil(workOrderPreferences)) {
    initialValues = pick([
      'isAlarmActive',
      'isDoorCodeActive',
      'doorCode',
      'alarmCode',
      'defaultEntryPermissionPreferenceId',
    ])(workOrderPreferences);
    initialValues = {
      ...initialValues,
      isAlarmActive: initialValues.isAlarmActive ? 'yes' : 'no',
      isDoorCodeActive: initialValues.isDoorCodeActive ? 'yes' : 'no',
    };
  }
  return initialValues;
};

export const useUnitEntryPreferences = (context: any) => {
  const [entryPreferences, setEntryPreferences] = useState([]);
  const { organizationId } = context.property;
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchHouseholdPreferences = async () => {
      if (isNil(entryPreferences) || isEmpty(entryPreferences)) {
        const entryPermissionsService = new EntryPermissionsService();
        const entryPermissions = await entryPermissionsService.getAll(
          organizationId,
        );
        const parsedEntryPermissions = entryPermissions.map((option) => ({
          value: option.entryPermissionId,
          text: option.entryPermissionDescription,
        }));
        const sortedEntryPermissions = sortBy(prop('text'))(
          parsedEntryPermissions,
        );
        setEntryPreferences(sortedEntryPermissions);
      }
    };

    if (subscribed) {
      fetchHouseholdPreferences();
    }

    return () => {
      subscribed = false;
    };
  }, [entryPreferences, organizationId]);

  return [entryPreferences];
};

export const useWorkOrderPreferences = (
  context: any,
  toasterFn: Function,
  intl: any,
) => {
  const [valuesChanged, setValuesChanged] = useState(false);

  const [workOrderPreferences, setWorkOrderPreferences] = useState(null);

  // API call to update information
  const { id: propertyId, organizationId } = context.property;
  const updateWorkOrderPreferences = useCallback(
    async (payload) => {
      const woPreferencesService = new WorkOrderPreferenceService();
      try {
        await woPreferencesService.createOrUpdate(organizationId, propertyId, {
          ...payload,
        });
        setValuesChanged(true);
        toasterFn({
          message: intl.formatMessage(messages.successBody),
          title: intl.formatMessage(messages.success),
        });
      } catch (error) {
        toasterFn({
          type: 'error',
          message: intl.formatMessage(messages.errorBody),
          title: intl.formatMessage(messages.error),
        });
      }
    },
    [propertyId, organizationId, intl, toasterFn],
  );

  // Fetcher for work order preference for a hh/unit
  const { householdId, unitId } = context;
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    let subscribed = true;

    const fetchHouseholdPreferences = async (
      householdId: string,
      unitId: string,
    ) => {
      if (isNil(workOrderPreferences) || valuesChanged) {
        const woPreferencesService = new WorkOrderPreferenceService();
        const householdPreferences = await woPreferencesService.search(
          organizationId,
          propertyId,
          { householdId, unitId },
        );
        setWorkOrderPreferences(householdPreferences);
        setValuesChanged(false);
      }
    };

    if (subscribed) {
      fetchHouseholdPreferences(householdId, unitId);
    }
    return () => {
      subscribed = false;
    };
  }, [
    workOrderPreferences,
    valuesChanged,
    householdId,
    unitId,
    propertyId,
    organizationId,
  ]);

  return [updateWorkOrderPreferences, workOrderPreferences];
};
