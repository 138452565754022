import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ViewProperty.Title',
    defaultMessage: 'Fortress - View Property',
  },
  goBack: {
    id: 'App.ViewProperty.goback',
    defaultMessage: 'Go Back to Manage Properties',
  },
  fetchPropertyErrorBody: {
    id: 'App.ViewProperty.Fetch.ErrorBody',
    defaultMessage: 'Failed to load property data',
  },
  success: {
    id: 'App.ViewProperty.Update.Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'App.ViewProperty.Update.Error',
    defaultMessage: 'Error',
  },
  successBody: {
    id: 'App.ViewProperty.Update.successBody',
    defaultMessage: 'Property updated',
  },
  errorBody: {
    id: 'App.ViewProperty.Update.errorBody',
    defaultMessage: 'Error updating Property',
  },
  viewEditBankInformation: {
    id: 'App.ViewProperty.ViewEditBankInformation',
    defaultMessage: 'View/Edit Bank Information',
  },
  postMLTInProgress: {
    id: 'App.ViewProperty.postMLTInProgress',
    defaultMessage: 'Posting first of month transactions, please wait...',
  },
  inProgress: {
    id: 'App.ViewProperty.inProgress',
    defaultMessage: 'In Progress',
  },
  integrationErrorBody: {
    id: 'App.ViewProperty.Update.integrationErrorBody',
    defaultMessage: 'Error updating integration',
  },
});

export default messages;
