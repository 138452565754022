import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { FormattedMessage } from 'react-intl';
import { isNil, isEmpty } from 'ramda';
import { bindActionCreators } from 'redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { promptToaster } from '../App/actions';
import ManageCertificationsContent from './ManageCertificationsContent';
import SelectionButtons from './SelectionButtons';

import CertificationsFilterDrawer from './CertificationsFilterDrawer';
import {
  HEADERS,
  FORM_NAME,
  CERTIFICATION_TYPES,
  OTHER_HUD,
  OTHER_LIHTC,
} from './constants';
import {
  useFetchComplianceApprovalStatuses,
  useFetchPropertyAffordablePrograms,
  useHasFilters,
} from './hooks';
import SearchActions from '../../components/SearchActions';
import { downloadCSV, parseCertFilters } from './utils';

import generalMessages from '../App/messages';
import componentMessages from './messages';

const messages = { ...generalMessages, ...componentMessages };

type ManageCertificationsPageProps = {
  intl: Object,
  organizationId: string,
  selectedProperty: Object,
};

const ManageCertificationsPage = ({
  actions: { promptToaster },
  intl,
  selectedProperty,
  organizationId,
  flags,
}: ManageCertificationsPageProps) => {
  const [displayAll, setDisplayAll] = useState(false);
  const [certificationType, setCertificationType] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState('{}');
  const [showFilter, setShowFilter] = useState(false);
  const [currentSort, setCurrentSort] = useState({
    columnName: '',
    order: '',
  });

  const hasFilters = useHasFilters(filter);

  const handleSortChange = (newSort: Object) => {
    setCurrentSort(newSort);
  };

  const handleApplyFilter = useCallback(
    (currentFilter: Object) => {
      const stringifiedFilter = JSON.stringify(currentFilter);
      setFilter(stringifiedFilter);
    },
    [setFilter],
  );

  const onSelectCertificationType = (selectedCertificationType: string) => {
    setCertificationType(selectedCertificationType);
    setFilter('{}');
    setCurrentSort({
      columnName: '',
      order: '',
    });
  };

  const handleSearchSubmit = ({ searchText = '' }) =>
    setSearchText(searchText.trim());

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  const handleDownload = () => {
    const order =
      isNil(currentSort) || isEmpty(currentSort)
        ? null
        : {
            fieldName: currentSort.columnName,
            order: currentSort.order,
          };
    const filters = parseCertFilters(JSON.parse(filter));
    const searchTextQuery = searchText ? { searchText } : {};
    const _hasFilters = hasFilters || searchText?.length;

    downloadCSV(
      certificationType,
      organizationId,
      selectedProperty.id,
      headers,
      searchTextQuery,
      filters,
      order,
      _hasFilters,
      intl,
      displayAll,
    );
  };

  const complianceApprovalStatuses = useFetchComplianceApprovalStatuses({
    flags,
    intl,
    toasterFn: promptToaster,
  });

  const propertyAffordablePrograms = useFetchPropertyAffordablePrograms({
    organizationId,
    propertyId: selectedProperty.id,
    intl,
    toasterFn: promptToaster,
  });

  const headers = HEADERS[certificationType];

  const showActionButtons = [
    CERTIFICATION_TYPES.RECERT,
    CERTIFICATION_TYPES.MOVE_IN,
    CERTIFICATION_TYPES.INITIAL,
    OTHER_HUD,
    OTHER_LIHTC,
  ].includes(certificationType);

  const searchActionsProps = {
    csvDownloadButton: {
      disabled: !certificationType,
      onClick: handleDownload,
    },
    filterButton: {
      disabled: !certificationType || displayAll,
      hasActiveFilters: hasFilters,
      onClick: showActionButtons ? handleFilterClick : undefined,
    },
    searchField: {
      disabled: !certificationType,
      form: FORM_NAME,
      onSubmit: handleSearchSubmit,
      placeholder: `${intl.formatMessage(messages.search)}`,
    },
  };

  return (
    <DocumentTitle title={intl.formatMessage(messages.title)}>
      <Grid className="bodywrap container-fluid" fluid>
        <Row>
          <Col xs={12}>
            <Col className="section-header" xs={3} md={4}>
              <h1>
                <FormattedMessage {...messages.title} />
              </h1>
            </Col>
            <Col xs={9} md={8}>
              {certificationType && <SearchActions {...searchActionsProps} />}
            </Col>
            <Col xs={12} md={12} className="padbottom10">
              <SelectionButtons
                certificationType={certificationType}
                onSelectCertificationType={onSelectCertificationType}
                flags={flags}
                selectedProperty={selectedProperty}
              />
            </Col>
            <ManageCertificationsContent
              certificationType={certificationType}
              searchText={searchText}
              filter={filter}
              currentSort={currentSort}
              handleSortChange={handleSortChange}
              headers={headers}
              complianceApprovalStatuses={complianceApprovalStatuses}
              propertyAffordablePrograms={propertyAffordablePrograms}
              displayAll={displayAll}
              setDisplayAll={setDisplayAll}
              show={showFilter}
              setShow={setShowFilter}
            />
            <CertificationsFilterDrawer
              show={showFilter}
              setShow={setShowFilter}
              handleApplyFilter={handleApplyFilter}
              certificationType={certificationType}
              handleFilterClick={handleFilterClick}
              complianceApprovalStatuses={complianceApprovalStatuses}
              propertyAffordablePrograms={propertyAffordablePrograms}
              displayAll={displayAll}
            />
          </Col>
        </Row>
      </Grid>
    </DocumentTitle>
  );
};

export const mapStateToProps = ({
  app: { currentUser, selectedProperty },
}: any): any => {
  return {
    organizationId: currentUser ? currentUser.user.organizationId : '',
    selectedProperty,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  );
  return { actions };
}

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ManageCertificationsPage));

export default withLDConsumer()(connected);
