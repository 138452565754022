import React from 'react';
import { Row, Col } from 'react-bootstrap';

const LeaseFormSignatureAndRepresentations = () => {
  return (
    <div className="print-only">
      <div className="block-heading">
        <h2>Signature and Representations</h2>
      </div>
      <Row>
        <Col xs={12}>
          <p>
            In signing the below, I represent that the information set forth on
            this application is true and complete. I specifically authorize and
            acknowledge the performance of a criminal and credit background
            check. I understand that this application may be rejected based on
            information contained in the reports. I further authorize
            verification of any and all information set forth on this
            Application, including release of information by any employer
            (present and former) and any lender. I waive all right of action for
            any consequences resulting from such information. All such
            information contained herein and released as authorized above will
            be kept confidential. I understand that this is not a lease
            agreement.{' '}
          </p>
        </Col>
      </Row>
      <Row className="signature-container">
        <Col md={6} className="text-center">
          <label>Signature</label>
          <input className="input-lg form-control" type="text" />
        </Col>
        <Col md={6} className="text-center">
          <label>Date of Submission</label>
          <input className="input-lg form-control" type="text" />
        </Col>
      </Row>
    </div>
  );
};

export default LeaseFormSignatureAndRepresentations;
