import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import { renderSelectField } from '../../../../../utils/redux-form-helper';
import { pathOr } from 'ramda';
import messages from './messages';
import DashesIfNullOrUndefined from '../../../../../components/DashesIfNullOrUndefined';

export const LeaseBasicsLeftColumn = ({
  basicLeaseFees,
  intl,
  isEditable,
  isTransfer,
  lateMethods,
  unit,
}: any) => {
  const unitNumber = pathOr('', ['number'], unit);
  const floorplanName = pathOr('', ['floorPlan', 'internalName'], unit);
  return (
    <Col xs={12} sm={6}>
      <Row>
        <Col xs={7}>
          <label>
            {isTransfer ? 'New ' : ''}
            {intl.formatMessage(messages.unitNumberLabel)}
          </label>
        </Col>
        <Col xs={5}>
          <div className="form-group">{unitNumber}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={7}>
          <label>{intl.formatMessage(messages.floorPlanLabel)}</label>
        </Col>
        <Col xs={5}>
          <div className="form-group">{floorplanName}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label className="required">
            {intl.formatMessage(messages.lateMethodLabel)}
          </label>
        </Col>
        <Col xs={12}>
          <div className="form-group">
            <Field
              name="lateMethodId"
              component={renderSelectField}
              options={lateMethods}
              disabled={!isEditable}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <label>{intl.formatMessage(messages.basicLeaseFeesLabel)}</label>
          <div className="form-group form-groupbox form-groupbox--scrollable">
            {basicLeaseFees.map((fee) => {
              let feeDescription = pathOr(null, ['displayNameOnQuote'], fee);
              if (feeDescription === null) {
                feeDescription = pathOr(
                  null,
                  ['ptc', 'transactionCode', 'description'],
                  fee,
                );
              }
              return (
                <Row key={fee.id}>
                  <Col xs={7}>
                    <label>
                      <DashesIfNullOrUndefined data={feeDescription} />
                    </label>
                  </Col>
                  <Col xs={5}>
                    <div className="form-group">
                      {fee.descriptiveFeeAmount
                        ? fee.descriptiveFeeAmount
                        : `$${fee.feeAmount}`}
                    </div>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default LeaseBasicsLeftColumn;
