import * as ActionTypes from './constants';
import type {
  Action,
  PaginationMeta,
  OrderValue,
  FilterValue,
} from '../App/types';

export function getAllCommunications(
  sorting: OrderValue,
  filterValue: FilterValue,
): * {
  return {
    type: ActionTypes.GET_ALL_COMMUNICATIONS,
    payload: {
      sorting,
      filterValue,
    },
  };
}

export function getAllCommunicationsSuccess(
  communications: Array<any>,
  meta: PaginationMeta,
) {
  return {
    type: ActionTypes.GET_ALL_COMMUNICATIONS_SUCCESS,
    payload: communications,
    meta,
  };
}

export function getAllCommunicationsFailed(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ALL_COMMUNICATIONS_ERROR,
    payload: error,
  };
}

export function getOneCommunication(headerId: string, status: string) {
  return {
    type: ActionTypes.GET_ONE_COMMUNICATION,
    payload: { headerId, status },
  };
}

export function getOneCommunicationSuccess(communication: Object) {
  return {
    type: ActionTypes.GET_ONE_COMMUNICATION_SUCCESS,
    payload: communication,
  };
}

export function getOneCommunicationError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_ONE_COMMUNICATION_ERROR,
    payload: error,
  };
}

export function updateColumnsSortValue(field: string, value: string) {
  return {
    type: ActionTypes.UPDATE_COLUMNS_SORT_VALUE,
    payload: { [field]: value },
  };
}

export function cleanLoadedCommunications(): Action<any> {
  return {
    type: ActionTypes.CLEAN_LOADED_COMMUNICATIONS,
    payload: undefined,
  };
}
