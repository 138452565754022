import { useMemo } from 'react';
import { palette } from '@fortress-technology-solutions/fortress-component-library/design';

const getRowSX = ({ isPastDue, isAttentionRequired }) => {
  if (isPastDue) {
    return { backgroundColor: palette.lighter.red, color: palette.main.red };
  }

  if (isAttentionRequired && !isPastDue) {
    return { backgroundColor: palette.lighter.purple };
  }

  return undefined;
};
const useRows = ({
  sortedAndFilteredResults,
  ModalProps: { handleOpen: handleOpenModal },
  isFeatureFlagActiveAndIncludeNotStartedCerts,
}) => {
  return useMemo(() => {
    return sortedAndFilteredResults?.map(
      ({
        applicantName,
        applicationId,
        certOpenedDate,
        certificationType,
        certStatus,
        complianceApprovalStatus,
        complianceDocumentType,
        isActive,
        isAttentionRequired,
        isPastDue,
        isResident,
        isReviewed,
        oldestSubmissionDocumentDate,
        programType,
        propertyId,
        propertyName,
        residentId,
        unitNumber,
        uploadDate,
        voucherEffectiveDate,
      }) => {
        let customerType = 'application';
        const customerId =
          isResident === 'Yes' || isResident === true
            ? residentId
            : applicationId;

        if (certificationType === 'Move-out') {
          customerType = 'prior-resident';
        } else if (isResident === 'Yes' || isResident === true) {
          customerType = 'resident';
        }

        const sx = getRowSX({ isPastDue, isAttentionRequired });

        return {
          sx,
          certificationType: {
            value: certificationType,
          },
          ...(isFeatureFlagActiveAndIncludeNotStartedCerts
            ? {
                certStatus: {
                  value: certStatus,
                },
              }
            : []),
          propertyName: {
            value: propertyName,
          },
          unitNumber: {
            value: unitNumber,
          },
          applicantName: {
            variant: 'link',
            to: `/property/${propertyId}/${customerType}/${customerId}`,
            target: '_blank',
            value: applicantName,
          },
          complianceDocumentType: {
            value: complianceDocumentType,
          },
          uploadDate: {
            variant: 'dateTime',
            value: uploadDate,
          },
          isReviewed: {
            value: isReviewed ? 'Yes' : 'No',
          },
          programType: {
            value: programType,
          },
          voucherEffectiveDate: {
            variant: 'date',
            value: voucherEffectiveDate,
          },
          complianceApprovalStatus: {
            variant: 'complianceApprovalStatus',
            value: complianceApprovalStatus,
          },
          certHistory: {
            variant: 'iconButton',
            iconName: 'OverviewIcon',
            color: 'primary',
            onClick: () => {
              handleOpenModal('certificationHistory', {
                applicantName,
                oldestSubmissionDocumentDate,
                certOpenedDate,
              });
            },
          },
          isActive: {
            value: isActive ? 'Yes' : 'No',
          },
          isResident: {
            value: isResident ? 'Yes' : 'No',
          },
          isPastDue: {
            value: isPastDue ? 'Yes' : 'No',
          },
          isAttentionRequired: {
            value: isAttentionRequired ? 'Yes' : 'No',
          },
        };
      },
    );
  }, [
    handleOpenModal,
    sortedAndFilteredResults,
    isFeatureFlagActiveAndIncludeNotStartedCerts,
  ]);
};

export default useRows;
