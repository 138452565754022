import { all, cond, contains, equals, filter, not, pathOr, T } from 'ramda';

export const filterSelectableCodes = (txCodes: Array<Object>) =>
  filter((code) => code.userSelectable, txCodes);

export const filterOutApplicantOnlyCodes = (txCodes: Array<Object>) =>
  filter(
    (code) => !pathOr(false, ['transactionCode', 'isApplicantExcluded'], code),
    txCodes,
  );

export const filterOutAfterFASOnlyCodes = (txCodes: Array<Object>) =>
  filter(
    (code) => !pathOr(false, ['transactionCode', 'isAfterFASOnly'], code),
    txCodes,
  );

export const filterOutAfterFASExcludedCodes = (txCodes: Array<Object>) =>
  filter(
    (code) => !pathOr(false, ['transactionCode', 'isAfterFASExcluded'], code),
    txCodes,
  );

export const getCurrentResidentCodes = (txCodes: Array<Object>) =>
  filterSelectableCodes(filterOutAfterFASOnlyCodes(txCodes));

export const getApplicantCodes = (txCodes: Array<Object>) =>
  filterOutApplicantOnlyCodes(
    filterSelectableCodes(filterOutAfterFASOnlyCodes(txCodes)),
  );

export const getPriorResidentAfterFASCodes = (txCodes: Array<Object>) =>
  filterSelectableCodes(filterOutAfterFASExcludedCodes(txCodes));

export const transactionCodesProvider = (
  customerStatus: string,
  txCodes: Array<Object>,
  fasCreated: boolean = false,
) => {
  // $FlowFixMe
  const priorAfterFas = () => equals('Prior Resident') && fasCreated;
  const codeMap = cond([
    [equals('Applicant'), () => getApplicantCodes(txCodes)],
    [equals('Resident'), () => getCurrentResidentCodes(txCodes)],
    [priorAfterFas, () => getPriorResidentAfterFASCodes(txCodes)],
    [equals('Prior Resident'), () => getCurrentResidentCodes(txCodes)],
    [T, () => filterSelectableCodes(txCodes)],
  ]);

  return codeMap(customerStatus);
};

export const getTransactionIsReversible = (
  transaction: Object,
  ledgerIsOps: boolean = false,
  allowDomusoReversals: boolean = false,
  fasCreated: boolean = false,
  writtenOffBalance: number,
) => {
  // $FlowFixMe
  const code = pathOr(
    '',
    ['propertyTransactionCode', 'transactionCode', 'code'],
    transaction,
  );
  // $FlowFixMe
  const transactionType = pathOr(
    '',
    ['propertyTransactionCode', 'transactionCode', 'transactionType', 'name'],
    transaction,
  );
  // $FlowFixMe
  const documentNumber = pathOr('', ['documentNumber'], transaction);

  const domusoReversalDisabled =
    !allowDomusoReversals &&
    ([
      'PYMTDOM',
      'FTPMTACH',
      'FTPMTCHECK',
      'FTPMTCHECKFH',
      'FTPMTCASH',
      'FTPMTCREDIT',
      'FTPMTDEBIT',
      'FTPMTMONEYORDER',
    ].includes(code) ||
      (code === 'PYMTPAYLS' &&
        documentNumber.indexOf('Rent, Order ID') !== -1));

  const transactionIsPosted = transaction.status === 'Posted';

  // $FlowFixMe
  const codeIsReversible = !pathOr(
    false,
    ['propertyTransactionCode', 'transactionCode', 'disableReversal'],
    transaction,
  );

  //TODO: Verify if this is equivalent to the next validation, if so, remove it.
  const isSubsidyReversible =
    !ledgerIsOps ||
    writtenOffBalance <= 0 ||
    pathOr(
      false,
      ['propertyTransactionCode', 'transactionCode', 'isPayment'],
      transaction,
    );

  const afterFASReversalDisabled =
    ledgerIsOps &&
    fasCreated &&
    contains(transactionType, ['Charge', 'Credit']);
  const afterFASReversalDisabledForSecurity =
    fasCreated && contains(transactionType, ['Security Charge']);
  return all(equals(true))([
    not(domusoReversalDisabled),
    not(afterFASReversalDisabled || afterFASReversalDisabledForSecurity),
    transactionIsPosted,
    codeIsReversible,
    isSubsidyReversible,
  ]);
};
