import { get, patch } from '../utils/api';
import * as R from 'ramda';

import moment from 'moment';
import type Moment from 'moment';

export type GraphData = {
  [key: string]: {
    [key: string]: {
      leaseLimit: number,
      leases: number,
      actualLeases: number,
      activeLeases: number,
      renewalLeases: number,
      limit: number,
      overLimit: number,
      availableLeases: number,
      moveInProcess: number,
    },
  },
  mtm: number,
  units: number,
  leaseMax: number,
  limitMax: number,
  noLimits: boolean,
};

export type LimitUpdate = {
  id: string,
  limit: number,
};

const prepareDate = (date: any) => {
  if (moment.isMoment(date)) return date;
  if (typeof date === 'string') return moment(date);
  return {
    isValid: () => false,
  };
};

export default class LeaseExpirationLimitService {
  getLeaseExpirationLimitsGraphData(
    organizationId: string,
    propertyId: string,
    years: number[],
  ): Promise<GraphData> {
    return get(
      `/${organizationId}/properties/${propertyId}/lease-expiration-limits?years=${years.join(
        ',',
      )}`,
    );
  }

  async validateLeaseExpirationLimit(
    organizationId: string,
    propertyId: string,
    endDate: Moment,
    expectedEndDate: Moment,
    leaseId: ?string,
    offset = 61,
  ) {
    const momentEndDate = prepareDate(endDate);
    const momentExpectedEndDate = prepareDate(expectedEndDate);

    if (!momentEndDate.isValid() || !momentExpectedEndDate.isValid())
      throw new Error('Invalid end date format.');

    const searchParams = new URLSearchParams(
      R.reject(R.isNil, {
        endDate: momentEndDate.toISOString(),
        expectedEndDate: momentExpectedEndDate.toISOString(),
        offset,
        leaseId,
      }),
    );

    return get(
      `/${organizationId}/${propertyId}/lease-expiration-limit/validate?${searchParams.toString()}`,
    );
  }

  getLimitDataByYears(
    organizationId: string,
    propertyId: string,
    years: number[],
  ) {
    return get(
      `/${organizationId}/properties/${propertyId}/lease-expiration-limits/limit-data?years=${years.join(
        ',',
      )}`,
    );
  }

  editLimits(
    organizationId: string,
    propertyId: string,
    limitUpdates: LimitUpdate[],
  ) {
    const body = JSON.stringify(limitUpdates);
    return patch(
      `/${organizationId}/${propertyId}/lease-expiration-limits`,
      body,
    );
  }
}
