import { defineMessages } from 'react-intl';

const messages = defineMessages({
  oneTimeRentConcessionLabel: {
    id: 'App.LeaseDataTabForm.Concessions.OneTimeRentConcessionLabel',
    defaultMessage: 'One-Time Rent Concession',
  },
  amountLabel: {
    id: 'App.LeaseDataTabForm.Concessions.AmountLabel',
    defaultMessage: 'Amount',
  },
  monthlyRentConcessionLabel: {
    id: 'App.LeaseDataTabForm.Concessions.MonthlyRentConcessionLabel',
    defaultMessage: 'Monthly Rent Concession',
  },
  otherDiscountConcessionLabel: {
    id: 'App.LeaseDataTabForm.Concessions.OtherDiscountConcessionLabel',
    defaultMessage: 'Other/Discount Concession',
  },
  monthsLabel: {
    id: 'App.LeaseDataTabForm.Concessions.MonthsLabel',
    defaultMessage: 'Months',
  },
  descriptionLabel: {
    id: 'App.LeaseDataTabForm.Concessions.DescriptionLabel',
    defaultMessage: 'Description',
  },
});

export default messages;
