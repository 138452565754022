/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  Input,
  Spinner,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import useFormatMessage from '../../hooks/useFormatMessage';
import { Modal } from 'react-bootstrap';
import messages from './messages';
import EditFeeForm from './editFeeForm';
import { useForm, FormProvider } from 'react-hook-form';
import PropertyFeesService from '../../services/propertyFeesService';
import { toastr } from 'react-redux-toastr';
import useSelectedProperty from '../../hooks/useSelectedProperty';
function EditFeeModal({ intl, isOpen, selectedFee, onClose, refetchFees }) {
  const selectedProperty = useSelectedProperty();
  const initialValues = {
    descriptionAmount: selectedFee.descriptionAmount,
    feeAmount: selectedFee.feeAmount,
    feeType: selectedFee.feeAmount ? 'feeAmount' : 'descriptionAmount',
  };

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: initialValues,
  });

  const isDirty = methods.formState.isDirty;

  const formatMessage = useFormatMessage();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const onCancelClick = () => {
    if (isDirty) {
      setShowConfirmation(true);
    } else {
      onClose();
    }
  };

  const onConfirmationCancel = () => {
    setShowConfirmation(false);
  };
  const onConfirmationProceed = () => {
    onClose();
    setShowConfirmation(false);
  };

  const renderCancelConfirmation = () => {
    return (
      <div
        className="modal-confirm"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '0px',
          marginTop: '0px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h2" sx={{ marginBottom: '30px' }}>
          {intl.formatMessage(messages.cancelConfirmationHeader)}
        </Typography>
        <Stack
          direction="row"
          justifyContent="center"
          spacing={2}
          marginRight={4}
          marginLeft={4}
        >
          <Button fullWidth variant="primary" onClick={onConfirmationProceed}>
            {intl.formatMessage(messages.yes)}
          </Button>
          <Button fullWidth onClick={onConfirmationCancel}>
            {intl.formatMessage(messages.no)}
          </Button>
        </Stack>
      </div>
    );
  };

  const onSubmit = async (data) => {
    try {
      const service = new PropertyFeesService();
      const payload = {};
      if (data.feeType === 'feeAmount') {
        payload.feeAmount = data.feeAmount;
      } else {
        payload.descriptiveFeeAmount = data.descriptionAmount;
      }
      const result = await service.updatePropertyFeeWithAssociations(
        selectedProperty.organizationId,
        selectedProperty.id,
        selectedFee.id,
        payload,
      );

      onClose();
      refetchFees();
      toastr.success(
        formatMessage(messages.successTitle),
        formatMessage(messages.successMessage),
      );
    } catch (error) {
      toastr.error('Error', error || error.message);
    }
  };

  return (
    <FormProvider {...methods}>
      <Modal
        backdrop="static"
        keyboard={false}
        show={isOpen}
        onHide={() => {}}
        bsSize="small"
        autoFocus
      >
        <Modal.Header>
          <Typography variant="h2">
            {formatMessage(messages.editTitle, {
              code: selectedFee?.feeTransactionCode,
            })}
          </Typography>
        </Modal.Header>
        <Modal.Body style={{ marginTop: '0px' }}>
          {showConfirmation && renderCancelConfirmation()}
          <EditFeeForm intl={intl} />
        </Modal.Body>
        {!showConfirmation && (
          <Modal.Footer>
            <Stack
              direction="row"
              justifyContent="center"
              spacing={2}
              marginRight={4}
              marginLeft={4}
              margin={1}
            >
              <Button
                fullWidth
                type="button"
                variant="default"
                onClick={() => onCancelClick()}
                disabled={false}
              >
                {intl.formatMessage(messages.cancel)}
              </Button>
              <Button
                fullWidth
                type="submit"
                variant="primary"
                onClick={methods.handleSubmit((data) => {
                  onSubmit(data);
                })}
                disabled={!isDirty}
              >
                {intl.formatMessage(messages.saveChanges)}
              </Button>
            </Stack>
          </Modal.Footer>
        )}
      </Modal>
    </FormProvider>
  );
}

export default EditFeeModal;
