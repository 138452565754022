import classNames from 'classnames';
import * as R from 'ramda';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from 'react-select';
import styled from 'styled-components';
import Spacer from '../../../../components/Spacer';
import Spinner from '../../../../components/Spinner';
import type { Intl } from '../../../../types';
import { validateEmail } from '../../../../utils/validations';
import { sortHouseholds } from '../../../CreateSubsidyBatch/utils';
import { useDownloadProrateCalcScreen } from '../hooks';
import {
  useProrateCalculatorEmailRecipients,
  useSendProrateCalculatorEmail,
} from './hooks';
import messages from './messages';

type Props = {
  show: boolean,
  onHide: () => void,
  organizationId: string,
  propertyId: string,
  propertyName: string,
  intl: Intl,
  headerFormValues: Object,
  formValues: Object,
};

const MoveInMoveOutLabels = {
  moveIn: 'Move In',
  moveOut: 'Move Out',
};

const StatusLabels = {
  prospect: 'Prospect',
  applicant: 'Applicant',
  current: 'Current Resident',
};

const StyledDiv = styled.div`
  width: 48%;
  display: inline-block !important;
`;

const formatProspects = (prospects) =>
  prospects.map((prospect) => ({
    label: `${prospect.firstName} ${prospect.lastName}`,
    value: prospect.id,
  }));

function EmailModal(props: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      status: '',
      household: '',
      prospect: '',
    },
  });

  useEffect(() => {
    if (props.show) {
      reset();
    }
  }, [props.show, reset]);

  useEffect(() => {
    if (props.show && props.headerFormValues?.moveInMoveOut) {
      setValue(
        'emailSubject',
        props.intl.formatMessage(messages.emailSubjectMessage, {
          moveInMoveOut:
            MoveInMoveOutLabels[props.headerFormValues?.moveInMoveOut],
          propertyName: props.propertyName,
        }),
      );
      setValue(
        'messageBody',
        props.intl.formatMessage(messages.messageBodyMessage, {
          moveInMoveOut:
            MoveInMoveOutLabels[props.headerFormValues?.moveInMoveOut],
          propertyName: props.propertyName,
        }),
      );
    }
  }, [
    props.intl,
    props.headerFormValues?.moveInMoveOut,
    props.propertyName,
    props.show,
    setValue,
  ]);

  const [recipients] = useProrateCalculatorEmailRecipients(
    props.organizationId,
    props.propertyId,
  );
  const householdsMap = useMemo(
    () => sortHouseholds(recipients.households, true),
    [recipients.households],
  );

  const { value: statusWatch } = watch('status');
  const { value: householdWatch } = watch('household');
  const { value: prospectWatch } = watch('prospect');

  useEffect(() => {
    setValue('household', '');
    setValue('prospect', '');
  }, [setValue, statusWatch]);

  const householdOptions = useMemo(
    () => householdsMap[statusWatch] ?? [],
    [statusWatch, householdsMap],
  );

  const prospectOptions = useMemo(
    () => formatProspects(recipients.prospects),
    [recipients.prospects],
  );

  const formattedDate = useMemo(
    () => new Date().toISOString().split('T')[0],
    [],
  );

  const handleDownload = useDownloadProrateCalcScreen({
    organizationId: props.organizationId,
    propertyId: props.propertyId,
    formValues: props.formValues,
    headerFormValues: props.headerFormValues,
    householdId: householdWatch,
    prospectId: prospectWatch,
  });

  const [sendProrateCalculatorEmail, isLoading, success] =
    useSendProrateCalculatorEmail(props.organizationId, props.propertyId);

  const { onHide } = props;
  useEffect(() => {
    if (success) {
      onHide();
    }
  }, [onHide, success]);

  const onSubmit = useCallback(
    (values: Object, event: Event) => {
      event.preventDefault();

      sendProrateCalculatorEmail({
        headerFormValues: props.headerFormValues,
        formValues: props.formValues,
        ...R.omit(['household'], values),
        status: values.status.value,
        householdId: values.household.value,
        prospectId: values.prospect.value,
      });
    },
    [props.formValues, props.headerFormValues, sendProrateCalculatorEmail],
  );

  return (
    <Modal show={props.show} onHide={props.onHide} bsSize="lg">
      <Modal.Header>
        <i className="icon et-calendar" />
        <Spacer h={13} inline />
        <h1>
          <FormattedMessage {...messages.prorateCalculatorEmail} />
        </h1>
        <Spacer v={16} />
        <span style={{ fontWeight: 400, fontSize: 14, marginLeft: '1%' }}>
          * <FormattedMessage {...messages.requiredFields} />
        </span>
      </Modal.Header>
      <Modal.Body style={{ margin: 14 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={classNames('form-group', {
              'has-error': errors.fromEmail,
            })}
          >
            <label htmlFor="fromEmail">
              <FormattedMessage {...messages.fromEmail} />*
            </label>
            <input
              className="form-control input-lg"
              placeholder="Enter email"
              {...register('fromEmail', {
                required: true,
                validate: { invalid: validateEmail },
              })}
            />
            {errors.fromEmail &&
              (errors.fromEmail.type === 'invalid' ? (
                <FormattedMessage {...messages.invalidEmail} />
              ) : (
                <FormattedMessage {...messages.thisFieldIsRequired} />
              ))}
          </div>

          <div>
            <label htmlFor="status">
              <FormattedMessage {...messages.recipients} />*
            </label>
            <Spacer h={8} inline />
            <FormattedMessage {...messages.recipientsNote} />
            <Spacer />
            <StyledDiv>
              <Controller
                name="status"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={Object.keys(StatusLabels).map((status) => ({
                      value: status,
                      label: props.intl.formatMessage(messages[status]),
                    }))}
                    placeholder={props.intl.formatMessage(
                      messages.selectStatus,
                    )}
                  />
                )}
              />
            </StyledDiv>
            <StyledDiv style={{ marginLeft: '4%' }}>
              {statusWatch === 'prospect' ? (
                <Controller
                  name="prospect"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={prospectOptions.map(({ value, label }) => ({
                        value,
                        label,
                      }))}
                      placeholder={props.intl.formatMessage(
                        messages.selectUnitResident,
                      )}
                      isDisabled={statusWatch === ''}
                      blurInputOnSelect
                    />
                  )}
                />
              ) : (
                <Controller
                  name="household"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={householdOptions.map(({ value, label }) => ({
                        value: value.householdId,
                        label,
                      }))}
                      placeholder={props.intl.formatMessage(
                        messages.selectUnitResident,
                      )}
                      isDisabled={statusWatch === ''}
                      blurInputOnSelect
                    />
                  )}
                />
              )}
            </StyledDiv>
          </div>

          <div
            className={classNames('form-group', {
              'has-error': errors.emailSubject,
            })}
            style={{ marginTop: 10 }}
          >
            <label htmlFor="emailSubject">
              <FormattedMessage {...messages.emailSubject} />
            </label>
            <input
              className="form-control input-lg"
              {...register('emailSubject', { required: true })}
              disabled={true}
            />
            {errors.emailSubject && (
              <FormattedMessage {...messages.thisFieldIsRequired} />
            )}
          </div>

          <div
            className={classNames('form-group', {
              'has-error': errors.messageBody,
            })}
          >
            <label htmlFor="messageBody">
              <FormattedMessage {...messages.messageBody} />
            </label>
            <textarea
              className="form-control input-lg"
              {...register('messageBody', { required: true })}
              disabled={true}
              style={{ paddingLeft: 7 }}
            />
            {errors.messageBody && (
              <FormattedMessage {...messages.thisFieldIsRequired} />
            )}
          </div>

          <div>
            <label>
              <FormattedMessage {...messages.attachments} />
            </label>
            <Spacer />
            <FormattedMessage
              {...messages.attachmentsMessage}
              values={{
                moveInMoveOut:
                  MoveInMoveOutLabels[props.headerFormValues?.moveInMoveOut],
                date: formattedDate,
              }}
              tagName="label"
            />
            <Spacer h={8} inline />
            <a onClick={handleDownload} disabled={!isValid}>
              <i className="et-cloud-download" style={{ fontSize: 16 }} />
            </a>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer style={{ textAlign: 'center' }}>
        <Button onClick={props.onHide} disabled={isLoading}>
          <FormattedMessage {...messages.cancel} />
        </Button>
        <Spacer h={16} inline />
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || isLoading}
        >
          {isLoading ? (
            <Spinner small />
          ) : (
            <FormattedMessage {...messages.sendEmail} />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default injectIntl(EmailModal);
