import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as formHelpers from '../../utils/redux-form-helper';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { useFormContext } from 'react-hook-form';
import Label from './Label';
import DatePicker from './DatePicker';
import moment from 'moment';
import messages from '../ActivityContents/messages';
import {
  grey,
  red,
} from '@fortress-technology-solutions/fortress-component-library/design';

const MM_DD_YYYY_FORMAT = 'MM/DD/YYYY';

const Separator = styled.div`
  width: 16px;
  height: 0;
  border: 1px solid ${grey.lighter};
  margin: 0 10px;
`;

const NoInputPadding = styled.div`
  .form-control {
    padding: 0 6px;
  !important;
  }
`;

const RangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rdt {
    flex-grow: 1;
  !important;
  }

  .form-control {
    padding: 0 6px;
  !important;
  }
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  color: ${red.dark};
  font-weight: 500;
`;

const dateTimeClassname = 'date-range-ctrl';

type Props = { intl: Object };

function DateRangePicker(props: Props) {
  const {
    register,
    control,
    getValues,
    resetField,
    formState: { errors },
  } = useFormContext();

  const isRange = getValues('isRange');

  useEffect(() => {
    if (!isRange) {
      resetField('toDate');
    }
  }, [isRange, resetField]);

  const isDateFormatValid = (value) =>
    value === '' ||
    moment(value, MM_DD_YYYY_FORMAT, true).isValid() ||
    'Date must be in MM/DD/YYYY format';

  const isEndDateAfterStartDate = (endDate) => {
    const startDate = getValues('fromDate');
    if (!startDate || !endDate) return false;
    if (
      !moment(startDate, MM_DD_YYYY_FORMAT, true).isValid() ||
      !moment(endDate, MM_DD_YYYY_FORMAT, true).isValid()
    )
      return false;
    return (
      endDate === '' ||
      moment(endDate.toDate()).isAfter(moment(startDate.toDate())) ||
      'Start date must come before end date'
    );
  };

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Label>{props.intl.formatMessage(messages.date)}</Label>
        <Stack direction="row" spacing={1}>
          <input
            name="isRange"
            type="checkbox"
            {...register('isRange')}
            checked={isRange}
          />
          <Label>{props.intl.formatMessage(messages.range)}</Label>
        </Stack>
      </Stack>
      {isRange && (
        <Stack direction="column">
          <RangeContainer>
            <DatePicker
              control={control}
              name={'fromDate'}
              inputProps={{
                placeholder: 'Start',
                className: dateTimeClassname,
              }}
              timeFormat={false}
              closeOnSelect={true}
              renderInput={formHelpers.renderDatePickerInput}
              rules={{
                validate: {
                  required: (value) => {
                    if (!value && getValues('toDate') && getValues('isRange'))
                      return 'Start date is required for range';
                    return true;
                  },
                  validDate: isDateFormatValid,
                },
              }}
            />
            <Separator />
            <DatePicker
              name={'toDate'}
              control={control}
              inputProps={{
                placeholder: 'End',
                className: dateTimeClassname,
              }}
              timeFormat={false}
              closeOnSelect={true}
              rules={{
                validate: {
                  required: (value) => {
                    if (!value && getValues('isRange'))
                      return 'Start and end date required when range selected';
                    return true;
                  },
                  validDate: isDateFormatValid,
                  dataRangeValid: isEndDateAfterStartDate,
                },
              }}
              renderInput={formHelpers.renderDatePickerInput}
            />
          </RangeContainer>
          {errors.fromDate && (
            <Stack marginTop={1} marginBottom={1}>
              <ErrorMessage>{errors.fromDate.message}</ErrorMessage>
            </Stack>
          )}
          {errors.toDate && (
            <Stack marginTop={1} marginBottom={1}>
              <ErrorMessage>{errors.toDate.message}</ErrorMessage>
            </Stack>
          )}
        </Stack>
      )}

      {!isRange && (
        <NoInputPadding>
          <Stack direction="column">
            <DatePicker
              control={control}
              name={'fromDate'}
              inputProps={{
                placeholder: MM_DD_YYYY_FORMAT,
                className: dateTimeClassname,
              }}
              timeFormat={false}
              closeOnSelect={true}
              renderInput={formHelpers.renderDatePickerInput}
              rules={{
                validate: {
                  validDate: isDateFormatValid,
                },
              }}
            />
            {errors.fromDate && (
              <Stack marginTop={1} marginBottom={1}>
                <ErrorMessage>{errors.fromDate.message}</ErrorMessage>
              </Stack>
            )}
          </Stack>
        </NoInputPadding>
      )}
    </Stack>
  );
}

export default DateRangePicker;
