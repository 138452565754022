import messages from './messages';
import ActivityService from '../../services/activityService';

const getReturnPromise = (errorFromForm: any, errorToReturn: any) => {
  const errors = {};
  if (errorFromForm && errorFromForm.sync) {
    errors._error = errorFromForm;
  }

  if (errorToReturn) {
    errors._error = {
      sync: false,
      async: true,
      message: errorToReturn,
    };
  }

  if (errors._error) {
    return Promise.reject(errors);
  }
  return Promise.resolve();
};

const asyncValidate = (
  values: Object,
  dispatch: Function,
  { intl, mode, error, prospect, organizationId }: Object,
): Promise<void> => {
  if (mode === 'schedule' && !values.noTimes) {
    const activityService = new ActivityService();
    let errorToReturn;
    return activityService
      .getPendingByAssignee(
        values.assignedToId,
        prospect.propertyId,
        organizationId,
      )
      .then((response: Object) => {
        if (response.length >= 201) {
          errorToReturn = intl.formatMessage(
            messages.tooManyActivitiesForAssignee,
          );
        }
        return activityService
          .getPendingByProspect(
            prospect.id,
            prospect.propertyId,
            organizationId,
          )
          .then((response: Object) => {
            if (response.length >= 5) {
              errorToReturn = intl.formatMessage(
                messages.tooManyActivitiesForProspect,
              );
            }

            return getReturnPromise(error, errorToReturn);
          });
      });
  }

  return getReturnPromise(error);
};

export default asyncValidate;
