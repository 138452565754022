import {
  formatCurrency,
  formatNumberToString,
} from '@fortress-technology-solutions/fortress-component-library/utils';
import { pathOr } from 'ramda';
import moment from 'moment';
import { parseAffordableRent } from '../../utils/lease-helpers';

const HUD_FLOORPLAN = 'HUD';
const RD_FLOORPLAN = 'RD';
const LIHTC_FLOORPLAN = 'LIHTC';
const BOND_FLOORPLAN = 'BOND';

export const getAffordablePlanDetails = (floorplans: Array<Object>) => {
  return floorplans.reduce(
    (acc, floorplan) => {
      const { isHUD, isLIHTC, isRD } = getFloorPlanType(floorplan);
      if (acc.hasHUD === true && acc.hasLIHTC === true) return acc;
      if (isHUD === true) {
        acc.hasHUD = true;
      }
      if (isLIHTC === true) {
        acc.hasLIHTC = true;
      }
      if (isRD === true) {
        acc.hasRD = true;
      }
      return acc;
    },
    {
      hasHUD: false,
      hasLIHTC: false,
      hasRD: false,
    },
  );
};

export const getFloorPlanType = (floorPlan: Object) => {
  const fpAffordablePrograms = pathOr(
    [],
    ['floorPlanAffordablePrograms'],
    floorPlan,
  );
  const fpType = fpAffordablePrograms.reduce(
    (acc, program) => {
      const name = pathOr(
        '',
        ['propertyAffordableProgram', 'masterAffordableProgram', 'name'],
        program,
      );
      switch (name) {
        case HUD_FLOORPLAN: {
          acc.isHUD = true;
          break;
        }

        case RD_FLOORPLAN: {
          acc.isRD = true;
          break;
        }
        case LIHTC_FLOORPLAN:
        case BOND_FLOORPLAN: {
          acc.isLIHTC = true;
          break;
        }
        default:
          return acc;
      }
      return acc;
    },
    {
      isHUD: false,
      isLIHTC: false,
      isRD: false,
    },
  );
  return { ...fpType };
};

export const processFloorPlanDataToCSV = ({ rows, filteredHeaders }) => {
  const selectFormat = ({ variant, value }) => {
    if (!variant && value) return `="${value}"` ?? '';
    let dataTypes = {
      link: () => value ?? '',
      description: () => value ?? '',
      currency: () => formatCurrency(value),
      number: () => formatNumberToString(value) ?? '',
      string: () => value ?? '',
      null: () => value ?? '',
    };
    return dataTypes[variant] ? `"${dataTypes[variant]()}"` : '';
  };

  return rows.map((floorPlan) => {
    const csvRow = filteredHeaders.map(({ id }) => selectFormat(floorPlan[id]));
    return csvRow.join(',');
  });
};

export const parse236Rents = ({ floorPlan }) => {
  const hud236BasicRent = floorPlan?.hud236BasicRent
    ? parseAffordableRent({
        affordableFloorplanPricing: floorPlan,
        date: moment(),
        rentListName: 'fp236BasicRents',
        floorPlanRentName: 'hud236BasicRent',
      })
    : 0;

  const hud236MarketRent = floorPlan?.hud236MarketRent
    ? parseAffordableRent({
        affordableFloorplanPricing: floorPlan,
        date: moment(),
        rentListName: 'fp236MarketRents',
        floorPlanRentName: 'hud236MarketRent',
      })
    : 0;

  return {
    hud236BasicRent,
    hud236MarketRent,
  };
};
