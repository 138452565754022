import React from 'react';
import ElementWithPermissions from '../ElementWithPermissions';

const PencilButton = ({ onClick, scope = [] }: Object) => (
  <ElementWithPermissions scope={scope}>
    <a onClick={onClick}>
      <i className="icon et-pencil" />
    </a>
  </ElementWithPermissions>
);

export default PencilButton;
