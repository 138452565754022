import type { Action } from '../App/types';
import type { ResidentProfileState } from './types';
import * as ActionTypes from './constants';
import * as ProspectActionTypes from '../PeopleProfile/constants';
import * as ApplicationActionTypes from '../ApplicationProfile/constants';
import {
  GET_CUSTOMER_LEDGER_HEADER_SUCCESS,
  GET_CUSTOMER_LEDGER_HEADER_ERROR,
} from '../Ledger/constants';

export const initialState = {
  residentInfo: {
    residentCustomer: {
      customerId: '',
      customer: {
        firstName: '',
        lastName: '',
        status: null,
      },
    },
    lease: {
      fasReady: null,
      endDate: '',
      moveInDate: '',
      unit: {
        number: '',
      },
    },
    household: {
      doNotRenew: false,
      underEviction: false,
      paymentsDoNotAccept: false,
      paymentsCertifiedOnly: false,
      autoEmailMonthlyInvoices: false,
    },
  },
  applicationInfo: {
    id: '',
    applicants: [],
    vehicleCount: 0,
    affordableQualifyingChecklist: [],
  },
  applicationQualificationInfo: {
    id: '',
    applicants: [],
    vehicleCount: 0,
    affordableQualifyingChecklist: [],
  },
  prospectInfo: {},
  assignedTo: {
    id: '',
  },
  ledgerBalance: '0.00',
  subsidyBalance: '0.00',
  monthsWithLateFees: 0,
  monthsWithNSFFees: 0,
  pendingActivities: [],
  completedActivities: [],
  completedActivitiesMeta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  allActivities: [],
  allActivitiesMeta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  columnOrder: {
    status: 'sortable',
    type: 'sortable',
    startTime: 'sortable',
  },
  leaseTerms: [],
  lateMethods: [],
  securityDepositAlternatives: [],
  basicLeaseFees: [],
  nonRefundableAdminFee: 0,
  monthlyTransactions: [],
  selectedMonthlyOption: 'current',
  applicationStatuses: [],
  applicants: [],
  screening: {},
  leases: [],
  currentLeaseId: null,
  labelEndDate: null,
  affordableQualifyingChecklist: [],
  openFiscalPeriod: {},
  isFirstFiscalPeriod: false,
  applicationDecisionStatuses: [],
  applicantCancellationReasons: [],
  submittingRenewal: false,
  isCompliant: false,
  deletedApplicants: [],
  transferInformation: {},
  affordableQualifications: [],
  affordableException: {},
  affordableSetup: {},
  householdAffordableDetails: null,
  recertDueDates: [],
  firstUnitMoveInAfterPriorResident: null,
  softDeletedLeaseTerms: [],
  camPools: [],
  fpNonOptionalCharges: [],
  socketFailed: false,
};

const applicationProfileReducer = (
  state: ResidentProfileState = initialState,
  action: Action<*, *>,
): ResidentProfileState => {
  const { type, payload, meta } = action;
  const defaultColumnOrder = {
    startTime: 'sortable',
  };
  switch (type) {
    case ActionTypes.GET_ONE_RESIDENT_SUCCESS: {
      return {
        ...state,
        residentInfo: {
          ...payload.residentInfo,
          recertDueInfo: payload.recertDueInfo,
        },
        applicationInfo: {
          ...payload.applicantInfo,
          affordableQualifyingChecklist: payload.affordableQualifyingChecklist,
          prospectInfo: payload.prospectInfo,
        },
        applicationQualificationInfo: {
          ...payload.applicationQualificationInfo,
        },
        prospectInfo: payload.prospectInfo,
        monthsWithLateFees: payload.monthsWithLateFees,
        monthsWithNSFFees: payload.monthsWithNSFFees,
        assignedTo: payload.applicantInfo.assignedTo,
        leaseTerms: payload.leaseTerms,
        softDeletedLeaseTerms: payload.softDeletedLeaseTerms,
        lateMethods: payload.lateMethods,
        securityDepositAlternatives: payload.securityDepositAlternatives,
        basicLeaseFees: payload.basicLeaseFees,
        nonRefundableAdminFee: payload.nonRefundableAdminFee,
        applicants: payload.applicants,
        leases: payload.leases,
        openFiscalPeriod: payload.openFiscalPeriod,
        isFirstFiscalPeriod: payload.isFirstFiscalPeriod,
        submittingRenewal: false,
        isCompliant: payload.isCompliant,
        deletedApplicants: payload.deletedApplicants,
        affordableQualifications: payload.affordableQualifications,
        affordableException: payload.affordableException,
        affordableSetup: payload.affordableSetup,
        householdAffordableDetails: payload.householdAffordableDetails,
        recertDueDates: payload.recertDueDates,
        affordableQualificationHistories:
          payload.affordableQualificationHistories,
        fpNonOptionalCharges: payload.floorPlanNonOptionalCharges,
      };
    }
    case ActionTypes.GET_APPLICATION_DECISION_STATUS_SUCCESS: {
      return { ...state, applicationDecisionStatuses: payload };
    }
    case ActionTypes.GET_UNIT_BY_ID_SUCCESS: {
      return { ...state, unit: payload };
    }
    case ProspectActionTypes.GET_PROSPECT_PENDING_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        pendingActivities: payload,
      };
    }
    case ProspectActionTypes.GET_PROSPECT_COMPLETED_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        completedActivities: payload,
        completedActivitiesMeta: meta,
      };
    }
    case ProspectActionTypes.GET_PROSPECT_ALL_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        allActivities: payload,
        allActivitiesMeta: meta,
      };
    }
    case ProspectActionTypes.UPDATE_COLUMNS_SORT_VALUE: {
      return {
        ...state,
        columnOrder: Object.assign({}, defaultColumnOrder, payload),
      };
    }
    case ApplicationActionTypes.ASSIGN_APPLICATION: {
      if (state.residentInfo.residentCustomer.customerId !== '') {
        return {
          ...state,
          assignedTo: {
            id: payload.assignedToId,
          },
        };
      }
      return {
        ...initialState,
      };
    }
    case GET_CUSTOMER_LEDGER_HEADER_SUCCESS: {
      return {
        ...state,
        ledgerBalance: payload.balance,
        subsidyBalance: payload.subjournalsBalance.Subsidy,
      };
    }
    case GET_CUSTOMER_LEDGER_HEADER_ERROR: {
      return {
        ...state,
        ledgerBalance: '---',
      };
    }
    case ActionTypes.UPDATE_DO_NOT_RENEW_SUCCESS: {
      const stateCopy = {
        ...state,
      };
      stateCopy.residentInfo.household.doNotRenew = payload;
      return stateCopy;
    }
    case ActionTypes.UPDATE_FAS_READY_SUCCESS: {
      return {
        ...state,
        residentInfo: {
          ...state.residentInfo,
          lease: {
            ...state.residentInfo.lease,
            fasReady: payload,
          },
        },
      };
    }
    case ActionTypes.UPDATE_UNDER_EVICTION_SUCCESS: {
      const stateCopy = {
        ...state,
      };
      stateCopy.residentInfo.household.underEviction = payload;
      return stateCopy;
    }

    case ActionTypes.GET_ONE_RESIDENT_ERROR:
    case ActionTypes.MOVE_OUT_RESIDENT_SUCCESS:
    case ActionTypes.CLEAN_LOADED_RESIDENT: {
      return {
        ...initialState,
      };
    }
    case ActionTypes.GET_MONTHLY_TRANSACTIONS_RESIDENT_SUCCESS: {
      return {
        ...state,
        monthlyTransactions: payload,
      };
    }
    // case ActionTypes.GET_ALL_MONTHLY_TRANSACTIONS_RESIDENT_SUCCESS: {
    //   return {
    //     ...state,
    //     monthlyTransactions: payload,
    //   };
    // }
    case ActionTypes.SET_MONTHLY_OPTION_RESIDENT: {
      return {
        ...state,
        selectedMonthlyOption: payload,
      };
    }
    case ActionTypes.GET_APPLICATION_STATUS_SUCCESS: {
      return {
        ...state,
        applicationStatuses: payload,
      };
    }
    case ActionTypes.GET_RESIDENT_SCREENING_STATUS: {
      return {
        ...state,
        screening: {
          ...state.screening,
          submitting: true,
          error: null,
        },
      };
    }
    case ActionTypes.GET_RESIDENT_SCREENING_STATUS_SUCCESS: {
      return {
        ...state,
        screening: {
          ...state.screening,
          submitting: false,
          results: payload,
        },
      };
    }
    case ActionTypes.GET_RESIDENT_SCREENING_STATUS_ERROR: {
      return {
        ...state,
        screening: {
          ...state.screening,
          submitting: false,
          error: payload,
          results: {},
        },
      };
    }
    case ActionTypes.UPDATE_LABEL_END_DATE_RESIDENT: {
      return {
        ...state,
        labelEndDate: payload,
      };
    }
    case ActionTypes.RENEW_LEASE: {
      return {
        ...state,
        submittingRenewal: true,
      };
    }
    case ActionTypes.RENEW_LEASE_FAIL: {
      return {
        ...state,
        submittingRenewal: false,
      };
    }
    case ActionTypes.UPDATE_AUTO_EMAIL_MONTHLY_INVOICE_SUCCESS: {
      const stateCopy = {
        ...state,
      };
      stateCopy.residentInfo.household.autoEmailMonthlyInvoices = payload;
      return stateCopy;
    }
    case ActionTypes.GET_FIRST_UNIT_MOVE_IN_DATE_AFTER_PRIOR_RESIDENT_SUCCESS: {
      return {
        ...state,
        firstUnitMoveInAfterPriorResident: payload,
      };
    }
    case ActionTypes.GET_FIRST_UNIT_MOVE_IN_DATE_AFTER_PRIOR_RESIDENT_ERROR: {
      return {
        ...state,
        firstUnitMoveInAfterPriorResident: null,
      };
    }
    case ActionTypes.FETCH_CAM_POOLS_SUCCESS: {
      return { ...state, camPools: payload };
    }
    case ActionTypes.SOCKET_EVENT_FAS_ERROR: {
      return { ...state, socketFailed: true };
    }
    default:
      return state;
  }
};

export default applicationProfileReducer;
