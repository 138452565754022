import {
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_FAILURE,
  CREATE_TRANSACTION_SUCCESS,
  GET_TRANSACTION_TYPES,
  GET_TRANSACTION_TYPES_FAILURE,
  GET_TRANSACTION_TYPES_SUCCESS,
  GET_PROPERTY_TRANSACTION_CODES,
  GET_PROPERTY_TRANSACTION_CODES_FAILURE,
  GET_PROPERTY_TRANSACTION_CODES_SUCCESS,
  GET_PROPERTY_SUBJOURNAL_TYPES,
  GET_PROPERTY_SUBJOURNAL_TYPES_FAILURE,
  GET_PROPERTY_SUBJOURNAL_TYPES_SUCCESS,
  HANDLE_SOCKET_TRANSACTION_FAILURE,
  HANDLE_SOCKET_TRANSACTION_SUCCESS,
} from './constants';
import type { TransactionType, CreateTransaction } from './types';
import type { Action } from '../../containers/App/types';

export function createTransaction(
  transaction: CreateTransaction,
  residentId: ?string,
  filterReversed: boolean,
): Action<any> {
  return {
    type: CREATE_TRANSACTION,
    payload: {
      transaction,
      residentId,
      filterReversed,
    },
  };
}

export function createTransactionSuccess(customerId: string): Action<any> {
  return {
    type: CREATE_TRANSACTION_SUCCESS,
    payload: customerId,
  };
}

export function createTransactionFailure(payload: Object): Action<any> {
  return {
    type: CREATE_TRANSACTION_FAILURE,
    error: true,
    payload,
  };
}

export function getTransactionTypes(): Action<any> {
  return {
    type: GET_TRANSACTION_TYPES,
    payload: undefined,
  };
}

export function getTransactionTypesSuccess(
  payload: Array<TransactionType>,
): Action<any> {
  return {
    type: GET_TRANSACTION_TYPES_SUCCESS,
    payload,
  };
}

export function getTransactionTypesFailure(payload: Object): Action<any> {
  return {
    type: GET_TRANSACTION_TYPES_FAILURE,
    error: true,
    payload,
  };
}

export function getPropertyTransactionCodes(): Action<any> {
  return {
    type: GET_PROPERTY_TRANSACTION_CODES,
    payload: undefined,
  };
}

export function getPropertyTransactionCodesSuccess(
  payload: Object,
): Action<any> {
  return {
    type: GET_PROPERTY_TRANSACTION_CODES_SUCCESS,
    payload,
  };
}

export function getPropertyTransactionCodesFailure(
  payload: Object,
): Action<any> {
  return {
    type: GET_PROPERTY_TRANSACTION_CODES_FAILURE,
    error: true,
    payload,
  };
}

export function getPropertySubjournalTypes(): Action<any> {
  return {
    type: GET_PROPERTY_SUBJOURNAL_TYPES,
    payload: undefined,
  };
}

export function getPropertySubjournalTypesSuccess(
  payload: Object,
): Action<any> {
  return {
    type: GET_PROPERTY_SUBJOURNAL_TYPES_SUCCESS,
    payload,
  };
}

export function getPropertySubjournalTypesFailure(
  payload: Object,
): Action<any> {
  return {
    type: GET_PROPERTY_SUBJOURNAL_TYPES_FAILURE,
    error: true,
    payload,
  };
}

export function handleSocketTransactionFailure(payload: Object): Action<any> {
  return {
    type: HANDLE_SOCKET_TRANSACTION_FAILURE,
    payload,
  };
}

export function handleSocketTransactionSuccess(payload: Object): Action<any> {
  return {
    type: HANDLE_SOCKET_TRANSACTION_SUCCESS,
    payload,
  };
}
