import React, { FunctionComponent } from 'react';
import { Input } from './Input';

type Props = {
  maxWidth: string,
  value: string,
  setValue: Function,
  custom: any[],
};

export const AddressInput = ({
  maxWidth,
  value,
  setValue,
  ...custom
}: Props): FunctionComponent<Props> => (
  <Input
    maxWidth={maxWidth}
    display="inline"
    margin="0 0.375%"
    value={value}
    setValue={setValue}
    {...custom}
  />
);
