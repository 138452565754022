import React, { useState } from 'react';
import { Stack } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import type { UserPermission } from '../../App/types';
import { promptToaster } from '../../App/actions';
import SetAsidePrograms from './SetAsidePrograms';
import SetAsideSummary from './SetAsideSummary';

type Props = {
  intl: Object,
  organizationId: string,
  propertyId: string,
  promptToaster: Function,
  userPermissions: Array<UserPermission>,
  actions: Object,
};

const PropertyComplianceTab = ({
  intl,
  propertyId,
  organizationId,
  actions: { promptToaster },
  userPermissions,
}: Props) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const refreshHandler = {
    shouldRefresh,
    setShouldRefresh,
  };
  return (
    <Stack id={'propertyComplianceTab'} direction={'column'} spacing={2}>
      <SetAsideSummary
        intl={intl}
        organizationId={organizationId}
        propertyId={propertyId}
        promptToaster={promptToaster}
        userPermissions={userPermissions}
        refreshHandler={refreshHandler}
      />
      <SetAsidePrograms
        intl={intl}
        organizationId={organizationId}
        propertyId={propertyId}
        promptToaster={promptToaster}
        userPermissions={userPermissions}
        refreshHandler={refreshHandler}
      />
    </Stack>
  );
};

const InjectedPropertyComplianceTab = injectIntl(PropertyComplianceTab);

export const mapStateToProps = ({
  app: { currentUser, selectedProperty },
}: any): any => {
  return {
    organizationId: currentUser.user.organizationId,
    userPermissions: currentUser.permissions,
    propertyId: selectedProperty.id,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  actions: bindActionCreators(
    {
      promptToaster,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedPropertyComplianceTab);
