import React, { useMemo } from 'react';
import { Table } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import type { SetAsideSummaryComponentProps } from '../types';
import { calculateTotals } from './totalsRow';
import setAsideSummaryTableMessages from './messages';
import setAsideSummaryMessages from '../messages';
import {
  Box,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

const messages = {
  ...setAsideSummaryTableMessages,
  ...setAsideSummaryMessages,
};
const headerSX = {
  backgroundColor: 'background.paper',
  color: 'text.primary',
};
const headerInnerSx = {
  justifyContent: 'flex-end',
};
const SetAsideSummaryTable = ({
  intl,
  kpi,
  contentHeight,
  setAsideKpiLeasedUnits,
}: SetAsideSummaryComponentProps) => {
  const headers = [
    {
      id: 'color',
      label: '',
      sx: headerSX,
      innerSx: headerInnerSx,
    },
    {
      id: 'setAside',
      label: intl.formatMessage({ ...messages.headerSetAside }),
      sx: headerSX,
      innerSx: headerInnerSx,
    },
    {
      id: 'requiredUnits',
      label: intl.formatMessage({ ...messages.headerRequired }),
      sx: headerSX,
      innerSx: headerInnerSx,
    },
    {
      id: 'actualUnits',
      label: intl.formatMessage({ ...messages.headerActual }),
      sx: headerSX,
      innerSx: headerInnerSx,
    },
    ...(setAsideKpiLeasedUnits
      ? [
          {
            id: 'leasedUnits',
            label: intl.formatMessage({ ...messages.headerLeased }),
            sx: headerSX,
            innerSx: headerInnerSx,
            info: (
              <>
                <Typography variant="body1">
                  {intl.formatMessage({ ...messages.tooltipPreLeasedUnit })}{' '}
                  <b>{intl.formatMessage({ ...messages.leasedUnit })}</b>{' '}
                  {intl.formatMessage({ ...messages.tooltipPostLeasedUnit })}:
                </Typography>
                <ul style={{ paddingLeft: 24 }}>
                  <Typography variant="body1" component={'li'}>
                    {intl.formatMessage({ ...messages.tooltipFirstItem })}
                  </Typography>
                  <Typography variant="body1" component={'li'}>
                    {intl.formatMessage({ ...messages.tooltipSecondItem })}
                  </Typography>
                </ul>
              </>
            ),
          },
        ]
      : []),
  ];
  const rows = useMemo(() => {
    if (!kpi) return [];
    return kpi.map((data) => {
      return {
        color: {
          variant: 'div',
          sx: {
            margin: 2,
            backgroundColor: data?.backgroundColor ?? 'black',
            borderRadius: '50%',
            width: 16,
            height: 16,
          },
        },
        setAside: { value: Number(data.setAside), variant: 'number' },
        requiredUnits: { value: data.numRequiredUnits, variant: 'number' },
        actualUnits: {
          value: data.numActualUnits,
          variant: 'number',
          sx: { color: 'warning.dark' },
        },
        ...(setAsideKpiLeasedUnits
          ? {
              leasedUnits: { value: data.numLeasedUnits, variant: 'number' },
            }
          : {}),
      };
    });
  }, [kpi, setAsideKpiLeasedUnits]);
  const totals = useMemo(() => {
    const totals = calculateTotals(kpi);
    return {
      color: {
        value: 'Total:',
      },
      setAside: {
        value: ' ',
      },
      requiredUnits: { value: totals.required, variant: 'number' },
      actualUnits: { value: totals.actual, variant: 'number' },
      ...(setAsideKpiLeasedUnits
        ? {
            leasedUnits: { value: totals.leased, variant: 'number' },
          }
        : {}),
    };
  }, [kpi, setAsideKpiLeasedUnits]);

  return (
    <Box sx={{ width: { md: '50%' } }}>
      <Typography variant={'formSubheading'} display={'block'} marginBottom={1}>
        {intl.formatMessage({ ...messages.title })}
      </Typography>
      <Table
        name={'set-aside-summary-table'}
        headers={headers}
        rows={rows}
        footerRow={totals}
        height={contentHeight}
        headerSx={{ backgroundColor: 'background.paper' }}
        showSettings={false}
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: 1,
        }}
        count={rows.length}
        totalCount={rows.length}
      />
    </Box>
  );
};

export default SetAsideSummaryTable;
