import type { Action } from '../App/types';
import type { ManageApplicationsState } from './types';
import * as ActionTypes from './constants';

const initialState: ManageApplicationsState = {
  meta: {
    count: 0,
    pageCount: 0,
    totalCount: 0,
    next: '',
    previous: '',
    self: '',
    first: '',
    last: '',
  },
  applications: [],
  columnOrder: {
    applicationCode: 'sortable',
    applicationStatusId: 'sortable',
    unitApp: 'sortable',
    assignedToId: 'sortable',
  },
  currentSorting: {
    fieldName: '',
    order: '',
  },
  statuses: [],
  isLoading: false,
  isInitialState: true,
};

const manageApplicationsReducer = (
  state: ManageApplicationsState = initialState,
  action: Action<*, *>,
): ManageApplicationsState => {
  const { type, payload, meta } = action;
  switch (type) {
    case ActionTypes.GET_ALL_APPLICATIONS: {
      return {
        ...state,
        currentSorting: payload.sorting,
        isLoading: true,
      };
    }
    case ActionTypes.GET_ALL_APPLICATIONS_SUCCESS: {
      return {
        ...state,
        applications: payload,
        meta: meta,
        isLoading: false,
        isInitialState: false,
      };
    }
    case ActionTypes.GET_ALL_APPLICATION_STATUSES_SUCCESS: {
      return { ...state, statuses: payload };
    }
    case ActionTypes.UPDATE_COLUMNS_SORT_VALUE: {
      return {
        ...state,
        columnOrder: {
          ...initialState.columnOrder,
          ...payload,
        },
      };
    }
    case ActionTypes.CLEAN_LOADED_APPLICATIONS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default manageApplicationsReducer;
