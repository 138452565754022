import React from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import messages from './messages';
import type { Option } from './types';
import { ReadOnlyField } from './Common';
import * as constants from './constants';
import {
  renderSelectFieldTwoColumns,
  renderSelectField,
  renderTextAreaTwoColumns,
} from '../../../../utils/redux-form-helper';
import { pathOr, head } from 'ramda';
import moment from 'moment';

type FormLeftColumnProps = {
  intl: Object,
  leaseTypeOptions: Option[],
  lateMethodOptions: Option[],
  customer: Object,
  isEditable: boolean,
  onLeaseEdit: Function,
  unit: Object,
  commencementDate: Date,
};
const calcRentableSquareFeet = (unitRentableSqFtHistory, commencementDate) => {
  const earliestValue =
    unitRentableSqFtHistory.length && moment(commencementDate).isValid()
      ? head(unitRentableSqFtHistory)
      : null;
  if (!earliestValue) {
    return null;
  }
  const initialRentableSqft = earliestValue.squareFeet;
  const rentableSqft = unitRentableSqFtHistory.reduce(
    (currentRentableSqFt: number, history: Object) => {
      if (moment(commencementDate).isSameOrAfter(history.startDate)) {
        return history.squareFeet;
      }
      return currentRentableSqFt;
    },
    initialRentableSqft,
  );
  return rentableSqft;
};

function FormLeftColumn({
  intl,
  lateMethodOptions,
  leaseTypeOptions,
  customer,
  isEditable,
  onLeaseEdit,
  unit,
  commencementDate,
}: FormLeftColumnProps) {
  const showPencil = !isEditable;
  const unitRentableSqFtHistory = pathOr(
    [],
    ['unitRentableSquareFeetHistory'],
    unit,
  );
  const rentableSqFt = calcRentableSquareFeet(
    unitRentableSqFtHistory,
    commencementDate,
  );
  return (
    <Col xs={12} lg={6}>
      <Row>
        <Col xs={12}>
          <Field
            name="leaseTypeId"
            options={leaseTypeOptions || []}
            component={renderSelectFieldTwoColumns}
            col1={[4, 12]}
            col2={[8, 12]}
            label={intl.formatMessage(messages.leaseTypeField)}
            disabled={!isEditable}
          />
        </Col>
      </Row>
      <Row>
        <ReadOnlyField
          label={intl.formatMessage(messages.tenantLegalName)}
          value={customer.firstName}
        />
      </Row>
      <Row>
        <ReadOnlyField
          label={intl.formatMessage(messages.dba)}
          value={customer.preferredName}
        />
      </Row>
      <Row>
        <ReadOnlyField
          label={intl.formatMessage(messages.parentCompany)}
          value={customer.parentCompany}
        />
      </Row>
      <Row>
        <ReadOnlyField
          label={intl.formatMessage(messages.rentableSquareFeet)}
          value={rentableSqFt}
        />
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            name="lateMethodId"
            options={lateMethodOptions || []}
            component={renderSelectField}
            label={intl.formatMessage(messages.lateMethodField)}
            disabled={!isEditable}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Field
            name="overallLeaseComments"
            label={intl.formatMessage(messages.overallLeaseCommentsField)}
            placeholder={intl.formatMessage(
              messages.overallLeaseCommentsPlaceholder,
            )}
            component={renderTextAreaTwoColumns}
            col1={[showPencil ? 3 : 4, showPencil ? 1 : 12]}
            col2={[8, 12]}
            pencilCol={[1, 1]}
            disabled={!isEditable}
            showPencil={showPencil}
            onPencilClick={onLeaseEdit}
            pencilScope={constants.EDIT_LEASE_SCOPE}
          />
        </Col>
      </Row>
    </Col>
  );
}

const selector = formValueSelector(constants.FORM_NAME);

const mapStateToProps = (state): any => ({
  commencementDate: selector(state, 'commencementDate'),
});

export default connect(mapStateToProps)(FormLeftColumn);
