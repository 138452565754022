import { isEmpty, isNil } from 'ramda';
import messages from './messages';
import {
  INCOME_VALUE_FIELDS,
  INCOME_ROW_NAMES,
  PAYRATE_VALUE_FIELDS,
  HOURSWEEKSMONTHS_VALUE_FIELDS,
} from './constants';

const checkEmptyNil = (value: string) => {
  return isEmpty(value) || isNil(value);
};

export const validate = (values: Object, { intl }: Object) => {
  const errors = {};

  INCOME_ROW_NAMES.forEach((row) => {
    if (
      values[row] === 'yes' &&
      INCOME_VALUE_FIELDS.includes(`${row}Income`) &&
      (!values[`${row}Income`] || +values[`${row}Income`] === 0)
    ) {
      errors[`${row}Income`] = intl.formatMessage(messages.amountRequired);
    }
    if (values[`${row}Income`] < 0) {
      errors[`${row}Income`] = intl.formatMessage(messages.mustBePositive);
    }
  });

  // Validate payDataColumns
  INCOME_ROW_NAMES.forEach((row) => {
    if (
      values[row] === 'yes' &&
      PAYRATE_VALUE_FIELDS.includes(`${row}PayRate`) &&
      (!values[`${row}PayRate`] || +values[`${row}PayRate`] === 0)
    ) {
      errors[`${row}PayRate`] = intl.formatMessage(messages.amountRequired);
    }
    if (values[`${row}PayRate`] < 0) {
      errors[`${row}PayRate`] = intl.formatMessage(messages.mustBePositive);
    }
    if (
      values[row] === 'yes' &&
      HOURSWEEKSMONTHS_VALUE_FIELDS.includes(`${row}HoursWeeksMonths`) &&
      (!values[`${row}HoursWeeksMonths`] ||
        +values[`${row}HoursWeeksMonths`] === 0)
    ) {
      errors[`${row}HoursWeeksMonths`] = intl.formatMessage(
        messages.amountRequired,
      );
    }
    if (values[`${row}HoursWeeksMonths`] < 0) {
      errors[`${row}HoursWeeksMonths`] = intl.formatMessage(
        messages.mustBePositive,
      );
    }
  });

  if (
    values.collectChildSupport === 'yes' &&
    checkEmptyNil(values.collectChildSupportCounty)
  ) {
    errors.collectChildSupportCounty = intl.formatMessage(
      messages.stateAndCountyRequired,
    );
  }

  if (
    values.anticipateChildSupport === 'yes' &&
    checkEmptyNil(values.anticipateChildSupportCounty)
  ) {
    errors.anticipateChildSupportCounty = intl.formatMessage(
      messages.stateAndCountyRequired,
    );
  }

  if (values.other === 'yes' && checkEmptyNil(values.otherIncomeName)) {
    errors.otherIncomeName = intl.formatMessage(messages.sourceRequired);
  }

  if (
    values.publicHousing === 'yes' &&
    checkEmptyNil(values.publicHousingText)
  ) {
    errors.publicHousingText = intl.formatMessage(
      messages.publicHousingAuthorityRequired,
    );
  }

  return errors;
};

export default validate;
