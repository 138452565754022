type ValidationResult = {
  isValid: boolean,
};

function validateRunByDate(formValues: Object): ValidationResult {
  const { startDate, endDate } = formValues;

  return { isValid: startDate && endDate };
}

function validateRunByPeriod(formValues: Object): ValidationResult {
  const { period } = formValues;

  return { isValid: period && period !== 'default' };
}

function validateRunByOneDate(formValues: Object): ValidationResult {
  const { date } = formValues;

  return { isValid: Boolean(date) };
}

function validateRunByPeriodRange(formValues: Object): ValidationResult {
  const { startPeriod, endPeriod } = formValues;

  return { isValid: startPeriod && endPeriod };
}

function validateRunByScheduledAndActualPeriod(
  formValues: Object,
): ValidationResult {
  const { scheduledPeriod, actualPeriod } = formValues;

  return { isValid: scheduledPeriod && actualPeriod };
}

function validateRunByCurrentAndFuturePeriod(
  formValues: Object,
): ValidationResult {
  const { futurePeriod } = formValues;

  return { isValid: Boolean(futurePeriod) };
}

const ValidationsMap = {
  runByPeriod: { validateFn: validateRunByPeriod, typeRange: 'period' },
  runByDate: { validateFn: validateRunByDate, typeRange: 'range' },
  runByOneDate: { validateFn: validateRunByOneDate, typeRange: 'date' },
  runByPeriodRange: {
    validateFn: validateRunByPeriodRange,
    typeRange: 'periodRange',
  },
  runByScheduledAndActualPeriod: {
    validateFn: validateRunByScheduledAndActualPeriod,
    typeRange: 'scheduledAndActualPeriodRange',
  },
  runByCurrentAndFuturePeriod: {
    validateFn: validateRunByCurrentAndFuturePeriod,
    typeRange: 'currentAndFuturePeriodRange',
  },
};

export function validateForm(report: Object, formValues: Object): boolean {
  let hasValidations = false;
  const validationResult = Object.entries(ValidationsMap).reduce(
    (prev, [runBy, { validateFn, typeRange }], index) => {
      if (prev.isValid) return prev;

      if (report[runBy] && formValues.typeRange === typeRange) {
        hasValidations = true;
        const result = validateFn(formValues);
        return result;
      }

      return prev;
    },
    { isValid: false },
  );

  return (
    formValues.format !== 'default' &&
    (validationResult.isValid || !hasValidations)
  );
}
