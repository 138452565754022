import { defineMessages } from 'react-intl';

const messages = defineMessages({
  minorsHowMany:{
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Minors.HowMany',
    defaultMessage: 'How Many?'
  },
  other: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Minors.Other',
    defaultMessage: 'Minor(s) - under 18'
  },
  addDetail: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Minors.AddDetail',
    defaultMessage: '+ Add minor detail'
  },
  firstName: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Minors.FirstName',
    defaultMessage: 'First'
  },
  middleName: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Minors.MiddleName',
    defaultMessage: 'Middle'
  },
  lastName: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Minors.LastName',
    defaultMessage: 'Last'
  },
  suffix: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Minors.SuffixName',
    defaultMessage: 'Suffix'
  },
  preferredName: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Minors.PreferredName',
    defaultMessage: 'Preferred Name'
  },
  age: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Minors.Age',
    defaultMessage: 'Age'
  },
  delete: {
    id: 'App.ProspectProfile.ProspectProfileForm.AdditionalOccupants.Minors.Delete',
    defaultMessage: 'Delete'
  },
});

export default messages;