import moment from 'moment';
import { validateDate, validateRequired } from '../../../utils/validations';

const MAX_LENGTH_UNIT_NUMBER = 50;
const MAX_LENGTH_LEASEHOLDER = 100;

const validate = (values: Object, props: Object) => {
  const errors = {};
  const { moveDate, unitNumber, leaseholderName } = values;

  // Required Fields
  ['moveDate', 'moveInMoveOut'].forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      errors[fieldName] = 'This field is required';
    }
  });

  const mMoveDate = moment(moveDate);

  // Valid Dates
  if (!errors['moveDate']) {
    if (!validateDate(mMoveDate)) {
      errors['moveDate'] = 'Please enter a valid date';
    }
  }

  // Length of unit number
  if (!errors['unitNumber'] && unitNumber?.length > MAX_LENGTH_UNIT_NUMBER) {
    errors[
      'unitNumber'
    ] = `Must be ${MAX_LENGTH_UNIT_NUMBER} characters or less`;
  }

  // Length of leaseholder name
  if (
    !errors['leaseholderName'] &&
    leaseholderName?.length > MAX_LENGTH_LEASEHOLDER
  ) {
    errors[
      'leaseholderName'
    ] = `Must be ${MAX_LENGTH_LEASEHOLDER} characters or less`;
  }

  return errors;
};

export default validate;
