export const calculateSumOfEachField = (arrayOfObjects: Array<Object>) => {
  return arrayOfObjects.reduce((acc, curr) => {
    Object.keys(curr).forEach((rowKey) => {
      const value = curr[rowKey];
      if (!acc[rowKey]) {
        acc[rowKey] = 0;
      }
      acc[rowKey] += value;
    });

    return acc;
  }, {});
};

export const mapValuesByField = (arrayOfObjects: Array<Object>) => {
  return arrayOfObjects.reduce((acc, curr) => {
    Object.keys(curr).forEach((c) => {
      const value = curr[c];
      if (!acc[c]) {
        acc[c] = [];
      }
      acc[c].push(value);
    });
    return acc;
  }, {});
};

export const weightedAverage = (
  nums: Array<number> = [],
  weights: Array<number> = [],
) => {
  const [sum, weightSum] = weights.reduce(
    (acc, w, i) => {
      acc[0] += nums[i] * w;
      acc[1] += w;
      return acc;
    },
    [0, 0],
  );
  return sum / weightSum;
};
