import { useMemo } from 'react';
import { dateDiffInDays } from '../../utils/date-helpers';
import messages from './messages';
import * as utils from './utils';

export const useDateInfo = (assignedUnits) => {
  return useMemo(() => {
    const today = new Date();
    const expiringUnits = Array.isArray(assignedUnits) ? assignedUnits : [];
    const monthToMonth = expiringUnits.filter(
      ({ lease }) => dateDiffInDays(today, new Date(lease.endDate)) < 0,
    ).length;
    const under30 = expiringUnits.filter(
      ({ lease }) =>
        dateDiffInDays(today, new Date(lease.endDate)) >= 0 &&
        dateDiffInDays(today, new Date(lease.endDate)) <= 30,
    ).length;
    const between30and59 = expiringUnits.filter(
      ({ lease }) =>
        dateDiffInDays(today, new Date(lease.endDate)) > 30 &&
        dateDiffInDays(today, new Date(lease.endDate)) <= 60,
    ).length;
    const between60and90 = expiringUnits.filter(
      ({ lease }) =>
        dateDiffInDays(today, new Date(lease.endDate)) > 60 &&
        dateDiffInDays(today, new Date(lease.endDate)) <= 90,
    ).length;
    const between90and120 = expiringUnits.filter(
      ({ lease }) =>
        dateDiffInDays(today, new Date(lease.endDate)) > 90 &&
        dateDiffInDays(today, new Date(lease.endDate)) <= 120,
    ).length;
    const over120 = expiringUnits.filter(
      ({ lease }) => dateDiffInDays(today, new Date(lease.endDate)) > 120,
    ).length;

    return [
      { title: messages.month, count: monthToMonth },
      {
        title: messages.expiringText,
        subtitle: messages.under30,
        count: under30,
      },
      {
        title: messages.expiringText,
        subtitle: messages.between31and60,
        count: between30and59,
      },
      {
        title: messages.expiringText,
        subtitle: messages.between61and90,
        count: between60and90,
      },
      {
        title: messages.expiringText,
        subtitle: messages.between90and120,
        count: between90and120,
      },
      {
        title: messages.expiringText,
        subtitle: messages.over120,
        count: over120,
      },
    ];
  }, [assignedUnits]);
};

export const useAssignedUnitData = (assignedUnit) => {
  return useMemo(() => {
    const { id, lease, unit, lastLease, unitId } = assignedUnit;

    const { isRenewal = false, isRenewalComplete = false } = lastLease || {};

    const {
      id: leaseId,
      household: { doNotRenew = '', underEviction = '' },
      resident = [],
      startDate: leaseStartDate = null,
    } = lease;

    const {
      unitStatus,
      number,
      floorPlan: { id: floorPlanId, internalName, quotingRentAmount },
    } = unit;
    const { floorPlan } = unit;

    const primaryResident = utils.getPrimaryResident(resident);

    if (!primaryResident?.residentCustomer?.customer?.pp) {
      primaryResident.residentCustomer.customer.pp = { atv: [] };
    }

    const {
      id: primaryResidentId = null,
      residentCustomer: {
        customer: {
          firstName = '',
          lastName = '',
          opLedger: [{ allSubjournalRunningBalance: balance = '' }],
          pp: { atv: renewalNotes = [] } = { atv: [] },
        },
      },
    } = primaryResident;

    const selectedLease = {
      unit: { id, number },
      residentName: `${firstName} ${lastName}`,
      leaseId,
    };
    const statusName = utils.getCsvStatus({ isRenewal, isRenewalComplete });
    const latestRenewalNote =
      +renewalNotes?.length > 0
        ? utils.getLatestRenewalNote(renewalNotes, leaseStartDate)
        : {
            notes: null,
            by: { firstName: null },
            cs: { isCompleted: false },
            startTime: null,
          };
    const {
      notes,
      cs: { isCompleted: isRenewalNoteComplete },
      by: { firstName: assignedTo },
      startTime: renewalNoteDate,
    } = latestRenewalNote;

    const disableNote = !latestRenewalNote || !isRenewalNoteComplete;

    return {
      assignedTo,
      balance,
      baseMarketRentAmount: floorPlan.marketRent,
      disableNote,
      doNotRenew,
      firstName,
      floorPlanId,
      internalName,
      isRenewal,
      isRenewalComplete,
      isRenewalNoteComplete,
      lastName,
      lease,
      notes,
      primaryResident,
      primaryResidentId,
      quotingRentAmount,
      renewalNoteDate,
      selectedLease,
      statusName,
      underEviction,
      unitId,
      unitStatus,
    };
  }, [assignedUnit]);
};
