import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import ElementWithPermissions from '../../../components/ElementWithPermissions';

type Props = {
  totalPayments: number,
  disableRejectAcceptBtn: boolean,
};

const ManageOnlinePaymentsHeader = (props: Props) => {
  const { totalPayments, disableRejectAcceptBtn } = props;
  return (
    <div className="container-fluid">
      <Row>
        <Col xs={6} lg={8}>
          <h1>
            <FormattedMessage {...messages.header} />
          </h1>
          <p>
            <FormattedMessage {...messages.totalPayments} />{' '}
            <span className="text-blue">{totalPayments}</span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={6} lg={4} className="float-right padbottom10">
          <div className="float-right">
            <ElementWithPermissions
              scope={['manage-online-payments-accept-reject-void']}
            >
              <Button
                variant="shoutSubtle"
                onClick={() => {
                  // TODO
                }}
                sx={{ mr: 2 }}
                disabled={disableRejectAcceptBtn}
              >
                <FormattedMessage {...messages.reject} />
              </Button>
              <Button
                variant="shout"
                onClick={() => {
                  // TODO
                }}
                disabled={disableRejectAcceptBtn}
              >
                <FormattedMessage {...messages.accept} />
              </Button>
            </ElementWithPermissions>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ManageOnlinePaymentsHeader;
