import { useCallback } from 'react';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils/index';
import { exportAndDownloadCSV } from '../../utils/csv-helpers';

const useCSVExport = ({ filteredHeaders, rows, hasAnyFilters }) => {
  const onCSVButtonClick = useCallback(() => {
    const fileName = `Manage-Unit-Availability-${formatDateDB(new Date())}`;

    exportAndDownloadCSV({
      excludedHeaders: ['actions'],
      filteredHeaders,
      fileName,
      rows,
      hasAnyFilters,
    });
  }, [filteredHeaders, rows, hasAnyFilters]);

  return {
    onCSVButtonClick,
  };
};

export default useCSVExport;
