import {
  validateRequired,
  validatePositiveMoney,
  validateDate,
} from '../../../utils/validations';

const validate = (values: Object, props: Object) => {
  const { showUaTypeAndEffectiveDate, isCurrentSuggestedDeposit } = props;
  const { feeAmount } = values;
  const errors = {};
  [
    'feeAmount',
    'startDate',
    ...(showUaTypeAndEffectiveDate
      ? ['implementationDate', 'utilityAllowanceTypeIds']
      : []),
  ].forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      errors[fieldName] = 'This field is required.';
    }
  });
  [
    'startDate',
    ...(showUaTypeAndEffectiveDate ? ['implementationDate'] : []),
  ].forEach((fieldName) => {
    if (fieldName && !validateDate(values[fieldName])) {
      errors[fieldName] = 'Please enter a valid date';
    }
  });
  const minFee = isCurrentSuggestedDeposit ? -1 : 0;
  ['feeAmount'].forEach((fieldName) => {
    if (
      !validatePositiveMoney(feeAmount) ||
      parseFloat(feeAmount) <= minFee ||
      parseFloat(feeAmount) > 9999.99
    ) {
      errors[fieldName] = 'Please enter a valid amount';
    }
  });

  return errors;
};

export default validate;
