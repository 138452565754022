import React from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import messages from './messages';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import SubmitButton from '../../../components/SubmitButton';
import { PERMISSION_CREATE_TX_BATCH } from '../constants';
import { renderSelectField } from '../../../utils/redux-form-helper';
import { useGetAllFiscalPeriods } from './hooks';
import { generateFiscalPeriodsOption } from './utils';

type Props = {
  onCreateNewBatchClicked: Function,
  organizationId: string,
  propertyId: string,
};

const ManageTransactionBatchesHeader = (props: Props) => {
  const { onCreateNewBatchClicked, organizationId, propertyId } = props;
  const [fiscalPeriods, fiscalPeriodsLoading] = useGetAllFiscalPeriods({
    propertyId,
    organizationId,
  });
  const fiscalPeriodsOption = generateFiscalPeriodsOption(
    fiscalPeriods,
    fiscalPeriodsLoading,
  );

  return (
    <div className="container-fluid">
      <form>
        <Row>
          <Col xs={12} md={6}>
            <h1 className="padtop30">
              <FormattedMessage {...messages.header} />
            </h1>
          </Col>
          <Col xs={12} md={6}>
            <div className="pull-right">
              <div className="padright10" style={{ display: 'inline-block' }}>
                <Field
                  name="filterBy"
                  selectClassNames="input-md form-control"
                  component={renderSelectField}
                  label="Filter By Period"
                  options={fiscalPeriodsOption}
                  style={{ width: '200px' }}
                  bsSize="lg"
                />
              </div>
              <div style={{ display: 'inline-block' }}>
                <ElementWithPermissions scope={[PERMISSION_CREATE_TX_BATCH]}>
                  <SubmitButton
                    type="button"
                    bsStyle="warning"
                    href="#"
                    clickHandler={onCreateNewBatchClicked}
                    isSubmitting={false}
                  >
                    <FormattedMessage {...messages.createNewBatchButtonLabel} />
                  </SubmitButton>
                </ElementWithPermissions>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'ManageTransactionBatchesHeaderForm',
  enableReinitialize: true,
})(ManageTransactionBatchesHeader);
