import React, { useState, useMemo, useEffect } from 'react';
import messages from './messages';
import { FormattedMessage } from 'react-intl';
import { getFormValues, reduxForm, isPristine, isValid } from 'redux-form';
import { connect } from 'react-redux';
import { isNil } from 'ramda';

import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';

import AddInlineRow from './AddInlineRow';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import validate from './validate';
import TransactionBatchDetailsTableFooter from './transactionBatchDetailsTableFooter';
import Spinner from '../../../components/Spinner';
import {
  getCurrentResidentsOptions,
  getStartingResidentsBalances,
  getSubjournalsOptions,
  getPropertyTransactionCodesOptions,
} from './utils';
import { DEFAULT_ROW_ID } from './constants';
import { transactionCodesProvider } from '../../../utils/transaction-helpers';
import ConfirmPostBatchModal from '../ConfirmPostBatchModal';

const StyledForm = styled.form`
  .form-group {
    margin-bottom: 0;
  }
`;

type Props = {
  formValues: Object,
  headerFormValues: Object,
  subjournals: Object,
  propertyTransactionCodes: Object,
  currentResidents: Object,
  transactionTypes: Object,
  isLoading: boolean,
  change: Function,
  valid: boolean,
  onSaveAndClose: Function,
  onDelete: Function,
  onPost: Function,
  initialValues: Object,
  transactionBatch: Object,
  showDeleteBtn: boolean,
  enableDeleteBtn: boolean,
  isBatchEditable: boolean,
  isHeaderFormPristine: boolean,
  isHeaderFormValid: boolean,
  pristine: boolean,
  transactionBatchHeaderId: string,
  isAllCommercial: boolean,
  deletedTxRowsIds: Array<string>,
  setDeletedTxRowsIds: Function,
  onSaveAndCloseClicked: Function,
};

export const CreateTransactionBatchDetails = (props: Props) => {
  const {
    subjournals,
    propertyTransactionCodes,
    currentResidents,
    transactionTypes,
    isLoading,
    change,
    formValues,
    headerFormValues,
    valid,
    onDelete,
    onPost,
    pristine,
    initialValues,
    transactionBatch,
    showDeleteBtn,
    enableDeleteBtn,
    transactionBatchHeaderId,
    isBatchEditable,
    isHeaderFormPristine,
    isHeaderFormValid,
    isAllCommercial,
    deletedTxRowsIds,
    setDeletedTxRowsIds,
    onSaveAndCloseClicked,
  } = props;

  const [showConfirmPostBatchModal, setShowConfirmPostBatchModal] =
    useState(false);

  const initalInlineRows = Object.values(initialValues || []).map((row, i) => {
    const newInlineRow = {
      id: row.id,
      key: i,
      balance: 0,
    };
    return newInlineRow;
  });

  const [counter, setCounter] = useState(0);
  const [inlineRowsArray, setInlineRowsArray] = useState([
    {
      id: `${DEFAULT_ROW_ID}-${counter}`,
      key: counter,
      balance: 0,
    },
  ]);

  const [isInitialLoad, setIsInitialLoad] = useState(false);
  useEffect(() => {
    if (!isLoading && !isInitialLoad && initalInlineRows.length) {
      setInlineRowsArray(initalInlineRows);
      setCounter(initalInlineRows.length);
      setIsInitialLoad(true);
    }
  }, [isInitialLoad, initalInlineRows, isLoading]);

  const startingBalances = useMemo(
    () => getStartingResidentsBalances(currentResidents),
    [currentResidents],
  );

  const infoPopover = (
    <Popover id={'popover-positioned-bottom'}>
      {isAllCommercial ? (
        <FormattedMessage {...messages.tooltipTenant} />
      ) : (
        <FormattedMessage {...messages.tooltipResident} />
      )}
    </Popover>
  );
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errRowId, setErrRowId] = useState([]);
  const deleteRow = (id: string) => {
    const newArray = inlineRowsArray.filter((row) => row.id !== id);
    setInlineRowsArray(newArray);
    if (errRowId.find((rowId: string) => rowId === id)) {
      const newErrRowIds = errRowId.filter((rowId) => rowId !== id);
      setErrRowId(newErrRowIds);
      if (!newErrRowIds.length) {
        setShowErrorMsg(false);
      }
    }
    if (!id.includes(`${DEFAULT_ROW_ID}`)) {
      setDeletedTxRowsIds([...deletedTxRowsIds, id]);
    }
    change(`inline${id}`, null);
  };

  const addRow = () => {
    const newRow = {
      id: `${DEFAULT_ROW_ID}-${counter + 1}`,
      key: counter + 1,
      balance: 0,
    };
    setCounter(counter + 1);
    setInlineRowsArray([...inlineRowsArray, newRow]);
  };

  const disableDeleteRows = inlineRowsArray.length === 1;

  const currentResidentsOptions = getCurrentResidentsOptions(currentResidents);
  const summaryRow = {
    totalBatchAmount: (0).toFixed(2),
    totalHouseholds: [],
    totalTransactions: 0,
  };

  // filter resident transaction codes
  const residentTransactionCodes = transactionCodesProvider(
    'Resident',
    propertyTransactionCodes,
  );

  const getRowsWithBalances = () => {
    const rv = inlineRowsArray.reduce(
      (acc, row) => {
        const tx = transactionBatch?.transactions.filter(
          (tx) => tx.id === row.id,
        );
        const emptyOption = [{ value: '', text: 'Choose', disabled: true }];
        const rowFormValues = formValues?.[`inline${row.id}`];
        if (!rowFormValues) {
          const newRowValue = {
            ...row,
            balance: null,
            newBalance: null,
            initialSubjournalOptions: emptyOption,
            initialTransactionCodeOptions: emptyOption,
          };
          return {
            allRows: [...acc.allRows, newRowValue],
            balances: acc.balances,
          };
        }

        const customerId = rowFormValues.customer;
        if (!customerId) {
          const newRowValue = {
            ...row,
            balance: null,
            newBalance: null,
            initialSubjournalOptions: emptyOption,
            initialTransactionCodeOptions: emptyOption,
          };
          return {
            allRows: [...acc.allRows, newRowValue],
            balances: acc.balances,
          };
        }

        const currentBalance = acc?.balances?.[customerId] ?? 0;
        const rowAmountStr = rowFormValues?.amount;
        const rowAmount = +(rowAmountStr ?? 0);
        const rowTransactionTypeId = rowFormValues.transactionType;
        const rowTransactionTypeObj = isNil(rowTransactionTypeId)
          ? null
          : transactionTypes.find((t) => t.id === rowTransactionTypeId);
        const rowTransactionType = rowTransactionTypeObj?.name ?? null;
        const addedAmount =
          isNil(rowAmount) || isNil(rowTransactionType)
            ? 0
            : rowTransactionType === 'Credit'
            ? -1 * rowAmount
            : rowAmount;
        const finalRowBalance = currentBalance + addedAmount;
        summaryRow.totalBatchAmount = (
          +summaryRow.totalBatchAmount + +addedAmount
        ).toFixed(2);
        if (!summaryRow.totalHouseholds.includes(customerId)) {
          summaryRow.totalHouseholds.push(customerId);
        }
        summaryRow.totalTransactions++;

        const availableCodes = residentTransactionCodes.filter(
          (code) =>
            code.transactionCode.transactionTypeId === rowTransactionTypeId,
        );
        const availableSubjournals = availableCodes.map(
          (code) => code.transactionCode.subjournalId,
        );
        const subjournalOptions = getSubjournalsOptions(
          subjournals.filter((subjournal) =>
            availableSubjournals.find(
              (e) => e === subjournal.masterSubjournalId,
            ),
          ),
        );

        const initialSubjournalOptions =
          !rowTransactionTypeId || !rowTransactionTypeId.length
            ? [{ value: '', text: 'Choose', disabled: true }]
            : subjournalOptions;

        const selectedType = transactionTypes.find((type) => {
          return type.id === rowTransactionTypeId;
        });
        const selectedSubjournal = subjournals.find(
          (s) => s.id === rowFormValues.subjournal,
        );

        const txCodeOptions = getPropertyTransactionCodesOptions(
          residentTransactionCodes
            .filter(
              (code) =>
                rowTransactionTypeId ===
                (code?.transactionCode?.transactionTypeId ?? ''),
            )
            .filter(
              (code) =>
                (!!selectedType && !selectedType.hasSubjournals) ||
                (selectedSubjournal &&
                  (code?.transactionCode?.masterSubjournal?.id ?? '') ===
                    selectedSubjournal.masterSubjournalId),
            ),
        );
        const initialTransactionCodeOptions =
          !rowTransactionTypeId || !rowFormValues.subjournal
            ? [{ value: '', text: 'Choose', disabled: true }]
            : txCodeOptions;
        const customerName =
          tx && tx.length
            ? `${tx[0]?.customer?.firstName} ${tx[0]?.customer?.lastName}`
            : '';

        // get saved transaction code name
        const rowTransactionCodeId = rowFormValues.transactionCode;
        const rowTransactionCodeObj = isNil(rowTransactionCodeId)
          ? null
          : initialTransactionCodeOptions.find(
              (t) => t.value === rowTransactionCodeId,
            );
        const rowTransactionCode = rowTransactionCodeObj?.text ?? null;

        // get saved subjournal name
        const rowSubjournalId = rowFormValues.subjournal;
        const rowSubjournalObj = isNil(rowSubjournalId)
          ? null
          : initialSubjournalOptions.find((t) => t.value === rowSubjournalId);
        const rowSubjournalCode = rowSubjournalObj?.text ?? null;

        const newRowValue = {
          ...row,
          balance: currentBalance,
          newBalance: isNil(rowAmountStr) ? null : finalRowBalance,
          customerId,
          customerName,
          initialSubjournalOptions,
          initialTransactionCodeOptions,
          amount: rowAmountStr,
          transactionType: rowTransactionType,
          transactionCode: rowTransactionCode,
          subjournal: rowSubjournalCode,
        };
        return {
          allRows: [...acc.allRows, newRowValue],
          balances: { ...acc.balances, [customerId]: finalRowBalance },
        };
      },
      { allRows: [], balances: startingBalances },
    );

    return rv.allRows;
  };

  const onPostConfirmClicked = async () => {
    setShowConfirmPostBatchModal(false);
    let saveResult;
    if (
      !transactionBatchHeaderId ||
      !isHeaderFormPristine ||
      !pristine ||
      checkUnsavedRowsFromAddGroup()
    ) {
      saveResult = await onSaveAndCloseClicked(headerFormValues, formValues);
    }
    return onPost({ transactionBatchHeaderId: saveResult?.header?.id });
  };

  const isEditTxBatch = !!initialValues;
  const updateShowErrorMsg = (id) => {
    setShowErrorMsg(true);
    if (!errRowId.find((rowId) => rowId === id)) {
      setErrRowId([...errRowId, id]);
    }
  };

  const checkUnsavedRowsFromAddGroup = () => {
    const formValuesKeys = Object.keys(formValues || {}) || [];
    const unsavedRows = formValuesKeys?.find((key) =>
      formValues?.[key]?.id?.startsWith('unsaved_row'),
    );
    return unsavedRows;
  };

  const saveEnabled =
    ((!isBatchEditable || isNil(transactionBatchHeaderId)) &&
      isHeaderFormValid &&
      ((!pristine && valid) || (pristine && checkUnsavedRowsFromAddGroup()))) ||
    (isBatchEditable &&
      !showErrorMsg &&
      isHeaderFormValid &&
      (!isHeaderFormPristine ||
        (!pristine && valid) ||
        (pristine && checkUnsavedRowsFromAddGroup())));

  const postEnabled =
    ((!isBatchEditable || isNil(transactionBatchHeaderId)) &&
      isHeaderFormValid &&
      ((!pristine && valid) || (pristine && checkUnsavedRowsFromAddGroup()))) ||
    (isBatchEditable &&
      !showErrorMsg &&
      isHeaderFormValid &&
      ((isHeaderFormPristine && pristine) || valid));

  const isViewMode =
    transactionBatch?.header?.id &&
    transactionBatch?.header?.batchStatus !== 'Saved';
  const errorMsgHeight = showErrorMsg ? 50 : 0;
  const dynamicHeight = !isLoading
    ? inlineRowsArray?.length > 1
      ? errorMsgHeight + 125 + 55 * inlineRowsArray?.length
      : 170 + errorMsgHeight
    : 200;

  return (
    <React.Fragment>
      <div className="container-fluid" style={{ height: `${dynamicHeight}px` }}>
        {showErrorMsg && isEditTxBatch && (
          <div className="alert alert-secondary">
            <div className="container-fluid">
              <div>
                <strong>
                  <FormattedMessage {...messages.errorMsgResident} />
                </strong>
              </div>
            </div>
          </div>
        )}
        <StyledForm>
          <div className="table-deposits-container">
            <table className="table table-prospects table-striped">
              <thead className="table-header">
                <tr>
                  <th>
                    <FormattedMessage {...messages.transactionType} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.subjournal} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.transactionCode} />
                  </th>
                  <th>
                    <FormattedMessage {...messages.amount} />
                  </th>
                  <th style={{ minWidth: '200px' }}>
                    {isAllCommercial ? (
                      <FormattedMessage {...messages.unitTenant} />
                    ) : (
                      <FormattedMessage {...messages.unitResident} />
                    )}
                  </th>
                  <th>
                    <FormattedMessage {...messages.currentLedgerBalance} />{' '}
                    <OverlayTrigger overlay={infoPopover}>
                      <i className="icon et-alert-info" />
                    </OverlayTrigger>
                  </th>
                  <th>
                    <FormattedMessage {...messages.newLedgerBalance} />
                  </th>
                  {!isViewMode && (
                    <th>
                      <FormattedMessage {...messages.delete} />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody data-test="details-table">
                {isLoading ? (
                  <tr>
                    <td colSpan={8}>
                      <div className="text-center">
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                ) : (
                  getRowsWithBalances().map((row, i) => (
                    <AddInlineRow
                      key={row.key}
                      id={row.id}
                      deleteRow={deleteRow}
                      subjournals={subjournals}
                      propertyTransactionCodes={propertyTransactionCodes}
                      currentResidentsOptions={currentResidentsOptions}
                      transactionTypes={transactionTypes}
                      change={change}
                      disableDelete={disableDeleteRows}
                      balance={row.balance}
                      newBalance={row.newBalance}
                      customerId={row.customerId}
                      residentTransactionCodes={residentTransactionCodes}
                      initialSubjournalOptions={row.initialSubjournalOptions}
                      initialTransactionCodeOptions={
                        row.initialTransactionCodeOptions
                      }
                      setShowErrorMsg={updateShowErrorMsg}
                      isEditTxBatch={isEditTxBatch}
                      customerName={row.customerName}
                      isViewMode={isViewMode}
                      amount={row.amount}
                      transactionType={row.transactionType}
                      transactionCode={row.transactionCode}
                      subjournal={row.subjournal}
                    />
                  ))
                )}
              </tbody>
              <tfoot className="table-footer">
                <TransactionBatchDetailsTableFooter
                  addRow={addRow}
                  totalBatchAmount={summaryRow.totalBatchAmount}
                  totalHouseholds={summaryRow.totalHouseholds.length}
                  totalTransactions={summaryRow.totalTransactions}
                  isViewMode={isViewMode}
                  isAllCommercial={isAllCommercial}
                />
              </tfoot>
            </table>
          </div>
        </StyledForm>
      </div>

      {!isViewMode && (
        <Row className="padtop30 text-center">
          {showDeleteBtn && (
            <Button
              onClick={onDelete}
              variant="deleteSubtle"
              disabled={!enableDeleteBtn}
              sx={{ mr: 2 }}
            >
              <FormattedMessage {...messages.deleteBatch} />
            </Button>
          )}
          <Button
            onClick={() => onSaveAndCloseClicked(headerFormValues, formValues)}
            variant="primarySubtle"
            disabled={!saveEnabled}
            sx={{ mr: 2 }}
          >
            <FormattedMessage {...messages.saveClose} />
          </Button>
          <Button
            onClick={() => {
              setShowConfirmPostBatchModal(true);
            }}
            variant="primary"
            disabled={!postEnabled}
            sx={{ width: 120 }}
          >
            <FormattedMessage {...messages.post} />
          </Button>
        </Row>
      )}

      {showConfirmPostBatchModal && (
        <ConfirmPostBatchModal
          onHide={() => setShowConfirmPostBatchModal(false)}
          onConfirm={() => {
            onPostConfirmClicked();
          }}
          totalBatchAmount={summaryRow.totalBatchAmount}
          totalHouseholds={summaryRow.totalHouseholds.length}
          totalTransactions={summaryRow.totalTransactions}
          isAllCommercial={isAllCommercial}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: GlobalState): Object => {
  const formValues = getFormValues('transactionBatchForm')(state);
  const headerFormValues = getFormValues('CreateTransactionBatchHeader')(state);
  const isHeaderFormPristine = isPristine('CreateTransactionBatchHeader')(
    state,
  );
  const isHeaderFormValid = isValid('CreateTransactionBatchHeader')(state);
  return {
    formValues,
    headerFormValues,
    isHeaderFormPristine,
    isHeaderFormValid,
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'transactionBatchForm',
    enableReinitialize: true,
    validate,
  })(CreateTransactionBatchDetails),
);
