import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'App.ViewProperty.GPRModal.Title',
    defaultMessage: 'MST Financial Setting',
  },
  save: {
    id: 'App.ViewProperty.GPRModal.Save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'App.ViewProperty.GPRModal.Cancel',
    defaultMessage: 'Cancel',
  },
  note: {
    id: 'App.ViewProperty.GPRModal.Note',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Note: The option selected above will determine which rent type listed for each floor plan will be calculated as the GPR for this property.',
  },
  mstType: {
    id: 'App.ViewProperty.GPRModal.MSTType',
    defaultMessage: 'MST Type',
  },
});
