import React from 'react';
import DashesIfNullOrUndefined from '../../components/DashesIfNullOrUndefined';
import messages from './messages';
import {
  Stack,
  TableCell,
  TableRow,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { HouseholdSection } from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import ServiceAnimalIndicator from './ServiceAnimalIndicator';

type Props = {
  editHouseholdMember: Function,
  householdMembers: Array<Object>,
};

export default function ApplicantHousehold({
  intl,
  householdMembers,
  editHouseholdMember,
}: Props) {
  return (
    <HouseholdSection
      intl={intl}
      headers={[messages.name, messages.relation]}
      rows={householdMembers.map((member) => {
        const isPet = member.type === 'pet';

        return (
          <TableRow
            key={member.id}
            sx={{ backgroundColor: 'transparent !important' }}
          >
            <TableCell>
              {isPet ? (
                <DashesIfNullOrUndefined data={member.name} />
              ) : (
                <a onClick={() => editHouseholdMember(member)}>
                  <DashesIfNullOrUndefined data={member.name} />
                </a>
              )}
            </TableCell>
            <TableCell>
              <Stack direction={'row'} spacing={0.5} alignItems={'center'}>
                <span>{member.relationship}</span>

                {member.isServiceAnimal && <ServiceAnimalIndicator />}
              </Stack>
            </TableCell>
          </TableRow>
        );
      })}
    ></HouseholdSection>
  );
}
