import { useContext } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { PageContext } from '../context';
import messages from '../messages';

type Props = {
  by: string,
  on: string,
};

const Wrapper = styled.div`
  background: #ebf5ff;
  border: 1px solid #2136e1;
  box-sizing: border-box;
  border-radius: 2px;
  color: #2136e1;
  padding: 10px 18px;
`;

const formatDate = (date: string) => {
  return moment(date).format('MMM DD, YYYY @ h:mm A');
};

function LastUpdatedBlock({ by, on }: Props) {
  const { formatMessage } = useContext(PageContext);
  return (
    <Wrapper>
      {formatMessage(messages.lastEditedBy)} {by} {formatMessage(messages.on)}{' '}
      {formatDate(on)}
    </Wrapper>
  );
}

export default LastUpdatedBlock;
