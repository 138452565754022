const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

class PriorityLevelService {
  getAll(organizationId: string) {
    const options = {
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      }
    };
    return request(`${apiURL}/${organizationId}/priorityLevels`, options);
  }
}

export default PriorityLevelService;
