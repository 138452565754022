import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { pathOr, isNil } from 'ramda';

import Spinner from '../../../components/Spinner';

import messages from './messages';
import {
  useModifyTransactionCamPool,
  useDeleteTransactionCamPool,
} from './hooks';

import ModifyTransactionCamPoolForm from './form';

type CustomerOpsLedgerCamPool = {
  camRecordsId: string,
  startDate: Date,
  camAllocationsId: string,
  camPoolName: string,
};

type Props = {
  customerOpsLedgerId: string,
  currentCamPool: CustomerOpsLedgerCamPool,
  camPools?: Array<Object>,
  intl: any,
  onClose: Function,
  promptToaster: Function,
  organizationId: string,
  propertyId: string,
  householdId: string,
};

const DEFAULT_OPTION = { value: 'default', text: 'Choose', disabled: true };

const ModifyTransactionCamPoolModal = ({
  customerOpsLedgerId,
  currentCamPool,
  camPools,
  intl,
  onClose,
  promptToaster,
  organizationId,
  propertyId,
  householdId,
}: Props) => {
  const initialCamPool = pathOr(null, ['camAllocationsId'], currentCamPool);
  const initialCamRecord = pathOr(null, ['camRecordsId'], currentCamPool);
  const initialApplyToCamPool =
    !isNil(initialCamPool) && !isNil(initialCamRecord);

  const [formValues, setFormValues] = useState({
    applyToCamPool: initialApplyToCamPool,
    camRecord: initialCamRecord || DEFAULT_OPTION.value,
    camPool: initialCamPool || DEFAULT_OPTION.value,
  });

  const { applyToCamPool, camRecord, camPool } = formValues;

  const [isLoadingModify, modifyTransactionCamPool] =
    useModifyTransactionCamPool({
      organizationId,
      propertyId,
      householdId,
      intl,
      promptToaster,
      onSuccess: () => onClose(true),
    });

  const [isLoadingDelete, deleteTransactionCamPool] =
    useDeleteTransactionCamPool({
      organizationId,
      propertyId,
      householdId,
      intl,
      promptToaster,
      onSuccess: () => onClose(true),
    });

  const isUnassigningPool = initialApplyToCamPool && !applyToCamPool;
  const isChangingPool =
    applyToCamPool &&
    camRecord !== DEFAULT_OPTION.value &&
    camPool !== DEFAULT_OPTION.value &&
    (camRecord !== initialCamRecord || camPool !== initialCamPool);
  const enableSaveButton = isUnassigningPool || isChangingPool;

  const isLoading = isLoadingModify || isLoadingDelete;

  const onSubmit = () => {
    if (isChangingPool) {
      modifyTransactionCamPool(customerOpsLedgerId, camPool);
    } else if (isUnassigningPool) {
      deleteTransactionCamPool(customerOpsLedgerId);
    }
  };

  return isLoading ? (
    <div className="text-center">
      <Spinner />
    </div>
  ) : (
    <Form className="modal-body">
      <ModifyTransactionCamPoolForm
        intl={intl}
        formValues={formValues}
        setFormValues={setFormValues}
        camPools={camPools}
      />

      <Row>
        <Col xs={6}>
          <Button
            bsStyle={'primary'}
            className="pull-right"
            onClick={onSubmit}
            disabled={!enableSaveButton}
          >
            {intl.formatMessage(messages.save)}
          </Button>
        </Col>
        <Col xs={6}>
          <Button
            bsStyle={'default'}
            className="pull-left"
            onClick={() => onClose(false)}
          >
            {intl.formatMessage(messages.cancel)}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ModifyTransactionCamPoolModal;
