import { put, takeLatest, select, call, throttle } from 'redux-saga/effects';
import { reset } from 'redux-form';
import { push } from 'react-router-redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';

import ProspectProfileService from '../../services/prospectProfileService';
import ApplicationService from '../../services/applicationService';
import ApplicationFeesService from '../../services/applicationFeesService';
import * as ActionTypes from './constants';
import * as createApplicationActions from './actions';
import messages from './messages';
import { getUrlWithSelectedPropertyId } from '../../utils/navigation-helpers';

import type { Saga } from 'redux-saga';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../App/selectors';

import type { Action } from '../App/types';

export function* fetchGetOneProspect(action: Action<string>): Saga<void> {
  try {
    const selectedProperty = yield select(selectSelectedProperty);
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const prospectsService = new ProspectProfileService();
    const response = yield call(
      prospectsService.getOne,
      action.payload,
      selectedProperty.id,
      organizationId,
    );
    yield put(createApplicationActions.getOneProspectSuccess(response));
  } catch (err) {
    yield put(createApplicationActions.getOneProspectError(err));
  }
}

export function* getOneProspectSaga(): Saga<void> {
  yield takeLatest(ActionTypes.GET_ONE_PROSPECT, fetchGetOneProspect);
}

export function* fetchCreateApplication(action: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const applicationService = new ApplicationService();
    const app = yield applicationService.save(action.payload, organizationId);
    yield put(createApplicationActions.createApplicationSuccess());
    yield put(reset('editHousehold'));
    yield put(push(getUrlWithSelectedPropertyId(`/application/${app.id}`)));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successDescription),
        title: renderTranslatedMessage(messages.successHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* createApplication(): Saga<void> {
  yield throttle(500, ActionTypes.CREATE_APPLICATION, fetchCreateApplication);
}

export function* fetchGetApplicationFees(action: Action<any>): Saga<void> {
  try {
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const selectedProperty = yield select(selectSelectedProperty);
    const numAdults = action.payload;
    const applicationFeesService = new ApplicationFeesService();
    const fees = yield applicationFeesService.getAll(
      organizationId,
      selectedProperty.id,
      numAdults,
    );
    yield put(createApplicationActions.getApplicationFeesSuccess(fees));
  } catch (err) {
    yield put(createApplicationActions.getApplicationFeesError(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.errorHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* getApplicationFees(): Saga<void> {
  yield takeLatest(ActionTypes.GET_APPLICATION_FEES, fetchGetApplicationFees);
}

export default [getOneProspectSaga, createApplication, getApplicationFees];
