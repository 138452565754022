import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Card,
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import {
  HUD_PROGRAM_NAME,
  LIHTC_PROGRAM_NAME,
  RD_PROGRAM_NAME,
} from '../../constants/affordableProgramNames';
import {
  COMPLIANCE_APPROVAL_STATUSES,
  affordableComplianceApprovalStatusesMessages,
} from '../../constants/affordableComplianceApprovalStatuses';
import messages from './messages';

const ORDER = {
  complianceApprovalStatus: [
    'approved',
    'approvedPendingSign',
    'correctionNeeded',
    'correctionNeededFinal',
    'denied',
    'onNoticeHold',
    'notStarted',
    'pending',
    'pendingFinalApproval',
  ],
};

const PROGRAMS = [LIHTC_PROGRAM_NAME, HUD_PROGRAM_NAME, RD_PROGRAM_NAME];
const CertificationsSummary = ({
  propertyAffordablePrograms,
  summary: { recerts = {}, certifications = {} },
}) => {
  const activePropertyPrograms = PROGRAMS.filter((program) =>
    propertyAffordablePrograms.includes(program),
  );
  const orderedCertifications = useMemo(() => {
    const orderedCertifications = [];
    // map the certifications to name as key
    const parsedCertifications = Object.entries(certifications).reduce(
      (acc, [_, { name, programs }]) => {
        acc[name] = { name, programs };
        return acc;
      },
      {},
    );
    // order the certifications
    ORDER.complianceApprovalStatus.forEach((complianceApprovalStatusKey) => {
      const statusString =
        COMPLIANCE_APPROVAL_STATUSES[complianceApprovalStatusKey];

      if (parsedCertifications[statusString]) {
        parsedCertifications[statusString].complianceApprovalStatusKey =
          complianceApprovalStatusKey;
        orderedCertifications.push(parsedCertifications[statusString]);
      }
    });

    return orderedCertifications;
  }, [certifications]);

  const shouldRender = useMemo(() => {
    if (Object.keys(recerts).length || Object.keys(certifications).length)
      return true;
  }, [recerts, certifications]);

  return shouldRender ? (
    <Stack id={'certificationsSummary'}>
      <Stack
        direction={'row'}
        spacing={1}
        sx={{ padding: 2, overflowX: 'auto', width: '100%' }}
      >
        {orderedCertifications.map(
          ({ name, complianceApprovalStatusKey, programs }) => {
            return (
              <Card
                key={name}
                sx={{
                  minWidth: 210,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant={'inputLabel'} padding={2}>
                  <FormattedMessage
                    {...affordableComplianceApprovalStatusesMessages[
                      complianceApprovalStatusKey
                    ]}
                  />
                </Typography>
                <Stack direction={'column'} spacing={1} sx={{ padding: 2 }}>
                  {activePropertyPrograms.map((PROGRAM) => {
                    const count = programs[PROGRAM] ?? 0;
                    return (
                      <Typography
                        key={PROGRAM}
                        color={count === 0 ? 'text.disabled' : 'text.primary'}
                      >
                        {PROGRAM}: {count}
                      </Typography>
                    );
                  })}
                </Stack>
              </Card>
            );
          },
        )}

        {Object.entries(recerts).map(([key, programs]) => {
          if (!key.length) return null;
          return (
            <Card
              key={key}
              sx={{
                minWidth: 210,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant={'inputLabel'} padding={2}>
                <FormattedMessage {...messages[key]} />
              </Typography>
              <Stack direction={'column'} spacing={1} sx={{ padding: 2 }}>
                {activePropertyPrograms.map((PROGRAM) => {
                  const count = programs[PROGRAM] ?? 0;
                  return (
                    <Typography
                      key={PROGRAM}
                      color={count === 0 ? 'text.disabled' : 'text.primary'}
                    >
                      {PROGRAM}: {count}
                    </Typography>
                  );
                })}
              </Stack>
            </Card>
          );
        })}
      </Stack>
    </Stack>
  ) : null;
};

CertificationsSummary.defaultProps = {
  summary: {
    recerts: {},
    certifications: {},
  },
};
export default CertificationsSummary;
