export const GET_ALL_PROSPECTS = 'app/ManageProspects/GET_ALL_PROSPECTS';
export const GET_ALL_PROSPECTS_SUCCESS =
  'app/ManageProspects/GET_ALL_PROSPECTS_SUCCESS';
export const GET_ALL_PROSPECTS_ERROR =
  'app/ManageProspects/GET_ALL_PROSPECTS_ERROR';

export const ASSIGN_PROSPECT = 'app/ManageProspects/ASSIGN_PROSPECT';
export const ASSIGN_PROSPECT_SUCCESS =
  'app/ManageProspects/ASSIGN_PROSPECT_SUCCESS';
export const ASSIGN_PROSPECT_ERROR =
  'app/ManageProspects/ASSIGN_PROSPECT_ERROR';

export const CHANGE_PROSPECT_STATUS =
  'app/ManageProspects/CHANGE_PROSPECT_STATUS';
export const CHANGE_PROSPECT_STATUS_SUCCESS =
  'app/ManageProspects/CHANGE_PROSPECT_STATUS_SUCCESS';
export const CHANGE_PROSPECT_STATUS_ERROR =
  'app/ManageProspects/CHANGE_PROSPECT_STATUS_ERROR';

export const CREATE_PROSPECT_ACTIVITY =
  'app/ManageProspects/CREATE_PROSPECT_ACTIVITY';
export const CREATE_PROSPECT_ACTIVITY_SUCCESS =
  'app/ManageProspects/CREATE_PROSPECT_ACTIVITY_SUCCESS';
export const CREATE_PROSPECT_ACTIVITY_ERROR =
  'app/ManageProspects/CREATE_PROSPECT_ACTIVITY_ERROR';

export const UPDATE_PROSPECT_ACTIVITY =
  'app/ManageProspects/UPDATE_PROSPECT_ACTIVITY';
export const DELETE_PROSPECT_ACTIVITY =
  'app/ManageProspects/DELETE_PROSPECT_ACTIVITY';

export const CLEAR_MANAGE_PROSPECT_MESSAGES =
  'app/ManageProspects/CLEAR_MANAGE_PROSPECT_MESSAGES';

export const UPDATE_COLUMNS_SORT_VALUE =
  'app/ManageProspects/UPDATE_COLUMNS_SORT_VALUE';
export const CLEAN_LOADED_PROSPECTS =
  'app/ManageProspects/CLEAN_LOADED_PROSPECTS';

export const REFRESH_PROSPECT = 'app/ManageProspects/REFRESH_PROSPECT';

export const PROSPECT = 'Prospect';

export const HEADERS = [
  { id: 'status', title: 'Status' },
  { id: 'lastName', title: 'Last Name' },
  { id: 'firstName', title: 'First Name' },
  { id: 'phoneNumber', title: 'Phone Number' },
  { id: 'email', title: 'Email' },
  { id: 'prospectCreated', title: 'Prospect Created' },
  { id: 'lastActivity', title: 'Last Activity' },
  { id: 'nextActivity', title: 'Next Activity' },
  { id: 'moveInDate', title: 'Desired Move-In Date' },
  { id: 'nBeds', title: '# of Bedrooms' },
  { id: 'assignedTo', title: 'Assigned To' },
];
