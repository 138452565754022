import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export const GET_ALL_DELINQUENCIES =
  'app/ManageDelinquencies/GET_ALL_DELINQUENCIES';
export const GET_ALL_DELINQUENCIES_SUCCESS =
  'app/ManageDelinquencies/GET_ALL_DELINQUENCIES_SUCCESS';
export const GET_ALL_DELINQUENCIES_ERROR =
  'app/ManageDelinquencies/GET_ALL_DELINQUENCIES_ERROR';

export const CLEAN_LOADED_DELINQUENCIES =
  'app/ManageDelinquencies/CLEAN_LOADED_DELINQUENCIES';

export const HEADER_ROWS = [
  {
    headers: [
      {
        id: 'unitOrder',
        title: <FormattedMessage {...messages.unitNumber} />,
        rowSpan: '2',
      },
      {
        id: 'status',
        title: <FormattedMessage {...messages.status} />,
        rowSpan: '2',
      },
      {
        id: 'floorPlan',
        title: <FormattedMessage {...messages.floorPlan} />,
        rowSpan: '2',
      },
      {
        id: 'name',
        title: <FormattedMessage {...messages.residentApplicantName} />,
        rowSpan: '2',
      },
      {
        id: 'phoneNumber',
        title: <FormattedMessage {...messages.phoneNumber} />,
        rowSpan: '2',
        classes: 'table-col-width150',
        order: null,
      },
      {
        id: 'moveInDate',
        title: <FormattedMessage {...messages.moveInDate} />,
        rowSpan: '2',
      },
      {
        id: 'moveOutDate',
        title: <FormattedMessage {...messages.moveOutDate} />,
        rowSpan: '2',
      },
      {
        id: 'collectionsNotes',
        title: <FormattedMessage {...messages.collectionsNotes} />,
        rowSpan: '2',
        classes: 'table-col-width150',
      },
      {
        id: 'underEviction',
        title: <FormattedMessage {...messages.underEviction} />,
        rowSpan: '2',
      },
      {
        id: 'prepaidBalance',
        title: <FormattedMessage {...messages.prepaidBalance} />,
        rowSpan: '2',
      },
      {
        id: 'delinquentBalance',
        title: <FormattedMessage {...messages.delinquentBalance} />,
        rowSpan: '2',
      },
      {
        id: 'writtenOff',
        title: <FormattedMessage {...messages.writtenOff} />,
        rowSpan: '2',
      },
      {
        id: 'subsidyOnlyBalance',
        title: <FormattedMessage {...messages.subsidyOnlyBalance} />,
        rowSpan: '2',
      },

      {
        id: 'CAMOnlyBalance',
        title: <FormattedMessage {...messages.CAMOnlyBalance} />,
        rowSpan: '2',
      },
      {
        id: 'residentOnlyBalance',
        title: <FormattedMessage {...messages.residentOnlyBalance} />,
        rowSpan: '2',
      },
      {
        id: 'rentOnlyBalance',
        title: <FormattedMessage {...messages.rentOnlyBalance} />,
        rowSpan: '2',
      },
      {
        id: 'agingBalances',
        title: <FormattedMessage {...messages.agingBalances} />,
        classes: 'header-darker',
        order: null,
        props: {
          colSpan: '4',
          scope: 'colgroup',
        },
      },
      {
        id: 'depositsHeld',
        title: <FormattedMessage {...messages.depositsHeld} />,
        rowSpan: '2',
      },
      {
        id: 'depositsOwed',
        title: <FormattedMessage {...messages.depositsOwed} />,
        rowSpan: '2',
      },
      {
        id: 'lateFees',
        title: <FormattedMessage {...messages.lateFees} />,
        rowSpan: '2',
      },
      {
        id: 'nsfFees',
        title: <FormattedMessage {...messages.nsfFees} />,
        rowSpan: '2',
      },
      {
        id: 'actions',
        title: <FormattedMessage {...messages.actions} />,
        rowSpan: '2',
        order: null,
      },
    ],
  },
  {
    headers: [
      {
        id: 'current',
        title: <FormattedMessage {...messages.current} />,
        classes: 'header-darker--var',
      },
      {
        id: 'thirtyOneToSixty',
        title: '31-60',
        scope: 'col',
        classes: 'header-darker--var',
      },
      {
        id: 'sixtyOneToNinety',
        title: '61-90',
        scope: 'col',
        classes: 'header-darker--var',
      },
      {
        id: 'ninetyOnePlus',
        title: '91+',
        scope: 'col',
        classes: 'header-darker--var',
      },
    ],
  },
];

export const FILTERS = [
  {
    fieldName: 'balances',
    fieldDescription: 'Types of Balances',
    options: [
      { value: 'prepaidBalance', text: 'Has Prepaid Balance' },
      { value: 'delinquentBalance', text: 'Has Delinquent Balance' },
      { value: 'subsidyOnlyBalance', text: 'Has Subsidy Balance' },
    ],
  },
  {
    fieldName: 'statuses',
    fieldDescription: 'Status',
    options: [
      { value: 'occupiedNoNotice', text: 'Occupied / No Notice' },
      { value: 'occupiedOnNotice', text: 'Occupied / On Notice' },
      { value: 'applicant', text: 'Applicant' },
      { value: 'priorResident', text: 'Prior Resident' },
    ],
  },
  {
    fieldName: 'eviction',
    fieldDescription: 'Eviction',
    options: [{ value: 'underEviction', text: 'Under Eviction' }],
  },
];

export const STATUS_NAMES_TO_VALUES = {
  occupiedNoNotice: 'Occupied / No Notice',
  occupiedOnNotice: 'Occupied / On Notice to Vacate',
  applicant: 'Applicant',
  priorResident: 'Prior Resident',
};
