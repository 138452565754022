import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.MaritalStatusSection.Title',
    defaultMessage: 'Marital Status'
  },
  spouse: {
    id: 'App.MaritalStatusSection.Spouse',
    defaultMessage: 'Spouse'
  },
  firstNameLabel: {
    id: 'App.MaritalStatusSection.FirstNameLabel',
    defaultMessage: 'Legal First Name'
  },
  middleNameLabel: {
    id: 'App.MaritalStatusSection.MiddleNameLabel',
    defaultMessage: 'Middle Name'
  },
  lastNameLabel: {
    id: 'App.MaritalStatusSection.LastNameLabel',
    defaultMessage: 'Last Name'
  },
  preferredNameLabel: {
    id: 'App.MaritalStatusSection.PreferredNameLabel',
    defaultMessage: 'Preferred Name'
  },
});

export default messages;
