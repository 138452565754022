import React from 'react';
import { findIndex, isNil, pathOr, propEq } from 'ramda';
import { FormattedDate } from 'react-intl';
import ManageFormsDropDown from '../../../components/ManageFormsDropDown';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import { phoneFormatter } from '../../../utils/redux-form-helper';
import {
  formatDateLocale,
  getDateDiffInDays,
  getDateUTC,
  getIsPastDueDate,
} from '../../../utils/date-helpers';
import { getRowColor } from '../utils';
import type { WorkOrder } from '../../App/types';
import {
  Link,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { table as tableColors } from '@fortress-technology-solutions/fortress-component-library/design';
import { getUrlWithSelectedPropertyId } from '../../../utils/navigation-helpers';

type Props = {
  flags: { [flagName: string]: boolean },
  workOrder: WorkOrder,
  onClick: Function,
  onAssigneeChange: Function,
  onPriorityChange: Function,
  dropDownAssignees: Array<Object>,
  priorityLevels: Array<Object>,
};
const WorkOrderDetails = (props: Props) => {
  const {
    flags,
    priorityLevels,
    workOrder,
    dropDownAssignees,
    onAssigneeChange,
    onPriorityChange,
  } = props;
  // $FlowFixMe
  const finishedDate = workOrder.finishedDate;
  const lastStatusChange = workOrder.lastStatusChangeDate;
  const statusDescription = pathOr(
    '',
    ['status', 'statusDescription'],
    workOrder,
  );
  const isActiveWorkOrder =
    statusDescription !== 'Completed' && statusDescription !== 'Canceled';
  const dateToCompare =
    statusDescription === 'Open' ||
    statusDescription === 'On Hold' ||
    statusDescription === 'In Progress'
      ? getDateUTC()
      : statusDescription === 'Canceled'
      ? lastStatusChange
      : isNil(finishedDate)
      ? lastStatusChange
      : finishedDate;

  const daysOpen = getDateDiffInDays(dateToCompare, workOrder.createdAt);

  const isPastDueDate = getIsPastDueDate(workOrder?.dueDate);

  const assigneeId = workOrder?.assignedTo?.id || '';
  const assigneeIndex = findIndex(propEq('value', workOrder.assignedToId))(
    dropDownAssignees,
  );
  const statusCheck =
    assigneeIndex > 0 ? dropDownAssignees[assigneeIndex].status : 'Inactive';

  const workOrderAssignedToIsNullOrStatusCheckIsInactive =
    workOrder.assignedTo === null || statusCheck === 'Inactive';
  const backgroundColor = flags.workOrderDueDateManageScreen
    ? getRowColor(
        isPastDueDate,
        isActiveWorkOrder,
        workOrderAssignedToIsNullOrStatusCheckIsInactive,
      )
    : workOrderAssignedToIsNullOrStatusCheckIsInactive
    ? tableColors.warning
    : null;

  const otherCommonAreaLocation = workOrder?.otherCommonAreaLocation ?? null;
  // $FlowFixMe
  let location = pathOr(false, ['commonAreaLocationId'], workOrder)
    ? // $FlowFixMe
      pathOr(
        '',
        ['commonAreaLocation', 'commonAreaLocationDescription'],
        workOrder,
      )
    : workOrder.unitNumber;
  if (location === 'Other' && otherCommonAreaLocation) {
    location = `${location} - ${otherCommonAreaLocation}`;
  }
  const phoneNumber = workOrder.requestorPhone
    ? phoneFormatter(workOrder.requestorPhone)
    : null;
  const issue = workOrder.issue ? workOrder.issue : null;
  const issueDescription =
    issue && issue.issueDescription ? issue.issueDescription : '';
  // $FlowFixMe
  const otherDescription = pathOr(null, ['otherDescription'], workOrder);
  const description = otherDescription
    ? `Other - ${otherDescription}`
    : issueDescription;

  return (
    <tr style={{ backgroundColor }}>
      <td>{workOrder.detailIdReadable}</td>
      <td>{location}</td>
      <td>
        <ElementWithPermissions scope={['workorder-read']}>
          <Link
            component="a"
            href={getUrlWithSelectedPropertyId(
              `/edit-work-order/${workOrder.detailId}`,
            )}
            active="true"
          >
            {`${description}`}
          </Link>
        </ElementWithPermissions>
      </td>
      <td>
        <span className="row-detail">
          <FormattedDate
            value={workOrder.createdAt}
            year="numeric"
            month="numeric"
            day="2-digit"
            hour="numeric"
            minute="numeric"
          />
        </span>
      </td>
      <td className="dropdown">
        <ManageFormsDropDown
          items={dropDownAssignees}
          changeFunction={onAssigneeChange(workOrder)}
          initialValue={assigneeId}
          scope={['change-work-order-assignee']}
        />
      </td>
      <td className="dropdown">
        <ManageFormsDropDown
          items={priorityLevels}
          changeFunction={onPriorityChange(workOrder)}
          initialValue={workOrder.priorityLevelId}
          scope={['workorder-edit']}
        />
      </td>
      <td>
        <span className="row-detail">{statusDescription}</span>
      </td>
      <td className="hidden-xs">{daysOpen}</td>
      {flags.workOrderDueDateManageScreen && (
        <td className="row-detail">
          <Typography
            color={isPastDueDate && isActiveWorkOrder ? 'error' : undefined}
          >
            {formatDateLocale(workOrder.dueDate)}
          </Typography>
        </td>
      )}
      <td className="row-detail">
        <Typography>{formatDateLocale(workOrder.finishedDate)}</Typography>
      </td>
      <td>
        <span className="row-detail">{workOrder.requestorName}</span>
        <br />
        <span className="row-detail">{phoneNumber}</span>
      </td>
    </tr>
  );
};

export default WorkOrderDetails;
