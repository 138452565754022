import moment from 'moment';
import { isEmpty, isNil, pathOr } from 'ramda';
import { MODAL_STATES } from '../../constants';
import { validateDate } from '../../../../utils/validations';

const validate = (values: Object, props: Object) => {
  const errors = {};

  const { camStartDate } = values;
  const { modalState, selectedCamRecordId } = props;
  const camRecords = pathOr([], ['camRecords'], props);

  /**
   * Required Field
   */
  if (isNil(camStartDate) || isEmpty(camStartDate)) {
    errors['camStartDate'] = 'CAM Start Date is required.';
  }

  /**
   * Valid Date
   */
  if (!errors['camStartDate'] && !validateDate(camStartDate)) {
    errors['camStartDate'] =
      'CAM Start Date must be a valid date format (MM/DD/YYYY).';
  }

  const mNewStartDate = moment(camStartDate);

  /**
   * CAM start date can be up to 18 months in the past and 18 months in the future.
   */
  if (!errors['camStartDate']) {
    const momentTodayDate = moment();
    const moment18MonthsAfter = moment(momentTodayDate).add(18, 'months');
    const moment18MonthsBefore = moment(momentTodayDate).add(-18, 'months');
    if (
      mNewStartDate.isAfter(moment18MonthsAfter) ||
      mNewStartDate.isBefore(moment18MonthsBefore)
    ) {
      errors['camStartDate'] = 'Please enter a valid CAM Start Date';
    }
  }

  /**
   * Make sure there is no CAM Record with the same start date for this unit
   */
  if (!errors['camStartDate']) {
    const isDuplicate = camRecords.some((camRecord) => {
      if (
        modalState === MODAL_STATES.EDIT_START_DATE &&
        camRecord.id === selectedCamRecordId
      ) {
        return false; // duplicate with itself, don't care...
      }

      const mCurrentStartDate = moment(camRecord.startDate);
      return mNewStartDate.isSame(mCurrentStartDate, 'day');
    });
    if (isDuplicate) {
      errors['camStartDate'] =
        'There is already a CAM record for this Unit with the same start date';
    }
  }

  /**
   * new CAM startDate should be after the old one
   */
  if (!errors['camStartDate']) {
    camRecords.forEach((camRecord) => {
      if (
        modalState === MODAL_STATES.START_NEW_CAM &&
        camRecord.id === selectedCamRecordId
      ) {
        if (mNewStartDate.isBefore(moment(camRecord.startDate), 'day')) {
          errors['camStartDate'] =
            'The new CAM start date should be after the previous CAM';
        }
      }
    });
  }

  return errors;
};

export default validate;
