import { put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../../utils/redux-form-helper';
import messages from './messages';
import type { Saga } from 'redux-saga';

import {
  GET_TRANSFER_REQUIREMENTS,
  RESIDENT_COMPLETE_TRANSFER,
} from './constants';
import {
  getTransferRequirementsSuccess,
  getTransferRequirementsError,
  completeTransferSuccess,
  completeTransferError,
} from './actions';
import {
  selectCurrentUserOrganizationId,
  selectSelectedProperty,
} from '../../App/selectors';
import residentService from '../../../services/residentService';

export function* fetchGetTransferRequirementsSaga({
  payload,
}: Object): Saga<void> {
  try {
    const transferResidentService = new residentService();
    const organizationId = yield select(selectCurrentUserOrganizationId);
    const property = yield select(selectSelectedProperty);
    const response = yield transferResidentService.getTransferRequirements(
      organizationId,
      property.id,
      payload.leaseId,
      payload.userId,
    );

    yield put(getTransferRequirementsSuccess(response));
  } catch (err) {
    yield put(getTransferRequirementsError(err));
  }
}

export function* getTransferRequirementsSaga(): Saga<void> {
  yield takeLatest(GET_TRANSFER_REQUIREMENTS, fetchGetTransferRequirementsSaga);
}

export function* fetchCompleteTransferSaga({ payload }: Object): Saga<void> {
  const organizationId = yield select(selectCurrentUserOrganizationId);
  const property = yield select(selectSelectedProperty);
  const propertyId = property?.id ?? '';
  try {
    const transferResidentService = new residentService();
    const response = yield transferResidentService.completeTransfer(
      organizationId,
      property.id,
      payload.actualTransferDate,
      payload.leaseId,
      payload.userId,
    );
    yield put(push(`/property/${propertyId}/manage-rent-roll`));
    yield put(completeTransferSuccess(response));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.transferSuccess),
        title: renderTranslatedMessage(messages.transferSuccessHeader),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  } catch (err) {
    yield put(completeTransferError(err));
    yield put(push(`/property/${propertyId}/manage-rent-roll`));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.transferError),
        options: {
          showCloseButton: true,
          removeOnHover: true,
        },
      }),
    );
  }
}

export function* completeTransferSaga(): Saga<void> {
  yield takeLatest(RESIDENT_COMPLETE_TRANSFER, fetchCompleteTransferSaga);
}

export default [getTransferRequirementsSaga, completeTransferSaga];
