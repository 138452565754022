import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FieldArray } from 'redux-form';
import { ProRatedAmounts } from './ProRatedAmounts';

type ProRateSectionProps = {
  monthlyTransactions: Array<Object>,
  moveInDate: any,
  headerText: string,
  noteText: string,
  intl: Function,
  moveInApplyFullAmountToProRate: boolean,
  roundProratedRentsNote: ?string,
};
export const ProrateSection = ({
  monthlyTransactions,
  moveInDate,
  headerText,
  noteText,
  intl,
  moveInApplyFullAmountToProRate,
  roundProratedRentsNote,
}: ProRateSectionProps) => (
  <Row>
    <Col xs={12} lg={11}>
      {headerText && <h3>{headerText}</h3>}
      <div className="table-scroll table-units-container">
        <FieldArray
          name="prorated"
          component={ProRatedAmounts}
          monthlyTransactions={monthlyTransactions}
          moveInDate={moveInDate}
          intl={intl}
          moveInApplyFullAmountToProRate={moveInApplyFullAmountToProRate}
          roundProratedRents={!!roundProratedRentsNote}
        />
      </div>
      {noteText && !moveInApplyFullAmountToProRate && (
        <>
          <p className="padtop20">
            <strong>Note: </strong>
            {noteText}
          </p>
          {roundProratedRentsNote && (
            <p>
              <strong>*</strong>
              {roundProratedRentsNote}
            </p>
          )}
        </>
      )}
    </Col>
  </Row>
);
