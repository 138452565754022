import React from 'react';
import { Row, Col, FormControl } from 'react-bootstrap';

import messages from './messages';

type DocumentSelectionProps = {
  intl: Object,
  show: boolean,
  filename: string,
  error: string,
  disable: boolean,
  onChange: Function,
};

const DocumentSelection = ({
  intl,
  show,
  filename,
  error,
  disable,
  onChange,
}: DocumentSelectionProps) => {
  const showError = error && error.length > 0;
  return (
    show && (
      <Row>
        <Col xs={12} sm={4}>
          <div className="form-group">
            <label className="input">
              {intl.formatMessage(messages.fileName)}
            </label>
            <input
              className="inputfile inputfile-custom"
              id="uploadDepositDoc"
              type="file"
              name="file"
              onChange={onChange}
              disabled={disable}
            />
            <label htmlFor="uploadDepositDoc">
              <span>{filename}</span>
              <strong>{intl.formatMessage(messages.browse)}</strong>
            </label>
            {showError && <span className="has-error">{error}</span>}
          </div>
        </Col>
        <Col xs={12} sm={4}>
          <label className="input">
            {intl.formatMessage(messages.documentType)}
          </label>
          <FormControl componentClass="select" disabled={true}>
            <option value="Deposit Details">Deposit Details</option>
          </FormControl>
        </Col>
      </Row>
    )
  );
};

export default DocumentSelection;
