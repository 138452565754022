import { useState, useEffect } from 'react';
import { Field, FormSection } from 'redux-form';
import {
  renderCurrencyField,
  renderSelectField,
  renderSearchDropDown,
  requiredSelect,
} from '../../../../utils/redux-form-helper';
import { FormattedNumber } from 'react-intl';

import {
  getSubjournalsOptions,
  getPropertyTransactionCodesOptions,
  getTransactionTypeOptions,
  getCustomerNameWithUnit,
} from '../utils';
import { isNil } from 'ramda';

type Props = {
  id: string,
  subjournals: Object,
  currentResidentsOptions: Object,
  transactionTypes: Object,
  disableDelete: boolean,
  balance: number,
  newBalance: number,
  deleteRow: Function,
  change: Function,
  residentTransactionCodes: Objets,
  setShowErrorMsg: Function,
  isEditTxBatch: boolean,
  customerName: string,
  isViewMode: boolean,
  amount: number,
  transactionType: string,
  transactionCode: string,
  subjournal: string,
};

const AddInlineRow = (props: Props) => {
  const {
    id,
    disableDelete,
    deleteRow,
    subjournals,
    currentResidentsOptions,
    transactionTypes,
    change,
    balance,
    newBalance,
    customerId,
    residentTransactionCodes = [],
    initialSubjournalOptions = [],
    initialTransactionCodeOptions = [],
    setShowErrorMsg,
    isEditTxBatch,
    customerName,
    isViewMode,
    amount,
    transactionType,
    transactionCode,
    subjournal,
  } = props;
  const transactionTypesOptions = getTransactionTypeOptions(transactionTypes);
  const [filteredSubjournals, setFilteredSubjournals] = useState(
    initialSubjournalOptions,
  );
  const [filteredPTC, setFilteredPTC] = useState(initialTransactionCodeOptions);
  const [selectedTransactionType, setSelectedTransactionType] = useState(null);
  const [isSubjournalDisabled, setIsSubjournalDisabled] = useState(
    !(initialSubjournalOptions.length - 1),
  );
  const [isTransactionCodeDisabled, setIsTransactionCodeDisabled] = useState(
    !(initialTransactionCodeOptions.length - 1),
  );
  useEffect(() => {
    if (
      filteredSubjournals.length === 1 &&
      initialSubjournalOptions.length > 1
    ) {
      setFilteredSubjournals(initialSubjournalOptions);
      setIsSubjournalDisabled(false);
    }
    if (filteredPTC.length === 1 && initialTransactionCodeOptions.length > 1) {
      setFilteredPTC(initialTransactionCodeOptions);
      setIsTransactionCodeDisabled(false);
    }
  }, [
    setFilteredSubjournals,
    filteredSubjournals,
    initialSubjournalOptions,
    filteredPTC,
    initialTransactionCodeOptions,
  ]);

  const getFilteredCodes = (target, value) => {
    const selectedType = transactionTypes.find((type) => {
      return type.id === selectedTransactionType;
    });
    const selectedSubjournal = subjournals.find((s) => s.id === value);
    setFilteredPTC(
      getPropertyTransactionCodesOptions(
        residentTransactionCodes
          .filter(
            (code) =>
              selectedTransactionType ===
              (code?.transactionCode?.transactionTypeId ?? ''),
          )
          .filter(
            (code) =>
              (!!selectedType && !selectedType.hasSubjournals) ||
              (selectedSubjournal &&
                (code?.transactionCode?.masterSubjournal?.id ?? '') ===
                  selectedSubjournal.masterSubjournalId),
          )
          .filter((code) => !code?.transactionCode?.isPayment),
      ),
    );
    setIsTransactionCodeDisabled(false);
  };
  const getFilteredSubjournals = (target, value) => {
    // reset subjournal and transactionCode dropdown fields
    change(`inline${id}.subjournal`, '');
    change(`inline${id}.transactionCode`, '');
    setFilteredPTC([{ value: '', text: 'Choose', disabled: true }]);
    const availableCodes = residentTransactionCodes.filter(
      (code) => code.transactionCode.transactionTypeId === value,
    );
    const availableSubjournals = availableCodes.map(
      (code) => code.transactionCode.subjournalId,
    );
    setFilteredSubjournals(
      getSubjournalsOptions(
        subjournals.filter((subjournal) =>
          availableSubjournals.find((e) => e === subjournal.masterSubjournalId),
        ),
      ),
    );
    setIsSubjournalDisabled(false);
    setSelectedTransactionType(value);
  };

  const onCustomerChanged = async (ev: any) => {
    const customerId = ev?.value ?? null;
    change(`inline${id}.customer`, customerId);
  };

  const selectedCustomer =
    customerId && currentResidentsOptions
      ? currentResidentsOptions.find(
          (issue: Object) => issue.value === customerId,
        )
      : null;
  const showErrBg =
    isEditTxBatch && !selectedCustomer && !id.includes('unsaved_row');
  const backgroundColor = showErrBg ? '#FFF0F0' : '';
  if (showErrBg) {
    setTimeout(() => {
      setShowErrorMsg(id);
    });
  }

  return (
    <FormSection name={`inline${id}`}>
      <tr style={{ backgroundColor }}>
        <td>
          {!isViewMode ? (
            <Field
              name="transactionType"
              component={renderSelectField}
              options={transactionTypesOptions}
              onChange={getFilteredSubjournals}
              messageAsTooltip
            />
          ) : (
            transactionType
          )}
        </td>
        <td>
          {!isViewMode ? (
            <Field
              name="subjournal"
              component={renderSelectField}
              options={filteredSubjournals}
              disabled={isSubjournalDisabled}
              onChange={getFilteredCodes}
              messageAsTooltip
            />
          ) : (
            subjournal
          )}
        </td>
        <td>
          {!isViewMode ? (
            <Field
              name="transactionCode"
              component={renderSelectField}
              options={filteredPTC}
              disabled={isTransactionCodeDisabled}
              messageAsTooltip
            />
          ) : (
            transactionCode
          )}
        </td>
        <td>
          {!isViewMode ? (
            <Field
              name="amount"
              component={renderCurrencyField}
              messageAsTooltip
              min="0"
              max="100000"
              style={{ zIndex: '0' }}
              placeholder="0.00"
            />
          ) : (
            `$${amount ? amount.toFixed(2) : '0.00'}`
          )}
        </td>
        <td style={{ paddingRight: '0px', paddingLeft: '5px' }}>
          {!isViewMode ? (
            showErrBg ? (
              <Field
                classNames="search-actions searchbox-default searchbox__hasautocomplete"
                component={renderSearchDropDown}
                name="customer"
                placeholder="Choose"
                options={[{ value: customerId, label: customerName }]}
                bsSize="sm"
                validate={requiredSelect}
                onChangeCallback={onCustomerChanged}
                fieldValue={[{ value: customerId, label: customerName }]}
              />
            ) : (
              <Field
                classNames="search-actions searchbox-default searchbox__hasautocomplete"
                component={renderSearchDropDown}
                name="customer"
                placeholder="Choose"
                options={currentResidentsOptions}
                bsSize="sm"
                validate={requiredSelect}
                onChangeCallback={onCustomerChanged}
                fieldValue={selectedCustomer}
              />
            )
          ) : (
            getCustomerNameWithUnit(customerId, currentResidentsOptions)
          )}
        </td>
        <td>
          {isNil(balance) ? (
            '---'
          ) : (
            <FormattedNumber
              className="pull-right"
              value={balance}
              style={{ value: 'currency' }.value}
              currency="USD"
            />
          )}
        </td>
        <td>
          {isNil(newBalance) ? (
            '---'
          ) : (
            <FormattedNumber
              className="pull-right"
              value={newBalance}
              style={{ value: 'currency' }.value}
              currency="USD"
            />
          )}
        </td>
        {!isViewMode && (
          <td>
            <button
              className="btn btn-sm btn-default"
              onClick={() => {
                deleteRow(id);
              }}
              type="button"
              disabled={disableDelete}
            >
              <i className="et-trash" />
            </button>
          </td>
        )}
      </tr>
    </FormSection>
  );
};

export default AddInlineRow;
