import React from 'react';
// $FlowFixMe
import loaderGray from '../../img/floader-gray.gif';

const GifSpinner = () => {
  return (
    <div className="text-center">
      <img src={loaderGray} alt="loading..." />
    </div>
  );
};

export default GifSpinner;
