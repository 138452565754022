import React from 'react';
import {
  Box,
  Typography,
  Button,
  Stack,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';

type Props = {
  rejectMessage: any,
  confirmMessage: any,
  title: any,
  onReject: Function,
  onConfirm: Function,
  backdrop?: boolean,
};

const ConfirmOverlay = ({
  rejectMessage,
  confirmMessage,
  title,
  onReject,
  onConfirm,
  backdrop = false,
}: Props) => {
  return (
    <div>
      {backdrop && (
        <div
          class="fade modal-backdrop in"
          style={{
            zIndex: '100',
          }}
        />
      )}
      <Box padding={2} className={'modal-confirm'}>
        <Typography variant={'h1'} paragraph>
          {title}
        </Typography>
        <Stack direction={'row'} spacing={2}>
          <Button variant="primary" onClick={onConfirm} sx={{ flex: 1 }}>
            {confirmMessage}
          </Button>
          <Button variant="default" onClick={onReject} sx={{ flex: 1 }}>
            {rejectMessage}
          </Button>
        </Stack>
      </Box>
    </div>
  );
};

export default ConfirmOverlay;
