import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ReputationMgmt.Title',
    defaultMessage: 'Reputation Management',
  },
  overallAvgRatingSectionTitle: {
    id: 'App.ReputationMgmt.OverallAvgRatingSectionTitle',
    defaultMessage: 'Overall Average Rating',
  },
  recommendBasedAvgRatingTitle: {
    id: 'App.ReputationMgmt.RecommendBasedAvgRatingTitle',
    defaultMessage: 'Average Rating',
  },
  ratingPlural: {
    id: 'App.ReputationMgmt.RatingPlural',
    defaultMessage: 'Ratings',
  },
  ratingSingular: {
    id: 'App.ReputationMgmt.RatingSingular',
    defaultMessage: 'Rating',
  },
  reviewSingular: {
    id: 'App.ReputationMgmt.ReviewSingular',
    defaultMessage: 'Review',
  },
  reviewPlural: {
    id: 'App.ReputationMgmt.ReviewPlural',
    defaultMessage: 'Reviews',
  },
  sourcePlural: {
    id: 'App.ReputationMgmt.SourcePlural',
    defaultMessage: 'Sources',
  },
  sourceSingular: {
    id: 'App.ReputationMgmt.SourceSingular',
    defaultMessage: 'Source',
  },
  averageRatingOf: {
    id: 'App.ReputationMgmt.AverageRatingOf',
    defaultMessage: 'Average Rating of',
  },
  reviewsSectionTitle: {
    id: 'App.ReputationMgmt.ReviewsSectionTitle',
    defaultMessage: 'Reviews',
  },
  reviews: {
    id: 'App.ReputationMgmt.Reviews',
    defaultMessage: 'reviews',
  },
  from: {
    id: 'App.ReputationMgmt.From',
    defaultMessage: 'from',
  },
  flagReview: {
    id: 'App.ReputationMgmt.FlagReview',
    defaultMessage: 'Flag Review',
  },
  showResponse: {
    id: 'App.ReputationMgmt.ShowResponse',
    defaultMessage: 'Show Response',
  },
  hideResponse: {
    id: 'App.ReputationMgmt.HideResponse',
    defaultMessage: 'Hide Response',
  },
  responsePublished: {
    id: 'App.ReputationMgmt.ResponsePublished',
    defaultMessage: 'Response Published',
  },
  responsePending: {
    id: 'App.ReputationMgmt.ResponsePending',
    defaultMessage: 'Response Needed',
  },
  respondToReview: {
    id: 'App.ReputationMgmt.RespondToReview',
    defaultMessage: 'Respond to Review',
  },
  search: {
    id: 'App.ReputationMgmt.Search',
    defaultMessage: 'Search',
  },
  edited: {
    id: 'App.ReputationMgmt.Edited',
    defaultMessage: 'Edited',
  },
  noReviewsFound: {
    id: 'App.ReputationMgmt.NoReviewsFound',
    defaultMessage: 'No reviews found',
  },
  filterNoResults: {
    id: 'App.ReputationMgmt.filterNoResults',
    defaultMessage: 'No reviews match your filter criteria',
  },
  filterSubtitle: {
    id: 'App.ReputationMgmt.filterSubtitle',
    defaultMessage: 'Remove, add, or edit filters to improve results',
  },
  mostRecentSort: {
    id: 'App.ReputationMgmt.MostRecentSort',
    defaultMessage: 'Most Recent',
  },
  oldestSort: {
    id: 'App.ReputationMgmt.OldestSort',
    defaultMessage: 'Oldest',
  },
  highestSort: {
    id: 'App.ReputationMgmt.HighestSort',
    defaultMessage: 'Highest',
  },
  lowestSort: {
    id: 'App.ReputationMgmt.LowestSort',
    defaultMessage: 'Lowest',
  },
  loadMoreReviews: {
    id: 'App.ReputationMgmt.LoadMoreReviews',
    defaultMessage: 'Load More Reviews',
  },
  success: {
    id: 'App.ReputationMgmt.Success',
    defaultMessage: 'Success',
  },
  updateResponseSuccess: {
    id: 'App.ReputationMgmt.UpdateResponseSuccess',
    defaultMessage: 'Response has been editted.',
  },
  respondSuccess: {
    id: 'App.ReputationMgmt.RespondSuccess',
    defaultMessage: 'Review has been responded.',
  },
  error: {
    id: 'App.ReputationMgmt.Error',
    defaultMessage: 'Error',
  },
  errorFetchReviews: {
    id: 'App.ReputationMgmt.ErrorFetchReviews',
    defaultMessage: 'Failed to fetch reviews',
  },
  errorFetchBreakdown: {
    id: 'App.ReputationMgmt.ErrorFetchBreakdown',
    defaultMessage: 'Failed to fetch rating breakdown.',
  },
  errorFetchOverallStats: {
    id: 'App.ReputationMgmt.ErrorFetchOverallStats',
    defaultMessage: 'Failed to fetch overall stats.',
  },
  dateValidationError: {
    id: 'App.ReputationMgmt.DateValidationError',
    defaultMessage: 'Invalid date range selected',
  },
  filterBy: {
    id: 'App.ReputationMgmt.VariableDateControlFilterBy',
    defaultMessage: 'Filter By',
  },
  status: {
    id: 'App.ReputationMgmt.VariableDateControlStatus',
    defaultMessage: 'Status',
  },
  failedToEditResponse: {
    id: 'App.ReputationMgmt.FailedToEditResponse',
    defaultMessage: 'Failed to edit response.',
  },
  failedToRespondToReview: {
    id: 'App.ReputationMgmt.FailedToRespondToReview',
    defaultMessage: 'Failed to respond to review.',
  },
  failedToDeleteReviewResponse: {
    id: 'App.ReputationMgmt.FailedToDeleteReviewResponse',
    defaultMessage: 'Failed to delete response.',
  },
  respondToThisReview: {
    id: 'App.ReputationMgmt.RespondToThisReview',
    defaultMessage: 'Respond to this Review...',
  },
  submitResponse: {
    id: 'App.ReputationMgmt.SubmitResponse',
    defaultMessage: 'Submit Response',
  },
  cancel: {
    id: 'App.ReputationMgmt.Cancel',
    defaultMessage: 'Cancel',
  },
  confirmDeleteTitle: {
    id: 'App.ReputationMgmt.ConfirmDeleteTitle',
    defaultMessage: 'Are you sure you want to delete this response?',
  },
  confirmDeleteAcceptText: {
    id: 'App.ReputationMgmt.ConfirmDeleteAcceptText',
    defaultMessage: 'Yes, delete',
  },
  confirmDeleteRejectText: {
    id: 'App.ReputationMgmt.ConfirmDeleteRejectText',
    defaultMessage: 'No, cancel',
  },
  category: {
    id: 'App.ReputationMgmt.Category',
    defaultMessage: 'Category',
  },
  recommendedTooltip: {
    id: 'App.ReputationMgmt.RecommendedTooltip',
    defaultMessage:
      'Past reviews with a 3, 4 or 5 numeric rating are classified as Recommend',
  },
  notRecommendedTooltip: {
    id: 'App.ReputationMgmt.NotRecommendedTooltip',
    defaultMessage:
      'Past reviews with a 1 or 2 numeric rating are classified as Not Recommend',
  },
  recommend: {
    id: 'App.ReputationMgmt.Recommend',
    defaultMessage: 'Recommend',
  },
  recommends: {
    id: 'App.ReputationMgmt.Recommends',
    defaultMessage: 'Recommends',
  },
  recommended: {
    id: 'App.ReputationMgmt.Recommended',
    defaultMessage: 'Recommended',
  },
  recommendedIcon: {
    id: 'App.ReputationMgmt.RecommendedIcon',
    defaultMessage: 'Recommended Icon',
  },
  doesntRecommend: {
    id: 'App.ReputationMgmt.DoesntRecommend',
    defaultMessage: "Doesn't Recommend",
  },
  errorFetchingMappedSources: {
    id: 'App.ReputationMgmt.ErrorFetchingMappedSources',
    defaultMessage: 'Failed to fetch mapped sources.',
  },
  connectCardTitle: {
    id: 'App.ReputationMgmt.ConnectCardTitle',
    defaultMessage: "Connect Fortress to your Property's Business Accounts",
  },
  connectCardSelectSourceText: {
    id: 'App.ReputationMgmt.ConnectCardSelectSourceText',
    defaultMessage:
      'When selecting a source, you will be redirected to their site and will be prompted to allow access to data.',
  },
  connectWithFb: {
    id: 'App.ReputationMgmt.ConnectWithFb',
    defaultMessage: 'Connect with Facebook',
  },
  connectCardSyncDataText: {
    id: 'App.ReputationMgmt.ConnectCardSyncDataText',
    defaultMessage:
      'Once connected, Fortress will be able to sync available data to your Reputation Management dashboard.',
  },
  connectCardOneSourceText: {
    id: 'App.ReputationMgmt.ConnectCardOneSourceText',
    defaultMessage: 'Connect to at least 1 source to Sync',
  },
  permissionRequiredCardTitle: {
    id: 'App.ReputationMgmt.PermissionRequiredCardTitle',
    defaultMessage: 'Permission Required',
  },
  the: {
    id: 'App.ReputationMgmt.The',
    defaultMessage: 'The',
  },
  permissionName: {
    id: 'App.ReputationMgmt.PermissionName',
    defaultMessage: 'Reputation Mgmt: Add & Edit Sources',
  },
  permissionRequiredCardText: {
    id: 'App.ReputationMgmt.PermissionRequiredCardText',
    defaultMessage:
      'permission is required to configure sources for ratings and reviews. Once ' +
      'the permission is added by your admin, keep in mind you will need credentials from ' +
      'the sources (for example: Google Business Profile credentials) to complete the set up.',
  },
});

export default messages;
