import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { pathOr } from 'ramda';

import confirm from '../../../../components/ConfirmDialogModal';
import Spinner from '../../../../components/Spinner';
import Row from '../../../../components/Table/Row';
import Data from '../../../../components/Table/Data';
import { formatDate, formatMoney } from '../../../../utils/formatter';
import { useAsyncDownloadDocument } from '../../../../hooks/downloadDocument';
import { getUrlWithSelectedPropertyId } from '../../../../utils/navigation-helpers';

import { ActionsMenu } from './ActionsMenu';
import {
  missingPrevValuesTextRedCheck,
  parseEditGRCertValues,
  parseNewAssistanceDetails,
} from './utils';
import {
  useAsyncGenerate59a,
  useAsyncUploadSigned59a,
  useCancelCertification,
  useCompleteCertification,
  useAsyncEditCertification,
} from './hooks';

const GRCertRow = ({
  promptToaster,
  grCert,
  handleRemoveRow,
  openEditGRCertModal,
  openUploadDocumentModal,
  selectedProperty,
  updateRow,
}: Object) => {
  const [signed59A, setSigned59A] = useState(grCert.document || null);
  const {
    affordableQualificationId,
    unit,
    floorPlanName,
    headOfHousehold,
    newGrossRent,
    newHUDUA,
    grEffectiveDate,
    anticipatedVoucherDate,
    priorCertificationDate,
    otherCertInProgress,
    residentSignatureRequired,
    isCorrection,
    correction,
    residentId,
    occupiedOnNTV,
    priorActiveCert,
    generated59a,
  } = grCert;

  const { handleEditGRCert, editedGRCert, editing } = useAsyncEditCertification(
    selectedProperty,
    grCert.affordableQualificationId,
    promptToaster,
  );

  const { generating59A, generated59aUpdate, handleGenerate59a } =
    useAsyncGenerate59a(
      affordableQualificationId,
      selectedProperty,
      promptToaster,
    );

  const { handleUploadSigned59a, uploadedDocument } = useAsyncUploadSigned59a(
    affordableQualificationId,
    selectedProperty,
    promptToaster,
  );

  const filename = `50059A_${moment().unix()}.pdf`;
  const signed59AId = pathOr(null, ['id'], signed59A);
  const handleDownloadDocument = useAsyncDownloadDocument(
    selectedProperty,
    signed59AId,
    filename,
    promptToaster,
  );

  const { handleCancelCertification, canceling } = useCancelCertification(
    affordableQualificationId,
    selectedProperty,
    promptToaster,
    handleRemoveRow,
  );

  const confirmCancelCert = () => {
    confirm(
      `Are you sure you want to cancel this Gross Rent Certification for ${unit} - ${headOfHousehold}?`,
    ).then(() => handleCancelCertification());
  };

  const { handleCompleteCertification, completing } = useCompleteCertification(
    affordableQualificationId,
    selectedProperty,
    promptToaster,
    handleRemoveRow,
  );

  useEffect(() => {
    if (uploadedDocument) {
      return setSigned59A(uploadedDocument);
    }
  }, [uploadedDocument]);

  useEffect(() => {
    if (editedGRCert) {
      updateRow(JSON.parse(editedGRCert));
    }
    if (generated59aUpdate) {
      updateRow({ ...grCert, generated59a: generated59aUpdate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedGRCert, generated59aUpdate]);

  const editCertInfo = {
    ...parseEditGRCertValues(grCert),
    handleEditGRCert,
    submitting: editing,
  };

  const textRedForMissingCorrection =
    isCorrection === 'Yes' && !correction ? 'text-red' : '';
  const correctionText = textRedForMissingCorrection
    ? `${isCorrection}**`
    : isCorrection;

  const disableGenerate59 =
    !priorCertificationDate ||
    !!textRedForMissingCorrection ||
    !!priorActiveCert;

  const link =
    occupiedOnNTV === 'Prior'
      ? `/prior-resident/${residentId}`
      : `/resident/${residentId}`;

  const { subsidy, residentRent, utilityReimbursement } =
    parseNewAssistanceDetails(grCert);
  const textRedForMissingPrevCert = missingPrevValuesTextRedCheck(grCert);

  return (
    <Row>
      <Data>{unit}</Data>
      <Data>{floorPlanName}</Data>
      <Data>
        {headOfHousehold && residentId ? (
          <Link to={getUrlWithSelectedPropertyId(link)}>{headOfHousehold}</Link>
        ) : (
          headOfHousehold
        )}
      </Data>
      <Data>{formatMoney(newGrossRent)}</Data>
      <Data>{formatMoney(newHUDUA)}</Data>
      <Data>{formatDate(grEffectiveDate)}</Data>
      <Data>{formatDate(anticipatedVoucherDate)}</Data>
      <Data className={!priorCertificationDate ? 'text-red' : ''}>
        {!priorCertificationDate ? '***' : formatDate(priorCertificationDate)}
      </Data>
      <Data>{otherCertInProgress}</Data>
      <Data>{occupiedOnNTV}</Data>
      <Data className={textRedForMissingPrevCert}>{subsidy}</Data>
      <Data className={textRedForMissingPrevCert}>{residentRent}</Data>
      <Data className={textRedForMissingPrevCert}>{utilityReimbursement}</Data>
      <Data className={textRedForMissingPrevCert}>
        {!priorCertificationDate ? '***' : residentSignatureRequired}
      </Data>
      <Data className={textRedForMissingCorrection}>{correctionText}</Data>
      <Data>{generated59a ? 'Yes' : '---'}</Data>
      <Data>
        {!signed59AId ? (
          '---'
        ) : (
          <a className="btn-text" onClick={handleDownloadDocument}>
            {formatDate(signed59A.createdAt)}
          </a>
        )}
      </Data>
      <Data>
        {generating59A ? (
          <Spinner small />
        ) : (
          <button
            className="btn btn-sm btn-default"
            onClick={() => handleGenerate59a()}
            disabled={disableGenerate59}
          >
            <i className="et-download" />
          </button>
        )}
      </Data>
      <Data className="dropdown">
        {canceling || completing ? (
          <Spinner small />
        ) : (
          <ActionsMenu
            openUploadDocumentModal={openUploadDocumentModal(
              handleUploadSigned59a,
            )}
            openEditGRCertModal={openEditGRCertModal(editCertInfo)}
            handleCancelCertification={confirmCancelCert}
            handleCompleteCertification={handleCompleteCertification}
            uploadedSigned59A={!!signed59AId}
            hasGenerated59a={generated59a}
            disableGenerate59={disableGenerate59}
          />
        )}
      </Data>
    </Row>
  );
};

export default GRCertRow;
