import React from 'react';
import { Row, Col } from 'react-bootstrap';
import type { Props } from './types';

export const CompleteRenewalHeader = ({
  handleCancel,
  isCommercial,
  unitNumber,
}: Props) => {
  return (
    <>
      <Row>
        <Col xs={6} sm={12}>
          <a className="btn-text" onClick={handleCancel}>
            <i className="et-chevron-left" />
            Go Back to {isCommercial ? 'Tenant' : 'Resident'} profile
          </a>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12}>
          <h1>
            Are you sure you want to complete the Lease Renewal Process for this{' '}
            {isCommercial ? 'Tenant' : 'Resident'}?
          </h1>
          <h4>Unit Number: {unitNumber}</h4>
          <p>Please review and confirm before you continue.</p>
        </Col>
      </Row>
    </>
  );
};
