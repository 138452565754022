import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as reduxForm from 'redux-form';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import * as R from 'ramda';
import * as constants from './constants';
import messages from '../messages';
import ContactForm from './ContactForm';
import SubmitButton from '../../../components/SubmitButton';
import ConfirmOverlay from '../../../components/ConfirmOverlay';
import * as tenantActions from '../actions';

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButtonMargins = styled.span`
  & button {
    margin: 0 10px;
  }
`;

const TrashRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  & i {
    font-size: 20px;
  }
`;

const getInitValuesFromContact = R.pick([
  'commercialContactTypeId',
  'contactName',
  'businessName',
  'phoneNumber',
  'emailAddress',
  'note',
  'contactAddress',
  'contactAddress2',
  'city',
  'stateId',
  'zipCode',
  'businessPhoneExtension',
  'secondaryPhoneNumber',
]);

function ContactModal({
  contact,
  onHide,
  show,
  organizationId,
  householdId,
  submit,
  form,
  createContact,
  refreshContacts,
  editContact,
  deleteContact,
}) {
  const [showConfirmDeleteOverlay, setShowConfirmDeleteOverlay] = useState(
    false,
  );
  const isAddForm = R.isNil(contact);
  const headerMessage = isAddForm ? messages.addContact : messages.editContact;

  const handleHide = () => {
    onHide();
    setShowConfirmDeleteOverlay(false);
  };

  const handleDeleteReject = () => {
    setShowConfirmDeleteOverlay(false);
  };

  const handleDeleteConfirm = () => {
    deleteContact({
      contactId: contact.id,
      householdId,
      refreshContacts,
    });
    handleHide();
    setShowConfirmDeleteOverlay(false);
  };

  const handleTrashIconClick = () => {
    setShowConfirmDeleteOverlay(true);
  };

  const isSubmitDisabled = () => {
    return R.prop('syncErrors', form) || showConfirmDeleteOverlay;
  };

  const handleSubmit = (values) => {
    if (isAddForm)
      createContact({ contact: values, householdId, refreshContacts });
    else
      editContact({
        contactId: contact.id,
        contact: values,
        householdId,
        refreshContacts,
      });

    handleHide();
  };

  const handleSubmitButtonClick = () => {
    submit(constants.FORM_NAME);
  };

  const initialValues = useMemo(
    () => (isAddForm ? undefined : getInitValuesFromContact(contact)),
    [contact, isAddForm],
  );

  return (
    <Modal show={show} onHide={handleHide} bsSize="large" autoFocus>
      <Modal.Header style={{ paddingBottom: 0 }} closeButton>
        <i className="icon et-notes" />
        <h1>
          <FormattedMessage {...headerMessage} />
        </h1>
        <p style={{ marginLeft: '1%', fontWeight: 400 }}>
          * <FormattedMessage {...messages.requiredField} />
        </p>
      </Modal.Header>
      <Modal.Body>
        {showConfirmDeleteOverlay && (
          <ConfirmOverlay
            title={
              <FormattedMessage {...messages.contactDeleteConfirmationTitle} />
            }
            rejectMessage={<FormattedMessage {...messages.no} />}
            confirmMessage={<FormattedMessage {...messages.yes} />}
            onReject={handleDeleteReject}
            onConfirm={handleDeleteConfirm}
          />
        )}
        {!isAddForm && (
          <TrashRow>
            <a>
              <i className="icon et-trash" onClick={handleTrashIconClick} />
            </a>
          </TrashRow>
        )}
        <ContactForm
          initialValues={initialValues}
          organizationId={organizationId}
          onSubmit={handleSubmit}
        />
      </Modal.Body>
      <Modal.Footer>
        <FooterContainer>
          <Button type="button" bsStyle="default" onClick={handleHide}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <SubmitButtonMargins>
            <SubmitButton
              bsStyle="primary"
              clickHandler={handleSubmitButtonClick}
              disabled={isSubmitDisabled()}
              isSubmitting={false}
            >
              <FormattedMessage {...messages.save} />
            </SubmitButton>
          </SubmitButtonMargins>
        </FooterContainer>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = ({ form }) => ({
  form: form[constants.FORM_NAME],
});

const mapDispatchToProps = {
  submit: reduxForm.submit,
  createContact: tenantActions.createContact,
  editContact: tenantActions.editContact,
  deleteContact: tenantActions.deleteContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);
