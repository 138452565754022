import React, { useEffect } from 'react';
import Styled from 'styled-components';
import { reduxForm } from 'redux-form';
import { Table } from 'react-bootstrap';
import ColumnHeader from '../../../components/ColumnHeader';
import Data from '../../../components/Table/Data';
import Spinner from '../../../components/Spinner';
import FloorplanDetail from './FloorplanDetail';

import messages from './messages';
import validate from './validate';
import { connect } from 'react-redux';
import { reduceFormValues } from './utils';
import { flatten, isEmpty, isNil, uniq } from 'ramda';

const StyledHudForm = Styled.form`
  table.hud-limits-form-table {
    td > div.form-group {
      &,
      &.has-error > .help-block,
      &.has-error {
        margin-bottom: 0;
      }

      & > .help-block:empty {
        margin: 0;
      }

      &.form-group.has-error {
        max-width: 140px;
      }

      .right-calendar .rdtPicker {
        right: 0;
      }

      .rdt {
        min-width: 120px;
      }
    }

    & > tbody > tr:not(:first-child) > td {
      border: none;
    }

    & > tbody > tr > td:first-child,
    & > tbody > tr > td:nth-child(2),
    & > thead > tr > tr th:first-child,
    & > thead > tr > tr th:nth-child(2) {
      min-width: 100px;
    }
  }
`;

const submit = (values, dispatch, props) => {
  const { onFormSubmit, floorPlans } = props;
  const reducedFormValues = reduceFormValues(values, floorPlans);
  onFormSubmit(reducedFormValues);
};

const onChange = (values, dispatch, props) => {
  const {
    formState: { setIsEmptyForm },
  } = props;
  const formValues = Object.values(values).map((floorPlan) =>
    Object.values(floorPlan),
  );
  const flat = flatten(formValues);
  setIsEmptyForm(
    uniq(flat).filter(
      (fieldValue) => !isNil(fieldValue) && !isEmpty(fieldValue),
    ).length === 0,
  );
};

const HudLimitsForm = ({
  intl,
  floorPlans,
  isLoading,
  initialValues,
  handleSubmit,
  onFormSubmit,
  reset,
  submitting,
  valid,
  formState,
}: Object) => {
  useEffect(() => {
    const { setIsValidForm, setIsFormSubmitting } = formState;
    setIsValidForm(valid);
    setIsFormSubmitting(submitting);
  }, [submitting, valid]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <StyledHudForm onSubmit={handleSubmit}>
      <Table className="hud-limits-form-table">
        <thead>
          <tr>
            <ColumnHeader
              columnLabel={[intl.formatMessage(messages.floorplan)]}
            />
            <ColumnHeader
              columnLabel={[intl.formatMessage(messages.marketingName)]}
            />
            <ColumnHeader columnLabel={[intl.formatMessage(messages.rent)]} />
            <ColumnHeader
              columnLabel={[intl.formatMessage(messages.newHudGrossRent)]}
            />
            <ColumnHeader
              columnLabel={[intl.formatMessage(messages.startDate)]}
            />
            <ColumnHeader
              columnLabel={[intl.formatMessage(messages.hudUtilityAllowance)]}
            />
            <ColumnHeader
              columnLabel={[
                intl.formatMessage(messages.newHudUtilityAllowance),
              ]}
            />
            <ColumnHeader
              columnLabel={[intl.formatMessage(messages.startDate)]}
            />
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <Data colSpan={8}>
                <Spinner />
              </Data>
            </tr>
          ) : (
            floorPlans.map((floorPlan) => {
              return <FloorplanDetail floorPlan={floorPlan} intl={intl} />;
            })
          )}
        </tbody>
      </Table>
    </StyledHudForm>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { initialValues } = ownProps;
  return {
    initialValues,
    onSubmit: submit,
    onChange: onChange,
  };
};

// $FlowFixMe
export default connect(mapStateToProps)(
  reduxForm({
    form: 'hudLimitsForm',
    enableReinitialize: true,
    touchOnChange: true,
    validate,
  })(HudLimitsForm),
);
