import {
  compose,
  equals,
  keys,
  length,
  defaultTo,
  head,
  mergeAll,
  reject,
  values,
  mergeDeepLeft,
} from 'ramda';
/* eslint-disable max-len */
import warnApplicantsEmployerSection from '../../../components/LeaseApplicantFormCommonSections/ApplicantsEmployerSection/warn';
import warnStudentInformation from '../../../components/LeaseAffordableApplicantFormCommonSections/StudentInformationSection/warn';
import warnEmploymentInformation from '../../../components/LeaseAffordableApplicantFormCommonSections/EmploymentInformationSection/warn';
import warnSignatureSection from '../../../components/LeaseApplicantFormCommonSections/SignatureSection/warn';
/* eslint-enable max-len */

import validateProgress from './validateProgress';

const validateComplete = (
  formValues: any,
  { intl, petTypesList, employmentStatuses, studentsTypes }: Object,
) => {
  const employmentInformation = warnEmploymentInformation(
    formValues.employmentInformation,
    { intl },
  );

  const studentInformation = warnStudentInformation(
    formValues.studentInformation,
    { intl, studentsTypes },
  );

  const applicantsCurrentEmployer = warnApplicantsEmployerSection(
    formValues.applicantsCurrentEmployer,
    formValues.employmentInformation.status,
    { intl, employmentStatuses },
  );
  const applicantsSecondCurrentEmployer = warnApplicantsEmployerSection(
    formValues.applicantsSecondCurrentEmployer,
    '',
    { intl, employmentStatuses },
  );
  const applicantsPreviousEmployer = warnApplicantsEmployerSection(
    formValues.applicantsPreviousEmployer,
    '',
    { intl, employmentStatuses },
  );

  const signature = warnSignatureSection(formValues.signature, { intl });
  const arrayOfErrors: any = [
    { applicantsCurrentEmployer },
    { applicantsSecondCurrentEmployer },
    { applicantsPreviousEmployer },
    { studentInformation },
    { employmentInformation },
    { signature },
  ];
  const hasError = compose(
    equals(0),
    length,
    keys,
    defaultTo({}),
    head,
    values,
  );

  const errorsComplete = mergeAll(reject(hasError, arrayOfErrors));

  const errorsProgress = validateProgress(formValues, { intl });

  const errors = mergeDeepLeft(errorsComplete, errorsProgress);

  return errors;
};

export default validateComplete;
