import { defineMessages } from 'react-intl';

const messages = defineMessages({
  petFeesAndRentLabel: {
    id: 'App.LeaseDataTabForm.PetFeesAndRent.petFeesAndRentLabel',
    defaultMessage: 'Pet Fees and Rent',
  },
  feeAmount: {
    id: 'App.LeaseDataTabForm.PetFeesAndRent.feeAmount',
    defaultMessage: 'Fee Amount',
  },
});

export default messages;
