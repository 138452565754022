import DOMPurify from 'dompurify';
import FileSaver from 'file-saver';
import { toastr } from 'react-redux-toastr';
import { apiURL, requestToDownload } from '../../utils/api';

export const handlePreview = async ({
  documentContent,
  footerContent,
  organizationId,
}) => {
  try {
    toastr.info('Pending', 'Generating preview...');

    const file = await requestToDownload(
      `${apiURL}/${organizationId}/centralized-documents/generate-preview`,
      {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('session_id'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: DOMPurify.sanitize(documentContent),
          footerContent: DOMPurify.sanitize(footerContent),
        }),
      },
    );

    FileSaver.saveAs(file, 'preview.pdf');
  } catch (error) {
    let message = typeof error === 'string' ? error : error.toString();

    if (message.includes('[object Object]')) {
      message = 'Failed to generate preview.';
      console.error(error); // eslint-disable-line
    }

    toastr.error('Error', message);
  }
};
