import React from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import messages from './messages';
import { FormattedMessage } from 'react-intl';

type Props = {
  onHide: Function,
  onSubmit: Function,
  show: boolean,
};

const MissingTransactionCodeModal = (props: Props) => {
  const { onHide, show } = props;
  return (
    <div className="static-modal">
      <Modal
        bsSize="small"
        dialogClassName="modal-dialog modal-alert"
        show={show}
        onHide={onHide}
        autoFocus={true}
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <h2 className="text-center">
                <FormattedMessage {...messages.modalHeader} />
              </h2>
              <Row className="padtop20">
                <p className="text-center">
                  <FormattedMessage {...messages.messageLine1} />
                </p>
                <p className="text-center">
                  <FormattedMessage {...messages.messageLine2} />
                </p>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={12} className="text-center">
              <Button bsStyle="primary" onClick={onHide}>
                <FormattedMessage {...messages.close} />
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MissingTransactionCodeModal;
