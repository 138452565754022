import { React } from 'react';
import {
  Typography,
  Stack,
  Button,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';
import { HelpIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';
import messages from './messages';
import { FormattedMessage } from 'react-intl';

export const MissingSmartPricesModal = ({ show, onClose, intl }) => (
  <Modal
    open={show}
    onClose={onClose}
    icon={<HelpIcon sx={{ fontSize: '30px' }} />}
    title={intl.formatMessage(messages.title)}
    ModalProps={{
      PaperProps: {
        sx: { maxWidth: '500px' },
      },
    }}
    actions={
      <>
        <Button variant="text" onClick={onClose}>
          {intl.formatMessage(messages.gotIt)}
        </Button>
      </>
    }
  >
    <Grid container>
      <Stack spacing={2.5}>
        <Typography variant={'body2'}>
          <FormattedMessage {...messages.whyTitle} />
        </Typography>
        <Typography>
          <FormattedMessage {...messages.whyContent} />
        </Typography>
        <Typography variant={'body2'}>
          <FormattedMessage {...messages.becauseTitle} />
        </Typography>
        <Stack direction={'row'} spacing={1.5}>
          <Typography>•</Typography>
          <Typography>
            <FormattedMessage {...messages.becauseContent1} />
          </Typography>
        </Stack>
        <Stack direction={'row'} spacing={1.5}>
          <Typography>•</Typography>
          <Typography>
            <FormattedMessage {...messages.becauseContent2} />
          </Typography>
        </Stack>
        <Stack direction={'row'} spacing={1.5}>
          <Typography>•</Typography>
          <Typography>
            <FormattedMessage {...messages.becauseContent3} />
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  </Modal>
);
