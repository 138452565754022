import { useState } from 'react';
import moment from 'moment';

import { Button } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Modal } from '@fortress-technology-solutions/fortress-component-library/Molecules_Fortress';

import CreateCentralizedReportForm from '../CreateCentralizedReportForm';

const CreateCentralizedReportModal = ({
  isOpen,
  closeModal,
  createReport,
  reportName,
}) => {
  const [dateRangeStart, setDateRangeStart] = useState(null);
  const [dateRangeEnd, setDateRangeEnd] = useState(null);
  const [year, setYear] = useState(null);
  const variant = reportName === 'MN CRP Export' ? 'year' : 'date-range';
  const shouldDisableCreate =
    (variant === 'date-range' && !dateRangeStart) ||
    (variant === 'year' && !year);
  function handleCreateReport() {
    createReport({
      dateRangeStart,
      dateRangeEnd: dateRangeEnd || moment(),
      year,
      variant,
    });
    resetForm();
  }
  function handleCancel() {
    resetForm();
  }
  function resetForm() {
    setDateRangeStart(null);
    setDateRangeEnd(null);
    setYear(null);
    closeModal();
  }
  const actions = (
    <>
      <Button variant="text" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        variant="primary"
        onClick={handleCreateReport}
        disabled={shouldDisableCreate}
      >
        Create
      </Button>
    </>
  );

  return (
    <>
      <Modal
        actions={actions}
        open={isOpen}
        onClose={resetForm}
        title={`Create ${reportName} Report`}
      >
        <CreateCentralizedReportForm
          dateRangeStart={dateRangeStart}
          dateRangeEnd={dateRangeEnd}
          year={year}
          setDateRangeStart={setDateRangeStart}
          setDateRangeEnd={setDateRangeEnd}
          setYear={setYear}
          variant={variant}
        />
      </Modal>
    </>
  );
};

export default CreateCentralizedReportModal;
