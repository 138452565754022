import { pathOr } from 'ramda';
const request = require('../utils/api').default;
const { apiURL } = require('../utils/api');

export class LoggerService {
  log(
    { stack, message, userId, action }: Object,
    propertyId: string,
    orgId: string
  ) {
    const body = {
      message: pathOr('', ['componentStack'], message),
      stack: stack.message,
      userId,
      action
    };
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    };
    return request(`${apiURL}/${orgId}/${propertyId}/log`, options);
  }
}
