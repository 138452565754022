import {
  validateRange,
  validateRequired,
  validateZipCode,
} from '../../../../../utils/validations';
import messages from '../messages';

const validate = (values: Object = {}, { intl, flags }: Object) => {
  const errors = {};

  const { unitFloorLevel, zipCode } = values;

  const { removeUnitFloorLevelRestrictions } = flags;

  ['streetAddress1', 'city', 'state'].forEach((fieldName) => {
    if (!validateRequired(fieldName, values)) {
      errors[fieldName] = intl.formatMessage(messages.required);
    }
  });

  if (
    !removeUnitFloorLevelRestrictions &&
    unitFloorLevel &&
    !validateRange(unitFloorLevel, 1, 25)
  ) {
    errors.unitFloorLevel = intl.formatMessage(messages.outOfRangeFloorLevel);
  }

  if (
    (zipCode && !validateZipCode(zipCode)) ||
    !validateRequired('zipCode', values)
  ) {
    errors.zipCode = intl.formatMessage(messages.invalidZipCode);
  }

  return errors;
};

export default validate;
