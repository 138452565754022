import { useMemo, Fragment, useContext, useState, useEffect } from 'react';
import * as R from 'ramda';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import Card, { CardBody } from '../../../components/Card';
import IconLink from '../../../components/IconLink';
import Checkbox from '../../../components/Checkbox';
import Spacer from '../../../components/Spacer';
import Spinner from '../../../components/Spinner';
import confirm from '../../../components/ConfirmDialogModal';
import LimitsVsActualsGraph from './LimitsVsActualsGraph';
import NoLimitsGraph from './NoLimitsGraph';
import type { GraphData } from './types';
import { PageContext } from '../context';
import messages from '../messages';
import LimitDataByYear from './LimitDataByYear';
import SelectedLimit from './SelectedLimit';
import LeaseExpirationLimitService from '../../../services/leaseExpirationLimitService';
import {
  generateLimitsVsActualsGraphProps,
  generateNoLimitsGraphProps,
} from './utils';

const generateMonthOptions = (years: number[]) => {
  const generateOptions = (year: number) =>
    R.range(0, 12).map((m) => ({
      value: `${m}-${year}`,
      text: `${moment().month(m).format('MMMM')} ${year}`,
    }));

  return R.flatten(years.map((year: number) => generateOptions(year)));
};

type Props = {
  years: number[],
  selectedYears: number[],
  onYearClick: (number, boolean) => void,
  graphData: GraphData,
  isDataReady: boolean,
  selectedMonth: string,
  onSelectedMonthChange: Function,
  shouldShowCloseEditLimitsConfirm: boolean,
};

function LimitsVsActualsCard({
  graphData,
  years,
  selectedYears,
  onYearClick,
  isDataReady,
  selectedMonth,
  onSelectedMonthChange,
  shouldShowCloseEditLimitsConfirm,
}: Props) {
  const {
    formatMessage,
    organizationId,
    propertyId,
    onEditLimitsSuccess,
    canEdit,
  } = useContext(PageContext);
  const [isSavingSelectedLimit, setIsSavingSelectedLimit] = useState(false);
  const monthOptions = useMemo(
    () => generateMonthOptions(selectedYears),
    [selectedYears],
  );
  const [selectedLimitMonth, setSelectedLimitMonth] = useState(() => {
    const today = moment();
    const month = today.month();
    const year = today.year();

    const currentMonthOption = monthOptions?.find(
      (monthOption) => monthOption.value === `${month}-${year}`,
    );

    return currentMonthOption?.value ?? monthOptions?.[0]?.value;
  });

  const handleSelectedLimitMonthChange = (e) => {
    setSelectedLimitMonth(e.target.value);
  };
  const { firstYear, lastYear } = useMemo(() => {
    return {
      firstYear: R.head(years),
      lastYear: R.last(years),
    };
  }, [years]);
  const [isLimitDataByYearShown, setIsLimitDataByYearShown] = useState(false);

  const graphProps = useMemo(() => {
    if (!isDataReady) return {};

    return graphData.noLimits
      ? generateNoLimitsGraphProps(graphData, selectedYears)
      : generateLimitsVsActualsGraphProps(graphData, selectedYears);
  }, [graphData, selectedYears, isDataReady]);
  const leaseCounts = useMemo(() => {
    if (!isDataReady) return [];

    return graphData.noLimits
      ? graphProps.leasesData
      : graphProps.actualsData.map((c, i) => c + graphProps.overLimitData[i]);
  }, [isDataReady, graphProps, graphData]);

  const renderCheckboxes = () => {
    return years.map((y: number, i: number) => {
      const useSpacer = i + 1 !== years.length;
      return (
        <Fragment key={y}>
          <Checkbox
            label={y}
            checked={selectedYears.includes(y)}
            onChange={(v) => onYearClick(y, v)}
          />
          {useSpacer && <Spacer h={10} />}
        </Fragment>
      );
    });
  };

  const handleLimitDataByYearBtnClick = () => {
    if (isLimitDataByYearShown && shouldShowCloseEditLimitsConfirm)
      confirm(formatMessage(messages.closeConfirmTitle)).then(() =>
        setIsLimitDataByYearShown(false),
      );
    else setIsLimitDataByYearShown(!isLimitDataByYearShown);
  };

  const handleSelectedLimitSave = async (
    year: number,
    month: number,
    newLimit: number,
  ) => {
    try {
      const service = new LeaseExpirationLimitService();

      setIsSavingSelectedLimit(true);

      const update = {
        id: graphData[year][month].limitId,
        limit: newLimit,
      };

      await service.editLimits(organizationId, propertyId, [update]);

      onEditLimitsSuccess?.();
    } catch {
      toastr.error(
        formatMessage(messages.error),
        formatMessage(messages.editSingleLimitError),
      );
    } finally {
      setIsSavingSelectedLimit(false);
    }
  };

  const handleGraphColumnClick = (elements) => {
    const index = elements[0]?._index;

    if (index === undefined) return;

    const [month, year] = graphProps.labels[index];
    setSelectedLimitMonth(`${moment(month, 'MMM').month()}-${year}`);
  };

  useEffect(() => {
    const selectedOption = monthOptions.find(
      (opt) => opt.value === selectedLimitMonth,
    );
    if (selectedOption === undefined)
      setSelectedLimitMonth(monthOptions?.[0]?.value);
  }, [monthOptions, selectedLimitMonth]);

  return (
    <Card style={{ paddingTop: '2%' }}>
      <CardBody>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <h2 style={{ marginBottom: 2 }}>
              {formatMessage(messages.cardHeader)}
            </h2>
            <span style={{ color: '#9A9A9A' }}>
              {firstYear} - {lastYear}
            </span>
          </div>
          <div>
            <b>{formatMessage(messages.yearsVisibleLabel)}: </b>
            <div style={{ display: 'flex' }}>{renderCheckboxes()}</div>
          </div>
        </div>
        {!isDataReady ? (
          <Spinner />
        ) : (
          <>
            <div>
              {graphData.noLimits ? (
                <NoLimitsGraph
                  {...graphProps}
                  onColumnClick={handleGraphColumnClick}
                />
              ) : (
                <LimitsVsActualsGraph
                  {...graphProps}
                  onColumnClick={handleGraphColumnClick}
                />
              )}
            </div>
            <Spacer v={30} />
            <div
              style={{
                display: 'flex',
                justifyContent: graphData?.noLimits
                  ? 'flex-end'
                  : 'space-between',
                alignItems: 'center',
              }}
            >
              {isDataReady && selectedLimitMonth && !graphData.noLimits && (
                <SelectedLimit
                  leaseCounts={leaseCounts}
                  limits={graphProps.limitData}
                  selectedYears={selectedYears}
                  unitCount={graphProps.unitCount}
                  onSave={handleSelectedLimitSave}
                  isSaving={isSavingSelectedLimit}
                  monthOptions={monthOptions}
                  selectedLimitMonth={selectedLimitMonth}
                  onSelectedLimitMonthChange={handleSelectedLimitMonthChange}
                  labels={graphProps.labels}
                />
              )}
              {canEdit ? (
                <IconLink
                  iconClass={isLimitDataByYearShown ? 'et-delete' : 'et-pencil'}
                  text={formatMessage(
                    isLimitDataByYearShown
                      ? messages.closeLimitDataByYear
                      : messages.editLimitDataByYear,
                  )}
                  onClick={handleLimitDataByYearBtnClick}
                />
              ) : (
                <IconLink
                  iconClass={isLimitDataByYearShown ? 'et-delete' : undefined}
                  text={formatMessage(
                    isLimitDataByYearShown
                      ? messages.closeLimitDataByYear
                      : messages.viewLimitDataByYear,
                  )}
                  onClick={handleLimitDataByYearBtnClick}
                />
              )}
            </div>
            <Spacer v={23} />
          </>
        )}
        {isLimitDataByYearShown && (
          <div>
            <Spacer v={32} />
            <LimitDataByYear />
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default LimitsVsActualsCard;
