import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import DashesIfNullOrUndefined from '../../../components/DashesIfNullOrUndefined';

const PRIOR_COLOR = '#A9A9A9';

type PeopleSectionProps = {
  householdMembers: Array<Object>,
  editHouseholdMember: Function,
  residentId?: string,
};

const PeopleSection = ({
  householdMembers,
  editHouseholdMember,
  residentId,
}: PeopleSectionProps) => {
  const renderCommercialApplicantHousehold = (
    <tbody>
      <tr>
        <th style={{ width: '60%' }}>
          <FormattedMessage {...messages.personName} />
        </th>
        <th>
          <FormattedMessage {...messages.personDba} />
        </th>
      </tr>
      {householdMembers.map((member) => (
        <tr key={member.id}>
          <td>
            <a onClick={() => editHouseholdMember(member)}>
              <DashesIfNullOrUndefined data={member.firstName} />
            </a>
          </td>
          <td>{member.preferredName}</td>
        </tr>
      ))}
    </tbody>
  );
  const renderCommercialResidentHousehold = (
    <tbody>
      <tr>
        <th>
          <FormattedMessage {...messages.personName} />
        </th>
        <th>
          <FormattedMessage {...messages.personDba} />
        </th>
      </tr>
      {householdMembers.map((occupant, i) => {
        const isPrior = occupant.status === 'Prior';
        const isPet = occupant.type === 'pet';
        let color = isPrior ? PRIOR_COLOR : null;
        color = occupant.status === 'Pending' ? 'red' : color;
        return (
          <tr key={i} style={{ color }}>
            <td>
              {' '}
              {color || occupant.status === 'Denied' || isPet ? (
                <span>{occupant.name}</span>
              ) : (
                <a onClick={() => editHouseholdMember(occupant.id)}>
                  <DashesIfNullOrUndefined data={occupant.firstName} />
                </a>
              )}
            </td>
            <td>{occupant.preferredName}</td>
          </tr>
        );
      })}
    </tbody>
  );
  return (
    <table className="table table-simple">
      {residentId
        ? renderCommercialResidentHousehold
        : renderCommercialApplicantHousehold}
    </table>
  );
};

export default PeopleSection;
