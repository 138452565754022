/* eslint-disable max-len */

import { get } from '../utils/api';

export default class HouseholdProfileService {
  getHouseholdProfileAffordableQualificationsByProgram(
    organizationId: string,
    propertyId: string,
    householdId: string,
    programId: string,
  ) {
    return get(
      `/${organizationId}/${propertyId}/household-profile/${householdId}/property-affordable-programs/${programId}/qualifications`,
    );
  }
}
