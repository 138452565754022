import { useEffect, useState } from 'react';
import { useLocalStorage } from '@fortress-technology-solutions/fortress-component-library/hooks';
import useFetchApplication from '../../hooks/data-fetching/useFetchApplication';

const useApplicantOrResidentToAssignUnit = ({ organizationId, propertyId }) => {
  const [applicantToAssignUnit, setApplicantToAssignUnit] = useState({});
  // this local state is set in applicant and resident profiles
  const { localStorageState: applicantOrResidentToAssignUnit } =
    useLocalStorage('previous-applicant-id', 'object');

  // fetching application becuase localstorage won't have the latest state
  const { data: application } = useFetchApplication({
    organizationId,
    propertyId,
    applicationId: applicantOrResidentToAssignUnit?.id,
  });

  useEffect(() => {
    if (applicantOrResidentToAssignUnit?.id) {
      setApplicantToAssignUnit({
        id: applicantOrResidentToAssignUnit?.id,
        ...(Object.keys(application ?? {}).length
          ? application
          : applicantOrResidentToAssignUnit),
      });

      localStorage.removeItem('previous-applicant-id');
    }
  }, [applicantOrResidentToAssignUnit, application]);

  return applicantToAssignUnit;
};

export default useApplicantOrResidentToAssignUnit;
