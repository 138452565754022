import { useEffect, useState, useMemo, useCallback } from 'react';
import * as R from 'ramda';
import type { FilterValues } from '../../ManageRentRoll/FilterControls/types';
import type { AppliedFilters } from '../../ManageRentRoll/types';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

import ActivityService from '../../services/activityService';

import type {
  ChangeEventHandler,
  ChangePayload,
} from '../containers/ManageRentRoll/FilterControls/types';

const FLIP_SORT_ORDER = { ASC: 'DESC', DESC: 'ASC' };
const SORT_FULL_TEXT = { ASC: 'ascending', DESC: 'descending' };

export const useActivityFilterOptions = ({ selectedProperty, prospectId }) => {
  const [activityFilterOptions, setActivityFilterOptions] = useState([]);
  const [activityFilterOptionsLoaded, setActivityFilterOptionsLoaded] =
    useState(true);

  const updateActivityFilterOptions = useCallback(() => {
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    const fetchActivityFilterOptions = async () => {
      setActivityFilterOptionsLoaded(false);

      const { id, organizationId } = selectedProperty;
      const activityService = new ActivityService();
      const activityFilterResults =
        await activityService.getActivityFilterOptions(
          prospectId,
          id,
          organizationId,
          options,
        );
      setActivityFilterOptions(activityFilterResults);
      setActivityFilterOptionsLoaded(true);
    };

    fetchActivityFilterOptions();

    return () => abortController.abort();
  }, [selectedProperty, prospectId]);

  useEffect(() => {
    updateActivityFilterOptions();
  }, [updateActivityFilterOptions]);

  return [
    activityFilterOptions,
    activityFilterOptionsLoaded,
    updateActivityFilterOptions,
  ];
};

export const useActivityFilters = ({ activityFilterOptions }) => {
  const DEFAULT_FILTERS = {
    exactDate: null,
    toDate: null,
    fromDate: null,
    types: [],
    statuses: [],
  };
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const handleFilterControlsChange: ChangeEventHandler = (
    key: string,
    payload: ChangePayload,
  ) => {
    if (payload.type === 'multi-select') {
      setFilters({
        ...filters,
        [key]: payload.values,
      });
    }
    if (payload.type === 'date') {
      setFilters({
        ...filters,
        [key]: payload.value,
      });
    }
  };

  const clearActivityFilters = () => {
    setFilters(DEFAULT_FILTERS);
  };

  const clearDateRange = () => {
    setFilters({
      ...filters,
      toDate: undefined,
      fromDate: undefined,
    });
  };

  const clearExactDate = () => {
    setFilters({
      ...filters,
      exactDate: undefined,
    });
  };

  const isFilters =
    filters.exactDate ||
    filters.fromDate ||
    filters.toDate ||
    filters.statuses.length > 0 ||
    filters.types.length > 0;

  useEffect(() => {
    const activityFilterOptionIds = activityFilterOptions.types?.map(
      (type) => type.value,
    );

    // eslint-disable-next-line max-len
    if (
      filters.types.filter(
        (typeId) => !activityFilterOptionIds.includes(typeId),
      ).length > 0
    ) {
      setFilters({
        ...filters,
        types: filters.types.filter((typeId) =>
          activityFilterOptionIds.includes(typeId),
        ),
      });
    }
  }, [activityFilterOptions, filters]);

  return {
    filters,
    handleFilterControlsChange,
    clearActivityFilters,
    clearDateRange,
    clearExactDate,
    isFilters,
  };
};

export const useFetchActivities = ({
  selectedProperty,
  prospectId,
  filters,
  updateActivityFilterOptions,
  isFilters,
  shouldUpdate,
}) => {
  // activities variables
  const [refresh, setRefresh] = useState(false);
  const [activities, setActivities] = useState({ results: [], meta: {} });
  const [activitiesLoaded, setActivitiesLoaded] = useState(true);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('dateTime');
  const [order, setOrder] = useState('DESC');
  const [columnOrder, setColumnOrder] = useState({ dateTime: 'descending' });

  // Gets all activities
  const mode = null;
  const limit = 10;

  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const options = { signal: abortController.signal };
    const fetchActivities = async () => {
      setActivitiesLoaded(false);
      const { id, organizationId } = selectedProperty;
      const activityService = new ActivityService();
      const activitiesResults = await activityService.getAllByProspect(
        prospectId,
        id,
        isFilters ? 1 : page,
        limit,
        sort,
        order,
        mode,
        organizationId,
        filters,
        options,
      );
      setActivities(activitiesResults);
      setActivitiesLoaded(true);

      // reload filter options anytime we reload activities
      updateActivityFilterOptions();
    };

    fetchActivities();

    return () => abortController.abort();
  }, [
    prospectId,
    selectedProperty,
    filters,
    order,
    columnOrder,
    page,
    limit,
    sort,
    refresh,
    shouldUpdate,
    isFilters,
    updateActivityFilterOptions,
  ]);

  const onSortOrder = (column) => {
    if (column === sort) {
      const newDir = FLIP_SORT_ORDER[order];
      setOrder(newDir);
      setColumnOrder({ [column]: SORT_FULL_TEXT[newDir] });
    } else {
      const newDir = 'DESC';
      setSort(column);
      setOrder(newDir);
      setColumnOrder({ [column]: SORT_FULL_TEXT[newDir] });
    }
  };

  return [
    activities,
    activitiesLoaded,
    page,
    limit,
    order,
    columnOrder,
    setPage,
    onSortOrder,
    () => setRefresh(!refresh),
  ];
};

export const useIsFilterActive = ({ allActivities, filters, isFilters }) => {
  const [isFilterActive, setIsFilterActive] = useState(false);

  useEffect(() => {
    if (allActivities?.results?.length < 1 && !isFilters) {
      setIsFilterActive(false);
    }
  }, [allActivities, filters, isFilters]);

  return {
    isFilterActive,
    setIsFilterActive,
  };
};

export const useAppliedFilters = (
  filters: FilterValues,
  handleFilterControlsChange: ChangeEventHandler,
  clearActivityFilters: Function,
  clearDateRange: Function,
  clearExactDate: function,
  activityFilterOptions: Object,
) => {
  const appliedFilters = useMemo(
    () => mapFilterValuesToAppliedFilters(filters, activityFilterOptions),
    [filters, activityFilterOptions],
  );

  const handleRemoveFilter = ({ meta }) => {
    if (meta.isDateRangeFilter) {
      clearDateRange();
      return;
    }

    if (meta.isExactDateFilter) {
      clearExactDate();
      return;
    }

    const { key, value } = meta;

    if (!filters[key]) return;

    const newFilters = filters[key].filter(
      (val) =>
        val !==
        activityFilterOptions[key].find(
          (filterOption) => filterOption.text === value,
        ).value,
    );

    handleFilterControlsChange(key, {
      type: 'multi-select',
      values: newFilters,
    });
  };

  const handleClearAll = () => {
    clearActivityFilters();
  };

  return {
    appliedFilters,
    handleRemoveFilter,
    handleClearAll,
  };
};

const toAppliedFilter = R.curryN(
  3,
  (key: string, textFn: Function, value: any) => ({
    id: uuid(),
    text: textFn?.(value) || value,
    meta: {
      key,
      value,
    },
  }),
);

const mapFilterValuesToAppliedFilters = (
  filterValues: FilterValues,
  options: string[],
): AppliedFilters => {
  if (!filterValues) return [];

  const preTypeFilters = filterValues.types.map((typeId) => {
    const typeObject = options.types.find((type) => type.value === typeId);
    return typeObject?.text;
  });
  const typeFilters = preTypeFilters.map(toAppliedFilter('types', R.identity));

  const preStatusFilters = filterValues.statuses.map((statusId) => {
    const statusObject = options.statuses.find(
      (status) => status.value === statusId,
    );
    return statusObject.text;
  });
  const statusFilters = preStatusFilters.map(
    toAppliedFilter('statuses', R.identity),
  );
  const dateRangeFilter = dateRangeToAppliedFilter(
    filterValues.fromDate,
    filterValues.toDate,
  );

  const exactDateFilter = exactDateToAppliedFilter(filterValues.exactDate);
  return [
    ...typeFilters,
    ...statusFilters,
    ...dateRangeFilter,
    ...exactDateFilter,
  ];
};

const exactDateToAppliedFilter = (exactDate: ?string) => {
  const DATE_FORMAT = 'MM/DD/YYYY';

  if (!exactDate) return [];

  return [
    {
      id: uuid(),
      text: `${moment(exactDate).format(DATE_FORMAT)}`,
      meta: { isExactDateFilter: true },
    },
  ];
};

const dateRangeToAppliedFilter = (fromDateStr: ?string, toDateStr: ?string) => {
  const DATE_FORMAT = 'MM/DD/YYYY';

  if (!fromDateStr || !toDateStr) return [];

  const from = moment(fromDateStr);
  const to = moment(toDateStr);

  if (!from.isValid() || !to.isValid() || from.isAfter(to, 'day')) return [];

  return [
    {
      id: uuid(),
      text: `${from.format(DATE_FORMAT)}-${to.format(DATE_FORMAT)}`,
      meta: { isDateRangeFilter: true },
    },
  ];
};
