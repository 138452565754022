import { get, post, del, put } from '../utils/api';

export default class RentableItemsService {
  async getPropertyRentableItem(organizationId, propertyId, id, options) {
    return await get(
      `/${organizationId}/${propertyId}/property-rentable-item/${id}`,
      options,
    );
  }

  async getAllPropertyRentableItems(organizationId, propertyId, options) {
    return await get(
      `/${organizationId}/${propertyId}/property-rentable-items`,
      options,
    );
  }

  async getAllPropertyFeesWithRentableItems(
    organizationId,
    propertyId,
    options,
  ) {
    return await get(
      `/${organizationId}/${propertyId}/property-fees-with-rentable-items`,
      options,
    );
  }

  async getHouseholdRentableItem(organizationId, propertyId, id, options) {
    return await get(
      `/${organizationId}/${propertyId}/household-rentable-item/${id}`,
      options,
    );
  }

  async getAllHouseholdRentableItems(
    organizationId,
    propertyId,
    householdId,
    options,
  ) {
    return await get(
      `/${organizationId}/${propertyId}/household-rentable-items/${householdId}`,
      options,
    );
  }

  async deleteHouseholdRentableItem(organizationId, propertyId, id, options) {
    return await del(
      `/${organizationId}/${propertyId}/household-rentable-item/${id}`,
      null,
      options,
    );
  }

  async createHouseholdRentableItem(
    organizationId,
    propertyId,
    payload,
    options,
  ) {
    return await post(
      `/${organizationId}/${propertyId}/household-rentable-item`,
      payload,
      options,
    );
  }

  async updateHouseholdRentableItem(
    organizationId,
    propertyId,
    id,
    payload,
    options,
  ) {
    return await put(
      `/${organizationId}/${propertyId}/household-rentable-item/${id}`,
      payload,
      options,
    );
  }
}
