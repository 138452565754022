import { defineMessages } from 'react-intl';

const messages = defineMessages({
  resetPassword: {
    id: 'App.ManageUsers.ActionsMenu.ResetPassword',
    defaultMessage: 'Reset Password',
  },
  confirmResetPassword: {
    id: 'App.ManageUsers.ActionsMenu.ConfirmResetPassword',
    defaultMessage:
      "Are you sure you want to reset this User's password?  Clicking Yes will send the User a password reset email.",
  },
});

export default messages;
