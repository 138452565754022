import { defineMessages } from 'react-intl';

const messages = defineMessages({
  header: {
    id: 'App.ProrateCalculator.Header.header',
    defaultMessage: 'Prorate Calculator',
  },
});

export default messages;
