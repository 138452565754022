import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.Login.Title',
    defaultMessage: 'Fortress - Login'
  },
  username: {
    id: 'App.Login.Username',
    defaultMessage: 'Username'
  },
  password: {
    id: 'App.Login.Password',
    defaultMessage: 'Password'
  },
  rememberUser: {
    id: 'App.Login.RememberUser',
    defaultMessage: 'Remember Username'
  },
  signIn: {
    id: 'App.Login.signIn',
    defaultMessage: 'Sign In'
  },
  header: {
    id: 'App.Login.Header',
    defaultMessage: 'Enter a New Password'
  },
  help: {
    id: 'App.Login.help',
    defaultMessage:
      'Having trouble signing in? Please contact your system administrator for assistance.'
  },
  forgotPassword: {
    id: 'App.Login.forgotPassword',
    defaultMessage: 'Forgot Password?'
  },
  changeUser: {
    id: 'App.Login.changeUser',
    defaultMessage: 'Not you? Change user'
  },
  welcomeBack: {
    id: 'App.Login.welcomeBack',
    defaultMessage: 'Welcome Back,'
  }
});

export default messages;
