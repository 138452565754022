import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { Row, FormGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import MinorRows from './MinorRows';
import generateNumberOfOptions from '../../../../utils/index.js';

import { renderSelectField } from '../../../../utils/redux-form-helper';

type ComponentProps = {
  intl: any,
  suffixes: Array<Object>
};

const selectOptions = generateNumberOfOptions(10);

const Minors = ({ intl, suffixes }: ComponentProps) => (
  <FormGroup className="applicant-subgrp">
    <Row>
      <div className="additional-occupants-head">
        <h3>
          <FormattedMessage {...messages.other} />
        </h3>
        <div className="head-subtitle">
          <FormattedMessage {...messages.minorsHowMany} />
        </div>
        <div className="head-selector">
          <Field
            name="minorsCount"
            component={renderSelectField}
            options={selectOptions}
            selectClassNames="input-lg"
          />
        </div>
      </div>
    </Row>
    <FieldArray
      name="minors"
      component={MinorRows}
      intl={intl}
      suffixes={suffixes}
    />
  </FormGroup>
);

export default Minors;
