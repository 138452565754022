import * as ActionTypes from './constants';
import type { Action } from '../App/types';
import type { EditRoleState } from './types';

const initialState = {
  role: {},
  permissions: [],
  selectedPermissions: [],
  roleTypes: [],
  nonePermissionsSelected: false,
  rolePermitsAssignees: false,
  isSubmitting: false,
};

const editRoleReducer = (
  state: EditRoleState = initialState,
  action: Action<*, *>,
): EditRoleState => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.GET_ROLE_SUCCESS: {
      return {
        ...state,
        role: payload,
        selectedPermissions: payload.permissions.map((p) => p.id),
      };
    }
    case ActionTypes.SET_SELECTED_PERMISSIONS: {
      return { ...state, selectedPermissions: payload };
    }
    case ActionTypes.NONE_PERMISSIONS_SELECTED: {
      return { ...state, nonePermissionsSelected: payload };
    }
    case ActionTypes.SHOW_ROLE_PERMIT_ASSIGNEED: {
      return { ...state, rolePermitsAssignees: payload };
    }
    case ActionTypes.EDIT_ROLE: {
      return {
        ...state,
        isSubmitting: true,
      };
    }
    case ActionTypes.EDIT_ROLE_SUCCESS:
    case ActionTypes.EDIT_ROLE_FAIL: {
      return {
        ...state,
        isSubmitting: false,
      };
    }
    default:
      return state;
  }
};

export default editRoleReducer;
