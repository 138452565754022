import type {
  AdditionalOccupant,
  FormProspect,
  PetOccupant,
  Prospect,
  ProspectPreferences,
  ProspectStatusType as ProspectStatus,
} from 'src/containers/ProspectProfile/types';
import { concat, isEmpty, isNil, map, omit, pickAll } from 'ramda';
import {
  NO_PREFERENCE_KEY,
  NO_PREFERENCE_TEXT,
} from '../containers/ProspectProfile/ProspectProfileForm/LookingFor/LookingFor.hook';

const setNullToEmpty = (x) => (isEmpty(x) && !Array.isArray(x) ? null : x);

export const isNBedsArrEmpty = (nBedsArr: string[]) =>
  Array.isArray(nBedsArr) && nBedsArr.length === 0;

export const nBedsArrIsEmptyArrayOrNull = (nBedsArr: string[]) =>
  isNBedsArrEmpty(nBedsArr) || isNil(nBedsArr);

export const nBedsExist = (nBeds: number) => !isNil(nBeds);

const isNoPreferenceSelectedForNumBeds = (nBedsArr: string[]) =>
  isNil(nBedsArr) ||
  (Array.isArray(nBedsArr) && nBedsArr[0] === NO_PREFERENCE_KEY);

const mapNBedsToDescription = (nBeds: string[]) => {
  const nBedsTextMap = {
    0: 'Studio',
    1: '1 Bedroom',
  };

  return nBeds?.map((nBed) => nBedsTextMap[nBed] ?? `${nBed} Bedrooms`);
};

export const formatNBedsArr = (nBedsArr: string[], nBeds: string): string => {
  if (nBedsArrIsEmptyArrayOrNull(nBedsArr) && nBedsExist(nBeds))
    return mapNBedsToDescription([nBeds]);

  if (isNoPreferenceSelectedForNumBeds(nBedsArr)) return NO_PREFERENCE_TEXT;

  return mapNBedsToDescription(nBedsArr?.sort())?.join(', ') || '---';
};

export const nBedsArrInitialValueBuilder = (
  nBedsArr: string[],
  nBeds: number,
) => {
  return nBedsArrIsEmptyArrayOrNull(nBedsArr) && nBedsExist(nBeds)
    ? [nBeds.toString()]
    : nBedsArr ?? [NO_PREFERENCE_KEY];
};

const getAdditionalOccupants = (
  prospect: FormProspect,
  relationships: Array<Object>,
): Array<AdditionalOccupant> => {
  const spouseRelationshipId = (relationships ?? []).find(
    (relationship) => relationship?.text === 'Partner',
  )?.value;
  const spouseAdults = prospect.adults
    ? prospect.adults.filter(
        (adult) => adult?.relationshipId === spouseRelationshipId,
      )
    : [];
  const otherAdults = prospect.adults
    ? prospect.adults.filter(
        (adult) => adult?.relationshipId !== spouseRelationshipId,
      )
    : [];
  const spouseOccupants = spouseAdults.map((spouse) => ({
    type: 'Spouse',
    ...spouse,
  }));

  const otherAdultsOccupants = otherAdults.map((other) => ({
    type: 'Other Adult',
    ...other,
  }));

  const kidsOccupants = prospect.minors
    ? prospect.minors.map((minor) => ({
        type: 'Minor',
        age: isEmpty(String(minor?.age)) ? null : parseInt(minor?.age, 10),
        ...omit(['age'], minor),
      }))
    : [];
  return concat(spouseOccupants, concat(kidsOccupants, otherAdultsOccupants));
};

const getPetOccupants = (prospect: FormProspect): Array<PetOccupant> => {
  return prospect.pets
    ? prospect.pets.map((pet) => ({
        petBreedId: pet.petBreedId === '' ? undefined : pet.petBreedId,
        ...pet,
      }))
    : [];
};

const getDefaultStatus = (prospectStatusList: ProspectStatus[]): string => {
  const listStatus = prospectStatusList.map((prospectStatus) => ({
    value: prospectStatus.id,
    isDefault: prospectStatus.isDefault,
  }));
  return listStatus.filter((s) => s.isDefault === true)[0].value;
};
export const getProspectPreferences = (
  prospect: FormProspect,
): ProspectPreferences => {
  const sectionProperties = [
    'helpText',
    'leaseTermInMonths',
    'moveInDateFrom',
    'moveInDateScheduled',
    'moveInDateTo',
    'nBaths',
    'nBeds',
    'nBedsArr',
    'nHalfBaths',
    'notWants',
    'preferredFloorPlanId',
    'priceFrom',
    'priceTo',
    'wants',
  ];

  let preferencesObject: ProspectPreferences = pickAll(
    sectionProperties,
    prospect,
  );

  preferencesObject = map(setNullToEmpty, preferencesObject);

  const hasUserChosenNoPreferenceForNumBeds =
    Array.isArray(preferencesObject.nBedsArr) &&
    preferencesObject.nBedsArr.includes(NO_PREFERENCE_KEY);

  return hasUserChosenNoPreferenceForNumBeds
    ? { ...preferencesObject, nBedsArr: null }
    : preferencesObject;
};

export const getProspectToSubmit = (props: {
  prospect: Prospect,
  propertyId: string,
  currentProspect: Prospect,
  prospectStatusList: ProspectStatus[],
  isPost?: boolean,
  relationships: any,
}) => {
  const {
    prospect,
    propertyId,
    currentProspect,
    prospectStatusList,
    isPost,
    relationships,
  } = props;

  let prospectToSubmit: Prospect = {
    ...omit(
      [
        'helpText',
        'kids',
        'kidsAmount',
        'kidsChecked',
        'leaseTermInMonths',
        'moveInDateFrom',
        'moveInDateTo',
        'moveInDateScheduled',
        'isUnitAssigned',
        'unitId',
        'nBaths',
        'nBeds',
        'nBedsArr',
        'nHalfBaths',
        'noMoveInDate',
        'notWants',
        'otherAdults',
        'otherAdultsAmount',
        'otherAdultsChecked',
        'pets',
        'petsAmount',
        'petsChecked',
        'preferredFloorPlanId',
        'priceFrom',
        'priceTo',
        'spouse',
        'spouseChecked',
        'wants',
      ].concat(isPost ? ['adults', 'minors', 'currentProspectStatus'] : []),
      prospect,
    ),
    referralTypeId: prospect.referralTypeId?.length
      ? prospect.referralTypeId
      : null,
    contactTypeId: prospect.contactTypeId?.length
      ? prospect.contactTypeId
      : null,
    additionalOccupants: getAdditionalOccupants(prospect, relationships),
    adultsCount:
      prospect.adultsCount === 'default' ? null : prospect.adultsCount,
    minorsCount:
      prospect.minorsCount === 'default' ? null : prospect.minorsCount,
    petsCount: prospect.petsCount === 'default' ? null : prospect.petsCount,
    petOccupants: getPetOccupants(prospect),
    propertyId,
    prospectPreferences: getProspectPreferences(prospect),
    prospectStatusId: prospectStatusList?.length
      ? getDefaultStatus(prospectStatusList)
      : undefined,
  };

  if (currentProspect) {
    const { id, prospectPreferences } = currentProspect;
    prospectToSubmit = {
      ...prospectToSubmit,
      id,
      prospectPreferences: {
        ...prospectToSubmit.prospectPreferences,
        id: prospectPreferences.id,
      },
    };
  }

  if (prospectToSubmit.assignedToId === '') {
    prospectToSubmit.assignedToId = null;
  }

  return prospectToSubmit;
};
