export const getComputedElementStyle = (
  element: HTMLElement,
  style: string,
  getNumber: boolean = false,
) => {
  let propertyValue = getComputedStyle(element).getPropertyValue(style);
  if (getNumber) {
    propertyValue = parseFloat(propertyValue.split('px')[0]);
  }
  return propertyValue;
};
