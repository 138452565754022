import moment from 'moment';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import Spacer from '../../../../components/Spacer';
import Spinner from '../../../../components/Spinner';
import { isToDateNotSameOrAfterFromDate } from '../../../../utils/date-helpers';
import {
  renderDateField,
  required,
  validDate,
} from '../../../../utils/redux-form-helper';
import messages from './messages';

const FullDiv = styled.div`
  background-color: #f8f8f8;
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
`;

export const validate = (values: Object, props: Object) => {
  const errors = {};

  if (
    isToDateNotSameOrAfterFromDate(
      props.insurance.policyStartDate,
      values.policyEndDate,
    )
  ) {
    errors.policyEndDate = props.intl.formatMessage(
      messages.endDateBeforeStartDateError,
      {
        policyStartDate: moment(props.insurance.policyStartDate).format(
          'MM/DD/YYYY',
        ),
      },
    );
  }

  return errors;
};

export const StopCoverageForm = reduxForm({
  form: 'StopCoverageForm',
  validate,
})(({ handleSubmit, onCancel, isLoading, intl, insurance, valid }) => {
  return (
    <FullDiv>
      <Spacer v={32} />
      <form onSubmit={handleSubmit}>
        <h2>
          {intl.formatMessage(messages.stopCoveragePrompt, insurance ?? {})}
        </h2>
        <Spacer v={25} />
        <h5>{intl.formatMessage(messages.stopCoverageMessage)}</h5>
        <Spacer v={30} />
        <div style={{ width: 250, margin: 'auto' }}>
          <Field
            name="policyEndDate"
            component={renderDateField}
            label={`${intl.formatMessage(messages.policyEndDate)}*`}
            placeholder="MM/DD/YYYY"
            className="modal-fixed-position"
            bsSize="lg"
            validate={[required, validDate]}
          />
        </div>
        <Spacer v={20} />
        <div>
          <Button onClick={onCancel} disabled={isLoading}>
            {intl.formatMessage(messages.cancel)}
          </Button>
          <Spacer h={30} inline />
          <Button
            bsStyle="primary"
            type="submit"
            disabled={!valid || isLoading}
          >
            {isLoading ? (
              <Spinner small />
            ) : (
              intl.formatMessage(messages.confirm)
            )}
          </Button>
        </div>
      </form>
    </FullDiv>
  );
});
