import { put, takeLatest } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { renderTranslatedMessage } from '../../utils/redux-form-helper';
import { VERIFY_TOKEN, RESET_PASSWORD } from './constants';
import {
  verifyTokenSuccess,
  verifyTokenFail,
  resetPasswordSuccess,
  resetPasswordFail
} from './actions';
import UserService from '../../services/userService';
import type { Action } from '../App/types';
import type { Token, ResetPassword } from './types';
import type { Saga } from 'redux-saga';
import messages from './messages';

export function* fetchVerifyToken(action: Action<Token>): Saga<void> {
  let response = 'no set response';
  try {
    const userService = new UserService();
    response = yield userService.verifyToken(action.payload);
    yield put(verifyTokenSuccess(response));
  } catch (err) {
    yield put(verifyTokenFail(err));
  }
}

export function* verifyTokenSaga(): Saga<void> {
  yield takeLatest(VERIFY_TOKEN, fetchVerifyToken);
}

export function* fetchResetPassword(action: Action<ResetPassword>): Saga<void> {
  try {
    const userService = new UserService();
    const response = yield userService.resetPassword(action.payload);
    yield put(resetPasswordSuccess(response));
    yield put(
      toastrActions.add({
        type: 'success',
        message: renderTranslatedMessage(messages.successMessage),
        title: renderTranslatedMessage(messages.successTitle),
        options: {
          showCloseButton: true,
          removeOnHover: true
        }
      })
    );
  } catch (err) {
    yield put(resetPasswordFail(err));
    yield put(
      toastrActions.add({
        type: 'error',
        message: err.toString(),
        title: renderTranslatedMessage(messages.failureTitle),
        options: {
          showCloseButton: true,
          removeOnHover: true
        }
      })
    );
  }
}

export function* resetPasswordSaga(): Saga<void> {
  yield takeLatest(RESET_PASSWORD, fetchResetPassword);
}

export default [verifyTokenSaga, resetPasswordSaga];
