import { useMemo, useEffect, useCallback, useRef, useState } from 'react';
import {
  Stack,
  Divider,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Link as RRLink, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import type { Props } from './types';
import { DOCUMENTS } from './constants';
import FortressLogoSrc from '../../img/Fortress-Logo@3x.png';

const ContentDiv = styled.div`
  & li::marker {
    color: #9a9a9a;
    font-size: 26px;
  }
  & li li::marker {
    color: #b4b4b4;
  }
  & li ul {
    list-style-type: disc;
  }
  width: 100%;
  padding: 50px 100px ${({ paddingBottom = '0px' }) => paddingBottom} 50px;
  max-height: 100%;
  overflow-y: auto;
  overscroll-behavior-y: contain;
`;

const linkStyles = css`
  color: #${({ isSelected }) => (isSelected ? '2B91AB' : '000')};
  font-size: ${({ fontSize }) => fontSize}px;
  &:hover,
  &:focus {
    color: #4d73ff;
    text-decoration: none;
  }
`;

const Link = styled(RRLink)`
  ${linkStyles}
`;
const IndexLink = styled.a`
  ${linkStyles}
`;

const CONTENT_DIV_ID = 'content-div';

function LegalDocumentPageLayout({
  children = null,
  links,
  location: { hash: selectedLink, pathname },
  history,
}: Props) {
  const mainDivRef = useRef(null);
  const selectedDocument = useMemo(() => {
    if (pathname === '/termsofuse') return DOCUMENTS.TERMS_OF_USE;
    if (pathname === '/privacypolicy') return DOCUMENTS.PRIVACY_POLICY;
    return undefined;
  }, [pathname]);
  const [contentPaddingBottom, setContentPaddingBottom] = useState('0px');

  const scrollToSection = useCallback((id: string) => {
    const section = document.getElementById(id);
    const contentDiv = document.getElementById(CONTENT_DIV_ID);

    if (!section) return;

    section.scrollIntoView();

    if (!contentDiv) return;

    contentDiv.scrollBy(0, -35);
  }, []);

  const handlePageResize = () => {
    if (!mainDivRef.current) {
      setContentPaddingBottom('0px');
      return;
    }

    const rect = mainDivRef.current.getBoundingClientRect();
    const maxHeight = rect.height;

    setContentPaddingBottom(`${maxHeight * 0.8}px`);
  };

  useEffect(() => {
    scrollToSection(selectedLink?.replace('#', ''));
  }, [selectedLink, scrollToSection]);

  useEffect(() => {
    window.addEventListener('resize', handlePageResize);

    handlePageResize();

    return () => window.removeEventListener('resize', handlePageResize);
  }, []);

  return (
    <div>
      <div style={{ padding: '48px 30px' }}>
        <img
          style={{ width: 255, height: 50 }}
          src={FortressLogoSrc}
          alt="Fortress Logo"
        />
      </div>
      <Stack>
        <Stack
          sx={{ padding: '0px 30px 5px 30px' }}
          direction="row"
          spacing={4}
        >
          <Link
            fontSize={20}
            isSelected={selectedDocument === DOCUMENTS.TERMS_OF_USE}
            to="termsofuse"
          >
            Terms of Use
          </Link>
          <Link
            fontSize={20}
            isSelected={selectedDocument === DOCUMENTS.PRIVACY_POLICY}
            to="privacypolicy"
          >
            Privacy Policy
          </Link>
        </Stack>
        <Divider />
        <div style={{ display: 'flex', maxHeight: '83vh' }} ref={mainDivRef}>
          {links && links.length > 0 && (
            <Stack
              direction="row"
              sx={{ maxHeight: '100%', overflowY: 'auto' }}
            >
              <Stack
                sx={{ padding: '35px 25px 280px 25px', maxWidth: 240 }}
                spacing={1}
              >
                {links.map(({ label, id }) => {
                  const hash = `#${id}`;
                  return (
                    <IndexLink
                      key={id}
                      isSelected={selectedLink === hash}
                      href={hash}
                      fontSize={15}
                      onClick={(e) => {
                        // prevent the scroll behavior, bc we handle that with scrollToSection
                        e.preventDefault();

                        if (selectedLink !== hash) history.push(hash);

                        scrollToSection(id);
                      }}
                    >
                      {label}
                    </IndexLink>
                  );
                })}
              </Stack>
              <Divider orientation="vertical" />
            </Stack>
          )}
          <ContentDiv id={CONTENT_DIV_ID} paddingBottom={contentPaddingBottom}>
            {children}
          </ContentDiv>
        </div>
      </Stack>
    </div>
  );
}

export default withRouter(LegalDocumentPageLayout);
