const defaultConfig = {
  columns: [
    'unit',
    'floorPlan',
    'bedsBaths',
    'sqFt',
    'status',
    'resident',
    'moveInDate',
    'leaseStartDate',
    'leaseEndDate',
    'collectionNote',
    'underEviction',
    'baseFloorPlanMarketRent',
    'amenityFees',
    'marketRent',
    'priorLeasedRent',
    'leasedRent',
    'residentRent',
    'subsidyRent',
    'chargedUnitFees',
    'balance',
    'moveOutDate',
    'moveInReady',
    'daysVacant',
    'quotingRent',
    'applicant',
    'applicantStatus',
    'scheduledMoveInDate',
    'otherHouseholdMembers',
  ],
  showHideColumns: [
    'otherHouseholdMembers',
    'baseFloorPlanMarketRent',
    'amenityFees',
    'amenityDetails',
  ],
  totals: [
    'sqFt',
    'marketRent',
    'priorLeasedRent',
    'leasedRent',
    'residentRent',
    'chargedUnitFees',
    'subsidyRent',
    'balance',
    'amenityFees',
    'baseFloorPlanMarketRent',
  ],
  filterableColumns: [],
};

const affordableConfig = {
  columns: [
    ...defaultConfig.columns,
    'noteRent',
    'basicRent',
    'requestedHardshipExemption',
    'setAside',
    'grossRentLimit',
    'netMaxRent',
    'nonOptionalCharge',
    'utilityAllowances',
    'actualUa',
    'totalGrossRent',
  ],
  showHideColumns: [
    ...defaultConfig.showHideColumns,
    'requestedHardshipExemption',
    'setAside',
    'nonOptionalCharge',
    'utilityAllowances',
    'actualUa',
    'totalGrossRent',
  ],
  totals: [
    ...defaultConfig.totals,
    'netMaxRent',
    'grossRentLimit',
    'utilityAllowances',
    'actualUa',
    'totalGrossRent',
  ],
};

export const config = {
  Conventional: defaultConfig,
  Mixed: affordableConfig,
  Affordable: affordableConfig,
};
