const { del, put } = require('../utils/api');

export default class CustomerOpsLedgerCAMAllocationsService {
  delete(
    organizationId: string,
    propertyId: string,
    householdId: string,
    customerOpsLedgerId: string,
  ) {
    return del(
      `/${organizationId}/${propertyId}/households/${householdId}/customer-ops-ledger/${customerOpsLedgerId}`,
    );
  }

  modify(
    organizationId: string,
    propertyId: string,
    householdId: string,
    customerOpsLedgerId: string,
    camAllocationsId: string,
  ) {
    const payload = {
      camAllocationsId,
    };
    return put(
      `/${organizationId}/${propertyId}/households/${householdId}/customer-ops-ledger/${customerOpsLedgerId}`,
      JSON.stringify(payload),
    );
  }
}
