import { useContext, Fragment } from 'react';
import { PageContext } from '../context';
import messages from '../messages';
import LimitDataByYearTable from './LimitDataByYearTable';
import Spacer from '../../../components/Spacer';

function LimitDataByYear() {
  const { formatMessage, years, canEdit } = useContext(PageContext);
  return (
    <div>
      <h2>
        {formatMessage(
          canEdit
            ? messages.limitDataByYearSectionTitle
            : messages.viewLimitDataByYearSectionTitle,
        )}
      </h2>
      {years.map((year) => (
        <Fragment key={year}>
          <Spacer v={20} />
          <LimitDataByYearTable year={year} />
        </Fragment>
      ))}
    </div>
  );
}

export default LimitDataByYear;
