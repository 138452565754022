import type { Passwords } from '../types';
import messages from './messages';

const validate = (values: Passwords, { intl }: Object) => {
  const errors = {};

  if (!values.password) {
    errors.password = intl.formatMessage(messages.requiredFields);
  }

  if (values.password && values.password.length > 50) {
    errors.password = intl.formatMessage(messages.length);
  }

  if(!values.confirmPassword) {
    errors.confirmPassword = intl.formatMessage(messages.requiredFields);
  }

  const validPassword = 
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).+$/.test(values.password);

  if(!errors.password && values.password !== values.confirmPassword) {
    errors.confirmPassword = intl.formatMessage(messages.matchPasswords);
  } else if(!errors.password && (!validPassword || (values.password && values.password.length < 10))){
    errors.password = intl.formatMessage(messages.missingMin);
  }

  return errors;
};

export default validate;
