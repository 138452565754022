import { defineMessages } from 'react-intl';

const messages = defineMessages({
  rent: {
    id: 'App.CommercialLeaseDataTab.Rent.Rent',
    defaultMessage: 'Rent',
  },
  startDate: {
    id: 'App.CommercialLeaseDataTab.Rent.StartDate',
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: 'App.CommercialLeaseDataTab.Rent.EndDate',
    defaultMessage: 'End Date',
  },
  months: {
    id: 'App.CommercialLeaseDataTab.Rent.months',
    defaultMessage: '# Months',
  },
  monthlyAmount: {
    id: 'App.CommercialLeaseDataTab.Rent.monthlyAmount',
    defaultMessage: 'Monthly Amount',
  },
  psfPerMonth: {
    id: 'App.CommercialLeaseDataTab.Rent.PSFPerMonth',
    defaultMessage: 'PSF/M',
  },
  psfPerYear: {
    id: 'App.CommercialLeaseDataTab.Rent.PSFPerYear',
    defaultMessage: 'PSF/A',
  },
  totalRent: {
    id: 'App.CommercialLeaseDataTab.Rent.TotalRent',
    defaultMessage: 'Total Rent',
  },
  notes: {
    id: 'App.CommercialLeaseDataTab.Rent.Notes',
    defaultMessage: 'Notes',
  },
  addRent: {
    id: 'App.CommercialLeaseDataTab.Rent.AddRent',
    defaultMessage: 'Add Rent',
  },
  showLess: {
    id: 'App.CommercialLeaseDataTab.Rent.ShowLess',
    defaultMessage: 'Show Less',
  },
  showMore: {
    id: 'App.CommercialLeaseDataTab.Rent.ShowMore',
    defaultMessage: 'Show More',
  },
  note: {
    id: 'App.CommercialLeaseDataTab.Rent.Note',
    defaultMessage: 'NOTE:',
  },
  noteDescription: {
    id: 'App.CommercialLeaseDataTab.Rent.NoteDescription',
    defaultMessage: `You will need to manually add rent on
              the "Monthly Charges" table below.`,
  },
  rentFormNote: {
    id: 'App.CommercialLeaseDataTab.Rent.RentFormNote',
    defaultMessage: `If Dollar amount is selected, enter the monthly amount for rent.
    Must be greater than the amount of the last rent step.
    If Percentage amount is selected, enter the percentage of the last rent step amount rent will be.
    Must be over 100% to increase rent amount.`,
  },
  rentDisabledTooltip: {
    id: 'App.LeaseDataTabEmpty.RentDisabledTooltip',
    defaultMessage: 'Rent cannot be added until Lease Basics have been saved.',
  },
});

export default messages;
