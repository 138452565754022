import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.ResidentProfile.ManageDocuments.Title',
    defaultMessage: 'Fortress - Documents',
  },
  header: {
    id: 'App.ResidentProfile.ManageDocuments.Header',
    defaultMessage: 'Documents',
  },
  uploadFile: {
    id: 'App.ResidentProfile.ManageDocuments.UploadFile',
    defaultMessage: 'Upload File',
  },
  deleteConfirmation: {
    id: 'App.ResidentProfile.ManageDocuments.DeleteConfirmation',
    defaultMessage: 'Are you sure you want to delete?',
  },
  errorHeader: {
    id: 'App.ResidentProfile.ManageDocuments.ErrorHeader',
    defaultMessage: 'Error',
  },
  successHeader: {
    id: 'App.ResidentProfile.ManageDocuments.SuccessHeader',
    defaultMessage: 'Success',
  },
  successMessage: {
    id: 'App.ResidentProfile.ManageDocuments.SuccessMessage',
    defaultMessage: 'The document was uploaded.',
  },
  generateLetterSuccessMessage: {
    id: 'App.ResidentProfile.ManageDocuments.GenerateLetterSuccessMessage',
    defaultMessage: 'Resident Letter Generated',
  },
  generateLetterErrorMessage: {
    id: 'App.ResidentProfile.ManageDocuments.GenerateLetterErrorMessage',
    defaultMessage: 'Failed to Generate Resident Letter',
  },
  successDeleteMessage: {
    id: 'App.ResidentProfile.ManageDocuments.SuccessDeleteMessage',
    defaultMessage: 'The document was deleted.',
  },
  chooseOption: {
    id: 'App.ResidentProfile.ManageDocuments.ChooseOption',
    defaultMessage: 'Choose an option',
  },
  generateTenantLetterSuccessMessage: {
    id: 'App.ResidentProfile.ManageDocuments.GenerateTenantLetterSuccessMessage',
    defaultMessage: 'Tenant Letter Generated',
  },
  generateTenantLetterErrorMessage: {
    id: 'App.ResidentProfile.ManageDocuments.GenerateTenantLetterErrorMessage',
    defaultMessage: 'Failed to Generate Tenant Letter',
  },
  failedDocumentDownloadTitle: {
    id: 'App.ResidentProfile.ManageDocuments.FailedDocumentDownloadTitle',
    defaultMessage: 'Error while downloading',
  },
  failedDocumentDownload: {
    id: 'App.ResidentProfile.ManageDocuments.FailedDocumentDownload',
    defaultMessage: 'This file is not available to display',
  },
  complianceDeleteDisable: {
    id: 'App.ResidentProfile.ManageDocuments.ComplianceDeleteDisable',
    defaultMessage:
      'If you would like to delete this document you must do so from the Qualification tab.',
  },
});

export default messages;
