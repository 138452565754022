// $FlowFixMe
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  Button,
  Typography,
  Stack,
  Tooltip,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import { Field } from 'redux-form';
import { bindActionCreators } from 'redux';
import { partial, pathOr, props, isNil, isEmpty } from 'ramda';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import MonthlyTransactionModal from './MonthlyTransactionModal';
import type { GlobalState } from '../../../../App/types';
import * as applicationActions from '../../../../ApplicationProfile/actions';
import * as residentActions from '../../../../ResidentProfile/actions';

import DashesIfNullOrUndefined from '../../../../../components/DashesIfNullOrUndefined';
import { renderSelectField } from '../../../../../utils/redux-form-helper';
import {
  getMonthlyTransactions,
  mapFormValuesLeaseData,
} from '../../../../../utils/lease-helpers';
import ElementWithPermissions from '../../../../../components/ElementWithPermissions';

import { DeleteChargeModal } from './DeleteChargeModal';

import {
  MONTLHY_LEASE_CHARGE_DROPDOWN_OPTIONS,
  CHARGE_PERIOD_TYPES,
} from './constants';
import messages from './messages';
import { useFlags } from 'launchdarkly-react-client-sdk';

type Props = {
  actions: Object,
  applicationId?: string,
  allMonthlyTransactions: Array<Object>,
  canComplete: Function,
  formValues: Object,
  frNames: string[],
  intl: Object,
  isDisabledMonthly: boolean,
  isLocked: boolean,
  labelEndDate: string,
  lease: Object,
  moveInDateInput: any,
  property: Object,
  propertySubjournalTypes: Array<Object>,
  residentId?: string,
  selectedMonthlyOption: string,
  showGenerateMessage: boolean,
  transactionTypes: Array<Object>,
  transactionCodes: Array<Object>,
  updateTransactionsList: Function,
  valid: boolean,
};

type LocalState = {
  transactionTypes: Object,
  currentProperty: Object,
};

export const MonthlyLeaseChargesOrCredits = ({
  actions,
  allMonthlyTransactions,
  applicationId,
  lease,
  labelEndDate,
  formValues,
  residentId,
  transactionCodes,
  property,
  transactionTypes,
  canComplete,
  frNames,
  intl,
  isDisabledMonthly,
  isLocked,
  propertySubjournalTypes,
  selectedMonthlyOption,
  updateTransactionsList,
  showGenerateMessage,
}: Props) => {
  const flags = useFlags();
  const [show, setShow] = useState(false);
  const [showDeleteChargeModal, setShowDeleteChargeModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [modalData, setModalData] = useState({});
  const [subjournal, setSubjournal] = useState({});
  const [auditData, setAuditData] = useState({});
  const [chargePeriodType, setChargePeriodType] = useState(
    CHARGE_PERIOD_TYPES.PRIOR_CURRENT,
  );

  const monthlyTransactions = getMonthlyTransactions(
    allMonthlyTransactions,
    selectedMonthlyOption,
  );

  const openModal = (
    edit: boolean,
    modalData: Object,
    subjournal: any,
    auditData: any = {},
  ) => {
    const startDate = moment(formValues.startDate).utc().format('MM/DD/YYYY');
    let endDate;
    if (labelEndDate) {
      endDate = moment(labelEndDate);
    } else {
      const leaseEndDate = pathOr(null, ['endDate'], lease);
      endDate = leaseEndDate ? moment(leaseEndDate) : null;
    }

    let noEndDate = !edit ? false : !modalData.endDate;

    if (flags.transactionEndDate) {
      noEndDate = !edit
        ? property.isNoEndDateActive ?? false
        : !modalData.endDate;
    }

    setShow(true);
    setEdit(edit);
    setModalData({
      subjournalId: 'default',
      transactionTypeId: 'default',
      propertyTransactionCodeId: 'default',
      startDate,
      endDate,
      ...modalData,
      noEndDate,
    });
    setSubjournal(subjournal);
    setAuditData(auditData);
  };

  const closeModal = () => setShow(false);

  const getChargePeriodType = (data: Object) => {
    const prorateFieldValues = props(
      [
        'newLeaseProratedLmt',
        'priorLeaseProratedLmt',
        'prorateEndedLmtReplacement',
      ],
      data,
    );
    const hasProrateFields = prorateFieldValues.some(
      (v) => !isEmpty(v) && !isNil(v),
    );

    if (hasProrateFields) {
      return CHARGE_PERIOD_TYPES.PRORATE;
    }

    const today = moment();
    const futureCharge = !(
      moment(modalData.startDate).isSameOrBefore(today) &&
      (moment(modalData.endDate).isAfter(today) || !modalData.endDate)
    );
    return futureCharge
      ? CHARGE_PERIOD_TYPES.FUTURE
      : CHARGE_PERIOD_TYPES.PRIOR_CURRENT;
  };

  const openDeleteChargeModal = (modalData: Object) => {
    const newChargePeriodType = getChargePeriodType(modalData);
    setShowDeleteChargeModal(true);
    setModalData(modalData);
    setChargePeriodType(newChargePeriodType);
  };

  const closeDeleteChargeModal = () => setShowDeleteChargeModal(false);

  const handleSubmit = (values: any) => {
    const transaction = {
      id: values.id || null,
      note: values.note || null,
      startDate: values.startDate || null,
      endDate: !values.endDate || values.noEndDate ? null : values.endDate,
      subjournalId: values.subjournalId || null,
      amount: values.amount || null,
      propertyTransactionCodeId: values.propertyTransactionCodeId || null,
    };
    const mapLeaseFormValues = mapFormValuesLeaseData(formValues);
    if (residentId) {
      actions.saveMonthlyChargeResident(
        lease.id,
        residentId,
        transaction,
        mapLeaseFormValues,
        updateTransactionsList,
      );
    } else {
      actions.saveMonthlyCharge(
        applicationId,
        transaction,
        mapLeaseFormValues,
        updateTransactionsList,
      );
    }
    closeModal();
  };

  const handleChargeDelete = () => {
    actions.deleteMonthlyCharge(modalData, applicationId);
    closeDeleteChargeModal();
    updateTransactionsList();
  };

  const onChangeMonthlyOption = (event: any) => {
    if (residentId) {
      actions.setMonthlyOptionResident(event.target.value);
    } else {
      actions.setMonthlyOption(event.target.value);
    }
  };

  let leaseIdentifier = '';
  const today = new Date().toISOString().slice(0, 10);

  if (lease.isRenewal) {
    leaseIdentifier = 'Renewal Lease';
  } else if (lease.isTransfer) {
    leaseIdentifier = 'Transfer Lease';
  } else if (isDisabledMonthly) {
    leaseIdentifier = 'Prior Lease';
  } else {
    leaseIdentifier = 'Current Lease';
  }

  const selectableCodes = transactionCodes
    ? transactionCodes.filter(
        (code) =>
          code.userSelectable &&
          pathOr(false, ['transactionCode', 'isMonthlyTransactionCode'], code),
      )
    : [];

  const notCurrentMovedInLease = isLocked && !lease.isMovedIn;
  const disableAddTransactionBtn =
    !canComplete || isDisabledMonthly || notCurrentMovedInLease;
  return (
    <div>
      {show && (
        <MonthlyTransactionModal
          show={show}
          close={closeModal}
          intl={intl}
          property={property}
          subjournals={propertySubjournalTypes}
          transactionTypes={transactionTypes}
          transactionCodes={selectableCodes}
          onSubmit={handleSubmit}
          initialValues={modalData}
          edit={edit}
          subjournal={subjournal}
          lease={lease}
          frNames={frNames}
          auditData={auditData}
          autoChargeMtmFeesFlag={flags.autoChargeMtmFees}
        />
      )}
      {showDeleteChargeModal && (
        <DeleteChargeModal
          intl={intl}
          chargePeriodType={chargePeriodType}
          closeDeleteChargeModal={closeDeleteChargeModal}
          handleChargeDelete={handleChargeDelete}
        />
      )}
      <div>
        <Typography variant={'h2'}>
          <i className="et-money text-blue padright10" />
          {intl.formatMessage(messages.monthlyLeaseChargesOrCreditsLabel)}*
        </Typography>
        <Typography component={'div'} variant={'formSubheading'} pl={'36px'}>
          for {leaseIdentifier}
        </Typography>
        <Stack
          spacing={1}
          direction={'row'}
          justifyContent={'flex-end'}
          alignItems={'flex-start'}
        >
          <Field
            name="monthlyOption"
            component={renderSelectField}
            bsSize="lg"
            options={MONTLHY_LEASE_CHARGE_DROPDOWN_OPTIONS}
            onChange={onChangeMonthlyOption}
          />
          <Stack>
            <ElementWithPermissions scope={['lease-create']}>
              <Button
                variant={'shout'}
                onClick={partial(openModal, [false, {}, undefined])}
                disabled={disableAddTransactionBtn}
              >
                {intl.formatMessage(messages.addMonthlyTransactionLabel)}
              </Button>
            </ElementWithPermissions>
            {!canComplete && (
              <Typography variant={'caption'}>
                {intl.formatMessage(messages.leaseStartDateRequired)}
              </Typography>
            )}
          </Stack>
        </Stack>
      </div>
      <div className="table-scroll table-units-container">
        <table className="table table-prospects table-striped">
          <thead className="table-header">
            <tr>
              <th>Transaction Code</th>
              <th>Description</th>
              <th>Transaction Type</th>
              <th>Amount</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {monthlyTransactions
              .filter((transaction) => {
                if (flags.prorateRentsForMoveOuts) {
                  return transaction;
                }
                return !transaction.isMoveOutProrate;
              })
              .map((transaction) => {
                const isDeleteChargeEnabled =
                  selectedMonthlyOption === 'past' ||
                  isDisabledMonthly ||
                  transaction.endDate === today ||
                  transaction.isMoveOutProrate;
                const editTransactionDisabled = transaction.isMoveOutProrate;
                const transactionCode = pathOr(
                  null,
                  ['propertyTransactionCode', 'transactionCode', 'code'],
                  transaction,
                );
                const transactionCodeDescription = pathOr(
                  null,
                  ['propertyTransactionCode', 'transactionCode', 'description'],
                  transaction,
                );
                const transactionType = pathOr(
                  null,
                  [
                    'propertyTransactionCode',
                    'transactionCode',
                    'transactionType',
                    'name',
                  ],
                  transaction,
                );
                const transactionTypeId = pathOr(
                  null,
                  [
                    'propertyTransactionCode',
                    'transactionCode',
                    'transactionType',
                    'id',
                  ],
                  transaction,
                );
                const startDate = transaction.startDate
                  ? moment(transaction.startDate).utc().format('MM/DD/YYYY')
                  : null;
                const endDate = transaction.endDate
                  ? moment(transaction.endDate).format('MM/DD/YYYY')
                  : null;
                const amount = transaction.amount
                  ? `$${Number(transaction.amount).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : null;

                const auditData = {
                  createdBy: `${transaction.createdBy.firstName} ${transaction.createdBy.lastName}`,
                  updatedBy: `${transaction.updatedBy.firstName} ${transaction.updatedBy.lastName}`,
                  createdAt: transaction.createdAt,
                  updatedAt: transaction.updatedAt,
                };

                const modalData = {
                  id: transaction.id,
                  note: transaction.note,
                  startDate,
                  endDate,
                  subjournalId: transaction.subjournalId,
                  amount: transaction.amount,
                  propertyTransactionCodeId:
                    transaction.propertyTransactionCodeId,
                  transactionTypeId: transactionTypeId,
                  newLeaseProratedLmt: transaction.newLeaseProratedLmt,
                  priorLeaseProratedLmt: transaction.priorLeaseProratedLmt,
                  prorateEndedLmtReplacement:
                    transaction.prorateEndedLmtReplacement,
                };
                const subjournal = propertySubjournalTypes
                  ? propertySubjournalTypes.find(
                      (sj) => sj.id === transaction.subjournalId,
                    )
                  : null;

                return (
                  <tr key={transaction.id}>
                    <td>
                      <DashesIfNullOrUndefined data={transactionCode} />
                    </td>
                    <td>
                      <DashesIfNullOrUndefined
                        data={transactionCodeDescription}
                      />
                    </td>
                    <td>
                      <DashesIfNullOrUndefined data={transactionType} />
                    </td>
                    <td>
                      <DashesIfNullOrUndefined data={amount} />
                    </td>
                    <td>
                      <DashesIfNullOrUndefined data={startDate} />
                    </td>
                    <td>
                      <DashesIfNullOrUndefined data={endDate} />
                    </td>
                    <td>
                      <ElementWithPermissions
                        scope={['financial-transaction-create', 'lease-create']}
                      >
                        <div className="container-of-trash">
                          <Tooltip
                            variant="light"
                            placement="top"
                            title={
                              transaction.isMoveOutProrate ? (
                                <Typography>
                                  {intl.formatMessage(
                                    messages.moveOutProrateTooltip,
                                  )}
                                </Typography>
                              ) : null
                            }
                          >
                            <div className="row-remove-btn">
                              <a
                                onClick={partial(openModal, [
                                  true,
                                  modalData,
                                  subjournal,
                                  auditData,
                                ])}
                                disabled={editTransactionDisabled}
                              >
                                <i className="et-pencil padright10" />
                              </a>
                              <a
                                onClick={() => openDeleteChargeModal(modalData)}
                                disabled={isDeleteChargeEnabled}
                              >
                                <i className="et-trash" />
                              </a>
                            </div>
                          </Tooltip>
                        </div>
                      </ElementWithPermissions>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {showGenerateMessage && (
        <p className="padtop20">
          <FormattedMessage
            {...messages.totalMonthlyDue}
            values={{
              note: (
                <strong>
                  {intl.formatMessage(messages.noteGeneratingLease)}
                </strong>
              ),
            }}
          />
        </p>
      )}
    </div>
  );
};

export const mapStateToProps = (state: GlobalState & LocalState): Object => {
  const { app, transactionTypes, currentProperty } = state;
  return {
    property: app.selectedProperty,
    transactionTypes: transactionTypes.types,
    transactionCodes: currentProperty.transactionCodes.codes,
    propertySubjournalTypes: currentProperty.subjournalTypes.types,
  };
};

export function mapDispatchToProps(dispatch: any): Object {
  const actions = bindActionCreators(
    {
      ...applicationActions,
      ...residentActions,
    },
    dispatch,
  );
  return { actions };
}

const InjectedMonthlyLeaseChargesOrCredits = injectIntl(
  MonthlyLeaseChargesOrCredits,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedMonthlyLeaseChargesOrCredits);
