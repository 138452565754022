import { defineMessages } from 'react-intl';

const messages = defineMessages({
  marketingDetails: {
    id: 'App.MarketingDetails.marketingDetails',
    defaultMessage: 'Marketing Details',
  },
  shortPropertyDescription: {
    id: 'App.MarketingDetails.shortPropertyDescription',
    defaultMessage: 'Short Property Description (on Fortress Quote)',
  },
  officeHours: {
    id: 'App.MarketingDetails.officeHours',
    defaultMessage: 'Office Hours',
  },
  propertyGallery: {
    id: 'App.MarketingDetails.propertyGallery',
    defaultMessage: 'Property Gallery',
  },
  required: {
    id: 'App.MarketingDetails.Required',
    defaultMessage: 'Field value is required.',
  },
  fullPropertyDescription: {
    id: 'App.MarketingDetails.FullPropertyDescription',
    defaultMessage: 'Full/Long Property Description',
  },
});

export default messages;
