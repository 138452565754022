import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.Title',
    defaultMessage: 'Load Property Data',
  },
  uploadFileNote: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.UploadFile',
    defaultMessage:
      'Note: The document type must be a CSV. For MINC a .xml file and for MAT a .mat file',
  },
  cancel: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.Cancel',
    defaultMessage: 'Cancel',
  },
  post: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.Upload',
    defaultMessage: 'Upload',
  },
  propertyName: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.PropertyName',
    defaultMessage: 'Property Name',
  },
  fileType: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.FileType',
    defaultMessage: 'File Type',
  },
  createPropertyDataBatchSuccessHeader: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.createPropertyDataBatchSuccessHeader',
    defaultMessage: 'Success',
  },
  createPropertyDataBatchFailureHeader: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.createPropertyDataBatchFailureHeader',
    defaultMessage: 'Error',
  },
  createPropertyDataBatchSuccessDescription: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.createPropertyDataBatchSuccessDescription',
    defaultMessage:
      'Property Data uploaded - A confirmation email will be sent when it finishes',
  },
  createPropertyDataBatchFailureDescription: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.createPropertyDataBatchFailureDescription',
    defaultMessage: 'Property data batch file could not be uploaded.',
  },
  yes: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.no',
    defaultMessage: 'No',
  },
  cancelConfirmationHeader: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.cancelConfirmationHeader',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  uploadText: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.UploadText',
    defaultMessage:
      // eslint-disable-next-line max-len
      'Clicking Upload will send your file to Fortress to process for Posting Batch information. You will receive a success or failure email shortly.',
  },
  emailText: {
    id: 'App.CreatePropertyDataBatch.CreatePropertyDataBatchForm.EmailText',
    defaultMessage:
      'If you receive a failure email, you should verify the errors listed and retry to load those rows only.',
  },
});

export default messages;
