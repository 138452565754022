export const GET_APPLICANT_INFORMATION_ERROR =
  'app/CreateCommercialTenant/GET_APPLICANT_INFORMATION_ERROR';
export const GET_APPLICATION_CHECKLIST_STATUS =
  'app/CreateCommercialTenant/GET_APPLICATION_CHECKLIST_STATUS';
export const GET_APPLICATION_SIGNED_LEASE_STATUS =
  'app/CreateCommercialTenant/GET_APPLICATION_SIGNED_LEASE_STATUS';
export const GET_MOVE_IN_ACTIONS_SUCCESS =
  'app/CreateCommercialTenant/GET_MOVE_IN_ACTIONS_SUCCESS';

export const GET_ONE_APPLICATION =
  'app/CreateCommercialTenant/GET_ONE_APPLICATION';
export const GET_ONE_APPLICATION_SUCCESS =
  'app/CreateCommercialTenant/GET_ONE_APPLICATION_SUCCESS';
export const GET_ONE_APPLICATION_ERROR =
  'app/CreateCommercialTenant/GET_ONE_APPLICATION_ERROR';

export const GET_SIGNED_LEASE_STATUS_SUCCESS =
  'app/CreateCommercialTenant/GET_SIGNED_LEASE_STATUS_SUCCESS';

export const GET_APPLICATION_LEASE_STATUS =
  'app/CreateCommercialTenant/GET_APPLICATION_LEASE_STATUS';
export const GET_APPLICATION_LEASE_STATUS_SUCCESS =
  'app/CreateCommercialTenant/GET_APPLICATION_LEASE_STATUS_SUCCESS';

export const GET_APPLICATION_CONTACT_STATUS =
  'app/CreateCommercialTenant/GET_APPLICATION_CONTACT_STATUS';
export const GET_APPLICATION_CONTACT_STATUS_SUCCESS =
  'app/CreateCommercialTenant/GET_APPLICATION_CONTACT_STATUS_SUCCESS';

export const GET_APPLICATION_INSURANCE_STATUS =
  'app/CreateCommercialTenant/GET_APPLICATION_INSURANCE_STATUS';
export const GET_APPLICATION_INSURANCE_STATUS_SUCCESS =
  'app/CreateCommercialTenant/GET_APPLICATION_INSURANCE_STATUS_SUCCESS';

export const GET_APPLICATION_LEASE_START_DATE_STATUS =
  'app/CreateCommercialTenant/GET_APPLICATION_LEASE_START_DATE_STATUS';
export const GET_APPLICATION_LEASE_START_DATE_STATUS_SUCCESS =
  'app/CreateCommercialTenant/GET_APPLICATION_LEASE_START_DATE_STATUS_SUCCESS';

export const CLEAN = 'app/CreateCommercialTenant/CLEAN';
export const COMMERCIAL_MOVE_IN =
  'app/CreateCommercialTenant/COMMERCIAL_MOVE_IN';
export const COMMERCIAL_MOVE_IN_SUCCESS =
  'app/CreateCommercialTenant/COMMERCIAL_MOVE_IN_SUCCESS';
export const COMMERCIAL_MOVE_IN_ERROR =
  'app/CreateCommercialTenant/COMMERCIAL_MOVE_IN_ERROR';

export const GET_UNIT_AVAILABILITY_STATUS =
  'app/CreateCommercialTenant/GET_UNIT_AVAILABILITY_STATUS';
export const GET_UNIT_AVAILABILITY_STATUS_SUCCESS =
  'app/CreateCommercialTenant/GET_UNIT_AVAILABILITY_STATUS_SUCCESS';
