// $FlowFixMe
import { useEffect, useState, useCallback } from 'react';
import { always, cond, equals, T, pathOr, isEmpty } from 'ramda';
import WorkOrderPreferenceService from '../../../services/workOrderPreferenceService';

import PetResidentService from '../../../services/residentPetService';

export const useUnitEntryPreferences = (
  context: any,
  selectedUnitId: any,
  changeCallback: Function,
) => {
  const [subscribed, setSubscribed] = useState(true);
  const [fetch, setFetch] = useState(true);
  const [selectedUnitPreferences, setSelectedUnitPreferences] = useState(null);

  const updateSelectedUnitPreferences = () => setFetch(true);

  const fetchHouseholdWorkOrderPreferences = useCallback(async () => {
    setSubscribed(true);
    if (selectedUnitId || fetch) {
      const { id: propertyId, organizationId } = context.property;
      const { householdId, unitId } = context;
      let householdPreferences = {};
      if (selectedUnitId && householdId && unitId) {
        const woPreferencesService = new WorkOrderPreferenceService();
        householdPreferences = await woPreferencesService.search(
          organizationId,
          propertyId,
          { householdId, unitId },
        );
      }
      if (subscribed) {
        const alarmActiveValue = cond([
          [equals(true), always('Yes')],
          [equals(false), always('No')],
          [T, always(null)],
        ]);

        const doorCodeValue = cond([
          [equals(true), always('Yes')],
          [equals(false), always('No')],
          [T, always(null)],
        ]);

        setSelectedUnitPreferences({
          ...householdPreferences,
          isAlarmActive: alarmActiveValue(householdPreferences.isAlarmActive),
          isDoorCodeActive: doorCodeValue(
            householdPreferences.isDoorCodeActive,
          ),
        });
        const initialEntryPreference = pathOr(
          null,
          ['defaultEntryPermissionPreferenceId'],
          householdPreferences,
        );
        if (!context.isEditMode) {
          changeCallback('entryPermissionPreferenceId', initialEntryPreference);
        }
        setFetch(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnitId, fetch, subscribed]);

  useEffect(() => {
    fetchHouseholdWorkOrderPreferences();
    return () => {
      setSubscribed(false);
    };
  }, [fetchHouseholdWorkOrderPreferences]);
  return [selectedUnitPreferences, updateSelectedUnitPreferences];
};

export const useAsyncResidentPets = (
  context: any,
  selectedUnitId: any,
  initialAlertValues: Object,
  changeCallback: Function,
) => {
  const [subscribed, setSubscribed] = useState(true);
  const [fetch, setFetch] = useState(true);
  const [petResidents, setPetResidents] = useState([]);

  const updatePetResidentList = () => setFetch(true);

  const fetchResidentPets = useCallback(async () => {
    setSubscribed(true);
    if (selectedUnitId || fetch) {
      // $FlowFixMe
      const abortController = new AbortController(); // eslint-disable-line
      const residentPetsService = new PetResidentService();
      const options = { signal: abortController.signal };
      const { id: propertyId, organizationId } = context.property;
      const { householdId } = context;
      const residentPetsResponse =
        selectedUnitId && householdId
          ? await residentPetsService.getResidentPetsForHousehold(
              organizationId,
              propertyId,
              householdId,
              options,
            )
          : [];
      if (subscribed) {
        setPetResidents(residentPetsResponse);

        changeCallback('petAlerts', null);
        //initialize the redux form fields:
        if (
          (!isEmpty(residentPetsResponse) && !context.isEditMode) ||
          (context.isEditMode && context.initialAlertValue)
        ) {
          changeCallback('petAlert', true);
        }
        residentPetsResponse.forEach((pet) => {
          const petId = pathOr('', ['residentPet', 'id'], pet);
          changeCallback(
            `petAlerts.${petId}.isCaged`,
            pathOr(null, [petId, 'isCaged'], initialAlertValues),
          );
        });
        setFetch(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUnitId, fetch, subscribed]);

  useEffect(() => {
    fetchResidentPets();
    return () => {
      setSubscribed(false);
    };
  }, [fetchResidentPets]);

  return {
    residentPetsList: petResidents || [],
    updateResidentPetsList: updatePetResidentList,
  };
};
