import { useMemo } from 'react';
import moment from 'moment';
import type { UseManageSelections } from './types';
const useManageSelections = ({
  actualOrAnticipatedMoveOutDate,
  allPropertyFeesWithRentableItemsData = [],
  allHouseholdRentableItemsData = [],
  numberIdValue,
  typeValue,
}: UseManageSelections) => {
  const hasEndDateAfterAnticipatedMoveOutDate: boolean = useMemo(() => {
    return actualOrAnticipatedMoveOutDate?.length
      ? allHouseholdRentableItemsData?.some(
          ({ endDate }) =>
            endDate === null ||
            moment(endDate).isAfter(
              moment(actualOrAnticipatedMoveOutDate),
              'day',
            ),
        )
      : false;
  }, [actualOrAnticipatedMoveOutDate, allHouseholdRentableItemsData]);

  const selectedPropertyFeeWithRentableItem = useMemo(() => {
    return typeValue?.length
      ? allPropertyFeesWithRentableItemsData?.find(({ id }) => id === typeValue)
      : null;
  }, [allPropertyFeesWithRentableItemsData, typeValue]);

  const selectedPropertyItemOfPropertyFeeWithRentableItem = useMemo(() => {
    return selectedPropertyFeeWithRentableItem
      ? selectedPropertyFeeWithRentableItem?.propertyRentableItems?.find(
          ({ id }) => id === numberIdValue,
        )
      : {};
  }, [selectedPropertyFeeWithRentableItem, numberIdValue]);

  return {
    hasEndDateAfterAnticipatedMoveOutDate,
    selectedPropertyFeeWithRentableItem,
    selectedPropertyItemOfPropertyFeeWithRentableItem,
  };
};

export default useManageSelections;
