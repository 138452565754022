import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import { isEmpty, isNil, omit } from 'ramda';
import { useCallback } from 'react';
import { toastr } from 'react-redux-toastr';
import ProrateCalculatorService from '../../../services/prorateCalculatorService';
import type { FeatureFlags } from '../../../types';

type UseDownloadProrateCalcScreenProps = {
  organizationId: string,
  propertyId: string,
  formValues: Object,
  headerFormValues: Object,
  householdId: ?string,
  prospectId: ?string,
};

const getValidDetailFormValues = (formValues) => {
  const keys = Object.keys(formValues);
  const nullKeys = keys.filter((key) => formValues[key] === null);
  const values = omit(nullKeys, formValues);
  return values;
};

export const useDownloadProrateCalcScreen = ({
  organizationId,
  propertyId,
  formValues,
  headerFormValues,
  householdId,
  prospectId,
}: UseDownloadProrateCalcScreenProps) => {
  const { prorateCalculatorSeparateQuotes }: FeatureFlags = useFlags();

  const getFilename = useCallback((): string => {
    if (!prorateCalculatorSeparateQuotes) {
      return `Prorate_Calculator_${moment().unix()}.pdf`;
    }

    const moveType =
      headerFormValues?.moveInMoveOut === 'moveIn' ? 'Move In' : 'Move Out';
    const date = moment(headerFormValues?.moveDate).format('MM-DD-YYYY');
    const leaseholderName = headerFormValues?.leaseholderName;
    const leaseHolderPrefix =
      isNil(leaseholderName) ||
      isEmpty(leaseholderName) ||
      householdId ||
      prospectId
        ? ''
        : `${leaseholderName} - `;
    return `${leaseHolderPrefix}${moveType} Quote - ${date}.pdf`;
  }, [
    headerFormValues?.leaseholderName,
    headerFormValues?.moveDate,
    headerFormValues?.moveInMoveOut,
    householdId,
    prorateCalculatorSeparateQuotes,
    prospectId,
  ]);

  const fetchDownloadProrateCalcScreen = useCallback(async () => {
    if (organizationId && propertyId && formValues && headerFormValues) {
      const abortController = new AbortController();
      const options = { signal: abortController.signal };
      const prorateCalculatorService = new ProrateCalculatorService();

      try {
        await prorateCalculatorService.downloadProrateCalcScreen({
          organizationId,
          propertyId,
          formData: {
            formValues: getValidDetailFormValues(formValues),
            headerFormValues,
            householdId,
            prospectId,
          },
          fileName: getFilename(),
          options,
        });
      } catch {
        toastr.error('Error', 'Error while downloading');
      }
    }
  }, [
    organizationId,
    propertyId,
    formValues,
    headerFormValues,
    householdId,
    prospectId,
    getFilename,
  ]);

  return fetchDownloadProrateCalcScreen;
};
