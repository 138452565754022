import { useMemo } from 'react';

/**
 * Hook to determine if property has RD program. Must have PropertyAffordablePrograms joined (pap)
 * @param {*} property property object with pap array
 * // clean up the style
 * object path = property->pap->[i]->masterAffordableProgram->name === 'RD'
 * @returns boolean
 */
function useIsRDProperty(property): boolean {
  const isRDProperty = useMemo(
    () =>
      property?.pap?.some((pap) => pap?.masterAffordableProgram?.name === 'RD'),
    [property],
  );

  return isRDProperty;
}

export default useIsRDProperty;
