import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'redux-form';
import {
  phoneFormatter,
  renderSimpleCheckboxField,
} from '../../../../utils/redux-form-helper';

const headers = [
  {
    id: 'method',
    label: 'Method',
  },
  {
    id: 'recipient',
    label: 'Recipient',
  },
  {
    id: 'contact',
    label: 'Contact',
  },
  {
    id: 'status',
    label: 'Status',
  },
];

type Props = {
  requestor: any,
  change: any,
  selectedPropertyId: string,
  customer: Object,
};
function CommunicationTable({ change, selectedPropertyId, customer }: Props) {
  const [validNotifications, setValidNotifications] = useState({
    SMS: false,
    Email: false,
  });

  useEffect(() => {
    if (customer?.id) {
      setValidNotifications((coms) => ({
        ...coms,
        SMS: !customer.isOptedOut,
      }));

      setValidNotifications((coms) => ({
        ...coms,
        Email: customer.isValidEmail,
      }));
    }
  }, [customer]);

  const isValidMethod = useCallback(
    (info: string, method: string) => {
      return !!info && validNotifications[method];
    },
    [validNotifications],
  );

  const methodsData = [
    {
      method: 'Email',
      recipient: `${customer.name}`,
      contact: customer.emailAddress,
      status: isValidMethod(customer.emailAddress, 'Email'),
    },
    {
      method: 'Text',
      recipient: `${customer.name}`,
      contact: phoneFormatter(customer.phoneNumber),
      status: isValidMethod(customer.phoneNumber, 'SMS'),
    },
  ];

  const rowStyle = {
    backgroundColor: 'white',
    border: '1px solid #D1D1D1',
    borderRadius: '3px',
  };

  const failedRowStyle = {
    backgroundColor: '#FFF0F0',
  };

  const cellStyle = {
    padding: '5px',
    paddingLeft: '9px',
    textAlign: 'left',
  };
  return (
    <div>
      <table
        style={{
          margin: 'auto',
          width: '100%',
        }}
      >
        <tr>
          {headers.map((header) => (
            <th style={{ padding: '10px 0px 10px', textAlign: 'left' }}>
              {header.label}
            </th>
          ))}
        </tr>
        {methodsData.map((row) => (
          <tr
            style={row.status ? rowStyle : { ...rowStyle, ...failedRowStyle }}
          >
            <td style={cellStyle}>
              <div>
                <Field
                  disabled={!row.status}
                  name={`communicationMethod-${row.method}`}
                  component={renderSimpleCheckboxField}
                  bsSize="lg"
                  label={
                    <div style={!row.status ? { color: '#D70000' } : {}}>
                      {row.method}
                    </div>
                  }
                />
              </div>
            </td>
            <td style={cellStyle}>{row.recipient}</td>
            <td style={cellStyle}>{row.contact}</td>
            <td style={cellStyle}>{row.status ? 'Pass' : 'Fail'}</td>
          </tr>
        ))}
      </table>
    </div>
  );
}

export default CommunicationTable;
