import { defineMessages } from 'react-intl';

const messages = defineMessages({
  alarm: {
    id: 'App.ManageProperties.WOHouseholdPreferencesForm.alarm',
    defaultMessage: 'Alarm?',
  },
  alarmCode: {
    id: 'App.ManageProperties.WOHouseholdPreferencesForm.alarmCode',
    defaultMessage: 'Alarm Code',
  },
  entryPreference: {
    id: 'App.ManageProperties.WOHouseholdPreferencesForm.entryPreference',
    defaultMessage: 'Unit Entry Preference',
  },
  yes: {
    id: 'App.ManageProperties.WOHouseholdPreferencesForm.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'App.ManageProperties.WOHouseholdPreerencesForm.no',
    defaultMessage: 'No',
  },
  typeInAlarm: {
    id: 'App.ManageProperties.WOHouseholdPreerencesForm.typeInAlarm',
    defaultMessage: 'Type in your alarm code',
  },
  note: {
    id: 'App.ManageProperties.WOHouseholdPreerencesForm.note',
    defaultMessage: 'Note:',
  },
  alarmNote: {
    id: 'App.ManageProperties.WOHouseholdPreerencesForm.alarmNote',
    defaultMessage:
      'If there is an alarm, but the code is not known, enter "Unknown."',
  },
  required: {
    id: 'App.ManageProperties.WOHouseholdPreferencesForm.required',
    defaultMessage: 'Field value is required.',
  },
  usesDoorCode: {
    id: 'App.ManageProperties.WOHouseholdPreferencesForm.door',
    defaultMessage: 'Door Code?',
  },
  doorCode: {
    id: 'App.ManageProperties.WOHouseholdPreferencesForm.doorCode',
    defaultMessage: 'Door Code',
  },
  typeInDoorCode: {
    id: 'App.ManageProperties.WOHouseholdPreerencesForm.typeInDoorCode',
    defaultMessage: 'Type in your door code',
  },
  doorNote: {
    id: 'App.ManageProperties.WOHouseholdPreerencesForm.doorNote',
    defaultMessage:
      'If there is a door code, but the code is not known, enter "Unknown."',
  },
});

export default messages;
