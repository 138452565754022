import { useEffect, useState } from 'react';

import CamRecordService from '../../../../services/camRecordService';
import type {
  FetchCamRecordsMonthlyChargesHookProps,
  EditCamMonthlyChargesHookProps,
} from './types';
import messages from './messages';

export const useFetchCamRecordsMonthlyCharges = ({
  intl,
  propertyId,
  organizationId,
  camRecordId,
  promptToaster,
}: FetchCamRecordsMonthlyChargesHookProps): any => {
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [monthlyCharges, setMonthlyCharges] = useState([]);
  useEffect(() => {
    // $FlowFixMe
    const abortController = new AbortController(); // eslint-disable-line
    const fetchCalculations = async () => {
      if (!propertyId || !organizationId) {
        throw new Error('Could not fetch CAM Monthly Charges');
      }
      setIsLoading(true);
      const camRecordService = new CamRecordService();
      const response = await camRecordService.getMonthlyCharges(
        organizationId,
        propertyId,
        camRecordId,
        abortController.signal,
      );
      setMonthlyCharges(response);
      setIsLoading(false);
    };
    if (camRecordId) {
      fetchCalculations();
    }
    setRefresh(false);
    return () => abortController.abort();
  }, [intl, organizationId, propertyId, camRecordId, promptToaster, refresh]);

  return [isLoading, monthlyCharges, () => setRefresh(true)];
};

export const editCamMonthlyCharges = async ({
  newAmount,
  newStartDate,
  endDate,
  intl,
  propertyId,
  organizationId,
  camCalculationsHouseholdMonthlyTransactionsId,
  promptToaster,
}: EditCamMonthlyChargesHookProps): any => {
  try {
    const camRecordService = new CamRecordService();
    await camRecordService.updateMonthlyCharges(
      newAmount,
      newStartDate,
      endDate,
      organizationId,
      propertyId,
      camCalculationsHouseholdMonthlyTransactionsId,
    );
    promptToaster({
      message: intl.formatMessage(messages.updateMonthlyChargesSuccessBody),
      title: intl.formatMessage(messages.updateMonthlyChargesSuccess),
    });
  } catch (err) {
    promptToaster({
      type: 'error',
      title: intl.formatMessage(messages.updateMonthlyChargesError),
      message: err.toString(),
    });
  }
};
