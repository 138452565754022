import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { NoPrint, Print } from 'react-easy-print';
import { FormattedDate } from 'react-intl';
import messages from '../messages';
export const CreateWorkOrderHeader = ({
  formatMessage,
  currentUser,
  workOrderId,
  createdAt,
}: Object) => {
  const { firstName, lastName } = currentUser;
  const time = createdAt ? (
    <FormattedDate
      value={createdAt}
      year="numeric"
      month="numeric"
      day="2-digit"
      hour="numeric"
      minute="numeric"
    />
  ) : null;
  return (
    <Row>
      <Col className="form-header" xs={12}>
        <i className="et-tasks float-left" />
        <h2 className="float-left">
          <NoPrint force>{formatMessage(messages.createWorkOrder)}</NoPrint>
          <Print printOnly name="workOrder">
            {formatMessage(messages.maintenanceRequest).toUpperCase()}
          </Print>
        </h2>
        <NoPrint force>
          <small className="float-left">
            {formatMessage(messages.required)}
          </small>
        </NoPrint>
        <Print printOnly name="workOrder">
          <div className="col-xs-12 col-lg-9">
            <div className="highlight-details">
              <p>
                <span>
                  <b>{formatMessage(messages.workOrderId)}: </b>
                  {workOrderId ? workOrderId : '---'}
                </span>
                <span>
                  {formatMessage(messages.createdAt)} {time} by{' '}
                  {`${firstName} ${lastName}`}
                </span>
              </p>
            </div>
          </div>
        </Print>
      </Col>
    </Row>
  );
};
