import React, { Component } from 'react';
import moment from 'moment';
import { omit, pathOr, isNil, isEmpty } from 'ramda';
import { Collapse, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Tooltip } from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from '../DocumentsTable/messages';
import type { Document } from '../types';
import type { Applicant } from '../../CreateApplication/types';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import { certificationTypeNameMap } from '../../AffordableQualificationTab/HUDTab/constants';
import { COMPLIANCE_DOCUMENT_TYPE } from '../constants';
import globalMessages from '../messages';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import type { FeatureFlags } from '../../../types';
import { DownloadIcon } from '@fortress-technology-solutions/fortress-component-library/Icons';

type Props = {
  intl: any,
  documents: Array<Document>,
  onDeleteClick: Function,
  handleDownloadDocument: Function,
  deletedApplicants: Array<any>,
  flags: FeatureFlags,
};

type State = {
  toggles: Object,
};

function changeToogle(id: string) {
  return (prevState) => {
    const newStateToggles = omit([id], prevState.toggles);
    newStateToggles[id] = !prevState.toggles[id];
    return { ...prevState, toggles: newStateToggles };
  };
}

export class DocumentsDetails extends Component<Props, State> {
  state = {
    toggles: {},
  };

  onToggleClick = (document: Document) => {
    const id = document.id;

    this.setState(changeToogle(id));
  };

  getNotes = (document: Document): string => {
    const aqEffectiveDate = pathOr(
      null,
      ['aqDocument', 'affordableQualification', 'voucherEffectiveDate'],
      document,
    );
    const aqCertificationType = pathOr(
      null,
      ['aqDocument', 'affordableQualification', 'certificationType'],
      document,
    );

    const notes = document.notes;

    if (!isNil(aqEffectiveDate) || !isNil(aqCertificationType)) {
      const certificationType = !isNil(aqCertificationType)
        ? certificationTypeNameMap[aqCertificationType]
        : '---';
      const effectiveDate = aqEffectiveDate || '---';
      const aqNote = `Certification: ${certificationType} - Effective Date: ${effectiveDate}`;
      return !isEmpty(notes) ? `${notes} | ${aqNote}` : aqNote;
    }

    return notes;
  };

  generatePopover = (document: Document) => {
    const name = `${document?.updatedBy?.firstName || ''} ${
      document?.updatedBy?.lastName || ''
    }`;
    const notes = this.getNotes(document);
    return (
      <Popover id={document.id}>
        {notes}
        {!notes && '(No notes)'}
        {notes && (
          <span className="notes-date">
            {!document?.updatedAt
              ? '---'
              : moment(document?.updatedAt).format('MMM DD, YYYY')}{' '}
            - {name}
          </span>
        )}
      </Popover>
    );
  };

  getApplicantName = (applicant: Applicant, applicantId: string) => {
    let name = '';
    if (!applicant) {
      const applicantObject = this.props.deletedApplicants.find(
        (a) => a.id === applicantId,
      );
      name = pathOr('---', ['name'], applicantObject);
    } else {
      if (applicant.applicantCustomer) {
        const customer = applicant.applicantCustomer.customer;
        name = `${customer.firstName} ${customer.lastName}`;
      }
      if (applicant.applicantMinor) {
        name = `${applicant.applicantMinor.firstName} ${applicant.applicantMinor.lastName}`;
      }
      if (applicant.applicantPet) {
        name = `${applicant.applicantPet.name}`;
      }
    }

    return name;
  };

  renderDocumentDetailRow = (
    document: Document,
    className: string,
    intl: Object,
  ) => {
    const notes = this.getNotes(document);
    const id = document.id;
    const applicantName = this.getApplicantName(
      document.applicant,
      document.applicantId,
    );
    const isComplianceDocument =
      document?.documentType?.type === COMPLIANCE_DOCUMENT_TYPE;
    return (
      <tr key={`${id}-RW`} className={className}>
        <td className="hidden-xs">
          {moment(document.createdAt).format('MMM DD, YYYY')}
        </td>
        {this.props.flags.uploadDocumentsInPortal && (
          <td className="row-detail">
            {document.wasUploadedFromPortal && (
              <i
                className="et-isolated-check"
                style={{ color: '#4CBB17', fontSize: 22 }}
              />
            )}
          </td>
        )}
        <td className="hidden-xs">{document.documentType.name}</td>
        <td>
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.householdMember)}:
          </span>
          {applicantName}
        </td>
        <td
          style={{
            whiteSpace: 'break-spaces',
            wordWrap: 'break-word',
          }}
        >
          <span className="row-detail">
            {this.props.intl.formatMessage(messages.documentName)}:
          </span>
          {document.name}
        </td>
        <td>
          <OverlayTrigger
            placement="left"
            overlay={this.generatePopover(document)}
          >
            <Button
              bsStyle="default"
              bsSize="small"
              className="btn-comments"
              disabled={!notes}
            >
              <i className="icon et-comment-words" />
            </Button>
          </OverlayTrigger>
        </td>
        <td className="text-center">
          <Button
            id="download-button"
            bsStyle="default"
            bsSize="small"
            style={{ padding: '2px 8px' }}
            onClick={() => this.props.handleDownloadDocument(id, document.name)}
          >
            <DownloadIcon />
          </Button>
          <ElementWithPermissions scope={['document-delete']}>
            <Tooltip
              title={
                isComplianceDocument
                  ? intl.formatMessage(globalMessages.complianceDeleteDisable)
                  : ''
              }
              arrow
              placement="top"
              style={{ display: 'inline-block' }}
            >
              <Button
                id="delete-button"
                bsStyle="default"
                bsSize="small"
                disabled={
                  document.documentType.manualRemovalDisabled ||
                  isComplianceDocument
                }
                onClick={this.props.onDeleteClick(document.id)}
              >
                <i className="icon et-trash" />
              </Button>
            </Tooltip>
          </ElementWithPermissions>
        </td>
      </tr>
    );
  };

  renderDocumentToggleDetail = (document: Document, intl: Object) => {
    const id = document.id;
    return (
      <Collapse key={`${id}-DT`} in={this.state.toggles[id]}>
        {this.renderDocumentDetailRow(
          document,
          'table-section hidden-sm hidden-md hidden-lg',
          intl,
        )}
      </Collapse>
    );
  };

  renderDocumentToggleHeader = (document: Document) => {
    const id = document.id;
    const className = this.state.toggles[id] ? 'expanded' : 'collapsed';
    return (
      <tr
        key={`${id}-HD`}
        className="visible-xs table-section"
        onClick={() => this.onToggleClick(document)}
      >
        <th className={`visible-xs clickable ${className}`}>
          {document.documentType.name}
        </th>
      </tr>
    );
  };

  render() {
    const { intl } = this.props;
    return (
      <tbody>
        {this.props.documents.reduce((previous, document: Document) => {
          return previous.concat([
            this.renderDocumentToggleHeader(document),
            this.renderDocumentToggleDetail(document, intl),
            this.renderDocumentDetailRow(
              document,
              'table-section hidden-xs',
              intl,
            ),
          ]);
        }, [])}
      </tbody>
    );
  }
}

export default withLDConsumer()(DocumentsDetails);
