import React from 'react';
import { Panel, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import ElementWithPermissions from '../../../components/ElementWithPermissions';
import styled from 'styled-components';
import type { Prospect } from '../../ProspectProfile/types';
import {
  Header,
  Title,
  Divider,
  DetailList,
  EditLink,
  EditIcon,
  ProspectNotes,
  NotesLabel,
  NotesCont,
  LastEdit,
} from '../../../components/ProfileVisualComponents';
import IconTitle from '../../../components/ProfileVisualComponents/IconTitle';

type Props = {
  currentRecord: Prospect,
  handleEdit: Function,
  intl: Object,
  latestLog: Object | null,
};

const formatPhone = (phoneNumber: string) => {
  return phoneNumber
    ? `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(
        3,
        3,
      )}-${phoneNumber.substr(6, 4)}`
    : '---';
};

const dashesIfNullOrUndefined = (data?: string) => (!data ? '---' : data);

const ProfilePanel = styled(Panel).attrs({
  className: 'block block-profile block-white-shadow',
})``;

const ProfileDetailsMessage = styled.span.attrs({
  className: 'profile-details',
})``;

const WordBreakSpan = styled.span`
  word-break: break-all;
`;

const DetailItemList = styled.li``;

const StatusTag = styled.span.attrs({
  className: 'tag status-tag',
  style: (props) => {
    return { backgroundColor: props.color };
  },
})``;

const ProfileDetails = ({
  currentRecord,
  handleEdit,
  intl,
  latestLog,
}: Props) => {
  const firstName = dashesIfNullOrUndefined(currentRecord.firstName);
  const lastName = dashesIfNullOrUndefined(currentRecord.lastName);
  const email = dashesIfNullOrUndefined(currentRecord.emailAddress);
  const phone = formatPhone(currentRecord.phoneNumber);
  const StatusColor = currentRecord.currentProspectStatus.color;
  const updatedByName =
    latestLog && latestLog.updatedBy
      ? `${latestLog.updatedByUser.firstName} ${latestLog.updatedByUser.lastName}`
      : '---';

  const renderLastStatus = latestLog ? (
    <LastEdit>
      <p>
        Last edit by {updatedByName} on{' '}
        {intl.formatDate(latestLog.updatedAt, {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
        })}{' '}
        @ {intl.formatTime(latestLog.updatedAt)}
      </p>
    </LastEdit>
  ) : null;

  const renderLastestNote =
    latestLog && latestLog.notes ? (
      <ProspectNotes>
        <NotesLabel>
          <FormattedMessage {...messages.notes} />{' '}
        </NotesLabel>
        <NotesCont>{latestLog.notes}</NotesCont>
      </ProspectNotes>
    ) : null;
  return (
    <ProfilePanel>
      <Header>
        <Title>
          <Row key="1">
            <Col xs={12} sm={9}>
              <h1 key="1">
                {firstName} {lastName}
              </h1>
            </Col>
          </Row>
        </Title>
      </Header>
      <Panel.Body>
        <ProfileDetailsMessage>
          <FormattedMessage {...messages.status} />{' '}
        </ProfileDetailsMessage>
        <StatusTag color={StatusColor}>
          {currentRecord.currentProspectStatus.name}
        </StatusTag>
        <ElementWithPermissions scope={['prospect-update']}>
          <EditLink onClick={handleEdit} style={{ display: 'inline' }}>
            <EditIcon />
            <FormattedMessage {...messages.edit} />
          </EditLink>
        </ElementWithPermissions>
        {renderLastStatus}
        {renderLastestNote}
        <Divider />
        <IconTitle message={messages.contactInformation} />
        <DetailList>
          <DetailItemList>
            {' '}
            <FormattedMessage {...messages.phone} />
            {` ${phone}`}
          </DetailItemList>
          <DetailItemList>
            {' '}
            <FormattedMessage {...messages.email} />
            <WordBreakSpan>
              <a href={`mailto:${email}`}> {email}</a>
            </WordBreakSpan>
          </DetailItemList>
        </DetailList>
      </Panel.Body>
    </ProfilePanel>
  );
};

export default ProfileDetails;
