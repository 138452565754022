import { defineMessages } from 'react-intl';

const messages = defineMessages({
  overview: {
    id: 'App.Home.Overview',
    defaultMessage: 'Overview',
  },
  calendar: {
    id: 'App.Home.Calendar',
    defaultMessage: 'Calendar',
  },
  noDataDescription: {
    id: 'App.Home.noDataDescription',
    defaultMessage: 'There is currently no data to display',
  },
  task: {
    id: 'App.Home.Task',
    defaultMessage: 'Task',
  },
  leasingActivity: {
    id: 'App.Home.LeasingActivity',
    defaultMessage: 'Leasing Activity',
  },
  propertyCompliance: {
    id: 'App.Home.PropertyCompliance',
    defaultMessage: 'Compliance',
  },
  portfolio: {
    id: 'App.Home.Portfolio',
    defaultMessage: 'Portfolio',
  },
  compliance: {
    id: 'App.Home.Compliance',
    defaultMessage: 'Compliance',
  },
  propertyInfo: {
    id: 'App.Home.PropertyInfo',
    defaultMessage: 'Property Info',
  },
  reputationMgmtTabTitle: {
    id: 'App.Home.ReputationMgtmtTabTitle',
    defaultMessage: 'Reputation Management',
  },
});

export default messages;
