import React, { Component } from 'react';
import type { Applicant } from '../../../CreateApplication/types';
import { Panel, Col } from 'react-bootstrap';
import classnames from 'classnames';
import { Header, Title } from '../../../../components/ProfileVisualComponents';
import { path, partial, pathOr } from 'ramda';
import type { DecisionStatus } from '../../types';
import ChecklistItem from '../ChecklistItem';
import messages from './messages';
import ApplicantApprovalContainer from './ApplicantApprovalContainer';

type Props = {
  intl: Object,
  applicant: Applicant,
  applicationDecisionStatus: Array<DecisionStatus>,
  handleChecklistOptionChange?: Function,
  handleEditApplicationForm: Function,
  approveApplicant?: Function,
  cancelApplicant?: Function,
  isResident?: boolean,
  deniedReasons?: ?any,
  isCanceled?: boolean,
  isCompliant: boolean,
  leaseExecuted?: boolean,
  leaseSentToPortal?: boolean,
};

type State = {
  open: boolean,
};

const ChecklistToggleApproval = ({ approved }: Object) => (
  <span
    className={classnames({
      'prosp-status-icon prosp-status-green': approved,
      'prosp-status-icon prosp-status-red': !approved,
    })}
  >
    <i
      className={classnames({
        'icon et-circle-check': approved,
        'icon et-clock': !approved,
      })}
    />
  </span>
);
class ApplicantChecklistToggle extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const {
      isResident,
      deniedReasons,
      isCanceled,
      leaseExecuted,
      leaseSentToPortal,
    } = this.props;
    const applicant = this.props.applicant;
    const applicationDecisionStatus = this.props.applicationDecisionStatus;
    const handleSubmit = (state, cancelCallback) => {
      if (!state.decision) {
        return;
      }
      if (state.decision === 'approve') {
        // $FlowFixMe
        this.props.approveApplicant(applicant, cancelCallback);
      } else {
        // $FlowFixMe
        this.props.cancelApplicant(applicant, state.cancelReasonId);
      }
    };
    const applicantType = path(['applicantType', 'name'], applicant);
    const checklistItems =
      path(['checklist', 'checklistItems'], applicant) || [];
    const checklistApproved =
      path(['checklist', 'approved'], applicant) || null;

    const isAdult = path(['applicantCustomer'], applicant);
    const applicantChecklistItems = checklistItems.map((item) => (
      <ChecklistItem
        key={item.id}
        item={item}
        applicationDecisionStatuses={applicationDecisionStatus}
        handleChecklistOptionChange={
          this.props.handleChecklistOptionChange
            ? this.props.handleChecklistOptionChange(applicant)
            : null
        }
        chooseMessage={this.props.intl.formatMessage(messages.chooseOption)}
      />
    ));
    let color = null;
    if (
      isAdult &&
      pathOr(false, ['customer', 'status'], isAdult) === 'Prior Resident'
    ) {
      color = '#A9A9A9';
    }
    return (
      <Panel expanded={this.state.open} onToggle>
        <Header>
          <Title>
            <div
              onClick={() =>
                !isResident && this.setState({ open: !this.state.open })
              }
              className="container-fluid"
            >
              <Col md={isResident ? 6 : 4}>
                <ChecklistToggleApproval approved={checklistApproved} />
                <h4 className="prosp-name" style={{ color }}>
                  {applicant.name}
                </h4>
              </Col>
              <Col md={4}>
                <div style={{ color }} className="prosp-label float-right">
                  {applicantType}
                </div>
              </Col>
              <Col
                md={isResident ? 2 : 3}
                className={isResident ? 'text-right' : ''}
              >
                {isAdult && (
                  <a
                    className={`btn btn-text no-margin ${
                      isResident || leaseExecuted || leaseSentToPortal
                        ? 'disabled'
                        : ''
                    }`}
                    onClick={partial(this.props.handleEditApplicationForm, [
                      applicant,
                    ])}
                  >
                    {this.props.intl.formatMessage(messages.applicationLabel)}
                    <i className="icon et-pencil" />
                  </a>
                )}
              </Col>
              {!isResident && (
                <Col md={1}>
                  <div className="panel-button">
                    {this.state.open && <i className="icon et-minus" />}
                    {!this.state.open && <i className="icon et-plus" />}
                  </div>
                </Col>
              )}
            </div>
          </Title>
          <Panel.Collapse>
            <Panel.Body>
              <table className="table table-prospects table-ledger table-documents">
                <thead className="table-titles table-header">
                  <tr>
                    <th>Checklist</th>
                    <th>Option</th>
                    <th>Status</th>
                    <th>Decision</th>
                  </tr>
                </thead>
                <tbody>{applicantChecklistItems}</tbody>
              </table>
            </Panel.Body>
            {!isCanceled && (
              <ApplicantApprovalContainer
                checklistApproved={checklistApproved}
                handleSubmit={handleSubmit}
                deniedReasons={deniedReasons}
                isCompliant={this.props.isCompliant}
              />
            )}
          </Panel.Collapse>
        </Header>
      </Panel>
    );
  }
}

export default ApplicantChecklistToggle;
