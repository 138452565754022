import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { pathOr } from 'ramda';
import { Button, Row, Col } from 'react-bootstrap';

import messages from './messages';
import { getMonthlyChargesToDisplay } from './utils';

import DashesIfNullOrUndefined from '../../../../components/DashesIfNullOrUndefined';
import FormattedDateOrDashes from '../../../../components/FormattedDateOrDashes';
import Spinner from '../../../../components/Spinner';
import { formatCurrency } from '../../../../utils';
import EditChargesModal from './editChargesModal';

type Props = {
  intl: Object,
  selectedCamRecord: Object,
  propertyId: string,
  organizationId: string,
  promptToaster: Function,
  isLoading: boolean,
  monthlyCharges: Object,
  refreshCharges: Function,
  isPriorResident?: boolean,
};

const CamMonthlyCharges = ({
  intl,
  propertyId,
  organizationId,
  promptToaster,
  isLoading,
  monthlyCharges,
  refreshCharges,
  isPriorResident = false,
}: Props) => {
  const [selectedPeriod, setSelectedPeriod] = useState('current');

  const monthlyChargesToDisplay = getMonthlyChargesToDisplay(
    monthlyCharges,
    selectedPeriod,
  );

  const [showEditChargesModal, setShowEditChargesModal] = useState(false);
  const [selectedCamCharge, setSelectedCamCharge] = useState({});

  const periodOptions = ['Past', 'Current', 'Future'].map((period, idx) => (
    <option key={idx} value={period.toLowerCase()}>
      {period}
    </option>
  ));

  return (
    <React.Fragment>
      <EditChargesModal
        intl={intl}
        propertyId={propertyId}
        organizationId={organizationId}
        promptToaster={promptToaster}
        show={showEditChargesModal}
        onHide={() => setShowEditChargesModal(false)}
        selectedCamCharge={selectedCamCharge}
        refreshMonthlyChargeTable={refreshCharges}
      />

      <Row className="padtop10 padbottom10 padleft5 padright5">
        <Col xs={9}>
          <h2>
            <i className="et-clipboard text-blue padright10" />
            <FormattedMessage {...messages.camMonthlyCharges} />
          </h2>
        </Col>
        <Col xs={3}>
          <select
            name="camStartDate"
            className="form-control"
            value={selectedPeriod}
            onChange={(evt) => setSelectedPeriod(evt.target.value)}
          >
            {periodOptions}
          </select>
        </Col>
      </Row>
      {/* Loading data... */}
      {isLoading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <div className="table-scroll table-units-container">
          <table className="table table-fixed-headers table-prospects table-striped">
            <thead className="table-header">
              <tr>
                <th>
                  <FormattedMessage {...messages.camPool} />
                </th>
                <th>
                  <FormattedMessage {...messages.transactionType} />
                </th>
                <th>
                  <FormattedMessage {...messages.transactionCode} />
                </th>
                <th>
                  <FormattedMessage {...messages.amount} />
                </th>
                <th>
                  <FormattedMessage {...messages.startDate} />
                </th>
                <th>
                  <FormattedMessage {...messages.endDate} />
                </th>
                <th>
                  <FormattedMessage {...messages.edit} />
                </th>
              </tr>
            </thead>
            <tbody>
              {renderMonthlyChargesRows(
                intl,
                monthlyChargesToDisplay,
                setShowEditChargesModal,
                setSelectedCamCharge,
                isPriorResident,
              )}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  );
};

const renderMonthlyChargesRows = (
  intl,
  monthlyCharges,
  setShowEditChargesModal,
  setSelectedCamCharge,
  isPriorResident,
) => {
  return (
    monthlyCharges &&
    monthlyCharges
      .sort((chargeA, chargeB) => {
        const nameA = pathOr('', ['camPool'], chargeA);
        const nameB = pathOr('', ['camPool'], chargeB);
        return nameA.toLowerCase() < nameB.toLowerCase() ? -1 : 1;
      })
      .map((charge, i) => {
        const camPool = pathOr(null, ['camPool'], charge);
        const transactionType = pathOr(null, ['transactionType'], charge);
        const transactionCode = pathOr(null, ['transactionCode'], charge);
        const amount = pathOr(null, ['amount'], charge);
        const startDate = pathOr(null, ['startDate'], charge);
        const endDate = pathOr(null, ['endDate'], charge);
        const allowEditing = pathOr(false, ['allowEditing'], charge);

        const disableEditButton = isPriorResident || !allowEditing;

        return (
          <tr key={i}>
            <td>
              <DashesIfNullOrUndefined data={camPool} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={transactionType} />
            </td>
            <td>
              <DashesIfNullOrUndefined data={transactionCode} />
            </td>
            <td>{amount !== null ? formatCurrency(intl, amount) : '---'}</td>
            <td>
              <FormattedDateOrDashes value={startDate} format="MM/DD/YYYY" />
            </td>
            <td>
              <FormattedDateOrDashes value={endDate} format="MM/DD/YYYY" />
            </td>
            <td>
              <Button
                id="download-button"
                bsStyle="default"
                bsSize="small"
                onClick={() => {
                  setShowEditChargesModal(true);
                  setSelectedCamCharge(charge);
                }}
                disabled={disableEditButton}
              >
                <i className="icon et-pencil" />
              </Button>
            </td>
          </tr>
        );
      })
  );
};

export default CamMonthlyCharges;
