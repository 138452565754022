import type { Action } from '../App/types';
import * as ActionTypes from './constants';
import type { Unit, UnitFloorplan } from './types';

export function getUnitById(payload: any): Action<any> {
  return {
    type: ActionTypes.GET_UNIT_BY_ID,
    payload,
  };
}

export function getUnitByIdError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_UNIT_BY_ID_ERROR,
    payload: error,
  };
}

export function getUnitByIdSuccess(unit: Unit): Action<Unit> {
  return {
    type: ActionTypes.GET_UNIT_BY_ID_SUCCESS,
    payload: unit,
  };
}

export function getFloorplanById(payload: any): Action<any> {
  return {
    type: ActionTypes.GET_FLOORPLAN_BY_ID,
    payload,
  };
}

export function getFloorplanByIdError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_FLOORPLAN_BY_ID_ERROR,
    payload: error,
  };
}

export function getFloorplanByIdSuccess(
  floorplan: UnitFloorplan,
): Action<UnitFloorplan> {
  return {
    type: ActionTypes.GET_FLOORPLAN_BY_ID_SUCCESS,
    payload: floorplan,
  };
}

export function getUnitStatuses(payload: any): Action<any> {
  return {
    type: ActionTypes.GET_UNIT_STATUSES,
    payload,
  };
}

export function getUnitStatusesError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_UNIT_STATUSES_ERROR,
    payload: error,
  };
}

export function getUnitStatusesSuccess(statuses: Object): Action<Unit> {
  return {
    type: ActionTypes.GET_UNIT_STATUSES_SUCCESS,
    payload: statuses,
  };
}

export function getIncomeAverageData(): Action<any> {
  return {
    type: ActionTypes.GET_AVERAGE_INCOME_DATA,
    payload: {},
  };
}

export function getIncomeAverageDataError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_AVERAGE_INCOME_DATA_ERROR,
    payload: error,
  };
}

export function getIncomeAverageDataSuccess(
  incomeAverageData: Object,
): Action<Unit> {
  return {
    type: ActionTypes.GET_AVERAGE_INCOME_DATA_SUCCESS,
    payload: incomeAverageData,
  };
}

export function updateUnit(payload: any): Action<any> {
  return {
    type: ActionTypes.UPDATE_UNIT,
    payload,
  };
}

export function updateUnitError(error: Error): Action<Error> {
  return {
    type: ActionTypes.UPDATE_UNIT_ERROR,
    payload: error,
  };
}

export function updateUnitSuccess(unit: Object): Action<Unit> {
  return {
    type: ActionTypes.UPDATE_UNIT_SUCCESS,
    payload: unit,
  };
}

export function cleanLoadedUnit(): Action<null> {
  return {
    type: ActionTypes.CLEAN_LOADED_UNITS,
    payload: null,
  };
}
