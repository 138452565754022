import { defineMessages } from 'react-intl';

const messages = defineMessages({
  docTitle: {
    id: 'App.ManagePriorResidents.DocTitle',
    defaultMessage: 'Fortress - Manage Prior Residents',
  },
  docTitleCommercial: {
    id: 'App.ManagePriorResidents.DocTitleCommercial',
    defaultMessage: 'Fortress - Manage Prior Tenants',
  },
  title: {
    id: 'App.ManagePriorResidents.Title',
    defaultMessage: 'Manage Prior Residents',
  },
  titleCommercial: {
    id: 'App.ManagePriorResidents.TitleCommercial',
    defaultMessage: 'Manage Prior Tenants',
  },
  priorResidents: {
    id: 'App.ManagePriorResidents.PriorResidents',
    defaultMessage: 'Prior Residents',
  },
  priorTenants: {
    id: 'App.ManagePriorResidents.PriorTenants',
    defaultMessage: 'Prior Tenants',
  },
  texting: {
    id: 'App.ManagePriorResidents.Texting',
    defaultMessage: 'Texting',
  },
  unit: {
    id: 'App.ManagePriorResidents.Unit',
    defaultMessage: 'Unit',
  },
  moveInDate: {
    id: 'App.ManagePriorResidents.MoveInDate',
    defaultMessage: 'Move-In Date',
  },
  moveOutDate: {
    id: 'App.ManagePriorResidents.MoveOutDate',
    defaultMessage: 'Move-Out Date',
  },
  FASDate: {
    id: 'App.ManagePriorResidents.FASDate',
    defaultMessage: 'FAS Date',
  },
  lateFees: {
    id: 'App.ManagePriorResidents.LateFees',
    defaultMessage: 'Months Late Fees Charged',
  },
  underEviction: {
    id: 'App.ManagePriorResidents.UnderEviction',
    defaultMessage: 'Under Eviction',
  },
  doNotRenew: {
    id: 'App.ManagePriorResidents.DoNotRenew',
    defaultMessage: 'Do Not Renew',
  },
  collectionStatus: {
    id: 'App.ManagePriorResidents.CollectionStatus',
    defaultMessage: 'Collections Status',
  },
  claimCreated: {
    id: 'App.ManagePriorResidents.ClaimCreated',
    defaultMessage: 'Claim Created',
  },
  balance: {
    id: 'App.ManagePriorResidents.Balance',
    defaultMessage: 'Balance',
  },
  residentBalance: {
    id: 'App.ManagePriorResidents.ResidentBalance',
    defaultMessage: 'Resident Balance',
  },
  subsidyBalance: {
    id: 'App.ManagePriorResidents.SubsidyBalance',
    defaultMessage: 'Subsidy Balance',
  },
});

export default messages;
