import type { RenewalFormInfo } from '../types.js';
import { type } from 'ramda';
import moment from 'moment';
import messages from '../messages';

const validateDateFormat = (intl, date) => {
  const formattedDate = moment(date);
  const todayDate = moment(Date.now());
  if (date === 'MM/DD/YYYY') {
    return intl.formatMessage(messages.mustSelectADate);
  } else if (!formattedDate.isValid() || type(date) === 'String') {
    return intl.formatMessage(messages.invalidDateFormat);
  } else if (
    formattedDate.isBefore(todayDate) ||
    formattedDate.isAfter(todayDate.add(120, 'days'))
  ) {
    return intl.formatMessage(messages.invalidDateValue);
  }
};

const validatePairedValues = (intl, errors, values, i) => {
  const length = values[`offer${i}TermId`];
  const rent = values[`offer${i}Rent`];
  const missingLength = !length && rent;
  const missingRent = length && !rent;

  if (missingLength) {
    errors[`offer${i}TermId`] = intl.formatMessage(
      messages.mustChooseATermLength,
    );
  } else if (missingRent) {
    errors[`offer${i}Rent`] = intl.formatMessage(
      messages.mustEnterALeaseRentAmount,
    );
  }
  if (rent && +rent <= 0) {
    errors[`offer${i}Rent`] = intl.formatMessage(messages.valueGreaterThanZero);
  }
};

const validate = (values: RenewalFormInfo, { intl }: Object) => {
  const errors = { transactionCodes: [] };

  errors.offerExpirationDate = !values.offerExpirationDate;
  errors.offerExpirationDate = validateDateFormat(
    intl,
    values.offerExpirationDate,
  );

  if (!values.offer1Rent) {
    errors.offer1Rent = intl.formatMessage(messages.mustEnterALeaseRentAmount);
  }
  if (!values.offer1TermId) {
    errors.offer1TermId = intl.formatMessage(messages.mustChooseATermLength);
  }

  validatePairedValues(intl, errors, values, '1');
  validatePairedValues(intl, errors, values, '2');
  validatePairedValues(intl, errors, values, '3');

  const offer3Exists = values.offer3TermId || values.offer3Rent;
  const offerTwoExists = values.offer2TermId || values.offer2Rent;
  if (offer3Exists && !offerTwoExists) {
    errors.offer2Rent = intl.formatMessage(messages.mustHaveOfferTwo);
  }

  values.transactionCodes?.forEach((codeValues, i) => {
    errors.transactionCodes[i] = {};
    if (codeValues.id !== 'default') {
      const totalOffersAmount = [
        codeValues.offer1,
        codeValues.offer2,
        codeValues.offer3,
      ].reduce((a, b) => Number(a) + Number(b), 0);

      // no empty offer amounts for all 3 offers
      if (totalOffersAmount <= 0) {
        errors.transactionCodes[i].id = intl.formatMessage(
          messages.mustHaveOfferAmount,
        );
      }

      // no negative offer amounts
      if (codeValues.offer1 && +codeValues.offer1 < 0) {
        errors.transactionCodes[i].offer1 = intl.formatMessage(
          messages.valuePositive,
        );
      }
      if (codeValues.offer2 && +codeValues.offer2 < 0) {
        errors.transactionCodes[i].offer2 = intl.formatMessage(
          messages.valuePositive,
        );
      }
      if (codeValues.offer3 && +codeValues.offer3 < 0) {
        errors.transactionCodes[i].offer3 = intl.formatMessage(
          messages.valuePositive,
        );
      }
    }
  });

  return errors;
};

export default validate;
