import request, { apiURL, getRequestOptions } from '../utils/api';

export default class DelinquencyServiceV2 {
  getAllDelinquencies({ organizationId, propertyId, options }) {
    return request(
      `${apiURL}/${organizationId}/${propertyId}/manage-delinquent-and-prepaid`,
      {
        ...options,
        ...getRequestOptions(),
      },
    );
  }
}
