import moment from 'moment';
import { isNil, pathOr } from 'ramda';

const hasDelinquentBalance = (row: Object) => !isNil(row.delinquentBalance);
const hasPrepaidBalance = (row: Object) => !isNil(row.prepaidBalance);
const onNTV = (row: Object) =>
  row.unitStatus === 'Occupied / On Notice to Vacate';
const monthToMonthLeases = (row: Object) =>
  isNil(row.endDate) || moment(row.endDate).isBefore(moment());
const isExpiringIn0_30Days = (row: Object) => {
  const differenceOfDaysFromEndDate = moment(row.endDate).diff(
    moment(),
    'days',
  );
  return differenceOfDaysFromEndDate >= 0 && differenceOfDaysFromEndDate < 31;
};
const isExpiringIn31_60Days = (row: Object) => {
  const differenceOfDaysFromEndDate = moment(row.endDate).diff(
    moment(),
    'days',
  );
  return differenceOfDaysFromEndDate > 30 && differenceOfDaysFromEndDate < 61;
};
const isExpiringIn61_90Days = (row: Object) => {
  const differenceOfDaysFromEndDate = moment(row.endDate).diff(
    moment(),
    'days',
  );
  return differenceOfDaysFromEndDate > 60 && differenceOfDaysFromEndDate < 91;
};
const isExpiringIn91_120Days = (row: Object) => {
  const differenceOfDaysFromEndDate = moment(row.endDate).diff(
    moment(),
    'days',
  );
  return differenceOfDaysFromEndDate > 90 && differenceOfDaysFromEndDate < 121;
};
const approved = (row: Object) => row.status === 'Approved';
const pendingApproval = (row: Object) => row.status === 'In Process';

const calculateDateRange = (
  prospectDate,
  dateFrom,
  dateTo,
  status,
  filterName,
) => {
  const momentDateFrom = dateFrom ? moment(dateFrom) : undefined;
  const momentDateTo = dateTo ? moment(dateTo) : undefined;
  const matchDateRangeFrom = momentDateFrom
    ? prospectDate.isSameOrAfter(momentDateFrom, 'day')
    : true;
  const matchDateRangeTo = momentDateTo
    ? prospectDate.isSameOrBefore(momentDateTo, 'day')
    : true;
  return (
    matchDateRangeFrom && matchDateRangeTo && status.startsWith(filterName)
  );
};

const active = (row: Object) =>
  pathOr('', ['currentProspectStatus', 'name'], row).startsWith('Active');

const hold = (row: Object) =>
  pathOr('', ['currentProspectStatus', 'name'], row).startsWith('Hold');

const deadOrLost = (row: Object, dateFrom: any = '', dateTo: any = '') => {
  const status = pathOr('', ['currentProspectStatus', 'name'], row);
  if (dateFrom || dateTo) {
    const lastProspectStatus = pathOr(
      undefined,
      ['prospectStatusChangeLog', '0', 'createdAt'],
      row,
    );
    if (lastProspectStatus) {
      return calculateDateRange(
        moment(lastProspectStatus),
        dateFrom,
        dateTo,
        status,
        'Dead',
      );
    }
    return false;
  }
  return status.startsWith('Dead');
};

const numberOfBeds = (row: Object, nBeds: number) =>
  pathOr(0, ['prospectPreferences', 'nBeds'], row) === nBeds;

export const residentsNumberOfBeds = (row: Object, filter: string) => {
  const [, nBeds] = filter.split('-');

  return row.nBeds === Number(nBeds);
};

export const residentsUnitLevel = (row: Object, filter: string) => {
  const [, unitLevel] = filter.split(/-(.*)/);

  return row.unitFloorLevel === unitLevel;
};

export const residentsBuildingNumber = (row: Object, filter: string) => {
  const [, buildingNumber] = filter.split(/-(.*)/);

  return row.buildingNumber === buildingNumber;
};

export const residentsUnitAmenities = (row: Object, filter: string) => {
  const [, unitAmenity] = filter.split(/-(.*)/);

  return row.unitAmenities.includes(unitAmenity);
};

export {
  hasDelinquentBalance,
  isExpiringIn61_90Days,
  isExpiringIn91_120Days,
  hasPrepaidBalance,
  onNTV,
  monthToMonthLeases,
  isExpiringIn0_30Days,
  isExpiringIn31_60Days,
  approved,
  pendingApproval,
  active,
  hold,
  deadOrLost,
  numberOfBeds,
};
