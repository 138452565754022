import { useQuery, QueryOptions } from 'react-query';

import HousingAssistanceVoucherService from '../../services/housingAssistanceVoucherService';

const useHousingAssistanceVouchersQuery = ({
  organizationId,
  propertyId,
  householdId,
  options = {},
}: {
  organizationId: string,
  propertyId: string,
  householdId: string,
  options?: QueryOptions,
}) => {
  const housingAssistanceVoucherService = new HousingAssistanceVoucherService();
  const queryKey = [
    'getAllVouchersByHouseholdId',
    organizationId,
    propertyId,
    householdId,
  ];

  const { data, isLoading, refetch } = useQuery(
    queryKey,
    async () => {
      const response =
        await housingAssistanceVoucherService.getAllVouchersByHouseholdId(
          organizationId,
          propertyId,
          householdId,
        );
      window.dispatchEvent(new CustomEvent('VOUCHERS_UPDATED'));
      return response;
    },
    { refetchOnWindowFocus: false, ...options },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};

export default useHousingAssistanceVouchersQuery;
