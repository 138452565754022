import React from 'react';
import { FormGroup, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { messages } from './messages';
import { TextInput } from '../BasicDetails/TextInput';
import { removeNonNumericCharacters } from '../../../utils';

type Props = {
  intl: Object,
  title: string,
  onChange: Function,
  data: Object,
};

export const BankInformationForm = ({ intl, title, onChange, data }: Props) => {
  const { bankName, bankAccountNumber, bankRoutingNumber, comment } = data;
  return (
    <FormGroup className="applicant-grp" style={{ margin: 25 }}>
      <Row>
        <h2>{intl.formatMessage(title)}</h2>
      </Row>
      <Row>
        <div className="container-of-inputs">
          <Col md={4}>
            <label>
              <FormattedMessage {...messages.bankName} />
            </label>
          </Col>
          <Col md={4}>
            <label>
              <FormattedMessage {...messages.accountNumber} />
            </label>
          </Col>
          <Col md={4}>
            <label>
              <FormattedMessage {...messages.routingNumber} />
            </label>
          </Col>
        </div>
      </Row>
      <Row>
        <div className="container-of-inputs">
          <Col md={4}>
            <TextInput
              name="bankName"
              value={bankName}
              editMode={true}
              onChange={onChange}
              placeholder="Bank Name"
              maxWidth="100%"
              maxLength={255}
            />
          </Col>
          <Col md={4}>
            <TextInput
              name="bankAccountNumber"
              value={bankAccountNumber}
              editMode={true}
              placeholder="Account Number"
              maxWidth="100%"
              onChange={({ target: { name, value } }) => {
                onChange({
                  target: {
                    name,
                    value: removeNonNumericCharacters(value),
                  },
                });
              }}
              maxLength={255}
            />
          </Col>
          <Col md={4}>
            <TextInput
              name="bankRoutingNumber"
              value={bankRoutingNumber}
              editMode={true}
              placeholder="Routing Number"
              maxWidth="100%"
              onChange={({ target: { name, value } }) => {
                onChange({
                  target: {
                    name,
                    value: removeNonNumericCharacters(value),
                  },
                });
              }}
              maxLength={255}
            />
          </Col>
        </div>
      </Row>
      <Row>
        <div className="container-of-inputs">
          <Col md={12}>
            <label>
              <FormattedMessage {...messages.comment} />
            </label>
          </Col>
        </div>
      </Row>
      <Row>
        <div className="container-of-inputs">
          <Col md={12}>
            <TextInput
              name="comment"
              value={comment}
              editMode={true}
              onChange={onChange}
              placeholder="Comment"
              maxWidth="100%"
              maxLength={255}
            />
          </Col>
        </div>
      </Row>
    </FormGroup>
  );
};
