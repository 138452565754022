import React from 'react';
import { Col, Row } from 'react-bootstrap';
import messages from './messages';
import ConcessionForm from './concessionForm';

const getFormInitalValues = (lease) => ({
  oneTimeRentConcession: lease.oneTimeRentConcession,
  monthlyRentConcession: lease.monthlyRentConcession,
  otherDiscountConcession: lease.otherDiscountConcession,
  oneTimeRentMonth: lease.oneTimeRentMonth,
  monthlyRentMonth: lease.monthlyRentMonth,
  discountDescription: lease.discountDescription,
});

function Concessions({ intl, isEditable, lease }: Object) {
  return (
    <React.Fragment>
      <Row className="padtop10 padbottom10">
        <Col xs={12}>
          <h2>
            <i className="et-money text-blue padright10" />
            {intl.formatMessage(messages.concessions)}
          </h2>
        </Col>
      </Row>
      <Row className="container-fluid">
        <ConcessionForm
          intl={intl}
          isEditable={isEditable}
          lease={lease}
          initialValues={getFormInitalValues(lease)}
        />
      </Row>
    </React.Fragment>
  );
}
export default Concessions;
