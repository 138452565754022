import * as React from 'react';
import { Grid } from '@fortress-technology-solutions/fortress-component-library/Molecules';
import Spinner from '../../../components/Spinner';
import {
  Stack,
  Typography,
} from '@fortress-technology-solutions/fortress-component-library/Atoms';
import messages from '../messages';
import { useMemo } from 'react';
import ReviewItem from '../ReviewsCard/ReviewItem';
import type { Review, ResponseSubmitSuccessHandler } from '../types';

type Props = {
  reviews: Review[],
  formatMessage: Function,
  showSpinner: boolean,
  onResponseDeleteSuccess: Function,
  onResponseSubmitSuccess: ResponseSubmitSuccessHandler,
};

export const ReviewsCardContent = (props: Props) => {
  const {
    reviews,
    formatMessage,
    showSpinner,
    isFiltersPopulated,
    onResponseDeleteSuccess,
    onResponseSubmitSuccess,
  } = props;
  const reviewItemUI = useMemo(
    () =>
      reviews.map((r, i) => (
        <ReviewItem
          key={i}
          {...r}
          onResponseDeleteSuccess={onResponseDeleteSuccess}
          onResponseSubmitSuccess={onResponseSubmitSuccess}
        />
      )),
    [reviews, onResponseDeleteSuccess, onResponseSubmitSuccess],
  );

  if (showSpinner) {
    return <Spinner />;
  }
  return (
    <Grid item xs={12} marginTop={2}>
      {reviews.length === 0 && isFiltersPopulated && (
        <>
          <Stack justifyContent="center" alignItems="center" padding={12}>
            <Typography fontSize={24}>
              {formatMessage(messages.filterNoResults)}
            </Typography>
            <Typography fontSize={16} marginTop={2}>
              {formatMessage(messages.filterSubtitle)}
            </Typography>
          </Stack>
        </>
      )}
      {reviews.length === 0 && !isFiltersPopulated ? (
        <Stack justifyContent="center" alignItems="center" marginBottom={1}>
          <Typography fontSize={16}>
            {formatMessage(messages.noReviewsFound)}
          </Typography>
        </Stack>
      ) : (
        reviewItemUI
      )}
    </Grid>
  );
};
