// $FlowFixMe
import React, { Fragment } from 'react';
import { reduxForm, Form, Field } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Row, Col } from 'react-bootstrap';
import {
  renderNumberField,
  renderTextField,
  renderSelectField,
} from '../../../../../utils/redux-form-helper';

import Spinner from '../../../../../components/Spinner';
import { FORM_NAME } from '../../constants';
import messages from '../messages';
import { useAsyncStateOptions } from '../hooks';
import validate from './validate';

type ReduxFormProps = {
  handleSubmit: Function,
};

type Props = ReduxFormProps & {
  onSubmit: Function,
  initialValues: Object,
  handleDeleteVehicle: Function,
  isAffordableProperty: boolean,
};

const ADA_OPTIONS = [
  {
    value: 'true',
    text: 'Yes',
  },
  {
    value: 'false',
    text: 'No',
  },
];

const GeneralUnitInformationForm = ({
  handleSubmit,
  initialValues,
  onSubmit,
  isAffordableProperty,
  flags,
}: Props) => {
  const unitOptions = useAsyncStateOptions();

  const { removeUnitFloorLevelRestrictions } = flags;

  return (
    <Fragment>
      {!unitOptions ? (
        <Spinner />
      ) : (
        <Form
          className="form-container container-fluid"
          onSubmit={handleSubmit}
        >
          <Grid fluid>
            <Row>
              <Col xs={12} sm={6}>
                <FormattedMessage
                  {...(removeUnitFloorLevelRestrictions
                    ? messages.unitFloorLevel
                    : messages.unitLevel)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="unitFloorLevel"
                  component={
                    removeUnitFloorLevelRestrictions
                      ? renderTextField
                      : renderNumberField
                  }
                  label="Unit Level"
                  className="input form-control"
                  maxLength={removeUnitFloorLevelRestrictions ? undefined : 2}
                  max={25}
                  min={0}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormattedMessage {...messages.unitAddress} />
              </Col>
              <Col xs={12} sm={6}>
                <Field
                  name="streetAddress1"
                  component={renderTextField}
                  label="Street Address 1"
                  placeholder="Address Line 1"
                  className="input form-control"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} />
              <Col xs={12} sm={6}>
                <Field
                  name="streetAddress2"
                  component={renderTextField}
                  label="Street Address 2"
                  placeholder="Address Line 2"
                  className="input form-control"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} />
              <Col xs={12} sm={6}>
                <Field
                  name="city"
                  component={renderTextField}
                  label="City"
                  placeholder="City"
                  className="input form-control"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} />
              <Col xs={6} sm={3}>
                <Field
                  name="state"
                  component={renderSelectField}
                  className="input form-control no-border no-padding"
                  options={unitOptions.stateOptions}
                />
              </Col>
              <Col xs={6} sm={3}>
                <Field
                  name="zipCode"
                  component={renderTextField}
                  label="Zip Code / Postal Code"
                  placeholder="Zip Code / Postal Code"
                  className="input form-control"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <FormattedMessage {...messages.unitADA} />
              </Col>
              <Col xs={6} sm={6}>
                <Field
                  name="isADA"
                  component={renderSelectField}
                  className="input form-control no-border no-padding"
                  options={ADA_OPTIONS}
                />
              </Col>
            </Row>
            {isAffordableProperty && (
              <Row>
                <Col xs={12} sm={6}>
                  <FormattedMessage {...messages.altXMLUnitNumber} />
                </Col>
                <Col xs={12} sm={6}>
                  <Field
                    name="xmlUnitNumber"
                    component={renderTextField}
                    label="Alternative Unit Name (XML)"
                    placeholder="Alternative Unit Name (XML)"
                    className="input form-control"
                    maxLength={255}
                  />
                </Col>
              </Row>
            )}
          </Grid>
        </Form>
      )}
    </Fragment>
  );
};

export default injectIntl(
  reduxForm({
    form: FORM_NAME,
    validate,
    touchOnChange: true,
  })(GeneralUnitInformationForm),
);
