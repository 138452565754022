import { defineMessages } from 'react-intl';

const messages = defineMessages({
  pendingDescriptionReversal: {
    id: 'App.ManageMiscTransactions.pendingDescriptionReversal',
    defaultMessage: 'Transaction reversal pending.',
  },
  successDescriptionReversal: {
    id: 'App.ManageMiscTransactions.successDescriptionReversal',
    defaultMessage: 'Transaction reversal pending.',
  },
  errorDescriptionReversal: {
    id: 'App.ManageMiscTransactions.errorDescriptionReversal',
    defaultMessage: 'Transaction could not be reversed.',
  },
  pendingHeaderReversal: {
    id: 'App.ManageMiscTransactions.pendingHeaderReversal',
    defaultMessage: 'Pending',
  },
  successHeaderReversal: {
    id: 'App.ManageMiscTransactions.successHeaderReversal',
    defaultMessage: 'Success',
  },
  errorHeaderReversal: {
    id: 'App.ManageMiscTransactions.errorHeaderReversal',
    defaultMessage: 'Error',
  },
});

export default messages;
