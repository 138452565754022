import { isNil } from 'ramda';

import {
  validateEmail,
  validatePhoneNumber,
  validateRequired,
} from '../../../utils/validations';

import formMessages from '../../../containers/ShortLeaseApplication/messages';

import type { ContactInformation } from './types';

const validate = (values: ContactInformation, { intl }: Object) => {
  const errors = {};

  if (isNil(values)) {
    errors.emailAddress = intl.formatMessage(formMessages.required);
    return errors;
  }

  if (!validateRequired('emailAddress', values)) {
    errors.emailAddress = intl.formatMessage(formMessages.required);
  }
  if (!validateRequired('phoneNumber', values)) {
    errors.phoneNumber = intl.formatMessage(formMessages.required);
  }

  if (!errors.emailAdress && !validateEmail(values.emailAddress)) {
    errors.emailAddress = intl.formatMessage(formMessages.invalidValue);
  }

  if (!errors.phoneNumber && !validatePhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = intl.formatMessage(formMessages.invalidValue);
  }

  return errors;
};

export default validate;
