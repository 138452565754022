import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { createConfirmation, confirmable } from 'react-confirm';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import {
  renderTextAreaField,
  renderSelectField,
} from '../../../utils/redux-form-helper';
import validate from './validate';
import messages from './messages';
import type { GlobalState, SelectOption } from '../../App/types';

type Props = {
  intl: Object,
  locale: string,
  isSaving: boolean,
  hasError: boolean,
  store: Object,
  pristine: boolean,
  valid: boolean,
  warning: string,
  error: Object,
  show: boolean,
  dismiss: Function,
  confirmation: any,
  options: Object,
  proceed: Function,
  applicationId: string,
  documentTypes: Array<SelectOption>,
  householdMembers: Array<SelectOption>,
  uploadDocument: Function,
};

type StateProps = {
  documentTypeId: string,
  applicantId: string,
  notes: string,
};

type State = {
  file: any,
  fileName: string,
  fileErrorMessage: string,
  showCancelConfirmation: boolean,
  showFileError: boolean,
  saveAndAddNew: boolean,
};

export class Uploader extends Component<Props & StateProps, State> {
  state = {
    file: undefined,
    fileName: '',
    fileErrorMessage: '',
    showCancelConfirmation: false,
    showFileError: false,
    saveAndAddNew: false,
  };

  componentDidUpdate(prevProps: any) {
    if (this.shouldCloseUploader(prevProps) && this.state.saveAndAddNew) {
      this.setState({ file: undefined, fileName: '' });
    }
    if (this.shouldCloseUploader(prevProps) && !this.state.saveAndAddNew) {
      this.props.proceed();
    }
  }

  shouldCloseUploader(prevProps: any) {
    const isSavinghasChanged = prevProps.isSaving !== this.props.isSaving;
    return isSavinghasChanged && !this.props.isSaving && !this.props.hasError;
  }

  handleUpload = (event: any) => {
    this.setState({ showFileError: false });
    const fileName = event.target.value.replace(/.*(\/|\\)/, '');
    const file = event.target.files[0];
    this.setState({ fileName, file });
    this.validateSize(file);
  };

  validateSize = (file: Object) => {
    if (file.size > 100 * 1000 * 1000) {
      // 100mb
      this.setState({
        showFileError: true,
        fileErrorMessage: this.props.intl.formatMessage(messages.maxFileSize),
      });
    }
  };

  saveAndClose = () => {
    if (this.state.file) {
      const document = this.getDocument();
      this.props.uploadDocument({
        ...document,
        file: this.state.file,
      });
      this.setState({ saveAndAddNew: false });
    } else {
      this.setState({
        showFileError: true,
        fileErrorMessage: this.props.intl.formatMessage(messages.required),
      });
    }
  };

  saveAndAddNew = () => {
    if (this.state.file) {
      const document = this.getDocument();
      this.props.uploadDocument({
        ...document,
        file: this.state.file,
      });
      this.setState({ saveAndAddNew: true });
    } else {
      this.setState({
        showFileError: true,
        fileErrorMessage: this.props.intl.formatMessage(messages.required),
      });
    }
  };

  getDocument() {
    return {
      applicationId: this.props.applicationId,
      documentTypeId: this.props.documentTypeId,
      applicantId: this.props.applicantId,
      notes: this.props.notes,
    };
  }

  renderCancelConfirmation = () => {
    return (
      <div className="modal-confirm">
        <h1>
          {this.props.intl.formatMessage(messages.cancelConfirmationHeader)}
        </h1>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="primary"
            className="btn-delete pull-right"
            onClick={this.props.dismiss}
          >
            {this.props.intl.formatMessage(messages.yes)}
          </Button>
        </Col>
        <Col xs={12} sm={6}>
          <Button
            bsStyle="default"
            className="pull-left"
            onClick={() => this.cancelConfirmationToggle(false)}
          >
            {this.props.intl.formatMessage(messages.no)}
          </Button>
        </Col>
      </div>
    );
  };

  cancelConfirmationToggle = (visible: boolean) => {
    this.setState({
      showCancelConfirmation: visible,
    });
  };

  cancel = () => {
    if (this.state.showCancelConfirmation || this.props.isSaving) return;
    if (!this.props.pristine) {
      this.cancelConfirmationToggle(true);
    } else {
      this.props.dismiss();
    }
  };

  isSaveButtonDisabled = () => {
    const hasNoFile = !(this.state.file !== undefined);
    return (
      this.props.pristine ||
      !this.props.valid ||
      this.state.showCancelConfirmation ||
      this.props.isSaving ||
      this.state.showFileError ||
      hasNoFile
    );
  };

  render() {
    return (
      <form encType="multipart/form-data">
        <div className="mainbody dashboard-view collapsed ledger-documents">
          <Modal
            backdrop
            bsSize="lg"
            show={this.props.show}
            onHide={this.cancel}
          >
            <Modal.Header closeButton>
              <i className="icon et-upload" />
              <Modal.Title componentClass="h1">
                {this.props.intl.formatMessage(messages.title)}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.showCancelConfirmation &&
                this.renderCancelConfirmation()}
              {!this.props.isSaving && (
                <Row>
                  <Col xs={12} sm={4}>
                    <div className="form-group">
                      <label className="input">
                        {this.props.intl.formatMessage(messages.fileName)}
                      </label>
                      <input
                        className="inputfile inputfile-custom"
                        id="upload1"
                        type="file"
                        name="file"
                        onChange={this.handleUpload}
                      />
                      <label htmlFor="upload1">
                        <span>{this.state.fileName}</span>
                        <strong>
                          {this.props.intl.formatMessage(messages.browse)}
                        </strong>
                      </label>
                      {this.state.showFileError && (
                        <span className="has-error">
                          {this.state.fileErrorMessage}
                        </span>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} sm={4}>
                    <label className="input">
                      {this.props.intl.formatMessage(messages.documentType)}
                    </label>
                    <Field
                      store={this.props.store}
                      name="documentTypeId"
                      component={renderSelectField}
                      options={this.props.documentTypes}
                      bsSize="lg"
                    />
                  </Col>
                  <Col xs={12} sm={4}>
                    <Row>
                      <Col xs={12}>
                        <label className="input">
                          {this.props.intl.formatMessage(
                            messages.householdMember,
                          )}
                        </label>
                        <Field
                          store={this.props.store}
                          name="applicantId"
                          component={renderSelectField}
                          options={this.props.householdMembers}
                          bsSize="lg"
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {!this.props.isSaving && (
                <Row>
                  <Col xs={12} sm={12}>
                    <label className="input">
                      {this.props.intl.formatMessage(messages.documentNotes)}
                    </label>
                    <Field
                      store={this.props.store}
                      name="notes"
                      component={renderTextAreaField}
                      rows="6"
                      placeholder="Add your notes..."
                      maxLength="500"
                    />
                  </Col>
                </Row>
              )}
              {this.props.isSaving && (
                <Row>
                  <div className="loading-container">
                    <div className="spinner">
                      <div className="spinnerbar spnbar1" />
                      <div className="spinnerbar spnbar2" />
                      <div className="spinnerbar spnbar3" />
                      <div className="spinnerbar spnbar4" />
                      <div className="spinnerbar spnbar5" />
                    </div>
                    <h5>{this.props.intl.formatMessage(messages.uploading)}</h5>
                  </div>
                </Row>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Row>
                <Col xs={4}>
                  <Button
                    bsStyle="default"
                    className="pull-right"
                    onClick={this.cancel}
                    disabled={
                      this.state.showCancelConfirmation || this.props.isSaving
                    }
                  >
                    {this.props.intl.formatMessage(messages.cancel)}
                  </Button>
                </Col>
                <Col xs={4} className="text-center">
                  <Button
                    bsStyle="primary"
                    onClick={this.saveAndClose}
                    disabled={this.isSaveButtonDisabled()}
                  >
                    {this.props.intl.formatMessage(messages.saveAndClose)}
                  </Button>
                </Col>
                <Col xs={4}>
                  <Button
                    bsStyle="primary"
                    className="pull-left"
                    onClick={this.saveAndAddNew}
                    disabled={this.isSaveButtonDisabled()}
                  >
                    {this.props.intl.formatMessage(messages.saveAndAddNew)}
                  </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
        </div>
      </form>
    );
  }
}

let InjectedUploader = reduxForm({
  form: 'uploader',
  validate: validate,
  enableReinitialize: true,
  initialValues: {
    documentTypeId: 'default',
    applicantId: 'default',
    notes: '',
  },
})(Uploader);

const selector = formValueSelector('uploader');

export const mapStateToProps = (state: GlobalState): StateProps => {
  return {
    locale: state.languageProvider.locale,
    isSaving: state.manageDocuments.isSaving,
    hasError: state.manageDocuments.hasError,
    documentTypeId: selector(state, 'documentTypeId'),
    applicantId: selector(state, 'applicantId'),
    notes: selector(state, 'notes'),
  };
};

InjectedUploader = connect(mapStateToProps)(InjectedUploader);

const dialog = createConfirmation(confirmable(InjectedUploader));

export const uploader = (
  intl: any,
  store: any,
  applicationId: string,
  documentTypes: Array<SelectOption>,
  householdMembers: Array<SelectOption>,
  uploadDocument: Function,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    dialog({
      intl,
      store,
      applicationId,
      documentTypes,
      householdMembers,
      uploadDocument,
    }).then(
      (data: any) => resolve(data),
      (error: Object) => reject(error),
    );
  });
};
