import { useMutation, UseMutationOptions } from 'react-query';
import AffordableQualificationService from '../../services/affordableQualificationService';
import { formatDateDB } from '@fortress-technology-solutions/fortress-component-library/utils';
import useAbortController from '../../hooks/useAbortController';

const useDownloadAll59AsMutation = ({
  propertyId,
  organizationId,
  options,
}: {
  propertyId: string,
  organizationId: string,
  options?: UseMutationOptions,
}) => {
  const abortControllerOptions = useAbortController();
  return useMutation(async () => {
    const fileName = `${formatDateDB(new Date())} - Gross Rent 59a Batch.pdf`;
    return new AffordableQualificationService().generateAndDownloadAll59As(
      organizationId,
      propertyId,
      fileName,
      abortControllerOptions,
    );
  }, options);
};

export default useDownloadAll59AsMutation;
