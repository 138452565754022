import { defineMessages } from 'react-intl';

const messages = defineMessages({
  floorplanEditTitle: {
    id: 'App.ViewUnit.FloorplanEditModal.FloorplanEditTitle',
    defaultMessage: 'Change Floorplan Type',
  },
  cancel: {
    id: 'App.ViewUnit.FloorplanEditModal.Cancel',
    defaultMessage: 'Cancel',
  },
  saveChanges: {
    id: 'App.ViewUnit.FloorplanEditModal.SaveChanges',
    defaultMessage: 'Save Changes',
  },
  selectTypeDescription: {
    id: 'App.ViewUnit.FloorplanEditModal.SelectTypeDescription',
    defaultMessage: 'Select a floorplan type for this unit:',
  },
});

export default messages;
