import React, { useState } from 'react';
import { FormControl, FormGroup, HelpBlock, InputGroup } from 'react-bootstrap';

import messages from './messages';

type Props = {
  insuranceFeeAmount: number,
  onChange: React.FormEventHandler<FormControl>,
  formGroupStyles: ?React.CSSProperties,
  intl: Object,
};

export function InsuranceFeeInput({
  insuranceFeeAmount,
  onChange,
  formGroupStyles,
  intl,
}: Props) {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    const [integerPart, fractionalPart] = (value ?? '').split('.');
    const newValue = parseFloat(
      `${integerPart}.${fractionalPart?.[0]}${fractionalPart?.[1]}`,
    );

    onChange({ target: { name, value: isNaN(newValue) ? 0 : newValue } });
  };

  return (
    <FormGroup
      validationState={insuranceFeeAmount < 10 ? 'error' : null}
      style={formGroupStyles}
    >
      <InputGroup>
        <InputGroup.Addon>$</InputGroup.Addon>
        <FormControl
          name="insuranceFeeAmount"
          type={isFocused ? 'number' : 'text'}
          value={
            isFocused
              ? (insuranceFeeAmount || 0).toString()
              : Number(insuranceFeeAmount).toFixed(2)
          }
          onChange={handleChange}
          min={10}
          onFocus={onFocus}
          onBlur={onBlur}
          style={{ paddingLeft: 9 }}
          step={0.01}
        />
      </InputGroup>
      {insuranceFeeAmount < 10 && (
        <HelpBlock>
          {intl.formatMessage(messages.assetProtectFeeValidation)}
        </HelpBlock>
      )}
    </FormGroup>
  );
}
