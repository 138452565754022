import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import {
  renderTextField,
  renderPhoneField,
  phoneParser,
  phoneFormatter,
} from '../../../../../utils/redux-form-helper';
import messages from './messages';

export const EmergencyContactInformation = ({ intl, isEditable }: Object) => (
  <React.Fragment>
    <Row className="padtop20 padbottom10">
      <Col xs={12}>
        <h2>
          <i className="et-firstaid text-blue padright10" />
          {intl.formatMessage(messages.emergencyContactInformationLabel)}
        </h2>
      </Col>
    </Row>
    <Row>
      <Col sm={4}>
        <div className="form-group">
          <label>
            {intl.formatMessage(messages.emergencyContactNameLabel)}
          </label>
          <Field
            name="emergencyContactName"
            component={renderTextField}
            maxLength="25"
            className="input-md form-control"
            disabled={!isEditable}
          />
        </div>
      </Col>
      <Col sm={3}>
        <div className="form-group">
          <label>
            {intl.formatMessage(messages.emergencyContactPhoneLabel)}
          </label>
          <Field
            name="emergencyContactPhone"
            component={renderPhoneField}
            parse={phoneParser}
            format={phoneFormatter}
            disabled={!isEditable}
          />
        </div>
      </Col>
      <Col sm={3}>
        <div className="form-group">
          <label>
            {intl.formatMessage(messages.emergencyContactRelationshipLabel)}
          </label>
          <Field
            name="emergencyContactRelationship"
            component={renderTextField}
            maxLength="25"
            className="input-md form-control"
            disabled={!isEditable}
          />
        </div>
      </Col>
    </Row>
  </React.Fragment>
);

export default EmergencyContactInformation;
