import React from 'react';
import {
  Table,
  TableHeader,
} from '@fortress-technology-solutions/fortress-component-library/Organisms_Fortress';
import { useManageApplications } from './ApplicationsTable_V2.hooks';
import useBaseTableProps from '../../../hooks/useBaseTableProps';
import TableLayout from '../../../components/TableLayout';

const ApplicationsTable = ({
  permissions,
  currentUserId,
  intl,
  affordablePrograms,
  locale,
  onEditActivity,
  onRecordActivity,
  onScheduleActivity,
  onCreateActivity,
  applicationStatuses,
  organizationId,
  propertyId,
  assigningUnitsMoveInDatesFlag,
  selectedProperty,
}) => {
  const baseTableProps = useBaseTableProps();
  const {
    CSVIsExporting,
    allColumnsHidden,
    columnOptions,
    count,
    dateState,
    filterState,
    filterTypeState,
    filteredHeaders,
    handleCSVDownload,
    handleColumnChange,
    handleDateSubmit,
    handleFilterChange,
    handleFilterTypeChange,
    handleSearchSubmit,
    handleSortChange,
    isLoading,
    name,
    order,
    orderBy,
    rows,
    searchState,
    selectedColumns,
    totalCount,
  } = useManageApplications({
    applicationStatuses,
    organizationId,
    propertyId,
    assigningUnitsMoveInDatesFlag,
    currentUserId,
    affordablePrograms,
    locale,
    intl,
    onEditActivity,
    onRecordActivity,
    onScheduleActivity,
    onCreateActivity,
    permissions,
    selectedProperty,
  });

  return (
    <>
      <TableLayout
        name={name}
        sx={{
          'button[disabled]': { backgroundColor: 'unset' },
        }}
        childrenElementsHeight={56 + 8}
      >
        {(tableHeight) => (
          <>
            <TableHeader
              title={'Manage Applications'}
              count={count}
              totalCount={totalCount}
            />
            <Table
              {...baseTableProps}
              allColumnsHidden={allColumnsHidden}
              columnOptions={columnOptions}
              count={count}
              dateState={dateState}
              filterState={filterState}
              filterTypeState={filterTypeState}
              handleColumnChange={handleColumnChange}
              handleDateSubmit={handleDateSubmit}
              handleFilterChange={handleFilterChange}
              handleFilterTypeChange={handleFilterTypeChange}
              handleSearchSubmit={handleSearchSubmit}
              handleSortChange={handleSortChange}
              headers={filteredHeaders}
              height={tableHeight}
              isCSVExportLoading={CSVIsExporting}
              isLoading={isLoading}
              name={name}
              onCSVButtonClick={handleCSVDownload}
              order={order}
              orderBy={orderBy}
              rows={rows}
              searchState={searchState}
              selectedColumns={selectedColumns}
              totalCount={totalCount}
            />
          </>
        )}
      </TableLayout>
    </>
  );
};

ApplicationsTable.defaultProps = {
  affordablePrograms: {},
  applications: [],
  intl: {},
  onEditActivity: () => {},
  onRecordActivity: () => {},
  onScheduleActivity: () => {},
  onCreateActivity: () => {},
};
export default ApplicationsTable;
