import { pathOr } from 'ramda';

// $FlowFixMe
import { download } from '../../utils/downloadFile';
import PropertyService from '../../services/propertyService';
import {
  determinePortalStatus,
  determinedPortalActiveColumnString,
  determineLinkColumnString,
  formatPhoneNumber,
  getStreetAddress,
} from './utils';

const downloadCSV = async (
  csvHeaders: Array<Object>,
  title: string,
  subtitle: string,
  organizationId: string,
  query: Object,
) => {
  const headers = csvHeaders
    .map((header) => {
      return header.title;
    }, [])
    .join(',');
  const fileName = `${title.toLocaleLowerCase().replace(/\s/g, '_')}.csv`;
  const propertyService = new PropertyService();
  // Request API not to paginate results
  const downloadQuery = {
    ...query,
    paginationInfo: {
      pagination: false,
    },
  };
  const properties = await propertyService.getProperties(
    organizationId,
    downloadQuery,
  );
  const rows = properties.results.map((property) => {
    const {
      physicalStreetAddress1,
      physicalStreetAddress2,
      name,
      shortName,
      propertyStatus: { description: status },
      propertyClass: { propertyType: propertyClass },
      physicalCity: city,
      physicalZip: zip,
      state: { code: state },
      phoneNumber,
      propertyEmail: email,
      pfp: { periodName: period },
      autoLateFeesPostingActive,
      leaseRentPercentage,
    } = property;
    const owner = pathOr('---', ['propertyOwner', 'name'], property);
    const portalStatus = determinePortalStatus(property);
    const portalActiveStatus = determinedPortalActiveColumnString(portalStatus);
    const orgPropUrl = `${organizationId}/${property.id}`;
    const links = determineLinkColumnString(portalStatus, orgPropUrl);
    const streetAddress = getStreetAddress(
      physicalStreetAddress1,
      physicalStreetAddress2,
    );
    const fullAddress = `${streetAddress}, ${city}, ${state} ${zip}`;
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    const formattedLeaseRentPercentage = leaseRentPercentage
      ? leaseRentPercentage.replace('%', '') + '%'
      : '---';
    const gpr = pathOr(
      '---',
      ['propertyReportConfiguration', '0', 'config1Value'],
      property,
    );

    return [
      status,
      `"${name}"`,
      shortName,
      `"${owner}"`,
      propertyClass,
      `"${fullAddress}"`,
      formattedPhoneNumber,
      email,
      period,
      autoLateFeesPostingActive,
      formattedLeaseRentPercentage,
      gpr,
      portalActiveStatus,
      links,
    ].join(',');
  }, []);
  const csv = [title, subtitle, '\n', headers, ...rows].join('\n');
  download(csv, fileName, 'text/csv;charset=utf-8');
};

export default downloadCSV;
