import * as ActionTypes from './constants';

import type { Action } from '../App/types';
import type { Application } from '../CreateApplication/types';
import type { AplicantInfo, Status, MoveInInfo } from './types';

export function getApplicantInformationError(error: Error): Action<Error> {
  return {
    type: ActionTypes.GET_APPLICANT_INFORMATION_ERROR,
    payload: error,
  };
}

export function getChecklistStatus(applicant: AplicantInfo): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_CHECKLIST_STATUS,
    payload: applicant,
  };
}

export function getSignedLeaseStatus(applicant: AplicantInfo): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_SIGNED_LEASE_STATUS,
    payload: applicant,
  };
}

export function getScreeningStatus(applicant: AplicantInfo): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_SCREENING_STATUS,
    payload: applicant,
  };
}

export function getVehicleStatus(applicant: AplicantInfo): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_VEHICLE_STATUS,
    payload: applicant,
  };
}

export function getLeaseStatus(applicant: AplicantInfo): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_LEASE_STATUS,
    payload: applicant,
  };
}

export function getLeaseStartDateStatus(
  applicant: AplicantInfo,
): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_LEASE_START_DATE_STATUS,
    payload: applicant,
  };
}

export function getMoveInActionsStatus(
  applicant: AplicantInfo,
): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_MOVE_IN_ACTION,
    payload: applicant,
  };
}

export function getVehicleStatusSuccess(status: Status): Action<Object> {
  return {
    type: ActionTypes.GET_VEHICLE_STATUS_SUCCESS,
    payload: status,
  };
}

export function getMoveInSucesssStatus(status: Status): Action<Object> {
  return {
    type: ActionTypes.GET_MOVE_IN_ACTIONS_SUCCESS,
    payload: status,
  };
}

export function getOneApplication(applicationId: string): Action<string> {
  return {
    type: ActionTypes.GET_ONE_APPLICATION,
    payload: applicationId,
  };
}

export function getOneApplicationSuccess(
  application: Application,
): Action<Application> {
  return {
    type: ActionTypes.GET_ONE_APPLICATION_SUCCESS,
    payload: application,
  };
}

export function getOneProperty(applicationId: string): Action<string> {
  return {
    type: ActionTypes.GET_ONE_PROPERTY,
    payload: applicationId,
  };
}

export function getOnePropertySuccess(property: Object): Action<Application> {
  return {
    type: ActionTypes.GET_ONE_PROPERTY_SUCCESS,
    payload: property,
  };
}

export function getApplicationChecklistStatusSucess(
  status: Status,
): Action<Object> {
  return {
    type: ActionTypes.GET_CHECKLIST_STATUS_SUCCESS,
    payload: status,
  };
}

export function getApplicationScreeningStatusSucess(
  status: Status,
): Action<Object> {
  return {
    type: ActionTypes.GET_SCREENING_STATUS_SUCCESS,
    payload: status,
  };
}

export function getApplicationSignedLeaseStatusSucess(
  status: Status,
): Action<Object> {
  return {
    type: ActionTypes.GET_SIGNED_LEASE_STATUS_SUCCESS,
    payload: status,
  };
}

export function getApplicationLeaseStatusSucess(
  status: Status,
): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_LEASE_STATUS_SUCCESS,
    payload: status,
  };
}

export function getApplicationLeaseStartDateStatusSucess(
  status: Status,
): Action<Object> {
  return {
    type: ActionTypes.GET_APPLICATION_LEASE_START_DATE_STATUS_SUCCESS,
    payload: status,
  };
}

export function clean(): Action<any> {
  return {
    type: ActionTypes.CLEAN,
    payload: undefined,
  };
}

export function moveIn(
  applicationId: string,
  actualMoveInDate: any,
  requiredActions: Array<any>,
  moveInWithoutScreening: boolean,
  prorated: Array<any>,
): Action<MoveInInfo> {
  return {
    type: ActionTypes.MOVEIN,
    payload: {
      actualMoveInDate,
      applicationId,
      moveInWithoutScreening,
      requiredActions,
      prorated,
    },
  };
}

export function moveInSuccess(): Action<any> {
  return {
    type: ActionTypes.MOVEIN_SUCCESS,
    payload: undefined,
  };
}

export function moveInError(error: Error): Action<Error> {
  return {
    type: ActionTypes.MOVEIN_ERROR,
    payload: error,
  };
}

export function getUnitAvailabilityStatus(
  applicationId: string,
): Action<string> {
  return {
    type: ActionTypes.GET_UNIT_AVAILABILITY_STATUS,
    payload: applicationId,
  };
}

export function getUnitAvailabilityStatusSuccess(
  status: Status,
): Action<Status> {
  return {
    type: ActionTypes.GET_UNIT_AVAILABILITY_STATUS_SUCCESS,
    payload: status,
  };
}
