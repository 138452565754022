import React from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { cond, equals, always, T, pathOr, isEmpty } from 'ramda';
import moment from 'moment';
import messages from './messages';
import {
  SCREENING_RESULTS_APPROVED,
  SCREENING_RESULTS_DECLINE_ENABLE,
} from '../../containers/ApplicationProfile/constants';

import TUReasonList from '../TUReasonList';

type Props = {
  intl: any,
  show: boolean,
  dismiss: Function,
  saveCallback: Function,
  onResubmitCallback: Function,
  updateFinalStatusField: Function,
  updateFinalDecisionNote: Function,
  finalDecisionNote: string,
  requireFinalDecisionNote: boolean,
  screening: Object,
  values: Object,
  finalStatusSavingCallback: Function,
  finalStatusSaving: boolean,
  toggleClose: Function,
  isClosing: boolean,
  checkPermission: boolean,
  checkApprovedPermission: boolean,
};

type IntlMetaProps = {
  intl: any,
  createdBy: Object,
  updatedAt: string,
};

type IntlMsgMetaProps = {
  intl: any,
  resultMessage: string,
  createdBy: Object,
  updatedAt: string,
  reasons?: Object,
};

type ResubmitProps = {
  onResubmitCallback: Function,
};

type IntlScreenResubmitProps = {
  intl: any,
  screening: Object,
  onResubmitCallback: Function,
  finalStatusSavingCallback: Function,
  finalStatusSaving: boolean,
  saveCallback: Function,
  values: Object,
  isClosing: boolean,
  dismiss: Function,
  toggleClose: Function,
};

type ConfirmProps = {
  values: Object,
  saveCallback: Function,
  finalStatusSavingCallback: Function,
};

type ScreeningProps = {
  intl: any,
  screening: Object,
  finalStatusSavingCallback: Function,
  updateFinalStatusField: Function,
  updateFinalDecisionNote: Function,
  finalDecisionNote: string,
  requireFinalDecisionNote: boolean,
  finalStatusSaving: boolean,
  values: Object,
  checkPermission: boolean,
  checkApprovedPermission: boolean,
};

export const CancelConfirmation = ({ intl, dismiss, toggleClose }: Object) => {
  return (
    <div className="modal-confirm">
      <h1>{intl.formatMessage(messages.cancelConfirmationHeader)}</h1>
      <div className="col-xs-12 col-sm-6">
        <div className="btn btn-delete pull-right" onClick={dismiss}>
          <FormattedMessage {...messages.yes} />
        </div>
      </div>
      <div className="col-xs-12 col-sm-6">
        <div className="btn btn-default pull-left" onClick={toggleClose}>
          <FormattedMessage {...messages.no} />
        </div>
      </div>
    </div>
  );
};

export const FormatMetaInformation = ({
  intl,
  createdBy,
  updatedAt,
}: IntlMetaProps) => {
  return (
    <small>
      {intl.formatMessage(messages.submitted)}
      {': '}
      {createdBy.firstName} {createdBy.lastName}{' '}
      {intl.formatMessage(messages.on)}{' '}
      {moment(updatedAt).format('MMMM DD, YYYY @ h:mm A')}
    </small>
  );
};

export const ErrorScreeningStatusBody = ({
  onResubmitCallback,
}: ResubmitProps) => {
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 text-center">
        <div className="status-icon-big status-warning text-center">
          <i className="icon et-alert-urgent" />
        </div>
        <h2>
          <FormattedMessage {...messages.cannotRetrieve} />
        </h2>
        <div className="row">
          <div className="col-xs-12">
            <a className="btn btn-tertiary" onClick={onResubmitCallback}>
              <i className="icon et-refresh" />
              <FormattedMessage {...messages.submitAgain} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ApprovedScreeningBody = ({
  intl,
  resultMessage,
  createdBy,
  updatedAt,
  reasons,
}: IntlMsgMetaProps) => (
  <div className="row results-throw">
    <div className="col-xs-12 col-sm-12 text-center">
      <h4>
        <FormattedMessage {...messages.results} />
      </h4>
      <div className="status-icon-big status-approved text-center">
        <i className="et-check-shield" />
      </div>
      <h2>
        <FormattedMessage {...messages.status} />
        {` ${resultMessage}`}
      </h2>
      <TUReasonList reasons={reasons} />
      <FormatMetaInformation
        intl={intl}
        createdBy={createdBy}
        updatedAt={updatedAt}
      />
    </div>
  </div>
);

export const ApprovedConditionsScreeningBody = ({
  intl,
  resultMessage,
  createdBy,
  updatedAt,
  reasons,
}: IntlMsgMetaProps) => {
  return (
    <div className="row results-throw">
      <div className="col-xs-12 col-sm-12 text-center">
        <h4>
          <FormattedMessage {...messages.results} />
        </h4>
        <div className="status-icon-big status-conditions text-center">
          <i className="et-alert-diamond" />
        </div>
        <h2>
          <FormattedMessage {...messages.status} />
          {` ${resultMessage}`}
        </h2>
        <TUReasonList reasons={reasons} />
        <FormatMetaInformation
          intl={intl}
          createdBy={createdBy}
          updatedAt={updatedAt}
        />
      </div>
    </div>
  );
};

export const DeclinedScreeningBody = ({
  intl,
  resultMessage,
  createdBy,
  updatedAt,
  reasons,
}: IntlMsgMetaProps) => {
  return (
    <div className="row results-throw">
      <div className="col-xs-12 col-sm-12 text-center">
        <h4>
          <FormattedMessage {...messages.results} />
        </h4>
        <div className="status-icon-big status-declined text-center">
          <i className="et-construction" />
        </div>
        <h2>
          <FormattedMessage {...messages.status} />
          {` ${resultMessage}`}
        </h2>
        <TUReasonList reasons={reasons} />
        <FormatMetaInformation
          intl={intl}
          createdBy={createdBy}
          updatedAt={updatedAt}
        />
      </div>
    </div>
  );
};

export const PendingScreeningBody = ({
  intl,
  resultMessage,
  createdBy,
  updatedAt,
  reasons,
}: IntlMsgMetaProps) => {
  return (
    <div className="row results-throw">
      <div className="col-xs-12 col-sm-12 text-center">
        <h4>
          <FormattedMessage {...messages.results} />
        </h4>
        <div className="status-icon-big status-disabled text-center">
          <i className="icon et-comment-alert" />
        </div>
        <h2>
          <FormattedMessage {...messages.status} />
          {` ${resultMessage}`}
        </h2>
        <TUReasonList reasons={reasons} />
        <FormatMetaInformation
          intl={intl}
          createdBy={createdBy}
          updatedAt={updatedAt}
        />
      </div>
    </div>
  );
};

export const SubmittingScreeningBody = () => {
  return (
    <div>
      <div className="row">
        <div className="loading-container">
          <div className="spinner">
            <div className="spinnerbar spnbar1" />
            <div className="spinnerbar spnbar2" />
            <div className="spinnerbar spnbar3" />
            <div className="spinnerbar spnbar4" />
            <div className="spinnerbar spnbar5" />
          </div>
          <h5>
            <FormattedMessage {...messages.submitting} />
          </h5>
          <p className="text-center">
            <FormattedMessage {...messages.pleaseWait} />
          </p>
        </div>
      </div>
    </div>
  );
};

export const ConfirmStatusBody = ({
  values,
  saveCallback,
  finalStatusSavingCallback,
}: ConfirmProps) => {
  const renderFn = cond([
    [equals(1), always(<FormattedMessage {...messages.confirmApprove} />)],
    [equals(3), always(<FormattedMessage {...messages.confirmConditions} />)],
    [equals(4), always(<FormattedMessage {...messages.confirmDecline} />)],
  ]);

  return (
    <div className="modal-confirm">
      <h1>{renderFn(+values.statusId)}</h1>
      <div className="col-xs-12 col-sm-6">
        <div className="btn btn-primary pull-right" onClick={saveCallback}>
          <FormattedMessage {...messages.yes} />
        </div>
      </div>
      <div className="col-xs-12 col-sm-6">
        <div
          className="btn btn-default pull-left"
          onClick={finalStatusSavingCallback}
        >
          <FormattedMessage {...messages.no} />
        </div>
      </div>
    </div>
  );
};

export const renderBodyByStatusCode = (intl: any, screenStatus: Object) => {
  const renderFn = cond([
    [
      equals(1),
      always(<ApprovedScreeningBody {...screenStatus} intl={intl} />),
    ],
    [
      equals(2),
      always(<ApprovedScreeningBody {...screenStatus} intl={intl} />),
    ],
    [
      equals(3),
      always(<ApprovedConditionsScreeningBody {...screenStatus} intl={intl} />),
    ],
    [
      equals(4),
      always(<DeclinedScreeningBody {...screenStatus} intl={intl} />),
    ],
    [
      equals(5),
      always(<ApprovedConditionsScreeningBody {...screenStatus} intl={intl} />),
    ],
    [equals(6), always(<PendingScreeningBody {...screenStatus} intl={intl} />)],
    [T, always(<ErrorScreeningStatusBody {...screenStatus} intl={intl} />)],
  ]);
  return renderFn(+screenStatus.resultId);
};

export const RenderStatusBody = ({
  intl,
  screening,
  onResubmitCallback,
  finalStatusSavingCallback,
  finalStatusSaving,
  saveCallback,
  values,
  toggleClose,
  isClosing,
  dismiss,
}: IntlScreenResubmitProps) => {
  const hasScreening = pathOr(false, ['results', 'screen'], screening);
  const hasError = pathOr(false, ['error'], screening);
  const isSubmitting = screening.submitting;

  if (isClosing) {
    return (
      <CancelConfirmation
        intl={intl}
        dismiss={dismiss}
        toggleClose={toggleClose}
      />
    );
  }

  if (isSubmitting) {
    return <SubmittingScreeningBody />;
  }

  if (hasError) {
    return <ErrorScreeningStatusBody onResubmitCallback={onResubmitCallback} />;
  }

  if (!hasScreening) {
    return <SubmittingScreeningBody />;
  }

  if (finalStatusSaving) {
    return (
      <ConfirmStatusBody
        saveCallback={saveCallback}
        values={values}
        finalStatusSavingCallback={finalStatusSavingCallback}
      />
    );
  }

  const screenStatus = screening.results.screen;
  return renderBodyByStatusCode(intl, screenStatus);
};

export const RenderFinalDecisionForm = ({
  intl,
  screening,
  finalStatusSavingCallback,
  updateFinalStatusField,
  updateFinalDecisionNote,
  finalDecisionNote,
  requireFinalDecisionNote,
  finalStatusSaving,
  values,
  checkPermission,
  checkApprovedPermission,
}: ScreeningProps) => {
  const hasScreening = pathOr(false, ['results', 'screen'], screening);
  const screeningCode = +pathOr(
    0,
    ['results', 'screen', 'resultId'],
    screening,
  );
  const hasError = pathOr(false, ['error'], screening);
  const isSubmitting = screening.submitting;
  const showFooter =
    !isSubmitting && !hasError && hasScreening && !finalStatusSaving;

  // enable if the code is 1: Accept, 2: Accept with cond, 3: Conditional
  const approvedDisable = !(
    checkPermission ||
    (checkApprovedPermission &&
      SCREENING_RESULTS_APPROVED.indexOf(screeningCode) !== -1)
  );

  // enable if the code is 1: Accept, 2: Accept with cond, 3: Conditional, 4: Declined, 5: Refer
  const declinedDisable = !(
    checkPermission ||
    (checkApprovedPermission &&
      SCREENING_RESULTS_DECLINE_ENABLE.indexOf(screeningCode) !== -1)
  );

  return showFooter
    ? [
        <div key={1} className="container">
          <div className="row text-center">
            <div className="col-xs-4 text-right">
              <h2>Final Decision</h2>
            </div>
            <div className="col-xs-3 text-left">
              <select
                className="form-control"
                onChange={updateFinalStatusField}
              >
                <option key={0} value="" disabled selected>
                  {intl.formatMessage(messages.choose)}
                </option>
                <option
                  key={1}
                  value={JSON.stringify({
                    statusId: '1',
                    statusMessage: 'Approved',
                  })}
                  disabled={approvedDisable}
                >
                  {intl.formatMessage(messages.approved)}
                </option>
                <option
                  key={2}
                  value={JSON.stringify({
                    statusId: '3',
                    statusMessage: 'Approved with Conditions',
                  })}
                  disabled={approvedDisable}
                >
                  {intl.formatMessage(messages.approvedWithConditions)}
                </option>
                <option
                  key={3}
                  value={JSON.stringify({
                    statusId: '4',
                    statusMessage: 'Declined',
                  })}
                  disabled={declinedDisable}
                >
                  {intl.formatMessage(messages.decline)}
                </option>
              </select>
            </div>
          </div>
          <div className="row text-center mt-2">
            <div className="col-xs-4 text-right">
              <h4>Reason for Decision{requireFinalDecisionNote && ' *'}</h4>
            </div>
            <div className="col-xs-3 text-left">
              <textarea
                value={finalDecisionNote || ''}
                maxLength={2000}
                onChange={updateFinalDecisionNote}
                className={'form-control'}
                placeholder={
                  requireFinalDecisionNote
                    ? 'Enter Note (Required)'
                    : 'Enter Note'
                }
              />
            </div>
          </div>
        </div>,
      ]
    : null;
};

export const RenderStatusFooter = ({
  intl,
  screening,
  finalStatusSavingCallback,
  updateFinalStatusField,
  finalDecisionNote,
  requireFinalDecisionNote,
  finalStatusSaving,
  values,
  checkPermission,
  checkApprovedPermission,
}: ScreeningProps) => {
  const hasScreening = pathOr(false, ['results', 'screen'], screening);
  const hasError = pathOr(false, ['error'], screening);
  const isSubmitting = screening.submitting;
  const showFooter =
    !isSubmitting && !hasError && hasScreening && !finalStatusSaving;
  const submitDisabled =
    isEmpty(values) ||
    (requireFinalDecisionNote &&
      !(finalDecisionNote && finalDecisionNote.length > 0));

  return showFooter
    ? [
        <div key={1} className="text-center">
          <button
            className="btn btn-primary"
            onClick={finalStatusSavingCallback}
            disabled={submitDisabled}
          >
            Save
          </button>
        </div>,
      ]
    : null;
};

export const ScreeningStatusModal = ({
  intl,
  show,
  dismiss,
  saveCallback,
  onResubmitCallback,
  screening,
  updateFinalStatusField,
  updateFinalDecisionNote,
  finalDecisionNote,
  requireFinalDecisionNote,
  values,
  finalStatusSavingCallback,
  finalStatusSaving,
  toggleClose,
  isClosing,
  checkPermission,
  checkApprovedPermission,
}: Props) => {
  const modalDismiss = () => {
    if (isClosing) return;
    return !isEmpty(values) ? toggleClose() : dismiss();
  };

  return (
    <div className="static-modal">
      <Modal
        className="creditstatus-page"
        backdrop
        bsSize="lg"
        show={show}
        onHide={modalDismiss}
      >
        <Modal.Header closeButton>
          <i className="icon et-check-shield" />
          <Modal.Title componentClass="h1">Screening</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RenderStatusBody
            intl={intl}
            screening={screening}
            onResubmitCallback={onResubmitCallback}
            finalStatusSaving={finalStatusSaving}
            finalStatusSavingCallback={finalStatusSavingCallback}
            saveCallback={saveCallback}
            values={values}
            isClosing={isClosing}
            dismiss={dismiss}
            toggleClose={toggleClose}
          />
          <RenderFinalDecisionForm
            intl={intl}
            screening={screening}
            updateFinalStatusField={updateFinalStatusField}
            updateFinalDecisionNote={updateFinalDecisionNote}
            finalDecisionNote={finalDecisionNote}
            requireFinalDecisionNote={requireFinalDecisionNote}
            finalStatusSaving={finalStatusSaving}
            finalStatusSavingCallback={finalStatusSavingCallback}
            values={values}
            checkPermission={checkPermission}
            checkApprovedPermission={checkApprovedPermission}
          />
        </Modal.Body>
        <Modal.Footer>
          <RenderStatusFooter
            intl={intl}
            screening={screening}
            updateFinalStatusField={updateFinalStatusField}
            finalStatusSaving={finalStatusSaving}
            finalStatusSavingCallback={finalStatusSavingCallback}
            requireFinalDecisionNote={requireFinalDecisionNote}
            finalDecisionNote={finalDecisionNote}
            values={values}
            checkPermission={checkPermission}
            checkApprovedPermission={checkApprovedPermission}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ScreeningStatusModal;
