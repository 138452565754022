import { get, put } from '../utils/api';

export default class AffordableSetUpService {
  getPropertyIncomeAveragingPercent(
    organizationId: string,
    propertyId: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/income-averaging-percent`,
      options,
    );
  }

  modifyPropertyIncomeAveragingPercent(
    organizationId: string,
    propertyId: string,
    incomeAveragingPercent: string,
  ) {
    return put(
      `/${organizationId}/${propertyId}/income-averaging-percent`,
      JSON.stringify({ incomeAveragingPercent }),
    );
  }

  async getAllPassbookRatesByState(
    organizationId: string,
    propertyId: string,
    state: string,
    options?: Object,
  ) {
    return get(
      `/${organizationId}/${propertyId}/passbook-rates/${state}`,
      options,
    );
  }
}
