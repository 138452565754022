import { pathOr, propOr } from 'ramda';

export const getPrimaryApplicantRegisters = (applications) =>
  applications.map((application) => {
    const primaryApplicant = application.applicants.find((applicant) => {
      return pathOr(false, ['applicantCustomer', 'isProspect'], applicant);
    });
    const unitNumber = pathOr('---', ['au', 'unit', 'number'], application);
    const moveInDate = pathOr(null, ['au', 'lease', 'moveInDate'], application);
    const assignedTo = pathOr({}, ['assignedTo'], application);
    const assignedToName = `${assignedTo.firstName} ${assignedTo.lastName}`;
    const customer = pathOr(
      {},
      ['applicantCustomer', 'customer'],
      primaryApplicant,
    );
    const emailAddress = propOr('', 'emailAddress', customer);
    const phoneNumber = propOr('', 'phoneNumber', customer);
    const name = pathOr('', ['name'], primaryApplicant);
    const status = pathOr('', ['applicationStatus', 'name'], application);
    return {
      ...application,
      name,
      assignedUnit: unitNumber,
      status,
      scheduledMoveInDate: moveInDate,
      assignedTo: assignedToName,
      emailAddress,
      phoneNumber,
      applyFilter: false,
      selected: false,
      id: propOr('', 'id', primaryApplicant),
      applicationId: application.id,
    };
  });

export const getAllApplicantRegisters = (applications) =>
  applications.reduce((acc, application) => {
    const unitNumber = application.au?.unit?.number ?? '---';
    const moveInDate = application.au?.lease?.moveInDate ?? null;
    const assignedTo = application.assignedTo ?? {};
    const assignedToName = `${assignedTo.firstName} ${assignedTo.lastName}`;
    const status = application.applicationStatus?.name ?? '';

    application.applicants.forEach((applicant) => {
      if (applicant.applicantType?.name !== 'Non Financial Lease Signer') {
        const customer = applicant.applicantCustomer?.customer ?? {};
        const emailAddress = customer.emailAddress ?? '';
        const phoneNumber = customer.phoneNumber ?? '';
        const name = applicant.name ?? '';
        acc.push({
          ...application,
          name,
          assignedUnit: unitNumber,
          status,
          scheduledMoveInDate: moveInDate,
          assignedTo: assignedToName,
          emailAddress,
          phoneNumber,
          applyFilter: false,
          selected: false,
          id: applicant.id,
          applicationId: application.id,
        });
      }
    });
    return acc;
  }, []);
