import React, { Component } from 'react';
import { update, isNil, isEmpty } from 'ramda';
import { FormSection } from 'redux-form';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  renderTextField,
  renderPhoneField,
  renderEmailField,
  renderSelectField,
  phoneParser,
  phoneFormatter,
} from '../../../utils/redux-form-helper';
import MiniProfileFormPanel from '../../MiniProfileFormPanel';
import messages from './messages';
import { requiredVal } from '../utils';

const RowWarning = styled.div`
  .highlight-details {
    margin: 10px;
    padding: 10px;
  }
`;

const PANEL_TITLE = 'General Information';

type Props = {
  openPanel: ?string,
  togglePanel: Function,
  suffixes: Array<Object>,
  referralType: string,
  isAdult: boolean,
  leaseExecuted: boolean,
  leaseSentToPortal: boolean,
  relationshipOptions: Array<Object>,
  applicantTypeOptions: Array<Object>,
  isPrimary: boolean,
  bypassedFields?: string[],
};

type State = {
  editable: boolean,
};

const FIELD_TO_LABEL_MAP = {
  preferredName: 'Preferred Name',
  firstName: 'Legal First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  suffixId: 'Suffix',
  phoneNumber: 'Phone',
  applicantTypeId: 'Type',
  relationshipId: 'Relationship',
  emailAddress: 'Email Address',
};

const GeneralInformationFormSection = ({
  disabled,
  suffixes,
  referralType,
  isAdult,
  leaseExecuted,
  leaseSentToPortal,
  relationshipOptions,
  applicantTypeOptions,
  isPrimary,
  sectionInitialValues,
}) => {
  const isEmailBypassed =
    isNil(sectionInitialValues.emailAddress) ||
    isEmpty(sectionInitialValues.emailAddress);
  const isPhoneNumberBypassed =
    isNil(sectionInitialValues.phoneNumber) ||
    isEmpty(sectionInitialValues.phoneNumber);
  const isFirstNameBypassed =
    isNil(sectionInitialValues.firstName) ||
    isEmpty(sectionInitialValues.firstName);
  const isLastNameBypassed =
    isNil(sectionInitialValues.lastName) ||
    isEmpty(sectionInitialValues.lastName);
  const isApplicantTypeIdBypassed =
    isNil(sectionInitialValues.applicantTypeId) ||
    isEmpty(sectionInitialValues.applicantTypeId);
  const isRelationshipIdBypassed =
    isNil(sectionInitialValues.relationshipId) ||
    isEmpty(sectionInitialValues.relationshipId);

  return (
    <FormSection name="generalInformation">
      {leaseExecuted && (
        <RowWarning className="row">
          <div className="highlight-details">
            <FormattedMessage {...messages.leaseLockedExecuted} />
          </div>
        </RowWarning>
      )}
      {leaseSentToPortal && (
        <RowWarning className="row">
          <div className="highlight-details">
            <FormattedMessage {...messages.leaseLockedSentToPortal} />
          </div>
        </RowWarning>
      )}
      <div className="row">
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="preferredName"
              className="input-md form-control"
              component={renderTextField}
              maxLength="35"
              placeholder={disabled ? '---' : ''}
              label={FIELD_TO_LABEL_MAP.preferredName}
              showLabel={true}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="firstName"
              className="input-md form-control"
              component={renderTextField}
              maxLength="35"
              placeholder={disabled ? '---' : ''}
              label={`${FIELD_TO_LABEL_MAP.firstName}*`}
              showLabel={true}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
              warn={isFirstNameBypassed ? requiredVal : undefined}
              validate={isFirstNameBypassed ? undefined : requiredVal}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="middleName"
              className="input-md form-control"
              component={renderTextField}
              maxLength="35"
              placeholder={disabled ? '---' : ''}
              label={FIELD_TO_LABEL_MAP.middleName}
              showLabel={true}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="lastName"
              className="input-md form-control"
              component={renderTextField}
              maxLength="35"
              placeholder={disabled ? '---' : ''}
              label={`${FIELD_TO_LABEL_MAP.lastName}*`}
              showLabel={true}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
              warn={isLastNameBypassed ? requiredVal : undefined}
              validate={isLastNameBypassed ? undefined : requiredVal}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="suffixId"
              selectClassNames="input-md form-control"
              component={renderSelectField}
              label={FIELD_TO_LABEL_MAP.suffixId}
              disabled={disabled || leaseExecuted || leaseSentToPortal}
              options={suffixes}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <Field
              name="phoneNumber"
              className="input-md form-control"
              component={renderPhoneField}
              parse={phoneParser}
              format={phoneFormatter}
              placeholder={disabled || !isAdult ? '---' : ''}
              label={`${FIELD_TO_LABEL_MAP.phoneNumber}${isAdult ? '*' : ''}`}
              showLabel={true}
              disabled={disabled || !isAdult}
              warn={isPhoneNumberBypassed && isAdult ? requiredVal : undefined}
              validate={
                isPhoneNumberBypassed || !isAdult ? undefined : requiredVal
              }
            />
          </div>
        </div>
      </div>
      {isAdult && (
        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <Field
                name="applicantTypeId"
                selectClassNames="input-md form-control"
                component={renderSelectField}
                label={`${FIELD_TO_LABEL_MAP.applicantTypeId}*`}
                disabled={
                  disabled || leaseExecuted || leaseSentToPortal || isPrimary
                }
                options={applicantTypeOptions}
                warn={isApplicantTypeIdBypassed ? requiredVal : undefined}
                validate={isApplicantTypeIdBypassed ? undefined : requiredVal}
              />
            </div>
          </div>
          <div className="col-sm-4">
            <div className="form-group">
              <Field
                name="relationshipId"
                selectClassNames="input-md form-control"
                component={renderSelectField}
                label={`${FIELD_TO_LABEL_MAP.relationshipId}${
                  isPrimary ? '' : '*'
                }`}
                disabled={
                  disabled || leaseExecuted || leaseSentToPortal || isPrimary
                }
                options={relationshipOptions}
                warn={
                  isRelationshipIdBypassed && isPrimary
                    ? requiredVal
                    : undefined
                }
                validate={
                  isRelationshipIdBypassed || !isPrimary
                    ? undefined
                    : requiredVal
                }
              />
            </div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              name="emailAddress"
              className="input-md form-control"
              component={renderEmailField}
              placeholder={disabled || !isAdult ? '---' : ''}
              label={`${FIELD_TO_LABEL_MAP.emailAddress}${isAdult ? '*' : ''}`}
              showLabel={true}
              disabled={disabled || !isAdult}
              warn={isEmailBypassed && isAdult ? requiredVal : undefined}
              validate={isEmailBypassed || !isAdult ? undefined : requiredVal}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-8">
          <div className="form-group">
            <label>How did you hear about our property?</label>
            <p>{referralType || '---'}</p>
          </div>
        </div>
      </div>
    </FormSection>
  );
};

export class GeneralInformation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editable: false,
    };
  }

  toggleEdit = () => {
    this.setState({ editable: !this.state.editable });
  };

  render() {
    const {
      openPanel,
      togglePanel,
      suffixes,
      referralType,
      isAdult,
      leaseExecuted,
      leaseSentToPortal,
      relationshipOptions,
      applicantTypeOptions,
      isPrimary,
      bypassedFields = [],
      sectionInitialValues,
    } = this.props;
    const suffixOptions = update(0, { value: '', text: 'Choose' }, suffixes);
    const mappedBypassedFields = bypassedFields.map(
      (bf) => FIELD_TO_LABEL_MAP[bf],
    );

    return (
      <MiniProfileFormPanel
        open={openPanel === PANEL_TITLE}
        toggleOpen={togglePanel(PANEL_TITLE)}
        title={PANEL_TITLE}
        bypassedFields={mappedBypassedFields}
      >
        <div className="container-fluid padleft5 padright5 padtop15 padbottom15">
          <div className="row">
            <div className="col-md-12">
              <a
                onClick={() => this.toggleEdit()}
                className="small simple-link pull-right"
              >
                <i className="icon et-pencil" />
                <span>Edit </span>
              </a>
            </div>
          </div>
          <GeneralInformationFormSection
            disabled={!this.state.editable}
            suffixes={suffixOptions}
            referralType={referralType}
            isAdult={isAdult}
            leaseExecuted={leaseExecuted}
            leaseSentToPortal={leaseSentToPortal}
            relationshipOptions={relationshipOptions}
            applicantTypeOptions={applicantTypeOptions}
            isPrimary={isPrimary}
            sectionInitialValues={sectionInitialValues}
          />
        </div>
      </MiniProfileFormPanel>
    );
  }
}

export default GeneralInformation;
