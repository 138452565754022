const { apiURL } = require('../utils/api');

const request = require('../utils/api').default;

export default class PropertySpecialsService {
  getPropertySpecials(propertyId: string, otherOptions?: Object = {}) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      ...otherOptions,
    };
    return request(`${apiURL}/propertySpecials/${propertyId}`, options);
  }
  createPropertySpecial(
    organizationId: string,
    propertyId: string,
    propertySpecial: Object,
  ) {
    const options = {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(propertySpecial),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/propertySpecials`,
      options,
    );
  }
  updatePropertySpecial(
    organizationId: string,
    propertyId: string,
    propertySpecialId: string,
    propertySpecial: Object,
  ) {
    const options = {
      method: 'PUT',
      headers: {
        Authorization: localStorage.getItem('session_id'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(propertySpecial),
    };
    return request(
      `${apiURL}/${organizationId}/${propertyId}/propertySpecials/${propertySpecialId}`,
      options,
    );
  }
}
